<div class="ai-predictions-tile" fxLayout="column" fxLayoutGap="10px">
  <!-- Predictions list -->
  <mat-card class="p-0 ai-card-list">
    <mat-tab-group class="ai-tabs" [(selectedIndex)]="activeTabIndex">
      <mat-tab [label]="TABS?.INFO?.title">
        <kl-ai-predictions-information-list
          [progress]="progress"
          [informationList]="informationList"
          [copyId]="TABS?.INFO?.id"
          [showCopy]="pageDetails?.memberRole === memberRoles?.ADMIN || pageDetails?.memberRole === memberRoles?.CONTRIBUTOR"
          (onCopyClicked)="copyToClipBoard($event)">
        </kl-ai-predictions-information-list>
      </mat-tab>
      <mat-tab [label]="TABS?.INPUT?.title">
        <kl-ai-predictions-list
          [progress]="progress"
          [predictionList]="inputsList"
          [showCopy]="pageDetails?.memberRole === memberRoles?.ADMIN || pageDetails?.memberRole === memberRoles?.CONTRIBUTOR"
          [copyId]="TABS?.INPUT?.id"
          (onCopyClicked)="copyToClipBoard($event)">
        </kl-ai-predictions-list>
      </mat-tab>
      <mat-tab [label]="TABS?.OUTPUT?.title">
        <div fxLayout="column" class="ai-outputs" [attr.id]="TABS?.OUTPUT?.id">

          <div *ngFor="let item of outputsList; let last = last; let i = index" class="item" [ngClass]="{'separator': !last}" [attr.id]="'output-ai-predictions' + i">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="column">
                <p class="m-0 mb-2 font-weight-bold product-name">{{item?.productName | titlecase}}</p>
                <!-- <div>
                  <span class="m-0 title">{{item?.title}}</span>
                  <span class="title" *ngIf="item?.requirement"> needed</span>
                </div> -->
              </div>
              <div *ngIf="item?.title && (pageDetails?.memberRole === memberRoles?.ADMIN || pageDetails?.memberRole === memberRoles?.CONTRIBUTOR)">
                <!-- Copy button -->
                <button mat-icon-button class="share-button" (click)="copyToClipBoard('output-ai-predictions' + i)" fxHide.xs>
                  <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/copy.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
                </button>
              </div>
              <!-- <div><span class="stat-unit">Kgs/2000 Acre</span></div> -->
            </div>

            <span class="m-0 title" *ngIf="item?.title">{{item?.title}}</span>

            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between">
              <p class="date unit m-0" *ngIf="item?.requirement && item?.requirement !== '0'">
                <span class="font-weight">Quantity:&nbsp;</span>
                <span *ngIf="item?.requirement && item?.requirement !== '0'">{{ ((item?.requirement | number:'1.0-0') === '0') ? '1' :  (item?.requirement | number:'1.0-0')}}</span>
                <span *ngIf="item?.requirement && item?.unit && item?.requirement !== '0'"> {{item?.unit}}</span>
                <span *ngIf="item?.tagData?.expectedYieldPerAcre?.value && item?.requirement && item?.unit">
                  <span> (</span>
                  <span>{{((item?.tagData?.expectedYieldPerAcre?.value | number:'1.0-0') === '0') ? '1' :  (item?.tagData?.expectedYieldPerAcre?.value | number:'1.0-0')}}
                    <span> {{item?.tagData?.expectedYieldPerAcre?.unit}}/acre</span>
                  </span>
                  <span>)</span>
                </span>
              </p>

              <!-- Cost -->
              <p class="date unit m-0" *ngIf="item?.cost">
                <span *ngIf="item?.cost">
                  <span class="font-weight">Value:&nbsp;</span>
                  <span> ₹</span>
                  <span>{{item?.cost | number: '1.0-0'}} </span>
                </span>
                <span *ngIf="item?.tagData?.cost && item?.cost">
                  <span> (₹</span>
                  <span>{{item?.tagData?.cost}}/</span>
                  <span>acre)</span>
                </span>
              </p>
            </div>
          </div>

          <div *ngIf="(outputsList?.length <= 0 || outputsList[0]?.requirement === 0 ) && !progress" class="my-5 py-3">
            <kl-no-content
              svgIcon="assets/svgs/information.svg"
              text="No outputs">
            </kl-no-content>
          </div>

        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
  <!-- /Predictions list -->

</div>

<!-- Mobile copy and share complete data -->
<main class="container p-0 copy-share-buttons fixed-bottom" fxHide.xs *ngIf="pageDetails?.memberRole === memberRoles?.ADMIN || pageDetails?.memberRole === memberRoles?.CONTRIBUTOR">
  <div class="footer-mobile-tab-wrapper col-6 p-0 mx-auto" fxLayout="row" >

    <div class="footer-mobile-tab w-100">
      <div fxLayout="row" id="tab-color" fxLayoutAlign="space-between">
        <button mat-button class="py-1" (click)="copyToClipBoard(activeTabDetails?.id)">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
            <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/copy.svg"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            <span class="title copy-data">Copy all data</span>
          </div>
        </button>

        <button mat-button class="py-1" (click)="openAiPredictionsShareUpdate()">
          <div class="share-data" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">
            <i class="fas fa-share-alt share"></i>
            <span class="title">Share</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</main>
<!--/ Mobile copy and share complete data -->
