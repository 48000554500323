import { Component, Injector, OnInit } from '@angular/core';
import { BreadcrumbService } from '@kalgudi/common/ui/breadcrumbs';
import { scaleOutAnimation, slideRightAnimation } from '@kalgudi/core';
import { TaskShareUpdate } from '@kalgudi/types';

import { KalgudiTaskFullView } from '../../classes/kalgudi-task-full-view';

@Component({
  selector: 'kl-task-fullview-page',
  templateUrl: './task-fullview-page.component.html',
  styleUrls: ['./task-fullview-page.component.scss'],
  animations: [scaleOutAnimation, slideRightAnimation]
})
export class TaskFullviewPageComponent extends KalgudiTaskFullView implements OnInit {


  activeTabIndex: number;

  readonly TABS = {
    INFO: { index: 0, title: 'Task info' },
    UPDATES: { index: 1, title: 'Activity logs' },
    DETAILS: { index: 2, title: 'Task submission' },
    SUBMISSIONS: { index: 2, title: 'Submissions'}
  };

  constructor(
    protected injector: Injector,
    private breadcrumbService: BreadcrumbService
  ) {

    super(injector);

    this.activeTabIndex = this.TABS.INFO.index;

  }

  ngOnInit() {
    this.init();

    //Setting dynamic breadcrumb label
    this.breadcrumbService.updateDynamicBreadcrumbParams({
      projectTitle: this.taskDetails.projectTitle,
      taskTitle: this.taskDetails.title
    });
  }

  onPostCreated(res: TaskShareUpdate) {
    // console.log('me', res);
  }

}
