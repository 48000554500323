import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiGeoLocationMarkerModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { GaugeModule } from 'angular-gauge';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CropHealthDataComponent } from './crop-health-data/crop-health-data.component';
import { MassAreaUnitConversionPipe } from './mass-area-unit-conversion.pipe';
import { NitrogenDetailsComponent } from './nitrogen-details/nitrogen-details.component';
import { PhosphorusDetailsComponent } from './phosphorus-details/phosphorus-details.component';
import { ReccomendationsCommonComponent } from './reccomendations-common/reccomendations-common.component';
import {
  SatelliteAdvisoiresLocationComponent,
} from './satellite-advisoires-location/satellite-advisoires-location.component';
import {
  SatelliteAdvisoiresNitrogenLevelTileComponent,
} from './satellite-advisoires-nitrogen-level-tile/satellite-advisoires-nitrogen-level-tile.component';
import {
  SatelliteAdvisoriesPhosphorusLevelTileComponent,
} from './satellite-advisories-phosphorus-level-tile/satellite-advisories-phosphorus-level-tile.component';
import {
  SatelliteAdvisoriesPlantHealthLevelTileComponent,
} from './satellite-advisories-plant-health-level-tile/satellite-advisories-plant-health-level-tile.component';
import {
  SatelliteAdvisoriesSoilMoistureTileComponent,
} from './satellite-advisories-soil-moisture-tile/satellite-advisories-soil-moisture-tile.component';
import {
  SatelliteAdvisoriesWaterIndicatorTileComponent,
} from './satellite-advisories-water-indicator-tile/satellite-advisories-water-indicator-tile.component';
import { SatelliteAdvisoriesService } from './satellite-advisories.service';
import { SoilMoistureCanvasGaugeComponent } from './soil-moisture-canvas-gauge/soil-moisture-canvas-gauge.component';
import { SoilMoistureDetailsComponent } from './soil-moisture-details/soil-moisture-details.component';



@NgModule({
  declarations: [
    CropHealthDataComponent,
    SatelliteAdvisoriesPhosphorusLevelTileComponent,
    SatelliteAdvisoriesSoilMoistureTileComponent,
    SatelliteAdvisoriesWaterIndicatorTileComponent,
    SatelliteAdvisoiresNitrogenLevelTileComponent,
    SoilMoistureCanvasGaugeComponent,
    MassAreaUnitConversionPipe,
    SoilMoistureDetailsComponent,
    NitrogenDetailsComponent,
    PhosphorusDetailsComponent,
    SatelliteAdvisoiresLocationComponent,
    ReccomendationsCommonComponent,
    SatelliteAdvisoriesPlantHealthLevelTileComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,

    ReactiveFormsModule,

    FlexLayoutModule,

    AngularSvgIconModule,

    KalgudiGooglePlacesModule,
    KalgudiGoogleGeoLocationModule,
    KalgudiGeoLocationMarkerModule,
    KalgudiPipesModule,

    GaugeModule.forRoot(),
  ],
  exports: [
    CropHealthDataComponent,
    SatelliteAdvisoriesPhosphorusLevelTileComponent,
    SatelliteAdvisoriesSoilMoistureTileComponent,
    SatelliteAdvisoriesWaterIndicatorTileComponent,
    SatelliteAdvisoiresNitrogenLevelTileComponent,
    SatelliteAdvisoriesPlantHealthLevelTileComponent,
    SoilMoistureDetailsComponent,
    NitrogenDetailsComponent,
    PhosphorusDetailsComponent,
    SatelliteAdvisoiresLocationComponent,
  ],
  providers: [
    SatelliteAdvisoriesService,
  ]
})
export class SatelliteAdvisoriesModule { }
