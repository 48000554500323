<main id="profile-certificates-achievements">

  <div class="mb-2 achievements" fxLayout fxLayoutAlign="space-between center">
    <!-- Achievements heading -->
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <svg-icon src="assets/svgs/trophy.svg"
        class="text-center menu-icons cursor-pointer"
        [applyCss]="true"
        [svgStyle]="{ 'width.px': 18, 'height.px': 18}">
      </svg-icon>
      <h4 class="titles mb-0" translate>Achievements</h4>
    </div>
    <!-- / Achievements heading -->

    <!-- Add achievement button -->
    <div class="edit-section">
      <button mat-icon-button class="add-btn"  [matTooltip]="'Add achievement' | translate" (click)="showAddAchievementDialog()" *ngIf="editable">
        <mat-icon class="text-white">add</mat-icon>
      </button>
    </div>
    <!--/ Add achievement button -->
  </div>

  <!-- Achievements -->
  <ng-container *ngFor="let achievement of achievements; let i = index">
    <div fxLayout fxLayoutAlign="space-between center" class="border-bottom pb-2 mb-2 achievement-list">

      <div>
        <h5 class="achievement-title text-capitalize"> {{ achievement?.title }} </h5>
        <p class="achievement-description mb-0">
          <span translate>From</span>{{ achievement?.description }}</p>
      </div>

      <!-- Achievement action buttons -->
      <div class="edit-section" *ngIf="editable">
        <button mat-icon-button class="edit-btn"
          [matMenuTriggerFor]="menu">
          <mat-icon class="text-muted">more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

          <button mat-menu-item id="edit-achievement" (click)="updateAchievement(achievement?.businessAwardId, achievement)">
            <mat-icon class="action-icons">edit</mat-icon>
            <span>Edit</span>
          </button>

          <button mat-menu-item id="delete-achievement" (click)="deleteAchievement(achievement?.businessAwardId)">
            <mat-icon class="action-icons">delete_outline</mat-icon>
            <span>Delete</span>
          </button>

        </mat-menu>
      </div>
      <!--/ Achievement action buttons -->
    </div>
  </ng-container>
  <!--/ Achievements -->

  <!-- No content if there no achievement -->
  <ng-container *ngIf="!achievements?.length">
    <div class="no-content-center w-100" fxLayoutAlign="center center">
      <kl-no-content
        text="No Achievement added yet!">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no achievement -->

</main>
