import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialogRef, KalgudiUsersPickerMobileDialog, KL_MOBILE_DIALOG_DATA } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiPageMembersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-kalgudi-share-member-picker-mobile-dialog',
  templateUrl: './kalgudi-share-member-picker-mobile-dialog.component.html',
  styleUrls: ['../member-picker-mobile-dialog-common.scss', './kalgudi-share-member-picker-mobile-dialog.component.scss']
})
export class KalgudiShareMemberPickerMobileDialogComponent extends KalgudiUsersPickerMobileDialog implements OnInit {

  pageId: string;
  activeTabIndex: number;

  readonly TABS = {
    ALL:  { index: 0, title: 'All Members' },
    SELECTED: { index: 1, title: 'Selected Members' },
  };
  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) protected dialogData: KalgudiPageMembersPickerDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);

    this.pageId = dialogData.pageId;
  }

  ngOnInit() {
    this.activeTabIndex = this.TABS.ALL.index;
  }

}
