<div class="autocomplete crop-autocomplete">

  <!-- Selected items -->
  <div class="my-2" *ngIf="itemsList">
    <mat-chip-list class="kl-chip-list-sm">
      <mat-chip *ngFor="let item of itemsList; let index=index;"
        [selectable]="false"
        [removable]="true"
        (removed)="removeItem(index)">
        <span>{{ item?.productName | translate }}</span>
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </div>
  <!-- / Selected items -->

  <form autocomplete="off" [formGroup]="autoCompleteForm">

    <div fxLayout fxLayoutGap="6px" fxLayoutAlign="start center">
      <!-- <div fxFlex="65px">
        <p class="mb-0">
          Add more
        </p>
        <p class="mb-0">crops</p>
      </div> -->

      <div class="w-100">
        <mat-form-field [appearance]="appearance" class="w-100 mat-form-field-sm">
          <!-- <mat-label translate>{{label}}</mat-label> -->

          <input type="text" matInput formControlName="productName"
            [placeholder]="placeholder | translate"
            [matAutocomplete]="autoComplete" [required]="required">

          <!-- <span matSuffix *ngIf="loading$ | async">
            <span class="spinner-border spinner-border-lg text-muted" role="status"></span>
          </span> -->

          <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete" [displayWith]="displayWithFn">
            <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" (onSelectionChange)="selectItem($event)">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="text-success">eco</mat-icon>

                <div>
                  <span class="d-block autocomplete-field title">{{prediction?.productName}}</span>
                  <span class="d-block autocomplete-field subtitle">{{prediction?.baseCategory?.value}}</span>
                  <!-- <div fxLayout="column" fxLayoutAlign="start center">
                  </div> -->
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>

          <button id="search-icon-btn" mat-button matSuffix mat-icon-button type="submit">
            <mat-icon class="search">search</mat-icon>
          </button>

          <mat-error *ngIf="required && inputField?.touched" translate>Field is required</mat-error>
        </mat-form-field>

        <p class="no-crop-error text-danger" *ngIf="noPrediction && inputFieldValue?.length > 2" @fadeIn>
          <span translate>No crop found with keyword </span>
          <strong>"{{ inputFieldValue }}"</strong>
        </p>
      </div>

    </div>

  </form>

</div>

