<mat-card fxFlex="100%" fxLayout="column" class="warehouse-godown-tile">

  <!-- Header -->
  <kl-common-profile-header
    title="Warehouse / Godown"
    svgIcon="assets/svgs/warehouse.svg"
    [editable]="editable"
    actionIcon="edit"
    (action)="showWarehouseDialog()">
  </kl-common-profile-header>
  <!-- /Header -->

  <!-- List -->
  <section>
    <div fxLayout="column">
      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(60% - 25px)" class="warehouse-field">Capacity</span>
        <span fxFlex="40%" class="warehouse-value w-100 text-truncate">{{ profileWarehouseList?.capacityInTons || '-'}}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(60% - 25px)" class="warehouse-field">Size of warehouse</span>
        <span fxFlex="40%" class="warehouse-value w-100 text-truncate">{{ profileWarehouseList?.warehouseSize || '-'}}</span>
      </div>

      <div class="mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(60% - 25px)" class="warehouse-field">Items stocked</span>
        <span fxFlex="40%" class="warehouse-value w-100 text-truncate">{{ profileWarehouseList?.itemsStocked || '-'}}</span>
      </div>
    </div>
  </section>
  <!--/ List -->

</mat-card>
