import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiPageRelation,
  KalgudiSurveyDetails,
  KalgudiSurveyQuestion,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { KalgudiSurveyService } from '../../../services/kalgudi-survey.service';
import { KalgudiSurveysHelperService } from '../../../services/kalgudi-surveys-helper.service';
import { SurveyStateService } from '../../../services/survey-state.service';
import { KalgudiSurveyQuestionsService } from '../services/kalgudi-survey-questions.service';

@Directive()
export abstract class KalgudiSurveyQuestions extends KalgudiInboxStream<KalgudiSurveyQuestion> {

  surveyId: string;
  surveyDetails: KalgudiSurveyDetails;

  availableActionTypes = SurveyPageActions;

  memberRole: KalgudiPageRelation;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  protected surveyHelper: KalgudiSurveysHelperService;
  private surveyQuestionsService: KalgudiSurveyQuestionsService;
  private kalgudiDialogs: KalgudiDialogsService;
  private surveyState: SurveyStateService;
  private surveyService: KalgudiSurveyService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    this.surveyQuestionsService = this.injector.get<KalgudiSurveyQuestionsService>(KalgudiSurveyQuestionsService);
    this.kalgudiDialogs         = this.injector.get(KalgudiDialogsService);
    this.surveyState            = this.injector.get(SurveyStateService);
    this.surveyHelper           = this.injector.get(KalgudiSurveysHelperService);
    this.surveyService          = this.injector.get(KalgudiSurveyService);

    this.init();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Verifies if the user is allowed to remove the question or not.
   * Shows error message if the user is not allowed to remove the question,
   * otherwise removes the question.
   */
  checkAndRemoveQuestion(questionId: string): void {

    try {

      // Verify question additions
      this.surveyHelper.canModifyQuestion(this.surveyDetails);

      this.removeQuestion(questionId);
    } catch (e) {
      this.util.showApiErrorMessage(e);
    }
  }

  /**
   * Verifies if the user is allowed to add question or not.
   * Shows add question dialog if the user is allowed to add the question
   * or else throws error.
   */
  checkAndAddQuestion(): void {

    try {

      // Verify question additions
      this.surveyHelper.canModifyQuestion(this.surveyDetails);

      this.addQuestion();
    } catch (e) {
      this.util.showApiErrorMessage(e);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and Protected interfacing methods
  // --------------------------------------------------------

  /**
   * Initializes survey fullview subscriptions and survey details
   */
  protected init(): void {

    // Initialize survey details
    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdated(survey));


    // Subscribe to survey page state change
    this.subscribeToStateActions();
  }


  /**
   * Called after deleting the question successfully
   */
  protected deleteQuestionHandler(res: boolean): void {
    this.resetStream();
    this.updateSurveyDetails();
  }
  /**
   * Calls stream api method to get question list
   * @param offset
   * @param limit
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.surveyQuestionsService.getQuestions(this.surveyId)
      .pipe(
        map(res => {
          return {
            items: res.results,
            count: res.count
          };
        })
      );
  }

  /**
   * Opens the add question dialog in web view
   */
  protected showQuestionWebDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.surveyQuestionsService.showQuestionsDialog(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Opens the add question dialog in mobile view
   */
  protected showQuestionMobileDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.surveyQuestionsService.showMobileQuestionsDialog(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Event handler for question delete API errors.
   */
  protected onApiError(err: any): void {

    this.notification.showMessage(err.message || err.error.message);
  }

  /**
   * Opens the add question dialog
   */
  private addQuestion(): void {

    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add question',
      acceptButtonTitle: 'Add',
      rejectButtonTitle: 'Cancel',
      data: {
        surveyId: this.surveyId,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show add question dialog
    this.showAddQuestionDialog(dialogDetails, dialogConfig)
      .pipe(

        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(r => r.accepted),

      )
      .subscribe(
        res => {
          this.resetStream();
          this.updateSurveyDetails();
        }
      );
  }

  /**
   * Calls api to delete the question from the survey
   */
  private removeQuestion(questionId: string): void {

    this.showDeleteConfirmationDialog()
      .pipe(
        switchMap(res => this.surveyQuestionsService.deleteQuestion(this.surveyId, questionId))
      )
      .subscribe(
        res => this.deleteQuestionHandler(res),
        err => this.onApiError(err)
      );
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the question. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Checks which type of view(web / mobile) and opens the related dialog
   */
  private showAddQuestionDialog(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.showQuestionMobileDialog(dialogConfig)
      : this.showQuestionWebDialog(dialogConfig, matDialogConfig);
  }

  /**
   * Subscribes to survey state action updates and calls the necessary methods.
   */
  private subscribeToStateActions(): void {

    this.surveyState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === SurveyPageActions.ADD_QUESTION) {
          this.checkAndAddQuestion();
        }
      });
  }

  /**
   * Event handler gets called on the survey details update in the
   * state service
   */
  private onSurveyStateUpdated(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
    this.surveyId      = survey.surveyId;
    this.memberRole    = survey.role;
  }


  /**
   * Updates survey details in the state
   */
  private updateSurveyDetails(): void {

    this.surveyService.getSurvey(this.surveyDetails.surveyId)
      .subscribe(survey => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, survey));
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
