<div id="kalgudi-re-share-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">

  <!-- dialog header -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!-- /dialog header -->

  <!--dialog content-->
  <kl-share-update-form
    (postCreated)="onPostCreated($event);"
    [reShareDetails]="true"
    [postDetails]="data.data.postDetails">
  </kl-share-update-form>
  <!-- /dialog content -->
</div>
