import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseBaseProductAutocomplete,
  ApiResponseCommon,
  APiResponseTaskCreation,
  BaseProductAutocomplete,
  IdValueMap,
  KalgudiProjectTask,
  KalgudiTaskTemplateSearchResults,
  PartialData,
  StoreType,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiTaskCreationApiService {


  /**
   * `v2/listing/vproducts/:productId`
   */
  private readonly API_BASE_PRODUCT = `${this.environment.restBaseUrlV2}/listing/vproducts/:productId`;

  /**
   * `v2/projects`
   */
  private readonly API_PROJECTS = `${this.environment.restBaseUrlV2}/projects`;

  /**
   * `v2/projects/tasks`
   */
  private readonly API_TASKS = `${this.API_PROJECTS}/tasks`;

  /**
   * `v2/projects/tasks/:taskId?projectId=00dw`
   */
  private readonly API_TASK_UPDATE = `${this.API_TASKS}/:taskId`;

  /**
   * `v2/projects/templates`
   */
  private readonly API_TEMPLATES = `${this.API_PROJECTS}/templates`;

  /**
   * `v2/projects/templates/:templateId`
   */
  private readonly API_TEMPLATE = `${this.API_TEMPLATES}/:templateId`;

  /**
   * `v2/projects/templates/search`
   */
  private readonly API_TEMPLATE_SEARCH = `${this.API_TEMPLATES}/search`;

  /**
   * `https://inputs.kalgudi.com/rest/v2/search/farmstore/products`
   */
  private readonly API_BASE_PRODUCT_PREDICTION = `https://inputs.kalgudi.com/rest/v2/search/farmstore/products`;


  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Api to create a task
   */
  createTask(projectId: string, req: KalgudiProjectTask, filters: PartialData = {}): Observable<APiResponseTaskCreation> {

    const params = {
      projectId,
      ...filters
    };

    return this.httpClient
      .post<ApiResponseCommon>(this.API_TASKS, req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );
  }

  /**
   * Api to update a task
   */
  updateTask(projectId: string, taskId: string, req: KalgudiProjectTask, filters: PartialData = {}): Observable<APiResponseTaskCreation> {

    const params = {
      projectId,
      ...filters
    };

    return this.httpClient
      .put<ApiResponseCommon>(this.API_TASK_UPDATE.replace(':taskId', taskId), req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        map(res => res.data)
      );
  }


  /**
   * Api to fetch the task templates
   */
  getTaskTemplates(keyword: string, projectType: string, offset: number, limit: number): Observable<KalgudiTaskTemplateSearchResults> {

    const params = {
      keyword,
      projectType,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient
    .get<ApiResponseCommon>(this.API_TEMPLATE_SEARCH, { params })
    .pipe(
      map(res => this.util.apiErrorHandler(res)),

      map(res => res.data)
    );
  }

  getTemplateSchema(templateId: string): Observable<any> {

    return this.httpClient
      .get<ApiResponseCommon>(this.API_TEMPLATE.replace(':templateId', templateId))
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }


  /**
   * Fetches crop stages of a product from PDS api
   */
  fetchCropStages(productId: string, storeType: StoreType): Observable<IdValueMap[]> {

    const url = this.API_BASE_PRODUCT.replace(':productId', productId);

    const params = { storeType: storeType.toString() };

    return this.httpClient
      .get<ApiResponseCommon>(url, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data.data ? res.data.data.cropStages : [])
      );
  }


  /**
   * Fetch task list
   * @param projectId
   * @param offset
   * @param limit
   */
  fetchStream(
    offset: number,
    limit: number,
    projectId = '',
  ): Observable<KalgudiStreamData> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      projectId,
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_TASKS, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi project details object type
        map(r => ({ items: r.data.results, count: r.data.count}))

      );
  }

  /**
   * Gets the base product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    offset: number,
    limit: number,
    filters: PartialData = {}
  ): Observable<BaseProductAutocomplete[]> {

    filters.category= '';
    filters.l2Id='';
    filters.l3Id='';
    filters.l4Id='';

    const params = {
      keyword,
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters
    };

    return this.httpClient.get<ApiResponseBaseProductAutocomplete>(this.API_BASE_PRODUCT_PREDICTION, { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data),
      );
  }

}
