import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable()
export class KalgudiNgxFormlyMapperService {

  constructor() { }

  mapAppearance(mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig {

    mappedField.templateOptions.appearance = mapSource.appearance || 'outline';

    return mappedField;
  }

  mapConfiguration(mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig {

    mappedField = this.mapAppearance(mappedField, mapSource);

    mappedField.templateOptions.readonly    = !!(mapSource.readonly);
    mappedField.templateOptions.disabled    = !!(mapSource.disabled);
    mappedField.templateOptions.placeholder = mapSource.placeholder;
    mappedField.templateOptions.hidden      = mapSource.hidden;

    // Map class names
    mappedField.className = mapSource.widget ? mapSource.widget.className : '';
    mappedField.fieldGroupClassName  = mapSource.widget ? mapSource.widget.fieldGroupClassName : '';
    mappedField.templateOptions.rows = mapSource.rows;

    // Text area mapping
    if (mapSource.rows && +mapSource.rows > 1) {
      mappedField.type = 'textarea'
    }

    return mappedField;
  }
}
