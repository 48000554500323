<div class="pt-4 pages-member-picker">
  <div class="user-search pb-4">

    <!-- Search bar -->
    <kl-search [searchForm]="searchForm" placeHolder="Search and select page members" (search)="search()">
    </kl-search>
    <!-- / Search bar -->
  </div>
  <!-- <div *ngIf="searchKeyword" class="mt-1">
    <p class="mb-0 count">Found <span><b>{{(paginator$ | async)?.count}}</b></span> results for
      <span><b>"{{searchKeyword}}"</b></span></p>
  </div> -->

  <!-- List of users -->

  <ng-container *ngIf="{ stream: (stream$ | async), paginator: (paginator$ | async)} as view">
    <ng-container *ngIf="view?.stream?.length > 0">
      <div class="list overflow-auto mb-3">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-2">
          <div *ngFor="let user of view?.stream" fxFlex="49%" class="mb-3" fxFlex.xs="100%" (click)="toggleUserSelection(user)"
            [ngClass]="{'selected': !!(selectedUsersMap[user?.profileKey])}">
            <kl-selectable-user-tile
              avatarSize="md"
              [author]="user"
              [isSelected]="!!(selectedUsersMap[user?.profileKey])">
            </kl-selectable-user-tile>
          </div>
        </div>

        <!-- Load more -->
        <div class="mt-3">
          <kl-load-more
            [noMoreText]="'No more users to show' | translate"
            [progress]="view?.paginator?.loading"
            [hasMoreItems]="view?.paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
      </div>
    </ng-container>

    <!-- No content -->
    <div *ngIf="view?.stream?.length <= 0" fxLayoutAlign="center center" class="py-3 h-100 no-content">
      <kl-no-content
        [progress]="view?.paginator?.loading"
        [progressText]="'Hold tight, loading members list...' | translate"
        [text]="'No members to show!' | translate"
        svgIcon="assets/svgs/undraw_people_search_wctu.svg"
        iconSize="lg">
      </kl-no-content>

    </div>
  </ng-container>

</div>
