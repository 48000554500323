<section id="project-list-grid">
  <!-- Search bar -->
  <div class="mb-3" fxLayoutAlign="center center">
    <kl-search class="search w-100"
      placeHolder="Type and hit enter to search projects"
      (search)="search();"
      [searchForm]="searchForm">
    </kl-search>
  </div>
  <!-- /Search bar -->

  <div fxLayout="row wrap" fxLayoutGap="20px grid" fxLayout.xs="column">

    <ng-container *ngIf="((stream$ | async) || true) as stream">
      <div fxFlex="33.3%" fxFlex.sm="50%" fxFlex.md="50%"  fxFlex.xs="100%" *ngFor="let project of stream; let i = index">
        <div [@floatTop]="{ value: '', params: {delay: (i % maxPageLimit) * 50} }" class="projects">
          <kl-project-tile
            [projectDetails]="project"
            [overrideDefaultRouteNavigation]="overrideDefaultRouteNavigation"
            (deleteProject)="deleteProject(project?.id)"
            (addProjectMembers)="showAddMembersDialog(project?.id)"
            (updateProject)="showUpdateProject(project?.id)"
            (addTask)="openTaskCreationDialog(project?.id)"></kl-project-tile>
        </div>
      </div>

      <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

        <ng-container *ngIf="!stream || stream?.length <= 0">
          <kl-no-content
            svgIcon="assets/svgs/projects.svg"
            text="You are not part of any project"
            actionLink="Create your first project"
            progressText="Loading your projects..."
            [progress]="paginator?.loading"
            (action)="createProject()">
          </kl-no-content>
        </ng-container>

        <!-- Load more -->
        <div class="mt-3" *ngIf="paginator && stream?.length">
          <kl-load-more
            noMoreText="No more projects"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
        <!-- /Load more -->
      </div>
    </ng-container>

  </div>

</section>
