import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyDetails, SurveyBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { CloneSurveyDialogComponent } from '../components/clone-survey-dialog/clone-survey-dialog.component';
import {
  CloneSurveyMobileDialogComponent,
} from '../components/clone-survey-mobile-dialog/clone-survey-mobile-dialog.component';
import { CreateSurveyDialogComponent } from '../components/create-survey-dialog/create-survey-dialog.component';
import {
  CreateSurveyMobileDialogComponent,
} from '../components/create-survey-mobile-dialog/create-survey-mobile-dialog.component';
import { KalgudiCreateSurveyApiService } from './create-survey-api.service';

@Injectable()
export class KalgudiCreateSurveyService {

  constructor(
    private api: KalgudiCreateSurveyApiService,
    private kalgudiDialogService: KalgudiDialogsService,
  ) { }

  /**
   * Opens survey creation dialog
   */
  openSurveyCreationDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialogService.openMobileDialog(CreateSurveyMobileDialogComponent, details)
      : this.kalgudiDialogService.openDialog(CreateSurveyDialogComponent, details, config);
  }

  /**
   * Opens clone survey dialog
   */
  openCloneSurveyDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialogService.openMobileDialog(CloneSurveyMobileDialogComponent, details)
      : this.kalgudiDialogService.openDialog(CloneSurveyDialogComponent, details, config);
  }

  /**
   * Make an api call to create a survey
   */
  createSurvey(payload: SurveyBasicDetails): Observable<KalgudiSurveyDetails> {

    return this.api.createSurvey(payload);
  }

  /**
   * Make an api call to clone survey
   */
  createCloneOfSurvey(payload: any, surveyId: string): Observable<KalgudiSurveyDetails> {
    return this.api.createCloneOfSurvey(payload, surveyId);
  }

}
