import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiStreamS3FetchService } from '@kalgudi/social';
import { KalgudiDialogConfig, KalgudiDialogResult, ProgramShareUpdate } from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  MobileProgramShareUpdateFormComponent,
} from '../components/mobile-program-share-update-form/mobile-program-share-update-form.component';
import {
  UpdateProgramPostDialogComponent,
} from '../components/update-program-post-dialog/update-program-post-dialog.component';
import {
  UpdateProgramPostMobileDialogComponent,
} from '../components/update-program-post-mobile-dialog/update-program-post-mobile-dialog.component';
import { KalgudiProgramShareUpdateApiService } from './kalgudi-program-share-update-api.service';

/**
 * Kalgudi program share update stream service api and business logic definition.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiProgramShareUpdateService {

  constructor(
    private api: KalgudiProgramShareUpdateApiService,
    private s3Fetch: KalgudiStreamS3FetchService,
    private dialogService: KalgudiDialogsService,
  ) { }


  /**
   * @param config Dialog
   */
  showShareUpdateDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openShareUpdateMobileDialog(details)
      : this.openShareUpdateDialog(details, config);
  }

  /**
   * Gets, stream of share update activities performed under specified program.
   *
   * @param entityId Page/program id
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getShareStream(entityId: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchStream(entityId, offset, limit)
      .pipe(

        // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
        // method.
        mergeMap(r =>

          // Combine response from all s3 items
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem<ProgramShareUpdate>(s as any, s.url, s.event))
          )
        ),

        // Map the complete stream response
        map(items => ({ items }))
      );
  }

  /**
   * Gets, stream of share update activities performed under specified program.
   *
   * @param entityId Page/program id
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getPageShareStream(entityId: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchPageShareStream(entityId, offset, limit)
      .pipe(

        // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
        // method.
        mergeMap(r =>

          // Combine response from all s3 items
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem<ProgramShareUpdate>(s as any, s.url, s.event))
          )
        ),

        // Map the complete stream response
        map(items => ({ items }))
      );
  }


  /**
   * Gets, stream of share update activities performed under all pages.
   *
   * @param pageIds Page/program ids
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getUpdatesStream(msgTypes: string, pageIds: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchUpdatesStream(msgTypes, pageIds, offset, limit);

  }

  /**
   * Opens program share update form for mobil view
   */
  openMobileShareUpdateForm(kuberPageId?: string, pageId?: string) {
    return this.dialogService.openMobileDialog(MobileProgramShareUpdateFormComponent, {
      data: { },
      title: 'Share an Update',
    });
  }


  /**
   * Opens share update form
   */
  private openShareUpdateDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.dialogService.openDialog(UpdateProgramPostDialogComponent, details, config);
  }

  /**
   * Opens schedule send dialog in mobile
   */
  private openShareUpdateMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.dialogService.openMobileDialog(UpdateProgramPostMobileDialogComponent, details );
  }

}
