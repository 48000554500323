import { Component, Inject, Injector, OnInit } from '@angular/core';

import { KalgudiBoardMembers } from '../../classes/kalgudi-board-members';
import { Router } from '@angular/router';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-board-members',
  templateUrl: './board-members.component.html',
  styleUrls: ['./board-members.component.scss']
})
export class BoardMembersComponent extends KalgudiBoardMembers implements OnInit {

  isPublicPage: boolean = false;

  constructor(
    protected injector: Injector,
    private router: Router,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(injector);
  }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  onDestroyed(): void {}

}
