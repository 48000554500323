import { Directive, Inject, Injector, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import {
  checkMobileDevice,
  KalgudiAppService,
  KalgudiUsersService,
  KalgudiUtilityService,
  LikeShareRequest,
  NotImplementedError,
  ReportAbuseRequest,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiInputDialogConfig,
  KalgudiUser,
  KalgudiUserBasicDetails,
  ScheduleDates,
  SocialPost,
} from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import {
  AiPredictionsSendSmsDialogComponent,
} from './components/ai-predictions-send-sms-dialog/ai-predictions-send-sms-dialog.component';
import {
  AiPredictionsSendSmsMobileDialogComponent,
} from './components/ai-predictions-send-sms-mobile-dialog/ai-predictions-send-sms-mobile-dialog.component';
import { KalgudiHomeStreamService } from './services/kalgudi-home-stream.service';
import { KalgudiSocialService } from './services/kalgudi-social.service';
import { KalgudiReportAbuseService } from './services/report-abuse/report-abuse.service';


/**
 * Defines methods for any social actions. Defines actions that you can take
 * on social post. Common actions you can perform on a social post are - like, comment,
 * delete, report abuse.
 *
 * This class implements all above basic actions for any social post tile.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSocialActions<T extends SocialPost> implements OnDestroy {

  /**
   * Social post details, it must be provided by a stream.
   */
  @Input()
  postDetails: T;

  /**
   * List of comments if a social post has comments on it.
   */
  @Input()
  comments: T[] = [];

  /**
   * Status of performing any action.
   */
  actionProgress = false;

  showProgress = false;

  isLoggedIn: boolean;

  scheduledDates: ScheduleDates;

  /**
   * Comments form
   */
  protected socialCommentsForm: FormGroup;

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  private destroyed = new Subject();

  private socialService: KalgudiSocialService;
  protected homeStream: KalgudiHomeStreamService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected kalgudiApp: KalgudiAppService,
    protected usersService: KalgudiUsersService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    this.socialService = this.injector.get(KalgudiSocialService);
    this.homeStream = this.injector.get(KalgudiHomeStreamService);

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();

    // Call the method implemented by children for memory cleanup
    this.onDestroyed();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------
  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }

  /**
   * Gets, Kalgudi logged in user profile details
   */
  get basicUserDetails$(): Observable<KalgudiUserBasicDetails> {
    return this.kalgudiApp.profileBasicDetails$
      .pipe(

        // Take only first record
        take(1),
      );
  }

  /**
   * Fetches the logged in user profile
   */
  get profile(): KalgudiUser {
    return this.kalgudiApp.profileLocal;
  }

  /**
   * Gets, the comment form
   */
  get commentsForm(): FormGroup {
    return this.socialCommentsForm;
  }

  /**
   * Comment text field
   */
  get commentField(): AbstractControl {
    return this.commentsForm.get('text');
  }

  /**
   * Comment text field value
   */
  get commentValue(): string {
    return this.commentField.value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------

  /**
   * Likes a content, it calls the like post Api implemented by it's child.
   */
  like(): void {

    // Get share payload, to be implemented by its child
    const payload = this.getLikePayload();

    this.showProgress = true;

    // Call api to share post
    this.likePostApi(payload)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Update the post like details
        tap(r => this.updatePost(r)),

        finalize(() => this.showProgress = false)
      )
      .subscribe(
        res => this.onPostLiked(res),
        err => this.onPostActionApiError(err)
      );
  }

  /**
   * Adds a comment to the social post.
   */
  comment(): void {

    // Tap on spinner
    this.actionProgress = true;

    // Get comment payload
    const payload = this.getCommentsPayload();

    // Disable comments form
    this.commentsForm.disable();

    // Call the comments Api to post comment
    this.commentPostApi(payload)
      .pipe(

        // Subscribe to the observable till the instance is not destroyed
        takeUntil(this.destroyed$),

        // Tap off progress spinner
        finalize(() => this.actionProgress = false),

        // Reset comments field
        tap(r => this.resetCommentsForm()),

        // Update is commented flag
        tap(r => r.commented = true),

        // Update the post details with latest comments
        tap(r => this.updatePost(r))
      )
      .subscribe(
        res => this.onPostCommented(res),
        err => this.onCommentError(err)
      );
  }

  /**
   * Deletes the post. Removes the post from the stream.
   */
  deletePost(): void {

    this.showDeleteConfirmationDialog()
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Inject attached images to share form
        switchMap(r => this.deletePostApi()),
      )
      .subscribe(
        res => this.onPostDeleted(),
        err => this.onPostActionApiError(err)
      );
  }

  /**
   * Reports a post to the Api. The reported post is used later by the
   * AI. It shows the input dialog box to accept the reason for reporting
   * the post. Finally calls the report Api.
   */
  report(postText: string, id: string): void {

    this.showReportAbuseDialog(postText)
      .pipe(

        // Subscribe till the instance is alive
        takeUntil(this.destroyed$),

        // Transform the input dialog response to report api response
        switchMap(r => this.reportApi(r, id))
      )
      .subscribe(
        res => this.notifications.showMessage(`Thanks for reporting`),
        err => this.onPostActionApiError(err)
      );
  }

  /**
   * Updates the post details with the latest post details. It keeps the previous
   * post details object and inject the new values in to the post details.
   *
   * @param post Latest post object
   */
  updatePost(post: T): T {

    // Update the post details with the latest post details
    // Make sure you don't lose any fields.
    // Using spread operator makes sure it overrides previous values
    // with the new values
    this.postDetails = {
      ...this.postDetails,
      ...post
    };

    // Turn of the progress spinner
    this.actionProgress = false;

    return this.postDetails;
  }

  /**
   * Opens ai predictions send as sms dialog
   */
  openAiPredictionsSendSmsDialog(pageId: string) {

    const url = `app/pages/${pageId}/d/sms`

    window.open(url, '_blank');

    // Users dialog UI configuration
    // const dialogDetails: KalgudiDialogConfig = {
    //   title: 'Send as SMS',
    //   data: {
    //   }
    // };

    // // Material dialog configuration
    // const dialogConfig: MatDialogConfig = {
    //   width: '500px',
    //   maxWidth: '500px',
    //   panelClass: 'kl-dialog',
    //   hasBackdrop: true,
    //   disableClose: true,
    //   autoFocus: false,
    //   data: {
    //   }
    // };

    // // Show status dialog
    // return this.showAiPredictionsSendSmsDialog(dialogDetails, dialogConfig)
    //   .pipe(

    //     filter(r => r.accepted),
    //   );
  }

  /**
   * Share on whatsapp
   */
  shareOnWhatsapp() {

  }


  /**
   * Getting latest advisory details
   */
  refreshLatestSatelliteAdvisory(profileKey: string, landId: string) {
    this.notifications.showSpinner();

    this.homeStream.getLatestAdvisory(profileKey, landId)
    .pipe(
      takeUntil(this.destroyed$),
      finalize(() => {this.notifications.hideSpinner()})
    )
    .subscribe(
      res => {
        this.notifications.showMessage('Your request being processed, please check back later!')
      },
      err => {
        this.notifications.showMessage('Something went wrong, please try again later!')
      }
    )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;

  /**
   * Implement post like action Api call
   */
  protected likePostApi(payload: LikeShareRequest): Observable<T> {
    throw new NotImplementedError(new Error('Implement likePostApi() method if you are using like()'));
  }

  /**
   * Construct and gets the like payload
   */
  protected getLikePayload(): LikeShareRequest {
    throw new NotImplementedError(new Error('Implement getLikePayload() method if you are using like()'));
  }

  /**
   * Implement operation to be performed on successful post like.
   */
  protected onPostLiked(res: T): void {
    throw new NotImplementedError(new Error('Implement onPostLiked() method if you are using like()'));
  }

  /**
   * Implement post like action Api call
   */
  protected commentPostApi(payload: LikeShareRequest): Observable<T> {
    throw new NotImplementedError(new Error('Implement commentPostApi() method if you are using comment()'));
  }

  /**
   * Gets, the comment payload. It will fetch all fetch all values from the
   * comments form.
   *
   * Override this method if the comment payload is different for different
   * implementations.
   */
  protected getCommentsPayload(): LikeShareRequest {
    return this.util.clone<LikeShareRequest>(this.commentsForm.value);
  }

  /**
   * Event handler called after posted comment successfully.
   */
  protected onPostCommented(res: T): void {
    throw new NotImplementedError(new Error('Implement onPostCommented() method if you are using comment()'));
  }

  /**
   * Implement post delete Api call.
   */
  protected deletePostApi(): Observable<boolean> {
    throw new NotImplementedError(new Error('Implement deletePostApi() method if you are using delete()'));
  }

  /**
   * Implement operations to be performed on successful post deletion.
   */
  protected onPostDeleted(): void {
    throw new NotImplementedError(new Error('Implement onPostDeleted() method if you are using delete()'));
  }

  /**
   * Defines the report abuse Api.
   *
   * @param reason Reason for reporting the the
   */
  protected reportApi(reason: string, id: string): Observable<boolean> {

    const payload: ReportAbuseRequest = this.getReportAbusePayload(reason, id);

    return this.reportAbuse.reportAbuse(payload);
  }

  /**
   * Prepares payload for report abuse Api call
   *
   * @param reasonForReporting
   */
  protected getReportAbusePayload(reasonForReporting: string, id: string): ReportAbuseRequest {

    if (!this.isLoggedIn) {
      throw new Error('User not logged in');
    }

    return {
      businessName: this.usersService.getBusinessName(this.kalgudiApp.profileLocal),
      location: this.usersService.getLocationLong(this.kalgudiApp.profileLocal),
      message: reasonForReporting,
      mobileNumber: this.profile.mobileNo,
      moduleId: 'content',
      profileKey: this.profile.profileKey,
      userName: this.profile.firstName,
      fullViewMessageId: id,
    };
  }

  /**
   * Event handler for share update posting API errors.
   */
  protected onPostActionApiError(err: Error): void {

    this.notifications.showMessage("Something went wrong, please try again later!");
  }

  /**
   * Event handler for any comment posting error.
   */
  protected onCommentError(err: Error): void {

    // Enable comments form on error
    this.commentsForm.enable();

    this.util.errorHandler(err.message);
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the post. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Shows input dialog and returns string.
   */
  private showReportAbuseDialog(shareText: string): Observable<string> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiInputDialogConfig = {
      title: 'Report abuse',
      acceptButtonTitle: 'Submit',
      rejectButtonTitle: 'Cancel',
      message: 'You are reporting abuse for',
      inputPlaceHolder: 'What is wrong in this message?',
      multiLineInput: true,
      data: {
        text: shareText
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      maxWidth: '100vw',
      width: '500px',
      panelClass: 'kl-report-abuse-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showInput(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.data.text),
      );
  }

  /**
   * Resets the comment form values. Resetting comments form
   * causes the comments form to re-enable it if disabled.
   */
  private resetCommentsForm(): void {

    // Reset the comment field
    this.commentField.reset();

    // Enable the comments form back if disabled
    this.commentsForm.enable();
  }

  /**
   * Check for web and mobile and opens Send Sms dialog
   *
   * @param config Dialog
   */
  private showAiPredictionsSendSmsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.kalgudiDialogs.openMobileDialog(AiPredictionsSendSmsMobileDialogComponent, dialogConfig);
    } else {

      return this.kalgudiDialogs.openDialog(AiPredictionsSendSmsDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
