import { Injector, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { KalgudiMatTableStream, KalgudiStreamData } from '@kalgudi/core';
import { CreditActivity } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { CreditService } from '../services/credit.service';


@Directive()
export abstract class KalgudiCreditActivity extends  KalgudiMatTableStream<CreditActivity> {

  @ViewChild(MatPaginator, { static: true })

  matPaginator: MatPaginator;

  private creditService: CreditService;

  constructor(protected injector: Injector) {
    super();

    this.creditService = this.injector.get(CreditService);

    this.pageSizeOptions = [5, 10, 15];

    this.displayedColumns = [
      'remark',
      'firstName',
      'entityName',
      'closingCredit'
    ];

  }

  /**
   * calls stream API to fetch activity list
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.creditService.fetchCreditActivityList(offset, limit);

  }

}
