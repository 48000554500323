import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiCommonDirectivesModule } from '../../directives/kalgudi-common-directives.module';
import {
  KalgudiMobileDialogContainerComponent,
} from './components/kalgudi-mobile-dialog-container/kalgudi-mobile-dialog-container.component';
import { KalgudiMobileDialogService } from './kalgudi-mobile-dialog.service';


/**
 * Module to host mobile dialogs.
 *
 * @see https://material.angular.io/cdk/overlay/overview
 * @see https://blog.thoughtram.io/angular/2017/11/20/custom-overlays-with-angulars-cdk.html
 * @see https://blog.thoughtram.io/angular/2017/11/27/custom-overlays-with-angulars-cdk-part-two.html
 */
@NgModule({
  declarations: [
    KalgudiMobileDialogContainerComponent,
  ],
  imports: [
    CommonModule,

    // Material
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,

    // Kalgudi libraries
    KalgudiCommonDirectivesModule,

    TranslateModule
  ],
  providers: [
    KalgudiMobileDialogService,
  ],
  exports: [
    KalgudiMobileDialogContainerComponent,
  ],
  entryComponents: [
    KalgudiMobileDialogContainerComponent,
  ]
})
export class KalgudiMobileDialogModule { }
