import { Directive, Injector, Input, OnDestroy } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProfilePageActions } from '../../constants';
import {
  EditCommonProfileDetailsDialogService,
} from '../../modules/profile-basic-edit/services/edit-common-profile-details-dialog.service';
import { KalgudiProfileStateService } from '../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../services/kalgudi-profile.service';



/**
 * Base class for Kalgudi profile sections. Defines common functions for
 * profile sections.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiUserProfileHeader implements OnDestroy {

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Stream emitted on instance destroyed
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }

  @Input()
  user: KalgudiUser;

  @Input()
  editable: boolean;


  pageId: string;
  isDigitalAssist: boolean;
  loginId: string;

  /**
   * Emitted when component is destroyed
   */
  private destroyed = new Subject();

  private notifications: KalgudiNotification;
  private profileService: KalgudiProfileService;
  private profileState: KalgudiProfileStateService;
  private profileBasicDetailsEditDialogService: EditCommonProfileDetailsDialogService;

  constructor(
    protected injector: Injector
  ) {

    this.notifications                        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileService                       = this.injector.get(KalgudiProfileService);
    this.profileState                         = this.injector.get(KalgudiProfileStateService);
    this.profileBasicDetailsEditDialogService = this.injector.get(EditCommonProfileDetailsDialogService);

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.getLoginId();
        }
      );

    this.pageId = this.profileState.pageId;

    this.isDigitalAssist = this.pageId ? true : false;

  }

  ngOnDestroy(): void {

    this.destroyed.next();
    this.destroyed.complete();

    this.onDestroyed();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Uploads the file to the s3
   * @param fileEvent
   */
  uploadProfilePic(fileEvent: any) {

    this.profileState.dispatchAction(ProfilePageActions.UPDATE_PROFILE_PIC, fileEvent);

  }

  /**
   * Calls api to get the login id
   */
  getLoginId() {
    this.profileService.getLoginId()
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.loginId = res.loginId;
      });
  }

  /**
   * Shows Edit login id dialog
   */
  showEditLoginIdDialog() {

    this.profileBasicDetailsEditDialogService.showEditLoginIdDialog(this.loginId)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed.
   */
  protected abstract onDestroyed(): void;


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private methods
  // --------------------------------------------------------

  /**
   * Event handler for API error.
   */
  protected onProfilePicUpdateError(err: Error): void {

    this.notifications.showMessage(err.message);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
