import { Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, Provider, SimpleChanges } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import { IdValueMap, KalgudiProjectTask, StoreType } from '@kalgudi/types';

import { KalgudiTaskCreationService } from '../../services/kalgudi-task-creation.service';



// Page tags NG_VALUE_ACCESSOR provider for custom form control
const FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CropDependencyPickerComponent),
  multi: true,
};


@Component({
  selector: 'kl-crop-dependency-picker',
  templateUrl: './crop-dependency-picker.component.html',
  styleUrls: ['./crop-dependency-picker.component.scss'],
  providers: [ FORM_CONTROL_PROVIDER ]
})
export class CropDependencyPickerComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {

  @Input()
  productId: string;

  @Input()
  tasks: KalgudiProjectTask[];

  @Input()
  storeType: StoreType;

  cropStages: IdValueMap[] = [];
  afterBefore: IdValueMap[] = [
    { id: 'AFTER', value: 'After'},
    { id: 'BEFORE', value: 'Before'}
  ];

  cropStageForm: FormGroup;

  task = new FormControl({});

  disabled = false;

  constructor(
    private fb: FormBuilder,
    private taskCreationService: KalgudiTaskCreationService,
  ) {

    this.cropStageForm = this.newCropStageForm;

    this.cropStageForm.valueChanges.subscribe(res => {

      // Invoke ControlValueAccessor `onChange` to update formControl values
      this.onChange(res);
      this.onTouched();
    });

    this.task.valueChanges.subscribe(
      (value: KalgudiProjectTask) => {
        if (value && value.id) {
          this.taskIdField.patchValue(value.id);
          this.taskTitleField.patchValue(value.title);

        }
      }
    );
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    // if (changes.productId && changes.storeType && this.productId && this.storeType) {
    //   this.fetchCropStages(this.productId, this.storeType);
    // }
  }

  ngOnDestroy(): void {
    this.cropStageForm.reset();
  }

  // /**
  //  * Crop stage field
  //  */
  // private get cropStageField(): AbstractControl {
  //   return this.cropStageForm.get('cropStage');
  // }


  /**
   * Task Id field
   */
  private get taskIdField(): AbstractControl {
    return this.cropStageForm.get('taskId');
  }


  /**
   * Task title field
   */
  private get taskTitleField(): AbstractControl {
    return this.cropStageForm.get('taskTitle');
  }

  /**
   * Returns new stage form
   */
  private get newCropStageForm(): FormGroup {
    return this.fb.group({
      from: [0, Validators.required],
      to: [''],
      // when: [this.afterBefore[0].id, Validators.required],
      // cropStage: ['', Validators.required],
      taskId: [''],
      taskTitle: ['']
    });
  }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    // this.tagsForm.patchValue(obj);

    if (obj) {
      this.cropStageForm.patchValue(obj);

      const task = this.tasks.find(task => task.id === obj.taskId);

      this.task.patchValue(task);
    } else {
      this.cropStageForm.reset();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    if (isDisabled) {
      this.cropStageForm.disable();
    } else {
      this.cropStageForm.enable();
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // private fetchCropStages(productId: string, storeType: StoreType): void {

  //   this.taskCreationService.fetchCropStages(productId, storeType)
  //     .subscribe(stages => {
  //       this.cropStages = stages;

  //       if (this.cropStages.length > 0) {
  //         this.cropStageField.patchValue(this.cropStages[0].id);
  //       }
  //     });
  // }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
