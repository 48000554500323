<div class="task-stats">
  <mat-tab-group [(selectedIndex)]="activeTabIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="title" translate>{{ TABS.COMPLETED.title }}</span>
      </ng-template>
      <div class="list" *ngIf="activeTabIndex === TABS.COMPLETED.index">

        <div *ngIf="taskMembersList?.completed?.length > 0">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="15px" class="p-0 select-card">
            <div fxFlex="33.3%" fxFlex.md="50%" fxFlex.xs="100%"
              *ngFor="let users of taskMembersList?.completed; let i = index">
              <div>
                <kl-selectable-user-tile [author]="users" [avatarSize]="'md'" [roundedAvatar]="true" [isSelected]="false"
                  [navigationAllowed]="true" enableElevation="true">
                </kl-selectable-user-tile>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="taskMembersList?.completed?.length <= 0" class="stats-no-content" fxLayout="row" fxLayoutAlign="center center">
          <kl-no-content
            faIcon="far fa-user-circle"
            text="No members"
            progressText="Hold tight, searching users..."
            [progress]="progress">
          </kl-no-content>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="title" translate>{{ TABS.IN_PROGRESS.title }}</span>
      </ng-template>
      <div class="list" *ngIf="activeTabIndex === TABS.IN_PROGRESS.index">

        <div *ngIf="taskMembersList?.in_progress?.length > 0">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="15px grid" class="p-0 select-card">
            <div fxFlex="33.3%" fxFlex.md="50%" fxFlex.xs="100%"
              *ngFor="let users of taskMembersList?.in_progress; let i = index">
              <div>
                <kl-selectable-user-tile [author]="users" [avatarSize]="'md'" [roundedAvatar]="true" [isSelected]="false"
                  [navigationAllowed]="true" enableElevation="true">
                </kl-selectable-user-tile>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="taskMembersList?.in_progress?.length <= 0" class="stats-no-content" fxLayout="row" fxLayoutAlign="center center">
          <kl-no-content
            faIcon="far fa-user-circle"
            text="No members"
            progressText="Hold tight, searching users..."
            [progress]="progress">
          </kl-no-content>
        </div>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="title" translate>{{ TABS.NOT_STARTED.title }}</span>
      </ng-template>
      <div class="list" *ngIf="activeTabIndex === TABS.NOT_STARTED.index">

        <div *ngIf="taskMembersList?.not_started?.length > 0">
          <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="15px grid" class="p-0 select-card">
            <div fxFlex="33.3%" fxFlex.md="50%" fxFlex.xs="100%"
              *ngFor="let users of taskMembersList?.not_started; let i = index">
              <div>
                <kl-selectable-user-tile [author]="users" [avatarSize]="'md'" [roundedAvatar]="true" [isSelected]="false"
                  [navigationAllowed]="true" enableElevation="true">
                </kl-selectable-user-tile>
              </div>
            </div>
          </div>

        </div>

        <div *ngIf="taskMembersList?.not_started?.length <= 0" class="stats-no-content" fxLayout="row" fxLayoutAlign="center center">
          <kl-no-content
            faIcon="far fa-user-circle"
            text="No members"
            progressText="Hold tight, searching users..."
            [progress]="progress">
          </kl-no-content>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
