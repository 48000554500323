<mat-card class="log-tile">
  <div fxLayoutGap="5px" fxLayoutAlign="start center">
    <div class="status-circle"
      [ngClass]="{'success': smsLogs?.deliveryStatus === smsLogsStatus?.SUCCESS,
        'pending': smsLogs?.deliveryStatus === smsLogsStatus?.PENDING,
        'failure': smsLogs?.deliveryStatus === smsLogsStatus?.FAILED}">
      </div>
    <div class="state">{{smsLogs?.mobileNo}}</div>
  </div>
  <div class="sms-holder-name pb-2 mb-2">{{smsLogs?.firstName}}</div>
  <div>
    {{smsLogs?.deliveryStatus | titlecase | translate}}
  </div>
</mat-card>
