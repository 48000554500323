import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  StringAnyMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { PageActions } from '../../constants';
import { KalgudiPageMembersDialogService } from '../../modules/program-members/services/kalgudi-page-members-dialog.service';
import { KalgudiPageMembersService } from '../../services/kalgudi-page-member.service';
import { KalgudiPageService } from '../../services/kalgudi-page.service';
import { ProgramStateService } from '../../services/program-state.service';
import { ApiPageMemberAddResponseData } from '../../typings';
import { KalgudiProgramManageUsers } from './kalgudi-program-manage-users';

@Directive()
export abstract class KalgudiProgramManageMembers extends KalgudiProgramManageUsers {

  private pageMembersDialogService: KalgudiPageMembersDialogService;

  constructor(
    protected kalgudiProgram: KalgudiPageService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected programState: ProgramStateService,
    protected manageUsersService: KalgudiPageMembersService,
    protected usersPickerService: KalgudiUsersPickerService,
    protected injector: Injector,
  ) {

    super(kalgudiProgram, notification, util, programState);

    this.pageMembersDialogService = this.injector.get(KalgudiPageMembersDialogService);

    /**
     * On `ADD_CONTRIBUTOR` state action changed
     */
    this.programState.action$
      .pipe(
        filter(action => action.type === PageActions.ADD_MEMBER),

        takeUntil(this.destroyed$),
      )
      .subscribe(res => this.add(true, this.pageId));

    // Initialize member
    this.initMembersManagement();
  }



  protected searchApi(searchKeyword: string, offset: number, limit: number, extraParams?: StringAnyMap): Observable<KalgudiStreamData> {
    return this.manageUsersService.getFollowers(this.pageId, offset, limit)
      .pipe(
        tap(res => this.programState.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATED, res.count))
      );
  }

  protected addApi(selectedUsers: KalgudiUsersMap, selectedUsersList: KalgudiUserBasicDetails[]): Observable<ApiPageMemberAddResponseData> {
    return this.manageUsersService.addFollowers(this.pageId, selectedUsersList);
  }

  /**
   * Calls an API to remove the contributor
   * @param user contributor details
   * @param entityId ProgramId
   */
  protected removeApi(user: KalgudiUserBasicDetails, entityId?: string): Observable<ApiPageMemberAddResponseData> {

    const userProfileKey = user.profileKey;

    return this.manageUsersService.deleteFollower(this.pageId, [ userProfileKey ]);
  }

  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.pageMembersDialogService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.pageMembersDialogService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

}
