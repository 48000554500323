import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

@Component({
  selector: 'kl-task-view-tile',
  templateUrl: './task-view-tile.component.html',
  styleUrls: ['./task-view-tile.component.scss'],
  animations: kalgudiAnimations,
})
export class TaskViewTileComponent implements OnInit {

  @Input()
  taskSubmissionDetails: any;

  @Input()
  animationDelay = 0;

  tileAnimationState: 'init' | 'deleted';

  constructor() { }

  ngOnInit() {
    this.tileAnimationState = 'init';
  }

}
