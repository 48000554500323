<div id="kalgudi-confirm-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">

  <!-- dialog header -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!-- /dialog header -->

  <!--dialog content-->
  <div class="dialog-content">

    <div class="audio-attachment-wrapper">
      <kl-audio-attachmnet-form-control
        [columnButton]="true"
        [isFpoRobocall]="data?.isFpoRobocall"
        label="Start recording"
        [url]="data?.data?.url"
        [formControl]="audioAttachment"
        [s3Category]="data?.data?.s3Category">
      </kl-audio-attachmnet-form-control>
    </div>
    <!-- buttons -->
    <div class="pt-0 submit-button" fxLayout="row" (clicked)="ok()" fxLayoutAlign="end end" fxLayoutGap="10px" *ngIf="audioAttachment?.value?.url">
      <kl-flat-button
        type="button"
        label="Submit"
        (clicked)="ok()">
      </kl-flat-button>
    </div>

  </div>
  <!-- /dialog content -->
</div>
