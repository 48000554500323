<div class="users-search-stream" *ngIf="(paginator$ | async) as paginator">
  <div class="mb-3" fxLayoutAlign="center center">
    <kl-search class="search w-100"
      [placeHolder]="'Type and hit enter to search survey members' | translate"
      (search)="search();"
      [searchForm]="searchForm">
    </kl-search>
  </div>

  <!-- User search Stream Starts -->
  <div class="mt-4 mb-4 search-stream overflow-auto">
    <ng-container *ngIf="stream$ | async as stream">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="pr-2 select-card">
        <div class="mb-3" fxFlex="49%" fxFlex.xs="100%"
          *ngFor="let user of stream; let i = index">
          <div [@floatTop]="{ value: '', params: {delay: (i % maxPageLimit) * 10} }" (click)="toggleUserSelection(user)"
            [ngClass]="{'selected': !!(selectedUsersMap[user?.profileKey])}">
            <kl-selectable-user-tile
              [author]="user"
              [avatarSize]="'md'"
              [isSelected]="!!(selectedUsersMap[user?.profileKey])">
            </kl-selectable-user-tile>
          </div>
        </div>
      </div>

      <!-- No content -->
      <div class="p-2">
        <ng-container *ngIf="!stream || stream?.length <= 0">
          <kl-no-content
            faIcon="far fa-user-circle"
            text="Type and hit enter to search users"
            progressText="Hold tight, searching users..."
            [progress]="paginator?.loading">
          </kl-no-content>
        </ng-container>
      </div>

      <!-- / No content -->

      <!-- Load more search stream starts-->
      <ng-container *ngIf="stream.length > 0">
        <kl-load-more noMoreText="No more search" [progress]="paginator?.loading" [hasMoreItems]="paginator?.hasItems"
          (clicked)="nextPage()">>
        </kl-load-more>
      </ng-container>
      <!-- Load more search stream ends-->
    </ng-container>
  </div>
  <!-- User search Stream End -->
</div>
