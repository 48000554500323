import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { TaskCreationDialogComponent } from '../components/task-creation-dialog/task-creation-dialog.component';
import {
  TaskCreationMobileDialogComponent,
} from '../components/task-creation-mobile-dialog/task-creation-mobile-dialog.component';

@Injectable()
export class KalgudiTaskCreationDialogService {
  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
  ) { }

  /**
   * Opens project creation dialog
   */
  openTaskCreationDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
    ? this.kalgudiDialogService.openMobileDialog(TaskCreationMobileDialogComponent, details)
    : this.kalgudiDialogService.openDialog(TaskCreationDialogComponent, details, config);
  }
}
