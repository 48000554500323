import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-tabs',
  templateUrl: './mobile-tabs.component.html',
  styleUrls: ['./mobile-tabs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MobileTabsComponent implements OnInit {

  @Input()
  color: 'primary' | 'accent' | 'warn';

  @Input()
  menu: UIMenu;

  @Output()
  clicked = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  tabClicked(): void {
    this.clicked.emit();
  }

}
