<main id="all-projects">
  <mat-card class="my-3">
    <div fxLayout fxLayout.xs="column">

      <div fxFlex="70%">
        <!-- Header -->
        <header class="separator-horizontal pb-3">
          <kl-project-dashboard-header [projectCategories]="projectCategories"></kl-project-dashboard-header>
        </header>
        <!-- /Header -->

        <div class="my-3">
          <kl-all-projects-stats [stats]="projectsStats?.projectType" [chartData]="projectsStats?.projects" [projectCategories]="projectCategories"></kl-all-projects-stats>
        </div>

        <div fxLayout class="" fxLayoutGap="35px" fxLayout.xs="column" fxLayoutGap.xs="20px">
          <div class="stats p-3" fxFlex>
            <kl-project-task-stats [taskStatsDetails]="projectsStats?.tasks" taskStatsDirection="vertical"></kl-project-task-stats>
          </div>
          <div class="stats p-3" fxFlex>
            <kl-project-members-stats [memberStatsDetails]="projectsStats?.members" memberStatsDirection="vertical"></kl-project-members-stats>
          </div>
        </div>
      </div>

      <div fxFlex="3%" class="separator-vertical mx-4"></div>

      <!-- Recent projects -->
      <div fxFlex="27%">
        <kl-recent-projects></kl-recent-projects>
      </div>
      <!--/ Recent projects -->
    </div>
  </mat-card>
</main>
