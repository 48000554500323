import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RouterEventsService } from '@kalgudi/core';
import { BreadcrumbList, PartialData } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { buffer, map, shareReplay, take, tap } from 'rxjs/operators';

import { RouteTreeNode } from './models';

/**
 * Breadcrumb services generates a route tree and breadcrumb list till the reached node.
 *
 * The service is dependent on the route configuration. It generates list of breadcrumb
 * if the breadcrumb is present in the route configuration data.
 *
 * @usageNotes
 * Ensure the route configuration is having `breadcrumb` member in the data field.
 *
 * If you want the current route should be part of the breadcrumb add the breadcrumb member
 * to the route configuration.
 * ```ts
 * const routes: Routes = [
 *   {
 *     path: 'auth',
 *     canActivate: [ KalgudiNoAuthGuard ],
 *     loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
 *   },
 *   {
 *     path: 'app',
 *     canActivate: [ KalgudiAuthGuard ],
 *     data: {
 *       breadcrumb: {
 *         title: 'Home'
 *       },
 *     },
 *   }
 * ]
 * ```
 *
 * If you want want a route to be part of the breadcrumb simply pass the `breadcrumb: false`
 * in the route configuration data.
 * ``` ts
 * const routes: Routes = [
 *   {
 *     path: 'auth',
 *     canActivate: [ KalgudiNoAuthGuard ],
 *     loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
 *   },
 *   {
 *     path: 'app',
 *     canActivate: [ KalgudiAuthGuard ],
 *     data: {
 *       breadcrumb: {
 *         title: 'Home'
 *       },
 *     },
 *     children: [
 *       {
 *         path: 'home',
 *         data: {
 *           preload: true,
 *           breadcrumb: false,
 *         },
 *         loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
 *       },
 *     ]
 *   },
 * ];
 * ```
 *
 * @author Pankaj Prakash
 */
@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {

  private dynamicBreadcrumbSubject = new BehaviorSubject<BreadcrumbList>([]);
  private _currentBreadcrumb: BreadcrumbList;

  readonly routeTree$: Observable<RouteTreeNode[]>;
  readonly breadcrumb$: Observable<BreadcrumbList>;
  readonly dynamicBreadcrumb$: Observable<BreadcrumbList>;

  constructor(
    private routerEvents: RouterEventsService
  ) {

    this.routeTree$         = this.initRouteTree();
    this.breadcrumb$        = this.initBreadcrumb().pipe(tap(_ => this._currentBreadcrumb = _));
    this.dynamicBreadcrumb$ = this.dynamicBreadcrumbSubject.asObservable();

    // WARNING: Don't remove this line. An initial subscription is required so that the
    // the observable `breadcrumb$` always has a value in the stream after the subscription.
    // This is added to tackle the issue when the value in the observable is emitted prior to the
    // subscription.
    this.breadcrumb$.subscribe().unsubscribe();
  }

  get currentBreadcrumb(): BreadcrumbList {
    return this._currentBreadcrumb;
  }

  /**
   * Updates a dynamic breadcrumb list
   */
  updateDynamicBreadcrumb(breadcrumb: BreadcrumbList): void {
    this.dynamicBreadcrumbSubject.next(breadcrumb);
  }

  /**
   * Updates a dynamic breadcrumb params list
   */
  updateDynamicBreadcrumbParams(params: PartialData): void {

    this.breadcrumb$
      .pipe(take(1))
      .subscribe(breadcrumb => {

        const keys = Object.keys(params);

        breadcrumb.forEach(b => {
          keys.forEach(k => b.title = b.title ? b.title.replace(`:${k}`, params[k]) : b.title)
        });
      });
  }

  /**
   * Generates a route tree from the route configuration. Where in the route
   * tree list the first element is always the root node followed by the last element
   * as the leaf node in the route tree.
   *
   */
  private initRouteTree(): Observable<RouteTreeNode[]> {

    return this.routerEvents.childActivationEnd$
      .pipe(
        // Collect all events till the `navigationEnd$` event is emitted
        buffer(this.routerEvents.navigationEnd$),

        // Generate a route tree from the root node in the route
        map(([routerEvent]) => this.generateRouteTree(routerEvent.snapshot.root)),

        // Cache the latest value in the stream
        shareReplay(1),
      );
  }

  /**
   * Constructs a breadcrumb list from the route tree.
   *
   * Returns the array list of breadcrumb.
   */
  private initBreadcrumb(): Observable<BreadcrumbList> {

    return this.routeTree$
      .pipe(
        map(node =>
          node.filter(n => n.data.breadcrumb)
            .map(n => {

              return {
                title: n.data.breadcrumb.title || n.data.title,
                routerLink: n.routerLink,
              };
            })
        ),

        // Cache the latest value in the stream
        shareReplay(1),
      );
  }

  /**
   * Generates the route tree from the current route tree node.
   * Returns an array of routes where the 0th index is the root node.
   */
  private generateRouteTree(route: ActivatedRouteSnapshot): RouteTreeNode[] {

    // Final route tree
    const routeTree: RouteTreeNode[] = [];

    // Current route tree node
    let node: RouteTreeNode = {
      url: '',
      data: {},
      routerLink: '',
    };

    // Store the list of urls in the current route tree
    const urlList: string[] = [];

    // Loop till the route has got a child
    while (route) {

      // No need to merge data
      if (route.url && route.url.length > 0) {

        // Append the current route path to the urls list
        urlList.push(route.url[0].path);

        node = {
          url: route.url[0].path,
          data: route.data,
          routerLink: `/${urlList.join('/')}`,
        };


        routeTree.push(node);
      } else {

        // To ensure routes with empty path does not gets into the route tree
        // Merge with previous data
        const previousData = node.data;
        const newData = route.data;
        node.data = { ...previousData, ...newData };
      }

      route = route.firstChild;
    }

    // Return the final route tree
    return routeTree;
  }
}
