import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnChanges,
  OnInit,
  Provider,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { of } from 'rxjs';

import { KalgudiProductAutocomplete } from '../../../classes/kalgudi-product-autocomplete';


// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'kl-product-autocomplete',
  templateUrl: './product-autocomplete.component.html',
  styleUrls: ['./product-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})
export class ProductAutocompleteComponent extends KalgudiProductAutocomplete implements OnInit, OnChanges {


  constructor(
    protected injector: Injector,
    protected fb: FormBuilder) {
    super(injector, fb)
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.previousStoreType && this.storeType && (this.previousStoreType !== this.storeType)) {
      this.predictions$ = of([])
      this.initAutocomplete();
    }

    this.previousStoreType = this.storeType;

  }
}
