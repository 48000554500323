import { Component, Injector, OnInit } from '@angular/core';

import { EditSellerDetails } from './edit-seller-details';

@Component({
  selector: 'kl-seller-details-edit',
  templateUrl: './seller-details-edit.component.html',
  styleUrls: ['./seller-details-edit.component.scss']
})
export class SellerDetailsEditComponent extends EditSellerDetails implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.sellerConfigDetails) {
      this.sellerConfigForm.patchValue(this.sellerConfigDetails);
    }
  }

  onDestroyed(): void {}

}
