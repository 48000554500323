<main id="output-store-production-details" class="position-relative container">
  <!-- Product details icon -->
  <section class="position-absolute header" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <img class="rounded-circle icons" src="assets/images/production.png">
    <h4 class="nav-title mb-15">Production details</h4>
  </section>

  <!-- Product details section -->
  <div class="details-info position-relative" fxLayout="row" fxLayoutGap="25px" fxLayout.xs="column">

    <div class="left-border mr-30" fxHide.xs></div>

    <!-- Supplier details section -->
    <!-- Web view-->
    <section fxHide.xs fxShow.gt-xs fxFlex="20%" class="common-details position-relative" fxLayout="column" fxLayoutAlign="start start" [ngClass]="{'connection-details position-relative': !productionDetails?.length}">
      <div class="bg-white w-100 supplier-heading" fxLayout="row" fxLayoutAlign="center center">
        <span>Supplier</span>
      </div>
      <div class="supplier w-100" fxLayout="row wrap" fxLayoutAlign="start start">
        <ng-container *ngFor="let supplier of supplierList; let last= last">
          <mat-card fxLayout="column" fxLayoutGap="4px" class="w-100 rounded-0 supplier-details shadow-none bg-white cursor-pointer"
            [ngClass]="{'border-bottom-0': last}" (click)="onSupplierSelection(supplier)">
            <div fxLayout="row" fxLayoutGap="8px">
              <div class="position-relative">
                <img fxFlex="25px" class="rounded-circle" [src]="supplier?.farmerProfilePicUrl" alt="farmer-logo" height="25" width="25" (error)="img.src = defaultProfilePic" #img>
                <div class="selected-supplier rounded-circle" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedSupplier === supplier?.farmerProfileKey">
                  <mat-icon class="selected-icon" fxLayout="row" fxLayoutAlign="center center">
                    done
                  </mat-icon>
                </div>
              </div>
              <div fxFlex="calc(100% - 35px)" fxLayoutAlign="start center">
                <div [matTooltip]="supplier?.farmerName" matTooltipPosition="right" class="title text-truncate">
                  {{supplier?.farmerName}}
                </div>
              </div>
            </div>
            <div fxLayout="row">
              <span>
                <img class="address-icon" src="assets/svgs/location.svg" width="13" height="13" alt="location-icon">
              </span>
              <span fxLayout="row" fxLayoutAlign="start center" [matTooltip]="supplier?.farmerLocation"
                matTooltipPosition="right" class="supplier-location d-block text-truncate text-secondary">
                {{supplier?.farmerLocation}}
              </span>
            </div>
          </mat-card>
          <div class="position-absolute more-supplier" *ngIf="!last && (supplierList.length > 3)"></div>
        </ng-container>
      </div>
    </section>
    <!-- Web view-->

    <!-- Mobile view -->
    <section fxHide.gt-xs fxShow.xs class="mt-3 position-relative common-details">
      <mat-accordion>
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
          <mat-expansion-panel-header [ngClass]="{'connection-details-mobile position: relative': !productionDetails?.length}" >
            <mat-panel-title class="panel-title">
              {{supplierName}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- <div class="circle rounded-circle ml-3 mt-1" fxLayoutAlign="end end"></div> -->
          <div class="supplier w-100" fxLayout="row wrap" fxLayoutAlign="start start">
            <ng-container *ngFor="let supplier of supplierList; let last= last">
              <mat-card fxLayout="column" fxLayoutGap="4px" class="w-100 rounded-0 supplier-details bg-white shadow-none cursor-pointer"
                [ngClass]="{'border-bottom-0': last}" (click)="onSupplierSelection(supplier)">
                <div fxLayout="row" fxLayoutGap="8px">
                  <div class="position-relative">
                    <img fxFlex="25px" class="rounded-circle" [src]="supplier?.farmerProfilePicUrl" alt="farmer-logo" height="25" width="25" (error)="img.src = defaultProfilePic" #img>
                    <div class="selected-supplier rounded-circle" fxLayout="row" fxLayoutAlign="center center" *ngIf="selectedSupplier === supplier?.farmerProfileKey">
                      <mat-icon class="selected-icon" fxLayout="row" fxLayoutAlign="center center">
                        done
                      </mat-icon>
                    </div>
                  </div>
                  <div fxFlex="calc(100% - 35px)" fxLayoutAlign="start center">
                    <div [matTooltip]="supplier?.farmerName" matTooltipPosition="right" class="title text-truncate">
                      {{supplier?.farmerName}}
                    </div>
                  </div>
                </div>
                <div fxLayout="row">
                  <span>
                    <img class="address-icon" src="assets/svgs/location.svg" width="13" height="13" alt="location-icon">
                  </span>
                  <span fxLayout="row" fxLayoutAlign="start center" [matTooltip]="supplier?.farmerLocation"
                    matTooltipPosition="right" class="supplier-location d-block text-truncate text-secondary">
                    {{supplier?.farmerLocation}}
                  </span>
                </div>
              </mat-card>
              <div class="position-absolute more-supplier" *ngIf="!last && (supplierList.length > 3)"></div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </section>
    <!-- Mobile view -->
    <!-- Supplier details section -->

    <!-- Procurement, production and Fulfillment card details section -->
    <section fxFlex="55%" class="mt-n3" [ngClass]="{'show-details': !productionDetails?.length}">

      <ng-container first-card *ngIf="productionDetails?.length">
        <!-- list of activities -->
        <div *ngFor="let items of productionDetails; let index = index">
          <kl-traceability-activities [items]="items" [index]="index"></kl-traceability-activities>
        </div>
        <!--/ list of activities -->
      </ng-container>

      <mat-card first-card *ngIf="!productionDetails?.length" fxLayout="column" fxLayoutAlign="center center" class="no-content mt-3 position-relative">
        <!-- <div class="circle rounded-circle ml-3 mt-1" fxLayoutAlign="end end"></div> -->

        <div *ngIf="progress">
          <kl-no-content [progress]="progress" progressText="Loading activities..."></kl-no-content>
        </div>

        <div class="w-100" fxLayout="column" fxLayoutAlign="center center" *ngIf="!progress">
          <img class="image" src="assets/images/no-activities.gif" width=210 height=115>
          <span class="text-muted text">Activities yet to be published</span>
        </div>
      </mat-card>
      <!-- Procurement and production details -->
      <!-- <div *ngIf="!showDetailsCard">
        <div>
          <ng-content select="[first-card]"></ng-content>
        </div>
      </div> -->

      <!-- Fulfillment card -->
      <!-- <mat-card class="mt-3 no-content position-relative" fxLayout="column" fxLayoutGap="3px" *ngIf="showDetailsCard">
        <div class="circle rounded-circle fulfillment" fxLayoutAlign="end end"></div>

        <div>
          <ng-content select="[second-card]"></ng-content>
        </div>
      </mat-card> -->
    </section>

  </div>
</main>
