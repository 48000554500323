import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType, PortalInjector } from '@angular/cdk/portal';
import { ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';

import { KalgudiMobileDialogRef } from './classes/mobile-dialog-overlay-ref';
import {
  KalgudiMobileDialogContainerComponent,
} from './components/kalgudi-mobile-dialog-container/kalgudi-mobile-dialog-container.component';
import { KL_MOBILE_DIALOG_DATA, KL_MOBILE_DIALOG_DESC, KL_MOBILE_DIALOG_TITLE } from './config';
import { MobileDialogConfig, MobileDialogContainer } from './models';

/**
 * Default mobile dialog configuration
 */
const DEFAULT_DIALOG_CONFIG: MobileDialogConfig = {
  title: 'Kalgudi',
  backdropClass: 'kl-mobile-backdrop',
  hasBackdrop: true,
  panelClass: 'kl-mobile-dialog',
  width: '100vw',
  height: '100vh',
  data: {},
};


@Injectable()
export class KalgudiMobileDialogService {

  constructor(
    private injector: Injector,
    private overlay: Overlay,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  /**
   * Opens a mobile dialog box
   */
  open(component: ComponentType<any>, config: MobileDialogConfig = DEFAULT_DIALOG_CONFIG): KalgudiMobileDialogRef {

    // Default dialog config
    const dialogConfig = { ...DEFAULT_DIALOG_CONFIG, ...config };

    // Returns overlayRef which is a portal host
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate dialog remote control
    const dialogRef = new KalgudiMobileDialogRef(overlayRef);


    // Create new instance of injector from the custom dialog injection tokens
    const injector = this.createInjector(config, dialogRef);

    // Attach core mobile dialog component to the PortalHost
    // Returns the ComponentRef instance
    const overlayComponent = this.attachDialogToContainer(
      overlayRef,
      dialogConfig,
      dialogRef,
      injector,
      // KalgudiMobileDialogContainerComponent,
    );

    // Pass the instance of the overlay component to the remote control
    dialogRef.componentInstance = overlayComponent;


    // Attach mobile dialog methods
    this.instantiateMobileDialogMethods(overlayComponent, dialogRef);

    // Inject the dialog component to the mobile dialog container
    this.injectDialogComponent(component, injector, overlayComponent);

    // Return the newly created dialog reference to act as a remote control of the dialog
    return dialogRef;
  }

  /**
   * Creates an dialog overlay with the specified configuration.
   */
  private createOverlay(config: MobileDialogConfig): OverlayRef {

    // Override default dialog configuration
    const dialogConfig = this.getOverlayConfig(config);

    // Returns overlayRef which is a portal host
    return this.overlay.create(dialogConfig);
  }

  /**
   * Constructs the mobile dialog overlay configuration.
   */
  private getOverlayConfig(dialogConfig: MobileDialogConfig): OverlayConfig {

    // Position the dialog horizontally and vertically center
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    // Block the scroll
    const scrollStrategy = this.overlay.scrollStrategies.block();

    return new OverlayConfig({
      ...dialogConfig,
      positionStrategy,
      scrollStrategy
    });
  }

  /**
   * Creates custom dialog injection tokens.
   *
   * @param config Dialog configuration
   * @param dialogRef OverlayRef
   */
  private createInjector(config: MobileDialogConfig, dialogRef: KalgudiMobileDialogRef): PortalInjector {

    // Instantiate a new WeakMap for our custom injection tokens
    const injectionTokens = new WeakMap();

    // Set custom injection tokens
    injectionTokens.set(KalgudiMobileDialogRef, dialogRef);
    injectionTokens.set(KL_MOBILE_DIALOG_DATA,  this.getDialogData(config));
    injectionTokens.set(KL_MOBILE_DIALOG_TITLE, this.getDialogTitle(config));
    injectionTokens.set(KL_MOBILE_DIALOG_DESC,  this.getDialogDescription(config));

    // Instantiate new PortalInjector
    return new PortalInjector(this.injector, injectionTokens);
  }

  /**
   * Attaches the dialog component to the PortalHost container.
   *
   * @returns Attached ComponentRef instance
   */
  private attachDialogToContainer(
    overlayRef: OverlayRef,
    config: MobileDialogConfig,
    dialogRef: KalgudiMobileDialogRef,
    injector: PortalInjector,
  ) {

    // Create new instance of injector from the custom dialog injection tokens
    // const injector = this.createInjector(config, dialogRef);

    // Create dynamic component that can be attached to the PortalHost
    const containerPortal = new ComponentPortal(KalgudiMobileDialogContainerComponent, null, injector);

    const containerRef: ComponentRef<KalgudiMobileDialogContainerComponent> = overlayRef.attach(containerPortal);

    // Attach ComponentPortal to the PortalHost
    return containerRef.instance;
  }

  /**
   * Initializes mobile dialog methods
   */
  private instantiateMobileDialogMethods(
    component: MobileDialogContainer,
    dialogRef: KalgudiMobileDialogRef
  ): void {

    component.closeDialog = (data?: any) => dialogRef.close(data);
  }

  /**
   * Injects the dialog component to the dialog model view container.
   */
  private injectDialogComponent(
    component: ComponentType<any>,
    injector: Injector,
    overlayComponent: KalgudiMobileDialogContainerComponent
  ): void {

    // Get the component instance from the component factory
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    // ViewContainerRef of the overlay component
    const viewContainerRef = overlayComponent.contentViewContainerRef;

    // Clear the existing views
    viewContainerRef.clear();

    // Attach the component request to the view
    viewContainerRef.createComponent(componentFactory, 0, injector);
  }

  /**
   * Gets, the mobile dialog data passed by the injection component.
   * Returns the dialog data if specified by the injector otherwise empty object.
   */
  private getDialogData(config: MobileDialogConfig): any {

    // Empty object is required to ensure injector does not throws StaticInjector errors
    // As undefined cannot be injected to a component
    return config.data || {};
  }

  /**
   * Gets, the mobile dialog title passed by the injection component.
   * Returns the dialog title if specified by the injector otherwise empty string.
   */
  private getDialogTitle(config: MobileDialogConfig): string {

    // If no dialog title is passed then pass the default dialog title as empty
    return config.title || '';
  }

  /**
   * Gets, the mobile dialog description passed by the injection component.
   * Returns the dialog description if specified by the injector otherwise empty string.
   */
  private getDialogDescription(config: MobileDialogConfig): string {

    // If no dialog title is passed then pass the default dialog title as empty
    return config.dialogDescription || '';
  }
}
