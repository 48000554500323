import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiDealsWith, KalgudiUser, PartialData } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiProductApiService {

  /**
   * `v2/listing/vproducts/:productId/varieties`
   */
  private readonly API_PRODUCT = `${this.env.restBaseUrlV2}/listing/vproducts/:productId/varieties`;


  /**
   * `v2/profiles`
   */
  private readonly API_PROFILE_BASE = `${this.env.restBaseUrlV2}/profiles`;

  /**
   * `v2/profiles/crops`
   */
  private readonly API_DEALS_WITH = `${this.API_PROFILE_BASE}/:profileKey/crops`;

  private readonly API_TOP_PRODUCTS = `${this.env.domain}/data/storeProducts/TOP_BASE_PRODUCT_LIST.json`;

  /**
   * `/data/storeProducts/TRADERS_BASE_PRODUCTS.json`
   */
  private readonly API_TRADERS_BASE_PRODUCTS =`${this.env.domain}/data/storeProducts/TRADERS_BASE_PRODUCTS.json`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Calls api to get the store types
   */
  fetchProductVarieties(productId: string): Observable<any> {

    const params = {
      storeType: 'BIZ_STORE',
      baseProductId: productId
    }

    return this.httpClient.get<ApiResponseCommon>(this.API_PRODUCT.replace(':productId', productId), { params })
    .pipe(
      map(res => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi product details object type
      map(res => res.data.results)
    );
  }


  /**
   * Calls api to post the product
   */
  postDealsWith(profileKey: string, payload: KalgudiDealsWith[], extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_DEALS_WITH.replace(':profileKey', profileKey), payload, {params})
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data),

      );

  }

  getTopProducts(): Observable<any> {

    if (!this.env.production) {
      // Getting baseProducts
      if(this.env.appId === 'TRADERS_APP') {
        return this.httpClient.get<any>(this.API_TRADERS_BASE_PRODUCTS)
      } else {
        return EMPTY;
      }
    } else {

      if(this.env.appId === 'TRADERS_APP') {
        return this.httpClient.get<any>(this.API_TRADERS_BASE_PRODUCTS)

      } else
        return this.httpClient.get<any>(this.API_TOP_PRODUCTS)

        .pipe(
          // Check for API response errors
          // map(res => this.util.apiErrorHandler(res))

        );

    }

  }
}
