import { Directive, Inject, Injector } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { KalgudiAttachmentsPickerService, KalgudiImagePickerService } from '@kalgudi/common';
import { GeoLocationService, KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  AttachmentList,
  FileMimeTypes,
  KalgudiUserBasicDetails,
  LatLong,
  QuestionTypes,
  ShareQaRequest,
  ShareRequest,
  ShareVisibility,
  SocialPost,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { KALGUDI_HOME_STREAM_EVENTS } from './constants';
import { SocialDataNormalizerService } from './services/social-data-normalizer.service';


/**
 * Base class definition for all Kalgudi social modules. It defines social post
 * creation base class.
 *
 * The type `T` defines the social post request creation structure.
 * The type `R` defines the social post response after the post has been created.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSocial<T extends ShareRequest | ShareQaRequest, R extends SocialPost> extends KalgudiDestroyable {

  /**
   * Share form for social
   */
  abstract shareForm: FormGroup;

  progress = false;

  audioAttachment = new FormControl(null);

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE, FileMimeTypes.DOCUMENT, FileMimeTypes.AUDIO ];

  protected readonly DEFAULT_SHARE_VISIBILITY: ShareVisibility = 'CONNECTS';
  protected readonly DEFAULT_QUESTION_TYPE: QuestionTypes = 'disease_related';

  private geoLocationService: GeoLocationService;
  private attachmentsPickerService: KalgudiAttachmentsPickerService;

  googleLocation: LatLong;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
  ) {

    super();

    this.geoLocationService = this.injector.get(GeoLocationService);
    this.attachmentsPickerService = this.injector.get(KalgudiAttachmentsPickerService);

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, Kalgudi logged in user profile details
   */
  get basicUserDetails$(): Observable<KalgudiUserBasicDetails> {
    return this.kalgudiApp.profile$
      .pipe(

        // Take only first record
        take(1),
      );
  }

  /**
   * Share form attachments form array controls
   */
  get shareFormAttachmentsControls(): AbstractControl {
    return this.shareForm.get('lstOfAttachments');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------

  /**
   * Creates a new social post. Calls the create post Api implemented by
   * its children.
   */
  async createPost() {

    this.shareForm.value.noOfHours = this.shareForm.value.trainingHours*60 + this.shareForm.value.trainingMinutes;

    delete this.shareForm.value.trainingHours;
    delete this.shareForm.value.trainingMinutes;

    // Get share payload, to be implemented by its child
    let payload = this.getCreationPayload(this.shareForm.value);

    if (payload['filter'] && !payload['filter'].gender) {
      delete payload['filter'].gender;
    }

    // Fetches google place location
    this.googleLocation = await this.getGeoCords();

    // Attach geo location to the payload
    payload = {
      ...payload,
      geoLocation: this.googleLocation
    };

    // Reset share form
    this.shareForm.reset();

    // Disable share form
    this.shareForm.disable();

    // Turn on spinner
    // this.notifications.showSpinner(true);
    this.progress = true;

    // Call api to share post
    this.createPostApi(payload)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Turn of spinner either on success or failure
        finalize(() => {
          // this.notifications.hideSpinner();
          this.progress = false;
        })
      )
      .subscribe(
        res => this.postCreationHandler(payload, res),
        err => this.onSharePostingError(err)
      );


  }

  /**
   * Reset schedule date and time
   */
  clearDate() {
    this.shareForm.get('scheduledDate').reset();
    this.shareForm.get('scheduledTime').reset();
  }

  /**
   * Fetch google places location and crop to city and state
   *
   * @returns geolocation: { lat, long, full_location }
   */
  async getGeoCords(){
    // Fetch latest geo location details to inject into the share payload
    let geoLocation = this.geoLocationService.currentLatLong;

    if(geoLocation){
      await this.geoLocationService.getLocationPlace(geoLocation)
      .then( (res: any) => {
        if(res.results.length){
          let full_location = "";
          res.results[0].address_components.forEach( loc => {
            if(loc.types.includes('locality')) {
              full_location = loc.long_name;
            }
            if(loc.types.includes('administrative_area_level_1')) {
              full_location = full_location + ', ' + loc.long_name;
            }
          });
          geoLocation['full_location'] = full_location;
        } else {
          geoLocation = null;
        }
      })
      .catch(err => {
        geoLocation = null;
      })
    }
    return geoLocation;
  }

  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    const attachments: AttachmentList = this.shareFormAttachmentsControls.value || [];

    attachments.splice(index, 1);

    this.shareFormAttachmentsControls.patchValue(attachments);
  }

  /**
   * Removes audio attachment
   */
  removeAudioAttachment() {
    this.audioAttachment.value.url = '';
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;

  /**
   * Construct and gets, the share request payload.
   */
  protected abstract getCreationPayload(shareFormValue: any): T;

  /**
   * Event handler called after the share updated posted successfully.
   */
  protected abstract onPostCreated(payload: T, response: R): void;

  /**
   * Calls API to create/save social item.
   */
  protected abstract createPostApi(payload: T): Observable<R>;

  /**
   * Event handler for share update posting API errors.
   */
  protected onSharePostingError(err: any): void {

    this.notifications.showMessage(err.message || err.error.message);
    this.progress = false;
    this.shareForm.enable();

    // Selected gender all by default after submit the form
    this.shareForm.get('filter').get('gender').patchValue('');
  }

  /**
   * Resets kalgudi social sharing form.
   */
  protected resetForm(): void {
    this.shareForm.reset();
    this.audioAttachment.patchValue(null);
  }

  /**
   * Event handler for successful post creation Api response.
   */
  private postCreationHandler(payload: T, res: R): void {

    // Reset the share form
    this.resetForm();

    // Enable the form
    this.shareForm.enable();

    // Selected gender all by default after submit the form
    this.shareForm.get('filter').get('gender').patchValue('');

    // Normalize post author details for share update and qa
    if (res.event === KALGUDI_HOME_STREAM_EVENTS.SHARE_UPDATE || res.event === KALGUDI_HOME_STREAM_EVENTS.QUESTION || res.event === KALGUDI_HOME_STREAM_EVENTS.TRAINING) {
      this.socialDataNormalizer.mapShareUpdateQaAuthorDetails(res);
    }

    // Callback hooks for its children
    this.onPostCreated(payload, res);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
