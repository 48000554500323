<main class="common-details">

  <div class="expansion overflow-auto">
    <mat-accordion class="example-headers-align">

      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-title translate>
            Activity update
          </mat-panel-title>
        </mat-expansion-panel-header>


        <div class="w-75 mx-auto updates-form">

          <kl-task-share-update-form
            [digitalAssistance]="digitalAssistance"
            [projectId]="projectId"
            [taskId]="taskId"
            [activityTypes]="activityType?.MEMBER_UPDATES"
            [submissionDetails]="taskSubmissionDetails"
            [selectedMembersList]="selectedMembersList"
            [isBulk]="isBulk">
          </kl-task-share-update-form>
        </div>

        <!-- Updates stream -->
        <div class="updates-stream overflow-auto pb-3">
          <kl-task-updates-stream
            [taskId]="taskDetails?.taskId"
            [activityTypes]="activityType?.MEMBER_UPDATES"
            [memberRole]="taskDetails?.userRole"
            [submissionId]="submissionDetails?.activityId">
          </kl-task-updates-stream>
        </div>
        <!--/ Updates stream -->

      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title translate>
            Activity specific details
          </mat-panel-title>
        </mat-expansion-panel-header>
        <!-- Submission form -->
        <div class="overflow-auto">
          <div *ngIf="!fields" fxLayoutAlign="center center" class="pb-2 h-100 no-content">
            <kl-no-content progressText="Loading..." iconColor="rgba(0, 0, 0, 0.459)" [progress]="true">
            </kl-no-content>
          </div>

          <form autocomplete="off" class="common-info overflow-auto task-fill" [formGroup]="templateForm" *ngIf="fields" fxLayout="column" fxLayoutGap="10px">
            <formly-form [model]="model" [fields]="fields" class="details" [options]="options" [form]="templateForm"></formly-form>
          </form>

          <div class="w-100 details submit-button overflow-hidden" fxLayout fxLayoutAlign="end center">
            <kl-flat-button label="Submit" [progress]="progress"  (clicked)="saveActivityDetails()"></kl-flat-button>
          </div>
        </div>
        <!-- /Submission form -->
      </mat-expansion-panel>

    </mat-accordion>
  </div>
</main>
