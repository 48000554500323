import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiLocation, KalgudiUser, KalgudiUserBasicDetails } from '@kalgudi/types';

import { KalgudiAppService } from './kalgudi-app.service';


@Injectable({
  providedIn: 'root'
})
export class KalgudiUsersService {

  // private readonly localStorageKey = 'pf';

  // private readonly PROFILE_BASE = `${this.environment.restBaseUrl}/profiles`;

  // /**
  //  * `v1/profiles/:profileKey`
  //  */
  // private readonly USER_PROFILE = `${this.PROFILE_BASE}/:profileKey`;

  // private loggedInUserProfile: KalgudiUser;

  // private profileUpdated = new Subject<KalgudiUser>();


  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private app: KalgudiAppService,
  ) {

    // Initialize memory cache from local storage
    // this.initializeMemoryCache();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Observable that emits logged in user profile details if updated
   * while the app is running.
   */
  // get $profileUpdated(): Subject<KalgudiUser> {
  //   return this.profileUpdated;
  // }

  // /**
  //  * Current logged in user profile.
  //  */
  // get profile(): KalgudiUser {
  //   return this.loggedInUserProfile;
  // }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Current logged in user basic short profile details.
   */
  getBasicProfile(profile: KalgudiUser): KalgudiUserBasicDetails {
    return {
      firstName: profile.firstName,
      profileKey: profile.profileKey,
      profilePicURL: profile.profilePicUrl,
      profilePicUrl: profile.profilePicUrl,
      businessKey: this.getBusinessKey(profile),
      businessTypeName: this.getBusinessTypeName(profile),
      location: this.getLocationLong(profile),
      latLong: this.getLatLong(profile),
      distance: profile.distance ? profile.distance : null,
      locationDetails: this.getLocationDetails(profile),
      isMember: profile.isMember,
      userRoles: profile?.userRoles,
    };
  }

  /**
   * Checks if the given profile is of logged in user profile
   * or not.
   *
   * @returns `true` if the specified profile key matches with the
   * logged in user profile key, otherwise `false`.
   */
  isLoggedInUser(profileKey: string): boolean {

    return this.app.profileLocal.profileKey === profileKey;
  }

  /**
   * Gets, business name of the specified profile if exists.
   */
  public getBusinessName(profile: KalgudiUser): string {

    return this.hasBusinessDetailsInfo(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].businessName
      : '';
  }

  /**
   * Gets, business name of the specified profile if exists.
   */
  public getBusinessTypeId(profile: KalgudiUser): string {

    return this.hasBusinessDetailsInfo(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].businessTypeId
      : '';
  }

  /**
   * Gets, location long field of the specified profile if exists.
   */
  public getLocationLong(profile: KalgudiUser): string {

    return this.hasLocationDetails(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].locationTo.locationLong
      : '';
  }

  /**
   * Fetches logged in user profile details from the API.
   *
   * **NOTE** Must use this method only to fetch logged in user profile details.
   *
   * @param profileKey Logged in user profile key
   */
  // fetchUserProfile(profileKey: string): Observable<KalgudiUser> {

  //   // If user is logged in then verify its profile key
  //   if (this.loggedInUserProfile) {
  //     this.assertLoggedInUserProfileKey(profileKey, this.loggedInUserProfile.profileKey);
  //   }

  //   const url = this.USER_PROFILE.replace(':profileKey', profileKey);

  //   return this.httpClient.get<ApiResponseCommonV1>(url)
  //     .pipe(
  //       map(o => (this.kalgudiUserFetchHandler(profileKey, o), this.mapKalgudiUserResponse(o))),
  //       map(o => this.updateCache(o))
  //     );
  // }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Loads profile to memory cache from the local storage
   */
  // private initializeMemoryCache(): void {
  //   this.loggedInUserProfile = this.getProfileInLocalStorage();
  // }

  // /**
  //  * Gets, logged in kalgudi user profile stored in local storage.
  //  */
  // private getProfileInLocalStorage(): KalgudiUser {

  //   return this.util.getFromLocal<KalgudiUser>(this.localStorageKey, false);
  // }

  // /**
  //  * API response handler for the Kalgudi user profile API.
  //  *
  //  * On success it returns back the response returned from the API. Otherwise
  //  * throws back specific API errors.
  //  *
  //  * @param profileKey Profile key
  //  * @param res API response
  //  */
  // private kalgudiUserFetchHandler(profileKey: string, res: ApiResponseCommonV1): ApiResponseCommonV1 {

  //   // List of all profile error messages
  //   const errorMessages = {
  //     // Common error messages
  //     ...RestApiErrorMessages,

  //     // Profile specific error messages
  //     [HttpStatusCode.NOT_FOUND]: 'Unable to find profile'
  //   };

  //   // API response code is a non success response
  //   if (res.code !== HttpStatusCode.OK) {
  //     throw new ApiError(new Error(errorMessages[res.code]));
  //   }

  //   // All good, return the response back to process
  //   return res;
  // }

  // /**
  //  * Maps, the Kalgudi user profile API response to the KalgudiUser type.
  //  *
  //  * @param res Profile API response
  //  */
  // private mapKalgudiUserResponse(res: ApiResponseCommonV1): KalgudiUser {

  //   return this.util.toJson<KalgudiUser>(res.data);
  // }

  // /**
  //  * Updates the memory cache and local storage with the specified kalgudi
  //  * user profile details.
  //  *
  //  * On successful update it fire back an event `$profileUpdated`
  //  *
  //  * @param profile Updated user profile of the logged in user
  //  */
  // private updateCache(profile: KalgudiUser): KalgudiUser {

  //   // Update memory cache
  //   this.loggedInUserProfile = profile;

  //   // Update profile in local storage
  //   this.util.setToLocal(this.localStorageKey, profile, false);

  //   // Fire `profileUpdated` event with the latest profile details
  //   this.$profileUpdated.next(profile);

  //   // Return back the latest profile details for further processing
  //   return profile;
  // }


  /**
   * Gets, business key of the specified profile if exists.
   */
  private getBusinessKey(profile: KalgudiUser): string {

    return this.hasBusinessDetailsInfo(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].businessKey
      : '';
  }

  /**
   * Gets, business name of the specified profile if exists.
   */
  private getBusinessTypeName(profile: KalgudiUser): string {

    return this.hasBusinessDetailsInfo(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].businessTypeName
      : '';
  }

  /**
   * Gets, location long field of the specified profile if exists.
   */
  private getLatLong(profile: KalgudiUser): string {

    return this.hasLocationDetails(profile)
      ? `${profile.lstOfUserBusinessDetailsInfo[0].locationTo.latitude},${profile.lstOfUserBusinessDetailsInfo[0].locationTo.longitude}`
      : '';
  }

  /**
   * Checks if business details exists in the profile data or not.
   *
   * @returns `true` if business details exists in the profile otherwise `false`.
   */
  private hasBusinessDetailsInfo(profile: KalgudiUser): boolean {
    return Array.isArray(profile.lstOfUserBusinessDetailsInfo) && profile.lstOfUserBusinessDetailsInfo.length > 0;
  }

  /**
   * Checks if the location object exists in the profile data or not.
   *
   * @returns `true` if location details exists in the profile otherwise `false`.
   */
  private hasLocationDetails(profile: KalgudiUser): boolean {

    return this.hasBusinessDetailsInfo(profile) && !!(profile.lstOfUserBusinessDetailsInfo[0].locationTo);
  }

  /**
   * Asserts profile key for logged in user. Logged in user profile key must match the
   * profile key specified.
   */
  private assertLoggedInUserProfileKey(profileKey: string, loggedInUserProfileKey: string): boolean {

    if (profileKey !== loggedInUserProfileKey) {
      throw new Error('Profile key does not matches logged in user profile key.');
    }

    return true;
  }

  /**
   * Extract location from the kalgudi user profile details
   */
  private getLocationDetails(profile: KalgudiUser): KalgudiLocation {
    return this.hasBusinessDetailsInfo(profile)
      ? profile.lstOfUserBusinessDetailsInfo[0].locationTo
      : null;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
