<div fxLayout="column" class="geo-multi-loc h-100 overflow-hidden mw-100">
  <!-- Agm core map section -->
  <agm-map
    [streetViewControl]="false"
    class="geo position-relative"
    [fullscreenControl]="true"
    [zoom]="zoom"
    [zoomControlOptions]="zoomControlOptions"
    [latitude]="DEFAULT_LOCATION.latitude"
    [longitude]="DEFAULT_LOCATION.longitude"
    >
    <agm-marker
        *ngFor="let location of allLocations; let i = index"
        [latitude]="location.latitude"
        [longitude]="location.longitude">
    </agm-marker>
  </agm-map>
  <!-- / Agm core map section -->
</div>
