import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ValidateAdmins } from '@app/models';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonAppService {

  /** /v2/profiles/validate-admin */
  private readonly API_VALIDATE_ADMIN = `${this.env.restBaseUrlV2}/profiles/validate-admin`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService) { }

  /**
   * Gets validate admins
   */
  getValidateAdmins(): Observable<ValidateAdmins> {

    return this.httpClient.get<ApiResponseCommon>(this.API_VALIDATE_ADMIN)
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }
}
