<div id="kl-kalgudi-rating-form-control" fxLayout>
  <div *ngFor="let item of [1, 2, 3, 4, 5]; let j = index">
    <mat-icon class="cursor-pointer" *ngIf="!disableActions"
      [ngClass]="{'fill-star': rating?.value && (j + 1 <= rating?.value) }"
      (click)="addRating(j)">
      star
    </mat-icon>
    <mat-icon *ngIf="disableActions"
      [ngClass]="{'disable-filled-star': rating?.value && (j + 1 <= rating?.value),
      'disable-star':  !(rating?.value && (j + 1 <= rating?.value))}"
      (click)="addRating(j)">
      star
    </mat-icon>
  </div>
</div>
