import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';
import { TranslateModule } from '@ngx-translate/core';
import { KalgudiCommonModule } from '../../kalgudi-common.module';

import { KalgudiAttachUrlModule } from '../kalgudi-attach-url/kalgudi-attach-url.module';
import { KalgudiImageChipListModule } from '../kalgudi-image-chip-list/kalgudi-image-chip-list.module';
import { KalgudiImagePickerModule } from '../kalgudi-image-picker/kalgudi-image-picker.module';
import { KalgudiStoreProductsSearchModule } from '../kalgudi-store-products-search/kalgudi-store-products-search.module';
import { KalgudiNoContentModule } from './../kalgudi-no-content/kalgudi-no-content.module';
import { ChatBubbleComponent } from './chat-bubble/chat-bubble.component';
import { ChatFooterComponent } from './chat-footer/chat-footer.component';
import { ChatListComponent } from './chat-list/chat-list.component';



@NgModule({
  declarations: [ChatListComponent, ChatBubbleComponent, ChatFooterComponent],
  imports: [
    CommonModule,

    // Flex Module
    FlexLayoutModule,

    // Material modules
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,

    TranslateModule,

    // Kalgudi module
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiButtonModule,
    KalgudiStoreProductsSearchModule,
    KalgudiImageChipListModule,
    KalgudiGoogleLanguageTranslatorModule,
    KalgudiCommonModule
  ],
  exports: [
    ChatListComponent,
    ChatFooterComponent
  ]
})
export class KalgudiChatsModule { }
