import { Component, Injector, Input, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiProjectTask } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { KalgudiTaskStatus } from '../../classes/kalgudi-task-status';

@Component({
  selector: 'kl-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent extends KalgudiTaskStatus implements OnInit {

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  isBulk: boolean;

  constructor(
    protected injector: Injector,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.updateDateLocale();

    this.init();
  }

  onDestroyed(): void {}

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }
}
