import { Pipe, PipeTransform } from '@angular/core';

import { ParseDatePipe } from './parse-date.pipe';

/**
 * TimeAgoPipe transforms a string representation of date in time ago from now string.
 * Time ago is difference between current time and specified time.
 *
 * **Note:** You can also use this pipe inside script. Use the global available static
 * `transform()` function to get time ago string.
 *
 * @author Pankaj Prakash
 * @example
 * <span>{{'12/21/2018' | timeAgo}}</span>
 * let timeAgo = TimeAgoPipe.transform('12/21/2018');
 */
@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  static ONE_MIN    = 60;                           // 60 seconds in a min
  static ONE_HOUR   = TimeAgoPipe.ONE_MIN  * 60;    // 60 * 60 seconds in an hour
  static ONE_DAY    = TimeAgoPipe.ONE_HOUR * 24;    // 60 * 60 * 24 seconds in a day
  static ONE_MONTH  = TimeAgoPipe.ONE_DAY  * 30;    // 60 * 60 * 24 * 30 seconds in a month
  static ONE_YEAR   = TimeAgoPipe.ONE_DAY  * 365;   // 60 * 60 * 24 * 365 seconds in a year

  static TIME = [
    TimeAgoPipe.ONE_MIN,
    TimeAgoPipe.ONE_HOUR,
    TimeAgoPipe.ONE_DAY,
    TimeAgoPipe.ONE_MONTH,
    TimeAgoPipe.ONE_YEAR,
  ];

  static TIME_LABEL = [
    's',  // < TimeAgoPipe.ONE_MIN,
    'm',  // < TimeAgoPipe.ONE_HOUR,
    'h',  // < TimeAgoPipe.ONE_DAY,
    'd',  // < TimeAgoPipe.ONE_MONTH,
    'M',  // < TimeAgoPipe.ONE_YEAR,
    'Y',  // > TimeAgoPipe.ONE_YEAR,
  ];

  /**
   * Transforms string representation of a date to time ago string format.
   * Time ago is difference of current time and specified time.
   *
   * Note: You can use this function if needed timeAgo pipe as a function
   * within a script.
   *
   * @param value String representation of a date.
   * @returns Converted time ago string.
   */
  static transform(value: string): string {

    let timestamp: number;
    let result = value;

    try {

      // Parse Java date to Javascript object
      timestamp = ParseDatePipe.parseDate(value).getTime();

      // Time now in seconds
      const now = new Date().getTime();

      // Time difference in seconds
      const diff = Math.abs(now - timestamp) / 1000;

      // Get final time
      result = TimeAgoPipe.getTimeAgo(diff);
    } catch (e) { }


    return result;
  }


  transform(value: string, args?: any): string {
    return TimeAgoPipe.transform(value);
  }


  /**
   * Parses a string representation of Java date object to Javascript
   * date object.
   *
   * @param value String representation of Java date.
   * @returns Date object.
   */
  // static parseDate(value: string): Date {
  //   // Null checks
  //   if (!value) { return undefined; }

  //   let parsedDate = TimeAgoPipe.parseISODate(value);

  //   parsedDate = !isNaN(parsedDate.getTime()) ? parsedDate : TimeAgoPipe.parseKalgudiDate(value);

  //   return parsedDate;
  // }

  // /**
  //  * Try parsing ISO format string to Date.
  //  */
  // private static parseISODate(value: string): Date {

  //   let d: Date = null;

  //   try {
  //     d = new Date(value);
  //   } catch (e) {
  //     d = null;
  //   }

  //   return d;
  // }

  // /**
  //  * Try parsing Kalgudi format string to Date.
  //  */
  // private static parseKalgudiDate(value: string): Date {

  //   const split = value.split(' ');
  //   const date  = split[0].split('-');
  //   const time  = split[1].split(':');

  //   // Separate date and time from input string
  //   const input = {
  //     ms:   +split[2],
  //     sec:  +time[2] - 1,
  //     min:  +time[1] - 1,
  //     hr:   +time[0],
  //     day:  +date[2],
  //     mon:  +date[1] - 1,
  //     year: +date[0]
  //   };

  //   // Construct and return date
  //   let d: Date = null;

  //   try {
  //     d = new Date(Date.UTC(input.year, input.mon, input.day, input.hr, input.min, input.sec, input.ms));
  //   } catch (e) {}

  //   return d;
  // }

  private static getTimeAgo(diffInSeconds: number): string {

    let result = '';

    for (let i = TimeAgoPipe.TIME.length; i >= 0; i--) {

      if (diffInSeconds > TimeAgoPipe.TIME[i]) {
        result = `${Math.floor(diffInSeconds / TimeAgoPipe.TIME[i])}${TimeAgoPipe.TIME_LABEL[i + 1]}`;
        break;
      }
    }

    return result;
  }
}
