import { Component, Input, OnInit } from '@angular/core';
import { Attachment, StoreProductLevel2 } from '@kalgudi/types';

@Component({
  selector: 'kl-production-details',
  templateUrl: './production-details.component.html',
  styleUrls: ['./production-details.component.scss']
})
export class ProductionDetailsComponent implements OnInit {

  @Input()
  productionDetails: any;

  @Input()
  supplierDetails;

  @Input()
  productLevel3Title: string;

  @Input()
  level2ProductDetails: StoreProductLevel2;

  @Input()
  productionAttachments: Attachment[];

  traceabilityDetails = {
    "name": 'Production details',
    "image": 'assets/images/production.png',
    "navTitle": 'Activities',

    commonDetails : {
      breadCrumb: 'Supplier',
      location: 'Guntur, Andhra pradesh'
    }
  };


  constructor() { }

  ngOnInit() {
  }

}
