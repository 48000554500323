import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

import { KalgudiAddCropProduct } from '../../classes/kalgudi-add-crop-product';

@Component({
  selector: 'kl-add-crop-product',
  templateUrl: './add-crop-product.component.html',
  styleUrls: ['./add-crop-product.component.scss']
})
export class AddCropProductComponent extends KalgudiAddCropProduct implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
    ) {
    super(injector)
  }

  ngOnInit() {
    this.subscribeToValueChanges();
  }

  onDestroyed(): void {}

}
