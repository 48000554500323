import { Inject, Directive } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserAwardDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { KalgudiProfileSectionList } from '../../../classes/kalgudi-profile-section-list';
import { KalgudiProfileAwardsService } from './kalgudi-profile-awards.service';

@Directive()
export abstract class ProfileSectionAwards extends KalgudiProfileSectionList<KalgudiUserAwardDetails> {

  protected itemForm: FormGroup;
  protected existingItemFormGroup: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileAwardsService,
  ) {

    // Wake up (initialize) my parent
    super(notification, app, util);

    // Initialize item form
    this.itemForm = this.formGroup;
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Profile awards form group structure.
   *
   * **NOTE** Must define all fields of the profile list section type,
   * whether its shown in the view or not.
   */
  private get formGroup(): FormGroup {

    return new FormGroup({
      Flag: new FormControl(true),
      businessAwardId: new FormControl(''),
      description: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Implement the logic to check if the specified item in the profile section
   * list is new or existing item. An item in the list is new if it does not
   * contain any id otherwise its existing item.
   */
  protected isNewItem(item: KalgudiUserAwardDetails): boolean {

    return this.util.isNullOrEmpty(item.businessAwardId);
  }

  /**
   * Defines save new item Api.
   */
  protected saveNewItemApi(payload: KalgudiUserAwardDetails): Observable<KalgudiUserAwardDetails> {

    return this.api.saveNewAward(payload)
      .pipe(
        mapTo(payload),
      );
  }

  /**
   * Defines update award Api.
   */
  protected updateItemApi(payload: KalgudiUserAwardDetails): Observable<KalgudiUserAwardDetails> {

    return this.api.updateAward(payload)
      .pipe(
        mapTo(payload),
      );
  }

  /**
   * Deletes an item from the profile section items list.
   *
   * @param item Item to delete
   */
  protected deleteItemApi(item: KalgudiUserAwardDetails): Observable<boolean> {
    return this.api.deleteAward(item.businessAwardId);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
