<main>
  <section class="mb-3 mt-3">
    <kl-profile-logs-form
      [pageId]="pageId"
      [isAssisted]="isAssisted"
      [assistedProfileKey]="assistedProfileKey">
    </kl-profile-logs-form>
  </section>

  <section *ngIf="isMobileDevice">
    <kl-profile-logs-list [pageId]="pageId"></kl-profile-logs-list>
  </section>

    <section *ngIf="!isMobileDevice">
      <kl-profile-logs-list-table [pageId]="pageId" [profileKey]="assistedProfileKey"></kl-profile-logs-list-table>
    </section>
</main>
