import { Injectable } from '@angular/core';
import { DataStore } from '@kalgudi/core';
import { Observable } from 'rxjs';

import { LazyHttpRequest, LazyRequest, LazyRequestMap, LazyRequestModule } from './model/lazy-request.model';


@Injectable()
export class LocalHttpRequestSync {

  private static KEY = 'lsync';

  constructor(
    private dataStore: DataStore,
  ) {  }

  /**
   * Saves an http request object to the local data store.
   */
  saveHttpRequest(moduleId: string, request: LazyRequest): Observable<boolean> {

    // Get existing saved requests
    const existingRequests = this.getRequestsMap();

    // Generate request id
    const requestId = this.generateRequestId();

    // Store the current request in the object
    if (!existingRequests[moduleId]) {
      existingRequests[moduleId] = {};
    }
    existingRequests[moduleId][requestId] = request;

    // Save the updated request to the local data store
    return this.saveRequestsToLocal(existingRequests);
  }


  /**
   * Gets, saved http requests stored locally in the data store.
   * It fetched all requests of all modules stored in local data store.
   */
  getRequestsMap(): LazyRequestMap;
  /**
   * Gets, saved http requests stored locally in the data store.
   * Returns the requests specific to a module.
   */
  getRequestsMap(moduleId?: string): { [reqId: string]: LazyRequest };
  /**
   * Gets, saved http requests stored locally in the data store.
   */
  getRequestsMap(moduleId?: string): LazyRequestMap | { [reqId: string]: LazyRequest } {

    const requests = this.dataStore.getItem<LazyRequestMap>(LocalHttpRequestSync.KEY) || {};

    return moduleId ? requests[moduleId] : requests;
  }


  /**
   * Gets, an specific request based on the module and request id.
   */
  getRequest(moduleId: string, requestId: string): LazyHttpRequest {

    // Get the request module
    const reqModule = this.getRequestsMap(moduleId);

    return {
      moduleId,
      requestId,
      request: reqModule[requestId]
    };
  }

  /**
   * Returns list of all un-synced saved requests of all modules.
   */
  getRequestList(): LazyHttpRequest[];
  /**
   * Returns a list of all un-synced saved requests of an specific module.
   */
  getRequestList(moduleId?: string): LazyHttpRequest[] {

    // Gets, all requests saved in local data store
    const requests = this.getRequestsMap();

    // Map requests of a specific module
    if (moduleId) {
      return this.mapRequestsToList(moduleId, requests[moduleId]);
    }

    // Get all modules
    const modules = Object.keys(requests);

    // For each modules combine the requests
    return modules.reduce((allRequests, m) => allRequests.concat(this.mapRequestsToList(m, requests[m])), []);
  }

  /**
   * Removes a request from the local Storage
   */
  removeRequest(requestId: string, moduleId: string): Observable<boolean> {

    console.log('Deleting request from the local -> ', requestId, moduleId);

    // Get all request stored locally
    const requests = this.getRequestsMap();

    // Delete the request with the specified id
    delete requests[moduleId][requestId];

    // console.log('Requests after deletion -> ', requests);

    // Update the local requests sync object
    return this.saveRequestsToLocal(requests);
  }

  /**
   * Maps requests of a specific module to requests list
   */
  private mapRequestsToList(moduleId: string, requests: LazyRequestModule): LazyHttpRequest[] {

    // Extract all request ids in the request module
    const requestIds = Object.keys(requests);

    return requestIds.map(requestId => {
      return {
        moduleId,
        requestId,
        request: requests[requestId]
      };
    });
  }

  /**
   * Save the updated request to the local data store
   */
  private saveRequestsToLocal(requestsMap: LazyRequestMap): Observable<boolean> {
    return this.dataStore.setItem(LocalHttpRequestSync.KEY, requestsMap, true);
  }

  private generateRequestId(): string {
    return new Date().getTime().toString();
  }

}
