import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiStoreProduct } from '@kalgudi/types';

import { KalgudiCommonRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-share-products-tile',
  templateUrl: './share-products-tile.component.html',
  styleUrls: ['./share-products-tile.component.scss']
})
export class ShareProductsTileComponent implements OnInit {

  @Input()
  store = '';

  @Input()
  shareProduct: KalgudiStoreProduct;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiCommonRouteConfig,
    protected util: KalgudiUtilityService) { }

  ngOnInit() {
  }


  openStore(store: string): void {

    let productStore = '';

    if(this.shareProduct.storeType === 'SAM_CMP') {

      const url = `https://credit.samunnatimart.com/app/loan-fullview/${this.shareProduct.productId_level2}_${this.shareProduct.productId_level3}`;

      window.open(url, '_blank');

    } else {

      if(this.env.domain === 'https://core.kalgudi.com') {
        productStore = store === " " ? this.env.farmerStoreDomain : store;
      }

      this.appRouting.toStoreProductView({store: this.store || productStore, product: this.shareProduct});
    }
  }
}
