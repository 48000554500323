<mat-card class="my-3 project-list-header">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
    <!-- Content -->
    <div fxLayout="column">
      <div class="mb-2">
        <kl-breadcrumbs></kl-breadcrumbs>
      </div>
      <div fxLayout="row">
        <div [@scaleOut]>
          <svg-icon src="assets/svgs/members.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"
            class="mr-3 projects-icon"></svg-icon>
        </div>
        <div [@slideRight] class="ml-1 mr-3">
          <h3 translate>Members</h3>
        </div>
      </div>

    </div>
    <!-- /Content -->

    <!-- Header options -->
    <div fxLayout="row" fxLayout.xs="column">
      <!-- Search bar -->
      <!-- <div fxLayoutAlign="center center">
        <kl-search placeHolder="Search projects" [searchForm]="searchForm"></kl-search>
      </div> -->
      <!-- /Search bar -->

      <!-- <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center" class="ml-3">
        <div class="select-filter p-1" fxLayoutAlign="center center">
          <button mat-button (click)="openFilterSidebar()">
            <svg-icon src="assets/svgs/filter.svg" class="text-center menu-icons" [applyCss]="true"
              [svgStyle]="{ 'width.px': 24, 'height.px': 24}"></svg-icon>
              <span class="ml-2 text-muted">Filters</span>
          </button>
        </div>
      </div> -->
    </div>
    <!--/ Header options -->
  </div>
</mat-card>

