import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AvailableStoreTypeNames, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductAutoCompleteApiService {

  /**
   * `v2/search/bizstore/listing/vproducts/autocomplete`
   */
  private readonly API_PRODUCTS_AUTOCOMPLETE = `${this.env.restBaseUrlV2}/search/:storeType/listing/vproducts/autocomplete`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Gets the product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    storeType: string,
    offset: number,
    limit: number,
    filters: PartialData = {}
  ): Observable<any[]> {

    const params = {
      keyword,
      type: 'farmvproduct',
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters
    };

    const url = this.API_PRODUCTS_AUTOCOMPLETE.replace(':storeType', AvailableStoreTypeNames[storeType]);

    return this.httpClient.get<any>(url, { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data),
      );
  }

}
