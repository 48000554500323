import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { checkMobileDevice } from '@kalgudi/core';
import { BaseProductTypes } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { KalgudiCreateCropCalendar } from '../../classes/crop-calender-form';

@Component({
  selector: 'kl-crop-calender',
  templateUrl: './crop-calender.component.html',
  styleUrls: ['./crop-calender.component.scss']
})
export class CropCalenderComponent extends KalgudiCreateCropCalendar implements OnInit, OnChanges {

  @Input()
  selectedExistingProject: any;

  productTypes = BaseProductTypes;
  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.initProjectForm();

    this.updateDateLocale();

    if(checkMobileDevice()) {
      this.isMobileDevice = true;
    }
  }

  ngOnChanges() {

    if(this.selectedExistingProject) {

      this.projectTypeForm.get('crop').patchValue(this.selectedExistingProject.crop);
      this.projectTypeForm.get('variety').patchValue(this.selectedExistingProject.variety)

      if(this.cropField) {
        this.cropField.patchValue(this.selectedExistingProject.crop);
      }

      if(this.varietyField) {
        this.varietyField.patchValue(this.selectedExistingProject.variety)
      }
    }
  }

  protected onDestroyed(): void { }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
