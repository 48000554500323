<section class="container task-fullview-page">

  <section class="py-3 task-list-header sticky-top">
    <mat-card class="tasks ">
      <div class="mb-2">
        <kl-breadcrumbs></kl-breadcrumbs>
      </div>

      <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap.xs="16px">
        <!-- Content -->
        <div class="w-100" fxFlex="45%" fxLayout="row" fxLayoutGap="0" fxLayout.xs="column" fxLayoutGap.xs="16px">
          <div fxLayout fxFlex="100%" fxLayout.xs="column" class="w-100">
            <div fxLayout  fxFlex="100%" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" class="w-100">
              <div class="mr-3" [@scaleOut] fxFlex="10%">
                <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}"
                  [applyCss]="true" class="mr-3 projects-icon"></svg-icon>
              </div>
              <div [@slideRight] class="ml-1 mr-3" fxFlex="80%">
                <div>
                  <h4 class="text-capitalize text-truncate w-100">{{ taskDetails?.title }}</h4>
                  <!-- <p class="projectDetails mb-0 mt-n1">
                    {{ taskDetails?.description }}
                  </p> -->
                </div>

              </div>
            </div>

          </div>

        </div>
        <!-- /Content -->

        <kl-task-actions
          [taskDetails]="taskDetails"
          (addTaskMembers)="showAddTaskMembersDialog(taskDetails?.taskId, taskDetails?.projectId)"
          (taskUpdate)="updateTask(taskDetails?.taskId)"
          (taskDelete)="deleteTask(taskDetails?.taskId)"
          (stateUpdate)="updateState()">
        </kl-task-actions>

      </div>
    </mat-card>

  </section>

  <mat-card class="p-0">
    <mat-tab-group [(selectedIndex)]="activeTabIndex" mat-stretch-tabs>

      <!-- Task info tab -->
      <mat-tab [label]="TABS?.INFO?.title | translate">
        <div class="task-fill overflow-hidden px-3 pb-3" *ngIf="activeTabIndex === TABS?.INFO?.index">
          <kl-task-info [taskDetails]="taskDetails"></kl-task-info>
        </div>
      </mat-tab>
      <!--/ Task info tab -->

      <!-- Updates tab -->
      <mat-tab [label]="TABS?.UPDATES?.title | translate">
        <ng-container *ngIf="activeTabIndex === TABS?.UPDATES?.index">
          <div class="overflow-hidden px-3 pb-3" fxLayout="column" fxLayoutAlign="space-between">

            <!-- Updates stream -->
            <div class="updates-stream overflow-auto">
              <kl-task-updates-stream [taskId]="taskDetails?.taskId" ></kl-task-updates-stream>
            </div>
            <!--/ Updates stream -->

            <!-- Comment section -->
            <div fxFlex fxHide>
              <kl-task-share-update-form [projectId]="projectId" [taskId]="taskDetails?.taskId" (postCreated)="onPostCreated($event)"></kl-task-share-update-form>
            </div>
            <!-- / Comment section -->

          </div>
        </ng-container>
      </mat-tab>
      <!--/ Updates tab -->

      <!-- Details tab -->
      <mat-tab [label]="TABS?.DETAILS?.title | translate"
        *ngIf="taskDetails?.userRole === projectRoles?.MEMBER">
        <div class="task-fill overflow-auto px-3 pb-3" *ngIf="activeTabIndex === TABS?.DETAILS?.index">
          <kl-task-fill
            [projectId]="projectId"
            [taskId]="taskDetails?.taskId"
            [templateId]="taskDetails?.templateId"
            [taskDetails]="taskDetails"
            [submissionDetails]="submissionDetails">
          </kl-task-fill>
        </div>
      </mat-tab>
      <!--/ Details tab -->

      <!-- Submissions tab -->
      <mat-tab [label]="TABS?.SUBMISSIONS?.title | translate" *ngIf="taskDetails?.userRole === projectRoles?.ADMIN || taskDetails?.userRole === projectRoles?.CONTRIBUTOR">
        <div class="px-3 pb-3" *ngIf="activeTabIndex === TABS?.SUBMISSIONS?.index">
          <kl-task-submissions [taskId]="taskDetails?.taskId" [projectId]="projectId" [taskDetails]="taskDetails"></kl-task-submissions>
        </div>
      </mat-tab>
      <!--/ Submissions tab -->


    </mat-tab-group>
  </mat-card>

</section>
