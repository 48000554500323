import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiLiveStockList } from '../../classes/kalgudi-live-stock-list';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'kl-live-stock-list',
  templateUrl: './live-stock-list.component.html',
  styleUrls: ['./live-stock-list.component.scss']
})
export class LiveStockListComponent extends KalgudiLiveStockList implements OnInit {

  isPublicPage: boolean = false;

  constructor(protected injector: Injector,
    public router: Router
    ) {
    super(injector)
  }

  ngOnInit(): void {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
    this.getLiveStockList();
  }

  onDestroyed(): void {}

}
