<div class="farmer-crops h-100">

  <div class="farmer-crops-list h-100">
    <div *ngIf="!progress" class="h-100">
      <kl-cooperative-society-crops-list
        [products]="products"
        [profile]="profile"
        (editCrop)="editProductsDialog($event)"
        (deleteCrop)="deleteCrop($event)"
        [pageId]="pageId"
        [isAssisted]="isAssisted"
        [assistedProfileKey]="assistedProfileKey"
        (addProductDialog)="showAddProductsDialog()"
        [showViewCatalogue]="showViewCatalogue">
      </kl-cooperative-society-crops-list>
    </div>

    <ng-container *ngIf="progress">
      <mat-card fxLayout="column" class="crops-tile">
        <kl-no-content
          iconSize="md"
          progressText="Loading crops..."
          [progress]="progress">
        </kl-no-content>

      </mat-card>
    </ng-container>

  </div>
</div>
