import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProject, PartialData, SelectableUIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../config';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiProjectCreationService } from '../../project-creation/services/kalgudi-project-creation.service';
import { KalgudiProjectListService } from '../services/kalgudi-project-list.service';
import { KalgudiProjectsListActionsService } from '../services/kalgudi-projects-list-actions.service';

@Directive()
export abstract class KalgudiProjectsListGrid extends KalgudiSearchStream<KalgudiProject> {

  @Input()
  projectCategories: SelectableUIMenu[];

  projectsList: KalgudiProject[];

  readonly maxPageLimit = 9;

  private projectListService: KalgudiProjectListService;
  private projectCreationService: KalgudiProjectCreationService;
  private projectStateService: KalgudiProjectStateService;
  private projectService: KalgudiProjectService;

  private projectListActionService: KalgudiProjectsListActionsService;
  private projectsRouting: KalgudiProjectRouteConfig;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.projectListService       = this.injector.get<KalgudiProjectListService>(KalgudiProjectListService);
    this.projectCreationService   = this.injector.get<KalgudiProjectCreationService>(KalgudiProjectCreationService);
    this.projectStateService      = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.projectListActionService = this.injector.get<KalgudiProjectsListActionsService>(KalgudiProjectsListActionsService);
    this.projectService           = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.projectsRouting          = this.injector.get<KalgudiProjectRouteConfig>(KL_ROUTE_CONFIG);

    this.pageLimit = this.maxPageLimit;

    this.projectStateService.reloadProjects$
      .pipe(
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.resetStream()
      );

    this.projectStateService.reloadTasks$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.resetStream()
      );
  }

  /**
   * Opens the project creation dialog
   */
  createProject(projectId = '') {

    this.showCreateProjectDialog(projectId)
      .subscribe(
        res => this.onProjectCreated(res),
        err => {
          this.notification.showMessage('Unable to create project, please try again later!');
        }
      );
  }

  /**
   * Opens the project creation dialog box
   */
  showCreateProjectDialog(projectId = '') {

    return this.projectCreationService.openCreateProjectDialog(this.projectCategories, projectId, this.filters)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  deleteProject(projectId: string) {
    this.projectListActionService.deleteProject(projectId, this.filters)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res =>  this.projectDeleteHandler(res),
        err =>  this.projectDeleteErrHandler(err)
      );
  }

  showUpdateProject(projectId: string) {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories, projectId, this.filters)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.notification.showMessage('Project updated');
          this.projectStateService.reloadProjects();
        }
      );
  }

  /**
   * Open task creation dialog
   * @param projectId
   */
  openTaskCreationDialog(projectId: string, filters: PartialData = {}) {

    this.notification.showSpinner(true);

    this.projectService.getProject(projectId, filters)
      .pipe(

        // Hide the spinner
        tap(_ => this.notification.hideSpinner()),

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        switchMap(_ => this.projectListActionService.openTaskCreationDialog(projectId, filters))
      )
      .subscribe(
        (res) => {
          this.notification.showMessage('Task created successfully');
          setTimeout(() => {
            this.projectStateService.reloadTasks();
            this.updateProjectDetails(projectId);
          }, 1000);
        }
      );
  }

  /**
   * Opens the add members dialog
   */
  showAddMembersDialog(projectId: string) {

    this.projectListActionService.showAddMembersDialog(projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.projectStateService.reloadProjects();
        }
      );
  }

  /**
   * Child class should implement the filters
   */
  protected abstract get filters(): PartialData;

  /**
   * Event handler for successful project creation
   */
  protected onProjectCreated(res) {
    this.notification.showMessage('Project created');
    // this.projectStateService.reloadProjects();
    this.projectsRouting.toProject( { projectId: res.id }  , {type: 'GRID'});

  }


  /**
   * Event handler for successful project deletion
   */
  protected projectDeleteHandler(res) {
    this.projectStateService.reloadProjects();
    this.notification.showMessage('Project deleted successfully');
  }

  /**
   * Event handler for failed to delete project
   */
  protected projectDeleteErrHandler(err: Error) {
    this.notification.showMessage('Failed to delete a project');
  }

  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (searchKeyword) {
      return this.projectListService.searchProjects(searchKeyword, offset, limit, this.filters);
    } else {

      return this.projectListService.getProjects(offset, limit, this.filters);
    }
  }

  /**
   * Updates the project details with latest data
   */
  private updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }


}
