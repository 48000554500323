import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AgGridFormatter } from '@kalgudi/types';

@Injectable()
export class CurrencyFormatter implements AgGridFormatter {

  constructor(
    private currency: CurrencyPipe,
  ) {
  }

  format(value: any, format: string) {
    return this.currency.transform(value, format);
  }
}
