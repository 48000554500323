import { Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUserFarmerMembershipDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

import {
  AddFarmerMembershipDialogComponent,
} from '../components/add-farmer-membership-dialog/add-farmer-membership-dialog.component';
import {
  AddFarmerMembershipMobileDialogComponent,
} from '../components/add-farmer-membership-mobile-dialog/add-farmer-membership-mobile-dialog.component';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiFarmerMembershipsList extends KalgudiDestroyable {

  @Input()
  lstOfMemberships: KalgudiUserFarmerMembershipDetails[];

  editable: boolean;

  private kalgudiDialogService: KalgudiDialogsService;
  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector) {
    super()

    this.kalgudiDialogService         = this.injector.get(KalgudiDialogsService);
    this.kalgudiFarmerProfileService  = this.injector.get(KalgudiFarmerProfileService);
    this.util                         = this.injector.get(KalgudiUtilityService);
    this.notification                 = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileService               = this.injector.get(KalgudiProfileService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Opens memberships dialog
   */
  openMembershipsDialog(membershipDetails?: KalgudiUserFarmerMembershipDetails) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add memberships',
      acceptButtonTitle: 'Add',
      rejectButtonTitle: 'Cancel',
      data: {
        membershipDetails: membershipDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.showMembershipsDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Shows confirmation dialog and calls api to delete the membership
   */
  deleteMembership(membershipId: string) {

    this.showDeleteConfirmationDialog()
      .pipe(

        tap(res => this.notification.showSpinner()),

        switchMap(res => this.kalgudiFarmerProfileService.deleteMembership(membershipId)),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.notification.showMessage('Deleted successfully'),
        err => this.util.errorHandler(err)
      );

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }


  /**
   * Opens web and mobile membership dialogs
   */
  private showMembershipsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.kalgudiDialogService.openMobileDialog(AddFarmerMembershipMobileDialogComponent, dialogConfig);
    } else {

      return this.kalgudiDialogService.openDialog(AddFarmerMembershipDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
