<div>
  <div>
    <kl-connects-picker
      [multiSelect]="multiSelect"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-connects-picker>
  </div>

  <div class="py-1 px-3 w-100 buttons fixed-bottom" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <kl-link-button label="Cancel" buttonColor="warn" (clicked)="cancel();"></kl-link-button>
    <kl-link-button label="Select user" buttonColor="primary" (clicked)="ok();"></kl-link-button>
  </div>
</div>
