import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { IPageStateManager, NO_PAGE_ACTIONS } from '../../interfaces/page-state-manager';


/**
 * Default page state manager class
 */
@Injectable({
  providedIn: 'root'
})
export class DefaultPageStateManager implements IPageStateManager<any, NO_PAGE_ACTIONS> {

  readonly data$: Observable<any>;
  readonly id$: Observable<string>;

  readonly action$: Observable<{ type: NO_PAGE_ACTIONS, payload?: any }>;

  private pageDataSubject = new BehaviorSubject<any>(null);
  private actionSubject = new Subject<{ type: NO_PAGE_ACTIONS, payload?: any }>();

  constructor() {

    this.data$ = this.pageDataSubject.pipe(filter(val => val !== null))
    this.id$   = this.data$.pipe(map(val => val.id));

    this.action$ = this.actionSubject.pipe(filter(val => val !== null))
  }

  get data(): any {
    return this.pageDataSubject.getValue();
  }

  get id(): string {
    return this.pageDataSubject.getValue().id;
  }

  dispatchAction(action: NO_PAGE_ACTIONS, payload: any): void {
    this.actionSubject.next({ type: action, payload });
  }
}
