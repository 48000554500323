import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { SelectableUIMenu } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { KalgudiProjectCreationService } from '../../../project-creation/services/kalgudi-project-creation.service';

@Component({
  selector: 'kl-project-dashboard-header',
  templateUrl: './project-dashboard-header.component.html',
  styleUrls: ['./project-dashboard-header.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectDashboardHeaderComponent implements OnInit {

  @Input()
  projectCategories: SelectableUIMenu[];

  searchForm: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private projectCreationService: KalgudiProjectCreationService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
  ) {

    this.searchForm = new FormGroup({
      searchKeyword: new FormControl('')
    });
  }

  ngOnInit() {
  }

  /**
   * Opens the project creation dialog
   */
  openCreateProjectDialog() {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories)
      .subscribe(
        res => {
          this.notification.showMessage('Project created');

          this.appRouting.toProject( { projectId: res.id }  , {type: 'GRID'});

          // this.appRouting.toProjectList();
        }
      );
    }
}
