import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiSubmissionListResponseData,
  KalgudiSurveySubmission,
  KalgudiSurveySubmissionRequest,
} from '@kalgudi/types';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiSurveySubmissionApiService {


  /**
   * `v2/social/surveys/:surveyId/submissions`
   */
  private readonly API_SURVEYS_SUBMISSIONS = `${this.environment.restBaseUrlV2}/social/surveys/:surveyId/submissions`;


  /**
   * `v2/social/surveys/:surveyId/submissions/:submissionId`
   */
  private readonly API_SURVEYS_SUBMISSION = `${this.API_SURVEYS_SUBMISSIONS}/:submissionId`;

  constructor(
    private httpClient: HttpClient,
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private util: KalgudiUtilityService
  ) { }


  /**
   * Api to get all submissions of a survey
   */
  getSubmissions(surveyId: string, profileKey: string): Observable<ApiSubmissionListResponseData> {

    const params = {
      profileKey
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_SURVEYS_SUBMISSIONS.replace(':surveyId', surveyId), { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        map(res => res.data)
      );

  }


  /**
   * Api to get all submissions of a survey
   */
  postSubmissions(surveyId: string, payload: KalgudiSurveySubmissionRequest): Observable<KalgudiSurveySubmission> {


    const url = this.API_SURVEYS_SUBMISSIONS.replace(':surveyId', surveyId);

    return this.httpClient.post<ApiResponseCommon>(url, payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );

  }


  /**
   * Api to update all submissions of a survey
   */
  updateSubmissions(surveyId: string, submissionId: string, payload: KalgudiSurveySubmissionRequest): Observable<KalgudiSurveySubmission> {


    const url = this.API_SURVEYS_SUBMISSION.replace(':surveyId', surveyId).replace(':submissionId', submissionId);

    return this.httpClient.put<ApiResponseCommon>(url, payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        map(res => res.data)
      );

  }
}
