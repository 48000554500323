import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult, KalgudiUsersMap } from '@kalgudi/types';

@Component({
  selector: 'kl-add-program-members-mobile-dialog',
  templateUrl: './add-program-members-mobile-dialog.component.html',
  styleUrls: ['./add-program-members-mobile-dialog.component.scss']
})
export class AddProgramMembersMobileDialogComponent extends KalgudiMobileDialog  implements OnInit {

  /**
   * Modified set of selected users after performing actions on dialog.
   */
  selectedUsersMap: KalgudiUsersMap = {};

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * Triggers when the child emitted an event
   */
  membersSelected(res: KalgudiUsersMap): void {
    this.selectedUsersMap = res;

    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: this.selectedUsersMap
    };
    this.dialogRef.close(result);
  }
}

