import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { kalgudiAnimations, KalgudiAppService, KalgudiUsersService, KalgudiUtilityService } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';
import { KalgudiReportAbuseService, KalgudiSocialRouteConfig } from '@kalgudi/social';
import { ProgramShareUpdate, ShareUpdate } from '@kalgudi/types';

import { ShareUpdateActions } from '../../kalgudi-share-update-actions';
import { KalgudiShareUpdateService } from '../../services/kalgudi-share-update.service';


@Component({
  selector: 'kl-share-update-tile',
  templateUrl: './share-update-tile.component.html',
  styleUrls: ['./share-update-tile.component.scss'],
  animations: kalgudiAnimations,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareUpdateTileComponent extends ShareUpdateActions<ProgramShareUpdate> implements OnInit {

  @Input()
  scheduledUpdates: boolean;

  @Input()
  animationDelay = 0;

  @Input()
  fullView: boolean;

  @Input()
  showMatMenu: boolean;

  @Input()
  memberRole: string;

  @Input()
  url: string;

  @Input()
  showPageDetails: boolean;

  @Input()
  showLessData: boolean;

  @Output()
  postUpdated = new EventEmitter();



  tileAnimationState: 'init' | 'deleted';

  showCommentForm = false;
  isLoggedIn: boolean;

  farmStoreUrl: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected sharePost: KalgudiShareUpdateService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected usersService: KalgudiUsersService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(
      sharePost,
      notifications,
      kalgudiApp,
      kalgudiDialogs,
      usersService,
      reportAbuse,
      util,
      injector
    );

    this.farmStoreUrl = this.env.farmerStoreDomain;

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }


  ngOnInit() {

    this.tileAnimationState = 'init';
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Implement operation to be performed on successful post like.
   */
  onPostLiked(shareDetails: ShareUpdate): void {
    this.liked.emit();
  }

  onPostCommented(shareDetails: ShareUpdate): void {
    this.commented.emit();
  }

  toggleCommentList(): void {
    this.showCommentForm = !this.showCommentForm;
  }

  /**
   * Navigate to share update full view
   * @param shareId
   */
  toFullview(shareId: string): void {
    if (!this.fullView) {

      this.appRouting.toSocialShareUpdateFullview({ shareId });
    }
  }

  protected onPostDeleted(): void {
    this.tileAnimationState = 'deleted';
  }


  /**
   * calls after updating the post successfully
   */
  protected onPostUpdated(res): void {
    this.postUpdated.emit(res);
  }

}
