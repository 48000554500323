import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiProjectTask } from '@kalgudi/types';

@Component({
  selector: 'kl-task-status-dialog',
  templateUrl: './task-status-dialog.component.html',
  styleUrls: ['./task-status-dialog.component.scss']
})
export class TaskStatusDialogComponent extends KalgudiDialog<TaskStatusDialogComponent> implements OnInit {

  submissionDetails: KalgudiProjectTask;

  constructor(
    protected dialogRef: MatDialogRef<TaskStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) {

    super(dialogRef);

    this.submissionDetails = this.data.data.taskSubmissionDetails;
  }

  ngOnInit() {
  }

  taskStatusUpdated(res: KalgudiProjectTask) {
    this.submissionDetails = res;
    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        submissionDetails: this.submissionDetails
      }
    };
    this.dialogRef.close(result);
  }

}

