<mat-card class="mt-2 p-0 mb-2">

  <ng-container *ngIf="(myPages$ | async) as myPages">

    <!-- Programs you are admin -->
    <div class="py-3" *ngIf="myPages?.adminPages as adminPages" @fadeIn>
      <ng-container *ngIf="adminPages?.length > 0">
        <div fxLayout="row" class="mb-2 px-3" fxLayoutAlign="space-between center">
          <div fxFlex="75%">
            <p class="mb-0 program-list-name" translate>Pages you are admin</p>
          </div>
          <div>
            <a (click)="openProgramListPage()" translate>View all</a>
          </div>
        </div>

        <!-- Programs list  -->
        <div fxLayout="row" *ngFor="let page of adminPages | slice:0:5" class="py-2 list"
          (click)="openProgramFullview(page?.pageId)">
          <div fxLayout="column" class="pl-3" fxFlex="95%">
            <span class="text-capitalize program-title text-truncate mw-100" [title]="page?.pageTitle"><a>{{page?.pageTitle}}</a></span>
            <span class="page-type">{{page?.pageType | translate}}</span>
          </div>
        </div>
        <!-- Programs list  -->

      </ng-container>
    </div>
    <!-- /Programs you are admin -->

    <!-- Separator -->
    <div class="separator"></div>
    <!-- /Separator -->

    <!-- Program you follow -->
    <div *ngIf="myPages?.followingPages as followingPages" @fadeIn>
      <div class="py-3" *ngIf="followingPages?.length > 0">

        <ng-container>

          <div fxLayout="row" class="mb-2 px-3" fxLayoutAlign="space-between center">
            <div fxFlex="75%">
              <p class="mb-0 program-list-name" translate>Pages you are following</p>
            </div>
            <div>
              <a (click)="openProgramListPage()" translate>View all</a>
            </div>
          </div>

          <!-- Programs list  -->
          <div fxLayout="row" *ngFor="let page of followingPages | slice:0:5" class="py-2 list"
            (click)="openProgramFullview(page?.pageId)">
            <div fxLayout="column" class="pl-3" fxFlex="95%">
              <span class="text-capitalize program-title text-truncate mw-100" [title]="page?.pageTitle"><a>{{page?.pageTitle}}</a></span>
              <span class="page-type">{{page?.pageType | translate}}</span>
            </div>
          </div>
          <!--/ Programs list  -->

        </ng-container>

      </div>
    </div>
    <!--/ program you follow-->

  </ng-container>

</mat-card>
