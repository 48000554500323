import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-icon-link-button',
  templateUrl: './icon-link-button.component.html',
  styleUrls: ['../sass/kalgudi-buttons.scss']
})
export class IconLinkButtonComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  matIcon: string;

  @Input()
  svgIcon: string;

  @Input()
  type: 'button' | 'submit' | 'reset';

  @Input()
  classList: string;

  @Input()
  progress: boolean;

  @Input()
  disabled: boolean;

  @Output()
  clicked = new EventEmitter<MouseEvent>();

  constructor() {
  }

  ngOnInit() { }

  onClick(event: MouseEvent): void {

    this.clicked.emit(event);

    event.stopPropagation();
  }

}
