import { Component, Injector, OnInit } from '@angular/core';
import { fadeInAnimation } from '@kalgudi/core';

import { KalgudiStocksList } from '../../../../classes/kalgudi-stocks-list';

@Component({
  selector: 'kl-kalgudi-stock-list',
  templateUrl: './kalgudi-stock-list.component.html',
  styleUrls: ['./kalgudi-stock-list.component.scss'],
  animations: [ fadeInAnimation ]
})
export class KalgudiStockListComponent extends KalgudiStocksList implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
   }

  ngOnInit() {
    this.initTableStream(this.matPaginator, this.matSort);
  }

  onDestroyed(): void {}

}
