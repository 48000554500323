import { FormlyFieldConfig } from '@ngx-formly/core';

/**
 * List of formly JSON schema validations
 */
export class FormlyValidations {

  static minItemsValidationMessage(err, field: FormlyFieldConfig) {
    return `Must have ${field.templateOptions.minItems} items`;
  }

  static maxItemsValidationMessage(err, field: FormlyFieldConfig) {
    return `Must not have more than ${field.templateOptions.maxItems} items`;
  }

  static minLengthValidationMessage(err, field: FormlyFieldConfig) {
    return `Minimum ${field.templateOptions.minLength} characters required`;
  }

  static maxLengthValidationMessage(err, field: FormlyFieldConfig) {
    return `Must be greater than ${field.templateOptions.maxLength} characters`;
  }

  static minValidationMessage(err, field: FormlyFieldConfig) {
    return `Value must be greater or equal ${field.templateOptions.min}`;
  }

  static maxValidationMessage(err, field: FormlyFieldConfig) {
    return `Value must be less or equal ${field.templateOptions.max}`;
  }

  static multipleOfValidationMessage(err, field: FormlyFieldConfig) {
    return `Value should be multiple of ${field.templateOptions.step}`;
  }

  static exclusiveMinimumValidationMessage(err, field: FormlyFieldConfig) {
    return `Value must be less than ${field.templateOptions.step}`;
  }

  static exclusiveMaximumValidationMessage(err, field: FormlyFieldConfig) {
    return `Value must be greater than ${field.templateOptions.step}`;
  }

  static constValidationMessage(err, field: FormlyFieldConfig) {
    return `Invalid input, must be "${field.templateOptions.const}"`;
  }

  static duplicateItemsErrorMessage(err, field: FormlyFieldConfig) {
    return `Should not contain any duplicate item`;
  }

}
