import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ProjectStatistics, SelectableUIMenu } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProjectsDashboardService } from './../services/kalgudi-projects-dashboard.service';

@Directive()
export abstract class AllProjectsStats extends KalgudiDestroyable {

  @Input()
  projectCategories: SelectableUIMenu[];

  projectsStats: ProjectStatistics;

  private projectsDashboard: KalgudiProjectsDashboardService;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {

    super();

    this.projectsDashboard = this.injector.get<KalgudiProjectsDashboardService>(KalgudiProjectsDashboardService);
    this.notification      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
  }

  /**
   * Gets all projects stats
   */
  getAllProjectsDashboard() {

    this.projectsDashboard.getAllProjectsStats()
    .pipe(
      // Subscribe till `$destroyed` is not emitted
      takeUntil(this.destroyed$),
    )
    .subscribe(
      res => this.projectsStatsHandler(res),

      err => this.projectsStatsErrHandler(err)
    );
  }

  onDestroyed(): void {}

  /**
   * Event handler for successfully getting projects stats
   */
  protected projectsStatsHandler(res) {
    this.projectsStats = res;
  }

  /**
   * Event handler for failed to get projects stats
   */
  protected projectsStatsErrHandler(err: Error) {
    this.notification.showMessage('Unable to fetch projects stats');
  }
}
