import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiCalendarEvent, KalgudiProjectTask, PartialData } from '@kalgudi/types';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';
import isSameDay from 'date-fns/isSameDay';
import isSameMonth from 'date-fns/isSameMonth';
import { Subject } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../config';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTasksListService } from '../../project-fullview/services/kalgudi-tasks-list.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class KalgudiTasksCalendar  extends KalgudiDestroyable {

  @Input()
  projectId: string;

  @Input()
  loggedInUserTasks = false;

  @Input()
  profileKey: string;

  @Input()
  extraFilters: PartialData = {};

  taskList: KalgudiProjectTask[];


  /**
   * colors list to show the tasks
   */
  colors = [
    {
      // red
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    {
      // blue
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    {
      // yellow
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    {
      // green
      primary: 'green',
      secondary: 'green',
    }
  ];

  /**
   * Actions for each event
   */
  actions: CalendarEventAction[] = [
    {
      label: '<span class="material-icons">edit</span>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: any }): void => {

        this.openEditTaskDialog(event.taskId, event.projectId);

      },
    },
    {
      label: '<span class="material-icons">delete</span>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: any }): void => {
        this.deleteTask(event.taskId);
      },
    },
  ];

  view: CalendarView = CalendarView.Month;

  viewDate: Date = new Date();

  refresh: Subject<any> = new Subject();

  activeDayIsOpen = false;

  events: KalgudiCalendarEvent[] = [];

  progress: boolean;

  protected projectService: KalgudiProjectService;
  protected stateService: KalgudiProjectStateService;
  protected tasksActionService: KalgudiTaskActionsService;

  private tasksListService: KalgudiTasksListService;
  private taskService: KalgudiTasksService;
  private appRouting: KalgudiProjectRouteConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super();
    this.tasksListService   = this.injector.get<KalgudiTasksListService>(KalgudiTasksListService);
    this.stateService       = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.tasksActionService = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);
    this.projectService     = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.taskService        = this.injector.get(KalgudiTasksService);
    this.appRouting         = this.injector.get<KalgudiProjectRouteConfig>(KL_ROUTE_CONFIG);

    this.stateService.reloadTasks$
      .pipe(
        takeUntil(this.destroyed$),

        // skip(1)
      )
      .subscribe(
        (res) => this.getTasks()
      );
  }

  /**
   * Extra filters to get the tasks
   */
  get filters(): PartialData {
    return {};
  }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Opens task full view
   */
  openTaskFullview(event: KalgudiCalendarEvent) {

    this.appRouting.toTask( { projectId: event.projectId, taskId: event.taskId });

  }

  /**
   * Called when the day cell is clicked
   */
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  /**
   * Called when an event is dragged and dropped
   */
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }

      return iEvent;
    });
  }

  /**
   * Toggles the active view date
   */
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Gets the list of tasks
   */
  protected getTasks() {

    this.events = [];
    this.progress = true;
    this.tasksListService.getTasks(0, 100, this.projectId, this.profileKey, {...this.filters, ...this.extraFilters})
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.prepareEvents(res.items),
        err => this.util.apiErrorHandler(err)
      );

  }


  /**
   * Event handler for successful task deletion
   */
  protected taskDeleteHandler(res) {
    this.getTasks();
  }


  /**
   * Prepares the KalgudiProjectTask to CalendarEvent model
   */
  private prepareEvents(tasks: KalgudiProjectTask[]): void {


    tasks.forEach((t, i) => {

      const timeFrame = t.updatedTimeFrame ? t.updatedTimeFrame : t.timeFrame;

      const event: KalgudiCalendarEvent = {
        start: new Date(timeFrame.start),
        end: new Date(timeFrame.end),
        title: t.title,
        color: this.colors[i % this.colors.length],
        actions: this.actions,
        taskId: t.taskId,
        projectId: t.projectId
      };

      this.events.push(event);
    });
  }


  /**
   * opens the task edit dialog
   */
  private openEditTaskDialog(taskId: string, projectId: string) {

    this.updateProjectDetails(projectId);

    this.taskService.getTaskDetails(taskId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
        switchMap(_ => this.tasksActionService.openTaskCreationDialog(projectId, taskId, {...this.filters, ...this.extraFilters}))
      )
      .subscribe(
        (res) => {

          this.notification.showMessage('Task updated successfully');
          this.stateService.reloadTasks();
          this.updateProjectDetails(projectId);

          this.getTasks();
        }
      );
  }


  /**
   * Deletes the task
   */
  private deleteTask(taskId: string) {

    this.tasksActionService.deleteTask(taskId, {...this.filters, ...this.extraFilters})
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.taskDeleteHandler(res),

        err => this.util.apiErrorHandler(err)
      );
  }


  /**
   * Updates the project details with latest data
   */
  private updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}

