<div id="upload-image-mobile" class="h-100">

  <div class="content-images">
    <kl-upload-attachments
      [(attachments)]="attachments"
      [maxImages]="maxImages"
      [multiple]="multiple"
      [acceptFileTypes]="acceptFileTypes"
      [s3Category]="s3Category">
    </kl-upload-attachments>
  </div>

  <!-- Action buttons -->
  <div class="fixed-bottom p-3">
    <div class="pt-0 action-buttons w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">

      <div class="w-100">
        <kl-stroked-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()">
        </kl-stroked-button>
      </div>

      <div  class="w-100">
        <kl-flat-button [label]="dialogData?.acceptButtonTitle" (clicked)="ok()"></kl-flat-button>
      </div>

    </div>
  </div>
  <!--/ Action buttons -->

</div>
