import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { AddProjectMembersService, KalgudiProjectListService, KalgudiProjectsListGrid } from '@kalgudi/project-manager';
import {
  AddProjectMembersResponse,
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiPageDetails,
  KalgudiPageProject,
  KalgudiProgramShareFilter,
  KalgudiProject,
  PartialData,
  PROJECT_TARGET_AUDIENCE_TYPE,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PageActions } from '../../../constants';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { ProgramStateService } from '../../../services/program-state.service';
import {
  PageProjectsMembersDialogComponent,
} from '../components/page-projects-members-dialog/page-projects-members-dialog.component';
import {
  PageProjectsMembersMobileDialogComponent,
} from '../components/page-projects-members-mobile-dialog/page-projects-members-mobile-dialog.component';

@Directive()
export abstract class KalgudiPageProjectList extends KalgudiProjectsListGrid  {

  @Input()
  pageId: string;

  pageDetails: KalgudiPageDetails;

  memberRole: string;

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  protected appRouting: KalgudiProgramsRouteConfig;
  private programState: ProgramStateService;
  private pageProjectListService: KalgudiProjectListService;
  private dialogsService: KalgudiDialogsService;
  private projectMembersService: AddProjectMembersService;
  private kalgudiPageService: KalgudiPageService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);

    this.programState           = this.injector.get(ProgramStateService);
    this.pageProjectListService = this.injector.get(KalgudiProjectListService);
    this.dialogsService         = this.injector.get(KalgudiDialogsService);
    this.projectMembersService  = this.injector.get(AddProjectMembersService);
    this.appRouting             = this.injector.get<KalgudiProgramsRouteConfig>(KL_ROUTE_CONFIG);
    this.kalgudiPageService     = this.injector.get<KalgudiPageService>(KalgudiPageService);

    this.memberRole = this.programState.data.memberRole;

    /**
     * On `ADD_PROJECT` state action changed
     */
    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === PageActions.ADD_PROJECT),

      )
      .subscribe(res => {
        this.openCreateProjectDialog();
      });


    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.pageDetails = pageDetails;
      });

  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Extra filters to get the projects, In this case getting projects under the page
   */
  get filters(): PartialData {
    return {
      pageId: this.pageId
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Open create project dialog
   */
  openCreateProjectDialog() {
    this.showCreateProjectDialog()
      .subscribe( res => {
        this.openProjectFullView(res);
      });
  }

  /**
   * Opens project fullview in pages
   *
   * Making this anonymous function is essential to preserve its `this`
   * binding reference. As the reference of function is passed to other class.
   */
  openProjectFullView = <T extends KalgudiProject>(project: T): void => {
    this.appRouting.toProgramTasks({ pageId: this.pageId }, { projectId: project.id });
  }


  /**
   * Shows the project members management
   */
  addMembersToProject(project: KalgudiPageProject) {

    this.showMembersManagementDialog(project)
      .pipe(
        tap(_ => this.notification.showSpinner(true)),

        switchMap(dialogData => this.addMembers(project.pageId, project.id, dialogData.data.filters)),

        finalize(() => this.notification.hideSpinner()),
      )
      .subscribe(
        res => {
          this.resetStream();
        },
        err => {
          this.notification.showMessage('Unable to add members to project, please try again later');
        }
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Calls the API to add members to the project inside pages
   */
  private addMembers(pageId: string, projectId: string, filter: KalgudiProgramShareFilter): Observable<string[]> {

    // Construct payload for member add service
    const payload: AddProjectMembersResponse = this.util.clone({
      type: PROJECT_TARGET_AUDIENCE_TYPE.BROADCAST,
      filter: {
        ...filter,
        pageId,
      },
    });

    // Return the API response
    return this.projectMembersService.addProjectMembers(projectId, payload, { pageId })
  }

  /**
   * Shows project page members management dialog
   */
  private showMembersManagementDialog(project: KalgudiPageProject) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add members to project',
      acceptButtonTitle: 'Add members',
      rejectButtonTitle: 'Cancel',
      data: {
        pageId: project.pageId,
        project: project,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        pageId: project.pageId,
        project: project,
      }
    };

    return this.openPageProjectMembersManagementDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      );
  }


  /**
   * Shows the page project members management web or mobile dialog
   */
  private openPageProjectMembersManagementDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(PageProjectsMembersMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(PageProjectsMembersDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
