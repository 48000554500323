<main id="finance-details-form">
  <!-- Form group for financial details -->
  <form [formGroup]="fpoProfilesForm">
    <main class="financial-form-details overflow-auto p-3" fxLayout="column" formGroupName="financialDetails">

      <div [formGroup]="financialDetailsForm">
        <!-- Bank account details -->
        <div fxLayout="column" formGroupName="bankDetails" class="mb-2">
          <p class="mb-2 font-weight-bold headers" translate>Bank account details</p>
          <div fxLayout="row wrap" fxLayoutGap="10px grid">
            <div fxFlex="calc(33% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Bank name</mat-label>
                <input (keypress)="onKeyPress($event , 'alphanumericSpace')" matInput formControlName="bankName" [placeholder]="'Bank name' | translate" maxlength="100" pattern="^[A-Za-z\s_-]+$" />
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getBankName.hasError('pattern')" translate>Please enter only alphabets</mat-error>
            </div>

            <div fxFlex="calc(33% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Branch</mat-label>
                <input matInput formControlName="branch" [placeholder]="'Branch' | translate" maxlength="60" (keypress)="onKeyPress($event, 'alphanumericSpace')" [readonly]="!getBankName?.value" />
              </mat-form-field>
              <!-- <mat-error class="mt-n2" *ngIf="getBranchName?.touched && !getBankName?.value">Please start with the bank name.</mat-error> -->
              <mat-error class="mt-n2" *ngIf="getBranchName?.hasError('pattern')" translate>Enter a valid branch name.</mat-error>
            </div>

            <div fxFlex="calc(34% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Account type</mat-label>
                <mat-select formControlName="bankAccountType" [placeholder]="'Account type' | translate" [disabled]="!getBankName?.value">
                  <mat-option *ngFor="let type of accountType" [value]="type?.value">
                    {{ type?.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="calc(40% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Account number</mat-label>
                <input matInput formControlName="accountNumber" [placeholder]="'Account number' | translate" maxlength="18" (keypress)="onKeyPress($event)" [readonly]="!getAccuntType?.value"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="!(getBankName?.value && getAccuntType?.value) && getAccountNumber.touched" translate>Enter bank & branch name first</mat-error>
              <mat-error class="mt-n2" *ngIf="getAccountNumber?.hasError('pattern')" translate>Enter valid account number</mat-error>
            </div>

            <div fxFlex="calc(40% - 10px)" fxFlex.xs="calc(80% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>IFSC code</mat-label>
                <input class="text-uppercase" matInput formControlName="ifscCode" [placeholder]="'IFSC code' | translate" maxlength="11" minlength="11" (keypress)="onKeyPress($event, 'alphaNumeric')" pattern="^[A-Za-z][A-Za-z0-9]*$" [readonly]="!getAccountNumber?.value"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getIfsc?.hasError('pattern')" translate>Please enter valid ifsc code</mat-error>
              <mat-error class="mt-n2" *ngIf="getIfsc?.hasError('min')" translate>IFSC code should be minimum length of 11 characters</mat-error>
              <mat-error class="mt-n2" *ngIf="getIfsc?.touched && !getAccountNumber?.value" translate>Enter account number first</mat-error>
            </div>
            <div class="add-button">
              <button mat-fab class="mb-2" [disabled]="!(getBankName?.value && getAccuntType?.value && getAccountNumber?.value && getIfsc?.value && !bankDetailsFields.invalid)" (click)="addBankDetails()" >
                <mat-icon class="text-white add-icon">add</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!--/ Bank account details -->

        <!-- Added bank account details -->
        <div class="mb-2 finance-table-sections bank-table rounded" *ngIf="bankDetails && bankDetails?.length">
          <div class="table">
            <!-- Table header values -->
            <div class="table-header text-center text-white p-1">
              <div class="table-cell" translate>S.no</div>
              <div class="table-cell" translate>Bank name</div>
              <div class="table-cell" translate>Branch</div>
              <div class="table-cell" translate>Account type</div>
              <div class="table-cell" translate>Account number</div>
              <div class="table-cell" translate>IFSC</div>
              <div class="table-cell" translate>Action</div>
            </div>
            <!--/ Table header values -->

            <!-- Table body values -->
            <div class="table-body text-center">
              <div class="table-row bg-white" *ngFor="let details of bankDetails let i= index">
                <div class="table-cell text-break">{{ i+1 }}</div>
                <div class="table-cell text-break">{{ details?.bankName | titlecase }}</div>
                <div class="table-cell text-break">{{ details?.branch | titlecase }}</div>
                <div class="table-cell text-break">{{ details?.bankAccountType }}</div>
                <div class="table-cell text-break">{{ details?.accountNumber }}</div>
                <div class="table-cell text-break">{{ details?.ifscCode | uppercase }}</div>
                <div class="actions table-cell">
                  <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"  (click)="editBankDetails(i)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="delete-buttons"  (click)="removeBankDetails(i)">
                    <mat-icon class="delete-icon">clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!--/ Table body values -->
          </div>
        </div>
        <!--/ Added bank account details -->

        <!-- Loan details -->
        <div fxLayout="column" formGroupName="creditHistory" class="mb-2">
          <p class="mb-2 font-weight-bold headers" translate>Credit history</p>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div ngClass.lt-md="mb-2" fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Loan amount <small>(₹)</small></mat-label>
                <input class="no-arrows" matInput formControlName="loanAmountAvailed" [placeholder]="'Loan amount' | translate" maxlength="12" (keypress)="onKeyPress($event)" [value]="loanValue"/>
              </mat-form-field>
              <div class="mt-n2">
                <span class="text-danger" *ngIf="!loanAmountValidator">Amount must exceed 0</span>
              </div>
            </div>

            <div ngClass.lt-md="mb-2" fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Tenure(in months)</mat-label>
                <input matInput formControlName="tenure" [placeholder]="'Tenure(In months)' | translate" maxlength="3" [readonly]="!getLoan?.value" (keypress)="onKeyPress($event)"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="!getLoan?.value && getTenureField?.touched" translate>Please start with loan amount</mat-error>
              <mat-error class="mt-n2" *ngIf="getTenureField.hasError('pattern')" translate>Enter a valid month(s)</mat-error>
            </div>

            <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(33% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Availed from</mat-label>
                <input matInput formControlName="lenderName" [placeholder]="'Availed from' | translate" (keypress)="onKeyPress($event, 'alphabetSpace')" [readonly]="!getLoan?.value" maxlength="50"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="!getLoan?.value && getAvailedField.touched" translate>Enter Loan amount first</mat-error>
            </div>

            <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(33% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Status</mat-label>
                <mat-select formControlName="creditHistoryStatus" [placeholder]="'Status' | translate" [disabled]="!getAvailedField?.value">
                  <mat-option *ngFor="let status of creditHistroyStatus" [value]="status?.id">
                    {{ status?.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="add-button">
              <button mat-fab [disabled]="!((loanAmountValidator && getLoan?.value && !creditHistoryFields.invalid) && (getAvailedField?.value && getCreditStatusField?.value))" (click)="addCreditHistoryDetails()">
                <mat-icon class="add-icon text-white">add</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!--/ Loan details -->

        <!-- Added loan details -->
        <div class="mb-2 finance-table-sections loan-table rounded" *ngIf="creditHistoryDetails && creditHistoryDetails?.length">
          <div class="table">
            <!-- Table header values -->
            <div class="table-header text-center text-white p-1">
              <div class="table-cell" translate>S.no</div>
              <div class="table-cell" translate>Loan amount <small>(₹)</small></div>
              <div class="table-cell" translate>Tenure(in months)</div>
              <div class="table-cell" translate>Availed from</div>
              <div class="table-cell" translate>Status</div>
              <div class="table-cell" translate>Action</div>
            </div>
            <!--/ Table header values -->

            <!-- Table body values -->
            <div class="table-body text-center">
              <div class="table-row bg-white" *ngFor="let creditHistory of creditHistoryDetails let i= index">
                <div class="table-cell text-break">{{ i+1 }}</div>
                <div class="table-cell text-break">{{ convertIndianRupeeFormat(creditHistory?.loanAmountAvailed) }}</div>
                <div class="table-cell text-break">{{ creditHistory?.tenure }}</div>
                <div class="table-cell text-break">{{ (creditHistory?.lenderName | titlecase) || '-' }}</div>
                <div class="table-cell text-break">{{ creditHistroyStatusMap[creditHistory.creditHistoryStatus] || '-' }}</div>
                <div class="actions table-cell">
                  <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"  (click)="editCrediHistroyDetails(i)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="delete-buttons"  (click)="removeCreditHistory(i)">
                    <mat-icon class="delete-icon">clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!--/ Table body values -->
          </div>
        </div>
        <!--/ Added loan details -->

        <!-- License details -->
        <div fxLayout="column" formGroupName="licenses" class="mb-2">
          <p class="mb-2 font-weight-bold headers" translate>Licenses</p>
          <div fxLayout="column">
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Type of license</mat-label>
                  <mat-select formControlName="licenceName" placeholder="Type of license">
                    <mat-option *ngFor="let status of licenseStatus" [value]="status?.id">
                      {{ status?.value }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            <ng-container *ngIf="getLicenseType?.value !== 'NONE'">
              <div fxFlex="calc(27% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>License number</mat-label>
                  <input (keypress)="onKeyPressForSelectedLicense($event)" matInput
                  formControlName="licenceNumber" [readonly]="!getLicenseType?.value"
                  [maxlength]="selectedLicense === 'PAN' ? 10 : (selectedLicense === 'GST' ? 15 : 30)"
                  [minlength]="selectedLicense === 'PAN' ? 10 : (selectedLicense === 'GST' ? 15 : '')"
                  [placeholder]="'License number' | translate"
                  [ngClass]="{'input-uppercase': (selectedLicense === 'PAN' || selectedLicense === 'GST')}" pattern="^[A-Za-z0-9\/\-]*"/>
                </mat-form-field>
                <mat-error class="mt-n2" *ngIf="!licensesNamesField?.value && licensesNumberField.touched" translate>Enter license type first</mat-error>
                <mat-error class="mt-n2" *ngIf="licensesNumberField?.hasError('pattern')" translate>Enter a valid license number</mat-error>
              </div>
              <div fxFlex="calc(23% - 10px)" fxFlex.xs="calc(40% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Valid till</mat-label>
                  <input matInput [matDatepicker]="date" (focus)="date.open()" formControlName="validityDate" placeholder="Select validity date" [disabled]="!getLicenseNumberField?.value" (keypress)="onKeyPress($event)">
                  <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                  <mat-datepicker #date></mat-datepicker>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(40% - 10px)" class="table-order">
                <div fxLayout="row" fxLayoutAlign="space-between center" class="upload-btn">

                  <!-- Attachments -->
                  <div fxLayout="column">
                    <div>
                      <button mat-button class="attach-files attach-icon mt-n1" id="attach-files-button" color="primary"
                        [matMenuTriggerFor]="menu">
                        <mat-icon translate>attachment</mat-icon>
                        <span class="ml-1" translate>Upload proof</span>
                      </button>
                    </div>
                    <div class="mt-n2 ml-3">
                      <small>.jpeg, .jpg, .png or .svg</small>
                    </div>
                  </div>

                  <mat-menu #menu="matMenu" class="attach-file">
                    <ng-container>
                      <div mat-menu-item class="attach-file-mat-menu">
                        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                          <div>
                            <p class="m-0 title" translate>Image</p>
                          </div>

                          <div>
                            <kl-image-picker-form-control [formControl]="proofOfAttachments" [showBorder]="false"
                              [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/images.svg"
                              [allowMultiple]="false" [showAttachments]="false" [s3Category]="s3Category"
                              [acceptedFileTypes]="acceptedImageFileTypes">
                            </kl-image-picker-form-control>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </mat-menu>
                  <!--/ Attachments -->
                </div>

              </div>

              <div class="add-button">
                <button mat-fab [disabled]="!((getLicenseType?.value && getLicenseNumberField?.value && (isTypePanGst || getValidityField?.value) && !getLicenseNumberField.invalid && !licensesFields.invalid) || getAttachmentImage?.value) " (click)="addLicenseDetails()">
                  <mat-icon class="add-icon text-white">add</mat-icon>
                </button>
              </div>
            </ng-container>
              <div fxFlex="calc(80% - 10px)"></div>
            </div>


            <!-- Attached files -->
            <div fxLayout="row wrap" fxLayoutGap="8px" class="attachemnt-chips mb-2" fxFlex="100">
              <div fxFlex="calc(70% - 10px)" fxFlex.xs="calc(40% - 10px)"></div>
              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(40% - 10px)">
                <ng-container *ngIf="proofOfAttachments?.value?.length">
                  <ng-container *ngFor="let attachment of proofOfAttachments?.value; let i = index">
                    <mat-chip class="mb-4 mr-4">
                      <img class="chip-image cursor-pointer rounded-circle mr-1" [src]="attachment.url | prefixDomain" (click)="openImageFullView(proofOfAttachments?.value)">
                      <mat-icon class="cursor-pointer" matChipRemove [title]="'Remove image' | translate" (click)="removeImage(i)">cancel
                      </mat-icon>
                    </mat-chip>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <!--/ Attached files -->
          </div>
        </div>
        <!--/ License details -->

        <!-- Added license details -->
        <div class="mb-2 finance-table-sections loan-table rounded" *ngIf="licenseDetails && licenseDetails?.length">
          <div class="table">
            <!-- Table header values -->
            <div class="table-header text-center text-white p-1">
              <div class="table-cell" translate>S.no</div>
              <div class="table-cell" translate>Type</div>
              <div class="table-cell" translate>License number</div>
              <div class="table-cell" translate>Valid till</div>
              <div class="table-cell" translate>Proof</div>
              <div class="table-cell" translate>Action</div>
            </div>
            <!--/ Table header values -->

            <!-- Table body values -->
            <div class="table-body text-center">
              <div class="table-row bg-white" *ngFor="let license of licenseDetails let i= index">
                <div class="table-cell text-break">{{ i+1 }}</div>
                <div class="table-cell text-break">{{ licenseStatusMap[license?.licenceName] }}</div>
                <div class="table-cell text-break">{{ license?.licenceNumber }}</div>
                <div class="table-cell text-break">{{ (license?.validityDate | date: "dd-MM-yyyy") || '-' }}</div>
                <div class="table-cell text-break">
                  <img *ngIf="license?.attachment?.length" matTooltip="Click to full-view" class="chip-image cursor-pointer rounded-circle" [src]="license?.attachment | prefixDomain" (click)="openImageFullView(license?.attachment)">
                  <span *ngIf="!license?.attachment?.length">-</span>
                </div>
                <div class="actions table-cell">
                  <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"  (click)="editLicenseDetails(i)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="delete-buttons"  (click)="removeLicense(i)">
                    <mat-icon class="delete-icon">clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!--/ Table body values -->
          </div>
        </div>
        <!--/ Added license details -->

        <!-- Asset details -->
        <div fxLayout="column" formGroupName="assetDetails" class="mb-2">
          <p class="mb-2 font-weight-bold headers" translate>Asset details</p>
          <div fxLayout="row wrap" fxLayoutGap="10px">
            <div fxFlex="calc(26% - 10px)" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Type</mat-label>
                <input type="text" matInput formControlName="assetType" [matAutocomplete]="auto" maxlength="30">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                  <ng-container *ngFor="let type of assetTypefilteredOptions$ | async; let i = index">
                    <mat-option *ngIf="savedAssetTypes.includes('Other') ? true : !savedAssetTypes?.includes(type?.value)"  [value]="type?.value">
                      {{type?.value}}
                    </mat-option>
                  </ng-container>
                </mat-autocomplete>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getTypeField.value === '' ? false : isAssetTypesMatched ? !isAssetTypesMatched : true">Asset type not found</mat-error>
            </div>

            <div *ngIf="getTypeField?.value === 'Other'" [fxFlex]="getTypeField?.value === 'Other' ? 'calc(37.5% - 10px)' : 'calc(25% - 10px)'" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Other asset details</mat-label>
                <input matInput [placeholder]="'Enter other asset details' | translate" formControlName="otherDetails" (keypress)="onKeyPress($event, 'variety')" maxlength="50" [required]="getTypeField?.value === 'Other'"/>
              </mat-form-field>
            </div>

            <div *ngIf="getTypeField?.value !== 'None'" [fxFlex]="getTypeField?.value === 'Other' ? 'calc(37.5% - 10px)' : 'calc(25% - 10px)'" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Quantity</mat-label>
                <input matInput formControlName="quantity" [placeholder]="'Quantity' | translate" [readonly]="!getTypeField?.value" (keypress)="onKeyPress($event)" maxlength="5"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="!getTypeField?.value && getQuantity.touched" translate>Enter assets type first</mat-error>
              <mat-error class="mt-n2" *ngIf="getQuantity?.hasError('pattern')" translate>Enter a number</mat-error>
            </div>

            <div *ngIf="getTypeField?.value !== 'None'" [fxFlex]="getTypeField?.value === 'Other' ? 'calc(25% - 10px)' : 'calc(20% - 10px)'" fxFlex.xs="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Status</mat-label>
                <mat-select formControlName="assetStatus" [placeholder]="'Status' | translate" [disabled]="!getQuantity?.value">
                  <mat-option *ngFor="let status of assetsStatus" [value]="status?.id">
                    {{ status?.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="(getAssetStatus?.value === 'OWNED') && (getTypeField?.value !== 'None')" [fxFlex]="getTypeField?.value === 'Other' ? 'calc(33% - 10px)' : 'calc(20% - 10px)'" fxFlex.xs="calc(80% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Value <span>(₹)</span></mat-label>
                <input matInput maxlength="12" class="no-arrows" formControlName="assetValue" [placeholder]="'Value' | translate" (keypress)="onKeyPress($event)" [value]="convertAssetValue" pattern="^(\d+,?)+$"/>
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="assetValueField.hasError('pattern')" translate>Enter a number</mat-error>
            </div>

            <div *ngIf="getTypeField?.value === 'None'" fxFlex="calc(75% - 10px)">
            </div>

            <div *ngIf="getTypeField?.value !== 'None'" class="add-button">
              <button mat-fab [disabled]="!(getTypeField?.value && getQuantity?.value && getAssetStatus?.value && isAssetTypesMatched) || (getTypeField?.value === 'Other' && assetDetailsFields?.invalid)"
                (click)="addAssetDetails()">
                <mat-icon class="add-icon text-white">add</mat-icon>
              </button>
            </div>

            <div *ngIf="getAssetStatus?.value !== 'OWNED'" fxFlex="calc(20% - 10px)">
            </div>
          </div>
        </div>
        <!--/ Asset details -->

        <!-- Added asset details -->
        <div class="mb-2 finance-table-sections asset-table rounded" *ngIf="assetDetails && assetDetails?.length">
          <div class="table">
            <!-- Table header values -->
            <div class="table-header text-center text-white p-1">
              <div class="table-cell" translate>S.no</div>
              <div class="table-cell" translate>Type</div>
              <div class="table-cell" translate>Quantity</div>
              <div class="table-cell" translate>Status</div>
              <div class="table-cell" translate>Value <small>(₹)</small></div>
              <div class="table-cell" translate>Action</div>
            </div>
            <!--/ Table header values -->

            <!-- Table body values -->
            <div class="table-body text-center">
              <div class="table-row bg-white" *ngFor="let asset of assetDetails let i= index">
                <div class="table-cell text-break">{{ i+1 }}</div>
                <div class="table-cell text-break">{{ asset.assetType !== "Other" ? asset.assetType : asset.otherDetails }}</div>
                <div class="table-cell text-break">{{ asset?.quantity || '-' }}</div>
                <div class="table-cell text-break">{{ assetsStatusMap[asset.assetStatus] || '-' }}</div>
                <div class="table-cell text-break">{{ asset?.assetValue !== undefined ? convertIndianRupeeFormat(asset?.assetValue) : '-' }}</div>
                <div class="actions table-cell">
                  <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"  (click)="editAssetDetails(i)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="delete-buttons"  (click)="removeAssets(i)">
                    <mat-icon class="delete-icon">clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!--/ Table body values -->
          </div>
        </div>
        <!--/ Added asset details -->
      </div>

      <!-- Insurance count for crop,life and annadhatha farmers -->
      <div fxLayout="column" formGroupName="insurance" class="mb-2">
        <p class="mb-2 font-weight-bold headers" translate>Insurance</p>
        <div fxLayout fxLayoutGap="10px">
          <div fxFlex="calc(33.33% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Crop Insurance</mat-label>
              <input matInput formControlName="cropInsFarmerCount" [placeholder]="'No. of farmer insured' | translate" maxlength="6" (keypress)="onKeyPress($event)" />
            </mat-form-field>
            <mat-error class="mt-n2" *ngIf="cropInsuranceFarmersCount.hasError('pattern')" translate>Enter a number</mat-error>
          </div>

          <div fxFlex="calc(33.33% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Life insurance</mat-label>
              <input matInput formControlName="lifeInsFarmerCount" [placeholder]="'No. of farmer insured' | translate" maxlength="6" (keypress)="onKeyPress($event)" />
            </mat-form-field>
            <mat-error class="mt-n2" *ngIf="lifeInsuranceFarmersCount.hasError('pattern')" translate>Enter a number</mat-error>
          </div>

          <div fxFlex="calc(33.33% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Annadhatha Suraksha</mat-label>
              <input matInput formControlName="annadhathaSurakshaFarmerCount"
                [placeholder]="'No. of farmer insured' | translate" maxlength="6" (keypress)="onKeyPress($event)" />
            </mat-form-field>
            <mat-error class="mt-n2" *ngIf="annadathaSurakshaFarmersCount.hasError('pattern')" translate>Enter a number</mat-error>
          </div>
        </div>
      </div>
      <!--/ Insurance count for crop,life and annadhatha farmers -->
    </main>
  </form>
  <!--/ Form group for financial details -->

  <!-- Save button -->
  <!-- <div fxLayout fxLayoutAlign="end end" class="p-3">
    <kl-flat-button
      label="Save"
      [progress]="isProgress"
      [disabled]="(isSaveEnable && !isEditFinanceDialog) || (!isSaveEnable && !isEditFinanceDialog) || !insuranceGroup.valid || bankDetailsFields.invalid"
      (clicked)="addBasicInfoDetails()">
    </kl-flat-button>
  </div> -->
  <!--/ Save button -->
</main>
