import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-submission-form-dialog',
  templateUrl: './survey-submission-form-dialog.component.html',
  styleUrls: ['./survey-submission-form-dialog.component.scss']
})
export class SurveySubmissionFormDialogComponent extends KalgudiDialog<SurveySubmissionFormDialogComponent> implements OnInit {


  constructor(
    protected dialogRef: MatDialogRef<SurveySubmissionFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }


  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }


  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}

