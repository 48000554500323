import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';

import { BulkMembersAdditionComponent } from './bulk-members-addition/bulk-members-addition.component';



@NgModule({
  declarations: [BulkMembersAdditionComponent],
  imports: [
    CommonModule,

    // Material modules
    MatInputModule,
    MatFormFieldModule,
    FlexLayoutModule,

    TranslateModule,

    FormsModule,
    ReactiveFormsModule,

    // Kalgudi modules
    KalgudiButtonModule
  ],
  exports: [
    BulkMembersAdditionComponent
  ]
})
export class KalgudiBulkMembersAdditionModule { }
