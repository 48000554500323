<div class="container tasks-list">
  <kl-task-list-header
    [selectedView]="viewType"
    [projectsMenu]="projectMenu"
    [loggedInUserTasks]="loggedInUserTasks"
    [projectCategories]="projectCategories"
    (projectsOption)="showProjectsAction(projectDetails?.id, $event)">
  </kl-task-list-header>

  <div *ngIf="viewType === availableViewTypes?.GRID">
    <kl-task-list-grid [projectId]="projectDetails?.id" [profileKey]="profileKey" [loggedInUserTasks]="loggedInUserTasks"></kl-task-list-grid>
  </div>

  <div *ngIf="viewType === availableViewTypes?.LIST">
    <kl-task-list-table [projectId]="projectDetails?.id" [profileKey]="profileKey" [loggedInUserTasks]="loggedInUserTasks"></kl-task-list-table>
  </div>


  <div *ngIf="viewType === availableViewTypes?.CALENDAR">
    <kl-task-list-calendar [projectId]="projectDetails?.id" [profileKey]="profileKey" [loggedInUserTasks]="loggedInUserTasks"></kl-task-list-calendar>
  </div>

</div>

<div>
  <kl-sidebar name="showFilters" class="sidebar" position="right">
    <kl-task-filters></kl-task-filters>
  </kl-sidebar>
</div>
