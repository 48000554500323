<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{data?.title | translate}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <div>
      <h6 translate>Now get an attractive discount of 100 rupees, on purchase of 500 rupees product. Hurry, go to this app store today to take advantage of this opportunity.</h6>
    </div>
    <core-store-banner-tile-images></core-store-banner-tile-images>
  </div>

  <div class="pr-3 pb-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-flat-button label="Ok" (clicked)="ok()"></kl-flat-button>
  </div>
</div>
