<div id="about-us-description">
  <div fxLayout="column" fxLayoutGap="8px">
    <div>
      <p class="text-content overflow-hidden mb-0" klHideContent lineHeight="18" visibleLine="3"
        *ngIf="profile?.aboutMe">
        {{ profile?.aboutMe }}
      </p>
    </div>

    <div *ngIf="profile?.mainActivity">
      <p class="text-heading mb-0">Main Activity</p>
      <p class="text-content overflow-hidden mb-0" klHideContent lineHeight="18" visibleLine="2">
        {{ profile?.mainActivity }}
      </p>
    </div>

    <div *ngIf="profile?.assets?.length">
      <p class="text-heading mb-0">Assets</p>
      <p class="text-content overflow-hidden mb-0" klHideContent lineHeight="18" visibleLine="2">
        {{ profile?.assets }}
      </p>
    </div>

    <div fxLayout.xs="column" fxLayout="row" fxLayoutGap.gt-xs="9px" class="info-content" fxLayoutAlign.gt-xs="center start">

      <div fxFlex="50%">
        <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="(env.appId !== 'SAM_FPO')">
          <div fxFlex="60%">
            <p class="m-0 details-info">Total turnover(₹)</p>
          </div>

          <div fxFlex="40%">
            <p class="details-info-value m-0">{{ profile?.totalTurnOver || '-' }}</p>
          </div>
        </div>

        <div fxLayout fxLayoutGap="9px" class="about-info-line">
          <div fxFlex="60%">
            <p class="m-0 details-info">{{env?.appId === 'SHG_NET_APP' ? 'Monthly meeting' : 'Board meeting'}}</p>
          </div>

          <div fxFlex="40%">
            <p class="details-info-value m-0">{{ profile?.dateOfBoardMeeting || '-' }}</p>
          </div>
        </div>


        <div fxLayout fxLayoutAlign="space-between center" class="about-info-line">
          <div fxFlex="60%">
            <p class="m-0 details-info">Registration Number</p>
          </div>

          <div [fxFlex]="profile?.registrationCertificate ? '30%': '40%'">
            <p class="details-info-value m-0">{{ profile?.registrationNo || '-' }}</p>
          </div>

          <div [fxFlex]="profile?.registrationCertificate ? '10%': '0%'" *ngIf="profile?.registrationCertificate" (click)="openImageFullView(profile?.registrationCertificate)">
            <img class="attachment-image cursor-pointer" [src]="profile?.registrationCertificate | prefixDomain" matTooltip="Registration certificate" >
          </div>
        </div>

        <div fxLayout fxLayoutGap="9px" class="about-info-line">
          <div fxFlex="60%">
            <p class="m-0 details-info">Registered under</p>
          </div>

          <div fxFlex="40%">
            <p class="details-info-value m-0">{{ profile?.registeredUnder || '-' }}</p>
          </div>
        </div>

        <div fxLayout fxLayoutGap="9px" class="promoting-info-line">
          <div fxFlex="60%">
            <p class="m-0 details-info">Promoting Agency</p>
          </div>

          <div fxFlex="40%">
            <ng-container *ngFor="let agency of profile?.promotingAgency;let last = last">
              <span class="details-info-value m-0">{{ agency | trimSpecialCharacters }}</span>
              <span *ngIf="!last">&nbsp;/&nbsp;</span>
            </ng-container>
            <p *ngIf="!profile?.promotingAgency">-</p>
          </div>
        </div>
      </div>

      <div fxFlex="50%" class="registrations">
        <div *ngIf="!isPublicPage">
          <p class="details-heading mb-0 text-danger">Registrations & Licences</p>

          <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="env.appId !== 'SAM_FPO'">
            <div fxFlex="60%">
              <p class="m-0 details-info">Established</p>
            </div>

            <div fxFlex="40%">
              <p class="established-since m-0">{{( profile?.registrations?.establishedSince.includes('T') ?
                (profile?.registrations?.establishedSince | date: 'dd-MMM-yyyy') : profile?.registrations?.establishedSince)
                || '-' }}</p>
            </div>
          </div>

          <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="editable && !isPublicPage">
            <div fxFlex="60%">
              <p class="m-0 details-info">PAN Number</p>
            </div>

            <div fxFlex="40%" fxLayout fxLayoutAlign="space-between center">
              <span class="yes-info-value m-0" fxLayout fxLayoutAlign="space-between center"
                [ngClass]="{'no-info-value': !profile?.registrations || profile?.registrations?.isPan === 'NO' }">{{
                (profile?.registrations?.pan ? '' : profile?.registrations?.isPan || 'No') | titlecase }}
                <span *ngIf="profile?.registrations?.isPan === 'YES' && !profile?.registrations?.pan">&nbsp;/&nbsp; </span>
                <span *ngIf="profile?.registrations?.isPan === 'YES'"> {{ profile?.registrations?.pan || ' Not provided'
                  }}</span>
              </span>
              <!-- Image attachments -->
              <div *ngIf="profile?.registrations?.panAttachment"
                (click)="openImageFullView(profile?.registrations?.panAttachment)">
                <img class="attachment-image cursor-pointer" [src]="profile?.registrations?.panAttachment | prefixDomain">
              </div>
              <!--/ Image attachments -->
            </div>
          </div>
          <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="!isPublicPage">
            <div fxFlex="60%">
              <p class="m-0 details-info">GST Number</p>
            </div>

            <div fxFlex="40%" fxLayout fxLayoutAlign="space-between center">
              <span class="yes-info-value m-0" fxLayout fxLayoutAlign="space-between center"
                [ngClass]="{'no-info-value': profile?.registrations?.isGST === 'NO' || !profile?.registrations }">{{
                (profile?.registrations?.GST ? '' : profile?.registrations?.isGST || 'No') | titlecase }}
                <span *ngIf="profile?.registrations?.isGST === 'YES' && !profile?.registrations?.GST">&nbsp;/&nbsp;</span>
                <span *ngIf="profile?.registrations?.isGST === 'YES'"> {{ profile?.registrations?.GST || ' Not provided'
                  }}</span>
              </span>
              <!-- Image attachments -->
              <div *ngIf="profile?.registrations?.gstAttachment"
                (click)="openImageFullView(profile?.registrations?.gstAttachment)">
                <img class="attachment-image cursor-pointer" [src]="profile?.registrations?.gstAttachment | prefixDomain">
              </div>
              <!--/ Image attachments -->
            </div>
          </div>

          <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="!isPublicPage">
            <div fxFlex="60%">
              <p class="m-0 details-info">Pesticides licence</p>
            </div>

            <div fxFlex="40%" fxLayout fxLayoutAlign="space-between center">
              <span class="yes-info-value m-0" fxLayout fxLayoutAlign="space-between center"
                [ngClass]="{'no-info-value': profile?.registrations?.isPesticideLicense === 'NO' || !profile?.registrations }">{{
                (profile?.registrations?.pesticideLicense ? '' : profile?.registrations?.isPesticideLicense || 'No') |
                titlecase }}
                <span
                  *ngIf="profile?.registrations?.isPesticideLicense === 'YES' && !profile?.registrations?.pesticideLicense">&nbsp;/&nbsp;</span>
                <span *ngIf="profile?.registrations?.isPesticideLicense === 'YES'"> {{
                  profile?.registrations?.pesticideLicense || ' Not provided' }}</span>
              </span>
              <!-- Image attachments -->
              <div *ngIf="profile?.registrations?.pesticideLicenseAttachment"
                (click)="openImageFullView(profile?.registrations?.pesticideLicenseAttachment)">
                <img class="attachment-image cursor-pointer"
                  [src]="profile?.registrations?.pesticideLicenseAttachment | prefixDomain">
              </div>
              <!--/ Image attachments -->
            </div>
          </div>

          <div fxLayout fxLayoutGap="9px" class="about-info-line" *ngIf="!isPublicPage">
            <div fxFlex="60%">
              <p class="m-0 details-info">Fertilizer licence</p>
            </div>

            <div fxFlex="40%" fxLayout fxLayoutAlign="space-between center">
              <span class="yes-info-value m-0" fxLayout fxLayoutAlign="space-between center"
                [ngClass]="{'no-info-value': profile?.registrations?.isFertilizerLicense === 'NO' || !profile?.registrations }">{{
                (profile?.registrations?.fertilizerLicense ? '' : profile?.registrations?.isFertilizerLicense || 'No') |
                titlecase }}
                <span
                  *ngIf="profile?.registrations?.isFertilizerLicense === 'YES' && !profile?.registrations?.fertilizerLicense">&nbsp;/&nbsp;</span>
                <span *ngIf="profile?.registrations?.isFertilizerLicense === 'YES'"> {{
                  profile?.registrations?.fertilizerLicense || ' Not provided' }}</span>
              </span>
              <!-- Image attachments -->
              <div *ngIf="profile?.registrations?.fertilizerLicenseAttachment"
                (click)="openImageFullView(profile?.registrations?.fertilizerLicenseAttachment)">
                <img class="attachment-image cursor-pointer"
                  [src]="profile?.registrations?.fertilizerLicenseAttachment | prefixDomain">
              </div>
              <!--/ Image attachments -->
            </div>
          </div>

          <div fxLayout fxLayoutGap="9px" class="pt-2" *ngIf="!isPublicPage">
            <div fxFlex="60%">
              <p class="m-0 details-info">Seed licence</p>
            </div>

            <div fxFlex="40%" fxLayout fxLayoutAlign="space-between center">
              <span class="yes-info-value m-0" fxLayout fxLayoutAlign="space-between center"
                [ngClass]="{'no-info-value': profile?.registrations?.isSeedLicense === 'NO' || !profile?.registrations }">{{
                (profile?.registrations?.seedLicense ? '' : profile?.registrations?.isSeedLicense || 'No') | titlecase }}
                <span
                  *ngIf="profile?.registrations?.isSeedLicense === 'YES' && !profile?.registrations?.seedLicense">&nbsp;/&nbsp;</span>
                <span *ngIf="profile?.registrations?.isSeedLicense === 'YES'"> {{ profile?.registrations?.seedLicense || ' Not
                  provided' }}</span>
              </span>
              <!-- Image attachments -->
              <div *ngIf="profile?.registrations?.seedLicenseAttachment"
                (click)="openImageFullView(profile?.registrations?.seedLicenseAttachment)">
                <img class="attachment-image cursor-pointer"
                  [src]="profile?.registrations?.seedLicenseAttachment | prefixDomain">
              </div>
              <!--/ Image attachments -->
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
