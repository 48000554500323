import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'kl-common-profile-header',
  templateUrl: './common-profile-header.component.html',
  styleUrls: ['./common-profile-header.component.scss']
})
export class CommonProfileHeaderComponent implements OnInit {

  @Input()
  svgIcon: string;

  @Input()
  title: string;

  @Input()
  actionIcon: string;

  @Input()
  editable: boolean;

  @Output()
  action = new EventEmitter();
  isPublicPage: boolean = false;

  constructor(
    private router: Router,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
  ) { }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  // Emit the action event
  updateTile() {
    this.action.emit();
  }
}
