import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnChanges,
  OnInit,
  Provider,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiPromotionalProductAutocomplete } from '../../classes/kalgudi-promotional-product-autocomplete';



// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PromotionalProductAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'kl-promotional-product-autocomplete',
  templateUrl: './promotional-product-autocomplete.component.html',
  styleUrls: ['./promotional-product-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})
export class PromotionalProductAutocompleteComponent extends KalgudiPromotionalProductAutocomplete implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {
    super(injector, fb);
  }

  ngOnInit() {

  }

  ngOnChanges() {
    if(this.searchTypeList) {
      this.searchType.patchValue((this.pageId === this.kuberPageId || this.pageId === '0jc4')  ? this.searchTypeList[0].id : this.searchTypeList[1].id);
    }
  }

  protected onDestroyed(): void { }

}
