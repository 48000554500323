<div class="add-cattle">
  <form autocomplete="off" [formGroup]="addCertificateForm">
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Title -->
        <div fxLayout="column" fxFlex="50%">
          <mat-form-field appearance="outline">
            <mat-label translate>Title</mat-label>
            <input matInput required formControlName="certName" [placeholder]="'Enter certificate title' | translate" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
            <mat-error translate>Please enter the title</mat-error>
          </mat-form-field>
        </div>

        <!-- University -->
        <div fxLayout="column" class="w-100">
          <mat-form-field appearance="outline">
            <mat-label translate>Issued from</mat-label>
            <input matInput required formControlName="certAuthority" [placeholder]="'Enter issued from' | translate" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
            <mat-error translate>Please enter issued from</mat-error>
          </mat-form-field>
        </div>

        <section fxLayout="column" *ngIf="showOrganicCertificateFields" formGroupName="organicCertification">

          <!-- Farmers -->
          <div *ngIf="isShgApp" fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label translate>Farmers</mat-label>
              <input matInput formControlName="farmers" min="0" type="number" #farmers [placeholder]="'Enter no of farmers' | translate" (keypress)="validateNumericInputLength($event, farmers?.value,'farmers')">
            </mat-form-field>
          </div>
          <!--/ Farmers -->

          <!-- Crops -->
          <div *ngIf="isShgApp" fxLayout="column" class="w-100">
            <mat-form-field appearance="outline">
              <mat-label translate>Crops</mat-label>
              <input matInput formControlName="crops" [placeholder]="'Enter crops' | translate" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
            </mat-form-field>
          </div>
          <!--/ Crops -->

          <!-- Acres -->
          <div *ngIf="isShgApp" fxLayout="column" class="w-100">
            <mat-form-field appearance="outline">
              <mat-label translate>Acres</mat-label>
              <input matInput formControlName="acres" min="0" type="number" #acres [placeholder]="'Enter no of acres' | translate" (keypress)="validateNumericInputLength($event, acres?.value,'farmers')">
            </mat-form-field>
          </div>
          <!--/ Acres -->
        </section>
      </div>

      <div class="pt-2" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">

        <!-- Attach bank image -->
        <div fxLayout="column" fxLayoutAlign="center start">
          <!-- Image attachments -->
          <div *ngIf="attachmentsField.value">
            <kl-image-chip-list [attachments]="attachmentsField?.value" (remove)="removeImage($event)">
            </kl-image-chip-list>
          </div>
          <!--/ Image attachments -->

          <!-- Attach image -->
          <div>
            <button
            fxFlex="calc(35% - 8px)"
            mat-button
            class="p-0"
            id="attach-files-button"
            color="primary"
            [matMenuTriggerFor]="certificate">
            <mat-icon>attachment</mat-icon>
            <span class="" translate>Upload certificate</span>
            </button>
            <mat-menu #certificate="matMenu">
              <div mat-menu-item class="attach-file-mat-menu">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                  <div>
                    <p class="m-0 title">Image</p>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="attachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="false"
                      [showAttachments]="false"
                      [s3Category]="s3Category">
                    </kl-image-picker-form-control>
                  </div>
                  </div>
                </div>
              </mat-menu>
          </div>
          <!--/ Attach image -->
        </div>
        <!--/ Attach bank image -->

        <kl-flat-button
          type="submit"
          label= "{{ certificateId ? ' Update certificate ' : 'Add Certificate' }}"
          [progress]="progress"
          (clicked)="saveOrUpdateCertificate()"
          [disabled]="addCertificateForm?.invalid">
        </kl-flat-button>
      </div>

    </div>

  </form>

</div>
