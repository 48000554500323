import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { TaskFullViewDialogComponent } from '../components/task-full-view-dialog/task-full-view-dialog.component';
import {
  TaskFullViewMobileDialogComponent,
} from '../components/task-full-view-mobile-dialog/task-full-view-mobile-dialog.component';
import { TaskStatsDialogComponent } from '../components/task-stats-dialog/task-stats-dialog.component';
import { TaskStatsMobileDialogComponent } from '../components/task-stats-mobile-dialog/task-stats-mobile-dialog.component';
import { TaskSubmissionDialogComponent } from '../components/task-submission-dialog/task-submission-dialog.component';
import {
  TaskSubmissionMobileDialogComponent,
} from '../components/task-submission-mobile-dialog/task-submission-mobile-dialog.component';

@Injectable()
export class KalgudiTaskFullViewDialogService {

  constructor( private kalgudiDialogService: KalgudiDialogsService) { }


  /**
   * Open task full view dialog
   *
   * @param config Dialog
   */
  showTaskFullViewDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {
    // return this.dialogsService.openDialog(UploadImageDialogComponent, details, config);
    return checkMobileDevice()
      ? this.openTaskFullViewMobileDialog(details)
      : this.openTaskFullViewDialog(details, config);
  }

  /**
   * Open task full view dialog
   *
   * @param config Dialog
   */
  showTaskSubmissionDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {
    return checkMobileDevice()
      ? this.openTaskSubmissionMobileDialog(details)
      : this.openTaskSubmissionDialog(details, config);
  }

  /**
   * Open task stats dialog
   *
   * @param config Dialog
   */
  showTaskStatsDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {
    return checkMobileDevice()
      ? this.openTaskStatsMobileDialog(details)
      : this.openTaskStatsDialog(details, config);
  }


  /**
   * Opens task full view dialog
   */
  openTaskFullViewDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(TaskFullViewDialogComponent, details, config);
  }

  /**
   * Opens task full view Mobile dialog
   */
  openTaskFullViewMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(TaskFullViewMobileDialogComponent, details );
  }

  /**
   * Opens task submission dialog
   */
  openTaskSubmissionDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(TaskSubmissionDialogComponent, details, config);
  }

  /**
   * Opens task submission Mobile dialog
   */
  openTaskSubmissionMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(TaskSubmissionMobileDialogComponent, details );
  }


  /**
   * Opens task stats dialog
   */
  openTaskStatsDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(TaskStatsDialogComponent, details, config);
  }

  /**
   * Opens task stats Mobile dialog
   */
  openTaskStatsMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(TaskStatsMobileDialogComponent, details );
  }
}
