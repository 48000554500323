import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SelectCardComponent } from './select-card.component';



@NgModule({
  declarations: [
    SelectCardComponent
  ],
  imports: [
    CommonModule,

    // Material
    MatTooltipModule,
    MatIconModule,

    FlexLayoutModule,

    AngularSvgIconModule,

    TranslateModule
  ],
  exports: [
    SelectCardComponent,
  ]
})
export class KalgudiSelectCardModule { }
