<mat-card fxFlex="100%" fxLayout="column" class="banking-details-tile">

  <!-- Header -->

  <kl-common-profile-header
    title="Banking Details"
    svgIcon="assets/svgs/banking-details.svg"
    [editable]="editable"
    actionIcon="edit"
    (action)="showUpdateFpoBankingDetailsDialog()">
  </kl-common-profile-header>
  <!-- /Header -->

  <!-- List -->
  <section>

    <div fxLayout="column">

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">Bank name</span>
        <span fxFlex="50%" class="banking-details-value">{{ profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.bankName || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">Account number</span>
        <span fxFlex="50%" class="banking-details-value">{{ profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.accountNumber || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">Account holder name</span>
        <span fxFlex="50%" class="banking-details-value">{{ profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.accountHolderName || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">Branch address</span>
        <span fxFlex="50%" class="banking-details-value">{{ profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.branchAddress || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">IFSC code</span>
        <span fxFlex="50%" class="banking-details-value">{{ profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.ifscCode || '-' }}</span>
      </div>

      <div fxLayout fxLayoutAlign="start center">
        <span fxFlex="calc(50% - 25px)" class="banking-details-field">Bank Passbook Document</span>
        <div mat-raised-button matTooltip="Bank passbook" matTooltipPosition="before" fxFlex="50%" fxLayout *ngIf="profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.bankAttachment" (click)="openImageFullView(profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.bankAttachment)">
          <img class="attachment-image cursor-pointer" [src]="profileDetails?.fpoAdditionalDetails?.fpoBankingDetails?.bankAttachment | prefixDomain">
        </div>
      </div>

    </div>

  </section>
  <!--/ List -->


</mat-card>
