<div class="mx-xl-n3">
  <kl-social-comments-list
    [commentsList]="comments">
  </kl-social-comments-list>

  <div (click)="$event.stopPropagation()">
    <kl-social-comments-form
      [commentForm]="commentForm"
      [progress]="progress"
      (commented)="comment()"
      [kuberPage]="kuberPage">
    </kl-social-comments-form>
  </div>
</div>
