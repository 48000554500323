import { Injectable } from '@angular/core';
import { BaseProductAutocomplete, PartialData, StoreProductLevel2, StoreType } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { BaseProductAutocompleteApiService } from './base-product-autocomplete-api.service';

@Injectable()
export class BaseProductAutocompleteService {

  constructor(
    private api: BaseProductAutocompleteApiService,
  ) { }

  /**
   * Gets the base product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    storeType: StoreType | '' = '',
    filters: PartialData = {},
    offset: number = 0,
    limit: number = 30,
  ): Observable<BaseProductAutocomplete[]> {
    return this.api.getProductsPredictions(keyword, offset, limit, '', 'farmvproduct', storeType, filters);
  }


  /**
   * Gets the product variety for the specified search term.
   */
  getProductsVariety(
    text: string,
    offset: number = 0,
    limit: number = 30,
  ): Observable<StoreProductLevel2[]> {
    return this.api.getProductsVariety(text, offset, limit);
  }


  /**
   * Gets the base product varieties prediction for the specified search term.
   */
  getVarietiesPredictions(
    keyword: string,
    baseProductId: string,
    storeType: StoreType | '' = '',
    offset: number = 0,
    limit: number = 30,
  ): Observable<BaseProductAutocomplete[]> {
    return this.api.getProductsPredictions(keyword, offset, limit, baseProductId, 'VARIETY', storeType);
  }
}
