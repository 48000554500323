import { Injector, Directive } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { IdValueMap, StoreBaseProductBasicDetails } from '@kalgudi/types';
import { merge, of } from 'rxjs';
import { catchError, filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiCreateProjectType } from './kalgudi-create-project-type';

@Directive()
export abstract class KalgudiCreateCropCalendar extends KalgudiCreateProjectType {

  projectDuration = 0;

  seasonsList: IdValueMap[] = [
    {id: 'KHARIF', value: 'Kharif'},
    {id: 'RABI', value: 'Rabi'},
    {id: 'ZAID', value: 'Zaid'}
  ];

  units = [
    { id: 'grams', value: 'Grams' },
    { id: 'kgs', value: 'Kgs' },
    { id: 'tons', value: 'Tons' },
    { id: 'quintals', value: 'Quintals' },
    { id: 'pieces', value: 'Pieces' },
    { id: 'milliliters', value: 'Milliliters' },
    { id: 'liters', value: 'Liters' }
  ];

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  get cropField(): AbstractControl {
    return this.projectForm.get('crop');
  }

  get cropFieldValue(): StoreBaseProductBasicDetails {
    return this.cropField.value;
  }

  get varietyField(): AbstractControl {
    return this.projectForm.get('variety');
  }

  get varietyFieldValue(): StoreBaseProductBasicDetails {
    return this.varietyField.value;
  }

  get startDateField(): AbstractControl {
    return this.projectForm.get('startDate');
  }

  get endDateField(): AbstractControl {
    return this.projectForm.get('endDate');
  }

  /**
   * Getter for season start date and end date
   */
  get seasonStartDateField(): AbstractControl {
    return this.projectForm.get('seasonDetails.seasonStartDate');
  }

  get seasonEndDateField(): AbstractControl {
    return this.projectForm.get('seasonDetails.seasonEndDate');
  }

  /**
   * Getter for season details from project type form group
   */
  get seasonDetails(): AbstractControl {
    return this.projectForm.get('seasonDetails');
  }

  /**
   * Getter for season title from season details form group
   */
  get seasonTitle(): AbstractControl {
    return this.seasonDetails.get('seasonTitle');
  }

  /**
   * Getter for actual yield from season details form group
   */
  get actualYield(): AbstractControl {
    return this.seasonDetails.get('actualYield');
  }

  /**
   * Form group for the specific page type
   */
  protected get projectTypeForm(): FormGroup {

    return this.fb.group({
      crop: [{ value: '', disabled: this.editing }, Validators.required],
      variety: [{ value: {}, disabled: true }],
      year: [''],
      startDate: [''],
      endDate: [''],
      seasonDetails: this.fb.group({
        seasonTitle: [''],
        seasonStartDate: [''],
        seasonEndDate: [''],
        acres: ['', Validators.pattern("^[0-9]*$")],
        farmers: ['', Validators.pattern("^[0-9]*$")],
        expectedYield: this.fb.group({
          value: ['', Validators.pattern("^[0-9]*$")],
          unit: ['']
        }),
        actualYield: this.fb.group({
          value: ['', Validators.pattern("^[0-9]*$")],
          unit: ['']
        }),
      })
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Initializes the project form
   */
  protected initProjectForm() {

    super.initProjectForm();

    this.cropField.valueChanges
      .pipe(
        takeUntil(this.destroyed$),

        filter(_ => !this.editing)
      )
      .subscribe(_ => this.cropField.invalid ? this.varietyField.disable() : this.varietyField.enable());

    this.subscribeToProjectDurationChanges();
  }

  /**
   * Subscribes to project duration changes and calculates the project duration
   * in number base on the date.
   */
  private subscribeToProjectDurationChanges(): void {

    // Calculate project duration whenever the start or end date changes
    merge(
      this.startDateField.valueChanges,
      this.endDateField.valueChanges,
    ).pipe(
      takeUntil(this.destroyed$),

      // Calculate the project duration
      map(_ =>
        this.calculateProjectDuration(
          new Date(this.startDateField.value),
          new Date(this.endDateField.value)
        )
      ),

      // Handle if any error which date conversion
      catchError((e) => of(0))
    ).subscribe(duration => {
      this.projectDuration = duration;
    });
  }

  /**
   * Calculates the project duration. A project duration is the number of days
   * the project carried out between two days.
   */
  private calculateProjectDuration(startDate: any, endDate: any): number {

    let duration = 0;

    try {
      // Take the difference between the dates and divide by milliseconds per day.
      // Round to nearest whole number to deal with DST.
      // @see https://stackoverflow.com/a/543152/2401088
      duration = Math.round((endDate - startDate) / (1000*60*60*24));
    } catch(e) {
      duration = 0;
    }

    return duration;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
