import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ProfileLog } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-log-tile',
  templateUrl: './profile-log-tile.component.html',
  styleUrls: ['./profile-log-tile.component.scss']
})
export class ProfileLogTileComponent implements OnInit, OnChanges {

  @Input()
  profileLog: ProfileLog;

  defaultProfilePic = 'https://kalgudi.com/data/profilepics/M01mlud0PRFREG2019071020061802UNH001.png';

  pageProfilePic: string;

  constructor(@Inject(KL_ENV) private environment: KalgudiEnvironmentConfig) { }

  ngOnInit() {
  }

  ngOnChanges() {

    let url = '';
    if (this.profileLog.pageDetails && this.profileLog.pageDetails.pageProfilePic) {
      url = `url(${this.environment.domain + this.profileLog.pageDetails.pageProfilePic})`;
    }

    this.pageProfilePic = `${url ? url + ', ' : ''}url(${this.defaultProfilePic})`;
  }
}
