<div id="land-details-agro-tagging-table">
  <div class="tagging-title mt-4 pb-2 py-1 pl-1">Farming History</div>

  <mat-card class="p-0" *ngIf="agroLandDetails.length > 0">
    <main fxLayout="row wrap" class="season-tiles" fxLayoutGap.xs="0">
      <section class="season-tile py-2 px-2" [ngClass]="{'mr-0 border-bottom-0': last,'border-right-0': i % 2 !== 0}" *ngFor="let item of  agroLandDetails;let i = index; let last = last" fxFlex="calc(50% - 10px)" fxFlex.xs="100%">
        <div class="h-100" fxLayout fxLayoutAlign="space-between">
          <!-- Season details -->
          <div fxFlex="73%" fxLayout="row">
            <div *ngIf="item?.crop?.attachments && item?.crop?.attachments?.length && item?.crop?.attachments[0]?.attachments && item?.crop?.attachments[0]?.attachments?.length; else noImage">
              <img [src]="item?.crop?.attachments[0]?.attachments[0]?.url | prefixDomain" class="product-image mt-1"
                [klErrorImages]="[
                (item?.crop?.attachments[0]?.attachments[0]?.url) | prefixDomain,
                'assets/images/plant.png'
                ]">
            </div>

            <ng-template #noImage>

              <svg-icon [applyCss]="true" class="text-center mt-1" src="assets/svgs/farm-crop.svg"
                [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
            </ng-template>

            <div fxLayout="column" class="ml-2">
              <div>
                <span class="product-variety mw-100 text-truncate">{{ item?.crop?.productName }}</span>
              </div>
              <div class="land-size mt-n1">
                <span>{{ item?.land?.landSize?.value }}</span>
                <span>{{ item?.land?.landSize?.unit }}</span>
              </div>
            </div>
          </div>
          <!--/ Season details -->

          <!-- Actions section -->
          <div class="h-100" fxLayout="column" *ngIf="!isLandEditable" fxLayoutAlign="space-between end">
            <div>
              <a class="edit-text" translate (click)="editSeason(item)">Edit</a>
            </div>
          </div>
          <!--/ Actions section -->
        </div>

        <div class="season-dates mt-n3" fxLayout fxLayoutAlign="space-between end">
          <div class="season">
            <span class="text-truncate d-block">Season: {{ item?.title }}</span>
          </div>
          <div>
            <span>{{ item?.LUT | date:'dd MMM yyyy' }}</span>
          </div>
        </div>
      </section>
    </main>

  </mat-card>

  <mat-card class="noContent px-5 py-3 text-muted" *ngIf="!agroLandDetails.length">
    <kl-no-content
      text="No farming history added for this land"
      [progress]="progress"
      progressText="Loading agro tagging...">
    </kl-no-content>
  </mat-card>
</div>

