<section class="geo-tag-marker geo-tag-wrapper position-relative">
  <div class="text-center tagged-land" *ngIf="LabelPositionTop">
    <span class="my-3" *ngIf="location">
      <!-- Lat & long -->
      <span fxLayout fxLayoutAlign="space-between center" *ngIf="showLatLong">
        <span class="text-muted">Latitude & Longitude</span>
        <span class="font-weight-bold">{{latitude}}<span *ngIf="longitude">, </span>{{longitude}}</span>
      </span>
      <!-- / Lat & long -->

    </span>
    <span class="text-danger" *ngIf="error">
      {{error}}
    </span>
  </div>

  <agm-map
    [mapTypeId]="mapTypeId"
    [streetViewControl]="false"
    class="geo position-relative"
    [fullscreenControl]="true"
    [zoom]="zoom"
    [zoomControlOptions]="zoomControlOptions"
    [latitude]="+location?.latitude || +DEFAULT_LOCATION?.latitude"
    [longitude]="+location?.longitude || +DEFAULT_LOCATION?.longitude">

    <agm-marker *ngIf="location"
      iconUrl="assets/icons/location.png"
      [latitude]="+location?.latitude"
      [longitude]="+location?.longitude"
      (dragEnd)="onMarkerDragEnd(latitude, longitude, $event)"
      [markerDraggable]="markerDraggable">
    </agm-marker>
  </agm-map>

  <!-- <div class="position-absolute w-100 loading-text" fxLayout fxLayoutAlign="center center" *ngIf="progress">

    <p class="text-white mb-0">Loading...</p>
  </div> -->

  <div *ngIf="locationLabel" class="mt-1" fxLayout fxLayoutAlign="end center" fxLayoutAlign.xs="start center">
    <p>{{locationLabel}}</p>
  </div>

<!-- <button (click)="getUpdatedPath()">GET PATH</button>
  <button (click)="reset()">RESET</button> -->

  <p class="text-center tagged-land" *ngIf="!LabelPositionTop">
    <span class="text-success" *ngIf="location">
      <strong>
        Geo Tagged land: <span>{{location?.lat}},{{location.lng}}</span>
      </strong>
    </span>
    <span class="text-danger" *ngIf="error">
      {{error}}
    </span>
  </p>
</section>
