import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { KalgudiProjectFullview } from '../../classes/project-fullview';

@Component({
  selector: 'kl-project-fullview',
  templateUrl: './project-fullview.component.html',
  styleUrls: ['./project-fullview.component.scss']
})
export class ProjectFullviewComponent extends KalgudiProjectFullview implements OnInit {

  @Input()
  viewType: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute,
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private routingService: KalgudiProjectRouteConfig,
  ) {

    super(notification, util, route, injector);
  }

  ngOnInit() {

    // this.initStream();

  }

  showProjectsAction(projectId: string, menu: any) {


    if (this.loggedInUserTasks) {

      this.routingService.toTasksList({ type: menu.id });
    } else {

      this.routingService.toProject({projectId}, { type: menu.id });
    }

  }

  onDestroyed(): void {}

}
