<section class="geo-location-marker geo-fence-wrapper">

  <agm-map
    [mapTypeId]="mapTypeId"
    [streetViewControl]="false"
    [fullscreenControl]="true"
    [zoom]="zoom"
    [zoomControlOptions]="zoomControlOptions"
    [latitude]="+location?.latitude || +DEFAULT_LOCATION?.latitude"
    [longitude]="+location?.longitude || +DEFAULT_LOCATION?.longitude"
    (mapReady)="onMapReady($event)">

    <agm-marker *ngIf="location && (showDot || showPin)"
      [iconUrl]=" showPin ? 'assets/icons/location.png' : 'assets/images/current-location.png'"
      [latitude]="location?.latitude"
      [longitude]="location?.longitude">
    </agm-marker>

  </agm-map>

  <!-- <button (click)="getUpdatedPath()">GET PATH</button>
  <button (click)="reset()">RESET</button> -->
</section>
