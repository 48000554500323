import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-farmer-no-content',
  templateUrl: './farmer-no-content.component.html',
  styleUrls: ['./farmer-no-content.component.scss']
})
export class FarmerNoContentComponent implements OnInit {

  @Input()
  buttonTitle: string;

  @Input()
  svgIcon: string;

  @Input()
  backgroundImage: string;

  @Input()
  noContentHeight: string;

  @Input()
  backgroundImageHeight: string;

  @Input()
  noContentText: string;

  @Output()
  action = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }


  onActionButtonClicked(): void {
    this.action.emit();
  }

}
