import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { LogsResponseData, SmsLog } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

import { ProgramStateService } from '../../../services/program-state.service';
import { LogsListService } from '../services/logs-list.service';


@Directive()
export abstract class KalgudiSmsLogsList extends KalgudiInboxStream<SmsLog> {

  @Input()
  smsId: string;

  pageId: string;

  filterType: string;

  logsCount: number;
  deliveredLogsCount: number;
  failedLogsCount: number;
  pendingLogsCount: number;

  progress: boolean;

  // Dependencies
  private logsListService: LogsListService;
  private programStateService: ProgramStateService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    // Manually inject dependencies
    this.logsListService      = this.injector.get(LogsListService);
    this.programStateService  = this.injector.get(ProgramStateService);

    this.pageLimit = 10;

    // Get entity id
    this.programStateService.id$
      .pipe(
        first(),
      ).subscribe(id => {

       this.pageId = id;
      });
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls this method whenever the selected filter value changes
   */
  filterTypeChanged(filterType: string) {
    this.filterType = filterType;
    this.resetStream();
  }

  /**
   * Calls method to download logs list
   */
  downloadLogs() {
    this.progress = true;
    this.logsListService.downloadLogsList(this.smsId)
      .pipe(

        //Downloads logs
        switchMap(res => window.open(res.url, '_self')),

        //Turn of the progress
        finalize(() => this.progress = false),
      )
      .subscribe(
        // res => console.log(res)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Fetches logs list
   */
  protected streamApi( offset: number, limit: number): Observable<LogsResponseData> {
    return this.logsListService.fetchLogsList(this.smsId, this.pageId, offset, limit, this.filterType)
      .pipe(
        tap(res => {
            this.logsCount          = res.count;
            this.deliveredLogsCount = res.success;
            this.failedLogsCount    = res.failed;
            this.pendingLogsCount   = res.pending;
          }
        )
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
