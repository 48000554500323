import { Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { IdValueMap, KalgudiDealsWith, KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs/internal/Observable';
import { filter, finalize, map, startWith, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { AddCropDialogComponent } from '../components/add-crop-dialog/add-crop-dialog.component';
import { KalgudiProductService } from '../services/kalgudi-product.service';

@Directive()
export abstract class KalgudiAddCropProduct extends KalgudiDestroyable {

  @Input()
  myCrops: KalgudiDealsWith[] = [];

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  commoditiesLink: string;

  @Input()
  profileKey: string;

  @Input()
  hideSearch: boolean = false;

  @Input()
  showBaseProductSearchField: boolean = false;

  @Output()
  closeCropDialog = new EventEmitter();

  isShowDetails: boolean;
  productList: any[];
  showProductFields: boolean = true;

  crops: any[] = [];
  progress: boolean;

  filteredOptions: Observable<any[]>;
  productAutoComplete = new FormControl('');

  selectedBaseCategory: IdValueMap;

  baseProduct = new FormControl('');

  private dialogsService: KalgudiDialogsService;
  private profileStateService: KalgudiProfileStateService;
  private productService: KalgudiProductService;

  constructor(protected injector: Injector) {
    super();

    this.dialogsService      = this.injector.get(KalgudiDialogsService);
    this.profileStateService = this.injector.get(KalgudiProfileStateService);
    this.productService      = this.injector.get(KalgudiProductService);



    this.getTopProducts();
  }

  getTopProducts(): void {
    this.progress = true;

    this.productService.getTopProducts()
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.processProductsMetaData(res)
      )
  }

  /**
   * Toggle of show product form field
   */
  showProductForm() {
    this.showProductFields = !this.showProductFields;
    this.getTopProducts();
    this.productAutoComplete.reset();
  }

  processProductsMetaData(products: any) {
    this.crops = [];
    let productList: any[] = [];

    this.productList = productList;

    Object.keys(products).forEach(p => {

      this.crops.push({title: p, items: products[p]})
    });

    this.selectUserCrops();

    Object.values(products).forEach((products: any) => {

      products.forEach(bp => {
        const product = {
          VProductId: bp.VProductId,
          VProductName: bp.VProductName,
          attachments: bp.attachments,
          baseCategory: bp.baseCategory,
          selected: bp.selected,
          type: 'BASE_PRODUCT',
          aka: bp.aka
        }

        productList.push(product);

      });

    })

    this.filteredOptions = this.baseProduct.valueChanges
      .pipe(
        startWith(''),
        map(value => value ? (typeof value === 'string' ? value : value.VProductName || value.aka) : ''),
        map(name => name ? this._filter(name) : (productList.length > 10 ? productList : productList))
      );

  }

  /**
   * Filter the product name from the product list
   */
  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();
    return this.productList.filter(option => (option.VProductName.toLowerCase().indexOf(filterValue) === 0) || (option.aka.toLowerCase().indexOf(filterValue) === 0));
  }

  /**
   * Autocomplete display function. Alters the default model rendering
   * behavior of autocomplete input field.
   */
  displayFn(productDetails: any): string {
    return productDetails && productDetails.productName
      ? productDetails.productName
      : '';
  }

  /**
   * Calling to open edit product dialo box
   */
  onProductSelection(product: any) {
    this.editCrop(product, !product.selected);
  }

  selectUserCrops(): void {

    if (this.crops.length) {
      this.crops.forEach(c => {

        c.items.forEach(p => {

          this.myCrops.forEach(m => {

            if(m.baseProductId === p.VProductId) {
              p.selected = true;

            }
          })

        });
      })

    }
  }

  showDetails() {
    this.isShowDetails = true;
  }


  getProductsList(bpId: string): any {

    let crops;
    if (this.myCrops && this.myCrops.length) {

      crops = this.myCrops.reduce((bp, p) =>
        {
          bp[p.baseProductId] = (bp[p.baseProductId] || []);

          // console.log('p', p)
          bp[p.baseProductId].push(p);
          // console.log('bp', bp)
          return bp;
        },
        {},
      );

      return crops[bpId] ? crops[bpId] : null;

    }
  }

  searchNewCrop(baseCategory?: IdValueMap) : void {
    this.selectedBaseCategory = baseCategory;

    this.showAddProductsDialog();
  }

  editCrop(product: any, isNewProduct): void {

    let bp;

    if(isNewProduct) {

      bp = [{
        availableStock: {value: 0, unit: ""},
        baseProductId: product.VProductId,
        baseProductName: product.VProductName,
        productId: product.VProductId,
        productName: product.VProductId,
        storeType: "BIZ_STORE",
        attachments: product.attachments,
        baseProductAttachments: product.attachments
      }]
    } else {
      bp = this.getProductsList(product.VProductId);
    }


    this.showAddProductsDialog(bp, isNewProduct, product.aka)
  }

  openAddProductDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add crop',
      acceptButtonTitle: 'Add crop',
      rejectButtonTitle: 'Cancel',
      data: {
        showVariety: false,
        pageId: this.pageId,
        isAssisted: this.isAssisted,
        commoditiesLink: this.commoditiesLink,
        profileKey: this.profileKey
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-add-crop-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openAddProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        tap(_ => {
          this.closeCropDialog.emit();
        } ),

        filter(r => r.accepted),
      )
      .subscribe();
  }

  /**
   * Shows add products dialog
   */
  showAddProductsDialog(product: any = null, isNewProduct: boolean = false, aka: string = ''): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add crop',
      acceptButtonTitle: 'Add crop',
      rejectButtonTitle: 'Cancel',
      data: {
        aka: aka ? aka : '',
        showVariety: false,
        product: product ? product : null,
        isNewProduct: isNewProduct,
        selectedBaseCategory: this.selectedBaseCategory,
        pageId: this.pageId,
        isAssisted: this.isAssisted,
        commoditiesLink: this.commoditiesLink,
        profileKey: this.profileKey
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-add-crop-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openAddProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      )
      .subscribe(
        res => {
          this.selectUserCrops();
          this.selectedBaseCategory = null;

        }
      )
  }



  /**
   * Shows the add products web or mobile dialog
   */
  private openAddProductsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {


    return this.dialogsService.openDialog(AddCropDialogComponent, dialogConfig, matDialogConfig);
  }

  /**
   * Subscribe to value change of product auto complete field
   */
  subscribeToValueChanges() {

    this.productAutoComplete.valueChanges
      .pipe (
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        if(res) {
          this.crops = [];
          this.crops.push({title: '', items: [res]})
        }
      });
  }
}
