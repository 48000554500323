import { Injectable } from '@angular/core';
import { KalgudiStepper } from '@kalgudi/common';

import { PageCreationStateDataMap, PageCreationStates } from '../models';

/**
 * Page creation state management service. Page creation happens in multiple steps.
 * It defines a protocol to manage different steps of a page creation.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class CreatePageStateService extends KalgudiStepper<PageCreationStates, PageCreationStateDataMap> {


  /**
   * Default page creation state data
   */
  protected readonly DEFAULT_CREATION_STATE_DATA: PageCreationStateDataMap = {
    CHOOSE_PAGE_TYPE: { data: ''},
    FILL_COMMON_PAGE_DETAILS: { data: ''},
    FILL_SPECIFIC_PAGE_DETAILS: { data: ''},
    CREATE_PAGE: { data: ''},
    PAGE_CREATED: { data: ''},
  };

  /**
   * Page creation steps order
   */
  protected readonly CREATION_STATE_ORDER: PageCreationStates[] = [
    'CHOOSE_PAGE_TYPE',
    'FILL_COMMON_PAGE_DETAILS',
    'FILL_SPECIFIC_PAGE_DETAILS',
    'CREATE_PAGE',
    'PAGE_CREATED'
  ];

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  constructor() {

    super();

    this.initState(this.CREATION_STATE_ORDER[0], this.DEFAULT_CREATION_STATE_DATA);
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }


}
