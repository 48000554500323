import { Component, forwardRef, Input, OnInit, Inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { KalgudiUploadService, GeoLocationService } from '@kalgudi/core';
import { KalgudiUploadImage } from '../../classes/kalgudi-upload-image';
import { KalgudiImagePickerService } from '../../services/kalgudi-image-picker.service';
import { Router } from '@angular/router';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UploadImageComponent),
  multi: true,
};

@Component({
  selector: 'kl-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class UploadImageComponent extends KalgudiUploadImage implements OnInit {

  @Input()
  showAddOnLast: boolean;

  @Input()
  showCamera: boolean;
  isTransactionPage: boolean = false;

  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    protected uploadService: KalgudiUploadService,
    protected imagePickerService: KalgudiImagePickerService,
    protected geoLocationService: GeoLocationService,
    protected router: Router,
  ) {
    super(
      uploadService, imagePickerService, geoLocationService, router
    );
  }

  ngOnInit(): void {
    if (this.router.url && this.router.url.includes('transactions')) {
      this.isTransactionPage =  true
    }
  }

  onDestroyed(): void {}

}
