import { EventEmitter, Input, OnDestroy, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';

/**
 * Base class definition for all Kalgudi program types.
 * `Calling initPageForm() in child class is mandatory, it will initializes the form`
 */
@Directive()
export abstract class KalgudiCreatePageType<T> implements OnDestroy {

  @Input()
  pageForm: FormGroup;

  @Output()
  pageFormChange = new EventEmitter<FormGroup>();


  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  private destroyed = new Subject<any>();

  constructor(
    protected formBuilder: FormBuilder
  ) {}



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {

    this.destroyed.next();
    this.destroyed.complete();

    this.onDestroyed();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


   // --------------------------------------------------------
  // #region Abstract classes to be inherited by children
  // --------------------------------------------------------

  /**
   * Form group for the specific page type
   */
  protected abstract get pageTypeForm(): FormGroup;

  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Initializes the page form
   */
  protected initPageForm() {

    const form = this.pageTypeForm;

    form.patchValue(this.pageForm.value);

    this.pageForm = form;

    this.pageFormChange.emit(this.pageForm);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
