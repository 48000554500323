import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { KalgudiUtilityService } from '../../services/kalgudi-util.service';
import { DataStore } from './data-store';

/**
 * Wrapper class to store data in the client local data storage.
 * It stores data in the client browser localStorage.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class LocalStorageDS extends DataStore {

  constructor(
    private util: KalgudiUtilityService,
  ) {
    super();
  }

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem(key: string): any;
  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string): T;
  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem(key: string, async?: boolean): Observable<any>;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string, async?: boolean): Observable<T>;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string, async?: boolean): Observable<T> | Observable<any> | T | any {

    const res = this.util.toJson(localStorage.getItem(key));

    return async ? of(res) : res;
  }


  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any): boolean;

  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any, async?: boolean): Observable<boolean>;

  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any, async?: boolean): Observable<boolean> | boolean {

    // Convert object to json string
    const jsonString = typeof value === 'string' ? value : this.util.toString(value);

    // Store json string to localStorage
    localStorage.setItem(key, jsonString);

    return async ? of(true) : true;
  }


  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string): boolean;

  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string, async?: boolean): Observable<boolean>;

  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string, async?: boolean): Observable<boolean> | boolean {

    localStorage.removeItem(key);

    return async ? of(true) : true;
  }


  /**
   * Clears all data stored in the data store.
   */
  clear(): boolean;

  /**
   * Clears all data stored in the data store.
   */
  clear(async?: boolean): Observable<boolean>;

  /**
   * Clears all data stored in the data store.
   */
  clear(async?: boolean): Observable<boolean> | boolean {
    localStorage.clear();

    return async ? of(true) : true;
  }

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): boolean;

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): Observable<boolean>;

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): Observable<boolean> | boolean {

    const hasItem = !!(localStorage.getItem(key));

    return async ? of(hasItem) : hasItem;
  }
}
