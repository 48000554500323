import { Component, OnInit } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-credits-header',
  templateUrl: './credits-header.component.html',
  styleUrls: ['./credits-header.component.scss']
})
export class CreditsHeaderComponent implements OnInit {

  userDetails: KalgudiUser;

  constructor(private kalgudiApp: KalgudiAppService) { }

  ngOnInit() {
    this.userDetails = this.kalgudiApp.profileLocal;
  }

}
