import { Injector, Input, Directive } from '@angular/core';
import { trimSpecialCharacters } from '@kalgudi/core';
import { ChartData, PageProjectAnalytics, PageQaAnalytics, PageShareAnalytics, PageTaskAnalytics } from '@kalgudi/types';

import { KalgudiPageAnalyticsService } from '../services/page-analytics-activities.service';

@Directive()
export abstract class KalgudiPageActivityAnalytics {

  @Input()
  pageId: string;

  projectStats: PageProjectAnalytics;
  sharesStats: PageShareAnalytics;
  qaStats: PageQaAnalytics;
  taskStats: PageTaskAnalytics;
  shareTypeStats: ChartData[];
  qaTypeStats: ChartData[];
  projectTypeStats: ChartData[];
  taskTypeStats: ChartData[];


  private pageAnalyticsService: KalgudiPageAnalyticsService;

  constructor(
    protected injector: Injector,
  ) {
    this.pageAnalyticsService = this.injector.get(KalgudiPageAnalyticsService);
  }

  /**
   * Calls api method to get shares stats
   */
  getSharesAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchSharesAnalytics(pageId)
      .subscribe(res => {
        this.sharesStats = res;
        this.shareTypeStats = this.convertObjectToArray(res.updateTypeStats);
      } );
  }

  /**
   * Calls api method to get qa stats
   */
  getQaAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchQaAnalytics(pageId)
      .subscribe(res => {
        this.qaStats = res;
        this.qaTypeStats = this.convertObjectToArray(res);
      });
  }

  /**
   * Calls api method to get survey stats
   */
  getSurveysAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchSurveyAnalytics(pageId)
      .subscribe(
        // res => console.log('survey', res)
      );
  }

  /**
   * Calls api method to get project stats
   */
  getProjectsAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchProjectsAnalytics(pageId)
      .subscribe(res => {
        this.projectStats = res;
        this.projectTypeStats = this.convertObjectToArray(res.stateWiseCounts);
      });
  }

  /**
   * Calls api method to get tasks stats
   */
  getTasksAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchTasksAnalytics(pageId)
      .subscribe(res => {
        this.taskStats = res;
        this.taskTypeStats = this.convertObjectToArray(res.stateWiseCounts);
      });
  }


  /**
   * Initializes the page analytics activities
   */
  protected initStream() {
    this.getSharesAnalytics(this.pageId);
    this.getQaAnalytics(this.pageId);
    this.getSurveysAnalytics(this.pageId);
    this.getProjectsAnalytics(this.pageId);
    this.getTasksAnalytics(this.pageId);
  }

  /**
   * Convert an object to the array
   */
  private convertObjectToArray(obj: any): any[] {

    const arr = [];

    Object.entries(obj).forEach(i => {
      arr.push({
        name: trimSpecialCharacters(i[0]),
        value: i[1]
      });
    });

    return arr;
  }
}
