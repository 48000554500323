import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import {
  KalgudiAttachUrlModule,
  KalgudiCommonModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiProfilesModule } from '../../kalgudi-profiles.module';
import { AddLiveStockModule } from '../add-live-stock/add-live-stock.module';
import { KalgudiFarmerProfileModule } from '../farmer-profile/farmer-profile.module';
import { KalgudiProfileLogsModule } from '../profile-logs/profile-logs.module';
import { ProfileSocialActivitiesModule } from '../profile-social-activities/profile-social-activities.module';
import { ProfileUserTasksModule } from './../profile-user-tasks/profile-user-tasks.module';
import { AboutUsDialogComponent } from './components/about-us-dialog/about-us-dialog.component';
import { AboutUsMobileDialogComponent } from './components/about-us-mobile-dialog/about-us-mobile-dialog.component';
import { AboutUsTileComponent } from './components/about-us-tile/about-us-tile.component';
import { AddBoardMembersDialogComponent } from './components/add-board-members-dialog/add-board-members-dialog.component';
import {
  AddBoardMembersMobileDialogComponent,
} from './components/add-board-members-mobile-dialog/add-board-members-mobile-dialog.component';
import { AddBoardMembersComponent } from './components/add-board-members/add-board-members.component';
import { BoardMembersListComponent } from './components/board-members-list/board-members-list.component';
import { BoardMembersComponent } from './components/board-members/board-members.component';
import { CommonProfileHeaderComponent } from './components/common-profile-header/common-profile-header.component';
import {
  CooperativeSocietyAddSeasonFormComponent,
} from './components/cooperative-society-add-season-form/cooperative-society-add-season-form.component';
import {
  CooperativeSocietyAwardsComponent,
} from './components/cooperative-society-awards/cooperative-society-awards.component';
import {
  CooperativeSocietyCertificatesComponent,
} from './components/cooperative-society-certificates/cooperative-society-certificates.component';
import {
  CooperativeSocietyCropSeasonsComponent,
} from './components/cooperative-society-crop-seasons/cooperative-society-crop-seasons.component';
import {
  CooperativeSocietyCropsListComponent,
} from './components/cooperative-society-crops-list/cooperative-society-crops-list.component';
import {
  CooperativeSocietyCropsComponent,
} from './components/cooperative-society-crops/cooperative-society-crops.component';
import {
  CooperativeSocietyEditDialogComponent,
} from './components/cooperative-society-edit-dialog/cooperative-society-edit-dialog.component';
import {
  CooperativeSocietyEditMobileDialogComponent,
} from './components/cooperative-society-edit-mobile-dialog/cooperative-society-edit-mobile-dialog.component';
import { CooperativeSocietyEditComponent } from './components/cooperative-society-edit/cooperative-society-edit.component';
import {
  CooperativeSocietyFarmActivitySettingsComponent,
} from './components/cooperative-society-farm-activity-settings/cooperative-society-farm-activity-settings.component';
import {
  CooperativeSocietyFarmActivityComponent,
} from './components/cooperative-society-farm-activity/cooperative-society-farm-activity.component';
import {
  CooperativeSocietyLiveStockComponent,
} from './components/cooperative-society-live-stock/cooperative-society-live-stock.component';
import {
  CooperativeSocietyProfileHeaderComponent,
} from './components/cooperative-society-profile-header/cooperative-society-profile-header.component';
import {
  CooperativeSocietyProfileComponent,
} from './components/cooperative-society-profile/cooperative-society-profile.component';
import {
  FpoBankingDetailsTileComponent,
} from './components/fpo-banking-details/fpo-banking-details-tile/fpo-banking-details-tile.component';
import {
  UpdateFpoBankingDetailsDialogComponent,
} from './components/fpo-banking-details/update-fpo-banking-details-dialog/update-fpo-banking-details-dialog.component';
import {
  UpdateFpoBankingDetailsMobileDialogComponent,
} from './components/fpo-banking-details/update-fpo-banking-details-mobile-dialog/update-fpo-banking-details-mobile-dialog.component';
import {
  UpdateFpoBankingDetailsComponent,
} from './components/fpo-banking-details/update-fpo-banking-details/update-fpo-banking-details.component';
import { GaugeChartComponent } from './components/fpo-grading-details/_gauge-chart/gauge-chart.component';
import { FpoGradingTileComponent } from './components/fpo-grading-details/fpo-grading-tile/fpo-grading-tile.component';
import {
  FpoInfrastructureDialogComponent,
} from './components/fpo-infrastructure/fpo-infrastructure-dialog/fpo-infrastructure-dialog.component';
import {
  FpoInfrastructureFormComponent,
} from './components/fpo-infrastructure/fpo-infrastructure-form/fpo-infrastructure-form.component';
import {
  FpoInfrastructureMobileDialogComponent,
} from './components/fpo-infrastructure/fpo-infrastructure-mobile-dialog/fpo-infrastructure-mobile-dialog.component';
import {
  FpoInfrastructureTileComponent,
} from './components/fpo-infrastructure/fpo-infrastructure-tile/fpo-infrastructure-tile.component';
import { FpoOverViewFormComponent } from './components/fpo-over-view-form/fpo-over-view-form.component';
import { FpoOverViewComponent } from './components/fpo-over-view/fpo-over-view.component';
import { ServicesDialogComponent } from './components/service/services-dialog/services-dialog.component';
import { ServicesFormComponent } from './components/service/services-form/services-form.component';
import { ServicesListComponent } from './components/service/services-list/services-list.component';
import { ServicesMobileDialogComponent } from './components/service/services-mobile-dialog/services-mobile-dialog.component';
import { ServicesTileComponent } from './components/service/services-tile/services-tile.component';
import {
  WarehouseGodownDialogComponent,
} from './components/warehouse-godown/warehouse-godown-dialog/warehouse-godown-dialog.component';
import {
  WarehouseGodownFormComponent,
} from './components/warehouse-godown/warehouse-godown-form/warehouse-godown-form.component';
import {
  WarehouseGodownMobileDialogComponent,
} from './components/warehouse-godown/warehouse-godown-mobile-dialog/warehouse-godown-mobile-dialog.component';
import {
  WarehouseGodownTileComponent,
} from './components/warehouse-godown/warehouse-godown-tile/warehouse-godown-tile.component';
import { OrganisationProfileApiService } from './services/organisation-profile-api.service';
import { OrganisationProfileService } from './services/organisation-profile.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { BasicInfoDialogComponent } from './components/fpo-basic-info/basic-info-dialog/basic-info-dialog.component';
import { BasicInfoFormComponent } from './components/fpo-basic-info/basic-info-form/basic-info-form.component';
import { BasicInfoMobileDialogComponent } from './components/fpo-basic-info/basic-info-mobile-dialog/basic-info-mobile-dialog.component';
import { BasicInfoTileComponent } from './components/fpo-basic-info/basic-info-tile/basic-info-tile.component';
import { SocialFormComponent } from './components/social/social-form/social-form.component';
import { SocialTileComponent } from './components/social/social-tile/social-tile.component';
import { SocialDialogComponent } from './components/social/social-dialog/social-dialog.component';
import { SocialMobileDialogComponent } from './components/social/social-mobile-dialog/social-mobile-dialog.component';
import { CropFormComponent } from './components/crop-details/crop-form/crop-form.component';
import { CropTileComponent } from './components/crop-details/crop-tile/crop-tile.component';
import { CropMobileDialogComponent } from './components/crop-details/crop-mobile-dialog/crop-mobile-dialog.component';
import { CropDialogComponent } from './components/crop-details/crop-dialog/crop-dialog.component';
import { FinanceDetailsFormComponent } from './components/finance-details/finance-details-form/finance-details-form.component';
import { FinanceDetailsTileComponent } from './components/finance-details/finance-details-tile/finance-details-tile.component';
import { FinanceDetailsMobileDialogComponent } from './components/finance-details/finance-details-mobile-dialog/finance-details-mobile-dialog.component';
import { FinanceDetailsDialogComponent } from './components/finance-details/finance-details-dialog/finance-details-dialog.component';
import { BusinessActivitiesFormComponent } from './components/business-activities/business-activities-form/business-activities-form.component';
import { BusinessActivitiesTileComponent } from './components/business-activities/business-activities-tile/business-activities-tile.component';
import { BusinessActivitiesMobileDialogComponent } from './components/business-activities/business-activities-mobile-dialog/business-activities-mobile-dialog.component';
import { BusinessActivitiesDialogComponent } from './components/business-activities/business-activities-dialog/business-activities-dialog.component';

@NgModule({
  declarations: [
    CooperativeSocietyProfileHeaderComponent,
    CooperativeSocietyProfileComponent,
    CooperativeSocietyEditDialogComponent,
    CooperativeSocietyEditComponent,
    AboutUsTileComponent,
    AboutUsDialogComponent,
    AboutUsMobileDialogComponent,
    CooperativeSocietyCertificatesComponent,
    CooperativeSocietyAwardsComponent,
    BoardMembersComponent,
    AddBoardMembersComponent,
    AddBoardMembersDialogComponent,
    BoardMembersListComponent,
    AddBoardMembersMobileDialogComponent,
    CooperativeSocietyCropsComponent,
    CooperativeSocietyCropsListComponent,
    CooperativeSocietyFarmActivityComponent,
    CooperativeSocietyFarmActivitySettingsComponent,
    CooperativeSocietyEditMobileDialogComponent,
    FpoInfrastructureTileComponent,
    FpoInfrastructureDialogComponent,
    FpoInfrastructureMobileDialogComponent,
    FpoInfrastructureFormComponent,
    ServicesTileComponent,
    ServicesFormComponent,
    ServicesDialogComponent,
    ServicesMobileDialogComponent,
    ServicesListComponent,
    WarehouseGodownFormComponent,
    WarehouseGodownTileComponent,
    WarehouseGodownDialogComponent,
    WarehouseGodownMobileDialogComponent,
    FpoOverViewComponent,
    FpoOverViewFormComponent,
    CooperativeSocietyCropSeasonsComponent,
    UpdateFpoBankingDetailsComponent,
    UpdateFpoBankingDetailsDialogComponent,
    UpdateFpoBankingDetailsMobileDialogComponent,
    FpoBankingDetailsTileComponent,
    CooperativeSocietyAddSeasonFormComponent,
    CommonProfileHeaderComponent,
    CooperativeSocietyLiveStockComponent,
    FpoGradingTileComponent,
    GaugeChartComponent,
    BasicInfoDialogComponent,
    BasicInfoFormComponent,
    BasicInfoMobileDialogComponent,
    BasicInfoTileComponent,
    SocialFormComponent,
    SocialTileComponent,
    SocialDialogComponent,
    SocialMobileDialogComponent,
    CropFormComponent,
    CropTileComponent,
    CropMobileDialogComponent,
    CropDialogComponent,
    FinanceDetailsFormComponent,
    FinanceDetailsTileComponent,
    FinanceDetailsMobileDialogComponent,
    FinanceDetailsDialogComponent,
    BusinessActivitiesFormComponent,
    BusinessActivitiesTileComponent,
    BusinessActivitiesMobileDialogComponent,
    BusinessActivitiesDialogComponent
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,

    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatDividerModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTabsModule,

    FormsModule,
    ReactiveFormsModule,

    KalgudiProfilesModule,
    KalgudiFarmerProfileModule,
    KalgudiPipesModule,
    KalgudiCommonModule,
    KalgudiNoContentModule,
    KalgudiButtonModule,
    KalgudiGooglePlacesModule,
    KalgudiDirectivesModule,
    KalgudiAttachUrlModule,
    KalgudiImageChipListModule,
    KalgudiImagePickerModule,
    KalgudiStoreProductsSearchModule,
    KalgudiTimePickerModule,

    TranslateModule,
    KalgudiProfileLogsModule,
    ProfileSocialActivitiesModule,
    ProfileUserTasksModule,
    AddLiveStockModule,
  ],
  exports: [
    CooperativeSocietyProfileComponent,
    CooperativeSocietyCropSeasonsComponent,
    CooperativeSocietyProfileHeaderComponent,
    CooperativeSocietyEditComponent,
    AboutUsTileComponent,

    CooperativeSocietyCropsComponent,
    CooperativeSocietyCropsListComponent,

    CooperativeSocietyLiveStockComponent,
  ],
  entryComponents: [
    CooperativeSocietyEditDialogComponent,
    AddBoardMembersDialogComponent,
    AddBoardMembersMobileDialogComponent,
    CooperativeSocietyEditMobileDialogComponent,
    UpdateFpoBankingDetailsDialogComponent,
    UpdateFpoBankingDetailsMobileDialogComponent,
    FpoInfrastructureDialogComponent,
    FpoInfrastructureMobileDialogComponent,

    AboutUsDialogComponent,
    AboutUsMobileDialogComponent,

    WarehouseGodownDialogComponent,
    WarehouseGodownMobileDialogComponent,

    ServicesDialogComponent,
    ServicesMobileDialogComponent,
  ],
  providers: [
    OrganisationProfileService,
    OrganisationProfileApiService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ]
})
export class CooperativeSocietyProfileModule {}
