import { Component, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUser } from '@kalgudi/types';

import { KalgudiEmployeeProfileInfo } from '../../classes/kalgudi-employee-profile-info';

@Component({
  selector: 'kl-employee-info',
  templateUrl: './employee-info.component.html',
  styleUrls: ['./employee-info.component.scss']
})
export class EmployeeInfoComponent extends KalgudiEmployeeProfileInfo implements OnInit {

  @Input()
  profileDetails: KalgudiUser;

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
