import { Component, Input, OnInit } from '@angular/core';
import { StoreProductLevel2, StoreProductLevel3 } from '@kalgudi/types';

@Component({
  selector: 'kl-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @Input()
  level3ProductDetails: StoreProductLevel3;

  @Input()
  productLevel2Details: StoreProductLevel2;

  @Input()
  description: string;

  constructor() { }

  ngOnInit() {
  }

}
