import { Component, Input, OnInit } from '@angular/core';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-users-stack-tile',
  templateUrl: './users-stack-tile.component.html',
  styleUrls: ['./users-stack-tile.component.scss']
})
export class UsersStackTileComponent implements OnInit {

  @Input()
  users: KalgudiUserBasicDetails[];

  @Input()
  maxValue: number;

  constructor() { }

  ngOnInit() {
  }

}
