import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { LazyApiSyncInterceptor } from '../model';

@Injectable()
export class NoopApiSyncService implements LazyApiSyncInterceptor {

  pauseSync$: Observable<boolean>;
  resumeSync$: Observable<boolean>;

  constructor() {
    this.pauseSync$  = EMPTY;
    this.resumeSync$ = EMPTY;
  }

}
