import { Injectable } from '@angular/core';
import {
  ApiSurveyActivityLogResponseData,
  KalgudiSurveyAnalyticsResponse,
  KalgudiSurveyDetails,
  SurveyBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SurveyPageActions } from '../constants';
import { KalgudiSurveyApiService } from './kalgudi-survey-api.service';
import { SurveyStateService } from './survey-state.service';

@Injectable()
export class KalgudiSurveyService {

  constructor(
    private api: KalgudiSurveyApiService,
    private surveyState: SurveyStateService,
  ) {}

  /**
   * Gets, survey details from Api
   */
  getSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {
    return this.api.getSurvey(surveyId);
  }

  /**
   * Updates the survey details. Calls the Api to update survey details.
   */
  updateSurveyDetails(surveyId: string, surveyDetails: SurveyBasicDetails): Observable<KalgudiSurveyDetails> {
    return this.api.updateSurveyDetails(surveyId, surveyDetails)
      .pipe(
        tap(res => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, res))
      );
  }

  /**
   * Calls the delete dialog Api.
   */
  deleteSurvey(surveyId: string): Observable<boolean> {

    //returns the api service.
    return this.api.deleteSurvey(surveyId);
  }

  /**
   * Gets, survey activity logs
   */
  getActivityLogs(surveyId: string, offset: number, limit: number): Observable<ApiSurveyActivityLogResponseData> {

    return this.api.getActivityLogs(surveyId, offset, limit);
  }

  /**
   * Publishes a survey
   */
  publishSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {

    return this.api.publishSurvey(surveyId)
      .pipe(
        // Update survey details stored in state
        tap(survey => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, survey))
      );
  }

  /**
   * Publishes a survey
   */
  unPublishSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {

    return this.api.unPublishSurvey(surveyId)
      .pipe(
        // Update survey details stored in state
        tap(survey => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, survey))
      );
  }

  /**
   * Publishes a survey
   */
  sendReminder(surveyId: string): Observable<KalgudiSurveyDetails> {

    return this.api.sendReminder(surveyId)
      .pipe(
        // Update survey details stored in state
        tap(survey => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, survey))
      );
  }

  /**
   * Calls the fetch survey analytics Api.
   */
  getSurveyAnalytics(surveyId: string): Observable<KalgudiSurveyAnalyticsResponse> {

    //returns the api service.
    return this.api.fetchSurveyAnalytics(surveyId);
  }

  /**
   * Calls the send survey analytics Api.
   */
  sendSurveyAnalytics(surveyId: string, email: string): Observable<KalgudiSurveyAnalyticsResponse> {

    //returns the api service.
    return this.api.sendSurveyAnalytics(surveyId, email);
  }
}
