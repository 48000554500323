import { Component, Injector, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiBulkMembersAddition } from '../classes/kalgudi-bulk-members-addition';

@Component({
  selector: 'kl-bulk-members-addition',
  templateUrl: './bulk-members-addition.component.html',
  styleUrls: ['./bulk-members-addition.component.scss']
})
export class BulkMembersAdditionComponent extends KalgudiBulkMembersAddition implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {

    this.subscribeToAddMembersFormOnValueChanges();
  }

  onDestroyed(): void {}
}
