<section id="task-details-tile">

  <div class="task-details" fxLayout="row" fxLayoutGap="10px">

    <div class="task-tile" fxFlex="calc(100% - 30px)" fxLayout="row" fxLayoutGap="10px">
      <div class="image mt-1" fxFlex="30px">
        <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '30', 'height.px': '30'}"
          [applyCss]="true" class="mr-3 projects-icon"></svg-icon>
      </div>

      <div fxLayout="column" fxLayoutGap="5px" fxFlex="calc(100% - 40px)">
        <p class="task font-weight-bold my-0 text-truncate">{{ taskActivityDetails?.taskDetails?.title | titlecase }}</p>
        <p class="task-name my-0 text-truncate">{{ taskActivityDetails?.projectTitle | titlecase }}<span>,</span>
          <span class="text-muted mx-1">Task type:</span>
          <span class="task-type text-truncate">{{ taskActivityDetails?.taskDetails?.templateRefTitle | titlecase }}</span>
        </p>
        <span class="task-date my-0" *ngIf="taskActivityDetails?.taskDetails?.timeFrame?.start && taskActivityDetails?.taskDetails?.timeFrame?.end">
          {{ taskActivityDetails?.taskDetails?.timeFrame?.start | date:'dd' }}
          {{ taskActivityDetails?.taskDetails?.timeFrame?.start | date:'MMM' | translate }} -
          {{ taskActivityDetails?.taskDetails?.timeFrame?.end | date:'dd' }}
          {{ taskActivityDetails?.taskDetails?.timeFrame?.end | date:'MMM'| translate }}
          {{ taskActivityDetails?.taskDetails?.timeFrame?.end | date:'yyyy' }}
        </span>
      </div>
    </div>

    <div fxFlex="20px" fxLayoutAlign="end center" (click)="openTaskFullview()">
      <mat-icon class="arrow-icon">arrow_forward</mat-icon>
    </div>

  </div>
</section>
