import { Inject, Injector, Input, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiTasksService, ManageTaskMembers } from '@kalgudi/project-manager';
import { BulkMembersAdditionResponse, IdValueMap, KalgudiProjectTask, PartialData, TargetMembers } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class KalgudiPageAddTaskMembers extends ManageTaskMembers {

  @Input()
  pageId: string;

  targetType: TargetMembers;

  taskDetails: KalgudiProjectTask;

  projectMembers: IdValueMap[] = [
    {
      id: 'ALL',
      value: 'All'
    },
    {
      id: 'MEMBERS',
      value: 'Members'
    },
    {
      id: 'CONTRIBUTORS',
      value: 'Contributors'
    },
  ];

  targetMembersType = new FormControl('');

  protected taskService: KalgudiTasksService;
  private kalgudiStatusDialogService: KalgudiStatusDialogService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
    ) {

    super(notification, util, injector);

    this.taskService                = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.kalgudiStatusDialogService = this.injector.get(KalgudiStatusDialogService);

    // this.targetMembersType.valueChanges
    //   .pipe(
    //     takeUntil(this.destroyed$),

    //     skip(1),
    //   )
    //   .subscribe(res => {
    //     this.targetType = res;
    //     console.log("KalgudiPageAddTaskMembers -> this.targetType", this.targetType)
    //     this.addMember(this.targetType);
    //   });

  }

  /**
   * Extra filters to get the projects, In this case getting projects under the page
   */
  get filters(): PartialData {
    return {
      projectId: this.projectId,
      pageId: this.pageId
    };
  }

  /**
   * Event handler for target type selection change
   */
  onTargetTypeSelectionChanged(event: MatSelectChange): void {

    // console.log("KalgudiPageAddTaskMembers -> targetTypeSelectionChanged -> event", event);

    this.targetType = event.value;
    this.addMember(this.targetType);
  }

  /**
   * Get task details
   */
  getTaskDetails() {
    this.taskService.getTaskDetails(this.taskId)
      .subscribe(res => {
        this.taskDetails = res;
        this.targetMembersType.patchValue(this.taskDetails.targetedAudience.target);
      }
    );
  }

  /**
   * Calls the api to add task members
   */
  addMember(targetType: string) {

    const payload = {
      target: targetType,
    };

    this.addTaskMemberService.addTaskMembers(this.taskId, payload, this.filters)
      .pipe(

        // Unsubscribe to the stream when component gets destroyed
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.onSuccessHandler(res),
        err => this.onErrorHandler(err)
      );
  }

  /**
   * Calls the api to add task members
   */
  addBulkMembers(payload: string[]) {

    this.addTaskMemberService.addBulkMembers(this.taskId, payload, this.filters)
      .pipe(

        // Unsubscribe to the stream when component gets destroyed
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onErrorHandler(err)
      );
  }

  /**
   * Event handler for successful bulk member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);
    this.resetStream();
  }

  /**
   * On adding page task members successfully
   */
  protected onSuccessHandler(res) {
    this.resetStream();
    this.notification.showMessage('Added members successfully');
  }

  /**
   * Failed to add page task members
   */
  protected onErrorHandler(err: Error) {
    this.notification.showMessage('Failed to add members');
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

}
