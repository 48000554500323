import { Directive, Injector } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProfileRouteConfig } from '../../../config';

@Directive()
export abstract class KalgudiFarmerLiveStockList  {

  profileKey: string;

  private appRouting: KalgudiProfileRouteConfig;
  private kalgudiAppService: KalgudiAppService;

  constructor(
    protected injector: Injector
  ) {

    this.appRouting        = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);

    this.profileKey = this.kalgudiAppService.profileLocal.profileKey;
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Show live stock list page
   */
  showLiveStockListPage(): void {

    this.appRouting.toLiveStockListPage({profileKey: this.profileKey});
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
