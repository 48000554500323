import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiStreamData, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseCommonV1, ProgramStreamResponse, StreamUrls } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProgramShareUpdateApiService {

  /**
   * `v1/stream/programStream`
   */
  private readonly API_STREAM = `${this.env.restBaseUrl}/stream/programStream`;

  /**
   * `/v2/social/shares/shares-with-filter`
   */
  private readonly API_UPDATES_STREAM = `${this.env.restBaseUrlV2}/social/shares/shares-with-filter`;

  /**
   * `v2/pages/:pageId/pageStream`
   */
  private readonly API_PAGE_SHARES_STREAM = `${this.env.restBaseUrlV2}/pages/:pageId/pageStream`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches program share update stream from API.
   *
   * @param entityId Program/Page id for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchStream(entityId: string, offset: number, limit: number): Observable<StreamUrls[]> {

    const params = {
      entityId,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_STREAM, { params })
      .pipe(
        map(r => {
          // Handler API errors
          this.streamResponseHandler(r);

          // Map api response to object type
          const res = this.mapStreamResponse(r);

          // Map the response to the s3 urls array
          return res.entitiesStream;
        }),
      );
  }

  /**
   * Fetches program share update stream from API.
   *
   * @param entityId Program/Page id for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchPageShareStream(entityId: string, offset: number, limit: number): Observable<StreamUrls[]> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString()
    };

    const url = this.API_PAGE_SHARES_STREAM.replace(':pageId', entityId);

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r, 202)),
        map(r => {
          // Handler API errors
          // this.streamResponseHandler(r);

          // Map api response to object type
          // const res = this.mapStreamResponse(r);

          // Map the response to the s3 urls array
          return r.data.entitiesStream;
        }),
      );
  }

  /**
   * Fetches all pages share updates stream from API.
   *
   * @param pageIds Program/Page ids for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchUpdatesStream(msgTypes: string, pageIds: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    const params = {
      msgTypes,
      pageIds,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_UPDATES_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        map(r => ({ items: r.data.results, count: r.data.count}))

      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Asserts stream response for any errors. Throws appropriate
   * error on failed API response.
   *
   * @param res Share update stream API response
   */
  private streamResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Validate for error messages
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps API response to common stream urls array.
   */
  private mapStreamResponse(res: ApiResponseCommonV1): ProgramStreamResponse {

    return this.util.toJson<ProgramStreamResponse>(res.data);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
