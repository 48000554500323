import { Component, Input, OnInit } from '@angular/core';
import { SocialPostComments } from '@kalgudi/types';

@Component({
  selector: 'kl-social-comments-list',
  templateUrl: './social-comments-list.component.html',
  styleUrls: ['./social-comments-list.component.scss']
})
export class SocialCommentsListComponent implements OnInit {

  @Input()
  commentsList: SocialPostComments[];

  constructor() { }

  ngOnInit() {
  }

}
