import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseSurveyList, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiSurveyList } from '../../../typings';

@Injectable()
export class SurveysListApiService {

  /**
   * `v2/social/surveys`
   */
  private readonly API_SURVEYS_LIST = `${this.env.restBaseUrlV2}/social/surveys`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  fetchSurveysList(
    offset: number,
    limit: number,
    extraParams: PartialData = {},
    ): Observable<KalgudiSurveyList> {

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
      ...extraParams,
    };

    return this.httpClient.get<ApiResponseSurveyList>(this.API_SURVEYS_LIST, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi survey details object type
        map(res => ({ count: res.data.count, items: res.data.results }))
      );
  }

}

