import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AudioAttachmentService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import {
  Attachment,
  ATTACHMENT_TYPE_MAP,
  FileMimeTypes,
  KalgudiDialogConfig,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kl-common-qa-form',
  templateUrl: './common-qa-form.component.html',
  styleUrls: ['./common-qa-form.component.scss']
})
export class CommonQaFormComponent extends KalgudiDestroyable implements OnInit {

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.QA;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];

  cameraAttachment = new FormControl('');

  @Input()
  showPageSelection: boolean = false;

  @Input()
  compactView: boolean;

  @Input()
  showCamera: boolean;

  @Input()
  shareForm: FormGroup;

  @Input()
  audioAttachment: FormControl;

  @Input()
  shareFormText: AbstractControl;

  @Input()
  textFieldPlaceHolder: string;

  @Input()
  submitBtnText: string;

  @Input()
  progress: boolean;

  @Input()
  attachments: Attachment[] = [];

  @Input()
  rowsCount: number;

  @Output()
  shareQaPost = new EventEmitter<any>();

  @Output()
  attachImage = new EventEmitter<Attachment[]>();

  @Output()
  attachDoc = new EventEmitter<Attachment[]>();

  @Output()
  removeAttachment = new EventEmitter<number>();

  attachmentType = ATTACHMENT_TYPE_MAP;
  pageList: any;
  page: any;
  isAppToDisplay: boolean = false;

  constructor(
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private audioAttachmentService: AudioAttachmentService,
    public sharePostService: KalgudiShareUpdateService,
    private route: ActivatedRoute,
  ) {
    super();

    this.subscribeToImageCaptures();
  }

  ngOnInit() {

    // Display fields based on the app ID
    this.isAppToDisplay = this.environment.appId === 'FPO_APP' || this.environment.appId === 'FARMERS_APP' || this.environment.appId === 'SAM_FPO' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'ORMAS_SHG' || this.environment.appId === 'SHG_NET_APP';

    if((this.isAppToDisplay || this.environment.appId === 'UBM') && this.showPageSelection) {
      this.fetchPagesList();
    }
  }

  onDestroyed(): void {}

  /**
   * Removes audio attachment
   */
  removeAudioAttachment() {
    this.audioAttachment.value.url = '';
  }

  /**
   * On page selection
   */
  selectPage(page: any) {
    this.page = page.value ;
  }

  get doctype(): string {
    if (this.attachments && this.attachments.length) {

      return this.attachments[0].msgType;
    } else {
      return '';
    }

  }


  /**
   * Emits an event to the parent
   */
  postQa(): void {
    this.shareQaPost.emit(this.page);
  }

  /**
   * emits an event to the parent for uploading image
   * @param attachment
   */
  uploadImage(attachments): void {

    this.attachImage.emit(attachments);
  }


  /**
   * emits an event to the parent for uploading document
   * @param attachment
   */
  uploadDoc(attachments): void {

    this.attachDoc.emit(attachments);
  }

  /**
   * Called on selection on file
   * @param fileEvent
   */
  onFileSelection(fileEvent: any): void {
    if (fileEvent.target.files[0]) {
      this.uploadImage(fileEvent.target.files[0]);
    }
  }

  showAudioDialog(): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Record',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: this.s3Category,
        url: this.audioAttachment.value && this.audioAttachment.value.url
              ? this.audioAttachment.value.url
              : ''
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.audioAttachmentService.showAudioDialog(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.data),
      )
      .subscribe(
        res => this.audioAttachment.patchValue(res.attachment)
      );
  }



  /**
   * Emits an vent to the parent to remove the uploaded image
   * @param index
   */
  removeImage(index: number): void {
    this.removeAttachment.emit(index);
  }

  private subscribeToImageCaptures(): void {

    this.cameraAttachment.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {

          const images = Array.isArray(this.shareForm.get('lstOfAttachments').value) ? this.shareForm.get('lstOfAttachments').value : [] ;
          images.push(res);

          this.shareForm.get('lstOfAttachments').patchValue(images);

        });
  }

  /**
   * Fetches pages list from the API
   */
  fetchPagesList() {
    this.sharePostService.fetchPagesList(0, 1000, true)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          const pageListRes = res.items;
          this.pageList = pageListRes.filter(p => ['PROGRAM', 'ORGANIZATION' , 'FARMER', 'USER_MANAGEMENT'].includes(p.pageType));
        }
      )
  }

}
