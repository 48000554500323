<div class="member-picker-dialog">
  <div class="tab-groups-mobile">
    <mat-tab-group color="accent" animationDuration="0" [(selectedIndex)]="activeTabIndex">
      <!-- All members tab -->
      <mat-tab label="{{TABS.ALL.title}}">
        <kl-programs-member-picker *ngIf="activeTabIndex === TABS.ALL.index"
          [pageId]="pageId"
          [multiSelect]="multiSelect" [(selectedUsersMap)]="selectedUsersMap">
        </kl-programs-member-picker>
      </mat-tab>
      <!-- / All members tab -->

      <!-- Selected members tab -->
      <mat-tab label="{{TABS.SELECTED.title}}">
        <kl-kalgudi-programs-selected-member [selectedUsers]="selectedUsersMap"
          *ngIf="activeTabIndex === TABS.SELECTED.index">
        </kl-kalgudi-programs-selected-member>
      </mat-tab>
      <!-- / Selected members tab -->
    </mat-tab-group>
  </div>

  <!-- Buttons -->
  <div class="py-2 px-4 buttons fixed-bottom" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px"
    [ngClass]="{'mt-3': activeTabIndex === TABS.ALL.index}">
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-stroked-button label="Cancel" buttonColor="warn" (clicked)="cancel()"></kl-stroked-button>
    </div>
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-flat-button label="Select" buttonColor="primary" (clicked)="ok()"></kl-flat-button>
    </div>
  </div>
  <!-- / Buttons -->
</div>
