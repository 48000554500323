import { Directive, Injector } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiFormValidators } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiProfileBasicDetailsEdit } from '../../profile-basic-edit/classes/kalgudi-profile-basic-details-edit';
import { AboutUsDialogComponent } from '../components/about-us-dialog/about-us-dialog.component';
import { AboutUsMobileDialogComponent } from '../components/about-us-mobile-dialog/about-us-mobile-dialog.component';

@Directive()
export abstract class KalgudiOrgProfileAboutUs extends KalgudiProfileBasicDetailsEdit {

  showAboutUsFiled: boolean;
  editable: boolean;

  profile: KalgudiUser;

  profileForm: FormGroup;

  private kalgudiProfileState: KalgudiProfileStateService;
  private kalgudiProfileService: KalgudiProfileService;
  private dialogService: KalgudiDialogsService;


  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
    ) {

    super(injector,fb);

    this.kalgudiProfileService = this.injector.get(KalgudiProfileService);
    this.kalgudiProfileState   = this.injector.get(KalgudiProfileStateService);
    this.dialogService         = this.injector.get(KalgudiDialogsService);

    this.kalgudiProfileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.profile = res
      )

      this.profileForm = this.newFarmerProfileBasicDetailsForm;

    this.kalgudiProfileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------


  /**
   * Form group for employee basic details
   */
  private get newFarmerProfileBasicDetailsForm(): FormGroup {

    return this.fb.group({

      location: [{}],

      basicDetails: this.fb.group({
        firstName: [''],
        alternateMobileNo: ['', [...KalgudiFormValidators.mobileValidators]],
        altemailId: ['', [...KalgudiFormValidators.emailValidators]],
        businessTypeName: [''],
        aboutMe: [''],
        location: [{}],

        farmers: [''],
        employees: [''],
        villages: [''],
        establishedSince: [''],
        fpoAvgLand:  this.fb.group({
          unit: ['acre'],
          value: [0]
        })
      }),

    });
  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  // updateAboutYourself() {
  //   this.showAboutUsFiled = true;
  // }

  // cancelAddingAboutYourself() {
  //   this.showAboutUsFiled = false;
  // }


  /**
   * Shows about yourself dialog
   */
  updateAboutYourself(hideActions?: boolean) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'About us',
      data: {
        hideActions
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'about-us',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        hideActions
      }
    };

    return this.openAboutUsDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * On about yourself info changes success
   */
  protected onAddingEmployeeDetails(res): void  {
    this.showAboutUsFiled = false;
  }

  /**
   * Open about us dialog
   */
  private openAboutUsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(AboutUsMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(AboutUsDialogComponent, dialogConfig, matDialogConfig);

    }


  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
