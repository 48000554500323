import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-add-land-details-dialog',
  templateUrl: './add-land-details-dialog.component.html',
  styleUrls: ['./add-land-details-dialog.component.scss']
})
export class AddLandDetailsDialogComponent extends KalgudiDialog<AddLandDetailsDialogComponent> implements OnInit {

  user: KalgudiUser;
  geoTagLandDetails: any

  constructor(
    protected dialogRef: MatDialogRef<AddLandDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {

    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * Triggers when the child emitted an event
   */
  onLandAdded(res: KalgudiUser): void {
    this.user = res;

    this.ok();
  }

  /**
   * Triggers when the child emitted an event
   */
  geoTagDetails(res: any) {
    this.geoTagLandDetails = res;

    this.ok()
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        user: this.user,
        geoTagLandDetails: this.geoTagLandDetails
      }
    };
    this.dialogRef.close(result);
  }
}

