import { Inject, Injector, Input, Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KalgudiSidebarService } from '@kalgudi/common/ui/sidebar';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService, VIEW_TYPE_LIST } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject, KalgudiProjectTask, ProjectViewType, SelectableUIMenu, UIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { ProjectStateService } from '../../project-shared/services/project-state.service';
import { KalgudiTasksListService } from '../services/kalgudi-tasks-list.service';

@Directive()
export abstract class KalgudiProjectFullview extends KalgudiInboxStream<KalgudiProjectTask> {

  @Input()
  loggedInUserTasks: boolean;

  @Input()
  projectCategories: SelectableUIMenu[];

  projectsOption: string;

  availableViewTypes = ProjectViewType;

  projectDetails: KalgudiProject;

  @Input()
  profileKey: string;

  projectMenu: UIMenu[] = [
    ...VIEW_TYPE_LIST,
    {
      id: 'CALENDAR',
      svgIcon: 'assets/svgs/crop-calender.svg',
      title: 'Calendar view',
      disabled: false
    }
  ];


  private sidebarService: KalgudiSidebarService;
  private stateChangeService: ProjectStateService;
  private tasksListService: KalgudiTasksListService;
  private projectStateService: KalgudiProjectStateService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute,
    protected injector: Injector,
    ) {

    super(notification, util);

    this.sidebarService           = this.injector.get<KalgudiSidebarService>(KalgudiSidebarService);
    this.stateChangeService       = this.injector.get<ProjectStateService>(ProjectStateService);
    this.tasksListService         = this.injector.get<KalgudiTasksListService>(KalgudiTasksListService);
    this.projectStateService      = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);

    this.projectsOption = this.projectMenu[0].id;

    this.stateChangeService.filterSideBar$
      .pipe(

        takeUntil(this.destroyed$),

        tap(state => this.showFiltersSidebar()),

      )
      .subscribe();

    this.projectStateService.projectDetails$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => this.projectDetails = res);

  }

  showFiltersSidebar() {
    this.sidebarService.getSidebar('showFilters').toggleOpen();
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    if (this.loggedInUserTasks) {

      return this.tasksListService.getTasks(offset, limit, '', this.profileKey);
    } else {

      return this.tasksListService.getTasks(offset, limit, this.projectDetails.id, '');
    }
  }

}

