import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment } from '@kalgudi/types';

@Component({
  selector: 'kl-traceability-activities',
  templateUrl: './traceability-activities.component.html',
  styleUrls: ['./traceability-activities.component.scss']
})
export class TraceabilityActivitiesComponent implements OnInit {

  @Input()
  items: any;

  @Input()
  index: number;

  gallery: any[];

  isCollapsed : boolean;

  constructor(
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    private utility: KalgudiUtilityService) { }

  ngOnInit() {
  }

  collapsed() {
    this.isCollapsed = true
  }

  /**
   * Opens image full view
   */
   openImageFullView(attachments: Attachment[], index?: number): void {

    this.kalgudiPhotoswipe.open(attachments, this.environment.domain, index).subscribe();
  }

}
