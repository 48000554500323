import { Directive, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PartialData, StringAnyMap } from '@kalgudi/types';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiUtilityService } from '../services/kalgudi-util.service';
import { KalgudiStreamData } from '../typings';
import { KalgudiSearchStream } from './kalgudi-search-stream';

/**
 * Extends the base Kalgudi Search Stream. Use this class for any stream that has
 * search, filtering results and load more functionality.
 *
 * A child of KalgudiFilteredSearchStream must implement all abstract methods of KalgudiSearchStream
 * and
 *  - `searchApi()`: Implement the search Api call method here.
 *  - `filterForm`: Implement filter form
 *
 * It provides a search form group with field as `searchForm` where the form control name
 * of the search input field is `searchKeyword`.
 *
 * Must call the `initStream()` method to initialize the stream
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiFilteredSearchStream<T> extends KalgudiSearchStream<T> {

  abstract filterForm: FormGroup;

  /**
   * Event source for filters
   */
  private filterChangedSubject = new BehaviorSubject<any>(null);
  private filterChanged$: Observable<any> = this.filterChangedSubject.pipe(filter(f => f !== null));


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up my parent
    super(notification, util);

    // Subscribe to the page change and search events
    // this.subscribeToFilterEvents();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the value contained in the filter form
   */
  private get filterFormValue(): any {
    return this.filterForm.value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------




  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  filter(): void {

    // Push the latest filters in the filter form
    const selectedFilter = this.filterForm ? this.filterForm.value : null;
    this.filterChangedSubject.next(selectedFilter);

    // Reset the existing stream items
    this.resetStream(false);
  }

  /**
   * Resets the search to the default search. It clears any specified search keyword
   * and sets it back to empty.
   */
  resetFilters(): void {

    // Clear search keyword value in the search form
    this.filterForm.reset();

    this.filter();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  /**
   * Implement this method to define your search Api.
   */
  protected abstract filterApi(
    searchKeyword: string,
    filters: any,
    offset: number,
    limit: number,
    extraParams?: PartialData,
  ): Observable<KalgudiStreamData>;


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Initializes filtered search stream.
   */
  protected initStream(subscribeToEvents: boolean = true): void {

    super.initStream(false);

    if (subscribeToEvents) {
      this.subscribeToFilterEvents();
    }

    // this.search();
  }

  /**
   * Search api internally calls the filter api. The filter api handles search and filters based on the params.
   *
   * @override
   */
  protected searchApi(searchKeyword: string, offset: number, limit: number, extraParams?: StringAnyMap): Observable<KalgudiStreamData> {

    return this.filterApi(searchKeyword, this.filterFormValue, offset, limit, extraParams || {});
  }

  /**
   * Subscribes to page change or search keyword change events
   * and fetches the latest search results.
   */
  private subscribeToFilterEvents(): void {

    merge(
      this.pageChange$,
      this.searchKeywordChange$,
      this.filterChanged$,
      this.resetStream$,
    )
    .pipe(

      // Subscribe till the instance is alive
      takeUntil(this.destroyed$),

      tap(_ => this.setLoadingProgress(false)),

      // Always process if there is a valid paginator value
      filter(_ => this.paginatorValue !== null),

      // Fetch latest stream items and transform the response
      switchMap(_ => this.fetchStreamItems(this.paginatorValue))
    )
    .subscribe(_ => this.streamLoadAction = 'concat');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
