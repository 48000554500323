import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG, KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

import { KalgudiProfileRouteConfig } from '../../../../config';
import { KalgudiProfileService } from '../../../../services/kalgudi-profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-cooperative-society-live-stock',
  templateUrl: './cooperative-society-live-stock.component.html',
  styleUrls: ['./cooperative-society-live-stock.component.scss']
})
export class CooperativeSocietyLiveStockComponent implements OnInit {

  profileKey: string;
  isShgApp: boolean = true;

  editable: boolean;
  isPublicPage: boolean;

  private appRouting: KalgudiProfileRouteConfig;
  private kalgudiAppService: KalgudiAppService;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector,
              protected router: Router,
              @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    ) {
    this.appRouting        = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);

    this.profileKey = this.kalgudiAppService.profileLocal?.profileKey;
    this.profileService = this.injector.get(KalgudiProfileService);

    this.profileService.editable$
      .subscribe(
        res => this.editable = res
      );
  }

  ngOnInit(): void {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
    this.isShgApp = !(this.environment?.appId === 'SHG_NET_APP');
  }

  /**
   * Show live stock list page
   */
  showLiveStockListPage(): void {

    this.appRouting.toLiveStockListPage({profileKey: this.profileKey});
  }

}
