<section class="selected-member p-0 pt-4">
  <div class="users overflow-auto" fxLayout="row wrap" fxLayoutAlign="space-between">
    <!-- Iteration -->
    <div class="mb-4" fxFlex="48%" fxFlex.xs="100%" *ngFor="let user of selectedUsers">
      <!-- Selectable user tile -->
      <kl-selectable-user-tile avatarSize="md" [author]="user" [isSelected]="true">
      </kl-selectable-user-tile>
      <!-- / Selectable user tile -->
    </div>
    <!-- / Iteration -->
  </div>

  <div *ngIf="selectedUsers.length <= 0" fxLayoutAlign="center center" class="py-3 h-100 no-content">

    <kl-no-content
      svgIcon="assets/svgs/undraw_people_search_wctu.svg"
      iconSize="lg">
    </kl-no-content>

  </div>

</section>
