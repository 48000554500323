import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiProjectTask } from '@kalgudi/types';

import { KalgudiTaskSubmissionDaService } from '../../services/kalgudi-task-submission-da.service';

@Component({
  selector: 'kl-task-members-full-view-da',
  templateUrl: './task-members-full-view-da.component.html',
  styleUrls: ['./task-members-full-view-da.component.scss']
})
export class TaskMembersFullViewDaComponent implements OnInit, OnChanges {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  membersStatus: string;

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  hideTaskHeader: boolean;


  errorMsg: string;
  activeTabIndex: number;
  selectedProfileKeys: any[];

  readonly TABS = {
    COMPLETED: { index: 0, title: 'Completed', id: 'COMPLETED' },
    IN_PROGRESS: { index: 1, title: 'Inprogress', id: 'IN_PROGRESS' },
    NOT_STARTED: { index: 2, title: 'Not started', id: 'NOT_STARTED' },
  };


  constructor(
    private router: Router,
    private kalgudiTaskSubmissionDaService: KalgudiTaskSubmissionDaService,
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges() {

    this.activeTabIndex = this.membersStatus ? this.TABS[this.membersStatus].index : this.TABS.COMPLETED.index;

  }

  onDestroyed(): void { }

  /**
   * Get selected profileKeys
   */
  getSelectedProfileKeys(profiles: any[]) {
    this.selectedProfileKeys = profiles;
  }

  /**
   * Opens task submission page
   */
  openTaskSubmissionPage() {

    // this.kalgudiTaskSubmissionDaService.updateSelectedTaskMembers(this.selectedProfileKeys);

    localStorage.setItem('selectedProfiles', JSON.stringify(this.selectedProfileKeys));

    if(this.taskDetails.pageId) {
      const url = `/app/pages/${this.taskDetails.pageId}/tasks/${this.taskDetails.id}/members/submission`;

      this.router.navigate([url]);
    } else {

      const url = `/app/projects/${this.taskDetails.projectId}/${this.taskDetails.id}/members/submission`;

      this.router.navigate([url]);
    }
  }

  /**
   * Show error msg
   */
  showErrorMsg(errorMsg: string) {
    this.errorMsg = errorMsg;
  }
}
