import { Component, Inject, Injector, OnInit } from '@angular/core';
import { floatTopAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { UIMenu } from '@kalgudi/types';

import { KalgudiProgramsRouteConfig } from '../../../../config';
import { KalgudiPageProjectList } from '../../classes/kalgudi-page-project-list';

@Component({
  selector: 'kl-page-project-list',
  templateUrl: './kalgudi-page-project-list.component.html',
  styleUrls: ['./kalgudi-page-project-list.component.scss'],
  animations: [floatTopAnimation]
})
export class KalgudiPageProjectListComponent extends KalgudiPageProjectList implements OnInit {

  readonly footerMenus: UIMenu[];

  constructor(
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {

    super(notification, util, injector);

    this.footerMenus = [
      { svgIcon: 'assets/svgs/projects.svg', title: 'Create Project', clickAction: this.openMobilCreateProjectForm },
    ];
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

  /**
   * Opens Mobile qa form dialog
   */
  openMobilCreateProjectForm = (): void => {
    this.createProject();
  }
}
