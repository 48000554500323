<main id="kalgudi-employee-profile" fxLayout="column" fxLayoutAlign="center center">

  <section class="w-100 profile-banner-and-pic">

    <div class="banner-image w-100 position-relative" [style.background-image]="'url('+ profileDetails?.coverPicUrl +')'">
     <div fxLayout fxLayoutAlign="end end">
      <button
        class="banner-button border-0 text-white py-1 pl-2 mt-2 mr-2 position-relative"
        fxLayoutGap="4px" fxLayoutAlign="center center" *ngIf="editable">
        <i class="fa fa-camera cam-icon" aria-hidden="true"></i>
        <span translate>Change banner</span>
        <input class="position-absolute cursor-pointer w-100 h-100" type="file" [title]="'Click to change profile banner' | translate" (change)="uploadCoverPic($event)">
      </button>
    </div>

      <div class="w-100 position-absolute mt-5 pt-4" fxLayout fxLayoutAlign="center center">

        <div class="upload-profile-pic rounded-circle position-relative ">
          <!-- <div class="profile-pic"
            [style.background-image]="'url('+ profileDetails?.profilePicUrl || defaultProfilePic +'  | prefixDomain)'">
          </div> -->
          <img class="w-100 h-100 rounded-circle"
            [src]="(profileDetails?.profilePicUrl) | prefixDomain"
            [klErrorImages]="[
              (profileDetails?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
          />

            <div class="position-absolute edit-profile-section">

              <button mat-icon-button class="position-relative edit-profile-button" fxLayoutGap="4px" fxLayoutAlign="center center" *ngIf="editable">
                <mat-icon class="edit-profile">camera_alt</mat-icon>

                <input class="input-file w-100 h-100 position-absolute cursor-pointer rounded-circle" type="file" title="Change profile picture" accept=".png, .jpg, .jpeg" (change)="uploadProfilePic($event)">

              </button>
            </div>
        </div>
      </div>
    </div>
  </section>

  <section class="p-3 mt-5 pt-4 w-100 user-profile-details">
    <div class="edit-section w-100 mt-4 mb-n4" fxLayout fxLayoutAlign="end center">
      <button mat-icon-button class="edit-btn" [matTooltip]="'Edit basic details' | translate" (click)="showEditEmployeeProfileDialog()" *ngIf="editable">
        <mat-icon class="text-white">edit</mat-icon>
      </button>
    </div>
    <div class="profile-details mt-4" fxLayout="column" fxLayoutAlign="center center">
      <h4 class="text-capitalize first-name">{{ profileDetails?.firstName }}</h4>
      <div class="business-type mb-1">
        <span id="user-business-type">
          {{ profileDetails?.additionalBizDetails?.nativeBusinessTypeName }}
        </span>
        <span *ngIf="profileDetails?.employeeProfileTo?.experience?.years || profileDetails?.employeeProfileTo?.experience?.months">

          | Exp:
          <span *ngIf="profileDetails?.employeeProfileTo?.experience?.years">
            {{ profileDetails?.employeeProfileTo?.experience?.years }} <span translate>years</span>
          </span>
          <span *ngIf="profileDetails?.employeeProfileTo?.experience?.months">
            {{ profileDetails?.employeeProfileTo?.experience?.months }} <span translate>months</span>
          </span>
        </span>
      </div>
    </div>
  </section>

  <section class="w-100 profile-details px-3 pb-3" fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div class="pb-2 mb-1" fxLayout="column" fxLayoutAlign="center center" *ngIf="profileDetails?.emailId">
      <mat-icon>email</mat-icon>
      <p class="mb-0 other-details">{{ profileDetails?.emailId }}</p>
    </div>

    <div class="pb-2 mb-1 user-mobile-no" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>phone_in_talk</mat-icon>
      <p class="mb-0 other-details mobile-no">{{ profileDetails?.alternateMobileNo || profileDetails?.mobileNo }}</p>
    </div>

    <div class="pb-2 mb-1 user-location" fxLayout="column" fxLayoutAlign="start center">
      <mat-icon>location_on</mat-icon>
      <p class="mb-0 other-details location">
        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName }}, </span>
        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
      </p>
    </div>
  </section>

  <div class="edit-section" fxLayout *ngIf="isDigitalAssist">
    <p>
      <span translate>Login Id:</span>  {{ loginId }}
    </p>
    <button mat-icon-button class="edit-btn ml-2" [matTooltip]="'Update' | translate"  (click)="showEditLoginIdDialog()">
      <mat-icon class="text-white">edit</mat-icon>
    </button>
  </div>
</main>
