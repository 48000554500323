<mat-card fxFlex="100%" fxLayout="column" id="social-container">
  <!-- Crop header -->
  <div class="social-header py-2 mb-2 mt-n4" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="pt-2">
      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/icons/crops.svg"
          [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
      </div>
      <div>
        <p class="m-0 font-weight-bold" translate>Crop Info</p>
      </div>
    </div>
    <div matTooltip="Edit crop info details" *ngIf="!isPublicPage">
      <button  *ngIf="cropFormDetails?.cropDetails?.length || cropFormDetails?.cropWiseProcurement?.length" mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end" (click)="showCropDialog()">
      <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
      </button>
    </div>
  </div>
  <!--/ Crop header -->

  <!-- Crop data values -->
  <div class="crop-list" *ngIf="cropFormDetails?.cropDetails?.length || cropFormDetails?.previousYearCropDetails?.length || cropFormDetails?.cropWiseProcurement?.length">
    <!-- FPO crop details -->
    <div class="mb-2 mr-2" *ngIf="cropFormDetails?.cropDetails?.length">
      <!-- Header FPO crop details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>FPO crop details</h6>
      </div>
      <!--/ Header FPO crop details -->

      <!-- FPO crop values -->
      <div class="fpo-crop crop-table-value rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>F.year</div>
            <div class="table-cell" translate>Season</div>
            <div class="table-cell" translate>Crop</div>
            <div class="table-cell" translate *ngIf="false">Variety</div>
            <div class="table-cell" translate>Area(acre)</div>
            <div class="table-cell" translate>Quantity</div>
            <div class="table-cell" translate>Unit</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let currentYear of cropFormDetails?.cropDetails; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ currentYear?.year || '-' }}</div>
              <div class="table-cell text-break">{{ (currentYear?.season | titlecase) || '-' }}</div>
              <div class="table-cell text-break">{{ (currentYear?.cropName | titlecase) || '-' }}</div>
              <div class="table-cell text-break" *ngIf="false">{{ currentYear?.variety || '-' }}</div>
              <div class="table-cell text-break">{{ (+currentYear?.cultivationArea).toFixed(2) || '-' }}</div>
              <div class="table-cell text-break">{{ (+currentYear?.outputQty).toFixed(2) || '-' }}</div>
              <div class="table-cell text-break">{{ currentYear?.outputQtyUnit || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ FPO crop values -->
    </div>
    <!--/ FPO crop details -->

    <!-- FPO crop-wise details -->
    <div class="mb-2 mr-2" *ngIf="cropFormDetails?.cropWiseProcurement?.length">
      <!-- Header FPO crop details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>FPO crop-wise details</h6>
      </div>
      <!--/ Header FPO crop details -->

      <!-- FPO crop values -->
      <div class="fpo-crop-wise crop-table-value rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>F.year</div>
            <div class="table-cell" translate>Total farmers</div>
            <div class="table-cell" translate>Crop</div>
            <div class="table-cell" translate>Total qty</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let cropWise of cropFormDetails?.cropWiseProcurement; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ cropWise?.year || '-' }}</div>
              <div class="table-cell text-break">{{ cropWise?.noOfFarmers || '-' }}</div>
              <div class="table-cell text-break">{{ (cropWise?.crop | titlecase) || '-' }}</div>
              <div class="table-cell text-break">{{ (+cropWise?.totalQty).toFixed(2) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ FPO crop values -->
    </div>
    <!--/ FPO crop-wise details -->

  </div>
  <!--/ Crop data values -->

  <!-- No content if there no social -->
  <ng-container *ngIf="!(cropFormDetails?.cropDetails?.length || cropFormDetails?.previousYearCropDetails?.length || cropFormDetails?.cropWiseProcurement?.length)">
    <div class="w-100 no-content-social" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? ('No crops added yet!' | translate) : editable ? '' : ('No crops added yet!' | translate)"
        [actionLink]="isPublicPage ? '' : editable ? ('Add crop info' | translate) : ''"
        (action)="showCropDialog()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no social -->
</mat-card>
