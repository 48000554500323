<section class="value-card" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="tile w-100 mb-2">

    <!-- Data Information -->
    <div class="data-info" fxLayout="row" fxLayoutGap="12px">
      <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <i class="fas fa-shield-alt"></i>
      </div>

      <div class="data-details" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <div class="details-info">
          <h2 class="profile-title m-0"  translate>Awards </h2>
          <p class="mb-0" translate>Add your Awards by clicking on edit Icon</p>
        </div>

        <div fxLayoutAlign="center center" (click)="showAddItemForm()" *ngIf="editable && !isSaveUpdateFormVisible">
          <button mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
          </button>

        </div>
      </div>
    </div>
    <!-- / Data Information -->

    <!-- Add awards-->
    <kl-value-type-form
      *ngIf="isSaveUpdateFormVisible"
      [form]="form"
      [saveInProgress]="saveItemProgress"
      titleFormControlName="title"
      descriptionFormControlName="description"
      (closed)="hideAddItemForm()"
      (save)="saveOrUpdateItem()">
    </kl-value-type-form>
    <!-- / Add awards-->

    <!-- list of awards -->
    <kl-value-type-list
      [editable]="editable"
      [items]="profileSectionItems"
      itemDescriptionFieldName="description"
      itemTitleFieldName="title"
      titleText="Title"
      descriptionText="Description"
      (delete)="deleteItem($event);"
      (edit)="editItem($event)">
    </kl-value-type-list>
    <!-- / list of awards -->

  </mat-card>
</section>
