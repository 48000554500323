import { Injectable } from '@angular/core';
import { IPageStateManager } from '@kalgudi/core';
import { KalgudiPageDetails, StateManagerAction } from '@kalgudi/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PageActions } from '../constants';

/**
 * Kalgudi Program fullview state management service. It defines observable that
 * emits program state change name on every state change. A publisher must post
 * state change message using `update()` method.
 */
@Injectable()
export class ProgramStateService implements IPageStateManager<KalgudiPageDetails, PageActions> {

  readonly data$: Observable<KalgudiPageDetails>;
  readonly id$: Observable<string>;
  readonly action$: Observable<StateManagerAction<PageActions>>;

  private pageDataSubject = new BehaviorSubject<KalgudiPageDetails>(null);
  private actionSubject   = new Subject<StateManagerAction<PageActions>>();

  constructor() {
    this.action$ = this.actionSubject.asObservable();

    this.data$ = this.pageDataSubject.pipe(filter(val => val !== null));
    this.id$   = this.data$.pipe(map(val => val.pageId));

  }

  get data(): KalgudiPageDetails {
    return this.pageDataSubject.getValue();
  }

  get id(): string {
    return this.data ? this.data.pageId : null;
  }

  dispatchAction(action: PageActions, payload?: any): void {
    this.actionSubject.next({ type: action, payload });

    if (action === PageActions.PAGE_UPDATED && payload) {
      this.pageDataSubject.next(payload);
    }
  }

  // /**
  //  * Stream of latest program details object
  //  *
  //  * @see page$
  //  */
  // private pageSubject = new BehaviorSubject<KalgudiPageDetails>(null);

  // /**
  //  * Stream of latest program digital assistance stats.
  //  *
  //  * @see pageStats$
  //  */
  // private pageAssistanceSubject = new BehaviorSubject<KalgudiProgramDigitalAssistanceStats>(null);


  // /**
  //  * Program state changed
  //  */
  // private stateChanged = new Subject<ProgramStateActionMap>();



  // // --------------------------------------------------------
  // // #region Getter and Setters
  // // --------------------------------------------------------

  // /**
  //  * Gets, program state changed events. A state change means something
  //  * has been updated in the current program.
  //  *
  //  * Its a post action changes.
  //  *
  //  * @see actionPerformed$
  //  */
  // get stateChanged$(): Observable<ProgramStates> {
  //   return this.stateChanged
  //     .pipe(

  //       // Filter program states only
  //       filter(r => !!(PROGRAM_STATES_LIST.find(i => i === r))),

  //       map(r => r as ProgramStates)
  //     );
  // }

  // /**
  //  * Gets, program action change events. A program action refers to an
  //  * action taken by the different sections of the app that should reflect
  //  * to the some other section in the app.
  //  *
  //  * It is a pre action changes.
  //  *
  //  * @see stateChanged$
  //  */
  // get actionPerformed$(): Observable<ProgramActions> {

  //   return this.stateChanged
  //     .pipe(

  //       // Filter program actions only
  //       filter(r => !!(PROGRAM_ACTIONS_LIST.find(i => i === r))),

  //       map(r => r as ProgramActions)
  //     );
  // }

  // /**
  //  * Gets, latest program details from the program stream.
  //  */
  // get page$(): Observable<KalgudiPageDetails> {

  //   return this.pageSubject
  //     .pipe(

  //       // Filter null values from the stream
  //       filter(r => r !== null),

  //       // Update the program details in the state
  //     );
  // }

  // /**
  //  * Gets, the latest digital assistance stats object from the digital
  //  * assistance stream.
  //  */
  // get digitalAssistanceStats$(): Observable<KalgudiProgramDigitalAssistanceStats> {

  //   return this.pageAssistanceSubject
  //     .pipe(

  //       // Filter null values from the stream
  //       filter(r => r !== null)
  //     );
  // }

  // // --------------------------------------------------------
  // // #endregion
  // // --------------------------------------------------------



  // // --------------------------------------------------------
  // // #region Public interfacing methods
  // // --------------------------------------------------------

  // /**
  //  * Updates program state
  //  */
  // updateState(state: ProgramStates): void {

  //   if (!state) {
  //     throw new Error('Undefined state');
  //   }

  //   this.stateChanged.next(state);
  // }

  // /**
  //  * Updates program actions
  //  */
  // updateActions(action: ProgramActions): void {

  //   if (!action) {
  //     throw new Error('Undefined action');
  //   }

  //   this.stateChanged.next(action);
  // }


  // /**
  //  * Initializes program, pushes latest program object to the stream.
  //  */
  // updateProgram(page: KalgudiPageDetails, assistanceStats: KalgudiProgramDigitalAssistanceStats): void {

  //   // Push latest program and digital assistance details to the program stream
  //   this.updateProgramToStream(page);
  //   this.updateProgramStatsToStream(assistanceStats);
  // }

  // // --------------------------------------------------------
  // // #endregion
  // // --------------------------------------------------------



  // // --------------------------------------------------------
  // // #region Private methods
  // // --------------------------------------------------------


  // /**
  //  * Pushes new program details data to the `programSubject`
  //  *
  //  * @param page Latest program details
  //  */
  // private updateProgramToStream(page: KalgudiPageDetails): void {

  //   // Push program to the program stream
  //   this.pageSubject.next(page);
  // }

  // /**
  //  * Pushes new program digital assistance stats to the `programAssistanceSubject`.
  //  *
  //  * @param stats Latest program digital activity stats
  //  */
  // private updateProgramStatsToStream(stats: KalgudiProgramDigitalAssistanceStats): void {

  //   // Push assistance stats to the program stats stream
  //   this.pageAssistanceSubject.next(stats);
  // }

  // // --------------------------------------------------------
  // // #endregion
  // // --------------------------------------------------------

}
