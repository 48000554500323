import { CoreDefaultRouteConfigService } from './core-default-route-config.service';
import { CoreEnvironmentConfigService } from './core-environment-config.service';
import { CoreNotificationsConfigService } from './core-notifications-config.service';

/**
 * Kalgudi core library configuration object.
 */
export const CORE_LIB_CONFIG = {
  environment: CoreEnvironmentConfigService,
  notification: CoreNotificationsConfigService,
  routeConfig: CoreDefaultRouteConfigService,
};
