import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiProjectTask } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-task-tile',
  templateUrl: './task-tile.component.html',
  styleUrls: ['./task-tile.component.scss']
})
export class TaskTileComponent implements OnInit, OnChanges {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  enableActions: boolean;

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  addMembers = new EventEmitter();

  @Output()
  openFullview = new EventEmitter();

  @Output()
  updateTask = new EventEmitter();

  @Output()
  showTaskStats = new EventEmitter<string>();

  taskStatus = ['Not stared', 'In process', 'Completed'];

  projectRoles = KALGUDI_PAGE_RELATION_MAP;

  submissions: any[];

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  deleteTask() {
    this.delete.emit();
  }

  openAddMembersDialog() {
    this.addMembers.emit();
  }

  openTaskFullview() {
    this.openFullview.emit();
  }

  openEditTaskDialog() {
    this.updateTask.emit();
  }

  /**
   * Opens task stats Dialog
   */
  openTaskStatsDialog(statType: string) {
    this.showTaskStats.emit(statType);
  }

  ngOnChanges() {
    this.submissions = [
      {
        color: '#58EB8B',
        title: 'Completed members',
        id: 'COMPLETED',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.completed : 0,
      },
      {
        color: '#FFB74F',
        title: 'In progress members',
        id: 'IN_PROGRESS',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.in_progress : 0,
      },
      {
        color: '#FC5D5D',
        title: 'Not started members',
        id: 'NOT_STARTED',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.not_started : 0,
      }
    ];
  }

  /**
   * Opens task members page
   */
  openTaskMembersPage(membersStatus: string) {

    if(this.taskDetails.pageId) {

      const url = `/app/pages/${this.taskDetails.pageId}/tasks/${this.taskDetails.id}/members`;

      this.router.navigate([url], {queryParams : { membersStatus: membersStatus}});
    } else {
      // const url = `/app/projects/${this.taskDetails.projectId}/${this.taskDetails.id}/members`;

      // this.router.navigate([url], {queryParams : { membersStatus: membersStatus}});

      this.appRouting.toTasksMembers({projectId: this.taskDetails.projectId, taskId: this.taskDetails.id}, {membersStatus: membersStatus})

    }

  }
}
