<div id="image-upload-attachment" fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden kl-attach-image-dialog mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{dialogData?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Section for mat dialog content -->
    <div mat-dialog-content>
      <div class="dialog-container">
        <kl-upload-attachments
          [(attachments)]="attachments"
          [maxImages]="maxImages"
          [multiple]="multiple"
          [acceptFileTypes]="acceptFileTypes"
          [s3Category]="s3Category">
        </kl-upload-attachments>
      </div>
    </div>
    <!-- /Section for mat dialog content -->

    <!-- Section for mat action -->

    <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <div class="w-80">
        <kl-stroked-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()">
        </kl-stroked-button>
      </div>
      <div class="w-80">
        <kl-flat-button [label]="dialogData?.acceptButtonTitle" (clicked)="ok()"></kl-flat-button>
      </div>

    </div>
    <!-- /Section for mat action -->
  </div>
</div>
