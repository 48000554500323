import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { environment } from '@app/env';
import { KalgudiDialogsService } from '@kalgudi/common';
import { LazyApiSync } from '@kalgudi/common/lazy-http';
import { BreadcrumbService } from '@kalgudi/common/ui/breadcrumbs';
import { checkMobileDevice, KalgudiAppService, KalgudiSpinnerService } from '@kalgudi/core';
import { KalgudiFcmService } from '@kalgudi/third-party/fcm';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import * as Hammer from 'hammerjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { StoreBannerTileDialogComponent } from './modules/home/store-banner-tile-dialog/store-banner-tile-dialog.component';
import { RouterEventsService } from './services/router-events.service';

@Component({
  selector: 'core-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav', {static: true}) public sidenav: MatSidenav;

  constructor(
    private spinner: KalgudiSpinnerService,
    private routerEvents: RouterEventsService,        // Don't remove the injection, as it will remove the routing progress bar
    private elementRef: ElementRef,
    private kalgudiApp: KalgudiAppService,
    private fcmService: KalgudiFcmService,
    private lazyApiSync: LazyApiSync,                 // Don't remove necessary for offline API syncing
    // private swUpdates: ServiceWorkerUpdatesService,   // Don't remove this necessary for showing update snack bar for service worker update
    private breadcrumbService: BreadcrumbService,     // Don't remove this injection, necessary for breadcrumb router events subscription
    private dialogsService: KalgudiDialogsService,
  ) {

    // this.initSwipeActions();

    this.kalgudiApp.login$.subscribe(res => this.initFcm());
  }

  /**
   * Called after the constructor, initializing input properties, and the first call to ngOnChanges.
   */
  ngOnInit(): void {
    this.appUsage(false);
  }

  ngAfterViewInit(): void {

    if (environment.appId === 'SHAKTIMAN') {

      setTimeout(() => {
        this.showStorePageDialog();

      },         5000);
    }

  }

  initFcm() {

    this.fcmService.requestToken()
      .subscribe(
        r => {
          // console.log('Token generated')
        },
        err => console.error('Unable to generate token', err)
      );

    setTimeout(() => {
      this.fcmService.initFCM(this.kalgudiApp.profileLocal.profileKey).subscribe();

    },         2500);
  }

  /**
   * Opens sidenav from right to left on left swipe
   */
  initSwipeActions() {

    if (checkMobileDevice()) {

      const hammertime = new Hammer(this.elementRef.nativeElement, {});
      hammertime.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      hammertime.on('panleft', event => {
        if (event.pointerType !== 'mouse') {
          this.onLeftSwipe();
        }
      });

      hammertime.on('panright', event => {
        if (event.pointerType !== 'mouse') {
          this.sidenav.close();
        }
      });

      hammertime.on('panup', event => false);
      hammertime.on('pandown', event => false);
    }


  }

  /**
   * Calls app usage api
   */
  appUsage(isLogin: boolean) {
    return this.kalgudiApp.appUsage(isLogin)
    .pipe()
    .subscribe()
  }

  /**
   * Route navigation progress
   */
  get routeProgress$(): Observable<boolean> {
    return this.spinner.routeLoadingProgress$;
  }

  /**
   * Spinner status
   */
  get spinner$(): Observable<boolean> {
    return this.spinner.spinner$;
  }

  private onLeftSwipe(): void {
    if (this.kalgudiApp.loggedIn) {
      this.sidenav.open();
    }
  }


  private showStorePageDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Store',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openStorePageDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  private openStorePageDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(StoreBannerTileDialogComponent, dialogConfig, matDialogConfig);

  }
}
