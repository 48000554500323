import { Pipe, PipeTransform } from '@angular/core';

export function transformMassArea(kgPerHectare: number, convertTo: 'acre' |'hectare' |'feddan' = 'hectare'): number {

  let res = kgPerHectare;

  const ACRE_IN_HECTARE = 2.47105;
  const FEDDAN_IN_ACRE  = 1.03;

  if (convertTo === 'acre') {
    res /= ACRE_IN_HECTARE;
  } else  if(convertTo === 'feddan') {
    res /= (ACRE_IN_HECTARE * FEDDAN_IN_ACRE);
  }

  return res;
}

@Pipe({
  name: 'massAreaUnitConversion'
})
export class MassAreaUnitConversionPipe implements PipeTransform {

  /**
   * @param value kg/hectare
   */
  transform(value: number, unit: string): any {
    return transformMassArea(value, unit as any);
  }

}
