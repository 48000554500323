<div id="live-stock">
  <!-- Live stock header -->
  <div class="live-stock-header" fxLayout fxLayoutAlign="space-between center">

    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/farm-crop.svg"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 title" translate>Live Stock</p>
      </div>

    </div>

    <div>
      <button mat-button class="add-live-stock" (click)="showLiveStockListPage()">
        <div class="button-title">
          <p class="m-0"><b translate>Add</b></p>
        </div>
      </button>
    </div>

  </div>
  <!-- /Live stock header -->

  <!-- Live stock list -->
  <div class="live-stock-list mt-2">
    <kl-live-stock-list></kl-live-stock-list>
  </div>
  <!--/ Live stock list -->
</div>
