import { Observable } from 'rxjs';

import { IPageStateManager, NO_PAGE_ACTIONS } from '../../interfaces/page-state-manager';


/**
 * Default page state manager class
 */
export class PageStateManager implements IPageStateManager<any, NO_PAGE_ACTIONS> {

  readonly data$: Observable<any>;
  readonly id$: Observable<string>;

  readonly action$: Observable<{ type: NO_PAGE_ACTIONS, payload?: any }>;

  get data(): any {
    return {};
  }

  get id(): string {
    return '';
  }

  dispatchAction(action: "", payload?: any): void {
    throw new Error("Method not implemented.");
  }
}
