import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  GeoLocationService,
  KALGUDI_S3_POLICY_MAP,
  KalgudiDestroyable,
  KalgudiUploadService,
  S3UploadNamePathMap,
} from '@kalgudi/core';
import {
  Attachment,
  ATTACHMENT_TYPE_MAP,
  AttachmentList,
  AttachmentType,
  FileMimeTypes,
  KalgudiDialogConfig,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { KalgudiImagePickerService } from '../services/kalgudi-image-picker.service';
import { NavigationEnd, Router } from '@angular/router';

@Directive()
export abstract class KalgudiUploadImage extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  attachments: AttachmentList = [];

  @Output()
  attachmentsChange = new EventEmitter<Attachment[]>();

  @Input()
  maxImages: number;

  @Input()
  s3Category: S3PolicyPathCategoryMap;

  @Input()
  multiple: boolean;

  @Input()
  accept: string = [FileMimeTypes.IMAGE].join('');

  @Input()
  disabled: boolean;

  readonly attachmentTypes = ATTACHMENT_TYPE_MAP;

  inProgress: boolean;
  attachedFileSize: number;
  isRoboCallPage: boolean = false;

  constructor(
    protected uploadService: KalgudiUploadService,
    protected imagePickerService: KalgudiImagePickerService,
    protected geoLocationService: GeoLocationService,
    protected router: Router,
  ) {

    super();


    if (this.router) {
      this.isRoboCallPage = this.router.routerState.snapshot.url.includes('/robocall');
    }
  }

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.attachments = obj;
      // this.patchAttachmentsToForm(obj);
    } else {
      // this.resetAttachmentsForm();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  /**
   * Opens the camera dialog
   */
  showCameraDialog(): void {

    this.showAttachImageDialog().subscribe(
      res => this.attachments.push(res)
    );
  }

  /**
   * Gets the selected files
   * @param event
   */
  onFileSelection(event: any): void {
    this.attachedFileSize = +(event?.target?.files[0]?.size / 1e6).toFixed(2);

    if (this.isRoboCallPage && this.attachedFileSize > 5.0) {
        Swal.fire({
          titleText: 'Maximum file size limit is 5 MB',
          icon: 'warning',
        })
      } else {
        const path = this.s3Category && this.s3Category.category
          ? this.s3Category
          : KALGUDI_S3_POLICY_MAP.DEFAULT;

          this.uploadFiles(event.target.files, path, true);
      }
  }

  /**
   * Uploads a list of files to S3. On success it returns the uploaded file path
   * with the original file name.
   * @param files
   * @param path
   * @param compression
   */
  uploadFiles(files: FileList, path, compression: boolean): void {

    this.inProgress = true;
    const fileList = Array.from(files);

    this.uploadService.uploadFiles(fileList, path, compression)
      .pipe(
        map(r => this.mapUploadResponse(r)),

        finalize(() => this.inProgress = false)
      )
      .subscribe(res => this.injectAttachments(res));
  }

  /**
   * Injects the uploaded attachments into an array
   * @param images
   */
  injectAttachments(images: Attachment[]) {
    this.attachments = this.multiple
      ? this.attachments.concat(images).splice(0, this.maxImages)
      : images;

    this.attachmentsChange.next(this.attachments);

    this.updateFormControl(this.attachments);

  }


  /**
   * Updates the attachments parent form control value with the latest attachment
   * object.
   */
  updateFormControl(attachments: AttachmentList): void {

   // Changing the audio path for only robocall page
    if (this.isRoboCallPage && attachments && attachments?.length) {
      attachments.forEach(element => {
        if (!element.url.includes('/robocalltempfolder')) {
          element.url = element.url.replace('/share-a-thought', '/share-a-thought/robocalltempfolder')
        }
      });
    }

    this.onChange(attachments);
    this.onTouched();
  }

  /**
   * Removes the selected item from an array
   * @param index
   */
  remove(index: number) {
    this.attachments.splice(index, 1);
  }

  /**
   * Maps the response into attachment model
   * @param uploads
   */
  private mapUploadResponse(uploads: S3UploadNamePathMap[]): Attachment[] {

    const geoLocation = this.geoLocationService.currentLatLong;

    return uploads.map(u => {
      return {
        url: u.filePath,
        context: '',
        msgType: this.getAttachmentType(u.fileType),
        geoLocation
      };
    });
  }

  /**
   * Gets attachment type based on the uploaded file type.
   */
  private getAttachmentType(fileType: string = ''): AttachmentType {

    const trimmedFileType = fileType.substring(0, fileType.indexOf('/') + 1) + '*';

    // Default attachment type
    let attachmentType: AttachmentType = 'DOCUMENT';

    if (trimmedFileType === FileMimeTypes.IMAGE) {
      attachmentType = 'IMAGE';
    } else if (trimmedFileType === FileMimeTypes.AUDIO) {
      attachmentType = 'AUDIO';
    } else if (trimmedFileType === FileMimeTypes.VIDEO) {
      attachmentType = 'VIDEO';
    }

    return attachmentType;
  }


  /**
   * Shows camera dialog and returns the attachment.
   */
  private showAttachImageDialog(): Observable<Attachment> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Capture photo',
      acceptButtonTitle: 'Capture',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: this.s3Category
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.imagePickerService.showCaptureImageUpload(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(dialogRes => dialogRes && dialogRes.accepted),

        // Transform the partial data to Attachment type
        map(dialogRes => dialogRes.data.attachment)
      );
  }

}
