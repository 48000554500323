import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiSurveyTileComponent } from './kalgudi-survey-tile/kalgudi-survey-tile.component';
import { SurveyActionBarComponent } from './survey-action-bar/survey-action-bar.component';
import { SurveyBasicDetailsComponent } from './survey-basic-details/survey-basic-details.component';



@NgModule({
  declarations: [
    KalgudiSurveyTileComponent,
    SurveyBasicDetailsComponent,
    SurveyActionBarComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatCardModule,
    MatDividerModule,

    FlexLayoutModule,

    AngularSvgIconModule,

    // Kalgudi modules
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiDirectivesModule,

    TranslateModule
  ],
  exports: [
    KalgudiSurveyTileComponent
  ]
})
export class KalgudiHomeStreamModule { }
