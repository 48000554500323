<div class="mt-3 mb-1 project-list-header tasks">

  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap.xs="16px">
    <!-- Content -->
    <div class="w-100" fxFlex="45%" fxLayout="row" fxLayoutGap="0" fxLayout.xs="column" fxLayoutGap.xs="16px">
      <div fxLayout fxFlex="100%" fxLayout.xs="column" class="w-100">
        <div fxLayout  fxFlex="100%" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" class="w-100">
          <div class="mr-3" [@scaleOut] fxFlex="10%">
            <!-- <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}"
              [applyCss]="true" class="mr-3 projects-icon"></svg-icon> -->
          </div>
          <div [@slideRight] class="ml-1 mr-3" fxFlex="80%">
            <div>
              <!-- <h4 class="text-capitalize text-truncate">Tasks</h4> -->
            </div>

          </div>
        </div>

      </div>

    </div>
    <!-- /Content -->

    <!-- Header options -->
    <div fxLayout="row" fxLayout.xs="column">

      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center" fxHide.xs>
        <div *ngFor="let menu of projectMenu" fxLayoutAlign="center center">
          <div *ngIf="menu?.svgIcon" class="mt-n2"
          [ngClass]="{'selected-icons': menu?.id === selectedView, 'menu-icons': menu?.id !== selectedView}" >
            <button mat-icon-button
              [disabled]="menu?.disabled"
              [matTooltip]="menu?.id | titlecase | translate" (click)="showProjectsList(menu)">
              <div [@scaleOut]>
                <svg-icon [src]="menu?.svgIcon" class="text-center"
                  [applyCss]="true"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18}"></svg-icon>
              </div>
            </button>
          </div>
        </div>

      </div>
    </div>
    <!--/ Header options -->
  </div>
</div>
