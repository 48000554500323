<main id="send-survey-analytics">
  <div fxLayout="column">

    <form autocomplete="off" class="w-100" [formGroup]="sendSurveyAnalyticsForm">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px" fxLayoutGap.xs="0">
        <div fxFlex="80%">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Send survey analytics to email</mat-label>
            <input matInput [placeholder]="'Enter your email id to get survey analytics' | translate" formControlName="email" required>
            <mat-error translate>Please enter a valid email id</mat-error>
          </mat-form-field>

        </div>

        <div fxFlex>
          <div class="h-100 mt-n2" fxLayout fxLayoutAlign="end center">

            <kl-flat-button
              label="Send"
              [progress]="progress"
              [disabled]="sendSurveyAnalyticsForm?.invalid"
              (clicked)="sendSurveyAnalytics()">
            </kl-flat-button>
          </div>
        </div>
      </div>

    </form>

  </div>
</main>
