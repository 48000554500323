<div id="kl-select-template">

  <div class="mb-3" [ngClass]="{'pointer-none': !!taskId}">
    <kl-search placeHolder="Search and select task template" [searchForm]="searchForm" (search)="search()"></kl-search>
  </div>

  <section class="step-container">

    <ng-container *ngIf="(paginator$ | async) as paginator">

      <div class="pt-2" fxLayout="column" *ngIf="(stream$ | async) as stream">
        <div *ngIf="stream.length > 0">
          <kl-select-card layout="column"
            [(selectedValue)]="taskType"
            (selectedValueChange)="onTemplateSelection($event)"
            [options]="stream"
            [disableSelection]="!!taskId">
          </kl-select-card>

          <!-- Load more -->
          <div class="mt-3" *ngIf="stream.length > 0" [ngClass]="{'pointer-none': !!taskId}">
            <kl-load-more [noMoreText]="'No more templates' | translate"
              [progress]="paginator.loading"
              [hasMoreItems]="paginator.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </div>
        </div>

        <!-- No content -->
        <div *ngIf="!stream || stream?.length <= 0" fxLayoutAlign="center center" class="pb-2 h-100 no-content">
          <kl-no-content
            matIcon="search"
            [progress]="paginator.loading"
            progressText="Loading templates..."
            text="No templates found"
            iconColor="#00000075">
          </kl-no-content>
        </div>
      </div>

    </ng-container>
  </section>

</div>
