<section class="kalgudi-share-update-comment-tile">

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">

    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start">

      <!-- user profile pic -->
      <div class="profile-pic rounded-circle small mt-1">
        <img class="w-100 h-100 rounded-circle avatar"
          [src]="comment?.profilePicURL"
          [klErrorImages]="[
          comment?.profilePicURL | prefixDomain,
          domain + defaultProfilePic
          ]"
        />
      </div>
      <!-- /user profile pic -->

      <!-- user Details -->
      <div class="author-details">
        <a class="text-capitalize author-name d-block text-truncate mb-0" (click)="viewProfile(comment?.id)">
          {{ comment?.name | secureMobile }}
        </a>
        <p class="mb-0 time comment-text" [innerHTML]="comment?.text | linkify">
          {{ comment?.text }}
        </p>
      </div>
      <!-- /user Details -->
    </div>

    <div fxLayout="row">
      <p class="text-muted mb-0 time mt-1 text-time" [title]="comment?.TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'"> {{ comment?.TS | timeAgo }}</p>
      <!-- <button mat-icon-button fxLayoutAlign="end end" class="header-menu" [matMenuTriggerFor]="Menu" fxHide.xs>
        <mat-icon>more_horiz</mat-icon>
      </button>

        <mat-menu #Menu="matMenu" [xPosition]="'before'" id="action-menu" class="mat-menu-sm">
          <button mat-menu-item class="mb-0 button-size" id="report-btn"><span translate>Report abuse</span></button>
          <button mat-menu-item class="mb-0 button-size" id="edit-btn" translate>Edit</button>
          <button mat-menu-item class="mb-0 button-size" id="delete-btn" translate>Delete</button>
        </mat-menu>

      <div fxHide.gt-xs *ngIf="isLoggedIn">
        <button mat-icon-button class="header-menu" (click)="openBottomSheet()">
          <mat-icon class="menu dot-menu">more_horiz</mat-icon>
        </button>
      </div> -->
    </div>

  </div>
</section>

