import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressService {

  constructor() { }

  compressImage(file: File): Observable<File | Blob> {

    return new Observable<File | Blob>( observer => {

      const img = document.createElement('img');
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        img.src = e.target.result;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const MAX_WIDTH = 960;
        const MAX_HEIGHT = 960;

        img.onload = () => {
          let width = img.width;
          let height = img.height;
          if (width > 0 || height > 0) {
            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx2 = canvas.getContext('2d');
            ctx2.drawImage(img, 0, 0, width, height);
            const dataurl = canvas.toDataURL(file.type, 0.82);
            let byteString = '';
            if (dataurl.split(',')[0].indexOf('base64') >= 0) {
              try {
                byteString = atob(dataurl.split(',')[1]);
              } catch (e) {
                // console.log(e);

              }
            } else {
              byteString = unescape(dataurl.split(',')[1]);
            }
            const mimeString = dataurl.split(',')[0].split(':')[1].split(';')[0];
            const ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
            const tempBlob = new Blob([ia], {type: file.type});
            // tempBlob.name = file.name;
            observer.next(tempBlob);
            observer.complete();
          } else {
            observer.next(file);
            observer.complete();
          }
        };
      };
    });
  }
}
