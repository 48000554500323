import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiPipesModule } from '@kalgudi/core';

import { PageAiPredictionsFiltersComponent } from './page-ai-predictions-filters/page-ai-predictions-filters.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    PageAiPredictionsFiltersComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    // Material
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatChipsModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiPipesModule,
  ],
  exports: [
    PageAiPredictionsFiltersComponent
  ]
})
export class KalgudiPageAiPredictionsFiltersModule { }
