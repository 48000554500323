import { Injector, Directive } from '@angular/core';
import { KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { Credit, CreditPageDetails } from '@kalgudi/types';
import { finalize } from 'rxjs/operators';

import { CreditService } from '../services/credit.service';


@Directive()
export abstract class KalgudiCreditDetails extends KalgudiDestroyable {

  creditDetails: Credit;

  pages: CreditPageDetails[];

  totalCredit: number;

  // Intial flag settings
  smsSettings = {
    domesticSms: false,
    domesticVoiceCall: false,
    internationalSms: false,
    internationalVoiceCall: false
  };

  creditService: CreditService;
  notifications: KalgudiNotification;
  private appService: KalgudiAppService;

  constructor(
    protected injector: Injector
  ) {
    super();
    // Manually inject dependencies
    this.appService    = this.injector.get(KalgudiAppService);
    this.creditService = this.injector.get(CreditService);
    this.notifications = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Gets, creditor id (profile key of logged in user)
   */
  get creditorId() {
    return this.appService.profileLocal.profileKey;
  }

  /**
   * Fetches the credit details from service
   */
  fetchCreditDetails() {
    this.notifications.showSpinner();
    this.creditService.fetchCreditDetails(this.creditorId)
      .pipe(finalize(() => this.notifications.hideSpinner()))
      .subscribe(
        res => {
        this.creditDetails = res;
        this.initiateCredit(this.creditDetails);
      },
        err => {
        this.notifications.showMessage('Something went wrong, please try again later.');
      }
    );
  }

  /**
   * Initiate credit settings
   */
  initiateCredit(credit: Credit) {
    this.smsSettings.domesticSms = credit.domesticSms;
    this.smsSettings.domesticVoiceCall = credit.domesticVoiceCall;
    this.smsSettings.internationalSms = credit.internationalSms;
    this.smsSettings.internationalVoiceCall = credit.internationalVoiceCall;
  }

  /**
   * Fetches the pages list of the logged in user from service
   */
  fetchPages() {
    this.notifications.showSpinner();
    this.creditService.fetchUserPages(0, 5)
      .pipe(finalize(() => this.notifications.hideSpinner()))
      .subscribe(
        res => {
        this.pages = res.pages;
        this.totalCredit = res.totalCredit;
      },
        err => {
        this.notifications.showMessage('Something went wrong, please try again later.');
      }
    );
  }

  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------


}
