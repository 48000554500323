import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';

import { KalgudiDestroyable } from './kalgudi-destroyable';

/**
 * Base definition for Kalgudi Autocomplete functionality. Defines function required for an
 * autocomplete to work.
 *
 * @publicApi
 *
 * Publicly exposed methods/members
 *
 * - `predictions$: Observable<T[]>`: Stream of items to be show in autocomplete.
 *
 * - `loading$: Observable<boolean>`: Progress stream set to `true` if search is in progress otherwise `false`.
 *
 * - `noPrediction: boolean`: Flag which gets set as `true` if there is no predictions to show, otherwise `false`
 *
 * - `autoCompleteForm: FormGroup`: Autocomplete form group.
 *
 * - `inputFieldValue`: Current value of the autocomplete input form control.
 *
 * - `placeholder`: Placeholder to show in autocomplete input field.
 *
 * - `label`: Label to show in autocomplete form field.
 *
 * - `appearance`: Form field appearance.
 *
 * @usageNotes
 * 1. Override `streamApi(searchKeyword: string): Observable<T[]>` method.
 *
 * 2. Initialize `autoCompleteForm` form group with the autocomplete form.
 *
 * 3. Override `inputField` getter that specifies the auto complete search field auto complete form control.
 *
 * 4. Override `displayWithFn(value: StoreBaseProductBasicDetails): any` function if your autocomplete works with objects.
 *
 * 5. Must call the `initAutocomplete()` in constructor or `ngOnInit()`.
 *
 * @example
 *
 * ```ts
 * export abstract class KalgudiBaseProductAutocomplete extends KalgudiAutocomplete<StoreBaseProductBasicDetails> {
 *
 *   private autoCompleteApi: BaseProductAutocompleteService;
 *
 *   constructor(
 *     protected injector: Injector,
 *     protected fb: FormBuilder,
 *   ) {
 *
 *     super(fb);
 *
 *     this.autoCompleteApi = this.injector.get(BaseProductAutocompleteService);
 *
 *     this.placeholder = 'Search and select base products';
 *     this.label       = 'Search and select base products';
 *
 *     // Initialize the autoCompleteForm
 *     this.autoCompleteForm = this.newAutoCompleteFormGroup;
 *
 *     // Construct the prediction list fetching stream
 *     this.initAutocomplete();
 *   }
 *
 *   get inputField(): AbstractControl {
 *     return this.autoCompleteForm.get('productName');
 *   }
 *
 *   private get newAutoCompleteFormGroup(): FormGroup {
 *
 *     return this.fb.group({
 *       productId: [''],
 *       productName: [''],
 *     });
 *   }
 *
 *   displayWithFn(value: StoreBaseProductBasicDetails): any {
 *     return value && value.productName ? value.productName : value;
 *   }
 *
 *   protected streamApi(searchKeyword: string): Observable<StoreBaseProductBasicDetails[]> {
 *     return this.autoCompleteApi.getProductsPredictions(searchKeyword);
 *   }
 * }
 * ```
 *
 * Autocomplete UI
 * ```html
 * <form [formGroup]="autoCompleteForm">
 *   <mat-form-field [appearance]="appearance" class="w-100">
 *     <mat-label>{{label}}</mat-label>
 *
 *     <input type="text" matInput formControlName="productName"
 *       [placeholder]="placeholder"
 *       [matAutocomplete]="autoComplete">
 *
 *     <span matSuffix *ngIf="loading$ | async">
 *       <span class="spinner-border spinner-border-lg text-muted" role="status"></span>
 *     </span>
 *
 *     <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete" [displayWith]="displayWithFn">
 *       <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" (click)="selectItem(prediction)">
 *         <mat-icon class="text-success">eco</mat-icon>
 *         <span>{{prediction?.productName}}</span>
 *       </mat-option>
 *     </mat-autocomplete>
 *   </mat-form-field>
 *
 *   <p class="mt-n3 my-1 text-danger" *ngIf="noPrediction">
 *     <span>No products found with keyword </span>
 *     <strong>"{{ inputFieldValue }}"</strong>
 *   </p>
 * </form>
 * ```
 *
 * To ensure the component can be used as a custom form control add below provider to the component.
 * ```ts
 * const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
 *   provide: NG_VALUE_ACCESSOR,
 *   useExisting: forwardRef(() => YourComponentName),
 *   multi: true,
 * };
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiAutocomplete<T> extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  placeholder = 'Search and select';

  @Input()
  label = 'Search and select';

  @Input()
  appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'outline';

  @Input()
  required = false;

  @Input()
  disabled = false;

  @Input()
  minSearchLength  = 2;

  @Output()
  selectedBaseProduct = new EventEmitter<any>();

  autoCompleteForm: FormGroup;
  noPrediction = false;

  predictions$: Observable<T[]>;
  loading$: Observable<boolean>;

  private loadingSubject = new Subject<boolean>();

  constructor(
    protected fb: FormBuilder
  ) {
    super();

    this.loading$ = this.loadingSubject.asObservable();
  }

  /**
   * Gets, the auto complete input form field value
   */
  get inputFieldValue(): string {
    return this.inputField.value;
  }

  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return null;
  }



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    // Type checking for undefined or null as patch value throws
    // error if obj is null or undefined
    if (obj) {
      this.autoCompleteForm.patchValue(obj);
    } else {
      this.autoCompleteForm.reset();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    // Disable the form if the state is disabled
    isDisabled
      ? this.autoCompleteForm.disable()
      : this.autoCompleteForm.enable();
  }

  /**
   * Initializes the autocomplete base class.
   *
   * NOTE: Must call this method after initializing the `autoCompleteForm`
   */
  initAutocomplete(): void {
    this.predictions$ = this.constructPredictions$();
  }

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usageNotes
   * ```html
   * <mat-autocomplete [displayWith]="displayWithFn">
   * ```
   */
  displayWithFn(value: T): any {
    return value;
  }

  /**
   * Event handler for autocomplete selection. It updates the formControl with the selected
   * item.
   */
  selectItem(item: MatOptionSelectionChange | T) {

    if ((item && item instanceof MatOptionSelectionChange) && !item.source.selected) {
      return;
    }

    const value = item instanceof MatOptionSelectionChange ? item.source.value : item;

    this.selectedBaseProduct.emit(value);

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(value);
    this.onTouched();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected abstract streamApi(searchKeyword: string): Observable<T[]>;

  /**
   * Initializes autocomplete `prediction$` observable stream.
   */
  private constructPredictions$(): Observable<T[]> {

    return this.inputField
      .valueChanges
      .pipe(
        // Instantiate the stream with empty string
        startWith(''),

        // Filter empty strings
        filter(searchTerm => this.isValidSearch(searchTerm) && !this.disabled),

        // Trim extra spaces
        map(searchTerm => searchTerm.trim()),

        // Add debounce time for repeated key strokes. This will make less API.
        debounceTime(500),

        // Don't fall down if the search keyword is not changed
        distinctUntilChanged(),

        // Clear the previous form control item
        // Turn on the loading spinner
        tap(_ => {
          this.selectItem(null);
          this.toggleLoadingProgress(true);
        }),

        // Make an Api call and return the response to `prediction$`
        switchMap(searchTerm => this.getPredictions(searchTerm)),
      );
  }

  /**
   * Calls the autocomplete prediction api to get the predictions.
   * It returns list of auto complete items on success or empty array on error.
   */
  private getPredictions(searchTerm: string): Observable<T[]> {

    return this.streamApi(searchTerm)
      .pipe(

        // Enable/Disable no prediction flag
        tap(prediction => this.noPrediction = Array.isArray(prediction) && prediction.length <= 0),

        // Handle api errors, on any api error return empty array
        catchError(() => {

          this.noPrediction = true;

          return of([]);
        }),

        // Turn off the loading spinner
        finalize(() => this.toggleLoadingProgress(false)),
      );
  }

  /**
   * Checks if the search term is valid or not to make an api call.
   */
  private isValidSearch(searchTerm: string): boolean {
    return typeof searchTerm === 'string' && searchTerm.length >= this.minSearchLength;
  }

  /**
   * Toggles the current loading progress
   */
  private toggleLoadingProgress(val: boolean): void {
    this.loadingSubject.next(val);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}

