import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UIMenu } from '@app/models';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'core-header-menu-list',
  templateUrl: './header-menu-list.component.html',
  styleUrls: ['./header-menu-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderMenuListComponent implements OnInit {

  @Input()
  profile: KalgudiUser;

  @Input()
  login: boolean = false;

  @Input()
  menu: UIMenu[];

  @Input()
  isMatchedProfile: boolean;

  @Output()
  menuClosed = new EventEmitter();

  constructor(@Inject(KL_ENV) public env: KalgudiEnvironmentConfig) { }

  ngOnInit() {
  }

  close() {
    this.menuClosed.emit();
  }

  /**
   * Open admin page
   */
  openAdminPage(id) {
    if ( id === 'ADMIN') {
      window.open(`${ this.env.domain}/admin`, '_blank');
    }
  }
}
