import { EventEmitter, Inject, Injector, Output, Directive } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService, KalgudiUsersService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService, KalgudiSocialShare } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { KalgudiPageDetails, KalgudiUserBasicDetails, ProgramShareRequest, ProgramShareUpdate } from '@kalgudi/types';
import { timer } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiPagesMembersPickerService } from '../../program-members-picker-dialog/services/kalgudi-pages-members-picker.service';
import { PageShareFiltersService } from '../../program-target-members/services/page-share-filters.service';
import { PAGE_TYPES } from "../../page-creation/models";

@Directive()
export abstract class DigitalTraining extends KalgudiSocialShare<ProgramShareRequest, ProgramShareUpdate> {

  @Output()
  postCreated = new EventEmitter<ProgramShareUpdate>();

  shareForm: FormGroup;
  pageId: string;
  pageTitle: string;
  pageDetails: KalgudiPageDetails;
  availablePageTypes = PAGE_TYPES;

  assistedUser = new FormControl();

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected programDialogs: KalgudiPagesMembersPickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected targetedMetaService: PageShareFiltersService,
    protected mobileMenuService: KalgudiBottomSheetService,
    protected kalgudiUsersService: KalgudiUsersService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      mobileMenuService,
    )

    // Initialize page details
    this.initPageDetails();

    // Subscribe to assisted user details change
    this.subscribeToAssistedUserValueChanges();

    // Clear authorID which will be picked digital assistant
    this.shareFormAuthorId.patchValue('');

    // Initialize share update form
    this.shareForm = this.programShareUpdateForm;

    // Patch program details to program form
    this.patchProgramDetailsToForm();
  }

  /**
   * Share update common form
   */
  private get programShareUpdateForm(): FormGroup {
    return new FormGroup({
      // Add a `assistantDetails` control to the share form
      assistantDetails: new FormControl('', Validators.required),

      // Common fields inherited from share update
      authorId: new FormControl('', Validators.required),
      shareText: new FormControl(''),
      richShareText: new FormControl(''),
      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
      lstOfAttachments: new FormControl([]),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(''),
      scheduledTS: new FormControl(''),
      sharedTo: new FormControl(this.shareVisibilityList[0].value, Validators.required),
      fromDate: new FormControl('', Validators.required),

      // Program specific fields
      recipient: new FormControl(this.shareVisibilityList[1].value, Validators.required),
      entityId: new FormControl('', Validators.required),
      entityName: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isAuthor: new FormControl(false, Validators.required),
      msgTypes: new FormControl([], Validators.required),
      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),
        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        pageUrl: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
        isSms: new FormControl(''),
        gender: new FormControl('')
      }),
      isPublishedThroughTool: new FormControl(true),
      productsMetaData: new FormControl([])
    });
  }

  /**
   * Gets the program details and binding the details to he share form
   */
  private initPageDetails(): void {
  timer(500)
    .pipe(
      takeUntil(this.destroyed$),
      first(),
      switchMap(_ =>
        this.kalgudiPageService.pageDetails$
          .pipe(
            takeUntil(this.destroyed$),
            first(),
            tap(pageDetails => this.patchPageDetails(pageDetails))
          )
      )
    ).subscribe();
  }

  /**
   * Patch page details to the share form
   */
  private patchPageDetails(pageDetails: KalgudiPageDetails): void {

    this.pageId = pageDetails.pageId;
    this.pageTitle = pageDetails.pageTitle;
    // Patch page details to form
    this.shareFormPageId.patchValue(this.pageId);
    this.shareFormPageTitle.patchValue(this.pageTitle);

    // Patch logged in user details to the assistant details
    this.shareFormAssistantDetails.patchValue(this.kalgudiUsersService.getBasicProfile(this.kalgudiApp.profileLocal));
  }

  /**
   * Subscribes to assisted user details value changes. On every change it updates the
   * author details of the share form.
   */
  private subscribeToAssistedUserValueChanges(): void {
    this.assistedUser.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      ).subscribe(user => this.onAssistedUserChange(user));
  }

  /**
   * Action to perform on `assistedUser` form control value changes. It updates the
   * author details of the share form.
   */
  private onAssistedUserChange(userDetails: KalgudiUserBasicDetails): void {
    // Null check for userDetails fields
    const profileKey = userDetails && userDetails.profileKey ? userDetails.profileKey : '';

    this.shareFormAuthorId.patchValue(profileKey);
  }

  /**
   * Getter function to access the 'assistantDetails' form control from 'shareForm' form group.
   */
  private get shareFormAssistantDetails(): AbstractControl {
    return this.shareForm.get('assistantDetails');
  }

  /**
   * Share form author id field
   */
  get shareFormAuthorId(): AbstractControl {
    return this.shareForm.get('authorId');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form shared to field
   */
  get shareFormSharedTo(): AbstractControl {
    return this.shareForm.get('sharedTo');
  }

  /**
   * Share form program entity id field
   */
  get shareFormEntityId(): AbstractControl {
    return this.shareForm.get('entityId');
  }

  /**
   * Share form program entity name field
   */
  get shareFormEntityName(): AbstractControl {
    return this.shareForm.get('entityName');
  }

  /**
   * Share form program is admin field
   */
  get shareFormIsAdmin(): AbstractControl {
    return this.shareForm.get('isAdmin');
  }

  /**
   * Share form program is author field
   */
  get shareFormIsAuthor(): AbstractControl {
    return this.shareForm.get('isAuthor');
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * Share form program is sms field
   */
  get shareFormIsSms(): AbstractControl {
    return this.shareFormFilter.get('isSms');
  }

  /**
   * Share form program is page id field
   */
  get shareFormPageId(): AbstractControl {
    return this.shareFormFilter.get('pageId');
  }

  /**
   * Share form program is page title field
   */
  get shareFormPageTitle(): AbstractControl {
    return this.shareFormFilter.get('pageTitle');
  }

  /**
   * Share form program is page type field
   */
  get shareFormPageType(): AbstractControl {
    return this.shareFormFilter.get('pageType');
  }

  /**
   * Share form program is page url field
   */
  get shareFormPageUrl(): AbstractControl {
    return this.shareFormFilter.get('pageUrl');
  }

  /**
   * Share form program is parent page id field
   */
  get shareFormParentPageId(): AbstractControl {
    return this.shareFormFilter.get('parentPageId');
  }

  /**
   * Share form program is parent page title field
   */
  get shareFormParentPageTitle(): AbstractControl {
    return this.shareFormFilter.get('parentPageTitle');
  }

  /**
   * Share form program is parent page url field
   */
  get shareFormParentPageUrl(): AbstractControl {
    return this.shareFormFilter.get('parentPageUrl');
  }

  /**
   * Share form msgTypes field
   */
  get shareFormMsgTypes(): AbstractControl {
    return this.shareForm.get('msgTypes');
  }

  /**
   * Returns true if the advisories tag is selected else returns false
   */
  get isAdvisorySelected(): boolean {
    return Array.isArray(this.shareFormMsgTypes.value)
      ? this.shareFormMsgTypes.value.includes('ADVISORIES')
      : false;
  }

  /**
   * Share form program is products meta data field
   */
  get selectedProducts(): AbstractControl {
    return this.shareForm.get('productsMetaData');
  }

  /**
   * Share form program is training date field
   */
  get shareFormFromDate(): AbstractControl {
    return this.shareForm.get('fromDate');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {
    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);
      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets the program share update form
   * @override
   */
  resetForm() {
    // Honour all parent actions
    super.resetForm();
    this.audioAttachment.patchValue(null);

    // Patch page details after resetting page form
    this.patchProgramDetails(this.pageDetails);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the program details and binding the details to he share form
   */
  private patchProgramDetailsToForm(): void {
    timer(500)
      .pipe(
        takeUntil(this.destroyed$),
        first(),
        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),
              first(),
              tap(r => this.patchProgramDetails(r))
            )
        )
      ).subscribe();
  }

  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {
    this.pageDetails = pageDetails;
    this.pageId = pageDetails.pageId;

    // Required fields
    this.shareFormAuthorId.patchValue(this.kalgudiApp.profileLocal.profileKey);
    this.shareFormVisibility?.patchValue(this.shareVisibilityList[1].value);
    this.shareFormSharedTo?.patchValue(this.shareVisibilityList[0].value);

    this.shareFormEntityId.patchValue(pageDetails.pageId);
    this.shareFormEntityName.patchValue(pageDetails.pageTitle);
    this.shareFormIsAdmin.patchValue(this.kalgudiPageService.isPageAdmin(pageDetails));
    this.shareFormIsAuthor.patchValue(this.kalgudiPageService.isPageAuthor(pageDetails));

    // Filters
    this.shareFormIsSms.patchValue(false);
    this.shareFormPageId.patchValue(pageDetails.pageId);
    this.shareFormPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormPageType.patchValue(pageDetails.pageType);
    this.shareFormPageUrl.patchValue(pageDetails.pageProfilePic);
    this.shareFormParentPageId.patchValue(pageDetails.pageId);
    this.shareFormParentPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormParentPageUrl.patchValue(pageDetails.pageProfilePic);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
