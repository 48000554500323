<div id="create-survey-dialog" fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden kl-create-survey-dialog mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" translate  mat-dialog-title>{{dialogData?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <kl-create-survey
      [creationPayload]="creationPayload"
      (surveyCreated)="onSurveyCreation($event)">
    </kl-create-survey>
  </div>
</div>
