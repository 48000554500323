<section class="company-page mb-3 page-creation-form">
  <div class="w-100 company col-7 mx-auto p-0 page-form">

    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="15px">

        <!-- Vision -->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Vision</mat-label>
            <textarea rows="1" matInput formControlName="vision" required></textarea>
            <mat-error class="mt-3" *ngIf="vision?.errors?.required" translate>Please mention company vision</mat-error>
          </mat-form-field>
        </div>
        <!--/Vision -->

        <div class="company-founded-year w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
          <!-- Founded year -->
          <mat-form-field appearance="outline" class="date-picker" fxFlex="calc(50% - 20px)">
            <input matInput [placeholder]="'Founded year' | translate"
              formControlName="foundedYear"
              (click)="foundedYear.open()"
              [matDatepicker]="foundedYear"
              readonly />
            <mat-datepicker-toggle matSuffix [for]="foundedYear"></mat-datepicker-toggle>
            <mat-datepicker #foundedYear startView="year"></mat-datepicker>
          </mat-form-field>
          <!-- /Founded year -->

          <!-- Founders -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Founders</mat-label>
            <input matInput [placeholder]="'Founders' | translate" formControlName="founders">
          </mat-form-field>
          <!-- /Founders -->
        </div>

        <div class="company-founded-years  w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
          <!-- No of employees -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>No of employees</mat-label>
            <mat-select formControlName="noOfEmployees">
              <mat-option *ngFor="let employees of employeesList" [value]="employees">{{ employees | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- /No of employees -->

          <!-- Select industry -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Select industry</mat-label>
            <mat-select formControlName="industry">
              <mat-option *ngFor="let industry of industryList" [value]="industry">{{ industry | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- /Select industry -->
        </div>
      </div>
    </form>
    <!-- /Wrapper Form -->
  </div>
</section>
