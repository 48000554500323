import { Injectable } from '@angular/core';
import { ProgramShareUpdate } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiShareUpdateStreamStateService {

  private newStreamItemSubject = new Subject<ProgramShareUpdate>();

  constructor() { }

  get newStreamItem$(): Observable<ProgramShareUpdate> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: ProgramShareUpdate): void {
    this.newStreamItemSubject.next(item);
  }
}
