import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiMobileDialog } from '../../../mobile-dialog/classes/kalgudi-mobile-dialog';
import { KalgudiMobileDialogRef } from '../../../mobile-dialog/classes/mobile-dialog-overlay-ref';
import { KL_MOBILE_DIALOG_DATA } from '../../../mobile-dialog/config';
import { MobileDialogConfig } from '../../../mobile-dialog/models';

@Component({
  selector: 'kl-status-mobile-dialog',
  templateUrl: './status-mobile-dialog.component.html',
  styleUrls: ['./status-mobile-dialog.component.scss']
})
export class StatusMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
