import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiExistingProjects } from '../../classes/kalgudi-existing-projects';

@Component({
  selector: 'kl-existing-projects',
  templateUrl: './existing-projects.component.html',
  styleUrls: ['./existing-projects.component.scss']
})
export class ExistingProjectsComponent extends KalgudiExistingProjects implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

}
