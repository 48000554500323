<form autocomplete="off" [formGroup]="autoCompleteForm" class="position-relative">

  <mat-form-field [appearance]="appearance" class="w-100 search-location  custom-form-field">
    <mat-label *ngIf="label" translate>{{label || 'Search and select location'}}</mat-label>

    <input #autoCompleteInput class="select-location" type="text" matInput formControlName="locationLong"
      [placeholder]="placeholder || 'Search and select location' | translate"
      [matAutocomplete]="googlePlacesAutocomplete" [required]="required">

    <mat-autocomplete autoActiveFirstOption #googlePlacesAutocomplete="matAutocomplete"
      [displayWith]="displayLocationLong" (optionSelected)="locationChanged($event)">
      <mat-option class="google-places-options-list"
        *ngFor="let prediction of predictions$ | async"
        [value]="prediction"
        (onSelectionChange)="getPlaceDetails(prediction?.placeId, prediction?.locationLong)">

        <mat-icon>location_on</mat-icon>
        <span [title]="prediction?.locationLong">{{prediction?.locationLong}}</span>
      </mat-option>
    </mat-autocomplete>

    <!-- Search -->
    <button mat-button matSuffix mat-icon-button>
      <mat-icon class="search">search</mat-icon>
    </button>
    <!-- /Search -->
    <mat-error *ngIf="required && locationLongField?.touched" translate>Location is required</mat-error>
  </mat-form-field>
  <mat-error class="invalid-location-error text-danger position-absolute"
    *ngIf="locationLongField.value && !locationLongField.value.locationLong" translate>Please select a valid location
  </mat-error>
</form>

<div id="google-places-auto-complete"></div>
