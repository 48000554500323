<div class="footer-mobile-tab w-100">
  <div id="tab-color" [class]="color || 'accent'">
    <button mat-button class="w-100 px-3 py-2" [routerLink]="menu?.routerLink" routerLinkActive="router-link-active"
      (click)="tabClicked()">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
        <mat-icon class="icon" *ngIf="menu?.matIcon">{{ menu?.matIcon }}</mat-icon>
        <svg-icon class="svg-icon" *ngIf="menu?.svgIcon" [src]="menu?.svgIcon" [svgStyle]="{ 'width.px': 25, 'height.px': 25}">
        </svg-icon>
        <span class="title" *ngIf="menu?.title" translate>{{ menu?.title }}</span>
      </div>
    </button>
  </div>
</div>