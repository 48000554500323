import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { BusinessFieldNames } from '@kalgudi/types';

import { KalgudiProfileTaxesService } from './kalgudi-profile-taxes.service';
import { ProfileSectionTax } from './profile-section-taxes';

@Component({
  selector: 'kl-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent extends ProfileSectionTax implements OnInit {
  itemsList: BusinessFieldNames[] = [

    {
      fieldId: 'CST',
      fieldName: 'CST',
      abbreviation: 'Central Sales Tax'
    },
    {
      fieldId: 'FL',
      fieldName: 'FL',
      abbreviation: 'Fertilizer License'
    },
    {
      fieldId: 'GST',
      fieldName: 'GST',
      abbreviation: 'GST'
    },
    {
      fieldId: 'PL',
      fieldName: 'PL',
      abbreviation: 'Pesticide License'
      },
    {
      fieldId: 'SL',
      fieldName: 'SL',
      abbreviation: 'Seed License'
      },
    {
      fieldId: 'TIN',
      fieldName: 'TIN',
      abbreviation: 'Tax identification number'
      },
    {
      fieldId: 'VAT',
      fieldName: 'VAT',
      abbreviation: 'Value Added Tax'
      }
];
  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileTaxesService,
  ) {
    super(notification, app, util, api);
   }

  ngOnInit() {
  }

  protected onDestroyed(): void { }

}
