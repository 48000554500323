import { S3PolicyMap } from "@kalgudi/types";

export const KALGUDI_S3_POLICY_MAP: S3PolicyMap = {
  SHARE: {
    category: "stream",
    path: "data/activity/share-a-thought",
  },
  QA: {
    category: "stream",
    path: "data/activity/qa",
  },
  STORE_PRODUCTS: {
    category: "storeproducts",
    path: "data/p_images_raw",
  },
  PROFILE_PIC: {
    category: "profilepic",
    path: "data/profilepics",
  },
  COVER_PIC: {
    category: "profilepic",
    path: "data/profilepics/coverpics",
  },
  BUSINESS: {
    category: "business",
    path: "data/businessAttachments",
  },
  DEFAULT: {
    category: "business",
    path: "data/businessAttachments",
  },
  IMAGE_SEARCH: {
    category: "imageSearch",
    path: "data/imageSearch",
  },
  VOICE_SEARCH: {
    category: "voiceSearch",
    path: "data/voiceSearch",
  },
  ROBOCALL: {
    category: "stream",
    path: "data/activity/share-a-thought",
  },
};
