import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'core-home-store-banner-tile',
  templateUrl: './home-store-banner-tile.component.html',
  styleUrls: ['./home-store-banner-tile.component.scss']
})
export class HomeStoreBannerTileComponent implements OnInit {

  constructor(
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private route: Router
  ) { }

  ngOnInit() {

  }

  /**
   * Navigate to store page
   */
  openStorePage() {

    window.open(`https://shaktimanfarmingsolutions.com`, '_blank');
  }

}
