import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProjectApiService } from './services/kalgudi-project-api.service';
import { KalgudiProjectStateService } from './services/kalgudi-project-state.service';
import { KalgudiProjectService } from './services/kalgudi-project.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  exports: [],
  providers: [
    KalgudiProjectService,
    KalgudiProjectApiService,
    KalgudiProjectStateService,
  ]
})
export class KalgudiProjectManagerModule {

  public static forRoot(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiProjectManagerModule> {

    return {
      ngModule: KalgudiProjectManagerModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
