import { Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { IdValueMap } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

@Directive()
export abstract class KalgudiPageFilter {

  /**
   * Initial list of all filter items
   */
  @Input()
  items: IdValueMap[];

  /**
   * Parent form array
   */
  @Input()
  formArray: FormArray;

  /**
   * Local search and add form group
   */
  formGroup: FormGroup;

  private filteredItems: Observable<IdValueMap[]>;

  constructor() {

    this.formGroup = new FormGroup({
      searchText: new FormControl(),
      items: new FormArray([])
    });


    this.filteredItems = this.searchTextField.valueChanges
      .pipe(
        // Default filter
        startWith(''),

        // Trim extra spaces
        map(r => typeof r === 'string' ? r.trim() : ''),

        // Don't search until the search keyword changes
        distinctUntilChanged(),

        // Gap between each keystroke
        debounceTime(200),

        // Filter items based on the search keyword
        map(searchKeyword => searchKeyword ? this.filterItems(searchKeyword) : this.items.slice())
      );
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the search field form control
   */
  get searchTextField(): AbstractControl {
    return this.formGroup.get('searchText');
  }

  /**
   * Gets, the items form array.
   */
  get itemsFormArray(): FormArray {
    return this.formGroup.get('items') as FormArray;
  }

  /**
   * Gets, the selected items list
   */
  get itemsList(): IdValueMap[] {
    return this.itemsFormArray.value;
  }

  /**
   * Gets, stream of items containing filtered items.
   */
  get filteredItems$(): Observable<IdValueMap[]> {
    return this.filteredItems;
  }

  /**
   * Gets, list of all selected items id.
   */
  private get selectedItems(): string[] {
    return this.formArray.value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Autocomplete display function. Alters the default model rendering
   * behavior of autocomplete input field.
   */
  autoCompleteDisplayFn(item: IdValueMap): string {
    return item && item.value ? item.value : '';
  }

  /**
   * Pushes the item to the form group array. It also updates the
   * form control to which the component is bind-ed.
   */
  selectItem(event: MatOptionSelectionChange): void {

    const item: IdValueMap = event.source.value;

    // Don't re-select an existing item
    if (this.isSelected(item)) {
      return;
    }

    // Push item to local array form
    this.itemsFormArray.push(this.newItemFormGroup(item));

    // Push item id to the parent form
    this.formArray.push(this.newItemFormControl(item));

    // Reset the search text field
    this.searchTextField.patchValue('');
  }

  /**
   * Removes already selected item from the form array
   */
  removeItem(index: number): void {

    // Remove item from the local array
    this.itemsFormArray.removeAt(index);

    // Remove item from the parent
    this.formArray.removeAt(index);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Creates a new form group with the product details.
   */
  private newItemFormGroup(product: IdValueMap): FormGroup {

    return new FormGroup({
      id: new FormControl(product.id),
      value: new FormControl(product.value)
    });
  }

  /**
   * Creates a new form group with the product details.
   */
  private newItemFormControl(product: IdValueMap): FormControl {

    return new FormControl(product.id);
  }

  /**
   * Filter items based on their item name value. It trims and the value
   * and compares with the search keyword.
   *
   * The filter is case insensitive.
   */
  private filterItems(keyword: string = ''): IdValueMap[] {
    const filterValue = typeof keyword === 'string' ? keyword.trim().toLowerCase() : '';

    return this.items.filter(item => item.value.toLowerCase().includes(filterValue));
  }

  /**
   * Checks, whether an item is selected or not.
   *
   * @returns `true` if an item is already selected otherwise `false`.
   */
  private isSelected(item: IdValueMap): boolean {

    return this.selectedItems.findIndex(selectedProductId => item.id === selectedProductId) >= 0;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
