<div fxLayout="column" fxLayoutGap="20px" class="coverage-stats w-100">

  <!-- Members stats -->
  <mat-card>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <mat-icon class="members">groups</mat-icon>
      </div>
      <h2 class="m-0" translate>Members</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="membersColorScheme"
        [results]="memberStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 members-total">{{ totalMembers | number}}</p>
          <p class="mb-0 label" translate>Members</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="memberStats === null"></kl-fake-it-block>
  </mat-card>

  <!-- /Members stats -->

  <!--Location stats -->
  <mat-card>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <mat-icon class="locations">location_on</mat-icon>
      </div>
      <h2 class="m-0" translate>Location</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="locationColorScheme"
        [results]="locationStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 locations-total">{{ totalLocations | number}}</p>
          <p class="mb-0 label" translate>Locations</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="locationStats === null"></kl-fake-it-block>
  </mat-card>

  <!-- /Location stats -->

  <!-- Product stats -->
  <mat-card>
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <mat-icon class="products">eco</mat-icon>
      </div>
      <h2 class="m-0" translate>Products</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="productsColorSchema"
        [results]="productStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 products-total">{{ totalProducts | number}}</p>
          <p class="mb-0 label" translate>Products</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="productStats === null"></kl-fake-it-block>
  </mat-card>

  <!-- /Product stats -->

</div>
