import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiFarmerLiveStockList } from '../../classes/kalgudi-farmer-live-stock';

@Component({
  selector: 'kl-farmers-live-stock',
  templateUrl: './farmers-live-stock.component.html',
  styleUrls: ['./farmers-live-stock.component.scss']
})
export class FarmersLiveStockComponent extends KalgudiFarmerLiveStockList implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
  }

}
