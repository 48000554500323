<div class="task-status-tile" fxLayout="column" fxLayoutGap="7px">
  <div fxLayout fxLayoutAlign="space-between center" fxLayoutGap="10px">

    <!-- User updated -->
    <div *ngIf="taskStatus?.activity?.createdBy?.firstName" class="text-truncate user-name" fxFlex="calc(100% - 70px)">
      {{ taskStatus?.activity?.createdBy?.firstName | titlecase }} updated
    </div>
    <!-- /User updated -->

    <!-- Date -->
    <div class="date" fxFlex="60px" fxLayout="row">
      <span>{{ taskStatus?.LUT | date: 'dd MMM yy'}}</span>
    </div>
    <!-- /Date -->
  </div>

  <!-- Status -->
  <div fxLayout fxLayoutGap="3px">
    <span class="status">Status changed:</span>
    <span class="previous-state">{{ previousState | titlecase }}</span>
    <span *ngIf="taskStatus?.activity?.previousState && taskStatus?.activity?.state" class="status">to</span>
    <span class="state">{{ state | titlecase }}</span>
  </div>
  <!-- /Status -->
</div>
