<main id="profile-certificates-achievements" class="h-100">
  <mat-card class="certification-info h-100">

    <!-- Certification header -->
    <kl-common-profile-header
      title="Certificates"
      svgIcon="assets/svgs/medal.svg"
      [editable]="editable && certificates?.length >= 1"
      actionIcon="add"
      (action)="showAddCertificateDialog()">
    </kl-common-profile-header>
    <!--/ Certificates header -->

      <ng-container *ngFor="let certificate of certificates; let i = index; let last = last">

          <div fxLayout fxLayoutAlign="space-between center" class="mt-2 certificate-list">
            <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
              <ng-container *ngIf="certificate?.attachments && certificate?.attachments[0]?.url; else elseBlock">
                <img [src]="certificate?.attachments[0]?.url | prefixDomain" width="50" height="40">
              </ng-container>
              <ng-template #elseBlock>
                <img src="assets/images/certificates.jpg" width="50" height="40">
              </ng-template>
              <div>
                <p class="achievement-title mb-0 text-capitalize font-weight-bold">{{ certificate?.certName }} </p>
                <p class="achievement-description mb-0">
                  <span translate>From</span> {{ certificate?.certAuthority }}</p>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="8px" *ngIf="editable">
              <!-- <button mat-icon-button class="action-buttons edit-btn" (click)="updateCertificate(certificate?.businessCertificationId, certificate)">
                <mat-icon class="action-icons text-white">edit</mat-icon>
              </button> -->

              <button mat-icon-button class="action-buttons cancel-icon" (click)="deleteCertificate(certificate?.businessCertificationId)">
                <mat-icon class="action-icons delete-icon">delete_forever</mat-icon>
              </button>
            </div>

          </div>

          <div class="mt-2" fxLayout fxLayoutAlign="space-between center" *ngIf="certificate?.organicCertification && (certificate?.organicCertification?.farmers || certificate?.organicCertification?.crops || certificate?.organicCertification?.acres)">
            <div>
              <p class="m-0 certificate-organic-label">Farmers</p>
              <p class="m-0 certificate-organic-value">{{certificate?.organicCertification?.farmers ? certificate?.organicCertification?.farmers : '-'}}</p>
            </div>

            <div>
              <p class="m-0 certificate-organic-label">Crops</p>
              <p class="m-0 certificate-organic-value">{{certificate?.organicCertification?.crops || '-'}}</p>
            </div>

            <div>
              <p class="m-0 certificate-organic-label">Acres</p>
              <p class="m-0 certificate-organic-value">{{certificate?.organicCertification?.acres ? certificate?.organicCertification?.acres : '-'}}</p>
            </div>
          </div>

          <div *ngIf="!last" class="border-bottom pt-2 mb-2">
          </div>



      </ng-container>

    <!--/ Certificates -->

    <!-- No content if there no certificates -->
    <ng-container *ngIf="!certificates?.length">
      <div class="w-100" fxLayoutAlign="center center">
        <kl-no-content
          [text]="isPublicPage ? 'No certificate added yet!' : editable ? '' : 'No certificate added yet!'"
          [actionLink]="isPublicPage ? '' : editable ? 'Add certificates' : ''"
          (action)="showAddCertificateDialog()">
        </kl-no-content>
      </div>
    </ng-container>
    <!--/ No content if there no certificates -->
  </mat-card>
</main>
