import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiImageChipListComponent } from './kalgudi-image-chip-list.component';

@NgModule({
  declarations: [
    KalgudiImageChipListComponent
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule,

    TranslateModule,

    // Material
    MatIconModule,
    MatChipsModule,
    MatButtonModule,

    // Flex
    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiButtonModule,
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiPhotoswipeModule,
  ],
  exports: [
    KalgudiImageChipListComponent
  ]
})
export class KalgudiImageChipListModule { }
