<div class="add-cattle">
  <form autocomplete="off" [formGroup]="changePasswordForm">
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Current password -->
        <div fxLayout="column" class="w-100">
          <mat-form-field appearance="outline">
            <mat-label translate>Current password</mat-label>
            <input matInput required formControlName="currentPassword"
              [type]="hidePassword ? 'password' : 'text'"
              [placeholder]="'Enter current password' | translate">
              <mat-icon matSuffix (click)="hidePassword = !hidePassword">
                {{ hidePassword ? "visibility_off" : "visibility" }}
              </mat-icon>
            <mat-error translate>Please enter current password</mat-error>
          </mat-form-field>
        </div>

        <!-- New password -->
        <div fxLayout="column" class="w-100">
          <mat-form-field appearance="outline">
            <mat-label translate>New password</mat-label>
            <input matInput required formControlName="newPassword"
              [type]="hideNewPassword ? 'password' : 'text'"
              [placeholder]="'Enter new password' | translate">
            <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">
              {{ hideNewPassword ? "visibility_off" : "visibility" }}
            </mat-icon>
            <mat-error translate>Please enter new password</mat-error>
          </mat-form-field>
        </div>

        <!-- Confirm password -->
        <div fxLayout="column" class="w-100">
          <mat-form-field appearance="outline">
            <mat-label translate>Confirm password</mat-label>
            <input matInput required formControlName="confirmPassword"
              [type]="hideConfirmPassword ? 'password' : 'text'"
              [placeholder]="'Enter confirm password' | translate">
              <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                {{ hideConfirmPassword ? "visibility_off" : "visibility" }}
              </mat-icon>
            <mat-error translate>Please enter confirm password</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="pt-2" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
        <kl-flat-button
          type="submit"
          label= "Change password"
          [progress]="progress"
          (clicked)="changePassword()"
          [disabled]="newPasswordField?.value !== confirmPasswordField?.value">
        </kl-flat-button>
      </div>

    </div>

  </form>

</div>
