import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAutocomplete } from '@kalgudi/core';
import { BaseProductAutocomplete, BaseProductTypes, StoreBaseProductBasicDetails, StoreType } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiTaskCreationService } from '../services/kalgudi-task-creation.service';

@Directive()
export abstract class KalgudiTaskTagsProductAutocomplete extends KalgudiAutocomplete<StoreBaseProductBasicDetails> {

  @Input()
  storeType: StoreType | '' = '';

  @Input()
  productType: BaseProductTypes = BaseProductTypes.BASE_PRODUCT;

  @Input()
  baseProduct: StoreBaseProductBasicDetails;

  @Input()
  selectedBaseCategoryId: string;

  private autoCompleteApi: KalgudiTaskCreationService;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder
  ) {

    super(fb);

    this.autoCompleteApi = this.injector.get(KalgudiTaskCreationService);
    this.fb = this.injector.get(FormBuilder)

    this.placeholder = 'Search and select product';
    this.label       = 'Search and select product';

    // Initialize the autoCompleteForm
    this.autoCompleteForm = this.newAutoCompleteFormGroup;

    // Construct the prediction list fetching stream
    this.initAutocomplete();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return this.autoCompleteForm.get('productName');
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      productId: [''],
      productName: [''],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayWithFn(value: StoreBaseProductBasicDetails): any {
    return value && value.productName ? value.productName : value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected streamApi(searchKeyword: string): Observable<StoreBaseProductBasicDetails[]> {

    // Choose the base product api to call based on its type
    const api = this.baseProductPredictionApi(searchKeyword)
    return api.pipe(
        map(predictions => this.mapProductDetails(predictions))
      );
  }

  /**
   * Gets, product prediction with the specified search keyword
   */
  private baseProductPredictionApi(searchKeyword: string): Observable<BaseProductAutocomplete[]> {
    return this.autoCompleteApi.getProductsPredictions(searchKeyword);
  }

  /**
   * Maps a list of google places prediction result to KalgudiLocation type list.
   */
  private mapProductDetails(predictions: any[]): StoreBaseProductBasicDetails[] {

    return predictions.map(prediction => {
      return {
        productId: prediction.productId_level2,
        productName: prediction.productName_level2,
      };
    });

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
