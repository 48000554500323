import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { KalgudiStepperComponent } from './kalgudi-stepper.component';

@NgModule({
  declarations: [KalgudiStepperComponent],
  imports: [
    CommonModule,


    // Form Module
    ReactiveFormsModule,

    // Flex Module
    FlexLayoutModule,

    // Material Modules
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
  ],
  exports: [
    KalgudiStepperComponent
  ]
})
export class KalgudiStepperModule { }
