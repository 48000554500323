import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiDialogsModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiTimePickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { KalgudiDirectivesModule, KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';

import { PageActivityFiltersModule } from '../page-activity-filters/page-activity-filters.module';
import {
  KalgudiProgramMembersPickerDialogModule,
} from '../program-members-picker-dialog/program-members-picker-dialog.module';
import { KalgudiProgramShareUpdatesModule } from '../program-share-updates/program-share-updates.module';
import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import { MobilePublishSmsComponent } from './components/mobile-publish-sms/mobile-publish-sms.component';
import { PublishSmsFormComponent } from './components/publish-sms-form/publish-sms-form.component';
import { PublishSmsTileComponent } from './components/publish-sms-tile/publish-sms-tile.component';
import { SmsLogFiltersComponent } from './components/sms-log-filters/sms-log-filters.component';
import { SmsLogStatusComponent } from './components/sms-log-status/sms-log-status.component';
import { SmsLogsDialogComponent } from './components/sms-logs-dialog/sms-logs-dialog.component';
import { SmsLogsListComponent } from './components/sms-logs-list/sms-logs-list.component';
import { SmsLogsMobileDialogComponent } from './components/sms-logs-mobile-dialog/sms-logs-mobile-dialog.component';
import { SmsLogsStreamComponent } from './components/sms-logs-stream/sms-logs-stream.component';
import { SmsLogsTileComponent } from './components/sms-logs-tile/sms-logs-tile.component';
import {
  UpdateScheduledSmsDialogComponent,
} from './components/update-scheduled-sms-dialog/update-scheduled-sms-dialog.component';
import {
  UpdateScheduledSmsMobileDialogComponent,
} from './components/update-scheduled-sms-mobile-dialog/update-scheduled-sms-mobile-dialog.component';
import { KalgudiSmsStreamStateService } from './services/kalgudi-sms-stream-state.service';
import { KalgudiSmsUpdateService } from './services/kalgudi-sms-update.service';
import { LogsActionService } from './services/logs-action.service';
import { LogsListApiService } from './services/logs-list-api.service';
import { LogsListService } from './services/logs-list.service';
import { SmsLogsListStateService } from './services/sms-logs-list-state.service';

@NgModule({
  declarations: [
    PublishSmsFormComponent,
    PublishSmsTileComponent,
    SmsLogsDialogComponent,
    SmsLogsMobileDialogComponent,
    SmsLogsListComponent,
    SmsLogsTileComponent,
    SmsLogsStreamComponent,
    SmsLogFiltersComponent,
    SmsLogStatusComponent,
    MobilePublishSmsComponent,
    UpdateScheduledSmsDialogComponent,
    UpdateScheduledSmsMobileDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMenuModule,
    MatBottomSheetModule,
    MomentDateModule,

    // Kalgudi
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiUsersModule,
    KalgudiProgramTargetMembersModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiProgramShareUpdatesModule,
    KalgudiTimePickerModule,
    MatInputModule,
    PageActivityFiltersModule,
    KalgudiDialogsModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiDirectivesModule,
    TranslateModule,
    KalgudiSlideToggleModule

  ],
  exports: [
    PublishSmsFormComponent,
    PublishSmsTileComponent,
    UpdateScheduledSmsDialogComponent,
    UpdateScheduledSmsMobileDialogComponent
  ],
  entryComponents: [
    SmsLogsDialogComponent,
    SmsLogsMobileDialogComponent,
    MobilePublishSmsComponent,
    UpdateScheduledSmsDialogComponent,
    UpdateScheduledSmsMobileDialogComponent
  ],
  providers: [
    LogsActionService,
    LogsListService,
    LogsListApiService,
    KalgudiSmsStreamStateService,
    SmsLogsListStateService,
    KalgudiSmsUpdateService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]
})
export class KalgudiProgramSmsModule { }
