import { Component, Inject, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'kl-task-selected-members-list',
  templateUrl: './task-selected-members-list.component.html',
  styleUrls: ['./task-selected-members-list.component.scss']
})
export class TaskSelectedMembersListComponent implements OnInit {

  @Input()
  members: any[];

  @Input()
  isSubmission: boolean;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  taskMemberListData: any[] = [
    {
      profilePicUrl: "/assets/images/default-profile.jpeg",
      firstName: "Surya verdhan",
      location: "Warangal",
      businessTypeName: 'Employee',
      profileKey: '1'
    },
    {
      profilePicUrl: "/assets/images/default-profile.jpeg",
      firstName: "Nandhan Kumar",
      location: "Warangal",
      businessTypeName: 'Employee',
      profileKey: '2'
    },
    {
      profilePicUrl: "/assets/images/default-profile.jpeg",
      firstName: "Aryan sri",
      location: "Warangal",
      businessTypeName: 'Employee',
      profileKey: '3'
    },
    {
      profilePicUrl: "/assets/images/default-profile.jpeg",
      firstName: "Vasundhara",
      location: "Warangal",
      businessTypeName: 'Employee',
      profileKey: '4'
    },
    {
      profilePicUrl: "/assets/images/default-profile.jpeg",
      firstName: "Nandhan Kumar",
      location: "Warangal",
      businessTypeName: 'Employee',
      profileKey: '5'
    }
  ]

  showSelectedList: boolean;
  domain: string;

  constructor(@Inject(KL_ENV) public env: KalgudiEnvironmentConfig) {
    this.domain = this.env.coreDomain;
  }

  ngOnInit() {
  }

  /**
   * To hide and show selected members list
   */
  showSelectedMembersList() {
    this.showSelectedList = !this.showSelectedList;
  }
}
