import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import { AudioAttachmentDialogComponent } from './audio-attachment-dialog/audio-attachment-dialog.component';
import { AudioAttachmentService } from './audio-attachment.service';
import {
  AudioAttachmnetFormControlComponent,
} from './components/audio-attachmnet-form-control/audio-attachmnet-form-control.component';

@NgModule({
  declarations: [AudioAttachmnetFormControlComponent, AudioAttachmentDialogComponent],
  imports: [
    CommonModule,

    // Material modules
    MatButtonModule,
    MatIconModule,

    FlexLayoutModule,

    TranslateModule,
    FormsModule,
    ReactiveFormsModule,

    // Kalgudi modules
    KalgudiButtonModule,
    KalgudiPipesModule
  ],
  exports: [
    AudioAttachmnetFormControlComponent
  ],
  entryComponents: [
    AudioAttachmentDialogComponent
  ],
  providers: [
    AudioAttachmentService
  ]
})
export class KalgudiAudioAttachmentModule { }
