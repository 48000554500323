import { Injectable, Inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult, KalgudiUsersPickerDialogConfig, ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  AddProgramMembersDialogComponent,
} from '../components/add-program-members-dialog/add-program-members-dialog.component';
import {
  AddProgramMembersMobileDialogComponent,
} from '../components/add-program-members-mobile-dialog/add-program-members-mobile-dialog.component';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';

@Injectable()
export class KalgudiPageMembersDialogService {

  constructor(
    private dialogsService: KalgudiDialogsService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Shows page member picker dialog
   *
   * @param config Dialog
   */
  showKalgudiUsersPicker(
    details: KalgudiUsersPickerDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(AddProgramMembersDialogComponent, details, config);
  }

  /**
   * Shows page member picker dialog for mobil view
   */
  showMobileUsersPicker(
    details: KalgudiUsersPickerDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(AddProgramMembersMobileDialogComponent, details);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * 'v2/bms/market-place/loans'
   */
  private readonly CREATE_LOCATION_AND_CROP = `${this.env.restBaseUrlV2}/pages/members/add-location-crop`;

  /**
   * Calling end-point to create need assistance
   * @returns
   */
  createLocationAndCrop(payload:any, pageId: string, role: string): Observable<any> {
    let params: any = {
      pageId: pageId,
      role: role
    }
    return this.httpClient
      .post<ApiResponseCommon>(this.CREATE_LOCATION_AND_CROP, payload, {params})
      .pipe(
        // API response error handler
        map((res) => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),
        //No error, all good return true
        map((res) => res)
      );
  }
}
