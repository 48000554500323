import { Component, Injector, Input, OnInit } from '@angular/core';

import {
  KalgudiFarmerFarmingActivitySettings,
} from '../../../farmer-profile/classes/kalgudi-farmer-farming-activity-settings';

@Component({
  selector: 'kl-cooperative-society-crop-seasons',
  templateUrl: './cooperative-society-crop-seasons.component.html',
  styleUrls: ['./cooperative-society-crop-seasons.component.scss']
})
export class CooperativeSocietyCropSeasonsComponent extends KalgudiFarmerFarmingActivitySettings implements OnInit {

  @Input()
  cropId: string;

  @Input()
  pageId: string;

  @Input()
  profileKey: string;

  @Input()
  isAssisted: boolean;

  @Input()
  addSeason: boolean;

  showAddSeasonForm: boolean;

  constructor(protected injector: Injector) {

    super(injector);

    this.activeTabIndex = 0;
  }

  ngOnInit() {
    this.getSeasons();

    if(this.addSeason) {
      this.showAddSeasonForm = true;
    }
  }

  onDestroyed(): void {}

  toAddSeasonTab() {
    this.activeTabIndex = 1;
  }

  /**
   * On tab change
   */
  tabChanged(tab) {
    if (tab.index === 0) {
      this.isSeasonEdit = false;
    }
  }

  /**
   * Shows add season form
   */
  displayAddSeasonForm() {
    this.showAddSeasonForm = !this.showAddSeasonForm;
    this.selectedSeason = {};
  }

  /**
   * Opens add season tab with pre fill season details
   */
  editSeason(season: any) {
    this.showAddSeasonForm = true;
    this.selectedSeason = season;
    this.isSeasonEdit = true;
  }
}
