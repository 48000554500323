import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyDetails, PartialData } from '@kalgudi/types';

@Component({
  selector: 'kl-create-survey-dialog',
  templateUrl: './create-survey-dialog.component.html',
  styleUrls: ['./create-survey-dialog.component.scss']
})
export class CreateSurveyDialogComponent extends KalgudiDialog<CreateSurveyDialogComponent> implements OnInit {

  surveyData: KalgudiSurveyDetails;

  creationPayload: PartialData = {};

  constructor(
    protected dialogRef: MatDialogRef<CreateSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

    this.creationPayload = this.dialogData.data.creationPayload;
  }

  ngOnInit() {
  }

  /**
   * Calls after creating survey successfully
   */
  onSurveyCreation(survey: KalgudiSurveyDetails): void {
    this.surveyData = survey;
    this.ok();
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
        surveyDetails: this.surveyData
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }


  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
