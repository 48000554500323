<main id="traceability-activities">
  <mat-card class="mt-3 pl-4 ml-3 pb-4 activities position-relative" fxLayout="column" fxLayoutGap="16px">

    <div class="position-absolute series-no">
      <img src="assets/images/series-bg.png" width="38">
      <span class="position-absolute series font-weight-bold">{{index + 1}}</span>
    </div>

    <span class="text-secondary mb-1 time-info">{{ items?.shareTo?.CT | parseDate | date: 'dd MMM yyyy - hh:mm a' }}</span>

    <p class="desc mt-1 overflow-hidden text-content" klHideContent [lineHeight]="18" [visibleLine]="3">{{ items?.shareTo?.shareText }}</p>

    <div class="image image-gallery cursor-pointer position-relative w-100"
      (click)="openImageFullView(items?.shareTo?.lstOfAttachments) ; $event.stopPropagation($event);"
      *ngIf="items?.shareTo?.lstOfAttachments && items?.shareTo?.lstOfAttachments?.length">

      <div class="background-image" *ngIf="items?.shareTo?.lstOfAttachments[0]?.url"
        [style.background-image]="'url('+ (environment?.domain + items?.shareTo?.lstOfAttachments[0]?.url) +')'">
      </div>

      <!-- Number of images -->
      <div *ngIf="items?.lstOfAttachments?.length > 1"
        class="position-absolute img-list text-white"
        fxLayout="row"
        fxLayoutAlign="center center" fxLayoutGap="8px">
        <p class="mb-0 img-count font-weight-bold">{{ items?.shareTo?.lstOfAttachments?.length - 1 }}</p>
        <p class="mb-0 more" >More</p>
      </div>
      <!-- Number of images -->
    </div>

  </mat-card>
</main>
