import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiCreateSurveyService } from '../services/create-survey.service';

@Directive()
export abstract class KalgudiCreateCloneSurvey extends KalgudiDestroyable {

  @Input()
  surveyDetails: KalgudiSurveyDetails;

  @Output()
  closeDialog = new EventEmitter();

  readonly surveyExpirationMinDate = new Date();

  cloneSurveyForm: FormGroup;
  progress: boolean;

  // Dependencies
  private fb: FormBuilder;
  private kalgudiCreateSurveyService: KalgudiCreateSurveyService;
  private notifications: KalgudiNotification;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.notifications              = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.fb                         = this.injector.get(FormBuilder);
    this.kalgudiCreateSurveyService = this.injector.get(KalgudiCreateSurveyService);

    this.cloneSurveyForm = this.newCloneSurveyForm;
  }

  // --------------------------------------------------------
  // #region Setters and Getters methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  onDestroyed(): void {}

  /**
   * Calls api method to create clone of survey
   */
  cloneSurvey() {
    this.progress = true;

    const payload = this.preparePayload(this.cloneSurveyForm);

    this.kalgudiCreateSurveyService.createCloneOfSurvey(payload, this.surveyDetails.surveyId)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false )
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and Protected interfacing methods
  // --------------------------------------------------------

  /**
   * Constructs a new survey form
   */
  private get newCloneSurveyForm(): FormGroup {

    return this.fb.group({
      title: ['', Validators.required],
      expiryDate: ['', Validators.required],
      published: [''],
      addMembers: ['']
    });
  }

  /**
   * Prepare payload for clone survey form
   */
  private preparePayload(form: any) {
    const payload = {
      cloneTitle: form.get('title').value,
      expiryDate: form.get('expiryDate').value,
      isPublished: form.get('published').value ? form.get('published').value : false,
      addMembers: form.get('addMembers').value ? form.get('addMembers').value : false
    }

    return payload;
  }

  /**
   * Clone survey success handler
   */
  private addSuccessHandler(res: any) {
    this.closeDialog.emit();
  }

  /**
   * Clone survey error handler
   */
  private addErrorHandler(err: Error) {

    this.notifications.showMessage('Unable to clone survey, please try again later!');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
