import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  KalgudiAiPredictionsModule,
  KalgudiAttachUrlModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule,
  KalgudiTranslateModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiPageTargetMembersModule } from '@kalgudi/pages-shared';
import { KalgudiSocialModule } from '@kalgudi/social';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import { AiFiltersComponent } from './components/ai-filters/ai-filters.component';
import {
  AiPredictionsFiltersFormComponent,
} from './components/ai-predictions-filters-form/ai-predictions-filters-form.component';
import {
  AiPredictionsFiltersListComponent,
} from './components/ai-predictions-filters-list/ai-predictions-filters-list.component';
import {
  AiPredictionsInformationListComponent,
} from './components/ai-predictions-information-list/ai-predictions-information-list.component';
import { AiPredictionsListComponent } from './components/ai-predictions-list/ai-predictions-list.component';
import {
  AiPredictionsShareUpdateDialogComponent,
} from './components/ai-predictions-share-update-dialog/ai-predictions-share-update-dialog.component';
import {
  AiPredictionsShareUpdateMobileDialogComponent,
} from './components/ai-predictions-share-update-mobile-dialog/ai-predictions-share-update-mobile-dialog.component';
import {
  AiPredictionsShareUpdateComponent,
} from './components/ai-predictions-share-update/ai-predictions-share-update.component';
import { AiPredictionsTileComponent } from './components/ai-predictions-tile/ai-predictions-tile.component';
import { FpoListDialogComponent } from './components/fpo-list-dialog/fpo-list-dialog.component';
import { FpoListMobileDialogComponent } from './components/fpo-list-mobile-dialog/fpo-list-mobile-dialog.component';
import { FpoListsComponent } from './components/fpo-lists/fpo-lists.component';
import { AiPredictionsService } from './services/ai-predictions.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    AiPredictionsFiltersListComponent,
    AiPredictionsFiltersFormComponent,
    AiPredictionsTileComponent,
    AiPredictionsListComponent,
    AiPredictionsInformationListComponent,
    FpoListsComponent,
    AiPredictionsShareUpdateDialogComponent,
    AiPredictionsShareUpdateMobileDialogComponent,
    AiPredictionsShareUpdateComponent,
    AiFiltersComponent,
    FpoListDialogComponent,
    FpoListMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    // Form Module
    FormsModule,
    ReactiveFormsModule,

    // Material Module
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatTabsModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatRippleModule,
    ScrollingModule,

    TranslateModule,

    // Kalgudi modules
    KalgudiSlideToggleModule,
    KalgudiButtonModule,
    KalgudiTranslateModule,
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiCoreModule,
    KalgudiImageChipListModule,
    KalgudiSocialModule,
    MobileMenuBottomSheetModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiTimePickerModule,
    KalgudiPageTargetMembersModule,
    KalgudiStoreProductsSearchModule,

    KalgudiProgramTargetMembersModule,
    KalgudiAiPredictionsModule,

    KalgudiUsersModule,
  ],
  exports: [
    AiPredictionsFiltersListComponent,
    FpoListsComponent
  ],
  providers: [
    AiPredictionsService
  ],
  entryComponents: [
    AiPredictionsShareUpdateDialogComponent,
    AiPredictionsShareUpdateMobileDialogComponent,
    FpoListDialogComponent,
    FpoListMobileDialogComponent,
  ]
})
export class KalgudiPageAiPredictionsModule { }
