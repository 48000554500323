import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject, TaskTemplateDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';

@Directive()
export abstract class SelectTemplate extends KalgudiSearchStream<TaskTemplateDetails[]> {

  @Output()
  templateSelected = new EventEmitter<string>();

  @Input()
  taskType: string;

  @Input()
  taskId: string;

  templateType: string;

  projectDetails: KalgudiProject;

  private projectStateService: KalgudiProjectStateService;
  private projectService: KalgudiProjectService;


  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    this.pageLimit = 10;

    this.projectStateService = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.projectService      = this.injector.get<KalgudiProjectService>(KalgudiProjectService);

    this.projectStateService.projectDetails$
      .pipe(
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {

        this.projectDetails = res;

        this.initStream();

      });

  }

  onTemplateSelection(template): void {

    this.templateType = template;

    this.templateSelected.emit(template);

  }

  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.projectService.getTemplates(searchKeyword, this.projectDetails.type, offset, limit, this.taskType)
    .pipe(

      takeUntil(this.destroyed$),

      map(res => {

        return {
          count: res.count,
          items: res.results
        };
      }),

      map(res => {

        res.items.forEach(i => {
          i.svgIcon =  i.image;
          i.desc = i.description;
        });

        return res;
      })
      );

    }
}
