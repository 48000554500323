<main class="soil-moisture-details">

  <div>

    <section class="px-3 pt-3">

      <div fxLayout="row" fxLayoutGap="10px">
        <svg-icon [applyCss]="true" class="text-center"
          src="assets/svgs/soil-moist.svg" [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"></svg-icon>
        <p class="details mb-1">
          Volumetric Soil Moisture Estimated on
          <span>{{ estimatedTime | parseDate | date: 'dd MMM yyyy' }}</span>
        </p>
      </div>

      <div *ngIf="soilMoistureDetails?.waterNeeded">
        <p class="m-0 details">
          <span><b>{{soilMoistureDetails?.waterNeeded | number: '1.0-0'}}L </b></span>
          Water needed
        </p>
      </div>

    </section>
  </div>

  <section class="px-3" *ngIf="soilMoistureDetails.dynamicPOP">
    <kl-reccomendations-common [recommendations]="soilMoistureDetails?.dynamicPOP"></kl-reccomendations-common>
  </section>
  <div class="pt-2 px-3" fxLayout="row" fxLayoutGap="20px" fxLayout.xs="column">
    <div #satImgSoilMoisture class="position-relative" fxFlex="{{hideGauge? '100%' : 'calc(60% - 20px)'}}" fxFlex.xs="100%">
      <img [src]="soilMoistureImage" width="100%" height="281">

      <div class="position-absolute land-details px-2" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="soilMoistureDetails?.averageLevel || soilMoistureDetails?.concentrationQuality">
        <!-- <div *ngIf="25 <= soilMoistureDetails?.averageLevel && soilMoistureDetails?.averageLevel > 30">
          <mat-icon class="thumb-up">thumb_up</mat-icon>
        </div>
        <div *ngIf="25 >= soilMoistureDetails?.averageLevel && soilMoistureDetails?.averageLevel < 30">
          <mat-icon class="thumb-down">thumb_down</mat-icon>
        </div> -->
        <div class="land-size" fxLayout="column" fxLayoutAlign="center center">
          <p class="m-0">{{ soilMoistureDetails?.averageLevel * 100 | number }}</p>
          <!-- <p class="m-0">{{ soilMoistureDetails?.concentrationQuality || 'NA' }}</p> -->
        </div>
      </div>

      <svg class="boundary position-absolute" fill-opacity="0"  [attr.width]="satImgRef?.nativeElement?.offsetWidth" [attr.height]="satImgRef?.nativeElement?.offsetHeight">
        <polygon stroke="black" stroke-width="2" [attr.points]="boundaryPoints"></polygon>
      </svg>

    </div>

    <div fxFlex="40%" fxFlex.xs="100%" *ngIf="!hideGauge">
      <kl-soil-moisture-canvas-gauge
        [depthProfile]="soilMoistureDetails?.depthProfile"
        [avgSoilMoisture]="soilMoistureDetails?.averageSoilMoisture">
      </kl-soil-moisture-canvas-gauge>
    </div>
  </div>
</main>
