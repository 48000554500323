<form autocomplete="off" class="mt-3" [formGroup]="pageCloneForm">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Page title</mat-label>
    <input  matInput cdkFocusInitial placeholder="Page title" formControlName="pageTitle">
  </mat-form-field>

  <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-flat-button [progress]="progress" [label]="'Clone'" [disabled]="!pageTitle.value" (clicked)="clonePage()"></kl-flat-button>
  </div>
</form>
