import { Component, forwardRef, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';

import { GooglePlacesAutocomplete } from '../../classes/google-places-autocomplete';
import { ApiLoaderService } from '../../services/api-loader.service';
import { KalgudiGooglePlacesApiService } from '../../services/kalgudi-google-places-api.service';

const GOOGLE_PLACES_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GooglePlacesAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'kl-google-places-autocomplete',
  templateUrl: './google-places-autocomplete.component.html',
  styleUrls: ['./google-places-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ GOOGLE_PLACES_CONTROL_VALUE_ACCESSOR ]
})
export class GooglePlacesAutocompleteComponent extends GooglePlacesAutocomplete implements OnInit {

  constructor(
    protected fb: FormBuilder,
    protected apiLoader: ApiLoaderService,
    protected googleApiService: KalgudiGooglePlacesApiService,
  ) {

    super(fb, apiLoader, googleApiService);
  }

  ngOnInit() {
    this.init();
    this.autoCompleteForm.get('locationLong').valueChanges.subscribe(res => {
      if(!this.autoCompleteForm.get('locationLong').value) {
        this.onLocationChange.emit('empty');
      }
    })
  }


  protected onDestroyed(): void { }

}
