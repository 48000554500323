import { Component, Input, OnInit } from '@angular/core';
import { CoreNotificationsConfigService } from '@app/config';
import { floatTopAnimation, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { PartialData, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SchedulesUpdatesStreamService } from './scheduled-updates-stream.service';

@Component({
  selector: 'core-scheduled-updates-stream',
  templateUrl: './scheduled-updates-stream.component.html',
  styleUrls: ['./scheduled-updates-stream.component.scss'],
  animations: [floatTopAnimation]
})
export class ScheduledUpdatesStreamComponent extends KalgudiInboxStream<ShareUpdate> implements OnInit {

  @Input()
  overRideUpdatePost: (postDetails: ShareUpdate) => Observable<ShareUpdate>;

  @Input()
  filters: PartialData = {};

  readonly maxPageLimit = 20;

  constructor(
    protected notification: CoreNotificationsConfigService,
    protected util: KalgudiUtilityService,
    private normalizerService: SocialDataNormalizerService,
    private updatesStreamService: SchedulesUpdatesStreamService
  ) {

    super(notification, util);

    this.pageLimit = this.maxPageLimit;

  }

  ngOnInit() {
    this.initStream();

  }

  onDestroyed(): void {}

  streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.updatesStreamService.fetchUpdateStream(offset, limit, this.filters)
      .pipe(
        map(res => {
          res.items.forEach(q => {
            q = this.normalizerService.mapShareUpdateQaAuthorDetails(q);
          });

          return res;
        })
      );

  }

}
