import { Component, forwardRef, Input, OnChanges, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { KalgudiHourSelectableFormControl } from './kalgudi-home-selectable-form-control';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => HourSelectableFormControlComponent),
  multi: true,
};

@Component({
  selector: 'kl-hour-selectable-form-control',
  templateUrl: './hour-selectable-form-control.component.html',
  styleUrls: ['./hour-selectable-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class HourSelectableFormControlComponent extends KalgudiHourSelectableFormControl implements OnInit, OnChanges {

  @Input()
  selectedDate: Date;

  @Input()
  fieldAppearance: string = 'none';

  scheduledTimeList = [
    {label: '00:00 AM', value: 0}, {label: '01:00 AM', value: 1}, {label: '02:00 AM', value: 2},
    {label: '03:00 AM', value: 3}, {label: '04:00 AM', value: 4}, {label: '05:00 AM', value: 5},
    {label: '06:00 AM', value: 6}, {label: '07:00 AM', value: 7}, {label: '08:00 AM', value: 8},
    {label: '09:00 AM', value: 9}, {label: '10:00 AM', value: 10}, {label: '11:00 AM', value: 11},
    {label: '12:00 PM', value: 12}, {label: '01:00 PM', value: 13}, {label: '02:00 PM', value: 14},
    {label: '03:00 PM', value: 15}, {label: '04:00 PM', value: 16}, {label: '05:00 PM', value: 17},
    {label: '06:00 PM', value: 18}, {label: '07:00 PM', value: 19}, {label: '08:00 PM', value: 20},
    {label: '09:00 PM', value: 21}, {label: '10:00 PM', value: 22}, {label: '11:00 PM', value: 23},
  ];

  isTodayDateSelected: boolean;

  currentHour = new Date().getHours();

  constructor() {

    super();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.selectedDate) {

      const today = new Date();

      let date;
      try {
        date = new Date(this.selectedDate.toISOString());
      } catch (e) {
        date = new Date(this.selectedDate);
      }

      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        this.isTodayDateSelected = true;
      } else {
        this.isTodayDateSelected = false;
      }
    }

  }

  onDestroyed(): void {}
}
