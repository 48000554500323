import { Injector, Directive, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiFormValidators } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyAnalyticsResponse, KalgudiSurveyDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiSurveyService } from '../../../services/kalgudi-survey.service';
import { SurveyStateService } from '../../../services/survey-state.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent, KalgudiDialogsService } from '@kalgudi/common';

@Directive()
export abstract class KalgudiSendSurveyAnalytics extends KalgudiDestroyable {

  sendSurveyAnalyticsForm: FormGroup;
  progress: boolean;
  surveyId: string;

  @Input()
  surveyDetails: KalgudiSurveyDetails;

  private fb: FormBuilder;
  private notification: KalgudiNotification;
  private stateService: SurveyStateService;
  private kalgudiSurveyService: KalgudiSurveyService;
  private dialogsService: KalgudiDialogsService;

  constructor(protected injector: Injector) {

    super();

    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.fb                   = this.injector.get(FormBuilder);
    this.stateService         = this.injector.get(SurveyStateService);
    this.kalgudiSurveyService = this.injector.get(KalgudiSurveyService);
    this.dialogsService       = this.injector.get(KalgudiDialogsService);

    this.stateService.id$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        id => {
          this.surveyId = id;
        }
      );

    this.sendSurveyAnalyticsForm = this.fb.group({
      email: ['', [...KalgudiFormValidators.emailValidators]]
    });
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Call service method to send survey analytics
   */
  sendSurveyAnalytics() {

    if((this.surveyDetails?.metaData?.responseCount === this.surveyDetails?.metaData?.memberCount)) {
      this.progress = true;
      this.sendSurveyReport();
    } else {
      this.showConfirmDialog();
    }
  }

  /**
   * Send survey report to email
   */
  private sendSurveyReport(): void {
    const email = this.sendSurveyAnalyticsForm.value.email;

    this.kalgudiSurveyService.sendSurveyAnalytics(this.surveyId, email)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onSendSurveyAnalyticsSuccess(res),
        err => this.onSendSurveyAnalyticsError(err)
      );
  }


  private openConfirmDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
    ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(ConfirmDialogComponent, dialogConfig, matDialogConfig);

  }

  showConfirmDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm Download',
      message: 'All the participants have not completed the Survey, do you still want to download the report?',
      acceptButtonTitle: 'No',
      rejectButtonTitle: 'Yes',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openConfirmDialog(dialogDetails, dialogConfig).subscribe(r => {
      // Here false means user selected 'yes' for downloading the report
      if(r.accepted === false) {
        this.sendSurveyReport();
      }
    })
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful send survey analytics
   */
  protected onSendSurveyAnalyticsSuccess(res: KalgudiSurveyAnalyticsResponse): void {
    this.sendSurveyAnalyticsForm.reset();
    this.notification.showMessage('Survey analytics sent to your mail');
  }

  /**
   * Event handler for send survey analytics API errors.
   */
  protected onSendSurveyAnalyticsError(err: Error): void {
    this.notification.showMessage(err.message);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
