import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KalgudiDialogsModule } from '@kalgudi/common';
import { KalgudiQaModule } from '@kalgudi/qa';
import { TranslateModule } from '@ngx-translate/core';

import { MobileProgramQaFormComponent } from './components/mobile-program-qa-form/mobile-program-qa-form.component';
import { ProgramQaFormComponent } from './components/program-qa-form/program-qa-form.component';
import { KalgudiProgramQaApiService } from './services/kalgudi-program-qa-api.service';
import { KalgudiProgramQaService } from './services/kalgudi-program-qa.service';



@NgModule({
  declarations: [
    ProgramQaFormComponent,
    MobileProgramQaFormComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Kalgudi libraries
    KalgudiQaModule,
    KalgudiDialogsModule,

    TranslateModule
  ],
  exports: [
    ProgramQaFormComponent,
  ],
  providers: [
    KalgudiProgramQaApiService,
    KalgudiProgramQaService,
  ],
  entryComponents: [
    MobileProgramQaFormComponent
  ]
})
export class KalgudiProgramQaModule { }
