import { Inject, Injectable } from '@angular/core';
import { LazyHttpClient } from '@kalgudi/common/lazy-http';
import {
  ApiError,
  HttpStatusCode,
  KalgudiError,
  KalgudiUtilityService,
  LikeShareRequest,
  REST_API_ERROR_MESSAGES,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  KalgudiMessageDetails,
  KalgudiQAChatListApi,
  ShareActionType,
  ShareQaAnswerComment,
  ShareQaAnswerLike,
  ShareQaAnswerRequest,
  ShareQaFullDetails,
  ShareQaRequest,
  SimilarQuestion,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';



@Injectable()
export class KalgudiQaApiService {

  /**
   * `v2/social/questions`
   */
  private readonly API_QA_BASE = `${this.environment.restBaseUrlV2}/social/questions`;

  /**
   * `v2/social/questions/:questionId`
   */
  private readonly API_QA_VIEW = `${this.API_QA_BASE}/:questionId`;

  /**
   * `v2/social/questions/:questionId/replies`
   */
  private readonly API_QA_CHAT = `${this.API_QA_VIEW}/replies`;

  /**
   * `v2/social/questions/:questionId/replies/:messageId`
   */
  private readonly API_QA_REPLY = `${this.API_QA_CHAT}/:messageId`;

  /**
   * `v2/social/questions/:questionId/hide`
   */
  private readonly API_QA_HIDE = `${this.API_QA_VIEW}/hide`
  /**
   * `v2/social/questions/:questionId/answers`
   */
  private readonly API_ANSWER = `${this.API_QA_VIEW}/answers`;

  /**
   * `v2/social/questions/similarqa`
   */
  private readonly API_POPULAR_QA = `${this.environment.restBaseUrlV2}/social/questions/popularqa`;

  /**
   * `data/similar-qa/v1/:questionId_similarQA
   */
  private readonly API_SIMILAR_QA = `${this.environment.domain}/data/similar-qa/v1/:questionId_similarQA`;

  /**
   * `v2/social/questions/:questionId/answers/:answerId`
   */
  private readonly API_LIKE_COMMENT = `${this.API_ANSWER}/:answerId/:actionType`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: LazyHttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   *  Posts a Question
   *
   * @param req
   */
  postQuestion(req: ShareQaRequest): Observable<ShareQaFullDetails> {

    return this.httpClient
      .post<any>(this.API_QA_BASE, req)
      .pipe(

        // Check for qa API response errors
        map(res => this.postApiResponseHandler(res)),

        // Map API response to qa type
        map(r => this.util.toJson<ShareQaFullDetails>(r.data))
      );
  }

  /**
   *  Gets question object from the Api.
   */
  getQuestion<R extends ShareQaFullDetails>(questionId: string): Observable<R> {

    const url = this.API_QA_VIEW.replace(':questionId', questionId);

    return this.httpClient
      .get<any>(url)
      .pipe(

        // Check for qa API response errors
        map(res => this.questionApiResponseHandler(res)),

        // Map API response to qa type
        map(r => this.util.toJson<R[]>(r.data)[0])
      );
  }

  /**
   * Calls the delete post API.
   *
   * @param questionId question id
   */
  deletePost(questionId: string): Observable<boolean> {
    return this.httpClient
      .delete<ApiResponseCommon>(this.API_QA_VIEW.replace(':questionId', questionId))
      .pipe(
        // Handle any API errors
        map(res => this.deletePostResponseHandler(res)),

        // All good, emit true to the stream
        mapTo(true)
      );
  }

  /**
   * Calls the popular questions API.
   */
  getPopularQa(source: string): Observable<SimilarQuestion> {

    const params = {
      sourceFrom: source
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_POPULAR_QA, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data.popularqa),
      );
  }

  /**
   * Calls the similar questions API.
   */
  getSimilarQa(questionId: string): Observable<SimilarQuestion> {

    return this.httpClient.get<SimilarQuestion>(this.API_SIMILAR_QA.replace(':questionId', questionId))
      .pipe(
        // No errors, all good return true
        map(res => res),
      );
  }


  /**
   *  Post answer Api.
   */
  postAnswer(questionId: string, payload: ShareQaAnswerRequest): Observable<ShareQaFullDetails> {

    const url = this.API_ANSWER.replace(':questionId', questionId);

    return this.httpClient
      .post<ApiResponseCommon>(url, payload)
      .pipe(

        // Check for qa API response errors
        map(res => this.postApiResponseHandler(res)),

        // Map API response to qa type
        map(r => this.util.toJson<ShareQaFullDetails>(r.data))
      );
  }


  /**
   * like or comment a post
   * @param req
   */
  likeOrComment(
    questionId: string,
    answerId: string,
    payload: LikeShareRequest,
    actionType: ShareActionType
  ): Observable<ShareQaAnswerLike> {

    const url = this.API_LIKE_COMMENT
      .replace(':questionId', questionId)
      .replace(':answerId', answerId)
      .replace(':actionType', actionType);

    return this.httpClient
      .post<ApiResponseCommon>(url, payload)
      .pipe(
        map(res => this.likeCommentErrorHandler(res)),

        map(r => this.util.toJson<ShareQaAnswerLike>(r.data))
      );
  }

  /**
   *  Gets comments list from the Api.
   */
  getComments(questionId: string, answerId: string): Observable<ShareQaAnswerComment[]> {
    const url = this.API_LIKE_COMMENT
      .replace(':questionId', questionId)
      .replace(':answerId', answerId)
      .replace(':actionType', 'comments');

    return this.httpClient
      .get<ApiResponseCommon>(url)
      .pipe(
        map(res => this.getCommentErrorHandler(res)),

        map(r => this.util.toJson<ShareQaAnswerComment[]>(r.data))
      );
  }

  /**
   * Gets the all the QA replies
   */
  getQAChats(questionId: string): Observable<KalgudiQAChatListApi> {

    return this.httpClient
      .get<ApiResponseCommon>(this.API_QA_CHAT.replace(':questionId', questionId))
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(r => ({
          items: r.data.results,
          count: r.data.count,
          questionData: r.data.questionData,
          isAllowedToReply: r.data.isAllowedToReply
        }))
      );
  }

  /**
   * Calls an api to post a reply
   */
  postReply(questionId: string, payload: any): Observable<KalgudiMessageDetails> {


    return this.httpClient
      .post<ApiResponseCommon>(this.API_QA_CHAT.replace(':questionId', questionId), payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(r => r.data)
      );
  }

  deleteReply(questionId: string, messageId: string): Observable<boolean> {

    const url = this.API_QA_REPLY.replace(':questionId', questionId).replace(':messageId', messageId);

    return this.httpClient
      .delete<ApiResponseCommon>(url)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        mapTo(true)
      );
  }

  /**
   * Calls an api to hide a message
   */
  hideMessage(questionId: string): Observable<boolean> {

    const url = this.API_QA_HIDE.replace(':questionId', questionId);

    return this.httpClient
      .delete<ApiResponseCommon>(url)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        mapTo(true)
      );
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * It verifies for successful response
   */
  private postApiResponseHandler(res: ApiResponseCommon): ApiResponseCommon {

    // QA error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.CREATED) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful QA
    return res;
  }


  /**
   * It verifies for successful response
   */
  private questionApiResponseHandler(res: ApiResponseCommon): ApiResponseCommon {

    // QA error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.OK) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful QA
    return res;
  }

  /**
   * It verifies for successful response
   */
  private deletePostResponseHandler(res: ApiResponseCommon): ApiResponseCommon {

    // Delete error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.OK) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successfully deleted
    return res;
  }

  /**
   * It verifies for successful response
   */
  private likeCommentErrorHandler(res: ApiResponseCommon): ApiResponseCommon {

    // Like error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.CREATED) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successfully liked
    return res;
  }


  /**
   * It verifies for successful response
   */
  private getCommentErrorHandler(res: ApiResponseCommon): ApiResponseCommon {

    // QA error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.OK) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful QA
    return res;
  }


  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

}

