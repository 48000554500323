import { Component, Injector, OnInit } from '@angular/core';

import { ChangePassword } from '../../classes/change-password';

@Component({
  selector: 'kl-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends ChangePassword implements OnInit {

  hidePassword: boolean;
  hideNewPassword: boolean;
  hideConfirmPassword: boolean;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.hidePassword        = true;
    this.hideNewPassword     = true;
    this.hideConfirmPassword = true;
  }

  onDestroyed(): void {}
}
