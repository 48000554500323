<div class="farmers-task-tile-item-wrapper" *ngIf="farmersTasks?.length">
  <mat-card class="farmers-task-tile px-3 pt-2 pb-0">
    <!-- Task tile header -->
    <div fxLayout class="pb-2" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="start center">
        <div>
          <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '20', 'height.px': '20'}"
            [applyCss]="true" class="mr-3 projects-icon"></svg-icon>
        </div>
        <div fxLayoutAlign="start center">
          <p class="m-0" translate>Farmer Tasks</p>
        </div>
      </div>
    </div>
    <!--/ Task tile header -->

    <div class="border-bottom"></div>

    <!-- Farmers current task tile list -->
    <div fxLayout="row wrap" fxLayoutGap="10px grid">
      <div fxFlex="calc(50% - 10px)" fxFlex.xs="100%" class="farmer-task" *ngFor="let task of farmersTasks | slice: 0:4;let i = index;">
        <kl-tasks-tile
          enableActions= false
          (openFullview)="openTaskFullview(task)"
          [taskDetails]="task"
          [showStats]="true">
        </kl-tasks-tile>
      </div>
    </div>
    <!--/ Farmers current task tile list -->

    <!-- Task full view button -->
    <div fxLayout fxLayoutAlign="end end" class="py-2">
      <button mat-button class="view-all mr-n3"
        (click)="openTaskListPage()" fxLayout fxLayoutAlign="center center">
        <div fxLayout fxLayoutAlign="center center">
          <div><span translate>View tasks</span></div>
          <div class="view-icon"><mat-icon>keyboard_arrow_right</mat-icon></div>
        </div>
      </button>
    </div>
    <!--/ Task full view button -->
  </mat-card>
</div>
