<div class="project-creation" *ngIf="(creationState$ | async) as creationState">
  <!-- stepper -->
  <div class="mx-auto stepper-data" *ngIf="(creationStateData$ | async) as creationStateData">

    <kl-stepper>
      <mat-horizontal-stepper labelPosition="bottom" [linear]="true"
        ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" #stepper>


        <!-- Project template selection -->
        <mat-step>

          <ng-template matStepLabel><span translate>Project Category</span></ng-template>
          <div class="p-3 select-card" fxLayout="column">

            <div fxLayout="column" *ngIf="!projectId">
              <div fxLayout fxLayoutAlign="center center">
                <mat-tab-group class="project-creation-tabs" [(selectedIndex)]="activeTabIndex"  (selectedTabChange)="onTabChange($event)">
                  <mat-tab [label]="TABS?.CREATE_NEW?.title | translate">

                  </mat-tab>
                  <mat-tab [label]="TABS?.SELECT_EXISTING?.title | translate">
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
            <div *ngIf="activeTabIndex !== 1" class="mt-3">
              <kl-select-card layout="column" [(selectedValue)]="projectType" [options]="categoryTiles" [disableSelection]="projectDetails?.id ? true : false">
              </kl-select-card>
            </div>
            <div *ngIf="activeTabIndex === 1" class="mt-3">
              <kl-existing-projects (selectedExistingProject)="selectedExistingProjects($event)"></kl-existing-projects>
            </div>

          </div>

          <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="end center">

            <!-- Next page button -->
            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()"
                [disabled]="activeTabIndex === 1 ? (!selectedExistingProject || !selectedExistingProject.title || !selectedExistingProject.type) : !projectType">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span translate>Next</span>
                  <div fxLayoutAlign="center end">
                    <mat-icon class="mr-n2">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
            </div>
            <!-- / Next page button -->
          </div>

        </mat-step>


        <!-- Common project form -->
        <mat-step [stepControl]="commonProjectForm">

          <ng-template matStepLabel><span translate>Project Details</span></ng-template>

          <!-- Common form -->
          <kl-common-creation-form [(projectForm)]="commonProjectForm"></kl-common-creation-form>
          <!-- / Common form -->

          <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Back Button -->
            <div>
              <button mat-stroked-button (click)="previousState()"><span translate>Back</span></button>
            </div>
            <!-- / Back Button -->

            <!-- Next page button -->
            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()"
                [disabled]="commonProjectForm.invalid">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span translate>Next</span>
                  <div fxLayoutAlign="center end">
                    <mat-icon class="mr-n2">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
            </div>
            <!-- / Next page button -->
          </div>

        </mat-step>


        <!-- Final step, detailed project form -->
        <mat-step [stepControl]="detailedProjectForm">

          <ng-template matStepLabel><span translate>Project Creation</span></ng-template>

          <!-- Crop calender page -->
          <div *ngIf="creationStateData.CHOOSE_PROJECT_CATEGORY.data === availablePageTypes.CROP_CALENDAR">
            <kl-crop-calender [selectedExistingProject]="selectedExistingProject" [(projectForm)]="detailedProjectForm" [editing]="projectDetails?.id ? true : false"></kl-crop-calender>
          </div>
          <!-- / Crop calender page -->

          <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Back Button -->
            <div>
              <button mat-stroked-button (click)="previousState()"><span translate>Back</span></button>
            </div>
            <!-- / Back Button -->

            <!-- Next page button -->
            <div>
              <kl-flat-button
                [label]="projectDetails?.id ? 'Update' : 'Create' | translate"
                [progress]="progress"
                [disabled]="detailedProjectForm.invalid"
                (clicked)="nextStep()">
              </kl-flat-button>
            </div>
            <!-- / Next page button -->
          </div>
        </mat-step>

      </mat-horizontal-stepper>
    </kl-stepper>
  </div>
</div>
