<div id="kalgudi-share-update-form" class="p-0">

  <form autocomplete="off" [formGroup]="shareForm">
    <div class="p-3 qa-share-form">
      <kl-common-qa-form
        [shareForm]="shareForm"
        [shareFormText]="shareFormText"
        [attachments]="shareFormAttachments?.value"
        [textFieldPlaceHolder]="'Enter your question here and for better answers attach images of pest, condition or product.' | translate"
        [progress]="progress"
        submitBtnText="Ask"
        [audioAttachment]="audioAttachment"
        (shareQaPost)="createPost()"
        [rowsCount]="rowsCount"
        [compactView]="isMobileDevice ? true : false"
        (removeAttachment)="removeImage($event)">
      </kl-common-qa-form>

    </div>
  </form>

</div>
