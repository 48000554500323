import { Inject, Directive, Input, Injector } from "@angular/core";
import {
  KalgudiAppService,
  KalgudiUserSearchResults,
  KalgudiUsersService,
  KalgudiUtilityService,
} from "@kalgudi/core";
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from "@kalgudi/core/config";
import { IdValueMap, StringAnyMap } from "@kalgudi/types";
import { Observable, of } from "rxjs";
import { map, startWith, finalize, filter, takeUntil, tap, debounceTime, distinctUntilChanged, switchMap, catchError } from "rxjs/operators";

import { KalgudiUsersPickerBase } from "../../kalgudi-users/classes/kalgudi-users-picker-base";
import { KalgudiUsersSearchService } from "../../kalgudi-users/services/kalgudi-users-search.service";
import { FormArray, FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { KalgudiUsersPickerService } from "../services/kalgudi-users-picker.service";
import { MatOptionSelectionChange } from "@angular/material/core";
import { KalgudiUserPickerStateService } from "../services/kalgudi-user-picker-state.service";

/**
 * Kalgudi all users search picker. It searches for users in the kalgudi database.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiUsersPickerBase
 * @see KalgudiSearchStream
 * @see KalgudiStream
 */
@Directive()
export abstract class KalgudiUserPicker extends KalgudiUsersPickerBase {

  @Input()
  pageId: string

  @Input()
  showExtraFields: boolean = false;

  location = new FormControl("");
  crop = new FormControl("");
  locations: any[];
  crops: any[];
  filteredOptions$: Observable<any[]>;
  cropOptions: Observable<string[]>;
  profileKey: string;
  targetCount: any;
  locationAndCropForm: FormGroup;
  isFpoApp : boolean = false;
  groupId: string;

  private env: KalgudiEnvironmentConfig;
  locationIds: string;
  cropIds: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected usersSearch: KalgudiUsersSearchService,
    protected usersService: KalgudiUsersService,
    protected userPickerService: KalgudiUsersPickerService,
    protected kalgudiApp: KalgudiAppService,
    protected fb: FormBuilder,
    protected injector: Injector,
    protected kalgudiUserPickerStateService:KalgudiUserPickerStateService,
  ) {
    // Wake up (initialize) parent
    super(notification, util);
    this.locationAndCropForm = this.newLocationAndCropForm;
    this.env             = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    // this.getLocationsAndCrops();

    if (this.env.appId === "FPO_APP") {
      this.isFpoApp = true;
    }
    setTimeout(() => {
      this.groupId = this.pageId
    }, 500);
  }

  ngOnInit(): void { }

  private get newLocationAndCropForm() {
    return this.fb.group({
      location: [[]],
      crop: [[]]
    })
  }

  /**
   * Get cropsArray field from location.
   */
  get locationArray(): FormArray {
    return this.locationAndCropForm.get('location') as FormArray;
  }

  /**
   * Get cropsArray field from location.
   */
  get locationArrayValue(): any[] {
    return this.locationArray.value;
  }

  /**
   * Get cropsArray field from location.
   */
  get cropArrayValue(): any[] {
    return this.cropArray.value;
  }

  /**
   * Get cropsArray field from location.
   */
  get cropArray(): FormArray {
    return this.locationAndCropForm.get('crop') as FormArray;
  }

  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserSearchResults> {
    return this.usersSearch
      .searchKalgudiUsers(searchKeyword, offset, limit, extraParams)
      .pipe(
        map((res) => {
          // Transform complete users details to user basic details object
          res.items = res.items.map((user) =>
            this.usersService.getBasicProfile(user)
          ) as any;

          return res;
        })
      );
  }

  /**
   * Storing location & crops data
   */
  getLocationCropData(profileKey: string, keyword: string, type: string) {
    this.userPickerService
      .getLocation(profileKey, keyword, type)
      .pipe(finalize(() => this.notification.hideSpinner()))
      .subscribe(
        (res) => {
          if (type === 'LOCATION') {
            this.locations = res.data.location;
            this.getFilterLocations();
          } else if (type === 'CROP') {
            this.crops = res.data.crop;
            this.getFilteredCrops();
          }
        },
        (err) => {
          this.addErrorHandler(err);
        }
      );
  }

  /**
   * Get API for all locations and crops
   */
  getLocationsAndCrops() {
    this.getLocationCropData(this.kalgudiApp.profileLocal.profileKey, '', '');
  }

  /**
   * Value changes of location and crops
   */
  subscribeToValueChanges(control, type) {
    control.valueChanges.subscribe(val => {
      if (val && val.length > 2) {
        this.getLocationCropData(this.kalgudiApp.profileLocal.profileKey, val, type);
      }
    });
  }

  /**
   * Value changes of location
   */
  locationValueChanges() {
    this.subscribeToValueChanges(this.location, 'LOCATION');
  }

  /**
   * Value changes of crops
   */
  cropValueChanges() {
    this.subscribeToValueChanges(this.crop, 'CROP');
  }

  /**
   * get all filtered locations
   */
  getFilterLocations() {
    this.filteredOptions$ = this.location.valueChanges.pipe(
      // Default filter
      startWith(''),

      // Trim extra spaces
      map((value) =>
        value ? (typeof value === "string" ? value : value.placeName) : ""
      ),

      // Don't search until the search keyword changes
      distinctUntilChanged(),

      // Gap between each keystroke
      debounceTime(100),

      // Filter items based on the search keyword
      map(searchKeyword => searchKeyword ? this._locationFilter(searchKeyword) : this.locations),

      // tap(r => console.log(r)),
      );
  }

  /**
   * get location filter
   */
  private _locationFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.locations.filter((option) =>
      option.placeName && option.placeName.toLowerCase().includes(filterValue)
    );
  }

  /**
   * get all filtered crops
   */
  getFilteredCrops() {
    this.cropOptions = this.crop.valueChanges.pipe(
      // Default filter
      startWith(''),

      // Trim extra spaces
      map((value) =>
        value ? (typeof value === "string" ? value : value.productName) : ""
      ),

      // Don't search until the search keyword changes
      distinctUntilChanged(),

      // Gap between each keystroke
      debounceTime(400),

      // Filter items based on the search keyword
      map(searchKeyword => searchKeyword ? this._cropFilter(searchKeyword) : this.crops),
      // tap(r => console.log(r)),
      );
  }

  /**
   * get crops filter
   */
  private _cropFilter(value: any): string[] {
    const filterValue = value.toLowerCase();
    const res = this.crops.filter((option) =>
    option.productName && option.productName.toLowerCase().includes(filterValue)
    );

    return res;
  }

  /**
   * Error notification for get location or crop
   */
  private addErrorHandler(err) {
    this.notification.showMessage(err.error.message);
  }

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayWithFn(value: any): any {
    return value && value.productName ? value.productName : value;
  }

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayLocationWithFn(value: any): any {
    return value && value.placeName ? value.placeName : value;
  }

  /**
   * Refresh target count
   */
  refreshTargetCount() {
    this.locationIds = "";
    this.cropIds = "";

    this.locationIds = this.locationArray?.value.map(res=> res.placeId).toString();
    this.cropIds = this.cropArray?.value.map(res=> res.productId).toString();
    this.profileKey = this.kalgudiApp.profileLocal.profileKey;
    this.userPickerService
      .refreshTargetCount(
        this.profileKey,
        this.locationIds,
        this.cropIds
      )
      .pipe(finalize(() => this.notification.hideSpinner()))
      .subscribe(
        (res) => {
          this.targetCount = res.data.count;
        },
        (err) => {
          this.addErrorHandlerCount(err);
        }
      );
  }

  /**
   * Error notification for location or crop count
   */
  private addErrorHandlerCount(err) {
    this.notification.showMessage('Please select location or crop to fetch count');
  }

  /**
   * Removing items from location
   * @param index
   */
  locationRemoveItem(index) {
    this.locationArrayValue.splice(index, 1);
    this.kalgudiUserPickerStateService.updateIsLocationNames(this.locationArrayValue);
    this.targetCount = '';
  }

  /**
   * Pushes the item to the form group array. It also updates the
   * form control to which the component is bind-ed.
   */
  selectLocation(event): void {
    const item: any = event.option.value;

    // Don't re-select an existing item
    if (this.isLocationSelected(item)) {
      this.location.reset();
      return;
    }

    let obj = {};
    if (item) {
      obj = {
        placeId: item.placeId,
        placeName: item.placeName
      }

      // Push item to local array form
      this.locationArray.value.push(obj);
    }

    this.location.reset();
    this.getFilterLocations();
    this.kalgudiUserPickerStateService.updateIsLocationNames(this.locationArray.value);
    let payload = this.preparePayload(this.locationAndCropForm.value);
    this.kalgudiUserPickerStateService.updateCropAndLocationIds(payload);
    this.locationValueChanges();
  }

  /**
   * Checks, whether an item is selected or not.
   *
   * @returns `true` if an item is already selected otherwise `false`.
   */
  private isLocationSelected(item: any): boolean {
    return this.locationArray.value.findIndex(res => item.placeId === res.placeId) >= 0;
  }

  /**
   * Pushes the item to the form group array. It also updates the
   * form control to which the component is bind-ed.
   */
  selectCropItem(event): void {
    const item: any = event.option.value;

    // Don't re-select an existing item
    if (this.isCropSelected(item)) {
      this.crop.reset();
      return;
    }

    let obj = {};
    if (item) {
    obj = {
    productId: item.productId,
    productName: item.productName
    }
    }

        // Push item to local array form
    this.cropArray.value.push(obj);

    this.crop.reset();
    this.getFilteredCrops();
    this.kalgudiUserPickerStateService.updateIsCropNames(this.cropArray.value);
    let payload = this.preparePayload(this.locationAndCropForm.value);
    this.kalgudiUserPickerStateService.updateCropAndLocationIds(payload);
    this.cropValueChanges();
  }
  /**
   * Checks, whether an item is selected or not.
   *
   * @returns `true` if an item is already selected otherwise `false`.
   */
  private isCropSelected(item: any): boolean {
    return this.cropArray.value.findIndex(res => item.productId === res.productId) >= 0;
      }

  /**
   * Removing items from location
   * @param index
   */
  cropRemoveItem(index) {
    this.cropArray.value.splice(index, 1);
    this.kalgudiUserPickerStateService.updateIsLocationNames(this.cropArrayValue);
    this.targetCount = '';
  }

  /**
   * Preparing payload
   * @param formValue
   * @returns
   */
  preparePayload(formValue) {
    this.profileKey = this.kalgudiApp.profileLocal.profileKey;

    let crops = [];
    formValue.crop.forEach(crop => {
      crops.push(crop.productId);
    });

    let locations = [];
    formValue.location.forEach(location => {
      locations.push(location.placeId);
    });

    let payload = [
      {
        location: locations,
        crop: crops
      }
    ]

    return payload;
  }

  /**
   * Error notification for location or crop count
   */
  private errorHandler(err) {
    this.notification.showMessage(err.error.message || err.info || err.error || 'Unable to add crop and location, Please try again later!');
  }
}
