import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiTasksListApiService {

  /**
   * `v2/projects/tasks`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/projects/tasks`;

  /**
   * `v2/projects/tasks/search`
   */
  private readonly API_SEARCH_TASKS = `${this.API_STREAM}/search`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Fetch task list
   * @param projectId
   * @param offset
   * @param limit
   */
  fetchStream(
    offset: number,
    limit: number,
    projectId = '',
    profileKey = '',
    filters: PartialData = {}
  ): Observable<KalgudiStreamData> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      projectId,
      profileKey,
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi project details object type
        map(r => this.mapPagesListResponse(r))

      );
  }

  /**
   * Makes an API call to search list of all tasks with the specified
   * search keyword.
   * @param searchKeyword
   * @param projectId
   * @param offset
   * @param limit
   */
  searchTasks(
    searchKeyword: string,
    offset: number,
    limit: number,
    projectId = '',
    profileKey= '',
    filters: PartialData = {}
  ): Observable<KalgudiStreamData> {

    const params = {
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      projectId,
      profileKey,
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_SEARCH_TASKS, { params })
      .pipe(

        // Check for API errors
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi task search result type
        map(r => this.mapPagesListResponse(r))
      );
  }



  /**
   * Maps projects list API response to kalgudi project details type.
   */
  private mapPagesListResponse(res: ApiResponseCommon): KalgudiStreamData {

    return {
      items: res.data.results,
      count: res.data.count
    };
  }
}
