import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';

import { GeoLocationMarkerComponent } from './components/geo-location-marker-common/geo-location-marker-common.component';
import {
  GeoLocationMarkerDialogComponent,
} from './components/geo-location-marker-dialog/geo-location-marker-dialog.component';
import {
  GeoLocationMarkerMobileDialogComponent,
} from './components/geo-location-marker-mobile-dialog/geo-location-marker-mobile-dialog.component';
import { GpsGeoMarkerDialogComponent } from './components/gps-geo-marker-dialog/gps-geo-marker-dialog.component';
import {
  GpsGeoMarkerMobileDialogComponent,
} from './components/gps-geo-marker-mobile-dialog/gps-geo-marker-mobile-dialog.component';
import { GpsGeoMarkerComponent } from './components/gps-geo-marker/gps-geo-marker.component';
import { KalgudiGeoLocationMarkerService } from './kalgudi-geo-location-marker.service';

@NgModule({
  declarations: [
    GeoLocationMarkerComponent,
    GeoLocationMarkerDialogComponent,
    GeoLocationMarkerMobileDialogComponent,
    GpsGeoMarkerComponent,
    GpsGeoMarkerMobileDialogComponent,
    GpsGeoMarkerDialogComponent,
  ],
  imports: [
    CommonModule,

    // Material
    MatIconModule,
    MatButtonModule,

    // Flex
    FlexLayoutModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    KalgudiGoogleGeoLocationModule,
    KalgudiGooglePlacesModule,
  ],
  exports: [
    GeoLocationMarkerComponent,
    GpsGeoMarkerComponent
  ],
  entryComponents: [
    GeoLocationMarkerDialogComponent,
    GeoLocationMarkerMobileDialogComponent,
    GpsGeoMarkerDialogComponent,
    GpsGeoMarkerMobileDialogComponent,
  ],
  providers: [KalgudiGeoLocationMarkerService],
})
export class KalgudiGeoLocationMarkerModule {}
