import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoLocationService, KalgudiUploadService } from '@kalgudi/core';

import { KalgudiUploadImage } from '../../classes/kalgudi-upload-image';
import { KalgudiImagePickerService } from '../../services/kalgudi-image-picker.service';
import { Router } from '@angular/router';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UploadImageCompactViewComponent),
  multi: true,
};

@Component({
  selector: 'kl-upload-image-compact-view',
  templateUrl: './upload-image-compact-view.component.html',
  styleUrls: ['./upload-image-compact-view.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class UploadImageCompactViewComponent extends KalgudiUploadImage implements OnInit {

  @Input()
  showAddOnLast: boolean;

  @Input()
  showCamera: boolean;

  @Input()
  showDisabledFrames: boolean;

  constructor(
    protected uploadService: KalgudiUploadService,
    protected imagePickerService: KalgudiImagePickerService,
    protected geoLocationService: GeoLocationService,
    protected router: Router,
  ) {
    super(
      uploadService, imagePickerService, geoLocationService, router
    );
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
