<div fxLayout fxLayoutGap="15px" class="py-2 comments-form px-3">

  <!-- Profile pic -->
  <!-- <div class="image rounded-circle" *ngIf="!kuberPage">
    <img class="w-100 h-100 rounded-circle avatar"
      [src]="(profile$ | async)?.profilePicUrl"
      [klErrorImages]="[
      (profile$ | async)?.profilePicUrl | prefixDomain,
      domain + defaultProfilePic
      ]"
    />
  </div> -->
  <!--/ Profile pic -->

  <!-- Comment form field -->
  <!-- <form autocomplete="off" [formGroup]="commentForm" fxFlex (submit)="comment()" *ngIf="!kuberPage">
    <div fxLayout="column" fxLayoutGap="10px">
      <div class="w-100">
        <mat-form-field appearance="outline" class="w-100">
          <textarea matInput rows="1" [placeholder]="'Write your comment' | translate" formControlName="text" class="w-100"></textarea>
        </mat-form-field>
      </div>

      <div class="" fxLayoutAlign="end start">
        <div class="comment-btn">
          <kl-flat-button
            label="Comment"
            [disabled]="!commentText"
            [progress]="progress"
            (clicked)="comment()">
          </kl-flat-button>
        </div>
      </div>

    </div>
  </form> -->
  <!-- /Comment form field -->

  <!-- Comment form field -->
  <form autocomplete="off" [formGroup]="commentForm" fxFlex (submit)="comment()">
    <div class="w-100" fxLayout="row" fxLayoutGap="4px">
      <div fxFlex="calc(85% - 4px)">
        <mat-form-field appearance="outline" class="w-100">
          <textarea matInput rows="1" [placeholder]="'Write your comment' | translate" formControlName="text" class="w-100"></textarea>
        </mat-form-field>
      </div>

      <button mat-button class="mt-1 share-btn" fxFlex="calc(15% - 4px)" fxLayoutAlign="center center" [ngClass]="{'disabled-button': !commentText }" (clicked)="comment()" [disabled]="!commentText">
        <svg-icon class="svg-icon" src="assets/svgs/comment-send.svg" [svgStyle]="{ 'width.px': 18, 'height.px': 18}" fxLayoutAlign="start center" [applyCss]="true">
        </svg-icon>
      </button>

    </div>
  </form>
  <!-- /Comment form field -->

</div>
