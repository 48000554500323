import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  Action,
  ActivityType,
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiProjectTask,
  PartialData,
  ProjectStatus,
} from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class KalgudiTaskStatus extends KalgudiDestroyable {

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  filters: PartialData = {};

  @Input()
  isBulk: boolean;

  @Input()
  selectedMembersList: any[] = [];

  @Output()
  statusUpdated = new EventEmitter<KalgudiProjectTask>();

  taskDetails: KalgudiProjectTask;

  projectId: string;

  projectRoles = KALGUDI_PAGE_RELATION_MAP;

  activityType = ActivityType;

  action = Action;

  taskStatus = [
    {
      id: ProjectStatus.COMPLETED,
      name: 'Completed',
      matIcon: 'check_circle_outline'
    },
    {
      id: ProjectStatus.IN_PROGRESS,
      name: 'In progress',
      matIcon: 'slow_motion_video'
    },
    {
      id: ProjectStatus.NOT_STARTED,
      name: 'Not started',
      matIcon: 'trip_origin'
    },
  ];

  // Todays date for max date of submitted date picker
  readonly todaysDate = new Date();

  progress: boolean;

  statusForm = new FormGroup ({
    state: new FormControl(''),
    stateChangedOn: new FormControl('')
  });

  private stateService: KalgudiProjectStateService;
  private notifications: KalgudiNotification;
  private taskService: KalgudiTasksService;
  private util: KalgudiUtilityService;
  private kalgudiApp: KalgudiAppService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.stateService             = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.notifications            = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.taskService              = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.util                     = this.injector.get(KalgudiUtilityService);
    this.kalgudiApp               = this.injector.get(KalgudiAppService);

    this.stateService.projectDetails$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.projectId = res.id
      );

    this.stateService.taskDetails$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.taskDetails = res
      );

  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Initializes the form
   */
  init() {

    if (this.submissionDetails) {
      this.statusForm.patchValue(this.submissionDetails);
    }
  }

  /**
   * Call method to update the task status
   */
  updateStatus() {

    this.progress = true;

    if (this.taskDetails.userRole === this.projectRoles.ADMIN || this.taskDetails.userRole === this.projectRoles.CONTRIBUTOR) {
      const payload = this.prepareUpdateStatePayload(this.statusForm.value);

      this.projectId = this.submissionDetails.projectId;
      const profileKey =  this.submissionDetails.createdBy.profileKey;
      if(this.selectedMembersList.length) {
        payload.profileKeys = this.selectedMembersList;
      }

      if(this.taskDetails.state) {
        payload.previousState = this.taskDetails.state;
      }

      this.taskService.createActivity(
        this.projectId, this.taskDetails.taskId, ActivityType.TASK_SUBMISSION, payload, '', profileKey, Action.STATE_UPDATE, this.isBulk )
          .pipe(
            finalize(() => this.progress = false)
          )
          .subscribe(
            res => {
              this.stateService.reloadTasks();
              this.stateUpdateHandler(res);
            },
            err => this.stateUpdateErrHandler(err)
          );

    } else {
      this.projectId = this.submissionDetails.projectId;
      const profileKey =  this.submissionDetails.createdBy.profileKey;
      const payload =  this.preparePayload(this.statusForm.value);

      if(this.selectedMembersList.length) {
        payload.profileKeys = this.selectedMembersList;
      }

      if(this.taskDetails.state) {
        payload.previousState = this.taskDetails.state;
      }

      this.taskService.createActivity(
        this.projectId,
        this.taskDetails.taskId,
        this.activityType.TASK_SUBMISSION,
        payload, '', profileKey,
        this.action.STATE_UPDATE,
        this.isBulk )
        .subscribe(
          res => {
            this.stateUpdateHandler(res);
          },
          err => this.stateUpdateErrHandler(err)
        );

    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   *  Prepare payload if member
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  /**
   * Prepares payload for admin
   */
  protected prepareUpdateStatePayload(formValue): any {

    // If user himself is not filling the form then assign the assisted to field
    // to the payload.
    // It ensure that the user is filling the form on behalf of other.
    const assistedTo  = this.submissionDetails && this.isAssisting(this.submissionDetails.createdBy.profileKey)
      ? this.util.clone(this.submissionDetails.createdBy)
      : null;

    return this.util.clone({
      state: formValue.state,
      stateChangedOn: formValue.stateChangedOn,
      assistedTo,
      ...this.filters,
    });
  }

  /**
   * Event handler for successful state updation
   */
  protected stateUpdateHandler(res) {
    this.notifications.showMessage('Task state updated');
    this.statusUpdated.emit(res);
    this.stateService.updateTaskDetails(this.taskDetails);
    this.stateService.reloadTasks();
    this.statusForm.reset();
  }

  /**
   * Event handler for failed to update state
   */
  protected stateUpdateErrHandler(err: Error) {
    this.notifications.showMessage('Failed to update task state');
  }

  /**
   * Returns `true` if the task is submitted by the admin of the task.
   */
  private isAssisting(taskAssignedToProfileKey: string): boolean {
    return taskAssignedToProfileKey !== this.kalgudiApp.profileLocal.profileKey;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
