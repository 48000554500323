<section id="publish-sms">
  <mat-card class="mb-2" fxLayout="column">
    <form autocomplete="off" [formGroup]="shareForm" fxLayout="column">
      <!-- Sms template section -->
      <section class="sms-template-wrapper" [formGroup]="varInputsForm">
        <div>
          <div  fxLayout fxLayoutAlign="space-between center">
            <div *ngIf="isSamunnatiFpo" class="px-3">
              <span class="sms-type font-weight-bold" translate>SMS Type : </span>
            </div>
            <mat-form-field appearance="outline" class="template-type"
              [ngClass]="{'w-50': isSamunnatiFpo, 'w-100': !isSamunnatiFpo}">
              <mat-select (selectionChange)="selectedTemplate($event)" formControlName="smsTemplateType"
                [placeholder]="'Select template type' | translate" [disabled]="false">
                <mat-option *ngFor="let type of smsTemplateList" [value]="type">{{ isSamunnatiFpo ? type?.template_name :
                  type?.smsTemplateTitle }}</mat-option>

              </mat-select>
            </mat-form-field>
          </div>

          <p class="mb-2 mt-n2 text-danger" *ngIf="!progress && (!smsTemplateList || !smsTemplateList?.length)"
            translate>
            No SMS templates found
          </p>
        </div>

        <div class="p-3 sms-template border mb-3" *ngIf="templateType?.value">
          <div *ngIf="!isSamunnatiFpo" class="template-format mb-3">
            <div *ngFor="let template of smsTemplateList">
              <p class="m-0" *ngIf="
                  templateType?.value?.smsTemplateId === template?.smsTemplateId
                ">
                {{ template?.message }}
              </p>
            </div>
          </div>

          <div *ngIf="varCount > 0 && !isSamunnatiFpo">
            <p class="m-0">Select var inputs</p>
            <div formArrayName="items" *ngFor="
                let item of varInputsForm.get('items')['controls'];
                let i = index
              ">
              <div [formGroupName]="i" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="row wrap" fxLayoutGap.xs="0"
                fxLayoutAlign="start center">
                <div class="input-type-field" fxFlex="calc(30% - 15px)" fxFlex.xs="100%">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Input type</mat-label>
                    <mat-select formControlName="type">
                      <mat-option *ngFor="let type of varInputs" [value]="type">{{ type?.value }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="calc(70% - 15px)" fxFlex.xs="100%" fxLayout="row" fxLayoutGap="15px"
                  fxLayoutAlign="start center">
                  <div fxLayout="column" class="w-100">
                    <div class="w-100" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
                      <div fxFlex="{{
                          varInputsForm.get('items')?.value[i]?.type?.id ===
                          'URL'
                            ? 'calc(100% - 110px)'
                            : '100%'
                        }}">
                        <ng-container *ngIf="
                            varInputsForm.get('items')?.value[i]?.type
                              ?.id as type
                          ">
                          <mat-form-field appearance="outline" class="w-100" *ngIf="type !== 'DATE'">
                            <input matInput [maxLength]="
                                varInputsForm.get('items')?.value[i]?.type
                                  ?.id === 'URL'
                                  ? 300
                                  : 30
                              " formControlName="value" placeholder="Var {{ i + 1 }}" />
                            <mat-error translate>Max 30 characters</mat-error>
                          </mat-form-field>

                          <mat-form-field appearance="outline" class="w-100" *ngIf="type === 'DATE'">
                            <input matInput [matDatepicker]="datePicker" formControlName="value"
                              placeholder="Var {{ i + 1 }}" disabled />
                            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                            <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                          </mat-form-field>
                        </ng-container>

                        <div fxLayoutAlign="end end" class="mt-n2" *ngIf="
                            varInputsForm.get('items')?.value[i]?.type?.id ===
                            'TEXT'
                          ">
                          <p class="m-0 char-count" translate>
                            Remaining characters:
                            <span *ngIf="
                                varInputsForm.get('items')?.value[i]?.value
                                  ?.length <= 30
                              ">{{
                              30 -
                              varInputsForm.get("items")?.value[i]?.value
                              ?.length
                              }}</span>
                          </p>
                        </div>
                      </div>

                      <div fxFlex="95px" *ngIf="
                          varInputsForm.get('items')?.value[i]?.type?.id ===
                          'URL'
                        " class="short-url-button">
                        <button mat-flat-button color="accent" class="url-button" [disabled]="disableGenerateSmsBtn"
                          (click)="
                            toShortUrl(
                              varInputsForm.get('items')?.value[i]?.value,
                              i
                            )
                          ">
                          Short URL
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="
                  varInputsForm.get('items')?.value[i]?.type?.id === 'URL' &&
                  varInputsForm.get('items')?.value[i]?.shortUrl
                " fxLayoutAlign="end end">
                <p class="short-url">
                  Short url:
                  <span class="url">{{
                    varInputsForm.get("items")?.value[i]?.shortUrl
                    }}</span>
                </p>
              </div>
            </div>

            <div class="cursor-pointer mt-2" fxLayoutAlign="center center">
              <button mat-flat-button color="primary" class="generate-sms"
                [disabled]="varInputsForm.invalid || disableGenerateSmsBtn" (click)="showSampleMessage()" translate>
                Generate SMS
              </button>
            </div>

            <!-- Sample message -->
            <div class="mt-2 sms-message" *ngIf="sampleMessage">
              <p class="m-0">{{ sampleMessage }}</p>
            </div>
            <!--/ Sample message -->
          </div>

          <div *ngIf="varCount > 0 && isSamunnatiFpo">

            <div fxLayout  fxLayoutAlign="space-between center" *ngIf="templateType.value.template_name.includes('Input') || templateType.value.template_name.includes('Procurement')">
              <div class="sms-template-name px-3">
                <span class="px-3 mt-3 font-weight-bold" translate>Product Name : </span>
              </div>

              <div fxFlex="252px" fxFlex.lt-md="160px" class="ml-1">
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput (keypress)="onKeyPress($event)" formControlName="productName" maxLength="30" [placeholder]="'Enter Product Name' | translate" />
                </mat-form-field>
              </div>
            </div>

            <div fxLayout fxLayoutAlign="space-between center">
              <div class="sms-template-name px-3">
                <span class="px-3 mt-3 font-weight-bold" translate>FPO Name : </span>
              </div>
              <div fxFlex="252px" fxFlex.lt-md="160px">
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput (keypress)="onKeyPress($event)" formControlName="fpoName" maxLength="30" [placeholder]="'Enter FPO Name' | translate" />
                </mat-form-field>
              </div>
            </div>

            <div fxLayout fxLayoutAlign="space-between center"
              *ngIf="templateType.value.template_name.includes('BOD') || templateType.value.template_name.includes('Annual')">
              <div class="sms-template-name mt-3 px-3">
                <span class="px-3 mt-3 font-weight-bold" translate>Date : </span>
              </div>
              <div fxFlex="252px" fxFlex.lt-md="160px" class="ml-3">
                <mat-form-field appearance="outline" class="w-100">
                  <input matInput [matDatepicker]="datePicker" formControlName="fpoDate" [placeholder]="'Select Date' | translate"
                    [min]="todayDate" (focus)="datePicker.open()" />
                  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
                  <mat-datepicker #datePicker disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
            </div>

          </div>
          <div *ngIf="isSamunnatiFpo" class="mt-3">
            <div *ngFor="let template of smsTemplateList">

              <p class="m-0" *ngIf="templateType?.value?.smsTemplateId === template?.smsTemplateId && templateType.value.template_name.includes('BOD')">
                "Greetings! The BOD meeting of your FPO  <span class="font-weight-bold"> {{ fpoName?.value ? fpoName?.value : '_ _ _ _ _' }}</span> will be held on <span class="font-weight-bold">{{fpoDate?.value ? (fpoDate?.value | date: 'dd-MMM-YYYY') : '_ _ _ _ _' }}</span>.Please make sure to mark
                this date and attend the same without fail. -Team Samunnati"
              </p>

              <p class="m-0" *ngIf="templateType?.value?.smsTemplateId === template?.smsTemplateId && templateType.value.template_name.includes('Annual')">
                "Greetings! The AGM of your FPO <span class="font-weight-bold"> {{fpoName?.value ? fpoName?.value : '_ _ _ _ _' }}</span> for this year will be held on <span class="font-weight-bold">{{fpoDate?.value ? (fpoDate?.value | date: 'dd-MMM-YYYY') : '_ _ _ _ _' }}</span>. Please make sure to mark this date and attend the same without fail.
                This message is Powered by Samunnati"
              </p>

              <p class="m-0" *ngIf="templateType?.value?.smsTemplateId === template?.smsTemplateId && templateType.value.template_name.includes('Input')">
                "Greetings! The input product<span class="font-weight-bold"> {{productName?.value ? productName?.value : '_ _ _ _ _' }}</span> for this season is now available at your FPO <span class="font-weight-bold"> {{fpoName?.value ? fpoName?.value : '_ _ _ _ _' }}</span>.
                  Please come and collect the same before we run out of stock. This message is Powered by Samunnati"
              </p>

              <p class="m-0" *ngIf="templateType?.value?.smsTemplateId === template?.smsTemplateId && templateType.value.template_name.includes('Procurement')">
                "Greetings! Your FPO <span class="font-weight-bold">{{fpoName?.value ? fpoName?.value : '_ _ _ _ _' }}</span> has started procuring <span class="font-weight-bold">{{productName?.value ? productName?.value : '_ _ _ _ _' }}</span> for this harvest season. Please call your FPO and inform them how much quantities you are expecting to sell. This message is Powered by Samunnati"
              </p>

            </div>
          </div>
        </div>
      </section>
      <!--/ Sms template section -->

      <!-- Message input filed -->
      <!-- <div class="text-area-wrapper">
        <mat-form-field appearance="outline" class="sms-form w-100">
          <textarea matInput [placeholder]="'Enter your SMS here to publish' | translate" rows="1" [formControl]="message" (keyup)="textCounter(message?.value)"></textarea>
        </mat-form-field>
        <span class="char-percent" *ngIf= "message?.value && !isSmsTextLimitExceded">{{countPercentage}}% <span translate>characters remaining</span></span>
        <p class="credit-count" *ngIf= "message?.value && !isSmsTextLimitExceded"><span translate>Required</span> {{deductedCredit}} <span translate>credit per member</span> </p>
        <p class="excess-text" *ngIf= "message?.value && isSmsTextLimitExceded"><span translate> Characters excedded by</span> {{countPercentage}}% .<span translate >please remove some text!</span> </p>
      </div> -->
      <!-- / Message input filed -->

      <!-- Select tag and filter -->
      <kl-page-activity-filters fxFlex="100%" [pageId]="pageId" [shareForm]="shareForm"
        (shareWith)="shareWithChanged($event)" streamType="SMS">
      </kl-page-activity-filters>

      <!--/ Select tag and filter -->

      <!-- Gender -->
      <div class="mt-2" fxLayout fxLayoutAlign="start center" [formGroup]="shareFormFilter"
        *ngIf="(env?.appId === 'SAM_FARMER' || env?.appId === 'SAM_FPO') && (shareWithValue !== 'SPECIFIC_KALGUDI_USER')">
        <div class="mt-n1">
          <span class="mr-2 gender-text" translate>Gender: </span>
        </div>

        <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
          <mat-radio-button value="" checked class="mr-4">{{ "All" | translate}}</mat-radio-button>
          <mat-radio-button value="MALE" class="mr-4">{{ "Male" |translate}}</mat-radio-button>
          <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--/ Gender -->

      <!-- Send SMS to alternate mobile -->
      <div fxLayoutAlign="start center" class="mt-3 mb-1">
        <div class="mr-4 mb-2" translate>Send SMS to alternate mobile</div>
        <kl-slide-toggle falseLabel="Off" trueLabel="On" [checked]="isSMSToAltNumber"
          (toggleChanged)="onSmsSwitchToggle($event)">
        </kl-slide-toggle>
      </div>

      <div fxLayoutAlign="space-between center" fxLayout="row wrap">
        <!-- schedule time -->
        <div fxLayout fxLayoutGap="8px" fxLayoutGap.xs="8px" fxFlex="80%" class="pr-4">
          <span class="mt-3" fxFlex="57px" translate>Schedule:</span>

          <mat-form-field color="primary" class="schedule-font" floatLabel="never" fxFlex="50%">
            <mat-label translate>Choose date</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="scheduledDate" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>

          <div fxFlex="calc(50% - 81px)">
            <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value"
              [placeholder]="'Time' | translate" formControlName="scheduledTime"></kl-hour-selectable-form-control>
          </div>
        </div>
        <!-- schedule time -->

        <!-- Send button -->
        <div class="send-button" fxFlex="20%">
          <kl-flat-button [type]="'button'" [label]="'Send'" [progress]="progress" [disabled]="
              !templateType.value ||
              shareForm?.invalid ||
              isSmsTextLimitExceded
            " (clicked)="sendSms()">
          </kl-flat-button>
        </div>
        <!-- / Send button -->
      </div>
    </form>
  </mat-card>
</section>
