import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AiPredictionsService {

  /**
   * `v2/pages/:pageId/ai-predictions`
   */
  private readonly API_AI_PREDICTIONS = `${this.env.restBaseUrlV2}/pages/:pageId/ai-predictions`;

  /**
   * `v2/pages/:pageId/ai-predictions/filters`
   */
  private readonly API_AI_PREDICTIONS_FILTERS = `${this.env.restBaseUrlV2}/pages/:pageId/filters`;

  /**
   * `v2/projects/ai-predictions`
   */
  private readonly API_FILTERS = `${this.env.restBaseUrlV2}/projects/ai-predictions`


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Get AI prediction list
   */
  getAIPredictions(pageId: string, filters: PartialData = {}): Observable<any> {

    const params = {...filters }

    const url = this.API_AI_PREDICTIONS.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Get AI prediction filters
   */
  getAIPredictionsFilters(pageId: string, filters: PartialData = {}): Observable<any> {

    const params = {...filters }

    const url = this.API_AI_PREDICTIONS_FILTERS.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Get AI prediction filters
   */
  filterAiPredictions(payload: any, filters: PartialData = {}): Observable<any> {

    const params = {...filters }

    const url = this.API_FILTERS;

    return this.httpClient.post<ApiResponseCommon>(url, payload, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res.data),
      );
  }


  /**
   * Get latest AI predictions list
   */
  getLatestAIPredictions(pageId: string): Observable<any> {

    const url = this.API_AI_PREDICTIONS.replace(':pageId', pageId);

    return this.httpClient.post<ApiResponseCommon>(url, {pageId: pageId})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res.data),
      );
  }
}
