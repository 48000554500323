<div class="qa-chats-page container w-100 mt-2 p-0" fxLayout="row">

  <!-- Questions list -->
  <mat-card fxFlex="30%" fxHide.xs fxLayout fxLayoutAlign="center center">

    <div *ngIf="disableNoContent">
      <kl-no-content
        matIcon="'message'"
        text="No questions found">
      </kl-no-content>
    </div>

  </mat-card>
  <!--/ Questions list -->

  <!-- Chats -->
  <mat-card fxFlex="70%" fxFlex.xs="100%" class="w-100 p-0">
    <div id="group-chat" class="chat">
      <div class="chat-list position-relative" *ngIf="(stream$ | async) as stream">
        <div>
          <kl-chat-list
            [latestMessage]="latestMessage"
            [authorDetails]="stream[0]"
            [isAllowedToReply]="isAllowedToReply"
            [showBox]="showBox"
            [sender]="sender"
            [showAuthor]="true"
            [messages]="stream"
            (replyMessage)="onReplyMessage($event)"
            (deleteMessage)="deleteReply($event)"
            (onHideMessage)="hideMessage($event)">
          </kl-chat-list>
        </div>


        <div *ngIf="paginator$ | async as paginator">
          <div class="no-chat" *ngIf="!stream || stream?.length <= 0" fxLayout fxLayoutAlign="center center">
            <div>
              <kl-no-content
                matIcon="'message'"
                text="Be the first to start conversation!"
                [progress]="paginator?.loading">
              </kl-no-content>
            </div>
          </div>
        </div>

        <div class="chat-footer" *ngIf="isAllowedToReply">
          <kl-chat-footer [replyMessage]="replyMessage" [latestMessage]="latestMessage" [messageProgress]="messageProgress" (showReplyForm)="showReply($event)" (send)="sendReply($event)"></kl-chat-footer>
        </div>
      </div>


    </div>

  </mat-card>
  <!-- / Chats -->

</div>
