import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { KalgudiPipesModule } from '@kalgudi/core';

import { AddCreditsComponent } from './components/add-credits/add-credits.component';
import { CreditActivityComponent } from './components/credit-activity/credit-activity.component';
import { CreditDetailsComponent } from './components/credit-details/credit-details.component';
import { CreditSettingsComponent } from './components/credit-settings/credit-settings.component';
import { CreditsHeaderComponent } from './components/credits-header/credits-header.component';
import { TransferCreditsComponent } from './components/transfer-credits/transfer-credits.component';
import { CreditApiService } from './services/credit-api.service';
import { CreditService } from './services/credit.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';




@NgModule({
  declarations: [
    CreditDetailsComponent,
    CreditActivityComponent,
    CreditSettingsComponent,
    CreditsHeaderComponent,
    AddCreditsComponent,
    TransferCreditsComponent
  ],
  imports: [
    CommonModule,

    // Kalgudi modules
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiSlideToggleModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,

    // flex module
    FlexLayoutModule,

    // Material modules
    MatTabsModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,

    // Forms modules
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CreditDetailsComponent,
    CreditActivityComponent,
    CreditSettingsComponent,
    CreditsHeaderComponent
  ],
  providers: [
    CreditApiService,
    CreditService
  ]
})
export class KalgudiCreditsManagementModule { }
