import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { IAgGridCellRenderer } from '../../model/index';

@Component({
  selector: 'store-admin-ag-grid-yes-no-renderer',
  template: `
  <span class="ag-grid-yes-no-renderer {{cell?.row | lowercase}}">{{cell?.row}}</span>
  `,
  styles: [`
    .ag-grid-yes-no-renderer.yes {
      color: green;
    }

    .ag-grid-yes-no-renderer.no {
      color: red;
    }
  `],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridYesNoRendererComponent implements OnInit, IAgGridCellRenderer {

  public static componentId = 'AgGridYesNoRendererComponent';

  private params: any;
  public cell: any;

  ngOnInit(): void {}

  agInit(params: any): void {
    this.params = params;
    this.cell = {
      row: params.value,
      col: params.colDef.headerName,
      rowIndex: params.rowIndex,
    };
  }

  refresh(): boolean {
    return false;
  }

  formatString(val: any): string {
    return val;
  }
}
