import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../config';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTasksListService } from '../../project-fullview/services/kalgudi-tasks-list.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTaskFullViewDialogService } from '../services/kalgudi-task-full-view-dialog.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class KalgudiTasksGrid  extends KalgudiSearchStream<KalgudiProjectTask> {

  @Input()
  projectId: string;

  @Input()
  loggedInUserTasks: boolean = false;

  @Input()
  profileKey: string;

  protected projectService: KalgudiProjectService;
  protected stateService: KalgudiProjectStateService;
  protected tasksActionService: KalgudiTaskActionsService;

  private tasksListService: KalgudiTasksListService;
  private taskFullViewDialogService: KalgudiTaskFullViewDialogService;
  private taskService: KalgudiTasksService;
  private appRouting: KalgudiProjectRouteConfig;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialog: MatDialog,
    protected injector: Injector
  ) {

    super(notification, util);
    this.tasksListService          = this.injector.get<KalgudiTasksListService>(KalgudiTasksListService);
    this.stateService              = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.taskFullViewDialogService = this.injector.get<KalgudiTaskFullViewDialogService>(KalgudiTaskFullViewDialogService);
    this.tasksActionService        = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);
    this.projectService            = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.taskService               = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.appRouting                = this.injector.get<KalgudiProjectRouteConfig>(KL_ROUTE_CONFIG);

    this.stateService.reloadTasks$
      .pipe(
        takeUntil(this.destroyed$),

        // skip(1)
      )
      .subscribe(
        (res) => this.resetStream()
      );
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullviewDialog(title: string, taskId: string, taskDetails: KalgudiProjectTask) {

    this.appRouting.toTask( { projectId: taskDetails.projectId, taskId });

  }

  /**
   * Opens task creation dialog
   */
  openTaskCreationDialog(taskId = '') {
    this.tasksActionService.openTaskCreationDialog(this.projectId, taskId, this.filters)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (res) => {
          this.notification.showMessage('Task created successfully');
          this.stateService.reloadTasks();
          this.updateProjectDetails(this.projectId);
        }
      );
  }

  openEditTaskDialog(taskId: string, projectId: string) {

    this.updateProjectDetails(projectId);

    this.taskService.getTaskDetails(taskId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
        switchMap(_ => this.tasksActionService.openTaskCreationDialog(projectId, taskId, this.filters))
      )
      .subscribe(
        (res) => {

          this.notification.showMessage('Task updated successfully');
          this.stateService.reloadTasks();
          this.updateProjectDetails(projectId);
        }
      );
  }

  /**
   * Open task stats dialog
   */
  openTaskStatsDialog(statType: string, taskId: string, taskTitle: string) {

    this.tasksActionService.showAddTaskStatsDialog(taskId, statType, taskTitle)
    .pipe(
      // Subscribe till `$destroyed` is not emitted
      takeUntil(this.destroyed$),
    )
    .subscribe(
      res => {
        // Write code here
      }
    );
  }

  /**
   * Deletes the task
   */
  deleteTask(taskId: string) {

    this.tasksActionService.deleteTask(taskId, this.filters)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.taskDeleteHandler(res),

        err => this.taskDeleteErrHandler(err)
      );
  }


  /**
   * Open add members dialog
   */
  showAddTaskMembersDialog(taskId: string, projectId) {
    this.tasksActionService.showAddTaskMembersDialog(taskId, projectId)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.stateService.reloadTasks();
        }
      );
  }

  onDestroyed(): void {
  }

  /**
   * Extra filters to get the tasks, use it as extra params
   */
  protected get filters(): PartialData {

    return {
      sortBy: 'date'
    };
  }

  /**
   * Event handler for successful task deletion
   */
  protected taskDeleteHandler(res) {
    this.stateService.reloadTasks();
  }

  /**
   * Event handler for failed to delete task
   */
  protected taskDeleteErrHandler(err: Error) {
    this.notification.showMessage('Failed to delete a task');
  }


  /**
   * Gets the list of tasks
   */
  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (this.loggedInUserTasks) {
      this.projectId = '';
    }

    if (searchKeyword) {

      return this.tasksListService.searchTasks(searchKeyword, offset, limit, this.projectId, this.profileKey, this.filters);
    } else {

      return this.tasksListService.getTasks(offset, limit, this.projectId, this.profileKey, this.filters);
    }

  }


  /**
   * Updates the project details with latest data
   */
  protected updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }
}

