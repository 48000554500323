import { Injector, Input, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { Attachment, KalgudiProject, KalgudiUserBasicDetails, PartialData, ProjectType } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';
import { filter, finalize, take, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../config';
import { ProjectCreationStateDataMap, ProjectCreationStates } from '../../../models';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { CreateProjectStateService } from '../services/create-project-state.service';
import { KalgudiProjectCreationService } from '../services/kalgudi-project-creation.service';

@Directive()
export abstract class KalgudiProjectCreation extends KalgudiDestroyable {

  @Input()
  projectId: string;

  @Input()
  filters: PartialData;

  availablePageTypes = ProjectType;

  /**
   * Share form for social
   */
  projectForm: FormGroup;
  commonProjectForm: FormGroup;
  detailedProjectForm: FormGroup;

  progress = false;

  projectType: ProjectType;

  projectDetails: KalgudiProject;
  selectedExistingProject: any;
  isSeasonDetails: boolean;

  /**
   * Contains stream of all page creation stages data
   */
  creationStateData$: Observable<ProjectCreationStateDataMap>;

  /**
   * Contains current page creation state
   */
  creationState$: Observable<ProjectCreationStates>;


  private notifications: KalgudiNotification;
  private imagePickerService: KalgudiImagePickerService;
  private util: KalgudiUtilityService;
  private kalgudiApp: KalgudiAppService;
  private projectCreationService: KalgudiProjectCreationService;
  private projectCreationState: CreateProjectStateService;
  private projectService: KalgudiProjectService;
  protected appRouting: KalgudiProjectRouteConfig;
  protected fb: FormBuilder;
  public env: KalgudiEnvironmentConfig;


  constructor(
    protected injector: Injector,
  ) {

    super();

    this.notifications          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.imagePickerService     = this.injector.get<KalgudiImagePickerService>(KalgudiImagePickerService);
    this.util                   = this.injector.get<KalgudiUtilityService>(KalgudiUtilityService);
    this.kalgudiApp             = this.injector.get<KalgudiAppService>(KalgudiAppService);
    this.projectCreationService = this.injector.get<KalgudiProjectCreationService>(KalgudiProjectCreationService);
    this.projectCreationState   = this.injector.get<CreateProjectStateService>(CreateProjectStateService);
    this.projectService         = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.appRouting             = this.injector.get<KalgudiProjectRouteConfig>(KL_ROUTE_CONFIG);
    this.fb                     = this.injector.get(FormBuilder);
    this.env                    = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    this.creationStateData$ = this.projectCreationState.creationStateData$;

    this.creationState$ = this.projectCreationState.creationState$;

    /**
     * Tap `createProject()` if the state changes to the create project.
     * This action should happen automatically.
     */
    this.projectCreationState.creationState$
      .pipe(

        // Filter only `CREATE_PROJECT` events
        filter(state => state === 'CREATE_PROJECT'),

        takeUntil(this.destroyed$),

        tap(_ => this.saveProject())
      ).subscribe();

    this.commonProjectForm = new FormGroup({});
    this.detailedProjectForm = new FormGroup({});
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, Kalgudi logged in user profile details
   */
  get basicUserDetails$(): Observable<KalgudiUserBasicDetails> {
    return this.kalgudiApp.profile$
      .pipe(

        // Take only first record
        take(1),
      );
  }


  /**
   * Returns current active state
   */
  private get currentState(): ProjectCreationStates {
    return this.projectCreationState.currentState;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------

  initProjectForms(projectId) {

    this.projectService.getProject(projectId)
    .subscribe(
      res => {

        this.projectDetails = res;
        this.projectType = res.type as ProjectType;
      }
    );

  }

  saveProject() {
    if (this.projectDetails && this.projectDetails.id) {
      this.updateProject();
    } else if(this.selectedExistingProject && this.selectedExistingProject.type) {
      this.createProject('EXISTING_PROJECT');
    } else {
      this.createProject('NEW_PROJECT');
    }
  }

  /**
   * Mapping payload for selected existing project
   * @param projectDetails
   */
  selectedExistingProjects(projectDetails: any) {

    const payload = {
      type: projectDetails.type ? projectDetails.type : '',
      title: projectDetails.title ? projectDetails.title : '',
      description: projectDetails.description ? projectDetails.description : '',
      attachments: projectDetails.attachments ? projectDetails.attachments : [],
      crop: projectDetails.crop ? projectDetails.crop : {},
      variety: projectDetails.variety ? projectDetails.variety : {},
      year: projectDetails.year ? projectDetails.year : '',
      season: projectDetails.season ? projectDetails.season : '',
      startDate: projectDetails.startDate ? projectDetails.startDate : '',
      endDate: projectDetails.endDate ? projectDetails.endDate : '',
      seasonDetails: projectDetails.seasonDetails ? projectDetails.seasonDetails : {}
    }

    this.selectedExistingProject = payload;

    this.filters.projectId = projectDetails.id;
    this.filters.isExistingProject = true;

  }


  /**
   * Creates a new project. Calls the API to create a new project
   */
  createProject(projectType: string): void {

    if(projectType === 'NEW_PROJECT') {
      delete this.filters.projectId;
      delete this.filters.isExistingProject;
    }

    // Get share payload, to be implemented by its child
    const payload = this.getCreationPayload(this.projectCreationState.creationStateData);


    this.progress = true;

    // Call api to share post
    this.projectCreationService.createProject(payload, this.filters)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Turn of spinner either on success or failure
        finalize(() => {
          // this.notifications.hideSpinner();
          this.progress = false;
        })
      )
      .subscribe(
        res => {
          const projectId = res.id;
          this.projectCreationHandler(payload, res);
          // this.appRouting.toProject( { projectId }  , {type: 'GRID'});
        },
        err => this.onProjectCreationError(err)
      );
  }


  /**
   * Calls the API to update the project
   */
  updateProject(): void {


    // Get share payload, to be implemented by its child
    const payload = this.getCreationPayload(this.projectCreationState.creationStateData);

    this.progress = true;

    // Call api to share post
    this.projectCreationService.updateProject(payload, this.projectDetails.id, this.filters)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Turn of spinner either on success or failure
        finalize(() => {
          // this.notifications.hideSpinner();
          this.progress = false;
        })
      )
      .subscribe(
        res => this.projectCreationHandler(payload, res),
        err => this.onProjectCreationError(err)
      );
  }


  /**
   * Moves the current project creation state to the next state.
   */
  nextStep() {

    let data = {};

    if (this.currentState === 'CHOOSE_PROJECT_CATEGORY') {

      data = this.projectType ? this.projectType : this.selectedExistingProject.type;
      if (this.projectId) {

        this.commonProjectForm.patchValue(this.projectDetails);
      }

      if (!this.projectType && this.selectedExistingProject && this.selectedExistingProject.title !== "") {
        this.commonProjectForm.patchValue(this.selectedExistingProject);
      }

    } else if (this.currentState === 'FILL_COMMON_PROJECT_DETAILS') {

      data = this.commonProjectForm.value;

      if (this.projectId) {
        this.detailedProjectForm.patchValue(this.projectDetails);
      }

      if (this.selectedExistingProject && this.selectedExistingProject.title !== "") {
        this.detailedProjectForm.patchValue(this.selectedExistingProject);
      }

      // this.detailedProjectForm.get('crop').disable();
      // this.detailedProjectForm.get('variety').disable();

    } else if (this.currentState === 'FILL_SPECIFIC_PROJECT_DETAILS') {
      data = this.detailedProjectForm.value;
    } else if (this.currentState === 'CREATE_PROJECT') {
      return;
    }

    this.projectCreationState.nextState(data);
  }

  /**
   * Moves the current project creation stage to the previous state.
   */
  previousState() {
    this.projectCreationState.previousState();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * New form group for the attachments
   */
  private get attachForm(): FormGroup {
    return this.fb.group({
      context: [''],
      msgType: [''],
      url: [''],
    });
  }
  /**
   * Event handler called after the project created successfully.
   */
  protected abstract onProjectCreated(payload: KalgudiProject, response: KalgudiProject): void;
  protected abstract onProjectError(err: Error): void;

  /**
   * Construct and gets, the project creation payload.
   */
  protected getCreationPayload(formValue: any): KalgudiProject {

    this.isSeasonDetails = formValue.FILL_SPECIFIC_PROJECT_DETAILS.data.seasonDetails.seasonTitle !== '' ? true : false;

    let payload: any  = {
      ...this.projectDetails,
      type: formValue.CHOOSE_PROJECT_CATEGORY.data,
      isSeasonDetails: this.isSeasonDetails,
      ...formValue.FILL_COMMON_PROJECT_DETAILS.data,
      ...formValue.FILL_SPECIFIC_PROJECT_DETAILS.data
    };

    payload = this.util.clone(payload);

    return payload;
  }

  /**
   * Injects the attached images to the project creation form. It also invalidates
   * the share form for any errors.
   *
   * @param attachments Images list to attach
   *
   * @returns Updated share form.
   */
  protected injectImagesToForm(attachments: Attachment[]): FormGroup {
    return null;
  }

  /**
   * Calls API to create/save social item.
   */
  protected createProjectApi(payload: any): Observable<any> {
    return EMPTY;
  }

  /**
   * Event handler for project creation API errors.
   */
  protected onProjectCreationError(err: Error): void {
    const errMsg: string = this.env.appId === 'SAM_FPO' ? 'Only page-admin is allowed to update project' : err.message;
    this.notifications.showMessage(errMsg);
    this.progress = false;
    // this.projectForm.enable();

    this.onProjectError(err);
  }

  /**
   * Resets kalgudi projects creation form.
   */
  protected resetForm(): void {
    this.projectForm.reset();
  }

  /**
   * Event handler for successful project creation Api response.
   */
  private projectCreationHandler(payload: KalgudiProject, res: KalgudiProject): void {

    // Callback hooks for its children
    this.onProjectCreated(payload, res);

    // this.nextStep();

  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
