import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, KalgudiUserAwardDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiProfileAwardsApiService {

  /**
   * `v2/profiles/:profileKey/biz-awards`
   */
  private readonly API_AWARDS_BASE = `${this.env.restBaseUrlV2}/profiles/:profileKey/biz-awards`;

  /**
   * `v2/profiles/:profileKey/biz-awards`
   */
  private readonly API_AWARDS_SAVE = this.API_AWARDS_BASE;

  /**
   * `v2/profiles/:profileKey/biz-awards/:id`
   */
  private readonly API_AWARDS_UPDATE = `${this.API_AWARDS_BASE}/:id`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private profileState: KalgudiProfileStateService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Calls API to save a new award to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param payload Award payload
   */
  saveNewAward(
    profileKey: string, defaultBizKey: string,
    payload: KalgudiUserAwardDetails, extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_AWARDS_SAVE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey);

    return this.httpClient.post<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateAwardResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to save a new award to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param awardId Unique identified associated with award
   *
   * @param payload Updated award payload
   */
  updateNewAward(
    profileKey: string, defaultBizKey: string,
    awardId: string, payload: KalgudiUserAwardDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_AWARDS_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', awardId);

    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateAwardResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to delete an existing award of the logged in user.
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param awardId Unique identifier of the award
   */
  deleteAward(
    profileKey: string,
    defaultBizKey: string,
    awardId: string,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_AWARDS_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', awardId);

    return this.httpClient.delete<ApiResponseCommonV1>(url, { params })
      .pipe(

        // Api response error handler
        map(r => this.deleteAwardResponseHandler(r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Save new award API response handler. Throws error messages if
   * Api didn't sent success creation response.
   */
  private saveOrUpdateAwardResponseHandler(req: KalgudiUserAwardDetails, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved award details
    return res;
  }

  /**
   * Delete award API response handler. Throws error messages if
   * Api didn't sent success deletion response.
   */
  private deleteAwardResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved award details
    return res;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
