import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { KalgudiChipsModule } from '@kalgudi/common/ui/chips';
import { KalgudiPipesModule, PageStateManager } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SurveyStateService } from '../../services/survey-state.service';
import { KalgudiSurveyCreationModule } from '../survey-creation/survey-creation.module';
import { MySurveysListComponent } from './components/my-surveys-list/my-surveys-list.component';
import { SurveyListHeaderComponent } from './components/survey-list-header/survey-list-header.component';
import { SurveyListItemComponent } from './components/survey-list-item/survey-list-item.component';
import { SurveyListPageComponent } from './components/survey-list-page/survey-list-page.component';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { SurveyActionService } from './services/survey-action.service';
import { SurveysListApiService } from './services/surveys-list-api.service';
import { SurveysListService } from './services/surveys-list.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SurveyListItemComponent,
    SurveyListComponent,
    SurveyListHeaderComponent,
    SurveyListPageComponent,
    MySurveysListComponent,
  ],
  imports: [
    CommonModule,


    // Material modules
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,

    FlexLayoutModule,

    AngularSvgIconModule,

    // Kalgudi modules
    KalgudiBreadcrumbsModule,
    KalgudiChipsModule,
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,

    KalgudiSurveyCreationModule,
  ],
  providers: [
    SurveysListService,
    SurveysListApiService,
    SurveyActionService,
    {
      provide: PageStateManager,
      useExisting: SurveyStateService,
    },
  ],
  exports: [
    SurveyListItemComponent,
    SurveyListComponent,
    SurveyListHeaderComponent,
    SurveyListPageComponent,
    MySurveysListComponent
  ]
})
export class KalgudiSurveysListModule { }
