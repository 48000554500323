import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInAnimation, floatTopAnimation } from '@kalgudi/core';
import { TaskSubmissions } from '@kalgudi/project-manager';
import { KalgudiProjectTask } from '@kalgudi/types';

@Component({
  selector: 'kl-page-task-submissions',
  templateUrl: './page-task-submissions.component.html',
  styleUrls: ['./page-task-submissions.component.scss'],
  animations: [floatTopAnimation, fadeInAnimation]
})
export class PageTaskSubmissionsComponent extends TaskSubmissions implements OnInit {

  @Input()
  pageId: string;

  constructor(
    protected injector: Injector,
    private router: Router,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.initTableStream(this.matPaginator, this.matSort);
  }

  onDestroyed(): void {}

  getSubmissions(
    taskId: string,
    submissionDetails: KalgudiProjectTask,
  ): void {

    this.getSubmissionDetails(taskId, submissionDetails, {pageId: this.pageId});
  }

  /**
   * Navigate to submissions
   */
  navigateToSubmissions() {
    const url = `/app/pages/${this.taskDetails.pageId}/tasks/${this.taskDetails.id}/members`;
    this.router.navigate([url], {queryParams : { membersStatus: 'NOT_STARTED'}});
  }
}
