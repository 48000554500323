import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { AvailableDomains, DevAvailableDomains } from '@kalgudi/types';

@Component({
  selector: 'kl-promotional-share-products',
  templateUrl: './promotional-share-products.component.html',
  styleUrls: ['./promotional-share-products.component.scss']
})
export class PromotionalShareProductsComponent implements OnInit {

  @Input()
  productList;

  domain: string;

  defaultProfilePic = "/assets/svgs/farm-crop.svg";
  isMobileDevice = checkMobileDevice();

  storeProductList: any[] = [
    {
      productImg: "assets/images/herbal3.png",
      productName: "Annapurna Improved Birla Shaktiman store",
      storeName: "Birla Shaktiman",
      // cost: "100",
      actualCost: "200",
      quantity: "500",
      unit: "g"
    },
    {
      productImg: "assets/images/herbal3.png",
      productName: "Bond NPK 19:19:19",
      storeName: "Birla Shaktiman",
      cost: "5000",
      actualCost: '5200',
      quantity: "1",
      unit: "kg"
    },
  ]

  slideConfig = {
    slidesToShow: this.isMobileDevice ? 1 : 2,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    centerMode: false,
    infinite: false,
    variableWidth: true
    };

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiCommonRouteConfig,
  ) {

    this.domain = this.env.coreDomain;

  }


  ngOnInit() {
  }

  /**
   * Navigating to product full view
   * @param storeType
   * @param productName
   * @param productId
   */
  productFullView(storeType: any, productLevel2Title: string, productId_level2: string,productId_level3: string, stores?: any, isBulkOrder?: boolean): void {

    if(storeType === 'SAM_CMP') {

      const url = `https://credit.samunnatimart.com/app/loan-fullview/${productId_level2}_${productId_level3}`;
      window.open(url, '_blank');

    } else {

      const storeName = stores && Array.isArray(stores) && stores.length && stores[0]
                          ? this.env.production ? AvailableDomains[stores[0]]: DevAvailableDomains[stores[0]]
                          : storeType
                            ? this.env.production ? AvailableDomains[storeType] : DevAvailableDomains[storeType]
                            : this.env.domain === 'https://core.kalgudi.com' ? this.env.farmerStoreDomain : this.env.storeDomain;

      this.appRouting.toProductFullview(
        {
          storeName: storeName,
          productLevel2Title: productLevel2Title,
          productIdLevel2 : productId_level2,
          productIdLevel3: productId_level3,
          bulkOrder: isBulkOrder
        }
      );
    }
  }
}
