import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiMatTableStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiPageDetails, KalgudiStock } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, first, skip, switchMap, takeUntil } from 'rxjs/operators';

import { PageActions } from '../constants';
import { StockStateService } from '../modules/page-stocks/services/stock-state.service';
import { StocksActionService } from '../modules/page-stocks/services/stocks-action.service';
import { StocksService } from '../modules/page-stocks/services/stocks.service';
import { KalgudiPageService } from '../services/kalgudi-page.service';
import { ProgramStateService } from '../services/program-state.service';


@Directive()
export abstract class KalgudiStocksList extends KalgudiMatTableStream<KalgudiStock[]> {

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input()
  pageId: string;

  progress: boolean;
  pageDetails: KalgudiPageDetails;


  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  private stocksService: StocksService;
  private programState: ProgramStateService;
  private dialogsService: KalgudiDialogsService;
  private stocksActionService: StocksActionService;
  private notification: KalgudiNotification;
  private stockStateService: StockStateService;
  private kalgudiPageService: KalgudiPageService;

  constructor(protected injector: Injector) {

    super();

    this.stocksService       = this.injector.get<StocksService>(StocksService);
    this.programState        = this.injector.get<ProgramStateService>(ProgramStateService);
    this.dialogsService      = this.injector.get<KalgudiDialogsService>(KalgudiDialogsService);
    this.stocksActionService = this.injector.get<StocksActionService>(StocksActionService);
    this.notification        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.stockStateService   = this.injector.get<StockStateService>(StockStateService);
    this.kalgudiPageService  = this.injector.get<KalgudiPageService>(KalgudiPageService);


    // Override the list of columns to show
    this.displayedColumns = [
      'farmer',
      'stockAddedOn',
      'product',
      'price',
      'quantity',
      'settings'
    ];

    this.stockStateService.reloadStocks$
      .pipe(
        takeUntil(this.destroyed$),

        skip(1)
      )
      .subscribe(
        (res) => this.resetTable()

      );

    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.pageDetails = pageDetails;
        this.memberRole = pageDetails.memberRole;
      });


    /**
     * On `ADD_STOCK` state action changed
     */
    this.programState.action$
      .pipe(
        filter(action => action.type === PageActions.ADD_STOCK),

        takeUntil(this.destroyed$),
      )
      .subscribe(res => this.openAddStockDialog(this.pageId));
  }

  /**
   * Deletes the stock
   */
  deleteStock(stockId: string) {

    this.stocksActionService.deleteStock(stockId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.resetTable();
        },
        err => this.stockDeleteErrHandler(err)
      );
  }

  /**
   * Open edit stock dialog
   * @param stockId
   */
  editStock(stockId: string) {

    this.stocksService.getStock(stockId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
        switchMap(_ => this.stocksActionService.openStockCreationDialog(this.pageId, stockId))
      )
      .subscribe();

  }

  /**
   * Opens the stock creation dialog
   */
  openAddStockDialog(pageId) {

    this.stocksActionService.openStockCreationDialog(pageId, '')
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  protected streamApi(offset: number, limit: number, sortBy?: string, sortDirection?: string): Observable<KalgudiStreamData> {

    this.progress = true;

    return this.stocksService.getStocks(offset, limit, this.pageId)
      .pipe(
        finalize(
          () => this.progress = false
        ),
      );
  }

  protected onDestroyed(): void { }

  /**
   * Event handler for failed to delete stock
   */
  protected stockDeleteErrHandler(err: Error) {
    this.notification.showMessage('Failed to delete a stock');
  }

}
