<mat-card fxFlex="100%" fxLayout="column" class="fpo-infrastructure-tile">

  <!-- FPO infrastructure header -->

  <kl-common-profile-header
    title="{{ environment?.appId === 'SHG_NET_APP' ? 'SHG Infrastructure' : 'FPO Infrastructure'}}"
    svgIcon="assets/svgs/fpo-infrastructure.svg"
    [editable]="editable"
    actionIcon="edit"
    (action)="showFpoInfrastructureDialog()">
  </kl-common-profile-header>
  <!-- / FPO infrastructure header -->

  <!-- FPO Infrastructure list-->
  <div fxLayout="column">
    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(60% - 6px)" class="infrastructure-type">Internet connectivity</span>
      <span fxFlex="calc(40% - 6px)" class="infrastructure-value">{{ fpoInfrastructureDetails?.isInternetConnected || 'No' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(60% - 6px)" class="infrastructure-type">Computer / Tab available</span>
      <span fxFlex="calc(40% - 6px)" class="infrastructure-value">{{ fpoInfrastructureDetails?.isDeviceAvailable || 'No' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(60% - 6px)" class="infrastructure-type">Any accounting/FPO software</span>
      <span fxFlex="calc(40% - 6px)" class="infrastructure-value">
        {{ fpoInfrastructureDetails?.accounting?.isAccounted || 'No' }}
        <span *ngIf=" fpoInfrastructureDetails?.accounting?.FPOSoftware">/ {{ fpoInfrastructureDetails?.accounting?.FPOSoftware }}</span>
      </span>
    </div>

    <div *ngIf="isShgApp" class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(60% - 6px)" class="infrastructure-type">Any companies doing field trials / demos</span>
      <span fxFlex="calc(40% - 6px)" class="infrastructure-value">
        {{ fpoInfrastructureDetails?.fieldTrials?.isTrialDone || 'No' }}
        <span *ngIf=" fpoInfrastructureDetails?.fieldTrials?.trialsBy">/ {{ fpoInfrastructureDetails?.fieldTrials?.trialsBy }}</span>
      </span>
    </div>

    <div *ngIf="isShgApp" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(60% - 6px)" class="infrastructure-type">Interested in participating in input company interactions / demos</span>
      <span fxFlex="calc(40% - 6px)" class="infrastructure-value">
        {{ fpoInfrastructureDetails?.interestedToParticipate?.isInterestedToParticipate || 'No' }}
        <span *ngIf=" fpoInfrastructureDetails?.interestedToParticipate?.participatingInInput">/ {{ fpoInfrastructureDetails?.interestedToParticipate?.participatingInInput }}</span>
      </span>
    </div>

  </div>
  <!--/ FPO Infrastructure list-->

</mat-card>
