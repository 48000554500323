<div id="add-live-stock">
  <form autocomplete="off" [formGroup]="addLiveStockForm">
    <div fxLayout="column">
      <!-- Batch selection -->
      <mat-form-field appearance="outline" class="no-right-border w-100">
        <mat-select  formControlName="batchNo" placeholder="Batch number" required>
          <mat-option *ngFor="let batch of batches" [value]="batch">
            {{ batch }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!--/ Batch selection -->

      <!-- Name -->
      <ng-container>
        <mat-form-field appearance="outline" *ngIf="stockType.title === 'POULTRY'">
          <mat-label translate>Name of coop</mat-label>
          <input matInput formControlName="alternativeName" maxlength="30" (keypress)="onKeyPress($event, 'alphanumericSpace')" required>
        </mat-form-field>
      </ng-container>
      <!--/ Name -->

      <!-- Total -->
      <mat-form-field appearance="outline">
        <mat-label translate>Total {{stockType.title === 'POULTRY' ? 'chicks' : (stockType.title | titlecase)}}</mat-label>
        <input matInput min="0" formControlName="totalQuantity" type="number" required id="totalInputField" #inputValue (input)="restricting(inputValue.value, 'totalInputField')" #totalQuantity (keypress)="validateNumericInputLength($event, totalQuantity?.value,'totalQty')">
      </mat-form-field>
      <!--/ Total -->

      <!-- Coop dimensions -->
      <div formGroupName="dimensions">
        <span>Dimensions</span>
        <div fxLayout="row" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label translate>Size</mat-label>
              <input matInput min="0" type="number" formControlName="length" #length (keypress)="validateNumericInputLength($event, length?.value,'totalQty')">
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label translate>Unit</mat-label>
              <mat-select formControlName="unit">
                <mat-option value="option" *ngFor="let size of sizeInList" [disabled]="unit.value ? size?.id !== unit.value : false" [value]="size?.id">{{ size?.value | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="5px">
          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label translate>Size</mat-label>
              <input matInput type="number" min="0" formControlName="breadth" #breadth (keypress)="validateNumericInputLength($event, breadth?.value,'totalQty')">
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label translate>Unit</mat-label>
              <mat-select formControlName="unit">
                <mat-option value="option" *ngFor="let size of sizeInList" [disabled]="unit.value ? size?.id !== unit.value : false" [value]="size?.id">{{ size?.value | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!--/ Coop dimensions -->

      <!-- Geo tag -->
      <div class="geo-tag cursor-pointer" (click)="showAddGeoTagLandDialog()">
        <span>Click here to add geo tag</span>
      </div>

      <ng-container>
        <div *ngIf="geoTagDetails || stockType?.location">
          <p>Location: {{geoTagDetails?.location?.locationLong || stockType?.location?.location?.locationLong}}</p>
        </div>
      </ng-container>
      <!--/ Geo tag -->

      <!-- Breed name -->
      <mat-form-field appearance="outline">
        <mat-label translate>Breed name</mat-label>
        <input matInput formControlName="breedName" maxlength="30" (keypress)="onKeyPress($event, 'alphanumericSpace')">
      </mat-form-field>
      <!--/ Breed name -->

      <!-- Male -->
      <mat-form-field appearance="outline">
        <mat-label translate>{{maleTag | titlecase}}</mat-label>
        <input matInput (focus)="onFocusValue($event, maleCount)" (focusout)="onOutFocusValue($event, maleCount)" formControlName="maleCount" type="number" id="maleInputField" #inputValue1 (input)="restricting(inputValue1.value, 'maleInputField')" min="0" #maleCount (keypress)="validateNumericInputLength($event, maleCount?.value,'totalQty')">
      </mat-form-field>
      <!--/ Male -->

      <!-- Female -->
      <mat-form-field appearance="outline">
        <mat-label translate>{{femaleTag | titlecase}}</mat-label>
        <input matInput (focus)="onFocusValue($event, femaleCount)" (focusout)="onOutFocusValue($event, femaleCount)" formControlName="femaleCount" type="number" id="femaleInputField" #inputValue2 (input)="restricting(inputValue2.value, 'femaleInputField')" min="0" #femaleCount (keypress)="validateNumericInputLength($event, femaleCount?.value,'totalQty')">
      </mat-form-field>
      <!--/ Female -->

      <!-- Date -->
      <mat-form-field appearance="outline">
        <mat-label translate>Date</mat-label>
          <input matInput [placeholder]="'Date' | translate" formControlName="date" (click)="date.open()" [matDatepicker]="date" readonly/>
          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
      <!--/ Date -->

      <!-- Age -->
      <mat-form-field appearance="outline">
        <mat-label translate>Age</mat-label>
        <input matInput formControlName="age" type="number" id="ageInputField" #inputValue3 (input)="restricting(inputValue3.value, 'ageInputField')" min="0" #age (keypress)="validateNumericInputLength($event, age?.value,'totalQty')">
      </mat-form-field>
      <!--/ Age -->

      <!-- Mortality -->
      <mat-form-field appearance="outline">
        <mat-label translate>Mortality</mat-label>
        <input matInput (focus)="onFocusValue($event, mortality)" (focusout)="onOutFocusValue($event, mortality)" formControlName="mortality" type="number" id="mortalityInputField" #inputValue4 (input)="restricting(inputValue4.value, 'mortalityInputField')" min="0" #mortality (keypress)="validateNumericInputLength($event, mortality?.value,'totalQty')">
      </mat-form-field>
      <!--/ Mortality -->

      <!-- Section for avaliable quantity -->
      <section formArrayName="product" fxLayout="column">
        <div *ngFor="let controls of product?.controls; let i = index">
          <div [formGroupName]="i">
            <mat-form-field appearance="outline" fxFlex="100%">
              <mat-label translate>Avaliable for sale - {{controls?.value?.productName}}</mat-label>
              <input matInput formControlName="availableQuantity" type="number" id="{{ controls?.value?.productName }}" #inputValue5 (input)="restricting(inputValue5.value, controls?.value?.productName)"  min="0" #availableQuantity (keypress)="validateNumericInputLength($event, availableQuantity?.value,'totalQty')">
            </mat-form-field>
          </div>
        </div>
      </section>
      <!--/ Section for avaliable quantity -->

      <!-- Average -->
      <mat-form-field appearance="outline">
        <mat-label translate>Average {{stockType.title === 'POULTRY' ? 'bird' : ''}} {{(stockType.title === 'COWS' || stockType.title === 'BUFFALOES') ? 'milking' : 'weight'}}</mat-label>
        <input matInput (focus)="onFocusValue($event, averageWeight)" (focusout)="onOutFocusValue($event, averageWeight)" formControlName="averageWeight" min="0" type="number" id="averageInputField"  #averageWeight (keypress)="validateNumericInputLength($event, averageWeight?.value,'totalQty')">
      </mat-form-field>
      <!--/ Average -->

      <div fxLayout fxLayoutAlign="end end">
        <kl-flat-button
          type="submit"
          [label]="!showEditFields ? 'Add' : 'Update'"
          [disabled]="!showEditFields ? (addLiveStockForm?.invalid) : false"
          (clicked)="!showEditFields ? addLiveStock() : updateLiveStock()">
        </kl-flat-button>
      </div>
    </div>
  </form>
</div>
