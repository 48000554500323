import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { ChartDirection, KalgudiChart } from '@kalgudi/types';

@Component({
  selector: 'kl-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  animations: kalgudiAnimations
})
export class ChipListComponent implements OnInit {

  /**
   * Set `false` to disable animations, default set `true`
   */
  @Input()
  animation = true;

  @Input()
  chips: KalgudiChart;

  @Input()
  direction: ChartDirection;

  constructor() { }

  ngOnInit() {
  }

}
