import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-robocall-full-view-dialog',
  templateUrl: './robocall-full-view-dialog.component.html',
  styleUrls: ['./robocall-full-view-dialog.component.scss']
})
export class RobocallFullViewDialogComponent extends KalgudiDialog<RobocallFullViewDialogComponent> implements OnInit {

  buttonTitle: string = '';
  isButtonEnable: boolean = false;
  approveTime: Date;
  noOfUsers: string;
  isAppId: boolean = false;
  scheduleTime: Date;

  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    protected dialogRef: MatDialogRef<RobocallFullViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

    this.isAppId = this.env.appId !== 'SAM_FPO'
    this.approveTime = data?.data?.postDetails?.robocallDetails?.approveTime;
    this.noOfUsers = data?.data?.postDetails?.robocallDetails?.targetedUsers;
    this.scheduleTime = data?.data?.postDetails?.scheduledTS;

    // Checking existing of schedule & approval time
    if (this.scheduleTime && this.approveTime) {
      // Both scheduleTime and approveTime are truthy
      this.scheduleTime = new Date(this.scheduleTime);
      this.approveTime = new Date(this.approveTime);
      this.resultDate(this.approveTime > this.scheduleTime ? this.approveTime : this.scheduleTime);
    } else if (this.scheduleTime) {
        // Only scheduleTime is truthy
        this.buttonTitle = 'Waiting for approval';
    } else if (this.approveTime) {
        // Only approveTime is truthy
        this.resultDate(new Date(this.approveTime));
    } else {
        // Neither scheduleTime nor approveTime are truthy
        this.buttonTitle = 'Waiting for approval';
    }

  }

  ngOnInit(): void {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

  /**
   * Calculate result date & time to enable download button for SAM_FPO
   * @param date approveTime as date
   * @param user no of users
   * @returns
   */
  checkApprovalTime(date: Date) {
    let result = new Date(date);
    // result.setHours(result.getHours() + 5, result.getMinutes() + 30, 0, 0);
    result.setDate(result.getDate() + 1);

    return result;
  }

  /**
   * Final result will update by comparing with current time
   * @param dateField passing date which is greater
   */
  resultDate(dateField: Date) {
    let date = new Date(dateField);
    let result = this.checkApprovalTime(date);
    let currentTime = new Date();

    this.isButtonEnable = currentTime >= result;
    this.buttonTitle = this.isButtonEnable ? 'Download report' : 'Report in progress';
  }
}
