<div id="program-list-table">

  <mat-card [@floatTop]="{ value: '', params: { delay: 0 }}" class="p-0 overflow-hidden">

    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--Program name column -->
      <ng-container matColumnDef="programName" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Page </th>
        <td mat-cell *matCellDef="let element"> {{ element?.pageTitle }} </td>
      </ng-container>
      <!--/ Program name column -->

      <!-- User role column -->
      <ng-container matColumnDef="userRole" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Role </th>
        <td mat-cell *matCellDef="let element"> {{ element?.memberRole | titlecase }} </td>
      </ng-container>
      <!--/ User role column -->

      <!-- Date column -->
      <!-- <ng-container matColumnDef="date" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Start date </th>
        <td mat-cell *matCellDef="let element" class="text-secondary">
          <span *ngIf="element?.LUT">{{ element?.LUT | date:'dd MMM, yyyy' }}</span>
        </td>
      </ng-container> -->
      <!-- / Date column -->

      <!-- Members column -->
      <ng-container matColumnDef="members" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Members </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="4px">
            <div fxLayout fxLayoutAlign="center center">
              <span>{{element?.membersCount}}</span>
          </div>
          </div>
        </td>
      </ng-container>
      <!--/ Members column -->

      <!-- Program type column -->
      <ng-container matColumnDef="programType" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Type </th>
        <td mat-cell *matCellDef="let element">
          {{element?.pageType | titlecase | translate}}
        </td>
      </ng-container>
      <!--/ Program type column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" (click)="openPageFullview(row?.pageId)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

</div>
