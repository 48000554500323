import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiAddServices } from '../../../classes/kalgudi-add-services';

@Component({
  selector: 'kl-services-form',
  templateUrl: './services-form.component.html',
  styleUrls: ['./services-form.component.scss']
})
export class ServicesFormComponent extends KalgudiAddServices implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.servicesList = this.util.clone(this.profileDetails.fpoAdditionalDetails) ? this.util.clone(this.profileDetails.fpoAdditionalDetails.fpoServices) : [];
  }

  onDestroyed() : void {}
}
