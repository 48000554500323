import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiNotification } from './kalgudi-notification';

@Component({
  selector: 'kl-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends KalgudiNotification implements OnInit {

  defaultNotificationImage = 'https://www.kalgudi.com/data/profilepics/M00k4jn0PRFREG2017121337719606UNH001.png?1513163011043';

  constructor(protected injector: Injector) {

    super(injector);

  }

  ngOnInit() {
  }

}
