import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ActivityAnalyticsComponent } from './components/activity-analytics/activity-analytics.component';
import { AnalyticsRowComponent } from './components/analytics-row/analytics-row.component';
import { CoverageAnalyticsComponent } from './components/coverage-analytics/coverage-analytics.component';
import { CoverageStatsComponent } from './components/coverage-stats/coverage-stats.component';
import { PageAnalyticsStatsComponent } from './components/page-analytics-stats/page-analytics-stats.component';
import { PageAnalyticsComponent } from './components/page-analytics/page-analytics.component';
import { ProjectStatsComponent } from './components/project-stats/project-stats.component';
import { SocialStatsComponent } from './components/social-stats/social-stats.component';
import { TeamsAnalyticsComponent } from './components/teams-analytics/teams-analytics.component';
import { UserAssistantsStatsComponent } from './components/user-assistants-stats/user-assistants-stats.component';
import { KalgudiPageAnalyticsApiService } from './services/page-analytics-activities-api.service';
import { KalgudiPageAnalyticsService } from './services/page-analytics-activities.service';



@NgModule({
  declarations: [
    PageAnalyticsStatsComponent,
    AnalyticsRowComponent,
    PageAnalyticsComponent,
    ActivityAnalyticsComponent,
    CoverageAnalyticsComponent,
    TeamsAnalyticsComponent,
    SocialStatsComponent,
    ProjectStatsComponent,
    UserAssistantsStatsComponent,
    CoverageStatsComponent,
  ],
  imports: [
    CommonModule,

    RouterModule,

    AngularSvgIconModule,
    // Material
    MatCardModule,
    MatIconModule,

    // Flex module
    FlexLayoutModule,

    // Ngx charts
    NgxChartsModule,

    // Kalgudi libraries
    KalgudiNoContentModule,
    KalgudiPipesModule,
    TranslateModule
  ],
  exports: [
    PageAnalyticsStatsComponent,
    AnalyticsRowComponent,
    PageAnalyticsComponent,
    ActivityAnalyticsComponent,
    CoverageAnalyticsComponent,
    TeamsAnalyticsComponent,
  ],
  providers: [
    KalgudiPageAnalyticsService,
    KalgudiPageAnalyticsApiService
  ]
})
export class KalgudiPageAnalyticsModule { }
