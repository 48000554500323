<div id="farmer-land-details" fxLayout="column" fxLayoutGap.xs="10px" fxLayoutGap="15px">

  <div class="mb-1 mr-n3" flex fxLayout="row" fxLayoutAlign="end end">

    <kl-icon-link-button [type]="'button'" [label]="'Edit land'" *ngIf="!isLandEditable" (clicked)="openEditLandDetailsDialog()">
    </kl-icon-link-button>
    <!-- <button mat-icon-button class="edit-land-details border-0 p-0" fxLayoutAlign="end" (click)="openEditLandDetailsDialog()">
      <mat-icon class="rounded-circle ed  it-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
    </button> -->
  </div>

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div class="pr-2" fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="d-inline-block details-header text-truncate">
        <span>{{details?.landSize?.value}}</span>
        <span class="ml-1">{{details?.landSize?.unit | titlecase}}</span>
        <span *ngIf="!details?.landSize?.value">-</span>
      </div>
      <div class="text-truncate text-secondary details-sub-header" translate>Land size</div>
    </div>

    <div class="border-right"></div>

    <div class="pr-2" fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="details-header text-truncate">
        {{details?.soilType | trimSpecialCharacters | titlecase }}
        <span *ngIf="!details?.soilType">-</span>
      </div>
      <div class="text-secondary details-sub-header text-truncate" translate>Soil type</div>
    </div>

    <div class="border-right"></div>

    <div fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="d-inline-block details-header text-truncate" >
        <span>{{details?.waterLevel?.value}}</span>
        <span class="ml-1">{{details?.waterLevel?.unit | titlecase}}</span>
        <span *ngIf="!details?.waterLevel?.unit && !(details?.waterLevel?.value == 0) && !details?.waterLevel?.value">-</span>
      </div>
      <div class="text-secondary details-sub-header text-truncate" translate>Water level</div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div class="pr-2" fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="details-header text-truncate">
        {{details?.irrigationType | trimSpecialCharacters | titlecase}}
        <span *ngIf="!details?.irrigationType">-</span>
      </div>
      <div class="text-secondary details-sub-header text-truncate" translate>Irrigation type</div>
    </div>

    <div class="border-right"></div>

    <div class="pr-2" fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="details-header text-truncate">
        {{details?.salinity | trimSpecialCharacters | titlecase }}
        <span *ngIf="!details?.salinity">-</span>
      </div>
      <div class="text-secondary details-sub-header text-truncate" translate>Salinity</div>
    </div>

    <div class="border-right"></div>

    <div fxFlex="calc(33.33% - 15px)" fxLayout="column">
      <div class="details-header text-truncate">
        {{details?.salinityLevel | titlecase}}
        <span *ngIf="!details?.salinityLevel">-</span>
      </div>
      <div class="text-secondary details-sub-header text-truncate" translate>Salinity level</div>
    </div>
  </div>

  <!-- Land cover type -->
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="details?.landCoverType">
    <div class="pr-2" flex fxLayout="column">
      <div>
        <span class="details-header">Land cover type :</span>
        <span  class="details-sub-header text-secondary text-truncate ml-1">
          {{details?.landCoverType | trimSpecialCharacters | titlecase}}
        </span>
      </div>
    </div>
  </div>
  <!-- Land cover type -->

  <!-- Field notes -->
  <div fxLayout="row" fxLayoutAlign="start center" *ngIf="details?.fieldNotes">
    <div class="pr-2" flex fxLayout="column">
      <div class="field-notes" translate>Field notes</div>
      <div class="text-secondary overflow-hidden field-notes-content">
        {{details?.fieldNotes}}
      </div>
    </div>
  </div>
  <!--/ Field notes -->

  <!-- farm boundary google map -->
  <div *ngIf="details?.geoFences && details?.geoFences.length">
    <div class="map-header text-capitalize mb-2">Farmland on Google Maps</div>
    <div class="location-map" *ngIf="details?.geoFences.length === 1">
      <kl-geo-tag
      [location]="details?.location"
      [LabelPositionTop]="true"></kl-geo-tag>
    </div>
    <div class="location-map" *ngIf="details?.geoFences.length > 1">
      <kl-geo-location-marker
        [location]="details?.location"
        [polygonPath]="details?.geoFences"></kl-geo-location-marker>
    </div>
  </div>
  <!--/ farm boundary google map -->

  <!-- image attachments -->
  <div class="mr-n3" *ngIf="details?.attachments?.length">
    <div class="map-header text-capitalize mb-2">Geo tagging</div>
    <kl-post-tile-image-attachment [attachments]="details?.attachments">
    </kl-post-tile-image-attachment>
  </div>
  <!--/ image attachments -->
</div>
