<section>
  <div id="loading-spinner" class="no-content my-3" fxLayout="column" fxLayoutAlign="center center">

    <!-- Icons -->
    <div *ngIf="!progress">
      <div *ngIf="matIcon" class="icon {{ iconSize }}">
        <mat-icon [ngStyle]="{ color: iconColor }">
          {{ matIcon }}
        </mat-icon>
      </div>

      <div class="{{ iconSize }}" *ngIf="faIcon">
        <i class="{{ faIcon }}" [ngStyle]="{ color: iconColor }"></i>
      </div>

      <div *ngIf="svgIcon" class="svg-icon {{ iconSize }} mb-2">
        <svg-icon class="text-center" [applyCss]="true" [src]="svgIcon" [svgStyle]="{ 'fill': iconColor}">
        </svg-icon>
      </div>
    </div>
    <!-- /Icons -->

    <!-- Loading icon  -->
    <div class="spinner icon {{ iconSize }}" *ngIf="progress" fxLayout="column" fxLayoutAlign="center center">
      <span id="social-activity-spinner" class="spinner-border spinner-border-lg text-muted" role="status"></span>
      <span class="text-muted mt-1" id="progress-text" translate>{{ progressText}}</span>
    </div>
    <!-- / Loading icon  -->

    <!-- No content texts -->
    <div id="no-social-activity-text" fxLayout="column" fxLayoutAlign="center center" *ngIf="!progress">
      <p class="mb-0 text text-muted" *ngIf="text" translate>{{ text}}</p>
      <p class="mb-0 description text-muted" *ngIf="description" translate>{{ description }}</p>
      <a class="link" *ngIf="actionLink" (click)="onActionLinkClicked()" translate>{{ actionLink}}</a>
    </div>
    <!-- / No content texts -->

  </div>

</section>
