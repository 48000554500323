<div id="upload-image-mobile">

  <div class="mt-3">
    <kl-add-certificate
      (closeDialog)="cancel()"
      [certificateId]="certificateId"
      [certificateDetails]="certificateDetails"></kl-add-certificate>
  </div>

</div>
