import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-view-live-stock-location-mobile-dialog',
  templateUrl: './view-live-stock-location-mobile-dialog.component.html',
  styleUrls: ['./view-live-stock-location-mobile-dialog.component.scss']
})
export class ViewLiveStockLocationMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  constructor(
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_MOBILE_DIALOG_DATA) public data: MobileDialogConfig,
  ) {
    super(dialogRef)
  }

  ngOnInit(): void {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
