import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';
import { GeoFenceDetails, KalgudiLocation, LatLong } from '@kalgudi/types';
import { interval, Subject } from 'rxjs';
import { finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { KalgudiGeoLocationMarkerService } from '../../kalgudi-geo-location-marker.service';


const staticLatLong = [
  { latitude: 21.909416926059404, lat: 21.909416926059404, longitude: 77.90528560184376, lng: 77.90528560184376},
  { latitude: 21.90951646547678, lat: 21.90951646547678, longitude: 77.90065074466602, lng: 77.90065074466602},
  { latitude: 21.91296048648151, lat: 21.91296048648151, longitude: 77.90097260974781, lng: 77.90097260974781},
  { latitude: 21.912920671685548, lat: 21.912920671685548, longitude: 77.90401959918873, lng: 77.90401959918873}
];

@Component({
  selector: 'kl-gps-geo-marker',
  templateUrl: './gps-geo-marker.component.html',
  styleUrls: ['./gps-geo-marker.component.scss']
})
export class GpsGeoMarkerComponent extends KalgudiDestroyable implements OnInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  @Input()
  showDot: boolean;

  @Output()
  coordsChanged = new EventEmitter<LatLong[]>();

  @Output()
  areaChanged = new EventEmitter<number>();

  @Output()
  fenceUpdated = new EventEmitter<GeoFenceDetails>();

  fencedLatLongs: LatLong[] = [];

  marking = false;
  areaFenced = 0;

  currentLocation: LatLong = {
    lat: '',
    lng: '',
    latitude: '',
    longitude: ''
  };

  progress: boolean;
  locationLabel: string;

  private markingSubject = new Subject();

  // _geoFences = [
  //   {lat: 27.022860396325797, lng: 74.21720303914798},
  //   {lat: 27.022038446704393, lng: 74.21747126004946},
  //   {lat: 27.021904640382875, lng: 74.21837248227847},
  //   {lat: 27.02254499776355, lng: 74.21983160398257},
  //   {lat: 27.023711012276145, lng: 74.21933807752383},
  //   {lat: 27.022860396325797, lng: 74.21720303914798},
  // ];

  // geoFences = [];

  geoFence = new FormControl([]);

  coordinates: any;

  constructor(private kalgudiGeoLocationMarkerService: KalgudiGeoLocationMarkerService) {
    super();
  }

  ngOnInit() {
    this.progress = true;

    this.kalgudiGeoLocationMarkerService.getGeoCoords()
    // this.geoLocationService.geoCoords$
      .pipe(
        take(1),

        map(res => this.coordinates = res),

        switchMap(res => this.kalgudiGeoLocationMarkerService.searchOnGoogle(res)),

        finalize(() => this.progress = false),

        takeUntil(this.destroyed$)
      )
      .subscribe((res: any) => {
        this.progress = false;

        const kalgudiLocation: KalgudiLocation = res.kalgudiLocation;

        this.locationLabel =( kalgudiLocation.districtName ? kalgudiLocation.districtName + ', ' : '')  +
          (kalgudiLocation.stateName ? kalgudiLocation.stateName + ', ' : '') +
          (kalgudiLocation.countryName ? kalgudiLocation.countryName : '') +
          (kalgudiLocation.countryName && kalgudiLocation.postalCode ? ' - ': '') +
          (kalgudiLocation.postalCode ? kalgudiLocation.postalCode : '');

        this.currentLocation.lat        = kalgudiLocation.latitude;
        this.currentLocation.latitude   = kalgudiLocation.latitude;
        this.currentLocation.lng        = kalgudiLocation.longitude;
        this.currentLocation.longitude  = kalgudiLocation.longitude;

        // this.handleSearchObserver(observer);
      });

      setTimeout(() => {
        if(!this.coordinates) {
        this.progress = false;
        }
      },800);

    // this.geoFence.patchValue([this._geoFences[0]]);
    // this.geoFences.push(this._geoFences[0]);

    // interval(2000)
    //   .pipe(
    //     skip(1),

    //     take(this._geoFences.length - 1)
    //   )
    //   .subscribe(val => {
    //     this.geoFences.push(this._geoFences[val]);
    //     this.geoFence.patchValue(this.geoFences);
    //     console.log(val, this.geoFences);
    //   });
  }

  onDestroyed(): void {}

  startMarking() {

    this.marking = true;

    this.resetExistingLatLong();

    // Start capturing lat longs every 2 seconds
    interval(2000)
      .pipe(
        takeUntil(this.markingSubject)
      )
      .subscribe(_ => {
        navigator.geolocation.getCurrentPosition(
          position => {
            // console.log(position.coords);
            this.pushIfPreviousNotSame(position.coords);
          },
          err => console.error(err),
          {
            enableHighAccuracy: true,
            maximumAge: 0,
            timeout: 30000
          });
      });
  }

  stopMarking() {

    this.marking = false;
    this.markingSubject.next();

    // this.fencedLatLongs = staticLatLong;

    this.mergeFirstAndLastPoint();

    const areaInSqMetres = this.calculateArea(this.fencedLatLongs);
    this.areaFenced = +this.convertSqMetresToAcre(areaInSqMetres).toFixed(2);

    this.areaChanged.emit(this.areaFenced);

    this.saveFenceDetails();
  }

  /**
   * Saves the fence details
   */
  saveFenceDetails() {
    this.fenceUpdated.emit({
      coords: this.fencedLatLongs,
      areaInAcres: this.areaFenced,
    });
  }

  /**
   * Resets existing marking
   */
  resetExistingLatLong() {
    this.fencedLatLongs = [];
    this.areaFenced = 0;

    this.coordsChanged.emit(this.fencedLatLongs);
    this.areaChanged.emit(this.areaFenced);
    this.saveFenceDetails();
    this.geoMarker.reset();
  }

  /**
   * Merges first and last point. This is necessary to calculate the area
   */
  private mergeFirstAndLastPoint() {

    if (this.fencedLatLongs.length > 0) {
      this.pushLatLong(this.fencedLatLongs[0]);
    }
  }

  /**
   * Pushes a new item to the existing fenced lat long array.
   */
  private pushLatLong(val: LatLong): void {

    const newLatLong = {
      latitude: val.latitude,
      longitude: val.longitude,
      lat: val.latitude,
      lng: val.longitude,
    };

    this.fencedLatLongs.push(newLatLong);
    this.geoFence.patchValue(this.fencedLatLongs);

    console.log(val);

    this.coordsChanged.emit(this.fencedLatLongs);
  }

  /**
   * Pushes a new lat long to the fenced lat long array if the previous
   * fenced lat long value is not same as the current.
   */
  private pushIfPreviousNotSame(val: LatLong): void {

    // Only verify previous element if exists
    if (this.fencedLatLongs.length > 0) {

      // Gets last element from the fenced lat long
      const lastElem = this.fencedLatLongs[this.fencedLatLongs.length - 1];

      // Assert last element with current
      if (this.isLatLongSame(lastElem, val)) {
        return;
      }
    }

    // Last element not same hence push the new element ot the list
    this.pushLatLong(val);
  }

  /**
   * Checks if two lat longs are same or not
   */
  private isLatLongSame(val1: LatLong, val2: LatLong): boolean {
    return +val1.latitude === +val2.latitude && +val1.longitude === +val2.longitude;
  }

  /**
   * Calculates area enclosed by a series of closed lat long boundary.
   *
   * Returns area in square metres
   *
   * @see https://stackoverflow.com/a/32912727/2401088
   */
  private calculateArea(coords: LatLong[]): number {

    let area = 0;

    if (coords.length > 2) {

      for (let i = 0; i < coords.length - 1; i++)
      {
        let p1 = coords[i];
        let p2 = coords[i + 1];
        area += this.toRadian(+p2.longitude - +p1.longitude)
          * (
              2 + Math.sin(this.toRadian(+p1.latitude))
                + Math.sin(this.toRadian(+p2.latitude))
            );
      }

      area = area * 6378137 * 6378137 / 2;
    }

    return Math.abs(area);
  }

  /**
   * Convert square meter to acres
   *
   * @see https://www.checkyourmath.com/convert/area/m_acres.php
   */
  private convertSqMetresToAcre(val: number): number {
    return val / 4046.85642;
  }

  /**
   * Converts coordinates to Radians
   *
   * @see https://stackoverflow.com/a/32912727/2401088
   */
  private toRadian(input: number) {
    return input * Math.PI / 180;
  }

}
