import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiDestroyable, KalgudiUtilityService, KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiSurveyQuestionBasicDetails,
  KalgudiSurveyQuestionTypes,
  FileMimeTypes,
  AttachmentList,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { takeUntil, finalize } from 'rxjs/operators';

import { KalgudiSurveyQuestionsService } from '../services/kalgudi-survey-questions.service';

@Directive()
export abstract class KalgudiSurveyAddQuestion extends KalgudiDestroyable {

  @Input()
  surveyId: string;

  @Input()
  questionsCount: number;

  progress: boolean;

  questionForm: FormGroup;

  availableQuestionTypes = KalgudiSurveyQuestionTypes;

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.QA;

  readonly acceptedFileTypes: FileMimeTypes[] = [FileMimeTypes.IMAGE, FileMimeTypes.DOCUMENT];

  private fb: FormBuilder;
  private surveyQuestionsService: KalgudiSurveyQuestionsService;
  private notifications: KalgudiNotification;
  private util: KalgudiUtilityService;
  private imagePickerService: KalgudiImagePickerService;
  public env: KalgudiEnvironmentConfig
  isEnableMultiCheck: boolean = false;


  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                     = this.injector.get(FormBuilder);
    this.surveyQuestionsService = this.injector.get(KalgudiSurveyQuestionsService);
    this.notifications          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util                   = this.injector.get(KalgudiUtilityService);
    this.imagePickerService     = this.injector.get(KalgudiImagePickerService);
    this.questionForm           = this.newQuestionForm;
    this.env                    = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.questionFormValueChanges();

    if (this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER') {
      this.isEnableMultiCheck = true;
    }

  }

  // --------------------------------------------------------
  // #region for getters and setters
  // --------------------------------------------------------


  get optionArray(): FormArray {

    return this.questionForm.get('options') as FormArray;
  }

  get questionTypeField(): AbstractControl {
    return this.questionForm.get('questionType');
  }


  get questionField(): AbstractControl {

    return this.questionForm.get('question');
  }

  get attachmentsArray(): FormArray {
    return this.questionForm.get('attachments') as FormArray;
  }

  get attachmentsControls(): AbstractControl {

    return this.questionForm.get('attachments');
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Adds an option to the options list
   */
  addOption() {
    this.optionArray.push(this.newMcqFormControl());
  }

  /**
   * Calls api to post a question
   */
  addQuestion(): void {

    const payload = this.preparePayload(this.questionForm.value);

    this.progress = true;
    this.surveyQuestionsService.postQuestion(this.surveyId, payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.addQuestionHandler(res),
        err => this.onApiError(err)
      );
  }

  /**
   * Remove images from the form array
   */
  removeImage(index: number) {

    const attachments: AttachmentList = this.attachmentsControls.value || [];

    attachments.splice(index, 1);

    this.attachmentsControls.patchValue(attachments);
  }

  /**
   * Removes option from options array
   */
  removeOption(index): void {

    this.optionArray.removeAt(index);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and Protected interfacing methods
  // --------------------------------------------------------


  // Returns a new form group of survey question type
  private get newQuestionForm(): FormGroup {

    return this.fb.group({
      questionType: [''],
      question: ['', [Validators.required, Validators.minLength(10)]],
      attachments: new FormControl([]),
      options: this.fb.array([])
    });
  }


  /**
   * Prepares a payload
   */
  protected preparePayload(value): KalgudiSurveyQuestionBasicDetails {
    const multipleCheckBox = this.availableQuestionTypes.MULTIPLE_SELECT_CHECKBOX;
    const multipleChoice = this.availableQuestionTypes.MULTIPLE_CHOICE;

    if (value.questionType === multipleChoice) {
      if (multipleChoice !== this.availableQuestionTypes.MULTIPLE_CHOICE) {
        delete value.options;
      }
    }

    if (value.questionType === multipleCheckBox) {
      if (multipleCheckBox !== this.availableQuestionTypes.MULTIPLE_SELECT_CHECKBOX) {
        delete value.options;
      }
    }

    return value;
  }

  /**
   * Called after successful question post
   */
  protected addQuestionHandler(res): void { }


  /**
   * Event handler for question posting API errors.
   */
  protected onApiError(err: any): void {

    this.notifications.showMessage(err.message || err.error.message);
  }

  /**
   * Creates a new form group for option
   */
  private newMcqFormControl() {
    return this.fb.group({
      title: [''],
      value: ['', Validators.required]
    });
  }

  /**
   * Listens to the form field changes
   */
  private questionFormValueChanges(): void {
    this.questionTypeField.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if (value === this.availableQuestionTypes.MULTIPLE_CHOICE || value === this.availableQuestionTypes.MULTIPLE_SELECT_CHECKBOX) {
            this.addOption();
            this.addOption();
          } else {
            this.optionArray.clear();
          }

        }
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
