import { Directive, Inject, Injector, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  AttachmentList,
  FileMimeTypes,
  KalgudiSubmissionData,
  KalgudiSurveyDetails,
  KalgudiSurveyOption,
  KalgudiSurveySubmission,
  KalgudiSurveySubmissionRequest,
  PartialData,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil, tap } from 'rxjs/operators';

import { SurveyStateService } from '../../../services/survey-state.service';
import { KalgudiSurveySubmissionService } from '../services/kalgudi-survey-submission.service';

@Directive()
export abstract class KalgudiSurveySubmissionForm extends KalgudiInboxStream<KalgudiSurveySubmission> {

  @Input()
  surveyId: string;

  @Input()
  userProfileKey: string;

  @Input()
  extraPayload: PartialData = {};

  @Input()
  disableActions: boolean;

  submissionForm: FormGroup;

  readonly acceptedFileTypes: FileMimeTypes[] = [FileMimeTypes.IMAGE, FileMimeTypes.DOCUMENT];

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  surveyDetails: KalgudiSurveyDetails;
  submissionDetails: KalgudiSurveySubmission;

  progress: boolean;
  disableSubmissionForm = false;
  selectedOptionsDict: { [questionId: string]: string[] } = {};

  private surveySubmissionService: KalgudiSurveySubmissionService;
  private fb: FormBuilder;
  // private kalgudiApp: KalgudiAppService;
  private imagePickerService: KalgudiImagePickerService;
  private surveyState: SurveyStateService;
  private activatedRoute: ActivatedRoute;
  private router: Router;
  selectedOptionNames: any[] = [];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    this.fb = this.injector.get(FormBuilder);
    // this.kalgudiApp              = this.injector.get(KalgudiAppService);
    this.imagePickerService = this.injector.get(KalgudiImagePickerService);
    this.surveyState = this.injector.get(SurveyStateService);
    this.surveySubmissionService = this.injector.get(KalgudiSurveySubmissionService);
    this.activatedRoute = this.injector.get(ActivatedRoute);
    this.router = this.injector.get(Router);
    this.submissionForm = this.newSubmissionForm;

    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdate(survey));



  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  /**
   * Return submissionData form array
   */
  get submissionDataArray(): FormArray {

    return this.submissionForm.get('submissionData') as FormArray;
  }

  /**
   * Return answerData
   */
  getAnswerControl(index: number) {

    return this.submissionDataArray.at(index).get('answer');

  }

  /**
   * Return attachmentsData
   */
  getAttachmentsControl(answers: AbstractControl) {

    return answers.get('attachments');

  }

  /**
   * Creates a new instance of submission form
   */
  private get newSubmissionForm(): FormGroup {
    return this.fb.group({
      submissionData: this.fb.array([])
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Saves or updates the survey submission details
   */
  saveSubmission() {
    const payload = this.prepareSubmissionPayload(this.submissionDataArray.value);

    if (this.submissionDetails.submissionId) {
      this.updateSubmission(payload, this.submissionDetails.submissionId);
    } else {
      this.newSubmission(payload);
    }

  }


  /**
   * Removes the image from the particular answer
   */
  removeAttachment(answerIndex: number, imageIndex: number): void {

    const answers = this.getAnswerControl(answerIndex);

    const attachment = this.getAttachmentsControl(answers);

    const attachments: AttachmentList = attachment.value || [];

    attachments.splice(imageIndex, 1);

    attachment.patchValue(attachments);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and Protected interfacing methods
  // --------------------------------------------------------

  /**
   * Prepares survey submission payload
   */
  protected prepareSubmissionPayload(formValue: any) {

    const parentPayload = this.extraPayload || {};

    return {
      submissionData: formValue,
      ...parentPayload,
    };
  }


  protected submissionHandler(res: KalgudiSurveySubmission): void {
    this.notification.showMessage('Updated successfully');
    if (this.activatedRoute) {
      const surveyId = this.activatedRoute.snapshot.params.pageId;
      this.router.navigate([`app/home/pages/${surveyId}/d/surveys`])
    }
    this.submissionDetails = res;
  }

  /**
   * Calls stream api method to get submission
   * @param offset
   * @param limit
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.surveySubmissionService.getSubmissions(this.surveyId, this.userProfileKey || '')
      .pipe(
        map(res => ({ items: res.results, count: res.count })),

        tap(res => this.convertJsonIntoFormControl(res.items)),

        tap(res => this.submissionDetails = res.items[0])

      );
  }


  /**
   * Converting the json to form controls
   */
  private convertJsonIntoFormControl(submissions: KalgudiSurveySubmission[]) {

    // Init submission form
    this.submissionForm = this.newSubmissionForm;

    submissions[0].submissionData.forEach(element => {

      const data = this.submissionFormGroup(element);

      data.patchValue(element);

      this.submissionDataArray.push(data);

      if (this.disableSubmissionForm || this.disableActions) {
        this.submissionDataArray.disable();
      } else {
        this.submissionDataArray.enable();
      }

    });

  }

  /**
   * Showing check boxes as selected while editing the survey
   */
  isChecked(selectedValues: string, optionValue: string): boolean {
    if (selectedValues) {
      const selectedArray = selectedValues.split(';');
      return selectedArray.includes(optionValue);
    }
    return false;
  }

  /**
   * creates a form group for the submission list
   */
  private submissionFormGroup(value: KalgudiSubmissionData): FormGroup {
    return this.fb.group({
      answer: this.fb.group({
        attachments: new FormControl(value.answer && value.answer.attachments ? value.answer.attachments : []),
        value: [value.answer && value.answer.value ? value.answer.value : '']
      }),
      question: this.fb.group({
        question: [value.question.question || ''],
        questionId: [value.question.questionId || ''],
        attachments: new FormControl(value.question.attachments ? value.question.attachments : []),
        questionType: [value.question.questionType || ''],
        options: this.optionsArray(value.question.options ? value.question.options : [])
      })
    });
  }

  /**
   * Store check box selected values in the respective form controls
   */
  updateCheckedOptions(option: KalgudiSurveyOption, event: any, questionId: string) {

    const formArray = this.submissionForm.get('submissionData') as FormArray;
    const dataIndex = formArray.controls.findIndex(control =>
      control.get('question').get('questionId').value === questionId
    );

  if (dataIndex !== -1) {
    const checkboxControl = formArray.controls[dataIndex].get('answer').get('value') as FormControl;

    // Get current values array or initialize it if undefined
    let currentValues: string[] = checkboxControl.value ? checkboxControl.value.split(';') : [];

    if (event.checked) {
      // Add the selected option if not already present
      if (!currentValues.includes(option.value)) {
        currentValues.push(option.value);
      }
    } else {
      // Remove the unselected option
      currentValues = currentValues.filter(selectedOption => selectedOption !== option.value);
    }

    // Update the 'value' control directly
    checkboxControl.patchValue(currentValues.join(';'));
    }
  }

  /**
   * Getting index of the array
   * @param questionId
   * @returns
   */
  getAnswerControls(questionId: string): FormArray {
    let index: number;
    if (this.submissionDataArray && this.submissionDataArray.controls) {
      index = this.submissionDataArray.controls.findIndex((control: any) =>
        control.get('question.questionId').value === questionId
      );
    }
    return this.submissionDataArray.at(index).get('answer') as FormArray;

  }

  /**
   * creates a form array and related form group
   */
  private optionsArray(optionsList: KalgudiSurveyOption[] = []): FormArray {


    const options = this.fb.array([]);

    optionsList.forEach(i => {
      options.push(this.newMcqFormControl(i));
    });

    return options;

  }


  /**
   * Creates a new form group for option
   */
  private newMcqFormControl(value: KalgudiSurveyOption): FormGroup {
    const option = this.fb.group({
      title: [''],
      value: [value && value.value ? value.value : '', Validators.required]
    });

    return option;
  }


  /**
   * Post the submissions first time
   */
  private newSubmission(payload: KalgudiSurveySubmissionRequest) {

    this.progress = true;

    this.surveySubmissionService.postSubmissions(this.surveyId, payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.submissionHandler(res),
        err => this.util.errorHandler(err)
      );

  }


  /**
   * Updates the submissions
   */
  private updateSubmission(payload: KalgudiSurveySubmissionRequest, submissionId: string) {

    this.progress = true;

    this.surveySubmissionService.updateSubmissions(this.surveyId, submissionId, payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.submissionHandler(res),
        err => this.util.errorHandler(err)
      );

  }

  /**
   * Returns `true` if survey submission for should be disabled otherwise return `false`.
   */
  private canDisableSurveySubmission(survey: KalgudiSurveyDetails): boolean {

    const todaysDate = new Date().toISOString();

    return !(this.surveyDetails.surveyBasicDetails.isPublished && this.surveyDetails.surveyBasicDetails.expiryDate >= todaysDate);
  }

  /**
   * Event handler called on survey details update in the state
   */
  private onSurveyStateUpdate(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
    this.surveyId = survey.surveyId;
    this.disableSubmissionForm = this.canDisableSurveySubmission(survey);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}