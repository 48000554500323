<main>

  <section class="members-section">

    <ng-container *ngIf="(memberTargetingFilters$ | async) as filters">

      <kl-page-target-members
        [pageId]="pageId"
        [hideTargetAudienceCount]="true"
        [productsList]="filters?.products"
        [productsFormArray]="productsFilters"
        [organizationsList]="filters?.products"
        [organizationsFormArray]="productsFilters"
        [businessTypesList]="filters?.businessTypes"
        [businessTypesFormArray]="businessTypesFilters"
        [locationsList]="filters?.locations"
        [locationsFormArray]="locationsFilters"
        [countriesList]="filters?.countries"
        [countriesFormArray]="countriesFilters"
        [statesList]="filters?.states"
        [statesFormArray]="statesFilters"
        [districtsList]="filters?.districts"
        [districtsFormArray]="districtsFilters">

      </kl-page-target-members>
    </ng-container>
  </section>

  <div fxLayout="row" fxLayoutAlign="end end">
    <div fxLayoutAlign="end end" fxFlex="170px">
      <div fxFlex="100%">
        <kl-flat-button label="Target members" (clicked)="addMembers()"></kl-flat-button>
      </div>
    </div>
  </div>

</main>
