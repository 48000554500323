import { ControlPosition, ZoomControlOptions, ZoomControlStyle } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import { LatLong } from '@kalgudi/types';

@Component({
  selector: 'kl-geo-multi-locations',
  templateUrl: './geo-multi-locations.component.html',
  styleUrls: ['./geo-multi-locations.component.scss']
})
export class GeoMultiLocationsComponent implements OnInit {

  @Input()
  zoomControlOptions: ZoomControlOptions = {
    style: ZoomControlStyle.SMALL,
    position: ControlPosition.RIGHT_BOTTOM
  };

  // default zoom
  @Input()
  zoom: number = 7;

  @Input()
  allLocations: any;

  // Default IndiaFetch all locations
  readonly DEFAULT_LOCATION: LatLong = {
    latitude: 18.1124,
    longitude: 79.0192,
  };

  constructor() {}

  ngOnInit(): void {
  }

}
