<section class="clone-survey">
  <form autocomplete="off" [formGroup]="cloneSurveyForm" fxLayout="column">
    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label translate>Survey title</mat-label>
        <input  matInput required formControlName="title" [placeholder]="'Survey title' | translate" />
        <mat-error translate>Please enter survey title</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex>
      <mat-form-field appearance="outline" class="w-100" >
        <mat-label translate>Expires on</mat-label>
        <input matInput
          [placeholder]="'Expires on' | translate"
          formControlName="expiryDate"
          [matDatepicker]="startDate"
          (click)="startDate.open()"
          readonly
          [min]="surveyExpirationMinDate">
        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
        <mat-datepicker #startDate></mat-datepicker>
        <mat-error translate>Please enter expiry date</mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <mat-checkbox formControlName="published"><span translate>Published</span>
      </mat-checkbox>

      <mat-checkbox formControlName="addMembers"><span translate>Add Members</span>
      </mat-checkbox>
    </div>

    <div class="submit-btn">
      <kl-flat-button
      [type]="'submit'"
      [label]="'Create'"
      [progress]="progress"
      [disabled]="cloneSurveyForm.get('title').invalid"
      (clicked)="cloneSurvey()">
    </kl-flat-button>
    </div>
  </form>
</section>
