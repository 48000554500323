import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiAudioAttachmentModule,
  KalgudiChatsModule,
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
  KalgudiSocialSharesModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import {
  FailedHttpResponseInterceptorService,
  KalgudiLazyHttpClientModule,
  LAZY_HTTP_INTERCEPTOR,
} from '@kalgudi/common/lazy-http';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialModule } from '@kalgudi/social';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonQaFormComponent } from './components/common-qa-form/common-qa-form.component';
import { MobileQaFormComponent } from './components/mobile-qa-form/mobile-qa-form.component';
import { PopularQuestionsComponent } from './components/popular-questions/popular-questions.component';
import { QaAnswerTileComponent } from './components/qa-answer-tile/qa-answer-tile.component';
import { QaAnswersListComponent } from './components/qa-answers-list/qa-answers-list.component';
import { QaAnswersComponent } from './components/qa-answers/qa-answers.component';
import { QaChatsPageComponent } from './components/qa-chats-page/qa-chats-page.component';
import { QaCommentTileComponent } from './components/qa-comment-tile/qa-comment-tile.component';
import { QaCommentsListComponent } from './components/qa-comments-list/qa-comments-list.component';
import { QaCommentsComponent } from './components/qa-comments/qa-comments.component';
import { QaFormComponent } from './components/qa-form/qa-form.component';
import { QaHeaderComponent } from './components/qa-header/qa-header.component';
import { QaStreamComponent } from './components/qa-stream/qa-stream.component';
import { QaTileComponent } from './components/qa-tile/qa-tile.component';
import { SimilarQuestionsComponent } from './components/similar-questions/similar-questions.component';
import { KalgudiQaApiService } from './services/kalgudi-qa-api.service';
import { KalgudiQaStateService } from './services/kalgudi-qa-state.service';
import { KalgudiQaService } from './services/kalgudi-qa.service';

@NgModule({
  declarations: [
    QaFormComponent,
    QaTileComponent,
    QaAnswersComponent,
    QaAnswersListComponent,
    QaAnswerTileComponent,
    QaCommentTileComponent,
    QaCommentsListComponent,
    QaCommentsComponent,
    QaStreamComponent,
    QaHeaderComponent,
    CommonQaFormComponent,
    MobileQaFormComponent,
    PopularQuestionsComponent,
    SimilarQuestionsComponent,
    QaChatsPageComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,


    // Material
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatBadgeModule,
    MatTooltipModule,
    MatChipsModule,
    MatSelectModule,

    TranslateModule,
    AngularSvgIconModule,

    // Flex
    FlexLayoutModule,

    // Kalgudi
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiImagePickerModule,
    KalgudiSocialModule,
    KalgudiImageChipListModule,
    KalgudiUsersModule,
    KalgudiDialogsModule,
    MobileMenuBottomSheetModule,
    KalgudiLazyHttpClientModule,
    KalgudiNoContentModule,
    KalgudiSocialSharesModule,
    KalgudiChatsModule,
    KalgudiAudioAttachmentModule,


    // Kalgudi third party libraries
    KalgudiPhotoswipeModule,
    KalgudiGoogleLanguageTranslatorModule,
  ],
  exports: [
    QaStreamComponent,
    QaFormComponent,
    QaTileComponent,
    QaAnswersComponent,
    CommonQaFormComponent,
    QaHeaderComponent,
    MobileQaFormComponent,
    PopularQuestionsComponent,
    SimilarQuestionsComponent,
    QaChatsPageComponent
  ],
  providers: [
    KalgudiQaService,
    KalgudiQaApiService,
    KalgudiQaStateService,

    {
      provide: LAZY_HTTP_INTERCEPTOR,
      useClass: FailedHttpResponseInterceptorService
    }
  ],
  entryComponents: [
    // KalgudiUploadImageDialogComponent,
    // KalgudiInputDialogComponent,
    // ConfirmDialogComponent,
    MobileQaFormComponent
  ]
})
export class KalgudiQaModule {
  public static forChild(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiQaModule> {

    return {
      ngModule: KalgudiQaModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
