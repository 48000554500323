import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialogResult } from '@kalgudi/types';


/**
 * Base class for all Kalgudi dialog. A dialog will either emit data back
 * its owner or simply close the dialog without emitting any data back to
 * the parent.
 *
 * All dialogs created must extend to this class.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiDialog<T> {

  constructor(
    protected dialogRef: MatDialogRef<T>
  ) { }

  /**
   * It must call the `closeDialog()` method passing the dialog
   * result.
   */
  abstract ok(): void;

  /**
   * It must call the `closeDialog()` method passing the dialog
   * result.
   */
  abstract cancel(): void;

  /**
   * Closes the dialog emitting out the
   */
  closeDialog(dialogResult: KalgudiDialogResult): void {
    this.dialogRef.close(dialogResult);
  }
}
