import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiUpdateFpoBankingDetails } from '../../../classes/kalgudi-update-fpo-banking-details';

@Component({
  selector: 'kl-update-fpo-banking-details',
  templateUrl: './update-fpo-banking-details.component.html',
  styleUrls: ['./update-fpo-banking-details.component.scss']
})
export class UpdateFpoBankingDetailsComponent extends KalgudiUpdateFpoBankingDetails implements OnInit {

  s3Category: any;
  constructor(protected injector: Injector) {

    super(injector)
  }

  ngOnInit() {
    if(this.profileDetails.fpoAdditionalDetails  &&  this.profileDetails.fpoAdditionalDetails.fpoBankingDetails) {
      this.fpoBankingDetailsForm.patchValue(this.profileDetails.fpoAdditionalDetails.fpoBankingDetails);
    }

    this.bankAttachmentFormControl.patchValue('');
  }

  onDestroyed(): void {}

}
