<main id="common-details">
  <div class="container px-3 pt-3 mobile-wrapper">
    <div *ngIf="!fields" fxLayoutAlign="center center" class="pb-2 h-100 no-content">
      <kl-no-content progressText="Loading..." iconColor="rgba(0, 0, 0, 0.459)" [progress]="true">
      </kl-no-content>
    </div>

    <form autocomplete="off" class="mx-auto common-info" [formGroup]="taskForm" *ngIf="fields" fxLayout="column" fxLayoutGap="10px">

      <formly-form [model]="model" [fields]="fields" [options]="options" [form]="taskForm"></formly-form>
    </form>
  </div>

</main>
