import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiHomeStreamStateService } from '@kalgudi/social';
import { KalgudiDialogConfig, KalgudiDialogResult, NotificationsList } from '@kalgudi/types';

@Component({
  selector: 'kl-re-share-update-dialog',
  templateUrl: './re-share-update-dialog.component.html',
  styleUrls: ['./re-share-update-dialog.component.scss']
})
export class ReShareUpdateDialogComponent extends KalgudiDialog<ReShareUpdateDialogComponent> implements OnInit {

  constructor(
    protected dialogRef: MatDialogRef<ReShareUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    protected homeStreamState: KalgudiHomeStreamStateService,
    private util: KalgudiUtilityService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * On re sharing of post
   */
  onPostCreated(event: any): void {
    this.ok();
    this.pushItemToHomeStream(event);
    this.util.scrollToTop('smooth', 'kalgudi-core-content');
  }

  /**
   * Pushes latest posted item to the stream
   * @param val
   */
  private pushItemToHomeStream(val: NotificationsList): void {
    this.homeStreamState.unshiftToStream(val);
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };

    this.closeDialog(result);
  }
}
