import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, ProgramStreamResponse, StreamUrls } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProgramQaApiService {

  /**
   * `v1/stream/programQAStream`
   */
  private readonly API_STREAM = `${this.env.restBaseUrl}/stream/programQAStream`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private datePipe: DatePipe
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches program QA stream from API.
   *
   * @param entityId Program/Page id for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchStream(entityId: string, offset: number, limit: number, fromDate?: string, toDate?: string): Observable<StreamUrls[]> {

    let params: any = {
      entityId,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    if (fromDate && toDate) {
      const formattedFromDate = this.formatDate(fromDate);
      const formattedToDate = this.formatDate(toDate).replace('00:00:00','23:59:59');

      params = {
        ...params,
        fromDate: formattedFromDate,
        toDate: formattedToDate
      };
    }


    return this.httpClient.get<ApiResponseCommonV1>(this.API_STREAM, { params })
      .pipe(
        map(r => {
          // Handler API errors
          this.streamResponseHandler(r);

          // Map api response to object type
          const res = this.mapStreamResponse(r);

          // Map the response to the s3 urls array
          return res.entitiesStream;
        }),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Asserts stream response for any errors. Throws appropriate
   * error on failed API response.
   *
   * @param res QA stream API response
   */
  private streamResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Validate for error messages
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps API response to common stream urls array.
   */
  private mapStreamResponse(res: ApiResponseCommonV1): ProgramStreamResponse {

    return this.util.toJson<ProgramStreamResponse>(res.data);
  }

  formatDate(date) {
    const inputDate = new Date(date);
    const formattedDate = this.datePipe.transform(inputDate, 'yyyy-MM-dd HH:mm:ss');
    return formattedDate;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
