import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { UploadImageDialogComponent } from './components/upload-image-dialog/upload-image-dialog.component';
import { UploadImageMobileDialogComponent } from './components/upload-image-mobile-dialog/upload-image-mobile-dialog.component';
import { ImagePickerFormControlComponent } from './components/image-picker-form-control/image-picker-form-control.component';
import { CaptureImageFormControlComponent } from './components/capture-image-form-control/capture-image-form-control.component';
import { CaptureImageDialogComponent } from './components/capture-image-dialog/capture-image-dialog.component';
import { CaptureImageMobileDialogComponent } from './components/capture-image-mobile-dialog/capture-image-mobile-dialog.component';
import { UploadImageButtonComponent } from './components/upload-image-button/upload-image-button.component';
import { ImagesListComponent } from './components/images-list/images-list.component';
import { UploadImageCompactViewComponent } from './components/upload-image-compact-view/upload-image-compact-view.component';
import { CameraFormControlComponent } from './components/camera-form-control/camera-form-control.component';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiNoContentModule } from '../kalgudi-no-content/kalgudi-no-content.module';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiImageChipListModule } from '../kalgudi-image-chip-list/kalgudi-image-chip-list.module';
import { TranslateModule } from '@ngx-translate/core';
import { KalgudiImagePickerService } from './services/kalgudi-image-picker.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AttachImageComponent } from './components/attach-image/attach-image.component';



@NgModule({
  declarations: [
    UploadImageComponent,
    UploadImageDialogComponent,
    UploadImageMobileDialogComponent,
    ImagePickerFormControlComponent,
    CaptureImageFormControlComponent,
    CaptureImageDialogComponent,
    CaptureImageMobileDialogComponent,
    UploadImageButtonComponent,
    ImagesListComponent,
    UploadImageCompactViewComponent,
    CameraFormControlComponent,
    AttachImageComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,

    // Svg
    AngularSvgIconModule,

    // Material
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,

    FlexLayoutModule,

    //Kalgudi libraries
    KalgudiButtonModule,
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiImageChipListModule,

    TranslateModule
  ],
  exports: [
    ImagePickerFormControlComponent,
    UploadImageComponent,
    ImagesListComponent,
    UploadImageCompactViewComponent,
    CameraFormControlComponent
  ],
  entryComponents: [
    UploadImageDialogComponent,
    UploadImageMobileDialogComponent,
    CaptureImageDialogComponent,
    CaptureImageMobileDialogComponent,
    UploadImageComponent
  ],
  providers: [
    KalgudiImagePickerService
  ]
})
export class KalgudiImagePickerModule { }
