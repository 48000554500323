<div fxLayout="column" fxLayoutGap="20px" class="social-stats w-100">

  <!-- Share stats -->
  <mat-card class="cursor-pointer" (click)="openPageUpdates()">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <mat-icon @scaleOut class="share-icon">
        format_quote
      </mat-icon>
      <h2 class="m-0" translate>Shares</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="shareView"
        [scheme]="shareColorScheme"
        [results]="shareStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showShareLabels"
        [doughnut]="isShareDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 total">{{ totalStatsCount | number}}</p>
          <p class="mb-0 label" translate>Shares</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="totalStatsCount === null"></kl-fake-it-block>
  </mat-card>

  <!-- / Share stats -->

  <!-- Qa stats -->
  <mat-card class="cursor-pointer" (click)="openPageQa()">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <mat-icon @scaleOut class="qa-icon">
        help_outline
      </mat-icon>
      <h2 class="m-0" translate>Questions</h2>
    </div>

    <!-- Graph -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxFlex="35%" fxFlex.xs="45%" class="border-right" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="qaStats && qaStats[0]">
          <h3 class="m-0 value" [ngStyle]="{ 'color': qaColorScheme.domain[0] }">{{ qaStats[0]?.value }}</h3>
          <p class="m-0 title" translate> Total </p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="qaStats && qaStats[1]">
          <h3 class="m-0 value" [ngStyle]="{ 'color': qaColorScheme.domain[1] }">{{ qaStats[1]?.value }}</h3>
          <p class="m-0 title" translate>Replied</p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="qaStats && qaStats[2]">
          <h3 class="m-0 value" [ngStyle]="{ 'color': qaColorScheme.domain[2] }">{{ qaStats[2]?.value }}</h3>
          <p class="m-0 title" translate>Not replied</p>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
        <ngx-charts-pie-chart
          [view]="view"
          [scheme]="qaColorScheme"
          [results]="qaStats"
          [gradient]="gradient"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="showQaLabels"
          [doughnut]="isDoughnut">
        </ngx-charts-pie-chart>
      </div>

    </div>
    <!-- / Graph -->

    <kl-fake-it-block *ngIf="!qaStats"></kl-fake-it-block>
  </mat-card>
  <!-- /Qa stats -->

</div>
