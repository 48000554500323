import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult, ScheduleDates } from '@kalgudi/types';

@Component({
  selector: 'kl-schedule-send-mobile-dialog',
  templateUrl: './schedule-send-mobile-dialog.component.html',
  styleUrls: ['./schedule-send-mobile-dialog.component.scss']
})
export class ScheduleSendMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  scheduledDates: ScheduleDates;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }


  onDateSelection(dates: any) {

    this.scheduledDates = dates;

    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        scheduledDates: this.scheduledDates
      }
    };
    this.dialogRef.close(result);
  }

}
