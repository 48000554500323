import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult, KalgudiProjectTask, PartialData } from '@kalgudi/types';

@Component({
  selector: 'kl-task-submission-mobile-dialog',
  templateUrl: './task-submission-mobile-dialog.component.html',
  styleUrls: ['./task-submission-mobile-dialog.component.scss']
})
export class TaskSubmissionMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  taskId: string;
  projectId: string;
  taskDetails: KalgudiProjectTask;
  submissionDetails: KalgudiProjectTask;
  extraParams: PartialData;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.taskId            = this.dialogData.data.taskId;
    this.projectId         = this.dialogData.data.projectId;
    this.taskDetails       = this.dialogData.data.taskDetails;
    this.submissionDetails = this.dialogData.data.submissionDetails;
    this.extraParams       = this.dialogData.data.extraParams || {};
  }


  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }


}
