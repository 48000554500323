<div class="page-task-members" fxLayout="column">

  <mat-tab-group [(selectedIndex)]="activeTabIndex" mat-stretch-tabs>
    <mat-tab [label]="TABS?.TARGET_MEMBERS?.title  | translate">
      <div class="mt-3" *ngIf="activeTabIndex === TABS?.TARGET_MEMBERS?.index">

        <div fxLayout="row" fxLayoutGap="12px" class="mb-3 heading">

          <mat-form-field appearance="outline">
            <mat-label translate>Select target members</mat-label>
            <mat-select [formControl]="targetMembersType" (selectionChange)="onTargetTypeSelectionChanged($event)">
              <mat-option *ngFor="let members of projectMembers" [value]="members?.id">
                {{ members.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="border-bottom"></div>

        <div fxLayout="column" *ngIf="(stream$ | async) as users" class="task-members overflow-auto mt-3">

          <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-2">
            <div *ngFor="let user of users;" fxFlex="48%" fxFlex.xs="100%" class="mb-3">
              <div>
                <kl-selectable-user-tile [author]="user" avatarSize="md">
                </kl-selectable-user-tile>
              </div>
            </div>
          </div>

          <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

            <ng-container *ngIf="!users || users?.length <= 0">
              <kl-no-content
                [progress]="paginator?.loading"
                progressText="Loading..."
                text="Members not yet add to this project"
                matIcon="person_add">
              </kl-no-content>
            </ng-container>

            <!-- Load more -->
            <div class="mt-3" *ngIf="paginator && users?.length">
              <kl-load-more
                [noMoreText]="'No more users' | translate"
                [progress]="paginator.loading"
                [hasMoreItems]="paginator.hasItems"
                (clicked)="nextPage()">
              </kl-load-more>
            </div>
            <!-- /Load more -->
          </div>

        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="TABS?.BULK_MEMBERS?.title | translate"  [disabled]="isEnableBulkOption">
      <div class="mt-3 bulk-members" *ngIf="activeTabIndex === TABS?.BULK_MEMBERS?.index">
        <kl-bulk-members-addition (loginIdsList)="addBulkMembers($event)"></kl-bulk-members-addition>
      </div>
    </mat-tab>

  </mat-tab-group>


</div>
