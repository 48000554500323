<form autocomplete="off" id="kalgudi-common-qa-form" [formGroup]="shareForm" name="shareDetails">
  <div fxLayout="column" class="wrapper-main" *ngIf="!compactView">
    <div fxLayout="row" fxFlex.xs="100%" fxLayoutGap="15px">
      <!-- <div fxFlex="8%" fxFlex.xs="13%" fxHide.xs>
          <img class="imgDp">
        </div> -->
      <div fxFlex fxLayout="column" fxLayoutGap="10px" class="text-area-wrapper">
        <mat-form-field appearance="outline" class="update-form">
          <textarea matInput
            id="question-field"
            [placeholder]="textFieldPlaceHolder | translate"
            [rows]="rowsCount"
            formControlName="text">
          </textarea>
        </mat-form-field>
      </div>
    </div>

    <!-- Page selection -->
    <div *ngIf="(isAppToDisplay || environment.appId === 'UBM') && showPageSelection">
      <mat-form-field appearance="outline" class="update-form w-100">
        <mat-select [placeholder]="'Select page' | translate" [disabled]="!this.shareForm.get('text').value" (selectionChange)="selectPage($event)">
          <mat-option *ngFor="let page of pageList" [title]="page?.pageTitle + ' - ' + (page?.createdBy?.firstName)" [value]="page">
            <div class="text-secondary">{{ page?.pageTitle | titlecase }}</div>
            <div>
              <small>Owner: <span class="text-success">{{ page?.createdBy?.firstName }}</span></small>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--/ Page selection -->

    <!-- Attachments -->

    <kl-image-chip-list [attachments]="attachments" (remove)="removeImage($event)">
    </kl-image-chip-list>
    <!-- / Attachments -->

    <!-- Audio attachment -->
     <div class="my-2"  id="audioChip" *ngIf="audioAttachment?.value?.url">
      <mat-chip-list>
        <mat-chip (click)="showAudioDialog()" fxLayoutAlign="center center">
          <div class="audio-icon">
            <mat-icon color="primary">mic</mat-icon>
          </div>
          <div class="ml-1">Attached voice message</div>
          <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <!--/ Audio attachment -->

    <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
      <div>

        <button mat-button
          class="attach-files"
          id="attach-files-button"
          color="primary"
          [matMenuTriggerFor]="menu">
          <mat-icon>attachment</mat-icon>
          <span class="ml-1" translate>Attach</span>
        </button>



        <mat-menu #menu="matMenu" class="attach-file">
          <ng-container>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>File</p>
                </div>

                <div fxLayout="row">

                  <kl-image-picker-form-control
                    formControlName="lstOfAttachments"
                    [showBorder]="false"
                    [buttonType]="'mat-icon-button'"
                    svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" [ngClass]="{'attach-menu': audioAttachment}">
                <div>
                  <p class="m-0 title" translate>Image</p>
                </div>

                <div fxLayout="row">
                  <div [ngClass]="{'mt-2 camera': !audioAttachment}">
                    <kl-camera-form-control
                      fxFlex="95%"
                      [formControl]="cameraAttachment"
                      [showChipButton]="true"
                      [showIconButton]="true"
                      [showBorder]="false"
                      buttonIcon="camera_alt"
                      buttonLabel="Camera"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-camera-form-control>
                  </div>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                </div>
              </div>
            </div>


            <div mat-menu-item class="attach-file-mat-menu" *ngIf="audioAttachment">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                <div>
                  <p class="m-0 title" translate>Voice</p>
                </div>

                <div fxLayout="row">

                  <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                    class="rounded-pill mt-1" title="Attach image" type="button"
                    [matTooltip]="'Attach audio' | translate"
                    (click)="showAudioDialog()"
                    mat-icon-button>
                    <div class="label-icon" fxLayout fxLayoutAlign="center center">
                      <mat-icon class="m-0 camera-icon">mic</mat-icon>
                    </div>
                  </button>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedAudioFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                </div>
              </div>
            </div>


          </ng-container>
        </mat-menu>

      </div>
      <div class="share-btn mt-2" fxLayout="row" fxLayoutAlign="end end">
        <kl-flat-button class="submit-btn"
          [label]="submitBtnText"
          [disabled]="(isAppToDisplay) ? ((!shareFormText.value) || (pageList && pageList.length ? !page : '')) : (!shareFormText.value)"
          [progress]="progress"
          (clicked)="postQa()">
        </kl-flat-button>
      </div>
    </div>

  </div>

  <div *ngIf="compactView">
    <!-- <p class="mb-0 text-danger" translate>
      Upload plant/crop photos to understand your problem better
    </p>

    <kl-upload-image-compact-view
      [showAddOnLast]="true"
      [accept]="acceptedFileTypes | joinArray: ','"
      [maxImages]="3"
      [multiple]="true"
      [s3Category]="s3Category"
      [showCamera]="showCamera"
      [showDisabledFrames]="true"
      formControlName="lstOfAttachments">
    </kl-upload-image-compact-view> -->

    <div>
      <mat-form-field appearance="outline" class="update-form w-100">
        <textarea matInput
          [placeholder]="textFieldPlaceHolder | translate"
          [rows]="rowsCount"
          formControlName="text">
        </textarea>
      </mat-form-field>
    </div>

    <!-- Page selection -->
    <div *ngIf="(environment.appId === 'FPO_APP' || environment.appId === 'FARMERS_APP' || environment.appId === 'SAM_FPO' || environment.appId === 'FPO_NET_APP') && showPageSelection">
      <mat-form-field appearance="outline" class="update-form w-100">
        <mat-select placeholder="Select page" [disabled]="!this.shareForm.get('text').value" (selectionChange)="selectPage($event)">
          <mat-option *ngFor="let page of pageList" [value]="page">
            {{ page?.pageTitle }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!--/ Page selection -->

    <!-- Attachments -->

    <kl-image-chip-list [attachments]="attachments" (remove)="removeImage($event)">
    </kl-image-chip-list>
    <!-- / Attachments -->


      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()" fxLayoutAlign="center center">
            <div class="audio-icon">
              <mat-icon color="primary">mic</mat-icon>
            </div>
            <div class="ml-1">Attached voice message</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->

    <div>

      <button mat-button
        class="attach-files"
        id="attach-files-button"
        color="primary"
        [matMenuTriggerFor]="menu">
        <mat-icon>attachment</mat-icon>
        <span class="ml-1" translate>Attach</span>
      </button>



      <mat-menu #menu="matMenu" class="attach-file">
        <ng-container>

          <div mat-menu-item class="attach-file-mat-menu">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
              <div>
                <p class="m-0 title" translate>File</p>
              </div>

              <div fxLayout="row">

                <kl-image-picker-form-control
                  formControlName="lstOfAttachments"
                  [showBorder]="false"
                  [buttonType]="'mat-icon-button'"
                  svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                  [allowMultiple]="true"
                  [showAttachments]="false"
                  [s3Category]="s3Category"
                  [acceptedFileTypes]="acceptedFileTypes">
                </kl-image-picker-form-control>
              </div>
            </div>
          </div>

          <div mat-menu-item class="attach-file-mat-menu">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
              <div>
                <p class="m-0 title" translate>Image</p>
              </div>

              <div fxLayout="row">
                <div>
                  <kl-camera-form-control
                    fxFlex="95%"
                    [formControl]="cameraAttachment"
                    [showChipButton]="true"
                    [showIconButton]="true"
                    [showBorder]="false"
                    buttonIcon="camera_alt"
                    buttonLabel="Camera"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedImageFileTypes">
                  </kl-camera-form-control>
                </div>
                <div>
                  <span class="mx-2">|</span>
                </div>
                <div>
                  <kl-image-picker-form-control
                    formControlName="lstOfAttachments"
                    [showBorder]="false"
                    [buttonType]="'mat-icon-button'"
                    svgButtonIcon="assets/svgs/attach-file/images.svg"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedImageFileTypes">
                  </kl-image-picker-form-control>
                </div>

              </div>
            </div>
          </div>


          <div mat-menu-item class="attach-file-mat-menu" *ngIf="audioAttachment">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" >
              <div>
                <p class="m-0 title" translate>Voice</p>
              </div>

              <div fxLayout="row">

                <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                  class="rounded-pill mt-1" title="Attach image" type="button"
                  [matTooltip]="'Attach audio' | translate"
                  (click)="showAudioDialog()"
                  mat-icon-button>
                  <div class="label-icon" fxLayout fxLayoutAlign="center center">
                    <mat-icon class="m-0 camera-icon">mic</mat-icon>
                  </div>
                </button>
                <div>
                  <span class="mx-2">|</span>
                </div>
                <div>
                  <kl-image-picker-form-control
                    formControlName="lstOfAttachments"
                    [showBorder]="false"
                    [buttonType]="'mat-icon-button'"
                    svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedAudioFileTypes">
                  </kl-image-picker-form-control>
                </div>

              </div>
            </div>
          </div>


        </ng-container>
      </mat-menu>

    </div>


  <!-- <div>
      <strong class="mb-0 text-danger" *ngIf="!shareForm.get('lstOfAttachments')?.value?.length" translate>Please upload photos to understand your problem better</strong>
    </div> -->

    <div class="share-btn mt-2" fxLayout="row" fxLayoutAlign="center center">
      <kl-flat-button
        class="submit-btn w-100"
        label="Submit question"
        [disabled]="(environment.appId === 'FPO_APP' || environment.appId === 'FARMERS_APP' || environment.appId === 'SAM_FPO' || environment.appId === 'FPO_NET_APP') ? ((!shareFormText.value && !audioAttachment?.value?.url) || (pageList && pageList.length ? !page : '')) : (!shareFormText.value && !audioAttachment?.value?.url)"
        [progress]="progress"
        (clicked)="postQa()">
      </kl-flat-button>
    </div>
  </div>
</form>
