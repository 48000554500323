import { Component, Inject, Input, OnInit } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSurveyDetails } from '@kalgudi/types';

import { KalgudiSocialRouteConfig } from '../../../config';

@Component({
  selector: 'kl-survey-tile',
  templateUrl: './kalgudi-survey-tile.component.html',
  styleUrls: ['./kalgudi-survey-tile.component.scss']
})
export class KalgudiSurveyTileComponent implements OnInit {

  @Input()
  surveyDetails: KalgudiSurveyDetails;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private socialRouteConfig: KalgudiSocialRouteConfig
  ) { }

  ngOnInit() {
  }

  /**
   * Navigate to survey fullview
   * @param surveyId
   */
  toSurveyFullView(surveyId: string) {
    this.socialRouteConfig.toSurveySubmission({ surveyId });
  }

  /**
   * Navigates to profile page
   * @param profileKey
   */
  viewProfile(profileKey: string): void {
    this.socialRouteConfig.toProfile({ profileKey });
  }
}
