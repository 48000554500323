import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FpoProfiles } from '../../../classes/fpo-profiles';
import { OrganisationProfileService } from '../../../services/organisation-profile.service';

@Component({
  selector: 'kl-business-activities-form',
  templateUrl: './business-activities-form.component.html',
  styleUrls: ['./business-activities-form.component.scss']
})
export class BusinessActivitiesFormComponent extends FpoProfiles implements OnInit {
  optionsList = ["option1", "option2", "option3"];

  financialYears: any[] = [
    { id: '2022-2023', value: '2022-2023' },
    { id: '2021-2022', value: '2021-2022' },
    { id: '2020-2021', value: '2020-2021' },
    { id: '2019-2020', value: '2019-2020' },
    { id: '2018-2019', value: '2018-2019' }
  ];

  inputShop = [
    { id: 'YES', value: 'Yes' },
    { id: 'NO', value: 'No' }
  ];

  isSelected = [
    { id: 'YES', value: 'Yes' },
    { id: 'NO', value: 'No' }
  ];

  alliedActivities: any[] = [
    { id: 'CATTLE', value: 'Cattle' },
    { id: 'POULTRY', value: 'Poultry' },
    { id: 'PIGGERY', value: 'Piggery' },
    { id: 'FISHERIES', value: 'Fisheries' },
    { id: 'GOATERY', value: 'Goatery' },
    { id: 'OTHERS', value: 'Others' },
    { id: 'AGRO_FORESTRY', value: ' Agro forestry' }
  ]

  editInputYear: any;
  editOutputYear: any;
  editOtherYear: any;
  editCHCYear: any;
  editLendingYear: any;

  isShowQuantity: boolean;
  isShowInputRevenue: boolean;
  isShowChcShop: boolean;
  isShowChcRevenue: boolean;
  isShowOtherRevenue: boolean;

  lendingAmountValue: any;

  isShowConsumerValue: boolean;
  isShowCertifiedValue: boolean;
  isShowWarehouseValue: boolean;
  isShowColdStorageValue: boolean;
  isShowExportImportValue: boolean;
  isShowAlliedValue: boolean;

  inputConsumerProductsRevenue: any;
  inputCertifiedProductionRevenue: any;
  inputExportsOrImportsRevenue: any;
  inputAlliedActivitiesRevenue: any;

  inputBusinessActivityRevenue: any;
  isEditInputBusinessActivities: boolean;
  editInputBusinessActivitiesIndex: number;

  outputBusinessActivityRevenue: any
  isEditOutputBusinessActivities: boolean;
  editOutputBusinessActivitiesIndex: number;

  otherBusinessActivityRevenue: any;
  isEditOtherBusinessActivities: boolean;
  editOtherBusinessActivitiesIndex: number;

  chcRevenueValue: any;
  isEditChcServices: boolean;
  editChcServicesIndex: number;

  isEditLending: boolean;
  editLendingIndex: number;

  isEditConsumer: boolean;
  editConsumerIndex: number;

  isEditCertified: boolean;
  editCertifiedIndex: number;

  isEditWarehouse: boolean;
  editWarehouseIndex: number;

  isEditColdStorage: boolean;
  editColdStorageIndex: number;

  isEditExportsOrImports: boolean;
  editExportsOrImportsIndex: number;

  isEditAllied: boolean;
  editAlliedIndex: number;
  isSaveEnable: boolean = false;

  @Input() isEditDialog: boolean;
  constructor(
    protected injector: Injector,
    private organizationProfileService: OrganisationProfileService
  ) {
    super(injector);

    this.editInputYear = this.financialYears;
    this.editOutputYear = this.financialYears;
    this.editOtherYear = this.financialYears;
    this.editCHCYear = this.financialYears;
    this.editLendingYear = this.financialYears;
  }

  ngOnInit(): void {
    this.inputValueCommaConverter();
  }

  ngDoCheck() {
    if ((this.inputDetails?.length || this.outputDetails?.length || this.otherDetails?.length ||
      this.chcDetails?.length || this.lendingDetails?.length || this.consumerDetails?.length ||
      this.certifiedDetails?.length || this.warehouseDetails?.length || this.coldStorageDetails?.length ||
      this.exportsImportsDetails?.length || this.alliedDetails?.length)) {
        this.isSaveEnable = true;
        this.isEditDialog = true;
      } else {
        this.isSaveEnable = false;
    }

    // Filtering the object which have 'YES' value in array to show in the table
    // for consumer, certified, warehouse, cold-storage, export-import, allied, and other-business activities
    this.consumerDetails = this.consumerDetails.filter(res => res.showConsumer === 'YES');
    this.certifiedDetails = this.certifiedDetails.filter(res => res.showCertified === 'YES');
    this.warehouseDetails = this.warehouseDetails.filter(res => res.showWarehouse === 'YES');
    this.coldStorageDetails = this.coldStorageDetails.filter(res => res.showColdStorage === 'YES');
    this.exportsImportsDetails = this.exportsImportsDetails.filter(res => res.showImportsOrExports === 'YES');
    this.alliedDetails = this.alliedDetails.filter(res => res.showAllied === 'YES');
    this.otherDetails = this.otherDetails.filter(res => res.otherBusiness === 'YES');
  }

  ngOnChanges() {

    // Create an array containing all the details arrays to filter
    const detailsToFilter = [this.inputDetails, this.outputDetails, this.otherDetails, this.chcDetails, this.lendingDetails];

    // Set a timeout to run the filtering process after 1 second;
    setTimeout(() => {
      const editYears = detailsToFilter.map(details => this.filterFinancialYears(details));

      // Destructuring assignment to assign the filtered results to respective properties
      [this.editInputYear, this.editOutputYear, this.editOtherYear, this.editCHCYear, this.editLendingYear] = editYears;
    }, 1000);
  }

  onDestroyed(): void { }

  /**
   * Show the required field based on selection change
   */
  changeIsSelected(event: any, label: string) {

    switch (label) {
      case 'InputShop':
        this.isShowQuantity = event.value === 'YES';
        this.getQuantity.reset();
        break;
      case 'CHCShop':
        this.isShowChcShop = event.value === 'YES';
        this.chcQuantity.reset();
        this.chcBusinessYear.reset();
        this.chcBusinessRevenue.reset();
        break;
      case 'OtherShop':
        this.isShowOtherRevenue = event.value === 'YES';
        this.otherActivities.reset();
        this.otherBusinessYear.reset();
        this.otherBusinessRevenue.reset();

        if(event.value === "NO") {
          const object = {
            activities: "",
            businessRevenue: "",
            businessYear: "",
            otherBusiness: "NO"
          }

          if(this.otherDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all other business details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.otherDetails = [];
                this.otherDetails.push(object);
              } else {
                this.otherBusinessShow.patchValue('YES');
              }
            });
          }
        }

        break;
      case 'Consumer':
        this.consumerProductsCommodity.reset();
        this.consumerProductsRevenue.reset();

        if(event.value === "NO") {
          const object = {
            consumerProductsCommodity: '',
            consumerProductsRevenue: '',
            showConsumer: 'NO'
          }

          if(this.consumerDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all consumer details are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.consumerDetails = [];
                this.consumerDetails.push(object);
              }
              else {
                this.showConsumer.patchValue('YES');
              }
            });
          }
        }

        break;
      case 'Certified':
        this.commodityStoredInWarehouses.reset();
        this.certifiedProductionRevenue.reset();

        if(event.value === "NO") {
          const object = {
            certifiedProductionCrop: '',
            certifiedProductionRevenue: '',
            showCertified: 'NO'
          }

          if(this.certifiedDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all certified details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.certifiedDetails = [];
                this.certifiedDetails.push(object);
              }
              else {
                this.showCertified.patchValue('YES');
              }
            });
          }
        }

        break;
      case 'Warehouse':
        this.certifiedProductionsCrop.reset();
        this.capacityInWarehouses.reset();

        if(event.value === "NO") {
          const object = {
            capacityInWarehouse: "",
            commodityStoredInWarehouse: "",
            showWarehouse: "NO"
          }

          if(this.warehouseDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all warehouse details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.warehouseDetails = [];
                this.warehouseDetails.push(object);
              } else {
                this.showWarehouse.patchValue('YES');
              }
            });
          }
        }

        break;
      case 'ColdStorage':
        this.commodityStoredInColdStorages.reset();
        this.capacityInColdStorages.reset();

        if(event.value === "NO") {
          const object = {
            capacityInColdStorage: "",
            commodityStoredInColdStorage: "",
            showColdStorage: "NO"
          }

          if(this.coldStorageDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all cold storage details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.coldStorageDetails = [];
                this.coldStorageDetails.push(object);
              } else {
                this.showColdStorage.patchValue('YES');
              }
            });
          }
        }

        break;
      case 'ExportImport':
        this.exportOrImportCommodity.reset();
        this.exportOrImportRevenue.reset();

        if(event.value === "NO") {
          const object = {
            exportsOrImportsCommodity: "",
            exportsOrImportsRevenue: "",
            showImportsOrExports: "NO"
          }

          if(this.exportsImportsDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all export/import details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.exportsImportsDetails = [];
                this.exportsImportsDetails.push(object);
              } else {
                this.showImportsOrExports.patchValue('YES');
              }
            });
          }
        }
        break;
      case 'Allied':
        this.alliedActivitiesRevenues.reset();
        this.alliedActivitive.reset();

        if(event.value === "NO") {
          const object = {
            alliedActivities: "",
            alliedActivitiesRevenue: "",
            alliedOthers: "",
            showAllied: "NO"
          }

          if(this.alliedDetails.length) {
            let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Selecting NO will remove all allied details, are you sure you want to remove?');
            delConfirmed.then(confirmation => {
              if(confirmation.isConfirmed) {
                this.alliedDetails = [];
                this.alliedDetails.push(object);
              } else {
                this.showAllied.patchValue('YES');
              }
            });
          }
        }
        break;
      default:
        break;
    }

  }

  /**
   * Add input details to input details array when click happens
   */
  addInputDetails() {
    this.inputsGroup.value.quantity = parseInt(this.inputsGroup.value.quantity)
    this.inputsGroup.value.businessRevenue = this.inputsGroup.value.businessRevenue.replace(/,/g, '');
    if (!this.isEditInputBusinessActivities) {
      this.inputDetails.push(this.inputsGroup.value);
      this.editInputYear = this.filterFinancialYears(this.inputDetails);
    }
    else {
      this.inputDetails.splice(this.editInputBusinessActivitiesIndex, 1, this.inputsGroup.value);
      this.editInputYear = this.filterFinancialYears(this.inputDetails, this.financialYears, 'edit');
    }
    // Sorting financial year after adding
    this.inputDetails = this.sortFinancialYearsByYear(this.inputDetails, 'businessYear');
    this.isEditInputBusinessActivities = false;
    this.inputsGroup.reset();
    this.isShowQuantity = false;
  }

  /**
   * Add output data to output details array when click happens
   */
  addOutputDetails() {
    if (!this.isEditOutputBusinessActivities) {
      this.outputTopThreeBuyers.patchValue(this.outputTopThreeBuyers?.value);
      this.outputsGroup.value.businessRevenue = this.outputsGroup.value.businessRevenue.replace(/,/g, '');
      this.outputDetails.push(this.outputsGroup.value);
      this.editOutputYear = this.filterFinancialYears(this.outputDetails);
    }
    else {
      this.outputTopThreeBuyers.patchValue(this.outputTopThreeBuyers?.value);
      this.outputsGroup.value.businessRevenue = this.outputsGroup.value.businessRevenue.replace(/,/g, '');
      this.outputDetails.splice(this.editOutputBusinessActivitiesIndex, 1, this.outputsGroup.value);
      this.editOutputYear = this.filterFinancialYears(this.outputDetails, this.financialYears, 'edit');
    }
    // Sorting financial year after adding
    this.outputDetails = this.sortFinancialYearsByYear(this.outputDetails, 'businessYear');
    this.isEditOutputBusinessActivities = false;
    this.outputsGroup.reset();
  }

  /**
   * Add other data to other details array when click happens
   */
  addOtherDetails() {
    this.otherGroup.value.businessRevenue = this.otherGroup.value.businessRevenue.replace(/,/g,'');
    if(!this.isEditOtherBusinessActivities) {
      this.otherDetails.push(this.otherGroup.value);
      this.editOtherYear = this.filterFinancialYears(this.otherDetails)
    }
    else {
      this.otherDetails.splice(this.editOtherBusinessActivitiesIndex, 1, this.otherGroup.value)
      this.editOtherYear = this.filterFinancialYears(this.otherDetails, this.financialYears, 'edit');
    }
    // Sorting financial year after adding
    this.otherDetails = this.sortFinancialYearsByYear(this.otherDetails, 'businessYear');
    this.isEditOtherBusinessActivities = false;
    this.otherGroup.reset();
    this.isShowOtherRevenue = this.isCommoditySelected(this.otherDetails, this.otherBusinessShow);
  }

  /**
   * Add chc data to che details array when click happens
   */
  addChcDetails() {
    this.chcGroup.value.quantity = parseInt(this.chcGroup.value.quantity);
    this.chcGroup.value.businessRevenue = this.chcGroup.value.businessRevenue.replace(/,/g,'');
    if (!this.isEditChcServices) {
      this.chcDetails.push(this.chcGroup.value);
      this.editCHCYear = this.filterFinancialYears(this.chcDetails);
    }
    else {
      this.chcDetails.splice(this.editChcServicesIndex, 1, this.chcGroup.value)
      this.editCHCYear = this.filterFinancialYears(this.chcDetails, this.financialYears, 'edit');
    }
    // Sorting financial year after adding
    this.chcDetails = this.sortFinancialYearsByYear(this.chcDetails, 'businessYear');
    this.isEditChcServices = false;
    this.chcGroup.reset();
    this.isShowChcShop = false;
  }

  /**
   * Add lending data to lending details array when click happens
   */
  addLendingDetails() {
    this.lendingGroup.value.lendingAmount = this.lendingGroup.value.lendingAmount.replace(/,/g, '');
    if (!this.isEditLending) {
      this.lendingDetails.push(this.lendingGroup.value);
    }
    else {
      this.lendingDetails.splice(this.editLendingIndex, 1, this.lendingGroup.value);
    }
    // Sorting financial year after adding
    this.lendingDetails = this.sortFinancialYearsByYear(this.lendingDetails, 'year');
    this.isEditLending = false;
    this.lendingGroup.reset();
    this.editLendingYear = this.filterFinancialYears(this.lendingDetails, this.financialYears, 'lending');
  }

  /**
 * Add consumer to consumer details array when click happens
 */
  addConsumer() {
    this.consumerGroup.value.consumerProductRevenue = this.consumerGroup?.value?.consumerProductRevenue.replace(/,/g, '');
    if(!this.isEditConsumer) {
      this.consumerDetails.push(this.consumerGroup.value);
    }
    else {
      this.consumerDetails.splice(this.editConsumerIndex, 1, this.consumerGroup.value)
    }

    this.isEditLending = false;
    this.consumerGroup.reset();
    this.isShowConsumerValue = this.isCommoditySelected(this.consumerDetails, this.showConsumer);
  }

  /**
   * Add certified to certified details array when click happens
   */
  addCertified() {
    this.certifiedGroup.value.certifiedProductionRevenue = this.certifiedGroup.value.certifiedProductionRevenue.replace(/,/g, '');
    if(!this.isEditCertified) {
      this.certifiedDetails.push(this.certifiedGroup.value);
    }
    else {
      this.certifiedDetails.splice(this.editCertifiedIndex, 1, this.certifiedGroup.value)
    }
    this.isEditCertified = false;
    this.certifiedGroup.reset();
    this.isShowCertifiedValue = this.isCommoditySelected(this.certifiedDetails, this.showCertified);
  }

  /**
   * Add warehouse to warehouse details array when click happens
   */
  addWarehouse() {
    this.warehouseGroup.value.capacityInWarehouse = parseInt(this.warehouseGroup.value.capacityInWarehouse);
    if(!this.isEditWarehouse) {
      this.warehouseDetails.push(this.warehouseGroup.value);
    }
    else {
      this.warehouseDetails.splice(this.editWarehouseIndex, 1, this.warehouseGroup.value)
    }
    this.isEditWarehouse = false;
    this.warehouseGroup.reset();
    this.isShowWarehouseValue = this.isCommoditySelected(this.warehouseDetails, this.showWarehouse);
  }

  /**
   * Add cold storage to cold storage details array when click happens
   */
  addColdStorage() {
    this.coldStorageGroup.value.capacityInColdStorage = parseInt(this.coldStorageGroup.value.capacityInColdStorage);
    if(!this.isEditColdStorage) {
      this.coldStorageDetails.push(this.coldStorageGroup.value);
    }
    else {
      this.coldStorageDetails.splice(this.editColdStorageIndex, 1, this.coldStorageGroup.value)
    }
    this.isEditColdStorage = false;
    this.coldStorageGroup.reset();
    this.isShowColdStorageValue = this.isCommoditySelected(this.coldStorageDetails, this.showColdStorage);
  }

  /**
   * Add exports and imports to exports and imports details array when click happens
   */
  addExportsImports() {
    this.importsExportsGroup.value.exportsOrImportsRevenue = this.importsExportsGroup.value.exportsOrImportsRevenue.replace(/,/g,'');
    if(!this.isEditExportsOrImports) {
      this.exportsImportsDetails.push(this.importsExportsGroup.value);
    }
    else {
      this.exportsImportsDetails.splice(this.editExportsOrImportsIndex, 1, this.importsExportsGroup.value)
    }
    this.isEditExportsOrImports = false;
    this.importsExportsGroup.reset();
    this.isShowExportImportValue = this.isCommoditySelected(this.exportsImportsDetails, this.showImportsOrExports);
  }

  /**
   * Add allied to allied details array when click happens
   */
  addAllied() {
    this.alliedGroup.value.alliedActivitiesRevenue = this.alliedGroup.value.alliedActivitiesRevenue.replace(/,/g,'');
    if(!this.isEditAllied) {
      this.alliedDetails.push(this.alliedGroup.value);
    }
    else {
      this.alliedDetails.splice(this.editAlliedIndex, 1, this.alliedGroup.value)
    }
    this.isEditAllied = false;
    this.alliedGroup.reset();
    this.isShowAlliedValue = this.isCommoditySelected(this.alliedDetails, this.showAllied);
  }

  /**
   * Removes an item from the array
   * @param index Index of input activities to remove
   */
  removeInputActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this input activity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.inputDetails.splice(id, 1);
        this.inputBusinessActivitiesArray.value.splice(id, 1);
        this.inputsGroup.reset();
        this.editInputYear = this.filterFinancialYears(this.inputDetails, this.financialYears, 'delete');
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of output activities to remove
   */
  removeOutputActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this output activity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.outputDetails.splice(id, 1);
        this.outputBusinessActivitiesArray.value.splice(id, 1);
        this.outputsGroup.reset();
        this.editOutputYear = this.filterFinancialYears(this.outputDetails, this.financialYears, 'delete');
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of other activities to remove
   */
  removeOtherActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this other activity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.otherDetails.splice(id, 1);
        this.otherBusinessActivitiesArray.value.splice(id, 1);
        this.otherGroup.reset();
        this.editOtherYear = this.filterFinancialYears(this.otherDetails, this.financialYears, 'delete');
        this.isShowOtherRevenue = this.isCommoditySelected(this.otherDetails, this.otherBusinessShow);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of chc activities to remove
   */
  removeChcActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this CHC activity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.chcDetails.splice(id, 1);
        this.chcBusinessActivitiesArray.value.splice(id, 1);
        this.chcGroup.reset();
        this.editCHCYear = this.filterFinancialYears(this.chcDetails, this.financialYears, 'delete');
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of lending activities to remove
   */
  removeLendingActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this lending activity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.lendingDetails.splice(id, 1);
        this.lendingArray.value.splice(id, 1);
        this.lendingGroup.reset();
        this.editLendingYear = this.filterFinancialYears(this.lendingDetails, this.financialYears, 'lending');
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of consumer activities to remove
   */
  removeConsumer(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this consumer commodity details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.consumerDetails.splice(id, 1);
        this.consumerArray.value.splice(id, 1);
        this.consumerGroup.reset();
        this.isShowConsumerValue = this.isCommoditySelected(this.consumerDetails, this.showConsumer);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of certified activities to remove
   */
  removeCertified(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this certified seed production details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.certifiedDetails.splice(id, 1);
        this.certifiedArray.value.splice(id, 1);
        this.certifiedGroup.reset();
        this.isShowCertifiedValue = this.isCommoditySelected(this.certifiedDetails, this.showCertified);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of warehouse activities to remove
   */
  removeWarehouse(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this warehouse details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.warehouseDetails.splice(id, 1);
        this.warehouseArray.value.splice(id, 1);
        this.warehouseGroup.reset();
        this.isShowWarehouseValue = this.isCommoditySelected(this.warehouseDetails, this.showWarehouse);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of cold storage activities to remove
   */
  removeColdStorage(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this cold storage details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.coldStorageDetails.splice(id, 1);
        this.coldStorageArray.value.splice(id, 1);
        this.coldStorageGroup.reset();
        this.isShowColdStorageValue = this.isCommoditySelected(this.coldStorageDetails, this.showColdStorage);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of imports and exports activities to remove
   */
  removeImportsExports(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this import/export details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.exportsImportsDetails.splice(id, 1);
        this.exportsOrImportsArray.value.splice(id, 1);
        this.importsExportsGroup.reset();
        this.isShowExportImportValue = this.isCommoditySelected(this.warehouseDetails, this.showImportsOrExports);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of  to remove
   */
  removeAlliedActivities(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this allied details');
    delConfirmed.then(confirmation => {
      if(confirmation.isConfirmed) {
        this.alliedDetails.splice(id, 1);
        this.alliedArray.value.splice(id, 1);
        this.alliedGroup.reset();
        this.isShowAlliedValue = this.isCommoditySelected(this.warehouseDetails, this.showWarehouse);
      }
    });

  }

  /**
   * Removes an item from the array
   * @param index Index of Input details to remove
   */
  editInputActivities(index: any) {

    this.isEditInputBusinessActivities = true;
    this.editInputBusinessActivitiesIndex = index;
    this.editInputYear = this.financialYears.filter(res => res.id === this.inputDetails[index].businessYear);
    this.inputsGroup.patchValue(this.inputDetails[index]);
    if (this.inputDetails[index].quantity) {
      this.isShowQuantity = true;
    }
    this.inputValueCommaConverter();
  }

  /**
   * Removes an item from the array
   * @param index Index of output details to remove
   */
  editOutputActivities(index: any) {
    this.isEditOutputBusinessActivities = true;
    this.editOutputBusinessActivitiesIndex = index;
    this.editOutputYear = this.financialYears.filter(res => res.id === this.outputDetails[index].businessYear);
    this.outputsGroup.patchValue(this.outputDetails[index]);
    this.inputValueCommaConverter();
  }

  /**
   * Removes an item from the array
   * @param index Index of other details to remove
   */
  editOtherActivities(index: any) {
    this.isEditOtherBusinessActivities = true;
    this.editOtherBusinessActivitiesIndex = index;
    this.editOtherYear = this.financialYears.filter(res => res.id === this.otherDetails[index].businessYear);
    this.otherGroup.patchValue(this.otherDetails[index]);
    if (this.otherDetails[index].activities) {
      this.isShowOtherRevenue = true;
    }
    this.inputValueCommaConverter();
  }

  /**
   * Removes an item from the array
   * @param index Index of chc details to remove
   */
  editChcActivities(index: any) {
    this.isEditChcServices = true;
    this.editChcServicesIndex = index;
    this.editCHCYear = this.financialYears.filter(res => res.id === this.chcDetails[index].businessYear);
    this.chcGroup.patchValue(this.chcDetails[index]);
    if (this.chcDetails[index].quantity) {
      this.isShowChcShop = true;
    }
  }

  /**
   * Removes an item from the array
   * @param index Index of lending details to remove
   */
  editLendingActivities(index: any) {
    this.isEditLending = true;
    this.editLendingIndex = index;
    this.editLendingYear = this.financialYears.filter(res => res.id === this.lendingDetails[index].year);
    this.lendingGroup.patchValue(this.lendingDetails[index]);
  }

  /**
   * Removes an item from the array
   * @param index Index of consumer details to remove
   */
  editConsumer(index: any) {
    this.isEditConsumer = true;
    this.editConsumerIndex = index;
    this.consumerGroup.patchValue(this.consumerDetails[index]);
    if (this.consumerDetails[index].consumerProductCommodity || this.consumerDetails[index].consumerProductRevenue) {
      this.isShowConsumerValue = true;
    }
    this.inputValueCommaConverter();
  }

  /**
   * Removes an item from the array
   * @param index Index of certified details to remove
   */
  editCertified(index: any) {
    this.isEditCertified = true;
    this.editCertifiedIndex = index;
    this.certifiedGroup.patchValue(this.certifiedDetails[index]);
    if (this.certifiedDetails[index].certifiedProductionCrop || this.certifiedDetails[index].certifiedProductionRevenue) {
      this.isShowCertifiedValue = true;
    }
  }

  /**
   * Removes an item from the array
   * @param index Index of warehouse details to remove
   */
  editWarehouse(index: any) {
    this.isEditWarehouse = true;
    this.editWarehouseIndex = index;
    this.warehouseGroup.patchValue(this.warehouseDetails[index]);
    if (this.warehouseDetails[index].capacityInWarehouse || this.warehouseDetails[index].commodityStoredInWarehouse) {
      this.isShowWarehouseValue = true;
    }
  }

  /**
   * Removes an item from the array
   * @param index Index of cold storage details to remove
   */
  editColdStorage(index: any) {
    this.isEditColdStorage = true;
    this.editColdStorageIndex = index;
    this.coldStorageGroup.patchValue(this.coldStorageDetails[index]);
    if (this.coldStorageDetails[index].capacityInColdStorage || this.coldStorageDetails[index].commodityStoredInColdStorage) {
      this.isShowColdStorageValue = true;
    }
  }

  /**
   * Removes an item from the array
   * @param index Index of exports or imports details to remove
   */
  editExportsAndImports(index: any) {
    this.isEditExportsOrImports = true;
    this.editExportsOrImportsIndex = index;
    this.importsExportsGroup.patchValue(this.exportsImportsDetails[index]);
    if (this.exportsImportsDetails[index].exportsOrImportsCommodity || this.exportsImportsDetails[index].exportsOrImportsRevenue) {
      this.isShowExportImportValue = true;
    }

  }

  /**
   * Removes an item from the array
   * @param index Index of allied details to remove
   */
  editAlliedActivities(index: any) {
    this.isEditAllied = true;
    this.editAlliedIndex = index;
    this.alliedGroup.patchValue(this.alliedDetails[index]);
    if (this.alliedDetails[index].alliedActivities || this.alliedDetails[index].alliedActivitiesRevenue) {
      this.isShowAlliedValue = true;
    }
  }

  /**
   * To show constant after adding any one data in:
   * consumer, certified, warehouse, cold storage, export & import, allied
   */
  isCommoditySelected(groupDetails: any, selection: any) {
    if (groupDetails.length) {
      selection.patchValue(this.isSelected[0].id);
    }
    return groupDetails?.length > 0;
  }

  /**
   * This function is to toggle the financial years to prevent duplications
   * @param inputField
   * @param financialYrs
   * @param label
   * @returns
   */
  filterFinancialYears(inputField: any, financialYrs?: any, label?: string) {
    // If label is provided, filter the financialYears array; otherwise, use the original financialYears.
    const filteredYears = label ? financialYrs : this.financialYears;

    // Filter the financialYears array based on the presence of corresponding turnOverDetails.
    const financialYears = filteredYears.filter(res => !inputField.some(turnOver =>
      (label === 'lending') ? (turnOver.year === res.id) : (turnOver.businessYear === res.id)
    ));

    return financialYears;
  }

  /**
   * Convert indian rupee format
   */
  convertIndianRupeeFormat(value) {
    return '₹' + Number(value).toLocaleString('en-IN');
  }

  /**
   * To convert number into indian rupees notations
   * ex: from: 100000, to: 1,00,000
   */
  inputValueCommaConverter() {

    // Input business revenue field
    this.getBusinessRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.inputBusinessActivityRevenue = this.organizationProfileService.addCommaToNumericFields(this.getBusinessRevenue, this.inputBusinessActivityRevenue);
        }
    })

    // Output business revenue field
    this.outputBusinessRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.outputBusinessActivityRevenue = this.organizationProfileService.addCommaToNumericFields(this.outputBusinessRevenue, this.outputBusinessActivityRevenue);
        }
    })

    // Other business revenue field
    this.otherBusinessRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.otherBusinessActivityRevenue = this.organizationProfileService.addCommaToNumericFields(this.otherBusinessRevenue, this.otherBusinessActivityRevenue);
        }
    })

    // CHC revenue field
    this.chcBusinessRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.chcRevenueValue = this.organizationProfileService.addCommaToNumericFields(this.chcBusinessRevenue, this.chcRevenueValue);
        }
    })

    // Lending revenue field
    this.lendingAmounts.valueChanges.subscribe(val => {
      if (val) {
        this.lendingAmountValue = this.organizationProfileService.addCommaToNumericFields(this.lendingAmounts, this.lendingAmountValue);
        }
    })

    // Consumer products revenue field
    this.consumerProductsRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.inputConsumerProductsRevenue = this.organizationProfileService.addCommaToNumericFields(this.consumerProductsRevenue, this.inputConsumerProductsRevenue);
        }
    })

    // Certified products revenue field
    this.certifiedProductionRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.inputCertifiedProductionRevenue = this.organizationProfileService.addCommaToNumericFields(this.certifiedProductionRevenue, this.inputCertifiedProductionRevenue);
        }
    })

    // Export & Import products revenue field
    this.exportOrImportRevenue.valueChanges.subscribe(val => {
      if (val) {
        this.inputExportsOrImportsRevenue = this.organizationProfileService.addCommaToNumericFields(this.exportOrImportRevenue, this.inputExportsOrImportsRevenue);
        }
    })

    // Allied products revenue field
    this.alliedActivitiesRevenues.valueChanges.subscribe(val => {
      if (val) {
        this.inputAlliedActivitiesRevenue = this.organizationProfileService.addCommaToNumericFields(this.alliedActivitiesRevenues, this.inputAlliedActivitiesRevenue);
        }
    })

  }

  /**
   * Form group for input details
   */
  get inputsGroup(): FormGroup {
    return this.businessActivitiesForm.get('input') as FormGroup;
  }

  /**
   * Form group for outputs details
   */
  get outputsGroup(): FormGroup {
    return this.businessActivitiesForm.get('output') as FormGroup;
  }

  /**
   * Form group for chc details
   */
  get chcGroup(): FormGroup {
    return this.businessActivitiesForm.get('chc') as FormGroup;
  }

  /**
   * Form group for lending details
   */
  get lendingGroup(): FormGroup {
    return this.businessActivitiesForm.get('lending') as FormGroup;
  }

  /**
   * Form group for business year
   */
  get getBusinessYear(): AbstractControl {
    return this.inputsGroup.get('businessYear');
  }

  /**
   * Form group for business revenue
   */
  get getBusinessRevenue(): AbstractControl {
    return this.inputsGroup.get('businessRevenue');
  }

  /**
   * Form group for input shop
   */
  get getInputShop(): AbstractControl {
    return this.inputsGroup.get('inputShop');
  }

  /**
   * Form group for quantity
   */
   get getQuantity(): AbstractControl {
    return this.inputsGroup.get('quantity');
  }

  /**
   * Form group for business year
   */
  get outputBusinessYear(): AbstractControl {
    return this.outputsGroup.get('businessYear');
  }

  /**
   * Form group for business revenue
   */
  get outputBusinessRevenue(): AbstractControl {
    return this.outputsGroup.get('businessRevenue');
  }

  /**
   * Form group for percentage of output
   */
  get outputPercentageOfOutput(): AbstractControl {
    return this.outputsGroup.get('percentageOfOutput');
  }

  /**
   * Form group for top three buyers
   */
  get outputTopThreeBuyers(): AbstractControl {
    return this.outputsGroup.get('topThreeBuyers');
  }

  /**
   * Form group for business year
   */
  get otherBusinessYear(): AbstractControl {
    return this.otherGroup.get('businessYear');
  }

  /**
   * Form group for business revenue
   */
  get otherBusinessRevenue(): AbstractControl {
    return this.otherGroup.get('businessRevenue');
  }

  /**
   * Form group for activities
   */
  get otherActivities(): AbstractControl {
    return this.otherGroup.get('activities');
  }

  /**
   * Form group for business year
   */
  get chcBusinessYear(): AbstractControl {
    return this.chcGroup.get('businessYear');
  }

  /**
   * Form group for business revenue
   */
  get chcBusinessRevenue(): AbstractControl {
    return this.chcGroup.get('businessRevenue');
  }

  /**
   * Form group for quantity
   */
  get chcQuantity(): AbstractControl {
    return this.chcGroup.get('quantity');
  }

  /**
   * Form group for chc shop
   */
  get chcShopField(): AbstractControl {
    return this.chcGroup.get('chcShop');
  }

  /**
   * Form group for year
   */
  get lendingYear(): AbstractControl {
    return this.lendingGroup.get('year');
  }

  /**
   * Form group for lending amount
   */
  get lendingAmounts(): AbstractControl {
    return this.lendingGroup.get('lendingAmount');
  }

  /**
   * Form group for consumer Product commodity
   */
  get consumerProductsCommodity(): AbstractControl {
    return this.consumerGroup.get('consumerProductCommodity');
  }

  /**
   * Form group for consumer product revenue
   */
  get consumerProductsRevenue(): AbstractControl {
    return this.consumerGroup.get('consumerProductRevenue');
  }

  /**
   * Form group for certified production crop
   */
  get certifiedProductionsCrop(): AbstractControl {
    return this.certifiedGroup.get('certifiedProductionCrop');
  }

  /**
   * Form group for certified production revenue
   */
  get certifiedProductionRevenue(): AbstractControl {
    return this.certifiedGroup.get('certifiedProductionRevenue');
  }

  /**
   * Form group for input commodity stored in warehouse
   */
  get commodityStoredInWarehouses(): AbstractControl {
    return this.warehouseGroup.get('commodityStoredInWarehouse');
  }

  /**
   * Form group for capacity in warehouse
   */
  get capacityInWarehouses(): AbstractControl {
    return this.warehouseGroup.get('capacityInWarehouse');
  }

  /**
   * Form group for commodity stored in coldStorage
   */
  get commodityStoredInColdStorages(): AbstractControl {
    return this.coldStorageGroup.get('commodityStoredInColdStorage');
  }

  /**
   * Form group for capacity in coldStorage
   */
  get capacityInColdStorages(): AbstractControl {
    return this.coldStorageGroup.get('capacityInColdStorage');
  }

  /**
   * Form group for exports or imports commodity
   */
  get exportOrImportCommodity(): AbstractControl {
    return this.importsExportsGroup.get('exportsOrImportsCommodity');
  }

  /**
   * Form group for exports or Imports revenue
   */
  get exportOrImportRevenue(): AbstractControl {
    return this.importsExportsGroup.get('exportsOrImportsRevenue');
  }

  /**
   * Form group for allied activities
   */
  get alliedActivitive(): AbstractControl {
    return this.alliedGroup.get('alliedActivities');
  }

  /**
   * Form group for allied activities revenue
   */
  get alliedActivitiesRevenues(): AbstractControl {
    return this.alliedGroup.get('alliedActivitiesRevenue');
  }

}
