import { Injector, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { IdValueMap } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiHomeStreamService } from './services/kalgudi-home-stream.service';

@Directive()
export abstract class KalgudiAiPredictionsSendSms extends KalgudiDestroyable {

  aiPredictionsSendSmsForm: FormGroup;
  varInputs: FormGroup;
  items: FormArray;

  varCount: number;

  smsTemplateList: any[] = [];

  inputsValue: IdValueMap[] = [
    {id: 'NAME', value: 'Name'},
    {id: 'PRODUCT_SKU', value: 'Product SKU'},
    {id: 'URL', value: 'URL'}
  ];

  private fb: FormBuilder;
  private homeStreamService: KalgudiHomeStreamService;

  constructor(protected injector: Injector) {

    super();

    this.fb                = this.injector.get(FormBuilder);
    this.homeStreamService = this.injector.get(KalgudiHomeStreamService);

    this.varInputs = this.fb.group({
      items: this.fb.array([ this.varItems() ])
    });

    this.aiPredictionsSendSmsForm = this.newAiPredictionsSendSmsForm;

  }

  // --------------------------------------------------------
  // #region Getter and Setter
  // --------------------------------------------------------

  /**
   * Getter for Message field
   */
  get messageField(): AbstractControl {
    return this.aiPredictionsSendSmsForm.get('message');
  }

  /**
   * Getter for Sms template field
   */
  get smsTemplateField(): AbstractControl {
    return this.aiPredictionsSendSmsForm.get('smsTemplate');
  }

  /**
   * Form group for ai predictions send as sms
   */
  private get newAiPredictionsSendSmsForm(): FormGroup {

    return this.fb.group({
      smsTemplate: [''],
      message: ['']
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Gets list of sms templates
   */
  getSmsTemplateList() {
    this.homeStreamService.getSmsTemplateList()
      .subscribe(
        res => {
          res.forEach(smsTemplate => {
            if (smsTemplate.templateType === 'Promotional') {

              this.smsTemplateList.push(smsTemplate);
            }
          });
        }
      )
  }

  /**
   * Subscribes to sms template field value changes
   */
  subscribeSmsTemplateFieldValueChanges() {
    this.smsTemplateField.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          if(res && res.message) {
            this.messageField.patchValue(res.message);
          }

          const inputList = <FormArray>this.varInputs.controls.items;
          inputList.controls = [];

          this.varCount = 0;

          this.varCount = res.message.split('{#var#}').length - 1;

          for (var i = 0; i < this.varCount; i++) this.addItem();
        }
      )
  }

  /**
   * #var# form group
   * @returns
   */
  varItems(): FormGroup {
    return this.fb.group({
      type: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  /**
   * Adds items to the varList
   */
  addItem(): void {
    this.items = this.varInputs.get('items') as FormArray;
    this.items.push(this.varItems());
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
