<mat-tab-group class="members-tabs" [(selectedIndex)]="activeTabIndex">

  <!-- Manage members tab -->
  <mat-tab [label]="TABS.MANAGE.title | translate">
    <div class="mt-3 dialog-container manage-members overflow-auto" fxLayout="column" *ngIf="activeTabIndex === TABS.MANAGE.index">
      <kl-manage-project-members
        class="h-100"
        [projectId]="projectId">
      </kl-manage-project-members>
    </div>
  </mat-tab>
  <!-- / Manage members tab -->

  <!-- Search members tab -->
  <mat-tab [label]="TABS.SEARCH.title | translate">
    <div class="dialog-container">
      <kl-search-members [projectId]="projectId" (membersAdded)="onMembersAddition($event)" *ngIf="activeTabIndex === TABS.SEARCH.index"></kl-search-members>

    </div>
  </mat-tab>
  <!-- / Search members tab -->

  <!-- Bulk members tab -->
  <mat-tab [label]="TABS.BULK_MEMBERS.title | translate"  [disabled]="environment.production">
    <div class="dialog-container mt-3">
      <kl-bulk-members-addition (loginIdsList)="addMembers($event)"  *ngIf="activeTabIndex === TABS.BULK_MEMBERS.index"></kl-bulk-members-addition>
    </div>
  </mat-tab>
  <!-- / Bulk members tab -->

</mat-tab-group>
