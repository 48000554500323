import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import {
  APiResponseTaskCreation,
  BaseProductAutocomplete,
  IdValueMap,
  KalgudiProjectTask,
  KalgudiTaskTemplateSearchResults,
  PartialData,
  StoreType,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiTaskCreationApiService } from './kalgudi-task-creation-api.service';

@Injectable()
export class KalgudiTaskCreationService {

  constructor(private api: KalgudiTaskCreationApiService) { }


  /**
   * Make an api call to create a task
   */
  createTask(projectId: string, payload: KalgudiProjectTask, filters: PartialData = {}): Observable<APiResponseTaskCreation> {

    return this.api.createTask(projectId, payload, filters);
  }


  /**
   * Calls api method to get task list
   * @param projectId
   * @param offset
   * @param limit
   */
  getTasks(offset: number, limit: number, projectId: string): Observable<KalgudiStreamData> {
    return this.api.fetchStream(offset, limit, projectId);
  }

  /**
   * Update the task
   */
  updateTask(
    projectId: string,
    taskId: string,
    payload: KalgudiProjectTask,
    filters: PartialData = {}): Observable<APiResponseTaskCreation> {

    return this.api.updateTask(projectId, taskId, payload, filters);
  }


  /**
   * Calls the api to get the tasks templates
   */
  getTemplates(searchKeyword: string, projectType: string, offset, limit): Observable<KalgudiTaskTemplateSearchResults> {

    return this.api.getTaskTemplates(searchKeyword, projectType, offset, limit);
  }

  /**
   * Calls the api to get the schema
   * @param templateId
   */
  getTemplateSchema(templateId: string) {
    return this.api.getTemplateSchema(templateId);
  }

  /**
   * Fetches crop stages of a product from PDS api
   */
  fetchCropStages(productId: string, storeType: StoreType): Observable<IdValueMap[]> {
    return this.api.fetchCropStages(productId, storeType);
  }

  /**
   * Gets the product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    filters: PartialData = {},
    offset: number = 0,
    limit: number = 30,
  ): Observable<BaseProductAutocomplete[]> {
    return this.api.getProductsPredictions(keyword, offset, limit, filters);
  }

}
