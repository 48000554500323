<section class="post-tile-attachments">
  <div class="px-3" *ngIf="profileLogDetails?.message">
    <p id="post-tile-text" class="mb-0 text-content"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="3"
      klGoogleTranslate
      [translationSource]="source"
      [translationTargetList]="translationsList"
      [translationText]="profileLogDetails?.message"
      klEventBubblingBlocker="a"
      [innerHTML]="profileLogDetails?.message | linkify">
    </p>
  </div>

  <ng-container *ngIf="profileLogDetails?.attachments?.length">

    <div class="pl-3 pt-2 pb-2">

      <kl-social-attachments [attachments]="profileLogDetails?.attachments"></kl-social-attachments>
    </div>

  </ng-container>


  <ng-container *ngIf="profileLogDetails?.attachments?.length">

    <ng-container *ngIf="profileLogDetails?.attachments | attachmentTypeCount: 'AUDIO'">
      <div class="px-3 pt-2" fxLayout fxLayoutAlign="start center">

        <div>
          <mat-icon class="pt-1" color="primary">volume_up</mat-icon>
        </div>
        <div class="ml-1">{{ profileLogDetails?.attachments | attachmentTypeCount: 'AUDIO' }}
          <span translate>Voice messages attached</span>
        </div>
      </div>
    </ng-container>

  </ng-container>


</section>

