<div fxLayout="column" fxLayoutAlign="center center" class="soil-moisture-gauge">

  <!-- Depth profile drop down -->

  <div class="w-100">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label translate>Depth average</mat-label>
      <mat-select [formControl]="depthAvgForm">
        <ng-container *ngFor="let item of soilDepthList">
          <mat-option [value]="item">{{item.value}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <!--/ Depth profile drop down -->

  <!-- <div fxLayoutAlign="center center" class="depth-avg">

    <h3>{{selectedValue * 100 | number}}</h3>
  </div> -->

  <mwl-gauge
    class="one"
    [ngClass]="selectedClass"
    [max]="50"
    [min]="0"
    [dialStartAngle]="180"
    [dialEndAngle]="0"
    [value]="selectedValue * 100"
    [animated]="true"
    [animationDuration]="1"
  >
  </mwl-gauge>

  <!-- <radial-gauge
    [value]="034.95"
    title=""
    units="%"
    width="180"
    height="180"
    min-value="0"
    max-value="120"
    highlights='[
        {"from": 0, "to": 20, "color": "#ab47bc"},
        {"from": 20, "to": 40, "color": "#e91e63"},
        {"from": 40, "to": 60, "color": "#5c6bc0"},
        {"from": 60, "to": 80, "color": "#4caf50"},
        {"from": 80, "to": 100, "color": "#ffca28"},
        {"from": 100, "to": 120, "color": "#ff9800"}
    ]'
    value-box="true">
  </radial-gauge> -->
</div>

