import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { UsersStackTileComponent } from './users-stack-tile/users-stack-tile.component';



@NgModule({
  declarations: [UsersStackTileComponent],
  imports: [
    CommonModule,

    FlexLayoutModule,
  ],
  exports: [
    UsersStackTileComponent
  ]
})
export class UsersTileModule { }
