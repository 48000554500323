import { Component, Inject, Injector, OnInit } from '@angular/core';

import { AddCertificate } from '../../classes/add-certificate';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.scss']
})
export class AddCertificateComponent extends AddCertificate implements OnInit {

  isShgApp:boolean = true;

  s3Category: any;
  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
    ) {
    super(injector);

    this.isShgApp = this.environment?.appId === 'SHG_NET_APP' ? false : true;
  }

  ngOnInit() {

    if (this.certificateDetails) {
      this.addCertificateForm.patchValue(this.certificateDetails);
    }

    this.attachmentsField.patchValue([]);
  }

  onDestroyed(): void {}

  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    const attachments = this.attachmentsField.value || [];
    attachments.splice(index, 1);
    this.attachmentsField.patchValue(attachments);
  }
}
