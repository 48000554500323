<div id="kalgudi-input-dialog"  class="kalgudi-core-theme kl-input-dialog overflow-hidden">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content likes-list-content">

    <!-- Section for mat dialog content -->
    <kl-likes-list [shareId]="id" (viewProfilePage)="profilePageOpen()"></kl-likes-list>
    <!-- /Section for mat dialog content -->

  </div>
</div>
