import { Component, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiCommonPageCreation } from '../../classes/kalgudi-common-page-creation-form';

@Component({
  selector: 'kl-common-page-creation-form',
  templateUrl: './common-page-creation-form.component.html',
  styleUrls: ['./common-page-creation-form.component.scss'],
  animations: kalgudiAnimations
})
export class CommonPageCreationFormComponent extends  KalgudiCommonPageCreation implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

}
