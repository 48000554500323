<mat-card class="p-0 banner-tile">

  <!-- Header section -->
  <section class="px-3 pt-2">

    <div fxLayout class="pb-2" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="start center">
        <div>
          <svg-icon src="assets/svgs/store-products.svg" [svgStyle]="{'width.px': '20', 'height.px': '20'}"
            [applyCss]="true" class="mr-3 store-products-icon"></svg-icon>
        </div>
        <div fxLayoutAlign="start center">
          <p class="m-0" translate>Store products</p>
        </div>
      </div>
    </div>

    <div class="border-bottom"></div>

  </section>
  <!--/ Header section -->

  <!-- Slider section -->
  <core-store-banner-tile-images></core-store-banner-tile-images>
  <!--/ Slider section -->

  <!-- Footer section -->
  <section class="px-3">

    <div class="border-bottom"></div>

    <div fxLayout fxLayoutAlign="end end" class="py-2">
      <button mat-button color="primary" class="view-products"
        (click)="openStorePage()" fxLayout fxLayoutAlign="center center">
        <div fxLayout fxLayoutAlign="center center">
          <div><span translate>View products</span>
          </div>
        </div>
      </button>
    </div>

  </section>
  <!--/ Footer section -->

</mat-card>
