import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiSmsStates } from '@kalgudi/types';

import { KalgudiSmsLogsFilter } from '../../classes/kalgudi-sms-logs-filters';

@Component({
  selector: 'kl-sms-log-filters',
  templateUrl: './sms-log-filters.component.html',
  styleUrls: ['./sms-log-filters.component.scss']
})
export class SmsLogFiltersComponent extends KalgudiSmsLogsFilter implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);

  }

  ngOnInit() {
    // selected filter default value is 'ALL'
    this.selectedFilter.patchValue(KalgudiSmsStates.ALL);

    this.selectedFilterId.emit(this.selectedFilter.value);
  }

}
