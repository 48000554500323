import { Injectable } from '@angular/core';
import { KalgudiWeatherDetails } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

import { IWeatherCache } from '../model';

@Injectable()
export class WeatherCacheInMemoryService implements IWeatherCache {


  private weatherCache: { [key: string]: KalgudiWeatherDetails } = {};

  constructor() {}

  /**
   * Returns `true` if weather is cached in memory otherwise returns `false`
   */
  isWeatherCached(latitude: string | number, longitude: string | number, language: string): boolean {

    const key = this.constructCacheKey(latitude, longitude, language);

    // console.log('Check is weather cached -> ', key, this.weatherCache[key]);

    return !!(this.weatherCache[key]);
  }

  /**
   * Constructs weather cache key and returns the cache key
   */
  constructCacheKey(latitude: string | number, longitude: string | number, language: string): string {
    return `${latitude}${longitude}${language}`;
  }

  /**
   * Caches a weather for later usage
   */
  cacheWeather(latitude: string | number, longitude: string | number, language: string, weather: KalgudiWeatherDetails): void {

    const key = this.constructCacheKey(latitude, longitude, language);

    // console.log(`Caching weather ${key} `, weather);

    this.weatherCache[key] = weather;
  }

  /**
   * Gets cached weather
   */
  getCachedWeather(latitude: string | number, longitude: string | number, language: string): Observable<KalgudiWeatherDetails> {

    const key = this.constructCacheKey(latitude, longitude, language);

    // console.log('Getting cached weather', this.weatherCache[key]);

    return of(this.weatherCache[key]);
  }
}
