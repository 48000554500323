<div class="task-members-tile-da">

  <!-- Task member tile -->
  <div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center"
    class="py-2" [ngClass]="{'cursor-pointer': !isSubmission }">

    <!-- Task member profile pic -->
    <div class="position-relative rounded-circle members-list">
      <img
        [klErrorImages]="[
        (member?.profilePicUrl) | prefixDomain,
        domain + defaultProfilePic
      ]"
      [src]="(member?.profilePicUrl) | prefixDomain" class="image rounded-circle">
    </div>
    <!--/ Task member profile pic -->

    <div class="position-absolute" *ngIf="member?.selected && !isSubmission">
      <div class="selected rounded-circle" fxLayout fxLayoutAlign="center center">
        <mat-icon class="text-white selected-icon" fxLayout fxLayoutAlign="center center">done</mat-icon>
      </div>
    </div>

    <!-- Task member details -->
    <div fxLayout="column" class="member-details">

      <div class="name">{{ member?.firstName }}</div>

      <div>
        <span class="biz-type text-muted">{{ member?.businessTypeName }}</span>
        <span *ngIf="member?.businessTypeName && member?.locationLong" class="text-muted">,&nbsp;</span>
        <span class="text-muted text-capitalize location" *ngIf="member?.locationLong" title="member?.location">{{ member?.locationLong }}</span>
      </div>
    </div>
    <!--/ Task member details -->

  </div>
  <!--/ Task member tile -->
</div>
