import { Injector, Directive } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfile } from '../../../classes/kalgudi-profile';
import {
  EditCommonProfileDetailsDialogService,
} from '../../profile-basic-edit/services/edit-common-profile-details-dialog.service';

@Directive()
export abstract class KalgudiEmployeeProfile extends KalgudiProfile {

  pageId: string;
  isDigitalAssist: boolean;
  loginId: string;

  private dialogsService: KalgudiDialogsService;
  private profileBasicDetailsEditDialogService: EditCommonProfileDetailsDialogService;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService
  ) {

    super(injector, util);

    this.profileBasicDetailsEditDialogService = this.injector.get(EditCommonProfileDetailsDialogService);

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.getLoginId();
        }
      );

    this.pageId = this.profileState.pageId;

    this.isDigitalAssist = this.pageId ? true : false;
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Shows Edit employee profile dialog
   */
  showEditEmployeeProfileDialog() {

    this.profileBasicDetailsEditDialogService.showEditEmployeeProfileDialog()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  /**
   * Calls api to get the login id
   */
  getLoginId() {
    this.profileService.getLoginId()
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.loginId = res.loginId;
      });
  }

  /**
   * Shows Edit login id dialog
   */
  showEditLoginIdDialog() {

    this.profileBasicDetailsEditDialogService.showEditLoginIdDialog(this.loginId)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
