<div class="assist-members-picker rounded mb-1 p-1 pl-2 cursor-pointer position-relative"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="15px"
  (click)="pickMember()"
  [matTooltip]="'Click here to add a member' | translate">

  <div class="assistant-picker position-absolute w-100 rounded"></div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

    <!-- Assistant details -->
    <ng-container *ngIf="selectedUser">
      <div>
        <img class="profile-pic rounded-circle"
          [src]="selectedUser?.profilePicUrl | prefixDomain"
          (error)="img.src = defaultProfilePic" #img/>
      </div>

      <div>
        <p class="mb-0 name">
          <span>{{ selectedUser?.firstName }}</span>
          <span *ngIf="selectedUser?.location">, {{selectedUser?.location}}</span>
        </p>
      </div>
    </ng-container>
    <!-- / Assistant details -->

    <!-- No assistant details -->
    <ng-container *ngIf="!selectedUser">
      <div>
        <span class="member-picker" translate>Click to pick a member</span>
      </div>
    </ng-container>
    <!-- / No assistant details -->

  </div>

  <!-- Add button -->
  <div class="mr-n1" fxLayoutAlign="end end">
    <button mat-icon-button class="add" color="accent">
      <mat-icon>
        add
      </mat-icon>
    </button>
  </div>
  <!--/ Add button -->

</div>
