import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivityType, KalgudiProjectTask } from '@kalgudi/types';

import { TaskTemplateForm } from '../../classes/task-template-form';

@Component({
  selector: 'kl-task-fill',
  templateUrl: './task-fill.component.html',
  styleUrls: ['./task-fill.component.scss']
})
export class TaskFillComponent extends TaskTemplateForm implements OnInit, OnChanges, AfterViewInit {

  @Input()
  memberProfileKey: string;

  @Input()
  digitalAssistance: boolean;

  @Input()
  isBulk: boolean;

  activityType = ActivityType;
  taskSubmissionDetails: KalgudiProjectTask;

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() {

    // this.initTemplateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.templateId && this.templateId) {
      this.initTemplateSchema(this.templateId);
    }

    if(this.submissionDetails) {
      this.taskSubmissionDetails = this.submissionDetails;

    }
  }

  ngAfterViewInit(): void {

    this.initTemplateForm();
  }

  onDestroyed(): void {}

}
