import { Component, Injector, Input, OnInit } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiUserFarmerMembershipDetails } from '@kalgudi/types';

import { KalgudiFarmerMembershipsList } from '../../classes/kalgudi-farmer-memberships-list';

@Component({
  selector: 'kl-farmer-memberships',
  templateUrl: './farmer-memberships.component.html',
  styleUrls: ['./farmer-memberships.component.scss']
})
export class FarmerMembershipsComponent extends KalgudiFarmerMembershipsList implements OnInit {

  @Input()
  lstOfMemberships: KalgudiUserFarmerMembershipDetails[];

  membershipList: any[] = [
    {
      profileName:"Society co-app",
      fpo:"FPO",
      memberId:"ff2345",
      role:"Lead",
      since:"1985"
    },
    {
      profileName:"Society co-app",
      fpo:"FPO",
      memberId:"ff2345",
      role:"Lead",
      since:"1985"
    },

  ]


  constructor(protected injector: Injector) {
    super(injector)

  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
