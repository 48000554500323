import { Location } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

import { KalgudiSocialQaChatList } from '../../kalgudi-social-qa-chat-list';


@Component({
  selector: 'kl-qa-chats-page',
  templateUrl: './qa-chats-page.component.html',
  styleUrls: ['./qa-chats-page.component.scss']
})
export class QaChatsPageComponent extends KalgudiSocialQaChatList implements OnInit {

  sender: KalgudiUserBasicDetails;

  showBox: boolean;
  disableNoContent: boolean = true;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    protected location: Location,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    private kalgudiApp: KalgudiAppService
  ) {

    super(notification, util, injector, location);

    const user = this.kalgudiApp.profileLocal;

    this.sender = {
      firstName: user.firstName,
      profileKey: user.profileKey,
      profilePicURL: user.profilePicUrl
    };

  }

  ngOnInit() {

    this.initStream();

    this.disableNoContent = !(this.env?.appId === 'SAM_FPO' || this.env?.appId === 'FPO_APP' || this.env?.appId === 'FPO_NET_APP' || this.env?.appId === 'ORMAS_SHG' || this.env?.appId === 'SHG_NET_APP')
  }

  showReply(res: boolean) {
    this.showBox = res;
  }

  onDestroyed(): void {}

  replyHandler(res): void {
    super.replyHandler(res);

    this.showReply(false);
  }
}
