<ng-container
  *ngIf="postDetails && (postDetails?.shareId || postDetails?.scheduleId)"
>
  <div
    [id]="postDetails?.shareId"
    class="mt-2 robocall-tile"
    [@kalgudiStreamTile]="tileAnimationState"
    [ngClass]="{ deleted: tileAnimationState === 'deleted' }"
  >
    <mat-card
      class="update-tile p-0"
      [ngClass]="{ 'cursor-pointer': !fullView, 'no-border': fullView }"
      (click)="toFullview(postDetails)"
    >
      <div class="mb-2">
        <kl-robocall-header
          [scheduledUpdates]="scheduledUpdates"
          [latestActivity]="postDetails?.latestActivity"
          [authorId]="postDetails?.authorId"
          [entityId]="postDetails?.entityId"
          [userProfileKey]="(basicUserDetails$ | async)?.profileKey"
          [msgType]="
            postDetails?.msgTypes &&
            postDetails?.msgTypes.length &&
            postDetails?.msgTypes[0]
              ? postDetails?.msgTypes[0]
              : ''
          "
          (deletePost)="deletePost()"
          [memberRole]="memberRole"
          [showMatMenu]="false"
          (updateScheduledPost)="updateScheduledPost()"
          (report)="report(postDetails?.shareText, postDetails?.shareId)"
          (shareUpdate)="showShareUpdateDialog(postDetails)"
          (sendAsSms)="openAiPredictionsSendSmsDialog(postDetails?.entityId)"
          (shareWhatsapp)="shareOnWhatsapp()"
        >
        </kl-robocall-header>
      </div>

      <div class="p-2">
        <kl-post-author
          [scheduledTime]="
            postDetails?.scheduledTS ? postDetails?.scheduledTS : ''
          "
          [TS]="postDetails?.CT"
          [author]="
            showPageDetails && postDetails?.pageAuthorDetails
              ? postDetails?.pageAuthorDetails
              : postDetails?.author
          "
          avatarSize="md"
          postType="posted"
          [pageId]="postDetails?.entityId"
          [pageName]="postDetails?.filter?.pageTitle"
        >
        </kl-post-author>
      </div>

      <!-- Post tile tag  -->
      <ng-container
        *ngIf="
          postDetails?.msgTypes &&
          postDetails?.msgTypes?.length &&
          postDetails?.msgTypes[0] !== null
        "
      >
        <kl-post-tile-tag [tags]="postDetails?.msgTypes"></kl-post-tile-tag>
      </ng-container>
      <!-- / Post tile tag  -->

      <div class="post-tile-attachments">
        <kl-post-tile-attachments
          [isFullviewPage]="fullView"
          [shareUpdate]="postDetails"
        ></kl-post-tile-attachments>
      </div>
    </mat-card>
  </div>
</ng-container>
