import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommonV1,
  Notifications,
  NotificationsList,
  NotificationSocialCommon,
  WebNotifications,
} from '@kalgudi/types';
import { BehaviorSubject, EMPTY, interval, Observable } from 'rxjs';
import { catchError, filter, map, pairwise, repeatWhen, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { HttpStatusCode } from '../constants';
import { ApiError, KalgudiError } from '../errors';
import { KalgudiAppService } from './kalgudi-app.service';
import { KalgudiUtilityService } from './kalgudi-util.service';

/**
 * Web notification is one of the base services that gives first 40 items to render
 * on the home stream. It also provides various other counts that are necessary for
 * the initial app load.
 *
 * Web notification API call must happen every 2 minutes. So that it updates the app
 * with the latest network counts.
 *
 * The service exposes a single observable `$notifications`. It is a stream of web
 * notifications emitted to all subscribers. The service automatically handles
 * calling of web notification API after the 2 minute interval. It also ensure not
 * to call the notification API if there is no network.
 *
 * @author Pankaj Prakash
 *
 * @example
 * ```
 * this.webNotificationsService.$notifications.subscribe(r => console.log('Notification fetched', r));
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class KalgudiWebNotificationsService {

  /**
   * `v1/webnotifications`
   */
  private readonly API_WEB_NOTIFICATIONS = `${this.env.restBaseUrl}/webnotifications`;

  /**
   * Web notifications API fetch interval
   */
  private readonly WEB_NOTIFICATIONS_INTERVAL = 120000;

  /**
   * Web notification repeat interval. By default web notification API fetch
   * repeat interval is 2 minutes => 120000
   */
  private $notificationRepeat: Observable<number>;

  /**
   * Stream of web notification, containing always the latest emitted notification.
   */
  private webNotifications = new BehaviorSubject<WebNotifications>(null);


  private notificationInitialized = false;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private kalgudiApp: KalgudiAppService,
    private util: KalgudiUtilityService,
  ) {

    // Login, initialize web notification
    this.kalgudiApp.login$.subscribe(r => this.initWebNotification());

    // Logout, un subscribe to the web notification call
    this.kalgudiApp.logout$.subscribe(r => this.unSubscribeWebNotification());
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Observable fired on web notification update.
   */
  get notifications$(): Observable<WebNotifications> {
    return this.webNotifications
      .pipe(

        // Filter all null values out
        filter(r => r !== null),
      );
  }

  /**
   * Stream of first web notification API response containing
   * array of first 40 web notifications object.
   */
  get stream$(): Observable<NotificationsList[]> {

    return this.notifications$
      .pipe(

        // Only take latest one value
        take(1),

        // Map notifications object to array
        map(r => this.mapStreamNotificationsToArray(r.notifications))
      );
  }

  /**
   * Observable fired if there is any new stream notification
   * updates.
   */
  get newStreamNotifications$(): Observable<NotificationsList[]> {

    return this.notifications$
      .pipe(

        // Only require notifications field
        map(r => r.notifications),

        // Combine previous and current notification
        pairwise(),

        // Return the notifications that were not present in previous notification
        map(([prev, current]) => this.mapNewNotifications(prev, current)),

        // Map notification object to array
        map(r => this.mapStreamNotificationsToArray(r))
      );
  }

  /**
   * Observable fired if there is are chat notifications to show.
   */
  // get $chatNotifications(): Observable<number> {
  //   return null;
  // }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Initializes fetching of web notification
   */
  private initWebNotification(): void {

    // Validate conditions to initialize kalgudi notifications
    this.assertNotificationsInitialized();

    /**
     * Initialize notification interval stream
     */
    this.$notificationRepeat = interval(this.WEB_NOTIFICATIONS_INTERVAL)
      .pipe(

        // Run web notification interval until logout
        takeUntil(this.kalgudiApp.logout$),

        // Don't call notification repeat if there is no network
        filter(v => navigator.onLine)
      );

    // Get profile for which need to fetch web notifications
    this.kalgudiApp.profile$
      .pipe(
        // Filter profile details not having null
        filter(r => r !== null),

        // Subscribe to the profile data only once
        // No need to subscribe to the profile data if profile updated
        take(1),

        // Transform the profile stream to web notifications stream
        switchMap(r =>

          // Fetch web notification from API
          this.fetchWebNotifications(r.profileKey)
            .pipe(

              // Repeat fetching of web notifications until notification repeat exhausts
              repeatWhen(_ => this.$notificationRepeat),
            )

        )
      ).subscribe();


    // Make sure not to resubscribe to the web notification fetch
    this.notificationInitialized = true;
  }

  /**
   * Un subscribe web notification fetching
   */
  private unSubscribeWebNotification(): void {

    this.notificationInitialized = false;
    this.$notificationRepeat = null;
    this.webNotifications.next(null);
  }

  /**
   * Fetches web notification from the service. Web notification give
   * all the essential content, chat, group, business notifications
   * that the app needs for the initial render.
   *
   * @param key Profile key of the current logged in user.
   */
  private fetchWebNotifications(key: string): Observable<WebNotifications> {

    const params = { key };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_WEB_NOTIFICATIONS, { params })
      .pipe(
        map(r => (
          // Handle API errors
          this.webNotificationsFetchHandler(key, r),

          // Return the mapped API response
          this.mapWebNotificationResponse(key, r)
        )),

        // Map the web notification response to object type
        // map(r => this.mapWebNotificationResponse(key, r)),

        // Update web notification to all subscribers
        tap(r => this.updateSubscribers(r)),

        // Return empty response for failed API call
        catchError((err: Error) => EMPTY)
      );
  }

  /**
   * API response error handler. Checks for any errors in the web notification API response.
   * Throws specific error message if there are errors in the API response, otherwise
   * returns the API response.
   */
  private webNotificationsFetchHandler(req: string, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    if (res.code !== HttpStatusCode.OK) {
      // Server error while fetching web notifications
      throw new ApiError(new Error('Unable to load web notifications'));
    }

    // Everything good to go
    return res;
  }

  /**
   * Maps web notification API response to the Web notification type.
   */
  private mapWebNotificationResponse(req: string, res: ApiResponseCommonV1): WebNotifications {

    return this.util.toJson<WebNotifications>(res.data);
  }

  /**
   * Updates all subscribers of web notifications. It pushes
   * updated web notification to the `$webNotifications` stream.
   *
   * @param val Latest Web notifications object
   *
   * @return Latest updated web notification object
   */
  private updateSubscribers(val: WebNotifications): WebNotifications {

    // Null checks
    if (!val) {
      return;
    }

    // Push the latest notification to the `$webNotifications` stream
    this.webNotifications.next(val);

    // Return the update notification cache
    return val;
  }

  /**
   * Validates conditions to initialize kalgudi web notification.
   */
  private assertNotificationsInitialized(): boolean {

    // Assert already initialized notification stream
    if (this.notificationInitialized) {
      throw new KalgudiError(new Error('Cannot re-initialize web notifications'));
    }

    return true;
  }

  /**
   * Finds diff of both the notifications and return all notifications that are
   * unique.
   *
   * @returns Returns all unique notifications from both the object
   */
  private mapNewNotifications(prev: Notifications, curr: Notifications): Notifications {

    // Initialize both notifications difference as `prev` if available otherwise `curr`
    const diff: Notifications = {};

    // Get all keys (notification time) from previous notifications object
    const prevKeys = Object.keys(prev);

    // Get all keys (notification time) from current notifications object
    const currKeys = Object.keys(curr);

    // Get diff index of the latest web notification key.
    // If first key of current web notification object
    // matches first key of previous web notification key
    // then there is no difference.
    const diffIndex = currKeys.indexOf(prevKeys[0]);

    // Find all unique keys
    const newItemsCount = diffIndex >= 0 ? diffIndex : currKeys.length - 1;
    const uniqueKeys  = currKeys.slice(0, newItemsCount);

    // Construct diff notification objects from the unique keys
    uniqueKeys.forEach(k => diff[k] = curr[k]);

    return diff;
  }

  /**
   * Maps stream notification object type to notification array.
   */
  private mapStreamNotificationsToArray(notifications: Notifications): NotificationSocialCommon[] {

    return Object.values(notifications);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
