import { Component, Input, OnInit } from '@angular/core';
import { TraceabilityService } from '../../services/traceability.service';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kl-review-comments',
  templateUrl: './review-comments.component.html',
  styleUrls: ['./review-comments.component.scss'],
  providers: [NgbRatingConfig]
})
export class ReviewCommentsComponent implements OnInit {

  @Input()
  level2Id: string;

  reviewsComments: any;

  constructor(private traceabilityService: TraceabilityService) { }

  ngOnInit() {
    this.getReviewsComments();
  }

  /**
   * Gets reviews and comments
   */
  getReviewsComments() {
    this.traceabilityService.getReviewsComments(this.level2Id)
      .subscribe(
        res => {
          this.reviewsComments = res;
        }
      )
  }

}
