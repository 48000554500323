import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService, LikeShareRequest } from '@kalgudi/core';
import { KALGUDI_HOME_STREAM_EVENTS, SocialDataNormalizerService } from '@kalgudi/social';
import { IdValueMap, KalgudiDialogConfig, KalgudiDialogResult, PageType, ShareRequest, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MobileShareUpdateFormComponent } from '../components/mobile-share-update-form/mobile-share-update-form.component';
import { UpdatePostDialogComponent } from '../components/update-post-dialog/update-post-dialog.component';
import {
  UpdatePostMobileDialogComponent,
} from '../components/update-post-mobile-dialog/update-post-mobile-dialog.component';
import { KalgudiShareUpdateApiService } from './kalgudi-share-update-api.service';


@Injectable()
export class KalgudiShareUpdateService {

  constructor(
    private util: KalgudiUtilityService,
    private api: KalgudiShareUpdateApiService,
    private socialDataNormalizer: SocialDataNormalizerService,
    private dialogService: KalgudiDialogsService
  ) { }

  /**
   * @param config Dialog
   */
  showShareUpdateDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openShareUpdateMobileDialog(details)
      : this.openShareUpdateDialog(details, config);
  }

  /**
   * Calls API to share a post.
   */
  sharePost(req: ShareRequest): Observable<ShareUpdate> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<ShareRequest>(req);

    return this.api.createShare(payload)
      .pipe(

        // Attach an event property with the shared question.
        // This event is used by the streams
        tap(r => r.event = KALGUDI_HOME_STREAM_EVENTS.SHARE_UPDATE),
      );
  }

  /**
   * Fetches the share update object from the Api.
   *
   * @param shareId share id to get
   */
  getShareUpdate<R extends ShareUpdate>(shareId: string): Observable<R> {

    return this.api.getShareUpdate<R>(shareId)
      .pipe(

        // Map share update author details
        tap(r => this.socialDataNormalizer.mapShareUpdateQaAuthorDetails(r))
      );
  }

  /**
   * Calls a API to post a like
   * @param req
   */
  like(req: LikeShareRequest): Observable<ShareUpdate> {
    const payload = this.util.clone<LikeShareRequest>(req);

    return this.api.updateAction(payload, 'likes')
      .pipe(
        tap(r => this.socialDataNormalizer.mapShareUpdateLiked(r))
      );
  }

  /**
   * Calls a API to post a comment
   */
  comment(shareId: string, req: LikeShareRequest): Observable<ShareUpdate> {

    const payload = this.util.clone<LikeShareRequest>(req);

    return this.api.postComment(shareId, payload);
  }

  /**
   * Calls a API to delete a post
   *
   * @param shareId
   */
  deletePost(shareId: string): Observable<boolean> {
    return this.api.deletePost(shareId);
  }

  /**
   * Calls a API to update a post
   *
   * @param shareId
   */
  updateScheduledPost(scheduledId: string, shareDetails: ShareUpdate): Observable<ShareUpdate> {

    return this.api.updateScheduledPost(scheduledId, shareDetails);
  }

  /**
   * Calls a API to get likes list
   *
   * @param shareId
   */
  getLikesList(shareId: string) {
    return this.api.getLikesList(shareId);
  }

  /**
   * Opens Mobile share update form dialog
   */
  openMobileShareUpdateForm(): void {
    this.dialogService.openMobileDialog(MobileShareUpdateFormComponent, {
      data: { },
      title: 'Share an update',
    });
  }


  /**
   * Opens share update form
   */
  private openShareUpdateDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.dialogService.openDialog(UpdatePostDialogComponent, details, config);
  }

  /**
   * Opens schedule send dialog in mobile
   */
  private openShareUpdateMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.dialogService.openMobileDialog(UpdatePostMobileDialogComponent, details );
  }

  /**
   * Calls pages API
   */
  fetchPagesList(offset: number, limit: number,isQa?: boolean): Observable<any> {
    return this.api.fetchPagesList(offset, limit, isQa);
  }

  /**
   * Gets, program tags from local assets
   */
  getPageShareTags(pageType: PageType, baseProductId?: string): Observable<IdValueMap[]> {

    return this.api.getPageTags(pageType, baseProductId);
  }

}
