import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, ChangePassword } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable()
export class KalgudiChangePasswordApiService {

  /**
   * `v1/profiles/:profileKey/Password`
   */
  private readonly API_CHANGE_PWD = `${this.env.restBaseUrl}/profiles/:profileKey/Password`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }


  /**
   * Calls api to change password
   */
  changePassword(payload: ChangePassword, profileKey: string): Observable<boolean> {

    return this.httpClient
      .put<ApiResponseCommonV1>(this.API_CHANGE_PWD.replace(':profileKey', profileKey), payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        mapTo(true)
      );
  }
}
