import { Pipe, PipeTransform } from '@angular/core';

export function objectValues(value: object, ...args: any[]): any[] | object {

  try {
    return Object.values(value);
  } catch (e) {
    return value;
  }
}


/**
 * Takes an object and converts it to its values. Emits values of the object.
 *
 * **NOTE:** The pipe is an impure pipe.
 *
 * @example
 * ```ts
 * // Transforms this
 * const obj = {
 *   KEY1: {
 *     firstName: 'Pankaj Prakash',
 *     profileKey: 'KEY1'
 *   },
 *   KEY2: {
 *     firstName: 'Prakash',
 *     profileKey: 'KEY2'
 *   },
 * };
 *
 * // to this
 * [
 *  {
 *    firstName: 'Pankaj Prakash',
 *    profileKey: 'KEY1'
 *  },
 *  {
 *    firstName: 'Prakash',
 *    profileKey: 'KEY2'
 *  },
 * ];
 * ```
 *
 * HTML usage
 * ```html
 * <a *ngFor="let item of usersMap | objectValue">{{a?.firstName}}</a>
 * ```
 *
 *
 * @author Pankaj Prakash
 */
@Pipe({
  name: 'objectValues',
  pure: false,
})
export class ObjectValuesPipe implements PipeTransform {

  transform(value: object, ...args: any[]): any[] | object {

    return objectValues(value, args);
  }

}
