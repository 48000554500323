<div>
  <div class="chat-bubble" fxLayout="row" fxLayoutAlign="start end">

    <!-- Sender tempalte -->
    <ng-container *ngIf="isAuthor; else receiverTemplate">

      <div fxFlex="100%" class="sender">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="end start">
          <div class="bubble-text w-100">
            <div fxLayout="row">
              <p fxFlex="93%" class="m-0 author text-truncate" *ngIf="showAuthor">{{message?.sender?.firstName}}</p>
              <!-- <button fxFlex="7%" mat-icon-button class="actions">
                <i class="fas fa-share-alt share"></i>
              </button> -->

              <button fxFlex="7%" mat-icon-button [matMenuTriggerFor]="menu" class="actions" *ngIf="isAllowedToReply">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md chat">
                <button mat-menu-item id="reply-button" (click)="replyToMsg()"  *ngIf="message?.type !== 'QUESTION'">
                  <mat-icon class="action-icons">reply</mat-icon>
                  <span translate>Reply</span>
                </button>

                <button mat-menu-item id="delete-button" (click)="onDeleteMessage()"  *ngIf="message?.type !== 'QUESTION'">
                  <mat-icon class="action-icons">delete_outline</mat-icon>
                  <span translate>Delete</span>
                </button>

                <button mat-menu-item id="hide-button" (click)="hideMessage()" *ngIf="message?.type == 'QUESTION'">
                  <span translate>Hide</span>
                </button>
              </mat-menu>
            </div>

            <!-- Reply to  -->
            <div *ngIf="message?.replyToMsgData && message?.replyToMsgData?.message" class="specific-message">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="85%">
                  <p class="m-0 person text-truncate">{{message?.replyToMsgData?.sender?.firstName}}</p>
                  <p class="m-0 message text-truncate">{{message?.replyToMsgData?.message}}</p>
                </div>
              </div>
            </div>
            <!-- /Reply to -->

            <p class="m-0 message" *ngIf="message?.message">{{message?.message}}</p>

            <!-- Products -->
            <div  class="p-1" *ngIf="message?.productsMetaData && message?.productsMetaData?.length">
              <kl-share-update-products [shareProducts]="message?.productsMetaData"></kl-share-update-products>

              <p class="mb-0" translate *ngIf="environment?.appId === 'SHAKTIMAN'">
                To know more about the product and to buy it, click on the link below
                <a [href]="environment?.storeDomain" target="_blank">{{environment?.storeDomain}}</a>
              </p>
            </div>
            <!-- / Products -->

            <!-- Images -->
            <div *ngIf="message?.attachments && message?.attachments?.length" class="p-1">
              <kl-images-list [images]="message?.attachments"></kl-images-list>
            </div>
            <!-- /Images -->

            <ng-container *ngFor="let attachment of message?.attachments">
              <div class="audio-attach my-2" *ngIf="attachment?.msgType === 'AUDIO'" fxLayout="row" fxLayoutAlign="center center">
                <video width="100%" height="53" controls>
                  <source [src]="attachment?.url | prefixDomain" type="audio/mpeg"/>
                </video>
              </div>

            </ng-container>

          <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
            <div>
              <!-- <p class="m-0 translate">Translate to</p> -->
            </div>
            <p class="m-0 text-right">
              <time>{{message?.CT | parseDate | date:'dd MMM yyyy, hh:mm a'}}</time>
            </p>
          </div>
          </div>

          <div class="rounded-circle bg-light chat-avatar" fxFlex="30px">
            <img class="rounded-circle bg-light"
              [src]="(message?.sender?.profilePicURL || message?.sender?.profilePicUrl | resizeImage: '150x150') | prefixDomain"
              [alt]="message?.sender?.firstName"
              [title]="message?.sender?.firstName"
              [klErrorImages]="[
                (message?.sender?.profilePicURL || message?.sender?.profilePicUrl) | prefixDomain,
                domain + defaultProfilePic
              ]">
          </div>
        </div>
      </div>

    </ng-container>
    <!-- / Sender template -->

    <!-- Receiver template -->
    <ng-template #receiverTemplate>
      <div fxFlex="100%" class="receiver">
        <div fxLayout="row" fxLayoutGap="10px">
          <div class="rounded-circle bg-light chat-avatar" fxFlex="30px">
            <img class="rounded-circle bg-light"
              [src]="(message?.sender?.profilePicURL || message?.sender?.profilePicUrl | resizeImage: '150x150') | prefixDomain"
              [alt]="message?.sender?.firstName"
              [title]="message?.sender?.firstName"
              [klErrorImages]="[
                (message?.sender?.profilePicURL || message?.sender?.profilePicUrl) | prefixDomain,
                domain + defaultProfilePic
              ]">
          </div>

          <div class="bubble-text">
            <div fxLayout="row">
              <p fxFlex="93%" class="m-0 author text-truncate" *ngIf="showAuthor">{{message?.sender?.firstName}}</p>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="actions" *ngIf="isAllowedToReply">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md receiver">
                <button mat-menu-item id="reply-button" (click)="replyToMsg()" *ngIf="message?.type !== 'QUESTION'">
                  <mat-icon class="action-icons">reply</mat-icon>
                  <span translate>Reply</span>
                </button>

                <button mat-menu-item id="hide-button" (click)="hideMessage()" *ngIf="message?.type == 'QUESTION'">
                  <span translate>Hide</span>
                </button>
              </mat-menu>
            </div>
            <p class="m-0 translate text-truncate">{{message?.sender?.businessName}}</p>

            <!-- Reply to  -->
            <div *ngIf="message?.replyToMsgData && message?.replyToMsgData?.message" class="specific-message-receiver">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="85%">
                  <p class="m-0 person text-truncate">{{ message?.replyToMsgData?.sender?.firstName }}</p>
                  <p class="m-0 message text-truncate">{{message?.replyToMsgData?.message}}</p>
                </div>
              </div>
            </div>
            <!-- /Reply to -->
            <p class="m-0 message" *ngIf="message?.message"> {{message?.message}}</p>

            <!-- Products -->
            <div  class="p-1" *ngIf="message?.productsMetaData && message?.productsMetaData?.length">
              <kl-share-update-products [shareProducts]="message?.productsMetaData"></kl-share-update-products>

              <p class="mb-0" translate *ngIf="environment?.appId === 'SHAKTIMAN'">
                To know more about the product and to buy it, click on the link below
                <a [href]="environment?.storeDomain" target="_blank">{{environment?.storeDomain}}</a>
              </p>
            </div>
            <!-- / Products -->

            <!-- Images -->
            <div *ngIf="message?.attachments && message?.attachments?.length" class="p-1 my-2">
              <kl-images-list [images]="message?.attachments"></kl-images-list>
            </div>
            <!-- /Images -->


            <ng-container *ngFor="let attachment of message?.attachments">
              <div class="audio-attach my-2" *ngIf="attachment?.msgType === 'AUDIO'" fxLayout="row" fxLayoutAlign="center center">
                <video width="100%" height="53" controls>
                  <source [src]="attachment?.url | prefixDomain" type="audio/mpeg"/>
                </video>
              </div>

            </ng-container>

            <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2">
              <div>
                <!-- <p class="m-0 translate">Translate to</p> -->
              </div>
              <p class="m-0 text-right">
                <time>{{message?.CT | parseDate | date:'dd MMM yyyy, hh:mm a'}}</time>
              </p>
            </div>

          </div>
        </div>
      </div>
    </ng-template>
    <!-- / Receiver template -->



  </div>
</div>
