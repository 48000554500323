import { Inject, Injector, Input, Directive } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ShareQaAnswerRequest, ShareQaFullDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiSocialQA } from './kalgudi-social-qa';
import { KalgudiQaService } from './services/kalgudi-qa.service';

/**
 * Base class definition for all Kalgudi question answer components. Use this
 * as a base class to define your business logic to post an answer to the
 * posted questions.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSocialAnswer<T extends ShareQaAnswerRequest, R extends ShareQaFullDetails> extends KalgudiSocialQA<T, R> {

  @Input()
  questionId: string;

  @Input()
  qa: ShareQaFullDetails;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3,
    );

    // Initialize form
    this.shareForm = this.answerForm;
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Answer form
   */
  private get answerForm(): FormGroup {

    return new FormGroup({
      text: new FormControl(''),
      type: new FormControl('answer'),
      imageURL: new FormControl(''),
      filter: new FormGroup({}),
      groupName: new FormControl(this.DEFAULT_SHARE_VISIBILITY, Validators.required),
      id: new FormControl(),
      lstOfAttachments: new FormControl([]),
    });
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Calls API to share the current post.
   */
  createPostApi(payload: ShareQaAnswerRequest): Observable<R> {

    // Call api to share post
    return this.qaService.postAnswer(this.qa.questionId, payload)
      .pipe(

        // Map response to generic type
        map(r => r as R)
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the share update API request payload.
   */
  protected getCreationPayload(shareFormValue: T): T {

    shareFormValue.id = this.qa.questionId;

    return shareFormValue;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
