<div id="program-search-members">

  <div [ngClass]="{'extra-fields': showExtraFields}">
    <kl-user-picker
      [multiSelect]="true"
      [(selectedUsersMap)]="selectedUsersMap"
      [showExtraFields]="showExtraFields"
      [pageId]="pageId">
    </kl-user-picker>
  </div>

  <div class="pt-0 mx-2 mt-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="space-between center" fxLayoutGap="10px">
    <kl-link-button label="Cancel" buttonColor="warn" (clicked)="close()"></kl-link-button>
    <kl-link-button label="Add followers" buttonColor="primary" (clicked)="addMembers()" [disabled]="!(selectedUsersMap | keyvalue)?.length && (!showLocation?.length && !showCrop?.length)"></kl-link-button>
  </div>
</div>
