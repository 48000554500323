<section id="project-list-grid">
  <!-- Search bar -->
  <div class="mb-3 p-1" fxLayoutAlign="center center">
    <kl-search class="search w-100"
      placeHolder="Type and hit enter to search projects"
      (search)="search();"
      [searchForm]="searchForm">
    </kl-search>
  </div>
  <!-- /Search bar -->
  <div fxLayout="column" fxLayoutGap="20px grid" fxLayout.xs="column">
    <ng-container *ngIf="((stream$ | async) || true) as stream">
      <!-- Page -project-tile  -->
      <div fxFlex="100%" fxFlex.sm="50%" fxFlex.md="50%"  fxFlex.xs="100%" *ngFor="let project of stream; let i = index">
        <div [@floatTop]="{ value: '', params: {delay: i * 50} }" class="projects p-1 mb-1">
          <kl-project-tile
            [projectDetails]="project"
            [overrideDefaultRouteNavigation]="openProjectFullView"
            (deleteProject)="deleteProject(project?.id)"
            (addProjectMembers)="addMembersToProject(project)"
            (updateProject)="showUpdateProject(project?.id)"
            (addTask)="openTaskCreationDialog(project?.id, this.filters)"></kl-project-tile>
        </div>
      </div>
      <!-- /Page -project-tile -->
      <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

        <ng-container *ngIf="!stream || stream?.length <= 0">
          <kl-no-content
            svgIcon= "assets/svgs/projects.svg"
            text="You are not part of any project"
            [actionLink]="(pageDetails?.memberRole === memberRoles?.ADMIN || pageDetails?.memberRole === memberRoles?.CONTRIBUTOR) ? 'Create your first project' : ''"
            progressText="Loading your projects..."
            [progress]="paginator?.loading"
            (action)="openCreateProjectDialog()">
          </kl-no-content>
        </ng-container>

        <!-- Load more -->
        <div class="mt-3" *ngIf="paginator && stream?.length">
          <kl-load-more
            [noMoreText]="'No more projects' | translate"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
        <!-- /Load more -->
      </div>
    </ng-container>
  </div>

  <!-- Float button  -->
  <div *ngIf="memberRole === memberRoles.ADMIN || memberRole === memberRoles.CONTRIBUTOR">
   <!-- <kl-float-button (clicked)="createProject()"></kl-float-button>-->
   <div class="footer-mobile-tab-wrapper fixed-bottom" fxLayout="row" fxHide.gt-xs>

    <ng-container *ngFor="let menu of footerMenus; let first=first;">
      <div class="border-right my-2" *ngIf="!first"></div>
      <div fxFlex fxFlexAlign="center">
        <kl-mobile-tabs [menu]="menu" (clicked)="menu.clickAction()"></kl-mobile-tabs>
      </div>
    </ng-container>
  </div>
  </div>
  <!--/ Float button  -->
</section>
