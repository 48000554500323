import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class KalgudiTaskActivityStateService {

  taskActivityUpdated$: Observable<string>;

  private taskActivityUpdateSubject$ = new BehaviorSubject<any>(null);

  constructor() {

    this.taskActivityUpdated$ = this.taskActivityUpdateSubject$.pipe(
      shareReplay(1),
    );

   }

  taskActivityUpdated(): void {
    this.taskActivityUpdateSubject$.next('');
  }

}
