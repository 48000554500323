import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_PROFILE_PIC, KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiMessageDetails } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'kl-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss']
})
export class ChatBubbleComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  isAllowedToReply: boolean;

  @Input()
  sender: any;

  @Input()
  message: KalgudiMessageDetails;

  @Input()
  showAuthor: boolean;

  @Input()
  isAuthor: boolean;

  @Output()
  replyMessage = new EventEmitter<KalgudiMessageDetails>();

  @Output()
  deleteMessage = new EventEmitter<KalgudiMessageDetails>();

  @Output()
  onHideMessage = new EventEmitter<KalgudiMessageDetails>();

  domain: string;

  gallery: any[];

  profileKey: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor(
    private kalgudiApp: KalgudiAppService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super();

    this.domain = this.environment.coreDomain;

    this.kalgudiApp.profile$
      .pipe(
        // Subscribe only till the instance is alive
        takeUntil(this.destroyed$)
      )
      .subscribe( res => {
        this.profileKey = res.profileKey;
      });
  }

  ngOnInit() {

    this.isAuthor = this.profileKey === this.message.sender.profileKey;

  }

  onDestroyed(): void {}

  /**
   * Emits back to the parent for reply to the particular message
   */
  replyToMsg(): void {

    this.replyMessage.emit(this.message);
  }

  /**
   * Deleted the message
   */
  onDeleteMessage(): void {
    this.deleteMessage.emit(this.message);
  }

  /**
   * Hide the message
   */
  hideMessage(): void {
    this.onHideMessage.emit(this.message);
  }

}
