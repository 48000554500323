import { Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults, KalgudiUserSearchResults } from '@kalgudi/core';
import { KalgudiConnects, KalgudiUserBasicDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiUsersSearchApiService } from './kalgudi-users-search-api.service';

/**
 * Defines methods and Api wrappers for Kalgudi user search services.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiUsersSearchService {

  constructor(
    private api: KalgudiUsersSearchApiService,
  ) { }


  /**
   * Makes an API call to search list of all users with the specified
   * search keyword.
   */
  searchKalgudiUsers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams: PartialData = {},
    groupId?: string,
  ): Observable<KalgudiUserSearchResults> {

    return this.api.searchKalgudiUsers(searchKeyword, offset, limit, extraParams, groupId);
  }

  /**
   * Makes an API call to search list of all sellers with the specified
   * search keyword.
   */
  searchKalgudiSellers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams: PartialData = {},
  ): Observable<KalgudiUserSearchResults> {

    return this.api.searchKalgudiSellers(searchKeyword, offset, limit, extraParams);
  }

  /**
   * Makes an API call to search list of all my kalgudi connects with the specified
   * search keyword.
   */
  searchMyKalgudiConnects(
    searchKeyword: string,
    offset: number,
    limit: number
  ): Observable<KalgudiUserSearchResults> {

    return this.api.searchMyKalgudiConnects(searchKeyword, offset, limit);
  }

  /**
   * Makes an API call to search list of all my kalgudi connects.
   */
  fetchMyKalgudiConnects(offset: number, limit: number): Observable<KalgudiUserBasicSearchResults> {

    return this.api.fetchMyKalgudiConnects(offset, limit)
      .pipe(
        map((r) => {
          r.items = r.items.map((c) => this.mapUserBasicDetails(c));

          return r as KalgudiUserBasicSearchResults;
        })
      );
  }

  /**
   * Initializes the connects into kalgudiUserBasicDetails type
   */
  mapUserBasicDetails(user: KalgudiConnects): KalgudiUserBasicDetails {
    return {
      firstName: user.firstName,
      profileKey: user.userId,
      businessKey: user.businessId,
      businessName: user.businessName,
      businessTypeName: user.businessTypeName,
      profilePicURL: user.profilePicURL,
      profilePicUrl: user.profilePicURL,
      location: user.placeTo.locationLong,
    };
  }
}
