import { Component, OnInit,Input, Inject } from '@angular/core';
import { KALGUDI_PROFILE_BUSINESS_TYPE_NAMES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { StoreProductLevel2, StoreProductLevel3,KalgudiUserBasicDetails, AVAILABLE_STORES } from '@kalgudi/types';

@Component({
  selector: 'kl-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  appId: any;

  @Input()
  productLevel3Title: string;

  @Input()
  productLevel2Details: StoreProductLevel2;

  @Input()
  sellerDetails: KalgudiUserBasicDetails;

  @Input()
  supplierDetails;

  @Input()
  sellerProfileInfo: KalgudiUserBasicDetails;

  @Input()
  progress: boolean;

  @Input()
  sellerDetailsProgress: boolean;

  outputStoreId = AVAILABLE_STORES.OUTPUTS;

  bizType = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;

  constructor(
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig) {

    this.appId = this.environment.appId;
  }

  ngOnInit() {
  }

}
