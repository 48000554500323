import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSms } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LogsActionService } from '../services/logs-action.service';

@Directive()
export abstract class KalgudiSmsTile extends KalgudiDestroyable {

  @Input()
  overRideUpdatePost: (postDetails) => Observable<KalgudiSms>;

  /**
   * SMS details, it must be provided by a stream.
   */
  @Input()
  sms: KalgudiSms;

  private smsLogsActionService: LogsActionService;

  private notifications: KalgudiNotification;

  private util: KalgudiUtilityService;

  constructor(protected injector: Injector) {
    super();

    this.notifications        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.smsLogsActionService = this.injector.get(LogsActionService);
    this.util                 = this.injector.get(KalgudiUtilityService);
  }

  /**
   * Shows Logs list dialog
   */

  showLogs(smsId: string) {
    // open logs dialog
    this.smsLogsActionService.showLogsDialog(smsId)
      .pipe(
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Opens the sms form in update mode
   */
  updateSms() {

    if (this.overRideUpdatePost) {
      this.overRideUpdatePost(this.sms).subscribe(
        res => {
          this.notifications.showMessage('SMS updated');
          this.onPostUpdated(res);
        },
        err => this.util.errorHandler(err)
      );
    }
    /* else {

      // Input dialog UI configuration
      const dialogDetails: KalgudiDialogConfig = {
        title: 'Update SMS',
        acceptButtonTitle: 'Send',
        rejectButtonTitle: 'Cancel',
        data: {
          postDetails: this.postDetails
        }
      };

      // Material dialog configuration
      const dialogConfig: MatDialogConfig = {
        width: '700px',
        maxWidth: '700px',
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
      };

      this.smsUpdateService.showScheduleDialog(dialogDetails, dialogConfig)
        .pipe(
          filter(res => res && res.accepted)
        )
        .subscribe(
          res => {
            this.notifications.showMessage('Post updated');
            this.onPostUpdated(res);
          },
          err => this.util.errorHandler(err)
        );
    } */

  }


  /**
   * calls after updating the post successfully
   */
  protected onPostUpdated(res): void {}
}
