import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { StatusLogsComponent } from './components/status-logs/status-logs.component';
import { StatusMobileDialogComponent } from './components/status-mobile-dialog/status-mobile-dialog.component';
import { KalgudiStatusDialogService } from './services/kalgudi-status-dialog.service';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    StatusDialogComponent,
    StatusMobileDialogComponent,
    StatusLogsComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,
    MatButtonModule,
    MatCardModule,

    FlexLayoutModule,
    TranslateModule
  ],
  entryComponents: [
    StatusDialogComponent,
    StatusMobileDialogComponent,
  ],
  providers: [
    KalgudiStatusDialogService
  ]
})
export class KalgudiStatusDialogModule { }
