import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiAttachUrlModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiBottomSheetService, MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiSocialModule } from '@kalgudi/social';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  KuberPagePromotionalShareUpdateModule,
} from '../kuber-page-promotional-share-update/kuber-page-promotional-share-update.module';
import { PageActivityFiltersModule } from '../page-activity-filters/page-activity-filters.module';
import { KalgudiPageAiPredictionsFiltersModule } from '../page-ai-predictions-filters/page-ai-predictions-filters.module';
import {
  KalgudiProgramMembersPickerDialogModule,
} from '../program-members-picker-dialog/program-members-picker-dialog.module';
import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import {
  MobileProgramShareUpdateFormComponent,
} from './components/mobile-program-share-update-form/mobile-program-share-update-form.component';
import { ProgramShareUpdateFormComponent } from './components/share-update-form/program-share-update-form.component';
import {
  UpdateProgramPostDialogComponent,
} from './components/update-program-post-dialog/update-program-post-dialog.component';
import {
  UpdateProgramPostMobileDialogComponent,
} from './components/update-program-post-mobile-dialog/update-program-post-mobile-dialog.component';
import { KalgudiProgramShareUpdateApiService } from './services/kalgudi-program-share-update-api.service';
import { KalgudiProgramShareUpdateService } from './services/kalgudi-program-share-update.service';
import {MatRadioModule} from '@angular/material/radio';



@NgModule({
  declarations: [
    ProgramShareUpdateFormComponent,
    MobileProgramShareUpdateFormComponent,
    UpdateProgramPostDialogComponent,
    UpdateProgramPostMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    FormsModule,

    FlexLayoutModule,

    ReactiveFormsModule,

    // Material
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMenuModule,
    MomentDateModule,
    MatRadioModule,

    // Kalgudi Libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiProgramTargetMembersModule,
    KalgudiShareUpdateModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiImageChipListModule,
    KalgudiSocialModule,
    MobileMenuBottomSheetModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiTimePickerModule,
    KalgudiStoreProductsSearchModule,

    KalgudiPageAiPredictionsFiltersModule,

    PageActivityFiltersModule,

    KuberPagePromotionalShareUpdateModule,

    TranslateModule
  ],
  exports: [
    ProgramShareUpdateFormComponent,
    MobileProgramShareUpdateFormComponent
  ],
  providers: [
    KalgudiProgramShareUpdateService,
    KalgudiProgramShareUpdateApiService,
    KalgudiBottomSheetService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    MobileProgramShareUpdateFormComponent,
    UpdateProgramPostMobileDialogComponent,
    UpdateProgramPostDialogComponent,
  ]
})
export class KalgudiProgramShareUpdatesModule { }
