import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUsersPickerDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiPageMembersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-kalgudi-share-member-picker-dialog',
  templateUrl: './kalgudi-share-member-picker-dialog.component.html',
  styleUrls: ['./kalgudi-share-member-picker-dialog.component.scss']
})
export class KalgudiShareMemberPickerDialogComponent extends KalgudiUsersPickerDialog<KalgudiShareMemberPickerDialogComponent> implements OnInit {

  pageId: string;
  projectId: string;
  taskId: string;

  activeTabIndex: number;

  readonly TABS = {
    ALL:  { index: 0, title: 'All Members' },
    SELECTED: { index: 1, title: 'Selected Members' },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: KalgudiPageMembersPickerDialogConfig,
    protected dialogRef: MatDialogRef<KalgudiShareMemberPickerDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);

    this.pageId    = dialogData.pageId;
    this.projectId = dialogData.projectId;
    this.taskId    = dialogData.taskId;
  }

  ngOnInit() {
    this.activeTabIndex = this.TABS.ALL.index;
  }

}
