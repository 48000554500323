import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'kl-value-type-form',
  templateUrl: './value-type-form.component.html',
  styleUrls: ['./value-type-form.component.scss']
})
export class ValueTypeFormComponent implements OnInit {
  @Input()
  form: FormGroup;

  @Input()
  saveInProgress: boolean;

  @Input()
  titleFormControlName: string;

  @Input()
  descriptionFormControlName: string;

  @Input()
  field: string;

  @Input()
  type: string;

  @Output()
  closed = new EventEmitter<any>();

  @Output()
  save = new EventEmitter<any>();

  @Input()
  itemsList: any[];

  constructor() {}

  ngOnInit() {}

  saveButton() {
    this.save.emit();
  }

  cancelButton() {
    this.closed.emit();
  }

}
