<div class="w-100">
  <div class="mb-3">
    <button mat-icon-button (click)="cancel()">
      <mat-icon class="text-white">keyboard_backspace</mat-icon>
    </button>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">

    <div id="kalgudi-input-dialog" class="kalgudi-core-theme add-crop-dialog w-100 kl-input-dialog overflow-hidden">

      <div>
        <kl-add-crop [product]="data?.data?.product"
          [isNewProduct]="data?.data?.isNewProduct"
          [selectedBaseCategory]="data?.data?.selectedBaseCategory"
          [profileKey]="data?.data?.profileKey"
          [aka]="data?.data?.aka"
          (dealWithAdded)="onDealAdded($event)">
        </kl-add-crop>
      </div>
    </div>

  </div>
</div>


