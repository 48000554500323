import { Injectable } from '@angular/core';
import { FavoriteLocationsResponse, KalgudiLocation, KalgudiWeatherDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { WeatherCache } from '../classes/weather-cache';
import { KalgudiWeatherApiService } from './kalgudi-weather-api.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiWeatherService {

  constructor(
    private api: KalgudiWeatherApiService,
    private weatherCacheService: WeatherCache,
  ) {}

  /**
   * Calls api to get weather details
   */
  getWeatherDetails(profileKey: string, latitude: string | number, longitude: string | number, language: string): Observable<KalgudiWeatherDetails> {

    return this.weatherCacheService.isWeatherCached(latitude, longitude, language)
      ? this.weatherCacheService.getCachedWeather(latitude, longitude, language)
      : this.api.getWeatherDetails(profileKey, latitude, longitude, language)
          .pipe(
            // Cache the latest weather based on the service response
            tap(weather => this.weatherCacheService.cacheWeather(latitude, longitude, language, weather)),

            catchError((e) => this.weatherCacheService.getCachedWeather(latitude, longitude, language))
          );
  }

  /**
   * Calls api method to get favorite locations
   */
  getFavoriteLocations(profileKey: string, ): Observable<FavoriteLocationsResponse> {
    return this.api.getFavoriteLocations(profileKey);
  }

  /**
   * Calls api method to add in favorite locations
   */
  addToFavoriteLocations(profileKey: string, payload: KalgudiLocation): Observable<KalgudiLocation> {
    return this.api.addToFavoriteLocations(profileKey, payload);
  }
}
