<section class="value-card" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="tile w-100 mb-2">

    <!-- Taxes header -->
    <div class="data-info" fxLayout="row" fxLayoutGap="12px">
      <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <i class="far fa-money-bill-alt taxes"></i>
      </div>

      <div class="data-details" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <div class="details-info">
          <h2 class="profile-title m-0" translate>Taxes</h2>
          <p class="mb-0" translate>Add your Taxes by clicking on edit Icon</p>
        </div>

        <div fxLayoutAlign="center center" (click)="showAddItemForm()" *ngIf="editable">
          <button mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
          </button>

        </div>
      </div>
    </div>
    <!-- /Taxes header -->

    <!-- Add Taxes-->
    <!-- <kl-value-type-form
      *ngIf="isSaveUpdateFormVisible"
      [type]="'select'"
      [itemsList]="itemsList"
      [form]="itemForm"
      [saveInProgress]="saveItemProgress"
      titleFormControlName="field"
      descriptionFormControlName="fieldValue"
      field="field"
      (closed)="hideAddItemForm()"
      >
    </kl-value-type-form> -->
    <!-- / Add Taxes -->

    <!-- list of Taxes -->
    <kl-value-type-list
      [editable]="editable"
      [items]="profileSectionItems"
      itemDescriptionFieldName="fieldValue"
      itemTitleFieldName="fieldName"
      titleText="Taxes"
      descriptionText="Tax value"
      (delete)="deleteItem($event);"
      (edit)="editItem($event)">
    </kl-value-type-list>
    <!-- / list of Taxes -->

  </mat-card>
</section>
