import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-add-live-stock-mobile-dialog',
  templateUrl: './add-live-stock-mobile-dialog.component.html',
  styleUrls: ['./add-live-stock-mobile-dialog.component.scss']
})
export class AddLiveStockMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }
}
