import { Inject, Injector, Input, OnChanges, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { AudioAttachmentService, KalgudiImagePickerService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiAppService, KalgudiUtilityService, KalgudiUsersService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSocial, SocialDataNormalizerService } from '@kalgudi/social';
import {
  Attachment,
  ATTACHMENT_TYPE_MAP,
  FileMimeTypes,
  KalgudiDialogConfig,
  KalgudiPageDetails,
  KalgudiUser,
  S3PolicyPathCategoryMap,
  ShareRequest,
  ShareUpdate,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfilePageService } from '../../farmer-profile/services/kalgudi-profile-page.service';
import { ProfileLogsService } from '../services/profile-logs.service';


@Directive()
export abstract class KalgudiProfileLogsForm extends KalgudiSocial<ShareRequest, ShareUpdate> implements OnChanges {

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  pageDetails: KalgudiPageDetails;

  shareForm: FormGroup;

  urlFormControl = new FormControl({});

  attachmentType = ATTACHMENT_TYPE_MAP;

  cameraAttachment = new FormControl('');

  profile: KalgudiUser;

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;
  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];


  private audioAttachmentService: AudioAttachmentService;
  private kalgudiPageService: KalgudiProfilePageService;
  private profileState: KalgudiProfileStateService;
  private profileLogService: ProfileLogsService;
  private userService: KalgudiUsersService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp
    );

    this.audioAttachmentService = this.injector.get(AudioAttachmentService);
    this.kalgudiPageService     = this.injector.get(KalgudiProfilePageService);
    this.profileState           = this.injector.get(KalgudiProfileStateService);
    this.profileLogService      = this.injector.get(ProfileLogsService);
    this.userService            = this.injector.get(KalgudiUsersService);

    // Initialize share update form
    this.shareForm = this.shareUpdateForm;

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.profile = profile;
        }
      );

    this.subscribeToImageCaptures();

    // Fix: Syncing of multiple form elements with the same form control name
    // @see https://stackoverflow.com/a/53629638/2401088
    this.attachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.attachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share update common form
   */
  private get shareUpdateForm(): FormGroup {

    return new FormGroup({

      logDetails: new FormGroup({
        message: new FormControl(''),

        // imageUri: new FormControl(''),
        // uri: new FormControl(''),
        // uriTitle: new FormControl(''),
        // uriImage: new FormControl(''),
        // domain: new FormControl(''),

        attachments: new FormControl([]),
        status: new FormControl('')
        // productsMetaData: new FormControl([]),
      }),

      assistantDetails: new FormGroup({
        firstName: new FormControl(''),
        email: new FormControl(''),
        profileKey: new FormControl(''),
        role: new FormControl(''),
        profilePicUrl: new FormControl(''),
        mobile: new FormControl(''),
      }),

      member: new FormGroup({
        firstName: new FormControl(''),
        email: new FormControl(''),
        profileKey: new FormControl(''),
        role: new FormControl(''),
        profilePicUrl: new FormControl(''),
        mobile: new FormControl(''),
        location: new FormControl(''),
      }),

      pageDetails: new FormGroup({
        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        relation: new FormControl(''),
        pageProfilePic: new FormControl('')
      }),

    });
  }

  /**
   * Getter for log details form group
   */
  get shareFormLogDetails(): FormGroup {
    return this.shareForm.get('logDetails') as FormGroup;
  }

  /**
   * Message field.
   */
  get shareFormText(): AbstractControl {
    return this.shareFormLogDetails.get('message');
  }

  /**
   * Share form Uri url field.
   */
  get shareFormUri(): AbstractControl {
    return this.shareFormLogDetails.get('uri');
  }

  /**
   * Share form Uri image field.
   */
  get shareFormUriImage(): AbstractControl {
    return this.shareFormLogDetails.get('uriImage');
  }

  /**
   * Share form Uri title field.
   */
  get shareFormUriTitle(): AbstractControl {
    return this.shareFormLogDetails.get('uriTitle');
  }

  /**
   * Share form Uri domain field.
   */
  get shareFormUriDomain(): AbstractControl {
    return this.shareFormLogDetails.get('domain');
  }

  /**
   * Share form Uri products meta data
   */
  get selectedProducts(): AbstractControl {
    return this.shareFormLogDetails.get('productsMetaData');
  }

  /**
   * Share form attachments field.
   */
  get shareFormAttachments(): FormArray {
    return this.shareFormLogDetails.get('attachments') as FormArray;
  }

  /**
   * Share form status field
   */
  get shareFormStatus(): AbstractControl {
    return this.shareFormLogDetails.get('status');
  }

  /**
   * Getter for list of attachments
   */
  get attachmentsControl(): AbstractControl {
    return this.shareFormLogDetails.get('attachments');

  }

  /**
   * Getter for doc type
   */
  get doctype(): string {
    if (this.shareFormAttachmentsControls.value.length) {

      return this.shareFormAttachmentsControls.value[0].msgType;
    } else {
      return '';
    }

  }

  /**
   * Checks whether the url is attached or not
   */
  get isUrlAttachment(): boolean {
    return this.shareFormLogDetails.value.uri;
  }

  /**
   * Getter for page details form group
   */
  get shareFormPageDetails(): FormGroup {
    return this.shareForm.get('pageDetails') as FormGroup;
  }

  /**
   * Getter for page id field
   */
  get shareFormPageId(): AbstractControl {
    return this.shareFormPageDetails.get('pageId');
  }

  /**
   * Getter for page title field
   */
  get shareFormPageTitle(): AbstractControl {
    return this.shareFormPageDetails.get('pageTitle');
  }

  /**
   * Getter for page type field
   */
  get shareFormPageType(): AbstractControl {
    return this.shareFormPageDetails.get('pageType');
  }

  /**
   * Getter for page role field
   */
  get shareFormPageRelation(): AbstractControl {
    return this.shareFormPageDetails.get('relation');
  }

  /**
   * Getter for page profile pic field
   */
  get shareFormPageProfilePic(): AbstractControl {
    return this.shareFormPageDetails.get('pageProfilePic');
  }

  /**
   * Getter for assistant details form group
   */
  get shareFormAssistantDetails(): FormGroup {
    return this.shareForm.get('assistantDetails') as FormGroup;
  }

  /**
   * Getter for assistant user first name field
   */
  get shareFormAssistantFirstName(): AbstractControl {
    return this.shareFormAssistantDetails.get('firstName');
  }

  /**
   * Getter for assistant user email field
   */
  get shareFormAssistantEmail(): AbstractControl {
    return this.shareFormAssistantDetails.get('email');
  }

  /**
   * Getter for assistant user profileKey field
   */
  get shareFormAssistantProfileKey(): AbstractControl {
    return this.shareFormAssistantDetails.get('profileKey');
  }

  /**
   * Getter for assistant user role field
   */
  get shareFormAssistantRole(): AbstractControl {
    return this.shareFormAssistantDetails.get('role');
  }

  /**
   * Getter for assistant user profile pic url field
   */
  get shareFormAssistantProfilePicUrl(): AbstractControl {
    return this.shareFormAssistantDetails.get('profilePicUrl');
  }

  /**
   * Getter for assistant user mobile field
   */
  get shareFormAssistantMobile(): AbstractControl {
    return this.shareFormAssistantDetails.get('mobile');
  }

  /**
   * Getter for member form group
   */
  get shareFormMemberDetails(): FormGroup {
    return this.shareForm.get('member') as FormGroup;
  }

  /**
   * Getter for member first name field
   */
  get shareFormMemberFirstName(): AbstractControl {
    return this.shareFormMemberDetails.get('firstName');
  }

  /**
   * Getter for member email field
   */
  get shareFormMemberEmail(): AbstractControl {
    return this.shareFormMemberDetails.get('email');
  }

  /**
   * Getter for member profileKey field
   */
  get shareFormMemberProfileKey(): AbstractControl {
    return this.shareFormMemberDetails.get('profileKey');
  }

  /**
   * Getter for member role field
   */
  get shareFormMemberRole(): AbstractControl {
    return this.shareFormMemberDetails.get('role');
  }

  /**
   * Getter for member profile pic url field
   */
  get shareFormMemberProfilePicUrl(): AbstractControl {
    return this.shareFormMemberDetails.get('profilePicUrl');
  }

  /**
   * Getter for member mobile field
   */
  get shareFormMemberMobile(): AbstractControl {
    return this.shareFormMemberDetails.get('mobile');
  }

  /**
   * Getter for member location field
   */
  get shareFormMemberLocation(): AbstractControl {
    return this.shareFormMemberDetails.get('location');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  ngOnChanges() {
    if (this.pageId) {

      this.getPageDetails(this.pageId);
    }
  }

  /**
   * Clears the url meta data from the share form
   */
  resetUrlMetadataFromShareForm(): void {
    this.urlFormControl.reset();
  }

  /**
   * Calls api method to fetch page details
   */
  getPageDetails(pageId: string) {

    this.kalgudiPageService.fetchAndUpdatePageState(pageId)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.pageDetails = res;

          if (this.pageDetails) {

            this.patchProgramDetails(this.pageDetails)
          }
        }

      )
  }

  /**
   * Calls API to post the log.
   */
  createPostApi(payload: any): Observable<any> {

    delete payload.geoLocation;

    if (payload.logDetails && payload.logDetails.attachments.length && payload.logDetails.attachments[0].geoLocation) {

      delete payload.logDetails.attachments[0].geoLocation;
    }

    // Call api to share post
    return this.profileLogService.createProfileLog(payload);
  }

  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {

    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);

      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets share form. Overrides default implementation of reset form
   * by Kalgudi social.
   */
  resetForm(): void {
    this.shareFormAttachments.controls = [];
    this.shareForm.reset();
    this.urlFormControl.reset();
  }

  /**
   * Show audio attachment dialog
   */
  showAudioDialog(): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Record',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: this.s3Category,
        url: this.audioAttachment.value && this.audioAttachment.value.url
              ? this.audioAttachment.value.url
              : ''
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.audioAttachmentService.showAudioDialog(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.data),
      )
      .subscribe(
        res => this.audioAttachment.patchValue(res.attachment)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the share update API request payload.
   */
  protected getCreationPayload(shareFormValue: any): any {

    if (this.urlFormControl.value && this.urlFormControl.value.url) {

      shareFormValue.logDetails.uri = this.urlFormControl.value.url;
      shareFormValue.logDetails.domain = this.urlFormControl.value.domain;
      shareFormValue.logDetails.uriImage = this.urlFormControl.value.image;
      shareFormValue.logDetails.uriTitle = this.urlFormControl.value.title;
    }

    const audio: Attachment = this.audioAttachment.value;

    if (audio && audio.url) {
      shareFormValue.logDetails.attachments.push(audio);

      shareFormValue.logDetails.message = shareFormValue.logDetails.message ? shareFormValue.logDetails.message : 'Audio Share';
    }

    return shareFormValue;
  }

  /**
   * Subscribe to image captures
   */
  private subscribeToImageCaptures(): void {

    this.cameraAttachment.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {

          const images = Array.isArray(this.attachmentsControl.value) ? this.attachmentsControl.value : [] ;
          images.push(res);

          this.attachmentsControl.patchValue(images);

        });
  }

  /**
   * Initializing the share form with the program details, assistant user details and member details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    const userDetails = this.kalgudiApp.profileLocal;

    //Assistant user details
    this.shareFormAssistantFirstName.patchValue(userDetails.firstName);
    this.shareFormAssistantEmail.patchValue(userDetails.emailId ? userDetails.emailId : '');
    this.shareFormAssistantProfileKey.patchValue(userDetails.profileKey);
    // this.shareFormAssistantRole.patchValue(userDetails.firstName);
    this.shareFormAssistantProfilePicUrl.patchValue(userDetails.profilePicUrl);
    this.shareFormAssistantMobile.patchValue(userDetails.mobileNo ? userDetails.mobileNo : '');

    //Member details
    this.shareFormMemberFirstName.patchValue(this.profile.firstName);
    this.shareFormMemberEmail.patchValue(this.profile.emailId ? this.profile.emailId : '');
    this.shareFormMemberProfileKey.patchValue(this.profile.profileKey);
    // this.shareFormMemberRole.patchValue(this.profile.firstName);
    this.shareFormMemberProfilePicUrl.patchValue(this.profile.profilePicUrl);
    this.shareFormMemberMobile.patchValue(this.profile.mobileNo ? this.profile.mobileNo : '');
    this.shareFormMemberLocation.patchValue(this.userService.getLocationLong(this.profile));

    this.shareFormStatus.patchValue('PENDING');

    // Page details
    this.shareFormPageId.patchValue(this.pageId);
    this.shareFormPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormPageType.patchValue(pageDetails.pageType);
    this.shareFormPageRelation.patchValue(pageDetails.memberRole);
    this.shareFormPageProfilePic.patchValue(pageDetails.pageProfilePic);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
