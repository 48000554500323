import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class KalgudiTranslationService {

  private readonly localLangKey = 'lang';

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private translate: TranslateService,
  ) {

    this.initActiveLang();
  }

  get activeLanguage(): string {
    return this.getLanguageFromLocal() || this.defaultLanguage;
  }

  get defaultLanguage(): string {
    return this.env.appId && this.env.appId.includes('SHAKTIMAN') ? 'hi' : 'en';
  }

  /**
   * Updates default language cache in the localStorage and sets the default translation language
   */
  updateActiveLanguage(lang: string): void {
    this.setLanguageToLocal(lang);
    this.translate.use(lang);
  }


  /**
   * Initializes active language field
   */
  private initActiveLang(): void {

    let defaultLang = 'en';

    if (this.isLangSet()) {
      defaultLang = this.getLanguageFromLocal();
    } else {
      defaultLang = this.env.appId && this.env.appId.includes('SHAKTIMAN') ? 'hi' : 'en';
    }

    this.updateActiveLanguage(defaultLang);
  }

  /**
   * Gets, the current active translation language key from local storage.
   */
  private getLanguageFromLocal(): string {
    return localStorage.getItem(this.localLangKey);
  }

  /**
   * Sets, the lang to the local storage
   */
  private setLanguageToLocal(lang: string): void {
    localStorage.setItem(this.localLangKey, lang);
  }

  /**
   * Returns `true` if the language is set otherwise returns `false`.
   */
  private isLangSet(): boolean {
    return !!(localStorage.getItem(this.localLangKey));
  }
}
