import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable, checkMobileDevice } from '@kalgudi/core';
import { KalgudiProfileService } from '../../../../../services/kalgudi-profile.service';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BusinessActivitiesMobileDialogComponent } from '../business-activities-mobile-dialog/business-activities-mobile-dialog.component';
import { BusinessActivitiesDialogComponent } from '../business-activities-dialog/business-activities-dialog.component';

@Component({
  selector: 'kl-business-activities-tile',
  templateUrl: './business-activities-tile.component.html',
  styleUrls: ['./business-activities-tile.component.scss']
})
export class BusinessActivitiesTileComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  profileKey: string;

  @Input()
  actionIcon: string;

  @Input()
  basicActivityFormDetails: any;

  @Output()
  getFpoProfileData = new EventEmitter();

  @Input()
  isShowEdit: boolean;

  @Input()
  isPublicPage: boolean;

  editable: boolean;

  consumerDetailsList: any[] = [];
  certifiedDetailsList: any[] = [];
  warehouseDetailsList: any[] = [];
  coldStorageDetailsList: any[] = [];
  exportOrImportDetailsList: any[] = [];
  alliedDetailsList: any[] = [];
  otherBusinessDetailsList: any[] = [];

  noConsumerDetails: string;
  noCertifiedDetails: string;
  noWarehouseDetails: string;
  noColdStorageDetails: string;
  noExportOrImportDetails: string;
  noAlliedDetails: string;
  noOtherBusinessDetails: string;

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector) {
    super();

    this.dialogService = this.injector.get(KalgudiDialogsService);
    this.profileService = this.injector.get(KalgudiProfileService);
    this.profileService.editable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.editable = res));
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

    // Filtering the object which have 'YES' value in array to show in the table
    // for consumer, certified, warehouse, cold-storage, export-import, allied, and other-business activities
    this.consumerDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.consumer?.filter(res => res.showConsumer === 'YES');
    this.certifiedDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.certified?.filter(res => res.showCertified === 'YES');
    this.warehouseDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.warehouse?.filter(res => res.showWarehouse === 'YES');
    this.coldStorageDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.coldStorage?.filter(res => res.showColdStorage === 'YES');
    this.exportOrImportDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.exportsOrImports?.filter(res => res.showImportsOrExports === 'YES');
    this.alliedDetailsList = this.basicActivityFormDetails?.productsAndCommodities?.allied?.filter(res => res.showAllied === 'YES');
    this.otherBusinessDetailsList = this.basicActivityFormDetails?.otherBusinessActivities?.filter(res => res.otherBusiness === 'YES');

    // Check if array have object with value 'NO', return true
    this.noConsumerDetails = this.basicActivityFormDetails?.productsAndCommodities?.consumer?.some(res => res.showConsumer === 'NO');
    this.noCertifiedDetails = this.basicActivityFormDetails?.productsAndCommodities?.certified?.some(res => res.showCertified === 'NO');
    this.noWarehouseDetails = this.basicActivityFormDetails?.productsAndCommodities?.warehouse?.some(res => res.showWarehouse === 'NO');
    this.noColdStorageDetails = this.basicActivityFormDetails?.productsAndCommodities?.coldStorage?.some(res => res.showColdStorage === 'NO');
    this.noExportOrImportDetails = this.basicActivityFormDetails?.productsAndCommodities?.exportsOrImports?.some(res => res.showImportsOrExports === 'NO');
    this.noAlliedDetails = this.basicActivityFormDetails?.productsAndCommodities?.allied?.some(res => res.showAllied === 'NO');
    this.noOtherBusinessDetails = this.basicActivityFormDetails?.productsAndCommodities?.otherBusinessActivities?.some(res => res.otherBusiness === 'NO');

  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows social dialog
   */
  showBusinessActivitiesDialog(isEdit?: boolean) {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Business Activities',
      data: {
        profileKey: this.profileKey,
        basicActivityFormDetails: this.basicActivityFormDetails,
        pageId: 'BUSINESS',
        isEditDialog: isEdit
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '640px',
      maxWidth: '640px',
      panelClass: 'basicInfo',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {},
    };

    return this.openBusinessActivitiesDialog(dialogDetails, dialogConfig)
      .pipe(
        filter((r) => r.accepted),

        // map(r => r.accepted),
      )
      .subscribe(
        res => {
          if(res.accepted) {
            this.getFpoProfileData.emit();
          }
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open social dialog
   */
  private openBusinessActivitiesDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(BusinessActivitiesMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(BusinessActivitiesDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  /**
   * Converts to indian rupee format
   */
  convertIndianRupeeFormat(value) {
    return '₹' + Number(value).toLocaleString('en-IN');
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

  onDestroyed(): void { }


}
