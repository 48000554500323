import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask } from '@kalgudi/types';

import { KalgudiProfileRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-user-member-tasks',
  templateUrl: './user-member-tasks.component.html',
  styleUrls: ['./user-member-tasks.component.scss']
})
export class UserMemberTasksComponent implements OnInit, OnChanges {

  @Input()
  userMemberTasks: any[];

  @Input()
  profileKey: string;

  @Input()
  isAssisted: boolean = false;

  filteredMemberTasks: any[];

  userTasks: any[] = [
    {
      crop: "chilli",
      startDate: "10 jul",
      endDate: "20 jul 2021",
      cropDescription: "Soil Test",
      startingDate: "15 june,20",
      endingDate: "19 jul,20",
      cropName: "chilli"
    },
    {
      crop: "Maize Crop Calender",
      startDate: "10 jul",
      endDate: "20 jul 2021",
      cropDescription: "Soil Test",
      startingDate: "15 june,20",
      endingDate: "19 jul,20",
      cropName: "chilli"
    },
  ];


  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  ngOnChanges() {

    if(this.userMemberTasks && this.userMemberTasks.length) {
      this.filteredMemberTasks = this.userMemberTasks.reduce((task, t) =>
        {
          task[t.projectId] = (task[t.projectId] || []);

          if (t.projectId !== t.productId) {
            task[t.projectId].push(t);
          } else {
            task[t.projectId].push(t);
          }

          return task;
        },
        {},
      );
    }

  }

  /**
   * open task fullview
   * @param taskDetails
   */
  openTaskPage(taskDetails: KalgudiProjectTask) {
    (this.isAssisted && (taskDetails.userRole !== 'MEMBER'))? this.openTaskSubmissionPage(taskDetails) : this.openTaskFullview(taskDetails);
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullview(taskDetails: KalgudiProjectTask) {

    if (taskDetails.pageId) {

      this.appRouting.toProgramTaskFullview( { pageId: taskDetails.pageId, taskId: taskDetails.taskId });
    } else {

      this.appRouting.toTask( { projectId: taskDetails.projectId, taskId: taskDetails.taskId });
    }

  }

  /**
   * Opens task submission page
   */
  openTaskSubmissionPage(taskDetails: KalgudiProjectTask) {

    if(taskDetails.pageId) {
      const url = `/app/pages/${taskDetails.pageId}/tasks/${taskDetails.id}/members/submission`;

      this.router.navigate([url], {queryParams: {selectedProfileKey: this.profileKey}});
    } else {

      const url = `/app/projects/${taskDetails.projectId}/${taskDetails.id}/members/submission`;

      this.router.navigate([url], {queryParams: {selectedProfileKey: this.profileKey}});
    }
  }

}
