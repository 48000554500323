import { Component, Input, OnInit } from '@angular/core';
import { TypeValueMap } from '@kalgudi/types';

@Component({
  selector: 'kl-nutrition-values',
  templateUrl: './nutrition-values.component.html',
  styleUrls: ['./nutrition-values.component.scss']
})
export class NutritionValuesComponent implements OnInit {

  @Input()
  nutrients: TypeValueMap[];

  @Input()
  level3ProductDetails;

  constructor() { }

  ngOnInit() {
  }

}
