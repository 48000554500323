import { Component, OnInit } from '@angular/core';
import { ICellRenderer } from 'ag-grid-community';

@Component({
  selector: 'store-admin-loading-cell-renderer',
  template: `
  <div class="ag-custom-loading-cell" style="padding-left: 10px; line-height: 25px;">
    <i class="fas fa-spinner fa-pulse"></i> <span> {{this.params.loadingMessage}} </span>
  </div>
  `,
  styles: ['']
})
export class AgGridLoadingCellRendererComponent implements OnInit, ICellRenderer {

  public params: any;

  constructor() { }

  ngOnInit() { }

  refresh(params: any): boolean {
    return false;
  }

  agInit(params): void {
    this.params = params;
  }

}
