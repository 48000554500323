import { Injectable } from '@angular/core';
import { KalgudiProject, ProjectType } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiProjectCreationStateService {

  readonly projectType$: Observable<ProjectType>;
  readonly projectDetails$: Observable<KalgudiProject>;
  readonly projectMembers$: Observable<any>;

  private projectTypeSubject    = new Subject<ProjectType>();
  private projectDetailsSubject = new Subject<KalgudiProject>();
  private projectMembersSubject = new Subject();


  constructor() {

    this.projectType$    = this.projectTypeSubject.asObservable();
    this.projectDetails$ = this.projectDetailsSubject.asObservable();
    this.projectMembers$ = this.projectMembersSubject.asObservable();
  }

  /**
   * Pushes the current project type to the project stream.
   */
  updateProjectType(type: ProjectType): void {
    this.projectTypeSubject.next(type);
  }

  /**
   * Pushes the project details to the project details stream.
   */
  updateProjectDetails(projectDetails: KalgudiProject): void {
    this.projectDetailsSubject.next(projectDetails);
  }

  /**
   * Pushes the project member details to the project members stream.
   */
  updateProjectMembers(members: any): void {
    this.projectMembersSubject.next(members);
  }
}

