import { AgmMap, ControlPosition, ZoomControlOptions, ZoomControlStyle } from '@agm/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LatLong } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';


@Component({
  selector: 'kl-geo-tag',
  templateUrl: './geo-tag.component.html',
  styleUrls: ['./geo-tag.component.scss']
})
export class GeoTagComponent implements OnInit, OnChanges, OnDestroy  {

  @ViewChild(AgmMap) agmMap: AgmMap;

  // Telangana, Hyderabad, India
  readonly DEFAULT_LOCATION: LatLong = {
    latitude: 18.1124372,
    longitude: 79.01929969999999,
  };

  @Input()
  location: LatLong;

  @Input()
  LabelPositionTop = false;

  @Input()
  zoomControlOptions: ZoomControlOptions = {
    style: ZoomControlStyle.SMALL,
    position: ControlPosition.RIGHT_BOTTOM
  };

  @Input()
  mapTypeId: 'roadmap' | 'hybrid' | 'satellite' | 'terrain' | string = 'hybrid';

  @Input()
  zoom: number = 17;

  @Input()
  disabled: boolean = false;

  @Input()
  locationLabel: string;

  @Input()
  showError: string;

  @Input()
  markerDraggable: boolean = false;

  @Input()showLatLong: boolean = true;

  @Output()
  geoTagChanged = new EventEmitter<LatLong>();


  /**
   * Flag sets to `true` if the map has been initialized.
   */
  isMapInitialized = false;

  private readonly mapInitialized$: Observable<any>;
  private readonly initPolygon$: Observable<{ polygon: any, polygonPath?: any[]}>;
  private destroyedSubject = new Subject();
  private readonly destroyed$: Observable<any>;


  private watchNumber: number;
  error: string;

  latitude: string | number;
  longitude: string | number;

  constructor() {
  }

  ngOnInit() {

    this.watchNumber = navigator.geolocation.watchPosition(
      (success) => {
        this.location = {
          lat: success.coords.latitude,
          lng: success.coords.longitude,
          latitude: success.coords.latitude,
          longitude: success.coords.longitude,
        };
        this.geoTagChanged.emit(this.location);
        this.error = '';
      },
      (err) => {
        this.error = 'Unable to geo tag your land. Ensure GPS is turned on!'
        // console.error(err);
      }
      )

  }

  ngOnChanges(changes: SimpleChanges): void {

    if(this.showError) {
      this.error = 'Unable to geo tag your land. Ensure GPS is turned on!';
    }

    if ((this.location && this.location.latitude) || (this.location && this.location.longitude)) {
      this.location = {
        lat: this.location.latitude,
        lng: this.location.longitude,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
      };

      this.roundOfForLatLng(this.location);

      this.geoTagChanged.emit(this.location);

    }
  }

  /**
   * On marker drag end
   * @param lat
   * @param lng
   * @param event
   */
  onMarkerDragEnd(lat, lng, event: any) {
    this.latitude = event.coords.lat as number;
    this.longitude = event.coords.lng as number;

    this.location = {
      lat: this.latitude,
      lng: this.longitude,
      latitude: this.latitude,
      longitude: this.longitude,
    };

    this.roundOfForLatLng(this.location);
    this.geoTagChanged.emit(this.location);

    if (navigator) {
      navigator.geolocation.clearWatch(this.watchNumber);
    }

  }

  /**
   * Rounding of upto 6 number after decimal for latitude and longitude
   * @param location
   */
  roundOfForLatLng(location: LatLong) {
    const latitude = +location.latitude;
    this.latitude = (latitude).toFixed(6);
    const longitude = +location.longitude;
    this.longitude = (longitude).toFixed(6);
  }

  ngOnDestroy(): void {
    // Clear polygon event listeners if any

    this.destroyedSubject.next();
    this.destroyedSubject.complete();

    if (navigator) {
      navigator.geolocation.clearWatch(this.watchNumber);
    }
  }
}
