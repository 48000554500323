import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import {
  KalgudiUtilityService
} from "@kalgudi/core";
import { KL_ENV, KalgudiEnvironmentConfig } from "@kalgudi/core/config";
import {
  ApiResponseCommon,

  KalgudiRobocall,

  KalgudiSpecificSubscribers,
  LogsDownloadResponse,
  LogsResponseData,
  PublishRobocallResponse,
  RobocallStatusDownloadResponse,
  StreamUrls
} from "@kalgudi/types";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class RobocallListApiService {
  /**
   * `v2/pages/sms/:smsId/logs`
   */
  private readonly API_LOGS_LIST = `${this.env.restBaseUrlV2}/pages/sms/:smsId/logs`;

  /**
   * `v2/social/shares/robocall-status/:shareId`
   */
  private readonly API_DOWNLOAD_ROBOCALL_STATUS = `${this.env.restBaseUrlV2}/social/shares/robocall-status/:shareId`;

  /**
   * `v2/pages/pageId/sms`
   */
  private readonly API_PUBLISH_SMS = `${this.env.restBaseUrlV2}/pages/:pageId/sms`;

  /**
   * `v2/pages/sms/scheduled/:scheduleId`
   */
  private readonly API_UPDATE_SMS = `${this.env.restBaseUrlV2}/pages/sms/scheduled/:scheduleId`;

  /**
   * `v2/pages/:pageId/pageStream`
   */
  private readonly API_ROBOCALL_STREAM = `${this.env.restBaseUrlV2}/pages/:pageId/pageStream`;

  /**
   * /v2/pages/cem/sms-templates
   */
  private readonly SMS_TEMPLATES_LIST = `${this.env.restBaseUrlV2}/pages/cem/sms-templates`;

  /**
   * /v2/pages/{pageId}/sms/templates
   */
  private readonly SMS_TEMPLATES_LIST_FOR_WL = `${this.env.restBaseUrlV2}/pages/{pageId}/sms/templates`;

  /**
   * /v2/pages/cem/short-url
   */
  private readonly SHORT_URL = `${this.env.restBaseUrlV2}/pages/cem/short-url`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) {}

  /**
   * Calls api to get logs list
   *
   * @param pageId
   */
  fetchLogsList(
    smsId: string,
    pageId: string,
    offset: number,
    limit: number,
    filter: string
  ): Observable<LogsResponseData> {
    // API request query params
    const params = {
      pageId,
      limit: limit.toString(),
      offset: offset.toString(),
      filter,
    };

    const url = this.API_LOGS_LIST.replace(":smsId", smsId);

    return this.httpClient.get<ApiResponseCommon>(url, { params }).pipe(
      // Check and handle API response for errors
      map((res) => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi logs details object type
      map((res) => this.mapLogsStreamResponse(res))
    );
  }

  /**
   * Calls api to download logs list
   */
  downloadRobocallStatus(shareId: string): Observable<RobocallStatusDownloadResponse> {
    const url = this.API_DOWNLOAD_ROBOCALL_STATUS.replace(":shareId", shareId);

    return this.httpClient.get<ApiResponseCommon>(url).pipe(
      // Check and handle API response for errors
      map((res) => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi logs details object type
      map((res) => res.data)
    );
  }

  /**
   * `Calls api method to publish sms`
   */
  publishNewSms(
    pageId: string,
    payload: KalgudiSpecificSubscribers
  ): Observable<KalgudiRobocall> {
    // Api url
    const url = this.API_PUBLISH_SMS.replace(":pageId", pageId);

    return this.httpClient.post<PublishRobocallResponse>(url, payload).pipe(
      // Api response error handler
      map((r) => this.util.apiErrorHandler(r)),

      // Map API response to Kalgudi publish sms
      map((r) => r.data)
    );
  }

  /**
   * `Calls api method to update sms`
   */
  updateScheduledSms(
    scheduleId: string,
    payload: KalgudiSpecificSubscribers
  ): Observable<KalgudiRobocall> {
    // Api url
    const url = this.API_UPDATE_SMS.replace(":scheduleId", scheduleId);

    return this.httpClient.put<PublishRobocallResponse>(url, payload).pipe(
      // Api response error handler
      map((r) => this.util.apiErrorHandler(r)),

      // Map API response to Kalgudi update sms
      map((r) => r.data)
    );
  }

  /**
   * `Calls api method to get robocall stream`
   */
  getRobocallStream(
    pageId: string,
    offset: number,
    limit: number,
    extraParams: any
  ): Observable<{ count: number; items: StreamUrls[] }> {
    // Api url
    const url = this.API_ROBOCALL_STREAM.replace(":pageId", pageId);

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
      ...extraParams,
    };

    return this.httpClient.get<ApiResponseCommon>(url, { params }).pipe(
      // Map API response to Kalgudi robocall stream
      map((res) => ({ count: res.data.count, items: res.data.entitiesStream }))
    );
  }

  /**
   * Maps logs list API response.
   */
  private mapLogsStreamResponse(res: ApiResponseCommon): LogsResponseData {
    return {
      items: res.data.results,
      count: res.data.count,
      pending: res.data.pending,
      success: res.data.success,
      failed: res.data.failed,
    };
  }
}
