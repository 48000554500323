<div class="filter">
  <div fxLayout="row" fxLayoutAlign="end end">
    <div>
      <button mat-icon-button (click)="closeSidebar()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="px-3 pb-3">
    <h3>Filter</h3>
    <!-- Filter by date -->
    <p class="mb-0 sub-heading" translate>Filter by date</p>
    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">
      <div fxFlex="calc(50% - 20px)">
        <mat-form-field class="w-100">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="fromDate">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxFlex="calc(50% - 20px)">
        <mat-form-field class="w-100">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="toDate">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <!-- /Filter by date -->

    <div class="separator mb-2"></div>

    <!-- Project status -->
    <div>
      <mat-checkbox translate>Priority status</mat-checkbox>
    </div>

    <div>
      <p class="mb-1 sub-heading" translate>Project status</p>
      <div fxLayout="column">
        <div *ngFor="let status of projectStatus">
          <mat-checkbox>{{ status | translate}}</mat-checkbox>
        </div>
      </div>
    </div>

    <div class="separator mb-2"></div>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label translate>variety</mat-label>
      <mat-select multiple>
        <mat-option *ngFor="let variety of varietyTags" [value]="variety">{{ variety | translate}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label translate>Template type</mat-label>
      <mat-select multiple>
        <mat-option *ngFor="let type of templateType" [value]="type">{{ type | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /Project status -->
  </div>

</div>
