<ng-container *ngIf="postDetails && postDetails?.questionId">
  <div [id]="postDetails?.questionId" class="kalgudi-qa-tile my-2"
    [@floatTop]="{ value: '', params: { delay: animationDelay } }" [@kalgudiStreamTile]="tileAnimationState"
    [ngClass]="{'deleted': tileAnimationState === 'deleted'}">
    <mat-card class="p-0">
      <!-- Header -->
      <kl-qa-header
        [latestActivity]="postDetails?.latestActivity"
        [authorId]="postDetails?.authorId"
        [memberRole]="memberRole"
        [userProfileKey]="(basicUserDetails$ | async)?.profileKey"
        [showMatMenu]="showMatMenu"
        (deletePost)="deletePost()"
        (report)="report(postDetails?.questionText, postDetails?.questionId)">
      </kl-qa-header>
      <!-- / Header -->

      <!-- Question and Answer Information -->
      <div class="qns-awr" (click)="qaFullView()">
        <div class="px-3 pt-3 asked-info ">
          <!-- Asked Information -->
          <div>
            <kl-post-author
              [LUT]="postDetails?.LUT"
              [TS]="postDetails?.CT"
              [author]="postDetails?.author"
              avatarSize="md" postType="asked"
              [pageId]="postDetails?.entityId"
              [pageTitle]="postDetails?.filter?.pageTitle">
            </kl-post-author>
          </div>
          <div class="question-info py-2" [ngClass]="{'cursor-pointer': !fullView}">
            <p class="mb-0 question-title"
              klGoogleTranslate
              [translationSource]="source"
              [translationTargetList]="translationsList"
              [translationText]="postDetails?.questionText">
              {{ postDetails?.questionText }}
            </p>

            <!-- <div class="w-100 image" *ngIf="postDetails?.imageUri"
              (click)="openFullview(postDetails?.imageUri);$event.stopPropagation()" fxLayoutAlign="center center">
              <img class="mw-100 cursor-pointer" [src]="postDetails?.imageUri | prefixDomain">
            </div> -->


            <ng-container *ngIf="postDetails?.lstOfAttachments?.length">

              <ng-container *ngFor="let item of postDetails?.lstOfAttachments">
                <div class="mb-1" *ngIf="item?.msgType === 'AUDIO'" fxLayout fxLayoutAlign="start center">
                  <div>
                    <mat-icon color="primary">volume_up</mat-icon>
                  </div>
                  <div id="attached-voice-msg" class="ml-1">Voice messages attached</div>
                </div>
              </ng-container>

            </ng-container>

            <ng-container *ngIf="postDetails?.lstOfAttachments?.length">

              <kl-social-attachments [attachments]="postDetails?.lstOfAttachments"></kl-social-attachments>

            </ng-container>



          </div>
          <!-- / Asked Information -->

          <div *ngIf="postDetails?.latestReply && postDetails?.latestReply?.message">
            <!-- Answers count -->
            <a *ngIf="!fullView" fxLayout="row">
              <div class="border-bottom" fxFlex></div>
              <div class="pb-2 answers-count">
                <!-- <span>{{ postDetails?.answersCount }} </span> -->
                <!-- <span translate> {{ postDetails?.answersCount > 1 ? 'answers' : 'answer'}}</span> -->
                <!--<span translate>
                  Latest reply
                </span>-->
              </div>
            </a>
            <!-- / Answers count -->

            <!--  Answer information -->
            <div class="qa-tile-answers p-3" *ngIf="!fullView">
              <div>
                <kl-post-author [TS]="postDetails?.latestReply?.CT" [showLocation]="false"
                  [author]="postDetails?.latestReply?.sender" avatarSize="sm">
                </kl-post-author>
              </div>
              <div class="answer-info pt-1">{{ postDetails?.latestReply?.message }}</div>

              <!-- Image -->
              <ng-container *ngIf="postDetails?.latestReply?.attachments?.length">

                <kl-social-attachments [attachments]="postDetails?.latestReply?.attachments"></kl-social-attachments>

              </ng-container>
              <!-- /Image -->
            </div>


            <!-- / Answer information -->
          </div>

          <div *ngIf="fullView">

            <kl-qa-answers [url]="qaUrl" [qa]="postDetails"></kl-qa-answers>

          </div>
        </div>

        <!-- buttons -->
        <div class="px-3" *ngIf="!fullView">
          <div class="border-top">

          </div>
          <div class="py-1" fxLayout="row" fxLayoutAlign="space-between center">
            <div>
              <!-- <span class="mb-2 pl-3 text-muted" *ngIf="postDetails?.answersCount < 1" translate>
                Be the first to answer
              </span> -->
            </div>
            <div fxLayout="row" class="view-details-button">
              <kl-icon-link-button
                [type]="'button'"
                [svgIcon]="'assets/svgs/chatting.svg'"
                [label]="'View full details'"
                (clicked)="qaFullView()">
              </kl-icon-link-button>
              <!-- <kl-icon-link-button
                *ngIf="postDetails?.answersCount == 0"
                [type]="'button'"
                [matIcon]="'edit'"
                [label]="'Answer'"
                (clicked)="qaFullView()">
              </kl-icon-link-button> -->

              <div (click)="$event.stopPropagation(); $event.preventDefault()" *ngIf="url && (appId === 'SHAKTIMAN' || appId === 'KALGUDI_CORE')">
                <kl-social-shares [url]="qaUrl" btnColor="primary" direction="vertical"></kl-social-shares>
              </div>
            </div>

          </div>
        </div>
        <!-- / Buttons -->

      </div>
      <!-- / Question and Answer Information -->
    </mat-card>
  </div>
</ng-container>
