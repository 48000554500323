import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser, KalgudiUserBasicDetails, PROJECT_TARGET_AUDIENCE_TYPE } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { KalgudiTasksListService } from '../../project-fullview/services/kalgudi-tasks-list.service';
import { AddTaskMembersService } from './../services/add-task-members.service';

@Directive()
export abstract class SearchMembers extends KalgudiSearchStream<KalgudiUser> {

  @Input()
  taskId: string;

  @Input()
  projectId: string;

  progress: boolean;

  /**
   * Required for the two way binding of `selectedUsersMap`
   */
  @Output()
  selectedUsersMapChange = new EventEmitter<{ [key: string]: KalgudiUserBasicDetails }>();

  /**
   * Map of all selected users where key is their profile key and value
   * is of type T.
   */
  @Input()
  selectedUsersMap: { [key: string]: KalgudiUserBasicDetails } = {};

  searchUsers: [];

  users: KalgudiUserBasicDetails[];

  profileKeys = [];

  private addTaskMemberService: AddTaskMembersService;
  private tasksListService: KalgudiTasksListService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util);

    this.pageLimit = 10;

    this.addTaskMemberService = this.injector.get<AddTaskMembersService>(AddTaskMembersService);

    this.pageLimit = 10;
  }

  /**
   * Returns `true` if the user is selected otherwise `false`.
   */
  isUserSelected(user: KalgudiUserBasicDetails): boolean {

    // A user is selected if the user profile details exists in the
    // selected users map.
    return !!(this.selectedUsersMap[user.profileKey]);
  }

  get selectedUsers(): KalgudiUserBasicDetails[] {

    return Object.values(this.selectedUsersMap);
  }

  toggleUserSelection(user: KalgudiUserBasicDetails): boolean {

    // Check if the user is already selected or not
    const selected: boolean = this.isUserSelected(user);

    // Toggle the user selection
    selected ? this.deSelectUser(user) : this.selectUser(user);

    // Emit the event back on every selection
    this.selectedUsersMapChange.emit(this.selectedUsersMap);

    // Toggle initial selection state
    return !selected;
  }

  /**
   * Marks a user as selected. Moves the user to the selected users list.
   */
  selectUser(user: KalgudiUserBasicDetails): void {

    this.selectedUsersMap[user.profileKey] = user;
  }

  /**
   * Marks a user a not selected. Deletes the user map entry from the selected
   * users list.
   */
  deSelectUser(user: KalgudiUserBasicDetails): void {

    delete this.selectedUsersMap[user.profileKey];
  }

  /**
   * Calls the api to add task members
   */
  addMember() {

    this.users = Object.values(this.selectedUsersMap);

    this.profileKeys = this.users.map(u => u.profileKey);

    // Get share payload, to be implemented by its child
    const payload = {
      type: PROJECT_TARGET_AUDIENCE_TYPE.UNICAST,
      users: this.profileKeys,
    };

    this.progress = true;

    this.addTaskMemberService.addTaskMembers(this.taskId, payload)
      .pipe(

        // Unsubscribe to the stream when component gets destroyed
        takeUntil(this.destroyed$),

        // Hide spinner
        finalize(() => this.progress = false),
      )
      .subscribe(
        res => {
          this.notification.showMessage('Added members successfully');
          this.membersAdded(res);
        },
        err => this.membersErrorHandler(err)
      );
  }

  protected membersAdded(res): void {}

  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (searchKeyword) {
      return this.addTaskMemberService.searchMembers(this.projectId, searchKeyword, offset, limit)
      .pipe (

        // Transform the project members Api response to the KalgudiStreamData format
        map(res => {

          return {
            count: res.count,
            items: res.results
          };
        }),
      );

    } else {

      return this.addTaskMemberService.getProjectMembers(this.projectId, offset, limit)
      .pipe(

        // Transform the project members Api response to the KalgudiStreamData format
        map(res => {

          return {
            count: res.count,
            items: res.results
          };
        }),
      );
    }
  }

  protected membersErrorHandler(err) {
    this.notification.showMessage(err);
  }

}
