import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiProductsPickerModule } from '@kalgudi/common';
import { TranslateModule } from '@ngx-translate/core';

import { CommonPageDetailsModule } from '../common-page-details/common-page-details.module';
import { ProcessingPageInfoFormComponent } from './processing-page-info-form/processing-page-info-form.component';
import { ProgramInfoFormComponent } from './program-info-form/program-info-form.component';
import { ProgramInfoViewComponent } from './program-info-view/program-info-view.component';
import { ProgramInfoComponent } from './program-info/program-info.component';
import { StockPageInfoFormComponent } from './stock-page-info-form/stock-page-info-form.component';



@NgModule({
  declarations: [
    ProgramInfoComponent,
    ProgramInfoFormComponent,
    ProgramInfoViewComponent,
    StockPageInfoFormComponent,
    ProcessingPageInfoFormComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material modules
    MatInputModule,
    MatSelectModule,

    FlexLayoutModule,

    //Kalgudi modules
    CommonPageDetailsModule,
    KalgudiProductsPickerModule,

    TranslateModule
  ],
  exports: [
    ProgramInfoComponent,
    ProgramInfoFormComponent,
    ProgramInfoViewComponent,
    StockPageInfoFormComponent,
    ProcessingPageInfoFormComponent
  ]
})
export class KalgudiProgramDetailsModule { }
