<div id="kl-surveys-admins-page">
  <div class="mt-2 admin-page">
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="12px grid" fxLayoutAlign="space-between center"
      *ngIf="(stream$ | async) as admins">
      <div class="user-list-wrapper" fxFlex="50%" fxFlex.xs="100%"
        *ngFor="let user of admins; let i = index">
        <div class="user-list" [@floatTop]="{ value: '', params: {delay: (i % maxPageLimit) * 10}}">
          <kl-selectable-user-tile
            [author]="user"
            avatarSize="md"
            [showRemove]="memberRole === memberRoles?.ADMIN && i != 0"
            (removed)="checkAndRemoveAdmin(user)"
            [navigationAllowed]="true">
          </kl-selectable-user-tile>
        </div>
      </div>

      <div class="w-100" *ngIf="(paginator$ | async) as paginator" fxLayout="row">

        <!-- Load more admins if admins exist -->
        <div class="m-3 w-100" fxLayout="row" fxLayoutAlign="center center" *ngIf="admins?.length">
          <kl-load-more
            [noMoreText]="'No more admins' | translate"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
        <!-- /Load more admins if admins exist -->

        <!-- No content if there no admins -->
        <ng-container *ngIf="!admins?.length">
          <div class="no-content-center w-100" fxLayoutAlign="center center">
            <kl-no-content svgIcon="assets/svgs/admins.svg"
              text="No admins added for this survey yet!"
              iconSize="md"
              actionLink="Add admins"
              progressText="Loading survey admins..."
              [progress]="paginator?.loading"
              (action)="showAddSurveyAdminsDialog()">
            </kl-no-content>
          </div>
        </ng-container>
        <!--/ No content if there no admins -->

      </div>
    </div>
  </div>
</div>

<div *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
  <kl-float-button (clicked)="showAddSurveyAdminsDialog()"></kl-float-button>
</div>
