import { Component, Input,Inject, OnChanges, OnInit } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask } from '@kalgudi/types';
import { KalgudiProfileRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-user-current-tasks',
  templateUrl: './user-current-tasks.component.html',
  styleUrls: ['./user-current-tasks.component.scss']
})
export class UserCurrentTasksComponent implements OnInit, OnChanges {

  @Input()
  userCurrentTasks: any;

  filteredCurrentTasks: any[];

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig
  ) { }

  ngOnInit() {

  }

  ngOnChanges() {

    if(this.userCurrentTasks && this.userCurrentTasks.length) {
      this.filteredCurrentTasks = this.userCurrentTasks.reduce((task, t) =>
        {
          task[t.projectId] = (task[t.projectId] || []);

          if (t.projectId !== t.productId) {
            task[t.projectId].push(t);
          } else {
            task[t.projectId].push(t);
          }

          return task;
        },
        {},
      );

    }

  }

  /**
   * Opens task full view dialog
   */
   openTaskFullview(taskDetails: KalgudiProjectTask) {
    if (taskDetails.pageId) {
      this.appRouting.toProgramTaskFullview({ pageId: taskDetails.pageId, taskId: taskDetails.taskId });
    } else {
      this.appRouting.toTask({ projectId: taskDetails.projectId, taskId: taskDetails.taskId });
    }

  }
}
