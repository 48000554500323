<div class="add-crop py-3 px-2">

  <form autocomplete="off" fxLayout="column" [formGroup]="addCropForm" *ngIf="!product">

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px" *ngIf="env.appId !== 'TRADERS_APP'">

      <!-- Farming farm type -->
      <div fxFlex="calc(50% - 10px)">
        <mat-form-field class="w-100 mat-form-field-md" appearance="outline">
          <mat-label translate>Farming farm type</mat-label>
          <mat-select formControlName="farmingFarmType">
            <mat-option *ngFor="let type of farmingFarmTypes" [value]="type.id">
              {{type.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- /Farming farm type -->

      <!-- Since year -->
      <div fxFlex="calc(50% - 10px)">
        <mat-form-field class="w-100 mat-form-field-md" appearance="outline">
          <mat-label translate>Since year</mat-label>
          <input matInput [placeholder]="'Enter year' | translate" formControlName="sinceYear">
        </mat-form-field>
      </div>
      <!-- /Since year -->
    </div>

    <!-- Search -->
    <kl-base-product-autocomplete
      class="mt-n2"
      formControlName="crop"
      [selectedBaseCategoryId]="selectedBaseCategory ? selectedBaseCategory?.id : ''"
      [placeholder]="selectedBaseCategory? 'Search and select ' + type + ' in ' + selectedBaseCategory?.value : 'Search and select ' + type | translate"
      [label]="selectedBaseCategory? 'Search and select ' + type + ' in ' + selectedBaseCategory?.value :'Search and select ' + type | translate">
    </kl-base-product-autocomplete>
    <!-- /Search -->

  </form>

  <ng-container  *ngIf="!baseProductData && !baseProductData?.productId">
    <!-- <kl-no-content [iconSize]="'sm'"  text="Crop">
    </kl-no-content> -->
    <div class="no-content-wrapper" fxLayout="column" fxLayoutAlign="center center">

      <div fxLayout="column" fxLayoutAlign="center center">
        <svg-icon src="assets/svgs/farm-crop.svg" [svgStyle]="{'width.px': '70', 'height.px': '70'}"
        [applyCss]="true" class="mr-3 store-products-icon"></svg-icon>
      </div>
      <!-- No content texts -->
      <div id="no-social-activity-text" fxLayout="column" fxLayoutAlign="center center">
        <p class="mb-0 text text-muted ml-n3 text-capitalize" translate>{{type}}</p>
      </div>
      <!-- / No content texts -->
    </div>
  </ng-container>

  <ng-container *ngIf="baseProductData && baseProductData?.productId">

    <div fxLayout="column" fxLayoutAlign="center center" class="border-bottom pb-2 mx-4">
      <div>
        <svg-icon src="assets/svgs/completed.svg" class="text-center menu-icons cursor-pointer" [applyCss]="true"
          [svgStyle]="{ 'width.px': 33, 'height.px': 33}"></svg-icon>
      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
        <div *ngIf="baseProductData?.attachments && baseProductData?.attachments && baseProductData?.attachments[0]?.attachments && baseProductData?.attachments[0]?.attachments?.length">
          <img class="product-image rounded-circle"
            [src]="baseProductData?.attachments[0]?.attachments[0]?.url | prefixDomain"
            [klErrorImages]="[
            baseProductData?.attachments[0]?.attachments[0]?.url | prefixDomain,
            domain + '/assets/images/add-crop.png']" />
        </div>
        <div  *ngIf="!baseProductData?.attachments || !baseProductData?.attachments || !baseProductData?.attachments[0]?.attachments || !baseProductData?.attachments[0]?.attachments?.length">
          <img src="assets/images/add-crop.png" class="product-image rounded-circle">

        </div>
        <div class="product-chip">
          <p class="m-0 product-name">{{ baseProductData?.productName }}</p>
          <div *ngIf="aka" fxLayout>
            <a class="nav-link small p-0" #SpecificationTrigger="matMenuTrigger" [matMenuTriggerFor]="alsoKnownAs">Also known as</a>
            <mat-icon>expand_more</mat-icon>
          </div>
          <mat-menu #alsoKnownAs="matMenu" class="also-known px-2">
            <ng-container>
              <span>{{ aka }}</span>
            </ng-container>
          </mat-menu>
        </div>
      </div>

      <div>
        <p class="m-0 selected-crop text-center" translate>
          <span>You have selected</span>
          <span>&nbsp;{{baseProductData?.productName}}</span>
          <span>&nbsp;as your {{type}}</span>
        </p>
      </div>
    </div>

    <div fxLayout fxLayoutAlign="center center" *ngIf="!varietyProgress && baseProductData && baseProductData?.productId && !varietyList?.length">
      <p class="mb-2 selected-crop text-center" translate><span translate>No varieties found under</span> {{baseProductData?.productName}}</p>

    </div>

    <div *ngIf="varietyProgress" fxLayoutAlign="center center">

      <kl-no-content [progress]="true" [iconSize]="'sm'" [progressText]="'Loading varieties' | translate"></kl-no-content>
    </div>

    <div fxLayout="column" fxLayoutAlign="center center" class="border-bottom mt-2" *ngIf="!varietyProgress && baseProductData && baseProductData?.productId && varietyList?.length">
      <div class="varieties overflow-auto w-100">
        <div>
          <p class="mb-2 text-center text-muted" translate>Recommended variety</p>
          <div class="mb-1 w-100" fxLayout="row wrap" fxLayoutGap="12px grid">
          <ng-container *ngFor="let variety of varietyList | slice:0:4; let i = index">
            <div fxFlex="0 0 50%" (click)="onCropSelection(variety, false)">
              <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center"
                class="variety-tile  cursor-pointer" [ngClass]="{'selected-tile': variety?.selected}">
                <div fxFlex="85%"><p class="m-0 text-truncate mw-100"> {{variety?.VProductName}}</p></div>
                <div fxFlex="15%" fxLayoutAlign="end"><svg-icon src="assets/svgs/completed.svg" class="text-center" [ngClass]="{'not-selected': !variety?.selected}" [applyCss]="true"
                  [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon></div>
              </div>
            </div>
          </ng-container>
        </div>
        </div>
        <div class="w-100" *ngIf="searchedSelectedVarieties && searchedSelectedVarieties?.length">
          <!-- <p class="mb-2 text-center text-muted" translate>Recommended variety</p> -->
          <div class="mb-1 w-100" fxLayout="row wrap" fxLayoutGap="12px grid">
          <ng-container *ngFor="let variety of searchedSelectedVarieties; let i = index">
            <div fxFlex="0 0 50%" (click)="onCropSelection(variety, false)">
              <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center"
                class="variety-tile  cursor-pointer" [ngClass]="{'selected-tile': variety?.selected}">
                <div  fxFlex="85%"><p class="m-0 text-truncate mw-100"> {{variety?.VProductName}}</p></div>
                <div  fxFlex="15%" fxLayoutAlign="end"><svg-icon src="assets/svgs/completed.svg" class="text-center" [ngClass]="{'not-selected': !variety?.selected}" [applyCss]="true"
                  [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon></div>
              </div>
            </div>
          </ng-container>
        </div>
        </div>
      </div>

      <!-- <p class="mb-2 text-center text-muted">You didn't find variety, search here</p> -->

      <div class="mt-2 variety-search-wrapper" >
        <!-- <div>
          <p class="mb-2 selected-crop">Select variety</p>
        </div> -->
        <div class="w-100" *ngIf="!varietyList || varietyList?.length <= 4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>Didn't find variety? search here</mat-label>
            <input matInput type="text" disabled>
          </mat-form-field>
        </div>

        <div class="w-100" *ngIf="varietyList && varietyList?.length > 4">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>You didn't find variety? search here</mat-label>

            <div *ngIf="false">
              <mat-label *ngIf="!product" translate>You didn't find variety? search here</mat-label>
              <mat-label *ngIf="product && product?.length && product?.length === 1">
                {{product[0].productId === product[0].baseProductId ? 'Search and select varieties' : 'Add more varieties'}}
              </mat-label>
              <mat-label *ngIf="product && product?.length && product?.length > 1">Add more varieties</mat-label>
            </div>
            <input #autocompleteInput type="text" #searchInput matInput [formControl]="searchControl" [matAutocomplete]="auto">
            <mat-icon matSuffix>search</mat-icon>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"  (optionSelected)='onCropSelection($event.option.value, true)'>
              <mat-option class="variety-autocomplete-option" *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.VProductName | titlecase | trimSpecialCharacters}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <p class="mt-n3 my-1 text-danger" *ngIf="inputFieldValue?.length > 2" @fadeIn>
            <span translate>No varieties found with keyword </span>
            <strong>"{{ inputFieldValue }}"</strong>
          </p>
        </div>
      </div>

      <div class="varieties w-100" *ngIf="false">
        <div fxLayout="row wrap" fxLayoutGap="12px grid"
          *ngIf="product && product.length > 0"
          class="mb-1 w-100">
          <ng-container *ngFor="let variety of product; let i = index">
            <div fxFlex="0 0 50%" *ngIf="variety?.productId !== variety?.baseProductId">
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center"
                class="selected-tile  cursor-pointer">
                <div class="w-100 ml-2"><p class="m-0 text-truncate"> {{variety?.productName}}</p></div>
                <!-- <div><svg-icon src="assets/svgs/completed.svg" class="text-center" [applyCss]="true"
                  [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon></div> -->
              </div>
            </div>
          </ng-container>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="12px grid" class="mb-1 w-100" *ngIf="false">
          <div fxFlex="0 0 50%" *ngFor="let variety of selectedVarieties; let i = index">
            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center"
              class="selected-tile cursor-pointer">
              <div class="ml-2" fxFlex="70%"><p class="m-0 text-truncate"> {{variety?.VProductName}}</p></div>
              <div fxFlex class="mt-1" (click)="removeVariety(i)">
                <mat-icon class="clear-variety">clear</mat-icon>
                <!-- <svg-icon src="assets/svgs/completed.svg" class="text-center not-selected" [applyCss]="true"
                [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon> -->
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-3 w-100">
      <div class="save-button w-100" fxLayout fxLayoutAlign="center center">
        <div>
          <kl-flat-button
            [progress]="cropProgress"
            [disabled]="finalizedVarities?.length > 3"
            (clicked)="addProduct()"
            [label]="label">
          </kl-flat-button>
        </div>
      </div>
      <p class="mb-0 text-danger text-center" *ngIf="varietiesSelected > 3" translate>You can select at maximum 3 varieties at a time</p>
      <span class="text-center text-success" fxLayout="row" fxLayoutAlign="center center" *ngIf="varietiesSelected && varietiesSelected <= 3">
        <span translate>You have selected&nbsp;</span>
        <span>{{ varietiesSelected | json }}</span>
        <span>&nbsp;varieties</span>
      </span>

    </div>
  </ng-container>


</div>
