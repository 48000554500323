<mat-card class="selected-members-list">

  <div class="w-100" fxLayout="column">

    <div class="w-100">
      <span class="select-members text-muted">Selected members for task submission</span>
    </div>

    <div fxLayout="row">

      <!-- Selected members count-->
      <div fxFlex="70%" fxLayoutAlign="start center" class="members-pic">
        <div class="members-list rounded-circle" *ngFor="let member of members">
          <img class="image rounded-circle" width="30px" height="30px"
            [src]="(member?.profilePicUrl) | prefixDomain"
            [alt]="member?.firstName"
            [klErrorImages]="[
              (member?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
          />
        </div>
      </div>
      <!--/ Selected members count -->

      <!-- Show list and Hide list-->
      <div class="selection" fxLayoutAlign="end center" fxFlex="30%">
        <div (click)="showSelectedMembersList()" fxLayoutAlign="center center" *ngIf="!showSelectedList">
          <span class="show-list">Show list</span>
          <mat-icon class="arrow-symbol">keyboard_arrow_down</mat-icon>
        </div>
        <div (click)="showSelectedMembersList()" fxLayoutAlign="center center" *ngIf="showSelectedList">
          <span class="show-list">Hide list</span>
          <mat-icon class="arrow-symbol">keyboard_arrow_up</mat-icon>
        </div>
      </div>
      <!--/ Show list and Hide list -->

    </div>

  </div>

  <!-- List -->
  <div *ngIf="showSelectedList">
    <div *ngFor="let member of members;let last = last;let first = first" class="border-bottom"
      [ngClass]="{'border-bottom-0': last, 'pt-2': first}">

      <kl-task-members-tile-da [member]="member" [isSubmission]="true">
      </kl-task-members-tile-da>

    </div>
  </div>
  <!--/ List -->
</mat-card>
