<button mat-button *ngIf="!showChipButton"
  id="attach-files-button"
  [ngClass]="{'mat-button': !buttonType, 'mat-icon-button': buttonType}"
  class="attach-files"
  color="primary"
  [matTooltip]="'Attach Files' | translate"
  (click)="onAttachClicked()">
  <mat-icon class="button-icon">{{buttonIcon}}</mat-icon>
  <span *ngIf="!buttonType" class="ml-1 button-label" translate>{{buttonLabel}}</span>
</button>

<div *ngIf="showChipButton && !showIconButton" id="image-picker-btn">
  <div fxLayout fxLayoutAlign="center center" class="show-border" [ngClass]="{'button-chip mb-2 rounded-pill border': showBorder}">
    <button class="" [ngClass]="{'rounded-pill': showBorder}" [matTooltip]="'Attach Files' | translate" type="button" mat-button  (click)="onAttachClicked()">
      <div class="icon-label" fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
        <mat-icon class="cam-icon">{{buttonIcon}}</mat-icon>
        <span class="btn-label" translate>{{buttonLabel}}</span>
      </div>
    </button>
  </div>
</div>

<div *ngIf="showChipButton && showIconButton" id="image-picker-btn">
  <div fxLayout fxLayoutAlign="center center">
    <button  mat-icon-button (click)="onAttachClicked()">
      <div fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
        <mat-icon class="camera-icon">{{buttonIcon}}</mat-icon>
      </div>
    </button>
  </div>
</div>

