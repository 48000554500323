<div class="h-100">
  <div class="users-list">
    <kl-user-picker
      [multiSelect]="multiSelect"
      [pageId]="dialogData?.extraParams?.pageId"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-user-picker>
  </div>

  <div class="py-1 px-3 buttons fixed-bottom w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
    <kl-link-button label="Cancel" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
    <kl-link-button label="Select User" buttonColor="primary" (clicked)="ok()"></kl-link-button>
  </div>
</div>
