<div id="kl-survey-submission-form">

  <form autocomplete="off" [formGroup]="submissionForm" fxLayout="row wrap" fxLayout.xs="column"
    fxLayoutAlign="space-between center" *ngIf="(stream$ | async) as submissions">

    <ol class="w-100 questions-list">

      <li class="w-100 question" formArrayName="submissionData"
        *ngFor="let a of submissionDataArray?.controls; let i = index">
        <div [formGroupName]="i">
          <mat-card class="my-1 p-1 px-2 question" [@floatTop]="{ value: '', params: {delay: i * 40}}">
            <div class="p-1" formGroupName="answer">

              <p class="mb-1 question-title">
                <strong>{{a?.value?.question?.question}}</strong>
              </p>

              <div class="my-2">
                <kl-survey-question-attachments [attachments]="a?.value?.question?.attachments">
                </kl-survey-question-attachments>
              </div>

              <div *ngIf="a?.value?.question?.questionType === questionTypes?.TEXT">

                <mat-form-field class="w-100" appearance="outline">
                  <mat-label translate>Your answer</mat-label>
                  <textarea rows="2" matInput [placeholder]="'write your answer here' | translate"
                    formControlName="value"></textarea>
                </mat-form-field>
                <div [ngClass]="{'mt-0': false, 'mt-n3': true}" fxLayout>
                  <!--<button
                  title="Attach image"
                  type="button"
                  mat-icon-button
                  fxLayoutAlign="center center"
                  [disabled]="disableSubmissionForm || disableActions"
                  (click)="attachImage(i, a?.value?.answer?.attachments)">
                  <mat-icon
                    class="image"
                    [ngClass]="{'disable-image': disableSubmissionForm || disableActions}">
                    photo</mat-icon>
                </button>-->
                  <kl-image-picker-form-control formControlName="attachments" [allowMultiple]="true"
                    [showAttachments]="false" [s3Category]="s3Category" [acceptedFileTypes]="acceptedFileTypes">
                  </kl-image-picker-form-control>

                  <!-- Image attachments -->
                  <div class="ml-2 mt-n2">
                    <kl-image-chip-list [attachments]="a?.value?.answer?.attachments"
                      [hideRemove]="disableSubmissionForm || disableActions" (remove)="removeAttachment(i, $event)">
                    </kl-image-chip-list>
                  </div>
                  <!-- / Image attachments -->
                </div>
              </div>

              <div *ngIf="a?.value?.question?.questionType === questionTypes?.YES_NO">
                <mat-radio-group formControlName="value">
                  <p class="mb-2">
                    <mat-radio-button value="YES" translate>Yes</mat-radio-button>
                  </p>
                  <p class="mb-0">
                    <mat-radio-button value="NO" translate>No</mat-radio-button>
                  </p>
                </mat-radio-group>
              </div>


              <div *ngIf="a?.value?.question?.questionType === questionTypes?.DATE">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Your answer</mat-label>
                  <input matInput placeholder="Your answer" [matDatepicker]="startDate" (click)="startDate.open()"
                    readonly formControlName="value">
                  <!-- <mat-error class="mt-3">Please mention start duration</mat-error> -->
                  <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                  <mat-datepicker #startDate></mat-datepicker>
                  <mat-error translate>Please enter expiry date</mat-error>
                </mat-form-field>
              </div>


              <div *ngIf="a?.value?.question?.questionType === questionTypes?.MULTIPLE_CHOICE">
                <mat-radio-group formControlName="value">
                  <p class="mb-2" *ngFor="let option of a?.value?.question?.options">
                    <mat-radio-button [value]="option?.value">{{option?.value}}</mat-radio-button>
                  </p>
                </mat-radio-group>
              </div>

              <div *ngIf="a?.value?.question?.questionType === questionTypes?.MULTIPLE_SELECT_CHECKBOX">
                <div>
                  <div class="mb-2" *ngFor="let option of a?.value?.question?.options; let j=index">
                    <input type="hidden" formControlName="value">
                    <mat-checkbox
                      [value]="option?.value || ''"
                      (change)="updateCheckedOptions(option, $event, a?.value?.question?.questionId)"
                      [checked]="isChecked(a?.value?.answer?.value, option?.value || '')"
                      >
                     <span *ngIf="option?.value">{{ option?.value || '' }}</span>
                    </mat-checkbox>
                  </div>
                </div>
              </div>


              <div fxLayout *ngIf="a?.value?.question?.questionType === questionTypes?.RATING">
                <kl-kalgudi-rating-form-control formControlName="value"
                  [disableActions]='disableActions || disableSubmissionForm'></kl-kalgudi-rating-form-control>

                <!-- <div *ngFor="let item of [1, 2, 3, 4, 5]; let j = index">
                <mat-icon *ngIf="!(disableActions || disableSubmissionForm)"
                  [ngClass]="{'fill-star': a?.value?.answer?.value && (j + 1 <= a?.value?.answer?.value) }"
                  (click)="addRating(i, j)">
                  star
                </mat-icon>
                <mat-icon *ngIf="disableActions || disableSubmissionForm"
                  [ngClass]="{'disable-filled-star': a?.value?.answer?.value && (j + 1 <= a?.value?.answer?.value),
                  'disable-star':  !(a?.value?.answer?.value && (j + 1 <= a?.value?.answer?.value))}"
                  (click)="addRating(i, j)">
                  star
                </mat-icon>
              </div> -->

              </div>

            </div>
          </mat-card>
        </div>
      </li>
    </ol>

    <div class="w-100 pr-1" fxLayout fxLayoutAlign="end end"
      *ngIf="submissionDataArray?.length && (surveyDetails?.surveyBasicDetails?.isResubmittable || !submissionDetails?.submissionId) ">
      <div class="submit-btn" *ngIf="!disableSubmissionForm && !disableActions">
        <kl-flat-button [type]="'submit'" [label]="submissionDetails?.submissionId ? 'Update' : 'Submit'"
          [disabled]="!submissionForm?.valid" [progress]="progress" (clicked)="saveSubmission()">
        </kl-flat-button>
      </div>

      <div *ngIf="disableSubmissionForm || disableActions">
        <p class="mb-0 text-danger" *ngIf="!disableActions" translate>
          {{!surveyDetails?.surveyBasicDetails?.isPublished ? 'Survey is yet to start' : 'Survey is expired'}}

        </p>
      </div>
    </div>

    <!-- No content if there no questions -->
    <div class="w-100" *ngIf="(paginator$ | async) as paginator" fxLayout="row">
      <ng-container *ngIf="!submissions?.length">
        <div class="no-content-center w-100" fxLayoutAlign="center center">
          <kl-no-content svgIcon="assets/svgs/surveys-icon.svg" text="Survey submission not available!" iconSize="md"
            progressText="Loading survey..." [progress]="paginator?.loading">
          </kl-no-content>
        </div>
      </ng-container>
    </div>
    <!--/ No content if there no questions -->
  </form>


</div>