import { Injectable } from '@angular/core';
import { GeoFenceDetails } from '@kalgudi/types';

@Injectable()
export class SatelliteAdvisoriesService {

  constructor() { }


  /**
   * Logic taken from ConserWater dashboard
   *
   * @see https://conserwater.herokuapp.com/dashboard/
   */
  convertLatLongToPoints(
    points: GeoFenceDetails[],
    width: number,
    height: number,
    bbox: number[] = [],
  ) {

    // close polygon so that last point equals first point
    const closed =
      points[0].lat == points[points.length - 1].lat &&
      points[0].lng == points[points.length - 1].lng;

    if (!closed) {
      points.push(points[0]);
    }

    width -= 4;
    height -= 4;

    // const width = 628; // 799; // 628;
    // let height = 281; // 300; //281;
    let widthHalf = 0;

    if (window.innerWidth <= 893) {
      widthHalf = width;
    } else {
      widthHalf = Math.round(width / 2.0 - 30);
    }

    if (!bbox || bbox.length <= 0) {
      return;
    }

    let lat1 = bbox[0];
    let lng1 = bbox[1];
    let lat2 = bbox[2];
    let lng2 = bbox[3];

    const latAxis = [];
    const lngAxis = [];
    const lngAxisHalf = [];

    for (let lat = lat1; lat > lat2; lat += (lat2 - lat1) / height) {
      latAxis.push(lat);
    }

    for (var lng = lng2; lng < lng1; lng += (lng1 - lng2) / width) {
      lngAxis.push(lng);
    }

    for (var lng = lng2; lng < lng1; lng += (lng1 - lng2) / widthHalf) {
      lngAxisHalf.push(lng);
    }

    const pointsConverted = [];
    const pointsConverted_half = [];
    //console.log(JSON.stringify(bbox))
    //console.log(JSON.stringify(points))
    for (let point_idx = 0; point_idx < points.length; point_idx++) {
      let lat = +points[point_idx].lat;
      let lng = +points[point_idx].lng;

      let latConv = latAxis.length - 1;
      let lngConv = lngAxis.length - 1;

      for (let latAIdx = 0; latAIdx < latAxis.length; latAIdx++) {
        let lat_a = latAxis[latAIdx];
        if (Math.abs(lat_a - lat) < (lat1 - lat2) / height) {
          latConv = latAIdx;
          break;
        }
      }

      for (var lngAIdx = 0; lngAIdx < lngAxis.length; lngAIdx++) {
        let lng_a = lngAxis[lngAIdx];
        if (Math.abs(lng_a - lng) < (lng1 - lng2) / width) {
          lngConv = lngAIdx;
          break;
        }
      }

      let lngConvHalf;

      for (
        var lngAIdx = 0;
        lngAIdx < lngAxisHalf.length;
        lngAIdx++
      ) {
        let lng_a = lngAxisHalf[lngAIdx];
        if (Math.abs(lng_a - lng) < (lng1 - lng2) / widthHalf) {
          lngConvHalf = lngAIdx;
          break;
        }
      }
      pointsConverted.push([latConv, lngConv]);
      pointsConverted_half.push([latConv, lngConvHalf]);
      //console.log(lat+""+lng+""+[lat_conv, lng_conv])
    }
    let pointsStr = "";
    let pointsStrHalf = "";
    for (let p = 0; p < pointsConverted.length - 1; p++) {
      pointsStr +=
        pointsConverted[p][1] + "," + pointsConverted[p][0] + " ";
      pointsStrHalf +=
        pointsConverted_half[p][1] +
        "," +
        pointsConverted_half[p][0] +
        " ";
    }

    return pointsStr;
  }
}
