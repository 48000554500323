import { Injector, Input, Directive } from '@angular/core';
import { FormArray } from '@angular/forms';
import { IdValueMap, PageShareTargetAudienceRequest } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { KalgudiShareTargetMembersService } from '../services/kalgudi-share-target-members.service';


@Directive()
export abstract class KalgudiShareTargetMembers {

  @Input()
  pageId: string;

  @Input()
  isSms: boolean;

  @Input()
  taskId: string;

  @Input()
  projectId: string;

  @Input()
  hideProductsFilter: boolean = false;

  @Input()
  hideBusinessFilter: boolean = false;

  @Input()
  hideLocationFilter: boolean = false;

  @Input()
  filters: {
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  };

  @Input()
  headingLabel = 'Select the target audience';

  @Input()
  targetAudienceLabel = 'Target audience count:';

  @Input()
  hideTargetAudienceCount = false;

  @Input()
  productsList: IdValueMap[];

  @Input()
  productsFormArray: FormArray;

  @Input()
  organizationsList: IdValueMap[];

  @Input()
  organizationsFormArray: FormArray;

  @Input()
  businessTypesList: IdValueMap[];

  @Input()
  businessTypesFormArray: FormArray;

  @Input()
  locationsList: IdValueMap[];

  @Input()
  locationsFormArray: FormArray;

  @Input()
  countriesList: IdValueMap[];

  @Input()
  countriesFormArray: FormArray;

  @Input()
  statesList: IdValueMap[];

  @Input()
  statesFormArray: FormArray;

  @Input()
  districtsList: IdValueMap[];

  @Input()
  districtsFormArray: FormArray;

  locationRangeFilter = false;

  /**
   * Stream of target audience count
   */
  readonly targetAudienceCount$: Observable<number>;

  private readonly refreshTargetAudienceSubject = new BehaviorSubject<PageShareTargetAudienceRequest>(null);

  private filterService: KalgudiShareTargetMembersService;


  constructor(
    protected injector: Injector,
  ) {

    this.filterService = this.injector.get(KalgudiShareTargetMembersService);

    // Add hook to the refresh target audience stream
    this.targetAudienceCount$ = this.refreshTargetAudienceSubject
      .pipe(

        // Do nothing for no filters
        filter(filters => !!filters),

        // tap(r => console.log('filter changed 1', r)),

        // Fetch latest target audience count from api
        switchMap((filters) => this.filterService.fetchSocialPostTargetAudienceCount(filters)),
      );
  }

  /**
   * Event handler for locations to location range filter toggle
   */
  onLocationRangeFilterChange(val: boolean): void {
    this.locationRangeFilter = val;

    // Location filter is selected, clear location ranges
    val ? this.clearLocationFilters() : this.clearLocationRangeFilters();
  }

  /**
   * Refreshes share target audience
   */
  refreshTargetAudience(): void {

    this.refreshTargetAudienceSubject.next(this.prepareTargetAudiencePayload());
  }

  /**
   * Prepares target audience count request payload
   */
  protected prepareTargetAudiencePayload() {
    return {
      pageId: this.pageId,
      isSms: !!(this.isSms),
      valid: true,
      isPublishedThroughTool: false,
      products: this.productsFormArray.value,
      businessTypes: this.businessTypesFormArray.value,
      locations: this.locationsFormArray.value,
      countries: this.countriesFormArray.value,
      states: this.statesFormArray.value,
      districts: this.districtsFormArray.value,
    };
  }

  /**
   * Clears all location range filters list.
   */
  private clearLocationRangeFilters(): void {
    this.countriesFormArray.clear();
    this.statesFormArray.clear();
    this.districtsFormArray.clear();
  }

  /**
   * Clears all location filters list.
   */
  private clearLocationFilters(): void {
    this.locationsFormArray.clear();
  }

}
