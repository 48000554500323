import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SocialComments } from '@kalgudi/types';

@Component({
  selector: 'kl-social-comments',
  templateUrl: './social-comments.component.html',
  styleUrls: ['./social-comments.component.scss']
})
export class SocialCommentsComponent implements OnInit {

  @Input()
  kuberPage: boolean;

  @Input()
  comments: SocialComments[] = [];

  @Input()
  commentForm: FormGroup;

  @Input()
  progress: boolean;

  @Output()
  commented = new EventEmitter<FormGroup>();

  constructor() { }

  ngOnInit() { }

  /**
   * Emits an event to the parent
   */
  comment(): void {
    this.commented.emit(this.commentForm);
  }

}
