import { Injectable } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiUtilityService, LikeShareRequest } from '@kalgudi/core';
import { KALGUDI_HOME_STREAM_EVENTS, SocialDataNormalizerService } from '@kalgudi/social';
import {
  KalgudiMessageDetails,
  KalgudiQAChatListApi,
  ShareQaAnswer,
  ShareQaAnswerComment,
  ShareQaAnswerLike,
  ShareQaAnswerRequest,
  ShareQaFullDetails,
  ShareQaRequest,
  SimilarQuestion,
} from '@kalgudi/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { MobileQaFormComponent } from '../components/mobile-qa-form/mobile-qa-form.component';
import { KalgudiQaApiService } from './kalgudi-qa-api.service';

/**
 * Kalgudi QA posting/creation service.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiQaService {


  private liked = new BehaviorSubject<ShareQaAnswerLike>(null);
  private commented = new BehaviorSubject<ShareQaAnswerLike>(null);
  private answered = new BehaviorSubject<ShareQaFullDetails>(null);

  constructor(
    private util: KalgudiUtilityService,
    private qaApi: KalgudiQaApiService,
    private socialDataNormalizer: SocialDataNormalizerService,
    private dialogService: KalgudiDialogsService
  ) { }



  // --------------------------------------------------------
  // #region Getters and setters
  // --------------------------------------------------------

  /**
   * Stream of event emitted when an answer is liked
   */
  get liked$(): Observable<ShareQaAnswerLike> {
    return this.liked
      .pipe(

        // Filter null values
        filter(r => r !== null)
      );
  }

  /**
   * Stream of event emitted when there is a comment on answer
   */
  get commented$(): Observable<ShareQaAnswerLike> {
    return this.commented
      .pipe(

        // Filter null values
        filter(r => r !== null)
      );
  }

  /**
   * Stream of event emitted when a user posts an answer on a question
   */
  get answered$(): Observable<ShareQaFullDetails> {
    return this.answered
      .pipe(

        // Filter null values
        filter(r => r !== null)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  /**
   * Posts a question to the social qa API.
   *
   * @param payload Qa request payload
   */
  postQuestion<T extends ShareQaRequest>(req: T): Observable<ShareQaFullDetails> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<ShareQaRequest>(req);

    return this.qaApi.postQuestion(payload)
      .pipe(

        // Attach an event property with the shared question.
        // This event is used by the streams
        tap(r => r.event = KALGUDI_HOME_STREAM_EVENTS.QUESTION )
      );
  }

  /**
   * Posts an answer to a question.
   *
   * @param payload Answer request payload
   */
  postAnswer(questionId: string, request: ShareQaAnswerRequest): Observable<ShareQaFullDetails> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<ShareQaAnswerRequest>(request);

    return this.qaApi.postAnswer(questionId, payload)
      .pipe(
        tap(r => this.answered.next(r))
      );
  }

  /**
   * Likes an answer.
   *
   * @param payload Answer request payload
   */
  likeAnswer<T extends LikeShareRequest>(questionId: string, answerId: string, request: T): Observable<ShareQaAnswerLike> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<LikeShareRequest>(request);

    return this.qaApi.likeOrComment(questionId, answerId, payload, 'likes')
      .pipe(
        tap(r => this.liked.next(r))
      );
  }

  /**
   * Comments on an answer
   *
   * @param payload Answer request payload
   */
  commentAnswer<T extends LikeShareRequest>(questionId: string, answerId: string, request: T): Observable<ShareQaFullDetails> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<LikeShareRequest>(request);

    return this.qaApi.likeOrComment(questionId, answerId, payload, 'comments');
      // .pipe(
      //   tap(r => this.commented.next(r))
      // );
  }

  /**
   * Fetches the question object from the Api.
   *
   * @param questionId Question id to get
   */
  getQuestion<R extends ShareQaFullDetails>(questionId: string): Observable<R> {

    return this.qaApi.getQuestion<R>(questionId)
      .pipe(

        // Map qa author details
        tap(r => this.socialDataNormalizer.mapQaAuthorDetails(r))
      );
  }

  /**
   * Gets list of comments for an answer
   */
  getComments(questionId: string, answerId: string): Observable<ShareQaAnswerComment[]> {
    return this.qaApi.getComments(questionId, answerId);
  }

  /**
   * Gets, answers from the s3. It maps the basic answer details with the
   * complete answer details.
   *
   * It also maps the author details in each answer object.
   */
  getCompleteAnswer<R extends ShareQaAnswer>(answer: R): Observable<R> {

    return this.util.fetchJsonFromUrl<R>(answer.answerUrl)
      .pipe(

        // Merge basic answer details object with the complete answer details object
        map(r => {
          return {

            // Expand all fields of basic answer details
            ...answer,

            // Expand all fields of complete answer details.
            // It will override all the matching fields of `answer` object
            ...r
          };
        }),

        tap((r) => this.socialDataNormalizer.mapQaAnswersAuthorDetails([r as any])[0]),

        // On error return the basic answer details field
        catchError((err) => of(answer))
      );
  }

  /**
   * Resets Qa stream events. Calling this method emits `null` values
   * to the qa `liked`, `commented` and `answered` streams.
   */
  resetQaStream(): void {
    this.liked.next(null);
    this.commented.next(null);
    this.answered.next(null);
  }


  /**
   * Calls a API to delete a post
   *
   * @param shareId
   */
  deletePost(shareId: string): Observable<boolean> {
    return this.qaApi.deletePost(shareId);
  }

  /**
   * Calls api to get popular qa
   */
  getPopularQa(sourceFrom: string): Observable<SimilarQuestion> {
    return this.qaApi.getPopularQa(sourceFrom);
  }

  /**
   * Calls api to get similar qa
   */
  getSimilarQa(questionId: string): Observable<SimilarQuestion> {
    return this.qaApi.getSimilarQa(questionId);
  }


  /**
   * Opens Mobile qa form dialog
   */
  openMobileQaForm(): void {
    this.dialogService.openMobileDialog(MobileQaFormComponent, {
      data: { },
      title: 'Questions and answers',
    });
  }

  /**
   * Calls an api to get the stream of replies
   */
  getQAChats(questionId: string): Observable<KalgudiQAChatListApi> {

    return this.qaApi.getQAChats(questionId)
      .pipe(

        map((res: KalgudiQAChatListApi) => {
          const msg = this.prepareQuestionAsMessage(res.questionData);

          res.items.unshift(msg);

          return res;
        })
      );
  }


  /**
   * Calls api to post the reply
   */
  postReply(questionId: string, payload: any): Observable<KalgudiMessageDetails> {

    return this.qaApi.postReply(questionId, payload);
  }

  /**
   * Calls api to delete the answer
   */
  deleteReply(questionId: string, messageId: string): Observable<boolean> {
    return this.qaApi.deleteReply(questionId, messageId);
  }

  /**
   * Calls api to hide a message
   */
  hideMessage(questionId: string): Observable<boolean> {
    return this.qaApi.hideMessage(questionId);
  }

  /**
   * Prepares the question as message and inserts it into the chat list
   */
  private prepareQuestionAsMessage(questionDetails: ShareQaFullDetails): KalgudiMessageDetails {

    const msg: KalgudiMessageDetails = {
      message: questionDetails.questionText,
      CT: questionDetails.CT,
      attachments: questionDetails.lstOfAttachments,
      questionId: questionDetails.questionId,
      sender: {
        profilePicURL: questionDetails.authorProfilePicURL,
        businessName: questionDetails.authorBizName,
        businessTypeName: questionDetails.authorBizType,
        firstName: questionDetails.authorName,
        profileKey: questionDetails.authorId,
        profilePicUrl: questionDetails.authorProfilePicURL,
        locationLong: questionDetails.authorLocation
      },
      type: 'QUESTION'
    };

    return msg;
  }
}
