<section class="value-card" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="tile w-100 mb-2 overflow-hidden">

    <!--About header-->
    <div class="data-info border-bottom pb-2" fxLayout="row" fxLayoutGap="12px">
      <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <i class="fas fa-info-circle about"></i>
      </div>

      <div class="data-details" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <!-- User details-->
        <div class="details-info">
          <h2 class="profile-title m-0 truncate w-100" translate>About</h2>
          <!-- <a>
            <span translate>View </span>
            <span>{{user?.additionalBizDetails?.businessTypeName}} </span>
            <span translate> page</span>
          </a> -->
        </div>
        <!-- / User details-->

        <!-- Toggle button -->
        <div (click)="editToggle()" *ngIf="editable">
          <button mat-icon-button color="primary" class="icon">
            <mat-icon>{{ editFlag ? 'edit' : 'clear' }}</mat-icon>
          </button>
        </div>
        <!-- / Toggle button -->

      </div>
    </div>
    <!-- /About header-->

    <!-- Org Tile-->
    <kl-profile-about-org-tile
      (showEdit)="showEdit($event)"
      [user]="user"
      [editFlag]="editFlag"
      [editable]="editable"
      (openTCDialog)="showTermsAndConditionsDialog($event)">
    </kl-profile-about-org-tile>
    <!-- / Org Tile-->

    <!-- User Tile -->
    <!-- <kl-profile-about-user-tile [user]="user" [editFlag]="editFlag"></kl-profile-about-user-tile> -->
    <!-- / User Tile -->

  </mat-card>

</section>
