import { Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { IdValueMap } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileActionService } from '../../../services/kalgudi-profile-action.service';
import { LiveStockService } from '../services/live-stock.service';
import { DatePipe } from '@angular/common';

@Directive()
export abstract class KalgudiLiveStockForm extends KalgudiDestroyable {

  @Input()
  stockType: any;

  @Input()
  stock: any;

  @Input()
  showEditFields: boolean = false;

  @Output()
  closeDialog = new EventEmitter();

  addLiveStockForm: FormGroup;

  geoTag: string;
  maleTag: string;
  femaleTag: string;
  profileKey: string;
  geoTagDetails: any;

  batches = [
    1, 2, 3, 4, 5
  ];

  readonly sizeInList: IdValueMap[] = [
    {id: 'sq.ft' , value: 'Sq.ft'},
    {id: 'meters', value: 'Meters'},
    {id: 'cm', value: 'Cm'},
  ];

  private fb: FormBuilder;
  private notification: KalgudiNotification;
  private liveStockService: LiveStockService;
  private kalgudiAppService: KalgudiAppService;
  private util: KalgudiUtilityService;
  private profileActionService: KalgudiProfileActionService;
  private datePipe: DatePipe

  constructor(
    protected injector: Injector
  ) {
    super();
    this.fb                   = this.injector.get(FormBuilder);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.liveStockService     = this.injector.get(LiveStockService);
    this.kalgudiAppService    = this.injector.get(KalgudiAppService);
    this.util                 = this.injector.get(KalgudiUtilityService);
    this.profileActionService = this.injector.get(KalgudiProfileActionService);
    this.datePipe             = this.injector.get(DatePipe);
    this.addLiveStockForm    = this.newLiveStockForm;

    this.profileKey          = this.kalgudiAppService.profileLocal.profileKey;
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Getter for product form group
   */
  get product(): any {
    return this.addLiveStockForm.get('product');
  }

  /**
   * Getter for dimensions group
   */
  get dimensions(): AbstractControl {
    return this.addLiveStockForm.get('dimensions');
  }

  /**
   * Getter for total chicks
   */
  get totalChicks(): AbstractControl {
    return this.addLiveStockForm.get('totalQuantity');
  }

  /**
   * Getter for alternative name
   */
  get alternativeName(): AbstractControl {
    return this.addLiveStockForm.get('alternativeName');
  }

  /**
   * Getter for length
   */
  get length(): AbstractControl  {
    return this.dimensions.get('length');
  }

  /**
   * Getter for breadth
   */
  get breadth(): AbstractControl  {
    return this.dimensions.get('breadth');
  }

  /**
   * Getter for breed name
   */
  get breedName(): AbstractControl  {
    return this.addLiveStockForm.get('breedName');
  }

  /**
   * Getter for male count
   */
  get maleCount(): AbstractControl  {
    return this.addLiveStockForm.get('maleCount');
  }

  /**
   * Getter for female count
   */
  get femaleCount(): AbstractControl  {
    return this.addLiveStockForm.get('femaleCount');
  }

  /**
   * Getter for age
   */
  get age(): AbstractControl  {
    return this.addLiveStockForm.get('age');
  }

  /**
   * Getter for mortality
   */
  get mortality(): AbstractControl  {
    return this.addLiveStockForm.get('mortality');
  }

  /**
   * Getter for available quantity
   */
  get availableQuantity(): AbstractControl  {
    return this.product.get('availableQuantity');
  }

  /**
   * Getter for average weight
   */
  get averageWeight(): AbstractControl  {
    return this.addLiveStockForm.get('averageWeight');
  }

  /**
   * Getter for batch no
   */
  get batchNo(): AbstractControl  {
    return this.addLiveStockForm.get('batchNo');
  }

  /**
   * Getter for date
   */
  get date(): AbstractControl  {
    return this.addLiveStockForm.get('date');
  }

      /**
   * Getter for unit form control
   */
  get unit(): AbstractControl  {
    return this.dimensions.get('unit');
  }

  /**
   * Getter for product id form control
   */
  get productId(): AbstractControl  {
    return this.product.get('productId');
  }

  /**
   * Getter for product name form control
   */
  get productName(): AbstractControl  {
    return this.product.get('productName');
  }

  /**
   * Getter for type form control
   */
  get type(): AbstractControl  {
    return this.addLiveStockForm.get('type');
  }

  /**
   * Getter for attachments form control
   */
  get attachments(): AbstractControl  {
    return this.addLiveStockForm.get('attachments');
  }

  /**
   * Getter for location form control
   */
  get location(): AbstractControl  {
    return this.addLiveStockForm.get('location');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Patch value for form array form controls
   */
  patchValues(productId, productName, availableQuantity) {
    return this.fb.group({
      productId: [productId],
      productName: [productName],
      availableQuantity: [availableQuantity]
    })
  }

  /**
   * Restricting dot, e and different signs for number field
   * @param value
   */
  restricting(value: any, id: string) {
    value = id === 'averageInputField' ? value.replace(/[e\+\-]/gi, '') : value.replace(/[e\+\-\.]/gi, '');

    const htmlInputElement = <HTMLInputElement>document.getElementById(id);

    htmlInputElement.value = value;
  }

  /**
   * Open geo tag land dialog box
   */
  showAddGeoTagLandDialog() {
    this.profileActionService.showAddLandDetailsDialog(true, this.geoTagDetails)
    .subscribe(res => {
      this.geoTagDetails = res;
      this.location.patchValue(res);
    })
  }

  /**
   * Set label based on condition
   */
  setLabelBasedOnCondition() {
    this.geoTag = this.stockType.title === 'POULTRY' ? `Geo-Tag of Coop (lat' long')` : (this.stockType.title === 'FISH' || this.stockType.title === 'PRAWN') ? `Geo-Tag of pond (lat' long')` : `Geo-Tag of sheed (lat' long')`;
    this.maleTag = (this.stockType.title === 'POULTRY' || this.stockType.title === 'COWS' || this.stockType.title === 'BUFFALOES')
                ? (`Male ${this.stockType.title === 'POULTRY' ? 'birds' : this.stockType.title}`)
                  : this.stockType.title === 'SHEEP' ?
                    'Rams (Male sheep)' : this.stockType.title === 'GOAT' ? 'Buck (Male goat)' : this.stockType.title === 'PIGGERY' ? 'Boars (Male piggery)' : 'Breeder fish';
    this.femaleTag = (this.stockType.title === 'POULTRY' || this.stockType.title === 'COWS' || this.stockType.title === 'BUFFALOES')
    ? (`Female ${this.stockType.title === 'POULTRY' ? 'birds' : this.stockType.title}`)
      : this.stockType.title === 'SHEEP' ?
        'Ewes (Female sheep)' : this.stockType.title === 'GOAT' ? 'Does/Nanny (Female goat)' : this.stockType.title === 'PIGGERY' ? 'Gilts (Female piggery)' : 'Fatning fish';
  }

  /**
   * Save the live stock
   */
  addLiveStock() {
    this.notification.showSpinner();

    const payload = this.preparePayload(this.util.clone(this.addLiveStockForm.value));

    this.liveStockService.postLiveStock(this.profileKey, payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() =>  this.notification.hideSpinner())
      )
      .subscribe(
        res => this.addSuccessHandler('Live stock added successfully'),
        err => this.addErrorHandler(err)
      )
  }

  /**
   * Update the live stock
   */
  updateLiveStock() {
    this.notification.showSpinner();

    const payload = this.preparePayload(this.util.clone(this.addLiveStockForm.value));

    this.liveStockService.updateLiveStock(this.profileKey, payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() =>  this.notification.hideSpinner())
      )
      .subscribe(
        res => this.addSuccessHandler('Live stock updated successfully'),
        err => this.addErrorHandler(err)
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Form group for add live stock form
   */
  private get newLiveStockForm(): FormGroup {

    return this.fb.group({
      product: this.fb.array([]),
      batchNo: [''],
      attachments: [''],
      alternativeName: [''],
      location: [{}],
      breedName: [''],
      maleCount: [0],
      totalQuantity: [''],
      femaleCount: [0],
      date: [''],
      mortality: [0],
      age: [''],
      averageWeight: [0],
      type: [''],
      dimensions: this.fb.group({
        length: [''],
        breadth: [''],
        unit: [''],
        total: ['']
      })
    });
  }

  /**
   * Prepare payload
   * @param formValue
   * @returns
   */
  private preparePayload(formValue: any) {



      formValue.dimensions.total = formValue.dimensions.breadth * formValue.dimensions.length;

      if(this.stockType.title !== 'POULTRY') {
        delete formValue.alternativeName;
      }


      if (this.date.value) {
        const formattedDate = this.datePipe.transform(this.date.value, 'yyyy-MM-dd');
        this.date.patchValue(formattedDate);
      }

    return formValue;
  }

  /**
   * Close dialog box on success of post
   * @param res
   */
  private addSuccessHandler(res) {
    this.closeDialog.emit();
    this.notification.showMessage(res);
  }

  /**
   * On failure of creation of live stock
   * @param err
   */
  private addErrorHandler(err) {
    this.notification.showMessage(err.message || err.error.message);
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  /**
   * Validate input field for max 4 or 6-digit numbers.
   * @param event
   * @param fieldValue
   * @param fieldName
   * @returns
   */
  validateNumericInputLength(event, fieldValue?: any, fieldName?: any) {
    const x = event.which || event.keyCode;
    const isNumeric = (x) => x >= 48 && x <= 57;

    switch(fieldName) {
      case 'employees':
      case 'villages':
        return isNumeric(x) && fieldValue.length < 4;

        case 'farmers':
        return isNumeric(x) && fieldValue.length < 7;

        case 'totalQty':
        return isNumeric(x) && fieldValue.length < 11;

      case 'land':
        return (isNumeric(x) || x === 46) && fieldValue.length < 6;

      default:
        return false;
    }

  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
