import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kl-reccomendations-common',
  templateUrl: './reccomendations-common.component.html',
  styleUrls: ['./reccomendations-common.component.scss']
})
export class ReccomendationsCommonComponent implements OnInit {

  @Input()
  recommendations: String;

  recommendationsList = [];

  constructor() { }

  ngOnInit() {

    this.recommendationsList = this.recommendations.split(';')

  }

}
