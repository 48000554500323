import { Inject, Injectable } from '@angular/core';
import { KalgudiLazyLoaderService } from '@kalgudi/third-party/lazy-loader';
import { LatLong } from '@kalgudi/types';
import { BehaviorSubject, combineLatest, forkJoin, fromEvent, merge, Observable } from 'rxjs';
import { map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { KalgudiPhotoswipeAssetsConfig, KL_PS_ASSETS } from './config';
import { PhotoswipeAttachments, PhotoswipeGallery } from './types';


/**
 * Kalgudi wrapper of photoswipe. Use this service to show fullview of the image.
 *
 * It has two public exposed methods `open` and `close` which are used to open and
 * close the image fullview.
 *
 * It has a public getter `photoswipe$` that contains the latest list of images that
 * are being shown.
 *
 * @example
 * photoswipe.open([]).subscribe(r => console.log('Photoswipe fullview opened'));
 * photoswipe.close();
 *
 * @see image-preview.component.ts
 */
@Injectable()
export class PhotoswipeAngularWrapperService {

  private readonly DEFAULT_PHOTOSWIPE: PhotoswipeAttachments = {
    active: false,
    selectedIndex: 0,
    images: []
  };

  /**
   * Default photoswipe subject initialization
   */
  private readonly photoswipeSubject = new BehaviorSubject<PhotoswipeAttachments>(this.DEFAULT_PHOTOSWIPE);

  constructor(
    @Inject(KL_PS_ASSETS) private assetsConfig: KalgudiPhotoswipeAssetsConfig,
    private lazyLoader: KalgudiLazyLoaderService,
  ) { }

  /**
   * Stream of images that will be used to show image
   * fullview.
   */
  get photoswipe$(): Observable<PhotoswipeAttachments> {
    return this.photoswipeSubject.asObservable();
  }



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Opens photoswipe image viewer
   *
   * @param attachments List of attachments
   * @param prefixDomain Domain name with protocol if the image url is relative
   * @param selectedIndex Start previewing images from the specified selected index
   */
  open(
    attachments: { url: string, context?: string, msgType?: string, geoLocation?: LatLong}[],
    prefixDomain: string = '',
    selectedIndex: number = 0
  ): Observable<PhotoswipeAttachments> {

    // Transforms the kalgudi attachment type to stream of items that
    // return HTMLImageElement stream
    const obs = attachments.map((a, i) =>

      // Map attachment type to html image element
      this.mapAttachmentsToImage(
        {
          // Ensure all domains must have domain attached with it
          url: this.prefixDomain(a.url, prefixDomain),
          title: a.context,
          geoLocation: a.geoLocation
        },
        i
      )
    );

    // Dynamically load all photoswipe libraries.
    // Does nothing if the assets are already loaded.
    // Loading assets dynamically is required to ensure the feature gets loaded
    // only when user wants it.
    return this.loadAssets()
      .pipe(

        // Combine all observables that returns an html image element
        // as a single observable stream.
        switchMap(_ =>

          combineLatest( ...obs )
            .pipe(

              // Transform the HTMLImageElement array list to photoswipe type
              map(r => this.mapHtmlImageListToPhotoswipeGallery(r, selectedIndex, true)),

              // Emit stream of images to the photoswipe subject
              tap(r => this.photoswipeSubject.next(r)),
            )
          )
      );
  }

  /**
   * Closes the image fullview preview window.
   */
  close(): void {
    this.photoswipeSubject.next(this.DEFAULT_PHOTOSWIPE);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Load all photoswipe dependencies. Photoswipe libraries are locations are configured
   * while including photoswipe library.
   */
  private loadAssets(): Observable<any[]> {

    // Observable of scripts to load
    const scripts = this.assetsConfig.scripts.map(s => this.lazyLoader.loadScript(s));

    // Observable of styles to load
    const styles  = this.assetsConfig.css.map(s => this.lazyLoader.loadStyle(s));

    // Combine all and load all scripts and styles together
    return forkJoin([
      ...scripts,
      ...styles,
    ]);
  }

  /**
   * Maps an array of images type to Photoswipe images
   * array.
   *
   * @param imageDetails Image url and title
   * @param index Current index of the image
   */
  private mapAttachmentsToImage(
    imageDetails: { url: string, title: string, geoLocation?: LatLong },
    index = 0
  ): Observable<PhotoswipeGallery> {

    // Clone the image details to ensure immutability
    const image = imageDetails as any;

    // Map the current attachment index to the image
    image.index = index;

    // Create html image element instance
    const img = new Image();

    // Attach image src to the html element
    img.src = image.url;

    // Merge image onload and onerror events
    // Return the response returned by any of the first streams
    return merge(
      this.imageLoaded(imageDetails, img),
      this.imageError(imageDetails, img)
    )
    .pipe(

      // Take the first PhotoswipeGallery, whether from error or load event
      take(1),
    );
  }

  /**
   * Returns observable mapping the successful call to image.onload event.
   */
  private imageLoaded(imageDetails: { url: string, title: string, geoLocation?: LatLong }, img: HTMLImageElement): Observable<PhotoswipeGallery> {

    // Equivalent img.onload = (e) => ...
    return fromEvent(img, 'load')
      .pipe(
        // Subscribe to only 1 item from the stream and close the stream
        take(1),

        // Assign height and width to the image
        map(r => {
          const loadedImage: any = r.currentTarget;

          return {
            src: imageDetails.url,
            w: loadedImage.width,
            h: loadedImage.height,
            title: imageDetails.geoLocation
              ? imageDetails.title + '\n' +
              `<span style="display: block">Latitude: ${imageDetails.geoLocation.latitude}</span>` + '\n' +
              `Longitude: ${imageDetails.geoLocation.longitude}`
              : imageDetails.title,
          };
        }),
      );
  }

  /**
   * Returns observable mapping to failed image load operations. It maps
   * image.onerror events.
   */
  private imageError(imageDetails: { url: string, title: string }, img: HTMLImageElement): Observable<PhotoswipeGallery> {

    // Default error loading response
    const defaultErrorResponse: PhotoswipeGallery = {
      src: imageDetails.url,
      title: imageDetails.title,
      w: 100,
      h: 100
    };

    // Equivalent img.onerror = (e) => ...
    return fromEvent(img, 'error')
      .pipe(
        // Subscribe to only 1 item from the stream and close the stream
        take(1),

        // Assign height and width to the image
        mapTo(defaultErrorResponse),
      );
  }

  /**
   * Maps html images list to photoswipe attachments type
   */
  private mapHtmlImageListToPhotoswipeGallery(images: PhotoswipeGallery[], selectedIndex: number, active: boolean): PhotoswipeAttachments {
    return {
      images,
      selectedIndex,
      active,
    };
  }

  /**
   * Prefixes a url with http protocol and domain if not specified.
   */
  private prefixDomain(value: string, domain: string): string {

    // `true` if value begins with http or https otherwise `false`.
    const beginsWithHttp = (/^http[s]?:\/\//).test(value);

    return beginsWithHttp
      ? value
      : domain + value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
