<mat-card class="p-0" fxLayout="column" id="new-farmer-profile-details">

  <div fxLayout="row" class="profile-details-info">
    <div class="profile-tile">
      <div class="position-relative">
        <div class="profile-img fade-in-background-color">
          <img class="w-100 h-100" [src]="profile?.profilePicUrl | prefixDomain" [klErrorImages]="[
              (profile?.profilePicUrl) | prefixDomain,
              defaultProfilePic]" />
        </div>

        <div class="position-absolute cursor-pointer upload-img-area" *ngIf="editable && !isPublicPage">
          <span class="cursor-pointer position-absolute upload-icon">
            <mat-icon style="color:forestgreen;font-size: 16px;">edit</mat-icon>
          </span>

          <input class="cursor-pointer input-area position-absolute w-100 h-100" type="file"
            [title]="'Click to change profile picture' | translate" (change)="uploadProfilePic($event)"
            *ngIf="editable">
        </div>
      </div>

    </div>

    <div fxLayout="column" [fxFlex]="isMicrositeApp ? 'calc(64% - 5rem)' : 'calc(85% - 5rem)'"
      [fxFlex.md]="isMicrositeApp ? 'calc(57%)' : 'calc(85% - 5rem)'"
      [fxFlex.lt-md]="isMicrositeApp ? 'calc(49%)' : 'calc(85% - 5rem)'"
      [fxFlex.xs]="isMicrositeApp ? 'calc(45% - 5rem)' : 'calc(45% - 5rem)'" [ngClass]="{'profile-details' : !editable}"
      class="new-profile-details">
      <div class="mr-1">
        <div fxLayout fxLayout.xs="column">
          <h1 class="text-truncate mb-1 mr-1" ngClass.gt-xs="name" ngClass.lt-sm="h6">
            {{ environment?.appId === 'SAM_FPO' ? profile?.firstName : profile?.firstName | titlecase }}
          </h1>
          <div class="mt-n2" *ngIf="environment?.appId === 'SAM_FPO'">
            <span class="mr-2 cursor-pointer" title="Women led entity"
              *ngIf="basicInfoFormDetails?.basicDetails?.isWomenLedEntity">
              <svg-icon class="fpo-operation-icons text-center" src="assets/svgs/women-led-entity.svg"
                [svgStyle]="{ 'width.px': 25, 'height.px': 25 }">
              </svg-icon>
            </span>
            <span class="mr-2 cursor-pointer" title="Micro small & medium enterprises"
              *ngIf="basicInfoFormDetails?.basicDetails?.isMicroSmallMediumEnterprises">
              <svg-icon class="fpo-operation-icons text-center" src="assets/svgs/msme.svg"
                [svgStyle]="{ 'width.px': 40, 'height.px': 40 }">
              </svg-icon>
            </span>
            <span class="cursor-pointer" title="Climate smart agriculture"
              *ngIf="basicInfoFormDetails?.basicDetails?.isClimateSmartAgriculture">
              <svg-icon class="fpo-operation-icons text-center" src="assets/svgs/climate-control.svg"
                [svgStyle]="{ 'width.px': 25, 'height.px': 25 }">
              </svg-icon>
            </span>
          </div>
        </div>
      </div>
      <h2 class="location mb-0"
        title="{{profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}}, {{profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong}}">
        <span ngClass.lt-sm="5px">{{ profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName }}, </span>
        <span>{{ profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
        <span *ngIf="profile?.zipCode">, {{ profile?.zipCode }}</span>
      </h2>
      <h2 class="location mb-0" *ngIf="profile?.establishedSince && (environment.appId !== 'SAM_FPO')" translate>Since -
        {{ profile?.establishedSince }}</h2>

      <h2 class="location mb-0" *ngIf="profile?.establishedSince && (environment.appId === 'SAM_FPO')" translate>Date of
        Incorporation :
        <span>{{ profile?.establishedSince | date: 'dd-MM-yyyy' }}</span>
      </h2>

      <ng-container
        *ngIf="loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].businessTypeName === availableBusinessTypeNames.FPO || loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName === nativeBusinessTypeNames.PRODUCER_ORG">
        <div fxHide.gt-xs>
          <div class="email text-truncate" fxLayout="row" fxLayoutGap="5px" *ngIf="profile?.altemailId">
            <p class="mb-0 info text-truncate">{{ profile?.altemailId }}</p>
          </div>
          <div class="cursor-pointer email" *ngIf="!profile?.altemailId" (click)="showEditDialog()">
            <ng-container *ngIf="!isPublicPage">
              <p class="mb-0 info" fxLayout="row wrap" translate>Add your email id</p>
            </ng-container>
          </div>
        </div>

        <div fxHide.gt-xs>
          <div class="email text-truncate" *ngIf="profile?.alternateMobileNo" fxLayout="row" fxLayoutGap="5px">
            <p class="mb-0 info text-truncate" fxLayout="row">{{ profile?.alternateMobileNo }}</p>
          </div>

          <div class="cursor-pointer email" *ngIf="!profile?.alternateMobileNo" (click)="showEditDialog()">
            <ng-container *ngIf="!isPublicPage">
              <p class="mb-0 info" fxLayout="row" translate>Add your phone number</p>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div id="about-profile-tab" fxHide.xs fxLayout>
        <div fxLayout="row" *ngFor="let tab of tabsList; let first = first;let last = last" fxLayout
          [ngClass]="{'d-none': tab?.id === 'PROFILE_LOGS' && !showProfileLogs}">
          <a class="font-weight-bold w-100" fxLayout="row"
            [ngClass]="{'tab-selected text-secondary': selectedTabId === tab?.id}" (click)="toggleTab(tab?.id)"
            translate>
            <span *ngIf="!first" fxLayoutAlign="center center" class="mr-1 text-muted separator">|</span>
            <span class="pr-2 w-100" fxLayoutAlign="center center"> {{ tab?.title | translate }}</span>
          </a>
        </div>
      </div>

    </div>

    <!-- <div *ngIf="!isPublicPage" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start end"
      [fxFlex.md]="isMicrositeApp ? 'calc(19%)' : ''" [fxFlex.lt-md]="isMicrositeApp ? 'calc(24%)' : (environment?.appId === 'SAM_FPO') ? 'calc(30%)': ''"
      [fxFlex.xs]="isMicrositeApp ? 'calc(18% - 2rem)' : ''" class="share-edit-icons"> -->
    <div *ngIf="!isPublicPage" fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="start end"
      class="share-edit-icons">

      <!-- Dashboard -->
      <a *ngIf="!isPublicPage && profileKey === userProfileKey" class="cursor-pointer admin-link mr-1 mb-1"
        (click)="fpoAdminApp()" fxLayoutGap="2px" fxLayoutAlign="start">
        <mat-icon class="new-window-icon">launch</mat-icon>
        <span class="cursor-pointer admin-info pl-1 pr-1" *ngIf="profileKey === userProfileKey"> {{ environment?.appId
          === 'SAM_FPO' ? 'Dashboard' : 'Admin' | translate}}</span>
      </a>
      <!-- / Dashboard -->

      <!-- Share button -->
      <div class="social-shares mb-1 ml-0" ngClass.gt-sm="mr-2" fxFlexAlign.lt-sm="end"
        *ngIf="environment?.appId === 'SAM_FARMER' || isMicrositeApp">
        <a class="btn share-btn border-0 p-0 shadow-none" *ngIf="showProfileShare" [matMenuTriggerFor]="menu">
          <p class="m-0 share-container" fxLayout fxLayoutAlign="start center">
            <span fxHide.xs class="share-icon" [title]="'Share your profile on social media' | translate"
              translate>Share profile</span>
            <i class="fas fa-share-alt share-button ml-1"></i>
          </p>
        </a>

        <mat-menu #menu="matMenu" panelClass="custom" class="px-2 ml-4 mt-1 custom">
          <div class="social-btn" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0px">
            <div>
              <button mat-icon-button matTooltip="Click to copy" matTooltipPosition="before" (click)="clickToCopyUrl()">
                <i class="fa fa-clone copy-icon"></i>
              </button>
            </div>
            <div>
              <button mat-icon-button (click)="shareToWhatsappWeb()">
                <i class="fab fa-whatsapp share-icons whatsapp-btn"></i> </button>
            </div>
            <div>
              <button mat-icon-button (click)="shareToEmailWeb()">
                <i class="fas fa-envelope-square share-icons email-btn"></i>
              </button>
            </div>
            <div>
              <button mat-icon-button (click)="shareToFacebook()"><i
                  class="fab fa-facebook-f share-icons facebook-btn"></i> </button>
            </div>
            <div>
              <button mat-icon-button (click)="shareToLinkedin()">
                <i class="fab fa-linkedin-in share-icons linked-btn"></i>
              </button>
            </div>
            <div>
              <button mat-icon-button (click)="shareToTwitter()">
                <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
                  <path
                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </button>
            </div>
          </div>
        </mat-menu>
      </div>
      <!--/ Share button -->

      <!-- Edit button -->
      <button mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end end" (click)="showEditDialog()"
        *ngIf="editable">
        <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
      </button>
      <!--/ Edit button -->

    </div>

  </div>

  <!-- Local statistics title -->
  <div *ngIf="isSamId" class="pt-2 ml-2 statistics-title">
    <span class="m-0 font-weight-normal text-secondary title-text">Declared stats</span>
  </div>

  <!-- Declared count -->
  <div fxLayout="row" fxLayout.xs="column" class="mb-1 ml-1" [ngClass.gt-sm]="{'ml-2' : isSamId}" ngClass.lt-md="mx-1">
    <div class="w-100 profile-info" fxLayout="row" fxLayoutAlign="center center"
      [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '60%' : '50%'"
      fxFlex.xs="100%">
      <!-- Farmers & gender count -->
      <div class="" fxLayout fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
        [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '40%' : '33%'"
        fxFlex.xs="60%">
        <div fxFlexAlign="end">
          <div [ngClass]="{'farm-title': environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'}"
            fxLayout="column" fxLayoutAlign="center center">
            <span class="title1" translate>{{ environment?.appId === 'SHG_NET_APP' ? 'Members' : 'Farmers' }}</span>
            <span class="count mb-n1">
              {{ environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'
              ? ((basicInfoFormDetails?.basicDetails?.memberShipBase | number:'1.0-2') || 0)
              : ((profile?.farmers | number:'1.0-2') || 0) }}
            </span>
          </div>
        </div>
        <div class="farmer-separator mb-2"
          *ngIf="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'"></div>
        <div class=" colon pr-3" fxLayout="column" fxLayoutAlign="center start"
          *ngIf="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'">
          <div fxLayout>
            <span class="title width-gender" translate>Female</span>
            <span class="mr-1">:</span>
            <span class="count-margin">{{ (basicInfoFormDetails?.basicDetails?.femaleMembers | number:'1.0-2') || 0
              }}</span>
          </div>

          <div fxLayout>
            <span class="title width-gender" translate>Male</span>
            <span class="mr-1">:</span>
            <span class="count-margin">{{ (basicInfoFormDetails?.basicDetails?.maleMembers | number:'1.0-2') || 0
              }}</span>
          </div>
        </div>
      </div>

      <!-- Separators -->
      <div class="separator mb-2"></div>

      <!-- Land count -->
      <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
        [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
        fxFlex.xs="33%" [ngClass]="{'land-details' : profile?.fpoAvgLand?.unit}">
        <span class="title" translate>{{ environment?.appId === 'SHG_NET_APP' ? 'Product Category' : 'Land' }}</span>
        <div fxLayout="row wrap">
          <span class="count mb-n1">{{ (profile?.fpoAvgLand?.value | number:'1.0-2') || 0 }}</span>
          <span class="units" fxLayoutAlign="end end" *ngIf="profile?.fpoAvgLand?.value">
            {{ environment?.appId === 'SHG_NET_APP' ? '' : profile?.fpoAvgLand?.unit | translate}}
          </span>
        </div>
      </div>
      <div class="separator mb-2"></div>

      <!-- Villages count -->
      <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
        [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
        fxFlex.xs="33%">
        <span class="title" translate>Villages</span>
        <span class="count mb-n2">{{ (profile?.villages | number:'1.0-2') || 0 }}</span>
      </div>

      <!-- Separators -->
      <div class="separator mb-2"></div>

      <!-- Employees count -->
      <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
        [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
        fxFlex.xs="33%">
        <span class="title" translate>Employees</span>
        <span class="count mb-n2">{{ (profile?.employees) || 0 }}</span>
      </div>
    </div>

    <!-- Email & mobile number -->
    <ng-container
      *ngIf="environment.appId !== 'SAM_FPO' ? (loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].businessTypeName === availableBusinessTypeNames.FPO || loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName === nativeBusinessTypeNames.PRODUCER_ORG || isPublicPage) : false">
      <div *ngIf="editable || isPublicPage" fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayout.xs="row"
        [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '40%' : '50%'"
        fxFlex.xs="100%" [ngClass.gt-xs]="{'ml-2' : !(profile?.alternateMobileNo || profile?.altemailId)}" fxHide.xs>

        <div *ngIf="isPublicPage ? (profile?.altemailId || profile?.alternateMobileNo) : true"
          class="email-mobile-section ml-2" fxLayoutAlign="center center">

          <!-- Edit icon -->
          <div *ngIf="!isPublicPage" class="contact-edit-icon text-right cursor-pointer">
            <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/edit-icon.svg"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="showEditDialog()">
            </svg-icon>
          </div>

          <div fxLayout="column">
            <!-- Email -->
            <div *ngIf="isPublicPage ? (profile?.altemailId) : true" class=" mobile ml-1 mr-1"
              fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
              fxFlex.xs="{{profile?.altemailId && profile?.alternateMobileNo ? '58%' : '50%'}}">
              <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/mail.svg"
                [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"></svg-icon>
              <div class="text-truncate email-entered" *ngIf="profile?.altemailId">
                <p class="mb-0 info text-truncate text-dark">{{ profile?.altemailId }}</p>
              </div>
              <div class="cursor-pointer email" *ngIf="!profile?.altemailId" (click)="showEditDialog()">
                <ng-container *ngIf="!isPublicPage">
                  <p class="mb-0 info" fxLayout="row wrap" translate>Add your email id</p>
                </ng-container>
              </div>
            </div>

            <!-- Horizontal line -->
            <hr class="email-separator">

            <!-- Mobile -->
            <div *ngIf="isPublicPage ? (profile?.alternateMobileNo) : true" fxLayoutAlign="start center"
              class="mobile ml-2" fxLayoutGap="5px"
              fxFlex.xs="{{profile?.alternateMobileNo && profile?.altemailId ? '40%' : '50%'}}">
              <svg-icon [applyCss]="true" class="text-center text-dark" src="assets/svgs/phone.svg"
                [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
              </svg-icon>
              <div class="mobile-entered" *ngIf="profile?.alternateMobileNo">
                <p class="mb-0 info text-dark" fxLayout="row">+91-{{ profile?.alternateMobileNo }}</p>
              </div>

              <div class="cursor-pointer email" *ngIf="!profile?.alternateMobileNo" (click)="showEditDialog()">
                <ng-container *ngIf="!isPublicPage">
                  <p class="mb-0 info" fxLayout="row" translate>Add your phone number</p>
                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ng-container>

  </div>
  <!--/ Declared count -->

  <!-- Digitized count -->
  <div *ngIf="isSamId" class="digitalized-count">
    <!-- Digitized statistics title -->
    <div class="mt-2 ml-2 statistics-title">
      <span class="m-0 font-weight-normal text-secondary title-text">Digitized stats</span>
    </div>

    <!-- Digitized statistics -->
    <div fxLayout="row" fxLayout.xs="column" class="mb-2" ngClass.gt-sm="ml-2" ngClass.lt-md="mx-1">
      <div class="w-100 profile-info profile-info-new border-top" fxLayout="row" fxLayoutAlign="center center"
        fxFlex="60%" fxFlex.xs="100%">
        <!-- Farmers & gender count -->
        <div class="" fxLayout fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
          [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '40%' : '33%'"
          fxFlex.xs="60%">
          <div fxFlexAlign="end">
            <div [ngClass]="{'farm-title': environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'}"
              fxLayout="column" fxLayoutAlign="center center">
              <span class="title1" translate>Farmers</span>
              <span class="count mb-n1">{{ (fpoStats?.farmerAndgender?.farmers | number:'1.0-2') || 0 }}</span>
            </div>
          </div>
          <div class="farmer-separator mb-2"
            *ngIf="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'"></div>
          <div class=" colon pr-3" fxLayout="column" fxLayoutAlign="center start"
            *ngIf="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER'">
            <div fxLayout>
              <span class="title width-gender" translate>Female</span>
              <span class="mr-1">:</span>
              <span class="count-margin">{{ (fpoStats?.farmerAndgender?.female | number:'1.0-2') || 0 }}</span>
            </div>

            <div fxLayout>
              <span class="title width-gender" translate>Male</span>
              <span class=" mr-1">:</span>
              <span class="count-margin">{{ (fpoStats?.farmerAndgender?.male | number:'1.0-2') || 0 }}</span>
            </div>
          </div>
        </div>

        <!-- Separator -->
        <div class="separator mb-2"></div>

        <!-- Land -->
        <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
          [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
          fxFlex.xs="33%" [ngClass]="{'land-details' : profile?.fpoAvgLand?.unit}">
          <span class="title" translate>Land</span>
          <div fxLayout="row wrap">
            <span class="count mb-n1">{{ ((fpoStats?.farmersTotalLand | number:'1.0-2') || 0) }}</span>
            <span class="units" fxLayoutAlign="end end" translate>acres</span>
          </div>
        </div>

        <!-- Separator -->
        <div class="separator mb-2"></div>

        <!-- Villages -->
        <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
          [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
          fxFlex.xs="33%">
          <span class="title" translate>Villages</span>
          <span class="count mb-n2">{{ (fpoStats?.farmersVillage | number:'1.0-2') || 0 }}</span>
        </div>

        <!-- Separator -->
        <div class="separator mb-2"></div>

        <!-- Employees -->
        <div class="" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="center center"
          [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '20%' : '33%'"
          fxFlex.xs="33%">
          <span class="title" translate>Employees</span>
          <span class="count mb-n2">{{ (fpoStats?.employees | number:'1.0-2') || 0}}</span>
        </div>
      </div>

      <!-- Email & mobile number -->
      <ng-container
        *ngIf="environment.appId !== 'SAM_FPO' ? (loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].businessTypeName === availableBusinessTypeNames.FPO || loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName === nativeBusinessTypeNames.PRODUCER_ORG) : true">
        <div *ngIf="editable || isPublicPage" fxLayout="column" fxLayoutAlign="flex-start flex-start" fxLayout.xs="row"
          [fxFlex]="environment?.appId === 'SAM_FPO' || environment?.appId === 'SAM_FARMER' ? '40%' : '50%'"
          fxFlex.xs="100%" [ngClass.gt-xs]="{'ml-2' : !(profile?.alternateMobileNo || profile?.altemailId)}" fxHide.xs>

          <div *ngIf="isPublicPage ? (profile?.altemailId || profile?.alternateMobileNo) : true"
            class="email-mobile-section ml-2" fxLayoutAlign="center center">

            <!-- Edit icon -->
            <div *ngIf="!isPublicPage" class="contact-edit-icon text-right cursor-pointer">
              <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/edit-icon.svg"
                [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" (click)="showEditDialog()">
              </svg-icon>
            </div>

            <div fxLayout="column">
              <!-- Email -->
              <div *ngIf="isPublicPage ? (profile?.altemailId) : true" class=" mobile ml-1 mr-1"
                fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="5px"
                fxFlex.xs="{{profile?.altemailId && profile?.alternateMobileNo ? '58%' : '50%'}}">
                <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/mail.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"></svg-icon>
                <div class="text-truncate email-entered" *ngIf="profile?.altemailId">
                  <p class="mb-0 info text-truncate text-dark">{{ profile?.altemailId }}</p>
                </div>
                <div class="cursor-pointer email" *ngIf="!profile?.altemailId" (click)="showEditDialog()">
                  <ng-container *ngIf="!isPublicPage">
                    <p class="mb-0 info" fxLayout="row wrap" translate>Add your email id</p>
                  </ng-container>
                </div>
              </div>

              <hr class="email-separator">

              <!-- Mobile -->
              <div *ngIf="isPublicPage ? (profile?.alternateMobileNo) : true" fxLayoutAlign="start center"
                class="mobile ml-2" fxLayoutGap="5px"
                fxFlex.xs="{{profile?.alternateMobileNo && profile?.altemailId ? '40%' : '50%'}}">
                <svg-icon [applyCss]="true" class="text-center text-dark" src="assets/svgs/phone.svg"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18 }">
                </svg-icon>
                <div class="mobile-entered" *ngIf="profile?.alternateMobileNo">
                  <p class="mb-0 info text-dark" fxLayout="row">+91-{{ profile?.alternateMobileNo }}</p>
                </div>

                <div class="cursor-pointer email" *ngIf="!profile?.alternateMobileNo" (click)="showEditDialog()">
                  <ng-container *ngIf="!isPublicPage">
                    <p class="mb-0 info" fxLayout="row" translate>Add your phone number</p>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
    <!--/ Digitized statistics -->

  </div>
  <!--/ Digitized count -->

  <!-- Tab titles -->
  <div id="about-profile-tab" fxLayout fxHide.gt-xs>
    <div fxFlex="50%" fxLayout="row"
      *ngFor="let tab of isPublicPage && isMicrositeApp ? tabsList.splice(1, tabsList?.length) : tabsList; let first = first;let last = last"
      fxLayout [ngClass]="{'d-none': tab?.id === 'PROFILE_LOGS' && !showProfileLogs}">
      <a class="font-weight-bold w-100" fxLayout="row"
        [ngClass]="{'tab-selected text-secondary': selectedTabId === tab?.id}" (click)="toggleTab(tab?.id)" translate>
        <span *ngIf="!first" fxLayoutAlign="center center" class="mr-1 text-muted separator">|</span>
        <span class="pr-2 w-100" fxLayoutAlign="center center"> {{ tab?.title | translate }}</span>
      </a>
    </div>
  </div>

</mat-card>