<div fxLayout="column" class="geo-multi-loc kalgudi-core-theme h-100 overflow-hidden mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!-- / Dialog title -->

  <kl-geo-multi-locations [allLocations]="allLocations"></kl-geo-multi-locations>

</div>
