import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  KalgudiProgramsMemberPickerDialogComponent,
} from '../components/kalgudi-programs-member-picker-dialog/kalgudi-programs-member-picker-dialog.component';
import {
  MemberPickerMobileDialogComponent,
} from '../components/member-picker-mobile-dialog/member-picker-mobile-dialog.component';


@Injectable()
export class KalgudiPagesMembersPickerService {

  constructor(
    private kalgudiDialogs: KalgudiDialogsService,
  ) { }

  /**
   * Shows an kalgudi programs members picker dialog box. Returns an observable of `KalgudiDialogResult`.
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showMembersPicker(
    details: KalgudiDialogConfig,
    dialogConfig: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileMemberPickerDialog(details)
      : this.openMemberPickerDialog(details, dialogConfig);
  }

  /**
   * Opens member picker dialog
   */
  openMemberPickerDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.kalgudiDialogs.openDialog(KalgudiProgramsMemberPickerDialogComponent, details, dialogConfig);
  }

  /**
   * Opens member picker Mobile dialog
   */
  openMobileMemberPickerDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogs.openMobileDialog(MemberPickerMobileDialogComponent, details);
  }

}
