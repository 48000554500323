<div id="kl-capture-image-form-control" class="h-100 w-100">
  <div class="mt-2">
  </div>

  <div class="mt-2 position-relative">
    <div>
      <video id="video" #videoElement class="w-100" [ngClass]="{'apply-mirror': shouldFaceUser}" autoplay></video>
    </div>
    <canvas id="canvas" #canvasElement class="position-absolute image-canvas" [ngClass]="{'apply-mirror': shouldFaceUser}"></canvas>
  </div>
  <div fxLayout fxLayoutAlign="space-between end" *ngIf="!file">
    <div>
      <button mat-flat-button color="primary" (click)="changeCam()" fxHide.gt *ngIf="flipBtn">
        <!-- <mat-icon>loop</mat-icon> -->
        <span class="url">
          <svg-icon [applyCss]="true" class="text-center flip-cam" src="assets/svgs/flip.svg" [svgStyle]="{ 'width.px': 27, 'height.px': 27 }"></svg-icon>
        </span>
      </button>
      <!-- <kl-flat-button label="Flip" (clicked)="testClick()" fxHide.gt></kl-flat-button> -->
    </div>

    <kl-flat-button label="Capture" (clicked)="capture()"></kl-flat-button>
  </div>


  <!-- <div fxLayout fxLayoutGap="20px" fxLayoutAlign="end end" *ngIf="file">
    <kl-flat-button label="Clear" (clicked)="clear()"></kl-flat-button>

    <kl-flat-button label="Attach" (clicked)="uploadToS3()" [progress]="progress"></kl-flat-button>
  </div> -->

</div>
