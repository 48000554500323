import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-add-task-members-dialog',
  templateUrl: './add-task-members-dialog.component.html',
  styleUrls: ['./add-task-members-dialog.component.scss']
})
export class AddTaskMembersDialogComponent extends KalgudiDialog<AddTaskMembersDialogComponent> implements OnInit {

  taskId: string;

  projectId: string;

  constructor(
    protected dialogRef: MatDialogRef<AddTaskMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) {
    super(dialogRef);
  }

  ngOnInit() {
    this.taskId = this.data.data.taskId;
    this.projectId = this.data.data.projectId;
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }


}
