export class KalgudiError {

  error: Error;

  constructor(error: Error) {

    if (error) {
      this.error = error;
    }

    console.error(error.message);
  }
}
