<button mat-stroked-button
  [color]="buttonColor || 'primary'"
  class="kl-stroke-btn kl-btn mat-stroke-button"
  [type]="type"
  [disabled]="disabled || progress"
  (click)="onClick($event)"
  [ngClass]="{'warn': buttonColor === 'warn' }">
  <span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <span class="stroke-btn-label" translate>{{ label | translate }}</span>
      <mat-spinner *ngIf="progress" class="dim-spinner" [@slideRight]
        [diameter]="20"
        color="warn">
      </mat-spinner>
    </div>
  </span>
</button>


