import { Inject, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  StringAnyMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PageActions } from '../../constants';
import { KalgudiPageMembersService } from '../../services/kalgudi-page-member.service';
import { KalgudiPageService } from '../../services/kalgudi-page.service';
import { ProgramStateService } from '../../services/program-state.service';
import { ApiPageMemberAddResponseData } from '../../typings';
import { KalgudiProgramManageUsers } from './kalgudi-program-manage-users';

@Directive()
export abstract class KalgudiProgramManageAdmins extends KalgudiProgramManageUsers {


  constructor(
    protected kalgudiProgram: KalgudiPageService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected programState: ProgramStateService,
    protected manageUsersService: KalgudiPageMembersService,
    protected usersPickerService: KalgudiUsersPickerService
  ) {

    super(kalgudiProgram, notification, util, programState);

    /**
     * On `ADD_ADMIN` state action changed
     */
    this.programState.action$
      .pipe(
        filter(action => action.type === PageActions.ADD_ADMIN),

        takeUntil(this.destroyed$),
      )
      .subscribe(res => this.add(false, this.pageId));

    // Initialize member
    this.initMembersManagement();
  }



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  protected searchApi(searchKeyword: string, offset: number, limit: number, extraParams?: StringAnyMap): Observable<KalgudiStreamData> {
    return this.manageUsersService.getAdmins(this.pageId, offset, limit);
  }

  protected addApi(selectedUsers: KalgudiUsersMap, selectedUsersList: KalgudiUserBasicDetails[]): Observable<ApiPageMemberAddResponseData> {

    return this.manageUsersService.addAdmins(this.pageId, selectedUsersList);
  }

  /**
   * Calls an API to remove the admin
   */
  protected removeApi(user: KalgudiUserBasicDetails, pageId?: string): Observable<ApiPageMemberAddResponseData> {

    const userProfileKey = user.profileKey;

    return this.manageUsersService.deleteAdmins(this.pageId, [ userProfileKey ]);
  }

  /**
   * Opens a user picker dialog
   * @param details
   * @param config
   */
  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.usersPickerService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.usersPickerService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
