import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiAiPredictionsSendSms } from '../../kalgudi-ai-predictions-send-sms';


@Component({
  selector: 'kl-ai-predictions-send-sms',
  templateUrl: './ai-predictions-send-sms.component.html',
  styleUrls: ['./ai-predictions-send-sms.component.scss']
})
export class AiPredictionsSendSmsComponent extends KalgudiAiPredictionsSendSms implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
    this.getSmsTemplateList();

    this.subscribeSmsTemplateFieldValueChanges();
  }

  onDestroyed(): void {}
}
