import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SelectableChipComponent } from './selectable-chip/selectable-chip.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';


@NgModule({
  declarations: [
    SelectableChipComponent
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Material modules
    MatCheckboxModule,
    MatChipsModule,
    MatFormFieldModule,
    MatRippleModule,

    FlexLayoutModule,
    TranslateModule
  ],
  exports: [
    SelectableChipComponent
  ]
})
export class KalgudiSelectableChipModule { }
