<mat-card class="project-tile cursor-pointer" (click)="openProjectFullview(projectDetails?.id)">
  <div fxLayout="column">
    <!-- Project title -->
    <div class="menu-button">
      <div fxFlex="93%">
        <p class="mb-0 text-capitalize text-truncate project-title">{{ projectDetails?.title | translate }}</p>
      </div>
      <div fxFlex="7%" fxLayoutAlign="end">
        <button mat-icon-button
          *ngIf="projectDetails?.userRole === memberRoles?.ADMIN || projectDetails?.userRole === memberRoles?.CONTRIBUTOR"
          [matMenuTriggerFor]="menu"
          (click)="$event.stopPropagation()">
          <mat-icon class="text-muted">more_horiz</mat-icon>
        </button>
        <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

          <button mat-menu-item (click)="showAddMembersDialog()">
            <mat-icon class="action-icons">group_add</mat-icon>
            <span translate>Add/Manage Members</span>
          </button>

          <button mat-menu-item (click)="openTaskCreationDialog(projectDetails?.id)">
            <svg-icon [applyCss]="true" class="task-icon mr-2" src="assets/svgs/tasks.svg"
              [svgStyle]="{'width.px': '20', 'height.px': '20'}"></svg-icon>
            <span translate>Add Tasks</span>
          </button>

          <button mat-menu-item (click)="openUpdateProject()">
            <mat-icon class="action-icons">edit</mat-icon>
            <span translate>Edit</span>
          </button>

          <button mat-menu-item (click)="deletesProject()">
            <mat-icon class="action-icons">delete_outline</mat-icon>
            <span translate>Delete</span>
          </button>

        </mat-menu>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-2">
      <div fxLayout="column" fxFlex="70%">
        <!-- <div class="mw-100">
          <p class="mb-0 text-capitalize text-truncate project-title">{{ projectDetails?.title }}</p>
        </div> -->
        <p class="mb-0">
          <span><a class="text-success projectDetails mb-0 mt-n1">{{ projectDetails?.type | titlecase | trimSpecialCharacters | translate}} </a>
          </span>
          <span class="dot d-inline-block align-middle mx-1">
          </span>
          <span class="text-muted">{{ projectDetails?.CT | date: 'dd' }} </span>
          <span class="text-muted">{{ projectDetails?.CT | date: 'MMM' | translate }} </span>
          <span class="text-muted">{{ projectDetails?.CT | date: 'yyyy' }}</span></p>
      </div>
      <!-- <div>
        <div fxLayout="column" *ngIf="projectDetails?.status === 'COMPLETED'">
          <svg-icon src="assets/svgs/completed.svg" class="text-center menu-icons" [applyCss]="true"
            [svgStyle]="{ 'width.px': 24, 'height.px': 24}"></svg-icon>
          <p class="text-muted mb-0">Completed</p>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="projectDetails?.status === 'IN_PROGRESS'">
          <mat-progress-spinner class="example-margin" color="primary" mode="determinate"
            [value]="projectDetails?.statusValue">
          </mat-progress-spinner>
          <p class="text-muted mb-0">In progress</p>
        </div>
      </div> -->
    </div>
    <!-- /Project title -->

    <div class="all-tasks project-stats-sm px-3 py-2 mb-2" fxLayout="column">
    <!--<kl-project-task-stats
          [taskStatsDetails]="projectDetails?.taskStatsDetails"
          taskStatsDirection="horizontal"
          showMenu="false">
        </kl-project-task-stats> -->

        <kl-project-task-stats
          [taskStatsDetails]="projectDetails?.statistics?.tasks"
          taskStatsDirection="horizontal"
          showMenu="false"
          (createTaskDialog)="openTaskCreationDialog(projectDetails?.id)">
        </kl-project-task-stats>
    </div>

    <div class="all-members project-stats-sm px-3 py-2" fxLayout="column">
    <!--<kl-project-members-stats
          [memberStatsDetails]="projectDetails?.memberStatsDetails"
          memberStatsDirection="horizontal"
          showMenu="false">
        </kl-project-members-stats> -->

        <kl-project-members-stats
          [memberStatsDetails]="projectDetails?.statistics?.members"
          memberStatsDirection="horizontal"
          showMenu="false"
          [projectId]="projectDetails?.id"
          (addProjectMembers)="showAddMembersDialog()">
        </kl-project-members-stats>
    </div>

    <!-- /All members -->
  </div>
</mat-card>
