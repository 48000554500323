<div>

  <!-- Answers form  -->
  <div *ngIf="isLoggedIn && qa?.isAllowedToAnswer">
    <kl-common-qa-form
      [showPageSelection]=true
      [shareForm]="shareForm"
      [shareFormText]="shareFormText"
      [attachments]="shareFormAttachments?.value"
      [progress]="progress"
      textFieldPlaceHolder="Answer here"
      submitBtnText="Answer"
      (shareQaPost)="createPost()"
      [rowsCount]="rowsCount"
      [compactView]="false"
      (removeAttachment)="removeImage($event)">
    </kl-common-qa-form>
  </div>
  <!--/ Answers form  -->


  <a class="mt-3" fxLayout="row" fxLayoutGap="15px">
    <div class="border-bottom mb-3" fxFlex *ngIf="qa?.isAllowedToAnswer"></div>
    <div class="pb-2 answers-count" *ngIf="qa?.answers.length" >
      <span translate>{{ qa?.answers.length }} </span>
      <span translate> {{ qa?.answers?.length > 1 ? 'answers' : 'answer'}}</span>
    </div>
    <div class="pb-2 answers-count" *ngIf="qa?.answers.length < 1 && qa?.isAllowedToAnswer" >
      <span translate>Be the first to answer</span>
    </div>
  </a>


  <!-- Answers List -->
  <kl-qa-answers-list [url]="url" [qa]="qa"></kl-qa-answers-list>
  <!-- /Answers List -->

</div>
