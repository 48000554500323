import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { LazyHttpClient } from '@kalgudi/common/lazy-http';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  KalgudiDealsWith,
  KalgudiFarmerLand,
  KalgudiFarmingHistory,
  KalgudiUser,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiFarmerProfileApiService {

  /**
   * `v2/profiles`
   */
  private readonly API_PROFILE_BASE = `${this.env.restBaseUrlV2}/profiles`;

  /**
   * `v2/profiles/:profileKey/crops`
   */
  private readonly API_DEALS_WITH = `${this.API_PROFILE_BASE}/:profileKey/crops`;


  /**
   * `v2/profiles/:profileKey/lands`
   */
  private readonly API_LAND_DETAILS = `${this.API_PROFILE_BASE}/:profileKey/lands`;

  /**
   * `v2/profiles/:profileKey/farming-seasons`
   */
  private readonly API_FARM_HISTORY = `${this.API_PROFILE_BASE}/:profileKey/farming-seasons`;

  /**
   * `v2/profiles/:profileKey/farming-seasons/:id`
   */
  private readonly API_FARM = `${this.API_FARM_HISTORY}/:id`;

  /**
   * `v2/profiles/:profileKey/farming-seasons/years`
   */
  private readonly API_FARM_HISTORY_YEARS = `${this.API_FARM_HISTORY}/years`;


  /**
   * /v2/pages/satellite-advisories/land/:landId
   */
  private readonly API_SATELLITE_ADVISORY = `${this.env.restBaseUrlV2}/pages/satellite-advisories/land/:landId`;

  /**
   * `v2/profiles/:profileKey/farming-seasons`
   */
  private readonly API_FARMING_SEASON = `${this.API_PROFILE_BASE}/:profileKey/farming-seasons`;

  /**
   * `v2/profiles/:profileKey/crops`
   */
  private readonly API_FARMER_PRODUCTS = `${this.API_PROFILE_BASE}/:profileKey/crops`;

  /**
   * `v2/profiles/:profileKey/lands/zipFile`
   */
  private readonly API_LAND_DATA = `${this.API_PROFILE_BASE}/:profileKey/lands/zipFile`;

  /**
   * `v2/profiles/:profileKey/farmer-memberships`
   */
  private readonly API_FARMER_MEMBERSHIP = `${this.API_PROFILE_BASE}/:profileKey/farmer-memberships`;

  /**
   * `v2/profiles/:profileKey/farmer-memberships/:membershipId`
   */
  private readonly API_DELETE_FARMER_MEMBERSHIP = `${this.API_PROFILE_BASE}/:profileKey/farmer-memberships/:membershipId`;

  /**
   * `v2/profiles/:profileKey/farmer-equipments`
   */
  private readonly API_FARMER_EQUIPMENT = `${this.API_PROFILE_BASE}/:profileKey/farmer-equipments`;

  /**
   * `v2/profiles/:profileKey/farmer-equipments/:equipmentId`
   */
  private readonly API_UPDATE_FARMER_EQUIPMENT = `${this.API_PROFILE_BASE}/:profileKey/farmer-equipments/:equipmentId`;

  /**
   * `v2/profiles/<profileKey>/crops`
   */
  private readonly API_REMOVE_PRODUCT = `${this.env.restBaseUrlV2}/profiles/:profileKey/crops`;

  /**
   * `/data/storeProducts/LIVE_STOCK_TYPES.json`
   */
  private readonly API_LIVE_STOCK_TYPES = `https://devkalgudidata.s3.amazonaws.com/data/storeProducts/LIVE_STOCK_TYPES.json`;

  /**
   * `/data/storeProducts/LIVE_STOCK_TYPES.json`
   */
  private readonly API_LIVE_STOCK_PROD = `https://core.kalgudi.com/data/storeProducts/LIVE_STOCK_TYPES.json`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private lazyHttpClient: LazyHttpClient,
    private util: KalgudiUtilityService,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Calls api to post the product
   */
  postDealsWith(profileKey: string, payload: KalgudiDealsWith, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_DEALS_WITH.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls api to delete the product
   */
  removeDealsWith(profileKey: string, productId: string, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      productId,
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.delete<ApiResponseCommon>(this.API_DEALS_WITH, { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls api to post the land details
   */
  postLandDetails(profileKey: string, payload: KalgudiFarmerLand, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.lazyHttpClient.post<ApiResponseCommon>(this.API_LAND_DETAILS.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }


  /**
   * Calls api to delete the land
   */
  deleteLand(profileKey: string, landId: string, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      landId,
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.delete<ApiResponseCommon>(this.API_LAND_DETAILS.replace(':profileKey', profileKey), { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }



  /**
   * Calls api to get the land years
   */
  getLandYears(profileKey: string, landId: string): Observable<KalgudiStreamData> {

    const params = {
      landId
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_FARM_HISTORY_YEARS.replace(':profileKey', profileKey), { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => ({ items: res.data.results, count: +res.data.count }))
      );
  }

  /**
   * Calls api to get the farm history
   */
  getFarmHistory(profileKey: string, landId: string, year: string, filters: PartialData = {}, assistedTo?: string): Observable<KalgudiStreamData> {

    const params = {
      landId, year, ...filters,
      assistedTo
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_FARM_HISTORY.replace(':profileKey', profileKey), { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => ({ items: res.data.results, count: +res.data.count }))
      );
  }


  /**
   * Calls api to delete the farm
   */
  deleteFarm(profileKey: string, farmId: string, extraParams: PartialData = {}): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.delete<ApiResponseCommon>(this.API_FARM.replace(':id', farmId).replace(':profileKey', profileKey), { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        mapTo(true)
      );
  }


  /**
   * Calls api to add farming history
   */
  addFarmingHistory(profileKey: string, payload: KalgudiFarmingHistory, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_FARM_HISTORY.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }


  /**
   * Api to get satellite advisories
   */
  getSatellite(landId: string, advisory: string, profileKey: string): Observable<any> {

    const params = {
      advisory,
      profileKey
    };

    return this.httpClient
      .get<ApiResponseCommon>(
        this.API_SATELLITE_ADVISORY.replace(':landId', landId), {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }

  /**
   * Calls api to get the farm history seasons list
   */
  getFarmSeasonsList(profileKey: string, pageId: string, year: string, assistedTo: string): Observable<KalgudiFarmingHistory[]> {

    const params = {
      pageId,
      // landId: '1608091882823_M0003id0PRFREG2020121414753845UNH001',
      year,
      assistedTo,
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_FARM_HISTORY.replace(':profileKey', profileKey), { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res =>  res.data.results)
      );
  }

  /**
   * Calls api to add farming history
   */
  addFarmingSeason(profileKey: string, payload: any, extraParams: PartialData = {}): Observable<any> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_FARMING_SEASON.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls api to get the farm crops list
   */
  getFarmerProducts(profileKey: string,extraParams: PartialData = {}): Observable<KalgudiDealsWith[]> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_FARMER_PRODUCTS.replace(':profileKey', profileKey), { params })
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res =>  res.data)
      );
  }

  /**
   * Calls api to get the land data
   */
  getLandData(payload, profileKey, landId) {
    const params = { landId }

    return this.httpClient.post<ApiResponseCommon>(this.API_LAND_DATA.replace(':profileKey', profileKey), payload, {params})
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls API to save a new membership to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param payload Membership payload
   */
  saveNewMembership(
    profileKey: string,
    payload: any,
    isExisted: boolean
  ): Observable<KalgudiUser> {

    const params = {
      isExisted: isExisted.toString()
    }

    return this.httpClient.post<ApiResponseCommon>(this.API_FARMER_MEMBERSHIP.replace(':profileKey', profileKey), payload, {params})
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }


  /**
   * Calls API to update membership to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param payload Membership payload
   */
    updateMembership(
    profileKey: string,
    payload: any,
    isExisted: boolean,
    membershipId: string
  ): Observable<KalgudiUser> {

    const params = {
      isExisted: isExisted.toString(),
      id: membershipId
    }

    return this.httpClient.post<ApiResponseCommon>(this.API_FARMER_MEMBERSHIP.replace(':profileKey', profileKey), payload, {params})
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls api to delete the membership
   */
  deleteMembership(profileKey: string, membershipId: string): Observable<KalgudiUser> {

    return this.httpClient.delete<ApiResponseCommon>(this.API_DELETE_FARMER_MEMBERSHIP
      .replace(':membershipId', membershipId)
      .replace(':profileKey', profileKey))
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls API to save a new equipment to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param payload equipment payload
   */
  saveNewEquipment(
    profileKey: string,
    payload: any,
  ): Observable<KalgudiUser> {

    return this.httpClient.put<ApiResponseCommon>(this.API_FARMER_EQUIPMENT.replace(':profileKey', profileKey), payload)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Calls API to update equipment to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param payload equipment payload
   */
  updateEquipment(
    profileKey: string,
    equipmentId: string,
    payload: any,
  ): Observable<KalgudiUser> {

    return this.httpClient.post<ApiResponseCommon>(this.API_UPDATE_FARMER_EQUIPMENT
      .replace(':profileKey', profileKey)
      .replace(':equipmentId',equipmentId), payload)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
  }

  /**
   * Get live stock data
   * @returns
   */
  getLiveStock(): Observable<any> {

    const url = this.env.production ?  this.API_LIVE_STOCK_PROD : this.API_LIVE_STOCK_TYPES;
    return this.httpClient.get<ApiResponseCommon>(url);
  }

  /**
   * Calls api to delete the equipment
   */
  deleteEquipment(profileKey: string, equipmentId: string): Observable<KalgudiUser> {

    return this.httpClient.delete<ApiResponseCommon>(this.API_UPDATE_FARMER_EQUIPMENT
      .replace(':equipmentId', equipmentId)
      .replace(':profileKey', profileKey))
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // All good, emit true
        map(res => res.data)
      );
    }

  /**
   * Calls api to delete crop from profile
   * @param profileKey
   * @param productId
   */
  deleteCrop(profileKey:string, productId: string): Observable<any>{

    const params = { productId }

    return this.httpClient.delete<ApiResponseCommon>(this.API_REMOVE_PRODUCT.replace(':profileKey', profileKey), { params })
      .pipe(
        map((res) => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        map(res => res.data)
      );
  }
}
