import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { ActivityType, KalgudiProjectTask } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { KalgudiTaskSubmissionDaService } from '../../services/kalgudi-task-submission-da.service';
import { KalgudiTasksService } from '../../services/kalgudi-tasks.service';

@Component({
  selector: 'kl-task-members-submission-da',
  templateUrl: './task-members-submission-da.component.html',
  styleUrls: ['./task-members-submission-da.component.scss']
})
export class TaskMembersSubmissionDaComponent extends KalgudiDestroyable implements OnInit, OnChanges {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  hideTaskHeader: boolean;

  @Input()
  isSubmission: boolean;

  @Input()
  selectedProfileKey: string;

  projectId: string;

  members: any[] = [];
  selectedProfileKeys = [];
  submissionDetails: KalgudiProjectTask;
  activityType = ActivityType;

  isBulk: boolean = true;

  private taskService: KalgudiTasksService;

  constructor(
    private kalgudiTaskSubmissionDaService: KalgudiTaskSubmissionDaService,
    protected injector: Injector,
    private kalgudiAppService: KalgudiAppService,

  ) {
    super();
    this.taskService              = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
  }

  ngOnInit() {

    // setTimeout(() => {
    //   this.kalgudiTaskSubmissionDaService.selectedMembersList$
    //     .pipe(takeUntil(this.destroyed$))
    //     .subscribe(
    //       res => {

    //         this.members = res;
    //         res.forEach(profile => {
    //           this.selectedProfileKeys.push(profile.profileKey);
    //         })
    //       }
    //     );
    // }, 1000)

    if(localStorage.getItem('selectedProfiles')) {
      this.members = JSON.parse(localStorage.getItem('selectedProfiles'));
      this.members.forEach(profile => {
        this.selectedProfileKeys.push(profile.profileKey);
      })
    }

    this.fetchTaskSubmission().subscribe( res => this.submissionDetails = res);
  }

  ngOnChanges() {
    if(this.selectedProfileKey) {
      this.fetchProfileDetails(this.selectedProfileKey);
      this.selectedProfileKeys.push(this.selectedProfileKey);
    }
  }

  /**
   * Gets profile details
   * @param profileKey
   */
  fetchProfileDetails(profileKey: string) {
    this.kalgudiAppService.fetchUserProfile(profileKey)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.members.push(res);
      })
  }

  onDestroyed(): void {}

  /**
   * Fetching task submission details
   * @returns
   */
  protected fetchTaskSubmission(): Observable<KalgudiProjectTask> {

    return this.taskService.getActivities(
      this.activityType.TASK_SUBMISSION,
      this.taskDetails.taskId,
      0,
      1,
      '',
    )
    .pipe(
      map(res => res.items[0]),
    );
  }

}
