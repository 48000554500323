import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { KalgudiTaskSubmissionDaApiService } from './kalgudi-task-submission-da-api.service';


@Injectable()
export class KalgudiTaskSubmissionDaService  {

  selectedMembersList$: Observable<any[]>;

  private selectedMembersListSubject$ = new BehaviorSubject<any[]>([]);

  constructor(
    protected api: KalgudiTaskSubmissionDaApiService
  ) {

    this.selectedMembersList$ = this.selectedMembersListSubject$;
  }


  /**
   * Calls api method to get task list
   * @param projectId
   * @param offset
   * @param limit
   */
  getTaskMembersList(offset: number, limit: number, taskId: string, membersStatus: string): Observable<KalgudiStreamData> {
    return this.api.fetchStream(offset, limit, taskId, membersStatus);
  }

  /**
   * Makes an API call to search list of all tasks with the specified
   * search keyword.
   * @param searchKeyword
   * @param offset
   * @param limit
   * @param projectId
   */
  searchTaskMembersList(
    searchKeyword: string,
    offset: number,
    limit: number,
    projectId: string,
    taskId: string,
    membersStatus: string,
  ): Observable<KalgudiStreamData> {
    return this.api.searchTaskMembersList(searchKeyword, offset, limit, projectId, taskId, membersStatus);
  }

  /**
   * Update selected task members
   * @param members
   */
  updateSelectedTaskMembers(members) {
    this.selectedMembersListSubject$.next(members);
  }
}
