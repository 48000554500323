<div fxLayout="column" class="bulk-member-addition">

  <form class="w-100" [formGroup]="addMembersForm" autocomplete="off">

    <mat-form-field appearance="outline" class="w-100" [ngClass]="{'users-count': loginIdCount}">
      <mat-label translate>Add bulk members</mat-label>
      <textarea matInput [placeholder]="'Add bulk members using email/mobile id' | translate" formControlName="loginIds" required></textarea>
      <mat-error translate>Please enter email id or mobile number</mat-error>
    </mat-form-field>
    <span *ngIf="loginIdCount">User count : {{ loginIdCount }}</span>
  </form>

  <div class="pt-0" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-flat-button
      label="Add members"
      [disabled]="addMembersForm.invalid"
      (clicked)="addMembers()">
    </kl-flat-button>
  </div>
</div>
