<main>
  <!-- Progress -->
  <ng-container *ngIf="progress">
    <kl-no-content [progress]="progress" progressText="Loading tasks..."></kl-no-content>
  </ng-container>
  <!--/ Progress -->

  <!-- Tasks tiles -->
  <ng-container *ngIf="!progress">

    <div class="mb-3">
      <kl-user-current-tasks [userCurrentTasks]="userCurrentTasks"></kl-user-current-tasks>
    </div>

    <div class="mb-3">
      <kl-user-member-tasks [isAssisted]="isAssisted" [profileKey]="profileKey" [userMemberTasks]="userMemberTasks"></kl-user-member-tasks>
    </div>

    <div class="mb-3" #adminTasksTile>
      <kl-user-admin-tasks [userAdminTasks]="userAdminTasks"></kl-user-admin-tasks>
    </div>

  </ng-container>
  <!--/ Tasks tiles -->
</main>
