import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiQuestionListResponseData,
  ApiResponseCommon,
  KalgudiSurveyQuestion,
  KalgudiSurveyQuestionBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiSurveyQuestionsApiService {


  /**
   * `v2/social/surveys/:surveyId/questions`
   */
  private readonly API_SURVEYS_QUESTIONS = `${this.environment.restBaseUrlV2}/social/surveys/:surveyId/questions`;

  /**
   * `v2/social/surveys/:surveyId/questions/questionId`
   */
  private readonly API_SURVEYS_QUESTION = `${this.API_SURVEYS_QUESTIONS}/:questionId`;



  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Api to get all questions of a survey
   */
  getQuestions(surveyId: string): Observable<ApiQuestionListResponseData> {

    return this.httpClient.get<ApiResponseCommon>(this.API_SURVEYS_QUESTIONS.replace(':surveyId', surveyId))
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        map(res => res.data)
      );

  }


  /**
   * Api to post an answer to the survey
   */
  postQuestion(surveyId: string, payload: KalgudiSurveyQuestionBasicDetails): Observable<KalgudiSurveyQuestion> {

    return this.httpClient.post<ApiResponseCommon>(this.API_SURVEYS_QUESTIONS.replace(':surveyId', surveyId), payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );

  }

  /**
   * Api to delete an answer from the survey
   */
  deleteQuestion(surveyId: string, questionId: string): Observable<boolean> {

    const url = this.API_SURVEYS_QUESTION.replace(':surveyId', surveyId).replace(':questionId', questionId);

    return this.httpClient.delete<ApiResponseCommon>(url)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        map(res => res.data.deleted)
      );

  }

}
