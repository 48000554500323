import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ActivityType, KalgudiDialogConfig, KalgudiUser, ProjectRole, TaskShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, skip, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiTasksService } from '../services/kalgudi-tasks.service';
import { KalgudiTaskActivityStateService } from '../services/task-activity-state.service';

@Directive()
export abstract class TaskUpdatesStream extends KalgudiInboxStream<TaskShareUpdate> {

  @Input()
  taskId: string;

  @Input()
  activityTypes: ActivityType;

  @Input()
  memberProfileKey: string;

  @Input()
  memberRole: string;

  @Input()
  submissionId: string;

  @Input()
  projectId: string;

  profileKey: string;

  projectRole = ProjectRole;

  activityType = ActivityType;

  userDetails: KalgudiUser;

  private tasksService: KalgudiTasksService;
  private stateService: KalgudiTaskActivityStateService;
  private kalgudiApp: KalgudiAppService;
  private kalgudiDialogs: KalgudiDialogsService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.tasksService   = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.stateService   = this.injector.get<KalgudiTaskActivityStateService>(KalgudiTaskActivityStateService);
    this.kalgudiApp     = this.injector.get(KalgudiAppService);
    this.kalgudiDialogs = this.injector.get(KalgudiDialogsService);

    this.pageLimit = 10;

    this.stateService.taskActivityUpdated$
      .pipe(

        takeUntil(this.destroyed$),

        skip(1)
      )
      .subscribe(
        (res) => this.resetStream()
      );

    this.userDetails = this.kalgudiApp.profileLocal;
  }

  /**
   * Deletes the post. Removes the post from the stream.
   */
  deletePost(activityId: string): void {

    this.showDeleteConfirmationDialog()
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        map(r => this.notification.showSpinner()),

        // Calls api to delete post if accepted to delete
        switchMap(r => this.deletePostApi(activityId)),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onPostDeleted(),
        err => this.onPostActionApiError(err)
      );
  }

  protected streamApi(offset: number, limit: number ): Observable<KalgudiStreamData> {

    const activityType = this.activityTypes ? this.activityTypes : `${this.activityType.COMMENT},${this.activityType.MEMBER_UPDATES}`;

    // if (this.memberRole === this.projectRole.ADMIN) {

    //   this.profileKey = activityType === this.activityType.MEMBER_UPDATES ? this.memberProfileKey : '';
    // }

    const submissionId = this.activityTypes === this.activityType.MEMBER_UPDATES ? this.submissionId : '';


    return this.tasksService.getActivities(activityType, this.taskId, offset, limit, '', submissionId)
      .pipe(
        takeUntil(this.destroyed$),
        tap(res => setTimeout(() => {
          this.util.scrollToBottom('auto', 'task-updates');
        })
        )
      );
  }

  /**
   * Api method to delete post
   * @param id
   * @returns
   */
  protected deletePostApi(id: string): Observable<boolean> {

    // Call api to delete post
    return this.tasksService.deleteActivity(this.projectId, this.taskId, this.activityTypes, id);
  }

  /**
   * Implement operations to be performed on successful post deletion.
   */
  protected onPostDeleted(): void {
    // throw new NotImplementedError(new Error('Implement onPostDeleted() method if you are using delete()'));
    this.resetStream();
  }

  /**
   * Event handler for share update posting API errors.
   */
  protected onPostActionApiError(err: Error): void {

    this.notification.showMessage('Unable to delete activity, please try again later!');
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the post. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

}
