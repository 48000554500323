import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseProjectData, KalgudiProject, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProjectCreationApiService {


  /**
   * `v2/projects`
   */
  private readonly API_PROJECTS = `${this.environment.restBaseUrlV2}/projects`;

  /**
   * `v2/projects/:projectId`
   */
  private readonly API_PROJECT = `${this.API_PROJECTS}/:projectId`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Api to create a project
   */
  createProject(req: any, filters: PartialData = {}): Observable<KalgudiProject> {

    const params = {
      ...filters
    };

    return this.httpClient
      .post<ApiResponseProjectData>(this.API_PROJECTS, req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );
  }


  /**
   * Api to update the project
   */
  updateProject(req: any, projectId: string, filters: PartialData = {}): Observable<any> {

    const params = {
      ...filters
    };

    return this.httpClient
      .put<ApiResponseCommon>(this.API_PROJECT.replace(':projectId', projectId), req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        map(res => res.data)
      );
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

}
