import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiCreditDetails } from '../../classes/kalgudi-credit-details';

@Component({
  selector: 'kl-credit-details',
  templateUrl: './credit-details.component.html',
  styleUrls: ['./credit-details.component.scss']
})
export class CreditDetailsComponent extends KalgudiCreditDetails implements OnInit {

  isProgramList = false;

  constructor(
    protected injector: Injector
  ) {
    super(
      injector
    );
  }

  ngOnInit() {
    this.fetchCreditDetails();
    this.fetchPages();
  }

  /**
   * Toggle the program list table(show & hide programs list)
   */
  toggleIsProgramList() {
    this.isProgramList = !this.isProgramList;
  }

  protected onDestroyed(): void {}

}
