import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { KALGUDI_S3_POLICY_MAP, KalgudiUploadService, S3UploadNamePathMap } from '@kalgudi/core';
import { Attachment, AttachmentType, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { map } from 'rxjs/operators';

@Directive()
export abstract class KalgudiUploadAttachments {

  @Input()
  attachments: Attachment[] = [];

  @Output()
  attachmentsChange = new EventEmitter<Attachment[]>();

  @Input()
  maxImages: number;

  @Input()
  s3Category: S3PolicyPathCategoryMap;

  @Input()
  multiple: boolean;

  @Input()
  acceptFileTypes: string;

  constructor(
    protected uploadService: KalgudiUploadService
  ) { }


  /**
   * Gets the selected files
   * @param event
   */
  onFileSelection(event: any): void {

    const path = this.s3Category && this.s3Category.category
      ? this.s3Category
      : KALGUDI_S3_POLICY_MAP.DEFAULT;

    this.uploadFiles(event.target.files, path, false);

  }


  /**
   * Uploads a list of files to S3. On success it returns the uploaded file path
   * with the original file name.
   * @param files
   * @param path
   * @param compression
   */
  uploadFiles(files: FileList, path, compression: boolean): void {

    const fileList = Array.from(files);

    this.uploadService.uploadFiles(fileList, path, compression)
      .pipe(
        map(r => this.mapUploadResponse(r))
      )
      .subscribe(res => this.injectAttachments(res));
  }

  /**
   * Injects the uploaded attachments into an array
   * @param images
   */
  injectAttachments(images: Attachment[]) {
    this.attachments = this.attachments.concat(images).splice(0, this.maxImages);

    this.attachmentsChange.next(this.attachments);
  }


  /**
   * Removes the selected item from an array
   * @param index
   */
  remove(index: number) {
    this.attachments.splice(index, 1);
  }

  /**
   * Maps the response into attachment model
   * @param uploads
   */
  private mapUploadResponse(uploads: S3UploadNamePathMap[]): Attachment[] {

    const attachmentType: AttachmentType = 'IMAGE';

    return uploads.map(u => {
      return {
        url: u.filePath,
        context: '',
        msgType: attachmentType
      };
    });
  }

}
