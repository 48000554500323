import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { CreationDialogComponent } from '../components/creation-dialog/creation-dialog.component';
import { MobileCreationDialogComponent } from '../components/mobile-creation-dialog/mobile-creation-dialog-component';

@Injectable()
export class KalgudiProjectCreationDialogService {

  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
  ) { }

  /**
   * Opens project creation dialog
   */
  openProjectCreationDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialogService.openMobileDialog(MobileCreationDialogComponent, details)
      : this.kalgudiDialogService.openDialog(CreationDialogComponent, details, config);
  }
}
