import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StorePageComponent } from './components/store-page/store-page.component';


const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Store',
      showBackButton: true,
    },
    component: StorePageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StoreRoutingModule { }
