<mat-card class="crop-seasons-list p-2">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div class="m-0 heading">Crop season</div>
    <div>
      <button mat-icon-button class="add-season" [ngClass]="{'close-form': showAddSeasonForm}" (click)="displayAddSeasonForm()">
        <mat-icon *ngIf="!showAddSeasonForm">add</mat-icon>
        <mat-icon *ngIf="showAddSeasonForm">close</mat-icon>
      </button>
    </div>
  </div>

  <!-- Seasons form -->
  <div *ngIf="showAddSeasonForm">
    <kl-cooperative-society-add-season-form
    [cropId]="cropId"
    [required]="true"
    [pageId]="pageId"
    [profileKey]="profileKey"
    [isAssisted]="isAssisted"
    [selectedSeason]="selectedSeason"
    [isEditSeason]="isSeasonEdit"
    (seasonAdded)="getSeasons(); displayAddSeasonForm()"
    ></kl-cooperative-society-add-season-form>
  </div>
  <!-- /Seasons form -->

  <!-- Seasons list -->
  <div fxLayout="column">
    <div fxLayout="column" *ngFor="let season of seasonsList; let last = last" class="p-2" [ngClass]="{'border-bottom': !last}">
      <div fxLayout="row" fxLayoutAlign="space-between start">
        <div>
          <p class="m-0 crop-name">{{season?.title}}
            <!-- <span> - </span>
            <span>{{season?.season}}</span> -->
          </p>
          <p class="m-0 date">{{season?.seasonTimeFrame?.from | date: 'd/M/yy'}}
            <span> - </span>
            <span>{{season?.seasonTimeFrame?.to | date: 'd/M/yy'}}</span>
          </p>
        </div>
        <div fxLayout="row">
          <div>
            <button mat-icon-button class="action-buttons" (click)="editSeason(season)"><mat-icon>edit</mat-icon></button>
          </div>
          <div>
            <button mat-icon-button class="action-buttons" (click)="deleteSeason(season?.id)"><mat-icon>delete_outline</mat-icon></button>
          </div>

        </div>

      </div>

      <div fxLayout="row">
        <div fxFlex="20%">
          <p class="m-0 title">Farmers</p>
          <p class="m-0 value">{{season?.farmers | modernNumber}}</p>
        </div>
        <div fxFlex="20%">
          <p class="m-0 title">Acres</p>
          <p class="m-0 value">{{season?.acres}}</p>
        </div>
        <div fxFlex="35%">
          <p class="m-0 title">Expected yield</p>
          <p class="m-0 value">{{season?.expectedYield?.value}}
            <span> {{season?.expectedYield?.unit}}</span>
          </p>
        </div>
        <div fxFlex="25%">
          <p class="m-0 title">Actual yield</p>
          <p class="m-0 value">{{season?.actualYield?.value}}
            <span> {{season?.actualYield?.unit}}</span>
          </p>
        </div>
      </div>

      <div fxLayout="row" *ngIf="season?.harvestingMonth">
        <div fxFlex="50%">
          <p class="m-0 title">Harvest month</p>
          <p class="m-0 value">{{season?.harvestingMonth | date: 'd/M/yy'}}</p>
        </div>
      </div>
    </div>

  </div>
  <!-- /Seasons list -->
</mat-card>
