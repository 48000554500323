import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import {
  ApiError,
  HttpStatusCode,
  KalgudiStreamData,
  KalgudiUtilityService,
} from "@kalgudi/core";
import { KalgudiEnvironmentConfig, KL_ENV } from "@kalgudi/core/config";
import {
  ApiResponseCommon,
  KalgudiSms,
  KalgudiSpecificSubscribers,
  LogsDownloadResponse,
  LogsResponseData,
  PublishSmsResponse,
} from "@kalgudi/types";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class LogsListApiService {
  /**
   * `v2/pages/sms/:smsId/logs`
   */
  private readonly API_LOGS_LIST = `${this.env.restBaseUrlV2}/pages/sms/:smsId/logs`;

  /**
   * `v2/pages/sms/:smsId/downloadLogs`
   */
  private readonly API_DOWNLOAD_LOGS_LIST = `${this.env.restBaseUrlV2}/pages/sms/:smsId/downloadLogs`;

  /**
   * `v2/pages/pageId/sms`
   */
  private readonly API_PUBLISH_SMS = `${this.env.restBaseUrlV2}/pages/:pageId/sms`;

  /**
   * `v2/pages/sms/scheduled/:scheduleId`
   */
  private readonly API_UPDATE_SMS = `${this.env.restBaseUrlV2}/pages/sms/scheduled/:scheduleId`;

  /**
   * `v2/pages/:pageId/sms/stream`
   */
  private readonly API_SMS_STREAM = `${this.env.restBaseUrlV2}/pages/:pageId/sms/stream`;

  /**
   * /v2/pages/cem/sms-templates
   */
  private readonly SMS_TEMPLATES_LIST = `${this.env.restBaseUrlV2}/pages/cem/sms-templates`;

  /**
   * /v2/pages/{pageId}/sms/templates
   */
  private readonly SMS_TEMPLATES_LIST_FOR_WL = `${this.env.restBaseUrlV2}/pages/{pageId}/sms/templates`;

  /**
   * /v2/pages/cem/short-url
   */
  private readonly SHORT_URL = `${this.env.restBaseUrlV2}/pages/cem/short-url`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) {}

  /**
   * Calls api to get logs list
   *
   * @param pageId
   */
  fetchLogsList(
    smsId: string,
    pageId: string,
    offset: number,
    limit: number,
    filter: string
  ): Observable<LogsResponseData> {
    // API request query params
    const params = {
      pageId,
      limit: limit.toString(),
      offset: offset.toString(),
      filter,
    };

    const url = this.API_LOGS_LIST.replace(":smsId", smsId);

    return this.httpClient.get<ApiResponseCommon>(url, { params }).pipe(
      // Check and handle API response for errors
      map((res) => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi logs details object type
      map((res) => this.mapLogsStreamResponse(res))
    );
  }

  /**
   * Calls api to download logs list
   */
  downloadLogsList(smsId: string): Observable<LogsDownloadResponse> {
    const url = this.API_DOWNLOAD_LOGS_LIST.replace(":smsId", smsId);

    return this.httpClient.get<ApiResponseCommon>(url).pipe(
      // Check and handle API response for errors
      map((res) => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi logs details object type
      map((res) => res.data)
    );
  }

  /**
   * `Calls api method to publish sms`
   */
  publishNewSms(
    pageId: string,
    payload: KalgudiSpecificSubscribers
  ): Observable<KalgudiSms> {
    // Api url
    const url = this.API_PUBLISH_SMS.replace(":pageId", pageId);

    return this.httpClient.post<PublishSmsResponse>(url, payload).pipe(
      // Api response error handler
      map((r) => this.util.apiErrorHandler(r)),

      // Map API response to Kalgudi publish sms
      map((r) => r.data)
    );
  }

  /**
   * `Calls api method to update sms`
   */
  updateScheduledSms(
    scheduleId: string,
    payload: KalgudiSpecificSubscribers
  ): Observable<KalgudiSms> {
    // Api url
    const url = this.API_UPDATE_SMS.replace(":scheduleId", scheduleId);

    return this.httpClient.put<PublishSmsResponse>(url, payload).pipe(
      // Api response error handler
      map((r) => this.util.apiErrorHandler(r)),

      // Map API response to Kalgudi update sms
      map((r) => r.data)
    );
  }

  /**
   * `Calls api method to get sms stream`
   */
  getSmsStream(
    pageId: string,
    offset: number,
    limit: number
  ): Observable<KalgudiStreamData> {
    // Api url
    const url = this.API_SMS_STREAM.replace(":pageId", pageId);

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
    };

    return this.httpClient.get<ApiResponseCommon>(url, { params }).pipe(
      // Check and handle API response for errors
      map((res) => this.util.apiErrorHandler(res)),

      // Map API response to Kalgudi sms stream
      map((res) => ({ count: res.data.count, items: res.data.results }))
    );
  }

  /**
   * Api to get sms templates list
   */
  getSmsTemplateList(): Observable<any> {
    const WL_APPS = ["SAM_FPO"];
    const params = {
      appStoreType: "BIZ_STORE",
      appName: "UNIFIED",
      appStoreDomain: this.env.domain,
    };
    if (WL_APPS.includes(this.env.appId)) {
      return this.getSmsTemplateForWhiteListApp(params);
    } else {
      return this.httpClient
        .get<ApiResponseCommon>(this.SMS_TEMPLATES_LIST, { params })
        .pipe(
          map((res) => this.util.apiErrorHandler(res)),

          map((res) => res.data.results)
        );
    }
  }

  getSmsTemplateForWhiteListApp(params: any): Observable<any> {
    return this.httpClient
      .get<ApiResponseCommon>(this.SMS_TEMPLATES_LIST_FOR_WL)
      .pipe(
        map((res) => this.util.apiErrorHandler(res)),

        map((res) => {
          const formalizedTemplates = res.data.templates?.map(
            (template: any, index: number) => {
              return {
                ...template,
                smsTemplateTitle: template.sms_template,
                smsTemplateId: `${template.template_id}${index}`,
                message: template.sms_template,
                templateType: "Promotional",
              };
            }
          );
          return formalizedTemplates;
        })
      );
  }

  /**
   * Api to get short url
   */
  convertToShortUrl(payload: any): Observable<any> {
    return this.httpClient
      .post<ApiResponseCommon>(this.SHORT_URL, payload)
      .pipe(
        map((res) => {
          if (res.code !== HttpStatusCode.OK) {
            const errorMessage =
              res.info || "Something went wrong, please try again later.";
            throw new ApiError(new Error(errorMessage));
          }

          // All good, return the response back
          return res;
        }),

        map((res) => res.data)
      );
  }

  /**
   * Maps logs list API response.
   */
  private mapLogsStreamResponse(res: ApiResponseCommon): LogsResponseData {
    return {
      items: res.data.results,
      count: res.data.count,
      pending: res.data.pending,
      success: res.data.success,
      failed: res.data.failed,
    };
  }
}
