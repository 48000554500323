<div class="previous-syncs-list">

  <section id="stats-page">

    <div *ngIf="pageStats && !statsLoadingProgress">
      <!-- Page stats -->
      <!-- <div @fadeIn>
        <store-admin-page-stats
          [formControl]="selectedStatForm"
          [enableAction]="true"
          [cardType]="pageStats?.viewType"
          [stats]="pageStats?.results">
        </store-admin-page-stats>
      </div> -->


      <ng-container *ngIf="selectedStat">

        <div class="my-3" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">
          <div>

            <h3 class="m-0 header-title" @slideRight>Previous syncs</h3>

            <!-- <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
              <button mat-stroked-button
                title="Download report as CSV"
                [disabled]="!agGridOptions?.results"
                (click)="downloadCsv(selectedStat?.title)" color="primary">
                <mat-icon>get_app</mat-icon>
                <span translate>Download</span>
              </button>

            </div> -->
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
            <button mat-flat-button color="primary" class="send-btn" (click)="syncRecords()">
              <span translate>Sync satellite advisories</span>
            </button>
          </div>

        </div>

        <div @fadeIn>

          <ag-grid-angular #agGrid
            style="width: 100%; height: 600px"
            class="ag-theme-balham"
            [rowData]="agGridOptions?.results"
            [columnDefs]="agGridOptions?.columnDefs"
            [animateRows]="animateRows"

            [rowGroupPanelShow]="'always'"
            [floatingFilter]="agGridOptions?.floatingFilter"

            [paginationAutoPageSize]="paginationAutoPageSize"
            [paginationPageSize]="paginationPageSize"
            [pagination]="pagination"

            (rowClicked)="onRowClicked($event)">
          </ag-grid-angular>

        </div>
      </ng-container>
    </div>

    <kl-no-content *ngIf="!pageStats || statsLoadingProgress"
      svgIcon="assets/svgs/contributors.svg"
      text="Unable to load page members, please try again later"
      progressText="Loading page members, please wait..."
      [progress]="statsLoadingProgress">
    </kl-no-content>
  </section>

</div>

