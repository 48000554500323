import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { KalgudiFarmerLand } from '@kalgudi/types';
import { transformMassArea } from '../mass-area-unit-conversion.pipe';
import { SatelliteAdvisoriesService } from '../satellite-advisories.service';

@Component({
  selector: 'kl-crop-health-data',
  templateUrl: './crop-health-data.component.html',
  styleUrls: ['./crop-health-data.component.scss']
})
export class CropHealthDataComponent implements OnInit, OnChanges {

  @ViewChild('satImg', { static: true })
  satImgRef: ElementRef;

  @Input()
  plantHealthImage: string;

  @Input()
  plantHealthDetails: any;

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  bbox: number[] = [];

  @Input()
  estimatedTime: string;

  boundaryPoints: string = '';

  plantHealthPerHectare: number;
  plantHealthPerAcre: number;
  plantHealthPerFeddan: number;

  status: 'low' | 'good' | 'more';

  constructor( private satelliteAdvisoriesService: SatelliteAdvisoriesService ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.landDetails && this.bbox && !this.boundaryPoints) {
      this.boundaryPoints = this.satelliteAdvisoriesService.convertLatLongToPoints(
        this.landDetails.geoFences,
        this.satImgRef.nativeElement.offsetWidth,
        this.satImgRef.nativeElement.offsetHeight,
        this.bbox
      );
    }

    if (changes.plantHealthDetails && this.plantHealthDetails) {
      this.calculateStatus();
    }
  }

  /**
   * Calculating the status
   */
  private calculateStatus() {
    this.plantHealthPerHectare = this.plantHealthDetails.averageLevel;
    this.plantHealthPerAcre = this.plantHealthPerHectare * 2.47105;
    this.plantHealthPerFeddan = transformMassArea(this.plantHealthPerHectare, 'feddan'); // this.phosphorusPerAcre * 1.03;


    if (this.plantHealthPerFeddan < 10) {
      this.status = 'low';
    } else if (this.plantHealthPerFeddan > 20) {
      this.status = 'more';
    } else {
      this.status = 'good';
    }
  }
}
