import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import {
  SurveyQuestionAttachmentsComponent,
} from './components/survey-question-attachments/survey-question-attachments.component';



@NgModule({
  declarations: [SurveyQuestionAttachmentsComponent],
  imports: [
    CommonModule,

    // Flex
    FlexLayoutModule,

    // Material modules
    TranslateModule,

    // Kalgudi modules
    KalgudiPipesModule
  ],
  exports: [
    SurveyQuestionAttachmentsComponent
  ]
})
export class KalgudiSurveySharedModule { }
