import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AddProjectMembersResponse } from '@kalgudi/types';

import { AddProjectMembers } from '../../classes/add-project-members';

@Component({
  selector: 'kl-add-project-members',
  templateUrl: './add-project-members.component.html',
  styleUrls: ['./add-project-members.component.scss']
})
export class AddProjectMembersComponent extends AddProjectMembers implements OnInit {

  @Output()
  addedMembers = new EventEmitter<AddProjectMembersResponse>();


  activeTabIndex: number;

  readonly TABS = {
    MANAGE: { index: 0, title: 'Manage Members' },
    SEARCH: { index: 1, title: 'Search Members' },
    BULK_MEMBERS: { index: 2, title: 'Bulk members' },
  };

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(injector);

    this.activeTabIndex = 0;
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Called after successfully adding the members
   */
  protected membersAdded(res) {

    this.activeTabIndex = this.TABS.MANAGE.index;
    // this.addedMembers.emit(res);

  }

}
