import { AfterViewInit, Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { KalgudiCreateStockResponse, KalgudiStock } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiCreateStock } from '../../../../classes/kalgudi-create-stock';
import { StockStateService } from '../../services/stock-state.service';

@Component({
  selector: 'kl-create-stock',
  templateUrl: './create-stock.component.html',
  styleUrls: ['./create-stock.component.scss']
})
export class CreateStockComponent extends KalgudiCreateStock implements OnInit, AfterViewInit {

  @Output()
  stockCreated = new EventEmitter<boolean>();

  stockDetails: KalgudiStock;

  constructor(
    protected injector: Injector,
    protected stockStateService: StockStateService
  ) {

    super(injector);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.stockId) {
      this.stockStateService.stockDetails$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          res => {
            this.stockDetails = res;
            this.createStockForm.patchValue(this.stockDetails);
          }
        );
      }
  }

  onDestroyed(): void {}

  protected onStockCreated(payload: KalgudiCreateStockResponse, response: any): void {

    this.stockCreated.emit();
  }

}
