import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseProjectMembersData,
  ApiResponseTaskMembersData,
  BulkMembersAdditionResponse,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class AddTaskMembersApiService {

  /**
   * `v2/projects/members`
   */
  private readonly API_TASK_MEMBERS = `${this.environment.restBaseUrlV2}/projects/members`;

  /**
   * `v2/projects/members/search`
   */
  private readonly API_SEARCH_MEMBERS = `${this.environment.restBaseUrlV2}/projects/members/search`;


  /**
   * `v2/projects/members/bulk`
   */
  private readonly API_TASK_BULK_MEMBERS = `${this.API_TASK_MEMBERS}/bulk`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Api to add members to task
   */
  addTaskMembers(taskId: string, req, filters: PartialData = {}): Observable<ApiResponseTaskMembersData> {
    const params = {
      taskId,
      ...filters
    };

    return this.httpClient
      .post<ApiResponseCommon>(this.API_TASK_MEMBERS, req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),
        map(res => res.data)
      );
  }


  /**
   * Api to add members to task
   */
  getTaskMembers(taskId: string, offset: number, limit: number): Observable<ApiResponseTaskMembersData> {
    const params = {
      taskId,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient
      .get<ApiResponseCommon>(this.API_TASK_MEMBERS, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),
        map(res => res.data)
      );
  }

  /**
   * Api to get project members
   */
  getProjectMembers(projectId: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {
    const params = {
      projectId,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient
    .get<ApiResponseCommon>(this.API_TASK_MEMBERS, { params })
    .pipe(
       // Api response error handler
      map(res => this.util.apiErrorHandler(res)),

       // No errors, all good return true
      map(res => res.data),
    );
  }

  /**
   * Api to delete members in task
   */
  deleteTaskMembers(taskId: string, projectId: string, profileKey: string): Observable<string[]> {
    const params = {
      taskId,
      projectId,
      profileKey
    };

    return this.httpClient
      .delete<ApiResponseCommon>(this.API_TASK_MEMBERS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Api to search project members
   */
  searchMembers(projectId: string, searchKeyword: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {

    const params = {
      projectId,
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient
      .get<ApiResponseCommon>(this.API_SEARCH_MEMBERS, { params })
      .pipe(
        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data)
      );
  }

  /**
   * Defines the api to add bulk members to a task using ids
   */
  addBulkMembers(taskId: string, payload: string[], extraParams: PartialData = {}): Observable<BulkMembersAdditionResponse> {

    // Api query params
    const params = {
      taskId,
      ...extraParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_TASK_BULK_MEMBERS, payload, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi task member details object type
        map(res => res.data)
      );
  }
}

