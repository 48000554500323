import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { KalgudiProductsPickerModule } from '../kalgudi-products-picker/kalgudi-products-picker.module';

import { KalgudiMobileDialogModule } from '../mobile-dialog/kalgudi-mobile-dialog.module';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from './components/input-dialog/input-dialog.component';
import { KalgudiProductsPickerDialogComponent } from './components/kalgudi-products-picker-dialog/kalgudi-products-picker-dialog.component';
import { KalgudiDialogsService } from './services/kalgudi-dialogs.service';

@NgModule({
  declarations: [
    ConfirmDialogComponent,
    InputDialogComponent,
    // KalgudiUploadImageDialogComponent,
    // KalgudiUsersPickerDialogComponent,
    // KalgudiConnectsPickerDialogComponent,
    KalgudiProductsPickerDialogComponent,
  ],
  imports: [
    CommonModule,
    KalgudiPipesModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,

    // Flex
    FlexLayoutModule,

    KalgudiCoreModule,
    KalgudiButtonModule,
    KalgudiMobileDialogModule,
    // KalgudiUserPickerModule,
    // KalgudiConnectsPickerModule,
    // KalgudiProgramsMemberPickerModule,
    KalgudiProductsPickerModule,

    TranslateModule
  ],
  exports: [
    ConfirmDialogComponent,
    InputDialogComponent,
    // KalgudiUploadImageDialogComponent,
    // KalgudiUsersPickerDialogComponent,
    // KalgudiConnectsPickerDialogComponent,
    // KalgudiProductsPickerDialogComponent,
  ],
  entryComponents: [
    ConfirmDialogComponent,
    InputDialogComponent,
    // KalgudiUploadImageDialogComponent,
    // KalgudiUsersPickerDialogComponent,
    // KalgudiConnectsPickerDialogComponent,
    // KalgudiProductsPickerDialogComponent,
  ],
  providers: [
    KalgudiDialogsService,
  ],

})
export class KalgudiDialogsModule { }
