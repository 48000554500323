import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, EmployeeProfileTo, KalgudiUser, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class EmployeeProfileApiService {

  /**
   * `v2/profiles/employee-details`
   */
  private readonly API_EMPLOYEE_PROFILE = `${this.env.restBaseUrlV2}/profiles/employee-details`;

  constructor(
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Api definition for employee profile.
   */
  addEmployeeDetails(payload: EmployeeProfileTo, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_EMPLOYEE_PROFILE, payload, { params })
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response to employee profile details
        map(res => res.data)
      );
  }

}
