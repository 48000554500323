import { DatePipe } from '@angular/common';
import { Directive, Inject, Injector, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService, SHARE_TYPES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  IdValueMap,
  KalgudiPageDetails,
  KalgudiSms,
  KalgudiSpecificSubscribers,
  LabelValueMap,
  ScheduleDates,
} from '@kalgudi/types';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { filter, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiSmsUpdateService } from '../services/kalgudi-sms-update.service';
import { LogsListService } from '../services/logs-list.service';

@Directive()
export abstract class KalgudiPublishSms extends KalgudiDestroyable {

  @Input()
  scheduleId: string;

  varInputsForm: FormGroup;
  items: FormArray;

  varInputs: IdValueMap[] = [
    { id: "TEXT", value: "Text" },
    // {id: 'PRODUCT_SKU', value: 'Product SKU'},
    { id: "URL", value: "URL" },
    { id: "DATE", value: "Date" },
  ];

  smsTemplateList: any[] = [];
  varCount: number;
  sampleMessage: string;
  splittedArray: any;
  varList: any;
  disableGenerateSmsBtn: boolean = false;
  shortUrl: string;
  fpoMsg: string;
  isSamunnatiFpo: boolean = false;

  readonly sharedVisibilities = SHARE_TYPES;

  // scheduleBtnClicked = new FormControl('');

  shareVisibilityList: LabelValueMap[] = [SHARE_TYPES.ALL_SUBSCRIBERS];

  scheduledDates: ScheduleDates;

  shareForm: FormGroup;

  pageId: string;

  message: FormControl;

  progress: boolean;

  // SMS credits
  deductedCredit = 0;
  smsCreditSize = 160;
  countPercentage = 0;
  isSmsTextLimitExceded = false;

  isSMSToAltNumber = true;

  // Dependencies
  protected kalgudiPageService: KalgudiPageService;
  private logsListService: LogsListService;
  private smsUpdateService: KalgudiSmsUpdateService;
  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private datePipe: DatePipe;
  templateId: number;
  samItems: any[];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig

  ) {
    super();
    // Manually inject dependencies
    this.logsListService = this.injector.get(LogsListService);
    this.kalgudiPageService = this.injector.get(KalgudiPageService);
    this.smsUpdateService = this.injector.get(KalgudiSmsUpdateService);
    this.fb = this.injector.get(FormBuilder);
    this.datePipe = this.injector.get(DatePipe);

    this.isSamunnatiFpo = this.env.appId === 'SAM_FPO';

    this.message = new FormControl("", Validators.required);

    this.getPageDetails();

    this.shareForm = this.smsPublishForm;

    this.getSmsTemplateList();

    this.varInputsForm = this.fb.group({
      smsTemplateType: [""],
      productName: [""],
      fpoName: [""],
      fpoDate: [""],
      items: this.fb.array([this.varItems()]),
    });

    this.subscribeSmsTemplateTypeValueChanges();
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * service call to publish sms
   */
  sendSms() {
    this.progress = true;
    const payload = this.preparePayload();
    if (payload) {
      const res = payload.smsText.replace('\\n', '\n')
      payload.smsText = res;
    }
    if (this.scheduleId) {
      this.logsListService
        .updateScheduledSms(this.scheduleId, payload)
        .subscribe(
          (res) => this.onSmsSuccess(res),
          (err) => {
            this.notifications.showMessage(
              "Unable to publish SMS, please try again later."
            );
          }
        );
    } else {
      this.logsListService.publishNewSms(this.pageId, payload).subscribe(
        (res) => this.onSmsSuccess(res),
        (err) => {
          this.notifications.showMessage(
            "Unable to publish SMS, please try again later."
          );
        }
      );
    }
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get("recipient");
  }

  get shareToField(): AbstractControl {
    return this.shareForm.get("sharedTo");
  }

  get scheduledDateField(): AbstractControl {
    return this.shareForm.get("scheduledDate");
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * Getter for gender
   */
  get genderField(): AbstractControl {
    return this.shareFormFilter.get("gender");
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.shareFormVisibility.value;
  }

  /**
   * Getter for template type
   */
  get templateType(): AbstractControl {
    return this.varInputsForm.get("smsTemplateType");
  }

  get fpoName(): AbstractControl {
    return this.varInputsForm.get("fpoName");
  }

  get fpoDate(): AbstractControl {
    return this.varInputsForm.get("fpoDate");
  }

  get productName(): AbstractControl {
    return this.varInputsForm.get("productName");
  }

  /**
   * Allowing user to enter only letters with space
   */
  onKeyPress(event: any) {
    var x = event.which || event.keycode;
    if ((x >= 65 && x <= 90) || (x >= 97 && x <= 122) || x === 32) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Resets publish sms form. Overrides default implementation of reset form
   */
  resetForm(): void {
    this.shareForm.reset();
    this.message.reset();
    // patching values instead of null value
    this.shareFormVisibility.patchValue(this.shareVisibilityList[0].value);
    this.shareToField.patchValue(this.shareVisibilityList[0].value);
  }

  /**
   * To get sms text count
   */
  textCounter(shareText) {
    if (typeof shareText !== "undefined") {
      if (this.getByteCountPercentage(shareText)) {
        this.isSmsTextLimitExceded = false;
      } else {
        this.isSmsTextLimitExceded = true;
      }
    }
  }

  /**
   * Initializing the SMS details to the share form
   */
  initPostDetails(postDetails: KalgudiSms): void {
    this.shareForm.patchValue(postDetails);
    this.message.patchValue(postDetails.smsText);
  }

  /**
   * Event handler for sending SMS to alternate mobile number toggle
   */
  onSmsSwitchToggle(val: boolean): void {
    this.isSMSToAltNumber = val;
  }

  /**
   * Calls api to get short url
   */
  toShortUrl(url: string, index) {
    this.shortUrl = " ";

    const payload = {
      urlString: url,
    };

    this.logsListService.convertToShortUrl(payload).subscribe(
      (res) => {
        this.notifications.showMessage("Generated short URL");
        this.shortUrl = res;

        this.varInputsForm.value.items.forEach((varInput, i) => {
          if (varInput.type.id === "URL" && this.shortUrl && index === i) {
            varInput.shortUrl = this.shortUrl;
          }
        });

        if (index + 1 === this.varList.length) {
          this.varList[index] = this.shortUrl;
        }
      },
      (err) => {
        this.util.showApiErrorMessage(err);
      }
    );
  }

  /**
   * Gets list of sms templates
   */
  getSmsTemplateList() {
    this.logsListService.getSmsTemplateList().subscribe((res) => {
      res.forEach((smsTemplate) => {
        if (smsTemplate.templateType === "Promotional") {
          this.smsTemplateList.push(smsTemplate);
        }
      });
    });
  }

  /**
   * #var# form group
   * @returns
   */
  varItems(): FormGroup {
    return this.fb.group({
      type: ["", Validators.required],
      value: ["", [Validators.required]],
      shortUrl: [""],
    });
  }

  /**
   * Adds items to the varList
   */
  addItem(): void {
    this.items = this.varInputsForm.get("items") as FormArray;
    this.items.push(this.varItems());
  }

  /**
   * Shows sample message
   */
  showSampleMessage() {
    const resultArray: any = this.isSamunnatiFpo ? this.samItems : this.varList;
    resultArray.forEach((userInput: string | Date) => {
      let index = this.splittedArray.findIndex((value) =>
        value.includes("{#var#}")
      );
      // if (this.splittedArray.includes("{#var#},")) {
      //   index = this.splittedArray.indexOf("{#var#},");
      // } else if (this.splittedArray.includes("{#var#}.")) {
      //   index = this.splittedArray.indexOf("{#var#}.");
      // } else {
      //   index = this.splittedArray.indexOf("{#var#}");
      // }
      // this.splittedArray.splice(index, 1);
      if (moment(userInput).isValid()) {
        const date = this.datePipe.transform(userInput, 'dd-MMM-YYYY');
        userInput = this.isSamunnatiFpo ? date : moment(userInput).format("DD-MMM-YYYY");
      }
      this.splittedArray[index] = this.splittedArray[index].replace(
        "{#var#}",
        userInput
      );
      // this.splittedArray.splice(index, 0, userInput);
    });
    this.sampleMessage = this.splittedArray.join(" ");
    this.varInputsForm.disable();
    this.disableGenerateSmsBtn = true;
  }

  /**
   * Subscribes sms template type value changes
   */
  subscribeSmsTemplateTypeValueChanges() {
    this.templateType.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        const inputList = <FormArray>this.varInputsForm.controls.items;
        inputList.controls = [];
        this.varCount = 0;
        this.shortUrl = "";
        this.fpoName.reset();
        this.fpoDate.reset();
        this.productName.reset();

        if (res !== null && !res.message && !res.message.includes("{#var#}")) {
          this.sampleMessage = res.message;
        }

        this.varCount = res !== null ? res.message.split("{#var#}").length - 1 : 0;

        for (var i = 0; i < this.varCount; i++) this.addItem();

        this.splittedArray = res !== null ? res.message.split(" ") : [];

        this.subscribeVarInputFormValueChanges();
      });
  }

  /**
   * Subscribes value input form value changes
   */
  subscribeVarInputFormValueChanges() {
    this.varInputsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        if (res && res.items && res.items.length) {
          res.items.forEach((element) => {
            if (
              element.type.id === "URL" &&
              element.value !== "" &&
              this.shortUrl
            ) {
              // element.value = this.shortUrl;
            }
          });

          this.varList = res.items.map((obj) =>
            obj.shortUrl ? obj.shortUrl : obj.value
          );
        }
        this.disableGenerateSmsBtn = false;
      });
  }

  /**
   * Get selected templete id
   * @param event
   */
  selectedTemplate(event) {
    this.templateId = event.value.template_id;
  }

  protected abstract onSmsCreated(response: KalgudiSms): void;

  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * from group for filters
   */
  private get smsPublishForm(): FormGroup {
    return new FormGroup({
      // Common fields inherited from send sms
      sharedTo: new FormControl(
        this.shareVisibilityList[0].value,
        Validators.required
      ),

      // Program specific fields
      recipient: new FormControl(
        this.shareVisibilityList[0].value,
        Validators.required
      ),
      msgTypes: new FormControl([], Validators.required),
      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),
        pageId: new FormControl(""),
        isSms: new FormControl(true),
        gender: new FormControl(''),
      }),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(""),
      scheduledTS: new FormControl(""),
      isPublishedThroughTool: new FormControl(true),
    });
  }

  /**
   * Payload for publish sms
   */
  private preparePayload(): KalgudiSpecificSubscribers {
    if (this.shareForm.value.recipient === "SPECIFIC_SUBSCRIBERS") {
      this.shareForm.get("isPublishedThroughTool").patchValue(false);
    }

    if (
      this.shareForm.value.scheduledDate &&
      this.shareForm.value.scheduledTime
    ) {
      const date = new Date(this.shareForm.value.scheduledDate);

      const dateAndTime = `${date.getMonth() + 1
        }-${date.getDate()}-${date.getFullYear()} ${this.shareForm.value.scheduledTime
        }`;

      this.shareForm.value.scheduledTS = new Date(dateAndTime).toISOString();
    }

    const targetedMembers = {
      pageId: this.pageId,
      isSms: true,
      valid: true,
      isPublishedThroughTool: this.shareForm.value.isPublishedThroughTool,
      products: this.shareForm.value.filter.products,
      businessTypes: this.shareForm.value.filter.businessTypes,
      locations: this.shareForm.value.filter.locations,
      countries: this.shareForm.value.filter.countries,
      states: this.shareForm.value.filter.states,
      districts: this.shareForm.value.filter.districts,
    };

    this.samItems = [];
    if (this.isSamunnatiFpo) {
      if (this.templateType.value.template_name.includes('BOD')) {
        this.samItems.push(this.fpoName.value, this.fpoDate.value);
      }
      else if (this.templateType.value.template_name.includes('Annual')) {
        this.samItems.push(this.fpoName.value, this.fpoDate.value);
      }
      else if (this.templateType.value.template_name.includes('Input')) {
        this.samItems.push(this.productName.value, this.fpoName.value);
      }
      else if (this.templateType.value.template_name.includes('Procurement')) {
        this.samItems.push(this.fpoName.value, this.productName.value);
      }
      this.showSampleMessage();
    }

    const payload = {
      targetedMembersObject: targetedMembers,
      smsText: this.message.value,
      msgTypes: this.shareForm.value.msgTypes,
      recipient: this.shareForm.value.recipient,
      sharedTo: this.shareForm.value.sharedTo,
      scheduledTS: this.shareForm.value.scheduledTS,
      scheduledDate: this.shareForm.value.scheduledDate,
      scheduledTime: this.shareForm.value.scheduledTime,
      isSMSToAltNumber: this.isSMSToAltNumber,
      members: [],
      smsTemplateId: this.templateId,
      templateDetails: {
        smsTemplateId: this.templateId,
      },
      filters: {
        gender: this.genderField.value
      }
    };

    payload.smsText = this.sampleMessage;

    if (this.shareForm.value.recipient === "SPECIFIC_KALGUDI_USER") {
      payload.members = this.shareForm.value.filter.users;
    }

    return payload;
  }

  // private specificMembersList() {
  //   return this.notifications.showMessage('Please select members to send SMS');
  // }

  /**
   * Event handler for sms success
   */
  private onSmsSuccess(res) {
    this.resetForm();
    // Selected gender all by default after submit the form
    this.genderField.patchValue('');
    this.varInputsForm.reset();
    this.progress = false;
    this.notifications.showMessage("SMS sent successfully.");
    this.varInputsForm.enable();
    this.onSmsCreated(res);
  }

  /**
   * Gets the pageDetails for page id
   */
  private getPageDetails(): void {
    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap((_) =>
          this.kalgudiPageService.pageDetails$.pipe(
            takeUntil(this.destroyed$),

            first(),

            tap((r) => this.fetchPageId(r))
          )
        )
      )
      .subscribe();
  }

  /**
   * Gets page id
   */
  private fetchPageId(pageDetails: KalgudiPageDetails): void {
    this.pageId = pageDetails.pageId;
  }

  /**
   * to get Bytes count of a string
   */
  private getByteCountPercentage(shareText) {
    const count = encodeURI(shareText).split(/%..|./).length - 1;
    this.countPercentage = 100 - Math.round((count / 1330) * 100);
    if (this.countPercentage === 100) {
      this.countPercentage = 99;
    }
    if (this.countPercentage === 0) {
      if (count < 1330) {
        this.countPercentage = 1;
      } else if (count > 1330) {
        this.countPercentage = -1;
      } else {
        this.countPercentage = 0;
      }
    }
    // console.log('Byte count',count);
    if (count > 1330) {
      this.countPercentage = -1 * this.countPercentage;

      return false;
    } else {
      this.smsCreditCount(count);

      return true;
    }
  }

  /**
   * to get sms credit count
   */
  private smsCreditCount(count) {
    this.deductedCredit = Math.trunc(count / this.smsCreditSize);
    if (count % this.smsCreditSize > 0 || count === 0) {
      this.deductedCredit += 1;
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
