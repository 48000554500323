<section class="calendar-summary-list overflow-auto position-relative">

  <!-- Calendar summery form -->
  <div class="w-100 position-absolute">
    <kl-ai-predictions-filters-form
      (filterAiPredictionList)="fetchFilteredAiList($event)"
      (calendarSummaryDetails)="fetchCalendarSummaryDetails($event)" [pageId]="pageId">
    </kl-ai-predictions-filters-form>
  </div>
  <!--/ Calendar summery form -->

  <div class="container mt-45r" fxLayout="column">

    <mat-card class="p-2 mb-2" *ngIf="filterFormDetails?.products">
      <div fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="column">
          <p class="m-0 places">
            <!-- <span>{{filterFormDetails?.countries}} /</span> -->
            <span *ngIf="filterFormDetails?.locations">{{filterFormDetails?.locations}}</span>
            <span *ngIf="filterFormDetails?.states"> {{filterFormDetails?.states}}</span>
            <span *ngIf="filterFormDetails?.districts"> / {{filterFormDetails?.districts}}</span>
          </p>
          <p class="m-0 crops">
            <span *ngIf="filterFormDetails?.products">{{filterFormDetails?.products}}</span>
            <span *ngIf="filterFormDetails?.products && filterFormDetails?.businessTypes"> - </span>
            <span *ngIf="filterFormDetails?.businessTypes">{{filterFormDetails?.businessTypes}}</span>
          </p>
        </div>

        <!-- Refresh  -->
        <!--<div class="cursor-pointer" fxLayout="column" fxLayoutAlign="center center" (click)="refresh()">
          <mat-icon class="refresh-icon">loop</mat-icon>
          <span class="refresh-text">Refresh</span>
        </div> -->

      </div>
    </mat-card>

    <mat-card fxLayout fxLayoutAlign="space-between center" class="values-tiles">

      <div fxLayout="column" fxLayoutAlign="center start"
        *ngIf="!filterFormDetails?.states && !filterFormDetails?.districts && !filterFormDetails?.locations">
        <p class="value mb-0">{{aiStats?.states || 0}}</p>

        <h6 class="value-title font-weight-lighter">
          States
        </h6>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start"
        *ngIf="filterFormDetails?.districts">
        <p class="value mb-0">{{aiStats?.mandals || 0}}</p>

        <h6 class="value-title font-weight-lighter">
          Mandals
        </h6>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start"
        *ngIf="filterFormDetails?.states && !filterFormDetails?.districts">
        <p class="value mb-0">{{aiStats?.districts || 0}}</p>

        <h6 class="value-title font-weight-lighter">
          Districts
        </h6>
      </div>

      <div fxLayout="column" [ngClass]="{'cursor-pointer fpo': aiStats?.fpo}" fxLayoutAlign="center start" (click)="showFpoList(aiStats?.fpo)">
        <p class="value mb-0">{{aiStats?.fpo | modernNumber }}</p>

        <h6 class="value-title font-weight-lighter">
          FPO's
        </h6>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">
        <p class="value mb-0">{{aiStats?.farmers | modernNumber}}</p>

        <h6 class="value-title font-weight-lighter">
          Farmers
        </h6>
      </div>

      <div fxLayout="column" fxLayoutAlign="center start">
        <span class="value">
          <span>
            {{aiStats?.acres | modernNumber }}
          </span>
          <span class="unit">acres</span>
        </span>

        <h6 class="value-title font-weight-lighter">
          Land size
        </h6>
      </div>
    </mat-card>


    <!--<div *ngFor="let summary of calendarSummary">
      <mat-card class="calendar-summary-info mt-3">
          Calendar summary
          <div fxLayout="column">
            Calendar summary header
            <div class="calendar-summary-header py-2 mb-2">
              <div>
                <p class="m-0 title" translate>{{ summary?.title }}</p>
              </div>
            </div>
            Calendar summary header

            <div fxLayout="column" fxLayoutGap="9px">
              <div>
                <p class="m-0 summary-info">{{ summary?.content }}</p>
              </div>

              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxFlex="70%">
                  <span class="summary-info-timeSlab">
                    <span>
                      Time slab :
                    </span>
                    <span>{{ summary?.timeSlab }}</span>
                  </span>
                </div>

                <div fxFlex="30%" fxLayoutAlign="end">
                  <p class="summary-info-activities m-0">Activities</p>
                </div>
              </div>
            </div>

          </div>
          Calendar summary
      </mat-card>
    </div>-->

    <!-- AI prediction tile-->
    <div class="mt-2">
      <kl-ai-predictions-tile
        [informationList]="informationList"
        [inputsList]="inputsList"
        [outputsList]="outputsList"
        [progress]="progress"
        [pageId]="pageId">
      </kl-ai-predictions-tile>
    </div>
    <!--/ AI prediction tile-->

    <!-- Common AI prediction tile -->
    <!-- <kl-ai-predictions-common-tile
      [aiPredictions]="aiPredictions"
      [progress]="progress"
      [pageDetails]="pageDetails"
      [pageId]="pageId"
      [filterFormDetails]="filterFormDetails"
      (showFpoList)="showFpoList($event)">
    </kl-ai-predictions-common-tile> -->
    <!-- Common AI prediction tile -->

  </div>
</section>
