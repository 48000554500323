import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIElementSize } from '@kalgudi/types';

@Component({
  selector: 'kl-no-content',
  templateUrl: './kalgudi-no-content.component.html',
  styleUrls: ['./kalgudi-no-content.component.scss']
})
export class KalgudiNoContentComponent implements OnInit {

  @Input()
  svgIcon: string;

  @Input()
  matIcon: string;

  @Input()
  faIcon: string;

  @Input()
  iconColor = '#6c757d';  // Bootstrap text-muted color

  @Input()
  iconSize: UIElementSize = 'md';

  @Input()
  text: string = 'No more content to show';

  @Input()
  description: string;

  @Input()
  actionLink: string;

  @Input()
  progress: boolean;

  @Input()
  progressText: string = 'Loading...';

  @Output()
  action = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  onActionLinkClicked(): void {
    this.action.emit();
  }

}
