<main id="employee-info" class="p-3">

  <!-- Section for info details -->
  <form autocomplete="off" [formGroup]="employeeForm" >

    <!-- Section for specialization -->
    <section class="p-3 specific-sections mb-3 position-relative specialization">

      <h4 class="titles" translate>Specialization</h4>
      <div class="position-absolute edit-section" *ngIf="profileDetails?.employeeProfileTo?.specialization">
        <button mat-icon-button class="edit-btn" (click)="toggleSpecialization()" *ngIf="editable">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <div class="w-100 specialization-description">
        <p class="description mb-0" *ngIf="profileDetails?.employeeProfileTo?.specialization && !showSpecializationField">
          {{ profileDetails?.employeeProfileTo?.specialization }}
        </p>
        <a class="text-muted text-link text-center w-100"
          *ngIf="editable && !profileDetails?.employeeProfileTo?.specialization && !showSpecializationField"
          (click)="toggleSpecialization()" translate>
          Please add your specialization
        </a>

        <div class="specialization-text" *ngIf="showSpecializationField">

          <mat-form-field class="w-100" appearance="outline" >
            <mat-label translate>Specialization</mat-label>
            <textarea matInput required [placeholder]="'Specialization' | translate" formControlName="specialization"></textarea>
          </mat-form-field>
          <div fxLayout fxLayoutAlign="end center" fxLayoutGap="6px">

            <kl-link-button label="Cancel" buttonColor="warn" (clicked)="cancelAddingSpecialization()">
            </kl-link-button>
            <kl-link-button
              label="Save"
              buttonColor="accent"
              [progress]="progress"
              (clicked)="saveEmployeeDetails()"
              [disabled]="specializationField.invalid">
            </kl-link-button>

          </div>
        </div>
      </div>

      <!-- No content if there no specialization -->
      <ng-container *ngIf="!profileDetails?.employeeProfileTo?.specialization" >
        <div class="w-100" fxLayoutAlign="start center">
          <kl-no-content
            text="No Specialization added yet!">
          </kl-no-content>
        </div>
      </ng-container>
      <!--/ No content if there no specialization -->

    </section>
    <!--/ Section for Specialization -->

    <!-- Section for role and responsibility -->
    <section class="p-3 specific-sections position-relative roles-and-responsibility">
      <h4 class="titles" translate>Role & Responsibility</h4>
      <div class="position-absolute edit-section" *ngIf="profileDetails?.employeeProfileTo?.rolesAndResponsibilities">
        <button mat-icon-button class="edit-btn" (click)="toggleRoleAndResponsibilities()" *ngIf="editable">
          <mat-icon>edit</mat-icon>
        </button>
      </div>

      <div class="w-100 roles-and-responsibility-description">
        <p class="description mb-0" *ngIf="profileDetails?.employeeProfileTo?.rolesAndResponsibilities && !showRoleFiled">
          {{ profileDetails?.employeeProfileTo?.rolesAndResponsibilities }}
        </p>
        <a class="text-muted text-link text-center"
          *ngIf="editable && !profileDetails?.employeeProfileTo?.rolesAndResponsibilities && !showRoleFiled"
          (click)="toggleRoleAndResponsibilities()" translate>
          Please add your role and responsibilities
        </a>

        <div class="roles-and-responsibility-text" *ngIf="showRoleFiled">

          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>Role and responsibilities</mat-label>
            <textarea matInput required [placeholder]="'Enter your role' | translate" formControlName="rolesAndResponsibilities"></textarea>
          </mat-form-field>

          <div fxLayout fxLayoutAlign="end center" fxLayoutGap="6px">

            <kl-link-button label="Cancel" buttonColor="warn" (clicked)="cancelAddingRole()">
            </kl-link-button>
            <kl-link-button
              label="Save"
              buttonColor="accent"
              [progress]="progress"
              (clicked)="saveEmployeeDetails()"
              [disabled]="rolesAndResponsibilitiesField.invalid">
            </kl-link-button>

          </div>
        </div>

      </div>

      <!-- No content if there no role and responsibility -->
      <ng-container *ngIf="!profileDetails?.employeeProfileTo?.rolesAndResponsibilities" >
        <div class="w-100" fxLayoutAlign="start center">
          <kl-no-content
            text="No role and responsibility added yet!">
          </kl-no-content>
        </div>
      </ng-container>
      <!--/ No content if there no role and responsibility -->

    </section>
    <!--/ Section for role and responsibility -->

  </form>
  <!-- / Section for info details -->

  <!-- Section for certificates and achievements -->
  <section class="mt-3" fxLayout fxLayoutGap="16px" fxLayout.xs="column">

    <!-- Section for certificates -->
    <section class="specific-sections p-3" fxFlex="50%" fxFlex.xs="100%">
      <kl-employee-certificates
        [certificates]="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.lstOfBusinessCertificationDetails">
      </kl-employee-certificates>
    </section>
    <!-- / Section for certificates -->

    <!-- Section for achievements -->
    <section  class="specific-sections p-3" fxFlex="50%" fxFlex.xs="100%">
      <kl-employee-achievements [achievements]="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.lstOfBusinessAwardDetails"></kl-employee-achievements>
    </section>
    <!-- / Section for achievements -->

  </section>
  <!-- /Section for certificates and achievements -->

</main>
