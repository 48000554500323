import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkMobileDevice, scaleOutAnimation } from '@kalgudi/core';
import { ChartData } from '@kalgudi/types';

@Component({
  selector: 'kl-social-stats',
  templateUrl: './social-stats.component.html',
  styleUrls: ['./social-stats.component.scss'],
  animations: [scaleOutAnimation]
})
export class SocialStatsComponent implements OnInit {

  @Input()
  shareStats: ChartData[];

  @Input()
  qaStats: ChartData[];

  @Input()
  totalStatsCount: number;

  @Output()
  pageUpdates = new EventEmitter();

  @Output()
  pageQa = new EventEmitter();

  view: any[];

  shareView: any[];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showQaLabels: boolean = false;
  showShareLabels: boolean = true;
  isDoughnut: boolean = false;
  isShareDoughnut: boolean = true;
  legendPosition: string = 'below';

  qaColorScheme = {
    domain: ['#1b5e20', '#388e3c', '#81c784']
  };

  shareColorScheme = {
    domain: ['#fb8c00', '#42a5f5', '#ff5722', '#795548', '#ff9800', '#43a047', '#cddc39', '#0097a7', '#0288d1', '#283593']
  };

  constructor() { }

  ngOnInit() {
    this.view = checkMobileDevice() ? [150, 150] : [250, 250];
    this.shareView = checkMobileDevice() ? [350, 350] : [500, 300];
  }

  /**
   * Opens page updates
   */
  openPageUpdates() {
    this.pageUpdates.emit();
  }

  /**
   * Opens page qa
   */
  openPageQa() {
    this.pageQa.emit();
  }

}
