import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { kalgudiGoogleLanguageTranslatorConfig, TRANSLATION_CONFIG } from './config';
import { GoogleSupportedLanguagesList, GoogleTranslationResponse } from './types';

@Injectable()
export class KalgudiGoogleLanguageTranslationService {

  private readonly API_BASE = 'https://translation.googleapis.com/language/translate/v2';

  constructor(
    @Inject(TRANSLATION_CONFIG) private config: kalgudiGoogleLanguageTranslatorConfig,
    private httpClient: HttpClient,
  ) { }

  /**
   * Translates a text to another language
   */
  translate(
    textToTranslate: string,
    source: GoogleSupportedLanguagesList,
    target: GoogleSupportedLanguagesList,
    format: string = 'text',
  ): Observable<string> {

    // Google language api payload
    // @see https://cloud.google.com/translate/docs/basic/setup-basic
    const payload = {
      q: textToTranslate,
      source,
      target,
      format,
    };

    const params = {
      key: this.config.apiKey,
    };

    return this.httpClient.post<GoogleTranslationResponse>(this.API_BASE, payload, { params })
      .pipe(
        map(res => this.mapTranslationResponse(res))
      );
  }

  /**
   * Maps google translation response to string.
   */
  private mapTranslationResponse(res: GoogleTranslationResponse): string {

    if (res.data && Array.isArray(res.data.translations) && res.data.translations[0].translatedText) {
      return res.data.translations[0].translatedText;
    }

    throw res;
  }
}
