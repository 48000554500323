import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonSearchBarModule, KalgudiLoadMoreModule, KalgudiNoContentModule, KalgudiUsersModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';

import {
  KalgudiProgramsMemberPickerDialogComponent,
} from './components/kalgudi-programs-member-picker-dialog/kalgudi-programs-member-picker-dialog.component';
import {
  KalgudiProgramsMemberPickerComponent,
} from './components/kalgudi-programs-member-picker/kalgudi-programs-member-picker.component';
import {
  KalgudiProgramsSelectedMemberComponent,
} from './components/kalgudi-programs-selected-member/kalgudi-programs-selected-member.component';
import {
  MemberPickerMobileDialogComponent,
} from './components/member-picker-mobile-dialog/member-picker-mobile-dialog.component';
import { KalgudiPagesMembersPickerService } from './services/kalgudi-pages-members-picker.service';
import {MatRadioModule} from '@angular/material/radio';

// import {
//   KalgudiProgramsMemberPickerComponent,
//   KalgudiProgramsMemberPickerDialogComponent,
//   KalgudiProgramsSelectedMemberComponent,
// } from './components';


@NgModule({
  declarations: [
    KalgudiProgramsMemberPickerComponent,
    KalgudiProgramsMemberPickerDialogComponent,
    KalgudiProgramsSelectedMemberComponent,
    MemberPickerMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    // Material
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,

    FlexLayoutModule,
    MatRadioModule,

    // Kalgudi libraries
    KalgudiButtonModule,
    CommonSearchBarModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiUsersModule,

    TranslateModule
  ],
  exports: [
    KalgudiProgramsMemberPickerComponent,
    KalgudiProgramsSelectedMemberComponent,
    KalgudiProgramsMemberPickerDialogComponent,
  ],
  entryComponents: [
    KalgudiProgramsMemberPickerDialogComponent,
    MemberPickerMobileDialogComponent,
  ],
  providers: [
    KalgudiPagesMembersPickerService,
  ]
})
export class KalgudiProgramMembersPickerDialogModule { }
