import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { KalgudiOrgProfileAboutUs } from '../../classes/kalgudi-org-profile-about-us';

@Component({
  selector: 'kl-about-us-tile',
  templateUrl: './about-us-tile.component.html',
  styleUrls: ['./about-us-tile.component.scss']
})
export class AboutUsTileComponent extends KalgudiOrgProfileAboutUs implements OnInit {

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,

    ) {
    super(injector,fb);
  }

  ngOnInit() {
    this.profileForm.get('basicDetails').patchValue(this.profileDetails);
  }

  onDestroyed(): void {}
}
