<div class="p-2 profile-home">

  <!-- Header profile section -->
  <div class="profile-header mt-2 pt-2 w-100">
    <kl-cooperative-society-profile-header
      [selectedTabId]="selectedActivity"
      (activatedTab)="activatedTabDetails($event)"
      [showProfileLogs]="profileLogs"
      [userProfileKey]="assistedProfileKey"
      [profileShareUrl]="profileShareUrl">
    </kl-cooperative-society-profile-header>
  </div>
  <!--/ Header profile section -->

  <div *ngIf="selectedActivity === 'ABOUT'">
    <!-- About Us tile -->
    <div class="mt-2">
      <kl-about-us-tile></kl-about-us-tile>
    </div>
    <!--/ About Us tile -->

    <!-- Basic info details tile -->
    <div *ngIf="false" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
          <kl-basic-info-tile class="h-100">
          </kl-basic-info-tile>
        </div>
      </ng-container>
    </div>
    <!--/ Basic info details tile -->

    <!-- Banking & services tiles -->
    <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
      <!-- Banking details tile -->
      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
          <kl-fpo-banking-details-tile class="h-100"></kl-fpo-banking-details-tile>
        </div>
      </ng-container>
      <!--/ Banking details tile -->

      <!-- Services -->
      <ng-container *ngIf="!isPublicPage">
        <div [fxFlex.gt-xs]="editable ? 'calc(50% - 8px)' : 'calc(100% - 8px)'">
          <kl-services-tile></kl-services-tile>
        </div>
      </ng-container>
      <!--/ Services -->
    </div>
    <!--/ Banking & services tiles -->

    <!-- Grading details tile -->
    <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2" *ngIf="showProfileGrading">
      <div fxFlex.gt-xs="calc(50% - 8px)">
        <kl-fpo-grading-tile
          [hasDetailPage]="hasProfileGradingDetail"
          class="h-100">
        </kl-fpo-grading-tile>
      </div>
      <div fxFlex.gt-xs="calc(50% - 8px)">
        <kl-services-tile></kl-services-tile>
      </div>
    </div>
    <!--/ Grading details tile -->

    <!-- Cooperative & warehouse tiles -->
    <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
      <div fxFlex.gt-xs="calc(50% - 8px)">
        <kl-cooperative-society-crops
          [pageId]="pageId"
          [isAssisted]="isAssisted"
          [assistedProfileKey]="assistedProfileKey"
          (closeCropDialog)="openFarmPage()"
          [showViewCatalogue]="showViewCatalogue">
        </kl-cooperative-society-crops>
      </div>

      <!-- Warehouse/godown -->
      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)">
          <kl-warehouse-godown-tile class="h-100"></kl-warehouse-godown-tile>
        </div>
      </ng-container>
      <!--/ Warehouse/godown -->
    </div>
    <!--/ Cooperative & warehouse tiles -->

    <!-- AI-predictions -->
    <div class="ai-predictions-wrapper" *ngIf="showAipredictions">
      <kl-ai-predictions></kl-ai-predictions>
    </div>
    <!-- AI-predictions -->

    <!-- Board members & certificates tile -->
    <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
      <div fxFlex.gt-xs="calc(50% - 8px)">
        <kl-board-members class="h-100"> </kl-board-members>
      </div>

      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)">
          <kl-cooperative-society-certificates
            [certificates]="profileDetails?.lstOfUserBusinessDetailsInfo[0] ?.lstOfBusinessCertificationDetails">
          </kl-cooperative-society-certificates>
        </div>
      </ng-container>
    </div>
    <!--/ Board members & certificates tile -->

    <!-- Infrastructure & awards tile -->
    <div fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
      <!-- Fpo infrastructure tile -->
      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)">
          <kl-fpo-infrastructure-tile class="h-100"></kl-fpo-infrastructure-tile>
        </div>
      </ng-container>
      <!--/ Fpo infrastructure tile -->

      <ng-container *ngIf="!isPublicPage">
        <div fxFlex.gt-xs="calc(50% - 8px)">
          <kl-cooperative-society-awards
            [awards]=" profileDetails?.lstOfUserBusinessDetailsInfo[0] ?.lstOfBusinessAwardDetails">
          </kl-cooperative-society-awards>
        </div>
      </ng-container>
    </div>
    <!--/ Infrastructure & awards tile -->

    <!-- Live stock tile -->
    <div *ngIf="env?.appId !== 'SAM_FPO'" class="mt-2 cooperative-society">
      <kl-cooperative-society-live-stock></kl-cooperative-society-live-stock>
    </div>
    <!--/ Live stock tile -->
  </div>

  <!-- Profile social activities -->
  <div class="updates-tab" *ngIf="selectedActivity === 'UPDATES'">
    <kl-profile-social-activities
      [profileKey]="profileDetails?.profileKey">
    </kl-profile-social-activities>
  </div>
  <!--/ Profile social activities -->

  <!-- Tasks tab -->
  <div class="task-tab" *ngIf="selectedActivity === 'TASKS'">
    <kl-user-tasks
      [isAssisted]="isAssisted"
      [profileKey]="profileDetails?.profileKey"
      [isAdminTasks]="isAdminTasks">
    </kl-user-tasks>
  </div>
  <!--/ Tasks tab -->

  <!-- Profile logs view -->
  <div *ngIf="selectedActivity === 'PROFILE_LOGS'">
    <kl-profile-logs-view
      [pageId]="pageId"
      [isAssisted]="isAssisted"
      [assistedProfileKey]="assistedProfileKey">
    </kl-profile-logs-view>
  </div>
  <!--/ Profile logs view -->

  <!-- Social & crop details -->
  <div *ngIf="false" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
    <ng-container *ngIf="!isPublicPage">
      <!-- Social details tile -->
      <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
        <kl-social-tile class="h-100">
        </kl-social-tile>
      </div>
      <!--/ Social details tile -->

      <!-- Crop details tile -->
      <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
        <kl-crop-tile class="h-100">
        </kl-crop-tile>
      </div>
      <!--/ Crop details tile -->
    </ng-container>
  </div>
  <!--/ Social & crop details -->

  <!-- Finance details -->
  <div *ngIf="false" fxLayout.gt-xs="row" fxLayoutGap.gt-xs="8px" fxLayout.xs="column" fxLayoutGap.xs="8px" class="mt-2">
    <ng-container *ngIf="!isPublicPage">
      <!-- Finance details tile -->
      <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
        <kl-finance-details-tile class="h-100">
        </kl-finance-details-tile>
      </div>
      <!--/ Finance details tile -->

      <!-- Business activities -->
      <div fxFlex.gt-xs="calc(50% - 8px)" *ngIf="editable">
        <kl-business-activities-tile>
        </kl-business-activities-tile>
      </div>
      <!--/ Business activities -->
    </ng-container>
  </div>
  <!--/ Finance details -->
</div>
