import {
  AfterViewInit,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Injector,
  Input,
  OnChanges,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';

import { GoogleTranslateComponent } from './google-translate/google-translate.component';
import { GoogleSupportedLanguagesList, LanguageIdList } from './types';

@Directive({
  selector: '[klGoogleTranslate]'
})
export class GoogleTranslateDirective implements AfterViewInit, OnChanges {

  @Input()
  translationSource: GoogleSupportedLanguagesList;

  @Input()
  translationTargetList: LanguageIdList;

  @Input()
  translationText: string;

  @Input()
  format: string;

  private compRef: ComponentRef<GoogleTranslateComponent>;

  constructor(
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
  ) {
  }

  /**
   * Called before any other lifecycle hook. Use it to inject dependencies,
   * but avoid any serious work here.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.updateComponentFields();
  }

  /**
   * Called after ngAfterContentInit when the component's view has been initialized.
   * Applies to components only.
   */
  ngAfterViewInit(): void {
    this.injectGoogleTranslateComponent();
  }

  /**
   * Injects google translate component to the view
   */
  private injectGoogleTranslateComponent(): void {

    const componentFactory = this.cfr.resolveComponentFactory(GoogleTranslateComponent);

    this.compRef = this.viewContainerRef.createComponent(componentFactory, 0, this.injector);

    this.updateComponentFields();
  }

  /**
   * Update the GoogleTranslate component member field values
   */
  private updateComponentFields(): void {

    // No component instance reference found
    if (!this.compRef) {
      return;
    }

    this.compRef.instance.text   = this.translationText;
    this.compRef.instance.format = this.format;
    this.compRef.instance.source = this.translationSource;
    this.compRef.instance.targetLanguageList = this.translationTargetList;
    this.compRef.instance.reset();
  }

}
