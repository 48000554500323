import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiSurveyAddBulkMembers } from '../../classes/kalgudi-survey-add-bulk-members';

@Component({
  selector: 'kl-survey-members-add-bulk',
  templateUrl: './survey-members-add-bulk.component.html',
  styleUrls: ['./survey-members-add-bulk.component.scss']
})
export class SurveyMembersAddBulkComponent extends KalgudiSurveyAddBulkMembers implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
