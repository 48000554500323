import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DEFAULT_DIALOG_CONFIG } from '../../kalgudi-dialogs/constants';
import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../../mobile-dialog/models';
import {
  UploadAttachmentsDialogComponent,
} from '../components/upload-attachments-dialog/upload-attachments-dialog.component';
import {
  UploadAttachmentsMobileDialogComponent,
} from '../components/upload-attachments-mobile-dialog/upload-attachments-mobile-dialog.component';


@Injectable()
export class KalgudiAttachmentsPickerService {

  constructor(
    private dialogsService: KalgudiDialogsService,
  ) { }

  /**
   * Shows attachments picker dialog
   *
   * @param config Dialog
   */
  showAttachmentPicker(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileAttachmentsPickerDialog(details)
      : this.openAttachmentsPickerDialog(details, config);
  }


  /**
   * Opens attachments picker dialog
   */
  openAttachmentsPickerDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.dialogsService.openDialog(UploadAttachmentsDialogComponent, details, dialogConfig);
  }

  /**
   * Opens attachments picker Mobile dialog
   */
  openMobileAttachmentsPickerDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.dialogsService.openMobileDialog(UploadAttachmentsMobileDialogComponent, details );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
