import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kl-formly-null-type',
  template: ``,
  styles: [],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyNullTypeComponent extends FieldType { }
