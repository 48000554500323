import { Component, Input, OnInit } from '@angular/core';
import { SurveyBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-basic-details',
  templateUrl: './survey-basic-details.component.html',
  styleUrls: ['./survey-basic-details.component.scss']
})
export class SurveyBasicDetailsComponent implements OnInit {

  @Input()
  surveyBasicDetails: SurveyBasicDetails;

  constructor() { }

  ngOnInit() {
  }

}
