import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { checkMobileDevice, scaleOutAnimation } from '@kalgudi/core';
import { ChartData } from '@kalgudi/types';


@Component({
  selector: 'kl-project-stats',
  templateUrl: './project-stats.component.html',
  styleUrls: ['./project-stats.component.scss'],
  animations: [scaleOutAnimation]
})
export class ProjectStatsComponent implements OnInit {

  @Input()
  projectStats: ChartData[];

  @Input()
  taskStats: ChartData[];

  @Input()
  projectsTotalCount: number;

  @Input()
  tasksTotalCount: number;

  @Output()
  pageTasks = new EventEmitter();

  @Output()
  pageProjects = new EventEmitter();

  view: any[];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  taskColorScheme = {
    domain: ['#f48fb1', '#ec407a', '#f50057']
  };

  projectColorScheme = {
    domain: ['#7986cb', '#3949ab', '#1a237e']
  };

  constructor() { }

  ngOnInit() {
    this.view = checkMobileDevice() ? [350, 350] : [500, 300];
  }

  /**
   * Opens page projects
   */
  openPageProjects() {
    this.pageProjects.emit();
  }

  /**
   * Opens page tasks
   */
  openPageTasks() {
    this.pageTasks.emit();
  }

}
