<main fxLayout="column" class="satellite-advisory-dialog">
  <!-- <div>
    <kl-satellite-advisories-water-indicator-tile
      [waterLevel]="landDetails?.waterLevel"
      [landDetails]="landDetails">
    </kl-satellite-advisories-water-indicator-tile>
  </div> -->

  <mat-card class="p-0" fxLayout="column" fxLayoutGap="5px">

    <main class="px-3 py-2 border-bottom home-stream-header">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div>
            <!-- <mat-icon class="icon-success mt-1">
              add
            </mat-icon> -->
            <svg-icon [applyCss]="true"
              class="text-center attach-link"
              src="assets/svgs/water-level.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
            </svg-icon>
          </div>
          <div class="header-title">
            <h5 class="mb-0"><b>Satellite Advisories</b></h5>
          </div>
        </div>
      </div>
    </main>

    <div class="mt-2">
      <kl-satellite-advisoires-location [showMap]="true" [landDetails]="landDetails"></kl-satellite-advisoires-location>
    </div>

    <div *ngIf="soilMoisture != null" class="mt-n3">
      <kl-soil-moisture-details
          [soilMoistureDetails]="soilMoisture"
          [landDetails]="landDetails"
          [soilMoistureImage]="soilMoisture?.s3Url"
          [bbox]="satelliteRes?.bbox"
          [estimatedTime]="soilMoisture?.updateOn">
        </kl-soil-moisture-details>
    </div>

    <div *ngIf="nitrogen != null">
      <div>
        <kl-nitrogen-details
          [nitrogenDetails]="nitrogen"
          [nitrogenImage]="nitrogen?.s3Url"
          [landDetails]="landDetails"
          [bbox]="satelliteRes?.bbox"
          [estimatedTime]="nitrogen?.updateOn">
        </kl-nitrogen-details>
      </div>
    </div>

    <div *ngIf="phosphorus != null" class="pb-3">
      <kl-phosphorus-details
          [phosphorusDetails]="phosphorus"
          [phosphorusImage]="phosphorus?.s3Url"
          [landDetails]="landDetails"
          [bbox]="satelliteRes?.bbox"
          [estimatedTime]="phosphorus?.updateOn">
        </kl-phosphorus-details>
    </div>

    <!-- Crop health data -->
    <div *ngIf="plantHealth != null" class="pb-3">
      <kl-crop-health-data
        [plantHealthDetails]="plantHealth"
        [plantHealthImage]="plantHealth?.s3Url"
        [landDetails]="landDetails"
        [bbox]="satelliteRes?.bbox"
        [estimatedTime]="plantHealth?.updateOn">
      </kl-crop-health-data>
    </div>
    <!--/ Crop health data -->

    <div *ngIf="nitrogen === null && soilMoisture === null && !phosphorus">
      <kl-no-content
        svgIcon="assets/svgs/satellite.svg"
        [text]="'No advisories available for user!' | translate"
        iconSize="md">
      </kl-no-content>
    </div>

  </mat-card>

</main>
