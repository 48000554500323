import { Injectable } from "@angular/core";
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService } from "@kalgudi/common";
import { KalgudiStreamData, checkMobileDevice } from "@kalgudi/core";
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiSms,
  KalgudiSpecificSubscribers,
  LogsResponseData,
  ProgramShareUpdate,
  RobocallStatusDownloadResponse
} from "@kalgudi/types";
import { Observable, combineLatest } from "rxjs";

import { MatDialogConfig } from "@angular/material/dialog";
import { KalgudiStreamS3FetchService } from "@kalgudi/social";
import { map, mergeMap } from "rxjs/operators";
import { MobilePublishRobocallComponent } from "../components/mobile-publish-robocall/mobile-publish-robocall.component";
import { RobocallFullViewDialogComponent } from "../components/robocall-full-view-dialog/robocall-full-view-dialog.component";
import { RobocallListApiService } from "./robocall-logs-list-api.service";
import { RobocallFullViewMobileDialogComponent } from "../components/robocall-full-view-mobile-dialog/robocall-full-view-mobile-dialog.component";

@Injectable()
export class RobocallStreamService {
  constructor(
    private api: RobocallListApiService,
    private dialogService: KalgudiDialogsService,
    private s3Fetch: KalgudiStreamS3FetchService
  ) {}

  /**
   * Fetches logs list from the API
   */
  fetchLogsList(
    smsId: string,
    pageId: string,
    offset: number,
    limit: number,
    filterType: string
  ): Observable<LogsResponseData> {
    return this.api.fetchLogsList(smsId, pageId, offset, limit, filterType);
  }

  /**
   * Calls api method to download logs list from the API
   */
  downloadRobocallStatus(shareId: string): Observable<RobocallStatusDownloadResponse> {
    return this.api.downloadRobocallStatus(shareId);
  }

  /**
   * publish SMS
   */
  publishNewSms(pageId: string, payload): Observable<KalgudiSms> {
    return this.api.publishNewSms(pageId, payload);
  }

  /**
   * UPDATE SCHEDULED SMS
   */
  updateScheduledSms(
    scheduleId: string,
    payload: KalgudiSpecificSubscribers
  ): Observable<KalgudiSms> {
    return this.api.updateScheduledSms(scheduleId, payload);
  }

  /**
   * Get Robocall stream
   */
  getRobocallStream(
    pageId: string,
    offset: number,
    limit: number,
    extraParams
  ): Observable<KalgudiStreamData> {
    return this.api.getRobocallStream(pageId, offset, limit, extraParams).pipe(
      // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
      // method.
      mergeMap((r) => {
        // Combine response from all s3 items
        return combineLatest(
          r.items.map((s) =>
            this.s3Fetch.fetchStreamItem<ProgramShareUpdate>(
              s as any,
              s.url,
              s.event
            )
          )
        );
      }),

      // Map the complete stream response
      map((items) => {
        return { items };
      })
    );
  }

  /**
   * Opens initiate Robocall form for mobile view
   */
  openMobileRobocallForm(): Observable<KalgudiDialogResult> {
    return this.dialogService.openMobileDialog(MobilePublishRobocallComponent, {
      data: {},
      title: "Initiate Robocall",
    });
  }

  /**
   * @param config Dialog
   */
  openRobocallFullView(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openRobocallFullViewMobile(details)
      : this.openRobocallFullViewWeb(details, config);
  }

  /**
   * Opens robocall details page on web dialog
   */
  openRobocallFullViewWeb(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.dialogService.openDialog(RobocallFullViewDialogComponent, details, config);
  }

  /**
   * Opens robocall details page on mobile dialog
   */
  openRobocallFullViewMobile(details: KalgudiDialogConfig): Observable<KalgudiDialogResult> {
    return this.dialogService.openMobileDialog(RobocallFullViewMobileDialogComponent, details);
  }
}
