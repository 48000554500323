<div class="description-of-product">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div>
      <img src="assets/icons/about-icon.png" width="45">
    </div>

    <div>
      <!-- <p class="m-0 title">About</p> -->
      <p class="m-0 title">About</p>
      <!-- <p class="m-0 title">{{level3ProductDetails?.productLevel3Title | titlecase}}</p> -->
      <!-- <p class="m-0 author">
        <span class="m-0 author" *ngIf="level2ProductDetails?.sellerType === 'RESELLER'">{{level2ProductDetails?.resellerDetails?.firstName}}</span>
        <span class="m-0 author" *ngIf="level2ProductDetails?.sellerType === 'MANUFACTURER'">{{level2ProductDetails?.manufacturerDetails?.firstName}}</span>
      </p> -->
    </div>
  </div>

  <div class="description" *ngIf="description">
    <div>{{ description | titlecase }}</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="content" *ngIf="!description">
    <p class="no-content mb-0">No about us available</p>
  </div>
</div>
