<mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedIndex">
  <mat-tab [label]="'Frequently assisted members' | translate">
    <kl-frequent-assisted-members [pageId]="pageId"></kl-frequent-assisted-members>
  </mat-tab>

  <mat-tab [label]="'Add frequently assisted members' | translate">
    <kl-page-members-search *ngIf="selectedIndex === 1"
      [pageId]="pageId"
      [multiSelect]="true"
      [(selectedUsersMap)]="selectedUsersMap"
      (selectedUsersMapChange)="onSelectedUsersMapChange($event)">
    </kl-page-members-search>
  </mat-tab>
</mat-tab-group>
