import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageType, ProgramPageDetails } from '@kalgudi/types';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateProgram extends KalgudiCreatePageType<ProgramPageDetails> {

  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the Activities from Page type form
   */
  get activitiesFiled(): AbstractControl {
    return this.pageTypeForm.get('activities');
  }

  /**
   * Gets, the Start Duration from Page type form
   */
  get startDurationFiled(): AbstractControl {
    return this.pageTypeForm.get('startDuration');
  }

  /**
   * Gets, the End Duration from Page type form
   */
  get endDurationFiled(): AbstractControl {
    return this.pageTypeForm.get('endDuration');
  }

  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      vision: [''],
      programType: [''],
      activities: ['', Validators.required],
      startDuration: ['', Validators.required],
      endDuration: ['', Validators.required],
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'PROGRAM';
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
