<div class="add-farmer-equipment-form">
  <form autocomplete="off" [formGroup]="farmerEquipmentForm" fxLayout="column">

    <!-- Equipment name -->
    <mat-form-field appearance="outline">
      <mat-label>Equipment name</mat-label>
      <input matInput placeholder="Enter equipment name" formControlName="equipmentName" required>
    </mat-form-field>
    <!-- /Equipment name -->

    <!-- Equipment capacity -->
    <mat-form-field appearance="outline">
      <mat-label>Equipment capacity</mat-label>
      <input matInput placeholder="Enter equipment capacity" formControlName="equipmentCapacity" (keydown.space)="$event.preventDefault()" required>
    </mat-form-field>
    <!-- /Equipment capacity -->

    <div fxLayoutAlign="end end">
      <kl-flat-button
        type="submit"
        label= "{{ farmerEquipmentDetails ? ' Update equipment ' : 'Add equipment' }}"
        (clicked)="saveOrUpdateEquipment()"
        [disabled]="farmerEquipmentForm?.invalid">
      </kl-flat-button>
    </div>
  </form>
</div>
