import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, ProgramStreamResponse, StreamUrls } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PageStreamApiService {

  /**
   * `v1/stream/programStream`
   */
  private readonly API_STREAM = `${this.env.restBaseUrl}/stream/programStream`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Fetches program share update stream from API.
   *
   * @param entityId Program/Page id for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchStream(entityId: string, offset: number, limit: number): Observable<StreamUrls[]> {

    const params = {
      entityId,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_STREAM, { params })
      .pipe(

        map(res => this.util.apiErrorHandler(res)),
        map(r => {

          // Map api response to object type
          const res = this.util.toJson<ProgramStreamResponse>(r.data);

          // Map the response to the s3 urls array
          return res.entitiesStream;
        }),
      );
  }

}
