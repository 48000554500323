<div class="mb-1"
  [ngClass]="{'stats-sm':statsDirection === 'horizontal', 'stats':statsDirection === 'vertical'}">
  <p class="mb-0 count">{{ stat?.value }}</p>
  <p class="mb-0 label">{{ stat?.title }}</p>

  <section class="progress-section" *ngIf="showProgress">
    <mat-progress-bar
        class="progress rounded"
        color="primary"
        [value]="(progressValue / maxProgressValue) * 100">
    </mat-progress-bar>
  </section>

</div>


