import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiUpdateWarehouseGodown } from '../../../classes/kalgudi-update-warehouse-godown';

@Component({
  selector: 'kl-warehouse-godown-form',
  templateUrl: './warehouse-godown-form.component.html',
  styleUrls: ['./warehouse-godown-form.component.scss']
})
export class WarehouseGodownFormComponent extends KalgudiUpdateWarehouseGodown implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector)
  }

  ngOnInit() {
    if(this.profileDetails.fpoAdditionalDetails  &&  this.profileDetails.fpoAdditionalDetails.fpoWarehouse) {
      this.warehouseGodownForm.patchValue(this.profileDetails.fpoAdditionalDetails.fpoWarehouse);
    }
  }

  onDestroyed() : void {}

}
