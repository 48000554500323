import { Injectable } from '@angular/core';
import { ProjectStatistics } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiProjectsDashboardApiService } from './kalgudi-projects-dashboard-api.service';

@Injectable()
export class KalgudiProjectsDashboardService {

  constructor(private api: KalgudiProjectsDashboardApiService) { }

  /**
   * Make a api call to get all projects stats
   */
  getAllProjectsStats(): Observable<ProjectStatistics> {

    return this.api.getAllProjectsStats();
  }
}
