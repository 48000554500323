import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiCommonModule, KalgudiImagePickerModule, KalgudiStoreProductsSearchModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiPageTargetMembersModule } from '@kalgudi/pages-shared';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import { ActivityTagsComponent } from './components/activity-tags/activity-tags.component';
import { ActivityVisibilityComponent } from './components/activity-visibility/activity-visibility.component';
import { PageActivityFiltersComponent } from './components/page-activity-filters/page-activity-filters.component';
import {
  SpecificMemberVisibilityComponent,
} from './components/specific-member-visibility/specific-member-visibility.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ActivityTagsComponent,
    ActivityVisibilityComponent,
    SpecificMemberVisibilityComponent,
    PageActivityFiltersComponent,
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    // Material
    MatSelectModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiPipesModule,
    KalgudiProgramTargetMembersModule,
    KalgudiButtonModule,
    KalgudiStoreProductsSearchModule,
    KalgudiPageTargetMembersModule,
    KalgudiCommonModule,
    KalgudiImagePickerModule,

    TranslateModule
  ],
  exports: [
    ActivityTagsComponent,
    ActivityVisibilityComponent,
    SpecificMemberVisibilityComponent,
    PageActivityFiltersComponent,
  ]
})
export class PageActivityFiltersModule { }
