import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiCommonDirectivesModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  KalgudiUserProfileHeaderComponent,
} from './components/kalgudi-user-profile-header/kalgudi-user-profile-header.component';
import { KalgudiUserProfileComponent } from './components/kalgudi-user-profile/kalgudi-user-profile.component';
import { AiPredictionsComponent } from './components/sections/ai-predictions/ai-predictions.component';
import { AwardsComponent } from './components/sections/awards/awards.component';
import { KalgudiProfileAwardsApiService } from './components/sections/awards/kalgudi-profile-awards-api.service';
import { KalgudiProfileAwardsService } from './components/sections/awards/kalgudi-profile-awards.service';
import { CertificationsComponent } from './components/sections/certifications/certifications.component';
import {
  KalgudiProfileCertificatesApiService,
} from './components/sections/certifications/kalgudi-profile-certificates-api.service';
import {
  KalgudiProfileCertificatesService,
} from './components/sections/certifications/kalgudi-profile-certificates.service';
import { DealsEditComponent } from './components/sections/deals-edit/deals-edit.component';
import { DealsListComponent } from './components/sections/deals-list/deals-list.component';
import { DealsTileComponent } from './components/sections/deals-tile/deals-tile.component';
import { DealsComponent } from './components/sections/deals/deals.component';
import { EditActionBarComponent } from './components/sections/edit-action-bar/edit-action-bar.component';
import {
  KalgudiProfileMembershipsApiService,
} from './components/sections/memberships/kalgudi-profile-memberships-api.service';
import { KalgudiProfileMembershipsService } from './components/sections/memberships/kalgudi-profile-memberships.service';
import { MembershipsComponent } from './components/sections/memberships/memberships.component';
import { ProfileAboutOrgEditComponent } from './components/sections/profile-about-org-edit/profile-about-org-edit.component';
import { ProfileAboutOrgTileComponent } from './components/sections/profile-about-org-tile/profile-about-org-tile.component';
import { ProfileAboutOrgViewComponent } from './components/sections/profile-about-org-view/profile-about-org-view.component';
import {
  ProfileAboutUserEditComponent,
} from './components/sections/profile-about-user-edit/profile-about-user-edit.component';
import {
  ProfileAboutUserTileComponent,
} from './components/sections/profile-about-user-tile/profile-about-user-tile.component';
import {
  ProfileAboutUserViewComponent,
} from './components/sections/profile-about-user-view/profile-about-user-view.component';
import { ProfileAboutComponent } from './components/sections/profile-about/profile-about.component';
import { ProfileLocationEditComponent } from './components/sections/profile-location-edit/profile-location-edit.component';
import { ProfileLocationComponent } from './components/sections/profile-location/profile-location.component';
import { SellerDetailsEditComponent } from './components/sections/seller-details-edit/seller-details-edit.component';
import { SellerDetailsViewComponent } from './components/sections/seller-details-view/seller-details-view.component';
import { SellerDetailsComponent } from './components/sections/seller-details/seller-details.component';
import { KalgudiProfileTaxesApiService } from './components/sections/taxes/kalgudi-profile-taxes-api.service';
import { KalgudiProfileTaxesService } from './components/sections/taxes/kalgudi-profile-taxes.service';
import { TaxesComponent } from './components/sections/taxes/taxes.component';
import { ValueTypeEditComponent } from './components/sections/value-type-edit/value-type-edit.component';
import { ValueTypeFormComponent } from './components/sections/value-type-form/value-type-form.component';
import { ValueTypeListComponent } from './components/sections/value-type-list/value-type-list.component';
import { ProfileBasicEditModule } from './modules/profile-basic-edit/profile-basic-edit.module';
import { KalgudiProfileActionService } from './services/kalgudi-profile-action.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    KalgudiUserProfileComponent,
    KalgudiUserProfileHeaderComponent,
    ProfileAboutComponent,
    ProfileLocationComponent,
    ProfileLocationEditComponent,
    ProfileAboutOrgEditComponent,
    AwardsComponent,
    CertificationsComponent,
    MembershipsComponent,
    TaxesComponent,
    ProfileAboutOrgViewComponent,
    ValueTypeListComponent,
    ValueTypeFormComponent,
    ValueTypeEditComponent,
    EditActionBarComponent,
    ProfileAboutOrgTileComponent,
    ProfileAboutUserEditComponent,
    ProfileAboutUserViewComponent,
    ProfileAboutUserTileComponent,
    DealsComponent,
    DealsTileComponent,
    DealsEditComponent,
    DealsListComponent,
    SellerDetailsComponent,
    SellerDetailsEditComponent,
    SellerDetailsViewComponent,
    AiPredictionsComponent,
  ],
  imports: [
    CommonModule,

    // Flex
    FlexLayoutModule,

    // Material modules
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,

    AngularSvgIconModule,

    // Form Module
    ReactiveFormsModule,

    // Button Module
    KalgudiButtonModule,

    KalgudiCoreModule,
    KalgudiPipesModule,
    ProfileBasicEditModule,
    KalgudiCommonDirectivesModule,
    TranslateModule,
    KalgudiNoContentModule
  ],
  exports: [
    KalgudiUserProfileComponent,
    KalgudiUserProfileHeaderComponent,
    ProfileAboutComponent,
    ProfileLocationComponent,
    ProfileLocationEditComponent,
    ProfileAboutOrgEditComponent,
    AwardsComponent,
    CertificationsComponent,
    MembershipsComponent,
    TaxesComponent,
    ProfileAboutOrgViewComponent,
    DealsTileComponent,
    DealsComponent,
    SellerDetailsComponent,
    AiPredictionsComponent,
  ],
  providers: [
    // KalgudiProfileService,
    // KalgudiProfileApiService,

    KalgudiProfileAwardsService,
    KalgudiProfileAwardsApiService,

    KalgudiProfileCertificatesService,
    KalgudiProfileCertificatesApiService,

    KalgudiProfileMembershipsService,
    KalgudiProfileMembershipsApiService,

    KalgudiProfileTaxesService,
    KalgudiProfileTaxesApiService,

    // KalgudiProfileStateService,
    KalgudiProfileActionService,

    // KalgudiDaInterceptorService,

    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: KalgudiDaInterceptorService,
    //   multi: true,
    // },
  ]
})
export class KalgudiProfilesModule {
  public static forRoot(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiProfilesModule> {
    return {
      ngModule: KalgudiProfilesModule,
      providers: [
        {
          provide: KL_ENV,
          useClass: config && config.environment
        },
        {
          provide: KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide: KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
