import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, switchMapTo, takeUntil, tap } from 'rxjs/operators';

import { KalgudiSurveysRouteConfig } from '../config/survey-route-config';
import { SurveyPageActions } from '../constants';
import { KalgudiSurveyService } from '../services/kalgudi-survey.service';
import { KalgudiSurveysHelperService } from '../services/kalgudi-surveys-helper.service';
import { SurveyStateService } from '../services/survey-state.service';

@Directive()
export abstract class KalgudiSurvey extends KalgudiDestroyable {

  surveyDetails: KalgudiSurveyDetails;


  // Dependencies
  protected util: KalgudiUtilityService;
  protected surveyService: KalgudiSurveyService;
  protected surveysHelper: KalgudiSurveysHelperService;
  protected surveyState: SurveyStateService;
  protected uploadService: KalgudiUploadService;
  protected kalgudiDialogs: KalgudiDialogsService;
  private surveyRouting: KalgudiSurveysRouteConfig;
  private notifications: KalgudiNotification;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Manually inject all dependencies
    this.util           = this.injector.get(KalgudiUtilityService);
    this.surveyService  = this.injector.get(KalgudiSurveyService);
    this.surveysHelper  = this.injector.get(KalgudiSurveysHelperService);
    this.surveyState    = this.injector.get(SurveyStateService);
    this.uploadService  = this.injector.get(KalgudiUploadService);
    this.kalgudiDialogs = this.injector.get(KalgudiDialogsService);
    this.surveyRouting  = this.injector.get<KalgudiSurveysRouteConfig>(KL_ROUTE_CONFIG);
    this.notifications  = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Updates
   *
   * @param fileEvent
   */
  updateSurveyLogo(fileEvent: any): void {

    const path = KALGUDI_S3_POLICY_MAP.BUSINESS;

    /**
     * Cloning the survey details so that it should not update the original survey details until the service is successful
     */
    const survey: KalgudiSurveyDetails = this.util.clone(this.surveyDetails);

    this.uploadService.uploadFile(fileEvent.target.files[0], path)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        map(res => survey.surveyBasicDetails.logo = res.filePath),

        switchMap(res => this.surveyService.updateSurveyDetails(survey.surveyId, survey.surveyBasicDetails))
      )
      .subscribe(
        res => this.onLogoUpdate(res),
        err => this.util.showApiErrorMessage(err)
      );
  }

  /**
   * Publishes the survey
   */
  publishSurvey(): void {

    try {

      // Assert survey publish conditions
      this.surveysHelper.canPublishSurvey(this.surveyDetails);

      // Ask for publish confirmation
      this.showPublishConfirmDialog()
        .pipe(
          switchMap(_ => this.surveyService.publishSurvey(this.surveyDetails.surveyId)),
        )
        .subscribe(
          res => {
            // console.log(res);
          },
          err => this.util.showApiErrorMessage(err));

    } catch (e) {

      console.error('Cant publish survey. ', e);

      this.showInfoDialog('Error', e.message, 'warning', 'OK', 'Cancel')
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    }
  }

  /**
   * Publishes the survey
   */
  unPublishSurvey(): void {

    try {

      // Assert survey publish conditions
      this.surveysHelper.canUnPublishSurvey(this.surveyDetails);

      // Ask for publish confirmation
      this.showUnPublishConfirmDialog()
        .pipe(
          switchMap(_ => this.surveyService.unPublishSurvey(this.surveyDetails.surveyId)),
        )
        .subscribe(
          res => { },
          err => this.util.showApiErrorMessage(err));

    } catch (e) {

      console.error('Cant publish survey. ', e);

      this.showInfoDialog('Error', e.message, 'warning', 'OK', 'Cancel')
        .pipe(takeUntil(this.destroyed$))
        .subscribe();
    }
  }

  /**
   * Deletes the survey
   */
  deleteSurvey(): void {

    this.showDeleteConfirmationDialog()
      .pipe(

        takeUntil(this.destroyed$),

        // Turn on spinner
        tap(accepted => this.notifications.showSpinner(true)),

        // Call the delete survey service
        switchMapTo(this.surveyService.deleteSurvey(this.surveyDetails.surveyId)),

        // On completion turn off the spinner
        finalize(() => this.notifications.hideSpinner())

      )
      .subscribe(
        success => this.onSurveyDeleted(success),
        err => this.onSurveyDeleteError(err)
      );

  }

  /**
   * Send reminder
   */
  sendReminder() {
    this.showSendReminderConfirmDialog()
      .pipe(
        switchMap(_ => this.surveyService.sendReminder(this.surveyDetails.surveyId)),
      )
      .subscribe(
        success => this.onReminderSent(success),
        err => this.util.showApiErrorMessage(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Calls after reminder sent successfully
   */
  protected onReminderSent(res: KalgudiSurveyDetails): void {
    this.notifications.showMessage('Reminder sent, members will receive email/sms');
  }


  /**
   * Calls after deleting survey details successfully
   */
  protected onSurveyDeleted(res: boolean): void {
    this.surveyRouting.toSurveyList();
  }

  /**
   * Event handler for survey delete API errors
   */
  protected onSurveyDeleteError(res: KalgudiSurveyDetails): void {
    this.notifications.showMessage('Unable to delete survey, please try again later!');

  }

  /**
   * Called after updating the logo successfully
   */
  protected onLogoUpdate(res: KalgudiSurveyDetails): void {}

  /**
   * Initializes survey fullview subscriptions and survey details
   */
  protected init(): void {

    // Initialize survey details
    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdated(survey));


    // Subscribe to survey page state change
    this.subscribeToStateActions();
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible! Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Shows send reminder confirmation dialog
   */
  private showSendReminderConfirmDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm send reminder',
      acceptButtonTitle: 'Send reminder',
      rejectButtonTitle: 'Cancel',
      message: `This will send email/sms to all the members of the survey. Are you sure to send?`,
      matIcon: 'warning',
    };

    // Show the survey publish dialog confirmation
    return this.showInfoDialog(
      dialogDetails.title,
      dialogDetails.message,
      dialogDetails.matIcon,
      dialogDetails.acceptButtonTitle,
      dialogDetails.rejectButtonTitle,
    )
      .pipe(
        filter(res => res.accepted),

        map(res => res.accepted)
      );
  }

  /**
   * Shows survey publish confirmation dialog
   */
  private showPublishConfirmDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm Publish',
      acceptButtonTitle: 'Publish',
      rejectButtonTitle: 'Cancel',
      message: `Questions/Members modifications will not be allowed after publishing survey. Members will be allowed to fill the survey after publish. Do you want to publish survey?`,
      matIcon: 'warning',
    };

    // Show the survey publish dialog confirmation
    return this.showInfoDialog(
      dialogDetails.title,
      dialogDetails.message,
      dialogDetails.matIcon,
      dialogDetails.acceptButtonTitle,
      dialogDetails.rejectButtonTitle,
    )
      .pipe(
        filter(res => res.accepted),

        map(res => res.accepted)
      );
  }

  /**
   * Shows survey publish confirmation dialog
   */
  private showUnPublishConfirmDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm Unpublish',
      acceptButtonTitle: 'Unpublish',
      rejectButtonTitle: 'Cancel',
      message: `Members will not be allowed to fill the survey when unpublished. Do you want to unpublish survey?`,
      matIcon: 'warning',
    };

    // Show the survey publish dialog confirmation
    return this.showInfoDialog(
      dialogDetails.title,
      dialogDetails.message,
      dialogDetails.matIcon,
      dialogDetails.acceptButtonTitle,
      dialogDetails.rejectButtonTitle,
    )
      .pipe(
        filter(res => res.accepted),

        map(res => res.accepted)
      );
  }

  /**
   * Shows info dialog box
   */
  private showInfoDialog(
    title: string,
    message: string,
    matIcon: string,
    acceptButtonTitle: string,
    rejectButtonTitle: string,
  ): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title,
      acceptButtonTitle,
      rejectButtonTitle,
      message,
      matIcon,
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show the survey publish dialog confirmation
    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig);
  }

  /**
   * Event handler gets called on the survey details update in the
   * state service
   */
  private onSurveyStateUpdated(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
  }

  /**
   * Subscribes to survey state action updates and calls the necessary methods.
   */
  private subscribeToStateActions(): void {

    this.surveyState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === SurveyPageActions.PUBLISH_SURVEY) {
          this.publishSurvey();
        }

        if (action.type === SurveyPageActions.UN_PUBLISH_SURVEY) {
          this.unPublishSurvey();
        }

        // Fetch latest survey details and update it
        if (action.type === SurveyPageActions.FETCH_SURVEY) {
          this.updateSurveyDetails();
        }

        if (action.type === SurveyPageActions.UPDATE_LOGO) {
          this.updateSurveyLogo(action.payload);
        }

        if (action.type === SurveyPageActions.DELETE_SURVEY) {
          this.deleteSurvey();
        }

        if (action.type === SurveyPageActions.SEND_REMINDER) {
          this.sendReminder();
        }
      });
  }

  /**
   * Updates survey details in the state
   */
  private updateSurveyDetails(): void {

    this.surveyService.getSurvey(this.surveyDetails.surveyId)
      .subscribe(survey => this.surveyState.dispatchAction(SurveyPageActions.SURVEY_UPDATED, survey));
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
