import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { APiResponseTaskCreation, KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-task-creation-dialog',
  templateUrl: './task-creation-dialog.component.html',
  styleUrls: ['./task-creation-dialog.component.scss']
})
export class TaskCreationDialogComponent extends KalgudiDialog<TaskCreationDialogComponent> implements OnInit {

  taskResponse: APiResponseTaskCreation;

  constructor(
    protected dialogRef: MatDialogRef<TaskCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig,
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }


  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
        res: this.taskResponse
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

  onTaskCreation(taskCreationResponse: any) {
    this.taskResponse = taskCreationResponse;

    this.ok();
  }
}

