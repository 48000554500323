import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiUserTasksListApiService {

  /**
   * `v2/projects/tasks`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/projects/tasks`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Fetch task list
   * @param projectId
   */
  fetchStream(
    projectId = '',
    profileKey = '',
    filters: PartialData = {}
  ): Observable<KalgudiStreamData> {

    const params = {
      offset: '',
      limit: '',
      projectId,
      profileKey,
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // No errors, all good returns task list array
        map(res => ({ count: res.data.count, items: res.data.results }))

      );
  }

}
