import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { FPOInfrastructure, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { OrganisationProfileService } from '../services/organisation-profile.service';


@Directive()
export abstract class KalgudiUpdateFpoInfrastructure extends KalgudiDestroyable {

  @Output()
  closeDialog = new EventEmitter();

  fpoInfrastructureForm: FormGroup;
  progress: boolean;

  profileDetails: KalgudiUser;

  private fb: FormBuilder;
  private orgProfileService: OrganisationProfileService;
  protected util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private profileStateService: KalgudiProfileStateService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                  = this.injector.get(FormBuilder);
    this.orgProfileService   = this.injector.get(OrganisationProfileService);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.notification        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileStateService = this.injector.get(KalgudiProfileStateService);


    this.fpoInfrastructureForm = this.newFpoInfrastructureForm ;

    this.profileStateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => this.profileDetails = profile);
  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  /**
   * Getter for is internet connected field
   */
  get isInternetConnectedField(): AbstractControl {
    return this.fpoInfrastructureForm.get('isInternetConnected');
  }

  /**
   * Getter for is device available field
   */
  get isDeviceAvailableField(): AbstractControl {
    return this.fpoInfrastructureForm.get('isDeviceAvailable');
  }

  /**
   * Getter for interested to participate form from fpo infrastructure form
   */
  get interestedToParticipateForm(): AbstractControl {
    return this.fpoInfrastructureForm.get('interestedToParticipate');
  }

  /**
   * Getter for is interested to participate field
   */
  get isInterestedToParticipateField(): AbstractControl {
    return this.interestedToParticipateForm.get('isInterestedToParticipate');
  }

  /**
   * Getter for participating In Input field interested to participate form
   */
  get participatingInInputField(): AbstractControl {
    return this.interestedToParticipateForm.get('participatingInInput');
  }

  /**
   * Getter for accounting form from fpo infrastructure form
   */
  get accountingForm(): AbstractControl {
    return this.fpoInfrastructureForm.get('accounting');
  }

  /**
   * Getter for is accounted field
   */
  get isAccountingField(): AbstractControl {
    return this.accountingForm.get('isAccounted');
  }

  /**
   * Getter for field trials form from fpo infrastructure form
   */
  get fieldTrialsForm(): AbstractControl {
    return this.fpoInfrastructureForm.get('fieldTrials');
  }

  /**
   * Getter for is trial done field
   */
  get isTrialDoneField(): AbstractControl {
    return this.fieldTrialsForm.get('isTrialDone');
  }

  /**
   * Getter for fpo software field from accounting form
   */
  get fpoSoftwareField(): AbstractControl {
    return this.accountingForm.get('FPOSoftware');
  }

  /**
   * Getter for trials by field from field trials form
   */
  get trialsByField(): AbstractControl {
    return this.fieldTrialsForm.get('trialsBy');
  }

  /**
   * Form group for fpo infrastructure
   */
  private get newFpoInfrastructureForm(): FormGroup {

    return this.fb.group({
      isInternetConnected: [''],
      isDeviceAvailable: [''],
      interestedToParticipate: this.fb.group({
        isInterestedToParticipate: [''],
        participatingInInput: ['']
      }),
      accounting: this.fb.group({
        isAccounted: [''],
        FPOSoftware: ['']
      }),
      fieldTrials: this.fb.group({
        isTrialDone: [''],
        trialsBy: ['']
      })
    });
  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls api method to update fpo infrastructure
   */
  updateFpoInfrastructure() {
    this.progress = true;

    const payload = this.preparePayload(this.fpoInfrastructureForm.value);

    this.orgProfileService.updateFpoInfrastructure(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false )
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )

  }

  /**
   * Subscribe to isAccounting field value changes
   */
  subscribeToIsAccountingValueChanges() {
    this.isAccountingField.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.fpoSoftwareField.disable() : this.fpoSoftwareField.enable();
        if( res === 'NO') {
          this.fpoSoftwareField.reset();
        }
      }
    );

  }


  /**
   * Subscribe to isTrailsBy field value changes
   */
  subscribeToIsTrialsByFieldValueChanges() {

    this.isTrialDoneField.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.trialsByField.disable() : this.trialsByField.enable();
        if( res === 'NO') {
          this.trialsByField.reset();
        }
      }
    );

  }


  /**
   * Subscribe to isInterested To Participate field value changes
   */
  subscribeToIsParticipateFieldValueChanges() {

    this.isInterestedToParticipateField.valueChanges

    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.participatingInInputField.disable() : this.participatingInInputField.enable();
        if( res === 'NO') {
          this.participatingInInputField.reset();
        }
      }
    );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Prepares payload for updating fpo infrastructure
   */
  private preparePayload(formValue): FPOInfrastructure {

    return formValue;
  }

  /**
   * Fpo infrastructure success handler
   */
  private addSuccessHandler(res: KalgudiUser) {
    // this.fpoInfrastructureForm.reset();
    this.closeDialog.emit();
  }

  /**
   * Fpo infrastructure error handler
   */
  private addErrorHandler(err: Error) {

    this.notification.showMessage('Unable to update fpo infrastructure details, please try again later!');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
