import { Component, OnInit } from '@angular/core';
import { KalgudiUploadService } from '@kalgudi/core';

import { KalgudiUploadAttachments } from '../../classes/kalgudi-upload-attachments';

@Component({
  selector: 'kl-upload-attachments',
  templateUrl: './upload-attachments.component.html',
  styleUrls: ['./upload-attachments.component.scss']
})
export class UploadAttachmentsComponent extends KalgudiUploadAttachments implements OnInit {

  constructor(
    protected uploadService: KalgudiUploadService
  ) {
    super(
      uploadService
    );
  }

  ngOnInit() {
  }

}
