import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { TaskShareUpdate } from '@kalgudi/types';

import { KalgudiTaskFullView } from '../../classes/kalgudi-task-full-view';

@Component({
  selector: 'kl-task-full-view',
  templateUrl: './task-full-view.component.html',
  styleUrls: ['./task-full-view.component.scss']
})
export class TaskFullViewComponent extends KalgudiTaskFullView implements OnInit {

  @Output()
  taskDeleted = new EventEmitter();

  activeTabIndex: number;

  readonly TABS = {
    INFO: { index: 0, title: 'Task info' },
    UPDATES: { index: 1, title: 'Activity logs' },
    DETAILS: { index: 2, title: 'Task submission' },
    SUBMISSIONS: { index: 2, title: 'Submissions'}
  };

  constructor(
    protected injector: Injector,
  ) {

    super(
      injector
    );

    this.activeTabIndex = this.TABS.INFO.index;
  }


  ngOnInit() {

    this.getTaskDetails(this.taskId);
  }

  onDestroyed(): void {}

  onPostCreated(res: TaskShareUpdate) {
    // console.log('me', res);
  }

  protected taskDeleteHandler(res) {
    this.taskDeleted.emit();
  }

}
