import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { BaseProductTypes, KalgudiDealsWith, KalgudiUser, StoreBaseProductBasicDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiAddProducts extends KalgudiDestroyable {


  @Input()
  product: KalgudiDealsWith;

  @Input()
  isStock: boolean;


  productsForm: FormGroup;

  productTypes = BaseProductTypes;

  progress = false;

  private fb: FormBuilder;
  private farmerProfileService: KalgudiFarmerProfileService;
  private util: KalgudiUtilityService;

  constructor(protected injector: Injector) {

    super();

    this.fb                   = this.injector.get(FormBuilder);
    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.util                 = this.injector.get(KalgudiUtilityService);

    this.productsForm = this.newProductForm;

    this.subscribeToValueChanges();

  }


  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------


  /**
   * crop form control
   */
  get cropField(): AbstractControl {
    return this.productsForm.get('crop');
  }

  /**
   * Crop form value
   */
  get cropFieldValue(): StoreBaseProductBasicDetails {
    return this.cropField.value;
  }

  /**
   * Variety form control
   */
  get varietyField(): AbstractControl {
    return this.productsForm.get('variety');
  }

  /**
   * Variety value
   */
  get varietyFieldValue(): StoreBaseProductBasicDetails {
    return this.varietyField.value;
  }

  /**
   * Form group for the specific page type
   */
  protected get newProductForm(): FormGroup {

    return this.fb.group({
      crop: [{ value: '', disabled: false }, Validators.required],
      variety: [{ value: '', disabled: true }],
      availableStock: this.fb.group({
        unit: [''],
        value: [0]
      })
    });
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  init() {

    if (this.product) {

      this.productsForm.patchValue(this.product);

      this.cropField.patchValue({
        productName: this.product.baseProductName
      });
      this.varietyField.patchValue({
        productName: this.product.productName
      });
      this.cropField.disable();
      this.varietyField.disable();
    }
  }

  /**
   * Calls api to add a product
   */
  addProduct() {

    const payload = this.preparePayload(this.productsForm.value);

    this.progress = true;

    this.farmerProfileService.postDealsWith(payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onProductAdded(res),
        err => this.util.showApiErrorMessage(err)
      );

  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Called after successful product addition
   */
  protected onProductAdded(res: KalgudiUser): void {}

  /**
   * Prepares payload
   */
  protected preparePayload(value: any): KalgudiDealsWith {

    let payload: KalgudiDealsWith;

    if (this.product) {

      payload = this.product;
      payload.availableStock = this.productsForm.value.availableStock;

    } else {

      payload = {
        availableStock: value.availableStock,
        productId: value.variety.productId ,
        productName: value.variety.productName,
        attachments: value.variety.attachments,

        storeType: value.crop.storeType,
        baseCategory: value.crop.baseCategory,
        baseProductId: value.crop.productId,
        baseProductName: value.crop.productName,
        baseProductAttachments: value.crop.attachments
      };
    }

    return payload;
  }

  /**
   * Subscribing to the product form
   */
  private subscribeToValueChanges() {


    this.cropField.valueChanges
      .pipe (
        takeUntil(this.destroyed$),

      )
      .subscribe(_ => {
        this.cropField.invalid ? this.varietyField.disable() : this.varietyField.enable();
      });

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
