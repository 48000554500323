import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

import { KalgudiSatelliteAdvisories } from '../../classes/kalgudi-satellite-advisories';

@Component({
  selector: 'kl-satellite-advisories-land-details',
  templateUrl: './satellite-advisories-land-details.component.html',
  styleUrls: ['./satellite-advisories-land-details.component.scss']
})
export class SatelliteAdvisoriesLandDetailsComponent extends KalgudiSatelliteAdvisories implements OnInit {

  phosphorusImage: string;
  soilMoistureImage: string;

  estimatedTime = new Date();

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    protected injector: Injector
  ) {

    super(injector);

    this.soilMoistureImage = `https://conserwater.herokuapp.com/soil_moisture/?key=${this.environment.conserWaterKey}&area_name=dheeraj_10_ac`;
    this.phosphorusImage = `https://conserwater.herokuapp.com/phosphorous/?key=${this.environment.conserWaterKey}&area_name=dheeraj_10_ac`;
  }

  ngOnInit() {
    this.getSatelliteAdvisories();
  }

  onDestroyed(): void {}
}
