<section id="header-menu" *ngIf="profile">

  <!-- User profile -->
  <div *ngIf="login && profile?.firstName">
    <button mat-button (click)="close(); navigateToProfilePage(profile?.profileKey)" class="profile menu pt-3 p-0 w-100" fxLayoutAlign="center center">
      <div class="profile-tab" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px">
        <div fxFlex>
          <img class="profile-pic  rounded-circle"
            [src]="(profile?.profilePicUrl | resizeImage:'md') | prefixDomain"
            [klErrorImages]="[
              (profile?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
          />
        </div>
        <div class="profile-name m-0" [title]="profile?.firstName" fxFlex="70%">
          <p class="mb-0 overflow-hidden text-truncate" id="first-name">{{ profile?.firstName }}</p>
          <p class="mb-0 biz-name overflow-hidden text-truncate">{{ profile?.additionalBizDetails?.businessTypeName }}</p>
          <p class="mb-0 biz-name overflow-hidden text-truncate">{{ profile?.address2 }}</p>
        </div>

      </div>

      <div class="mb-2"  fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="1px" class="language-translation-buttons">
          <button mat-button class="lang english" [ngClass]="{'lang-selected': selectedLang === 'en'}" (click)="languageTranslation('en'); close(); $event.stopPropagation()">
            Eng
          </button>
          <div></div>
          <button mat-button class="lang hindi" [ngClass]="{'lang-selected': selectedLang === 'hi'}" (click)="languageTranslation('hi'); close(); $event.stopPropagation()">
            हि
          </button>
          <div></div>
          <button *ngIf="env?.appId !== 'PRORISE_CORE'" mat-button class="lang bengali" [ngClass]="{'lang-selected': selectedLang === 'ba'}" (click)="languageTranslation('ba'); close(); $event.stopPropagation()">
            বাং
          </button>
          <div></div>
          <button *ngIf="env?.appId === 'PRORISE_CORE'" mat-button class="lang" [ngClass]="{'lang-selected': selectedLang === 'te'}" (click)="languageTranslation('te'); close(); $event.stopPropagation()">
            తెలుగు
          </button>
          <div></div>
          <button mat-button class="lang" [ngClass]="{'lang-selected': selectedLang === 'ar'}" (click)="languageTranslation('ar'); close(); $event.stopPropagation()">
            عربى
          </button>
        </div>
      </div>
    </button>
  </div>
  <!-- /User profile -->

  <!-- Mobile menu -->
  <core-header-menu-list
    [menu]="mobileMenu"
    [login]="login"
    [profile]="profile"
    [isMatchedProfile]="isMatchedProfile"
    (menuClosed)="close()">
  </core-header-menu-list>
  <!-- Mobile menu -->

  <!-- Admin -->
  <button mat-menu-item (click)="fpoAdminApp()" *ngIf="profile?.lstOfUserBusinessDetailsInfo[0].businessTypeName === bizType.FPO">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
      <mat-icon class="new-window-icon ml-1">launch</mat-icon>
      <span class="menu" translate>Admin</span>
    </div>
  </button>
  <hr class="m-0">
  <!-- / Admin -->

  <!-- Change password -->
  <button mat-menu-item (click)="openChangePasswordDialog(profile?.profileKey)" *ngIf="env?.appId !== 'SHAKTIMAN'">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon>vpn_key</mat-icon>
      <span class="menu" translate>Change password</span>
    </div>
  </button>
  <hr class="m-0">
  <!-- / Change password -->

  <!-- Logout -->
  <button class="border-bottom w-100 py-2" id="logout-btn-mobile" mat-button mat-list-item (click)="logout()" *ngIf="login && profile?.firstName">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <svg-icon [applyCss]="true" src="assets/svgs/log-out.svg" class="menu-list-icons"
          [svgStyle]="{ 'width.px': 26, 'height.px': 24 }"></svg-icon>
      <span class="menu" translate>Log out</span>
    </div>
  </button>
  <!-- /Logout -->

</section>

