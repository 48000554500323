import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiSellerConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss']
})
export class SellerDetailsComponent implements OnInit {

  @Input()
  sellerConfigDetails: KalgudiSellerConfig;

  @Input()
  sellerId: string;

  @Input()
  editable: boolean;

  @Output()
  updatedDetails = new EventEmitter<any>();

  editFlag = true;

  constructor() { }

  ngOnInit() {
  }

  editToggle(): void {
    this.editFlag = !this.editFlag;
  }

  /**
   * Closes the edit form after successful response
   */
  closeEditForm() {
    this.editFlag = true;
    this.updatedDetails.emit();
  }
}
