import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  AddProjectMembersDialogComponent,
} from '../components/add-project-members-dialog/add-project-members-dialog.component';
import {
  AddProjectMembersMobileDialogComponent,
} from '../components/add-project-members-mobile-dialog/add-project-members-mobile-dialog.component';

@Injectable()
export class AddProjectMembersDialogService {

  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
  ) { }

  /**
   * Open add members to project dialog
   *
   * @param config Dialog
   */
  showAddMembersDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openAddMembersMobileDialog(details)
      : this.openAddMembersDialog(details, config);
  }

  /**
   * Opens add members to project dialog
   */
  openAddMembersDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(AddProjectMembersDialogComponent, details, config);
  }

  /**
   * Opens add members to project Mobile dialog
   */
  openAddMembersMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(AddProjectMembersMobileDialogComponent, details );
  }

}
