<form autocomplete="off" class="crop-dependency" [formGroup]="cropStageForm"
  fxLayout="row wrap"
  fxLayoutGap="8px"
  fxLayoutAlign="start center">

  <mat-form-field appearance="outline" fxFlex="100px" fxFlex.xs="35%">
    <mat-label translate>From</mat-label>
    <input matInput [placeholder]="'From' | translate" formControlName="from" type="number" required>
  </mat-form-field>

  <mat-form-field appearance="outline" fxFlex="100px" fxFlex.xs="35%">
    <mat-label translate>To</mat-label>
    <input matInput [placeholder]="'To' | translate" formControlName="to" type="number">
  </mat-form-field>

  <span fxFlex="60px" fxFlex.xs="25px" translate>days after</span>

  <!-- <mat-form-field appearance="outline" fxFlex="100px">
    <mat-label>When</mat-label>
    <mat-select formControlName="when">
      <mat-option *ngFor="let item of afterBefore" [value]="item?.id">{{item?.value}}</mat-option>
    </mat-select>
  </mat-form-field> -->

  <mat-form-field appearance="outline" fxFlex="150px" fxFlex.xs="100%">
    <mat-label translate>Select task</mat-label>
    <mat-select [formControl]="task" *ngIf="tasks">
      <mat-option *ngFor="let item of tasks;" [value]="item">{{item?.title}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- <mat-form-field appearance="outline" fxFlex="150px">
    <mat-label>Crop stage</mat-label>
    <mat-select formControlName="cropStage">
      <mat-option *ngFor="let item of cropStages" [value]="item?.id">{{item?.value}}</mat-option>
    </mat-select>
  </mat-form-field> -->

</form>
