<main class="share-update-action px-3 py-1" (click)="toFullview()">

  <!-- Action buttons -->
  <div fxLayout="row" class="border-top" fxLayoutAlign="space-between center">
    <div fxFlex>
      <div class="w-100" fxLayout="row" fxLayoutAlign="start center"
        class="action text-muted" fxLayoutGap="4px">

        <!-- Like action -->
        <div fxLayout="row">
          <button [disabled]="!isLoggedIn" *ngIf="!likedPost && !postLiked"
            [matTooltip]="likes | joinArrayFields:'name'" mat-icon-button class="like-button"
            (click)="onLike(true);$event.stopPropagation()">
            <svg-icon [applyCss]="true" class="svg-icon mr-1" src="assets/svgs/likes.svg"
              [svgStyle]="{ 'width.px': 14, 'height.px': 14}" fxLayoutAlign="start center">
            </svg-icon>
            <!-- <p class="mb-0 action-title" >{{'Like' | translate}}</p> -->
            <!-- <p class="mb-0 action-title likes-count" [title]="'Likes count' | translate" *ngIf="likesCount" (click)="openLikesListDialog(); $event.stopPropagation()">({{ likesCount | modernNumber }})</p> -->
          </button>
          <button [disabled]="!isLoggedIn" *ngIf="likedPost || postLiked" [matTooltip]="likes | joinArrayFields:'name'"
            mat-icon-button class="like-button" (click)="onLike(false); $event.stopPropagation()">
            <svg-icon [applyCss]="true" class="svg-icon mr-1" src="assets/svgs/fill-like.svg"
              [svgStyle]="{ 'width.px': 12, 'height.px': 12}" fxLayoutAlign="start center">
            </svg-icon>
            <!-- <p class="mb-0 action-title" >{{'Like' | translate}}</p> -->
            <!-- <p class="mb-0 action-title likes-count" [title]="'Likes count' | translate" *ngIf="likesCount" (click)="openLikesListDialog(); $event.stopPropagation()">({{ likesCount | modernNumber }})</p> -->
          </button>

          <span *ngIf="(likedPost || postLiked) && likes.length === 1" class="likes-text">You liked&nbsp;</span>
          <span *ngIf="(likedPost || postLiked) && likes.length > 1" class="likes-text mr-1">You and</span>
          <span class="action-title mr-1" [ngClass]="{'likes-count': likesCount}" *ngIf="likesCounts"
            [title]="'Likes count' | translate" (click)="openLikesListDialog(); $event.stopPropagation()">{{ likesCounts
            === 0 ? '' : likesCounts }}</span>
          <span *ngIf="(likedPost || postLiked) && likes.length > 1 && postLiked"
            (click)="openLikesListDialog(); $event.stopPropagation()" class="likes-text">others</span>

          <!-- <button mat-icon-button *ngIf="likesCount" [ngClass]="{'likes-count': likesCount}">
            <p class="mb-0 action-title" [title]="'Likes count' | translate" (click)="openLikesListDialog(); $event.stopPropagation()">{{ likesCount | modernNumber }}</p>
          </button> -->

        </div>
        <!--/ Like action -->

        <!-- Comment action -->
        <div fxLayout="row"
          (click)="onComment(); $event.stopPropagation(); $event.preventDefault()">
          <button mat-button class="action-buttons" [disabled]="!isLoggedIn">
            <!-- <p class="mb-0 action-title" *ngIf="commentsCount">({{commentsCount | modernNumber}})</p> -->
            <svg-icon class="svg-icon mr-1" src="assets/svgs/comments.svg"
              [svgStyle]="{ 'width.px': 14, 'height.px': 14}" fxLayoutAlign="start center">
            </svg-icon>
            <!-- <p class="mb-0 action-title" translate>Comment</p> -->
            <p class="mb-0 action-title" *ngIf="commentsCount">({{commentsCount | modernNumber}})</p>
          </button>
        </div>
        <!-- /Comment action -->

        <div class="social-share-button" (click)="$event.stopPropagation(); $event.preventDefault()"
          *ngIf="url && (isShareOption)">
          <kl-social-shares [url]="url" [hideShareLabel]="true" direction="vertical" [shareCount]="shareCount">
          </kl-social-shares>
        </div>

        <!-- Location of post -->
        <ng-container *ngIf="geoLocation">
          <div class="map-pin ml-1 cursor-pointer" fxLayout (click)="openOverlay($event)" type="button" cdkOverlayOrigin #trigger="cdkOverlayOrigin" [matTooltip]="geoLocation?.full_location">
            <mat-icon class="map-pin p-1">location_on</mat-icon>
          </div>

          <ng-template cdkConnectedOverlay cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop" [cdkConnectedOverlayHasBackdrop]="true" [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOverlayOpened" (backdropClick)="isOverlayOpened = false">
            <div fxLayout="column" class="location-popUp m-0 p-2">
              <!-- Heading -->
              <div fxLayoutAlign="space-between center" class="m-0 p-0">
                  <!-- Full location text -->
                  <div fxLayout fxLayoutAlign="space-between center" *ngIf="geoLocation?.full_location">
                    <span>Location: </span>
                    <span class="font-weight-bold pl-1">{{geoLocation?.full_location}}</span>
                  </div>
                  <!-- / Full location text -->
                <button mat-icon-button (click)='isOverlayOpened = false'>
                  <mat-icon>close</mat-icon>
                </button>
              </div>
              <!-- / Heading -->

              <!-- location -->
              <kl-geo-location
                [LabelPositionTop]="true"
                [showLatLong] = false
                [location]="geoLocation"
                >
              </kl-geo-location>
              <!-- location -->
            </div>
          </ng-template>
        </ng-container>
        <!-- Location of post -->

      </div>
    </div>

    <div>
      <ng-content></ng-content>
    </div>
  </div>
  <!-- /Action buttons -->
</main>
