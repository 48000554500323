<section class="university-page mb-3 page-creation-form">
  <div class="w-100 university col-7 mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="18px">

        <!-- Courses offered -->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Courses offered</mat-label>
            <input matInput [placeholder]="'Courses offered' | translate" formControlName="courses" required/>
            <mat-error class="mt-3" *ngIf="courses?.errors?.required" translate>Please mention courses offered</mat-error>
          </mat-form-field>
        </div>
        <!-- /Courses offered -->

        <!-- Founded Year & Founders -->
        <div class="founder-date w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">

          <!-- Founded Year -->
          <mat-form-field appearance="outline" class="date-picker" fxFlex="calc(50% - 20px)">
            <input matInput [placeholder]="'Founded year' | translate" (click)="foundedYear.open()" [matDatepicker]="foundedYear" formControlName="foundedYear" readonly />
            <mat-datepicker-toggle matSuffix [for]="foundedYear"></mat-datepicker-toggle>
            <mat-datepicker #foundedYear startView="year"></mat-datepicker>
          </mat-form-field>
          <!-- /Founded Year -->

          <!-- Founders -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Founders</mat-label>
            <input matInput [placeholder]="'Founders' | translate" formControlName="founders"/>
          </mat-form-field>
          <!-- /Founders -->

        </div>
        <!-- /Founded Year & Founders -->

        <!-- Control type -->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Control type</mat-label>
            <input matInput [placeholder]="'Control type' | translate" formControlName="controlType"/>
          </mat-form-field>
        </div>
        <!--  /Control type -->

        <!-- Fax Number & Enrollment range -->
        <div class="number w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">

          <!--  Fax number -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Fax number</mat-label>
            <input matInput [placeholder]="'Fax number' | translate" formControlName="faxNumber"/>
          </mat-form-field>
          <!-- / Fax number -->

          <!-- Enrollment range -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Enrollment range</mat-label>
            <input matInput [placeholder]="'Enrollment range' | translate" formControlName="enrollmentRange" />
          </mat-form-field>
          <!-- /Enrollment range -->

        </div>
        <!--/Fax Number & Enrollment range -->

      </div>
    </form>
    <!-- / Wrapper Form -->
  </div>
</section>
