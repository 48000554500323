import { Component, Input, OnInit } from '@angular/core';
import { ShareUpdateHeader } from '@kalgudi/types';

@Component({
  selector: 'kl-network-header',
  templateUrl: './network-header.component.html',
  styleUrls: ['./network-header.component.scss']
})
export class NetworkHeaderComponent implements OnInit {

  @Input()
  networksHeader: ShareUpdateHeader;

  constructor() { }

  ngOnInit() {
  }

}
