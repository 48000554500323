import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult, KalgudiSurveyQuestion } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-add-question-dialog',
  templateUrl: './mobile-add-question-dialog.component.html',
  styleUrls: ['./mobile-add-question-dialog.component.scss']
})
export class MobileAddQuestionDialogComponent extends KalgudiMobileDialog implements OnInit {

  question: KalgudiSurveyQuestion;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,

  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }


  /**
   * Triggers when the child eis an event
   */
  onQuestionAddition(question: KalgudiSurveyQuestion): void {
    this.question = question;
    this.ok();
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
        question: this.question
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }


}
