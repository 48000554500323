import { Inject, Injector, Input, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiFarmingHistory,
  KalgudiUser,
  ProgramShareUpdate,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import {
  FarmerFarmActivityDialogComponent,
} from '../components/farmer-farm-activity-dialog/farmer-farm-activity-dialog.component';
import {
  FarmerFarmActivityMobileDialogComponent,
} from '../components/farmer-farm-activity-mobile-dialog/farmer-farm-activity-mobile-dialog.component';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';
import { KalgudiFarmingActivityStreamStateService } from '../services/kalgudi-farming-activity-stream-state.service';
import { KalgudiProfilePageService } from '../services/kalgudi-profile-page.service';



@Directive()
export abstract class KalgudiCropFarmingActivity extends KalgudiInboxStream<ProgramShareUpdate> {

  @Input()
  cropPageId: string;

  seasonsList: KalgudiFarmingHistory[];

  profile: KalgudiUser;

  editable: boolean;

  activityFilter = new FormControl();
  selectedSeason: any;

  private dialogsService: KalgudiDialogsService;
  private farmerProfileService: KalgudiFarmerProfileService;
  protected updatesStream: KalgudiProfilePageService;
  private profileStateService: KalgudiProfileStateService;
  private farmingActivityStreamStateService: KalgudiFarmingActivityStreamStateService;
  protected profileService: KalgudiProfileService


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.dialogsService                    = this.injector.get(KalgudiDialogsService);
    this.updatesStream                     = this.injector.get(KalgudiProfilePageService);
    this.farmerProfileService              = this.injector.get(KalgudiFarmerProfileService);
    this.profileStateService               = this.injector.get(KalgudiProfileStateService);
    this.farmingActivityStreamStateService = this.injector.get(KalgudiFarmingActivityStreamStateService);
    this.profileService                    = this.injector.get(KalgudiProfileService);

    this.profileStateService.data$
      .pipe(

        take(1)
      )
      .subscribe(
        res => this.profile = res
      );

    this.activityFilter.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.selectedSeason = res;
          this.resetStream();
        }
      );

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  getSeasons() {
    const profileKey = this.profile.profileKey;

    this.farmerProfileService.getFarmSeasonsList(this.cropPageId, '', profileKey)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.seasonsList = res;

          if (!this.seasonsList || (this.seasonsList && !this.seasonsList.length)) {
            this.activityFilter.patchValue('ALL');
          }

          if (this.seasonsList && this.seasonsList.length) {
            this.activityFilter.patchValue(this.seasonsList[0]);
            this.selectedSeason = this.seasonsList[0];
          }

        }
      )
  }

  /**
   * Opens farm activity dialog
   */
  openFarmActivityDialog() {

    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Farm activity',
      data: {
        pageId: this.cropPageId,
        selectedSeason: this.selectedSeason
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        pageId: this.cropPageId
      }
    };

    // Show farm activity dialog
    this.showFarmActivityDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r && r.accepted),
      )
      .subscribe()

  }


  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<ProgramShareUpdate> {
    return this.farmingActivityStreamStateService.newStreamItem$;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Stream to fetch farming activities
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    const filter = this.activityFilter.value === 'ALL' ? 'ALL' : this.activityFilter.value.id

    return this.updatesStream.getPageShareStream(this.cropPageId, filter, offset, limit);
  }

  /**
   * Check for web and mobile and opens farm activity dialog
   *
   * @param config Dialog
   */
  private showFarmActivityDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(FarmerFarmActivityMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(FarmerFarmActivityDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
