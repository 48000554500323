import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { scaleOutAnimation, slideRightAnimation } from '@kalgudi/core';
import { UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-page-task-list-header',
  templateUrl: './page-task-list-header.component.html',
  styleUrls: ['./page-task-list-header.component.scss'],
  animations: [scaleOutAnimation, slideRightAnimation]
})
export class PageTaskListHeaderComponent implements OnInit {

  @Input()
  selectedView: string;

  @Input()
  projectMenu: UIMenu[];

  @Output()
  viewOption = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  showProjectsList(menu) {
    this.viewOption.emit(menu);
    this.selectedView = menu.id;
  }
}
