import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, StreamUrls } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProfileSocialActivitiesApiService {

  /**
   * `v1/stream/socialactivity`
   */
  private readonly API_UPDATES_STREAM = `${this.env.restBaseUrl}/stream/socialactivity`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Fetches profile share update stream from API.
   *
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchUpdatesStream(profileKey: string, offset: number, limit: number): Observable<StreamUrls[]> {

    const params = {
      profileKey,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_UPDATES_STREAM, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => this.util.toJson<StreamUrls[]>(res.data)),
      );
  }
}
