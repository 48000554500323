const ALL = {
  schema: {
    title: "Extending",
    definitions: {
      address: {
        type: "object",
        properties: {
          street_address: {
            title: "Address",
            type: "string"
          },
          city: {
            title: "City",
            type: "string"
          },
          state: {
            title: "State",
            type: "string"
          }
        },
        required: ["street_address", "city", "state"]
      }
    },
    type: "object",
    properties: {
      billing_address: {
        title: "Billing Address",
        $ref: "#/definitions/address"
      },
      shipping_address: {
        allOf: [
          {
            title: "Shipping Address",
            $ref: "#/definitions/address"
          },
          {
            properties: {
              type: {
                title: "Housing type",
                enum: ["Residential", "Business"]
              }
            },
            required: ["type"]
          }
        ]
      }
    }
  }
};

const ANY_OF = {
  schema: {
    type: "object",
    properties: {
      age: {
        type: "integer",
        title: "Age"
      }
    },
    anyOf: [
      {
        title: "First method of identification",
        properties: {
          firstName: {
            type: "string",
            title: "First name",
            default: "Chuck"
          },
          lastName: {
            type: "string",
            title: "Last name"
          }
        }
      },
      {
        title: "Second method of identification",
        properties: {
          idCode: {
            type: "string",
            title: "ID code"
          }
        }
      }
    ]
  },
  model: {
    firstName: "Chuck"
  }
};

const ARRAYS = {
  schema: {
    definitions: {
      Thing: {
        type: "object",
        properties: {
          name: {
            type: "string",
            default: "Default name"
          }
        }
      }
    },
    type: "object",
    properties: {
      listOfStrings: {
        type: "array",
        title: "A list of strings",
        items: {
          type: "string",
          default: "bazinga"
        }
      },
      multipleChoicesList: {
        type: "array",
        title: "A multiple choices list",
        items: {
          type: "string",
          enum: ["foo", "bar", "fuzz", "qux"]
        },
        uniqueItems: true
      },
      fixedItemsList: {
        type: "array",
        title: "A list of fixed items",
        items: [
          {
            title: "A string value",
            type: "string",
            default: "lorem ipsum"
          },
          {
            title: "a boolean value",
            type: "boolean"
          }
        ],
        additionalItems: {
          title: "Additional item",
          type: "number"
        }
      },
      minItemsList: {
        type: "array",
        title: "A list with a minimal number of items",
        minItems: 3,
        items: {
          $ref: "#/definitions/Thing"
        }
      },
      defaultsAndMinItems: {
        type: "array",
        title: "List and item level defaults",
        minItems: 5,
        default: ["carp", "trout", "bream"],
        items: {
          type: "string",
          default: "unidentified"
        }
      },
      nestedList: {
        type: "array",
        title: "Nested list",
        items: {
          type: "array",
          title: "Inner list",
          items: {
            type: "string",
            default: "lorem ipsum"
          }
        }
      },
      unorderable: {
        title: "Unorderable items",
        type: "array",
        items: {
          type: "string",
          default: "lorem ipsum"
        }
      },
      unremovable: {
        title: "Unremovable items",
        type: "array",
        items: {
          type: "string",
          default: "lorem ipsum"
        }
      },
      noToolbar: {
        title: "No add, remove and order buttons",
        type: "array",
        items: {
          type: "string",
          default: "lorem ipsum"
        }
      },
      fixedNoToolbar: {
        title: "Fixed array without buttons",
        type: "array",
        items: [
          {
            title: "A number",
            type: "number",
            default: 42
          },
          {
            title: "A boolean",
            type: "boolean",
            default: false
          }
        ],
        additionalItems: {
          title: "A string",
          type: "string",
          default: "lorem ipsum"
        }
      }
    }
  },
  model: {
    listOfStrings: ["foo", "bar"],
    multipleChoicesList: ["foo", "bar"],
    fixedItemsList: ["Some text", true, 123],
    nestedList: [["lorem", "ipsum"], ["dolor"]],
    unorderable: ["one", "two"],
    unremovable: ["one", "two"],
    noToolbar: ["one", "two"],
    fixedNoToolbar: [42, true, "additional item one", "additional item two"]
  }
};

const NESTED = {
  schema: {
    title: "A list of tasks",
    type: "object",
    required: ["title"],
    properties: {
      title: {
        type: "string",
        appearance: 'outline',
        title: "Task list title"
      },
      tasks: {
        type: "array",
        title: "Tasks",
        items: {
          type: "object",
          required: ["title"],
          properties: {
            title: {
              type: "string",
              title: "Title",
              appearance: 'outline',
              description: "A sample title"
            },
            details: {
              type: "string",
              title: "Task details",
              appearance: 'outline',
              description: "Enter the task details"
            },
            done: {
              type: "boolean",
              title: "Done?",
              default: false
            }
          }
        }
      }
    }
  },
  model: {
    title: "My current tasks",
    tasks: [
      {
        title: "My first task",
        details:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        done: true
      },
      {
        title: "My second task",
        details:
          "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur",
        done: false
      }
    ]
  }
};

const NULL = {
  schema: {
    title: "Null field example",
    description: "A short form with a null field",
    type: "object",
    required: ["firstName"],
    properties: {
      helpText: {
        title: "A null field",
        description:
          "Null fields like this are great for adding extra information",
        type: "null"
      },
      firstName: {
        type: "string",
        title: "A regular string field",
        default: "Chuck"
      }
    }
  },
  model: {
    firstName: "Chuck"
  }
};

const NULLABLE = {
  schema: {
    title: "A registration form (nullable)",
    description: "A simple form example using nullable types",
    type: "object",
    required: ["fullName"],
    properties: {
      fullName: {
        type: "string",
        title: "First name",
        default: "Chuck"
      },
      age: {
        type: ["integer", "null"],
        title: "Age"
      },
      bio: {
        type: ["string", "null"],
        title: "Bio",
        widget: {
          formlyConfig: {
            type: "textarea",
            templateOptions: {
              placeholder:
                "Leaving this field empty will cause formData property to be `null`",
              rows: 5
            }
          }
        }
      }
    }
  },
  model: {
    fullName: "Chuck",
    age: 75,
    bio: null
  }
};

const NUMBERS = {
  schema: {
    type: "object",
    title: "Number fields & widgets",
    properties: {
      number: {
        title: "Number",
        type: "number"
      },
      integer: {
        title: "Integer",
        type: "integer"
      },
      numberEnum: {
        type: "number",
        title: "Number enum",
        enum: [1, 2, 3]
      },
      numberEnumRadio: {
        type: "number",
        title: "Number enum",
        enum: [1, 2, 3]
      },
      integerRange: {
        title: "Integer range",
        type: "integer",
        minimum: 42,
        maximum: 100
      },
      integerRangeSteps: {
        title: "Integer range (by 10)",
        type: "integer",
        minimum: 50,
        maximum: 100,
        multipleOf: 10
      }
    }
  },
  model: {
    number: 3.14,
    integer: 42,
    numberEnum: 2,
    integerRange: 42,
    integerRangeSteps: 80
  }
};

const ONE_OF = {
  schema: {
    type: "object",
    oneOf: [
      {
        title: "Option 1",
        properties: {
          lorem: {
            title: "lorem",
            type: "string"
          }
        },
        required: ["lorem"]
      },
      {
        title: "Option 2",
        properties: {
          ipsum: {
            title: "ipsum",
            type: "string"
          }
        },
        required: ["ipsum"]
      }
    ]
  },
  model: {}
};

const REFERENCES = {
  schema: {
    definitions: {
      address: {
        type: "object",
        properties: {
          street_address: {
            type: "string",
            title: "Street address"
          },
          city: {
            type: "string",
            title: "City"
          },
          state: {
            type: "string",
            title: "State"
          }
        },
        required: ["street_address", "city", "state"]
      },
      node: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name"
          },
          children: {
            type: "array",
            items: {
              $ref: "#/definitions/node"
            }
          }
        }
      }
    },
    type: "object",
    properties: {
      billing_address: {
        title: "Billing address",
        $ref: "#/definitions/address"
      },
      shipping_address: {
        title: "Shipping address",
        $ref: "#/definitions/address"
      },
      tree: {
        title: "Recursive references",
        $ref: "#/definitions/node"
      }
    }
  },
  model: {
    billing_address: {
      street_address: "21, Jump Street",
      city: "Babel",
      state: "Neverland"
    },
    shipping_address: {
      street_address: "221B, Baker Street",
      city: "London",
      state: "N/A"
    },
    tree: {
      name: "root",
      children: [
        {
          name: "leaf"
        }
      ]
    }
  }
};

const SCHEMA_DEPENDENCIES = {
  schema: {
    title: "Schema dependencies",
    description: "These samples are best viewed without live validation.",
    type: "object",
    properties: {
      simple: {
        title: "Simple",
        type: "object",
        properties: {
          credit_card: {
            type: "number",
            title: "Credit card"
          }
        },
        dependencies: {
          credit_card: {
            properties: {
              billing_address: {
                type: "string",
                title: "Billing address"
              }
            },
            required: ["billing_address"]
          }
        }
      },
      person: {
        title: "Person",
        type: "object",
        properties: {
          "Do you have any pets?": {
            type: "string",
            title: "Do you have any pets?",
            enum: ["No", "Yes: One", "Yes: More than one"],
            default: "No"
          }
        },
        required: ["Do you have any pets?"],
        dependencies: {
          "Do you have any pets?": {
            oneOf: [
              {
                properties: {
                  "Do you have any pets?": {
                    enum: ["No"]
                  }
                }
              },
              {
                properties: {
                  "Do you have any pets?": {
                    enum: ["Yes: One"]
                  },
                  "How old is your pet?": {
                    title: "How old is your pet?",
                    type: "number"
                  }
                },
                required: ["How old is your pet?"]
              },
              {
                properties: {
                  "Do you have any pets?": {
                    enum: ["Yes: More than one"]
                  },
                  "Do you want to get rid of any?": {
                    title: "Do you want to get rid of any?",
                    type: "boolean"
                  }
                },
                required: ["Do you want to get rid of any?"]
              }
            ]
          }
        }
      }
    }
  },
  model: {}
};

const SELECTABLE_ALTERNATIVES = {
  schema: {
    title: "Select alternatives",
    type: "object",
    properties: {
      select1: {
        type: "string",
        title: "Select 1: Enum array",
        enum: ["Option 1", "Option 2", "Option 3"]
      },
      select2: {
        type: "string",
        title: "Select 2: AnyOf/OneOf array with enum",
        anyOf: [
          {
            title: "Option 1",
            enum: ["option1"]
          },
          {
            title: "Option 2",
            enum: ["option2"]
          },
          {
            title: "Option 3",
            enum: ["option3"]
          }
        ]
      },
      select3: {
        type: "string",
        title: "Select 3: AnyOf/OneOf array with const",
        oneOf: [
          {
            title: "Option 1",
            const: "option1"
          },
          {
            title: "Option 2",
            const: "option2"
          },
          {
            title: "Option 3",
            const: "option3"
          }
        ]
      },
      select4: {
        type: "string",
        title: "Select 4: Radio button",
        enum: ["Option 1", "Option 2", "Option 3"],
        widget: {
          formlyConfig: {
            type: "radio"
          }
        }
      },
      select5: {
        type: "string",
        title: "Select 5: with widget attr",
        widget: {
          formlyConfig: {
            type: "enum",
            templateOptions: {
              options: [
                {
                  value: "1",
                  label: "option 1"
                },
                {
                  value: "2",
                  label: "option 2"
                },
                {
                  value: "3",
                  label: "option 3"
                }
              ]
            }
          }
        }
      },
      select6: {
        type: "array",
        title: "Select 6: Multiselect",
        uniqueItems: true,
        items: {
          type: "string",
          enum: ["Option 1", "Option 2", "Option 3"]
        }
      }
    }
  },
  model: {}
};

const SIMPLE = {
  schema: {
    title: "A registration form",
    description: "A simple form example.",
    type: "object",
    required: ["firstName", "lastName"],
    properties: {
      firstName: {
        type: "string",
        title: "First name",
        default: "Chuck",
        appearance: 'outline',
      },
      lastName: {
        type: "string",
        title: "Last name",
        appearance: 'outline',
      },
      age: {
        type: "integer",
        title: "Age",
        appearance: 'outline',
      },
      bio: {
        type: "string",
        title: "Bio",
        appearance: 'outline',
      },
      password: {
        type: "string",
        title: "Password",
        minLength: 3,
        appearance: 'outline',
      },
      telephone: {
        type: "string",
        title: "Telephone",
        minLength: 10,
        appearance: 'outline',
      }
    }
  },
  model: {
    lastName: "Norris",
    age: 75,
    bio: "Roundhouse kicking asses since 1940",
    password: "noneed"
  }
};

export const JSON_SCHEMA = {
  ALL,
  ANY_OF,
  ARRAYS,
  NESTED,
  NULL,
  NULLABLE,
  NUMBERS,
  ONE_OF,
  REFERENCES,
  SCHEMA_DEPENDENCIES,
  SELECTABLE_ALTERNATIVES,
  SIMPLE
};
