import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUsersPickerBase } from '@kalgudi/common';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { StringAnyMap } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';

import { KalgudiProgramSearchService } from '../../../../services/kalgudi-program-search.service';
import { DigitalAssistanceService } from '../../services/digital-assistance.service';

@Component({
  selector: 'kl-page-members-search',
  templateUrl: './page-members-search.component.html',
  styleUrls: ['./page-members-search.component.scss']
})
export class PageMembersSearchComponent extends KalgudiUsersPickerBase implements OnInit {

  @Input()
  pageId: string;

  protected api: DigitalAssistanceService;
  protected pageApi: KalgudiProgramSearchService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up (initialize) parent
    super(notification, util);

    this.defaultSearchKeyword = '';

    this.api = this.injector.get(DigitalAssistanceService);
    this.pageApi = this.injector.get(KalgudiProgramSearchService);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed() {}

  /**
   * Defines the Api call for my kalgudi connects. If the search keyword is provided then it calls
   * the Api to search my connects otherwise it loads the list of all my connects.
   *
   * It chooses the Api call based on the search keyword.
   */
  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserBasicSearchResults> {

    let obs: any = EMPTY;

    if (this.pageId) {
      obs = this.pageApi.searchMembers(this.pageId, searchKeyword, offset, limit)
    }

    return obs;
  }
}
