import { Inject, Pipe, PipeTransform } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

/**
 * Prefixes a url string with the domain. It prefixes the url to the
 * domain if specified otherwise takes the domain from environment.
 *
 * @author Pankaj Prakash
 * @example
 * <img [src]="imageSrc | prefixDomain" />
 * <img [src]="imageSrc | prefixDomain: 'https://kalgudi.com'" />
 */
@Pipe({
  name: 'prefixDomain'
})
export class PrefixDomainPipe implements PipeTransform {

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) { }

  static transform(value: string, args: string): string {
    return PrefixDomainPipe.handleDomainPrefixing(value, args);
  }

  transform(value: string, args: string = this.env.domain): string {
    return PrefixDomainPipe.handleDomainPrefixing(value, args);
  }

  /**
   * Handles domain prefixing
   */
  private static handleDomainPrefixing(value: string, args?: string): string {

    // `true` if value begins with http or https otherwise `false`.
    const beginsWithHttp = (/^http[s]?:\/\//).test(value);

    return beginsWithHttp
      ? value
      : args + value;
  }
}
