<!-- Surveys logs list -->
<section class='my-4 logs-list' id="da-stream" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center"
  *ngIf="stream?.length" @fadeIn>
  <mat-card class="w-100 p-0 logs-wrapper">

    <div class="border-bottom logs-header px-3 py-2 mb-2 font-weight-bold" fxLayout fxLayoutAlign="space-between center">
      <p class="mb-0 header-title" fxFlex translate>User</p>
      <p class="mb-0 header-title" translate>Activity</p>
    </div>

    <div fxLayout="column" *ngFor="let activity of stream; let last = last;">

      <div class="log py-2 px-3" fxLayout="row" fxLayoutAlign="space-between center"
        [ngClass]="{'border-bottom': !last}">

        <div class="profile" fxFlex fxLayout fxLayoutGap="4px" fxLayoutAlign="start center">
          <img [src]="activity?.author?.profilePicUrl" class="rounded-circle" [alt]="activity?.author?.firstName"
            [klErrorImages]="[
              (activity?.author?.profilePicUrl) | prefixDomain,
              defaultProfilePic
            ]">

          <p class="mb-0 author-name">{{ activity?.author?.firstName }}</p>
          <p class="mb-0 date text-muted" [title]="activity?.activityTo?.notifyTime | parseDate | date: 'dd MMM yyyy, hh:mm a'">{{ activity?.activityTo?.notifyTime | parseDate | date: 'dd MMM yyyy, hh:mm a' }}</p>
        </div>

        <p class="mb-0 type">
          <strong>{{ activityTypeLabelMap[activity?.activityType] || activity?.activityType | translate}}</strong>
          <!-- <strong><a (click)="openSubmission(activity)" *ngIf="availableActivityTypes.SURVEY_SUBMITTED === activity?.subDoctype">{{activityTypeLabelMap[activity?.subDoctype]}}</a></strong> -->
        </p>
      </div>
    </div>

  </mat-card>
</section>
