import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TrainingFormComponent } from './components/training-form/training-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PageActivityFiltersModule } from '../page-activity-filters/page-activity-filters.module';
import {
  KalgudiAttachUrlModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule
} from '@kalgudi/common';
import { KalgudiSocialModule } from '@kalgudi/social';
import { TranslateModule } from '@ngx-translate/core';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiBottomSheetService, MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiPageAiPredictionsFiltersModule } from '../page-ai-predictions-filters/page-ai-predictions-filters.module';
import { KalgudiProgramTargetMembersModule } from '../program-target-members';
import { KuberPagePromotionalShareUpdateModule } from '../kuber-page-promotional-share-update/kuber-page-promotional-share-update.module';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateAdapter } from 'angular-calendar';
import { TrainingProgramPostDialogComponent } from './components/training-program-post-dialog/training-program-post-dialog.component';
import { TrainingProgramPostMobileDialogComponent } from './components/training-program-post-mobile-dialog/training-program-post-mobile-dialog.component';
import { MobileProgramShareTrainingFormComponent } from './components/mobile-program-share-training-form/mobile-program-share-training-form.component';
import { KalgudiProgramTrainingService } from './services/kalgudi-program-training.service';
import { KalgudiProgramTrainingApiService } from './services/kalgudi-program-training-api.service';
import {MatRadioModule} from '@angular/material/radio';




@NgModule({
  declarations: [
    TrainingFormComponent,
    TrainingProgramPostDialogComponent,
    TrainingProgramPostMobileDialogComponent,
    MobileProgramShareTrainingFormComponent
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    FormsModule,

    FlexLayoutModule,

    ReactiveFormsModule,

    // Material
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMenuModule,
    MomentDateModule,
    MatRadioModule,

    // Kalgudi
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiProgramTargetMembersModule,
    KalgudiShareUpdateModule,
    KalgudiImageChipListModule,
    KalgudiSocialModule,
    MobileMenuBottomSheetModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiTimePickerModule,
    KalgudiStoreProductsSearchModule,

    KalgudiPageAiPredictionsFiltersModule,

    PageActivityFiltersModule,

    KuberPagePromotionalShareUpdateModule,

    TranslateModule,

  ],
  exports: [
    TrainingFormComponent
  ],
  providers: [
    KalgudiBottomSheetService,
    KalgudiProgramTrainingService,
    KalgudiProgramTrainingApiService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]
})
export class KalgudiProgramTrainingModule { }
