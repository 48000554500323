<div class="creation-dialog w-100 p-2">
  <div class="dialog-data" fxLayout fxLayoutAlign="center center">

    <div class="h-100" fxLayout="column" fxLayoutAlign="space-between" fxFlex>

      <!--  Project page Header -->
      <header class="w-100 position-relative" fxLayout="row" fxHide.xs>
        <p class="w-100 header-title my-2" fxLayoutAlign="center center">
          <span class="title" translate>
            {{ dialogData?.data?.projectId ? 'Edit project' : 'Create your project' }}
          </span>
        </p>

        <div class="closed position-absolute">
          <button mat-icon-button (click)="cancel()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </header>
      <!-- / Project page Header -->

      <!-- Project creation -->
      <div class="project-creation overflow-auto">
        <kl-project-creation
          [projectId]="dialogData?.data?.projectId"
          [filters]="dialogData?.data?.filters"
          [categoryTiles]="dialogData?.data?.projectCategories"
          (projectCreated)="onProjectCreated($event)">
        </kl-project-creation>
      </div>
      <!-- / Project creation -->

    </div>

  </div>

</div>
