import { Injectable } from '@angular/core';
import { KalgudiUtilityService, ReportAbuseRequest } from '@kalgudi/core';
import { Observable } from 'rxjs';

import { KalgudiReportAbuseApiService } from './report-abuse-api.service';

@Injectable()
export class KalgudiReportAbuseService {

  constructor(
    private util: KalgudiUtilityService,
    private api: KalgudiReportAbuseApiService
  ) { }

  /**
   * Calls a API to report on post
   * @param reqObj payload
   */
  reportAbuse(request: ReportAbuseRequest): Observable<boolean> {
    const payload = this.util.clone<ReportAbuseRequest>(request);

    return this.api.reportAbuse(payload);
  }
}
