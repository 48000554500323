import { Inject, Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService, KalgudiSocialShare } from '@kalgudi/share-update';
import {
  KalgudiPromotionalProductDescriptionDialogComponent,
  KalgudiPromotionalShareUpdateService,
  KalgudiShareWithService,
  SocialDataNormalizerService,
} from '@kalgudi/social';
import {
  ActivityType,
  AvailableStoreTypes,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiPageDetails,
  KalgudiProjectTask,
  KalgudiUser,
  TaskShareUpdate,
  TaskShareUpdateRequest,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize } from 'rxjs/operators';

import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class kalgudiTaskPromotionalShareForm extends KalgudiSocialShare<TaskShareUpdateRequest, TaskShareUpdate> {

  @Input()
  activityTypes: ActivityType;

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  pageId: string;

  activityType = ActivityType;

  shareForm: FormGroup;

  skuSelection = new FormControl('');
  pageDetails: KalgudiPageDetails;
  selectedSku: any;

  level2Id: string;
  level2Initialized: any;
  level2Details: any;
  dosageDetails: any;
  dosageCropDetails: any;

  productDetailsProgress: boolean;
  kuberPageId: string;

  selectedStoreType: string;
  adminProfile: KalgudiUser;
  projectDetails: any;
  pageDetailsInfo: any;

  private promotionalShareUpdateService: KalgudiPromotionalShareUpdateService;
  private env: KalgudiEnvironmentConfig;
  private taskService: KalgudiTasksService;

  private projectService: KalgudiProjectService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiShareWithService: KalgudiShareWithService,
    protected sharePostService: KalgudiShareUpdateService,
    protected mobileMenuService: KalgudiBottomSheetService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      mobileMenuService
    );
    this.taskService                   = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.projectService                = this.injector.get(KalgudiProjectService);

    this.promotionalShareUpdateService = this.injector.get(KalgudiPromotionalShareUpdateService);
    this.env                           = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    // Initialize share update form
    this.shareForm = this.programShareUpdateForm;

    this.kuberPageId = this.env.production ? '054c' : '01bt';
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share update common form
   */
  private get programShareUpdateForm(): FormGroup {

    return new FormGroup({

      // Common fields inherited from share update
      authorId: new FormControl('', Validators.required),
      shareText: new FormControl('', Validators.required),
      richShareText: new FormControl(''),
      text: new FormControl(''),
      lstOfAttachments: new FormControl([]),
      assistedTo: new FormControl(''),

      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(''),
      scheduledTS: new FormControl(''),
      sharedTo: new FormControl(this.shareVisibilityList[0].value, Validators.required),

      crop: new FormControl(''),
      promotionalTitle: new FormControl('', Validators.required),

      // Program specific fields
      recipient: new FormControl(this.shareVisibilityList[1].value, Validators.required),
      entityId: new FormControl('', Validators.required),
      entityName: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isAuthor: new FormControl(false, Validators.required),
      msgTypes: new FormControl([], Validators.required),
      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),
        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        pageUrl: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
        isSms: new FormControl(''),
      }),
      isPublishedThroughTool: new FormControl(true),
      productsMetaData: new FormControl([], Validators.required)
    });
  }

  /**
   * Share form author id field
   */
  get shareFormProductsMetaData(): AbstractControl {
    return this.shareForm.get('productsMetaData');
  }

  /**
   * Share form author id field
   */
  get shareFormAuthorId(): AbstractControl {
    return this.shareForm.get('authorId');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form shared to field
   */
  get shareFormSharedTo(): AbstractControl {
    return this.shareForm.get('sharedTo');
  }

  /**
   * Share form program entity id field
   */
  get shareFormEntityId(): AbstractControl {
    return this.shareForm.get('entityId');
  }

  /**
   * Share form program entity name field
   */
  get shareFormEntityName(): AbstractControl {
    return this.shareForm.get('entityName');
  }

  /**
   * Share form program is admin field
   */
  get shareFormIsAdmin(): AbstractControl {
    return this.shareForm.get('isAdmin');
  }

  /**
   * Share form program is author field
   */
  get shareFormIsAuthor(): AbstractControl {
    return this.shareForm.get('isAuthor');
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * Getter for is sms field.
   */
  get shareFormIsSms(): AbstractControl {
    return this.shareFormFilter.get('isSms');
  }

  /**
   * Getter for page id field.
   */
  get shareFormPageId(): AbstractControl {
    return this.shareFormFilter.get('pageId');
  }

  /**
   * Getter for page title field.
   */
  get shareFormPageTitle(): AbstractControl {
    return this.shareFormFilter.get('pageTitle');
  }

  /**
   * Getter for page type field.
   */
  get shareFormPageType(): AbstractControl {
    return this.shareFormFilter.get('pageType');
  }

  /**
   * Getter for page url field.
   */
  get shareFormPageUrl(): AbstractControl {
    return this.shareFormFilter.get('pageUrl');
  }

  /**
   * Getter for parent page id field.
   */
  get shareFormParentPageId(): AbstractControl {
    return this.shareFormFilter.get('parentPageId');
  }

  /**
   * Getter for parent page title field.
   */
  get shareFormParentPageTitle(): AbstractControl {
    return this.shareFormFilter.get('parentPageTitle');
  }

  /**
   * Getter for parent page url field.
   */
  get shareFormParentPageUrl(): AbstractControl {
    return this.shareFormFilter.get('parentPageUrl');
  }

  /**
   * Getter for promotional title field.
   */
  get shareFormPromotionalTitle(): AbstractControl {
    return this.shareForm.get('promotionalTitle');
  }

  /**
   * Share form msgTypes field
   */
  get shareFormMsgTypes(): AbstractControl {
    return this.shareForm.get('msgTypes');
  }


  /**
   * Returns true if the advisories tag is selected else returns false
   */
  get isAdvisorySelected(): boolean {
    return Array.isArray(this.shareFormMsgTypes.value)
      ? this.shareFormMsgTypes.value.includes('ADVISORIES')
      :  false;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {

    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);

      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets the program share update form
   *
   * @override
   */
  resetForm() {
    // Honour all parent actions
    super.resetForm();
    this.audioAttachment.patchValue(null);
    this.level2Details = '';
    this.selectedSku = {};
    this.level2Initialized = '';
    this.shareFormProductsMetaData.reset();

    // Patch page details after resetting page form
    this.patchProgramDetails(this.pageDetailsInfo);
  }

  /**
   * Fetch product level2 details
   * @param level2Id
   */
  getProductLevel2(level2Id: string, storeType: string) {

    this.productDetailsProgress = true;
    this.level2Details = '';

    this.promotionalShareUpdateService.getProductLevel2(level2Id, storeType)
      .pipe(
        finalize(() => this.productDetailsProgress = false)
      )
      .subscribe(
        (res: any) => {

          res.level3ProductsList = res.level3ProductsList.filter(item => item.isBulkOrder === false && item.isPublished === true && item.mrp && item.pricePerUnit)

          this.level2Details = res;

          if(this.level2Details && this.level2Details.dosage && this.level2Details.dosage.length) {

            this.dosageCropDetails = this.level2Details.dosage.forEach(item => {
              if( this.projectId === item.crop.productId) {
                this.dosageDetails = item;
              }

            })
          }

          this.level2Initialized = this.level2Id;
        }
      )
  }

  /**
   * Fetch page details
   * @param pageId
   */
  getPageDetails(pageId: string) {
    this.kalgudiShareWithService.getPage(pageId).subscribe(
      res => {
        this.pageDetailsInfo = res;
        this.patchProgramDetailsToForm();
      }
    );
  }

  /**
   * Select sku
   */
  selectSKu(selectedSku) {
    this.selectedSku = selectedSku;
  }

  /**
   * To open the promotional product description dialog
   */
  openPromotionalProductDescriptionDialog() {
    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: '',
      data: {
        description: this.level2Details.description
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    // Show promotional product description dialog
    return this.showPromotionalProductDescriptionDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Assigning selected store on changed store type
   * @param value
   */
  onChangedStoreType(value: string) {
    this.selectedStoreType = value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the program details and binding the details to the share form
   */
  private patchProgramDetailsToForm(): void {
    this.patchProgramDetails(this.pageDetailsInfo)
  }

  /**
   * Update project details to the share form
   */
  protected updateProjectDetails(projectId: string) {

    this.projectService.getProject(projectId)
      .subscribe(
        res => {
          this.projectDetails = res
        },
        err => this.notifications.showMessage('Unable to load task template.'),
      );
  }


  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    // Required fields
    this.shareFormAuthorId.patchValue(this.kalgudiApp.profileLocal.profileKey);
    this.shareFormVisibility.patchValue(this.shareVisibilityList[1].value);
    this.shareFormSharedTo.patchValue(this.shareVisibilityList[0].value);
    this.shareFormMsgTypes.patchValue('PRODUCT');
    this.shareFormIsSms.patchValue(false);

    if(this.pageDetails) {
      this.pageDetails = pageDetails;

      this.pageId = pageDetails.pageId;

      this.shareFormEntityId.patchValue(this.pageDetails.pageId);
      this.shareFormEntityName.patchValue(this.pageDetails.pageTitle);

      // Filters
      this.shareFormIsSms.patchValue(false);
      this.shareFormPageId.patchValue(pageDetails.pageId);
      this.shareFormPageTitle.patchValue(pageDetails.pageTitle);
      this.shareFormPageType.patchValue(pageDetails.pageType);
      this.shareFormPageUrl.patchValue(pageDetails.pageProfilePic);
      this.shareFormParentPageId.patchValue(pageDetails.pageId);
      this.shareFormParentPageTitle.patchValue(pageDetails.pageTitle);
      this.shareFormParentPageUrl.patchValue(pageDetails.pageProfilePic);
    }
  }

  /**
   * Creating payload
   * @param shareFormValue
   * @returns
   */
  protected getCreationPayload(shareFormValue: any) {

    if (this.urlFormControl.value && this.urlFormControl.value.url) {

      shareFormValue.uri = this.urlFormControl.value.url;
      shareFormValue.domain = this.urlFormControl.value.domain;
      shareFormValue.uriImage = this.urlFormControl.value.image;
      shareFormValue.uriTitle = this.urlFormControl.value.title;
    }

    if (shareFormValue.scheduledDate && shareFormValue.scheduledTime ) {

      const date = new Date(shareFormValue.scheduledDate);

      const dateAndTime = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${shareFormValue.scheduledTime}`;

      shareFormValue.scheduledTS = new Date(dateAndTime).toISOString();
    }


    const formValue: any = this.util.clone({
      ...shareFormValue
    });

    const skuData = {
      createdTS: this.selectedSku.createdTS,
      LUT: this.selectedSku.LUT,
      productPicURL_level2: this.level2Details.attachments[0] && this.level2Details.attachments[0].attachments[0] && this.level2Details.attachments[0].attachments[0].url ? this.level2Details.attachments[0].attachments[0].url : '',
      productId_level2: this.selectedSku.productLevel2Id,
      masterLevel2Id: '',
      masterLevel2ShortId: '',
      productName_level2: this.selectedSku.productLevel2Title,
      description: this.selectedSku.description,
      subCategories: '',
      sellerName: ((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm')) ? shareFormValue.productsMetaData.resellerDetails.firstName : shareFormValue.productsMetaData.manufacturerDetails.firstName,
      sellerLocation: ((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm')) ? shareFormValue.productsMetaData.resellerDetails.location : shareFormValue.productsMetaData.manufacturerDetails.location,
      sellerId: ((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm')) ? shareFormValue.productsMetaData.resellerDetails.profileKey : shareFormValue.productsMetaData.manufacturerDetails.profileKey,
      // manufacturerName: shareFormValue.productsMetaData.manufacturerDetails.firstName,
      // manufacturerLocation: shareFormValue.productsMetaData.manufacturerDetails.location,
      // manufacturerId: shareFormValue.productsMetaData.manufacturerDetails.profileKey,
      productPicURL_level3: this.selectedSku.attachments && this.selectedSku.attachments.attachments[0] &&  this.selectedSku.attachments.attachments[0].url ? this.selectedSku.attachments.attachments[0].url : '',
      productId_level3: this.selectedSku.productLevel3Id,
      level2ShortId: '',
      level3ShortId: this.selectedSku.level3ShortId,
      productName_level3: this.selectedSku.productLevel3Title,
      pricePerSKU: this.selectedSku.pricePerUnit,
      SKUSmartElements: this.selectedSku.smartElements,
      mrp: this.selectedSku.mrp,
      approxDeliveryTime: this.level2Details.approxDeliveryTime,
      level2published: this.level2Details.isPublished,
      level3published: this.selectedSku.isPublished,
      stores: [this.selectedStoreType],
      baseProductName: this.level2Details.baseProduct.productName,
      isBulkOrder: this.selectedSku.isBulkOrder,
      selectedSKU: this.level2Details.selectedSKU,
      shipping: this.selectedSku.shipping,
      freeShippingEligible: this.selectedSku.freeShippingEligible,
      crops: (shareFormValue.crop || this.level2Details.crop) ? (shareFormValue.crop || this.level2Details.crop) : [],
      storeType: AvailableStoreTypes[this.selectedStoreType],
      dosage: this.dosageDetails ? this.dosageDetails : [],
    }


    const activityDetailsObj = {
      text: formValue.shareText,
      lstOfAttachments: formValue.lstOfAttachments,
      uri: this.urlFormControl.value && this.urlFormControl.value.url ? this.urlFormControl.value.url : '',
      domain: this.urlFormControl.value && this.urlFormControl.value.domain ? this.urlFormControl.value.domain : '',
      uriImage: this.urlFormControl.value && this.urlFormControl.value.image ? this.urlFormControl.value.image : '',
      uriTitle: this.urlFormControl.value && this.urlFormControl.value.title ? this.urlFormControl.value.title : '',
      productsMetaData: skuData ? [skuData] : [],
      shareType: 'PRODUCT',
      msgTypes: Array.isArray(formValue.msgTypes) ? formValue.msgTypes : [formValue.msgTypes],
      promotionalTitle: formValue.promotionalTitle,
      sharedTo: formValue.sharedTo,
      scheduledDate: formValue.scheduledDate,
      scheduledTime: formValue.scheduledTime,
      recipient: formValue.recipient,
      scheduledTS: shareFormValue.scheduledTS,
    }

    const payload: any = {
      activityDetails: activityDetailsObj,
      assistedTo: formValue.assistedTo
    };

    //Check selected profile and logged in user profile
    if (payload.assistedTo && payload.assistedTo.profileKey === this.adminProfile.profileKey) {
      delete payload.assistedTo;
    }

    return payload;
  }

  /**
   * Show promotional product description dialog
   **/
  private showPromotionalProductDescriptionDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.kalgudiDialogService.openDialog(KalgudiPromotionalProductDescriptionDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Calls API to share the current post.
   */
  createPostApi(payload: TaskShareUpdateRequest): Observable<TaskShareUpdate> {

    const activityType = this.activityTypes ? this.activityTypes : this.activityType.COMMENT;

    const submissionId = this.activityTypes === this.activityType.MEMBER_UPDATES ? this.submissionDetails.activityId : '';

    // Call api to share post
    return this.taskService.createActivity(this.projectId, this.taskId, activityType, payload, submissionId);

  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
