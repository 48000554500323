import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { Attachment, AttachmentList, FileMimeTypes, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { FpoProfiles } from '../../../classes/fpo-profiles';
import { OrganisationProfileService } from '../../../services/organisation-profile.service';

@Component({
  selector: 'kl-social-form',
  templateUrl: './social-form.component.html',
  styleUrls: ['./social-form.component.scss']
})
export class SocialFormComponent extends FpoProfiles implements OnInit {

  disableAttach: boolean = false;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  entities = [
    {id:0, value: 'National Bank For Agriculture And Rural Development (NABARD)'},
    {id:1, value: 'Small Farmers Agribusiness Consortium (SFAC)'},
    {id:2, value: 'National Agricultural Cooperative Marketing Federation of India Ltd. (NAFED)'},
    {id:3, value: 'Society For Elimination of Rural Poverty (SERP)'},
    {id:4, value: 'State government (Agriculture)'},
    {id:5, value: 'State government (Horticulture)'},
    {id:6, value: 'State government (Animal husbandry)'},
    {id:7, value: 'State government (Fisheries)'},
    {id:8, value: 'State government (Allied)'},
    {id:9, value: 'Self promoted'},
    {id:10, value: 'Others'}
  ]

  constructor(protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    private organizationProfileService: OrganisationProfileService,
    ) {
    super(injector)

    // Changing logo on value change for promoting institute
    this.getPromotingInstitutionLogoName.valueChanges.subscribe((val) => {
      this.addLogoPromoting(val);
    })
  }

  ngOnInit(): void { }

  /**
   * Automatically attach the preferred logo based on condition
   * @param val
   */
  addLogoPromoting(val: string) {
    // Below object logoMappings { key => logo name : value => url of the logo in kalgudi s3 bucket }
    const logoMappings: Record<string, string> = {
      'National Bank For Agriculture And Rural Development (NABARD)': '/data/activity/share-a-thought/1697517270121.png',
      'Small Farmers Agribusiness Consortium (SFAC)': '/data/activity/share-a-thought/1697517497337.png',
      'National Agricultural Cooperative Marketing Federation of India Ltd. (NAFED)': '/data/activity/share-a-thought/1697517407271.png',
      'Society For Elimination of Rural Poverty (SERP)': '/data/activity/share-a-thought/1697517451570.png',
    };

    const attachment = logoMappings[val] || '';
    this.disableAttach = attachment !== ''
    this.promotionOfAttachments.patchValue(attachment === '' ? [] : [attachment]);
  }


  /**
   * Removes an promotion image
   */
  removePromotionImage(index: number) {
    if (this.disableAttach) return;
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this promoting institute logo');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        const attachments: AttachmentList = this.promotionOfAttachments.value || [];
        attachments.splice(index, 1);
        this.promotionOfAttachments.patchValue(attachments);
      }
    });

  }

  /**
   * Removes an resource image
   */
  removeResourceImage(index: number) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this resource image');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        const attachments: AttachmentList = this.resourceOfAttachments.value || [];
        attachments.splice(index, 1);
        this.resourceOfAttachments.patchValue(attachments);
      }
    });

  }

  /**
   * Removes an team photo image
   */
  removePhotoImage(index: number) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this team image');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        const attachments: AttachmentList = this.teamPhotoOfAttachments.value || [];
        attachments.splice(index, 1);
        this.teamPhotoOfAttachments.patchValue(attachments);
      }
    });

  }

  /**
   * Removes an entity logo image
   */
  removeEntityPhotoImage(index: number) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this entity logo image');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        const attachments: AttachmentList = this.entityLogoOfAttachments.value || [];
        attachments.splice(index, 1);
        this.entityLogoOfAttachments.patchValue(attachments);
      }
    });
  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: Attachment[], index?: number): void {
    let urlDomain: string = `https://kalgudidata.s3.amazonaws.com${attachments[0]}`
    const attachmentObj: any = {
      url: urlDomain
    }
    const fullViewImage: Attachment[] = [attachmentObj];
    this.kalgudiPhotoswipe.open(fullViewImage, this.environment.domain, index).subscribe();
  }

  /**
   * Getter for socialInfo official-website-link
   */
  get getOfficialWebsiteValue(): FormGroup {
    return this.socialInfoGroup.get('officialWebsiteLink') as FormGroup;
  }
  /**
   * Getter for socialInfo official-website-link
   */
  get getFacebookWebsiteValue(): FormGroup {
    return this.socialInfoGroup.get('facebookLink') as FormGroup;
  }
  /**
   * Getter for socialInfo official-website-link
   */
  get getTwitterWebsiteValue(): FormGroup {
    return this.socialInfoGroup.get('twitterLink') as FormGroup;
  }
  /**
   * Getter for socialInfo official-website-link
   */
  get getInstagramWebsiteValue(): FormGroup {
    return this.socialInfoGroup.get('instagramLink') as FormGroup;
  }

  /**
   * Getter for promoting institution group
   */
  get promotingInstitutionGroup(): FormGroup {
    return this.socialDetailsGroup.get('promotingInstitution') as FormGroup;
  }

  /**
   * Getter for resource institution group
   */
  get resourceInstitutionGroup(): FormGroup {
    return this.socialDetailsGroup.get('resourceInstitution') as FormGroup;
  }

  /**
   * Getter for team photo group
   */
  get teamPhotoGroup(): FormGroup {
    return this.socialDetailsGroup.get('teamPhoto') as FormGroup;
  }

  /**
   * Getter for team photo name field
   */
  get getTeamPhotoName(): FormGroup {
    return this.teamPhotoGroup.get('teamPhotoName') as FormGroup;
  }

  /**
   * Getter for resource Institution logo name field
   */
  get getResourceInstitutionLogoName(): FormGroup {
    return this.resourceInstitutionGroup.get('resourcingInstitutionLogoName') as FormGroup;
  }

  /**
   * Getter for promoting institution logo name field
   */
  get getPromotingInstitutionLogoName(): FormGroup {
    return this.promotingInstitutionGroup.get('promotingInstitutionLogoName') as FormGroup;
  }


  onDestroyed(): void {}
}
