import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import { KalgudiSurveyAddMembers } from '../../classes/kalgudi-survey-add-members';

@Component({
  selector: 'kl-survey-members-add',
  templateUrl: './survey-members-add.component.html',
  styleUrls: ['./survey-members-add.component.scss']
})
export class SurveyMembersAddComponent extends KalgudiSurveyAddMembers implements OnInit {


  @Output()
  addedMembers = new EventEmitter();

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Event emit back to parent after adding members successfully
   */
  onMembersAdditionSuccess() {
    this.addedMembers.emit();
  }
}
