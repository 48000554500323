import { Component, Injector, OnInit } from '@angular/core';

import { PageActivityFilters } from './page-activity-filters';

@Component({
  selector: 'kl-page-activity-filters',
  templateUrl: './page-activity-filters.component.html',
  styleUrls: ['./page-activity-filters.component.scss']
})
export class PageActivityFiltersComponent extends PageActivityFilters implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

}
