import { Injector } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { PageFiltersService } from '@kalgudi/pages-shared';
import { IdValueMap } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { PageShareFiltersService } from '../../program-target-members/services/page-share-filters.service';

export abstract class KalgudiAddPageProjectMembers {

  filterFormGroup: FormGroup;

  memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }>;

  protected targetedMetaService: PageShareFiltersService;
  private filtersService: PageFiltersService;

  constructor(
    protected injector: Injector

  ) {

    // Inject all dependencies
    this.targetedMetaService = this.injector.get(PageShareFiltersService);
    this.filtersService = this.injector.get(PageFiltersService);

    this.memberTargetingFilters$ = this.filtersService.memberTargetingFilters$;

    this.filterFormGroup = this.filtersForm;
  }

  /**
   * Users filters field
   */
  get usersFilters(): FormArray {
    return this.filterFormGroup.get('users') as FormArray;
  }

  /**
   * Products filters field
   */
  get productsFilters(): FormArray {
    return this.filterFormGroup.get('products') as FormArray;
  }

  /**
   * Business types filter field
   */
  get businessTypesFilters(): FormArray {
    return this.filterFormGroup.get('businessTypes') as FormArray;
  }

  /**
   * Locations filter field
   */
  get locationsFilters(): FormArray {
    return this.filterFormGroup.get('locations') as FormArray;
  }

  /**
   * Countries filter field
   */
  get countriesFilters(): FormArray {
    return this.filterFormGroup.get('countries') as FormArray;
  }

  /**
   * States filter field
   */
  get statesFilters(): FormArray {
    return this.filterFormGroup.get('states') as FormArray;
  }

  /**
   * Districts filter field
   */
  get districtsFilters(): FormArray {
    return this.filterFormGroup.get('districts') as FormArray;
  }

  /**
   * Form
   */
  private get filtersForm(): FormGroup {

    return new FormGroup({

      users: new FormControl([]),
      products: new FormArray([]),
      businessTypes: new FormArray([]),
      locations: new FormArray([]),
      countries: new FormArray([]),
      states: new FormArray([]),
      districts: new FormArray([]),
    });
  }


}
