import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';

import { KalgudiSurveySubmissionModule } from '../survey-submission/survey-submission.module';
import { SurveyLogsStreamComponent } from './components/survey-logs-stream/survey-logs-stream.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SurveyLogsStreamComponent,
  ],
  imports: [
    CommonModule,

    // Material
    MatCardModule,
    FlexLayoutModule,
    TranslateModule,

    // Kalgudi libraries
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiSurveySubmissionModule
  ],
  exports: [
    SurveyLogsStreamComponent,
  ]
})
export class KalgudiSurveyLogsListModule { }
