import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kl-post-tile-tag',
  templateUrl: './kalgudi-post-tile-tag.component.html',
  styleUrls: ['./kalgudi-post-tile-tag.component.scss']
})
export class KalgudiPostTileTagComponent implements OnInit {

  @Input()
  tags: string[];

  constructor() { }

  ngOnInit() {
  }

}
