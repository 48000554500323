<section id="land-details">
  <!-- Land details -->
  <kl-land-details [isLandEditable]="dialogData?.data?.hideActions" [details]="dialogData?.data?.landDetails" (openLandDetailsDialog)="openLandDetailsEditDialog()"></kl-land-details>
  <!-- Land details -->
  <kl-land-details-agro-tagging
    [isLandEditable]="dialogData?.data?.hideActions"
    [details]="dialogData?.data?.landDetails"
    (seasonEdit)="openSeasonEditDialog($event)"
    [assistedProfileKey]="dialogData?.data?.assistedProfileKey">
  </kl-land-details-agro-tagging>
</section>
