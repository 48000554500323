import { MatDialogConfig } from '@angular/material/dialog';

/**
 * Define kalgudi dialog constants
 */

export const DEFAULT_DIALOG_CONFIG: MatDialogConfig<any> = {
  width: '500px',
  panelClass: 'kalgudi-dialog',
  hasBackdrop: true,
  autoFocus: true,
  restoreFocus: true
};
