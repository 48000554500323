import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiDestroyable } from '../classes/kalgudi-destroyable';

/**
 * Hides extra contents of a text element. Shows content up to a specified line.
 * It works based on `lineHeight` input and number of `visibleLine` i.e. number of
 * lines to show.
 *
 * It calculates the effective height of the element and based on the line height and
 * number of lines to show it adds a class `hide-content` also sets the `max-height`
 * property to the element to restrict the element height.
 *
 * The `hide-content` class adds "_...Show more_" content to the `::after` pseudo element.
 *
 * **Note** The directive is dependent on two CSS classes.
 * ```
 * .text-content {
 *   font-size: 13px;
 *   line-height: 18px;
 *   max-width: 300px;
 *   overflow: hidden;
 * }
 *
 * .hide-content {
 *   position: relative;
 *
 *   &::after {
 *     content: "...See more";
 *     position: absolute;
 *     font-weight: 600;
 *     letter-spacing: .1px;
 *     color: $kl-link-color;
 *     cursor: pointer;
 *     right: 0;
 *     bottom: 0;
 *     background: linear-gradient(90deg, transparent, #e8e9ed, #e8e9ed, #e8e9ed, #e8e9ed);
 *     padding-left: 24px;
 *
 *     &:hover {
 *       color: $kl-link-hover-color;
 *     }
 *   }
 * }
 * ```
 *
 * @example
 * ```
 * <p class="text-content" klHideContent lineHeight="18" visibleLine="2">
 * Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, ullam. Suscipit
 * possimus soluta accusamus animi officiis hic obcaecati dolor impedit qui quaerat
 * atque perspiciatis, velit nobis nulla nisi provident corrupti.
 * </p>
 * ```
 * @author Pankaj Prakash
 */
@Directive({
  selector: '[klHideContent]',
})
export class HideContentDirective extends KalgudiDestroyable implements OnInit, AfterViewInit {


  // --------------------------------------------------------
  // #region Getter and Setters
  // --------------------------------------------------------

  /**
   * Element upon which directive is applied
   */
  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  /**
   * Height of the element
   */
  private get elemHeight(): number {
    return this.element.offsetHeight;
  }

  @Input()
  lineHeight: number;

  @Input()
  visibleLine: number;

  /**
   * Constant for hide class
   */
  private readonly HIDE_CONTENT_CLASS = 'hide-content';

  private contentHidden: boolean;
  private clickListenerHandler: () => void;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService,
  ) {
    super();
  }

  ngOnInit(): void { }

  /**
   * Called after ngAfterContentInit when the component's view has been initialized.
   * Applies to components only.
   */
  ngAfterViewInit(): void {
    this.initialize();
  }

  onDestroyed() { }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Initializes hide content directive
   */
  private initialize(): void {

    // Check if content should be hidden or not
    this.contentHidden = this.canHideContent(this.elemHeight, this.lineHeight, this.visibleLine);

    // Add hide content class if content should be hidden
    if (this.contentHidden) {
      this.hideContent(this.element);

      // Attach click event listener to the element
      this.clickListenerHandler = this.attachListener(this.element);
    }
  }

  /**
   * Attaches click event listener to the element to show content.
   *
   * @returns Listener handler reference
   */
  private attachListener(element: HTMLElement): () => void {

    return this.renderer.listen(element, 'click', (e: Event) => {
      this.showContent(element);
      e.preventDefault();
      e.stopPropagation();
    });
  }

  /**
   * De attaches click event listener
   */
  private deAttachListener(listener: () => void): void {
    listener();
  }

  /**
   * Checks if the element has more text that overflows the specified visible line
   * or not. Content should be hidden if the element height is more than specified
   * visible lines.
   *
   * @returns `true` if content should be hidden, otherwise `false`.
   */
  private canHideContent(height: number, lineHeight: number, visibleLine: number): boolean {

    return (height > this.getVisibleElemHeight(lineHeight, visibleLine));
  }

  /**
   * Returns the visible element height.
   */
  private getVisibleElemHeight(lineHeight: number, visibleLine: number): number {
    return lineHeight * visibleLine;
  }

  /**
   * Hides the specified element up to a specified lines. It adds max-height
   * to the element.
   */
  private hideContent(element: HTMLElement): void {

    // Add hide-content class
    this.renderer.addClass(element, this.HIDE_CONTENT_CLASS);

    // Add max-height to the element
    const maxHeight = this.getVisibleElemHeight(this.lineHeight, this.visibleLine) + 'px';
    this.renderer.setStyle(element, 'max-height', maxHeight);

    // Raise content hidden flag
    this.contentHidden = true;

    this.setElementTextualContent(element);
  }

  /**
   * Updates the text to show on the element based on the latest translation literal
   */
  private setElementTextualContent(element: HTMLElement): void {

    // Set default text
    this.renderer.setAttribute(element, 'data-content', `...See more`);

    this.translate.stream('See more')
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(val => {
        this.renderer.setAttribute(element, 'data-content', `...${val}`);
      });
  }

  /**
   * Shows the hidden element content. It removes hide-element class and resets
   * max-height of the element to auto.
   */
  private showContent(element: HTMLElement): void {

    // Assert if can hide content
    if (!this.contentHidden) {
      return;
    }

    // Remove hide-content class
    this.renderer.removeClass(element, this.HIDE_CONTENT_CLASS);

    // Remove max-height style from the element
    this.renderer.removeStyle(element, 'max-height');

    // De attach click event listener
    this.deAttachListener(this.clickListenerHandler);

    // Raise content hidden flag
    this.contentHidden = false;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
