import { Component, Input, OnInit } from '@angular/core';
import { KalgudiChartItem, UIElementSize } from '@kalgudi/types';

@Component({
  selector: 'kl-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent implements OnInit {

  @Input()
  size: UIElementSize = 'sm';

  @Input()
  label: string;

  @Input()
  chip: KalgudiChartItem;

  constructor() { }

  ngOnInit() {
  }

}
