import { Directive, Injector } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiUser } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { ProfilePageActions } from '../../../constants';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import {
  EditCommonProfileDetailsDialogService,
} from '../../profile-basic-edit/services/edit-common-profile-details-dialog.service';

@Directive()
export abstract class KalgudiFarmerProfileBasicDetails extends KalgudiDestroyable {

  profile: KalgudiUser;
  editable: boolean;

  pageId: string;
  isDigitalAssist: boolean;
  loginId: string;

  private profileState: KalgudiProfileStateService;
  private profileService: KalgudiProfileService;
  private profileBasicDetailsEditDialogService: EditCommonProfileDetailsDialogService;

  constructor(protected injector: Injector) {

    super();

    this.profileState                        = this.injector.get(KalgudiProfileStateService);
    this.profileService                      = this.injector.get(KalgudiProfileService);
    this.profileBasicDetailsEditDialogService = this.injector.get(EditCommonProfileDetailsDialogService);

    this.pageId = this.profileState.pageId;

    this.isDigitalAssist = this.pageId ? true : false;

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.profile = profile;
          this.getLoginId();
        }
      );

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );


  }


  /**
   * Uploads the file to the s3
   * @param fileEvent
   */
  uploadProfilePic(fileEvent: any) {
    this.profileState.dispatchAction(ProfilePageActions.UPDATE_PROFILE_PIC, fileEvent);
  }

  editBasicProfileDetails(): void {
    this.profileState.dispatchAction(ProfilePageActions.SHOW_COMMON_PROFILE_EDIT_DIALOG);
  }

  /**
   * Calls api to get the login id
   */
  getLoginId() {
    this.profileService.getLoginId()
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.loginId = res.loginId;
      });
  }

  /**
   * Shows Edit login id dialog
   */
  showEditLoginIdDialog() {

    this.profileBasicDetailsEditDialogService.showEditLoginIdDialog(this.loginId)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }


}
