import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiImageChipListModule } from '../kalgudi-image-chip-list/kalgudi-image-chip-list.module';
import { KalgudiLoadMoreModule } from '../load-more/load-more.module';
import { CommonSearchBarModule } from '../search/common-search-bar.module';
import { KalgudiNoContentModule } from './../kalgudi-no-content/kalgudi-no-content.module';
import { SelectedStoreProductsComponent } from './components/selected-store-products/selected-store-products.component';
import { ShareProductsTileComponent } from './components/share-products-tile/share-products-tile.component';
import { ShareProductsComponent } from './components/share-products/share-products.component';
import { ShareUpdateProductsComponent } from './components/share-update-products/share-update-products.component';
import { StoreProductTileComponent } from './components/store-product-tile/store-product-tile.component';
import {
  StoreProductsFormControlComponent,
} from './components/store-products-form-control/store-products-form-control.component';
import { StoreProductsListComponent } from './components/store-products-list/store-products-list.component';
import {
  StoreProductsSearchDialogComponent,
} from './components/store-products-search-dialog/store-products-search-dialog.component';
import {
  StoreProductsSearchMobileDialogComponent,
} from './components/store-products-search-mobile-dialog/store-products-search-mobile-dialog.component';
import { StoreProductsSearchApiService } from './services/store-products-search-api.service';
import { StoreProductsSearchService } from './services/store-products-search.service';

@NgModule({
  declarations: [
    StoreProductTileComponent,
    StoreProductsSearchDialogComponent,
    StoreProductsSearchMobileDialogComponent,
    ShareProductsComponent,
    SelectedStoreProductsComponent,
    StoreProductsFormControlComponent,
    StoreProductsListComponent,
    ShareProductsTileComponent,
    ShareUpdateProductsComponent
  ],
  imports: [
    CommonModule,

    // Material
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatChipsModule,

    AngularSvgIconModule,

    // Flex
    FlexLayoutModule,

    // Kalgudi modules
    KalgudiPipesModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiImageChipListModule,
    CommonSearchBarModule,

    TranslateModule
  ],
  entryComponents: [
    StoreProductsSearchDialogComponent,
    StoreProductsSearchMobileDialogComponent,
  ],
  providers: [
    StoreProductsSearchService,
    StoreProductsSearchApiService
  ],
  exports: [
    StoreProductsFormControlComponent,
    SelectedStoreProductsComponent,
    ShareUpdateProductsComponent
  ]
})
export class KalgudiStoreProductsSearchModule { }
