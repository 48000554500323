import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiCoreRouteConfig, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublicPageGuard implements CanActivate, CanActivateChild {

  constructor(
    @Inject(KL_ROUTE_CONFIG) private kalgudiRouteConfig: KalgudiCoreRouteConfig,
    private kalgudiApp: KalgudiAppService,
    private router: Router,
  ) {}


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.handleGuard(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.handleGuard(childRoute, state);
  }

  /**
   * Handle auth guard protected pages
   */
  private handleGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // Route page to on guard failure page
    if (this.kalgudiApp.loggedIn) {
      const url = state.url.replace('/public/', '/app/');
      this.router.navigateByUrl(url);
    }

    return !this.kalgudiApp.loggedIn;
  }
}
