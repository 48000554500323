<div class="mobile-menu-bottom-sheet">

  <!-- Title -->
  <!-- <header class="w-100 p-2 position-relative" fxLayout="row" *ngIf="data?.title">
    <div class="mt-2 w-100" fxLayoutAlign="center center">
      <span class="mb-0 header">{{ data?.title | translate }}</span>
    </div>

    <div class="closed position-absolute">
      <button mat-icon-button class="close-button" (click)="close()">
        <mat-icon class="mt-n4 mb-n1">clear</mat-icon>
      </button>
    </div>
  </header> -->
  <!-- /Title -->

  <!-- Mobile menu grid view -->
  <div fxLayout="row" fxLayoutAlign="space-between center" class="p-3 kl-bottom-sheet"
    *ngIf="data?.styleType === 'icon-button'">
    <div *ngFor="let item of data?.menu" (click)="close(item)">
      <div fxLayout="column" fxLayoutAlign="center center">

        <button color="primary" mat-menu-item mat-mini-fab class="fab-buttons"
          [ngStyle]="{ 'background-color': item?.iconColor }"
          [disabled]="item?.disabled">
          <mat-icon class="text-white" *ngIf="item?.matIcon">{{ item?.matIcon }}</mat-icon>
          <svg-icon *ngIf="item?.svgIcon" [applyCss]="true" class="text-center" [src]="item?.svgIcon"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
        </button>

        <div>
          <p class="mb-0">{{ item?.title | translate }}</p>
        </div>

      </div>
    </div>
  </div>
  <!-- /Mobile menu grid view -->

  <!-- Mobile menu list view -->
  <div class="mobile-tab position-relative" *ngIf="data?.styleType === 'list'" [ngClass]="{'share-event-container': data?.title === 'Share event on social media'}">

    <div class="px-2 py-1">
      <button class="share-update w-100 bottom-sheet-button" fxLayout="row" mat-button [ngClass]="{'border-top': i !== 0 && !menu?.visible}"
        *ngFor="let menu of data?.menu; let i=index" (click)="close(menu)"
        [disabled]="menu?.disabled">
        <ng-container *ngIf="!menu?.hide">
          <div class="py-2">

            <mat-icon class="mr-1" fxFlex="50px" [ngStyle]="{'color': menu?.disabled ? 'rgba(0, 0, 0, 0.26)' : menu?.iconColor}" *ngIf="menu?.matIcon">
              {{ menu?.matIcon }}</mat-icon>
            <svg-icon *ngIf="menu?.svgIcon" fxFlex="40px" [applyCss]="true" class="text-center mr-1 mt-1" [src]="menu?.svgIcon"
            [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': menu?.iconColor }"></svg-icon>
            <i class="{{ menu?.faIcon }} mr-2" *ngIf="menu?.faIcon" [ngStyle]="{'color': menu?.iconColor}"></i>
            <span class="info">{{ menu?.title | translate }}</span>
          </div>
        </ng-container>
      </button>
    </div>
    <button mat-icon-button class="close-bottomsheet position-absolute" *ngIf="data?.showCloseButton" (click)="close()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <!-- Mobile menu list view -->

  <!-- Mobile description view -->
  <div fxLayout="column" class="description-sheet" *ngIf="data?.styleType === 'textContent'">
    <div fxLayout fxLayoutAlign="end start" class="clear-btn">
      <!-- <button class="close-dialog-btn outline-none" mat-icon-button (click)="close()">
        <mat-icon>clear</mat-icon>
      </button> -->
      <button mat-icon-button class="remove-button remove-circle" (click)="close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>

    <div class="description">
      <span class="product-description" translate>Product description</span>
      <p class="text-justify">{{data?.title}}</p>
    </div>
  </div>
  <!--/ Mobile description view -->

</div>
