import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppRoutes } from '@app/constants';
import { checkMobileDevice, KalgudiAppService, KalgudiUtilityService, KalgudiWebNotificationsService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaService } from '@kalgudi/qa';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import {
  KALGUDI_HOME_STREAM_EVENTS,
  KalgudiHomeStream,
  KalgudiHomeStreamService,
  KalgudiHomeStreamStateService,
} from '@kalgudi/social';
import { AvailableDomains, PartialData, UIMenu } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { CommonAppService } from '../../../services/common-app.service';

@Component({
  selector: 'core-home-stream',
  templateUrl: './home-stream.component.html',
  styleUrls: ['./home-stream.component.scss'],
})
export class HomeStreamComponent extends KalgudiHomeStream implements OnInit {

  readonly footerMenus: UIMenu[];

  filters: PartialData = {
    eventType : "SHAREATHOUGHT"
  };

  isUbmAndKhetigharHomeStreamView : boolean;

  streamEventType = KALGUDI_HOME_STREAM_EVENTS;

  isMobileDevice = false;

  selectedFilter = new FormControl('LATEST_UPDATES');

  readonly tasksRandomIndex: number;
  shareUrl: string;
  promotionalShareUrl: string;
  qaUrl: string;

  isMatchedProfile = false;

  inputStore = AvailableDomains.INPUTS;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected webNotifications: KalgudiWebNotificationsService,
    protected kalgudiApp: KalgudiAppService,
    protected homeStream: KalgudiHomeStreamService,
    protected homeStreamState: KalgudiHomeStreamStateService,
    protected util: KalgudiUtilityService,
    private shareUpdateService: KalgudiShareUpdateService,
    private qaService: KalgudiQaService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private commonService: CommonAppService
  ) {

    // Wake (initialize) up my parent
    super(notification, webNotifications, kalgudiApp, homeStream, homeStreamState, util);

    this.tasksRandomIndex = this.util.generateRandomNumber(1, 8);

    this.footerMenus = [
      { matIcon: 'help', title: 'Ask a question', clickAction: this.openMobileQaForm },
      { matIcon: 'format_quote', title: 'Share an update', clickAction: this.openMobileShareUpdateForm  },
    ];

    this.isUbmAndKhetigharHomeStreamView = !(this.environment?.appId == 'UBM' || this.environment?.appId == 'KHETIGHAR');
  }

  ngOnInit() {

    // Load the home stream
    // this.loadStream();
    this.initStream();

    this.kalgudiApp.login$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(_ => this.getValidateAdmins());

    this.isMobileDevice = checkMobileDevice();

    this.shareUrl = AppRoutes.PUBLIC_SOCIAL_UPDATE;

    this.promotionalShareUrl = AppRoutes.STORE_PRODUCT_FULL_VIEW;


    this.qaUrl = AppRoutes.PUBLIC_SOCIAL_QA;
  }

  onDestroyed(): void { }

  /**
   * Opens Mobile share update form dialog
   */
  openMobileShareUpdateForm = (): void => {
    this.shareUpdateService.openMobileShareUpdateForm();
  }

  /**
   * Opens Mobile qa form dialog
   */
  openMobileQaForm = (): void => {
    this.qaService.openMobileQaForm();
  }

  /**
   * Gets validate admins
   */
  getValidateAdmins() {

    if (this.kalgudiApp.loggedIn) {
      this.commonService.getValidateAdmins()
        .subscribe(
          res => {
            this.isMatchedProfile = res.status;
          }
        );
    }
  }
}
