import { Component, Input, OnInit } from '@angular/core';
import { NotificationNetwork, ShareUpdateHeader } from '@kalgudi/types';

@Component({
  selector: 'kl-connected-tile',
  templateUrl: './connected-tile.component.html',
  styleUrls: ['./connected-tile.component.scss']
})
export class ConnectedTileComponent implements OnInit {

  @Input()
  notification: NotificationNetwork;

  networksHeader: ShareUpdateHeader = {
    matIcon: 'group_add',
    iconColor: 'success',
    title: 'You are now connected with',
    showMenu: false
  };

  constructor() { }

  ngOnInit() {
  }

}
