import { Injectable } from '@angular/core';
import { KalgudiProject, KalgudiProjectTask } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';

@Injectable()
export class KalgudiProjectStateService {

  reloadProjects$: Observable<string>;

  reloadTasks$: Observable<string>;

  projectDetails$: Observable<KalgudiProject>;

  taskDetails$: Observable<KalgudiProjectTask>;

  private reloadProjectsSubject$ = new BehaviorSubject<any>(null);

  private reloadTasksSubject$ = new BehaviorSubject<any>(null);

  private projectDetailsSubject$ = new BehaviorSubject<KalgudiProject>(null);

  private taskDetailsSubject$ = new BehaviorSubject<KalgudiProjectTask>(null);

  constructor() {


    this.reloadProjects$ = this.reloadProjectsSubject$.pipe(

      // Filter null values
      filter(val => val !== null),

      shareReplay(1),
    );

    this.reloadTasks$ = this.reloadTasksSubject$.pipe(

      // Filter null values
      filter(val => val !== null),

      shareReplay(1),
    );

    this.projectDetails$ = this.projectDetailsSubject$.pipe(

      // Filter null values
      filter(val => val !== null),

      shareReplay(1),
    );

    this.taskDetails$ = this.taskDetailsSubject$.pipe(

      // Filter null values
      filter(val => val !== null),

      shareReplay(1),
    );

  }

  reloadTasks(): void {
    this.reloadTasksSubject$.next('');
  }

  reloadProjects(): void {
    this.reloadProjectsSubject$.next('');
  }

  /**
   * Update the project details when ever the project is changed
   * @param projectDetails
   */
  updateProjectDetails(projectDetails: KalgudiProject): void {
    this.projectDetailsSubject$.next(projectDetails);
  }

  /**
   * Update the task details when ever the task is changed
   * @param taskDetails
   */
  updateTaskDetails(taskDetails: KalgudiProjectTask): void {
    this.taskDetailsSubject$.next(taskDetails);
  }

}
