import { Component, forwardRef, Injector, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';

import { AssistantPicker } from '../../classes/assistant-picker';


/**
 * Provider for assistant picker as formControl
 */
const ASSISTANT_PICKER_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AssistMembersPickerComponent ),
  multi: true,
};


@Component({
  selector: 'kl-assist-members-picker',
  templateUrl: './assist-members-picker.component.html',
  styleUrls: ['./assist-members-picker.component.scss'],
  providers: [ ASSISTANT_PICKER_VALUE_ACCESSOR ]
})
export class AssistMembersPickerComponent extends AssistantPicker implements OnInit {

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() {
  }


  onDestroyed(): void { }

}
