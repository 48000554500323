import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUsersMap } from '@kalgudi/types';

import { SearchMembers } from '../../classes/search-members';

@Component({
  selector: 'kl-search-members',
  templateUrl: './search-members.component.html',
  styleUrls: ['./search-members.component.scss']
})
export class SearchMembersComponent extends SearchMembers implements OnInit {

  @Output()
  addedMembers = new EventEmitter<string[]>();

  selectedUsersMap: KalgudiUsersMap = {};

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

  membersAdded(res): void {
    this.addedMembers.emit(res);
  }

}
