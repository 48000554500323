<!-- filters & status -->
<mat-card class="my-3" fxLayoutAlign="space-between"  *ngIf="deliveredLogsCount || failedLogsCount || pendingLogsCount">
  <kl-sms-log-status
    [logsCount]="logsCount"
    [deliveredLogsCount]="deliveredLogsCount"
    [failedLogsCount]="failedLogsCount"
    [pendingLogsCount]="pendingLogsCount">
  </kl-sms-log-status>
  <kl-sms-log-filters (selectedFilterId)="filterTypeChanged($event)"></kl-sms-log-filters>
</mat-card>

<section id="logs-stream">
    <ng-container *ngIf="((stream$ | async) || true) as stream">

      <!-- List of  logs -->
      <div fxLayout="column" fxLayoutGap="15px">
        <ng-container *ngFor="let smsLogs of stream">
          <kl-sms-logs-tile [smsLogs]="smsLogs">
          </kl-sms-logs-tile>
        </ng-container>
      </div>
      <!--/ List of logs -->

      <div class="mt-3" *ngIf="paginator$ | async as paginator">

        <!-- No content -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <ng-container *ngIf="!stream || stream?.length <= 0">
            <kl-no-content
              faIcon="fa fa-frown"
              text="No records to show!"
              progressText="Loading sms logs..."
              [progress]="paginator?.loading">
            </kl-no-content>
          </ng-container>
        </div>
        <!--/ No content -->

        <!-- Load more -->
        <ng-container *ngIf="stream?.length > 0">
          <kl-load-more
            noMoreText="No more logs to show!"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </ng-container>
        <!--/ Load more -->
      </div>

    </ng-container>
</section>
