import { Injectable } from '@angular/core';
import { KalgudiSms } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiSmsStreamStateService {

  private newStreamItemSubject = new Subject<KalgudiSms>();

  constructor() { }

  get newStreamItem$(): Observable<KalgudiSms> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: KalgudiSms): void {
    this.newStreamItemSubject.next(item);
  }
}
