import { Location } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiCommonRouteConfig } from '../../../../config';
import { Traceability } from '../../classes/traceability';

@Component({
  selector: 'kl-traceability-page',
  templateUrl: './traceability-page.component.html',
  styleUrls: ['./traceability-page.component.scss']
})
export class TraceabilityPageComponent extends Traceability implements OnInit {

  appId: any;
  constructor(
    protected injector: Injector,
    private location: Location,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig,
  ) {
    super(injector, environment)

    this.appId = this.environment.appId;
  }

  ngOnInit() {
    if(this.orderId) {
      this.getOrderDetails(this.orderId);
    }

    if (this.level3ProductDetails) {

      if(this.environment.appId !== this.outputStoreId) {
        this.getTraceabilityDetails();
        this.getFulfillmentDetails();
      }

      if(this.environment.appId === this.outputStoreId) {
        this.getTraceabilityDetails();
        this.getSupplierList();
      }
    }


    if(this.userProfileKey) {
      this.getSellerProfileInfo(this.userProfileKey);
    }
  }

  /**
   * Navigate to previous page
   */
  navigateToPreviousPage(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.appRouting.toHome();
    }
  }

}
