<section class="position-relative">
  <!-- common traceability details -->
  <kl-common-traceability-details
    [showGallery]="false"
    [showDetailsCard]="true"
    [traceabilityDetails]="traceabilityDetails"
    [fullFillmentCenterBasedOnStore]="fullFillmentCenterBasedOnStore">

    <!-- list of activities -->
    <div second-card class="fulfillment mb-4">

      <!-- Assigned stock and availability check section -->
      <ng-container *ngIf="(trackingDetails?.stocks | json) !== ({} | json) ">

        <div fxLayout="column" fxLayoutAlign="center start" *ngIf="trackingDetails?.stocks as stocks">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <div>
              <img src="assets/images/assign-stock.png" class="fulfillment-icon" width="40">
            </div>
            <div>
              <p class="title m-0">Assigned the stock</p>
            </div>
          </div>

          <div class="steps w-100" fxLayout="row" fxLayoutAlign="start center">
            <div class="separator" fxHide.xs>
              <p class="m-0 time text-right">{{stocks?.TS | date: 'MMM d, y'}}</p>
              <p class="m-0 time text-right">{{stocks?.TS | date: 'h:mm a'}}</p>
            </div>
            <div class="dot rounded-circle" fxHide.xs></div>
            <div class="dash" fxHide.xs></div>
            <div class="separation-wrapper">

              <div class="separation" fxLayout="row wrap" fxLayoutAlign="start center">
                <p class="m-0 details">Availability checked by
                  <span class="author"> {{stocks?.stockManager?.name}}</span>
                </p>

                <div class="separator" fxHide.gt-xs>
                  <p class="m-0 time">
                  <span>{{stocks?.TS | date: 'MMM d, y'}}</span>
                  <span> {{stocks?.TS | date: 'h:mm a'}}</span>
                  </p>
                </div>
                <div fxHide.gt-xs class="dot rounded-circle"></div>
                <div fxHide.gt-xs class="dash"></div>
              </div>

            </div>
          </div>
        </div>
      </ng-container>
      <!--/ Assigned stock and availability check section -->

      <!-- Tracking details section -->
      <div *ngFor="let item of trackingDetailsList; let last = last; let first = first">
        <div fxLayout="column" fxLayoutAlign="center start">
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <div>
              <img [src]="orderImages[item?.currentState]?.icon" class="fulfillment-icon" width="40">
            </div>
            <div>
              <p class="title m-0">{{item?.title}}</p>
            </div>
          </div>

          <div class="steps w-100" fxLayout="row" fxLayoutAlign="start center"  *ngIf="!last || first">
            <div class="separator" fxHide.xs>
              <p class="m-0 time text-right">{{item?.TS | date: 'MMM d, y'}}</p>
              <p class="m-0 time text-right">{{item?.TS | date: 'h:mm a'}}</p>
            </div>
            <div fxHide.xs class="dot rounded-circle" [ngClass]="{'end-track': item?.currentState === 'ORD_SETTLED'}"></div>
            <div fxHide.xs class="dash" *ngIf="item?.currentState !== 'ORD_SETTLED'"></div>
            <div class="separation-wrapper">

              <div class="separation" fxLayout="row wrap" fxLayoutAlign="start center">
                <p class="m-0 details" fxFlex="100%">
                  <!-- {{ orderImages[item?.currentState]?.title }} -->
                  <span>{{actionMessageLookup[item?.currentAction || 'ord_RECEIVED']}}</span>
                  <span> by </span>
                  <span class="author"> {{item?.actorDetails?.firstName}}</span>
                </p>

                <div class="separator" fxHide.gt-xs>
                  <p class="m-0 time">
                  <span>{{item?.TS | date: 'MMM d, y'}}</span>
                  <span> {{item?.TS | date: 'h:mm a'}}</span>
                  </p>
                </div>
                <div fxHide.gt-xs class="dot rounded-circle"  [ngClass]="{'end-track': item?.currentState === 'ORD_SETTLED'}"></div>
                <div fxHide.gt-xs class="dash" *ngIf="item?.currentState !== 'ORD_SETTLED'"></div>

                <!-- Updates section -->
                <ng-container *ngIf="item?.items" >

                  <div class="updates position-relative d-block" fxFlex="100%" fxLayout="column" [ngClass]="{'bl-0': last}">
                    <a class="subtitle m0 cursor" (click)="item.expanded = !item.expanded;">{{item?.items?.length}}
                      <span i18n>{item.items.length, plural, =1 {Update} other {Updates}}</span>
                    </a>
                    <ul *ngIf="item.expanded" class="list-unstyled">
                      <li class="my-1 w-100 text-decoration-none" *ngFor="let subitem of item?.items">
                        <div>
                          <div class="text-primary font-14">{{subitem?.firstName}}</div>
                          <div>
                            <p class="desc mt-1 overflow-hidden text-content mb-1" klHideContent [lineHeight]="18" [visibleLine]="3">{{subitem?.message}}</p>
                          </div>

                          <div class="py-1" *ngIf="subitem?.lstOfAttachments && subitem?.lstOfAttachments?.length > 0" (click)="openImageFullView(subitem?.lstOfAttachments)" fxLayout fxLayoutAlign="start center">

                          <img *ngFor="let image of subitem?.lstOfAttachments; let i =  index; let first = first" class="cursor-pointer rounded-circle"
                              [src]="image?.url" (click)="openImageFullView(subitem?.lstOfAttachments, i)" [ngClass]="{'ml-n4': !first, 'border': image?.url}">
                          </div>
                          <div class="text-muted font-12">{{subitem?.createdTS | date: 'dd MMM yy \'at\' hh:mm a'}}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </ng-container>
                <!--/ Updates section -->
              </div>
            </div>
          </div>

        </div>

      </div>
      <!--/ Tracking details section -->

      <!-- No content section -->
      <div class="w-100" fxLayout="column" fxLayoutAlign="center center"  *ngIf="(trackingDetails?.stocks | json) === ({} | json) && !trackingDetailsList?.length">
        <img class="image" src="assets/images/no-activities.gif" width="210" height="115">
        <span class="text-muted text">Availability check is pending</span>
      </div>
      <!--/ No content section -->

    </div>
    <!--/ list of activities -->

  </kl-common-traceability-details>
  <!-- common traceability details -->
</section>
