import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUserBasicDetails,
  KalgudiUsersPickerDialogConfig,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { PageActions } from '../constants';
import { ProcessingService } from '../modules/page-processing/services/processing.service';
import { ProgramStateService } from '../services/program-state.service';

@Directive()
export abstract class KalgudiPageProcessingList extends KalgudiInboxStream<any> {

  @Input()
  pageId: string;

  private processingService: ProcessingService;
  private programState: ProgramStateService;
  private usersPickerService: KalgudiUsersPickerService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    this.processingService  = this.injector.get<ProcessingService>(ProcessingService);
    this.programState       = this.injector.get<ProgramStateService>(ProgramStateService);
    this.usersPickerService = this.injector.get<KalgudiUsersPickerService>(KalgudiUsersPickerService);
    /**
     * On `ADD_CONTRIBUTOR` state action changed
     */
    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === PageActions.ADD_PROCESSING),
      )
      .subscribe(res => this.add());
  }


  /**
   * Adds a user to the program. Displays a Kalgudi user picker dialog.
   * Finally calls the add api to add the user.
   *
   * On successful update it automatically updates the program state.
   */
  add(): void {

    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Select users to add',
      acceptButtonTitle: 'Select users',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        filter(r => r.accepted),

        map(res => Object.values(res.data)),

        filter(res => res.length > 0)
      )
      .subscribe(
        res => this.addProcessors(res)
      );
  }

  protected addProcessors(users: KalgudiUserBasicDetails[]): void {
    this.processingService.addProcessors(this.pageId, 'PROCESSOR', users)
    .subscribe(
      res => {
        this.resetStream();
      }
    );
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.processingService.getProcessors(offset, limit, this.pageId, 'PROCESSOR');

    }

  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.usersPickerService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.usersPickerService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }


  /**
   * Shows users picker for mobile and web based on the device or screen size.
   */
  private showUsersPicker(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileUserPickerDialog(dialogConfig)
      : this.openUsersPickerDialog(dialogConfig, matDialogConfig);
  }

}
