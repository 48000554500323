import { Inject, Injectable } from '@angular/core';
import { KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { IdValueMap, KalgudiPageDetails, KalgudiSurveyDetails, PageType, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo, switchMap, tap } from 'rxjs/operators';

import { PageActions } from '../constants';
import { KalgudiPageApiService } from './kalgudi-page-api.service';
import { ProgramStateService } from './program-state.service';



@Injectable()
export class KalgudiPageService {

  readonly pageDetails$: Observable<KalgudiPageDetails>;

  private pageShareTagsCache: IdValueMap[];

  constructor(
    private app: KalgudiAppService,
    private api: KalgudiPageApiService,
    private pageState: ProgramStateService,
    private uploadService: KalgudiUploadService,
    private util: KalgudiUtilityService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) {

    // this.userDetails = this.app.profileLocal;

    this.pageDetails$ = this.pageState.data$;
  }


  // --------------------------------------------------------
  // #region  Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches latest page details from service and updates the page details in the
   * page state.
   */
  fetchAndUpdatePageState(pageId: string): Observable<KalgudiPageDetails> {

    return this.getPage(pageId)
      .pipe(
        tap(page => this.updatePageState(page))
      );
  }

  /**
   * Updates the page details in the state service.
   */
  updatePageState(page: KalgudiPageDetails): void {
    this.pageState.dispatchAction(PageActions.PAGE_UPDATED, page);
  }


  /**
   * Gets, searched pages from Api
   */
  searchPages(keyword: string, mode: string = '',  pageType = ''): Observable<any> {
    return this.api.searchPages(keyword, mode, pageType);
  }

  /**
   * Gets, page details from Api
   */
  getPage(pageId: string): Observable<KalgudiPageDetails> {
    return this.api.getPage(pageId);
  }

  /**
   * Updates the page details. Calls the Api to update page details.
   */
  updatePage(pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {
    return this.api.updatePageDetails(pageDetails)
      .pipe(
        switchMap(_ => this.fetchAndUpdatePageState(pageDetails.pageId))
      );
  }

  /**
   * Calls the delete dialog Api.
   */
  deletePage(pageId: string): Observable<KalgudiPageDetails> {

    //returns the api service.
    return this.api.deletePage(pageId);
  }

  /**
   * Updates profile picture
   * @param file
   * @param path
   */
  updateProfilePic(file: File, path: S3PolicyPathCategoryMap, pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {

    return this.uploadProfilePicToS3(file, path, pageDetails)
      .pipe(

        // Update latest profile pic url to the Api
        switchMap(res => this.updatePage(res)),

        // Pull latest program data on successful profile pic update
        switchMap(res => this.fetchAndUpdatePageState(pageDetails.pageId)),
      );
  }

  /**
   * Returns `true` if the logged in user is admin of the page, otherwise `false`
   */
  isPageAdmin(pageDetails: KalgudiPageDetails): boolean {
    return pageDetails.memberRole === 'ADMIN';
  }

  /**
   * Returns `true` if the logged in user is author of the page, otherwise `false`.
   *
   * A person is author of the page if he has created the page.
   */
  isPageAuthor(pageDetails: KalgudiPageDetails): boolean {

    return (this.app.profileLocal && this.app.profileLocal.profileKey === pageDetails.createdBy.profileKey);
  }

  /**
   * Gets, program tags from local assets
   */
  getPageShareTags(pageType: PageType, baseProductId?: string): Observable<IdValueMap[]> {

    return this.api.getPageTags(pageType, baseProductId)
      .pipe(

        map(tags => this.sortPageShareTags(tags)),
      );

    // Check for local cache
    // return this.pageShareTagsCache
    //   ? of(this.pageShareTagsCache)   // Serve page share tags from cache
    //   : this.util.fetchJsonFromUrl<IdValueMap[]>('assets/json/program-share-tags.json')    // Fetch page tags from the api
    //       .pipe(

    //         map(tags => this.sortPageShareTags(tags)),

    //         // Cache the share tags locally
    //         tap(tags => this.pageShareTagsCache = tags)
    //       );
  }

  /**
   * Calls Kalgudi api to fetch survey details
   */
  getSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {
    return this.api.getSurvey(surveyId);
  }

  /**
   * Fetch all locations
   */
  fetchAllLocations(pageId: string): Observable<any> {
    return this.api.fetchAllLocations(pageId);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region  Private methods
  // --------------------------------------------------------

  /**
   * Uploads the file to s3 and gets the s3url
   * @param file
   * @param profileBasicDetails
   * @param path
   */
  private uploadProfilePicToS3(
      file: File,
      path: S3PolicyPathCategoryMap,
      pageDetails: KalgudiPageDetails
    ): Observable<KalgudiPageDetails> {

    // Call upload service
    return this.uploadService.uploadFile(file, path, false, `${pageDetails.pageId}.png`)
      .pipe(

        // Modifying the entity profile pic with the latest updated pic url
        tap(r => pageDetails.pageProfilePic = this.getProfilePicUrl(r.filePath)),

        mapTo(pageDetails),
      );
  }

  /**
   * Binds the time to the s3url
   * @param filePath
   */
  private getProfilePicUrl(filePath: string): string {

    return `${filePath}` + ('?' + new Date().getTime().toString());
  }

  /**
   * Sorts page share tags
   */
  private sortPageShareTags(tags: IdValueMap[]): IdValueMap[] {
    if(this.env.appId === 'FPO_APP' || this.env.appId === 'FARMERS_APP' || this.env.appId === 'SAM_FPO') {
      return tags;
    } else {
      return tags.sort((x, y) => x.id > y.id ? 1 : -1);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
