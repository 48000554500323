import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ButtonComponent } from './components/button/button.component';
import { FlatButtonComponent } from './components/flat-button/flat-button.component';
import { FloatButtonComponent } from './components/float-button/float-button.component';
import { IconLinkButtonComponent } from './components/icon-link-button/icon-link-button.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { StrokeButtonComponent } from './components/stroke-button/stroke-button.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    FlatButtonComponent,
    StrokeButtonComponent,
    ButtonComponent,
    LinkButtonComponent,
    IconLinkButtonComponent,
    FloatButtonComponent,
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    // Material module
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule,

    FlexLayoutModule,
    TranslateModule
  ],
  exports: [
    FlatButtonComponent,
    FloatButtonComponent,
    StrokeButtonComponent,
    ButtonComponent,
    LinkButtonComponent,
    IconLinkButtonComponent
  ]
})
export class KalgudiButtonModule { }
