import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DigitalAssistanceService } from '../../services/digital-assistance.service';

@Component({
  selector: 'kl-frequent-assisted-members',
  templateUrl: './frequent-assisted-members.component.html',
  styleUrls: ['./frequent-assisted-members.component.scss']
})
export class FrequentAssistedMembersComponent extends KalgudiInboxStream<KalgudiUserBasicDetails> implements OnInit {

  @Input()
  pageId: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    private daService: DigitalAssistanceService,
  ) {
    super(notification, util);

    this.pageLimit = 100;
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed() {}

  /**
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.daService.searchFrequentlyAssistedMembers(this.pageId, offset, limit)
      .pipe(
        // map(items => ({ items: items.items }))
      );
  }
}
