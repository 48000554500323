<div id="add-live-stock-page" class="mt-n3 p-2">
  <div fxLayout="row wrap" fxLayoutGap="3px">
    <mat-card fxFlex="24%" fxLayoutAlign="center center" *ngFor="let stock of livestockList" class="live-stock-tile p-1 mt-1" (click)="openAddLiveStockForm(stock)">
      <div fxLayout="column" fxLayoutAlign="center center">
        <img [src]="stock?.items?.attachments?.imageUrl" class="live-stock-image">

        <span class="text-secondary product-name text-truncate mw-100">{{stock?.title | titlecase}}</span>
      </div>
    </mat-card>
  </div>

  <ng-container *ngIf="progress || (!livestockList || (livestockList && !livestockList?.length))">
    <kl-no-content
      iconSize="md"
      [progressText]="'Loading ' + 'live stocks' + '...'"
      [progress]="progress">
    </kl-no-content>
  </ng-container>
</div>
