import { Injector, Input, OnChanges, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { EmployeeProfileTo, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { EmployeeProfileService } from '../services/employee-profile.service';

@Directive()
export abstract class KalgudiEmployeeProfileInfo extends KalgudiDestroyable implements OnChanges {

  @Input()
  profileDetails: KalgudiUser;

  showSpecializationField: boolean;
  showRoleFiled: boolean;
  progress: boolean;
  editable: boolean;

  employeeForm: FormGroup;

  private util: KalgudiUtilityService;
  private fb: FormBuilder;
  private employeeProfileService: EmployeeProfileService;
  private notification: KalgudiNotification;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector) {

    super();

    this.fb                     = this.injector.get(FormBuilder);
    this.util                   = this.injector.get(KalgudiUtilityService);
    this.employeeProfileService = this.injector.get(EmployeeProfileService);
    this.notification           = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileService                       = this.injector.get(KalgudiProfileService);

    this.employeeForm = this.employeeNewForm;

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

  }


  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Gets, specialization field from auth form group.
   */
  get specializationField(): AbstractControl {
    return this.employeeForm.get('specialization');
  }

  /**
   * Gets, the role and responsibilities field from auth form group.
   */
  get rolesAndResponsibilitiesField(): AbstractControl {
    return this.employeeForm.get('rolesAndResponsibilities');
  }

  /**
   * Form group for employee info
   */
  private get employeeNewForm(): FormGroup {

    return this.fb.group({
      specialization: ['', Validators.required],
      rolesAndResponsibilities: ['', Validators.required]
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  ngOnChanges() {
    if (this.profileDetails && this.profileDetails.employeeProfileTo) {

      this.employeeForm.patchValue(this.profileDetails.employeeProfileTo);

    }
  }

  toggleRoleAndResponsibilities() {
    this.showRoleFiled = true;
  }

  toggleSpecialization() {
    this.showSpecializationField = true;
  }

  cancelAddingSpecialization() {
    this.showSpecializationField = false;
  }

  cancelAddingRole() {
    this.showRoleFiled = false;
  }

  /**
   * Call api method save employee details
   */
  saveEmployeeDetails() {

    this.progress = true;

    const payload = this.getPreparedPayload(this.employeeForm.value);

    this.employeeProfileService.saveEmployeeDetails(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onAddingEmployeeDetails(res),
        err => this.notification.showMessage('Unable to update profile info, please try again later')
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Prepares payload
   */
  private getPreparedPayload(formValue): EmployeeProfileTo {
    return this.util.clone(formValue);
  }

  /**
   * On employee profile info changes success
   */
  private onAddingEmployeeDetails(res): void {
    this.notification.showMessage('Updated profile info');
    this.showSpecializationField = false;
    this.showRoleFiled = false;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
