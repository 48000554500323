<section class="add-credits-form p-3">
  <div class="mb-3 credit-values">Select credits you want to add </div>

  <!-- available credits section -->
  <div fxLayout="row" fxLayoutGap="10px">
    <ng-container *ngFor="let credit of creditsAvailable">
      <mat-card class="w-25 p-3 available-credits"
      [ngClass]="{'selected-credit': (selectedCredits == credit)}"
      (click)="selectCredit(credit)"
      fxLayout="column"
      fxLayoutAlign="stretch space-between">
        <div>{{credit?.credits}} Credits</div>
        <div fxLayoutAlign="end">
          {{credit?.amount | currency:"₹"}}
        </div>
      </mat-card>
    </ng-container>
  </div>

  <!-- form -->
  <form autocomplete="off" [formGroup]="creditForm">
    <div fxLayout="row" fxLayoutGap="15px" class="mt-4">
      <mat-form-field appearance="outline" fxFlex="50%">
        <mat-label>Email</mat-label>
        <input matInput required formControlName="email" placeholder="Enter email id">
        <mat-error *ngIf="creditForm.get('email')?.invalid">Please enter valid email</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" fxFlex="calc(50% - 15px)">
        <mat-label>Mobile</mat-label>
        <input matInput required formControlName="phone" placeholder="Enter mobile number">
        <mat-error *ngIf="creditForm.get('phone')?.invalid">Please enter valid mobile number</mat-error>
      </mat-form-field>
    </div>
    <!-- Add button -->
    <div fxLayoutAlign="end center">
      <div class="send-button pt-2 w-25">
        <kl-flat-button
        [type]="'button'"
        [label]="'Proceed to pay'"
        [progress]="progress"
        [disabled]="creditForm?.invalid || !selectedCredits"
        (clicked)="addCredits()">
        </kl-flat-button>
      </div>
    </div>
  </form>
</section>
