<mat-card class="my-2 p-0 assist-profile-logs" @bottomFloatingCard>

  <!-- Title -->
  <div fxLayout="row" class="px-3 pt-3 pb-0" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>
    <div>
      <svg-icon src="assets/svgs/profile-logs.svg" class="profile-icon" [svgStyle]="{'width.px': '25', 'height.px': '25', 'fill': 'rgb(149, 117, 205)'}"></svg-icon>
    </div>
    <div>
      <p class="mb-0 title" translate>Profile Logs</p>
    </div>
  </div>
  <!-- /Title -->

  <div id="kalgudi-profile-logs-form" class="p-0">
    <form autocomplete="off" [formGroup]="profileUpdateForm">
      <div class="p-3 share-update-form" fxLayout fxLayoutGap="10px">
        <div fxFlex>
          <kl-assist-members-picker [formControl]="assistedUser">
          </kl-assist-members-picker>
        </div>
        <div class="view-log-button">
          <kl-flat-button
            [label]="'View logs' | translate"
            [disabled]="!assistedUser?.value?.profileKey || (assistedUser?.value?.businessTypeName !== availableBusinessTypeNames?.FPO && assistedUser?.value?.businessTypeName !== availableBusinessTypeNames?.FARMER)"
            (clicked)="openProfileView(assistedUser?.value?.profileKey)"></kl-flat-button>
        </div>
      </div>

      <div *ngIf="assistedUser?.value?.businessTypeName && (assistedUser?.value?.businessTypeName !== availableBusinessTypeNames?.FPO && assistedUser?.value?.businessTypeName !== availableBusinessTypeNames?.FARMER)">
        <p class="mt-n3 px-3 pb-2 text-danger warning-text">Profile logs not enabled for {{assistedUser?.value?.businessTypeName}}</p>
      </div>

    </form>
  </div>

</mat-card>
