import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IdValueMap } from '@kalgudi/types';

import { programType } from '../../../constants';


@Component({
  selector: 'kl-program-info-form',
  templateUrl: './program-info-form.component.html',
  styleUrls: ['./program-info-form.component.scss']
})
export class ProgramInfoFormComponent {

  programType: IdValueMap[] = programType;

  @Input()
  infoForm: FormGroup;

  constructor() {}


}
