import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiCommonModule } from '../../kalgudi-common.module';
import { PostAuthorComponent } from './components/post-author/post-author.component';
import { SelectableUserTileComponent } from './components/selectable-user-tile/selectable-user-tile.component';
import { KalgudiUsersSearchApiService } from './services/kalgudi-users-search-api.service';
import { KalgudiUsersSearchService } from './services/kalgudi-users-search.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    PostAuthorComponent,
    SelectableUserTileComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiCommonModule,

    TranslateModule
  ],
  exports: [
    PostAuthorComponent,
    SelectableUserTileComponent,
  ],
  providers: [
    KalgudiUsersSearchService,
    KalgudiUsersSearchApiService,
  ]
})
export class KalgudiUsersModule { }
