import { IdValueMap } from './core.model';
import { ApiResponseCommon } from './rest-api.model';


export enum KalgudiSmsStates {
  ALL           = 'ALL',
  SUCCESS       = 'SUCCESS',
  FAILED        = 'FAILED',
  PENDING       = 'PENDING'
}

export interface SmsLog {
  mobileNo: number;
  firstName: string;
  profileKey: string;
  status: boolean;
  deliveryStatus: string;
  totalFragments?: number;
  usdCost?: number;
  isAdminSMS?: boolean;
  messageId?: string;
  entityId?: string;
  dlrStatus?: string;
  isDelivered?: boolean;
  smsId?: string;
  entityName?: string;
  smsType?: string;
  time?: string;
}

export interface KalgudiSpecificSubscribers {
  targetedMembersObject: TargetedMembersObject;
  members?: string[];
  smsText: string;
  msgTypes: string[];
  recipient: string;
  sharedTo: string;
  scheduledTS?: string;
  scheduledDate?: string;
  scheduledTime?: string;
}

export interface TargetedMembersObject {
  pageId: string;
  isSms: boolean;
  valid: boolean;
  isPublishedThroughTool: boolean;
  products: IdValueMap[];
  businessTypes: IdValueMap[];
  locations: IdValueMap[];
  countries: IdValueMap[];
  states: IdValueMap[];
  districts: IdValueMap[];
  smsTemplateId?: number;
  templateDetails?: KalgudiSpecificSubscribers
}

export interface PublishSmsResponse extends ApiResponseCommon {
  data: KalgudiSms;
}

export interface KalgudiSms {
  targetedMembersObject: TargetedMembersObject;
  smsText: string;
  msgTypes: string[];
  recipient: string;
  sharedTo: string;
  smsId: string;
  smsCount: number;
  pageId: string;
  author: AuthorDetails;
  CTS: string;
  UTS: string;
  authorId?: string;
  members?: string[];
  byteCount: number;
  totalCredit: number;
  scheduledTS?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  scheduleId?: string;
}

export interface AuthorDetails {
  firstName: string;
  profileKey: string;
  profilePicUrl: string;
  businessTypeName: string;
  businessKey: string;
  businessName: string;
}

export interface LogsResponseData {
  failed?: number;
  pending?: number;
  success?: number;
  total?: number;
  count?: number;
  items: any[];
}

export interface LogsDownloadResponse {
  url?: string;
}

export const SMS_LOGS_STATUS = {
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  PENDING: 'PENDING'
};

export const SMS_TYPE_FILTERS = {
  LATEST_SMS_UPDATES: 'LATEST_SMS_UPDATES',
  SCHEDULED_SMS_UPDATES: 'SCHEDULED_SMS_UPDATES',
};

