import { Injectable } from '@angular/core';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiPromotionalShareUpdateApiService } from './kalgudi-promotional-share-update-api.service';

@Injectable()
export class KalgudiPromotionalShareUpdateService {

  constructor(
    private api: KalgudiPromotionalShareUpdateApiService,
  ) { }

  /**
   * Gets the base product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    storeType: string,
    filters: PartialData = {},
    offset: number = 0,
    limit: number = 30,
  ): Observable<any[]> {
    return this.api.getProductsPredictions(keyword, storeType, offset, limit, filters);
  }

  /**
   * Gets the product level2 details
   */
  getProductLevel2(
    level2Id: string,
    storeType: string,
    filters: PartialData = {},
    offset: number = 0,
    limit: number = 100,
  ): Observable<any[]> {
    return this.api.getProductLevel2(level2Id, storeType, offset, limit, filters);
  }

}
