import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { IdValueMap } from '@kalgudi/types';

import { KalgudiShareRangeFilter } from '../../classes/share-range-filter';
import { KalgudiShareTargetMembersService } from '../../services/kalgudi-share-target-members.service';


@Component({
  selector: 'kl-share-location-range-filter',
  templateUrl: './share-location-range-filter.component.html',
  styleUrls: ['./share-location-range-filter.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareLocationRangeFilterComponent extends KalgudiShareRangeFilter implements OnInit {

  constructor(
    protected subscriberMetaService: KalgudiShareTargetMembersService,
  ) {

    super(subscriberMetaService);
  }

  ngOnInit() {
  }

  /**
   * **Note:** Don't remove this. Required for the selection range performance.
   */
  trackById(index: number, item: IdValueMap): any {
    return item ? item.id : null;
  }

  /**
   * Update selected country
   * @param countryId
   */
  setCountry(countryId: string): void {
    this.subscriberMetaService.updateSelectedCountry(countryId);
  }

  /**
   * Update selected state
   * @param stateId
   */
  setState(stateId: string): void {
    this.subscriberMetaService.updateSelectedState(stateId);
  }

  /**
   * Update selected district
   * @param districtId
   */
  setDistrict(districtId: string): void {
    this.subscriberMetaService.updateSelectedDistrict(districtId);
  }
}
