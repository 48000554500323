<div class="farmer-crop-list" *ngIf="false">
  <diV fxLayout.xs="column" fxLayout="row wrap">
    <div fxFlex="100%"
      class="mb-2"
      *ngFor="let product of products | keyvalue">

      <mat-card class="product-tile" [ngClass]="{'cursor-pointer': product?.value[0]?.pageId, 'pb-3 !important': !product?.value[0]?.pageId }" (click)="opensCropFullview(product?.value[0]?.baseProductId, product?.value[0]?.baseProductName, product?.value[0]?.pageId)">
        <div fxLayout fxLayoutAlign="end end" *ngIf="editable">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="options" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md crop-menu " fxLayout="column">
            <button class="edit-button p-0" mat-menu-item (click)="editProduct(product?.value)">
              <div fxLayout fxLayoutAlign="start center" class="ml-2">
                <mat-icon class="edit rounded-circle text-secondary mr-2" fxLayoutAlign="center center">edit</mat-icon>
                <span class="text-secondary font-weight-normal text" translate>Edit</span>
              </div>
            </button>
            <!-- <button class="delete-button p-1" mat-menu-item [matTooltip]="'Remove land details' | translate" (click)="deleteLandDetailsDialog(landDetails.landId)">
              <div fxLayout fxLayoutAlign="start center" class="ml-2">
                <mat-icon class="edit rounded-circle text-secondary" fxLayoutAlign="center center">delete</mat-icon>
                <span class="text-secondary font-weight-normal text">Delete</span>
              </div>
            </button> -->
          </mat-menu>
        </div>
        <div class="" [ngClass]="{'mt-n2': editable}" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="" [ngClass]="{'mt-n1': editable, 'mt-2 pt-1': !editable}">


            <div *ngIf="product?.attachments && product?.attachments?.length && product?.attachments[0]?.attachments && product?.attachments[0]?.attachments?.length; else noImage">
              <img [src]="product?.attachments[0]?.attachments[0]?.url | prefixDomain" class="product-image"
                [klErrorImages]="[
                (product?.attachments[0]?.attachments[0]?.url) | prefixDomain,
                'assets/images/plant.png'
                ]">
            </div>

            <ng-template #noImage>

              <img [src]="'assets/images/plant.png'" class="product-image">
            </ng-template>

            <div>
              <p class="m-0 product-name">{{product?.value[0].baseProductName | titlecase}}</p>
            </div>

          </div>

          <div fxFlex="26%" fxLayoutAlign="end end" *ngIf="product?.value[0]?.pageId">
            <button mat-button class="mt-3">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div fxLayout="column">
                  <p class="m-0 button-label text-right" translate>Farm</p>
                  <p class="m-0 button-label text-right" translate>Activity</p>
                </div>
                <div fxLayoutAlign="center center">
                  <mat-icon class="arrow-icon">arrow_forward</mat-icon>
                </div>
              </div>
            </button>
          </div>
        </div>
      </mat-card>
      <div class="varieties-bg">
        <div class="position-relative" [ngClass]="{'no-base-product': !product?.value.length}" fxLayout="row wrap" fxLayoutAlign="start center">
          <ng-container *ngFor="let item of product?.value | slice: 0:product?.value[0].isCrops ? product?.value?.length : 1; let last = last; let i = index">

            <ng-container *ngIf="item?.productId !== product?.value[0].baseProductId">

              <div class="px-3 py-2 varity-details-tile" fxFlex="0 0 calc(31% - 10px)" fxFlex.xs="100%" fxFlex.lt-lg="0 0 calc(31% - 10px)" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" [ngClass]="{'mt-2 pt-1': !editable}">

                <div *ngIf="item?.attachments && item?.attachments?.length && item?.attachments[0]?.attachments && item?.attachments[0]?.attachments?.length; else noImage">
                  <img [src]="item?.attachments[0]?.attachments[0]?.url | prefixDomain" class="variety-image"
                    [klErrorImages]="[
                    (item?.attachments[0]?.attachments[0]?.url) | prefixDomain,
                    'assets/images/plant.png'
                    ]">
                </div>

                <ng-template #noImage>

                  <img [src]="'assets/images/plant.png'" class="variety-image">
                </ng-template>

                <div>

                  <p class="m-0 base-products">
                    <span >{{item?.productName}}</span>
                    <!-- <span *ngIf="!last && product?.value[i + 1]?.baseProductId !== product?.value[i + 1].productId" class="mx-1">|</span> -->
                  </p>
                  <p class="season-details m-0" *ngIf="item?.latestSeasonInfo && item?.latestSeasonInfo?.length && item?.latestSeasonInfo[0]">
                    <span class="text-capitalize">{{item?.latestSeasonInfo[0]?.title}}</span>
                    <span *ngIf="item?.latestSeasonInfo[0]?.land?.landName">
                      <span> | </span>
                      <span> <</span>
                      <span>{{item?.latestSeasonInfo[0]?.land?.landName}}</span>
                      <span>></span>
                    </span>

                  </p>
                </div>

              </div>

              <div class="w-100 mx-3" [ngClass]="{'variety-separator my-1': !last && product?.value.length > 2}"></div>
              <p class="m-0 base-products position-absolute cursor-pointer" *ngIf="product?.value[0]?.variantCount > 1" [ngClass]="{'d-none': product?.value[0].isCrops}">
                <span class="ml-1" (click)="showAllCrops(product?.value[0])">+ {{product?.value[0]?.variantCount - 1}}</span>
              </p>
            </ng-container>
          </ng-container>

        </div>

      </div>

    </div>

  </diV>
</div>


<div class="farmer-crop-list">
  <div fxLayout.xs="column" fxLayout="row wrap">
    <div fxFlex="100%" *ngFor="let product of products | keyvalue | slice: 0: isCropTiles ? productsLength : 2">
      <div *ngFor="let productVariety of product?.value | slice: 0: isCropTiles ? productsLength : 1">
        <div class="product-tile py-1 mt-2" [ngClass]="{'cursor-pointer': productVariety?.pageId, 'pb-1 !important': !productVariety?.pageId }" (click)="opensCropFullview(productVariety?.baseProductId, productVariety?.baseProductName, productVariety?.pageId, productVariety?.farmingFarmType)">
          <div [ngClass]="{'mt-n2': editable}" fxLayout fxLayoutGap="6px">
            <div class="w-100" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">

              <!-- Product name and Variety name -->
              <span class="m-0 variety-name text-truncate">{{ productVariety?.productName | titlecase }}
                <span class="ml-1 mr-1" *ngIf="productVariety?.baseProductName">-
                  <span class="m-0 product-name">{{ productVariety?.baseProductName | titlecase }}</span>
                </span>
              </span>
              <button *ngIf="isAssisted" mat-icon-button [matMenuTriggerFor]="menu" class="options ml-3 mt-0" (click)="$event.stopPropagation()">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md crop-menu " fxLayout="column">
                <button class="edit-button p-0" mat-menu-item *ngIf="editable"
                  [matTooltip]="'Edit crop details' | translate"
                  (click)="editProduct(product?.value); $event.stopPropagation()">
                  <div fxLayout fxLayoutAlign="start center" class="mx-2">
                    <mat-icon class="edit rounded-circle text-secondary mr-2" fxLayoutAlign="center center">edit</mat-icon>
                    <span class="text-secondary font-weight-normal text" translate>Edit</span>
                  </div>
                </button>

                <button class="edit-button p-0" mat-menu-item
                  [matTooltip]="'Remove crop details' | translate"
                  (click)="deleteProduct(product?.value); $event.stopPropagation()">
                  <div fxLayout fxLayoutAlign="start center" class="mx-2">
                    <mat-icon class="edit rounded-circle text-secondary mr-2" fxLayoutAlign="center center">delete</mat-icon>
                    <span class="text-secondary font-weight-normal text" translate>Delete</span>
                  </div>
                </button>
              </mat-menu>
              <!-- /Product name and Variety name -->

            </div>

            <!-- Farm Activity -->
            <div fxFlex="100px" *ngIf="productVariety?.pageId" class="text-right">
              <button mat-button class="mt-2 p-0">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="3px">
                  <span class="m-0 pl-2 button-label text-right" translate>Farm Activity</span>
                  <div fxLayoutAlign="end center">
                    <mat-icon class="arrow-icon d-flex align-items-center">arrow_forward</mat-icon>
                  </div>
                </div>
              </button>
            </div>
            <!-- /Farm Activity -->
          </div>

          <!-- Season card -->
          <div class="season-card p-2 mt-2" *ngIf="productVariety?.latestSeasonInfo">
            <ng-container *ngFor="let latestSeason of productVariety?.latestSeasonInfo; let last = last">

              <!-- Season title, Start date - end date, Activity -->
              <div fxLayout="row" fxLayoutAlign="space-between">
                <div fxLayout="column">
                  <div class="season-title" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                    <p class="w-90 text-truncate">{{ latestSeason?.title | titlecase }} <span *ngIf="latestSeason?.title && (latestSeason?.season?.seasonTitle || latestSeason?.season)">- <span>{{ latestSeason?.season?.seasonTitle || latestSeason?.season | titlecase }}</span></span>
                    </p>
                  </div>
                  <div class="time-span text-secondary" fxLayout="row" fxLayoutGap="5px">
                    <span *ngIf="latestSeason?.season?.seasonStartDate && latestSeason?.season?.seasonEndDate">
                      {{ latestSeason?.season?.seasonStartDate | date:'dd' }}
                      {{ latestSeason?.season?.seasonStartDate | date:'MMM' | translate }}
                      {{ latestSeason?.season?.seasonStartDate | date:'yyyy' }}
                    </span>
                    <span *ngIf="latestSeason?.season?.seasonStartDate && latestSeason?.season?.seasonEndDate">-</span>
                    <span>
                      {{ latestSeason?.season?.seasonEndDate | date:'dd' }}
                      {{ latestSeason?.season?.seasonEndDate | date:'MMM' | translate }}
                      {{ latestSeason?.season?.seasonEndDate | date:'yyyy' }}
                    </span>
                  </div>
                </div>
                <div class="activity" translate>Activity</div>
              </div>
              <!-- /Season title, Start date - end date, Activity -->

              <!-- Land size, Expected and Actual Yield -->
              <div fxLayout="row" class="sub-header" fxLayoutAlign="space-between end">
                <div fxLayout="row" fxLayoutGap="4px" >
                  <span class="text-secondary time-span" translate>Land size</span>
                  <span class="season-title">-</span>
                  <span class="season-title">{{ latestSeason?.land?.landSize?.value ?  (latestSeason?.land?.landSize?.value  | modernNumber) : 0 }}</span>
                  <span class="season-title">{{ latestSeason?.land?.landSize?.unit }}</span>
                </div>

                <div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="space-between end">
                  <div>
                    <span class="text-secondary time-span" translate>Yield</span>
                    <span class="text-secondary time-span ml-1">-</span>
                  </div>

                  <div fxLayout="column" fxLayoutAlign="end start">
                    <div>
                      <span class="text-secondary time-span" translate>Expected</span>
                    </div>
                    <div fxLayout="row" fxLayoutGap="3px">
                      <span class="season-title">{{ latestSeason?.expectedYield?.value ?  (latestSeason?.expectedYield?.value | modernNumber)  : 0  }}</span>
                      <span class="season-title">{{ latestSeason?.expectedYield?.unit }}</span>
                    </div>
                  </div>

                  <div fxLayout="column" fxLayoutAlign="end start">
                    <div>
                      <span class="text-secondary time-span" translate>Actual</span>
                    </div>

                    <div fxLayout="row" fxLayoutGap="3px">
                      <span class="season-title">{{ latestSeason?.actualYield?.value ? (latestSeason?.actualYield?.value | modernNumber) : 0}}</span>
                      <span class="season-title">{{ latestSeason?.actualYield?.unit }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Land size, Expected and Actual Yield -->
              <hr *ngIf="!last" class="w-100 mt-1 mb-1">
            </ng-container>
          </div>
          <!-- /Season card -->
        </div>
      </div>
    </div>
  </div>

  <!-- view all & hide button -->
  <div class="view-more-btn" fxLayout fxLayoutAlign="end end" *ngIf="productsLength > 2">
    <div class="mb-2" fxLayoutAlign="start end" (click)="viewMoreTiles()" [ngClass]="{'d-none': isCropTiles}">
      <span class="view-all" translate>View all</span>
      <mat-icon class="arrow-symbol">keyboard_arrow_down</mat-icon>
    </div>

    <div class="mb-2" fxLayoutAlign="start end" (click)="viewMoreTiles()" *ngIf="isCropTiles">
      <span class="view-all"  translate>Hide</span>
      <mat-icon class="arrow-symbol">keyboard_arrow_up</mat-icon>
    </div>
  </div>
</div>

