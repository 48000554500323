import { Injectable } from '@angular/core';
import { IPageStateManager } from '@kalgudi/core';
import { KalgudiUser } from '@kalgudi/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ProfilePageActions } from '../constants';

@Injectable(
  {
    providedIn: 'root'
  }
)
export class KalgudiProfileStateService implements IPageStateManager<KalgudiUser, ProfilePageActions> {

  readonly data$: Observable<KalgudiUser>;
  readonly id$: Observable<string>;

  readonly action$: Observable<{ type: ProfilePageActions; payload?: any; }>;

  private pageDataSubject = new BehaviorSubject<KalgudiUser>(null);
  private actionSubject   = new Subject<{ type: ProfilePageActions; payload?: any; }>();
  private pageIdSubject = new BehaviorSubject<string>(null);

  constructor() {

    // console.log('Initializing profile state');


    this.action$ = this.actionSubject.asObservable();

    this.data$ = this.pageDataSubject.pipe(filter(val => val !== null));
    this.id$   = this.data$.pipe(map(val => val.profileKey));
  }

  get data(): KalgudiUser {
    return this.pageDataSubject.getValue();
  }

  get id(): string {
    return this.data ? this.data.profileKey : null;
  }

  get pageId(): string {
    return this.pageIdSubject.getValue();
  }

  get assistedProfileParams() {

    let params = {};

    if(this.pageId && this.id) {
      params = { pageId: this.pageId, assistedTo: this.id}
    }

    return params;
  }

  dispatchAction(action: ProfilePageActions, payload?: any): void {
    this.actionSubject.next({ type: action, payload });
    if (action === ProfilePageActions.PROFILE_UPDATED && payload) {
      this.pageDataSubject.next(payload);
    }

    if (action === ProfilePageActions.ASSIST_PROFILE) {

      this.pageIdSubject.next(payload);
    }

    // console.log('Updating state', { type: action, payload });

  }
}
