import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiServices } from '../../../classes/kalgudi-services';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-services-tile',
  templateUrl: './services-tile.component.html',
  styleUrls: ['./services-tile.component.scss']
})
export class ServicesTileComponent extends KalgudiServices implements OnInit {

  isPublicPage: boolean = false;

  constructor(
    protected injector: Injector,
    private router: Router,
  ) { super(injector); }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  onDestroyed(): void {}
}
