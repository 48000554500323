import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PageFiltersService } from '@kalgudi/pages-shared';
import { KalgudiSurveyMembersService, SurveyPageActions, SurveyStateService } from '@kalgudi/surveys';
import {
  AddSurveyMembersResponse,
  BulkMembersAdditionResponse,
  IdValueMap,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ProgramStateService } from '../../../../services/program-state.service';
import { PageShareFiltersService } from '../../../program-target-members/services/page-share-filters.service';

@Component({
  selector: 'kl-page-survey-member-add',
  templateUrl: './page-survey-member-add.component.html',
  styleUrls: ['./page-survey-member-add.component.scss']
})
export class PageSurveyMemberAddComponent extends KalgudiDestroyable implements OnInit {

  @Output()
  closeDialog = new EventEmitter<boolean>();

  filterFormGroup: FormGroup;

  memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }>;


  activeTabIndex: number;

  readonly TABS = {
    PAGE_MEMBERS: { index: 0, title: 'Page members' },
    TARGET_MEMBERS: { index: 1, title: 'Target members' },
    BULK_MEMBERS: { index: 2, title: 'Bulk members' },
  };

  selectedUsersMap: KalgudiUsersMap = {};

  readonly pageId$: Observable<string>;
  public env: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private targetedMetaService: PageShareFiltersService,
    private pageState: ProgramStateService,
    private surveyState: SurveyStateService,
    private surveyMembersService: KalgudiSurveyMembersService,
    private util: KalgudiUtilityService,
    private kalgudiStatusDialogService: KalgudiStatusDialogService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private filtersService: PageFiltersService,
  ) {

    super();
    this.env = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    // Inject all dependencies

    this.memberTargetingFilters$ = this.filtersService.memberTargetingFilters$;

    this.filterFormGroup = this.newFiltersForm;
    this.pageId$ = this.pageState.id$;

    this.activeTabIndex = this.TABS.PAGE_MEMBERS.index;
  }


  get selectedUsers(): KalgudiUserBasicDetails[] {

    return Object.values(this.selectedUsersMap);
  }

  /**
   * Users filters field
   */
  get usersFilters(): FormArray {
    return this.filterFormGroup.get('users') as FormArray;
  }

  /**
   * Products filters field
   */
  get productsFilters(): FormArray {
    return this.filterFormGroup.get('products') as FormArray;
  }

  /**
   * Business types filter field
   */
  get businessTypesFilters(): FormArray {
    return this.filterFormGroup.get('businessTypes') as FormArray;
  }

  /**
   * Locations filter field
   */
  get locationsFilters(): FormArray {
    return this.filterFormGroup.get('locations') as FormArray;
  }

  /**
   * Countries filter field
   */
  get countriesFilters(): FormArray {
    return this.filterFormGroup.get('countries') as FormArray;
  }

  /**
   * States filter field
   */
  get statesFilters(): FormArray {
    return this.filterFormGroup.get('states') as FormArray;
  }

  /**
   * Districts filter field
   */
  get districtsFilters(): FormArray {
    return this.filterFormGroup.get('districts') as FormArray;
  }

  /**
   * Form
   */
  private get newFiltersForm(): FormGroup {

    return new FormGroup({

      users: new FormControl([]),
      products: new FormArray([]),
      businessTypes: new FormArray([]),
      locations: new FormArray([]),
      countries: new FormArray([]),
      states: new FormArray([]),
      districts: new FormArray([]),
      gender: new FormControl(''),
    });
  }

  ngOnInit() { }

  onDestroyed(): void {}

  /**
   * Adds the page members to the survey
   */
  addPageMembers() {
    const payload = this.getAddMembersPayload(this.selectedUsers);

    this.addMembers(payload);

  }

  /**
   * Adds the targeted members to the survey
   */
  addTargetedMembers() {

    const payload: AddSurveyMembersResponse = {
      filter: {
        ...this.filterFormGroup.value,
        pageId: this.pageState.id,
      },
    };

    if (payload['filter'] && !payload['filter'].gender) {
      delete payload['filter'].gender;
    }
    this.addMembers(payload);
  }


  addMembers(payload: any) {

    this.notification.showSpinner(true);

    this.surveyMembersService.addMembersList(this.surveyState.id, payload)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => {
          this.surveyState.dispatchAction(SurveyPageActions.MEMBER_ADDED, res),
          this.notification.showMessage('Members targeted');
          this.closeDialog.emit();
        },
        err => this.util.showApiErrorMessage(err),
      );
  }

  /**
   * Add bulk members to page survey
   */
  addBulkMembers(payload: string[]) {

    this.notification.showSpinner(true);

    const filter = {
      pageId: this.pageState.id
    };

    this.surveyMembersService.addBulkMembersList(this.surveyState.id, payload, filter)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.util.showApiErrorMessage(err),
      );
  }

  /**
   * Construct and gets, the add members payload.
   */
  protected getAddMembersPayload(users: KalgudiUserBasicDetails[]): AddSurveyMembersResponse {

    const payload: AddSurveyMembersResponse  = {
      actors: users,
    };

    return payload;
  }

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);

    this.surveyState.dispatchAction(SurveyPageActions.MEMBER_ADDED, res),
    this.closeDialog.emit();
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }
}
