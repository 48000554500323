<div class="gct" *ngIf="targetLanguageList?.length > 0">
  <p class="gct-header">
    <span *ngIf="!selectedLanguage">
      <span translate>Translate to </span>

      <span *ngFor="let language of targetLanguageList; let last = last;"
        [ngClass]="{'link': selectedLanguage?.id !== language?.id}">

        <span *ngIf="selectedLanguage?.id !== language?.id; else selectedLanguageTemplate;"
          title="Translate the text to {{language?.title}}"
          (click)="translate(text, source, language?.id); $event.stopPropagation();">
          {{language?.title}}
        </span>

        <ng-template #selectedLanguageTemplate>
          <span>{{language?.title}}</span>
        </ng-template>

        <!-- <span *ngIf="!last">, </span> -->
      </span>
    </span>

    <!-- <span class="gct-brand" *ngIf="selectedLanguage">
      <span *ngIf="progress" translate>Translating... </span>

      <ng-container *ngIf="!progress && translatedText">
        <span><span translate>Translated to</span> {{selectedLanguage?.title}} <span translate>by</span> </span>
        <img height="15px" src="https://kalgudi.com/data/website/Google_2015_logo.svg" />
      </ng-container>

      <span class="gct-text-error" *ngIf="!progress && !translatedText"
        [title]="'Could not translate at this moment, please try again later!' | translate"><span translate>Error while translating</span></span>
    </span> -->
  </p>

  <div fxLayout="column">
    <p  *ngIf="!progress && translatedText" class="gct-text">
      {{translatedText}}
    </p>

    <span class="gct-brand" fxLayoutAlign="end end" *ngIf="selectedLanguage">
      <span *ngIf="progress" translate>Translating... </span>

      <ng-container *ngIf="!progress && translatedText">
        <span class="gct"><span translate>Translated to</span> {{selectedLanguage?.title}} <span translate>by</span> </span>
        <img height="13px" src="https://kalgudi.com/data/website/Google_2015_logo.svg" />
      </ng-container>

      <span class="gct-text-error" *ngIf="!progress && !translatedText"
        [title]="'Could not translate at this moment, please try again later!' | translate"><span translate>Something went wrong</span></span>
    </span>
  </div>

</div>
