import { KeyValue } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService, StoreProductsSearchService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, kalgudiAnimations, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { FileMimeTypes, KalgudiUserBasicDetails, ProgramShareRequest, ProgramShareUpdate } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import {
  KalgudiPagesMembersPickerService,
} from '../../../program-members-picker-dialog/services/kalgudi-pages-members-picker.service';
import { PageShareFiltersService } from '../../../program-target-members/services/page-share-filters.service';
import { KalgudiProgramTraining } from '../../classes/kalgudi-program-training';


@Component({
  selector: 'kl-program-training-form',
  templateUrl: './training-form.component.html',
  styleUrls: ['./training-form.component.scss'],
  animations: kalgudiAnimations,
  viewProviders: [ PageShareFiltersService ]
})
export class TrainingFormComponent extends KalgudiProgramTraining implements OnInit {

  rowsCount: number;
  showAdvanced: boolean;
  isMobileDevice: boolean;
  isAttendanceHour: boolean = false;
  maxDate: Date;
  minDate: Date = new Date();

  showScheduleTime: boolean = false;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];
  shareWithValue: string;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected programDialogs: KalgudiPagesMembersPickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected targetedMetaService: PageShareFiltersService,
    protected mobileMenuService: KalgudiBottomSheetService,
    private storeProductsSearchService: StoreProductsSearchService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      programDialogs,
      util,
      socialDataNormalizer,
      kalgudiApp,
      kalgudiPageService,
      sharePostService,
      targetedMetaService,
      mobileMenuService
    )

    this.lstOfAttachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.lstOfAttachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));

    // Limiting the date for today;
    let today = new Date();
    today.setDate(today.getDate());
    this.maxDate = today;
   }

  ngOnInit(): void {

    // check the device type
    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 8 : 3;

    this.updateDateLocale();

    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }

    // Checking appId for total attendance & no. of hours
    this.isAttendanceHour = (this.environment?.appId === 'FPO_APP' || this.environment?.appId === 'FARMERS_APP' || this.environment?.appId === 'FPO_NET_APP' || this.environment?.appId === 'SAM_FPO' || this.environment?.appId === 'SAM_FARMER' || this.environment?.appId === 'ORMAS_SHG' || this.environment.appId === 'SHG_NET_APP');
  }

  toggleAdvanced() {
    this.showAdvanced = true;
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  protected onDestroyed(): void { }

  /**
   * Calls after posting a share
   * @param payload
   * @param response
   */
  protected onPostCreated(payload: ProgramShareRequest, response: ProgramShareUpdate): void {

    this.postCreated.emit(response);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  /**
   * Triggered while changing share-with field
   */
  shareWithChanged(event: string) {
    this.shareWithValue = event;
  }

  /**
   * Pattern for removing decimal points
   * @param event
   * @returns
   */
  checkIfInteger(event: any){
    var x = event.which || event.keycode;
    if ((x == 46 || x == 101 || x == 43 || x == 45) && (x < 48 || x > 57)){
      return false;
    }
  }

  /**
   * Toggle the schedule date & time
   */
  toggleSchedule() {
    this.showScheduleTime = !this.showScheduleTime;
    this.clearDate();
  }
}
