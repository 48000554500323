import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { AttachmentList, FileMimeTypes } from '@kalgudi/types';

@Directive()
export abstract class CommonProjectCreationForm extends KalgudiDestroyable {


  @Input()
  projectForm: FormGroup;

  @Output()
  projectFormChange = new EventEmitter<FormGroup>();

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE, FileMimeTypes.DOCUMENT ];


  projectCommonForm: FormGroup;

  private fb: FormBuilder;
  private imagePickerService: KalgudiImagePickerService;
  private util: KalgudiUtilityService;

  constructor(protected injector: Injector) {

    super();

    this.fb = this.injector.get<FormBuilder>(FormBuilder);
    this.imagePickerService = this.injector.get<KalgudiImagePickerService>(KalgudiImagePickerService);
    this.util = this.injector.get<KalgudiUtilityService>(KalgudiUtilityService);

    this.projectCommonForm = this.commonForm;
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Attachments form Uri domain field.
   */
  get attachmentsField(): FormArray {
    return this.projectForm.get('attachments') as FormArray;
  }

  /**
   * Share form attachments form array controls
   */
  get attachmentsControls(): AbstractControl {
    return this.projectForm.get('attachments');
  }

  /**
   * common form group
   */
  private get commonForm(): FormGroup {

    return this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      attachments: new FormControl([])

    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    const attachments: AttachmentList = this.attachmentsControls.value || [];

    attachments.splice(index, 1);

    this.attachmentsControls.patchValue(attachments);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Initializes the page form
   */
  protected initProjectForm() {

    const form = this.projectCommonForm;

    form.patchValue(this.projectForm.value);

    this.projectForm = form;

    this.projectFormChange.emit(this.projectForm);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
