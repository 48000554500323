<main id="task-updates" *ngIf="(stream$ | async) as stream">
  <ng-container *ngIf="stream && stream?.length > 0">
    <div fxLayout="column" fxLayoutGap="16px" *ngFor="let update of stream; let i = index">
      <kl-task-updates-tile [update]="update"
        [userProfileKey]="userDetails.profileKey"
        (deletePost)="deletePost($event)">
      </kl-task-updates-tile>
    </div>
  </ng-container>

  <ng-container *ngIf="(paginator$ | async) as paginator">
    <ng-container *ngIf="!stream || stream?.length <= 0">
      <div *ngIf="!activityTypes" fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column">
        <kl-no-content
          svgIcon= "assets/svgs/updates.svg"
          [text]="'No updates for this task' | translate"
          [progressText]="'Loading task updates...' | translate"
          [progress]="paginator?.loading">
        </kl-no-content>
      </div>

      <div fxLayout="row" *ngIf="activityTypes && paginator?.loading"
        fxLayoutAlign="center center" fxLayout.xs="column">
        <kl-no-content
          [text]="''"
          [progressText]="'Loading task updates...' | translate"
          [progress]="paginator?.loading">
        </kl-no-content>
      </div>
    </ng-container>
  </ng-container>
</main>
