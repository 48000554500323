<div id="chat-list">
  <div>

    <ul #scrollMe *ngIf="messages?.length > 0" class="normal-chat-list" [ngClass]="{'reply-box' : !showBox, 'no-actions': !isAllowedToReply}">

      <div class="border-bottom p-3 author-info" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>

        <!-- User profile pic -->
        <div fxFlex="35px" class="profile-pic rounded-circle small">
          <img class="w-100 h-100 rounded-circle"
            [src]="(authorDetails?.sender?.profilePicURL || authorDetails?.sender?.profilePicUrl | resizeImage: '150x150') | prefixDomain"
            [klErrorImages]="[
              (authorDetails?.sender?.profilePicURL || authorDetails?.sender?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]" />
        </div>
        <!-- / User Profile pic -->

        <!-- user Details -->
        <div class="author-details" fxFlex="90%" fxFlex.xs="100%">

          <!-- User name -->
          <div fxLayout="column" class="mw-100">
            <div class="mw-70 author-first-name mr-1">
              <a class="text-capitalize author-name mb-0 text-truncate d-inline-block"
                (click)="viewProfile(authorDetails?.sender?.profileKey); $event.stopPropagation()">
                {{ authorDetails?.sender?.firstName }}
              </a>
            </div>

            <div fxLayout="row" class="mw-100">
              <div fxFlex="100%" fxFlex.xs="95%">
                <p class="text-truncate mb-0 mt-n2" fxLayout.xs="column">
                  <span class="biz-type mb-0 text-truncate mt-n4px business-type" *ngIf="authorDetails?.sender?.locationLong">
                    {{ authorDetails?.sender?.locationLong | translate }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <!-- /User name -->

        </div>

        <!-- / User details -->
      </div>

      <ng-container *ngFor="let msg of messages">
        <li *ngIf="!msg?.hide">
          <kl-chat-bubble
            [isAllowedToReply]="isAllowedToReply"
            [sender]="receiver"
            [showAuthor]="showAuthor"
            [message]="msg"
            (replyMessage)="replyToMsg($event)"
            (deleteMessage)="onDeleteMessage($event)"
            (onHideMessage)="hideMessage($event)">
          </kl-chat-bubble>
        </li>
      </ng-container>
    </ul>
  </div>

</div>
