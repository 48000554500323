<main id="status-logs-wrapper">
  <div fxLayout="row" fxLayoutGap="8px" class="status-counts">

    <p>
      <span class="text-success" translate>Added: </span>
      {{ usersList?.success }}
    </p>

    <p>
      <span class="text-danger" translate>Failed: </span>
      {{ usersList?.failed }}
    </p>

  </div>


  <section class="logs-list-wrapper overflow-auto">

    <mat-card class="w-100 p-0 logs-list overflow-auto logs-wrapper" fxHide.xs>

      <div class="border-bottom logs-header px-3 py-2 mb-2 font-weight-bold" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="8px">
        <p class="mb-0 header-title" fxFlex="calc(35% - 8px)" translate>Id</p>
        <p class="mb-0 header-title" fxFlex="calc(35% - 8px)" translate>User</p>
        <p class="mb-0 header-title mr-2" fxFlex="calc(10% - 8px)" translate>Status</p>
        <p class="mb-0 header-title" fxFlex="calc(20% - 8px)" translate>Failure reason</p>
      </div>

      <div fxLayout="column" *ngFor="let user of usersList?.users; let last = last;">

        <div class="log py-2 px-3" fxLayout="row" fxLayoutAlign="space-between center"
          [ngClass]="{'border-bottom': !last}">

          <div class="profile" fxFlex="calc(35% - 8px)">
            <p class="mb-0 author-name">{{ user?.loginId }}</p>
          </div>

          <div class="profile mr-2" fxFlex="calc(35% - 8px)">
            <p class="mb-0 author-name">{{ user?.firstName }}</p>
          </div>

          <div class="mr-2" fxFlex="calc(10% - 8px)">

            <p class="mb-0 type">
              <span [ngClass]="{'text-success': user?.status }">

                <strong>{{user?.status ? 'Added' : 'Failed' }}</strong>
              </span>
            </p>
          </div>

          <div fxFlex="calc(20% - 8px)">
            <p class="mb-0 date text-muted" >{{ user?.failedReason ? user?.failedReason : '-'}}</p>
          </div>
        </div>
      </div>

    </mat-card>
  </section>


  <!-- Status logs mobile UI -->
  <section class="log-tile" *ngFor="let user of usersList?.users; let last = last;" fxHide.gt-xs>
    <mat-card class="p-2">
      <div fxLayoutGap="5px" fxLayoutAlign="start center">
        <div class="status-circle" [ngClass]="user?.status ? 'bg-success' : 'bg-danger'"></div>
        <div class="title w-100 text-truncate mw-100">{{ user?.loginId }}</div>
      </div>
      <div class="border-bottom w-100 text-truncate mw-100" *ngIf="user?.firstName">{{ user?.firstName }}</div>
      <div>
        <p class="mb-0 date border-bottom" *ngIf="user?.failedReason">{{ user?.failedReason }}</p>
      </div>
      <div class="pt-1" [ngClass]="user?.status ? 'text-success' : 'text-danger'">
        {{user?.status ? 'Added' : 'Failed' }}
      </div>
    </mat-card>
  </section>
  <!-- Status logs mobile UI -->

</main>

