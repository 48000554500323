<section class="position-relative procurement-details">
  <!-- common traceability details -->
  <kl-common-traceability-details
    [bgColor]= "'#EDFFF0'"
    [level2ProductDetails]="level2ProductDetails"
    [traceabilityDetails]="traceabilityDetails"
    [basicDetails]="level2ProductDetails?.resellerDetails || level2ProductDetails?.manufacturerDetails"
    [showBreadCrumbs]="true"
    [showGallery]="true"
    [attachments]="procurementAttachments"
    [details]="!procurementDetails?.length"
    [showLocationDetails]="true"
    [level3ProductDetails]="level3ProductDetails"
    [supplierInfo]="supplierDetails"
    [profileInfo]="sellerProfileInfo">

    <ng-container first-card *ngIf="procurementDetails?.length">
      <!-- journey of your procurement -->
      <div *ngFor="let items of procurementDetails; let index = index">
        <kl-traceability-activities [items]="items" [index]="index"></kl-traceability-activities>
      </div>
      <!--/ journey of your procurement -->
    </ng-container>

    <mat-card first-card *ngIf="!procurementDetails?.length"  fxLayout="column" class="no-content mt-3 position-relative">
      <div class="circle rounded-circle ml-3 mt-1" fxLayoutAlign="end end"></div>

      <div class="w-100" fxLayout="column" fxLayoutAlign="center center">
        <img class="image" src="assets/images/no-activities.gif" width=210 height=115>
        <span class="text-muted text">No journey of your procurements</span>
      </div>
    </mat-card>

  </kl-common-traceability-details>
  <!-- common traceability details -->
</section>
