import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { KalgudiShareUpdateService } from '../../services/kalgudi-share-update.service';
import { KalgudiDestroyable } from '@kalgudi/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';

@Component({
  selector: 'kl-likes-list',
  templateUrl: './likes-list.component.html',
  styleUrls: ['./likes-list.component.scss']
})
export class LikesListComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  shareId: string;

  @Output()
  viewProfilePage = new EventEmitter();

  likesList: any[];
  progress: boolean;

  constructor(
    protected sharePost: KalgudiShareUpdateService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
  ) {
    super();
  }

  ngOnInit() {
    this.getLikesListUsersDetails()
  }

  onDestroyed(): void {}

  /**
   * To get the list of users who like the post
   */
  getLikesListUsersDetails() {
    this.progress = true;

    this.sharePost.getLikesList(this.shareId)
    .pipe(
      takeUntil(this.destroyed$),
      finalize(() => {
        this.progress = false;
      })
    )
    .subscribe(
      res => {
        this.likesList = res.results;
      }
    )
  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
    this.viewProfilePage.emit();
  }

}
