<div class="credit-activity">
  <!-- logs -->
  <div class="h-4">
    <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
  </div>

  <table mat-table [dataSource]="dataSource" class="w-100">

    <ng-container matColumnDef="remark">
      <th mat-header-cell *matHeaderCellDef>Activity</th>
      <td mat-cell *matCellDef="let credit" fxLayout="column">
        <div>{{ credit?.creditHistory?.remark }}</div>
        <div>{{ credit?.CTS | timeAgo }} ago</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let credit">
        {{ credit?.creditHistory?.user?.firstName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="entityName">
      <th mat-header-cell *matHeaderCellDef>Page</th>
      <td mat-cell *matCellDef="let credit">
        {{ credit?.creditHistory?.page?.pageTitle }}
      </td>
    </ng-container>

    <ng-container matColumnDef="closingCredit">
      <th mat-header-cell *matHeaderCellDef>Credit history</th>
      <td mat-cell *matCellDef="let credit">
        <div [ngClass]="{'amt-debit' : credit?.creditHistory?.creditAction === 'DEBIT','amt-credit' : credit?.creditHistory?.creditAction === 'CREDIT' }">
          <span>
              {{ credit?.creditHistory?.creditAction == 'DEBIT' ? '-' : '+' }}
          </span>
          <span>{{credit?.creditHistory?.effectiveCredit}}</span>
        </div>
        <div>closing credit: {{ credit?.creditHistory?.closingCredit }}</div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer h-60"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>

</div>

<!-- No content -->
<div fxLayout="row" fxLayoutAlign="center center" class="p-4" *ngIf="!dataSource?.data?.length">
  <ng-container>
    <kl-no-content faIcon="fa fa-frown" text="No credit transfer done yet!">
    </kl-no-content>
  </ng-container>
</div>
<!--/ No content -->
