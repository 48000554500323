import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import {
  BreadcrumbList,
  KalgudiProject,
  MemberStatistics,
  PartialData,
  ProjectRole,
  SelectableUIMenu,
  TaskStatistics,
  UIMenu,
} from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../config';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { AddProjectMembersDialogService } from '../../add-project-members/services/add-project-members-dialog.service';
import { KalgudiProjectCreationService } from '../../project-creation/services/kalgudi-project-creation.service';
import { KalgudiProjectsListActionsService } from '../../project-list/services/kalgudi-projects-list-actions.service';
import { ProjectStateService } from '../../project-shared/services/project-state.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';

@Directive()
export abstract class KalgudiTaskListHeader extends KalgudiDestroyable {

  @Input()
  breadCrumbsList: BreadcrumbList;

  @Input()
  projectsMenu: UIMenu[];


  @Input()
  totalMembers: MemberStatistics;

  @Input()
  totalTasks: TaskStatistics;

  @Input()
  selectedView: string;

  @Input()
  loggedInUserTasks: boolean;

  @Input()
  projectCategories: SelectableUIMenu[];

  @Output()
  projectsOption = new EventEmitter<any>();

  projectDetails: KalgudiProject;

  projectRoles = ProjectRole;

  searchForm: FormGroup;

  private stateChangeService: ProjectStateService;
  private notification: KalgudiNotification;
  private addProjectMembersService: AddProjectMembersDialogService;
  private stateService: KalgudiProjectStateService;
  private projectService: KalgudiProjectService;
  private taskActionsService: KalgudiTaskActionsService;
  private projectListActionService: KalgudiProjectsListActionsService;
  private appRouting: KalgudiProjectRouteConfig;
  private projectCreationService: KalgudiProjectCreationService;

  constructor(protected injector: Injector) {

    super();

    this.stateChangeService         = this.injector.get<ProjectStateService>(ProjectStateService);
    this.addProjectMembersService   = this.injector.get<AddProjectMembersDialogService>(AddProjectMembersDialogService);
    this.stateService               = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.notification               = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.projectService             = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.taskActionsService         = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);
    this.projectListActionService   = this.injector.get<KalgudiProjectsListActionsService>(KalgudiProjectsListActionsService);
    this.appRouting                 = this.injector.get<KalgudiProjectRouteConfig>(KL_ROUTE_CONFIG);
    this.projectCreationService     = this.injector.get<KalgudiProjectCreationService>(KalgudiProjectCreationService);

    // Initialize search form
    this.searchForm = new FormGroup({
      searchKeyword: new FormControl('')
    });

    this.stateService.projectDetails$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => this.projectDetails = res);

  }



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  showProjectsList(menu) {
    this.projectsOption.emit(menu);
    this.selectedView = menu.id;
  }

  openFilterSidebar() {

    this.stateChangeService.updateFilterSideBarStatus();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open add members dialog
   */
  showAddMembersDialog() {

    this.projectListActionService.showAddMembersDialog(this.projectDetails.id)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.updateProjectDetails(this.projectDetails.id);
        }
      );
  }

  /**
   * Opens task creation dialog
   */
  openTaskCreationDialog() {
    this.taskActionsService.openTaskCreationDialog(this.projectDetails.id)
      .subscribe(
        (res) => {
          this.notification.showMessage('Task created successfully');
          this.stateService.reloadTasks();
          this.updateProjectDetails(this.projectDetails.id);
        }
      );
  }

  /**
   * Deletes the project
   */
  deleteProject(projectId: string) {
    this.projectListActionService.deleteProject(projectId, this.filters)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res =>  this.projectDeleteHandler(res),
        err =>  this.projectDeleteErrHandler(err)
      );
  }

  /**
   * Updates the project
   */
  showUpdateProject(projectId: string) {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories, projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.notification.showMessage('Project updated successfully');
          this.updateProjectDetails(this.projectDetails.id);
        }
      );
  }


  /**
   * Child class should implement the filters
   */
  protected abstract get filters(): PartialData;

  /**
   * Event handler for successful project deletion
   */
  protected projectDeleteHandler(res) {
    this.notification.showMessage('Project deleted successfully');
    this.appRouting.toProjectList( null  , {type: 'GRID'});
  }

  /**
   * Event handler for failed to delete project
   */
  protected projectDeleteErrHandler(err: Error) {
    this.notification.showMessage('Failed to delete a project');
  }

  /**
   * Updates the project details with latest data
   */
  private updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



}
