import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiPageApiService } from './services/kalgudi-page-api.service';
import { KalgudiPageMemberApiService } from './services/kalgudi-page-member-api.service';
import { KalgudiPageMembersService } from './services/kalgudi-page-member.service';
import { KalgudiPageService } from './services/kalgudi-page.service';
import { KalgudiProgramSearchApiService } from './services/kalgudi-program-search-api.service';
import { KalgudiProgramSearchService } from './services/kalgudi-program-search.service';
import { ProgramStateService } from './services/program-state.service';
import { KalgudiDialogsModule } from '@kalgudi/common';

@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  providers: [
    KalgudiPageService,
    KalgudiPageApiService,
    KalgudiProgramSearchService,
    KalgudiProgramSearchApiService,
    ProgramStateService,

    // Stream apis
    KalgudiPageMembersService,
    KalgudiPageMemberApiService
  ],
})
export class KalgudiProgramsModule {

  public static forChild(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiProgramsModule> {

    return {
      ngModule: KalgudiProgramsModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
