import { Component, Injector, OnInit } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';

import { CommonProjectCreationForm } from '../../classes/common-project-creation-form';

@Component({
  selector: 'kl-common-creation-form',
  templateUrl: './common-creation-form.component.html',
  styleUrls: ['./common-creation-form.component.scss']
})
export class CommonCreationFormComponent extends CommonProjectCreationForm implements OnInit {

  isMobileDevice: boolean;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initProjectForm();

    if(checkMobileDevice()) {
      this.isMobileDevice = true;
    }
  }

  protected onDestroyed(): void { }

}
