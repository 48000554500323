import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KalgudiDestroyable, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { KalgudiTasksListService } from '../../services/kalgudi-tasks-list.service';


@Component({
  selector: 'kl-home-task-tile',
  templateUrl: './home-task-tile.component.html',
  styleUrls: ['./home-task-tile.component.scss']
})
export class HomeTaskTileComponent extends KalgudiDestroyable  implements OnInit {

  @Input()
  profileKey: string;

  userCurrentTasks: any[];
  farmersTasks: any[];

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute,
    private tasksListService: KalgudiTasksListService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
    // private tasksActionService: KalgudiTaskActionsService
    ) {

    super();
  }

  ngOnInit() {
    // this.initStream();

    this.getTasksList(0, 500);
  }

  onDestroyed(): void {}

  /**
   * Navigates to tasks list
   */
  openTaskListPage() {
    this.appRouting.toTasksList();
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullview(title: string, taskId: string, taskDetails: KalgudiProjectTask) {

    this.appRouting.toTask( { projectId: taskDetails.projectId, taskId });
  }

  /**
   * Open task stats dialog
   */
  openTaskStatsDialog(statType: string, taskId: string, taskTitle: string) {

    // this.tasksActionService.showAddTaskStatsDialog(taskId, statType, taskTitle)
    //   .pipe(
    //     // Subscribe till `$destroyed` is not emitted
    //     takeUntil(this.destroyed$),
    //   )
    //   .subscribe(
    //     res => {
    //       // Write code here
    //     }
    //   );
  }

  /**
   * To get all the tasks list
   */
  getTasksList(offset?: number, limit?: number) {
    this.tasksListService.getTasks(offset, limit, '', this.profileKey, { sortBy: 'date' })
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.userCurrentTasks = res.items.filter(task => task.userRole === 'MEMBER' && task.myTasks && task.isActive);
          this.farmersTasks = res.items.filter(task => task.userRole === 'ADMIN' && task.farmerTasks && task.isActive);
        }
      )
  }

}
