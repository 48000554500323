import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { StoreAdminAgGridModule } from '@kalgudi/ag-grid-lib/ag-grid';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridStatsTablePageComponent } from './components/ag-grid-stats-table-page/ag-grid-stats-table-page.component';
import { PageStatsListComponent } from './components/page-stats-list/page-stats-list.component';
import { PageStatsComponent } from './components/page-stats/page-stats.component';
import { QuickSearchComponent } from './components/quick-search/quick-search.component';
import { NoContentModule } from './no-content/no-content.module';
import { AgGridMapperService } from './services/ag-grid-mapper.service';
import { StatsTablePageService } from './services/stats-table-page.service';
import { UtilService } from './services/util.service';



@NgModule({
  declarations: [
    PageStatsComponent,
    AgGridStatsTablePageComponent,
    PageStatsListComponent,
    QuickSearchComponent
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Material
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,

    FlexLayoutModule,

    AgGridModule,

    NoContentModule,

    // Store admin libraries
    StoreAdminAgGridModule,
  ],
  exports: [
    PageStatsComponent,
    AgGridStatsTablePageComponent,
    PageStatsListComponent,
    QuickSearchComponent,
  ],
  providers: [
    StatsTablePageService,
    UtilService,
    AgGridMapperService
  ]
})
export class StoreAdminCommonModule { }
