import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiStoreProduct } from '@kalgudi/types';

import { KalgudiStoreProducts } from '../../classes/kalgudi-store-products';

@Component({
  selector: 'kl-store-products-list',
  templateUrl: './store-products-list.component.html',
  styleUrls: ['./store-products-list.component.scss']
})
export class StoreProductsListComponent extends KalgudiStoreProducts implements OnInit {

  @Output()
  selectedProducts = new EventEmitter<KalgudiStoreProduct[]>();

  selectedProductsMap: any = {};

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }


  ngOnInit() {
    this.initStream();
  }


  onDestroyed(): void {}

  /**
   * Products sends back to the parent
   */
  submit() {
    this.selectedProducts.emit(Object.values(this.selectedProductsMap));
  }

  /**
   * Toggles the product
   */
  toggleProductSelection(user: KalgudiStoreProduct): boolean {

    // Check if the product is already selected or not
    const selected: boolean = this.isProductSelected(user);

    // Toggle the product selection
    selected ? this.deSelectProduct(user) : this.selectProduct(user);

    // Toggle initial selection state
    return !selected;
  }


  /**
   * Returns `true` if the product is selected otherwise `false`.
   */
  isProductSelected(user: KalgudiStoreProduct): boolean {

    // A product is selected if the product details exists in the
    // selected products map.
    return !!(this.selectedProductsMap[user.productId_level3]);
  }

  /**
   * Marks a product as selected. Moves the product to the selected products list.
   */
  selectProduct(user: KalgudiStoreProduct): void {

    this.selectedProductsMap[user.productId_level3] = user;
  }

  /**
   * Removes the product from the selected list
   */
  deSelectProduct(user: KalgudiStoreProduct): void {

    delete this.selectedProductsMap[user.productId_level3];
  }


}
