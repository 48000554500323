<mat-card class="my-2 program-fullview p-0">
  <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutGap.xs="18px" class="px-3 pt-3 program-info">

    <!-- image -->
    <div fxLayoutAlign.xs="center center" fxLayoutAlign="center center">
      <div class="picture position-relative">
        <div class="image">
          <img [src]="pageDetails?.pageProfilePic | prefixDomain" (error)="img.src = defaultProfilePic" #img>
        </div>
        <span class="edit position-absolute" fxLayout="row" fxLayoutAlign="center center" *ngIf="pageDetails?.memberRole === memberRoles?.ADMIN">
          <button class="mat-elevation-z1" mat-mini-fab color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        </span>
        <input class="input-file position-absolute" type="file" [title]="'Click to change profile picture' | translate"
          (change)="uploadProfilePic($event)">
      </div>
    </div>
    <!-- /image -->

    <!-- content -->

    <div fxLayout="column" class="h-100 my-2 mw-100 program-header" fxLayoutAlign="center center" fxLayoutAlign.xs="center start " fxLayoutGap="5px" fxLayoutGap.xs="0">

      <div class="mw-100 cursor-pointer" (click)="selectTab(programTabs.UPDATES)">
        <h2 class="text-truncate text-center text-capitalize mw-100 mb-0" [title]="pageDetails?.pageTitle">{{ pageDetails?.pageTitle }}</h2>
      </div>

      <div class="mw-100" *ngIf="pageDetails?.createdBy?.firstName">
        <p class="mb-0 mw-100 text-truncate d-inline-block business-name">
          <span translate>Organised by </span>
          <span class="text-truncate" [title]="pageDetails?.createdBy?.firstName"><a>{{ pageDetails?.createdBy?.firstName}}</a></span>
          <!-- <span [title]="pageDetails?.createdBy?.businessName"><a>{{ pageDetails?.createdBy?.businessName }}</a></span> -->
        </p>
      </div>
      <div *ngIf="pageDetails?.pageType">
        <p class="page-type text-muted" translate>{{pageDetails?.pageType | titlecase}}</p>
      </div>
      <div class="about" *ngIf="pageDetails?.pageDescription">
        <p class="mb-0 truncate text-justify">
          {{ pageDetails?.pageDescription }}
        </p>
      </div>


    </div>


    <!-- social buttons -->
    <!-- <div fxLayout="row" class="w-100" fxLayoutAlign="start end" fxLayoutGap="10px"> -->

      <!-- <div *ngIf="pageDetails?.facebookLink">
        <a [href]="pageDetails?.facebookLink" target="_blank">
          <button mat-icon-button>
            <i class="fab fa-facebook-f icon-facebook"></i>
          </button>
        </a>
      </div>

      <div *ngIf="pageDetails?.twitterLink">
        <a [href]="pageDetails?.twitterLink" target="_blank">
          <button mat-icon-button>
            <i class="fab fa-twitter icon-twitter"></i>
          </button>
        </a>
      </div>

      <div *ngIf="pageDetails?.linkedInLink">
        <a [href]="pageDetails?.linkedInLink" target="_blank">
          <button mat-icon-button>
            <i class="fab fa-linkedin-in icon-linkedIn"></i>
          </button>
        </a>
      </div> -->

    <!-- </div> -->
    <!-- content -->

  </div>

  <!-- Contributors, Members and Admin count-->
  <div fxLayout="row" class="w-100 mb-2 px-3 pb-2 cursor-pointer"  fxLayoutAlign="center center">

    <div fxFlex="28%"  (click)="selectTab(programTabs.ADMINS)" class="border-right admins mr-2 pr-2 members-count">
      <p class="count mb-0 text-center" *ngIf="pageDetails?.adminsCount >= 0">{{ pageDetails?.adminsCount | modernNumber }}</p>
      <p [ngPlural]="pageDetails?.adminsCount || 0" class="mb-0 text-center">
        <ng-template ngPluralCase="=0"><span translate>Admin</span></ng-template>
        <ng-template ngPluralCase="=1"><span translate>Admin</span></ng-template>
        <ng-template ngPluralCase="other"><span translate>Admins</span></ng-template>
      </p>
    </div>


    <div fxFlex="30%" (click)="selectTab(programTabs.MEMBERS)" class="border-right followers mr-2 pr-2 members-count" [ngClass]="{'followers': !pageDetails?.followersCount}">
      <p class="count mb-0 text-center" *ngIf="pageDetails?.followersCount >= 0">{{ pageDetails?.followersCount | modernNumber }}</p>
      <p [ngPlural]="pageDetails?.followersCount || 0" class="mb-0 text-center">
        <ng-template ngPluralCase="=0"><span  translate>Follower</span></ng-template>
        <ng-template ngPluralCase="=1"><span translate>Follower</span></ng-template>
        <ng-template ngPluralCase="other"><span translate>Followers</span></ng-template>
      </p>
    </div>

    <div fxFlex="35%" (click)="selectTab(programTabs.CONTRIBUTORS)" [ngClass]="{'contributors': !pageDetails?.contributorsCount}" class="members-count border-right mr-2 pr-2" >
      <p class="count mb-0 text-center" *ngIf="pageDetails?.contributorsCount >= 0">{{ pageDetails?.contributorsCount | modernNumber }}</p>

      <p [ngPlural]=" pageDetails?.contributorsCount || 0" class="mb-0 text-center">
        <ng-template ngPluralCase="=0"><span translate>Contributor</span></ng-template>
        <ng-template ngPluralCase="=1"><span translate>Contributor</span></ng-template>
        <ng-template ngPluralCase="other"><span translate>Contributors</span></ng-template>
      </p>
    </div>

    <!-- All Locations -->
    <div fxFlex="7%" fxLayout="column" fxLayoutAlign="center center" class="members-count" >
      <button mat-icon-button fxLayoutAlign="center center" (click)="fetchLocations()">
        <mat-icon>pin_drop</mat-icon>
      </button>
      <!-- <p class="mt-n1">Locations</p> -->
    </div>
    <!-- / All Locations -->

  </div>
  <!-- /Contributors, Members and Admin count -->

  <!-- Join button -->
  <!-- <div fxLayout="row" class="w-100">
    <div class="w-100">
      <button mat-flat-button class="w-100" color="accent" [disabled]="true">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
          <div class="mt-n1 mx-2">
            <svg-icon src="assets/svgs/join.svg" [svgStyle]="{ 'width.px': 25, 'height.px': 25}" [applyCss]="true"
              class="join-icon">
            </svg-icon>
          </div>
          <span class="icon-label">Join</span>
        </div>
      </button>
    </div>

  </div> -->
  <!-- /Join button -->

</mat-card>
