<div class="answer-tile">

  <!-- Author Details -->
  <kl-post-author
    [TS]="answer?.TS"
    [showLocation]="false"
    [author]="answer?.author"
    avatarSize="sm">
  </kl-post-author>
  <!--/ Author Details -->

  <!-- Answer -->
  <div class="answer-info py-2">{{ answer?.answer }}</div>
  <!-- /Answer -->

  <!-- Image -->
  <div class="my-2">
    <!-- <kl-post-tile-image-attachment [url]="answer?.imageUri"></kl-post-tile-image-attachment> -->
    <ng-container *ngIf="answer?.lstOfAttachments?.length">

      <kl-social-attachments [attachments]="answer?.lstOfAttachments"></kl-social-attachments>

    </ng-container>
  </div>
  <!-- /Image -->

  <!-- Count of Likes and Comments -->
  <div class="count px-3 text-muted" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="5px">

    <!-- Likes count -->
    <div class="counts" *ngIf="answer?.likesCount" fxLayout fxLayoutGap="3px">
      <span *ngIf="answer?.likesCount">{{ answer?.likesCount | modernNumber }}</span>
      <span [ngPlural]="answer?.likesCount">
        <ng-template ngPluralCase="=1" ><span translate>Like</span></ng-template>
        <ng-template ngPluralCase="other"><span translate>Likes</span></ng-template>
      </span>
    </div>
    <!-- / Likes count -->

    <span *ngIf="answer?.likesCount && answer?.commentsCount">
      <i fxLayoutAlign="start center" class="fa fa-circle separation" aria-hidden="true"></i>
    </span>

    <!-- Comments count -->
    <!-- <div class="counts" *ngIf="answer?.commentsCount" fxLayout fxLayoutGap="3px">
      <span *ngIf="answer?.commentsCount">{{ answer?.commentsCount }}</span>
      <span [ngPlural]="answer?.commentsCount">
        <ng-template ngPluralCase="=1" ><span translate>Comment</span></ng-template>
        <ng-template ngPluralCase="other" ><span translate>Comments</span></ng-template>
      </span>
    </div> -->
    <!-- / Comments count -->

  </div>
  <!--/ Count of Likes and Comments -->

  <!-- Like and comment actions -->
  <div class="py-1 border-top" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">

    <div fxLayout="row" fxLayoutAlign="start center" class="action text-muted" fxLayoutGap="8px">

      <!-- Like action -->
      <div fxLayout="row" fxLayoutAlign="start center">
        <button mat-button class="action-buttons" (click)="like()" [disabled]="!isLoggedIn">
          <mat-icon matBadgeColor="warn" class="text-center like-icon action-icon cursor-pointer" [ngClass]="{'liked': liked}">
            thumb_up
          </mat-icon>
          <p class="mb-0 action-title">{{(liked ? 'Unlike' : 'Like') | translate}}</p>
        </button>
      </div>
      <!--/ Like action -->

      <!-- Comment action -->
      <!-- <div fxLayout="row" fxLayoutAlign="start center" (click)="fetchComments()">
        <button mat-button class="action-buttons" [disabled]="!isLoggedIn">
          <mat-icon matBadgeColor="warn" class="text-center action-icon" >insert_comment </mat-icon>
          <p class="mb-0 action-title" translate>Comment</p>
        </button>
      </div> -->
      <!-- /Comment action -->

      <div>
        <div (click)="$event.stopPropagation(); $event.preventDefault()" *ngIf="url && (appId === 'SHAKTIMAN' || appId === 'KALGUDI_CORE')">
          <kl-social-shares [url]="url" direction="vertical"></kl-social-shares>
        </div>
      </div>
    </div>

  </div>
  <!-- Like and comment actions -->

  <!-- Qa comment -->
  <div *ngIf="showComments">
    <div class="qa-comments m-auto">
      <kl-social-comments
        [commentForm]="commentsForm"
        [progress]="actionProgress"
        [comments]="commentsList"
        (commented)="comment()">
      </kl-social-comments>
    </div>
  </div>
  <!-- /Qa comment -->

</div>

