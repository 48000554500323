<main id="activity-seasons" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
  <section class="season-tile p-2 rounded" [ngClass]="{'mb-3': !last}" *ngFor="let season of seasonsList; let last = last" fxFlex="calc(50% - 10px)" fxFlex.xs="100%">
    <div class="h-100" fxLayout fxLayoutAlign="space-between">
      <!-- Season details -->
      <div fxFlex="73%" fxLayout="column">
        <h3 class="product-title mw-100 text-truncate mb-1">
          <!-- <span>{{season?.year}}</span>
          <span> - </span> -->
          <span class="text-capitalize">{{season?.title}}</span>
        </h3>

        <p class="mb-0 product-variety mw-100 text-truncate">{{season?.variety?.productName}}</p>
      </div>
      <!--/ Season details -->

      <!-- Actions section -->
      <div class="h-100" fxLayout="column" fxLayoutAlign="space-between end">
        <div fxLayout fxLayoutGap="5px" fxLayoutAlign="end">
          <div (click)="editSeason(season)" *ngIf="editable">
            <a class="edit-text" translate>Edit</a>
          </div>
          <!-- <span> | </span>

          <div>
            <p class="text-danger mb-0 delete-text">Delete</p>
          </div> -->
        </div>
      </div>
      <!--/ Actions section -->
    </div>
    <div class="season-dates text-secondary mt-n3" fxLayout fxLayoutGap="5px" fxLayoutAlign="end end">
      <div>
        <span>{{season?.seasonTimeFrame?.from | date: 'dd/MM/yyyy'}}</span>
      </div>

      <span> - </span>

      <div>
        <span>{{season?.seasonTimeFrame?.to | date: 'dd/MM/yyyy'}}</span>
      </div>
    </div>
  </section>
</main>
