import { Inject, Input, OnDestroy, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { Observable, Subject } from 'rxjs';
import { finalize, mapTo, switchMap, takeUntil } from 'rxjs/operators';


/**
 * Base class for Kalgudi profile sections. Defines common functions for
 * profile sections.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiProfileSectionList<T> implements OnDestroy {

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Stream emitted on instance destroyed
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }

  /**
   * Gets, a boolean `true` or `false` that specifies whether to show or hide
   * add new item form.
   */
  get isSaveUpdateFormVisible(): boolean {
    return this.showAddNewItemForm;
  }

  /**
   * Gets the current item form for the profile section list.
   */
  get form(): FormGroup {
    return this.itemForm;
  }

  /**
   * Existing items in the profile section
   */
  @Input()
  profileSectionItems: T[];

  /**
   * A profile is editable only when logged in user is viewing his profile.
   * `true` if profile is editable otherwise `false`.
   */
  @Input()
  editable: boolean;

  /**
   * Edit item progress spinner flag
   */
  editItemProgress: boolean;

  /**
   * Add item progress spinner flag
   */
  saveItemProgress: boolean;

  /**
   * New item form structure
   */
  protected abstract itemForm: FormGroup;

  /**
   * Index of item currently editing. At any time you can edit a specific profile
   * section items. It will contain the index of profile item you are editing currently.
   */
  private editingListIndex: number;

  private showAddNewItemForm: boolean;

  /**
   * Emitted when component is destroyed
   */
  private destroyed = new Subject();


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
  ) {

    // Default initializations
    this.profileSectionItems = [];
    this.editable = false;
    this.editingListIndex  = 0;
  }

  ngOnDestroy(): void {

    this.destroyed.next();
    this.destroyed.complete();

    this.onDestroyed();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Set the flag to show the add new item form.
   */
  showAddItemForm(value?: T): boolean {

    this.showAddNewItemForm = true;

    // Patch values to the form if value is present
    if (value) {
      this.itemForm.patchValue(value);
    }

    // Enable form controls
    this.itemForm.enable();
    this.itemForm.updateValueAndValidity();

    return this.showAddNewItemForm;
  }

  /**
   * Resets the flag to hide the add new item form.
   */
  hideAddItemForm(): boolean {

    this.showAddNewItemForm = false;

    this.saveItemProgress = false;

    // Reset previous form values
    this.itemForm.reset();

    return this.showAddNewItemForm;
  }

  /**
   * Saves or updates an item. It saves an item if the item is new i.e.
   * it does not contain the item id. It updates the item if the item
   * has item id.
   */
  saveOrUpdateItem(): void {

    // Toggle the progress spinner for new item form group
    this.saveItemProgress = true;

    // Get the form value
    // const payload = this.util.clone<T>(this.itemForm.value);
    const payload = this.preparePayload(this.itemForm.value);


    // Disable form controls
    this.itemForm.disable();
    this.itemForm.updateValueAndValidity();

    // Check whether to save or update the item
    const obs = this.isNewItem(payload)
      ? this.saveNewItem(payload)
      : this.updateItem(payload);


    // Call save/update item API to save
    obs.pipe(

      // Subscribe to the stream only till the component is alive
      takeUntil(this.destroyed$),

      // Make sure to disable the add new item progress
      finalize(() => this.saveItemProgress = false),

      // Fetch latest profile on successful profile update
      switchMap(r => this.app.updateProfile()
        .pipe(
          mapTo(r)
        )),
    )
    .subscribe(
      (res: T) => this.hideAddItemForm(),
      (err) => this.onUpdateError(err.error)
    );
  }

  /**
   * Shows edit item form pre-filled with item data.
   *
   * @param item Item to modify
   */
  editItem(item: T): void {
    this.showAddItemForm(item);
  }

  /**
   * Deletes a profile section list item from the list. Internally calls the
   * delete item Api implemented by its child.
   */
  deleteItem(item: T): void {

    // Toggle the progress spinner for new item form group
    this.notification.showSpinner();

    // Call save new item API to save
    this.deleteItemApi(item)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        // Make sure to disable the add new item progress
        finalize(() => this.notification.hideSpinner()),

        // Fetch latest profile on successful profile update
        switchMap(r => this.app.updateProfile()
          .pipe(
            mapTo(r)
          )),
      )
      .subscribe(
        res => res,
        (err) => this.onUpdateError(err.error)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  /**
   * Implement the API to call on adding of a new item to the list.
   */
  protected abstract saveNewItemApi(payload: T): Observable<T>;

  /**
   * Implement the API to call on modifying existing item to the list.
   */
  protected abstract updateItemApi(payload: T): Observable<T>;

  /**
   * Implement the Api to delete an item in the profile section list.
   */
  protected abstract deleteItemApi(item: T): Observable<boolean>;

  /**
   * Implement the logic to check if the specified item in the profile section
   * list is new or existing item. An item in the list is new if it does not
   * contain any id otherwise its existing item.
   */
  protected abstract isNewItem(item: T): boolean;

  /**
   * Called once, before the instance is destroyed.
   */
  protected abstract onDestroyed(): void;

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue): T {
    return this.util.clone<T>(formValue);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Event handler for any profile update error.
   */
  private onUpdateError(err: Error): void {
    this.notification.showMessage(err.message);
  }

  /**
   * Saves the new item to the profile section list. Internally it calls
   * the save new item API.
   */
  private saveNewItem(payload: T): Observable<T> {

    // Call save new item API to save
    return this.saveNewItemApi(payload);
  }

  /**
   * Toggles the edit option for specific item in the list of
   * existing set of profile section data.
   */
  private updateItem(payload: T): Observable<T> {

    return this.updateItemApi(payload);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
