import { Component, Inject, Injector, OnInit } from '@angular/core';
import { fadeInAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveyLogs } from '../../classes/survey-logs';
import { SurveyLogActivityType, SurveyLogActivityTypeLabelMap } from '../../constants';

@Component({
  selector: 'kl-survey-logs-stream',
  templateUrl: './survey-logs-stream.component.html',
  styleUrls: ['./survey-logs-stream.component.scss'],
  animations: [ fadeInAnimation ]
})
export class SurveyLogsStreamComponent extends KalgudiSurveyLogs implements OnInit {

  activityTypeLabelMap = SurveyLogActivityTypeLabelMap;

  availableActivityTypes = SurveyLogActivityType;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(injector, notification, util);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed() { }

}
