<div class="w-100" *ngIf="(shareTags$ | async) as tags" class="activity-tags">
  <mat-form-field appearance="outline" color="accent" class="mat-form-field-sm w-100 input-field selection"
    [matTooltip]="tagsFormValue | joinArray">

    <mat-label translate>Select tag<strong class="strong">*</strong></mat-label>
    <mat-select [formControl]="tagsForm" panelClass="mat-select-sm mat-checkbox-rounded tag-selection">
      <mat-option *ngFor="let tag of tags" [value]="tag.id">
        {{ tag.value | translate}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
