<section class="value-card" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="tile w-100 mb-2 overflow-hidden">

    <!--Seller header-->
    <div class="data-info border-bottom pb-2" fxLayout="row" fxLayoutGap="12px">
      <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <!-- <mat-icon class="seller-icon">emoji_people</mat-icon> -->
        <svg-icon class="seller-icon" src="assets/svgs/seller-config.svg" [svgStyle]="{ 'width.px': 35, 'height.px': 35}">
        </svg-icon>
      </div>

      <div class="data-details" fxLayout="row" fxLayoutAlign="space-between center" fxFlex>
        <!-- User details-->
        <div class="details-info">
          <h2 class="profile-title m-0" translate>Seller configuration</h2>
        </div>
        <!-- / User details-->

        <!-- Toggle button -->
        <div (click)="editToggle()" *ngIf="editable">
          <button mat-icon-button color="primary" class="icon">
            <mat-icon>{{sellerConfigDetails ? 'edit' : 'add'}}</mat-icon>
          </button>
        </div>
        <!-- / Toggle button -->

      </div>
    </div>
    <!-- /Seller header-->

    <!-- Org Tile-->
    <div>
      <!-- Profile seller edit -->

      <div class="py-3" *ngIf="!editFlag">
        <kl-seller-details-edit (closeDialog)="closeEditForm()" [sellerId]="sellerId" [sellerConfigDetails]="sellerConfigDetails">
        </kl-seller-details-edit>
      </div>
      <!-- / Profile seller edit -->

      <!-- Profile about org view -->
      <div class="pb-3" *ngIf="editFlag">
        <kl-seller-details-view [sellerConfigDetails]="sellerConfigDetails">
        </kl-seller-details-view>
      </div>
      <!-- Profile about org view -->
    </div>
    <!-- / Org Tile-->

  </mat-card>

</section>
