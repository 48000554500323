import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiProject, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { AddProjectMembersDialogService } from '../../add-project-members/services/add-project-members-dialog.service';
import { KalgudiTaskCreationDialogService } from '../../task-creation/services/kalgudi-task-creation-dialog.service';

@Injectable()
export class KalgudiProjectsListActionsService {

  constructor(
    private taskCreationService: KalgudiTaskCreationDialogService,
    private projectService: KalgudiProjectService,
    private kalgudiDialogs: KalgudiDialogsService,
    private addProjectMembersDialogService: AddProjectMembersDialogService,
  ) {
  }

  /**
   * Open add members dialog
   */
  showAddMembersDialog(projectId: string): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add members to project',
      acceptButtonTitle: 'Add members',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '900px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        projectId
      }
    };

    return this.addProjectMembersDialogService.showAddMembersDialog(dialogDetails, dialogConfig)
      .pipe(
        // filter(res => res.accepted),
      );

  }

  /**
   * Opens task creation dialog
   * @param projectId
   */
  openTaskCreationDialog(projectId: string, filters: PartialData = {}): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Create task',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId,
        filters
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '725px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.taskCreationService.openTaskCreationDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(res => res.accepted),

      );
  }

  deleteProject(projectId: string, filters: PartialData = {}): Observable<KalgudiProject> {

    return this.showDeleteConfirmationDialog()
      .pipe(

        // Calls delete api service
        switchMap(r => this.projectService.deleteProject(projectId, filters)),
      );
  }

  onDestroyed(): void {}


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the project. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

}
