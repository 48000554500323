import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ProfileSocialActivities, ProfileSocialActivityType } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { ProfileSocialActivitiesService } from '../services/profile-social-activities.service';

@Directive()
export abstract class KalgudiProfileSocialActivities extends KalgudiInboxStream<ProfileSocialActivities> {

  @Input()
  profileKey: string;

  socialActivityType = ProfileSocialActivityType;

  private profileSocialActivityService: ProfileSocialActivitiesService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.profileSocialActivityService = this.injector.get(ProfileSocialActivitiesService);
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.profileSocialActivityService.getShareUpdatesStream(this.profileKey, offset, limit);
  }
}
