import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiNoContentModule, KalgudiTaskTileModule } from '@kalgudi/common';

import { UserAdminTasksComponent } from './components/user-admin-tasks/user-admin-tasks.component';
import { UserCurrentTasksComponent } from './components/user-current-tasks/user-current-tasks.component';
import { UserMemberTasksComponent } from './components/user-member-tasks/user-member-tasks.component';
import { UserTasksComponent } from './components/user-tasks/user-tasks.component';
import { KalgudiUserTasksListApiService } from './services/kalgudi-user-tasks-list-api.service';
import { KalgudiUserTasksListService } from './services/kalgudi-user-tasks-list.service';



@NgModule({
  declarations: [
    UserCurrentTasksComponent,
    UserAdminTasksComponent,
    UserMemberTasksComponent,
    UserTasksComponent
  ],
  imports: [
    CommonModule,

    // FlexLayout Module
    FlexLayoutModule,

    // Material module
    MatIconModule,

    //Kalgudi modules
    KalgudiTaskTileModule,
    KalgudiNoContentModule
  ],
  exports: [
    UserTasksComponent
  ],
  providers: [
    KalgudiUserTasksListApiService,
    KalgudiUserTasksListService
  ]
})
export class ProfileUserTasksModule { }
