import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiCreateSalesTeams } from '../../classes/kalgudi-create-sales-teams-page';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-sales-teams-page-creation',
  templateUrl: './sales-teams-page-creation.component.html',
  styleUrls: ['./sales-teams-page-creation.component.scss']
})
export class SalesTeamsPageCreationComponent extends KalgudiCreateSalesTeams implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
  ) {

    super(fb);
  }

  ngOnInit() {

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

}
