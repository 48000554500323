<div id="kl-add-question-dialog" fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden add-question-dialog mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{dialogData?.title | translate}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <kl-survey-add-question
      [surveyId]="dialogData?.data?.surveyId"
      (questionAdded)="onQuestionAddition($event)">
    </kl-survey-add-question>
  </div>
</div>
