import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { KalgudiUserTasksListService } from '../../services/kalgudi-user-tasks-list.service';

@Component({
  selector: 'kl-user-tasks',
  templateUrl: './user-tasks.component.html',
  styleUrls: ['./user-tasks.component.scss']
})
export class UserTasksComponent implements OnInit {

  @ViewChild('adminTasksTile') adminTasksTile: ElementRef;

  @Input()
  profileKey: string;

  @Input()
  isAdminTasks: boolean;

  @Input()
  isAssisted: boolean = false;

  tasksList: any;
  progress: boolean;
  userCurrentTasks: any[];
  userAdminTasks: any[];
  userMemberTasks: any[];

  constructor(
    private tasksListService: KalgudiUserTasksListService
  ) { }

  ngOnInit() {
    this.fetchTasks();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.isAdminTasks) {
        this.adminTasksTile.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 900);
  }

  /**
   * Calls api method to fetch user tasks
   */
  fetchTasks() {
    this.progress = true;

    this.tasksListService.getTasks('', this.profileKey, { sortBy: 'date' })
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {

          this.tasksList = res;

          //Filtering user current tasks
          this.userCurrentTasks = this.tasksList.items.filter(task => task.userRole === 'MEMBER' && task.myTasks && !task.farmerTasks && task.isActive);

          //Filtering user tasks where his role is admin
          this.userAdminTasks   = this.tasksList.items.filter(task => task.userRole === 'ADMIN' && !task.myTasks && task.farmerTasks);

          //Filtering user tasks where his role is member
          this.userMemberTasks   = this.tasksList.items.filter(task => task.userRole === 'MEMBER' && !task.isActive && task.myTasks && !task.farmerTasks);

        }
      );
  }

}
