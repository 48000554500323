import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { checkMobileDevice } from '@kalgudi/core';
import { BulkMembersAdditionResponse, KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { StatusDialogComponent } from '../components/status-dialog/status-dialog.component';
import { StatusMobileDialogComponent } from '../components/status-mobile-dialog/status-mobile-dialog.component';

@Injectable()
export class KalgudiStatusDialogService {

  constructor(
    private dialogsService: KalgudiDialogsService,
  ) { }


  /**
   * Opens status dialog
   */
  openStatusDialog(usersList: BulkMembersAdditionResponse) {

    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Status',
      data: {
        users: usersList
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '900px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        users: usersList
      }
    };

    // Show status dialog
    return this.showStatusDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }


  /**
   * Check for web and mobile and opens status dialog
   *
   * @param config Dialog
   */
  private showStatusDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(StatusMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(StatusDialogComponent, dialogConfig, matDialogConfig);
    }
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
