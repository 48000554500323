import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiSurveyDetails, SurveyBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiCreateSurveyApiService {


  /**
   * `v2/social/surveys`
   */
  private readonly API_SURVEYS = `${this.environment.restBaseUrlV2}/social/surveys`;

  /**
   * `v2/social/surveys/:existingSurveyId/clone`
   */
  private readonly API_CLONE_SURVEYS = `${this.environment.restBaseUrlV2}/social/surveys/:existingSurveyId/clone`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  /**
   * Api to create a survey
   */
  createSurvey(req: SurveyBasicDetails): Observable<KalgudiSurveyDetails> {

    return this.httpClient.post<ApiResponseCommon>(this.API_SURVEYS, req)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );

  }

  /**
   * Calls api to clone of survey
   */
  createCloneOfSurvey(payload: any, surveyId: string): Observable<KalgudiSurveyDetails> {

    return this.httpClient.put<ApiResponseCommon>(this.API_CLONE_SURVEYS.replace(':existingSurveyId', surveyId), payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response get details
        map(res => res.data),
      );
  }

}
