import { Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults } from '@kalgudi/core';
import { Observable } from 'rxjs';

import { KalgudiProgramSearchApiService } from './kalgudi-program-search-api.service';

@Injectable()
export class KalgudiProgramSearchService {

  constructor(
    private api: KalgudiProgramSearchApiService,
  ) { }

  /**
   * Searches for a member in a program.
   */
  searchMembers(
    pageId: string,
    searchKeyword: string,
    offset: number,
    limit: number,
    gender?: string
  ): Observable<KalgudiUserBasicSearchResults> {

    return this.api.searchMembers(pageId, searchKeyword,  offset, limit, gender);
  }
}
