import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KALGUDI_PROFILE_BUSINESS_TYPE_NAMES, KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES } from '@kalgudi/core';
import { BoardOfMember } from '@kalgudi/types';

@Component({
  selector: 'kl-board-members-list',
  templateUrl: './board-members-list.component.html',
  styleUrls: ['./board-members-list.component.scss']
})
export class BoardMembersListComponent implements OnInit {

  @Input()
  boardOfMembers: BoardOfMember[];

  @Input()
  editable: boolean;

  @Input()
  hideActions: boolean;

  @Input()
  loggedInUserBusinessTypeName: string;

  @Input()
  loggedInUserNativeBusinessTypeName: string

  @Output()
  removeBoardOfMember = new EventEmitter<number>();

  availableBusinessTypeNames = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;
  nativeBusinessTypeNames    = KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to remove board member
   */
  removeMember(index: number) {
    this.removeBoardOfMember.emit(index);
  }

}
