import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { BulkMembersAdditionResponse } from '@kalgudi/types';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { KalgudiPageMembersService } from '../../../services/kalgudi-page-member.service';
import { ProgramStateService } from '../../../services/program-state.service';


@Directive()
export abstract class KalgudiPageAddBulkMembers extends KalgudiDestroyable {

  @Output()
  addedMembers = new EventEmitter();

  progress: boolean;
  pageId: string;

  attachments = new FormControl([]);

  private kalgudiPageMembers: KalgudiPageMembersService;
  private notification: KalgudiNotification;
  private programStateService: ProgramStateService;
  private kalgudiStatusDialogService: KalgudiStatusDialogService;
  public env: KalgudiEnvironmentConfig;

  constructor(protected injector: Injector) {

    super();

    this.kalgudiPageMembers         = this.injector.get(KalgudiPageMembersService);
    this.notification               = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.programStateService        = this.injector.get(ProgramStateService);
    this.kalgudiStatusDialogService = this.injector.get(KalgudiStatusDialogService);
    this.env                        = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    // Get entity id
    this.programStateService.id$
      .pipe(
        first(),
      ).subscribe(id => {

        this.pageId = id;
      });

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Calls the api to add bulk survey members
   */
  addMembers(users: string[]) {

    this.notification.showSpinner();

    this.kalgudiPageMembers.addBulkMembers(this.pageId, users)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onAddMemberError(err)
      );
  }

  /**
   * Calls the api to add bulk survey members
   */
  addMembersDocument() {
    this.notification.showSpinner();

    this.kalgudiPageMembers.addDocumentsBulkMembers(this.pageId, this.attachments.value[0].url)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onBulkMembersDocumentAdditionSuccess(res),
        err => this.onAddMemberError(err || err.error)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);

    // Update program state
    this.programStateService.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATE);

    // this.notification.showMessage('Members added');

    this.addedMembers.emit();

  }

  /**
   * Success handler
   */
  protected onBulkMembersDocumentAdditionSuccess(res: BulkMembersAdditionResponse) {
    this.notification.showSweetAlert({
      title: 'Successfully added bulk users document',
      icon: 'success'
    });

  }

  /**
   * Event handler for adding members API errors.
   */
  protected onAddMemberError(err: Error): void {
    const errMsg: string = this.env.appId === 'SAM_FPO' ? 'Some invalid role in the data' : err.message;
    this.notification.showMessage(errMsg);
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
