import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';

import { AddLiveStockDialogComponent } from './components/add-live-stock-dialog/add-live-stock-dialog.component';
import { AddLiveStockFormComponent } from './components/add-live-stock-form/add-live-stock-form.component';
import {
  AddLiveStockMobileDialogComponent,
} from './components/add-live-stock-mobile-dialog/add-live-stock-mobile-dialog.component';
import { LiveStockListComponent } from './components/live-stock-list/live-stock-list.component';
import { LiveStockApiService } from './services/live-stock-api.service';
import { LiveStockService } from './services/live-stock.service';
import { MatMenuModule } from '@angular/material/menu';
import { ViewLiveStockLocationDialogComponent } from './components/view-live-stock-location-dialog/view-live-stock-location-dialog.component';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ViewLiveStockLocationMobileDialogComponent } from './components/view-live-stock-location-mobile-dialog/view-live-stock-location-mobile-dialog.component';

@NgModule({
  declarations: [
    AddLiveStockFormComponent,
    AddLiveStockDialogComponent,
    AddLiveStockMobileDialogComponent,
    LiveStockListComponent,
    ViewLiveStockLocationDialogComponent,
    ViewLiveStockLocationMobileDialogComponent
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,
    KalgudiGoogleGeoLocationModule,

    // Forms
    ReactiveFormsModule,

    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMenuModule,
    MatTooltipModule,

    // Kalgudi modules
    KalgudiButtonModule,
    KalgudiGooglePlacesModule,
    KalgudiNoContentModule,
    KalgudiPipesModule,
    TranslateModule
  ],
  exports: [
    LiveStockListComponent
  ],
  providers: [
    LiveStockService,
    LiveStockApiService
  ]
})
export class AddLiveStockModule { }
