import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';

import { KalgudiNoContentModule } from '../kalgudi-no-content/kalgudi-no-content.module';
import { AboutComponent } from './components/about/about.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import {
  CommonTraceabilityDetailsComponent,
} from './components/common-traceability-details/common-traceability-details.component';
import { NutritionValuesComponent } from './components/nutrition-values/nutrition-values.component';
import { ProcurementDetailsComponent } from './components/procurement-details/procurement-details.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { ProductionDetailsComponent } from './components/production-details/production-details.component';
import { ReviewCommentsComponent } from './components/review-comments/review-comments.component';
import { TraceabilityActivitiesComponent } from './components/traceability-activities/traceability-activities.component';
import { TraceabilityFulfillmentComponent } from './components/traceability-fulfillment/traceability-fulfillment.component';
import { AgmDirectionDirective } from './components/traceability-map/kalgudi-agm-direction.component';
import {
  OrderLocationMarkerComponent,
} from './components/traceability-map/order-location-marker/order-location-marker.component';
import { TraceabilityMapComponent } from './components/traceability-map/traceability-map.component';
import { TraceabilityPageComponent } from './components/traceability-page/traceability-page.component';
import { GOOGLE_API_KEY } from './config';
import { TraceabilityApiService } from './services/traceability-api.service';
import { TraceabilityService } from './services/traceability.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OutputStoreProductionDetailsComponent } from './components/output-store-production-details/output-store-production-details.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    TraceabilityPageComponent,
    ProductDetailsComponent,
    CatalogueComponent,
    TraceabilityMapComponent,
    AgmDirectionDirective,
    OrderLocationMarkerComponent,
    TraceabilityFulfillmentComponent,
    ProductionDetailsComponent,
    CommonTraceabilityDetailsComponent,
    TraceabilityActivitiesComponent,
    ProcurementDetailsComponent,
    AboutComponent,
    NutritionValuesComponent,
    ReviewCommentsComponent,
    OutputStoreProductionDetailsComponent,
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,

    MatChipsModule,
    MatIconModule,
    MatCardModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatTooltipModule,
    NgbModule,

    KalgudiPipesModule,
    MatSidenavModule,

    KalgudiNoContentModule,
    KalgudiGooglePlacesModule,
    KalgudiPipesModule,
    KalgudiDirectivesModule,
    AgmCoreModule,
    TranslateModule
  ],
  exports: [
    TraceabilityPageComponent
  ],
  providers: [
    TraceabilityService,
    TraceabilityApiService
  ]
})
export class KalgudiTraceabilityModule {

  static forRoot(apiKey: string): ModuleWithProviders<KalgudiTraceabilityModule> {

    return {
      ngModule: KalgudiTraceabilityModule,
      providers: [
        {
          provide: GOOGLE_API_KEY,
          useValue: apiKey,
        },
        ...AgmCoreModule.forRoot({
          apiKey: apiKey,
          libraries: ['places', 'geometry']
        })
        .providers,
      ]
    };
  }

  static forChild(): ModuleWithProviders<KalgudiTraceabilityModule> {

    return {
      ngModule: KalgudiTraceabilityModule,
      providers: [
      ]
    };
  }
}
