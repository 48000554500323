import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-page-survey-member-add-dialog',
  templateUrl: './page-survey-member-add-dialog.component.html',
  styleUrls: ['./page-survey-member-add-dialog.component.scss']
})
export class PageSurveyMemberAddDialogComponent extends KalgudiDialog<PageSurveyMemberAddDialogComponent> implements OnInit {

  constructor(
    protected dialogRef: MatDialogRef<PageSurveyMemberAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {

    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: { }
    };
    this.dialogRef.close(result);
  }
}
