import { Injectable } from '@angular/core';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';

/**
 * Helper class to load third party dependencies asynchronously.
 *
 * @author Pankaj Prakash
 *
 * @example
 * ```
 * lazyLoader.loadScript('https://maps.google.com/apis/js').subscribe();
 * ```
 */
@Injectable()
export class KalgudiLazyLoaderService {

  private loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  constructor( ) {}

  /**
   * Loads a JS script dynamically
   *
   * @param url Url to load
   */
  loadScript(url: string, checkExisting: boolean = false): Observable<any> {

    // Don't load already loaded library
    if (this.isLibraryLoaded(url, checkExisting)) {
      return this.loadedLibraries[url] ? this.loadedLibraries[url].asObservable() : EMPTY;
    }

    // Attach the new library list to the local list
    this.loadedLibraries[url] = new ReplaySubject();

    // Create a new script node with the url
    const script = this.createScriptNode(url);

    // Attach dom onload event listener to the event
    this.attachOnLoadEvent(script, url);

    document.body.appendChild(script);

    return this.loadedLibraries[url].asObservable();
  }

  /**
   * Loads a css dynamically
   *
   * @param url Url to load
   */
  loadStyle(url: string): Observable<any> {

    // Don't load already loaded library
    if (this.isLibraryLoaded(url)) {
      return this.loadedLibraries[url].asObservable();
    }

    // Attach the new library list to the local list
    this.loadedLibraries[url] = new ReplaySubject();

    // Create a new style node with the url
    const style = this.createStyleNode(url);

    // Attach dom onload event listener to the event
    this.attachOnLoadEvent(style, url);

    this.appendNodeToHead(style);

    return this.loadedLibraries[url].asObservable();
  }



  /**
   * Checks if a library has already loaded or not. It only
   * lists the libraries loaded using dynamic loader.
   *
   * @param url Library url
   */
  private isLibraryLoaded(url: string, checkExisting: boolean = false): boolean {

    return !!(this.loadedLibraries[url] || (checkExisting && this.isScriptLoaded(url)));
  }

  /**
   * Returns `true` if the script has already been loaded and added to the html
   * document, otherwise returns `false`.
   */
  private isScriptLoaded(url: string): boolean {

    // Get all loaded scripts into the document
    const loadedScripts = document.querySelectorAll('script');

    // List of all the loaded script urls
    const loadedScriptSrc: string[] = [];

    // Extract the loaded script src attribute
    loadedScripts.forEach(tag => loadedScriptSrc.push(tag.src));

    // Strip out the query params and hash components from the url
    const parsedUrl = this.parseUrl(url);

    // Strip the loaded script query params and hash and check if a script has already
    // been loaded or not
    return loadedScriptSrc.findIndex(script => this.parseUrl(script).includes(parsedUrl)) >= 0;
  }

  /**
   * Strip out the query params and hash components from the url
   */
  private parseUrl(url: string): string {

    // Regular expression expression an url
    const urlRegex = /^[a-zA-Z\:\/\.\-+_0-9]*/gm;

    return url.match(urlRegex)[0];
  }

  /**
   * Create a new script tag with the specified url.
   *
   * @param url Url of the script to load
   */
  private createScriptNode(url: string): HTMLScriptElement {

    const node = document.createElement('script');

    node.type = 'text/javascript';
    node.async = true;
    node.src = url;

    return node;
  }

  /**
   * Create a new style tag with the specified url.
   *
   * @param url Url of the style to load
   */
  private createStyleNode(url: string): HTMLStyleElement {

    const node = document.createElement('link');
    node.type = 'text/css';
    node.href = url;
    node.rel = 'stylesheet';

    return node;
  }

  /**
   * Attaches DOM load event listener to the newly created node.
   */
  private attachOnLoadEvent(node: HTMLElement, url: string): void {

    node.onload = () => {
      this.loadedLibraries[url].next();
      this.loadedLibraries[url].complete();
    };
  }

  /**
   * Attaches a html element node to the head tag. Attachment is
   * required for dynamic loading of assets.
   *
   * @param node Html element node
   */
  private appendNodeToHead(node: HTMLElement): void {
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(node);
  }
}
