import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiProject, PartialData, SelectableUIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProjectCreationApiService } from './kalgudi-project-creation-api.service';
import { KalgudiProjectCreationDialogService } from './kalgudi-project-creation-dialog.service';

@Injectable()
export class KalgudiProjectCreationService extends KalgudiDestroyable {

  constructor(
    private api: KalgudiProjectCreationApiService,
    private createProjectDialogService: KalgudiProjectCreationDialogService,
  ) {

    super();
  }

  /**
   * Make an api call to create a project
   */
  createProject(payload: any, filters: PartialData = {}): Observable<KalgudiProject> {

    return this.api.createProject(payload, filters);
  }


  /**
   * Make an api call to update the project
   */
  updateProject(payload: any, projectId: string, filters: PartialData = {}): Observable<any> {

    return this.api.updateProject(payload, projectId, filters);
  }


  /**
   * Opens project creation dialog
   */
  openCreateProjectDialog(projectCategories: SelectableUIMenu[] , projectId = '', filters: PartialData = {}): Observable<KalgudiProject> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: projectId ? 'Edit project' : 'Create project',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId,
        filters,
        projectCategories
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '650px',
      maxWidth: '650px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.createProjectDialogService.openProjectCreationDialog(dialogDetails, dialogConfig)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        filter(res => res.accepted),

        // Map the newly created project details
        map(res => res.data as KalgudiProject),
      );
  }


  onDestroyed(): void {}


}
