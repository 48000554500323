<section class="geo-tag-marker geo-tag-wrapper position-relative">
  <div class="text-center tagged-land" *ngIf="LabelPositionTop">
    <span class="my-3" *ngIf="location">
      <!-- Lat & long -->
      <span fxLayout fxLayoutAlign="space-between center" *ngIf="showLatLong">
        <span class="text-muted">Latitude & Longitude</span>
        <span class="font-weight-bold">{{latitude}}<span *ngIf="longitude">, </span>{{longitude}}</span>
      </span>
      <!-- / Lat & long -->
    </span>
  </div>

  <agm-map
    [streetViewControl]="false"
    class="geo position-relative"
    [fullscreenControl]="true"
    [zoom]="zoom"
    [zoomControlOptions]="zoomControlOptions"
    [latitude]="+location?.latitude"
    [longitude]="+location?.longitude">

    <agm-marker *ngIf="location"
      iconUrl="assets/icons/location.png"
      [latitude]="+location?.latitude"
      [longitude]="+location?.longitude">
    </agm-marker>
  </agm-map>
</section>
