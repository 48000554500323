import { Inject, Injectable } from '@angular/core';
import { StatsTablePage, StatsTablePageService } from '@kalgudi/ag-grid-lib/common';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AgGridOptions, PageStatsData, PartialData, StatsCardType, StoreType } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

import { AgGridAdvisoryStatusComponent } from '../components/ag-grid-advisory-status/ag-grid-advisory-status.component';

@Injectable()
export class SatelliteAdvisoriesPreviousSyncsService implements StatsTablePage {

  /**
   * /v2/pages/:pageId/satellite-advisories/sync
   */
  private readonly API_BASE = `${this.env.restBaseUrlV2}/pages/:pageId/satellite-advisories/sync`;

  /**
   * /v2/pages/:pageId/satellite-advisories/sync/stats
   */
  private readonly API_BASE_STATS = `${this.API_BASE}/stats`;

  dynamicComponents = [
    AgGridAdvisoryStatusComponent
  ];

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private statsPageService: StatsTablePageService,
  ) { }

  fetchPageStats(
    storeType: StoreType,
    params: PartialData = {}
  ): Observable<PageStatsData> {
    params = {
      ...params,
      mode: 'ag_grid',
      offset: '0',
      limit: '10'
    };

    return of({
      results: [
        {
          clickAction: true,
          filter: { filter: 'ALL' },
          key: 'ALL',
          title: 'All',
          total: 1,
        },
      ],
      selectedIndex: 0,
      viewType: StatsCardType.CHIP
    });
  }

  fetchPageList(
    storeType: StoreType,
    params: PartialData = {}
  ): Observable<AgGridOptions> {
    params = {
      ...params,
      mode: 'ag_grid',
    };

    return this.statsPageService.fetchList(this.API_BASE.replace(':pageId', params.pageId), storeType, params, this.dynamicComponents);
  }
}
