import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ShareRequest, ShareUpdate, TaskShareUpdate, TaskShareUpdateRequest } from '@kalgudi/types';

import { KalgudiProfileLogsForm } from '../../classes/kalgudi-profile-logs-form';
import { ProfileLogsStateService } from '../../services/profile-logs-state.service';

@Component({
  selector: 'kl-profile-logs-form',
  templateUrl: './profile-logs-form.component.html',
  styleUrls: ['./profile-logs-form.component.scss']
})
export class ProfileLogsFormComponent extends KalgudiProfileLogsForm implements OnInit {

  @Output()
  postCreated = new EventEmitter<ShareUpdate>();

  rowsCount: number;
  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    private stateService: ProfileLogsStateService
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp,
    );
  }

  ngOnInit() {

    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 2 : 3;

  }

  onDestroyed(): void {}

  /**
   * Event handler called after the post is shared successfully.
   */
  onPostCreated(req: ShareRequest, res: TaskShareUpdate): void {
    this.postCreated.emit(res);
    this.stateService.profileLogUpdated();
  }
}
