import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, PartialData } from '@kalgudi/types';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ScheduledRobocallStreamService {

  /**
   * /v2/social/shares/scheduled
   */
  private readonly API_SCHEDULED_ROBOCALLS = `${this.env.restBaseUrlV2}/social/shares/scheduled`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private http: HttpClient,
    private util: KalgudiUtilityService
  ) { }


  /**
   * Calls api to fetch the stream
   */
  fetchScheduledRobocallStream(offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {

    return this.streamApi(offset, limit, filters);
  }

  /**
   * Gets the share list
   */
  private streamApi(offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {

    const params = {
      ...filters,
      offset: offset.toString(),
      limit: limit.toString(),
      isRobocall: true
    };


    return this.http.get<ApiResponseCommon>(this.API_SCHEDULED_ROBOCALLS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        map(res => ({ items: res.data.results, count: res.data.count })),

      );

  }

}
