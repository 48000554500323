<div class="all-users-list">

  <section id="stats-page">

    <div *ngIf="pageStats && !statsLoadingProgress">
      <!-- Page stats -->
      <!-- <div @fadeIn>
        <store-admin-page-stats
          [formControl]="selectedStatForm"
          [enableAction]="true"
          [cardType]="pageStats?.viewType"
          [stats]="pageStats?.results">
        </store-admin-page-stats>
      </div> -->


      <ng-container *ngIf="selectedStat">

        <div class="my-3" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="12px">

          <div fxLayout @slideRight fxLayoutAlign="start center">
            <button mat-icon-button (click)="backToPreviousPage()">
              <mat-icon>
                keyboard_backspace
              </mat-icon>
            </button>

            <h3 class="m-0 header-title">All users</h3>
          </div>

          <!-- <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
            <button mat-stroked-button
              title="Download report as CSV"
              [disabled]="!agGridOptions?.results"
              (click)="downloadCsv(selectedStat?.title)" color="primary">
              <mat-icon>get_app</mat-icon>
              <span translate>Download</span>
            </button>

          </div> -->

          <div fxLayout="row" fxLayoutAlign="end center" *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
            <button mat-flat-button color="primary" class="send-btn" (click)="sendAdvisory()">
              <span translate>Update</span>
            </button>
            <button mat-flat-button color="primary" [matMenuTriggerFor]="menu" class="schedule-btn px-2">
              <mat-icon class="arrow-icon">play_arrow</mat-icon>
            </button>

            <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md schedule-send">
              <button mat-menu-item id="reply-button" (click)="openScheduleUpdatesDialog()">
                <mat-icon class="action-icons">cancel_schedule_send</mat-icon>
                <span translate>Schedule send</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div @fadeIn>

          <ag-grid-angular #agGrid
            style="width: 100%; height: 600px"
            class="ag-theme-balham"
            [rowData]="agGridOptions?.results"
            [columnDefs]="agGridOptions?.columnDefs"
            [animateRows]="animateRows"

            [rowGroupPanelShow]="'always'"
            [floatingFilter]="agGridOptions?.floatingFilter"

            [paginationAutoPageSize]="paginationAutoPageSize"
            [paginationPageSize]="paginationPageSize"
            [pagination]="pagination"

            (rowClicked)="onRowClicked($event)">
          </ag-grid-angular>

        </div>
      </ng-container>
    </div>

    <kl-no-content *ngIf="!pageStats || statsLoadingProgress"
      svgIcon="assets/svgs/contributors.svg"
      text="Unable to load page members, please try again later"
      progressText="Loading page members, please wait..."
      [progress]="statsLoadingProgress">
    </kl-no-content>
  </section>

</div>
