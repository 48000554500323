import { Observable, of } from "rxjs";

import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { KalgudiAppService } from "../services";


export function initializeAppFactory(appService: KalgudiAppService, router: Router): () => Observable<true> {
  return () => {
    const {authParams, pathWithoutAuth} = SeparateAuthToken(window.location.search);
    if(authParams) {
      return appService.updateSession(
        authParams.token,
        authParams.userName,
        authParams.profileKey
      ).pipe(map(() => {
        router.navigateByUrl(location.pathname + pathWithoutAuth, {
          replaceUrl: true
        })
        return true;
      }))
    }
    return of(true);

  }

}


 /**
  * Extracts auth params from search params and returns auth tokens and non auth search params
  *
  * @param params search params
  * @returns
  */
 function SeparateAuthToken(params: string): {authParams?: AuthParams, pathWithoutAuth: string} {
    const queryParams =  decodeURI(params).split('&');

      const authTokenParamNames = ['primary=', 'secondary=', 'profile='];
      const authParams = authTokenParamNames.reduce((authToken, paramName) => {
        const paramIndex = queryParams.findIndex(param => param.includes(paramName));
        if(paramIndex !== -1) {
          authToken[paramName] = queryParams[paramIndex].split('=')[1];
          queryParams.splice(paramIndex, 1);
        }
        return authToken;
      }, {});

      if(Object.keys(authParams).length === 3) {
        const nonAuthParams = queryParams.join('&');
        authParams['secondary='] = authParams['secondary='].includes('+') ? authParams['secondary='] : `+${authParams['secondary='].trim()}`
        return {
          pathWithoutAuth: queryParams.length ? nonAuthParams.startsWith('?') ? nonAuthParams : `?${nonAuthParams}` : '',
          authParams: {
            profileKey: authParams['profile='],
            token: authParams['primary='],
            userName: authParams['secondary=']
          }
        }
      }
      return {pathWithoutAuth: params};
 }

 interface AuthParams {
  token: string;
  userName: string;
  profileKey: string;
}