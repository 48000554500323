import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProfileService } from '@kalgudi/profiles';
import {
  KalgudiChart,
  KalgudiDialogConfig,
  KalgudiPageRelation,
  KalgudiUser,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  PageDigitalAssistanceActions,
  PageDigitalAssistanceStats,
  PageDigitalAssistanceStream,
} from '@kalgudi/types';
import { forkJoin, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';
import { DigitalAssistanceDialogService } from '../services/digital-assistance-dialog.service';
import { DigitalAssistanceStateService } from '../services/digital-assistance-state.service';
import { DigitalAssistanceService } from '../services/digital-assistance.service';

@Directive()
export abstract class DigitalAssistanceStream extends KalgudiInboxStream<PageDigitalAssistanceStream> {

  pageId: string;
  memberRole: KalgudiPageRelation;

  readonly activitiesStats$: Observable<KalgudiChart>;
  readonly daActionPerformed$: Observable<PageDigitalAssistanceActions>;

  // Dependencies
  private pageState: ProgramStateService;
  private daDialogService: DigitalAssistanceDialogService;
  private profileService: KalgudiProfileService;

  private readonly localProfileSavedKey = 'pCache';

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected daService: DigitalAssistanceService,
    protected daStateService: DigitalAssistanceStateService,
  ) {

    // Initialize parent
    super(notification, util);

    // Manually inject dependencies
    this.pageState = this.injector.get(ProgramStateService);
    this.daDialogService = this.injector.get(DigitalAssistanceDialogService);
    this.profileService = this.injector.get(KalgudiProfileService);


    this.activitiesStats$ = this.pageState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === PageActions.PAGE_DA_STATS_UPDATED),

        map(action => this.mapDigitalAssistanceStatsToMenuItem(action.payload))
      );

    this.daActionPerformed$ = this.daStateService.actionPerformed$
      .pipe(
        takeUntil(this.destroyed$),

        tap(action => {
          if (action === 'ENABLE_OFFLINE') {
            this.enableOffline();
          }
        }),
      );
  }

  /**
   * On post creation handler. Reloads the stream to get the fresh
   * digital stream response.
   */
  onPostCreated(post: any) {

    // Unshift won't work here as the share post response is not equivalent to digital assistance
    // stream response
    // this.unshiftItem(post);
    this.resetStream();
  }

  /**
   * Initializes digital assistance inbox stream
   */
  protected initDaStream(pageId: string, memberRole: KalgudiPageRelation) {

    this.pageId = pageId;
    this.memberRole = memberRole;

    // Initializes KalgudiStream
    this.initStream();
  }


  /**
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.daService.fetchStream(this.pageId, this.memberRole, offset, limit)
      .pipe(
        map(items => ({ items }))
      );
  }

  /**
   * Converts digital assistance stats received from Api to menu list with
   * labels and colors.
   *
   * @returns The mapped api digital assistance to kalgudi chart object
   */
  private mapDigitalAssistanceStatsToMenuItem(stats: PageDigitalAssistanceStats): KalgudiChart {
    return [
      {
        title: 'Shares',
        value: stats.SHAREATHOUGHT,
        color: '#9575CD',
      },
      {
        title: 'Questions',
        value: stats.QA,
        color: '#F06292',
      },
      {
        title: 'Profile',
        value: stats.PROFILE_UPDATE,
        color: '#4FC3F7',
      },
      {
        title: 'Survey',
        value: stats.SURVEY_SUBMISSION,
        color: '#81C784',
      },
      // {
      //   title: 'Tasks',
      //   value: stats.PROFILE_UPDATE,
      //   color: '#81C784',
      // },
    ];
  }

  /**
   * Opens enable offline dialog to save digital assistance details in local storage
   */
  private enableOffline() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Select members for offline access',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {
        pageId: this.pageId,
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      panelClass: 'kl-offline-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.daDialogService.showDaOfflineDialog(dialogDetails, dialogConfig)
      .pipe(
        map(data => this.mapObjectsToArray(data.data)),

        switchMap(users => this.daService.saveFrequentAssistedMembers(this.pageId, users)),

        switchMap(users => this.fetchAllUsersProfiles(users))
      )
      .subscribe(
        res => this.util.showMessage('User saved successfully for offline usage'),
        err => this.util.showApiErrorMessage(err)
      )
  }

  /**
   * Maps kalgudi users hashmap to kalgudi user basic details array
   */
  private mapObjectsToArray(users: KalgudiUsersMap): KalgudiUserBasicDetails[] {

    const usersList = Object.values(users);

    // Remove extra profile pic url
    usersList.forEach((u: any) => {
      // delete u.profilePicURL;
      delete u.extraData;
    });

    return usersList as any;
  }

  /**
   * Fetches all user profile in background
   */
  private fetchAllUsersProfiles(users: KalgudiUserBasicDetails[]): Observable<KalgudiUser[]> {

    const obs = users.filter(user => !this.isProfileSavedLocally(user.profileKey))
      .map(user =>
        this.profileService.fetchProfile(user.profileKey, true)
          .pipe(
            tap(_ => this.saveToLocal(user.profileKey))
          )
    );

    return forkJoin(obs);
  }

  /**
   * Returns `true` if profile is saved locally otherwise `false`
   */
  private isProfileSavedLocally(userId: string): boolean {
    return this.getLocalCachedProfileKeys().includes(userId);
  }

  /**
   * Gets profile keys cached locally
   */
  private getLocalCachedProfileKeys(): string[] {
    return this.util.getFromLocal(this.localProfileSavedKey, false) || [];
  }

  private saveToLocal(userProfileKey: string): void {
    const profileList = this.getLocalCachedProfileKeys();

    const profileListSet = new Set(profileList);

    profileListSet.add(userProfileKey);

    this.util.setToLocal(this.localProfileSavedKey, Array.from(profileListSet), false);
  }
}
