import { KalgudiDestroyable } from '@kalgudi/core';
import { ShareUpdate } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiSocialShareUpdateFullview<T extends ShareUpdate> extends KalgudiDestroyable {

  /**
   * Stream of share update object
   */
  private shareUpdateSubject = new BehaviorSubject<T>(null);


  /**
   * Flag to check if the instance is already loaded or not.
   */
  private isShareUpdateLoaded = false;

  constructor() {
    super();
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the stream of share update item
   */
  get shareUpdate$(): Observable<T> {
    return this.shareUpdateSubject
      .pipe(

        // Filter null values
        filter(r => r !== null)
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Loads the share update from the basic object. This
   * method can be called only once.
   *
   * @param shareId Share update id
   * @param shareUpdate Share update object to load
   */
  protected loadShareUpdate(shareId: string, shareUpdate: T): void {

    // No need to load if already loaded share update
    if (this.isShareUpdateLoaded) {
      return;
    }

    // Emit share update details to the stream
    this.updateShareUpdate(shareUpdate);

    // Raise the loaded flag
    this.isShareUpdateLoaded = true;
  }

  /**
   * Updates the share update object in the share update stream
   *
   * @param shareUpdate
   */
  private updateShareUpdate(shareUpdate: T): void {
    this.shareUpdateSubject.next(shareUpdate);
  }

}
