import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import {
  Attachment,
  FileMimeTypes,
  KalgudiDialogResult,
  KalgudiImageDialogConfig,
  KalgudiImageDialogResult,
  S3PolicyPathCategoryMap
} from '@kalgudi/types';
import { KL_MOBILE_DIALOG_DATA, KalgudiMobileDialogRef } from '../../../mobile-dialog';

@Component({
  selector: 'kl-upload-image-mobile-dialog',
  templateUrl: './upload-image-mobile-dialog.component.html',
  styleUrls: ['./upload-image-mobile-dialog.component.scss']
})
export class UploadImageMobileDialogComponent implements OnInit {

  attachments: Attachment[];
  path: S3PolicyPathCategoryMap;
  s3Category: S3PolicyPathCategoryMap;
  maxImages: number;
  multiple: boolean;
  acceptedFileTypes: FileMimeTypes[] = [];

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: KalgudiImageDialogConfig,
    private dialogRef: KalgudiMobileDialogRef,
    private ref: ChangeDetectorRef
  ) {

    this.acceptedFileTypes = this.dialogData.data.acceptFileTypes as FileMimeTypes[];
    this.multiple          = this.dialogData.data.multiple;
    this.maxImages         = this.dialogData.data.maxImages;
    this.s3Category        = this.dialogData.data.s3Category;
    this.attachments       = Array.isArray(this.dialogData.data.attachments) ? this.dialogData.data.attachments : [];
    this.path              = this.dialogData.data.s3Category && this.dialogData.data.s3Category.category
      ? this.dialogData.data.s3Category
      :  KALGUDI_S3_POLICY_MAP.DEFAULT;
  }

  ngOnInit(): void {
    if(this.dialogData) {
      this.ref.detectChanges();
    }
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiImageDialogResult = {
      accepted: true,
      data: {
        attachments: this.attachments
      }
    };
    this.dialogRef.close(result);
  }

}
