import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiPageSurveysList } from '../../classes/page-surveys-list';

@Component({
  selector: 'kl-page-surveys-list',
  templateUrl: './page-surveys-list.component.html',
  styleUrls: ['./page-surveys-list.component.scss']
})
export class PageSurveysListComponent extends KalgudiPageSurveysList implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {
    super(
      notification,
      util,
      injector
    );
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void { }

}
