<div class="mb-3 mt-2" id="program-list-header" [@fadeIn]>
  <mat-card>
    <div fxLayout="row">

      <!-- Program icon -->
      <div fxFlex="10%" fxHide.xs>
        <svg-icon [applyCss]="true" class="programs" src="assets/svgs/page.svg"
          [svgStyle]="{ 'width.px': 60, 'height.px': 60 }"></svg-icon>
      </div>
      <!-- Program icon -->

      <!-- Heading and content -->
      <div fxFlex="18%" fxLayout="column" fxLayoutAlign="space-between start" fxHide.xs>
        <div>
          <h1 class="my-0 w-100" translate>
            Pages
          </h1>
        </div>
        <div class="mw-90">
          <p class="my-0 program-content" translate>
            All pages
          </p>
        </div>
      </div>
      <!-- /Heading and content -->

      <!-- Actions -->
      <div fxFlex="72%" fxLayout="column" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutGap="30px" class="w-100" fxHide.xs>
            <div class="w-100" fxLayoutAlign="start center" fxFlex="60%">
              <!-- <kl-search class="search w-100" placeHolder="Search programs" [searchForm]="searchForm"></kl-search> -->
            </div>
            <div class="w-100" fxFlex>
              <kl-flat-button class="w-100" label="Create a page" (clicked)="openCreatePage()"></kl-flat-button>
            </div>
        </div>

        <!-- <div fxHide.gt-xs class="w-100 mt-2" fxHide.xs>
          <kl-search class="search w-100" placeHolder="Search programs" [searchForm]="searchForm"></kl-search>
        </div> -->
      </div>

      <!-- /Actions -->
    </div>

    <div fxLayout fxLayout.xs="{{pageTypes.length <= 3? 'row' : 'column'}}" fxLayoutGap="5px">
      <div fxLayout="row wrap" fxLayoutGap="6px grid" class="w-100 cursor-pointer">
        <div *ngFor="let page of pageTypes; let i = index" fxFlex.xs="{{pageTypes.length <= 3? '28%' : '25%'}}">
          <a fxLayout="row" *ngIf="!page?.subMenu"
            fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="center center"
            (click)="selectTab(page?.id)" class="tabs">
            <svg-icon *ngIf="page?.svgIcon"
              [src]="page?.svgIcon" [applyCss]="true" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
              class=" mr-2" [ngClass]="{'program-tab-icon-active': page?.id === selectedTabId, 'program-tab-icon-inactive': page?.id !== selectedTabId}">
            </svg-icon>

            <p class="mb-0 text-center"
              [ngClass]="{'program-tab-active': page?.id === selectedTabId, 'program-tab-inactive': page?.id !== selectedTabId}">
              <span>{{ page?.title | translate}}</span>
              <span *ngIf="i === 1 && pageRelationAdminCount">({{ pageRelationAdminCount }})</span>
              <span *ngIf="i === 2 && pageRelationNotAdminCount">({{ pageRelationNotAdminCount }})</span>
            </p>
          </a>

          <a fxLayout="row" [matMenuTriggerFor]="menu" *ngIf="page?.subMenu"
            fxLayoutAlign="start center" fxLayout.xs="column" fxLayoutAlign.xs="center center"
            class="tabs">
            <svg-icon *ngIf="page?.svgIcon"
              [src]="page?.svgIcon" [applyCss]="true" [svgStyle]="{ 'width.px': 24, 'height.px': 24 }"
              class="mr-2" [ngClass]="{'program-tab-icon-active': page?.id === selectedTabId, 'program-tab-icon-inactive': page?.id !== selectedTabId}">
            </svg-icon>

            <p class="mb-0 text-center"
              [ngClass]="{'program-tab-active': page?.id === selectedTabId, 'program-tab-inactive': page?.id !== selectedTabId}">
              <span *ngIf="!title">{{ page?.title | translate}}</span>
             <span *ngIf="title">{{ title | titlecase | translate}} <span translate>Pages</span></span>
            </p>
          </a>

          <mat-menu  #menu="matMenu" class="mat-menu-md" >
            <div *ngFor="let menu of page?.subMenu">
              <button mat-menu-item>
                <div fxLayout (click)="onPageTypeSelection(menu?.id)" fxLayoutAlign="start center">
                  <svg-icon *ngIf="menu?.svgIcon"
                    [ngClass]="{'sub-menu-icon': menu?.iconColor}"
                    [src]="menu?.svgIcon" [applyCss]="true" [svgStyle]="{ 'width.px': 18, 'height.px': 18 }"
                    class="sub-menu-svg mr-2 pr-2">
                  </svg-icon>

                  <span class="ml-n2"> {{ menu?.title | translate}}</span>
                </div>
              </button>
            </div>
          </mat-menu>

        </div>
      </div>

      <div *ngIf="programMenu" fxLayout fxLayoutAlign="end end"
        fxLayoutAlign.xs="{{pageTypes.length <= 3? 'center center' : 'end end'}}" [ngClass]="{'mt-2': pageTypes.length <= 3}">
        <div *ngFor="let menu of programMenu">
          <div *ngIf="menu?.svgIcon" class="mt-n2 mr-1"
            [ngClass]="{'selected-icons': menu?.id === selectedView, 'menu-icons': menu?.id !== selectedView}">
            <button mat-icon-button (click)="showProgramsList(menu)"  [matTooltip]="menu?.id | translate | titlecase">
              <div [@scaleOut]>
                <svg-icon [src]="menu?.svgIcon" class="text-center"
                  [applyCss]="true"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18}"></svg-icon>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

  </mat-card>
</div>
