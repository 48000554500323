import {
  Directive,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
// import { FpoProfileService } from '../services/fpo-profiles.services';
import { finalize, takeUntil } from 'rxjs/operators';
import { KalgudiDestroyable, KalgudiFormValidators } from '@kalgudi/core';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class FpoProfiles extends KalgudiDestroyable {
  @Input()
  profileKey: string;

  @Input()
  socialFormDetails: any;

  @Input()
  cropFormDetails: any;

  @Input()
  newCropFormDetails: any;

  @Input()
  basicInfoFormDetails: any;

  @Input()
  aboutMeBasicDetails: any;

  @Input()
  programTypeDetails: any;

  @Input()
  samunnatiRelationShip: any;

  @Input()
  financeFormDetails: any;

  @Input()
  pageId: any;

  @Input()
  basicActivityFormDetails: any;

  @Input()
  crop: any;

  @Output()
  closeDialog = new EventEmitter();

  @Input()
  isEditBasicDialog: boolean;

  @Input()
  isEditProgramType: boolean;

  @Input()
  hideActions: boolean;

  fpoProfilesForm: FormGroup;
  turnoverDetailsForm: FormGroup;
  fpoLogoDetailsForm: FormGroup;
  businessActivitiesForm: FormGroup;
  currentYearDetailsForm: FormGroup;
  financialDetailsForm: FormGroup;
  cropDetailsForm: FormGroup;
  bodDetailsForm: FormGroup;

  lstOfAttachments = new FormControl('');
  promotionOfAttachments = new FormControl('');
  resourceOfAttachments = new FormControl('');
  teamPhotoOfAttachments = new FormControl('');
  proofOfAttachments = new FormControl('');
  entityLogoOfAttachments = new FormControl('');

  logoDetails: any[] = [];
  turnOverDetails: any = [];
  logInProfileKey: string;
  fpoProfileDetails: any;

  currentYearCropDetails: any[] = [];
  cropwiseProcurementDetails: any[] = [];

  newCurrentYearDetails: any[] = [];
  newPreviousYearDetails: any[] = [];

  bankDetails: any[] = [];
  creditHistoryDetails: any[] = [];
  licenseDetails: any[] = [];
  assetDetails: any[] = [];

  inputDetails: any[] = [];
  outputDetails: any[] = [];
  otherDetails: any[] = [];
  chcDetails: any[] = [];
  lendingDetails: any[] = [];
  topThreeBuyers: any[] = [];

  consumerDetails: any[] = [];
  certifiedDetails: any[] = [];
  warehouseDetails: any[] = [];
  coldStorageDetails: any[] = [];
  exportsImportsDetails: any[] = [];
  alliedDetails: any[] = [];

  isEditFpoLogoDetails: boolean;
  fpoLogoDetailsIndex: number;

  isEditTurnoverDetails: boolean;
  turnoverDetailsIndex: number;

  editCurrentYear: boolean;
  editCurrentYearIndex: number;

  editPreviousYear: boolean;
  editPreviousYearIndex: number;

  bodDetails: any[] = [];

  public fb: FormBuilder;
  private notification: KalgudiNotification;
  private profileState: KalgudiProfileStateService;
  public organisationProfileService: OrganisationProfileService;
  isProgress: boolean;

  financialYears: any[] = [
    { id: '2022-2023', value: '2022-2023' },
    { id: '2021-2022', value: '2021-2022' },
    { id: '2020-2021', value: '2020-2021' },
    { id: '2019-2020', value: '2019-2020' },
    { id: '2018-2019', value: '2018-2019' }
  ];
  editFinancialYears: any[];
  @Input() isEditFinanceDialog: boolean;

  constructor(protected injector: Injector) {
    super();

    this.fb = this.injector.get(FormBuilder);
    this.notification = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileState = this.injector.get(KalgudiProfileStateService);
    this.organisationProfileService = this.injector.get(OrganisationProfileService);

    this.fpoProfilesForm = this.newFpoProfilesForm;
    this.fpoLogoDetailsForm = this.newFpoLogoDetailsForm;
    this.turnoverDetailsForm = this.newTrunoverDetailsForm;
    this.businessActivitiesForm = this.newBusinessActivitiesForm;
    this.currentYearDetailsForm = this.newCurrentYearDetailsForm;
    this.financialDetailsForm = this.newFinancialDetailsForm;
    this.cropDetailsForm = this.newCropDetailsForm;
    this.bodDetailsForm = this.newBODDetailsForm;

    // Initialize profile details
    this.profileState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((profile) => (this.logInProfileKey = profile.profileKey));

    this.getFpoProfileDetails();
    this.editFinancialYears = this.financialYears;
  }

  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // .................   Basic info getter ..................... //

  /**
   * Getter for basic details info
   */
  get basicDetailsGroup(): FormGroup {
    return this.fpoProfilesForm.get('basicDetailsInfo') as FormGroup;
  }

  /**
   * Getter for samunnati relation ship
   */
  get samunnatiRelationshipGroup(): FormArray  {
    return this.basicDetailsGroup.get('samunnatiRelationship') as FormArray;
  }

  /**
   * Getter for program type details in basic info group
   */
    get programTypeGroup(): FormArray {
      return this.basicDetailsGroup.get('programType') as FormArray;
    }

  /**
   * Getter for basic details
   */
  get basicDetailsForm(): FormGroup {
    return this.basicDetailsGroup.get('basicDetails') as FormGroup;
  }

  /**
   * Getter for CEO details
   */
  get ceoDetailsGroup(): FormGroup {
    return this.basicDetailsGroup.get('ceoDetails') as FormGroup;
  }

  /**
   * Getter for basic profile details
   */
  get basicProfileDetails(): FormGroup {
    return this.basicDetailsGroup.get('basicProfileDetails') as FormGroup;
  }

  /**
   * Getter for entity details
   */
  get entityDetailsGroup(): FormGroup {
    return this.basicDetailsGroup.get('entityDetails') as FormGroup;
  }

  /**
   * Getter for company cin details
   */
  get companyCinField(): AbstractControl {
    return this.entityDetailsGroup.get('companyCin');
  }

  /**
   * Getter for fpo logo details
   */
  get fpoLogoDetailsArray(): FormArray {
    return this.basicDetailsGroup.get('fpoLogoDetails') as FormArray;
  }

  /**
   * Getter for turnover details
   */
  get turnoverDetailsArray(): FormArray {
    return this.basicDetailsGroup.get('turnoverDetails') as FormArray;
  }

  /**
   * Getter for Mobile number
   */
  get mobileNumber(): AbstractControl {
    return this.basicDetailsGroup.get('ceoMobileNumber');
  }

  /**
   * Getter for Email id
   */
  get emailIdentification(): AbstractControl {
    return this.basicDetailsGroup.get('ceoEmailId');
  }

  /**
   * Getter for attachments
   */
  get logoAttachments(): AbstractControl {
    return this.fpoLogoDetailsForm.get('attachment');
  }

  // .................   Financial getter ..................... //

  /**
   * Getter for finance details group
   */
  get financialDetailsGroup(): FormGroup {
    return this.fpoProfilesForm.get('financialDetails') as FormGroup;
  }

  /**
   * Getter for bank details array
   */
  get bankDetailsArray(): FormArray {
    return this.financialDetailsGroup.get('bankDetails') as FormArray;
  }

  /**
   * Getter for insurance details group
   */
  get insuranceGroup(): FormGroup {
    return this.financialDetailsGroup.get('insurance') as FormGroup;
  }

  /**
   * Getter for credit history array
   */
  get creditHistoryArray(): FormArray {
    return this.financialDetailsGroup.get('creditHistory') as FormArray;
  }

  /**
   * Getter for licenses array
   */
  get licensesArray(): FormArray {
    return this.financialDetailsGroup.get('licenses') as FormArray;
  }

  /**
   * Getter for asset details array
   */
  get assetDetailsArray(): FormArray {
    return this.financialDetailsGroup.get('assetDetails') as FormArray;
  }

  /**
   * Getter for crop insurance farmers count
   */
  get cropInsuranceFarmersCount(): AbstractControl {
    return this.insuranceGroup.get('cropInsFarmerCount');
  }

  /**
   * Getter for life insurance farmers count
   */
  get lifeInsuranceFarmersCount(): AbstractControl {
    return this.insuranceGroup.get('lifeInsFarmerCount');
  }

  /**
   * Getter for annadhatha suraksha count
   */
  get annadathaSurakshaFarmersCount(): AbstractControl {
    return this.insuranceGroup.get('annadhathaSurakshaFarmerCount');
  }

  /**
   * Getter for attachments
   */
  get licenseGroup(): FormGroup {
    return this.financialDetailsForm.get('licenses') as FormGroup;
  }

  /**
   * Getter for male members
   */
  get maleMembersCount(): AbstractControl {
    return this.basicDetailsForm.get('maleMembers');
  }

  /**
   * Getter for female members
   */
  get femaleMembersCount(): AbstractControl {
    return this.basicDetailsForm.get('femaleMembers');
  }

  /**
   * Getter for number of members
   */
  get numberOfMembersCount(): AbstractControl {
    return this.basicDetailsForm.get('memberShipBase');
  }

  /**
   * Getter for active members
   */
  get activeMembers(): AbstractControl {
    return this.basicDetailsForm.get('activeMembers');
  }

  /**
   * Getter for female members
   */
  get aboutMe(): AbstractControl {
    return this.basicDetailsForm.get('aboutMe');
  }

  /**
   * Getter for attachments
   */
  get proofAttachments(): AbstractControl {
    return this.licenseGroup.get('attachment');
  }

  // .................. social ................ //

  /**
   * Getter for asset details array
   */
  get socialDetailsGroup(): FormGroup {
    return this.fpoProfilesForm.get('socialDetails') as FormGroup;
  }

  /**
   * Getter for social-info group
   */
  get socialInfoGroup(): FormGroup {
    return this.socialDetailsGroup.get('socialInfo') as FormGroup;
  }

  /**
   * Getter for promoting institution
   */
  get promotingInstitutionGroup(): FormGroup {
    return this.socialDetailsGroup.get('promotingInstitution') as FormGroup;
  }

  /**
   * Getter for promoting attachments
   */
  get promotingAttachments(): AbstractControl {
    return this.promotingInstitutionGroup.get('promotingInstitutionLogo');
  }

  /**
   * Getter for promoting logo name
   */
  get logoName(): AbstractControl {
    return this.promotingInstitutionGroup.get('promotingInstitutionLogo');
  }

  /**
   * Getter for resource institution
   */
  get resourceInstitutionGroup(): FormGroup {
    return this.socialDetailsGroup.get('resourceInstitution') as FormGroup;
  }

  /**
   * Getter for resource attachments
   */
  get resourceAttachment(): AbstractControl {
    return this.resourceInstitutionGroup.get('resourcingInstitutionLogo');
  }

  /**
   * Getter for promoting institution
   */
  get teamPhotoGroup(): FormGroup {
    return this.socialDetailsGroup.get('teamPhoto') as FormGroup;
  }

  /**
   * Getter for teamPhoto attachments
   */
  get teamPhotoAttachments(): AbstractControl {
    return this.teamPhotoGroup.get('teamPhotoLogo');
  }

  /**
   * Getter for invoicePhotoGroup institution
   */
  get entityLogoAttachments(): AbstractControl {
    return this.socialDetailsGroup.get('entityLogo');
  }

  // .................   Business activities getter ..................... //

  /**
   * Getter for business activities group
   */
  get businessActivitiesGroup(): FormGroup {
    return this.fpoProfilesForm.get('businessActivities') as FormGroup;
  }

  /**
   * Getter for input business activities array
   */
  get inputBusinessActivitiesArray(): FormArray {
    return this.businessActivitiesGroup.get(
      'inputBusinessActivities'
    ) as FormArray;
  }

  /**
   * Getter for output business activities array
   */
  get outputBusinessActivitiesArray(): FormArray {
    return this.businessActivitiesGroup.get(
      'outputBusinessActivities'
    ) as FormArray;
  }

  /**
   * Getter for chc business activities array
   */
  get chcBusinessActivitiesArray(): FormArray {
    return this.businessActivitiesGroup.get('chcServices') as FormArray;
  }

  /**
   * Getter for other business activities array
   */
  get otherBusinessActivitiesArray(): FormArray {
    return this.businessActivitiesGroup.get(
      'otherBusinessActivities'
    ) as FormArray;
  }

  /**
   * Getter for lending array
   */
  get lendingArray(): FormArray {
    return this.businessActivitiesGroup.get('lending') as FormArray;
  }

  /**
   * Getter for products and commodities group
   */
  get productsAndCommoditiesGroup(): FormGroup {
    return this.businessActivitiesGroup.get(
      'productsAndCommodities'
    ) as FormGroup;
  }

  /**
   * Getter for consumer array
   */
  get consumerArray(): FormArray {
    return this.productsAndCommoditiesGroup.get('consumer') as FormArray;
  }

  /**
   * Getter for certified array
   */
  get certifiedArray(): FormArray {
    return this.productsAndCommoditiesGroup.get('certified') as FormArray;
  }

  /**
   * Getter for warehouse array
   */
  get warehouseArray(): FormArray {
    return this.productsAndCommoditiesGroup.get('warehouse') as FormArray;
  }

  /**
   * Getter for cold storage array
   */
  get coldStorageArray(): FormArray {
    return this.productsAndCommoditiesGroup.get('coldStorage') as FormArray;
  }

  /**
   * Getter for exports or imports array
   */
  get exportsOrImportsArray(): FormArray {
    return this.productsAndCommoditiesGroup.get(
      'exportsOrImports'
    ) as FormArray;
  }

  /**
   * Getter for exports or imports array
   */
  get alliedArray(): FormArray {
    return this.productsAndCommoditiesGroup.get('allied') as FormArray;
  }

  // .................   Crops getter ..................... //

  /**
   * Getter for crop details group
   */
  get cropDetailsGroup(): FormGroup {
    return this.fpoProfilesForm.get('cropInfo') as FormGroup;
  }

  /**
   * Getter for current year crop details array
   */
  get currentYearCropDetailsArray(): FormArray {
    return this.cropDetailsGroup.get('cropDetails') as FormArray;
  }

  /**
   * Getter for crop wise procurement details array
   */
  get cropwiseProcurementArray(): FormArray {
    return this.cropDetailsGroup.get('cropWiseProcurement') as FormArray;
  }

  /**
   * Form group for current year crop details
   */
  get currentYearDetailsGroup(): FormGroup {
    return this.currentYearDetailsForm.get(
      'cropDetails'
    ) as FormGroup;
  }

  /**
   * Form group for current year crop details
   */
  get currentYearCropDetailsGroup(): FormGroup {
    return this.cropDetailsForm.get('cropDetails') as FormGroup;
  }

  /**
   * Form group for crop wise procurement details
   */
  get cropWiseProcurementGroup(): FormGroup {
    return this.cropDetailsForm.get('cropWiseProcurement') as FormGroup;
  }

  /**
   * Getter for current year season
   */
  get currentYearSeason(): AbstractControl {
    return this.currentYearDetailsGroup.get('season');
  }

  /**
   * Getter for current year no of farmers
   */
  get currentYearNoOfFarmers(): AbstractControl {
    return this.currentYearDetailsGroup.get('noOfFarmers');
  }

  /**
   * Getter for current year crop
   */
  get currentYearCrop(): AbstractControl {
    return this.currentYearDetailsGroup.get('crop');
  }

  /**
   * Getter for current year variety
   */
  get currentYearVariety(): AbstractControl {
    return this.currentYearDetailsGroup.get('variety');
  }

  /**
   * Getter for current year cultivation area
   */
  get currentYearCultivationArea(): AbstractControl {
    return this.currentYearDetailsGroup.get('cultivationArea');
  }

  /**
   * Getter for current year output quantity
   */
  get currentYearOutput(): AbstractControl {
    return this.currentYearDetailsGroup.get('outputQty');
  }

  /**
   * Getter for current year output quantity unit
   */
  get currentYearOutputQtyUnit(): AbstractControl {
    return this.currentYearDetailsGroup.get('outputQtyUnit');
  }

  // .................. Basic info ................ //

  /**
   * Getter for financials year id
   */
  get financialsYearId(): AbstractControl {
    return this.turnoverDetailsForm.get('financialYearId');
  }

  /**
   * Getter for turn over
   */
  get turnOverField(): AbstractControl {
    return this.turnoverDetailsForm.get('turnover');
  }

  /**
   * Getter for logo field
   */
  get logoFieldName(): AbstractControl {
    return this.fpoLogoDetailsForm.get('logoName');
  }

  /**
   * Getter for attachments
   */
  get listOfAttachments(): AbstractControl {
    return this.fpoLogoDetailsForm.get('lstOfAttachments');
  }

  /**
   * Form group for insurance count
   */
  get insuranceCount(): AbstractControl {
    return this.financialDetailsGroup.get('insurance');
  }

  /**
   * Getter for crop insurance
   */
  get cropInsurance(): AbstractControl {
    return this.insuranceCount.get('cropInsFarmerCount');
  }

  /**
   * Getter for life insurance
   */
  get lifeInsurance(): AbstractControl {
    return this.insuranceCount.get('lifeInsFarmerCount');
  }

  /**
   * Getter for annadhatha suraksha
   */
  get annadhathaSuraksha(): AbstractControl {
    return this.insuranceCount.get('annadhathaSurakshaFarmerCount');
  }

  // .................   BOD getter ..................... //

  /**
   * Getter for bod details group
   */
  get newBodDetailsGroup(): FormGroup {
    return this.fpoProfilesForm.get('bod') as FormGroup;
  }

  /**
   * Getter for bod details group
   */
  get bodDetailsOtherGroup(): FormGroup {
    return this.newBodDetailsGroup.get('bodDetails') as FormGroup;
  }

  /**
   * Form group for crop wise procurement details
   */
  get bodDetailsGroup(): FormGroup {
    return this.bodDetailsForm.get('bodDetails') as FormGroup;
  }

  // ---------------- Business activity Group -----------------

  /**
   * Form group for consumer details
   */
  get consumerGroup(): FormGroup {
    return this.businessActivitiesForm.get('consumer') as FormGroup;
  }

  /**
   * Getter for show consumer field
   */
  get showConsumer(): AbstractControl {
    return this.consumerGroup.get('showConsumer');
  }

  /**
  * Form group for warehouse details
  */
  get warehouseGroup(): FormGroup {
    return this.businessActivitiesForm.get('warehouse') as FormGroup;
  }

  /**
  * Form group for certified details
  */
  get certifiedGroup(): FormGroup {
    return this.businessActivitiesForm.get('certified') as FormGroup;
  }

  /**
  * Getter for show certified field
  */
  get showCertified(): AbstractControl {
    return this.certifiedGroup.get('showCertified');
  }

  /**
   * Getter for show warehouse field
   */
  get showWarehouse(): AbstractControl {
    return this.warehouseGroup.get('showWarehouse');
  }

  /**
   * Form group for cold storage details
   */
  get coldStorageGroup(): FormGroup {
    return this.businessActivitiesForm.get('coldStorage') as FormGroup;
  }

  /**
   * Getter for show cold storage field
   */
  get showColdStorage(): AbstractControl {
    return this.coldStorageGroup.get('showColdStorage');
  }

  /**
  * Form group for imports or exports details
  */
  get importsExportsGroup(): FormGroup {
    return this.businessActivitiesForm.get('importsOrExports') as FormGroup;
  }

  /**
  * Getter for exports or Imports field
  */
  get showImportsOrExports(): AbstractControl {
    return this.importsExportsGroup.get('showImportsOrExports');
  }

  /**
   * Form group for allied details
   */
  get alliedGroup(): FormGroup {
    return this.businessActivitiesForm.get('allied') as FormGroup;
  }

  /**
   * Getter for allied field
   */
  get showAllied(): AbstractControl {
    return this.alliedGroup.get('showAllied');
  }

  /**
   * Form group for others details
   */
  get otherGroup(): FormGroup {
    return this.businessActivitiesForm.get('others') as FormGroup;
  }

  /**
  * Getter for Other business field
  */
  get otherBusinessShow(): AbstractControl {
    return this.otherGroup.get('otherBusiness');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods for child
  // --------------------------------------------------------

  /**
   * Create or update the profile details
   */
  addBasicInfoDetails(crop?: any) {
    this.isProgress = true;
    this.checkNoBusinessActivity();

    this.cropInsurance.patchValue((Number(this.cropInsurance.value)) || 0);
    this.lifeInsurance.patchValue((Number(this.lifeInsurance.value)) || 0);
    this.annadhathaSuraksha.patchValue((Number(this.annadhathaSuraksha.value)) || 0);

    this.teamPhotoAttachments.patchValue(this.teamPhotoAttachments.value || "");
    this.entityLogoAttachments.patchValue(this.entityLogoAttachments.value || "");
    this.resourceAttachment.patchValue(this.resourceAttachment.value || "");
    this.promotingAttachments.patchValue(this.promotingAttachments.value || "");

    // Deleting and modifying for bod details
    let bodDetails: any;
    if (this.bodDetails.length) {
      bodDetails = this.bodDetails.map(bod => {
        this.bodDetailsOtherGroup.value.push(bod);
        return bod;
      });
    }

    this.maleMembersCount.patchValue((Number(this.maleMembersCount.value)) || 0);
    this.femaleMembersCount.patchValue((Number(this.femaleMembersCount.value)) || 0);
    this.numberOfMembersCount.patchValue((Number(this.numberOfMembersCount.value)) || 0);
    this.activeMembers.patchValue((Number(this.activeMembers.value)) || 0);

    // DELETING BOD VALUES
    delete this.fpoProfilesForm.value.bod;

    // Removing commas in share capital field
    this.fpoProfilesForm.value.basicDetailsInfo.basicDetails.shareCapital = this.fpoProfilesForm.value.basicDetailsInfo.basicDetails.shareCapital.replace(/,/g,'');

    const payload = {
      ...this.fpoProfilesForm.value,
      bod: bodDetails || [],
      gradingDetails: {}
    };

    let aboutMeObj;
    if (this.aboutMe.value.length) {
      aboutMeObj = {
        aboutMe: this.aboutMe.value
      }
    }
    const modifiedPayload = {
      samunnatiFpoDetails: payload,
      fpoBasicDetails: aboutMeObj
    }

    this.currentYearCropDetails.forEach((crop) => {
      this.currentYearCropDetailsArray.value.push(crop);
    });

    this.cropwiseProcurementDetails.forEach((crop) => {
      this.cropwiseProcurementArray.value.push(crop);
    });

    this.assetDetails.forEach((finance) => {
      this.assetDetailsArray.value.push(finance);
    });

    this.bankDetails.forEach((finance) => {
      this.bankDetailsArray.value.push(finance);
    });

    this.creditHistoryDetails.forEach((finance) => {
      this.creditHistoryArray.value.push(finance);
    });

    this.licenseDetails.forEach((finance) => {
      this.licensesArray.value.push(finance);
    });

    this.inputDetails.forEach((business) => {
      this.inputBusinessActivitiesArray.value.push(business);
    });

    this.outputDetails.forEach((business) => {
      this.outputBusinessActivitiesArray.value.push(business);
    });

    this.otherDetails.forEach((business) => {
      this.otherBusinessActivitiesArray.value.push(business);
    });

    this.chcDetails.forEach((business) => {
      this.chcBusinessActivitiesArray.value.push(business);
    });

    this.lendingDetails.forEach((business) => {
      this.lendingArray.value.push(business);
    });

    this.consumerDetails.forEach((business) => {
      this.consumerArray.value.push(business);
    });

    this.certifiedDetails.forEach((business) => {
      this.certifiedArray.value.push(business);
    });

    this.warehouseDetails.forEach((business) => {
      this.warehouseArray.value.push(business);
    });

    this.coldStorageDetails.forEach((business) => {
      this.coldStorageArray.value.push(business);
    });

    this.exportsImportsDetails.forEach((business) => {
      this.exportsOrImportsArray.value.push(business);
    });

    this.alliedDetails.forEach((business) => {
      this.alliedArray.value.push(business);
    });

    this.turnOverDetails.forEach((turnOver) => {
      this.turnoverDetailsArray.value.push(turnOver);
    });

    console.log('modifiedPayload', modifiedPayload);

    // this.organisationProfileService
    //   .createFpoProfilesDetails(modifiedPayload)
    //   .pipe(
    //     takeUntil(this.destroyed$),
    //     finalize(() => (this.isProgress = false))
    //   )
    //   .subscribe(
    //     (res) => {
    //       this.addSuccessHandler(res);
    //     },
    //     (err) => this.addErrorHandler(err)
    //   );
  }

  /**
   * Check if any of the field contains 'NO' value, if true assign empty object
   */
  checkNoBusinessActivity() {
    // If show consumer field is set to NO, send empty string with keys
    if(this.showConsumer.value === 'NO') {
      this.consumerDetails = [];
      const object = {
        consumerProductsCommodity: '',
        consumerProductsRevenue: '',
        showConsumer: 'NO'
      }
      this.consumerDetails.push(object);
    }

    // If show certified field is set to NO, send empty string with keys
    if(this.showCertified.value === 'NO') {
      this.certifiedDetails = [];
      const object = {
        certifiedProductionCrop: '',
        certifiedProductionRevenue: '',
        showCertified: 'NO'
      }
      this.certifiedDetails.push(object);
    }

    // If show warehouse field is set to NO, send empty string with keys
    if(this.showWarehouse.value === 'NO') {
      this.warehouseDetails = [];
      const object = {
        capacityInWarehouse: 0,
        commodityStoredInWarehouse: "",
        showWarehouse: "NO"
      }
      this.warehouseDetails.push(object);
    }

    // If show cold storage field is set to NO, send empty string with keys
    if(this.showColdStorage.value === 'NO') {
      this.coldStorageDetails = [];
      const object = {
        capacityInColdStorage: 0,
        commodityStoredInColdStorage: "",
        showColdStorage: "NO"
      }
      this.coldStorageDetails.push(object);
    }

    // If show export/import field is set to NO, send empty string with keys
    if(this.showImportsOrExports.value === 'NO') {
      this.exportsImportsDetails = [];
      const object = {
        exportsOrImportsCommodity: "",
        exportsOrImportsRevenue: "",
        showImportsOrExports: "NO"
      }
      this.exportsImportsDetails.push(object);
    }

    // If show allied details field is set to NO, send empty string with keys
    if(this.showAllied.value === 'NO') {
      this.alliedDetails = [];
      const object = {
        alliedActivities: "",
        alliedActivitiesRevenue: "",
        alliedOthers: "",
        showAllied: "NO"
      }
      this.alliedDetails.push(object);
    }

    // If show other business details field is set to NO, send empty string with keys
    if(this.otherBusinessShow.value === 'NO') {
      this.otherDetails = [];
      const object = {
        activities: "",
        businessRevenue: "",
        businessYear: "",
        otherBusiness: "NO"
      }
      this.otherDetails.push(object);
    }
  }

  /**
   * Get the profile details
   */
  getFpoProfileDetails() {
    // this.notification.showSpinner();
    // this.fpoProfileService
    //   .getFpoProfilesDetails(this.logInProfileKey)
    //   .pipe(
    //     takeUntil(this.destroyed$),
    //     finalize(() => this.notification.hideSpinner())
    //   )
    //   .subscribe(
    //     (res) => {
    //       this.fpoProfileDetails = res.data.samunnatiFpoDetails;
    //       this.basicInfoDetails();
    //       this.socialDetails();
    //       this.patchFpoProfileDetails();
    //     },
    //     (err) => this.addErrorHandler(err)
    //   );

    this.profileState.data$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(profile => {
      this.fpoProfileDetails = profile;
    });
  }

  /**
   * Patch fpo profile details
   */
  basicInfoDetails() {

    this.basicDetailsGroup.patchValue(this.fpoProfileDetails.basicDetailsInfo);

    // Appending companyCin value present in entityDetails
    const companyCinVal = this.fpoProfileDetails.basicDetailsInfo.entityDetails.companyCin;
    if (companyCinVal) {
      this.companyCinField.patchValue(companyCinVal);
    }

    if (
      this.fpoProfileDetails.basicDetailsInfo.fpoLogoDetails &&
      this.fpoProfileDetails.basicDetailsInfo.fpoLogoDetails.length
    ) {
      this.fpoProfileDetails.basicDetailsInfo.fpoLogoDetails.forEach(
        (logoDetails) => {
          this.fpoLogoDetailsForm.patchValue(logoDetails);
          this.fpoLogoDetailsArray.push(
            this.fb.control(this.fpoLogoDetailsForm.value)
          );
          this.fpoLogoDetailsForm.reset();
        }
      );
    }

    if (
      this.fpoProfileDetails.basicDetailsInfo.turnoverDetails &&
      this.fpoProfileDetails.basicDetailsInfo.turnoverDetails.length
    ) {
      this.fpoProfileDetails.basicDetailsInfo.turnoverDetails.forEach(
        (turnover) => {
          this.turnOverDetails.push(turnover);
        }
      );
    }

    if (this.aboutMeBasicDetails) {
      this.aboutMe.patchValue(this.aboutMeBasicDetails)
    }
  }

  /**
   * Patch social details
   */
  socialDetails() {
    this.socialDetailsGroup.patchValue(this.fpoProfileDetails.socialDetails);

    if (this.fpoProfileDetails.socialDetails) {
      if (
        this.fpoProfileDetails.socialDetails.promotingInstitution &&
        this.fpoProfileDetails.socialDetails.promotingInstitution.promotingInstitutionLogo
      ) {
        const attachmentArray = [];
        attachmentArray.push(
          this.fpoProfileDetails.socialDetails.promotingInstitution.promotingInstitutionLogo
        );
        this.promotionOfAttachments.patchValue(attachmentArray);
      }

      if (
        this.fpoProfileDetails.socialDetails.resourceInstitution &&
        this.fpoProfileDetails.socialDetails.resourceInstitution.resourcingInstitutionLogo
      ) {
        const attachmentArray = [];
        attachmentArray.push(
          this.fpoProfileDetails.socialDetails.resourceInstitution.resourcingInstitutionLogo
        );
        this.resourceOfAttachments.patchValue(attachmentArray);
      }

      if (
        this.fpoProfileDetails.socialDetails.teamPhoto &&
        this.fpoProfileDetails.socialDetails.teamPhoto.teamPhotoLogo
      ) {
        const attachmentArray = [];
        attachmentArray.push(
          this.fpoProfileDetails.socialDetails.teamPhoto.teamPhotoLogo
        );
        this.teamPhotoOfAttachments.patchValue(attachmentArray);
      }

      if (
        this.fpoProfileDetails?.socialDetails &&
        this.fpoProfileDetails?.socialDetails?.entityLogo
      ) {
        const attachmentArray = [];
        attachmentArray.push(
          this.fpoProfileDetails?.socialDetails?.entityLogo
        );
        this.entityLogoOfAttachments.patchValue(attachmentArray);
      }
    }
  }

  /**
   * Storing fpo profile details when get service call happens initally
   */
  patchFpoProfileDetails() {

    if (this.fpoProfileDetails.financialDetails) {
      this.fpoProfileDetails.financialDetails.bankDetails.forEach((finance) => {
        this.bankDetails.push(finance);
      });

      this.fpoProfileDetails?.financialDetails?.assetDetails.forEach((finance) => {
        this.assetDetails.push(finance);
      });

      this.fpoProfileDetails.financialDetails.creditHistory.forEach((finance) => {
        this.creditHistoryDetails.push(finance);
      });

      this.fpoProfileDetails.financialDetails.licenses.forEach((finance) => {
        this.licenseDetails.push(finance);
      });

      this.insuranceGroup.patchValue(
        this.fpoProfileDetails.financialDetails.insurance
      );
    }

    if (this.fpoProfileDetails.businessActivities) {
      this.fpoProfileDetails.businessActivities.inputBusinessActivities.forEach(
        (business) => {
          this.inputDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.outputBusinessActivities.forEach(
        (business) => {
          this.outputDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.otherBusinessActivities.forEach(
        (business) => {
          this.otherBusinessShow.patchValue(business.otherBusiness);
          this.otherDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.chcServices.forEach(
        (business) => {
          this.chcDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.lending.forEach((business) => {
        this.lendingDetails.push(business);
      });

      this.fpoProfileDetails.businessActivities.productsAndCommodities.consumer.forEach(
        (business) => {
          this.showConsumer.patchValue(business.showConsumer);
          this.consumerDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.productsAndCommodities.certified.forEach(
        (business) => {
          this.showCertified.patchValue(business.showCertified);
          this.certifiedDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.productsAndCommodities.warehouse.forEach(
        (business) => {
          this.showWarehouse.patchValue(business.showWarehouse);
          this.warehouseDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.productsAndCommodities.coldStorage.forEach(
        (business) => {
          this.showColdStorage.patchValue(business.showColdStorage);
          this.coldStorageDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.productsAndCommodities.exportsOrImports.forEach(
        (business) => {
          this.showImportsOrExports.patchValue(business.showImportsOrExports);
          this.exportsImportsDetails.push(business);
        }
      );

      this.fpoProfileDetails.businessActivities.productsAndCommodities.allied.forEach(
        (business) => {
          this.showAllied.patchValue(business.showAllied);
          this.alliedDetails.push(business);
        }
      );
    }

    if (this.fpoProfileDetails.bod) {
      this.fpoProfileDetails.bod.forEach(
        (bod) => {
          this.bodDetails.push(bod);
      });
    }

    if (this.fpoProfileDetails.cropInfo) {
      this.fpoProfileDetails.cropInfo.cropDetails.forEach(
        (crop) => {
          this.currentYearCropDetails.push(crop);
        });

      this.fpoProfileDetails.cropInfo.cropWiseProcurement.forEach((crop) => {
        this.cropwiseProcurementDetails.push(crop);
      });
    }
  }

  /**
   * Create social details
   */
  addSocialDetails() {
    this.promotingAttachments.patchValue(
      this.promotionOfAttachments?.value[0] &&
        this.promotionOfAttachments?.value[0].url
        ? this.promotionOfAttachments?.value[0].url
        : this.promotionOfAttachments?.value[0]
    );
    this.resourceAttachment.patchValue(
      this.resourceOfAttachments?.value[0] &&
        this.resourceOfAttachments?.value[0].url
        ? this.resourceOfAttachments?.value[0].url
        : this.resourceOfAttachments?.value[0]
    );
    this.teamPhotoAttachments.patchValue(
      this.teamPhotoOfAttachments?.value[0] &&
        this.teamPhotoOfAttachments?.value[0].url
        ? this.teamPhotoOfAttachments?.value[0].url
        : this.teamPhotoOfAttachments?.value[0]
    );
    this.entityLogoAttachments.patchValue(
      this.entityLogoOfAttachments?.value[0] &&
        this.entityLogoOfAttachments?.value[0].url
        ? this.entityLogoOfAttachments?.value[0].url
        : this.entityLogoOfAttachments?.value[0]
    );
    this.addBasicInfoDetails();
  }

  /**
   * Push turnover details in array
   */
  addTurnoverDetails() {

    this.turnoverDetailsForm.value.turnover = this.turnoverDetailsForm.value.turnover.replace(/,/g, '');
    if (!this.isEditTurnoverDetails) {
      this.turnOverDetails.push(this.turnoverDetailsForm.value);
      this.filterFinancialYrs();
    } else {
      this.turnOverDetails.splice(this.turnoverDetailsIndex, 1, this.turnoverDetailsForm.value);
      this.filterFinancialYrs(this.editFinancialYears, 'edit');
    }
    // Sorting the details based on financial year.
    this.turnOverDetails = this.sortFinancialYearsByYear(this.turnOverDetails, 'financialYearId')
    this.isEditTurnoverDetails = false;
    this.turnoverDetailsForm.reset();
  }

  /**
   * Filters financial years after selection and add based in the turn over table
   */
  filterFinancialYrs(financialYears?:any, deleteYears?: string) {
    // If deleteYears is provided, filter the financialYears array; otherwise, use the original financialYears.
    const filteredYears = deleteYears ? financialYears : this.financialYears;

    // Filter the financialYears array based on the presence of corresponding turnOverDetails.
    const filteredFinancialYears = filteredYears.filter(year => {
      const hasTurnover = this.turnOverDetails.some(turnOver => turnOver.financialYearId === year.id);
      return !hasTurnover;
    });

    // Update the financialYears property with the filtered results.
    this.financialYears = filteredFinancialYears;

    return filteredFinancialYears;
  }

  /**
   * Sorting the array based on financial year
   * @param financialYear
   */
  sortFinancialYearsByYear(financialYear: any[], fieldName: string): any[] {
    return financialYear.sort((a, b) => {
      const yearA = a[fieldName].split('-')[0];
      const yearB = b[fieldName].split('-')[0];

      if (yearA > yearB) {
        return -1;
      } else if (yearA < yearB) {
        return 1;
      }

      return 0;
    });
  }

  /**
   * Push fpoLogo details
   */
  addFpoDetails() {
    const attachment = this.lstOfAttachments.value || '';
    this.logoAttachments.patchValue(attachment[0].url);
    this.logoDetails.push(this.fb.control(this.fpoLogoDetailsForm.value).value);
    this.fpoLogoDetailsArray.push(
      this.fb.control(this.fpoLogoDetailsForm.value)
    );
    this.fpoLogoDetailsForm.reset();
    this.lstOfAttachments.patchValue('');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected method
  // --------------------------------------------------------

  /**
   * Fpo profiles form
   */
  private get newFpoProfilesForm(): FormGroup {
    return new FormGroup({
      basicDetailsInfo: new FormGroup({
        basicDetails: new FormGroup({
          fpoId: new FormControl(''),
          fpoName : new FormControl(''),
          firstName: new FormControl(''),

          // orgName: new FormControl(''),
          contactNumber: new FormControl(
            '',
            KalgudiFormValidators.indianMobileNumberValidators
          ),
          foundedOn: new FormControl(''),
          memberShipBase: new FormControl('', KalgudiFormValidators.numberValidator),
          femaleMembers: new FormControl('', KalgudiFormValidators.numberValidator),
          maleMembers: new FormControl('', KalgudiFormValidators.numberValidator),
          isWomenLedEntity: new FormControl(''),
          activeMembers: new FormControl('', KalgudiFormValidators.numberValidator),
          perOfSmallHolder: new FormControl(0, Validators.max(100)),
          isFarmerGrpAvailable: new FormControl(''),
          shareCapital: new FormControl(''),
          fpoStatus: new FormControl(''),
          status: new FormControl(''),
          aboutMe: new FormControl(''),
          mainActivity: new FormControl(''),
          boardMeeting: new FormControl(''),
          panNumber: new FormControl(''),
          totalEmployees: new FormControl(''),
          totalBod: new FormControl(''),
          isFigAvailable: new FormControl(''),
          createdAt: new FormControl(''),
          updatedAt: new FormControl(''),
        }),

        turnoverDetails: new FormArray([]),

        ceoDetails: new FormGroup({
          ceoName: new FormControl('', KalgudiFormValidators.alphabetValidator),
          ceoMobileNumber: new FormControl(
            '',
            KalgudiFormValidators.indianMobileNumberValidators
          ),
          ceoEmailId: new FormControl(
            '',
            KalgudiFormValidators.strictEmailValidators
          ),
        }),

        entityDetails: new FormGroup({
          entityType: new FormControl(''),
          companyCin: new FormControl(''),
          societyType: new FormControl(''),
          cooperativeRegistrationNumber: new FormControl(''),
          trustRegistrationNumber: new FormControl(''),
        }),

        samunnatiRelationship: new FormGroup({
          rmEmail: new FormControl('', KalgudiFormValidators.strictEmailValidators),
          rmName: new FormControl('', KalgudiFormValidators.alphabetValidator),
          shName: new FormControl('', KalgudiFormValidators.alphabetValidator),
          shEmail: new FormControl('', KalgudiFormValidators.strictEmailValidators),
        }),

        // Program type details new formGroup
        programType: new FormGroup({
          isKym: new FormControl(''),
          mandi: new FormControl(''),
          lhfpoMember: new FormControl(''),
          bmgfMember: new FormControl(''),
          fpoNextMember: new FormControl(''),
          fpo360: new FormControl(''),
          isFederation: new FormControl(''),
          samIpl: new FormControl(''),
          sbi: new FormControl(''),
          isLighter: new FormControl(''),
        }),

      }),

      financialDetails: new FormGroup({
        bankDetails: new FormArray([]),
        creditHistory: new FormArray([]),
        licenses: new FormArray([]),
        assetDetails: new FormArray([]),
        insurance: new FormGroup({
          cropInsFarmerCount: new FormControl(0, KalgudiFormValidators.numberValidator),
          lifeInsFarmerCount: new FormControl(0, KalgudiFormValidators.numberValidator),
          annadhathaSurakshaFarmerCount: new FormControl(0, KalgudiFormValidators.numberValidator),
        }),
      }),

      socialDetails: new FormGroup({
        promotingInstitution: new FormGroup({
          promotingInstitution: new FormControl(''),
          promotingInstitutionLogo: new FormControl(''),
          promotingInstitutionLogoName : new FormControl(''),
        }),

        resourceInstitution: new FormGroup({
          resourcingInstitution: new FormControl(''),
          resourcingInstitutionLogo: new FormControl(''),
          resourcingInstitutionLogoName: new FormControl(''),
        }),

        teamPhoto: new FormGroup({
          teamPhotoName: new FormControl(''),
          teamPhotoLogo: new FormControl(''),
        }),

        entityLogo: new FormControl(''),

        socialInfo: new FormGroup({
          officialWebsiteLink: new FormControl(''),
          facebookLink: new FormControl(''),
          twitterLink: new FormControl(''),
          instagramLink: new FormControl(''),
        }),
      }),

      businessActivities: new FormGroup({
        inputBusinessActivities: new FormArray([]),
        outputBusinessActivities: new FormArray([]),
        otherBusinessActivities: new FormArray([]),
        chcServices: new FormArray([]),
        lending: new FormArray([]),

        productsAndCommodities: new FormGroup({
          consumer: new FormArray([]),
          certified: new FormArray([]),
          warehouse: new FormArray([]),
          coldStorage: new FormArray([]),
          exportsOrImports: new FormArray([]),
          allied: new FormArray([]),
        }),

        infrastructure: new FormGroup({
          id: new FormControl(0),
          name: new FormControl('')
        })

      }),

      cropInfo: new FormGroup({
        cropDetails: new FormArray([]),
        cropWiseProcurement: new FormArray([]),
      }),

      bod: new FormGroup({
        bodDetails: new FormArray([]),
      }),

    });
  }

  /**
   * Form group for service form
   */
  private get newFpoLogoDetailsForm(): FormGroup {
    return new FormGroup({
      logoName: new FormControl(''),
      attachment: new FormControl(''),
    });
  }

  /**
   * Form group for service form
   */
  private get newTrunoverDetailsForm(): FormGroup {
    return this.fb.group({
      financialYearId: [''],
      turnover: [''],
    });
  }

  /**
   * Form group for financial details
   */
  private get newFinancialDetailsForm(): FormGroup {
    return this.fb.group({
      bankDetails: this.fb.group({
        bankName: [''],
        branch: ['', KalgudiFormValidators.alphanumericValidator],
        bankAccountType: [''],
        accountNumber: ['', KalgudiFormValidators.numberValidator],
        ifscCode: [''],
      }),
      creditHistory: this.fb.group({
        loanAmountAvailed: [''],
        tenure: ['', KalgudiFormValidators.numberValidator],
        lenderName: [''],
        creditHistoryStatus: [''],
      }),
      licenses: this.fb.group({
        licenceName: [''],
        licenceNumber: [''],
        validityDate: [''],
        attachment: [''],
      }),
      assetDetails: this.fb.group({
        assetType: [''],
        assetStatus: [''],
        assetValue: [''],
        otherDetails: ['', KalgudiFormValidators.alphanumericValidator],
        quantity: ['', KalgudiFormValidators.numberValidator],
      }),
    });
  }

  /**
   * Form group for current year details form
   */
  private get newCurrentYearDetailsForm(): FormGroup {
    return this.fb.group({
      cropDetails: this.fb.group({
        year: [''],
        season: [''],
        cropId: [''],
        cropName: [''],
        varietyId: [''],
        varietyName:[''],
        cultivationArea: [''],
        outputQty: [''],
        outputQtyUnit: [''],
      }),
    });
  }

  /**
   * Form group for crop details form
   */
  private get newCropDetailsForm(): FormGroup {
    return this.fb.group({
      cropDetails: this.fb.group({
        year: [''],
        season: [''],
        cropId: [''],
        cropName: [''],
        varietyId: [''],
        varietyName:[''],
        cultivationArea: [''],
        outputQty: [''],
        outputQtyUnit: [''],
      }),

      cropWiseProcurement: this.fb.group({
        year: [''],
        noOfFarmers: [''],
        crop: [''],
        totalQty: [''],
        season : [''],
      }),
    });
  }

  /**
   * Form group for business activities
   */
  private get newBusinessActivitiesForm(): FormGroup {
    return this.fb.group({
      input: this.fb.group({
        businessYear: [''],
        businessRevenue: [''],
        inputShop: [''],
        quantity: [''],
      }),
      output: this.fb.group({
        businessYear: [''],
        businessRevenue: [''],
        percentageOfOutput: ['', Validators.max(100)],
        topThreeBuyers: [''],
      }),
      others: this.fb.group({
        businessYear: [''],
        businessRevenue: [''],
        otherBusiness: [''],
        activities: [''],
      }),
      chc: this.fb.group({
        businessYear: [''],
        businessRevenue: [''],
        chcShop: [''],
        quantity: [''],
      }),
      lending: this.fb.group({
        year: [''],
        lendingAmount: [''],
      }),
      consumer: this.fb.group({
        showConsumer: [''],
        consumerProductCommodity: [''],
        consumerProductRevenue: [''],
      }),
      certified: this.fb.group({
        showCertified: [''],
        certifiedProductionCrop: [''],
        certifiedProductionRevenue: [''],
      }),
      warehouse: this.fb.group({
        showWarehouse: [''],
        commodityStoredInWarehouse: [''],
        capacityInWarehouse: [''],
      }),
      coldStorage: this.fb.group({
        showColdStorage: [''],
        commodityStoredInColdStorage: [''],
        capacityInColdStorage: [''],
      }),
      importsOrExports: this.fb.group({
        showImportsOrExports: [''],
        exportsOrImportsCommodity: [''],
        exportsOrImportsRevenue: [''],
      }),
      allied: this.fb.group({
        showAllied: [''],
        alliedActivities: [''],
        alliedOthers: [''],
        alliedActivitiesRevenue: [''],
      }),
    });
  }

  /**
   * Form group for bodDetails form
   */
  private get newBODDetailsForm(): FormGroup {
    return this.fb.group({
      bodDetails: this.fb.group({
        name: ['', KalgudiFormValidators.alphabetValidator],
        designation: [''],
        gender: [''],
        location: ['', KalgudiFormValidators.alphanumericValidator],
        mobile: ['', KalgudiFormValidators.indianMobileNumberValidators],
        otherDesignation: ['', KalgudiFormValidators.alphanumericValidator],
      })
    })
  }

  /**
   * Add services success handler
   */
  private addSuccessHandler(res: any) {
    this.notification.showMessage('Data updated successfully');
    this.closeDialog.emit();
  }

  /**
   * Add services error handler
   */
  private addErrorHandler(err: Error) {
    this.notification.showMessage(
      'Unable to add services, please try again later!'
    );
  }

  seasonType = [
    { id: 'KHARIF', value: 'Kharif' },
    { id: 'RABI', value: 'Rabi' },
    { id: 'ZAID', value: 'Zaid' },
  ];

  outputQtyUnits = [
    { id: 'Gram(s)', value: 'Gram(s)' },
    { id: 'Kg(s)', value: 'Kg(s)' },
    { id: 'Quintal(s)', value: 'Quintal(s)' },
    { id: 'Ton(s)', value: 'Ton(s)' },
  ]

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * Converting give value into indian notation
   * ex: 100000 into 1,00,000
   * @param value
   * @returns
   */
  convertIndianRupeeFormat(value) {
    return '₹' + Number(value).toLocaleString('en-IN');
  }
}
