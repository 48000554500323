<div class="h-100 surveys-members-search">

  <div class="" fxLayout="column" fxLayoutAlign="space-between stretch">
    <main>
      <kl-surveys-members-search [(selectedUsersMap)]="selectedUsersMap">
      </kl-surveys-members-search>
    </main>

    <div class="pt-0 search-members position-fixed" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <kl-link-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
      <kl-link-button [label]="dialogData?.acceptButtonTitle" buttonColor="primary" (clicked)="ok()"></kl-link-button>
    </div>
  </div>
</div>
