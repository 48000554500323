import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { KalgudiCommonModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiProgramListModule } from '../program-list/program-list.module';
import { MyProgramsListComponent } from './my-programs-list/my-programs-list.component';
import { AllLocationsDialogComponent } from './program-header/all-locations-dialog/all-locations-dialog.component';
import {
  AllLocationsMobileDialogComponent,
} from './program-header/all-locations-mobile-dialog/all-locations-mobile-dialog.component';
import { ProgramHeaderComponent } from './program-header/program-header.component';
import { ProgramTabsComponent } from './program-tabs/program-tabs.component';


@NgModule({
  declarations: [
    MyProgramsListComponent,
    ProgramHeaderComponent,
    ProgramTabsComponent,
    AllLocationsDialogComponent,
    AllLocationsMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    RouterModule,

    // Material
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,

    FlexLayoutModule,

    // Svg Icon Module
    AngularSvgIconModule,

    // Kalgudi Libraries
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiProgramListModule,
    KalgudiCommonModule,

    TranslateModule,

    KalgudiGoogleGeoLocationModule
  ],
  exports: [
    MyProgramsListComponent,
    ProgramHeaderComponent,
    ProgramTabsComponent,
  ]
})
export class KalgudiProgramDetailsPageModule { }
