<div fxLayout="row" fxLayoutGap="10px" class="status-indication" fxLayout.xs="column" fxLayoutGap.xs="3px">

  <div fxShow.xs fxHide><span translate>Total</span>: {{ logsCount }}</div>

  <ng-container *ngFor="let state of statusStates">
    <div fxLayoutGap="5px" fxLayoutAlign="start center">
      <div class="status-circle {{state?.statusColor}}"></div>
      <div class="state">{{state?.label | translate}} ({{state?.count}})</div>
    </div>
  </ng-container>
</div>
