import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

import { KalgudiEmployeeProfile } from '../../classes/kalgudi-employee-profile';

@Component({
  selector: 'kl-employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent extends KalgudiEmployeeProfile implements OnInit {

  @Input()
  profileDetails: KalgudiUser;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(injector, util);

    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {

    this.getLoginId();
  }

  onDestroyed(): void {}

}
