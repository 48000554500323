import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiError, ReportAbuseRequest, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

@Injectable()
export class KalgudiReportAbuseApiService {

  /**
   * `v1/reportabuse`
   */
  private readonly API_REPORT_ABUSE = `${this.environment.restBaseUrl}/reportabuse`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
  ) { }

  /**
   * Calls the report abuse post API
   * @param payload Request payload
   */
  reportAbuse(payload: ReportAbuseRequest): Observable<boolean> {
    return this.httpClient
      .post<ApiResponseCommon>(this.API_REPORT_ABUSE, payload)
      .pipe(
        map(res => this.reportAbuseResponseHandler(res)),
        mapTo(true)
      );
  }

  /**
   * It verifies for successful response
   */
  private reportAbuseResponseHandler(res: ApiResponseCommon): ApiResponseCommon {
    // Report error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.OK) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successfully deleted
    return res;
  }
}
