import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { Credit, KalgudiCredit, SmsSettingsPayload } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CreditApiService } from './credit-api.service';

@Injectable()
export class CreditService {

  constructor(private api: CreditApiService) { }

  /**
   * Fetches logged in user pages from the API
   */
  fetchUserPages(offset: number, limit: number): Observable<KalgudiCredit> {

    return this.api.fetchCreditActivity(offset, limit);
  }

  /**
   * Fetches credit activity list from the API
   */
  fetchCreditActivityList(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchCreditActivity(offset, limit).pipe(

      // Map API response to Kalgudi credit activity details object type
      map(res => ({ count: res.activitiesCount, items: res.activities }))
    );
  }

  /**
   * Fetches credit details from API
   */
  fetchCreditDetails(creditorId: string): Observable<Credit> {
    return this.api.fetchCreditDetails(creditorId);
  }

  /**
   * Create and update credit settings from API
   */
  createAndUpdateCredit(payload: SmsSettingsPayload): Observable<Credit> {
    return this.api.createAndUpdateCredit(payload);
  }

  /**
   * Calls API to transfer credit
   */
  // transferCredits(payload) {
  //   return this.api.transferCredits(payload);
  // }

}
