import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProfileSocialActivities } from '../../classes/kalgudi-profile-social-activities';

@Component({
  selector: 'kl-profile-social-activities',
  templateUrl: './profile-social-activities.component.html',
  styleUrls: ['./profile-social-activities.component.scss']
})
export class ProfileSocialActivitiesComponent extends KalgudiProfileSocialActivities implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

}
