import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, KalgudiUserTaxDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiProfileTaxesApiService {

  /**
   * `v1/profiles/:profileKey/Businesses/:bizKey/BusinessTaxes`
   */
  private readonly API_TAXES_BASE = `${this.env.restBaseUrl}/profiles/:profileKey/Businesses/:bizKey/TaxFields`;

  /**
   * `v1/profiles/:profileKey/Businesses/:bizKey/BusinessTaxes`
   */
  private readonly API_TAXES_SAVE = this.API_TAXES_BASE;

  /**
   * `v1/profiles/:profileKey/Businesses/:bizKey/BusinessTaxes/:taxId`
   */
  private readonly API_TAXES_UPDATE = `${this.API_TAXES_BASE}/:taxId`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private profileState: KalgudiProfileStateService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Calls API to save a new tax to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param payload tax payload
   */
  saveNewTax(
    profileKey: string, defaultBizKey: string,
    payload: KalgudiUserTaxDetails, extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_TAXES_SAVE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey);

    return this.httpClient.post<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateTaxResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to save a new tax to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param taxId Unique identified associated with tax
   *
   * @param payload Updated tax payload
   */
  updateNewTax(
    profileKey: string, defaultBizKey: string,
    taxId: string, payload: KalgudiUserTaxDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_TAXES_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':taxId', taxId);

    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateTaxResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to delete an existing tax of the logged in user.
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param taxId Unique identifier of the tax
   */
  deleteTax(
    profileKey: string,
    defaultBizKey: string,
    taxId: string,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_TAXES_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':taxId', taxId);

    return this.httpClient.delete<ApiResponseCommonV1>(url, { params })
      .pipe(

        // Api response error handler
        map(r => this.deleteTaxResponseHandler(r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Save new tax API response handler. Throws error messages if
   * Api didn't sent success creation response.
   */
  private saveOrUpdateTaxResponseHandler(req: KalgudiUserTaxDetails, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.CREATED) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved tax details
    return res;
  }

  /**
   * Delete tax API response handler. Throws error messages if
   * Api didn't sent success deletion response.
   */
  private deleteTaxResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.CREATED) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved tax details
    return res;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
