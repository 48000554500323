import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiFcmService } from '@kalgudi/third-party/fcm';
import {
  ApiResponseCommon,
  ApiResponseCommonV1,
  AuthApiResponse,
  AuthDetails,
  AuthLoginResponse,
  KalgudiUser,
  LoginCredentials,
  SSOAuthApiResponse,
  SSOLoginResponse,
} from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { HttpStatusCode, LoginSource, REST_API_ERROR_MESSAGES } from '../constants';
import { ApiError, InvalidCredentialsError, KalgudiError, ProfileNotVerifiedError } from '../errors';
import { KalgudiUtilityService } from './kalgudi-util.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiAppApiService {

  /**
   * Rest api login responses
   */
  private readonly LOGIN_RESPONSES = {
    /** 200 */
    SUCCESS: HttpStatusCode.OK,

    /** 400 */
    INVALID_PASSWORD: HttpStatusCode.BAD_REQUEST,

    /** 404 */
    INVALID_USER: HttpStatusCode.NOT_FOUND,

    /** 300 */
    VERIFY_OTP: HttpStatusCode.MULTIPLE_CHOICE,
  };

  readonly API_PROFILE_BASE = `${this.env.restBaseUrl}/profiles`;

  readonly API_AUTH_BASE = `${this.env.restBaseUrlV2}/auth`;

  /**
   * `v1/auth/login`
   */
  readonly API_AUTH_LOGIN = `${this.API_AUTH_BASE}/login`;

  /**
   * `v2/auth/openid-login`
   */
  readonly API_OFFICE_365_AUTH_LOGIN = `${this.API_AUTH_BASE}/openid-login`;

  /**
   * `v1/auth/token`
   */
  readonly API_AUTH_TOKEN = `${this.API_AUTH_BASE}/token`;

  /**
   * `v1/auth/ping`
   */
  readonly API_AUTH_PING = `${this.API_AUTH_BASE}/ping`;

  /**
   * `v2/profiles`
   */
  readonly API_PROFILE = `${this.env.restBaseUrlV2}/profiles`;

  /**
   * `v1/profiles/:profileKey`
   */
  readonly API_USER_PROFILE = `${this.API_PROFILE}/:profileKey`;

  /**
   * `v2/profiles/last-open`
   */
  readonly API_PROFILE_LAST_OPEN = `${this.env.restBaseUrlV2}/profiles/last-open`;

  /** `https://shaktimanfarmingsolutions.com/logout` */
  readonly API_WHITE_LABELED_APP_LOGOUT = `${this.env.domain}/auth/logout`;

  /**
   * 'v2/auth/generate-otp`
   */
  private readonly GENERATE_OTP = `${this.env.restBaseUrlV2}/auth/generate-otp`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private fcmService: KalgudiFcmService,
  ) { }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * `true` if the app is shaktiman farming solutions otherwise `false`.
   */
  get isShaktimanFarmingSolutions(): boolean {
    return this.env.appId && this.env.appId.includes('SHAKTIMAN');
  }

  /**
   * `true` if the app is pro rise store otherwise `false`.
   */
  get isProRiseStore(): boolean {
    return this.env.appId && this.env.appId.includes('PRORISE_CORE');
  }

  /**
   * Calls login Rest API to log in user to the app.
   *
   * @param req Login request payload
   */
  login(req: LoginCredentials, otpverified: any): Observable<AuthLoginResponse> {

    let params;
    if(otpverified) {
      params = {
        otpverified
      }
    }

    return this.httpClient
      .post<AuthApiResponse>(this.API_AUTH_LOGIN, req, { params })
      .pipe(

        // Check for login API response errors
        map(res => this.loginResponseHandler(req, res)),

        // Map API response to kalgudi short user profile type
        map(r => r.data)
      );
  }

  /**
   * Calls login Rest API to log in user to the app by using office 365 token.
   *
   * @param token microsoft office 365 accessToken
   */
  loginWithOffice365Token(accessToken: string, appId: string): Observable<SSOLoginResponse> {

    return this.httpClient
      .post<SSOAuthApiResponse>(this.API_OFFICE_365_AUTH_LOGIN,
        { accessToken, loginSource: LoginSource.MICROSOFT_365}, {  })
      .pipe(

        // Check for login API response errors
        map(res => {
          if(!res.data.userBasicDetail) {
            res.data.userBasicDetail = res.data.userData;
          }

          return this.loginResponseHandler({} as any, res as any)
        }),

        // Map API response to kalgudi short user profile type
        map(r => r.data as SSOLoginResponse)
      );
  }

  /**
   * Hit the API call/endpoint of the generate-otp and generate OTP
   */
  generateOtp(payload: any): Observable<ApiResponseCommon> {
    return this.httpClient.post<ApiResponseCommon>(this.GENERATE_OTP, payload);
  };

  /**
   * Calls profile last open API.
   */
  appUsage(isLogin: boolean): Observable<any>{
    const payload ={
      appName : this.env.appId,
      isLogin : isLogin
    }

    const params ={}
    return this.httpClient.put<any>(this.API_PROFILE_LAST_OPEN, payload, { params })
    .pipe(
      // Handle api error
      map(res => this.util.apiErrorHandler(res)),

      // Return response data
      map(res => { res = res.info })
    );
  }

  /**
   * Gets, latest auth token from the auth Api.
   */
  getAuthToken(): Observable<AuthDetails> {

    return this.httpClient
      .get<AuthApiResponse>(this.API_AUTH_TOKEN)
      .pipe(

        // Check for login API response errors
        map(res => this.authTokenResponseHandler(res)),

        // Map API response to kalgudi short user profile type
        map(r => r.data.auth)
      );
  }

  /**
   * Logout user from the app.
   */
  logout(profileKey?: string): Observable<boolean> {
    return this.isShaktimanFarmingSolutions
      ? this.logoutShaktimanCore()
      : this.isProRiseStore
        ? this.logoutProRiseCore()
        : this.logoutKalgudiCore(profileKey);
  }

  /**
   * Makes an Api call to check whether app Api with auth validation is responding or not.
   */
  ping(): Observable<boolean> {

    return this.httpClient
      .get<AuthApiResponse>(this.API_AUTH_PING)
      .pipe(

        // Check for login API response errors
        // map(res => this.loginHandler(null, res)),

        // Map API response to kalgudi short user profile type
        mapTo(true)
      );
  }

  /**
   * Fetches logged in user profile details from the API.
   *
   * **NOTE** Must use this method only to fetch logged in user profile details.
   *
   * @param profileKey Logged in user profile key
   */
  fetchProfile(profileKey: string): Observable<KalgudiUser> {
    let params;
    if(this.env.appId === 'TRADERS_APP') {
      params = {
        appName: this.env.appId
      }
    }

    const url = this.API_USER_PROFILE.replace(':profileKey', profileKey);

    return this.httpClient.get<ApiResponseCommonV1>(url, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(
          res,
          HttpStatusCode.OK,
          {
            ...REST_API_ERROR_MESSAGES,
            [HttpStatusCode.NOT_FOUND]: 'Unable to find profile'
          }
        )),

        map(res => this.util.toJson<KalgudiUser>(res.data))
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Login response handler. It verifies login response for successful
   * or failed login. It also displays specific message on failed login.
   */
  private loginResponseHandler(req: LoginCredentials, res: AuthApiResponse): AuthApiResponse {

    // Login error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
      [this.LOGIN_RESPONSES.INVALID_USER]: !(this.env.appId === 'ORMAS' || this.env.appId === 'SAM_FARMER' || this.env.appId === 'SAM_FPO' || this.env.appId === 'FPO_APP' || this.env.appId === 'FPO_NET_APP' || this.env.appId === 'ORMAS_SHG') ? 'Invalid user id, please check your ' + (this.env.appId === 'TRADERS_APP' || 'ORMAS' ? 'mobile' : 'email/mobile') : res.info,
      [this.LOGIN_RESPONSES.INVALID_PASSWORD]: (this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER' || this.env.appId === 'FPO_APP' || this.env.appId === 'FPO_NET_APP' || this.env.appId === 'ORMAS_SHG') ?  res.info : 'Invalid password, please verify your password',
      [this.LOGIN_RESPONSES.VERIFY_OTP]: 'Please verify your OTP first to continue',
    };

    let error: KalgudiError;

    if (res.code === this.LOGIN_RESPONSES.VERIFY_OTP) {
      // Otp verification pending
      error = new ProfileNotVerifiedError(new Error(errorMessages[res.code]));

    } else if (res.code === this.LOGIN_RESPONSES.INVALID_PASSWORD || res.code === this.LOGIN_RESPONSES.INVALID_USER) {
      // Invalid credentials
      error = new InvalidCredentialsError(new Error(errorMessages[res.code]));

    } else if (res.code !== this.LOGIN_RESPONSES.SUCCESS) {
      // Api error
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful login
    return res;
  }

  /**
   * Auth token re-generate Api response handler. Handles all api error thrown by
   * the auth token service.
   */
  private authTokenResponseHandler(res: AuthApiResponse): AuthApiResponse {

    // Login error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES
    };

    if (res.code !== HttpStatusCode.CREATED) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successful login
    return res;
  }

  /**
   * Logout from kalgudi core
   */
  private logoutKalgudiCore(profileKey: string): Observable<boolean> {

    // Make sure you don't lose the existing token
    const token = this.fcmService.token;

    if (token) {
      this.deleteToken(token, profileKey).subscribe();
    }

    // Clear local storage
    localStorage.clear();

    // Set the fcm token back to the local storage after clearing local storage
    // this.fcmService.token = token;

    return of(false);
  }

  /**
   * Calls api to delete token
   */
  private deleteToken(token: string, profileKey: string): Observable<any> {
    return this.fcmService.deleteTokenFromApi(token, profileKey);
  }

  /**
   * Logouts from shaktiman core app.
   */
  private logoutShaktimanCore(): Observable<boolean> {

    // Clear local storage
    localStorage.clear();

    if (this.env.production && !this.env.development && this.env.domain === 'https://shaktimanfarmingsolutions.com') {
      window.open(this.API_WHITE_LABELED_APP_LOGOUT, '_self');
    }

    return of(false);
  }

  private logoutProRiseCore(): Observable<boolean> {

    // Clear local storage
    localStorage.clear();

    if (this.env.production && !this.env.development && this.env.domain === 'https://prorisestore.com') {
      window.open(this.API_WHITE_LABELED_APP_LOGOUT, '_self');
    }

    return of(false);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
