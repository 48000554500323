import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { KalgudiCoreRouteConfig, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { Observable } from 'rxjs';

import { KalgudiAppService } from '../services/kalgudi-app.service';


/**
 * App route authentication guards. Restricts a route to be active until the user is
 * logged in.
 *
 * @author Pankaj Prakash
 */
@Injectable({
  providedIn: 'root'
})
export class KalgudiAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    @Inject(KL_ROUTE_CONFIG) private kalgudiRouteConfig: KalgudiCoreRouteConfig,
    private kalgudiApp: KalgudiAppService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.handleGuard(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.handleGuard(childRoute, state);
  }

  /**
   * Handle auth guard protected pages
   */
  private handleGuard(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    // Route page to on guard failure page
    if (!this.kalgudiApp.loggedIn) {
      this.kalgudiRouteConfig.onAuthGuardFailure(route, state);
    }

    return this.kalgudiApp.loggedIn;
  }

}
