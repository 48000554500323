<div id="survey-questions-stream">

  <div *ngIf="(stream$ | async) as questions"
    fxLayout="row wrap"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center">

    <kl-survey-questions-list
      [questions]="questions"
      (removeQuestion)="checkAndRemoveQuestion($event)">
    </kl-survey-questions-list>

    <!-- No content if there no questions -->
    <div class="w-100" *ngIf="(paginator$ | async) as paginator" fxLayout="row">
      <ng-container *ngIf="!questions?.length">
        <div class="no-content-center w-100" fxLayoutAlign="center center">
          <kl-no-content
            svgIcon="assets/svgs/discuss-issue.svg"
            text="No questions added for this survey yet!"
            iconSize="md"
            actionLink="Add questions"
            progressText="Loading questions..."
            [progress]="paginator?.loading"
            (action)="checkAndAddQuestion()">
          </kl-no-content>
        </div>
      </ng-container>
    </div>
    <!--/ No content if there no questions -->
  </div>

</div>

<div *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
 <!-- <kl-float-button (clicked)="checkAndAddQuestion()"></kl-float-button>-->
 <div class="footer-mobile-tab-wrapper fixed-bottom" fxLayout="row" fxHide.gt-xs>

  <ng-container *ngFor="let menu of footerMenus; let first=first;">
    <div class="border-right my-2" *ngIf="!first"></div>
    <div fxFlex fxFlexAlign="center">
      <kl-mobile-tabs [menu]="menu" (clicked)="menu.clickAction()"></kl-mobile-tabs>
    </div>
  </ng-container>
</div>
</div>
