<mat-card fxFlex="100%" fxLayout="column" id="finance-details-container">
  <!-- finance details header -->
  <div class="finance-details-header py-2 mb-2 mt-n4" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="pt-2">
      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/icons/finance.svg"
          [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 font-weight-bold" translate>Finance Details</p>
      </div>
    </div>
    <div matTooltip="Edit finance details" *ngIf="!isPublicPage">
      <button  *ngIf="(financeFormDetails?.bankDetails?.length || financeFormDetails?.creditHistory?.length || financeFormDetails?.assetDetails?.length  || financeFormDetails?.licenses?.length || financeFormDetails?.insurance?.cropInsFarmerCount || financeFormDetails?.insurance?.lifeInsFarmerCount || financeFormDetails?.insurance?.annadhathaSurakshaFarmerCount)" mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end" (click)="showFinanceDetailsDialog(true)">
        <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
      </button>
    </div>
  </div>
  <!--/ finance details header -->

  <!-- Finance details list -->
  <div class="finance-details-list" *ngIf="(financeFormDetails?.bankDetails?.length || financeFormDetails?.creditHistory?.length || financeFormDetails?.licenses?.length || financeFormDetails?.assetDetails?.length || financeFormDetails?.insurance?.cropInsFarmerCount || financeFormDetails?.insurance?.lifeInsFarmerCount || financeFormDetails?.insurance?.annadhathaSurakshaFarmerCount)">

    <!-- Bank account details -->
    <div class="mb-2 mr-2" *ngIf="isPublicPage ? false : financeFormDetails?.bankDetails?.length">
      <!-- Header bank details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Bank account details</h6>
      </div>
      <!--/ Header bank details -->

      <!-- Bank values -->
      <div class="bank-values-table finance-table-value rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Bank name</div>
            <div class="table-cell" translate>Branch</div>
            <div class="table-cell" translate>Account type</div>
            <div class="table-cell" translate>Account number</div>
            <div class="table-cell" translate>IFSC</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let details of financeFormDetails?.bankDetails; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ (details?.bankName | titlecase) || '-' }}</div>
              <div class="table-cell text-break">{{ (details?.branch | titlecase) || '-' }}</div>
              <div class="table-cell text-break">{{ details?.bankAccountType || '-' }}</div>
              <div class="table-cell text-break">{{ details?.accountNumber || '-' }}</div>
              <div class="table-cell text-break">{{ (details?.ifscCode | uppercase) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Bank values -->
    </div>
    <!--/ Bank account details -->

    <!-- Credit details -->
    <div class="mb-2 mr-2" *ngIf="isPublicPage ? false : financeFormDetails?.creditHistory?.length">
      <!-- Header credit details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Credit history</h6>
      </div>
      <!--/ Header credit details -->

      <!-- Credit values -->
      <div class="credit-values-table finance-table-value rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Loan amount <span>(₹)</span></div>
            <div class="table-cell" translate>Tenure(in months)</div>
            <div class="table-cell" translate>Availed from</div>
            <div class="table-cell" translate>Status</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let details of financeFormDetails?.creditHistory; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(details?.loanAmountAvailed) || '-' }}</div>
              <div class="table-cell text-break">{{ details?.tenure || '-' }}</div>
              <div class="table-cell text-break">{{ (details?.lenderName | titlecase) || '-' }}</div>
              <div class="table-cell text-break">{{ (details?.creditHistoryStatus | titlecase) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Credit values -->
    </div>
    <!--/ Credit details -->

    <!-- License details -->
    <div class="mb-2 mr-2" *ngIf="financeFormDetails?.licenses?.length">
      <!-- Header license details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>License details</h6>
      </div>
      <!--/ Header license details -->

      <!-- License values -->
      <div class="license-values-table finance-table-value rounded overflow-auto" [ngClass]="{'license-public-view' : isPublicPage}">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Type of license</div>
            <div class="table-cell" translate *ngIf="!isPublicPage">License number</div>
            <div class="table-cell" translate>Valid till</div>
            <div class="table-cell" translate *ngIf="!isPublicPage">Attachment</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let license of financeFormDetails?.licenses; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ (license?.licenceName | titlecase) || '-' }}</div>
              <div class="table-cell text-break" *ngIf="!isPublicPage">{{ license?.licenceNumber || '-' }}</div>
              <div class="table-cell text-break">{{ (license?.validityDate | date: "dd-MM-yyyy")  || '-' }}</div>
              <div class="table-cell text-break" *ngIf="!isPublicPage">
                <span *ngIf="license?.attachment?.length">
                  <img matTooltip="Click to full-view" class="chip-image cursor-pointer rounded-circle mb-2 mr-2"
                  width="20" height="20" [src]="license?.attachment | prefixDomain" (click)="openImageFullView(license?.attachment)">
                  <!-- <a href="{{'https://kalgudidata.s3.amazonaws.com'+license?.attachment}}"
                    download="{{'https://kalgudidata.s3.amazonaws.com'+license?.attachment}}" target="_self"
                    class="ml-3">
                    <i class="fa fa-download" ></i>
                  </a> -->
                </span>
                <span *ngIf="!license?.attachment?.length" >-</span>
              </div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ License values -->
    </div>
    <!--/ License details -->

    <!-- Assets details -->
    <div class="mb-2 mr-2" *ngIf="financeFormDetails?.assetDetails?.length">
      <!-- Header asset details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Asset details</h6>
      </div>
      <!--/ Header asset details -->

      <!-- Asset values -->
      <div class="asset-values-table finance-table-value rounded overflow-auto" [ngClass]="{'asset-public-view' : isPublicPage}">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Type</div>
            <div class="table-cell" translate>Quantity</div>
            <div class="table-cell" translate>Status</div>
            <div class="table-cell" translate *ngIf="!isPublicPage">Value(₹)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let asset of financeFormDetails?.assetDetails; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ asset.assetType !== "Other" ? asset.assetType : asset.otherDetails  || '-' }}</div>
              <div class="table-cell text-break">{{ asset?.quantity || '-' }}</div>
              <div class="table-cell text-break">{{ (asset?.assetStatus | titlecase) || '-' }}</div>
              <div class="table-cell text-break" *ngIf="!isPublicPage">{{ convertIndianRupeeFormat(asset?.assetValue) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Asset values -->
    </div>
    <!--/ Assets details -->

    <!-- Insurance details -->
    <div class="details-list" fxLayout="column" *ngIf="financeFormDetails?.insurance?.cropInsFarmerCount || financeFormDetails?.insurance?.lifeInsFarmerCount || financeFormDetails?.insurance?.annadhathaSurakshaFarmerCount">
      <div class="mb-2" fxLayout="row" fxLayoutAlign="start">
        <span class="font-weight-bold" translate>Insurance</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>Crop farmers count</span>
        <span class="financial-details" fxFlex="calc(55% - 6px)">
          {{  financeFormDetails?.insurance?.cropInsFarmerCount || 0 }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>Life farmers count</span>
        <span class="financial-details" fxFlex="calc(55% - 6px)">
          {{  financeFormDetails?.insurance?.lifeInsFarmerCount || 0 }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>Annadhatha suraksha farmers count</span>
        <span class="financial-details" fxFlex="calc(55% - 6px)">
          {{  financeFormDetails?.insurance?.annadhathaSurakshaFarmerCount || 0 }}</span>
      </div>
    </div>
    <!--/ Insurance details -->

  </div>
  <!--/ Finance details list -->

  <!-- No content if there no finance details details -->
  <ng-container *ngIf="!(financeFormDetails?.bankDetails?.length || financeFormDetails?.creditHistory?.length || financeFormDetails?.licenses?.length || financeFormDetails?.assetDetails?.length || financeFormDetails?.insurance?.cropInsFarmerCount || financeFormDetails?.insurance?.lifeInsFarmerCount || financeFormDetails?.insurance?.annadhathaSurakshaFarmerCount)">
    <div class="w-100 no-content-finance" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? ('No finance details added yet!' | translate) : editable ? '' : ('No finance details added yet!' | translate)"
        [actionLink]="isPublicPage ? '' : editable ? ('Add finance' | translate) : ''"
         (action)="showFinanceDetailsDialog(false)">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no finance details details -->
</mat-card>
