import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import {
  ShareLocationRangeFilterComponent,
} from './components/share-location-range-filter/share-location-range-filter.component';
import {
  ShareTargetMembersFilterComponent,
} from './components/share-target-members-filter/share-target-members-filter.component';
import { ShareTargetMembersComponent } from './components/share-target-members/share-target-members.component';
import { KalgudiShareTargetMembersApiService } from './services/kalgudi-share-target-members-api.service';
import { KalgudiShareTargetMembersService } from './services/kalgudi-share-target-members.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ShareTargetMembersComponent,
    ShareTargetMembersFilterComponent,
    ShareLocationRangeFilterComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    // Material Modules
    MatIconModule,
    MatTooltipModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatButtonModule,
    ScrollingModule,

    FlexLayoutModule,
    TranslateModule,

    // Kalgudi libraries
    KalgudiSlideToggleModule
  ],
  exports: [
    ShareTargetMembersComponent,
    ShareTargetMembersFilterComponent
  ],
  providers: [
    KalgudiShareTargetMembersService,
    KalgudiShareTargetMembersApiService
  ]
})
export class ShareTargetMembersModule { }
