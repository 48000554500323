import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiProgramListService } from '../services';
import { KalgudiPageDetails } from '@kalgudi/types';
import { PageFilter, PageFilterTypes } from '../../../constants';

@Component({
  selector: 'kl-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramListComponent implements OnInit {

  @Input()
  pagesList: KalgudiPageDetails[];

  @Output()
  pageSelected = new EventEmitter<any>();

  @Output()
  showPageCloneDialog = new EventEmitter<string>();

  programType: any;

  programTypeForm: FormGroup;

  constructor(
  ) {

  }

  ngOnInit() {
  }


  /**
   * Emits an event to the parent to open selected program full view
   * @param pageId
   */
  onPageSelection(pageId: string): void {
    this.pageSelected.emit(pageId);
  }

  /**
   * Opens clone page dialog
   * @param pageId
   */
  openClonePageDialog(pageId: string) {
    this.showPageCloneDialog.emit(pageId);
  }

}
