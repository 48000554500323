import { Injector, Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiInputDialogConfig, UrlMetadata } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiDialogsService } from '../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { UrlMetaDataService } from './url-meta-data.service';

@Directive()
export abstract class KalgudiAttachUrlFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  dialogTitle: string = 'Attach url link';

  @Input()
  urlPlaceHolder: string = 'Enter or paste the url you want to share';

  urlForm: FormGroup;


  // Dependencies
  private fb: FormBuilder;
  private kalgudiDialogService: KalgudiDialogsService;
  private urlMetaData: UrlMetaDataService;
  private notification: KalgudiNotification;
  constructor(
    protected injector: Injector
  ) {

    super();

    // Manually inject dependencies
    this.fb                   = this.injector.get(FormBuilder);
    this.kalgudiDialogService = this.injector.get(KalgudiDialogsService);
    this.urlMetaData          = this.injector.get(UrlMetaDataService);
    this.notification = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.urlForm = this.newUrlForm;

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Creates a new url form array
   */
  private get newUrlForm(): FormGroup {
    return this.fb.group({

      url: [''],
      domain: [''],
      title: [''],
      image: [''],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Form control accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.urlForm.patchValue(obj);
    } else {
      this.urlForm.reset();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens picker dialog box to fetch the url
   */
  onAttachClicked(): void {

    // Input dialog UI configuration
    const { dialogDetails, dialogConfig } = this.getDialogConfiguration();

    // Open the url dialog
    this.showAttachUrlDialog(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Fetch url metadata and transform string url to url metadata type
        switchMap(url => this.fetchUrlMetadata(url)),

        // Inject url metadata to the url form
        map(metaData => this.injectUrlMetadataToUrlForm(metaData)),

      )
      .subscribe();
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * Prepares kalgudi default dialog configuration
   */
  protected getDialogConfiguration(): { dialogDetails: KalgudiInputDialogConfig, dialogConfig: MatDialogConfig } {

    // Input dialog UI configuration
    const dialogDetails: KalgudiInputDialogConfig = {
      title: this.dialogTitle,
      acceptButtonTitle: 'Attach Link',
      rejectButtonTitle: 'Cancel',
      multiLineInput: false,
      inputPlaceHolder: this.urlPlaceHolder,
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
    };

    return { dialogDetails, dialogConfig };
  }


  /**
   * Injects the url metadata to the url form. It also invalidates
   * the url form for any errors.
   *
   * @param urlMetaData Url metadata to inject to the url form
   *
   * @returns Updated url form.
   */
  private injectUrlMetadataToUrlForm(urlMetaData: UrlMetadata): FormGroup {

    this.urlForm.patchValue(urlMetaData);

    // Check for url form invalid errors
    this.urlForm.updateValueAndValidity();

    this.updateFormControl();

    return this.urlForm;
  }

  /**
   * Updates the url parent form control value with the latest url
   * object.
   */
  private updateFormControl(): void {

    this.onChange(this.urlForm.value);
    this.onTouched();
  }


  /**
   * Fetches url metadata from the API.
   */
  private fetchUrlMetadata(url: string): Observable<UrlMetadata> {

    this.notification.showSpinner();

    return this.urlMetaData.fetchMetaData(url)
      .pipe(
        tap(_ => this.notification.hideSpinner()),
      );
  }

  /**
   * Shows the picker dialog where we can pick the url
   */
  private showAttachUrlDialog(dialogDetails: KalgudiInputDialogConfig, dialogConfig: MatDialogConfig): Observable<string> {

    return this.kalgudiDialogService.showInput(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter accepted inputs and inputs having some data
        filter(r => r.accepted && r.data.text),

        // Return only input url string not complete dialog data
        map(r => r.data.text)
      );
  }

}
