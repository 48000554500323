import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable } from 'rxjs';

import { KalgudiStreamData } from '../typings';
import { KalgudiMatTableSearchStream } from './kalgudi-mat-table-search-stream';
import { PartialData } from '@kalgudi/types';


/**
 * Extension class for Material table. Use this class for searchable and filterable
 * material table.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiMatTableStream
 * @see KalgudiMatTableSearchStream
 */
@Directive()
export abstract class KalgudiMatTableFilteredSearchStream<T> extends KalgudiMatTableSearchStream<T> {

  filtersForm: FormGroup;

  constructor() {
    super();

    this.filtersForm = new FormGroup({});
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the current selected filters
   */
  get filters(): any {
    return this.filtersForm.value;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Fetches stream items from the Kalgudi API.
   */
  // protected abstract streamApi(offset: number, limit: number): Observable<KalgudiStreamData>;
  protected abstract streamApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    searchKeyword?: string,
    filters?: any,
    params?: PartialData
  ): Observable<KalgudiStreamData>;


  /**
   * Initializes the table stream. It initializes the material table with MatPaginator and
   * MatSort.
   *
   * Feed the references of `MatPaginator` and `MatSort` to the method in order to provide
   * pagination and sorting capability to the material table.
   *
   * @override
   */
  protected initTableStream(paginator?: MatPaginator, sort?: MatSort, allowMultiSelect = true, params?: PartialData): void {

    super.initTableStream(paginator, sort, allowMultiSelect, params);

    // Link the data source streamApi$ function reference.
    // streamApi$ function gets called on every page, sort value changes.
    // It overrides the base implementation
    this.dataSource.streamApi$ = (offset, limit, sortBy, sortDirection) => this.filterApi(offset, limit, sortBy, sortDirection);
  }

  /**
   * Calls the streamApi with the specified search keyword.
   */
  private filterApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
  ): Observable<KalgudiStreamData> {

    return this.streamApi(offset, limit, sortBy, sortDirection, this.searchKeyword, this.filters);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
