<div id="kl-survey-submission-form-dialog" fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden submission-form-dialog mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{dialogData?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="my-2 dialog-content overflow-auto" fxLayout="column" fxLayoutAlign="space-between stretch">

    <kl-survey-submission-form
      [surveyId]="dialogData?.data?.surveyId"
      [userProfileKey]="dialogData?.data?.userProfileKey"
      [disableActions]="true">
    </kl-survey-submission-form>

  </div>
</div>
