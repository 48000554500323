import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { AvailableDomains, DevAvailableDomains } from '@kalgudi/types';

@Component({
  selector: 'kl-promotional-share-multiple-products',
  templateUrl: './promotional-share-multiple-products.component.html',
  styleUrls: ['./promotional-share-multiple-products.component.scss']
})
export class PromotionalShareMultipleProductsComponent implements OnInit {

  @Input()
  productList;

  @Input()
  store = '';

  domain: string;

  defaultProfilePic = "/assets/svgs/farm-crop.svg";

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiCommonRouteConfig,
  ) {

    this.domain = this.env.coreDomain;

  }


  ngOnInit() {
  }

  /**
   * Navigating to product full view
   * @param storeType
   * @param productName
   * @param productId
   */
  productFullView(store: string, product): void {

    let productStore = '';

    if(this.env.domain === 'https://core.kalgudi.com') {
      productStore = store === " " ? this.env.farmerStoreDomain : store;
    }

    this.appRouting.toStoreProductView({store: this.store || productStore, product: product});
  }

}
