import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CompanyPageDetails, PageType } from '@kalgudi/types';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateCompany  extends KalgudiCreatePageType<CompanyPageDetails> {

  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  /**
   * Gets, the courses from Page type form
   */
  get vision(): AbstractControl {
    return this.pageTypeForm.get('vision');
  }

  /**
   * Gets, the founders from Page type form
   */
  get founders(): AbstractControl {
    return this.pageTypeForm.get('founders');
  }

  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      vision: ['', Validators.required],
      foundedYear: [''],
      founders: [''],
      noOfEmployees: [''],
      industry: ['']
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'COMPANY';
  }
}
