import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiPageDetails } from '@kalgudi/types';
import { filter, takeUntil } from 'rxjs/operators';

import { PageActions, PROGRAM_TABS } from '../../../constants';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { ProgramStateService } from '../../../services/program-state.service';
import { KalgudiProgramProfileHeader } from './program-header';


@Component({
  selector: 'kl-program-header',
  templateUrl: './program-header.component.html',
  styleUrls: ['./program-header.component.scss'],
  animations: kalgudiAnimations,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramHeaderComponent extends KalgudiProgramProfileHeader implements OnInit {

  @Output()getActivatedTabDetail = new EventEmitter<string>();

  defaultProfilePic = 'https://kalgudi.com/data/profilepics/M01mlud0PRFREG2019071020061802UNH001.png';

  programTabs = PROGRAM_TABS;
  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected pagesService: KalgudiPageService,
    private pageStateService: ProgramStateService,
    protected injector: Injector
  ) {

    super(
      notifications,
      pagesService,
      injector
    );

    this.pageStateService.action$
      .pipe(
        filter(action => action.type === PageActions.PROGRAM_MEMBERS_UPDATED),

        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.pageDetails.followersCount = res.payload
        }
      );
  }

  ngOnInit() { }

  /**
   * @param activity
   * Sending the value to the parent component
   */
  selectTab(activity: string) {
    this.getActivatedTabDetail.emit(`${this.pageDetails.pageId}/${activity}`);
  }


  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  protected onDestroyed(): void { }

  /**
   * On profile pic update
   */
  protected onProfilePicUpdate(res: KalgudiPageDetails): void { }

}
