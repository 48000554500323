<div class="farmer-crops">
  <!-- Lands header -->
  <div class="farmer-crops-header" fxLayout fxLayoutAlign="space-between center" *ngIf="profile?.farmerLandDetails?.lands && profile?.farmerLandDetails?.lands?.length">

    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/farm-land.svg"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 title" translate>Lands</p>
      </div>

    </div>

    <div *ngIf="editable">
      <button mat-button class="add-crop" (click)="showAddLandDetailsDialog()">
        <div class="button-title">
          <p class="m-0"><b translate>Add</b></p>
        </div>
      </button>
    </div>

  </div>
  <!-- /Lands header -->

  <div class="farmer-crops-list">
    <div *ngIf="profile?.farmerLandDetails?.lands && profile?.farmerLandDetails?.lands?.length">
      <kl-farmer-lands-list
        [pageId]="pageId"
        [isAssisted]="isAssisted"
        [assistedProfileKey]="assistedProfileKey">
      </kl-farmer-lands-list>
    </div>

    <div *ngIf="!profile?.farmerLandDetails?.lands || (profile?.farmerLandDetails?.lands && !profile?.farmerLandDetails?.lands?.length)">
      <kl-farmer-no-content
        [noContentText]="editable ? '' : 'No lands added yet!'"
        buttonTitle="Add land"
        [noContentHeight]="'40vh'"
        svgIcon="assets/svgs/farm-land.svg"
        [backgroundImageHeight]= "'95px'"
        backgroundImage="assets/images/farmer-add-land-bg.jpg"
        (action)="showAddLandDetailsDialog()">
      </kl-farmer-no-content>
    </div>
  </div>
</div>
