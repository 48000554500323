import { Component, Injector, OnInit } from '@angular/core';
import { fadeInAnimation } from '@kalgudi/core';
import { SMS_LOGS_STATUS } from '@kalgudi/types';

import { KalgudiLogsTableList } from '../../classes/kalgudi-logs-table-list';

@Component({
  selector: 'kl-sms-logs-list',
  templateUrl: './sms-logs-list.component.html',
  styleUrls: ['./sms-logs-list.component.scss'],
  animations: [fadeInAnimation]
})
export class SmsLogsListComponent extends KalgudiLogsTableList implements OnInit {

  smsLogsStatus = SMS_LOGS_STATUS;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initTableStream(this.matPaginator, this.matSort);
  }

  protected onDestroyed(): void { }

}
