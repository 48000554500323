import { Component, ElementRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment } from '@kalgudi/types';

@Component({
  selector: 'kl-images-list',
  templateUrl: './images-list.component.html',
  styleUrls: ['./images-list.component.scss']
})
export class ImagesListComponent implements OnInit {

  @ViewChild('widgetsContent', { read: ElementRef, static: true }) public widgetsContent: ElementRef<any>;

  @Input()
  images: Attachment[];
  maxLength: number;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) { }

  ngOnInit() {

    this.maxLength = checkMobileDevice() ? 2 : 3;
  }

  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

  /**
   * Open image full view
  */
  openFullview(): void {
    this.kalgudiPhotoswipe.open(this.images, this.env.domain, 0).subscribe();
  }

}
