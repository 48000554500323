<!-- Section for content -->
<div id="upload-image" class="text-center mt-2" fxLayout="row" fxLayout fxLayoutGap="10px" fxLayoutGap.xs="0"
fxLayoutAlign.xs="start center">


<!-- Dummy container -->
<ng-container *ngIf="showDisabledFrames">
  <div class="wrapper-img d-inline-block mw-100 compact-img-wrapper disabled-wrapper"
    fxFlexOrder="3"
    *ngFor="let item of []?.constructor((maxImages - attachments.length - 1 >= 0) ? maxImages - attachments.length - 1 : 0)">
    <div  class="wrapper-add w-100" fxLayout="row" fxLayoutAlign="center center">
      <div class="add-btn cursor-pointer position-relative add-compact-btn disabled">
        <ng-container>

          <ng-container *ngIf="!showCamera">
            <button mat-mini-fab class="btn btn-primary attach_file" color="primary" class="outline-none" disabled>
              <mat-icon class="add-icon text-white attach_file disabled">attach_file</mat-icon>
            </button>

            <p class="mt-2" fxHide.xs translate>Click here to attach</p>
            <p class="mt-2" fxHide.gt-xs translate>Gallery</p>
          </ng-container>

          <div class="h-100" *ngIf="showCamera" fxLayout="column" fxLayoutAlign="center center">
            <div fxFlex="calc(50% - 1px)" class="h-100 attach_file disabled" fxLayout fxLayoutAlign="center center">
              <mat-icon class="add-icon img-icon" color="primary">attach_file</mat-icon>

              <p class="mt-2" fxHide.xs translate>Click here to attach</p>
              <p class="m-0" fxHide.gt-xs translate>Gallery</p>
            </div>

            <div class="border-bottom my-border" fxFlex="1px"></div>

            <div fxFlex="calc(50% - 1px)" class="h-100 camera disabled" fxLayout fxLayoutAlign="center center">
              <mat-icon class="add-icon img-icon" color="primary">local_see</mat-icon>

              <p class="mt-2" fxHide.xs translate>Click here to attach</p>
              <p class="m-0" fxHide.gt-xs translate>Camera</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<!-- / Dummy container -->


<!-- Add photo -->
<div class="wrapper-img d-inline-block mw-100 compact-img-wrapper" fxFlexOrder="2" *ngIf="maxImages > attachments?.length ">
  <div class="wrapper-add w-100"
    [ngClass]="{'w-100': !attachments?.length}" fxLayout="row"
    fxLayoutAlign="center center">

    <div class="add-btn cursor-pointer position-relative w-100 add-compact-btn" fxLayout="column" fxLayoutAlign="center center">

      <ng-container *ngIf="!inProgress">
        <input class="overflow-hidden position-absolute attach-img cursor-pointer w-100"
          [ngClass]="{'show-camera': showCamera}"
          type="file" [accept]="accept" (change)="onFileSelection($event)">

        <ng-container *ngIf="!showCamera">
          <button mat-mini-fab class="btn btn-primary" color="primary" class="outline-none">
            <mat-icon class="add-icon text-white">attach_file</mat-icon>
          </button>

          <p class="mt-2" fxHide.xs translate>Click here to attach</p>
          <p class="mt-2" fxHide.gt-xs translate>Gallery</p>
        </ng-container>

        <div class="h-100" *ngIf="showCamera" fxLayout="column" fxLayoutAlign="center center">
          <div fxFlex="calc(50% - 1px)" class="h-100 attach_file" fxLayout fxLayoutAlign="center center">
            <mat-icon class="add-icon img-icon" color="primary">attach_file</mat-icon>

            <p class="mt-2" fxHide.xs translate>Click here to attach</p>
            <p class="m-0" fxHide.gt-xs translate>Gallery</p>
          </div>

          <div class="border-bottom my-border" fxFlex="1px"></div>

          <div fxFlex="calc(50% - 1px)" class="h-100 camera" fxLayout fxLayoutAlign="center center" (click)="showCameraDialog()">
            <mat-icon class="add-icon img-icon" color="primary">local_see</mat-icon>

            <p class="mt-2" fxHide.xs translate>Click here to attach</p>
            <p class="m-0" fxHide.gt-xs translate>Camera</p>
          </div>
        </div>
      </ng-container>

      <div fxLayout class="w-100" fxLayoutAlign="center center" *ngIf="inProgress">
        <kl-no-content [progress]="inProgress" progressText="Uploading..." iconSize="sm">
        </kl-no-content>
      </div>
    </div>

  </div>
</div>
<!-- / Add photo -->

<!-- Wrapper for image section -->
<div class="wrapper-img position-relative d-inline-block compact-img-wrapper" *ngFor="let item of attachments; let i = index;" fxFlexOrder="1">

  <button color="warn" class="clr-btn positioning position-absolute text-center" mat-mini-fab (click)="remove(i)">
    <mat-icon class="remove-icon text-white">close</mat-icon>
  </button>

  <div class="border image-textarea position-relative compact-view-image">

    <!-- Image attachment -->
    <div *ngIf="item?.msgType === attachmentTypes.IMAGE"
      class="w-100 h-100 image-textarea"
      [style.background-image]="'url('+ (item?.url | prefixDomain) +')'"></div>

    <!-- Document attachment -->
    <div class="doc-attach" *ngIf="item?.msgType === attachmentTypes.DOCUMENT" fxLayout="row" fxLayoutAlign="center center">
      <a [href]="item?.url | prefixDomain" target="_blank" [title]="'View file' | translate"><i class="fas fa-file-alt"></i></a>
    </div>

    <!-- Audio attachment -->
    <!-- <div class="audio-attach" *ngIf="item?.msgType === attachmentTypes.AUDIO" fxLayout="row" fxLayoutAlign="center center">
      <video width="100%" height="80" controls>
        <source [src]="item?.url | prefixDomain" type="audio/mpeg"/>
      </video>
    </div> -->

    <div class="text position-absolute w-100">
      <textarea class="outline-none text-center p-0 w-100 align-middle" [placeholder]="'Description' | translate" row="2"
        [(ngModel)]="item.context"></textarea>
    </div>

  </div>

</div>

<!-- Wrapper for image section -->

</div>
<!-- /Section for content -->

