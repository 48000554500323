import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  BoardOfMember,
  FPOBankingDetails,
  FPOInfrastructure,
  FPOServices,
  FPOWarehouse,
  KalgudiUser,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Injectable()
export class OrganisationProfileApiService {

  /**
   * /v2/profiles/:profileKey/board-members
   */
  private readonly API_BOARD_MEMBERS = `${this.env.restBaseUrlV2}/profiles/:profileKey/board-members`;

  /**
   * /v2/profiles/:profileKey/fpo-infrastructure
   */
  private readonly API_FPO_INFRASTRUCTURE = `${this.env.restBaseUrlV2}/profiles/:profileKey/fpo-infrastructure`;

  /*
   * /v2/profiles/:profileKey/fpo-services
   */
  private readonly API_SERVICE_LICENSE = `${this.env.restBaseUrlV2}/profiles/:profileKey/fpo-services`;

  /**
   * /v2/profiles/:profileKey/fpo-warehouse
   */
  private readonly API_FPO_WAREHOUSE = `${this.env.restBaseUrlV2}/profiles/:profileKey/fpo-warehouse`;

  /**
   * /v2/profiles/:profileKey/fpo-banking
   */
  private readonly API_FPO_BANKING_DETAILS = `${this.env.restBaseUrlV2}/profiles/:profileKey/fpo-banking`;

  /**
   * v2/profiles/:profileKey/farmer-count
   */
  private readonly API_FARMERS_COUNT = `${this.env.restBaseUrlV2}/profiles/:profileKey/farmer-count`;

  /**
   * /v2/bms/profiles/create-update/:profileKey
   */
  private readonly API_BMS_PROFILES_CREATE_UPDATE = `${this.env.restBaseUrlV2}/profiles/profile-common-data`;

  /**
   * /profiles/:profileKey/crops
   */
  private readonly API_CROP_VARIETY = `${this.env.restBaseUrlV2}/profiles/:profileKey/crops`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private profileService: KalgudiProfileService,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Calls api to add board of members
   */
  postBoardOfMembers(profileKey: string, payload: BoardOfMember[], extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_BOARD_MEMBERS.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to add board of members in profile object type
        map(res => res.data),

        switchMap(res => this.profileService.updateProfileAndState(profileKey))
      );
  }

  /**
   * Calls api to update fpo infrastructure
   */
  updateFpoInfrastructure(profileKey: string, payload: FPOInfrastructure, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_FPO_INFRASTRUCTURE.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to update fpo infrastructure details
        map(res => res.data),

        switchMap(res => this.profileService.updateProfileAndState(profileKey))
      );
  }


  /**
   * Calls api to add service license
   */
  postServiceLicense(profileKey: string, payload: FPOServices[], extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_SERVICE_LICENSE.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to adding service license
        map(res => res.data),

        switchMap(res => this.profileService.updateProfileAndState(profileKey))
      );
  }

  /**
   * Calls api to update warehouse
   */
  postWarehouse(profileKey: string, payload: FPOWarehouse, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_FPO_WAREHOUSE.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to update ware house in profile object type
        map(res => res.data),

        switchMap(res => this.profileService.updateProfileAndState(profileKey))
      );
  }

  /**
   * Calls api to update banking details
   */
  updateBankingDetails(profileKey: string, payload: FPOBankingDetails, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_FPO_BANKING_DETAILS.replace(':profileKey', profileKey), payload, { params } )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to update ware house in profile object type
        map(res => res.data),

        switchMap(res => this.profileService.updateProfileAndState(profileKey))
      )
  }

  /**
   * Calls api to fetch farmers count
   */
  fetchFarmerCount(profileKey): Observable<any> {
    return this.httpClient.get<ApiResponseCommon>(this.API_FARMERS_COUNT.replace(':profileKey', profileKey))
    .pipe(
      map(res => this.util.apiErrorHandler(res)),

      // Map API response to fetch farmer count
      map(res => res.data),
    )
  }

  /**
   * Update or create profile details
   */
  createFpoProfilesDetails(payload: any): Observable<any> {

    return this.httpClient.put<ApiResponseCommon>( this.API_BMS_PROFILES_CREATE_UPDATE, payload)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Get crops and variety list
   */
  getAllCropsVarieties(profileKey: string): Observable<any> {

    const url = this.API_CROP_VARIETY.replace(':profileKey', profileKey);
    const params: any = {
        pageId: '',
        assistedTo: profileKey
    }

    return this.httpClient.get<ApiResponseCommon>(url, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res),
      );
  }

}

