import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LazyHttpClientInterceptor } from '../model';

@Injectable()
export class NoopInterceptorService implements LazyHttpClientInterceptor {

  constructor() { }

  canCacheRequest(res?: any, err?: HttpErrorResponse): boolean {
    return false;
  }
}
