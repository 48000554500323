import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { DEFAULT_PROFILE_PIC, KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { UIMenu } from '@kalgudi/types';

import { KalgudiSocialRouteConfig } from '../../config';

@Component({
  selector: 'kl-social-comments-tile',
  templateUrl: './social-comments-tile.component.html',
  styleUrls: ['./social-comments-tile.component.scss']
})
export class SocialCommentsTileComponent implements OnInit {

  @Input()
  comment;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  isLoggedIn: boolean;

  listMenu = {
    title: '',
    styleType: 'list',
    menu: [
      {
        title: 'Report abuse',
        id: 'REPORT_ABUSE'
      },
      {
        title: 'Edit',
        id: 'EDIT',
        // hide: this.userProfileKey !== this.authorId && this.memberRole !== 'ADMIN',
      },
      {
        title: 'Delete',
        id: 'DELETE',
        // hide: this.userProfileKey !== this.authorId && this.memberRole !== 'ADMIN',
      },
    ]
  };

  constructor (
    private bottomSheetService: KalgudiBottomSheetService,
    private kalgudiApp: KalgudiAppService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig
  ) {
    this.domain = this.environment.coreDomain;
    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey});
  }

  /**
   * Open bottom sheet for mobile view
   */
  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if(!menu) {
      return;
    }

    switch (menu.id) {

      case 'REPORT_ABUSE':
        // this.reportAbuse();
        break;

      case 'EDIT':
        // this.editComment();
        break;

      case 'DELETE':
        // this.deleteUpdate();
        break;
    }
  }

}
