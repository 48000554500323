import { Injectable } from '@angular/core';
import { KalgudiAppService, KalgudiLanguageServiceService } from '@kalgudi/core';
import {
  GoogleSupportedLanguagesList,
  LanguageIdList,
  LanguageIdMap,
} from '@kalgudi/third-party/google-language-translator';
import { KalgudiUser } from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class KalgudiTranslationListService {

  constructor(
    private kalgudiApp: KalgudiAppService,
    private languageIdService: KalgudiLanguageServiceService,
  ) { }


  /**
   * Initializes google language translator options
   */
  initGoogleLangTranslator(postLanguageCode: string = ''): Observable<LanguageIdList> {

    const languageListToShow: LanguageIdList = [];

    const profile = this.kalgudiApp.profileLocal;


    if (!profile) {
      return of(languageListToShow);
    }

    return this.languageIdService.getLanguageId(profile.languageName)
      .pipe(
        first(),

        map(localLanguageId => {

          // English language google translation object
          const englishLanguage: LanguageIdMap = {
            id: 'en',
            title: 'English'
          };

          // Local language google translation object
          const localLanguage: LanguageIdMap = {
            id: localLanguageId as GoogleSupportedLanguagesList,
            title: profile.languageLocalName
          };

          // If share update has not been shared in english then attach the english language
          if (this.isNotSharedInEnglish(postLanguageCode)) {
            languageListToShow.push(englishLanguage);
          }

          // Show local language if the share update has not been shared in the local language
          if (!this.isSharedInLocalLang(postLanguageCode, profile)) {
            languageListToShow.push(localLanguage);
          }

          // Update the global variable to show languages list
          return languageListToShow;
        })
      );
  }

  /**
   * Returns `true` if the share update has been shared in english or
   * has the `googleLocationShortCodes` as english language otherwise `false`.
   */
  private isNotSharedInEnglish(languageCode: string): boolean {

    return (languageCode
      && (languageCode as GoogleSupportedLanguagesList) !== 'en')    // Shared location language is english
      && languageCode !== 'und';     // Shared location language is undefined
  }

  /**
   * Returns `true` if the share update has been shared in the local language
   * of the logged in user. Otherwise returns `false`.
   */
  private isSharedInLocalLang(languageCode: string, profile: KalgudiUser): boolean {
    // return languageCode && languageCode === profile.languageId;

    const languageName = profile.languageName.toLowerCase();

    return (languageCode && (languageCode as GoogleSupportedLanguagesList) === languageName.substring(0,2));

  }
}
