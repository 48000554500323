import { Component, Injector, OnInit } from '@angular/core';
import { FpoProfiles } from '../../../classes/fpo-profiles';
import { AbstractControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { startWith, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { OrganisationProfileService } from '../../../services/organisation-profile.service';
import { OrganisationProfileApiService } from '../../../services/organisation-profile-api.service';

@Component({
  selector: 'kl-crop-form',
  templateUrl: './crop-form.component.html',
  styleUrls: ['./crop-form.component.scss']
})
export class CropFormComponent extends FpoProfiles implements OnInit {

  // Financial years id's and values
  financialYears: any[] = [
    { id: '2022-2023', value: '2022-2023' },
    { id: '2021-2022', value: '2021-2022' },
    { id: '2020-2021', value: '2020-2021' },
    { id: '2019-2020', value: '2019-2020' },
    { id: '2018-2019', value: '2018-2019' }
  ];

  isEditCurrentYearCropDetails: boolean;
  currentYearCropDetailsIndex: number;

  isEditPreviousYearCropDetails: boolean;
  previousYearCropDetailsIndex: number;

  isEditCropWiseProcurementDetails: boolean;
  cropWiseProcurementDetailsIndex: number;
  cropAndVarieties: any[];
  cropDetails: any[];
  currentCropFilteredOptions$: Observable<any[]>;
  procurementCropFilteredOptions$: Observable<any[]>;
  isCropNameMatched: boolean = true;
  isCropWiseNameMatched: boolean = true;
  cropNames: any[];
  cropWiseNames: any[];

  constructor(
    protected injector: Injector,
    private organisationProfileApiService: OrganisationProfileApiService,
    private organizationProfileService: OrganisationProfileService
  ) {
    super(injector)
  }

  ngOnInit(): void {
    this.getCropAndVarietyDetails();
  }

  onDestroyed(): void { }

  /**
   * Add current year crop details to array when click happens
   */
  addCurrentYearCropDetails() {
    if (!this.isEditCurrentYearCropDetails) {
      this.currentYearCropDetails.push(this.currentYearCropDetailsGroup.value);
    }
    else {
      this.currentYearCropDetails.splice(this.currentYearCropDetailsIndex, 1 , this.currentYearCropDetailsGroup.value)
    }
    // Sorting the details based on financial year.
    this.currentYearCropDetails = this.sortFinancialYearsByYear(this.currentYearCropDetails, 'year');
    this.isEditCurrentYearCropDetails = false;
    this.currentYearCropDetailsGroup.reset();
  }

  /**
   * Add crop wise procurement details to array when click happens
   */
  cropwiseProcurement() {
    this.cropWiseProcurementGroup.value.noOfFarmers = parseInt(this.cropWiseProcurementGroup.value.noOfFarmers);
    if (!this.isEditCropWiseProcurementDetails) {
      this.cropwiseProcurementDetails.push(this.cropWiseProcurementGroup.value);
    }
    else {
      this.cropwiseProcurementDetails.splice(this.cropWiseProcurementDetailsIndex, 1 , this.cropWiseProcurementGroup.value)
    }
    // Sorting the array based on financial year
    this.cropwiseProcurementDetails = this.sortFinancialYearsByYear(this.cropwiseProcurementDetails, 'year');
    this.isEditCropWiseProcurementDetails = false;
    this.cropWiseProcurementGroup.reset();
  }

  /**
   * Removes an item from the array
   * @param index Index of current year crop details to remove
   */
  removeCurrentYearCropDetails(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this current year crop details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.currentYearCropDetails.splice(id, 1);
        this.currentYearCropDetailsArray.value.splice(id, 1);
        this.currentYearCropDetailsGroup.reset();
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of crop wise procurement details to remove
   */
  removeCropwiseProcurementDetails(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this cropwise procurement details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.cropwiseProcurementDetails.splice(id, 1);
        this.cropwiseProcurementArray.value.splice(id, 1);
        this.cropWiseProcurementGroup.reset();
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of current year crop details to remove
   */
  editCurrentYearCropDetails(index: any) {
    this.isEditCurrentYearCropDetails = true;
    this.currentYearCropDetailsIndex = index;
    this.currentYearCropDetailsGroup.patchValue(this.currentYearCropDetails[index]);
  }

  /**
   * Removes an item from the array
   * @param index Index of crop wise procurement details to remove
   */
  editCropWiseProcurementDetails(index: any) {
    this.isEditCropWiseProcurementDetails = true;
    this.cropWiseProcurementDetailsIndex = index;
    this.cropWiseProcurementGroup.patchValue(this.cropwiseProcurementDetails[index])
  }

  /**
   * Getter for current financial year id
   */
  get getCurrentFinancialYearId(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('year');
  }

  /**
   * Getter for season
   */
  get getSeason(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('season');
  }

  /**
   * Getter for crop
   */
  get getCrop(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('cropName');
  }

  /**
   * Getter for variety
   */
   get getVariety(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('varietyName');
  }

  /**
   * Getter for cultivation area
   */
  get getCultivationArea(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('cultivationArea');
  }

  /**
   * Getter for outputqty
   */
  get getOutputQty(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('outputQty');
  }

  /**
   * Getter for outputqtyunit
   */
   get getOutputQtyUnit(): AbstractControl {
    return this.currentYearCropDetailsGroup.get('outputQtyUnit');
  }

  /**
   * Getter for crop wise financial year id
   */
  get getCropWiseFinancialYearId(): AbstractControl {
    return this.cropWiseProcurementGroup.get('year');
  }

  /**
   * Getter for noOfFarmers
   */
  get getNoOfFarmers(): AbstractControl {
    return this.cropWiseProcurementGroup.get('noOfFarmers');
  }

  /**
   * Getter for crop
   */
  get getCropWise(): AbstractControl {
    return this.cropWiseProcurementGroup.get('crop');
  }

  /**
   * Getter for totalQty
   */
  get getTotalQty(): AbstractControl {
    return this.cropWiseProcurementGroup.get('totalQty');
  }

  /**
   * API to get crop details
   */
  getCropAndVarietyDetails() {
    this.organisationProfileApiService.getAllCropsVarieties(this.profileKey).subscribe(res =>{
      if (res.data) {
        this.cropAndVarieties = res.data;
        this.filterCropDetails(this.cropAndVarieties);
        this.getFilteredCrops();
        this.getFilteredProcurementCrops();
      }
    });
  }

  /**
   * Checks whether user input matches crop name or not in crop name field
   */
  cropNameMatched() {
    this.getCrop.valueChanges.subscribe(val => {
      this.isCropNameMatched = this.organizationProfileService.checkingItemInList(val, this.cropNames);
      if (this.cropDetails.length) {
        this.cropDetails.map(res => {
          if (res.cropValue === val) {
            this.currentYearCropDetailsGroup.get('cropId').patchValue(res.cropId);
          }
        });
      }
    });
  }

  /**
   * Checks whether user input matches crop name or not in crop name field
   */
  cropWiseNameMatched() {
    this.getCropWise.valueChanges.subscribe(val => {
      this.isCropWiseNameMatched = this.organizationProfileService.checkingItemInList(val, this.cropWiseNames);
    });
  }


  /**
   * Mapping crop details from the api response
   */
  filterCropDetails(crops: any) {
    let crop = [];
    let count = 0;
    crops.map(res => {
      if (res.baseProductName) {
        crop.push({cropId: res.baseProductId, cropValue: res.baseProductName});
      }
      count++;
    });
    let cropDetails = Array.from(new Map(crop.map(item => [item.cropId, item])).values());
    this.cropDetails = cropDetails;
  }

  /**
   * Patching crop ID based on crop selection
   */
  selectedCrop(event?: any) {
    if (this.cropDetails) {
      this.cropDetails.map(res => {
        if (res.cropValue === event.option.value) {
          this.currentYearCropDetailsGroup.get('cropId').patchValue(res.cropId);
        }
      });
    }
  }

  /**
   * Patching crop ID based on procurement crop selection
   */
  selectedProcurementCrop(event: any) {
    // this.cropDetails.forEach(res => {
    //   if (res.cropValue === event.option.value) {
    //     this.cropWiseProcurementGroup.get('cropId').patchValue(res.cropId);
    //   }
    // })
  }

  /**
   * To display current crops
   */
  displayCurrentCrops(value: any): any {
    return value && value.cropValue ? value.cropValue : value;
  }



  /**
   * To display procurement crops
   */
  displayProcurementCrops(value: any): any {
    return value && value.cropValue ? value.cropValue : value;
  }

  selectLocation(event: any) {}

  /**
   * get all filtered locations
   */
  getFilteredCrops() {
    this.currentCropFilteredOptions$ = this.getCrop.valueChanges.pipe(
      // Default filter
      startWith(''),

      // Trim extra spaces
      map((value) => value ? (typeof value === "string" ? value : value.cropValue) : ""),

      // Don't search until the search keyword changes
      distinctUntilChanged(),

      // Gap between each keystroke
      debounceTime(100),

      // Filter items based on the search keyword
      map((searchKeyword) => searchKeyword ? this._currentCropFilter(searchKeyword) : this.cropDetails),

      // tap(r => console.log(r)),
    );

  }

  /**
   * get all filtered locations
   */
  getFilteredProcurementCrops() {
    this.procurementCropFilteredOptions$ = this.getCropWise.valueChanges.pipe(
      // Default filter
      startWith(''),

      // Trim extra spaces
      map((value) => value ? (typeof value === "string" ? value : value.cropValue) : ""),

      // Don't search until the search keyword changes
      distinctUntilChanged(),

      // Gap between each keystroke
      debounceTime(100),

      // Filter items based on the search keyword
      map((searchKeyword) => searchKeyword ? this._procurementCropsFilter(searchKeyword) : this.cropDetails),

      // tap(r => console.log(r)),
    );

  }

  /**
   * Filter current crops based on keyword
   */
  private _currentCropFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.cropNames = this.cropDetails.map(option => option?.cropValue);
    this.cropNameMatched();
    return this.cropDetails.filter((option) => option?.cropValue.toLowerCase().includes(filterValue));
  }

  /**
   * Filter current crops based on keyword
   */
  private _procurementCropsFilter(value: string): string[] {
    const filterValue = value.toLowerCase();
    this.cropWiseNames = this.cropDetails.map(option => option?.cropValue);
    this.cropWiseNameMatched();
    return this.cropDetails.filter((option) => option?.cropValue.toLowerCase().includes(filterValue));
  }
}
