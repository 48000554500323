import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Provider, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kalgudiAnimations, KalgudiDestroyable } from '@kalgudi/core';
import { SelectableUIMenu } from '@kalgudi/types';

const SELECT_CARD_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectCardComponent),
  multi: true,
};

@Component({
  selector: 'kl-select-card',
  templateUrl: './select-card.component.html',
  styleUrls: ['./select-card.component.scss'],
  animations: kalgudiAnimations,
  encapsulation: ViewEncapsulation.None,
  providers: [ SELECT_CARD_CONTROL_PROVIDER ]
})
export class SelectCardComponent extends KalgudiDestroyable implements ControlValueAccessor, OnInit {

  @Input()
  layout: 'row' | 'column' = 'row';

  @Input()
  selectedValue: string;

  @Output()
  selectedValueChange = new EventEmitter<string>();

  @Input()
  options: SelectableUIMenu[] = [];

  @Input()
  disableSelection: boolean;

  disabled = false;

  constructor() {

    super();
  }

  ngOnInit() { }

  onDestroyed(): void { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.selectedValue = obj;
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Calls on page type selection
   */
  selectOption(index: number) {

    if (this.disableSelection) {

      return;
    }
    // Get the selected item id
    this.selectedValue = this.options[index].id;

    // Emit the selected item id from the component
    this.selectedValueChange.emit(this.selectedValue);

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(this.selectedValue);
    this.onTouched();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
