import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

import { LAZY_API_SYNC_INTERCEPTOR } from './config';
import { LocalHttpRequestSync } from './local-http-request-sync';
import { LazyApiSyncInterceptor, LazyHttpRequest, LazyRequest, LazyRequestList } from './model';

@Injectable()
export class LazyApiSync {

  constructor(
    @Inject(LAZY_API_SYNC_INTERCEPTOR) private lazyApiSync: LazyApiSyncInterceptor,
    private httpClient: HttpClient,
    private localRequests: LocalHttpRequestSync,
  ) {

    this.lazyApiSync.resumeSync$
      .pipe(
        switchMap(_ => this.syncAllRequests())
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, all un-synced requests stored in local storage.
   */
  private get unSyncedRequests(): LazyRequestList {
    return this.localRequests.getRequestList()
      .filter(r => r.request.status === false);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  syncAll(): Observable<any> {
    return EMPTY;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Sync all request with the API
   */
  private syncAllRequests(): Observable<any> {

    const requests = this.unSyncedRequests.map((req, index) => this.syncRequest(req, 2000 * index));

    // console.log("LazyApiSync -> syncAllRequests -> requests", requests);

    return forkJoin(
      ...requests
    );
  }

  /**
   * Syncs a request with the API. It syncs the records to the api with some fixed delay.
   * Once the record is successfully synced it removes the record from the local storage.
   */
  private syncRequest({ request, moduleId, requestId }: LazyHttpRequest, delayInMs = 2000): Observable<any> {

    // Get the http request to make
    const httpRequest = this.getHttpMethodToCall(request);

    return of(1)
      .pipe(
        // Put some delay before hitting the service
        // Delay defined by the parent
        delay(delayInMs),

        tap(_ => console.log('Syncing request to -> ', requestId, delayInMs)),

        // Call the API
        switchMap(_ => httpRequest),

        tap(_ => console.log('Synced request to -> ', requestId, delayInMs)),

        tap(_ => console.log('Removing request to -> ', requestId, delayInMs)),

        // Remove the request from local storage
        switchMap(_ => this.localRequests.removeRequest(requestId, moduleId))
      );
  }

  /**
   * Gets, the http request call observable.
   */
  private getHttpMethodToCall(lazyRequest: LazyRequest): Observable<any> {

    let request: Observable<any> = of({});

    if (lazyRequest.method === 'PUT') {
      request = this.httpClient.put(lazyRequest.url, lazyRequest.body, lazyRequest.options);
    } else if (lazyRequest.method === 'POST') {
      request = this.httpClient.post(lazyRequest.url, lazyRequest.body, lazyRequest.options);
    } else if (lazyRequest.method === 'DELETE') {
      request = this.httpClient.delete(lazyRequest.url, lazyRequest.options);
    }

    return request;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
