import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { takeUntil } from 'rxjs/operators';

import { SatelliteAdvisoriesAllUsersService } from '../services/satellite-advisories-all-users.service';

@Directive()
export abstract class KalgudiSatelliteAdvisories extends KalgudiDestroyable {

  @Input()
  pageId: string;

  @Input()
  syncId: string;

  @Input()
  profileKey: string;

  @Input()
  landId: string;

  soilMoisture: any;
  landDetails: any;
  nitrogen: any;
  phosphorus: any;
  plantHealth: any;

  satelliteRes: any;

  private satelliteService: SatelliteAdvisoriesAllUsersService;

  constructor(protected injector: Injector) {
    super();

    this.satelliteService = this.injector.get(SatelliteAdvisoriesAllUsersService);
  }

  /**
   * Gets satellite advisories
   */
  getSatelliteAdvisories() {
    this.satelliteService.getSatellite(this.pageId, this.syncId, this.profileKey, this.landId)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.satelliteRes = res;
          this.soilMoisture = res.soilMoisture;
          this.landDetails = res.landDetails;
          this.nitrogen = res.nitrogen;
          this.phosphorus = res.phosphorous;
          this.plantHealth = res.plantHealth;
        }
      );
  }
}
