import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiCreditSettings } from '../../classes/kalgudi-credit-settings';

@Component({
  selector: 'kl-credit-settings',
  templateUrl: './credit-settings.component.html',
  styleUrls: ['./credit-settings.component.scss']
})
export class CreditSettingsComponent extends KalgudiCreditSettings implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(
      injector
    );
   }

  ngOnInit() {
    this.fetchCreditDetails();
  }

  /**
   * Event handler for SMS toggle
   */
  onSmsToggle(val: boolean): void {
    this.isSettingsChange = true;
    this.smsSettings.domesticSms = val;
  }

  /**
   * Event handler for International SMS toggle
   */
  onInternationalSmsToggle(val: boolean): void {
    this.isSettingsChange = true;
    this.smsSettings.internationalSms = val;
  }

  protected onDestroyed(): void {}

}
