<div class="mt-n3 p-2" id="add-products">

  <div *ngIf="showBaseProductSearchField">
    <!-- Search product  -->
    <div *ngIf="showProductFields">
      <mat-form-field appearance="outline" class="w-100">
        <input #autocompleteInput type="text" #searchInput matInput [formControl]="baseProduct" [placeholder]="'Select product' | translate" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" class="product-list" (optionSelected)="onProductSelection($event.option.value)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            <div fxLayout fxLayoutAlign="start center" class="mw-100">

              <div class="products">
                <span class="d-block text-truncate">{{ option?.VProductName }}</span>
                <span class="d-block text-muted text-truncate also-known" *ngIf="option?.aka">Also known as {{ option?.aka }}</span>
              </div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <!-- / Search product  -->

    <!-- Search other product  -->
    <div class="product-field" *ngIf="!showProductFields">
      <!-- Product name  -->
      <div fxLayout="row" class="w-100">
        <kl-product-autocomplete class="w-100"
          [formControl]="productAutoComplete"
          [placeholder]="'Search and select product' | translate"
          [storeType]="'OUTPUTS_STORE'"
          [label]="'Search and select product' | translate">
        </kl-product-autocomplete>
      </div>
      <!--/ Product name  -->
    </div>
    <!-- / Search other product  -->

    <!-- No product  -->
    <div *ngIf="showProductFields">
      <span class="no-seller text-secondary" translate>
        Didn't find product in the above list?
        <a (click)="showProductForm()" class="text-primary" translate>
          Click here to add
        </a>
      </span>
    </div>
    <!-- No product  -->

    <div *ngIf="!showProductFields">
      <span class="no-seller">
        <a (click)="showProductForm()" class="text-primary" translate>
          Click here to select from existing list
        </a>
      </span>
    </div>
  </div>

  <div fxLayout="column" *ngFor="let crop of crops; let i = index">

    <div fxLayout="row" fxLayoutAlign="space-between">
      <span class="title mt-1" [ngClass]="{'mt-3': i !== 0}">{{crop?.title | trimSpecialCharacters | titlecase}}</span>
      <div class="mt-1 mr-2 bg-white p-2 border" *ngIf="i === 0 && !hideSearch" (click)="searchNewCrop()">
        <span class="mr-2 search-title" translate>Didn't find the <span>{{ (env.appId === 'TRADERS_APP' ? 'commodity' : 'crop' | translate) }}</span> <span translate>, search here</span></span>
        <!-- <i class="fa fa-search" aria-hidden="true"></i> -->
        <svg-icon src="assets/svgs/search.svg" [svgStyle]="{'width.px': '16', 'height.px': '16'}" [applyCss]="true"
            class="store-products-icon"></svg-icon>
      </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="3px">
      <mat-card fxFlex="24%" class="cereals-pulses p-1 mt-1" *ngFor="let cereal of (!hideSearch ? (crop?.items | slice:0:7) : crop?.items); let j = index" [ngClass]="{'selected-tab': cereal?.selected}" (click)="editCrop(cereal, !cereal?.selected)">
        <div class="mt-1" fxLayout="column" fxLayoutAlign="center center">
          <img *ngIf=" cereal?.attachments?.length && cereal?.attachments[0]?.attachments?.length && cereal?.attachments[0]?.attachments[0]?.url" [src]="cereal?.attachments[0]?.attachments[0]?.url | prefixDomain" class="crop-image">
          <img *ngIf=" !cereal?.attachments || !cereal?.attachments?.length || !cereal?.attachments[0]?.attachments || !cereal?.attachments[0]?.attachments?.length || !cereal?.attachments[0]?.attachments[0]?.url" src="assets/images/default-crop-image.png" class="crop-image">

          <span class="text-secondary product-name text-truncate mw-100">{{cereal?.VProductName}}</span>
          <span  *ngIf="cereal?.aka" class="text-secondary product-name text-truncate mw-100">({{ cereal?.aka }})</span>
        </div>

        <div class="selected-check position-absolute" *ngIf="cereal?.selected">
          <mat-icon>check_circle_outline</mat-icon>
        </div>
      </mat-card>

      <mat-card fxFlex="24%" class="cereals-pulses p-1 mt-1" *ngIf="!hideSearch && crop?.items?.length > 7" (click)="searchNewCrop(crop?.items[0]?.baseCategory)"
        [ngClass]="{'d-none': isShowDetails === true}">
        <div class="mt-2" fxLayout="column" fxLayoutAlign="center center">
          <i class="fa fa-search search-icon" aria-hidden="true"></i>
          <span class="text-secondary product-name">Search</span>
        </div>
      </mat-card>
    </div>
  </div>

</div>

<ng-container *ngIf="progress || (!crops || (crops && !crops?.length))">
  <kl-no-content
    iconSize="md"
    [progressText]="'Loading ' + env.appId === 'TRADERS_APP' ? 'commodities' : 'crops' + '...'"
    text="No top base products found"
    actionLink="Add product"
    (action)="openAddProductDialog()"
    [progress]="progress">
  </kl-no-content>
</ng-container>
