import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPageTargetMembersModule } from '@kalgudi/pages-shared';
import {
  KalgudiSurveyAdminsModule,
  KalgudiSurveyMembersModule,
  KalgudiSurveysListModule,
  KalgudiSurveysModule,
  KalgudiSurveysQuestionsModule,
  KalgudiSurveySubmissionModule,
} from '@kalgudi/surveys';
import { TranslateModule } from '@ngx-translate/core';

import {
  KalgudiProgramMembersPickerDialogModule,
} from '../program-members-picker-dialog/program-members-picker-dialog.module';
import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import { PageSurveyAdminsStreamComponent } from './components/page-survey-admins-stream/page-survey-admins-stream.component';
import { PageSurveyFillComponent } from './components/page-survey-fill/page-survey-fill.component';
import {
  PageSurveyManageMembersComponent,
} from './components/page-survey-manage-members/page-survey-manage-members.component';
import {
  PageSurveyMemberAddDialogComponent,
} from './components/page-survey-member-add-dialog/page-survey-member-add-dialog.component';
import {
  PageSurveyMemberAddMobileDialogComponent,
} from './components/page-survey-member-add-mobile-dialog/page-survey-member-add-mobile-dialog.component';
import { PageSurveyMemberAddComponent } from './components/page-survey-member-add/page-survey-member-add.component';
import { PageSurveysListComponent } from './components/page-surveys-list/page-surveys-list.component';
import {
  PageSurveysQuestionsStreamComponent,
} from './components/page-surveys-questions-stream/page-surveys-questions-stream.component';
import {MatRadioModule} from '@angular/material/radio';



@NgModule({
  declarations: [
    PageSurveysListComponent,
    PageSurveysQuestionsStreamComponent,
    PageSurveyAdminsStreamComponent,
    PageSurveyManageMembersComponent,
    PageSurveyMemberAddComponent,
    PageSurveyMemberAddDialogComponent,
    PageSurveyMemberAddMobileDialogComponent,
    PageSurveyFillComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatRadioModule,

    FlexLayoutModule,

    // Kalgudi surveys libraries
    KalgudiSurveysModule,
    KalgudiSurveysListModule,
    KalgudiSurveysQuestionsModule,
    KalgudiSurveyAdminsModule,
    KalgudiSurveyMembersModule,
    KalgudiSurveySubmissionModule,

    // Kalgudi other libraries
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiUsersModule,
    KalgudiButtonModule,
    KalgudiProgramTargetMembersModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule,
    KalgudiPageTargetMembersModule,

    TranslateModule
  ],
  exports: [
    PageSurveysListComponent,
    PageSurveysQuestionsStreamComponent,
    PageSurveyAdminsStreamComponent,
    PageSurveyManageMembersComponent,
    PageSurveyMemberAddComponent,
    PageSurveyFillComponent,
  ],
  entryComponents: [
    PageSurveyMemberAddDialogComponent,
    PageSurveyMemberAddMobileDialogComponent,
  ]
})
export class KalgudiPageSurveysModule { }
