<div class="farmer-equipments p-3">

  <!-- Add Equipments -->
  <div class="equipments-list" fxLayout fxLayoutAlign="space-between center">

    <div fxLayout fxLayoutGap="7px" fxLayoutAlign="start center">
      <div class="mt-2">
        <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/farmers-equipments.svg"
        [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 title" translate>Equipments</p>
      </div>
    </div>

    <div>
      <button mat-button class="add-equipments" (click)="openEquipmentsDialog()" *ngIf="editable">
        <div class="button-title">
          <p class="m-0 profile-name"><b translate>Add</b></p>
        </div>
      </button>
    </div>
  </div>

  <!-- Equipments List -->
  <div fxLayout="row" fxLayoutAlign="start center" class="pt-2" *ngIf="lstOfEquipments?.length > 0">
    <div fxLayout="column" fxFlex="40%">
      <span class="text-secondary sub-name">Name</span>
    </div>

    <div fxLayout="column" fxFlex="40%">
      <span class="text-secondary sub-name">Quantity</span>
    </div>

    <div fxFlex="20%" fxLayout="column" fxLayoutAlign="flex end">&nbsp;</div>
  </div>

  <div *ngFor="let equipmentsList of lstOfEquipments; let last = last">

    <!-- Section of  EquipmentName, Quantity -->
    <div fxLayout="row" fxLayoutAlign="start center" class="py-1" [ngClass]="{'mb-n2': last}">
      <div fxLayout="column" fxFlex="40%">
        <span class="equipment-details text-truncate">{{ equipmentsList?.equipmentName }}</span>
      </div>

      <div fxLayout="column" fxFlex="40%">
        <span class="equipment-details text-truncate">{{ equipmentsList?.equipmentCapacity}}</span>
      </div>

      <div fxFlex="20%" fxLayout="column" fxLayoutAlign="flex end" *ngIf="editable">
        <button mat-icon-button class="action-buttons" (click)="deleteEquipment(equipmentsList?.equipmentId)">
          <mat-icon class="delete">delete_outline</mat-icon>
        </button>
      </div>
    </div>
    <hr *ngIf="!last" class="m-0">
  </div>

  <!-- No content -->
  <div *ngIf="!lstOfEquipments?.length">
    <kl-no-content matIcon="agriculture"
      [text]="'No equipment added yet!' | translate"
      iconSize="md"
      [actionLink]="editable ? 'Add Equipment' : ''"
      (action)="openEquipmentsDialog()">
    </kl-no-content>
  </div>
  <!-- / No content -->
</div>
