import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, KalgudiUserCertificateDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiProfileCertificatesApiService {

  /**
   * `v2/profiles/:profileKey/biz-certifications`
   */
  private readonly API_CERTIFICATES_BASE = `${this.env.restBaseUrlV2}/profiles/:profileKey/biz-certifications`;

  /**
   * `v2/profiles/:profileKey/biz-certifications`
   */
  private readonly API_CERTIFICATES_SAVE = this.API_CERTIFICATES_BASE;

  /**
   * `v2/profiles/:profileKey/biz-certifications/:id`
   */
  private readonly API_CERTIFICATES_UPDATE = `${this.API_CERTIFICATES_BASE}/:id`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private profileState: KalgudiProfileStateService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Calls API to save a new certificate to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param payload Certificate payload
   */
  saveNewCertificate(
    profileKey: string,
    defaultBizKey: string,
    payload: KalgudiUserCertificateDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_CERTIFICATES_SAVE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey);

    return this.httpClient.post<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateCertificateResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to save a new certificate to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param certificateId Unique identified associated with certificate
   *
   * @param payload Updated certificate payload
   */
  updateNewCertificate(
    profileKey: string,
    defaultBizKey: string,
    certificateId: string,
    payload: KalgudiUserCertificateDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_CERTIFICATES_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', certificateId);

    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateCertificateResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to delete an existing certificate of the logged in user.
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param certificateId Unique identifier of the certificate
   */
  deleteCertificate(
    profileKey: string,
    defaultBizKey: string,
    certificateId: string,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_CERTIFICATES_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', certificateId);

    return this.httpClient.delete<ApiResponseCommonV1>(url, { params })
      .pipe(

        // Api response error handler
        map(r => this.deleteCertificateResponseHandler(r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Save new Certificate API response handler. Throws error messages if
   * Api didn't sent success creation response.
   */
  private saveOrUpdateCertificateResponseHandler(req: KalgudiUserCertificateDetails, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved certificate details
    return res;
  }

  /**
   * Delete certificate API response handler. Throws error messages if
   * Api didn't sent success deletion response.
   */
  private deleteCertificateResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved certificate details
    return res;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
