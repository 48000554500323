import { Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiWeatherDetails } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

import { IWeatherCache } from '../model';

@Injectable()
export class WeatherCacheLocalStorageService implements IWeatherCache {

  private readonly weatherCacheKey = 'weather-cache';

  constructor(
    private util: KalgudiUtilityService,
  ) {}

  private get weatherCache(): {[key: string]: KalgudiWeatherDetails} {
    return this.util.getFromLocal(this.weatherCacheKey, false) || {};
  }


  /**
   * Constructs weather cache key and returns the cache key
   */
  constructCacheKey(latitude: string | number, longitude: string | number, language: string): string {
    return `${latitude}${longitude}${language}`;
  }

  /**
   * Returns `true` if weather is cached in memory otherwise returns `false`
   */
  isWeatherCached(latitude: string | number, longitude: string | number, language: string): boolean {

    const key = this.constructCacheKey(latitude, longitude, language);

    const weather = this.weatherCache[key];

    // Check weather expiry time
    const nowInMs             = new Date().getTime();
    const weatherCachedOnInMs = weather ? new Date(weather.updatedOn).getTime() : nowInMs;

    const oneMinInMs = 60000;     // 1 * 60 * 1000;
    const oneHrInMs  = 3600000;   // 1 * 60 * 60 * 1000;


    return (nowInMs !== weatherCachedOnInMs) && (nowInMs - weatherCachedOnInMs) < oneHrInMs;
  }

  /**
   * Caches a weather for later usage
   */
  cacheWeather(latitude: string | number, longitude: string | number, language: string, weather: KalgudiWeatherDetails): void {

    weather.updatedOn = new Date().toISOString();

    const key = this.constructCacheKey(latitude, longitude, language);

    // console.log(`Caching weather ${key} `, weather);
    const weatherCache = this.weatherCache;
    weatherCache[key] = weather;
    this.util.setToLocal(this.weatherCacheKey, weatherCache, false);
  }

  /**
   * Gets cached weather
   */
  getCachedWeather(latitude: string | number, longitude: string | number, language: string): Observable<KalgudiWeatherDetails> {

    const key = this.constructCacheKey(latitude, longitude, language);

    // console.log('Getting cached weather', this.weatherCache[key]);

    return of(this.weatherCache[key]);
  }
}
