import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiFpoBankingDetails } from '../../../classes/kalgudi-fpo-banking-details';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';

@Component({
  selector: 'kl-fpo-banking-details-tile',
  templateUrl: './fpo-banking-details-tile.component.html',
  styleUrls: ['./fpo-banking-details-tile.component.scss']
})
export class FpoBankingDetailsTileComponent extends KalgudiFpoBankingDetails implements OnInit {

  constructor(
    protected injector: Injector,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,

  ) {
    super(injector)
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Opens image full view
   */
  openImageFullView(attachments: any): void {
    let imgUrl = 'https://kalgudidata.s3.amazonaws.com' + attachments;
    let imgObj = [
      {url: imgUrl}
    ]
    this.kalgudiPhotoswipe.open(imgObj).subscribe();
  }

}
