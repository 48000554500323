import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiTranslationListService } from '@kalgudi/social';
import { GoogleSupportedLanguagesList, LanguageIdList } from '@kalgudi/third-party/google-language-translator';
import { LogBasicDetails } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kl-profile-log-tile-attachment',
  templateUrl: './profile-log-tile-attachment.component.html',
  styleUrls: ['./profile-log-tile-attachment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileLogTileAttachmentComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  profileLogDetails: LogBasicDetails;

  // Google language translations options
  translationsList: LanguageIdList = [];
  source: GoogleSupportedLanguagesList;

  constructor(
    private translationListService: KalgudiTranslationListService
  ) {
    super();
  }

  ngOnInit() {
  }

  /**
   * Called before any other lifecycle hook. Use it to inject dependencies,
   * but avoid any serious work here.
   */
  /*ngOnChanges(changes: SimpleChanges): void {

    if (changes.profileLogDetails && this.profileLogDetails) {
      this.initGoogleLangTranslator(this.profileLogDetails);
    }
  }*/

  onDestroyed() { }

  /**
   * Initializes google language translator options
   */
  /*private initGoogleLangTranslator(profileLogDetails: LogBasicDetails): void {

    this.translationListService.initGoogleLangTranslator(profileLogDetails.googleLocationShortCodes)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(list => {
        this.translationsList = list;
        this.source = profileLogDetails.googleLocationShortCodes as GoogleSupportedLanguagesList;
      });
  }*/
}
