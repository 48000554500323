import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { KalgudiStreamS3FetchService, SocialDataNormalizerService } from '@kalgudi/social';
import { ProfileSocialActivities } from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import { ProfileSocialActivitiesApiService } from './profile-social-activities-api.service';

@Injectable()
export class ProfileSocialActivitiesService {

  constructor(
    private api: ProfileSocialActivitiesApiService,
    private s3Fetch: KalgudiStreamS3FetchService,
    private socialDataNormalizer: SocialDataNormalizerService,
  ) { }

  /**
   * Gets, stream of share update activities performed by user.
   *
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getShareUpdatesStream(profileKey: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchUpdatesStream(profileKey, offset, limit)
      .pipe(

        // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
        // method.
        mergeMap(r =>

          // Combine response from all s3 items
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem<ProfileSocialActivities>(s as any, s.url, s.event))
          )
        ),

        // Map the complete stream response
        map(items => ({ items })),

        // Map author details of the social posts
        tap(items => items.items.forEach(item => this.socialDataNormalizer.mapShareUpdateQaAuthorDetails(item)))
      );
  }
}
