import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
  CommonSearchBarModule,
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUserPickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';

import { AddTaskMembersDialogComponent } from './components/add-task-members-dialog/add-task-members-dialog.component';
import {
  AddTaskMembersMobileDialogComponent,
} from './components/add-task-members-mobile-dialog/add-task-members-mobile-dialog.component';
import { AddTaskMembersComponent } from './components/add-task-members/add-task-members.component';
import { ManageTaskMembersComponent } from './components/manage-task-members/manage-task-members.component';
import { SearchMembersComponent } from './components/search-members/search-members.component';
import { AddTaskMembersApiService } from './services/add-task-members-api.service';
import { AddTaskMembersDialogService } from './services/add-task-members-dialog.service';
import { AddTaskMembersService } from './services/add-task-members.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';



@NgModule({
  declarations: [
    AddTaskMembersDialogComponent,
    AddTaskMembersMobileDialogComponent,
    AddTaskMembersComponent,
    ManageTaskMembersComponent,
    SearchMembersComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatIconModule,
    MatTabsModule,
    MatButtonModule,

    FlexLayoutModule,

    KalgudiButtonModule,
    KalgudiUserPickerModule,
    KalgudiUsersModule,
    KalgudiNoContentModule,
    KalgudiPipesModule,
    CommonSearchBarModule,
    KalgudiLoadMoreModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule,
    TranslateModule
  ],
  entryComponents: [
    AddTaskMembersDialogComponent,
    AddTaskMembersMobileDialogComponent
  ],
  providers: [
    AddTaskMembersDialogService,
    AddTaskMembersApiService,
    AddTaskMembersService,
  ]
})
export class AddTaskMembersModule { }
