<main id="social-container">

  <form class="mt-2" [formGroup]="fpoProfilesForm">
    <div class="social-form overflow-auto">
      <span class="heading-text font-weight-bold" translate>Promoting institution</span>
      <section formGroupName="socialDetails">

        <div fxLayout="column" formGroupName="promotingInstitution">
          <!-- Institute dropdown and attachments -->
          <div fxLayout fxLayoutGap="10px" class="mt-2 mb-n3">
            <!-- Institution name -->
            <div class="institute-name" fxLayout="column" [fxFlex]="getPromotingInstitutionLogoName?.value === 'Others' ? '48%' : '100%'" class="mb-1">
              <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
                <mat-label translate>Institution name</mat-label>
                <mat-select formControlName="promotingInstitutionLogoName" disableOptionCentering panelClass="myPanelClass" class="institution-value">
                  <mat-option *ngFor="let option of entities;" [value]="option.value">
                    <span [matTooltip]="option.value" matTooltipPosition="below"> {{ option.value }} </span>
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <!-- <mat-error class="mt-n2" *ngIf="getPromotingInstitutionLogoName?.hasError('pattern')">Please use letters followed by numbers</mat-error> -->
            </div>
            <!--/ Institution name -->

            <!-- Other input field -->
            <div *ngIf="getPromotingInstitutionLogoName?.value === 'Others'" [fxFlex]="getPromotingInstitutionLogoName?.value === 'Others' ? '48%' : '0%'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Others institute name</mat-label>
                <input
                  matInput
                  [placeholder]="'Enter other institute name' | translate"
                  formControlName="promotingInstitution"
                  maxlength="50">
              </mat-form-field>
            </div>
            <!--/ Other input field -->
          </div>
          <!--/ Institute dropdown and attachments -->

          <div class="mt-1" fxLayout fxLayoutGap="10px">
            <!-- Image attachments -->
            <div class="mb-2" fxLayout="column" fxFlex="50%">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="column">
                  <div>
                    <button [disabled]="disableAttach" mat-button class="attach-files mt-n1" id="attach-files-button" color="primary" [matMenuTriggerFor]="promotionMenu">
                      <mat-icon translate>attachment</mat-icon>
                      <span class="ml-1" translate>Attach logo</span>
                    </button>
                  </div>
                  <div class="mt-n2">
                    <span class="attach-note" [ngClass]="{ 'image-type' : disableAttach }" >
                      .jpeg, .png or .jpg
                    </span>
                  </div>
                </div>

                <mat-menu #promotionMenu="matMenu" class="attach-file">
                  <ng-container>
                    <div class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu pl-3 pr-2 pt-1">
                        <div>
                          <p class="m-0 title" translate>Image</p>
                        </div>

                        <div>
                          <kl-image-picker-form-control
                            [formControl]="promotionOfAttachments"
                            [showBorder]="false"
                            [buttonType]="'mat-icon-button'"
                            svgButtonIcon="assets/svgs/attach-file/images.svg"
                            [showAttachments]="false"
                            [s3Category]="s3Category"
                            [allowMultiple]="false"
                            [acceptedFileTypes]="acceptedImageFileTypes">
                          </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-menu>
              </div>
            </div>
            <!--/ Image attachments -->

            <!-- Attached files -->
            <div fxLayout="row wrap" fxLayout fxLayoutAlign="start start" fxLayoutGap="8px" class="attachemnt-chips mt-2 mb-2 w-100" fxFlex="50%">
              <ng-container *ngIf="promotionOfAttachments?.value">
                <ng-container *ngFor="let attachment of promotionOfAttachments?.value; let i = index">
                  <mat-chip class="mb-4">
                    <img class="chip-image cursor-pointer rounded-circle mr-1" title="Click to full-view" [src]="(attachment?.url ? attachment?.url : attachment) | prefixDomain" (click)="openImageFullView(promotionOfAttachments?.value, i)">
                    <mat-icon class="cursor-pointer" matChipRemove [title]="disableAttach ? ('Image attached' | translate) : ('Remove image' | translate)" (click)="removePromotionImage(i)">
                      {{ disableAttach ? 'check_circle' : 'cancel' }}
                    </mat-icon>
                  </mat-chip>
                </ng-container>
              </ng-container>
            </div>
            <!--/ Attached files -->
          </div>
        </div>

        <span class="heading-text font-weight-bold mt-2" translate>Resource institution</span>

        <div fxLayout="row" fxLayoutGap="10px" class="w-100 mt-2 mb-n3" formGroupName="resourceInstitution">
          <!-- Resource institution logo -->
          <div fxLayout="column" class="mb-2 w-100">
            <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
              <mat-label translate>Institution name</mat-label>
              <input matInput [placeholder]="'Enter institution logo name' | translate" formControlName="resourcingInstitutionLogoName" maxlength="50" pattern="^[A-Za-z][A-Za-z0-9\s_-]*$">
            </mat-form-field>
            <mat-error  class="mt-n2" *ngIf="getResourceInstitutionLogoName?.hasError('pattern')">Please use letters followed by numbers</mat-error>
          </div>
          <!--/ Resource institution logo -->
        </div>

        <div class="mb-2" fxLayout fxLayoutGap="10px">
          <!-- Image attachment -->
          <div class="table-order" fxFlex="50%">
            <div fxLayout="row" fxLayoutAlign="space-between center">

              <!-- Attachments -->
              <div fxLayout="column">
                <div>
                  <button mat-button class="attach-files mt-n1" id="attach-files-button" color="primary" [matMenuTriggerFor]="resourceMenu">
                    <mat-icon translate>attachment</mat-icon>
                    <span class="ml-1" translate>Attach logo</span>
                  </button>
                </div>
                <div class="mt-n2">
                  <span class="attach-note">.jpeg, .png or .jpg</span>
                </div>
              </div>


              <mat-menu #resourceMenu="matMenu" class="attach-file">
                <ng-container>
                  <div class="attach-file-mat-menu">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu pl-3 pr-2 pt-1">
                      <div>
                        <p class="m-0 title" translate>Image</p>
                      </div>

                      <div>
                        <kl-image-picker-form-control
                          [formControl]="resourceOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [allowMultiple]="false"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-menu>
              <!--/ Attachments -->
            </div>
          </div>
          <!-- Image attachment -->

          <!-- Attached files -->
          <div fxLayout="row wrap" fxLayoutGap="8px" class="attachemnt-chips mt-2" fxFlex="50%">
            <ng-container *ngIf="resourceOfAttachments?.value">
              <ng-container *ngFor="let attachment of resourceOfAttachments?.value; let i = index">
                <mat-chip class="mb-4">
                  <img class="chip-image cursor-pointer rounded-circle mr-1" title="Click to full-view" [src]="(attachment?.url ? attachment?.url : attachment) | prefixDomain" (click)="openImageFullView(resourceOfAttachments?.value, i)">
                  <mat-icon class="cursor-pointer" matChipRemove [title]="'Remove image' | translate" (click)="removeResourceImage(i)">cancel
                  </mat-icon>
                </mat-chip>
              </ng-container>
            </ng-container>
          </div>
          <!--/ Attached files -->
        </div>

        <!-- Team photo attachments -->
        <span class="heading-text font-weight-bold" translate>Team photo</span>

        <div class="mb-3" fxLayout>
          <div fxLayout="row" fxLayoutGap="10px" fxFlex="50%" formGroupName="teamPhoto">

            <div class="table-order">
              <div fxLayout="row" fxLayoutAlign="space-between center">

                <!-- Attachments -->
                <div fxLayout="column">
                  <div>
                    <button mat-button class="attach-files mt-n1" id="attach-files-button" color="primary" [matMenuTriggerFor]="photoMenu">
                      <mat-icon translate>attachment</mat-icon>
                      <span class="ml-1" translate>Attach photo</span>
                    </button>
                  </div>
                  <div class="mt-n2">
                    <span class="attach-note">.jpeg, .png or .jpg</span>
                  </div>
                </div>

                <mat-menu #photoMenu="matMenu" class="attach-file">
                  <ng-container>
                    <div class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu pl-3 pr-2 pt-1">
                        <div>
                          <p class="m-0 title" translate>Image</p>
                        </div>
                        <div>
                          <kl-image-picker-form-control
                            [formControl]="teamPhotoOfAttachments"
                            [showBorder]="false"
                            [buttonType]="'mat-icon-button'"
                            svgButtonIcon="assets/svgs/attach-file/images.svg"
                            [showAttachments]="false"
                            [s3Category]="s3Category"
                            [acceptedFileTypes]="acceptedImageFileTypes"
                            [allowMultiple]="false">
                          </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </mat-menu>
                <!--/ Attachments -->
              </div>
            </div>
          </div>

          <!-- Attached files -->
          <div fxLayout="row wrap" fxLayoutGap="8px" class="attachemnt-chips" fxFlex="50%">
            <ng-container *ngIf="teamPhotoOfAttachments?.value">
              <ng-container *ngFor="let attachment of teamPhotoOfAttachments?.value; let i = index">
                <mat-chip class="mb-4">
                  <img class="chip-image cursor-pointer rounded-circle mr-1" title="Click to full-view" [src]="(attachment?.url ? attachment?.url : attachment) | prefixDomain" (click)="openImageFullView(teamPhotoOfAttachments?.value, i)">
                  <mat-icon class="cursor-pointer" matChipRemove [title]="'Remove image' | translate" (click)="removePhotoImage(i)">cancel
                  </mat-icon>
                </mat-chip>
              </ng-container>
            </ng-container>
          </div>
          <!--/ Attached files -->
        </div>
        <!--/ Team photo attachments -->

        <!-- Entity logo attachment -->
        <span class="heading-text font-weight-bold" translate>Entity logo</span>
        <div fxLayout>
          <div fxLayout="row" fxLayoutGap="10px" fxFlex="50%" formGroupName="entityPhoto">
            <div class="table-order">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <!-- Attachments -->
                <div fxLayout="column">
                  <div>
                    <button mat-button class="attach-files mt-n1" id="attach-files-button" color="primary" [matMenuTriggerFor]="entityPhoto">
                      <mat-icon translate>attachment</mat-icon>
                      <span class="ml-1" translate>Attach photo</span>
                    </button>
                  </div>
                  <div class="mt-n2">
                    <span class="attach-note">.jpeg, .png or .jpg</span>
                  </div>
                </div>
                <mat-menu #entityPhoto="matMenu" class="attach-file">
                  <div class="attach-file-mat-menu">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu pl-3 pr-2 pt-1">
                      <div>
                        <p class="m-0 title" translate>Image</p>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          [formControl]="entityLogoOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes"
                          [allowMultiple]="false">
                        </kl-image-picker-form-control>
                      </div>
                    </div>
                  </div>
                </mat-menu>
                <!--/ Attachments -->
              </div>
            </div>
          </div>
          <!-- Attached files -->
          <div fxLayout="row wrap" fxLayoutGap="8px" class="attachemnt-chips" fxFlex="50%">
            <ng-container *ngIf="entityLogoOfAttachments?.value">
              <ng-container *ngFor="let attachment of entityLogoOfAttachments?.value; let i = index">
                <mat-chip class="mb-4">
                  <img class="chip-image cursor-pointer rounded-circle mr-1" title="Click to full-view" [src]="(attachment?.url ? attachment?.url : attachment) | prefixDomain" (click)="openImageFullView(entityLogoOfAttachments?.value, i)">
                  <mat-icon matChipRemove [title]="'Remove image' | translate" (click)="removeEntityPhotoImage(i)">cancel
                  </mat-icon>
                </mat-chip>
              </ng-container>
            </ng-container>
          </div>
          <!--/ Attached files -->
        </div>
        <!--/ Entity logo attachment -->

        <section formGroupName="socialInfo">
          <div fxLayout="row" fxLayoutGap="10px" class="mt-4">

            <!-- Official website -->
            <div fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
                <mat-label translate>Official website link</mat-label>
                <input matInput [placeholder]="'Enter Official website' | translate" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" formControlName="officialWebsiteLink" maxlength="100">
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getOfficialWebsiteValue.invalid" translate>Please enter a valid URL!</mat-error>
            </div>
            <!--/ Official website -->

            <!-- Facebook link -->
            <div fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
                <mat-label translate>Facebook link</mat-label>
                <input matInput [placeholder]="'Enter Facebook link' | translate" formControlName="facebookLink" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" maxlength="100">
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getFacebookWebsiteValue.invalid" translate>Please enter a valid URL!</mat-error>
            </div>
            <!--/ Facebook link -->

          </div>

          <div fxLayout="row" fxLayoutGap="10px" class="mt-3">

            <!-- Twitter link -->
            <div fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
                <mat-label translate>Twitter link</mat-label>
                <input matInput [placeholder]="'Enter Twitter link' | translate" formControlName="twitterLink" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" maxlength="100">
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getTwitterWebsiteValue.invalid" translate>Please enter a valid URL!</mat-error>
            </div>
            <!--/ Twitter link -->

            <!-- Instagram link -->
            <div fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100" class="placeholder-text">
                <mat-label translate>Instagram link</mat-label>
                <input matInput [placeholder]="'Enter Instagram link' | translate" formControlName="instagramLink" pattern="[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)" maxlength="100">
              </mat-form-field>
              <mat-error class="mt-n2" *ngIf="getInstagramWebsiteValue.invalid" translate>Please enter a valid URL!</mat-error>
            </div>
            <!--/ Instagram link -->

          </div>
        </section>
      </section>
    </div>
  </form>

  <!-- Save button -->
  <!-- <div fxLayout fxLayoutAlign="end end" class="py-3">
    <kl-flat-button
      label="Save"
      [progress]="isProgress"
      [disabled]="this.socialDetailsGroup?.invalid"
      (clicked)="addSocialDetails()">
    </kl-flat-button>
  </div> -->
  <!--/ Save button -->
</main>
