import { InjectionToken } from '@angular/core';
import { StoreEnvironmentConfig, StoreType } from '@kalgudi/types';


/**
 * Specifies Kalgudi Store admin library configuration
 *
 * @author Pankaj Prakash
 */
export interface StoreAdminConfiguration {
  // coreConfig: KalgudiCoreConfig;
  storeType: StoreType;
  env?: StoreEnvironmentConfig;
}

export const STORE_ADMIN_CONFIG = new InjectionToken<StoreAdminConfiguration>('STORE_ADMIN_CONFIG');
