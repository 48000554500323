<mat-card
  fxFlex="100%"
  fxLayout="column"
  class="profile-grade-tile py-0"
  *ngIf="profileDetails$ | async as profileDetails"
>
  <!-- Header -->

  <kl-common-profile-header
    title="Profile grade"
    svgIcon="assets/svgs/analytics.svg"
    [editable]="hasDetailPage"
    actionIcon="launch"
    (action)="profileGrading()"
  >
  </kl-common-profile-header>

  <!-- /Header -->

  <!-- List -->
  <section>
    <div fxLayout="column">
      <div class="grading-card-fpo">
        <div class="row mx-0">
          <h5 class="col-10 p-0 title">{{ profileDetails.firstName }}</h5>
          <h5 class="col-2 p-0 score">{{ profileDetails.score || 80 }}&#37;</h5>
        </div>
        <div class="row center mx-0">
          <div class="col-8 p-0">
            <profile-gauge-chart
              variant="big-chart"
              [title]="profileDetails.firstName"
              [score]="profileDetails.score || 80"
            ></profile-gauge-chart>
          </div>
          <div
            class="col-4 p-0 d-flex justify-content-center flex-column align-items-end"
          >
            <span class="label">Your grade</span>
            <div class="grade">{{ profileDetails.grade || "A" }}</div>
          </div>
        </div>
        <p class="report-message">
          Congrats for the good work, activities such as building proper
          business plan, engagement with member farmers would take to higher
          grades.
        </p>
      </div>
    </div>
  </section>
  <!--/ List -->
</mat-card>
