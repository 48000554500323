<section class="promotional-recommended-products">
  <!-- <div class="my-2">
    <span class="products">Recommended Product</span>
  </div> -->
  <div *ngIf="productList.length <= 2">
    <div *ngFor="let product of productList">

      <div fxLayout=row fxLayoutGap="15px" class="mb-2">
        <div class="product-img" fxLayoutAlign="center center">
          <img [klErrorImages]="[
            (product?.productPicURL_level3) | prefixDomain,
            domain + defaultProfilePic]"
            [src]="product?.productPicURL_level3 | prefixDomain | resizeImage:'220x220'" class="w-100 h-100">
        </div>

        <div fxLayout="column" fxLayoutGap="8px" fxFlex.gt-xs="calc(80% - 10px)" fxFlex.xs="calc(65% - 10px)">
          <div fxLayout="column" *ngIf="product?.productName_level2">
            <span class="product-name overflow-hidden">{{product?.productName_level2}}</span>
            <span class="store-name text-truncate" *ngIf="product?.sellerName">{{ product?.sellerName }}</span>
            <!-- <span class="store-name text-truncate" *ngIf="product?.manufacturerName"><span class="font-weight-bold">Mfr: </span>{{ product?.manufacturerName }}</span> -->
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="5px">
            <div fxLayout="column" class="quantity-cost">
              <span class="price-tag" *ngIf="product?.mrp || product?.pricePerUnit">Price</span>
              <span class="mb-0" *ngIf="product?.mrp || product?.pricePerUnit">
                <span class="cost mr-2" *ngIf="product?.pricePerSKU">₹{{product?.pricePerSKU}}</span>
                <span class="text-muted actual-cost" *ngIf="product?.mrp"><del>₹{{product?.mrp}}</del></span>
              </span>
              <span class="quantity text-muted" *ngIf="product?.SKUSmartElements?.weight?.value && product?.SKUSmartElements?.weight?.unit">
                <span>{{product?.SKUSmartElements?.weight?.value}}</span>
                <span> {{product?.SKUSmartElements?.weight?.unit}}</span>
              </span>

              <span class="quantity text-muted" *ngIf="product?.SKUSmartElements?.dimensions?.width?.value || product?.SKUSmartElements?.dimensions?.length?.value">
                <span *ngIf="product?.SKUSmartElements?.dimensions?.width?.value">{{product?.SKUSmartElements?.dimensions?.width?.value}}</span>(W) x
                <span *ngIf="product?.SKUSmartElements?.dimensions?.length?.value">{{product?.SKUSmartElements?.dimensions?.length?.value}}</span>(L) in
                <span>{{ product?.SKUSmartElements?.dimensions?.unit}}</span>
              </span>

              <div *ngIf="!product?.mrp" fxLayout="column" class="enquiry-btn">
                <span class="price-tag">Price</span>
                <button mat-button disabled>
                  <span class="text-white">Send Enquiry</span>
                </button>
              </div>
            </div>

            <div class="view-product-btn" fxLayoutAlign="end end">
              <button mat-button (click)="productFullView(product?.storeType,product?.productName_level2,product?.productId_level2,product?.productId_level3 , product?.stores, product?.isBulkOrder);$event.stopPropagation()">
                  <span class="text-white" translate>View product</span>
              </button>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>

  <div *ngIf="productList.length > 2">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
      <div ngxSlickItem *ngFor="let product of productList">
        <div fxLayout="column" fxLayoutGap="10px" class="mr-3 p-2 multiple-products">
          <div fxLayout="column" *ngIf="product?.productName_level2">
            <span class="product-name overflow-hidden">{{product?.productName_level2}}</span>
            <span class="store-name text-truncate" *ngIf="product?.sellerName">{{ product?.sellerName }}</span>
            <!-- <span class="store-name text-truncate" *ngIf="product?.manufacturerName"><span class="font-weight-bold">Mfr: </span>{{ product?.manufacturerName }}</span> -->
          </div>

          <div class="product-img1 w-100" fxLayoutAlign="center center">
            <img [klErrorImages]="[
              (product?.productPicURL_level3) | prefixDomain,
              domain + defaultProfilePic]"
              [src]="product?.productPicURL_level3 | prefixDomain | resizeImage:'220x220'" class="w-100 h-100">
          </div>

          <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="5px">
            <div fxLayout="column" class="quantity-cost">
              <span class="price-tag" *ngIf="product?.mrp || product?.pricePerUnit">Price</span>
              <span class="mb-0" *ngIf="product?.mrp || product?.pricePerUnit">
                <span class="cost mr-2" *ngIf="product?.pricePerSKU">₹{{product?.pricePerSKU}}</span>
                <span class="text-muted actual-cost" *ngIf="product?.mrp"><del>₹{{product?.mrp}}</del></span>
              </span>
              <span class="quantity text-muted" *ngIf="product?.SKUSmartElements?.weight?.value && product?.SKUSmartElements?.weight?.unit">
                <span>{{product?.SKUSmartElements?.weight?.value}}</span>
                <span> {{product?.SKUSmartElements?.weight?.unit}}</span>
              </span>

              <span class="quantity text-muted" *ngIf="product?.SKUSmartElements?.dimensions?.width?.value || product?.SKUSmartElements?.dimensions?.length?.value">
                <span *ngIf="product?.SKUSmartElements?.dimensions?.width?.value">{{product?.SKUSmartElements?.dimensions?.width?.value}}</span>(W) x
                <span *ngIf="product?.SKUSmartElements?.dimensions?.length?.value">{{product?.SKUSmartElements?.dimensions?.length?.value}}</span>(L) in
                <span>{{ product?.SKUSmartElements?.dimensions?.unit}}</span>
              </span>

              <div *ngIf="!product?.mrp" fxLayout="column" class="enquiry-btn">
                <span class="price-tag">Price</span>
                <button mat-button disabled>
                  <span class="text-white">Send Enquiry</span>
                </button>
              </div>
            </div>

            <div class="view-product-btn" fxLayoutAlign="end end">
              <button mat-button (click)="productFullView(product?.storeType,product?.productName_level2,product?.productId_level2,product?.productId_level3 , product?.stores, product?.isBulkOrder);$event.stopPropagation()">
                  <span class="text-white" translate>View product</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ngx-slick-carousel>
  </div>
</section>
