import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import {
  KL_ENV,
  KL_NOTIFICATION,
  KalgudiEnvironmentConfig,
  KalgudiNotification,
} from "@kalgudi/core/config";
import { FileMimeTypes, KalgudiRobocall } from "@kalgudi/types";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { KalgudiPublishRobocall } from "../../classes/kalgudi-publish-robocall";
import { checkMobileDevice } from "@kalgudi/core";

@Component({
  selector: "kl-publish-robocall-form",
  templateUrl: "./publish-robocall-form.component.html",
  styleUrls: ["./publish-robocall-form.component.scss"],
})
export class PublishRobocallFormComponent
  extends KalgudiPublishRobocall
  implements OnInit
{
  @Output()
  robocallCreated = new EventEmitter<KalgudiRobocall>();

  /**
   * SMS details, it must be provided by a stream.
   */
  @Input()
  postDetails: KalgudiRobocall;

  shareWithValue: string;
  isMobileDevice: boolean;
  rowsCount!: number;
  scheduleValues: boolean = true;

  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO, FileMimeTypes.AUDIO_M4R, FileMimeTypes.AUDIO_AAC ];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) {
    super(injector, notification);

    // Checking value changes in schedule date & time
    this.shareForm.valueChanges.subscribe(val => {
      if (val.scheduledDate) {
        this.scheduleValues = false;
      } else {
        this.scheduleValues = true;
      }
      if (val.scheduledDate && val.scheduledTime) {
        this.scheduleValues = true;
      }
    })
  }

  ngOnInit() {
    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }
    this.rowsCount = checkMobileDevice() ? 8 : 3;
    this.updateDateLocale();
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  // selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
  //   return item.value.profileKey;
  // }

  protected onDestroyed(): void {}

  /**
   * Calls after posting a share
   * @param sms
   */
  protected onRobocallCreated(sms: KalgudiRobocall): void {
    this.robocallCreated.emit(sms);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) =>
      this.dateAdapter.setLocale(lang.lang)
    );
  }

  /**
   * Triggered while changing share-with field
   */
  shareWithChanged(event: string) {
    this.shareWithValue = event;
  }

  trackByKey = (index: number, obj: any): string => {
    return obj.url;
  };
}
