import { Injector, Input, Directive } from '@angular/core';
import { KalgudiAttachmentsPickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { Action, ActivityType, KALGUDI_PAGE_RELATION_MAP, KalgudiProjectTask } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, map, takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class KalgudiTaskFullView extends KalgudiDestroyable {

  @Input()
  taskId: string;

  taskDetails: KalgudiProjectTask;

  projectId: string;

  projectRoles = KALGUDI_PAGE_RELATION_MAP;

  activityType = ActivityType;

  action = Action;

  submissionDetails: KalgudiProjectTask;

  profileKey: string;

  private taskService: KalgudiTasksService;
  private attachmentsPickerService: KalgudiAttachmentsPickerService;
  private tasksActionService: KalgudiTaskActionsService;
  private stateService: KalgudiProjectStateService;
  private notifications: KalgudiNotification;
  private projectService: KalgudiProjectService;
  private kalgudiApp: KalgudiAppService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.taskService              = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.attachmentsPickerService = this.injector.get<KalgudiAttachmentsPickerService>(KalgudiAttachmentsPickerService);
    this.tasksActionService       = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);
    this.stateService             = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.notifications            = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.projectService           = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.kalgudiApp               = this.injector.get(KalgudiAppService);

    // this.stateService.projectDetails$
    // .pipe(
    // takeUntil(this.destroyed$),
    // )
    //   .subscribe(
    // res => this.projectId = res.id
    // );

    this.stateService.taskDetails$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.taskDetails = res;
          this.projectId = res.projectId;
        }
      );

    this.profileKey = this.kalgudiApp.profileLocal.profileKey;
  }


  onDestroyed(): void {}


  /**
   * Method to get submission details
   */
  init() {
    this.fetchTaskSubmission().subscribe( res => this.submissionDetails = res );
  }

  /**
   * Method to get task full details
   * @param taskId
   */
  getTaskDetails(taskId: string) {

    // this.notifications.showSpinner();

    this.taskService.getTaskDetails(taskId)
      .pipe(
        finalize(() => this.notifications.hideSpinner())
      )
      .subscribe(
        res => this.taskDetails = res,
      );
  }

  /**
   * Open add members dialog
   */
  showAddTaskMembersDialog(taskId: string, projectId: string) {

    this.tasksActionService.showAddTaskMembersDialog(taskId, projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.getTaskDetails(taskId);
          this.stateService.reloadTasks();
        }
      );
  }

  deleteTask(taskId: string) {
    this.tasksActionService.deleteTask(taskId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.taskDeleteHandler(res),
        err => this.taskDeleteErrHandler(err)
      );
  }

  updateTask(taskId: string) {
    this.tasksActionService.openTaskCreationDialog(this.projectId, taskId)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        (res) => {
          this.getTaskDetails(taskId);
          this.stateService.reloadTasks();
        }
      );
  }


  /**
   * Update the task
   */
  updateState() {

    this.tasksActionService.openTaskStatusChangeDialog(this.submissionDetails)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.stateUpdateHandler(res);
        },
        err => this.stateUpdateErrHandler(err)
      );


  }

  /**
   * Fetches task submission details from API
   */
  protected fetchTaskSubmission(): Observable<KalgudiProjectTask> {

    return this.taskService.getActivities(
      this.activityType.TASK_SUBMISSION,
      this.taskDetails.taskId,
      0,
      1,
      this.profileKey
    )
    .pipe(
      map(res => res.items[0]),
    );
  }


  /**
   * Event handler for successful state updation
   */
  protected stateUpdateHandler(res) {
    this.stateService.updateTaskDetails(this.taskDetails);
    this.getTaskDetails(this.taskDetails.taskId);
    this.stateService.reloadTasks();
  }

  /**
   * Event handler for failed to update state
   */
  protected stateUpdateErrHandler(err: Error) {
    this.notifications.showMessage('Failed to update task state');
  }

  /**
   * Event handler for successful task deletion
   */
  protected taskDeleteHandler(res) {
    this.stateService.reloadTasks();
  }

  /**
   * Event handler for failed to delete task
   */
  protected taskDeleteErrHandler(err: Error) {
    this.notifications.showMessage('Failed to delete a task');
  }

  /**
   * Updates the project details with latest data
   */
  private updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }

}
