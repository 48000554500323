import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { KalgudiDialogsModule } from '../kalgudi-dialogs/kalgudi-dialogs.module';
import { KalgudiNoContentModule } from '../kalgudi-no-content/kalgudi-no-content.module';
import { KalgudiUsersModule } from '../kalgudi-users/kalgudi-users.module';
import { KalgudiLoadMoreModule } from '../load-more/load-more.module';
import { CommonSearchBarModule } from '../search/common-search-bar.module';
import { ConnectsPickerDialogComponent } from './components/connects-picker-dialog/connects-picker-dialog.component';
import {
  ConnectsPickerMobileDialogComponent,
} from './components/connects-picker-mobile-dialog/connects-picker-mobile-dialog.component';
import { ConnectsPickerComponent } from './components/connects-picker/connects-picker.component';
import { KalgudiConnectsPickerService } from './services/kalgudi-connects-picker.service';



@NgModule({
  declarations: [
    ConnectsPickerComponent,
    ConnectsPickerDialogComponent,
    ConnectsPickerMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material module
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    FlexLayoutModule,

    // Kalgudi
    KalgudiButtonModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiDialogsModule,
    CommonSearchBarModule,
    KalgudiUsersModule,
  ],
  exports: [
    ConnectsPickerComponent
  ],
  entryComponents: [
    ConnectsPickerDialogComponent,
    ConnectsPickerMobileDialogComponent
  ],
  providers: [
    KalgudiConnectsPickerService
  ]
})
export class KalgudiConnectsPickerModule { }
