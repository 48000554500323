<div class="warehouse-godown-form">
  <form autocomplete="off" [formGroup]="warehouseGodownForm">
    <div fxLayout="column">

      <div fxLayout="column">

        <div fxLayout="row" fxLayoutGap="10px">
          <!-- Capacity -->
          <div fxLayout="column" class="mb-2" fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline">
              <mat-label translate>Capacity</mat-label>
              <input matInput formControlName="capacityInTons" [placeholder]="'Enter capacity' | translate" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              <mat-error translate>Please enter capacity</mat-error>
            </mat-form-field>
          </div>

          <!-- Size of warehouse -->
          <div fxLayout="column" class="mb-2" fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline">
              <mat-label translate>Size of warehouse</mat-label>
              <input matInput formControlName="warehouseSize" [placeholder]="'Enter size of warehouse' | translate" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              <mat-error translate>Please enter size of warehouse</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="10px">

          <!-- Items stocked -->
          <div fxLayout="column" class="mb-2" fxFlex="calc(60% - 10px)">
            <mat-form-field appearance="outline">
              <mat-label translate>Items stocked</mat-label>
              <input matInput formControlName="itemsStocked" [placeholder]="'Enter items stocked' | translate" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              <mat-error translate>Please enter items stocked</mat-error>
            </mat-form-field>
          </div>

          <div class="update-button">
            <kl-flat-button
              type="submit"
              label= "Save"
              [progress]="progress"
              (clicked)="updateWarehouse()"
              (disabled)="!warehouseGodownForm?.valid">
            </kl-flat-button>
          </div>
        </div>

      </div>

    </div>

  </form>
</div>
