import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AddProjectMembersResponse, ApiResponseCommon, BulkMembersAdditionResponse, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AddProjectMembersApiService {

  /**
   * `v2/projects/members`
   */
  private readonly API_PROJECT_MEMBERS = `${this.environment.restBaseUrlV2}/projects/members`;

  /**
   * `v2/projects/members/bulk`
   */
  private readonly API_PROJECT_BULK_MEMBERS = `${this.API_PROJECT_MEMBERS}/bulk`;


  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Api to get project members
   */
  getProjectMembers(projectId: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    const params = {
      projectId,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient
      .get<ApiResponseCommon>(this.API_PROJECT_MEMBERS, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response to Kalgudi project members object type
        map(r => this.mapMembersListResponse(r))
      );
  }

  /**
   * Api to add members to project
   */
  addProjectMembers(projectId: string, req: AddProjectMembersResponse, extraParams: PartialData = {}): Observable<string[]> {
    const params = {
      projectId,
      ...extraParams
    };

    return this.httpClient
      .post<ApiResponseCommon>(this.API_PROJECT_MEMBERS, req, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),
        map(res => res.data.users)
      );
  }

  /**
   * Defines the api method to add bulk members to a project using ids
   */
  addBulkMembers(projectId: string, payload: string[], extraParams: PartialData = {}): Observable<BulkMembersAdditionResponse> {

    // Api query params
    const params = {
      projectId,
      ...extraParams
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_PROJECT_BULK_MEMBERS, payload, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi project member details object type
        map(res => res.data)
      );
  }

  /**
   * Api to delete members to project
   */
  deleteProjectMembers(projectId: string, profileKey: string): Observable<string[]> {
    const params = {
      projectId,
      profileKey
    };

    return this.httpClient
      .delete<ApiResponseCommon>(this.API_PROJECT_MEMBERS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Maps projects list API response to kalgudi project members type.
   */
  private mapMembersListResponse(res: ApiResponseCommon): KalgudiStreamData {
    return {
      items: res.data.results,
      count: res.data.count
    };
  }

}
