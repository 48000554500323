import { IdValueMap } from '@kalgudi/types';

export enum PageActions {
  PAGE_UPDATED               = 'PAGE_UPDATED',
  PAGE_DA_STATS_UPDATED      = 'PAGE_DA_STATS_UPDATED',
  PROFILE_PIC_UPDATE         = 'PROFILE_PIC_UPDATE',
  PROGRAM_MEMBERS_UPDATE     = 'PROGRAM_MEMBERS_UPDATE',
  PROGRAM_ADMIN_UPDATE       = 'PROGRAM_ADMIN_UPDATE',
  FETCH_PROGRAM              = 'FETCH_PROGRAM',
  PROGRAM_CONTRIBUTOR_UPDATE = 'PROGRAM_CONTRIBUTOR_UPDATE',
  ADD_ADMIN                  = 'ADD_ADMIN',
  ADD_CONTRIBUTOR            = 'ADD_CONTRIBUTOR',
  ADD_MEMBER                 = 'ADD_MEMBER',
  UPDATE_PROGRAM_DETAILS     = 'UPDATE_PROGRAM_DETAILS',
  ADD_STOCK                  = 'ADD_STOCK',
  ADD_PROJECT                = 'ADD_PROJECT',
  ADD_TASK                   = 'ADD_TASK',
  DELETE_PAGE                = 'DELETE_PAGE',
  ADD_PROCESSING             = 'ADD_PROCESSING',
  PROGRAM_MEMBERS_UPDATED    = 'PROGRAM_MEMBERS_UPDATED',

  // Surveys actions
  PAGE_SURVEY_ADD          = 'PAGE_SURVEY_ADD',
  PAGE_SURVEY_QUESTION_ADD = 'PAGE_SURVEY_QUESTION_ADD',
  PAGE_SURVEY_ADMINS_ADD   = 'PAGE_SURVEY_ADMINS_ADD',
  PAGE_SURVEY_MEMBERS_ADD  = 'PAGE_SURVEY_MEMBERS_ADD',
  PAGE_CLONED              = 'PAGE_CLONED'
}

export const PROGRAM_TABS = {
  UPDATES: 'updates',
  QA: 'questions',
  SMS: 'sms',
  SURVEY: 'survey',
  INFO: 'info',
  ADMINS: 'admins',
  CONTRIBUTORS: 'contributors',
  MEMBERS: 'members'
};


export enum PageFilterTypes {
  MEMBER_ROLE = 'MEMBER_ROLE',
  PAGE_TYPE   = 'PAGE_TYPE'
}

export interface PageFilter {
  type?: PageFilterTypes;
  value?: string;
}

export const UNITS_LIST = [
  'Kg',
  'Quintals',
  'Tons',
  'Count'
] as const;

export const programType: IdValueMap[] = [
  {id: 'GROUP', value: 'Group'},
  {id: 'ADVISORY', value: 'Advisory'},
  {id: 'MARKETING', value: 'Marketing'}
];


export const DigitalAssistanceActivityTypeLabelMap = {
  SURVEY_SUBMISSION: 'Survey Submitted',
  SHAREATHOUGHT: 'Share Update',
  QA: 'Asked Question',
  PROFILE_UPDATE: 'Profile Update',
  TRAINING: 'Training'
};

export const AvailableDomains = {
  APGREENS: 'https://andhragreens.com',
  CORE: 'https://kalgudi.com',
  INPUTS: 'https://inputs.kalgudi.com',
  FARM_STORE: 'https://inputs.kalgudi.com',
  OUTPUTS: 'https://outputs.kalgudi.com',
  TGREENS: 'https://tgreens.in'
}
