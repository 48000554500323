import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { KalgudiAttachmentsPickerModule, KalgudiCommonModule, KalgudiImageChipListModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiCoreModule, KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { EmbedVideo } from 'ngx-embed-video';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import {
  AiPredictionsSendSmsDialogComponent,
} from './components/ai-predictions-send-sms-dialog/ai-predictions-send-sms-dialog.component';
import {
  AiPredictionsSendSmsMobileDialogComponent,
} from './components/ai-predictions-send-sms-mobile-dialog/ai-predictions-send-sms-mobile-dialog.component';
import { AiPredictionsSendSmsComponent } from './components/ai-predictions-send-sms/ai-predictions-send-sms.component';
import { ListWidgetComponent } from './components/list-widget/list-widget.component';
import { PostTileAttachmentsComponent } from './components/post-tile-attachments/post-tile-attachments.component';
import {
  PostTileImageAttachmentComponent,
} from './components/post-tile-image-attachment/post-tile-image-attachment.component';
import { PostTileLinkAttachmentComponent } from './components/post-tile-link-attachment/post-tile-link-attachment.component';
import { ScheduleSendDialogComponent } from './components/schedule-send-dialog/schedule-send-dialog.component';
import {
  ScheduleSendMobileDialogComponent,
} from './components/schedule-send-mobile-dialog/schedule-send-mobile-dialog.component';
import { ScheduleSendComponent } from './components/schedule-send/schedule-send.component';
import { SocialAttachmentsComponent } from './components/social-attachments/social-attachments.component';
import { SocialCommentsFormComponent } from './components/social-comments-form/social-comments-form.component';
import { SocialCommentsListComponent } from './components/social-comments-list/social-comments-list.component';
import { SocialCommentsTileComponent } from './components/social-comments-tile/social-comments-tile.component';
import { SocialCommentsComponent } from './components/social-comments/social-comments.component';
import { KalgudiHomeStreamApiService } from './services/kalgudi-home-stream-api.service';
import { KalgudiHomeStreamStateService } from './services/kalgudi-home-stream-state.service';
import { KalgudiHomeStreamService } from './services/kalgudi-home-stream.service';
import { KalgudiQaStreamStateService } from './services/kalgudi-qa-stream-state.service';
import { KalgudiShareUpdateStreamStateService } from './services/kalgudi-share-update-stream-state.service';
import { KalgudiSocialService } from './services/kalgudi-social.service';
import { KalgudiStreamS3FetchService } from './services/kalgudi-stream-s3-fetch.service';
import { KalgudiTranslationListService } from './services/kalgudi-translation-list.service';
import { KalgudiReportAbuseApiService } from './services/report-abuse/report-abuse-api.service';
import { KalgudiReportAbuseService } from './services/report-abuse/report-abuse.service';
import { SocialDataNormalizerService } from './services/social-data-normalizer.service';


@NgModule({
  declarations: [
    PostTileAttachmentsComponent,
    PostTileLinkAttachmentComponent,
    PostTileImageAttachmentComponent,
    SocialCommentsListComponent,
    SocialCommentsFormComponent,
    SocialCommentsTileComponent,
    SocialCommentsComponent,
    ListWidgetComponent,
    SocialAttachmentsComponent,
    ScheduleSendDialogComponent,
    ScheduleSendMobileDialogComponent,
    ScheduleSendComponent,
    AiPredictionsSendSmsDialogComponent,
    AiPredictionsSendSmsMobileDialogComponent,
    AiPredictionsSendSmsComponent
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material Module
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMenuModule,

    FlexLayoutModule,

    // NgxMaterialTimepickerModule,

    AngularSvgIconModule,

    EmbedVideo.forRoot(),


    // Kalgudi third party libraries
    KalgudiCoreModule,
    KalgudiCommonModule,
    KalgudiDirectivesModule,
    KalgudiPipesModule,
    KalgudiPhotoswipeModule,
    KalgudiButtonModule,
    KalgudiImageChipListModule,
    KalgudiGoogleLanguageTranslatorModule,
    KalgudiAttachmentsPickerModule,

    TranslateModule,

    SlickCarouselModule
  ],
  exports: [
    PostTileAttachmentsComponent,
    PostTileLinkAttachmentComponent,
    PostTileImageAttachmentComponent,
    SocialCommentsComponent,
    ListWidgetComponent,
    SocialAttachmentsComponent,
    ScheduleSendDialogComponent,
    ScheduleSendMobileDialogComponent
  ],
  providers: [
    KalgudiHomeStreamService,
    KalgudiHomeStreamApiService,
    KalgudiHomeStreamStateService,
    SocialDataNormalizerService,
    KalgudiReportAbuseApiService,
    KalgudiReportAbuseService,
    KalgudiStreamS3FetchService,
    KalgudiQaStreamStateService,
    KalgudiShareUpdateStreamStateService,
    KalgudiTranslationListService,
    KalgudiSocialService
  ],
  entryComponents: [
    ScheduleSendDialogComponent,
    ScheduleSendMobileDialogComponent,
    AiPredictionsSendSmsDialogComponent,
    AiPredictionsSendSmsMobileDialogComponent,
  ]
})
export class KalgudiSocialModule {
  public static forChild(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiSocialModule> {

    return {
      ngModule: KalgudiSocialModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
