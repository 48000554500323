<main class="add-land-details">
  <form autocomplete="off" fxLayout="column" [formGroup]="landDetailsForm">

    <!-- Tabs -->
    <div>
      <div class="w-100" fxLayout fxLayoutAlign="start center">
        <h3 class="create-boundary-heading mb-3" translate>Mark your farmland on Google map</h3>
      </div>

      <section class="">

        <mat-tab-group class="geo-fence-tabs" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="displayGeotagDialog($event)">

          <!-- Geo fence your land tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.GEO_FENCE.svgIcon"
                [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': 'red' }">
              </svg-icon>
              <span class="title">{{TABS.GEO_FENCE.title | translate}}</span>
            </ng-template>

            <div class="geo-tab-content" *ngIf="activeTabIndex === TABS.GEO_FENCE.index">

              <div class="mt-3" fxLayout="column" *ngIf="activeTabIndex === TABS.GEO_FENCE.index">
                <!-- Land location -->
                <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="100%">
                  <kl-google-places-autocomplete formControlName="location" label="Search Google maps" [placeholder]="'Search location' | translate" (onLocationChange)="locationChanged($event)"></kl-google-places-autocomplete>

                </div>
                <!--/ Land location -->
                <p class="text-danger mb-0 mt-n3 error-message" *ngIf="!currentPosition && !currentLocationProgress && (!landDetails?.landId || isEditLand)">Unable to access your location. Ensure GPS is turned on!</p>

                <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                  <p class="mt-2 mb-1" fxFlex.xs="100%">
                    <span translate>
                      Select

                      <img width="20" height="20" src="assets/images/down-arrow-black.jpg">
                      <span translate>symbol to start drawing the farm boundary</span>
                    </span>
                  </p>
                  <!-- <a (click)="getCurrentLocation(true)" fxFlex.xs="100%" [ngClass]="{'mb-1': isMobileDevice}" *ngIf="landDetails && landDetails?.landId">Use current location</a> -->
                </div>

                <div class="geo-fencing position-relative">

                  <kl-geo-location-marker formControlName="geoFences"
                    [showPin]="showPin"
                    [showDot]="!currentLocation || ((landDetails && landDetails.landId && !isEditLand) ||
                    (locationField.value.longitude !== (currentLocation && currentLocation.longitude ? currentLocation.longitude : locationField.value.longitude) ||
                    locationField.value.latitude !== (currentLocation && currentLocation.latitude ? currentLocation.latitude : locationField.value.latitude))) ? false : true"
                    [location]="locationField?.value">
                  </kl-geo-location-marker>

                  <div class="position-absolute h-100 w-100 loading-text" fxLayout fxLayoutAlign="center center" *ngIf="currentLocationProgress">

                    <p class="text-white mb-0">Loading...</p>
                  </div>
                </div>

              </div>

              <div class="my-1 w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <!-- <div fxLayout fxLayoutGap="10px">

                  <a mat-button (click)="openGpsGeoMarker()" fxHide fxShow.xs>Mark fence while walking</a>

                </div> -->

                <div class="float-right reset-button" fxLayout  fxLayoutAlign="end center"  fxLayoutAlign.xs="end end">
                  <div fxFlex="68px">
                    <kl-link-button
                      (clicked)="resetMap()"
                      [label]="'Reset'">
                    </kl-link-button>
                  </div>
                </div>

                <div *ngIf="locationField?.value && !isMobileDevice">
                  <p>{{drawBoundaryLocationLabel}}</p>
                </div>
              </div>

              <div *ngIf="locationField?.value && isMobileDevice">
                <p>{{drawBoundaryLocationLabel}}</p>
              </div>

              <!-- <p class="mt-1 mb-0 info-text" *ngIf="(!geoFences.value || !geoFences.value.length) && hideAddButton">
                Please fence your land for better recommendation
              </p> -->

              <div fxLayout fxLayoutAlign="center center" class="w-100 my-2">
                <ng-container *ngIf="landSizeUnit?.value as _landSizeUnit">
                  <!-- <p class="mb-2 mt-n3 area-calc-link"
                    *ngIf="geoFences?.value?.length"
                    (click)="calculateArea()"
                    title="Calculates area in {{_landSizeUnit}} from geo-fence coordinates">
                    <a>Calculate area in <span>{{_landSizeUnit}}</span></a>
                  </p> -->
                  <button mat-flat-button color="primary"  title="Calculates area in {{_landSizeUnit}} from geo-fence coordinates"
                    class="mt-0 calculate-button"
                    [disabled]="!geoFences?.value?.length"
                    (click)="calculateArea()">
                    <span translate>Calculate</span>
                    <!-- <span *ngIf="_landSizeUnit">in {{_landSizeUnit}}</span> -->
                  </button>
                </ng-container>
              </div>
            </div>

          </mat-tab>
          <!-- / Geo fence your land tab -->

          <mat-tab>
            <ng-template mat-tab-label>
              <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.GEO_WALK.svgIcon"
                [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': 'red' }">
              </svg-icon>
              <span class="title">{{TABS.GEO_WALK.title | translate}}</span>
            </ng-template>

            <ng-container *ngIf="activeTabIndex === TABS.GEO_WALK.index">
              <div class="gps-geo-tab">
                <kl-gps-geo-marker (fenceUpdated)="onFenceUpdate($event)" [showDot]="true">
                </kl-gps-geo-marker>
              </div>

              <div fxLayout fxLayoutAlign="center center" class="w-100 mb-2 mt-n1">
                <ng-container *ngIf="landSizeUnit?.value as _landSizeUnit">
                  <!-- <p class="mb-2 mt-n3 area-calc-link"
                    *ngIf="geoFences?.value?.length"
                    (click)="calculateArea()"
                    title="Calculates area in {{_landSizeUnit}} from geo-fence coordinates">
                    <a>Calculate area in <span>{{_landSizeUnit}}</span></a>
                  </p> -->
                  <button mat-flat-button color="primary"  title="Calculates area in {{_landSizeUnit}} from geo-fence coordinates"
                    class="mt-0 calculate-button"
                    [disabled]="!geoFences?.value?.length"
                    (click)="calculateArea()">
                    <span translate>Calculate</span>
                    <!-- <span *ngIf="_landSizeUnit">in {{_landSizeUnit}}</span> -->
                  </button>
                </ng-container>
              </div>
            </ng-container>
          </mat-tab>

          <!-- Geo tag your land tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.GEO_TAG.svgIcon"
                [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': 'red' }">
              </svg-icon>
              <span class="title">{{TABS.GEO_TAG.title | translate}}</span>
            </ng-template>
            <div class="geo-fencing geo-tag" *ngIf="activeTabIndex === TABS.GEO_TAG.index">
              <kl-geo-tag
                [showError]="!currentPosition && !currentLocationProgress"
                [locationLabel]="locationLabel"
                [location]="currentLocation"
                (geoTagChanged)="geoTagChanged($event)"
                [LabelPositionTop]="true"
                [markerDraggable]="true">
              </kl-geo-tag>
            </div>
          </mat-tab>
          <!-- / Geo tag your land tab -->

        </mat-tab-group>
      </section>

    </div>
    <!--/ Tabs -->

    <div class="mt-2 pb-0" fxLayout="row wrap" fxLayoutGap="10px grid">

      <div class="w-100 mr-0" *ngIf="!returnGeoTagDetails">
        <mat-form-field appearance="outline" class="w-100 mat-form-field-sm">
          <mat-label translate>Farm land name</mat-label>
        <input matInput required formControlName="landName" placeholder="Enter a rememberable name of the farm/plot">
          <mat-error translate class="mb-1">Please enter the land name</mat-error>
        </mat-form-field>
      </div>

      <!-- Land size | Size in -->
      <div class="mb-0" fxFlex="calc(50% - 10px)" fxFlex.xs="100%" formGroupName="landSize" *ngIf="!returnGeoTagDetails">
        <div fxLayout="row">
          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline" class="no-right-border mat-form-field-sm">
              <mat-label translate>Land size</mat-label>
              <input matInput type="number" required formControlName="value">
              <mat-error translate>Please enter the land size</mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline" class="no-left-border mat-form-field-sm selection">
              <mat-label translate>Unit</mat-label>
              <mat-select formControlName="unit" required>
                <mat-option value="option" *ngFor="let size of sizeInList" [value]="size?.id">{{ size?.value | translate }}</mat-option>
              </mat-select>
              <mat-error translate>Please select the unit</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!--/ Land size | Size in -->

      <!-- Water level -->
      <div class="mb-0"  fxFlex="calc(50% - 10px)" fxFlex.xs="100%" formGroupName="waterLevel" *ngIf="!returnGeoTagDetails">
        <div fxLayout="row">
          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline" class="mat-form-field-sm no-right-border">
              <mat-label translate>Ground water level</mat-label>
              <input matInput formControlName="value" type="number">
            </mat-form-field>
          </div>


          <div fxLayout="column" fxFlex="50%">
            <mat-form-field appearance="outline" class="mat-form-field-sm no-left-border selection">
              <mat-label translate>Unit</mat-label>
              <mat-select formControlName="unit">
                <mat-option value="option" *ngFor="let unit of waterLevelUnitList" [value]="unit?.id">{{ unit?.value | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </div>
      <!--/ Water level -->

      <!-- Soil type -->
      <div class="mb-0" fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="!returnGeoTagDetails">
        <mat-form-field appearance="outline" class=" mat-form-field-sm selection">
          <mat-label translate>Soil type</mat-label>
          <mat-select formControlName="soilType">
            <mat-option value="option" *ngFor="let type of soilTypes" [value]="type?.id">{{ type?.value | translate}}</mat-option>
          </mat-select>
          <mat-error translate>Please select the soil type</mat-error>
        </mat-form-field>
      </div>
      <!--/ Soil type -->

      <!-- Irrigation type -->
      <div class="mb-0" fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="!returnGeoTagDetails">
        <mat-form-field appearance="outline" class=" mat-form-field-sm selection">
          <mat-label translate>Irrigation type</mat-label>
          <mat-select formControlName="irrigationType">
            <mat-option value="option" *ngFor="let type of irrigationTypes" [value]="type?.id">{{ type?.value | translate}}</mat-option>
          </mat-select>
          <mat-error translate>Please select the irrigation type</mat-error>
        </mat-form-field>
      </div>
      <!--/ Irrigation type -->

      <!-- Salinity -->
      <div class="mb-0" fxLayout="column" fxFlex="0 0 50%" *ngIf="!returnGeoTagDetails">
        <mat-form-field appearance="outline" class=" mat-form-field-sm selection">
          <mat-label translate>Salinity</mat-label>
          <mat-select formControlName="salinity">
            <mat-option value="option" *ngFor="let type of salinityTags" [value]="type?.id">{{ type?.value | translate}}</mat-option>
          </mat-select>
          <mat-error translate>Please select the Salinity</mat-error>
        </mat-form-field>
      </div>
      <!--/ Salinity -->

      <!-- Salinity level -->
      <div class="mb-0" fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="salinity?.value === 'YES'">
        <mat-form-field appearance="outline" class=" mat-form-field-sm selection">
          <mat-label translate>Salinity level</mat-label>
          <mat-select formControlName="salinityLevel">
            <mat-option value="option" *ngFor="let type of salinityLevelTags" [value]="type?.id">{{ type?.value | translate}}</mat-option>
          </mat-select>
          <mat-error translate>Please select the Salinity level</mat-error>
        </mat-form-field>
      </div>
      <!--/ Salinity level -->

      <!-- Land cover types -->
      <div class="mb-0" fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="env?.appId === 'GEOAGROPRO'">
        <mat-form-field appearance="outline" class=" mat-form-field-sm selection">
          <mat-label translate>Land cover types</mat-label>
          <mat-select formControlName="landCoverType">
            <mat-option value="option" *ngFor="let type of landCoverTypes" [value]="type?.id">{{ type?.value | translate}}</mat-option>
          </mat-select>
          <mat-error translate>Please select the land cover type</mat-error>
        </mat-form-field>
      </div>
      <!--/ Land cover types -->

      <!-- Field notes -->
      <div class="mb-0" fxLayout="column" fxFlex="calc(100% - 10px)" *ngIf="env?.appId === 'GEOAGROPRO'">
        <mat-form-field appearance="outline" class="w-100">
          <!-- <mat-label translate>Field notes</mat-label> -->
          <textarea matInput [placeholder]="'Field notes' | translate" formControlName="fieldNotes"></textarea>
          <mat-error translate>Please enter field notes</mat-error>
        </mat-form-field>
      </div>
      <!--/ Field notes -->
    </div>



    <div class="mt-2 mb-1" *ngIf="landAttachments?.value && landAttachments?.value?.length" fxLayout="row wrap"  fxLayoutGap="10px grid">

      <ng-container *ngFor="let attachment of landAttachments?.value">
        <div class="position-relative" fxLayout="row wrap" fxFlex="0 0 33%" fxFlex.xs="0 0 50%">
          <div class="w-100">
            <div class="font-weight-bold position-absolute latitude-long px-2 py-1 text-white" fxLayout="column" *ngIf="attachment?.geoLocation" fxFlex="0 0 calc(100% - 10px)">
              <span>Latitude : {{ attachment?.geoLocation?.latitude }}
                <span *ngIf="attachment?.geoLocation?.longitude">,</span>
              </span>
              <span>Longitude: {{ attachment?.geoLocation?.longitude }}</span>
            </div>

            <img class="chip-image w-100"
              [src]="attachment?.url | prefixDomain"
              >
          </div>
        </div>
      </ng-container>
    </div>

    <!-- Image attachments -->
    <div class="mt-n2 mb-n3" *ngIf="soilHealthCardField && soilHealthCardField?.length">
      <kl-image-chip-list
        [isTruncate]="true"
        [attachments]="soilHealthCardField"
        (remove)="removeFiles($event)">
      </kl-image-chip-list>
    </div>
    <!-- / Image attachments -->

    <section class="w-100" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">

      <!-- Attach button -->
      <div class="mt-n1" fxLayoutGap="20px" fxLayoutGap.xs="0px"
          fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutAlign="space-between">

        <div fxLayout="row">
          <div>
            <button mat-button
              class="attach-files"
              id="attach-files-button"
              color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>attachment</mat-icon>
              <span class="ml-1" translate>Attach</span>
            </button>

            <mat-menu #menu="matMenu" class="attach-file">
              <ng-container>

                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Image</p>
                    </div>

                    <div fxLayout="row">
                      <div>
                        <kl-camera-form-control
                          fxFlex="95%"
                          [formControl]="cameraAttachment"
                          [showChipButton]="true"
                          [showIconButton]="true"
                          [showBorder]="false"
                          buttonIcon="camera_alt"
                          buttonLabel="Camera"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-camera-form-control>
                      </div>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="attachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>
              </ng-container>
            </mat-menu>
          </div>

          <!-- Soil health card -->
          <div fxLayout class="add-soil-health cursor-pointer position-relative w-100 mt-0 mx-3" *ngIf="!returnGeoTagDetails">
            <input  class="overflow-hidden position-absolute attach-pdf cursor-pointer w-100"
            type="file" accept="application/pdf,image/*" (change)="onFileSelection($event)" (click)="$event.target.value = null">

            <div class="attach-document" fxLayout="column" fxLayoutAlign="center center">
              <div mat-button fxLayout fxLayoutAlign="center center">
                <svg-icon class="mr-1" src="assets/svgs/attach-file/file-1.svg" [applyCss]=true [svgStyle]="{'width.px': 16, 'height.px': 16 }"></svg-icon>
                <span fxLayout="column">
                  <span class="soil-health-title" translate>Soil health card</span>
                  <!-- <span class="upload-pdf mt-n1 text-danger">Upload PDF format only</span> -->
                </span>
              </div>
            </div>
          </div>
          <!--/ Soil health card -->
        </div>

      </div>
      <!--/ Attach button -->

      <!-- Add button -->
      <div class="mt-2 add-btn" fxLayoutAlign="end end" fxLayoutAlign.xs="start" *ngIf="!hideAddButton">

        <kl-flat-button
          [label]="landDetails && landDetails?.landId ? 'Update' : 'Add' | translate"
          [progress]="progress"
          [disabled]="returnGeoTagDetails ? !geoTagInfo : !landDetailsForm?.valid"
          (clicked)="addLandDetails()">
        </kl-flat-button>
      </div>
      <!-- / Add button -->
    </section>

  </form>
</main>
