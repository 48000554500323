import { Directive, Inject, Injector, Input } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KALGUDI_S3_POLICY_MAP, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, KalgudiDialogResult, KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { AllLocationsDialogComponent } from './all-locations-dialog/all-locations-dialog.component';
import { AllLocationsMobileDialogComponent } from './all-locations-mobile-dialog/all-locations-mobile-dialog.component';


/**
 * Base class for Kalgudi programs header section. Defines common functions.
 *
 * @author Ashwini Vadde
 */
@Directive()
export abstract class KalgudiProgramProfileHeader extends KalgudiDestroyable {

  @Input()
  pageDetails: KalgudiPageDetails;

  private dialogService: KalgudiDialogsService;

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  allLocations: any;


  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected pagesService: KalgudiPageService,
    protected injector: Injector
  ) {
    super();

    this.dialogService  = this.injector.get(KalgudiDialogsService);
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Uploads the file to the s3
   * @param fileEvent
   */
  uploadProfilePic(fileEvent: any) {

    if (!fileEvent.target.files || !fileEvent.target.files[0]) {
      return;
    }

    const path = KALGUDI_S3_POLICY_MAP.PROFILE_PIC;

    this.notifications.showSpinner();

    this.pagesService.updateProfilePic(fileEvent.target.files[0], path, this.pageDetails)
      .pipe(
        finalize(() => this.notifications.hideSpinner()),

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.onProfilePicUpdate(res),
        err => this.onProfilePicUpdateError(err)
      );
  }

  /**
   * Fetch all locations
   */
  fetchLocations() {

    this.pagesService.fetchAllLocations(this.pageDetails.pageId)
      .pipe(
        finalize(() => this.notifications.hideSpinner()),
      )
      .subscribe(
        res => this.showLocations(res),
        err => this.showLocationsError(err)
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private methods
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed.
   */
  protected abstract onDestroyed(): void;

  /**
   * Event handler for API error.
   */
  protected onProfilePicUpdateError(err: Error): void {

    this.notifications.showMessage(err.message);
  }

  /**
   * Event handler Fetch all locations for API error.
   */
  protected showLocationsError(err: Error): void {

    this.notifications.showMessage(err.message);
  }

  /**
   * Called after Fetch all locations is updated successfully.
   */
  protected showLocations(res) {

    this.allLocations = res.locations;

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Locations',
      data: this.allLocations
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.openAllLocationsDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(r => r.accepted),
        map(r => r.accepted),
      );
  }

  /**
   * All locations dialog box
   */
  private openAllLocationsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(AllLocationsMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(AllLocationsDialogComponent, dialogConfig, matDialogConfig);
    }
  }


  /**
   * Called after profile pic is updated successfully.
   */
  protected onProfilePicUpdate(res: KalgudiPageDetails): void {}

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
