import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiBottomSheetService } from './kalgudi-bottom-sheet.service';
import { MobileMenuBottomSheetComponent } from './mobile-menu-bottom-sheet.component';



@NgModule({
  declarations: [
    MobileMenuBottomSheetComponent
  ],
  imports: [
    AngularSvgIconModule,

    CommonModule,

    // Material module
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,

    FlexLayoutModule,

    TranslateModule
  ],
  exports: [
    MobileMenuBottomSheetComponent
  ],
  entryComponents: [
    MobileMenuBottomSheetComponent,
  ],
  providers: [
    KalgudiBottomSheetService
  ]
})
export class MobileMenuBottomSheetModule { }
