import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';

import { AgGridAnchorRendererComponent } from './components/ag-grid-anchor-renderer/ag-grid-anchor-renderer.component';
import { AgGridDateRendererComponent } from './components/ag-grid-date-renderer/ag-grid-date-renderer.component';
import {
  AgGridLoadingCellRendererComponent,
} from './components/ag-grid-loading-cell-renderer/ag-grid-loading-cell-renderer.component';
import {
  AgGridQuantityUnitRendererComponent,
} from './components/ag-grid-quantity-unit-renderer/ag-grid-quantity-unit-renderer.component';
import { AgGridSlNoRendererComponent } from './components/ag-grid-sl-no-renderer/ag-grid-sl-no-renderer.component';
import { AgGridYesNoRendererComponent } from './components/ag-grid-yes-no-renderer/ag-grid-yes-no-renderer.component';
import { CurrencyFormatter } from './formatters/currency-formatter';
import { DateFormatter } from './formatters/date-formatter';
import { AgGridFormatters } from './formatters/formatters-constants';
import { SpecialCharacterFormatter } from './formatters/special-character-formatter';
import { AgGridMapperService } from './services/ag-grid-mapper.service';


@NgModule({
  declarations: [
    AgGridDateRendererComponent,
    AgGridSlNoRendererComponent,
    AgGridLoadingCellRendererComponent,
    AgGridQuantityUnitRendererComponent,
    AgGridYesNoRendererComponent,
    AgGridAnchorRendererComponent,
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,

    AgGridModule.withComponents([
      AgGridDateRendererComponent,
      AgGridSlNoRendererComponent,
      AgGridLoadingCellRendererComponent,
      AgGridYesNoRendererComponent,
      AgGridQuantityUnitRendererComponent,
      AgGridAnchorRendererComponent,
    ])
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    AgGridMapperService,
    {
      provide: AgGridFormatters.AG_GRID_DATE_FORMATTER,
      useClass: DateFormatter,
    },
    {
      provide: AgGridFormatters.AG_GRID_CURRENCY_FORMATTER,
      useClass: CurrencyFormatter,
    },
    {
      provide: AgGridFormatters.AG_GRID_TRIM_SPECIAL_CHARACTER_FORMATTER,
      useClass: SpecialCharacterFormatter,
    }
  ],
  exports: [
    // Modules
    AgGridModule,

    // Components
    AgGridSlNoRendererComponent,
    AgGridDateRendererComponent,
    AgGridLoadingCellRendererComponent,
    AgGridQuantityUnitRendererComponent,
    AgGridYesNoRendererComponent,
    AgGridAnchorRendererComponent,
  ],
  entryComponents: [
    AgGridSlNoRendererComponent,
    AgGridDateRendererComponent,
    AgGridLoadingCellRendererComponent,
    AgGridQuantityUnitRendererComponent,
    AgGridYesNoRendererComponent,
  ]
})
export class StoreAdminAgGridModule { }
