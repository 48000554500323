import { Directive, Inject, Input } from '@angular/core';
import { KalgudiUsersPickerBase } from '@kalgudi/common';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { StringAnyMap } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';

import { KalgudiProgramSearchService } from '../../../services/kalgudi-program-search.service';

/**
 * My kalgudi connects search picker. It searches for all connected users in the kalgudi database.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiUsersPickerBase
 * @see KalgudiSearchStream
 * @see KalgudiStream
 */
@Directive()
export abstract class KalgudiProgramMembersPicker extends KalgudiUsersPickerBase {

  @Input()
  pageId: string;
  selectedGender: any;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProgramSearchService,
  ) {

    // Wake up (initialize) parent
    super(notification, util);

    this.initStream();
  }

  /**
   * Select gender method
   */
  onItemChange(event){
    this.selectedGender = event.value;

    this.searchForm.reset();
    this.setStreamItems([]);
  }

  /**
   * Defines the Api call for my kalgudi connects. If the search keyword is provided then it calls
   * the Api to search my connects otherwise it loads the list of all my connects.
   *
   * It chooses the Api call based on the search keyword.
   */
  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserBasicSearchResults> {
    console.log('inside api', this.pageId, searchKeyword)
    return this.pageId
      ? this.api.searchMembers(this.pageId, searchKeyword, offset, limit, this.selectedGender)
      : EMPTY; // Don't call the search Api if page id is not present
  }
}
