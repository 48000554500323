import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-about-org-tile',
  templateUrl: './profile-about-org-tile.component.html',
  styleUrls: ['./profile-about-org-tile.component.scss']
})
export class ProfileAboutOrgTileComponent implements OnInit {

  @Output()
  openTCDialog = new EventEmitter();

  @Input()
  user: KalgudiUser;

  @Input()
  editFlag;

  @Input()
  editable: boolean;

  @Output()
  showEdit = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Closes the edit form after successful response
   */
  closeEditForm() {
    this.editFlag = !this.editFlag;
    this.showEdit.emit(this.editFlag);
  }

  /**
   * Shows terms and conditions Dialog
   */
  showTermsAndConditionsDialog(event: any) {
    this.openTCDialog.emit();
  }

}
