import { animate, state, style, transition, trigger } from '@angular/animations';

/**
 * Array of all kalgudi angular animations
 *
 * Animation delay tricks https://stackoverflow.com/a/53644952/2401088
 */

export const slideRightAnimation = trigger('slideRight', [
  transition(':enter', [
    style({
      transform: 'translateX(-10%)',
      opacity: '0.3'
    }),
    animate('250ms', style({
      transform: 'translateX(0%)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('250ms', style({
      transform: 'translateX(-10%)',
      opacity: '0.3'
    }))
  ])
]);



export const floatTopAnimation = trigger('floatTop', [
  transition(':enter', [
    style({
      transform: 'translateY(10px)',
      opacity: '0'
    }),
    animate('200ms {{delay}}ms', style({
      transform: 'translateY(0px)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'translateY(10px)',
      opacity: '0'
    }))
  ])
]);


export const floatRightAnimation = trigger('floatRight', [
  transition(':enter', [
    style({
      transform: 'translateX(-10px)',
      opacity: '0'
    }),
    animate('200ms {{delay}}ms', style({
      transform: 'translateX(0px)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'translateX(-10px)',
      opacity: '0'
    }))
  ])
]);


export const scaleOutAnimation = trigger('scaleOut', [
  transition(':enter', [
    style({
      transform: 'scale(0.6)',
      opacity: '0'
    }),
    animate('200ms', style({
      transform: 'scale(1)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'scale(0.6)',
      opacity: '0'
    }))
  ])
]);


export const scaleOutIconAnimation = trigger('scaleOutIcon', [
  // transition(':enter', [
  //   style({
  //     transform: 'scale(0.6)',
  //     opacity: '0'
  //   }),
  //   animate('200ms', style({
  //     transform: 'scale(1)',
  //     opacity: '1'
  //   }))
  // ]),
  // transition(':leave', [
  //   animate('200ms', style({
  //     transform: 'scale(0.6)',
  //     opacity: '0'
  //   }))
  // ]),


  state('true', style({
    transform: 'scale(0.4)',
    opacity: '0'
  })),
  state('*', style({
    transform: 'scale(1)',
    opacity: '1'
  })),

  transition('* <=> *', animate(800)),
  // transition('old  => new', animate(200)),
]);


export const topFloatingCardAnimation = trigger('topFloatingCard', [
  transition(':enter', [
    style({
      transform: 'translateY(30px)',
      opacity: '0'
    }),
    animate('200ms', style({
      transform: 'translateY(0px)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'translateY(30px)',
      opacity: '0'
    }))
  ])
]);


export const bottomFloatingCardAnimation = trigger('bottomFloatingCard', [
  transition(':enter', [
    style({
      transform: 'translateY(-10px)',
      opacity: '0'
    }),
    animate('200ms', style({
      transform: 'translateY(0px)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'translateY(-10px)',
      opacity: '0'
    }))
  ])
]);


export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('300ms', style({
      opacity: 1
    }))
  ]),
  transition(':leave', [
    animate('300ms', style({
      opacity: 0
    }))
  ])
]);


export const zoomOutAnimation = trigger('zoomOut', [
  state('*', style({
  })),
  state('zoomOut', style({
    transform: 'scale(0)',
    maxWidth: '0px',
    maxHeight: '0px',
    margin: '0px !important'
  })),

  transition('* => zoomOut', animate(200))
]);


export const slideToggleAnimation = trigger('slideToggle', [

  state('false', style({
    left: '-3px',
    right: '*',
  })),

  state('true', style({
    right: '-3px',
    left: '*'
  })),

  transition('* <=> *', animate(100))
]);


export const collapsibleMenuAnimation = trigger('collapsibleMenu', [

  state('false', style({
    height: '0px',
    overflow: 'hidden',
  })),

  state('true', style({
    height: '*',
    overflow: 'visible',
  })),

  transition('* <=> *', animate(100))
]);


export const rotateIconAnimation = trigger('rotateIcon', [

  state('false', style({
    transform: 'rotate(0deg)',
  })),

  state('true', style({
    transform: 'rotate(180deg)',
  })),

  transition('* <=> *', animate(200))
]);


export const kalgudiStreamTileAnimation = trigger('kalgudiStreamTile', [
  state('void', style({
    transform: 'translateY(10px)',
    opacity: '0'
  })),

  state('init', style({
    transform: 'translateY(0px)',
    opacity: '1',
    maxWidth: '100%',
    maxHeight: '100%',
  })),

  state('deleted', style({
    opacity: '0',
    maxHeight: '0px',
  })),

  transition('void => init', animate(200)),
  transition('init => deleted', animate(200)),
]);



/**
 * Export list of all animations
 */
export const kalgudiAnimations = [
  slideRightAnimation,
  floatTopAnimation,
  floatRightAnimation,
  scaleOutAnimation,
  scaleOutIconAnimation,
  topFloatingCardAnimation,
  bottomFloatingCardAnimation,
  fadeInAnimation,
  zoomOutAnimation,
  slideToggleAnimation,
  collapsibleMenuAnimation,
  rotateIconAnimation,
  kalgudiStreamTileAnimation,
];
