import { Directive, EventEmitter, Inject, Injector, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import {
  checkMobileDevice,
  KalgudiAppService,
  KalgudiUsersService,
  KalgudiUtilityService,
  LikeShareRequest,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiReportAbuseService, KalgudiSocialActions } from '@kalgudi/social';
import { KalgudiDialogConfig, KalgudiDialogResult, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LikesListDialogComponent } from './components/likes-list-dialog/likes-list-dialog.component';
import { LikesListMobileDialogComponent } from './components/likes-list-mobile-dialog/likes-list-mobile-dialog.component';
import { ReShareUpdateDialogComponent } from './components/re-share-update-dialog/re-share-update-dialog.component';
import {
  ReShareUpdateMobileDialogComponent,
} from './components/re-share-update-mobile-dialog/re-share-update-mobile-dialog.component';
import { KalgudiShareUpdateService } from './services/kalgudi-share-update.service';


/**
 * Business logic definition for Kalgudi Share update post tile actions.
 */
@Directive()
export abstract class ShareUpdateActions<T extends ShareUpdate> extends KalgudiSocialActions<T> {


  @Input()
  overRideUpdatePost: (postDetails: T) => Observable<ShareUpdate>;

  @Output()
  liked = new EventEmitter<T>();

  @Output()
  commented = new EventEmitter<T>();

  isLoggedIn: boolean;

  constructor(
    protected sharePost: KalgudiShareUpdateService,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected usersService: KalgudiUsersService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    // Initialize wake up my parent
    super(
      notifications,
      kalgudiDialogs,
      kalgudiApp,
      usersService,
      reportAbuse,
      util,
      injector
    );

    this.isLoggedIn = this.kalgudiApp.loggedIn;

    // Initialize comments form
    this.socialCommentsForm = new FormGroup({
      id: new FormControl(this.isLoggedIn ? this.profile.profileKey : ''),
      name: new FormControl(this.isLoggedIn ? this.profile.firstName : ''),
      shareId: new FormControl(''),
      text: new FormControl(''),
      type: new FormControl('comment')
    });

  }

  /**
   * Opens the share update form in update mode
   */
  updateScheduledPost() {

    if (this.overRideUpdatePost) {
      this.overRideUpdatePost(this.postDetails).subscribe(
        res => {
          this.notifications.showMessage('Post updated');
          this.onPostUpdated(res);
        },
        err => this.util.errorHandler(err)
      );
    } else {

      // Input dialog UI configuration
      const dialogDetails: KalgudiDialogConfig = {
        title: 'Update post',
        acceptButtonTitle: 'Send',
        rejectButtonTitle: 'Cancel',
        data: {
          postDetails: this.postDetails
        }
      };

      // Material dialog configuration
      const dialogConfig: MatDialogConfig = {
        width: '700px',
        maxWidth: '700px',
        hasBackdrop: true,
        disableClose: true,
        autoFocus: false,
      };

      this.sharePost.showShareUpdateDialog(dialogDetails, dialogConfig)
        .pipe(
          filter(res => res && res.accepted)
        )
        .subscribe(
          res => {
            this.notifications.showMessage('Post updated');
            this.onPostUpdated(res);
          },
          err => this.util.errorHandler(err)
        );
    }

  }

  /**
   * To open the likes list dialog
   */
  openLikesListDialog() {
    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Likes list',
      data: {
        id: this.postDetails.shareId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    // Show likes list dialog
    return this.showLikesListDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Shows input dialog and returns string.
   */
  showShareUpdateDialog(postDetails: any) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Share an update',
      data: {
        postDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'livestock-fisheries',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        postDetails
      }
    };

    return this.openReShareUpdateDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * calls after updating the post successfully
   */
  protected onPostUpdated(res): void {}
  /**
   * Calls API to delete post.
   */
  protected deletePostApi(): Observable<boolean> {

    // Call api to delete post
    return this.sharePost.deletePost(this.postDetails.shareId);
  }

  /**
   * Implement like post Api call
   */
  protected likePostApi(payload: LikeShareRequest): Observable<T> {

    // Call api to share post
    return this.sharePost.like(payload)
      .pipe(

        // Map response to generic type
        map(r => r as T)
      );
  }

  /**
   * Implement comment post api call
   */
  protected commentPostApi(payload: LikeShareRequest): Observable<T> {

    return this.sharePost.comment(this.postDetails.shareId, payload)
      .pipe(

        // Map response to generic type
        map(r => r as T)
      );
  }

  /**
   * Prepares the like payload
   */
  protected getLikePayload(): LikeShareRequest {

    return {
      id: this.isLoggedIn ? this.kalgudiApp.profileLocal.profileKey : '',
      name: this.isLoggedIn ? this.kalgudiApp.profileLocal.firstName : '',
      shareId: this.postDetails.shareId,
      text: '',
      type: 'like'
    };
  }

  /**
   * Gets, the comment payload. It will fetch all fetch all values from the
   * comments form.
   */
  protected getCommentsPayload(): LikeShareRequest {
    const payload = this.util.clone<LikeShareRequest>(this.commentsForm.value);

    // Append share id to the share payload
    payload.shareId = this.postDetails.shareId;

    return payload;
  }

  /**
   * Implement operation to be performed on successful post like.
   */
  protected onPostLiked(res: T): void { }

  /**
   * Show likes list dialog
   */
  private showLikesListDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.kalgudiDialogs.openMobileDialog(LikesListMobileDialogComponent, dialogConfig);
    } else {

      return this.kalgudiDialogs.openDialog(LikesListDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  /**
   * Open re share update dialog
   */
  private openReShareUpdateDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.kalgudiDialogs.openMobileDialog(ReShareUpdateMobileDialogComponent, dialogConfig);
    } else {
      return this.kalgudiDialogs.openDialog(ReShareUpdateDialogComponent, dialogConfig, matDialogConfig);

    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
