<main class="common-details">
  <form autocomplete="off"
  [ngClass]="{'col-10': !isMobileDevice}"
    class="mb-4 mx-auto mt-3 create-project common-info"
    [formGroup]="projectForm" fxLayout="column" fxLayoutGap="10px">

    <!-- Project title -->
    <div>
      <mat-form-field appearance="outline" class="w-100 project-form">
        <mat-label translate>Project Title</mat-label>
        <input matInput formControlName="title" required/>
      </mat-form-field>
    </div>
    <!-- / Project title -->

    <!-- Project description -->
    <div>
      <mat-form-field appearance="outline" class="w-100 project-form">
        <mat-label translate>Description</mat-label>
        <textarea rows="3" matInput formControlName="description" required></textarea>
      </mat-form-field>
    </div>
    <!-- / Project description -->

    <!-- <span class="text-muted" translate>Attachments</span> -->

    <!-- Attach image button -->
    <div class="attach-buttons" fxLayout="row" fxLayoutAlign="start center">

      <!-- <button [title]="'Attach image' | translate" type="button" mat-icon-button fxLayoutAlign="center center" (click)="attachImage()">
        <mat-icon class="image">photo</mat-icon>
      </button> -->
      <kl-image-picker-form-control formControlName="attachments"
        [allowMultiple]="true"
        [showAttachments]="false"
        [acceptedFileTypes]="acceptedFileTypes">
      </kl-image-picker-form-control>
    </div>
    <!-- / Attach image button -->

    <!-- Image attachments -->
    <kl-image-chip-list [attachments]="attachmentsField?.value" (remove)="removeImage($event)">
    </kl-image-chip-list>
    <!-- / Image attachments -->

  </form>
</main>
