import { Injector, Directive, Input } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { IdValueMap, PageType, ProcessingPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { ProgramStateService } from '../../../../services/program-state.service';

@Directive()
export abstract class ActivityTags extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  tagType: PageType;

  disabled = false;

  shareTags$: Observable<IdValueMap[]>;

  private kalgudiPageService: KalgudiPageService;
  private kalgudiPageState: ProgramStateService;

  tagsForm = new FormControl([]);

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.kalgudiPageService = this.injector.get(KalgudiPageService);
    this.kalgudiPageState   = this.injector.get(ProgramStateService);


    this.shareTags$ = this.kalgudiPageState.data$
      .pipe(
        switchMap(page => {

          let productId = '';

          try {
            if (page.pageType === 'STOCK') {
              productId = page.product.productId;
            } else if (page.pageType === 'PROCESSING') {
              productId = (<ProcessingPageDetails>page).stockProductDetails.productId;
            }
          } catch (e) {
            console.info('Unable to get product id for the page.');
          }


          return this.kalgudiPageService.getPageShareTags(this.tagType ? this.tagType : page.pageType, productId);
        }),
      );

    this.tagsForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(value => this.updateFormControlValue(value));
  }

  onDestroyed(): void {}



  // --------------------------------------------------------
  // #region Getters and setters
  // --------------------------------------------------------

  /**
   * Tags list
   */
  get tagsFormValue(): string[] {
    return this.tagsForm.value || [];
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.tagsForm.patchValue(obj);
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------




  /**
   * Event handler for form control value update in the parent
   */
  updateFormControlValue(item: string) {

    const arr = [item];
    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(arr);
    this.onTouched();
  }
}
