<div id="kalgudi-confirm-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">

  <!-- dialog header -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!-- /dialog header -->

  <!--dialog content-->
  <div class="dialog-content">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div>
        <mat-icon class="icon-size" [color]="data?.iconColor">{{data.matIcon}}</mat-icon>
      </div>
      <div>
        <p class="message mb-2 text-center" translate>{{data.message}}</p>
      </div>
    </div>

    <!-- buttons -->
    <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <button mat-button color="primary" class="p-0 reject-button" (click)="cancel()">{{data?.rejectButtonTitle | translate}}</button>
      <button mat-button color="warn" class="p-0 accept-button" (click)="ok()">{{data?.acceptButtonTitle | translate}}</button>
    </div>

  </div>
  <!-- /dialog content -->
</div>