<section id="da-fullview">

  <ng-container *ngIf="(activitiesStats$ | async) as activitiesStats">
    <div>
      <kl-digital-assistance-stats
        [stats]="activitiesStats"
        [menu]="digitalAssistMenu">
      </kl-digital-assistance-stats>
    </div>
  </ng-container>

  <ng-container *ngIf="(daActionPerformed$ | async) as daActions">

    <div *ngIf="daActions === 'SHAREATHOUGHT'">
      <kl-digital-assist-share-update (postCreated)="onPostCreated($event)">
      </kl-digital-assist-share-update>
    </div>

    <div *ngIf="daActions === 'QA'">
      <kl-digital-assist-qa (qaCreated)="onPostCreated($event)">
      </kl-digital-assist-qa>
    </div>

    <!-- Show the 'kl-digital-assist-training' component only when 'daActions' is 'TRAINING'.  -->
    <div *ngIf="daActions === 'TRAINING'">
      <kl-digital-assist-training (trainingCreated)="onPostCreated($event)"></kl-digital-assist-training>
    </div>

    <div *ngIf="daActions === 'PROFILE_UPDATE'">
      <kl-digital-assist-profile-update></kl-digital-assist-profile-update>
    </div>

    <div *ngIf="daActions === 'PROFILE_LOGS'">
      <kl-digital-assist-profile-logs></kl-digital-assist-profile-logs>
    </div>

    <div *ngIf="daActions === 'TASKS'">
      <kl-digital-assist-tasks></kl-digital-assist-tasks>
    </div>

  </ng-container>

  <ng-container  *ngIf="(stream$ | async) as stream">
    <div>
      <kl-assistance-stream [stream]="stream">
      </kl-assistance-stream>
    </div>

    <!-- No content if there is no assist members -->
    <div class="w-100" fxLayout="row" *ngIf="(paginator$ | async) as paginator">
      <ng-container *ngIf="!stream?.length">
        <div class="w-100 no-content-center" fxLayoutAlign="center center">
          <kl-no-content svgIcon="assets/svgs/digital-assist.svg"
            text="No assistance done yet!"
            iconSize="md"
            progressText="Loading assistance done by you..."
            [progress]="paginator?.loading">
          </kl-no-content>
        </div>
      </ng-container>
    </div>
    <!--/ No content if there is no assist members -->

  </ng-container>
</section>
