<div class="pr-2 post-tile-image" fxLayout="column" fxLayoutGap="10px" (click)="openFullview(); $event.stopPropagation()">

  <div *ngIf="false">
    <div class="image image-gallery cursor-pointer position-relative w-100"
    *ngIf="attachments && attachments?.length">
      <img width="800" height="320" class="mw-100 attachments" [src]="attachments[0]?.url | prefixDomain | resizeImage:'md'" alt="Kalgudi core images" #img (error)="img.src = env.domain + attachments[0]?.url ">

      <!-- <div class="background-image"
        [style.background-image]="'url('+ (attachments[0]?.url | resizeImage: 'md' | prefixDomain) +')'" >
      </div> -->

      <!-- Number of images -->
      <div *ngIf="attachments?.length > 1 && !attachments[0]?.geoLocation"
        class="position-absolute img-list text-white"
        fxLayout="row"
        fxLayoutAlign="center center" fxLayoutGap="8px">
        <p class="mb-0 img-count font-weight-bold">{{ attachments?.length - 1 }}</p>
        <p class="mb-0 more" translate>More</p>
      </div>
      <!-- Number of images -->

      <div class="font-weight-bold position-absolute latitude w-100" fxLayout="row" fxLayoutGap="8px" *ngIf="attachments[0]?.geoLocation">
        <!-- Number of images -->
        <div class="font-weight-bold px-2 py-1 latitude-long text-white" fxLayout="column" fxFlex="calc(80% - 10px)" [ngClass]="{'latitude-longitude' : attachments?.length > 1}">
          <span>Latitude : {{ attachments[0]?.geoLocation?.latitude }}
            <span *ngIf="attachments[0]?.geoLocation?.longitude">,</span>
          </span>
          <span>Longitude: {{ attachments[0]?.geoLocation?.longitude }}</span>
        </div>

        <div *ngIf="attachments?.length > 1"
          class="text-white latitude-long"
          fxLayout="row" fxLayoutAlign="center center"
            fxFlex="0 0 20">
          <p class="mb-0 img-count font-weight-bold">1 / {{ attachments?.length}}</p>
        </div>
        <!-- Number of images -->
      </div>
  </div>

  <div class="image image-gallery cursor-pointer w-100" *ngIf="url">
    <img class="w-100" [src]="url | prefixDomain | resizeImage:'md'">
  </div>
  </div>

  <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" *ngIf="isMobileDevice">
    <div ngxSlickItem *ngFor="let attachment of attachments">

      <div class="background-image images" *ngIf="attachments.length > 1"
        [style.background-image]="'url('+ (attachment?.url | prefixDomain) +')'" >
      </div>
      <div class="single-background-image images" *ngIf="attachments.length === 1"
        [style.background-image]="'url('+ (attachment?.url | prefixDomain) +')'" >
      </div>
    </div>
  </ngx-slick-carousel> -->

  <div *ngIf="!isMobileDevice">
    <div fxLayout="column" *ngIf="attachments.length > 1">
      <div>
        <div class="background-image images" [fxFlex]="(attachments.length >= 2 && attachments.length !== 3 && attachments.length !== 6) ? '50%' : '33.3%'"
          [style.background-image]="'url('+ (attachments[0]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" [fxFlex]="(attachments.length >= 2 && attachments.length !== 3 && attachments.length !== 6) ? '50%' : '33.3%'"
          [style.background-image]="'url('+ (attachments[1]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" *ngIf="((attachments.length > 2) && !(attachments.length === 4 || attachments.length === 5))" fxFlex="33.3%"
          [style.background-image]="'url('+ (attachments[2]?.url | prefixDomain) +')'" >
        </div>
      </div>

      <div *ngIf="attachments.length > 3" class="position-relative">
        <div class="background-image images" *ngIf="(attachments.length === 4 || attachments.length === 5 || attachments.length === 6)" [fxFlex]="(attachments.length === 4) ? '50%' : '33.3%'"
          [style.background-image]="'url('+ (attachments[2]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" [fxFlex]="(attachments.length === 4) ? '50%' : '33.3%'"
          [style.background-image]="'url('+ (attachments[3]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" *ngIf="attachments.length > 4" fxFlex="33.3%"
          [style.background-image]="'url('+ (attachments[4]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" *ngIf="attachments.length > 6" fxFlex="33.3%"
          [style.background-image]="'url('+ (attachments[5]?.url | prefixDomain) +')'">
        </div>
      </div>

      <!-- Number of images -->
      <div *ngIf="attachments?.length > 6"
        class="position-absolute web-img-number"
        fxLayout="row"
        fxLayoutAlign="center center" fxLayoutGap="8px">
        <p class="mb-0 img-count font-weight-bold">{{ attachments?.length - 6 }}</p>
        <p class="mb-0 more" translate>More</p>
      </div>
      <!-- Number of images -->
    </div>

    <div class="single-background-image" *ngIf="attachments.length === 1"
      [style.background-image]="'url('+ (attachments[0]?.url | prefixDomain) +')'" >
    </div>
  </div>

  <div *ngIf="isMobileDevice">
    <div fxLayout="column">
      <div>
        <div class="background-image images" [fxFlex]="(attachments.length > 1 && attachments.length !== 3) ? '50%' : '100%'"
          [style.background-image]="'url('+ (attachments[0]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" fxFlex="50%" *ngIf="attachments[1]?.url && attachments.length !== 3"
          [style.background-image]="'url('+ (attachments[1]?.url | prefixDomain) +')'" >
        </div>
      </div>

      <div *ngIf="attachments.length > 2" class="position-relative">
        <div class="background-image images" fxFlex="50%" *ngIf="attachments[1]?.url && attachments.length === 3"
          [style.background-image]="'url('+ (attachments[1]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" [fxFlex]="(attachments.length > 5 && attachments.length !== 3) ? '33.3%' : '50%'"
          [style.background-image]="'url('+ (attachments[2]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" *ngIf="attachments[3]?.url" [fxFlex]="attachments.length > 5 ? '33.3%' : '50%'"
          [style.background-image]="'url('+ (attachments[3]?.url | prefixDomain) +')'" >
        </div>
        <div class="background-image images" *ngIf="attachments[4]?.url" [fxFlex]="attachments.length > 5 ? '33.3%' : '50%'"
          [style.background-image]="'url('+ (attachments[4]?.url | prefixDomain) +')'" >
        </div>
      </div>

      <!-- Number of images -->
      <div *ngIf="attachments?.length > 5"
        class="position-absolute img-number"
        fxLayout="row"
        fxLayoutAlign="center center" fxLayoutGap="8px">
        <p class="mb-0 img-count font-weight-bold">{{ attachments?.length - 5 }}</p>
        <p class="mb-0 more" translate>More</p>
      </div>
      <!-- Number of images -->
    </div>
  </div>
</div>
