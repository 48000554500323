import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiShareWithApiService {

  /**
   * v2/pages/:pageId
   */
  private readonly API_PAGE_VIEW = `${this.env.restBaseUrlV2}/pages/:pageId`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient : HttpClient,
    private util: KalgudiUtilityService
  ) {
  }

  /**
   * Calls Kalgudi api to get entity details
   * @param pageId Unique id of entity
   * @returns
   */
  getPageId(pageId): Observable<KalgudiPageDetails> {
    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_VIEW.replace(':pageId',pageId))
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data)
      );
  }
}
