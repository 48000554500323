import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiChartItem } from '@kalgudi/types';

import { AllProjectsStats } from '../../classes/all-projects-stats';

@Component({
  selector: 'kl-all-projects-dashboard',
  templateUrl: './all-projects-dashboard.component.html',
  styleUrls: ['./all-projects-dashboard.component.scss']
})
export class AllProjectsDashboardComponent extends AllProjectsStats implements OnInit {

  taskStatsDetails = {
    title: 'Tasks',
    count: 131324,
    statsList: [
      {
        title: 'Completed',
        value: 80,
        bgColor: '#35E298',
      },
      {
        title: 'In progress',
        value: 40,
        bgColor: '#376DD6',
      },
      {
        title: 'Not started',
        value: 60,
        bgColor: '#2858B4',
      }
    ]
  };

  memberStatsDetails = {
    title: 'Members',
    count: 131324,
    statsList: [
      {
        title: 'Admin members',
        value: 8000,
      },
      {
        title: 'Contributor members',
        value: 4000,
      },
    ]
  };

  chipsList: KalgudiChartItem[] = [
    {
      title: 'Completed',
      value: 8000,
      textAlign: 'center',
      bgColor: '#35E298',
      color: '#fff'
    },
    {
      title: 'In progress',
      value: 4000,
      textAlign: 'center',
      bgColor: '#F9C200',
      color: '#fff'
    },
    {
      title: 'Not started',
      value: 4000,
      textAlign: 'center',
      bgColor: '#4BA9FF',
      color: '#fff'
    },
  ];

  statsList: KalgudiChartItem[] = [
    {
      title: 'Crop calenders',
      value: 8000,
      textAlign: 'left',
      color: '#5D7482'
    },
    {
      title: 'Agriculture',
      value: 4000,
      textAlign: 'left',
      color: '#5D7482'
    },
    {
      title: 'Paddy calender',
      value: 4000,
      textAlign: 'left',
      color: '#5D7482'
    },
    {
      title: 'Paddy calender',
      value: 4000,
      textAlign: 'left',
      color: '#5D7482'
    },
  ];

  chartData = [
    {
      name: 'Completed',
      value: 8000
    },
    {
      name: 'In process',
      value: 4000
    },
    {
      name: 'Not started',
      value: 4000
    }
  ];


  // chartDirection: ChartDirection = 'horizontal';


  constructor(protected injector: Injector) {
    super(injector);
   }

  ngOnInit() {
    this.getAllProjectsDashboard();
  }

}
