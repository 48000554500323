import { Component, forwardRef, Injector, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DEFAULT_PROFILE_PIC, fadeInAnimation } from '@kalgudi/core';

import { SpecificMembersVisibility } from './specific-members-visibility';


// Page tags NG_VALUE_ACCESSOR provider for custom form control
const SPECIFIC_MEMBER_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SpecificMemberVisibilityComponent),
  multi: true,
};


@Component({
  selector: 'kl-specific-member-visibility',
  templateUrl: './specific-member-visibility.component.html',
  styleUrls: ['./specific-member-visibility.component.scss'],
  providers: [ SPECIFIC_MEMBER_FORM_CONTROL_PROVIDER ],
  animations: [ fadeInAnimation ]
})
export class SpecificMemberVisibilityComponent extends SpecificMembersVisibility implements OnInit {

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
  }
}
