import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ActivityType } from '@kalgudi/types';

import { TaskSubmissionDetails } from '../../classes/task-submission-details';

@Component({
  selector: 'kl-task-submission-details',
  templateUrl: './task-submission-details.component.html',
  styleUrls: ['./task-submission-details.component.scss']
})
export class TaskSubmissionDetailsComponent extends TaskSubmissionDetails implements OnInit {

  activityType = ActivityType;

  domain: string;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) {
    super(injector);

    this.domain = this.env.coreDomain;
  }

  ngOnInit() {
  }

}
