<mat-card class="my-2 p-0 assist-profile-update" @bottomFloatingCard>

  <!-- Title -->
  <div fxLayout="row" class="px-3 pt-3 pb-0" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>
    <div>
      <svg-icon src="assets/svgs/profile.svg" class="profile-icon" [svgStyle]="{'width.px': '25', 'height.px': '25'}"></svg-icon>
    </div>
    <div>
      <p class="mb-0 title" translate>Profile Update</p>
    </div>
  </div>
  <!-- /Title -->

  <div id="kalgudi-profile-update-form" class="p-0">
    <form autocomplete="off" [formGroup]="profileUpdateForm">
      <div class="p-3 share-update-form" fxLayout fxLayoutGap="10px">
        <div fxFlex>
          <kl-assist-members-picker [formControl]="assistedUser">
          </kl-assist-members-picker>
        </div>
        <div class="edit-profile-button">
          <kl-flat-button
            label="Edit profile"
            [disabled]="!assistedUser?.value?.profileKey"
            (clicked)="openProfileView(assistedUser?.value?.profileKey)"></kl-flat-button>
        </div>
      </div>

      <!-- <div  fxLayout="row" fxLayoutGap="20px" class="px-3">
        <div class="position-relative">
          <img [src]="defaultProfilePic" class="rounded-circle profile-pic border">

          <div class="edit-profile mt-n4" fxLayoutAlign="end end">
            <button mat-icon-button color="primary" class="edit mr-n1 mt-n2" matTooltip="Edit profile picture">
              <mat-icon class="text-whit">edit</mat-icon>
            </button>
            <input class="input-file position-absolute mr-2" type="file" matTooltip="Click to change profile picture">
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="15px" class="w-100">

          <div fxLayout="column">
            <mat-form-field appearance="outline" class="mat-form-field-sm">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name">
            </mat-form-field>
          </div>

          <div fxLayout="column">
            <mat-form-field appearance="outline" class="mat-form-field-sm">
              <mat-label>Title</mat-label>
              <input matInput formControlName="title">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="p-3" fxLayoutGap="15px">
        <div fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label>Contact</mat-label>
            <input matInput formControlName="contact">
          </mat-form-field>
        </div>

        <div fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label>Location</mat-label>
            <input matInput formControlName="location">
          </mat-form-field>
        </div>

      </div> -->
    </form>
  </div>

</mat-card>
