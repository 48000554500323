import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProfileCertificatesService } from './kalgudi-profile-certificates.service';
import { ProfileSectionCertifications } from './profile-section-certifications';

@Component({
  selector: 'kl-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent extends ProfileSectionCertifications implements OnInit {

  protected itemForm: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileCertificatesService,
  ) {

    super(notification, app, util, api);
  }

  ngOnInit() {
  }

  protected onDestroyed(): void { }

}
