import { AfterViewInit, Component, Inject, Injector, OnInit } from '@angular/core';
import { fadeInAnimation, floatTopAnimation } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PagesListTable } from '../classes/pages-list-table';

@Component({
  selector: 'kl-program-list-table',
  templateUrl: './program-list-table.component.html',
  styleUrls: ['./program-list-table.component.scss'],
  animations: [floatTopAnimation, fadeInAnimation]
})
export class ProgramListTableComponent extends PagesListTable implements OnInit, AfterViewInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProgramsRouteConfig) {
    super(injector);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    this.initTableStream(this.matPaginator, this.matSort);

  }


  /**
   * Emits an event to the parent
   * @param id
   */
  openPageFullview(pageId: string): void {
    this.appRouting.toProgram({ pageId });
  }


  protected onDestroyed(): void { }
}
