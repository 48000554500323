import { IdValueMap } from '@kalgudi/types';

export const SOIL_TYPES: IdValueMap[] = [
  { id: 'ALLUVIAL' , value: 'Alluvial'},
  { id: 'BLACK', value: 'Black'},
  { id: 'CLAY_LOAM', value: 'Clay loam'},
  { id: 'CLAY', value: 'Clay'},
  { id: 'FERTILE', value: 'Fertile'},
  { id: 'LATERITE', value: 'Laterite'},
  { id: 'LOAMY', value: 'Loamy'},
  { id: 'REDD', value: 'Red'},
  { id: 'SANDY_LOAM', value: 'Sandy loam'},
  { id: 'SILT_LOAM', value: 'Silt loam'},
  { id: 'SOLID_RED', value: 'Solid Red'},
  { id: 'WELL_DRAINED_SANDY', value: 'Well drained sandy'},
  { id: 'WELL_DRAINED', value: 'Well drained'}
];

export const SIZE_IN_LIST: IdValueMap[] = [
  {id: 'acre' , value: 'Acre'},
  {id: 'hectare', value: 'Hectare'},
  {id: 'feddan', value: 'Feddan'},    // Used in Egypt
];

export const WATER_LEVEL_UNITS: IdValueMap[] = [
  {id: 'FEET', value: 'Feet'}
];

export const UNITS_LIST: IdValueMap[] = [
  { id: 'grams', value: 'Grams' },
  { id: 'kgs', value: 'Kgs' },
  { id: 'tons', value: 'Tons' },
  { id: 'quintals', value: 'Quintals' },
  { id: 'pieces', value: 'Pieces' },
  { id: 'milliliters', value: 'Milliliters' },
  { id: 'liters', value: 'Liters' }
];


export const IRRIGATION_TYPES: IdValueMap[] = [
  {id: 'FURRO_RAISED_BED' , value: 'Furro/Raised bed '},
  {id: 'FLOOD', value: 'Flood'},
  {id: 'SPRINKLER', value: 'Sprinkler'},
  {id: 'DRIP', value: 'Drip'}
];

export const SALINITY: IdValueMap[] = [
  {id: 'YES', value: 'Yes'},
  {id: 'NO', value: 'No'}
];

export const SALINITY_LEVEL: IdValueMap[] = [
  {id: 'HIGH', value: 'High'},
  {id: 'LOW', value: 'Low'},
  {id: 'MEDIUM', value: 'Medium'}
];

export const LAND_COVER_TYPES: IdValueMap[] = [
  { id: 'IRRIGATED_CROPLANDS', value: 'Irrigated croplands' },
  { id: 'RAINFED_CROPLANDS', value: 'Rainfed croplands' },
  { id: 'AGROPASTORAL_LANDS', value: 'Agropastoral lands' },
  { id: 'AGROFORESTRY', value: 'Agroforestry' },
  { id: 'AGROORALSILVOPAST', value: 'Agrosilvopastoral' },
  { id: 'DESERT_AGRICULTURE', value: 'Desert agriculture' },
  { id: 'ORCHARDS', value: 'Orchards' },
  { id: 'PLANTATIONS', value: 'Plantations' },
  { id: 'SACRED_GROWS', value: 'Sacred Grows' },
  { id: 'GRASS_LANDS', value: 'Grass lands' },
  { id: 'SHRUB_LANDS', value: 'Shrub lands' },
  { id: 'RANGE_LANDS', value: 'Range lands' },
  { id: 'FORESTS_LANDS', value: 'Forests lands' },
  { id: 'WET_LANDS', value: 'Wet lands' },
  { id: 'WATER_BODY', value: 'Water body' },
  { id: 'BARREN_LANDS', value: 'Barren lands' },
  { id: 'BUILD_UP_AREAS', value: 'Build-up areas' }
];

export enum PageActions {
  PAGE_UPDATED               = 'PAGE_UPDATED',
  PAGE_DA_STATS_UPDATED      = 'PAGE_DA_STATS_UPDATED',
  PROFILE_PIC_UPDATE         = 'PROFILE_PIC_UPDATE',
  PROGRAM_MEMBERS_UPDATE     = 'PROGRAM_MEMBERS_UPDATE',
  PROGRAM_ADMIN_UPDATE       = 'PROGRAM_ADMIN_UPDATE',
  FETCH_PROGRAM              = 'FETCH_PROGRAM',
  PROGRAM_CONTRIBUTOR_UPDATE = 'PROGRAM_CONTRIBUTOR_UPDATE',
  ADD_ADMIN                  = 'ADD_ADMIN',
  ADD_CONTRIBUTOR            = 'ADD_CONTRIBUTOR',
  ADD_MEMBER                 = 'ADD_MEMBER',
  UPDATE_PROGRAM_DETAILS     = 'UPDATE_PROGRAM_DETAILS',
  ADD_STOCK                  = 'ADD_STOCK',
  ADD_PROJECT                = 'ADD_PROJECT',
  ADD_TASK                   = 'ADD_TASK',
  DELETE_PAGE                = 'DELETE_PAGE',
  ADD_PROCESSING             = 'ADD_PROCESSING',

  // Surveys actions
  PAGE_SURVEY_ADD          = 'PAGE_SURVEY_ADD',
  PAGE_SURVEY_QUESTION_ADD = 'PAGE_SURVEY_QUESTION_ADD',
  PAGE_SURVEY_ADMINS_ADD   = 'PAGE_SURVEY_ADMINS_ADD',
  PAGE_SURVEY_MEMBERS_ADD  = 'PAGE_SURVEY_MEMBERS_ADD',
}
