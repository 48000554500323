<section class="post-tile-attachments" (click)="toFullview()">
  <div class="px-3 post-tile" *ngIf="shareUpdate?.shareText">
    <!-- <p id="post-tile-text" class="mb-0 text-content"
      *ngIf="!isFullviewPage"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="3"
      klGoogleTranslate
      [translationSource]="source"
      [translationTargetList]="translationsList"
      [translationText]="shareUpdate?.shareText"
      klEventBubblingBlocker="a"
      [innerHTML]="shareUpdate?.shareText | linkify">
    </p> -->
    <div class="post-info"
      *ngIf="!isFullviewPage">

      <!-- Training details -->
      <div *ngIf="shareUpdate?.fromDate">
        <!-- Date -->
        <div fxLayout fxLayoutAlign="flex-start center">
          <p class="font-weight-light mr-1" translate>Training Date: </p> <p>{{ shareUpdate?.fromDate | date }}</p>
        </div>

        <div fxLayout="column">
          <!-- Attendees -->
          <div fxLayout fxLayoutAlign="flex-start center">
            <p class="font-weight-light mr-1" translate>Total attendees: </p> <p class="font-weight-lighter">{{ shareUpdate?.totalAttendance }} <small>members</small></p>
          </div>

          <!-- Duration -->
          <div class="mt-n3" fxLayout fxLayoutAlign="flex-start center">
            <p class="font-weight-light mr-1" translate>Duration: </p> <p class="font-weight-lighter">{{ hours }}<small>hrs</small>  {{ minutes }}<small>min</small></p>
          </div>
        </div>
      </div>
      <!--/ Training details -->

      <span class="share-text overflow-hidden" [innerHTML]="(showMore) ? (shareUpdate?.shareText | linkify) : shareUpdate?.shareText | limitText: true: limit | linkify">
      </span>
      <span class="see-more" *ngIf="shareUpdate?.shareText.length > limit && !showMore" (click)="show();$event.stopPropagation();" translate>...See more</span>
      <span *ngIf="env?.appId === 'SAM_FPO' || env?.appId === 'SAM_FARMER' || env?.appId === 'ORMAS_SHG' ; else showWithTranslate">
      </span>

      <ng-template #showWithTranslate>
        <ng-container
          klGoogleTranslate
          [translationSource]="source"
          [translationTargetList]="translationsList"
          [translationText]="shareUpdate?.shareText">
        </ng-container>
      </ng-template>
    </div>

    <!-- Post full-view -->
    <div *ngIf="isFullviewPage">
      <!-- Training details -->
      <div *ngIf="shareUpdate?.fromDate">
        <!-- Date -->
        <div fxLayout fxLayoutAlign="flex-start center">
          <p class="font-weight-light mr-1" translate>Training Date: </p> <p>{{ shareUpdate?.fromDate | date }}</p>
        </div>

        <div fxLayout="column">
          <!-- Attendees -->
          <div fxLayout fxLayoutAlign="flex-start center">
            <p class="font-weight-light mr-1" translate>Total attendees: </p> <p class="font-weight-lighter">{{ shareUpdate?.totalAttendance }} <small translate>members</small></p>
          </div>

          <!-- Duration -->
          <div class="mt-n3" fxLayout fxLayoutAlign="flex-start center">
            <p class="font-weight-light mr-1" translate>Duration: </p> <p class="font-weight-lighter">{{ hours }}<small>hrs</small>  {{ minutes }}<small>min</small></p>
          </div>
        </div>
      </div>
      <!--/ Training details -->

      <!-- Description -->
      <p class="mb-0 text-content" *ngIf="env?.appId === 'SAM_FPO' || env?.appId === 'SAM_FARMER'|| env?.appId === 'ORMAS_SHG' ; else showWithTranslator"
        [innerHTML]="shareUpdate?.shareText | linkify">
      </p>

      <ng-template #showWithTranslator>
        <p class="mb-0 text-content" klGoogleTranslate [translationSource]="source" [translationTargetList]="translationsList"
          [translationText]="shareUpdate?.shareText" klEventBubblingBlocker="a"
          [innerHTML]="shareUpdate?.shareText | linkify">
        </p>
      </ng-template>
      <!--/ Description -->
    </div>
    <!-- Post full-view -->
  </div>

  <ng-container *ngIf="shareUpdate?.lstOfAttachments?.length">

    <div class="pl-2">

      <kl-social-attachments [attachments]="shareUpdate?.lstOfAttachments"></kl-social-attachments>
    </div>

  </ng-container>


  <ng-container *ngIf="shareUpdate?.lstOfAttachments?.length && !isFullviewPage">

    <ng-container *ngIf="shareUpdate?.lstOfAttachments | attachmentTypeCount: 'AUDIO'">
      <div class="px-3" fxLayout fxLayoutAlign="start center">

        <div>
          <mat-icon class="pt-1" color="primary">volume_up</mat-icon>
        </div>
        <div class="ml-1">{{ shareUpdate?.lstOfAttachments | attachmentTypeCount: 'AUDIO' }}
          <span translate>Voice messages attached</span>
        </div>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="isFullviewPage">
    <ng-container *ngFor="let attachment of shareUpdate?.lstOfAttachments">
      <div class="audio-attach my-2 px-3" *ngIf="attachment?.msgType === 'AUDIO'" fxLayout="row" fxLayoutAlign="center center">
        <video width="100%" height="53" controls>
          <source [src]="attachment?.url | prefixDomain" type="audio/mpeg"/>
        </video>
      </div>

    </ng-container>
  </ng-container>



  <div *ngIf="shareUpdate?.uri && shareUpdate?.uriTitle">
    <kl-post-tile-link-attachment [post]="shareUpdate">
    </kl-post-tile-link-attachment>
  </div>
</section>
