<div class="crop-calender p-2">

  <!-- Task type, start date and end date -->
  <div class="main-header" fxLayout fxLayoutAlign="space-between center">
    <span *ngIf="taskDetails?.templateRefTitle" class="task-type">{{ taskDetails?.templateRefTitle | titlecase }}</span>

    <div *ngIf="taskDetails?.timeFrame?.start && taskDetails?.timeFrame?.end" class="main-header text-secondary" fxLayout fxLayoutGap="3px">
      <span>
        {{ taskDetails?.timeFrame?.start | date:'dd' }}
        {{ taskDetails?.timeFrame?.start | date:'MMM' | translate }}
        {{ taskDetails?.timeFrame?.start |date:'yyyy' }}
      </span>
      <span>to </span>
      <span>
        {{ taskDetails?.timeFrame?.end | date:'dd' }}
        {{ taskDetails?.timeFrame?.end | date:'MMM' | translate }}
        {{ taskDetails?.timeFrame?.end |date:'yyyy' }}
      </span>
    </div>
  </div>
  <!--/ Task type, start date and end date -->

  <!-- Task name -->
  <div *ngIf="taskDetails?.title">
    <span class="task-name text-truncate">{{ taskDetails?.title }}</span>
  </div>
  <!--/ Task name -->

  <!-- Members -->
  <div class="members-count" *ngIf="taskDetails?.statistics?.members?.members">
    <span>Members</span>
    <span class="px-1">-</span>
    <span>{{ taskDetails?.statistics?.members?.members }}</span>
  </div>
  <!--/ Members -->

  <!-- Description-title -->
  <div class="pt-1" *ngIf="isSubmission">
    <span class="description-title">Description</span>
  </div>
  <!--/ Description-title -->

  <!-- Description -->
  <div *ngIf="taskDetails?.description">
    <p class="text-secondary description-data overflow-hidden mb-0"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="3" >{{ taskDetails?.description }}
    </p>
  </div>
  <!--/ Description -->

</div>

