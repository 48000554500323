import { Component, Input, OnInit } from '@angular/core';

import { kalgudiAnimations } from '../../animations';

@Component({
  selector: 'kl-spinner',
  template: `
  <section id="kalgudi-spinner" [ngClass]="{'blocking': blocking}" [@fadeIn]>
    <mat-progress-spinner
      diameter="40"
      stroke="6"
      color="accent"
      mode="indeterminate">
    </mat-progress-spinner>
  </section>
  `,
  styles: [`
  #kalgudi-spinner {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 99999999;
  }

  #kalgudi-spinner.blocking {
    background-color: rgba(0, 0, 0, 0.2);
  }

  `],
  animations: kalgudiAnimations,
})
export class KalgudiSpinnerComponent implements OnInit {

  @Input()
  blocking = true;

  constructor() { }

  ngOnInit() { }

}
