import { Pipe, PipeTransform } from '@angular/core';

export function trimSpecialCharacters(value: string, replaceWith: string = ' ') {

    const text = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();

    return (text || '').replace(/_/g, replaceWith);
}

/**
 * Pipe to replace all special characters from the string. Currently it
 * supports removing _ from the string.
 *
 * @author Pankaj Prakash
 */
@Pipe({
  name: 'trimSpecialCharacters'
})
export class TrimSpecialCharactersPipe implements PipeTransform {

  transform(value: string, replaceWith: string = ' '): any {

    return (value || '').replace(/_/g, replaceWith);
  }

}
