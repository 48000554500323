import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { KalgudiFarmerCrops } from '../../classes/kalgudi-farmer-crops';


@Component({
  selector: 'kl-farmer-crops',
  templateUrl: './farmer-crops.component.html',
  styleUrls: ['./farmer-crops.component.scss']
})
export class FarmerCropsComponent extends KalgudiFarmerCrops implements OnInit {


  constructor(
    protected injector: Injector,
    private router: Router,
  ) {

    super(injector);
  }

  ngOnInit() {
    if (this.openCropDialog) {
      this.showAddProductsDialog();
    }
  }

  onDestroyed(): void {}

  /**
   * Event handler on fetching crop success.
   */
  protected onCropFetchingSuccess(res: any): void {
    this.productsLength = res.length;
    this.getProductsList(res);
  }

  /**
   * Event handler on fetching crop errors.
   */
  protected onCropError(err: Error): void {}

  /**
   * Navigate to tasks
   */
  navigateToTasks() {

    const showCalendarView = true;

    this.router.navigate(['/app/projects'], {queryParams: { type: 'GRID', showCalendarView: showCalendarView}});
  }


  /**
   * Function to get length of crops for tasks and catalouge
   */
  get cropsLength() {
    if(!this.products) return 0;
    return Object.keys(this.products).length;
  }

}
