import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUserPickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import {
  AddProjectMembersDialogComponent,
} from './components/add-project-members-dialog/add-project-members-dialog.component';
import {
  AddProjectMembersMobileDialogComponent,
} from './components/add-project-members-mobile-dialog/add-project-members-mobile-dialog.component';
import { AddProjectMembersComponent } from './components/add-project-members/add-project-members.component';
import { ManageProjectMembersComponent } from './components/manage-project-members/manage-project-members.component';
import { SearchMembersComponent } from './components/search-members/search-members.component';
import { AddProjectMembersApiService } from './services/add-project-members-api.service';
import { AddProjectMembersDialogService } from './services/add-project-members-dialog.service';
import { AddProjectMembersService } from './services/add-project-members.service';


@NgModule({
  declarations: [
    AddProjectMembersDialogComponent,
    AddProjectMembersMobileDialogComponent,
    AddProjectMembersComponent,
    SearchMembersComponent,
    ManageProjectMembersComponent,

  ],
  imports: [
    CommonModule,

    // Material module
    MatIconModule,
    MatTabsModule,
    MatButtonModule,

    FlexLayoutModule,

    TranslateModule,

    KalgudiButtonModule,

    KalgudiUserPickerModule,
    KalgudiUsersModule,
    KalgudiNoContentModule,
    KalgudiPipesModule,
    KalgudiLoadMoreModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule
  ],
  exports: [
    AddProjectMembersComponent,
    SearchMembersComponent,
    ManageProjectMembersComponent,
  ],
  entryComponents: [
    AddProjectMembersDialogComponent,
    AddProjectMembersMobileDialogComponent
  ],
  providers: [
    AddProjectMembersDialogService,
    AddProjectMembersApiService,
    AddProjectMembersService
  ],
})
export class AddProjectMembersModule { }
