import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';

import {
  UploadAttachmentsDialogComponent,
} from './components/upload-attachments-dialog/upload-attachments-dialog.component';
import {
  UploadAttachmentsMobileDialogComponent,
} from './components/upload-attachments-mobile-dialog/upload-attachments-mobile-dialog.component';
import { UploadAttachmentsComponent } from './components/upload-attachments/upload-attachments.component';
import { KalgudiAttachmentsPickerService } from './services/kalgudi-attachments-picker.service';



@NgModule({
  declarations: [
    UploadAttachmentsMobileDialogComponent,
    UploadAttachmentsDialogComponent,
    UploadAttachmentsComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,

    // Material
    MatDialogModule,
    MatButtonModule,
    MatIconModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiButtonModule,
    KalgudiPipesModule,
  ],
  exports: [
    UploadAttachmentsComponent,
  ],
  entryComponents: [
    UploadAttachmentsMobileDialogComponent,
    UploadAttachmentsDialogComponent,
  ],
  providers: [
    KalgudiAttachmentsPickerService,
  ]
})
export class KalgudiAttachmentsPickerModule { }
