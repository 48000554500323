import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiPageFilter } from '../../page-filter';


@Component({
  selector: 'kl-page-target-members-filter',
  templateUrl: './page-target-members-filter.component.html',
  styleUrls: ['./page-target-members-filter.component.scss'],
  animations: kalgudiAnimations,
})
export class PageTargetMembersFilterComponent extends KalgudiPageFilter implements OnInit {

  @Input()
  label: string;

  @Input()
  searchPlaceholder: string;

  constructor() {

    super();
  }

  ngOnInit() { }
}
