import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
  MomentDateModule,
} from "@angular/material-moment-adapter";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  KalgudiCommonModule,
  KalgudiDialogsModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiTimePickerModule,
  KalgudiUsersModule,
} from "@kalgudi/common";
import { KalgudiButtonModule } from "@kalgudi/common/ui/button";
import { KalgudiSlideToggleModule } from "@kalgudi/common/ui/slide-toggle";
import {
  KalgudiDirectivesModule,
  KalgudiPipesModule,
  MAT_INDIAN_DATE_FORMAT,
} from "@kalgudi/core";
import { TranslateModule } from "@ngx-translate/core";
import { DateAdapter } from "angular-calendar";

import { PageActivityFiltersModule } from "../page-activity-filters/page-activity-filters.module";
import { KalgudiProgramMembersPickerDialogModule } from "../program-members-picker-dialog/program-members-picker-dialog.module";
import { KalgudiProgramShareUpdatesModule } from "../program-share-updates/program-share-updates.module";
import { KalgudiProgramTargetMembersModule } from "../program-target-members/program-target-members.module";
import { MobilePublishRobocallComponent } from "./components/mobile-publish-robocall/mobile-publish-robocall.component";
import { PublishRobocallFormComponent } from "./components/publish-robocall-form/publish-robocall-form.component";

import { KalgudiRobocallStreamStateService } from "./services/kalgudi-robocall-stream-state.service";

import { MatChipsModule } from "@angular/material/chips";
import { MatDividerModule } from "@angular/material/divider";
import { KalgudiSocialModule } from "@kalgudi/social";
import { RobocallTileHeaderComponent } from "./components/robocall-tile-header/robocall-tile-header.component";
import { RobocallTileComponent } from "./components/robocall-tile/robocall-tile.component";
import { ScheduledRobocallStreamComponent } from "./components/scheduled-robocall-stream/scheduled-robocall-stream.component";
import { RobocallStreamService } from "./services/robocall-stream.service";
import { ScheduledRobocallStreamService } from "./services/scheduled-robocall-stream.service";
import { RobocallListApiService } from "./services/robocall-logs-list-api.service";
import { RobocallFullViewDialogComponent } from './components/robocall-full-view-dialog/robocall-full-view-dialog.component';
import { RobocallFullViewMobileDialogComponent } from './components/robocall-full-view-mobile-dialog/robocall-full-view-mobile-dialog.component';

@NgModule({
  declarations: [
    PublishRobocallFormComponent,
    MobilePublishRobocallComponent,
    RobocallTileComponent,
    RobocallTileHeaderComponent,
    ScheduledRobocallStreamComponent,
    RobocallFullViewDialogComponent,
    RobocallFullViewMobileDialogComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatCardModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatButtonModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMenuModule,
    MatBottomSheetModule,
    MomentDateModule,
    MatChipsModule,
    MatDividerModule,

    // Kalgudi
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiUsersModule,
    KalgudiProgramTargetMembersModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiProgramShareUpdatesModule,
    KalgudiTimePickerModule,
    MatInputModule,
    PageActivityFiltersModule,
    KalgudiDialogsModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiDirectivesModule,
    TranslateModule,
    KalgudiSlideToggleModule,
    KalgudiImagePickerModule,
    KalgudiCommonModule,
    KalgudiSocialModule,
  ],
  exports: [PublishRobocallFormComponent, RobocallTileComponent, ScheduledRobocallStreamComponent],
  entryComponents: [
    MobilePublishRobocallComponent, RobocallFullViewDialogComponent, RobocallFullViewMobileDialogComponent
  ],
  providers: [
    RobocallStreamService,
    ScheduledRobocallStreamService,
    KalgudiRobocallStreamStateService,
    RobocallListApiService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT,
    },
  ],
})
export class KalgudiProgramRobocallModule {}
