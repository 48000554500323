import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { DEFAULT_DIALOG_CONFIG } from '../../kalgudi-dialogs';
import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../../mobile-dialog';
import { CaptureImageDialogComponent } from '../components/capture-image-dialog/capture-image-dialog.component';
import { CaptureImageMobileDialogComponent } from '../components/capture-image-mobile-dialog/capture-image-mobile-dialog.component';
import { UploadImageDialogComponent } from '../components/upload-image-dialog/upload-image-dialog.component';
import { UploadImageMobileDialogComponent } from '../components/upload-image-mobile-dialog/upload-image-mobile-dialog.component';

@Injectable()
export class KalgudiImagePickerService {

  constructor(
    private dialogsService: KalgudiDialogsService
  ) { }

  /**
   * Shows image upload dialog
   *
   * @param config Dialog
   */
  showImageUpload(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
    options?:any
  ): Observable<KalgudiDialogResult> {

    details.options = options;
    // return this.dialogsService.openDialog(UploadImageDialogComponent, details, config);
    return checkMobileDevice()
      ? this.openMobileImageUploadDialog(details)
      : this.openImageUploadDialog(details, config);
  }


  /**
   * Shows captured image upload dialog
   *
   * @param config Dialog
   */
  showCaptureImageUpload(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileCaptureImageDialog(details)
      : this.openCaptureImageDialog(details, config);
  }


  /**
   * Opens member picker dialog
   */
  openImageUploadDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.dialogsService.openDialog(UploadImageDialogComponent, details, dialogConfig);
  }

  /**
   * Opens member picker Mobile dialog
   */
  openMobileImageUploadDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.dialogsService.openMobileDialog(UploadImageMobileDialogComponent, details );
  }


  /**
   * Opens camera picker dialog
   */
  openCaptureImageDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.dialogsService.openDialog(CaptureImageDialogComponent, details, dialogConfig);
  }

  /**
   * Opens camera picker Mobile dialog
   */
  openMobileCaptureImageDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.dialogsService.openMobileDialog(CaptureImageMobileDialogComponent, details );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
