import { ProjectType, SelectableUIMenu, UIMenu } from '@kalgudi/types';

import { AppRoutes } from './app-routes';


export * from './app-routes';

/*
**Menu list for web view
// */
// export const WEB_MENU_LIST: UIMenu[] = [
//   {
//     title: 'Home',
//     svgIcon: 'assets/svgs/home.svg',
//     routerLink: AppRoutes.APP_HOME,
//     visible: true
//   },
//   // {
//   //   title: 'Tasks',
//   //   faIcon: 'fa fa-tasks',
//   // },
//   // {
//   //   title: 'Crop calender',
//   //   matIcon: 'calendar_today',
//   // },
//   {
//     title: 'Projects',
//     svgIcon: 'assets/svgs/projects.svg',
//     // routerLink: AppRoutes.APP_PROJECTS,
//     // queryParams: {
//     //   type: 'GRID'
//     // },
//     subMenu: [
//       {
//         title: 'All Projects',
//         svgIcon: 'assets/svgs/projects.svg',
//         routerLink: AppRoutes.APP_PROJECTS,
//         queryParams: {
//           type: 'GRID'
//         },
//       },
//       {
//         title: 'Dashboards',
//         svgIcon: 'assets/svgs/dashboard.svg',
//         routerLink: AppRoutes.APP_PROJECTS_DASHBOARD,
//       },
//       {
//         title: 'Tasks',
//         svgIcon: 'assets/svgs/tasks.svg',
//         routerLink: AppRoutes.APP_TASKS,
//         queryParams: {
//           type: 'GRID'
//         },
//       },
      // {
      //   title: 'Members',
      //   svgIcon: 'assets/svgs/team-building.svg',
      //   // routerLink: AppRoutes.APP_PROJECTS_MEMBERS,
      // },

//     ],
//     visible: !environment.production
//   },
//   {
//     title: 'Pages',
//     svgIcon: 'assets/svgs/page.svg',
//     routerLink: AppRoutes.APP_PROGRAMS,
//     queryParams: {
//       type: 'GRID'
//     },
//     visible: true
//   },
//   {
//     title: 'Stocks',
//     matIcon: 'shopping_cart',
//     routerLink: AppRoutes.APP_STOCKS_LOTS,
//     visible: true,
//   },
//   {
//     title: 'Processing',
//     matIcon: 'how_to_reg',
//     // svgIcon: 'assets/svgs/processing.svg',
//     routerLink: AppRoutes.APP_STOCKS_PROCESSORS,
//     visible: true,
//   },
//   {
//     title: 'Login',
//     svgIcon: 'assets/svgs/log-in.svg',
//     routerLink: AppRoutes.AUTH_LOGIN,
//     visible: true
//   },
//   {
//     title: 'Search',
//     svgIcon: 'assets/svgs/search.svg',
//     routerLink: AppRoutes.APP_SEARCH,
//     visible: !environment.production
//   }

// ];

/*
**Menu list view
*/
export const MENU_LIST: UIMenu[] = [
  {
    title: 'Home',
    svgIcon: 'assets/svgs/home.svg',
    routerLink: AppRoutes.APP_HOME,
    visible: true,
    hideMobile: true
  },
  {
    id: 'PAGES_ADVISORIES',
    title: 'Advisories',
    svgIcon: 'assets/svgs/advisories.svg',
    routerLink: AppRoutes.APP_PROGRAM_ADVISORIES,
    visible: true,
  },
  {
    title: 'QA',
    svgIcon: 'assets/svgs/qa.svg',
    routerLink: AppRoutes.APP_SOCIAL_QA,
    iconSize: 24,
    visible: true
  },
  {
    title: 'Updates',
    svgIcon: 'assets/svgs/updates.svg',
    routerLink: AppRoutes.APP_SOCIAL_UPDATES,
    iconSize: 24,
    visible: true,
    // disabled: true,
  },
  // {
  //   title: 'Stocks',
  //   matIcon: 'shopping_cart',
  //   routerLink: AppRoutes.APP_STOCKS_LOTS,
  // },
  // {
  //   title: 'Processing',
  //   // svgIcon: 'assets/svgs/processing.svg',
  //   matIcon: 'how_to_reg',
  //   routerLink: AppRoutes.APP_STOCKS_PROCESSORS,
  // },
  {
    title: 'Surveys',
    svgIcon: 'assets/svgs/surveys-icon.svg',
    routerLink: AppRoutes.APP_SURVEYS,
    visible: true
  },
  {
    id: 'CROP_CALENDAR',
    title: 'Crop Calendar',
    svgIcon: 'assets/svgs/crop-calender.svg',
    routerLink: AppRoutes.APP_PROJECTS,
    queryParams: {
      type: 'GRID'
    },
    visible: true
  },
  {
    id: 'PAGES_GROUPS',
    title: 'Groups',
    svgIcon: 'assets/svgs/group.svg',
    routerLink: AppRoutes.APP_PROGRAM_GROUPS,
    visible: true,
  },
  {
    id: 'STORE',
    title: 'Store',
    svgIcon: 'assets/svgs/store.svg',
    routerLink: AppRoutes.APP_STORE,
    visible: true,
  },
  {
    title: 'Search',
    svgIcon: 'assets/svgs/search.svg',
    routerLink: AppRoutes.APP_SEARCH,
    visible: true
  },
  {
    id: 'ADMIN',
    title: 'Admin',
    svgIcon: 'assets/svgs/admin.svg',
    visible: false,
    hideMobile: true
  },
  {
    id: 'ADVANCED',
    title: 'Advanced',
    svgIcon: 'assets/svgs/advanced-settings.svg',
    visible: false,
    subMenu: [
      {
        id: 'PAGES',
        title: 'Pages',
        svgIcon: 'assets/svgs/page.svg',
        routerLink: AppRoutes.APP_PROGRAMS,
        queryParams: {
          type: 'GRID'
        },
        // subMenu: [
        //   {
        //     title: 'Stocks',
        //     matIcon: 'shopping_cart',
        //     routerLink: AppRoutes.APP_STOCKS_LOTS,
        //   },
        //   {
        //     title: 'Processing',
        //     // svgIcon: 'assets/svgs/processing.svg',
        //     matIcon: 'how_to_reg',
        //     routerLink: AppRoutes.APP_STOCKS_PROCESSORS,
        //   },
        // ],
        visible: true
      },
      {
        title: 'Projects',
        svgIcon: 'assets/svgs/projects.svg',
        routerLink: AppRoutes.APP_PROJECTS,
        queryParams: {
          type: 'GRID'
        },
      },
      {
        title: 'QA',
        svgIcon: 'assets/svgs/qa.svg',
        routerLink: AppRoutes.APP_SOCIAL_QA_LIST,
        iconSize: 24,
        visible: true
      },
      {
        title: 'Updates',
        svgIcon: 'assets/svgs/updates.svg',
        routerLink: AppRoutes.APP_SOCIAL_UPDATES_LIST,
        iconSize: 24,
        visible: true
      },
    ]
  },
  {
    title: 'Login',
    svgIcon: 'assets/svgs/profile.svg',
    routerLink: AppRoutes.AUTH_LOGIN,
    visible: true,
    hideMobile: true
  },
];

export const PROFILE_MENU_LIST: UIMenu[] = [
  {
    title: 'My profile',
    id: 'MY_PROFILE',
    svgIcon: 'assets/svgs/profile.svg',
    routerLink: AppRoutes.APP_PROFILES
  },
  {
    id: 'ADMIN',
    title: 'Admin',
    svgIcon: 'assets/svgs/admin.svg',
  },
  {
    id: 'ADVANCED',
    title: 'Advanced',
    svgIcon: 'assets/svgs/advanced-settings.svg',
    visible: true,
    subMenu: [
      {
        id: 'PAGES',
        title: 'Pages',
        svgIcon: 'assets/svgs/page.svg',
        routerLink: AppRoutes.APP_PROGRAMS,
        queryParams: {
          type: 'GRID'
        },
        // subMenu: [
        //   {
        //     title: 'Stocks',
        //     matIcon: 'shopping_cart',
        //     routerLink: AppRoutes.APP_STOCKS_LOTS,
        //   },
        //   {
        //     title: 'Processing',
        //     // svgIcon: 'assets/svgs/processing.svg',
        //     matIcon: 'how_to_reg',
        //     routerLink: AppRoutes.APP_STOCKS_PROCESSORS,
        //   },
        // ],
        visible: true
      },
      {
        title: 'Projects',
        svgIcon: 'assets/svgs/projects.svg',
        routerLink: AppRoutes.APP_PROJECTS,
        queryParams: {
          type: 'GRID'
        },
      },
      {
        title: 'QA',
        svgIcon: 'assets/svgs/qa.svg',
        routerLink: AppRoutes.APP_SOCIAL_QA_LIST,
        iconSize: 24,
        visible: true
      },
      {
        title: 'Updates',
        svgIcon: 'assets/svgs/updates.svg',
        routerLink: AppRoutes.APP_SOCIAL_UPDATES_LIST,
        iconSize: 24,
        visible: true
      },
    ]
  },
  {
    id: 'CHANGE_LANGUAGE',
    title: 'Change language',
    svgIcon: 'assets/svgs/language.svg',
    visible: true,
    subMenu: [
      {
        visible: true
      }
    ],
  }
];

/*
** Project Template type
*/
export const PROJECT_TEMPLATE_TYPE: SelectableUIMenu[] = [
  {
    svgIcon: 'assets/svgs/calender.svg',
    title: 'Crop calendar',
    desc: 'Define calendar for your crop',
    id: ProjectType.CROP_CALENDAR,
  },
  // {
  //   svgIcon: 'assets/svgs/sys-management.svg',
  //   title: 'Software development',
  //   desc: 'Lorem ipsum dolor sit amet consectetur',
  //   id: ProjectType.SOFTWARE_DEVELOPMENT
  // },
  // {
  //   svgIcon: 'assets/svgs/farming-activities.svg',
  //   title: 'Road map creation',
  //   desc: 'Lorem ipsum dolor sit amet consectetur',
  //   id: ProjectType.ROADMAP_CREATION
  // },
  // {
  //   svgIcon: 'assets/svgs/farming-activities.svg',
  //   title: 'Generic',
  //   desc: 'Lorem ipsum dolor sit amet consectetur',
  //   id: ProjectType.GENERIC
  // }
];
