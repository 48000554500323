<div id="upload-image-mobile" class="h-100">

  <div class="mt-2">
    <kl-task-submission-details
      [extraParams]="extraParams"
      [taskId]="taskId"
      [projectId]="projectId"
      [taskDetails]="taskDetails"
      [submissionDetails]="submissionDetails">
    </kl-task-submission-details>
  </div>

</div>
