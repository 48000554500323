import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-crop-dialog',
  templateUrl: './crop-dialog.component.html',
  styleUrls: ['./crop-dialog.component.scss']
})
export class CropDialogComponent extends KalgudiDialog<CropDialogComponent> implements OnInit {

  cropFormDetails: any;

  profileKey: any;
  pageId: any;

  constructor(
    protected dialogRef: MatDialogRef<CropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

    this.profileKey = this.data.data.profileKey;
    this.cropFormDetails = this.data.data.cropFormDetails;
    this.pageId = this.data.data.pageId;
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }
}
