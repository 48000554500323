import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { KalgudiPhotoswipeService } from '../kalgudi-photoswipe.service';
import { PhotoswipeAttachments, PhotoswipeOptions } from '../types';


declare var PhotoSwipeUI_Default: any;
declare var PhotoSwipe: any;

@Component({
  selector: 'kl-image-preview',
  templateUrl: './image-preview.component.html',
  styles: [`
  .pswp__caption .pswp__caption__center {

    font-size: 15px;

    @media screen and (min-width: 599px) {
      text-align: center;
    }
  }
  `]
})
export class ImagePreviewComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  gallery: PhotoswipeAttachments;

  photoSwipe: any;

  private initialized: boolean;

  constructor(
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) { }

  ngOnInit() {
    this.initialized = false;
  }

  ngOnDestroy() {
    this.photoSwipe = null;
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.gallery && !this.initialized) {
      this.initializePhotoSwipe();
    }
  }

  /**
   * Closes photoswipe image fullview dialog
   */
  close() {
    this.kalgudiPhotoswipe.close();
  }

  /**
   * Initializes photoswipe image preview library
   */
  private initializePhotoSwipe(): void {

    // Get photoswipe element from document
    const psElement = document.querySelectorAll('.pswp')[0];

    // Photoswipe options
    const options: PhotoswipeOptions = {
      index : this.gallery.selectedIndex,
      closeOnScroll: false,
    };

    // Initialize photoswipe
    this.photoSwipe = new PhotoSwipe(psElement, PhotoSwipeUI_Default, this.gallery.images, options);
    this.photoSwipe.init();

    // close() method ensure the photoswipe state gets changed to inactive
    // Don't comment or remove the below line. It will make the photoswipe instable.
    this.photoSwipe.listen('destroy', () => this.close());
    this.photoSwipe.component = this;

    this.initialized = true;
  }

}
