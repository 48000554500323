import { Component, Injector, Input, OnInit } from '@angular/core';

import { KalgudiUserLoginIdUpdate } from '../../classes/kalgudi-user-loginid-update';

@Component({
  selector: 'kl-user-mobile-number-update',
  templateUrl: './user-mobile-number-update.component.html',
  styleUrls: ['./user-mobile-number-update.component.scss']
})
export class UserMobileNumberUpdateComponent extends KalgudiUserLoginIdUpdate implements OnInit {

  @Input()
  loginId: string;

  isEmailId: boolean;

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {

    this.loginId = this.loginId.includes('@') ?  this.loginId : this.removeMobileTelecomCode(this.loginId);

    this.profileForm.get('newMobileNo').patchValue(this.loginId);

    this.isEmailId = this.loginId.includes('@') ? true : false;

  }

  onDestroyed(): void {}

  /**
   * Remove telecom code from login id
   * @param loginId
   */
  private removeMobileTelecomCode(loginId): string {

    return  loginId.replace('+91', '');
  }
}
