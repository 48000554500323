import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-clone-survey-dialog',
  templateUrl: './clone-survey-dialog.component.html',
  styleUrls: ['./clone-survey-dialog.component.scss']
})
export class CloneSurveyDialogComponent extends KalgudiDialog<CloneSurveyDialogComponent> implements OnInit {
  surveyDetails: KalgudiSurveyDetails;

  constructor(
    protected dialogRef: MatDialogRef<CloneSurveyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

    this.surveyDetails = dialogData.data.surveyDetails;
  }

  ngOnInit() {
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }


  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
