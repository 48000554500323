<main id="add-service-license">
  <section>
    <form autocomplete="off" class="mt-2" [formGroup]="servicesForm">

      <div fxLayout="column" fxLayoutGap="10px">

        <div fxLayout="row wrap"  fxLayoutGap="10px" class="w-100" fxLayoutGap.xs="0">

          <div fxFlex="calc(62% - 10px)" fxFlex.xs="100%" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="6px">

            <!-- Service -->
            <div fxLayout="column" fxFlex="calc(31% - 10px)" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Service</mat-label>
                <input matInput [placeholder]="'Enter Service' | translate" formControlName="service" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')" required>
                <mat-error class="mt-2" translate>Please enter Service</mat-error>
              </mat-form-field>
            </div>
            <!--/ Service -->

            <!-- License -->
            <div fxLayout="column" fxFlex="calc(31% - 10px)" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>License</mat-label>
                <input matInput [placeholder]="'Enter License' | translate" formControlName="license" (keydown.space)="$event.preventDefault()" maxlength="40" (keypress)="onKeyPress($event, 'alphaNumeric')">
              </mat-form-field>
            </div>
            <!--/ License -->
          </div>

          <div fxLayout="row" fxLayoutAlign="end center" fxHide.xs>
            <button mat-icon-button class="add-button mb-2" [disabled]="servicesForm?.invalid" (click)="addServiceLicense()">
              <mat-icon class="text-white">add</mat-icon>
            </button>
          </div>
          <div class="w-100" fxLayoutAlign="end end">
            <div class="mt-3" fxLayout="row" fxHide.gt-xs>
              <button mat-flat-button class="add-button button-web w-100 text-white" [disabled]="servicesForm?.invalid" (click)="addServiceLicense()">
                <span>Add</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

  </section>

  <div [ngClass]="{'mt-2 list-services': editable}">
    <kl-services-list
      [serviceLicense]="profileServicesList"
      [editable]="editable"
      (removeServicesLicense)="prepareRemoveServicesPayload($event)">
    </kl-services-list>
  </div>

  <div class="w-100 mt-2 save-button">
    <kl-flat-button
      label="Save"
      [progress]="progress"
      (clicked)="removeServices()"
      (disabled)="!servicesForm?.valid">
    </kl-flat-button>
  </div>
</main>
