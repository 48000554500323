import { Component, Input, OnInit } from "@angular/core";
import { KalgudiProfileRouteConfig } from '../../../../../config/index';
import { KalgudiProfileStateService } from '../../../../../services/kalgudi-profile-state.service';

@Component({
  selector: "kl-fpo-grading-tile",
  templateUrl: "./fpo-grading-tile.component.html",
  styleUrls: ["./fpo-grading-tile.component.scss"],
})
export class FpoGradingTileComponent implements OnInit {
  @Input() hasDetailPage!: boolean;
  public profileDetails$ = this.profileStateService.data$;
  private appRouting: KalgudiProfileRouteConfig;

  constructor(private profileStateService: KalgudiProfileStateService) {}

  ngOnInit(): void {}

  public profileGrading(): void {
    this.appRouting.toProfileGrading();
  }
}
