<button mat-button class="kl-link-btn"
  [color]="buttonColor || 'primary'"
  (click)="onClick($event)"
  [type]="type"
  [disabled]="disabled || progress">
  <span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px">
      <span class="link-label" *ngIf="label" translate>{{label}}</span>
      <mat-spinner *ngIf="progress" class="dim-spinner" [@slideRight]
        [diameter]="20"
        color="warn">
      </mat-spinner>
    </div>
  </span>
</button>
