import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@app/env';
import { KalgudiAuthGuard, KalgudiCoreModule, KalgudiNoAuthGuard, PreloadSameRouteModulesService } from '@kalgudi/core';

import { PublicPageGuard } from './guard/public-page.guard';



const routes: Routes = [
  {
    path: 'auth',
    canActivate: [KalgudiNoAuthGuard],
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'app',
    canActivate: [KalgudiAuthGuard],
    data: {
      breadcrumb: {
        title: 'Home'
      },
    },
    children: [
      {
        path: 'home',
        data: {
          preload: true,
          breadcrumb: false,
        },
        loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'profiles',
        data: { preload: true, },
        loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule),
      },
      {
        path: 'public/profiles',
        data: { preload: true, },
        loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule),
      },
      {
        path: 'credits',
        loadChildren: () => import('./modules/credits-management/credits-management.module').then(m => m.CreditsManagementModule),
      },
      {
        path: 'pages',
        data: {
          preload: true,
          breadcrumb: false,
        },
        loadChildren: () => import('./modules/programs/programs.module').then(m => m.ProgramsModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'tasks',
        loadChildren: () => import('./modules/tasks/tasks.module').then(m => m.TasksModule)
      },
      {
        path: 'social',
        data: {
          breadcrumb: false
        },
        loadChildren: () => import('./modules/social/social.module').then(m => m.SocialModule)
      },
      {
        path: 'search',
        data: {
          breadcrumb: {
            title: 'Search'
          },
        },
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
      },
      {
        path: 'stocks',
        data: {
          title: 'Stocks',
          breadcrumb: {
            title: 'Stocks'
          }
        },
        loadChildren: () => import('./modules/stocks/stocks.module').then(m => m.StocksModule),
      },
      {
        path: 'surveys',
        data: {
          title: 'Surveys',
          breadcrumb: {
            title: 'Surveys'
          },
        },
        loadChildren: () => import('./modules/surveys/surveys.module').then(m => m.SurveysModule),
      },
      {
        path: 'weather',
        data: {
          title: 'Weather'
        },
        loadChildren: () => import('./modules/weather/weather.module').then(m => m.WeatherModule),
      },
      {
        path: 'store',
        data: {
          title: 'Store'
        },
        loadChildren: () => import('./modules/store/store.module').then(m => m.StoreModule),
      },
      {
        path: 'network',
        data: {
          title: 'Network',
          breadcrumb: false
        },
        loadChildren: () => import('./modules/networks/networks.module').then(m => m.NetworksModule),
      },
      {
        path: 'order',
        children: [
          {
            path: 'traceability',
            loadChildren: () => import('./modules/traceability/traceability.module').then(m => m.TraceabilityModule),
          }
        ]
      },
      {
        path: 'user-stocks',
        data: {
          title: 'Stocks',
          breadcrumb: {
            title: 'Stocks'
          }
        },
        loadChildren: () => import('./modules/user-stocks/user-stocks.module').then(m => m.UserStocksModule),
      },
      {
        path: 'user-documents',
        data: {
          title: 'Documents',
          breadcrumb: {
            title: 'Documents'
          }
        },
        loadChildren: () => import('./modules/user-documents/user-documents.module').then(m => m.UserDocumentsModule),
      },
      {
        path: 'accounts',
        data: {
          title: 'Accounts',
          breadcrumb: {
            title: 'Accounts'
          }
        },
        loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
      },
      {
        path: 'reports',
        data: {
          title: 'Reports',
          breadcrumb: {
            title: 'Reports'
          }
        },
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'home'
      },
    ]
  },
  {
    path: 'public',
    canActivate: [ PublicPageGuard ],
    children: [
      {
        path: 'social',
        loadChildren: () => import('./modules/social/social.module').then(m => m.SocialModule),
      },
      {
        path: 'profiles',
        loadChildren: () => import('./modules/profiles/profiles.module').then(m => m.ProfilesModule),
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'app',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'app',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [

    // Root  router
    RouterModule.forRoot(routes, {

    // Uncomment below line to enable routes trace output to console
    // Routes tracing can be useful when debugging routing issues
    // enableTracing: true,

    // Use hash prefix in the base of the URL
    useHash: environment.useHash,

    // Ensure to pass parent route path params to child params
    // NOTE: Removal of this causes all dependent child on its
    // parent route params to break.
    paramsInheritanceStrategy: 'always',

    // Ensure to restore the scroll position when navigated back
    // This only works with static data, it does not works
    // if route data is cleared.
    scrollPositionRestoration: 'enabled',

    // Preload specific lazy loaded routes.
    // This ensure better performance of the lazy loaded modules.
    // Set `data: { preload: true } ` flag in your route configuration of lazy loaded modules
    // whom you want to preload.
    // NOTE: Don't preload all modules as it will negatively impact performance.
    preloadingStrategy: PreloadSameRouteModulesService,

    relativeLinkResolution: 'legacy'
}),

    // Kalgudi Core
    KalgudiCoreModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
