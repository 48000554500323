import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiPageTargetMembers } from '../classes/kalgudi-page-target-members';



@Component({
  selector: 'kl-page-target-members',
  templateUrl: './page-target-members.component.html',
  styles: [],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTargetMembersComponent extends KalgudiPageTargetMembers implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() { }
}
