<section class="card-types">
  <div fxLayout="column">

    <div class="types-data" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="4% grid" fxLayoutAlign="center" fxFlex>

      <!-- Iteration of types -->
      <ng-container *ngFor="let option of options; let i = index">
        <div class="mb-2 type-info" fxFlex="33.3%" fxFlex.xs="100%" *ngIf="!option?.hide">

          <!-- Selected type -->
          <div class="border p-3 card position-relative cursor-pointer"
            [ngClass]="{'cursor-not-allowed': disableSelection}"
            (click)="selectOption(i)"
            [@floatTop]="{ value: '', params: { delay: i * 50 } }">

            <div class="card-type" [fxLayout]="layout" fxLayout.xs="row" fxLayoutAlign.xs="start" fxLayoutAlign="start" fxLayoutGap="15px">

              <!-- Svg icon -->
              <div class="svg-icons" fxLayoutAlign="center center" fxLayoutAlign.xs="start">
                <svg-icon [applyCss]="true" [src]="option?.svgIcon" [svgStyle]="{ 'width.px': 54, 'height.px': 54 }" *ngIf="option?.svgIcon">
                </svg-icon>
              </div>
              <!-- / Svg icon -->

              <!-- Description -->
              <div class="card-desc" fxLayout="column" [ngClass]="{ 'text-center-align': layout === 'column'}" fxLayoutAlign="center">
                <div>
                  <h3 class="title mb-2" [matTooltip]="option?.title | translate" matTooltipPosition="right">{{ option?.title | translate }}</h3>
                </div>
                <div class="description text-secondary" [matTooltip]="option?.desc | translate" matTooltipPosition="right">
                  <span>{{ option?.desc | translate }}</span>
                </div>
              </div>
              <!-- / Description -->
            </div>

            <div class="position-absolute selected-type rounded-circle" *ngIf="option?.id === selectedValue" fxLayoutAlign="center center">

              <div class="rounded-circle circle" fxLayoutAlign="center center" [@floatTop]="{ value: '', params: { delay: 25 } }">
                <mat-icon aria-hidden="true" fxLayoutAlign="center center">check</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- Selected type -->

    </div>
    <!-- Iteration of types -->

  </div>

</section>
