<div id="stock-page">
  <form autocomplete="off" [formGroup]="stockPageForm">

    <h3 class="product text-primary" translate>Product</h3>

    <div fxLayout="column" fxLayoutGap="20px">
      <kl-base-product-autocomplete
        formControlName="product"
        [required]="true">
      </kl-base-product-autocomplete>

      <kl-base-product-autocomplete formControlName="variety"
        placeholder="Search and select product variety"
        label="Search and select product variety"
        [productType]="productTypes.VARIETY"
        [baseProduct]="baseProductFieldValue"
        [required]="true">
      </kl-base-product-autocomplete>
    </div>

  </form>
</div>
