<mat-card class="my-3 project-list-header">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column">
    <!-- Content -->
    <div fxLayout="column">
      <div class="mb-2">
        <kl-breadcrumbs></kl-breadcrumbs>
      </div>
      <div fxLayout="row">
        <div [@scaleOut]>
          <svg-icon src="assets/svgs/projects.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"
            class="mr-3 projects-icon"></svg-icon>
        </div>
        <div [@slideRight] class="ml-1 mr-3">
          <h3 translate>Projects</h3>
        </div>
        <div fxLayout="row">
          <button mat-stroked-button color="primary" class="add-proj-button w-100" (click)="openCreateProjectDialog()">
            <mat-icon class="add-icon mr-2">add_circle</mat-icon>
            <span translate>Add Project</span>
          </button>
        </div>

      </div>

    </div>
    <!-- /Content -->

    <!-- Header options -->
    <div class="header-options" fxLayout="row" fxLayout.xs="column" fxHide.xs>
      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center">
        <div class="select-filter p-1" fxLayoutAlign="center center">
          <button disabled mat-button (click)="openFilterSidebar()" class="mt-n2">
            <svg-icon src="assets/svgs/filter.svg" class="text-center menu-icons" [applyCss]="true"
              [svgStyle]="{ 'width.px': 24, 'height.px': 24}" title="Filter"></svg-icon>
            <span class="ml-2 mt-1" translate>Filter</span>
          </button>
        </div>
        <div *ngFor="let menu of projectsMenu" fxLayoutAlign="center center">
          <div *ngIf="menu?.svgIcon" class="mt-n2"
            [ngClass]="{'selected-icons': menu?.id === selectedView, 'menu-icons': menu?.id !== selectedView}">
            <button mat-icon-button (click)="showProjectsList(menu)"  [matTooltip]="menu?.id | titlecase | translate">
              <div [@scaleOut]>
                <svg-icon [src]="menu?.svgIcon" class="text-center"
                  [applyCss]="true"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18}"></svg-icon>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--/ Header options -->
  </div>
</mat-card>

