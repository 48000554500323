<main class="px-3 py-1 border-bottom home-stream-header">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div>
        <mat-icon class="icon-success mt-1">
          {{networksHeader?.matIcon}}</mat-icon>
      </div>
      <div class="header-title">
        <h3 class="mb-0"><b>{{ networksHeader?.title | translate}}</b></h3>
      </div>
    </div>
  </div>
</main>
