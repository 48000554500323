import { Component, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { MembersTableList } from '../classes/members-table-list';

@Component({
  selector: 'kl-members-list-table',
  templateUrl: './members-list-table.component.html',
  styleUrls: ['./members-list-table.component.scss'],
  animations: kalgudiAnimations
})
export class MembersListTableComponent extends MembersTableList implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);

  }

  ngOnInit() {

    this.getMembers();

    // Initializes the KalgudiMatTableStream
    this.initTableStream(this.matPaginator, this.matSort);
  }

}
