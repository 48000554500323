<main class="edit-profile-basic">

  <!-- <div class="edit-profile-header">
    <h3 translate class="heading m-3">Edit profile</h3>
  </div> -->

  <form autocomplete="off" [formGroup]="profileForm" class="form-wrapper px-3 py-2 overflow-auto">
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Basic details -->
        <div>

          <!-- Name [readonly]="!isDigitalAssist"-->
          <div fxLayout="column" formGroupName="basicDetails">
            <label class="mb-0" translate>Title</label>
            <div class="mt-n1" fxLayout id="basic-profile-name">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput  [placeholder]="'Enter title' | translate" type="text" (input)="onInputChange($event)"
                (keypress)="onKeyPress($event, 'title')" formControlName="firstName" maxlength="200" required  [readonly]="!isSamFpoNameEditable">
              </mat-form-field>
            </div>
            <mat-error class="mt-n2" translate *ngIf="firstNameField?.hasError('required')">Please enter the title</mat-error>
            <mat-error class="mt-n2" translate *ngIf="firstNameField?.hasError('pattern')">Please enter a valid title</mat-error>
          </div>
          <!--/ Name -->

          <!-- Location -->
          <div>
            <div class="mt-2">
              <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName }}, </span>
              <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
              <!-- <label class="mb-0" translate>Location</label> -->
            </div>

            <div class="mt-n1" >

              <kl-google-places-autocomplete [useKalgudiGoogleLocation]="true" formControlName="location" [placeholder]="'Village / Town / City' | translate"></kl-google-places-autocomplete>
            </div>
          </div>
          <!-- /Location -->

          <div formGroupName="basicDetails">

            <div [fxLayout]="isShgApp ? 'column' : 'row'" fxLayoutGap="10px">

              <!-- Pin code -->
              <div fxLayout="column" [fxFlex]="isShgApp ? 'calc(100% - 10px)' : 'calc(50% - 10px)'">
                <label class="mb-0" translate>Pin code</label>
                <div class="w-100 mt-n1">
                  <mat-form-field class="w-100" appearance="outline">
                    <input matInput minlength="6" maxlength="6"
                      pattern="^[1-9]\d{5}$"
                      (keypress)="onKeyPress($event)"
                      formControlName="zipCode"
                      [placeholder]="'Enter pin code' | translate"
                      (keydown.space)="$event.preventDefault()">
                    </mat-form-field>
                    <mat-error translate class="error-msg mt-n2 mb-1" *ngIf="zipCode?.invalid">Please enter a valid pincode</mat-error>
                </div>
              </div>
              <!--/ Pin code -->

              <!-- Established since field -->
              <div *ngIf="!isShgApp">
                <div fxLayout="column" fxFlex="calc(50% - 10px)"
                  *ngIf="env.appId !== 'FPO_APP' && env.appId !== 'SAM_FPO' && env.appId !== 'FPO_NET_APP'">
                  <label class="mb-0" translate>Established since</label>
                  <div fxLayout="row" class="w-100 mt-n1">
                    <mat-form-field class="w-100" appearance="outline">
                      <input matInput formControlName="establishedSince" [placeholder]="'Enter established since' | translate"
                        (keydown.space)="$event.preventDefault()">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!--/ Established since field -->

              <!-- Established since and age SHGNET -->
              <div fxLayout="column" fxFlex="calc(100% - 10px)" *ngIf="isShgApp">
                <div class="d-flex justify-content-between">
                  <label class="mb-0" translate>Established since</label>
                  <span *ngIf="establishedSince.value" class="age-section"><b>Age : </b><span><b>{{yearsDiff || 0}}</b>yrs,
                      <b>{{monthsDiff || 0}}</b>m, <b>{{daysDiff || 0}}</b>d</span></span>
                </div>
                <div fxLayout="row" class="w-100" class="mt-n1">
                  <mat-form-field class="w-100" appearance="outline">
                    <input
                      matInput
                      [matDatepicker]="establishedDatePicker"
                      [max]="maxDate"
                      formControlName="establishedSince"
                      [placeholder]="'Enter established since' | translate"
                      readonly>
                    <mat-datepicker-toggle matSuffix [for]="establishedDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #establishedDatePicker panelClass="custom-date-picker"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <!--/ Established since and age SHGNET -->

              <!-- Established since field -->
              <div fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="env.appId === 'SAM_FPO'">
                <label class="mb-0" translate>Date of Incorporation</label>
                <div fxLayout="row" class="w-100" class="mt-n1">
                  <mat-form-field class="w-100" appearance="outline">
                    <input matInput [matDatepicker]="establishedDatePicker" [max]="maxDate" formControlName="establishedSince" [placeholder]="'Enter established since' | translate"
                      (keydown.space)="$event.preventDefault()" (focus)="establishedDatePicker.open()" readonly>
                    <mat-datepicker-toggle matSuffix [for]="establishedDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #establishedDatePicker panelClass="custom-date-picker"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <!--/ Established since field -->
            </div>

            <div fxLayout="row wrap" fxLayoutGap="10px">

              <!-- Farmers field -->
              <div fxLayout="column" fxFlex="calc(50% - 10px)" class="famer-count" *ngIf="env.appId !== 'SAM_FPO'">
                <label class="mb-0" translate>{{ environment?.appId === 'SHG_NET_APP' ? 'Members' : 'Farmers' }}</label>
                <div class="mt-n1" >
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput
                      (focus)="farmerFocus()"
                      (focusout)="farmerFocus('out')"
                      (keypress)="onKeyPress($event)"
                      formControlName="farmers"
                      type="number"
                      [placeholder]="environment?.appId === 'SHG_NET_APP' ? 'Enter members count' : 'Enter Farmers count' | translate">
                  </mat-form-field>
                </div>
              </div>
              <!--/ Farmers field -->

              <!-- Employees field -->
              <div fxLayout="column" fxFlex="calc(50% - 10px)" [ngClass]="{'employees-field': env.appId !== 'SAM_FPO'}">
                <label class="mb-0" translate>Employees</label>
                <div class="mt-n1" >
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput (focus)="employeeFocus()" min="0" (focusout)="employeeFocus('out')" #employees (keypress)="validateNumericInputLength($event,employees.value,'employees')" formControlName="employees" [maxlength]="env.appId === 'SAM_FPO' ? 4 : ''" type="number" [placeholder]="'Enter employees count' | translate" >
                  </mat-form-field>
                </div>
              </div>
              <!--/ Employees field -->

              <!-- Villages field -->
              <div fxLayout="column" fxFlex="calc(50% - 10px)" [ngClass]="{'villages-field-fpo': env.appId === 'SAM_FPO'}">
                <label class="mb-0" translate>Villages</label>
                <div class="mt-n1" >
                  <mat-form-field appearance="outline" class="w-100">
                    <input matInput (focus)="villagesFocus()" min="0" (focusout)="villagesFocus('out')" #villages (keypress)="validateNumericInputLength($event, villages.value,'villages')" formControlName="villages" type="number" [placeholder]="'Enter villages count' | translate" >
                  </mat-form-field>
                </div>
              </div>
              <!--/ Villages field -->

              <div fxFlex="calc(50% - 10px)" formGroupName="fpoAvgLand" [ngClass]="{'land-field': env.appId !== 'SAM_FPO'}">
                <label class="mb-0" translate>Land</label>
                <div fxLayout="row" class="mt-n1">
                  <!-- Land value field -->
                  <div fxLayout="column" fxFlex="50%" class="mr-1">
                    <mat-form-field appearance="outline" class="no-right-border">
                      <input matInput type="number" min="0" #land (keypress)="validateNumericInputLength($event, land.value, 'land')" (focus)="landFocus()" (focusout)="landFocus('out')" formControlName="value" [placeholder]="'Enter land size' | translate">
                    </mat-form-field>
                  </div>
                  <!--/ Land value field -->

                  <!-- Land unit field -->
                  <div fxLayout="column" fxFlex="50%">
                    <mat-form-field appearance="outline" class="no-left-border selection">
                      <mat-label translate>Unit</mat-label>
                      <mat-select formControlName="unit" >
                        <mat-option value="option" *ngFor="let size of sizeInList" [value]="size?.id">{{ size?.value | translate }}</mat-option>
                      </mat-select>
                      <mat-error translate>Please select the unit</mat-error>
                    </mat-form-field>
                  </div>
                  <!-- Land unit field -->
                </div>
              </div>

              <!-- Mobile number -->
              <div class="suffix-fields" fxLayout="column" fxFlex="calc(50% - 10px)">
                <label class="mb-0" translate>Mobile</label>
                <div class="mt-n1 w-100" >
                  <mat-form-field class="w-100" appearance="outline">
                    <input matInput
                      minlength="10" maxlength="10"
                      (keypress)="onKeyPress($event)"
                      placeholder="Title"
                      [placeholder]="'Enter mobile no' | translate"
                      formControlName="alternateMobileNo"
                      (keydown.space)="$event.preventDefault()">
                    <span matPrefix>
                      {{profileDetails?.mobileCode}}
                      <span class="mr-1 pl-1">|</span>
                    </span>
                  </mat-form-field>
                    <mat-error translate class="mt-n2 error-messages" *ngIf="mobileNoField?.hasError('pattern')">Please enter valid mobile number</mat-error>
                </div>
              </div>
              <!-- Mobile number -->
            </div>

            <!-- Contact -->
            <ng-container>
              <div fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">
                <!-- Email id -->
                <div fxLayout="column" fxFlex="100%">
                  <label class="mb-0" translate>Email</label>
                  <div class="mt-n1 w-100" >
                    <mat-form-field class="w-100" appearance="outline">
                      <input matInput type="email" placeholder="Title"  [placeholder]="'Enter email id' | translate" formControlName="altemailId" (keydown.space)="$event.preventDefault()">
                    </mat-form-field>
                    <mat-error translate class="mt-n2 error-messages" *ngIf="basicEmailId?.hasError('pattern')">Please enter valid email id</mat-error>
                  </div>
                </div>
                <!--/ Email id -->
              </div>
            </ng-container>
            <!-- / Contact -->
          </div>
        </div>
        <!-- / Basic details -->
      </div>

      <div class="w-100 mt-2">
        <kl-flat-button
          label="Update profile"
          [progress]="progress"
          (clicked)="updateProfile()"
          [disabled]="!profileForm?.valid">
        </kl-flat-button>
      </div>

    </div>

  </form>
</main>
