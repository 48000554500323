import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';

import { PageStreamComponent } from './page-stream/page-stream.component';
import { PageStreamApiService } from './services/page-stream-api.service';
import { PageStreamService } from './services/page-stream.service';



@NgModule({
  declarations: [PageStreamComponent],
  imports: [
    CommonModule,

    // Flex module
    FlexLayoutModule,

    // Kalgudi modules
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiShareUpdateModule
  ],
  exports: [
    PageStreamComponent
  ],
  providers: [
    PageStreamService,
    PageStreamApiService
  ]
})
export class KalgudiPageStreamModule { }
