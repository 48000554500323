import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { STORE_ADMIN_CONFIG, StoreAdminConfiguration } from './config';



@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
  ],
  exports: [ ],
  providers: [ ]
})
export class StoreAdminModule {

  static forRoot(config: StoreAdminConfiguration): ModuleWithProviders<StoreAdminModule> {

    return {
      ngModule: StoreAdminModule,
      providers: [
        {
          provide: STORE_ADMIN_CONFIG,
          useValue: config
        },
      ]
    };
  }
}
