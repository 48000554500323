import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { KalgudiPageTargetMembersModule } from '@kalgudi/pages-shared';
import { TranslateModule } from '@ngx-translate/core';

import {
  ProgramLocationRangeFilterComponent,
} from './components/program-location-range-filter/program-location-range-filter.component';
import {
  ProgramTargetMembersFilterComponent,
} from './components/program-target-members-filter/program-target-members-filter.component';
import { ProgramTargetMembersComponent } from './components/program-target-members.component';
import { PageShareFiltersApiService } from './services/page-share-filters-api.service';
import { PageShareFiltersService } from './services/page-share-filters.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    ProgramTargetMembersFilterComponent,
    ProgramLocationRangeFilterComponent,
    ProgramTargetMembersComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Material Modules
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatChipsModule,
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    ScrollingModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiSlideToggleModule,
    KalgudiPageTargetMembersModule,

    TranslateModule
  ],
  exports: [
    ProgramTargetMembersFilterComponent,
    ProgramLocationRangeFilterComponent,
    ProgramTargetMembersComponent,
  ],
  providers: [
    PageShareFiltersApiService,
    PageShareFiltersService,
  ]
})
export class KalgudiProgramTargetMembersModule { }
