import { Injector } from '@angular/core';
import { KalgudiUploadService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { S3PolicyPathCategoryMap } from '@kalgudi/types';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/**
 * @see https://stackoverflow.com/questions/53705057/how-to-implement-custom-image-uploader-for-ckeditor5-in-angular-5
 * @see https://ckeditor.com/docs/ckeditor5/latest/framework/guides/deep-dive/upload-adapter.html
 */
export class CkEditorUploadAdapter {

  private uploadService: KalgudiUploadService;
  private env: KalgudiEnvironmentConfig;

  constructor(
    private loader,
    private category: S3PolicyPathCategoryMap,
    private injector: Injector,
  ) {

    this.uploadService = this.injector.get(KalgudiUploadService);
    this.env           = this.injector.get(KL_ENV);
  }


  // To change the name of file
  upload = () => {

    return from(this.loader.file)
      .pipe(
        switchMap((file: File) => {
            const fileName = file.name + '_' + new Date().getTime();

            return this.uploadService.uploadFile(file, this.category, false, fileName);
          }),

          map(res => ({ default: this.env.domain + res.filePath })),

          catchError(() => of())
        )
        .toPromise();
  }

  abort = () => {
    console.log('UploadAdapter abort');
  }
}
