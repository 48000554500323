import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  UpdateScheduledSmsDialogComponent,
} from '../components/update-scheduled-sms-dialog/update-scheduled-sms-dialog.component';
import {
  UpdateScheduledSmsMobileDialogComponent,
} from '../components/update-scheduled-sms-mobile-dialog/update-scheduled-sms-mobile-dialog.component';



@Injectable()
export class KalgudiSmsUpdateService {

  constructor(private kalgudiDialogService: KalgudiDialogsService) { }


  /**
   * @param config Dialog
   */
  showScheduleDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openScheduleSendMobileDialog(details)
      : this.openScheduleSendDialog(details, config);
  }

  /**
   * Opens schedule send dialog
   */
  openScheduleSendDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(UpdateScheduledSmsDialogComponent, details, config);
  }

  /**
   * Opens schedule send dialog in mobile
   */
  openScheduleSendMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(UpdateScheduledSmsMobileDialogComponent, details );
  }

}
