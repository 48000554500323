<div class="members-target-filters">

  <form autocomplete="off" [formGroup]="formGroup">
    <div class="text-area-wrapper w-100">
      <mat-form-field class="w-100 position-relative target-members" appearance="outline" [ngClass]="{'disabled-field': !items.length}">
        <label class="form-label position-absolute text-secondary">{{ label | translate }}</label>
        <!-- <mat-label>Product</mat-label> -->
        <mat-icon matPrefix class="search-icon pl-2">search</mat-icon>

        <input class="ml-3" #autoCompleteInput matInput
          [placeholder]="searchPlaceholder | translate" aria-label="Products"
          [matAutocomplete]="autoComplete"
          formControlName="searchText"
          [readonly]="!items.length"/>

        <!-- Auto complete -->
        <mat-autocomplete #autoComplete="matAutocomplete" class="program-filters"
          [autoActiveFirstOption]="true"
          [displayWith]="autoCompleteDisplayFn">

          <h3 class="text-muted heading mt-1 pt-2 px-3 mb-0" translate>Search suggestions</h3>

          <!-- Virtual scroll is required for the large data sets in production -->
          <ng-container *ngIf="(filteredItems$ | async) as filteredItems">
            <cdk-virtual-scroll-viewport class="v-auto-list-container" [itemSize]="42">
              <ng-container *cdkVirtualFor="let product of filteredItems; let index=index;">
                <mat-option [value]="product" class="border-bottom product-list"
                  (onSelectionChange)="selectItem($event);">
                  <span>{{ product.value | translate }}</span>
                </mat-option>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </ng-container>

        </mat-autocomplete>
        <!-- / Auto complete -->

      </mat-form-field>

      <p class="mb-1 text-danger" *ngIf="!items.length">No {{ label }} available</p>

    </div>

    <!-- Selected items -->
    <div class="mt-n3 mb-4">
      <mat-chip-list class="kl-chip-list-sm">
        <mat-chip *ngFor="let item of itemsList; let index=index;"
          [selectable]="false"
          [removable]="true"
          (removed)="removeItem(index)">
          <span>{{ item?.value | translate }}</span>
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
    <!-- / Selected items -->

  </form>
</div>
