<div class="share-product-tile border py-2 cursor-pointer" (click)="openStore(shareProduct?.stores && shareProduct?.stores.length ? shareProduct?.stores[0] : ' '); $event.stopPropagation();">

  <div fxLayout="column" fxLayoutAlign="center center" class="w-100 position-relative">
    <div class="available p-1 px-2 position-absolute" fxFlexAlign="start" >
      <p class="m-0 text-black text-bold" translate>Attractive offer available</p>
    </div>

    <!-- Product image -->
    <div fxLayout class="position-relative mt-2">

      <img class="w-100" [src]="shareProduct?.productPicURL_level3 | prefixDomain | resizeImage:'100x100'" width="100" height="100">

      <!-- <div class="offer p-1 position-absolute" fxLayout fxLayoutAlign="start end">
        <p class="m-0 text-white">18% off</p>
      </div> -->
    </div>
    <!-- /Product image -->

    <!-- Product details -->
    <div fxFlex="100%" fxLayout="column" class="w-100" fxLayoutAlign="space-between center">

      <div class="mw-100">
        <p class="m-0 w-100 product-name text-truncate mw-100 px-1" [matTooltip]="shareProduct?.productName_level3">
          {{ shareProduct?.productName_level3 }}</p>
      </div>
      <div class="shop-now p-1 px-2">
        <p class="m-0 text-white" translate>{{ shareProduct?.storeType === 'SAM_CMP' ? 'Apply Now' : 'Shop Now' }}</p>
      </div>
    </div>
    <!-- /Product details -->
  </div>

</div>
