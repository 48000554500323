import { Injector, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyDetails, SurveyBasicDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { KalgudiSurveyService } from '../../../services/kalgudi-survey.service';
import { SurveyStateService } from '../../../services/survey-state.service';
import { SurveyActionService } from '../../surveys-list/services/survey-action.service';

@Directive()
export abstract class KalgudiSurveyInfoManagement extends KalgudiDestroyable {

  surveyDetails: KalgudiSurveyDetails;

  surveyForm: FormGroup;
  surveyFullDetails: KalgudiSurveyDetails;
  surveyId: string;

  progress: boolean;
  showEditForm = true;

  availableActionTypes = SurveyPageActions;

  private stateService: SurveyStateService;
  private fb: FormBuilder;
  private surveyService: KalgudiSurveyService;
  private notifications: KalgudiNotification;
  private surveyActionService: SurveyActionService;

  constructor(protected injector: Injector) {
    super();

    this.stateService        = this.injector.get(SurveyStateService);
    this.fb                  = this.injector.get(FormBuilder);
    this.surveyService       = this.injector.get(KalgudiSurveyService);
    this.notifications       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.surveyActionService = this.injector.get(SurveyActionService);

    this.surveyForm = this.surveyUpdateForm;

    this.stateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        survey => {
          this.onSurveyStateUpdate(survey);
        }
      );

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Survey info form group
   */
  private get surveyUpdateForm(): FormGroup {

    return this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      logo: [''],
      location: [''],
      expiryDate: [''],
      isResubmittable: [],
      isPublished: [],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Closes the edit form
   */
  closeEditForm(): void {
    this.showEditForm = false;
  }

  /**
   * Toggles the info form
   */
  toggleEditForm(): void {

    this.showEditForm  = true;
  }

  /**
   * Updates the survey details. Internally calls the api to
   * update the survey.
   */
  updateSurvey() {

    // Get survey details update payload
    const payload = this.prepareSurveyPayload(this.surveyForm);

    this.progress = true;

    this.surveyService.updateSurveyDetails(this.surveyId, payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onSurveyDetailsUpdate(res),
        err => this.onUpdateError(err)
      );
  }

  /**
   * Dispatches action to delete survey
   */
  deleteSurvey(): void {

    this.stateService.dispatchAction(SurveyPageActions.DELETE_SURVEY);

  }

  sendReminder(): void {
    this.stateService.dispatchAction(SurveyPageActions.SEND_REMINDER);
  }

  /**
   * Calls method to open clone survey dialog
   */
  addCloneSurveyDialog() {
    this.surveyActionService.showCloneSurveyDialog(this.surveyDetails);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Constructs survey updation payload.
   */
  protected prepareSurveyPayload(surveyForm: FormGroup): SurveyBasicDetails {
    return surveyForm.value;
  }

  /**
   * Calls after updating survey details successfully
   */
  protected onSurveyDetailsUpdate(res: KalgudiSurveyDetails): void {
    this.notifications.showMessage('Survey updated successfully');
    this.stateService.dispatchAction(SurveyPageActions.SURVEY_UPDATED, res);
    this.closeEditForm();
  }

  /**
   * Event handler for survey update API errors.
   */
  protected onUpdateError(err: Error): void {

    this.notifications.showMessage(err.message);
  }

  /**
   * Event handler called on survey state details update
   */
  private onSurveyStateUpdate(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
    this.surveyId = survey.surveyId;
    this.patchSurveyDetails(this.surveyDetails);
  }

  /**
   * Initializing the survey info form
   * @param surveyDetails
   */
  private patchSurveyDetails(surveyDetails: KalgudiSurveyDetails): void {
    this.surveyFullDetails = surveyDetails;
    this.surveyForm.patchValue(surveyDetails.surveyBasicDetails);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
