import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult, KalgudiSurveyDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-create-survey-mobile-dialog',
  templateUrl: './create-survey-mobile-dialog.component.html',
  styleUrls: ['./create-survey-mobile-dialog.component.scss']
})
export class CreateSurveyMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  extraCreationPayload: any;

  surveyData: KalgudiSurveyDetails;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,

  ) {
    super(dialogRef);

    this.extraCreationPayload = this.dialogData.data.creationPayload;
  }

  ngOnInit() {
  }

  /**
   * Calls after creating survey successfully
   */
  onSurveyCreation(survey: KalgudiSurveyDetails): void {
    this.surveyData = survey;
    this.ok();
  }


  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
        surveyDetails: this.surveyData
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
