<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{data?.title | translate}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content mx-2">
    <kl-social-form
    (closeDialog)="ok()"
    [profileKey]="profileKey"
    [socialFormDetails]="socialFormDetails">
    </kl-social-form>
  </div>
</div>
