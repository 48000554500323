import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { AttachmentList, FileMimeTypes, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { FpoProfiles } from '../../../classes/fpo-profiles';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Observable } from 'rxjs/internal/Observable';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map } from 'rxjs/internal/operators/map';
import { OrganisationProfileService } from '../../../services/organisation-profile.service';

@Component({
  selector: 'kl-finance-details-form',
  templateUrl: './finance-details-form.component.html',
  styleUrls: ['./finance-details-form.component.scss']
})
export class FinanceDetailsFormComponent extends FpoProfiles implements OnInit {

  isEditBankDetails: boolean;
  editBankDetailsIndex: number;
  isEditCreditHistory: boolean;
  editCreditHistoryIndex: number;
  isEditLicenses: boolean;
  editLicensesIndex: number;
  isEditAssetDetails: boolean;
  editAssetDetailsIndex: number;
  convertAssetValue: any;
  assetTypefilteredOptions$: Observable<any[]>;
  isAssetTypesMatched: boolean = true;
  savedAssetTypes: string[] = [];


  @Input() isEditFinanceDialog: boolean;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [FileMimeTypes.IMAGE];
  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  addingBankDetails: boolean;

  selectedLicenseName: string;
  selectedLicense: string;

  loanValue: any;
  loanAmountValidator: boolean = true;

  creditHistroyStatus: any[] = [
    { id: 'OVERDUE', value: 'Overdue' },
    { id: 'LIVE', value: 'Live' },
    { id: 'CLOSED', value: 'Closed' }
  ];

  licenseStatus: any[] = [
    { id: 'SEED', value: 'Seed' },
    { id: 'FERTILIZER', value: 'Fertilizer' },
    { id: 'PESTICIDE', value: 'Pesticide' },
    { id: 'APMC', value: 'APMC' },
    { id: 'WEIGHTS_MEASURES', value: 'Weights & measures' },
    { id: 'ORGANIC_CERTIFICATION', value: 'Organic certification' },
    { id: 'FSSAI', value: 'FSSAI' },
    { id: 'APEDA', value: 'APEDA' },
    { id: 'MPEDA', value: 'MPEDA' },
    { id: 'PARTICIPATORY_GUARANTEE', value: 'Participatory guarantee system certification' },
    { id: 'EXIM', value: 'EXIM' },
    { id: 'AGMARK', value: 'AGMARK' },
    { id: 'GST', value: 'GST' },
    { id: 'DIRECT_MARKETING', value: 'Direct marketing' },
    { id: 'UDYOG_AADHAAR', value: 'Udyog aadhaar' },
    { id: 'PAN', value: 'PAN' },
    { id: 'NONE', value: 'None' }
  ];

  assetsType: any[] = [
    { id: 'BUILDING', value: 'Building' },
    { id: 'CULTIVATOR', value: 'Cultivator' },
    { id: 'DISK_HARROW', value: 'Disk Harrow' },
    { id: 'HARVESTER', value: 'Harvester' },
    { id: 'LAND_LEVELER', value: 'Land leveler' },
    { id: 'MINI_TRUCK', value: 'Mini Truck' },
    { id: 'MOBILE_PROCESSING_UNIT', value: 'Mobile processing unit' },
    { id: 'NONE', value: 'None' },
    { id: 'OTHER', value: 'Other' },
    { id: 'PLOTS', value: 'Plots' },
    { id: 'PLOUGH', value: 'Plough' },
    { id: 'PUMP', value: 'Pump' },
    { id: 'RIDGER', value: 'Ridger' },
    { id: 'ROTAVATOR', value: 'Rotavator' },
    { id: 'SEED_DRILL', value: 'Seed drill' },
    { id: 'SPRAYER', value: 'Sprayer' },
    { id: 'TRACTOR', value: 'Tractor' },
    { id: 'TRANSPLANTER', value: 'Transplanter' },
    { id: 'TRUCK', value: 'Truck' }
  ];

  assetsStatus: any[] = [
    { id: 'OWNED', value: 'Owned' },
    { id: 'LEASED', value: 'Leased' }
  ];

  accountType: any[] = [
    { id: 'CURRENT_ACCOUNT', value: 'Current account' },
    { id: 'SAVING_ACCOUNT', value: 'Saving account' },
    { id: 'OVERDRAFT_ACCOUNT', value: 'OverDraft account' },
    { id: 'FIXED_DEPOSIT_ACCOUNT', value: 'Fixed deposit account' },
    { id: 'RECURRING_DEPOSIT_ACCOUNT', value: 'Recurring deposit account' }
  ];

  assetsTypeMap: any = {
    DISK_HARROW: 'Disk Harrow',
    PLOUGH: 'Plough',
    LAND_LEVELER: 'Land leveler',
    RIDGER: 'Ridger',
    CULTIVATION: 'Cultivation',
    SEED_DRILL: 'Seed drill',
    ROTAVATOR: 'Rotavator',
    PUMP: 'Pump',
    SPRAYER: 'Sprayer',
    TRANSPLANTER: 'Transplanter',
    TRACTOR: 'Tractor',
    HARVESTER: 'Harvester',
    MINI_TRUCK: 'Mini Truck',
    TRUCK: 'Truck',
    MOBILE_PROCESSING_UNIT: 'Mobile processing unit',
    PLOTS: 'Plots',
    BUILDING: 'Building',
    OTHER: 'Other',
    NONE: 'None',
  }

  assetsStatusMap: any = {
    OWNED: 'Owned',
    LEASED: 'Leased'
  }

  accountTypeMap: any = {
    SAVING_ACCOUNT: 'Savings',
    CURRENT_ACCOUNT: 'Current',
    OVERDRAFT_ACCOUNT: 'OverDraft',
    FIXED_DEPOSIT_ACCOUNT: 'Fixed deposit',
    RECURRING_DEPOSIT_ACCOUNT: 'Recurring deposit'
  }

  creditHistroyStatusMap: any = {
    OVERDUE: 'Overdue',
    LIVE: 'Live',
    CLOSED: 'Closed'
  }

  licenseStatusMap: any = {
    SEED: 'Seed',
    FERTILIZER: 'Fertilizer',
    PESTICIDE: 'Pesticide',
    APMC: 'APMC',
    WEIGHTS_MEASURES: 'Weights & measures',
    ORGANIC_CERTIFICATION: 'Organic certification',
    FSSAI: 'FSSAI',
    APEDA: 'APEDA',
    MPEDA: 'MPEDA',
    PARTICIPATORY_GUARANTEE: 'Participatory guarantee system certification',
    EXIM: 'EXIM',
    AGMARK: 'AGMARK',
    GST: 'GST',
    DIRECT_MARKETING: 'Direct marketing',
    UDYOG_AADHAAR: 'Udyog aadhaar',
    PAN: 'PAN',
    NONE: 'None'
  }
  isSaveEnable: boolean = false;
  localStorageAssets: any;
  isTypePanGst: boolean = false;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    private organizationProfileService: OrganisationProfileService
  ) {
    super(injector);

    this.subscribeToLicenseValueChanges();

    this.getLicenseType.valueChanges.subscribe(val => {
      this.isTypePanGst = (val === 'PAN' || val === 'GST');
    })

    // Removing zero values for all insurance fields;
    setTimeout(() => {
      let zeroValues = [
        this.cropInsuranceFarmersCount,
        this.lifeInsuranceFarmersCount,
        this.annadathaSurakshaFarmersCount,
      ]

      zeroValues.forEach(control => {
        if (control.value === 0) {
          control.patchValue('');
        }
      })
    }, 1000);
  }

  ngOnInit(): void {
    this.inputValueConvertor();
    // this.assetTypeValueChanges();
    // this.assetStatusValueChanges();
    // setTimeout(() => {
    //     if (this.isEditFinanceDialog) {
    //     // this.localStorageAssets = JSON.parse(localStorage.getItem('assetsFinance'));
    //     // this.assetDetails = this.localStorageAssets ?? [];
    //     // let details = Array.from(new Map(this.assetDetails.map(item => [item.assetType, item])).values());
    //     // this.assetDetails = details;
    //     }
    //   }, 1000)

    this.getAssetTypeDetails();
    this.updateAssetTypeList();
  }


  ngDoCheck() {
    if (this.bankDetails?.length ||
      this.creditHistoryDetails?.length ||
      this.licenseDetails?.length ||
      this.assetDetails?.length ||
      this.cropInsuranceFarmersCount?.value ||
      this.lifeInsuranceFarmersCount?.value ||
      this.annadathaSurakshaFarmersCount?.value) {
      this.isSaveEnable = true;
      this.isEditFinanceDialog = true;
    } else {
      this.isSaveEnable = false;
    }
  }

  /**
   * To filter asset-type when user search
   */
  getAssetTypeDetails() {
    this.assetTypefilteredOptions$ = this.getTypeField.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value?.value),
        map(name => name ? this._filterAssetType(name) : this.assetsType.slice())
      );
  }

  displayFn(asset: any): string {
    return asset ? asset : '';
  }

  private _filterAssetType(name: string): any[] {
    const filterValue = name.toLowerCase();
    this.assetTypeMatched();
    return this.assetsType.filter(option => option?.value?.toLowerCase().includes(filterValue));
  }

  /**
   * Get already saved assetType from assetDetails array
   */
  updateAssetTypeList() {
    setTimeout(() => {
      this.savedAssetTypes = this.assetDetails.map(asset => asset.assetType);
    }, 900);
  }

  /**
   * If the searched text matches the asset-types, return true; otherwise, return false.
   */
  assetTypeMatched() {
    let assetTypes = this.assetsType.map(val => val.value) ?? [];
    this.getTypeField.valueChanges.subscribe(val => {
        this.isAssetTypesMatched = this.organizationProfileService.checkingItemInList(val, assetTypes);
      });
  }

  /**
   * Subscribe to licence type value changes
   */
  subscribeToLicenseValueChanges() {
    this.licensesNamesField.valueChanges
      .subscribe(value => {
        this.selectedLicenseName = this.licenseStatusMap[value] + ' number';
        this.selectedLicense = this.licenseStatusMap[value];
        this.licenceNumberField.reset();
      });
  }

  /**
   * Asset type value changes
   */
  assetTypeValueChanges() {
    this.getTypeField.valueChanges.subscribe(val => {
      if (val) {
        this.getQuantity.reset();
        this.getAssetStatus.reset();
        this.getAssetValue.reset();
      }
    })
  }

  /**
   * Asset status value changes
   */
  assetStatusValueChanges() {
    this.getAssetStatus.valueChanges.subscribe(val => {
      if (val) {
        this.getAssetValue.reset();
      }
    })
  }

  /**
   * Not Allowing special characters and symbols
   * @param event
   * @returns
   */
  onKeyPressForSelectedLicense(event: any) {
    var x = event.which || event.keycode;

    if (this.selectedLicenseName === 'PAN' || 'GST') {
      return (x >= 47 && x <= 57) || (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || x == 45;
    }
  }

  /**
   * Add bank details to bank details array when click happens
   */
  addBankDetails() {
    this.getAccountNumber.patchValue(Number(this.getAccountNumber.value))
    if (!this.isEditBankDetails) {
      this.bankDetails.push(this.bankDetailsFields.value);
    }
    else {
      this.bankDetails.splice(this.editBankDetailsIndex, 1, this.bankDetailsFields.value);
    }
    this.isEditBankDetails = false;
    this.bankDetailsFields.reset();
  }

  /**
   * Add credit history details credit history array when click happens
   */
  addCreditHistoryDetails() {
    this.getTenureField.patchValue(Number(this.getTenureField.value))

    // Removing the commas from the loan-amount-available
    this.creditHistoryFields.value.loanAmountAvailed = this.creditHistoryFields.value.loanAmountAvailed.replace(/,/g, '');

    if (!this.isEditCreditHistory) {
      this.creditHistoryDetails.push(this.creditHistoryFields.value);
    }
    else {
      this.creditHistoryDetails.splice(this.editCreditHistoryIndex, 1, this.creditHistoryFields.value)
    }
    this.isEditCreditHistory = false;
    this.creditHistoryFields.reset();
  }

  /**
   * Add license details to license details array when click happens
   */
  addLicenseDetails() {
    const attachment = this.proofOfAttachments.value || '';
    this.proofAttachments.patchValue(attachment[0] && attachment[0].url ? attachment[0].url : "");

    if (!this.isEditLicenses) {
      this.licenseDetails.push(this.licensesFields.value);
    }
    else {
      this.licenseDetails.splice(this.editLicensesIndex, 1, this.licensesFields.value)
    }
    this.isEditLicenses = false;
    this.licensesFields.reset();
    this.proofOfAttachments.reset();
    this.selectedLicenseName = '';
  }

  /**
   * Removes an item from the array
   * @param index Index of licenses details to remove
   */
  editLicenseDetails(index: any) {
    this.isEditLicenses = true;
    this.editLicensesIndex = index;
    this.inputValueConvertor();
    this.licensesFields.patchValue(this.licenseDetails[index]);

    // Showing attached image
    const licenseAttachment = [];
    if (this.licenseDetails[index].attachment.length) {
      licenseAttachment.push({
        url: this.licenseDetails[index].attachment
      });
      this.proofOfAttachments.patchValue(licenseAttachment || '');
    } else {
      this.proofOfAttachments.patchValue('');
    }
  }

  /**
   * Add asset details to asset details array when click happens
   */
  addAssetDetails() {
    this.getQuantity.patchValue(Number(this.getQuantity.value))
    this.assetDetailsFields.value.assetValue = this.assetDetailsFields?.value?.assetValue?.replace(/,/g, '');
    if (!this.isEditAssetDetails) {
      this.assetDetails.push(this.assetDetailsFields.value);
    }
    else {
      this.assetDetails.splice(this.editAssetDetailsIndex, 1, this.assetDetailsFields.value)
    }
    this.isEditAssetDetails = false;
    this.assetDetailsFields.reset();
    this.updateAssetTypeList();
    this.isAssetTypesMatched = true;
  }

  /**
   * Removes an item from the array
   * @param index Index of bank details to remove
   */
  removeBankDetails(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this bank account details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.bankDetails.splice(id, 1);
        this.bankDetailsArray.value.splice(id, 1);
        this.bankDetailsFields.reset();
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of credit history details to remove
   */
  removeCreditHistory(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this credit history details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.creditHistoryDetails.splice(id, 1);
        this.creditHistoryArray.value.splice(id, 1);
        this.creditHistoryFields.reset();
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of license details to remove
   */
  removeLicense(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this licenses details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.licenseDetails.splice(id, 1);
        this.licensesArray.value.splice(id, 1);
        this.licensesFields.reset();
        this.selectedLicenseName = '';
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of asset details to remove
   */
  removeAssets(id: any) {
    let delConfirmed: any = this.organizationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this asset details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.assetDetails.splice(id, 1);
        this.assetDetailsArray.value.splice(id, 1);
        this.assetDetailsFields.reset();
        this.updateAssetTypeList();
        this.isAssetTypesMatched = true;
      }
    });
  }

  /**
   * Removes an item from the array
   * @param index Index of image to remove
   */
  removeImage(index: number) {
    const attachments: AttachmentList = this.proofOfAttachments?.value ?? '';
    attachments.splice(index, 1);
    this.proofOfAttachments.patchValue(attachments);
  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: any): void {
    let imgUrl = this.environment.s3BucketUrl + attachments;

    let imgObj = [
      {url: imgUrl}
    ]

    this.kalgudiPhotoswipe.open(imgObj).subscribe();
  }

  /**
   * To convert number into indian rupees notations
   * ex: from: 100000, to: 1,00,000
   */
  inputValueConvertor() {
    // Get loan value
    this.getLoan.valueChanges.subscribe(val => {
      if (val) {
        this.loanValue = this.organizationProfileService.addCommaToNumericFields(this.getLoan, this.loanValue);
        this.loanAmountValidator = parseInt(this.getLoan.value) > 0 && this.getLoan.value !== '';
      }
    })

    // Asset value
    this.assetValueField.valueChanges.subscribe(val => {
      if (val) {
        this.convertAssetValue = this.organizationProfileService.addCommaToNumericFields(this.assetValueField, this.convertAssetValue);
      }
    })
  }

  /**
   * Removes an item from the array
   * @param index Index of bank details to remove
   */
  editBankDetails(index: any) {
    this.isEditBankDetails = true;
    this.editBankDetailsIndex = index;
    this.bankDetailsFields.patchValue(this.bankDetails[index]);
  }

  /**
   * Removes an item from the array
   * @param index Index of credit histroy details to remove
   */
  editCrediHistroyDetails(index: any) {
    this.isEditCreditHistory = true;
    this.editCreditHistoryIndex = index;
    this.creditHistoryDetails[index].loanAmountAvailed = this.creditHistoryDetails[index].loanAmountAvailed.replace(/,/g, '');
    this.inputValueConvertor();
    this.creditHistoryFields.patchValue(this.creditHistoryDetails[index]);
  }

  /**
   * Removes an item from the array
   * @param index Index of asset details to remove
   */
  editAssetDetails(index: any) {
    this.isEditAssetDetails = true;
    this.editAssetDetailsIndex = index;
    this.assetDetailsFields.patchValue(this.assetDetails[index]);
  }

  onDestroyed(): void { }

  /**
   * Form group for bank details
   */
  get bankDetailsFields(): FormGroup {
    return this.financialDetailsForm.get('bankDetails') as FormGroup;
  }

  /**
   * Form group for credit history
   */
  get creditHistoryFields(): FormGroup {
    return this.financialDetailsForm.get('creditHistory') as FormGroup;
  }

  /**
   * Form group for license details
   */
  get licensesFields(): FormGroup {
    return this.financialDetailsForm.get('licenses') as FormGroup;
  }

  /**
   * Getter for license number
   */
  get licenceNumberField(): AbstractControl {
    return this.licensesFields.get('licenceNumber');
  }

  /**
   * Form group for asset details
   */
  get assetDetailsFields(): FormGroup {
    return this.financialDetailsForm.get('assetDetails') as FormGroup;
  }

  /**
   * Getter for asset value
   */
  get assetValueField(): AbstractControl {
    return this.assetDetailsFields.get('assetValue');
  }

  /**
   * Getter for bank name
   */
  get getBankName(): AbstractControl {
    return this.bankDetailsFields.get('bankName');
  }

  /**
   * Getter for branch name
   */
  get getBranchName(): AbstractControl {
    return this.bankDetailsFields.get('branch');
  }

  /**
   * Getter for account type
   */
  get getAccuntType(): AbstractControl {
    return this.bankDetailsFields.get('bankAccountType');
  }

  /**
   * Getter for account number
   */
  get getAccountNumber(): AbstractControl {
    return this.bankDetailsFields.get('accountNumber');
  }

  /**
   * Getter for ifsc
   */
  get getIfsc(): AbstractControl {
    return this.bankDetailsFields.get('ifscCode');
  }

  /**
   * Getter for license names
   */
  get licensesNamesField(): AbstractControl {
    return this.licensesFields.get('licenceName');
  }

  /**
   * Getter for license number
   */
  get licensesNumberField(): AbstractControl {
    return this.licensesFields.get('licenceNumber');
  }


  /**
   * Getter for loan amount availed
   */
  get getLoan(): AbstractControl {
    return this.creditHistoryFields.get('loanAmountAvailed');
  }

  /**
   * Getter for tenure
   */
  get getTenureField(): AbstractControl {
    return this.creditHistoryFields.get('tenure');
  }

  /**
   * Getter for availed from
   */
  get getAvailedField(): AbstractControl {
    return this.creditHistoryFields.get('lenderName');
  }

  /**
   * Getter for status
   */
  get getCreditStatusField(): AbstractControl {
    return this.creditHistoryFields.get('creditHistoryStatus');
  }

  /**
   * Getter for type of license
   */
  get getLicenseType(): AbstractControl {
    return this.licensesFields.get('licenceName');
  }

  /**
   * Getter for license number
   */
  get getLicenseNumberField(): AbstractControl {
    return this.licensesFields.get('licenceNumber');
  }

  /**
   * Getter for validity
   */
  get getValidityField(): AbstractControl {
    return this.licensesFields.get('validityDate');
  }

  /**
   * Getter for attachment
   */
  get getAttachmentImage(): AbstractControl {
    return this.licensesFields.get('lstOfAtachments');
  }

  /**
   * Getter for type
   */
  get getTypeField(): AbstractControl {
    return this.assetDetailsFields.get('assetType');
  }

  /**
   * Getter for attachment
   */
  get getAssetStatus(): AbstractControl {
    return this.assetDetailsFields.get('assetStatus');
  }

  /**
   * Getter for attachment
   */
  get getAssetValue(): AbstractControl {
    return this.assetDetailsFields.get('assetValue');
  }

  /**
   * Getter for other details
   */
  get getOtherDetails(): AbstractControl {
    return this.assetDetailsFields.get('otherDetails');
  }

  /**
   * Getter for quantity
   */
  get getQuantity(): AbstractControl {
    return this.assetDetailsFields.get('quantity');
  }
}
