<header id="kalgudi-core-header" class="w-100 fixed-top">
  <mat-toolbar class="header-nav overflow-hidden">
    <ng-container *ngIf="(headerState$ | async) as app">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="h-100 w-100 px-5 header mx-auto">

        <!-- Logo -->
        <ng-container>
          <!-- Desktop Header -->
          <button mat-button class="logo-head" (click)="goToHome()"
            fxHide fxShow.gt-md fxShow.gt-lg
            [disabled]="!app?.login"
            [ngClass]="{'disabled-home': !app?.login}">

            <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
              <div class="logo" fxLayoutAlign="center center" [ngClass]="{'prorise-logo': env?.appId === 'PRORISE_CORE'}">
                <img class="w-100 h-100" [src]="appConfig?.appLogoUrl" [alt]="appConfig?.title"/>
              </div>
              <div class="heading position-relative" fxLayout="column">
                <h1 fxLayoutAlign="start center" class="m-0" class="header-title" translate [ngClass]="{'prorise-heading': !app?.routeData?.showBackButton && env?.appId === 'PRORISE_CORE'}">{{appConfig?.title}}</h1>
                <span *ngIf="env?.appId === 'PRORISE_CORE'" class="prorise-des">Information & customer engagement</span>
                <!--<div class="header-chip position-absolute w-100">
                  <span class="text-white tag" translate>BETA</span>
                </div>-->
              </div>
            </div>
          </button>

          <!-- Mobile header -->
          <div class="w-100 mobile-toolbar" fxShow fxHide.gt-md fxHide.gt-lg>
            <div fxLayout="row" fxLayoutGap="26px" fxLayoutGap.xs="8px" fxLayoutAlign="space-between center" class="mobile-title">
              <button mat-icon-button *ngIf="app?.routeData?.showBackButton && app?.login"
                (click)="navigateBack(app?.routeData?.routerLink)">
                <mat-icon>keyboard_arrow_left</mat-icon>
              </button>

              <div *ngIf="!app?.routeData?.showBackButton"
                (click)="goToHome()" fxFlex="30px"
                [ngClass]="{'mb-1': !app?.routeData?.showBackButton && env?.appId === 'PRORISE_CORE'}">
                <button mat-button class="logo app-logo" fxLayoutAlign="center center">
                  <img class="w-100 h-100" [src]="appConfig?.appLogoUrl" [alt]="appConfig?.title"/>
                </button>
              </div>

              <div class="heading position-relative" [ngClass]="{'mobile-prorise': !app?.routeData?.showBackButton && env?.appId === 'PRORISE_CORE'}">
                <h1 class="m-0" class="app-title" [ngClass]="{'prorise-title': !app?.routeData?.showBackButton && env?.appId === 'PRORISE_CORE'}">{{ ( app?.routeData?.title && app?.login ? app?.routeData?.title : appConfig?.title ) | translate }}</h1>
                <span *ngIf="!app?.routeData?.showBackButton && env?.appId === 'PRORISE_CORE'" class="prorise-des mb-2">Information & customer engagement</span>
                <!--<div class="header-chip position-absolute w-100">
                  <span class="text-white tag" translate>BETA</span>
                </div>-->
              </div>
            </div>
          </div>

        </ng-container>
        <!-- / Logo -->


        <!-- Menu list -->
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="header-menu" >

          <!-- Web menu -->
          <ng-container *ngFor="let menu of webMenu">
            <ng-container *ngIf="!menu?.subMenu">
              <div class="header-menu-btn-wrapper" *ngIf="menu?.visible && ((app?.login && menu?.title !== 'Login') || (menu?.title === 'Login' && !app?.login))" fxShow.gt-md fxShow.gt-lg fxHide>
                <button class="menu-btn"
                  mat-button fxLayout="column"
                  fxLayoutAlign="start center"
                  [queryParams]="menu?.queryParams"
                  fxHide.lt-md
                  [disabled]="!menu?.routerLink || menu?.disabled"
                  [routerLink]="menu?.routerLink"
                  routerLinkActive="router-link-active">
                  <div fxFlex="33px" (click)="(menu?.routerLink)" class="">
                    <mat-icon *ngIf="menu?.matIcon">{{ menu?.matIcon }}</mat-icon>
                    <i class="{{ menu?.faIcon }}" *ngIf="menu?.faIcon"></i>
                    <svg-icon [applyCss]="true"
                      *ngIf="menu?.svgIcon"
                      [src]="menu?.svgIcon"
                      [svgStyle]="{ 'width.px': menu?.iconSize || 18, 'height.px': menu?.iconSize || 18 }"
                      class="header-icons"></svg-icon>
                  </div>
                  <div class="menu-title m-0 p-0">
                    <p class="m-0 pt-1" translate>{{ menu?.title }}</p>
                  </div>
                </button>
              </div>
            </ng-container>

            <ng-container *ngIf="menu?.subMenu">
              <div *ngIf="menu?.visible">
                <button class="menu-btn"
                  mat-button fxLayout="column"
                  fxLayoutAlign="start center"
                  fxHide.lt-md
                  [routerLink]="menu?.routerLink"
                  routerLinkActive="menu?.routerLink ? 'router-link-active' : ''"
                  *ngIf="(app?.login && menu?.title !== 'Login') || (menu?.title === 'Login' && !app?.login)"
                  [matMenuTriggerFor]="subMenuItem"
                  #menuTrigger="matMenuTrigger"
                  (mouseenter)="mouseEnter(menuTrigger, menu?.id)" (mouseleave)="mouseLeave(menuTrigger, menu.id)">
                  <div fxFlex="33px" (click)="(menu?.routerLink)">
                    <mat-icon *ngIf="menu?.matIcon">{{ menu?.matIcon }}</mat-icon>
                    <i class="{{ menu?.faIcon }}" *ngIf="menu?.faIcon"></i>
                    <svg-icon [applyCss]="true"
                      *ngIf="menu?.svgIcon"
                      [src]="menu?.svgIcon"
                      [svgStyle]="{ 'width.px': 16, 'height.px': 16 }" class="header-icons"></svg-icon>
                  </div>
                  <div class="menu-title m-0 p-0">
                    <p class="m-0 pt-1" translate>{{ menu?.title }}</p>
                  </div>

                  <mat-menu #subMenuItem="matMenu" class="sub-item-menu" [hasBackdrop]="false">
                    <span (mouseenter)="mouseEnter(menuTrigger, menu?.id)" (mouseleave)="mouseLeave(menuTrigger, menu?.id)">
                      <button class="mat-sub-menu-item" *ngFor="let subitem of menu?.subMenu" mat-menu-item
                        [queryParams]="subitem?.queryParams"
                        [routerLink]="subitem?.routerLink">
                        <svg-icon [applyCss]="true"
                          *ngIf="subitem?.svgIcon"
                          [src]="subitem?.svgIcon"
                          [svgStyle]="{ 'width.px': 17, 'height.px': 17 }" class="sub-menu-icon">
                        </svg-icon>
                        <mat-icon  class="m-0 sub-menu-icons" *ngIf="subitem?.matIcon">{{ subitem?.matIcon }}</mat-icon>
                        <span class="p-1" translate>
                          {{ subitem.title }}
                        </span>
                        <hr class="mx-3 my-2 mb-0">
                      </button>
                    </span>
                  </mat-menu>
                </button>
              </div>

            </ng-container>

          </ng-container>

          <!-- User profile -->
          <ng-container *ngIf="app?.login">
            <div *ngIf="(profile$ | async) as profile" fxHide.lt-md fxShow.gt-md fxShow.gt-lg fxHide>
              <button mat-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger" id="logged-in-profile-btn">
                <div fxLayout="column">
                  <div>
                    <img class="login-profile rounded-circle" id="profile-pic"
                      [src]="(profile?.profilePicUrl | resizeImage:'md') | prefixDomain"
                      [klErrorImages]="[
                        (profile?.profilePicUrl) | prefixDomain,
                        domain + defaultProfilePic
                      ]"
                    />
                    </div>
                  <div fxLayout="row" fxLayoutAlign="start center" class="w-100px" fxLayoutAlign="center">
                    <p class="mb-0 user-name mt-n2 text-truncate mw-100" id="profile-name"  [title]="profile?.firstName">
                      {{ profile?.firstName }}</p>
                  </div>
                </div>
              </button>
              <mat-menu #menu="matMenu" class="user-menu mt-n1 overflow-hidden" id="drop-down-menu-content" [hasBackdrop]="true">
                <div>
                  <!-- <div fxLayout="row" class="w-100" class="language-translation-options">
                    <button mat-button class="w-100 mx-n2" [ngClass]="{'lang-selected': selectedLang === 'en'}" (click)="languageTranslation('en')">
                      Eng
                    </button>
                    <div class="border-left"></div>
                    <button mat-button class="w-100 mx-n2" [ngClass]="{'lang-selected': selectedLang === 'hi'}" (click)="languageTranslation('hi')">
                      हि
                    </button>
                    <div *ngIf="env?.appId !== 'PRORISE_CORE'" class="border-left"></div>
                    <button *ngIf="env?.appId !== 'PRORISE_CORE'" mat-button class="w-100 mx-n2" [ngClass]="{'lang-selected': selectedLang === 'ba'}" (click)="languageTranslation('ba')">
                      বাং
                    </button>
                    <div *ngIf="env?.appId === 'PRORISE_CORE'" class="border-left"></div>
                    <button *ngIf="env?.appId === 'PRORISE_CORE'" mat-button class="w-100 mx-n2 te" [ngClass]="{'lang-selected': selectedLang === 'te'}" (click)="languageTranslation('te')">
                      తెలుగు
                    </button>
                    <div class="border-left"></div>
                    <button mat-button class="w-100 mx-n2" [ngClass]="{'lang-selected': selectedLang === 'ar'}" (click)="languageTranslation('ar')">
                      عربى
                    </button>
                  </div> -->
                  <ng-container *ngFor="let menu of profileMenu">
                    <button *ngIf="!menu?.subMenu" mat-menu-item id="my-profile-btn"
                      [ngClass]="{'hide-button': menu?.id === 'ADMIN' && !isMatchedProfile}"
                      [routerLink]="[menu?.routerLink]"
                      (click)="openAdminPage(menu?.id, profile?.profileKey)" fxLayoutAlign="start center">
                      <svg-icon [applyCss]="true" class="profile mr-2 ml-n2"
                      *ngIf="menu?.svgIcon"
                      [src]="menu?.svgIcon"
                      [svgStyle]="{ 'width.px': 20, 'height.px': 20}"></svg-icon>
                      <span translate class="ml-3">{{menu?.title}}</span>
                    </button>

                    <ng-container *ngIf="menu?.subMenu">
                      <div *ngIf="menu?.visible">
                        <button class="w-100"  mat-menu-item [routerLink]="[menu?.routerLink]" fxLayoutAlign="start center"
                          routerLinkActive="menu?.routerLink ? 'router-link-active' : ''"
                          *ngIf="(app?.login && menu?.title !== 'Login') || (menu?.title === 'Login' && !app?.login)"
                          #matMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="subMenuItem">
                          <div fxFlex="33px" (click)="(menu?.routerLink)">
                            <mat-icon *ngIf="menu?.matIcon">{{ menu?.matIcon }}</mat-icon>
                            <i class="{{ menu?.faIcon }}" *ngIf="menu?.faIcon"></i>
                            <svg-icon [applyCss]="true"
                              *ngIf="menu?.svgIcon"
                              [src]="menu?.svgIcon"
                              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }" class="profile mr-2 ml-n2"></svg-icon>
                          </div>
                          <div class="menu-title m-0 p-0">
                            <p class="m-0 pt-1" translate>{{ menu?.title }}</p>
                          </div>

                          <mat-menu #subMenuItem="matMenu" class="sub-item-menu" [hasBackdrop]="false">
                            <span (mouseenter)="matMenuenter()" (mouseleave)="matMenuLeave(menuTrigger,matMenuTrigger)">
                              <ng-container *ngIf="menu.id !== 'CHANGE_LANGUAGE'">
                                <button class="mat-sub-menu-item" *ngFor="let subitem of menu?.subMenu" mat-menu-item
                                  [queryParams]="subitem?.queryParams"
                                  [routerLink]="subitem?.routerLink">
                                  <svg-icon [applyCss]="true"
                                    *ngIf="subitem?.svgIcon"
                                    [src]="subitem?.svgIcon"
                                    [svgStyle]="{ 'width.px': 17, 'height.px': 17 }" class="sub-menu-icon">
                                  </svg-icon>
                                  <mat-icon  class="m-0 sub-menu-icons" *ngIf="subitem?.matIcon">{{ subitem?.matIcon }}</mat-icon>
                                  <span class="p-1" translate>
                                    {{ subitem.title }}
                                  </span>
                                  <hr class="mx-3 my-2 mb-0">
                                </button>
                              </ng-container>

                              <ng-container *ngIf="menu.id === 'CHANGE_LANGUAGE'">
                                <mat-radio-group class="language-selection">
                                  <mat-radio-button value="1" [checked]="selectedLang === 'en'" (click)="languageTranslation('en')">English</mat-radio-button>
                                  <mat-radio-button value="2" [checked]="selectedLang === 'hi'" (click)="languageTranslation('hi')">हिंदी</mat-radio-button>
                                  <mat-radio-button value="3" [checked]="selectedLang === 'ba'" *ngIf="env?.appId !== 'PRORISE_CORE'" (click)="languageTranslation('ba')">বাংলা</mat-radio-button>
                                  <mat-radio-button value="5" [checked]="selectedLang === 'te'" (click)="languageTranslation('te')">తెలుగు</mat-radio-button>
                                  <mat-radio-button value="4" [checked]="selectedLang === 'ar'" (click)="languageTranslation('ar')">عربى</mat-radio-button>
                                </mat-radio-group>
                              </ng-container>
                            </span>
                          </mat-menu>
                        </button>
                      </div>

                    </ng-container>

                  </ng-container>

                  <!-- Admin -->
                  <button mat-menu-item (click)="fpoAdminApp()" *ngIf="profile?.lstOfUserBusinessDetailsInfo[0]?.businessTypeName === bizType.FPO">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="18px">
                      <mat-icon class="new-window-icon ml-n2">launch</mat-icon>
                      <span class="menu ml-1" translate>Admin</span>
                    </div>
                  </button>
                  <!-- / Admin -->

                  <!-- Change password -->
                  <button mat-menu-item id="change-password-btn" (click)="openChangePasswordDialog(profile?.profileKey)" *ngIf="env?.appId !== 'SHAKTIMAN' && env?.appId !== 'PRORISE_CORE'">
                    <div fxLayoutAlign="start center" fxLayoutGap="20px">
                      <mat-icon class="ml-n2">vpn_key</mat-icon>
                      <span class="" translate>Change password</span>
                    </div>
                  </button>
                  <!-- / Change password -->

                  <!-- Credits management -->
                  <!-- <button mat-menu-item (click)="goToCredits()">
                    <div>
                      <mat-icon>stay_current_portrait</mat-icon>
                      <span>Credits management</span>
                    </div>
                  </button> -->

                  <!-- Logout -->
                  <button mat-menu-item (click)="logout()" id="logout-btn">
                    <div fxLayoutAlign="start center">
                      <svg-icon [applyCss]="true" class="profile mr-2 ml-n2" src="assets/svgs/log-out.svg"
                        [svgStyle]="{ 'width.px': 20, 'height.px': 20}"></svg-icon>
                      <span class="ml-3" translate>Log out</span>
                    </div>
                  </button>
                </div>
              </mat-menu>
            </div>
          </ng-container>
          <!-- /Web menu -->

          <!-- Mobile menu -->
          <div class="float-right" class="menu-button" fxLayoutAlign="end end" fxHide.gt-md fxHide.gt-lg>

            <button mat-icon-button (click)="onToggleSideNav()" class="nav-btn" *ngIf="app?.login" id="mobile-nav-hamburger-btn">
              <mat-icon>menu</mat-icon>
            </button>

            <button mat-icon-button (click)="goToLogin()" class="mobile-login-btn" *ngIf="!app?.login">
              <div fxLayout="column">
                <div class="login-icon">

                  <svg-icon [applyCss]="true" class="header-icons" src="assets/svgs/profile.svg"
                  [svgStyle]="{ 'width.px': 22, 'height.px': 22}"></svg-icon>
                </div>
                <div class="menu-title p-0 mx-0 mb-0">
                  <p class="m-0 p-0" translate>Login</p>
                </div>
              </div>
            </button>
          </div>
          <!-- /Mobile menu -->
        </div>
        <!-- /Menu list -->
      </div>
    </ng-container>
  </mat-toolbar>
</header>
