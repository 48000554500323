<div id="profile-update-stream">
  <ng-container *ngIf="(stream$ | async) as items">

    <!-- Wrapper for list of updates -->
    <ng-container *ngIf="items?.length">

      <div>

        <div id="updates-stream" *ngFor="let item of (stream$ | async); let index=index;">

          <div class="stream-width mx-auto">
            <!-- Share update tile -->
            <ng-container *ngIf="item?.activityType === socialActivityType?.CREATE_SHARE">
              <kl-share-update-tile
                *ngIf="!item?.promotionalTitle && (!item?.productsMetaData || item?.productsMetaData?.length === 0)"
                [postDetails]="item"
                [animationDelay]="index * 1">
              </kl-share-update-tile>

              <kl-promotional-share-tile
                *ngIf="item?.promotionalTitle || (!item?.promotionalTitle && item?.productsMetaData && item?.productsMetaData?.length > 0)"
                [postDetails]="item"
                [animationDelay]="index * 1">
              </kl-promotional-share-tile>
            </ng-container>

            <!-- QA tile -->
            <ng-container *ngIf="item?.activityType === socialActivityType?.CREATE_QUESTION">
              <kl-qa-tile
                [animationDelay]="index * 1"
                [postDetails]="item">
              </kl-qa-tile>
            </ng-container>
          </div>

        </div>
      </div>

      <!-- Load more -->
      <div class="m-3" *ngIf="(paginator$ | async) as paginator">
        <kl-load-more
          noMoreText="No more updates"
          [progress]="paginator?.loading"
          [hasMoreItems]="paginator?.hasItems"
          (clicked)="nextPage()">
        </kl-load-more>
      </div>
      <!--/ Load more -->

    </ng-container>
    <!-- / Wrapper for list of updates -->

    <!-- No content if there no updates -->
    <div *ngIf="(paginator$ | async) as paginator" fxLayout="row" class="w-100">
      <div *ngIf="!items?.length" class="h-100 w-100" fxLayoutAlign="center center">
        <kl-no-content fxHide.xs
          svgIcon="assets/svgs/updates.svg"
          text="No social activities to show"
          iconSize="md"
          progressText="Loading your social activities..."
          [progress]="paginator?.loading">
        </kl-no-content>
      </div>
    </div>
    <!-- No content if there no updates -->

  </ng-container>
</div>

