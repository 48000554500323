import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUsersMap } from '@kalgudi/types';
import { ProgramStateService } from '../../../../services/program-state.service';
import { PageActions } from '../../../../constants/index';

@Component({
  selector: 'kl-add-program-members-dialog',
  templateUrl: './add-program-members-dialog.component.html',
  styleUrls: ['./add-program-members-dialog.component.scss']
})
export class AddProgramMembersDialogComponent extends KalgudiDialog<AddProgramMembersDialogComponent> implements OnInit {

  /**
   * Modified set of selected users after performing actions on dialog.
   */
  selectedUsersMap: KalgudiUsersMap = {};

  constructor(
    protected dialogRef: MatDialogRef<AddProgramMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    protected util: KalgudiUtilityService,
    protected programState: ProgramStateService,
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }

  /**
   * Triggers when the child emitted an event
   */
  membersSelected(res: KalgudiUsersMap): void {
    this.selectedUsersMap = res;

    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: this.selectedUsersMap
    };
    this.dialogRef.close(result);
  }

  onMembersAdded() {
    this.programState.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATE);
    this.cancel();
  }
}

