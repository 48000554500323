import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult, KalgudiSurveyDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-clone-survey-mobile-dialog',
  templateUrl: './clone-survey-mobile-dialog.component.html',
  styleUrls: ['./clone-survey-mobile-dialog.component.scss']
})
export class CloneSurveyMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  surveyDetails: KalgudiSurveyDetails;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef
  ) {
    super(dialogRef);

    this.surveyDetails = dialogData.data.surveyDetails;
  }

  ngOnInit() {
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
