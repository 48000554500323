import { Component, Inject, Injector, OnInit } from '@angular/core';
import { floatTopAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveyMembersManagement } from '../../classes/kalgudi-survey-members-management';

@Component({
  selector: 'kl-survey-members-stream',
  templateUrl: './survey-members-stream.component.html',
  styleUrls: ['./survey-members-stream.component.scss'],
  animations: [ floatTopAnimation ],
})
export class SurveyMembersStreamComponent extends KalgudiSurveyMembersManagement implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

}
