import { Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiGeoLocationMarkerService } from '@kalgudi/common';
import {
  GeoLocationService,
  KALGUDI_S3_POLICY_MAP,
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiUploadService,
  KalgudiUtilityService,
  LazySyncApiError,
  S3UploadNamePathMap,
} from '@kalgudi/core';
import {
  Attachment,
  AttachmentList,
  AttachmentType,
  FileMimeTypes,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiFarmerLand,
  KalgudiLocation,
  KalgudiUser,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { delay, finalize, map, take, takeLast, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { GeotagConfirmDialogComponent } from '../components/geotag-confirm-dialog/geotag-confirm-dialog.component';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiAddLandDetails extends KalgudiDestroyable implements ControlValueAccessor {

  activeTabIndex: number;

  readonly TABS = {
    GEO_FENCE: { index: 0, title: 'Draw boundary', svgIcon: 'assets/svgs/farmer-profile/pencil-boundary.svg'},
    GEO_WALK: { index: 1, title: 'Walk & create the boundary', svgIcon: 'assets/svgs/farmer-profile/man-walking.svg'},
    GEO_TAG: { index: 2, title: 'Use current location', svgIcon: 'assets/svgs/farmer-profile/user-location.svg'},
  };

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  landDetailsForm: FormGroup;

  @Input()
  isAssisted: boolean;

  @Input()
  returnGeoTagDetails: boolean = false;

  @Output()
  attachmentsChange = new EventEmitter<Attachment[]>();

  @Output()
  geoTagDetails = new EventEmitter<any>();

  protected util: KalgudiUtilityService;

  progress = false;

  showSalinityLevel: boolean;

  cameraAttachment = new FormControl('');

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  currentPosition: { latitude: number; longitude: number; accuracy?: number };
  currentLocationProgress: boolean = true;
  isEditLand: boolean;

  locationLabel: string;
  currentLocation: any;
  geoTagInfo: boolean;

  profile: KalgudiUser;

  assistedUserLocation: KalgudiLocation;

  attachments: Attachment[] = [];
  inProgress: boolean;
  fileTitle: string;
  soilHealthCardField: any;

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT_PDF ];
  readonly s3CategoryDefault: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  private fb: FormBuilder;
  private farmerProfileService: KalgudiFarmerProfileService;
  private dialogsService: KalgudiDialogsService;
  private appService: KalgudiAppService;
  private kalgudiGeoLocationMarkerService: KalgudiGeoLocationMarkerService;
  private profileStateService: KalgudiProfileStateService;
  protected uploadService: KalgudiUploadService;
  protected geoLocationService: GeoLocationService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.fb                              = this.injector.get(FormBuilder);
    this.util                            = this.injector.get(KalgudiUtilityService);
    this.farmerProfileService            = this.injector.get(KalgudiFarmerProfileService);
    this.dialogsService                  = this.injector.get(KalgudiDialogsService);
    this.appService                      = this.injector.get(KalgudiAppService);
    this.kalgudiGeoLocationMarkerService = this.injector.get(KalgudiGeoLocationMarkerService);
    this.profileStateService             = this.injector.get(KalgudiProfileStateService);
    this.uploadService                   = this.injector.get(KalgudiUploadService);
    this.geoLocationService              = this.injector.get(GeoLocationService);

    this.landDetailsForm = this.commonLandDetailsForm;

    this.profileStateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res =>  this.profile = res
      )

    this.subscribeToSoilHealthCardOnValueChanges();
  }

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.attachments = obj;
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------

  get locationField(): AbstractControl {

    return this.landDetailsForm.get('location');
  }

  get landSize(): AbstractControl {
    return this.landDetailsForm.get('landSize');
  }

  get landSizeUnit(): AbstractControl {
    return this.landSize.get('unit');
  }

  get landSizeValue(): AbstractControl {
    return this.landSize.get('value');
  }

  get geoFences(): AbstractControl {
    return this.landDetailsForm.get('geoFences');
  }

  get salinity(): AbstractControl {
    return this.landDetailsForm.get('salinity');
  }

  /**
   * Getter for attachments as array.
   */
  get landAttachments(): FormArray {
    return this.landDetailsForm.get('attachments') as FormArray;
  }

  /**
   * Getter for attachments control
   */
  get lstOfAttachmentsControl(): AbstractControl {
    return this.landDetailsForm.get('attachments');
  }

  /**
   * Getter for soil health card
   */
  get soilHealthCardFiled(): AbstractControl {
    return this.landDetailsForm.get('soilHealthCard');
  }

  /**
   * Getter for location label
   */
  get drawBoundaryLocationLabel() {
    this.assistedUserLocation = this.profile.lstOfUserBusinessDetailsInfo[0].locationTo;

    // if(this.isAssisted && !this.landDetails && this.assistedUserLocation) {

    //   this.locationField.patchValue(this.assistedUserLocation);

    // }

    const drawLocationLabel = (this.locationField.value.districtName ? this.locationField.value.districtName + ', ' : '')  +
      (this.locationField.value.stateName ? this.locationField.value.stateName + ', ' : '') +
      (this.locationField.value.countryName ? this.locationField.value.countryName : '') +
      (this.locationField.value.countryName && this.locationField.value.postalCode ? ' - ': '') +
      (this.locationField.value.postalCode ? this.locationField.value.postalCode : '');

    return drawLocationLabel;
  }

  /**
   * Getter for showing pin for edit land which is created through geo tagging land
   */
  get showPin() {
    return this.landDetails && this.landDetails.landId && !this.isEditLand
      && (this.landDetails.geoFences && !this.landDetails.geoFences.length)
      && (this.landDetails.location
      && !this.landDetails.location.countryName
      && !this.landDetails.location.countryShortName
      && !this.landDetails.location.stateName
      && !this.landDetails.location.locationShort
      && !this.landDetails.location.locationLong
      ) && (this.landDetails.location.longitude === this.locationField.value.longitude
        && this.landDetails.location.latitude === this.locationField.value.latitude);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Subscribes to soil health card on value changes
   */
  subscribeToSoilHealthCardOnValueChanges() {
    this.landDetailsForm.get('soilHealthCard').valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.soilHealthCardField = res;
        }
      );
  }

  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    const attachments: AttachmentList = this.lstOfAttachmentsControl.value || [];

    attachments.splice(index, 1);

    this.lstOfAttachmentsControl.patchValue(attachments);
  }

  /**
   * Removes an item from the array
   *
   * @param index Index of soil health card to remove
   */
  removeFiles(index: number) {
    this.soilHealthCardField.splice(index, 1);
  }

  /**
   * Subscribe to camera images
   */
  subscribeToImageCaptures(): void {

    this.cameraAttachment.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {

          const images = Array.isArray(this.lstOfAttachmentsControl.value) ? this.lstOfAttachmentsControl.value : [] ;
          images.push(res);

          this.lstOfAttachmentsControl.patchValue(images);

        });
  }

  /**
   * Calls api to add the land details
   */
  addLandDetails(): void {

    const payload = this.preparePayload(this.landDetailsForm.value);

    this.progress = true;

    if(!this.returnGeoTagDetails) {
      this.farmerProfileService.postLandDetails(payload)
        .pipe(
          finalize(() => this.progress = false)
        )
        .subscribe(
          res => {
            this.addLandDetailsHandler(res)
          },
          err => {
            if (err instanceof LazySyncApiError) {
              this.addLandDetailsHandler(null);
            }

            this.util.showApiErrorMessage(err);
          }
        );
    } else {
      delete payload.fieldNotes;
      delete payload.irrigationType;
      delete payload.landCoverType;
      delete payload.landName;
      delete payload.salinity;
      delete payload.salinityLevel;
      delete payload.soilHealthCard;
      delete payload.soilType;
      delete payload.waterLevel;

      this.geoTagDetails.emit(payload)
    }

  }

  /**
   * Initializes the form
   */
  init(): void {

    this.landDetailsForm.patchValue(this.landDetails);
  }


  /**
   * Gets the selected files
   * @param event
   */
  onFileSelection(event: any): void {

    this.uploadFiles(event.target.files, this.s3CategoryDefault, true);
  }


  /**
   * Uploads a list of files to S3. On success it returns the uploaded file path
   * with the original file name.
   * @param files
   * @param path
   * @param compression
   */
  uploadFiles(files: FileList, path, compression: boolean): void {

    this.inProgress = true;
    const fileList = Array.from(files);
    this.fileTitle = fileList[0].name;

    this.uploadService.uploadFiles(fileList, path, compression)
      .pipe(
        map(r => this.mapUploadResponse(r)),

        finalize(() => this.inProgress = false)
      )
      .subscribe(res => this.injectAttachments(res));
  }

  /**
   * Injects the uploaded attachments into an array
   * @param attachments
   */
  injectAttachments(attachments: Attachment[]) {

    this.attachmentsChange.next(attachments);

    this.landDetailsForm.patchValue({
      soilHealthCard: attachments
    });
  }

  /**
   * Maps the response into attachment model
   * @param uploads
   */
  private mapUploadResponse(uploads: S3UploadNamePathMap[]): Attachment[] {

    const geoLocation = this.geoLocationService.currentLatLong;

    return uploads.map(u => {

      return {
        url: u.filePath,
        context: this.fileTitle,
        msgType: this.getAttachmentType(u.fileType),
        geoLocation
      };
    });
  }

  /**
   * Gets attachment type based on the uploaded file type.
   */
  private getAttachmentType(fileType: string = ''): AttachmentType {

    const trimmedFileType = fileType.substring(0, fileType.indexOf('/') + 1) + '*';

    // Default attachment type
    let attachmentType: AttachmentType = 'DOCUMENT';

    if (trimmedFileType === FileMimeTypes.IMAGE) {
      attachmentType = 'IMAGE';
    }

    return attachmentType;
  }

  /**
   * Shows confirm dialog
   */
  displayGeotagDialog(tab) {
    if (tab.index === 2) {
      this.showConfirmDialog()
        .subscribe(res => {

          this.activeTabIndex = res.accepted ? 2 : 0;
          this.geoTagInfo = true;
          if(!res.accepted && this.activeTabIndex === 0) {

            if(this.landDetails && this.landDetails.landId) {

              this.geoFences.patchValue(this.landDetails.geoFences);
              this.locationField.patchValue(this.landDetails.location);
            } else {
              this.locationField.patchValue(this.currentLocation);
            }
          }

        },
        err => {
          this.activeTabIndex = 0;

          if(this.landDetails && this.landDetails.landId) {

            this.geoFences.patchValue(this.landDetails.geoFences);
            this.locationField.patchValue(this.landDetails.location);
          } else {
            this.locationField.patchValue(this.currentLocation);
          }
        });
    }
  }

  /**
   * Displays confirmation dialog
   */
  showConfirmDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirmation',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openConfirmDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        // filter(r => r && r.accepted),

        // map(r => r.accepted)
      );
  }

  /**
   * Get current location
   * @param shouldSearchInGoogle
   */
  getCurrentLocation(edit?: boolean): void {

    this.currentLocationProgress = true;

    this.isEditLand = edit ? true : false;

    // if(this.isEditLand) {
      // this.showRemoveGeoFenceConfirmationDialog('This will reset your current geo fencing and land area. Are you sure you want to use your current location?')
      //   .pipe(

      //     tap(
      //       res => {
      //         if(res.accepted) {
      //           this.fetchCurrentLocation();
      //         } else {
      //           this.currentLocationProgress = false;
      //           this.isEditLand = false;
      //           this.geoFences.patchValue(this.landDetails.geoFences);
      //           this.locationField.patchValue(this.landDetails.location);
      //         }
      //       }
      //     )

      //   )
      //   .subscribe();

    // } else {
      this.fetchCurrentLocation();
    // }

    setTimeout(() => {

      if(!this.currentPosition) {
        this.currentLocationProgress = false;

        if(!this.currentLocationProgress && !this.isEditLand && !this.landDetails) {

          const loggedInUserLocation = this.appService.loggedIn ? this.appService.profileLocal.lstOfUserBusinessDetailsInfo[0].locationTo : {};

          this.locationField.patchValue(loggedInUserLocation);
        }
      }
    }, 500);

  }

  /**
   * On location location change showing confirmation dialog
   * @param location
   */
  locationChanged(location) {

    if((this.landDetails && this.landDetails.landId && this.landDetails.location) && (location.locationLong !== this.landDetails.location.locationLong)) {

      this.showRemoveGeoFenceConfirmationDialog('This will reset your current geo fencing and land area. Are you sure you want to change location?')
        .pipe(

          tap(res => {
            if (res.accepted) {
              this.geoFences.patchValue([]);
            } else {
              this.locationField.patchValue(this.landDetails.location);
              this.geoFences.patchValue(this.landDetails.geoFences);
              this.isEditLand = false;
            }
          })

        )
        .subscribe();
    }
  }

  /**
   * Subscribe to geo fence value changes to check current area
   */
  subscribeToGeoFenceValueChanges() {
    this.geoFences.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {

          if(res && res.length) {

            const latLng = {latitude: res[0].lat, longitude: res[0].lng}

            if (latLng.longitude && latLng.latitude) {

              this.kalgudiGeoLocationMarkerService.searchOnGoogle(latLng)
                .pipe(take(1))
                .subscribe(
                  (res: any) => {

                    const currentLocation = res.kalgudiLocation;

                    if(this.currentLocation && ((this.currentLocation.districtName !== currentLocation.districtName) || (this.currentLocation.stateName !== currentLocation.stateName))) {

                      this.locationField.patchValue(currentLocation);
                    }
                  }
                )
            }

          }
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Called after adding land details successfully
   */
  protected addLandDetailsHandler(res: KalgudiUser) {}

  /**
   * Prepares the payload
   */
  protected preparePayload(value: KalgudiFarmerLand): KalgudiFarmerLand {

    let payload = this.util.clone(value);

    if (this.landDetails && this.landDetails.landId) {
      payload = {...this.landDetails, ...payload};
    }

    payload.waterLevel.value = +payload.waterLevel.value || 0;
    payload.landSize.value   = +payload.landSize.value || 0;

    return payload;
  }

  /**
   * Converts an area to current selected unit and updates the area to the area field.
   */
  protected convertAndUpdateArea(areaInAcres: number) {

    const landSizeUnit: 'acre' |'hectare' |'feddan' = this.landSizeUnit.value;

    let area = areaInAcres;

    if (landSizeUnit === 'hectare') {
      area = areaInAcres * 0.404686;
    } else  if(landSizeUnit === 'feddan') {
      area = areaInAcres * 1.03;
    }

    this.landSizeValue.patchValue(area.toFixed(2));
  }

  /**
   * Returns the land details form group
   */
  private get commonLandDetailsForm(): FormGroup {

    const loggedInUserLocation = this.appService.loggedIn ? this.appService.profileLocal.lstOfUserBusinessDetailsInfo[0].locationTo : {};

    return this.fb.group({
      landName: ['', Validators.required],
      location: ['', Validators.required],
      soilType: [''],
      landSize: this.fb.group({
        unit: ['acre', Validators.required],
        value: [0, Validators.required]
      }),
      waterLevel: this.fb.group({
        value: [0],
        unit: ['']
      }),
      irrigationType: [''],
      salinity: [''],
      salinityLevel: [''],
      geoFences: [],
      attachments: [[]],
      landCoverType: [''],
      fieldNotes: [''],
      soilHealthCard: [[]]
    });
  }

  /**
   * Shows the confirm web or mobile dialog
   */
  private openConfirmDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(GeotagConfirmDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Handles search observer
   * @param observer
   */
  private handleSearchObserver(observer: Observable<any>) {

    this.currentLocationProgress = true;
    observer.pipe(
      takeLast(1),
      delay(1000),
      finalize(() => {
        this.currentLocationProgress = false;
      })
    ).subscribe(
      res => {
        if(res) {
          this.currentLocation = res.kalgudiLocation;
          this.currentLocationProgress = false

          if ((this.landDetails && this.landDetails.landId && this.isEditLand) || !this.landDetails || !this.landDetails.landId) {
            this.geoFences.patchValue([]);
            this.locationField.patchValue(res.kalgudiLocation);

          } else {
            this.geoFences.patchValue(this.landDetails.geoFences);
            this.locationField.patchValue(this.landDetails.location);
          }

          this.locationLabel = (this.currentLocation.districtName ? this.currentLocation.districtName + ', ' : '')  +
            (this.currentLocation.stateName ? this.currentLocation.stateName + ', ' : '') +
            (this.currentLocation.countryName ? this.currentLocation.countryName : '') +
            (this.currentLocation.countryName && this.currentLocation.postalCode ? ' - ': '') +
            (this.currentLocation.postalCode ? this.currentLocation.postalCode : '');
        }

      },
      err => {
        this.currentLocationProgress = false;

        if (!this.landDetails) {

          const loggedInUserLocation = this.appService.loggedIn ? this.appService.profileLocal.lstOfUserBusinessDetailsInfo[0].locationTo : {};

          this.locationField.patchValue(loggedInUserLocation);
        }
      }
    );

    setTimeout(() => {

      if(!this.currentLocation) {
        this.currentLocationProgress = false;
      }
    }, 500);

  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showRemoveGeoFenceConfirmationDialog(message: string): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Ok',
      rejectButtonTitle: 'Cancel',
      message: message,
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r),
      );
  }

  /**
   * Fetch current current location using current location coordinates
   */
  private fetchCurrentLocation() {
    this.kalgudiGeoLocationMarkerService.getGeoCoords()
      .pipe(
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.currentPosition = res;
        const observer = this.kalgudiGeoLocationMarkerService.searchOnGoogle(this.currentPosition);
        this.handleSearchObserver(observer);
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
