import { Injector, Directive } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfile } from '../../../classes/kalgudi-profile';
import { ProfilePageActions } from '../../../constants';
import {
  EditCommonProfileDetailsDialogService,
} from '../../profile-basic-edit/services/edit-common-profile-details-dialog.service';

@Directive()
export abstract class KalgudiFarmerProfile extends KalgudiProfile {

  private editCommonProfileDetailsDialogService: EditCommonProfileDetailsDialogService;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService
  ) {
    super(injector, util);

    this.editCommonProfileDetailsDialogService = this.injector.get(EditCommonProfileDetailsDialogService);
  }

  showEditBasicDetailsDialog() {
    this.editCommonProfileDetailsDialogService.showEditEmployeeProfileDialog()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();
  }

  protected onProfileStateUpdate(action: { type: ProfilePageActions; payload?: any; }): void {

    if (action.type === ProfilePageActions.SHOW_COMMON_PROFILE_EDIT_DIALOG) {
      this.showEditBasicDetailsDialog();
    }
  }
}
