<div
  class="p-2 bg-white border selectable-tile-wrapper"
  [title]="author?.isMember ? (author?.userRoles) : ''"
  [ngClass]="{'selectable-tile': isSelected, 'isMember-title': author?.isMember, 'tile-elevation': enableElevation, 'cursor-pointer': !author?.isMember, 'mt-3': author?.isMember}"
  [@fadeIn]>

   <!-- Tagging for only those who are already a part of the group -->
  <div *ngIf="author?.isMember" class="is-follower text-right">
    <small translate>Already {{ (author?.userRoles | lowercase) === 'admin' ? "an admin" : "a " + (author?.userRoles | lowercase) }}</small>
  </div>

  <section class="selectable-card">

    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between" fxLayout.xs="column">
      <div fxFlex="100%" class="mw-100 w-100">

        <div class="select-tile" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">

          <!-- User profile pic -->
          <div class="position-relative" fxFlex="50px">
            <div fxLayoutAlign="center center" class="profile-pic rounded-circle"
              [ngClass]="{'medium': avatarSize == 'md', 'small': avatarSize == 'sm', 'large': avatarSize == 'lg'}">
              <img class="w-100 h-100 rounded-circle avatar"
                [src]="(author?.profilePicUrl || author?.profilePicURL | resizeImage:'sm') | prefixDomain"
                [klErrorImages]="[
                (author?.profilePicUrl || author?.profilePicURL) | prefixDomain,
                domain + defaultProfilePic
                ]"
              />
            </div>
            <div fxLayout="row">
              <div class="position-absolute selected-tile rounded-circle" *ngIf="isSelected"
                fxLayoutAlign="center center">
                <div fxLayoutAlign="center center">
                  <mat-icon class="done-icon">done</mat-icon>
                </div>
              </div>
            </div>
          </div>

          <!-- User Details -->
          <div class="tile-details mw-100" fxFlex="calc(100% - 58px)">
            <div fxLayout="column" class="w-100">
              <div fxLayout="row" class="mr-2">
                <div fxFlex="88%">
                  <a *ngIf="navigationAllowed"> <span (click)="viewProfile(author?.profileKey)"
                    class="text-capitalize user-name" title="{{ author?.firstName }}">{{ author?.firstName }}</span></a>
                  <span *ngIf="!navigationAllowed" class="text-capitalize user-name" title="{{ author?.firstName }}">{{ author?.firstName }}</span>
                </div>
                <div class="remove-icon" fxFlex *ngIf="showRemove" (click)="removeUser()" [matTooltip]="'Remove' | translate"
                  matTooltipPosition="right">
                  <mat-icon class="clear-icon cursor-pointer text-right">clear</mat-icon>
                </div>
              </div>
              <!-- Location -->
              <div fxLayout="column" class="mw-100">
                <span class="biz-name text-capitalize mb-2" title="{{ author?.businessTypeName | translate }}">{{ author?.businessTypeName | translate }}</span>
                <span class="text-muted text-capitalize user-location" *ngIf="!author?.locationDetails?.placeName" title="author?.location || author?.locationLong">{{ author?.location || author?.locationLong }}</span>
                <span class="text-muted text-capitalize user-location"
                  *ngIf="author?.locationDetails?.placeName"
                  title="{{author?.locationDetails?.placeName }}, {{author?.locationDetails?.locationShort}}">
                  {{author?.locationDetails?.placeName }},
                  {{author?.locationDetails?.locationShort}}
                </span>
              </div>
              <!-- distance -->
              <div class="mt-1" *ngIf="author?.distance">
                <span class="text-muted text-capitalize user-location">{{author?.distance}} KM</span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</div>
