import { Injectable, NgZone } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { GooglePlacesPrediction, KalgudiGooglePlaceMap } from '../models/index';
import { GooglePlacesKalgudiLocationMapService } from './google-places-kalgudi-location-map.service';

/// <reference types="@types/googlemaps" />

declare var google: any;

@Injectable()
export class KalgudiGooglePlacesApiService {

  constructor(
    private ngZone: NgZone,
    private googlePlacesLocationMapService: GooglePlacesKalgudiLocationMapService,
  ) { }



  getPlaceDescription(placeId: string, placeAddress?: string): Observable<KalgudiGooglePlaceMap> {

    if (!placeId) {
      throwError('Invalid google place Id');
    }

    const self = this;

    return new Observable<any>(observer => {

      const suggestionsCallback = (response: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {

        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          observer.error('Unable to get Google Places prediction');

          return;
        }

        self.ngZone.run(() => {

          const placeDetails = {
            googlePlaceDetails: response,
            ...this.googlePlacesLocationMapService.mapToKalgudiLocation(response, placeAddress)
          };

          // placeDetails.kalgudiLocation.googleAddress = placeAddress;

          observer.next(placeDetails);
          observer.complete();
        });

      };

      const placesService = new google.maps.places.PlacesService(document.getElementById('google-places-auto-complete') as HTMLDivElement);

      placesService.getDetails({ placeId }, suggestionsCallback);
    });
  }

  getQueryPredictions(input: string): Observable<GooglePlacesPrediction[]> {

    if (!input) {
      throwError('Cannot perform search on empty string');
    }

    const self = this;

    return new Observable<GooglePlacesPrediction[]>(observer => {

      let result: GooglePlacesPrediction[] = [];

      const suggestionsCallback = (predictions: google.maps.places.AutocompletePrediction[], status: string) => {

        // if (status !== google.maps.places.PlacesServiceStatus.OK) {
        //   observer.error('Unable to get Google Places prediction');

        //   return;
        // }

        self.ngZone.run(() => {

          result = predictions ? this.mapAutocompletePredictionsToGooglePlace(predictions) : [];

          observer.next(result);
          observer.complete();
        });

      };

      const autoCompleteService = new google.maps.places.AutocompleteService();

      var options = {
        input: input,
        componentRestrictions: {country: "in"}
      }

      autoCompleteService.getPlacePredictions(options, suggestionsCallback);
      // autoCompleteService.getQueryPredictions({ input }, suggestionsCallback);
    });
  }

  /**
   * Maps auto complete predictions result to GooglePrediction array.
   */
  private mapAutocompletePredictionsToGooglePlace(predictions: google.maps.places.AutocompletePrediction[]): GooglePlacesPrediction[] {

    return predictions.map(prediction => {
      return {
        placeId: prediction.place_id,
        description: prediction.description,
        types: prediction.types
      };
    });
  }
}
