<div *ngIf="shareForm">
  <div [formGroup]="shareForm">

    <div fxLayout="row" fxLayoutGap="25px" fxLayout.xs="column">
      <!-- Share visibility selection -->
      <div fxFlex="calc(50% - 25px)" *ngIf="!hideShareVisibility" fxFlex.xs="100%" class="order-2 order-lg-1 order-sm-1 order-md-1">
        <div fxLayout="column">
          <kl-activity-visibility
            [shareVisibilityList]="shareVisibilityList"
            formControlName="recipient"
            (visibilityChanged)="clearShareFormTargetingFilters($event)">
          </kl-activity-visibility>
        </div>
      </div>
      <!-- / Share visibility selection -->

      <!-- Tags selection -->
      <div class="share-btn order-1 order-lg-2 order-sm-2 order-md-2" [fxFlex]="hideShareVisibility ? '100%' : '50%'" fxFlex.xs="100%" *ngIf="showTags">
        <kl-activity-tags [tagType]="tagType" formControlName="msgTypes">
        </kl-activity-tags>
      </div>
      <!-- / Tags selection -->
    </div>

    <!-- Specific members picker -->
    <div *ngIf="shareFormVisibilityValue === sharedVisibilities?.SPECIFIC_KALGUDI_USER?.value">
      <div formGroupName="filter">
        <kl-specific-member-visibility
          [pageId]="pageId"
          [shareFormFilterUsers]="shareFormFilterUsers"
          formControlName="users"
          [showBulkUpload]="showBulkUpload"
          (verifyBulkUpload)="verify($event)"
          (isExcelUploaded)="onUploadedExcel($event)">
        </kl-specific-member-visibility>
      </div>
    </div>
    <!-- /Specific members picker -->


    <!-- Program target members filters -->
    <ng-container *ngIf="shareFormVisibilityValue === sharedVisibilities?.SPECIFIC_SUBSCRIBERS?.value">
      <div class="my-3" *ngIf="(memberTargetingFilters$ | async) as filters">
        <kl-page-target-members
          [hideBusinessFilter]="hideBusinessFilter"
          [hideProductsFilter]="hideProductsFilter"
          [pageId]="pageId"
          [shareFormFilter]="shareFormFilter"
          [productsList]="filters?.products"
          [productsFormArray]="shareFormFilterProducts"
          [organizationsList]="filters?.products"
          [organizationsFormArray]="shareFormFilterProducts"
          [businessTypesList]="filters?.businessTypes"
          [businessTypesFormArray]="shareFormFilterBusinessTypes"
          [locationsList]="filters?.locations"
          [locationsFormArray]="shareFormFilterLocations"
          [countriesList]="filters?.countries"
          [countriesFormArray]="shareFormFilterCountries"
          [statesList]="filters?.states"
          [statesFormArray]="shareFormFilterStates"
          [districtsList]="filters?.districts"
          [districtsFormArray]="shareFormFilterDistricts"
          [streamType]="streamType">
        </kl-page-target-members>
      </div>
    </ng-container>
    <!-- /Program target members filters -->

  </div>

</div>
