<div id="fpo-infrastructure-form">
  <form autocomplete="off" [formGroup]="fpoInfrastructureForm" fxLayout="column" fxLayoutGap="4px">

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0px">
      <!-- Internet connectivity -->
      <div fxLayout="column" fxFlex="calc(50% - 10px)"  fxFlex.xs="100%">
        <div>
          <p>Have a internet connectivity?</p>
        </div>

        <div fxLayout>

          <mat-form-field appearance="outline" class="w-100">
            <mat-select formControlName="isInternetConnected">
              <mat-option value="YES">{{ 'Yes' | translate }}</mat-option>
              <mat-option value="NO">{{ 'No' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <!--/ Internet connectivity -->

      <!-- Computer/tab availability -->
      <div fxLayout="column" fxFlex="calc(50% - 10px)" fxFlex.xs="100%">
        <div>
          <p>Computer / Tab available?</p>
        </div>

        <div fxLayout>
          <mat-form-field appearance="outline" class="w-100">
            <mat-select formControlName="isDeviceAvailable">
              <mat-option value="YES">{{ 'Yes' | translate }}</mat-option>
              <mat-option value="NO">{{ 'No' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </div>
      <!--/ Computer/tab availability -->
    </div>

    <!-- Accounting/ fpo software-->
    <div fxLayout="column" formGroupName="accounting">
      <div fxLayout="column" >

        <div>
          <p>Any accounting?</p>
        </div>

        <div fxLayout fxLayoutGap="8px">

          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select formControlName="isAccounted">
                <mat-option value="YES">{{ 'Yes' | translate }}</mat-option>
                <mat-option value="NO">{{ 'No' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 10px)">
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>App name</mat-label>
                <input matInput [placeholder]="'App name' | translate" formControlName="FPOSoftware" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--/ Accounting/ fpo software-->

    <!-- Field trials -->
    <div *ngIf="isShgApp" fxLayout="column" formGroupName="fieldTrials">

      <div fxLayout="column">
        <div>
          <p>Any companies doing field trials?</p>
        </div>

        <div fxLayout fxLayoutGap="8px">

          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select formControlName="isTrialDone">
                <mat-option value="YES">{{ 'Yes' | translate }}</mat-option>
                <mat-option value="NO">{{ 'No' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 10px)">
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Trials by</mat-label>
                <input matInput [placeholder]="'Trials by' | translate" formControlName="trialsBy" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!--/ Field trials -->

    <!-- Interested in participating -->
    <div *ngIf="isShgApp" fxLayout="column" formGroupName="interestedToParticipate">

      <div fxLayout="column">

        <div>
          <p>Interested in participating in input company interactions?</p>
        </div>

        <div fxLayout fxLayoutGap="8px">

          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-select formControlName="isInterestedToParticipate">
                <mat-option value="YES">{{ 'Yes' | translate }}</mat-option>
                <mat-option value="NO">{{ 'No' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="calc(50% - 10px)">
            <div fxLayout="column">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Participated company</mat-label>
                <input matInput [placeholder]="'Participated company' | translate" formControlName="participatingInInput" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!--/ Interested in participating -->

    <!-- Submit button -->
    <div fxLayout>
      <div fxLayout fxLayoutAlign="end center" class="mt-2 w-100 save-button">
        <kl-flat-button
          label="Save"
          [progress]="progress"
          (clicked)="updateFpoInfrastructure()"
          (disabled)="!fpoInfrastructureForm?.valid">
        </kl-flat-button>
      </div>
    </div>
    <!--/ Submit button -->

  </form>
</div>
