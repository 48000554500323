<div class="kalgudi-core-theme overflow-hidden" id="frequent-members-picker">
  <div class="dialog-header pl-4" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title p-0" mat-dialog-title translate>{{dialogData?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="tab-groups px-4">
    <kl-enable-offline-da
      [pageId]="dialogData?.data?.pageId"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-enable-offline-da>
  </div>

  <!-- Buttons -->
  <div class="pt-0 mx-4 mb-3 buttons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-stroked-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()"></kl-stroked-button>
    </div>
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-flat-button [label]="dialogData?.acceptButtonTitle" buttonColor="primary" (clicked)="ok()"></kl-flat-button>
    </div>
  </div>
  <!-- / Buttons -->

</div>
