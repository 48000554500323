<main class="px-3 pt-1 qa-header home-stream-header">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom pb-1">
    <div class="header-title" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="6px">
      <mat-icon class="qa">help</mat-icon>
      <div>
        <h3 class="mb-0 info" *ngIf="!latestActivity?.type" translate>Question and Answers</h3>
        <h3 *ngIf="latestActivity?.type" class="mb-0">
          <span *ngIf="activityTypes[latestActivity?.type]">

            <a class="mr-1" (click)="viewProfile(latestActivity?.id)"
              *ngIf="userDetails?.profileKey !== latestActivity?.id">
              <b>{{ latestActivity?.name }}</b>
            </a>
            <b *ngIf="userDetails?.profileKey === latestActivity?.id" translate>You </b>
          </span>
          <span class="text-muted">{{activityTypes[latestActivity?.type] || 'Question and Answers' | translate }}</span>
        </h3>
      </div>
    </div>

    <!-- Header menu button -->
    <div [fxHide.xs]="!showMatMenu" *ngIf="isLoggedIn">
      <button mat-icon-button fxLayoutAlign="end end" class="header-menu" [matMenuTriggerFor]="Menu">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>
      <mat-menu #Menu="matMenu" [xPosition]="'before'" id="action-menu" class="mat-menu-sm">
        <button mat-menu-item class="mb-0 button-size" id="report-btn" (click)="reportAbuse()"><span translate>Report abuse</span></button>
        <button mat-menu-item class="mb-0 button-size" id="delete-btn" *ngIf="userProfileKey === authorId || memberRole === 'ADMIN'"
          (click)="deleteUpdate()" translate>Delete</button>
      </mat-menu>
    </div>
    <!-- / Header menu button -->

    <!-- Mobile menu -->
    <div fxHide.gt-xs *ngIf="isLoggedIn && !showMatMenu">
      <button mat-icon-button class="header-menu" (click)="openBottomSheet()">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>
    </div>

    <!-- /Mobile menu -->

  </div>
</main>
