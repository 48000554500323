<div id="task-form-updates" class="p-2 rounded-sm">

  <!-- Task form header -->
  <div class="task-updates" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
    <p class="person-updated mb-0 text-truncate" fxFlex="calc(100% - 83px)" *ngIf="taskFormUpdates?.activity?.createdBy?.firstName">
      {{ taskFormUpdates?.activity?.createdBy?.firstName | titlecase }} updated
    </p>
    <p class="updated-date mb-0" fxFlex="73px" fxLayoutAlign="end end" *ngIf="taskFormUpdates?.LUT">
      {{ taskFormUpdates?.LUT | date: 'dd MMM yy' }}
    </p>
  </div>
  <!-- /Task form header -->

  <div *ngFor="let item of formUpdateDetails;let last = last" [ngClass]="{'mb-2': !last}">
    <p class="mb-0 text-truncate header-type">{{ item?.type | titlecase }}</p>

    <!-- Task form info -->
    <div fxLayout="column" *ngFor="let formValue of item?.value;let last = last" fxLayoutAlign="center start" class="form-values" [ngClass]="{'mb-3': !last}">
      <span class="text-secondary text-truncate type" *ngIf="formValue?.type">{{ formValue?.type | titlecase }}</span>

      <span class="text-truncate value" *ngIf="formValue?.value === null">-</span>
      <span class="text-truncate value" *ngIf="formValue?.value !== null && formValue?.value !== '' && !isNumber(formValue?.value) && formValue?.value?.includes('000Z')">{{ formValue?.value | date: 'dd MMM yy'}}</span>
      <span class="text-truncate value" *ngIf="formValue?.value !== null && formValue?.value !== '' && !isNumber(formValue?.value) && !formValue?.value?.includes('000Z')">{{ formValue?.value | titlecase }}</span>
      <span class="text-truncate value" *ngIf="isNumber(formValue?.value)">{{ formValue?.value }}</span>
    </div>
    <!-- /Task form info -->
  </div>

</div>
