import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAutocomplete } from '@kalgudi/core';
import { BaseProductAutocomplete, BaseProductTypes, StoreBaseProductBasicDetails, StoreType } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseProductAutocompleteService } from '../services/base-product-autocomplete.service';

/**
 * Defines logic to show base product autocomplete.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiBaseProductAutocomplete extends KalgudiAutocomplete<StoreBaseProductBasicDetails> {

  @Input()
  storeType: StoreType | '' = '';

  @Input()
  productType: BaseProductTypes = BaseProductTypes.BASE_PRODUCT;

  @Input()
  baseProduct: StoreBaseProductBasicDetails;

  @Input()
  selectedBaseCategoryId: string;

  private autoCompleteApi: BaseProductAutocompleteService;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {

    super(fb);

    this.autoCompleteApi = this.injector.get(BaseProductAutocompleteService);

    this.placeholder = 'Search and select base product';
    this.label       = 'Search and select base product';

    // Initialize the autoCompleteForm
    this.autoCompleteForm = this.newAutoCompleteFormGroup;

    // Construct the prediction list fetching stream
    this.initAutocomplete();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return this.autoCompleteForm.get('productName');
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      productId: [''],
      productName: [''],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayWithFn(value: StoreBaseProductBasicDetails): any {
    return value && value.productName ? value.productName : value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected streamApi(searchKeyword: string): Observable<StoreBaseProductBasicDetails[]> {

    // Choose the base product api to call based on its type
    const api = this.productType === BaseProductTypes.BASE_PRODUCT
      ? this.baseProductPredictionApi(searchKeyword)
      : this.varietiesProductPredictionApi(searchKeyword, this.baseProduct ? this.baseProduct.productId : '');

    return api.pipe(
        map(predictions => this.mapProductDetails(predictions))
      );
  }

  /**
   * Gets, base product prediction with the specified search keyword
   */
  private baseProductPredictionApi(searchKeyword: string): Observable<BaseProductAutocomplete[]> {
    return this.autoCompleteApi.getProductsPredictions(searchKeyword, this.storeType, {baseCategoryId: this.selectedBaseCategoryId});
  }

  /**
   * Gets, varieties product prediction with the specified search keyword and base product id
   */
  private varietiesProductPredictionApi(searchKeyword: string, baseProductId: string): Observable<BaseProductAutocomplete[]> {
    return this.autoCompleteApi.getVarietiesPredictions(searchKeyword, baseProductId, this.storeType);
  }

  /**
   * Maps a list of google places prediction result to KalgudiLocation type list.
   */
  private mapProductDetails(predictions: BaseProductAutocomplete[]): StoreBaseProductBasicDetails[] {

    return predictions.map(prediction => {
      return {
        productId: prediction.VProductId,
        productName: prediction.VProductName,

        baseCategory: {
          id: prediction.baseCategory.id,
          value: prediction.baseCategory.value,
          // level3Count: prediction.baseCategory.level3Count,
        },
        attachments: prediction.attachments,
        storeType: prediction.storeType
      };
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
