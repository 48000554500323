import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import {
  CommonSearchBarModule,
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  MobileTabsModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPageTargetMembersModule } from '@kalgudi/pages-shared';
import {
  AddProjectMembersModule,
  KalgudiProjectListModule,
  KalgudiProjectManagerModule,
  KalgudiProjectSharedModule,
} from '@kalgudi/project-manager';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import { AddPageProjectMembersComponent } from './components/add-page-project-members/add-page-project-members.component';
import { KalgudiPageProjectListComponent } from './components/kalgudi-page-project-list/kalgudi-page-project-list.component';
import { PageProjectBulkMembersComponent } from './components/page-project-bulk-members/page-project-bulk-members.component';
import {
  PageProjectsMembersDialogComponent,
} from './components/page-projects-members-dialog/page-projects-members-dialog.component';
import {
  PageProjectsMembersMobileDialogComponent,
} from './components/page-projects-members-mobile-dialog/page-projects-members-mobile-dialog.component';



@NgModule({
  declarations: [
    KalgudiPageProjectListComponent,
    PageProjectsMembersDialogComponent,
    AddPageProjectMembersComponent,
    PageProjectsMembersMobileDialogComponent,
    PageProjectBulkMembersComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,
    MatButtonModule,
    MatTabsModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    CommonSearchBarModule,
    KalgudiProjectManagerModule,
    KalgudiProjectListModule,
    KalgudiProjectSharedModule,
    KalgudiButtonModule,
    KalgudiProgramTargetMembersModule,
    AddProjectMembersModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule,
    KalgudiPageTargetMembersModule,
    MobileTabsModule,

    TranslateModule
  ],
  exports: [
    KalgudiPageProjectListComponent,
  ],
  entryComponents: [
    PageProjectsMembersDialogComponent,
    PageProjectsMembersMobileDialogComponent
  ]
})
export class KalgudiPageProjectsModule { }
