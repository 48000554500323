<section id="update-seller-tiles">
  <!--About user details tile-->
  <div class="update-data" fxLayout="column" *ngIf="sellerConfigDetails">

    <!-- Tax details -->
    <p class="head-text mb-2 mt-2" translate *ngIf="sellerConfigDetails?.taxDetails">Tax details</p>
    <div fxLayout="row" fxLayoutAlign="center center">

      <!-- PAN number -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.taxDetails?.panNumber">
        <p class="mb-0 info"><strong translate>PAN number</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.taxDetails?.panNumber }}</span>
      </div>
      <!-- / PAN number-->

      <!-- Provisional GSTIN -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.taxDetails?.gstinNumber">
        <p class="mb-0 info"><strong translate>Provisional GSTIN</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.taxDetails?.gstinNumber }}</span>
      </div>
      <!--/ Provisional GSTIN -->
    </div>
    <!-- /Tax details -->

    <!-- Bank account details -->
    <p class="head-text mb-2 mt-2" translate *ngIf="sellerConfigDetails?.bankAccount && sellerConfigDetails?.bankAccount?.accountNumber">Bank account details</p>
    <div fxLayout="row" fxLayoutAlign="center center">

      <!-- Account holder name -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.bankAccount?.accountHolderName">
        <p class="mb-0 info"><strong translate>Account holder name</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.bankAccount?.accountHolderName }}</span>
      </div>
      <!-- / Account holder name-->

      <!-- Account type -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.bankAccount?.acountType">
        <p class="mb-0 info"><strong translate>Account type</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.bankAccount?.acountType | titlecase | trimSpecialCharacters }}</span>
      </div>
      <!--/ Account type -->
    </div>

    <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.bankAccount?.bankName">
      <p class="mb-0 info"><strong translate>Bank name</strong></p>
      <span class="info-data mb-1">{{ sellerConfigDetails?.bankAccount?.bankName }}</span>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">

      <!-- Account number -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.bankAccount?.accountNumber">
        <p class="mb-0 info"><strong translate>Account number</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.bankAccount?.accountNumber }}</span>
      </div>
      <!-- / Account number-->

      <!-- IFSC code -->
      <div class="title-data" fxLayout="column" fxFlex="calc(50% - 15px)" *ngIf="sellerConfigDetails?.bankAccount?.ifscCode">
        <p class="mb-0 info"><strong translate>IFSC code</strong></p>
        <span class="info-data mb-1">{{ sellerConfigDetails?.bankAccount?.ifscCode }}</span>
      </div>
      <!--/ IFSC code -->
    </div>
    <!-- /Bank account details -->

  </div>
  <div>
    <kl-no-content *ngIf="!sellerConfigDetails"
      text="No details added yet" iconColor="#00000075">
    </kl-no-content>
  </div>
</section>
