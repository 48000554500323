import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiCommonModule, KalgudiLoadMoreModule, KalgudiNoContentModule, KalgudiUsersModule } from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import { ConnectedTileComponent } from './components/connected-tile/connected-tile.component';
import { NetworkAuthorDetailsComponent } from './components/network-author-details/network-author-details.component';
import { NetworkHeaderComponent } from './components/network-header/network-header.component';
import { NetworksTileComponent } from './components/networks-tile/networks-tile.component';
import { ProfileViewTileComponent } from './components/profile-view-tile/profile-view-tile.component';
import { ProfileViewsComponent } from './components/profile-views/profile-views.component';
import { ProfileVisitedTileComponent } from './components/profile-visited-tile/profile-visited-tile.component';
import { KalgudiNetworksApiService } from './services/kalgudi-networks-api.service';
import { KalgudiNetworksService } from './services/kalgudi-networks.service';



@NgModule({
  declarations: [
    ProfileVisitedTileComponent,
    ConnectedTileComponent,
    NetworksTileComponent,
    NetworkHeaderComponent,
    NetworkAuthorDetailsComponent,
    ProfileViewsComponent,
    ProfileViewTileComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatCardModule,
    MatIconModule,

    FlexLayoutModule,

    // Kalgudi
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiUsersModule,
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiBreadcrumbsModule,
    KalgudiCommonModule,

    TranslateModule
  ],
  exports: [
    NetworksTileComponent,
    NetworkHeaderComponent,
    ProfileViewsComponent,
  ],
  providers: [
    KalgudiNetworksApiService,
    KalgudiNetworksService,
  ]
})
export class KalgudiNetworksModule { }
