import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { DigitalAssistanceFrequentMembersPicker } from '../../classes/digital-assistance-frequent-members-picker';

@Component({
  selector: 'kl-page-frequent-members-picker',
  templateUrl: './page-frequent-members-picker.component.html',
  styleUrls: ['./page-frequent-members-picker.component.scss']
})
export class PageFrequentMembersPickerComponent extends DigitalAssistanceFrequentMembersPicker implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up my parent
    super(injector, notification, util);
  }

  ngOnInit() {
    this.initStream();
  }

  protected onDestroyed(): void { }

}
