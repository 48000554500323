<div id="list-filters" class="project-list-filters">
  <div fxLayout fxLayout.xs="column">
    <div fxFlex="40%" class="position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
      [view]="view"
      [scheme]="colorScheme"
      [results]="chartStats"
      [gradient]="gradient"
      [legend]="showLegend"
      [legendPosition]="legendPosition"
      [labels]="showLabels"
      [doughnut]="isDoughnut">
    </ngx-charts-pie-chart>

    <div class="position-absolute">
      <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
        <p class="mb-0 label" translate>Total projects</p>
        <p class="mb-0 projects-total">{{ chartData?.total | number}}</p>
      </div>
    </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
      <!-- <div class="mb-5 mt-3" fxLayout="row">
        <button mat-stroked-button color="primary" class="add-proj-button w-100" (click)="openCreateProjectDialog()">
          <mat-icon class="add-icon mr-2">add_circle</mat-icon>
          <span>Add Project</span>
        </button>
      </div> -->
      <div class=mb-3>
        <kl-chip-list [chips]="chipsList"></kl-chip-list>
      </div>
      <div>
        <kl-chip-list [chips]="statsList"></kl-chip-list>
      </div>
    </div>
  </div>
</div>
