import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-submission-form-mobile-dialog',
  templateUrl: './survey-submission-form-mobile-dialog.component.html',
  styleUrls: ['./survey-submission-form-mobile-dialog.component.scss']
})
export class SurveySubmissionFormMobileDialogComponent extends KalgudiMobileDialog implements OnInit {


  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,

  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {},
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}

