import { Component, EventEmitter, forwardRef, Inject, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiGeoLocationMarkerService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';
import {
  FileMimeTypes,
  GeoFenceDetails,
  IdValueMap,
  KalgudiDialogConfig,
  KalgudiLocation,
  KalgudiUser,
  LatLong,
} from '@kalgudi/types';
import { filter, map, take } from 'rxjs/operators';

import { KalgudiAddLandDetails } from '../../classes/kalgudi-add-land-details';
import {
  IRRIGATION_TYPES,
  LAND_COVER_TYPES,
  SALINITY,
  SALINITY_LEVEL,
  SIZE_IN_LIST,
  SOIL_TYPES,
  WATER_LEVEL_UNITS,
} from '../../constants';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AddLandDetailsComponent),
  multi: true,
};


@Component({
  selector: 'kl-add-land-details',
  templateUrl: './add-land-details.component.html',
  styleUrls: ['./add-land-details.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class AddLandDetailsComponent extends KalgudiAddLandDetails implements OnInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  @Input()
  hideAddButton = false;

  @Output()
  landAdded = new EventEmitter<KalgudiUser>();

  geoFencing = new FormControl([]);

  readonly soilTypes: IdValueMap[] = SOIL_TYPES;

  readonly sizeInList: IdValueMap[] = SIZE_IN_LIST;

  readonly waterLevelUnitList: IdValueMap[] = WATER_LEVEL_UNITS;

  readonly irrigationTypes: IdValueMap[] = IRRIGATION_TYPES;

  readonly salinityTags: IdValueMap[] = SALINITY;

  readonly salinityLevelTags: IdValueMap[] = SALINITY_LEVEL;

  readonly landCoverTypes: IdValueMap[] = LAND_COVER_TYPES;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT_PDF ];

  fenceDetails: GeoFenceDetails;

  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    private geoLocationMarkerService: KalgudiGeoLocationMarkerService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
  ) {

    super(injector);

    this.activeTabIndex = 0;

    this.subscribeToImageCaptures();

    this.isMobileDevice = checkMobileDevice() ? true : false;

  }

  ngOnInit() {
    if (this.landDetails && this.landDetails.landId) {
      this.currentLocationProgress = false;

      this.init();
    }

    if(this.returnGeoTagDetails) {
      this.init();
    }

    this.getCurrentLocation();

    this.subscribeToGeoFenceValueChanges();
  }

  onDestroyed() { }


  /**
   * Resets the geo fence markings
   */
  resetMap() {

    this.geoMarker.reset();
    this.geoFences.patchValue([]);

    const isEdit = this.landDetails && this.landDetails.landId ? true : false;
    this.getCurrentLocation(isEdit);

  }

  /**
   * Calculates area automatically by using geofencing
   */
  calculateArea() {

    const geoFences = this.geoFences.value;

    this.geoTagInfo = geoFences.length;

    if (!geoFences.length) {
      return;
    }

    this.convertAndUpdateArea(this.geoMarker.calculateAreaOfSphericalPolygonInAcres(geoFences));
  }

  /**
   * Called after adding land details successfully
   */
  addLandDetailsHandler(res: KalgudiUser) {
    this.landAdded.emit(res);

    if (res && res.farmerLandDetails && res.farmerLandDetails.lands[res.farmerLandDetails.lands.length - 1].conserWaterAreaName) {
      this.util.showMessage('Land saved successfully. We are processing satellite imaginary data, please check back later to see satellite advisories for the land.');
    }
  }

  openGpsGeoMarker(): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Fence your field',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {},
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'kl-gps-geo-fence-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.geoLocationMarkerService.openGpsGeoLocationMarkerDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(data => data.accepted),

        map(data => data.data as GeoFenceDetails)
      )
      .subscribe(data => {
        this.landDetailsForm.get('geoFences').patchValue(data.coords);

        // this.landSize.patchValue({
        //   unit: 'acre',
        //   value: data.areaInAcres,
        // });

        // console.log(data);
      });
  }

  geoTagChanged(latLong: LatLong): void {

    const location: KalgudiLocation = {
      countryId: '',
      countryName: '',
      countryShortName: '',
      districtId: '',
      districtName: '',
      locality: '',
      locationLong: '',
      locationShort: '',
      location_category_id: '',
      openerpCountryId: '',
      placeId: '',
      placeName: '',
      postalCode: '',
      regionId: '',
      regionName: '',
      stateId: '',
      stateName: '',

      ...latLong,
    };

    this.locationField.patchValue(location);
    this.geoFences.patchValue([]);

    const latLng = {latitude: latLong.latitude, longitude: latLong.longitude}

    if (latLng.longitude && latLng.latitude) {

      this.geoLocationMarkerService.searchOnGoogle(latLng)
        .pipe(take(1))
        .subscribe(
          (res: any) => {

            const currentLocation = res.kalgudiLocation;

            if((this.currentLocation.districtName !== currentLocation.districtName) || (this.currentLocation.stateName !== currentLocation.stateName)) {

              this.locationLabel = (currentLocation.districtName ? currentLocation.districtName + ', ' : '')  +
                (currentLocation.stateName ? currentLocation.stateName + ', ' : '') +
                (currentLocation.countryName ? currentLocation.countryName : '') +
                (currentLocation.countryName && currentLocation.postalCode ? ' - ': '') +
                (currentLocation.postalCode ? currentLocation.postalCode : '');

            }
          }
        )
    }

    // console.log(this.locationField.value);
  }

  onFenceUpdate(event: GeoFenceDetails) {
    this.fenceDetails = event;
    console.log(event);

    this.geoFences.patchValue(event.coords);

    const location = {
      countryId: '',
      countryName: '',
      countryShortName: '',
      districtId: '',
      districtName: '',
      locality: '',
      locationLong: '',
      locationShort: '',
      location_category_id: '',
      openerpCountryId: '',
      placeId: '',
      placeName: '',
      postalCode: '',
      regionId: '',
      regionName: '',
      stateId: '',
      stateName: '',
      latitude: event.coords[0] && event.coords[0].latitude ? event.coords[0].latitude : this.locationField.value.latitude,
      longitude: event.coords[0] && event.coords[0].longitude ? event.coords[0].longitude : this.locationField.value.longitude,
    }

    this.locationField.patchValue(location);
  }
}
