import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveyList } from '../../classes/survey-list';

@Component({
  selector: 'kl-survey-list-page',
  templateUrl: './survey-list-page.component.html',
  styleUrls: ['./survey-list-page.component.scss']
})
export class SurveyListPageComponent extends KalgudiSurveyList implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

}
