import { AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList, ViewEncapsulation } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material/stepper';

@Component({
  selector: 'kl-stepper',
  template: '<ng-content></ng-content>',
  styleUrls: ['./kalgudi-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KalgudiStepperComponent implements OnInit, AfterViewInit {

  @Input()
  disablePointerEvents = true;

  @ContentChildren(MatHorizontalStepper) stepperQueryList: QueryList<MatHorizontalStepper>;

  matStepper: MatHorizontalStepper;

  constructor() {}

  ngOnInit() { }

  ngAfterViewInit(): void {

    this.matStepper = this.stepperQueryList.first;
  }

  next() {
    this.matStepper.next();
  }

  previous() {
    this.matStepper.previous();
  }
}
