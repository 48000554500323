
<mat-card fxLayout="row" fxLayoutAlign="center center" class="w-100 user-assistant-stats">
  <ngx-charts-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="userStats"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="isDoughnut">
  </ngx-charts-pie-chart>

  <kl-fake-it-block *ngIf="!userStats"></kl-fake-it-block>
</mat-card>
