import { Injectable } from '@angular/core';
import { KalgudiAppService, KalgudiUserBasicSearchResults } from '@kalgudi/core';
import { KalgudiStreamS3FetchService, SocialDataNormalizerService } from '@kalgudi/social';
import {
  KalgudiPageRelation,
  KalgudiUserBasicDetails,
  NotificationSocialCommon,
  PageDigitalAssistanceStats,
  PageDigitalAssistanceStream,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';
import { DigitalAssistanceApiService } from './digital-assistance-api.service';

@Injectable()
export class DigitalAssistanceService {


  constructor(
    private api: DigitalAssistanceApiService,
    private app: KalgudiAppService,
    private s3Fetch: KalgudiStreamS3FetchService,
    private socialDataNormalizer: SocialDataNormalizerService,
    private pageState: ProgramStateService,
  ) { }



  /**
   * Searches a list of all frequently assisted members for a page.
   */
  searchFrequentlyAssistedMembers(
    pageId: string,
    offset: number = 0,
    limit: number = 100,
  ): Observable<KalgudiUserBasicSearchResults> {

    return this.api.searchFrequentlyAssistedMembers(pageId, this.app.profileLocal.profileKey, offset, limit);
  }

  /**
   * Saves users to the frequent users list
   */
  saveFrequentAssistedMembers(pageId: string, users: KalgudiUserBasicDetails[]): Observable<KalgudiUserBasicDetails[]> {
    return this.api.saveFrequentAssistedMembers(pageId, users);
  }

  /**
   * Fetch digital assistance stream from the Api. It also fetches complete assistance
   * details from the S3 and injects it to the object.
   */
  fetchStream(
    pageId: string,
    role: KalgudiPageRelation,
    offset: number,
    limit: number
  ): Observable<PageDigitalAssistanceStream[]> {

    // Fetch all assistance done by me
    const myProfileKey = this.app.profileLocal.profileKey;

    return this.api.fetchAssistance(pageId, role, myProfileKey, offset, limit)
      .pipe(

        // Map stream items
        map(res => {

          // Update digital assistance stats
          this.updateDigitalAssistanceStats(res.statistics);

          // Add author details
          res.activities.forEach(activity => activity.author = this.mapDigitalAssistanceStreamAuthorDetails(activity.activityTo));

          return res.activities;
        }),
      );
  }

  /**
   * Returns author details of `KalgudiBasicUserDetails` type from
   * digital assistance stream object.
   */
  private mapDigitalAssistanceStreamAuthorDetails(da: NotificationSocialCommon): KalgudiUserBasicDetails {
    return {
      firstName: da.latestActivity.name,
      profileKey: da.latestActivity.id,
      profilePicUrl: da.latestActivity.profilePicURL,
      profilePicURL: da.latestActivity.profilePicURL,
    };
  }

  /**
   * Updates digital assistance stats to the page state.
   */
  private updateDigitalAssistanceStats(stats: PageDigitalAssistanceStats): void {
    this.pageState.dispatchAction(PageActions.PAGE_DA_STATS_UPDATED, stats);
  }

}
