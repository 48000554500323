import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails, UIElementSize } from '@kalgudi/types';

import { KalgudiCommonRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-post-author',
  templateUrl: './post-author.component.html',
  styleUrls: ['./post-author.component.scss']
})
export class PostAuthorComponent implements OnInit, OnChanges {

  @Input()
  pageTitle: string;

  @Input()
  pageName: string;

  @Input()
  scheduledTime: any;

  @Input()
  author: KalgudiUserBasicDetails;

  @Input()
  TS: string;

  @Input()
  LUT: string;

  @Input()
  avatarSize: UIElementSize;

  @Input()
  roundedAvatar: boolean;

  @Input()
  activityDescription: string;

  @Input()
  showTS: boolean;

  @Input()
  showLocation: boolean;

  @Input()
  showActivityDescription: boolean;

  @Input()
  hideTime: boolean;

  @Input()
  shareText: string;

  @Input()
  postType: string;

  @Input()
  pageId: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  resizedImage: string = '';

  domain: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) {
    this.showLocation = true;
    this.roundedAvatar = true;

    this.domain = this.env.coreDomain;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.avatarSize && this.avatarSize) {
      this.computeAvatarSize();
    }
  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
  }

  viewPage(pageId: string) {
    this.appRouting.toProgram({ pageId })
  }

  private computeAvatarSize(): void {

    if (this.avatarSize === 'sm') {
      this.resizedImage = '50x50';
    } else if (this.avatarSize === 'md') {
      this.resizedImage = '150x150';
    }
  }
}
