import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KalgudiLoadMoreModule, KalgudiNoContentModule, KalgudiUserPickerModule, KalgudiUsersModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import {
  KalgudiPageProcessingListComponent,
} from './components/kalgudi-page-processing-list/kalgudi-page-processing-list.component';
import { ProcessingApiService } from './services/processing-api.service';
import { ProcessingService } from './services/processing.service';




@NgModule({
  declarations: [KalgudiPageProcessingListComponent],
  imports: [
    CommonModule,

    // Flex
    FlexLayoutModule,

    // Kalgudi modules
    KalgudiUsersModule,
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiUserPickerModule,
  ],
  exports: [
    KalgudiPageProcessingListComponent
  ],
  providers: [
    ProcessingApiService,
    ProcessingService
  ]
})
export class KalgudiPageProcessingModule { }
