<main class="project-dashboard-header projects">

  <!-- Breadcrumbs -->
  <div>
    <kl-breadcrumbs></kl-breadcrumbs>
  </div>
  <!-- /Breadcrumbs -->

  <div fxLayout fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutGap.xs="10px">

    <!-- Header title -->
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <svg-icon src="assets/svgs/projects.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"
          class="mr-3 projects-icon"></svg-icon>
      </div>
      <div>
        <div [@slideRight]>
          <h3 class="title mb-0 text-body"><b translate>Projects</b></h3>
        </div>
        <p class="mb-0 description text-muted" translate>Dashboard</p>
      </div>
    </div>
    <!-- /Header title -->

    <!--Add project-->
    <div class="mb-0 mt-3" fxLayout="row">
      <button mat-stroked-button color="primary" class="add-proj-button w-100" (click)="openCreateProjectDialog()">
        <mat-icon class="add-icon mr-2">add_circle</mat-icon>
        <span translate>Add Project</span>
      </button>
    </div>
     <!-- /Add project-->

    <!-- Search -->
    <!-- <div fxFlex="310px" fxFlex.xs="80%" fxLayout fxLayoutAlign="end start">
      <kl-search placeHolder="Search projects, tasks, members" [searchForm]="searchForm" class="w-100"></kl-search>
    </div> -->
    <!-- /Search -->

  </div>

</main>
