import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { checkMobileDevice, DEFAULT_PROFILE_PIC, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { takeUntil } from 'rxjs/operators';

import { ProgramStateService } from '../../../services/program-state.service';

@Directive()
export abstract class AssistProfileUpdate extends KalgudiDestroyable {

  @Output()
  profileFullView = new EventEmitter();

  pageId: string;

  profileUpdateForm: FormGroup;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  assistedUser = new FormControl();

  private fb: FormBuilder;
  private appRouting: KalgudiCommonRouteConfig;
  private programState: ProgramStateService;
  private environment: KalgudiEnvironmentConfig;

  constructor(protected injector: Injector) {

    super();

    this.fb = this.injector.get(FormBuilder);

    this.appRouting   = this.injector.get<KalgudiCommonRouteConfig>(KL_ROUTE_CONFIG);
    this.programState = this.injector.get(ProgramStateService);
    this.environment  = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    this.profileUpdateForm = this.assistProfileUpdateForm;

    this.programState.id$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        id => this.pageId = id
      );

  }

  /**
   * Gets, the name from assist profile update form
   */
  get name(): AbstractControl {
    return this.assistProfileUpdateForm.get('name');
  }

  /**
   * Gets, the title from assist profile update form
   */
  get title(): AbstractControl {
    return this.assistProfileUpdateForm.get('title');
  }

  /**
   * Gets, the location from assist profile update form
   */
  get location(): AbstractControl {
    return this.assistProfileUpdateForm.get('location');
  }

  /**
   * Gets, the title from assist profile update form
   */
  get contact(): AbstractControl {
    return this.assistProfileUpdateForm.get('contact');
  }

  /**
   * Opens the profile in new tab
   */
  openProfileView(profileKey: string): void {
    if (!checkMobileDevice()) {
      if (!this.environment.production && this.environment.development) {
        const url = `${this.environment.domain}/core/app/profiles/${profileKey}?pageId=${this.pageId}&isAssisted=true`;

        window.open(url, '_blank');
      } else {
        this.appRouting.toProfile({profileKey}, {pageId: this.pageId, isAssisted: true }, true);
      }
    } else {
      this.profileFullView.emit();
      this.appRouting.toProfile({profileKey}, {pageId: this.pageId, isAssisted: true });
    }

  }

  /**
   * Form group for assist profile update
   */
  private get assistProfileUpdateForm(): FormGroup {

    return this.fb.group({
      name: [''],
      title: [''],
      contact: [''],
      location: ['']

    });
  }
}
