import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import {
  ApiQuestionListResponseData,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiSurveyQuestion,
  KalgudiSurveyQuestionBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { SurveyStateService } from '../../../services/survey-state.service';
import { AddQuestionDialogComponent } from '../components/add-question-dialog/add-question-dialog.component';
import {
  MobileAddQuestionDialogComponent,
} from '../components/mobile-add-question-dialog/mobile-add-question-dialog.component';
import { KalgudiSurveyQuestionsApiService } from './kalgudi-survey-questions-api.service';

@Injectable()
export class KalgudiSurveyQuestionsService {

  constructor(
    private dialogsService: KalgudiDialogsService,
    private api: KalgudiSurveyQuestionsApiService,
    private util: KalgudiUtilityService,
    private surveyState: SurveyStateService,
  ) { }


  /**
   * Make an api call to get questions
   */
  getQuestions(surveyId: string): Observable<ApiQuestionListResponseData> {

    return this.api.getQuestions(surveyId);
  }


  /**
   * Make an api call to post question
   */
  postQuestion(surveyId: string, reqObj: KalgudiSurveyQuestionBasicDetails): Observable<KalgudiSurveyQuestion> {

    const payload = this.util.clone(reqObj);

    return this.api.postQuestion(surveyId, payload)
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  /**
   * Make an api call to delete question
   */
  deleteQuestion(surveyId: string, questionId: string): Observable<boolean> {

    return this.api.deleteQuestion(surveyId, questionId)
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  showQuestionsDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(AddQuestionDialogComponent, details, config);
  }

  /**
   * Opens program share update form for mobil view
   */
  showMobileQuestionsDialog(
    details: KalgudiDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(MobileAddQuestionDialogComponent, details);
  }

}
