import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiError, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { IdValueMap, KalgudiSellerConfig } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Directive()
export abstract class EditSellerDetails extends KalgudiDestroyable {

  @Input()
  sellerConfigDetails: KalgudiSellerConfig;

  @Input()
  sellerId: string;

  @Output()
  closeDialog = new EventEmitter();

  accountType: IdValueMap[] = [
    {id: 'SAVINGS_ACCOUNT', value: 'Savings'},
    {id: 'CURRENT_ACCOUNT', value: 'Current'},
    {id: 'OTHERS_ACCOUNT', value: 'Others'}
  ];

  sellerConfigForm: FormGroup;

  progress: boolean;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private kalgudiProfileService: KalgudiProfileService;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {
    super();

    this.fb                    = this.injector.get(FormBuilder);
    this.util                  = this.injector.get(KalgudiUtilityService);
    this.notification          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiProfileService = this.injector.get(KalgudiProfileService);

    this.sellerConfigForm = this.editSellerConfigForm;
  }


  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Updates seller config
   */
  updatesSellerConfig() {

    const payload = this.preparePayload(this.sellerConfigForm.value);

    payload.profileKey = this.sellerId;

    payload.sellerConfigurationId  = this.sellerId + '_SELLER_CONFIGURATION';

    this.progress = true;

    this.kalgudiProfileService.updateSellerConfigDetails(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onSellerConfigUpdated(res),
        err => this.onSellerConfigUpdateError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  /**
   * Certificate updated successfully
   */
  protected onSellerConfigUpdated(res) {
    this.closeDialog.emit();
    this.notification.showMessage('Seller details updated');
  }

  /**
   * Failed to update certificate
   */
  protected onSellerConfigUpdateError(err: KalgudiError) {
    this.notification.showMessage('Failed to update seller details');
  }


  /**
   * Initializing edit seller config form
   */
  private get editSellerConfigForm(): FormGroup {

    return this.fb.group({

      taxDetails: this.fb.group({
        panNumber: [''],
        gstinNumber: ['']
      }),

      bankAccount: this.fb.group({
        accountHolderName: [''],
        acountType: [''],
        bankName: [''],
        accountNumber: [''],
        ifscCode: ['']
      })
    });
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
