import { Component, Inject, Injector, OnInit } from '@angular/core';
import { floatTopAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveysMembersSearch } from '../../classes/kalgudi-surveys-members-search';

@Component({
  selector: 'kl-surveys-members-search',
  templateUrl: './surveys-members-search.component.html',
  styleUrls: ['./surveys-members-search.component.scss'],
  animations: [ floatTopAnimation ],
})
export class SurveysMembersSearchComponent extends KalgudiSurveysMembersSearch implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(injector, notification, util);
  }

  ngOnInit() {

    this.initStream();
  }

  onDestroyed(): void {}

}
