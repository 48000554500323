import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialogRef, KalgudiUsersPickerMobileDialog, KL_MOBILE_DIALOG_DATA } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiPageMembersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-frequent-members-picker-mobile-dialog',
  templateUrl: './frequent-members-picker-mobile-dialog.component.html',
  styleUrls: ['../../../program-members-picker-dialog/components/member-picker-mobile-dialog-common.scss', './frequent-members-picker-mobile-dialog.component.scss']
})
export class FrequentMembersPickerMobileDialogComponent extends KalgudiUsersPickerMobileDialog implements OnInit {

  pageId: string;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) protected dialogData: KalgudiPageMembersPickerDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);

    this.pageId = dialogData.pageId;
  }

  ngOnInit() { }

}
