import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { DataStore } from './classes/data-stores/data-store';
import { LocalStorageDS } from './classes/data-stores/local-storage-ds';
import { DefaultPageStateManager } from './classes/page-state/default-page-state-manager';
import { PageStateManager } from './classes/page-state/page-state-manager';
import { KalgudiSpinnerComponent } from './components/kalgudi-spinner/kalgudi-spinner.component';
import {
  ServiceWorkerNotificationComponent,
} from './components/service-worker-notification/service-worker-notification.component';
import { KalgudiPipesModule } from './pipes/kalgudi-pipes.module';



@NgModule({
  declarations: [
    KalgudiSpinnerComponent,
    ServiceWorkerNotificationComponent
  ],
  imports: [
    CommonModule,

    RouterModule,

    // Material
    MatButtonModule,
    FlexLayoutModule,
    KalgudiPipesModule,

    MatProgressSpinnerModule,
  ],
  providers: [
    LocalStorageDS,
    {
      provide: DataStore,
      useClass: LocalStorageDS,
    },
    {
      provide: PageStateManager,
      useClass: DefaultPageStateManager,
    },
  ],
  exports: [
    KalgudiSpinnerComponent,
    KalgudiPipesModule
  ],
  entryComponents: [
    ServiceWorkerNotificationComponent,
  ]
})
export class KalgudiCoreModule {

  public static forRoot(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiCoreModule> {

    return {
      ngModule: KalgudiCoreModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
