import { Component, Injector, OnInit } from '@angular/core';
import { fadeInAnimation } from '@kalgudi/core';

import { KalgudiCreditActivity } from '../../classes/kalgudi-credit-activity';

@Component({
  selector: 'kl-credit-activity',
  templateUrl: './credit-activity.component.html',
  styleUrls: ['./credit-activity.component.scss'],
  animations: [fadeInAnimation]
})
export class CreditActivityComponent extends KalgudiCreditActivity implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
   }

  ngOnInit() {
    // Initializes the KalgudiMatTableStream
    this.initTableStream(this.matPaginator);
  }

  protected onDestroyed(): void { }

}
