<div class="kalgudi-core-theme overflow-hidden member-picker">
  <div class="dialog-header pl-4" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title p-0" mat-dialog-title translate>Select program members</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="tab-groups px-4">
    <mat-tab-group color="accent" animationDuration="0" [(selectedIndex)]="activeTabIndex">
      <!-- All members tab -->
      <mat-tab [label]="TABS.ALL.title | translate">
        <kl-programs-member-picker *ngIf="activeTabIndex === TABS.ALL.index" [pageId]="pageId"
          [multiSelect]="multiSelect" [(selectedUsersMap)]="selectedUsersMap">
        </kl-programs-member-picker>
      </mat-tab>
      <!-- / All members tab -->

      <!-- Selected members tab -->
      <mat-tab [label]="TABS.SELECTED.title | translate">
        <kl-kalgudi-programs-selected-member [selectedUsers]="selectedUsersMap"
          *ngIf="activeTabIndex === TABS.SELECTED.index">
        </kl-kalgudi-programs-selected-member>
      </mat-tab>
      <!-- / Selected members tab -->
    </mat-tab-group>
  </div>

  <!-- Buttons -->
  <div class="pt-0 mx-4 mb-3 buttons" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px"
    [ngClass]="{'mt-3': activeTabIndex === TABS.ALL.index}">
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-stroked-button label="Cancel" buttonColor="warn" (clicked)="cancel()"></kl-stroked-button>
    </div>
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-flat-button label="Select" buttonColor="primary" [disabled]="!(selectedUsersMap | keyvalue)?.length" (clicked)="ok()"></kl-flat-button>
    </div>
  </div>
  <!-- / Buttons -->

</div>
