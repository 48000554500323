<div id="kalgudi-promotional-share-form" class="p-0" *ngIf="shareForm">
  <form autocomplete="off" [formGroup]="shareForm">
    <div class="pb-1 promotional-share-form">

      <!-- Title field -->
      <mat-form-field appearance="outline" class="w-100 mb-2 mat-form-field-sm">
        <mat-label translate>Title</mat-label>
        <input matInput [placeholder]="'Title' | translate" formControlName="promotionalTitle" required>
        <mat-error class="title-mat-error" translate>Title is required</mat-error>
      </mat-form-field>
      <!--/ Title field -->

      <!-- Product auto complete -->
      <div class="promotional-products mt-1">
        <kl-kalgudi-promotional-product-autocomplete
          [searchTypeList]="searchTypeList"
          formControlName="productsMetaData"
          [required]="true"
          [selectedBaseCategoryId]="''"
          [placeholder]="'Search and select product' | translate"
          [pageId]="pageId"
          [label]="'Search and select product' | translate"
          (selectedStoreType)="onChangedStoreType($event)">
        </kl-kalgudi-promotional-product-autocomplete>
      </div>
      <!--/ Product auto complete -->

      <ng-container *ngIf="productDetailsProgress">
        <p class="mb-n2 w-100 mt-n1" fxLayout fxLayoutAlign="end end">Loading product details...</p>
      </ng-container>

      <!-- Level2 details and sku's -->
      <section *ngIf="level2Details && !productDetailsProgress">
        <div fxLayout="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="space-between" fxLayoutGap="8px">
          <div fxFlex="100%" class="mb-1 mr-0 see-more-btn position-relative">
            <!-- <p class="mb-2 font-weight-500">{{level2Details.productLevel2Title}}</p> -->
            <span class="text-justify overflow-hidden description-content">
              {{level2Details.description | limitText: true: textLimit}}
            </span>
            <button mat-button *ngIf="level2Details.description.length > textLimit" (click)="openProductDescriptionDialog()">..See More</button>
          </div>

          <ng-container *ngIf="dosageDetails">
            <div fxLayout="column" class="mb-1">
              <p class="mb-1 font-weight-500">Per acre dosage </p>

              <div fxLayout>
                <span class="dosage-text">Crop name - </span>
                <span class="dosage-info">&nbsp;{{ dosageDetails?.crop?.productName}}</span>
              </div>
              <div fxLayout="row wrap" fxLayoutGap="20px">
                <div fxLayout>
                  <p class="dosage-text mb-0">{{ dosageDetails?.hectare }} - </p>
                  <span class="dosage-info" fxLayout>
                    <span>&nbsp;{{ dosageDetails?.volume }}</span>
                    <span>&nbsp;{{ dosageDetails?.unit }}</span>
                  </span>
                </div>
                <div fxLayout="row">
                  <span class="dosage-text">Pest</span>
                  <span>&nbsp;-&nbsp;</span>
                  <span class="dosage-info">{{ dosageDetails?.pest }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <mat-form-field class="mat-form-field-sm w-100 selection-field" matPrefix appearance="outline">
            <mat-label translate>Select SKU</mat-label>
            <mat-select [formControl]="skuSelection" required>
              <mat-option fxLayout="row wrap" *ngFor="let item of level2Details.level3ProductsList" [value]="item">

                <!-- Smart elements -->
                <span fxLayout="row wrap" *ngFor="let skuItem of item?.selectedSKU">
                  <span *ngIf="skuItem === 'color' || skuItem === 'setCount'">
                    <span>{{ item?.smartElements[skuItem]?.value }}</span>
                    <span>{{ item?.smartElements[skuItem]?.unit }},&nbsp;</span>
                  </span>
                  <span *ngIf="skuItem === 'dimensions'">
                    <span>
                      {{ item?.smartElements[skuItem]?.width?.value }}(W) x
                      {{ item?.smartElements[skuItem]?.length?.value }}(L) in
                      {{ item?.smartElements[skuItem]?.unit }},&nbsp;</span>
                  </span>
                  <span *ngIf="
                      skuItem !== 'color' &&
                      skuItem !== 'dimensions' &&
                      skuItem !== 'setCount'
                    ">
                    <span>{{ item?.smartElements[skuItem]?.value }}
                      {{ item?.smartElements[skuItem]?.unit }},&nbsp;</span>
                  </span>
                </span>
                <!-- / Smart elements -->

                <span class="mb-0" translate>Price: {{item?.mrp}}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>

          <p *ngIf="level2Details.level3ProductsList.length === 0" class="mb-0 text-danger" translate>No SKU's found for this product</p>

        </div>
      </section>
      <!--/ Level2 details and sku's -->

      <ng-container *ngIf="level2Details">

        <p class="mb-1 mt-2 sub-text font-weight-500" translate>
          Recommended crops
        </p>

        <!-- Crop auto complete -->
        <div class="crop-form-field">
          <kl-kalgudi-promotional-crop-autocomplete
            formControlName="crop"
            [placeholder]="'Add crops'"
            [postCreated]="createdPost"
            [itemsList]="level2Details && level2Details.crop ? level2Details.crop : []">
          </kl-kalgudi-promotional-crop-autocomplete>
        </div>
        <!-- /Crop auto complete -->
      </ng-container>


      <!-- Form field -->
      <div class="text-area-field-wrapper mt-2 description-field">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Description</mat-label>
          <textarea matInput class="input-field" [placeholder]="'Description' | translate" [rows]="rowsCount" formControlName="shareText" required></textarea>
          <mat-error class="mt-1" translate>Description is required</mat-error>
        </mat-form-field>
      </div>
      <!-- / Form field -->

      <!-- Share filters -->
      <div class="mb-2 mt-2">
        <kl-share-activity-filters
          [shareVisibilityList]="shareVisibilityList"
          [shareForm]="shareForm"
          [taskId]="taskId"
          [projectId]="projectId"
          [pageId]="pageId">
        </kl-share-activity-filters>
      </div>
      <!-- / Share filters -->

      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url" class="mb-3">
        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">
        </kl-post-tile-link-attachment>
      </div>
      <!-- / url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->

      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()">
            <div class="audio-icon">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>
            <div class="ml-1">Voice message attached</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->

      <div class="mt-n1" fxLayoutGap="20px" fxLayoutGap.xs="0px"
        fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutAlign="space-between center">

        <div fxLayout="row">
          <div>
            <button mat-button
              class="attach-files"
              id="attach-files-button"
              color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>attachment</mat-icon>
              <span translate>Attach</span>
            </button>

            <mat-menu #menu="matMenu" class="attach-file">
              <ng-container>

                <!-- Image attachment -->
                <div mat-menu-item class="attach-file-mat-menu" [matTooltip]="'Capture image' | translate">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Image</p>
                    </div>

                    <div fxLayout="row">
                      <div>
                        <kl-camera-form-control
                          fxFlex="95%"
                          [formControl]="cameraAttachment"
                          [showChipButton]="true"
                          [showIconButton]="true"
                          [showBorder]="false"
                          buttonIcon="camera_alt"
                          buttonLabel="Camera"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-camera-form-control>
                      </div>
                    <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                    </div>
                  </div>
                </div>
                <!--/ Image attachment -->

                <!-- Audio attachment -->
                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Voice</p>
                    </div>

                    <div fxLayout="row">
                      <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                        class="mt-1" [ngClass]="{'rounded-pill': isMobileDevice}" type="button"
                        [matTooltip]="'Attach audio' | translate"
                        (click)="showAudioDialog()"
                        mat-icon-button>
                        <div class="label-icon" fxLayout fxLayoutAlign="center center">
                          <mat-icon class="m-0 camera-icon">mic</mat-icon>
                        </div>
                      </button>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="lstOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedAudioFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>
                <!--/ Audio attachment -->

                <!-- Video attachment -->
                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Video</p>
                    </div>

                    <div fxLayout="row">
                      <div class="mt-1">
                        <kl-attach-url-form-control
                          fxFlex="100%"
                          [showBorder]="false"
                          [showChipButton]="false"
                          svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                          [formControl]="urlFormControl"
                          buttonType="mat-icon-button">
                        </kl-attach-url-form-control>
                      </div>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="lstOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedVideoFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>
                <!--/ Video attachment -->

                <!-- File attachment -->
                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu-file">
                    <div>
                      <p class="m-0 title" translate>File</p>
                    </div>

                    <div fxLayout="row">

                      <kl-image-picker-form-control
                        formControlName="lstOfAttachments"
                        [showBorder]="false"
                        [buttonType]="'mat-icon-button'"
                        svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                        [allowMultiple]="true"
                        [showAttachments]="false"
                        [s3Category]="s3Category"
                        [acceptedFileTypes]="acceptedFileTypes">
                      </kl-image-picker-form-control>
                    </div>
                  </div>
                </div>
                <!--/ File attachment -->

              </ng-container>
            </mat-menu>
          </div>

          <div>
            <div id="schedule-update-wrapper" class="" fxLayout fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="0" fxLayoutAlign="end center" fxLayoutAlign.xs="end">
              <div class="schedule-post-wrapper" fxLayout fxLayoutGap="12px" fxLayoutGap.xs="6px" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" >
                <span class="schedule" translate>Schedule</span>
                <div fxFlex="110px" fxFlex.xs="92px">
                  <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                    <mat-label translate class="choose-date">Choose date</mat-label>
                    <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" formControlName="scheduledDate">
                    <mat-datepicker-toggle matSuffix [for]="scheduledDate">
                      <div  matDatepickerToggleIcon>
                        <svg-icon class="text-center date-icon"
                          [applyCss]="true"
                          src="assets/svgs/date-calendar.svg"
                          [svgStyle]="{ 'width.px': 13, 'height.px': 13}">
                        </svg-icon>
                      </div>
                    </mat-datepicker-toggle>
                    <mat-datepicker #scheduledDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div fxFlex="76px" fxFlex.xs="48px">
                  <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value" [placeholder]="'Time' | translate" formControlName="scheduledTime"></kl-hour-selectable-form-control>
                </div>
              </div>

            </div>

          </div>
        </div>

        <div [ngClass]="{'send-btn-container': !progress}" class="share-button w-100" fxLayout fxLayoutAlign="end end">
          <div [ngClass]="{'w-100 mt-2': isMobileDevice}">
            <kl-flat-button
              class="submit-btn"
              [label]="postDetails ? 'Update' : 'Submit'"
              [disabled]="(!shareFormText.value || !shareFormPromotionalTitle.value || (!shareFormProductsMetaData.value || (shareFormProductsMetaData.value && !shareFormProductsMetaData.value.productId)) || !this.selectedSku) && !audioAttachment?.value?.url"
              [progress]="progress"
              (clicked)="createPost()">
            </kl-flat-button>

          </div>
        </div>

      </div>

    </div>
  </form>
</div>
