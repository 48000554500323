<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ data?.title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="dialog-content p-0 add-member-dialog">
    <kl-add-program-members (selectedUsers)="membersSelected($event)"
      (closeMembersDialog)="cancel()"
      [showExtraFields]="data?.extraParams?.showExtraFields"
      [pageId]="data?.extraParams?.pageId"
      (onResetStream)="onMembersAdded()">
    </kl-add-program-members>
  </div>
</div>
