import { Injectable } from '@angular/core';
import { KalgudiMessageDetails } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiQaStateService {

  private newStreamItemSubject = new Subject<KalgudiMessageDetails>();

  constructor() { }

  get newStreamItem$(): Observable<KalgudiMessageDetails> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: KalgudiMessageDetails): void {
    this.newStreamItemSubject.next(item);
  }
}
