import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiProject } from '@kalgudi/types';

@Component({
  selector: 'kl-creation-dialog',
  templateUrl: './creation-dialog.component.html',
  styleUrls: ['./creation-dialog.component.scss']
})
export class CreationDialogComponent extends KalgudiDialog<CreationDialogComponent> implements OnInit {

  projectDetails: KalgudiProject;


  constructor(
    protected dialogRef: MatDialogRef<CreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

  }

  ngOnInit() {}

  /**
   * Event handler called upon successful project creation.
   * Closes the dialog with the project details
   */
  onProjectCreated(project: KalgudiProject): void {

    this.projectDetails = project;

    this.ok();
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: this.projectDetails,
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }
}
