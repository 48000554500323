import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

import {
  PageLocationRangeFilterComponent,
} from './components/page-location-range-filter/page-location-range-filter.component';
import {
  PageTargetMembersFilterComponent,
} from './components/page-target-members-filter/page-target-members-filter.component';
import { PageTargetMembersComponent } from './components/page-target-members.component';
import { PageShareFiltersApiService } from './services/page-filters-api.service';
import { PageFiltersService } from './services/page-filters.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
  declarations: [
    PageTargetMembersFilterComponent,
    PageLocationRangeFilterComponent,
    PageTargetMembersComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    // Material Modules
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatChipsModule,
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    ScrollingModule,
    MatRadioModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiSlideToggleModule,

    TranslateModule,
  ],
  exports: [
    PageTargetMembersFilterComponent,
    PageLocationRangeFilterComponent,
    PageTargetMembersComponent,
  ],
  providers: [
    PageShareFiltersApiService,
    PageFiltersService,
  ]
})
export class KalgudiPageTargetMembersModule { }
