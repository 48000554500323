<mat-card [@floatTop]="{ value: '', params: {delay: 20}}" class="p-0 mb-1 program-tile">
  <div fxLayout="row " fxLayoutGap="14px" fxLayoutAlign="start start" class="px-2 py-2 w-100">

    <!-- Program image -->
    <div fxFlex="15%" fxFlex.xs="calc(16% - 14px)">
      <span class="profile-pic d-block rounded-circle overflow-hidden w-100 m-auto"
        [style.background-image]="pageProfilePic | safe: 'style'">

        <img class="profile-img w-100" width="100" src="assets/icons/transparent.png"/>
      </span>
    </div>
    <!-- /Program image -->

    <!-- Program content -->
    <div fxLayout="column" fxFlex="54%" fxFlex.xs="49%"
      [ngClass]="{'followers': pageDetails?.memberRole === memberRoles?.FOLLOWER, 'contributors': pageDetails?.memberRole === memberRoles?.CONTRIBUTOR}">
      <div>
        <a class="text-capitalize line-truncate name" [title]="pageDetails?.pageTitle">{{ pageDetails?.pageTitle }}</a>
        <p class="mb-1 page-type text-success">{{pageDetails?.pageType | titlecase | trimSpecialCharacters | translate }}</p>
      </div>
      <div class="page-description">
        <!-- <p class="mb-0"><span class="admin">Admin</span><span class="mx-1 admin">|</span><span
            class="edit"><a>Edit</a></span></p> -->
        <p class="mb-0 text-muted text-truncate" [title]="pageDetails?.pageDescription">{{ pageDetails?.pageDescription }}</p>
      </div>
      <div class="date text-truncate" [title]="pageDetails.createdBy.firstName">
        <span class="font-weight-bold" translate>Owner :</span> {{pageDetails.createdBy.firstName}}
      </div>
      <div>
        <p class="date mb-0">
          <span class="font-weight-bold" translate>Created Date : </span>
          <span>{{pageDetails?.CT | date:'dd'}} </span>
          <span translate>{{pageDetails?.CT | date:'MMM' }} </span>
          <span>{{pageDetails?.CT | date:'yyyy' }} </span>
        </p>
      </div>
    </div>
    <!-- /Program content -->

    <!-- Roles -->
    <div fxLayout="column" fxLayoutAlign="space-between center" class="h-85">
      <div fxLayout="row">
        <!-- <div *ngIf="pageDetails?.memberRole != memberRoles.ADMIN && pageDetails?.memberRole != memberRoles.CONTRIBUTOR" (click)="$event.stopPropagation()">
          <kl-button label="Unfollow" ></kl-button>
          <kl-slide-toggle falseLabel="Follow"></kl-slide-toggle>
        </div> -->

        <div class="clone-chip mr-1" *ngIf="pageDetails?.memberRole === memberRoles?.ADMIN">
          <mat-chip-list>
            <mat-chip (click)="openClonePage(); $event.stopPropagation()" translate>Clone</mat-chip>
          </mat-chip-list>
        </div>

        <div *ngIf="pageDetails?.memberRole === memberRoles?.ADMIN">
          <kl-chips [chip]="memberRole?.ADMIN"></kl-chips>
        </div>

        <div *ngIf="pageDetails?.memberRole === memberRoles?.FOLLOWER">
          <kl-chips [chip]="memberRole?.FOLLOWER"></kl-chips>
        </div>

        <div *ngIf="pageDetails?.memberRole === memberRoles?.CONTRIBUTOR">
          <kl-chips [chip]="memberRole?.CONTRIBUTOR"></kl-chips>
        </div>
      </div>

      <div class="mt-members">
        <p class="mb-0 members-count text-center">{{ pageDetails?.membersCount | modernNumber }}</p>
        <p class="mb-0 members text-center" translate>Members</p>
      </div>
    </div>
    <!-- /Roles -->

  </div>
</mat-card>
