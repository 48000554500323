import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSmsLogsList } from '../../classes/kalgudi-sms-logs-list';

@Component({
  selector: 'kl-sms-logs-stream',
  templateUrl: './sms-logs-stream.component.html',
  styleUrls: ['./sms-logs-stream.component.scss']
})
export class SmsLogsStreamComponent extends KalgudiSmsLogsList implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {
    super(injector, notification, util);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}
}
