import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';

import { KalgudiFarmerFarmingActivityAddSeason } from '../../classes/kalgudi-farmer-farming-activity-add-season';

@Component({
  selector: 'kl-farmer-farming-activity-add-season',
  templateUrl: './farmer-farming-activity-add-season.component.html',
  styleUrls: ['./farmer-farming-activity-add-season.component.scss']
})
export class FarmerFarmingActivityAddSeasonComponent extends KalgudiFarmerFarmingActivityAddSeason implements OnInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  constructor(
    protected injector: Injector,
    protected dialogsService: KalgudiDialogsService
  ) {
    super(injector, dialogsService);

    this.activeTabIndex = 0;
  }

  ngOnInit() {
    this.addHarvestingDate();

    if (this.farmingFarmType) {
      this.farmingFarmTypeField.patchValue(this.farmingFarmType);
    }
  }

  /**
   * Resets the geo fence markings
   */
  resetMap() {

    this.geoMarker.reset();
    this.geoFences.patchValue(null);
  }

  onDestroyed(): void {}
}
