import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, FavoriteLocationsResponse, KalgudiLocation, KalgudiWeatherDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiWeatherApiService {

  /**
   * `v2/profiles/:profileKey/weather`
   */
  private readonly API_WEATHER = `${this.env.restBaseUrlV2}/profiles/:profileKey/weather`;

  /**
   * `/v2/profiles/:profileKey/favourite-locations`
   */
  private readonly API_FAVORITE_LOCATIONS = `${this.env.restBaseUrlV2}/profiles/:profileKey/favourite-locations`;

  constructor(
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) { }


  /**
   * Calls Kalgudi api to get weather details
   */
  getWeatherDetails(profileKey: string, latitude: string | number, longitude: string | number, language: string): Observable<KalgudiWeatherDetails> {

    const params = {
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      lang: language
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_WEATHER.replace(':profileKey', profileKey), { params } )
      .pipe(

        // Api response error handlerS
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Calls Kalgudi api to get favorite locations
   */
  getFavoriteLocations(profileKey: string, ): Observable<FavoriteLocationsResponse> {

    return this.httpClient.get<ApiResponseCommon>(this.API_FAVORITE_LOCATIONS.replace(':profileKey', profileKey))
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Calls api to add in favorite locations list
   */
  addToFavoriteLocations(profileKey: string, payload: KalgudiLocation): Observable<KalgudiLocation> {

    return this.httpClient.post<ApiResponseCommon>(this.API_FAVORITE_LOCATIONS.replace(':profileKey', profileKey), payload)
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi favorite location object type
        map(res => res.data)
      );
  }
}
