import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialogResult, KalgudiImageDialogConfig, KalgudiImageDialogResult } from '@kalgudi/types';

import { KalgudiUploadAttachmentsDialog } from '../../classes/kalgudi-upload-attachments-dialog';


@Component({
  selector: 'kl-upload-attachments-dialog',
  templateUrl: './upload-attachments-dialog.component.html',
  styleUrls: ['./upload-attachments-dialog.component.scss']
})
export class UploadAttachmentsDialogComponent extends KalgudiUploadAttachmentsDialog<UploadAttachmentsDialogComponent> implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploadAttachmentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiImageDialogConfig,
  ) {

    super(
      dialogRef,
      dialogData,
    );
  }

  ngOnInit() {}

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiImageDialogResult = {
      accepted: true,
      data: {
        attachments: this.attachments
      }
    };

    this.closeDialog(result);
  }

}
