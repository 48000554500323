import { animate, state, style, transition, trigger } from '@angular/animations';

export const zoomOutAnimation = trigger('zoomOut', [
  state('*', style({
  })),
  state('zoomOut', style({
    transform: 'scale(0)',
    maxWidth: '0px',
    maxHeight: '0px',
    margin: '0px !important'
  })),

  transition('* => zoomOut', animate(200))
]);


export const fadeInAnimation = trigger('fadeIn', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('300ms', style({
      opacity: 1
    }))
  ]),
  transition(':leave', [
    animate('300ms', style({
      opacity: 0
    }))
  ])
]);

/**
 * Array of all kalgudi angular animations
 *
 * Animation delay tricks https://stackoverflow.com/a/53644952/2401088
 */

export const slideRightAnimation = trigger('slideRight', [
  transition(':enter', [
    style({
      transform: 'translateX(-10%)',
      opacity: '0.3'
    }),
    animate('250ms', style({
      transform: 'translateX(0%)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('250ms', style({
      transform: 'translateX(-10%)',
      opacity: '0.3'
    }))
  ])
]);


export const floatTopAnimation = trigger('floatTop', [
  transition(':enter', [
    style({
      transform: 'translateY(10px)',
      opacity: '0'
    }),
    animate('200ms {{delay}}ms', style({
      transform: 'translateY(0px)',
      opacity: '1'
    }))
  ]),
  transition(':leave', [
    animate('200ms', style({
      transform: 'translateY(10px)',
      opacity: '0'
    }))
  ])
]);
