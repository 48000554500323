import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { checkMobileDevice, GeoLocationService, KalgudiDestroyable, KalgudiUploadService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { Attachment, ATTACHMENT_TYPE_MAP, S3PolicyPathCategoryMap } from '@kalgudi/types';

/**
 * Referred from https://davidwalsh.name/browser-camera
 */
@Component({
  selector: 'kl-capture-image-form-control',
  templateUrl: './capture-image-form-control.component.html',
  styleUrls: ['./capture-image-form-control.component.scss']
})
export class CaptureImageFormControlComponent extends KalgudiDestroyable implements OnInit {


  @ViewChild('videoElement', { read: ElementRef, static: true }) public videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement', { read: ElementRef, static: true }) public canvasElement: ElementRef<HTMLCanvasElement>;

  @Input()
  s3Category: S3PolicyPathCategoryMap;

  @Output()
  capturedImage = new EventEmitter<Attachment>();

  blob: any;
  file: any;
  localStream: any;

  progress: any;

  defaultsOpts: any;
  flipBtn: boolean;

  // default user media options
  shouldFaceUser: boolean;

  constructor(
    protected uploadService: KalgudiUploadService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected geoLocationService: GeoLocationService
  ) {

    super();

  }

  ngOnInit() {

    this.defaultsOpts = { audio: false, video: true };


    this.shouldFaceUser = checkMobileDevice() ? false : true ;

    // check whether we can use facingMode
    const supports: any = navigator.mediaDevices.getSupportedConstraints();
    this.flipBtn = supports.facingMode === true ? true : false;

    this.initMedia();

  }

  /**
   * Changes the camera
   */
  changeCam() {
    if (this.localStream == null ) {
      return;
    }

    // we need to flip, stop everything
    this.localStream.getTracks().forEach(t => {
      t.stop();
    });

    // toggle / flip
    this.shouldFaceUser = !this.shouldFaceUser;

    this.initMedia();
  }

  /**
   * Initializes the video media
   */
  initMedia(): void {

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      this.defaultsOpts.video = { facingMode: this.shouldFaceUser ? 'user' : 'environment' };

      // Not adding `{ audio: true }` since we only want video now
      navigator.mediaDevices.getUserMedia(this.defaultsOpts)
      .then(stream => {

        this.videoElement.nativeElement.srcObject = stream;

        // this.videoElement.nativeElement.requestFullscreen();

        this.localStream = stream;
        this.videoElement.nativeElement.play();

      })
      .catch((err) => {
        console.log(err);
      });
    }

  }

  onDestroyed(): void {

    if (this.localStream) {
      // Unsubscribing to the media devices
      // this.localStream.getTracks()[0].stop();
      this.localStream.getTracks().forEach(t => {
        t.stop();
      });
    }

  }

  /**
   * Clears the captured image
   */
  clear(): void {

    const context = this.canvasElement.nativeElement.getContext('2d');
    context.clearRect(0, 0, 640, 480);

    this.file = null;
  }

  /**
   * Captures the image
   */
  capture() {

    this.canvasElement.nativeElement.width = this.videoElement.nativeElement.clientWidth;
    this.canvasElement.nativeElement.height = this.videoElement.nativeElement.clientHeight;

    // Elements for taking the snapshot
    const context = this.canvasElement.nativeElement.getContext('2d');

    context.drawImage(
      this.videoElement.nativeElement,
      0,
      0,
      this.canvasElement.nativeElement.width,
      this.canvasElement.nativeElement.height
    );

    this.postCanvasToURL();
  }

  /**
   * Pushes the image to s3
   */
  uploadToS3(): void {

    // this.progress = true;

    this.notification.showSpinner();
    this.uploadService.uploadFile(this.file, this.s3Category, true, `${this.file.name}.${this.file.type.split('/').pop()}`)
      .subscribe(
        res => {
          this.notification.hideSpinner();
          const geoLocation = this.geoLocationService.currentLatLong;

          this.capturedImage.emit({
            url: res.filePath,
            context: '',
            msgType: ATTACHMENT_TYPE_MAP.IMAGE,
            geoLocation: geoLocation
          });
        },
        err => this.notification.hideSpinner()
      );
  }


  /**
   * Converts the data url to the file format
   */
  private postCanvasToURL() {
    // Convert canvas image to Base64
    const img = this.canvasElement.nativeElement.toDataURL();
    // Convert Base64 image to binary
    this.blob = this.dataURItoBlob(img);

    this.file = this.blobToFile(this.blob, `qa${new Date().getTime()}`);

    this.uploadToS3();

  }

  /**
   * Converting the data uri to the blob format
   */
  private dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {

      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
  }

  /**
   * Converting the blob to the file format
   */
  private blobToFile = (blob: Blob, fileName: string): File => {
    const b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return blob as File;
  }
}
