import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, switchMap, take } from 'rxjs/operators';

import { HttpStatusCode } from '../constants/http-status-codes';
import { KalgudiAppService } from '../services/kalgudi-app.service';
import { Router } from '@angular/router';

/**
 * Kalgudi default http request interceptor to all Kalgudi APIs. It appends
 * `userName` and `password` params to the request header.
 *
 * Inject http interceptors in the root module as a provider to intercept
 * all http request to API.
 *
 * Handles any http Kalgudi infrastructure error responses. A global error
 * handler for any http error response.
 *
 * It checks for following error messages and perform activities based on that.
 * 1. **400** - The auth token passed is invalid. Logout from the app and force user
 * to login again.
 * 2. **401** - The auth token passed is valid but expired. In this case regenerate the auth
 * token by calling auth Api.
 *
 * @author Pankaj Prakash
 * @example
 * {
 *   providers: [
 *     {
 *       provide: HTTP_INTERCEPTORS,
 *       useClass: HttpInterceptorService,
 *       multi: true,
 *     }
 *   ]
 * }
 */
@Injectable({
  providedIn: 'root'
})
export class KalgudiHttpInterceptorService implements HttpInterceptor {

  isPublicPage: boolean;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private app: KalgudiAppService,
    private router: Router,
  ) { }



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Intercepts all http requests and add the logged in user login details.
   *
   * @param req Current request
   * @param next Next callback chain in the request
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return this.tryHttpRequest(req, next)
      .pipe(

        // Handle http errors
        catchError(err =>

          of({}).pipe(

            // Handle error globally
            switchMap(r => this.handleErrors(req, err)),

            // If global error handler didn't thrown any error then try making
            // the same request one more time.
            switchMap(r => this.tryHttpRequest(req, next))
          )
        )
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Makes a http request wrapping auth headers
   */
  private tryHttpRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Append auth headers to the current request
    const request = this.shouldAppendAuthHeaders(req) // req.url.includes(this.env.restBaseUrl) || req.url.includes(this.env.restBaseUrlV2)
      ? this.appendAuthHeaders(req)
      : req;

    return next.handle(request);
  }

  /**
   * Appends auth headers to the http request.
   *
   * @param req Http request
   */
  private appendAuthHeaders(req: HttpRequest<any>): HttpRequest<any> {

    let headers: HttpHeaders = req.headers;

    if(this.env.appId === 'UBM' || this.env.appId === 'KHETIGHAR' || this.env.appId === 'ORMAS' || this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER') {
      headers = headers.append('wl_app', (this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER') ? 'SAMUNNATI' : this.env.appId);
    }

    // if(this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER') {
    //   headers = headers.append('project', 'SAMUNNATI');
    // }

    // Append auth headers only if user is logged in
    if (this.app.loggedIn && this.app.authToken) {
      headers = headers.append('userName', this.app.loginCredentials.userName);
      // headers = headers.append('password', this.app.loginCredentials.password);
      headers = headers.append('token',    this.app.authToken.token);

      if(req.url.includes('transactions') || req.url.includes('estore/lots')) {
        if(!headers.has('project')) {
          headers = headers.append('project', this.env.appId);
        }
      }


    } else {

      // console.log("KalgudiHttpInterceptorService -> appendAuthHeaders");

      // Does not have a valid token
      // Users are not allowed to use the app without login or valid token.
      // this.app.logout(false);
    }

    // Create copy of existing request and return back
    return req.clone({ headers });
  }

  /**
   * Updates the auth token. If token update is already in pending then
   * returns the observable containing token update state.
   */
  private refreshAuthToken(): Observable<any> {

    return this.app.authTokenRefreshing
      ? this.app.authTokenRefreshing$.pipe(take(1))
      : this.app.updateAuthToken();
  }

  /**
   * Handles any http Kalgudi infrastructure error responses. A global error
   * handler for any http error response.
   *
   * It checks for following error messages and perform activities based on that.
   * 1. **400** - The auth token passed is invalid. Logout from the app and force user
   * to login again.
   * 2. **401** - The auth token passed is valid but expired. In this case regenerate the auth
   * token by calling auth Api.
   */
  private handleErrors(req: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {

    // Assert if global error handling is required or not
    // Calling this will throw an error if the error should not get handled this place.
    const shouldHandle = this.shouldHandle(req, err);

    if (typeof shouldHandle !== 'boolean') {
      return shouldHandle;
    }
    const val = err.status === HttpStatusCode.BAD_REQUEST
      ? this.handleInvalidAuthToken(err)
      : err.status === HttpStatusCode.UNAUTHORIZED
        ? this.handleExpiredAuthToken(err)
        : null;

    if (val === null) {
      throw err;
    }

    return val.pipe(

      // A small delay is required after handling the http errors.
      // The delay ensures successful settlement of all codes that gets
      // executed after the error handler.
      // For this case after refreshing the auth token it immediately makes a request for the
      // same http request. Which does not have the updated token. Adding a small delay
      // ensures it gets the latest auth token from local.
      delay(200)
    );
  }

  /**
   * Generic error handler for all Kalgudi V2 api, that responded with error code **400**.
   * Error code **400** symbolizes the auth token passed in the http request header is invalid.
   *
   * In case of invalid auth token, the app must clear all its states, reload itself and take user to the
   * login page.
   */
  private handleInvalidAuthToken(err: HttpErrorResponse): Observable<any> {
    // Logout from the app and take user to the login page
    return this.app.logout(!this.app.isShaktimanFarmingSolutions && !this.app.isProRiseStore);
  }

  /**
   * Generic error handler for all Kalgudi V2 api, that responded with error code **401**.
   * Error code **401** symbolizes the auth token passed in the http request header has expired.
   *
   * In case of expired auth token, the app must hit the api to regenerate the auth token, update its
   * token state (cache) and retry the service it was trying to hit again.
   */
  private handleExpiredAuthToken(err: HttpErrorResponse): Observable<any> {
    /** As per new requirement,
    * instead of refreshing the auth token we will clear the user's current session
    * */

    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
    return this.isPublicPage ? null : this.app.logout(!this.app.isShaktimanFarmingSolutions && !this.app.isProRiseStore);
  }

  /**
   * Asserts if for a given request the error should be handled globally or not.
   * Define a list of all possible request patterns that must be handled globally.
   */
  private shouldHandle(req: HttpRequest<any>, err: HttpErrorResponse): boolean | Observable<never> {

    // Put list of all Api that must go through the custom http error handler
    const errorThrowingApis = [
      `${this.env.restBaseUrlV2}/`,
    ];

    // Http api url
    const requestedApi = req.url;

    if (errorThrowingApis.findIndex(s => requestedApi.includes(s)) < 0) {
      // The http request is not from one of the error throwing Api
      // The response error should not be handled globally
      return throwError(err);
    }

    // Request url contains a global error throwing api url
    return true;
  }

  /**
   * Returns `true` if the interceptor should append auth headers otherwise `false`.
   */
  private shouldAppendAuthHeaders(req: HttpRequest<any>): boolean {

    /**
     * List of urls that does not requires auth headers
     */
    const exemptedUrls = [
      // `${this.env.domain}/rest/v1/profiles/coudtls`,
      // `${this.env.domain}/v2/auth/login`,
    ];

    return (req.url.includes(this.env.restBaseUrl) || req.url.includes(this.env.restBaseUrlV2))
      && !exemptedUrls.includes(req.url);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
