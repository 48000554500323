import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { KalgudiDialogsService } from "@kalgudi/common";
import {
  KalgudiAppService,
  KalgudiSpinnerService,
  KalgudiUsersService,
  KalgudiUtilityService,
  kalgudiAnimations,
} from "@kalgudi/core";
import {
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
  KalgudiEnvironmentConfig,
  KalgudiNotification,
} from "@kalgudi/core/config";
import {
  KalgudiShareUpdateService,
  ShareUpdateActions,
} from "@kalgudi/share-update";
import {
  KalgudiReportAbuseService,
  KalgudiSocialRouteConfig,
} from "@kalgudi/social";
import { KalgudiDialogConfig, KalgudiRobocall, ShareUpdate } from "@kalgudi/types";
import { Observable } from "rxjs";
import { filter, switchMap, take } from "rxjs/operators";
import { RobocallStreamService } from "../../services/robocall-stream.service";

@Component({
  selector: "kl-robocall-tile",
  templateUrl: "./robocall-tile.component.html",
  styleUrls: ["./robocall-tile.component.scss"],
  animations: kalgudiAnimations,
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RobocallTileComponent
  extends ShareUpdateActions<KalgudiRobocall>
  implements OnInit {
  @Input()
  scheduledUpdates: boolean;

  @Input()
  animationDelay = 0;

  @Input()
  fullView: boolean;

  @Input()
  showMatMenu: boolean;

  @Input()
  memberRole: string;

  @Input()
  url: string;

  @Input()
  showPageDetails: boolean;

  @Input()
  showLessData: boolean;

  @Output()
  postUpdated = new EventEmitter();

  tileAnimationState: "init" | "deleted";

  showCommentForm = false;
  isLoggedIn: boolean;

  farmStoreUrl: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected sharePost: KalgudiShareUpdateService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected usersService: KalgudiUsersService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    private robocallStreamService: RobocallStreamService,
    private spinner: KalgudiSpinnerService,
  ) {
    super(
      sharePost,
      notifications,
      kalgudiApp,
      kalgudiDialogs,
      usersService,
      reportAbuse,
      util,
      injector
    );

    this.farmStoreUrl = this.env.farmerStoreDomain;

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
    this.tileAnimationState = "init";
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Implement operation to be performed on successful post like.
   */
  onPostLiked(shareDetails: ShareUpdate): void {
    this.liked.emit();
  }

  onPostCommented(shareDetails: ShareUpdate): void {
    this.commented.emit();
  }

  toggleCommentList(): void {
    this.showCommentForm = !this.showCommentForm;
  }

  /**
   * Navigate to share update full view
   * @param shareId
   */
  toFullview(postDetails: KalgudiRobocall): void {
    let shareId = postDetails?.shareId;
    let newPostDetails;
    this.spinner.toggleRouteLoadingProgress(true);

    // Fetching live data while opening fullview
    this.sharePost.getShareUpdate(shareId)
      .pipe().subscribe(
        res => { newPostDetails = res }
      )

    setTimeout(() => {
      this.spinner.toggleRouteLoadingProgress(false);
      this.showRobocallFullView(newPostDetails).pipe(
        take(1),
        switchMap(_ => {
          return this.robocallStreamService.downloadRobocallStatus(newPostDetails.shareId);
        }),
      ).subscribe(res => {
        if(res.report_url) {
          window.open(res?.report_url, '_self')
          this.notifications.showMessage("Report downloaded successfully!");
        } else {
          this.notifications.showMessage(res?.message);
        }
      });
    }, 1100);
  }

  /**
   * Opens the share update form in update mode
   */
  private showRobocallFullView(postDetails: KalgudiRobocall): Observable<any> {

    if(this.fullView) {
      return;
    }

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Robocall',
      acceptButtonTitle: 'Send',
      rejectButtonTitle: 'Cancel',
      data: {
        postDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      hasBackdrop: true,
      disableClose: false,
      autoFocus: false,
    };

    return this.robocallStreamService.openRobocallFullView(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res && res.accepted)
      );
  }

  protected onPostDeleted(): void {
    this.tileAnimationState = "deleted";
  }

  /**
   * calls after updating the post successfully
   */
  protected onPostUpdated(res): void {
    this.postUpdated.emit(res);
  }
}
