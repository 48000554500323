<section id="catalogue" fxLayout="column" class="h-100">
  <div class="catalogue-tile supplier-tile h-100" fxLayout="column" *ngIf="appId !== outputStoreId">
  <!-- <div class="catalogue-tile supplier-tile h-100 cursor-pointer" fxLayout="column" (click)="openSupplierPage(supplierDetails)"> -->
    <div fxLayoutAlign="space-between center" class="mb-2" fxLayout="row">
        <div>
          <span class="manufacturer">
            Supplier  <!-- &nbsp;/&nbsp;Producers&nbsp;/&nbsp;Farmer -->
          </span>
        </div>
        <!--<div class="catalogue-title cursor-pointer">
          Catalogue(200)
        </div>-->
      </div>

      <ng-container  *ngIf="progress" fxLayoutAlign="center start">
        <kl-no-content
          progressText="Loading..."
          [progress]="progress">
        </kl-no-content>
      </ng-container>

      <ng-container *ngIf="!progress">
        <div fxLayout="column" class="supplier-details" fxLayoutGap="5px">
          <div class="ml-3">
            <!-- <div class="seller font-weight-bold mb-4 cursor-ponter"> -->
            <div class="font-weight-bold mb-1">
              <span class="supplier">
                {{supplierDetails?.firstName}}
              </span>
            </div>

            <div fxLayout="column" fxLayoutGap="5px">
              <div class="location">
                <img *ngIf="supplierDetails?.location" src="assets/svgs/location.svg">
                <span *ngIf="supplierDetails?.locationDetails?.placeName">{{supplierDetails?.locationDetails?.placeName}}</span>
                <span *ngIf="supplierDetails?.locationDetails?.districtName">, {{supplierDetails?.locationDetails?.districtName}}</span>
                <span *ngIf="supplierDetails?.locationDetails?.stateName">, {{supplierDetails?.locationDetails?.stateName}}</span>
              </div>
              <!--<div class="certification ml-20 text-muted">
                <span>Certification</span>
                <span> - </span>
                <span>FSSAI</span>
              </div>-->
            </div>
          </div>
        </div>
      </ng-container>
  </div>

  <span class="border-bottom" *ngIf="appId !== outputStoreId"></span>

  <div class="catalogue-tile h-100" fxLayout="column" [ngClass]="{'output-reseller-tile mb-4': (appId === outputStoreId)}">

    <div fxLayoutAlign="space-between center" class="mb-2" fxLayout="row">
      <div>
        <span class="manufacturer" *ngIf="appId !== outputStoreId">
          Reseller
        </span>

        <span class="manufacturer" *ngIf="appId === outputStoreId">
          {{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.businessTypeName === (bizType?.FARMER || bizType?.FPO) ? 'Supplier' : 'Reseller'}}
        </span>

      </div>
      <!--<div class="catalogue-title">
        Catalogue(200)
      </div>-->
    </div>

    <ng-container  *ngIf="sellerDetailsProgress" fxLayoutAlign="center start">
      <kl-no-content
        progressText="Loading..."
        [progress]="sellerDetailsProgress">
      </kl-no-content>
    </ng-container>

    <ng-container *ngIf="!sellerDetailsProgress">
      <div>
        <div fxLayout="column" class="supplier-details" fxLayoutGap="5px">
          <div class="ml-3">
            <!-- <div class="seller font-weight-bold mb-4 cursor-pointer" (click)="openVasudhaikaPage(level2ProductDetails?.resellerDetails);$event.stopPropagation(); $event.preventDefault()"> -->
            <div class="font-weight-bold mb-1">

              <span class="supplier">
                {{sellerProfileInfo?.firstName}}
              </span>
            </div>

            <div fxLayout="column" fxLayoutGap="5px">
              <!-- <div class="location">
                <img *ngIf="level2ProductDetails?.resellerDetails?.location" src="assets/svg/location.svg">
                <span> {{level2ProductDetails?.resellerDetails?.location}}</span>
              </div> -->

              <div class="location">
                <img *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo" src="assets/svgs/location.svg">
                <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName">{{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}}</span>
                <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName">, {{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName}}</span>
                <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName">, {{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName}}</span>
              </div>

              <!--<div class="certification ml-20">
                <span>Certification</span>
                <span> - </span>
                <span>Spices Board | ML/REG/13684/2017</span>
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <div>
      <!-- <kl-no-content text="No reseller"></kl-no-content> -->
    </div>

    <!-- <div fxLayoutAlign="center center" *ngIf="productLevel2Details?.sellerType !== 'RESELLER'">
      <h6 class="no-details text-muted">No reseller</h6>
    </div> -->
  </div>

</section>
