import { Component, Injector, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KalgudiUtilityService } from '@kalgudi/core';

import { KalgudiCooperativeSocietyProfile } from '../../classes/kalgudi-cooperative-society-profile';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';


@Component({
  selector: 'kl-cooperative-society-profile',
  templateUrl: './cooperative-society-profile.component.html',
  styleUrls: ['./cooperative-society-profile.component.scss']
})
export class CooperativeSocietyProfileComponent extends KalgudiCooperativeSocietyProfile implements OnInit {

  isPublicPage: boolean;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute,
    private router: Router,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
  ) {

    super(injector, util,route);
  }

  ngOnInit() {

    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');

    this.init();
    this.getLoginId();

  }

  onDestroyed(): void {}
}
