import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { Attachment, AttachmentList, FileMimeTypes, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { FpoProfiles } from '../../../classes/fpo-profiles';
import { OrganisationProfileService } from '../../../services/organisation-profile.service';

@Component({
  selector: 'kl-basic-info-form',
  templateUrl: './basic-info-form.component.html',
  styleUrls: ['./basic-info-form.component.scss']
})
export class BasicInfoFormComponent extends FpoProfiles implements OnInit {

  basicInfoForm: FormGroup;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [FileMimeTypes.IMAGE];
  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  addingBankDetails: boolean;
  menu: any;
  turnOverValue: any;
  shareCapitalValue: string;
  readonly todaysDate = new Date();
  isSaveEnable: boolean = false;
  allFinancialYearFilled: boolean = false;
  @Input() isEditBasicDialog: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    public organisationProfileService: OrganisationProfileService,
  ) {
    super(injector);

    // Value change
    this.entityValueChanges();
    this.removeZeros();
  }

  ngOnInit(): void {
    this.inputValueCommaConverter();
  }

  ngOnChanges() {
    setTimeout(() => {
      this.filterFinancialYrs();
    },2000)
  }

  entityList: any[] = [
    { id: 'FPO' , value: 'Farmer Producer Organization(FPO)' },
    { id: 'FPC' , value: 'Farmer Producer Company(FPC)' },
    { id: 'COOPERATIVE_SOCITY' , value: 'Cooperative Society' },
    { id: 'TRUST' , value: 'Trust' }
  ];

  entities = [
    { id: 0, name: 'Yes', value: true },
    { id: 1, name: 'No', value: false },
  ];

  options = ['option1', 'option2', 'option3']
  status = ['option1', 'option2']
  societyType = ['option1', 'option2', 'option3']
  selectedOption: string;

  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {
    const attachments: AttachmentList = this.lstOfAttachments.value || [];
    attachments.splice(index, 1);
    this.lstOfAttachments.patchValue(attachments);
  }

  /**
   * Removes an item from the array
   * @param index Index of fpo logo details to remove
   */
  removeFpoLogoDetails(id: any) {
    this.logoDetails.splice(id, 1);
    this.fpoLogoDetailsArray.value.splice(id, 1);
  }

  /**
   * Removes an item from the array
   * @param index Index of turn over details to remove
   */
  removeTurnOverDetails(id: any) {
    let delConfirmed: any = this.organisationProfileService.openConfirmationSweetAlert('Are you sure you want to delete this Turn over details');
    delConfirmed.then(confirmation => {
      if (confirmation.isConfirmed) {
        this.turnOverDetails.splice(id, 1);
        this.turnoverDetailsArray.value.splice(id, 1);
        this.turnoverDetailsForm.reset();
        this.filterFinancialYrs(this.editFinancialYears, 'delete');
        this.financialYearField();
      }
    });
  }

  /**
   * Edit an item from the array
   * @param index Index of turn over details to edit
   */
  editFpoLogoDetails(index: any) {
    this.isEditFpoLogoDetails = true;
    this.fpoLogoDetailsIndex = index;
    this.fpoLogoDetailsForm.patchValue(this.fpoLogoDetailsArray.value[index]);
  }

  /**
   * Edit an item from the array
   * @param index Index of turn over details to edit
   */
  editTurnoverDetails(index: any) {
    this.allFinancialYearFilled = false;
    this.isEditTurnoverDetails = true;
    this.turnoverDetailsIndex = index;
    this.financialYears = this.editFinancialYears.filter(res => res.id === this.turnOverDetails[index].financialYearId);
    this.turnOverDetails[index].turnover = this.turnOverDetails[index].turnover.replace(/,/g, '');
    this.turnoverDetailsForm.patchValue(this.turnOverDetails[index]);
    this.inputValueCommaConverter();
  }

  onDestroyed(): void { }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: Attachment[], index?: number): void {
    this.kalgudiPhotoswipe.open(attachments, this.environment.domain, index).subscribe();
  }

  /**
   * To convert number into indian rupees notations
   * ex: from: 100000, to: 1,00,000
   */
  inputValueCommaConverter() {
    this.turnOverField.valueChanges.subscribe(val => {
      if (val) {
        this.turnOverValue = this.organisationProfileService.addCommaToNumericFields(this.turnOverField, this.turnOverValue);
        }
    })
    this.getShareCapital.valueChanges.subscribe(val => {
      if (val) {
        this.shareCapitalValue = this.organisationProfileService.addCommaToNumericFields(this.getShareCapital, this.shareCapitalValue);
        }
    })
  }

  /**
   * Showing message when all financial years are filled-up
   */
  financialYearField() {
    this.allFinancialYearFilled = !this.financialsYearId?.value && this.turnOverDetails.length === 5;
  }

  /**
   * Removing unnecessary zero values
   */
  removeZeros() {
    setTimeout(() => {
      let zeroValues = [
        this.memberShip,
        this.maleMembers,
        this.femaleMembers,
        this.activeMembers
      ]

      zeroValues.forEach(control => {
        if (control.value === 0) {
          control.patchValue('');
        }
      })
    }, 900);
  }

  /**
   * Resetting values for entity drop-down
   */
  entityValueChanges() {
    this.entityTypeField.valueChanges.subscribe((val) => {
      this.cinInputField.patchValue('')
      this.trustInputField.patchValue('')
      this.cooperativeInputField.patchValue('')
    })
  }

  // ----------------------- Getter's ----------------------- //

  /**
   * Get the AbstractControl for a field by name
   * @param fieldGroup The name of the Group or Details
   * @param fieldName The name of the field
   */
  getFormControl(fieldGroup, fieldName: string): AbstractControl {
    return fieldGroup.get(fieldName);
  }

  // Getters for CEO details only AbstractControl
  entityTypeField = this.getFormControl(this.entityDetailsGroup, 'entityType');
  cinInputField = this.getFormControl(this.entityDetailsGroup, 'companyCin');
  trustInputField = this.getFormControl(this.entityDetailsGroup, 'trustRegistrationNumber');
  cooperativeInputField = this.getFormControl(this.entityDetailsGroup, 'cooperativeRegistrationNumber');

  // Getters for CEO details only AbstractControl
  getCeoName = this.getFormControl(this.ceoDetailsGroup, 'ceoName');
  mobileNumberField = this.getFormControl(this.ceoDetailsGroup, 'ceoMobileNumber');
  emailIdField = this.getFormControl(this.ceoDetailsGroup, 'ceoEmailId');

  // Getters for basic-info form details only AbstractControl
  contactNameField = this.getFormControl(this.basicDetailsForm, 'contactNumber');
  memberShip = this.getFormControl(this.basicDetailsForm, 'memberShipBase');
  maleMembers = this.getFormControl(this.basicDetailsForm, 'maleMembers');
  femaleMembers = this.getFormControl(this.basicDetailsForm, 'femaleMembers');
  percentageOfSmallFarmers = this.getFormControl(this.basicDetailsForm, 'perOfSmallHolder');
  getShareCapital = this.getFormControl(this.basicDetailsForm, 'shareCapital');

}
