import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kl-geo-location-marker-common',
  templateUrl: './geo-location-marker-common.component.html',
  styleUrls: ['./geo-location-marker-common.component.scss']
})
export class GeoLocationMarkerComponent implements OnInit {

  location = new FormControl('');
  geoFencing = new FormControl([]);

  constructor() { }

  ngOnInit() {
  }


  ok() {

  }

  cancel() {

  }
}
