import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable, checkMobileDevice } from '@kalgudi/core';
import { KalgudiProfileService } from '../../../../../services/kalgudi-profile.service';
import { KalgudiProfileStateService } from '../../../../../services/kalgudi-profile-state.service';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BasicInfoDialogComponent } from '../basic-info-dialog/basic-info-dialog.component';
import { BasicInfoMobileDialogComponent } from '../basic-info-mobile-dialog/basic-info-mobile-dialog.component';
import { KL_ENV, KL_NOTIFICATION, KalgudiEnvironmentConfig, KalgudiNotification } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';

@Component({
  selector: 'kl-basic-info-tile',
  templateUrl: './basic-info-tile.component.html',
  styleUrls: ['./basic-info-tile.component.scss']
})
export class BasicInfoTileComponent extends KalgudiDestroyable implements OnInit {
  @Input()
  profileKey: string;

  @Input()
  basicInfoFormDetails: any;

  @Input()
  aboutMeBasicDetails: any;

  @Output()
  getFpoProfileData = new EventEmitter();

  @Input()
  actionIcon: string;

  @Input()
  isPublicPage: boolean;

  editable: boolean;
  s3BucketUrl: any;

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;
  private notification: KalgudiNotification;
  // private fpoProfileService: FpoProfileService;
  private profileStateService: KalgudiProfileStateService;
  fpoProfileDetails: any;
  isProgress: boolean;

  constructor(protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService
    ) {
    super();

    this.dialogService = this.injector.get(KalgudiDialogsService);
    this.profileService = this.injector.get(KalgudiProfileService);
    // this.fpoProfileService = this.injector.get(FpoProfileService);
    this.notification = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.s3BucketUrl         = environment.s3BucketUrl;
    this.profileStateService = this.injector.get(KalgudiProfileStateService);

    this.profileService.editable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.editable = res));

    this.getFpoProfileDetails();

  }

  ngOnInit(): void {
  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows social dialog
   */
  showBasicInfoDialog(isEdit?: boolean) {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Basic info',
      data: {
        profileKey: this.profileKey,
        basicInfoFormDetails: this.basicInfoFormDetails,
        aboutMeBasicDetails: this.aboutMeBasicDetails,
        isEditBasicDialog: isEdit
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'basicInfo',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {},
    };

    return this.openBasicInfoDialog(dialogDetails, dialogConfig)
      .pipe(
        filter((r) => r.accepted),

        // map(r => r.accepted),
      )
      .subscribe(
        res => {
          if(res.accepted) {
            this.getFpoProfileData.emit();
          }
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open social dialog
   */
  private openBasicInfoDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(BasicInfoMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(BasicInfoDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

  onDestroyed(): void { }

  /**
   * Get the profile details
   */
  getFpoProfileDetails() {
    this.notification.showSpinner;
    // this.fpoProfileService.getFpoProfilesDetails(this.profileKey)
    // .pipe(
    //   takeUntil(this.destroyed$),
    //   finalize(() => this.notification.hideSpinner)
    // )
    // .subscribe(
    //   res => {
    //     if (res) {
    //       this.fpoProfileDetails = res?.data?.samunnatiFpoDetails;
    //     }
    //   },
    // );

    this.profileStateService.data$
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(profile => {
      if (profile) {
      this.fpoProfileDetails = profile;
      console.log('this.fpoProfileDetails', this.fpoProfileDetails);

      }
    });
  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: any): void {
    let imgUrl = this.s3BucketUrl + attachments;
    let imgObj = [
      {url: imgUrl}
    ]

    this.kalgudiPhotoswipe.open(imgObj).subscribe();
  }

  /**
   * Convert indian rupee format
   */
  convertIndianRupeeFormat(value) {
    if (value === '') return '-'
    return '₹' + Number(value).toLocaleString('en-IN');
  }
}
