import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

@Component({
  selector: 'kl-slide-toggle',
  templateUrl: './kalgudi-slide-toggle.component.html',
  styleUrls: ['./kalgudi-slide-toggle.component.scss'],
  animations: kalgudiAnimations,
})
export class KalgudiSlideToggleComponent implements OnInit {

  @Input()
  trueLabel: string;

  @Input()
  falseLabel: string;

  @Input()
  checked = false;

  @Output()
  toggleChanged = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  toggleState(value?: boolean) {

    this.checked = typeof value === 'boolean'
      ? value
      : !this.checked;

    this.toggleChanged.emit(this.checked);
  }
}
