import { Injectable } from '@angular/core';
import { StoreProductLevel2 } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { TraceabilityApiService } from './traceability-api.service';

@Injectable()
export class TraceabilityService {

  constructor(
    private apiService: TraceabilityApiService
  ) { }

  /**
   * Calling api method to fetch product level2 details
   * @param productId
   * @returns
   */
  getProductLevel2Details(productId: string): Observable<StoreProductLevel2> {
    return this.apiService.getProductLevel2Details(productId);
  }

  /**
   * Fetching order details
   * @param orderId
   * @returns
   */
  getOrderFullView(orderId: string): Observable<any> {
    return this.apiService.getOrderFullView(orderId);
  }

  /**
   * Calls api method to get fulfillments details
   */
  getFulfillmentDetails(orderId: string, productLevel3Id: string): Observable<any> {
    return this.apiService.getFulfillmentDetails(orderId, productLevel3Id);
  }

  /**
   * Fetching order details
   * @param orderId
   * @param productLevel2Id
   * @param productLevel3Id
   * @returns
   */
  getTraceability(orderId: string,productLevel2Id: string, productLevel3Id: string ): Observable<any> {
    return this.apiService.getTraceability(orderId, productLevel2Id,productLevel3Id)
  }



  /**
   * Fetching list of suppliers (in output store)
   * @param orderId
   * @param productLevel2Id
   * @param productLevel3Id
   * @returns
   */
   getSupplierList(orderId: string): Observable<any> {
    return this.apiService.getSupplierList(orderId)
  }

  /**
   * Fetching order details
   * @param pageId
   * @returns
   */
  getSupplierDetails(pageId: string ): Observable<any> {
    return this.apiService.getSupplierDetails(pageId)
  }

  /** Calling api method to fetch review and rating details
   * @param level2Id
   * @returns
   */
  getReviewsComments(level2Id: string): Observable<any> {
    return this.apiService.getReviewsComments(level2Id);
  }

}
