import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUsersPickerDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiPageMembersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-pages-frequent-members-pickers-dialog',
  templateUrl: './pages-frequent-members-pickers-dialog.component.html',
  styleUrls: ['./pages-frequent-members-pickers-dialog.component.scss']
})
export class PagesFrequentMembersPickersDialogComponent
  extends KalgudiUsersPickerDialog<PagesFrequentMembersPickersDialogComponent>
  implements OnInit {

  pageId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: KalgudiPageMembersPickerDialogConfig,
    protected dialogRef: MatDialogRef<PagesFrequentMembersPickersDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);

    this.pageId = dialogData.pageId;
  }

  ngOnInit() { }

}
