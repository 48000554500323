import { Component, Injector, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { AiPredictionsFiltersForm } from '../../classes/ai-predictions-filters-form';


@Component({
  selector: 'kl-ai-predictions-filters-form',
  templateUrl: './ai-predictions-filters-form.component.html',
  styleUrls: ['./ai-predictions-filters-form.component.scss']
})
export class AiPredictionsFiltersFormComponent extends AiPredictionsFiltersForm implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {

    this.isShowCalendarForm = true;
    this.getAiPredictionsFilters();

    this.filterFormGroup.get('states').valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.districtList = this.transformHashMapToArray(this.districtArrayList[res])
        }
      );
  }

  onDestroyed(): void {

  }


}
