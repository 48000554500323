import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserFarmerEquipmentDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiFarmerEquipmentForm extends KalgudiDestroyable {

  @Input()
  farmerEquipmentDetails: KalgudiUserFarmerEquipmentDetails;

  @Output()
  closeDialog = new EventEmitter();

  farmerEquipmentForm: FormGroup;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;

  constructor(protected injector: Injector) {
    super();

    this.fb                          = this.injector.get(FormBuilder);
    this.util                        = this.injector.get(KalgudiUtilityService);
    this.notification                = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiFarmerProfileService = this.injector.get(KalgudiFarmerProfileService);


    this.farmerEquipmentForm = this.newFarmerEquipmentForm;
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Form group for add farmer equipment form
   */
  private get newFarmerEquipmentForm(): FormGroup {

    return this.fb.group({
      equipmentName: ['', Validators.required],
      equipmentCapacity: ['', Validators.required]
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Saves or updates farmer equipment
   */
  saveOrUpdateEquipment() {

    this.farmerEquipmentDetails ? this.updateEquipment(this.farmerEquipmentDetails.equipmentId) : this.addEquipment();
  }

  /**
   * Saves farmer equipment
   */
  addEquipment() {
    const payload = this.preparePayload(this.farmerEquipmentForm.value);

    this.notification.showSpinner();
    this.kalgudiFarmerProfileService.saveNewEquipment(payload)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.notification.hideSpinner();
        })
      )
      .subscribe(
        res => {
          this.notification.showMessage('Equipment added');
          this.closeDialog.emit();
        },
        err => {
          this.util.apiErrorHandler(err);
        }
      )
  }

  /**
   * Updates farmer equipment
   */
  updateEquipment(equipmentId: string) {
    const payload = this.preparePayload(this.farmerEquipmentForm.value);

    this.notification.showSpinner();
    this.kalgudiFarmerProfileService.updateEquipment(equipmentId, payload)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.notification.hideSpinner();
        })
      )
      .subscribe(
        res => {
          this.notification.showMessage('Equipment updated');
          this.closeDialog.emit();
        },
        err => {
          this.util.apiErrorHandler(err);
        }
      )
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
