import { Component, Injector, OnInit } from '@angular/core';

import { ANALYTICS_CHART_GRADIENT_COLORS, surveyQuestionTypeLabelMap } from '../../../../constants';
import { KalgudiSurveyAnalytics } from '../../classes/kalgudi-survey-analytics';

@Component({
  selector: 'kl-survey-analytics-chart',
  templateUrl: './survey-analytics-chart.component.html',
  styleUrls: ['./survey-analytics-chart.component.scss']
})
export class SurveyAnalyticsChartComponent extends KalgudiSurveyAnalytics implements OnInit {

  view: any[] = [200, 200];

  // options
  gradient = true;
  showLegend = true;
  showLabels = false;
  isDoughnut = true;
  legendPosition = 'below';

  colorScheme = {
    domain: ANALYTICS_CHART_GRADIENT_COLORS,
  };

  readonly questionTitleMap = surveyQuestionTypeLabelMap;

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
    this.fetchSurveyAnalytics();

  }

  onDestroyed(): void {}
}
