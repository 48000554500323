import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { UIMenu } from '@kalgudi/types';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PageFilter, PageFilterTypes } from '../../../constants';
import { PageListStateService } from '../services/page-list-state.service';

@Component({
  selector: 'kl-program-list-header',
  templateUrl: './program-list-header.component.html',
  styleUrls: ['./program-list-header.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramListHeaderComponent implements OnInit {

  @Output()
  programsOption = new EventEmitter<any>();

  @Input()
  programMenu: UIMenu;

  @Input()
  pageRelationAdminCount: number;

  @Input()
  pageRelationNotAdminCount: number;

  @Input()
  pageTypes: UIMenu[];

  @Input()
  selectedView: string;

  searchForm: FormGroup;

  selectedTabId: string;
  title: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProgramsRouteConfig,
    private pageListStateService: PageListStateService,
  ) {
    this.searchForm = new FormGroup({
      searchKeyword: new FormControl('')
    });
  }

  ngOnInit() {
    this.selectedTabId = '';
    // this.selectedView = this.programMenu[0].id;
  }

  openCreatePage(): void {
    this.appRouting.toNewProgram();
  }

  selectTab(tabId: string): void {
    this.selectedTabId = tabId;

    const filter: PageFilter = {
      type: PageFilterTypes.MEMBER_ROLE,
      value: tabId
    };

    this.pageListStateService.updatePageType(filter);
  }

  onPageTypeSelection(tabId: string): void {

    this.selectedTabId = tabId;

    this.title = tabId;

    const filter: PageFilter = {
      type: PageFilterTypes.PAGE_TYPE,
      value: tabId
    };

    this.pageListStateService.updatePageType(filter);
  }

  showProgramsList(menu) {

    this.selectedView = menu.id;
    this.programsOption.emit(menu);
  }
}
