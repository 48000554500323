import { Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { KALGUDI_HOME_STREAM_EVENTS } from '../constants';
import { SocialDataNormalizerService } from './social-data-normalizer.service';

/**
 * Handles fetching of stream items from S3 and mapping data after fetching of stream.
 * Many of the inbox stream items are fetched from S3. It service comes handy while fetching
 * stream items from the S3 and inject some extra fields to the stream items.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiStreamS3FetchService {

  constructor(
    private util: KalgudiUtilityService,
    private socialDataNormalizer: SocialDataNormalizerService,
  ) { }

  /**
   * Fetches the item details from the S3 and injects to the notification
   * stream.
   */
  fetchStreamItem<T>(item: T, s3Url: string, eventType: string): Observable<T> {

    return this.util.fetchJsonFromUrl<T>(s3Url)
      .pipe(

        map(r => {
          return {
            // Map details from the existing stream item object
            ...item,

            // Map details from latest s3 stream item object
            // This will override any details that stream item object
            // contains with the same field name
            ...r,
          };
        }),

        // Map post author details
        map(r => this.normalizeHomeStreamDetails(r, eventType)),

        // Return the existing stream item on failed S3 fetch
        catchError(err => {
          console.warn('Error while fetching stream item ', err);
          return of(item);
        })
      );
  }

  /**
   * Adds and merges fields of home stream that are required home stream.
   */
  private normalizeHomeStreamDetails(item: any, event: string): any {

    if (event === KALGUDI_HOME_STREAM_EVENTS.SHARE_UPDATE || event === KALGUDI_HOME_STREAM_EVENTS.TRAINING) {

      if (item.entityId) {
        this.socialDataNormalizer.mapPageShareUpdateQaAuthorDetails(item);
      }

      this.socialDataNormalizer.mapShareUpdateQaAuthorDetails(item);
      this.socialDataNormalizer.mapShareUpdateLiked(item);

    } else if ( event === KALGUDI_HOME_STREAM_EVENTS.QUESTION ) {
      this.socialDataNormalizer.mapQaAuthorDetails(item);

    } else if (event === KALGUDI_HOME_STREAM_EVENTS.PROFILE_VISITED || event === KALGUDI_HOME_STREAM_EVENTS.CONNECT_REQUEST_ACCEPTED) {
      this.socialDataNormalizer.mapNetworkAuthorDetails(item);
    }

    return item;
  }
}
