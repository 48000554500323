import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult, KalgudiProject } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-creation-dialog',
  templateUrl: './mobile-creation-dialog-component.html',
  styleUrls: ['./mobile-creation-dialog-component.scss']
})
export class MobileCreationDialogComponent extends KalgudiMobileDialog implements OnInit {

  // categoryTiles: SelectableUIMenu[] = PROJECT_TEMPLATE_TYPE;

  projectDetails: KalgudiProject;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,

  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * Event handler called upon successful project creation.
   * Closes the dialog with the project details
   */
  onProjectCreated(project: KalgudiProject): void {

    this.projectDetails = project;

    this.ok();
  }


  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: this.projectDetails,
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
