<div class="share-tab">
  <mat-card id="app-share-tab" class="p-0" [@fadeIn] fxHide.xs>
    <mat-tab-group mat-stretch-tabs color="accent" animationDuration="0" [(selectedIndex)]="activeTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="mr-1 update-icon">{{ TABS.UPDATES.icon }}</mat-icon>
          <span class="title" translate>{{ TABS.UPDATES.title }}</span>
        </ng-template>
        <kl-share-update-form *ngIf="activeTabIndex === TABS.UPDATES.index" (postCreated)="onPostCreated($event);">
        </kl-share-update-form>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="mr-1 qa-icon">{{ TABS.QA.icon }}</mat-icon>
          <span class="title" translate>{{ TABS.QA.title }}</span>
        </ng-template>

        <kl-qa-form *ngIf="activeTabIndex === TABS.QA.index" (postCreated)="onQaCreated($event)">
        </kl-qa-form>
      </mat-tab>
    </mat-tab-group>
  </mat-card>

  <!-- <div>
    <kl-float-button (clicked)="openBottomSheet()"></kl-float-button>
  </div> -->

</div>
