import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { kalgudiGoogleLanguageTranslatorConfig, TRANSLATION_CONFIG } from './config';
import { GoogleTranslateDirective } from './google-translate.directive';
import { GoogleTranslateComponent } from './google-translate/google-translate.component';
import { KalgudiGoogleLanguageTranslationService } from './kalgudi-google-language-translation.service';


@NgModule({
  declarations: [
    GoogleTranslateDirective,
    GoogleTranslateComponent,
  ],
  imports: [
    CommonModule,

    TranslateModule,
    FlexLayoutModule,
  ],
  exports: [
    GoogleTranslateDirective,
    GoogleTranslateComponent,
  ],
  entryComponents: [
    GoogleTranslateComponent,
  ],
  providers: [
    KalgudiGoogleLanguageTranslationService,
  ]
})
export class KalgudiGoogleLanguageTranslatorModule {

  public static forRoot(config: kalgudiGoogleLanguageTranslatorConfig): ModuleWithProviders<KalgudiGoogleLanguageTranslatorModule> {

    return {
      ngModule: KalgudiGoogleLanguageTranslatorModule,
      providers: [
        {
          provide: TRANSLATION_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
