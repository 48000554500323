import { Pipe, PipeTransform } from '@angular/core';

/**
 * Checks if an object has any property or not. Calling this pipe on
 * an object returns `true` if the object has at least single property on it.
 * Otherwise returns `false`.
 *
 * @author Pankaj Prakash
 * @example
 * <p *ngIf="!(myObject | isEmpty)">p?.someValue</p>
 */
@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: object, ...args: any[]): boolean {
    return Object.entries(value).length === 0 && value.constructor === Object;
  }

}
