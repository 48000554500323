import { Injector, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { CreditService } from '../services/credit.service';


@Directive()
export abstract class KalgudiTransferCredits extends KalgudiDestroyable {

  creditTransferForm: FormGroup;

  progress: boolean;

  private fb: FormBuilder;
  private appService: KalgudiAppService;
  private notifications: KalgudiNotification;
  private creditService: CreditService;

  constructor(
    protected injector: Injector
  ) {

    super();
    // Manually inject dependencies
    this.fb            = this.injector.get(FormBuilder);
    this.appService    = this.injector.get(KalgudiAppService);
    this.notifications = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.creditService = this.injector.get(CreditService);

    this.creditTransferForm = this.transferCreditsForm;
    this.patchLoggedInUserDetails();

  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * service call to transfer credits
   */
  transferCredits() {
    this.progress = true;
    const payload = this.preparePayload();
    // this.creditService.transferCredits(payload)
    //   .subscribe(
    //     res => this.onTransferCreditSuccess(res),
    //     err => {
    //       this.notifications.showMessage('Unable to transfer credits, please try again later.');
    //     }
    // );
  }


  /**
   * Resets the transfer credits form.
   */
  resetForm(): void {
    this.creditTransferForm.reset();
  }

  /**
   * Get loggedinuser profile key
   */
  get profileKey() {
    return this.appService.profileLocal.profileKey;
  }

  /**
   * Get loggedinuser profile key
   */
  get loggedInUserName() {
    return this.appService.profileLocal.firstName;
  }

  /**
   * get transfer credit form value
   */
  get creditFormDetails() {
    return this.creditTransferForm.value;
  }

  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * from group for transfer credits
   */
  private get transferCreditsForm(): FormGroup {
    return this.fb.group({
      creditFromField: ['', Validators.required],
      creditToField: ['', Validators.required],
      credit: ['', Validators.required]
    });
  }

  /**
   * Patch form value
   */
  private patchLoggedInUserDetails() {
    const profile = this.appService.profileLocal;
    this.creditTransferForm.get('creditFromField').patchValue(profile.firstName);
  }

  /**
   * Payload for transfer credits
   */
  private preparePayload() {
    const payload = {
      // userId: this.creditFormDetails.creditFromField,
      // pageId: ,
      // credit: ,
      // remark:
    };

    return payload;
  }

  /**
   * Event handler on success of transfer credits
   */
  private onTransferCreditSuccess(res) {
    this.resetForm();
    this.progress = false;
    this.notifications.showMessage('Credits added successfully.');
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
