import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiSurveyActivityLogResponse,
  ApiSurveyActivityLogResponseData,
  KalgudiSurveyAnalyticsResponse,
  KalgudiSurveyDetails,
  SurveyBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiSurveyApiService {


  /**
   * `v2/social/surveys/`
   */
  private readonly API_SURVEY_BASE = `${this.env.restBaseUrlV2}/social/surveys`;

  /**
   * `v2/surveys/:surveyId`
   */
  private readonly API_SURVEY_VIEW = `${this.API_SURVEY_BASE}/:surveyId`;

  /**
   * `v2/surveys/:surveyId/publish`
   */
  private readonly API_SURVEY_PUBLISH = `${this.API_SURVEY_BASE}/:surveyId/publish`;

  /**
   * `v2/surveys/:surveyId/unpublish`
   */
  private readonly API_SURVEY_UNPUBLISH = `${this.API_SURVEY_BASE}/:surveyId/unpublish`;

  /**
   * `v2/surveys/:surveyId/reminder`
   */
  private readonly API_SURVEY_REMINDER = `${this.API_SURVEY_BASE}/:surveyId/reminder`;

  /**
   * `v2/surveys/:surveyId/logs`
   */
  private readonly API_SURVEY_LOGS = `${this.API_SURVEY_BASE}/:surveyId/logs`;

  /**
   * `v2/social/surveys/:surveyId/analytics`
   */
  private readonly API_SURVEY_ANALYTICS = `${this.API_SURVEY_BASE}/:surveyId/analytics`;

  /**
   * `v2/social/surveys/:surveyId/analytics/download`
   */
  private readonly API_SURVEY_ANALYTICS_DOWNLOAD = `${this.API_SURVEY_ANALYTICS}/download`;

  constructor(
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) { }


  /**
   * Calls Kalgudi api to get survey details
   *
   * @param surveyId Unique id of survey
   */
  getSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {


    const url = this.API_SURVEY_VIEW.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data.survey),
      );
  }

  /**
   * Api definition for survey details updation.
   */
  updateSurveyDetails(surveyId: string, surveyDetails: SurveyBasicDetails): Observable<KalgudiSurveyDetails> {

    const url = this.API_SURVEY_VIEW.replace(':surveyId', surveyId);

    return this.httpClient.put<ApiResponseCommon>(url, surveyDetails)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response to Kalgudi survey details
        map(res => res.data)
      );
  }

  /**
   * Api definition for delete survey.
   */
  deleteSurvey(surveyId: string): Observable<boolean> {

    const url = this.API_SURVEY_VIEW.replace(':surveyId', surveyId);

    return this.httpClient.delete<ApiResponseCommon>(url)
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Gets, the survey activity logs
   */
  getActivityLogs(surveyId: string, offset: number = 0, limit: number = 20): Observable<ApiSurveyActivityLogResponseData> {

    const url = this.API_SURVEY_LOGS.replace(':surveyId', surveyId);

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient.get<ApiSurveyActivityLogResponse>(url, { params })
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Calls the API to publish a survey
   */
  publishSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {

    const url = this.API_SURVEY_PUBLISH.replace(':surveyId', surveyId);

    return this.httpClient.put<ApiResponseCommon>(url, {})
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Calls the API to unpublish a survey
   */
  unPublishSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {

    const url = this.API_SURVEY_UNPUBLISH.replace(':surveyId', surveyId);

    return this.httpClient.put<ApiResponseCommon>(url, {})
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Calls the API to send reminder to the user
   */
  sendReminder(surveyId: string): Observable<KalgudiSurveyDetails> {

    const url = this.API_SURVEY_REMINDER.replace(':surveyId', surveyId);

    return this.httpClient.put<ApiResponseCommon>(url, {})
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Api definition to fetch survey analytics.
   *
   * @param surveyId
   */
  fetchSurveyAnalytics(surveyId: string): Observable<KalgudiSurveyAnalyticsResponse> {

    const url = this.API_SURVEY_ANALYTICS.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(
        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to fetch Kalgudi survey analytics
        map(res => res.data)
      );
  }

  /**
   * Api definition for send survey analytics.
   *
   * @param surveyId
   * @param email
   */
  sendSurveyAnalytics(surveyId: string, email: string): Observable<KalgudiSurveyAnalyticsResponse> {

    const params = {
      email
    };

    const url = this.API_SURVEY_ANALYTICS_DOWNLOAD.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(
        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi send survey analytics
        map(res => res.data)
      );
  }
}
