import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AvailableStoreAppNames, AvailableStoreTypes, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PromotionalShareUpdateApiService {

  /**
   * `/v2/search/farmstore/products/autocomplete`
   */
  private readonly API_PRODUCTS_AUTOCOMPLETE = `${this.environment.restBaseUrlV2}/search/:storeType/products/autocomplete`;

  /**
   * `v2/listing/products/l2/:level2Id`
   */
  private readonly API_PRODUCT_LEVEL2 = `${this.environment.restBaseUrlV2}/listing/products/l2/:level2Id`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Gets the product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    storeType: string,
    offset: number,
    limit: number,
    filters: PartialData = {}
  ): Observable<any[]> {

    let params: any = {
      keyword,
      appName: AvailableStoreAppNames[storeType],
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters
    };

    if (params.appName === 'SAM_CMP') {
      params.store = params.appName
    }

    const url = this.API_PRODUCTS_AUTOCOMPLETE.replace(':storeType', AvailableStoreTypes[storeType].toLowerCase().replace(/_/g, ''));

    return this.httpClient.get<any>(url, { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data),
      );
  }

  /**
   * Calls api to fetch product level2 details
   */
  getProductLevel2(
    level2Id: string,
    storeType: string,
    offset: number,
    limit: number,
    filters: PartialData = {}
  ): Observable<any[]> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      storeType: AvailableStoreTypes[storeType],
      appName: AvailableStoreAppNames[storeType],
      languageId: '1',
      ...filters
    };

    return this.httpClient.get<any>(this.API_PRODUCT_LEVEL2.replace(':level2Id', level2Id), { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data.data),
      );
  }

}
