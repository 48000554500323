import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-about-org-view',
  templateUrl: './profile-about-org-view.component.html',
  styleUrls: ['./profile-about-org-view.component.scss']
})
export class ProfileAboutOrgViewComponent implements OnInit {

  @Input()
  user: KalgudiUser;

  @Input()
  editable: boolean;
  appId: string;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private appService: KalgudiAppService) {
    this.appId = this.env.appId;
  }

  ngOnInit() {
  }

  /**
   * Get loggedinuser profile key
   */
  get loggedInUser() {
    return this.appService.profileLocal.profileKey;
  }

}
