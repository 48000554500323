<div id="recent-projects" *ngIf="paginator$ | async as paginator">

  <div class="mt-4" fxLayout fxLayoutAlign="space-between center">
    <h3 class="title mb-0 text-dark" translate>Recent projects</h3>
  </div>

  <div *ngIf="(stream$ | async) as stream">
    <kl-projects-list [projectsList]="stream"></kl-projects-list>

    <!-- No content -->
    <div class="mt-5" *ngIf="!stream || stream?.length <= 0">
      <kl-no-content
        faIcon="fa fa-frown"
        text="No recent projects"
        iconSize="sm"
        progressText="Loading recent projects..."
        [progress]="paginator?.loading">
      </kl-no-content>
    </div>
    <!-- / No content -->
  </div>
</div>
