import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { KalgudiCreateProcessing } from '../../classes/kalgudi-create-processing-page';

@Component({
  selector: 'kl-processing-page-creation',
  templateUrl: './processing-page-creation.component.html',
  styleUrls: ['./processing-page-creation.component.scss']
})
export class ProcessingPageCreationComponent extends KalgudiCreateProcessing implements OnInit {

  constructor(protected injector: Injector, protected fb: FormBuilder) {

    super(injector, fb);
  }

  ngOnInit() {

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }


}
