import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails, StoreProductLevel2, StoreProductLevel3 } from '@kalgudi/types';

import { KalgudiCommonRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  @Input()
  level3ProductDetails: StoreProductLevel3;

  @Input()
  productLevel2Details: StoreProductLevel2;

  @Input()
  sellerDetails: KalgudiUserBasicDetails;

  @Input()
  sellerProfileInfo: KalgudiUserBasicDetails;

  @Output()
  toggleSideNav = new EventEmitter<string>();

  constructor(
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiCommonRouteConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to open sidenav
   */
  openSideNav(type: string) {
    this.toggleSideNav.emit(type);
  }

  /**
   * Open store product full view
   */
  openStore(productDetails: any): void {

    const product = {
      productName_level2: productDetails.productLevel2Title,
      productId_level2: productDetails.productLevel2Id,
      productId_level3: this.level3ProductDetails.productLevel3Id
    }

    this.appRouting.toStoreProductView({store: productDetails.stores[0], product: product});
  }

}
