import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiDialog } from '../../../kalgudi-dialogs/classes/kalgudi-dialog';

@Component({
  selector: 'kl-geo-location-marker-dialog',
  templateUrl: './geo-location-marker-dialog.component.html',
  styleUrls: ['./geo-location-marker-dialog.component.scss']
})
export class GeoLocationMarkerDialogComponent extends KalgudiDialog<GeoLocationMarkerDialogComponent> implements OnInit {

  projectId: string;

  constructor(
    protected dialogRef: MatDialogRef<GeoLocationMarkerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
