import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { ACTIVITY_TYPES, KalgudiAppService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialRouteConfig } from '@kalgudi/social';
import { BottomSheetMenu, KalgudiUser, SocialPostLatestActivity, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-promotional-share-header',
  templateUrl: './promotional-share-header.component.html',
  styleUrls: ['./promotional-share-header.component.scss']
})
export class PromotionalShareHeaderComponent implements OnInit,OnChanges {

  @Input()
  promotionalTitle: string;

  @Input()
  promotionalTime: string;

  @Input()
  promotionalLocation: string;

  @Input()
  pageDetails;

  @Input()
  scheduledUpdates: boolean;

  @Input()
  latestActivity: SocialPostLatestActivity;

  @Input()
  userProfileKey: string;

  @Input()
  memberRole: string;

  @Input()
  authorId: string;

  @Output()
  deletePost = new EventEmitter<boolean>();

  @Output()
  report = new EventEmitter<boolean>();

  listMenu: BottomSheetMenu;
  activityTypes = ACTIVITY_TYPES;
  userDetails: KalgudiUser;
  isLoggedIn: boolean;


  constructor(
    private app: KalgudiAppService,
    private bottomSheetService: KalgudiBottomSheetService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    private location: LocationStrategy,
    private route: Router
  ) {

    this.userDetails = this.app.profileLocal;
    this.isLoggedIn = this.app.loggedIn;
    // this.bottomSheetService.disableBackButton();
  }

  ngOnInit() {

  }


  ngOnChanges() {
    this.listMenu = {
      title: '',
      styleType: 'list',
      showCloseButton : true,
      menu: [
        {
          title: 'Report abuse',
          id: 'REPORT_ABUSE',
          hide: this.scheduledUpdates,
        },
        {
          title: 'Delete',
          id: 'DELETE',
          hide: this.isLoggedIn ? (this.authorId !== this.userDetails.profileKey ) || this.memberRole === 'ADMIN' : false,
        }
      ]
    };
  }

  /**
   * Emits an event to delete the update
   */
  deleteUpdate(): void {
    this.deletePost.emit();
  }

  /**
   * Emits an event to report on the post
   */
  reportAbuse(): void {
    this.report.emit();
  }

  /**
   * Navigates to profile page
   * @param profileKey
   */
  viewProfile(profileKey: string): void {
    this.appRouting.toProfile({ profileKey });
  }

  openBottomSheet(): void {

    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'REPORT_ABUSE':
        this.reportAbuse();
        break;

      case 'DELETE':
        this.deleteUpdate();
        break;
    }
  }

}
