<div id="survey-members-search-dialog" fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden kl-surveys-members-search-dialog mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{dialogData?.title | translate}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content" fxLayout="column" fxLayoutAlign="space-between stretch">
    <main>
      <kl-surveys-members-search [(selectedUsersMap)]="selectedUsersMap">
      </kl-surveys-members-search>
    </main>

    <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <kl-link-button [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
      <kl-link-button [label]="dialogData?.acceptButtonTitle" buttonColor="primary" (clicked)="ok()"></kl-link-button>
    </div>
  </div>

</div>
