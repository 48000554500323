import { Component, OnInit, Injector, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KalgudiDestroyable, KalgudiFormValidators, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize, takeUntil } from 'rxjs/operators';
import { KalgudiFarmerProfileService } from '../../services/kalgudi-farmer-profile.service';

@Component({
  selector: 'kl-land-data-download-form',
  templateUrl: './land-data-download-form.component.html',
  styleUrls: ['./land-data-download-form.component.scss']
})
export class LandDataDownloadFormComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  landId: string;

  @Input()
  profileKey: string;

  emailId = new FormControl('', [...KalgudiFormValidators.emailValidators]);
  email: string;
  progress: boolean;

  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;
  private notification: KalgudiNotification;
  protected util: KalgudiUtilityService;

  constructor(protected injector: Injector) {
    super();
    this.kalgudiFarmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.notification                = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util                        = this.injector.get(KalgudiUtilityService);
  }

  ngOnInit() {
  }

  /**
   * To get land data after click on download button
   */
  getLandData() {
    this.email = this.emailId.value;
    const payload = {
      email: this.email
    }
    this.progress = true;
    this.kalgudiFarmerProfileService.getLandData(payload, this.profileKey, this.landId)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.notification.showMessage(res.data);
        },
        err => this.notification.showMessage('Something went wrong, please try again later!')
      )
  }

  onDestroyed(): void {}

}
