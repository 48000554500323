import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@app/env';

import { HomeStreamComponent } from './home-stream/home-stream.component';
import { HomeComponent } from './home.component';
import { ShareUpdatePageComponent } from './share-update-page/share-update-page.component';



const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'update',
        component: ShareUpdatePageComponent,
      },
      {
        path: '',
        data: {
          title: environment.appConfig.title
        },
        component: HomeStreamComponent,
      },
      {
        path: '**',
        redirectTo: '',
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
