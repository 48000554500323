import { Inject, Injectable } from '@angular/core';
import { KalgudiLazyLoaderService } from '@kalgudi/third-party/lazy-loader';
import { Observable } from 'rxjs';

import { GOOGLE_API_KEY } from '../config/index';

@Injectable()
export class ApiLoaderService {

  private API = 'https://maps.googleapis.com/maps/api/js?libraries=places,drawing,geometry&key=';

  constructor(
    @Inject(GOOGLE_API_KEY) private apiKey: string,
    private lazyLoaderService: KalgudiLazyLoaderService,
  ) {

    // Append api key to the api url
    this.API += this.apiKey;
  }

  loadPlacesApi(): Observable<any> {
    return this.lazyLoaderService.loadScript(this.API, true);
  }
}
