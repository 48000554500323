import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import {
  checkMobileDevice,
  kalgudiAnimations,
  KalgudiAppService,
  KalgudiUsersService,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { AssistedShareRequest, FileMimeTypes, ShareUpdate } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { AssistedShareUpdate } from '../../classes/assisted-share-update';

@Component({
  selector: 'kl-digital-assist-share-update',
  templateUrl: './digital-assist-share-update.component.html',
  styleUrls: [
    '../../../../../../../share-update/src/lib/components/share-update-form/share-update-form.component.scss',
    './digital-assist-share-update.component.scss'
  ],
  animations: kalgudiAnimations,
})
export class DigitalAssistShareUpdateComponent extends AssistedShareUpdate implements OnInit {


  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];

  @Output()
  postCreated = new EventEmitter<any>();

  rowsCount: number;
  isMobileDevice: boolean;
  showAdvanced: boolean;
  showScheduleTime: boolean = false;

  public env: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected sharePostService: KalgudiShareUpdateService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected mobileMenuService: KalgudiBottomSheetService,
    protected kalgudiPageService: KalgudiPageService,
    protected kalgudiUsersService: KalgudiUsersService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {


    // Initialize parent
    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      mobileMenuService,
      kalgudiPageService,
      kalgudiUsersService,
    );

    this.env = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
  }

  ngOnInit() {

    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 10 : 3;

    this.updateDateLocale();
  }

  toggleAdvanced() {
    this.showAdvanced = true;
  }

  protected onDestroyed(): void { }

  /**
   * Event handler called after the post is shared successfully.
   */
  protected onPostCreated(payload: AssistedShareRequest, response: ShareUpdate): void {
    this.postCreated.emit(response);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  /**
   * Toggle the schedule date & time
   */
  toggleSchedule() {
    this.showScheduleTime = !this.showScheduleTime;
    this.clearDate();
  }

}
