import { KalgudiDestroyable } from '@kalgudi/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Directive } from "@angular/core";


@Directive()
export abstract class KalgudiStepper<State, StateDataMap> extends KalgudiDestroyable {


  // --------------------------------------------------------
  // #region Instance members
  // --------------------------------------------------------

  /**
   * Current page creation state.
   */
  get creationState$(): Observable<State> {
    return this.creationStateSubject.asObservable()
      .pipe(

        // Share the same state with all subscribers
        share()
      );
  }

  /**
   * Current active state
   */
  get currentState(): State {
    return this.creationStateSubject.getValue();
  }

  /**
   * Stream of all page creation stages data
   */
  get creationStateData$(): Observable<StateDataMap> {
    return this.creationStateDataSubject.asObservable()
      .pipe(

        share()
      );
  }

  /**
   * Gets data for each page creation state.
   */
  get creationStateData(): StateDataMap {
    return this.creationStateDataSubject.getValue();
  }

  /**
   * Page creation steps order
   */
  protected abstract CREATION_STATE_ORDER: State[];

  /**
   * Default page creation state data
   */
  protected abstract DEFAULT_CREATION_STATE_DATA: StateDataMap;


  /**
   * Stream to define current active state
   */
  private creationStateSubject: BehaviorSubject<State>; // = new BehaviorSubject<State>('CHOOSE_PAGE_TYPE');

  /**
   * Holds the data for each state actions.
   */
  private creationStateDataSubject: BehaviorSubject<StateDataMap>;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  constructor() {

    super();
  }



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Moves the current page creation state to the next state.
   * Perform no action if its the last stage of the page creation.
   *
   * @param currentStateData Data specific to current state
   */
  nextState(currentStateData?: any): void {

    // No action needed if its last stage
    if (this.isFinalState()) {
      return;
    }

    // Get the next state and update the state
    const newState = this.getNextState();

    // Update current state data in the `creationStateDataSubject`
    this.updateCurrentStateData(currentStateData);

    // Update the state in the `creationStateSubject`
    this.updateState(newState);
  }

  /**
   * Moves the current page creation stage to the previous state.
   * Perform no action if its the first stage of the page creation.
   */
  previousState(): void {

    // No action needed if its initial stage
    if (this.isInitialState()) {
      return;
    }

    // Get the previous state and update the state
    const previousState = this.getPreviousState();

    // Update the state in the `creationStateSubject`
    this.updateState(previousState);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * Pushes the latest state to the `creationStateSubject`.
   */
  protected updateState(state: State): void {
    this.creationStateSubject.next(state);
  }

  protected initState(initialState: State, data: any): void {
    this.creationStateSubject = new BehaviorSubject<State>(initialState);
    this.creationStateDataSubject = new BehaviorSubject<StateDataMap>(data);
    // this.creationStateDataSubject.next(data);
  }


  /**
   * Updates the current state data into the stream.
   */
  protected updateCurrentStateData(data?: any): void {


    // Get all states data
    const creationStateData = this.creationStateData;

    // Update the current state data
    creationStateData[this.currentState as any].data = data;

    // Push the latest state data to the stream
    this.creationStateDataSubject.next(creationStateData);
  }

  /**
   * Returns `true` if the current active state is the initial/first state.
   * Otherwise returns `false`.
   */
  private isInitialState(): boolean {
    return (this.getCurrentStateIndex() === 0);
  }

  /**
   * Returns `true` if the current active state is the final state of the
   * page creation order. Otherwise returns `false`.
   */
  private isFinalState(): boolean {
    return (this.getCurrentStateIndex() === (this.CREATION_STATE_ORDER.length - 1));
  }

  /**
   * Gets, the index of the current active page creation state
   */
  private getCurrentStateIndex(): number {
    return this.getStateIndex(this.currentState);
  }

  /**
   * Gets, the order of page creation state from the orders list.
   */
  private getStateIndex(state: State): number {
    return this.CREATION_STATE_ORDER.indexOf(state);
  }

  /**
   * Gets, the next state based on the current active state.
   * Returns the next state in the page creation order if current
   * state is not the final state.
   *
   * Return the same state if the current state is the final state in the
   * page creation state order.
   */
  private getNextState(): State {

    return this.isFinalState()
      ? this.currentState
      : this.CREATION_STATE_ORDER[this.getCurrentStateIndex() + 1];
  }

  /**
   * Gets, the previous state based on the current active state.
   *
   * Returns the previous state in the page creation order if the previous
   * state is not the initial state.
   *
   * Return the same state if the current state is the initial state in the page
   * creation state order.
   */
  private getPreviousState(): State {

    return this.isInitialState()
      ? this.currentState
      : this.CREATION_STATE_ORDER[this.getCurrentStateIndex() - 1];
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
