import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageAddNewUsers } from '../../classes/kalgudi-page-add-new-users';

@Component({
  selector: 'kl-program-add-new-user',
  templateUrl: './program-add-new-user.component.html',
  styleUrls: ['./program-add-new-user.component.scss']
})
export class ProgramAddNewUserComponent extends KalgudiPageAddNewUsers implements OnInit {
  readonly genderList: any = [
    { id: 'MALE', value: 'Male'},
    { id: 'FEMALE', value: 'Female'}
  ];

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
    this.userType.patchValue('email');
  }

  onDestroyed(): void {}
}
