import { Component, Input, OnInit } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';

@Component({
  selector: 'kl-profile-logs-view',
  templateUrl: './profile-logs-view.component.html',
  styleUrls: ['./profile-logs-view.component.scss']
})
export class ProfileLogsViewComponent implements OnInit {

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  isMobileDevice: boolean;

  constructor() {
    this.isMobileDevice = checkMobileDevice()? true : false;;
  }

  ngOnInit() {
  }

}
