import { Injectable } from '@angular/core';
import { ProgramQaFullDetails } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiQaStreamStateService {

  private newStreamItemSubject = new Subject<ProgramQaFullDetails>();

  constructor() { }

  get newStreamItem$(): Observable<ProgramQaFullDetails> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: ProgramQaFullDetails): void {
    this.newStreamItemSubject.next(item);
  }
}
