import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiLivestock } from '../../classes/kalgudi-livestock';

@Component({
  selector: 'kl-add-live-stock-page',
  templateUrl: './add-live-stock-page.component.html',
  styleUrls: ['./add-live-stock-page.component.scss']
})
export class AddLiveStockPageComponent extends KalgudiLivestock implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.getLiveStock();
  }

  onDestroyed(): void {}

}
