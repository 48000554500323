import { Injectable } from '@angular/core';
import { KalgudiStepper } from '@kalgudi/common';

import { ProjectCreationStateDataMap, ProjectCreationStates } from '../../../models';

@Injectable()
export class CreateProjectStateService extends KalgudiStepper<ProjectCreationStates, ProjectCreationStateDataMap> {


  /**
   * Default page creation state data
   */
  protected readonly DEFAULT_CREATION_STATE_DATA: ProjectCreationStateDataMap = {
    CHOOSE_PROJECT_CATEGORY: { data: ''},
    FILL_COMMON_PROJECT_DETAILS: { data: ''},
    FILL_SPECIFIC_PROJECT_DETAILS: { data: ''},
    CREATE_PROJECT: { data: ''},
    PROJECT_CREATED: { data: ''},
  };

  /**
   * Page creation steps order
   */
  protected readonly CREATION_STATE_ORDER: ProjectCreationStates[] = [
    'CHOOSE_PROJECT_CATEGORY',
    'FILL_COMMON_PROJECT_DETAILS',
    'FILL_SPECIFIC_PROJECT_DETAILS',
    'CREATE_PROJECT',
    'PROJECT_CREATED'
  ];


  constructor() {

    super();

    this.initState(this.CREATION_STATE_ORDER[0], this.DEFAULT_CREATION_STATE_DATA);
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }


}
