import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';

import { AdvanceSearchComponent } from './components/advance-search/advance-search.component';
import { SearchComponent } from './search.component';


@NgModule({
  declarations: [
    AdvanceSearchComponent,
    SearchComponent
  ],
  imports: [
    CommonModule,

    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,

    FlexLayoutModule,

    // Angular Form
    ReactiveFormsModule,

    //Kalgudi lib
    KalgudiButtonModule,

    TranslateModule,
  ],
  exports: [
    SearchComponent,
    AdvanceSearchComponent
  ]
})
export class CommonSearchBarModule { }
