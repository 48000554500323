<main id="farming-activity-add-season" class="mt-2">
  <form autocomplete="off" fxLayout.gt-xs="column" [formGroup]="farmingActivityAddSeasonForm">

    <p class="labels mb-0" translate>Season details</p>

    <div fxFlex.gt-xs="50%">
      <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
        <mat-label translate>Season title</mat-label>
        <input matInput [placeholder]="'Enter season title' | translate" formControlName="title" required>
        <span matSuffix class="position-relative info-icon">
          <span class="cursor-pointer suffix-icon rounded-circle">
            i
          </span>
          <span class="hide d-none position-absolute bg-white p-2 text-black-50 rounded" translate>
            <strong>Eg: </strong> 2020 - Karif
          </span>
        </span>
      </mat-form-field>
    </div>

    <!-- Season time frame section -->
    <section class="from-to-dates dotted-border-bottom" formGroupName="seasonTimeFrame">
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center center">
        <div fxFlex="calc(50% - 12px)">
          <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
            <mat-label translate>From</mat-label>
            <input matInput formControlName="from" [matDatepicker]="from" (click)="from.open()" readonly required>
            <mat-datepicker-toggle matSuffix [for]="from">
              <div  matDatepickerToggleIcon>

                <svg-icon class="text-center date-icon"
                  [applyCss]="true"
                  src="assets/svgs/farmer-profile/calendar.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20}">
                </svg-icon>
              </div>
            </mat-datepicker-toggle>
            <!-- <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle> -->
            <mat-datepicker #from></mat-datepicker>
          </mat-form-field>
        </div>

        <span class="h-100 mt-n1" fxLayout fxLayoutAlign="center center">-</span>

        <div fxFlex="calc(50% - 12px)">
          <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
            <mat-label translate>To</mat-label>
            <input formControlName="to" matInput [matDatepicker]="to" (click)="to.open()" readonly required>
            <mat-datepicker-toggle matSuffix [for]="to">
              <div  matDatepickerToggleIcon>

                <svg-icon class="text-center date-icon"
                  [applyCss]="true"
                  src="assets/svgs/farmer-profile/calendar.svg"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20}">
                </svg-icon>
              </div>
            </mat-datepicker-toggle>
            <!-- <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle> -->
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </section>
    <!--/ Season time frame section -->

    <!-- Select variety for the crop season section-->
    <section class="mt-2 dotted-border-bottom">
      <p class="labels mb-2" translate>Select variety for the crop season</p>
      <div class="w-100" fxLayout="row wrap" fxLayoutGap="12px">
        <ng-container *ngFor="let variety of varietyList; let i = index">
          <div class="mb-2 mw-100" *ngIf="variety?.productId !== variety?.baseProductId" (click)="onCropSelection(variety, i)">
            <div fxLayout="row" fxLayoutAlign="start center"
              class="variety-tile  cursor-pointer" [ngClass]="{'selected-tile': variety?.selected}">
              <div fxFlex="98%"><p class="m-0 text-truncate mw-100 mr-2"> {{variety?.productName}}</p></div>
              <div fxFlex="2%" class="ml-n2"><svg-icon src="assets/svgs/completed.svg" class="text-center" [ngClass]="{'not-selected': !variety?.selected}" [applyCss]="true"
                [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon></div>
            </div>
          </div>

          <ng-container *ngIf="(varietyList && varietyList?.length <= 1 && variety?.productId === variety?.baseProductId) || !varietyList || (varietyList && !varietyList?.length)">
            <p class="text-muted text-center w-100 mb-0" translate>No varieties added yet!</p>
          </ng-container>
        </ng-container>
      </div>

      <div fxLayout fxLayoutGap="2px">
        <p class="" translate>Didn't find variety you want? </p>
        <a (click)="editCrop()" class="links" translate> search & add here</a>
      </div>
    </section>
    <!--/ Select variety for the crop season section-->

    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="50%">
        <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
          <mat-label translate>Farmers</mat-label>
          <input matInput [placeholder]="'Enter farmers count' | translate" formControlName="farmers" type="number" [required]="required">
          <!-- <mat-error *ngIf="farmingActivityAddSeasonForm.get('farmers').hasError('pattern')">
            Enter valid farmers count
          </mat-error> -->
          <mat-error translate>Please enter farmers count</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex="calc(50% - 10px)">
        <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
          <mat-label translate>Acres</mat-label>
          <input matInput [placeholder]="'Enter acres' | translate" formControlName="acres" type="number" [required]="required">
          <!-- <mat-error *ngIf="farmingActivityAddSeasonForm.get('acres').hasError('pattern')">
            Enter valid acres
          </mat-error> -->
          <mat-error translate>Please enter acres</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="50%" fxLayout="row" formGroupName="expectedYield">
        <div fxFlex="70%">
          <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
            <mat-label translate>Expected yield</mat-label>
            <input matInput [placeholder]="'Enter expected yield' | translate" formControlName="value" type="number" [required]="required">
            <!-- <mat-error *ngIf="expectedYield.get('value').hasError('pattern')">
              Enter valid expected yield
            </mat-error> -->
            <mat-error translate>Please enter expected yield</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="30%">
          <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
            <mat-label translate>Select unit</mat-label>
            <mat-select appearance="outline" formControlName="unit" [required]="required">
              <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.value}}</mat-option>
            </mat-select>
            <mat-error translate>Please select unit</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex="calc(50% - 10px)" fxLayout="row" formGroupName="actualYield">
        <div fxFlex="70%" >
          <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
            <mat-label translate>Actual yield</mat-label>
            <input matInput [placeholder]="'Enter actual yield' | translate" formControlName="value">
            <mat-error *ngIf="actualYield.get('value').hasError('pattern')">
              Enter valid actual yield
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="30%">
          <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
            <mat-label translate>Select unit</mat-label>
            <mat-select appearance="outline" formControlName="unit">
              <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div>
      <mat-form-field color="accent" class="w-100 info-field mat-form-field-md" appearance="outline">
        <mat-label translate>Harvesting Month</mat-label>
        <input matInput formControlName="harvestingMonth" [matDatepicker]="harvestingMonth" (click)="harvestingMonth.open()" readonly>
        <mat-datepicker-toggle matSuffix [for]="harvestingMonth">
          <div  matDatepickerToggleIcon>

            <svg-icon class="text-center date-icon"
              [applyCss]="true"
              src="assets/svgs/farmer-profile/calendar.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20}">
            </svg-icon>
          </div>
        </mat-datepicker-toggle>
        <!-- <mat-datepicker-toggle matSuffix [for]="harvestingMonth"></mat-datepicker-toggle> -->
        <mat-datepicker #harvestingMonth></mat-datepicker>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="w-100" *ngIf="projectsList?.length > 0">
      <mat-form-field appearance="outline" class="w-100 mat-form-field-md">
        <mat-select placeholder="Select crop calendar" appearance="outline" formControlName="cropCalendarId">
          <mat-option *ngFor="let project of projectsList" [value]="project">{{project?.title}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" class="w-100">
      <div fxLayout fxLayoutGap="2px">
        <p class="" translate>Didn't find crop calendar you want? </p>
        <a (click)="toProjectList()" class="links" translate> Click here to create</a>
      </div>
    </div>

    <!-- Add button -->
    <div class="mt-2 add-btn w-100" fxLayoutAlign="center center">

      <kl-flat-button
        [label]="'Submit'"
        [disabled]="farmingActivityAddSeasonForm.invalid"
        [progress]="progress"
        (clicked)="addSeason()">
      </kl-flat-button>
    </div>
    <!-- / Add button -->
  </form>
</main>
