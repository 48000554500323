<div id="task-members-submission-da">
  <!-- Task list header -->
  <div *ngIf="!hideTaskHeader">
    <kl-task-list-header></kl-task-list-header>
  </div>
  <!--/ Task list header -->

  <div class="mt-2">
    <!-- Task header -->
    <kl-task-header-da [taskDetails]="taskDetails" [isSubmission]="true"></kl-task-header-da>
    <!--/ Task header -->
  </div>

  <!-- Selected members list -->
  <div class="mt-2" *ngIf="members?.length">
    <kl-task-selected-members-list [members]="members" [isSubmission]="true"></kl-task-selected-members-list>
  </div>
  <!--/ Selected members list -->

  <mat-card class="mt-2" *ngIf="selectedProfileKeys?.length">

    <!-- Task status form -->
    <div>
      <h1 class="update-status">Update status</h1>
      <kl-task-status
        [selectedMembersList]="selectedProfileKeys"
        [isBulk]="true">
      </kl-task-status>
    </div>
    <!--/ Task status form -->

  </mat-card>

  <mat-card class="mt-2" *ngIf="selectedProfileKeys?.length">

    <!-- Activity form -->
    <kl-task-fill
      [projectId]="taskDetails?.projectId"
      [taskId]="taskDetails?.taskId"
      [templateId]="taskDetails?.templateId"
      [taskDetails]="taskDetails"
      [submissionDetails]="submissionDetails"
      [selectedMembersList]="selectedProfileKeys"
      [isBulk]="true">
    </kl-task-fill>
    <!--/ Activity form -->

  </mat-card>

</div>
