<ng-container>
  <div class="mt-2 task-update-tile"
    [@kalgudiStreamTile]="tileAnimationState"
    [ngClass]="{'deleted': tileAnimationState === 'deleted'}">
    <mat-card class="task-view-tile cursor-pointer py-1 px-2">

      <!-- Task update tile header -->
      <div>
        <kl-task-update-header></kl-task-update-header>
      </div>
      <!--/ Task update tile header -->

      <!-- Task update details -->
      <div class="my-2">
        <kl-task-details [taskActivityDetails]="taskSubmissionDetails"></kl-task-details>
      </div>
      <!--/ Task update details -->

      <!-- Task status -->
      <div class="my-2" *ngIf="taskSubmissionDetails?.submissionType === 'STATE_UPDATE'">
        <kl-task-status [taskStatus]="taskSubmissionDetails"></kl-task-status>
      </div>
      <!--/ Task status -->

      <!-- Task form updates -->
       <div class="my-2" *ngIf="taskSubmissionDetails?.submissionType === 'FORM_SUBMISSION'">
        <kl-task-form-updates [taskFormUpdates]="taskSubmissionDetails"></kl-task-form-updates>
      </div>
      <!--/ Task form updates -->

      <!-- Task activity updates -->
      <div class="my-2" *ngIf="taskSubmissionDetails?.submissionType === 'SHARE_UPDATE'">
        <kl-task-activity-updates [taskActivityUpdates]="taskSubmissionDetails"></kl-task-activity-updates>
      </div>
      <!--/ Task activity updates  -->

    </mat-card>
  </div>
</ng-container>
