<mat-card fxFlex="100%" fxLayout="column" id="social-container">
  <!-- social header -->
  <div class="social-header py-2 mb-2 mt-n4" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="pt-2">
      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/icons/social.svg"
          [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 font-weight-bold" translate>Social</p>
      </div>
    </div>
    <div>
      <button *ngIf="!isPublicPage" matTooltip="Edit social details" mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end" (click)="showSocialDialog()">
        <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
      </button>
    </div>
  </div>
  <!--/ social header -->

  <!-- Social list -->
  <section>
    <div fxLayout="column" class="tile-list">

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.promotingInstitution?.promotingInstitutionLogoName">
        <span fxFlex="calc(40% - 25px)" translate>Promoting institution name</span>
        <span class="social-details" fxFlex="60%">
          {{ (socialFormDetails?.promotingInstitution?.promotingInstitutionLogoName) ? ((socialFormDetails?.promotingInstitution?.promotingInstitutionLogoName === 'Others') ? (socialFormDetails?.promotingInstitution?.promotingInstitution) : (socialFormDetails?.promotingInstitution?.promotingInstitutionLogoName)) : '-' }}
        </span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px"
        *ngIf="socialFormDetails && socialFormDetails?.promotingInstitution?.promotingInstitutionLogo">
        <span fxFlex="calc(40% - 25px)" translate>Promoting institution logo</span>
        <div mat-raised-button fxFlex="60%">
          <img matTooltip="Click to full view" matTooltipPosition="before" class="attachement-image cursor-pointer mr-4"
            [src]="socialFormDetails?.promotingInstitution?.promotingInstitutionLogo | prefixDomain" alt="logo" width="30" height="30"
            (click)="openImageFullView(socialFormDetails?.promotingInstitution?.promotingInstitutionLogo)" />
          <!-- <a matTooltip="promoting institution logo" matTooltipPosition="after" href="{{s3BucketUrl+socialFormDetails?.promotingInstitution?.attachment}}"
            download="{{socialFormDetails?.promotingInstitution?.attachment}}" target="_self">
            <i class="fa fa-download" style="font-size:medium"></i></a> -->
        </div>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.resourceInstitution?.resourcingInstitutionLogoName">
        <span fxFlex="calc(40% - 25px)" translate>Resource institution name</span>
        <span class="social-details" fxFlex="60%">{{ socialFormDetails?.resourceInstitution?.resourcingInstitutionLogoName || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.resourceInstitution?.resourcingInstitutionLogo">
        <span fxFlex="calc(40% - 25px)" translate>Resource institution logo</span>
        <div mat-raised-button fxFlex="60%">
          <img matTooltip="Click to full view" matTooltipPosition="before" class="attachement-image cursor-pointer mr-4"
            [src]="socialFormDetails?.resourceInstitution?.resourcingInstitutionLogo | prefixDomain" alt="logo" width="30" height="30"
            (click)="openImageFullView(socialFormDetails?.resourceInstitution?.resourcingInstitutionLogo)" />
          <!-- <a matTooltip="resource institution logo" matTooltipPosition="after" href="{{s3BucketUrl+socialFormDetails?.resourceInstitution?.attachment}}"
            download="{{socialFormDetails?.resourceInstitution?.attachment}}" target="_self">
            <i class="fa fa-download" style="font-size:medium"></i></a> -->
        </div>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.teamPhoto?.teamPhotoName">
        <span fxFlex="calc(40% - 25px)" translate>Team photo</span>
        <span class="social-details" fxFlex="60%">{{ socialFormDetails?.teamPhoto?.teamPhotoName || '-' }}</span>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.teamPhoto?.teamPhotoLogo">
        <span fxFlex="calc(40% - 25px)" translate>Team photo</span>
        <div mat-raised-button fxFlex="60%" fxLayout="row" fxLayoutAlign="flex-start center">
          <img matTooltip="Click to full view" matTooltipPosition="before" class="attachement-image cursor-pointer mr-4" [src]="(socialFormDetails?.teamPhoto?.teamPhotoLogo | prefixDomain) || '-'"  alt="logo" width="30" height="30" (click)="openImageFullView(socialFormDetails?.teamPhoto?.teamPhotoLogo)" />
          <!-- <a matTooltip="team photo" matTooltipPosition="after" href="{{s3BucketUrl+socialFormDetails?.teamPhoto?.attachment}}"
            download="{{socialFormDetails?.teamPhoto?.attachment}}" target="_self">
            <i class="fa fa-download" style="font-size:medium"></i></a> -->
        </div>
      </div>

      <!-- Entity logo -->
      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="25px" *ngIf="socialFormDetails && socialFormDetails?.entityLogo">
        <span fxFlex="calc(40% - 25px)" translate>Entity logo</span>
        <div mat-raised-button fxFlex="60%" fxLayout="row" fxLayoutAlign="flex-start center">
          <img matTooltip="Click to full view" matTooltipPosition="before" class="attachement-image cursor-pointer mr-4" [src]="(socialFormDetails?.entityLogo | prefixDomain) || '-'"  alt="logo" width="30" height="30" (click)="openImageFullView(socialFormDetails?.entityLogo)" />
        </div>
      </div>
      <!--/ Entity logo -->

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(40% - 25px)" translate>Official website link</span>
        <a [href]="addHTTPToUrl(socialFormDetails?.socialInfo?.officialWebsiteLink)" target="_blank" class="social-details" fxFlex="60%">
        {{ addHTTPToUrl(socialFormDetails?.socialInfo?.officialWebsiteLink) }}
        </a>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(40% - 25px)" translate>Facebook link</span>
        <a [href]="addHTTPToUrl(socialFormDetails?.socialInfo?.facebookLink)" target="_blank" class="social-details" fxFlex="60%">
          {{ addHTTPToUrl(socialFormDetails?.socialInfo?.facebookLink) }}
        </a>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(40% - 25px)" translate>Twitter link</span>
        <a [href]="addHTTPToUrl(socialFormDetails?.socialInfo?.twitterLink)" target="_blank" class="social-details" fxFlex="60%">
          {{ addHTTPToUrl(socialFormDetails?.socialInfo?.twitterLink) }}
        </a>
      </div>

      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutAlign="start" fxLayoutGap="25px">
        <span fxFlex="calc(40% - 25px)" translate>Instagram link</span>
        <a [href]="addHTTPToUrl(socialFormDetails?.socialInfo?.instagramLink)" target="_blank" class="social-details" fxFlex="60%">
          {{ addHTTPToUrl(socialFormDetails?.socialInfo?.instagramLink) }}
        </a>
      </div>

    </div>
  </section>
  <!--/ Social list -->

  <!-- No content if there no social -->
  <ng-container *ngIf="false">
    <div class="w-100" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? ('No socials added yet!' | translate) : editable ? '' : ('No socials added yet!' | translate)"
        [actionLink]="isPublicPage ? '' : editable ? ('Add social' | translate) : ''"
        (action)="showSocialDialog()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no social -->
</mat-card>
