<main id="survey-members-add" class="h-100" fxLayout="column">
  <div fxFlex="92%">
    <kl-user-picker [multiSelect]="true" [(selectedUsersMap)]="selectedUsersMap"></kl-user-picker>
  </div>

  <div class="pt-0 mt-4 add-members" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-flat-button
      label="Add members"
      [progress]="progress"
      [disabled]="!selectedUsers?.length"
      (clicked)="addMember()">
    </kl-flat-button>
  </div>
</main>
