import { Component, Injector, OnInit } from '@angular/core';

import { fadeInAnimation, slideRightAnimation } from '../../animations';
import { AgGridStatsTablePage } from '../../classes/ag-grid-stats-table-page';

@Component({
  selector: 'store-admin-ag-grid-stats-table-page',
  templateUrl: './ag-grid-stats-table-page.component.html',
  styleUrls: ['./ag-grid-stats-table-page.component.scss'],
  animations: [ fadeInAnimation, slideRightAnimation ]
})
export class AgGridStatsTablePageComponent extends AgGridStatsTablePage implements OnInit {

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit(): void {

    this.initPage();
  }

}
