import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProfile } from '../../../classes/kalgudi-profile';
import { KalgudiProfileRouteConfig } from '../../../config';

@Component({
  selector: 'kl-ai-predictions',
  templateUrl: './ai-predictions.component.html',
  styleUrls: ['./ai-predictions.component.scss']
})
export class AiPredictionsComponent extends KalgudiProfile implements OnInit {

  constructor(
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProfileRouteConfig,
    protected injector: Injector,
    protected util: KalgudiUtilityService,
  ) {
    super(injector, util)
  }


  ngOnInit() {

  }


  toAiPrediction() {

    this.appRouting.toAiPrediction({profileKey: this.profileState.data.profileKey});
  }


  onDestroyed(): void {}
}
