<div id="page-target-filters" [@floatTop]="{ value: '', params: { delay: 0 }}">
  <!-- Heading -->
  <div fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" class="mb-3" *ngIf="!hideTargetAudienceCount">
    <h4 class="m-0 h6">{{ headingLabel | translate }}</h4>

    <div>
      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center">
        <p class="text-secondary mb-0">
          <span>{{ targetAudienceLabel | translate }}</span>
          <span>{{ targetAudienceCount$ | async | number }}</span>
        </p>

        <a class="cursor-pointer rounded-circle" [matTooltip]="'Refresh targeted members count' | translate" (click)="refreshTargetAudience()">
          <mat-icon class="rounded-circle" matRipple matRippleColor="primary">settings_backup_restore</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <!-- / Heading -->

  <!-- Product, organization and business types filters -->
  <div>

    <!-- Product filter -->
    <div class="mb-2" *ngIf="!hideProductsFilter">
      <kl-page-target-members-filter
        label="Crops"
        searchPlaceholder="Search and select crops"
        [formArray]="productsFormArray"
        [items]="productsList">
      </kl-page-target-members-filter>
    </div>
    <!-- / Product filter -->

    <!-- Organization filter -->
    <!-- <div class="mb-2">
      <kl-page-target-members-filter
        label="Organizations"
        searchPlaceholder="Search and select products"
        [formArray]="organizationsFormArray"
        [items]="productsList">
      </kl-page-target-members-filter>
    </div> -->
    <!-- / Organization filter -->

    <!-- Business types filter -->
    <div class="mb-2" *ngIf="!hideBusinessFilter">
      <kl-page-target-members-filter
        label="User role"
        searchPlaceholder="Search and select user roles"
        [formArray]="businessTypesFormArray"
        [items]="businessTypesList">
      </kl-page-target-members-filter>
    </div>
    <!-- / Business types filter -->


  </div>
  <!-- /Product, organization and business types filters -->

  <!-- Location -->
  <div *ngIf="!hideLocationFilter">

    <div fxLayout fxLayoutGap="10px" class="mb-2">
      <kl-slide-toggle
        falseLabel="Location"
        trueLabel="Select location range"
        (toggleChanged)="onLocationRangeFilterChange($event);">
      </kl-slide-toggle>
    </div>

    <!-- Locations filter -->
    <div *ngIf="!locationRangeFilter">
      <kl-page-target-members-filter
        label="Locations"
        searchPlaceholder="Search and select locations"
        [formArray]="locationsFormArray"
        [items]="locationsList">
      </kl-page-target-members-filter>
    </div>
    <!-- / Locations filter -->

    <!-- Location range filter -->
    <div *ngIf="locationRangeFilter">
      <kl-page-location-range-filter
        [countries]="countriesList"
        [countriesFormArray]="countriesFormArray"
        [states]="statesList"
        [statesFormArray]="statesFormArray"
        [districts]="districtsList"
        [districtsFormArray]="districtsFormArray">
      </kl-page-location-range-filter>
    </div>
    <!-- /Location range filter -->

  </div>
  <!--/ Location -->

  <div *ngIf="false"
    class="mb-2"
    fxLayout
    fxLayoutAlign="start center"
    [formGroup]="shareFormFilter"
  >
    <div class="mt-n1">
      <span class="mr-2 gender-text">Gender: </span>
    </div>

    <mat-radio-group
      aria-label="Select an option"
      class="mt-1"
      formControlName="gender"
    >
      <mat-radio-button value="" checked class="mr-4">All</mat-radio-button>
      <mat-radio-button value="MALE" class="mr-4">Male</mat-radio-button>
      <mat-radio-button value="FEMALE" class="mr-4"
        >Female</mat-radio-button
      >
    </mat-radio-group>
  </div>
</div>
