import { MatDateFormats } from '@angular/material/core';

/**
 * The file defines various common date formats for the material date picker.
 *
 * Available material date picker formats -
 *  - `MAT_SHORT_DATE_FORMAT` - `dd MMM yyyy`
 *  - `MAT_LONG_DATE_FORMAT`
 *  - `MAT_INDIAN_DATE_FORMAT`
 *  - `MAT_US_DATE_FORMAT`
 *
 * To change MatDatePicker format in the component provide the DatePicker configurations
 *
 * @example
 * providers: [
 *  {
 *    provide: DateAdapter,
 *    useClass: MomentDateAdapter,
 *    deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
 *  },
 *  { provide: MAT_DATE_FORMATS, useValue: MAT_SHORT_DATE_FORMAT }
 * ]
 *
 * See the Moment.js docs for the meaning of these formats:
 * @see https://momentjs.com/docs/#/displaying/format/
 *
 * @author Pankaj Prakash
 */


/**
 * Displays date in the `DD MMM YYYY` - 01 Jan 2020
 */
export const MAT_SHORT_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD MMM YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'DD MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'DD MMM YYYY',
  },
};

/**
 * Displays date in the `DD MMMM YYYY` - 01 January 2020
 */
export const MAT_LONG_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD MMMM YYYY',
  },
  display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'DD MMMM YYYY',
    dateA11yLabel: 'DD MMMM YYYY',
    monthYearA11yLabel: 'DD MMMM YYYY',
  },
};

/**
 * Displays date in the `DD-MM-YYYY` - 01-01-2020
 */
export const MAT_INDIAN_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD-MM-YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'DD-MM-YYYY',
  },
};

/**
 * Displays date in the `MMMM DD YYYY` - January 01 2020
 */
export const MAT_US_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'LLL',
  },
  display: {
    dateInput: 'LLL',
    monthYearLabel: 'MMMM DD YYYY',
    dateA11yLabel: 'MMMM DD YYYY',
    monthYearA11yLabel: 'MMMM DD YYYY',
  },
};
