import { Inject, Injector, Directive } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiPageRelation, PageDigitalAssistanceActions, UIMenu } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { DigitalAssistanceStateService } from '../services/digital-assistance-state.service';
import { DigitalAssistanceService } from '../services/digital-assistance.service';
import { DigitalAssistanceStream } from './digital-assistance-stream';

@Directive()
export abstract class KalgudiDigitalAssistancePage extends DigitalAssistanceStream {

  pageId: string;
  memberRole: KalgudiPageRelation;
  public env: KalgudiEnvironmentConfig;


  readonly digitalAssistMenu: UIMenu[];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected daService: DigitalAssistanceService,
    protected daStateService: DigitalAssistanceStateService,
    protected kalgudiPageService: KalgudiPageService,
  ) {

    // Initialize parent
    super(
      injector,
      notification,
      util,
      daService,
      daStateService
    );

    /**
     * Injecting the environment variable
     */
    this.env = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    // Initialize page details
    this.initPageDetails();

    // Initialize digital assistance menu
    this.digitalAssistMenu = this.getDigitalAssistMenu();
  }

  /**
   * Initializes page details and digital assistance stream
   */
  private initPageDetails(): void {

    this.kalgudiPageService.pageDetails$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(pageDetails => this.initDaStream(pageDetails.pageId, pageDetails.memberRole));
  }

  /**
   * Prepares digital assistance menu items
   */
  private getDigitalAssistMenu(): UIMenu[] {

    return [
      {
        title: 'Share an update',
        svgIcon: 'assets/svgs/updates.svg',
        id: this.getMenuId('SHAREATHOUGHT'),
      },
      {
        title: 'Ask a question',
        svgIcon: 'assets/svgs/discuss-issue.svg',
        id: this.getMenuId('QA'),
      },
      {
        title: 'Training',
        svgIcon: 'assets/svgs/training.svg',
        id: this.getMenuId('TRAINING'),
        hide: !(this.env.appId === 'SAM_FARMER' || this.env.appId === 'SAM_FPO')
      },
      {
        title: 'Update profile',
        svgIcon: 'assets/svgs/update-profile.svg',
        id: this.getMenuId('PROFILE_UPDATE'),
      },
      {
        title: 'Profile logs',
        svgIcon: 'assets/svgs/profile-logs.svg',
        id: this.getMenuId('PROFILE_LOGS'),
      },
      {
        title: 'Tasks',
        svgIcon: 'assets/svgs/tasks.svg',
        id: this.getMenuId('TASKS'),
      },
      // {
      //   title: 'Farm activity',
      //   svgIcon: 'assets/svgs/farm-activity.svg',
      //   id: this.getMenuId('CROP_CALANDAR'),
      // },
      {
        title: 'Enable offline',
        matIcon: 'offline_pin',
        id: 'ENABLE_OFFLINE',
        hide: this.env.appId === 'FPO_NET_APP' || this.env.appId === 'SAM_FPO' || this.env.appId === 'SAM_FARMER' || this.env.appId === 'ORMAS_SHG'
      }
    ];
  }

  /**
   * Asserts digital assistance menu to choose from one of the available types.
   */
  private getMenuId(daAction: PageDigitalAssistanceActions): string {
    return daAction.toString();
  }
}
