<section class="credit-settings p-3">
  <div fxLayout="row" fxLayoutAlign="space-between start" class="br-bottom">
    <div>
      <h3 class="sub-heading">Domestic SMS</h3>
      <p>1 credit for 160 charecters.</p>
    </div>
    <kl-slide-toggle
    falseLabel="Off"
    trueLabel="On"
    [checked]="this.smsSettings.domesticSms"
    (toggleChanged)="onSmsToggle($event);">
    </kl-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between start" class="mt-3">
    <div>
      <h3 class="sub-heading">International SMS</h3>
      <p>SMS will be sent across all countries</p>
    </div>
    <kl-slide-toggle
    falseLabel="Off"
    trueLabel="On"
    [checked]="this.smsSettings.internationalSms"
    (toggleChanged)="onInternationalSmsToggle($event);">
    </kl-slide-toggle>
  </div>

  <!-- Save button -->
  <div fxLayoutAlign="end center">
    <div class="send-button pt-2 w-25">
      <kl-flat-button
      [type]="'button'"
      [label]="'Save'"
      [progress]="progress"
      [disabled]="!isSettingsChange"
      (clicked)="createAndUpdateCredit()">
      </kl-flat-button>
    </div>
  </div>

</section>
