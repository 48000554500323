import { Inject } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiPageDetails } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiProgramListService } from '../../program-list/services/kalgudi-program-list.service';


export abstract class MyKalgudiProgramsList {

  pageRelationAdmin$: Observable<KalgudiPageDetails[]>;
  pageRelationFollower$: Observable<KalgudiPageDetails[]>;

  myPages$: Observable<{ adminPages: KalgudiPageDetails[], followingPages: KalgudiPageDetails[] }>;

  constructor(
    protected programsService: KalgudiPageService,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    protected pageListService: KalgudiProgramListService,
  ) {


    this.myPages$ = this.pageListService.getMyPages('');

    this.pageRelationAdmin$ = of([]);
    this.pageRelationFollower$ = of([]);
  }

  /**
   *  Navigates to program list page
   */
  openProgramListPage() {
    this.appRouting.toProgramList();
  }

  /**
   * Navigates to program full view
   * @param pageId
   */
  openProgramFullview(pageId: string): void {
    this.appRouting.toProgram({ pageId });
  }

}
