<mat-card class="w-75 ml-2 mt-3 mx-auto updates-tile">

  <div fxLayout fxLayoutGap="8px" fxLayoutAlign="space-between">
    <div class="mb-1" fxLayout fxLayoutGap="8px" fxLayoutAlign="start">
      <img class="profile-pic rounded-circle mt-1" [src]="update?.createdBy?.profilePicUrl"
        [klErrorImages]="[
          (update?.createdBy?.profilePicURL || update?.createdBy?.profilePicUrl) | prefixDomain,
          domain + defaultProfilePic
        ]"
        [src]="(update?.createdBy?.profilePicURL || update?.createdBy?.profilePicUrl) | prefixDomain" >
      <div fxLayout="column">
        <a class="text-capitalize author-name">{{update?.createdBy?.firstName}}</a>
        <p class="text-muted mb-0">
          {{update?.CT | date:'dd'}}
          {{update?.CT | date:'MMMM' | translate}}
          {{update?.CT | date:'yyyy'}}
        </p>
      </div>
    </div>

    <div class="mr-n2 mt-n1" *ngIf="update?.createdBy?.profileKey === userProfileKey">
      <!-- three dot menu -->
      <button mat-icon-button class="header-menu" [matMenuTriggerFor]="Menu">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>

      <mat-menu #Menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
        <button mat-menu-item id="delete" class="mb-0"
          (click)="deleteUpdate(update.activityId)" translate>Delete</button>
      </mat-menu>
    </div>
  </div>

  <div class="update-description-wrapper">
    <p class="mb-0 update-description text-content"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="2">
      {{update?.activityDetails?.text}}
    </p>
  </div>

  <ng-container *ngIf="update?.activityDetails?.productsMetaData && update?.activityDetails?.productsMetaData[0] && update?.activityDetails?.productsMetaData?.length">
    <!-- Promotional share crops -->
    <div class="mt-2">
      <kl-promotional-share-crops
        [cropsList]="update?.activityDetails?.productsMetaData ? update?.activityDetails?.productsMetaData[0]?.crops : []">
      </kl-promotional-share-crops>
    </div>
    <!--/ Promotional share crops -->

    <!-- Promotional share attachments -->
    <div class="mt-2" *ngIf="update?.text">
      <kl-post-tile-attachments  [shareUpdate]="update?.text"></kl-post-tile-attachments>
    </div>
    <!--/ Promotional share attachments -->

    <!-- Promotional share products -->
    <div class="mt-2" *ngIf="update?.activityDetails?.productsMetaData">
      <kl-promotional-share-products [productList]="update?.activityDetails?.productsMetaData"></kl-promotional-share-products>
    </div>
    <!--/ Promotional share products -->

    <ng-container *ngIf="update?.activityDetails?.productsMetaData && update?.activityDetails?.productsMetaData[0]?.dosage && update?.activityDetails?.productsMetaData[0]?.dosage?.length">
      <div fxLayout="column" class="mt-2 dosage-wrapper bg-white p-2">

        <div fxLayout fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0px" fxLayoutAlign="space-between">

          <div fxLayout>
            <span class="dosage-text" translate>Crop Name - </span>
            <span class="dosage-info">&nbsp;{{ update?.activityDetails?.productsMetaData[0]?.dosage?.crop?.productName}}</span>
          </div>
          <div fxLayout>
            <p class="dosage-text mb-0" translate>Per acre dosage - </p>
            <span class="dosage-info" fxLayout>
              <span>&nbsp;{{ update?.activityDetails?.productsMetaData[0]?.dosage?.volume }}</span>
              <span>&nbsp;{{ update?.activityDetails?.productsMetaData[0]?.dosage?.unit }}</span>
            </span>
          </div>
        </div>

        <div fxLayout>
          <span class="dosage-text" translate>Pest - </span>
          <span class="dosage-info">&nbsp;{{ update?.activityDetails?.productsMetaData[0]?.dosage?.pest }}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="false">
    <div class="mt-3 mr-n3 ml-n3 mb-n3" *ngIf="update?.activityDetails?.lstOfAttachments[0]?.msgType != 'DOCUMENT'">
      <div class="w-100 updates-image cursor-pointer position-relative" (click)="openFullview(update?.activityDetails?.lstOfAttachments)"
        [style.background-image]="'url('+ (update?.activityDetails?.lstOfAttachments[0]?.url | prefixDomain ) +')'">
        <!-- <img class="w-100" height="200px" [src]="update?.lstOfAttachments[0]?.url | prefixDomain | resizeImage:'md'"> -->
        <!-- Number of images -->
        <div *ngIf="update?.activityDetails?.lstOfAttachments?.length > 1"
          class="position-absolute img-list text-white"
          fxLayout="column"
          fxLayoutAlign="center center">
          <p class="mb-0 img-count">{{ update?.activityDetails?.lstOfAttachments?.length - 1 }}</p>
          <p class="mb-0 more" translate>More</p>
        </div>
        <!-- Number of images -->
      </div>
    </div>
    <div class="mt-2" fxLayout="row wrap" fxLayoutGap="6px" *ngIf="update?.activityDetails?.lstOfAttachments[0]?.msgType === 'DOCUMENT'">
      <mat-chip-list>
        <ng-container *ngFor="let item of update?.activityDetails?.lstOfAttachments; let i = index">
          <mat-chip class="pl-0 chips">
            <!-- <mat-icon>file_copy</mat-icon> -->
            <a class="text-truncate ml-1 pl-1" [href]="item?.url | prefixDomain" target="_blank" >
              {{ item?.context || 'Document ' + (i + 1)}}
            </a>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>

  </ng-container>


  <ng-container *ngIf="update?.activityDetails?.lstOfAttachments?.length">

    <div class="mt-3 mr-n3 ml-n3 mb-n3">

      <kl-social-attachments [attachments]="update?.activityDetails?.lstOfAttachments"></kl-social-attachments>
    </div>

  </ng-container>

  <div class="mt-3 mr-n3 ml-n3 mb-n3" *ngIf="update?.activityDetails?.uri && update?.activityDetails?.uriTitle">
    <kl-post-tile-link-attachment [post]="update?.activityDetails">
    </kl-post-tile-link-attachment>
  </div>

</mat-card>
