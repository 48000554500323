import { Component, Injector, Input, OnChanges, OnInit } from '@angular/core';

import { KalgudiTaskStats } from '../../classes/kalgudi-task-stats';

@Component({
  selector: 'kl-task-stats',
  templateUrl: './task-stats.component.html',
  styleUrls: ['./task-stats.component.scss']
})
export class TaskStatsComponent extends KalgudiTaskStats implements OnInit, OnChanges {

  @Input()
  statType: string;

  activeTabIndex: number;

  TABS = {
    COMPLETED: {
      index: 0,
      color: '#58EB8B',
      title: 'Completed',
    },
    IN_PROGRESS: {
      index: 1,
      color: '#FFB74F',
      title: 'In progress',
    },
    NOT_STARTED: {
      index: 2,
      color: '#FC5D5D',
      title: 'Not started',
    }
  }

  constructor(protected injector: Injector) {
    super(injector)
  }

  ngOnInit() {
  }

  ngOnChanges() {

    if(this.statType) {
      this.activeTabIndex = this.TABS[this.statType].index;
    }

    if(this.taskId) {
      this.getTaskMemberStats(this.taskId);
    }
  }

  onDestroyed(): void {}

}
