import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { disableAutofill } from './disable-autofill-directive';
import { DynamicHostDirective } from './dynamic-host.directive';
import { ErrorImagesDirective } from './error-images.directive';
import { NumberType } from './number-type.directive';
import { NumberOnlyDirective } from  './number-only.directive';
import { OptionsScrollDirective } from './options-scroll.directive';



@NgModule({
  declarations: [
    DynamicHostDirective,
    ErrorImagesDirective,
    NumberType,
    NumberOnlyDirective,
    BlockCopyPasteDirective,
    OptionsScrollDirective,
    disableAutofill
  ],
  imports: [
    CommonModule

  ],
  exports: [
    DynamicHostDirective,
    ErrorImagesDirective,
    NumberType,
    NumberOnlyDirective,
    disableAutofill,
    BlockCopyPasteDirective,
    OptionsScrollDirective
  ],
  providers: [ ]
})
export class KalgudiCommonDirectivesModule { }
