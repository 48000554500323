import { AgmMap, ControlPosition, PolygonOptions, ZoomControlOptions, ZoomControlStyle } from '@agm/core';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoDetails, LatLong } from '@kalgudi/types';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { GoogleGeoLocationService } from '../../services/google-geo-location.service';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => GeoLocationMarkerComponent),
  multi: true,
};


@Component({
  selector: 'kl-geo-location-marker',
  templateUrl: './geo-location-marker.component.html',
  styleUrls: ['./geo-location-marker.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class GeoLocationMarkerComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild(AgmMap) agmMap: AgmMap;

  // Telangana, Hyderabad, India
  readonly DEFAULT_LOCATION: LatLong = {
    latitude: 18.1124372,
    longitude: 79.01929969999999,
  };

  @Input()
  location: LatLong;

  @Input()
  zoomControlOptions: ZoomControlOptions = {
    style: ZoomControlStyle.SMALL,
    position: ControlPosition.RIGHT_BOTTOM
  };

  @Input()
  mapTypeId: 'roadmap' | 'hybrid' | 'satellite' | 'terrain' | string = 'hybrid';

  @Input()
  zoom: number = 17;

  @Input()
  drawingControls: boolean = true;

  @Input()
  drawingModes: any[];

  @Input()
  activeDrawingMode: any;

  @Input()
  polygonOptions: PolygonOptions = {
    draggable: true,
    editable: true,
    clickable: true,
    visible: true,
    zIndex: 1,
    fillColor: '#ff0000',
    strokeColor: '#cb0202',
  };

  @Input()
  polygonPath: GeoDetails[] = [];

  @Input()
  disabled: boolean = false;

  @Input()
  showDot: boolean;

  @Input()
  showPin: boolean;

  @Output()
  polygonChanged = new EventEmitter<any>();

  @Output()
  areaChanged = new EventEmitter<number>();

  polygon: any;

  /**
   * Flag sets to `true` if the map has been initialized.
   */
  isMapInitialized = false;

  private map: any;
  private overlayCompleteEventListenerRef: any;
  private drawingManager: any;

  private polygonEventListeners: any[] = [];


  private mapInitializedSubject = new Subject<any>();
  private readonly mapInitialized$: Observable<any>;
  private initPolygonSubject = new Subject<{ polygon: any, polygonPath?: any[]}>();
  private readonly initPolygon$: Observable<{ polygon: any, polygonPath?: any[]}>;
  private destroyedSubject = new Subject();
  private readonly destroyed$: Observable<any>;


  constructor(
    private geoLocationService: GoogleGeoLocationService,
  ) {

    this.mapInitialized$ = this.mapInitializedSubject.asObservable();
    this.initPolygon$    = this.initPolygonSubject.asObservable();
    this.destroyed$      = this.destroyedSubject.asObservable();


    this.subscribePolygonInitializationUpdates();
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.polygonPath && this.polygonPath) {
      this.updatePolygon(null, this.polygonPath);
    }
  }

  ngOnDestroy(): void {
    // Clear polygon event listeners if any
    this.reset();

    this.removeOverlayCompleteEventListener();

    this.destroyedSubject.next();
    this.destroyedSubject.complete();
  }



  // --------------------------------------------------------
  // #region Form control accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.updatePolygon(null, obj);
    } else {
      this.reset();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------




  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Event handler called when the agm/core map gets ready
   */
  onMapReady(map) {

    this.map = map;

    this.initDrawingManager(map);

    this.mapInitializedSubject.next(map);
    this.mapInitializedSubject.complete();

    this.isMapInitialized = true;
  }

  /**
   * Gets, the polygon path.
   */
  getPolygonPath(): GeoDetails[] {

    // Polygon does not exists
    if (!this.polygon) {
      return [];
    }

    return this.geoLocationService.getPath(this.polygon);

    // Get the path (lat, langs) of the shape
    // return this.geoLocationService.getPath({ latLngs: { i: [this.polygon.getPath()] }});
  }

  /**
   * Resets the current drawn shape and shows the drawing manager back.
   */
  reset() {

    if (this.polygon) {
      // Clear previous polygon event listeners
      this.removePolygonEventListener(this.polygon);

      // Clear the polygon
      this.polygon.setMap(null);
      this.polygon = null;
    }

    // Enable back the drawing
    this.showDrawingManager();
  }

  /**
   * Calculates area of polygon in acres
   *
   * @param polygon List of lat langs
   */
  calculateAreaOfSphericalPolygonInAcres(polygon: GeoDetails[]): number {

    const areaInSqM = this.calculateAreaOfSphericalPolygon(polygon);

    const areaInHectares = areaInSqM / (((10000.0 ) * 10.0 ) /10.0);

    const areaInAcres = areaInHectares * 2.47105;

    return areaInAcres;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------




  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Updates the polygon
   */
  private updatePolygon(polygon: any, polygonPath: any[] = []): void {
    this.initPolygonSubject.next({ polygon, polygonPath});

    const location = this.getCenterCoordinates(polygonPath);
    this.location = location || this.location;
  }

  /**
   * Subscribe to the polygon initializations functions
   */
  private subscribePolygonInitializationUpdates(): void {

    combineLatest(
      this.mapInitialized$,
      this.initPolygon$,
    )
    .pipe(
      takeUntil(this.destroyed$)
    ).subscribe(([map, polygon]) => this.initPolygon(polygon.polygon, polygon.polygonPath));
  }

  /**
   * Initializes the polygon to the map
   */
  private initPolygon(polygon: any, polygonPath: any[] = []): void {

    // Reset previous polygon if exists
    this.reset();

    if (polygon) {
      // Create a new shape from the current drawn shape
      this.polygon        = polygon.overlay;
      this.polygon.type   = polygon.type;
      this.polygon.zIndex = 1;
    } else if (Array.isArray(polygonPath) && polygonPath.length > 0) {

      this.polygon = new google.maps.Polygon({
        ...this.polygonOptions,
        paths: polygonPath,
        zIndex: 1,
        map: this.map,
        editable: !this.disabled,
        draggable: !this.disabled,
      });
    }

    this.updatedPolygonPath();

    // Attach event listener to the polygon
    this.attachPolygonEventListener(this.polygon);

    if (this.polygon) {

      // Get the path (lat, langs) of the shape
      this.polygonPath = this.polygon ? this.geoLocationService.getPath(this.polygon) : [];

      // Only one drawing allowed, cannot redraw on the map.
      this.hideDrawingManager();
    }

  }

  /**
   * Gets, the polygon path from the polygon
   */
  private updatedPolygonPath(): GeoDetails[] {

    // Get the path (lat, langs) of the shape
    this.polygonPath = this.getPolygonPath();

    // Update polygon path to form control
    this.onChange(this.polygonPath);
    this.onTouched();

    const areaInAcres = this.updatePolygonArea(this.polygonPath);
    this.polygonChanged.emit({
      areaInAcres,
      polygon: this.polygonPath,
    });

    // console.log("GeoLocationMarkerComponent -> this.polygonPath", this.polygonPath);

    return this.polygonPath
  }

  /**
   * Creates a new instance of drawing manager on to the map.
   */
  private initDrawingManager(map: any) {

    if (!google) {
      return;
    }

    const options: google.maps.drawing.DrawingManagerOptions = {
      drawingControl: this.drawingControls,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [ google.maps.drawing.OverlayType.POLYGON ],
      },
      polygonOptions: {
        ...this.polygonOptions as any,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    // Create a new instance of drawing manager
    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    // Attach drawing manager events
    this.attachOverlayCompleteEventListener(this.drawingManager);
  }

  /**
   * Attach overlay complete event listener to google maps
   */
  private attachOverlayCompleteEventListener(drawingManager: any): void {

    if (!google || this.overlayCompleteEventListenerRef) {
      return;
    }

    this.overlayCompleteEventListenerRef = google.maps.event.addListener(
      drawingManager,
      'overlaycomplete',
      (event) => this.updatePolygon(event)
    );
  }

  /**
   * Remove overlay complete event listener from the google map
   */
  private removeOverlayCompleteEventListener(): void {

    if (!google || !this.overlayCompleteEventListenerRef) {
      return;
    }

    google.maps.event.removeListener(this.overlayCompleteEventListenerRef);

    this.overlayCompleteEventListenerRef = null;
  }

  /**
   * Attaches event listener to polygon
   */
  private attachPolygonEventListener(polygon: any): void {

    // Attach click event
    if (!polygon) {
      return;
    }

    const setAtListener    = google.maps.event.addListener(polygon.getPath(), 'set_at', ()  => this.updatedPolygonPath());
    const insertAtListener = google.maps.event.addListener(polygon.getPath(), 'insert_at', ()  => this.updatedPolygonPath());

    // Necessary to push the event listeners to a variable for resource cleanup later
    this.polygonEventListeners.push(setAtListener);
    this.polygonEventListeners.push(insertAtListener);
  }

  /**
   * Removes event listener from polygon
   */
  private removePolygonEventListener(polygon: any): void {

    if (!polygon) {
      return;
    }

    // Remove all the event listeners attached to the polygon
    (this.polygonEventListeners as Array<google.maps.MapsEventListener>).forEach(listener => listener.remove());

    // Clear the event listener in memory
    this.polygonEventListeners = [];
  }

  /**
   * Hides the drawing manager control
   */
  private hideDrawingManager(): void {
    if (!this.drawingManager) {
      return;
    }

    (this.drawingManager as google.maps.drawing.DrawingManager).setOptions({
      drawingControl: false,
      drawingMode: null,
    });
  }

  /**
   * Displays the drawing manager control
   */
  private showDrawingManager(): void {
    if (!this.drawingManager) {
      return;
    }

    (this.drawingManager as google.maps.drawing.DrawingManager).setOptions({
      drawingControl: true,
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    });
  }

  /**
   * Get center coordinates of a geo fence details
   *
   * Logic copied from ConserWater API dashboard
   *
   * @see https://conserwater.herokuapp.com/ Source code ;)
   */
  private getCenterCoordinates(coords: GeoDetails[]): GeoDetails {

    if (coords.length <= 0) {
      return null;
    } else if (coords.length === 1) {
      return coords[0];
    }

    let sumLat  = Number(-coords[0].lat);
    let sumLong = Number(-coords[0].lng);

    coords.map(x => {
      sumLat  += +x.lat;
      sumLong += +x.lng;
    });

    const avgLat  = sumLat / (coords.length-1);
    const avgLong = sumLong / (coords.length-1);

    const lat = (avgLat * 100) / 100;
    const lng = (avgLong * 100) / 100;

    return {
      lat,
      lng,
      latitude: lat,
      longitude: lng,
    };
  }

  /**
   * Calculates area of the polygon and fires back an event to the parent.
   */
  private updatePolygonArea(polygon: GeoDetails[]): number {

    const areaInAcres = this.calculateAreaOfSphericalPolygonInAcres(polygon);

    this.areaChanged.emit(areaInAcres);

    return areaInAcres;
  }

  /**
   * Code taken from ConserWater public source code
   *
   * @return Area of a spherical polygon in sq meters
   */
  private calculateAreaOfSphericalPolygon(polygon: GeoDetails[], radius?: any) {
    // uses method due to Karney: osgeo-org.1560.x6.nabble.com/Area-of-a-spherical-polygon-td3841625.html;
    // for each edge of the polygon, tan(E/2) = tan(Δλ/2)·(tan(φ1/2) + tan(φ2/2)) / (1 + tan(φ1/2)·tan(φ2/2))
    // where E is the spherical excess of the trapezium obtained by extending the edge to the equator

    if (polygon.length <= 0) {
      return 0;
    }

    const R = radius === undefined ? 6371e3 : Number(radius);

    // close polygon so that last point equals first point
    const closed =
      polygon[0].lat == polygon[polygon.length - 1].lat &&
      polygon[0].lng == polygon[polygon.length - 1].lng;

    if (!closed) {
      polygon.push(polygon[0]);
    }

    const nVertices = polygon.length - 1;

    let S = 0; // spherical excess in steradians
    for (let v = 0; v < nVertices; v++) {
      let φ1 = (+polygon[v].lat * 3.14159) / 180;
      let φ2 = (+polygon[v + 1].lat * 3.14159) / 180;
      let Δλ = ((+polygon[v + 1].lng - +polygon[v].lng) * 3.14159) / 180;

      let E =
        2 *
        Math.atan2(
          Math.tan(Δλ / 2) * (Math.tan(φ1 / 2) + Math.tan(φ2 / 2)),
          1 + Math.tan(φ1 / 2) * Math.tan(φ2 / 2)
        );

      S += E;
    }

    let A = Math.abs(S * R * R); // area in units of R

    return A;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
