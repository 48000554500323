import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { BulkMembersAdditionResponse } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiSurveyMembersService } from '../../../services/kalgudi-survey-members.service';
import { SurveyStateService } from '../../../services/survey-state.service';

@Directive()
export abstract class KalgudiSurveyAddBulkMembers extends KalgudiDestroyable {

  @Output()
  addedMembers = new EventEmitter();

  progress: boolean;

  surveyId: string;

  private kalgudiSurveyMembers: KalgudiSurveyMembersService;
  private notification: KalgudiNotification;
  private stateService: SurveyStateService;
  private kalgudiStatusDialogService: KalgudiStatusDialogService;

  constructor(protected injector: Injector) {

    super();

    this.kalgudiSurveyMembers       = this.injector.get(KalgudiSurveyMembersService);
    this.notification               = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.stateService               = this.injector.get(SurveyStateService);
    this.kalgudiStatusDialogService = this.injector.get(KalgudiStatusDialogService);

    this.stateService.id$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        id => {
          this.surveyId = id;
        }
      );
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Calls the api to add bulk survey members
   */
  addMembers(users: string[]) {

    this.notification.showSpinner();

    this.kalgudiSurveyMembers.addBulkMembersList(this.surveyId, users)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onAddMemberError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);

    this.addedMembers.emit();

    // this.notification.showMessage('Members added');
  }

  /**
   * Event handler for adding members API errors.
   */
  protected onAddMemberError(err: Error): void {
    this.notification.showMessage(err.message);
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
