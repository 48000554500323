import { Inject, Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProjectTask, PartialData, TaskTemplateDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { TaskCreationStates } from '../models';
import { CreateTaskStateService } from '../services/create-task-state.service';
import { KalgudiTaskCreationService } from '../services/kalgudi-task-creation.service';

@Directive()
export abstract class KalgudiTaskCreation extends KalgudiDestroyable {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  filters: PartialData;

  taskDetails: KalgudiProjectTask;

  commonTaskForm: FormGroup;
  detailedTaskForm: FormGroup;

  templateType: any;

  searchForm: FormGroup;

  templateSchema: TaskTemplateDetails;

  progress: boolean;

  protected projectStateService: KalgudiProjectStateService;
  protected fb: FormBuilder;
  private taskStateService: CreateTaskStateService;
  private taskCreationService: KalgudiTaskCreationService;
  private notifications: KalgudiNotification;
  private appService: KalgudiAppService;
  private projectService: KalgudiProjectService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super();

    this.commonTaskForm = new FormGroup({});
    this.detailedTaskForm = new FormGroup({});

    this.taskStateService       = this.injector.get<CreateTaskStateService>(CreateTaskStateService);
    this.taskCreationService    = this.injector.get<KalgudiTaskCreationService>(KalgudiTaskCreationService);
    this.notifications          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.projectService         = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.projectStateService    = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.fb                     = this.injector.get(FormBuilder);
    /**
     * Tap `createTask()` if the state changes to the create task.
     * This action should happen automatically.
     */
    this.taskStateService.creationState$
      .pipe(

        // Filter only `CREATE_TASK` events
        // filter(state => state === 'CREATE_TASK'),

        takeUntil(this.destroyed$),

        // tap(_ => this.createTask())
      ).subscribe(

        state => {


          if (state === 'CREATE_TASK') {

            this.saveTask();
          } else if (state === 'FILL_COMMON_TASK_DETAILS') {

            // Fetch the template schema if not available
            if (!this.templateSchema) {
              // A delay is necessary to ensure stepper moves ahead then get the template
              setTimeout(() => this.getTemplateSchema(this.templateType), 300);
            }
          }

        }
      );

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  /**
   * Returns current active state
   */
  get currentState(): TaskCreationStates {
    return this.taskStateService.currentState;
  }

  get searchKeywordField(): AbstractControl {
    return this.searchForm.get('searchKeyword');
  }

  get creationTemplateDataField(): AbstractControl {
    return this.commonTaskForm.get('creationTemplateData');
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  resetSearchKeyword() {
    this.searchKeywordField.patchValue('');
  }

  /**
   * Saves the task
   */
  saveTask() {

    if (this.taskId) {
      this.updateTask();
    } else {
      this.createTask();
    }
  }

  /**
   * Calls the api to create a task
   */
  createTask() {

    // Get share payload, to be implemented by its child
    const payload = this.getCreationPayload(this.taskStateService.creationStateData);

    this.progress = true;

    this.taskCreationService.createTask(this.projectId, payload, this.filters)
      .subscribe(
        res => {

          this.progress = false;
          this.taskCreationHandler(res);
        },
        err => this.onTaskCreationError(err)
      );
  }

  /**
   * Calls the api to update a task
   */
  updateTask() {

    // Get share payload, to be implemented by its child
    const payload = this.getCreationPayload(this.taskStateService.creationStateData);

    this.progress = true;

    this.taskCreationService.updateTask(this.projectId, this.taskId, payload, this.filters)
      .subscribe(
        res => {

          this.progress = false;
          this.taskUpdationHandler(res);
        },
        err => this.onTaskUpdationError(err)
      );
  }

  /**
   * Fetches the selected template full details
   */
  getTemplateSchema(templateId: string) {

    this.notification.showSpinner(true);

    this.projectService.getTemplateSchema(templateId)
      .pipe(
        finalize(() => this.notification.hideSpinner()),
      )
      .subscribe(
        res => this.onTemplateFetching(res),
        err => this.onTaskCreationError(err)
      );

  }

  onTemplateFetching(template: TaskTemplateDetails): void {
    this.templateSchema = template;
  }

  /**
   * Update the schema with latest fields
   */
  updateSchema(template: any) {
    this.templateSchema.schema.properties.tags = template;
  }

  /**
   * Moves the current page creation state to the next state.
   */
  nextStep() {

    let data = {};

    if (this.currentState === 'CHOOSE_TASK_TYPE') {

      data = this.templateType;
      if (this.taskId) {
        this.patchAttachments(this.taskDetails);
      }

    } else if (this.currentState === 'FILL_COMMON_TASK_DETAILS') {

      data = this.commonTaskForm.value;
      // if (this.taskId) {
      //   this.detailedTaskForm.patchValue(this.taskDetails);
      // }
      this.detailedTaskForm.patchValue(data);

    } else if (this.currentState === 'FILL_SPECIFIC_TASK_DETAILS') {
      data = this.detailedTaskForm.value;
    }

    this.taskStateService.nextState(data);

  }

  /**
   * New form group for the attachments
   */
  private get attachForm(): FormGroup {
      return this.fb.group({
        context: [''],
        msgType: [''],
        url: [''],
      });
    }

  /**
   * Moves the current page creation stage to the previous state.
   */
  previousState() {
    this.taskStateService.previousState();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  protected abstract onTaskError(err: Error): void;

  protected onTaskCreation(res): void {}

  /**
   * Fetches the task details from projects state service and initializes the
   * task details.
   */
  protected initTaskDetails(taskId: string): void {

    this.projectStateService.taskDetails$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.taskDetails = res;
          this.templateType = res.templateRefId;
        }
      );
  }

  /**
   * Construct and gets, the task creation payload.
   */
  protected getCreationPayload(formValue: any): any {

    const payload: any  = {
      template: this.templateSchema,
      task: {
        templateId: formValue.CHOOSE_TASK_TYPE.data,
        ...formValue.FILL_COMMON_TASK_DETAILS.data,
        // ...formValue.FILL_SPECIFIC_TASK_DETAILS.data,
        projectId: this.projectId
      }
    };


    if (payload.task.timeFrame.start) {
      payload.task.timeFrame.start = this.util.toISOString(payload.task.timeFrame.start);
    }

    if (payload.task.timeFrame.end) {
      payload.task.timeFrame.end = this.util.toISOString(payload.task.timeFrame.end);
    }

    if (payload.task.tags && payload.task.tags.input && payload.task.tags.input.length) {
      payload.task.tags.input.forEach((input, i) => {
        if(!input.product && !input.cost && !input.quantityPerAcre.value && !input.quantityPerAcre.unit && !input.needType) {
          payload.task.tags.input.splice(i, 1);
        }

        if(input.productName) {
          const product = {
            productName: input.productName,
            productId: ''
          }

          input.product = product;
        }
      });
    }

    if (payload.task.tags && payload.task.tags.output && payload.task.tags.output.length) {
      payload.task.tags.output.forEach((output, i) => {
        if(!output.product && !output.cost && !output.expectedYieldPerAcre.value && !output.expectedYieldPerAcre.unit) {
          payload.task.tags.output.splice(i, 1);
        }
      });
    }

    // if (payload.task.tags && payload.task.tags.services && payload.task.tags.services.length) {
    //   payload.task.tags.services.forEach((service, i) => {
    //     if(!service.product && !service.cost && !service.noOfHourPerAcre) {
    //       payload.task.tags.services.splice(i, 1);
    //     }
    //   });
    // }

    if (payload.task.tags && payload.task.tags.info && payload.task.tags.info.length) {
      payload.task.tags.info.forEach((info, i) => {
        if(!info) {
          payload.task.tags.info.splice(i, 1);
        }
      });
    }

    return payload;
  }

  /**
   * Validates common task form for errors
   */
  protected validateCommonTaskForm(formValue: any): boolean {

    let isValid = true;

    if (formValue && formValue.isTaskDependentOnCropStage) {

      isValid = (formValue.cropStageDependency.from
        && formValue.cropStageDependency.to
        && formValue.cropStageDependency.taskId);
    }

    return isValid;
  }

  /**
   * Validates the form if the selected template type if `CROP_STAGE_PICKER`
   * then `cropStage` field must be present.
   */
  protected validateCommonTaskCropStage(formValue: any): boolean {

    let isValid = true;

    if (this.templateSchema.templateType === 'CROP_STAGE_PICKER' && formValue) {

      isValid = !!(formValue.cropStage);
    }

    return isValid;
  }


  /**
   * Event handler for task creation API errors.
   */
  protected onTaskCreationError(err: Error): void {

    this.notifications.showMessage('Something went wrong, Unable to create task');

    this.onTaskError(err);
  }

  /**
   * Event handler for task Updation API errors.
   */
  protected onTaskUpdationError(err: Error): void {

    this.notifications.showMessage('Something went wrong, Unable to update task');

    this.onTaskError(err);
  }


  private taskCreationHandler(res) {

    // this.notification.showMessage('Task created successfully');
    this.taskStateService.nextState(res);
    this.onTaskCreation(res);
  }

  private taskUpdationHandler(res) {

    // this.notification.showMessage('Task updated successfully');
    this.taskStateService.nextState(res);
    this.onTaskCreation(res);
  }

  /**
   * Patches the common form
   */
  private patchAttachments(taskDetails: KalgudiProjectTask) {

    if (taskDetails.attachments && taskDetails.attachments.length) {

      // Patches the form array by inserting the formGroup individually
      taskDetails.attachments.forEach(a => {
        const imageArray = this.commonTaskForm.get('attachments') as FormArray;
        imageArray.push(this.attachForm);
      });
    }

    this.commonTaskForm.patchValue(taskDetails);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
