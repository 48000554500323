import { HttpClient } from '@angular/common/http';
import { Directive, Inject, Injector, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import {
  KalgudiFilteredSearchStream,
  KalgudiStreamData,
  KalgudiStreamLoadAction,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiPageDetails, PartialData } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';

import { PageActions, PageFilter, PageFilterTypes } from '../../../constants';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { ProgramStateService } from '../../../services/program-state.service';
import { ProgramCloneDialogComponent } from '../program-clone-dialog/program-clone-dialog.component';
import { KalgudiProgramListService } from '../services/kalgudi-program-list.service';
import { PageListStateService } from '../services/page-list-state.service';


@Directive()
export abstract class KalgudiPagesList extends KalgudiFilteredSearchStream<KalgudiPageDetails> {

  @Input()
  pageFilters: PartialData = {};

  programTypeForm: FormGroup;
  programType: any;
  pageList: any;
  isSecondHit: boolean = false;
  progress: boolean;

  /**
   * Page filter form
   */
  filterForm: FormGroup;

  streamLoadAction: KalgudiStreamLoadAction = 'concat';

  pageType: string;
  isDisableButton: boolean = false;

  /**
   * Gets, the member role form field
   */
  private get membersRoleForm(): AbstractControl {
    return this.filterForm.get('memberRole');
  }

  private dialogsService: KalgudiDialogsService;
  private programState: ProgramStateService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected pagesListService: KalgudiProgramListService,
    protected kalgudiPrograms: KalgudiPageService,
    protected pageListStateService: PageListStateService,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    protected fb: FormBuilder,
    protected http: HttpClient

  ) {

    // Wake (initialize) up my parent
    super(notification, util);

    this.dialogsService = this.injector.get(KalgudiDialogsService);
    this.programState   = this.injector.get(ProgramStateService);

    // Define filters form
    this.filterForm = new FormGroup({
      memberRole: new FormControl('')
    });

    this.streamLoadAction = 'concat';
    this.programTypeForm = this.fb.group({
      keyword: '',
      all : true,
      groupTitle : true,
      groupDescription : true,
      includePageTypes: ''
    });

    // Invoke filter on page filter updation
    this.pageListStateService.pageTypeChange$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(filter => this.updateFilter(filter));

    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === PageActions.PAGE_CLONED),
      )
      .subscribe(res => this.resetStream());

      this.formValueChanges();
  }

  /**
   * Fetching page types
   */
  getProgramTypeList() {
    this.http.get('assets/json/page-creation-types.json').subscribe((res) => {
      this.programType = res;
    });
  }

  /**
   * Assigned boolean whenever the form value change
   */
  formValueChanges() {
    this.programTypeForm.valueChanges.subscribe(val => {
      if (val) {
        this.isDisableButton = false;
      }
    });
  }

  /**
   * Clear filters
   */
  clearFilters() {
    this.isSecondHit = false;
    this.progress = true;
    this.checkIncludePageTypes.reset();
    this.checkInputFieldValue.reset();
    const payload = this.paginatorValue;
    payload.offset = 0;
    payload.count = 0;
    payload.hasItems = true;
    payload.includePageTypes = 'PROGRAM,FARMER,MY_FARMERS,MY_FPOS,FIG';
    delete payload.searchProperties;
    delete payload.keyword;
    this.streamLoadAction = 'replace';
    this.setPaginator(payload);
    this.all.patchValue(true);
    this.groupTitle.patchValue(true);
    this.groupDescription.patchValue(true);
    this.isDisableButton = false;
  }

  /**
   * Fetch pages list on search keyword
   */
  getProgramTypeData(): void {
    this.isSecondHit = true;
    this.progress = true;
    const payload = this.paginatorValue;
    let keywords: string = this.programTypeForm.value.keyword;
    let includePageType: string = this.programTypeForm?.value?.includePageTypes?.toString() || "";
    let searchProperties: any = this.programTypeForm.value;

    payload.offset = 0;
    payload.keyword = keywords;
    payload.includePageTypes = includePageType;
    payload.searchProperties = searchProperties;
    this.streamLoadAction = 'replace';
    this.setPaginator(payload);
    this.isDisableButton = true;
  }

  /**
   * Get program type includePageType field value
   */
  get checkIncludePageTypes(): AbstractControl {
    return this.programTypeForm.get('includePageTypes');
  }

  /**
   * Get program type input field value
   */
  get checkInputFieldValue(): AbstractControl {
    return this.programTypeForm.get('keyword');
  }

  /**
   * Get for all
   */
  get all(): AbstractControl {
    return this.programTypeForm.get('all');
  }

  /**
   * Get for group title
   */
  get groupTitle(): AbstractControl {
    return this.programTypeForm.get('groupTitle');
  }

  /**
   * Get for group description
   */
  get groupDescription(): AbstractControl {
    return this.programTypeForm.get('groupDescription');
  }

  /**
   * Open page clone dialog
   * @param pageId
   */
  openPageCloneDialog(pageId: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Page cloning',
      data: {
        pageId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        pageId
      }
    };

    this.openPageCloningDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),
      )
  }

  /**
   * Opens page clone dialog for web and mobile
   * @param dialogConfig
   * @param matDialogConfig
   * @returns
   */
  private openPageCloningDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {


    // if (checkMobileDevice()) {

    //   return this.dialogsService.openMobileDialog(ProgramCloneDialogComponent, dialogConfig);
    // } else {

    return this.dialogsService.openDialog(ProgramCloneDialogComponent, dialogConfig, matDialogConfig);
    // }

  }

  /**
   * Initializes page list
   */
  protected initPagesList() {

    // Do not initialize the stream again
    if (this.isStreamInitialized) {
      return;
    }

    // this.extraSearchParams = {
    //   pageType: ''  // Will show all pages
    // };

    // Initialize the search stream
    this.initStream();


    this.isStreamInitialized = true;
  }

  /**
   * Implement this method to define your filter Api.
   *
   * @override
   */
  protected filterApi(searchKeyword: string, filters: any, offset: number, limit: number, extraParams?: PartialData): Observable<KalgudiStreamData> {

    if (filters.memberRole.type === PageFilterTypes.PAGE_TYPE) {
      return this.pagesListService.pagesList(offset, limit, filters.memberRole.value, this.pageFilters);
    } else {
      if (this.isSecondHit) {
        let keywords: string = this.programTypeForm.value.keyword;
        let includePageType: string = this.programTypeForm?.value?.includePageTypes?.toString() || "";
        let searchProperties: any = this.programTypeForm.value;
        return this.pagesListService.fetchPagesList(offset, limit, { memberRole: '' },  {includePageTypes: includePageType, keyword: keywords}, searchProperties)
        .pipe(finalize(() => (this.progress = false)));
      } else {
        return this.pagesListService.fetchPagesList(offset, limit, {memberRole: filters.memberRole.value}, this.pageFilters)
        .pipe(finalize(() => (this.progress = false)));
      }
    }
  }


  /**
   * Pages search api
   *
   * @param searchKeyword
   * @param offset
   * @param limit
   */
  private pagesSearchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.pagesListService.fetchPagesList(offset, limit, null);
  }

  /**
   * Pages filter api
   */
  private pagesFilterApi(filters: any, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.pagesListService.fetchPagesList(offset, limit, filters);
  }

  /**
   * Pages filtered search api
   */
  private pagesFilteredSearchApi(searchKeyword: string, filters: any, offset: number, limit: number): Observable<KalgudiStreamData> {

    return EMPTY;
  }

  /**
   * Updates the page filter to the page filter form and call the filter service.
   */
  private updateFilter(filter: PageFilter): void {

    this.membersRoleForm.patchValue(filter);
    this.filter();
  }
}
