<mat-card id="new-farmer-profile-details">

  <div fxLayout="row" fxLayoutGap="16px">

    <div fxFlex="55px" class="profile-tile">
      <div class="profile-img fade-in-background-color ml-1 rounded-circle position-relative overflow-hidden">
        <img class="w-100 h-100 rounded-circle" [src]="profile?.profilePicUrl | prefixDomain" alt="profilePicUrl"
          [klErrorImages]="[
          (profile?.profilePicUrl) | prefixDomain,defaultProfilePic
        ]"/>
        <div class="position-absolute profile-pic-edit w-100" *ngIf="editable">

          <span class="position-relative" fxLayout fxLayoutAlign="center center">
            <i class="fa fa-camera cam-icon mt-1 text-white" aria-hidden="true"></i>
            <input class="input-file cursor-pointer position-absolute" type="file" [matTooltip]="'Click to change profile picture' | translate" (change)="uploadProfilePic($event)" *ngIf="editable">
          </span>
        </div>
      </div>
    </div>

    <div fxLayout="column" fxFlex="calc(100% - 71px)"  class="new-profile-details">
      <div fxLayout fxLayoutAlign="space-between center">

        <h1 class="name text-truncate w-70 mb-0" fxFlex="calc(100% - 70px)">{{ profile?.firstName | titlecase}}</h1>
        <div fxLayout="row" fxLayoutGap="6px" fxFlex.xs="10%" class="share-edit-icons" >

          <!-- Share button -->
          <!-- <div class="social-shares" *ngIf="environment.appId === 'SAM_FARMER' || environment?.appId === 'SAM_FPO'">
            <button mat-icon-button class="btn share-btn border-0 p-0 shadow-none" [matMenuTriggerFor]="menu"
              [title]="'Share event on social media' | translate">
              <i class="fas fa-share-alt share-button mt-n3 shadow-none"></i>
            </button>

            <mat-menu #menu="matMenu" class="px-2 ml-n3 mt-1 custom">
              <div class="social-btn" fxLayout="column" fxLayoutAlign="center center">

                <div>
                  <button mat-icon-button
                    (click)="shareToFacebook()">
                    <i class="fab fa-facebook-f share-icons facebook-btn"></i>
                  </button>
                </div>

                <div>
                  <button mat-icon-button
                    (click)="shareToTwitter()">
                    <i class="fab fa-twitter share-icons twitter-btn"></i>
                  </button>
                </div>

                <div>
                  <button mat-icon-button
                    (click)="shareToLinkedin()">
                    <i class="fab fa-linkedin-in share-icons linked-btn"></i>
                  </button>
                </div>

                <div>
                  <button mat-icon-button
                    (click)="shareToWhatsappWeb()">
                    <i class="fab fa-whatsapp share-icons whatsapp-btn"></i> </button>
                </div>

                <div>
                  <button mat-icon-button
                    (click)="shareToEmailWeb()">
                    <i class="fas fa-envelope-square share-icons email-btn"></i>
                  </button>
                </div>
              </div>
            </mat-menu>
          </div> -->
          <!--/ Share button -->

          <button mat-icon-button class="border-0 edit-button p-0 mr-1" fxLayoutAlign="end" (click)="showEditDialog()" *ngIf="editable">
            <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
          </button>
        </div>
      </div>
      <h2 class="location mb-1 text-truncate"
        title="{{profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}} {{profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName && profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set' ? ',' : ''}} {{profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName === 'Location yet to be set' ? '' : profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong}}">
        <span>{{ profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName?.replace(',', '') }}</span>
        <span *ngIf="profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName && profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set'">,&nbsp;</span>
        <span *ngIf="profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set'">{{ profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
      </h2>

      <!-- Education info -->
      <div class="education-info" fxLayout *ngIf="profile?.education">
        <span translate>Education:</span>
        <span class="education-title">&nbsp;{{ profile?.education | translate }}</span>
      </div>
      <!--/ Education info -->

      <!-- <div id="about-update-tab" fxLayout>
        <div *ngFor="let tab of tabsList; let first = first;let last = last">
          <a class="font-weight-bold" [ngClass]="{'tab-selected text-secondary': selectedTabId === tab?.id, 'd-none': tab?.id === 'PROFILE_LOGS' && !showProfileLogs}"
          (click)="toggleTab(tab?.id)" translate>
            <span *ngIf="!first" class="mr-1 text-muted separator">|</span>
            <span class="pr-2 "> {{ tab?.title | translate }}</span>
          </a>
        </div>
      </div> -->

    </div>
  </div>

  <!-- Farmers-Profile-tabs -->
  <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start space-between" class="farmers-tabs p-0 mt-3 w-100">
    <mat-tab-group class="land-crops mb-n3" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="toggleTab($event)">

      <!-- Land-Crops tab -->
      <mat-tab [label]="TABS?.LANDCROPS?.title | translate">
        <div class="task-fill overflow-hidden" *ngIf="activeTabIndex === TABS?.LANDCROPS?.index">
        </div>
      </mat-tab>

      <!-- Updates tab -->
      <mat-tab [label]="TABS?.UPDATES?.title | translate">
        <div class="task-fill overflow-hidden" *ngIf="activeTabIndex === TABS?.UPDATES?.index">
        </div>
      </mat-tab>

      <!-- Tasks tab -->
      <mat-tab [label]="TABS?.TASKS?.title | translate">
        <div class="task-fill overflow-hidden" *ngIf="activeTabIndex === TABS?.TASKS?.index">
        </div>
      </mat-tab>

      <!-- Profile logs  tab -->
      <ng-container *ngIf="activeTabIndex === 3 && showProfileLogs">

        <mat-tab [label]="TABS?.PROFILE_LOGS?.title | translate">
          <div class="overflow-hidden" *ngIf="activeTabIndex === TABS?.PROFILE_LOGS?.index">
          </div>
        </mat-tab>

      </ng-container>

    </mat-tab-group>
  </div>

</mat-card>
