import { Injectable } from '@angular/core';
import {
  ApiResponseCommonListData,
  PageAnalytics,
  PageDigitalAssistAnalytics,
  PageProjectAnalytics,
  PageQaAnalytics,
  PageShareAnalytics,
  PageTaskAnalytics,
  PartialData,
} from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { KalgudiPageAnalyticsApiService } from './page-analytics-activities-api.service';

@Injectable()
export class KalgudiPageAnalyticsService {

  constructor(
    private api: KalgudiPageAnalyticsApiService
  ) { }

  /**
   * Fetches shares analytics of a page.
   */
  fetchPageAnalytics(pageId: string, filters: PartialData = {}): Observable<PageAnalytics> {
    return this.api.fetchPageAnalytics(pageId, filters)
      .pipe(
        catchError(e => of({ totalMembers: 0, totalLocations: 0, totalProducts: 0 }))
      );
  }

  /**
   * Fetches coverage analytics of a page.
   */
  fetchPageCoverageAnalytics(pageId: string, groupBy: string): Observable<ApiResponseCommonListData> {
    return this.api.fetchPageCoverageAnalytics(pageId, groupBy);
  }

  /**
   * Fetches shares analytics of a page.
   */
  fetchSharesAnalytics(pageId: string): Observable<PageShareAnalytics> {
    return this.api.fetchSharesAnalytics(pageId)
      .pipe(
        catchError(e => of({ totalShares: 0 }))
      );
  }

  /**
   * Fetches qa analytics of a page.
   */
  fetchQaAnalytics(pageId: string): Observable<PageQaAnalytics> {
    return this.api.fetchQaAnalytics(pageId)
      .pipe(
        catchError(e => of({ totalQuestions: 0 }))
      );
  }

  /**
   * Fetches surveys analytics of a page.
   */
  fetchSurveyAnalytics(pageId: string): Observable<any> {
    return this.api.fetchSurveyAnalytics(pageId);
  }

  /**
   * Fetches projects analytics of a page.
   */
  fetchProjectsAnalytics(pageId: string): Observable<PageProjectAnalytics> {
    return this.api.fetchProjectsAnalytics(pageId);
  }

  /**
   * Fetches tasks analytics of a page.
   */
  fetchTasksAnalytics(pageId: string): Observable<PageTaskAnalytics> {

    return this.api.fetchTasksAnalytics(pageId);
  }

  /**
   * Fetches projects analytics of a page.
   */
  fetchDigitalAssistAnalytics(pageId: string): Observable<PageDigitalAssistAnalytics> {
    return this.api.fetchPageDaAnalytics(pageId);
  }

}
