import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { KalgudiMobileDialogRef } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSms } from '@kalgudi/types';

import { KalgudiSmsStreamStateService } from '../../services/kalgudi-sms-stream-state.service';

@Component({
  selector: 'kl-mobile-publish-sms',
  templateUrl: './mobile-publish-sms.component.html',
  styleUrls: ['./mobile-publish-sms.component.scss']
})
export class MobilePublishSmsComponent implements OnInit {

  @Output()
  smsSent = new EventEmitter<KalgudiSms>();


  constructor(
    protected smsStreamState: KalgudiSmsStreamStateService,
    private dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification
  ) { }

  ngOnInit() {
  }

  onSmsSent(sms: KalgudiSms): void {
    this.smsSent.emit(sms);
    if (sms && !sms.scheduledTS) {
      this.pushItemToHomeStream(sms);
    } else {
      this.notifications.showMessage(`SMS scheduled`);
    }
    this.dialogRef.close();
  }

  /**
   * Pushes latest posted item to the stream
   * @param sms
   */
  private pushItemToHomeStream(sms: KalgudiSms): void {
    this.smsStreamState.unshiftToStream(sms);
  }

}
