import { Pipe, PipeTransform } from '@angular/core';
import * as convert_ from "convert-units";

const convert = convert_;

@Pipe({
  name: 'unitsConverter'
})
export class UnitsConverterPipe implements PipeTransform {

  transform(weightValue, product: any, storeName: string) {

    let availableQty: any = {};
    let weight = product.smartElements.weight;

    if (weight.enabled) {

      if (weight.unit != null) {
        // Actual qty
        let value = storeName !== 'OUTPUTS_STORE' ? +weight.value * +product.productQuantity : +product.productQuantity;
        if (weight.unit.toLowerCase() == 'liters') {
          availableQty.value = value;
          availableQty.unit = 'liters'
        } else if (weight.unit.toLowerCase() == 'pieces') {
          availableQty.value = +product.productQuantity;
          availableQty.unit = weight.unit;
        } else {
          availableQty.value = this.convertMethod(value, weight.unit, 'ton');
          availableQty.unit = 'ton';
        }
      }
    } else {
      availableQty.value = +product.productQuantity;
      availableQty.unit = 'Nos';
    }

    let finalUnit = availableQty.unit.includes('s') ? availableQty.unit.replace('s', '(s)') : availableQty.unit + '(s)';

    finalUnit = finalUnit.charAt(0).toUpperCase() + finalUnit.slice(1);

    return `${availableQty.value} ${finalUnit}`;
  }

  /**
   * Covert value and unit
   * @param value
   * @param fromUnit
   * @param toUnit
   * @returns
   */
  convertMethod(value, fromUnit, toUnit) {
    var convertFromUnit :any='';
    var convertToUnit :any= '';
    switch (fromUnit.toLowerCase()) {
      // grams, Mililitter, liter, kg , ton, quintal

      case 'grams': convertFromUnit = 'g';
      break;
      case 'kgs':
      case 'kg' : convertFromUnit = 'kg';
      break;
      case 'tons' :
      case 'ton' : convertFromUnit = 'mt';
      break;
      case 'quintals':
      case 'quintol':
      case 'quintal' : convertFromUnit = 'kg';
      value = value * 100;

      break;
    }

    switch (toUnit.toLowerCase()) {
      // grams, Mililitter, liter, kg , ton, quintal
      case 'grams': convertToUnit = 'g';
      break;
      case 'kgs':
      case 'kg' : convertToUnit = 'kg';
      break;
      case 'tons' :
      case 'ton' : convertToUnit = 'mt';
      break;
      case 'quintals':
      case 'quintal' : convertToUnit = 'kg';
      break;
    }

    var result = convert(value).from(convertFromUnit).to(convertToUnit);

    if (toUnit.toLowerCase() === 'quintal' || toUnit.toLowerCase() === 'quintals') {
      result /= 100;
    }

    return result;
  }
}
