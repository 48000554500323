
<mat-card id="kalgudi-share-update-form" class="">
  <form autocomplete="off" [formGroup]="shareForm" name="shareForm">
    <div class="share-update-form" formGroupName="logDetails">

      <!-- Form field -->
      <div fxLayout="row" class="text-area-wrapper">
        <mat-form-field appearance="outline" class="update-form w-100">
          <textarea id="share-text" name="message" matInput [placeholder]="'Write your log here' | translate" [rows]="rowsCount" formControlName="message"></textarea>
        </mat-form-field>
      </div>
      <!-- / Form field -->

      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url">
        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">
        </kl-post-tile-link-attachment>
      </div>
      <!-- / url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->

      <!-- Selected products -->
      <div class="my-2" *ngIf="selectedProducts?.value">
        <p class="text-secondary mt-2" *ngIf="isMobileDevice && selectedProducts?.value?.length">Suggested products</p>
        <kl-selected-store-products [selectedProducts]="selectedProducts.value" (remove)="removeProduct($event)"></kl-selected-store-products>
      </div>
      <!-- /Selected products -->

      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()" fxLayoutAlign="center center">
            <div class="audio-icon">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>
            <div class="ml-1">Voice message attached</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->

      <!-- Attachment buttons and post button -->
      <div class="mt-n1" fxLayoutGap="20px" fxLayoutGap.xs="0px"
        fxLayoutAlign="space-between center">

        <div fxLayout="row">
          <div>
            <button mat-button
              class="attach-files"
              id="attach-files-button"
              color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>attachment</mat-icon>
              <span class="ml-1" translate>Attach</span>
            </button>

            <!-- Attachments menu -->
            <mat-menu #menu="matMenu" class="attach-file">
              <ng-container>

                <!-- Images attachments section -->
                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Image</p>
                    </div>

                    <div fxLayout="row">
                      <div>
                        <kl-camera-form-control
                          fxFlex="95%"
                          [formControl]="cameraAttachment"
                          [showChipButton]="true"
                          [showIconButton]="true"
                          [showBorder]="false"
                          buttonIcon="camera_alt"
                          buttonLabel="Camera"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-camera-form-control>
                      </div>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="attachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>
                <!--/ Images attachments section -->

                <!-- Audio attachment section -->
                <!-- <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Voice</p>
                    </div>

                    <div fxLayout="row">
                      <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                        class="mt-1" [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                        [matTooltip]="'Attach audio' | translate"
                        (click)="showAudioDialog()"
                        mat-icon-button>
                        <div class="label-icon" fxLayout fxLayoutAlign="center center">
                          <mat-icon class="m-0 camera-icon">mic</mat-icon>
                        </div>
                      </button>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="attachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedAudioFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div> -->
                <!--/ Audio attachment section -->

                <!-- Document attachment section -->
                <!-- <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                    <div>
                      <p class="m-0 title" translate>File</p>
                    </div>

                    <div fxLayout="row">

                      <kl-image-picker-form-control
                        formControlName="attachments"
                        [showBorder]="false"
                        [buttonType]="'mat-icon-button'"
                        svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                        [allowMultiple]="true"
                        [showAttachments]="false"
                        [s3Category]="s3Category"
                        [acceptedFileTypes]="acceptedFileTypes">
                      </kl-image-picker-form-control>
                    </div>
                  </div>
                </div> -->
                <!--/ Document attachment section -->

                <!-- Video attachments section -->
                <!-- <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Video</p>
                    </div>

                    <div fxLayout="row">
                      <div class="mt-1">
                        <kl-attach-url-form-control
                          fxFlex="100%"
                          [showBorder]="false"
                          [showChipButton]="false"
                          svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                          [formControl]="urlFormControl"
                          buttonType="mat-icon-button">
                        </kl-attach-url-form-control>
                      </div>

                    </div>
                  </div>
                </div> -->
                <!--/ Video attachments section -->

                <!-- Product attachment section -->
                <!-- <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                    <div>
                      <p class="m-0 title" translate>Product</p>
                    </div>

                    <div fxLayout="row">

                      <kl-store-products-form-control
                        buttonType="mat-icon-button"
                        buttonLabel="Product"
                        formControlName="productsMetaData">
                      </kl-store-products-form-control>
                    </div>
                  </div>
                </div> -->
                <!--/ Product attachment section -->
              </ng-container>
            </mat-menu>
            <!--/ Attachments menu -->
          </div>

        </div>

        <!-- Post button -->
        <div [ngClass]="{'send-btn-container': !progress}" class="share-button" fxLayout fxLayoutAlign="end end">
          <div class="mb-n1" [ngClass]="{'w-100 mt-2': isMobileDevice}">
            <kl-flat-button
              class="submit-btn"
              label="Save"
              [disabled]="!shareFormText.value && !audioAttachment?.value?.url"
              [progress]="progress"
              (clicked)="createPost()">
            </kl-flat-button>

          </div>
        </div>
        <!--/ Post button -->
      </div>
      <!--/ Attachment buttons and post button -->
    </div>
  </form>
</mat-card>

