import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { KALGUDI_S3_POLICY_MAP, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { S3PolicyPathCategoryMap } from '@kalgudi/types';
import { filter, finalize } from 'rxjs/operators';
import { KalgudiAudioAttachmentFormControl } from '../../classes/kalgudi-audio-attachment-form-control';
import Swal from 'sweetalert2';
import { NavigationEnd, Router } from '@angular/router';

/**
 * `Referenced from https://stackblitz.com/edit/media-recorder-hvccdr?file=src%2Fapp%2Fapp.component.html`
 */

declare var MediaRecorder: any;


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AudioAttachmnetFormControlComponent),
  multi: true,
};

@Component({
  selector: 'kl-audio-attachmnet-form-control',
  templateUrl: './audio-attachmnet-form-control.component.html',
  styleUrls: ['./audio-attachmnet-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class AudioAttachmnetFormControlComponent extends KalgudiAudioAttachmentFormControl implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  @Input()
  label: string;

  @Input()
  url: string;

  @Input()
  columnButton: boolean;

  @Input()
  isFpoRobocall: boolean;

  // Flag to toggle the recording status
  isRecording: boolean;

  mediaRecorder: any;
  chunks = [];

  localStream: any;
  timerId: any;
  uploadRecording: boolean;

  minutes = 0;
  seconds = 1;
  progress: boolean;
  isPermissionEnabled: boolean;

  showWarnMsg: boolean;
  attachedFileSize: number;
  isRoboCallPage: Boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    private dom: DomSanitizer,
    protected uploadImageTos3: KalgudiUploadService,
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    private util: KalgudiUtilityService,
    private appRef: ApplicationRef,
    public router: Router,
  ) {

    super();

    if (this.router) {
      this.isRoboCallPage = this.router.routerState.snapshot.url.includes('/robocall');
    }

  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.initMedia();

    this.cd.detectChanges();


    if (this.url) {
      this.audioUrl.patchValue(this.url);
    }

    setTimeout(() => {
      this.showWarnMsg = true;
    }, 10000);
  }

  ngOnDestroy() {
    if (this.isPermissionEnabled) {
      this.localStream.getTracks()[0].stop();
    }
  }

  onDestroyed(): void { }

  /**
   * Initializes the stream
   */
  initMedia(): void {

    navigator.getUserMedia(
      { audio: true },
      stream => {
        this.isPermissionEnabled = true;

        this.localStream = stream;
        this.mediaRecorder = new MediaRecorder(stream);
        let audioType: any = this.isFpoRobocall ? 'audio/wav' : 'audio/ogg';
        this.mediaRecorder.onstop = e => {
          const blob = new Blob(this.chunks, { type: audioType });
          this.chunks = [];
          this.isRecording = false;

          this.getElapsedTime();

          if (this.uploadRecording) {
            let latestDate: any = this.isFpoRobocall ? `${new Date().getTime()}` : `qa${new Date().getTime()}`;

            const file = this.blobToFile(blob, latestDate);
            this.attachedFileSize = +(file?.size / 1e6).toFixed(2);

            if (this.isRoboCallPage && this.attachedFileSize > 5.0) {
              Swal.fire({
                titleText: 'Maximum file size limit is 5 MB',
                icon: 'warning',
              })
            } else {
              this.uploadRecord(file);
            }
          } else {
            this.reset();
          }


          // this.cd.detectChanges();
        };
        this.mediaRecorder.ondataavailable = e => {
          this.chunks.push(e.data);
        };
      },
      () => {

        this.util.showMessage('Please grant permission to record audio');

        this.isPermissionEnabled = false;
      },
    );
  }


  /**
   * Uploads the recording to the s3
   */
  uploadRecord(file: File): void {
    this.uploadImageTos3.uploadFile(file, this.s3Category, false, `${file.name}.${file.type.split('/').pop()}`)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => {

          // this.notification.hideSpinner();
          const filePath = this.isRoboCallPage ? `https://kalgudi.com/data/activity/share-a-thought/robocalltempfolder/${file.name}.${file.type.split('/').pop()}` : res.filePath;

          this.audioUrl.patchValue(filePath);

          this.reset();

        },
        err => {
          this.progress = false;
          this.notification.hideSpinner();
          this.util.errorHandler('Something went wrong');
        }
      );
  }

  /**
   * Starts the recording
   */
  startRecording() {
    this.mediaRecorder.start();
    this.audioUrl.patchValue(null);

    this.isRecording = true;
    this.getElapsedTime();
  }

  /**
   * Stops the recording and uploads to s3
   */
  stopRecording() {
    this.progress = true;
    this.uploadRecording = true;
    this.notification.showSpinner();
    this.mediaRecorder.stop();

  }

  /**
   * Cancels the recording
   */
  cancelRecording(): void {
    this.uploadRecording = false;
    // this.notification.showSpinner();
    this.reset();

    this.mediaRecorder.stop();
  }

  /**
   * Removes the uploaded recording
   */
  clearRecord(): void {
    this.audioUrl.patchValue(null);
  }

  /**
   * Returns the minutes and seconds when the timer starts
   */
  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  /**
   * Converting the blob to the file format
   */
  private blobToFile = (blob: Blob, fileName: string): File => {

    const b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;

    //Cast to a File() type
    return blob as File;
  }


  /**
   * sets time interval to display how may the recording time
   */
  private getElapsedTime() {

    let milliSecond = 0;

    if (this.isRecording) {
      // Stop => Running

      this.timerId = setInterval(() => {
        milliSecond++;

        if (milliSecond >= 100) {
          this.seconds++;
          milliSecond = 0;
        }
        if (this.seconds >= 60) {
          this.minutes++;
          this.seconds = 0;
        }
      }, 10);
    } else {
      clearInterval(this.timerId);
    }
  }

  /**
   * Resets all the flags and variables
   */
  private reset(): void {

    this.isRecording = false;
    this.minutes = 0;
    this.seconds = 1;
    this.progress = false;
    this.notification.hideSpinner();
    this.appRef.tick();
  }
}
