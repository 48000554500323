import { Directive, Inject, Injector } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSocial, SocialDataNormalizerService } from '@kalgudi/social';
import { Attachment, ShareQaFullDetails, ShareQaRequest } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { KalgudiQaService } from './services/kalgudi-qa.service';

/**
 * Base class definition for all Kalgudi social question answer modules.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSocialQA<T extends ShareQaRequest, R extends ShareQaFullDetails> extends KalgudiSocial<T, R> {

  shareForm: FormGroup;
  isLoggedIn: boolean;

  audioAttachment = new FormControl(null);
  page: any;

  public environment: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp
    );

    this.environment = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.isLoggedIn = this.kalgudiApp.loggedIn;

    // Initialize form
    this.shareForm = this.qaForm;

    this.attachmentsFormControl.valueChanges
    .pipe( takeUntil(this.destroyed$))
    .subscribe(v => this.attachmentsFormControl.setValue(v, { onlySelf: true, emitEvent: false }));

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Question answers common form
   */
  private get qaForm(): FormGroup {

    return new FormGroup({
      authorId: new FormControl(this.isLoggedIn ? this.kalgudiApp.profileLocal.profileKey : '', Validators.required),
      text: new FormControl(''),
      groupName: new FormControl(this.DEFAULT_SHARE_VISIBILITY, Validators.required),
      imageURL: new FormControl(''),
      type: new FormControl('question'),
      questionType: new FormControl(this.DEFAULT_QUESTION_TYPE, Validators.required),
      filter: new FormGroup({}),

      lstOfAttachments: new FormControl([]),
    });
  }

  /**
   * Share form text field.
   */
  get shareFormText(): AbstractControl {
    return this.shareForm.get('text');
  }

  get attachmentsFormControl(): AbstractControl {
    return this.shareForm.get('lstOfAttachments');
  }

  /**
   * Share form Uri domain field.
   */
  get shareFormAttachments(): FormArray {
    return this.shareForm.get('lstOfAttachments') as FormArray;
  }

  /**
   * Share form attachments form array controls
   */
  get shareFormAttachmentsControls(): FormArray {
    return this.shareForm.controls.lstOfAttachments as FormArray;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Calls API to share the current post.
   */
  createPostApi(payload: ShareQaRequest): Observable<R> {

    // Call api to share post
    return this.qaService.postQuestion(payload)
      .pipe(

        // Map response to generic type
        map(r => r as R)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the share update API request payload.
   */
  protected getCreationPayload(shareFormValue: any): T {

    const audio: Attachment = this.audioAttachment.value;

    if (audio && audio.url) {
      shareFormValue.lstOfAttachments.push(audio);

      shareFormValue.text = shareFormValue.text ? shareFormValue.text : 'Audio question';
    }

    // To show asked question inside group -> Q&A
    if((this.environment.appId === 'FPO_APP' || this.environment.appId === 'FARMERS_APP' || this.environment.appId === 'UBM' || this.environment.appId === 'SAM_FPO' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'ORMAS_SHG' || this.environment.appId === 'SHG_NET_APP') && this.page) {
      shareFormValue.entityId = this.page.pageId;
      shareFormValue.entityName = this.page.pageTitle;
      shareFormValue.filter = {
        users:  null,
        products: [],
        businessTypes: [],
        locations: [],
        countries: [],
        states: [],
        districts: [],
        pageId: this.page.pageId,
        pageTitle: this.page.pageTitle,
        pageType: this.page.pageType,
        pageUrl: this.page.pageProfilePic,
        parentPageId: this.page.pageId,
        parentPageTitle: this.page.pageTitle ,
        parentPageUrl:  this.page.pageProfilePic,
        isSms: false
      };
      shareFormValue.isAdmin = true;
      shareFormValue.isAuthor = true;
      shareFormValue.isPublishedThroughTool = true;
      shareFormValue.msgTypes = [];
      shareFormValue.productsMetaData = [];
      shareFormValue.recipient = "ALL_SUBSCRIBERS"
    }

    return shareFormValue;
  }

  /**
   * Resets kalgudi social sharing form.
   */
  protected resetForm(): void {
    this.shareForm.reset();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
