import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiSurveyDetails,
  PartialData,
  SurveyActivityLogDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { KalgudiSurveyService } from '../../../services/kalgudi-survey.service';
import { SurveyStateService } from '../../../services/survey-state.service';
import { KalgudiSurveySubmissionService } from '../../survey-submission/services/kalgudi-survey-submission.service';


@Directive()
export abstract class KalgudiSurveyLogs extends KalgudiInboxStream<SurveyActivityLogDetails> {

  surveyDetails: KalgudiSurveyDetails;

  // Dependencies
  protected surveyService: KalgudiSurveyService;
  protected surveyState: SurveyStateService;
  private surveySubmissionService: KalgudiSurveySubmissionService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    // Manually inject dependencies
    this.surveyState             = this.injector.get(SurveyStateService);
    this.surveyService           = this.injector.get(KalgudiSurveyService);
    this.surveySubmissionService = this.injector.get(KalgudiSurveySubmissionService);

    this.pageLimit = 100;
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Open survey submission dialog
   */
  openSubmission(activityDetails: SurveyActivityLogDetails): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: `${activityDetails.activity.createdBy.firstName}'s submission `,
      acceptButtonTitle: 'Submit',
      rejectButtonTitle: 'Cancel',
      data: {
        userProfileKey: activityDetails.activity.createdBy.profileKey,
        surveyId: this.surveyDetails.surveyId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '70vw',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.surveySubmissionService.openSurveySubmissionDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(res => res.accepted),

      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Initializes the survey activity logs stream
   *
   * @override
   */
  protected initStream() {

    // Subscribes to state action changes
    this.subscribeToStateActions();

    // Initialize survey details
    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdated(survey));

    super.initStream();
  }

  protected streamApi(
    offset: number,
    limit: number,
    extraParams?: PartialData
  ): Observable<KalgudiStreamData> {

    return this.surveyService.getActivityLogs(this.surveyDetails.surveyId, offset, limit)
      .pipe(
        map(res => ({ items: res.results, count: res.count })),
      );
  }


  /**
   * Subscribes to survey state action updates and calls the necessary methods.
   */
  private subscribeToStateActions(): void {

    // Update the logs list on survey details update
    this.surveyState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === SurveyPageActions.SURVEY_UPDATED)
      )
      .subscribe(action => {
        this.resetStream()
      });
  }

  /**
   * Event handler gets called on the survey details update in the
   * state service
   */
  private onSurveyStateUpdated(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
