import { Injectable } from '@angular/core';
import { IPageStateManager } from '@kalgudi/core';
import { KalgudiSurveyDetails } from '@kalgudi/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SurveyPageActions } from '../constants';

@Injectable()
export class SurveyStateService implements IPageStateManager<KalgudiSurveyDetails, SurveyPageActions> {

  readonly data$: Observable<KalgudiSurveyDetails>;
  readonly id$: Observable<string>;
  readonly action$: Observable<{ type: SurveyPageActions; payload?: any; }>;

  private pageDataSubject = new BehaviorSubject<KalgudiSurveyDetails>(null);
  private actionSubject   = new Subject<{ type: SurveyPageActions; payload?: any; }>();

  constructor() {
    this.action$ = this.actionSubject.asObservable();

    this.data$ = this.pageDataSubject.pipe(filter(val => val !== null));
    this.id$   = this.data$.pipe(map(val => val.surveyId));

  }

  get data(): KalgudiSurveyDetails {
    return this.pageDataSubject.getValue();
  }

  get id(): string {
    return this.data ? this.data.surveyId : null;
  }

  dispatchAction(action: SurveyPageActions, payload?: any): void {
    this.actionSubject.next({ type: action, payload });

    if (action === 'SURVEY_UPDATED' && payload) {
      this.pageDataSubject.next(payload);
    }
  }
}
