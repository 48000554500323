import { Component, Injector, OnInit } from '@angular/core';

import { ShareActivityFilters } from './share-activity-filters';


@Component({
  selector: 'kl-share-activity-filters',
  templateUrl: './share-activity-filters.component.html',
  styleUrls: ['./share-activity-filters.component.scss']
})
export class ShareActivityFiltersComponent extends ShareActivityFilters implements OnInit {

  constructor(protected injector: Injector,
    ) {
    super(injector)
  }

  ngOnInit() {

    if(this.taskId || this.projectId) {
      this.updateTaskAndProjectIdFilters(this.projectId, this.taskId)
    } else if(this.pageId) {
      this.updatePageIdFilters(this.pageId);
    }
  }

}
