import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiWarehouseGodown } from '../../../classes/kalgudi-warehouse-godown';

@Component({
  selector: 'kl-warehouse-godown-tile',
  templateUrl: './warehouse-godown-tile.component.html',
  styleUrls: ['./warehouse-godown-tile.component.scss']
})
export class WarehouseGodownTileComponent extends KalgudiWarehouseGodown implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit() {

  }

  onDestroyed(): void {}

}
