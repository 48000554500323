import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiStoreProduct } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { StoreProductsSearchService } from '../services/store-products-search.service';

@Directive()
export abstract class KalgudiStoreProducts extends KalgudiSearchStream<KalgudiStoreProduct> {

  @Input()
  storeType: string = '';

  private productsSearchService: StoreProductsSearchService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.productsSearchService = this.injector.get(StoreProductsSearchService);

    this.pageLimit = 50;
  }


  /**
   * Fetches the stream
   * @param searchKeyword
   * @param offset
   * @param limit
   */
  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (searchKeyword) {
      return this.productsSearchService.searchProducts(searchKeyword, offset, limit, this.storeType);
    } else {

      return this.productsSearchService.getProducts(offset, limit, this.storeType);
    }
  }


}
