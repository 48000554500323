import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { checkMobileDevice, KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { Attachment, FileMimeTypes, KalgudiMessageDetails, S3PolicyPathCategoryMap } from '@kalgudi/types';

@Component({
  selector: 'kl-chat-footer',
  templateUrl: './chat-footer.component.html',
  styleUrls: ['./chat-footer.component.scss']
})
export class ChatFooterComponent implements OnInit, OnChanges {

  @Input()
  replyMessage: KalgudiMessageDetails;

  @Input()
  latestMessage: KalgudiMessageDetails;

  @Input()
  messageProgress: boolean;

  @Output()
  showReplyForm = new EventEmitter();

  @Output()
  send = new EventEmitter();

  chatForm: FormGroup;

  progress: boolean;

  isMobileDevice: boolean;

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE, FileMimeTypes.DOCUMENT ];

  rowsCount: number;

  showBtns: boolean;

  constructor() {

    this.isMobileDevice = checkMobileDevice();

  }

  ngOnInit() {
    this.showBtns = false;
    this.rowsCount = 1;
    this.chatForm = this.qaChatForm;
  }

  ngOnChanges() {
    this.progress = this.messageProgress;
    if (this.latestMessage) {
      this.resetForm();
    }

  }

  get attachmentsArray(): Attachment[] {

    return this.chatForm.get('attachments').value && this.chatForm.get('attachments').value.length
      ? this.chatForm.get('attachments').value
      : [];
  }

  get selectedProducts(): any[] {

    return this.chatForm.get('productsMetaData').value && this.chatForm.get('productsMetaData').value.length
      ? this.chatForm.get('productsMetaData').value
      : [];
  }

  expandBox(): void {
    this.rowsCount = 2;
    this.showBtns = true;
    this.showReplyForm.emit(this.showBtns);

  }

  /**
   * Removes the selected image
   */
  removeAttachment(index): void {
    this.attachmentsArray.splice(index, 1);
  }

  removeProduct(index): void {
    this.selectedProducts.splice(index, 1);
  }

  /**
   * Removes the reply message
   */
  removeReplyMsg(): void {
    this.replyMessage = null;
  }
  /**
   * Show reply box
   */
  showReplyBox() {
    this.showReplyForm.emit(this.showBtns);
  }

  /**
   * form value will emits back the parent
   */
  sendMessage(): void {


    if (this.replyMessage && this.replyMessage.message) {

      this.chatForm.get('replyToMsgData').patchValue(this.replyMessage);
      this.chatForm.get('replyToMsgId').patchValue(this.replyMessage.messageId);
    }

    this.send.emit(this.chatForm.value);

    this.progress = true;
  }

  /**
   * Share update common form
   */
  private get qaChatForm(): FormGroup {

    return new FormGroup({
      message: new FormControl(''),
      attachments: new FormControl([]),
      productsMetaData: new FormControl([]),
      replyToMsgData: new FormControl(),
      replyToMsgId: new FormControl()
    });
  }

  private resetForm(): void {
    this.chatForm.reset();
    this.chatForm.get('attachments').patchValue([]);
    this.chatForm.get('productsMetaData').patchValue([]);
    this.showBtns = false;
    this.replyMessage = null;
    this.rowsCount = 1;
    this.latestMessage = null;

  }


}
