import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiStoreProduct } from '@kalgudi/types';

@Component({
  selector: 'kl-share-update-products',
  templateUrl: './share-update-products.component.html',
  styleUrls: ['./share-update-products.component.scss']
})
export class ShareUpdateProductsComponent implements OnInit {

  @ViewChild('widgetsContent', { read: ElementRef, static: true }) public widgetsContent: ElementRef<any>;

  @Input()
  store = '';

  @Input()
  shareProducts: KalgudiStoreProduct[];
  maxLength: number;

  constructor() { }

  ngOnInit() {

    this.maxLength = checkMobileDevice() ? 2 : 3;
  }

  scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
  }

  scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
  }

}
