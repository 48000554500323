<section class="page-stats-wrapper my-4"
  fxLayout="row"  fxLayoutAlign="center start" fxLayoutGap="16px"
  [ngClass]="{'selectable': enableAction}">

  <!-- Mat card stats -->
  <!-- <ng-container *ngIf="cardType === statsCardType.CARD">
    <mat-card></mat-card>
  </ng-container> -->
  <!-- / Mat card stats -->


  <!-- Chips stats -->
  <ng-container *ngIf="cardType === statsCardType.CHIP || cardType === statsCardType.CARD">
    <mat-card>
      <mat-chip-list>


        <mat-chip @zoomOut
          *ngFor="let stat of stats; let index=index;"
          class="flat-corners light-chip"
          [ngClass]="{
            'selected': selectedStat?.key === stat?.key,
            'selectable': stat?.clickAction,
            'disabled-stat': !stat?.clickAction
          }"
          color="primary"
          [selectable]="false"
          [disableRipple]="!stat?.clickAction">
          <a *ngIf="stat?.clickAction" (click)="onStatsClicked(stat)">
            <ng-container  *ngTemplateOutlet="statsTemplate"></ng-container>
          </a>
          <span *ngIf="!stat?.clickAction">
            <ng-container  *ngTemplateOutlet="statsTemplate"></ng-container>
          </span>

          <ng-template #statsTemplate>
            <span>{{stat?.total | number }} </span>
            <span class="text-capitalize">{{stat?.title}}</span>
          </ng-template>

        </mat-chip>
      </mat-chip-list>
    </mat-card>
  </ng-container>
  <!-- / Chips stats -->
</section>
