import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiLocation, WeatherDetails } from '@kalgudi/types';

import { weatherIconCodes, weatherTime } from '../../constants';

@Component({
  selector: 'kl-weather-details',
  templateUrl: './weather-details.component.html',
  styleUrls: ['./weather-details.component.scss']
})
export class WeatherDetailsComponent implements OnInit {

  @Input()
  todaysWeather: WeatherDetails;

  @Input()
  weatherDetails: WeatherDetails[];

  @Input()
  location: KalgudiLocation;

  @Input()
  weatherUpdates = [];

  @Input()
  showFavorite: boolean;

  @Input()
  isFavoriteLocation: boolean;

  @Output()
  toFavoriteLocations = new EventEmitter<KalgudiLocation>();

  weatherTime = weatherTime;

  weatherCodes = weatherIconCodes;


  constructor() { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to add it in favorite location
   */
  addToFavoriteLocations(location: KalgudiLocation) {

    this.toFavoriteLocations.emit(location);
  }
}
