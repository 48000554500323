import { AfterViewInit, Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsSolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

@Component({
  selector: 'profile-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss'],
})
export class GaugeChartComponent implements AfterViewInit {
  @Input() title: string;

  @Input() score: number;

  @Input() variant: 'big-chart' | 'small-chart' = 'small-chart';

  constructor() {}

  public ngAfterViewInit(): void {
    this.createChartGauge();
  }

  private createChartGauge(): void {
    const chart = Highcharts.chart(this.title + '-chart', {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      pane: {
        startAngle: -115,
        endAngle: 115,
        background: null,
        center: ['50%', '75%'],
        size: '110%',
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
          distance: 20,
          style: {
            fontSize: '0.875rem',
          },
        },
        lineWidth: 0,
        plotBands: [
          {
            from: 0,
            to: 20,
            color: '#e84f42', // Dark orange
            thickness: 20,
          },
          {
            from: 20,
            to: 50,
            color: '#f2964c', // Light orange
            thickness: 20,
          },
          {
            from: 50,
            to: 70,
            color: '#fdc84b', // Yellow
            thickness: 20,
          },
          {
            from: 70,
            to: 90,
            color: '#9ac24b', // Light green
            thickness: 20,
          },
          {
            from: 90,
            to: 100,
            color: '#7da744', // Dark green
            thickness: 20,
          },
        ],
      },

      // plotOptions: {
      //   solidgauge: {
      //     dataLabels: {
      //       y: -25,
      //       borderWidth: 0,
      //       useHTML: true,
      //     },
      //   },
      // },
      // tooltip: {
      //   enabled: false,
      // },
      series: [
        {
          name: 'Score: ',
          data: [0],
          // tooltip: {
          //   valueSuffix: " km/h",
          //   valuePrefix: "Score: ",
          // },
          dataLabels: {
            // format: "{y} km/h",
            borderWidth: 0,
            color: Highcharts.defaultOptions.title?.style?.color ?? '#333333',
            style: {
              fontSize: '1rem',
            },
          },
          // Pointer
          dial: {
            radius: '80%',
            backgroundColor: '#0c8c5b',
            baseWidth: 4,
            baseLength: '0%',
            rearLength: '0%',
          },
          // Center ball of pointer
          pivot: {
            backgroundColor: '#0c8c5b',
            radius: 10,
          },
        },
      ],
    } as any);

    setTimeout(() => {
      chart.series[0].points[0].update(this.score);
    }, 500);
  }
}
