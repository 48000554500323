<div id="update-stream-header" class="my-2" [@fadeIn]>
  <mat-card class="px-3 py-2">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayout.xs="column">

      <!--user Details-->
      <div fxFlex="60%" fxLayout="row" [ngClass]="{'border-right': environment.appId !== 'SAM_FPO'}" fxLayoutGap="8px" fxLayoutGap.lt-md="15px">
        <!-- Profile pic -->
        <div fxFlex="25%" fxFlex.lt-md="30%" id="profile-pic">
          <img class="profile-pic"
            [src]="(user?.profilePicUrl | resizeImage: 'md') | prefixDomain"
            [klErrorImages]="[
              (user?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
          />
        </div>

        <!-- user Profile details  -->
        <div fxLayout="column" fxLayoutGap="5px" fxFlex="75%" fxFlex.lt-md="70%">
          <p class="mb-0 name cursor-pointer" (click)="viewProfile(user?.profileKey)">{{user?.firstName | titlecase}}</p>
          <p class="mb-0 business-type text-truncate">{{(user?.additionalBizDetails?.businessTypeName | translate) || (user?.lstOfUserBusinessDetailsInfo[0]?.businessTypeName | translate)}}</p>
          <p class="mb-0 location">{{user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong}}</p>
          <p class="mb-0 business-name">{{user?.additionalBizDetails?.profileTypeDetails?.adminDetails?.businessName}}</p>
        </div>

      </div>
      <!-- /user Details -->

      <!-- Web Notification Details -->

      <div fxFlex = "40%" fxLayout="column" fxLayoutGap="10px" class="border-left" class=" pl-2 w-100" *ngIf="(environment?.appId !== 'SAM_FPO')">
        <div [routerLink]="viewProfilePage" routerLinkActive="active" id="profile-views-count"
          fxLayout="row" fxLayoutGap="4px" fxLayoutGap.lt-md="8px" class="web-notification networks cursor-pointer" fxLayoutAlign="start center">
          <div fxFlex="10%" fxFlex.lt-md="12%">
            <p class="count mb-0">{{ profileViewsCount | modernNumber }}</p>
          </div>
          <p  class="count-data mb-0" translate>Viewed your profile</p>
        </div>
        <div class="border-bottom" *ngIf="(environment?.appId !== 'UBM' || environment?.appId !== 'KHETIGHAR')"></div>
        <div fxLayout="row" fxLayoutAlign="start center" id="connects-count" class="web-notification" fxLayoutGap="4px" fxLayoutGap.lt-md="8px" *ngIf="(environment?.appId !== 'UBM' || environment?.appId !== 'KHETIGHAR')">
          <div fxFlex="10%" fxFlex.lt-md="12%">
              <p class="count mb-0">{{ connectsCount | modernNumber}}</p>
          </div>
          <div>
              <p class="mb-0">
                <span class="count-data" translate>Connections</span>
                <!-- <span class="notifi-details"><a> Grow your network</a></span> -->
              </p>
          </div>
        </div>
      </div>
      <!-- web notification details -->

    </div>
  </mat-card>
</div>
