<div id="kl-surveys-members-stream">

  <div>
    <div class="stream-section" fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="12px grid" fxLayoutAlign="space-between center"
      *ngIf="(stream$ | async) as members">

      <ng-container *ngIf="members?.length">

        <!-- Members list -->
        <div class="user-list-wrapper" fxFlex="50%" fxFlex.xs="100%"
          *ngFor="let user of members; let i = index;">
          <div class="user-list" [@floatTop]="{ value: '', params: {delay: (i % maxPageLimit) * 10}}">
            <kl-selectable-user-tile
              [author]="user" avatarSize="md"
              [showRemove]="memberRole === memberRoles?.ADMIN"
              (removed)="checkAndRemoveMember(user)"
              [navigationAllowed]="true">
            </kl-selectable-user-tile>
          </div>
        </div>
        <!-- /Members list -->

      </ng-container>


      <ng-container *ngIf="(paginator$ | async) as paginator">

        <!-- Load more members if members exist -->
        <div class="m-3 w-100" fxLayout="row" fxLayoutAlign="center center" *ngIf="members?.length">
          <kl-load-more
            noMoreText="No more members"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
        <!-- /Load more members if members exist -->


        <!-- No content if there is no members -->
        <div class="w-100" fxLayout="row">
          <ng-container *ngIf="!members?.length">
            <div class="no-content-center w-100" fxLayoutAlign="center center">
              <kl-no-content svgIcon="assets/svgs/contributors.svg" text="No Members added for this survey yet!"
                iconSize="md"
                actionLink="Add members"
                progressText="Loading survey members"
                [progress]="paginator?.loading"
                (action)="checkAndAddMember()">
              </kl-no-content>
            </div>
          </ng-container>
        </div>
        <!--/ No content if there is no members -->

      </ng-container>

    </div>
  </div>
</div>

<div *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
  <kl-float-button (clicked)="checkAndAddMember()"></kl-float-button>
</div>
