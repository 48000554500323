import { Injector, Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiNgxFormlyMapperService } from '@kalgudi/third-party/ngx-formly';
import { KalgudiProjectTask, TaskTemplateDetails } from '@kalgudi/types';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';

import { KalgudiProjectService } from '../../../services/kalgudi-project.service';

@Directive()
export abstract class TaskInfo {

  fields: FormlyFieldConfig[];
  options: FormlyFormOptions;
  templateForm: FormGroup;
  model: any = {};

  @Input()
  taskDetails: KalgudiProjectTask;

  private notification: KalgudiNotification;
  private projectService: KalgudiProjectService;
  private formlyJsonschema: FormlyJsonschema;
  private jsonToFormlyMapper: KalgudiNgxFormlyMapperService;

  constructor(protected injector: Injector) {

    this.notification       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.projectService     = this.injector.get(KalgudiProjectService);
    this.formlyJsonschema   = this.injector.get(FormlyJsonschema);
    this.jsonToFormlyMapper = this.injector.get(KalgudiNgxFormlyMapperService)

    this.templateForm = new FormGroup({});
  }

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the template formly json schema to fill
   */
  protected initTemplateSchema(templateId: string) {

    this.projectService.getTemplateSchema(templateId)
      .subscribe(
        res => this.initFormly(res),
        err => this.notification.showMessage('Unable to load task template.'),
      );
  }

  /**
   * Initializes the fetched schema to formly json schema
   */
  private initFormly(template: TaskTemplateDetails) {

    this.fields = [this.formlyJsonschema.toFieldConfig(template.creationSchema, {
      map: (mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig => {

        mappedField = this.jsonToFormlyMapper.mapConfiguration(mappedField, mapSource);

        mappedField.templateOptions.readonly =  true;

        // mappedField.templateOptions.disabled = this.taskDetails.userRole === this.projectRole.ADMIN;

        return mappedField;
      }
    })];
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}