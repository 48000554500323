<main class="h-100" id="create-stock">

  <h4 class="product-name">{{ productDetails?.productName }}</h4>

  <form autocomplete="off" [formGroup]="createStockForm" class="h-100" fxLayout="column" fxLayoutAlign.xs="space-between">
    <div fxLayout="column">
      <div class="farmer-picker rounded mb-3 p-1 pl-2 cursor-pointer position-relative"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="15px"
        (click)="pickFarmer()"
        [matTooltip]="'Click here to add a farmer' | translate">

        <div class="picker position-absolute w-100 rounded"></div>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">


          <!-- Add farmer details -->
          <ng-container>
            <div>
              <span class="member-picker">{{ farmerField?.value ? farmerField?.value?.firstName : 'Click to pick farmer' | translate }}</span>
            </div>
          </ng-container>
          <!-- / Add farmer details -->

        </div>

        <!-- Add button -->
        <div class="mr-n1" fxLayoutAlign="end end">
          <button mat-icon-button class="add" color="accent" [disabled]="stockId !== ''">
            <mat-icon>
              add
            </mat-icon>
          </button>
        </div>
        <!--/ Add button -->

      </div>


      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">

        <mat-form-field appearance="outline" fxFlex>
          <input matInput type="text" [placeholder]="'Price' | translate" formControlName="price">
          <span class="mt-n1" matPrefix>₹&nbsp;</span>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <input matInput type="text" [placeholder]="'Quantity' | translate" formControlName="quantityValue">
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-select [placeholder]="'Units' | translate" formControlName="quantityUnits">
            <mat-option *ngFor="let unit of units" [value]="unit">
              {{ unit | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
    </div>



    <!-- <div class="farmer-wrapper">
      <kl-user-picker [multiSelect]="false" [(selectedUsersMap)]="selectedUsersMap"></kl-user-picker>
    </div> -->

    <div class="pt-0 pr-3" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <kl-link-button
        label="{{stockId ? 'Update stock' : 'Create stock'}}"
        [disabled]="createStockForm.invalid"
        (clicked)="saveStock()">
      </kl-link-button>
    </div>
  </form>
</main>
