import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseBaseProductAutocomplete,
  ApiResponseProductVariantAutocomplete,
  BaseProductAutocomplete,
  PartialData,
  StoreProductLevel2,
  StoreType,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class BaseProductAutocompleteApiService {

  /**
   * `v2/search/common/baseProducts`
   */
  private readonly API_BASE_PRODUCT_PREDICTION = `${this.environment.restBaseUrlV2}/search/common/baseProducts`;

  /**
   * `v2/search/farmstore/listing/variants`
   */
  private readonly API_PRODUCT_VARIETY = `${this.environment.restBaseUrlV2}/search/bizstore/listing/variants`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Gets the base product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    offset: number,
    limit: number,
    vproductId = '',
    type: 'farmvproduct' | 'VARIETY' = 'farmvproduct',
    storeType: StoreType | '' = '',
    filters: PartialData = {}
  ): Observable<BaseProductAutocomplete[]> {

    const params = {
      keyword,
      offset: offset.toString(),
      limit: limit.toString(),
      type,
      vproductId,
      storeType,
      ...filters
    };

    return this.httpClient.get<ApiResponseBaseProductAutocomplete>(this.API_BASE_PRODUCT_PREDICTION, { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data),
      );
  }


  /**
   * Gets the product variety  for the specified search term.
   */
  getProductsVariety(
    text: string,
    offset: number,
    limit: number,
  ): Observable<StoreProductLevel2[]> {

    const params = {

      keyword: 'PRODUCT_NAME',
      offset: offset.toString(),
      limit: limit.toString(),
      text
    };

    return this.httpClient.get<ApiResponseProductVariantAutocomplete>(this.API_PRODUCT_VARIETY, { params })
      .pipe(
        // Handle api errors
        map(res => this.util.apiErrorHandler(res)),

        // Map the api response data
        map(res => res.data),
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
