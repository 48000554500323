<div id="common-search" class="p-0">
  <form autocomplete="off" [formGroup]="searchForm" class="form-group m-0" (submit)="onSearch($event)">

    <div fxLayout="row" class="w-100">

      <mat-form-field class="w-100" appearance="outline">

        <div class="search-input">
          <input class="input-form" matInput type="text" [placeholder]="placeHolder | translate"
            cdkFocusInitial formControlName="searchKeyword" />
        </div>

        <!-- Select field -->

        <mat-form-field class="h-100 selection search-type" matSuffix appearance="none"
          *ngIf="searchTypeList && searchTypeList?.length && !prefixSearchType"
          [ngStyle]="{'width': searchTypeWidth}"
          [ngClass]="{'border-left': prefixSearchType}">
          <mat-select
            formControlName="searchType"
            [placeholder]="searchTypePlaceHolder"
            floatPlaceholder="never"
            (selectionChange)="onTypeChange($event)">
            <mat-option *ngFor="let type of searchTypeList" [value]="type?.id">
              {{type?.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="h-100 selection search-type" matPrefix appearance="none"
          *ngIf="searchTypeList && searchTypeList?.length && prefixSearchType"
          [ngStyle]="{'width': searchTypeWidth}"
          [ngClass]="{'border-right': prefixSearchType}">
          <mat-select
            formControlName="searchType"
            [placeholder]="searchTypePlaceHolder"
            floatPlaceholder="never"
            (selectionChange)="onTypeChange($event)">
            <mat-option *ngFor="let type of searchTypeList" [value]="type?.id">
              {{type?.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- /Select field -->

        <!-- Search -->
        <button id="search-icon-btn" mat-button *ngIf="!prefixSearchButton" matSuffix mat-icon-button type="submit">
          <mat-icon class="search">search</mat-icon>
        </button>

        <button id="search-icon-btn" mat-button *ngIf="prefixSearchButton" matPrefix mat-icon-button type="submit">
          <mat-icon class="search">search</mat-icon>
        </button>
        <!-- /Search -->

      </mat-form-field>
    </div>
  </form>
</div>
