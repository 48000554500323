import { Component, Input, OnInit } from '@angular/core';
import { SMS_LOGS_STATUS, SmsLog } from '@kalgudi/types';

@Component({
  selector: 'kl-sms-logs-tile',
  templateUrl: './sms-logs-tile.component.html',
  styleUrls: ['./sms-logs-tile.component.scss']
})
export class SmsLogsTileComponent implements OnInit {

  @Input()
  smsLogs: SmsLog;

  smsLogsStatus = SMS_LOGS_STATUS;

  constructor() { }

  ngOnInit() {
  }

}
