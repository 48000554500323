import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ChildActivationEnd,
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Route,
  RouteConfigLoadEnd,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

/**
 * Defines a service for all router events.
 */
@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {

  readonly navigationStart$: Observable<NavigationStart>;
  // readonly routeConfigLoadStart$: Observable<RouteConfigLoadStart>;
  readonly routeConfigLoadEnd$: Observable<RouteConfigLoadEnd>;
  // readonly routesRecognized$: Observable<RoutesRecognized>;
  // readonly guardsCheckStart$: Observable<GuardsCheckStart>;
  // readonly childActivationStart$: Observable<ChildActivationStart>;
  // readonly activationStart$: Observable<ActivationStart>;
  // readonly guardsCheckEnd$: Observable<GuardsCheckEnd>;
  // readonly resolveStart$: Observable<ResolveStart>;
  // readonly resolveEnd$: Observable<ResolveEnd>;
  // readonly activationEnd$: Observable<ActivationEnd>;
  readonly childActivationEnd$: Observable<ChildActivationEnd>;
  readonly navigationEnd$: Observable<NavigationEnd>;
  readonly navigationCancel$: Observable<NavigationCancel>;
  readonly navigationError$: Observable<NavigationError>;
  // readonly scroll$: Observable<Scroll>;

  readonly routeData$: Observable<any>;

  readonly config: Route[];

  readonly routerEvents$: Observable<any>;


  constructor(
    private router: Router,
  ) {

    this.config = this.router.config;

    this.routerEvents$ = this.router.events;

    this.navigationStart$    = this.routerEvents$.pipe(filter(event => event instanceof NavigationStart), share());
    this.navigationEnd$      = this.routerEvents$.pipe(filter(event => event instanceof NavigationEnd), share());
    this.navigationCancel$   = this.routerEvents$.pipe(filter(event => event instanceof NavigationCancel), share());
    this.navigationError$    = this.routerEvents$.pipe(filter(event => event instanceof NavigationError), share());
    this.routeConfigLoadEnd$ = this.routerEvents$.pipe(filter(event => event instanceof RouteConfigLoadEnd), share());
    this.childActivationEnd$ = this.routerEvents$.pipe(filter(event => event instanceof ChildActivationEnd), share());

    this.routeData$ = this.initRouteData();
  }

  /**
   * Initializes router data observable.
   */
  private initRouteData(): Observable<Data> {

    return this.routerEvents$
      .pipe(

        // A route change event is fired on successful route NavigationEnd event
        filter(route => route instanceof RoutesRecognized),

        // Find the last child in the activated route
        // Last child contains the activated route data
        map((route: RoutesRecognized) => this.getLastChild(route.state.root)),

        // Return activated route data
        map((route: ActivatedRouteSnapshot) => route.data),

        share()
      );
  }


  /**
   * Gets the last child in the route tree
   */
  private getLastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {

    return route.firstChild
      ? this.getLastChild(route.firstChild)
      : route;
  }
}
