import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserAwardDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileAwardsService } from '../../../components/sections/awards/kalgudi-profile-awards.service';

@Directive()
export abstract class AddAwards extends KalgudiDestroyable {

  addAwardForm: FormGroup;

  @Input()
  awardId: string;

  @Input()
  awardDetails: KalgudiUserAwardDetails;

  @Output()
  closeDialog = new EventEmitter();

  progress: boolean;

  private fb: FormBuilder;
  private awardsService: KalgudiProfileAwardsService;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;

  constructor(
    protected injector: Injector) {

    super();

    this.fb                 = this.injector.get(FormBuilder);
    this.awardsService      = this.injector.get(KalgudiProfileAwardsService);
    this.util               = this.injector.get(KalgudiUtilityService);
    this.notification       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.addAwardForm = this.newAwardForm;

  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Form group for add farmer award form
   */
  private get newAwardForm(): FormGroup {

    return this.fb.group({
      businessAwardId: [''],
      title: ['', Validators.required],
      description: ['', Validators.required],
      attachments: ['']
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Save or update award
   */
  saveOrUpdateAward() {
    this.awardId ? this.updateAward() : this.saveNewAward();
  }

  /**
   * Saves new award
   */
  saveNewAward() {

    const payload = this.preparePayload(this.addAwardForm.value);

    this.progress = true;
    this.awardsService.saveNewAward(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onAwardAdded(res)
      );
  }

  /**
   * Updates awards
   */
  updateAward() {

    const payload = this.preparePayload(this.addAwardForm.value);

    this.progress = true;

    this.awardsService.updateAward(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)

      )
      .subscribe(
        res => this.onAwardUpdated(res)
      );
  }

  /**
   * Getter for award imageUrl field
   */
  get attachmentsField(): any {
    return this.addAwardForm.get('attachments');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  /**
   * Award added successfully
   */
  protected onAwardAdded(res: boolean) {
    this.closeDialog.emit();
  }

  /**
   * Award updated successfully
   */
  protected onAwardUpdated(res: boolean) {
    this.closeDialog.emit();
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
