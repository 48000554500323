<div class="container store-page my-3">
  <div fxHide.xs fxLayout="column" fxLayoutGap="15px">

    <div>
      <a [href]="env.storeDomain" target="_blank">
        <img src="assets/images/store.jpg" class="w-100">
      </a>
    </div>

    <div>
      <a [href]="ordersUrl" target="_blank">
        <img src="assets/images/orders.jpg" class="w-100" >
      </a>
    </div>

    <!--<div>
      <img src="assets/images/coupons.jpg" class="w-100">
    </div>-->
  </div>

  <div fxHide.gt-xs fxLayout="column">
    <div>
      <a [href]="env.storeDomain" target="_blank">
        <img src="assets/images/store-mobile-image.jpg" class="w-100">
      </a>
    </div>

    <div>
      <a [href]="ordersUrl" target="_blank">
        <img src="assets/images/orders-mobile.jpg" class="w-100" >
      </a>
    </div>

    <!--<div>
      <img src="assets/images/coupons-mobile.jpg" class="w-100">
    </div>-->
  </div>
</div>


<!-- <a href="https://shaktimanfarmingsolutions.com/" target="_blank" class="m-4" fxLayout="row" fxLayoutAlign="center center">
  <button mat-raised-button color="primary">
    View Store
  </button>
</a> -->
