import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiAppService, KalgudiUploadService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  CommonBasicProfile,
  KalgudiProject,
  KalgudiSellerConfig,
  KalgudiUser,
  KalgudiUserBasicDetails,
  KalgudiUserLoginIdResponse,
  KalgudiUserLoginIdUpdatePayload,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mapTo, switchMap, take, tap } from 'rxjs/operators';

import { ProfilePageActions } from '../constants';
import { KalgudiProfileApiService } from './kalgudi-profile-api.service';
import { KalgudiProfileStateService } from './kalgudi-profile-state.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiProfileService {

  editable$: Observable<boolean>;

  pageId: string;


  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private api: KalgudiProfileApiService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private appService: KalgudiAppService,
    private uploadService: KalgudiUploadService,
    private profileState: KalgudiProfileStateService,
    private kalgudiApp: KalgudiAppService

  ) {

    // console.log("profile service intialized");

    this.editable$ = this.isEditable();

    this.profileState.action$.subscribe(action => {

      if (action.type === ProfilePageActions.ASSIST_PROFILE) {
        this.pageId = action.payload;

        // console.log('Profile state updated in profile service');


        // if (this.pageId) {
        //   this.params.pageId = this.pageId;
        //   this.params.assistedTo = this.profileState.id;
        // } else {
        //   this.params = {};
        // }
      }
    });
    // this.params = {};

  }

  /**
   * Updates the latest profile with local and profile state
   */
  updateProfileAndState(profileKey: string = this.appService.profileLocal.profileKey): Observable<KalgudiUser> {

    const obs: Observable<KalgudiUser> = this.isLoggedInUser(profileKey)
      ? this.appService.updateProfile()         // User updating its own profile hence update the profile locally in local storage too
      : this.fetchProfile(profileKey);          // User is not updating its own profile hence just fetch latest profile

    return obs
      .pipe(

        // After fetching latest profile ensure the profile state gets updated
        tap(profile => this.profileState.dispatchAction(ProfilePageActions.PROFILE_UPDATED, profile))
      );
  }


  /**
   * Returns `true` if the passed `profileKey` is current logged in user.
   * Otherwise returns `false`.
   */
  isLoggedInUser(profileKey: string): boolean {
    return profileKey === this.appService.profileLocal.profileKey;
  }

  /**
   * Fetches logged in kalgudi user profile
   */
  fetchProfile(profileKey: string, blockProfileView = false): Observable<KalgudiUser> {
    return this.api.fetchProfile(profileKey)
      .pipe(
        tap(res => {
          if (this.kalgudiApp.profileLocal.profileKey !== profileKey && !blockProfileView)  {
              // Calls profiles view service while viewing other profiles
              this.callProfileViewService(profileKey);
          }

        }),

        map(r => this.processUserDetails(r)),
      );
  }

  /**
   * Attention! Use this function to fetch public profile only
   */
  fetchPublicProfile(profileKey: string): Observable<KalgudiUser> {
    return this.api.fetchProfile(profileKey);
  }

  /**
   * Calls view profile service
   */
  callProfileViewService(profileKey: string): void {

    this.api.callProfileViewService(profileKey).subscribe();
  }

  /**
   * Updates profile picture
   * @param file
   * @param path
   */
  updateProfilePic(file: File, path: S3PolicyPathCategoryMap): Observable<KalgudiUser> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        switchMap(r => this.uploadProfilePicToS3(file, r, path)),

        switchMap(r => this.uploadProfilePicApi(r)),

        // Ensure profile locally
        switchMap(r => this.updateProfileAndState(this.profileState.id))
      );
  }


  /**
   * Updates cover picture
   * @param file
   * @param path
   */
  updateCoverPic(file: File, path: S3PolicyPathCategoryMap): Observable<any> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        switchMap(r => this.uploadCoverPicToS3(file, r, path)),

        switchMap(r => this.uploadCoverPicApi(r)),

        // Ensure profile locally
        switchMap(r => this.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Updates basic common profile details
   */
  updateCommonBasicDetails(payload: CommonBasicProfile): Observable<KalgudiUser> {
    return this.api.updateCommonBasicDetails(payload)
      .pipe(
        switchMap(_ => this.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Updates basic common profile details
   */
  updateLoginId(payload: KalgudiUserLoginIdUpdatePayload): Observable<KalgudiUser> {
    return this.api.updateLoginId(payload)
      .pipe(
        switchMap(_ => this.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Calls api to get login id
   */
  getLoginId(): Observable<KalgudiUserLoginIdResponse> {
    return this.api.getLoginId();
  }


  /**
   * Calls api to get seller config details
   */
  getSellerConfigDetails(profileKey: string): Observable<KalgudiSellerConfig> {
    return this.api.getSellerConfigDetails(profileKey);
  }

  /**
   * Calls api to update seller config details
   */
  updateSellerConfigDetails(payload: KalgudiSellerConfig): Observable<ApiResponseCommon> {
    return this.api.updateSellerConfigDetails(payload);
  }

  /**
   * Gets projects list
   */
  getProjectsList(): Observable<KalgudiProject[]> {
    return this.api.getProjectsList();
  }

  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Uploads the file to s3 and gets the s3url
   * @param file
   * @param profileBasicDetails
   * @param path
   */
  private uploadProfilePicToS3(
    file: File,
    profileBasicDetails: KalgudiUserBasicDetails,
    path: S3PolicyPathCategoryMap
    ): Observable<KalgudiUserBasicDetails> {

    // Call upload service
    const extension = this.uploadService.getFileExtension(file.type);

    return this.uploadService.uploadFile(file, path, true, `${profileBasicDetails.profileKey}.png`)
    .pipe(
      tap(r => profileBasicDetails.profilePicURL = this.getProfilePicUrl(r.filePath)),

      mapTo(profileBasicDetails)
    );

  }


  /**
   * Uploads the file to s3 and gets the s3url
   * @param file
   * @param profileBasicDetails
   * @param path
   */
  private uploadCoverPicToS3(
    file: File,
    profileBasicDetails: KalgudiUserBasicDetails,
    path: S3PolicyPathCategoryMap
    ): Observable<KalgudiUserBasicDetails> {

    // Call upload service
    const extension = this.uploadService.getFileExtension(file.type);

    return this.uploadService.uploadFile(file, path, false, `${profileBasicDetails.profileKey}.png`)
      .pipe(
        tap(r => profileBasicDetails.coverPicUrl = this.getProfilePicUrl(r.filePath)),

        mapTo(profileBasicDetails)
      );

  }

  /**
   * Calls profile picture API
   * @param profileBasicDetails
   */
  private uploadProfilePicApi(profileBasicDetails: KalgudiUserBasicDetails): Observable<any> {
    // Call upload service
    return this.api.uploadProfilePic(profileBasicDetails);

  }

  /**
   * Calls cover picture API
   * @param profileBasicDetails
   */
  private uploadCoverPicApi(profileBasicDetails: KalgudiUserBasicDetails): Observable<any> {
    // Call upload service
    return this.api.uploadCoverPic(profileBasicDetails.profileKey, profileBasicDetails.coverPicUrl);

  }

  /**
   * @param filePath
   */
  private getProfilePicUrl(filePath: string): string {

    return `${this.env.domain}${filePath}` + ('?' + new Date().getTime().toString());
  }


  private isEditable(): Observable<boolean> {

    return combineLatest(
      this.appService.profile$,              // Logged in user profile
      this.profileState.id$                   // Active profile
        .pipe(

          // Filter null values
          filter(r => r !== null)
        ),
    )
    .pipe(
      // Profile is editable if logged in user profile key matches with the current viewing profile
      map(([loggedInProfile, currentProfileId]) => loggedInProfile.profileKey === currentProfileId),

      map(res => this.pageId ? true : res)
    );
  }


  /**
   * Processing the profile data
   */
  private processUserDetails(profile: KalgudiUser): KalgudiUser {

    try {
      if (profile.dateOfBirth) {
        profile.dateOfBirth = new Date(profile.dateOfBirth).toISOString();
      }
    } catch (e) {
      profile.dateOfBirth = '';
    }

    return profile;
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
