import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { FPOBankingDetails, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class KalgudiUpdateFpoBankingDetails extends KalgudiDestroyable {

  @Output()
  closeDialog = new EventEmitter();

  fpoBankingDetailsForm: FormGroup;

  bankAttachmentFormControl = new FormControl(['']);
  profileDetails: KalgudiUser;

  progress: boolean;

  private fb: FormBuilder;
  private profileState: KalgudiProfileStateService;
  private orgProfileService: OrganisationProfileService;
  protected util: KalgudiUtilityService;
  private notification: KalgudiNotification;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                = this.injector.get(FormBuilder);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.orgProfileService = this.injector.get(OrganisationProfileService);
    this.util              = this.injector.get(KalgudiUtilityService);
    this.notification      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);


    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
      });

    this.fpoBankingDetailsForm = this.newFpoBankingDetailsForm;
  }


  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  /**
   * Form group for banking details form
   */
  private get newFpoBankingDetailsForm(): FormGroup {

    return this.fb.group({
      bankName: [''],
      accountHolderName: [''],
      accountNumber: [''],
      branchAddress: [''],
      ifscCode: [''],
      bankAttachment: ['']
    });
  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls api method to update banking details
   */
  updateBankingDetails() {

    this.progress = true;

    if (this.bankAttachmentFormControl.value && this.bankAttachmentFormControl.value.length && this.bankAttachmentFormControl.value[0].url) {
      this.bankAttachmentField.patchValue(this.bankAttachmentFormControl.value[0].url);
    }

    const payload = this.preparePayload(this.fpoBankingDetailsForm.value);

    this.orgProfileService.updateBankingDetails(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false )
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )

  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Prepares payload for update banking details
   */
  private preparePayload(formValue): FPOBankingDetails {

    return formValue;
  }

  /**
   * Update banking details success handler
   */
  private addSuccessHandler(res: KalgudiUser) {
    this.closeDialog.emit();
  }

  /**
   * Update banking details error handler
   */
  private addErrorHandler(err: Error) {

    this.notification.showMessage('unable to update banking details, please try again later!');
  }

  /**
   * Getter for bank imageUrl field
   */
  get bankAttachmentField(): any {
    return this.fpoBankingDetailsForm.get('bankAttachment');
  }

  /**
   * Remove the attached image
   */
  removeImage() {
    this.bankAttachmentFormControl.patchValue('');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
