<div id="profile-logs-list">
  <ng-container *ngIf="(stream$ | async) as items">
    <ng-container *ngIf="items?.length">

      <div>
        <div *ngFor="let items of (stream$ | async); let index=index;" class="mb-3">
          <kl-profile-log-tile [profileLog] = "items"></kl-profile-log-tile>
        </div>
      </div>

      <div class="m-3" *ngIf="(paginator$ | async) as paginator">
        <kl-load-more
          noMoreText="No more profile logs"
          [progress]="paginator?.loading"
          [hasMoreItems]="paginator?.hasItems"
          (clicked)="nextPage()">
        </kl-load-more>
      </div>

    </ng-container>

    <div *ngIf="(paginator$ | async) as paginator" fxLayout="row" class="w-100">
      <div *ngIf="!items?.length" class="no-content-center w-100" fxLayoutAlign="center center">
        <kl-no-content fxHide.xs
          svgIcon="assets/svgs/profile-logs.svg"
          text="No profile logs list to show"
          iconSize="md"
          progressText="Loading your profile logs list..."
          [progress]="paginator?.loading">
        </kl-no-content>
      </div>
    </div>

  </ng-container>
</div>
