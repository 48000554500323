<div id="upload-image-mobile">

  <div class="mt-3">
    <kl-add-live-stock-form
      [stockType]="dialogData?.data?.stockType"
      [stock]="dialogData?.data?.stock"
      [showEditFields]="dialogData?.data?.showEditFields"
      (closeDialog)="ok()">
    </kl-add-live-stock-form>
  </div>

</div>
