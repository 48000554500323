<mat-card class="task-tile px-3 pb-3 pt-0 cursor-pointer" fxLayout="column" (click)="openTaskFullview()">

  <!-- Task details -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="55%">
      <p class="mb-0 text-truncate">
        <span class="text-primary task-type" translate>
          {{ taskDetails?.templateRefTitle | titlecase }}
        </span>
      </p>
    </div>
    <div fxFlex="45%" class="text-right">
      <p *ngIf=" taskDetails?.timeFrame?.start !== taskDetails?.timeFrame?.end"
        class="mb-0 text-truncate text-muted date">
        <span> {{ taskDetails?.timeFrame?.start | date:'dd' }} </span>
        <span> {{ taskDetails?.timeFrame?.start | date:'MMMM' | translate }} </span>-
        <span> {{ taskDetails?.timeFrame?.end | date:'dd' }} </span>
        <span> {{ taskDetails?.timeFrame?.end | date:'MMMM' | translate }} </span>
      </p>
      <p *ngIf=" taskDetails?.timeFrame?.start === taskDetails?.timeFrame?.end"
        class="mb-0 text-truncate text-muted date">
        <span> {{ taskDetails?.timeFrame?.start | date:'dd' }}</span>
        <span> {{ taskDetails?.timeFrame?.start | date:'MMMM' | translate }}</span>
        <span> {{ taskDetails?.timeFrame?.start | date:'yyyy' }}</span>
      </p>
    </div>

    <div class="mt-2 mr-n2">
      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="!enableActions"
        [disabled]="taskDetails?.userRole === projectRoles?.MEMBER"
        class="menu-button" (click)="$event.stopPropagation()">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

        <button mat-menu-item (click)="openEditTaskDialog()">
          <mat-icon>edit</mat-icon>
          <span translate>Edit</span>
        </button>

        <button mat-menu-item (click)="openAddMembersDialog()">
          <mat-icon>group_add</mat-icon>
          <span translate>Members</span>
        </button>

        <button mat-menu-item (click)="deleteTask()">
          <mat-icon>delete_outline</mat-icon>
          <span translate>Delete</span>
        </button>

      </mat-menu>
    </div>
  </div>
  <div fxFlex="80%" [matTooltip]="taskDetails?.title">
    <p class="mb-0 title text-capitalize text-truncate"><b>{{ taskDetails?.title | translate }}</b></p>
  </div>
  <div>
    <p class="mb-0 text-muted proj-tile text-capitalize text-truncate" [ngClass]="{'no-data': !taskDetails?.projectTitle }"
    >{{ taskDetails?.projectTitle | translate}}</p>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="taskDetails?.userRole === 'MEMBER'" [ngClass]="{'no-members': !taskDetails?.statistics?.members?.total}">
    <div fxLayout fxLayoutAlign="center center">
      <span class="mr-2">
        <mat-icon class="text-muted group-icon">group</mat-icon>
      </span>
      <span [matTooltip]="'Users count' | translate" class="text-muted" *ngIf="taskDetails?.statistics?.members?.total">
        {{ taskDetails?.statistics?.members?.total }}
      </span>
      <span class="text-muted" *ngIf="!taskDetails?.statistics?.members?.total" translate>
        No members</span>
    </div>

    <div fxLayout class="position-relative stats" [ngClass]="{'no-members': !taskDetails?.statistics?.members?.total}">
      <div *ngFor="let submission of submissions" class="ml-2">
        <div (click)="openTaskStatsDialog(submission?.id); $event.stopPropagation()">
          <span class="dot d-inline-block align-middle mx-1" [ngStyle]="{'background-color': submission?.color}">
          </span>
          <span class="state">{{ submission?.value }}</span>
        </div>
      </div>

      <div fxLayout="column" class="stats-hover position-absolute mt-4 px-2">
        <div class="triangle ml-5"></div>
        <div *ngFor="let submission of submissions" fxLayoutAlign="center center" class="separator py-1">
          <div (click)="openTaskStatsDialog(submission?.id); $event.stopPropagation()" [ngClass]="{'stat-type': !enableActions}">
            <span class="text-white title"> {{ submission?.title | translate }}</span>
            <span class="text-white value">- {{ submission?.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="taskDetails?.userRole === 'MEMBER'" fxLayout class="progress mb-2">
    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.completed/ taskDetails?.statistics?.submissions?.total * 100 )+'%'}"
      class="completed" [matTooltip]="taskDetails?.statistics?.submissions?.completed + ' - ' + ('Completed' | translate)">

    </div>
    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.in_progress/ taskDetails?.statistics?.submissions?.total * 100)+'%'}"
      class="in-progress" [matTooltip]="taskDetails?.statistics?.submissions?.in_progress + ' - ' + ('In progress' | translate)">
    </div>
    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.not_started/ taskDetails?.statistics?.submissions?.total * 100)+'%'}"
      class="not-started" [matTooltip]="taskDetails?.statistics?.submissions?.not_started + ' - ' + ('Not started' | translate)">

    </div>
  </div>
  <!-- /Task details -->

  <!-- Members list -->
  <div *ngIf="taskDetails?.userRole !== 'MEMBER'" fxLayout="row" fxLayoutGap="10px" class="mb-1">
    <span class="text-black-50 fs-3" *ngIf="taskDetails?.statistics?.members?.members">Members</span>
    <span *ngIf="taskDetails?.statistics?.members?.members"> - </span>
    <span [matTooltip]="'Members count' | translate" class="text-muted" *ngIf="taskDetails?.statistics?.members?.members">
      {{ taskDetails?.statistics?.members?.members }}
    </span>
  </div>
  <!-- /Members list -->

  <!-- Task status -->
  <div *ngIf="taskDetails?.userRole !== 'MEMBER'" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="mb-1">
    <div fxFlex="33.3%" (click)="openTaskMembersPage('COMPLETED'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-completed" fxLayoutAlign="center center">
      <span translate>Completed</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.completed : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>

    <div fxFlex="33.3%" (click)="openTaskMembersPage('IN_PROGRESS'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-inProgress" fxLayoutAlign="center center">
      <span translate>Inprogress</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.in_progress : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>

    <div fxFlex="33.3%" (click)="openTaskMembersPage('NOT_STARTED'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-notStarted" fxLayoutAlign="center center">
      <span translate>Not started</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.not_started : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>
  </div>
  <!-- /Task status -->

</mat-card>
