import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

import { SatelliteAdvisoriesService } from '../satellite-advisories.service';

@Component({
  selector: 'kl-satellite-advisories-soil-moisture-tile',
  templateUrl: './satellite-advisories-soil-moisture-tile.component.html',
  styleUrls: ['./satellite-advisories-soil-moisture-tile.component.scss'],
  animations: [floatTopAnimation]
})
export class SatelliteAdvisoriesSoilMoistureTileComponent implements OnInit {

  @ViewChild('satImgSoilMoisture', { static: true })
  satImgRef: ElementRef;

  showMap: boolean = false;

  @Input()
  animationDelay = 0;

  @Input()
  estimatedTime: string;

  @Input()
  soilMoistureImage: string;

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  soilMoistureDetails: any;

  @Input()
  bbox: number[];

  boundaryPoints: string = '';

  constructor(
    private satelliteAdvisoriesService: SatelliteAdvisoriesService,
  ) {}

  ngOnInit() {
  }

  showLandMap() {
    this.showMap = !this.showMap;
  }
}
