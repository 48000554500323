import { Location } from '@angular/common';
import { Component, forwardRef, Inject, Injector, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { AgGridStatsTablePage, StatsTablePage } from '@kalgudi/ag-grid-lib/common';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, fadeInAnimation, KalgudiUtilityService, slideRightAnimation } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, KalgudiDialogResult, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, first, takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { SatelliteAdvisoriesAllUsersService } from '../../services/satellite-advisories-all-users.service';
import { SatelliteAdvisoriesSyncService } from '../../services/satellite-advisories-sync.service';
import {
  SatelliteAdvisoriesLandDialogComponent,
} from '../satellite-advisories-land-dialog/satellite-advisories-land-dialog.component';
import {
  SatelliteAdvisoriesLandMobileDialogComponent,
} from '../satellite-advisories-land-mobile-dialog/satellite-advisories-land-mobile-dialog.component';
import {
  SatelliteAdvisoriesScheduleUpdatesDialogComponent,
} from '../satellite-advisories-schedule-updates-dialog/satellite-advisories-schedule-updates-dialog.component';

@Component({
  selector: 'kl-satellite-advisories-all-users-ag-grid',
  templateUrl: './satellite-advisories-all-users-ag-grid.component.html',
  styleUrls: ['./satellite-advisories-all-users-ag-grid.component.scss'],
  providers: [
    {
      provide: StatsTablePage,
      useExisting: forwardRef(() => SatelliteAdvisoriesAllUsersService)
    }
  ],
  animations: [ fadeInAnimation, slideRightAnimation ]
})

export class SatelliteAdvisoriesAllUsersAgGridComponent extends AgGridStatsTablePage implements OnInit {

  @Input()
  pageId: string;

  @Input()
  syncId: string;

  get statsParams(): PartialData {

    return {
      pageId: this.pageId,
      syncId: this.syncId
    };
  }

  get listParams(): PartialData {

    return {
      pageId: this.pageId,
      syncId: this.syncId
    };
  }

  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    protected injector: Injector,
    private dialogsService: KalgudiDialogsService,
    private _location: Location,
    private satelliteAdvisoriesService: SatelliteAdvisoriesSyncService,
    private klUtil: KalgudiUtilityService,
    private kalgudiPageService: KalgudiPageService
  ) {

    super(injector);

    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.memberRole = pageDetails.memberRole;
      });
  }

  ngOnInit() {
    this.initPage();
  }

  sendAdvisory() {

    const payload = {
      syncId: this.syncId,
    };

    this.notification.showSpinner(true);

    this.satelliteAdvisoriesService.shareAdvisories(this.pageId, this.syncId, payload)
      .pipe(
        finalize(() => this.notification.hideSpinner()),
      )
      .subscribe(
        res => this.notification.showMessage('We are processing advisories and will be sent to farmers soon!'),
        err => this.klUtil.showApiErrorMessage(err),
      );
  }

  /**
   * Click event handler for row
   */
  onRowClicked(row: any): void {
    this.showLandDetailsDialog(row.data);
  }

  /**
   * Navigate to previous page
   */
  backToPreviousPage() {
    this._location.back();
  }

  /**
   * Shows land details dialog
   */
  showLandDetailsDialog(satelliteData: any) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Satellite advisories for ' + satelliteData.userDetails.firstName,
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {
        pageId: this.pageId,
        syncId: this.syncId,
        profileKey: satelliteData.userDetails.profileKey,
        landId: satelliteData.landId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openLandDetailsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      );
  }

  /**
   * Opens schedule updates dialog
   */
  openScheduleUpdatesDialog() {

    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Schedule updates',
      data: {
        pageId: this.pageId,
        syncId: this.syncId,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '80vw',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        pageId: this.pageId,
        syncId: this.syncId,
      }
    };

    // Show status dialog
    return this.showScheduleUpdatesDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }


  /**
   * Open schedule updates dialog
   *
   * @param config Dialog
   */
  private showScheduleUpdatesDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(SatelliteAdvisoriesScheduleUpdatesDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Shows the land details web or mobile dialog
   */
  private openLandDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(SatelliteAdvisoriesLandMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(SatelliteAdvisoriesLandDialogComponent, dialogConfig, matDialogConfig);
    }

  }

}


