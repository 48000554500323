<div class="p-4 sms-logs-list">

  <div fxLayoutAlign="space-between center">
    <div><span translate>Total</span>: {{ logsCount }}</div>
    <kl-sms-log-filters (selectedFilterId)="filterTypeChanged($event)"></kl-sms-log-filters>
  </div>

  <div class="mt-3">

    <!-- logs -->
    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>

    <table mat-table [dataSource]="dataSource" class="w-100">

      <ng-container matColumnDef="mobileNo">
        <th mat-header-cell *matHeaderCellDef translate>Mobile</th>
        <td mat-cell *matCellDef="let sms">
          <div fxLayout="row" fxLayoutGap="6px">

            <div class="status-circle mr-2 mt-1 d-block"
              [ngClass]="{'success': sms?.deliveryStatus === smsLogsStatus?.SUCCESS,
                'pending': sms?.deliveryStatus === smsLogsStatus?.PENDING,
                'failure': sms?.deliveryStatus === smsLogsStatus?.FAILED}">
            </div>
            <span>{{ sms?.mobileNo }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef translate>Name</th>
        <td mat-cell *matCellDef="let sms">
          {{ sms?.firstName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="deliveryStatus">
        <th mat-header-cell *matHeaderCellDef translate>Status</th>
        <td mat-cell *matCellDef="let sms">
          {{ sms?.deliveryStatus | titlecase}}

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer h-60"></tr>
    </table>

    <ng-container *ngIf="!(loading$ | async) && !logsCount">
      <kl-no-content
        svgIcon="assets/svgs/block.svg"
        text="No records to show!"
        progressText="Loading sms logs...">
      </kl-no-content>
    </ng-container>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>

  <!-- status colors -->
  <div fxLayout="row" fxLayoutAlign="space-between center"  *ngIf="deliveredLogsCount || failedLogsCount || pendingLogsCount">
    <div>
      <kl-sms-log-status
        [deliveredLogsCount]="deliveredLogsCount"
        [failedLogsCount]="failedLogsCount"
        [pendingLogsCount]="pendingLogsCount">
      </kl-sms-log-status>
    </div>
    <div>
      <kl-icon-link-button
        type="button"
        matIcon="arrow_downward"
        label="Download"
        [progress]="progress"
        (clicked)="downloadLogs()">
      </kl-icon-link-button>
    </div>
  </div>

</div>

