import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiChart, PageDigitalAssistanceActions, UIMenu } from '@kalgudi/types';

import { DigitalAssistanceStateService } from '../../services/digital-assistance-state.service';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-digital-assistance-stats',
  templateUrl: './digital-assistance-stats.component.html',
  styleUrls: ['./digital-assistance-stats.component.scss']
})
export class DigitalAssistanceStatsComponent implements OnInit {

  @Input()
  stats: KalgudiChart;

  @Input()
  menu: UIMenu[];

  constructor(
    private daStateService: DigitalAssistanceStateService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) { }

  ngOnInit() {
  }

  /**
   * Updates the action performed on the digital assistance
   */
  updateDaAction(actionId: PageDigitalAssistanceActions): void {
    this.daStateService.updateAction(actionId);
  }

}
