import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiPipesModule } from '@kalgudi/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiNoContentModule } from '../kalgudi-no-content/kalgudi-no-content.module';
import {
  AiPredictionsCommonInformationListComponent,
} from './components/ai-predictions-common-information-list/ai-predictions-information-list.component';
import { AiPredictionsCommonListComponent } from './components/ai-predictions-common-list/ai-predictions-common-list.component';
import { AiPredictionsStatsTileComponent } from './components/ai-predictions-stats-tile/ai-predictions-stats-tile.component';
import { AiPredictionsCommonTileComponent } from './components/ai-predictions-common-tile/ai-predictions-common-tile.component';
import { AiPredictionsCommonInputListComponent } from './components/ai-predictions-common-input-list/ai-predictions-common-input-list.component';
import { AiPredictionsCommonOutputListComponent } from './components/ai-predictions-common-output-list/ai-predictions-common-output-list.component';



@NgModule({
  declarations: [
    AiPredictionsStatsTileComponent,
    AiPredictionsCommonTileComponent,
    AiPredictionsCommonListComponent,
    AiPredictionsCommonInformationListComponent,
    AiPredictionsCommonInputListComponent,
    AiPredictionsCommonOutputListComponent
  ],
  imports: [
    CommonModule,

    //Material modules
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiPipesModule,
    KalgudiNoContentModule
  ],
  exports: [
    AiPredictionsCommonTileComponent
  ]
})
export class KalgudiAiPredictionsModule { }
