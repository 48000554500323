<section id="initiate-robocall">
  <mat-card class="mb-2" fxLayout="column">
    <form autocomplete="off" [formGroup]="shareForm" fxLayout="column">
      <section class="robocall-template-wrapper">
        <div class="text-area-wrapper">
          <mat-form-field appearance="outline" class="w-100 mb-2">
            <textarea matInput class="input-field" [placeholder]="'Please enter a context for robocall' | translate" [rows]="rowsCount"
              formControlName="shareText"></textarea>
          </mat-form-field>
        </div>
        <!-- Audio attachment -->
        <div class="my-2">
          <mat-chip-list>
            <ng-container *ngIf="attachedAudioList?.length">
              <mat-chip (click)="showAudioDialog(0)">
                <div class="audio-icon">
                  <mat-icon color="primary">volume_up</mat-icon>
                </div>
                <div class="ml-1">Main audio</div>
                <mat-icon matChipRemove (click)="removeAudioAttachment(0)">cancel</mat-icon>
              </mat-chip>
            </ng-container>
          </mat-chip-list>
        </div>

        <ng-container *ngIf="attachedAudioList?.length > 1">
          <div class="divider-container">
            <span>Response audio</span>
            <mat-divider></mat-divider>
          </div>
          <div class="my-2">
            <mat-chip-list>
              <ng-container *ngFor="
                  let attachment of attachedAudioList | slice : 1;
                  let index = index;
                  trackBy: trackByKey
                ">
                <mat-chip (click)="showAudioDialog(index + 1)">
                  <div class="audio-icon">
                    <mat-icon color="primary">volume_up</mat-icon>
                  </div>
                  <div class="ml-1">
                    {{ getAudioLabel(index + 1) }}
                  </div>
                  <mat-icon matChipRemove (click)="removeAudioAttachment(index + 1)">cancel</mat-icon>
                </mat-chip>
              </ng-container>
            </mat-chip-list>
          </div>
        </ng-container>

        <!--/ Audio attachment -->
      </section>

      <!-- Select tag and filter -->
      <kl-page-activity-filters fxFlex="100%" [pageId]="pageId" [shareForm]="shareForm" streamType="ROBOCALL"
        (shareWith)="shareWithChanged($event)">
      </kl-page-activity-filters>

      <!--/ Select tag and filter -->

      <!-- Gender field -->
      <div *ngIf="shareForm?.value?.recipient === 'SPECIFIC_SUBSCRIBERS'" class="mb-2" fxLayout
        fxLayoutAlign="start center" [formGroup]="shareFormFilter">
        <div class="mt-n1">
          <span class="mr-2 gender-text" translate>Gender: </span>
        </div>

        <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
          <mat-radio-button value="" checked class="mr-4">{{ "All" | translate }}</mat-radio-button>
          <mat-radio-button value="MALE" class="mr-4">{{ "Male" | translate }}</mat-radio-button>
          <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--/ Gender field -->

      <!-- Call to alternate mobile -->
      <div fxLayoutAlign="start center" class="mt-3 mb-1">
        <div class="mr-4 mb-2" translate>Call to alternate mobile</div>
        <kl-slide-toggle falseLabel="Off" trueLabel="On" [checked]="isSMSToAltNumber"
          (toggleChanged)="onRobocallSwitchToggle($event)">
        </kl-slide-toggle>
      </div>

      <div fxLayout="row" fxLayout.lt-md="row wrap" fxLayoutAlign="space-between center">
        <div>
          <button mat-button class="attach-files p-0 pr-3" id="attach-files-button" color="primary"
            [disabled]="attachedAudioList?.length >= 5" [matMenuTriggerFor]="menu">
            <mat-icon>attachment</mat-icon>
            <span class="ml-1" translate>Attach</span>
          </button>
          <mat-menu #menu="matMenu" class="attach-file">
            <ng-container>
              <!-- <div mat-menu-item class="attach-file-mat-menu">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="35px"
                  class="attach-menu"
                  (click)="audioUploadComponent?.onAttachClicked()"
                >
                  <div>
                    <p class="m-0 title">Voice</p>
                  </div>

                  <div fxLayout="row"> -->
              <!-- <button
                      fxFlex="95%"
                      id="attach-audio-button"
                      fxLayoutAlign="center center"
                      class="mt-1"
                      [ngClass]="{ 'rounded-pill': isMobileDevice }"
                      title="Attach image"
                      type="button"
                      [matTooltip]="'Attach audio' | translate"
                      (click)="showAudioDialog()"
                      mat-icon-button
                    >
                      <div
                        class="label-icon"
                        fxLayout
                        fxLayoutAlign="center center"
                      >
                        <mat-icon class="m-0 camera-icon">mic</mat-icon>
                      </div>
                    </button>
                    <div>
                      <span class="mx-2">|</span>
                    </div> -->
              <!-- <div>
                      <kl-image-picker-form-control
                        formControlName="lstOfAttachments"
                        [showBorder]="false"
                        [buttonType]="'mat-icon-button'"
                        svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                        [allowMultiple]="true"
                        [showAttachments]="false"
                        [s3Category]="s3Category"
                        [acceptedFileTypes]="acceptedAudioFileTypes"
                      >
                      </kl-image-picker-form-control>
                    </div>
                  </div> -->
              <!-- </div> -->
              <!-- </div> -->
              <div mat-menu-item class="attach-robocall-file-mat-menu">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                  <div>
                    <p class="m-0 title" translate>Voice</p>
                  </div>

                  <div fxLayout="row">
                    <button fxFlex="95%" fxLayoutAlign="center center" class="mt-1"
                      [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                      [matTooltip]="'Attach audio' | translate" (click)="showAudioDialog()" mat-icon-button>
                      <div class="label-icon" fxLayout fxLayoutAlign="center center">
                        <mat-icon class="m-0 camera-icon">mic</mat-icon>
                      </div>
                    </button>
                    <div>
                      <span class="mx-2">|</span>
                    </div>
                    <div>
                      <kl-image-picker-form-control
                        formControlName="lstOfAttachments"
                        [showBorder]="false"
                        [buttonType]="'mat-icon-button'"
                        svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                        [allowMultiple]="true"
                        [showAttachments]="false"
                        [acceptedFileTypes]="acceptedAudioFileTypes"
                        [s3Category]="s3Category">
                      </kl-image-picker-form-control>
                    </div>

                  </div>
                </div>
              </div>
            </ng-container>
          </mat-menu>
        </div>
        <!-- schedule time -->
        <div fxFlex="60%" fxLayout fxLayoutGap="8px" fxLayoutAlign="space-evenly center">
          <span translate>Schedule:</span>

          <mat-form-field color="primary" class="schedule-font" floatLabel="never" fxFlex="40%">
            <mat-label translate>Choose date</mat-label>
            <input matInput [matDatepicker]="scheduleDatePicker" (click)="this.scheduleDatePicker.open();" formControlName="scheduledDate" [min]="today"
              readonly />
            <mat-datepicker-toggle matSuffix [for]="scheduleDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #scheduleDatePicker></mat-datepicker>
          </mat-form-field>

          <div fxFlex="30%">
            <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value"
              [placeholder]="'Time' | translate" [disabled]="!scheduledDateField?.value" formControlName="scheduledTime">
            </kl-hour-selectable-form-control>
          </div>

          <div matTooltip="clear schedule" *ngIf="scheduledDateField?.value || scheduledTimeField?.value">
            <mat-icon class="clear-btn cursor-pointer" (click)="clearDate()">
              close
            </mat-icon>
          </div>

        </div>
        <!-- schedule time -->

        <!-- Send button -->
        <div class="ml-2">
          <kl-flat-button
            type="button"
            label="Send"
            [progress]="progress"
            [disabled]="
            !attachedAudioList?.length || !shareFormText?.value?.trim()?.length || shareForm?.invalid || !scheduleValues
            "
            (clicked)="scheduleRobocall()">
          </kl-flat-button>
        </div>
        <!-- / Send button -->
      </div>
    </form>
  </mat-card>
</section>
