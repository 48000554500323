<section class="add-products p-2">

  <form autocomplete="off" fxLayout="column" [formGroup]="productsForm">
    <!-- Product name -->
    <div class="mb-2 crop-name">
      <kl-base-product-autocomplete
        formControlName="crop"
        [required]="true"
        [placeholder]="'Search and select crop' | translate"
        [label]="'Search and select crop' | translate">
      </kl-base-product-autocomplete>
    </div>
    <!--/ Product name -->

    <!-- Variety -->
    <div id="crop-variety" class="mb-2">

      <kl-base-product-autocomplete
        formControlName="variety"
        [placeholder]="'Search and select crop variety' | translate"
        [label]="'Search and select crop variety' | translate"
        [productType]="productTypes.VARIETY"
        [baseProduct]="cropFieldValue"
        [required]="true">
      </kl-base-product-autocomplete>
    </div>
    <!-- / Variety -->

    <div fxLayout fxLayoutGap="15px" *ngIf="product || isStock" formGroupName="availableStock">
      <!-- Quantity -->
      <div fxLayout="column" fxFlex="calc(50% - 15px)">
        <mat-form-field appearance="outline">
          <mat-label translate>Quantity</mat-label>
          <input type="number" matInput required formControlName="value" required>
          <mat-error translate>Please enter quantity</mat-error>
        </mat-form-field>
      </div>
      <!--/ Quantity -->

      <!-- Select units -->
      <div fxLayout="column" fxFlex="calc(50% - 15px)">
        <mat-form-field appearance="outline">
          <mat-label translate>Select units</mat-label>
          <mat-select formControlName="unit" required>
            <mat-option value="option" *ngFor="let units of allUnits" [value]="units?.id">{{ units?.value | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Select units -->

    </div>

    <div class="pt-3" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
      <kl-flat-button
      [label]="product && product?.baseProductId ? 'Update Crop' : 'Add Crop' | translate"
      [progress]="progress"
      [disabled]="!productsForm?.valid"
      (clicked)="addProduct()">
      </kl-flat-button>
    </div>

  </form>
</section>
