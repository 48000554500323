import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiUsersMap } from '@kalgudi/types';

@Component({
  selector: 'kl-enable-offline-da',
  templateUrl: './enable-offline-da.component.html',
  styleUrls: ['./enable-offline-da.component.scss']
})
export class EnableOfflineDaComponent implements OnInit {

  @Input()
  pageId: string;

  @Input()
  selectedUsersMap: KalgudiUsersMap;

  @Output()
  selectedUsersMapChange = new EventEmitter<KalgudiUsersMap>();

  selectedIndex = 0;

  constructor() { }

  ngOnInit() {
  }

  onSelectedUsersMapChange(users: KalgudiUsersMap): void {
    this.selectedUsersMapChange.emit(users);
  }

}
