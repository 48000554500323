import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProfileLogsList } from '../../classes/kalgudi-profile-logs-list';

@Component({
  selector: 'kl-profile-logs-list',
  templateUrl: './profile-logs-list.component.html',
  styleUrls: ['./profile-logs-list.component.scss']
})
export class ProfileLogsListComponent extends KalgudiProfileLogsList implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}
}
