import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiProject } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-project-tile',
  templateUrl: './project-tile.component.html',
  styleUrls: ['./project-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectTileComponent implements OnInit {

  @Input()
  projectDetails: KalgudiProject;

  @Input()
  taskStatsDetails: any;

  @Input()
  memberStatsDetails: any;

  @Input()
  disableClick: boolean;

  @Input()
  overrideDefaultRouteNavigation: <T extends KalgudiProject>(project: T) => void;

  @Output()
  deleteProject = new EventEmitter();

  @Output()
  addProjectMembers = new EventEmitter();

  @Output()
  updateProject = new EventEmitter();

  @Output()
  addTask = new EventEmitter();

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig
  ) { }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Emits event back to the parent
   */
  deletesProject() {
    this.deleteProject.emit();
  }

  /**
   * Open projects full view
   */
  openProjectFullview(projectId: string) {

    if (this.disableClick) {
      return;
    }

    // Check if parent overrided default route behavior or not
    // For overrided behavior call the overrided method
    if (this.overrideDefaultRouteNavigation) {
      this.overrideDefaultRouteNavigation(this.projectDetails);
    } else {
      this.appRouting.toProject({ projectId }, {type: 'GRID'});
    }

  }

  /**
   * Open add members dialog
   */
  showAddMembersDialog() {
    this.addProjectMembers.emit();
  }

  openUpdateProject() {
    this.updateProject.emit();
  }

  /**
   * Opens task creation dialog
   */
  openTaskCreationDialog(projectId: string) {
    this.addTask.emit();
  }
}
