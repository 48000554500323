<mat-card fxFlex="100%" fxLayout="column" id="basic-info-container">
  <!-- social header -->
  <div class="basic-info-header py-2 mb-2 mt-n4" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="pt-2">
      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/icons/info.svg"
          [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 font-weight-bold" translate>Basic Info</p>
      </div>
    </div>

    <!-- Header -->
    <div matTooltip="Edit basic info details" *ngIf="!isPublicPage">
      <kl-common-profile-header [editable]="editable"
        actionIcon="edit" (action)="showBasicInfoDialog(true)">
      </kl-common-profile-header>
    </div>
    <!-- /Header -->

    <!-- <div matTooltip="Edit basic info details" *ngIf="!isPublicPage">
      <button  *ngIf="editable" mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end" (click)="showBasicInfoDialog(true)">
      <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">{{actionIcon || 'edit'}}</mat-icon>
      </button>
    </div> -->
  </div>
  <!--/ social header -->

  <!-- basic info list -->
  <div *ngIf="false" class="basic-info-list" fxLayout="column">

    <div>
      <h6 class="font-weight-bold mb-1 mt-2" translate>Entity details</h6>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Entity type</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{
        (basicInfoFormDetails?.entityDetails?.entityType) === 'COOPERATIVE_SOCITY'
        ? 'Cooperative Society' : (basicInfoFormDetails?.entityDetails?.entityType) === 'TRUST'
        ? 'Trust' : (basicInfoFormDetails?.entityDetails?.entityType) || '-'
        }}
      </span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start"
      *ngIf="(basicInfoFormDetails?.entityDetails?.companyCin)">
      <span fxFlex="calc(45% - 6px)" translate>CIN</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.entityDetails?.companyCin || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start"
      *ngIf="(basicInfoFormDetails?.entityDetails?.entityType === 'COOPERATIVE_SOCITY' && basicInfoFormDetails?.entityDetails?.cooperativeRegistrationNumber)">
      <span fxFlex="calc(45% - 6px)" translate>Cooperative registration number</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.entityDetails?.cooperativeRegistrationNumber || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start"
      *ngIf="(basicInfoFormDetails?.entityDetails?.entityType === 'TRUST' && basicInfoFormDetails?.entityDetails?.trustRegistrationNumber)">
      <span fxFlex="calc(45% - 6px)" translate>Trust registration number</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.entityDetails?.trustRegistrationNumber || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Entity contact number</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.basicDetails?.contactNumber ? "+91-"+ basicInfoFormDetails?.basicDetails?.contactNumber
        : '-' }}
      </span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Total members</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.memberShipBase) || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Male members</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.maleMembers) || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Female members</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.femaleMembers) || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Women led entity</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.isWomenLedEntity === true) ? 'Yes' :
        (basicInfoFormDetails?.basicDetails?.isWomenLedEntity === false) ? 'No' : '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Active members of FPO</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.activeMembers) || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>% of small holder farmers</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.perOfSmallHolder | number:'1.2-2') }}
      </span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>About yourself</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ aboutMeBasicDetails || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Main activity</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.basicDetails?.mainActivity || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Board meeting details</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ basicInfoFormDetails?.basicDetails?.boardMeeting || '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Farmer groups</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ (basicInfoFormDetails?.basicDetails?.isFarmerGrpAvailable === true) ? 'Yes' :
        (basicInfoFormDetails?.basicDetails?.isFarmerGrpAvailable === false) ? 'No' : '-' }}</span>
    </div>

    <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <span fxFlex="calc(45% - 6px)" translate>Share capital</span>
      <span class="basic-info-details" fxFlex="calc(55% - 6px)">
        {{ convertIndianRupeeFormat(basicInfoFormDetails?.basicDetails?.shareCapital) || '-' }}</span>
    </div>

    <!-- Turnover table values -->
    <div class="mb-2 mr-2" *ngIf="basicInfoFormDetails?.turnoverDetails?.length">
      <!-- Header turn over details -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Turnover details</h6>
      </div>
      <!--/ Header turn over details -->

      <!-- Turn over values -->
      <div class="turnover-values-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Financial year</div>
            <div class="table-cell" translate>Turnover revenue(₹)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white"
              *ngFor="let turnover of basicInfoFormDetails?.turnoverDetails; let i=index;">
              <div class="table-cell">{{ i+1 }}</div>
              <div class="table-cell">{{ turnover?.financialYearId || '-' }}</div>
              <div class="table-cell">{{ convertIndianRupeeFormat(turnover?.turnover) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Turn over values -->
    </div>
    <!--/ Turnover table values -->

    <div fxHide>
      <h6 class="font-weight-bold mb-1 mt-2" translate>CEO details</h6>
    </div>

    <div fxHide>
      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>CEO name</span>
        <span class="basic-info-details" fxFlex="calc(55% - 6px)">
          {{ basicInfoFormDetails?.ceoDetails?.ceoName || '-' }}</span>
      </div>
      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>CEO mobile number</span>
        <span class="basic-info-details" fxFlex="calc(55% - 6px)">
          {{ basicInfoFormDetails?.ceoDetails?.ceoMobileNumber ?
          "+91-"+basicInfoFormDetails?.ceoDetails?.ceoMobileNumber : '-' }}
        </span>
      </div>
      <div class="border-bottom mb-2 pb-2" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
        <span fxFlex="calc(45% - 6px)" translate>CEO email</span>
        <span class="basic-info-details" fxFlex="calc(55% - 6px)">
          {{ basicInfoFormDetails?.ceoDetails?.ceoEmailId || '-' }}</span>
      </div>
    </div>
    <div>
    </div>

    <!--/ Program type section -->


  </div>
  <!--/ basic info list -->

  <!-- No content if there no social -->
  <ng-container *ngIf="false">
    <div class="w-100" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? ('No basic info added yet!' | translate) : editable ? '' : ('No basic info added yet!' | translate)"
        [actionLink]="isPublicPage ? '' : editable ? ('Add basic info' | translate) : ''"
        (action)="showBasicInfoDialog()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no social -->
</mat-card>