import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonSearchBarModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { WeatherCache } from './classes/weather-cache';
import { HomeWeatherTileComponent } from './components/home-weather-tile/home-weather-tile.component';
import { WeatherDetailsComponent } from './components/weather-details/weather-details.component';
import { WeatherFullviewComponent } from './components/weather-fullview/weather-fullview.component';
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';
import { KalgudiWeatherApiService } from './services/kalgudi-weather-api.service';
import { WeatherCacheInMemoryService } from './services/weather-cache-in-memory.service';
import { WeatherCacheLocalStorageService } from './services/weather-cache-local-storage.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    WeatherWidgetComponent,
    HomeWeatherTileComponent,
    WeatherFullviewComponent,
    WeatherDetailsComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatCardModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,

    ReactiveFormsModule,

    // Flex module
    FlexLayoutModule,

    AngularSvgIconModule,

    TranslateModule,

    // Kalgudi Modules
    KalgudiNoContentModule,
    CommonSearchBarModule,
    KalgudiGooglePlacesModule
  ],
  exports: [
    WeatherFullviewComponent,
    HomeWeatherTileComponent,
    WeatherWidgetComponent
  ],
  providers: [
    KalgudiWeatherApiService,
    WeatherCacheInMemoryService,
    WeatherCacheLocalStorageService,
    {
      provide: WeatherCache,
      useExisting: WeatherCacheLocalStorageService,
    }
  ],
})

export class KalgudiWeatherModule {

  public static forRoot(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiWeatherModule> {
    return {
      ngModule: KalgudiWeatherModule,
      providers: [
        {
          provide: KL_ENV,
          useClass: config && config.environment
        },
        {
          provide: KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide: KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        }
      ]
    };
  }
}
