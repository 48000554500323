import { Injectable } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import {
  KalgudiUserBasicDetails,
  NotificationNetwork,
  ShareQaAnswerFull,
  ShareQaFullDetails,
  ShareUpdate,
} from '@kalgudi/types';

/**
 * Social API response data normalizer service. Normalizes various
 * data structures to one structure common to all social modules.
 *
 * The normalized structure can be used by many shared components.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class SocialDataNormalizerService {

  constructor(
    private kalgudiApp: KalgudiAppService,
  ) { }



  /**
   * Maps share update or Qa author details. Merges share update author details
   * from separate field to a single field.
   *
   * Returns the mapped user details.
   *
   * @param update Share update to map
   */
  mapShareUpdateQaAuthorDetails(update: ShareUpdate | ShareQaFullDetails): KalgudiUserBasicDetails {

    // Construct author details from separate fields in the
    // share update object
    const author: KalgudiUserBasicDetails = {
      profileKey: update.authorId,
      firstName: update.authorName,
      profilePicURL: update.authorProfilePicURL,
      location: update.authorLocation,
      businessTypeName: update.authorBizType,
      businessKey: update.authorBizId,
      businessName: update.authorName,
    };

    // Map share update author details
    update.author = author;

    return author;
  }

  /**
   * Maps qa author details and qa answer author details
   */
  mapQaAuthorDetails(qa: ShareQaFullDetails): ShareQaFullDetails {


    // Construct author details from separate fields in the
    // share update object
    const author: KalgudiUserBasicDetails = {
      profileKey: qa.authorId,
      firstName: qa.authorName,
      profilePicURL: qa.authorProfilePicURL,
      profilePicUrl: qa.authorProfilePicURL,
      location: qa.authorLocation,
      businessTypeName: qa.authorBizType,
      businessKey: qa.authorBizId,
      businessName: qa.authorName,
    };

    // Map share update author details
    qa.author = author;

    // Maps qa answers author details
    if (qa.answer) {
      qa.answer.author = {
        profileKey: qa.answer.authorId,
        firstName: qa.answer.authorName,
        profilePicURL: qa.answer.authorProfilePicURL,
        businessTypeName: qa.answer.authorBizType,
        businessKey: qa.answer.authorBizId,
        businessName: qa.answer.authorName,
      };
    }

    return qa;
  }

  /**
   * Maps questions answers author details.
   */
  mapQaAnswersAuthorDetails(answers: ShareQaAnswerFull[]): ShareQaAnswerFull[] {

    answers.forEach(a => {
      a.author = {
        profileKey: a.authorId,
        firstName: a.authorName,
        profilePicURL: a.authorProfilePicURL,
        businessTypeName: a.authorBizType,
        businessKey: a.authorBizId,
        businessName: a.authorName,
      };
    });

    return answers;
  }

  /**
   * Maps network home stream object author details. Merges network author details
   * from separate field to a single field.
   *
   * Returns the mapped user details.
   *
   * @param notification Network notification object to map
   */
  mapNetworkAuthorDetails(notification: NotificationNetwork): KalgudiUserBasicDetails {

    // Construct author details from separate fields in the
    // share update object
    const author: KalgudiUserBasicDetails = {
      profileKey: notification.senderProfileKey,
      firstName: notification.senderFirstName,
      profilePicURL: notification.senderProfilePicUrl,
      location: notification.senderPlaceTo.locationLong,
      businessTypeName: notification.senderBusinessTypeName,
      businessKey: notification.senderBusinessId,
      businessName: notification.senderBusinessName,
    };

    // Map share update author details
    notification.author = author;

    return author;
  }


  /**
   * Maps whether the current user liked the share or not
   * @param share
   */
  mapShareUpdateLiked(share: ShareUpdate): ShareUpdate {

    share.liked = share.likes.filter(l => l.id === this.kalgudiApp.profileLocal.profileKey).length > 0;

    return share;
  }

  /**
   * Maps page share update or Qa author details. Merges share update author details
   * from separate field to a single field.
   *
   * Returns the mapped user details.
   *
   * @param update Share update to map
   */
  mapPageShareUpdateQaAuthorDetails(update: ShareUpdate | ShareQaFullDetails): KalgudiUserBasicDetails {

    let author: KalgudiUserBasicDetails;

    // Construct page author details from separate fields in the
    // share update object
    if (update.pageDetails) {

      author = {
        firstName: update.authorName,
        profileKey: update.authorId,
        profilePicURL: update.pageDetails.pageProfilePic,
        // businessTypeName: update.pageDetails.pageType,
      };

      // Map page author details for page share update
      update.pageAuthorDetails = author;
    }

    return author;
  }

}
