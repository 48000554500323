import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  ApiSurveyMemberAddResponseData,
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiSurveyDetails,
  KalgudiUserBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { KalgudiSurveyMembersService } from '../../../services/kalgudi-survey-members.service';
import { KalgudiSurveysHelperService } from '../../../services/kalgudi-surveys-helper.service';
import { SurveyStateService } from '../../../services/survey-state.service';
import { SurveyMembersActionService } from '../services/survey-members-action.service';

@Directive()
export abstract class KalgudiSurveyMembersManagement extends KalgudiInboxStream<KalgudiUserBasicDetails> {

  @Input()
  overRideDefaultSurveyMemberDialog: () => void;


  surveyDetails: KalgudiSurveyDetails;
  surveyId: string;

  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  availableActionTypes = SurveyPageActions;

  maxPageLimit = 16;

  protected surveyState: SurveyStateService;
  protected surveyHelper: KalgudiSurveysHelperService;
  private surveyMembersActionService: SurveyMembersActionService;
  private kalgudiSurveyMembersService: KalgudiSurveyMembersService;
  private kalgudiDialogs: KalgudiDialogsService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util);

    this.surveyMembersActionService  = this.injector.get(SurveyMembersActionService);
    this.kalgudiSurveyMembersService = this.injector.get(KalgudiSurveyMembersService);
    this.surveyState                 = this.injector.get(SurveyStateService);
    this.kalgudiDialogs              = this.injector.get(KalgudiDialogsService);
    this.surveyHelper                = this.injector.get(KalgudiSurveysHelperService);

    this.pageLimit = this.maxPageLimit;

    this.init();
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Verifies if the user is allowed to add the member or not.
   * Shows error message if the user is not allowed to add the member,
   * otherwise shows the add member dialog.
   */
  checkAndAddMember(): void {

    /**
     * Emits an event to the parent, if the survey is under page
     */
    if (this.overRideDefaultSurveyMemberDialog) {

      this.overRideDefaultSurveyMemberDialog();

    } else {

      try {

        // Verify question additions
        this.surveyHelper.canModifyMembers(this.surveyDetails);

        this.showAddSurveyMembersDialog();
      } catch (e) {
        this.util.showApiErrorMessage(e);
      }
    }

  }

  /**
   * Verifies if the user is allowed to remove the member or not.
   * Shows error message if the user is not allowed to remove the member,
   * otherwise removes the member.
   */
  checkAndRemoveMember(user: KalgudiUserBasicDetails): void {

    try {

      // Verify question additions
      this.surveyHelper.canModifyMembers(this.surveyDetails);

      this.removeUser(user);
    } catch (e) {
      this.util.showApiErrorMessage(e);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Initializes survey fullview subscriptions and survey details
   */
  protected init(): void {

    // Initialize survey details
    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdated(survey));


    // Subscribe to survey page state change
    this.subscribeToStateActions();
  }


  /**
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.kalgudiSurveyMembersService.getMembersList(this.surveyId, offset, limit);
  }

  /**
   * Calls api to remove member for the survey
   */
  protected removeMembers(user: KalgudiUserBasicDetails): void {
    this.notification.showSpinner();

    this.kalgudiSurveyMembersService.deleteMember(this.surveyId, user.profileKey)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        (res) => this.onMemberRemoved(res),
        (err) => this.onRemovingMemberError(err)
      );
  }

  /**
   * Event handler for survey members adding successful
   */
  protected onMembersAdditionSuccess(res: ApiSurveyMemberAddResponseData): void {
    this.notification.showMessage('Members added');
    this.resetStream();
  }

  /**
   * Event handler for survey member deleted successful
   */
  protected onMemberRemoved(res: ApiSurveyMemberAddResponseData): void {
    this.notification.showMessage('Removed member successfully');
    this.resetStream();
  }

  /**
   * Event handler for survey member delete API errors
   */
  protected onRemovingMemberError(err: Error): void {
    this.notification.showMessage('Unable to remove member, please try again later!');
  }

  /**
   * Opens survey members dialog
   */
  private showAddSurveyMembersDialog() {
    this.surveyMembersActionService.showAddSurveyMembersDialog()
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
      );
  }

  /**
   * Removes a user from the survey.
   * Opens confirmation dialog before deleting member
   */
  private removeUser(user: KalgudiUserBasicDetails): void {

    // Open confirmation dialog before deleting
    this.showDeleteConfirmationDialog()
      .pipe(

        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.removeMembers(user)
      );
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Remove',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to remove member?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Subscribes to survey state action updates and calls the necessary methods.
   */
  private subscribeToStateActions(): void {

    this.surveyState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === SurveyPageActions.ADD_MEMBER) {
          this.checkAndAddMember();
        }
        if (action.type === SurveyPageActions.MEMBER_ADDED) {
          this.resetStream();
        }
      });
  }

  /**
   * Event handler gets called on the survey details update in the
   * state service
   */
  private onSurveyStateUpdated(survey: KalgudiSurveyDetails): void {
    this.surveyDetails = survey;
    this.surveyId = survey.surveyId;
    this.memberRole = survey.role;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
