import { Component, Inject, Injector, OnInit } from '@angular/core';

import { KalgudiFpoInfrastructure } from '../../../classes/kalgudi-fpo-infrastructure';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-fpo-infrastructure-tile',
  templateUrl: './fpo-infrastructure-tile.component.html',
  styleUrls: ['./fpo-infrastructure-tile.component.scss']
})
export class FpoInfrastructureTileComponent extends KalgudiFpoInfrastructure implements OnInit {

  isShgApp: boolean = true;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
    ) {

    super(injector);
    this.isShgApp = !(environment?.appId === 'SHG_NET_APP');
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
