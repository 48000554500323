export interface KalgudiFcmConfig {
  appId: string;
  firebaseConfig: KalgudiFireBaseConfig;
  kalgudiApi: string;
}

export interface KalgudiFireBaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL?: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

export const KL_FCM_CONFIG = new InjectionToken<KalgudiFcmConfig>('KL_FCM');

import { InjectionToken } from '@angular/core';


export interface KalgudiFcmNotification {
  notification: FCMPayloadNotification;
  data: FCMPayloadData;
}

export interface FCMPayloadNotification {
  title: string;
  body: string;
  click_action: string;
}

export interface FCMPayloadData {
  deepLinkUrl: string;
}
