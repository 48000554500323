import { InjectionToken } from '@angular/core';

/**
 * Defines mobile dialog overlay configuration
 */


// Mobile dialog data injection token
export const KL_MOBILE_DIALOG_DATA  = new InjectionToken<any>('KL_MOBILE_DIALOG_DATA');
export const KL_MOBILE_DIALOG_TITLE = new InjectionToken<string>('KL_MOBILE_DIALOG_TITLE');
export const KL_MOBILE_DIALOG_DESC  = new InjectionToken<string>('KL_MOBILE_DIALOG_DESC');
