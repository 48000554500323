import { Injector, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  AddSurveyMembersResponse,
  ApiSurveyMemberAddResponseData,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
} from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiSurveyMembersService } from '../../../services/kalgudi-survey-members.service';
import { SurveyStateService } from '../../../services/survey-state.service';



@Directive()
export abstract class KalgudiSurveyAddMembers extends KalgudiDestroyable {

  selectedUsersMap: KalgudiUsersMap = {};

  profileKeys: string[];

  progress: boolean;

  surveyId: string;


  private kalgudiSurveyMembers: KalgudiSurveyMembersService;
  private notification: KalgudiNotification;
  private stateService: SurveyStateService;

  constructor(protected injector: Injector) {

    super();

    this.kalgudiSurveyMembers = this.injector.get(KalgudiSurveyMembersService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.stateService         = this.injector.get(SurveyStateService);

    this.stateService.id$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        id => {
          this.surveyId = id;
        }
      );
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  get selectedUsers(): KalgudiUserBasicDetails[] {

    return Object.values(this.selectedUsersMap);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Calls the api to add survey members
   */
  addMember() {

    // Get members payload
    const payload = this.getAddMembersPayload(this.selectedUsers);

    this.progress = true;

    this.kalgudiSurveyMembers.addMembersList(this.surveyId, payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onAddMemberError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Construct and gets, the add members payload.
   */
  protected getAddMembersPayload(users: KalgudiUserBasicDetails[]): AddSurveyMembersResponse {

    const payload: AddSurveyMembersResponse  = {
      actors: users,
    };

    return payload;
  }

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: ApiSurveyMemberAddResponseData): void {
    this.notification.showMessage('Members added');
  }

  /**
   * Event handler for adding members API errors.
   */
  protected onAddMemberError(err: Error): void {
    this.notification.showMessage(err.message);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
