import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

import {
  KalgudiRatingFormControlComponent,
} from './components/kalgudi-rating-form-control/kalgudi-rating-form-control.component';



@NgModule({
  declarations: [KalgudiRatingFormControlComponent],
  imports: [
    CommonModule,

    //Forms
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    // Material modules
    MatIconModule

  ],
  exports: [
    KalgudiRatingFormControlComponent
  ]
})
export class KalgudiSurveyFormControlsModule { }
