import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-float-button',
  templateUrl: './float-button.component.html',
  styleUrls: ['./float-button.component.scss']
})
export class FloatButtonComponent implements OnInit {

  @Output()
  clicked = new EventEmitter<MouseEvent>();

  @Input()
  icon: string;

  @Input()
  color: 'primary' | 'accent' | 'warn';

  constructor() { }

  ngOnInit() {
  }


  onClick(event: MouseEvent): void {

    this.clicked.emit(event);

    event.stopPropagation();
  }

}
