import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageType, StockPageDetails, StoreBaseProductBasicDetails } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateStock extends KalgudiCreatePageType<StockPageDetails> {

  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);

    /**
     * Waits till the form gets initialized
     */
    setTimeout(() => {
      this.initStockForm();
    }, 1000);
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  get baseProductField(): AbstractControl {
    return this.pageForm.get('product');
  }

  get baseProductFieldValue(): StoreBaseProductBasicDetails {
    return this.baseProductField.value;
  }

  get varietyField(): AbstractControl {
    return this.pageForm.get('variety');
  }

  get varietyFieldValue(): StoreBaseProductBasicDetails {
    return this.varietyField.value;
  }

  get variantField(): AbstractControl {
    return this.pageForm.get('variant');
  }

  get variantFieldValue(): StoreBaseProductBasicDetails {
    return this.variantField.value;
  }


  initStockForm() {

    this.baseProductField.valueChanges
    .pipe(
      takeUntil(this.destroyed$),

      // filter(_ => !this.editing)
    )
    .subscribe(_ => this.baseProductField.invalid ? this.varietyField.disable() : this.varietyField.enable());
  }
  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      product: [{ value: '', disabled: false }, Validators.required],
      variety: [{ value: '', disabled: true }, Validators.required],
      variant: [{ value: '', disabled: false }],
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'STOCK';
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
