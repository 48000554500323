import { Injectable } from '@angular/core';
import { KalgudiPageDetails, KalgudiPageRelation, PartialData } from '@kalgudi/types';
import { combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { KalgudiPagesSearchResults } from '../../../typings';
import { KalgudiProgramListApiService } from './kalgudi-program-list-api.service';

/**
 * Kalgudi programs list management service file.
 */
@Injectable()
export class KalgudiProgramListService {

  myPages: { adminPages: KalgudiPageDetails[], followingPages: KalgudiPageDetails[] };

  constructor(
    private api: KalgudiProgramListApiService,
  ) { }

  /**
   * Fetches pages list from the API
   */
  fetchPagesList(offset: number, limit: number, { memberRole }, filters: PartialData = {}, searchProperties?: any): Observable<KalgudiPagesSearchResults> {

    return this.api.fetchPagesList(offset, limit, memberRole, filters, searchProperties);
  }

  /**
   * Fetches pages list from the API
   */
  pagesList(offset: number, limit: number, pageType: string, filters: PartialData = {}): Observable<KalgudiPagesSearchResults> {

    return this.api.pagesList(offset, limit, pageType, filters);
  }

  /**
   * Fetches pages list from the API
   */
  searchPage(searchKeyword: string, offset: number, limit: number): Observable<KalgudiPagesSearchResults> {

    return this.api.searchPage(searchKeyword, offset, limit);
  }

  /**
   * Gets, pages you follow and pages you are part. It fetches all pages from the Api
   * skipping the specified page.
   *
   * @param pageId Skip the current page
   */
  getMyPages(pageId: string): Observable<{ adminPages: KalgudiPageDetails[], followingPages: KalgudiPageDetails[] }> {

    return this.myPages
      ? of(this.myPages)
      : combineLatest(
          this.getMyPagesWithFilter(pageId, 0, 5, 'ADMIN'),
          this.getMyPagesWithFilter(pageId, 0, 5, 'FOLLOWER')
        ).pipe(
          map(([adminPages, followingPages]) => {
            return { adminPages, followingPages };
          }),

          // Cache the response locally
          tap(myPages => this.myPages = myPages)
        );
  }

  /**
   * Calls api method to clone page
   */
  clonePage(payload: any, pageId: string): Observable<any> {

    return this.api.clonePage(payload, pageId);
  }

  /**
   * Get, pages list.
   */
  private getMyPagesWithFilter(
    pageId: string,
    offset: number,
    limit: number,
    memberRole: KalgudiPageRelation
  ): Observable<KalgudiPageDetails[]> {

    return this.fetchPagesList(offset, limit, { memberRole })
      .pipe(
        map(res => res.items),

        // Skip the specified page
        map(pages => pages.filter(page => page.pageId !== pageId))
      );
  }
}
