import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiMessageDetails } from '@kalgudi/types';
import { Subject } from 'rxjs';

import { KalgudiCommonRouteConfig } from '../../../config';

@Component({
  selector: 'kl-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit, OnDestroy, AfterViewChecked {

  @Input()
  latestMessage: any;

  @Input()
  authorDetails: KalgudiMessageDetails;

  @Input()
  isAllowedToReply: boolean;

  @Input()
  showBox: boolean;

  @Input()
  sender: any;

  @Input()
  receiver: any;

  @Input()
  messages: KalgudiMessageDetails[];

  @Input()
  showAuthor: boolean;

  @Output()
  replyMessage = new EventEmitter<KalgudiMessageDetails>();

  @Output()
  deleteMessage = new EventEmitter<KalgudiMessageDetails>();

  @Output()
  onHideMessage = new EventEmitter<KalgudiMessageDetails>();

  authorId: string;
  deletedMessage: string;
  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  @ViewChild('scrollMe')
  private myScrollContainer: ElementRef;

  private $destroyed = new Subject();

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  ngAfterViewChecked() {

    if (this.latestMessage) {
      this.scrollToBottom();
    }

  }

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }

    this.latestMessage = null;
  }

  /**
   * Emits back to the parent for reply to the particular message
   */
  replyToMsg(msg: KalgudiMessageDetails): void {

    this.replyMessage.emit(msg);
  }

  /**
   * Deleted the message
   */
  onDeleteMessage(msg: KalgudiMessageDetails): void {
    this.deleteMessage.emit(msg);
  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
  }

  /**
   * Hide the message
   */
  hideMessage(msg: KalgudiMessageDetails): void {
    this.onHideMessage.emit(msg);
  }

}
