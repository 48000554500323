import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RoutesRecognized,
} from '@angular/router';
import { KalgudiSpinnerService } from '@kalgudi/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouterEventsService {

  constructor(
    private router: Router,
    private spinner: KalgudiSpinnerService,
  ) {

    this.router.events.subscribe(e => {

      // console.log(e);

      if (e instanceof NavigationStart) {
        this.onNavigationStart(e);
      } else  if (e instanceof NavigationEnd) {
        this.onNavigationEnd(e);
      } else if (e instanceof NavigationError) {
        this.onNavigationError(e);
      } else if (e instanceof NavigationCancel) {
        this.onNavigationCancel(e);
      }
    });
  }


  /**
   * Gets the activated route data.
   */
  get routeData$(): Observable<any> {
    return this.router.events
      .pipe(

        // A route change event is fired on successful route NavigationEnd event
        filter(route => route instanceof RoutesRecognized),

        // Find the last child in the activated route
        // Last child contains the activated route data
        map((route: RoutesRecognized) => this.getLastChild(route.state.root)),

        // Return activated route data
        map(route => route.data)
      );
  }

  /**
   * Gets the last child in the route tree
   */
  private getLastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {

    return route.firstChild
      ? this.getLastChild(route.firstChild)
      : route;
  }


  /**
   * Event handler for router navigation start
   */
  private onNavigationStart(e: NavigationStart): void {
    this.spinner.toggleRouteLoadingProgress(true);
  }

  /**
   * Event handler for router navigation end
   */
  private onNavigationEnd(e: NavigationEnd): void {
    this.spinner.toggleRouteLoadingProgress(false);
  }

  /**
   * Event handler for router navigation error
   */
  private onNavigationError(e: NavigationError): void {
    this.spinner.toggleRouteLoadingProgress(false);
  }

  /**
   * Event handler for router navigation canceled
   */
  private onNavigationCancel(e: NavigationCancel): void {
    this.spinner.toggleRouteLoadingProgress(false);
  }
}
