<ng-container *ngIf="postDetails && (postDetails?.shareId || postDetails?.scheduleId)">
  <div [id]="postDetails?.shareId" class="mt-2 share-update-tile"
    [@kalgudiStreamTile]="tileAnimationState"
    [ngClass]="{'deleted': tileAnimationState === 'deleted'}">

    <mat-card class="update-tile p-0" [ngClass]="{'cursor-pointer': !fullView}" (click)="toFullview(postDetails?.shareId)">

      <div [ngClass]="{'mb-2': postDetails?.satelliteAdvisories}">
        <kl-share-update-header
          [scheduledUpdates]="scheduledUpdates"
          [latestActivity]="postDetails?.latestActivity"
          [authorId]="postDetails?.authorId"
          [entityId]="postDetails?.entityId"
          [userProfileKey]="(basicUserDetails$ | async)?.profileKey"
          [msgType]="postDetails?.msgTypes && postDetails?.msgTypes.length && postDetails?.msgTypes[0] ? postDetails?.msgTypes[0] : ''"
          (deletePost)="deletePost()"
          [memberRole]="memberRole"
          [showMatMenu]="showMatMenu"
          (updateScheduledPost)="updateScheduledPost()"
          (report)="report(postDetails?.shareText, postDetails?.shareId)"
          (shareUpdate)="showShareUpdateDialog(postDetails)"
          (sendAsSms)="openAiPredictionsSendSmsDialog(postDetails?.entityId)"
          (shareWhatsapp)="shareOnWhatsapp()"
          (refreshAdvisory)="refreshLatestSatelliteAdvisory(postDetails?.authorId, postDetails?.satelliteAdvisories?.landDetails?.landId)">
        </kl-share-update-header>
      </div>


      <div class="p-2"  *ngIf="!postDetails?.satelliteAdvisories">
        <kl-post-author
          [scheduledTime]="postDetails?.scheduledTS ? postDetails?.scheduledTS : ''"
          [TS]="postDetails?.CT"
          [author]="showPageDetails && postDetails?.pageAuthorDetails ? postDetails?.pageAuthorDetails : postDetails?.author"
          avatarSize="md"
          postType="posted"
          [pageId]="postDetails?.entityId"
          [pageName]="postDetails?.filter?.pageTitle">
        </kl-post-author>
      </div>

      <!-- Post tile tag  -->
      <ng-container *ngIf="postDetails?.msgTypes && postDetails?.msgTypes?.length && postDetails?.msgTypes[0] !== null ">
        <kl-post-tile-tag [tags]="postDetails?.msgTypes"></kl-post-tile-tag>
      </ng-container>
      <!-- / Post tile tag  -->

      <!-- Product chip -->
      <section class="product-chip pl-2 mb-2" *ngIf="postDetails?.productTo">
        <mat-chip-list>
          <mat-chip>{{ postDetails?.productTo?.productName }}</mat-chip>
        </mat-chip-list>
      </section>
      <!--/ Product chip -->

      <div class="pl-2 mb-2" *ngIf="postDetails?.farmingSeasonTitle">
        <div *ngIf="postDetails?.farmingActivityDetails">
          <p class="date m-0">Activity performed on
            <span> {{postDetails?.farmingActivityDetails?.seasonDetails?.date | date: 'd MMM yy, hh:mm a'}}</span>
          </p>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" class="farming-season-tile">
          <div>
            <img src="assets/images/farming-season.png" width="35" height="35">
          </div>

          <div>
            <p class="m-0">
              <span class="title"><b>{{postDetails?.farmingSeasonTitle | titlecase}}</b></span>
              <span class="dot d-inline-block align-middle mx-1 " *ngIf="postDetails?.farmingActivityDetails?.seasonType"></span>
              <span class="season-type">{{ postDetails?.farmingActivityDetails?.seasonType | trimSpecialCharacters | titlecase}}</span></p>
            <p class="m-0 season" *ngIf="!postDetails?.farmingActivityDetails?.seasonDetails">Season</p>
            <p class="m-0 season" *ngIf="postDetails?.farmingActivityDetails?.seasonDetails">Activity:
              <span>{{postDetails?.farmingActivityDetails?.seasonDetails?.activityType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.irrigationType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.irrigationType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.fertilizerType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.fertilizerType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.weedType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.weedType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.pestsType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.pestsType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.diseaseType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.diseaseType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.harvestType"> - {{postDetails?.farmingActivityDetails?.seasonDetails?.harvestType | trimSpecialCharacters | titlecase}}</span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails.hours">
                {{postDetails?.farmingActivityDetails?.seasonDetails.hours}} hours
              </span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails.quantityApplied">
                <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails.quantityApplied?.value !== 0"> {{postDetails?.farmingActivityDetails?.seasonDetails.quantityApplied?.value}} </span>
                <span>{{postDetails?.farmingActivityDetails?.seasonDetails.quantityApplied?.unit}}</span>
              </span>
              <span *ngIf="postDetails?.farmingActivityDetails?.seasonDetails?.rateOfInfestation">
                {{postDetails?.farmingActivityDetails?.seasonDetails?.rateOfInfestation | trimSpecialCharacters | titlecase}}
              </span>

            </p>
          </div>
        </div>

      </div>

      <ng-container *ngIf="postDetails?.satelliteAdvisories">

        <div *ngIf="postDetails?.satelliteAdvisories?.landDetails">
          <kl-satellite-advisoires-location
            [disableLinkAction]="showLessData"
            [showMap]="!showLessData"
            [landDetails]="postDetails?.satelliteAdvisories?.landDetails"></kl-satellite-advisoires-location>
        </div>

        <div class="mx-3 my-2">
          <div class="land-details-tile"  *ngIf="postDetails?.satelliteAdvisories?.landDetails?.soilType || postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.value || postDetails?.farmingActivityDetails?.seasonDetails?.irrigationType">
            <div fxLayout="row">
              <div class="land-details" fxLayout="column" fxFlex="33.33%">
                <span>Salinity</span>
                <span class="sub-land-details"><b>
                  {{postDetails?.satelliteAdvisories?.landDetails?.salinity | trimSpecialCharacters | titlecase }}
                  <span *ngIf="!postDetails?.satelliteAdvisories?.landDetails?.salinity">-</span>
                </b></span>
              </div>
              <div class="border-right"></div>
              <div class="land-details" fxLayout="column" fxFlex="33.33%">
                <span>Salinity level</span>
                <span class="sub-land-details"><b>
                  {{postDetails?.satelliteAdvisories?.landDetails?.salinityLevel | titlecase}}
                  <span *ngIf="!postDetails?.satelliteAdvisories?.landDetails?.salinityLevel">-</span>
                </b></span>
              </div>
              <div class="border-right"></div>
              <div class="land-details" fxLayout="column" fxFlex="33.33%">
                <span>Soil type</span>
                  <span class="sub-land-details"><b>
                    {{postDetails?.satelliteAdvisories?.landDetails?.soilType | trimSpecialCharacters | titlecase}}
                    <span *ngIf="!postDetails?.satelliteAdvisories?.landDetails?.soilType">-</span>
                  </b></span>
              </div>
            </div>
            <div fxLayout="row">
              <div class="land-details" fxLayout="column" fxFlex="33.3%">
                <span>Water level</span>
                <span class="d-inline-block mt-1">
                  <span class="sub-land-details mr-1"><b>{{postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.value}}</b></span>
                  <span class="sub-land-details"><b>{{postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.unit | trimSpecialCharacters | titlecase}}</b></span>
                  <span *ngIf="!postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.unit && !(postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.value == 0) && !postDetails?.satelliteAdvisories?.landDetails?.waterLevel?.value"><b>-</b></span>
                </span>
              </div>
              <div class="border-right"></div>
              <div class="land-details" fxLayout="column" fxFlex="66.7%">
                <span>Irrigation type</span>
                  <span class="sub-land-details"><b>
                    {{postDetails?.farmingActivityDetails?.seasonDetails?.irrigationType | trimSpecialCharacters | titlecase}}
                    <span *ngIf="!postDetails?.farmingActivityDetails?.seasonDetails?.irrigationType">-</span>
                  </b></span>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="postDetails?.satelliteAdvisories?.soilMoisture?.s3Url">
          <kl-soil-moisture-details
            [hideGauge]="showLessData"
            [soilMoistureDetails]="postDetails?.satelliteAdvisories?.soilMoisture"
            [landDetails]="postDetails?.satelliteAdvisories?.landDetails"
            [soilMoistureImage]="postDetails?.satelliteAdvisories?.soilMoisture?.s3Url"
            [bbox]="postDetails?.satelliteAdvisories?.bbox"
            [estimatedTime]="postDetails?.LUT ? postDetails?.LUT : postDetails?.CT">
          </kl-soil-moisture-details>
        </div>

        <div *ngIf="(postDetails?.satelliteAdvisories?.nitrogen?.s3Url && !showLessData) || (showLessData && !postDetails?.satelliteAdvisories?.soilMoisture?.s3Url)">
          <kl-nitrogen-details
            [nitrogenDetails]="postDetails?.satelliteAdvisories?.nitrogen"
            [nitrogenImage]="postDetails?.satelliteAdvisories?.nitrogen?.s3Url"
            [landDetails]="postDetails?.satelliteAdvisories?.landDetails"
            [bbox]="postDetails?.satelliteAdvisories?.bbox"
            [estimatedTime]="postDetails?.LUT ? postDetails?.LUT : postDetails?.CT">
          </kl-nitrogen-details>
        </div>

        <div *ngIf="(postDetails?.satelliteAdvisories?.phosphorous?.s3Url && !showLessData) || (showLessData && !postDetails?.satelliteAdvisories?.soilMoisture?.s3Url && !postDetails?.satelliteAdvisories?.nitrogen?.s3Url)">
          <kl-phosphorus-details
            [phosphorusDetails]="postDetails?.satelliteAdvisories?.phosphorous"
            [phosphorusImage]="postDetails?.satelliteAdvisories?.phosphorous?.s3Url"
            [landDetails]="postDetails?.satelliteAdvisories?.landDetails"
            [bbox]="postDetails?.satelliteAdvisories?.bbox"
            [estimatedTime]="postDetails?.LUT ? postDetails?.LUT : postDetails?.CT">
          </kl-phosphorus-details>
        </div>

        <!-- Crop health data(Plant health) -->
        <div *ngIf="(postDetails?.satelliteAdvisories?.plantHealth?.s3Url)">
          <kl-crop-health-data
            [plantHealthDetails]="postDetails?.satelliteAdvisories?.plantHealth"
            [plantHealthImage]="postDetails?.satelliteAdvisories?.plantHealth?.s3Url"
            [landDetails]="postDetails?.satelliteAdvisories?.landDetails"
            [bbox]="postDetails?.satelliteAdvisories?.bbox"
            [estimatedTime]="postDetails?.LUT ? postDetails?.LUT : postDetails?.CT">
          </kl-crop-health-data>
        </div>
        <!--/ Crop health data(Plant health) -->

      </ng-container>

      <ng-container *ngIf="!postDetails?.satelliteAdvisories">

        <div class="post-tile-attachments">
          <kl-post-tile-attachments (fullView)="toFullview(postDetails?.shareId)" [isFullviewPage]="fullView"   [shareUpdate]="postDetails"></kl-post-tile-attachments>
        </div>

        <div class="p-3" *ngIf="postDetails?.productsMetaData?.length">
          <kl-share-update-products [shareProducts]="postDetails?.productsMetaData" [store]="postDetails?.pageDetails?.pageId === '0066' ? farmStoreUrl : null"></kl-share-update-products>
        </div>

      </ng-container>


      <!-- Count of Likes and Comments -->
      <!-- <div class="count pt-1 px-3 text-muted" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px"> -->

        <!-- Likes count -->
        <!-- <button *ngIf="postDetails?.likesCount" title="Likes list" fxLayoutAlign="start center"  mat-icon-button class="action-buttons like-button counts rounded-0" (click)="openLikesListDialog();$event.stopPropagation()">
          <div fxLayout="row">
            <svg-icon class="svg-icon mr-1" src="assets/svgs/fill-like.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12}" fxLayoutAlign="start center">
            </svg-icon>
            <p class="mb-0 action-title likes-count">{{ postDetails?.likesCount | modernNumber }}</p>
          </div>
        </button> -->
        <!-- / Likes count -->

        <!-- Comments count -->
        <!-- <div class="counts comment-count w-100" *ngIf="postDetails?.commentsCount" fxLayout fxLayoutGap="3px" fxLayoutAlign="end center">
          <span *ngIf="postDetails?.commentsCount">{{ postDetails?.commentsCount }}</span>
          <span [ngPlural]="postDetails?.commentsCount">
            <ng-template ngPluralCase="=1"><span translate>Comment</span></ng-template>
            <ng-template ngPluralCase="other"><span translate>Comments</span></ng-template>
          </span>
        </div> -->
        <!-- / Comments count -->

      <!-- </div> -->
      <!-- Count of Likes and Comments -->

      <div *ngIf="!scheduledUpdates">
        <kl-share-update-action-bar
          [shareId]="postDetails?.shareId"
          [likes]="postDetails?.likes"
          [liked]="postDetails?.liked"
          [likesCount]="postDetails?.likesCount"
          [commentsCount]="postDetails?.commentsCount"
          [geoLocation]="postDetails?.geoLocation"
          [url]="url"
          (like)="like()"
          (commented)="toggleCommentList()"
          (fullView)="toFullview(postDetails?.shareId)"
          >
          <div class="satellite-advisory-action" *ngIf="postDetails?.satelliteAdvisories && showLessData">
            <button mat-button (click)="toFullview(postDetails?.shareId); $event.stopPropagation()">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div>
                  <p class="mb-0" translate>View All</p>
                </div>
                <div fxLayoutAlign="center center">
                  <mat-icon class="text-center">trending_flat</mat-icon>
                </div>
              </div>
            </button>
          </div>
        </kl-share-update-action-bar>

        <!-- Comment section -->
        <div class="mx-xl-3" *ngIf="showCommentForm || fullView && isLoggedIn">
          <kl-social-comments
            [commentForm]="commentsForm"
            [progress]="actionProgress"
            [comments]="postDetails?.recentComments "
            (commented)="comment()">
          </kl-social-comments>
        </div>
        <!-- /Comment form -->
      </div>
    </mat-card>
  </div>
</ng-container>
