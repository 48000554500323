import { Inject, Injectable, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutes } from '@app/constants';
import { environment } from '@app/env';
import { KalgudiAuthRouteConfig } from '@kalgudi/auth';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiCoreRouteConfig, KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiProgramsRouteConfig } from '@kalgudi/programs';
import { KalgudiProjectRouteConfig } from '@kalgudi/project-manager';
import { KalgudiSearchRouteConfig } from '@kalgudi/search';
import { KalgudiSocialRouteConfig } from '@kalgudi/social';
import { KalgudiStocksRouteConfig } from '@kalgudi/stocks';
import { KalgudiAccountsRouteConfig } from '@kalgudi/stocks/src/lib/config/accounts-route-config';
import { KalgudiSurveysRouteConfig } from '@kalgudi/surveys';
import { PartialData, RouteQueryParams } from '@kalgudi/types';
import { KalgudiWeatherRouteConfig } from '@kalgudi/weather';

@Injectable({
  providedIn: 'root'
})
export class CoreDefaultRouteConfigService implements
  KalgudiCoreRouteConfig,
  KalgudiAuthRouteConfig,
  KalgudiCommonRouteConfig,
  KalgudiSocialRouteConfig,
  KalgudiProgramsRouteConfig,
  KalgudiProjectRouteConfig,
  KalgudiSearchRouteConfig,
  KalgudiSurveysRouteConfig,
  KalgudiWeatherRouteConfig,
  KalgudiStocksRouteConfig,
  KalgudiAccountsRouteConfig {

  readonly pageNotFoundPath: string;
  readonly authGuardFailedPath: string;
  readonly noAuthGuardFailedPath: string;

  useHash: boolean;

  constructor(
    private router: Router,
    private zone: NgZone,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private util: KalgudiUtilityService
  ) {
    this.pageNotFoundPath = '';
    this.authGuardFailedPath = '/auth/signin';
    this.noAuthGuardFailedPath = '/app/home';

    this.useHash = true;
  }

  onGuardSuccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void { }

  onAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

    if(this.environment.appId === 'KUBER') {
      localStorage.setItem('navigateTo', state.url)
    }

    // Route to the auth guard failed path if configured
    if (this.authGuardFailedPath) {
      this.route(this.authGuardFailedPath);
    }
  }

  onNoAuthGuardFailure(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {

    // Route to the no auth guard failed path if configured
    if (this.noAuthGuardFailedPath) {
      this.route(this.noAuthGuardFailedPath);
    }
  }

  toHome(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_HOME;

    this.route(url, queryParams);

    return url;
  }

  toSocial(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SOCIAL;

    this.route(url, queryParams);

    return url;
  }

  toSocialShareUpdate(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SOCIAL_UPDATES;

    this.route(url, queryParams);

    return url;
  }

  toSocialShareUpdateFullview(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SOCIAL_UPDATES_VIEW.replace(':shareId', params.shareId);

    this.route(url, queryParams);

    return url;
  }

  toSocialQaStream(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SOCIAL_QA;

    this.route(url, queryParams);

    return url;
  }

  toSocialQaFullview(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SOCIAL_QA_VIEW.replace(':questionId', params.questionId);

    this.route(url, queryParams);

    return url;
  }

  toLogin(params?: PartialData, queryParams?: RouteQueryParams): string {

    // if (this.environment.appId === 'SHAKTIMAN') {

    //   window.open(this.environment.domain, '_self');

    // } else {

    //   const url =  AppRoutes.AUTH_LOGIN ;

    //   this.route(url, queryParams);

    //   return url;
    // }
    const url =  AppRoutes.AUTH_LOGIN ;

    this.route(url, queryParams);

    return url;
  }

  toSignup(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.AUTH_SIGNUP;

    this.route(url, queryParams);

    return url;
  }

  toResetPassword(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.AUTH_RESET_PASSWORD;

    this.route(url, queryParams);

    return url;
  }

  toVerifyOtp(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.AUTH_OTP;

    this.route(url, queryParams);

    return url;
  }

  toProfile(params?: PartialData, queryParams?: RouteQueryParams, openInNewTab?: boolean): string {

    const url = AppRoutes.APP_PROFILE_VIEW.replace(':profileKey', params.profileKey);

    this.route(url, queryParams, openInNewTab);

    return url;
  }

  toAiPrediction(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_AI_PREDICTION.replace(':profileKey', params.profileKey);

    this.route(url, queryParams);

    return url;
  }

  toMyProfile(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROFILES;

    this.route(url, queryParams);

    return url;
  }

  toProgramList(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAMS;

    this.route(url, queryParams);

    return url;
  }

  toSearch(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SEARCH;

    this.route(url, queryParams);

    return url;
  }

  toProgram(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toNewProgram(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_NEW;

    this.route(url, queryParams);

    return url;
  }


  toProgramUpdates(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramQa(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_QUESTIONS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSurveys(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_SURVEYS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }


  toProgramSurveyLogs(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_LOGS.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }


  toProgramSurveySubmission(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_FILL.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSurveyAdmins(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_ADMINS.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSurveyQuestions(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_QUESTIONS.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSurveyInfo(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_INFO.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSurveyMembers(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_MEMBERS.replace(':pageId', params.pageId).replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProgramSms(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_SMS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramReportActivity(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_REPORT_ACTIVITY.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramDigitalAssistance(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_DIGITAL_ASSISTANCE.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramProjects(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_PROJECTS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }


  toProgramTasks(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_TASKS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramTaskFullview(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_TASKS_VIEW.replace(':pageId', params.pageId).replace(':taskId', params.taskId);

    this.route(url, queryParams);

    return url;
  }

  toProductFullview(params?: PartialData, queryParams?: RouteQueryParams): string {

    const productKey = this.util.encodeUri(params.productLevel2Title).toLowerCase();

    let url = `${params.storeName}/p/c/${productKey}/${params.productIdLevel2}_${params.productIdLevel3}`;

    url = params.bulkOrder ? `${url}?bulkOrder=true` : url;

    window.open(url, '_blank');

    return url;
  }

  toProgramStocks(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_STOCKS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }


  toProgramProcessing(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_PROCESSING.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramInfo(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_INFO.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramAdmin(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_ADMINS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramContributors(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_CONTRIBUTORS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  toProgramMembers(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_MEMBERS.replace(':pageId', params.pageId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * Routes to page surveys list
   */
  toPageSurveys(pageId: string, params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEYS.replace(':pageId', pageId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * Routes to page satellite advisories all users
   */
  toPageSatelliteAdvisoriesAllUsers(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_VIEW_SATELLITE_ADVISORIES_ALL_USERS
      .replace(':pageId', params.pageId).replace(':syncId', params.syncId);

    this.zone.run(() => {
      this.router.navigate([url], queryParams);
      });

    // this.route(url, queryParams);

    return url;
  }

  /**
   * Routes to page surveys logs
   */
  toPageSurveyLogs(pageId: string, surveyId: string, params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_LOGS
      .replace(':pageId', pageId)
      .replace(':surveyId', surveyId);

    this.route(url, queryParams);

    return url;
  }


  /**
   * Routes to page surveys submission
   */
  toPageSurveySubmission(pageId: string, surveyId: string, params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROGRAM_SURVEY_FILL
      .replace(':pageId', pageId)
      .replace(':surveyId', surveyId);

    this.route(url, queryParams);

    return url;
  }

  toProjectList(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROJECTS;

    this.route(url, queryParams);

    return url;
  }

  toProject(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_PROJECT_VIEW.replace(':projectId', params.projectId);

    this.route(url, queryParams);

    return url;
  }

  toTasksList(queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_TASKS;

    this.route(url, queryParams);

    return url;
  }

  toTask(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_TASK_VIEW.replace(':projectId', params.projectId).replace(':taskId', params.taskId);

    this.route(url, queryParams);

    return url;
  }


  toUsersSearch(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SEARCH_USERS;

    this.route(url, queryParams);

    return url;
  }

  toProductsSearch(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SEARCH_PRODUCTS;

    this.route(url, queryParams);

    return url;
  }

  toSurveyList(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SURVEYS;

    this.route(url, queryParams);

    return url;
  }

  toSurveyFullView(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SURVEYS_VIEW.replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toSurveyLogs(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SURVEYS_VIEW_LOGS.replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toSurveySubmission(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SURVEYS_VIEW_FILL_SURVEY.replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toSurveyQuestions(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_SURVEYS_VIEW_QUESTIONS.replace(':surveyId', params.surveyId);

    this.route(url, queryParams);

    return url;
  }

  toWeather(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_WEATHER;

    this.route(url, queryParams);

    return url;
  }

  toCreditDetails(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_CREDITS_DETAILS;

    this.route(url, queryParams);

    return url;
  }

  toCropFullview(params?: PartialData, queryParams?: RouteQueryParams, openInNewTab?: boolean): string {

    const url = AppRoutes.APP_CROP_FULLVIEW.replace(':profileKey', params.profileKey).replace(':cropId', params.cropId).replace(':cropTitle', params.cropTitle);

    this.route(url, queryParams, openInNewTab);

    return url;
  }

  toFpoCropSeasons(params?: PartialData, queryParams?: RouteQueryParams, openInNewTab?: boolean): string {

    const url = AppRoutes.APP_FPO_CROP_SEASONS.replace(':profileKey', params.profileKey).replace(':cropId', params.cropId).replace(':cropTitle', params.cropTitle);

    this.route(url, queryParams, openInNewTab);

    return url;
  }

  toCropSettings(params?: PartialData, queryParams?: RouteQueryParams, openInNewTab?: boolean): string {

    const url = AppRoutes.APP_CROP_SETTINGS.replace(':profileKey', params.profileKey).replace(':cropId', params.cropId).replace(':cropTitle', params.cropTitle);

    this.route(url, queryParams, openInNewTab);

    return url;
  }

  toBaseProductListPage(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_BASE_PRODUCT_LIST.replace(':profileKey', params.profileKey)

    this.route(url, queryParams);

    return url;
  }

  toLiveStockListPage(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_LIVE_STOCK_LIST.replace(':profileKey', params.profileKey)

    this.route(url, queryParams);

    return url;
  }

  toStoreProductView(params?: PartialData, queryParams?: RouteQueryParams): string {
    const { product, store }  = params;
    const productName = this.util.encodeUri(product.productName_level2).toUpperCase();

    const url = `${store || environment.storeDomain}/p/c/${productName}/${product.productId_level2}_${product.productId_level3}`;

    this.util.openUrl(url);

    return url;
  }


  /**
   * Routes the app to the specific url
   */
  private route(url: string, queryParams?: RouteQueryParams, openInNewTab?: boolean): void {

    if (openInNewTab) {

      this.newTab(url, queryParams);

    } else {
      this.router.navigate([url], { queryParams });
    }

  }

  /**
   * Opens the url in new tab
   */
  private newTab(url: string, queryParams?: RouteQueryParams): void {

    const useHash = environment.useHash;

    const routeUrl = this.router.serializeUrl(this.router.createUrlTree([url], { queryParams }));
    window.open(`${useHash ? '#' : ''}${routeUrl}`, '_blank');
  }

  /**
   * To fpo admin app
   */
  toFpoAdminApp(params?: PartialData, queryParams?: RouteQueryParams): void {

    const url = 'https://fpo.kalgudi.com/admin';

    window.open(url, '_blank');
  }

  /**
   * To tasks members
   */
  toTasksMembers(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_TASK_MEMBERS_DA.replace(':projectId', params.projectId).replace(':taskId', params.taskId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * To lot full view
   */
  toLotFullView(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_LOT_FULLVIEW.replace(':lotId', params.lotId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * To StockBoard full view
   */
  toStockBoardFullView(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCK_BOARD_FULLVIEW.replace(':productId', params.productId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * To create lot
   */
  toCreateLot(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_CREATE_LOT;

    this.route(url, queryParams);

    return url;
  }

  /**
   * To lot page
   */
  toLotPage(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_LOT_PAGE;

    this.route(url, queryParams);

    return url;
  }

  /**
   * To Create transaction
   */
  toCreateTransaction(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_CREATE_TRANSACTION;

    this.route(url, queryParams);

    return url;
  }

  /**
   * To transaction full view
   */
  toTransactionFullView(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_TRANSACTION_FULLVIEW.replace(':transactionId', params.transactionId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * To accounts sale full view
   */
  toSaleAccountsFullView(params?: PartialData): string {

    const url = AppRoutes.APP_ACCOUNTS_SALE_FULLVIEW.replace(':transactionId', params.transactionId);

    this.route(url);

    return url;
  }

  /**
   * To accounts purchase full view
   */
  toPurchaseAccountsFullView(params?: PartialData): string {

    const url = AppRoutes.APP_ACCOUNTS_PURCHASE_FULLVIEW.replace(':transactionId', params.transactionId);

    this.route(url);

    return url;
  }

  /**
   * To skus full view
   */
  toSkusFullView(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_SKUS_FULL_VIEW
      .replace(':l2Id',params.productLevel2Id )
      .replace(':l3Id', params.productLevel3Id);

    this.route(url, queryParams);

    return url;
  }


  /**
   * To transaction page
   */
  toTransactionPage(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_TRANSACTION_PAGE;

    this.route(url, queryParams);

    return url;
  }

  /**
   * To transaction payment details
   */
  toTransactionPaymentDetails(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = AppRoutes.APP_STOCKS_TRANSACTION_PAYMENT_DETAILS.replace(':transactionId', params.transactionId);

    this.route(url, queryParams);

    return url;
  }

  /**
   * Navigates to user creation page
   * @param params
   * @param queryParams
   * @returns
   */
  navigateToUserCreation(params?: PartialData, queryParams?: RouteQueryParams): string {

    const url = '';

    this.route(url, queryParams);

    return url;
  }
}
