import { Inject, Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { checkMobileDevice, KalgudiFilteredSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiPageSurveyDetails, KalgudiSurveyDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiSurveysRouteConfig } from '../../../config';
import { SurveyStateService } from '../../../services/survey-state.service';
import { SurveyActionService } from '../services/survey-action.service';
import { SurveysListService } from '../services/surveys-list.service';

/**
 * Kalgudi survey stream class. Defines logic to filter, search and get surveys list.
 *
 * @author Ashwini Vadde, Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSurveyList<T extends KalgudiSurveyDetails = KalgudiSurveyDetails>
  extends KalgudiFilteredSearchStream<T> {

  @Input()
  overrideSurveyFulView: (survey: KalgudiPageSurveyDetails) => void;

  /**
   * Survey filter form
   */
  filterForm: FormGroup;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  /**
   * Gets, the member role form field
   */
  private get membersRoleForm(): AbstractControl {
    return this.filterForm.get('memberRole');
  }


  protected appRouting: KalgudiSurveysRouteConfig;
  private surveysListService: SurveysListService;
  private surveyStateService: SurveyStateService;
  private surveyActionService: SurveyActionService;


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.appRouting          = this.injector.get<KalgudiSurveysRouteConfig>(KL_ROUTE_CONFIG);
    this.surveysListService  = this.injector.get(SurveysListService);
    this.surveyStateService  = this.injector.get(SurveyStateService);
    this.surveyActionService = this.injector.get(SurveyActionService);

    // Define filters form
    this.filterForm = new FormGroup({
      memberRole: new FormControl('')
    });

    this.surveyStateService.action$
      .pipe(
        takeUntil(this.destroyed$),

        // Filter list filter update actions
        filter(action => action.type === 'SURVEY_LIST_FILTER_UPDATE'),

        // Only payload is essential
        map(action => action.payload)
      )
      .subscribe(surveyFilter => this.updateFilter(surveyFilter));
  }

  /**
   * Opens create survey dialog box
   */
  openCreateSurveyDialog(extraParams: PartialData = {}) {

    this.surveyActionService.showCreateSurveyDialog(extraParams)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        map(res => res.data.surveyDetails)
      )
      .subscribe(
        (res: T) => {

          this.openSurveyFullView(res);
          // this.appRouting.toSurveyFullView({surveyId: res.surveyId});
        }
      );
  }

  /**
   * Navigating to survey full view
   * @param id
   */
  openSurveyFullView(survey: T | KalgudiPageSurveyDetails): void {
    const surveyId = survey.surveyId;

    if (this.overrideSurveyFulView) {
      this.overrideSurveyFulView(survey as KalgudiPageSurveyDetails);

      return;
    }

    if (checkMobileDevice()) {
      this.appRouting.toSurveyFullView({ surveyId });
    } else if (survey.role === this.memberRoles.ADMIN ) {

      this.appRouting.toSurveyLogs({ surveyId });
    } else {
      this.appRouting.toSurveySubmission({ surveyId });
    }
  }

  /**
   * Reset the stream of survey list if clone of survey is created
   */
  cloneSurveyDetailsAccepted(accepted: boolean) {
    if(accepted) {
      this.resetStream();
    }
  }

  /**
   * Initializes survey list
   *
   * @override
   */
  protected initStream() {

    // Do not initialize the stream again
    if (this.isStreamInitialized) {
      return;
    }

    // Initialize the search stream
    super.initStream();

    this.isStreamInitialized = true;
  }

  /**
   * Implement this method to define your filter Api.
   *
   * @override
   */
  protected filterApi(
    searchKeyword: string,
    filters: any,
    offset: number,
    limit: number,
    extraParams?: PartialData
  ): Observable<KalgudiStreamData> {

    return this.surveysListService.fetchSurveysList(
      offset,
      limit,
      {
        role: filters.memberRole,
        ...extraParams,
      });
  }

  /**
   * Updates the survey filter to the survey filter form and call the filter service.
   */
  private updateFilter(surveyFilter: string): void {

    this.membersRoleForm.patchValue(surveyFilter);
    this.filter();
  }

}
