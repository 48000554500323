<main id="business-activities-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header p-3" fxLayout fxLayoutAlign="space-between center">
    <h5 class="mb-0 text-white header" mat-dialog-title translate>Business Activities</h5>
    <button mat-icon-button>
      <mat-icon class="text-white" (click)="cancel()">clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content p-0">
    <kl-business-activities-form
      (closeDialog)="ok()"
      [profileKey]="profileKey"
      [basicActivityFormDetails]="basicActivityFormDetails"
      [pageId]="pageId"
      [isEditDialog]="isEditDialog">
    </kl-business-activities-form>
  </div>
</main>
