import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Inject, Injectable } from '@angular/core';
import { KalgudiOrgProfileAboutUs } from '../../classes/kalgudi-org-profile-about-us';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-fpo-over-view',
  templateUrl: './fpo-over-view.component.html',
  styleUrls: ['./fpo-over-view.component.scss']
})
export class FpoOverViewComponent extends KalgudiOrgProfileAboutUs implements OnInit {

  isPublicPage: boolean;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
    private router: Router,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,

  ) { super(injector,fb); }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  onDestroyed(): void {}

  /**
   * Opens image full view
   */
  openImageFullView(attachments: any): void {
    let imgUrl = 'https://kalgudidata.s3.amazonaws.com' + attachments;
    let imgObj = [
      {url: imgUrl}
    ]
    this.kalgudiPhotoswipe.open(imgObj).subscribe();
  }
}
