import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@app/env';
import { KalgudiLazyHttpClientModule, LAZY_API_SYNC_INTERCEPTOR, OnNetworkApiSyncService } from '@kalgudi/common/lazy-http';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import {
  DataStore,
  KalgudiAppService,
  KalgudiCoreModule,
  KalgudiHttpInterceptorService,
  LocalStorageDS,
  MAT_LONG_DATE_FORMAT,
  initializeAppFactory,
} from '@kalgudi/core';
import { KalgudiProfilesModule } from '@kalgudi/profiles';
import { KalgudiFcmModule } from '@kalgudi/third-party/fcm';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { KalgudiLazyLoaderModule } from '@kalgudi/third-party/lazy-loader';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { KalgudiWeatherModule } from '@kalgudi/weather';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CORE_LIB_CONFIG } from './config';
import { i18Loader } from './i18n-loader';
import { MapHttpToHttpsService } from './interceptors/map-http-to-https.service';
import { HomeModule } from './modules/home/home.module';
import { StoreModule } from './modules/store/store.module';
import { SharedModule } from './shared/shared.module';
import { Router } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    // NOTE:
    // 1. Don't remove required for the Kalgudi Core 'root' services
    // 2. Don't include the HttpClientModule again in any other module. Doing so
    //    will reinitialize the HttpClientModule and its interceptors configured
    HttpClientModule,

    // Material
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatDialogModule,

    // Root route
    AppRoutingModule,

    // Core app modules
    SharedModule,
    StoreModule,
    HomeModule,

    AgmCoreModule,


    // i18n language translation
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (i18Loader),
        deps: [ HttpClient ]
      }
    }),

    // Kalgudi third party libraries wrapper
    // Required to load assets dynamically, removal of this
    // will enforce all assets to load eagerly.
    KalgudiLazyLoaderModule.forRoot(),

    // Ensure to have KalgudiLazyLoaderModule loaded before this
    KalgudiPhotoswipeModule.forRoot({
      scripts: [
        'assets/lib/photoswipe-4.1.2/photoswipe.min.js',
        'assets/lib/photoswipe-4.1.2/photoswipe-ui-default.min.js'
      ],
      css: [
        'assets/lib/photoswipe-4.1.2/photoswipe.min.css',
        'assets/lib/photoswipe-4.1.2/default-skin.min.css'
      ],
    }),

    // Initialize FCM plugin
    AngularFireModule.initializeApp(environment.firebaseConfig),
    KalgudiFcmModule.forRoot({
      appId: environment.appId,
      firebaseConfig: environment.firebaseConfig,
      kalgudiApi: environment.restBaseUrlV2
    }),

    // Kalgudi Google places Api
    KalgudiGooglePlacesModule.forRoot(environment.googlePlacesApi),

    // Kalgudi libraries
    KalgudiProfilesModule.forRoot(CORE_LIB_CONFIG),
    KalgudiCoreModule.forRoot(CORE_LIB_CONFIG),
    KalgudiLazyHttpClientModule,
    KalgudiWeatherModule.forRoot(CORE_LIB_CONFIG),
    KalgudiGoogleLanguageTranslatorModule.forRoot({ apiKey: environment.googlePlacesApi }),
    KalgudiGoogleGeoLocationModule.forRoot(environment.googlePlacesApi),

    KalgudiBreadcrumbsModule,

    NguCarouselModule,
    // NgxMaterialTimepickerModule,

    // Register service worker for production
    // Run `ng serve --prod --ssl=true` to check it locally
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [KalgudiAppService, Router],
      multi: true
    },
    // SurveyStateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KalgudiHttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MapHttpToHttpsService,
      multi: true,
    },
    {
      provide: DataStore,
      useClass: LocalStorageDS,
    },
    {
      provide: LAZY_API_SYNC_INTERCEPTOR,
      useClass: OnNetworkApiSyncService,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_LONG_DATE_FORMAT
    },

  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
