import { Injectable } from '@angular/core';
import { LatLong } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { PhotoswipeAngularWrapperService } from './photoswipe-angular-wrapper.service';
import { PhotoswipeAttachments } from './types';


/**
 * Kalgudi angular wrapper service for photoswipe. Use this service to open image preview.
 *
 * It has two public exposed methods `open` and `close` which are used to open and
 * close the image fullview.
 *
 * @example
 * photoswipe.open([]).subscribe();
 * photoswipe.close();
 */
@Injectable()
export class KalgudiPhotoswipeService {

  constructor(
    private libraryWrapper: PhotoswipeAngularWrapperService,
  ) { }


  /**
   * Opens photoswipe image viewer
   *
   * @param attachments List of attachments
   * @param prefixDomain Domain name with protocol if the image url is relative
   * @param selectedIndex Start previewing images from the specified selected index
   */
  open(
    attachments: { url: string, context?: string, msgType?: string, geoLocation?: LatLong}[],
    prefixDomain: string = '',
    selectedIndex: number = 0
  ): Observable<PhotoswipeAttachments> {

    return this.libraryWrapper.open(attachments, prefixDomain, selectedIndex);
  }

  /**
   * Closes the image fullview preview window.
   */
  close(): void {
    this.libraryWrapper.close();
  }

}
