import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { KalgudiStoreProduct } from '@kalgudi/types';

import { KalgudiStoreProductsFormControl } from '../../classes/kalgudi-store-products-form-control';

const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StoreProductsFormControlComponent),
  multi: true,
};

@Component({
  selector: 'kl-store-products-form-control',
  templateUrl: './store-products-form-control.component.html',
  styleUrls: ['./store-products-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class StoreProductsFormControlComponent extends KalgudiStoreProductsFormControl implements OnInit {

  @Input()
  showSelectedProducts: boolean;

  @Input()
  buttonType: string;

  @Input()
  selectedProducts: KalgudiStoreProduct[];

  @Input()
  showChipButton = false;

  @Input()
  showBorder: boolean;

  @Output()
  remove = new EventEmitter<number>();

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Emits an event back to parent to remove product
   */
  removeProduct(index: number): void {
    this.remove.emit(index);
  }
}
