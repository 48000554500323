import { Injector, Input, Directive } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  AddProjectMembersResponse,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  PROJECT_TARGET_AUDIENCE_TYPE,
} from '@kalgudi/types';

import { AddProjectMembersService } from '../services/add-project-members.service';

@Directive()
export abstract class SearchMembers {


  @Input()
  projectId: string;

  selectedUsersMap: KalgudiUsersMap = {};

  profileKeys: string[];

  progress: boolean;


  private addProjectMemberService: AddProjectMembersService;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {

    this.addProjectMemberService = this.injector.get<AddProjectMembersService>(AddProjectMembersService);
    this.notification = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
  }

  get selectedUsers(): KalgudiUserBasicDetails[] {

    return Object.values(this.selectedUsersMap);
  }

  /**
   * Calls the api to add project members
   */
  addMember() {

    // Get share payload, to be implemented by its child
    const payload = this.getAddMembersPayload(this.selectedUsers);

    this.progress = true;

    this.addProjectMemberService.addProjectMembers(this.projectId, payload)
      .subscribe(
        res => {
          this.progress = false;
          this.notification.showMessage('Added members successfully');
          this.onAddingMembers(res);
        },
        err => this.onMembersFetchError(err)
      );
  }


  /**
   * Construct and gets, the add members payload.
   */
  protected getAddMembersPayload(users: KalgudiUserBasicDetails[]): AddProjectMembersResponse {


    this.profileKeys = users.map(u => u.profileKey);

    const payload: AddProjectMembersResponse  = {
      type: PROJECT_TARGET_AUDIENCE_TYPE.UNICAST,
      users: this.profileKeys,
    };

    return payload;
  }


  protected onAddingMembers(res): void {}

  /**
   * Event handler for adding members API errors.
   */
  protected onMembersFetchError(err: Error): void {
    this.notification.showMessage(err.message);
  }

}
