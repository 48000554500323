<div>
  <kl-google-places-autocomplete [formControl]="location"></kl-google-places-autocomplete>

  <div class="geo-fencing">
    <kl-geo-location-marker [formControl]="geoFencing" ></kl-geo-location-marker>
  </div>


  <!-- buttons -->
  <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-button color="warn" class="p-0" (click)="cancel()">Reset</button>
    <button mat-button color="primary" class="p-0" (click)="ok()">Ok</button>
  </div>
</div>
