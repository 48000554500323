import { EventEmitter, Inject, Input, Output, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails, StringAnyMap } from '@kalgudi/types';
import { Observable } from 'rxjs';

/**
 * Base definition for kalgudi users picker. A kalgudi user picker is a stream
 * of users either all kalgudi users, my connects, program members, my employees etc.
 *
 * It extends the features of Kalgudi stream to take the features of a stream.
 *
 * Child of this class must use `searchUsers()` method to search list of users
 * and `toggleUserSelection()` to select or deselect an user.
 *
 * Add the `searchForm` to the search input form and input text form control name
 * as `searchText`.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiUsersPickerBase extends KalgudiSearchStream<KalgudiUserBasicDetails> {

  /**
   * `true` if we can select multiple users, otherwise `false`.
   */
  @Input()
  multiSelect: boolean;

  /**
   * Map of all selected users where key is their profile key and value
   * is of type T.
   */
  @Input()
  selectedUsersMap: { [key: string]: KalgudiUserBasicDetails } = {};

  /**
   * Required for the two way binding of `selectedUsersMap`
   */
  @Output()
  selectedUsersMapChange = new EventEmitter<{ [key: string]: KalgudiUserBasicDetails }>();

  protected defaultSearchKeyword = 'a';

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up parent
    super(notification, util);

    // Use default search keyword as a
    // this.defaultSearchKeyword = 'a';

    this.pageLimit = 10;
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the list of selected users.
   */
  get selectedUsers(): KalgudiUserBasicDetails[] {
    return Object.values(this.selectedUsersMap);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Adds a user to the selected user list if not selected.
   * If already selected then removes from the list of selected users.
   *
   * Returns boolean that defines the current status of the user.
   *
   * @param user User to select
   *
   * @returns `true` if user is added to the selected users list,
   * otherwise `false`.
   */
  toggleUserSelection(user: KalgudiUserBasicDetails): boolean {

    // If multi select not enabled then clear the selected users list
    if (!this.multiSelect) {
      this.deSelectAll();
    }

    // Check if the user is already selected or not
    const selected: boolean = this.isUserSelected(user);

    // Toggle the user selection
    selected ? this.deSelectUser(user) : this.selectUser(user);

    // Emit the event back on every selection
    this.selectedUsersMapChange.emit(this.selectedUsersMap);

    // Toggle initial selection state
    return !selected;
  }

  /**
   * Returns `true` if the user is selected otherwise `false`.
   */
  isUserSelected(user: KalgudiUserBasicDetails): boolean {

    // A user is selected if the user profile details exists in the
    // selected users map.
    return !!(this.selectedUsersMap[user.profileKey]);
  }

  /**
   * Marks a user as selected. Moves the user to the selected users list.
   */
  selectUser(user: KalgudiUserBasicDetails): void {

    this.selectedUsersMap[user.profileKey] = user;
  }

  /**
   * Marks a user a not selected. Deletes the user map entry from the selected
   * users list.
   */
  deSelectUser(user: KalgudiUserBasicDetails): void {

    delete this.selectedUsersMap[user.profileKey];
  }

  /**
   * Deselects all selected users.
   */
  deSelectAll(): void {

    // Empty the list of all selected users.
    this.selectedUsersMap = {};
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Api to search users. Implement this method that must return
   * Kalgudi user type. It defines the source of users list.
   */
  protected abstract userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserBasicSearchResults>;


  /**
   * Fetches stream items from the Kalgudi API.
   */
  protected searchApi(searchKeyword: string, offset: number, limit: number, extraParams?: StringAnyMap): Observable<KalgudiStreamData> {

    // If no search keyword specified search with default search keyword specified
    searchKeyword = searchKeyword || this.defaultSearchKeyword;

    return this.userApi(searchKeyword, offset, limit, extraParams);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
