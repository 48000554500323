import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { LoadMoreComponent } from './load-more.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    LoadMoreComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    // Kalgudi libraries
    KalgudiButtonModule,
    TranslateModule
  ],
  exports: [
    LoadMoreComponent,
  ]
})
export class KalgudiLoadMoreModule { }
