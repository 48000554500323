import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiTransferCredits } from '../../classes/kalgudi-transfer-credits';

@Component({
  selector: 'kl-transfer-credits',
  templateUrl: './transfer-credits.component.html',
  styleUrls: ['./transfer-credits.component.scss']
})
export class TransferCreditsComponent extends KalgudiTransferCredits implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(
      injector
    );
  }

  ngOnInit() {
  }

  protected onDestroyed(): void {}

}
