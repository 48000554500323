import { Component, Input, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { TraceabilityService } from '../../services/traceability.service';

@Component({
  selector: 'kl-output-store-production-details',
  templateUrl: './output-store-production-details.component.html',
  styleUrls: ['./output-store-production-details.component.scss']
})
export class OutputStoreProductionDetailsComponent implements OnInit {


  @Input()
  supplierList: any;

  @Input()
  orderId: string;

  panelOpenState = false;

  progress = false;

  selectedSupplier: string;

  supplierName: string;

  productionDetails: any[] = [];

  defaultProfilePic = 'assets/images/default-profile.jpeg';

  constructor(private traceabilityService: TraceabilityService) { }

  ngOnInit() {

    this.selectedSupplier = this.supplierList[0].farmerProfileKey;
    this.getTraceabilityDetails(this.supplierList[0].productLevel2Id, this.supplierList[0].productLevel3Id)

    this.supplierName = this.supplierList ? this.supplierList[0].farmerName : 'Select supplier';
  }

  onSupplierSelection(supplier: any) {

    this.supplierName = supplier.farmerName;

    const prevSelectedProfile = this.selectedSupplier;

    this.selectedSupplier = supplier.farmerProfileKey;

    if(this.selectedSupplier !== prevSelectedProfile) {
      this.productionDetails = [];
      this.getTraceabilityDetails(supplier.productLevel2Id, supplier.productLevel3Id)
    }
  }


  getTraceabilityDetails(l2Id, l3Id) {

    this.progress = true;
    this.traceabilityService.getTraceability(this.orderId, l2Id, l3Id)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe (
        res => {
          this.productionDetails = res.productions;
          // this.procurementDetails = res.procurements

        }
      )
  }
}
