import { ModuleWithProviders, NgModule } from '@angular/core';

import { KalgudiLazyLoaderService } from './kalgudi-lazy-loader.service';


/**
 * The lazy loader module must be included in the root module i.e. `AppModule`.
 */
@NgModule({
  declarations: [],
  imports: [],
})
export class KalgudiLazyLoaderModule {

  static forRoot(): ModuleWithProviders<KalgudiLazyLoaderModule> {

    return {
      ngModule: KalgudiLazyLoaderModule,
      providers: [
        KalgudiLazyLoaderService,
      ]
    };
  }
}
