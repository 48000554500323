import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';


@Component({
  selector: 'kl-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {

  @Input()
  taskActivityDetails: any;

  constructor(@Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig) { }

  ngOnInit() {
  }

  /**
   * Opens task full view
   */
  openTaskFullview() {

    if (this.taskActivityDetails.pageId) {

      this.appRouting.toProgramTaskFullview({ pageId: this.taskActivityDetails.pageId, taskId: this.taskActivityDetails.taskId });
    } else {

      this.appRouting.toTask({ projectId: this.taskActivityDetails.projectId, taskId: this.taskActivityDetails.taskId });
    }
  }

}
