import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CommonSearchBarModule,
  KalgudiAttachmentsPickerModule,
  KalgudiAttachUrlModule,
  KalgudiCommonModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiTimePickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiChipsModule } from '@kalgudi/common/ui/chips';
import { UsersTileModule } from '@kalgudi/common/ui/users-tile';
import { KalgudiDirectivesModule, KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import {
  KalgudiPromotionalShareUpdateModule,
  KalgudiShareWithModule,
  KalgudiSocialModule,
  ShareMemberPickerModule,
} from '@kalgudi/social';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AddProjectMembersModule } from '../add-project-members/add-project-members.module';
import { KalgudiProjectListModule } from '../project-list/project-list.module';
import { TaskCreationModule } from '../task-creation/task-creation.module';
import { AddTaskMembersModule } from './../add-task-members/add-task-members.module';
import { TaskActionsComponent } from './components/task-actions/task-actions.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { TaskFillComponent } from './components/task-fill/task-fill.component';
import { TaskFiltersComponent } from './components/task-filters/task-filters.component';
import { TaskFullViewDialogComponent } from './components/task-full-view-dialog/task-full-view-dialog.component';
import {
  TaskFullViewMobileDialogComponent,
} from './components/task-full-view-mobile-dialog/task-full-view-mobile-dialog.component';
import { TaskFullViewComponent } from './components/task-full-view/task-full-view.component';
import { TaskFullviewPageComponent } from './components/task-fullview-page/task-fullview-page.component';
import { TaskHeaderDaComponent } from './components/task-header-da/task-header-da.component';
import { TaskInfoComponent } from './components/task-info/task-info.component';
import { TaskListCalendarComponent } from './components/task-list-calendar/task-list-calendar.component';
import { TaskListGridComponent } from './components/task-list-grid/task-list-grid.component';
import { TaskListHeaderComponent } from './components/task-list-header/task-list-header.component';
import { TaskListTableComponent } from './components/task-list-table/task-list-table.component';
import { TaskMemberListDaComponent } from './components/task-member-list-da/task-member-list-da.component';
import { TaskMembersFullViewDaComponent } from './components/task-members-full-view-da/task-members-full-view-da.component';
import {
  TaskMembersSubmissionDaComponent,
} from './components/task-members-submission-da/task-members-submission-da.component';
import { TaskMembersTileDaComponent } from './components/task-members-tile-da/task-members-tile-da.component';
import {
  TaskPromotionalShareUpdateFormComponent,
} from './components/task-promotional-share-update-form/task-promotional-share-update-form.component';
import {
  TaskSelectedMembersListComponent,
} from './components/task-selected-members-list/task-selected-members-list.component';
import { TaskShareUpdateFormComponent } from './components/task-share-update-form/task-share-update-form.component';
import { TaskStatsDialogComponent } from './components/task-stats-dialog/task-stats-dialog.component';
import { TaskStatsMobileDialogComponent } from './components/task-stats-mobile-dialog/task-stats-mobile-dialog.component';
import { TaskStatsComponent } from './components/task-stats/task-stats.component';
import { TaskStatusDialogComponent } from './components/task-status-dialog/task-status-dialog.component';
import { TaskStatusComponent } from './components/task-status/task-status.component';
import { TaskSubmissionDetailsComponent } from './components/task-submission-details/task-submission-details.component';
import { TaskSubmissionDialogComponent } from './components/task-submission-dialog/task-submission-dialog.component';
import {
  TaskSubmissionMobileDialogComponent,
} from './components/task-submission-mobile-dialog/task-submission-mobile-dialog.component';
import { TaskSubmissionsComponent } from './components/task-submissions/task-submissions.component';
import { TaskTileComponent } from './components/task-tile/task-tile.component';
import { TaskUpdatesStreamComponent } from './components/task-updates-stream/task-updates-stream.component';
import { TaskUpdatesTileComponent } from './components/task-updates-tile/task-updates-tile.component';
import { KalgudiTaskActionsService } from './services/kalgudi-task-actions.service';
import { KalgudiTaskFullViewDialogService } from './services/kalgudi-task-full-view-dialog.service';
import { KalgudiTaskSubmissionDaApiService } from './services/kalgudi-task-submission-da-api.service';
import { KalgudiTaskSubmissionDaService } from './services/kalgudi-task-submission-da.service';
import { KalgudiTasksApiService } from './services/kalgudi-tasks-api.service';
import { KalgudiTasksService } from './services/kalgudi-tasks.service';
import { KalgudiTaskActivityStateService } from './services/task-activity-state.service';

@NgModule({
  declarations: [
    TaskTileComponent,
    TaskListHeaderComponent,
    TaskListGridComponent,
    TaskListTableComponent,
    TaskFiltersComponent,
    TaskFullViewComponent,
    TaskFullViewDialogComponent,
    TaskUpdatesStreamComponent,
    TaskUpdatesTileComponent,
    TaskFillComponent,
    TaskShareUpdateFormComponent,
    TaskFullViewMobileDialogComponent,
    TaskSubmissionsComponent,
    TaskSubmissionDialogComponent,
    TaskSubmissionDetailsComponent,
    TaskSubmissionMobileDialogComponent,
    TaskActionsComponent,
    TaskInfoComponent,
    TaskFullviewPageComponent,
    TaskStatusDialogComponent,
    TaskStatusComponent,
    TaskListCalendarComponent,
    TaskDetailsComponent,
    TaskStatsDialogComponent,
    TaskStatsMobileDialogComponent,
    TaskStatsComponent,
    TaskMembersSubmissionDaComponent,
    TaskHeaderDaComponent,
    TaskMemberListDaComponent,
    TaskMembersFullViewDaComponent,
    TaskMembersTileDaComponent,
    TaskSelectedMembersListComponent,
    TaskPromotionalShareUpdateFormComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatCardModule,
    MatTableModule,
    MatIconModule,
    MatPaginatorModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatTabsModule,
    MatTooltipModule,
    MatInputModule,
    MatProgressBarModule,
    MatChipsModule,
    MatExpansionModule,
    MomentDateModule,

    FlexLayoutModule,
    AngularSvgIconModule,

    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyModule.forChild(),

    // Angular calendar
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),

    ReactiveFormsModule,
    FormsModule,

    // Kalgudi modules
    KalgudiChipsModule,
    KalgudiPipesModule,
    UsersTileModule,
    KalgudiProjectListModule,
    KalgudiBreadcrumbsModule,
    TaskCreationModule,
    CommonSearchBarModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiAttachmentsPickerModule,
    KalgudiPipesModule,
    KalgudiShareUpdateModule,
    KalgudiButtonModule,
    KalgudiImageChipListModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiSocialModule,
    KalgudiCommonModule,
    KalgudiTimePickerModule,
    KalgudiPromotionalShareUpdateModule,
    TaskCreationModule,
    AddProjectMembersModule,
    AddTaskMembersModule,

    KalgudiUsersModule,
    KalgudiShareWithModule,

    KalgudiDirectivesModule,

    TranslateModule,
    ShareMemberPickerModule,
  ],
  exports: [
    TaskTileComponent,
    TaskListHeaderComponent,
    TaskListGridComponent,
    TaskListTableComponent,
    TaskFiltersComponent,
    TaskActionsComponent,
    TaskUpdatesStreamComponent,
    TaskShareUpdateFormComponent,
    TaskFillComponent,
    TaskSubmissionsComponent,
    TaskInfoComponent,
    TaskFullViewComponent,
    TaskFullviewPageComponent,
    TaskListCalendarComponent,
    TaskMembersFullViewDaComponent,
    TaskMembersSubmissionDaComponent,
  ],
  providers: [
    KalgudiTasksService,
    KalgudiTasksApiService,
    KalgudiTaskFullViewDialogService,
    KalgudiTaskActionsService,
    KalgudiTaskActivityStateService,
    KalgudiTaskSubmissionDaApiService,
    KalgudiTaskSubmissionDaService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    TaskFullViewDialogComponent,
    TaskFullViewMobileDialogComponent,
    TaskSubmissionDialogComponent,
    TaskSubmissionMobileDialogComponent,
    TaskStatusDialogComponent,
    TaskStatsDialogComponent,
    TaskStatsMobileDialogComponent,
  ]
})
export class KalgudiTasksModule { }
