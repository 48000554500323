export const SHARE_TYPES = {
  CONNECTS:              { label: 'Connections', value: 'CONNECTS' },
  ALL_SUBSCRIBERS:       { label: 'All subscribers', value: 'ALL_SUBSCRIBERS'} ,
  SPECIFIC_KALGUDI_USER: { label: 'Specific members', value: 'SPECIFIC_KALGUDI_USER'},
  SPECIFIC_SUBSCRIBERS:  { label: 'Specific groups', value: 'SPECIFIC_SUBSCRIBERS'}
};

export const ACTIVITY_TYPES = {
  comment: 'commented',
  like: 'liked',
  question: 'asked question',
  reply: 'replied',
  answer: 'answered'
};
