import { Component, Injector, Input, OnInit } from '@angular/core';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { FileMimeTypes, S3PolicyPathCategoryMap } from '@kalgudi/types';

import { KalgudiPageAddBulkMembers } from '../../classes/kalgudi-page-add-bulk-members';

@Component({
  selector: 'kl-program-add-bulk-members',
  templateUrl: './program-add-bulk-members.component.html',
  styleUrls: ['./program-add-bulk-members.component.scss']
})
export class ProgramAddBulkMembersComponent extends KalgudiPageAddBulkMembers implements OnInit {

  @Input()
  s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  sampleCsvLink = `https://kalgudidata.s3.amazonaws.com/data/pages/bulk-members/SampleFile.xlsx`;

  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  /**
   * Remove attachments
   */
  removeAttachment(): void {
    this.attachments.patchValue([]);
  }

  onDestroyed(): void {}

}
