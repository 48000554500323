import { KeyValue } from '@angular/common';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService, StoreProductsSearchService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { FileMimeTypes, KalgudiUserBasicDetails, ProgramShareRequest, ProgramShareUpdate } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import {
  KalgudiPagesMembersPickerService,
} from '../../../program-members-picker-dialog/services/kalgudi-pages-members-picker.service';
import { KalgudiProgramShareUpdate } from '../../../program-share-updates/classes/kalgudi-program-share-update';
import { PageShareFiltersService } from '../../../program-target-members/services/page-share-filters.service';

@Component({
  selector: 'kl-ai-predictions-share-update',
  templateUrl: './ai-predictions-share-update.component.html',
  styleUrls: ['./ai-predictions-share-update.component.scss']
})
export class AiPredictionsShareUpdateComponent extends KalgudiProgramShareUpdate implements OnInit {

  rowsCount: number;
  showAdvanced: boolean;
  isMobileDevice: boolean;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected programDialogs: KalgudiPagesMembersPickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected targetedMetaService: PageShareFiltersService,
    protected mobileMenuService: KalgudiBottomSheetService,
    private storeProductsSearchService: StoreProductsSearchService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      programDialogs,
      util,
      socialDataNormalizer,
      kalgudiApp,
      kalgudiPageService,
      sharePostService,
      targetedMetaService,
      mobileMenuService
    );

    this.lstOfAttachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.lstOfAttachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));
  }

  ngOnInit() {
    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 8 : 5;

    this.updateDateLocale();

    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }

    this.shareFormMsgTypes.patchValue(['AI_PREDICTIONS']);
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  protected onDestroyed(): void { }

  /**
   * Calls after posting a share
   * @param payload
   * @param response
   */
  protected onPostCreated(payload: ProgramShareRequest, response: ProgramShareUpdate): void {

    this.postCreated.emit(response);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
