import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { MobileTabsComponent } from './mobile-tabs.component';



@NgModule({
  declarations: [
    MobileTabsComponent
  ],
  imports: [
    CommonModule,

    // Router module
    RouterModule,

    // Material module
    MatIconModule,

    FlexLayoutModule,

    TranslateModule,
    AngularSvgIconModule,

    MatButtonModule,
  ],
  exports: [
    MobileTabsComponent
  ]
})
export class MobileTabsModule { }
