import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiStoreProduct } from '@kalgudi/types';

@Component({
  selector: 'kl-share-products',
  templateUrl: './share-products.component.html',
  styleUrls: ['./share-products.component.scss']
})
export class ShareProductsComponent implements OnInit {

  @Input()
  storeType: string;

  @Output()
  selectedShareProducts = new EventEmitter<KalgudiStoreProduct[]>();

  constructor() { }

  ngOnInit() {
  }

  submitShareProducts(products: KalgudiStoreProduct[]) {

    this.selectedShareProducts.emit(products);
  }
}
