import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiCommonDirectivesModule } from './directives/kalgudi-common-directives.module';
import { ProductAutocompleteComponent } from './modules/components/product-autocomplete/product-autocomplete.component';
import { KalgudiPostTileTagComponent } from './modules/kalgudi-post-tile-tag/kalgudi-post-tile-tag.component';


@NgModule({
  declarations: [
    KalgudiPostTileTagComponent,
    ProductAutocompleteComponent,
  ],
  imports: [
    // Common
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatDialogModule,
    MatChipsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatAutocompleteModule,
    MatInputModule,
    MatIconModule,

    // Kalgudi libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    // feature-flag module
    // KalgudiFeatureFlagModule,

    TranslateModule,
  ],
  exports: [
    // KalgudiDialogsModule,
    // KalgudiImageChipListModule,
    // KalgudiLoadMoreModule,
    KalgudiCommonDirectivesModule,
    KalgudiPostTileTagComponent,
    ProductAutocompleteComponent,
    // KalgudiFeatureFlagModule,
    // KalgudiStepperModule
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: featureFactory,
    //   deps: [FeatureFlagService],
    //   multi: true
    //   }
  ],
})
export class KalgudiCommonModule {

  public static forChild(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiCommonModule> {

    return {
      ngModule: KalgudiCommonModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
