import { Inject, Injector, Input, Directive } from "@angular/core";
import { KalgudiAppService, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from "@kalgudi/core";
import { KalgudiNotification, KL_NOTIFICATION } from "@kalgudi/core/config";
import { Observable } from "rxjs";
import { skip, takeUntil } from "rxjs/operators";
import { KalgudiProfileStateService } from "../../../services/kalgudi-profile-state.service";
import { ProfileLogsStateService } from "../services/profile-logs-state.service";
import { ProfileLogsService } from "../services/profile-logs.service";

@Directive()
export abstract class KalgudiProfileLogsList extends KalgudiInboxStream<any> {

  @Input()
  profileKey: string;

  @Input()
  pageId: string;

  contributorId: string;
  memberId: string;

  private profileLogService: ProfileLogsService;
  private profileState: KalgudiProfileStateService;
  private kalgudiApp: KalgudiAppService;
  private stateService: ProfileLogsStateService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {

    super(notification, util);

    this.profileLogService = this.injector.get(ProfileLogsService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.kalgudiApp        = this.injector.get(KalgudiAppService);
    this.stateService      = this.injector.get(ProfileLogsStateService);

    this.contributorId = this.kalgudiApp.profileLocal.profileKey;


    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.memberId = profile.profileKey;
        }
      );

    this.stateService.profileLogUpdated$
      .pipe(

        takeUntil(this.destroyed$),

        skip(1)
      )
      .subscribe(
        (res) => this.resetStream()
      );
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.profileLogService.getProfileLogsStream(this.memberId, this.contributorId, this.pageId, offset, limit);
  }

}
