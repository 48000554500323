<mat-tab-group [(selectedIndex)]="activeTabIndex" class="members-tabs">

  <!-- Manage members tab -->
  <mat-tab [label]="TABS?.MANAGE?.title | translate">
    <div class="mt-3 dialog-container">
      <div class="h-100" *ngIf="activeTabIndex === TABS?.MANAGE?.index">
        <kl-manage-task-members [projectId]="projectId" [taskId]="taskId"></kl-manage-task-members>
      </div>
    </div>
  </mat-tab>
  <!-- / Manage members tab -->

  <!-- Search members tab -->
  <mat-tab [label]="TABS?.SEARCH?.title | translate">
    <div dialog-container>
      <div *ngIf="activeTabIndex === TABS?.SEARCH?.index">
        <kl-search-members [projectId]="projectId" [taskId]="taskId" (addedMembers)="addedMembers()"></kl-search-members>

      </div>

    </div>
  </mat-tab>
  <!-- / Search members tab -->

  <!-- Bulk members tab -->
  <mat-tab [label]="TABS?.BULK_MEMBERS?.title | translate"  [disabled]="environment.production">
    <div class="dialog-container mt-3">
      <kl-bulk-members-addition (loginIdsList)="addMembers($event)"  *ngIf="activeTabIndex === TABS.BULK_MEMBERS.index"></kl-bulk-members-addition>
    </div>
  </mat-tab>
  <!-- / Bulk members tab -->

</mat-tab-group>
