import { Inject, Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSocial, SocialDataNormalizerService } from '@kalgudi/social';
import {
  Action,
  ActivityType,
  Attachment,
  KalgudiProjectTask,
  KalgudiUser,
  KalgudiUserBasicDetails,
  TaskShareUpdate,
  TaskShareUpdateRequest,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class TaskShareUpdateForm extends KalgudiSocial<TaskShareUpdateRequest, TaskShareUpdate> {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  activityTypes: ActivityType;

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  selectedMembersList: string[] = [];

  @Input()
  isBulk: boolean;

  shareForm: FormGroup;

  profilePicUrl: string;
  documents: Attachment[];

  activityType = ActivityType;

  adminProfile: KalgudiUser;
  memberProfile: KalgudiUserBasicDetails;

  urlFormControl = new FormControl({});

  private taskService: KalgudiTasksService;


  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected injector: Injector,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp
    );

    // Initialize Share update form
    this.shareForm = this.shareUpdatesForm;

    this.taskService = this.injector.get<KalgudiTasksService>(KalgudiTasksService);

    this.profilePicUrl = this.kalgudiApp.profileLocal.profilePicUrl;

    this.adminProfile = this.kalgudiApp.profileLocal;

  }

  /**
   * Calls API to share the current post.
   */
  createPostApi(payload: TaskShareUpdateRequest): Observable<TaskShareUpdate> {



    const activityType = this.activityTypes ? this.activityTypes : this.activityType.COMMENT;

    const submissionId = this.activityTypes === this.activityType.MEMBER_UPDATES ? this.submissionDetails.activityId : '';

    // Call api to share post
    return this.taskService.createActivity(this.projectId, this.taskId, activityType, payload, submissionId, '', Action.STATE_UPDATE, this.isBulk);

  }

  /**
   * Resets share form. Overrides default implementation of reset
   * by Kalgudi social.
   */
  resetForm(): void {
    this.shareForm.reset();
    this.urlFormControl.reset();

  }

  /**
   * Clears the url meta data from the share form
   */
  resetUrlMetadataFromShareForm(): void {
    this.urlFormControl.reset();
  }

  /**
   * Share updates form
   */
  private get shareUpdatesForm(): FormGroup {

    return new FormGroup({
      text: new FormControl(''),
      lstOfAttachments: new FormControl([]),
      assistedTo: new FormControl(''),

      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
    });
  }

  /**
   * Share update form text field.
   */
  get shareFormText(): AbstractControl {
    return this.shareForm.get('text');
  }

  /**
   * Share update form Uri domain field.
   */
  get shareFormAttachments(): AbstractControl {
    return this.shareForm.get('lstOfAttachments');
  }

  /**
   * Share update form attachments form array controls
   */
  get shareFormAttachmentsControls(): AbstractControl {
    return this.shareForm.controls.lstOfAttachments;
  }

  get doctype(): string {
    if (this.shareFormAttachmentsControls.value.length) {

      return this.shareFormAttachmentsControls.value[0].msgType;
    } else {
      return '';
    }

  }

  /**
   * Gets, the share update API request payload.
   */
  protected getCreationPayload(shareFormValue: any) {


    if (this.urlFormControl.value && this.urlFormControl.value.url) {

      shareFormValue.uri = this.urlFormControl.value.url;
      shareFormValue.domain = this.urlFormControl.value.domain;
      shareFormValue.uriImage = this.urlFormControl.value.image;
      shareFormValue.uriTitle = this.urlFormControl.value.title;
    }

    const formValue: any = this.util.clone({
      ...shareFormValue
    });

    const activityDetailsObj = {
      text: formValue.text,
      lstOfAttachments: formValue.lstOfAttachments,
      uri: this.urlFormControl.value && this.urlFormControl.value.url ? this.urlFormControl.value.url : '',
      domain: this.urlFormControl.value && this.urlFormControl.value.domain ? this.urlFormControl.value.domain : '',
      uriImage: this.urlFormControl.value && this.urlFormControl.value.image ? this.urlFormControl.value.image : '',
      uriTitle: this.urlFormControl.value && this.urlFormControl.value.title ? this.urlFormControl.value.title : ''
    };

    const payload: any = {
      activityDetails: activityDetailsObj,
      assistedTo: formValue.assistedTo
    };

    if(this.selectedMembersList.length) {
      payload.profileKeys = this.selectedMembersList;
    }

    //Check selected profile and logged in user profile
    if (payload.assistedTo && payload.assistedTo.profileKey === this.adminProfile.profileKey) {
      delete payload.assistedTo;
    }

    return payload;
  }

}
