
import { Injector, Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable } from '@kalgudi/core';
import {
  Attachment,
  AttachmentList,
  FileMimeTypes,
  KalgudiImageDialogConfig,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 *
 */
@Directive()
export abstract class KalgudiImagePickerFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  disabled = false;

  @Input()
  showAttachments = true;

  @Input()
  s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  @Input()
  buttonIcon: string = 'attachment';

  @Input()
  buttonLabel: string = 'Attach files';

  @Input()
  allowMultiple = false;

  @Input()
  maxImages: number = 50;

  @Input()
  acceptedFileTypes: FileMimeTypes[];

  @Input()
  showCamera: boolean;

  @Input()
  showAddOnLast: boolean;

  attachmentsForm: FormArray;
  attachmentsList: AttachmentList = [];

  // Dependencies
  protected fb: FormBuilder;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Manually inject dependencies
    this.fb = this.injector.get(FormBuilder);

    this.attachmentsForm = this.newAttachmentsForm;
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Creates a new attachments form array
   */
  private get newAttachmentsForm(): FormArray {
    return this.fb.array([]);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Form control accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.patchAttachmentsToForm(obj);
    } else {
      this.resetAttachmentsForm();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens picker dialog box to pick the attachment
   */
  onAttachClicked(event?: MouseEvent): void {

    // Below code is needed to avoid opening multiple dialog at once.
    // if(event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }

    // Input dialog UI configuration
    const { dialogDetails, dialogConfig } = this.getDialogConfiguration();

    // Open the attachment dialog
    this.showPickerDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(attachmentList => this.onAttachmentSelected(attachmentList));
  }

  /**
   * Removes an attachment from the attachments list
   */
  removeAttachment(index: number): void {

    if (index > this.attachmentsList.length) {
      throw new Error('Could not remove attachment. Invalid index!');
    }

    // Remove attachment from the form array
    this.attachmentsForm.removeAt(index);

    // Remove attachment from the attachment list
    this.attachmentsList = this.attachmentsForm.value;

    // Update the form control
    this.updateFormControl(this.attachmentsForm.value);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Shows the picker dialog where we can pick the attachments
   */
  protected abstract showPickerDialog(dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig): Observable<AttachmentList>;

  /**
   * Prepares kalgudi default dialog configuration
   */
  protected getDialogConfiguration(): { dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig } {

    // Input dialog UI configuration
    const dialogDetails: KalgudiImageDialogConfig = {
      title: 'Attach files',
      acceptButtonTitle: 'Attach',
      rejectButtonTitle: 'Cancel',
      data: {
        attachments: this.attachmentsForm.value,
        s3Category: this.s3Category,
        multiple: this.allowMultiple,
        maxImages: this.maxImages,
        acceptFileTypes: this.acceptedFileTypes,
        showCamera: this.showCamera,
        showAddOnLast: this.showAddOnLast
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return { dialogDetails, dialogConfig };
  }

  /**
   * Event handler for attachments selection. Overrides the current form
   * control value with the selected attachments list. It also updates the form
   * control with the updated value.
   */
  private onAttachmentSelected(attachments: AttachmentList): void {

    // Patch attachment to the form control
    this.patchAttachmentsToForm(attachments);

    this.updateFormControl(attachments);
  }

  /**
   * Updates the attachments parent form control value with the latest attachment
   * object.
   */
  private updateFormControl(attachments: AttachmentList = this.attachmentsForm.value): void {
    this.onChange(attachments);
    this.onTouched();
  }

  /**
   * Creates a new attachment form from the given attachment object or
   * creates an empty attachment form group.
   */
  private newAttachmentForm(
    attachment: Attachment = {
      msgType: '' as any,
      url: '',
      context: ''
    }
  ): FormGroup {

    return this.fb.group({
      url: [attachment.url],
      msgType: [attachment.msgType],
      context: [attachment.context]
    });
  }

  /**
   * Clears the existing attachment for array and patches all attachments
   * to the attachment form array.
   */
  private patchAttachmentsToForm(attachments: AttachmentList = []): void {

    // Clear all previous values
    this.attachmentsForm.clear();

    // Convert attachments list into attachment form controls array
    // Patch the constructed attachments form array to form control
    attachments.forEach(attachment => this.attachmentsForm.push(this.newAttachmentForm(attachment)));

    // Update the attachments list with the updated attachment
    this.attachmentsList = attachments;
  }

  /**
   * Resets the attachments form
   */
  private resetAttachmentsForm(): void {
    this.attachmentsForm.clear();
    this.attachmentsList = [];
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
