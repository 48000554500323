import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { BaseProductTypes } from '@kalgudi/types';

import { KalgudiCreateStock } from '../../classes/kalgudi-create-stock-page';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-stock-page-creation',
  templateUrl: './stock-page-creation.component.html',
  styleUrls: ['./stock-page-creation.component.scss']
})
export class StockPageCreationComponent extends KalgudiCreateStock implements OnInit {

  productTypes = BaseProductTypes;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
  ) {

    super(fb);
  }

  ngOnInit() {

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }


}
