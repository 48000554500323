import { FormBuilder, FormGroup } from '@angular/forms';
import { PageType, SalesTeamsPageDetails } from '@kalgudi/types';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateSalesTeams extends KalgudiCreatePageType<SalesTeamsPageDetails> {

  constructor(protected fb: FormBuilder) {
    super(fb);
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      selectCrops: ['']
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'SALES_TEAMS';
  }

}

