import { Injectable } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUserCertificateDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiProfileCertificatesApiService } from './kalgudi-profile-certificates-api.service';

@Injectable()
export class KalgudiProfileCertificatesService {


  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private app: KalgudiAppService,
    private profileService: KalgudiProfileService,
    private api: KalgudiProfileCertificatesApiService,
    private profileState: KalgudiProfileStateService,
  ) {

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }
   }

  /**
   * Saves a new certificate to the current logged in user profile.
   */
  saveNewCertificate(payload: KalgudiUserCertificateDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new certificate Api
        switchMap(r => this.api.saveNewCertificate(r.profileKey, r.defaultBusinessKey, payload)),

        // Update profile locally and profile state
        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
            .pipe(mapTo(res))
        ),
      );
  }

  /**
   * Updates an existing certificate of the current logged in user profile.
   */
  updateCertificate(payload: KalgudiUserCertificateDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Update new certificate Api
        switchMap(r => this.api.updateNewCertificate(
          r.profileKey, r.defaultBusinessKey, payload.businessCertificationId, payload)),

        // Update profile locally and profile state
        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
            .pipe(mapTo(res))
        ),
      );
  }

  /**
   * Deletes an certificate from the logged in user profile.
   */
  deleteCertificate(certificateId: string): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new certificate Api
        switchMap(r => this.api.deleteCertificate(r.profileKey, r.defaultBusinessKey, certificateId)),

        // Update profile locally and profile state
        switchMap(res =>
          this.profileService.updateProfileAndState()
            .pipe(mapTo(res))
        ),
      );
  }
}
