<!-- Search bar -->
<div class="mb-0" fxLayoutAlign="center center">
  <kl-search class="search w-100"
    placeHolder="Type and hit enter to search tasks"
    (search)="search();"
    [searchForm]="searchForm">
  </kl-search>
</div>
<!-- /Search bar -->

<div *ngIf="(stream$ | async) as stream" id="task-list-grid">
  <div fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.xs="column">
    <div fxFlex="50%" fxFlex.xs="100%" *ngFor="let task of stream; let i = index" class="mb-2 task-list">
      <div [@floatTop]="{ value: '', params: {delay: i * 20} }" class="task">
        <kl-task-tile
          [taskDetails]="task"
          (delete)="deleteTask(task?.taskId)"
          (addMembers)="showAddTaskMembersDialog(task?.taskId, task?.projectId)"
          (openFullview)="openTaskFullviewDialog(task?.title, task?.taskId, task)"
          (updateTask)="openEditTaskDialog(task?.taskId, task?.projectId)"
          (showTaskStats)="openTaskStatsDialog($event, task?.taskId, task?.title)"></kl-task-tile>
      </div>
    </div>
  </div>

  <div class="mt-3" *ngIf="paginator$ | async as paginator">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column">
      <ng-container *ngIf="!stream || stream?.length <= 0">
        <div>
          <kl-no-content
            svgIcon= "assets/svgs/tasks.svg"
            text= "{{loggedInUserTasks ? 'You did not participate in any tasks yet!' : 'Did not create any tasks for this project yet!'}}"
            progressText="Loading your tasks..."
            actionLink="{{loggedInUserTasks ? '' : 'Create your first task in this project'}}"
            [progress]="paginator?.loading"
            (action)="openTaskCreationDialog()">
          </kl-no-content>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="stream?.length > 0">
      <kl-load-more
        noMoreText="No more task to show!"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </ng-container>
  </div>
</div>

