import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable, checkMobileDevice } from '@kalgudi/core';
import { KalgudiProfileService } from '../../../../../services/kalgudi-profile.service';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { FinanceDetailsDialogComponent } from '../finance-details-dialog/finance-details-dialog.component';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { FinanceDetailsMobileDialogComponent } from '../finance-details-mobile-dialog/finance-details-mobile-dialog.component';

@Component({
  selector: 'kl-finance-details-tile',
  templateUrl: './finance-details-tile.component.html',
  styleUrls: ['./finance-details-tile.component.scss']
})
export class FinanceDetailsTileComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  profileKey: string;

  @Input()
  actionIcon: string;

  @Input()
  financeFormDetails: any;

  @Input()
  isPublicPage: boolean;

  @Output()
  getFpoProfileData = new EventEmitter();

  editable: boolean;

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService
    ) {
    super();

    this.dialogService = this.injector.get(KalgudiDialogsService);
    this.profileService = this.injector.get(KalgudiProfileService);

    this.profileService.editable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.editable = res));
  }

  /**
   * Converts to indian rupee format
   */
  convertIndianRupeeFormat(value) {
    if (value === '-' || value === undefined) { return '-'; }
    return '₹' + Number(value).toLocaleString('en-IN');
  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows Finance Details dialog
   */
  showFinanceDetailsDialog(isEdit?: boolean) {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Finance Details',
      data: {
        profileKey: this.profileKey,
        financeFormDetails: this.financeFormDetails,
        pageId: 'FINANCE',
        isEdit: isEdit
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '640px',
      maxWidth: '640px',
      panelClass: 'FinanceDetails',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {},
    };

    return this.openFinanceDetailsDialog(dialogDetails, dialogConfig)
    .pipe(
      filter((r) => r.accepted),

      // map(r => r.accepted),
    )
    .subscribe(
      res => {
        if(res.accepted) {
          this.getFpoProfileData.emit();
        }
      }
    )
  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: any): void {
    let imgUrl = this.environment.s3BucketUrl + attachments;

    let imgObj = [
      {url: imgUrl}
    ]

    this.kalgudiPhotoswipe.open(imgObj).subscribe();
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open Finance Details dialog
   */
  private openFinanceDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(FinanceDetailsMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(FinanceDetailsDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

  onDestroyed(): void {}

  ngOnInit(): void {}
}
