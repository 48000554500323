import { Injector, Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable } from '@kalgudi/core';
import {
  Attachment,
  FileMimeTypes,
  KalgudiDialogConfig,
  KalgudiImageDialogConfig,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 *
 */
@Directive()
export abstract class KalgudiCameraFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  disabled = false;

  @Input()
  showAttachments = true;

  @Input()
  s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  @Input()
  buttonIcon: string = 'attachment';

  @Input()
  buttonLabel: string = 'Attach Files';

  @Input()
  allowMultiple = false;

  @Input()
  maxImages: number = 50;

  @Input()
  acceptedFileTypes: FileMimeTypes[];

  @Input()
  showCamera: boolean;

  @Input()
  showAddOnLast: boolean;

  // Dependencies
  protected fb: FormBuilder;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Manually inject dependencies
    this.fb = this.injector.get(FormBuilder);
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Creates a new attachments form array
   */
  private get newAttachmentsForm(): FormArray {
    return this.fb.array([]);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Form control accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    // if (obj) {
    //   this.patchAttachmentsToForm(obj);
    // } else {
    //   this.resetAttachmentsForm();
    // }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens picker dialog box to pick the attachment
   */
  onAttachClicked(): void {

    // Input dialog UI configuration
    const { dialogDetails, dialogConfig } = this.getDialogConfiguration();

    // Open the attachment dialog
    this.showPickerDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(attachment => this.onAttachmentSelected(attachment));
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Shows the picker dialog where we can pick the attachments
   */
  protected abstract showPickerDialog(dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig): Observable<Attachment>;

  /**
   * Prepares kalgudi default dialog configuration
   */
  protected getDialogConfiguration(): { dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig } {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Capture photo',
      acceptButtonTitle: 'Capture',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: this.s3Category
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return { dialogDetails, dialogConfig };
  }

  /**
   * Event handler for attachments selection. Overrides the current form
   * control value with the selected attachments list. It also updates the form
   * control with the updated value.
   */
  private onAttachmentSelected(attachments: Attachment): void {

    this.updateFormControl(attachments);
  }

  /**
   * Updates the attachments parent form control value with the latest attachment
   * object.
   */
  private updateFormControl(attachments: Attachment): void {

    this.onChange(attachments);
    this.onTouched();
  }

  /**
   * Creates a new attachment form from the given attachment object or
   * creates an empty attachment form group.
   */
  private newAttachmentForm(
    attachment: Attachment = {
      msgType: '' as any,
      url: '',
      context: ''
    }
  ): FormGroup {

    return this.fb.group({
      url: [attachment.url],
      msgType: [attachment.msgType],
      context: [attachment.context]
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
