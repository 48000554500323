<main id="profile-certificates-awards" class="h-100">

  <kl-common-profile-header
    title="Awards"
    svgIcon="assets/svgs/award-svg.svg"
    [editable]="editable && awards?.length >= 1"
    actionIcon="add"
    (action)="showAddAwardDialog()">
  </kl-common-profile-header>

  <!-- Awards -->
  <ng-container *ngFor="let award of awards; let i = index; let last = last">
    <div fxLayout fxLayoutAlign="space-between center" class="achievement-list">

      <div fxLayout fxLayoutGap="10px">
        <ng-container *ngIf="award?.attachments && award?.attachments[0]?.url; else elseBlock">
          <img [src]="award?.attachments[0]?.url | prefixDomain" width="50" height="40">
        </ng-container>
        <ng-template #elseBlock>
          <svg-icon src="assets/svgs/trophy.svg"
            class="text-center menu-icons cursor-pointer"
            [applyCss]="true"
            [svgStyle]="{ 'width.px': 50, 'height.px': 40}">
          </svg-icon>
        </ng-template>

        <div>
          <h5 class="award-title text-capitalize mb-0"> {{ award?.title }} </h5>
          <p class="award-description mb-0">
            <span translate>From </span> {{ award?.description }}</p>
        </div>
      </div>

      <!--Award action buttons -->
      <div fxLayout fxLayoutGap="8px" class="edit-section" *ngIf="editable">
        <!-- <button mat-icon-button class="action-buttons edit-award-btn" id="edit-achievement" (click)="updateAward(award?.businessAwardId, award)">
          <mat-icon class="action-icons text-white">edit</mat-icon>
        </button> -->

        <button mat-icon-button class="action-buttons cancel-icon" id="delete-achievement" (click)="deleteAward(award?.businessAwardId)">
          <mat-icon class="action-icons delete-icon">delete_forever</mat-icon>
        </button>

        <!-- <button mat-icon-button class="edit-btn"
          [matMenuTriggerFor]="menu">
          <mat-icon class="text-muted">more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

          <button mat-menu-item id="edit-achievement" (click)="updateAchievement(achievement?.businessAwardId, achievement)">
            <mat-icon class="action-icons">edit</mat-icon>
            <span>Edit</span>
          </button>

          <button mat-menu-item id="delete-achievement" (click)="deleteAchievement(achievement?.businessAwardId)">
            <mat-icon class="action-icons">delete_outline</mat-icon>
            <span>Delete</span>
          </button>

        </mat-menu> -->
      </div>
      <!--/Award action buttons -->
    </div>

    <div *ngIf="!last" class="border-bottom pt-2 mb-2">
    </div>

  </ng-container>
  <!--/ awards -->

  <!-- No content if there no achievement -->
  <ng-container *ngIf="!awards?.length">
    <div class="w-100" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? 'No awards added yet!' : editable ? '' : 'No awards added yet!'"
        [actionLink]="isPublicPage ? '' : editable ? 'Add award' : ''"
        (action)="showAddAwardDialog()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no achievement -->

</main>
