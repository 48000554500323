<div class="mt-3 page-da-stats">

  <kl-analytics-row [title]="'Digital Assistance' | translate">

    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="Profile Creation"
      [statsCount]="pageDaStats?.PROFILE_CREATION || '0'"
      svgIcon="assets/svgs/profile.svg"
      matIconColor="#9c27b0"
      className="profile-create-icon">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="Profile update"
      [statsCount]="pageDaStats?.PROFILE_UPDATE || '0'"
      svgIcon="assets/svgs/update-profile-user.svg"
      matIconColor="#e91e63"
      className="profile-update-icon">
    </kl-page-analytics-stats>
  </kl-analytics-row>

  <kl-analytics-row>
    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="Mobile number update"
      [statsCount]="pageDaStats?.MOBILE_NO_UPDATE || '0'"
      matIcon="call"
      matIconColor="orangered">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="QA"
      [statsCount]="pageDaStats?.QA || '0'"
      matIcon="help_outline"
      matIconColor="green"
      className="qa-icon">
    </kl-page-analytics-stats>
  </kl-analytics-row>

  <kl-analytics-row>
    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="Survey submission"
      [statsCount]="pageDaStats?.SURVEY_SUBMISSION || '0'"
      svgIcon="assets/svgs/survey-to-fill.svg"
      matIconColor="#648eda">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex="calc(50% - 10px)" fxFlex.xs="48%"
      title="Shares"
      [statsCount]="pageDaStats?.SHARES || '0'"
      matIcon="format_quote"
      matIconColor="orange">
    </kl-page-analytics-stats>
  </kl-analytics-row>

  <div class="mb-3">
    <h5 translate>User Assistants</h5>
    <kl-user-assistants-stats [userStats]="userStats"></kl-user-assistants-stats>
  </div>

</div>
