<div [ngClass]="{'h-100': !paginator?.count}" *ngIf="(paginator$ | async) as paginator">
  <ng-container *ngIf="stream$ | async as users">

    <div class="px-2 list-wrapper" fxLayout="row wrap" fxLayoutAlign="space-between center" >
      <div fxFlex="48%" fxFlex.xs="100%" class="mb-3" *ngFor="let user of users">
          <kl-selectable-user-tile
            [author]="user"
            avatarSize="md"
            [showRemove]="true"
            (removed)="removeSelectedUser(user?.profileKey)">
          </kl-selectable-user-tile>
      </div>

    </div>
    <div *ngIf="!paginator?.count" class="h-100" fxLayout fxLayoutAlign="center center">
      <kl-no-content
        [progress]="paginator?.loading"
        progressText="Loading..."
        text="Members not yet add to this task"
        matIcon="person_add">
      </kl-no-content>
    </div>
    <div class="mt-3" *ngIf="paginator?.count">
      <kl-load-more
        noMoreText="No more users"
        [progress]="paginator.loading"
        [hasMoreItems]="paginator.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </div>
  </ng-container>
</div>
