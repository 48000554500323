<div class="store-product-tile cursor-pointer p-2" [ngClass]="{'store-product-selected': isSelected}">

  <div fxLayout="column" fxLayoutAlign="center center" class="w-100">

    <!-- Product image -->
    <div fxLayout class="position-relative">
      <img [src]="product?.productPicURL_level3 | prefixDomain | resizeImage:'100x100'" width="100" height="100">

      <div class="product-selected position-absolute" fxLayout fxLayoutAlign="start end" *ngIf="isSelected">
        <div class="selected rounded-circle">
          <mat-icon class="text-white">done</mat-icon>
        </div>
      </div>
    </div>
    <!-- /Product image -->

    <!-- Product details -->
    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center" class="w-100">
      <p class="m-0 w-100 text-center product-name text-truncate" [matTooltip]="product?.productName_level3">
        {{ product?.productName_level3 }}</p>
      <p class="m-0 text-truncate text-center product-quantity">
        <span>
          <span>{{ product?.SKUSmartElements?.weight?.value }}</span>
          <span>{{ product?.SKUSmartElements?.weight?.unit }}</span>
        </span>
        <!-- |
        <span>{{ product?.mrp | currency: '₹' }}</span></p> -->
    </div>
    <!-- /Product details -->
  </div>

</div>
