import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

import { CommonPageFormComponent } from './common-page-form/common-page-form.component';
import { CommonPageInfoComponent } from './common-page-info/common-page-info.component';


@NgModule({
  declarations: [
    CommonPageInfoComponent,
    CommonPageFormComponent
  ],
  imports: [
    CommonModule,

    //Material modules
    MatInputModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    TranslateModule
  ],
  exports: [
    CommonPageInfoComponent,
    CommonPageFormComponent
  ]
})
export class CommonPageDetailsModule { }

