<main id="traceability-details" class="container">

  <section class="position-absolute header" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
    <img class="rounded-circle icons" [src]="traceabilityDetails?.image">
    <h4 class="nav-title mb-15">{{traceabilityDetails?.name}}</h4>
  </section>

  <div class="details-info position-relative" fxLayoutGap="25px" fxLayout.xs="column">

    <div class="left-border mr-30" fxHide.xs></div>

    <section fxFlex="20%" fxLayout="column" class="common-details">
      <!-- <mat-card class="bg-white type-details rounded-0" fxLayout="column" [ngClass]="{'supplier-tile cursor-pointer': productLevel3Title}"
        (click)="openSupplierPage(basicDetails)"> -->
      <mat-card class="bg-white gallery-info rounded-0" fxLayout="column" [ngClass]="{'type-details position: relative': details || showDetailsCard}">
        <div fxLayout="row" fxLayoutGap="10px" class="w-100">
          <span *ngIf="showBreadCrumbs && traceabilityDetails?.commonDetails?.breadCrumb" class="w-100 text-truncate text-secondary type">{{ traceabilityDetails?.commonDetails?.breadCrumb }}</span>
          <div fxLayoutAlign="center center" *ngIf="traceabilityDetails?.commonDetails?.subImage" class="mr-0 w-100">
            <img class="rounded-circle icons" [src]="traceabilityDetails?.commonDetails?.subImage">
          </div>
        </div>
        <div fxLayoutAlign="center center" class="mr-0 w-100 mb-n2 mt-2" *ngIf="basicDetails && showProfilePic">
          <img class="rounded-circle icons basic-details-profile-pic" [src]="(basicDetails.profilePicUrl || basicDetails.profilePicURL ) | prefixDomain" (error)="img.src = defaultProfilePic" #img>
        </div>
        <h6 fxLayoutAlign="center center" *ngIf="!fullFillmentCenterBasedOnStore && !basicDetails" class="font-weight-500 mt-3 title text-center">{{level2ProductDetails?.manufacturerDetails?.firstName}}</h6>
        <h6 fxLayoutAlign="center center" *ngIf="fullFillmentCenterBasedOnStore && !basicDetails" class="font-weight-500 text-danger mt-1 mb-1 title">
          Fulfillment center
        </h6>
        <!-- <h6 fxLayoutAlign="center center" *ngIf="basicDetails" class="font-weight-500 mt-3 title supplier-name text-center cursor-ponter" (click)="openVasudhaikaPage(basicDetails);$event.stopPropagation(); $event.preventDefault()" [ngClass]="{'profile-tile cursor-pointer': basicDetails?.firstName}">{{basicDetails?.firstName}}</h6> -->
        <h6 fxLayoutAlign="center center" *ngIf="basicDetails" class="font-weight-500 mt-3 title supplier-name text-center">{{basicDetails?.firstName}}</h6>

        <span class="text-secondary location-details text-center">
          <img *ngIf="level2ProductDetails?.manufacturerDetails?.location || fullFillmentCenterBasedOnStore" src="assets/svgs/location.svg">

          <span class="location" *ngIf="!fullFillmentCenterBasedOnStore && !basicDetails">{{ level2ProductDetails?.manufacturerDetails?.location }}</span>

          <span class="location" *ngIf="!showLocationDetails">
            <span *ngIf="basicDetails?.locationDetails?.placeName">{{basicDetails?.locationDetails?.placeName}}</span>
            <span *ngIf="basicDetails?.locationDetails?.districtName  && (basicDetails?.locationDetails?.placeName !== basicDetails?.locationDetails?.districtName)">, {{basicDetails?.locationDetails?.districtName}}</span>
            <span *ngIf="basicDetails?.locationDetails?.stateName">, {{basicDetails?.locationDetails?.stateName}}</span>
          </span>

          <span class="fulfillment-location" *ngIf="fullFillmentCenterBasedOnStore">{{fullFillmentCenterBasedOnStore?.location}}</span>
          <span class="location">
            <span *ngIf="profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName">{{profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}}</span>
            <span *ngIf="profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName">, {{profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName}}</span>
            <span *ngIf="profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName">, {{profileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName}}</span>
          </span>
        </span>

        <span class="purchased-details text-center mt-3 text-secondary" *ngIf="level3ProductDetails && supplierInfo">
          Purchased <span class="font-weight-bold">{{level3ProductDetails?.productLevel3Title | titlecase}}</span> from
          <span class="font-weight-bold">{{supplierInfo?.firstName}}</span>
          <!-- <span class="font-weight-bold">{{supplierInfo?.data?.CT | date: 'dd MMM yyyy'}}</span> -->
        </span>

      </mat-card>

      <!-- Gallery card -->
      <mat-card class="gallery-info rounded-0 p-10" *ngIf="showGallery && attachments && attachments.length" [ngStyle]="{'background-color': bgColor}">
        <span class="type" fxLayoutAlign="center center">Gallery</span>
        <div class="mt-15 mb-15" fxLayout="row wrap" fxLayoutAlign="center center">
          <div class="attachments rounded-circle cursor-pointer" *ngFor="let images of attachments | slice:0:5; let i = index" (click)="openImageFullView(attachments, i)">
            <img [src]="images.url | prefixDomain" class="images rounded-circle">
          </div>
          <div class="attachments rounded-circle cursor-pointer" *ngIf="attachments.length > 5" (click)="openImageFullView(attachments)">
            <div class="images rounded-circle  border bg-white" fxLayoutAlign="center center" >
              <span>+</span>
              <span>{{attachments?.length - 5}}</span>
            </div>
          </div>
        </div>
      </mat-card>

    </section>

    <section fxFlex="55%" class="show-details mt-n3">
      <!-- Procurement and production details -->
      <div *ngIf="!showDetailsCard">
        <div>
          <ng-content select="[first-card]"></ng-content>
        </div>
      </div>

      <!-- Fulfillment card -->
      <mat-card class="mt-3 no-content position-relative" fxLayout="column" fxLayoutGap="3px" *ngIf="showDetailsCard">
        <div class="circle rounded-circle fulfillment" fxLayoutAlign="end end"></div>

        <div>
          <ng-content select="[second-card]"></ng-content>
        </div>
      </mat-card>
    </section>


  </div>
</main>
