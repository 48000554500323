import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { FileMimeTypes, ShareRequest, ShareUpdate } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiSocialShare } from '../../kalgudi-social-share';
import { KalgudiShareUpdateService } from '../../services/kalgudi-share-update.service';


@Component({
  selector: 'kl-share-update-form',
  templateUrl: './share-update-form.component.html',
  styleUrls: ['./share-update-form.component.scss'],
})
export class ShareUpdateFormComponent extends KalgudiSocialShare<ShareRequest, ShareUpdate> implements OnInit {

  @Output()
  postCreated = new EventEmitter<any>();

  @Input() options:any;

  attachmentsForm = new FormControl([]);

  rowsCount: number;
  showAdvanced: boolean;
  isMobileDevice: boolean;
  pageList: any;
  page: any;
  minDate: Date = new Date();
  isAppToDisplay: boolean = false;

  showScheduleTime: boolean = false;

  enableTagSelection: boolean = false;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogsService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected sharePostService: KalgudiShareUpdateService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected bottomSheetService: KalgudiBottomSheetService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    // Initialize parent
    super(
      injector,
      notification,
      imagePickerService,
      kalgudiDialogsService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      bottomSheetService,
    );
  }

  ngOnInit() {

    // Display fields based on the application ID
    this.isAppToDisplay = this.environment.appId === 'KALGUDI_CORE' || this.environment.appId === 'FPO_APP' || this.environment.appId === 'KHETIGHAR' || this.environment.appId === 'SAM_FARMER' || this.environment.appId === 'FARMERS_APP' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'SAM_FPO' || this.environment.appId === 'ORMAS_SHG' || this.environment.appId === 'UBM' || this.environment.appId === 'SHG_NET_APP';

    if(this.isAppToDisplay) {
      this.fetchPagesList();
    }
    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 10 : 3;

    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }

    this.updateDateLocale();

  }

  toggleAdvanced() {
    this.showAdvanced = true;
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Event handler called after the post is shared successfully.
   */
  onPostCreated(req: ShareRequest, res: any): void {
    this.postCreated.emit(res);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  /**
   * On page selection
   */
  selectPage(page: any) {
    this.page = page.value ;
    this.enableTagSelection = true;
  }

  /**
   * Fetches pages list from the API
   */
  fetchPagesList() {
    this.sharePostService.fetchPagesList(0, 1000)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          const pageListRes = res.items;
          this.pageList = pageListRes.filter(p => ['PROGRAM', 'ORGANIZATION', 'FARMER','MY_FARMERS', "MY_FPOS"].includes(p.pageType));
        }
      )
  }

  /**
   * Toggle the schedule date & time
   */
  toggleSchedule() {
    this.showScheduleTime = !this.showScheduleTime;
    this.clearDate();
  }
}
