import { Component, Input, OnInit } from '@angular/core';
import { KalgudiSellerConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-seller-details-view',
  templateUrl: './seller-details-view.component.html',
  styleUrls: ['./seller-details-view.component.scss']
})
export class SellerDetailsViewComponent implements OnInit {

  @Input()
  sellerConfigDetails: KalgudiSellerConfig;

  constructor() { }

  ngOnInit() {
  }

}
