import { Injector, Input, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GeoLocationService, KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiNgxFormlyMapperService } from '@kalgudi/third-party/ngx-formly';
import {
  Action,
  ActivityType,
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiProjectTask,
  PartialData,
  TaskTemplateDetails,
} from '@kalgudi/types';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class TaskTemplateForm extends KalgudiDestroyable {

  @Input()
  templateId: string;

  @Input()
  activityId: string;

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  memberProfileKey: string;

  @Input()
  selectedMembersList: string[] = [];

  @Input()
  isBulk: boolean;

  /**
   * Extra query params that will be passed in all services
   */
  @Input()
  extraParams: PartialData;

  fields: FormlyFieldConfig[];
  form: FormGroup;
  model: any = {};
  options: FormlyFormOptions;
  progress: boolean;

  templateForm: FormGroup;

  templateSchema: TaskTemplateDetails;

  availableActivityTypes = ActivityType;

  loggedInProfileKey: string;

  projectRole = KALGUDI_PAGE_RELATION_MAP;

  protected geoLocationService: GeoLocationService;
  protected notification: KalgudiNotification;
  protected util: KalgudiUtilityService;
  private appService: KalgudiAppService;
  private projectService: KalgudiProjectService;
  private formlyJsonschema: FormlyJsonschema;
  private jsonToFormlyMapper: KalgudiNgxFormlyMapperService;
  private tasksService: KalgudiTasksService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.notification       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util               = this.injector.get(KalgudiUtilityService);
    this.appService         = this.injector.get<KalgudiAppService>(KalgudiAppService);
    this.geoLocationService = this.injector.get(GeoLocationService);
    this.projectService     = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.formlyJsonschema   = this.injector.get<FormlyJsonschema>(FormlyJsonschema);
    this.jsonToFormlyMapper = this.injector.get<KalgudiNgxFormlyMapperService>(KalgudiNgxFormlyMapperService);
    this.tasksService       = this.injector.get<KalgudiTasksService>(KalgudiTasksService);

    this.templateForm = new FormGroup({});

    this.loggedInProfileKey = this.appService.profileLocal.profileKey;
  }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Initializes template form with schema and filled submission details.
   */
  initTemplateForm(): void {

    // Fetch the template to fill
    // if (this.templateId) {
    //   this.initTemplateSchema(this.templateId);
    // }

    // Fetch the existing filled details
    // if (this.taskDetails && this.taskDetails.userRole === this.projectRole.ADMIN) {
    if (this.submissionDetails) {
      this.initTaskSubmissionDetails(this.submissionDetails);
    } else {
      this.fetchTaskSubmission()
        .subscribe(res => this.initTaskSubmissionDetails(res));
    }
  }

  /**
   * Saves task activity submission for the specified user
   */
  saveActivityDetails() {

    if (this.model && this.model.activityId) {
      this.updateTaskSubmission(this.model.activityId);
    } else {
      this.saveTaskSubmission();
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the template formly json schema to fill
   */
  protected initTemplateSchema(templateId: string) {

    this.projectService.getTemplateSchema(templateId)
      .subscribe(
        res => this.initFormly(res),
        err => this.notification.showMessage('Unable to load task template.'),
      );
  }

  /**
   * Prepares payload
   */
  protected preparePayload(): any {

    const geoLocation = this.geoLocationService.currentLatLong;

    const extraParams = this.extraParams || {};

    // If user himself is not filling the form then assign the assisted to field
    // to the payload.
    // It ensure that the user is filling the form on behalf of other.
    const assistedTo  = this.submissionDetails && this.isAssisting(this.submissionDetails.createdBy.profileKey)
      ? this.util.clone(this.submissionDetails.createdBy)
      : null;

    return {
      activityDetails: {
        ...this.model,
        ...this.util.clone(this.templateForm.value),
      },
      geoLocation,
      assistedTo,
      ...extraParams
    };
  }


  /**
   * Event handler for failed to create activity
   */
  protected templateErrHandler(err: Error) {
    this.notification.showMessage('Failed to create activity');
  }

  /**
   * Calls after successfully creating teh activity
   */
  protected templateSavedHandler(res) {

    this.notification.showMessage('Task details saved successfully');

    this.model = res;
  }

  /**
   * Fetches task submission details from API
   */
  private fetchTaskSubmission(): Observable<KalgudiProjectTask> {

    return this.tasksService.getActivities(
      this.availableActivityTypes.TASK_SUBMISSION,
      this.taskId,
      0,
      1,
      this.loggedInProfileKey
    )
    .pipe(
      takeUntil(this.destroyed$),

      map(res => res.items[0]),
    );
  }

  /**
   * Initializes task submission details.
   *
   * @param submissionDetails Latest task details containing the submission
   */
  private initTaskSubmissionDetails(submissionDetails: KalgudiProjectTask): void {

    this.submissionDetails = submissionDetails;

    // console.log("TaskTemplateForm -> initTaskSubmissionDetails -> this.submissionDetails", this.submissionDetails);

    let cropStage = '';
    try {
      cropStage = (this.taskDetails.cropStage || '').toLowerCase();
      cropStage = cropStage.charAt(0).toUpperCase() + cropStage.substring(1);
      cropStage = cropStage.replace(/_/g, ' ');
    } catch (e) {}

    this.model = {
      ...this.submissionDetails.activityDetails,
      cropStage,
    };

    // console.log("TaskTemplateForm -> initTaskSubmissionDetails -> this.model", this.model);
  }

  /**
   * Initializes the fetched schema to formly json schema
   */
  private initFormly(template: TaskTemplateDetails) {

    this.fields = [this.formlyJsonschema.toFieldConfig(template.schema, {
      map: (mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig => {

        mappedField = this.jsonToFormlyMapper.mapConfiguration(mappedField, mapSource);

        // mappedField.templateOptions.disabled = this.taskDetails.userRole === this.projectRole.ADMIN;

        return mappedField;
      }
    })];
  }


  /**
   * Calls api to create activity
   */
  private saveTaskSubmission() {

    this.progress = true;

    const payload = this.preparePayload();

    if(this.selectedMembersList.length) {
      payload.profileKeys = this.selectedMembersList;
    }

    const profileKey = this.taskDetails.userRole === this.projectRole.ADMIN ||
                       this.taskDetails.userRole === this.projectRole.CONTRIBUTOR ? this.memberProfileKey : '';

    this.tasksService.createActivity(this.projectId, this.taskId, this.availableActivityTypes.TASK_SUBMISSION, payload, '', profileKey, Action.STATE_UPDATE,this.isBulk)
      .subscribe(
        res => {
          this.progress = false;

          this.templateSavedHandler(res.activityDetails);
        },
        err => this.templateErrHandler(err)
      );
  }


  /**
   * Calls api to update the activity
   */
  private updateTaskSubmission(activityId: string) {

    this.progress = true;

    const payload = this.preparePayload();

    if(this.selectedMembersList.length) {
      payload.profileKeys = this.selectedMembersList;
    }

    const profileKey = this.taskDetails.userRole === this.projectRole.ADMIN ||
     this.taskDetails.userRole === this.projectRole.CONTRIBUTOR
     ? this.memberProfileKey : '';

    this.tasksService.updateActivity(
      this.projectId,
      this.taskId,
      this.availableActivityTypes.TASK_SUBMISSION,
      activityId,
      payload,
      profileKey,
    )
      .subscribe(
        res => {
          this.progress = false;

          this.templateSavedHandler(res);

        },
        err => this.templateErrHandler(err)
      );
  }

  /**
   * Returns `true` if the task is submitted by the admin of the task.
   */
  private isAssisting(taskAssignedToProfileKey: string): boolean {
    return taskAssignedToProfileKey !== this.appService.profileLocal.profileKey;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
