<div class="prediction-list" [attr.id]="copyId">
  <div fxLayout="column" id="input-all-data">

    <div *ngFor="let item of predictionList; let last = last; let i = index" [attr.id]="'input-ai-predictions' + i">
      <div fxLayout="column" class="item selected-item" [ngClass]="{'separator': !last, 'not-selected' : item?.hide}">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column">
            <p class="m-0 font-weight-bold title">{{item?.title | titlecase}}</p>
            <!-- <p class="date m-0" *ngIf="item?.fromDate">
              {{item?.fromDate | date:'dd MMM'}}
              <span *ngIf="item?.fromDate && item?.toDate"> - </span>
              <span>{{item?.toDate | date:'dd MMM'}}</span>
            </p> -->
          </div>
          <div>
            <!-- Copy button -->
            <button mat-icon-button class="copy-button share-button" (click)="copyToClipBoard('input-ai-predictions' + i)" fxHide.xs *ngIf="showCopy">
              <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/copy.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            </button>

            <button mat-icon-button (click)="displayDetails(item)" *ngIf="item?.hide">
              <!-- <mat-icon class="add-button" >add</mat-icon> -->
              <svg-icon [applyCss]="true" class="text-center items-info" src="assets/svgs/add.svg"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </button>
            <button mat-icon-button (click)="hideExtraInfo(item)" *ngIf="!item?.hide">
              <!-- <mat-icon class="add-button">remove</mat-icon> -->
              <svg-icon [applyCss]="true" class="text-center items-info" src="assets/svgs/minus.svg"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </button>
          </div>
        </div>
        <div *ngIf="!item?.hide">
          <div fxLayout="column" *ngFor="let list of item?.items; let last = last" class="nested-list" [ngClass]="{'separator': !last}">
            <div fxLayout="column">
              <div fxLayout="column">
                <p class="m-0 font-weight-bold name">{{list?.productName}}
                  <span *ngIf="list?.requirement?.quantity"> needed</span>
                  <span *ngIf="list?.cropStage">
                    <span>(</span>
                    <span> {{ list?.cropStage }} </span>
                    <span>)</span>
                  </span>
                </p>
                <p class="date m-0" *ngIf="list?.fromDate">
                  {{list?.fromDate | date:'dd MMM'}}
                  <span *ngIf="list?.fromDate && list?.toDate"> - </span>
                  <span>{{list?.toDate | date:'dd MMM'}}</span>
                </p>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" [ngClass]="{'mt-1': list?.fromDate}">
                <div>
                  <p class="date m-0" *ngIf="list?.requirement?.quantity">
                    <!-- <span>Requirements: </span> -->
                    <!--<span *ngIf="!list?.requirement?.quantity">{{list?.requirement?.quantity | number: '1.0-0'}} </span>-->
                    <span class="font-weight">Quantity:&nbsp;</span>
                    <span *ngIf="list?.requirement?.quantity && list?.requirement?.quantity !== '0'">{{((list?.requirement?.quantity | number:'1.0-0') === '0') ? '1' :  (list?.requirement?.quantity | number:'1.0-0')}}</span>
                    <span *ngIf="list?.requirement?.quantity && list?.requirement?.unit && list?.requirement?.quantity !== '0'"> {{list?.requirement?.quantity > 1 ? list?.requirement?.unit : singleUnit[list?.requirement?.unit]}}</span>
                    <span *ngIf="list?.tagData?.quantityPerAcre?.value && list?.requirement?.quantity && list?.requirement?.unit">
                      <span> (</span>
                      <span>{{((list?.tagData?.quantityPerAcre?.value | number:'1.0-0') === '0') ? '1' :  (list?.tagData?.quantityPerAcre?.value | number:'1.0-0')}}
                        <span> {{list?.tagData?.quantityPerAcre?.value > 1 ? list?.tagData?.quantityPerAcre?.unit : singleUnit[list?.tagData?.quantityPerAcre?.unit]}}/</span>
                        <span *ngIf="item?.title === 'Machinery'">{{ list?.tagData?.noOfAcres }}&nbsp;</span>
                        <span>{{ item?.title === 'Machinery' ? ( list?.tagData?.noOfAcres > 1 ? 'acres' : 'acre' ) : 'acre' }}</span>
                      </span>
                      <span>)</span>
                    </span>
                  </p>
                </div>

                <!-- Cost -->
                <p class="date m-0" [ngClass]="{'mt-1': list?.requirement?.quantity}">
                  <span *ngIf="list?.requirement?.cost">
                    <span class="font-weight">Value:&nbsp;</span>
                    <span> ₹</span>
                    <span>{{list?.requirement?.cost | number: '1.0-0'}} </span>
                  </span>
                  <span *ngIf="list?.tagData?.cost && list?.requirement?.cost">
                    <span> (₹</span>
                    <span>{{list?.tagData?.cost}}/</span>
                    <span>{{(item?.title === 'Machinery' || item?.title === 'Services') ? 'hour' : 'acre' }} </span>
                    <span>)</span>
                  </span>
                </p>
              </div>

              <div  *ngIf="item?.title === 'Services' || item?.title === 'Machinery'">
                <p class="date m-0" *ngIf="list?.requirement?.timeInHours">
                  <span class="service-hours font-weight">No of hours:&nbsp;</span>
                  <span *ngIf="list?.requirement?.timeInHours">{{list?.requirement?.timeInHours}}</span>
                  <span *ngIf="list?.tagData?.noOfHourPerAcre && list?.requirement?.timeInHours">
                    <span> (</span>
                    <span>{{list?.tagData?.noOfHourPerAcre}} {{list?.tagData?.noOfHourPerAcre > 1 ? 'hours' : 'hour'}}/acre</span>
                    <span>)</span>
                  </span>
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="predictionList?.length <= 0 && !progress" class="my-5 py-3">
    <kl-no-content
      svgIcon="assets/svgs/information.svg"
      text="No inputs">
    </kl-no-content>
  </div>
</div>
