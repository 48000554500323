import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KL_MOBILE_DIALOG_DATA, MobileDialogConfig, KalgudiMobileDialogRef } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-training-program-post-mobile-dialog',
  templateUrl: './training-program-post-mobile-dialog.component.html',
  styleUrls: ['./training-program-post-mobile-dialog.component.scss']
})
export class TrainingProgramPostMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
   }

  ngOnInit(): void {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {}
    };
    this.dialogRef.close(result);
  }

}
