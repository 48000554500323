import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment, KalgudiUserBasicDetails, StoreProductLevel2, StoreProductLevel3 } from '@kalgudi/types';

@Component({
  selector: 'kl-common-traceability-details',
  templateUrl: './common-traceability-details.component.html',
  styleUrls: ['./common-traceability-details.component.scss']
})
export class CommonTraceabilityDetailsComponent implements OnInit {

  @Input()
  details: any;

  @Input()
  traceabilityDetails: any;

  @Input()
  basicDetails: any;

  @Input()
  level2ProductDetails: StoreProductLevel2;

  @Input()
  fulFillmentDetails: any;

  @Input()
  productLevel3Title: string;

  @Input()
  attachments: Attachment[];

  @Input()
  showBreadCrumbs: boolean;

  @Input()
  showGallery: boolean  = false;

  @Input()
  subImage: string;

  @Input()
  showDetailsCard: boolean

  @Input()
  bgColor = '#cbeeff';

  @Input()
  showProfilePic: boolean;

  @Input()
  showLocationDetails: boolean;

  @Input()
  level3ProductDetails: StoreProductLevel3;

  @Input()
  supplierInfo: any;

  @Input()
  profileInfo: KalgudiUserBasicDetails;

  @Input()
  fullFillmentCenterBasedOnStore: any;

  defaultProfilePic = 'assets/images/default-profile.jpeg';

  constructor(
    private router: Router,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
    private util: KalgudiUtilityService
  ) { }

  ngOnInit() {
  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: Attachment[], index?: number): void {

    this.kalgudiPhotoswipe.open(attachments, this.env.domain, index).subscribe();
  }
}
