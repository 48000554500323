import { Injectable } from '@angular/core';
import { ApiResponseProjectMembersData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiMembersApiService } from './kalgudi-members-api.service';

@Injectable()
export class KalgudiMembersService {

  constructor(private api: KalgudiMembersApiService) { }

  /**
   * Make an api call get project members
   */
  getMembers(projectId: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {
    return this.api.getMembers(projectId, offset, limit);
  }

  /**
   * Make an api call to search project members
   */
  searchMembers(projectId: string, searchKeyword: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {
    return this.api.searchMembers(projectId, searchKeyword, offset, limit);
  }
}
