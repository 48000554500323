<div id="location-range-filter">
  <div [formGroup]="formGroup">
    <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="column">

      <div fxLayout="row" fxLayoutGap="10px" fxFlex="calc(60% - 10px)">

        <!-- Country field -->
        <div fxFlex="calc(50% - 10px)">
          <mat-form-field appearance="outline" color="accent" class="mat-form-field-sm mw-100">
            <mat-label translate>Country</mat-label>

            <mat-select panelClass="mat-select-sm" formControlName="country"
              (selectionChange)="setCountry($event?.value?.id)">
              <mat-option *ngFor="let item of countries; trackBy: trackById" [value]="item">
                {{ item?.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- /Country field -->

        <!-- State field -->
        <div fxFlex="calc(50% - 10px)">
          <mat-form-field appearance="outline" color="accent" class="mat-form-field-sm mw-100">
            <mat-label translate>State</mat-label>

            <mat-select panelClass="mat-select-sm" formControlName="state"
              (selectionChange)="setState($event?.value?.id)">
              <mat-option *ngFor="let item of states; trackBy: trackById" [value]="item">
                {{ item?.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- / State field -->

      </div>

      <div fxLayout="row" fxLayoutGap="10px" fxFlex="calc(40% - 10px)">

        <!-- District field -->
        <div fxFlex="calc(65% - 10px)" fxFlex.xs="calc(90% - 10px)">
          <mat-form-field appearance="outline" color="accent" class="mat-form-field-sm mw-100">
            <mat-label translate>District</mat-label>
            <mat-select panelClass="mat-select-sm" formControlName="district"
              (selectionChange)="setDistrict($event?.value?.id)">
              <mat-option *ngFor="let item of districts; trackBy: trackById" [value]="item">
                {{ item?.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Add -->
        <div class="ml-n1" fxFlex="calc(35% - 10px)" fxFlex.xs="calc(10% - 10px)">
          <a fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center" fxLayoutAlign.xs="center center" class="h-100">
            <button mat-icon-button type="button" color="accent" [matTooltip]="'Click to add location range' | translate"
              (click)="selectItem()" [disabled]="formGroup?.invalid">
              <mat-icon class="add-btn">add_circle_outline</mat-icon>
            </button>
            <!-- <a class="mb-0" (click)="selectItem()">Add</a> -->
          </a>
        </div>
        <!-- /Add -->

      </div>

    </div>
  </div>


  <div class="my-3">
    <mat-chip-list class="mb-4 kl-chip-list-sm">

      <mat-chip *ngFor="let item of selectedRange; let i = index" @fadeIn>
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
          <div>
            <span class="text-truncate" >{{ item.value | translate }}</span>
          </div>
          <mat-icon matChipRemove (click)="removeItem(i)" [title]="'Remove location' | translate ">
            cancel
          </mat-icon>
        </div>
      </mat-chip>

    </mat-chip-list>
  </div>


</div>
