import { Inject, Directive } from '@angular/core';
import {
  KalgudiAppService,
  KalgudiInboxStream,
  KalgudiStreamData,
  KalgudiStreamLoadAction,
  KalgudiUtilityService,
  KalgudiWebNotificationsService,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser, NotificationsList } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { KalgudiHomeStreamStateService } from './services/kalgudi-home-stream-state.service';
import { KalgudiHomeStreamService } from './services/kalgudi-home-stream.service';


@Directive()
export abstract class KalgudiHomeStream extends KalgudiInboxStream<NotificationsList> {

  /**
   * Default stream load action.
   */
  streamLoadAction: KalgudiStreamLoadAction = 'concat';

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected webNotifications: KalgudiWebNotificationsService,
    protected kalgudiApp: KalgudiAppService,
    protected homeStream: KalgudiHomeStreamService,
    protected homeStreamState: KalgudiHomeStreamStateService,
    protected util: KalgudiUtilityService,
  ) {

    // Wake (initialize) my parent
    super(notification, util);

    // Update paginator limit to 40
    this.pageLimit = 10;
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Logged in user profile
   */
  get profile$(): Observable<KalgudiUser> {
    return this.kalgudiApp.profile$;
  }

  /**
   * Logged in user connects count
   */
  get connectsCount$(): Observable<number> {
    return this.webNotifications.notifications$
      .pipe(

        // Map connects count from the web notification object
        map(r => r.connectsCount)
      );
  }

  /**
   * Logged in user profile views count
   */
  get profileViewsCount$(): Observable<number> {

    return this.webNotifications.notifications$
      .pipe(

        // Map profile view count from web notification object
        map(r => r.profileViewsCount)
      );
  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<NotificationsList> {
    return this.homeStreamState.newStreamItem$;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * Fetches home stream items from API.
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.homeStream.getHomeStream(offset, limit)
      .pipe(

        // Take first emitted value from the stream
        // This is required as the home stream items has two
        // data sources i.e. web notification and feeds API
        // Hence take either of the streams at a time.
        // Not required for other streams having single data source
        take(1),

        // Maps kalgudi home stream item response to KalgudiStreamData format
        map(items => ({ items })),

        // tap(items => console.log(items))
      );
  }

}
