import { Component, forwardRef, Injector, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ActivityTags } from './activity-tags';



// Page tags NG_VALUE_ACCESSOR provider for custom form control
const PAGE_TAG_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ActivityTagsComponent),
  multi: true,
};


@Component({
  selector: 'kl-activity-tags',
  templateUrl: './activity-tags.component.html',
  styleUrls: ['./activity-tags.component.scss'],
  providers: [ PAGE_TAG_FORM_CONTROL_PROVIDER ]

})
export class ActivityTagsComponent extends ActivityTags implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

}
