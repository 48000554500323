import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageTeamAnalytics } from '../../classes/page-team-analytics';

@Component({
  selector: 'kl-teams-analytics',
  templateUrl: './teams-analytics.component.html',
  styleUrls: ['./teams-analytics.component.scss']
})
export class TeamsAnalyticsComponent extends KalgudiPageTeamAnalytics implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initStream();

  }

}
