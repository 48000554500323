<div id="kl-schedule-send" class="h-100" fxLayout.xs="column" fxLayoutAlign.xs="space-between">

  <div  fxLayout fxLayout.xs="column" fxLayoutGap="25px">
    <div class="calendar">
      <mat-calendar [minDate]="today" [selected]="selectedDate" (selectedChange)="onSelection($event)"></mat-calendar>
    </div>

    <div class="pt-4" fxLayout.xs="row" fxLayoutGap.xs="4px" fxLayoutAlign.xs="center center">

      <p class="mb-0" [ngClass]="{'border-bottom': selectedDate}" fxFlex.xs="50%">
        {{selectedDate | date: 'MMM' | translate}}
        {{selectedDate | date: 'dd,'}}
        {{selectedDate | date: 'yyyy'}}
      </p>

      <!-- <mat-form-field floatLabel='never' fxFlex.xs>
        <input matInput readonly [ngxTimepicker]="picker" placeholder="Enter time" [formControl]="selectedTime">
        <mat-icon matSuffix>alarm</mat-icon>
        <ngx-material-timepicker #picker format="24"></ngx-material-timepicker>
      </mat-form-field> -->

    </div>
  </div>

  <div fxLayout fxLayoutAlign="end end">
    <div fxFlex="110px">
      <kl-flat-button
        [label]="postDetails ? 'Update' : 'Schedule send'"
        [disabled]="!selectedDate"
        (clicked)="scheduleSend()"></kl-flat-button>
    </div>
  </div>

</div>
