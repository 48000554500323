<section class="likes-list">
  <div fxLayout="row wrap" fxLayoutGap="10px grid" fxLayout.xs="column" fxLayoutGap.xs="8px">
    <div fxFlex="50%" fxFlex.xs="100%" *ngFor="let item of likesList;let last = last">
      <mat-card class="like-tile">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="2px">

          <!-- User profile pic -->
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" class="mw-100">
            <div fxLayoutAlign="center center" *ngIf="item?.profilePicURL; else noImage" class="profile-pic">
              <img [src]="item?.profilePicURL | prefixDomain" class="user-image"
                [klErrorImages]="[
                (item?.profilePicURL) | prefixDomain,
                'assets/images/default-profile.jpeg'
                ]">
            </div>

            <ng-template #noImage>
              <img src="assets/images/default-profile.jpeg" class="user-image">
            </ng-template>

            <div fxLayout="column" fxFlex="82%">
              <div class="cursor-pointer" (click)="viewProfile(item?.id); $event.stopPropagation()">
                <span class="name text-truncate d-block">{{ item?.name | secureMobile }}</span>
              </div>

              <div>
                <span class="location text-muted d-block text-truncate">{{ item?.locationLong }}</span>
              </div>
            </div>
          </div>

          <!-- User Details -->
          <div fxLayout="row" fxLayoutAlign="space-between center" class="tile-details">
            <div fxLayout="row" fxLayoutGap="3px" class="like-date">
              <mat-icon class="text-center like-icon action-icon">
                thumb_up
              </mat-icon>
              <span class="mb-0 date">{{ item?.TS | date:'dd MMM yyyy' }}</span>
            </div>

            <!-- <div>
              <span class="activity">See all activity</span>
            </div> -->
          </div>

        </div>
      </mat-card>
    </div>
  </div>

  <div *ngIf="progress" fxLayout class="no-content" fxLayoutAlign="center center">
    <kl-no-content
      progressText="Loading..."
      [progress]="progress">
    </kl-no-content>
  </div>
</section>
