import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiSurveyDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { KalgudiCreateSurveyService } from '../../survey-creation/services/create-survey.service';

@Injectable()
export class SurveyActionService {

  constructor( private createSurveyService: KalgudiCreateSurveyService) { }

  /**
   * Open survey creation dialog
   */
  showCreateSurveyDialog(creationPayload: PartialData = {}): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Create',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        creationPayload,
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '70vw',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.createSurveyService.openSurveyCreationDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(res => res.accepted),

      );
  }

  /**
   * Open clone survey dialog
   */
  showCloneSurveyDialog(surveyDetails: KalgudiSurveyDetails): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Clone survey',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        surveyDetails: surveyDetails
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '50vw',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.createSurveyService.openCloneSurveyDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(res => res.accepted),

      );
  }

}
