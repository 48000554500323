<div id="satellite-advisories-mobile">

  <div>
    <kl-satellite-advisories-land-details
      [pageId]="pageId"
      [syncId]="syncId"
      [profileKey]="profileKey"
      [landId]="landId">
    </kl-satellite-advisories-land-details>
  </div>

</div>
