<main class="banner-tile">

   <!-- Slider section -->
   <ngu-carousel #bannerCarousel [inputs]="carouselConfig" [dataSource]="carouselItems">

    <!-- <div *nguCarouselDef="let item;" class="banner-item">
      <div class="tile" class="mw-100" >
        <div [style.background-image]="item"></div>
        <img [src]="item" width="635" class="mw-100">
      </div>
    </div> -->

    <ngu-tile class="p-0 cursor-pointer" *nguCarouselDef="let item; let j = index" (click)="openStorePage()">
      <div class="tile w-100" [style.background]="'url(' + item + ')'">
       <!-- <h1>{{j}}</h1> -->
      </div>
    </ngu-tile>

    <button NguCarouselNext class="previous-button position-absolute m-auto rounded-circle cursor-pointer text-white border-0" fxLayout fxLayoutAlign="center center">
      <mat-icon>navigate_next</mat-icon>
    </button>

    <button NguCarouselPrev class="next-button position-absolute m-auto rounded-circle cursor-pointer text-white border-0" fxLayout fxLayoutAlign="center center">
      <mat-icon>navigate_before</mat-icon>
    </button>

    <!-- <ul class="points overflow-auto text-center m-0" NguCarouselPoint>
      <li *ngFor="let j of bannerCarousel.pointNumbers; let j = index" [class.active]="j==bannerCarousel.activePoint" (click)="bannerCarousel.moveTo(j)"></li>
    </ul> -->

  </ngu-carousel>
  <!--/ Slider section -->
</main>