import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KalgudiNotification, KL_NOTIFICATION, } from '@kalgudi/core/config';
import { BottomSheetMenu, UIMenu } from '@kalgudi/types';

import { SocialSharesService } from '../../services/social-shares.service';

@Component({
  selector: 'kl-social-shares',
  templateUrl: './social-shares.component.html',
  styleUrls: ['./social-shares.component.scss']
})
export class SocialSharesComponent implements OnInit {

  @Input()
  shareCount: number;

  @Input()
  hideShareLabel: boolean = false;

  @Input()
  btnColor: string;

  @Input()
  url: string;

  @Input()
  direction: string;

  isLoggedIn: boolean;

  listMenu: BottomSheetMenu =
    {
      title: 'Share event on social media',
      styleType: 'list',
      menu: [
        {
          id: 'CLICK_TO_COPY_URL',
          title: 'Copy url',
          faIcon: 'fa fa-clone copy-icon',
          iconColor: '#464a4e'
        },
        {
          id: 'SHARE_TO_WHATSAPP',
          title: 'Whatsapp',
          faIcon: 'fab fa-whatsapp',
          iconColor: '#0ee00e'
        },
        {
          id: 'SHARE_TO_FACEBOOK',
          title: 'Facebook',
          faIcon: 'fab fa-facebook-f',
          iconColor: '#385898'
        },
        {
          id: 'SHARE_TO_TWITTER',
          title: 'Twitter',
          svgIcon: 'assets/svgs/new-twitter-icon.svg',
          iconColor: '#1da1f2'
        },
        {
          id: 'SHARE_TO_LINKEDIN',
          title: 'Linkedin',
          faIcon: 'fab fa-linkedin-in',
          iconColor: '#544bc2'
        },
        {
          id: 'SHARE_TO_EMAIL',
          title: 'Email',
          faIcon: 'fas fa-envelope-square',
          iconColor: '#d33f2b'
        }
      ]
    };

  @ViewChild(MatMenuTrigger, { static: true }) triggerBtn: MatMenuTrigger;

  constructor(
    private socialShareService: SocialSharesService,
    private bottomSheetService: KalgudiBottomSheetService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,

    private kalgudiApp: KalgudiAppService) {

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
  }

  openShareButton() {
    if (!checkMobileDevice()) {
      this.triggerBtn.openMenu();
    }
    else {
      this.triggerBtn.closeMenu();
      this.openBottomSheet();
    }
  }

  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }


  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'SHARE_TO_FACEBOOK':
        this.shareToFacebook();
        break;

      case 'SHARE_TO_TWITTER':
        this.shareToTwitter();
        break;

      case 'SHARE_TO_LINKEDIN':
        this.shareToLinkedin();
        break;

      case 'SHARE_TO_WHATSAPP':
        this.shareToWhatsappMobile();
        break;

      case 'SHARE_TO_EMAIL':
        this.shareToEmailMobile();
        break;
        case 'CLICK_TO_COPY_URL':
          this.clickToCopyUrl();
          break;
    }
  }

  shareToFacebook() {
    this.socialShareService.shareEventToFacebook(this.url);
  }
  shareToTwitter() {
    this.socialShareService.shareEventToTwitter(this.url);
  }
  shareToLinkedin() {
    this.socialShareService.shareEventToLinkedIn(this.url);
  }
  shareToWhatsappWeb() {
    this.socialShareService.shareEventToWhatsappWeb(this.url);
  }
  shareToWhatsappMobile() {
    this.socialShareService.shareEventToWhatsappMobile(this.url);
  }
  shareToEmailWeb() {
    this.socialShareService.shareEventToEmailWeb(this.url);
  }
  shareToEmailMobile() {
    this.socialShareService.shareEventToEmailMobile(this.url);
  }

  clickToCopyUrl() {
    this.socialShareService.eventClickToCopyUrl(this.url);
    this.notification.showMessage('Copied to clipboard');
  }
}
