import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiCreateCloneSurvey } from '../../classes/clone-survey';

@Component({
  selector: 'kl-clone-survey',
  templateUrl: './clone-survey.component.html',
  styleUrls: ['./clone-survey.component.scss']
})
export class CloneSurveyComponent extends KalgudiCreateCloneSurvey implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    if(this.surveyDetails && (this.surveyDetails.surveyBasicDetails.title || this.surveyDetails.surveyBasicDetails.expiryDate)) {
      this.cloneSurveyForm.get('title').setValue(this.surveyDetails.surveyBasicDetails.title);
      this.cloneSurveyForm.get('expiryDate').setValue(this.surveyDetails.surveyBasicDetails.expiryDate);
    }
  }

}
