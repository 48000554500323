<section class="processing-page mb-3 page-creation-form">
  <div class="w-100 university col-7 mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">

      <div fxLayout="column">
        <kl-page-type-autocomplete placeholder="Search and select stock page" [formControl]="stockPage" [required]="true" [pageType]="'STOCK'">
        </kl-page-type-autocomplete>
      </div>

      <div class="page-info" fxLayout="column" fxLayoutGap="18px">


        <div class="farmer-picker rounded mb-3 p-1 pl-2 cursor-pointer position-relative" fxLayout="row"
          fxLayoutAlign="space-between center" fxLayoutGap="15px" (click)="pickFarmer()"
          [matTooltip]="'Click here to add a farmer' | translate">

          <div class="picker position-absolute w-100 rounded"></div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

            <ng-container>
              <div>
                <span
                  class="member-picker" translate>Click to pick processor</span>
              </div>
            </ng-container>

          </div>

          <!-- Add button -->
          <div class="mr-n1" fxLayoutAlign="end end">
            <button mat-icon-button class="add" color="accent">
              <mat-icon>
                add
              </mat-icon>
            </button>
          </div>
          <!--/ Add button -->

        </div>
        <div class="chip px-3 pb-2" *ngIf="processorsField?.value">
          <mat-chip-list>
            <!-- Mat chip iteration-->
            <mat-chip *ngFor="let user of processorsField?.value;let i = index">
              <span>{{ user?.firstName | titlecase | trimSpecialCharacters }}</span>
              <span><mat-icon class="chip-icon ml-2" (click)="removeProcessor(i)">clear</mat-icon></span>

          </mat-chip>
            <!-- / Mat chip iteration-->
          </mat-chip-list>
        </div>

      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
        <mat-form-field appearance="outline" fxFlex="calc(50% - 12px)">
          <mat-label translate>Quantity</mat-label>
          <input matInput [placeholder]="'Quantity' | translate" formControlName="quantity"
            required>
          <span></span>
          <mat-error class="error-field" *ngIf="!pageFormControls?.quantity?.valid"><span translate>Quantity should be less than or equal to total quantity</span>({{totalQuantity?.kgs}}) </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="calc(50% - 12px)">
          <mat-select [placeholder]="'Units' | translate" formControlName="units">
            <mat-option *ngFor="let unit of units" [value]="unit">
              {{ unit | translate }}
            </mat-option>
          </mat-select>
          <mat-error class="error-field" *ngIf="unitsField?.touched" translate>Please select units</mat-error>
        </mat-form-field>
      </div>

    </form>
    <!-- / Wrapper Form -->
  </div>
</section>
