import { Injector, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyAnalyticsData } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiSurveyService } from '../../../services/kalgudi-survey.service';
import { SurveyStateService } from '../../../services/survey-state.service';

@Directive()
export abstract class KalgudiSurveyAnalytics extends KalgudiDestroyable {

  progress: boolean;
  surveyId: string;

  surveyAnalytics: KalgudiSurveyAnalyticsData;

  private notification: KalgudiNotification;
  private stateService: SurveyStateService;
  private kalgudiSurveyService: KalgudiSurveyService;

  constructor(protected injector: Injector) {

    super();

    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.stateService         = this.injector.get(SurveyStateService);
    this.kalgudiSurveyService = this.injector.get(KalgudiSurveyService);

    this.stateService.id$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        id => {
          this.surveyId = id;
        }
      );

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Call service method to send survey analytics
   */
  fetchSurveyAnalytics() {

    this.progress = true;

    this.kalgudiSurveyService.getSurveyAnalytics(this.surveyId)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.surveyAnalytics = res.data;
        },
        err => this.onFetchSurveyAnalyticsError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for send survey analytics API errors.
   */
  protected onFetchSurveyAnalyticsError(err: Error): void {
    this.notification.showMessage('Unable to fetch survey analytics');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
