import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ProjectStateService } from '../../../project-shared/services/project-state.service';


@Component({
  selector: 'kl-task-filters',
  templateUrl: './task-filters.component.html',
  styleUrls: ['./task-filters.component.scss']
})
export class TaskFiltersComponent implements OnInit {

  projectStatus = ['Not started', 'In process', 'Completed'];

  varietyTags = ['Crop calendar', 'Management', 'Agriculture', 'Plantation', 'Paddy'];

  templateType = ['Land preparation'];

  constructor(
    private stateChangeService: ProjectStateService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.updateDateLocale();
  }

  closeSidebar() {
    this.stateChangeService.updateFilterSideBarStatus();
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }
}
