import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiCommonDirectivesModule,
  KalgudiCommonModule,
  KalgudiNoContentModule,
  KalgudiProductsPickerModule,
  KalgudiStepperModule,
  KalgudiUserPickerModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSelectCardModule } from '@kalgudi/common/ui/select-card';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { PageAutocompleteModule } from '../page-autocomplete/page-autocomplete.module';
import { CommonPageCreationFormComponent } from './components/common-page-creation-form/common-page-creation-form.component';
import { CompanyPageCreationComponent } from './components/company-page-creation/company-page-creation.component';
import { FarmerPageCreationComponent } from './components/farmer-page-creation/farmer-page-creation.component';
import {
  OrganizationPageCreationComponent,
} from './components/organization-page-creation/organization-page-creation.component';
import { PageCreationComponent } from './components/page-creation/page-creation.component';
import { ProcessingPageCreationComponent } from './components/processing-page-creation/processing-page-creation.component';
import { ProgramPageCreationComponent } from './components/program-page-creation/program-page-creation.component';
import { SalesTeamsPageCreationComponent } from './components/sales-teams-page-creation/sales-teams-page-creation.component';
import { StockPageCreationComponent } from './components/stock-page-creation/stock-page-creation.component';
import { UniversityPageCreationComponent } from './components/university-page-creation/university-page-creation.component';
import { CreatePageStateService } from './services/create-page-state.service';
import { KalgudiCreatePageApiService } from './services/kalgudi-create-page-api.service';
import { KalgudiCreatePageService } from './services/kalgudi-create-page.service';
import { FarmersInterestGroupPageCreationComponent } from './components/farmers-interest-group-page-creation/farmers-interest-group-page-creation.component';


@NgModule({
  declarations: [
    ProgramPageCreationComponent,
    UniversityPageCreationComponent,
    OrganizationPageCreationComponent,
    FarmerPageCreationComponent,
    CompanyPageCreationComponent,
    PageCreationComponent,
    CommonPageCreationFormComponent,
    StockPageCreationComponent,
    ProcessingPageCreationComponent,
    SalesTeamsPageCreationComponent,
    FarmersInterestGroupPageCreationComponent,
  ],
  imports: [
    CommonModule,

    // Svg Icon Module
    AngularSvgIconModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Flex Module
    FlexLayoutModule,


    // Material Modules
    MatCardModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatStepperModule,
    CdkStepperModule,
    MatChipsModule,
    MomentDateModule,

    // Kalgudi libraries
    KalgudiButtonModule,
    KalgudiStepperModule,
    KalgudiCommonDirectivesModule,
    KalgudiCommonModule,
    KalgudiGooglePlacesModule,
    KalgudiSelectCardModule,
    KalgudiProductsPickerModule,
    PageAutocompleteModule,
    KalgudiUserPickerModule,
    KalgudiPipesModule,
    KalgudiNoContentModule,

    TranslateModule
  ],
  exports: [
    ProgramPageCreationComponent,
    UniversityPageCreationComponent,
    OrganizationPageCreationComponent,
    FarmerPageCreationComponent,
    CompanyPageCreationComponent,
    PageCreationComponent,
    ProcessingPageCreationComponent,
    SalesTeamsPageCreationComponent
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
    KalgudiCreatePageService,
    KalgudiCreatePageApiService,
    CreatePageStateService,
  ]
})
export class KalgudiPageCreationModule { }
