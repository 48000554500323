import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiPageAddTaskMembers } from '../../classes/kalgudi-page-add-task-members';

@Component({
  selector: 'kl-add-page-task-members',
  templateUrl: './add-page-task-members.component.html',
  styleUrls: ['./add-page-task-members.component.scss']
})
export class AddPageTaskMembersComponent extends KalgudiPageAddTaskMembers implements OnInit {

  readonly TABS = {
    TARGET_MEMBERS: { index: 0, title: 'Target members' },
    BULK_MEMBERS: { index: 1, title: 'Bulk members' },
  };

  activeTabIndex: number;
  isEnableBulkOption: boolean = false;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
    ) {

    super(notification, util, injector);

    this.activeTabIndex = this.TABS.TARGET_MEMBERS.index;
  }

  ngOnInit() {

    this.initStream();

    this.getTaskDetails();
    this.isEnableBulkOption = !(this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'FPO_APP' || this.environment.appId === 'SHG_NET_APP');
  }

  onDestroyed(): void {}


}
