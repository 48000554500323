import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProjectCreationService } from '../../project-creation/services/kalgudi-project-creation.service';
import { ProjectStateService } from '../../project-shared/services/project-state.service';

@Component({
  selector: 'kl-members-list-header',
  templateUrl: './members-list-header.component.html',
  styleUrls: ['./members-list-header.component.scss'],
  animations: kalgudiAnimations
})
export class MembersListHeaderComponent implements OnInit {

  searchForm: FormGroup;

  @Output()
  showSidebar = new EventEmitter<any>();

  constructor(
    private stateChangeService: ProjectStateService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private projectCreationService: KalgudiProjectCreationService
  ) {

    // Initialize search form
    this.searchForm = new FormGroup({
      searchKeyword: new FormControl('')
    });
  }

  ngOnInit() {
  }


  openFilterSidebar() {
    this.stateChangeService.updateFilterSideBarStatus();
  }

}
