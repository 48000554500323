<div id="kalgudi-image-chip-list" class="mt-1 mb-4" *ngIf="attachments?.length">
  <div fxLayout="row" *ngIf="!hideChipList">
    <div fxFlex fxFlexAlign="start">
      <mat-chip-list>
        <ng-container *ngFor="let item of attachments; let i = index">
          <mat-chip class="pl-0 chips" [ngClass]="{'pr-0': hideRemove && !item?.context}">

            <!-- Image attachment -->
            <img class="chip-image cursor-pointer rounded-circle mr-1"
              *ngIf="item?.msgType === attachmentType?.IMAGE"
              [src]="item.url | prefixDomain"
              (click)="openFullview()">

            <!-- Document attachment -->
            <div class="doc-attach" *ngIf="item?.msgType === attachmentType?.DOCUMENT" fxLayout="row" fxLayoutAlign="center center">
              <i class="fas fa-file-alt"></i>
            </div>

            <div class="audio-icon" *ngIf="item?.msgType === attachmentType?.AUDIO" fxLayout="row" fxLayoutAlign="center center">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>

            <div class="audio-icon" *ngIf="item?.msgType === attachmentType?.VIDEO" fxLayout="row" fxLayoutAlign="center center">
              <svg-icon class="svg-icon video-icon ml-2"  src="assets/svgs/attach-file/video-camera-with-play-button.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20, 'fill': 'black'}">
              </svg-icon>
            </div>

            <a class="description text-truncate ml-1"
              [href]="item?.url | prefixDomain"
              target="_blank"
              [ngClass]="{'truncate': item?.msgType === attachmentType?.DOCUMENT && isTruncate}"
              [title]="item?.msgType === attachmentType?.IMAGE ? 'View image'
                : item?.msgType === attachmentType?.DOCUMENT ? 'View document'
                : item?.msgType === attachmentType?.AUDIO ? 'View audio'
                : 'View file'">
              {{item?.context}}
            </a>
            <!-- <span class="text-truncate" [title]="item?.context" *ngIf="item?.msgType !== attachmentType?.DOCUMENT">{{item?.context}}</span> -->
            <mat-icon matChipRemove [title]="'Remove image' | translate" (click)="removeImage(i)" *ngIf="!hideRemove">cancel</mat-icon>
          </mat-chip>
        </ng-container>
      </mat-chip-list>
    </div>
  </div>

  <div *ngIf="hideChipList" fxLayout="row wrap" fxLayoutGap="5px">
    <div class="wrapper-img position-relative d-inline-block mb-2" *ngFor="let item of attachments; let i = index;" fxFlex="calc(33.3% - 5px)">

      <button color="warn" class="positioning position-absolute text-center" mat-mini-fab (click)="removeImage(i)">
        <mat-icon class="remove-icon text-white">close</mat-icon>
      </button>

      <div class="image-textarea position-relative">

        <!-- Image attachment -->
        <div *ngIf="item?.msgType === attachmentType.IMAGE"
          class="w-100 h-100 image-textarea"
          [style.background-image]="'url('+ (item?.url | prefixDomain) +')'" (click)="openFullview()">
        </div>

        <!-- Document attachment -->
        <div class="doc-attach" *ngIf="item?.msgType === attachmentType.DOCUMENT" fxLayout="row" fxLayoutAlign="center center">
          <a [href]="item?.url | prefixDomain" target="_blank" [title]="'View file' | translate"><i class="fas fa-file-alt"></i></a>
        </div>

        <!-- Audio attachment -->
        <div class="audio-attach" *ngIf="item?.msgType === attachmentType.AUDIO" fxLayout="row" fxLayoutAlign="center center">
          <audio class="w-100 outline-none audio-attachment" controls="false" controlsList="nodownload">
            <source [src]="item?.url | prefixDomain" type="audio/mpeg">
          </audio>
        </div>

        <!-- Video attachment -->
        <div class="audio-attach" *ngIf="item?.msgType === attachmentType.VIDEO" fxLayout="row" fxLayoutAlign="center center">
          <video class="outline-none" width="100%" height="115" controls>
            <source [src]="item?.url | prefixDomain" type="video/mp4"/>
          </video>
        </div>

      </div>

    </div>
  </div>
</div>
