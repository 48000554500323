import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormlyModule } from '@ngx-formly/core';

import { FormlyValidations } from './classes/formly-validations';
import { FormlyArrayTypeComponent } from './components/formly-array-type.component';
import { FormlyNullTypeComponent } from './components/formly-null-type.component';
import { FormlyObjectTypeComponent } from './components/formly-object-type.component';
import { KalgudiNgxFormlyMapperService } from './services/kalgudi-ngx-formly-mapper.service';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
    FormlyObjectTypeComponent,
    FormlyArrayTypeComponent,
    FormlyNullTypeComponent,
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,
    TranslateModule,

    FormlyModule.forRoot({

      validationMessages: [
        { name: 'const', message: FormlyValidations.constValidationMessage },
        { name: 'exclusiveMaximum', message: FormlyValidations.exclusiveMaximumValidationMessage },
        { name: 'exclusiveMinimum', message: FormlyValidations.exclusiveMinimumValidationMessage },
        { name: 'max', message: FormlyValidations.maxValidationMessage },
        { name: 'maxItems', message: FormlyValidations.maxItemsValidationMessage },
        { name: 'maxlength', message: FormlyValidations.maxLengthValidationMessage },
        { name: 'min', message: FormlyValidations.minValidationMessage },
        { name: 'minItems', message: FormlyValidations.minItemsValidationMessage },
        { name: 'minlength', message: FormlyValidations.minLengthValidationMessage },
        { name: 'multipleOf', message: FormlyValidations.multipleOfValidationMessage },
        { name: 'uniqueItems', message: FormlyValidations.duplicateItemsErrorMessage },
      ],
      types: [
        { name: 'string', extends: 'input' },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number',
            },
          },
        },
        { name: 'boolean', extends: 'checkbox' },
        { name: 'enum', extends: 'select' },
        { name: 'null', component: FormlyNullTypeComponent, wrappers: ['form-field'] },
        { name: 'array', component: FormlyArrayTypeComponent },
        { name: 'object', component: FormlyObjectTypeComponent },
      ],
    }),
  ],
  exports: [
    FormlyObjectTypeComponent,
    FormlyArrayTypeComponent,
    FormlyNullTypeComponent,
  ],
  providers: [
    KalgudiNgxFormlyMapperService
  ]
})
export class KalgudiNgxFormlyModule { }
