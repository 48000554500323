<main id="ai-predictions-send-sms">
  <form autocomplete="off" [formGroup]="aiPredictionsSendSmsForm">

    <div>
      <mat-form-field appearance="outline" class="selection w-100">
        <mat-label translate>SMS template</mat-label>
        <mat-select formControlName="smsTemplate">
          <mat-option value="option" *ngFor="let type of smsTemplateList" [value]="type">{{ type?.smsTemplateTitle | translate}}</mat-option>
        </mat-select>
        <mat-error translate>Please select the SMS template type</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <!-- <mat-label translate>Selected template message</mat-label> -->
        <textarea matInput [placeholder]="'Selected template message' | translate" rows="3" formControlName="message"></textarea>
      </mat-form-field>
    </div>

    <div class="pt-1">
      <div *ngIf="varCount > 0" [formGroup]="varInputs">
        <div formArrayName="items"
          *ngFor="let item of varInputs.get('items')['controls']; let i = index;">
          <div [formGroupName]="i" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
            <div fxFlex="40%">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Input type</mat-label>
                <mat-select formControlName="type">
                  <mat-option *ngFor="let type of inputsValue" [value]="type">{{ type?.value }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div fxFlex="{{varInputs.get('items')?.value[i]?.type?.id === 'URL' ? '50%' : '60%'}}">
              <mat-form-field appearance="outline" class="w-100">
                <input matInput formControlName="value">
              </mat-form-field>
            </div>

            <!--<div fxFlex="10%" *ngIf="varInputs.get('items')?.value[i]?.type?.id === 'URL'" class="short-url-button">
              <button mat-raised-button color="accent" class="url-button">Short URL</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div>
      <a>Target audience count: 20</a>
    </div>

    <div class="w-100 mt-5" fxLayoutAlign="center center">
      <kl-flat-button label="SEND"></kl-flat-button>
    </div>
  </form>
</main>
