import { Directive, EventEmitter, Injector, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiFormValidators } from '@kalgudi/core';
import { IdValueMap } from '@kalgudi/types';

import { SIZE_IN_LIST } from '../../farmer-profile';
import { KalgudiProfileBasicDetailsEdit } from '../../profile-basic-edit/classes/kalgudi-profile-basic-details-edit';

@Directive()
export abstract class KalgudiOrgProfileEditDetails extends KalgudiProfileBasicDetailsEdit {

  @Output()
  closeDialog = new EventEmitter();

  readonly sizeInList: IdValueMap[] = SIZE_IN_LIST;
  isSamFpoNameEditable: boolean = true;

  // profileForm: FormGroup;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder
  ) {
    super(injector, fb);

    this.profileForm = this.newFarmerProfileBasicDetailsForm;


  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Getter for basic details form group
   */
  private get basicDetails(): AbstractControl {
    return this.profileForm.get('basicDetails');
  }

  /**
   * Getter for business type name field
   */
  private get businessTypeName(): AbstractControl {
    return this.basicDetails.get('businessTypeName');
  }

  /**
   * Getter for employee details
   */
  private get employeeDetails(): AbstractControl {
    return this.profileForm.get('employeeDetails');
  }

  /**
   * Getter for basic details group
   */
  private get basicDetailsGroup(): FormGroup {
    return this.profileForm.get('basicDetails') as FormGroup;
  }

  /**
   * Getter for zip code
   */
  get zipCode() : AbstractControl {
    return this.basicDetailsGroup.get('zipCode');
  }

  /**
   * Getter for employees
   */
  public get employessFiled(): AbstractControl {
    return this.basicDetailsGroup.get('employees');
  }

  /**
   * Getter for farmers
   */
  public get farmersFiled(): AbstractControl {
    return this.basicDetailsGroup.get('farmers');
  }

  /**
   * Getter for villages
   */
  public get villagesFiled(): AbstractControl {
    return this.basicDetailsGroup.get('villages');
  }

  /**
   * Getter for land group
   */
  public get landGroup(): FormGroup {
    return this.basicDetailsGroup.get('fpoAvgLand') as FormGroup;
  }

  /**
   * Getter for mobile no
   */
  public get mobileNoField(): AbstractControl {
    return this.basicDetailsGroup.get('alternateMobileNo');
  }

  /**
   * Getter for first name
   */
  public get firstNameField(): AbstractControl {
    return this.basicDetailsGroup.get('firstName');
  }

  /**
   * Getter for land
   */
  public get landFiled(): AbstractControl {
    return this.landGroup.get('value');
  }

  /**
   * Getter for Establish date
   */
  public get establishedSince(): AbstractControl {
    return this.basicDetailsGroup.get('establishedSince');
  }

  /**
   * Form group for employee basic details
   */
  private get newFarmerProfileBasicDetailsForm(): FormGroup {

    return this.fb.group({

      location: [{}],

      basicDetails: this.fb.group({
        firstName: ['', [...KalgudiFormValidators.alphabetValidator]],
        alternateMobileNo: ['', [...KalgudiFormValidators.indianMobileNumberValidators]],
        altemailId: ['', [...KalgudiFormValidators.strictEmailValidators]],
        businessTypeName: [''],
        aboutMe: [''],
        location: [{}],

        farmers: [''],
        employees: [''],
        villages: [''],
        establishedSince: [''],
        zipCode: [''],
        fpoAvgLand:  this.fb.group({
          unit: ['acre'],
          value: [0]
        })
      }),

    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Initializing to patch profile details
   */
  init() {
    //Made fpo title read only when business tyoe id FPO,FPC & cooperatives
    const businessType = this.profileDetails?.lstOfUserBusinessDetailsInfo[0]?.businessTypeName;
    if (this.environment.appId === 'SAM_FPO' && (businessType === "Producer Organizations (FPO)" || businessType === "Farmer producer Company (FPC)" || businessType === "Co-operative Societies")) {
      this.isSamFpoNameEditable = false
    }
    this.profileForm.get('basicDetails').patchValue(this.profileDetails);

    this.businessTypeName.patchValue(this.profileDetails.lstOfUserBusinessDetailsInfo[0].businessTypeName);

    // Patch employee details
    if (this.employeeDetails) {
      this.employeeDetails.patchValue(this.profileDetails.employeeProfileTo || {});
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * On profile basic details changed
   */
  protected onAddingEmployeeDetails(res): void {
    this.closeDialog.emit();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
