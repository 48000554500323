import { Component, Input, OnInit } from '@angular/core';
import { NotificationNetwork, ShareUpdateHeader } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-visited-tile',
  templateUrl: './profile-visited-tile.component.html',
  styleUrls: ['./profile-visited-tile.component.scss']
})
export class ProfileVisitedTileComponent implements OnInit {

  @Input()
  notification: NotificationNetwork;

  networksHeader: ShareUpdateHeader = {
    matIcon: 'group_add',
    iconColor: 'success',
    title: 'Viewed your profile',
    showMenu: false
  };

  constructor() { }

  ngOnInit() {
  }

}
