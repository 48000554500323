import { Component, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { PartialData, UIMenu } from '@kalgudi/types';

import { KalgudiTaskListHeader } from '../../classes/kalgudi-task-list-header';

@Component({
  selector: 'kl-task-list-header',
  templateUrl: './task-list-header.component.html',
  styleUrls: ['./task-list-header.component.scss'],
  animations: kalgudiAnimations
})
export class TaskListHeaderComponent extends KalgudiTaskListHeader implements OnInit {

  projectMobileMenu: UIMenu[] = [
    {
      id: 'GRID',
      svgIcon: 'assets/svgs/grid-view.svg',
      title: 'Grid'
    },
    {
      id: 'CALENDAR',
      svgIcon: 'assets/svgs/crop-calender.svg',
      title: 'Calendar',
      disabled: false
    }
  ];

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    // this.selectedView = this.projectsMenu[0].id;
  }

  onDestroyed(): void {}


  /**
   * Extra filters to get the projects
   */
  get filters(): PartialData {
    return {};
  }
}
