import { Component, Injector, OnInit } from '@angular/core';
import { KalgudiUserFarmerEquipmentDetails } from '@kalgudi/types';

import { KalgudiFarmerEquipments } from '../../classes/kalgudi-farmer-equipments';

@Component({
  selector: 'kl-farmers-equipments',
  templateUrl: './farmers-equipments.component.html',
  styleUrls: ['./farmers-equipments.component.scss']
})
export class FarmersEquipmentsComponent extends KalgudiFarmerEquipments implements OnInit {

  listEquipments: KalgudiUserFarmerEquipmentDetails[];

    equipmentsList: any[] = [
    {
      equipmentName: 'test',
      equipmentQuantity: 2,
      equipmentId: '1325435'
    },
    {
      equipmentName: 'test1',
      equipmentQuantity: 223,
      equipmentId: '1325436'
    },
    {
      equipmentName: 'test2',
      equipmentQuantity: 2233,
      equipmentId: '1325436'
    },
    {
      equipmentName: 'test3',
      equipmentQuantity: 22233,
      equipmentId: '1325436'
    },
    {
      equipmentName: 'test4',
      equipmentQuantity: 22333,
      equipmentId: '1325436'
    }
  ]

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
