import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kl-sms-log-status',
  templateUrl: './sms-log-status.component.html',
  styleUrls: ['./sms-log-status.component.scss']
})
export class SmsLogStatusComponent implements OnInit {

  @Input()
  deliveredLogsCount: number;

  @Input()
  failedLogsCount: number;

  @Input()
  pendingLogsCount: number;

  @Input()
  logsCount: number;

  statusStates = [];

  constructor() { }

  ngOnInit() {
    this.statusStates = [
      {
        label: 'Success',
        count: this.deliveredLogsCount,
        statusColor: 'success'
      },
      {
        label: 'Failure',
        count: this.failedLogsCount,
        statusColor: 'failure'
      },
      {
        label: 'Pending',
        count: this.pendingLogsCount,
        statusColor: 'pending'
      }
    ];
  }

}
