import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { FPOServices, KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { KalgudiProfileService, KalgudiProfileStateService } from '../../../services';
import { ServicesDialogComponent } from '../components/service/services-dialog/services-dialog.component';
import { ServicesMobileDialogComponent } from '../components/service/services-mobile-dialog/services-mobile-dialog.component';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class KalgudiServices extends KalgudiDestroyable {

  editable: boolean;

  profileDetails: KalgudiUser;
  profileServiceList: FPOServices[];

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;
  private profileState: KalgudiProfileStateService;

  constructor(
    protected injector: Injector
  ) {
    super();

    this.dialogService     = this.injector.get(KalgudiDialogsService);
    this.profileService    = this.injector.get(KalgudiProfileService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);


    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
        this.profileServiceList = this.profileDetails.fpoAdditionalDetails  &&   this.profileDetails.fpoAdditionalDetails.fpoServices  ?  this.profileDetails.fpoAdditionalDetails.fpoServices : [];
      });

  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows service dialog
   */
  showServiceDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Services',
      data: {

      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'services',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {

      }
    };

    return this.openServicesDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open services dialog
   */
  private openServicesDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(ServicesMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(ServicesDialogComponent, dialogConfig, matDialogConfig);

    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
