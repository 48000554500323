<mat-card class="new-farmer-certificates">

  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="10px">
      <div>
        <svg-icon src="assets/svgs/farmer-certificates.svg" [svgStyle]="{ 'width.px': 30, 'height.px': 30}"></svg-icon>
      </div>
      <div>
        <p class="title m-0" translate>Certificates</p>
      </div>
    </div>

    <div>
      <button class="add-button" mat-button (click)="showAddCertificateDialog()" *ngIf="editable">
        <p class="m-0" translate>Add</p>
      </button>
    </div>
  </div>

  <!-- List -->
  <div class="list mt-2">
    <div fxLayout="row" *ngIf="certificates?.length > 0">
      <div fxFlex="40%">
        <p class="label m-0 mb-n1" translate>Name</p>
      </div>
      <div fxFlex="40%">
        <p class="label ml-1 mb-n1" translate>Issued from</p>
      </div>
      <div fxFlex="20%"></div>
    </div>

    <div *ngFor="let certificate of certificates; let last = last">
      <div fxLayout="row" class="py-1" [ngClass]="{'mb-n2': last}">
        <div fxFlex="40%">
          <span class="value text-truncate m-0">{{certificate?.certName}}</span>
        </div>
        <div fxFlex="40%">
          <span class="value text-truncate m-0">{{certificate?.certAuthority}}</span>
        </div>
        <div fxFlex="20%" fxLayout="row" fxLayoutAlign="end" *ngIf="editable">
          <button mat-icon-button class="action-buttons" (click)="deleteCertificate(certificate?.businessCertificationId)">
            <mat-icon class="delete">delete_outline</mat-icon>
          </button>
        </div>
      </div>
      <hr *ngIf="!last" class="m-0">
    </div>
  </div>
  <!-- /List -->

  <!-- No content -->
  <div *ngIf="!certificates?.length">
    <kl-no-content svgIcon="assets/svgs/patent.svg"
      [text]="'No certificate added yet!' | translate"
      iconSize="md"
      [actionLink]="editable ? 'Add Certificate' : ''"
      (action)="showAddCertificateDialog()">
    </kl-no-content>
  </div>
  <!-- / No content -->

</mat-card>
