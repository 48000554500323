<div id="kalgudi-training-form" class="p-0" *ngIf="shareForm">
  <form autocomplete="off" [formGroup]="shareForm">
    <div class="pb-1 px-3 pt-3 program-share-form">

      <!-- Form field -->
      <div class="text-area-wrapper">
        <mat-form-field appearance="outline" class="w-100 mb-2">
          <textarea matInput class="input-field" [placeholder]="'Enter training details here!' | translate" [rows]="rowsCount" formControlName="shareText"></textarea>
        </mat-form-field>
      </div>
      <!--/ Form field -->

      <!-- Share filters -->
      <div class="mb-2">
        <kl-page-activity-filters
          [pageId]="pageId"
          [shareForm]="shareForm"
          [tagType]="availablePageTypes?.TRAINING"
          (shareWith)="shareWithChanged($event)">
        </kl-page-activity-filters>
      </div>
      <!-- / Share filters -->

      <!-- Gender -->
      <div class="my-2" fxLayout fxLayoutAlign="start center" [formGroup]="shareFormFilter" *ngIf="shareWithValue !== 'SPECIFIC_KALGUDI_USER'">
        <div class="mt-n1">
          <span class="mr-2 gender-text" translate>Gender: </span>
        </div>

        <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
          <mat-radio-button value="" class="mr-4">{{ "All" | translate }}</mat-radio-button>
          <mat-radio-button value="MALE" class="mr-4">{{ "Male" | translate }}</mat-radio-button>
          <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--/ Gender -->

      <!-- Training & total-attendance -->
      <div class="mt-n1 mb-1" fxLayoutGap="10px" fxLayoutGap.xs="0px"
        fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutAlign="start center">

        <div id="trainingAttendance" class="w-100" fxLayout="row" fxLayout.lt-lg="column">
          <!-- Training date -->
          <div [ngClass]="isAttendanceHour ? 'mr-4' : 'mr-0'" ngClass.lt-lg="mr-0" fxFlex="calc(47% - 10px)" fxLayout="column" fxLayoutAlign="start">
            <mat-form-field color="primary" class="mat-inputs" floatLabel="never" appearance="outline">
              <mat-label translate>Training Date</mat-label>
              <input matInput [max]="maxDate" [matDatepicker]="trainingDate" readonly (click)="trainingDate.open()" formControlName="fromDate" required>
              <mat-datepicker-toggle matSuffix [for]="trainingDate"></mat-datepicker-toggle>
              <mat-datepicker #trainingDate></mat-datepicker>
              <mat-error *ngIf="shareFormFromDate?.hasError('required')" translate>
                Training date is required
              </mat-error>
            </mat-form-field>
          </div>
          <!--/ Training date -->

          <!-- Total attendance -->
          <div *ngIf="isAttendanceHour" class="attendence" fxFlex="calc(53% - 10px)" fxLayout="" fxLayoutAlign="flex-start center">
            <!-- <span class="mb-2">Total attendance: </span> -->
            <mat-form-field class="attendence-input mat-inputs w-100" appearance="outline">
              <mat-label translate>Total Attendees</mat-label>
              <input matInput type="number" min="1" max="100000" formControlName="totalAttendance" (keypress)="checkIfInteger($event)" [required]="isAttendanceHour">
              <mat-error *ngIf="shareFormTotalAttendance?.hasError('required')" translate>
                Total Attendees is required
              </mat-error>
              <mat-error *ngIf="shareFormTotalAttendance?.hasError('max')" translate>
                Max limit is 1,00,000 members
              </mat-error>
              <mat-error *ngIf="shareFormTotalAttendance?.hasError('min')" translate>
                Enter a positive number!
              </mat-error>
            </mat-form-field>
          </div>
          <!--/ Total attendance -->
        </div>
      </div>
      <!--/ Training & total-attendance -->

      <!-- Training hours and schedule (date & time) -->
      <div class="hours-schedule mb-n1" fxLayout fxLayout.lt-lg="column" fxLayoutGap.lt-lg="8px">
        <!-- Training hours & time -->
        <div *ngIf="isAttendanceHour" fxLayout="column" fxLayoutAlign="flex-start" class="hour-minutes" ngClass="mr-4" ngClass.lt-lg="mr-0" fxFlex="calc(47% - 10px)">
          <div>
            <span class="number-of-hour" translate>Training duration</span>
          </div>
          <div fxLayout fxFlex="100%">
            <div class="attendence mr-2" fxFlex="calc(50% - 10px)">
              <mat-form-field class="attendence-input mat-inputs w-100" appearance="outline">
                <mat-label translate>Hours</mat-label>
                <input matInput type="number" min="0" max="999" formControlName="trainingHours" (keypress)="checkIfInteger($event)" [required]="isAttendanceHour">
                <mat-error *ngIf="shareFormTrainingHours?.hasError('required')" translate>
                  Hour is required
                </mat-error>
                <mat-error *ngIf="shareFormTrainingHours?.hasError('max')" translate>
                  Max limit is <strong>999</strong>
                </mat-error>
                <mat-error *ngIf="shareFormTrainingHours?.hasError('min')" translate>
                  Enter a positive number!
                </mat-error>
              </mat-form-field>
            </div>
            <div class="attendence" fxFlex="calc(50% - 10px)">
              <mat-form-field class="attendence-input mat-inputs w-100" appearance="outline">
                <mat-label translate>Minutes</mat-label>
                <input matInput pattern="[0-9]*" max="60" type="number" formControlName="trainingMinutes" (keypress)="checkIfInteger($event)">
                <mat-error *ngIf="shareFormTrainingMinutes?.hasError('max')" translate>
                  Max limit is <strong>60</strong>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--/ Training hours & time -->

        <!-- Schedule date and time -->
        <div fxLayout="column" [fxLayoutAlign]="showScheduleTime ? 'flex-start' : 'center'" fxFlex="calc(53% - 10px)">
          <!-- <div>
            <span fxLayout fxLayoutAlign="space-between" class="schedule" translate *ngIf="showScheduleTime">
              Schedule date & time
              <a (click)="toggleSchedule()" translate>Close schedule</a>
            </span>
            <span class="schedule mt-2" translate *ngIf="!showScheduleTime">
              Do you want to schedule the post?
              <a (click)="toggleSchedule()" translate>Click here</a>
            </span>
          </div>

          <div *ngIf="showScheduleTime" fxLayout fxLayoutGap="5px">
            <div class="mr-2" fxFlex="calc(50% - 10px)" fxLayout="column" fxLayoutAlign="start">
              <mat-form-field color="primary" class="mat-inputs" floatLabel="never" appearance="outline">
                <mat-label translate>Date</mat-label>
                <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" [min]="minDate" formControlName="scheduledDate">
                <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>
                <mat-datepicker #scheduledDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div [fxFlex]="scheduledDateField?.value ? 'calc(35% - 10px)' : 'calc(48% - 10px)'">
              <kl-hour-selectable-form-control
                [selectedDate]="scheduledDateField?.value"
                fieldAppearance="outline"
                [disabled]="!scheduledDateField?.value"
                [placeholder]="'Time' | translate"
                formControlName="scheduledTime">
              </kl-hour-selectable-form-control>
            </div>
            <div *ngIf="scheduledDateField?.value" fxFlex="calc(15% - 10px)" class="clear-container">
              <span class="clear-btn cursor-pointer text-danger" (click)="clearDate()" translate>Clear</span>
            </div>
          </div> -->
        </div>
        <!--/ Schedule date and time -->
      </div>
      <!--/ Training attendance and hours -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->

      <!-- URL attachment -->
      <div *ngIf="urlFormControl?.value?.url" class="mb-3">
        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">
        </kl-post-tile-link-attachment>
      </div>
      <!--/ URL attachment -->

      <!-- Attachments-button & save-button -->
      <div ngClass.lt-lg="mt-3" fxLayout fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="flex-start start" fxLayout.lt-sm="column">
        <button mat-button
          class="attach-files p-0"
          id="attach-files-button"
          color="primary"
          [matMenuTriggerFor]="menu">
          <mat-icon>attachment</mat-icon>
          <span class="ml-1" translate>Attach</span>
        </button>

        <mat-menu #menu="matMenu" class="attach-file">
          <ng-container>
            <div mat-menu-item class="attach-file-mat-menu" [matTooltip]="'Capture image' | translate">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>Image</p>
                </div>

                <div fxLayout="row">
                  <div>
                    <kl-camera-form-control
                      fxFlex="95%"
                      [formControl]="cameraAttachment"
                      [showChipButton]="true"
                      [showIconButton]="true"
                      [showBorder]="false"
                      buttonIcon="camera_alt"
                      buttonLabel="Camera"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-camera-form-control>
                  </div>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-image-picker-form-control>
                  </div>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>Voice</p>
                </div>

                <div fxLayout="row">
                  <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                    class="mt-1" [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                    [matTooltip]="'Attach audio' | translate"
                    (click)="showAudioDialog()"
                    mat-icon-button>
                    <div class="label-icon" fxLayout fxLayoutAlign="center center">
                      <mat-icon class="m-0 camera-icon">mic</mat-icon>
                    </div>
                  </button>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedAudioFileTypes">
                    </kl-image-picker-form-control>
                  </div>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>Video</p>
                </div>

                <div fxLayout="row">
                  <div class="mt-1">
                    <kl-attach-url-form-control
                      fxFlex="100%"
                      [showBorder]="false"
                      [showChipButton]="false"
                      svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                      [formControl]="urlFormControl"
                      buttonType="mat-icon-button">
                    </kl-attach-url-form-control>
                  </div>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedVideoFileTypes">
                    </kl-image-picker-form-control>
                  </div>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu-file">
                <div>
                  <p class="m-0 title" translate>File</p>
                </div>

                <div fxLayout="row">
                  <kl-image-picker-form-control
                    formControlName="lstOfAttachments"
                    [showBorder]="false"
                    [buttonType]="'mat-icon-button'"
                    svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-menu>

        <!-- Submit and update button -->
        <div [ngClass]="{'send-btn-container': !progress}" class="share-button w-100" fxLayout fxLayoutAlign="flex-end center" fxLayoutGap="15px">
          <div [ngClass]="{'w-100 mt-2': isMobileDevice}">
            <kl-flat-button
              class="submit-btn"
              [label]="postDetails ? 'Update' : 'Submit'"
              [disabled]="(!shareFormText.value && !audioAttachment?.value?.url) || shareForm?.invalid"
              [progress]="progress"
              (clicked)="createPost()">
            </kl-flat-button>
          </div>
        </div>
        <!--/ Submit and update button -->
      </div>
      <!-- Attachments-button & save-button -->

    </div>
  </form>
</div>
