import { Component, Inject, Injector, OnInit } from '@angular/core';
import { floatTopAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveyManageAdmin } from '../../classes/kalgudi-survey-manage-admin';

@Component({
  selector: 'kl-survey-admins-stream',
  templateUrl: './survey-admins-stream.component.html',
  styleUrls: ['./survey-admins-stream.component.scss'],
  animations: [ floatTopAnimation ]
})
export class SurveyAdminsStreamComponent extends KalgudiSurveyManageAdmin implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}
}
