import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ProjectStatistics } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProjectsDashboardApiService {

  /**
   * `v2/projects/dashboards`
   */
  private readonly PROJECTS_DASHBOARD = `${this.env.restBaseUrlV2}/projects/dashboards`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Gets all projects stats
   */
  getAllProjectsStats(): Observable<ProjectStatistics> {

    return this.httpClient.get<ApiResponseCommon>(this.PROJECTS_DASHBOARD)
      .pipe(

      // Api response error handler
      map(res => this.util.apiErrorHandler(res)),

      // No errors, all good return true
      map(res => res.data),
    );
  }
}
