import { LatLong } from "./core.model";
import { ApiResponseCommon } from "./rest-api.model";
import { AuthorDetails, TargetedMembersObject } from "./sms.model";
import {
  Attachment,
  LatestActivity,
  ProgramShareFilter,
  ProgramShareRequest,
  SocialProductTo,
} from "./social.model";
import { KalgudiUserBasicDetails } from "./user.model";

export enum KalgudiRobocallStates {
  ALL = "ALL",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  PENDING = "PENDING",
}

export interface RobocallLog {
  mobileNo: number;
  firstName: string;
  profileKey: string;
  status: boolean;
  deliveryStatus: string;
  totalFragments?: number;
  usdCost?: number;
  isAdminSMS?: boolean;
  messageId?: string;
  entityId?: string;
  dlrStatus?: string;
  isDelivered?: boolean;
  smsId?: string;
  entityName?: string;
  smsType?: string;
  time?: string;
}

export interface RobocallRequestPayload extends ProgramShareRequest {
  authorId: string;
  shareText: string;
  richShareText?: string;
  imageUri?: string;
  uri?: string;
  uriTitle?: string;
  uriImage?: string;
  domain?: string;
  lstOfAttachments: Attachment[];
  isSMSToAltNumber: boolean;
  scheduledDate?: string;
  scheduledTime?: string;
  scheduledTS?: string;
  entityId: string;
  entityName: string;
  isAdmin: boolean;
  isAuthor: boolean;
  msgTypes: string[];
  filter: ProgramRobocallFilter;
  isPublishedThroughTool: boolean;
  productsMetaData?: SocialProductTo;
  geoLocation?: LatLong;
}

export interface ProgramRobocallFilter extends ProgramShareFilter {
  isRobocall: boolean;
  gender?: string;
  users?: string[];
  states?: string[];
  districts?: string[];
  countries?: string[];
}

export interface PublishRobocallResponse extends ApiResponseCommon {
  data: KalgudiRobocall;
}

export interface KalgudiRobocall {
  targetedMembersObject: TargetedMembersObject;
  smsText: string;
  msgTypes: string[];
  recipient: string;
  sharedTo: string;
  smsId: string;
  smsCount: number;
  pageId: string;
  author: AuthorDetails;
  CTS: string;
  UTS: string;
  authorId?: string;
  members?: string[];
  byteCount: number;
  totalCredit: number;
  scheduledTS?: string;
  scheduledDate?: string;
  scheduledTime?: string;
  scheduleId?: string;
  shareId?: string;
  latestActivity?: LatestActivity;
  entityId?: string;
  shareText?: string;
  CT?: string;
  pageAuthorDetails?: KalgudiUserBasicDetails;
  filter?: any;
}

export const ROBOCALL_LOGS_STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

export const ROBOCALL_TYPE_FILTERS = {
  LATEST_ROBOCALL_UPDATES: "LATEST_ROBOCALL_UPDATES",
  SCHEDULED_ROBOCALL_UPDATES: "SCHEDULED_ROBOCALL_UPDATES",
};

export interface RobocallStatusDownloadResponse {
  report_url?: string;
  message?: string;
}
