import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableSearchStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiPageDetails } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

import { KalgudiMembersService } from './../services/kalgudi-members.service';

@Directive()
export abstract class MembersTableList extends KalgudiMatTableSearchStream<KalgudiPageDetails> {

  @Input()
  projectId: string;

  offset: number;

  limit: number;

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  private membersService: KalgudiMembersService;
  private notifications: KalgudiNotification;

  constructor(protected injector: Injector) {

    super();

    this.membersService = this.injector.get<KalgudiMembersService>(KalgudiMembersService);
    this.notifications = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    // Override the page size options if you don't want default page size
    this.pageSizeOptions = [1, 5, 10];

    // Override the list of columns to show
    this.displayedColumns = [
      'memberName',
      'projects',
      'inProgress',
      'completed',
      'performance',
      'settings',
    ];
  }

  /**
   * Gets members list
   */
  getMembers() {
    this.membersService.getMembers(this.projectId, this.offset, this.limit)
    .subscribe(err => this.notifications.showMessage('Unable to fetch project members list'));
  }

  // Define the streamApi method to specify the data source of the table
  protected streamApi(offset: number, limit: number, sortBy?: string, sortDirection?: string): Observable<KalgudiStreamData> {
    return of({
      items: [
        {
          memberName: 'Pankaj',
          projects: '27',
          inProgress: '40',
          completed: '900',
          performance: 'Active',
          icon: 'more_horiz',
        },
        {
          memberName: 'Ashwini',
          projects: '31',
          inProgress: '4',
          completed: '500',
          performance: 'Active',
          icon: 'more_horiz',
        },
        {
          memberName: 'Ramya',
          inProgress: '2',
          completed: '354',
          projects: '9',
          performance: 'Inactive',
          icon: 'more_horiz',
        },
        {
          memberName: 'Pushpalatha',
          inProgress: '89',
          completed: '7',
          projects: '4',
          performance: 'Active',
          icon: 'more_horiz',
        },
        {
          memberName: 'Sri ramya',
          inProgress: '90',
          completed: '4',
          projects: '100',
          performance: 'Inactive',
          icon: 'more_horiz',
        },
      ].slice(offset, limit)
    });
  }

  protected onDestroyed(): void { }

}
