import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProfileRouteConfig } from '../../../../config';
import { KalgudiCropFarmingActivity } from '../../classes/kalgudi-crop-farming-activity';

@Component({
  selector: 'kl-crop-farming-activity',
  templateUrl: './crop-farming-activity.component.html',
  styleUrls: ['./crop-farming-activity.component.scss']
})
export class CropFarmingActivityComponent extends KalgudiCropFarmingActivity implements OnInit {

  @Input()
  cropId: string

  @Input()
  isAssisted: boolean;

  @Input()
  pageId: string;

  @Input()
  profileKey: string;

  @Input()
  cropTitle: string;

  @Input()
  farmingFarmType: string;

  @Input()
  hideTileTime = 10000;

  selected: string;

  showInfoCard = true;

  isMobileDevice: boolean;

  prevScrollPos = 0;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig) {

    super(notification, util,injector);

    this.isMobileDevice = checkMobileDevice();
  }

  ngOnInit() {
    this.selected = 'all';

    // this.initStream();

    // Hiding the info card after 10 secs
    setTimeout(() => {
      this.showInfoCard = false;
    }, this.hideTileTime);

    this.getSeasons();

    // if (checkMobileDevice()) {
    //   window.addEventListener('scroll', this.scroll, true);
    // }
  }

  onDestroyed(): void {}

  // scroll = (event): void => {

  //   const currentScrollPos = event.srcElement.scrollTop;

  //   if (this.prevScrollPos < currentScrollPos) {
  //     document.getElementById('farm-activity-scroll').style.margin = '-12px';
  //     document.getElementById('farm-activity-scroll').style.padding = '60px 0px 8px 0px';
  //   } else {
  //     document.getElementById('farm-activity-scroll').style.margin = '0px';
  //     document.getElementById('farm-activity-scroll').style.padding = '18px 0px 8px 0px';
  //   }

  //   this.prevScrollPos = currentScrollPos;

  // }

  /**
   * Opens crop settings page
   */
  openCropSettings() {

    const pageId =this.pageId;
    const isAssisted = this.isAssisted;

    this.appRouting.toCropSettings({'profileKey': this.profileKey, 'cropId': this.cropId, 'cropTitle': this.cropTitle}, {'cropPageId': this.cropPageId, pageId, isAssisted, 'farmingFarmType': this.farmingFarmType})

  }
}
