import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio';
import {
  CommonSearchBarModule,
  KalgudiAttachUrlModule,
  KalgudiCommonModule,
  KalgudiConnectsPickerModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiProfilesModule } from '@kalgudi/profiles';
import { KalgudiQaModule } from '@kalgudi/qa';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiSocialModule } from '@kalgudi/social';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  KalgudiProgramMembersPickerDialogModule,
} from '../program-members-picker-dialog/program-members-picker-dialog.module';
import { AssistActionBarComponent } from './components/assist-action-bar/assist-action-bar.component';
import { AssistMembersPickerComponent } from './components/assist-members-picker/assist-members-picker.component';
import { AssistQaTileComponent } from './components/assist-qa-tile/assist-qa-tile.component';
import { AssistShareUpdateTileComponent } from './components/assist-share-update-tile/assist-share-update-tile.component';
import { AssistanceStreamComponent } from './components/assistance-stream/assistance-stream.component';
import { DigitalAssistFullViewComponent } from './components/digital-assist-full-view/digital-assist-full-view.component';
import {
  DigitalAssistProfileLogsMobileDialogComponent,
} from './components/digital-assist-profile-logs-mobile-dialog/digital-assist-profile-logs-mobile-dialog.component';
import {
  DigitalAssistProfileLogsComponent,
} from './components/digital-assist-profile-logs/digital-assist-profile-logs.component';
import {
  DigitalAssistProfileUpdateMobileDialogComponent,
} from './components/digital-assist-profile-update-mobile-dialog/digital-assist-profile-update-mobile-dialog.component';
import {
  DigitalAssistProfileUpdateComponent,
} from './components/digital-assist-profile-update/digital-assist-profile-update.component';
import { DigitalAssistQaComponent } from './components/digital-assist-qa/digital-assist-qa.component';
import {
  DigitalAssistShareUpdateComponent,
} from './components/digital-assist-share-update/digital-assist-share-update.component';
import {
  DigitalAssistTasksMobileDialogComponent,
} from './components/digital-assist-tasks-mobile-dialog/digital-assist-tasks-mobile-dialog.component';
import { DigitalAssistTasksComponent } from './components/digital-assist-tasks/digital-assist-tasks.component';
import { DigitalAssistanceStatsComponent } from './components/digital-assistance-stats/digital-assistance-stats.component';
import { EnableOfflineDaComponent } from './components/enable-offline-da/enable-offline-da.component';
import { EnableOfflineDialogComponent } from './components/enable-offline-dialog/enable-offline-dialog.component';
import {
  EnableOfflineMobileDialogComponent,
} from './components/enable-offline-mobile-dialog/enable-offline-mobile-dialog.component';
import {
  FrequentAssistedMembersComponent,
} from './components/frequent-assisted-members/frequent-assisted-members.component';
import {
  FrequentMembersPickerMobileDialogComponent,
} from './components/frequent-members-picker-mobile-dialog/frequent-members-picker-mobile-dialog.component';
import {
  PageFrequentMembersPickerComponent,
} from './components/page-frequent-members-picker/page-frequent-members-picker.component';
import { PageMembersSearchComponent } from './components/page-members-search/page-members-search.component';
import {
  PagesFrequentMembersPickersDialogComponent,
} from './components/pages-frequent-members-pickers-dialog/pages-frequent-members-pickers-dialog.component';
import { DigitalAssistanceApiService } from './services/digital-assistance-api.service';
import { DigitalAssistanceDialogService } from './services/digital-assistance-dialog.service';
import { DigitalAssistanceStateService } from './services/digital-assistance-state.service';
import { DigitalAssistanceService } from './services/digital-assistance.service';
import { DigitalAssistTrainingComponent } from './components/digital-assist-training/digital-assist-training.component';
import { PageActivityFiltersModule } from "../page-activity-filters/page-activity-filters.module";

@NgModule({
  declarations: [
    DigitalAssistanceStatsComponent,
    DigitalAssistFullViewComponent,
    DigitalAssistShareUpdateComponent,
    DigitalAssistQaComponent,
    AssistMembersPickerComponent,
    AssistActionBarComponent,
    AssistShareUpdateTileComponent,
    AssistQaTileComponent,
    AssistanceStreamComponent,


    PagesFrequentMembersPickersDialogComponent,
    FrequentMembersPickerMobileDialogComponent,
    PageFrequentMembersPickerComponent,
    DigitalAssistProfileUpdateComponent,
    EnableOfflineDialogComponent,
    EnableOfflineMobileDialogComponent,
    EnableOfflineDaComponent,
    PageMembersSearchComponent,
    FrequentAssistedMembersComponent,
    DigitalAssistProfileUpdateMobileDialogComponent,
    DigitalAssistProfileLogsComponent,
    DigitalAssistProfileLogsMobileDialogComponent,
    DigitalAssistTasksComponent,
    DigitalAssistTasksMobileDialogComponent,
    DigitalAssistTrainingComponent
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material modules
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatDividerModule,
    MomentDateModule,
    MatRadioModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiProfilesModule,
    KalgudiQaModule,
    KalgudiConnectsPickerModule,
    KalgudiShareUpdateModule,
    KalgudiUsersModule,
    KalgudiCommonModule,
    KalgudiSocialModule,
    KalgudiButtonModule,
    KalgudiQaModule,
    KalgudiPipesModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiImageChipListModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiImagePickerModule,
    CommonSearchBarModule,
    KalgudiAttachUrlModule,
    KalgudiTimePickerModule,
    KalgudiStoreProductsSearchModule,
    PageActivityFiltersModule,

    TranslateModule
  ],
  exports: [
    DigitalAssistanceStatsComponent,
    DigitalAssistFullViewComponent,
    DigitalAssistShareUpdateComponent,
    DigitalAssistQaComponent,
    AssistMembersPickerComponent,
    AssistActionBarComponent,
    AssistShareUpdateTileComponent,
    AssistQaTileComponent,
    AssistanceStreamComponent,
    PageFrequentMembersPickerComponent,
    DigitalAssistProfileUpdateComponent,
    DigitalAssistTrainingComponent
  ],
  entryComponents: [
    DigitalAssistShareUpdateComponent,
    DigitalAssistQaComponent,
    DigitalAssistProfileUpdateMobileDialogComponent,

    PagesFrequentMembersPickersDialogComponent,
    FrequentMembersPickerMobileDialogComponent,

    EnableOfflineDialogComponent,
    EnableOfflineMobileDialogComponent,

    DigitalAssistProfileLogsMobileDialogComponent,
    DigitalAssistTasksMobileDialogComponent,
    DigitalAssistTrainingComponent
  ],
  providers: [
    DigitalAssistanceService,
    DigitalAssistanceApiService,
    DigitalAssistanceDialogService,
    DigitalAssistanceStateService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]

})
export class DigitalAssistanceModule { }
