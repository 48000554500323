<div class="weather-fullview" *ngIf="weatherDetails && weatherDetails[0]?.hourly">

  <div class="weather py-4">
    <div class="container">
      <kl-weather-details
        [location]="location"
        [todaysWeather]="todaysWeather"
        [weatherDetails]="weatherDetails"
        [weatherUpdates]="weatherUpdates"></kl-weather-details>
    </div>
  </div>

  <div class="search-tile container">
    <div class="mt-3 location-search">
      <form autocomplete="off" [formGroup]="locationForm">
        <kl-google-places-autocomplete
          formControlName="locationDetails"
          placeholder = "Search weather for other locations"></kl-google-places-autocomplete>
      </form>

    </div>

    <!-- Favorite locations -->
    <section class="favorite-locations" *ngIf="favoriteLocations">

      <mat-chip-list>
        <ng-container *ngFor="let location of favoriteLocations; let last = last;">
          <mat-chip [ngClass]="{'recent-added-location': last}" (click)="getFavoriteLocationDetails(location)">
            <a class="location-title" *ngIf="location?.placeName">{{ location?.placeName }}, {{ location?.locationShort }}</a>
            <a class="location-title" *ngIf="!location?.placeName">{{ location?.locationLong }}</a>
          </mat-chip>
        </ng-container>
      </mat-chip-list>

    </section>
    <!-- /Favorite locations -->

    <mat-card class="mt-3 mb-5" id="searched-location" *ngIf="searchTodaysWeather">
      <kl-weather-details
        [location]="searchLocation"
        [todaysWeather]="searchTodaysWeather"
        [weatherDetails]="searchWeatherDetails"
        [weatherUpdates]="weatherUpdatesByLocation"
        showFavorite="true"
        [isFavoriteLocation]="isFavoriteLocation"
        (toFavoriteLocations)="addToFavoriteLocation($event)">
      </kl-weather-details>
    </mat-card>

    <div *ngIf="!searchTodaysWeather" class="mt-3 mb-5">
      <kl-no-content
        svgIcon="assets/icons/weather/cloud.svg"
        text="Search weather for other locations">
      </kl-no-content>
    </div>
  </div>



</div>

<div *ngIf="!weatherDetails && !weatherDetails[0]?.hourly" class="mt-3 mb-5">
  <kl-no-content
    svgIcon="assets/icons/weather/cloud.svg"
    text="Weather details not available">
  </kl-no-content>
</div>
