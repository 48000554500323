import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import { KalgudiPageAnalytics } from '../../classes/page-analytics';

@Component({
  selector: 'kl-page-analytics',
  templateUrl: './page-analytics.component.html',
  styleUrls: ['./page-analytics.component.scss']
})
export class PageAnalyticsComponent extends KalgudiPageAnalytics implements OnInit {

  @Output()
  pageUpdates = new EventEmitter();

  @Output()
  pageQa = new EventEmitter();

  @Output()
  pageProjects = new EventEmitter();

  @Output()
  pageTasks = new EventEmitter();

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initStream();
  }

  /**
   * Opens page updates
   */
  openPageUpdates() {
    this.pageUpdates.emit();
  }

  /**
   * Opens page qa
   */
  openPageQa() {
    this.pageQa.emit();
  }

  /**
   * Opens page projects
   */
  openPageProjects() {
    this.pageProjects.emit();
  }

  /**
   * Opens page tasks
   */
  openPageTasks() {
    this.pageTasks.emit();
  }
}

