<ng-container *ngIf="postDetails && (postDetails?.shareId || postDetails?.scheduleId)">
  <div [id]="postDetails?.shareId" class="mt-2 share-update-tile"
    [@kalgudiStreamTile]="tileAnimationState"
    [ngClass]="{'deleted': tileAnimationState === 'deleted'}">
    <mat-card class="promotional-share-tile cursor-pointer" [ngClass]="{'cursor-pointer': !fullView}" (click)="toFullview(postDetails?.shareId)">
      <!-- Promotional share header -->
      <div>
        <kl-promotional-share-header
          [promotionalTitle]="postDetails?.promotionalTitle"
          [latestActivity]="postDetails?.latestActivity"
          [promotionalTime]="postDetails?.CT"
          [promotionalLocation]="postDetails?.authorLocation"
          [pageDetails]="postDetails?.pageDetails"
          [authorId]="postDetails?.authorId"
          [userProfileKey]="(basicUserDetails$ | async)?.profileKey"
          (report)="report(postDetails?.shareText, postDetails?.shareId)"
          (deletePost)="deletePost()"
          [scheduledUpdates]="scheduledUpdates"
          [memberRole]="memberRole">
        </kl-promotional-share-header>
      </div>
      <!--/ Promotional share header -->

      <!-- Promotional share crops -->
      <div>
        <kl-promotional-share-crops [cropsList]="postDetails?.productsMetaData ? postDetails?.productsMetaData[0]?.crops : []"></kl-promotional-share-crops>
      </div>
      <!--/ Promotional share crops -->

      <!-- Promotional share attachments -->
      <div>
        <kl-post-tile-attachments [isFullviewPage]="fullView" [shareUpdate]="postDetails"></kl-post-tile-attachments>
      </div>
      <!--/ Promotional share attachments -->

      <!-- Promotional share products -->
      <div *ngIf="postDetails?.productsMetaData && postDetails?.promotionalTitle">
        <kl-promotional-share-products [productList]="postDetails?.productsMetaData"></kl-promotional-share-products>
      </div>
      <!--/ Promotional share products -->

      <!-- Promotional multiple share products -->
      <div *ngIf="postDetails?.productsMetaData && !postDetails?.promotionalTitle">
        <kl-promotional-share-multiple-products [productList]="postDetails?.productsMetaData" [store]="postDetails?.pageDetails?.pageId === '0066' ? farmStoreUrl : null"></kl-promotional-share-multiple-products>
      </div>
      <!-- Promotional multiple share products -->

      <!-- Count of Likes and Comments -->
      <!-- <div class="count pt-1 text-muted" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="5px"> -->

        <!-- Likes count -->
        <!-- <button *ngIf="postDetails?.likesCount" fxLayoutAlign="start center" title="Likes list" mat-icon-button class="action-buttons like-button counts rounded-0" (click)="openLikesListDialog();$event.stopPropagation()">
          <div fxLayout="row">
            <svg-icon class="svg-icon mr-1" src="assets/svgs/fill-like.svg" [svgStyle]="{ 'width.px': 12, 'height.px': 12}" fxLayoutAlign="start center">
            </svg-icon>
            <p class="mb-0 action-title likes-count">{{ postDetails?.likesCount | modernNumber }}</p>
          </div>
        </button> -->
        <!-- / Likes count -->

        <!-- Comments count -->
        <!-- <div class="counts comment-count w-100" *ngIf="postDetails?.commentsCount" fxLayout fxLayoutGap="3px" fxLayoutAlign="end center">
          <span *ngIf="postDetails?.commentsCount">{{ postDetails?.commentsCount }}</span>
          <span [ngPlural]="postDetails?.commentsCount">
            <ng-template ngPluralCase="=1"><span translate>Comment</span></ng-template>
            <ng-template ngPluralCase="other"><span translate>Comments</span></ng-template>
          </span>
        </div> -->
        <!-- / Comments count -->

      <!-- </div> -->
      <!-- Count of Likes and Comments -->


      <!-- Promotional share tile action bar -->
      <div>
        <kl-share-update-action-bar
          (commented)="toggleCommentList()"
          [shareId]="postDetails?.shareId"
          [likes]="postDetails?.likes"
          [liked]="postDetails?.liked"
          [likesCount]="postDetails?.likesCount"
          [commentsCount]="postDetails?.commentsCount"
          [url]="url"
          (like)="like()"
          (fullView)="toFullview(postDetails?.shareId)">
        </kl-share-update-action-bar>
      </div>
      <!--/ Promotional share tile action bar -->

      <!-- Comment section -->
      <div class="mx-xl-3" *ngIf="showCommentForm || fullView">
        <kl-social-comments
          [commentForm]="commentsForm"
          [progress]="actionProgress"
          [comments]="postDetails?.recentComments "
          (commented)="comment()"
          [kuberPage]="kuberPageId">
        </kl-social-comments>
      </div>
      <!-- /Comment form -->
    </mat-card>
  </div>
</ng-container>
