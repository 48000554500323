import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EventBubblingBlockerDirective } from './event-bubbling-blocker.directive';
import { HideContentDirective } from './hide-content.directive';

@NgModule({
  declarations: [
    HideContentDirective,
    EventBubblingBlockerDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HideContentDirective,
    EventBubblingBlockerDirective,
  ]
})
export class KalgudiDirectivesModule { }
