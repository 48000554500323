<mat-card class="my-2 p-0" [@floatTop]="{value: '', params: {delay: animationDelay}}">
  <kl-network-header [networksHeader]="networksHeader"></kl-network-header>

  <kl-profile-visited-tile *ngIf="!isConnected"
    [notification]="notification">
  </kl-profile-visited-tile>

  <kl-connected-tile *ngIf="isConnected"
    [notification]="notification">
  </kl-connected-tile>
</mat-card>
