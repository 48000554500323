import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CoreNotificationsConfigService } from '@app/config';
import { KalgudiMobileDialogService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiQaService } from '@kalgudi/qa';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { KalgudiHomeStreamStateService } from '@kalgudi/social';
import { BottomSheetMenu, NotificationsList, UIMenu } from '@kalgudi/types';


@Component({
  selector: 'core-share-tab',
  templateUrl: './share-tab.component.html',
  styleUrls: ['./share-tab.component.scss'],
  animations: kalgudiAnimations,
})
export class ShareTabComponent implements OnInit {


  @Output()
  shareCreated = new EventEmitter<any>();

  @Output()
  qaCreated = new EventEmitter<any>();

  listMenu: BottomSheetMenu =
    {
      title: 'Share your ideas',
      styleType: 'list',
      menu: [
        {
          id: 'SHARE_UPDATE',
          title: 'Share an update',
          matIcon: 'format_quote',
          iconColor: '#8c6baa'
        },
        {
          id: 'QA',
          title: 'Ask a question',
          matIcon: 'help',
          iconColor: '#e58422'
        }
      ]
    };

  readonly TABS = {
    UPDATES:  { index: 0, icon: 'format_quote', title: 'Share an update' },
    QA:       { index: 1, icon: 'help', title: 'Ask a question' },
  };

  activeTabIndex: number;

  constructor(
    private homeStreamState: KalgudiHomeStreamStateService,
    private bottomSheetService: KalgudiBottomSheetService,
    private mobileDialog: KalgudiMobileDialogService,
    private shareUpdateService: KalgudiShareUpdateService,
    private qaService: KalgudiQaService,
    private notification: CoreNotificationsConfigService
  ) { }

  ngOnInit() {
    this.activeTabIndex = this.TABS.UPDATES.index;
  }

  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  onPostCreated(event: any): void {
    this.shareCreated.emit(event);
    if (event && !event.scheduledTS) {
      this.pushItemToHomeStream(event);
    } else {
      this.notification.showMessage(`Post scheduled`);
    }
  }

  onQaCreated(event: any): void {
    this.qaCreated.emit(event);
    this.pushItemToHomeStream(event);
  }

  private pushItemToHomeStream(val: NotificationsList): void {
    this.homeStreamState.unshiftToStream(val);
  }


  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'SHARE_UPDATE':
        this.openMobileShareUpdateForm();
        break;

      case 'QA':
        this.openMobileQaForm();
        break;

    }
  }

  /**
   * Opens Mobile share update form dialog
   */
  private openMobileShareUpdateForm(): void {
    this.shareUpdateService.openMobileShareUpdateForm();
  }

  /**
   * Opens Mobile qa form dialog
   */
  private openMobileQaForm(): void {
    this.qaService.openMobileQaForm();
  }

}
