import { Observable } from 'rxjs';

import { IDataStore } from '../../interfaces/data-store';

/**
 * Base class definition for managing data in the client local data store.
 *
 * @author Pankaj Prakash
 */
export class DataStore implements IDataStore {

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem(key: string): any;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string): T;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem(key: string, async?: boolean): Observable<any>;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string, async?: boolean): Observable<T>;

  /**
   * Returns the data stored at the specified key in the data store.
   *
   * @param key Key to get from the data store
   */
  getItem<T>(key: string, async?: boolean): Observable<T> | Observable<any> | T | any {
    throw new Error('Not implemented Error');
  }

  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any): boolean;

  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any, async?: boolean): Observable<boolean>;

  /**
   * Stores an item in the data store with the specified key and value.
   */
  setItem(key: string, value: any, async?: boolean): Observable<boolean> | boolean {
    throw new Error('Not implemented Error');
  }

  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string): boolean;

  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string, async?: boolean): Observable<boolean>;

  /**
   * Removes a key from the data store.
   *
   * @param key Key to remove
   */
  removeItem(key: string, async?: boolean): Observable<boolean> | boolean {
    throw new Error('Not implemented Error');
  }

  /**
   * Clears all data stored in the data store.
   */
  clear(): boolean;

  /**
   * Clears all data stored in the data store.
   */
  clear(async?: boolean): Observable<boolean>;

  /**
   * Clears all data stored in the data store.
   */
  clear(async?: boolean): Observable<boolean> | boolean {
    throw new Error('Not implemented Error');
  }

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): boolean;

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): Observable<boolean>;

  /**
   * Checks if an item with specified key exists in local data store or not.
   */
  hasItem(key: string, async?: boolean): Observable<boolean> | boolean {
    throw new Error('Not implemented Error');
  }
}
