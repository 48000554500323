<main id="business-activities-container">
  <!-- Form group for business activities -->
  <form [formGroup]="fpoProfilesForm" class="overflow-auto">
    <main class="dialoge-content basic-form-details overflow-auto p-3" fxLayout="column" formGroupName="basicDetailsInfo">

      <!-- Entity details -->
      <div fxLayout="column" formGroupName="entityDetails">
        <p class="mb-0 font-weight-bold headers pb-1" translate>Entity details</p>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div fxFlex="calc(50% - 10px)" [ngClass]="{ '': entityTypeField?.value !== '' , 'mr-2': entityTypeField?.value === '' }">
            <mat-form-field appearance="outline" [ngClass]="{ 'w-100': entityTypeField?.value !== '' , 'w-50': entityTypeField?.value === '' }">
              <mat-label translate>Entity type</mat-label>
              <mat-select formControlName="entityType" [placeholder]="'Business account type' | translate">
                <mat-option *ngFor="let entity of entityList" [value]="entity?.id">{{ entity?.value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 10px)" *ngIf="entityTypeField?.value === 'FPC'">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>CIN</mat-label>
              <input (keypress)="onKeyPress($event , 'cin')" maxlength="25" matInput
              [placeholder]="'Enter CIN' | translate" formControlName="companyCin">
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 10px)" *ngIf="entityTypeField?.value === 'TRUST'">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Trust registration number</mat-label>
              <input matInput [placeholder]="'Enter trust registration' | translate"
                formControlName="trustRegistrationNumber" maxlength="30" (keypress)="onKeyPress($event)">
            </mat-form-field>
          </div>

          <div fxFlex="calc(50% - 10px)" fxFlex.xs="100%" *ngIf="entityTypeField?.value === 'COOPERATIVE_SOCITY'">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Cooperative registration number</mat-label>
              <input matInput [placeholder]="'Enter cooperative registration number' | translate"
                formControlName="cooperativeRegistrationNumber" maxlength="30" (keypress)="onKeyPress($event)">
            </mat-form-field>
          </div>
        </div>
      </div>
      <!--/ Entity details -->

      <!-- Product and Commodities -->
      <div fxLayout="column" formGroupName="basicDetails">
        <div fxLayout="column">
          <p class="mb-0 font-weight-bold headers pb-1" translate>Basic details</p>

          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Entity contact number</mat-label>
                <input matInput [placeholder]="'Enter entity contact number' | translate" formControlName="contactNumber" pattern="^[0-9]+$" maxlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>
              <mat-error class="mt-n2 mb-2 error-message" *ngIf="contactNameField?.hasError('pattern')" translate>Enter valid mobile number</mat-error>
            </div>

            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Total number of members</mat-label>
                <input matInput [placeholder]="'Total number of members' | translate" formControlName="memberShipBase" maxlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>
              <mat-error class="mt-n2 mb-2 error-message" *ngIf="memberShip?.hasError('pattern')" translate>Please enter a number</mat-error>
            </div>
          </div>

          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Male members</mat-label>
                <input matInput [placeholder]="'Enter number of male members' | translate" formControlName="maleMembers" maxlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>
              <mat-error class="mt-n2 mb-2 error-message" *ngIf="maleMembers?.hasError('pattern')" translate>Please enter a number</mat-error>
            </div>

            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Female members</mat-label>
                <input matInput [placeholder]="'Enter number of female members' | translate" formControlName="femaleMembers" maxlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>
              <mat-error class="mt-n2 mb-2 error-message" *ngIf="femaleMembers?.hasError('pattern')" translate>Please enter a number</mat-error>
            </div>
          </div>

          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Total no. of active members under FPO</mat-label>
                <input matInput [placeholder]="'Enter active no. of members under FPO' | translate" formControlName="activeMembers"  maxlength="10" (keypress)="onKeyPress($event)">
              </mat-form-field>
              <mat-error class="mt-n2 mb-2 error-message" *ngIf="activeMembers?.hasError('pattern')" translate>Please enter a number</mat-error>
            </div>

            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Women-led entity</mat-label>
                <mat-select formControlName="isWomenLedEntity" [placeholder]="'Select women-led entity' | translate">
                  <mat-option *ngFor="let option of entities" [value]="option?.value">{{ option?.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label translate>Board meeting details</mat-label>
                <input matInput [placeholder]="'Board meeting details' | translate" type="text" maxlength="100" formControlName="boardMeeting">
              </mat-form-field>
            </div>

            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>% of small holder farmers</mat-label>
                <input matInput (keypress)="onKeyPress($event, 'area')" class="no-arrows"
                  [placeholder]="'Enter percent of small holder farmers' | translate"
                  formControlName="perOfSmallHolder" max="100" type="number">
              </mat-form-field>
              <mat-error *ngIf="percentageOfSmallFarmers?.hasError('max')" class="mt-n2 mb-2 error-message" translate>
                Percentage should be equal or less than 100
              </mat-error>
            </div>
          </div>

          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label translate>About FPO</mat-label>
                <textarea matInput [placeholder]="'Write about FPO' | translate" maxlength="250"
                  formControlName="aboutMe"></textarea>
              </mat-form-field>
            </div>

            <!-- Main activity -->
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label translate>Main activity</mat-label>
                <textarea matInput [placeholder]="'Write about main activity(s)' | translate" maxlength="250"
                  formControlName="mainActivity"></textarea>
              </mat-form-field>
            </div>
            <!--/ Main activity -->
          </div>
        </div>

        <!-- Farmer groups available -->
        <div fxLayout="column">
          <div fxLayout fxLayoutGap="10px">
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Farmer groups available</mat-label>
                <mat-select formControlName="isFarmerGrpAvailable" [placeholder]="'Farmer groups' | translate">
                  <mat-option *ngFor="let option of entities" [value]="option?.value">{{ option?.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Share capital <span>(₹)</span></mat-label>
                <input matInput formControlName="shareCapital"
                  [placeholder]="'Enter share capital(₹)' | translate"
                  type="text" maxlength="12" (keypress)="onKeyPress($event)" [value]="shareCapitalValue">
              </mat-form-field>
            </div>
          </div>
        </div>
        <!--/ Farmer groups available -->
      </div>

      <!--/ Output business -->

      <!-- Fpo logo details -->
      <div fxLayout="column" class="mb-4 pt-3 mx-0" formGroupName="fpoDetails"
        *ngIf="fpoLogoDetailsArray?.value?.length">
        <div fxLayout class="table-header-separator">
          <span class="font-weight-bold" ngClass.lt-md="table-header" fxFlex.gt-sm="33%" translate>Fpo logo name</span>
          <span class="font-weight-bold" ngClass.lt-md="table-header" fxFlex.gt-sm="33%" translate>Logo</span>
          <span class="font-weight-bold" fxFlex.gt-sm="33%" translate>Action</span>
        </div>

        <hr class="bg-dark mt-1 mb-1">

        <div fxLayout="column" class="mt-1" *ngFor="let logo of fpoLogoDetailsArray?.value; let i= index">
          <div fxLayout>
            <span ngClass.lt-md="table-data" fxFlex.gt-sm="33%" class="text-truncate">{{ logo?.logoName }}</span>
            <span ngClass.lt-md="table-data" fxFlex.gt-sm="33%" class="text-truncate"><img class="attachement-image cursor-pointer" width="20" height="20"
                [src]="logo?.attachment | prefixDomain" /></span>
            <div fxFlex.gt-sm="33%">
              <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editFpoLogoDetails(i)">
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>

              <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="action-buttons" (click)="removeFpoLogoDetails(i)">
                <mat-icon class="action-icons text-danger">clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--/ Fpo logo details -->

      <!-- Turnover business details -->
      <div fxLayout="column" [formGroup]="turnoverDetailsForm">
        <p class="mb-0 font-weight-bold headers" translate>Turn over</p>
        <div fxLayout fxLayoutGap="10px">
          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Financial year</mat-label>
              <mat-select formControlName="financialYearId" [placeholder]="'Business account type' | translate" (click)="financialYearField()">
                <mat-option *ngFor="let years of financialYears" [value]="years?.id">{{ years?.id }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div class="mt-n2 mb-2">
              <span class="text-danger" *ngIf="allFinancialYearFilled" translate>All financial years are filled!</span>
            </div>
          </div>
          <div fxFlex="calc(40% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Turnover <small>(₹)</small></mat-label>
              <input class="no-arrows" matInput [placeholder]="'Enter turnover (₹)' | translate" [readonly]="!financialsYearId?.value" formControlName="turnover" maxlength="12" (keypress)="onKeyPress($event)" [value]="turnOverValue">
            </mat-form-field>
            <mat-error class="mt-n2" *ngIf="!financialsYearId.value && turnOverField.touched" translate>Enter financial year first</mat-error>
          </div>
          <!-- Add button -->
          <div class="add-button mt-1" fxLayout="row" fxLayoutAlign="end flex-start">
            <button mat-fab [disabled]="!(financialsYearId?.value && turnOverField?.value)" (click)="addTurnoverDetails()">
              <mat-icon class="text-white add-icon">add</mat-icon>
            </button>
          </div>
          <!--/ Add button -->
        </div>
        <!-- Turn over details -->
        <div class="turnover-table" *ngIf="turnOverDetails && turnOverDetails?.length">
          <div class="table">
            <!-- Table header values -->
            <div class="table-header text-center text-white p-1">
              <div class="table-cell" translate>S.no</div>
              <div class="table-cell" translate>Financial year</div>
              <div class="table-cell" translate>Turnover <span>(₹)</span></div>
              <div class="table-cell" translate>Action</div>
            </div>
            <!--/ Table header values -->

            <!-- Table body values -->
            <div class="table-body text-center">
              <div class="table-row bg-white" *ngFor="let turnover of turnOverDetails let i= index">
                <div class="table-cell">{{ i+1 }}</div>
                <div class="table-cell">{{ turnover?.financialYearId }}</div>
                <div class="table-cell">{{ convertIndianRupeeFormat(turnover?.turnover) }}</div>
                <div class="actions table-cell">
                  <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"  (click)="editTurnoverDetails(i)">
                    <mat-icon class="edit-icon">edit</mat-icon>
                  </button>
                  <button matTooltip="Delete" matTooltipPosition="after" mat-icon-button class="delete-buttons"  (click)="removeTurnOverDetails(i)">
                    <mat-icon class="delete-icon">clear</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <!--/ Table body values -->
          </div>
        </div>
        <!--/ Turn over details -->
      </div>
      <!--/ Turnover business details -->

      <!-- Output business details -->
      <div fxLayout="column" fxLayout.xs="column" formGroupName="ceoDetails" fxHide>
        <p class="mb-0 font-weight-bold headers" translate>CEO details</p>
        <div fxLayout fxLayoutGap="10px">
          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Name</mat-label>
              <input (keypress)="onKeyPress($event, 'alphabetSpace')" matInput [placeholder]="'Enter name' | translate" maxlength="60" formControlName="ceoName">
            </mat-form-field>
            <mat-error class="mt-n2 error-message" *ngIf="getCeoName?.hasError('pattern')" translate>Enter a valid name</mat-error>
          </div>
          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Mobile number</mat-label>
              <input matInput [placeholder]="'Enter mobile number' | translate" formControlName="ceoMobileNumber" pattern="^[0-9]+$" maxlength="10" (keypress)="onKeyPress($event)">
            </mat-form-field>
            <mat-error class="mt-n2 error-message" *ngIf="mobileNumberField?.hasError('pattern')" translate>Enter valid mobile number</mat-error>
          </div>
        </div>
        <div fxLayout>
          <div fxFlex="49%" fxFlex.xs="100%">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Email id</mat-label>
              <input matInput [placeholder]="'Enter email id' | translate" formControlName="ceoEmailId" type="email">
            </mat-form-field>
            <mat-error class="mt-n2 error-message" *ngIf="emailIdField?.hasError('pattern')" translate>Please enter a valid email id</mat-error>
          </div>
        </div>
      </div>
      <!--/ Output business -->
    </main>

    <div fxLayout fxLayoutAlign="end end" class="mt-2 mr-3 save-button mb-4">
      <kl-flat-button
        [label]="'Save' | translate"
        [disabled]="(ceoDetailsGroup.invalid || basicDetailsForm.invalid || percentageOfSmallFarmers.invalid)"
        [progress]="isProgress"
        (clicked)="addBasicInfoDetails()">
      </kl-flat-button>
    </div>
  </form>
  <!--/ Form group for business activities -->
</main>
