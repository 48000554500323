import { Component, Injector, Input, OnInit } from '@angular/core';

import { KalgudiFarmerFarmingActivitySettings } from '../../classes/kalgudi-farmer-farming-activity-settings';

@Component({
  selector: 'kl-farmer-farming-activity-settings',
  templateUrl: './farmer-farming-activity-settings.component.html',
  styleUrls: ['./farmer-farming-activity-settings.component.scss']
})
export class FarmerFarmingActivitySettingsComponent extends KalgudiFarmerFarmingActivitySettings implements OnInit {

  @Input()
  cropId: string;

  @Input()
  pageId: string;

  @Input()
  profileKey: string;

  @Input()
  isAssisted: boolean;

  constructor(protected injector: Injector) {

    super(injector);

    this.activeTabIndex = 0;
  }

  ngOnInit() {
    this.getSeasons();
  }

  onDestroyed(): void {}

  toAddSeasonTab() {
    this.activeTabIndex = 1;
  }

  /**
   * On tab change
   */
  tabChanged(tab) {
    if (tab.index === 0) {
      this.isSeasonEdit = false;
    }
  }

  /**
   * Opens add season tab with pre fill season details
   */
  editSeason(season: any) {
    this.activeTabIndex = 1;
    this.selectedSeason = season;
    this.isSeasonEdit = true;
  }
}
