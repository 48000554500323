import { Inject, Injectable } from '@angular/core';
import { StatsTablePage, StatsTablePageService } from '@kalgudi/ag-grid-lib/common';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AgGridOptions, PageStatsData, PartialData, StoreType } from '@kalgudi/types';
import { Observable, of } from 'rxjs';

@Injectable()
export class PageMembersService implements StatsTablePage {

  /**
   * /v2/social/questions
   */
  private readonly API_BASE = `${this.env.restBaseUrlV2}/pages/members`;

  /**
   * /v2/social/questions/stats
   */
  private readonly API_PAGE_MEMBERS_STATS = `${this.API_BASE}/stats`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private statsPageService: StatsTablePageService,
  ) { }


  fetchPageStats(storeType: StoreType, params: PartialData = {}): Observable<PageStatsData> {
    params = {
      ...params,
      mode: 'ag_grid',
      role: 'FOLLOWER'
    };

    return of({
      'results': [
        {
          'clickAction': true,
          'filter': { 'filter': 'ALL' },
          'key': 'ALL',
          'title': '',
          'total': 5
        }
      ],
      'selectedIndex': 0,
      'viewType': 'CHIPS' as any
    }); //this.statsPageService.fetchStats(this.API_PAGE_MEMBERS_STATS, storeType, params);
  }

  fetchPageList(storeType: StoreType, params: PartialData = {}): Observable<AgGridOptions> {

    params = {
      ...params,
      mode: 'ag_grid',
      role: 'FOLLOWER',
    };

    return this.statsPageService.fetchList(this.API_BASE, storeType, params);
  }
}

