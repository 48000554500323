<div class="survey-list-page">
  <div class="w-100">

    <section id="surveys-list">
      <div *ngIf="((stream$ | async) || true) as stream">

        <!-- List of surveys -->
        <kl-survey-list
          tileFxFlex="100%"
          [surveyList]="stream"
          (surveySelected)="openSurveyFullView($event)">
        </kl-survey-list>
        <!--/ List of surveys -->

        <div class="mt-3" *ngIf="paginator$ | async as paginator">

          <!-- No content -->
          <div fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="!stream || stream?.length <= 0">
              <kl-no-content
                svgIcon="assets/svgs/surveys-icon.svg"
                text="You are not part of any survey"
                [actionLink]="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR ? 'Create your first survey' : ''"
                progressText="Loading your surveys..."
                [progress]="paginator?.loading"
                (action)="createSurveyInPage()">
              </kl-no-content>
            </ng-container>
          </div>
          <!--/ No content -->

          <!-- Load more -->
          <ng-container *ngIf="stream?.length > 0">
            <kl-load-more
              noMoreText="No more surveys to show!"
              [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </ng-container>
          <!--/ Load more -->
        </div>

      </div>
    </section>

  </div>
</div>

<div *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
  <kl-float-button (clicked)="createSurveyInPage()"></kl-float-button>
</div>
