import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiFarmerLand, KalgudiUser, WeatherDetails } from '@kalgudi/types';
import { KalgudiWeatherService } from '@kalgudi/weather';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { AddLandDetailsDialogComponent } from '../components/add-land-details-dialog/add-land-details-dialog.component';
import {
  AddLandDetailsMobileDialogComponent,
} from '../components/add-land-details-mobile-dialog/add-land-details-mobile-dialog.component';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiFarmerLandList  extends KalgudiInboxStream<string> {

  @Input()
  openLandDialog: boolean;

  @Output()
  closeLandDialog = new EventEmitter();

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  profile: KalgudiUser;
  landDetails: KalgudiFarmerLand;
  weatherDetails: WeatherDetails;
  landYears = [];
  editable: boolean;

  private profileState: KalgudiProfileStateService;
  private farmerProfileService: KalgudiFarmerProfileService;
  private weatherService: KalgudiWeatherService;
  private profileService: KalgudiProfileService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialogsService: KalgudiDialogsService
  ) {

    super(notification, util);

    this.profileState         = this.injector.get(KalgudiProfileStateService);
    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.weatherService       = this.injector.get(KalgudiWeatherService);
    this.profileService       = this.injector.get(KalgudiProfileService);

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => this.initProfile(res));

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Gets the land details on selection
   */
  getLandDetails(land: KalgudiFarmerLand) {
    this.landDetails = land;
    this.resetStream();
  }

  /**
   * Edits the land details
   */
  editLandDetailsDialog(landDetails: KalgudiFarmerLand): void {

    this.showAddLandDetailsDialog(landDetails);
  }

  /**
   * Shows confirmation dialog and calls api to delete the farm
   */
  deleteLandDetailsDialog(landId: string) {

    this.showDeleteConfirmationDialog()
      .pipe(

        tap(res => this.notification.showSpinner()),

        switchMap(res => this.farmerProfileService.deleteLand(landId)),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onDeleteLand(true),
        err => this.util.errorHandler(err)
      );

  }

  /**
   * Shows add land details dialog
   */
  showAddLandDetailsDialog(landDetails: KalgudiFarmerLand = null) {

    if (!this.editable) {
      return;
    }

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: landDetails && landDetails.landId ? 'Update land details' : 'Add land details',
      acceptButtonTitle: 'Add land details',
      rejectButtonTitle: 'Cancel',
      data: {
        landDetails,
        isAssisted: this.isAssisted
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openAddLandDetailsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        tap(_ => this.closeLandDialog.emit()),

        filter(r => r && r.accepted),
      )
      .subscribe();
  }

  /**
   * Gets weather details
   */
  getWeatherDetails() {

    this.weatherService.getWeatherDetails(
      this.profile.profileKey,
      this.profile.lstOfUserBusinessDetailsInfo[0].locationTo.latitude,
      this.profile.lstOfUserBusinessDetailsInfo[0].locationTo.longitude, 'EN')
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.weatherDetails = res.results[0][0];
        }
      );
  }

  /**
   * Reload the years list
   */
  reloadYears(): void {
    this.resetStream();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Called after deleting the farm
   */
  protected onDeleteLand(res: boolean): void {}

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.farmerProfileService.getLandYears(this.landDetails.landId);

  }


  /**
   * Shows the add land details web or mobile dialog
   */
  private openAddLandDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddLandDetailsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddLandDetailsDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Initializes the land details
   */
  private initProfile(res: KalgudiUser): void {

    this.profile = res;

    if (this.profile.farmerLandDetails && this.profile.farmerLandDetails.lands && this.profile.farmerLandDetails.lands.length) {
      this.landDetails = this.landDetails ? this.landDetails : this.profile.farmerLandDetails.lands[0];
      this.initStream();
    }
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
