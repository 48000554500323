<mat-card id="kalgudi-training-form" class="my-2 p-0">
  <!-- Title -->
  <div fxLayout="row" class="px-3 pt-3 pb-0" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>
    <svg-icon src="assets/svgs/training.svg" [svgStyle]="{'width.px': '25', 'height.px': '25'}"></svg-icon>
    <p class="mb-0 title" translate>Training</p>
  </div>
  <!--/ Title -->

  <form autocomplete="off" [formGroup]="shareForm" *ngIf="shareForm">
    <div class="pb-1 px-3 pt-3 program-share-form">
      <kl-assist-members-picker [formControl]="assistedUser"></kl-assist-members-picker>

      <!-- Form field -->
      <div class="text-area-wrapper">
        <mat-form-field appearance="outline" class="w-100 mb-2 mt-2">
          <textarea matInput class="input-field" [placeholder]="'Enter training details here!' | translate"
            [rows]="rowsCount" formControlName="shareText"></textarea>
        </mat-form-field>
      </div>
      <!--/ Form field -->

      <!-- Share filters -->
      <div class="mb-2">
        <kl-page-activity-filters
          [pageId]="pageId"
          [shareForm]="shareForm"
          [tagType]="availablePageTypes?.TRAINING"
          (shareWith)="shareWithChanged($event)">
        </kl-page-activity-filters>
      </div>
      <!--/ Share filters -->

      <!-- Gender -->
      <div class="mt-2" fxLayout fxLayoutAlign="start center" [formGroup]="shareFormFilter" *ngIf="shareWithValue !== 'SPECIFIC_KALGUDI_USER'">
        <div class="mt-n1">
          <span class="mr-2 gender-text" translate>Gender: </span>
        </div>

        <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
          <mat-radio-button value="" class="mr-4">{{ "All" | translate }}</mat-radio-button>
          <mat-radio-button value="MALE" class="mr-4">{{ "Male" | translate }}</mat-radio-button>
          <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--/ Gender -->

      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url" class="mb-3">
        <kl-post-tile-link-attachment [image]="urlFormControl?.value?.image" [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url" [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">
        </kl-post-tile-link-attachment>
      </div>
      <!--/ url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)"></kl-image-chip-list>
      <!--/ Image attachments -->

      <div class="mt-n1" fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="start start" fxLayout.xs="column"
        fxLayoutAlign="start center">
        <div fxLayout="row" fxLayout.lt-lg="column">
          <!-- Date of training -->
          <div class="training-date" fxLayout fxLayoutAlign="start center" fxLayoutGap="10px" fxLayoutGap.xs="4px">
            <span translate>Training Date:</span>

            <mat-form-field color="primary" class="schedule-font w-50" floatLabel="never">
              <mat-label translate>Choose date</mat-label>

              <input matInput [matDatepicker]="trainingDate" readonly (click)="trainingDate.open()" formControlName="fromDate">

              <mat-datepicker-toggle matSuffix [for]="trainingDate"></mat-datepicker-toggle>

              <mat-datepicker #trainingDate></mat-datepicker>
            </mat-form-field>
          </div>
          <!--/ Date of training -->

          <!-- Schedule date and time -->
          <!-- <div class="pt-2 ml-xl-n4">
            <div id="schedule-update-wrapper" class="" fxLayout fxLayoutGap="15px" fxLayoutGap.xs="10px"
              fxLayoutAlign="start center" fxLayoutAlign.xs="start center">
              <div class="schedule-post-wrapper" fxLayout fxLayoutGap="6px" fxLayoutGap.xs="3px"
                fxLayoutAlign="start center" fxLayoutAlign.xs="start center">
                <span class="schedule ml-4 mr-2" translate>Schedule: </span>

                <div fxFlex="110px" fxFlex.xs="95px">
                  <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                    <mat-label translate>Choose date</mat-label>

                    <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" formControlName="scheduledDate">

                    <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>

                    <mat-datepicker #scheduledDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div fxFlex="76px" fxFlex.xs="88px">
                  <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value"
                    [disabled]="!scheduledDateField?.value" [placeholder]="'Time' | translate"
                    formControlName="scheduledTime"></kl-hour-selectable-form-control>
                </div>
              </div>

              <span class="clear-btn cursor-pointer text-danger pl-2" *ngIf="scheduledDateField?.value" (click)="clearDate()" translate>Clear</span>
            </div>
          </div> -->
          <!--/ Schedule date and time -->
        </div>
      </div>

      <!-- Attachments menu with Image options -->
      <div fxLayout fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
        <button mat-button class="attach-files p-0" id="attach-files-button" color="primary" [matMenuTriggerFor]="menu">
          <mat-icon>attachment</mat-icon>

          <span class="ml-1" translate>Attach</span>
        </button>

        <mat-menu #menu="matMenu" class="attach-file">
          <ng-container>
            <div mat-menu-item class="attach-file-mat-menu" [matTooltip]="'Capture image' | translate">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <p class="m-0 title" translate>Image</p>

                <div fxLayout="row">
                  <kl-camera-form-control fxFlex="95%" [formControl]="cameraAttachment" [showChipButton]="true"
                    [showIconButton]="true" [showBorder]="false" buttonIcon="camera_alt" buttonLabel="Camera"
                    [allowMultiple]="true" [showAttachments]="false" [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedImageFileTypes">
                  </kl-camera-form-control>

                  <span class="mx-2">|</span>

                  <kl-image-picker-form-control formControlName="lstOfAttachments" [showBorder]="false"
                    [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/images.svg"
                    [allowMultiple]="true" [showAttachments]="false" [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedImageFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <p class="m-0 title" translate>Voice</p>

                <div fxLayout="row">
                  <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center" class="mt-1"
                    [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                    [matTooltip]="'Attach audio' | translate" (click)="showAudioDialog()" mat-icon-button>
                    <div class="label-icon" fxLayout fxLayoutAlign="center center">
                      <mat-icon class="m-0 camera-icon" translate>mic</mat-icon>
                    </div>
                  </button>

                  <span class="mx-2">|</span>

                  <kl-image-picker-form-control formControlName="lstOfAttachments" [showBorder]="false"
                    [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                    [allowMultiple]="true" [showAttachments]="false" [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedAudioFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <p class="m-0 title" translate>Video</p>

                <div fxLayout="row">
                  <div class="mt-1">
                    <kl-attach-url-form-control fxFlex="100%" [showBorder]="false" [showChipButton]="false"
                      svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg" [formControl]="urlFormControl"
                      buttonType="mat-icon-button">
                    </kl-attach-url-form-control>
                  </div>

                  <span class="mx-2">|</span>

                  <kl-image-picker-form-control formControlName="lstOfAttachments" [showBorder]="false"
                    [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                    [allowMultiple]="true" [showAttachments]="false" [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedVideoFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu-file">
                <p class="m-0 title" translate>File</p>

                <div fxLayout="row">
                  <kl-image-picker-form-control formControlName="lstOfAttachments" [showBorder]="false"
                    [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                    [allowMultiple]="true" [showAttachments]="false" [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>
          </ng-container>
        </mat-menu>

        <!-- Submit and update button -->
        <div [ngClass]="{'send-btn-container': !progress}" class="share-button w-100" fxLayout
          fxLayoutAlign="flex-end center" fxLayoutGap="15px">
          <div [ngClass]="{'w-100 mt-2': isMobileDevice}">
            <kl-flat-button class="submit-btn" [label]="postDetails ? 'Update' : 'Submit'"
              [disabled]="(!shareFormText.value && !audioAttachment?.value?.url) || shareForm?.invalid"
              [progress]="progress" (clicked)="createPost()">
            </kl-flat-button>
          </div>
        </div>
        <!--/ Submit and update button -->
      </div>
      <!--/ Attachments menu with Image options -->
    </div>
  </form>
</mat-card>
