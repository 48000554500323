<main id="kalgudi-core-app" class="kl-smooth-scrollbar">

  <!-- Route navigation progress -->
  <div fxHide.xs *ngIf="(routeProgress$ | async)">
    <core-route-loading-progress></core-route-loading-progress>
  </div>
  <!-- / Route navigation progress -->

  <!-- Kalgudi blocking spinner -->
  <div *ngIf="(spinner$ | async)">
    <kl-spinner></kl-spinner>
  </div>
  <!-- / Kalgudi blocking spinner -->

  <mat-sidenav-container>

    <mat-sidenav position="end" #sidenav role="navigation">
      <mat-nav-list>
        <core-header-menu (menuClosed)="sidenav.close()"></core-header-menu>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>

      <kl-notification></kl-notification>
      <div class="header">
        <core-app-header (toggleMenu)="sidenav.toggle()"> </core-app-header>
      </div>
      <div class="content" id="kalgudi-core-content">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>

  </mat-sidenav-container>

  <!-- Kalgudi photoswipe Don't remove it-->
  <!-- Single place photoswipe injection -->
  <kl-photoswipe></kl-photoswipe>
  <!-- / Kalgudi photoswipe -->

</main>

