<div id="farmer-crops">
  <div>
    <!-- Crops header -->
    <div class="farmer-crops-header" fxLayout fxLayoutAlign="space-between center" *ngIf="profile?.dealsWith">

      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

        <div>
          <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/farm-crop.svg"
            [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
        </div>

        <div>
          <p class="m-0 title" translate>Crops</p>
        </div>

      </div>

      <div *ngIf="editable">
        <button mat-button class="add-crop" (click)="showAddProductsDialog()">
          <div class="button-title">
            <p class="m-0"><b translate>Add</b></p>
          </div>
        </button>
      </div>

    </div>
    <!-- /Crops header -->

    <div *ngIf="!progress && profile?.dealsWith">
      <kl-farmer-crops-list [products]="products"
        (editCrop)="editProductsDialog($event)"
        (deleteCrop)="deleteCrop($event)"
        [pageId]="pageId"
        [isAssisted]="isAssisted"
        [productsLength]="productsLength"
        [assistedProfileKey]="assistedProfileKey">
      </kl-farmer-crops-list>

      <!-- <div *ngIf="products && showViewCatalogue" class="w-100" fxLayout fxLayoutAlign="end end">
        <a class="view-stock" (click)="navigateToCataloguePage()">View stock catalogue</a>
      </div> -->

      <ng-container *ngIf="products && showViewCatalogue">
        <mat-card>
          <div *ngIf="isAssisted" fxLayout="row" class="mt-1 cursor-pointer" (click)="navigateToCataloguePage()" fxLayoutAlign="space-between">
            <a class="task-stock" translate>View stock catalogue</a>
            <mat-icon class="arrow-icon ml-1">arrow_forward</mat-icon>
          </div>
        </mat-card>
      </ng-container>

    </div>
  </div>

  <div class="farmer-crops-list mt-2">

    <ng-container *ngIf="progress">
      <kl-no-content
        iconSize="md"
        [progressText]="'Loading crops...' | translate"
        [progress]="progress">
      </kl-no-content>
    </ng-container>

    <div *ngIf="!progress && !profile?.dealsWith">

      <kl-farmer-no-content
        [noContentText]="editable ? '' : 'No crops added yet!' | translate"
        [buttonTitle]="'Add crop' | translate"
        [noContentHeight]="'40vh'"
        svgIcon="assets/svgs/farm-crop.svg"
        [backgroundImageHeight]= "'85px'"
        backgroundImage="assets/images/add-crop.png"
        (action)="showAddProductsDialog()">
      </kl-farmer-no-content>

    </div>

  </div>
</div>
