import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './breadcrumbs.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BreadcrumbsComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,

    RouterModule,
    TranslateModule
  ],
  exports: [
    BreadcrumbsComponent,
  ]
})
export class KalgudiBreadcrumbsModule { }
