import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ShareQaFullDetails, ShareUpdate } from '@kalgudi/types';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { KalgudiDigitalAssistancePage } from '../../classes/digital-assistance-page';
import { DigitalAssistanceStateService } from '../../services/digital-assistance-state.service';
import { DigitalAssistanceService } from '../../services/digital-assistance.service';

@Component({
  selector: 'kl-digital-assist-full-view',
  templateUrl: './digital-assist-full-view.component.html',
  styleUrls: ['./digital-assist-full-view.component.scss']
})
export class DigitalAssistFullViewComponent extends KalgudiDigitalAssistancePage implements OnInit {

  @Input()
  qaDetails: ShareQaFullDetails;

  @Input()
  shareUpdate: ShareUpdate;
  assistOption: string;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected daService: DigitalAssistanceService,
    protected daStateService: DigitalAssistanceStateService,
    protected kalgudiPageService: KalgudiPageService,
  ) {

    super(
      injector,
      notification,
      util,
      daService,
      daStateService,
      kalgudiPageService,
    );
  }

  ngOnInit() {
  }

  protected onDestroyed(): void { }
}
