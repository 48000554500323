import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SocialSharesService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { BottomSheetMenu, UIMenu } from '@kalgudi/types';

import {
  EditCommonProfileDetailsDialogService,
 } from '../../../profile-basic-edit/services/edit-common-profile-details-dialog.service';
import { KalgudiFarmerProfileBasicDetails } from '../../classes/farmer-profile-basic-details';

@Component({
  selector: 'kl-new-farmer-profile-header',
  templateUrl: './new-farmer-profile-header.component.html',
  styleUrls: ['./new-farmer-profile-header.component.scss']
})
export class NewFarmerProfileHeaderComponent extends KalgudiFarmerProfileBasicDetails implements OnInit, OnChanges {

  @Input()
  profileShareUrl: string;

  @Input()
  selectedTabId: number;

  @Input()
  showProfileLogs: boolean;

  @Output()
  activatedTab = new EventEmitter<number>();

  defaultProfilePic = 'assets/images/add-farm.png';

  activeTabIndex: number;

  userProfileKey: string;

  readonly TABS = {
    LANDCROPS: { index: 0, title: 'Land-Crops', id: 'LANDCROPS' },
    UPDATES: { index: 1, title: 'Updates', id: 'UPDATES' },
    TASKS: { index: 2, title: 'Tasks', id: 'TASKS' },
    PROFILE_LOGS: { index: 3, title: 'Profile logs', id: 'PROFILE_LOGS' },
  };

  listMenu: BottomSheetMenu = {
    title: 'Share event on social media',
    styleType: 'list',
    menu: [
      {
        id: 'SHARE_TO_FACEBOOK',
        title: 'Share to Facebook',
        faIcon: 'fab fa-facebook-f',
        iconColor: '#385898'
      },
      {
        id: 'SHARE_TO_TWITTER',
        title: 'Share to Twitter',
        faIcon: 'fab fa-twitter',
        iconColor: '#1da1f2'
      },
      {
        id: 'SHARE_TO_LINKEDIN',
        title: 'Share to Linkedin',
        faIcon: 'fab fa-linkedin-in',
        iconColor: '#544bc2'
      },
      {
        id: 'SHARE_TO_WHATSAPP',
        title: 'Share to Whatsapp',
        faIcon: 'fab fa-whatsapp',
        iconColor: '#0ee00e'
      },
      {
        id: 'SHARE_TO_EMAIL',
        title: 'Share to Email',
        faIcon: 'fas fa-envelope-square',
        iconColor: '#d33f2b'
      }
    ]
  };

  // tabsList: UIMenu[] = [
  //   {
  //     id: 'ABOUT',
  //     selected: false,
  //     title: 'About'
  //   },
  //   {
  //     id: 'UPDATES',
  //     selected: false,
  //     title: 'Updates'
  //   },
  //   {
  //     id: 'PROFILE_LOGS',
  //     selected: false,
  //     title: 'Profile logs'
  //   }
  // ];

  domain: string;
  url: string;

  constructor(
    private socialShareService: SocialSharesService,
    private kalgudiApp: KalgudiAppService,
    private bottomSheetService: KalgudiBottomSheetService,
    protected injector: Injector,
    private dialogsService: EditCommonProfileDetailsDialogService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(injector);

    this.domain = this.environment.coreDomain;
    this.activeTabIndex = this.TABS.LANDCROPS.index;
    this.userProfileKey = this.kalgudiApp.profileLocal.profileKey;
  }

  ngOnInit() {
    if(this.profileShareUrl) {
      this.url = this.profileShareUrl.replace(':profileKey', this.userProfileKey);
    }
  }

  ngOnChanges() {
    if (this.selectedTabId) {
      this.activeTabIndex = this.selectedTabId;
    }
  }

  onDestroyed(): void { }

  /**
   * Opening menu and bottom sheet depends on device
   */
  openShareButton() {
    this.openBottomSheet();
  }

  /**
   * Open bottom sheet if it is mobile device
   */
  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Opens profile basic details dialog
   */
  showEditDialog() {
    this.dialogsService.showEditEmployeeProfileDialog();
  }

  /**
   * Emit the value of activated tab
   */
  toggleTab(activity) {

    this.activatedTab.emit(this.activeTabIndex);
  }

  /**
   * Share to face book
   */
  shareToFacebook() {
    this.socialShareService.shareEventToFacebook(this.url);
  }

  /**
   * Share to twitter
   */
  shareToTwitter() {
    this.socialShareService.shareEventToTwitter(this.url);
  }

  /**
   * Share to linked in
   */
  shareToLinkedin() {
    this.socialShareService.shareEventToLinkedIn(this.url);
  }

  /**
   * Share to whatsapp web
   */
  shareToWhatsappWeb() {
    this.socialShareService.shareEventToWhatsappWeb(this.url);
  }

  /**
   * Share to whatsapp mobile
   */
  shareToWhatsappMobile() {
    this.socialShareService.shareEventToWhatsappMobile(this.url);
  }

  /**
   * Share to email web
   */
  shareToEmailWeb() {
    this.socialShareService.shareEventToEmailWeb(this.url);
  }

  /**
   * Share to email mobile
   */
  shareToEmailMobile() {
    this.socialShareService.shareEventToEmailMobile(this.url);
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {
    if (!menu) {
      return;
    }

    switch (menu.id) {
      case 'SHARE_TO_FACEBOOK':
        this.shareToFacebook();
        break;

      case 'SHARE_TO_TWITTER':
        this.shareToTwitter();
        break;

      case 'SHARE_TO_LINKEDIN':
        this.shareToLinkedin();
        break;

      case 'SHARE_TO_WHATSAPP':
        this.shareToWhatsappMobile();
        break;

      case 'SHARE_TO_EMAIL':
        this.shareToEmailMobile();
        break;
    }
  }
}
