import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { IAgGridCellRenderer } from '../../model/index';

@Component({
  selector: 'store-admin-ag-grid-sl-no-renderer',
  template: `
  <span>{{(cell?.rowIndex + 1) | number}}</span>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class AgGridSlNoRendererComponent implements OnInit, IAgGridCellRenderer {


  public static componentId = 'AgGridSlNoRendererComponent';

  private params: any;
  public cell: any;

  ngOnInit(): void {}

  agInit(params: any): void {
    this.params = params;
    this.cell = {
      row: params.value,
      col: params.colDef.headerName,
      rowIndex: params.rowIndex,
    };
  }

  refresh(): boolean {
    return false;
  }
  formatString(val: any): string {

    return val;
  }
}
