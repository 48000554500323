<div class="kalgudi-core-theme live-stock-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.data?.showEditFields ? 'Edit' : 'Add'}} {{data?.data?.stockType?.title | titlecase}} details</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content">
    <kl-add-live-stock-form
      [stockType]="data?.data?.stockType"
      [stock]="data?.data?.stock"
      [showEditFields]="data?.data?.showEditFields"
      (closeDialog)="ok()">
    </kl-add-live-stock-form>
  </div>
</div>
