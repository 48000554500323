import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, EventEmitter, HostListener, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { KalgudiStepperComponent } from '@kalgudi/common';
import { KalgudiProject } from '@kalgudi/types';

import { KalgudiProjectCreation } from '../../classes/kalgudi-project-creation';
import { CreateProjectStateService } from '../../services/create-project-state.service';


@Component({
  selector: 'kl-project-creation',
  templateUrl: './project-creation.component.html',
  styleUrls: ['./project-creation.component.scss'],
  providers: [
    {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {displayDefaultIndicatorType: false},
    }
  ],
  viewProviders: [ CreateProjectStateService ]
})
export class ProjectCreationComponent extends KalgudiProjectCreation implements OnInit {

  @ViewChild(KalgudiStepperComponent) kalgudiStepper: KalgudiStepperComponent;

  @Input()
  categoryTiles: string;

  @Output()
  projectCreated = new EventEmitter<KalgudiProject>();

  selectedIndex = 0;

  readonly TABS = {
    CREATE_NEW: { index: 0, title: 'Create new' },
    SELECT_EXISTING: { index: 1, title: 'Select existing' },
  };

  activeTabIndex: any;

  constructor(
    protected injector: Injector,
  ) {

    super(injector);

    this.activeTabIndex = this.TABS.CREATE_NEW.index;
  }

  ngOnInit() {

    if (this.projectId) {
      this.initProjectForms(this.projectId);
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false; // stay on same page
  }

  /**
   * On tab change
   * @param tabDetails
   */
  onTabChange(tabDetails: any) {
    this.projectType = null;
    this.selectedExistingProject = {};
    this.commonProjectForm.reset();
    this.detailedProjectForm.reset();
  }


  stepperIndexChanged(stepperIndex) {
    this.selectedIndex = stepperIndex;
  }


  nextStep(): void {
    this.kalgudiStepper.next();

    super.nextStep();
  }

  previousState(moveStepper = true): void {

    if (moveStepper) {
      this.kalgudiStepper.previous();
    }

    super.previousState();
  }

  protected onProjectCreated(payload: KalgudiProject, response: KalgudiProject): void {

    // this.appRouting.toProject( { projectId: response.id }  , {type: 'GRID'});

    this.projectCreated.emit(response);
  }

  protected onProjectError(err: Error): void {

    this.previousState(false);
  }

  protected onDestroyed(): void { }


}
