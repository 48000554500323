import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';

@Injectable()
export class GoogleGeoLocationService {

  constructor(
    private mapApiLoader: MapsAPILoader
  ) { }

  /**
   * Get lat long path associated with the google may polygon object.
   */
  getPath(polygon: any): Array<{ lat: number, lng: number }> {


    const len = polygon.getPath().getLength();
    let latLng: Array<{lat: number, lng: number}> = [];

    for (let i = 0; i < len; i++) {
      const vertex = polygon.getPath().getAt(i);
      const vertexLatLng = { lat: vertex.lat(), lng: vertex.lng() };
      latLng.push(vertexLatLng);
    }

    return latLng;



    // if (this.isLatLongExists(event)) {
    //   latLng = event.latLngs.i[0].i.map(item => ({ lat: item.lat(), lng: item.lng() }));
    // }

    // return latLng;
  }

  private isLatLongExists(event): boolean {
    return !!(event.latLngs && event.latLngs.i && event.latLngs.i[0] && event.latLngs.i[0].i);
  }

}
