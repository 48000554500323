import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAutocomplete } from '@kalgudi/core';
import { StoreBaseProductBasicDetails, StoreProductLevel2 } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseProductAutocompleteService } from '../services/base-product-autocomplete.service';

/**
 * Defines logic to show base product autocomplete.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiProductVarietyAutocomplete extends KalgudiAutocomplete<StoreBaseProductBasicDetails> {


  @Input()
  baseProduct: StoreBaseProductBasicDetails;

  private autoCompleteApi: BaseProductAutocompleteService;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {

    super(fb);

    this.autoCompleteApi = this.injector.get(BaseProductAutocompleteService);

    this.placeholder = 'Search and select variant';
    this.label       = 'Search and select variant';

    // Initialize the autoCompleteForm
    this.autoCompleteForm = this.newAutoCompleteFormGroup;

    // Construct the prediction list fetching stream
    this.initAutocomplete();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return this.autoCompleteForm.get('productName');
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      productId: [''],
      productName: [''],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayWithFn(value: StoreBaseProductBasicDetails): any {
    return value && value.productName ? value.productName : value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected streamApi(searchKeyword: string): Observable<StoreBaseProductBasicDetails[]> {

    // Products variety api
    const api = this.varietiesProductApi(searchKeyword);

    return api.pipe(
        map(predictions => this.mapProductDetails(predictions))
      );
  }

  /**
   * Gets, varieties product prediction with the specified search keyword and base product id
   */
  private varietiesProductApi(text: string): Observable<StoreProductLevel2[]> {
    return this.autoCompleteApi.getProductsVariety(text);
  }

  /**
   * Maps a list of google places prediction result to KalgudiLocation type list.
   */
  private mapProductDetails(predictions: StoreProductLevel2[]): StoreBaseProductBasicDetails[] {

    return predictions.map(prediction => {
      return {
        productId: prediction.productLevel2Id,
        productName: prediction.productLevel2Title,

      };
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
