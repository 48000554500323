import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiCreateStockResponse } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class StocksApiService {

  /**
   * `v2/pages/stocks`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/pages/stocks`;

  /**
   * /v2/projects/stocks/:stockId
   */
  private readonly API_STOCK = `${this.API_STREAM}/:stockId`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Fetch stocks list
   * @param pageId
   * @param offset
   * @param limit
   */
  fetchStream(
    offset: number,
    limit: number,
    pageId: string): Observable<KalgudiStreamData> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      pageId
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi project details object type
        map(r => this.mapStocksListResponse(r))

      );
  }

  /**
   * Api to create a stock
   */
  createStock(pageId: string, req: KalgudiCreateStockResponse): Observable<any> {

    const params = {
      pageId
    };

    return this.httpClient
      .post<ApiResponseCommon>(this.API_STREAM, req, {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        map(res => res.data)
      );
  }

  /**
   * Updates the stock
   */
  updateStock(stockId: string, payload: KalgudiCreateStockResponse) {
    const url = this.API_STOCK.replace(':stockId', stockId);

    return this.httpClient.put<ApiResponseCommon>(url, payload)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Deletes the stock
   */
  deleteStock(stockId: string) {
    const url = this.API_STOCK.replace(':stockId', stockId);

    return this.httpClient.delete<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Get the stock
   * @param stockId
   */
  getStock(stockId: string) {
    const url = this.API_STOCK.replace(':stockId', stockId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(r => this.util.apiErrorHandler(r)),

        // No errors, all good return true
        map(res => res.data),
      );
  }


  /**
   * Maps projects list API response to kalgudi project details type.
   */
  private mapStocksListResponse(res: ApiResponseCommon): KalgudiStreamData {

    return {
      items: res.data.results,
      count: res.data.count
    };
  }
}
