import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-likes-list-dialog',
  templateUrl: './likes-list-dialog.component.html',
  styleUrls: ['./likes-list-dialog.component.scss']
})
export class LikesListDialogComponent extends KalgudiDialog<LikesListDialogComponent> implements OnInit {

  id: string;

  constructor(
    protected dialogRef: MatDialogRef<LikesListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

    this.id = this.data.data.id;
  }

  ngOnInit() {
  }

  /**
   * Close the dialog box on navigation to user profile page
   */
  profilePageOpen() {
    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
