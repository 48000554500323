import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'modernNumber'
})
export class ModernNumberPipe implements PipeTransform {

  transform(value: any = 0, args?: any): any {
    let res = `${value}`;

    value = Math.abs(+value);

    const B = 1000000000;
    const M = 1000000;
    const K = 1000;

    if (value >= B) {
      res = `${Math.round((value / B) * 100) / 100} B`;
    } else if (value >= M) {
      res = `${Math.round((value / M) * 100) / 100} M`;
    } else if (value >= K) {
      res = `${Math.round((value / K) * 100) / 100} K`;
    } else {
      res = `${Math.round(value)}`;
    }

    return res;
  }

}
