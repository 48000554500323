import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import {
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiPhotoswipeModule } from '@kalgudi/third-party/photoswipe';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';

import { KalgudiSurveyFormControlsModule } from '../survey-form-controls/survey-form-controls.module';
import { KalgudiSurveySharedModule } from '../survey-shared/survey-shared.module';
import {
  SurveySubmissionFormDialogComponent,
} from './components/survey-submission-form-dialog/survey-submission-form-dialog.component';
import {
  SurveySubmissionFormMobileDialogComponent,
} from './components/survey-submission-form-mobile-dialog/survey-submission-form-mobile-dialog.component';
import { SurveySubmissionFormComponent } from './components/survey-submission-form/survey-submission-form.component';
import { KalgudiSurveySubmissionApiService } from './services/kalgudi-survey-submission-api.service';
import { KalgudiSurveySubmissionService } from './services/kalgudi-survey-submission.service';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    SurveySubmissionFormComponent,
    SurveySubmissionFormDialogComponent,
    SurveySubmissionFormMobileDialogComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    MomentDateModule,
    MatCheckboxModule,

    // Flex
    FlexLayoutModule,

    // Forms module
    FormsModule,
    ReactiveFormsModule,

    // Kalgudi Modules
    KalgudiDialogsModule,
    KalgudiNoContentModule,
    KalgudiImageChipListModule,
    KalgudiButtonModule,
    KalgudiImagePickerModule,
    KalgudiPhotoswipeModule,
    KalgudiPipesModule,
    KalgudiSurveySharedModule,
    KalgudiSurveyFormControlsModule
  ],
  exports: [
    SurveySubmissionFormComponent
  ],
  providers: [
    KalgudiSurveySubmissionService,
    KalgudiSurveySubmissionApiService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    SurveySubmissionFormDialogComponent,
    SurveySubmissionFormMobileDialogComponent
  ]
})
export class KalgudiSurveySubmissionModule { }
