<div class="survey-list-page">
  <div class="w-100">

    <section id="surveys-list">
      <div *ngIf="((stream$ | async) || true) as stream">

        <!-- List of surveys -->
        <div>
          <kl-survey-list
            [surveyList]="stream"
            (surveySelected)="openSurveyFullView($event)"
            (surveyDetailsAccepted)="cloneSurveyDetailsAccepted($event)">
          </kl-survey-list>
        </div>
        <!--/ List of surveys -->

        <div class="mt-3" *ngIf="paginator$ | async as paginator">

          <!-- No content -->
          <div fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="!stream || stream?.length <= 0">
              <kl-no-content
                svgIcon="assets/svgs/surveys-icon.svg"
                text="You are not part of any survey"
                actionLink="Create your first survey"
                progressText="Loading your surveys..."
                [progress]="paginator?.loading"
                (action)="openCreateSurveyDialog()">
              </kl-no-content>
            </ng-container>
          </div>
          <!--/ No content -->

          <!-- Load more -->
          <ng-container *ngIf="stream?.length > 0">
            <kl-load-more
              noMoreText="No more surveys to show!"
              [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </ng-container>
          <!--/ Load more -->
        </div>

      </div>
    </section>

  </div>
</div>
