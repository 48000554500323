import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponsePageDigitalAssistanceStream,
  ApiResponsePageMemberSearch,
  KalgudiPageRelation,
  KalgudiUserBasicDetails,
  PageDigitalAssistanceStreamData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DigitalAssistanceApiService {

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_BASE = `${this.env.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/digitalAssist`
   */
  private readonly API_PAGE_DIGITAL_ASSISTANCE = `${this.API_PAGE_BASE}/digitalAssist`;

  /**
   * `v2/pages/digitalAssist/contacts`
   */
  private readonly API_PAGE_DIGITAL_ASSISTANCE_FREQUENT_MEMBERS = `${this.API_PAGE_DIGITAL_ASSISTANCE}/contacts`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Makes an API call to search list of page digital assistance frequent members with the specified
   * search keyword.
   */
  searchFrequentlyAssistedMembers(
    pageId: string,
    contributorProfileKey: string,
    offset = 0,
    limit = 1000,
  ): Observable<KalgudiUserBasicSearchResults> {

    const params = {
      pageId,
      profileKey: contributorProfileKey,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponsePageMemberSearch>(this.API_PAGE_DIGITAL_ASSISTANCE_FREQUENT_MEMBERS, { params })
      .pipe(

        // Check for API errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi user search result type
        map(res => ({ items: res.data.results, count: res.data.count }))
      );
  }

  /**
   * Saves frequent assisted users to the database
   */
  saveFrequentAssistedMembers(
    pageId: string,
    users: KalgudiUserBasicDetails[]
  ): Observable<KalgudiUserBasicDetails[]> {

    const params = {
      pageId,
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_PAGE_DIGITAL_ASSISTANCE_FREQUENT_MEMBERS, users, { params })
      .pipe(

        // Check for API errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response
        map(res => Object.values<KalgudiUserBasicDetails>(res.data))
      );
  }

  /**
   * Fetches kalgudi digital assistance stream for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchAssistance(
    pageId: string,
    role: KalgudiPageRelation,
    profileKey: string,
    offset: number,
    limit: number
  ): Observable<PageDigitalAssistanceStreamData> {

    // Query params
    const params = {
      pageId,
      profileKey,
      role: role.toString(),
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponsePageDigitalAssistanceStream>(this.API_PAGE_DIGITAL_ASSISTANCE, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
