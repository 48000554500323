
<div class="ai-prediction my-2 cursor-pointer" (click)="toAiPrediction()" fxLayout fxLayoutAlign="space-between center">

  <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">


    <div>

      <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/network.svg"

        [svgStyle]="{ 'width.px': 30, 'height.px': 30}"></svg-icon>

    </div>


    <div>

      <p class="m-0 title" translate>AI predictions</p>

    </div>

  </div>

  <div>

    <button mat-icon-button class="next">

      <div class="button-title">

        <mat-icon>arrow_right_alt</mat-icon>

      </div>

    </button>

  </div>

</div>
