import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { SurveyStateService } from '@kalgudi/surveys';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';
import {
  PageSurveyMemberAddDialogComponent,
} from '../components/page-survey-member-add-dialog/page-survey-member-add-dialog.component';
import {
  PageSurveyMemberAddMobileDialogComponent,
} from '../components/page-survey-member-add-mobile-dialog/page-survey-member-add-mobile-dialog.component';

@Directive()
export abstract class KalgudiPageSurveyManageMembers extends KalgudiDestroyable {

  pageId: string;

  // Dependencies
  protected programState: ProgramStateService;
  protected surveyState: SurveyStateService;
  protected kalgudiDialog: KalgudiDialogsService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Manually inject dependencies
    this.programState  = this.injector.get(ProgramStateService);
    this.surveyState   = this.injector.get(SurveyStateService);
    this.kalgudiDialog = this.injector.get(KalgudiDialogsService);

    this.pageId = this.programState.id;

    // Subscribe to page state manager state changes
    this.subscribeToPageActions();
  }



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Open add survey members dialog
   */
  showAddSurveyMembersDialog = (): void  => {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add survey members',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '70vw',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.openAddSurveyMembersDialog(dialogDetails, dialogConfig)
      .pipe(

        takeUntil(this.destroyed$),

        filter(res => res.accepted),
      )
      .subscribe();
  }

  /**
   * Opens add survey members dialog
   */
  private openAddSurveyMembersDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialog.openMobileDialog(PageSurveyMemberAddMobileDialogComponent, details)
      : this.kalgudiDialog.openDialog(PageSurveyMemberAddDialogComponent, details, config);
  }

  /**
   * Subscribes to page state changes
   */
  private subscribeToPageActions(): void {

    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === PageActions.PAGE_SURVEY_MEMBERS_ADD) {
          // this.surveyState.dispatchAction(SurveyPageActions.ADD_MEMBER);
          this.showAddSurveyMembersDialog();
        }
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
