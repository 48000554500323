import { Pipe, PipeTransform } from '@angular/core';


/**
 * Transforms an image file URL to a resized resource URL. The pipe
 * adds a resized image width and height as suffix to the source image
 * file.
 *
 * Acceptable dimensions : 'sm' or '150x150', 'md' or '540x400'
 *
 * @author Pankaj Prakash
 * @example
 * ```
 * <!-- For size 540x400 -->
 * <img mat-card-image class="image-attachment" [src]="imageUrl | resizeImage:'md'">
 * <img mat-card-image class="image-attachment" [src]="imageUrl | resizeImage:'540x400'">
 *
 * <!-- For size 150x150 -->
 * <img mat-card-image class="image-attachment" [src]="imageUrl | resizeImage">
 * <img mat-card-image class="image-attachment" [src]="imageUrl | resizeImage:'sm'">
 * <img mat-card-image class="image-attachment" [src]="imageUrl | resizeImage:'150x150'">
 * ```
 */
@Pipe({
  name: 'resizeImage'
})
export class ResizeImagePipe implements PipeTransform {


  transform(url: string, dimension: string = ''): string {

    // Default width and height for sm
    let width  = 150;
    let height = 150;

    if (dimension === 'md' || dimension === '540x400') {
      width  = 540;
      height = 400;
    } else if (dimension === 'sm' || dimension === '150x150') {
      width = 150;
      height = 150;
    } else if (dimension.includes('x')) {
      const dim = dimension.toLowerCase().split('x');
      width  = +dim[0];
      height = +dim[1]
    }

    // Don't execute function if url is not a valid url
    return url
      ? this.getResizedUrl(url, width, height)
      : url;
  }


  /**
   * Constructs image url based on resized width and height. If adds a suffix
   * _${width}x${height} at the end of an image file name.
   *
   * For example
   * source = https://www.kalgudi.com/.../M00pyco0PRFREG2018012529937998UNH0011522306235.54.jpg
   * dest   = https://www.kalgudi.com/.../M00pyco0PRFREG2018012529937998UNH0011522306235.54_540x400.jpg
   *
   * @param url Image file URL.
   * @param width Width of image, this width is used to suffix image file path.
   * @param height Height of image, this height is used to suffix image file path.
   */
  private getResizedUrl(url: string, width: number, height: number): string {

    const IMAGE_SUFFIX = `_${width}x${height}`;

    // Extract file path and name from url
    const filePath = url.substr(0, url.lastIndexOf('/') + 1);
    let fileName   = url.substr(url.lastIndexOf('/') + 1, url.length);

    // Remove query string if exists
    if (fileName.lastIndexOf('?') > -1) {
      fileName = fileName.substring(0, fileName.lastIndexOf('?'));
    }

    // Add suffix before file extension if exists
    if (fileName.lastIndexOf('.') > -1) {
      const nameBeforeImageType = fileName.substring(0, fileName.lastIndexOf('.'));

      fileName = fileName.replace(nameBeforeImageType, `${nameBeforeImageType}${IMAGE_SUFFIX}`);

    } else {
      // No extension add scaled URL at last
      fileName = fileName + IMAGE_SUFFIX;
    }


    return `${filePath}${fileName}`;
  }
}
