import { Injectable } from '@angular/core';
import {
  BoardOfMember,
  FPOBankingDetails,
  FPOInfrastructure,
  FPOServices,
  FPOWarehouse,
  KalgudiUser,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { OrganisationProfileApiService } from './organisation-profile-api.service';

@Injectable()
export class OrganisationProfileService {

  constructor(
    private api: OrganisationProfileApiService,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Calls api to add board of members
   */
  postBoardOfMembers(payload: BoardOfMember[], extraParams: PartialData = {}): Observable<KalgudiUser> {

    return this.api.postBoardOfMembers(this.profileState.id, payload, extraParams)

  }

  /**
   * Calls api to update fpo infrastructure
   */
  updateFpoInfrastructure(payload: FPOInfrastructure, extraParams: PartialData = {}): Observable<KalgudiUser> {

    return this.api.updateFpoInfrastructure(this.profileState.id, payload, extraParams)
  }

  /**
   * Calls api to add service license
   */
  postServiceLicense(payload: FPOServices[], extraParams: PartialData = {}): Observable<KalgudiUser> {

    return this.api.postServiceLicense(this.profileState.id, payload, extraParams)

  }

  /**
   * Calls api to update ware house
   */
  postWarehouse(payload: FPOWarehouse, extraParams: PartialData = {}): Observable<KalgudiUser> {

    return this.api.postWarehouse(this.profileState.id, payload, extraParams)

  }

  /**
   * Calls api to update banking details
   */
  updateBankingDetails(payload: FPOBankingDetails, extraParams: PartialData = {}): Observable<KalgudiUser> {

    return this.api.updateBankingDetails(this.profileState.id, payload, extraParams)

  }

  /**
   * Calls api to fetch farmers count
   */
  fetchFarmersCount(profileKey) {
    return this.api.fetchFarmerCount(profileKey);
  }

  /**
   * Api call to save FPO profile details
   */
  createFpoProfilesDetails(profileKey) {
    return this.api.createFpoProfilesDetails(profileKey);
  }

  /**
   * This service will convert the input number into indian notation : 10,00,000
   * @param inputField
   * @param inputValue
   * @returns
   */
  addCommaToNumericFields(inputField, inputValue) {
    let value = inputField.value.replace(/,/g, ''); // Remove existing commas
    const numericValue = parseInt(value, 10);

    if (!isNaN(numericValue)) {
      const formattedValue = numericValue.toLocaleString('en-IN');
      inputValue = formattedValue;
    } else {
      inputValue = ''; // Handle invalid input here
    }

    return inputValue;
  }

  /**
   * Displays warning sweet alert dialog
   * @param msg Message to show
   */
  showWarningSweetAlert(errorMsg: string): void {
    Swal.fire({
      titleText: errorMsg,
      icon: 'warning',
    })
  }

  /**
   * Displays sweet alert with delete and cancel confirmations
   * @param msg Message to show
   */
  openConfirmationSweetAlert(errorMsg: string): any {
    let alert = Swal.fire({
      titleText: errorMsg,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    });
    return alert;
  }

  /**
   * Checking user input matches array value or not in the form field
   */
  checkingItemInList(item: any, itemsList: any[]): boolean {
    let isListMatched: boolean = false;
    for (let i = 0; i <= itemsList.length; i++) {
      if (item && item === itemsList[i]) {
        isListMatched = true;
        break;
      } else {
        isListMatched = false;
      }
    }
    return isListMatched;
  }

}
