import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';

import { FpoAddCropSeasons } from '../../classes/kalgudi-fpo-add-crop-season';

@Component({
  selector: 'kl-cooperative-society-add-season-form',
  templateUrl: './cooperative-society-add-season-form.component.html',
  styleUrls: ['./cooperative-society-add-season-form.component.scss']
})
export class CooperativeSocietyAddSeasonFormComponent extends FpoAddCropSeasons implements OnInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  units = [
  { id: 'grams', value: 'Grams' },
  { id: 'kgs', value: 'Kgs' },
  { id: 'tons', value: 'Tons' },
  { id: 'quintals', value: 'Quintals' },
  { id: 'pieces', value: 'Pieces' },
  { id: 'milliliters', value: 'Milliliters' },
  { id: 'liters', value: 'Liters' }
];;

  constructor(
    protected injector: Injector,
    protected dialogsService: KalgudiDialogsService
  ) {
    super(injector, dialogsService);

    this.activeTabIndex = 0;
  }

  ngOnInit() {
    this.getProjectsList();
  }

  /**
   * Resets the geo fence markings
   */
  resetMap() {

    this.geoMarker.reset();
    this.geoFences.patchValue(null);
  }

  onDestroyed(): void {}
}
