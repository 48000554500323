import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, S3PolicyPathCategoryMap, S3UploadToken } from '@kalgudi/types';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { S3UploadNamePathMap } from '../typings';
import { ImageCompressService } from './image-compress.service';
import { KalgudiUtilityService } from './kalgudi-util.service';
import { NavigationEnd, Router } from '@angular/router';

/**
 * Helper service to upload files to the S3. Use this method to upload single file
 * or a list of files.
 *
 * @author Pankaj Prakash
 * @example
 * ```
 * // Upload single file
 * uploadService.uploadFile(event.target.files[0], KalgudiS3PolicyMap.SHARE, true, 'upload-filename');
 *
 * // Upload multiple file
 * const files = Array.from(event.target.files);
 * uploadService.uploadFile(files, KalgudiS3PolicyMap.SHARE, true);
 * ```
 */
@Injectable({
  providedIn: 'root'
})
export class KalgudiUploadService {

  /**
   * `rest/v1/signpolicy`
   */
  private readonly API_S3_SIGN_POLICY = `${this.env.restBaseUrl}/signpolicy_new`;

  isRoboCallPage: Boolean = false;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private imageService: ImageCompressService,
    public router: Router,
  ) {

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event) {
          this.isRoboCallPage = event.url.includes('/robocall');
        }
    });
  }

  /**
   * Uploads a single file to S3.
   *
   * @param file File to upload
   * @param category S3 upload category and path
   * @param compression `true` to enable image compression. Default set to `false`.
   * @param fileName Upload file name
   */
  uploadFile(file: File, category: S3PolicyPathCategoryMap, compression = false, fileName?: string): Observable<S3UploadNamePathMap> {

    // Fetch s3 token then upload the file
    return this.fetchS3Token(category.category, file.type)
      .pipe(
        // Map the token observable stream to the stream returned by upload function
        mergeMap(token => this.upload(file, category, token, compression, fileName))
      );
  }


  /**
   * Uploads a list of files to S3. On success it returns the uploaded file path
   * with the original file name.
   *
   * @param files List of files to upload
   * @param category S3 upload path and category
   * @param compression `true` to enable image compression. Default set to `false`.
   *
   * @returns Observable of fileName and filePath array
   */
  uploadFiles(files: File[], category: S3PolicyPathCategoryMap, compression = false): Observable<S3UploadNamePathMap[]> {
    // Combine list of all uploads together.
    // Note: All upload will be triggered only when subscribed to
    //       the returned object.
    return forkJoin(
      this.mapFilesToUpload(files, category, compression)
    );
  }

  /**
   * Gets, extension of the file. Trims out the file name and returns the
   * file extension. It also trims out extra unnecessary characters from the
   * file extension.
   *
   * @param mimeType MIME type of the file
   */
  getFileExtension(mimeType: string): string {
    return mimeType
      .split('/')[1]  // Trim generic file type `image/png`. Will keep 'png' only
      .split('+')[0]; // Trim extra file type characters like 'svg+xml'. Will trim out '+xml'
  }

  /**
   * Gets, extension of the file. Trims out the file name and returns the
   * file extension. It also trims out extra unnecessary characters from the
   * file extension.
   *
   * @param fileName Name of the file
   */
  getFileExtensionByFile(fileName: string = ''): string {

    const lastDot = fileName.lastIndexOf('.');

    const ext = fileName.substring(lastDot + 1);

    return ext;
  }


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Maps list of files to upload to a stream of Observables array.
   * It also fetches the S3 upload token for each file type as token may
   * differ for different type of uploads.
   */
  private mapFilesToUpload(
    files: File[],
    category: S3PolicyPathCategoryMap,
    // token: S3UploadToken,
    compression?: boolean,
    fileName?: string
  ): Observable<S3UploadNamePathMap>[] {

    // Array of upload stream
    const ob: Observable<S3UploadNamePathMap>[] = [];

    let compressionImage = compression;

    // Get token for each individual upload and
    // Push individual upload streams to the array `ob`
    files.forEach(f => ob.push(



      // Fetch s3 token for the current file
      this.fetchS3Token(category.category, f.type)
        .pipe(

          tap(res => compressionImage = f.type.includes('image') ? true : false),

          // Map the current token observable stream to the stream returned by file upload
          mergeMap(token => this.upload(f, category, token, compressionImage, fileName))
        )
    ));

    // Return the array of file stream to upload
    return ob;
  }

  /**
   * Uploads file to S3.
   *
   * @param file File to upload
   * @param s3Category S3 upload category and path
   * @param compression `true` to enable image compression. Default set to `false`.
   * @param fileName Upload file name
   */
  private upload(
    file: File,
    s3Category:
    S3PolicyPathCategoryMap,
    token: S3UploadToken,
    compression = false,
    fileName?: string
  ): Observable<S3UploadNamePathMap> {

    let ob: Observable<File | Blob> = of(file);

    // Compress image before uploading if compression enabled
    if (compression) {
      ob = this.imageService.compressImage(file);
    }

    // Upload image without compression
    return ob.pipe(
      mergeMap(f => this.uploadToS3(f, s3Category, token, fileName))
    );
  }

  /**
   * Uploads a file to S3 and returns the uploaded file path and file name map.
   *
   * @param file File to upload
   * @param category S3 upload path and category
   * @param token S3 upload token response sent by API
   * @param fileName File name to save. Default is current time in milliseconds
   * suffixed with file original name
   *
   * @returns Observable of object containing the uploaded file path and name
   */
  private uploadToS3(
    file: File | Blob,
    category: S3PolicyPathCategoryMap,
    token: S3UploadToken,
    fileName?: string
  ): Observable<S3UploadNamePathMap> {

    const fileType = this.getFileType(file);

    // Extract file extension
    const extension = this.getFileExtensionByFile((file as any).name) || fileType.split('/')[1];

    // Set default filename if not set
    fileName = fileName || `${new Date().getTime()}.${extension}`;

    // Upload file key
    const s3FileName = `${category.path}/${fileName}`;

    // Type of the file

    // Construct the request payload
    const request = new FormData();

    let roboCallPath: any = '';

    // Changing AWS storage path for robocall audio attachments
    if (this.isRoboCallPage) {
      if (s3FileName.includes('/share-a-thought')) {
        roboCallPath = s3FileName.replace('/share-a-thought', '/share-a-thought/robocalltempfolder')
      }
    }

    // Prepare request data
    request.append('AWSAccessKeyId', token.accesskey);
    request.append('policy', token.policy);
    request.append('signature', token.signature);
    request.append('signatureVersion', 'v4');
    request.append('key', this.isRoboCallPage ? roboCallPath : s3FileName);
    request.append('acl', 'public-read');
    request.append('Content-Type', 'image/');
    request.append('file', file);
    request.append('x-amz-algorithm', 'AWS4-HMAC-SHA256');
    request.append('x-amz-date', '20240409T000000Z');
    request.append('X-Amz-Credential', `${token.accesskey}/20240409/ap-south-1/s3/aws4_request`);


    return this.httpClient.post(this.env.s3BucketUrl, request)
      .pipe(
        map(res => ({
          fileName,
          filePath: `/${s3FileName}`,
          fileType: file.type,
        })
        )
      );
  }

  /**
   * Fetches, S3 upload token containing signature and sign policy from API.
   * An S3 upload token is necessary to upload files to S3.
   *
   * The upload token is a combination of upload `policy` and `signature`. These
   * are used while uploading files to S3.
   */
  private fetchS3Token(category: string, contentType: string): Observable<S3UploadToken> {

    // Extract generic content type for images
    contentType = contentType.includes('image/') ? 'image/' : contentType;

    // Query params
    const params = { category, contentType };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_S3_SIGN_POLICY, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => this.util.toJson<S3UploadToken>(res.data))
      );
  }

  /**
   * Returns the type of the file if exists otherwise returns a default file type
   * i.e. 'application/octet-stream'.
   */
  private getFileType(file: File | Blob): string {
    return file.type !== '' ? file.type : 'application/octet-stream';
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
