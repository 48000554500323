<div class="user-admin-tasks p-3">

  <div fxLayout="column" fxLayoutGap="5px">
    <span class="text-secondary title border-bottom pb-2" translate>Crop calendars you are admin stream</span>

    <div fxLayout="row wrap" fxLayoutGap="10px grid">
      <div fxFlex="calc(50% - 10px)" fxFlex.xs="100%" class="users-task" *ngFor="let task of userAdminTasks">
        <kl-tasks-tile
          [enableActions]="false"
          (openFullview)="openTaskFullview(task)"
          [taskDetails]="task"
          [showStats]="true">
        </kl-tasks-tile>
      </div>
    </div>

    <ng-container *ngIf="!userAdminTasks?.length">
      <p class="m-0 p-0 text-center text-muted" translate>
        There is no tasks
      </p>
    </ng-container>
  </div>

</div>
