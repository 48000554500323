import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

import { KalgudiAddTaskMembers } from '../../classes/kalgudi-add-task-members';

@Component({
  selector: 'kl-add-task-members',
  templateUrl: './add-task-members.component.html',
  styleUrls: ['./add-task-members.component.scss']
})
export class AddTaskMembersComponent extends KalgudiAddTaskMembers implements OnInit {

  @Input()
  taskId: string;

  @Input()
  projectId: string;

 // @Output()
  // users: EventEmitter<any>;
  users: any;

  selectedUsers: KalgudiUserBasicDetails;

  activeTabIndex: number;

  readonly TABS = {
    MANAGE: { index: 0, title: 'Manage Members' },
    SEARCH: { index: 1, title: 'Search Members' },
    BULK_MEMBERS: { index: 2, title: 'Bulk members' },
  };


  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(injector);
  }

  ngOnInit() {
    this.activeTabIndex = this.TABS.MANAGE.index;
  }

  selectedUsersList(users) {
    this.selectedUsers = users;
  }

  addedMembers(): void {

    this.activeTabIndex = this.TABS.MANAGE.index;
  }

  onDestroyed(): void  {}
}

