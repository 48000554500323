import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { FPOServices, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class KalgudiAddServices extends KalgudiDestroyable {

  @Output()
  closeDialog = new EventEmitter();

  servicesForm: FormGroup;

  profileDetails: KalgudiUser;

  editable: boolean;

  progress: boolean;

  servicesList: FPOServices[] = [];

  profileServicesList: FPOServices[];

  private fb: FormBuilder;
  private profileState: KalgudiProfileStateService;
  private profileService: KalgudiProfileService;
  private orgProfileService: OrganisationProfileService;
  protected util: KalgudiUtilityService;
  private notification: KalgudiNotification;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                = this.injector.get(FormBuilder);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.profileService    = this.injector.get(KalgudiProfileService);
    this.orgProfileService = this.injector.get(OrganisationProfileService);
    this.util              = this.injector.get(KalgudiUtilityService);
    this.notification      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);


    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
        this.profileServicesList = (this.profileDetails.fpoAdditionalDetails && this.profileDetails.fpoAdditionalDetails.fpoServices) ? this.profileDetails.fpoAdditionalDetails.fpoServices : [];
      });

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    this.servicesForm = this.newServiceForm;
  }


  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  /**
   * Form group for service form
   */
   private get newServiceForm(): FormGroup {

    return this.fb.group({
      service: [''],
      license: [''],
    });
  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls api method to add service license
   */
  addServiceLicense() {

    this.notification.showSpinner();

    const payload = this.prepareAddServicesPayload(this.util.clone(this.servicesForm.value));


    this.orgProfileService.postServiceLicense(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() =>  this.notification.hideSpinner())
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )
  }

  /**
   * Calls api method to remove service License
   */
  removeServices() {

    this.progress = true;

    const payload = this.profileServicesList;

    if(this.profileServicesList.length < this.profileDetails.fpoAdditionalDetails.fpoServices.length) {
      this.orgProfileService.postServiceLicense(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false),
      )
      .subscribe(
        res => this.removeSuccessHandler(res),
        err => this.removeErrorHandler(err)
      )
    } else {
      this.closeDialog.emit();
    }

  }

  /**
   * Prepares payload for removing services
   */
  prepareRemoveServicesPayload(index?: number) {

    this.servicesList.splice(index, 1);

    this.profileServicesList = this.servicesList;

  }
  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Prepares payload for adding services
   */
  private prepareAddServicesPayload(formValue): FPOServices[] {

    this.servicesList.push(formValue);
    return this.servicesList;
  }

  /**
   * Add services success handler
   */
  private addSuccessHandler(res: KalgudiUser) {
    this.servicesForm.reset();
  }

  /**
   * Remove services success handler
   */
  private removeSuccessHandler(res: KalgudiUser) {
    this.closeDialog.emit();
  }

  /**
   * Add services error handler
   */
  private addErrorHandler(err: Error) {

    // If service fails removing the newly added item from the list
    this.servicesList = this.profileServicesList;
    this.notification.showMessage('Unable to add services, please try again later!');
  }

  /**
   * Remove services error handler
   */
  private removeErrorHandler(err: Error) {
    this.notification.showMessage('Unable to remove services, please try again later!');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
