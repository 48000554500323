
import { AgGridOptions } from './ag-grid';
import { PartialData } from './core.model';
import { ApiResponseCommon } from './rest-api.model';

export interface RouteData {
  title?: string;
  description?: string;
  routerLink?: string;
  showBackButton?: boolean;
  animation?: boolean;
  preload?: boolean;
}

/**
 * Page stats object
 */
export interface PageStats {
  title: string;
  total: number;
  key?: string;
  clickAction?: boolean;
  filter?: PartialData;
  isRecommend?: boolean;
}

export declare type PageStatsList = Array<PageStats>;

export interface PageStatsFilter {
  key: string;
  value: string;
}

/**
 * Stats card type
 */
export enum StatsCardType {
  CHIP = 'CHIPS',
  CARD = 'CARDS'
}

export interface PageStatsData {
  results: PageStatsList;
  viewType: StatsCardType;
  selectedIndex?: number;
}

export interface PageListData {
  count: number;
  results: any[];
  columnDefs: any[];
}

export interface PageStatsApiResponse extends ApiResponseCommon {
  data: PageStatsData;
}

export interface PageListApiResponse extends ApiResponseCommon {
  data: AgGridOptions;
}

export interface StoreEnvironmentConfig {
  readonly appId?: string;
  readonly production: boolean;
  readonly development?: boolean;
  readonly awsAccessKey: string;
  readonly s3BucketUrl: string;
  readonly domain: string;
  readonly kalgudiDomain?: string;
  readonly consumerStoreDomain: string;
  readonly bizStoreDomain: string;
  readonly farmerStoreDomain: string;
  readonly restBaseUrl: string;
  readonly restBaseUrlV2: string;
  readonly googlePlacesApi?: string;
  readonly firebaseApi?: string;
  readonly [key: string]: any;
}
