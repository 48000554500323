import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnInit,
  Provider,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiProductVarietyAutocomplete } from '../../classes/product-variety-autocomplete';


// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductVarietyAutocompleteComponent),
  multi: true,
};


@Component({
  selector: 'kl-product-variety-autocomplete',
  templateUrl: './product-variety-autocomplete.component.html',
  styleUrls: ['./product-variety-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})
export class ProductVarietyAutocompleteComponent extends KalgudiProductVarietyAutocomplete implements OnInit {

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {
    super(injector, fb);
  }

  ngOnInit() { }

  protected onDestroyed(): void { }

}
