import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';

import { SendSurveyAnalyticsComponent } from './components/send-survey-analytics/send-survey-analytics.component';
import { SurveyAnalyticsChartComponent } from './components/survey-analytics-chart/survey-analytics-chart.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SendSurveyAnalyticsComponent,
    SurveyAnalyticsChartComponent,
  ],
  imports: [
    CommonModule,

    // Material module
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,

    ReactiveFormsModule,
    FormsModule,

    FlexLayoutModule,

    NgxChartsModule,

    // Kalgudi modules
    KalgudiButtonModule,
    KalgudiPipesModule,
    KalgudiNoContentModule,
    TranslateModule
  ],
  exports: [
    SendSurveyAnalyticsComponent,
    SurveyAnalyticsChartComponent
  ]
})
export class KalgudiSurveyAnalyticsModule { }
