import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreDefaultRouteConfigService } from '@app/config';
import { MENU_LIST } from '@app/constants';
import {
  DEFAULT_PROFILE_PIC,
  KALGUDI_PROFILE_BUSINESS_TYPE_NAMES,
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiTranslationService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiUser, UIMenu } from '@kalgudi/types';
import { ChangePasswordDialogService } from '@kalgudi/user-settings';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CommonAppService } from '../../../services/common-app.service';

@Component({
  selector: 'core-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent extends KalgudiDestroyable implements OnInit {

  @Output()
  menuClosed = new EventEmitter();

  mobileMenu: UIMenu[] = MENU_LIST;

  login: boolean;
  login$: Observable<boolean>;
  profile$: Observable<KalgudiUser>;
  profile: KalgudiUser;
  selectedLang: string;

  isMatchedProfile = false;

  domain: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;
  bizType = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;

  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    private kalgudiApp: KalgudiAppService,
    private router: Router,
    private appRouting: CoreDefaultRouteConfigService,
    private changePasswordDialog: ChangePasswordDialogService,
    private translate: KalgudiTranslationService,
    private commonService: CommonAppService
  ) {

    super();

    this.login$ = this.kalgudiApp.loginState$;
    this.profile$ = this.kalgudiApp.profile$;

    this.kalgudiApp.loginState$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(login => this.login = login);

    this.kalgudiApp.profile$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => this.profile = profile);

    this.domain = this.env.coreDomain;
  }

  ngOnInit() {

    this.selectedLang = this.translate.activeLanguage;

    this.kalgudiApp.login$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(_ => this.getValidateAdmins());
  }

  onDestroyed() { }

  close() {
    this.menuClosed.emit();
  }

  /**
   * Navigate to profile full view
   */
  navigateToProfilePage(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
  }

  /**
   * Translate to Eng
   */
  languageTranslation(language: string) {

    this.selectedLang = language;
    this.translate.updateActiveLanguage(language);
  }

  /**
   * Gets validate admins
   */
  getValidateAdmins() {

    if (this.kalgudiApp.loggedIn) {
      this.commonService.getValidateAdmins()
        .subscribe(
          res => {
            this.isMatchedProfile = res.status;
          }
        );
    }
  }

  /**
   * sign out
   */
  logout(): void {
    this.kalgudiApp.logout(true)
      .subscribe(_ => {

        if (!this.env.appId.includes('SHAKTIMAN') && !this.env.appId.includes('PRORISE_CORE')) {
          this.appRouting.toLogin();
        }
      });

    this.close();
  }


  /**
   * Opens change password dialog
   */
  openChangePasswordDialog(profileKey: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Change password',
      acceptButtonTitle: 'Change password',
      rejectButtonTitle: 'Cancel',
      data: {
        profileKey
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.changePasswordDialog.openChangePasswordDialog(dialogDetails, dialogConfig).subscribe();
  }

  /**
   * Navigating to fpo admin app
   */
  fpoAdminApp() {
    this.appRouting.toFpoAdminApp();
  }
}
