import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

import { HourSelectableFormControlComponent } from './hour-selectable-form-control/hour-selectable-form-control.component';



@NgModule({
  declarations: [HourSelectableFormControlComponent],
  imports: [
    CommonModule,

    // Material modules
    MatFormFieldModule,
    MatSelectModule,

    // Forms
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    HourSelectableFormControlComponent
  ]
})
export class KalgudiTimePickerModule { }
