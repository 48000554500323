import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KalgudiSpinnerService } from '@kalgudi/core';
import { KalgudiNotification } from '@kalgudi/core/config';
import { KalgudiDialogConfig } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class CoreNotificationsConfigService implements KalgudiNotification {

  private notification = new BehaviorSubject<KalgudiDialogConfig>(null);

  constructor(
    private spinner: KalgudiSpinnerService,
    private snackBar: MatSnackBar,
    // private translate: TranslateService,
  ) { }

  get $notification(): Observable<KalgudiDialogConfig> {
    return this.notification;
  }

  showMessage(msg: string, action?: string, duration: number = 5000): void {

    // this.notification.next({
    //   title: '',
    //   message: msg,
    //   acceptButtonTitle: action,
    //   rejectButtonTitle: ''
    // });

    // this.translate.get(msg)
    //   .pipe(take(1))
    //   .subscribe(val =>  this.snackBar.open(val, '', { duration }));

    this.snackBar.open(msg, 'X', {
      duration,
    });
  }

  /**
   * Displays a Sweet Alert popup in the app
   * @param options A list of Sweet Alert options - as to how the popup should be displayed.
   */
  async showSweetAlert(options: SweetAlertOptions):Promise<any> {
    const response =  await Swal.fire(options);
    return new Promise ((resolve, reject) => {
      resolve(response)
    });
  }

  showSpinner(blocking?: boolean): void {
    this.spinner.toggleSpinner(true);
  }

  hideSpinner(): void {
    this.spinner.toggleSpinner(false);
  }

}
