import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'core-share-update-page',
  templateUrl: './share-update-page.component.html',
  styleUrls: ['./share-update-page.component.scss']
})
export class ShareUpdatePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onPostCreated(event: any): void {

  }

}
