import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BottomSheetMenu, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-menu-bottom-sheet',
  templateUrl: './mobile-menu-bottom-sheet.component.html',
  styleUrls: ['./mobile-menu-bottom-sheet.component.scss']
})
export class MobileMenuBottomSheetComponent implements OnInit {


  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetMenu,
    private bottomSheetRef: MatBottomSheetRef<MobileMenuBottomSheetComponent>
  ) { }

  ngOnInit() { }

  close(menu?: UIMenu): void {
    this.bottomSheetRef.dismiss(menu);
  }

}
