import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-business-activities-dialog',
  templateUrl: './business-activities-dialog.component.html',
  styleUrls: ['./business-activities-dialog.component.scss']
})
export class BusinessActivitiesDialogComponent extends KalgudiDialog<BusinessActivitiesDialogComponent> implements OnInit {

  optionsList = ["option1", "option2", "option3"];
  profileKey: string;
  basicActivityFormDetails: any;
  pageId: any;
  isEditDialog: boolean;

  constructor(
    protected dialogRef: MatDialogRef<BusinessActivitiesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

    this.profileKey = this.data.data.profileKey;
    this.basicActivityFormDetails = this.data.data.basicActivityFormDetails;
    this.pageId = this.data.data.pageId;
    this.isEditDialog = this.data.data.isEditDialog;
  }

  ngOnInit(): void {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      data: null
    };
    this.dialogRef.close(result);
  }
  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
