import { Component, Input, OnInit } from '@angular/core';
import { ChartDirection, KalgudiChartItem } from '@kalgudi/types';

@Component({
  selector: 'kl-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit {

  @Input()
  stat: KalgudiChartItem;

  @Input()
  progressValue: number = 0;

  @Input()
  maxProgressValue: number = 100;

  /**
   * Shows or hides the progress status bar
   */
  @Input()
  showProgress: boolean;

  /**
   * `true` to disable animations, default set to `false`.
   */
  @Input()
  disableAnimation = false;

  @Input()
  statsDirection: ChartDirection;

  constructor() { }

  ngOnInit() {
  }

}
