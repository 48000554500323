import { InjectionToken } from '@angular/core';

import { DateFormatter } from './date-formatter';

export enum AgGridFormatters {
  AG_GRID_DATE_FORMATTER = 'AG_GRID_DATE_FORMATTER',
  AG_GRID_CURRENCY_FORMATTER = 'AG_GRID_CURRENCY_FORMATTER',
  AG_GRID_TRIM_SPECIAL_CHARACTER_FORMATTER = 'AG_GRID_TRIM_SPECIAL_CHARACTER_FORMATTER',
}

export const AG_GRID_DATE_FORMATTER = new InjectionToken<DateFormatter>('AG_GRID_DATE_FORMATTER');
