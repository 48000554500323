import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { UIMenu } from '@kalgudi/types';

import { KalgudiUserProfileHeader } from './kalgudi-user-profile-header';

@Component({
  selector: 'kl-user-profile-header',
  templateUrl: './kalgudi-user-profile-header.component.html',
  styleUrls: ['./kalgudi-user-profile-header.component.scss'],
})
export class KalgudiUserProfileHeaderComponent extends KalgudiUserProfileHeader implements OnInit,OnChanges {

  @Output()
  activatedTab = new EventEmitter<string>();

  @Input()
  selectedTabId: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;
  domain: string;
  showNumber: boolean;

  tabsList: UIMenu[] = [
    {
      id: 'ABOUT',
      selected: false,
      title: 'About'
    },
    {
      id: 'UPDATES',
      selected: false,
      title: 'Updates'
    },
    {
      id: 'TASKS',
      selected: false,
      title: 'Tasks'
    },
    // {
    //   id: 'PROFILE_LOGS',
    //   selected: false,
    //   title: 'Profile logs'
    // }
  ];

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    ) {

    super(injector);

    this.domain = this.env.coreDomain;

  }

  ngOnInit() {
    this.getLoginId();

  }

  ngOnChanges() {
    if(/^\d+$/.test(this.user.firstName)) {
      this.showNumber = true;
    }
    else {
      this.showNumber = false;
    }
  }

  /**
   * Emit the value of activated tab
   */
  toggleTab(activity: string) {
    this.selectedTabId = activity;
    this.activatedTab.emit(activity);
  }

  protected onDestroyed(): void { }


  /**
   * On profile pic update
   */
  protected onProfilePicUpdate(): void {}


}
