<div [formGroup]="commonInfoForm">
  <div fxLayout="column" fxLayoutGap="20px">
    <!-- About -->
    <div fxLayout="column">
      <mat-form-field appearance="outline" class="mat-form-field-sm">
        <mat-label translate>About</mat-label>
        <textarea matInput formControlName="pageDescription"></textarea>
      </mat-form-field>
    </div>
    <!-- /About -->

    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Address -->
      <div fxFlex="50%" fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Address</mat-label>
          <input matInput formControlName="address">
        </mat-form-field>
      </div>
      <!-- /Address -->

      <!-- Location -->
      <div class="disabled-cursor" fxFlex="50%" fxLayout="column" formGroupName="locationDetails">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Location</mat-label>
          <input class="disabled-cursor" matInput formControlName="locationLong">
        </mat-form-field>
      </div>
      <!-- /Location -->

    </div>

    <div fxLayout="row" fxLayoutGap="10px">
      <!-- Email -->
      <div fxFlex="50%" fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Email</mat-label>
          <input matInput formControlName="emailId">
          <mat-error class="mt-3" *ngIf="emailIdField?.errors" translate>Please mention valid email id</mat-error>
        </mat-form-field>
      </div>
      <!-- /Email -->

      <!-- Contact Number -->
      <div fxFlex="50%" fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Contact Number</mat-label>
          <input matInput formControlName="mobileNo">
          <mat-error class="mt-3" *ngIf="mobileNoField?.errors" translate>Up to 10 digits allowed</mat-error>
        </mat-form-field>
      </div>
      <!-- /Contact Number -->

    </div>
  </div>
</div>
