import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';

@Component({
  selector: 'kl-fpo-lists',
  templateUrl: './fpo-lists.component.html',
  styleUrls: ['./fpo-lists.component.scss']
})
export class FpoListsComponent implements OnInit {

  @Input()
  fpoList: any;

  @Output()
  closeFpoDialog = new EventEmitter();

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  fpoListData: any[] = [
    {
      img: "/assets/images/default-profile.jpeg",
      name: "Leanne Graham",
      location: "Ranga Reddy, Telangana IND",
      mobileNo: "1111111111",
      email: "Sincere@april.biz"
    },
    {
      img: "/assets/images/default-profile.jpeg",
      name: "Leanne Graham",
      location: "Ranga Reddy, Telangana IND",
      mobileNo: "1111111111",
      email: "Sincere@april.biz"
    },
    {
      img: "/assets/images/default-profile.jpeg",
      name: "Leanne Graham",
      location: "Ranga Reddy, Telangana IND",
      mobileNo: "1111111111",
      email: "Sincere@april.biz"
    },
    {
      img: "/assets/images/default-profile.jpeg",
      name: "Leanne Graham",
      location: "Ranga Reddy, Telangana IND",
      mobileNo: "1111111111",
      email: "Sincere@april.biz"
    },
    {
      img: "/assets/images/default-profile.jpeg",
      name: "Leanne Graham",
      location: "Ranga Reddy, Telangana IND",
      mobileNo: "1111111111",
      email: "Sincere@april.biz"
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Emitting for close dialog
   */
  closeFpoListDialog() {
    this.closeFpoDialog.emit();
  }
}
