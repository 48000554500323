<div
  id="notifications"
  class="my-2 cursor-pointer p-2 border d-flex rounded position-fixed"
  *ngIf="notificationActive">
  <ng-container *ngIf="(notification$ | async) as notification" (click)="openNotification(notification)">
    <div class="rounded-circle d-inline-block align-top">
      <img width="50" class="rounded-circle profile-pic" [src]="defaultNotificationImage">
    </div>
    <div class="d-inline-block ml-1 align-top mw-200">
      <h3 class="mt-0 text-truncate mb-2">{{ notification?.notification?.title }}</h3>
      <p class="mb-0 text-truncate text-secondary">{{ notification?.notification?.body }}</p>
    </div>
    <div class="position-absolute clear-button">
      <button mat-icon-button (click)="notificationActive = false">
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </ng-container>
</div>
