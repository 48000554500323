<div class="mt-3">

  <kl-analytics-row>
    <kl-page-analytics-stats fxFlex fxFlex.xs="48%"
      title="Members"
      [statsCount]="pageAnalytics?.totalMembers"
      matIcon="groups"
      matIconColor="mediumslateblue">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex fxFlex.xs="48%"
      title="Locations"
      [statsCount]="pageAnalytics?.totalLocations"
      matIcon="location_on"
      matIconColor="orangered">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex fxFlex.xs="48%"
      title="Products"
      [statsCount]="pageAnalytics?.totalProducts"
      matIcon="eco"
      matIconColor="green">
    </kl-page-analytics-stats>
  </kl-analytics-row>

  <div>
    <kl-coverage-stats
      [totalLocations]="pageAnalytics?.totalLocations"
      [totalMembers]="pageAnalytics?.totalMembers"
      [totalProducts]="pageAnalytics?.totalProducts"
      [locationStats]="pageLocationAnalytics"
      [memberStats]="pageMembersAnalytics"
      [productStats]="pageProductsAnalytics"></kl-coverage-stats>
  </div>

</div>
