<div class="user-member p-3">

  <div fxLayout="column" fxLayoutGap="10px">
    <span class="text-secondary main-header border-bottom pb-2" translate>Crop calendars you are member of</span>

    <div *ngFor="let userTasks of filteredMemberTasks | keyvalue">

      <!-- Main-header -->
      <div class="mb-2 text-truncate w-90" fxLayout fxLayoutAlign="space-between center">
          <!-- Crop calender name -->
          <div class="crop text-secondary">
            {{ userTasks?.value[0]?.projectTitle }}
          </div>
          <!-- /Crop calender name -->

        <!-- Start and End Date -->
        <div class="date text-secondary" fxLayout="row" *ngIf="userTasks?.value[0]?.projectTimeFrame?.startDate &&  userTasks?.value[0]?.projectTimeFrame?.endDate" fxLayoutGap="5px">
          {{ userTasks?.value[0]?.projectTimeFrame?.startDate | date: 'dd MMM, yy' }} - {{ userTasks?.value[0]?.projectTimeFrame?.endDate | date: 'dd MMM, yy' }}
        </div>
        <!-- /Start and End Date -->
      </div>
      <!-- /Main-header -->

      <ng-container *ngFor="let userMemberTasks of userTasks?.value">

        <!-- Member task card -->
        <div class="member-card cursor-pointer p-2 mb-1" fxLayout="column">
          <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="column" fxFlex="91%">
              <div class="title" fxLayout="row" fxLayoutAlign="start center">
                <span class="text-truncate">{{ userMemberTasks?.title }}</span>
              </div>
              <div class="time-span text-secondary" fxLayout="row" *ngIf="userMemberTasks?.timeFrame?.start &&  userMemberTasks?.timeFrame?.end" fxLayoutGap="5px">
                {{ userMemberTasks?.timeFrame?.start | date: 'dd MMM, yy' }} - {{ userMemberTasks?.timeFrame?.end | date: 'dd MMM, yy'}}
              </div>
            </div>
            <div fxFlex="9%" class="text-right">
              <mat-icon (click)="openTaskPage(userMemberTasks)" class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>
          <div>
            <span class="crop-name text-secondary">{{ userMemberTasks?.templateRefTitle }}</span>
          </div>
        </div>
        <!-- /Member task card -->
      </ng-container>

    </div>

    <ng-container *ngIf="!userMemberTasks?.length">
      <p class="m-0 p-0 text-center text-muted" translate>
        There is no tasks
      </p>
    </ng-container>
  </div>
</div>
