<div class="task-fullview-dialog">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="p-3 header-title text-capitalize mb-0" translate>{{ title }}</h1>
    <button class="close-dialog-btn outline-none mr-1" mat-icon-button (click)="cancel()"><mat-icon>clear</mat-icon></button>
  </div>

  <div class="px-3 pb-3 task-fullview overflow-auto">
    <kl-task-full-view (taskDeleted)="ok()" [taskId]="taskId" ></kl-task-full-view>
   </div>
</div>
