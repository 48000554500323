import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiProfileService } from '@kalgudi/profiles';
import { KalgudiDialogResult, KalgudiUser, KalgudiUserBasicDetails, KalgudiUsersMap } from '@kalgudi/types';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { ProgramStateService } from '../../../../services/program-state.service';
import { DigitalAssistanceService } from '../../services/digital-assistance.service';

@Component({
  selector: 'kl-enable-offline-mobile-dialog',
  templateUrl: './enable-offline-mobile-dialog.component.html',
  styleUrls: ['./enable-offline-mobile-dialog.component.scss']
})
export class EnableOfflineMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  selectedUsersMap: KalgudiUsersMap = {};
  pageId: string;
  private readonly localProfileSavedKey = 'pCache';

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    private programState: ProgramStateService,
    private daService: DigitalAssistanceService,
    private util: KalgudiUtilityService,
    private profileService: KalgudiProfileService
  ) {
    super(dialogRef);

    this.programState.id$
    .pipe(
      // takeUntil(this.destroyed$)
    )
    .subscribe(
      id => this.pageId = id
    );

  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    // const result: KalgudiDialogResult = {
    //   accepted: true,
    //   data: this.selectedUsersMap,
    // };
    this.save(this.selectedUsersMap);

    // this.dialogRef.close(result);

  }

  save(users) {

    const usersList = this.mapObjectsToArray(users);

    this.daService.saveFrequentAssistedMembers(this.pageId, usersList)
      .pipe(
        switchMap(u => this.fetchAllUsersProfiles(u))
      )
      .subscribe(
        res => {
          this.util.showMessage('User saved successfully for offline usage');

          const result: KalgudiDialogResult = {
            accepted: true,
            data: this.selectedUsersMap,
          };

          this.dialogRef.close(result);
        },
        err => this.util.showApiErrorMessage(err)
      )
  }

    /**
   * Maps kalgudi users hashmap to kalgudi user basic details array
   */
  private mapObjectsToArray(users: KalgudiUsersMap): KalgudiUserBasicDetails[] {

    const usersList = Object.values(users);

    // Remove extra profile pic url
    usersList.forEach((u: any) => {
      // delete u.profilePicURL;
      delete u.extraData;
    });

    return usersList as any;
  }

  /**
   * Fetches all user profile in background
   */
  private fetchAllUsersProfiles(users: KalgudiUserBasicDetails[]): Observable<KalgudiUser[]> {

    const obs = users.filter(user => !this.isProfileSavedLocally(user.profileKey))
      .map(user =>
        this.profileService.fetchProfile(user.profileKey, true)
          .pipe(
            tap(_ => this.saveToLocal(user.profileKey))
          )
    );

    const val = obs.length > 0 ? obs : [of(null)];

    return forkJoin(val);
  }

  /**
   * Returns `true` if profile is saved locally otherwise `false`
   */
  private isProfileSavedLocally(userId: string): boolean {
    return this.getLocalCachedProfileKeys().includes(userId);
  }

  /**
   * Gets profile keys cached locally
   */
  private getLocalCachedProfileKeys(): string[] {
    return this.util.getFromLocal(this.localProfileSavedKey, false) || [];
  }

  private saveToLocal(userProfileKey: string): void {
    const profileList = this.getLocalCachedProfileKeys();

    const profileListSet = new Set(profileList);

    profileListSet.add(userProfileKey);

    this.util.setToLocal(this.localProfileSavedKey, Array.from(profileListSet), false);
  }
}
