import { Injectable } from '@angular/core';
import { KalgudiStock } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class StockStateService {

  stockDetails$: Observable<KalgudiStock>;

  reloadStocks$: Observable<string>;

  private stockDetailsSubject$ = new BehaviorSubject<KalgudiStock>(null);

  private reloadStocksSubject$ = new BehaviorSubject<any>(null);

  constructor() {

    this.stockDetails$ = this.stockDetailsSubject$.pipe(
      shareReplay(1),
    );

    this.reloadStocks$ = this.reloadStocksSubject$.pipe(
      shareReplay(1),
    );
  }

  reloadStocks(): void {
    this.reloadStocksSubject$.next('');
  }

  /**
   * Update the stock details
   * @param stockDetails
   */
  updateStockDetails(stockDetails: KalgudiStock): void {
    this.stockDetailsSubject$.next(stockDetails);
  }
}
