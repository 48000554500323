import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { KalgudiMobileDialogService } from '../../mobile-dialog/kalgudi-mobile-dialog.service';
import { MobileDialogConfig } from '../../mobile-dialog/models';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '../components/input-dialog/input-dialog.component';
import { DEFAULT_DIALOG_CONFIG } from '../constants';

/**
 * Handles showing of common dialogs.
 */
@Injectable()
export class KalgudiDialogsService {

  constructor(
    private dialog: MatDialog,
    private mobileDialogService: KalgudiMobileDialogService,
    private util: KalgudiUtilityService,
  ) { }



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens a dialog with set of configurations. Generic method to open any
   * dialog.
   */
  openDialog(
    component: Type<any>,
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    config.data = details;

    return this.dialog
      .open(component, config)
      .afterClosed()
      .pipe(take(1));
  }

  /**
   * Opens a custom mobile dialog with set of configurations. Generic method to open any
   * dialog.
   */
  openMobileDialog(
    component: Type<any>,
    config: MobileDialogConfig,
  ): Observable<KalgudiDialogResult> {

    config.data = this.util.clone(config);

    return this.mobileDialogService
      .open(component, config)
      .afterClosed$
      .pipe(take(1));
  }

  /**
   * Shows a confirm dialog box.
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showConfirm(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return this.openDialog(ConfirmDialogComponent, details, config);
  }

  /**
   * Shows an input dialog box. Returns an observable of `KalgudiDialogResult`.
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showInput(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return this.openDialog(InputDialogComponent, details, config);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
