import { Directive, EventEmitter, Injector, Input, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KalgudiAutocomplete } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { BaseProductAutocomplete, BaseProductTypes, StoreBaseProductBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ProductAutoCompleteService } from '../services/product-autocomplete.service';

/**
 * Defines logic to show product autocomplete.
 *
 * @author Pushpalatha mekala
 */
@Directive()
export abstract class KalgudiProductAutocomplete extends KalgudiAutocomplete<any> {

  @Input()
  storeType: string;

  @Input()
  productType: BaseProductTypes = BaseProductTypes.BASE_PRODUCT;

  @Input()
  baseProduct: StoreBaseProductBasicDetails;

  @Output()
  selectedStoreType = new EventEmitter<string>();

  searchType = new FormControl('');
  previousStoreType: string;

  private autoCompleteApi: ProductAutoCompleteService;
  private env: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder
  ) {

    super(fb);

    this.autoCompleteApi = this.injector.get(ProductAutoCompleteService);
    this.env             = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    this.placeholder = 'Search and select product';
    this.label       = 'Search and select product';

    // Initialize the autoCompleteForm
    this.autoCompleteForm = this.newAutoCompleteFormGroup;

    // Construct the prediction list fetching stream
    this.initAutocomplete();

    this.subscribeStoreTypeValueChanges();

  }

  protected onDestroyed(): void {

  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return this.autoCompleteForm.get('productName');
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      productId: [''],
      productName: [''],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Value mapper for the autocomplete, maps base product name field from the
   * BaseProductAutocomplete object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayProductName">
   * ```
   */
  displayWithFn(value: any): any {
    return value && value.productName ? value.productName : value;
  }

  /**
   * Subscribing to store type value changes
   */
  subscribeStoreTypeValueChanges() {
    this.searchType.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.autoCompleteForm.reset();
          this.selectedStoreType.emit(res);
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected streamApi(searchKeyword: string): Observable<StoreBaseProductBasicDetails[]> {

    // Choose the base product api to call based on its type
    const api = this.baseProductPredictionApi(searchKeyword)

    return api.pipe(
        map(predictions => this.mapProductDetails(predictions))
      );
  }

  /**
   * Gets, base product prediction with the specified search keyword
   */
  private baseProductPredictionApi(searchKeyword: string): Observable<BaseProductAutocomplete[]> {

    return this.autoCompleteApi.getProductsPredictions(searchKeyword, this.storeType, {}, 0, 20);

  }

  /**
   * Maps a list of google places prediction result to KalgudiLocation type list.
   */
  private mapProductDetails(predictions: any[]): any[] {

    return predictions.map(prediction => {
      return {
        VProductId: prediction.VProductId,
        VProductName: prediction.VProductName,
        productId: prediction.VProductId,
        productName: prediction.VProductName,
        nameInOtherLanguage: prediction.nameInOtherLanguage,
        // baseCategory: {
        //   id: prediction.baseCategory.id,
        //   value: prediction.baseCategory.value,
        //   // level3Count: prediction.baseCategory.level3Count,
        // },
        attachments: prediction.attachments ? prediction.attachments : [],
        // storeType: prediction.storeType ? prediction.storeType : 'KUBER',
        // manufacturerDetails: prediction.manufacturerDetails,
        // resellerDetails: prediction.resellerDetails
      };
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
