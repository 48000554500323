import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kl-soil-moisture-canvas-gauge',
  templateUrl: './soil-moisture-canvas-gauge.component.html',
  styleUrls: ['./soil-moisture-canvas-gauge.component.scss']
})
export class SoilMoistureCanvasGaugeComponent extends KalgudiDestroyable implements OnInit {

  // Took reference from below link
  // http://www.wayneparrott.com/add-a-realtime-gauge-to-your-angular-project/

  soilDepthList = [
    {index: 0, value: 'Depth average'},
    {index: 1, value: '0 - 10cm (0 - 4in)'},
    {index: 2, value: '10 - 40cm (4 - 16in)'},
    {index: 3, value: '0.4 - 1m (1 - 3ft)'},
    {index: 4, value: '1 - 2m (3 - 6ft)'}
  ];

  classNames = ['one', 'two', 'three', 'four', 'five'];

  @Input()
  depthProfile: any[];

  @Input()
  avgSoilMoisture: any;

  depthAvgForm = new FormControl('');

  // public value$: Observable<number>;
  selectedValue: any;
  selectedClass: string;

  constructor() {

    super();

    this.depthAvgForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {

        if (res.index === 0) {
          this.selectedValue = this.avgSoilMoisture;
          this.selectedClass = this.classNames[0];
        } else {
          this.selectedValue = this.depthProfile[res.index - 1];
          this.selectedClass = this.classNames[res.index];
        }

      });
  }

  ngOnInit() {

    // this.value$ = interval(1000).pipe(
    //   map(() => this.selectedValue * 100)
    // );

    this.depthAvgForm.patchValue(this.soilDepthList[0]);
  }

  onDestroyed(): void {}

}
