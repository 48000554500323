import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  ProfileBasicDetailsEditDialogComponent,
} from '../components/profile-basic-details-edit-dialog/profile-basic-details-edit-dialog.component';
import {
  ProfileBasicDetailsEditMobileDialogComponent,
} from '../components/profile-basic-details-edit-mobile-dialog/profile-basic-details-edit-mobile-dialog.component';
import {
  UserMobileNumberUpdateDialogComponent,
} from '../components/user-mobile-number-update-dialog/user-mobile-number-update-dialog.component';

@Injectable()
export class EditCommonProfileDetailsDialogService {

  constructor(
    private dialogsService: KalgudiDialogsService,
  ) { }


  /**
   * Shows Edit employee profile dialog
   */
  showEditEmployeeProfileDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update profile',
      acceptButtonTitle: 'Update profile',
      rejectButtonTitle: 'Cancel',
      data: { }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openEditEmployeeProfileDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(r => r.accepted),
      );
  }

  /**
   * Shows Edit login id dialog
   */
  showEditLoginIdDialog(loginId: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update login id',
      acceptButtonTitle: 'Update',
      rejectButtonTitle: 'Cancel',
      data: {
        oldLoginId: loginId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        oldLoginId: loginId
      }
    };

    return this.dialogsService.openDialog(UserMobileNumberUpdateDialogComponent, dialogDetails)
      .pipe(
        filter(r => r.accepted),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Shows the Edit employee profile web or mobile dialog
   */
  private openEditEmployeeProfileDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.dialogsService.openMobileDialog(ProfileBasicDetailsEditMobileDialogComponent, dialogConfig)
      : this.dialogsService.openDialog(ProfileBasicDetailsEditDialogComponent, dialogConfig, matDialogConfig);
  }

}
