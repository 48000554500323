import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonSearchBarModule, KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiChipsModule } from '@kalgudi/common/ui/chips';
import { KalgudiSlideToggleModule } from '@kalgudi/common/ui/slide-toggle';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ProgramListHeaderComponent } from './program-list-header/program-list-header.component';
import { ProgramListPageComponent } from './program-list-page/program-list-page.component';
import { ProgramListTableComponent } from './program-list-table/program-list-table.component';
import { ProgramListComponent } from './program-list/program-list.component';
import { ProgramTileComponent } from './program-tile/program-tile.component';
import { KalgudiProgramListApiService } from './services/kalgudi-program-list-api.service';
import { KalgudiProgramListService } from './services/kalgudi-program-list.service';
import { PageListStateService } from './services/page-list-state.service';
import { ProgramCloneDialogComponent } from './program-clone-dialog/program-clone-dialog.component';
import { ProgramCloneMobileDialogComponent } from './program-clone-mobile-dialog/program-clone-mobile-dialog.component';
import { ProgramCloneComponent } from './program-clone/program-clone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { KalgudiCreatePageService } from '../page-creation';

@NgModule({
  declarations: [
    ProgramTileComponent,
    ProgramListPageComponent,
    ProgramListHeaderComponent,
    ProgramListComponent,
    ProgramListTableComponent,
    ProgramCloneDialogComponent,
    ProgramCloneMobileDialogComponent,
    ProgramCloneComponent,
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    // Material
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatChipsModule,
    MatInputModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatCheckboxModule,

    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiChipsModule,
    KalgudiSlideToggleModule,
    CommonSearchBarModule,

    TranslateModule
  ],
  exports: [
    ProgramTileComponent,
    ProgramListPageComponent,
    ProgramListHeaderComponent,
    ProgramListComponent,
    ProgramListTableComponent
  ],
  entryComponents: [
    ProgramCloneDialogComponent,
    ProgramCloneMobileDialogComponent,
  ],
  providers: [
    KalgudiProgramListService,
    KalgudiProgramListApiService,
    PageListStateService,
    KalgudiCreatePageService
  ]
})
export class KalgudiProgramListModule { }
