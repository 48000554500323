import { Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { ATTACHMENT_TYPE_MAP } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class KalgudiAudioAttachmentFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  placeholder: string;

  @Input() disabled: boolean;

  audioUrl = new FormControl(null);

  /**
   * On change function binding reference for formControlName
   */
  onChange: (value) => {};

  /**
   * On touched function binding reference for formControlName
   */
  onTouched: () => {};

  constructor() {

    super();

    this.audioUrl.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => this.notifyValueChange()
    );
  }

  /**
   * Gets the latest changes and return back to the form control
   */
  notifyValueChange() {
    if (this.onChange) {
      this.onChange({
        url: this.audioUrl.value,
        context: '',
        msgType: ATTACHMENT_TYPE_MAP.AUDIO
      });
    }
  }

  /**
   * Writes the value to the form control
   * @param val
   */
  writeValue(val: any): void {
    this.audioUrl.patchValue(val);
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
