import { EventEmitter,Inject, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiFormValidators, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { IdValueMap, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { DatePipe } from '@angular/common';

@Directive()
export abstract class KalgudiFpoOverViewForm extends KalgudiDestroyable {

  @Output()
  closedForm = new EventEmitter();

  fpoOverViewForm: FormGroup;

  private fb: FormBuilder;

  promotingAgency: IdValueMap[] = [
    { id: 'NABARD', value: 'NABARD'},
    { id: 'SFAC', value: 'SFAC'},
    { id: 'NAFED', value: 'NAFED'},
    { id: 'STATE_GOVERNMENT_(AGRICULTURE/HORTICULTURE/ANIMAL_HUSBANDRY/FISHERIES)', value: 'State government (agriculture/horticulture/animal husbandry/fisheries)'},
    { id: 'OTHERS_:_SERP', value: 'others : SERP'},
  ];

  profileDetails: KalgudiUser;

  progress: boolean;

  panAttachmentFormControl = new FormControl(['']);
  gstAttachmentFormControl = new FormControl(['']);
  regCertAttachmentFormControl = new FormControl('');
  pesticideLicenseAttachmentFormControl = new FormControl(['']);
  seedLicenseAttachmentFormControl = new FormControl(['']);
  fertilizerLicenseAttachmentFormControl = new FormControl(['']);

  private profileService: KalgudiProfileService;
  protected util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private profileState: KalgudiProfileStateService;
  public datePipe: DatePipe;

  constructor(
    protected injector: Injector,
    protected imagePickerService: KalgudiImagePickerService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
  ) {

    super();

    this.fb                = this.injector.get(FormBuilder);
    this.util              = this.injector.get(KalgudiUtilityService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.profileService    = this.injector.get(KalgudiProfileService);
    this.notification      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.datePipe          = this.injector.get(DatePipe);

    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;

      });

    this.fpoOverViewForm = this.newFpoBasicDetails;

  }

  onDestroyed(): void {}

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  /**
   * Getter for fpo basic details
   */
  get fpoBasicDetails(): AbstractControl {
    return this.fpoOverViewForm.get('fpoBasicDetails');
  }

  /**
   * Getter for registration details
   */
  get businessTypeName(): AbstractControl {
    return this.fpoBasicDetails.get('businessTypeName');
  }

  /**
   * Getter for registration details
   */
  get registrationDetails(): AbstractControl {
    return this.fpoBasicDetails.get('registrations');
  }

  /**
   * Getter for registration certificate
   */
  get regCertificateAttachmentField(): AbstractControl {
    return this.fpoBasicDetails.get('registrationCertificate');
  }

  /**
   * Getter for pan number from fpo basic details field
   */
  get isPan(): AbstractControl {
    return this.registrationDetails.get('isPan');
  }

  /**
   * Getter for pan details
   */
  get panDetails(): AbstractControl {
    return this.registrationDetails.get('pan');
  }

  /**
   * Getter for gst number from fpo basic details field
   */
  get isGST(): AbstractControl {
    return this.registrationDetails.get('isGST');
  }

  /**
   * Getter for gst number
   */
  get gstNumber(): AbstractControl {
    return this.registrationDetails.get('GST');
  }


  /**
   * Getter for pesticide license from fpo basic details field
   */
  get isPesticideLicense(): AbstractControl {
    return this.registrationDetails.get('isPesticideLicense');
  }

  /**
   * Getter for fertlilzer license from fpo basic details field
   */
  get isFertilizerLicense(): AbstractControl {
    return this.registrationDetails.get('isFertilizerLicense');
  }

  /**
   * Getter for seed license from fpo basic details field
   */
  get isSeedLicense(): AbstractControl {
    return this.registrationDetails.get('isSeedLicense');
  }

  /**
   * Getter for pan field from fpo basic details field
   */
  get panField(): AbstractControl {
    return this.registrationDetails.get('pan');
  }

  /**
   * Getter for pan imageUrl field from fpo basic details field
   */
  get panAttachmentField(): AbstractControl {
    return this.registrationDetails.get('panAttachment');
  }

  /**
   * Getter for gst field from fpo basic details field
   */
  get gstField(): AbstractControl {
    return this.registrationDetails.get('GST');
  }
  /**
   * Getter for FSSAI field from fpo basic details field
   */
  // get isFssaiField(): AbstractControl {
  //   return this.registrationDetails.get('isFssai');
  // }

  /**
   * Getter for gst image field from fpo basic details field
   */
  get gstAttachmentField(): AbstractControl {
    return this.registrationDetails.get('gstAttachment');
  }

  /**
   * Getter for pesticide license field from fpo basic details field
   */
  get pesticideLicenseField(): AbstractControl {
    return this.registrationDetails.get('pesticideLicense');
  }

  /**
   * Getter for pesticide license image field from fpo basic details field
   */
  get pesticideLicenseAttachmentField(): AbstractControl {
    return this.registrationDetails.get('pesticideLicenseAttachment');
  }


  /**
   * Getter for fertilizer license field from fpo basic details field
   */
  get fertilizerLicenseField(): AbstractControl {
    return this.registrationDetails.get('fertilizerLicense');
  }

  /**
   * Getter for fertilizer license field from fpo basic details field
   */
  get fertilizerLicenseAttachmentField(): AbstractControl {
    return this.registrationDetails.get('fertilizerLicenseAttachment');
  }

  /**
   * Getter for seed license field from fpo basic details field
   */
  get seedLicenseField(): AbstractControl {
    return this.registrationDetails.get('seedLicense');
  }

  /**
   * Getter for seed license field from fpo basic details field
   */
  get seedLicenseAttachmentField(): AbstractControl {
    return this.registrationDetails.get('seedLicenseAttachment');
  }


  /**
   * Form group for fpo basic details form
   */
  private get newFpoBasicDetails(): FormGroup {

    return this.fb.group ({
      fpoBasicDetails: this.fb.group({
        firstName: [''],
        alternateMobileNo: ['', [...KalgudiFormValidators.mobileValidators]],
        altemailId: ['', [...KalgudiFormValidators.emailValidators]],
        businessTypeName: [''],
        location: [{}],

        farmers: [0],
        employees: [0],
        villages: [0],
        fpoAvgLand:  this.fb.group({
          unit: ['acre'],
          value: [0]
        }),
        mainActivity: [''],
        aboutMe: [''],
        assets: [],
        totalTurnOver: [''],
        dateOfBoardMeeting: [''],
        registrationNo: [''],
        registrationCertificate:[''],
        registeredUnder: [''],
        promotingAgency: [[]],
        registrations: this.fb.group({
          establishedSince: [''],
          isPan: [''],
          pan: [''],
          panAttachment: [''],
          isGST: [''],
          GST: [''],
          gstAttachment: [''],
          isPesticideLicense: [''],
          pesticideLicense: [''],
          pesticideLicenseAttachment: [''],
          isFertilizerLicense: [''],
          fertilizerLicense: [''],
          fertilizerLicenseAttachment: [''],
          isSeedLicense: [''],
          seedLicense: [''],
          seedLicenseAttachment: [''],
          // isFssai: [''],
        })
      })
    })
  }

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls api method to update profile
   */
  updateProfile() {

    // Here value(patch) string is converted into array

    if (this.env?.appId === "SHG_NET_APP") {
      const promotingAgencyValue = this.fpoOverViewForm.value.fpoBasicDetails.promotingAgency;
      const patchedValue = typeof promotingAgencyValue === 'string' && promotingAgencyValue.trim() !== '' ? [promotingAgencyValue] : [];
      this.fpoOverViewForm.get('fpoBasicDetails.promotingAgency').patchValue(patchedValue);
    }

    if (this.fpoOverViewForm.value.fpoBasicDetails.registrations.establishedSince === null) {
      this.fpoOverViewForm.value.fpoBasicDetails.registrations.establishedSince = "";
    }

    // Remove time-stamp from date picker
    if(this.env?.appId === "SHG_NET_APP" || this.env?.appId === "FPO_APP") {
    // Remove time-stamp from date picker
      let establishedDate: any = this.fpoOverViewForm.value.fpoBasicDetails.registrations.establishedSince;

      if(establishedDate) {
        let formattedDate = this.datePipe.transform(establishedDate, 'yyyy-MM-dd');
        this.fpoOverViewForm.value.fpoBasicDetails.registrations.establishedSince = formattedDate;
      }
    }

    // Checking wether formControl have any value in it
    this.patchValueIfUrlExists(this.panAttachmentFormControl, this.panAttachmentField);
    this.patchValueIfUrlExists(this.gstAttachmentFormControl, this.gstAttachmentField);

    this.regCertificateAttachmentField.patchValue(
      this.regCertAttachmentFormControl?.value[0] &&
        this.regCertAttachmentFormControl?.value[0]?.url
        ? this.regCertAttachmentFormControl.value[0].url : ''
    );

    this.patchValueIfUrlExists(this.pesticideLicenseAttachmentFormControl, this.pesticideLicenseAttachmentField);
    this.patchValueIfUrlExists(this.seedLicenseAttachmentFormControl, this.seedLicenseAttachmentField);
    this.patchValueIfUrlExists(this.fertilizerLicenseAttachmentFormControl, this.fertilizerLicenseAttachmentField);

    const payload = this.preparePayload(this.util.clone(this.fpoOverViewForm.value));

    this.progress = true;

    this.profileService.updateCommonBasicDetails(payload)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )
  }

  /**
   * Check whether formControl have any value
   * @param formControl
   * @param field
   */
  patchValueIfUrlExists(formControl, field) {
    if (formControl.value && formControl.value.length && formControl.value[0].url) {
      field.patchValue(formControl.value[0].url);
    }
  }

  /**
   * Cancel the edit of fpo profile
   */
  cancelEditingAboutYourself() {
    this.closedForm.emit();
  }

  /**
   * Subscribe to isPan field value changes
   */
  subscribeToIsPanFieldValueChanges() {
    this.isPan.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.panField.disable() : this.panField.enable();
        if( res === 'NO') {
          this.panField.reset();
        }
      }
    );
  }

  /**
   * Subscribe to isGst field value changes
   */
  subscribeToIsGSTFieldValueChanges() {
    this.isGST.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.gstField.disable() : this.gstField.enable();
        if( res === 'NO') {
          this.gstField.reset();
        }
      }
    );
  }

  /**
   * Subscribe to isPesticide license field value changes
   */
  subscribeToIsPesticideLicenseFieldValueChanges() {
    this.isPesticideLicense.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.pesticideLicenseField.disable() : this.pesticideLicenseField.enable();
        if( res === 'NO') {
          this.pesticideLicenseField.reset();
        }
      }
    );
  }

  /**
   * Subscribe to isFertilizer license field value changes
   */
  subscribeToIsFertilizerLicenseFieldValueChanges() {
    this.isFertilizerLicense.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.fertilizerLicenseField.disable() : this.fertilizerLicenseField.enable();
        if( res === 'NO') {
          this.fertilizerLicenseField.reset();
        }
      }
    );
  }

  /**
   * Subscribe to isSeed license field value changes
   */
  subscribeToIsSeedLicenseFieldValueChanges() {
    this.isSeedLicense.valueChanges
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        res === 'NO' ? this.seedLicenseField.disable() : this.seedLicenseField.enable();
        if( res === 'NO') {
          this.seedLicenseField.reset();
        }
      }
    );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Prepare payload for update profile
   */
  private preparePayload(formValue: any) {

    formValue.fpoBasicDetails.assets = formValue.fpoBasicDetails.assets ? (Array.isArray(formValue.fpoBasicDetails.assets) ?
      formValue.fpoBasicDetails.assets :
      [formValue.fpoBasicDetails.assets]) : [];

    const payload = this.util.clone({
      ...formValue
    });

    return payload;
  }

  /**
   * Update profile success handler
   */
  private addSuccessHandler(res) {
    this.closedForm.emit();
    this.notification.showMessage('Updated profile info');
  }

  /**
   * Update profile error handler
   */
  private addErrorHandler(err: Error) {
    this.notification.showMessage('Unable to update profile, please try again later!');
  }

  /**
   * Removing the attached image of pan
   */
  removeImage(formControl: any) {
    formControl.patchValue('');
  }

  /**
   * Remove registration certificate attachment
   */
  removeRegCertImage() {
    this.regCertAttachmentFormControl.patchValue('');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}