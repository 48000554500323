import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonList,
  IdValueMap,
  KalgudiPageDetails,
  KalgudiSurveyDetails,
  PageType,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PAGE_TYPES } from '../modules/page-creation';


/**
 * Page management Api services definition
 */
@Injectable()
export class KalgudiPageApiService {

  /**
   * `v2/pages`
   */
  private readonly API_PAGE_BASE = `${this.env.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_TAGS = `${this.API_PAGE_BASE}/tags`;


  /**
   * `v2/pages`
   */
  private readonly API_PAGE_SEARCH = `${this.API_PAGE_BASE}/search`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_VIEW = `${this.API_PAGE_BASE}/:pageId`;

  /**
   * `v2/social/surveys/:surveyId`
   */
  private readonly API_SURVEY_VIEW = `${this.env.restBaseUrlV2}/social/surveys/:surveyId`;

  /**
   * `v2/social/surveys/:surveyId`
   */
  private readonly API_FETCH_LOCATIONS = `${this.env.restBaseUrlV2}/pages/:pageId/get-locations`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------



  /**
   * Calls Kalgudi api to get pages
   *
   * @param pageId Unique id of entity
   */
  searchPages(keyword: string, mode: string = '',  pageType = ''): Observable<any> {

    const url = this.API_PAGE_SEARCH;

    const params = {
      mode,
      pageType,
      keyword
    };

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPage(pageId: string): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Api definition for page details updation.
   */
  updatePageDetails(pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageDetails.pageId);

    return this.httpClient.put<ApiResponseCommon>(url, pageDetails)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response to KalgudiPageDetails type
        map(res => res.data)
      );
  }

  /**
   * Api definition for delete dialog box.
   */
  deletePage(pageId: string): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageId);

    return this.httpClient.delete<ApiResponseCommon>(url)
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPageTags(pageType: PageType, baseProductId: string = ''): Observable<IdValueMap[]> {

    const params = {
      pageType,
      baseProductId,
    };

    if(pageType === PAGE_TYPES.TRAINING) {
      params['appType'] = 'fpo';
    }

    return this.httpClient.get<ApiResponseCommonList>(this.API_PAGE_TAGS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data ? res.data.results : []),
      );
  }

  /**
   * Calls Kalgudi api to fetch survey details
   *
   * @param surveyId Unique id of survey
   */
  getSurvey(surveyId: string): Observable<KalgudiSurveyDetails> {


    const url = this.API_SURVEY_VIEW.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data.survey),
      );
  }

  /**
   * Fetch all locations
   * @param pageId
   * @returns
   */
  fetchAllLocations(pageId: string) {

    const url = this.API_FETCH_LOCATIONS.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
