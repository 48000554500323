import { Component, OnInit } from '@angular/core';
import { KalgudiMobileDialogRef } from '@kalgudi/common';
import { KalgudiQaStreamStateService } from '@kalgudi/social';
import { ProgramQaFullDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-program-qa-form',
  templateUrl: './mobile-program-qa-form.component.html',
  styleUrls: ['./mobile-program-qa-form.component.scss']
})
export class MobileProgramQaFormComponent implements OnInit {

  constructor(
    protected qaStreamState: KalgudiQaStreamStateService,
    private dialogRef: KalgudiMobileDialogRef
  ) { }

  ngOnInit() {
  }

  onPostCreated(event: any): void {
    this.pushItemToHomeStream(event);
    this.dialogRef.close();
  }

  /**
   * Pushes latest posted item to the stream
   * @param val
   */
  private pushItemToHomeStream(val: ProgramQaFullDetails): void {
    this.qaStreamState.unshiftToStream(val);
  }

}
