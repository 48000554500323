import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { kalgudiAnimations, KalgudiAppService, KalgudiUsersService, KalgudiUtilityService } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';
import { KalgudiReportAbuseService, KalgudiSocialRouteConfig } from '@kalgudi/social';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment, ShareQaFullDetails } from '@kalgudi/types';

import { KalgudiQaActions } from '../../kalgudi-qa-actions';
import { KalgudiQaService } from '../../services/kalgudi-qa.service';

@Component({
  selector: 'kl-qa-tile',
  templateUrl: './qa-tile.component.html',
  styleUrls: ['./qa-tile.component.scss'],
  animations: kalgudiAnimations,
})
export class QaTileComponent extends KalgudiQaActions<ShareQaFullDetails> implements OnInit, OnChanges {

  @Input()
  fullView: boolean;

  @Input()
  animationDelay = 0;

  @Input()
  url: string;

  @Input()
  showMatMenu: boolean;

  @Input()
  memberRole: string;

  @Output()
  openQaFullView = new EventEmitter<any>();

  showForm: boolean;

  tileAnimationState: 'init' | 'deleted';

  qaUrl: string;
  appId: string;

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected usersService: KalgudiUsersService,
    protected qaService: KalgudiQaService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) {
    super(
      injector,
      notifications,
      kalgudiDialogs,
      kalgudiApp,
      usersService,
      qaService,
      reportAbuse,
      util,
    );

    this.appId = this.env.appId;
  }

  ngOnInit() {
    this.tileAnimationState = 'init';

    this.qaUrl =this.url ? this.url.replace(':questionId', this.postDetails.questionId) : '';
  }

  /**
   * Called before any other lifecycle hook. Use it to inject dependencies,
   * but avoid any serious work here.
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.postDetails && this.postDetails) {
      this.initGoogleLangTranslator();
    }
  }

  /**
   * Navigates to QA full view
   */
  qaFullView(): void {
    this.appRouting.toSocialQaFullview({questionId: this.postDetails.questionId});
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   *
   * @param res
   */
  onPostLike(shareDetails: ShareQaFullDetails): void {
    // console.log('like res', res);
    // this.liked.emit();
  }

  onPostCommented(shareDetails: ShareQaFullDetails): void {
    // this.commented.emit();
  }

  openFullview(imageUrl: string): void {
    const images: Attachment[] = [{url: imageUrl, msgType: 'IMAGE'}];

    this.kalgudiPhotoswipe.open(images, this.env.domain, 0).subscribe();
  }

  protected onPostDeleted(): void {
    this.tileAnimationState = 'deleted';
  }


}
