<div fxLayout="row" class="download-form" fxLayoutGap="8px">
  <mat-form-field appearance="outline" class="mat-form-field-sm email-field">
    <mat-label>Email</mat-label>
    <input matInput [formControl]="emailId" placeholder='Your email id'/>
    <mat-error class="mt-3">Please enter a valid email address</mat-error>
  </mat-form-field>

  <kl-flat-button
    class="submit-btn"
    [type]="'submit'"
    label="Submit"
    [progress]="progress"
    [disabled]="!emailId.value || emailId?.invalid"
    (clicked)="getLandData()">
  </kl-flat-button>
</div>
