<div class="program-list-page">
  <div class="w-100">

    <section id="programs-list">
      <div *ngIf="((stream$ | async) || true) as stream">

        <!-- Search engine for groups -->
        <mat-card class="group-search-fields my-3" [formGroup]="programTypeForm">
          <div class="w-100" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center flex-start" fxLayout.lt-sm="column"
            fxLayoutAlign.lt-sm="center end">
            <div [ngClass]="{'input-container':!isMobileDevice,'w-100':isMobileDevice}">
              <div fxLayout="row" fxLayoutGap="10px" [ngClass]="{'mr-1':isMobileDevice}">
                <mat-form-field appearance="outline" class="" [ngClass]="{'w-50':isMobileDevice,'w-75':!isMobileDevice}">
                  <input matInput cdkFocusInitial [placeholder]="'Search Groups...' | translate" formControlName="keyword">
                </mat-form-field>

                <!-- Dropdown for program types -->
                <mat-form-field appearance="outline" class="w-50">
                  <mat-label translate>Program Type</mat-label>
                  <mat-select formControlName="includePageTypes" multiple>
                    <mat-option *ngFor="let program of programType" [value]="program?.id"> {{ program?.title | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <!--/ Dropdown for program types -->
              </div>
              <section class="mt-1" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="start center"
                fxLayoutGap="2rem" fxLayoutGap.xs="1rem">
                <!-- Checklist for specificity in groups searching -->
                <span class="checkbox-options"><mat-checkbox formControlName="all" [checked]="true">{{ 'ALL' | translate }}</mat-checkbox></span>
                <span class="checkbox-options"><mat-checkbox formControlName="groupTitle">{{ 'Group Title' | translate }}</mat-checkbox></span>
                <span class="checkbox-options"><mat-checkbox formControlName="groupDescription">{{ 'Group Description' | translate }}</mat-checkbox></span>
                <!--/ Checklist for specificity in groups searching -->
              </section>
            </div>

            <!-- Search button -->
            <div class="mt-1" fxLayout="row">
              <kl-flat-button class="buttons mr-2" [type]="'submit'" [label]="'Submit'" [disabled]="isDisableButton"
                (clicked)="getProgramTypeData()">
              </kl-flat-button>

              <kl-flat-button class="" [type]="'reset'" [label]="'Clear'" [disabled]="!isDisableButton"
                (clicked)="clearFilters()">
              </kl-flat-button>
            </div>
            <!--/ Search button -->
          </div>
        </mat-card>

        <!--/ Search engine for groups -->

        <!-- Loading icon  -->
        <div *ngIf="progress" fxLayout="column" fxLayoutAlign="center center">
          <span class="spinner-border spinner-border-lg text-muted" role="status"></span>
          <span class="text-muted mt-1 loading" translate>Loading groups...</span>
        </div>
        <!-- / Loading icon  -->

        <div *ngIf="!progress">
          <kl-program-list [pagesList]="stream" (pageSelected)="onPageSelected($event)"
            (showPageCloneDialog)="openPageCloneDialog($event)">
          </kl-program-list>
        </div>

        <div class="mt-3" *ngIf="paginator$ | async as paginator">
          <div fxLayout="row" fxLayoutAlign="center center">
            <ng-container *ngIf="!stream || stream?.length <= 0">
              <kl-no-content svgIcon="assets/svgs/page.svg" [text]="'You don\'t own or follow any page!' | translate"
                actionLink="Create your first page" [progressText]="'Loading your pages...' | translate" [progress]="paginator?.loading"
                (action)="openCreatePage()">
              </kl-no-content>
            </ng-container>
          </div>

          <ng-container *ngIf="stream?.length > 0 && (!progress)">
            <kl-load-more [noMoreText]="'No more pages to show!' | translate" [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems" (clicked)="nextPage()">
            </kl-load-more>
          </ng-container>
        </div>
      </div>
    </section>

    <div *ngIf="!hideFloatPlus">
      <kl-float-button (clicked)="openCreatePage()"></kl-float-button>
    </div>

  </div>
</div>
