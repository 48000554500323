import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiDialogsModule } from '../kalgudi-dialogs/kalgudi-dialogs.module';
import { KalgudiNoContentModule } from '../kalgudi-no-content/kalgudi-no-content.module';
import { KalgudiUsersModule } from '../kalgudi-users/kalgudi-users.module';
import { KalgudiLoadMoreModule } from '../load-more/load-more.module';
import { CommonSearchBarModule } from '../search/common-search-bar.module';
import { UserPickerComponent } from './components/user-picker/user-picker.component';
import { UsersPickerDialogComponent } from './components/users-picker-dialog/users-picker-dialog.component';
import {
  UsersPickerMobileDialogComponent,
} from './components/users-picker-mobile-dialog/users-picker-mobile-dialog.component';
import { KalgudiUsersPickerService } from './services/kalgudi-users-picker.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
import { KalgudiUserPickerStateService } from './services/kalgudi-user-picker-state.service';
import {MatRadioModule} from '@angular/material/radio';


@NgModule({
  declarations: [
    UserPickerComponent,
    UsersPickerDialogComponent,
    UsersPickerMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    CommonSearchBarModule,
    ReactiveFormsModule,
    FormsModule,

    // Material module
    MatButtonModule,
    MatIconModule,
    MatInputModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiDialogsModule,
    KalgudiButtonModule,
    KalgudiUsersModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatRippleModule,
    MatChipsModule,
    MatRadioModule,

    TranslateModule
  ],
  exports: [
    UserPickerComponent,
  ],
  entryComponents: [
    UsersPickerDialogComponent,
    UsersPickerMobileDialogComponent
  ],
  providers: [
    KalgudiUsersPickerService,
    KalgudiUserPickerStateService
  ]
})
export class KalgudiUserPickerModule { }
