import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { GooglePlacesKalgudiLocationMapService } from '@kalgudi/third-party/google-places';
import {
  KalgudiDealsWith,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiFarmerLand,
  KalgudiFarmingHistory,
  KalgudiUser,
  KalgudiUserFarmerEquipmentDetails,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { AddProductsDialogComponent } from '../components/add-products-dialog/add-products-dialog.component';
import {
  AddProductsMobileDialogComponent,
} from '../components/add-products-mobile-dialog/add-products-mobile-dialog.component';
import { KalgudiFarmerProfileApiService } from './kalgudi-farmer-profile-api.service';

@Injectable()
export class KalgudiFarmerProfileService {

  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private api: KalgudiFarmerProfileApiService,
    private util: KalgudiUtilityService,
    private profileService: KalgudiProfileService,
    private profileState: KalgudiProfileStateService,
    private dialogsService: KalgudiDialogsService,
    private mapApiLoader: MapsAPILoader,
    private googlePlacesLocationMapService: GooglePlacesKalgudiLocationMapService,
  ) {

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }

  }


  /**
   * Shows the add products web or mobile dialog
   */
  openAddProductsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddProductsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddProductsDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Calls api to post the product
   */
  postDealsWith(payload: KalgudiDealsWith): Observable<KalgudiUser> {

    const reqPayload = this.util.clone(payload);

    return this.api.postDealsWith(this.profileState.id, reqPayload)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }


  /**
   * Calls api to remove the product from deals with
   */
  removeDealsWith(productId: string): Observable<KalgudiUser> {

    return this.api.removeDealsWith(this.profileState.id, productId)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }

  /**
   * Calls api to post the land details
   */
  postLandDetails(payload: KalgudiFarmerLand): Observable<KalgudiUser> {

    const reqPayload = this.util.clone(payload);

    return this.api.postLandDetails(this.profileState.id, reqPayload)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }


  /**
   * Calls api to delete the land
   */
  deleteLand(landId: string): Observable<KalgudiUser> {

    return this.api.deleteLand(this.profileState.id, landId)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }



  /**
   * Calls api to get the land years
   */
  getLandYears(landId: string): Observable<KalgudiStreamData> {

    return this.api.getLandYears(this.profileState.id, landId);
  }


  /**
   * Calls api to get the farm history
   */
  getFarmHistory(landId: string, year: string, filters: PartialData = {}, assistedTo?: string): Observable<KalgudiStreamData> {

    return this.api.getFarmHistory(this.profileState.id, landId, year, filters, assistedTo);
  }


  /**
   * Calls api to delete the farm
   */
  deleteFarm(farmId: string): Observable<KalgudiUser> {

    return this.api.deleteFarm(this.profileState.id, farmId)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Call api method to add farming history
   */
  addFarmingHistory(payload: KalgudiFarmingHistory): Observable<KalgudiUser> {
   // const reqPayload = this.util.clone(payload);

   return this.api.addFarmingHistory(this.profileState.id, payload)
    .pipe(

      // Ensure profile locally
      switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
    );
  }

  getSatellite(landId: string, landType: string, profileKey: string): Observable<any> {

    return this.api.getSatellite(landId, landType, profileKey);
  }

  /**
   * Calls api to get the farm history seasons list
   */
  getFarmSeasonsList(pageId: string, year: string, assistedTo: string): Observable<KalgudiFarmingHistory[]> {

    return this.api.getFarmSeasonsList(this.profileState.id, pageId, year, assistedTo);
  }

  /**
   * Call api method to add farming history
   */
  addFarmingSeason(payload: any): Observable<any> {

    return this.api.addFarmingSeason(this.profileState.id, payload);
  }

  /**
   * Get farmer crops
   */
  getFarmerProducts(extraParams: PartialData = {}): Observable<KalgudiDealsWith[]> {

    return this.api.getFarmerProducts(this.profileState.id,extraParams);
  }

  /**
   * Get land data
   */
  getLandData(payload, profileKey, landId): Observable<any> {
    return this.api.getLandData(payload, profileKey, landId);
  }

  /**
   * Saves a new membership to the current logged in user profile.
   */
  saveNewMembership(payload: any, isExisted: boolean): Observable<KalgudiUser> {

    return this.api.saveNewMembership(this.profileState.id, payload, isExisted)
    .pipe(

      // Ensure profile locally
      switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
    );
  }

  /**
   * Updates a membership to the current logged in user profile.
   */
  updateMembership(payload: any, isExisted: boolean, membershipId: string): Observable<KalgudiUser> {

    return this.api.updateMembership(this.profileState.id, payload, isExisted, membershipId)
    .pipe(

      // Ensure profile locally
      switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
    );
  }

  /**
   * Calls api to delete the membership
   */
  deleteMembership(membershipId: string): Observable<KalgudiUser> {

    return this.api.deleteMembership(this.profileState.id, membershipId)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Calls api to delete the crop
   */
  deleteCrop(profileKey:string, productId: string): Observable<KalgudiUser> {

    return this.api.deleteCrop(profileKey, productId)
  }

  /**
   * Saves a new equipment to the current logged in user profile.
   */
  saveNewEquipment(payload: KalgudiUserFarmerEquipmentDetails): Observable<KalgudiUser> {

    return this.api.saveNewEquipment(this.profileState.id, payload)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }

  /**
   * update equipment to the current logged in user profile.
   */
  updateEquipment(equipmentId: string, payload: KalgudiUserFarmerEquipmentDetails): Observable<KalgudiUser> {

    return this.api.updateEquipment(this.profileState.id, equipmentId, payload)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }

  /**
   * Calls api to delete the equipment
   */
  deleteEquipment(equipmentId: string): Observable<KalgudiUser> {

    return this.api.deleteEquipment(this.profileState.id, equipmentId)
      .pipe(

        // Ensure profile locally
        switchMap(res => this.profileService.updateProfileAndState(this.profileState.id))
      );
  }

  /**
   * Gets live stock
   */
  getLiveStock(): Observable<any> {

    return this.api.getLiveStock();
  }

  /**
   * Search location on google
   */
  searchOnGoogle(searchFields) {
    return new Observable(observer => {
      setTimeout(() => {
        this.mapApiLoader && this.mapApiLoader.load().then(
          () => {
            if (typeof google !== 'object' || typeof google.maps !== 'object') {
              observer.error(`google map is blocked by the client browser`);
              return;
            }
            const geoCoder = new google.maps.Geocoder();
            let params: any = { region: 'IN' };
            params.location = {
              lat: searchFields.latitude || '',
              lng: searchFields.longitude || ''
            }

            geoCoder.geocode(params, (responseList, status) => {
              if (status === 'OK') {
                const response = responseList[0];

                const placeDetails = {
                  googlePlaceDetails: response,
                  ...this.googlePlacesLocationMapService.mapToKalgudiLocation(response)
                };

                observer.next(placeDetails);
              } else {
                observer.error(`Unable to find the location, please try alternate option`);
              }
              observer.complete();
            });
          });
      });

    })
  }

}
