<mat-tab-group class="members-tabs" [(selectedIndex)]="activeTabIndex">

  <!-- Search members tab -->
  <mat-tab [label]="TABS.SEARCH.title | translate">
    <div class="dialog-container manage-members overflow-auto" fxLayout="column" *ngIf="activeTabIndex === TABS.SEARCH.index">
      <div id="search-members" class="h-100" fxLayout="column">

        <kl-program-search-members
          [showExtraFields]="showExtraFields"
          [pageId]="pageId"
          (selectedUsersList)="selectedUsersList($event)"
          (closeDialog)="closeDialog()"
          (onFollowersAdded)="resetStream()">
        </kl-program-search-members>
      </div>

    </div>
  </mat-tab>
  <!-- / Search members tab -->

  <!-- Bulk members tab -->
  <mat-tab [label]="TABS.BULK.title | translate">
    <div class="p-3 tabs-content" *ngIf="activeTabIndex === TABS.BULK.index">
      <kl-program-add-bulk-members (addedMembers)="closeDialog()"></kl-program-add-bulk-members>
    </div>
  </mat-tab>
  <!-- / Bulk members tab -->

  <!-- Bulk members tab -->
  <mat-tab [label]="TABS.NEW_USER.title | translate">
    <div class="p-3 tabs-content" *ngIf="activeTabIndex === TABS.NEW_USER.index">
      <kl-program-add-new-user (selectedUsersList)="selectedUsersList($event)"></kl-program-add-new-user>
    </div>
  </mat-tab>
  <!-- / Bulk members tab -->

</mat-tab-group>
