import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-load-more',
  template: `
  <div class="text-center">
    <div class="load-more m-auto">
      <kl-stroked-button class="load-more-btn" *ngIf="hasMoreItems"
        [label]="text | translate"
        [progress]="progress"
        [disabled]="!hasMoreItems"
        (clicked)="onClick($event)">
      </kl-stroked-button>
      <!-- <i class="fa fa-circle icon no-load-more-btn"
        *ngIf="!hasMoreItems && environment?.appId !== 'SAM_FPO'"
        fxLayout
        fxLayoutAlign="center center">
      </i> -->
      <div *ngIf="!hasMoreItems">
        <kl-stroked-button class="load-more-btn"
          [label]="noMoreText | translate"
          [disabled]="!hasMoreItems">
        </kl-stroked-button>
      </div>
    </div>
  </div>
  `,
  styles: [`
    .load-more {
      display:inline-block;
      line-height:1;
    }

    .icon {
      color: #c4cace;
    }
  `]
})
export class LoadMoreComponent implements OnInit {

  @Input()
  loadMoreText: string;

  @Input()
  loadingText: string;

  @Input()
  progress: boolean;

  @Input()
  noMoreText: string;

  @Input()
  hasMoreItems: boolean;

  @Output()
  clicked = new EventEmitter<any>();

  constructor(
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {
    this.loadMoreText = 'Load more';
    this.loadingText  = 'Loading...';
    this.noMoreText   = 'No more items';
    this.progress     = false;
  }

  ngOnInit() {
  }

  get text(): string {
    return this.hasMoreItems
      ? this.progress
        ? this.loadingText : this.loadMoreText
      : this.noMoreText;
  }

  onClick(event: any): void {
    this.clicked.next();
  }

}
