import { KeyValue } from '@angular/common';
import { Injector, Input, Directive, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import {
  KalgudiDialogResult,
  KalgudiPageMembersPickerDialogConfig,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import {
  KalgudiPagesMembersPickerService,
} from '../../../program-members-picker-dialog/services/kalgudi-pages-members-picker.service';

@Directive()
export abstract class SpecificMembersVisibility extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  pageId = '';

  @Input()
  shareFormFilterUsers: FormControl;

  @Input()
  showBulkUpload: boolean = false;

  @Output()
  verifyBulkUpload = new EventEmitter<any>();

  @Output()
  isExcelUploaded = new EventEmitter<boolean>();

  disabled = false;

  selectedProgramMembers = {};

  readonly sharedVisibilities = SHARE_TYPES;
  attachments = new FormControl();
  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.DEFAULT;

  readonly acceptedFileTypes: string[] = ['.csv', '.xls', '.xlsx'];

  private programDialogs: KalgudiPagesMembersPickerService;
  private kalgudiPageService: KalgudiPageService;

  constructor(
    protected injector: Injector,
  ) {
    super();

    this.programDialogs     = this.injector.get(KalgudiPagesMembersPickerService);
    this.kalgudiPageService = this.injector.get(KalgudiPageService);

    this.kalgudiPageService.pageDetails$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(pageDetails => this.pageId = pageDetails.pageId);

    this.subscribeToAttachmentValueChanges();
  }

  onDestroyed(): void { }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Opens dialog box to search and select program members
   */
  pickPageMembers(): void {

    this.openProgramMembersPicker()
      .pipe(
        takeUntil(this.destroyed$),

        // Take some actions if the dialog Okay button is clicked,
        // Neglecting cancel button click action
        filter(r => r.accepted),

        // tap(r => this.updateSelectedMembers(r.data))
      )
      .subscribe(r => {
        this.updateSelectedMembers(r.data);
        this.selectedProgramMembers = r.data;
      });
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  /**
   * Removes a selected user from the list of selected users. Removed
   * user won't get the shared update.
   */
  removeSelectedProgramMember(profileKey: string): void {

    // Get the current users map
    const selectedUsers = this.selectedProgramMembers;

    // Remove user from the list
    delete selectedUsers[profileKey];

    // Update the list
    this.updateSelectedMembers(selectedUsers);
  }

  /**
   * Remove attachment
   */
  removeAttachment(): void {
    this.attachments.patchValue([]);
  }

  /**
   * Event emit back to parent to verify excel sheet
   */
  verifyExcel() {
    this.verifyBulkUpload.emit(this.attachments.value);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Event handler for form control value update in the parent
   */
  private updateFormControlValue(item: any) {

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(item);
    this.onTouched();
  }

  /**
   * Updates the current selected program members whom to send the update.
   */
  private updateSelectedMembers(users: KalgudiUsersMap): void {
    // this.selectedMembers.next(users);
    // this.selectedMembers = users;

    // Patch member profile keys to the form
    this.patchSelectedMembers(this.getSelectedUsersProfileKeys(users));
  }

  /**
   * Patch selected members value to the form
   */
  private patchSelectedMembers(members: string[]): void {

    // Remove all values
    this.shareFormFilterUsers.reset();

    // Form array accepts form group or form control
    // Patching values directly to form array wont work.
    this.shareFormFilterUsers.patchValue(members);
    // members.forEach(r => {
    //   this.shareFormFilterUsers.push(new FormControl(r));
    // });
  }

  /**
   * Clears list of selected program members for targeting.
   */
  private clearSelectedMembers(): void {

    this.selectedProgramMembers = {};
  }

  /**
   * Transforms users map to array of profile keys
   */
  private getSelectedUsersProfileKeys(users: KalgudiUsersMap): string[] {

    return Object.values(users)
      .map(r => r.profileKey);
  }

  /**
   * Opens program members picker dialog box.
   */
  private openProgramMembersPicker(): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiPageMembersPickerDialogConfig = {
      title: 'Search and select program members',
      acceptButtonTitle: 'Select',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
      pageId: this.pageId,
      data: this.selectedProgramMembers
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kalgudi-dialog',
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: true,
      disableClose: true
    };

    return this.programDialogs.showMembersPicker(dialogDetails, dialogConfig);

  }

  /**
   * Subscribe to attachment form control value changes
   */
  private subscribeToAttachmentValueChanges() {
    this.attachments.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          res && res.length ? this.isExcelUploaded.emit(true) : this.isExcelUploaded.emit(false);
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
