import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { AgGridStatsTablePage, StatsTablePage } from '@kalgudi/ag-grid-lib/common';
import { fadeInAnimation, slideRightAnimation } from '@kalgudi/core';
import { PartialData } from '@kalgudi/types';

import { PageMembersService } from './page-members.service';

@Component({
  selector: 'core-page-members-ag-grid',
  templateUrl: './page-members-ag-grid.component.html',
  styleUrls: ['./page-members-ag-grid.component.scss'],
  providers: [
    {
      provide: StatsTablePage,
      useExisting: forwardRef(() => PageMembersService)
    }
  ],
  animations: [ fadeInAnimation, slideRightAnimation ]
})
export class PageMembersAgGridComponent extends AgGridStatsTablePage implements OnInit {

  @Input()
  pageId: string;

  get statsParams(): PartialData {

    return {
      pageId: this.pageId
    }
  }

  get listParams(): PartialData {

    return {
      pageId: this.pageId,
    }
  }

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.initPage();
  }

  /**
   * Click event handler for row
   */
  onRowClicked(row: any): void {

  }

}
