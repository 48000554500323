import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiCreateFarmer } from '../../classes/kalgudi-create-farmer-page';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-farmer-page-creation',
  templateUrl: './farmer-page-creation.component.html',
  styleUrls: ['./farmer-page-creation.component.scss']
})
export class FarmerPageCreationComponent extends KalgudiCreateFarmer implements OnInit {

  cropList: string[] = ['Sugarcane', 'Tobacco', 'Cotton', 'Jute', 'Mushroom', 'Onion', 'Tomato'];

  areaUnitList: string[] = ['Acres', 'Hectare', 'Yard', 'Square miles', 'Square feet'];

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
  ) {

    super(fb);
  }

  ngOnInit() {

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

}
