import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, UrlMetadata } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UrlMetaDataService {

  /**
   * @url `rest/v1/stream/meta`
   */
  private readonly API_SHARE_METADATA = `${this.env.restBaseUrl}/stream/meta`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Gets the url meta data
   * @param eventId
   * @param shareDetails
   */
  public fetchMetaData(url: string): Observable<UrlMetadata> {

    const params = { url };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_SHARE_METADATA, { params })
      .pipe(
        map((res: ApiResponseCommonV1) => this.mapUrlMetaData(res))
      );
  }

  /**
   * Maps meta data
   */
  private mapUrlMetaData(res: ApiResponseCommonV1): UrlMetadata    {

    if (res.code !== HttpStatusCode.OK || !res.data) {
      throw new ApiError(new Error(`Unable to fetch url meta data`));
    }

    return this.util.toJson<UrlMetadata>(res.data);
  }
}
