import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { environment } from '@app/env';
import { KalgudiAppConfiguration } from '@app/models';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'core-app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppFooterComponent implements OnInit {
  year: number;

  helpTimedOutCloser;

  contactTimedOutCloser;

  legalTimedOutCloser;

  appConfig: KalgudiAppConfiguration = environment.appConfig;

  constructor(@Inject(KL_ENV) public env: KalgudiEnvironmentConfig) { }

  ngOnInit() {
    this.year = new Date().getFullYear();

  }

  /**
   * Displays menu on hover
   */
  helpMouseEnter(trigger) {
    if (this.helpTimedOutCloser) {
      clearTimeout(this.helpTimedOutCloser);
    }
    trigger.openMenu();
  }

  contactMouseEnter(trigger) {
    if (this.contactTimedOutCloser) {
      clearTimeout(this.contactTimedOutCloser);
    }
    trigger.openMenu();
  }

  legalMouseEnter(trigger) {
    if (this.legalTimedOutCloser) {
      clearTimeout(this.legalTimedOutCloser);
    }
    trigger.openMenu();
  }

  /**
   * Disappears menu when mouse out
   */
  helpMouseLeave(trigger) {
    this.helpTimedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

  contactMouseLeave(trigger) {
    this.contactTimedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

  legalMouseLeave(trigger) {
    this.legalTimedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }

}
