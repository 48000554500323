import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { BottomSheetMenu, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-task-update-header',
  templateUrl: './task-update-header.component.html',
  styleUrls: ['./task-update-header.component.scss']
})
export class TaskUpdateHeaderComponent implements OnInit {

  @Output()
  deletePost = new EventEmitter<boolean>();

  @Output()
  report = new EventEmitter<boolean>();

  listMenu: BottomSheetMenu = {
    title: '',
    styleType: 'list',
    showCloseButton : true,
    menu: [
      {
        title: 'Report abuse',
        id: 'REPORT_ABUSE',
      },
      {
        title: 'Delete',
        id: 'DELETE',
      }
    ]
  };

  constructor(private bottomSheetService: KalgudiBottomSheetService) { }

  ngOnInit() {
  }

  /**
   * Emits an event to delete the update
   */
  deleteUpdate(): void {
    this.deletePost.emit();
  }

  /**
   * Emits an event to report on the post
   */
  reportAbuse(): void {
    this.report.emit();
  }

  /**
   * Open bottom sheet for delete and report abuse option
   */
  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'REPORT_ABUSE':
        this.reportAbuse();
        break;

      case 'DELETE':
        this.deleteUpdate();
        break;
    }
  }

}
