import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kl-task-form-updates',
  templateUrl: './task-form-updates.component.html',
  styleUrls: ['./task-form-updates.component.scss']
})
export class TaskFormUpdatesComponent implements OnInit {

  @Input()
  taskFormUpdates: any;

  formUpdateDetails: any[];

  isNumber(val): boolean { return typeof val === 'number'; }

  constructor() { }

  ngOnInit() {

    if(this.taskFormUpdates.activity.activityDetails) {
      let formValues;
      this.formUpdateDetails = Object.entries(this.taskFormUpdates.activity.activityDetails).map(([type, value]) => ({type, value}));

      this.formUpdateDetails.forEach(formDetails => {
        if(typeof formDetails.value === 'object' && formDetails.value !== null) {
          formValues = Object.entries(formDetails.value).map(([type, value]) => ({type, value}));

          formDetails.value = formValues;

          formDetails.value.forEach(element => {
            element.type = element.type.replace(/([a-z])([A-Z])/g, '$1 $2');
          });
        } else {
          formDetails.value = [
            {
              value: formDetails.value
            }
          ];
        }

      });
    }
  }
}
