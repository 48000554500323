import { AfterViewInit, Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUsersService, KalgudiUtilityService, KalgudiStreamData, KalgudiAppService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiUsersSearchService } from '../../../kalgudi-users/services/kalgudi-users-search.service';
import { KalgudiUserPicker } from '../../classes/kalgudi-user-picker';
import { Observable, EMPTY } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { KalgudiUsersPickerService } from '../../services/kalgudi-users-picker.service';
import { FormBuilder } from "@angular/forms";
import { KalgudiUserPickerStateService } from '../../services/kalgudi-user-picker-state.service';

/**
 * General user picker to pick any Kalgudi User. Returns a map of
 * of selected users.
 *
 * @author Pankaj Prakash
 *
 * @example
 * <kl-user-picker
 *  [multiSelect]="true"
 *  [(selectedUsersMap)]="selectedUsersMap">
 * </kl-user-picker>
 */
@Component({
  selector: 'kl-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['../../../picker-common.scss', './user-picker.component.scss'],
})
export class UserPickerComponent extends KalgudiUserPicker implements OnInit, AfterViewInit {

  readonly maxPageLimit = 20;

  @Input()
  searchPlaceHolder = 'Type to search users';

  @Input()
  showExtraFields: boolean = false;

  selectedOption: string = 'searchUsers';
  locationValue: string = '';
  cropValue: string = '';
  searchUsers: string = '';

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected usersSearch: KalgudiUsersSearchService,
    protected usersService: KalgudiUsersService,
    protected userPickerService: KalgudiUsersPickerService,
    protected kalgudiApp: KalgudiAppService,
    // protected programState: ProgramStateService
    protected fb: FormBuilder,
    protected injector: Injector,
    protected kalgudiUserPickerStateService:KalgudiUserPickerStateService,
  ) {

    // Wake up my parent
    super(notification, util, usersSearch, usersService, userPickerService, kalgudiApp, fb, injector, kalgudiUserPickerStateService) ;
    this.pageLimit = this.maxPageLimit;
  }

  ngOnInit() {
    this.locationValueChanges();
    this.cropValueChanges();
  }



  ngAfterViewInit(): void {
    this.initStream();
  }

  protected onDestroyed(): void { }

  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (searchKeyword) {
      return this.usersSearch.searchKalgudiUsers(searchKeyword, offset, limit, {}, this.groupId)
      .pipe(
        map((res) => {
          // Transform complete users details to user basic details object
          res.items = res.items.map((user) =>
            this.usersService.getBasicProfile(user)
          ) as any;

          return res;
        })
      );
    } else {
      return EMPTY;
    }
  }

  /**
   * Executes when the radio button selection changes.
   * Resets search parameters, clears selected location and crop values,
   * deselects all items, and updates form control arrays for locations and crops.
   * Resets the target count to an empty value.
   */
  onRadioChange() {
    this.resetSearch();
    this.locationValue = '';
    this.cropValue = '';
    this.deSelectAll();
    this.locationArray.patchValue([]);
    this.cropArray.patchValue([]);
    this.targetCount = '';
  }

}
