import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';
import { KalgudiSurveyQuestion, KalgudiSurveyQuestionTypes } from '@kalgudi/types';

import { surveyQuestionTypeLabelMap } from '../../../../constants';

@Component({
  selector: 'kl-survey-questions-list',
  templateUrl: './survey-questions-list.component.html',
  styleUrls: ['./survey-questions-list.component.scss'],
  animations: [ floatTopAnimation ]
})
export class SurveyQuestionsListComponent implements OnInit {

  @Input()
  questions: KalgudiSurveyQuestion[];

  @Output()
  removeQuestion = new EventEmitter<string>();

  readonly questionTitleMap = surveyQuestionTypeLabelMap;
  readonly questionTypes = KalgudiSurveyQuestionTypes;

  constructor() { }

  ngOnInit() {
  }

  /**
   * Emits an event back to delete a question
   */
  onQuestionRemoveClicked(questionId: string): void {
    this.removeQuestion.emit(questionId);
  }
}
