import { Injector, Input, Directive } from '@angular/core';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { AddProjectMembersService } from '@kalgudi/project-manager';
import { BulkMembersAdditionResponse, PartialData } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class KalgudiPageProjectBulkMembers extends KalgudiDestroyable {

  @Input()
  projectId: string;

  @Input()
  pageId: string;

  private notification: KalgudiNotification;
  private kalgudiStatusDialogService: KalgudiStatusDialogService;
  private addProjectMembersService: AddProjectMembersService;

  constructor(protected injector: Injector) {

    super();

    this.notification               = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiStatusDialogService = this.injector.get(KalgudiStatusDialogService);
    this.addProjectMembersService   = this.injector.get(AddProjectMembersService);
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Extra filters to add bulk members, In this case adding bulk members under the page
   */
  get filters(): PartialData {
    return {
      pageId: this.pageId
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls the api to add bulk project members
   */
  addBulkMembers(users: string[]) {

    this.notification.showSpinner();

    this.addProjectMembersService.addBulkMembers(this.projectId, users, this.filters)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onAddMemberError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);

  }

  /**
   * Event handler for adding members API errors.
   */
  protected onAddMemberError(err: Error): void {
    this.notification.showMessage(err.message);
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
