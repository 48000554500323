<div class="container profile-views my-3" fxLayout="column" *ngIf="(stream$ | async) as stream">

  <mat-card class="px-3 py-1" fxHide.xs>

    <div class="mb-2">
      <kl-breadcrumbs></kl-breadcrumbs>
    </div>

    <div fxLayout="column">
      <div fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="30px">
        <div [@scaleOut]>
          <mat-icon class="views-icon">people</mat-icon>
        </div>
        <div [@slideRight] class="ml-1 mr-3">
          <h4 class="m-0" translate>Profile views</h4>
        </div>
      </div>

    </div>
  </mat-card>

  <!-- profile views list -->
  <div [ngClass]="{'mt-3': !isMobileDevice}">
    <div fxLayout="row wrap" fxLayoutGap="2% grid">
      <div fxFlex="20%" fxFlex.xs="50%" *ngFor="let view of stream; let i = index">
        <div class="mt-3" class="cursor-pointer view-tile">
          <kl-profile-view-tile [author]="view" [avatarSize]="isMobileDevice ? 'md': 'lg'" >
          </kl-profile-view-tile>
        </div>
      </div>
    </div>
  </div>
  <!-- /profile views list -->

  <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

    <ng-container *ngIf="!stream || stream?.length <= 0">
      <kl-no-content
        matIcon="sentiment_dissatisfied"
        text="No one viewed your profile yet!"
        progressText="Loading profile views..."
        [progress]="paginator?.loading">
      </kl-no-content>
    </ng-container>

    <!-- Load more -->
    <div class="mt-3" *ngIf="paginator && stream?.length">
      <kl-load-more
        noMoreText="No more views"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </div>
    <!-- /Load more -->
  </div>

</div>
