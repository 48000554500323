import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment, AVAILABLE_STORES } from '@kalgudi/types';

import { actionMessageLookup, orderImages } from '../../constants/tracking-icons';

@Component({
  selector: 'kl-traceability-fulfillment',
  templateUrl: './traceability-fulfillment.component.html',
  styleUrls: ['./traceability-fulfillment.component.scss']
})
export class TraceabilityFulfillmentComponent implements OnInit , OnChanges {

  @Input()
  fulFillmentDetails: any;

  @Input()
  trackingDetails: any;

  @Input()
  fullFillmentCenterBasedOnStore: any;

  orderImages = orderImages;

  actionMessageLookup = actionMessageLookup;

  traceabilityDetails = {
    "name": 'Fulfillment',
    "image": 'assets/images/fulfillment.png',

    commonDetails : {
      breadCrumb: 'Manufacture / Producers / Farmer',
      location: 'Guntur, Andhra pradesh',
      subImage: "assets/images/fulfillment-center.jpg"
    }
  };

  fulfilment = [
    {
      icon: 'assets/images/assign-stock.png',
      title: 'Assign the stock',
      steps: [
        {
          time: '09:00 AM',
          date: '28 Nov 20',
          details: 'Availability checked by',
          checkedBy: 'Ankaraju'
        },
        {
          time: '09:00 AM',
          date: '28 Nov 20',
          details: 'Availability accepted by',
          acceptedBy: 'Ankaraju'
        }
      ]
    },
    {
      icon: 'assets/images/quality-control.png',
      title: 'Quality control',
      steps: [
        {
          time: '09:00 AM',
          date: '28 Nov 20',
          details: 'QC passed and packing accepted by',
          acceptedBy: 'Ankaraju'
        }
      ]
    },
    {
      icon: 'assets/images/packing.png',
      title: 'Packing',
      steps: [
        {
          time: '09:00 AM',
          date: '28 Nov 20',
          details: 'Packing done and transport accepted by',
          acceptedBy: 'Ankaraju'
        }
      ]
    },
    {
      icon: 'assets/images/transportation.png',
      title: 'Transportation to delivery point',
      steps: [
        {
          time: '09:00 AM',
          date: '28 Nov 20',
          details: 'its take 12 hr to reah delivery point',
        }
      ]
    },
    {
      icon: 'assets/images/delivery-out.png',
      title: 'Delivery Out',
    },
    {
      icon: 'assets/images/delivery-door-step.png',
      title: 'Delivered to your door step',
    }
  ]

  outputStoreId = AVAILABLE_STORES.OUTPUTS;

  trackingDetailsList = [];

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(simpleChanges: SimpleChanges) {

    if(this.trackingDetails && this.trackingDetails.tracking.length && simpleChanges.trackingDetails) {

      if(this.environment.appId !== this.outputStoreId) {
        this.trackingDetails.tracking.forEach(async track => {
          if(!(track.currentState === 'ORD_RECEIVED' || track.currentState === 'ORD_INP')) {
            this.trackingDetailsList.push(track);
          }

        });
      }

      if(this.environment.appId === this.outputStoreId) {

        this.trackingDetailsList = this.trackingDetails.tracking;

      }
    }

  }

  /**
   * Opens image full view
   */
  openImageFullView(attachments: Attachment[], index?: number): void {
    this.kalgudiPhotoswipe.open(attachments, this.environment.domain, index).subscribe();
  }

}
