import { Inject, Injector, Input, Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUsersService, KalgudiUtilityService, LikeShareRequest } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiReportAbuseService, KalgudiSocialActions } from '@kalgudi/social';
import { ShareQaAnswerComment, ShareQaAnswerFull, ShareQaFullDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiQaService } from './services/kalgudi-qa.service';


@Directive()
export abstract class KalgudiAnswerAction<T extends LikeShareRequest> extends KalgudiSocialActions<T> {


  @Input()
  qa: ShareQaFullDetails;

  @Input()
  answer: ShareQaAnswerFull;

  socialCommentsForm: FormGroup;

  showComments = false;

  commentsList: ShareQaAnswerComment[] = [];

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected kalgudiApp: KalgudiAppService,
    protected usersService: KalgudiUsersService,
    protected qaService: KalgudiQaService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(
      notifications,
      kalgudiDialogs,
      kalgudiApp,
      usersService,
      reportAbuse,
      util,
      injector
    );

    this.socialCommentsForm = new FormGroup({
      text: new FormControl('')
    });

  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Fetches the comments list
   */
  fetchComments() {
    this.showComments = !this.showComments;

    if (this.showComments) {
      // Call api to share post
      this.getCommentsApi(this.answer.questionId, this.answer.answerId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.updateCommentsList(res),
        err => this.onPostActionApiError(err)
      );
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Calls API to get the comments list
   * @param questionId
   * @param answerId
   */
  protected getCommentsApi(questionId: string, answerId: string): Observable<ShareQaAnswerComment[]> {
    // Call api to share post
    return this.qaService.getComments(questionId, answerId)
    .pipe(

      // Map response to generic type
      map(r => r as any)
    );
  }

  /**
   * Calls API to post a like.
   */
  protected likePostApi(payload: LikeShareRequest): Observable<T> {

    // Call api to share post
    return this.qaService.likeAnswer(this.answer.questionId, this.answer.answerId, payload)
      .pipe(

        // Map response to generic type
        map(r => r as any)
      );
  }


  /**
   * Implement comment post api call
   */
  protected commentPostApi(payload: LikeShareRequest): Observable<T> {

    return this.qaService.commentAnswer(this.answer.questionId, this.answer.answerId, payload)
      .pipe(

        // Gets the latest comments
        switchMap(r => this.getCommentsApi(this.answer.questionId, this.answer.answerId)),

        // Updates the comments list
        tap(r => this.updateCommentsList(r)),

        // Map response to generic type
        map(r => r as any),
      );
  }
  /**
   * Prepares the like payload
   */
  protected getLikePayload(): LikeShareRequest {

    return {
      answerAuthorId: this.kalgudiApp.profileLocal.profileKey,
      answerId: this.answer.answerId,
      id: this.kalgudiApp.profileLocal.profileKey,
      name: this.kalgudiApp.profileLocal.firstName,
      questionId: this.answer.questionId,
      text: '',
      type: 'like'
    };
  }

  protected getCommentsPayload(): LikeShareRequest {

    return {
      answerAuthorId: this.kalgudiApp.profileLocal.profileKey,
      answerId: this.answer.answerId,
      id: this.kalgudiApp.profileLocal.profileKey,
      name: this.kalgudiApp.profileLocal.firstName,
      questionId: this.answer.questionId,
      text: this.commentField.value,
      type: 'comment'
    };
  }

  /**
   * Initializes the comments with the latest comments
   * @param comments
   */
  private updateCommentsList(comments: ShareQaAnswerComment[]): void {
    this.commentsList = comments;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
