import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-view-tile',
  templateUrl: './profile-view-tile.component.html',
  styleUrls: ['./profile-view-tile.component.scss']
})
export class ProfileViewTileComponent implements OnInit {

  @Input()
  author: KalgudiUserBasicDetails;

  @Input()
  avatarSize: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {
  }

  /**
   * Navigate to user profile full view
   *
   * @param profileKey
   */
  viewProfile(profileKey: string) {
    this.appRouting.toProfile({profileKey});
  }

}
