import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProfileAwardsService } from './kalgudi-profile-awards.service';
import { ProfileSectionAwards } from './profile-section-awards';

@Component({
  selector: 'kl-awards',
  templateUrl: './awards.component.html',
  styleUrls: ['./awards.component.scss'],
})
export class AwardsComponent extends ProfileSectionAwards implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileAwardsService,
  ) {

    super(notification, app, util, api);
  }

  ngOnInit() {
  }

  protected onDestroyed(): void { }

}
