import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';

/**
 * Base class definition for all Kalgudi project types.
 * `Calling initProjectForm() in child class is mandatory, it will initializes the form`
 */
@Directive()
export abstract class KalgudiCreateProjectType extends KalgudiDestroyable {

  @Input()
  projectForm: FormGroup;

  @Input()
  editing = false;

  @Output()
  projectFormChange = new EventEmitter<FormGroup>();

  protected fb: FormBuilder;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.fb = this.injector.get<FormBuilder>(FormBuilder);

  }

  /**
   * Form group for the specific project type
   */
  protected abstract get projectTypeForm(): FormGroup;

  /**
   * Initializes the project form
   */
  protected initProjectForm() {

    const form = this.projectTypeForm;

    form.patchValue(this.projectForm.value);

    this.projectForm = form;

    this.projectFormChange.emit(this.projectForm);
  }


}
