import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { LeftSidebarTabsComponent } from './left-sidebar-tabs.component';
import { PageRouterLinkActiveDirective } from './page-router-link-active.directive';


@NgModule({
  declarations: [
    LeftSidebarTabsComponent,
    PageRouterLinkActiveDirective,
  ],
  imports: [
    CommonModule,

    // Material modules
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    RouterModule,

    TranslateModule
  ],
  exports: [
    LeftSidebarTabsComponent,
  ]
})
export class LeftSidebarTabsModule { }
