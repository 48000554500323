import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class KalgudiBulkMembersAddition extends KalgudiDestroyable {

  @Output()
  loginIdsList = new EventEmitter<string[]>();

  addMembersForm: FormGroup;
  loginIdCount: number;

  private fb: FormBuilder;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {
    super();

    this.fb             = this.injector.get(FormBuilder);
    this.notification   = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.addMembersForm =  this.newAddMembersForm;
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Event emit back to parent with selected user ids for bulk member addition
   */
  addMembers() {
    // Get members payload
    const payload = this.getAddMembersPayload(this.addMembersForm.value);

    this.loginIdsList.emit(payload);

    this.addMembersForm.reset();
  }

  /**
   * Subscribes to loginIds form on value changes
   */
  subscribeToAddMembersFormOnValueChanges() {
    this.addMembersForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.getUserArrayCount(res);
        }
      );
  }

  /**
   * Mapping login ids count based on comma separated numbers or email ids
   * @param res
   */
  getUserArrayCount(res) {
    const array = res && res.loginIds ? res.loginIds.split(',').filter(function(entry) { return entry.trim() !== '' && entry.length != 0}) : [];
    this.loginIdCount = array && array.length && array[0] != '' ? array.length : 0;
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Construct and gets, the add members payload.
   */
  protected getAddMembersPayload(payload: any): string[] {

    payload.loginIds = payload.loginIds.split(',');

    if (payload.loginIds.length > 50) {
      const errorMessage = 'Unable to add members, ensure you adding max 50 members at a time';

      this.notification.showMessage(errorMessage);
      throw new Error(errorMessage);
    }

    return payload;
  }

  /**
   * Constructs a members form
   */
  private get newAddMembersForm(): FormGroup {
    return this.fb.group({
      loginIds: ['']
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
