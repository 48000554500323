import { Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IdValueMap } from '@kalgudi/types';

import { KalgudiShareTargetMembersService } from '../services/kalgudi-share-target-members.service';


/**
 * Defines functionality for program location range filter. It accepts
 * `countries`, `states` and `districts` array list and their respective
 * form arrays.
 */
@Directive()
export abstract class KalgudiShareRangeFilter {

  @Input()
  countries: IdValueMap[];

  @Input()
  countriesFormArray: FormArray;

  @Input()
  states: IdValueMap[];

  @Input()
  statesFormArray: FormArray;

  @Input()
  districts: IdValueMap[];

  @Input()
  districtsFormArray: FormArray;

  /**
   * Local search and add form group
   */
  formGroup: FormGroup;

  selectedRange: {
    value: string,
    country: IdValueMap,
    state?: IdValueMap,
    district?: IdValueMap
  }[] = [];


  constructor(
    protected subscriberMetaService: KalgudiShareTargetMembersService,
  ) {

    this.formGroup = new FormGroup({
      country: new FormControl('', [ Validators.required ]),
      state: new FormControl(''),
      district: new FormControl(''),
    });
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Get, current selected country form group
   */
  private get countryFormGroup(): AbstractControl {
    return this.formGroup.get('country');
  }

  /**
   * Get, current selected state form group
   */
  private get stateFormGroup(): AbstractControl {
    return this.formGroup.get('state');
  }

  /**
   * Get, current selected district form group
   */
  private get districtFormGroup(): AbstractControl {
    return this.formGroup.get('district');
  }

  /**
   * Gets, list of all selected countries id.
   */
  private get selectedCountries(): string[] {
    return this.countriesFormArray.value;
  }

  /**
   * Gets, list of all selected states id.
   */
  private get selectedStates(): string[] {
    return this.statesFormArray.value;
  }

  /**
   * Gets, list of all selected districts id.
   */
  private get selectedDistricts(): string[] {
    return this.districtsFormArray.value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Pushes the item to the form group array. It also updates the
   * form control to which the component is bind-ed.
   */
  selectItem(): void {

    // Get selected country, state and district
    const { country, state, district } = this.getSelectedItem();

    // Push the selected country, states and districts ids to the parent form group
    this.selectCountry(country);
    this.selectState(state);
    this.selectDistrict(district);

    // Push selected country, states and districts to the range selection
    this.pushRangeToSelectedItem(country, state, district);

    // Clear the current selection
    this.clearSelectedItem();
  }

  /**
   * Removes already selected item from the form array
   *
   * <TODO> Think of logic to remove range selection.
   */
  removeItem(index: number): void {

    // // Remove item from the local array
    // this.itemsFormArray.removeAt(index);

    // // Remove item from the parent
    // this.formArray.removeAt(index);

    this.selectedRange.splice(index, 1)

    this.countriesFormArray.removeAt(index),
    this.districtsFormArray.removeAt(index),
    this.statesFormArray.removeAt(index)
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Creates a new form group with the product details.
   */
  private newItemFormControl(product: IdValueMap): FormControl {
    return new FormControl(product.id);
  }

  /**
   * Adds a country to the selected list
   */
  private selectCountry(item: IdValueMap): void {

    // No need to do anything if country is already selected
    if (!item || this.isCountrySelected(item)) {
      return;
    }

    // Add country to the selected items list
    this.countriesFormArray.push(this.newItemFormControl(item));
  }

  /**
   * Checks, whether a country is selected or not.
   *
   * @returns `true` if the country is already selected otherwise `false`.
   */
  private isCountrySelected(item: IdValueMap): boolean {

    return this.isItemSelected(this.selectedCountries, item);
  }

  /**
   * Adds a state to the selected list
   */
  private selectState(item: IdValueMap): void {

    // No need to do anything if state is already selected
    if (!item || this.isStateSelected(item)) {
      return;
    }

    // Add state to the selected items list
    this.statesFormArray.push(this.newItemFormControl(item));
  }

  /**
   * Checks, whether a state is selected or not.
   *
   * @returns `true` if the state is already selected otherwise `false`.
   */
  private isStateSelected(item: IdValueMap): boolean {

    return this.isItemSelected(this.selectedStates, item);
  }

  /**
   * Adds a district to the selected list
   */
  private selectDistrict(item: IdValueMap): void {

    // No need to do anything if district is already selected
    if (!item || this.isDistrictSelected(item)) {
      return;
    }

    // Add district to the selected items list
    this.districtsFormArray.push(this.newItemFormControl(item));
  }

  /**
   * Checks, whether a district is selected or not.
   *
   * @returns `true` if the district is already selected otherwise `false`.
   */
  private isDistrictSelected(item: IdValueMap): boolean {

    return this.isItemSelected(this.selectedDistricts, item);
  }

  /**
   * Generic method to check if an item is already selected or not.
   */
  private isItemSelected(selectedItemIds: string[], item: IdValueMap): boolean {

    return (Array.isArray(selectedItemIds) && item) &&  // Validate passed params, null checks
      selectedItemIds.findIndex(selectedItemId => item.id === selectedItemId) >= 0;
  }

  /**
   * Gets, current selected location range
   */
  private getSelectedItem(): { country: IdValueMap, state: IdValueMap, district: IdValueMap } {

    return {
      country: this.countryFormGroup.value,
      state: this.stateFormGroup.value,
      district: this.districtFormGroup.value,
    };
  }

  /**
   * Clears all selected items in the location range
   */
  private clearSelectedItem(): void {
    this.countryFormGroup.reset();
    this.stateFormGroup.reset();
    this.districtFormGroup.reset();

    // Clear the list of selected countries, states and district
    this.subscriberMetaService.updateSelectedDistrict('');
    this.subscriberMetaService.updateSelectedState('');
    this.subscriberMetaService.updateSelectedCountry('');
  }

  /**
   * Push a range of location to the selected range
   *
   * @param country Selected country
   * @param state Selected state
   * @param district Selected district
   */
  private pushRangeToSelectedItem(country: IdValueMap, state: IdValueMap, district: IdValueMap): void {

    this.selectedRange.push({
      value: this.getSelectedItemLabel(country, state, district),
      country,
      state,
      district
    });
  }

  /**
   * Gets, selected item label. Label is the combination of `country, state, district` format.
   *
   * @param country Selected country
   * @param state Selected state
   * @param district Selected district
   */
  private getSelectedItemLabel(country: IdValueMap, state: IdValueMap, district: IdValueMap): string {

    const countryLabel  = this.getItemLabel(country);
    const stateLabel    = this.getItemLabel(state);
    const districtLabel = this.getItemLabel(district);

    return (countryLabel ? countryLabel : '') +
      (stateLabel ? `, ${stateLabel}` : '') +
      (districtLabel ? `, ${districtLabel}` : '');
  }

  /**
   * Get, selected item label
   */
  private getItemLabel(item: IdValueMap): string {
    return item && item.id ? item.value : '';
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
