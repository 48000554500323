import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  AddSurveyMembersResponse,
  ApiSurveyMemberAddResponseData,
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiSurveyDetails,
  KalgudiUserBasicDetails,
  KalgudiUsersPickerDialogConfig,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { SurveyPageActions } from '../../../constants';
import { KalgudiSurveyMembersService } from '../../../services/kalgudi-survey-members.service';
import { KalgudiSurveysHelperService } from '../../../services/kalgudi-surveys-helper.service';
import { SurveyStateService } from '../../../services/survey-state.service';

@Directive()
export abstract class KalgudiSurveyManageAdmin extends KalgudiInboxStream<KalgudiUserBasicDetails> {

  surveyId: string;
  surveyDetails: KalgudiSurveyDetails;

  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  availableActionTypes = SurveyPageActions;

  maxPageLimit = 16;

  private kalgudiSurveyMembersService: KalgudiSurveyMembersService;
  private surveyState: SurveyStateService;
  private usersPickerService: KalgudiUsersPickerService;
  private kalgudiDialogs: KalgudiDialogsService;
  protected surveyHelper: KalgudiSurveysHelperService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification, util);

    this.kalgudiSurveyMembersService = this.injector.get(KalgudiSurveyMembersService);
    this.surveyState                = this.injector.get(SurveyStateService);
    this.usersPickerService          = this.injector.get(KalgudiUsersPickerService);
    this.kalgudiDialogs              = this.injector.get(KalgudiDialogsService);
    this.surveyHelper                = this.injector.get(KalgudiSurveysHelperService);

    this.pageLimit = this.maxPageLimit;

    this.init();
  }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens survey members dialog
   */
  showAddSurveyAdminsDialog() {

    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Add admin',
      acceptButtonTitle: 'Select users',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(r => r.accepted),

        map(res => Object.values(res.data)),

        filter(res => res.length > 0)
      )
      .subscribe(
        res => this.addAdmins(res)
      );
  }

  /**
   * Verifies if the admin is allowed to remove the user or not.
   * Shows an error message if the admin is not allowed to remove the
   * user otherwise removes the user.
   */
  checkAndRemoveAdmin(user: KalgudiUserBasicDetails): void {

    try {

      // Verify question additions
      this.surveyHelper.canModifyAdmins(this.surveyDetails);

      this.removeAdmin(user);
    } catch (e) {
      this.util.showApiErrorMessage(e);
    }
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Initializes survey fullview subscriptions and survey details
   */
  protected init(): void {

    // Initialize survey details
    this.surveyState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(survey => this.onSurveyStateUpdate(survey));


    // Subscribe to survey page state change
    this.subscribeToStateActions();
  }

  /**
   * @override streamApi to fetch admins list of survey
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.kalgudiSurveyMembersService.getAdmins(this.surveyId, offset, limit);
  }

  /**
   * Event handler called on survey state details update
   */
  protected onSurveyStateUpdate(survey: KalgudiSurveyDetails) {
    this.surveyDetails = survey;
    this.surveyId = survey.surveyId;
    this.memberRole = survey.role;
  }

  /**
   * Calls api to add admins for the survey
   */
  protected addAdmins(users: KalgudiUserBasicDetails[]): void {

    // Get admins payload
    const payload = this.prepareAddAdminsPayload(users);

    this.kalgudiSurveyMembersService.addAdmins(this.surveyId, payload)
    .subscribe(
      res => {
        this.resetStream();
      },
      err =>  this.notification.showMessage('Unable to add admin, please try again later!')
    );
  }

  /**
   * Calls api to remove admin for the survey
   */
  protected removeAdmins(user: KalgudiUserBasicDetails): void {

    this.notification.showSpinner();

    this.kalgudiSurveyMembersService.deleteAdmins(this.surveyId, user.profileKey)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        (res) => this.onAdminRemoved(res),
        (err) => this.onRemovingAdminError(err)
      );
  }

  /**
   * Construct and gets, the add admins payload.
   */
  protected prepareAddAdminsPayload(users: KalgudiUserBasicDetails[]): AddSurveyMembersResponse {

    const payload: AddSurveyMembersResponse  = {
      actors: users,
    };

    return payload;
  }


  /**
   * Event handler for survey admin deleted successful
   */
  protected onAdminRemoved(res: ApiSurveyMemberAddResponseData): void {
    this.notification.showMessage('Removed admin successfully');
    this.resetStream();
  }

  /**
   * Event handler for survey admin delete API errors
   */
  protected onRemovingAdminError(err: Error): void {
    this.notification.showMessage('Unable to remove admin, please try again later!');
  }


  /**
   * Opens user picker web dialog
   */
  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.usersPickerService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Opens user picker mobile dialog
   */
  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.usersPickerService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Remove admin from survey
   * Show confirmation dialog before deleting admin
   */
  private removeAdmin(user: KalgudiUserBasicDetails) {

    // Open confirmation dialog before deleting
    this.showDeleteConfirmationDialog()
      .pipe(

        takeUntil(this.destroyed$),

        // Turn on spinner
        tap(accepted => this.notification.showSpinner(true)),

        // On completion turn off the spinner
        finalize(() => this.notification.hideSpinner()),

      )
      .subscribe(
        res => this.removeAdmins(user)
      );
  }


  /**
   * Shows users picker for mobile and web based on the device or screen size.
   */
  private showUsersPicker(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileUserPickerDialog(dialogConfig)
      : this.openUsersPickerDialog(dialogConfig, matDialogConfig);
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Remove',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to remove admin?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Subscribes to survey state action updates and calls the necessary methods.
   */
  private subscribeToStateActions(): void {

    this.surveyState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === SurveyPageActions.ADD_ADMIN) {
          this.showAddSurveyAdminsDialog();
        }
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
