import { Injectable } from '@angular/core';
import { KalgudiProject, KalgudiTaskTemplateSearchResults, PartialData, TaskTemplateDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KalgudiProjectApiService } from './kalgudi-project-api.service';
import { KalgudiProjectStateService } from './kalgudi-project-state.service';

@Injectable()
export class KalgudiProjectService {

  constructor(
    private api: KalgudiProjectApiService,
    private projectStateService: KalgudiProjectStateService,
    ) { }

  /**
   * Gets, project details from Api
   */
  getProject(projectId: string, filters: PartialData = {}): Observable<KalgudiProject> {
    return this.api.getProject(projectId, filters)
      .pipe(
        tap(projectDetails => this.projectStateService.updateProjectDetails(projectDetails))
      );
  }

  deleteProject(projectId: string, filters: PartialData = {}): Observable<KalgudiProject> {

    return this.api.deleteProject(projectId, filters);
  }


  /**
   * Calls the api to get the tasks templates
   */
  getTemplates(searchKeyword: string, projectType: string, offset, limit, startFrom: string): Observable<KalgudiTaskTemplateSearchResults> {

    return this.api.getTaskTemplates(searchKeyword, projectType, offset, limit, startFrom);
  }

  /**
   * Calls the api to get the schema
   * @param templateId
   */
  getTemplateSchema(templateId: string): Observable<TaskTemplateDetails> {

    return this.api.getTemplateSchema(templateId);

  }

}
