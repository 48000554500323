<nav class="breadcrumb mb-0 p-0">
  <ng-container *ngIf="(breadcrumb$ | async) as breadcrumb">
    <ng-container *ngFor="let item of breadcrumb; let last=last;">

      <!-- Breadcrumb lists -->
      <a class="item position-relative" *ngIf="!last"
        [routerLink]="item?.routerLink"
        routerLinkActive="active" translate>
        {{ item?.title }}
      </a>

      <!-- Separator -->
      <span *ngIf="!last" class="text-muted d-inline-block mx-1"> / </span>

      <!-- Last element of the breadcrumb -->
      <span *ngIf="last" class="text-secondary" translate>{{ item?.title }}</span>

    </ng-container>
  </ng-container>
</nav>
