import { Inject, Directive } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserCertificateDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { KalgudiProfileSectionList } from '../../../classes/kalgudi-profile-section-list';
import { KalgudiProfileCertificatesService } from './kalgudi-profile-certificates.service';

@Directive()
export abstract class ProfileSectionCertifications extends KalgudiProfileSectionList<KalgudiUserCertificateDetails> {

  protected itemForm: FormGroup;
  protected existingItemFormGroup: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileCertificatesService,
  ) {

    super(notification, app, util);

    // Initialize item form
    this.itemForm = this.formGroup;
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Profile certificates form group structure.
   *
   * **NOTE** Must define all fields of the profile list section type,
   * whether its shown in the view or not.
   */
  private get formGroup(): FormGroup {

    return new FormGroup({
      Flag: new FormControl(true),
      businessCertificationId: new FormControl(''),
      certAuthority: new FormControl('', Validators.required),
      certName: new FormControl('', Validators.required),
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Implement the logic to check if the specified item in the profile section
   * list is new or existing item. An item in the list is new if it does not
   * contain any id otherwise its existing item.
   */
  protected isNewItem(item: KalgudiUserCertificateDetails): boolean {

    return this.util.isNullOrEmpty(item.businessCertificationId);
  }

  /**
   * Defines save new item Api.
   */
  protected saveNewItemApi(payload: KalgudiUserCertificateDetails): Observable<KalgudiUserCertificateDetails> {

    return this.api.saveNewCertificate(payload)
      .pipe(
        mapTo(payload),
      );
  }

  /**
   * Defines update certificate Api.
   */
  protected updateItemApi(payload: KalgudiUserCertificateDetails): Observable<KalgudiUserCertificateDetails> {
    return this.api.updateCertificate(payload)
      .pipe(
        mapTo(payload),
      );

  }

  /**
   * Deletes an item from the profile section items list.
   *
   * @param item Item to delete
   */
  protected deleteItemApi(item: KalgudiUserCertificateDetails): Observable<boolean> {

    return this.api.deleteCertificate(item.businessCertificationId);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
