import { FeatureFlagService } from '../modules/kalgudi-feature-flag';

export const CHART_GRADIENT_COLORS = [
 '#35E298', // Green

 '#EBB62D', // Yellow

 '#4BA9FF', // Blue
];

export const SINGLE_UNITS = {
  grams: 'gram',
  millitires: 'millilitre',
  liters: 'litre',
  kgs: 'kg',
  tons: 'ton',
  quintals: 'quintal',
  pieces: 'piece'
}

// // APP_initializer function to fetch feature-flags
// export const featureFactory = (featureFlagsService: FeatureFlagService) => () =>{
//   console.log("🚀 ~ file: index.ts ~ line 24 ~ featureFactory ~ featureFlagsService", featureFlagsService)

//   if(featureFlagsService !== undefined){
//     const toGo = featureFlagsService.loadConfig();
//     console.log("🚀 ~ file: index.ts ~ line 27 ~ featureFactory ~ toGo", toGo)
//     return toGo;
//   } else {
//     return;
//   }
// }

// APP_initializer function to fetch feature-flags
export function featureFactory (featureFlagsService: FeatureFlagService) {

  return (
    function () {

      if (featureFlagsService !== undefined) {
        const toGo = featureFlagsService.loadConfig();
        return toGo;

      } else {
        return;

      };

    })
};
