import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiFarmerEquipmentForm } from '../../classes/kalgudi-farmer-equipment-form';

@Component({
  selector: 'kl-farmer-equipment-form',
  templateUrl: './farmer-equipment-form.component.html',
  styleUrls: ['./farmer-equipment-form.component.scss']
})
export class FarmerEquipmentFormComponent extends KalgudiFarmerEquipmentForm implements OnInit {

  constructor(protected injector: Injector) {
    super(injector)
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
