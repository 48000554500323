import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KalgudiStepperComponent } from '@kalgudi/common';
import { kalgudiAnimations, KalgudiUtilityService } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';

import { KalgudiProgramsRouteConfig } from '../../../../config';
import { KalgudiCreatePage } from '../../classes/kalgudi-create-page';
import { CreatePageStateService } from '../../services/create-page-state.service';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-page-creation',
  templateUrl: './page-creation.component.html',
  styleUrls: ['./page-creation.component.scss'],
  viewProviders: [ CreatePageStateService ],
  animations: kalgudiAnimations,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {displayDefaultIndicatorType: false},
  }]
})
export class PageCreationComponent extends KalgudiCreatePage implements OnInit, OnChanges, AfterViewInit {

  @ViewChild(KalgudiStepperComponent) kalgudiStepper: KalgudiStepperComponent;
  private _document?: Document;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
    protected pageStateService: CreatePageStateService,
    protected util: KalgudiUtilityService,
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig,
    @Inject(DOCUMENT) document?: any
  ) {

    super(notifications, fb, createPageService, pageStateService, util, appRouting, environment);

    this._document = document as Document;
  }

  ngOnInit() {
    this.getPageTypes();
    this._document.body.classList.toggle("stepperOpen");
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false; // stay on same page
  }

  /**
   * Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
   */
  ngOnChanges(changes: SimpleChanges): void {

  }

  ngAfterViewInit(): void {
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  nextStep(): void {
    this.kalgudiStepper.next();

    super.nextStep();
  }

  previousState(): void {
    this.kalgudiStepper.previous();

    super.previousState();
  }
}
