import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiStoreProduct } from '@kalgudi/types';

@Component({
  selector: 'kl-selected-store-products',
  templateUrl: './selected-store-products.component.html',
  styleUrls: ['./selected-store-products.component.scss']
})
export class SelectedStoreProductsComponent implements OnInit {

  @Input()
  selectedProducts: KalgudiStoreProduct[];

  @Output()
  remove = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Emits an event back to parent to remove product
   */
  removeProduct(index: number): void {
    this.remove.emit(index);
  }

}
