<mat-card id="survey-tile" class="pb-0 pt-2 mt-2 cursor-pointer" (click)="toSurveyFullView(surveyDetails?.surveyId)">

  <!-- Surveys header -->
  <div class="border-bottom mb-3 pb-2" fxLayout fxLayoutGap="8px">
    <svg-icon src="assets/svgs/surveys-icon.svg"
      [svgStyle]="{'width.px': '16', 'height.px': '16'}"
      [applyCss]="true"
      class="surveys-icon">
    </svg-icon>

    <span>
      <a class="mr-1" (click)="viewProfile(surveyDetails?.createdBy?.profileKey); $event.stopPropagation()">
        <b>{{ surveyDetails?.createdBy?.firstName }}</b>
      </a>
      <span *ngIf="surveyDetails?.eventCategory !== 'SURVEYS_REMINDER'" translate>added you to a survey</span>
      <span *ngIf="surveyDetails?.eventCategory === 'SURVEYS_REMINDER'" translate>reminding you for the survey</span>
    </span>
  </div>
  <!--/ Surveys header -->

  <!-- Surveys basic details -->
  <kl-survey-basic-details [surveyBasicDetails]="surveyDetails?.surveyBasicDetails"></kl-survey-basic-details>
  <!--/ Surveys basic details -->

  <!-- Surveys action bar -->
  <div class="border-top">
    <kl-survey-action-bar [surveyResponse]="surveyDetails?.metaData" (toFullview)="toSurveyFullView(surveyDetails?.surveyId)"></kl-survey-action-bar>

  </div>
  <!--/ Surveys action bar -->

</mat-card>
