import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUserAwardDetails,
  KalgudiUserCertificateDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import {
  AddLiveStockDialogComponent,
} from '../modules/add-live-stock/components/add-live-stock-dialog/add-live-stock-dialog.component';
import {
  AddLiveStockMobileDialogComponent,
} from '../modules/add-live-stock/components/add-live-stock-mobile-dialog/add-live-stock-mobile-dialog.component';
import { AddAwardsDialogComponent } from '../modules/awards/components/add-awards-dialog/add-awards-dialog.component';
import {
  AddAwardsMobileDialogComponent,
} from '../modules/awards/components/add-awards-mobile-dialog/add-awards-mobile-dialog.component';
import {
  AddCertificateDialogComponent,
} from '../modules/certificates/components/add-certificate-dialog/add-certificate-dialog.component';
import {
  AddCertificateMobileDialogComponent,
} from '../modules/certificates/components/add-certificate-mobile-dialog/add-certificate-mobile-dialog.component';
import {
  AddLandDetailsDialogComponent,
} from '../modules/farmer-profile/components/add-land-details-dialog/add-land-details-dialog.component';
import {
  AddLandDetailsMobileDialogComponent,
} from '../modules/farmer-profile/components/add-land-details-mobile-dialog/add-land-details-mobile-dialog.component';
import { ViewLiveStockLocationDialogComponent } from '../modules/add-live-stock/components/view-live-stock-location-dialog/view-live-stock-location-dialog.component';
import { ViewLiveStockLocationMobileDialogComponent } from '../modules/add-live-stock/components/view-live-stock-location-mobile-dialog/view-live-stock-location-mobile-dialog.component';

@Injectable()

export class KalgudiProfileActionService {

  constructor(
    private dialogsService: KalgudiDialogsService
  ) { }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region for certificate dialogs
  // --------------------------------------------------------

  /**
   * Shows add certificate dialog
   */
  showAddCertificateDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add certificate',
      acceptButtonTitle: 'Add Certificate',
      rejectButtonTitle: 'Cancel',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddCertificateDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Updates certificate
   */
  updateCertificate(certificateId: string, certificateDetails: KalgudiUserCertificateDetails) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update certificate',
      acceptButtonTitle: 'Update Certificate',
      rejectButtonTitle: 'Cancel',
      data: {
        id: certificateId,
        details: certificateDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddCertificateDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  // --------------------------------------------------------
  // #endregion for certificate dialogs
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region for award dialogs
  // --------------------------------------------------------

  /**
   * Shows add awards dialog
   */
  showAddAwardDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add awards',
      acceptButtonTitle: 'Add Award',
      rejectButtonTitle: 'Cancel',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddAwardDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r && r.accepted),
      );
  }

  /**
   * Updates award
   */
  updateAward(awardId: string, awardDetails: KalgudiUserAwardDetails) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update award',
      acceptButtonTitle: 'Update Award',
      rejectButtonTitle: 'Cancel',
      data: {
        id: awardId,
        details: awardDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddAwardDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r && r.accepted),
      );
  }

  /**
   * Shows live stock dialog
   */
  showLiveStockDialogForm(stockType: any, stock?: any, showEditFields?: boolean) {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add details',
      acceptButtonTitle: 'Submit',
      rejectButtonTitle: 'Cancel',
      data: {
        stockType, stock, showEditFields
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openLiveStockDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(res => res.accepted)
      );
  }

  /**
   * Shows live stock location dialog
   */
  viewLocationDialog(locationDetails?: any) {
  // Input dialog UI configuration
  const dialogDetails: KalgudiDialogConfig = {
    title: 'View location',
    acceptButtonTitle: 'Submit',
    rejectButtonTitle: 'Cancel',
    data: {
      locationDetails
    }
  };

  // Material dialog configuration
  const dialogConfig: MatDialogConfig = {
    width: '500px',
    maxWidth: '500px',
    panelClass: 'kl-dialog',
    hasBackdrop: true,
    disableClose: true,
    autoFocus: false,
    data: {
    }
  };

  return this.openViewLocationDialog(dialogDetails, dialogConfig)
    .pipe(

      filter(r => r.accepted),

      map(res => res.accepted)
    );
}


  /**
   * Shows add land details dialog
   */
  showAddLandDetailsDialog(returnGeoTagDetails: boolean, landDetails: any): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add land details',
      acceptButtonTitle: 'Add land details',
      rejectButtonTitle: 'Cancel',
      data: {
        returnGeoTagDetails,
        landDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddLandDetailsDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(r => r && r.accepted),

        map(res => res.data.geoTagLandDetails),
      )
  }

  // --------------------------------------------------------
  // #endregion for award dialogs
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Shows the add awards web or mobile dialog
   */
  private openAddAwardDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddAwardsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddAwardsDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Shows the add certificate web or mobile dialog
   */
  private openAddCertificateDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddCertificateMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddCertificateDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Shows the live stock web or mobile dialog
   */
  private openLiveStockDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddLiveStockMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddLiveStockDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Shows the live stock location web or mobile dialog
   */
  private openViewLocationDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(ViewLiveStockLocationMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(ViewLiveStockLocationDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  /**
   * Shows the add land details web or mobile dialog
   */
  private openAddLandDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddLandDetailsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddLandDetailsDialogComponent, dialogConfig, matDialogConfig);
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
