<section class="block-container px-3 pt-3 pb-2">
  <!-- form -->
  <form autocomplete="off" name="form" [formGroup]="form">
    <div class="w-100" fxLayout="row" fxLayoutGap="15px" fxLayout.xs="column">

      <div class="form-group mb-2" fxLayout="column" fxFlex="calc(50%-15px)" *ngIf="type != 'select'">
        <span class="label-name" translate>Title</span>
        <mat-form-field appearance="outline">
          <input matInput type="text" [placeholder]="'Title' | translate" [formControlName]="titleFormControlName">
        </mat-form-field>
      </div>

      <div class="form-group mb-2" fxLayout="column" fxFlex="calc(50%-15px)" *ngIf="type === 'select'">
        <span class="label-name" translate>Taxes</span>
        <mat-form-field appearance="outline">
          <mat-select [formControlName]="titleFormControlName">
            <mat-option *ngFor="let field of itemsList" [value]="field?.fieldName">
              <span>{{field.fieldName}}</span> (<span>{{field.abbreviation}}</span>)
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group details mb-2" fxLayout="column" fxFlex="50%">
        <span class="label-name" translate>Other Details</span>
        <mat-form-field appearance="outline">
          <input matInput [placeholder]="'Other details' | translate" [formControlName]="descriptionFormControlName">
        </mat-form-field>
      </div>
    </div>

    <!-- Buttons -->
    <div fxLayout="row" fxLayoutAlign="end end">
      <kl-link-button
        [disabled]="form.invalid"
        [progress]="saveInProgress"
        [label]="'SAVE'"
        (clicked)="saveButton()">
      </kl-link-button>

      <kl-link-button
        [label]="'CANCEL'" (clicked)="cancelButton()">
      </kl-link-button>

    </div>
    <!-- / Buttons -->

  </form>
  <!-- / form -->
</section>
