import { LocationStrategy } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { ACTIVITY_TYPES, KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialRouteConfig } from '@kalgudi/social';
import { BottomSheetMenu, KalgudiUser, ShareUpdateHeader, SocialPostLatestActivity, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-share-update-header',
  templateUrl: './share-update-header.component.html',
  styleUrls: ['./share-update-header.component.scss'],
})
export class ShareUpdateHeaderComponent implements OnInit, OnChanges {

  @Input()
  scheduledUpdates: boolean;

  listMenu: BottomSheetMenu;

  @Input()
  latestActivity: SocialPostLatestActivity;

  @Input()
  userProfileKey: string;

  @Input()
  entityId: any;

  @Input()
  memberRole: string;

  @Input()
  authorId: string;

  @Input()
  shareUpdateHeader: ShareUpdateHeader;

  @Input()
  msgType: string;

  @Input()
  showMatMenu: boolean = false;

  @Output()
  deletePost = new EventEmitter<boolean>();

  @Output()
  report = new EventEmitter<boolean>();

  @Output()
  shareUpdate = new EventEmitter<boolean>();

  @Output()
  sendAsSms = new EventEmitter<boolean>();

  @Output()
  shareWhatsapp = new EventEmitter<boolean>();

  @Output()
  updateScheduledPost = new EventEmitter<boolean>();

  @Output()
  refreshAdvisory = new EventEmitter<boolean>();

  activityTypes = ACTIVITY_TYPES;

  userDetails: KalgudiUser;
  isLoggedIn: boolean;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    private app: KalgudiAppService,
    private bottomSheetService: KalgudiBottomSheetService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    private location: LocationStrategy,
    private route: Router,
  ) {
    this.userDetails = this.app.profileLocal;
    this.isLoggedIn = this.app.loggedIn;
  }

  ngOnInit() { }


  ngOnChanges() {
    this.listMenu = {
      title: '',
      styleType: 'list',
      showCloseButton : true,
      menu: [
        {
          title: 'Report abuse',
          id: 'REPORT_ABUSE',
          hide : this.scheduledUpdates,
        },
        {
          title: 'Share from here',
          id: 'SHARE_UPDATE',
          hide : !this.entityId && !(this.env.appId === 'KALGUDI_CORE' || this.env.appId === 'UBM'),
        },
        {
          title: 'Delete',
          id: 'DELETE',
          hide : this.isLoggedIn ? (this.authorId !==  this.userDetails.profileKey && this.scheduledUpdates) || this.memberRole !== 'ADMIN' : false,
        },
        {
          title: 'Update',
          id: 'UPDATE',
          hide : !this.scheduledUpdates
        },

      ]
    };
  }

  /**
   * Navigates to profile page
   * @param profileKey
   */
  viewProfile(profileKey: string): void {
    this.appRouting.toProfile({ profileKey });
  }

  /**
   * Emits an event to delete the update
   */
  deleteUpdate(): void {
    this.deletePost.emit();
  }

  /**
   * Emits an event to report on the post
   */
  reportAbuse(): void {

    this.report.emit();
  }

  /**
   * Emits an event to share on the post
   */
  reShare(): void {

    this.shareUpdate.emit();
  }

  /**
   * Event emit back to parent to send as sms
   */
  sendSms(): void {
    this.sendAsSms.emit();
  }

  /**
   * Event emit back to parent to refresh advisory
   */
  refreshLatestAdvisory(): void {
    this.refreshAdvisory.emit();
  }

  /**
   * Event emit back to parent to share on whatsapp
   */
  shareOnWhatsapp(): void {
    this.shareWhatsapp.emit();
  }

  /**
   * Emits an event to edit on the post
   */
  updatePost(): void {
    this.updateScheduledPost.emit();
  }

  openBottomSheet(): void {
    this.bottomSheetService.disableBackButton();

    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'REPORT_ABUSE':
        this.reportAbuse();
        break;

      case 'SHARE_UPDATE':
        this.reShare();
        break;

      case 'DELETE':
        this.deleteUpdate();
        break;

      case 'UPDATE':
        this.updatePost();
        break;

    }
  }
}
