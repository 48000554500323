import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  AddSurveyMembersResponse,
  ApiResponseCommonList,
  ApiSurveyMemberAddResponse,
  ApiSurveyMemberAddResponseData,
  BulkMembersAdditionResponse,
  KalgudiPageRelation,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class KalgudiSurveyMembersApiService {

  /**
   * `v2/social/surveys`
   */
  private readonly API_SURVEYS = `${this.env.restBaseUrlV2}/social/surveys`;

  /**
   * `v2/social/surveys/:surveyId/actors`
   */
  private readonly API_SURVEYS_ACTORS = `${this.API_SURVEYS}/:surveyId/actors`;

  /**
   * `v2/social/surveys/:surveyId/actors/bulk`
   */
  private readonly API_SURVEYS_BULK_MEMBERS = `${this.API_SURVEYS_ACTORS}/bulk`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Fetches survey members stream from API.
   *
   * @param surveyId Survey id for which the stream must be fetched
   * @param role
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchUsers(surveyId: string, role: KalgudiPageRelation, offset: number, limit: number): Observable<KalgudiStreamData> {

    // Api query params
    const params = {
      role,
      offset: offset.toString(),
      limit: limit.toString()
    };

    const url = this.API_SURVEYS_ACTORS.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommonList>(url, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi survey member details object type
        map(res => ({
          count: res.data.count,
          items: res.data.results
        }))
      );
  }

  /**
   * Searches survey members.
   *
   * @param surveyId Survey id for which the stream must be fetched
   * @param role
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  searchUsers(
    surveyId: string,
    searchKeyword: string,
    role: KalgudiPageRelation,
    offset: number,
    limit: number,
    extraParams: PartialData = {}
  ): Observable<KalgudiStreamData> {

    // Api query params
    const params = {
      role,
      searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      ...extraParams,
    };

    const url = this.API_SURVEYS_ACTORS.replace(':surveyId', surveyId);

    return this.httpClient.get<ApiResponseCommonList>(url, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi survey member details object type
        map(res => ({
          count: res.data.count,
          items: res.data.results
        }))
      );
  }

  /**
   * Defines the api to add members to a survey
   */
  addUsers(
    surveyId: string,
    usersList: AddSurveyMembersResponse,
    role: KalgudiPageRelation
  ): Observable<ApiSurveyMemberAddResponseData> {

    // Api query params
    const params = {
      role,
    };

    const url = this.API_SURVEYS_ACTORS.replace(':surveyId', surveyId);

    return this.httpClient.post<ApiSurveyMemberAddResponse>(url, usersList, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED, {
          ...REST_API_ERROR_MESSAGES,
          [HttpStatusCode.BAD_REQUEST]: 'Unable to add members, ensure you adding max 10 members at a time'
        })),

        // Map API response to Kalgudi survey member details object type
        map(res => res.data)
      );
  }

  /**
   * Defines the api to add bulk members to a survey
   */
  addBulkMembers(
    surveyId: string,
    usersList: AddSurveyMembersResponse,
    role: KalgudiPageRelation,
    filters: PartialData = {}
  ): Observable<BulkMembersAdditionResponse> {

    // Api query params
    const params = {
      role,
      ...filters
    };

    const url = this.API_SURVEYS_BULK_MEMBERS.replace(':surveyId', surveyId);

    return this.httpClient.post<ApiSurveyMemberAddResponse>(url, usersList,  { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi survey member details object type
        map(res => res.data)
      );
  }

  /**
   * Defines api to remove members from survey
   *
   * @param surveyId
   */
  deleteUsers(surveyId: string, userProfileKey: string): Observable<ApiSurveyMemberAddResponseData> {

    // Api query params
    const params = {
      profileKeys: userProfileKey
    };

    const url = this.API_SURVEYS_ACTORS.replace(':surveyId', surveyId);

    return this.httpClient.delete<ApiSurveyMemberAddResponse>(url, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response to Kalgudi survey member details object type
        map(res => res.data)
      );
  }


}
