import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { IdValueMap } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { programType } from '../../../../constants';
import { KalgudiCreateProgram } from '../../classes/kalgudi-create-program-page';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-farmers-interest-group-page-creation',
  templateUrl: './farmers-interest-group-page-creation.component.html',
  styleUrls: ['./farmers-interest-group-page-creation.component.scss'],
  animations: kalgudiAnimations,
})
export class FarmersInterestGroupPageCreationComponent extends KalgudiCreateProgram implements OnInit {

  programType: IdValueMap[] = programType;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(fb);
  }

  ngOnInit() {
    this.updateDateLocale();

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void {}

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}