import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { ChipListComponent } from './components/chip-list/chip-list.component';
import { ChipsComponent } from './components/chips/chips.component';



@NgModule({
  declarations: [
    ChipsComponent,
    ChipListComponent,
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,

    TranslateModule
  ],
  exports: [
    ChipsComponent,
    ChipListComponent,
  ]
})
export class KalgudiChipsModule { }
