import { Injectable } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiUserBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { ProcessingApiService } from './processing-api.service';

@Injectable()

export class ProcessingService {

  constructor(
    private api: ProcessingApiService,
    private util: KalgudiUtilityService,
  ) { }


  /**
   * Calls api method to get stocks list
   * @param pageId
   * @param offset
   * @param limit
   */
  getProcessors(offset: number, limit: number, pageId: string, actorRole: string): Observable<KalgudiStreamData> {

    return this.api.fetchStream(offset, limit, pageId, actorRole);
  }

  addProcessors(pageId: string, actorRole: string, payload: KalgudiUserBasicDetails[]): Observable<any> {

    return this.api.addProcessors(pageId, actorRole, payload);
  }
}
