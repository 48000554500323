import { Component, Input, OnInit } from '@angular/core';
import { KalgudiProjectTask } from '@kalgudi/types';

@Component({
  selector: 'kl-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {

  @Input()
  taskDetails: KalgudiProjectTask;

  show: boolean;

  selectedItem: any;

  singleItem: boolean;

  constructor() { }

  ngOnInit() {
    if(this.taskDetails.tags) {
      this.singleItem = this.taskDetails.tags.input.length === 1 ? true : false;
    }
  }

  /**
   * To show the list
   */
  displayDetails(index: number) {
    this.show = true;
    this.selectedItem = index;
  }

  /**
   * To hide the list
   */
  hideExtraInfo(index: number) {
    this.show = false;
    this.singleItem = false;
    this.selectedItem = index;
  }

}
