export const weatherIconCodes = {
  113: 'assets/icons/weather/animated/day.svg',
  116: 'assets/icons/weather/animated/cloudy-day-1.svg',
  119: 'assets/icons/weather/animated/cloudy-night-3.svg',
  176: 'assets/icons/weather/animated/rainy-3.svg',
  122: 'assets/icons/weather/animated/rainy-7.svg',
  143: 'assets/icons/weather/animated/rainy-1.svg',
  179: 'assets/icons/weather/animated/rainy-4.svg',
  182: 'assets/icons/weather/animated/rainy-5.svg',
  185: 'assets/icons/weather/animated/snowy-4.svg',
  200: 'assets/icons/weather/animated/thunder.svg',
  227: 'assets/icons/weather/animated/snowy-6.svg',
  230: 'assets/icons/weather/animated/snowy-3.svg',
  248: 'assets/icons/weather/animated/cloudy-day-2.svg',
  260: 'assets/icons/weather/animated/snowy-6.svg',
  263: 'assets/icons/weather/animated/rainy-4.svg',
  266: 'assets/icons/weather/animated/snowy-4.svg',
  281: 'assets/icons/weather/animated/rainy-7.svg',
  284: 'assets/icons/weather/animated/snowy-6.svg',
  293: 'assets/icons/weather/animated/rainy-7.svg',
  296: 'assets/icons/weather/animated/rainy-4.svg',
  299: 'assets/icons/weather/animated/rainy-2.svg',
  302: 'assets/icons/weather/animated/rainy-5.svg',
  305: 'assets/icons/weather/animated/rainy-3.svg',
  308: 'assets/icons/weather/animated/rainy-6.svg',
  311: 'assets/icons/weather/animated/rainy-7.svg',
  353: 'assets/icons/weather/animated/rainy-7.svg',

  // weather updates constants
  HUMIDITY: 'assets/icons/weather/humidity.svg',
  CLOUD: 'assets/icons/weather/cloud.svg',
  VISIBILITY: 'assets/icons/weather/animated/weather-sagittarius.svg',
  SUNSET: 'assets/icons/weather/animated/cloudy-day-2.svg',
  MOON_RISE: 'assets/icons/weather/animated/night.svg',
  MOON_SET: 'assets/icons/weather/animated/cloudy-night-2.svg',
  WIND_FLOW: 'assets/icons/weather/wind.svg',


  default: 'assets/icons/weather/animated/rainy-7.svg',
};

export const weatherTime = {
  0: '12:00 AM',
  100: '1:00 AM',
  200: '2:00 AM',
  300: '3:00 AM',
  400: '4:00 AM',
  500: '5:00 AM',
  600: '6:00 AM',
  700: '7:00 AM',
  800: '8:00 AM',
  900: '9:00 AM',
  1000: '10:00 AM',
  1100: '11:00 AM',
  1200: '12:00 PM',
  1300: '1:00 PM',
  1400: '2:00 PM',
  1500: '3:00 PM',
  1600: '4:00 PM',
  1700: '5:00 PM',
  1800: '6:00 PM',
  1900: '7:00 PM',
  2000: '8:00 PM',
  2100: '9:00 PM',
  2200: '10:00 PM',
  2300: '11:00 PM',
  2400: '12:00 PM',
};
