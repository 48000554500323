import { Injectable } from '@angular/core';
import { KalgudiSurveyDetails } from '@kalgudi/types';

@Injectable()
export class KalgudiSurveysHelperService {

  constructor() { }

  /**
   * Validates if the survey can be published or not.
   * Admin can publish the survey only if
   * 1. Member count is more than 0
   * 2. Questions count is more than 0
   * 3. Survey is not published
   *
   * @throws Specific error if admin cannot publish the survey
   */
  canPublishSurvey(survey: KalgudiSurveyDetails): boolean {

    const errorMessages = {
      members: 'You must add at least 1 member to publish the survey.',
      questions: 'You must add at least 1 question to publish the survey.',
      published: 'Survey is already published'
    };

    let errorType: keyof typeof errorMessages | null = null;

    if (survey.metaData.memberCount <= 0) {
      errorType = 'members';
    }

    if (survey.metaData.questionCount <= 0) {
      errorType = 'questions';
    }

    if (survey.surveyBasicDetails.isPublished) {
      errorType = 'published';
    }

    // Error found could not publish survey
    if (errorType) {
      throw new Error(errorMessages[errorType]);
    }

    return true;
  }

  /**
   * Validates if the survey can be published or not.
   * Admin can un-publish the survey only if
   * 1. Survey is not published
   *
   * @throws Specific error if admin cannot un-publish the survey
   */
  canUnPublishSurvey(survey: KalgudiSurveyDetails): boolean {

    const errorMessages = {
      published: 'Survey is already unpublished',
      membersResponded: 'You cannot unpublish a survey as few members already responded to the survey.'
    };

    let errorType: keyof typeof errorMessages | null = null;

    if (!survey.surveyBasicDetails.isPublished) {
      errorType = 'published';
    }

    // if (survey.metaData.responseCount > 0) {
    //   errorType = 'membersResponded';
    // }

    // Error found could not publish survey
    if (errorType) {
      throw new Error(errorMessages[errorType]);
    }

    return true;
  }

  /**
   * Verifies if the user is allowed to add/remove question or not.
   */
  canModifyQuestion(survey: KalgudiSurveyDetails): boolean {

    const errorMessages = {
      published: 'You must unpublish the survey to add/remove questions',
      membersResponded: 'You cannot modify questions as one or more member has responded',
    };

    let errorType: keyof typeof errorMessages | null = null;

    // if (survey.surveyBasicDetails.isPublished) {
    //   errorType = 'published';
    // }

    if (survey.metaData.responseCount > 0) {
      errorType = 'membersResponded';
    }

    // Error found could not publish survey
    if (errorType) {
      throw new Error(errorMessages[errorType]);
    }

    return true;
  }

  /**
   * Verifies if the user is allowed to add/remove admins or not.
   */
  canModifyAdmins(survey: KalgudiSurveyDetails): boolean {

    const errorMessages = {
      published: 'You must unpublish the survey to add/remove admins.',
    };

    let errorType: keyof typeof errorMessages | null = null;

    // if (survey.surveyBasicDetails.isPublished) {
    //   errorType = 'published';
    // }

    // Error found could not publish survey
    if (errorType) {
      throw new Error(errorMessages[errorType]);
    }

    return true;
  }

  /**
   * Verifies if the user is allowed to add/remove members or not.
   */
  canModifyMembers(survey: KalgudiSurveyDetails): boolean {

    const errorMessages = {
      published: 'You must unpublish the survey to add/remove members.',
    };

    let errorType: keyof typeof errorMessages | null = null;

    // if (survey.surveyBasicDetails.isPublished) {
    //   errorType = 'published';
    // }

    // Error found could not publish survey
    if (errorType) {
      throw new Error(errorMessages[errorType]);
    }

    return true;
  }

}
