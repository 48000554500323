import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KalgudiCommonModule, KalgudiDialogsModule } from '@kalgudi/common';
import { KalgudiCoreModule, PageStateManager } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiSurveyApiService } from './services/kalgudi-survey-api.service';
import { KalgudiSurveyMembersApiService } from './services/kalgudi-survey-members-api.service';
import { KalgudiSurveyMembersService } from './services/kalgudi-survey-members.service';
import { KalgudiSurveyService } from './services/kalgudi-survey.service';
import { KalgudiSurveysHelperService } from './services/kalgudi-surveys-helper.service';
import { SurveyStateService } from './services/survey-state.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    KalgudiCoreModule,
    KalgudiCommonModule,
    KalgudiDialogsModule,
  ],
  exports: [],
  providers: [
    SurveyStateService,
    KalgudiSurveyService,
    KalgudiSurveysHelperService,
    KalgudiSurveyApiService,
    KalgudiSurveyMembersService,
    KalgudiSurveyMembersApiService,
    {
      provide: PageStateManager,
      useExisting: SurveyStateService,
    },
  ]
})
export class KalgudiSurveysModule {

  public static forRoot(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiSurveysModule> {

    return {
      ngModule: KalgudiSurveysModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
