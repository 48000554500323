import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { KalgudiAppService, KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject, SelectableUIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectListService } from '../../project-list/services/kalgudi-project-list.service';

@Directive()
export abstract class KalgudiExistingProjects extends KalgudiSearchStream<KalgudiProject> {

  @Input()
  projectCategories: SelectableUIMenu[];

  @Output()
  selectedExistingProject = new EventEmitter<any>();

  projectsList: KalgudiProject[];

  readonly maxPageLimit = 12;

  selectedExistingProjectDetails: any;

  private projectListService: KalgudiProjectListService;
  private projectStateService: KalgudiProjectStateService;
  private kalgudiApp: KalgudiAppService;
  private env: KalgudiEnvironmentConfig;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.kalgudiApp          = this.injector.get<KalgudiAppService>(KalgudiAppService);
    this.projectListService  = this.injector.get<KalgudiProjectListService>(KalgudiProjectListService);
    this.projectStateService = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.env                 = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    this.pageLimit = this.maxPageLimit;

    this.projectStateService.reloadProjects$
      .pipe(
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.resetStream()
      );

    this.projectStateService.reloadTasks$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.resetStream()
      );
  }

  /**
   * Event emit back to parent with selected project details
   * @param projectDetails
   */
  selectExistingProject(projectDetails) {
    this.selectedExistingProjectDetails = projectDetails;
    this.selectedExistingProject.emit(projectDetails);
  }

  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    const filters = {
      profileKey: this.env.production ? 'M00k4jn0PRFREG2017121337719606UNH001' : 'M00000w0PRFREG2018102424844371UNH001'
    }

    if (searchKeyword) {
      return this.projectListService.searchProjects(searchKeyword, offset, limit, filters);
    } else {

      return this.projectListService.getProjects(offset, limit, filters);
    }
  }

}
