import { Attachment } from './social.model';
import { KalgudiLocation, KalgudiPageRelation, KalgudiUserBasicDetails } from './user.model';


export enum KalgudiSurveyQuestionTypes {
  YES_NO          = 'YES_NO',
  TEXT            = 'TEXT',
  RATING          = 'RATING',
  DATE            = 'DATE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  MULTIPLE_SELECT_CHECKBOX = 'MULTIPLE_SELECT_CHECKBOX',
}

export interface KalgudiSurveyDetails {

  createdBy?: KalgudiUserBasicDetails;
  updateBy?: KalgudiUserBasicDetails;
  docType?: string;
  CT?: string;
  LUT?: string;
  surveyId?: string;
  surveyBasicDetails?: SurveyBasicDetails;
  metaData?: SurveyMetaData;
  role?: KalgudiPageRelation;

  eventCategory?: string;

}


export interface SurveyBasicDetails {
  description: string;
  expiryDate: string;
  isPublished: boolean;
  isResubmittable: boolean;
  location: KalgudiLocation;
  logo: string;
  title: string;
}

export interface SurveyMetaData {
  adminCount: number;
  contributorCount: number;
  memberCount: number;
  questionCount: number;
  responseCount: number;
}

export interface KalgudiSurveyQuestion {

  createdBy: KalgudiUserBasicDetails;
  updatedBy: KalgudiUserBasicDetails;
  docType: string;
  CT: string;
  LUT: string;
  surveyId: string;
  questionId: string;
  questionDetails: KalgudiSurveyQuestionBasicDetails;
}

export interface KalgudiSurveyQuestionBasicDetails {
  attachments: Attachment[];
  questionType: string;
  question: string;
  options: KalgudiSurveyOption[];
  questionId?: string;
}


export interface KalgudiSurveyOption {
  title: string;
  value: string;
}

export interface SurveyActivityLogDetails {
  logId: string;
  docType: string;
  activity: SurveyActivityDetails;
  surveyId: string;
  createdTS: string;
  subDoctype: string;
}

export interface SurveyActivityDetails {
  CT: string;
  LUT: string;
  admins: KalgudiUserBasicDetails[];
  docType: string;
  metaData: SurveyMetaData;
  surveyId: string;
  updateBy: KalgudiUserBasicDetails;
  createdBy: KalgudiUserBasicDetails;
  surveyBasicDetails: SurveyBasicDetails;
}

export declare type SurveyActivityLogDetailsList = Array<SurveyActivityLogDetails>;

export interface KalgudiSurveySubmission {
    CT?: string;
    LUT?: string;
    docType?: string;
    surveyId?: string;
    createdBy?: KalgudiUserBasicDetails;
    updatedBy?: KalgudiUserBasicDetails;
    submissionId?: string;
    submissionData?: KalgudiSubmissionData[];
}

export interface KalgudiSubmissionData {
  answer: KalgudiAnswerData;
  question: KalgudiSurveyQuestionBasicDetails;
}

export interface KalgudiAnswerData {
  value: string;
  attachments: Attachment[];
}

export interface KalgudiSurveySubmissionRequest {
  submissionData: KalgudiSubmissionData[];
}

export interface KalgudiSurveyAnalyticsData {
  [key: string]: KalgudiSurveyAnalytics;
}

export interface KalgudiSurveyAnalytics {
  question?: KalgudiSurveyQuestionBasicDetails;
  analytics?: SurveyAnalytics;
}

export interface SurveyAnalytics {
  NO?: string;
  YES?: string;
}
