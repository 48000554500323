<section *ngIf="isShgApp">
  <main id="live-stock-list" class="h-100" *ngIf="!isPublicPage">

    <kl-common-profile-header
      title="Live Stock"
      svgIcon="assets/svgs/farm-crop.svg"
      actionIcon="add"
      [editable]="editable"
      (action)="showLiveStockListPage()">
    </kl-common-profile-header>

    <div class="list-details overflow-auto pr-2">
      <kl-live-stock-list></kl-live-stock-list>
    </div>

  </main>
</section>