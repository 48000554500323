import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageCoverageAnalytics } from '../../classes/page-coverage-analytics';

@Component({
  selector: 'kl-coverage-analytics',
  templateUrl: './coverage-analytics.component.html',
  styleUrls: ['./coverage-analytics.component.scss']
})
export class CoverageAnalyticsComponent extends KalgudiPageCoverageAnalytics implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initStream();
  }

}
