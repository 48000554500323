<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="60%">
      <h1 class="header-title text-capitalize pb-0" mat-dialog-title translate>{{data?.title | titlecase}}</h1>
      <p *ngIf="!data?.data?.hideActions" class="mb-1 text-white ml-3">{{data?.dialogDescription | titlecase}}</p>
      <div *ngIf="data?.data?.hideActions" class="mb-1 text-white ml-3">
        <span *ngIf="data?.dialogDescription.landName">{{data?.dialogDescription.landName | titlecase}},</span>
        <span class="ml-1">{{data?.dialogDescription.location.locationLong | titlecase}}</span>
      </div>
    </div>
    <div fxFlex="40%" fxLayoutAlign="end center" *ngIf="data?.data?.showDownloadButton">
      <button mat-icon-button class="download-button"
        title="Download land data zip file"
        (click)="downloadZipFile()" color="primary">
        <mat-icon>get_app</mat-icon>
      </button>
    </div>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content land-details-dialog">
    <!-- Land details -->
    <kl-land-details [isLandEditable]="data?.data?.hideActions" [details]="data?.data?.landDetails" (openLandDetailsDialog)="openLandDetailsEditDialog()"></kl-land-details>
    <!--/ Land details -->

    <kl-land-details-agro-tagging
      [isLandEditable]="data?.data?.hideActions"
      [details]="data?.data?.landDetails"
      (seasonEdit)="openSeasonEditDialog($event)"
      [assistedProfileKey]="data?.data?.assistedProfileKey">
    </kl-land-details-agro-tagging>
  </div>
</div>
