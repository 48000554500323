import { Component, Inject, OnInit } from '@angular/core';
import {
  KalgudiMobileDialog,
  KL_MOBILE_DIALOG_DATA,
  MobileDialogConfig,
  KalgudiMobileDialogRef
} from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-crop-mobile-dialog',
  templateUrl: './crop-mobile-dialog.component.html',
  styleUrls: ['./crop-mobile-dialog.component.scss']
})
export class CropMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  cropFormDetails: any;
  profileKey: any;
  pageId: any;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);

    this.profileKey = this.dialogData.data.profileKey;
    this.cropFormDetails = this.dialogData.data.cropFormDetails;
    this.pageId = this.dialogData.data.pageId;
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
