<!-- Search bar -->
<div class="mb-3" fxLayoutAlign="center center">
  <kl-search class="search w-100"
    placeHolder="Type and hit enter to search tasks"
    (search)="search();"
    [searchForm]="searchForm">
  </kl-search>
</div>
<!-- /Search bar -->

<div *ngIf="((stream$ | async) || true) as stream" id="task-list-grid">
  <div fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.xs="column">
    <div fxFlex="100%" *ngFor="let task of stream; let i = index" class="task-list">
      <div [@floatTop]="{ value: '', params: {delay: i * 50} }" class="task">
        <kl-task-tile
          [taskDetails]="task"
          (delete)="deleteTask(task?.taskId)"
          (addMembers)="showMembersManagementDialog(task?.projectId, task?.taskId)"
          (openFullview)="openTaskFullviewDialog(task?.title, task?.taskId, task, task?.projectId)"
          (updateTask)="openEditTaskDialog(task?.taskId, task?.projectId)"
          (showTaskStats)="openTaskStatsDialog($event, task?.taskId, task?.title)"></kl-task-tile>
      </div>
    </div>
  </div>

  <div class="mt-3" *ngIf="paginator$ | async as paginator">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column">
      <ng-container *ngIf="!stream || stream?.length <= 0">
        <div>
          <kl-no-content
            svgIcon= "assets/svgs/tasks.svg"
            text= "Did not create any tasks for this page yet!"
            progressText="Loading your tasks..."
            [progress]="paginator?.loading"
            (action)="openTaskCreationDialog()">
          </kl-no-content>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="paginator && stream?.length > 0">
      <kl-load-more
        [noMoreText]="'No more task to show!' | translate"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </ng-container>
  </div>
</div>

<div *ngIf="projectId && memberRole === memberRoles.ADMIN || memberRole === memberRoles.CONTRIBUTOR">
  <kl-float-button matIcon="add" (clicked)="showAddTaskCreationDialog()"></kl-float-button>
</div>
