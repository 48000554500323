import { Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiTaskFullView } from '@kalgudi/project-manager';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PageTaskMembersDialogComponent } from '../components/page-task-members-dialog/page-task-members-dialog.component';
import {
  PageTaskMembersMobileDialogComponent,
} from '../components/page-task-members-mobile-dialog/page-task-members-mobile-dialog.component';

@Directive()
export abstract class KalgudiPageTaskFullView extends KalgudiTaskFullView {

  @Input()
  pageId: string;

  private dialogsService: KalgudiDialogsService;

  constructor(
    protected injector: Injector
    ) {
      super(injector);

      this.dialogsService          = this.injector.get(KalgudiDialogsService);
    }

  /**
   * Shows project page members management dialog
   */
  showMembersManagementDialog(projectId: string, taskId: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Task members',
      acceptButtonTitle: 'Add members',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId,
        taskId,
        pageId: this.pageId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openPageTaskMembersManagementDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        // filter(r => r.accepted),
      );
  }

  /**
   * Shows the page task members management web or mobile dialog
   */
  private openPageTaskMembersManagementDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(PageTaskMembersMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(PageTaskMembersDialogComponent, dialogConfig, matDialogConfig);
    }

  }


}
