import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { LazyApiSyncInterceptor } from '../model';

@Injectable()
export class OnNetworkApiSyncService implements LazyApiSyncInterceptor {

  pauseSync$: Observable<boolean>;
  resumeSync$: Observable<boolean>;

  private pauseSyncSubject  = new Subject<boolean>();
  private resumeSyncSubject = new Subject<boolean>();

  constructor() {
    this.pauseSync$  = this.pauseSyncSubject.asObservable();
    this.resumeSync$ = this.resumeSyncSubject.asObservable();

    this.attachBrowserOnlineEvent();
  }

  /**
   * Attach online and offline browser events to the stream
   */
  private attachBrowserOnlineEvent(): void {
    window.ononline  = () => this.resumeSyncSubject.next(true);
    window.onoffline = () => this.pauseSyncSubject.next(true);
  }
}
