import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, S3PolicyPathCategoryMap } from '@kalgudi/types';

import { KalgudiDialog } from '../../kalgudi-dialogs';

@Component({
  selector: 'kl-audio-attachment-dialog',
  templateUrl: './audio-attachment-dialog.component.html',
  styleUrls: ['./audio-attachment-dialog.component.scss']
})
export class AudioAttachmentDialogComponent extends KalgudiDialog<AudioAttachmentDialogComponent> implements OnInit {

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  audioAttachment = new FormControl(null);

  constructor(
    protected dialogRef: MatDialogRef<AudioAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }


  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        attachment: this.audioAttachment.value
      }
    };

    this.closeDialog(result);
  }

}
