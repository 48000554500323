import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiError, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiProgramDetails, PageType, ProgramPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiCreatePageApiService {


  /**
   * `v2/pages`
   */
  private readonly API_PAGES = `${this.environment.restBaseUrlV2}/pages`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Calls create page api
   * @param req
   */
  createPage(req: ProgramPageDetails, pageType: PageType): Observable<KalgudiProgramDetails> {

    const params = {
      pageType
    };

    return this.httpClient
      .post<ApiResponseCommon>(this.API_PAGES, req, { params })
      .pipe(
        map(res => this.createPageErrorHandler(res)),
        map(r => this.util.toJson<KalgudiProgramDetails>(r.data))
      );
  }


  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * It verifies for successful response
   * @param res
   */
  createPageErrorHandler(res: ApiResponseCommon): ApiResponseCommon {

    // create page error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.CREATED) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful page creation
    return res;
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------


}
