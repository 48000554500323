import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService } from '@kalgudi/core';
import { BottomSheetMenu, KALGUDI_PAGE_RELATION_MAP, KalgudiPageRelation, KalgudiUser, UIMenu } from '@kalgudi/types';

import { KalgudiSmsTile } from '../../classes/kalgudi-sms-tile';

@Component({
  selector: 'kl-publish-sms-tile',
  templateUrl: './publish-sms-tile.component.html',
  styleUrls: ['./publish-sms-tile.component.scss']
})
export class PublishSmsTileComponent extends KalgudiSmsTile implements OnInit {

  @Input()
  memberRole: KalgudiPageRelation;

  @Input()
  scheduledUpdates = false;

  @Output()
  postUpdated = new EventEmitter();

  userDetails: KalgudiUser;

  authorId: string;

  listMenu: BottomSheetMenu;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  constructor(
    protected injector: Injector,
    private appService: KalgudiAppService,
    private bottomSheetService: KalgudiBottomSheetService
  ) {
    super(injector);
    this.userDetails = this.appService.profileLocal;
  }

  ngOnInit() {
    this.authorId = this.sms.author.profileKey;
    this.listMenu = {
      title: '',
      styleType: 'list',
      menu: [
        {
          title: 'Update',
          id: 'UPDATE',
          visible : !this.scheduledUpdates
        },

      ]
    };
  }

  onDestroyed(): void {}

  openBottomSheet(): void {

    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * calls after updating the post successfully
   */
  protected onPostUpdated(res): void {
    this.postUpdated.emit(res);
  }


  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {
      case 'UPDATE':
        this.updateSms();
        break;

    }
  }

}
