<main id="survey-basic-details">

  <!-- Survey details -->
  <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="start center">
    <img *ngIf="surveyBasicDetails?.logo" [src]="surveyBasicDetails?.logo | prefixDomain" width="50" height="50">
    <img *ngIf="!surveyBasicDetails?.logo" src="assets/images/survey-user.png" width="50" height="50">

    <div>
      <div>
        <a class="text-capitalize survey-title mb-0 text-truncate d-inline-block mw-100">
          {{ surveyBasicDetails?.title }}
        </a>
        <!-- <span class="mb-0 text-muted"
          [title]="TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">{{ TS | timeAgo }} ago</span> -->
      </div>
      <p class="mb-0">
        <span class="published-text" *ngIf="surveyBasicDetails?.isPublished" translate>Published</span>
        <span class="dot d-inline-block align-middle mx-2" *ngIf="surveyBasicDetails?.isPublished"></span>
        <span class="expires-text"><span translate>Expires on</span>
          {{ surveyBasicDetails?.expiryDate | date:'MMM' | translate}}
          {{ surveyBasicDetails?.expiryDate | date:'dd' }},
          {{ surveyBasicDetails?.expiryDate | date:'yyyy' }}
        </span>
      </p>
    </div>

  </div>
  <!--/ Survey details -->

  <!-- Description -->
  <div class="my-3">
    <p class="mb-0 text-content"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="3">
      {{ surveyBasicDetails?.description }}
    </p>
  </div>
  <!--/ Description -->

</main>
