import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseCommonV1, NotificationsList, NotificationSocialCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class KalgudiHomeStreamApiService {

  /**
   * `v1/feed/home`
   */
  private readonly API_HOME_STREAM = `${this.env.restBaseUrl}/feed/home`;

  /**
   * /v2/pages/cem/sms-templates
   */
  private readonly SMS_TEMPLATES_LIST = `${this.env.restBaseUrlV2}/pages/cem/sms-templates`;


  /**
   * /v2/profiles/{profileKey}/lands/latestAdvisory
   */
  private readonly REFRESH_LATEST_ADVISORY = `${this.env.restBaseUrlV2}/profiles/:profileKey/lands/latestAdvisory`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches home stream direct from API. You must fetch the home stream
   * from API after first 40 calls from the
   *
   * @param profileKey Logged in user profile key
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchHomeStream(profileKey: string, offset: number, limit: number): Observable<NotificationsList[]> {

    const params = {
      profilekey: profileKey,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_HOME_STREAM, { params })
      .pipe(
        // Handler API errors
        map(res => this.homeStreamResponseHandler(res)),

        // Map API response to array of common notifications object
        map(res => this.mapHomeStreamResponse(res))
      );
  }

  /**
   * Api to get sms templates list
   */
  getSmsTemplateList(): Observable<any> {

    const params = {
      appStoreType: 'BIZ_STORE',
      appName: 'UNIFIED',
      appStoreDomain: this.env.domain
    }

    return this.httpClient
      .get<ApiResponseCommon>(this.SMS_TEMPLATES_LIST, {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data.results)
      );
  }

  /**
   * Api to get latest advisory
   */
  getLatestAdvisory(profileKey: string, landId: string): Observable<any> {

    const params = {
      landId:  landId,
    }

    const url = this.REFRESH_LATEST_ADVISORY.replace(':profileKey', profileKey);

    return this.httpClient
      .get<ApiResponseCommon>(url, {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)

      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Asserts home stream response for any errors. Throws appropriate
   * error on failed API response.
   *
   * @param res Home stream API response
   */
  private homeStreamResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Validate for error messages
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps API response to common notification array.
   */
  private mapHomeStreamResponse(res: ApiResponseCommonV1): NotificationSocialCommon[] {

    return this.util.toJson<NotificationSocialCommon[]>(res.data);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
