<div id="kalgudi-qa-form" class="p-3">
  <kl-common-qa-form
    [showPageSelection]=true
    [shareForm]="shareForm"
    [shareFormText]="shareFormText"
    [attachments]="shareFormAttachments?.value"
    [textFieldPlaceHolder]="'Enter your question here and for better answers attach images of pest, condition or product.' | translate"
    [progress]="progress"
    submitBtnText="Ask"
    (shareQaPost)="sharePost($event)"
    [rowsCount]="rowsCount"
    [compactView]="isMobileDevice"
    [showCamera]="isMobileDevice"
    [audioAttachment]="audioAttachment"
    (removeAttachment)="removeImage($event)">
  </kl-common-qa-form>
</div>
