<ng-container *ngIf="((stream$ | async) || true) as stream">

  <div fxLayout="column" fxLayoutGap="15px">
    <ng-container *ngFor="let item of stream">
      <!-- Robocall tile -->
      <kl-robocall-tile [postDetails]="item" [scheduledUpdates]="true">
      </kl-robocall-tile>
      <!-- / Robocall tile -->
    </ng-container>
  </div>

  <div class="m-3" *ngIf="paginator$ | async as paginator">
    <!-- No content -->
    <div fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngIf="!stream || stream?.length <= 0">
        <kl-no-content
        svgIcon ="assets/svgs/robocall.svg"
        text="No Robocall initiated from this program yet!"
        iconSize="md"
        progressText="Loading scheduled robocall stream..."
        [progress]="paginator?.loading"
        >
        </kl-no-content>

      </ng-container>
    </div>
    <!--/ No content -->

    <!-- Load More -->
    <ng-container *ngIf="stream?.length > 0">

      <kl-load-more
      [progress]="paginator?.loading"
      [hasMoreItems]="paginator?.hasItems"
      (clicked)="nextPage()">
      </kl-load-more>
      <!-- / Load More -->

    </ng-container>
  </div>

</ng-container>
