import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonListData,
  PageAnalytics,
  PageDigitalAssistAnalytics,
  PageProjectAnalytics,
  PageQaAnalytics,
  PageShareAnalytics,
  PageTaskAnalytics,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiPageAnalyticsApiService {

  /**
   * `v2/pages/analytics`
   */
  private readonly API_PAGE_ANALYTICS = `${this.env.restBaseUrlV2}/pages/analytics`;

  /**
   * `v2/pages/analytics/members`
   */
  private readonly API_PAGE_COVERAGE_ANALYTICS = `${this.env.restBaseUrlV2}/pages/analytics/members`;

  /**
   * `v2/social/analytics/shares`
   */
  private readonly API_PAGE_SHARES_ANALYTICS = `${this.env.restBaseUrlV2}/social/analytics/shares`;

  /**
   * `v2/social/analytics/qa`
   */
  private readonly API_PAGE_QA_ANALYTICS = `${this.env.restBaseUrlV2}/social/analytics/qa`;

  /**
   * `v2/social/analytics/surveys`
   */
  private readonly API_PAGE_SURVEYS_ANALYTICS = `${this.env.restBaseUrlV2}/social/analytics/surveys`;

  /**
   * `v2/projects/analytics/projects`
   */
  private readonly API_PAGE_PROJECTS_ANALYTICS = `${this.env.restBaseUrlV2}/projects/analytics/projects`;

  /**
   * `v2/projects/analytics/tasks`
   */
  private readonly API_PAGE_TASKS_ANALYTICS = `${this.env.restBaseUrlV2}/projects/analytics/tasks`;

  /**
   * `/v2/pages/:pageId/analytics/digital-assistants`
   */
  private readonly API_PAGE_DA_ANALYTICS = `${this.env.restBaseUrlV2}/pages/:pageId/analytics/digital-assistants`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Fetches kalgudi shares stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchPageAnalytics(pageId: string, filters: PartialData = {}): Observable<PageAnalytics> {

    // Query params
    const params = {
      pageId
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(err => this.util.apiErrorHandler(err)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi page coverage stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchPageCoverageAnalytics(pageId: string, groupBy: string): Observable<ApiResponseCommonListData> {

    // Query params
    const params = {
      pageId,
      groupBy
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_COVERAGE_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(err => this.util.apiErrorHandler(err)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi shares stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchSharesAnalytics(pageId: string): Observable<PageShareAnalytics> {

    // Query params
    const params = { pageId };

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_SHARES_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(err => this.util.apiErrorHandler(err)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi qa stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchQaAnalytics(pageId: string): Observable<PageQaAnalytics> {

    // Query params
    const params = { pageId, };

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_QA_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi surveys stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchSurveyAnalytics(pageId: string): Observable<any> {

    // Query params
    const params = {
      pageId,
    };

    return this.httpClient.get<any>(this.API_PAGE_SURVEYS_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi projects stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchProjectsAnalytics(pageId: string): Observable<PageProjectAnalytics> {

    // Query params
    const params = { pageId, };

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGE_PROJECTS_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi tasks stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchTasksAnalytics(pageId: string): Observable<PageTaskAnalytics> {

    // Query params
    const params = {
      pageId,
    };

    return this.httpClient.get<any>(this.API_PAGE_TASKS_ANALYTICS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches kalgudi digital assistance stats for the specified page.
   *
   * @param pageId Unique id of entity
   */
  fetchPageDaAnalytics(pageId: string): Observable<PageDigitalAssistAnalytics> {

    // Query params
    const params = { pageId };

    const url = this.API_PAGE_DA_ANALYTICS.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(err => this.util.apiErrorHandler(err)),

        // No errors, all good return true
        map(res => res.data),
      );
  }
}
