import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

import { transformMassArea } from '../mass-area-unit-conversion.pipe';
import { SatelliteAdvisoriesService } from '../satellite-advisories.service';

@Component({
  selector: 'kl-nitrogen-details',
  templateUrl: './nitrogen-details.component.html',
  styleUrls: ['./nitrogen-details.component.scss']
})
export class NitrogenDetailsComponent implements OnInit, OnChanges {

  @ViewChild('satImg', { static: true })
  satImgRef: ElementRef;

  @Input()
  nitrogenImage: string;

  @Input()
  nitrogenDetails: any;

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  bbox: number[] = [];

  @Input()
  estimatedTime: string;

  showMap: boolean = false;

  boundaryPoints: string = '';

  status: 'low' | 'good' | 'more';

  nitrogenPerHectare: number;
  nitrogenPerAcre: number;
  nitrogenPerFeddan: number;

  constructor(
    private satelliteAdvisoriesService: SatelliteAdvisoriesService,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.landDetails && this.bbox && !this.boundaryPoints) {
      this.boundaryPoints = this.satelliteAdvisoriesService.convertLatLongToPoints(
        this.landDetails.geoFences,
        this.satImgRef.nativeElement.offsetWidth,
        this.satImgRef.nativeElement.offsetHeight,
        this.bbox
      );
    }

    if (changes.nitrogenDetails && this.nitrogenDetails) {
      this.calculateStatus();
    }
  }

  showLandMap() {
    this.showMap = !this.showMap;
  }

  private calculateStatus() {
    this.nitrogenPerHectare = this.nitrogenDetails.averageLevel;
    this.nitrogenPerAcre = this.nitrogenPerHectare * 2.47105;
    this.nitrogenPerFeddan = transformMassArea(this.nitrogenPerHectare, 'feddan'); // this.nitrogenPerAcre * 1.03;

    // console.log(this.nitrogenDetails, this.nitrogenPerHectare, this.nitrogenPerAcre, this.nitrogenPerFeddan);

    if (this.nitrogenPerFeddan < 70) {
      this.status = 'low';
    } else if (this.nitrogenPerFeddan > 80) {
      this.status = 'more';
    } else {
      this.status = 'good';
    }
  }

}
