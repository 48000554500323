import { Inject, Injector, Directive } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaStreamStateService } from '@kalgudi/social';
import { KALGUDI_PAGE_RELATION_MAP, ProgramQaFullDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

import { KalgudiPageInboxStream } from '../../../classes/kalgudi-page-inbox-stream';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiProgramQaService } from '../services/kalgudi-program-qa.service';

@Directive()
export abstract class KalgudiProgramQaStream extends KalgudiPageInboxStream<ProgramQaFullDetails> {

  memberRole: string;
  isSecondHit: boolean = false;
  memberRoles = KALGUDI_PAGE_RELATION_MAP;
  isProgress: boolean;

  private kalgudiProgram: KalgudiPageService;


  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected updatesStream: KalgudiProgramQaService,
    protected qaStreamState: KalgudiQaStreamStateService,
  ) {

    super(notification, util);

    this.kalgudiProgram = this.injector.get(KalgudiPageService);


    this.kalgudiProgram.pageDetails$
    .pipe(
      first(),
    ).subscribe(pageDetails => {
      this.memberRole = pageDetails.memberRole;
    });
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
  if (this.isSecondHit) {
    return this.updatesStream.getQaStream(this.pageId, offset, limit, this.fromDate, this.toDate)
    .pipe(finalize(() => (this.isProgress = false)));
  } else {
    return this.updatesStream.getQaStream(this.pageId, offset, limit)
    .pipe(finalize(() => (this.isProgress = false)));
    }
  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<ProgramQaFullDetails> {
    return this.qaStreamState.newStreamItem$;
  }

}
