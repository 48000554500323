import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import { IdValueMap, LabelValueMap } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  KalgudiShareTargetMembersService,
} from '../../modules/share-target-members/services/kalgudi-share-target-members.service';

@Directive()
export abstract class ShareActivityFilters extends KalgudiDestroyable {

  @Input()
  pageId = '';

  @Input()
  projectId = '';

  @Input()
  taskId = '';

  @Input()
  shareForm: FormGroup;

  @Input()
  showTags = true;

  @Input()
  shareVisibilityList: LabelValueMap[] = [];

  @Input()
  hideShareVisibility = false;

  @Input()
  hideProductsFilter: boolean = false;

  @Input()
  hideBusinessFilter: boolean = false;

  readonly sharedVisibilities = SHARE_TYPES;

  memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }>;

  private kalgudiShareTargetMembers: KalgudiShareTargetMembersService;
  private kalgudiShareTargetMembersService: KalgudiShareTargetMembersService;

  constructor(
    protected injector: Injector,
  ) {
    super();

    // Inject all dependencies
    this.kalgudiShareTargetMembers        = this.injector.get(KalgudiShareTargetMembersService);
    this.kalgudiShareTargetMembersService = this.injector.get(KalgudiShareTargetMembersService);


    this.memberTargetingFilters$ = this.kalgudiShareTargetMembers.memberTargetingFilters$;
  }

  onDestroyed(): void { }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.shareFormVisibility.value;
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * Share form program messages users filters field
   */
  get shareFormFilterUsers(): FormArray {
    return this.shareFormFilter.get('users') as FormArray;
  }

  /**
   * Share form program messages products filters field
   */
  get shareFormFilterProducts(): FormArray {
    return this.shareFormFilter.get('products') as FormArray;
  }

  /**
   * Share form program messages business types filter field
   */
  get shareFormFilterBusinessTypes(): FormArray {
    return this.shareFormFilter.get('businessTypes') as FormArray;
  }

  /**
   * Share form program messages locations filter field
   */
  get shareFormFilterLocations(): FormArray {
    return this.shareFormFilter.get('locations') as FormArray;
  }

  /**
   * Share form program messages countries filter field
   */
  get shareFormFilterCountries(): FormArray {
    return this.shareFormFilter.get('countries') as FormArray;
  }

  /**
   * Share form program messages states filter field
   */
  get shareFormFilterStates(): FormArray {
    return this.shareFormFilter.get('states') as FormArray;
  }

  /**
   * Share form program messages districts filter field
   */
  get shareFormFilterDistricts(): FormArray {
    return this.shareFormFilter.get('districts') as FormArray;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Update filters id for specific groups
   * @param filtersId
   */
  updatePageIdFilters(filtersId: string) {
    this.kalgudiShareTargetMembersService.updatePageIdFilters(filtersId);
  }

  /**
   * Update filters id for specific groups
   * @param taskId
   * @param projectId
   */
  updateTaskAndProjectIdFilters(projectId: string, taskId?: string) {
    this.kalgudiShareTargetMembersService.updateTaskAndProjectIdFilters(projectId, taskId);
  }

  /**
   * Event handler for share visibility change. It resets targeted users list,
   * targeted countries, states, districts and locations list.
   */
  clearShareFormTargetingFilters(): void {

    // Clear users list
    this.shareFormFilterUsers.reset();

    // Clear all other filters
    this.shareFormFilterProducts.clear();
    this.shareFormFilterBusinessTypes.clear();

    // Clear location filters
    this.shareFormFilterLocations.clear();
    this.shareFormFilterCountries.clear();
    this.shareFormFilterStates.clear();
    this.shareFormFilterDistricts.clear();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
