import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDialogConfig, ScheduleDates, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'kl-schedule-send',
  templateUrl: './schedule-send.component.html',
  styleUrls: ['./schedule-send.component.scss']
})
export class ScheduleSendComponent implements OnInit {

  @Input()
  postDetails: ShareUpdate;

  @Output()
  scheduledDate = new EventEmitter<ScheduleDates>();

  today = new Date();
  selectedDate: string;
  selectedTime = new FormControl('00:00 am');

  scheduledDateAndTime: string;

  constructor(
    private dialogsService: KalgudiDialogsService
  ) {

    this.selectedDate = this.today.toISOString();

    this.selectedTime.valueChanges.subscribe(
      val =>  this.setTimeAndDate(val)
    );

  }

  ngOnInit() {


    if (this.postDetails) {
      this.selectedDate = this.postDetails.scheduledDate;
      this.selectedTime.patchValue(this.postDetails.scheduledTime);
    }


    // Sets the date for default date and time
    this.setTimeAndDate(this.selectedTime.value);

  }

  /**
   * Calls on date selection
   */
  onSelection(date: Date): void {

    this.selectedDate = date.toISOString();
    this.setTimeAndDate(this.selectedTime.value);

  }

  /**
   * Emits back the scheduled date to the parent on confirmation
   */
  scheduleSend(): void {

    this.showDeleteConfirmationDialog()
      .subscribe(
        res => {
          this.scheduledDate.emit({
            scheduledTS: this.scheduledDateAndTime,
            scheduledDate: this.selectedDate,
            scheduledTime: this.selectedTime.value
          });
        }
      );
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm send',
      acceptButtonTitle: 'Ok',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to schedule this update?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '400px',
      maxWidth: '400px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }

  /**
   * Sets the selected date and selected time into the one single date
   */
  private setTimeAndDate(time: string) {

    const date = new Date(this.selectedDate);

    const dateAndTime = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${time}`;

    this.scheduledDateAndTime = new Date(dateAndTime).toISOString();

  }


}
