<div id="share-visibility">
  <mat-form-field appearance="outline" color="accent" class="w-100 mat-form-field-sm input-field selection" >
    <mat-label translate>Share with</mat-label>
    <mat-select [formControl]="visibilityForm" panelClass="mat-select-sm">
      <mat-option *ngFor="let item of shareVisibilityList" [value]="item?.value">
        {{ item.label | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

