import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DEFAULT_PROFILE_PIC, KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';

@Component({
  selector: 'kl-social-comments-form',
  templateUrl: './social-comments-form.component.html',
  styleUrls: ['./social-comments-form.component.scss']
})
export class SocialCommentsFormComponent implements OnInit {

  @Input()
  kuberPage: boolean;

  @Input()
  commentForm: FormGroup;

  @Input()
  progress: boolean;

  @Output()
  commented = new EventEmitter<FormGroup>();

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    private app: KalgudiAppService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {
    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {
  }

  get commentText(): string {
    return this.commentForm.get('text').value;
  }

  /**
   * Gets, Kalgudi logged in user profile details
   */
  get profile$(): Observable<KalgudiUser> {
    return this.app.profile$;
  }

  /**
   * Emits an event to the parent
   */
  comment(): void {
    this.commented.emit(this.commentForm);
  }
}
