export enum SurveyPageActions {
  FETCH_SURVEY              = 'FETCH_SURVEY',
  SURVEY_UPDATED            = 'SURVEY_UPDATED',
  SURVEY_RESET              = 'SURVEY_RESET',
  ADD_QUESTION              = 'ADD_QUESTION',
  EDIT_SURVEY               = 'EDIT_SURVEY',
  DELETE_SURVEY             = 'DELETE_SURVEY',
  ADD_MEMBER                = 'ADD_MEMBER',
  MEMBER_ADDED              = 'MEMBER_ADDED',
  ADD_ADMIN                 = 'ADD_ADMIN',
  SURVEY_LIST_FILTER_UPDATE = 'SURVEY_LIST_FILTER_UPDATE',
  PUBLISH_SURVEY            = 'PUBLISH_SURVEY',
  UN_PUBLISH_SURVEY         = 'UN_PUBLISH_SURVEY',
  UPDATE_LOGO               = 'UPDATE_LOGO',
  SEND_REMINDER             = 'SEND_REMINDER'
}

export const surveyQuestionTypeLabelMap = {
  YES_NO          : 'Yes/No',
  TEXT            : 'Text',
  RATING          : 'Rating',
  DATE            : 'Date',
  MULTIPLE_CHOICE : 'Multiple choice',
  MULTIPLE_SELECT_CHECKBOX : 'Multiple select checkbox',
};

export const SURVEY_TABS = {
  Logs: 'logs',
  QUESTIONS: 'questions',
  INFO: 'info',
  ADMINS: 'admins',
  MEMBERS: 'members'
};

export const ANALYTICS_CHART_GRADIENT_COLORS = [
  '#DC3545', // Red

  '#35E298', // Green

  '#4BA9FF', // Blue

  '#EBB62D', // Yellow,

  '#0071bc', // App header blue

  '#eea236', // Orange

  '#00a0bc', // Accent color

  '#1258a4', // Link color
 ];
