import { Component, Inject, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { SocialSharesService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { BottomSheetMenu, UIMenu } from '@kalgudi/types';

import { KalgudiProfileRouteConfig } from '../../../../config';
import { KalgudiOrgProfileHeader } from '../../classes/kalgudi-org-profile-header';
import { OrganisationProfileService } from '../../services/organisation-profile.service';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'kl-cooperative-society-profile-header',
  templateUrl: './cooperative-society-profile-header.component.html',
  styleUrls: ['./cooperative-society-profile-header.component.scss']
})
export class CooperativeSocietyProfileHeaderComponent extends KalgudiOrgProfileHeader implements OnInit, OnChanges {

  @Input()
  userProfileKey: string;

  @Input()
  basicInfoFormDetails: any;

  showProfileShare: boolean;
  isPublicPage: boolean;
  isMicrositeApp: boolean;
  isSamId: boolean;

  url: string;

  profileKey: string;
  fpoStats: any;
  basicInfoDetails: any;

  listMenu: BottomSheetMenu = {
    title: 'Share event on social media',
    styleType: 'list',
    menu: [
      {
        id: 'SHARE_TO_FACEBOOK',
        title: 'Share to Facebook',
        faIcon: 'fab fa-facebook-f',
        iconColor: '#385898'
      },
      {
        id: 'SHARE_TO_TWITTER',
        title: 'Share to Twitter',
        faIcon: 'fab fa-twitter',
        iconColor: '#1da1f2'
      },
      {
        id: 'SHARE_TO_LINKEDIN',
        title: 'Share to Linkedin',
        faIcon: 'fab fa-linkedin-in',
        iconColor: '#544bc2'
      },
      {
        id: 'SHARE_TO_WHATSAPP',
        title: 'Share to Whatsapp',
        faIcon: 'fab fa-whatsapp',
        iconColor: '#0ee00e'
      },
      {
        id: 'SHARE_TO_EMAIL',
        title: 'Share to Email',
        faIcon: 'fas fa-envelope-square',
        iconColor: '#d33f2b'
      }
    ]
  };

  constructor(
    private socialShareService: SocialSharesService,
    private bottomSheetService: KalgudiBottomSheetService,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private kalgudiApp: KalgudiAppService,
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProfileRouteConfig,
    private organisationProfileService: OrganisationProfileService,
    private router: Router,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    private route: ActivatedRoute
  ) {

    super(injector);
    if (this.kalgudiApp.profileLocal && this.kalgudiApp.profileLocal.profileKey) {
      this.profileKey = this.kalgudiApp.profileLocal.profileKey;
    }

    // AppId condition for Sam_FPO & Sam_farmers
    this.isSamId = this.environment?.appId === 'SAM_FPO' || this.environment.appId === 'SAM_FARMERS';
  }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
    this.isMicrositeApp = this.environment?.appId === 'SAM_FPO' || this.environment?.appId === 'FPO_APP' || this.environment?.appId === 'FPO_NET_APP';

    if(this.isPublicPage) {
      this.profileKey = this.route.snapshot.params.profileKey;
    }

    this.fetchFarmersCount();
  }

  /**
   * fetch farmers count
   */
  fetchFarmersCount() {
    this.organisationProfileService.fetchFarmersCount(this.profileKey)
      .pipe(
        finalize(() => this.destroyed$)
      )
      .subscribe(
        res => {
          this.fpoStats = res
        }
      )

      this.basicInfoDetails = this.profile?.samunnatiFpoDetails?.basicDetailsInfo?.basicDetails
  }

  ngOnChanges() {

    const profileLocalKey = this.kalgudiApp.profileLocal?.profileKey;

    if(this.profileShareUrl && profileLocalKey) {
      this.url = this.profileShareUrl.replace(':profileKey', profileLocalKey);
    }

    if(this.userProfileKey) {
      this.showProfileShare = this.userProfileKey === profileLocalKey;
    }
  }

  /**
   * Opening menu and bottom sheet depends on device
   */
  openShareButton() {
    this.openBottomSheet();
  }

  /**
   * Open bottom sheet if it is mobile device
   */
  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Share to face book
   */
  shareToFacebook() {
    this.socialShareService.shareEventToFacebook(this.url);
  }

  /**
   * Share to twitter
   */
  shareToTwitter() {
    this.socialShareService.shareEventToTwitter(this.url);
  }

  /**
   * Share to linked in
   */
  shareToLinkedin() {
    this.socialShareService.shareEventToLinkedIn(this.url);
  }

  /**
   * Share to whatsapp web
   */
  shareToWhatsappWeb() {
    this.socialShareService.shareEventToWhatsappWeb(this.url);
  }

  /**
   * Share to whatsapp mobile
   */
  shareToWhatsappMobile() {
    this.socialShareService.shareEventToWhatsappMobile(this.url);
  }

  /**
   * Share to email web
   */
  shareToEmailWeb() {
    this.socialShareService.shareEventToEmailWeb(this.url);
  }

  /**
   * Share to email mobile
   */
  shareToEmailMobile() {
    this.socialShareService.shareEventToEmailMobile(this.url);
  }

  /**
   * Navigating to fpo admin app
   */
  fpoAdminApp() {
    this.appRouting.toFpoAdminApp();
  }

  /**
   * Copy public url on click
   */
  clickToCopyUrl() {
    let domain = (this.environment.appId === 'SHAKTIMAN') ? 'https://core.shaktimanfarmingsolutions.com' : this.environment.coreDomain;
    let url = `${domain}${this.url.replace('/public','/#/public')}`;
    navigator.clipboard.writeText(url);
    this.notification.showMessage('Copied to clipboard');
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'SHARE_TO_FACEBOOK':
        this.shareToFacebook();
        break;

      case 'SHARE_TO_TWITTER':
        this.shareToTwitter();
        break;

      case 'SHARE_TO_LINKEDIN':
        this.shareToLinkedin();
        break;

      case 'SHARE_TO_WHATSAPP':
        this.shareToWhatsappMobile();
        break;

      case 'SHARE_TO_EMAIL':
        this.shareToEmailMobile();
        break;
    }
  }

  onDestroyed(): void {}

}
