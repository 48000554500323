<main id="members-list">

  <div class="members-header py-2 mb-2" fxLayout fxLayoutAlign="space-between center">
    <div fxFlex="60%" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <p class="m-0 title font-weight-bold" translate>Name</p>
    </div>

    <div fxFlex="30%">
      <p class="m-0 title font-weight-bold" translate>Designation</p>
    </div>

    <div fxFlex="10%"></div>
  </div>

  <ng-container *ngIf="!boardOfMembers?.length">
    <p class="text-muted pt-2 text-center w-100 no-content">No members added yet!</p>
  </ng-container>

  <section class="list overflow-auto" *ngIf="boardOfMembers?.length">

    <div  *ngFor="let member of boardOfMembers; let last = last; let index = index">
      <div class="farmer-crops-header" fxLayout fxLayoutAlign="space-between center">
        <div fxFlex="60%" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <p class="m-0 title font-weight-500 w-100 text-truncate" translate>{{ member?.name }}</p>
          <p class="m-0 text-muted" translate *ngIf="loggedInUserBusinessTypeName === availableBusinessTypeNames.FPO || loggedInUserNativeBusinessTypeName === nativeBusinessTypeNames.PRODUCER_ORG">{{ member?.mobile }}</p>
        </div>

        <div fxFlex="32%" [ngClass]="{'view-all-dest' : hideActions}">
          <p class="m-0 title text-secondary w-100 text-truncate" translate>{{ member?.designation || '-'}}</p>
        </div>

        <div fxFlex="8%" fxLayout fxLayoutAlign="end end" fxLayoutAlign.xs="start start" *ngIf="editable && !hideActions">
          <button mat-icon-button class="action-buttons bg-danger" (click)="removeMember(index)">
            <mat-icon class="action-icons text-white">clear</mat-icon>
          </button>
        </div>
      </div>

      <div class="border-bottom pt-2 mb-2">
      </div>

    </div>
  </section>
</main>

