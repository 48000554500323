<button mat-flat-button
  [color]="buttonColor || 'primary'"
  class="w-100 mt-0 kl-flat-btn kl-btn mat-flat-button"
  [type]="type"
  [disabled]="disabled || progress"
  (click)="onClick($event)">
  <span>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
      <span class="flat-btn-label" translate>{{ label }}</span>
      <mat-spinner *ngIf="progress" class="dim-spinner" [@slideRight]
        [diameter]="20"
        color="warn">
      </mat-spinner>
    </div>
  </span>
</button>
