import { Component, OnInit } from '@angular/core';

import { IAgGridCellRenderer } from '../../model/index';

@Component({
  selector: 'kl-admin-ag-grid-quantity-unit-renderer',
  template: `
  <span>{{ cell?.row?.value }} {{cell?.row?.unit}}</span>
  `,
  styles: []
})
export class AgGridQuantityUnitRendererComponent implements OnInit, IAgGridCellRenderer {

  public static componentId = 'AgGridQuantityUnitRendererComponent';

  private params: any;
  public cell: any;

  ngOnInit(): void {}

  agInit(params: any): void {
    this.params = params;
    this.cell = {
      row: params.value,
      col: params.colDef.headerName,
    };
  }

  refresh(): boolean {
    return false;
  }

  formatString(val: any): string {
    try {
      return `${val.value} ${val.unit}`;
    } catch (e) {
      return '';
    }
  }
}
