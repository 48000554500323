<div class="credit-details" fxLayout="column" fxLayoutGap="10px">
  <!-- Program credit details -->
  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="col-6">Total account credits:</div>
    <div class="col-6">{{ creditDetails?.availableCredits + totalCredit }} Credits</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="col-6">User credits:</div>
    <div class="col-6">{{creditDetails?.availableCredits}} Credits</div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <div class="col-6">Total program credits:</div>
    <div class="col-6">{{ totalCredit }} Credits</div>
  </div>

  <!-- Show & hide programs with credit tables -->
  <ng-container *ngIf="pages?.length > 0">
    <a (click)="toggleIsProgramList()" class="col-6">
      {{isProgramList ? '(Hide details)' : '(See details)'}}
    </a>
  </ng-container>

  <!-- Program list with credits -->
  <div class="px-3" [hidden]="!isProgramList">
    <table class="table table-striped mb0" style="border: 1px solid #f1ecec;">
      <thead>
        <tr>
          <th scope="col">Program name</th>
          <th scope="col">Credits</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let page of pages">
          <td>{{page?.pageTitle}}</td>
          <td>{{page?.credit}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Tabs for add and transfer credits -->
  <mat-tab-group mat-align-tabs="start">

    <!-- add -->
    <mat-tab label="Add credits">
      <kl-add-credits></kl-add-credits>
    </mat-tab>

    <!-- transfer -->
    <mat-tab label="Transfer credits">
      <kl-transfer-credits></kl-transfer-credits>
    </mat-tab>

  </mat-tab-group>
</div>
