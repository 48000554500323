<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ data?.title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="dialog-content overflow-auto land-details-form">
    <kl-add-land-details
      [landDetails]="data?.data?.landDetails"
      [isAssisted]="data?.data?.isAssisted"
      [returnGeoTagDetails]="data?.data?.returnGeoTagDetails"
      (landAdded)="onLandAdded($event)"
      (geoTagDetails)="geoTagDetails($event)">
    </kl-add-land-details>
  </div>
</div>
