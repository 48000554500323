import { KalgudiDestroyable } from '@kalgudi/core';
import {
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiPageDetails,
  KalgudiPageRelation,
  KalgudiProgramDigitalAssistanceStats,
  PageType,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { PageActions } from '../constants';
import { KalgudiPageService } from '../services/kalgudi-page.service';
import { ProgramStateService } from '../services/program-state.service';
import { Directive } from "@angular/core";

/**
 * Base class definition for a Kalgudi programs page. It uses `ProgramStateService`
 * to manage program states. On any state change it fetches latest program details and
 * pushes to the program stream.
 *
 * To use this class a child must call its `initProgram()` method to seed initial program
 * details.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiProgram extends KalgudiDestroyable {

  readonly memberRoles = KALGUDI_PAGE_RELATION_MAP;

  /**
   * Gets, latest program details from the program stream.
   */
  readonly pageDetails$: Observable<KalgudiPageDetails>;

  /**
   * Gets, the latest digital assistance stats object from the digital
   * assistance stream.
   */
  readonly digitalAssistanceStats$: Observable<KalgudiProgramDigitalAssistanceStats>;


  /**
   * Program unique Id
   */
  private pageId: string;
  private memberRole: KalgudiPageRelation;
  private pageType: PageType;


  constructor(
    protected pageStateService: ProgramStateService,
    protected kalgudiProgram: KalgudiPageService,
  ) {

    super();

    this.pageDetails$ = this.pageStateService.data$
      .pipe(
        takeUntil(this.destroyed$),

        tap(page => this.onPageStateChange(page)),
      );

    this.digitalAssistanceStats$ = this.pageStateService.action$
      .pipe(
        filter(action => action.type === PageActions.PAGE_DA_STATS_UPDATED),

        map(action => action.payload)
      );
  }



  // --------------------------------------------------------
  // #region Getter and Setters
  // -------------------------------------------------------

  /**
   * Current program id
   */
  get id(): string {
    return this.pageId;
  }

  /**
   * Logged in user role
   */
  get role(): KalgudiPageRelation {
    return this.memberRole;
  }

  /**
   * Current pageType
   */
  get currentPageType(): PageType {
    return this.pageType;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler called after page state change
   */
  protected onPageStateChange(page: KalgudiPageDetails): void {
    this.pageId     = page.pageId;
    this.memberRole = page.memberRole;
    this.pageType   = page.pageType;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
