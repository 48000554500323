import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiDialogsModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SurveyInfoFormComponent } from './components/survey-info-form/survey-info-form.component';
import { SurveyInfoPageComponent } from './components/survey-info-page/survey-info-page.component';
import { SurveyInfoComponent } from './components/survey-info/survey-info.component';



@NgModule({
  declarations: [
    SurveyInfoComponent,
    SurveyInfoFormComponent,
    SurveyInfoPageComponent
  ],
  imports: [
    CommonModule,

    FormsModule,

    ReactiveFormsModule,

    // Material module
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MomentDateModule,

    FlexLayoutModule,

    FlexLayoutModule,

    AngularSvgIconModule,
    TranslateModule,

    // Kalgudi modules
    KalgudiButtonModule,
    KalgudiGooglePlacesModule,
    KalgudiDialogsModule
  ],
  exports: [
    SurveyInfoComponent,
    SurveyInfoFormComponent,
    SurveyInfoPageComponent,
  ],
  providers: [
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]
})
export class KalgudiSurveyInfoModule { }
