import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiAttachUrlModule,
  KalgudiAudioAttachmentModule,
  KalgudiCommonModule,
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
  KalgudiSocialSharesModule,
  KalgudiStoreProductsSearchModule,
  KalgudiTimePickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import {
  FailedHttpResponseInterceptorService,
  KalgudiLazyHttpClientModule,
  LAZY_HTTP_INTERCEPTOR,
} from '@kalgudi/common/lazy-http';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiDirectivesModule, KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiCoreConfig, KL_ENV, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialModule, SatelliteAdvisoriesModule } from '@kalgudi/social';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import {
  PromotionalShareCropsComponent,
} from './components/kuber/promotional-share-crops/promotional-share-crops.component';
import {
  PromotionalShareHeaderComponent,
} from './components/kuber/promotional-share-header/promotional-share-header.component';
import {
  PromotionalShareMultipleProductsComponent,
} from './components/kuber/promotional-share-multiple-products/promotional-share-multiple-products.component';
import {
  PromotionalShareProductsComponent,
} from './components/kuber/promotional-share-products/promotional-share-products.component';
import { PromotionalShareTileComponent } from './components/kuber/promotional-share-tile/promotional-share-tile.component';
import { LikesListDialogComponent } from './components/likes-list-dialog/likes-list-dialog.component';
import { LikesListMobileDialogComponent } from './components/likes-list-mobile-dialog/likes-list-mobile-dialog.component';
import { LikesListComponent } from './components/likes-list/likes-list.component';
import { MobileShareUpdateFormComponent } from './components/mobile-share-update-form/mobile-share-update-form.component';
import { ReShareUpdateDialogComponent } from './components/re-share-update-dialog/re-share-update-dialog.component';
import {
  ReShareUpdateMobileDialogComponent,
} from './components/re-share-update-mobile-dialog/re-share-update-mobile-dialog.component';
import { ShareUpdateActionBarComponent } from './components/share-update-action-bar/share-update-action-bar.component';
import { ShareUpdateFormComponent } from './components/share-update-form/share-update-form.component';
import { ShareUpdateHeaderComponent } from './components/share-update-header/share-update-header.component';
import { ShareUpdateStreamComponent } from './components/share-update-stream/share-update-stream.component';
import { ShareUpdateTileComponent } from './components/share-update-tile/share-update-tile.component';
import {
  TaskActivityUpdatesComponent,
} from './components/task-update-tile/task-activity-updates/task-activity-updates.component';
import { TaskDetailsComponent } from './components/task-update-tile/task-details/task-details.component';
import { TaskFormUpdatesComponent } from './components/task-update-tile/task-form-updates/task-form-updates.component';
import { TaskStatusComponent } from './components/task-update-tile/task-status/task-status.component';
import { TaskUpdateHeaderComponent } from './components/task-update-tile/task-update-header/task-update-header.component';
import { TaskViewTileComponent } from './components/task-update-tile/task-view-tile/task-view-tile.component';
import { UpdatePostDialogComponent } from './components/update-post-dialog/update-post-dialog.component';
import { UpdatePostMobileDialogComponent } from './components/update-post-mobile-dialog/update-post-mobile-dialog.component';
import { KalgudiShareUpdateApiService } from './services/kalgudi-share-update-api.service';
import { KalgudiShareUpdateService } from './services/kalgudi-share-update.service';


@NgModule({
  declarations: [
    ShareUpdateTileComponent,
    ShareUpdateStreamComponent,
    ShareUpdateHeaderComponent,
    ShareUpdateActionBarComponent,
    ShareUpdateFormComponent,
    MobileShareUpdateFormComponent,
    UpdatePostDialogComponent,
    UpdatePostMobileDialogComponent,
    LikesListComponent,
    LikesListDialogComponent,
    LikesListMobileDialogComponent,

    PromotionalShareCropsComponent,
    PromotionalShareTileComponent,
    PromotionalShareHeaderComponent,
    PromotionalShareProductsComponent,
    PromotionalShareMultipleProductsComponent,
    TaskViewTileComponent,
    TaskDetailsComponent,
    TaskStatusComponent,
    TaskFormUpdatesComponent,
    TaskActivityUpdatesComponent,
    TaskUpdateHeaderComponent,
    ReShareUpdateDialogComponent,
    ReShareUpdateMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    AngularSvgIconModule,

    // Material
    MatCardModule,
    MatChipsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatDatepickerModule,
    TranslateModule,
    MomentDateModule,
    OverlayModule,
    MatTooltipModule,

    // Flex
    FlexLayoutModule,


    // Kalgudi libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiCommonModule,
    KalgudiSocialModule,
    KalgudiUsersModule,
    KalgudiDialogsModule,
    KalgudiImagePickerModule,
    KalgudiImageChipListModule,
    MobileMenuBottomSheetModule,
    KalgudiLazyHttpClientModule,
    KalgudiAttachUrlModule,
    KalgudiNoContentModule,
    KalgudiSocialSharesModule,
    KalgudiTimePickerModule,
    KalgudiStoreProductsSearchModule,
    KalgudiAudioAttachmentModule,
    KalgudiDirectivesModule,

    SatelliteAdvisoriesModule,

    SlickCarouselModule,

    KalgudiGoogleGeoLocationModule,
  ],
  exports: [
    ShareUpdateFormComponent,
    ShareUpdateTileComponent,
    ShareUpdateHeaderComponent,
    MobileShareUpdateFormComponent,
    PromotionalShareTileComponent,
    PromotionalShareHeaderComponent,
    PromotionalShareProductsComponent,
    PromotionalShareCropsComponent,
    TaskViewTileComponent,
    DatePipe,
  ],
  providers: [
    KalgudiShareUpdateService,
    KalgudiShareUpdateApiService,
    {
      provide: LAZY_HTTP_INTERCEPTOR,
      useClass: FailedHttpResponseInterceptorService
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    // KalgudiInputDialogComponent,
    // KalgudiUploadImageDialogComponent,
    // ConfirmDialogComponent,
    // KalgudiUsersPickerDialogComponent,
    // KalgudiConnectsPickerDialogComponent,
    // KalgudiProductsPickerDialogComponent,
    MobileShareUpdateFormComponent,
    UpdatePostDialogComponent,
    UpdatePostMobileDialogComponent,
    LikesListDialogComponent,
    LikesListMobileDialogComponent,
    ReShareUpdateDialogComponent,
    ReShareUpdateMobileDialogComponent,
  ]
})
export class KalgudiShareUpdateModule {

  public static forChild(config: KalgudiCoreConfig): ModuleWithProviders<KalgudiShareUpdateModule> {

    return {
      ngModule: KalgudiShareUpdateModule,
      providers: [
        {
          provide:  KL_ENV,
          useClass: config && config.environment
        },
        {
          provide:  KL_NOTIFICATION,
          useClass: config && config.notification
        },
        {
          provide:  KL_ROUTE_CONFIG,
          useClass: (config && config.routeConfig)
        },
      ]
    };
  }
}
