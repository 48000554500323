import { PartialData } from './core.model';
import { PageShareTargetAudienceRequest } from './pages.model';
import { StoreBaseProductBasicDetails } from './product-catalogue.model';
import { Attachment, KalgudiShareFilters, ShareVisibility, SocialPost } from './social.model';
import { KalgudiUserBasicDetails } from './user.model';

// export type Type = 'PROGRAM' | 'UNIVERSITY' | 'ORGANIZATION' | 'COMPANY' | 'FARMER';

export enum ProjectStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  ARCHIVED = 'ARCHIVED',
}

export enum ProjectType {
  CROP_CALENDAR = 'CROP_CALENDAR',
  SOFTWARE_DEVELOPMENT = 'SOFTWARE_DEVELOPMENT',
  ROADMAP_CREATION = 'ROADMAP_CREATION',
  GENERIC = 'GENERIC'
}

export enum ProjectCropSeason {
  KHARIF = 'KHARIF',
  RABI = 'RABI',
  ZAID = 'ZAID'
}

export const PROJECT_TARGET_AUDIENCE_TYPE = {
  UNICAST: 'UNICAST',
  MULTICAST: 'MULTICAST',
  BROADCAST: 'BROADCAST'
} as const;

export enum ProjectMemberType {
  ADMIN = 'ADMIN',
  CONTRIBUTOR = 'CONTRIBUTOR',
  MEMBER = 'MEMBER'
}

export enum ProjectTaskRepeatFreq {
  HOURLY = 'HOURLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  ANNUALLY = 'ANNUALLY',
  NONE = 'NONE'
}

export enum ActivityType {
  COMMENT         = 'COMMENT',
  TASK_SUBMISSION = 'TASK_SUBMISSION',
  ADMIN_UPDATES   = 'ADMIN_UPDATES',
  MEMBER_UPDATES  = 'MEMBER_UPDATES',
  MEDIA_UPDATES   = 'MEDIA_UPDATES'
}

export enum Action {
  STATE_UPDATE = 'STATE_UPDATE'
}

export enum ProjectRole {
  ADMIN  = 'ADMIN',
  MEMBER = 'MEMBER',
  OTHER  = 'OTHER'
}

// export interface KalgudiCalendarEvent extends CalendarEvent {
export interface KalgudiCalendarEvent {
  taskId?: string;
  projectId?: string;
  [key: string]: any;
}

export interface KalgudiProject {
  id?: string;
  title?: string;
  description?: string;
  type?: ProjectType;

  CT?: string;
  LUT?: string;
  endDate?: string;
  startDate?: string;

  createdBy?: KalgudiUserBasicDetails;
  updatedBy?: KalgudiUserBasicDetails;
  author?: KalgudiUserBasicDetails;
  attachments?: Attachment[];

  status?: ProjectStatus;
  targetedAudience?: ProjectTargetedAudience;

  /* runtime params */
  tasksCount: {
    [key in ProjectStatus]: number; // { [key: ProjectStatus]: number }
  };
  totalTasks: number;

  membersCount: {
    [key in ProjectMemberType]: number; //  { [key: ProjectMemberType]: number}
  };
  totalMembers: number;

  statusValue?: string;
  userRole?: ProjectRole;

  statistics?: ProjectStatistics;

  /* below are used for UI purpose only */
  taskStatsDetails?: TaskStatsDetails;        // UI purpose
  memberStatsDetails?: MemberStatsDetails;   // UI purpose
}

export interface ProjectStatistics {
  tasks?: TaskStatistics;
  members?: MemberStatistics;
  projects?: TaskStatistics;
  projectType?: ProjectType;
}

export interface TaskStatistics {
  total?: number;
  completed?: number;
  not_started?: number;
  in_progress?: number;
}

export interface MemberStatistics {
  admins?: number;
  contributors?: number;
  member?: number;
  total?: number;
}

export interface KalgudiPageProject extends KalgudiProject {
  pageId: string;
}

export interface KalgudiCropCalendarProject extends KalgudiProject {
  crop: StoreBaseProductBasicDetails;
  variety: StoreBaseProductBasicDetails;
  season: ProjectCropSeason;
  year: string;
}

export interface ProjectTargetedAudience {
  type: string;
  users: string;
  target?: string;
  filter: PageShareTargetAudienceRequest;
}


export interface KalgudiProjectTask {
  id?: string;
  taskId?: string;
  title?: string;
  description?: string;
  farmerTasks?: boolean;
  myTasks?: boolean;

  projectId?: string;
  projectTitle?: string;
  projectType?: ProjectType;
  docType?: string;
  variety?: string;

  CT?: string;
  LUT?: string;

  createdBy?: KalgudiUserBasicDetails;
  updatedBy?: KalgudiUserBasicDetails;
  attachments?: Attachment[];

  timeFrame?: {
    start?: string;
    end?: string;
    duration?: string;
  };
  repeatFreq?: ProjectTaskRepeatFreq;

  templateId?: string;
  templateRefId?: string;
  assignedTo?: KalgudiUserBasicDetails;

  state?: ProjectStatus;
  stateChangedOn?: string;

  checkList?: {
    title?: string,
    completed?: boolean
  }[];
  clonedFrom?: string;
  targetedAudience?: ProjectTargetedAudience;
  templateRefTitle?: string;
  activityDetails?: any;

  userRole?: ProjectRole;

  isTaskDependentOnCropStage?: boolean;
  cropStageDependency?: {
    from?: number;
    to?: number;
    when?: string;
    cropStage?: string;
    taskTitle?: string;
  };
  cropStage?: string;

  creationTemplateData?: PartialData;

  activityId?: string;
  statistics?: {
    members: {
      total: number;
      admins: number;
      members: number;
    },
    submissions: {
      total: number;
      completed: number;
      in_progress: number;
      not_started: number;
    }
  };

  updatedTimeFrame?: {
    start?: string;
    end?: string;
    duration?: string;
  };

  tags?: ProjectTaskTags;
  pageId?: string;
}

export interface ProjectTaskTags {
  info?: string[];
  input?: TaskTagsInput[];
  output?: TaskTagsOutput[];
  services?: TaskTagsServices[];
}

export interface TaskTagsInput {
  product?: any;
  cost?: number;
  needType?: string;
  dosesPerAcre?: any;
  quantityPerAcre?: any;
  noOfHourPerAcre?: number;
  noOfAcres?: number;
}

export interface TaskTagsOutput {
  product?: any;
  cost?: number;
  expectedYieldPerAcre?: any;
}

export interface TaskTagsServices {
  product?: any;
  cost?: number;
  noOfHourPerAcre?: number;
}

export interface KalgudiProjectTemplate extends KalgudiProjectTask {
  taskId: string;
}

export interface MemberStatsDetails {
  title: string;
  count: number;
  statsList: MemberStatsList[];
}

interface MemberStatsList {
  title?: ProjectMemberType;
  value?: number;
  bgColor?: string;
}

export interface TaskStatsDetails {
  title: string;
  count: number;
  statsList: TaskStatsList[];
}


interface TaskStatsList {
  title?: ProjectStatus;
  value?: number;
  bgColor?: string;
}

export interface TaskTemplateDetails {
  title?: string;
  description?: string;
  image?: string;
  schema?: PartialData;
  creationSchema?: PartialData;
  templateType?: string;

  svgIcon?: string; // UI purpose
  desc?: string;    // UI purpose
}

export interface ApiResponseProjectMembersData {
  results: KalgudiUserBasicDetails[];
  count: number;
}

export interface ApiResponseTaskMembersData {
  results: KalgudiUserBasicDetails[];
  count: number;
}

export interface ApiResponseProjectMembersList {
  count: number;
  items: KalgudiUserBasicDetails[];
}

export interface AddProjectMembersResponse {
  type: string;
  users?: string[];
  filter?: KalgudiShareFilters;
}

export interface TaskShareUpdateRequest {
  authorId: string;
  shareText: string;
  sharedTo: ShareVisibility;
  lstOfAttachments?: Attachment[];
}

export interface TaskShareUpdate extends SocialPost {
  shareText?: '';
  lstOfAttachments?: [];
}

export interface ChartData {
  name: string;
  value: number;
}

