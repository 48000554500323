import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageSurveyManageAdmin } from '../../classes/page-survey-manage-admin';

@Component({
  selector: 'kl-page-survey-admins-stream',
  template: `
    <kl-survey-admins-stream></kl-survey-admins-stream>
  `,
  styles: [``]
})
export class PageSurveyAdminsStreamComponent extends KalgudiPageSurveyManageAdmin implements OnInit {

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() { }

  onDestroyed(): void {}

}
