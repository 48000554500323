import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BaseProductTypes, StoreBaseProductBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-stock-page-info-form',
  templateUrl: './stock-page-info-form.component.html',
  styleUrls: ['./stock-page-info-form.component.scss']
})
export class StockPageInfoFormComponent implements OnInit {

  productTypes = BaseProductTypes;

  @Input()
  stockPageForm: FormGroup;

  constructor() {}

  get baseProductField(): AbstractControl {
    return this.stockPageForm.get('product');
  }

  get baseProductFieldValue(): StoreBaseProductBasicDetails {
    return this.baseProductField.value;
  }

  ngOnInit() {
  }

}
