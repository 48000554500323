import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import {
  ApiSubmissionListResponseData,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiSurveySubmission,
  KalgudiSurveySubmissionRequest,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  SurveySubmissionFormDialogComponent,
} from '../components/survey-submission-form-dialog/survey-submission-form-dialog.component';
import {
  SurveySubmissionFormMobileDialogComponent,
} from '../components/survey-submission-form-mobile-dialog/survey-submission-form-mobile-dialog.component';
import { KalgudiSurveySubmissionApiService } from './kalgudi-survey-submission-api.service';

@Injectable()
export class KalgudiSurveySubmissionService {

  constructor(
    private api: KalgudiSurveySubmissionApiService,
    private util: KalgudiUtilityService,
    private kalgudiDialogService: KalgudiDialogsService
  ) { }


  /**
   * Opens survey submission dialog
   */
  openSurveySubmissionDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialogService.openMobileDialog(SurveySubmissionFormMobileDialogComponent, details)
      : this.kalgudiDialogService.openDialog(SurveySubmissionFormDialogComponent, details, config);
  }

  /**
   * Make an api call to get submissions
   */
  getSubmissions(surveyId: string, profileKey: string): Observable<ApiSubmissionListResponseData> {

    return this.api.getSubmissions(surveyId, profileKey);
  }


  /**
   * Make an api call to post submissions
   */
  postSubmissions(surveyId: string, payload: KalgudiSurveySubmissionRequest): Observable<KalgudiSurveySubmission> {

    const reqObj = this.util.clone(payload);

    return this.api.postSubmissions(surveyId, reqObj);
  }


  /**
   * Make an api call to update submissions
   */
  updateSubmissions(surveyId: string, submissionId: string, payload: KalgudiSurveySubmissionRequest): Observable<KalgudiSurveySubmission> {

    const reqObj = this.util.clone(payload);

    return this.api.updateSubmissions(surveyId, submissionId, reqObj);
  }

}
