import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiProfileRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-land-details-mobile-dialog',
  templateUrl: './land-details-mobile-dialog.component.html',
  styleUrls: ['./land-details-mobile-dialog.component.scss']
})
export class LandDetailsMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  isEditLand: boolean;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * Open the land details edit dialog and close land details dialog
   */
  openLandDetailsEditDialog(){
    this.isEditLand = true;
    this.ok();
  }

  /**
   * Opens season edit dialog
   */
  openSeasonEditDialog(season: any) {
    this.ok();

    const cropPageId = season.pageId;
    const pageId = this.dialogData.data.pageId;
    const isAssisted = this.dialogData.data.isAssisted;
    const seasonId = season.id;

    this.appRouting.toCropSettings({'profileKey': this.dialogData.data.assistedProfileKey, 'cropId': season.crop.productId, 'cropTitle': season.crop.productName}, {cropPageId, pageId, isAssisted, seasonId})

  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        isEditLand: this.isEditLand
      }
    };
    this.dialogRef.close(result);
  }
}
