import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiAddCredits } from '../../classes/kalgudi-add-credits';

@Component({
  selector: 'kl-add-credits',
  templateUrl: './add-credits.component.html',
  styleUrls: ['./add-credits.component.scss']
})
export class AddCreditsComponent extends KalgudiAddCredits implements OnInit {

  constructor(
    protected injector: Injector) {
      super(
        injector
      );
    }

  ngOnInit() {
  }

  protected onDestroyed(): void {}

}
