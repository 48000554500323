import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { DEFAULT_DIALOG_CONFIG } from '../kalgudi-dialogs';
import { AudioAttachmentDialogComponent } from './audio-attachment-dialog/audio-attachment-dialog.component';

@Injectable()
export class AudioAttachmentService {

  constructor(
    private dialog: MatDialog,
  ) { }


  showAudioDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return this.openDialog(AudioAttachmentDialogComponent, details, config);
  }

  openDialog(
    component: Type<any>,
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    config.data = details;

    return this.dialog
      .open(component, config)
      .afterClosed()
      .pipe(take(1));
  }
}
