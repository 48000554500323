export const orderImages = {
  ORD_RECEIVED: {
    icon: "https://kalgudi.com/app/common/images/state-machine/lace.png",
    title: "Order Placed"
  },

  ORD_INP: {
    icon: "https://kalgudi.com/app/common/images/state-machine/sand-clock.png",
      title: "Order in progress"
  },

  QC_WAITING: {
    icon:  "https://kalgudi.com/app/common/images/state-machine/quality.png",
    title: "Quality Check"
  },

  QC_INP: {
    icon: "https://kalgudi.com/app/common/images/state-machine/quality.png",
    title: "Quality Check"
  },

  PKG_WAITING:{
    icon :"https://kalgudi.com/app/common/images/state-machine/packingbox.png",
    title: "Packing"
  },

  PKG_INP:{
    icon:  "https://kalgudi.com/app/common/images/state-machine/box.png",
    title: "Packing"
  },

  TR_WAITING: {
    icon: "https://kalgudi.com/app/common/images/state-machine/transit.png",
    title: "Transportation"
  },

  TR_INP:{
    icon: "https://kalgudi.com/app/common/images/state-machine/transit.png",
    title: "Transportation"
  },
  ORD_DELIVERED: {
    icon: "https://kalgudi.com/app/common/images/state-machine/delivery.png",
    title: "Delivered"
  },

  QC_TRANSPORT_WAITING: {
    icon: "https://kalgudi.com/app/common/images/state-machine/exclamation.svg",
  },

  INTRANSIT_FOR_QC: {
    icon: "https://kalgudi.com/app/common/images/state-machine/transit.png",
    title: "Intransit for QC"
  },

  order_HOLD: {
    icon: "https://kalgudi.com/app/common/images/state-machine/hold.png",
    title: 'Hold Order'
  },

  ORD_SETTLED: {
    icon: "assets/images/delivery-door-step.png"
  },

  ORD_REFUNDED: {
    icon: "assets/images/ord-refunded.png",
    title: "Refund"
  },

  REFUND_INPROGRESS: {
    icon: "assets/images/initiate_Refund.png",
    title: "In Progress"
  },

  SETTLEMENT_APPROVAL_INP: {
    icon: "assets/images/delivery-door-step.png"
  }
};

export const actionMessageLookup = {
  ord_RECEIVED: "Waiting for seller confirmation",
  ord_REJECT: "Order Rejected",
  ord_ACCEPT: "Order Accepted",
  ord_UPDATE: "Order Accepted",
  ord_READY: "READY",
  ord_READY_ACCEPT_QC: "Order ready and QC accepted",
  qc_ACCEPT: "Order ready and QC accepted",
  qc_REJECT: "QC Rejected",
  qc_UPDATE: "Order ready and QC accepted",
  qc_PASS: "Quality check passed",
  qc_FAIL: "Quality check failed",
  qc_PASS_ACCEPT_PKG: "QC passed and packing accepted",
  pkg_ACCEPT: "QC passed and packing accepted",
  pkg_REJECT: "Packaging Rejected",
  pkg_UPDATE: "QC passed and packing accepted",
  pkg_DONE: "Packaging Done",
  pkg_FAIL: "Packaging Failed",
  pkg_DONE_ACCEPT_TR: "Packing done and transport accepted",
  tr_ACCEPT: "Packing done and transport accepted",
  tr_REJECT: "Transit Rejected",
  tr_UPDATE: "Packing done and transport accepted",
  tr_DELIVER: "Package Delivered",
  tr_FAIL_TO_DELIVER: "Failed to deliver",
  qc_TR_ACCEPT: "Transit for QC accepted",
  qc_TR_REJECT: "Order Rejected",
  kForce_Timeout: "Timeout",
  qc_TR_FAIL_TO_DELIVER: "Fail",
  qc_TR_DELIVER: "Delivered to QC",
  qc_TR_UPDATE: "Update",
  order_HOLD: 'Hold Order',
  cancel_order: 'Cancel Order',
  ord_refunded: 'Refunded',
  initiate_Refund: 'Refund in progress',
  ord_settled: 'Settled'
};
