import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable, KalgudiFormValidators } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KalgudiCreateStockResponse,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiStock,
  KalgudiUsersMap,
  KalgudiUsersPickerDialogConfig,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';

import { UNITS_LIST } from '../constants';
import { StockStateService } from '../modules/page-stocks/services/stock-state.service';
import { StocksService } from '../modules/page-stocks/services/stocks.service';
import { KalgudiPageService } from '../services/kalgudi-page.service';


@Directive()
export abstract class KalgudiCreateStock extends KalgudiDestroyable {

  @Input()
  pageId: string;

  @Input()
  stockId: string;

  selectedUsersMap: KalgudiUsersMap = {};

  createStockForm: FormGroup;

  units = UNITS_LIST;

  productDetails: any;
  stockDetails: KalgudiStock;

  protected stockStateService: StockStateService;
  private stocksService: StocksService;
  private fb: FormBuilder;
  private notification: KalgudiNotification;
  private kalgudiPageService: KalgudiPageService;
  private usersPickerService: KalgudiUsersPickerService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.stocksService      = this.injector.get<StocksService>(StocksService);
    this.fb                 = this.injector.get<FormBuilder>(FormBuilder);
    this.notification       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiPageService = this.injector.get<KalgudiPageService>(KalgudiPageService);
    this.stockStateService  = this.injector.get<StockStateService>(StockStateService);
    this.usersPickerService = this.injector.get<KalgudiUsersPickerService>(KalgudiUsersPickerService);

    this.createStockForm = this.stockForm;

    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.createStockForm.get('product').patchValue(pageDetails.product);
        this.createStockForm.get('variety').patchValue(pageDetails.variety);
      });

  }


  // --------------------------------------------------------
  // #region Getter and Setters
  // --------------------------------------------------------

  /**
   * Returns the farmerDetails form control
   */
  get farmerField(): AbstractControl {
    return this.createStockForm.get('farmerDetails');
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Check and call the create and update stock methods
   */
  saveStock() {
    // this.createStockForm.get('farmerDetails').patchValue(this.selectedUsers[0]);

    const payload = {...this.stockDetails, ...this.createStockForm.value};

    if (this.stockId) {

      this.updateStock(payload);
    } else {

      this.createStock(payload);
    }

  }


  /**
   * Adds a user to the stock. Displays a Kalgudi user picker dialog.
   * Finally calls the add api to add the user.
   *
   */
  pickFarmer(): void {

    if (this.stockId) {
      return;
    }

    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Select users to add',
      acceptButtonTitle: 'Select users',
      rejectButtonTitle: 'Cancel',
      multiSelect: false,
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(r => r.accepted),

        map(res => Object.values(res.data))

      )
      .subscribe(
        res => this.farmerField.patchValue(res[0])

      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Form group to create stock
   */
  protected get stockForm(): FormGroup {

    return this.fb.group({
      farmerDetails: ['', Validators.required],
      product: [''],
      variety: [''],
      price: ['', [ Validators.required, ...KalgudiFormValidators.numberValidator]],
      quantityValue: ['', [ Validators.required, ...KalgudiFormValidators.numberValidator]],
      quantityUnits: ['', Validators.required],
    });
  }

  /**
   * Creates a new stock. Calls the API to create a new stock
   */
  protected createStock(payload) {

    this.stocksService.createStock(this.pageId, payload)
      .subscribe(
        res => {
          this.stockCreationHandler(payload, res);
          this.notification.showMessage('Created stock successfully');
        },
        err => this.onCreateStockError(err)
      );
  }

  /**
   * Calls the API to update the stock
   */
  protected updateStock(payload) {

    this.stocksService.updateStock(this.stockId, payload)
      .subscribe(
        res => {
          this.stockCreationHandler(payload, res);
          this.notification.showMessage('Updated stock successfully');
        },
        err => this.onCreateStockError(err)
    );
  }


  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.usersPickerService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.usersPickerService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  /**
   * Event handler for create stock API errors.
   */
  protected onCreateStockError(err: Error): void {
    this.notification.showMessage(err.message);
  }



  protected abstract onStockCreated(payload: KalgudiCreateStockResponse, response: any): void;


  /**
   * Shows users picker for mobile and web based on the device or screen size.
   */
  private showUsersPicker(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileUserPickerDialog(dialogConfig)
      : this.openUsersPickerDialog(dialogConfig, matDialogConfig);
  }

  /**
   * Event handler for successful stock creation Api response.
   */
  private stockCreationHandler(payload: KalgudiCreateStockResponse, res: any): void {

    // Callback hooks for its children
    this.onStockCreated(payload, res);
    this.stockStateService.reloadStocks();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}

