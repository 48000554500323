import { Inject, Input, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PartialData, StringAnyMap } from '@kalgudi/types';
import { BehaviorSubject, merge, Observable, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { KalgudiUtilityService } from '../services/kalgudi-util.service';
import { KalgudiStreamData } from '../typings';
import { KalgudiStream } from './kalgudi-stream';

/**
 * Extends the base Kalgudi Stream. Use this class for any stream that has
 * search and load more functionality.
 *
 * A child of KalgudiSearchStream must implement all abstract methods of KalgudiStream
 * and
 *  - `searchApi()`: Implement the search Api call method here.
 *
 * It provides a search form group with field as `searchForm` where the form control name
 * of the search input field is `searchKeyword`.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSearchStream<T> extends KalgudiStream<T> {

  /**
   * Extra search params that must be passed to the Api
   */
  @Input()
  extraSearchParams: PartialData = {};

  /**
   * Search initiates with this keyword if given any
   */
  @Input()
  initialSearchKeyword: string;

  /**
   * Search form group
   */
  readonly searchForm: FormGroup;

  /**
   * Gets, the stream of search keyword change events. A new search keyword
   * is only emitted to the event stream if its distinct than previous
   * search keyword.
   */
  protected searchKeywordChange$: Observable<string>;

  /**
   * Stream of search keywords
   */
  protected searchKeywordSubject = new BehaviorSubject<string>(null);

  minSearchLength = 3;


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up my parent
    super(notification, util);

    // Initialize search form
    this.searchForm = new FormGroup({
      searchKeyword: new FormControl(''),
      searchType: new FormControl(''),
    });

    this.searchKeywordChange$ = this.searchKeywordSubject
      .pipe(

        // Filter all null values
        filter(r => r !== null),

        // Debounce requests for 300 ms
        debounceTime(300),

        // Emit new search keyword only when its distinct from previous search keyword
        // distinctUntilChanged(),

        // On any search keyword change reset the stream
        tap(_ => this.resetStream(false))
      );

    // Searching with the initial keyword if given
    timer(300)
      .pipe(

        // Ensure to take only 1 value
        take(1),
      )
      .subscribe(_ => {
        if(this.initialSearchKeyword) {
          this.searchForm.get('searchKeyword').patchValue(this.initialSearchKeyword);
          this.search();
        }
      });

  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the search keyword
   */
  get searchKeyword(): string {
    return this.searchForm.get('searchKeyword').value;
  }

  /**
   * Gets, the search type field in the search form.
   */
  get searchTypeField(): AbstractControl {
    return this.searchForm.get('searchType');
  };

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Performs a search operation on the current search keyword.
   * It emits the search keyword to the search stream.
   */
  search(): void {

    // Emit new search keyword in the search stream
    this.searchKeywordSubject.next(this.searchKeyword);
  }

  /**
   * Resets the search to the default search. It clears any specified search keyword
   * and sets it back to empty.
   */
  resetSearch(): void {

    // Clear search keyword value in the search form
    this.searchForm.reset();

    // Use patchValue to update only the 'searchKeyword' control
    if (!this.searchKeyword) {
      this.searchForm.patchValue({
        searchKeyword:  '',
      });
    }

    // Emit the empty search keyword to the search stream
    this.searchKeywordSubject.next(this.searchKeyword);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  /**
   * Implement this method to define your search Api.
   */
  protected abstract searchApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiStreamData>;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Loads the initial set of data in the stream.
   *
   * @override
   */
  protected initStream(subscribeToEvents: boolean = true): void {

    super.initStream();

    if (subscribeToEvents) {
      // Subscribe to the page change and search events
      this.subscribeToSearchEvents();
    }


    // Make a default search
    // this.search();
  }

  /**
   * Fetches stream items from the Kalgudi Api. Internally it calls the search
   * Api with the search keyword and extra params.
   *
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    // Call the stream search Api
    return this.searchApi(this.searchKeyword, offset, limit, this.extraSearchParams);
  }

  /**
   * Subscribes to page change or search keyword change events
   * and fetches the latest search results.
   */
  private subscribeToSearchEvents(): void {

    merge(
      this.pageChange$,
      this.resetStream$,
      this.searchKeywordChange$,
    )
      .pipe(

        // Subscribe till the instance is alive
        takeUntil(this.destroyed$),

        tap(_ => this.setLoadingProgress(false)),

        // Always process if there is a valid paginator value
        filter(_ => this.paginatorValue !== null),

        // Fetch latest stream items and transform the response
        switchMap(_ => this.fetchStreamItems(this.paginatorValue, this.extraSearchParams))
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
