import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kl-formly-object-type',
  template: `
  <div class="mb-3 kl-formly-object-type" *ngIf="!to?.hidden">
    <legend *ngIf="to?.label">{{ to?.label | translate }}</legend>
    <p *ngIf="to?.description">{{ to?.description | translate }}</p>
    <div class="alert alert-danger" role="alert" *ngIf="showError && formControl?.errors">
      <formly-validation-message [field]="field"></formly-validation-message>
    </div>

    <div class="{{field?.fieldGroupClassName}}">
      <formly-field *ngFor="let f of field?.fieldGroup" [field]="f"></formly-field>
    </div>
  </div>
  `,
  styles: [`
    kl-formly-object-type,
    .kl-formly-object-type {
      display: block;
      width: 100%;
    }
  `],
  encapsulation: ViewEncapsulation.None,
})
export class FormlyObjectTypeComponent extends FieldType {

  defaultOptions = {
    defaultValue: {},
  };
}

