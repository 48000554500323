<div class="users-picker rounded mb-1 p-1 pl-2 cursor-pointer position-relative"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="15px"
  (click)="pickMember()"
  [matTooltip]="placeholder | translate">

  <div class="user-picker position-absolute w-100 rounded"></div>

  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">

    <!-- User details -->
    <ng-container *ngIf="selectedUser">
      <div>
        <img [src]="selectedUser?.profilePicUrl" class="profile-pic rounded-circle">
      </div>

      <div>
        <p class="mb-0 name">
          <span>{{ selectedUser?.firstName }}</span>
          <span *ngIf="selectedUser?.location">, {{selectedUser?.location}}</span>
        </p>
      </div>
    </ng-container>
    <!-- / User details -->

    <!-- No User details -->
    <ng-container *ngIf="!selectedUser">
      <div>
        <span class="member-picker">{{placeholder | translate}}</span>
      </div>
    </ng-container>
    <!-- / No User details -->

  </div>

  <!-- Add button -->
  <div class="mr-n1" fxLayoutAlign="end end">
    <button mat-icon-button class="add" color="accent">
      <mat-icon>
        add
      </mat-icon>
    </button>
  </div>
  <!--/ Add button -->

</div>
