import { Injectable } from '@angular/core';
import { KalgudiPageRelation, KalgudiSurveyDetails, PartialData } from '@kalgudi/types';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { KalgudiSurveyList } from '../../../typings';
import { SurveysListApiService } from './surveys-list-api.service';

@Injectable()
export class SurveysListService {

  mySurveys: { createdSurveys: KalgudiSurveyDetails[], followingSurveys: KalgudiSurveyDetails[] };

  constructor(private api: SurveysListApiService) { }

  /**
   * Fetches surveys list from the API
   */
  fetchSurveysList(offset: number, limit: number, extraParams: PartialData): Observable<KalgudiSurveyList> {

    return this.api.fetchSurveysList(offset, limit, extraParams);
  }

  /**
   * Gets, surveys you follow and surveys you are part. It fetches all surveys from the Api
   * skipping the specified survey.
   *
   * @param surveyId Skip the current survey
   */
  getMySurveys(surveyId: string): Observable<{ createdSurveys: KalgudiSurveyDetails[], followingSurveys: KalgudiSurveyDetails[] }> {

    return this.mySurveys
      ? of(this.mySurveys)
      : combineLatest(
        this.getMySurveysWithFilter(surveyId, 0, 5, 'ADMIN').pipe(catchError(e => of([]))),
        this.getMySurveysWithFilter(surveyId, 0, 5, 'MEMBER').pipe(catchError(e => of([])))
      ).pipe(
        map(([createdSurveys, followingSurveys]) => {
          return { createdSurveys, followingSurveys };
        }),

        // Cache the response locally
        tap(mySurveys => this.mySurveys = mySurveys)
      );
  }

  /**
   * Get, surveys list.
   */
  private getMySurveysWithFilter(
    surveyId: string,
    offset: number,
    limit: number,
    memberRole: KalgudiPageRelation
  ): Observable<KalgudiSurveyDetails[]> {

    return this.fetchSurveysList(offset, limit, { role: memberRole })
      .pipe(
        map(res => res.items),

        // Skip the specified survey
        map(surveys => surveys.filter(survey => survey.surveyId !== surveyId))
      );
  }

}
