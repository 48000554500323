import { Injector, Input, Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiFarmingHistory, KalgudiUser } from '@kalgudi/types';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';



@Directive()
export abstract class KalgudiFarmerFarmingActivitySettings extends KalgudiDestroyable {

  @Input()
  pageId: string;

  @Input()
  seasonId: string;

  @Input()
  farmingFarmType: string;

  seasonsList: KalgudiFarmingHistory[];

  profile: KalgudiUser;
  progress: boolean;
  editable: boolean;
  selectedSeason: any;

  activeTabIndex: number;

  isSeasonEdit: boolean;

  readonly TABS = {
    SEASONS: { index: 0, title: 'Seasons' },
    ADD_SEASON: { index: 1, title: 'Add season' },
  };

  private farmerProfileService: KalgudiFarmerProfileService;
  private profileStateService: KalgudiProfileStateService;
  private notification: KalgudiNotification;
  private profileService: KalgudiProfileService;
  private route: ActivatedRoute;
  private router: Router;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.profileStateService  = this.injector.get(KalgudiProfileStateService);
    this.profileService       = this.injector.get(KalgudiProfileService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.route                = this.injector.get(ActivatedRoute);
    this.router               = this.injector.get(Router);

    this.profileStateService.data$
      .pipe(

        take(1)
      )
      .subscribe(
        res => this.profile = res
      );

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

  }

  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Fetch season after adding or updating season
   */
  seasonAdded() {
    this.activeTabIndex = 0;

    if (this.seasonId) {
      const params = { ...this.route.snapshot.queryParams };
      delete params.seasonId;
      this.router.navigate([], { queryParams: params });
    }

    this.getSeasons();
  }

  getSeasons() {
    this.progress = true;

    const profileKey = this.profile.profileKey;

    this.farmerProfileService.getFarmSeasonsList(this.pageId, '', profileKey)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.seasonsList = res;
          if (this.seasonId) {
            this.seasonsList.map(season => (this.seasonId === season.id) ? this.selectedSeason = season : '')
            this.isSeasonEdit = true;
            this.activeTabIndex = 1;
          }
        }
      )
  }

  /**
   * Deletes season
   * @param seasonId
   */
  deleteSeason(seasonId: string) {
    this.notification.showSpinner();
    this.farmerProfileService.deleteFarm(seasonId)
      .subscribe(
        res=> {
          this.notification.hideSpinner();
          this.notification.showMessage('Season deleted')
          this.getSeasons();
        }
      )
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
