import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { SINGLE_UNITS } from '@kalgudi/common';

@Component({
  selector: 'kl-ai-predictions-list',
  templateUrl: './ai-predictions-list.component.html',
  styleUrls: ['./ai-predictions-list.component.scss']
})
export class AiPredictionsListComponent implements OnInit {

  @Input()
  predictionList: any[];

  @Input()
  showCopy: boolean;

  @Input()
  copyId: string;

  @Output()
  onCopyClicked = new EventEmitter();

  @Input()
  progress: boolean;

  showDetails: boolean;
  selectedItem: number;
  hide: boolean;

  singleUnit = SINGLE_UNITS;


  constructor(private util: KalgudiUtilityService) { }

  ngOnInit(): void {
  }

  /**
   * Copying the html as text to clipboard
   * @param elementId
   */
  copyToClipBoard(elementId): void {
    this.onCopyClicked.emit(elementId)
  }

  /**
   * To show the list
   */
  displayDetails(item) {
    item['hide'] = false
  }

  /**
   * To hide the list
   */
  hideExtraInfo(item) {
    item['hide'] = true
  }

}
