<div id="live-stock-list">

  <div *ngFor="let stock of updatedLiveStockList; let last = last" [ngClass]="{'live-stock-data': !last}">

    <div fxLayout="row" fxLayoutAlign="space-between center">

      <div fxLayout>
        <img [src]="stock?.attachments?.imageUrl" class="live-stock-image mr-2">
        <span class="type mt-1">{{stock?.type | titlecase}} ({{ stock?.product?.productName | titlecase }})</span>
      </div>

      <div *ngIf="editable" (click)="openEditLiveStockDialog(stock)" mat-raised-button matTooltip="Edit {{stock?.type | titlecase}} Details" matTooltipPosition="before">
        <mat-icon class="edit-icon rounded-circle cursor-pointer" fxLayoutAlign="center center">edit</mat-icon>
      </div>
    </div>

    <hr class="my-1">

    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">

      <div fxLayout="column">

        <div>
          <span class="heading-values">Name of the coop : </span>
          <span class="text-secondary">{{ stock?.coopName | titlecase }}</span>
        </div>

        <div>
          <span class="heading-values">Geo tag : </span>
          <span class="text-primary cursor-pointer" matTooltip="Click to view location" (click)="showLocationDialog(stock?.location?.location)">
            {{stock?.location?.location?.longitude ? stock?.location?.location?.longitude : '-'}}
          </span>
        </div>

        <div fxLayout="row" class="mr-0" [matMenuTriggerFor]="dimensionsMenu">
          <span class="heading-values">Coop dimensions</span>
          <mat-icon class="info-icon text-success" fxLayoutAlign="start center">info</mat-icon>
        </div>

        <!-- Mat menu for more icon -->
        <mat-menu class="px-2" fxLayout="column" #dimensionsMenu="matMenu">

          <span fxLayoutAlign="space-between end" class="border-bottom mat-info" fxLayout>
            <span class="dimensions">Length :</span>
            <span class="text-muted">{{ stock?.dimensions?.length || '-' }}</span>
          </span>
          <span fxLayoutAlign="space-between end" class="border-bottom mat-info" fxLayout>
            <span class="dimensions">Breadth :</span>
            <span class="text-muted">{{ stock?.dimensions?.breadth || '-' }}</span>
          </span>
          <span fxLayoutAlign="space-between end" class="mat-info" fxLayout>
            <span class="dimensions">Unit :</span>
            <span class="text-muted">{{ stock?.dimensions?.unit || '-' | titlecase }}</span>
          </span>
        </mat-menu>
        <!-- Mat menu for more icon -->

      </div>

      <div fxLayout="column">

        <div>
          <span class="heading-values">Available for sale : </span>
          <span class="text-secondary">{{stock?.availableQuantity || 0}}</span>
        </div>

        <div>
          <span class="heading-values">Mortality : </span>
          <span class="text-secondary">{{stock?.mortality || 0}}</span>
        </div>

        <div fxLayout="row" class="mr-0" [matMenuTriggerFor]="menu">
          <span class="heading-values">Other details</span>
          <mat-icon class="info-icon text-success" fxLayoutAlign="start center">info</mat-icon>
        </div>


        <!-- Mat menu for more icon -->
        <mat-menu class="other-details-info px-2" fxLayout="column" #menu="matMenu">

          <span fxLayoutAlign="space-between end" class="border-bottom mat-info" fxLayout>
            <span class="dimensions">Male :</span>
            <span class="text-muted">{{ stock?.maleCount || 0 }}</span>
          </span>
          <span fxLayoutAlign="space-between end" class="border-bottom mat-info" fxLayout>
            <span class="dimensions">Female :</span>
            <span class="text-muted">{{ stock?.femaleCount || 0 }}</span>
          </span>
          <span fxLayoutAlign="space-between end" class="border-bottom mat-info" fxLayout>
            <span class="dimensions">Total chicks : &nbsp;</span>
            <span class="text-muted">{{ stock?.totalQuantity || '-' }}</span>
          </span>
          <span fxLayoutAlign="space-between end" class="mat-info" fxLayout>
            <span class="dimensions">Avg bird wt : &nbsp;</span>
            <span class="text-muted">{{ stock?.avgWeight || '-' }}</span>
          </span>

        </mat-menu>
        <!-- Mat menu for more icon -->
      </div>
    </div>
  </div>

  <!-- No content if there no live stock -->
  <ng-container *ngIf="!livestockList?.length">
    <div class="w-100" fxLayoutAlign="center center">
      <kl-no-content
        [text]="'No live stock added yet!'"
        [actionLink]="isPublicPage ? '' : editable ? 'Add live stock' : ''"
        (action)="showLiveStockListPage()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no live stock -->
</div>
