<section class="sales-teams-page mb-3 page-creation-form">
  <div class="w-100 col-7 sales-teams mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="18px">

        <span class="mb-0"><b translate>Products dealing with</b></span>
        <!--Choose crop-->
        <div fxLayout="column">
          <kl-base-product-autocomplete
            formControlName="selectCrops"
            [placeholder]="'Search and select product' | translate"
            [label]="'Search and select product' | translate">
          </kl-base-product-autocomplete>
        </div>
        <!--/Choose crop-->

      </div>
    </form>
    <!-- /Wrapper Form -->

  </div>
</section>
