import { Directive, Inject, Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiFormValidators } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiPageDetails, StateManagerAction, UIMenu } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, map, switchMapTo, take, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../config';
import { PageActions } from '../constants';
import { PAGE_TYPES } from '../modules/page-creation';
import { KalgudiPageService } from '../services/kalgudi-page.service';
import { ProgramStateService } from '../services/program-state.service';

@Directive()
export abstract class KalgudiProgramInfoManagement implements OnDestroy {

  availablePageTypes = PAGE_TYPES;

  pageDetailsForm: FormGroup;

  pageFullDetails: KalgudiPageDetails;

  progress = false;

  isEdit = false;

  pageId: string;

  pageDetails$: Observable<KalgudiPageDetails>;

  editProgram$: Observable<StateManagerAction<PageActions>>;
  deletePage$: Observable<StateManagerAction<PageActions>>;

  listMenu = {
    title: '',
    styleType: 'list',
    menu: [
      {
        title: 'Delete',
        id: 'DELETE',
        // matIcon: 'delete',
        // iconColor: '#8c6baa'
      },
      {
        title: 'Edit',
        id: 'UPDATE',
        // matIcon: 'edit',
        // iconColor: '#8c6baa'
      },

    ]
  };

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  private destroyed = new Subject<any>();

  private appRouting: KalgudiProgramsRouteConfig;
  private bottomSheetService: KalgudiBottomSheetService;

  constructor(
    protected kalgudiPageService: KalgudiPageService,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected programState: ProgramStateService,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected injector: Injector,
  ) {

    // Inject dependencies
    this.appRouting         = this.injector.get<KalgudiProgramsRouteConfig>(KL_ROUTE_CONFIG);
    this.bottomSheetService = this.injector.get(KalgudiBottomSheetService);

    this.pageDetailsForm = this.pageDetailsFormGroup;

    this.editProgram$ = this.programState.action$
      .pipe(
        filter(action => action.type === PageActions.UPDATE_PROGRAM_DETAILS)
      );

    this.deletePage$ = this.programState.action$
      .pipe(
        filter(action => action.type === PageActions.DELETE_PAGE),
      );


    this.pageDetails$ = this.kalgudiPageService.pageDetails$;

    // Patch the latest page details to the page details form group
    this.pageDetails$
      .pipe(
        take(1),
      )
      .subscribe(pageDetails => this.patchPageDetails(pageDetails));

    this.editProgram$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(r => this.openFormEditing());

    this.deletePage$
      .pipe(

        takeUntil(this.destroyed$),
      )
      .subscribe(r => this.deletePage(this.pageId));
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  get destroyed$(): Subject<any> {
    return this.destroyed;
  }

  /**
   * Program info form group
   */
  private get pageDetailsFormGroup(): FormGroup {

    return new FormGroup({
      pageTitle: new FormControl(''),
      vision: new FormControl(''),
      activities: new FormControl(''),
      pageDescription: new FormControl(''),

      emailId: new FormControl('',  KalgudiFormValidators.emailValidators),
      mobileNo: new FormControl('', KalgudiFormValidators.mobileValidators),

      webSite: new FormControl(''),
      programType: new FormControl(''),

      facebookLink: new FormControl(''),
      twitterLink: new FormControl(''),
      linkedInLink: new FormControl(''),
      wikipediaLink: new FormControl(''),

      address: new FormControl(''),
      locationDetails: new FormGroup({
        countryId: new FormControl(''),
        countryName: new FormControl(''),
        districtId: new FormControl(''),
        districtName: new FormControl(''),
        locationLong: new FormControl({ value: '', disabled: true }),
        locationShort: new FormControl(''),
        openerpCountryId: new FormControl(''),
        placeId: new FormControl(''),
        placeName: new FormControl(''),
        stateId: new FormControl(''),
        stateName: new FormControl('')
      }),

      product: new FormControl(''),
      variety: new FormControl(''),

    });
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();

    this.onDestroyed();
  }

  /**
   * Calls  update program api
   */
  save() {

    // Get page details update payload
    const payload = this.getPayload();

    this.progress = true;

    this.kalgudiPageService.updatePage(payload)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onPageDetailsUpdate(res),
        err => this.onUpdateError(err)
      );
  }

  /**
   * Closes the edit form
   */
  closeFormEditing(): void {
    this.isEdit = false;
  }

  /**
   * Toggles the info form
   */
  openFormEditing(): void {

    this.isEdit = true;
  }

  /**
   * Opens bottom sheet for menu list
   */
  openBottomSheet(): void {

    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'DELETE':
        this.deletePage(this.pageId);
        break;

      case 'UPDATE':
        this.openFormEditing();
        break;

    }
  }

  /**
   * Shows the delete confirmation dialog box.
   */
  deletePage(pageId: string): void {

    // Turn on spinner

    this.showDeleteConfirmationDialog()
      .pipe(

        takeUntil(this.destroyed$),

        // Turn on spinner
        tap(accepted => this.notifications.showSpinner(true)),

        // On completion turn off the spinner
        finalize(() => this.notifications.hideSpinner()),

        // Call the delete page service
        switchMapTo(this.kalgudiPageService.deletePage(this.pageId)),
      )
      .subscribe(
        success => {
          // Page deleted successfully lead them to page list page
          this.appRouting.toProgramList();
        },
        err => {
          this.notifications.showMessage('Unable to delete page, please try again later!');
        });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;

  /**
   * Calls after updating page details successfully
   * @param pageDetails
   */
  protected onPageDetailsUpdate(pageDetails: KalgudiPageDetails): void {
    this.closeFormEditing();
  }

  /**
   * Event handler for share update posting API errors.
   */
  protected onUpdateError(err: Error): void {

    this.notifications.showMessage(err.message);
  }

  /**
   * Initializing the program info form
   * @param pageDetails
   */
  private patchPageDetails(pageDetails: KalgudiPageDetails): void {

    this.pageFullDetails = pageDetails;
    this.pageId = pageDetails.pageId;
    this.pageDetailsForm.patchValue(pageDetails);

  }

  /**
   * Prepares payload
   */
  private getPayload(): KalgudiPageDetails {

    return {...this.pageFullDetails, ...this.pageDetailsForm.value};
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible! Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }
}
