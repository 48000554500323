<div class="mt-3">

  <div>
    <h5 translate>Coverage</h5>

    <kl-coverage-stats
      [totalLocations]="pageAnalytics?.totalLocations"
      [totalMembers]="pageAnalytics?.totalMembers"
      [totalProducts]="pageAnalytics?.totalProducts"
      [locationStats]="pageLocationAnalytics"
      [memberStats]="pageMembersAnalytics"
      [productStats]="pageProductsAnalytics"></kl-coverage-stats>
  </div>

  <div class="mb-3">
    <h5 translate>Social</h5>

    <kl-social-stats
      [totalStatsCount]="sharesStats?.totalShares"
      [shareStats]="shareTypeStats"
      [qaStats]="qaTypeStats"
      (pageUpdates)="openPageUpdates()"
      (pageQa)="openPageQa()">
    </kl-social-stats>

  </div>

  <div class="mb-3">
    <h5 translate>Projects</h5>

    <kl-project-stats
      [projectsTotalCount]="projectStats?.totalProjects"
      [tasksTotalCount]="taskStats?.totalTasks"
      [projectStats]="projectTypeStats"
      [taskStats]="taskTypeStats"
      (pageProjects)="openPageProjects()"
      (pageTasks)="openPageTasks()">
    </kl-project-stats>

  </div>

  <div class="mb-3">
    <h5 translate>User Assistants</h5>
    <kl-user-assistants-stats [userStats]="userStats"></kl-user-assistants-stats>
  </div>

</div>
