import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, UIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';

import {
  DigitalAssistProfileLogsMobileDialogComponent,
} from '../components/digital-assist-profile-logs-mobile-dialog/digital-assist-profile-logs-mobile-dialog.component';
import {
  DigitalAssistProfileUpdateMobileDialogComponent,
} from '../components/digital-assist-profile-update-mobile-dialog/digital-assist-profile-update-mobile-dialog.component';
import { DigitalAssistQaComponent } from '../components/digital-assist-qa/digital-assist-qa.component';
import {
  DigitalAssistShareUpdateComponent,
} from '../components/digital-assist-share-update/digital-assist-share-update.component';
import { DigitalAssistTasksMobileDialogComponent } from '../components/digital-assist-tasks-mobile-dialog/digital-assist-tasks-mobile-dialog.component';
import { EnableOfflineDialogComponent } from '../components/enable-offline-dialog/enable-offline-dialog.component';
import {
  EnableOfflineMobileDialogComponent,
} from '../components/enable-offline-mobile-dialog/enable-offline-mobile-dialog.component';
import {
  FrequentMembersPickerMobileDialogComponent,
} from '../components/frequent-members-picker-mobile-dialog/frequent-members-picker-mobile-dialog.component';
import {
  PagesFrequentMembersPickersDialogComponent,
} from '../components/pages-frequent-members-pickers-dialog/pages-frequent-members-pickers-dialog.component';

@Injectable()
export class DigitalAssistanceDialogService {


  constructor(private kalgudiDialogs: KalgudiDialogsService) { }

  /**
   * Opens assist share update Dialog in mobile view
   */
  openMobileAssistShareUpdate(): void {
    this.kalgudiDialogs.openMobileDialog(DigitalAssistShareUpdateComponent, {
      data: {},
      title: 'Share an update',
    });
  }

  /**
   * Opens assist qa in mobile view
   */
  openMobileAssistQa(): void {
    this.kalgudiDialogs.openMobileDialog(DigitalAssistQaComponent, {
      data: {},
      title: 'Ask a question',
    });
  }

  /**
   * Opens user tasks in mobile view
   */
   openMobileAssistTasks(): void {
    this.kalgudiDialogs.openMobileDialog(DigitalAssistTasksMobileDialogComponent, {
      data: {},
      title: 'User Tasks',
    });
  }

  /**
   * Opens assist profile in mobile view
   */
  openMobileAssistProfileUpdate(): void {
    this.kalgudiDialogs.openMobileDialog(DigitalAssistProfileUpdateMobileDialogComponent, {
      data: {},
      title: 'Update profile',
    });
  }

  /**
   * Opens assist profile logs in mobile view
   */
  openMobileAssistProfileLogs(): void {
    this.kalgudiDialogs.openMobileDialog(DigitalAssistProfileLogsMobileDialogComponent, {
      data: {},
      title: 'Profile logs',
    });
  }

  /**
   * Opens offline in mobile view
   */
  showDaOfflineMobileDialog() {
    return this.kalgudiDialogs.openMobileDialog(EnableOfflineMobileDialogComponent, {
      data: {},
      title: 'Select members for offline access',
    });
  }



  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'SHARE_UPDATE':
        this.openMobileAssistShareUpdate();
        break;

      case 'QA':
        this.openMobileAssistQa();
        break;

      case 'UPDATE_PROFILE':
        this.openMobileAssistProfileUpdate();
        break;

      case 'PROFILE_LOGS':
        this.openMobileAssistProfileLogs();
        break;

      case 'ENABLE_OFFLINE':
          this.showDaOfflineMobileDialog();
          break;

      case 'TASKS':
        this.openMobileAssistTasks();
        break;
    }
  }

  /**
   *
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showDaOfflineDialog(
    details: KalgudiDialogConfig,
    dialogConfig: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openDaOfflineMobileDialog(details)
      : this.openDaOfflineWebDialog(details, dialogConfig);
  }

  openDaOfflineWebDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.kalgudiDialogs.openDialog(EnableOfflineDialogComponent, details, dialogConfig);
  }

  openDaOfflineMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogs.openMobileDialog(EnableOfflineMobileDialogComponent, details);
  }

  /**
   * Shows an kalgudi programs frequent picker dialog box. Returns an observable of `KalgudiDialogResult`.
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showFrequentPicker(
    details: KalgudiDialogConfig,
    dialogConfig: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openFrequentMemberPickerMobileDialog(details)
      : this.openFrequentMemberPickerDialog(details, dialogConfig);
  }

  /**
   * Opens frequent member picker dialog
   */
  openFrequentMemberPickerDialog(details: KalgudiDialogConfig, dialogConfig: MatDialogConfig) {
    return this.kalgudiDialogs.openDialog(PagesFrequentMembersPickersDialogComponent, details, dialogConfig);
  }

  /**
   * Opens frequent member picker Mobile dialog
   */
  openFrequentMemberPickerMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogs.openMobileDialog(FrequentMembersPickerMobileDialogComponent, details);
  }
}
