<div class="add-farmer-membership-form">
  <form autocomplete="off" [formGroup]="farmerMembershipForm" fxLayout="column">

    <!-- Organization -->
    <mat-form-field appearance="outline">
      <mat-label>Organization<strong class="strong">*</strong></mat-label>
      <mat-select formControlName="organization">
        <mat-option *ngFor="let organization of organizationList" [value]="organization">
          {{organization}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /Organization -->

    <!-- Organization name -->
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Organization name<strong class="strong">*</strong></mat-label>
      <input matInput placeholder="Enter organization name" formControlName="organizationName">
    </mat-form-field>
    <!-- /Organization name -->

    <!-- Membership types -->
    <mat-form-field appearance="outline">
      <mat-label>Member role<strong class="strong">*</strong></mat-label>
      <mat-select formControlName="memberRole">
        <mat-option *ngFor="let membershipType of membershipTypeList" [value]="membershipType">
          {{membershipType}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /Membership types -->

    <!-- Membership id -->
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Membership Id<strong class="strong">*</strong></mat-label>
      <input matInput placeholder="Enter membership Id" formControlName="farmerMembershipId" (keydown.space)="$event.preventDefault()">
    </mat-form-field>
    <!-- /Membership id -->

    <!-- Since year -->
    <mat-form-field class="example-full-width" appearance="outline">
      <mat-label>Since<strong class="strong">*</strong></mat-label>
      <input matInput placeholder="Enter year" formControlName="sinceYear" (keydown.space)="$event.preventDefault()">
    </mat-form-field>
    <!-- /Since year -->

    <div fxLayoutAlign="end end">
      <kl-flat-button
        type="submit"
        label= "{{ farmerMembershipDetails ? ' Update membership ' : 'Add membership' }}"
        (clicked)="saveOrUpdateMembership()"
        [disabled]="farmerMembershipForm?.invalid">
      </kl-flat-button>
    </div>
  </form>
</div>
