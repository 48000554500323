<div fxLayout="column"
  class="kalgudi-core-theme h-100 overflow-hidden w-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <!-- Embed PDF File -->
  <object class="w-100 h-100" [data]="data?.data?.soilHealthCard[0]?.url | prefixDomain | safe: 'resourceUrl'" type="application/pdf" title="Soil health card">
    <iframe class="w-100 h-100" [src]="data?.data?.soilHealthCard[0]?.url | prefixDomain | safe: 'resourceUrl'"></iframe>
  </object>

</div>
