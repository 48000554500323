import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

/**
 * Preload a lazy loaded module immediately after loading of initial module.
 *
 * `PreloadSameRouteModulesService` preload all modules that are configured by
 * its route definition. To preload a module set the `preload` flag in the route
 * configuration.
 *
 * @author Pankaj Prakash
 *
 * @example
 * {
 *   path: '',
 *   data: {
 *    preload: true,    // <-- Set this flag to preload a lazy loaded module
 *   },
 *   loadChildren: () => import('./modules/program-list/program-list.module').then(m => m.ProgramListModule)
 * },
 *
 * @see https://coryrylan.com/blog/custom-preloading-and-lazy-loading-strategies-with-angular
 */
@Injectable({
  providedIn: 'root'
})
export class PreloadSameRouteModulesService implements PreloadingStrategy {

  constructor() { }

  preload(route: Route, load: () => Observable<any>): Observable<any> {

    return (route.data && route.data.preload)
      ? load()
      : of(null);
  }
}
