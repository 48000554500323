<div id="kl-audio-attachmnet-form-control" class="w-100" fxLayout>
  <div *ngIf="!audioUrl?.value" class="w-100">
    <div *ngIf="!columnButton">
      <button class="mb-3 audio-attachment-btn" mat-button [ngClass]="{'mat-icon-button': !label}" color="primary" (click)="startRecording()" *ngIf="!isRecording" [disabled]="!isPermissionEnabled">
        <mat-icon [color]="isPermissionEnabled ? 'primary' : ''">settings_voice</mat-icon>
        <span class="ml-1" translate>{{label}}</span>
      </button>
    </div>


    <div *ngIf="columnButton" fxLayout="column" fxLayoutAlign="center center">
      <button class="mb-3 audio-attachment-btn mat-icon-button" mat-button color="primary" (click)="startRecording()" *ngIf="!isRecording" [disabled]="!isPermissionEnabled">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-icon [color]="isPermissionEnabled ? 'primary' : ''">settings_voice</mat-icon>
          <span class="ml-1" translate>{{label}}</span>
        </div>

      </button>
    </div>


    <h6 class="mt-2" *ngIf="isRecording && !progress" translate>Recording</h6>
    <div class="rounded-pill my-2 w-100 recording" *ngIf="isRecording" fxLayout fxLayoutAlign="space-between center">

      <div class="ml-3">
        <strong>
          {{format(minutes)}}:{{format(seconds)}}
        </strong>
      </div>

      <div>
        <button mat-button (click)="stopRecording()">
          <mat-icon color="primary">stop</mat-icon>
          <span class="ml-1" translate>Stop</span>
        </button>
      </div>
      <div class="cancel-button">
        <button mat-button (click)="cancelRecording()">
          <mat-icon class="text-danger">delete</mat-icon>
          <span class="ml-1" translate>Cancel</span>
        </button>
      </div>



    </div>

  </div>

  <div class="position-relative my-2" *ngIf="audioUrl?.value" fxFlex="100%">
    <audio class="w-100 outline-none" controls='true' [src]="audioUrl?.value | prefixDomain" *ngIf="audioUrl?.value"></audio>

    <div class="position-absolute clear-audio">
      <mat-icon class="text-danger cursor-pointer" matChipRemove title="Remove record" (click)="clearRecord()">cancel</mat-icon>

    </div>
  </div>

</div>
<div class="w-100 pt-3 warn-msg-wrapper" fxLayout fxLayoutAlign="center end" *ngIf="showWarnMsg && !isPermissionEnabled">
  <p class="mb-0 text-danger warn-msg">Please allow microphone in your device</p>
</div>
