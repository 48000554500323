import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiStreamData } from '../typings';
import { KalgudiDestroyable } from './kalgudi-destroyable';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiBaseStream extends KalgudiDestroyable {

  /**
   * Fetches stream items from the Kalgudi API.
   */
  protected abstract streamApi(offset: number, limit: number, extraParams?: PartialData): Observable<KalgudiStreamData>;

}
