import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PageFiltersService } from '@kalgudi/pages-shared';
import { IdValueMap, KalgudiPageDetails, StringStringMap } from '@kalgudi/types';
import { Observable, timer } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { PageShareFiltersService } from '../../program-target-members/services/page-share-filters.service';
import { AiPredictionsService } from '../services/ai-predictions.service';

@Directive()
export abstract class AiPredictionsFiltersForm extends KalgudiDestroyable {

  @Input()
  pageId: string;

  @Output()
  calendarSummaryDetails = new EventEmitter();

  @Output()
  filterAiPredictionList = new EventEmitter();

  isShowCalendarForm: boolean;
  locationRangeFilter = false;
  stateList: IdValueMap[];
  districtList: IdValueMap[];
  districtArrayList: any;
  countries: IdValueMap[];
  profileKey: string;
  pageDetails: KalgudiPageDetails;

  filterFormGroup: FormGroup;

  memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[],
  }>;

  private filtersService: PageFiltersService;
  protected targetedMetaService: PageShareFiltersService;
  protected kalgudiAppService: KalgudiAppService;
  protected kalgudiPageService: KalgudiPageService;
  protected aiPredictionService: AiPredictionsService;
  protected fb: FormBuilder;
  protected notification: KalgudiNotification;
  protected util: KalgudiUtilityService;
  states: any;
  products: any;
  businessTypes: any;
  locations: any;
  prefilledFilterFormData: any;
  resetFields: boolean;


  constructor(
    protected injector: Injector
  ) {

    super();

    // Inject all dependencies
    this.kalgudiPageService  = this.injector.get(KalgudiPageService);
    this.targetedMetaService = this.injector.get(PageShareFiltersService);
    this.filtersService = this.injector.get(PageFiltersService);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);
    this.aiPredictionService = this.injector.get(AiPredictionsService);
    this.notification = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util = this.injector.get(KalgudiUtilityService);
    this.fb = this.injector.get(FormBuilder);


    this.profileKey = this.kalgudiAppService.profileLocal.profileKey;
    this.memberTargetingFilters$ = this.filtersService.memberTargetingFilters$;
    this.patchProgramDetailsToForm();

    this.filterFormGroup = this.filtersForm;
  }

  /**
   * Users filters field
   */
  get usersFilters(): FormArray {
    return this.filterFormGroup.get('users') as FormArray;
  }

  /**
   * Products filters field
   */
  get productsFilters(): FormArray {
    return this.filterFormGroup.get('products') as FormArray;
  }

  /**
   * Business types filter field
   */
  get businessTypesFilters(): FormArray {
    return this.filterFormGroup.get('businessTypes') as FormArray;
  }

  /**
   * Locations filter field
   */
  get locationsFilters(): FormArray {
    return this.filterFormGroup.get('locations') as FormArray;
  }

  /**
   * Countries filter field
   */
  get countriesFilters(): FormArray {
    return this.filterFormGroup.get('countries') as FormArray;
  }

  /**
   * States filter field
   */
  get statesFilters(): FormArray {
    return this.filterFormGroup.get('states') as FormArray;
  }

  /**
   * Districts filter field
   */
  get districtsFilters(): FormArray {
    return this.filterFormGroup.get('districts') as FormArray;
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Toggling the calendar form
   */
  showCalendarForm() {
    this.isShowCalendarForm = !this.isShowCalendarForm;
  }

  /**
   * Closes filter form
   */
  closeCalendarForm() {
    this.resetFilterFields();
    this.isShowCalendarForm = !this.isShowCalendarForm;
  }

  /**
   * Updating calendar form
   */
  updateCalendarForm() {
    this.showCalendarForm();
    const details = this.filterFormGroup.value;
    const payload = {
      state: details.state || '',
      district: details.district || '',
      mandal: details.mandal || '',
      season: details.seasonType || '',
      crop: details.crop || '',
      variety: details.variety || '',
      from: details.from ? new Date(details.from).toISOString() : '',
      to: details.to ? new Date(details.to).toISOString() : ''
    }

    this.calendarSummaryDetails.emit(payload);

    // this.clearCalendarForm();
  }

  /**
   * Gets latest AI predictions list
   */
  refresh() {

    this.notification.showSpinner();
    this.aiPredictionService.getLatestAIPredictions(this.pageId)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {this.notification.hideSpinner()})
      )
      .subscribe(
        res => {
          this.notification.showMessage('Fetching and calculating the predictions. Please return after sometime')
        },
        err => {
          this.util.apiErrorHandler(err)
        }
      )
  }

  /**
   * Gets AI predictions filters
   */
  getAiPredictionsFilters() {

    this.aiPredictionService.getAIPredictionsFilters(this.pageId, {pageId: this.pageId})
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.countries = this.transformHashMapToArray(res.countries);
          this.states = res.statesOrRegions[this.countries[0].id];
          this.products = res.products;
          this.locations = res.locations;
          this.businessTypes = res.businessTypes;
          this.stateList = this.transformHashMapToArray(res.statesOrRegions[this.countries[0].id]);
          this.districtArrayList = res.districts;
        }
      )
  }

  /**
   * Submit ai prediction filters form
   */
  submitAiPredictionFilter() {

    const payload = {
      filter: this.filterFormGroup.value
    };

    payload.filter.products.splice(1);
    payload.filter.businessTypes.splice(1);
    payload.filter.locations.splice(1);

    let countryArray = [];

    if(this.countries)
    countryArray.push(this.countries[0].id.toString());
    payload.filter.countries = countryArray;

    let stateArray = [];

    if(payload.filter.states !== "") {
      stateArray.push(payload.filter.states.toString());
      payload.filter.states = stateArray;
    } else {
      payload.filter.states = stateArray;
    }

    let districtArray = [];

    if(payload.filter.districts !== "") {

      districtArray.push(payload.filter.districts.toString());
      payload.filter.districts = districtArray;
    } else {
      payload.filter.districts = districtArray;
    }

    this.notification.showSpinner();

    this.aiPredictionService.filterAiPredictions(payload, {pageId: this.pageId, cropId: payload.filter.products[0]})
      .pipe(
        finalize(() => {
          this.calendarSummaryDetails.emit(payload);
          this.isShowCalendarForm = !this.isShowCalendarForm;
          this.notification.hideSpinner();
          this.prefillingFilterForm(payload);
          this.resetFields = false;
        })
        )
        .subscribe(res=> {
          this.filterAiPredictionList.emit(res);
          // this.notification.showMessage('Fetching and calculating the predictions. Please return after sometime');
        },
        err => {
          this.notification.showMessage('Something went wrong, Try again after some time');
          this.filterAiPredictionList.emit('');
        })
  }

  /**
   * Reset filter fields
   */
  resetFilterFields() {
    this.resetFields = true;
    this.productsFilters.clear();
    this.businessTypesFilters.clear();
    this.locationsFilters.clear();
    this.filterFormGroup.get('states').patchValue('');
    this.filterFormGroup.get('districts').patchValue('');
    this.locationRangeFilter = false;
  }

  /**
   * Prefilling filter form
   */
  prefillingFilterForm(payload) {

    let prefilledData = {
      products: {
        id: payload.filter.products && payload.filter.products.length === 1 ? payload.filter.products[0] : '',
        value: payload.filter.products && payload.filter.products.length === 1 ? this.products[payload.filter.products[0]] : ''
      },
      businessTypes:  {
        id: payload.filter.businessTypes && payload.filter.businessTypes.length === 1 ?  payload.filter.businessTypes[0] : '',
        value: payload.filter.businessTypes && payload.filter.businessTypes.length === 1 ? this.businessTypes[payload.filter.businessTypes[0]] : ''
      },
      locations: {
        id: payload.filter.locations && payload.filter.locations.length === 1 ? payload.filter.locations[0] : '',
        value: payload.filter.locations && payload.filter.locations.length === 1 ? this.locations[payload.filter.locations[0]] : ''
      }
    }

    this.prefilledFilterFormData = prefilledData;

  }

  /**
   * Event handler for locations to location range filter toggle
   */
  onLocationRangeFilterChange(val: boolean): void {
    this.locationRangeFilter = val;

    // Location filter is selected, clear location ranges
    val ? this.clearLocationFilters() : this.clearLocationRangeFilters();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Clears all location range filters list.
   */
  private clearLocationRangeFilters(): void {

    this.filterFormGroup.get('states').patchValue('');
    this.filterFormGroup.get('districts').patchValue('');
  }

  /**
   * Clears all location filters list.
   */
  private clearLocationFilters(): void {
    this.locationsFilters.clear();
  }

  /**
   * Form
   */
    private get filtersForm(): FormGroup {

      return new FormGroup({

        users: new FormControl([]),
        products: new FormArray([], Validators.required),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormControl(''),
        states: new FormControl(''),
        districts: new FormControl(''),


        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        pageUrl: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
        isSms: new FormControl(''),
      });
    }


  /**
   * Gets the program details and binding the details to he share form
   */
  private patchProgramDetailsToForm(): void {

    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),

              first(),

              tap(r => this.patchProgramDetails(r))
            )
        )
      ).subscribe();
  }


  /**
   * Transforms hash map of id and value structure to id value type array.
   *
   * @param hashMap Hash map
   */
  protected transformHashMapToArray(hashMap: StringStringMap): IdValueMap[] {

    // Final mapped items
    const mappedItems: IdValueMap[] = [];

    // Null checks, return empty array immediately on null values
    if (!hashMap) {
      return mappedItems;
    }

    // Extract all keys from the hash map
    const ids = Object.keys(hashMap);

    // For each id map its value to a new type
    ids.forEach(id => mappedItems.push({
        id,
        value: hashMap[id]
      })
    );

    // Finally return the mapped list
    return mappedItems;
  }

  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    this.pageDetails = pageDetails;

    this.pageId = pageDetails.pageId;

    // Filters
    this.filterFormGroup.get('isSms').patchValue(false);
    this.filterFormGroup.get('pageId').patchValue(this.pageId);
    this.filterFormGroup.get('pageTitle').patchValue(pageDetails.pageTitle);
    this.filterFormGroup.get('pageType').patchValue(pageDetails.pageType);
    this.filterFormGroup.get('pageUrl').patchValue(pageDetails.pageProfilePic);
    this.filterFormGroup.get('parentPageId').patchValue(pageDetails.pageId);
    this.filterFormGroup.get('parentPageTitle').patchValue(pageDetails.pageTitle);
    this.filterFormGroup.get('parentPageUrl').patchValue(pageDetails.pageProfilePic);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
