import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-question-attachments',
  templateUrl: './survey-question-attachments.component.html',
  styleUrls: ['./survey-question-attachments.component.scss']
})
export class SurveyQuestionAttachmentsComponent implements OnInit {


  @Input()
  attachments: Attachment[];

  @Input()
  url: string;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) {
  }

  ngOnInit() {
  }


  openFullview(): void {
    const images: Attachment[] = this.url ? [{url: this.url, msgType: 'IMAGE'}] : this.attachments;

    this.kalgudiPhotoswipe.open(images, this.env.domain, 0).subscribe();
  }

}
