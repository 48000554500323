<main>
  <mat-card class="pt-0">

    <mat-tab-group class="farming-activity-tabs" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="tabChanged($event)">

      <!-- Seasons tab -->
      <mat-tab [label]="TABS.SEASONS.title | translate">
        <div class="mt-3" fxLayout="column" *ngIf="activeTabIndex === TABS.SEASONS.index">
          <ng-container *ngIf="!progress">
            <kl-farmer-farming-activity-seasons
              [editable]="editable"
              [seasonsList]="seasonsList"
              (onSeasonEditClick)="editSeason($event)">
            </kl-farmer-farming-activity-seasons>
          </ng-container>

          <ng-container *ngIf="progress">
            <kl-no-content
              iconSize="md"
              progressText="Loading seasons..."
              [progress]="progress">
            </kl-no-content>
          </ng-container>

          <div *ngIf="!progress && !seasonsList?.length">
            <kl-farmer-no-content
              [noContentText]="editable ? '' : 'No seasons added yet!'"
              buttonTitle="Add Season"
              svgIcon="assets/svgs/falling-leaves.svg"
              backgroundImage="assets/images/add-farm.png"
              noContentHeight="450px"
              (action)="toAddSeasonTab()">
            </kl-farmer-no-content>
          </div>

        </div>
      </mat-tab>
      <!-- / Seasons tab -->

      <!-- Add season tab -->
      <mat-tab [label]="TABS.ADD_SEASON.title | translate" [disabled]="!editable">
        <div class="geo-fencing" *ngIf="activeTabIndex === TABS.ADD_SEASON.index">
          <kl-farmer-farming-activity-add-season
            [cropId]="cropId"
            [pageId]="pageId"
            [profileKey]="profileKey"
            [isAssisted]="isAssisted"
            [selectedSeason]="selectedSeason"
            [isEditSeason]="isSeasonEdit"
            [farmingFarmType]="farmingFarmType"
            (seasonAdded)="seasonAdded()">
          </kl-farmer-farming-activity-add-season>
        </div>
      </mat-tab>
      <!-- / Add season tab -->

    </mat-tab-group>
  </mat-card>
</main>
