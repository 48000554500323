import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable, KalgudiError } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult, KalgudiUserAwardDetails } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfileAwardsService } from '../../../components/sections/awards/kalgudi-profile-awards.service';
import { KalgudiProfileActionService } from '../../../services/kalgudi-profile-action.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Directive()
export abstract class KalgudiEmployeeAchievements extends KalgudiDestroyable  {

  @Input()
  achievements: KalgudiUserAwardDetails[];

  editable: boolean;

  private awardsService: KalgudiProfileAwardsService;
  private notification: KalgudiNotification;
  private profileActionService: KalgudiProfileActionService;
  private profileService: KalgudiProfileService;

  constructor(protected injector: Injector) {

    super();

    this.awardsService        = this.injector.get(KalgudiProfileAwardsService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileActionService = this.injector.get(KalgudiProfileActionService);
    this.profileService       = this.injector.get(KalgudiProfileService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

  }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Deletes achievements
   */
  deleteAchievement(awardId: string) {

    this.awardsService.deleteAward(awardId)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.onAchievementDeleted(res),
        err => this.onAchievementDeleteFailed(err)
      );
  }

  /**
   * Updates achievement
   */
  updateAchievement(awardId: string, awardDetails: KalgudiUserAwardDetails) {

    return this.profileActionService.updateAward(awardId, awardDetails)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.onAchievementUpdated(res),
        err => this.onAchievementUpdateError(err)
      );
  }


  /**
   * Shows add achievement dialog
   */
  showAddAchievementDialog() {

    return this.profileActionService.showAddAwardDialog()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.onAchievementAdded(res),
        err => this.onAchievementFailed(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // -------------------------------------------------------

  /**
   * Achievement deleted successfully
   */
  protected onAchievementDeleted(res) {
    this.notification.showMessage('Achievement deleted');
  }

  /**
   * Failed to deleted achievement
   */
  protected onAchievementDeleteFailed(err: KalgudiError) {
    this.notification.showMessage('Failed to delete achievement');
  }

  /**
   * Award added successfully
   */
  protected onAchievementAdded(res: KalgudiDialogResult) {
    this.notification.showMessage('Added achievement');
  }

  /**
   * Failed to add award
   */
  protected onAchievementFailed(err: KalgudiError) {
    this.notification.showMessage('Failed to add achievement');
  }

  /**
   * Achievement updated successfully
   */
  protected onAchievementUpdated(res: KalgudiDialogResult) {
    this.notification.showMessage('Achievement updated');
  }

  /**
   * Failed to update Achievement
   */
  protected onAchievementUpdateError(err: KalgudiError) {
    this.notification.showMessage('Failed to update achievement');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
