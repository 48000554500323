<section id="home-stream" class="container" *ngIf="(profile$ | async) as profile">
  <div [ngClass]="{'row': isUbmAndKhetigharHomeStreamView}">
    <div [ngClass]="{'col-md-10 offset-md-1 pl-md-3 pr-md-3': isUbmAndKhetigharHomeStreamView}">

      <!-- Header menu tile -->
      <!-- <div fxHide.gt-xs>
        <core-header-menu-tile [isMatchedProfile]="isMatchedProfile">
        </core-header-menu-tile>
      </div> -->

      <ng-container *ngIf="isUbmAndKhetigharHomeStreamView">
        <div fxHide.gt-xs class="p-0 weather-tile"
          *ngIf="!isMobileDevice && profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set'">
          <kl-home-weather-tile
          [profileKey]="profile?.profileKey"
          [location]="profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo">
          </kl-home-weather-tile>
        </div>
      </ng-container>

      <div class="content row justify-content-center" [ngClass]="{'justify-content-center': isUbmAndKhetigharHomeStreamView}">

        <div class="col-1" fxHide.xs *ngIf="!isUbmAndKhetigharHomeStreamView"></div>

        <div class="content-details" [ngClass]="{'col-10': !isUbmAndKhetigharHomeStreamView, 'col-sm-8': isUbmAndKhetigharHomeStreamView}">

          <!-- Logged in user profile details -->
          <div fxHide.xs>
            <core-profile-details
            [user]="profile"
            [connectsCount]="connectsCount$ | async"
            [profileViewsCount]="profileViewsCount$ | async">
            </core-profile-details>
          </div>
          <!-- / Logged in user profile details -->


          <!-- Share update and Qa tab -->
          <div>
            <core-share-tab>
            </core-share-tab>
          </div>
          <div class="update mt-3 mr-3" fxLayout="row" fxLayoutAlign="center" fxLayoutAlign.xs="end" fxLayoutGap="10px" >
            <span  class="select-options overflow-hidden" translate>
              <!--<mat-form-field appearance="none">
              <mat-select class="update-filters" [formControl]="selectedFilter">
                <mat-option value="LATEST_UPDATES"><span translate>Updates</span></mat-option>
                <mat-option value="SCHEDULED_UPDATES"><span translate>Scheduled</span></mat-option>
              </mat-select>
              </mat-form-field>-->
                <select class="w-100 border-0 cursor-pointer" [formControl]="selectedFilter" id="home-stream-updates-btn">
                  <option value="LATEST_UPDATES" translate>Updates</option>
                  <option value="SCHEDULED_UPDATES"  translate>Scheduled</option>
              </select>
            </span>

            <div fxFlex class="border-bottom mb-3" fxHide.xs></div>
          </div>
          <!-- / Share update and Qa tab -->

          <!-- Home stream items -->
          <div *ngIf="selectedFilter?.value === 'LATEST_UPDATES'" id="home-stream-tiles">
            <div *ngFor="let item of (stream$ | async); let index=index;" class="home-stream-items">

              <div class="my-2" *ngIf="tasksRandomIndex === index && environment?.appId === 'SHAKTIMAN'">
                <core-home-store-banner-tile></core-home-store-banner-tile>
              </div>

              <!-- Home task tile -->
                <div class="my-2" *ngIf="tasksRandomIndex === index">
                  <kl-home-task-tile [profileKey]="profile?.profileKey"></kl-home-task-tile>
                </div>
              <!-- Home task tile -->

              <ng-container>
                <!-- Share update tile -->
                <ng-container *ngIf="item?.event === streamEventType.SHARE_UPDATE || item?.event === streamEventType.TRAINING">

                  <kl-promotional-share-tile
                    [url]="item?.promotionalTitle ? (item | storeLink) : shareUrl?.replace(':shareId', item?.id)"
                    *ngIf="item?.promotionalTitle || (!item?.promotionalTitle && item?.productsMetaData && item?.productsMetaData?.length > 0)"
                    [postDetails]="item"
                    [animationDelay]="index * 1"
                    showPageDetails="true"
                    [showLessData]="true">
                  </kl-promotional-share-tile>

                  <kl-share-update-tile
                    *ngIf="!item?.promotionalTitle && (!item?.productsMetaData || item?.productsMetaData?.length === 0)"
                    [url]="shareUrl?.replace(':shareId', item?.id)"
                    [postDetails]="item"
                    [animationDelay]="index * 1"
                    showPageDetails="true"
                    [showLessData]="true">
                  </kl-share-update-tile>
                </ng-container>

                <!-- QA tile -->
                <ng-container *ngIf="item?.event === streamEventType.QUESTION">
                  <kl-qa-tile
                    [url]="qaUrl"
                    [animationDelay]="index * 1"
                    [postDetails]="item">
                  </kl-qa-tile>
                </ng-container>

                <!-- Survey tile -->
                <ng-container *ngIf="item?.event === streamEventType?.SURVEYS">
                  <kl-survey-tile [surveyDetails]="item"></kl-survey-tile>
                </ng-container>
                <!--/ Survey tile -->

                <!-- Profile visited tile or Connect accepted tile -->
                <ng-container *ngIf="item?.event === streamEventType.PROFILE_VISITED || item?.event === streamEventType.CONNECT_REQUEST_ACCEPTED">
                  <kl-networks-tile
                    [animationDelay]="index * 1"
                    [isConnected]="item?.event === streamEventType.CONNECT_REQUEST_ACCEPTED"
                    [notification]="item">
                  </kl-networks-tile>
                </ng-container>
              </ng-container>
            </div>

            <ng-container *ngIf="(paginator$ | async) as paginator">
              <ng-container *ngIf="(stream$ | async) as stream">
                <ng-container *ngIf="stream?.length || paginator?.loading">
                  <div class="m-4">
                    <kl-load-more
                      id="home-stream-load-more"
                      noMoreText="No more updates"
                      [progress]="paginator?.loading"
                      [hasMoreItems]="paginator?.hasItems"
                      (clicked)="nextPage()">
                    </kl-load-more>
                  </div>
                </ng-container>

              </ng-container>
            </ng-container>
          </div>
          <!-- / Home stream items -->

          <div *ngIf="selectedFilter?.value === 'SCHEDULED_UPDATES'">
            <core-scheduled-updates-stream [filters]="filters"></core-scheduled-updates-stream>
          </div>
        </div>

        <div class="footer-mobile-tab-wrapper fixed-bottom" fxLayout="row" fxHide.gt-xs>

          <ng-container *ngFor="let menu of footerMenus; let first=first;">
            <div class="border-right my-2" *ngIf="!first"></div>
            <div fxFlex fxFlexAlign="center">
              <kl-mobile-tabs [menu]="menu" (clicked)="menu.clickAction()"></kl-mobile-tabs>
            </div>
          </ng-container>
        </div>

        <!-- Right sidebar -->
        <aside class="col-sm-4" fxHide.xs *ngIf="isUbmAndKhetigharHomeStreamView">
          <div class="sticky-top right-content">
            <kl-home-weather-tile
              *ngIf="!isMobileDevice && profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set'"
              [profileKey]="profile?.profileKey"
              [location]="profile?.lstOfUserBusinessDetailsInfo[0]?.locationTo">
            </kl-home-weather-tile>

            <core-app-footer></core-app-footer>
          </div>
          <!-- <div class="position-fixed">
          <core-sidebar-image></core-sidebar-image>
          <core-app-footer></core-app-footer>
          </div> -->
        </aside>
        <div class="col-1" fxHide.xs *ngIf="!isUbmAndKhetigharHomeStreamView"></div>
      </div>
    </div>
  </div>
</section>
