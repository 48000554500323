import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { IdValueMap, KalgudiUser } from '@kalgudi/types';

import { KalgudiAddProducts } from '../../classes/kalgudi-add-products';
import { UNITS_LIST } from '../../constants';

@Component({
  selector: 'kl-add-products',
  templateUrl: './add-products.component.html',
  styleUrls: ['./add-products.component.scss']
})
export class AddProductsComponent extends KalgudiAddProducts implements OnInit {

  @Output()
  dealWithAdded = new EventEmitter<KalgudiUser>();

  readonly allUnits: IdValueMap[] = UNITS_LIST;

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {

    this.init();

  }


  onDestroyed(): void {}

  /**
   * Called after successful product addition
   */
  onProductAdded(res: KalgudiUser): void {
    this.dealWithAdded.emit(res);
  }
}
