import { ChangeDetectionStrategy, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CoreDefaultRouteConfigService } from '@app/config';
import { MENU_LIST, PROFILE_MENU_LIST } from '@app/constants';
import { environment } from '@app/env';
import { KalgudiAppConfiguration, RouteData } from '@app/models';
import { RouterEventsService } from '@app/services';
import {
  DEFAULT_PROFILE_PIC,
  KALGUDI_PROFILE_BUSINESS_TYPE_NAMES,
  kalgudiAnimations,
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiTranslationService,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiUser, UIMenu } from '@kalgudi/types';
import { ChangePasswordDialogService, KalgudiUserSettingsRouteConfig } from '@kalgudi/user-settings';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CommonAppService } from 'src/app/services/common-app.service';

@Component({
  selector: 'core-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent extends KalgudiDestroyable implements OnInit {

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  adminProfileKeys = [
    'M01pft20PRFREG2020082432003237UNH001', // SHRI SAUMITRA KESH PANDEY, 7618070003
    'M01pcwy0PRFREG2020062538535852UNH001', // SHRI AMARESH SANNIGRAHI, 8726027461
    'M01pcx30PRFREG2020062538543898UNH001', // SHRI A K SRIVASTAVA, 9433024033
    'M01pe1x0PRFREG2020070818819327UNH001', // SHRI AMIT KUMAR, 7618970009
    'M01pe1y0PRFREG2020070818941596UNH001', // SHRI DILEEP KUMAR SINGH, 9336132505
    'M01pe1z0PRFREG2020070819062104UNH001', // SHRI SHRI PRAKASH SINGH, 8726037713
    'M01pe3c0PRFREG2020071144417088UNH001', // Praveen Paliwal, 9889148147
    'M01pe3e0PRFREG2020071147276015UNH001', // Devesh Saboo, 9555030476
    'M01oyw40PRFREG2020061517302318UNH001', // Indo Gulf Fertilisers, igf-jag.krishi@adityabirla.com
    'M001jlc0PRFREG2016022328002820USS001', // Sreekanth voleti, 9177546888
    'M00j46m0PRFREG2017020219981952UNH001', // sreekanth voleti, sreekanth.voleti@vasudhaika.net,
    'M01pcww0PRFREG2020062538534121UNH001', // SHRI SAUMITRA KESH PANDEY, 8902499563
  ];

  @Output()
  toggleMenu = new EventEmitter();

  appConfig: KalgudiAppConfiguration = environment.appConfig;

  webMenu: UIMenu[] = MENU_LIST;
  profileMenu: UIMenu[] = PROFILE_MENU_LIST;

  isLoggedIn: boolean;

  userDetails: KalgudiUser;

  timedOutCloserProjects;
  timedOutCloserPages;

  timedOutCloserProfile;
  isMouseEntered = false;
  isMenuOpen = false;

  headerState$: Observable<{login: boolean, routeData: RouteData}>;
  login$: Observable<boolean>;
  profile$: Observable<KalgudiUser>;
  selectedLang: string;
  loggedInProfileKey: string;
  isMatchedProfile = false;

  domain: string;
  bizType = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;

  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiUserSettingsRouteConfig,
    private router: Router,
    private kalgudiApp: KalgudiAppService,
    private routerEvents: RouterEventsService,
    private kalgudiUtilService: KalgudiUtilityService,
    private changePasswordDialog: ChangePasswordDialogService,
    private translate: KalgudiTranslationService,
    private commonService: CommonAppService,
    private coreRouting: CoreDefaultRouteConfigService,
  ) {
    super();

    this.userDetails = kalgudiApp.profileLocal;

    this.login$ = this.kalgudiApp.loginState$;
    this.profile$ = this.kalgudiApp.profile$;

    this.headerState$ = combineLatest(
    this.kalgudiApp.loginState$,
    this.routerEvents.routeData$
    ).pipe(

    map(([login, routeData]) => {
        return {
          login, routeData
        };
      })
    );

    // if (this.userDetails) {
    //   this.loggedInProfileKey = this.userDetails.profileKey;

    //   for (const profileKey of this.adminProfileKeys) {
    //     if (profileKey === this.loggedInProfileKey) {
    //       this.isMatchedProfile = true;
    //     }
    //   }
    // }

    this.domain = this.env.coreDomain;
  }

  ngOnInit() {
    this.selectedLang = this.translate.activeLanguage;

    this.kalgudiApp.login$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(_ => this.getValidateAdmins());
  }

  onDestroyed(): void {}

  onToggleSideNav(): void {
    this.toggleMenu.emit();
  }

  /**
   * Gets validate admins
   */
  getValidateAdmins() {

    if (this.kalgudiApp.loggedIn) {
      this.commonService.getValidateAdmins()
        .subscribe(
          res => {
            this.isMatchedProfile = res.status;
          }
        );
    }
  }

  /**
   * sign out
   */
  logout(): void {

    this.kalgudiApp.logout(true)
      .subscribe(_ => {
        // if (!this.env.appId.includes('SHAKTIMAN')) {
        //   this.appRouting.toLogin();
        // }

        this.appRouting.toLogin();
      });
  }

  goToCredits() {
    this.appRouting.toCreditDetails();
  }

  goToHome(): void {
    this.appRouting.toHome();
  }

  goToLogin(): void {
    this.appRouting.toLogin();
  }

  /**
   * Navigating to fpo admin app
   */
  fpoAdminApp() {
    this.coreRouting.toFpoAdminApp();
  }

  /**
   * Handles back button navigation for mobile devices.
   */
  navigateBack(url: string): void {

    if (url) {
      this.router.navigate([url]);
    } else {
      this.kalgudiUtilService.toGoPreviousPage();
    }
  }

  /**
   * Displays menu on hover
   */
  mouseEnter(trigger, id) {

    if ( id === 'PROJECTS') {
      if (this.timedOutCloserProjects) {
        clearTimeout(this.timedOutCloserProjects);
      }
    } else {

      if (this.timedOutCloserPages) {
        clearTimeout(this.timedOutCloserPages);
      }

    }

    trigger.openMenu();
  }

  /**
   * Disappears menu when mouse out
   */
  mouseLeave(trigger, id) {

    if ( id === 'PROJECTS') {
      this.timedOutCloserProjects = setTimeout(() => {
        trigger.closeMenu();
      }, 50);
    } else {
      this.timedOutCloserPages = setTimeout(() => {
        trigger.closeMenu();
      }, 50);
    }

  }


  /**
   * Displays main menu on hover
   */
  buttonEnter(trigger) {

    this.timedOutCloserProfile = trigger
    setTimeout(() => {
      if(this.timedOutCloserProfile != trigger){
        this.timedOutCloserProfile.closeMenu();
        this.isMenuOpen = false;
        this.isMouseEntered = false;
        trigger.openMenu();
      }
      else if (!this.isMenuOpen) {
        this.isMouseEntered = true;
        trigger.openMenu();
      }
      else {
        this.isMouseEntered = true;
      }
    })
  }


  /**
   * Open admin page
   */
  openAdminPage(id: string, profileKey: string) {
    if ( id === 'ADMIN') {
      window.open(`${ this.env.domain}/admin`, '_blank');
    } else if (id === 'MY_PROFILE') {
      this.coreRouting.toProfile({ profileKey })
    }
  }

  /**
   * Disappears main menu when mouse out
   */
  buttonLeave(trigger) {
    setTimeout(() => {
      if (this.isMouseEntered && !this.isMenuOpen) {
        trigger.closeMenu();
      } if (!this.isMenuOpen) {
        trigger.closeMenu();
      } else {
        this.isMouseEntered = false;
      }
    }, 100)
  }

  /**
   * Enteres mouse on menu
   */
  menuEnter() {
    this.isMenuOpen = true;
    if (this.isMouseEntered) {
      this.isMouseEntered = false;
    }
  }

  /**
   * Disappears mouse on menu
   */
  menuLeave(trigger) {
    setTimeout(() => {
      if (!this.isMouseEntered) {
        this.isMenuOpen = false;
        trigger.closeMenu();
      }
    }, 80)
  }

  /**
   * Displays sub menu on hover of main menu
   */
  matMenuenter() {
    this.isMouseEntered = true;
  }

  /**
   * Disappears sub menu when mouse out
   */
  matMenuLeave(trigger1, trigger2) {
    setTimeout(() => {
      if (this.isMouseEntered) {
        trigger1.closeMenu();
        this.isMenuOpen = false;
        this.isMouseEntered = false;
      } else {
        this.isMouseEntered = false;
        trigger2.closeMenu();
      }
    }, 100)
  }

  /**
   * Translate to Eng
   */
  languageTranslation(language: string) {

    this.selectedLang = language;
    this.translate.updateActiveLanguage(language);
  }


  /**
   * Opens change password dialog
   */
  openChangePasswordDialog(profileKey: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Change password',
      acceptButtonTitle: 'Change password',
      rejectButtonTitle: 'Cancel',
      data: {
        profileKey
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.changePasswordDialog.openChangePasswordDialog(dialogDetails, dialogConfig).subscribe();
  }

}
