<main id="promotional-share-crops" *ngIf="cropsList?.length" class="my-2">
  <!-- <div class="mb-2" *ngIf="cropsList?.length">
    <span class="crops">Applicability</span>
  </div> -->
  <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" (click)="$event.stopPropagation();" [config]="slideConfig">
    <div ngxSlickItem *ngFor="let crops of cropsList" class="slide crop-list">
      <div class="w-100 cursor-pointer" fxLayout="column" fxLayoutAlign="center center">
        <div class="crop-image rounded-circle" *ngIf="crops?.attachments && (crops?.attachments[0]?.attachments?.length || crops?.attachments?.attachments?.length)else noImage">
          <img [src]="(crops?.attachments[0]?.attachments[0]?.url || crops?.attachments?.attachments[0]?.url ) | prefixDomain" width="30px" height="30px" class="rounded-circle"
            [klErrorImages]="[
            (crops?.attachments[0]?.attachments[0]?.url || crops?.attachments?.attachments[0]?.url) | prefixDomain,
            'assets/svgs/farm-crop.svg'
            ]">
        </div>

        <ng-template #noImage>

          <img src="assets/svgs/farm-crop.svg" width="30px" height="30px" class="rounded-circle">
        </ng-template>
        <span class="crop-name text-center mt-1 text-truncate">{{ crops?.productName }}</span>
      </div>
    </div>
  </ngx-slick-carousel> -->

  <div fxLayout.gt-xs="row wrap" fxLayout.xs="row" class="crop-list overflow-auto" fxLayoutAlign="start start">
    <div *ngFor="let crops of cropsList" class="crop-tile" fxFlex.xs="calc(23%)">

    <div class="text-center cursor-pointer d-inline-block">

      <img class="crop-image rounded-circle" *ngIf="crops?.attachments && (crops?.attachments[0]?.attachments?.length || crops?.attachments?.attachments?.length)else noImage" [src]="(crops?.attachments[0]?.attachments[0]?.url || crops?.attachments?.attachments[0]?.url ) | prefixDomain" width="30px" height="30px" class="rounded-circle"
        [klErrorImages]="[
        (crops?.attachments[0]?.attachments[0]?.url || crops?.attachments?.attachments[0]?.url) | prefixDomain,
        'assets/svgs/farm-crop.svg'
        ]">

      <ng-template #noImage>

        <img src="assets/svgs/farm-crop.svg" width="30px" height="30px" class="rounded-circle">
      </ng-template>
      <p class="crop-name text-center mb-0 mt-1 text-truncate">{{ crops?.productName }}</p>
    </div>

    </div>
  </div>
</main>
