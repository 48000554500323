<main id="loginid-update-form">
  <form autocomplete="off" [formGroup]="profileForm">
      <!-- Name -->
      <div fxLayout="column" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxLayout="column" fxFlex>
          <mat-label translate>Login id</mat-label>
          <input matInput required formControlName="newMobileNo" [placeholder]="'Please enter your login id' | translate" [readonly]="isEmailId">
          <mat-error *ngIf="!isEmailId">{{(profileForm.controls['newMobileNo'].hasError('required') ? 'Mobile number is required' : 'Please enter a valid mobile number') | translate }}</mat-error>
        </mat-form-field>

        <p class="note pl-1" translate *ngIf="isEmailId"><b>Email update not yet enabled</b></p>
      </div>
      <!--/ Name -->

      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
        <kl-flat-button
          label="Update"
          [progress]="progress"
          (clicked)="updateLoginId()"
          [disabled]="!profileForm?.valid || isEmailId">
        </kl-flat-button>
      </div>
  </form>
</main>
