import { Inject, Injector, Directive } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyList } from '@kalgudi/surveys';
import { KalgudiPageRelation, KalgudiPageSurveyDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PageActions } from '../../../constants';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { ProgramStateService } from '../../../services/program-state.service';

@Directive()
export abstract class KalgudiPageSurveysList extends KalgudiSurveyList<KalgudiPageSurveyDetails> {

  memberRole: KalgudiPageRelation;

  // Dependencies
  protected programState: ProgramStateService;
  protected kalgudiProgram: KalgudiPageService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {
    super(
      notification,
      util,
      injector
    );

    // Manually inject dependencies
    this.programState   = this.injector.get(ProgramStateService);
    this.kalgudiProgram = this.injector.get(KalgudiPageService);

    // Get member role
    this.kalgudiProgram.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {
        this.memberRole = pageDetails.memberRole;
      });


    // Subscribe to page state manager state changes
    this.subscribeToPageActions();
  }



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Creates a new page in the survey, calls the survey creation dialog with
   * extra payload params.
   */
  createSurveyInPage() {

    const pageParams: PartialData = {
      pageId: this.programState.id,
    };

    this.openCreateSurveyDialog(pageParams);
  }

  /**
   * Navigating to survey full view
   *
   * @override
   */
  openSurveyFullView(survey: KalgudiPageSurveyDetails): void {

    const surveyId = survey.surveyId;

    const routing: KalgudiProgramsRouteConfig = this.appRouting as any;

    if (survey.role === this.memberRoles.ADMIN ) {
      routing.toPageSurveyLogs(this.programState.id, surveyId);
    } else {

      routing.toPageSurveySubmission(this.programState.id, surveyId);
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Adds extra pageId to the extra query param of the filter API and calls the parent
   * class method.
   *
   * @override
   */
  protected filterApi(
    searchKeyword: string,
    filters: any,
    offset: number,
    limit: number,
    extraParams?: PartialData
  ): Observable<KalgudiStreamData> {

    const pageId = this.programState.id;

    extraParams = extraParams ? { ...extraParams, pageId } : { pageId };

    return super.filterApi(searchKeyword, filters, offset, limit, extraParams);
  }


  /**
   * Subscribes to page state changes
   */
  private subscribeToPageActions(): void {

    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === PageActions.PAGE_SURVEY_ADD) {
          this.createSurveyInPage();
        }

        // console.log('state', action);
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
