<mat-card class="mt-1 mb-0 project-list-header tasks">
  <div class="mb-2 task-breadcrumbs" fxLayout="row" fxLayoutAlign="space-between center">
    <kl-breadcrumbs></kl-breadcrumbs>
  </div>

  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="0" fxLayout.sm="column" fxLayout.xs="column" fxLayoutGap.xs="16px">
    <!-- Content -->
    <div class="w-100" fxFlex="45%" fxLayout="row" fxLayoutGap="0" fxLayout.xs="column" fxLayoutGap.xs="16px">
      <div fxLayout fxFlex="100%" fxLayout.xs="column" class="w-100">
        <div fxLayout  fxFlex="100%" fxLayoutAlign="start center" class="w-100">
          <div fxLayoutGap="10px" [@scaleOut] fxFlex="10%" fxFlex.xs="15%">
            <img src="assets/images/crop-calender.png" class="calender-image" alt="cal-image">
          </div>
          <div [@slideRight] class="ml-1" fxFlex="80%" fxLayout.xs="row" fxLayoutGap.xs="4px" fxLayoutAlign.xs="space-between center">
            <div *ngIf="!loggedInUserTasks" fxFlex="100%" fxFlex.xs="calc(94% - 4px)">
              <h4 class="text-capitalize text-truncate w-100 mb-0" [matTooltip]="projectDetails?.title">{{ projectDetails?.title }}</h4>
              <div class="time-frame text-secondary" *ngIf="projectDetails?.startDate && projectDetails?.endDate">
                <span class="text-muted">
                  {{ projectDetails?.startDate | date: 'dd' }}
                  {{ projectDetails?.startDate | date: 'MMM' | translate }}
                  {{ projectDetails?.startDate | date: 'yyyy' }}
                </span>
                <span> to </span>
                <span class="text-muted">
                  {{ projectDetails?.endDate | date: 'dd' }}
                  {{ projectDetails?.endDate | date: 'MMM' | translate }}
                  {{ projectDetails?.endDate | date: 'yyyy' }}
                </span>
              </div>
            </div>
            <div fxFlex="calc(6% - 4px)" fxHide.gt-xs>
              <button mat-icon-button class="view-menu-buttons" [matMenuTriggerFor]="matMenu">
                <mat-icon class="text-muted">more_vert</mat-icon>
              </button>
              <mat-menu #matMenu="matMenu" [xPosition]="'before'" class="mat-menu-md task">
                <div *ngFor="let menu of projectMobileMenu" mat-menu-item>
                  <button class="p-0" mat-menu-item (click)="showProjectsList(menu)">
                    <svg-icon [src]="menu?.svgIcon" class="text-center view-icon"
                    [applyCss]="true"
                    [svgStyle]="{ 'width.px': 18, 'height.px': 18}"></svg-icon>
                    <span class="ml-2 view-title">{{menu?.title}}</span>
                  </button>
                </div>

              </mat-menu>
            </div>
            <div *ngIf="loggedInUserTasks">
              <h4 class="text-capitalize text-truncate" translate> Tasks</h4>
              <p class="text-success projectDetails mb-0 mt-n1" translate>
                List of tasks that you have participated
              </p>
            </div>

            <!-- <p class="mb-0 projectDetails text-muted"><span>CreatedBy: </span>{{ projectDetails?.createdBy?.firstName }}</p> -->
          </div>
        </div>

      </div>

    </div>
    <!-- /Content -->

    <!-- Header options -->
    <div fxLayout="row" fxLayout.xs="column">
      <!-- Search bar -->
      <!-- <div fxLayoutAlign="center center">
        <kl-search placeHolder="Search projects" [searchForm]="searchForm"></kl-search>
      </div> -->
      <!-- /Search bar -->

      <!-- Add members and tasks -->
      <div class="action-btn-wrapper" fxLayout="row" fxLayoutAlign="start end" fxLayoutGap="30px" fxLayoutGap.xs="15px" *ngIf="!loggedInUserTasks">
        <button fxFlex.xs="50%" mat-stroked-button color="primary" class="tasks-button"
          [disabled]="projectDetails?.userRole !== projectRoles?.ADMIN"
          (click)="openTaskCreationDialog()">
          <div fxLayout fxLayoutAlign="center center">
              <p class="mb-0 text-muted tasks mr-1">{{projectDetails?.statistics?.tasks?.total| number}} </p>
              <p class="mb-0 text-muted label mr-2" translate>Tasks</p>
              <mat-icon *ngIf="projectDetails?.userRole === projectRoles?.ADMIN">add_circle</mat-icon>
          </div>
        </button>

        <button fxFlex.xs="50%" mat-stroked-button color="primary" class="tasks-button"
          [disabled]="projectDetails?.userRole !== projectRoles?.ADMIN"
          (click)="showAddMembersDialog()">
          <div fxLayout fxLayoutAlign="center center">
              <p class="mb-0 text-muted tasks mr-1"> {{projectDetails?.statistics?.members?.member | number}} </p>
              <p class="mb-0 text-muted label mr-2" translate>Members</p>
              <mat-icon *ngIf="projectDetails?.userRole === projectRoles?.ADMIN">add_circle</mat-icon>
          </div>
        </button>
      </div>
      <!-- / Added members and task -->

      <div class="border-right ml-3"></div>

      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center" fxHide.xs>
        <div class="select-filter p-1" fxLayoutAlign="center center">
          <button disabled mat-button (click)="openFilterSidebar()" class="mt-n2">
            <svg-icon src="assets/svgs/filter.svg" class="text-center menu-icons" [applyCss]="true"
              [svgStyle]="{ 'width.px': 24, 'height.px': 24}" title="Filter"></svg-icon>
            <span class="ml-2" translate>Filter</span>
          </button>
        </div>
        <div *ngFor="let menu of projectsMenu" fxLayoutAlign="center center">
          <div *ngIf="menu?.svgIcon" class="mt-n2"
          [ngClass]="{'selected-icons': menu?.id === selectedView, 'menu-icons': menu?.id !== selectedView}" >
            <button mat-icon-button (click)="showProjectsList(menu)"
              [disabled]="menu?.disabled"
              [matTooltip]="menu?.id | titlecase | translate">
              <div [@scaleOut]>
                <svg-icon [src]="menu?.svgIcon" class="text-center"
                  [applyCss]="true"
                  [svgStyle]="{ 'width.px': 18, 'height.px': 18}"></svg-icon>
              </div>
            </button>
          </div>
        </div>

        <div class="mt-n2">
          <button mat-icon-button
            *ngIf="projectDetails?.userRole === projectRoles?.ADMIN"
            [matMenuTriggerFor]="menu">
            <mat-icon class="text-muted">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

            <button mat-menu-item (click)="showUpdateProject(projectDetails?.id)">
              <mat-icon class="action-icons">edit</mat-icon>
              <span translate>Edit</span>
            </button>

            <button mat-menu-item (click)="deleteProject(projectDetails?.id)">
              <mat-icon class="action-icons">delete_outline</mat-icon>
              <span translate>Delete</span>
            </button>

          </mat-menu>
        </div>
      </div>
    </div>
    <!--/ Header options -->
  </div>
</mat-card>
