import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponsePageMemberSearch } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class KalgudiProgramSearchApiService {


  /**
   * `v2/pages/search/members`
   */
  private readonly API_SEARCH_MEMBERS = `${this.env.restBaseUrlV2}/pages/search/members`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Makes an API call to search list of all entity members with the specified
   * search keyword.
   */
  searchMembers(
    pageId: string,
    text: string,
    offset: number,
    limit: number,
    gender?: string,
  ): Observable<KalgudiUserBasicSearchResults> {

    const params = {
      text,
      pageId,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    if (gender !== 'ALL') {
      params['gender'] = gender;
    }

    return this.httpClient.get<ApiResponsePageMemberSearch>(this.API_SEARCH_MEMBERS, { params })
      .pipe(

        // Check for API errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi user search result type
        map(res => ({ items: res.data.results, count: res.data.count }))
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
