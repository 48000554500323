import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { KalgudiQaService } from '@kalgudi/qa';
import { ShareQaFullDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class QuestionAnswerResolverService implements Resolve<ShareQaFullDetails> {

  constructor(
    private qa: KalgudiQaService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ShareQaFullDetails | Observable<ShareQaFullDetails> | Promise<ShareQaFullDetails> {

    // Fetch profile key from route params if available
    // Otherwise fetch the profile key from app.
    const questionId = route.params.questionId;

    return this.qa.getQuestion(questionId)
      .pipe(
        take(1)
      );
  }
}
