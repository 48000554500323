import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiProjectListApiService } from './kalgudi-project-list-api.service';

@Injectable()
export class KalgudiProjectListService {

  constructor(private api: KalgudiProjectListApiService) { }

  getProjects(offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {

    return this.api.fetchStream(offset, limit, filters);
  }

  /**
   * Makes an API call to search list of all projects with the specified
   * search keyword.
   */
  searchProjects(searchKeyword: string, offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {
    return this.api.searchProjects(searchKeyword, offset, limit, filters);
  }
}
