import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { FPOInfrastructure, KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import {
  FpoInfrastructureDialogComponent,
} from '../components/fpo-infrastructure/fpo-infrastructure-dialog/fpo-infrastructure-dialog.component';
import {
  FpoInfrastructureMobileDialogComponent,
} from '../components/fpo-infrastructure/fpo-infrastructure-mobile-dialog/fpo-infrastructure-mobile-dialog.component';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class KalgudiFpoInfrastructure extends KalgudiDestroyable {

  editable: boolean;
  fpoInfrastructureDetails: FPOInfrastructure;
  profileDetails: KalgudiUser;

  private profileService: KalgudiProfileService;
  private dialogService: KalgudiDialogsService;
  private orgProfileService: OrganisationProfileService;
  private profileStateService: KalgudiProfileStateService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.profileService      = this.injector.get(KalgudiProfileService);
    this.dialogService       = this.injector.get(KalgudiDialogsService);
    this.orgProfileService   = this.injector.get(OrganisationProfileService);
    this.profileStateService = this.injector.get(KalgudiProfileStateService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    this.profileStateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;

        this.fpoInfrastructureDetails = this.profileDetails.fpoAdditionalDetails && this.profileDetails.fpoAdditionalDetails.fpoInfrastructure
                                          ? this.profileDetails.fpoAdditionalDetails.fpoInfrastructure : {};

      });
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows add fpo infrastructure dialog
   */
  showFpoInfrastructureDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'FPO infrastructure',
      data: {

      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'fpo-infrastructure',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {

      }
    };

    return this.openFpoInfrastructureDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open fpo infrastructure dialog
   */
  private openFpoInfrastructureDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(FpoInfrastructureMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(FpoInfrastructureDialogComponent, dialogConfig, matDialogConfig);

    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
