import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiAppService, KalgudiMatTableFilteredSearchStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment, KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, skip, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { ProfileLogsStateService } from '../services/profile-logs-state.service';
import { ProfileLogsService } from '../services/profile-logs.service';

@Directive()
export abstract class KalgudiProfileLogsListTable extends KalgudiMatTableFilteredSearchStream<KalgudiPageDetails> {
  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input()
  profileKey: string;

  @Input()
  pageId: string;

  progress: boolean;
  contributorId: string;
  memberId: string;

  private profileLogService: ProfileLogsService;
  private profileState: KalgudiProfileStateService;
  private kalgudiApp: KalgudiAppService;
  private kalgudiPhotoswipe: KalgudiPhotoswipeService;
  private env: KalgudiEnvironmentConfig;
  private stateService: ProfileLogsStateService;

  constructor(protected injector: Injector) {
    super();

    this.profileLogService = this.injector.get(ProfileLogsService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.kalgudiApp        = this.injector.get(KalgudiAppService);
    this.kalgudiPhotoswipe = this.injector.get(KalgudiPhotoswipeService);
    this.env               = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.stateService      = this.injector.get(ProfileLogsStateService);

    this.contributorId = this.kalgudiApp.profileLocal.profileKey;


    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.memberId = profile.profileKey;
        }
      );

    this.displayedColumns = [
      'page',
      'logMessage',
      // 'location',
      'attachments',
      'assistant',
      'time'
    ];


    this.stateService.profileLogUpdated$
      .pipe(

        takeUntil(this.destroyed$),

        skip(1)
      )
      .subscribe(
        (res) => this.resetTable()
      );
  }

  /**
   * Profile log stream api
   *
   * @param searchKeyword
   * @param offset
   * @param limit
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    this.progress = true;

    const memberId = this.profileKey ? this.profileKey : this.memberId;

    return this.profileLogService.getProfileLogsStream(memberId, this.contributorId, this.pageId, offset, limit)
    .pipe(
      finalize(
        () => this.progress = false
      ),
    );
  }

  /**
   * Opens image full view
   */
  openFullview(attachments: Attachment[]): void {

    this.kalgudiPhotoswipe.open(attachments, this.env.domain, 0).subscribe();
  }
}
