import { Injector, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiFormValidators } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { AvailableCredits } from '@kalgudi/types';


@Directive()
export abstract class KalgudiAddCredits extends KalgudiDestroyable {

  creditForm: FormGroup;

  progress: boolean;

  // defining available credits
  creditsAvailable = [
    { credits: 1000, amount: (1000 / 5) },
    { credits: 5000, amount: (5000 / 5) },
    { credits: 10000, amount: (10000 / 5) },
    { credits: 20000, amount: (20000 / 5) }
  ];

  selectedCredits: AvailableCredits;
  isSmsSendingEnabled = false;

  private fb: FormBuilder;
  private appService: KalgudiAppService;
  private notifications: KalgudiNotification;

  constructor(
    protected injector: Injector
  ) {
    super();
    // Manually inject dependencies
    this.fb         = this.injector.get(FormBuilder);
    this.appService = this.injector.get(KalgudiAppService);
    this.notifications = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.creditForm = this.addCreditsForm;
    this.patchLoggedInUserDetails();
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * service call to add credits
   */
  addCredits() {
    this.progress = true;
    const payload = this.preparePayload();
    // this.creditsService.addCredits(this.pageId, payload)
    //   .subscribe(
    //     res => this.onAddCreditSuccess(res),
    //     err => {
    //       this.notifications.showMessage('Unable to add credits, please try again later.');
    //     }
    // );
  }

  /**
   * Amount updation by changing the credit value
   */
  selectCredit(credit: AvailableCredits) {
    if (credit) {
      this.isSmsSendingEnabled = true;
    }
    // To get selected credits and amount
    this.selectedCredits = credit;
    // console.log(this.selectedCredits);
  }


  /**
   * Resets the add credits form and resets selectedCredits to null
   */
  resetForm(): void {
    this.creditForm.reset();
    this.selectedCredits = null;
  }


  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * from group for adding credits
   */
  private get addCreditsForm(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.required, ...KalgudiFormValidators.emailValidators]],
      phone: ['', [Validators.required, ...KalgudiFormValidators.mobileValidators]]
    });
  }

  /**
   * Patch form values
   */
  private patchLoggedInUserDetails() {
    const profile = this.appService.profileLocal;
    this.creditForm.get('email').patchValue(this.appService.getEmailId(profile));
    this.creditForm.get('phone').patchValue(this.appService.getMobileNumber(profile));
  }

  /**
   * Payload for add credits
   */
  private preparePayload() {

  }

  /**
   * Event handler on success of adding credits
   */
  // private onAddCreditSuccess(res) {
  //   this.resetForm();
  //   this.progress = false;
  //   this.notifications.showMessage('Credits added successfully.');
  // }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
