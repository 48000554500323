import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreadcrumbList } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'kl-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbsComponent implements OnInit {

  breadcrumb$: Observable<BreadcrumbList>;

  constructor(
    private breadcrumbService: BreadcrumbService
  ) {

    this.breadcrumb$ = this.breadcrumbService.breadcrumb$;
  }

  ngOnInit() { }

}
