import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiDestroyable, KalgudiFormValidators, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { BoardOfMember, KalgudiUser } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { OrganisationProfileService } from '../services/organisation-profile.service';

@Directive()
export abstract class KalgudiAddBoardMembers extends KalgudiDestroyable {

  @Input()
  hideActions = false;

  @Output()
  closeDialog = new EventEmitter();

  boardOfMembersForm: FormGroup;

  profileDetails: KalgudiUser;

  editable: boolean;
  progress: boolean;

  boardOfMembersList: BoardOfMember[] = [];
  profileMembers: BoardOfMember[];

  loggedInUserDetails: KalgudiUser;

  private fb: FormBuilder;
  private profileState: KalgudiProfileStateService;
  private profileService: KalgudiProfileService;
  private orgProfileService: OrganisationProfileService;
  protected util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private kalgudiAppService: KalgudiAppService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                = this.injector.get(FormBuilder);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.profileService    = this.injector.get(KalgudiProfileService);
    this.orgProfileService = this.injector.get(OrganisationProfileService);
    this.util              = this.injector.get(KalgudiUtilityService);
    this.notification      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);


    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
        this.profileMembers = this.profileDetails.boardOfMembers;
      });

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    this.loggedInUserDetails = this.kalgudiAppService.profileLocal;

    this.boardOfMembersForm = this.newBoardOfMembersForm;
  }

  /**
   * Calls api method to add and remove board of members
   */
  addBoardMembers() {

    this.notification.showSpinner();

    const payload = this.prepareAddMembersPayload(this.util.clone(this.boardOfMembersForm.value));

    this.orgProfileService.postBoardOfMembers(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() =>  this.notification.hideSpinner())
      )
      .subscribe(
        res => this.addSuccessHandler(res),
        err => this.addErrorHandler(err)
      )
  }

    /**
   * Calls api method to add and remove board of members
   */
  removeBoardMembers() {

    this.progress = true;

    const payload = this.profileMembers;

    if (this.profileMembers.length < this.profileDetails.boardOfMembers.length) {
      this.orgProfileService.postBoardOfMembers(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false),
      )
      .subscribe(
        res => this.removeSuccessHandler(res),
        err => this.removeErrorHandler(err)
      )
    } else {
      this.closeDialog.emit();
    }

  }

  onDestroyed(): void {}

  /**
   * Form group for board of members
   */
  private get newBoardOfMembersForm(): FormGroup {

    return this.fb.group({
      name: ['', Validators.required],
      designation: [''],
      mobile: ['', KalgudiFormValidators.indianMobileNumberValidators],
      // dateOfJoining: ['']
    });
  }

  /**
   * Prepares payload for adding board members
   */
  private prepareAddMembersPayload(formValue): BoardOfMember[] {

    this.boardOfMembersList.push(formValue);

    return this.boardOfMembersList;
  }

  /**
   * Add members success handler
   */
  private addSuccessHandler(res: KalgudiUser) {
    this.boardOfMembersForm.reset();
  }

  /**
   * Remove members success handler
   */
  private removeSuccessHandler(res: KalgudiUser) {
    this.closeDialog.emit();
  }

  /**
   * Add members error handler
   */
  private addErrorHandler(err: Error) {

    // If service fails removing the newly added item from the list
    this.boardOfMembersList = this.profileMembers;
    this.notification.showMessage('Unable to add members, please try again later!');
  }

  /**
   * Remove members error handler
   */
  private removeErrorHandler(err: Error) {
    this.notification.showMessage('Unable to remove members, please try again later!');
  }

  /**
   * Prepares payload for removing board members
   */
  prepareRemoveMembersPayload(index?: number) {

    this.boardOfMembersList.splice(index, 1);

    this.profileMembers = this.boardOfMembersList;

  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

}
