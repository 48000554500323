import { Injectable, OnDestroy } from '@angular/core';
import { PageFiltersService } from '@kalgudi/pages-shared';
import { IdValueMap, PageFilters, PageShareTargetAudienceRequest, StringStringMap } from '@kalgudi/types';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { ProgramStateService } from '../../../services/program-state.service';
import { PageShareFiltersApiService } from './page-share-filters-api.service';

@Injectable()
export class PageShareFiltersService implements OnDestroy {


  private rangeSubject = new BehaviorSubject<PageFilters>(null);
  private locationsSubject = new BehaviorSubject<StringStringMap>(null);

  private targetAudienceSubject = new BehaviorSubject<number>(0);

  private selectedCountrySubject = new BehaviorSubject<string>('');
  private selectedStateSubject = new BehaviorSubject<string>('');
  private selectedDistrictSubject = new BehaviorSubject<string>('');

  private destroyed$ = new Subject();

  constructor(
    private api: PageShareFiltersApiService,
    private pageState: ProgramStateService,
    private pageFilters: PageFiltersService,
  ) {

    this.pageState.data$
      .pipe(
        takeUntil(this.destroyed$),

        // Reset the selected filters
        tap(_ => this.resetSelectedFilters())
      )
      .subscribe(page => this.initTargetedMeta(page.pageId));
  }


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Program subscribers range metadata.
   */
  private get range$(): Observable<PageFilters> {
    return this.rangeSubject
      .pipe(
        filter(range => range !== null),
      );
  }

  /**
   * Program subscribers locations metadata.
   */
  private get locationsRange$(): Observable<StringStringMap> {

    return this.locationsSubject
      .pipe(
        filter(range => range !== null)
      );
  }

  /**
   * Gets, stream of products that the program members deals.
   */
  get products$(): Observable<IdValueMap[]> {

    return this.range$
      .pipe(

        // Convert hash map product structure to id value array
        map(range => this.transformHashMapToArray(range.products)),

        // Sorts the products list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets, stream of countries that the program member belongs.
   */
  get countries$(): Observable<IdValueMap[]> {

    return this.range$
      .pipe(

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.countries)),

        // Sorts the countries list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets, stream of states that the program member belongs.
   */
  get statesOrRegions$(): Observable<IdValueMap[]> {

    return this.selectedCountrySubject
      .pipe(
        // tap(r => console.log('changed state', this.selectedCountryId)),

        switchMap(countryId => this.range$),

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.statesOrRegions[this.selectedCountryId])),

        // Sorts the states list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets, stream of countries that the program member belongs.
   */
  get districts$(): Observable<IdValueMap[]> {

    return this.selectedStateSubject
      .pipe(

        switchMap(range => this.range$),

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.districts[this.selectedStateId])),

        // Sorts the districts list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets, stream of business types that the program member belongs.
   */
  get businessTypes$(): Observable<IdValueMap[]> {

    return this.range$
      .pipe(

        // Convert hash map business types structure to id value array
        map(range => this.transformHashMapToArray(range.businessTypes)),

        // Sorts the business types list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets, stream of business types that the program member belongs.
   */
  get locations$(): Observable<IdValueMap[]> {

    return this.locationsRange$
      .pipe(

        // Convert hash map business types structure to id value array
        map(range => this.transformHashMapToArray(range)),

        // Sorts the location list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Gets program targeting filter.
   */
  get memberTargetingFilters$(): Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }> {
    return combineLatest(
      this.products$,
      this.businessTypes$,
      this.countries$,
      this.statesOrRegions$,
      this.districts$,
      this.locations$,
    )
    .pipe(
      map(([products, businessTypes, countries, states, districts, locations]) => {
        return {
          products,
          businessTypes,
          countries,
          states,
          districts,
          locations
        };
      })
    );
  }

  /**
   * Gets targeted audience count
   */
  get targetAudience$(): Observable<number> {
    return this.targetAudienceSubject;
  }

  /**
   * Gets, the current selected country id.
   */
  private get selectedCountryId(): string {
    return this.selectedCountrySubject.getValue();
  }

  /**
   * Gets, the current selected state id.
   */
  private get selectedStateId(): string {
    return this.selectedStateSubject.getValue();
  }

  /**
   * Gets, the current selected district id
   */
  private get selectedDistrictId(): string {
    return this.selectedDistrictSubject.getValue();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Updates the selected country details. Based on the selected country
   * a state gets selected.
   */
  updateSelectedCountry(countryId: string): void {

    // Clean states list
    this.resetSelectedState();

    this.selectedCountrySubject.next(countryId);
  }

  /**
   * Updates the selected state details. Based on the selected country
   * a state gets selected.
   */
  updateSelectedState(stateId: string): void {
    // Clear district list
    this.resetSelectedDistrict();

    this.selectedStateSubject.next(stateId);

  }

  /**
   * Updates the selected district details.
   */
  updateSelectedDistrict(districtId: string): void {
    this.selectedDistrictSubject.next(districtId);
  }

  /**
   * Gets, the program targeted audience count for a share post with the
   * selected filters.
   *
   * @param payload Selected filters for the program targeted share
   */
  fetchSocialPostTargetAudienceCount(payload: PageShareTargetAudienceRequest): Observable<number> {

    return this.api.fetchSocialPostTargetAudienceCount(payload);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Loads subscribers range data. Program range data contains set of all
   * unique products, locations, and business types that the program
   * members belongs to.
   *
   * Program subscribers metadata is must to do any social activity targeted
   * to any specific members.
   */
  private initTargetedMeta(pageId: string): void {

    // Combine latest stream item from individual streams
    combineLatest(
      this.fetchPageFilters(pageId),
      this.fetchSubscribersLocations(pageId),
      // this.fetchShareTargetedAudienceCount(null)
    )
    .pipe(

      // Take only first result, no need of subscription after getting
      // API response
      take(1),
    )
    .subscribe(([pageFilters, locationsFilters]) => this.pageFilters.initPageFilters(pageFilters, locationsFilters));
  }

  /**
   * Loads subscribers range data. Program range data contains set of all
   * unique products, locations, and business types that the program
   * members belongs to.
   *
   * Program subscribers metadata is must to do any social activity targeted
   * to any specific members.
   */
  private fetchPageFilters(pageId: string): Observable<PageFilters> {

    return this.fetchProgramSubscribersMeta(pageId)
      .pipe(

        // Assign range data to global variable
        tap(r => this.rangeSubject.next(r)),
      );
  }

  /**
   * Loads, program subscribers locations. Fetches a list of locations
   * that program subscribers belongs.
   */
  private fetchSubscribersLocations(pageId: string): Observable<StringStringMap> {

    return this.fetchProgramSubscribersLocations(pageId)
      .pipe(

        // Emit locations list to the locations data stream
        tap(r => this.locationsSubject.next(r))
      );
  }

  /**
   * Fetches program share range details.
   *
   * @param pageId Program/page/entity id
   */
  private fetchProgramSubscribersMeta(pageId: string): Observable<PageFilters> {
    return this.api.fetchPageFilters(pageId);
  }

  /**
   * Gets, a list of location that the program subscribers belongs.
   *
   * @param pageId Program/page/entity id
   */
  private fetchProgramSubscribersLocations(pageId: string): Observable<StringStringMap> {

    return this.api.fetchProgramSubscribersLocations(pageId);
  }

  /**
   * Sorts the program filter list
   * @param list List to sort
   */
  private sortFilterList(list: IdValueMap[]): IdValueMap[] {

    return list.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  /**
   * Transforms hash map of id and value structure to id value type array.
   *
   * @param hashMap Hash map
   */
  private transformHashMapToArray(hashMap: StringStringMap): IdValueMap[] {

    // Final mapped items
    const mappedItems: IdValueMap[] = [];

    // Null checks, return empty array immediately on null values
    if (!hashMap) {
      return mappedItems;
    }

    // Extract all keys from the hash map
    const ids = Object.keys(hashMap);

    // For each id map its value to a new type
    ids.forEach(id => mappedItems.push({
        id,
        value: hashMap[id]
      })
    );

    // Finally return the mapped list
    return mappedItems;
  }

  /**
   * Resets the selected filters
   */
  private resetSelectedFilters(): void {
    this.resetSelectedCountry();
    this.resetSelectedState();
    this.resetSelectedDistrict();
  }

  /**
   * Resets selected country
   */
  private resetSelectedCountry(): void {
    this.selectedCountrySubject.next('');
  }

  /**
   * Resets selected state
   */
  private resetSelectedState(): void {
    this.selectedStateSubject.next('');
  }

  /**
   * Resets selected district
   */
  private resetSelectedDistrict(): void {
    this.selectedDistrictSubject.next('');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
