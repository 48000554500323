<main id="business-activities">
  <form [formGroup]="businessActivitiesForm" class="business-form overflow-auto">
    <div class="mb-2">
      <!-- Mat-group for business activity's tab -->
      <mat-tab-group>
        <!-- Input business activity tab -->
        <mat-tab [label]="'Input' | translate">
          <!-- Input business -->
          <div fxLayout="column" class="px-3" formGroupName="input">
            <p class="mb-0 mt-2 font-weight-bold headers" translate>Input Business</p>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Input business year</mat-label>
                  <mat-select [placeholder]="'Input business year' | translate" formControlName="businessYear">
                    <mat-option *ngFor="let years of editInputYear" [value]="years?.id">{{ years?.id }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Revenue(₹)</mat-label>
                  <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" pattern="^[0-9,]+$" [readonly]="!getBusinessYear?.value" formControlName="businessRevenue" maxlength="12" (keypress)="onKeyPress($event)" [value]="inputBusinessActivityRevenue"/>
                </mat-form-field>
                <mat-error class="mt-n2" *ngIf="getBusinessRevenue?.hasError('pattern')" translate>Enter a valid revenue</mat-error>
              </div>

              <div fxFlex="calc(20% - 10px)" [fxFlex.xs]="getInputShop?.value ? 'calc(46% - 10px)' : 'calc(30% - 10px)'">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Input shop</mat-label>
                  <mat-select [placeholder]="'Input shop' | translate" formControlName="inputShop" [disabled]="!getBusinessYear?.value"
                    (selectionChange)="changeIsSelected($event, 'InputShop')">
                    <mat-option *ngFor="let option of inputShop" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(34% - 10px)" *ngIf="isShowQuantity">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>No.of shops</mat-label>
                  <input matInput class="no-arrows" [placeholder]="'No.of shops' | translate" formControlName="quantity" maxlength="6" (keypress)="onKeyPress($event)"/>
                </mat-form-field>
              </div>

              <div class="add-button mb-2" fxLayout="row" fxLayoutAlign="end center">
                <button mat-fab [disabled]="!((getBusinessYear?.value && getBusinessRevenue?.value) && (getInputShop?.value || getQuantity?.value))" (click)="addInputDetails()">
                  <mat-icon class="text-white add-icon">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!--/ Input business -->

          <!-- Input business details -->
          <div class="input-table-sections business-table-view rounded mx-3" *ngIf="inputDetails && inputDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Year</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>No.of shops</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let input of inputDetails; let i= index;">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break">{{ input?.businessYear }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(input?.businessRevenue) }}</div>
                  <div class="table-cell text-break">{{ input?.quantity || '-'}}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editInputActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeInputActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Input business details -->
        </mat-tab>
        <!--/ Input business activity tab -->

        <!-- Output business activity tab -->
        <mat-tab [label]="'Output' | translate">
          <!-- Output business details -->
          <div fxLayout="column" class="px-3" formGroupName="output">
            <p class="mb-0 mt-2 font-weight-bold headers" translate>Output business</p>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Output business year</mat-label>
                  <mat-select placeholder="Output business year" formControlName="businessYear">
                    <mat-option *ngFor="let years of editOutputYear" [value]="years?.id">{{ years?.id }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Revenue(₹)</mat-label>
                  <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" [readonly]="!outputBusinessYear?.value" formControlName="businessRevenue" maxlength="12" pattern="^[0-9,]+$" (keypress)="onKeyPress($event)" [value]="outputBusinessActivityRevenue"/>
                </mat-form-field>
                <mat-error class="mt-n2" *ngIf="outputBusinessRevenue?.hasError('pattern')" translate>Enter a valid revenue </mat-error>
              </div>

              <!-- <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(46% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Top 3 buyers</mat-label>
                  <input matInput placeholder="buyer1, buyer2, buyer3" formControlName="topThreeBuyers" maxlength="50"/>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(34% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>% of business</mat-label>
                  <input matInput placeholder="Enter % of business" formControlName="percentageOfOutput" (keypress)="onKeyPress($event, 'area')" maxlength="6"/>
                </mat-form-field>
                <mat-error *ngIf="outputsGroup.get('percentageOfOutput')?.hasError('max')" class="mt-n2 error-message">
                  Should be less than 100
                </mat-error>
              </div> -->

              <div class="add-button mb-2" fxLayout="row" fxLayoutAlign="end center">
                <button mat-fab [disabled]="!(outputBusinessYear?.value && outputBusinessRevenue?.value)" (click)="addOutputDetails()">
                  <mat-icon class="text-white add-icon">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!--/ Output business -->

          <!-- Output business details -->
          <div class="output-table-sections business-table-view rounded mx-3" *ngIf="outputDetails && outputDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Year</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <!-- <div class="table-cell" translate>Output top 3 buyers</div>
                <div class="table-cell" translate>% of output business</div> -->
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let output of outputDetails; let i= index;">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break">{{ output?.businessYear }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(output?.businessRevenue) }}</div>
                  <!-- <div class="table-cell text-break text-capitalize">{{ (output?.topThreeBuyers) || '-' }}</div>
                  <div class="table-cell text-break">{{ (output?.percentageOfOutput/100 | percent) || '-' }}</div> -->
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editOutputActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeOutputActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Output business details -->
        </mat-tab>
        <!--/ Output business activity tab -->

        <!-- CHC business activity tab -->
        <mat-tab [label]="'CHC' | translate">
          <!-- CHC business -->
          <div fxLayout="column" class="px-3" formGroupName="chc">
            <p class="mb-0 mt-2 font-weight-bold headers" translate>Custom Hiring Center</p>
            <div fxLayout="row wrap" fxLayoutGap="10px">

              <div fxFlex="calc(20% - 10px)" [fxFlex.xs]="chcShopField?.value ? 'calc(50% - 10px)' : 'calc(30% - 10px)'">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>CHC shop</mat-label>
                  <mat-select [placeholder]="'CHC shop' | translate" formControlName="chcShop"
                    (selectionChange)="changeIsSelected($event, 'CHCShop')">
                    <mat-option *ngFor="let option of inputShop" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(50% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Business year</mat-label>
                  <mat-select [placeholder]="'Enter business year' | translate" formControlName="businessYear" [disabled]="!isShowChcShop">
                    <mat-option *ngFor="let years of editCHCYear" [value]="years?.id">{{ years?.id }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div  *ngIf="isShowChcShop" fxLayoutGap="10px" fxFlex="calc(40% - 10px)">
                <div fxFlex="calc(50% - 10px)" fxFlex.xs="calc(43% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Revenue(₹)</mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" [readonly]="!chcBusinessYear?.value"
                      formControlName="businessRevenue" maxlength="12" pattern="^[0-9,]+$" (keypress)="onKeyPress($event)" [value]="chcRevenueValue" />
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="chcBusinessRevenue?.hasError('pattern')" translate>Enter a valid revenue </mat-error>
                </div>
                <div fxFlex="calc(50% - 10px)" fxFlex.xs="calc(57% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>No.of shops</mat-label>
                    <input class="no-arrows" matInput [placeholder]="'No.of shops' | translate" formControlName="quantity" (keypress)="onKeyPress($event)" maxlength="6"/>
                  </mat-form-field>
                </div>
              </div>

              <div class="add-button mb-3" fxLayout="row" fxLayoutAlign="end center">
                <button mat-fab [disabled]="!(chcBusinessYear?.value && chcBusinessRevenue?.value && chcShopField?.value && chcQuantity?.value)" (click)="addChcDetails()">
                  <mat-icon class="text-white add-icon">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!--/ CHC business -->

          <!-- CHC business details -->
          <div class="chc-table-sections business-table-view rounded mx-3" *ngIf="chcDetails && chcDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>CHC business year</div>
                <div class="table-cell" translate>No.of shops</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let chc of chcDetails; let i= index;">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break">{{ chc?.businessYear }}</div>
                  <div class="table-cell text-break">{{ chc?.quantity || '-' }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(chc?.businessRevenue?.toLocaleString('en-IN')) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editChcActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeChcActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ CHC business details -->
        </mat-tab>
        <!--/ CHC business activity tab -->

        <!-- Lending business activity tab -->
        <mat-tab [label]="'Lending' | translate">
          <!-- Lending business -->
          <div fxLayout="column" class="px-3" formGroupName="lending">
            <p class="mb-0 mt-2 font-weight-bold headers" translate>Lending</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(40% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Lending year</mat-label>
                  <mat-select placeholder="Lending year" formControlName="year">
                    <mat-option *ngFor="let years of editLendingYear" [value]="years?.id">{{ years?.id }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(40% - 10px)">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>How much was the lending <span>(₹)</span></mat-label>
                  <input matInput class="no-arrows" [placeholder]="'How much was the lending(₹)' | translate" [readonly]="!lendingYear?.value" formControlName="lendingAmount" maxlength="12" (keypress)="onKeyPress($event)" [value]="lendingAmountValue"/>
                </mat-form-field>
              </div>

              <div class="add-button mb-3" fxLayout="row" fxLayoutAlign="end center">
                <button mat-fab [disabled]="!(lendingYear?.value && lendingAmounts?.value)" (click)="addLendingDetails()">
                  <mat-icon class="text-white add-icon">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- Lending business -->

          <!-- Lending business details -->
          <div class="lending-table-sections business-table-view rounded mx-3" *ngIf="lendingDetails && lendingDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Lending year</div>
                <div class="table-cell" translate>How much was the lending <span>(₹)</span></div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let lending of lendingDetails; let i= index;">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break">{{ lending?.year }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(lending?.lendingAmount) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editLendingActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeLendingActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Lending business details -->
        </mat-tab>
        <!--/ Lending business activity tab -->
      </mat-tab-group>
      <!--/ Mat group for business activity's tab -->
    </div>

    <div class="products-commodities mt-4">
      <!-- Mat-group for product's and commodities -->
      <mat-tab-group>
        <!-- Consumer ready tab -->
        <mat-tab [label]="'Consumer' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="consumer">
            <p class="mb-1 font-weight-bold headers" translate>Consumer ready commodity</p>
            <div fxLayout fxLayoutGap="10px">
              <div class="is-selected" fxFlex="calc(26% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Consumer ready commodity</mat-label>
                  <mat-select [placeholder]="'Consumer commodity' | translate" formControlName="showConsumer"
                    (selectionChange)="changeIsSelected($event, 'Consumer')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74% - 10px)" fxLayout fxLayoutGap="10px" *ngIf="showConsumer?.value === 'YES'">

                <div fxFlex="calc(22% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Commodity name</mat-label>
                    <input matInput [placeholder]="'Enter commodity name' | translate" formControlName="consumerProductCommodity" maxlength="40" pattern="^[a-zA-Z][a-zA-Z0-9\s_]*$"/>
                  </mat-form-field>
                  <mat-error *ngIf="consumerProductsCommodity.invalid" translate>Enter a valid commodity name!</mat-error>
                </div>

                <div fxFlex="calc(22% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Revenue(₹)</mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" formControlName="consumerProductRevenue" pattern="^[0-9,]+$" maxlength="12" (keypress)="onKeyPress($event)" [value]="inputConsumerProductsRevenue"/>
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="consumerProductsRevenue?.hasError('pattern')" translate>Enter a valid revenue</mat-error>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="start">
                  <button mat-fab [disabled]="!(consumerProductsCommodity?.value && consumerProductsRevenue?.value && !consumerProductsCommodity.invalid)" (click)="addConsumer()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>

            </div>
          </div>

          <!-- Consumer details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="consumerDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Commodity</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let consumer of consumerDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ consumer?.consumerProductCommodity }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(consumer?.consumerProductRevenue?.toLocaleString('en-IN')) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editConsumer(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeConsumer(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Consumer details -->
        </mat-tab>
        <!--/ Consumer ready tab -->

        <!-- Certified tab -->
        <mat-tab [label]="'Certified' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="certified">
            <p class="mb-1 font-weight-bold headers" translate>Certified seed production</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(26% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Certified seed production</mat-label>
                  <mat-select placeholder="Consumer commodity" formControlName="showCertified"
                    (selectionChange)="changeIsSelected($event, 'Certified')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74%-10px)" fxLayout fxLayoutGap="10px" *ngIf="showCertified?.value === 'YES'">
                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Crop name</mat-label>
                    <input matInput [placeholder]="'Enter crop name' | translate" formControlName="certifiedProductionCrop" maxlength="40" pattern="^[a-zA-Z][a-zA-Z0-9\s_]*$"/>
                  </mat-form-field>
                  <mat-error *ngIf="certifiedProductionsCrop.invalid">Enter a valid name!</mat-error>
                </div>

                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Revenue(₹)</mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" formControlName="certifiedProductionRevenue" pattern="^[0-9,]+$" maxlength="12" (keypress)="onKeyPress($event)" [value]="inputCertifiedProductionRevenue"/>
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="certifiedProductionRevenue?.hasError('pattern')" translate>Enter a valid revenue </mat-error>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="start">
                  <button mat-fab [disabled]="!(certifiedProductionsCrop?.value && certifiedProductionRevenue?.value && !certifiedProductionsCrop.invalid)" (click)="addCertified()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Certified details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="certifiedDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Crop</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let certified of certifiedDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ certified?.certifiedProductionCrop }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(certified?.certifiedProductionRevenue?.toLocaleString('en-IN')) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editCertified(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeCertified(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Certified details -->
        </mat-tab>
        <!--/ Certified tab -->

        <!-- Warehouse tab -->
        <mat-tab [label]="'Warehouse' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="warehouse">
            <p class="mb-1 font-weight-bold headers" translate>Warehouse commodity</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(26% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Warehouse commodity</mat-label>
                  <mat-select [placeholder]="'Warehouse commodity' | translate" formControlName="showWarehouse"
                    (selectionChange)="changeIsSelected($event, 'Warehouse')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74%-10px)" fxLayout fxLayoutGap="10px" *ngIf="showWarehouse?.value === 'YES'">
                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Commodity name</mat-label>
                    <input matInput [placeholder]="'Enter commodity name' | translate" formControlName="commodityStoredInWarehouse" maxlength="40" pattern="^[a-zA-Z][a-zA-Z0-9\s_]*$"/>
                  </mat-form-field>
                  <mat-error *ngIf="commodityStoredInWarehouses.invalid" translate>Enter a valid name!</mat-error>
                </div>

                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Capacity(in tons)</mat-label>
                    <input matInput [placeholder]="'Enter capacity(in tons)' | translate" formControlName="capacityInWarehouse" maxlength="9" class="no-arrows" (keypress)="onKeyPress($event, 'area')"/>
                  </mat-form-field>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="start">
                  <button mat-fab [disabled]="!(commodityStoredInWarehouses?.value && capacityInWarehouses?.value && !commodityStoredInWarehouses.invalid)" (click)="addWarehouse()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Warehouse details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="warehouseDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Commodity stored</div>
                <div class="table-cell" translate>Capacity(in tons)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let wareHouse of warehouseDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ wareHouse?.commodityStoredInWarehouse }}</div>
                  <div class="table-cell text-break">{{ wareHouse?.capacityInWarehouse }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editWarehouse(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeWarehouse(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Warehouse details -->
        </mat-tab>
        <!--/ Warehouse tab -->

        <!-- Cold storage tab -->
        <mat-tab [label]="'Cold Storage' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="coldStorage">
            <p class="mb-1 font-weight-bold headers" translate>Cold storage commodity</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(26% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate> Cold storage commodity</mat-label>
                  <mat-select placeholder="Cold storage commodity" formControlName="showColdStorage"
                    (selectionChange)="changeIsSelected($event, 'ColdStorage')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74%-10px)" fxLayout fxLayoutGap="10px" *ngIf="showColdStorage?.value === 'YES'">
                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Commodity name</mat-label>
                    <input matInput [placeholder]="'Enter commodity name' | translate" formControlName="commodityStoredInColdStorage" maxlength="40" pattern="^[a-zA-Z][a-zA-Z0-9\s_]*$"/>
                  </mat-form-field>
                  <mat-error *ngIf="commodityStoredInColdStorages.invalid">Enter a valid name!</mat-error>
                </div>

                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Capacity(in tons)</mat-label>
                    <input matInput [placeholder]="'Enter capacity(in tons)' | translate" formControlName="capacityInColdStorage" maxlength="9" class="no-arrows" (keypress)="onKeyPress($event, 'area')"/>
                  </mat-form-field>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="start">
                  <button mat-fab [disabled]="!(commodityStoredInColdStorages?.value && capacityInColdStorages?.value && !commodityStoredInColdStorages.invalid)" (click)="addColdStorage()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Cold storage details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="coldStorageDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Commodity stored</div>
                <div class="table-cell" translate>Capacity(in tons)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let coldStorage of coldStorageDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ coldStorage?.commodityStoredInColdStorage }}</div>
                  <div class="table-cell text-break">{{ coldStorage?.capacityInColdStorage }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editColdStorage(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeColdStorage(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Cold storage details -->
        </mat-tab>
        <!--/ Cold storage tab -->

        <!-- Exports/imports tab -->
        <mat-tab [label]="'Exports/Imports' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="importsOrExports">
            <p class="mb-1 font-weight-bold headers" translate>Exports/Imports commodity</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(26% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Exports/Imports commodity</mat-label>
                  <mat-select placeholder="Exports/Imports commodity" formControlName="showImportsOrExports"
                    (selectionChange)="changeIsSelected($event, 'ExportImport')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74%-10px)" fxLayout fxLayoutGap="10px" *ngIf="showImportsOrExports?.value === 'YES'">
                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Commodity name</mat-label>
                    <input matInput [placeholder]="'Enter commodity name' | translate" formControlName="exportsOrImportsCommodity" pattern="^[a-zA-Z][a-zA-Z0-9\s_]*$" maxlength="40"/>
                  </mat-form-field>
                  <mat-error *ngIf="exportOrImportCommodity.invalid" translate>Enter a valid name!</mat-error>
                </div>

                <div fxFlex="calc(26% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Revenue(₹)</mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" formControlName="exportsOrImportsRevenue" pattern="^[0-9,]+$" maxlength="12" (keypress)="onKeyPress($event)" [value]="inputExportsOrImportsRevenue"/>
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="exportOrImportRevenue?.hasError('pattern')" translate>Enter a valid revenue</mat-error>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="start">
                  <button mat-fab [disabled]="!(exportOrImportCommodity?.value && exportOrImportRevenue?.value && !exportOrImportCommodity.invalid)" (click)="addExportsImports()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Exports and imports details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="exportsImportsDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Commodity</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let exportsOrImports of exportsImportsDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ exportsOrImports?.exportsOrImportsCommodity }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(exportsOrImports?.exportsOrImportsRevenue) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editExportsAndImports(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeImportsExports(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Exports and imports details -->
        </mat-tab>
        <!--/ Exports/imports tab -->

        <!-- Allied tab -->
        <mat-tab [label]="'Allied' | translate">
          <div fxLayout="column" class="px-3 mt-1" formGroupName="allied">
            <p class="mb-1 font-weight-bold headers" translate>Allied commodity</p>
            <div fxLayout fxLayoutGap="10px">
              <div fxFlex="calc(20% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Allied commodity</mat-label>
                  <mat-select placeholder="Allied commodity" formControlName="showAllied"
                    (selectionChange)="changeIsSelected($event, 'Allied')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(74%-10px)" fxLayout fxLayoutGap="10px" *ngIf="showAllied?.value === 'YES'">
                <div [fxFlex]="alliedActivitive?.value === 'OTHERS' ? 'calc(18% - 10px)' : 'calc(26% - 10px)'">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Allied activities</mat-label>
                    <mat-select [placeholder]="'Allied activities' | translate" formControlName="alliedActivities">
                      <mat-option *ngFor="let option of alliedActivities" [value]="option?.id">
                        {{ option?.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div *ngIf="alliedActivitive?.value === 'OTHERS'" fxFlex="calc(18% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Other allied details</mat-label>
                    <input matInput formControlName="alliedOthers" type="text" maxlength="50" [placeholder]="'Enter other allied details' | translate">
                  </mat-form-field>
                </div>

                <div [fxFlex]="alliedActivitive?.value === 'OTHERS' ? 'calc(18% - 10px)' : 'calc(26% - 10px)'">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Turnover <span>(₹)</span></mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter turnover(₹)' | translate" formControlName="alliedActivitiesRevenue" pattern="^[0-9,]+$" maxlength="7" (keypress)="onKeyPress($event)" [value]="inputAlliedActivitiesRevenue"/>
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="alliedActivitiesRevenues?.hasError('pattern')" translate>Enter a valid turnover</mat-error>
                </div>

                <div class="add-button mb-1" fxLayout="row" fxLayoutAlign="end center">
                  <button mat-fab [disabled]="!(alliedActivitive?.value && alliedActivitiesRevenues?.value)" (click)="addAllied()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Allied details -->
          <div class="commodity-table-sections business-table-view rounded mx-3" *ngIf="alliedDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Activities</div>
                <div class="table-cell" translate>Turnover <span>(₹)</span></div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let allied of alliedDetails; let i= index">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break text-capitalize">{{ allied?.alliedActivities !== "OTHERS" ? allied?.alliedActivities : allied?.alliedOthers }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(allied?.alliedActivitiesRevenue) }}
                  </div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button"
                      (click)="editAlliedActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons"
                      (click)="removeAlliedActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Allied details -->
        </mat-tab>
        <!--/ Allied tab -->

        <!-- Other business activity tab -->
        <mat-tab [label]="'Other' | translate">
          <!-- Other business -->
          <div fxLayout="column" class="px-3" formGroupName="others">
            <p class="mb-0 mt-2 font-weight-bold headers" translate>Other business</p>
            <div fxLayout="row wrap" fxLayoutGap="10px">
              <div fxFlex="calc(20% - 10px)" fxFlex.xs="calc(100% - 10px)" class="close-tab">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label translate>Other business activities</mat-label>
                  <mat-select placeholder="Other business activities" formControlName="otherBusiness"
                    (selectionChange)="changeIsSelected($event, 'OtherShop')">
                    <mat-option *ngFor="let option of isSelected" [value]="option?.id">{{ option?.value }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="otherBusinessShow?.value === 'YES'" fxLayout="row wrap" fxFlex="calc(80%-10px)" fxFlex.xs="calc(80%-10px)" fxLayoutGap="10px">
                <div fxFlex="calc(30% - 10px)" fxFlex.xs="calc(40% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Other business activity year</mat-label>
                    <mat-select placeholder="Other business activity year" formControlName="businessYear">
                      <mat-option *ngFor="let years of editOtherYear" [value]="years?.id">{{ years?.id }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="calc(30% - 10px)" fxFlex.xs="calc(57% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Other business activities</mat-label>
                    <input matInput [placeholder]="'Enter activities' | translate" formControlName="activities" maxlength="50"/>
                  </mat-form-field>
                </div>

                <div fxFlex="calc(30% - 10px)" fxFlex.xs="calc(43% - 10px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Revenue(₹)</mat-label>
                    <input matInput class="no-arrows" [placeholder]="'Enter revenue(₹)' | translate" [readonly]="!otherBusinessYear?.value" formControlName="businessRevenue" pattern="^[0-9,]+$" maxlength="12" (keypress)="onKeyPress($event)" [value]="otherBusinessActivityRevenue"/>
                  </mat-form-field>
                  <mat-error class="mt-n2" *ngIf="otherBusinessRevenue?.hasError('pattern')">Enter a valid revenue </mat-error>
                </div>

                <div class="add-button mb-2" fxLayout="row" fxLayoutAlign="end center">
                  <button mat-fab [disabled]="!(otherBusinessYear?.value && otherBusinessRevenue?.value && otherBusinessShow?.value && otherActivities?.value)" (click)="addOtherDetails()">
                    <mat-icon class="text-white add-icon">add</mat-icon>
                  </button>
                </div>
              </div>


            </div>
          </div>
          <!--/ Other business -->

          <!-- Other business details -->
          <div class="other-table-sections business-table-view rounded mx-3" *ngIf="otherDetails && otherDetails?.length">
            <div class="table">
              <!-- Table header values -->
              <div class="table-header text-center text-white p-1">
                <div class="table-cell" translate>S.no</div>
                <div class="table-cell" translate>Year</div>
                <div class="table-cell" translate>Activity</div>
                <div class="table-cell" translate>Revenue(₹)</div>
                <div class="table-cell" translate>Actions</div>
              </div>
              <!--/ Table header values -->

              <!-- Table body values -->
              <div class="table-body text-center">
                <div class="table-row bg-white" *ngFor="let other of otherDetails; let i= index;">
                  <div class="table-cell text-break">{{ i+1 }}</div>
                  <div class="table-cell text-break">{{ other?.businessYear }}</div>
                  <div class="table-cell text-break text-capitalize">{{ other?.activities || '-' }}</div>
                  <div class="table-cell text-break">{{ convertIndianRupeeFormat(other?.businessRevenue?.toLocaleString('en-IN')) }}</div>
                  <div class="actions table-cell">
                    <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editOtherActivities(i)">
                      <mat-icon class="edit-icon">edit</mat-icon>
                    </button>
                    <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeOtherActivities(i)">
                      <mat-icon class="delete-icon">clear</mat-icon>
                    </button>
                  </div>
                </div>
              </div>
              <!--/ Table body values -->
            </div>
          </div>
          <!--/ Other business details -->
        </mat-tab>
        <!-- Other business activity tab -->
      </mat-tab-group>
      <!--/ Mat-group for product's and commodities -->
    </div>
  </form>

  <!-- Save button -->
  <!-- <div fxLayout fxLayoutAlign="end end" class="p-3">
    <kl-flat-button
      label="Save"
      [progress]="isProgress"
      [disabled]="(isSaveEnable && !isEditDialog) || businessActivitiesForm.invalid || outputsGroup.get('percentageOfOutput')?.hasError('max')"
      (clicked)="addBasicInfoDetails()">
    </kl-flat-button>
  </div> -->
  <!--/ Save button -->
</main>
