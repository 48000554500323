import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class KalgudiSpinnerService {

  private blockingSpinner = new BehaviorSubject<boolean>(false);
  private routeLoadingProgress = new BehaviorSubject<boolean>(false);

  constructor() { }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the current status of spinner.
   */
  get spinner$(): Observable<boolean> {
    return this.blockingSpinner;
  }

  /**
   * Gets, the current status of route navigation status. Stream
   * contains `true` if the route is currently in navigation phase,
   * otherwise `false`.
   */
  get routeLoadingProgress$(): Observable<boolean> {
    return this.routeLoadingProgress;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Toggles the current status of spinner
   */
  toggleSpinner(val: boolean): void {
    this.blockingSpinner.next(val);
  }

  /**
   * Toggles the current status of route navigation progress.
   */
  toggleRouteLoadingProgress(val: boolean): void {
    this.routeLoadingProgress.next(val);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
