import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';

@Component({
  selector: 'kl-satellite-advisories-phosphorus-level-tile',
  templateUrl: './satellite-advisories-phosphorus-level-tile.component.html',
  styleUrls: ['./satellite-advisories-phosphorus-level-tile.component.scss'],
  animations: [floatTopAnimation]
})
export class SatelliteAdvisoriesPhosphorusLevelTileComponent implements OnInit {

  @ViewChild('satImg', { static: true })
  satImgRef: ElementRef;

  @Input()
  animationDelay = 0;

  @Input()
  estimatedTime: string;

  @Input()
  phosphorusImage: string;

  @Input()
  phosphorusDetails: any;

  @Input()
  landDetails: any;

  @Input()
  bbox: number[] = [];

  boundaryPoints: string = '';

  showMap: boolean = false;

  phosphorusPerHectare: number;
  phosphorusPerAcre: number;
  phosphorusPerFeddan: number;

  status: 'low' | 'good' | 'more';

  constructor() {}

  ngOnInit() {
  }

}
