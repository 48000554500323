import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfileBasicDetailsEdit } from '../../classes/kalgudi-profile-basic-details-edit';

@Component({
  selector: 'kl-profile-basic-details-edit',
  templateUrl: './profile-basic-details-edit.component.html',
  styleUrls: ['./profile-basic-details-edit.component.scss']
})
export class ProfileBasicDetailsEditComponent extends KalgudiProfileBasicDetailsEdit implements OnInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  termsAndConditions = new FormControl('');
  appId: string;
  showAgeError: boolean = false;

  constructor(
    protected injector: Injector,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    protected fb: FormBuilder,
  ) {
    super(injector, fb);
    this.appId = this.env.appId;

    this.basicDetailsDoBField.valueChanges
      .pipe(
        takeUntil(
          this.destroyed$
        )
      )
      .subscribe(res => {
        if (res && res._d) {
          this.calculateAge(res._d)
        }
      })
  }

  ngOnInit() {

    this.activeTabIndex = 0;
    this.updateDateLocale();

    this.getCurrentLocation();

  }

  onDestroyed(): void { }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  /**
   * Calculate the age below 18
   * @param dob
   */
  calculateAge(dob: any) {

    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);

    if (age <= 18) {
      this.showAgeError = !this.showAgeError;
    } else {
      this.showAgeError = false;
    }

  }

  /**
  * Not Allowing characters and symbols
  * @param event
  * @returns
  */
  onKeyPress(event: KeyboardEvent, activity?: string): boolean {
    const x = event.which || event.keyCode;

    if (activity === 'title') {
      return this.isTitleKey(x);
    } else {
      return this.isDefaultKey(x);
    }
  }

  private isTitleKey(x: number): boolean {
    return (x >= 65 && x <= 90) || (x >= 97 && x <= 122) || x === 32;
  }

  private isDefaultKey(x: number): boolean {
    return (x >= 48 && x <= 57) || x === 46;
  }
}
