import { Inject, Input, OnDestroy, Directive } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiUtilityService } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';
import { PageType, SelectableUIMenu } from '@kalgudi/types';
import { Observable, of, Subject } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PAGE_CREATION_STATES_MAP, PAGE_TYPES, PageCreationStateDataMap, PageCreationStates } from '../models';
import { CreatePageStateService } from '../services/create-page-state.service';
import { KalgudiCreatePageService } from '../services/kalgudi-create-page.service';

/**
 * Base class definition for all Kalgudi program types.
 */
@Directive()
export abstract class KalgudiCreatePage implements OnDestroy {

  @Input()
  defaultSelectedPageType: PageType;

  availablePageTypes = PAGE_TYPES;

  pageTypes: SelectableUIMenu[];

  progress: boolean;

  // readonly pageTypes: SelectableUIMenu[] = [
  //   {
  //     desc: 'Program helps you engageinitial a community of professionals to drive actions that are relevant to your business.',
  //     svgIcon: 'assets/svgs/program-filled.svg',
  //     title: 'Program',
  //     id: PAGE_TYPES.PROGRAM,
  //   },
  //   {
  //     desc: 'Product stock management',
  //     svgIcon: 'assets/svgs/stocks-filled.svg',
  //     title: 'Stock',
  //     id: PAGE_TYPES.STOCK,
  //     hide: this.environment.appId === 'SHAKTIMAN',
  //   },
  //   {
  //     desc: 'Manage your product processing',
  //     svgIcon: 'assets/svgs/processing.svg',
  //     title: 'Processing',
  //     id: PAGE_TYPES.PROCESSING,
  //     hide: this.environment.appId === 'SHAKTIMAN',
  //   },
  //   {
  //     desc: 'This organisational structure promotes internal competition.',
  //     svgIcon: 'assets/svgs/organisation-filled.svg',
  //     title: 'Organization',
  //     id: PAGE_TYPES.ORGANIZATION,
  //   },
  //   // {
  //   //   desc: 'Visitors get as much information as possible upon visiting a page',
  //   //   svgIcon: 'assets/svgs/university-filled.svg',
  //   //   title: 'University',
  //   //   id: PAGE_TYPES.UNIVERSITY
  //   // },
  //   {
  //     desc: 'Company may be a corporation, partnership, association, joint-stock company, trust, fund, or organized group of persons',
  //     svgIcon: 'assets/svgs/company-filled.svg',
  //     title: 'Company',
  //     id: PAGE_TYPES.COMPANY,
  //   },
  //   {
  //     desc: 'Farmers are often members of local, regional, or national farmers unions or agricultural producers organizations',
  //     svgIcon: 'assets/svgs/farmer-filled.svg',
  //     title: 'Farmer',
  //     id: PAGE_TYPES.FARMER,
  //   },
  //   {
  //     desc: ' Create your sales teams page',
  //     svgIcon: 'assets/svgs/seller.svg',
  //     title: 'Sales teams',
  //     id: PAGE_TYPES.SALES_TEAMS,
  //   },
  // ];


  /**
   * Page form will have both the common fields and the specific page type fields
   */
  pageForm: FormGroup;

  pageId: string;

  availableStates = PAGE_CREATION_STATES_MAP;

  disableCreateButton: boolean;

  /**
   * common page form
   */
  commonPageForm: FormGroup;
  pageType: PageType;
  detailedPageForm: FormGroup;

  currentPageData: any;

  /**
   * Contains stream of all page creation stages data
   */
  creationStateData$: Observable<PageCreationStateDataMap>;

  /**
   * Contains current page creation state
   */
  creationState$: Observable<PageCreationStates>;

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  private destroyed = new Subject<any>();

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected formBuilder: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
    protected pageStateService: CreatePageStateService,
    protected util: KalgudiUtilityService,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig
  ) {

    this.creationStateData$ = this.pageStateService.creationStateData$;

    this.creationState$ = this.pageStateService.creationState$;


    /**
     * Tap `createPage()` if the state changes to the create page.
     * This action should happen automatically.
     */
    this.pageStateService.creationState$
      .pipe(

        // Filter only `CREATE_PAGE` events
        filter(state => state === 'CREATE_PAGE'),

        takeUntil(this.destroyed$),

        tap(_ => this.createPage())
      ).subscribe();

    this.commonPageForm = new FormGroup({});
    this.detailedPageForm = new FormGroup({});
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }

  /**
   * Returns current active state
   */
  private get currentState(): PageCreationStates {
    return this.pageStateService.currentState;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {

    this.destroyed.next();
    this.destroyed.complete();

    this.onDestroyed();
  }

  /**
   * Fetches page types
   */
  getPageTypes() {

    this.progress = true;

    this.createPageService.getPageTypes()
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.pageTypes = res

          if (this.pageTypes && this.defaultSelectedPageType) {
            this.initializePageType(this.defaultSelectedPageType);
          }
        }
      );
  }

  /**
   * Creates a page. Calls the create page Api implemented by
   * its children.
   */
  createPage(): void {

    // Get payload, to be implemented by its child
    const payload = this.getCreationPayload(this.pageStateService.creationStateData);

    // Disable the form
    // this.pageForm.disable();

    // Call api to create page
    this.createPageService.createPage(payload, this.pageType)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.pageCreationHandler(res),
        err => this.onPageCreationError(err)
      );
  }

  /**
   * Initializes the selected page type and moves to the next step.
   * Use this method if a page should be default selected and wanted
   * to move to the next step.
   */
  initializePageType(selectedPageType: PageType) {

    // Get selected page type menu item from the list of all available pages
    const selectedPageTypeMenuItem = this.pageTypes.find(p => p.id === selectedPageType);

    // No page type with the specified page type
    if (!selectedPageTypeMenuItem) {
      return;
    }

    // Update the selected page type
    this.pageType = selectedPageTypeMenuItem.id as PageType;


    // Added a timer delay to ensure the MatStepper gets initialized properly
    // Don't remove the delay as it will throw error moving to next step as
    // MatStepper is not initialized at this point.
    setTimeout(() => {
      // Move to next step
      this.nextStep();
    }, 500);
  }

  /**
   * Moves the current page creation state to the next state.
   */
  nextStep() {

    let data = {};

    if (this.currentState === 'CHOOSE_PAGE_TYPE') {
      data = this.pageType;
    } else if (this.currentState === 'FILL_COMMON_PAGE_DETAILS') {
      data = this.commonPageForm.value;
    } else if (this.currentState === 'FILL_SPECIFIC_PAGE_DETAILS') {
      data = this.detailedPageForm.value;
    }

    this.pageStateService.nextState(data);
  }

  /**
   * Moves the current page creation stage to the previous state.
   */
  previousState() {
    this.pageStateService.previousState();
  }

  // serCurrentPageData(pageData: any) {

  //   if (pageData instanceof FormGroup) {
  //     this.currentPageData = pageData.value;
  //   } else {
  //     this.currentPageData = pageData;
  //   }

  // }

  viewPage() {
    this.appRouting.toProgram({ pageId: this.pageId });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


   // --------------------------------------------------------
  // #region Abstract classes to be inherited by children
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------



  /**
   * Calls API to create the page.
   */
  protected createPageApi(payload: any): Observable<any> {
    return of();
  }

  /**
   * Prepares the payload
   * @param formValue
   */
  protected getCreationPayload(formValue: PageCreationStateDataMap): any {

    const payload: any  = {
      pageType: formValue.CHOOSE_PAGE_TYPE.data,
      ...formValue.FILL_COMMON_PAGE_DETAILS.data,
      ...formValue.FILL_SPECIFIC_PAGE_DETAILS.data,
      pageProfilePic: '/data/profilepics/default.png?1593235117935'
    };

    if (payload.startDuration) {
      payload.startDuration = this.util.toISOString(payload.startDuration);
    }

    if (payload.endDuration) {
      payload.endDuration = this.util.toISOString(payload.endDuration);
    }

    return payload;
  }

  /**
   * Calls after the successful page creation
   * @param res Api response
   */
  protected pageCreationHandler(res: any): void {

    this.disableCreateButton = false;
    this.pageId = res.pageId;
    this.nextStep();
  }

  /**
   * Event handler for create page API errors.
   */
  protected onPageCreationError(err: Error): void {

    this.disableCreateButton = true;

    this.previousState();

    this.notifications.showMessage('Unable to create page, Please try again');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
