import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_PROFILE_PIC, kalgudiAnimations } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

import { KalgudiCommonRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-selectable-user-tile',
  templateUrl: './selectable-user-tile.component.html',
  styleUrls: ['./selectable-user-tile.component.scss'],
  animations: kalgudiAnimations,
})
export class SelectableUserTileComponent implements OnInit {

  @Input()
  author: KalgudiUserBasicDetails;

  @Input()
  avatarSize: string;

  @Input()
  roundedAvatar: boolean;

  @Input()
  isSelected: boolean;

  @Input()
  showRemove: boolean;

  @Input()
  enableElevation: boolean;

  @Input()
  navigationAllowed: boolean;

  @Output()
  removed = new EventEmitter<any>();

  @Output()
  viewProfilePage = new EventEmitter();

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {
    this.roundedAvatar = true;

    this.domain = this.environment.coreDomain;
  }


  ngOnInit() {

  }

  removeUser() {
    this.removed.emit();
  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
    this.viewProfilePage.emit();
  }

}
