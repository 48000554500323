<div fxLayout="row">
  <div fxFlex.xs="100%">
    <ng-container>
      <div (click)="pickPageMembers()" class="pt-2">
        <strong>
          <a href="javascript: void(0);" translate>Click here to select specific members</a>
        </strong>
      </div>
    </ng-container>
  </div>
</div>

<div fxLayout="row" class="share-visibility">

  <!-- Selected members list -->
  <div *ngIf="!(selectedProgramMembers | isEmpty)">
    <ng-container>

      <!-- Heading -->
      <div class="my-2">
        <span class="text-muted bg-white d-inline pr-2" translate>Update will be sent to</span>
        <div class="border-bottom mt-n2"></div>
      </div>

      <!-- Chip list -->
      <div class="my-3">
        <mat-chip-list class="mb-4">
          <ng-container *ngFor="let item of (selectedProgramMembers | keyvalue); trackBy: selectedUserTrackByFun">
            <mat-chip class="pl-0 chips" [@fadeIn]>
              <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="start center">
                <img class="chip-image rounded-circle" [src]="item?.value?.profilePicUrl | prefixDomain"
                  [klErrorImages]="[
                    (item?.value?.profilePicUrl) | prefixDomain,
                    defaultProfilePic
                  ]"
                />
                <span class="text-truncate" [title]="item?.value?.firstName">{{ item?.value?.firstName }}</span>
                <mat-icon matChipRemove [title]="'Remove user' | translate" (click)="removeSelectedProgramMember(item?.value?.profileKey)">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
      <!--/ Chip list -->

    </ng-container>
  </div>
  <!-- / Selected members list -->
</div>
