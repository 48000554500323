import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import {
  AddSurveyMembersResponse,
  ApiSurveyMemberAddResponseData,
  BulkMembersAdditionResponse,
  KalgudiPageRelation,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SurveyPageActions } from '../constants';
import { KalgudiSurveyMembersApiService } from './kalgudi-survey-members-api.service';
import { SurveyStateService } from './survey-state.service';

@Injectable()
export class KalgudiSurveyMembersService {

  constructor(
    private api: KalgudiSurveyMembersApiService,
    private surveyState: SurveyStateService,
  ) { }

  // --------------------------------------------------------
  // #region Admins management
  // --------------------------------------------------------

  /**
   * Fetches list of admins of the survey from the Api.
   */
  getAdmins(surveyId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.getUsers(surveyId, 'ADMIN', offset, limit);
  }

  /**
   * Adds an admin to a survey.
   */
  addAdmins(surveyId: string, usersList: AddSurveyMembersResponse): Observable<ApiSurveyMemberAddResponseData> {
    return this.addUsers(surveyId, usersList, 'ADMIN')
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  /**
   * Removes a list of admins from a survey
   */
  deleteAdmins(surveyId: string, profileKey: string): Observable<ApiSurveyMemberAddResponseData> {
    return this.deleteUsers(surveyId, profileKey)
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region members management
  // --------------------------------------------------------


  /**
   * Fetches list of members of the survey from the Api.
   */
  getMembersList(surveyId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.getUsers(surveyId, 'MEMBER', offset, limit);
  }

  /**
   * Searches list of survey members.
   */
  searchMembers(surveyId: string, searchKeyword: string, offset: number, limit: number, extraParams: PartialData = {}): Observable<KalgudiStreamData> {
    return this.api.searchUsers(surveyId, searchKeyword, 'MEMBER', offset, limit, extraParams);
  }

  /**
   * Adds members to a survey.
   */
  addMembersList(surveyId: string, usersList: AddSurveyMembersResponse): Observable<ApiSurveyMemberAddResponseData> {
    return this.addUsers(surveyId, usersList, 'MEMBER')
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  /**
   * Add bulk members to a survey.
   */
  addBulkMembersList(
    surveyId: string,
    usersList: AddSurveyMembersResponse,
    filters: PartialData = {}
  ): Observable<BulkMembersAdditionResponse> {
    return this.api.addBulkMembers(surveyId, usersList, 'MEMBER', filters)
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }

  /**
   * Removes a list of members from a survey
   */
  deleteMember(surveyId: string, profileKey: string): Observable<ApiSurveyMemberAddResponseData> {
    return this.deleteUsers(surveyId, profileKey)
      .pipe(
        tap(_ => this.surveyState.dispatchAction(SurveyPageActions.FETCH_SURVEY))
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Fetches, members list from the surveys Api.
   */
  private getUsers(surveyId: string, memberRole: KalgudiPageRelation, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.api.fetchUsers(surveyId, memberRole, offset, limit);
  }

  /**
   * Adds a member to a kalgudi survey.
   */
  private addUsers(
    surveyId: string,
    usersList: AddSurveyMembersResponse,
    memberRole: KalgudiPageRelation
  ): Observable<ApiSurveyMemberAddResponseData> {

    return this.api.addUsers(surveyId, usersList, memberRole);
  }

  /**
   * Removes a member from the survey
   */
  private deleteUsers(surveyId: string, usersProfileKey: string): Observable<ApiSurveyMemberAddResponseData> {
    return this.api.deleteUsers(surveyId, usersProfileKey);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
