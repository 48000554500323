<div id="kalgudi-project-members-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ data?.title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>

  <div class="dialog-content add-member-dialog">

    <mat-tab-group [(selectedIndex)]="activeTabIndex" [animationDuration]="0">
      <mat-tab [label]="TABS.MANAGE.title | translate">
        <kl-manage-project-members *ngIf="activeTabIndex === TABS.MANAGE.index"
          [projectId]="projectDetails?.id">
        </kl-manage-project-members>
      </mat-tab>

      <mat-tab [label]="TABS.TARGET_MEMBERS.title | translate">
        <div class="px-2">
          <kl-add-page-project-members
            [pageId]="data?.data?.pageId"
            (addedMembers)="addedMembers($event)">
          </kl-add-page-project-members>
        </div>
      </mat-tab>

      <!-- Bulk members tab -->
      <mat-tab [label]="TABS.BULK_MEMBERS.title | translate"  [disabled]="environment.production">
        <div class="dialog-container" *ngIf="activeTabIndex === TABS.BULK_MEMBERS.index">
          <kl-page-project-bulk-members [pageId]="data?.data?.pageId" [projectId]="projectDetails?.id"></kl-page-project-bulk-members>
        </div>
      </mat-tab>
      <!-- / Bulk members tab -->

      <!-- <mat-tab [label]="TABS.SEARCH.title">
        <kl-search-members *ngIf="activeTabIndex === TABS.SEARCH.index"
          [projectId]="projectDetails?.id"
          (membersAdded)="onMembersAddition($event)">
        </kl-search-members>
      </mat-tab> -->

    </mat-tab-group>

  </div>
</div>
