<main>
  <form class="w-100" [formGroup]="surveyForm">
    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label translate>Survey title</mat-label>
        <input matInput required formControlName="title" [placeholder]="'Survey title' | translate" />
        <mat-error translate>Please enter the title</mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label translate>Description</mat-label>
        <textarea rows="1" required matInput formControlName="description" [placeholder]="'A brief description of survey' | translate" ></textarea>
        <mat-error translate>Survey description is mandatory</mat-error>
      </mat-form-field>
    </div>

    <div class="w-100" fxLayout fxLayout.xs="column" fxLayoutGap="10px" fxLayoutGap.xs="0">
      <div fxFlex="50%" fxFlex.xs="100%">
        <kl-google-places-autocomplete label="Location/Place *" [placeholder]="'Location/Place *' | translate" formControlName="location"></kl-google-places-autocomplete>
      </div>

      <div fxFlex="50%" fxFlex.xs="100%">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Expires on</mat-label>
          <input matInput
            [placeholder]="'Expires on' | translate"
            [matDatepicker]="startDate"
            (click)="startDate.open()"
            readonly required
            formControlName="expiryDate">
          <!-- <mat-error class="mt-3">Please mention start duration</mat-error> -->
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout fxLayoutAlign="space-between">
      <section>
        <mat-checkbox formControlName="isResubmittable"><span translate>Allow resubmission</span></mat-checkbox>
      </section>
    </div>

  </form>
</main>
