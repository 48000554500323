import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CommonSearchBarModule } from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiProjectListModule } from '../project-list/project-list.module';
import { MembersListHeaderComponent } from './members-list-header/members-list-header.component';
import { MembersListTableComponent } from './members-list-table/members-list-table.component';
import { KalgudiMembersApiService } from './services/kalgudi-members-api.service';
import { KalgudiMembersService } from './services/kalgudi-members.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';



@NgModule({
  declarations: [
    MembersListHeaderComponent,
    MembersListTableComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatIconModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiProjectListModule,
    KalgudiBreadcrumbsModule,
    CommonSearchBarModule,
    TranslateModule
  ],
  exports: [
    MembersListHeaderComponent,
    MembersListTableComponent
  ],
  providers: [
    KalgudiMembersApiService,
    KalgudiMembersService,
  ]
})
export class KalgudiMembersModule { }
