import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { SelectableUIMenu, UIMenu } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { KalgudiProjectStateService } from '../../../../services/kalgudi-project-state.service';
import { KalgudiProjectCreationService } from '../../../project-creation/services/kalgudi-project-creation.service';
import { ProjectStateService } from '../../../project-shared/services/project-state.service';

@Component({
  selector: 'kl-projects-list-header',
  templateUrl: './projects-list-header.component.html',
  styleUrls: ['./projects-list-header.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectsListHeaderComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  projectCategories: SelectableUIMenu[];

  @Input()
  projectsMenu: UIMenu;

  @Output()
  projectsOption = new EventEmitter<any>();

  @Output()
  showSidebar = new EventEmitter<any>();

  @Input()
  selectedView: string;

  constructor(
    private stateChangeService: ProjectStateService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
    private projectCreationService: KalgudiProjectCreationService,
    private projectStateService: KalgudiProjectStateService,
  ) {

    super();

  }

  ngOnInit() {
    // this.selectedView = this.projectsMenu[0].id;
  }

  onDestroyed(): void {}

  showProjectsList(menu) {

    this.selectedView = menu.id;
    this.projectsOption.emit(menu);
  }

  openFilterSidebar() {
    this.stateChangeService.updateFilterSideBarStatus();
  }

  /**
   * Opens the project creation dialog
   */
  openCreateProjectDialog() {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories)
      .subscribe(
        res => {
          this.notification.showMessage('Project created');

          this.appRouting.toProject( { projectId: res.id }  , {type: 'GRID'});
          // this.projectStateService.reloadProjects();
        }
      );
  }

}
