import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  KalgudiPromotionalCropAutocompleteComponent,
} from './kalgudi-promotional-crop-autocomplete/kalgudi-promotional-crop-autocomplete.component';
import {
  KalgudiPromotionalProductAutocompleteComponent,
} from './kalgudi-promotional-product-autocomplete/kalgudi-promotional-product-autocomplete.component';
import {
  KalgudiPromotionalProductDescriptionDialogComponent,
} from './kalgudi-promotional-product-description-dialog/kalgudi-promotional-product-description-dialog.component';
import { KalgudiPromotionalShareUpdateApiService } from './services/kalgudi-promotional-share-update-api.service';
import { KalgudiPromotionalShareUpdateService } from './services/kalgudi-promotional-share-update.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    KalgudiPromotionalProductAutocompleteComponent,
    KalgudiPromotionalProductDescriptionDialogComponent,
    KalgudiPromotionalCropAutocompleteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    // Material module
    MatFormFieldModule,
    MatBottomSheetModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatAutocompleteModule,
    AngularSvgIconModule,

    // Kalgudi Libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiDirectivesModule,
    MobileMenuBottomSheetModule,
    TranslateModule
  ],
  exports: [
    KalgudiPromotionalProductAutocompleteComponent,
    KalgudiPromotionalProductDescriptionDialogComponent,
    KalgudiPromotionalCropAutocompleteComponent
  ],
  providers: [
    KalgudiPromotionalShareUpdateApiService,
    KalgudiPromotionalShareUpdateService
  ],
  entryComponents: [
    KalgudiPromotionalProductDescriptionDialogComponent,
  ]
})
export class KalgudiPromotionalShareUpdateModule { }
