import { Injector, Input, Directive } from '@angular/core';
import { KalgudiStatusDialogService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { BulkMembersAdditionResponse } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { AddTaskMembersService } from '../services/add-task-members.service';

@Directive()
export abstract class KalgudiAddTaskMembers extends KalgudiDestroyable {

  @Input()
  taskId: string;

  @Input()
  projectId: string;

  private notification: KalgudiNotification;
  private kalgudiStatusDialogService: KalgudiStatusDialogService;
  private addTaskMembersService: AddTaskMembersService;

  constructor(protected injector: Injector) {

    super();

    this.notification               = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiStatusDialogService = this.injector.get(KalgudiStatusDialogService);
    this.addTaskMembersService      = this.injector.get(AddTaskMembersService);
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls the api to add bulk task members
   */
  addMembers(users: string[]) {

    this.notification.showSpinner();

    this.addTaskMembersService.addBulkMembers(this.taskId, users)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onMembersAdditionSuccess(res),
        err => this.onAddMemberError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful member addition.
   */
  protected onMembersAdditionSuccess(res: BulkMembersAdditionResponse): void {

    this.openStatusDialog(res);

  }

  /**
   * Event handler for adding members API errors.
   */
  protected onAddMemberError(err: Error): void {
    this.notification.showMessage(err.message);
  }

  /**
   * Open status dialog
   */
  private openStatusDialog(users: BulkMembersAdditionResponse) {
    return this.kalgudiStatusDialogService.openStatusDialog(users)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
