import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class StoreProductsSearchApiService {
  /**
   * `v2/listing/products/l2/home-page-categories-tiles`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/listing/products/l2/home-page-categories-tiles`;

  /**
   * `v2/search/farmstore/products`
   */
  private readonly API_SEARCH_STORE_PRODUCTS = `${this.env.restBaseUrlV2}/search/farmstore/products`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  /**
   * Calls listing api to fetch the store products
   */
  fetchStream(offset: number, limit: number, storeType: string = ''): Observable<KalgudiStreamData> {

    const host = this.env.domain.split('://core.' || '://').pop();

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      domain: host,
      languageId: '1',
      store: storeType ? 'FARMSTORE' : host,
      onlyCardData: 'true'
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        map(r => ({ items: r.data.cardData, count: r.data.count}))

      );
  }

  /**
   * Makes an API call to search list of all products with the specified
   * search keyword.
   */
  searchProducts(searchKeyword: string, offset: number, limit: number, storeType: string = ''): Observable<KalgudiStreamData> {

    const host = this.env.domain.split('://core.' || '://').pop();

    const params = {
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      domain: host,
      store: storeType ? 'FARMSTORE' : host,
      onlyCardData: 'true'
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_SEARCH_STORE_PRODUCTS, { params })
      .pipe(

        // Check for API errors
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to product search result type
        map(r => ({ items: r.data, count: r.data.count}))
      );
  }

}
