<div class="p-2" id="task-member-list-da" *ngIf="(stream$ | async) as stream">
  <div fxLayout fxLayoutAlign="space-between center">
    <div fxLayout="column">
      <span class="member text-muted" translate>Members</span>
      <span class="member-count">
        {{ memberCount }}
      </span>
    </div>

    <kl-search class="search"
      [placeHolder]="'Search members' | translate"
      (search)="search();"
      [searchForm]="searchForm">
    </kl-search>
  </div>

  <div *ngFor="let member of stream;let last = last;let first = first" class="border-bottom"
    [ngClass]="{'border-bottom-0': last, 'pt-2': first}">

    <div (click)="toggleTaskMemberSelection(member)">
      <!-- Task members tile -->
      <kl-task-members-tile-da
        [member]="member">
      </kl-task-members-tile-da>
      <!--/ Task members tile -->
    </div>
  </div>

  <div class="mt-3" *ngIf="paginator$ | async as paginator">
    <div fxLayout="row" fxLayoutAlign="center center" fxLayout.xs="column">
      <ng-container *ngIf="!stream || stream?.length <= 0">
        <div>
          <kl-no-content
            svgIcon="assets/svgs/members.svg"
            text= "No members"
            progressText="Loading members..."
            [progress]="paginator?.loading">
          </kl-no-content>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="stream?.length > 0">
      <kl-load-more
        noMoreText="No more members to show!"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </ng-container>
  </div>
</div>
