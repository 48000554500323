import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import { SearchMembers } from '../../classes/search-members';

@Component({
  selector: 'kl-search-members',
  templateUrl: './search-members.component.html',
  styleUrls: ['./search-members.component.scss']
})
export class SearchMembersComponent extends SearchMembers implements OnInit {


  @Output()
  membersAdded = new EventEmitter<string[]>();

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  protected onAddingMembers(res): void {
    this.membersAdded.emit(res);
  }

}
