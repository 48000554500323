import { Component, Inject, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { fadeInAnimation, KalgudiTranslationService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { WeatherFullview } from '../../classes/weather-fullview';
import { KalgudiWeatherRouteConfig } from '../../config';

@Component({
  selector: 'kl-home-weather-tile',
  templateUrl: './home-weather-tile.component.html',
  styleUrls: ['./home-weather-tile.component.scss'],
  animations: [fadeInAnimation],
})
export class HomeWeatherTileComponent extends WeatherFullview implements OnInit, OnChanges {

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiWeatherRouteConfig,
    protected injector: Injector,
    private translate: TranslateService,
    protected translationService: KalgudiTranslationService
  ) {
    super(injector, translationService);
  }

  ngOnInit() {

    this.translate.onLangChange
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.getWeatherDetails(this.location.latitude, this.location.longitude, res.lang.toUpperCase());
      } );
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.location && this.location) {
      this.getWeatherDetails(this.location.latitude, this.location.longitude, this.translationService.activeLanguage.toUpperCase());
    }
  }

  /**
   * Navigates to weather page
   */
  opensWeatherPage(): void {
    this.appRouting.toWeather();
  }

  onDestroyed(): void {}
}
