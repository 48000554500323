import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialogRef } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateStreamStateService } from '@kalgudi/social';
import { ProgramShareUpdate, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-program-share-update-form',
  templateUrl: './mobile-program-share-update-form.component.html',
  styleUrls: ['./mobile-program-share-update-form.component.scss']
})
export class MobileProgramShareUpdateFormComponent implements OnInit {

  selectedTabId: string;

  tabsList: UIMenu[] = [
    {
      id: 'UPDATES',
      selected: false,
      title: 'Updates'
    },
    {
      id: 'PRODUCTS',
      selected: false,
      title: 'Products'
    }
  ];

  constructor(
    protected shareUpdateStreamState: KalgudiShareUpdateStreamStateService,
    private dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification
  ) { }

  ngOnInit() {
    this.selectedTabId = this.tabsList[0].id
  }

  onPostCreated(event: any): void {
    if (event && !event.scheduledTS) {
      this.pushItemToHomeStream(event);
    } else {
      this.notifications.showMessage(`Post scheduled`);
    }
    this.dialogRef.close();
  }

  /**
   * Toggle for updates and product tabs
   */
  toggleTab(activity: string) {
    this.selectedTabId = activity;
  }

  /**
   * Pushes latest posted item to the stream
   * @param val
   */
  private pushItemToHomeStream(val: ProgramShareUpdate): void {
    this.shareUpdateStreamState.unshiftToStream(val);
  }

}
