<div id="upload-image-mobile" class="h-100">

  <div class="mt-3">
    <kl-add-awards
      [awardId]="awardId"
      [awardDetails]="awardDetails"
      (closeDialog)="ok()"></kl-add-awards>
  </div>

</div>
