import { Component, Input, OnInit } from '@angular/core';
import { ShareQaFullDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-qa-answers-list',
  templateUrl: './qa-answers-list.component.html',
  styleUrls: ['./qa-answers-list.component.scss']
})
export class QaAnswersListComponent implements OnInit {

  @Input()
  qa: ShareQaFullDetails;

  @Input()
  url: string;

  constructor() { }

  ngOnInit() {
  }

}
