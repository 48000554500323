<!-- Search bar -->
<div class="mb-3" fxLayoutAlign="center center">
  <kl-search class="search w-100"
    placeHolder="Type and hit enter to search projects"
    (search)="search();"
    [searchForm]="searchForm">
  </kl-search>
</div>
<!-- /Search bar -->

<div id="project-list-table">

  <mat-card [@floatTop]="{ value: '', params: { delay: 0 }}" class="p-0 overflow-hidden">

    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!-- <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? toggleTableSelection() : null"
            [checked]="allRowsSelected"
            [indeterminate]="someRowsSelected">
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row; let index = index;">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? toggleRowSelection(row, index) : null"
            [checked]="selectedRowsMap[index]">
          </mat-checkbox>
        </td>
      </ng-container> -->

      <!--Task name column -->
      <ng-container matColumnDef="projectName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span translate>Project name</span> </th>
        <td mat-cell *matCellDef="let element"> {{ element?.title }} </td>
      </ng-container>
      <!--/ Task name column -->

      <!--Category column -->
      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <span translate>Category</span> </th>
        <td mat-cell *matCellDef="let element"> {{ projectTypesMap[element?.type] | translate }} </td>
      </ng-container>
      <!--/ Category column -->

      <!-- Start date column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <span translate>Start date</span> </th>
        <td mat-cell *matCellDef="let element" class="text-secondary">
          <span *ngIf="element?.startDate">
            {{ element?.startDate | date:'dd' }}
            {{ element?.startDate | date:'MMM' | translate}}
            {{ element?.startDate | date:'yyyy' }}
          </span>
          <span *ngIf="!element?.startDate"> - </span>
        </td>
      </ng-container>
      <!-- / Start date column -->

      <!-- Members column -->
      <ng-container matColumnDef="members">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <span translate>Members</span> </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="4px">
            <div>
              <kl-users-stack-tile [users]="element?.users" maxValue="3"></kl-users-stack-tile>
            </div>
            <div fxLayout fxLayoutAlign="center center">
              <span>{{element?.statistics?.members?.total}}</span>

      <!-- <button mat-mini-fab color="primary" class="add-btn mt-1">
              <mat-icon class="text-white">add</mat-icon>
            </button> -->
          </div>
          </div>
        </td>
      </ng-container>
      <!--/ Members column -->

      <!-- Tasks column -->
      <ng-container matColumnDef="tasks">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> <span translate>Tasks</span> </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="4px">
            <div>
              <p class="mb-0">{{ element?.tasks }}</p>
            </div>
            <div fxLayout fxLayoutAlign="center center">
                <span>{{element?.statistics?.tasks?.total}}</span>
      <!-- <button mat-mini-fab color="primary" class="add-btn mt-1">
              <mat-icon class="text-white">add</mat-icon>
            </button> -->
            </div>
          </div>
        </td>
      </ng-container>
      <!--/ Tasks column -->

      <!-- Status column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> <span translate>Status</span> </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div fxLayout fxLayoutAlign="start center">
            <div class="chip {{ element?.status | lowercase }}">
              <p class="text-white">{{ element?.status | titlecase | trimSpecialCharacters | translate }}</p>
            </div>
          </div>

      <!-- <div *ngFor="let item of element?.chips">
              <kl-chips [chip]="item"></kl-chips>
          </div> -->
        </td>
      </ng-container>
      <!-- / Status column -->

      <!-- Settings column -->
      <ng-container matColumnDef="settings">
        <th mat-header-cell *matHeaderCellDef> <span translate>Actions</span> </th>
        <td mat-cell *matCellDef="let element">
          <!-- <span> - </span> -->

          <!-- settings menu  -->

          <div fxLayout="row" class="actions" *ngIf="element?.userRole === projectRole?.ADMIN">
            <!-- <button mat-icon-button class="mb-0" matTooltip="Add Tasks">
              <mat-icon>add_circle</mat-icon>
            </button> -->
            <button mat-icon-button class="mb-0" [matTooltip]="'Edit project' | translate"
              (click)="showUpdateProject(element?.id);$event.stopPropagation()">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button class="mb-0" [matTooltip]="'Add Members' | translate" (click)="showAddMembersDialog(element?.id);$event.stopPropagation()">
              <mat-icon>group_add</mat-icon>
            </button>
            <button mat-icon-button class="mb-0" [matTooltip]="'Add Tasks' | translate" (click)="openTaskCreationDialog(element?.id);$event.stopPropagation()">
              <svg-icon [applyCss]="true" class="task-icon mr-2" src="assets/svgs/tasks.svg"
                [svgStyle]="{'width.px': '20', 'height.px': '20'}"></svg-icon>
            </button>
            <button mat-icon-button class="mb-0" [matTooltip]="'Delete' | translate"
              (click)="deleteProject(element?.id);$event.stopPropagation()">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </div>
          <!-- settings menu  -->

        </td>
      </ng-container>
      <!-- / Settings column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer"  (click)="openProjectFullview(row?.id)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

</div>
