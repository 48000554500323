import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ProfileLog } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProfileLogsApiService {

  /**
   * `v2/profiles/profile-logs`
   */
  private readonly API_PROFILE_LOGS = `${this.env.restBaseUrlV2}/profiles/profile-logs`;

  /**
   * `v2/profiles/profile-logs`
   */
  private readonly API_PROFILE_LOGS_LIST = `${this.env.restBaseUrlV2}/profiles/profile-logs`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    protected util: KalgudiUtilityService
  ) { }

  /**
   * Calls api to post profile log
   */
  createProfileLog(payload: ProfileLog): Observable<any> {

    return this.httpClient.post<ApiResponseCommon>(this.API_PROFILE_LOGS, payload)
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response
        map(r => this.util.toJson<any>(r.data))
      );
  }

  /**
   * Fetches list of products
   */
  getProfileLogList(memberId: string, contributorId: string, pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    const params = {
      memberId,
      contributorId,
      pageId,
      offset: offset.toString(),
      limit : limit.toString(),
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_PROFILE_LOGS_LIST, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi project details object type
        map(res => ({ items: res.data.results, count: res.data.count }))
      );
  }
}
