import { EventEmitter, Inject, Injector, Input, OnChanges, Output, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService, KalgudiSocialShare } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import {
  IdValueMap,
  KalgudiDialogConfig,
  KalgudiPageDetails,
  KalgudiUser,
  ProgramShareRequest,
  ProgramShareUpdate,
} from '@kalgudi/types';
import { Observable, timer } from 'rxjs';
import { filter, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';
import { KalgudiProfilePageService } from '../services/kalgudi-profile-page.service';
import { KalgudiProgramStateService } from '../services/kalgudi-program-state.service';


@Directive()
export abstract class KalgudiFarmerFarmActivity  extends KalgudiSocialShare<ProgramShareRequest, ProgramShareUpdate> implements OnChanges {

  @Input()
  pageId: string;

  @Input()
  pageDetails: KalgudiPageDetails;

  @Input()
  selectedSeason: any;

  @Output()
  postCreated = new EventEmitter<ProgramShareUpdate>();

  shareForm: FormGroup;

  entityId: string;

  season = new FormControl('');

  seasonsList: any;

  shareTags$: Observable<IdValueMap[]>;

  profile: KalgudiUser;
  inProgress: boolean;

  shareFormTime = new FormControl({value: '', disabled: true});

  seasonTags: IdValueMap[] = [
    {id: 'PRE_SEASON', value: 'Pre season'},
    {id: 'IN_SEASON', value: 'In season'},
    {id: 'POST_SEASON', value: 'Post season'}
  ];

  preSeasonTags: IdValueMap[] = [
    {id: 'LAND_PREPARATION', value: 'Land preparation'},
    {id: 'SEED_SELECTION', value: 'Seed selection'},
    {id: 'PRE_AGRONOMIC_ADVISORIES', value: 'Pre agronomic advisories'}
  ];

  inSeasonTags: IdValueMap[] = [
    {id: 'TRANSPLANTATION', value: 'Transplantation'},
    {id: 'SOWING', value: 'Sowing'},
    {id: 'IRRIGATION', value: 'Irrigation'},
    {id: 'FERTILIZER', value: 'Fertilizer'},
    {id: 'WEEDING', value: 'Weeding'},
    {id: 'PESTS', value: 'Pests'},
    {id: 'DISEASE', value: 'Disease'}
  ];

  postSeasonTags: IdValueMap[] = [
    {id: 'HARVEST', value: 'Harvest'}
  ];

  irrigationTags: IdValueMap[] = [
    {id: 'FURRO', value: 'Furro'},
    {id: 'FLOOD', value: 'Flood'},
    {id: 'SPINKLER', value: 'Spinkler'},
    {id: 'DRIP', value: 'Drip'}
  ];


  fertilizerTags: IdValueMap[] = [
    {id: 'NITROGEN', value: 'NItrogen'},
    {id: 'PROSPEROUS', value: 'Prosperous'},
    {id: 'POTASH', value: 'Potash'},
    {id: 'MICRO', value: 'Micro'}
  ];

  weedTags: IdValueMap[] = [
    {id: 'MANUAL', value: 'Manual'},
    {id: 'HERBICIDES', value: 'Herbicides'}
  ];


  pestsTags: IdValueMap[] = [
    {id: 'BORERS', value: 'Borers'},
    {id: 'LEAF_MINERS', value: 'Leaf mines'}
  ];

  diseaseTags: IdValueMap[] = [
    {id: 'LEAD_DUST', value: 'Leaf rust'}
  ];

  harvestTags: IdValueMap[] = [
    {id: 'YIELD', value: 'Yield'},
    {id: 'BIOMASS', value: 'Biomass'}
  ];

  rateOfInfestation: IdValueMap[] = [
    {id: 'LOW', value: 'Low'},
    {id: 'MEDUIM', value: 'Meduim'},
    {id: 'HIGH', value: 'High'}
  ];

  private kalgudiPageState: KalgudiProgramStateService;
  private farmerProfileService: KalgudiFarmerProfileService;
  private profileState: KalgudiProfileStateService;


  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiProfilePageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected mobileMenuService: KalgudiBottomSheetService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      mobileMenuService
    );

    this.kalgudiPageState     = this.injector.get(KalgudiProgramStateService);
    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.profileState         = this.injector.get(KalgudiProfileStateService);

    // Initialize share update form
    this.shareForm = this.programShareUpdateForm;

    // Patch program details to program form
    this.patchProgramDetailsToForm();

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.profile = profile;
        }
      );

    this.shareTags$ = this.kalgudiPageState.data$
      .pipe(
        switchMap(page => {

          let productId = '';

          try {
            if (page.pageType === 'STOCK') {
              productId = page.product.productId;
            } else if (page.pageType === 'PROCESSING') {
              productId = (<any>page).stockProductDetails.productId;
            }
          } catch (e) {
            console.info('Unable to get product id for the page.');
          }

          return this.kalgudiPageService.getPageShareTags(page.pageType, productId);
        }),
      );

      this.shareFormValueChanges();

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share update common form
   */
  private get programShareUpdateForm(): FormGroup {

    return new FormGroup({

      // Common fields inherited from share update
      authorId: new FormControl('', Validators.required),
      shareText: new FormControl(''),
      richShareText: new FormControl(''),
      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
      lstOfAttachments: new FormControl([]),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(''),
      scheduledTS: new FormControl(''),
      sharedTo: new FormControl(this.shareVisibilityList[0].value, Validators.required),

      farmingSeasonId: new FormControl(''),
      farmingSeasonTitle: new FormControl(''),

      // Program specific fields
      recipient: new FormControl(this.shareVisibilityList[0].value),
      entityId: new FormControl('', Validators.required),
      entityName: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isAuthor: new FormControl(false, Validators.required),
      msgTypes: new FormControl([]),

      // Farming activity details
      farmingActivityDetails: new FormGroup({
        seasonType: new FormControl('', Validators.required),
        seasonDetails: new FormGroup({
          activityType: new FormControl(''),
          irrigationType: new FormControl(''),
          fertilizerType: new FormControl(''),
          weedType: new FormControl(''),
          pestsType: new FormControl(''),
          diseaseType: new FormControl(''),
          harvestType: new FormControl(''),
          date: new FormControl(''),
          hours: new FormControl(0),
          quantityApplied: new FormGroup({
            value: new FormControl(0),
            unit: new FormControl('')
          }),
          rateOfInfestation: new FormControl('')
        })
      }),

      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),

        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        pageUrl: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
        isSms: new FormControl(''),
      }),
      isPublishedThroughTool: new FormControl(true),
      productsMetaData: new FormControl([])
    });
  }

  /**
   * Share form author id field
   */
  get shareFormAuthorId(): AbstractControl {
    return this.shareForm.get('authorId');
  }

  /**
   * Share form farming season id
   */
  get shareFormFarmingSeasonId(): AbstractControl {
    return this.shareForm.get('farmingSeasonId');
  }

  /**
   * Share form farming season title
   */
  get shareFormFarmingSeasonTitle(): AbstractControl {
    return this.shareForm.get('farmingSeasonTitle');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form shared to field
   */
  get shareFormSharedTo(): AbstractControl {
    return this.shareForm.get('sharedTo');
  }

  /**
   * Share form program entity id field
   */
  get shareFormEntityId(): AbstractControl {
    return this.shareForm.get('entityId');
  }

  /**
   * Share form program entity name field
   */
  get shareFormEntityName(): AbstractControl {
    return this.shareForm.get('entityName');
  }

  /**
   * Share form program is admin field
   */
  get shareFormIsAdmin(): AbstractControl {
    return this.shareForm.get('isAdmin');
  }

  /**
   * Share form program is author field
   */
  get shareFormIsAuthor(): AbstractControl {
    return this.shareForm.get('isAuthor');
  }

  /**
   * Share form farm activity details or tags field
   */
  get shareFormFarmActivityDetails(): FormGroup {
    return this.shareForm.get('farmingActivityDetails') as FormGroup;
  }

  /**
   * Share form season details
   */
  get shareFormSeasonDetails(): FormGroup {
    return this.shareFormFarmActivityDetails.get('seasonDetails') as FormGroup;
  }

  get shareFormDate(): AbstractControl {
    return this.shareFormSeasonDetails.get('date');
  }


  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  get shareFormIsSms(): AbstractControl {
    return this.shareFormFilter.get('isSms');
  }

  get shareFormPageId(): AbstractControl {
    return this.shareFormFilter.get('pageId');
  }

  get shareFormPageTitle(): AbstractControl {
    return this.shareFormFilter.get('pageTitle');
  }

  get shareFormPageType(): AbstractControl {
    return this.shareFormFilter.get('pageType');
  }

  get shareFormPageUrl(): AbstractControl {
    return this.shareFormFilter.get('pageUrl');
  }

  get shareFormParentPageId(): AbstractControl {
    return this.shareFormFilter.get('parentPageId');
  }

  get shareFormParentPageTitle(): AbstractControl {
    return this.shareFormFilter.get('parentPageTitle');
  }

  get shareFormParentPageUrl(): AbstractControl {
    return this.shareFormFilter.get('parentPageUrl');
  }

  /**
   * Share form farming activity details
   */
  get shareFormFarmingActivityDetails(): FormGroup {
    return this.shareForm.get('farmingActivityDetails') as FormGroup;
  }

  get seasonTypeControl(): AbstractControl {
    return this.shareFormFarmingActivityDetails.get('seasonType');
  }

  get seasonDetails(): FormGroup {
    return this.shareFormFarmingActivityDetails.get('seasonDetails') as FormGroup;
  }

  get activityTypeControl(): AbstractControl {
    return this.seasonDetails.get('activityType');
  }


  /**
   * Share form msgTypes field
   */
  get shareFormMsgTypes(): AbstractControl {
    return this.shareForm.get('msgTypes');
  }

  /**
   * Returns true if the advisories tag is selected else returns false
   */
  get isAdvisorySelected(): boolean {
    return Array.isArray(this.shareFormMsgTypes.value)
      ? this.shareFormMsgTypes.value.includes('ADVISORIES')
      :  false;
  }

  get selectedProducts(): AbstractControl {
    return this.shareForm.get('productsMetaData');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  ngOnChanges() {
    this.getPageDetails(this.pageId);
    this.getSeasons(this.pageId);

    if (this.pageDetails) {
      this.entityId = this.pageDetails.pageId;

      this.patchProgramDetails(this.pageDetails)
    }

  }

  getSeasons(pageId: string) {
    this.inProgress = true;

    const profileKey = this.profile.profileKey;

    this.farmerProfileService.getFarmSeasonsList(pageId, '', profileKey)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.inProgress = false)
      )
      .subscribe(
        res => {
          this.seasonsList = res;

          this.seasonsList.forEach(season => {
            if (season.id === this.selectedSeason.id) {
              this.season.patchValue(season);
            }
          });

        }
      )
  }

  /**
   * Calls api method to fetch page details
   */
  getPageDetails(pageId: string) {

    if (this.pageDetails) {
      return;
    }

    this.kalgudiPageService.fetchAndUpdatePageState(pageId)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.pageDetails = res;

          if (this.pageDetails) {
            this.entityId = this.pageDetails.pageId;

            this.patchProgramDetails(this.pageDetails)
          }
        }

      )
  }

  /**
   * Share post
   * Check if season value not there shows confirmation dialog
   */
  sharePost() {
    if (this.season.value) {
      this.shareFormFarmingSeasonId.patchValue(this.season.value.id);
      this.shareFormFarmingSeasonTitle.patchValue(this.season.value.title);
    }

    if (!this.season.value) {

      this.showConfirmationDialog()
      .pipe(

        map(res => this.createPost())
      )
      .subscribe(
      );
    } else {

      this.createPost();
    }
  }

  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {

    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);

      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets the program share update form
   *
   * @override
   */
  resetForm() {
    // Honour all parent actions
    super.resetForm();

    this.shareFormTime.patchValue('');

    // Patch page details after resetting page form
    this.patchProgramDetails(this.pageDetails);
  }

  protected getCreationPayload(shareFormValue: any): ProgramShareRequest {

    const time = this.shareFormTime.value;

    const splitted = time.split(':', 2);
    const event = new Date(this.shareFormDate.value);

    const modifiedDate = new Date(event.setHours(splitted[0], splitted[1]));

    const payload = super.getCreationPayload(shareFormValue);

    if(this.shareFormTime.value) {

      payload.farmingActivityDetails.seasonDetails.date = modifiedDate.toISOString();
    }

    payload.msgTypes = [payload.farmingActivityDetails.seasonType];

    return payload;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the program details and binding the details to he share form
   */
  private patchProgramDetailsToForm(): void {

    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),

              first(),

              tap(r => this.patchProgramDetails(r))
            )
        )
      ).subscribe();
  }

  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    this.pageDetails = pageDetails;

    this.pageId = pageDetails.pageId;

    // Required fields
    this.shareFormAuthorId.patchValue(this.kalgudiApp.profileLocal.profileKey);
    this.shareFormVisibility.patchValue(this.shareVisibilityList[0].value);
    this.shareFormSharedTo.patchValue(this.shareVisibilityList[0].value);

    this.shareFormEntityId.patchValue(this.pageId);
    this.shareFormEntityName.patchValue(pageDetails.pageTitle);
    this.shareFormIsAdmin.patchValue(this.kalgudiPageService.isPageAdmin(pageDetails));
    this.shareFormIsAuthor.patchValue(this.kalgudiPageService.isPageAuthor(pageDetails));

    // Filters
    this.shareFormIsSms.patchValue(false);
    this.shareFormPageId.patchValue(this.pageId);
    this.shareFormPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormPageType.patchValue(pageDetails.pageType);
    this.shareFormPageUrl.patchValue(pageDetails.pageProfilePic);
    this.shareFormParentPageId.patchValue(this.pageId);
    this.shareFormParentPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormParentPageUrl.patchValue(pageDetails.pageProfilePic);
    // this.shareFormFarmingSeasonId.patchValue(this.season.value.id);
    // this.shareFormFarmingSeasonTitle.patchValue(this.season.value.title);
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm',
      acceptButtonTitle: 'Ok',
      rejectButtonTitle: 'Cancel',
      message: 'Do you want to share this post without any season?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }


  private shareFormValueChanges(): void {

    this.shareFormDate.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        value => {
          if(value) {
            this.shareFormTime.enable();
          } else {
            this.shareFormTime.disable();
          }
        }
      );

  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
