import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiStreamData, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProjectListApiService {



  /**
   * `v2/projects`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/projects`;

  /**
   * `v2/projects`
   */
  private readonly API_SEARCH_PROJECTS = `${this.API_STREAM}/search`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  fetchStream(offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.streamResponseHandler(r)),

        // Map API response to Kalgudi project details object type
        map(r => this.mapPagesListResponse(r))

      );
  }

  /**
   * Makes an API call to search list of all projects with the specified
   * search keyword.
   */
  searchProjects(searchKeyword: string, offset: number, limit: number, filters: PartialData = {}): Observable<KalgudiStreamData> {

    const params = {
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_SEARCH_PROJECTS, { params })
      .pipe(

        // Check for API errors
        map(r => this.streamResponseHandler(r)),

        // Map API response to Kalgudi project search result type
        map(r => this.mapPagesListResponse(r))
      );
  }



  private streamResponseHandler(res: ApiResponseCommon): ApiResponseCommon {

    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Validate for error messages
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps projects list API response to kalgudi project details type.
   */
  private mapPagesListResponse(res: ApiResponseCommon): KalgudiStreamData {

    return {
      items: res.data.results,
      count: res.data.count
    };
  }


}
