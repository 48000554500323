<section class="network-author-details p-3">

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">

      <!-- user profile pic -->
      <div fxLayoutAlign="center center" class="profile-pic rounded-circle"
        [ngClass]="{'medium': avatarSize == 'md', 'small': avatarSize == 'sm', 'large': avatarSize == 'lg'}">
        <img class="w-100 h-100 rounded-circle"
          [src]="(author?.profilePicURL | resizeImage:'sm') | prefixDomain"
          [klErrorImages]="[
          (author?.profilePicUrl || author?.profilePicURL) | prefixDomain,
          domain + defaultProfilePic
          ]"
        />
      </div>

      <!-- user Details -->
      <div class="author-details">
        <a class="text-capitalize author-name mb-0" (click)="viewProfile(author?.profileKey)">
          {{ author?.firstName | secureMobile}}
        </a>
        <p class="biz-type mb-0" *ngIf="author?.businessName">
          {{ author?.businessName | secureMobile}}
        </p>
        <p class="mb-0 time text-muted" *ngIf="author?.location">
          {{ author?.location }}
        </p>
      </div>
    </div>

    <!-- network tile buttons -->
    <div fxLayoutAlign="end end">
      <kl-link-button [label]="isConnected ? 'Message' : 'View Profile'"  (clicked)="viewProfile(author?.profileKey)">
      </kl-link-button>
    </div>
  </div>
</section>
