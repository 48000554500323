import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KalgudiDialogResult, KalgudiImageDialogConfig, KalgudiImageDialogResult } from '@kalgudi/types';
import { KalgudiUploadImageDialog } from '../../classes/kalgudi-upload-image-dialog';

@Component({
  selector: 'kl-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss']
})
export class UploadImageDialogComponent extends KalgudiUploadImageDialog<UploadImageDialogComponent> implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UploadImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiImageDialogConfig,
    private ref: ChangeDetectorRef
  ) {

    super(
      dialogRef,
      dialogData,
    );
  }

  ngOnInit(): void {
    if(this.dialogData) {
      this.ref.detectChanges();
    }
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiImageDialogResult = {
      accepted: true,
      data: {
        attachments: this.attachments
      }
    };

    this.closeDialog(result);
  }

}
