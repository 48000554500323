<section class="promotional-header-info">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom pb-1">
    <div class="header-name" fxLayout="row" fxLayoutAlign="start center">
      <div class="update-icon" fxLayoutAlign="center center">
        <mat-icon class="updates">format_quote</mat-icon>
      </div>
      <div class="header-title">
        <h3 class="mb-0" *ngIf="!latestActivity?.type" translate>Updates
        </h3>
        <h3 *ngIf="latestActivity?.type" class="mb-0 d-flex" fxLayoutAlign="start center">
          <span *ngIf="activityTypes[latestActivity?.type]" class="user-detail text-truncate">
            <a class="mr-1" (click)="viewProfile(latestActivity?.id)"
              *ngIf="userDetails?.profileKey !== latestActivity?.id">
              <b>{{ latestActivity?.name | secureMobile }}</b>
            </a>
            <b *ngIf="userDetails?.profileKey === latestActivity?.id" translate>You&nbsp;</b>
          </span>
          <span class="text-muted">{{activityTypes[latestActivity?.type] || 'Updates' | translate }}</span>
          <span *ngIf="activityTypes[latestActivity?.type] === 'liked'" class="mb-0 text-muted"
            [title]="latestActivity?.TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">&nbsp;{{ latestActivity?.TS | timeAgo }}
            <span translate>ago</span>
          </span>
          <span *ngIf="activityTypes[latestActivity?.type] === 'commented'" class="mb-0 text-muted"
            [title]="latestActivity?.TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">&nbsp;{{ latestActivity?.TS | timeAgo }}
            <span translate>ago</span>
          </span>
        </h3>
      </div>
    </div>

    <div fxHide.xs fxFlex="6%" (click)="$event.stopPropagation(); $event.preventDefault()" *ngIf="isLoggedIn">

      <!-- three dot menu -->
      <button mat-icon-button fxLayoutAlign="end end" class="header-menu" [matMenuTriggerFor]="Menu">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>

      <mat-menu #Menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
        <button mat-menu-item id="report-abuse" class="mb-0" *ngIf="!scheduledUpdates" (click)="reportAbuse()" translate>Report abuse</button>
        <button mat-menu-item id="delete" class="mb-0" *ngIf="(authorId === userDetails?.profileKey && !scheduledUpdates) || memberRole === 'ADMIN'"
          (click)="deleteUpdate()" translate>Delete</button>
      </mat-menu>
    </div>

    <div fxHide.gt-xs  *ngIf="isLoggedIn" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="2px">

      <!-- Mobile menu -->
      <button mat-icon-button class="header-menu" (click)="openBottomSheet(); $event.stopPropagation(); $event.preventDefault()">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>
      <!--/ Mobile menu -->
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="space-between start" class="mw-100 promotional-header pt-2">
    <div fxLayout="row"fxFlex="94%" fxLayoutGap="6px" fxLayoutAlign="start center">
      <div *ngIf="promotionalTitle !== 'Recommended products'">
        <img class="logo rounded-circle mb-1"
          src="assets/images/frame.png"/>
      </div>
      <div *ngIf="promotionalTitle === 'Recommended products'">
        <img class="logo rounded-circle mb-1"
          src="assets/images/robot.png"/>
      </div>

      <div fxLayout="column" fxFlex="calc(90% - 6px)" class="details">
        <div>
          <span class="title mr-1 text-truncate d-block" *ngIf="promotionalTitle !== 'Recommended products'">{{ promotionalTitle }}</span>
          <span class="title mr-1 text-truncate d-block" *ngIf="promotionalTitle === 'Recommended products'">{{ promotionalTitle }}&nbsp;from&nbsp;<span class="special-text">kalgudi AI</span></span>
          <span class="title mr-1" *ngIf="!promotionalTitle">No title</span>
          <!-- <span class="time text-muted" [title]="promotionalTime | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'" *ngIf="promotionalTime">posted {{promotionalTime | timeAgo}}
            <span translate>ago</span>
          </span> -->
        </div>

        <div fxLayout="row" fxLayoutGap="5px">
          <span class="time text-muted" [title]="promotionalTime | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'" *ngIf="promotionalTime">{{"posted" | translate}} {{promotionalTime | timeAgo}}
            <span translate>ago</span>
          </span>
          <!-- <span class="time text-muted">{{promotionalTime | timeAgo}}</span>
          <img *ngIf="promotionalLocation" src="assets/svgs/k-location.svg">
          <span class="location text-muted"> {{promotionalLocation}}</span> -->
        </div>

      </div>
    </div>
  </div>

</section>
