import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { AgGridFormatter } from '@kalgudi/types';


@Injectable()
export class DateFormatter implements AgGridFormatter {

  constructor(
    private datePipe: DatePipe,
  ) {
  }

  format(value: any, format: string) {
    return this.datePipe.transform(value, format);
  }
}
