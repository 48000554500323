<mat-tab-group class="members-tabs" [(selectedIndex)]="activeTabIndex">

  <!-- Search members tab -->
  <mat-tab [label]="TABS.SEARCH.title | translate">
    <div class="mt-3 dialog-container manage-members overflow-auto" fxLayout="column" *ngIf="activeTabIndex === TABS.SEARCH.index">
      <div id="search-members" class="h-100" fxLayout="column">

        <kl-survey-members-add (addedMembers)="addedMembers()"></kl-survey-members-add>
      </div>

    </div>
  </mat-tab>
  <!-- / Search members tab -->

  <!-- Bulk members tab -->
  <mat-tab [label]="TABS.BULK.title | translate"  [disabled]="environment.production">
    <div class="dialog-container">
      <kl-survey-members-add-bulk (addedMembers)="addedMembers()"></kl-survey-members-add-bulk>
    </div>
  </mat-tab>
  <!-- / Bulk members tab -->

</mat-tab-group>
