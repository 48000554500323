import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUsersPickerDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiPageMembersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-programs-member-picker-dialog',
  templateUrl: './kalgudi-programs-member-picker-dialog.component.html',
  styleUrls: ['./kalgudi-programs-member-picker-dialog.component.scss']
})
export class KalgudiProgramsMemberPickerDialogComponent
  extends KalgudiUsersPickerDialog<KalgudiProgramsMemberPickerDialogComponent>
  implements OnInit {

  pageId: string;
  activeTabIndex: number;

  readonly TABS = {
    ALL:  { index: 0, title: 'All Members' },
    SELECTED: { index: 1, title: 'Selected Members' },
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: KalgudiPageMembersPickerDialogConfig,
    protected dialogRef: MatDialogRef<KalgudiProgramsMemberPickerDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);

    this.pageId = dialogData.pageId;
  }

  ngOnInit() {
    this.activeTabIndex = this.TABS.ALL.index;
  }

}
