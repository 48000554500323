import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInAnimation, floatTopAnimation } from '@kalgudi/core';

import { TaskSubmissions } from '../../classes/task-submissions';

@Component({
  selector: 'kl-task-submissions',
  templateUrl: './task-submissions.component.html',
  styleUrls: ['./task-submissions.component.scss'],
  animations: [floatTopAnimation, fadeInAnimation]
})
export class TaskSubmissionsComponent extends TaskSubmissions implements OnInit {

  constructor(
    protected injector: Injector,
    private router: Router,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.initTableStream(this.matPaginator, this.matSort);
  }

  onDestroyed(): void {}

  /**
   * Navigate to submissions
   */
  navigateToSubmissions() {
    const url = `/app/projects/${this.taskDetails.projectId}/${this.taskDetails.id}/members`;
    this.router.navigate([url], {queryParams : { membersStatus: 'NOT_STARTED'}});
  }
}
