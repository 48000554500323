import { Directive, Injector } from '@angular/core';
import {
  KALGUDI_S3_POLICY_MAP,
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiUsersService,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSellerConfig, KalgudiUser } from '@kalgudi/types';
import { BehaviorSubject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { ProfilePageActions } from '../constants';
import { KalgudiProfileStateService } from '../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../services/kalgudi-profile.service';


@Directive()
export abstract class KalgudiProfile extends KalgudiDestroyable {

  profileDetails: KalgudiUser;

  editable: boolean;

  sellerConfigDetails: KalgudiSellerConfig;

  protected profileKey = new BehaviorSubject<string>('');


  protected profileService: KalgudiProfileService;
  protected profileState: KalgudiProfileStateService;
  private notification: KalgudiNotification;
  private users: KalgudiUsersService;
  private kalgudiProfiles: KalgudiProfileService;
  public env: KalgudiEnvironmentConfig;
  private app: KalgudiAppService;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService
  ) {

      super();

      this.notification     = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
      this.users            = this.injector.get(KalgudiUsersService);
      this.kalgudiProfiles  = this.injector.get(KalgudiProfileService);
      this.profileState     = this.injector.get(KalgudiProfileStateService);
      this.app              = this.injector.get(KalgudiAppService);
      this.env              = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
      this.profileService   = this.injector.get(KalgudiProfileService);

      this.profileService.editable$
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe(
          res => this.editable = res
        );
  }


  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods for child
  // --------------------------------------------------------


  /**
   * Uploads the file to the s3
   * @param fileEvent
   */
  uploadProfilePic(fileEvent: any) {

    if (fileEvent.target.files && fileEvent.target.files[0]) {

      const path = KALGUDI_S3_POLICY_MAP.PROFILE_PIC;

      this.notification.showSpinner();

      this.profileService.updateProfilePic(fileEvent.target.files[0], path)
        .pipe(
          finalize(() => this.notification.hideSpinner())
        )
        .subscribe(
          res => this.onProfilePicUpdate(res),
          err => this.util.errorHandler('Server error, please try again later')
        );
    }
  }

  /**
   * Gets seller config details
   */
  getSellerConfigDetails() {
    this.profileService.getSellerConfigDetails(this.profileDetails.profileKey)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.sellerConfigDetails = res
      );
  }

  /**
   * Uploads the file to the s3 and updates the profile coverPic
   * @param fileEvent
   */
  uploadCoverPic(fileEvent: any) {

    if (fileEvent.target.files && fileEvent.target.files[0]) {

      const path = KALGUDI_S3_POLICY_MAP.COVER_PIC;

      this.notification.showSpinner();

      this.profileService.updateCoverPic(fileEvent.target.files[0], path)
        .pipe(
          finalize(() => this.notification.hideSpinner())
        )
        .subscribe(
          res => this.onProfilePicUpdate(res),
          err => this.util.errorHandler(err)
        );
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected method
  // --------------------------------------------------------


  /**
   * Called after profile pic is updated successfully.
   */
  protected onProfilePicUpdate(res: KalgudiUser): void {}

  /**
   * Event handler called on profile state update
   */
  protected onProfileStateUpdate(action: { type: ProfilePageActions; payload?: any; }): void { }


  /**
   * Initializes profile subscriptions
   */
  protected init(): void {

    // Initialize profile details
    this.profileState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(profile => this.onProfileUpdated(profile));


    // Subscribe to profile page state change
    this.subscribeToStateActions();
    this.getSellerConfigDetails();
  }

  /**
   * Event handler gets called on the profile details update in the
   * state service
   */
  private onProfileUpdated(profile: KalgudiUser): void {
    this.profileDetails = profile;
  }


  /**
   * Subscribes to profile state action updates and calls the necessary methods.
   */
  public subscribeToStateActions(): void {

    this.profileState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === ProfilePageActions.UPDATE_PROFILE_PIC) {

          this.uploadProfilePic(action.payload);
        }

        if (action.type === ProfilePageActions.COVER_PIC_UPDATE) {
          this.uploadCoverPic(action.payload);
        }

        this.onProfileStateUpdate(action);
      });
  }


  /**
   * Updates profile details in the state
   */
  private updateProfileDetails(profile: KalgudiUser): void {

    this.profileState.dispatchAction(ProfilePageActions.PROFILE_UPDATED, profile);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
