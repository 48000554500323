import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { AppRoutes } from '@app/constants';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { DEFAULT_PROFILE_PIC, kalgudiAnimations } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'core-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDetailsComponent implements OnInit {

  @Input()
  user: KalgudiUser;

  @Input()
  profileViewsCount: number;

  @Input()
  connectsCount: number;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  viewProfilePage: string;

  domain: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    this.viewProfilePage = AppRoutes.APP_PROFILE_VIEWS;

    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {

  }

  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey});
  }

}
