import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smartElements'
})
export class SmartElementsPipe implements PipeTransform {

  transform(product: any, args?: any[]): string {

    let smartElement = '';

    if(product.smartElements) {

      const elements : any = Object.entries(product.smartElements);

      let enabledElements = [];
      elements.forEach((element, index) => {
        if (element[1].enabled) {
          enabledElements.push(element)
        }
      })

      enabledElements.forEach((e,index) => {

        if(e[0] === 'dimensions') {
          smartElement = smartElement + (e[1].width.enabled ? e[1].width.value + '(W)' : '')
                                      + ((e[1].width.enabled && (e[1].length.enabled || e[1].height.enabled || e[1].circumference.enabled)) ? ' x ' : '')
                                      + (e[1].length.enabled ? e[1].length.value + '(L)' : '')
                                      + ((e[1].length.enabled && (e[1].height.enabled || e[1].circumference.enabled)) ? ' x ' : '')
                                      + (e[1].height.enabled ? e[1].height.value + '(H)' : '')
                                      + ((e[1].height.enabled && e[1].circumference.enabled) ? ' x ' : '')
                                      + (e[1].circumference.enabled ? e[1].circumference.value + '(C)' : '')
                                      + (((index !== (enabledElements.length - 1)) && (e[1].length.enabled || e[1].height.enabled || e[1].circumference.enabled)) ? ', ' : '')
        }

        if(e[0] !== 'dimensions'){
          smartElement = smartElement + (e[1].enabled ? ((e[1].value ? e[1].value : '') + (e[1].unit && e[1].value ? " " : '') + (e[1].unit ? e[1].unit : '')) : '')
                                      + (index !== (enabledElements.length - 1) && e[1].enabled ? ', ' : '');
        }
      });
    }


    let productTitle = product.productLevel3Title ? product.productLevel3Title + (smartElement ?  ' ['+ smartElement + ']' : '') : smartElement.length ? '['+ smartElement + ']' : ' ';

    return productTitle;
  }

}
