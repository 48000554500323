import { Injector } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSurveyDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiSurveysRouteConfig } from '../../../config';
import { SurveysListService } from '../services/surveys-list.service';


export abstract class KalgudiMySurveyList {

  mySurveys$: Observable<{ createdSurveys: KalgudiSurveyDetails[], followingSurveys: KalgudiSurveyDetails[] }>;

  private surveyListService: SurveysListService;
  private appRouting: KalgudiSurveysRouteConfig;

  constructor(
    protected injector: Injector
  ) {

    this.surveyListService = this.injector.get(SurveysListService);
    this.appRouting        = this.injector.get<KalgudiSurveysRouteConfig>(KL_ROUTE_CONFIG);

    this.mySurveys$ = this.surveyListService.getMySurveys('');
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   *  Navigates to survey list page
   */
  openSurveyListPage() {
    this.appRouting.toSurveyList();
  }

  /**
   * Navigates to survey full view
   * @param surveyId
   */
  openSurveyFullview(surveyId: string): void {
    this.appRouting.toSurveyFullView({ surveyId });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
