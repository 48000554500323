import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiDialogsModule, KalgudiNoContentModule, KalgudiUserPickerModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import { CreateStockComponent } from './components/create-stock/create-stock.component';
import { KalgudiStockListComponent } from './components/kalgudi-stock-list/kalgudi-stock-list.component';
import { StockCreationDialogComponent } from './components/stock-creation-dialog/stock-creation-dialog.component';
import {
  StockCreationMobileDialogComponent,
} from './components/stock-creation-mobile-dialog/stock-creation-mobile-dialog.component';
import { StockStateService } from './services/stock-state.service';
import { StocksActionService } from './services/stocks-action.service';
import { StocksApiService } from './services/stocks-api.service';
import { StocksService } from './services/stocks.service';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    KalgudiStockListComponent,
    CreateStockComponent,
    StockCreationDialogComponent,
    StockCreationMobileDialogComponent,
  ],
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,

    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiDialogsModule,
    KalgudiUserPickerModule,
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiPipesModule,

    TranslateModule
  ],
  exports: [
    KalgudiStockListComponent
  ],
  providers: [
    StocksApiService,
    StocksService,
    StocksActionService,
    StockStateService
  ],
  entryComponents: [
    StockCreationDialogComponent,
    StockCreationMobileDialogComponent
  ]
})
export class KalgudiPageStocksModule { }
