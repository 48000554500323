import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  OnInit,
  Provider,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiPageAutocomplete } from './page-type-autocomplete-class';

// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PageTypeAutocompleteComponent),
  multi: true,
};


@Component({
  selector: 'kl-page-type-autocomplete',
  templateUrl: './page-type-autocomplete.component.html',
  styleUrls: ['./page-type-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})
export class PageTypeAutocompleteComponent extends KalgudiPageAutocomplete implements OnInit {


  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {
    super(injector, fb);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
