import { Injector, Input, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiStoreProduct } from '@kalgudi/types';
import { filter, takeUntil } from 'rxjs/operators';

import { StoreProductsSearchService } from '../services/store-products-search.service';

@Directive()
export abstract class KalgudiStoreProductsFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  disabled = false;

  @Input()
  showSelectedProductTiles = true;

  @Input()
  buttonIcon: string;

  @Input()
  buttonLabel = 'Add product';

  @Input()
  allowMultiple = false;

  @Input()
  storeType: string;

  productList: KalgudiStoreProduct[] = [];

  // Dependencies
  private storeProductsSearchService: StoreProductsSearchService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Manually inject dependencies
    this.storeProductsSearchService = this.injector.get(StoreProductsSearchService);

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Form control accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

    if (obj) {
      this.productList = obj;
    } else {
      this.resetProductList();
    }
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Opens picker dialog box to pick the product
   */
  onAddProductClicked(): void {

    // Input dialog UI configuration
    const { dialogDetails, dialogConfig } = this.getDialogConfiguration();

    // Open the store product dialog
    this.storeProductsSearchService.showStoreProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),
        filter(res => res && res.accepted)
      )
      .subscribe(
        res => {
          this.updateFormControl(res.data.products);
        }
      );
  }

  /**
   * Removes an product from the product list
   */
  removeProduct(index: number): void {

    if (index > this.productList.length + 1) {
      throw new Error('Could not remove product. Invalid index!');
    }

    this.productList.splice(index, 1);

    this.updateFormControl(this.productList);

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Prepares kalgudi default dialog configuration
   */
  protected getDialogConfiguration(): { dialogDetails: KalgudiDialogConfig, dialogConfig: MatDialogConfig } {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Suggest products',
      acceptButtonTitle: 'Send',
      rejectButtonTitle: 'Cancel',
      data: {
        storeType: this.storeType
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return { dialogDetails, dialogConfig };
  }

  /**
   * Updates the products parent form control value with the latest product
   * object.
   */
  private updateFormControl(products: KalgudiStoreProduct[]): void {
    this.onChange(products);
    this.onTouched();
  }

  /**
   * Resets the products list
   */
  private resetProductList(): void {
    this.productList = [];
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
