import { Injectable } from '@angular/core';
import { ChangePassword } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiChangePasswordApiService } from './kalgudi-change-password-api.service';

@Injectable()
export class KalgudiChangePasswordService {

  constructor(private api: KalgudiChangePasswordApiService) { }

  /**
   * Calls api to change the password
   */
  changePassword(payload: ChangePassword, profileKey: string): Observable<boolean> {

    return this.api.changePassword(payload, profileKey);
  }
}
