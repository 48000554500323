<div class="page-survey-fill" *ngIf="pageSurvey$ | async as pageSurvey">

  <div [formGroup]="assistedToFormGroup" *ngIf="pageSurvey?.page as page">
    <kl-surveys-members-picker formControlName="assistedTo"
      *ngIf="page?.memberRole === memberRoles?.ADMIN || page?.memberRole === memberRoles?.CONTRIBUTOR">
    </kl-surveys-members-picker>
  </div>

  <kl-survey-submission-form
    [userProfileKey]="assistedToValue?.assistedTo?.profileKey"
    [extraPayload]="assistedToValue">
  </kl-survey-submission-form>

</div>
