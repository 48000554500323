import { Inject, Input, Directive } from '@angular/core';
import { KalgudiUsersPickerBase } from '@kalgudi/common';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { StringAnyMap } from '@kalgudi/types';
import { EMPTY, Observable } from 'rxjs';

import { KalgudiShareSearchMembersService } from '../../../services/kalgudi-share-search-members.service';

/**
 * My kalgudi connects search picker. It searches for all connected users in the kalgudi database.
 * @see KalgudiUsersPickerBase
 * @see KalgudiSearchStream
 * @see KalgudiStream
 */
@Directive()
export abstract class KalgudiShareMembersPicker extends KalgudiUsersPickerBase {

  @Input()
  pageId: string;

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected api: KalgudiShareSearchMembersService,
  ) {

    // Wake up (initialize) parent
    super(notification, util);

    this.initStream();
  }

  /**
   * Defines the Api call for my kalgudi connects. If the search keyword is provided then it calls
   * the Api to search my connects otherwise it loads the list of all my connects.
   *
   * It chooses the Api call based on the search keyword.
   */
  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserBasicSearchResults> {

    const params = {
      pageId: this.pageId ? this.pageId : '',
      taskId: this.taskId ? this.taskId : '',
      projectId: this.projectId ? this.projectId : '',
    }

    extraParams = extraParams ? { ...extraParams, ...params } : params ;

    return (this.pageId || this.taskId || this.projectId)
      ? this.api.searchMembers( searchKeyword, offset, limit, extraParams)
      : EMPTY// Don't call the search Api if page id is not present
  }
}
