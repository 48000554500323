import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { KalgudiMobileDialogRef } from "@kalgudi/common";
import { KalgudiNotification, KL_NOTIFICATION } from "@kalgudi/core/config";
import { KalgudiRobocall, KalgudiSms } from "@kalgudi/types";

import { KalgudiRobocallStreamStateService } from "../../services/kalgudi-robocall-stream-state.service";

@Component({
  selector: "kl-mobile-publish-robocall",
  templateUrl: "./mobile-publish-robocall.component.html",
  styleUrls: ["./mobile-publish-robocall.component.scss"],
})
export class MobilePublishRobocallComponent implements OnInit {
  @Output()
  robocallSent = new EventEmitter<KalgudiSms>();

  constructor(
    protected robocallStreamState: KalgudiRobocallStreamStateService,
    private dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification
  ) {}

  ngOnInit() {}

  onRobocallSent(robocall: KalgudiRobocall): void {
    this.robocallSent.emit(robocall);
    if (robocall && !robocall.scheduledTS) {
      this.pushItemToHomeStream(robocall);
    } else {
      this.notifications.showMessage(`Robocall initiated`);
    }
    this.dialogRef.close();
  }

  /**
   * Pushes latest posted item to the stream
   * @param robocall
   */
  private pushItemToHomeStream(robocall: KalgudiRobocall): void {
    this.robocallStreamState.unshiftToStream(robocall);
  }
}
