import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class KalgudiTaskStats extends KalgudiDestroyable {

  @Input()
  taskId: string;

  taskMembersList: any;
  progress: boolean;

  private kalgudiTaskService: KalgudiTasksService;
  private notification: KalgudiNotification;


  constructor(protected injector: Injector) {
    super();

    this.kalgudiTaskService = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
    this.notification       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
  }


  /**
   * gets task member stats
   */
  getTaskMemberStats(taskId) {

    this.progress = true;

    this.kalgudiTaskService.getTaskMemberStats(taskId)
      .pipe(
        finalize(() => this.progress = false),
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.taskMembersList = res;
        }
      )
  }
}
