<div id="kl-survey-add-question">
  <form autocomplete="off" [formGroup]="questionForm">
    <mat-form-field appearance="outline">
      <mat-label translate>Question type</mat-label>
      <mat-select formControlName="questionType">
        <mat-option *ngFor="let type of isEnableMultiCheck ? questionTypes : questionTypes.slice(0, -1)" [value]="type?.id">
          <span>
            {{type?.value | translate}}
        </span>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="questionTypeField?.value">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label translate>Question title</mat-label>
        <textarea
          rows="2"
          matInput
          required
          [placeholder]="'Write your question title here' | translate"
          formControlName="question">
        </textarea>
        <mat-error class="text-right"  *ngIf="!questionField.valid">
          {{ (questionForm.controls['question'].hasError('minlength') ? 'Minimum of 10 characters' : 'Please enter the title') | translate }}
        </mat-error>
      </mat-form-field>
      <div [ngClass]="{'mt-0': attachmentsArray?.value?.length, 'mt-n3': !attachmentsArray?.value?.length}" fxLayout>
        <!-- <button
          [title]="'Attach image' | translate"
          type="button"
          mat-icon-button
          fxLayoutAlign="center center"
          (click)="attachImage(attachmentsArray?.value)">
          <mat-icon class="image">photo</mat-icon>
        </button> -->

        <kl-image-picker-form-control formControlName="attachments"
          [allowMultiple]="true"
          [showAttachments]="false"
          [s3Category]="s3Category"
          [acceptedFileTypes]="acceptedFileTypes">
        </kl-image-picker-form-control>

        <!-- Image attachments -->
        <div class="ml-2 mt-n2">
          <kl-image-chip-list
            [attachments]="attachmentsArray?.value"
            (remove)="removeImage($event)">
          </kl-image-chip-list>
        </div>
        <!-- / Image attachments -->
      </div>
      <ng-container *ngIf="questionTypeField?.value === availableQuestionTypes?.MULTIPLE_CHOICE || questionTypeField?.value === availableQuestionTypes?.MULTIPLE_SELECT_CHECKBOX">
        <p class="text-right mb-0">
        <span translate>Options count</span> {{ optionArray.controls?.length }}
        </p>
        <div class="options">
          <div
            class="pr-2"
            formArrayName="options"
            *ngFor="let a of optionArray.controls; let i = index">
            <div [formGroupName]="i">
              <div fxLayout fxLayoutGap="12px">
                <div fxFlex>
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label for="title"><span translate>Option</span>{{ i + 1 }}</mat-label>
                    <input matInput formControlName="value" required />
                    <mat-icon
                      class="remove-option cursor-pointer"
                      matSuffix
                      (click)="removeOption(i)">
                      clear
                    </mat-icon>
                    <mat-error class="text-right" translate>Please enter option title</mat-error>
                  </mat-form-field>
                </div>
                <!-- <div fxFlex>
                  <button class="clear-btn" mat-flat-button>
                    <mat-icon>clear</mat-icon>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4" fxLayout fxLayoutAlign="start end">
          <div class="submit-btn">
            <button mat-icon-button (click)="addOption()">
              <mat-icon color="primary">add</mat-icon>
            </button>
            <!-- <kl-link-button
                [type]="'button'"
                [label]="'Add option'"
                (clicked)="addOption()">
              </kl-link-button> -->
          </div>
        </div>
      </ng-container>

      <div fxLayout fxLayoutAlign="end end">
        <div class="submit-btn">
          <kl-flat-button
            [type]="'submit'"
            [label]="'Add'"
            [progress]="progress"
            [disabled]="!questionForm?.valid"
            (clicked)="addQuestion()">
          </kl-flat-button>
        </div>
      </div>
    </div>
  </form>
</div>
