<ol class="survey-analytics-chart w-100 p-0 m-0">
  <li class="question w-100 d-inline-block position-relative" *ngFor="let item of (surveyAnalytics | objectValues)">
    <mat-card class="my-1 p-2 question d-inline-block position-relative">

      <h3 class="question-type mb-1">{{ questionTitleMap[item?.question?.questionType] | translate }}</h3>
      <p class="question-title mb-1">{{ item?.question?.question }}</p>

      <div fxLayout fxLayoutAlign="center center">
        <ngx-charts-pie-chart
          [view]="view"
          [scheme]="colorScheme"
          [results]="item?.analytics"
          [gradient]="gradient"
          [legend]="showLegend"
          [legendPosition]="legendPosition"
          [labels]="showLabels"
          [doughnut]="isDoughnut">
        </ngx-charts-pie-chart>
      </div>
    </mat-card>
  </li>
</ol>

<ng-container *ngIf="!surveyAnalytics">
  <kl-no-content
    svgIcon="assets/svgs/survey-analytics.svg"
    text="No analytics to show!"
    progressText="Loading analytics"
    [progress]="progress">
  </kl-no-content>
</ng-container>
