<section class="mb-1 land-details"  *ngIf="profile?.farmerLandDetails?.lands && profile?.farmerLandDetails?.lands?.length">

  <div fxLayout="row wrap" fxLayoutGap="8px" fxLayoutGap.xs="0px">
    <div fxLayout="column" fxFlex.gt-md="0 0 calc(25% - 8px)" fxFlex.gt-xs="0 0 calc(50% - 8px)" fxFlex.gt-sm="0 0 calc(33.3% - 8px)" fxFlex.xs="100%"
      *ngFor="let details of profile?.farmerLandDetails?.lands.slice().reverse() | slice: 0: isLandTiles  ? profile?.farmerLandDetails?.lands?.length : (isMobileDevice ? 2: 4); let last = last; let i = index;"
      (click)="toShareUpdateFullview(details?.shareId)"
      class="p-2 tile"
      [ngClass]="{'mr-2': !isMobileDevice}">

      <div class="w-100" fxLayout="row" fxLayoutGap="5px">
        <div fxLayoutAlign="start center" fxFlex="calc(100% - 30px)">
          <span  class="pt-1 land-name text-truncate" *ngIf="details?.landName">
            {{ details?.landName | titlecase }}
          </span>
        </div>

        <div fxLayoutAlign="end end" fxFlex="25px" *ngIf="editable">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="options" (click)="$event.stopPropagation()">
            <mat-icon>more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md chat" fxLayout="column">
            <button class="edit-button px-1" mat-menu-item (click)="showAddLandDetailsDialog(details)">
              <div fxLayout fxLayoutAlign="start center" class="ml-2">
                <mat-icon class="land-edit rounded-circle text-secondary" fxLayoutAlign="center center">edit</mat-icon>
                <span class="text-secondary font-weight-normal text" translate>Edit</span>
              </div>
            </button>
            <button class="delete-button px-1" mat-menu-item [matTooltip]="'Remove land details' | translate" (click)="deleteLandDetailsDialog(details?.landId)">
              <div fxLayout fxLayoutAlign="start center" class="ml-2">
                <mat-icon class="land-edit rounded-circle text-secondary" fxLayoutAlign="center center">delete</mat-icon>
                <span class="text-secondary font-weight-normal text" translate>Delete</span>
              </div>
            </button>
          </mat-menu>
        </div>
      </div>

      <div class="px-1 pb-1 w-100"  fxLayout="row" fxLayoutGap="6px">

        <div fxLayout="row" fxLayoutGap="6px" [fxFlex]="details?.shareId ? 'calc(100% - 136px)' : 'calc(100% - 68px)'">
          <div class="land-size ml-0" fxLayoutAlign="start center">
            <span class="land font-weight text-secondary">{{ details?.landSize?.value | number:'1.0-2' }}</span>
            <span class="sub-script text-secondary">{{ details?.landSize?.unit | translate }}</span>
          </div>

          <div class="location-info" fxFlex='calc(100% - 75px)' fxLayoutAlign="start center">
            <mat-icon class="location-icon text-secondary" *ngIf="details?.location?.placeName || details?.location?.stateName">location_on</mat-icon>
            <span class="text-secondary text-truncate state-name" [matTooltip]="details?.location?.placeName || details?.location?.stateName">{{ details?.location?.placeName || details?.location?.stateName }}</span>
          </div>
        </div>

        <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="end end" [fxFlex]="details?.shareId ? '130px' : '62px'">
          <div fxLayoutAlign="start center" class="advisories-btn mb-0 cursor-pointer" (click)="toShareUpdateFullview(details?.shareId)" *ngIf="details?.shareId">
            <span translate>Advisories</span>
          </div>

          <div class="line text-secondary" *ngIf="details?.shareId">|</div>

          <div fxLayoutAlign="start center" class="advisories-btn mb-0 cursor-pointer" (click)="landDetailsDialog(details);$event.stopPropagation()">
            <span translate>Land details</span>
          </div>
        </div>

      </div>

      <div class="pl-1 mt-n1 soil-details" *ngIf="details?.soilHealthCard && details?.soilHealthCard?.length">
        <svg-icon [applyCss]="true" class="text-center mr-1" src="assets/svgs/attach-file/file-1.svg"
          [svgStyle]="{'width.px': 11, 'height.px': 11}"></svg-icon>
        <a class="soil-info" [href]="details?.soilHealthCard[0]?.url | prefixDomain" download>Soil health card</a>
      </div>
    </div>

    <!-- view more -->
    <div class="view-more-btn" fxLayout fxLayoutAlign="end end" *ngIf="isMobileDevice ? profile?.farmerLandDetails?.lands?.length > 2 : profile?.farmerLandDetails?.lands?.length > 4" [ngClass]="{'d-none': isLandTiles}">
      <span class="view-all" (click)="viewMoreTiles()" translate>View all</span>
      <mat-icon class="arrow-symbol">keyboard_arrow_down</mat-icon>
    </div>
    <!-- /view more -->

    <!-- Hide button -->
    <div class="view-more-btn" fxLayoutAlign="start end" (click)="viewMoreTiles()" *ngIf="isLandTiles">
      <span class="view-all"  translate>Hide</span>
      <mat-icon class="arrow-symbol">keyboard_arrow_up</mat-icon>
    </div>
    <!-- /Hide button -->
  </div>
</section>

