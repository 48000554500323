<mat-card class="my-3 surveys-list-header">
  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayout.xs="column" fxLayoutGap.xs="10px">
    <!-- Content -->
    <div fxLayout="column" class="w-100">

      <div class="mb-2">
        <kl-breadcrumbs></kl-breadcrumbs>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="10px" class="mb-1 w-100">

        <div fxLayout="row">
          <div [@scaleOut]>
            <svg-icon src="assets/svgs/surveys-icon.svg"
              [svgStyle]="{'width.px': '35', 'height.px': '35'}"
              [applyCss]="true"
              class="mr-3 surveys-icon">
            </svg-icon>
          </div>

          <div [@slideRight] class="ml-1 mr-3">
            <h3 class="mb-0" translate>Surveys</h3>
          </div>

        </div>

        <div class="w-100" fxLayout="row" fxLayoutGap.xs="5px" fxLayoutAlign="space-between center">
          <button mat-stroked-button color="primary" class="add-survey-button" (click)="create()">
            <mat-icon class="add-icon mr-2">add_circle</mat-icon>
            <span translate>Create</span>
          </button>

          <!-- Filters -->
          <div>
            <button fxLayout fxLayoutAlign="center center" mat-button [matMenuTriggerFor]="menu" color="primary">
              <svg-icon [src]="selectedFilter?.svgIcon"
                [svgStyle]="{'width.px': '16', 'height.px': '16'}"
                [applyCss]="true"
                class="filter-icon">
              </svg-icon>
              <span class="ml-2 text-muted mb-0">{{selectedFilter?.title | translate}}</span>
            </button>

            <mat-menu #menu="matMenu" class="mat-menu-md" >
              <div *ngFor="let filter of filterTypes">
              <button [ngClass]="{'status' : selectedFilter?.id === filter?.id }" mat-menu-item (click)="selectTab(filter)">
                <div fxLayout>

                  <svg-icon [applyCss]="true"
                    [src]="filter?.svgIcon"
                    [svgStyle]="{'width.px': '19', 'height.px': '19'}"
                    class="filter-gray-icon mr-2" >
                  </svg-icon>

                  <span translate> {{ filter?.title }}</span>
                </div>
              </button>
              </div>
            </mat-menu>

          </div>
          <!-- /Filters -->

        </div>

      </div>

    </div>
    <!-- /Content -->
  </div>
</mat-card>

