import { Directive, Inject } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { EMPTY, merge, Observable, timer } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { KalgudiUtilityService } from '../services/kalgudi-util.service';
import { KalgudiStream } from './kalgudi-stream';

/**
 * Extends the base Kalgudi Stream. Use this class for any stream that has only
 * load more functionality.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiInboxStream<T> extends KalgudiStream<T> {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);


    // Subscribe to the page change events
    // Subscribing will cause the stream to load.
    merge(
      this.pageChange$,
      this.resetStream$,
    )
    .pipe(

      // Subscribe till the instance is alive
      takeUntil(this.destroyed$),

      // Always process if there is a valid paginator value
      filter(_ => this.paginatorValue !== null),

      // Fetch latest stream items and transform the response
      switchMap(_ => this.fetchStreamItems(this.paginatorValue)))

      .subscribe();


    // Subscribe to the new stream items
    // A delay is required so that its child get initialized
    // Child initialization is required because the `newStreamItem$`
    // observable will be defined by its child
    timer(1000)
      .pipe(

        // Ensure to take only 1 value
        take(1),
      )
      .subscribe(_ => {
        this.newStreamItem$
          .pipe(

            // Subscribe till the instance is alive
            takeUntil(this.destroyed$),
          )
          .subscribe(r => this.unshiftItem(r));
      });

  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<T> {
    return EMPTY;
  }
}
