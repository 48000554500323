import { Observable } from "rxjs";

import { IdValueMap, PartialData } from "./core.model";
import {
  KalgudiStoreProduct,
  StoreBaseProduct,
} from "./product-catalogue.model";
import { Attachment } from "./social.model";
import { KalgudiLocation, KalgudiUser } from "./user.model";

export interface KalgudiDialogConfig {
  title: string;
  message?: string;
  matIcon?: string;
  faIcon?: string;
  iconColor?: string;
  acceptButtonTitle?: string;
  rejectButtonTitle?: string;

  data?: PartialData;

  [key: string]: any;
}

export interface KalgudiImageDialogConfig extends KalgudiDialogConfig {
  data?: KalgudiImageDialogData;
}

export interface KalgudiUsersPickerDialogConfig extends KalgudiDialogConfig {
  initialSearchKeyword?: string;
  multiSelect?: boolean;
  extraParams?: PartialData;
  data?: KalgudiUsersMap;
}

export interface KalgudiPageMembersPickerDialogConfig
  extends KalgudiUsersPickerDialogConfig {
  pageId: string;
}

export interface KalgudiUsersMap {
  [key: string]: KalgudiUser;
}

export interface KalgudiSkuPickerDialogConfig extends KalgudiDialogConfig {
  initialSearchKeyword?: string;
  multiSelect?: boolean;
  extraParams?: PartialData;
  data?: KalgudiSkuMap;
}

export interface KalgudiSkuMap {
  [key: string]: KalgudiStoreProduct;
}

export interface KalgudiBaseProductPickerDialogConfig
  extends KalgudiDialogConfig {
  initialSearchKeyword?: string;
  multiSelect?: boolean;
  extraParams?: PartialData;
  data?: KalgudiBaseProductMap;
}

export interface KalgudiBaseProductMap {
  [key: string]: StoreBaseProduct;
}

export interface KalgudiImageDialogData {
  s3Category?: S3PolicyPathCategoryMap;
  attachments?: Attachment[];
  multiple?: boolean;
  maxImages?: number;
  acceptFileTypes?: FileMimeTypes[] | string;
  showCamera?: boolean;
  showAddOnLast?: boolean;
}

export enum FileMimeTypes {
  IMAGE = "image/*",
  DOCUMENT = "application/*",
  AUDIO = "audio/*",
  VIDEO = "video/*",
  DOCUMENT_PDF = "application/pdf",
  AUDIO_MP3 = '.mp3',
  AUDIO_AAC = '.aac',
  AUDIO_M4R = '.m4r'
}

export interface KalgudiInputDialogConfig extends KalgudiDialogConfig {
  inputPlaceHolder?: string;
  multiLineInput: boolean;
  inputText?: string;
}

export interface KalgudiInputDialogResult {
  inputText: string;
}

export interface KalgudiDialogResult {
  accepted?: boolean;
  data: PartialData;
}

export interface KalgudiImageDialogResult extends KalgudiDialogResult {
  data: KalgudiImageDialogDataResult;
}

export interface KalgudiImageDialogDataResult {
  attachments: Attachment[];
}

export interface S3PolicyMap {
  SHARE: S3PolicyPathCategoryMap;
  QA: S3PolicyPathCategoryMap;
  STORE_PRODUCTS: S3PolicyPathCategoryMap;
  PROFILE_PIC: S3PolicyPathCategoryMap;
  COVER_PIC: S3PolicyPathCategoryMap;
  BUSINESS: S3PolicyPathCategoryMap;
  DEFAULT: S3PolicyPathCategoryMap;
  IMAGE_SEARCH: S3PolicyPathCategoryMap;
  VOICE_SEARCH: S3PolicyPathCategoryMap;
  ROBOCALL: S3PolicyPathCategoryMap;
}

export interface S3PolicyPathCategoryMap {
  category: string;
  path: string;
}

export interface S3UploadToken {
  signature: string;
  policy: string;
  accesskey?: string;
}

export interface Paginator {
  offset: number;
  limit: number;
  count?: number;
  loading?: boolean;
  loading$?: Observable<boolean>;
  hasItems?: boolean;
  keyword?: string;
  includePageTypes?: string;
  searchProperties?: any;
  fromDate?: string;
  toDate?: string;
}

/**
 * UI route query params
 */
export interface RouteQueryParams extends PartialData {}

export interface GooglePlace {
  street_number?: string;
  route?: string;

  administrative_area_level_1?: string;
  administrative_area_level_2?: string;
  administrative_area_level_3?: string;

  sublocality_level_1?: string;
  sublocality_level_2?: string;

  locality?: string;
  country?: string;
  postal_code?: string;

  latitude?: string | number;
  longitude?: string | number;
}

export interface GoogleLocationAutocomplete {
  kalgudiLocation: KalgudiLocation;
  googlePlace: GooglePlace;
}

export interface ProgramTabs {
  title: string;
  index: number;
  icon: string;
  selected?: boolean;
  iconRotate: string;
  options: ProgramTabOptions[];
  hide?: boolean;
}

export interface ProgramTabOptions {
  id?: string;
  title: string;
  desc: string;
  svgIcon: string;
  fillColor: string;
  index: number;
  iconColor: string;
  iconSize?: string;
  routerLink: string;
  disable?: boolean;
  hide?: boolean;
  actionList?: ActionListOptions[];
  badgeCount?: number;
}

export interface ActionListOptions {
  action?: boolean;
  actionIcon?: string;
  actionIconText?: string;
  actionType?: any;
}
export interface StepperDetails {
  label: string;
  icon: string;
  iconName: string;
  index: number;
  selected?: boolean;
}

export interface BottomSheetMenu {
  title: string;
  styleType: "list" | "icon-button";
  menu?: UIMenu[];
  showCloseButton?: boolean;
}

export interface SelectableChip extends IdValueMap {
  selected: boolean;
}

export declare type SelectableChipList = Array<SelectableChip>;

export interface UIMenu {
  id?: string;
  title?: string;
  matIcon?: string;
  defaultProfilePic?: string;
  faIcon?: string;
  iconColor?: string;
  svgIcon?: string;
  toolTip?: string;
  subMenu?: UIMenu[];
  routerLink?: string;
  queryParams?: {
    [key: string]: string;
  };
  aLink?: string;
  selected?: boolean;
  visible?: boolean;
  hide?: boolean;
  disabled?: boolean;
  iconSize?: number;
  hideMobile?: boolean;
  clickAction?: any;
}

export interface SelectableUIMenu extends UIMenu {
  desc?: string;
}

export interface KalgudiChartItem {
  title: string;
  value?: number;
  color?: string; // Color code
  bgColor?: string; // Color code
  textAlign?: TextAlignment;
  progress?: number;
}

export declare type KalgudiChart = Array<KalgudiChartItem>;

export declare type ChartDirection = "vertical" | "horizontal";

export declare type UIElementSize = "sm" | "md" | "lg";

export declare type TextAlignment = "left" | "right" | "center" | "justify";

export interface Breadcrumb {
  title: string;
  routerLink: string;
}

export declare type BreadcrumbList = Array<Breadcrumb>;

export enum ProjectViewType {
  GRID = "GRID",
  LIST = "LIST",
  CALENDAR = "CALENDAR",
}

export interface StateManagerAction<A> {
  type: A;
  payload?: any;
}

export interface WidgetItem extends UIMenu {
  count: number;
}

export declare type WidgetItemList = Array<WidgetItem>;

export interface KalgudiAIPredictions {
  results?: KalgudiAiPredictionsList;
  stats?: KalgudiAiPredictionsStats;
}

export interface KalgudiAiPredictionsList {
  informationList?: KalgudiAiPredictionsInformationList[];
  inputList?: KalgudiAiPredictionsInputList[];
  outputList?: KalgudiAiPredictionsOutputList[];
}

export interface KalgudiAiPredictionsInformationList {
  fromDate?: string;
  info?: string[];
  description?: string;
  title?: string;
  toDate?: string;
  hide?: boolean;
}

export interface KalgudiAiPredictionsInputList {
  items?: AIPredictionsInputList[];
  title?: string;
  hide?: boolean;
}

export interface AIPredictionsInputList {
  cropStage?: string;
  cropStageId?: string;
  fromDate?: string;
  id?: string;
  productName?: string;
  requirement?: {
    quantity?: string;
    unit?: string;
    cost?: number;
    timeInHours?: number;
  };
  tagData?: {
    cost?: number;
    needType?: string;
    quantityPerAcre?: {
      value?: string;
      unit?: string;
    };
    perUnitQuantity?: number;
    noOfAcres?: number;
    noOfHourPerAcre?: number;
    dosesPerAcre?: {
      unit?: string;
      value?: string;
    };
  };
  toDate?: string;
}

export interface KalgudiAiPredictionsOutputList {
  cost?: number;
  productId?: string;
  productName?: string;
  requirement?: string;
  title?: string;
  unit?: string;
  tagData?: {
    cost?: number;
    expectedYieldPerAcre?: {
      unit?: string;
      value?: string;
    };
  };
}

export interface KalgudiAiPredictionsStats {
  acres?: number;
  districts?: number;
  farmers?: number;
  fpo?: number;
  mandals?: number;
  states?: number;
}

export const AvailableDomains = {
  APGREENS: "https://andhragreens.com",
  CORE: "https://kalgudi.com",
  INPUTS: "https://inputs.kalgudi.com",
  FARM_STORE: "https://inputs.kalgudi.com",
  OUTPUTS: "https://outputs.kalgudi.com",
  BIZ_STORE: "https://outputs.kalgudi.com",
  TGREENS: "https://tgreens.in",
  KUBER: "https://app.kuber.farm/app/store",
  "https://andhragreens.com": "https://andhragreens.com",
  "https://kalgudi.com": "https://kalgudi.com",
  "https://inputs.kalgudi.com": "https://inputs.kalgudi.com",
  "https://outputs.kalgudi.com": "https://outputs.kalgudi.com",
  "https://tgreens.in": "https://tgreens.in",
  "https://app.kuber.farm": "https://app.kuber.farm/app/store",
  "https://kuber.farm": "https://app.kuber.farm/app/store",
  "https://emahila.org": "https://emahila.org",
  "https://shaktimanfarmingsolutions.com":
    "https://shaktimanfarmingsolutions.com",
  "https://prorisestore.com": "https://prorisestore.com",
  "https://marketplace.kalgudi.com": "https://marketplace.kalgudi.com",
  "https://sikkimgreens.com": "https://sikkimgreens.com",
};

export const DevAvailableDomains = {
  APGREENS: "https://devkalgudi.vasudhaika.net/apgreens",
  CORE: "https://devkalgudi.vasudhaika.net/core",
  INPUTS: "https://devkalgudi.vasudhaika.net/farmer",
  FARM_STORE: "https://devkalgudi.vasudhaika.net/farmer",
  OUTPUTS: "https://devkalgudi.vasudhaika.net/trade",
  BIZ_STORE: "https://devkalgudi.vasudhaika.net/trade",
  TGREENS: "https://devkalgudi.vasudhaika.net/tgreens",
  KUBER: "https://devkalgudi.vasudhaika.net/kuber-app",
};

export const AvailableStoreAppNames = {
  'https://andhragreens.com' : 'APGREEN',
  'https://shaktimanfarmingsolutions.com' : 'SHAKTIMAN',
  'https://outputs.kalgudi.com' : 'OUTPUTS',
  'https://inputs.kalgudi.com' : 'INPUTS',
  'https://fpo.kalgudi.com' : 'FPO',
  'https://prorisestore.com' : 'PRORISE_STORE',
  'https://geoagropro.org' : 'GEOAGROPRO',
  'https://emahila.org': 'EMAHILA',
  'http://emahila.kalgudi.com': 'EMAHILA',
  'https://tgreens.in': 'TGREENS',
  'https://marketplace.kalgudi.com': 'UNIFIED_STORE',
  'https://kalgudi.com': 'UNIFIED_STORE',
  'https://sikkimgreens.com': 'SIKKIM_GREENS_STORE',
  'https://kuber.farm': 'KUBER',
  'https://credit.fponext.com': 'SAM_CMP',
  'https://credit.samunnatimart.com': 'SAM_CMP'
};

export const AvailableStoreTypes = {
  'https://andhragreens.com' : 'BIZ_STORE',
  'https://shaktimanfarmingsolutions.com' : 'FARM_STORE',
  'https://outputs.kalgudi.com' : 'BIZ_STORE',
  'https://inputs.kalgudi.com' : 'FARM_STORE',
  'https://fpo.kalgudi.com' : 'FARM_STORE',
  'https://prorisestore.com' : 'FARM_STORE',
  'https://geoagropro.org': 'FARM_STORE',
  'https://emahila.org': 'BIZ_STORE',
  'http://emahila.kalgudi.com': 'BIZ_STORE',
  'https://tgreens.in': 'BIZ_STORE',
  'https://marketplace.kalgudi.com': 'BIZ_STORE',
  'https://kalgudi.com': 'BIZ_STORE',
  'https://sikkimgreens.com': 'BIZ_STORE',
  'https://kuber.farm': 'FARM_STORE',
  'https://trader.kalgudi.com': 'BIZ_STORE',
  'https://credit.fponext.com': 'BIZ_STORE',
  'https://credit.samunnatimart.com': 'BIZ_STORE'
};

export const AvailableStoreTypeNames = {
  OUTPUTS_STORE: "bizstore",
  INPUTS_STORE: "farmstore",
};
