import { Component, Inject, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'kl-promotional-share-crops',
  templateUrl: './promotional-share-crops.component.html',
  styleUrls: ['./promotional-share-crops.component.scss']
})
export class PromotionalShareCropsComponent implements OnInit {

  @Input()
  cropsList;

  domain: string;

  defaultProfilePic = "/assets/svgs/farm-crop.svg";

  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 3,
    prevArrow: `<div class="s-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i><div>`,
    nextArrow: `<div class="s-next"><i class="fa fa-chevron-right" aria-hidden="true"></i><div>`
  };

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) {
    this.domain = this.env.coreDomain;
  }

  ngOnInit() {
  }

}
