import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiStock } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { StockCreationDialogComponent } from '../components/stock-creation-dialog/stock-creation-dialog.component';
import {
  StockCreationMobileDialogComponent,
} from '../components/stock-creation-mobile-dialog/stock-creation-mobile-dialog.component';
import { StocksService } from './stocks.service';

@Injectable()
export class StocksActionService {

  constructor(
    private stocksService: StocksService,
    private dialogsService: KalgudiDialogsService
  ) { }

  /**
   * Deletes the stock
   */
  deleteStock(stockId: string): Observable<KalgudiStock> {

    return this.showDeleteConfirmationDialog()
      .pipe(

        // Calls delete api service
        switchMap(r => this.stocksService.deleteStock(stockId)),
      );
  }

  /**
   * Add a new stock
   */
  openStockCreationDialog(pageId: string, stockId: string): Observable<any> {

    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Create stock',
      acceptButtonTitle: 'Create stock',
      rejectButtonTitle: 'Cancel',
      data: {
        pageId,
        stockId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        pageId,
        stockId
      }
    };

    // Show user picker dialog
    return this.showStockCreationDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );

  }

  showStockCreationDialog(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(StockCreationMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(StockCreationDialogComponent, dialogConfig, matDialogConfig);
    }

  }


  /**
   * Opens dialog for deleting stock
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the stock. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }


}
