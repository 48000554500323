<main id="kl-common-task-form" *ngIf="taskForm">
  <form autocomplete="off" class="mb-4 mx-auto mt-3 common-info" [formGroup]="taskForm" fxLayout="column" fxLayoutGap="10px">
    <div>
      <!-- Task title -->
      <mat-form-field appearance="outline" class="w-100">
        <mat-label translate>Task title</mat-label>
        <input matInput [placeholder]="'Task tile' | translate" formControlName="title" required>
        <mat-error translate>Enter task title</mat-error>
      </mat-form-field>
      <!-- /Task title -->
    </div>

    <!-- Description -->
    <div *ngIf="!isKalgudiOrFponetApp" >
      <mat-form-field appearance="outline" class="w-100">
        <mat-label translate>Description</mat-label>
        <textarea rows="4" matInput formControlName="description" required></textarea>
        <mat-error translate>Enter task description</mat-error>
      </mat-form-field>
    </div>

    <div *ngIf="isKalgudiOrFponetApp" class="task-desc-editor overflow-hidden mb-3">
      <ckeditor [editor]="Editor" (ready)="onReady($event)" formControlName="description"></ckeditor>
    </div>
    <!-- / Description -->


    <!-- Dates -->
    <p class="mb-0" translate>Ideal time</p>
    <div *ngIf="showDateForm"
      fxLayout="row" fxLayout.xs="column"
      fxLayoutGap="30px" fxLayoutGap.xs="15px"
      formGroupName="timeFrame">
      <div fxFlex="calc(50% - 30px)">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label translate>Start date</mat-label>
          <input matInput [matDatepicker]="startDate" formControlName="start" required readonly (click)="startDate.open()">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlex="calc(50% - 30px)">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label translate>End date</mat-label>
          <input matInput [matDatepicker]="endDate" formControlName="end" required readonly [min]="startDateField?.value" (click)="endDate.open()">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    <ng-container *ngIf="(stream$ | async) as stream ">

      <!-- Crop stage dependency -->
      <mat-checkbox *ngIf="projectDetails?.type === projectTypes.CROP_CALENDAR && stream?.length" formControlName="isTaskDependentOnCropStage">
        <span translate>Is this task dependent on another task</span>
      </mat-checkbox>
      <ng-container *ngIf="taskDependentOnCropStageValue">
        <kl-crop-dependency-picker
          formControlName="cropStageDependency"
          [tasks]="stream"
          [productId]="projectDetails?.variety?.productId || projectDetails?.crop?.productId"
          [storeType]="projectDetails?.variety?.storeType || projectDetails?.crop?.storeType">
        </kl-crop-dependency-picker>
      </ng-container>
      <!-- / Crop stage dependency -->

      <!-- Crop calendar crop stage picker -->
      <!-- <ng-container *ngIf="templateSchema?.templateType === 'CROP_STAGE_PICKER'">
        <kl-crop-stage-picker
          formControlName="cropStage"
          [productId]="projectDetails?.variety?.productId || projectDetails?.crop?.productId"
          [storeType]="projectDetails?.variety?.storeType || projectDetails?.crop?.storeType">
        </kl-crop-stage-picker>
      </ng-container> -->
      <!-- / Crop calendar crop stage picker -->
    </ng-container>

    <!-- / Dates -->


    <!-- Type -->
    <!-- <div formGroupName="type" fxLayout>

      <div fxFlex="50%">
        <mat-checkbox formControlName="repeat">Is Repeat</mat-checkbox>
      </div>

      <div fxFlex *ngIf="repeatField">
        <mat-form-field appearance="outline">
          <mat-label>Frequency</mat-label>
          <mat-select formControlName="frequency">
            <mat-option *ngFor="let frequency of frequencyList" [value]="frequency">
              {{frequency}}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div> -->

    <!-- /Type -->

    <!-- Attach image button -->
    <!-- <span class="text-muted">Attachments</span>

    <div class="attach-buttons" fxLayout="row" fxLayoutAlign="start center">
      <button title="Attach image" color="success" type="button" mat-icon-button fxLayoutAlign="center center" (click)="attachImage()">
        <mat-icon class="image">photo</mat-icon>
      </button>
    </div> -->
    <!-- / Attach image button -->


    <!-- Image attachments -->
    <!-- <kl-image-chip-list [attachments]="attachmentsField?.value" (remove)="removeImage($event)">
    </kl-image-chip-list> -->
    <!-- / Image attachments -->

    <!-- Tags section -->
    <div>
      <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
        <mat-label translate>Tags</mat-label>
        <mat-select appearance="outline" multiple formControlName="tagNames" (selectionChange)="onTagSelected($event)">
          <mat-option *ngFor="let tag of tagsList" [value]="tag.id">{{tag.value | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div formGroupName="tags">

      <div *ngIf="selectedTagsList?.INFO">
        <div class="mb-2" fxLayout fxLayoutAlign="space-between start">
          <div>
            <p class="mb-0 tags-title" translate>Information</p>
          </div>
          <!-- <div class="mt-2px">
            <button class="arrow-buttons-up" mat-icon-button (click)="toggleInfo()">
              <i class="fas fa-minus show-icon" *ngIf="showInfo"></i>
              <i class="fas fa-plus show-icon" *ngIf="!showInfo"></i>
            </button>
          </div> -->
        </div>
        <div formArrayName="info" *ngIf="showInfo" [@collapsibleMenu]="{ value: showInfo }">
          <div *ngFor="let f of infoArray.controls;let i=index" fxLayout fxLayoutGap="4px">

            <mat-form-field appearance="outline" fxFlex>
              <mat-label translate>Information</mat-label>
              <textarea rows="1" cols="1" matInput placeholder="Information" [formControlName]="i"></textarea>
            </mat-form-field>

            <!-- <div fxFlex="30px">
              <button type="submit" color="warn" mat-flat-button (click)="removeInfo(i)" class="rounded-circle remove-info-btn">
                <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer" [applyCss]="true"
                  [svgStyle]="{ 'width.px': 20, 'height.px': 20}">
                </svg-icon>
              </button>
            </div> -->
          </div>


          <!-- <div class="mb-2" fxLayout fxLayoutAlign="end end">
            <kl-link-button label="Add more" [disabled]="!infoArray?.valid" (clicked)="addInfo()"></kl-link-button>
          </div> -->
        </div>

      </div>

      <!-- Input array -->
      <div *ngIf="selectedTagsList?.INPUT">
        <div class="mb-2" fxLayout fxLayoutAlign="space-between start">
          <div>
            <p class="mb-0 tags-title" translate>Input</p>
          </div>
          <!-- <div class="mt-2px">
            <button class="arrow-buttons-up" mat-icon-button (click)="toggleInput()" >
              <i class="fas fa-minus show-icon"  *ngIf="showInput"></i>
              <i class="fas fa-plus show-icon"  *ngIf="!showInput"></i>
            </button>
          </div> -->
        </div>
        <div *ngIf="showInput" formArrayName="input" [@collapsibleMenu]="{ value: showInput }">
          <div *ngFor="let item of inputArray.controls; let i=index; let last = last">
            <div [formGroupName]="i" *ngIf="last && false">
              <div class="w-100">
                <kl-base-product-autocomplete
                  formControlName="product">
                </kl-base-product-autocomplete>
              </div>

              <div class="w-100" fxLayout fxLayoutGap="6px" fxLayout.xs="column" fxLayoutGap.xs="0">
                <mat-form-field class="w-100" appearance="outline"  fxFlex="calc(50% - 6px)">
                  <mat-label translate>Need type</mat-label>
                  <mat-select formControlName="needType">
                    <mat-option *ngFor="let item of needTypeList" [value]="item?.value">{{item?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="calc(50% - 6px)" *ngIf="inputArray.controls[i].get('needType').value === 'Services' || inputArray.controls[i].get('needType').value === 'Machinery'">
                  <mat-label translate>No of hours per acre</mat-label>
                  <input matInput type="number" formControlName="noOfHourPerAcre" [placeholder]="'No of hours per acre' | translate">
                </mat-form-field>
              </div>

              <p class="mb-0">{{inputArray.controls[i].get('needType').value === 'Services' ? 'Quantity' : 'Requirement' | translate}} (per acre)</p>
              <div fxLayout fxLayoutGap="6px" fxLayout.xs="column" fxLayoutGap.xs="0">

                <div fxLayout="column" fxFlex="calc(50% - 6px)">
                  <div fxLayout fxLayoutGap="6px" fxLayout.xs="column" fxLayoutGap.xs="0">
                    <div fxFlex="calc(50% - 6px)" fxLayout  formGroupName="quantityPerAcre">
                      <mat-form-field class="no-right-border" fxFlex="50%" appearance="outline">
                        <!-- <mat-label>Value</mat-label> -->
                        <input matInput type="text" formControlName="value" [placeholder]="'Value' | translate">
                      </mat-form-field>

                      <mat-form-field class="no-left-border" fxFlex="50%" appearance="outline">
                        <mat-label translate>Units</mat-label>
                        <mat-select formControlName="unit">
                          <mat-option *ngFor="let item of unitList" [value]="item">{{item}}</mat-option>
                        </mat-select>
                      </mat-form-field>

                    </div>
                  </div>
                </div>

                <div fxFlex="calc(50% - 6px)" fxLayout fxLayoutGap="6px">

                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label translate>Cost per
                      {{inputArray.controls[i].get('needType').value === 'Services' || inputArray.controls[i].get('needType').value === 'Machinery' ? 'hour' : 'acre'}}
                    </mat-label>
                    <input matInput type="number" formControlName="cost" placeholder="Cost">
                  </mat-form-field>
                  <!-- <div class="add-more-button" fxFlex="93px">
                    <kl-link-button label="Add more" [disabled]="!inputArray?.valid" (clicked)="addInput()"></kl-link-button>
                  </div> -->
                </div>

              </div>

            </div>

            <!-- Add multiple inputs -->
            <div [formGroupName]="i" *ngIf="last">
              <div [fxLayout]="showProductNameField || inputArray.controls[i].value.showProductNameField? 'column' : 'row'" fxLayoutGap="6px" fxLayout.xs="column">
                <div  fxFlex="calc(50% - 6px)" fxLayout fxLayoutGap="6px" fxLayout.xs="column">
                  <div fxFlex="calc(50% - 6px)">
                    <!-- <kl-base-product-autocomplete
                      formControlName="product">
                    </kl-base-product-autocomplete> -->
                    <kl-task-tags-product-autocomplete formControlName="product" (noProductFound)="showProductName()">

                    </kl-task-tags-product-autocomplete>
                  </div>

                  <div *ngIf="showProductNameField || inputArray.controls[i].value.showProductNameField" fxFlex="calc(50% - 6px)">
                    <mat-form-field class="w-100" appearance="outline">
                      <mat-label translate>Product name</mat-label>
                      <input matInput type="text" formControlName="productName" placeholder="Value">
                    </mat-form-field>
                  </div>

                </div>

                <mat-form-field class="w-100" appearance="outline"  [fxFlex]="showProductNameField || inputArray.controls[i].value.showProductNameField? '100%' : 'calc(50% - 6px)'">
                  <mat-label translate>Need type</mat-label>
                  <mat-select formControlName="needType">
                    <mat-option *ngFor="let item of needTypeList" [value]="item?.value">{{item?.value}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <p class="mb-0">{{inputArray.controls[i].get('needType').value === 'Services' ? 'Quantity' : 'Requirement'}} (per acre)</p>

              <div class="w-100" fxLayout="row wrap" fxLayoutGap="6px" fxLayout.xs="column" fxLayoutGap.xs="0">

                <div [fxFlex]="inputArray.controls[i].get('needType').value === 'Machinery' ? 'calc(50%)' : 'calc(40% - 6px)'">
                  <div class="w-100" fxLayout  formGroupName="quantityPerAcre">
                    <mat-form-field class="no-right-border" fxFlex="50%" appearance="outline">
                      <input matInput type="text" formControlName="value" placeholder="Value">
                    </mat-form-field>

                    <mat-form-field class="no-left-border" fxFlex="50%" appearance="outline">
                      <mat-label translate>Units</mat-label>
                      <mat-select formControlName="unit">
                        <mat-option *ngFor="let item of unitList" [value]="item">{{item}}</mat-option>
                      </mat-select>
                    </mat-form-field>

                  </div>
                </div>

                <div class="mr-0" *ngIf="inputArray.controls[i].get('needType').value === 'Machinery'" fxFlex="calc(50% - 6px)">
                  <mat-form-field appearance="outline" class="w-100">
                    <!-- {{inputArray.controls[i].get('quantityPerAcre').get('unit').value | titlecase }} -->
                    <mat-label translate>Units/no of acres</mat-label>
                    <input matInput type="number" formControlName="noOfAcres" [placeholder]="'No of acres' | translate">
                  </mat-form-field>
                </div>

                <div [fxFlex]="inputArray.controls[i].get('needType').value === 'Machinery' ? 'calc(45% - 6px)' : 'calc(25% - 6px)'" >
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>Cost per
                      {{inputArray.controls[i].get('needType').value === 'Services' || inputArray.controls[i].get('needType').value === 'Machinery' ? 'hour' : 'acre'}}
                    </mat-label>
                    <input matInput type="number" formControlName="cost" [placeholder]="'Cost' | translate">
                  </mat-form-field>
                </div>

                <div [fxFlex]="inputArray.controls[i].get('needType').value === 'Machinery' ? 'calc(45% - 6px)' : 'calc(25% - 6px)'" *ngIf="inputArray.controls[i].get('needType').value === 'Services' || inputArray.controls[i].get('needType').value === 'Machinery'">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label translate>No of hours per acre</mat-label>
                    <input matInput type="number" formControlName="noOfHourPerAcre" [placeholder]="'No of hours per acre' | translate">
                  </mat-form-field>
                </div>

                <div class="add-input-button" fxFlex="10%">
                  <button class="p-0" mat-button [disabled]="!inputArray?.valid" (click)="addInput()" fxHide.xs>
                    <mat-icon class="add-icon">add</mat-icon>
                  </button>
                </div>

                <div class="add-input-button"  fxHide.gt-xs fxLayoutAlign="end end">
                  <button class="p-0" mat-button [disabled]="!inputArray?.valid" (click)="addInput()">
                    <span class="add-icon mr-2" translate>Add more</span>
                    <mat-icon class="add-icon">add</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutGap="6px" class="w-100">

            <ng-container *ngFor="let item of inputArray?.value; let last = last; let i = index">

              <div fxLayout fxLayoutGap="6px" class="px-2 rounded-pill py-1 bg-lightgray mb-2 mw-100" *ngIf="!last">
                <div class="text-truncate d-block">
                  <span *ngIf="item?.product?.productName">{{item?.product?.productName}}</span>
                  <span *ngIf="item?.dosesPerAcre?.value"> - {{item?.dosesPerAcre?.value}} {{item?.dosesPerAcre?.unit}}</span>
                  <span *ngIf="item?.quantityPerAcre?.value"> - {{item?.quantityPerAcre?.value}} {{item?.quantityPerAcre?.unit}}</span>
                  <span *ngIf="item?.noOfHourPerAcre"> - ₹{{item?.noOfHourPerAcre}} per hour</span>
                  <span *ngIf="item?.cost"> - {{item?.cost}}</span>
                  <span *ngIf="item?.needType"> - {{item?.needType}}</span>
                </div>
                <div (click)="removeInput(i)">
                  <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer remove-icon" [applyCss]="true"
                      [svgStyle]="{ 'width.px': 16, 'height.px': 16}">
                  </svg-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- Output array -->
      <div *ngIf="selectedTagsList?.OUTPUT">

        <div class="mb-2" fxLayout fxLayoutAlign="space-between start">
          <div>
            <p class="mb-0 tags-title" translate>Output</p>
          </div>
          <div class="mt-2px">
            <!-- <button class="arrow-buttons-up" mat-icon-button (click)="toggleOutput()">
              <i class="fas fa-minus show-icon" *ngIf="showOutput"></i>
              <i class="fas fa-plus show-icon" *ngIf="!showOutput"></i>
            </button> -->

          </div>
        </div>
        <div *ngIf="showOutput" formArrayName="output" [@collapsibleMenu]="{ value: showOutput }">
          <div *ngFor="let item of outputArray.controls; let i=index; let last = last">
            <div [formGroupName]="i" *ngIf="last">

              <div>

                <kl-base-product-autocomplete
                  formControlName="product">
                </kl-base-product-autocomplete>

              </div>

              <p class="mb-0" translate>Expected yield (per Acre)</p>
              <div fxLayout fxLayoutGap="6px"  fxLayout.xs="column" fxLayoutGap.xs="0">
                <div fxFlex="calc(50% - 6px)" fxLayout  formGroupName="expectedYieldPerAcre">

                  <mat-form-field class="no-right-border" fxFlex="50%" appearance="outline">
                    <!-- <mat-label>Value</mat-label> -->
                    <input matInput type="text" formControlName="value" [placeholder]="'Value' | translate">
                  </mat-form-field>

                  <mat-form-field class="no-left-border " fxFlex="50%" appearance="outline">
                    <mat-label translate>Units</mat-label>
                    <mat-select formControlName="unit">
                      <mat-option *ngFor="let item of unitList" [value]="item">{{item}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div fxFlex="calc(50% - 6px)" fxLayout fxLayoutGap="6px">

                  <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label translate>Cost to expected value</mat-label>
                    <input matInput type="number" formControlName="cost" [placeholder]="'Cost' | translate">
                  </mat-form-field>

                  <!-- <div class="add-more-button" fxFlex="93px">
                    <kl-link-button label="Add more" [disabled]="!outputArray?.valid" (clicked)="addOutput()"></kl-link-button>
                  </div> -->
                </div>


              </div>

            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutGap="6px" class="w-100">

            <ng-container *ngFor="let item of outputArray?.value; let last = last; let i = index">
              <div fxLayout="row" fxLayoutGap="6px" class="px-2 rounded-pill py-1 bg-lightgray mb-2 mw-100" *ngIf="!last">
                <div class="text-truncate d-block">
                  <span *ngIf="item?.product?.productName">{{item?.product?.productName}}</span>
                  <span *ngIf="item?.expectedYieldPerAcre?.value"> - {{item?.expectedYieldPerAcre?.value}} {{item?.expectedYieldPerAcre?.unit}}</span>
                  <span *ngIf="item?.cost"> - {{item?.cost}}</span>
                </div>
                <div (click)="removeOutput(i)">
                  <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer remove-icon" [applyCss]="true"
                      [svgStyle]="{ 'width.px': 16, 'height.px': 16}">
                  </svg-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <!-- services array -->
      <!-- <div *ngIf="selectedTagsList?.SERVICES">

        <div class="tags-title-wrapper px-1 mb-2" fxLayout fxLayoutAlign="space-between start">
          <div>
            <p class="mb-0 tags-title p-1">Services</p>
          </div>
          <div class="mt-2px">
            <button class="arrow-buttons-up" mat-icon-button (click)="toggleService()" >
              <i class="fas fa-minus show-icon" *ngIf="showService"></i>
              <i class="fas fa-plus show-icon" *ngIf="!showService"></i>
            </button>
          </div>
        </div>
        <div *ngIf="showService" formArrayName="services" [@collapsibleMenu]="{ value: showService }">
          <div *ngFor="let item of serviceArray.controls; let i=index; let last = last">
            <div [formGroupName]="i" *ngIf="last">
              <div class="w-100">
                <kl-base-product-autocomplete
                  formControlName="product">
                </kl-base-product-autocomplete>
              </div>

              <div fxLayout fxLayoutGap="6px" fxLayout.xs="column" fxLayoutGap.xs="0">

                <mat-form-field appearance="outline" fxFlex="calc(50% - 6px)">
                  <mat-label>No of hours per acre</mat-label>
                  <input matInput type="number" formControlName="noOfHourPerAcre" placeholder="No of hours per acre">
                </mat-form-field>

                <div fxFlex="calc(50% - 6px)" fxLayout fxLayoutGap="6px">
                  <mat-form-field appearance="outline" fxFlex="calc(100% - 99px)">
                    <mat-label>Cost</mat-label>
                    <input matInput type="number" formControlName="cost" placeholder="Cost">
                  </mat-form-field>

                  <div class="add-more-button" fxFlex="93px">
                    <kl-link-button label="Add more" [disabled]="!serviceArray?.valid" (clicked)="addServices()"></kl-link-button>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div fxLayout="row wrap" fxLayoutGap="6px" class="w-100">
            <ng-container *ngFor="let item of serviceArray?.value; let last = last; let i = index">
              <div fxLayout="row" fxLayoutGap="6px" class="px-2 rounded-pill py-1 bg-lightgray mb-2 mw-100" *ngIf="!last">
                <div class="text-truncate d-block">
                  <span *ngIf="item?.product?.productName">{{item?.product?.productName}}</span>
                  <span *ngIf="item?.noOfHourPerAcre"> - ₹{{item?.noOfHourPerAcre}} per hour</span>
                  <span *ngIf="item?.cost"> - ₹{{item?.cost}}</span>
                </div>
                <div (click)="removeService(i)">
                  <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer remove-icon" [applyCss]="true"
                      [svgStyle]="{ 'width.px': 16, 'height.px': 16}">
                  </svg-icon>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div> -->
    </div>


  </form>


  <form autocomplete="off" class="dynamic-form-field mb-4 mt-3"
    [formGroup]="creationTemplateForm" *ngIf="creationTemplateFormlyFields"
    fxLayout="column" fxLayoutGap="10px">

    <formly-form
      [model]="creationTemplateModel"
      [fields]="creationTemplateFormlyFields"
      [form]="creationTemplateForm">
    </formly-form>
  </form>

</main>
