import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kl-analytics-row',
  template: `
  <section class="mb-3">
    <h2 class="h5">{{title}}</h2>
    <div fxLayout="row" fxLayoutGap="10px" fxLayout.xs="row wrap" fxLayoutGap.xs="2%">
      <ng-content></ng-content>
    </div>
  </section>
  `,
  styles: [`
  `],
  encapsulation: ViewEncapsulation.None,
})
export class AnalyticsRowComponent implements OnInit {

  @Input()
  title: string = '';

  constructor() { }

  ngOnInit() {
  }

}
