import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiStreamData, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonList,
  KalgudiPageAddNewUserPayload,
  KalgudiPageAddNewUsersResponse,
  KalgudiPageRelation,
  KalgudiUserBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiPageMemberAddResponse, ApiPageMemberAddResponseData } from '../typings';

@Injectable()
export class KalgudiPageMemberApiService {

  /**
   * `v2/pages/members`
   */
  private readonly API_PAGE_MEMBERS = `${this.env.restBaseUrlV2}/pages/members`;

  /**
   * `v2/pages/members/bulk-excel`
   */
  private readonly API_PAGE_DOCUMENTS_MEMBERS = `${this.env.restBaseUrlV2}/pages/members/bulk-excel`;

  /**
   * `v2/pages/members/bulk`
   */
  private readonly API_PAGE_BULK_MEMBERS = `${this.API_PAGE_MEMBERS}/bulk`;


  /**
   * `v2/pages/members/bulk`
   */
  private readonly API_PAGE_ADD_NEW_USERS = `${this.env.restBaseUrlV2}/profiles/user-member-addition`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches page members stream from API.
   *
   * @param pageId Page id for which the stream must be fetched
   * @param role
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchMembers(pageId: string, role: KalgudiPageRelation, offset: number, limit: number): Observable<KalgudiStreamData> {

    // Api query params
    const params = {
      pageId,
      role,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient.get<ApiResponseCommonList>(this.API_PAGE_MEMBERS, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi page member details object type
        map(res => ({
          count: res.data.count,
          items: res.data.results
        }))
      );
  }

  /**
   * Defines the api to add members to a page
   */
  addMembers(pageId: string, usersList: KalgudiUserBasicDetails[], role: KalgudiPageRelation): Observable<ApiPageMemberAddResponseData> {

    // Api query params
    const params = {
      pageId,
      role,
    };

    return this.httpClient.post<ApiPageMemberAddResponse>(this.API_PAGE_MEMBERS, usersList, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED, {
          ...REST_API_ERROR_MESSAGES,
          [HttpStatusCode.BAD_REQUEST]: 'Unable to add members, ensure you adding max 10 members at a time'
        })),

        // Map API response to Kalgudi page member details object type
        map(res => res.data)
      );
  }

  /**
   * Defines the api to add document bulk members
   */
  addDocumentsBulkMembers(pageId: string, s3Url: string): Observable<any> {

    // Api query params
    const payload = {
      pageId,
      s3Url: s3Url
    };

    return this.httpClient.post<any>(this.API_PAGE_DOCUMENTS_MEMBERS, payload)
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi page member details object type
        map(res => res.data)
      );
  }


  /**
   * Defines the api to add bulk members to a page using ids
   */
  addBulkMembers(pageId: string, payload: any, role: KalgudiPageRelation): Observable<ApiPageMemberAddResponseData> {

    // Api query params
    const params = {
      pageId,
      role
    };

    return this.httpClient.post<ApiPageMemberAddResponse>(this.API_PAGE_BULK_MEMBERS, payload, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi page member details object type
        map(res => res.data)
      );
  }

  /**
   * Defines api to remove members from page
   *
   * @param pageId
   */
  deleteMembers(pageId: string, userProfileKeyList: string[]): Observable<ApiPageMemberAddResponseData> {

    // Api query params
    const params = {
      pageId,
      profileKeys: userProfileKeyList.join(',')
    };

    return this.httpClient.delete<ApiPageMemberAddResponse>(this.API_PAGE_MEMBERS, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response to Kalgudi page member details object type
        map(res => res.data)
      );
  }

  /**
   * Defines the api to add new users to a page
   */
  addNewUser(payload: KalgudiPageAddNewUserPayload): Observable<KalgudiPageAddNewUsersResponse> {

    return this.httpClient.post<ApiResponseCommon>(this.API_PAGE_ADD_NEW_USERS, payload)
      .pipe(

        // Check and handle API response for errors
        map(res => {

          if (res.code !== HttpStatusCode.OK) {
            const errorMessage = res.info || 'Something went wrong, please try again later.';
            throw new ApiError(new Error(errorMessage));
          }

          // All good, return the response back
          return res;
        }),

        // Map API response to Kalgudi page users details object type
        map(res => res.data)
      );
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
