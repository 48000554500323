import { Component, forwardRef, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ShareVisibility } from './share-visibility';

// Page tags NG_VALUE_ACCESSOR provider for custom form control
const ACTIVITY_VISIBILITY_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShareVisibilityComponent),
  multi: true,
};

@Component({
  selector: 'kl-share-visibility',
  templateUrl: './share-visibility.component.html',
  styleUrls: ['./share-visibility.component.scss'],
  providers: [ ACTIVITY_VISIBILITY_FORM_CONTROL_PROVIDER ]

})
export class ShareVisibilityComponent extends ShareVisibility implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
