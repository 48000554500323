<div fxLayout="row" class="w-100">
  <div class="pt-2" fxLayout="row" fxLayoutGap="4px" fxFlex.xs="100%" class="w-100">
    <ng-container>
      <div [ngClass]="{'mt-2': showBulkUpload}" (click)="pickPageMembers()">
        <strong>
          <a href="javascript: void(0);" translate>Click here to select specific members</a>
        </strong>
      </div>
      <div fxLayout="row" *ngIf="showBulkUpload">
        <p class="p-1 rounded-circle border or-text mt-2 ml-2">OR</p>
        <div fxLayout fxLayoutGap="4px">
          <div>
            <kl-image-picker-form-control
              [formControl]="attachments" title="Browse" buttonLabel="Upload bulk"
              [allowMultiple]="false" [showAttachments]="false"
              [s3Category]="s3Category" [maxImages]="1"
              [acceptedFileTypes]="acceptedFileTypes">
            </kl-image-picker-form-control>
          </div>

          <div class="sample-file-wrapper pt-2">
            <span><a [href]="'https://kalgudidata.s3.amazonaws.com/data%2FbusinessAttachments%2F1702970347241.xlsx'" target="_blank">(Sample file)</a></span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div fxLayout fxLayoutGap="16px" *ngIf="attachments?.value && attachments?.value?.length">

  <div class="file-attachment rounded-pill position-relative mat-chips"
    fxLayout fxLayoutGap="4px">

    <div class="attach-icon-container">
      <mat-icon class="attach-icon">insert_drive_file</mat-icon>
    </div>
    <div class="text-bold" fxFlexAlign="center">Excel attachment</div>

    <div class="hide d-none position-absolute" (click)="removeAttachment()">
      <mat-icon class="icon-display cursor-pointer">close</mat-icon>
    </div>
  </div>

  <div>
    <kl-flat-button label="Verify" (clicked)="verifyExcel()"></kl-flat-button>
  </div>
</div>

<div fxLayout="row" class="specific-member-list">

  <!-- Selected members list -->
  <div *ngIf="!(selectedProgramMembers | isEmpty)">
    <ng-container>

      <!-- Heading -->
      <div class="my-2">
        <span class="text-muted bg-white d-inline pr-2" translate>Update will be sent to</span>
        <div class="border-bottom mt-n2"></div>
      </div>

      <!-- Chip list -->
      <div class="my-3">
        <mat-chip-list class="mb-4">
          <ng-container *ngFor="let item of (selectedProgramMembers | keyvalue); trackBy: selectedUserTrackByFun">
            <mat-chip class="pl-0 chips" [@fadeIn]>
              <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
                <img class="chip-image rounded-circle" [src]="item?.value?.profilePicUrl | prefixDomain"
                  [klErrorImages]="[
                    (item?.value?.profilePicUrl) | prefixDomain,
                    defaultProfilePic
                  ]" />

                <span class="text-truncate" [title]="item?.value?.firstName">{{ item?.value?.firstName }}</span>

                <mat-icon matChipRemove [title]="'Remove user' | translate" (click)="removeSelectedProgramMember(item?.value?.profileKey)">
                  cancel
                </mat-icon>
              </div>
            </mat-chip>
          </ng-container>
        </mat-chip-list>
      </div>
      <!--/ Chip list -->

    </ng-container>
  </div>
  <!-- / Selected members list -->
</div>
