<div id="project-list-item" fxLayout fxLayoutGap="12px" (click)="openProjectFullview(project?.id)">


  <div fxFlex="10%">
    <img [src]="project?.createdBy?.profilePicUrl | prefixDomain" width="26" height="26" class="rounded-circle"
      (error)="img.src = defaultProfilePic" #img>
  </div>

  <!-- Project details -->
  <div fxFlex="90%" fxLayout="column">

    <div class="mb-2" fxLayout="row" class="w-100 cursor-pointer" fxLayoutGap="10px">
      <div fxFlex="60%">
        <h4 class="title text-truncate text-capitalize mb-0">{{ project?.title }}</h4>
      </div>
      <div fxFlex="35%" class="date">
        <mat-icon class="text-muted">calendar_today</mat-icon>
        <time class="text-muted">
          {{ project?.CT | date:'dd MMM yyyy' }}
        </time>
      </div>
    </div>

    <!-- Project description -->
    <div>
      <p class="mb-0 description text-muted">
        {{ project?.description }}
      </p>
    </div>
    <!--/ Project description -->

  </div>
  <!-- /Project details -->


</div>
