import { Component, Input, OnInit } from '@angular/core';
import { KalgudiStoreProduct } from '@kalgudi/types';

@Component({
  selector: 'kl-store-product-tile',
  templateUrl: './store-product-tile.component.html',
  styleUrls: ['./store-product-tile.component.scss']
})
export class StoreProductTileComponent implements OnInit {

  @Input()
  product: KalgudiStoreProduct;

  @Input()
  isSelected: boolean;

  constructor() { }

  ngOnInit() {}

}
