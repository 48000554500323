import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableStream } from '@kalgudi/core';
import { LogsResponseData, SmsLog } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { ProgramStateService } from '../../../services/program-state.service';
import { LogsListService } from '../services/logs-list.service';
import { SmsLogsListStateService } from '../services/sms-logs-list-state.service';

@Directive()
export abstract class KalgudiLogsTableList extends  KalgudiMatTableStream<SmsLog> {

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input()
  smsId: string;

  pageId: string;

  filterType: string;

  logsCount: number;

  progress: boolean;

  deliveredLogsCount: number;
  failedLogsCount: number;
  pendingLogsCount: number;

  private logsListService: LogsListService;
  private programStateService: ProgramStateService;
  private logsListStateService: SmsLogsListStateService;

  constructor(protected injector: Injector) {
    super();

    this.logsListService      = this.injector.get(LogsListService);
    this.programStateService  = this.injector.get(ProgramStateService);
    this.logsListStateService = this.injector.get(SmsLogsListStateService);

    this.pageSizeOptions = [5, 10];

    this.displayedColumns = [
      'mobileNo',
      'firstName',
      'deliveryStatus'
    ];

    // Get entity id
    this.programStateService.id$
      .pipe(
        first(),
      ).subscribe(id => {

        this.pageId = id;
      });

    // Invoke filter on status filter updation
    this.logsListStateService.statusTypeChanged$
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(res => {
        this.filterType = res;
        this.resetTable();
      });
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls this method whenever the selected filter value changes
   */
  filterTypeChanged(filterType: string) {
    this.filterType = filterType;
    this.resetTable();
  }

  /**
   * Calls method to download logs list
   */
  downloadLogs() {
    this.progress = true;
    this.logsListService.downloadLogsList(this.smsId)
      .pipe(

        //Downloads logs
        switchMap(res => window.open(res.url, '_self')),

        //Turn of the progress
        finalize(() => this.progress = false),
      )
      .subscribe();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Fetches logs list
   */
  protected streamApi(offset: number, limit: number): Observable<LogsResponseData> {

    return this.logsListService.fetchLogsList(this.smsId, this.pageId, offset, limit, this.filterType)
      .pipe(
        tap(res => {
            this.logsCount          = res.count;
            this.deliveredLogsCount = res.success;
            this.failedLogsCount    = res.failed;
            this.pendingLogsCount   = res.pending;
          }
        )
      );

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
