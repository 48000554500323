import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { AddProjectMembersResponse, BulkMembersAdditionResponse, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { AddProjectMembersApiService } from './add-project-members-api.service';

@Injectable()
export class AddProjectMembersService {

  constructor(
    private api: AddProjectMembersApiService
  ) { }

  /**
   * Make an api call get project members
   */
  getProjectMembers(projectId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.api.getProjectMembers(projectId, offset, limit);
  }

  /**
   * Make an api call to add project members
   */
  addProjectMembers(projectId: string, payload: AddProjectMembersResponse, params: PartialData = {}): Observable<string[]> {
    return this.api.addProjectMembers(projectId, payload, params);
  }

  /**
   * Make an api call to delete project members
   */
  deleteProjectMembers(projectId: string, profileKey: string): Observable<string[]> {
    return this.api.deleteProjectMembers(projectId, profileKey);
  }

  /**
   * Make an api call to add project bulk members
   */
  addBulkMembers(projectId: string, payload: string[], params: PartialData = {}): Observable<BulkMembersAdditionResponse> {
    return this.api.addBulkMembers(projectId, payload, params);
  }
}
