import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'kl-task-status',
  templateUrl: './task-status.component.html',
  styleUrls: ['./task-status.component.scss']
})
export class TaskStatusComponent implements OnInit, OnChanges {

  @Input()
  taskStatus: any;

  previousState: string;
  state: string;

  constructor() {
  }

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.taskStatus && this.taskStatus.activity && this.taskStatus.activity.previousState && this.taskStatus.activity.state) {
      this.previousState = this.taskStatus.activity.previousState.replace(/_/g, "");
      this.state = this.taskStatus.activity.state.replace(/_/g, "");
    }
  }

}
