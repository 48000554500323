import { Injectable } from '@angular/core';
import { KalgudiStepper } from '@kalgudi/common';

import { TaskCreationStateDataMap, TaskCreationStates } from '../models';

/**
 * Page creation state management service. Page creation happens in multiple steps.
 * It defines a protocol to manage different steps of a page creation.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class CreateTaskStateService extends KalgudiStepper<TaskCreationStates, TaskCreationStateDataMap> {



  /**
   * Default page creation state data
   */
  protected readonly DEFAULT_CREATION_STATE_DATA: TaskCreationStateDataMap = {
    CHOOSE_TASK_TYPE: { data: ''},
    FILL_COMMON_TASK_DETAILS: { data: ''},
    FILL_SPECIFIC_TASK_DETAILS: { data: ''},
    CREATE_TASK: { data: ''},
    TASK_CREATED: { data: ''},
    ADD_MEMBERS: { data: ''},
    MEMBERS_ADDED: { data: '' }

  };

  /**
   * Page creation steps order
   */
  protected readonly CREATION_STATE_ORDER: TaskCreationStates[] = [
    'CHOOSE_TASK_TYPE',
    'FILL_COMMON_TASK_DETAILS',
    'FILL_SPECIFIC_TASK_DETAILS',
    'CREATE_TASK',
    'TASK_CREATED',
    'ADD_MEMBERS',
    'MEMBERS_ADDED'
  ];

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  constructor() {

    super();

    this.initState(this.CREATION_STATE_ORDER[0], this.DEFAULT_CREATION_STATE_DATA);
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }


}
