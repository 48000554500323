import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService, KalgudiSocialShare } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import {
  Attachment,
  AvailableStoreTypes,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiPageDetails,
  ProgramShareRequest,
  ProgramShareUpdate,
} from '@kalgudi/types';
import { Observable, timer } from 'rxjs';
import { filter, finalize, first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiPagesMembersPickerService } from '../../program-members-picker-dialog';
import { PageShareFiltersService } from '../../program-target-members';
import {
  PromotionalProductDescriptionDialogComponent,
} from '../components/promotional-product-description-dialog/promotional-product-description-dialog.component';
import { PromotionalShareUpdateService } from '../services/promotional-share-update.service';

@Directive()
export abstract class KalgudiPromotionalShareForm extends KalgudiSocialShare<ProgramShareRequest, ProgramShareUpdate> {

  @Output()
  postCreated = new EventEmitter<ProgramShareUpdate>();

  @Input()
  pageId: string;

  shareForm: FormGroup;

  skuSelection = new FormControl('');
  pageDetails: KalgudiPageDetails;
  selectedSku: any;

  level2Id: string;
  level2Initialized: any;
  level2Details: any;

  productDetailsProgress: boolean;
  kuberPageId: string;

  selectedStoreType: string;

  private promotionalShareUpdateService: PromotionalShareUpdateService;
  private env: KalgudiEnvironmentConfig;


  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected programDialogs: KalgudiPagesMembersPickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected targetedMetaService: PageShareFiltersService,
    protected mobileMenuService: KalgudiBottomSheetService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      sharePostService,
      socialDataNormalizer,
      kalgudiApp,
      mobileMenuService
    );

    this.promotionalShareUpdateService = this.injector.get(PromotionalShareUpdateService);
    this.env                           = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    // Initialize share update form
    this.shareForm = this.programShareUpdateForm;

    // Patch program details to program form
    this.patchProgramDetailsToForm();

    this.kuberPageId = this.env.production ? '054c' : '01bt';
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share update common form
   */
  private get programShareUpdateForm(): FormGroup {

    return new FormGroup({

      // Common fields inherited from share update
      authorId: new FormControl('', Validators.required),
      shareText: new FormControl('', Validators.required),
      richShareText: new FormControl(''),
      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
      lstOfAttachments: new FormControl([]),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(''),
      scheduledTS: new FormControl(''),
      sharedTo: new FormControl(this.shareVisibilityList[0].value, Validators.required),

      crop: new FormControl(''),
      promotionalTitle: new FormControl('', Validators.required),

      // Program specific fields
      recipient: new FormControl(this.shareVisibilityList[1].value, Validators.required),
      entityId: new FormControl('', Validators.required),
      entityName: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isAuthor: new FormControl(false, Validators.required),
      msgTypes: new FormControl([], Validators.required),
      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),

        // mandals: new FormArray([]),
        // varieties: new FormArray([]),
        // seasons: new FormArray([]),
        // from: new FormControl(''),
        // to: new FormControl(''),

        pageId: new FormControl(''),
        pageTitle: new FormControl(''),
        pageType: new FormControl(''),
        pageUrl: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
        isSms: new FormControl(''),
      }),
      isPublishedThroughTool: new FormControl(true),
      productsMetaData: new FormControl([], Validators.required)
    });
  }

  /**
   * Share form author id field
   */
  get shareFormProductsMetaData(): AbstractControl {
    return this.shareForm.get('productsMetaData');
  }


  /**
   * Share form author id field
   */
  get shareFormAuthorId(): AbstractControl {
    return this.shareForm.get('authorId');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form shared to field
   */
  get shareFormSharedTo(): AbstractControl {
    return this.shareForm.get('sharedTo');
  }

  /**
   * Share form program entity id field
   */
  get shareFormEntityId(): AbstractControl {
    return this.shareForm.get('entityId');
  }

  /**
   * Share form program entity name field
   */
  get shareFormEntityName(): AbstractControl {
    return this.shareForm.get('entityName');
  }

  /**
   * Share form program is admin field
   */
  get shareFormIsAdmin(): AbstractControl {
    return this.shareForm.get('isAdmin');
  }

  /**
   * Share form program is author field
   */
  get shareFormIsAuthor(): AbstractControl {
    return this.shareForm.get('isAuthor');
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  get shareFormIsSms(): AbstractControl {
    return this.shareFormFilter.get('isSms');
  }

  /**
   * Getter for page id field.
   */
  get shareFormPageId(): AbstractControl {
    return this.shareFormFilter.get('pageId');
  }

  /**
   * Getter for page title field.
   */
  get shareFormPageTitle(): AbstractControl {
    return this.shareFormFilter.get('pageTitle');
  }

  /**
   * Getter for page type field.
   */
  get shareFormPageType(): AbstractControl {
    return this.shareFormFilter.get('pageType');
  }

  /**
   * Getter for page url field.
   */
  get shareFormPageUrl(): AbstractControl {
    return this.shareFormFilter.get('pageUrl');
  }

  /**
   * Getter for parent page id field.
   */
  get shareFormParentPageId(): AbstractControl {
    return this.shareFormFilter.get('parentPageId');
  }

  /**
   * Getter for parent page title field.
   */
  get shareFormParentPageTitle(): AbstractControl {
    return this.shareFormFilter.get('parentPageTitle');
  }

  /**
   * Getter for parent page url field.
   */
  get shareFormParentPageUrl(): AbstractControl {
    return this.shareFormFilter.get('parentPageUrl');
  }

  /**
   * Getter for promotional title field.
   */
  get shareFormPromotionalTitle(): AbstractControl {
    return this.shareForm.get('promotionalTitle');
  }

  /**
   * Share form msgTypes field
   */
  get shareFormMsgTypes(): AbstractControl {
    return this.shareForm.get('msgTypes');
  }


  /**
   * Returns true if the advisories tag is selected else returns false
   */
  get isAdvisorySelected(): boolean {
    return Array.isArray(this.shareFormMsgTypes.value)
      ? this.shareFormMsgTypes.value.includes('ADVISORIES')
      :  false;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------


  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {

    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);

      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets the program share update form
   *
   * @override
   */
  resetForm() {
    // Honour all parent actions
    super.resetForm();
    this.audioAttachment.patchValue(null);
    this.level2Details = '';
    this.selectedSku = {};
    this.level2Initialized = '';
    this.shareFormProductsMetaData.reset();

    // Patch page details after resetting page form
    this.patchProgramDetails(this.pageDetails);
  }

  /**
   * Fetch product level2 details
   * @param level2Id
   */
  getProductLevel2(level2Id: string, storeType: string) {

    this.productDetailsProgress = true;
    this.level2Details = '';

    this.promotionalShareUpdateService.getProductLevel2(level2Id, storeType)
      .pipe(
        finalize(() => this.productDetailsProgress = false)
      )
      .subscribe(
        (res: any) => {

          res.level3ProductsList = res.level3ProductsList.filter(item => item.isBulkOrder === false && item.isPublished === true && item.mrp && item.pricePerUnit)

          this.level2Details = res;

          this.level2Initialized = this.level2Id;
        }
      )
  }

  /**
   * Fetch page details
   * @param pageId
   */
  getPageDetails(pageId: string) {
    this.kalgudiPageService.fetchAndUpdatePageState(pageId).subscribe();
  }

  /**
   * Select sku
   */
  selectSKu(selectedSku) {
    this.selectedSku = selectedSku;
  }

  /**
   * To open the promotional product description dialog
   */
  openPromotionalProductDescriptionDialog() {
    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: '',
      data: {
        description: this.level2Details.description
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    // Show promotional product description dialog
    return this.showPromotionalProductDescriptionDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Assigning selected store on changed store type
   * @param value
   */
  onChangedStoreType(value: string) {
    this.selectedStoreType = value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the program details and binding the details to he share form
   */
  private patchProgramDetailsToForm(): void {

    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),

              first(),

              tap(r => this.patchProgramDetails(r))
            )
        )
      ).subscribe();
  }

  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    this.pageDetails = pageDetails;

    this.pageId = pageDetails.pageId;

    this.shareFormMsgTypes.patchValue('PRODUCT');

    // Required fields
    this.shareFormAuthorId.patchValue(this.kalgudiApp.profileLocal.profileKey);
    this.shareFormVisibility.patchValue(this.shareVisibilityList[1].value);
    this.shareFormSharedTo.patchValue(this.shareVisibilityList[0].value);

    this.shareFormEntityId.patchValue(pageDetails.pageId);
    this.shareFormEntityName.patchValue(pageDetails.pageTitle);
    this.shareFormIsAdmin.patchValue(this.kalgudiPageService.isPageAdmin(pageDetails));
    this.shareFormIsAuthor.patchValue(this.kalgudiPageService.isPageAuthor(pageDetails));

    // Filters
    this.shareFormIsSms.patchValue(false);
    this.shareFormPageId.patchValue(pageDetails.pageId);
    this.shareFormPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormPageType.patchValue(pageDetails.pageType);
    this.shareFormPageUrl.patchValue(pageDetails.pageProfilePic);
    this.shareFormParentPageId.patchValue(pageDetails.pageId);
    this.shareFormParentPageTitle.patchValue(pageDetails.pageTitle);
    this.shareFormParentPageUrl.patchValue(pageDetails.pageProfilePic);
  }

  protected getCreationPayload(shareFormValue: any): any {

    const skuData = {
      createdTS: this.selectedSku.createdTS,
      LUT: this.selectedSku.LUT,
      productPicURL_level2: this.level2Details.attachments[0] && this.level2Details.attachments[0].attachments[0] && this.level2Details.attachments[0].attachments[0].url ? this.level2Details.attachments[0].attachments[0].url : '',
      productId_level2: this.selectedSku.productLevel2Id,
      masterLevel2Id: '',
      masterLevel2ShortId: '',
      productName_level2: this.selectedSku.productLevel2Title,
      description: this.selectedSku.description,
      subCategories: '',
      // manufacturerName: shareFormValue.productsMetaData.manufacturerDetails.firstName,
      // manufacturerLocation: shareFormValue.productsMetaData.manufacturerDetails.location,
      // manufacturerId: shareFormValue.productsMetaData.manufacturerDetails.profileKey,
      productPicURL_level3: this.selectedSku.attachments && this.selectedSku.attachments.attachments[0] &&  this.selectedSku.attachments.attachments[0].url ? this.selectedSku.attachments.attachments[0].url : '',
      productId_level3: this.selectedSku.productLevel3Id,
      level2ShortId: '',
      level3ShortId: this.selectedSku.level3ShortId,
      productName_level3: this.selectedSku.productLevel3Title,
      pricePerSKU: this.selectedSku.pricePerUnit,
      SKUSmartElements: this.selectedSku.smartElements,
      mrp: this.selectedSku.mrp,
      approxDeliveryTime: this.level2Details.approxDeliveryTime,
      level2published: this.level2Details.isPublished,
      level3published: this.selectedSku.isPublished,
      stores: [this.selectedStoreType],
      baseProductName: this.level2Details.baseProduct.productName,
      isBulkOrder: this.selectedSku.isBulkOrder,
      selectedSKU: this.level2Details.selectedSKU,
      shipping: this.selectedSku.shipping,
      freeShippingEligible: this.selectedSku.freeShippingEligible,
      crops: (shareFormValue.crop || this.level2Details.crop) ? (shareFormValue.crop || this.level2Details.crop) : [],
      storeType: AvailableStoreTypes[this.selectedStoreType]
      // reviewsAndRatingsTo: {}
    }

    if((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm') && shareFormValue.productsMetaData && shareFormValue.productsMetaData.resellerDetails && shareFormValue.productsMetaData.resellerDetails.firstName) {
      skuData['sellerName'] = shareFormValue.productsMetaData.resellerDetails.firstName;
    } else if(shareFormValue.productsMetaData && shareFormValue.productsMetaData.manufacturerDetails && shareFormValue.productsMetaData.manufacturerDetails.firstName){
      skuData['sellerName'] = shareFormValue.productsMetaData.manufacturerDetails.firstName;
    }

    if((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm') && shareFormValue.productsMetaData && shareFormValue.productsMetaData.resellerDetails && shareFormValue.productsMetaData.resellerDetails.location) {
      skuData['sellerLocation'] = shareFormValue.productsMetaData.resellerDetails.location;
    } else if(shareFormValue.productsMetaData && shareFormValue.productsMetaData.manufacturerDetails && shareFormValue.productsMetaData.manufacturerDetails.location){
      skuData['sellerLocation'] = shareFormValue.productsMetaData.manufacturerDetails.location;
    }

    if((this.pageId === this.kuberPageId) && (this.selectedStoreType === 'https://kuber.farm') && shareFormValue.productsMetaData && shareFormValue.productsMetaData.resellerDetails && shareFormValue.productsMetaData.resellerDetails.profileKey) {
      skuData['sellerId'] = shareFormValue.productsMetaData.resellerDetails.profileKey;
    } else if(shareFormValue.productsMetaData && shareFormValue.productsMetaData.manufacturerDetails && shareFormValue.productsMetaData.manufacturerDetails.profileKey){
      skuData['sellerId'] = shareFormValue.productsMetaData.manufacturerDetails.profileKey;
    }

    shareFormValue.productsMetaData    = skuData ? [skuData] : [];

    if(shareFormValue.productsMetaData) {
      shareFormValue.productsMetaData[0].storeType = this.level2Details.storeType ? this.level2Details.storeType : shareFormValue.productsMetaData[0].storeType;
    }

    if(shareFormValue.msgTypes) {
      shareFormValue.msgTypes = Array.isArray(shareFormValue.msgTypes) ? shareFormValue.msgTypes : [shareFormValue.msgTypes];
    }

    if(shareFormValue.crop) {

      delete shareFormValue.crop;
    }

    if (this.urlFormControl.value && this.urlFormControl.value.url) {

      shareFormValue.uri = this.urlFormControl.value.url;
      shareFormValue.domain = this.urlFormControl.value.domain;
      shareFormValue.uriImage = this.urlFormControl.value.image;
      shareFormValue.uriTitle = this.urlFormControl.value.title;
    }

    if (shareFormValue.scheduledDate && shareFormValue.scheduledTime ) {

      const date = new Date(shareFormValue.scheduledDate);

      const dateAndTime = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${shareFormValue.scheduledTime}`;

      shareFormValue.scheduledTS = new Date(dateAndTime).toISOString();
    }

    // if (this.scheduledDates && this.scheduledDates.scheduledTS) {

    //   shareFormValue.scheduledTS = this.scheduledDates.scheduledTS;
    //   shareFormValue.scheduledDate = this.scheduledDates.scheduledDate;
    //   shareFormValue.scheduledTime = this.scheduledDates.scheduledTime;
    // }
    const audio: Attachment = this.audioAttachment.value;


    if (audio && audio.url) {
      shareFormValue.lstOfAttachments.push(audio);

      shareFormValue.shareText = shareFormValue.shareText ? shareFormValue.shareText : 'Audio Share';
    }

    return shareFormValue;
  }

  private showPromotionalProductDescriptionDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.kalgudiDialogService.openDialog(PromotionalProductDescriptionDialogComponent, dialogConfig, matDialogConfig);

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
