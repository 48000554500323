import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiTasksListApiService } from './kalgudi-tasks-list-api.service';

@Injectable()
export class KalgudiTasksListService {

  constructor(private api: KalgudiTasksListApiService) { }

  /**
   * Calls api method to get task list
   * @param projectId
   * @param offset
   * @param limit
   */
  getTasks(offset: number, limit: number, projectId: string, profileKey: string, filters: PartialData = {}): Observable<KalgudiStreamData> {
    return this.api.fetchStream(offset, limit, projectId, profileKey, filters);
  }

  /**
   * Makes an API call to search list of all tasks with the specified
   * search keyword.
   * @param searchKeyword
   * @param offset
   * @param limit
   * @param projectId
   */
  searchTasks(
    searchKeyword: string,
    offset: number,
    limit: number,
    projectId: string,
    profileKey: string,
    filters: PartialData = {}
  ): Observable<KalgudiStreamData> {
    return this.api.searchTasks(searchKeyword, offset, limit, projectId, profileKey, filters);
  }
}
