import { Component, Injector, OnInit, ViewChild } from '@angular/core';

import { KalgudiCooperativeCropsList } from '../../classes/kalgudi-cooperative-crops-list';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-cooperative-society-crops-list',
  templateUrl: './cooperative-society-crops-list.component.html',
  styleUrls: ['./cooperative-society-crops-list.component.scss']
})
export class CooperativeSocietyCropsListComponent extends KalgudiCooperativeCropsList implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  isPublicPage: boolean = false;

  constructor(protected injector: Injector, private router: Router,) {
    super(injector);
  }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  closeMenu(event: MouseEvent): void {
    this.trigger.closeMenu();
  }

  onDestroyed(): void {}
}
