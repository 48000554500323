import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiStoreProduct } from '@kalgudi/types';

import { KalgudiDialog } from '../../../kalgudi-dialogs/classes/kalgudi-dialog';

@Component({
  selector: 'kl-store-products-search-dialog',
  templateUrl: './store-products-search-dialog.component.html',
  styleUrls: ['./store-products-search-dialog.component.scss']
})
export class StoreProductsSearchDialogComponent extends KalgudiDialog<StoreProductsSearchDialogComponent> implements OnInit {

  products: KalgudiStoreProduct[];

  constructor(
    protected dialogRef: MatDialogRef<StoreProductsSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

  }

  ngOnInit() {
  }

  /**
   * Triggered when child class emits an event
   */
  onProductsSelection(products: KalgudiStoreProduct[]): void {

    this.products = products;
    this.ok();
  }
  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        products: this.products
      }
    };
    this.dialogRef.close(result);
  }

}
