import { Injector, Input, Directive } from '@angular/core';
import { KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiAIPredictions, KalgudiPageDetails, PartialData } from '@kalgudi/types';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { AiPredictionsService } from '../services/ai-predictions.service';


@Directive()
export abstract class AiPredictionsFiltersList extends KalgudiDestroyable {

  @Input()
  pageId: string;

  profileKey: string;
  inputsList: any[] = [];
  outputsList: any[] = [];
  informationList: any[] = [];
  aiStats: any;
  filterFormDetails: any;
  isShowFilterForm: boolean;
  progress: boolean;
  aiPredictions: KalgudiAIPredictions;
  pageDetails: KalgudiPageDetails;

  fpoList: any;

  protected kalgudiAppService: KalgudiAppService;
  protected aiPredictionService: AiPredictionsService;
  private notification: KalgudiNotification;
  private kalgudiPageService: KalgudiPageService;
  protected util: KalgudiUtilityService;
  countries: any;
  states: any;
  products: any;
  businessTypes: any;
  districts: any;
  locations: any;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.kalgudiAppService   = this.injector.get(KalgudiAppService);
    this.aiPredictionService = this.injector.get(AiPredictionsService);
    this.notification        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.kalgudiPageService  = this.injector.get(KalgudiPageService);

    this.profileKey = this.kalgudiAppService.profileLocal.profileKey;

    this.kalgudiPageService.pageDetails$
    .pipe(
      first(),
    ).subscribe(pageDetails => {

      this.pageDetails = pageDetails;
    });

  }


  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------


  /**
   * showing filters form
   */
  showFiltersForm() {
    this.isShowFilterForm = !this.isShowFilterForm;
  }

  /**
   * Fetches calendar summary details
   */
  fetchCalendarSummaryDetails(res: any) {

    let filterDetails = {
      products: this.products[res.filter.products[0]],
      businessTypes: res.filter.businessTypes.length > 0 ? this.businessTypes[res.filter.businessTypes[0]] : '',
      locations: res.filter.locations.length > 0 ? this.locations[res.filter.locations[0]] : '',
      countries: res.filter.countries.length > 0 ? this.countries[res.filter.countries[0]] : '',
      states: res.filter.states.length > 0 ? this.states[res.filter.countries[0]][res.filter.states[0]] : '',
      districts: res.filter.districts.length > 0 ? this.districts[res.filter.states[0]][res.filter.districts[0]] : ''
    }

    this.filterFormDetails = filterDetails

  }

  fetchFilteredAiList(res: any) {
    this.informationList = res.results && res.results.informationList ? res.results.informationList : [];
    this.inputsList = res.results && res.results.inputList ? res.results.inputList : [];
    this.outputsList = res.results && res.results.outputList ? res.results.outputList : [];
    this.aiStats = res.stats ? res.stats : {};
    this.fpoList = res.stats && res.stats.fpoList ? res.stats.fpoList : [];
    // this.aiPredictions = res;
  }

  /**
   * Gets AI predictions filters
   */
  getAiPredictionsFilters() {

    this.aiPredictionService.getAIPredictionsFilters(this.pageId, {pageId: this.pageId})
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.products = res.products;
          this.businessTypes = res.businessTypes;
          this.locations = res.locations;
          this.countries = res.countries;
          this.states = res.statesOrRegions;
          this.districts = res.districts;
        }
      )
  }

  /**
   * Gets AI predictions list
   */
  getAiPredictions(filters: PartialData = {}) {

    this.notification.showSpinner();
    this.progress = true;

    this.aiPredictionService.getAIPredictions(this.pageId, filters)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.notification.hideSpinner(),
          this.progress = false;
        })
      )
      .subscribe(
        res => {
          this.informationList = res.results.informationList;
          this.inputsList = res.results.inputList;
          this.outputsList = res.results.outputList;
          this.aiStats = res.stats;
          // this.aiPredictions = res;
        }
      )
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
