<div class="task-creation-dialog w-100 p-2">
  <div class="dialog-data" fxLayout fxLayoutAlign="center center">

    <div class="h-100" fxLayout="column" fxLayoutAlign="space-between" fxFlex>

      <!-- Header -->
      <header class="w-100 position-relative" fxLayout="row" fxHide.xs>
        <div class="w-100 header-title" fxLayoutAlign="center center">
          <strong>
            <span class="title" translate>
              {{dialogData?.data?.taskId ? 'Edit task' : 'Start a new task'}}
            </span>
          </strong>
        </div>

        <div class="closed position-absolute">
          <button mat-icon-button (click)="cancel()">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
      </header>
      <!-- / Header -->

      <!-- TaSK creation -->
      <div class="task-creation-dialog overflow-auto">

        <kl-task-creation
          [projectId]="dialogData?.data?.projectId"
          [taskId]="dialogData?.data?.taskId"
          [filters]="dialogData?.data?.filters"
          (taskCreated)="onTaskCreation($event)">
        </kl-task-creation>
      </div>
      <!-- / Task creation -->

    </div>

  </div>

</div>
