import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { CORE_LIB_CONFIG } from '@app/config';
import { SharedModule } from '@app/shared';
import {
  KalgudiCommonModule,
  KalgudiLoadMoreModule,
  KalgudiMobileDialogModule,
  KalgudiNoContentModule,
  MobileTabsModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiBottomSheetService, MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiNetworksModule } from '@kalgudi/networks';
import { KalgudiPageAiPredictionsModule } from '@kalgudi/programs';
import { KalgudiProjectFullviewModule } from '@kalgudi/project-manager';
import { KalgudiQaModule } from '@kalgudi/qa';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiHomeStreamModule, KalgudiSocialModule } from '@kalgudi/social';
import { KalgudiWeatherModule } from '@kalgudi/weather';
import { NguCarouselModule } from '@ngu/carousel';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ScheduledUpdateStreamModule } from '../../shared/components/scheduled-update-stream/scheduled-update-stream.module';
import { HomeRoutingModule } from './home-routing.module';
import { HomeStoreBannerTileComponent } from './home-store-banner-tile/home-store-banner-tile.component';
import { HomeStreamComponent } from './home-stream/home-stream.component';
import { HomeComponent } from './home.component';
import { ProfileDetailsComponent } from './profile-details/profile-details.component';
import { ShareTabComponent } from './share-tab/share-tab.component';
import { ShareUpdatePageComponent } from './share-update-page/share-update-page.component';
import { StoreBannerTileDialogComponent } from './store-banner-tile-dialog/store-banner-tile-dialog.component';
import { StoreBannerTileImagesComponent } from './store-banner-tile-images/store-banner-tile-images.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [
    HomeComponent,
    HomeStreamComponent,
    ProfileDetailsComponent,
    ShareTabComponent,
    ShareUpdatePageComponent,
    HomeStoreBannerTileComponent,
    StoreBannerTileDialogComponent,
    StoreBannerTileImagesComponent,
  ],
  imports: [
    AngularSvgIconModule,

    CommonModule,

    // Routing
    HomeRoutingModule,

    // Material & Flex
    FlexLayoutModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatSelectModule,

    FormsModule,
    ReactiveFormsModule,

    ScheduledUpdateStreamModule,

    KalgudiButtonModule,
    KalgudiMobileDialogModule,
    MobileMenuBottomSheetModule,
    KalgudiNoContentModule,

    SharedModule,
    KalgudiProjectFullviewModule,

    // Kalgudi Core lib
    KalgudiCoreModule,
    KalgudiCommonModule.forChild(CORE_LIB_CONFIG),
    KalgudiSocialModule.forChild(CORE_LIB_CONFIG),
    KalgudiShareUpdateModule.forChild(CORE_LIB_CONFIG),
    KalgudiQaModule.forChild(CORE_LIB_CONFIG),
    KalgudiWeatherModule,
    KalgudiButtonModule,
    KalgudiMobileDialogModule,
    KalgudiNetworksModule,
    KalgudiLoadMoreModule,
    KalgudiPipesModule,
    KalgudiHomeStreamModule,
    MobileTabsModule,
    KalgudiPageAiPredictionsModule,

    TranslateModule,

    NguCarouselModule,
  ],
  entryComponents: [
    StoreBannerTileDialogComponent
  ],
  exports: [
    ShareTabComponent
  ],
  providers: [
    KalgudiBottomSheetService,
  ]
})
export class HomeModule { }
