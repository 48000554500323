import { ProjectType } from '@kalgudi/types';

export const PROJECT_TYPE_TITLE_MAP: {[key in ProjectType]: string} = {
  CROP_CALENDAR: 'Crop calendar',
  GENERIC: 'Generic',
  ROADMAP_CREATION: 'Roadmap',
  SOFTWARE_DEVELOPMENT: 'Software development'
};

export const TASK_TAGS_INFO = {
  type: "string",
  label: "Information",
  title: "Information",
  widget:{className: "col-12, p-0"},
}

export const TASK_TAGS_INPUT = {
  type: "object",
  title: "Input",
  properties: {
    product: {
      label: "Product",
      title: "Product",
      type: "tagsBaseProduct",
      widget:{className: "col-12 p-0"}
    },
    cost: {
      label: "Cost",
      title: "Cost",
      type: "number",
      widget:{className: "col-12 p-0"}
    },
    dosesPerAcre: {
      type: "object",
      description: 'Doses per acreage',
      widget: { fieldGroupClassName: "row", className: "m-0" },
      properties: {
        value: {
          label: "Value",
          title: "Value",
          type: "string",
          widget: {className: "col-6 pl-0"}
        },
        unit: {
          label: "Unit",
          title: "Unit",
          type: "string",
          widget: {className: "col-6 pl-0 pr-0"},
          enum: [
            "grams", "milliliters", "liters", "kgs", "tons", "quintals", "pieces"
          ],
        }
      }
    }
  }

}

export const TASK_TAGS_OUTPUT =  {
  type: "object",
  title: "Output",
  properties: {
    product: {
      label: "Product",
      title: "Product",
      type: "tagsBaseProduct",
      widget:{className: "col-12 p-0"}
    },
    cost: {
      label: "Cost",
      title: "Cost",
      type: "number",
      widget:{className: "col-12 p-0"}
    },
    expectedYieldPerAcre: {
      type: "object",
      description: 'Expected yield per acreage',
      widget: { fieldGroupClassName: "row", className: "m-0" },
      properties: {
        value: {
          label: "Value",
          title: "Value",
          type: "string",
          widget: {className: "col-6 pl-0"},
        },
        unit: {
          label: "Unit",
          title: "Unit",
          type: "string",
          widget: {className: "col-6 pl-0 pr-0"},
          enum: [
            "grams", "milliliters", "liters", "kgs", "tons", "quintals", "pieces"
          ],
        }
      }
    }
  }

}

export const TASK_TAGS_SERVICES =  {
  type: "object",
  title: "Services",
  properties: {
    product: {
      label: "Product",
      title: "Product",
      type: "tagsBaseProduct",
      widget:{className: "col-12 p-0"}
    },
    cost: {
      label: "Cost",
      title: "Cost",
      type: "number",
      widget:{className: "col-12 p-0"}
    },
    noOfHourPerAcre: {
      label: "No of hour per acre",
      title: "No of hour per acre",
      type: "number",
      widget:{className: "col-12 p-0"}
    }
  }

}
