import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-likes-list-mobile-dialog',
  templateUrl: './likes-list-mobile-dialog.component.html',
  styleUrls: ['./likes-list-mobile-dialog.component.scss']
})
export class LikesListMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  id: string;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);

    this.id = this.dialogData.data.id;
  }

  ngOnInit() {
  }

  /**
   * Close the dialog box on navigation to user profile page
   */
  profilePageOpen() {
    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }
}
