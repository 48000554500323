import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-profile-about',
  templateUrl: './profile-about.component.html',
  styleUrls: ['./profile-about.component.scss']
})
export class ProfileAboutComponent implements OnInit {

  @Output()
  showTCDialog = new EventEmitter();

  @Input()
  user: KalgudiUser;

  @Input()
  editable: boolean;

  editFlag = true;

  constructor(private appService: KalgudiAppService) { }

  ngOnInit() {
  }

  /**
   * Get loggedinuser profile key
   */
  get loggedInUserName() {
    return this.appService.profileLocal.firstName;
  }

  /**
   * Toggle edit profile
   */
  editToggle(): void {
    this.editFlag = !this.editFlag;
  }

  /**
   * Toggle edit when event emitting from parent
   * @param flag
   */
  showEdit(flag: boolean): void {
    this.editFlag = flag;
  }

  /**
   * Shows terms and conditions dialog
   * @param event
   */
  showTermsAndConditionsDialog(event: any) {
    this.showTCDialog.emit();
  }

}
