<mat-card fxFlex="100%" fxLayout="column" class="board-members-tile">

  <!-- Members header -->
  <kl-common-profile-header
    title="{{ environment?.appId === 'SHG_NET_APP' ? 'SHG Members' : 'Board Members'}}"
    svgIcon="assets/svgs/fpo-board-members.svg"
    actionIcon="edit"
    [editable]="editable && profileDetails?.boardOfMembers?.length"
    (action)="showBoardMembersDialog()">
  </kl-common-profile-header>
  <!-- /Members header -->

  <section *ngIf="profileDetails?.boardOfMembers || (profileDetails?.boardOfMembers && profileDetails?.boardOfMembers?.length)">

    <div  *ngFor="let member of profileDetails?.boardOfMembers | slice:0:2 ; let last = last">
      <div class="farmer-crops-header" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <div fxFlex="calc(60% - 10px)" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <p class="m-0 title font-weight-500 w-100 text-truncate" translate>{{ member?.name }}</p>
          <ng-container *ngIf="!isPublicPage">
            <p class="m-0 text-muted w-100 text-truncate" translate *ngIf="(loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].businessTypeName === availableBusinessTypeNames.FPO || loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName === nativeBusinessTypeNames.PRODUCER_ORG)">{{ member?.mobile }}</p>
          </ng-container>
        </div>

        <div fxFlex="40%">
          <p class="m-0 title text-secondary w-100 text-truncate" translate>{{ member?.designation || '-' }}</p>
        </div>
      </div>

      <div *ngIf="!last" class="border-bottom pt-2 mb-2">
      </div>

    </div>

    <div class="w-100 mt-2" fxLayout fxLayoutAlign="end end" *ngIf="profileDetails?.boardOfMembers?.length > 2">
      <a class="view-all" (click)="viewMembersDialog()" translate>View all</a>
    </div>
  </section>

  <!-- No content if there no board members -->
  <ng-container *ngIf="!profileDetails?.boardOfMembers || (profileDetails?.boardOfMembers && !profileDetails?.boardOfMembers?.length)">
    <div class="w-100" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? 'No members added yet!' : editable ? '' : 'No members added yet!'"
        [actionLink]="isPublicPage ? '' : editable ? 'Add members' : ''"
        (action)="showBoardMembersDialog()">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no board members -->
</mat-card>
