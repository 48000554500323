import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyDetails, PartialData, SurveyBasicDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiCreateSurveyService } from '../services/create-survey.service';

@Directive()
export abstract class KalgudiCreateSurvey extends KalgudiDestroyable {

  /**
   * A custom payload added by the parent component. All values in the payload
   * is passed in the payload to the survey creation API.
   *
   * Create survey form values are replaced by this object values so use it
   * with caution.
   */
  @Input()
  creationPayload: PartialData = {};

  surveyForm: FormGroup;

  progress = false;

  readonly surveyExpirationMinDate = new Date();


  // Dependencies
  private uploadImageTos3: KalgudiUploadService;
  private util: KalgudiUtilityService;
  private fb: FormBuilder;
  private notifications: KalgudiNotification;
  private createSurveyService: KalgudiCreateSurveyService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.notifications       = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.uploadImageTos3     = this.injector.get(KalgudiUploadService);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.fb                  = this.injector.get(FormBuilder);
    this.createSurveyService = this.injector.get(KalgudiCreateSurveyService);

    this.surveyForm = this.newSurveyForm;
  }



  // --------------------------------------------------------
  // #region Setters and Getters methods
  // --------------------------------------------------------

  get surveyLogoUrlControl(): AbstractControl {
    return this.surveyForm.get('logo');
  }

  /**
   * Constructs a new survey form
   */
  private get newSurveyForm(): FormGroup {

    return this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      logo: [''],
      location: ['', Validators.required],
      expiryDate: ['', Validators.required],
      isResubmittable: [true],
      isPublished: [false]
    });
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Uploads image to S3
   * @param fileEvent
   */
  updateSurveyLogo(fileEvent: any): void {

    const path = KALGUDI_S3_POLICY_MAP.BUSINESS;

    this.uploadImageTos3.uploadFile(fileEvent.target.files[0], path)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.surveyLogoUrlControl.patchValue(res.filePath),
        err => this.onApiError(err)
      );

  }


  /**
   * Calls the create api
   */
  create(): void {

    const payload = this.prepareCreationPayload(this.surveyForm.value);

    this.progress = true;

    this.createSurveyService.createSurvey(payload)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onSurveyCreation(res),
        err => this.onApiError(err)
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and Protected interfacing methods
  // --------------------------------------------------------

  protected abstract onSurveyCreation(res: KalgudiSurveyDetails): void;

  /**
   * Prepares the payload
   */
  protected prepareCreationPayload(formValue: any): SurveyBasicDetails {

    // Creation payload from the parent form
    // Values that are being passed from the parent component are
    // overrided by the survey form values.
    const parentCreationPayload = this.util.clone(this.creationPayload || {});

    return {
      ...this.util.clone(formValue),
      ...parentCreationPayload,
    };
  }

  /**
   * Event handler for share update posting API errors.
   */
  protected onApiError(err: any): void {

    this.notifications.showMessage(err.message || err.error.message);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
