<div id="upload-image-mobile" class="h-100">

  <div>
    <kl-add-program-members
    (selectedUsers)="membersSelected($event)"
    (closeMembersDialog)="cancel()"
    [pageId]="dialogData?.extraParams?.pageId"
    [showExtraFields]="dialogData?.extraParams?.showExtraFields">
    </kl-add-program-members>
  </div>

</div>
