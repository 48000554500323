import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import {
  KalgudiPageAddNewUserPayload,
  KalgudiPageAddNewUsersResponse,
  KalgudiPageRelation,
  KalgudiUserBasicDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import { ApiPageMemberAddResponseData } from '../typings';
import { KalgudiPageMemberApiService } from './kalgudi-page-member-api.service';

/**
 * Kalgudi page members management service.
 */
@Injectable()
export class KalgudiPageMembersService {

  constructor(
    private api: KalgudiPageMemberApiService,
  ) { }


  // --------------------------------------------------------
  // #region Admins management
  // --------------------------------------------------------

  /**
   * Fetches list of admins of the page from the Api.
   */
  getAdmins(pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.getMember(pageId, 'ADMIN', offset, limit);
  }

  /**
   * Adds an admin to a page.
   */
  addAdmins(pageId: string, usersList: KalgudiUserBasicDetails[]): Observable<ApiPageMemberAddResponseData> {
    return this.addMembers(pageId, usersList, 'ADMIN');
  }

  /**
   * Removes a list of users from a page
   */
  deleteAdmins(pageId: string, profileKeyList: string[]): Observable<ApiPageMemberAddResponseData> {
    return this.deleteMembers(pageId, profileKeyList);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Contributors management
  // --------------------------------------------------------

  /**
   * Fetches list of contributors of the page from the Api.
   */
  getContributors(pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.getMember(pageId, 'CONTRIBUTOR', offset, limit);
  }

  /**
   * Adds contributors to a page.
   */
  addContributors(pageId: string, usersList: KalgudiUserBasicDetails[]): Observable<ApiPageMemberAddResponseData> {
    return this.addMembers(pageId, usersList, 'CONTRIBUTOR');
  }

  /**
   * Removes a list of users from a page
   */
  deleteContributors(pageId: string, profileKeyList: string[]): Observable<ApiPageMemberAddResponseData> {
    return this.deleteMembers(pageId, profileKeyList);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Followers management
  // --------------------------------------------------------


  /**
   * Fetches list of followers of the page from the Api.
   */
  getFollowers(pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.getMember(pageId, 'FOLLOWER', offset, limit);
  }

  /**
   * Adds contributors to a page.
   */
  addFollowers(pageId: string, usersList: KalgudiUserBasicDetails[]): Observable<ApiPageMemberAddResponseData> {
    return this.addMembers(pageId, usersList, 'FOLLOWER');
  }

  /**
   * Removes a list of users from a page
   */
  deleteFollower(pageId: string, profileKeyList: string[]): Observable<ApiPageMemberAddResponseData> {
    return this.deleteMembers(pageId, profileKeyList);
  }

  /**
   * Calls api method to bulk members to a page using ids.
   */
  addBulkMembers(pageId: string, payload: any): Observable<ApiPageMemberAddResponseData> {

    return this.api.addBulkMembers(pageId, payload, 'FOLLOWER');
  }

  /**
   * Calls api method to document bulk members.
   */
    addDocumentsBulkMembers(pageId: string, s3Url: string): Observable<any> {

      return this.api.addDocumentsBulkMembers(pageId, s3Url);
    }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * Calls api method to add new users to page
   */
  addNewUser(payload: KalgudiPageAddNewUserPayload): Observable<KalgudiPageAddNewUsersResponse> {

    return this.api.addNewUser(payload);
  }


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Fetches, members list from the pages Api.
   */
  private getMember(pageId: string, memberRole: KalgudiPageRelation, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.api.fetchMembers(pageId, memberRole, offset, limit);
  }

  /**
   * Adds a member to a kalgudi page.
   */
  private addMembers(
    pageId: string,
    usersList: KalgudiUserBasicDetails[],
    memberRole: KalgudiPageRelation
  ): Observable<ApiPageMemberAddResponseData> {

    return this.api.addMembers(pageId, usersList, memberRole);
  }

  /**
   * Removes a program member from the page
   */
  private deleteMembers(pageId: string, usersProfileKeyList: string[]): Observable<ApiPageMemberAddResponseData> {
    return this.api.deleteMembers(pageId, usersProfileKeyList);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
