import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageProjectBulkMembers } from '../../classes/kalgudi-page-project-bulk-members';

@Component({
  selector: 'kl-page-project-bulk-members',
  templateUrl: './page-project-bulk-members.component.html',
  styleUrls: ['./page-project-bulk-members.component.scss']
})
export class PageProjectBulkMembersComponent extends KalgudiPageProjectBulkMembers implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
