<mat-card class="my-2 p-0 assist-profile-tasks" @bottomFloatingCard>

  <!-- Title -->
  <div fxLayout="row" class="px-3 pt-3 pb-0" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>
    <div>
      <svg-icon src="assets/svgs/tasks.svg" class="profile-icon" [svgStyle]="{'width.px': '25', 'height.px': '25', 'fill': 'rgb(149, 117, 205)'}"></svg-icon>
    </div>
    <div>
      <p class="mb-0 title" translate> Tasks</p>
    </div>
  </div>
  <!-- /Title -->

  <!-- Dialog-box to add members -->
  <div id="kalgudi-profile-tasks-form" class="p-0">
    <form autocomplete="off" [formGroup]="profileUpdateForm">
      <div class="p-3 share-update-form" fxLayout fxLayoutGap="10px">
        <div fxFlex>
          <kl-assist-members-picker [formControl]="assistedUser">
          </kl-assist-members-picker>
        </div>
        <div class="view-task-button">
          <kl-flat-button
            [label]="'View tasks' | translate"
            [disabled]="!assistedUser?.value?.profileKey"
            (clicked)="openProfileView(assistedUser?.value?.profileKey)">
          </kl-flat-button>
        </div>
      </div>
    </form>
  </div>
  <!-- /Dialog-box to add members -->

</mat-card>
