import { InjectionToken } from '@angular/core';

/**
 * Specifies Kalgudi photoswipe assets location
 *
 * @author Pankaj Prakash
 */
export interface KalgudiPhotoswipeAssetsConfig {
  scripts: string[];
  css: string[];
}

export const KL_PS_ASSETS = new InjectionToken<KalgudiPhotoswipeAssetsConfig>('KL_PS_ASSETS');
