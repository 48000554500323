import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { Attachment, KalgudiImageDialogConfig } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiCameraFormControl } from '../../classes/kalgudi-camera-form-control';
import { KalgudiImagePickerService } from '../../services/kalgudi-image-picker.service';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CameraFormControlComponent),
  multi: true,
};
@Component({
  selector: 'kl-camera-form-control',
  templateUrl: './camera-form-control.component.html',
  styleUrls: ['./camera-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class CameraFormControlComponent extends KalgudiCameraFormControl implements OnInit {

  @Input()
  buttonType: string;

  @Input()
  showChipButton = false;

  @Input()
  showBorder: boolean;

  @Input()
  showIconButton: boolean;

  constructor(
    protected injector: Injector,
    private imagePickerService: KalgudiImagePickerService,
  ) {

    super(injector);
  }

  ngOnInit() { }

  onDestroyed() { }

  /**
   * Shows the picker dialog where we can pick the attachments
   */
  protected showPickerDialog(dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig): Observable<Attachment> {

    return this.imagePickerService.showCaptureImageUpload(dialogDetails, dialogConfig)
    .pipe(
      // Subscribe till `$destroyed` is not emitted
      takeUntil(this.destroyed$),

      // Filter only accepted actions, do nothing for cancel actions
      filter(dialogRes => dialogRes && dialogRes.accepted),

      // Transform the partial data to Attachment type
      map(dialogRes => dialogRes.data.attachment)
    );
  }

}
