<section class="picker">
  <div class="px-4 pt-4 main-wrapper">
    <div id="user-search" class="pb-2">

      <!-- Search bar -->
      <kl-search placeHolder="Search your connects" [searchForm]="searchForm" (search)="search()"></kl-search>
      <!-- / Search bar -->

    </div>

    <!-- <div *ngIf="searchKeyword" class="mt-1">
      <p class="mb-0 count">Found <span><b>{{ (paginator$ | async)?.count }}</b></span> results for
        <span><b>"{{ searchKeyword }}"</b></span></p>
    </div> -->

    <!-- List of users -->
    <ng-container *ngIf="(paginator$ | async)?.count">
      <div class="list overflow-auto mt-4">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-2 list-wrapper">
          <div *ngFor="let user of (stream$ | async);" fxFlex="48%" fxFlex.xs="100%" class="mb-3"
            (click)="toggleUserSelection(user)"
            [ngClass]="{'selected': !!(selectedUsersMap[user?.profileKey])}">
            <kl-selectable-user-tile [author]="user" avatarSize="md"
              [isSelected]="!!(selectedUsersMap[user?.profileKey])">
            </kl-selectable-user-tile>
          </div>
        </div>

        <!-- Load more -->
        <div class="mt-3">
          <kl-load-more noMoreText="No more users"
            [progress]="(paginator$ | async).loading"
            [hasMoreItems]="(paginator$ | async).hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
      </div>
    </ng-container>

    <!-- No content -->
    <div *ngIf="!(paginator$ | async)?.count" fxLayoutAlign="center center" class="pb-2 h-100 no-content">
      <kl-no-content matIcon="people" text="Type and search users" iconColor="#00000075">
      </kl-no-content>
    </div>

  </div>
</section>
