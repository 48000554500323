import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { finalize } from 'rxjs/operators';

import { SatelliteAdvisoriesSyncService } from '../../services/satellite-advisories-sync.service';

@Component({
  selector: 'kl-satellite-advisories-schedule-updates-dialog',
  templateUrl: './satellite-advisories-schedule-updates-dialog.component.html',
  styleUrls: ['./satellite-advisories-schedule-updates-dialog.component.scss']
})
export class SatelliteAdvisoriesScheduleUpdatesDialogComponent extends
  KalgudiDialog<SatelliteAdvisoriesScheduleUpdatesDialogComponent> implements OnInit {

  pageId: string;

  syncId: string;

  scheduleUpdatesForm: FormGroup;
  progress: boolean;

  constructor(
    protected dialogRef: MatDialogRef<SatelliteAdvisoriesScheduleUpdatesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    private fb: FormBuilder,
    private satelliteAdvisoriesService: SatelliteAdvisoriesSyncService,
    private klUtil: KalgudiUtilityService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
  ) {
    super(dialogRef);

    // Initialize share update form
    this.scheduleUpdatesForm = this.newScheduleUpdatesForm;

    this.pageId = this.data.data.pageId;
    this.syncId = this.data.data.syncId;
  }

  ngOnInit() {
  }

  /**
   * Getter for schedule date field
   */
  get scheduledDateField(): AbstractControl {
    return this.scheduleUpdatesForm.get('scheduledDate');
  }

  /**
   * Creates a new schedule updates form array
   */
  private get newScheduleUpdatesForm(): FormGroup {
    return this.fb.group({

      scheduledDate: [''],
      scheduledTime: [''],
      scheduledTS: [''],
    });
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

  /**
   * Calls api method to send schedule updates
   */
  sendScheduleUpdates() {

    const payload = this.getCreationPayload(this.scheduleUpdatesForm.value);

    // this.notification.showSpinner(true);
    this.progress = true;

    this.satelliteAdvisoriesService.shareAdvisories(this.pageId, this.syncId, payload)
      .pipe(
        finalize(() => this.progress = false),
      )
      .subscribe(
        res => {
          this.ok();
          // this.notification.showMessage('We are processing advisories and will be sent to farmers soon!')
        },
        err => this.klUtil.showApiErrorMessage(err),
      );
  }

  /**
   * Gets, the schedule updates API request payload.
   */
  protected getCreationPayload(shareFormValue: any): any {

    if (shareFormValue.scheduledDate && shareFormValue.scheduledTime ) {

      const date = new Date(shareFormValue.scheduledDate);

      const dateAndTime = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${shareFormValue.scheduledTime}`;

      shareFormValue.scheduledTS = new Date(dateAndTime).toISOString();
    }

    shareFormValue.syncId = this.syncId;

    return shareFormValue;
  }


}
