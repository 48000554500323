import { Injectable, Type } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import {
  AddSurveyMembersDialogComponent,
} from '../components/add-survey-members-dialog/add-survey-members-dialog.component';
import {
  AddSurveyMembersMobileDialogComponent,
} from '../components/add-survey-members-mobile-dialog/add-survey-members-mobile-dialog.component';
import {
  SurveysMembersSearchDialogComponent,
} from '../components/surveys-members-search-dialog/surveys-members-search-dialog.component';
import {
  SurveysMembersSearchMobileDialogComponent,
} from '../components/surveys-members-search-mobile-dialog/surveys-members-search-mobile-dialog.component';

@Injectable()
export class SurveyMembersActionService {

  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
  ) { }

  /**
   * Open add survey members dialog
   */
  showAddSurveyMembersDialog(): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add survey members',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '70vw',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.openAddSurveyMembersDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res.accepted),
      );
  }

  /**
   * Shows surveys members search dialog with default params
   */
  showMembersSearchDialog(): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Search survey members',
      acceptButtonTitle: 'Select',
      rejectButtonTitle: 'Cancel',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '70vw',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.openDialog(SurveysMembersSearchDialogComponent, SurveysMembersSearchMobileDialogComponent, dialogDetails, dialogConfig)
      .pipe(
        filter(res => res && res.accepted),
      );

  }

  /**
   * Opens add survey members dialog
   */
  openAddSurveyMembersDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {
    return this.openDialog(AddSurveyMembersDialogComponent, AddSurveyMembersMobileDialogComponent, details, config);
  }

  /**
   * Opens web or mobile dialog based on mobile device
   */
  private openDialog(component: Type<any>, mobileComponent: Type<any>, details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.kalgudiDialogService.openMobileDialog(mobileComponent, details)
      : this.kalgudiDialogService.openDialog(component, details, config);
  }
}
