import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiSidebarModule } from '@kalgudi/common/ui/sidebar';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiTasksModule } from '../tasks/tasks.module';
import { HomeTaskTileComponent } from './components/home-task-tile/home-task-tile.component';
import { ProjectFullviewComponent } from './components/project-fullview/project-fullview.component';
import { KalgudiTasksListApiService } from './services/kalgudi-tasks-list-api.service';
import { KalgudiTasksListService } from './services/kalgudi-tasks-list.service';
import { UsersTaskTileComponent } from './components/users-task-tile/users-task-tile.component';
import { FarmersTaskTileComponent } from './components/farmers-task-tile/farmers-task-tile.component';
import { KalgudiTaskTileModule } from '@kalgudi/common';


@NgModule({
  declarations: [
    ProjectFullviewComponent,
    HomeTaskTileComponent,
    UsersTaskTileComponent,
    FarmersTaskTileComponent
  ],
  imports: [
    CommonModule,

    // Flex module
    FlexLayoutModule,

    // Material module
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    AngularSvgIconModule,

    // Kalgudi modules
    KalgudiSidebarModule,
    KalgudiTasksModule,
    KalgudiTaskTileModule,

    TranslateModule
  ],
  exports: [
    ProjectFullviewComponent,
    HomeTaskTileComponent
  ],
  providers: [
    KalgudiTasksListApiService,
    KalgudiTasksListService,
  ]
})
export class KalgudiProjectFullviewModule { }
