<main id="frequent-members-picker">
  <div class="tab-groups">
    <kl-enable-offline-da
      [pageId]="pageId"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-enable-offline-da>
  </div>

  <!-- Buttons -->
  <div class="pt-0 mx-2 mt-1 mb-2 buttons" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="30px">
    <div fxFlex.xs="50%">
      <kl-stroked-button label="Cancel" buttonColor="warn" (clicked)="cancel()"></kl-stroked-button>
    </div>
    <div fxFlex.xs="50%">
      <kl-flat-button label="Save" buttonColor="primary" (clicked)="ok()"></kl-flat-button>
    </div>
  </div>
  <!-- / Buttons -->
</main>
