import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-value-type-list',
  templateUrl: './value-type-list.component.html',
  styleUrls: ['./value-type-list.component.scss'],
})
export class ValueTypeListComponent implements OnInit {

  @Input()
  editable: boolean;

  @Input()
  items: any[];

  @Input()
  itemTitleFieldName: string;

  @Input()
  itemDescriptionFieldName: string;

  @Input()
  titleText: string;

  @Input()
  descriptionText: string;

  @Output()
  edit = new EventEmitter<any>();

  @Output()
  delete = new EventEmitter<any>();

  constructor() {
    this.titleText = 'Title';
    this.descriptionText = 'Other details';
  }

  ngOnInit() {

  }

  editDetails(item: any): void {
    this.edit.emit(item);
  }

  deleteItem(item: any): void {
    this.delete.emit(item);
  }
}
