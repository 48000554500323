import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialogRef } from '@kalgudi/common';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';
import { KalgudiShareUpdateStreamStateService } from '@kalgudi/social';
import { ProgramShareUpdate } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-program-share-training-form',
  templateUrl: './mobile-program-share-training-form.component.html',
  styleUrls: ['./mobile-program-share-training-form.component.scss']
})
export class MobileProgramShareTrainingFormComponent implements OnInit {

  constructor(
    protected shareUpdateStreamState: KalgudiShareUpdateStreamStateService,
    private dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification
  ) { }

  ngOnInit(): void {
  }

  onPostCreated(event: any): void {
    if (event && !event.scheduledTS) {
      this.pushItemToHomeStream(event);
    } else {
      this.notifications.showMessage(`Post scheduled`);
    }
    this.dialogRef.close();
  }

  /**
   * Pushes latest posted item to the stream
   * @param val
   */
  private pushItemToHomeStream(val: ProgramShareUpdate): void {
    this.shareUpdateStreamState.unshiftToStream(val);
  }

}
