<section class="value-card" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="tile w-100 mb-2">

    <!-- Data Information -->
    <div class="data-info" fxLayout="row" fxLayoutGap="12px">
      <div class="icon" fxLayout="row" fxLayoutAlign="center center">
        <i class="fas fa-certificate certificate"></i>
      </div>

      <div class="data-details" fxLayout="row" fxLayoutAlign="space-between" fxFlex>
        <div class="details-info">
          <h2 class="profile-title m-0" translate>Certifications </h2>
          <p class="mb-0" translate>Add your Certifications by clicking on edit Icon</p>
        </div>

        <div fxLayoutAlign="center center" (click)="showAddItemForm()" *ngIf="editable">
          <button mat-icon-button color="primary">
            <mat-icon>add</mat-icon>
          </button>

        </div>
      </div>
    </div>
    <!-- / Data Information -->

    <!-- Add Certifications-->
    <kl-value-type-form
      *ngIf="isSaveUpdateFormVisible"
      [form]="form"
      [saveInProgress]="saveItemProgress"
      titleFormControlName="certName"
      descriptionFormControlName="certAuthority"
      (closed)="hideAddItemForm()"
      (save)="saveOrUpdateItem()">
    </kl-value-type-form>
    <!-- / Add Certifications-->

    <!-- list of Certifications -->
    <kl-value-type-list
      [editable]="editable"
      [items]="profileSectionItems"
      itemDescriptionFieldName="certAuthority"
      itemTitleFieldName="certName"
      titleText="Name"
      descriptionText="Authority"
      (delete)="deleteItem($event);"
      (edit)="editItem($event)">
    </kl-value-type-list>
    <!-- / list of Certifications -->

  </mat-card>
</section>
