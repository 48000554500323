import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiQaModule } from '@kalgudi/qa';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';

import {
  ProfileSocialActivitiesComponent,
} from './components/profile-social-activities/profile-social-activities.component';
import { ProfileSocialActivitiesApiService } from './services/profile-social-activities-api.service';
import { ProfileSocialActivitiesService } from './services/profile-social-activities.service';



@NgModule({
  declarations: [
    ProfileSocialActivitiesComponent
  ],
  imports: [
    CommonModule,

    // Kalgudi modules
    KalgudiShareUpdateModule,
    KalgudiQaModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule
  ],
  exports: [
    ProfileSocialActivitiesComponent
  ],
  providers: [
    ProfileSocialActivitiesService,
    ProfileSocialActivitiesApiService
  ]
})
export class ProfileSocialActivitiesModule { }
