<mat-card fxLayout="row" fxLayoutAlign="center center"
  (click)="openPageActivity()"
  [ngClass]="{'page-stats-tile-hover': isClickable}"
  class="page-analytics-stats px-4">

  <div class="bg-white stats-tile" *ngIf="statsCount !== null"
    fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">

    <div fxLayoutAlign="center center" fxFlex="20%">
      <mat-icon @scaleOut *ngIf="matIcon"
        [ngStyle]="{ 'color': matIconColor }">
        {{matIcon}}
      </mat-icon>

      <div [@scaleOut] *ngIf="svgIcon">
        <svg-icon [src]="svgIcon" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"
          [class]="className"></svg-icon>
      </div>
    </div>

    <div fxFlex="80%">
      <h2 class="mb-0 text-center count"
        [ngStyle]="{ 'color': matIconColor }"
        [@floatRight]="{ value: '', params: {delay: 200} }">{{ statsCount | modernNumber }}</h2>
      <p class="mb-0 text-center" @fadeIn>{{ title | translate}}</p>
    </div>
  </div>

  <kl-fake-it-block *ngIf="statsCount === null"></kl-fake-it-block>
</mat-card>
