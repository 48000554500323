import { Component, Inject, Input, OnInit } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { SimilarQuestionDetails } from '@kalgudi/types';

import { KalgudiSocialRouteConfig } from '../../config';

@Component({
  selector: 'kl-list-widget',
  templateUrl: './list-widget.component.html',
  styleUrls: ['./list-widget.component.scss']
})
export class ListWidgetComponent implements OnInit {

  @Input()
  widgetList: SimilarQuestionDetails[];

  @Input()
  showCount = 5;

  constructor(@Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig) { }

  ngOnInit() {
  }

  /**
   * Navigates to QA full view
   */
  qaFullview(questionId: string): void {
    this.appRouting.toSocialQaFullview({questionId});
  }


}
