import { Inject, Injector, Directive } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { KalgudiDialogsService } from "@kalgudi/common";
import { KalgudiStreamData, KalgudiUtilityService } from "@kalgudi/core";
import { KalgudiNotification, KL_NOTIFICATION } from "@kalgudi/core/config";
import {
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiPageDetails,
  KalgudiRobocall,
  Paginator,
  PartialData,
} from "@kalgudi/types";
import { Observable, from, of } from "rxjs";
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { KalgudiPageInboxStream } from "../../../classes/kalgudi-page-inbox-stream";
import { KalgudiPageService } from "../../../services/kalgudi-page.service";
import { KalgudiRobocallStreamStateService } from "../services/kalgudi-robocall-stream-state.service";
import { RobocallStreamService } from "../services/robocall-stream.service";
// import { KalgudiSmsUpdateService } from "../../program-sms";

@Directive()
export abstract class KalgudiRobocallStream extends KalgudiPageInboxStream<KalgudiRobocall> {
  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  // Dependencies
  private kalgudiProgram: KalgudiPageService;
  private robocallStreamState: KalgudiRobocallStreamStateService;
  private kalgudiDialogs: KalgudiDialogsService;
  // private updateSms: KalgudiSmsUpdateService;
  private robocallStream: RobocallStreamService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService
  ) {
    super(notification, util);
    // Manually inject dependencies
    this.kalgudiProgram = this.injector.get(KalgudiPageService);
    this.robocallStreamState = this.injector.get(
      KalgudiRobocallStreamStateService
    );
    this.kalgudiDialogs = this.injector.get(KalgudiDialogsService);
    // this.updateSms = this.injector.get(KalgudiSmsUpdateService);
    this.robocallStream = this.injector.get(RobocallStreamService);

    this.pageLimit = 10;

    this.kalgudiProgram.pageDetails$.pipe(first()).subscribe((pageDetails) => {
      this.memberRole = pageDetails.memberRole;
    });
  }

  // --------------------------------------------------------
  // #region for public methods
  // --------------------------------------------------------

  /**
   * Gets the program details
   */
  get program$(): Observable<KalgudiPageDetails> {
    return this.kalgudiProgram.pageDetails$;
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  showScheduleSmsConfirmationDialog = (
    postDetails: KalgudiRobocall
  ): Observable<KalgudiRobocall> => {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: "Alert",
      acceptButtonTitle: "Ok",
      rejectButtonTitle: "Cancel",
      message:
        "Updating schedule ROBOCALL will reset previous filters and share tags. Are you sure to update?",
      matIcon: "warning",
      iconColor: "warn",
      data: {},
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: "400px",
      maxWidth: "400px",
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig).pipe(
      // Filter only accepted actions, do nothing for cancel actions
      filter((r) => r.accepted),

      // Transform the partial data to boolean whether confirmation accepted or rejected
      map((r) => r.accepted),

      switchMap((res) => this.showUpdateScheduledSmsDialog(postDetails))
    );
  };

  /**
   * Opens the share update form in update mode
   */
  showUpdateScheduledSmsDialog(postDetails: KalgudiRobocall): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: "Update Robocall",
      acceptButtonTitle: "Send",
      rejectButtonTitle: "Cancel",
      data: {
        postDetails,
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: "700px",
      maxWidth: "700px",
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };
    return from([{}]);
    // @todo
    // return this.updateSms
    //   .showScheduleDialog(dialogDetails, dialogConfig)
    //   .pipe(filter((res) => res && res.accepted));
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call sms stream.
   */
  protected streamApi(
    offset: number,
    limit: number,
    extraParams
  ): Observable<KalgudiStreamData> {
    return this.robocallStream.getRobocallStream(
      this.pageId,
      offset,
      limit,
      extraParams
    );
  }

  /**
   * Loads the stream items. Internally it calls the `streamApi` function to fetch the
   * stream from API.
   */
  protected fetchStreamItems(
    paginator: Paginator,
    extraParams: PartialData = {}
  ): Observable<KalgudiStreamData> {
    // Turn on the spinner
    this.setLoadingProgress(true);

    // Load the stream items from API
    return this.streamApi(paginator.offset, paginator.limit, {
      ...extraParams,
      isRobocall: true,
    }).pipe(
      // Ensure to close stream after fetching first item
      take(1),

      // Subscribe to the stream Api responses only till the instance is alive
      takeUntil(this.destroyed$),

      // Handler for successful stream items fetch
      tap((r) => {
        this.onStreamFetch(this.streamValue, r, this.paginatorValue);

        this.setLoadingProgress(false);
      }),

      finalize(() => this.setLoadingProgress(false)),

      // Turn off the loading progress spinner
      tap((_) => this.setLoadingProgress(false)),

      // Listen for any errors while loading of stream
      catchError(() => {
        this.setLoadingProgress(false);

        return of({ items: [], count: 0 });
      })
    );
  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<KalgudiRobocall> {
    return this.robocallStreamState.newStreamItem$;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
