import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUserPickerFormControl } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { SurveyMembersActionService } from '../services/survey-members-action.service';


/**
 *
 * Must provide the `FORM_CONTROL_ACCESSOR` to the component.
 *
 * @usageNotes
 * #### To ensure the KalgudiSurveyMembersPickerFormControl can be used as a formControlName
 *
 * ```ts
 * Component({
 *  providers: [{
 *   provide: NG_VALUE_ACCESSOR,
 *   useExisting: forwardRef(() => YourComponent),
 *   multi: true,
 *  }]
 * })
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiSurveyMembersPickerFormControl extends KalgudiUserPickerFormControl {

  // Dependencies
  protected surveyMembersService: SurveyMembersActionService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    // Inject dependencies manually
    this.surveyMembersService = this.injector.get(SurveyMembersActionService);
  }


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Shows page members picker dialog for mobile and web based on the device or screen size.
   *
   * @override
   */
  protected showUsersPicker(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {
      return this.surveyMembersService.showMembersSearchDialog();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
