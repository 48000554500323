<main id="farming-activity-add-season" class="mt-2">
  <form autocomplete="off" fxLayout.gt-xs="column" [formGroup]="farmingActivityAddSeasonForm">

    <p class="labels mb-0" translate>Season details</p>

    <div fxFlex.gt-xs="50%">
      <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
        <mat-label translate>Season title</mat-label>
        <input matInput [placeholder]="'Enter season title' | translate" formControlName="title" required>
        <span matSuffix class="position-relative info-icon">
          <span class="cursor-pointer suffix-icon rounded-circle">
            i
          </span>
          <span class="hide d-none position-absolute bg-white p-2 text-black-50 rounded" translate>
            <strong>Eg: </strong> 2020 - Karif
          </span>
        </span>
      </mat-form-field>
    </div>

    <div fxFlex.gt-xs="50%">
      <mat-form-field class="w-100 mat-form-field-md" appearance="outline">
        <mat-label translate>Farming farm type</mat-label>
        <mat-select appearance="outline" formControlName="farmingFarmType" required>
          <mat-option *ngFor="let type of farmingFarmTypes" [value]="type?.id">{{type?.value}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Season time frame section -->
    <section class="from-to-dates dotted-border-bottom" formGroupName="seasonTimeFrame">
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="center center">
        <div fxFlex="calc(50% - 12px)">
          <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
            <mat-label translate>From</mat-label>
            <input matInput formControlName="from" [matDatepicker]="from" (click)="from.open()" readonly required>
            <mat-datepicker-toggle matSuffix [for]="from">
              <div  matDatepickerToggleIcon>

                <svg-icon class="text-center date-icon"
                  [applyCss]="true"
                  src="assets/svgs/farmer-profile/calendar.svg"
                  [svgStyle]="{ 'width.px': 14, 'height.px': 14}">
                </svg-icon>
              </div>
            </mat-datepicker-toggle>
            <!-- <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle> -->
            <mat-datepicker #from></mat-datepicker>
          </mat-form-field>
        </div>

        <span class="h-100 mt-n1" fxLayout fxLayoutAlign="center center">-</span>

        <div fxFlex="calc(50% - 12px)">
          <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
            <mat-label translate>To</mat-label>
            <input formControlName="to" matInput [matDatepicker]="to" (click)="to.open()" readonly required>
            <mat-datepicker-toggle matSuffix [for]="to">
              <div  matDatepickerToggleIcon>

                <svg-icon class="text-center date-icon"
                  [applyCss]="true"
                  src="assets/svgs/farmer-profile/calendar.svg"
                  [svgStyle]="{ 'width.px': 14, 'height.px': 14}">
                </svg-icon>
              </div>
            </mat-datepicker-toggle>
            <!-- <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle> -->
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </section>
    <!--/ Season time frame section -->

    <!-- Harvesting Dates -->
    <div formArrayName="harvestingDates" class="from-to-dates dotted-border-bottom"
      *ngFor="let item of harvestingDatesArray?.controls; let i = index; let last = last">
      <div *ngIf="harvestingDatesArray?.length === (i+1)" class="">
        <p class="m-0">Harvesting time frame</p>
        <div [formGroupName]="i" fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <div fxFlex="calc(50% - 42px)">
            <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
              <mat-label translate>Start date</mat-label>
              <input matInput formControlName="startDate" [matDatepicker]="startDate" (click)="startDate.open()" readonly [required]="farmingActivityAddSeasonForm?.get('harvestingDates')?.value?.length === 1">
              <mat-datepicker-toggle matSuffix [for]="startDate">
                <div  matDatepickerToggleIcon>

                  <svg-icon class="text-center date-icon"
                    [applyCss]="true"
                    src="assets/svgs/farmer-profile/calendar.svg"
                    [svgStyle]="{ 'width.px': 14, 'height.px': 14}">
                  </svg-icon>
                </div>
              </mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>
          <span  fxFlex="4px" class="h-100 mt-n1" fxLayout fxLayoutAlign="center center">-</span>
          <div fxFlex="calc(50% - 42px)">

            <mat-form-field color="accent" class="w-100 mat-form-field-md" appearance="outline">
              <mat-label translate>End date</mat-label>
              <input matInput formControlName="endDate" [matDatepicker]="endDate" (click)="endDate.open()" readonly [required]="farmingActivityAddSeasonForm?.get('harvestingDates')?.value?.length === 1">
              <mat-datepicker-toggle matSuffix [for]="endDate">
                <div  matDatepickerToggleIcon>
                  <svg-icon class="text-center date-icon"
                    [applyCss]="true"
                    src="assets/svgs/farmer-profile/calendar.svg"
                    [svgStyle]="{ 'width.px': 14, 'height.px': 14}">
                  </svg-icon>
                </div>
              </mat-datepicker-toggle>
              <!-- <mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle> -->
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
          <div *ngIf="last" class="text-center mt-n1" fxFlex="28px">
            <button class="add-harvesting-dates" mat-icon-button [disabled]="!harvestingDatesArray?.controls[i]?.get('startDate')?.value || !harvestingDatesArray?.controls[i]?.get('endDate')?.value" (click)="addHarvestingDate()">
              <mat-icon class="w-100 h-100 rounded-circle">add</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!----/Harveting date range information-->
    <div fxLayout.lt-md="column" fxLayout.sm="row wrap"   fxLayoutGap="20px" >
      <div fxFlex.lt-xl="33%" fxFlex.lt-lg="50%" fxFlex.lt-md="calc(50% - 20px)" fxFlex.lt-sm="100%"   *ngFor="let harvestingDateRange of farmingActivityAddSeasonForm?.get('harvestingDates')?.value; let i = index">
        <div fxLayout="row no-wrap" class="harvestingdate-table" fxLayoutGap="10px" *ngIf="harvestingDatesArray?.length > i+1">
          <div  fxFlex="calc(50% - 34px)" >{{harvestingDateRange.startDate | date :'mediumDate'}}</div>
          <div  fxFlex="calc(50% - 34px)" > {{harvestingDateRange.endDate | date :'mediumDate'}}</div>
          <div  fxFlex="24px" class="text-right" (click)="removeHarvestingDate(i)">
              <mat-icon class="cursor-pointer" color="delete-hdate">delete_forever</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <!-- /Harvesting Dates -->

    <!-- Select variety for the crop season section-->
    <section class="mt-2 dotted-border-bottom">
      <p class="labels mb-2" translate>Select variety for the crop season</p>
      <div class="w-100" fxLayout="row wrap" fxLayoutGap="12px">
        <ng-container *ngFor="let variety of varietyList; let i = index">
          <div class="mb-2 mw-100" *ngIf="variety?.productId !== variety?.baseProductId" (click)="onCropSelection(variety, i)">
            <div fxLayout="row" fxLayoutAlign="start center"
              class="variety-tile  cursor-pointer" [ngClass]="{'selected-tile': variety?.selected}">
              <div fxFlex="98%"><p class="m-0 text-truncate mw-100 mr-2"> {{variety?.productName}}</p></div>
              <div fxFlex="2%" class="ml-n2"><svg-icon src="assets/svgs/completed.svg" class="text-center" [ngClass]="{'not-selected': !variety?.selected}" [applyCss]="true"
                [svgStyle]="{ 'width.px': 17, 'height.px': 23}"></svg-icon></div>
            </div>
          </div>

          <ng-container *ngIf="(varietyList && varietyList?.length <= 1 && variety?.productId === variety?.baseProductId) || !varietyList || (varietyList && !varietyList?.length)">
            <p class="text-muted text-center w-100 mb-0" translate>No varieties added yet!</p>
          </ng-container>
        </ng-container>
      </div>

      <div fxLayout fxLayoutGap="2px">
        <p class="" translate>Didn't find variety you want? </p>
        <a (click)="editCrop()" class="links" translate> search & add here</a>
      </div>
    </section>
    <!--/ Select variety for the crop season section-->

    <section class="mt-2">
      <p class="labels my-2" translate>Land Details</p>
      <mat-form-field class="mat-form-field-md w-100 selection" appearance="outline">
        <mat-label translate>Select land</mat-label>
        <mat-select panelClass="land-details-selection" required placeholder="Select land details" [formControl]="landDetails">
          <mat-option *ngFor="let land of profile?.farmerLandDetails?.lands"  [value]="land">
            <span class="d-block">
              <span class="d-block main-title">
                <span *ngIf="land?.landName">{{ land?.landName }} - </span> {{ land?.landSize?.value }} {{ land?.landSize?.unit }}
              </span>
              <span class="mb-0 location-details">{{ land?.location?.districtName || land?.location?.stateName }}</span>
            </span>
          </mat-option>
        </mat-select>

      </mat-form-field>


      <div fxLayout fxLayoutGap="2px">
        <p class="" translate>Didn't find your land? </p>
        <a (click)="showAddLandDetailsDialog()" class="links" translate>Click & add here</a>
      </div>
    </section>

    <!-- Fields for expected and actual yield -->
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" *ngIf="landDetails?.value">
      <div fxFlex="50%" fxLayout="row" formGroupName="expectedYield">
        <!-- Field to enter expected yield -->
        <div fxFlex="70%" fxFlex.xs="60%">
          <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
            <mat-label translate>Expected yield</mat-label>
            <input matInput [placeholder]="'Enter expected yield' | translate" formControlName="value" type="number" required>
            <mat-error translate>Please enter expected yield</mat-error>
          </mat-form-field>
        </div>
        <!--/ Field to enter expected yield -->

        <!-- Field to select unit -->
        <div fxFlex="30%" fxFlex.xs="40%">
          <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
            <mat-label translate>Select unit</mat-label>
            <mat-select appearance="outline" formControlName="unit" required>
              <mat-option *ngFor="let unit of units" [value]="unit?.id">{{unit?.value}}</mat-option>
            </mat-select>
            <mat-error translate>Please select unit</mat-error>
          </mat-form-field>
        </div>
        <!--/ Field to select unit -->
      </div>
      <div fxFlex="calc(50% - 10px)" fxLayout="row" formGroupName="actualYield">
        <!-- Field to enter actual yield -->
        <div fxFlex="70%" fxFlex.xs="60%">
          <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
            <mat-label translate>Actual yield</mat-label>
            <input matInput [placeholder]="'Enter actual yield' | translate" formControlName="value">
            <mat-error *ngIf="actualYield.get('value').hasError('pattern')">
              Enter valid actual yield
            </mat-error>
          </mat-form-field>
        </div>
        <!--/ Field to enter actual yield -->

        <!-- Field to select unit -->
        <div fxFlex="30%" fxFlex.xs="40%">
          <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
            <mat-label translate>Select unit</mat-label>
            <mat-select appearance="outline" formControlName="unit">
              <mat-option *ngFor="let unit of units" [value]="unit?.id">{{unit?.value}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Field to select unit -->
      </div>
    </div>
    <!--/ Fields for expected and actual yield -->

    <!-- Section for Make your farm land for this season -->
    <div *ngIf="landDetails?.value">
      <kl-add-land-details [landDetailsForm]="land" [landDetails]="selectedSeason?.land" [hideAddButton]="true"></kl-add-land-details>
    </div>
    <!--/ Section for Make your farm land for this season -->

    <!-- Add button -->
    <div class="mt-2 add-btn w-100" fxLayoutAlign="center center">

      <kl-flat-button
        [label]="isEditSeason && selectedSeason ? 'Update farm season' : 'Submit farm season' | translate"
        [disabled]="farmingActivityAddSeasonForm.invalid || landDetails.invalid"
        [progress]="progress"
        (clicked)="addSeason()">
      </kl-flat-button>
    </div>
    <!-- / Add button -->
  </form>
</main>
