import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonV1,
  PageFilters,
  PageShareTargetAudienceRequest,
  StringStringMap,
} from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class PageShareFiltersApiService {

  /**
   * `v2/pages`
   */
  private readonly API_PAGE_BASE = `${this.env.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_VIEW = `${this.API_PAGE_BASE}/:pageId`;

  /**
   * `v2/pages/:pageId/filters`
   */
  private readonly API_PAGE_FILTERS = `${this.API_PAGE_VIEW}/filters`;

  /**
   * `v1/stream/page/share`
   */
  private readonly PROGRAM_SHARE_BASE = `${this.env.restBaseUrl}/stream/page/share`;

  /**
   * `v1/stream/page/share/range`
   */
  private readonly API_PROGRAM_RANGE = `${this.PROGRAM_SHARE_BASE}/range`;

  /**
   * `v1/stream/page/share/locations`
   */
  private readonly API_PROGRAM_RANGE_LOCATIONS = `${this.PROGRAM_SHARE_BASE}/locations`;

  /**
   * `v2/pages/targets`
   */
  private readonly API_PROGRAM_TARGETED_AUDIENCE = `${this.API_PAGE_BASE}/filters/targets`;

  /**
   * `v1/stream/publish/shares/targets`
   */
  private readonly API_PROGRAM_TARGETED_AUDIENCE_V1 = `${this.env.restBaseUrl}/stream/publish/shares/targets`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches page share filter details.
   */
  fetchPageFilters(pageId: string): Observable<PageFilters> {

    // Api url
    const url = this.API_PAGE_FILTERS.replace(':pageId', pageId);

    // Query params
    const params = { pageId };

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(
        // Handles API errors
        map(res => this.pageFiltersHandler(pageId, res)),

        // Maps API response to range type
        map(res => res.data)
      );
  }

  /**
   * Fetches, a list of location from API that the program subscribers belongs.
   *
   * @param pageId Program/page/entity id
   */
  fetchProgramSubscribersLocations(pageId: string): Observable<StringStringMap> {

    // Query params
    const params = { pageId };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_PROGRAM_RANGE_LOCATIONS, { params })
      .pipe(

        // Handles API errors
        map(r => this.programSubscribersLocationsHandler(pageId, r)),

        // Maps API response to range type
        map(r => this.mapSubscribersLocations(r))
      );
  }

  /**
   * Gets, the program targeted audience count for a share post with the
   * selected filters.
   *
   * @param payload Selected filters for the program targeted share
   */
  fetchSocialPostTargetAudienceCount(payload: PageShareTargetAudienceRequest): Observable<number> {

    const params = {
      pageId: payload.pageId
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_PROGRAM_TARGETED_AUDIENCE, payload, { params })
      .pipe(

        // Handle API errors
        map(res => this.pageShareTargetAudienceHandler(payload, res)),

        // Convert the API response to number
        map(res => res.data && res.data.count ? +res.data.count : 0),

        // On any error return 0
        catchError(err => of(0)),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Page share filters data API response handler. It checks
   * for any API errors and throw specific error.
   */
  private pageFiltersHandler(entityId: string, res: ApiResponseCommon): ApiResponseCommon {

    // Login error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
      [HttpStatusCode.NO_CONTENT]: 'No page filters found'
    };

    // Api error
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, successful login
    return res;
  }

  /**
   * Maps program subscribers range meta data to object type.
   */
  private mapSubscribersMeta(res: ApiResponseCommonV1): PageFilters {

    return this.util.toJson<PageFilters>(res.data);
  }

  /**
   * Program subscribers meta data API response handler. It checks
   * for any API errors and throw specific error.
   */
  private programSubscribersLocationsHandler(entityId: string, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Login error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api error
    if (res.code !== HttpStatusCode.OK) {

      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, successful login
    return res;
  }

  /**
   * Maps program subscribers locations API response to hash map.
   */
  private mapSubscribersLocations(res: ApiResponseCommonV1): StringStringMap {

    return this.util.toJson<StringStringMap>(res.data);
  }

  /**
   * Program social share targeted audience API response handler. It checks
   * for any API errors and throw specific error.
   */
  private pageShareTargetAudienceHandler(payload: PageShareTargetAudienceRequest, res: ApiResponseCommon): ApiResponseCommon {

    // Login error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api error
    if (res.code !== HttpStatusCode.OK) {

      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, successful login
    return res;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



}
