import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiSurveyQuestion } from '@kalgudi/types';

@Component({
  selector: 'kl-add-question-dialog',
  templateUrl: './add-question-dialog.component.html',
  styleUrls: ['./add-question-dialog.component.scss']
})
export class AddQuestionDialogComponent extends KalgudiDialog<AddQuestionDialogComponent> implements OnInit {

  question: KalgudiSurveyQuestion;
  constructor(
    protected dialogRef: MatDialogRef<AddQuestionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }

  /**
   * Triggers when the child eis an event
   */
  onQuestionAddition(question: KalgudiSurveyQuestion): void {
    this.question = question;
    this.ok();
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {
        question: this.question
      },
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }


  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
