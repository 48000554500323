import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonV1,
  CommonBasicProfile,
  KalgudiProject,
  KalgudiSellerConfig,
  KalgudiUser,
  KalgudiUserLoginIdResponse,
  KalgudiUserLoginIdUpdatePayload,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from './kalgudi-profile-state.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiProfileApiService {

  private readonly PROFILE_BASE = `${this.env.restBaseUrl}/profiles`;

  /**
   * `v2/profiles/:profileKey`
   */
  private readonly USER_PROFILE_BASE = `${this.env.restBaseUrlV2}/profiles/:profileKey`;

  /**
   * `v2/projects`
   */
  private readonly PROJECTS = `${this.env.restBaseUrlV2}/projects`;

  /**
   * `v1/profiles/:profileKey`
   */
  private readonly USER_PROFILE = `${this.PROFILE_BASE}/:profileKey`;

  /**
   * `v1/profiles/:profileKey/profilePic`
   */
  private readonly PROFILE_PIC = `${this.USER_PROFILE}/profilePic`;

  /**
   * `v1/profiles/:profileKey/coverPic`
   */
  private readonly COVER_PIC = `${this.USER_PROFILE}/coverPic`;

  /**
   * `v2/profiles/profile-common-data`
   */
  private readonly API_COMMON_PROFILE_BASIC_DETAILS = `${this.env.restBaseUrlV2}/profiles/profile-common-data`;

  /**
   * `v1/network/views/:profileKey`
   */
  private readonly API_PROFILE_VIEW = `${this.env.restBaseUrl}/network/views/:profileKey`;

  /**
   * `v1/profiles/onlineseller/:profileKey_SELLER_CONFIGURATION`
   */
  private readonly API_SELLER_CONFIG = `${this.env.restBaseUrl}/profiles/onlineseller/:profileKey_SELLER_CONFIGURATION`;

  /**
   * `v1/profiles/onlineseller`
   */
  private readonly API_UPDATE_SELLER_CONFIG = `${this.env.restBaseUrl}/profiles/onlineseller`;

  /**
   * `v2/profiles/login-change`
   */
  private readonly API_UPDATE_LOGIN_ID = `${this.env.restBaseUrlV2}/profiles/login-change`;

  /**
   * `v2/profiles/loginId`
   */
  private readonly API_GET_LOGIN_ID = `${this.env.restBaseUrlV2}/profiles/loginId`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private profileState: KalgudiProfileStateService
  ) { }

  /**
   * Fetches logged in user profile details from the API.
   *
   * **NOTE** Must use this method only to fetch logged in user profile details.
   *
   * @param profileKey Logged in user profile key
   */
  fetchProfile(profileKey: string): Observable<KalgudiUser> {

    const url = this.USER_PROFILE_BASE.replace(':profileKey', profileKey);

    return this.httpClient.get<ApiResponseCommonV1>(url)
      .pipe(
        map(o => this.kalgudiUserFetchHandler(profileKey, o)),
        map(o => this.mapKalgudiUserResponse(o))
      );
  }

  /**
   * Calls the profile pic update API.
   * @param profileKey
   * @param s3Url
   * @param headers
   */
  uploadProfilePic(profileDetails: any, extraParams: PartialData = {}): Observable<boolean> {

    const url = this.API_COMMON_PROFILE_BASIC_DETAILS.replace(':profileKey', profileDetails.profileKey);

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    const profilePicPayload = {
      aboutMe: profileDetails.aboutMe,
      altemailId: profileDetails.altemailId,
      alternateMobileNo: profileDetails.alternateMobileNo,
      businessTypeName: profileDetails.additionalBizDetails.businessTypeName,
      employees: profileDetails.employees,
      establishedSince: profileDetails.establishedSince,
      farmers: profileDetails.farmers,
      firstName: profileDetails.firstName,
      fpoAvgLand : profileDetails.fpoAvgLand,
      location: profileDetails.location,
      villages: profileDetails.villages,
      profilePicURL: profileDetails.profilePicURL,
      zipCode: ''
    }

    const payload = {basicDetails: profilePicPayload};

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('source', 'WEB');


    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { headers, params })
      .pipe(
        // Check for API response errors
        map(res => this.profilePicHandler(res)),

        // All good, emit true
        mapTo(true)
      );
  }

  /**
   * Calls the cover pic update API.
   * @param profileKey
   * @param s3Url
   * @param headers
   */
  uploadCoverPic(profileKey: string, coverPicUrl: string, extraParams: PartialData = {}): Observable<boolean> {

    const url = this.API_COMMON_PROFILE_BASIC_DETAILS.replace(':profileKey', profileKey);

    const payload = {basicDetails: {bannerURL: coverPicUrl}};

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('source', 'WEB');

    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { headers, params })
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // All good, emit true
        mapTo(true)
      );
  }


  /**
   * Updates basic common profile details
   */
  updateCommonBasicDetails(payload: CommonBasicProfile, extraParams: PartialData = {}): Observable<KalgudiUser> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_COMMON_PROFILE_BASIC_DETAILS, payload, { params })
      .pipe(

        // Check for api errors
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }

  /**
   * Updates login id
   */
  updateLoginId(payload: KalgudiUserLoginIdUpdatePayload, extraParams: PartialData = {}): Observable<ApiResponseCommon> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_UPDATE_LOGIN_ID, payload, { params })
      .pipe(

        // Check for api errors
        map(res => {

          if (res.code !== HttpStatusCode.OK || res.data.code !== HttpStatusCode.OK) {
            const errorMessage = res.data.info || 'Something went wrong, please try again later.';
            throw new ApiError(new Error(errorMessage));
          }

          // All good, return the response back
          return res;
        }),

        map(res => res.data)
      );
  }

  /**
   * Fetches login id
   */
  getLoginId(extraParams: PartialData = {}): Observable<KalgudiUserLoginIdResponse> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_GET_LOGIN_ID, { params })
      .pipe(

        // Check for api errors
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }

  /**
   * Fetches projects list
   */
  getProjectsList(): Observable<KalgudiProject[]> {

    return this.httpClient.get<ApiResponseCommon>(this.PROJECTS)
      .pipe(

        // Check for api errors
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data.results)
      );
  }

  /**
   * Fetches seller details
   */
  getSellerConfigDetails(profileKey: string): Observable<KalgudiSellerConfig> {

    const url = this.API_SELLER_CONFIG.replace(':profileKey', profileKey);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Check for api errors
        map(res => this.util.apiErrorHandler(res)),

        map(res => this.util.toJson<KalgudiSellerConfig>(res.data))
      );

  }


  /**
   * Updates seller config details
   */
  updateSellerConfigDetails(payload: KalgudiSellerConfig): Observable<ApiResponseCommon> {

    return this.httpClient.put<ApiResponseCommon>(this.API_UPDATE_SELLER_CONFIG, payload)
      .pipe(

        // Check for api errors
        map(res => {

          if (res.code !== HttpStatusCode.OK) {
            const errorMessage = res.data.info || 'Something went wrong, please try again later.';
            throw new ApiError(new Error(errorMessage));
          }

          // All good, return the response back
          return res;
        }),

        map(res => res.data)
      );
  }


  /**
   * Calls view profile service
   */
  callProfileViewService(profileKey: string) {

    return this.httpClient.post<ApiResponseCommon>(this.API_PROFILE_VIEW.replace(':profileKey', profileKey), {})
      .pipe(

        // Check for api errors
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * API response handler for the Kalgudi user profile API.
   *
   * On success it returns back the response returned from the API. Otherwise
   * throws back specific API errors.
   *
   * @param profileKey Profile key
   * @param res API response
   */
  private kalgudiUserFetchHandler(profileKey: string, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // List of all profile error messages
    const errorMessages = {
      // Common error messages
      ...REST_API_ERROR_MESSAGES,

      // Profile specific error messages
      [HttpStatusCode.NOT_FOUND]: 'Unable to find profile'
    };

    // API response code is a non success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, return the response back to process
    return res;
  }


  /**
   * API response handler for the profile pic update API.
   *
   * On success it returns back the response returned from the API. Otherwise
   * throws back specific API errors.
   *
   * @param res API response
   */
  private profilePicHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {
    // List of all profile error messages
    const errorMessages = {
      // Common error messages
      ...REST_API_ERROR_MESSAGES,

    };

    // API response code is a non success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, return the response back to process
    return res;
  }

  /**
   * Maps, the Kalgudi user profile API response to the KalgudiUser type.
   *
   * @param res Profile API response
   */
  private mapKalgudiUserResponse(res: ApiResponseCommonV1): KalgudiUser {

    return this.util.toJson<KalgudiUser>(res.data);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
