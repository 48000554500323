export enum SurveyLogActivityType {
  SURVEY_CREATED   = 'SURVEY_CREATED',
  SURVEY_UPDATED   = 'SURVEY_UPDATED',
  SURVEY_SUBMITTED = 'SURVEY_SUBMITTED',
  SURVEY_PUBLISHED = 'SURVEY_PUBLISHED',
  SURVEY_UNPUBLISHED = 'SURVEY_UNPUBLISHED',

  // When adding any new activity type please ensure to add in its mapping
  // down below.
};

export const SurveyLogActivityTypeLabelMap = {
  // TODO: Following commented syntax is not working while build.
  // Need to identify why its not working
  // [SurveyLogActivityType.SURVEY_CREATED]: 'Created',

  SURVEY_CREATED: 'Created',
  SURVEY_UPDATED: 'Updated',
  SURVEY_SUBMITTED: 'Submitted',
  SURVEY_PUBLISHED: 'Published',
  SURVEY_UNPUBLISHED: 'UnPublished',
}
