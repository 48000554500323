<mat-card class="p-0">
  <div fxLayout="row" class="digital-assist-stats" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center">

    <!-- Stats -->
    <div fxLayout="row wrap" fxFlex="50%" fxFlex.xs="100%" class="py-2 px-1">
      <div fxFlex="25%" *ngFor="let item of stats" fxLayout="column" fxLayoutAlign="start center">
        <span [ngStyle]="{'color': item?.color}" class="stats">
          {{ item?.value || 0}}
        </span>
        <span class="stats-title">
          {{ item?.title | translate}}
        </span>
      </div>

    </div>
    <!-- / Stats -->

    <div fxFlex="12%" fxHide.xs></div>

    <!-- Assist members button -->
    <div class="add-members pr-1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px" fxHide.xs [matMenuTriggerFor]="assistMenu">
      <div class="triangle ml-0"></div>
      <span class="add-button" translate>
        Assist Members
      </span>
      <button mat-icon-button>
        <mat-icon class="add-icon">add</mat-icon>
      </button>

      <!-- Assist Members menu -->
      <mat-menu #assistMenu="matMenu" [xPosition]="'before'">
        <ng-container *ngFor="let assistMenu of menu; let last = last;">
          <button mat-menu-item
            (click)="updateDaAction(assistMenu?.id)"
            *ngIf="!assistMenu?.hide">
            <div fxLayout="row" fxLayoutGap="12px" class="assist-menu">
              <div>
                <svg-icon *ngIf="assistMenu?.svgIcon"
                  class="text-center menu-icons" [applyCss]="true" [src]="assistMenu?.svgIcon"
                  [svgStyle]="{ 'width.px': 23, 'height.px': 23 }">
                </svg-icon>
                <mat-icon class="m-0" *ngIf="assistMenu?.matIcon">{{assistMenu?.matIcon}}</mat-icon>
              </div>
              <div>
                <p class="mb-0 menu-title">{{ assistMenu?.title | translate }}</p>
              </div>
            </div>
          </button>

          <mat-divider *ngIf="!last"></mat-divider>
        </ng-container>
      </mat-menu>
      <!-- / Assist members menu -->

    </div>
    <!-- /Assist members button-->
  </div>

</mat-card>
