import { Component, Inject, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { TaskUpdatesStream } from '../../classes/task-updates-stream';

@Component({
  selector: 'kl-task-updates-stream',
  templateUrl: './task-updates-stream.component.html',
  styleUrls: ['./task-updates-stream.component.scss']
})
export class TaskUpdatesStreamComponent extends TaskUpdatesStream implements OnInit, OnChanges {

  @Input()
  taskId: string;

  taskIdInitialized = false;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }

  ngOnInit() {

  }

  onDestroyed(): void {}


  ngOnChanges(): void {

    if (this.taskId && !this.taskIdInitialized) {
      this.taskIdInitialized = true;
      this.initStream();
    }
  }


}
