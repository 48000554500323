import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LAZY_API_SYNC_INTERCEPTOR, LAZY_HTTP_INTERCEPTOR } from './config';
import { LazyApiSync } from './lazy-api-sync';
import { LazyHttpClient } from './lazy-http-client';
import { LocalHttpRequestSync } from './local-http-request-sync';
import { FailedHttpResponseInterceptorService } from './services/failed-http-response-interceptor.service';
import { NoopApiSyncService } from './services/noop-api-sync.service';
import { NoopInterceptorService } from './services/noop-interceptor.service';
import { OnNetworkApiSyncService } from './services/on-network-api-sync.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    LazyHttpClient,
    LocalHttpRequestSync,
    NoopInterceptorService,
    FailedHttpResponseInterceptorService,
    LazyApiSync,
    NoopApiSyncService,
    OnNetworkApiSyncService,

    // Use as default provider for LAZY_HTTP_INTERCEPTOR
    {
      provide: LAZY_HTTP_INTERCEPTOR,
      useClass: NoopInterceptorService,
    },
    {
      provide: LAZY_API_SYNC_INTERCEPTOR,
      useClass: NoopApiSyncService,
    },
  ]
})
export class KalgudiLazyHttpClientModule { }
