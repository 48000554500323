import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { KalgudiRatingFormControl } from '../../classes/kalgudi-rating-form-control';

const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => KalgudiRatingFormControlComponent),
  multi: true,
};

@Component({
  selector: 'kl-kalgudi-rating-form-control',
  templateUrl: './kalgudi-rating-form-control.component.html',
  styleUrls: ['./kalgudi-rating-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class KalgudiRatingFormControlComponent extends KalgudiRatingFormControl implements OnInit {

  constructor() {

    super();
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
