import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPageRelation, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiPagesListResponse, KalgudiPagesSearchResults } from '../../../typings';



/**
 * Kalgudi pages list API service. Handles API calling and responses for
 * Kalgudi pages that I am part of and admin.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiProgramListApiService {

  /**
   * `v2/pages`
   */
  private readonly API_PAGES_LIST = `${this.env.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/search`
   */
  private readonly API_PAGES_SEARCH = `${this.env.restBaseUrlV2}/pages/search`;

  /**
   * `v2/pages/:pageId/clone`
   */
  private readonly API_CLONE_PAGE = `${this.env.restBaseUrlV2}/pages/:pageId/clone`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  fetchPagesList(
    offset: number,
    limit: number,
    memberRole: KalgudiPageRelation,
    filters: PartialData = {},
    searchProperties?: any): Observable<KalgudiPagesSearchResults> {

    // API request query params
    const params: any = {
      memberRole: memberRole ? memberRole : '',
      limit: limit.toString(),
      offset: offset.toString(),
      ...filters
    };

    // Adding params according to search
    const propertiesToCopy = [
      'all',
      'groupDescription',
      'groupTitle',
    ];

    for (const property of propertiesToCopy) {
      if (searchProperties?.[property] === true) {
        params[property] = true;
      }
    }

    return this.httpClient.get<ApiPagesListResponse>(this.API_PAGES_LIST, { params })
      .pipe(

        // Check and handle API response for errors
        map(r => this.pagesListHandler(r)),

        // Map API response to Kalgudi page details object type
        map(r => this.mapPagesListResponse(r))
      );
  }


  /**
   * Call api to search pages list based on search keyword
   */
  searchPage(
    searchKeyword: string,
    offset: number,
    limit: number): Observable<KalgudiPagesSearchResults> {

    // API request query params
    const params = {
      keyword: searchKeyword,
      limit: limit.toString(),
      offset: offset.toString(),
    };

    return this.httpClient.get<ApiPagesListResponse>(this.API_PAGES_SEARCH, { params })
      .pipe(

        // Check and handle API response for errors
        map(r => this.pagesListHandler(r)),

        // Map API response to Kalgudi page details object type
        map(r => this.mapPagesListResponse(r))
      );
  }

  /**
   * Call api to search pages list based on page type
   */
  pagesList(
    offset: number,
    limit: number,
    pageType: string,
    filters: PartialData = {}): Observable<KalgudiPagesSearchResults> {

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
      pageType,
      ...filters
    };

    return this.httpClient.get<ApiPagesListResponse>(this.API_PAGES_SEARCH, { params })
      .pipe(

        // Check and handle API response for errors
        map(r => this.pagesListHandler(r)),

        // Map API response to Kalgudi page details object type
        map(r => this.mapPagesListResponse(r))
      );
  }

  /**
   * Call api to clone page
   */
  clonePage(payload: any, pageId: string): Observable<any> {

    return this.httpClient.post<ApiPagesListResponse>(this.API_CLONE_PAGE.replace(':pageId', pageId), payload)
      .pipe(

        // Check and handle API response for errors
        map(r => this.util.apiErrorHandler(r, HttpStatusCode.ACCEPTED)),

        // Map API response to Kalgudi page details object type
        map(r => r.data)
      );
  }


  /**
   * Pages list API response error handler. Handles any error in pages
   * API response service. In case of non-success response code it throws
   * an error. Otherwise returns the API response for further processing.
   */
  private pagesListHandler(res: ApiPagesListResponse): ApiPagesListResponse {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Didn't got success response from API, throw error
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, pages list fetched successfully
    return res;
  }

  /**
   * Maps pages list API response to kalgudi program details type.
   */
  private mapPagesListResponse(res: ApiPagesListResponse): KalgudiPagesSearchResults {

    return {
      items: res.data.results,
      count: res.data.count
    };
  }
}
