import { Component, Input, OnInit } from '@angular/core';
import { Attachment, ATTACHMENT_TYPE_MAP } from '@kalgudi/types';

@Component({
  selector: 'kl-social-attachments',
  templateUrl: './social-attachments.component.html',
  styleUrls: ['./social-attachments.component.scss']
})
export class SocialAttachmentsComponent implements OnInit {

  @Input()
  attachments: Attachment[];

  attachmentType = ATTACHMENT_TYPE_MAP;

  imagesList: Attachment[];
  videoList: Attachment[];

  constructor() { }

  ngOnInit() {

    if (this.attachments && this.attachments.length) {

      this.imagesList = this.attachments.filter(attachment => attachment.msgType === this.attachmentType.IMAGE);
      this.videoList = this.attachments.filter(attachment => attachment.msgType === this.attachmentType.VIDEO);
    }
  }

}
