import { Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { ProfileLog } from '@kalgudi/types';
import { KalgudiStreamData } from '@kalgudi/core';
import { Observable } from 'rxjs';

import { ProfileLogsApiService } from './profile-logs-api.service';

@Injectable()
export class ProfileLogsService {

  constructor(
    protected util: KalgudiUtilityService,
    private api:  ProfileLogsApiService,
  ) { }

  /**
   * Call api method to post profile log
   */
  createProfileLog(req: ProfileLog): Observable<any> {

    const payload = this.util.clone<any>(req);

    return this.api.createProfileLog(payload);
  }

  /**
   * Gets, stream of profile log.
   *
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getProfileLogsStream(memberId: string, contributorId: string, pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.api.getProfileLogList(memberId, contributorId, pageId , offset, limit,);
  }
}
