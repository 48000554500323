import { Injectable } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUserMembershipDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiProfileMembershipsApiService } from './kalgudi-profile-memberships-api.service';

@Injectable()
export class KalgudiProfileMembershipsService {


  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private app: KalgudiAppService,
    private api: KalgudiProfileMembershipsApiService,
    private profileState: KalgudiProfileStateService,
    private profileService: KalgudiProfileService,
  ) {

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }
   }

  /**
   * Saves a new membership to the current logged in user profile.
   */
  saveNewMembership(payload: KalgudiUserMembershipDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new membership Api
        switchMap(r => this.api.saveNewMembership(r.profileKey, r.defaultBusinessKey, payload)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
          .pipe(mapTo(res))
        )
      );
  }

  /**
   * Updates an existing membership of the current logged in user profile.
   */
  updateMembership(payload: KalgudiUserMembershipDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Update new membership Api
        switchMap(r => this.api.updateNewMembership(
          r.profileKey, r.defaultBusinessKey, payload.businessMembershipId, payload)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
            .pipe(mapTo(res))
        )
      );
  }

  /**
   * Deletes an membership from the logged in user profile.
   */
  deleteMembership(membershipId: string): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new membership Api
        switchMap(r => this.api.deleteMembership(r.profileKey, r.defaultBusinessKey, membershipId)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
          .pipe(mapTo(res))
        )
      );
  }
}
