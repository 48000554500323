import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KalgudiDialog } from '@kalgudi/common';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';

import { KalgudiProfileRouteConfig } from '../../../../config';


@Component({
  selector: 'kl-add-crop-dialog',
  templateUrl: './add-crop-dialog.component.html',
  styleUrls: ['./add-crop-dialog.component.scss']
})
export class AddCropDialogComponent extends KalgudiDialog<AddCropDialogComponent> implements OnInit {

  user: KalgudiUser;

  constructor(
    protected dialogRef: MatDialogRef<AddCropDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig,
    private router: Router
  ) {
    super(dialogRef);
  }

  ngOnInit() { }


  /**
   * Called when the product is added successfully
   */
  onDealAdded(user: KalgudiUser): void {
    this.user = user;

    this.ok();

    const profileKey = user.profileKey;
    const pageId = this.data.data.pageId;
    const isAssisted = this.data.data.isAssisted;

    if(this.data.data.commoditiesLink) {
      this.router.navigate([this.data.data.commoditiesLink]);
    } else {

      if(this.router.url === `/app/profiles/${profileKey}`) {
        this.appRouting.toProfile({ profileKey }, { pageId, isAssisted });
      } else {
        this.appRouting.toBaseProductListPage({ profileKey }, { pageId, isAssisted });
      }

    }
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }
}
