import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddTaskMembersService } from '../services/add-task-members.service';

@Directive()
export abstract class ManageTaskMembers extends KalgudiInboxStream<ManageTaskMembers> {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  protected addTaskMemberService: AddTaskMembersService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
    ) {
    super(notification, util);

    this.addTaskMemberService = this.injector.get<AddTaskMembersService>(AddTaskMembersService);

    this.pageLimit = 10;

  }

  /**
   * Deletes task members
   * @param profileKey
   */
  removeSelectedUser(profileKey: string) {
    this.addTaskMemberService.deleteTaskMembers(this.taskId, this.projectId, profileKey)
      .subscribe(
        res =>  this.deleteTaskMemberHandler(res),
        err =>  this.deleteTaskMemberErrorHandler(err)
      );
  }

  /**
   * Child class should implement the filters
   */
  protected abstract get filters(): PartialData;

  /**
   * Event handler for successful task members deletion
   */
  protected deleteTaskMemberHandler(res) {
    this.resetStream();
    this.notification.showMessage('Member deleted successfully');
  }

  /**
   * Event handler for failed to delete task members
   */
  protected deleteTaskMemberErrorHandler(err: Error) {
    this.notification.showMessage('Failed to delete member');
  }


  /**
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.addTaskMemberService.getTaskMembers(this.taskId, offset, limit)
    .pipe(

      // Transform the project members Api response to the KalgudiStreamData format
      map(res => {

        return {
          count: res.count,
          items: res.results
        };
      }),
    );
  }

}
