import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { kalgudiAnimations, KalgudiUtilityService, VIEW_TYPE_LIST } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, ProjectViewType, UIMenu } from '@kalgudi/types';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { PAGE_TYPES } from '../../page-creation';
import { KalgudiPagesList } from '../classes/kalgudi-pages-list';
import { KalgudiProgramListService } from '../services/kalgudi-program-list.service';
import { PageListStateService } from '../services/page-list-state.service';

@Component({
  selector: 'kl-program-list-page',
  templateUrl: './program-list-page.component.html',
  styleUrls: ['./program-list-page.component.scss'],
  animations: kalgudiAnimations,
})
export class ProgramListPageComponent extends KalgudiPagesList implements OnInit, AfterViewInit {

  @Input()
  hideFloatPlus: boolean;

  @Output()
  programSelected = new EventEmitter<any>();

  @Input()
  viewType: string;

  availableViewTypes = ProjectViewType;
  isMobileDevice: boolean;

  readonly pageTypes: UIMenu[] = [
    {
      id: '',
      svgIcon: 'assets/svgs/all-programs.svg',
      title: 'All pages',
      // index: 0,
      selected: true
    },
    {
      id: `${KALGUDI_PAGE_RELATION_MAP.ADMIN},${KALGUDI_PAGE_RELATION_MAP.CONTRIBUTOR}`,
      svgIcon: 'assets/svgs/admin.svg',
      title: 'Admin',
      // index: 1,
      selected: false
    },
    {
      id: KALGUDI_PAGE_RELATION_MAP.FOLLOWER,
      svgIcon: 'assets/svgs/participant.svg',
      title: 'Following',
      // index: 2,
      selected: false
    },
    {
      svgIcon: 'assets/svgs/page.svg',
      title: 'All page types',
      // index: 2,
      selected: false,
      subMenu: [
        {
          id: '',
          title: 'All page types',
          iconColor: 'grey',
          svgIcon: 'assets/svgs/page.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.COMPANY,
          title: 'Company',
          svgIcon: 'assets/svgs/company.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.STOCK,
          title: 'Stock',
          svgIcon: 'assets/svgs/stocks.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.FARMER,
          title: 'Farmer',
          svgIcon: 'assets/svgs/farmer.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.ORGANIZATION,
          title: 'Organization',
          svgIcon: 'assets/svgs/organization.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.PROGRAM,
          title: 'Program',
          svgIcon: 'assets/svgs/program.svg',
          selected: false
        },
        {
          id: PAGE_TYPES.UNIVERSITY,
          title: 'University',
          svgIcon: 'assets/svgs/university.svg',
          selected: false
        }
      ]
    },
  ];

  programMenu: UIMenu[] = VIEW_TYPE_LIST;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProgramsRouteConfig,
    protected pagesListService: KalgudiProgramListService,
    protected kalgudiPrograms: KalgudiPageService,
    protected pageListStateService: PageListStateService,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    protected fb: FormBuilder,
    protected http: HttpClient,
  ) {
    super(notification, pagesListService, kalgudiPrograms, pageListStateService, util, injector, fb, http);
    this.isMobileDevice = window.innerWidth < 500;
  }

  ngOnInit() {
    this.getProgramTypeList();
  }

  ngAfterViewInit() {

    this.initPagesList();
  }

  onDestroyed(): void { }

  /**
   * Emits an event to the parent
   * @param id
   */
  onPageSelected(pageId: string): void {
    this.appRouting.toProgram({ pageId });
  }

  showProgramsAction(menu: any) {
    this.appRouting.toProgramList(null, { type: menu.id });
  }

  /**
   * Navigates to program creation page
   */
  openCreatePage(): void {
    this.appRouting.toNewProgram();
  }
}
