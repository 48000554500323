import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { PageTypeAutocompleteComponent } from './page-type-autocomplete/page-type-autocomplete.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



@NgModule({
  declarations: [PageTypeAutocompleteComponent],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material modules
    MatAutocompleteModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,

    TranslateModule
  ],
  exports: [
    PageTypeAutocompleteComponent
  ]
})
export class PageAutocompleteModule { }
