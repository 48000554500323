import { Component, Injector, OnInit } from '@angular/core';

import { FarmerAwards } from '../../../farmer-profile/classes/farmer-awards';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-cooperative-society-awards',
  templateUrl: './cooperative-society-awards.component.html',
  styleUrls: ['./cooperative-society-awards.component.scss']
})
export class CooperativeSocietyAwardsComponent extends FarmerAwards implements OnInit {

  isPublicPage: boolean = false;

  constructor(protected injector: Injector,
    private router: Router
    ) {
    super(injector);
  }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  onDestroyed(): void {}

}
