import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CommonSearchBarModule,
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiProductsPickerModule,
  KalgudiStepperModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSelectCardModule } from '@kalgudi/common/ui/select-card';
import { MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiNgxFormlyModule } from '@kalgudi/third-party/ngx-formly';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonTaskFormComponent } from './components/common-task-form/common-task-form.component';
import { CropDependencyPickerComponent } from './components/crop-dependency-picker/crop-dependency-picker.component';
import { CropStagePickerComponent } from './components/crop-stage-picker/crop-stage-picker.component';
import { DetailedTaskFormComponent } from './components/detailed-task-form/detailed-task-form.component';
import { SelectTemplateComponent } from './components/select-template/select-template.component';
import { TaskCreationDialogComponent } from './components/task-creation-dialog/task-creation-dialog.component';
import {
  TaskCreationMobileDialogComponent,
} from './components/task-creation-mobile-dialog/task-creation-mobile-dialog.component';
import { TaskCreationComponent } from './components/task-creation/task-creation.component';
import { TaskTagsFormComponent } from './components/task-tags-form/task-tags-form.component';
import {
  TaskTagsProductAutocompleteComponent,
} from './components/task-tags-product-autocomplete/task-tags-product-autocomplete.component';
import { TaskTagsProductComponent } from './components/task-tags-product/task-tags-product.component';
import { CreateTaskStateService } from './services/create-task-state.service';
import { KalgudiTaskCreationApiService } from './services/kalgudi-task-creation-api.service';
import { KalgudiTaskCreationDialogService } from './services/kalgudi-task-creation-dialog.service';
import { KalgudiTaskCreationService } from './services/kalgudi-task-creation.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';



@NgModule({
  declarations: [
    TaskCreationDialogComponent,
    TaskCreationComponent,
    CommonTaskFormComponent,
    DetailedTaskFormComponent,
    SelectTemplateComponent,
    TaskCreationMobileDialogComponent,
    CropDependencyPickerComponent,
    CropStagePickerComponent,
    TaskTagsFormComponent,
    TaskTagsProductComponent,
    TaskTagsProductAutocompleteComponent
  ],
  imports: [
    CommonModule,

    // Material modules
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatStepperModule,
    MatTooltipModule,
    MatInputModule,
    MatDatepickerModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MomentDateModule,

    AngularSvgIconModule,

    // Flex
    FlexLayoutModule,
    CKEditorModule,

    // Kalgudi modules
    KalgudiDialogsModule,
    KalgudiStepperModule,
    KalgudiSelectCardModule,
    KalgudiDialogsModule,
    KalgudiImageChipListModule,
    KalgudiImagePickerModule,
    CommonSearchBarModule,
    KalgudiNgxFormlyModule,
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,


    // Formly
    FormlyModule.forRoot({
      types: [
        { name: 'tagsBaseProduct', component: TaskTagsProductComponent },
      ]
    }),

    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    TranslateModule,
    KalgudiProductsPickerModule
  ],
  providers: [
    KalgudiTaskCreationDialogService,
    CreateTaskStateService,
    KalgudiTaskCreationService,
    KalgudiTaskCreationApiService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    TaskCreationDialogComponent,
    TaskCreationMobileDialogComponent
  ]
})
export class TaskCreationModule { }
