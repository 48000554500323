import { Component, Input, OnInit } from '@angular/core';
import { checkMobileDevice, scaleOutAnimation } from '@kalgudi/core';
import { ChartData } from '@kalgudi/types';


@Component({
  selector: 'kl-coverage-stats',
  templateUrl: './coverage-stats.component.html',
  styleUrls: ['./coverage-stats.component.scss'],
  animations: [scaleOutAnimation]
})
export class CoverageStatsComponent implements OnInit {

  @Input()
  locationStats: ChartData[];

  @Input()
  memberStats: ChartData[];

  @Input()
  productStats: ChartData[];

  @Input()
  totalLocations: number;

  @Input()
  totalMembers: number;

  @Input()
  totalProducts: number;

  view: any[];

  // options
  gradient: boolean = false;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = 'below';

  locationColorScheme = {
    domain: [
      '#ff6d00', '#ffab00', '#bf360c', '#ffc107', '#fff176', '#ffea00', '#795548', '#9e9e9e', '#5d4037',
      '#f57f17', '#4caf50', '#01579b', '#009688', '#ab47bc', '#00e676', 'fdd835', '#f9a825', '#4caf50',
      '#8bc34a', '#cddc39', '#69f0ae', '#b2ff59', '#eeff41', 'aeea00', '#76ff03', '00c853',
      '#00e676', 'fdd835', '#f9a825'
    ]
  };

  membersColorScheme = {
    domain: ['#01579b', '#009688', '#ab47bc']
  };

  productsColorSchema = {
    domain: [
      '#4caf50', '#8bc34a', '#cddc39', '#69f0ae', '#b2ff59', '#eeff41', 'aeea00', '#76ff03', '00c853',
      '#00e676', 'fdd835', '#f9a825', '#ff6d00', '#ffab00', '#bf360c', '#ffc107', '#fff176', '#ffea00', '#795548', '#9e9e9e', '#5d4037',
      '#f57f17', '#4caf50']
  };


  constructor() { }

  ngOnInit() {
    this.view = checkMobileDevice() ? [350, 350] : [500, 300];
  }

}
