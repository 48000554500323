import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, KalgudiUserBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ProcessingApiService {


  /**
   * `v2/pages/actors`
   */
  private readonly API_STREAM = `${this.env.restBaseUrlV2}/pages/actors`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }


  /**
   * Fetch stocks list
   * @param pageId
   * @param offset
   * @param limit
   */
  fetchStream(
    offset: number,
    limit: number,
    pageId: string,
    actorRole: string): Observable<KalgudiStreamData> {

    const params = {
      offset: offset.toString(),
      limit: limit.toString(),
      pageId,
      actorRole
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_STREAM, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi project details object type
        map(r => this.mapStreamResponse(r))

      );
  }


  addProcessors(pageId: string, actorRole: string, payload: KalgudiUserBasicDetails[]): Observable<any> {

    const params = {
      pageId,
      actorRole
    };

    return this.httpClient.post<ApiResponseCommon>(this.API_STREAM, payload, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r, HttpStatusCode.CREATED)),

        // Map API response to Kalgudi project details object type
        map(r => r.data)

      );
  }

  private mapStreamResponse(res: ApiResponseCommon): KalgudiStreamData {

    return {
      items: res.data.results,
      count: res.data.count
    };
  }
}
