import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { PartialData } from '@kalgudi/types';

import { ManageTaskMembers } from '../../classes/manage-task-members';

@Component({
  selector: 'kl-manage-task-members',
  templateUrl: './manage-task-members.component.html',
  styleUrls: ['./manage-task-members.component.scss']
})
export class ManageTaskMembersComponent extends ManageTaskMembers implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector) {
      super(notification, util, injector);
    }


  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

  /**
   * Extra filters to get the projects
   */
  get filters(): PartialData {
    return {};
  }

}
