import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiFpoOverViewForm } from '../../classes/kalgudi-fpo-over-view-form';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { FileMimeTypes } from '@kalgudi/types';

@Component({
  selector: 'kl-fpo-over-view-form',
  templateUrl: './fpo-over-view-form.component.html',
  styleUrls: ['./fpo-over-view-form.component.scss']
})
export class FpoOverViewFormComponent extends KalgudiFpoOverViewForm implements OnInit {
  s3Category: any;
  acceptedImageFileTypes: FileMimeTypes[] = [FileMimeTypes.IMAGE];;
  s3BucketUrl: any;
  imgObj: any
  isFpoOrFponetApp: boolean = false;
  isShgApp: boolean = true;
  maxDate = new Date();

  constructor(
    protected injector: Injector,
    protected imagePickerService: KalgudiImagePickerService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) {
    super(
      injector,
      imagePickerService,
      env,
    );

    this.s3BucketUrl = env.s3BucketUrl;
    this.subscribeToIsPanFieldValueChanges();
    this.subscribeToIsGSTFieldValueChanges();
    this.subscribeToIsPesticideLicenseFieldValueChanges();
    this.subscribeToIsFertilizerLicenseFieldValueChanges();
    this.subscribeToIsSeedLicenseFieldValueChanges();

    let regCertificateUrl = this.profileDetails?.registrationCertificate;
    if(regCertificateUrl) {
      let imgUrl = this.s3BucketUrl + regCertificateUrl;
      this.imgObj = [{ url: imgUrl }];
      this.regCertAttachmentFormControl.patchValue(this.imgObj);
    }

    this.isFpoOrFponetApp = this.env?.appId === 'FPO_APP' || this.env?.appId === 'FPO_NET_APP';
    this.isShgApp = !(this.env?.appId === 'SHG_NET_APP');
  }

  ngOnInit() {
    this.isPan.patchValue('NO');
    this.isGST.patchValue('NO');
    this.isPesticideLicense.patchValue('NO');
    this.isFertilizerLicense.patchValue('NO');
    this.isSeedLicense.patchValue('NO');

    this.panAttachmentFormControl.patchValue('');
    this.gstAttachmentFormControl.patchValue('');
    this.pesticideLicenseAttachmentFormControl.patchValue('');
    this.fertilizerLicenseAttachmentFormControl.patchValue('');
    this.seedLicenseAttachmentFormControl.patchValue('');

    this.fpoBasicDetails.patchValue(this.profileDetails);

    this.businessTypeName.patchValue(this.profileDetails.lstOfUserBusinessDetailsInfo[0].businessTypeName);
  }
}
