<main id="add-board-members">
  <section *ngIf="editable && !hideActions">
    <form autocomplete="off" class="mt-2" [formGroup]="boardOfMembersForm">

      <div fxLayout="column" fxLayoutGap="10px">

        <div fxLayout="row wrap"  fxLayoutGap="10px" class="w-100" fxLayoutGap.xs="0">

          <div fxFlex="calc(62% - 10px)" fxFlex.xs="100%" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0">

            <!-- Name -->
            <div fxLayout="column" fxFlex="calc(38% - 10px)" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Name</mat-label>
                <input matInput [placeholder]="'Enter name' | translate" formControlName="name" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')" required>
                <mat-error class="mt-2" translate>Please enter name</mat-error>
              </mat-form-field>
            </div>
            <!--/ Name -->

            <!-- Designation -->
            <div fxLayout="column" fxFlex="calc(32% - 10px)" fxFlex.xs="100%">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Designation</mat-label>
                <input matInput  [placeholder]="'Enter designation' | translate" formControlName="designation" maxlength="30" (keypress)="onKeyPress($event, 'alphabetSpace')">
              </mat-form-field>
            </div>
            <!--/ Designation -->
          </div>

          <div fxLayout="row wrap" fxLayoutGap="10px" fxFlex="calc(20% - 10px)" fxFlex.xs="100%">

            <!-- Mobile -->
            <div fxLayout="column" fxFlex="calc(40% - 10px)" fxFlex.xs="calc(70% - 10px)">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label translate>Mobile number</mat-label>
                <input matInput  [placeholder]="'Enter mobile number' | translate" (keydown.space)="$event.preventDefault()" formControlName="mobile" maxlength="10" (keypress)="onKeyPress($event, 'number')">
                <mat-error translate class="mt-2">Please enter valid mobile number</mat-error>
              </mat-form-field>
            </div>
            <!--/ Mobile -->

            <div class="mt-n2" fxLayout="row" fxLayoutAlign="end center" fxHide.xs>
              <button mat-icon-button class="add-button" [disabled]="boardOfMembersForm?.invalid" (click)="addBoardMembers()">
                <mat-icon class="text-white">add</mat-icon>
              </button>
            </div>
            <div fxFlex.xs="calc(30% - 10px)" class="mt-n2" fxLayout="row" fxLayoutAlign="end center" fxHide.gt-xs>
              <button mat-flat-button class="add-button button-web w-100 text-white" [disabled]="boardOfMembersForm?.invalid" (click)="addBoardMembers()">
                <span>Add</span>
                <!-- <mat-icon class="text-white">add</mat-icon> -->
              </button>
            </div>
          </div>
        </div>

        <!-- Date of joining -->
        <!-- <div class="w-100" fxLayout fxLayoutAlign="space-between center">
          <div fxFlex="calc(50% - 10px)">
            <mat-form-field appearance="outline" fxLayout="column" class="w-100">
              <mat-label translate>Date of joining</mat-label>

                <input matInput [placeholder]="'Date of joining' | translate"
                  formControlName="dateOfJoining"
                  (click)="dateOfJoining.open()"
                  [matDatepicker]="dateOfJoining"
                  readonly />

                <mat-datepicker-toggle matSuffix [for]="dateOfJoining"></mat-datepicker-toggle>
                <mat-datepicker #dateOfJoining></mat-datepicker>
            </mat-form-field>
          </div>

        </div> -->
        <!--/ Date of joining -->
      </div>
    </form>

  </section>

  <div [ngClass]="{'mt-2 list-members': editable && !hideActions}">
    <kl-board-members-list
      [loggedInUserBusinessTypeName]="loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].businessTypeName"
      [loggedInUserNativeBusinessTypeName]="loggedInUserDetails?.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName"
      [boardOfMembers]="profileMembers"
      [editable]="editable"
      [hideActions]="hideActions"
      (removeBoardOfMember)="prepareRemoveMembersPayload($event)">
    </kl-board-members-list>
  </div>

  <div class="w-100 mt-2 save-button">
    <kl-flat-button
      label="Save"
      [progress]="progress"
      (clicked)="removeBoardMembers()"
      (disabled)="!boardOfMembersForm?.valid">
    </kl-flat-button>
  </div>
</main>
