<div id="program-update-stream">

  <ng-container>
    <ng-container *ngIf="(stream$ | async) as items">

      <!-- Wrapper for list of updates -->

      <div class="update m-3" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px"
        *ngIf="items?.length">
        <span translate>
          Updates
        </span>
        <div fxFlex class="border-bottom mb-2" fxHide.xs></div>
      </div>
      <ng-container *ngIf="items?.length">

        <div>
          <div *ngFor="let item of items; let index=index;">
            <kl-share-update-tile
              *ngIf="!item?.promotionalTitle && (!item?.productsMetaData || item?.productsMetaData?.length === 0)"
              [url]="url"
              [postDetails]="item"
              [animationDelay]="index * 1">
            </kl-share-update-tile>

            <kl-promotional-share-tile
              *ngIf="item?.promotionalTitle || (!item?.promotionalTitle && item?.productsMetaData && item?.productsMetaData?.length > 0)"
              [url]="url"
              [postDetails]="item"
              [animationDelay]="index * 1">
            </kl-promotional-share-tile>
          </div>
        </div>

        <!-- Load more -->
        <div class="m-3" *ngIf="(paginator$ | async) as paginator">
          <kl-load-more
            noMoreText="No more updates"
            [progress]="paginator?.loading"
            [hasMoreItems]="paginator?.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
        <!--/ Load more -->

      </ng-container>
      <!-- / Wrapper for list of updates -->

      <!-- No content if there no updates -->
      <div *ngIf="(paginator$ | async) as paginator" fxLayout="row" class="w-100">
        <div *ngIf="paginator?.loading" class="no-content-center w-100" fxLayoutAlign="center center">
          <kl-no-content
            svgIcon="assets/svgs/updates.svg"
            text="No updates from this program"
            iconSize="md"
            progressText="Loading updates..."
            [progress]="paginator?.loading">
          </kl-no-content>
        </div>
      </div>
      <!-- No content if there no updates -->

    </ng-container>
  </ng-container>
</div>

