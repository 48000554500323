import { MapsAPILoader } from '@agm/core';
import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'kl-order-location-marker',
  templateUrl: './order-location-marker.component.html',
  styleUrls: ['../traceability-map.component.scss']
})
export class OrderLocationMarkerComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  fulfillmentBasedOnStore: any;

  @Input()
  sellerDetails: any;

  @Input()
  buyerDetails;

  @Input()
  progress: boolean;

  @Input()
  orderDetails: any;

  public lat: string;
  public lng: string;

  public origin: google.maps.LatLng;
  public destination: google.maps.LatLng;
  public wayPoints: google.maps.DirectionsWaypoint[] = [];

  public renderOptions = {
    suppressMarkers: true,
  }

  test = 'Ash'
  public markerOptions = {
      origin: {
        icon: 'assets/icons/seller_icon.png',
        infoWindow: `
          <h5>Supplier</h5>
          <p>address</p>
          `
      },
      waypoints: {
        icon: 'assets/icons/fmt_icon.png',

        infoWindow: `
        <h5>Fulfillment center</h5>
        <p>address</p>
        `
      },
      destination: {
        icon: 'assets/icons/buyer_icon.png',
        animation: {
          BOUNCE: 1,
          DROP: 2
        },
        infoWindow: `
        <h5>Buyer</h5>
        <p>address</p>
        `
      },

  }

  constructor(private mapsApiLoader: MapsAPILoader) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.mapsApiLoader.load()
      .then(() => this.initAfterGoogleMaps());

  }

  ngOnChanges() {
    this.initAfterGoogleMaps();

  }

  initAfterGoogleMaps() {


    // this.lat = `17.448294`;
    // this.lng = `78.391487`;

    // this.origin = new google.maps.LatLng(17.448294,78.391487);

    // this.destination = new google.maps.LatLng(17.437462,78.448288);
    this.wayPoints = [
      {
        location: new google.maps.LatLng(17.447412,78.376228),
        stopover: false,
      }
    ];

    // Origin
    this.lat = this.sellerDetails.locationDetails.latitude;
    this.lng = this.sellerDetails.locationDetails.longitude;
    this.origin = new google.maps.LatLng(+this.lat, +this.lng);
    this.markerOptions.origin.infoWindow = `
    <h5 class="heading manufacturer">Supplier</h5>
    <p class="title">
      ${this.sellerDetails.firstName}
    </p>
    `
      // <span>, ${this.sellerDetails.location}</span>

    // Destination
    this.destination = new google.maps.LatLng(this.buyerDetails.googleLocation.latitude, this.buyerDetails.googleLocation.longitude)
    this.markerOptions.destination.infoWindow = `
      <h5 class="heading buyer">Buyer</h5>
      <p class="title">
        ${this.orderDetails.buyerFirstName || this.buyerDetails.name}
      </p>
    `
      // <span>, ${this.buyerDetails.addressLine1}</span>

    // FMT
    // const lat = this.fulFillmentDetails.lstOfUserBusinessDetailsInfo[0].locationTo.latitude;
    // const lng = this.fulFillmentDetails.lstOfUserBusinessDetailsInfo[0].locationTo.longitude;

    const lat = this.fulfillmentBasedOnStore.lat;
    const lng = this.fulfillmentBasedOnStore.lng;

    // console.log(lat, lng);


    this.wayPoints[0].location = new google.maps.LatLng(+lat, +lng);
    this.markerOptions.waypoints.infoWindow = `
    <h5 class="heading fmt">Fulfillment center</h5>
    <p class="title">
      ${this.fulfillmentBasedOnStore.location}
    </p>
      `
  }

}