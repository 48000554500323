import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LiveStockApiService {

  /**
   * `/data/storeProducts/LIVE_STOCK_TYPES.json`
   */
  private readonly API_LIVE_STOCK_TYPES = `https://devkalgudidata.s3.amazonaws.com/data/storeProducts/LIVE_STOCK_TYPES.json`;

  /**
   * `/data/storeProducts/LIVE_STOCK_TYPES.json`
   */
  private readonly API_LIVE_STOCK_PROD = `https://core.kalgudi.com/data/storeProducts/LIVE_STOCK_TYPES.json`;

  /**
   * /v2/profiles/:profileKey/live-stock
   */
  private readonly API_ADD_LIVE_STOCK = `${this.env.restBaseUrlV2}/profiles/:profileKey/live-stock`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Get live stock data
   * @returns
   */
  getLiveStock(): Observable<any> {

    const url = this.env.production ?  this.API_LIVE_STOCK_PROD : this.API_LIVE_STOCK_TYPES;
    return this.httpClient.get<ApiResponseCommon>(url);
  }

  /**
   * Calls api to add live stock
   */
  postLiveStock(profileKey: string, payload: any): Observable<any> {

    return this.httpClient.post<ApiResponseCommon>(this.API_ADD_LIVE_STOCK.replace(':profileKey', profileKey), payload)
      .pipe(
        map(res => {
            if (res.code !== HttpStatusCode.OK) {
              const errorMessage = res.info || 'Something went wrong, please try again later.';
              throw new ApiError(new Error(errorMessage));
            }
            // All good, return the response back
            return res.info;
          }),

        // Map the api response data
        map(res => res.data)
      );
  }

  /**
   * Calls api to update live stock
   */
  updateLiveStock(profileKey: string, payload: any): Observable<any> {

    return this.httpClient.put<ApiResponseCommon>(this.API_ADD_LIVE_STOCK.replace(':profileKey', profileKey), payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to add live stock
        map(res => res.data)
      );
  }

  /**
   * Calls api to get live stock list
   */
  getLiveStockList(profileKey: string): Observable<any> {

    return this.httpClient.get<ApiResponseCommon>(this.API_ADD_LIVE_STOCK.replace(':profileKey', profileKey))
    .pipe(
      map(res => this.util.apiErrorHandler(res)),

      // Map API response to add live stock
      map(res => res.data),
    );
  }
}
