import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[klEventBubblingBlocker]'
})
export class EventBubblingBlockerDirective implements OnDestroy, AfterViewInit {

  @Input()
  klEventBubblingBlocker: string;

  private blockedNodes: NodeList;

  /**
   * Element upon which directive is applied
   */
  private get element(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  constructor(
    private elementRef: ElementRef,
  ) { }

  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.removeEventListeners();
  }

  /**
   * Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
   */
  ngAfterViewInit(): void {
    this.blockLinksEventBubbling();
  }

  /**
   * Blocks event bubbling of a tag
   */
  private blockLinksEventBubbling() {

    this.blockedNodes = this.element.querySelectorAll(this.klEventBubblingBlocker) as any;

    this.blockedNodes.forEach(node => {
      node.addEventListener('click', (event) => { event.stopPropagation(); })
    });
  }

  private removeEventListeners(): void {

    if (this.blockedNodes) {
      this.blockedNodes.forEach(node => node.removeEventListener('click', _ => _));
    }
  }
}
