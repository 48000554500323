import { Injectable } from '@angular/core';
import {
  ApiResponseProjectMembersData,
  ApiResponseTaskMembersData,
  BulkMembersAdditionResponse,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import { AddTaskMembersApiService } from './add-task-members-api.service';

@Injectable()
export class AddTaskMembersService {

  constructor(
    private api: AddTaskMembersApiService
  ) { }

  /**
   * Make an api call to add task members
   */
  addTaskMembers(taskId: string, payload, filters: PartialData = {}): Observable<ApiResponseTaskMembersData> {
    return this.api.addTaskMembers(taskId, payload, filters);
  }


  /**
   * Make an api call to get task members
   */
  getTaskMembers(taskId: string, offset: number, limit: number): Observable<ApiResponseTaskMembersData> {
    return this.api.getTaskMembers(taskId, offset, limit);
  }

  /**
   * Make an api call to get project members
   */
  getProjectMembers(projectId: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {
    return this.api.getProjectMembers(projectId, offset, limit);
  }

  /**
   * Make an api call to delete task members
   */
  deleteTaskMembers(taskId: string, projectId: string, profileKey: string): Observable<string[]> {
    return this.api.deleteTaskMembers(taskId, projectId, profileKey);
  }

  /**
   * Make an api call to search project members
   */
  searchMembers(projectId: string, searchKeyword: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {
    return this.api.searchMembers(projectId, searchKeyword, offset, limit);
  }

  /**
   * Make an api call to add task bulk members
   */
  addBulkMembers(taskId: string, payload: string[], params: PartialData = {}): Observable<BulkMembersAdditionResponse> {
    return this.api.addBulkMembers(taskId, payload, params);
  }
}
