import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { Directive } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, finalize, map, mapTo, startWith, switchMap, tap } from 'rxjs/operators';

import { KalgudiStreamData } from '../typings';
import { PartialData } from '@kalgudi/types';

/**
 * It defines a customized version of MatDataSource for material tables.
 *
 * @usage
 *
 * ```ts
 * ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
 * ViewChild(MatSort, { static: true }) matSort: MatSort;
 *
 * // No paginator, no mat sort
 * const dataSource = new KalgudiDataSource<T>();
 *
 * // Only paginator, no mat sort
 * const dataSource = new KalgudiDataSource<T>(this.matPaginator);
 *
 * // No paginator, only mat sort
 * const dataSource = new KalgudiDataSource<T>(null, this.matSort);
 *
 * // With paginator and mat sort
 * const dataSource = new KalgudiDataSource<T>(this.matPaginator, this.matSort);
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export class KalgudiDataSource<T> extends DataSource<T> {

  /**
   * Stream api service, it gets called on every table pagination or sorting change
   *
   * Override this method while creating a new instance of the `KalgudiDataSource`
   */
  streamApi$: (offset: number, limit: number, sortBy: string, sortDirection: SortDirection, params?: PartialData) => Observable<KalgudiStreamData>;

  data$: Observable<T[]>;
  loading$: Observable<boolean>;

  private readonly loadingSubject     = new BehaviorSubject<boolean>(false);
  private readonly dataSubject        = new BehaviorSubject<T[]>([]);
  private readonly resetStreamSubject = new BehaviorSubject(false);
  private readonly destroyedSubject   = new Subject();

  private paginator$: Observable<PageEvent>;
  private sort$: Observable<Sort>;
  private resetStream$: Observable<any>;
  private destroyed$: Observable<any>;
  private params$: Observable<PartialData>;


  constructor(
    private matPaginator?: MatPaginator,
    private matSort?: MatSort,
    public params?: PartialData
  ) {
    super();

    // Default streamApi
    this.streamApi$ = this.defaultStreamApi;

    this.paginator$   = this.initPaginator$();
    this.sort$        = this.initSort$();
    this.data$        = this.dataSubject.asObservable();
    this.loading$     = this.loadingSubject.asObservable();
    this.resetStream$ = this.resetStreamSubject.asObservable();
    this.destroyed$   = this.destroyedSubject.asObservable();
    this.params$      = this.initparams$(params);
    // Subscribe to reset page event changes
    // this.resetStream$
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(_ => this.resetTable());
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  /**
   * List of current items in the data source
   */
  get data(): T[] {
    return this.dataSubject.getValue();
  }


  /**
   * Creates a default page event object
   */
  private get DEFAULT_PAGE_EVENT(): PageEvent {

    const pageEvent = new PageEvent();

    pageEvent.length            = 10;
    pageEvent.pageSize          = 10;
    pageEvent.pageIndex         = 0;
    pageEvent.previousPageIndex = 0;

    return pageEvent;
  }

  /**
   * Creates a default page event object
   */
  private get DEFAULT_PAGE_STARTING_VALUE(): PageEvent {

    const pageEvent = new PageEvent();

    pageEvent.length    = this.matPaginator ? this.matPaginator.length : 10;
    pageEvent.pageSize  = this.matPaginator ? this.matPaginator.pageSize : 10;
    pageEvent.pageIndex = this.matPaginator ? this.matPaginator.pageIndex : 0;

    return pageEvent;
  }

  /**
   * Default sort object
   */
  private get DEFAULT_PAGE_SORT(): Sort {

    return {
      active: '',
      direction: 'asc'
    };
  }

  /**
   * Default sort object
   */
  private get DEFAULT_PAGE_SORT_STARTING_VALUE(): Sort {

    return {
      active: this.matSort ? this.matSort.active : '',
      direction: this.matSort ? this.matSort.direction : 'asc'
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  connect(collectionViewer: CollectionViewer): Observable<T[] | readonly T[]> {
    return combineLatest(

      this.resetStream$,

      // For any page events
      this.paginator$.pipe(

        // Start with a default page values
        startWith(this.DEFAULT_PAGE_STARTING_VALUE),

        // Don't emit if the paginator has not changed
        distinctUntilChanged(this.isPageChanged),
      ),

      // For any sort events
      this.sort$.pipe(
        startWith(this.DEFAULT_PAGE_SORT_STARTING_VALUE),

        // Don't emit if sort has not changed
        distinctUntilChanged(this.isSortChanged)
      )
    )
    .pipe(

      // Fetch table stream items from the API
      switchMap(([r, p, s]) => {
        return this.params$.pipe(switchMap((extraParams) => {

          return this.fetchItemsFromApi(p.pageIndex * p.pageSize, p.pageSize, s.active, s.direction, extraParams)
        }))
      }),

      // Return the items from the stream
      map(streamData => streamData.items)
    );

  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();

    this.destroyedSubject.next();
    this.destroyedSubject.complete();
  }

  /**
   * Resets the table stream
   */
  resetStream(): void {
    this.resetTable();
    this.resetStreamSubject.next(!this.resetStreamSubject.getValue());
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Fetches table stream items from the API.
   */
  private fetchItemsFromApi(offset: number, limit: number, sortBy: string, sortDirection: SortDirection, extraParams?: PartialData): Observable<KalgudiStreamData> {

    // Turn on the loading progress spinner
    this.toggleLoading(true);

    return this.streamApi$(offset, limit, sortBy, sortDirection, extraParams)
      .pipe(

        // Set paginator if count exists
        tap(streamData => this.setPaginatorLength(streamData.count)),

        // Push the latest data into the observable stream
        tap(streamData => this.dataSubject.next(streamData.items)),

        // Handle Api errors,
        // On any Api error return the empty stream item
        catchError(() => {
          return of({
            count: 0,
            items: []
          });
        }),

        // Turn off loading progress spinner
        finalize(() => this.toggleLoading(false)),
      );
  }

  /**
   * Default stream api implementation
   */
  private defaultStreamApi(offset: number, limit: number, sortBy: string, sortDirection: SortDirection, params?: PartialData): Observable<KalgudiStreamData> {

    return of()
      .pipe(
        mapTo({
          items: [],
          count: 0
        }
      ));
  }

  /**
   * Sets the paginator length. Use this method to set paginator length on each Api call.
   */
  private setPaginatorLength(value: number = 0): void {

    if (this.matPaginator) {
      this.matPaginator.length = value;
    }
  }

  /**
   * Gets, the MatPaginator object if specified otherwise, default paginator object.
   */
  private initPaginator$(): Observable<PageEvent> {

    return this.matPaginator
      ? this.matPaginator.page
      : of(this.DEFAULT_PAGE_EVENT);
  }

  /**
   * Gets, the MatSort object if specified otherwise, default sort object.
   */
  private initSort$(): Observable<Sort> {

    return this.matSort
      ? this.matSort.sortChange
      : of(this.DEFAULT_PAGE_SORT);
  }

  /**
   *
   * @param params
   * @returns extra params object
   */
  private initparams$(params): Observable<any> {
    return of(params ? params : {});
  }

  /**
   * Returns `true` if the sort has changed otherwise `false`.
   */
  private isSortChanged(x: MatSort, y: MatSort): boolean {
    return (x.active === y.active && x.direction === y.direction);
  }

  /**
   * Returns `true` if the page has changed otherwise `false`.
   */
  private isPageChanged(x: PageEvent, y: PageEvent): boolean {
    return (x.length === y.length && x.pageIndex === y.pageIndex && x.pageSize === y.pageSize);
  }

  /**
   * Toggles the loading status
   */
  private toggleLoading(val: boolean): void {

    this.loadingSubject.next(val);
  }

  /**
   * Resets paginator and sort.
   */
  private resetTable(): void {

    this.resetMatPaginator();
    this.resetMatSort();
  }

  private resetMatPaginator(): void {
    // this.matPaginator.pageIndex = 0;

    if (!this.matPaginator) {
      return;
    }

    this.matPaginator.firstPage();
  }

  private resetMatSort(): void {

    if (!this.matSort) {
      return;
    }

    this.matSort.active = '';
    this.matSort.direction = '';
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
