import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, KalgudiUserMembershipDetails, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Injectable()
export class KalgudiProfileMembershipsApiService {

  /**
   * `v2/profiles/:profileKey/biz-memberships`
   */
  private readonly API_MEMBERSHIPS_BASE = `${this.env.restBaseUrlV2}/profiles/:profileKey/biz-memberships`;

  /**
   * `v2/profiles/:profileKey/biz-memberships`
   */
  private readonly API_MEMBERSHIPS_SAVE = this.API_MEMBERSHIPS_BASE;

  /**
   * `v2/profiles/:profileKey/biz-memberships/:id`
   */
  private readonly API_MEMBERSHIPS_UPDATE = `${this.API_MEMBERSHIPS_BASE}/:id`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private profileState: KalgudiProfileStateService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Calls API to save a new membership to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param payload Membership payload
   */
  saveNewMembership(
    profileKey: string, defaultBizKey: string,
    payload: KalgudiUserMembershipDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_MEMBERSHIPS_SAVE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey);

    return this.httpClient.post<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateMembershipResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to save a new membership to the logged in user profile
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param membershipId Unique identified associated with membership
   *
   * @param payload Updated membership payload
   */
  updateNewMembership(
    profileKey: string, defaultBizKey: string,
    membershipId: string, payload: KalgudiUserMembershipDetails,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_MEMBERSHIPS_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', membershipId);

    return this.httpClient.put<ApiResponseCommonV1>(url, payload, { params })
      .pipe(

        // Api response error handler
        map(r => this.saveOrUpdateMembershipResponseHandler(payload, r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  /**
   * Calls API to delete an existing membership of the logged in user.
   *
   * @param profileKey Logged in user profile key
   * @param defaultBizKey Logged in user default business key
   * @param membershipId Unique identifier of the membership
   */
  deleteMembership(
    profileKey: string,
    defaultBizKey: string,
    membershipId: string,
    extraParams: PartialData = {}
  ): Observable<boolean> {

    const params = {
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    // Api url
    const url = this.API_MEMBERSHIPS_UPDATE
      .replace(':profileKey', profileKey)
      .replace(':bizKey', defaultBizKey)
      .replace(':id', membershipId);

    return this.httpClient.delete<ApiResponseCommonV1>(url, { params })
      .pipe(

        // Api response error handler
        map(r => this.deleteMembershipResponseHandler(r)),

        // No errors, all good return true
        mapTo(true),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Save new Membership API response handler. Throws error messages if
   * Api didn't sent success creation response.
   */
  private saveOrUpdateMembershipResponseHandler(req: KalgudiUserMembershipDetails, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved membership details
    return res;
  }

  /**
   * Delete membership API response handler. Throws error messages if
   * Api didn't sent success deletion response.
   */
  private deleteMembershipResponseHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Api didn't responded success response
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));

    }

    // All good, successfully saved membership details
    return res;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
