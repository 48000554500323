<section class="program-page mb-3 mt-1 page-creation-form">
  <div class="w-100 col-7 mx-auto p-0 program-creation page-form">

    <!-- Wrapper Form -->
    <ng-container *ngIf="pageForm">
      <form autocomplete="off" [formGroup]="pageForm">
        <div class="page-info">

          <!-- Page Info -->
          <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="0">
            <div class="pb-3" fxLayout="column" fxFlex="50%" fxFlex.xs="100%">
              <mat-form-field appearance="outline" [@floatTop]="{ value: '20', params: {delay: 50}}">
                <mat-label translate>FIG Activities</mat-label>
                <input matInput [placeholder]="'FIG Activities' | translate" formControlName="activities" required>
                <mat-error class="mt-3" *ngIf="activitiesFiled?.errors?.required" translate>Please mention FIG
                  activities</mat-error>
              </mat-form-field>
            </div>

            <div class="fig-type-field" fxFlex="50%" fxLayout="column" fxFlex.xs="100%">
              <mat-form-field appearance="outline" [@floatTop]="{ value: '20', params: {delay: 50}}">
                <mat-label translate>FIG type</mat-label>
                <mat-select formControlName="programType">
                  <mat-option *ngFor="let type of programType" [value]="type?.id">
                    {{ type?.value | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- / Page Info -->

          <span class="px-2 text-secondary" [@floatTop]="{ value: '30', params: {delay: 75}}" translate>Duration<strong
              class="strong">*</strong></span>

          <!-- Date picker -->
          <div class="date-picker mt-2 pb-3 w-100" fxLayout="row" fxLayoutGap="15px" fxLayoutGap.xs="0px"
            fxLayoutAlign="space-between" [@floatTop]="{ value: '40', params: {delay: 100}}">
            <mat-form-field appearance="outline" fxFlex="44%">
              <input matInput placeholder="DD/MM/YYYY" [matDatepicker]="startDate" (click)="startDate.open()"
                formControlName="startDuration" readonly required>
              <mat-error class="mt-3" *ngIf="startDurationFiled?.errors?.required" translate>Please mention start
                duration</mat-error>
              <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>

            <span text-center fxLayoutAlign="center center" translate>to</span>

            <mat-form-field appearance="outline" fxFlex="44%">
              <input matInput placeholder="DD/MM/YYYY" [matDatepicker]="endDate" (click)="endDate.open()"
                formControlName="endDuration" readonly required>
              <mat-error class="mt-3" *ngIf="endDurationFiled?.errors?.required" translate>Please mention end
                duration</mat-error>
              <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
            </mat-form-field>
          </div>
          <!-- / Date picker -->

          <!-- Vision -->
          <div class="mb-3 pt-1" fxLayout="column">
            <mat-form-field appearance="outline" [@floatTop]="{ value: '60', params: {delay: 150}}">
              <mat-label translate>Vision</mat-label>
              <textarea matInput [placeholder]="'Vision of this FIG page' | translate"
                formControlName="vision"></textarea>
            </mat-form-field>
          </div>

        </div>

      </form>
    </ng-container>
    <!-- / Wrapper Form -->
  </div>
</section>