<mat-card class="my-2 p-0 assist-qa" @bottomFloatingCard>

  <!-- Title -->
  <div fxLayout="row" class="px-3 pt-3 pb-0" fxLayoutGap="8px" fxLayoutAlign="start center" fxHide.xs>
    <div>
      <svg-icon src="assets/svgs/discuss-issue.svg" [svgStyle]="{'width.px': '25', 'height.px': '25'}"></svg-icon>
    </div>
    <div>
      <p class="mb-0 title" translate>Ask a question</p>
    </div>
  </div>
  <!-- /Title -->

  <div id="kalgudi-share-update-form" class="p-0">
    <form autocomplete="off" [formGroup]="shareForm">
      <div class="p-3 share-update-form">

        <div>
          <kl-assist-members-picker [formControl]="assistedUser">
          </kl-assist-members-picker>
        </div>

        <!-- Form field -->
        <div id="kalgudi-qa-form">
          <kl-common-qa-form
            [shareForm]="shareForm"
            [shareFormText]="shareFormText"
            [attachments]="shareFormAttachments?.value"
            [textFieldPlaceHolder]="'Hey what do u want to know about?'"
            [progress]="progress"
            submitBtnText="Ask"
            (shareQaPost)="createPost()"
            [rowsCount]="rowsCount"
            [compactView]="isMobileDevice ? true : false"
            (removeAttachment)="removeImage($event)">
          </kl-common-qa-form>
        </div>
        <!-- / Form field -->
      </div>
    </form>
  </div>

</mat-card>
