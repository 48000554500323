<div class="farmer-memberships p-3">

  <!-- Add membership -->
  <div class="members-list" fxLayout fxLayoutAlign="space-between center">

    <div fxLayout fxLayoutGap="7px" fxLayoutAlign="start center">
      <div class="mt-2">
        <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/farmer-membership.svg"
        [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 title" translate>Membership</p>
      </div>
    </div>

    <div>
      <button mat-button class="add-membership" *ngIf="editable" (click)="openMembershipsDialog()">
        <div class="button-title">
          <p class="m-0 profile-name"><b translate>Add</b></p>
        </div>
      </button>
    </div>
  </div>
  <!-- /Add membership -->

  <div class="mt-2" *ngFor="let memberDetails of lstOfMemberships; let last = last">
    <div fxLayout="row" class="mb-1" fxLayoutAlign="space-between center">

      <!-- Profile name  -->
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="row">
            <p class="m-0 profile-name text-truncate w-90">
              {{ memberDetails?.organizationName }}<span class="ml-1 profile-name"
              *ngIf="memberDetails?.organizationName"> - <span class="m-0 data-details">{{ memberDetails?.organization }}</span></span>
            </p>
          </div>
        </div>

      </div>
      <!-- /Profile name  -->

      <!-- Edit button -->
      <div fxLayout="column" fxLayoutAlign="end end" fxFlex="10%" fxFlex.sm="16%" *ngIf="editable">
        <button mat-icon-button [matMenuTriggerFor]="menu" class="options" (click)="$event.stopPropagation()">
          <mat-icon class="text-secondary">more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu" class="mat-menu-md membership-mat-menu" fxLayout="column">
          <button class="edit-button border-bottom px-1" mat-menu-item (click)="openMembershipsDialog(memberDetails)">
            <div fxLayout fxLayoutAlign="start center" class="ml-2">
              <mat-icon class="membership-edit rounded-circle text-secondary" fxLayoutAlign="center center">edit</mat-icon>
              <span class="text-secondary font-weight-normal text" translate>Edit</span>
            </div>
          </button>
          <button class="delete-button px-1" mat-menu-item (click)="deleteMembership(memberDetails?.id)">
            <div fxLayout fxLayoutAlign="start center" class="ml-2">
              <mat-icon class="membership-edit rounded-circle text-secondary" fxLayoutAlign="center center">delete</mat-icon>
              <span class="text-secondary font-weight-normal text" translate>Delete</span>
            </div>
          </button>
        </mat-menu>
      </div>
      <!-- /Edit button -->
    </div>

    <!-- Section of Member id, role, since -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="column" fxFlex="33.3%">
        <span class="text-secondary sub-name" translate>Member ID</span>
        <span class="member-details text-truncate">{{ memberDetails?.farmerMembershipId }}</span>
      </div>

      <div fxLayout="column" fxFlex="33.3%">
        <span class="text-secondary sub-name" translate>Role</span>
        <span class="member-details text-truncate">{{ memberDetails?.memberRole}}</span>
      </div>

      <div fxLayout="column" fxFlex="33.3%">
        <span class="text-secondary sub-name" translate>Since</span>
        <span class="member-details text-truncate">{{ memberDetails?.sinceYear }}</span>
      </div>
    </div>
    <!-- /Section of Member id, role, since -->
    <hr *ngIf="!last" class="w-100">
  </div>

  <!-- No content -->
  <div *ngIf="!lstOfMemberships?.length">
    <kl-no-content matIcon="people"
      [text]="'No membership added yet!' | translate"
      iconSize="md"
      [actionLink]="editable ? 'Add Membership' : '' | translate"
      (action)="openMembershipsDialog()">
    </kl-no-content>
  </div>
  <!-- / No content -->
</div>
