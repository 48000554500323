import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ShareQaAnswerRequest, ShareQaFullDetails, ShareQaRequest } from '@kalgudi/types';

import { KalgudiSocialAnswer } from '../../kalgudi-social-answer';
import { KalgudiQaService } from '../../services/kalgudi-qa.service';

@Component({
  selector: 'kl-qa-answers',
  templateUrl: './qa-answers.component.html',
  styleUrls: ['./qa-answers.component.scss']
})
export class QaAnswersComponent extends KalgudiSocialAnswer<ShareQaAnswerRequest, ShareQaFullDetails> implements OnInit {

  @Input()
  url: string;

  rowsCount: number;
  isLoggedIn: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService
  ) {
    super(
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3
    );

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
    this.rowsCount = checkMobileDevice() ? 3 : 3;
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Event handler called after the answer posted successfully.
   */
  onPostCreated(req: ShareQaRequest, res: any): void {
    // console.log('qa', this.qa);
    // this.postCreated.emit(res);
  }

}
