<div class="share-update-products">
  <div fxLayout="row">
    <div class="text-left" fxFlex="5%" fxLayoutAlign="center center" *ngIf="shareProducts?.length > maxLength">
      <button mat-icon-button (click)="scrollLeft(); $event.stopPropagation()">
        <mat-icon>chevron_left</mat-icon>
      </button>
    </div>

    <div #widgetsContent fxFlex="{{shareProducts?.length > 3 ? '90%' : '100%'}}"
      fxLayout="row" fxLayoutGap="15px"  class="w-100 overflow-hidden">
      <div fxFlex="0 0 calc(33% - 15px)" fxFlex.xs="0 0 calc(50% - 15px)" class="tile w-100" *ngFor="let shareProduct of shareProducts">
        <kl-share-products-tile [shareProduct]="shareProduct" [store]="store" ></kl-share-products-tile>
      </div>
    </div>

    <div class="text-right" fxFlex="5%" fxLayoutAlign="center center" *ngIf="shareProducts?.length > maxLength">
      <button mat-icon-button (click)="scrollRight(); $event.stopPropagation()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  </div>

</div>
