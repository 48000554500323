import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiMobileDialogModule,
  KalgudiNoContentModule,
  MobileTabsModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { KalgudiSurveySharedModule } from '../survey-shared/survey-shared.module';
import { AddQuestionDialogComponent } from './components/add-question-dialog/add-question-dialog.component';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import {
  MobileAddQuestionDialogComponent,
} from './components/mobile-add-question-dialog/mobile-add-question-dialog.component';
import { SurveyQuestionsListComponent } from './components/survey-questions-list/survey-questions-list.component';
import { SurveyQuestionsStreamComponent } from './components/survey-questions-stream/survey-questions-stream.component';
import { KalgudiSurveyQuestionsApiService } from './services/kalgudi-survey-questions-api.service';
import { KalgudiSurveyQuestionsService } from './services/kalgudi-survey-questions.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    AddQuestionComponent,
    SurveyQuestionsListComponent,
    SurveyQuestionsStreamComponent,
    AddQuestionDialogComponent,
    MobileAddQuestionDialogComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Material
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    TranslateModule,

    // Flex module
    FlexLayoutModule,

    // Kalgudi modules
    KalgudiDialogsModule,
    KalgudiMobileDialogModule,
    KalgudiButtonModule,
    KalgudiImagePickerModule,
    KalgudiImageChipListModule,
    KalgudiNoContentModule,
    MobileTabsModule,

    // Survey modules
    KalgudiSurveySharedModule,
  ],
  providers: [
    KalgudiSurveyQuestionsService,
    KalgudiSurveyQuestionsApiService
  ],
  entryComponents: [
    AddQuestionDialogComponent,
    MobileAddQuestionDialogComponent
  ],
  exports: [
    AddQuestionComponent,
    SurveyQuestionsStreamComponent,
    SurveyQuestionsListComponent,
  ]
})
export class KalgudiSurveysQuestionsModule { }
