import { Component, OnInit, ViewChild } from '@angular/core';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';

@Component({
  selector: 'core-store-banner-tile-images',
  templateUrl: './store-banner-tile-images.component.html',
  styleUrls: ['./store-banner-tile-images.component.scss']
})
export class StoreBannerTileImagesComponent implements OnInit {

  @ViewChild('bannerCarousel', {static: true}) bannerCarousel: NguCarousel<any>;

  name = 'Angular';
  slideNo = 0;

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: {timing: 3000, initialDelay: 1000},
    point: {
      visible: true
    },
    loop: true,
    touch: true,
    velocity: 0.2
  };

  carouselItems = [
    'assets/shaktimaan/banner1.jpg',
    'assets/shaktimaan/banner2.jpg',
    'assets/shaktimaan/banner3.jpg',
  ];

  constructor() { }

  ngOnInit() {
  }


  /**
   * Navigate to store page
   */
  openStorePage() {

    window.open(`https://shaktimanfarmingsolutions.com`, '_blank');
  }
}
