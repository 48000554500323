export const KALGUDI_BUSINESS_TYPES = {
  ACCOMMODATION_AND_FOOD_SERVICES: 'BT000020',
  ADMINISTRATIVE_SUPPORT_WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES: 'BT000021',
  AGRI_INPUTS: 'BT000008',
  AGRICULTURE_FORESTRY_AND_FISHING: 'BT000022',
  ANIMAL_HUSBANDRY_POULTRY_FISHERIES: 'BT000042',
  ARTS_ENTERTAINMENT_AND_RECREATION: 'BT000025',
  ASSOCIATIONS: 'BT000041',
  CO_OPERATIVE_SOCIETIES: 'BT000010',
  CONSTRUCTION: 'BT000026',
  CROPS_PLANTS: 'BT000043',
  DIGITAL_SERVICE_AGENT: 'BT000013',
  DIGITAL_SERVICES_AGENCY: 'BT000014',
  EDUCATIONAL_SERVICES: 'BT000027',
  EMPLOYEE: 'BT000017',
  FARMER: 'BT000002',
  FINANCE_AND_INSURANCE: 'BT000028',
  HEALTH_CARE_AND_SOCIAL_ASSISTANCE: 'BT000029',
  I_RUN_A_BUSINESS: 'BT000019',
  I_WILL_TELL_LATER: 'BT000012',
  INFORMATION_TECHNOLOGY_IT: 'BT000030',
  INTERMEDIARY_AGENT_BROKER: 'BT000001',
  KRISHI_VIGYAN_KENDRAS: 'BT000048',
  MANUFACTURER: 'BT000023',
  MANUFACTURING: 'BT000031',
  MINING_QUARRYING_OIL_AND_GAS_EXTRACTION: 'BT000032',
  NGO: 'BT000045',
  ORGANIZATION: 'BT000018',
  OTHER_SERVICES_EXCEPT_PUBLIC_ADMINISTRATION: 'BT000033',
  OTHERS: 'BT000044',
  PROCESSOR_MILLER_GINNER: 'BT000007',
  PRODUCER_GROUP: 'BT000050',
  PRODUCER_ORGANIZATIONS: 'BT000049',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES: 'BT000034',
  PUBLIC_ADMINISTRATION: 'BT000035',
  REAL_ESTATE_RENTAL_AND_LEASING: 'BT000036',
  RETAIL_TRADE: 'BT000037',
  SALE_MARKETING_AND_PUBLISHING: 'BT000024',
  SERVICE_PROVIDER: 'BT000009',
  STUDENT: 'BT000015',
  TRADE_UNIONS: 'BT000046',
  TRADER: 'BT000000',
  TRANSPORTATION_AND_WAREHOUSING: 'BT000038',
  TRANSPORTER: 'BT000003',
  TRUSTS: 'BT000047',
  UNIVERSITY: 'BT000016',
  UTILITIES: 'BT000039',
  WAREHOUSER: 'BT000006',
  WHOLESALE_TRADE: 'BT000040',
};

