<div class="info-form mb-4">
  <form autocomplete="off" [formGroup]="infoForm">

    <h3 class="my-3" translate>General Information</h3>
    <!-- Program name -->
    <div fxLayout="column" fxLayoutGap="20px">

      <div fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Name of the program</mat-label>
          <input matInput formControlName="pageTitle">
        </mat-form-field>
      </div>

      <!-- Vision -->
      <div fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Vision</mat-label>
          <textarea matInput formControlName="vision"></textarea>
        </mat-form-field>
      </div>

      <!-- Activities -->
      <div fxLayout="column">
        <mat-form-field appearance="outline" class="mat-form-field-sm">
          <mat-label translate>Program Activities</mat-label>
          <textarea matInput formControlName="activities"></textarea>
        </mat-form-field>
      </div>


      <kl-common-page-form [commonInfoForm]="infoForm"></kl-common-page-form>


      <!-- Website link and program type -->
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>Website Link</mat-label>
            <input matInput formControlName="webSite">
          </mat-form-field>
        </div>

        <div fxFlex fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>Program type</mat-label>
            <mat-select formControlName="programType">
              <mat-option *ngFor="let type of programType" [value]="type?.id">
                {{ type?.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>



    </div>

    <!-- Social Media Links -->

    <h3 class="my-3" translate>Social Media Links</h3>

    <div fxLayout="column" fxLayoutGap="20px">


      <div fxLayout="row" fxLayoutGap="10px">
        <!-- Facebook link -->
        <div fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>Facebook Link</mat-label>
            <input matInput formControlName="facebookLink">
          </mat-form-field>
        </div>

        <!-- Twitter Link -->
        <div fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>Twitter Link</mat-label>
            <input matInput formControlName="twitterLink">
          </mat-form-field>
        </div>

      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <!-- LinkedIn link -->
        <div fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>LinkedIn Link</mat-label>
            <input matInput formControlName="linkedInLink">
          </mat-form-field>
        </div>

        <!-- Wikipedia Link -->
        <div fxFlex="50%" fxLayout="column">
          <mat-form-field appearance="outline" class="mat-form-field-sm">
            <mat-label translate>Wikipedia Link</mat-label>
            <input matInput formControlName="wikipediaLink">
          </mat-form-field>
        </div>

      </div>

    </div>

  </form>
</div>
