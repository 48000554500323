import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment, ATTACHMENT_TYPE_MAP } from '@kalgudi/types';

@Component({
  selector: 'kl-image-chip-list',
  templateUrl: './kalgudi-image-chip-list.component.html',
  styleUrls: ['./kalgudi-image-chip-list.component.scss']
})
export class KalgudiImageChipListComponent implements OnInit {

  @Input()
  attachments: Attachment[];

  @Input()
  hideRemove: boolean;

  @Input()
  isTruncate: boolean;

  @Output()
  remove = new EventEmitter<number>();

  attachmentType = ATTACHMENT_TYPE_MAP;
  hideChipList: boolean;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService
    ) { }

  ngOnInit() {
    this.hideChipList = checkMobileDevice();
  }

  /**
   * Emits an event to the parent
   * @param index
   */
  removeImage(index) {
    this.remove.emit(index);
  }

  openFullview(): void {
    this.kalgudiPhotoswipe.open(this.attachments, this.env.domain, 0).subscribe();
  }

}
