import { KeyValue } from '@angular/common';
import { Component, Inject, Injector, Input, OnChanges, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService, StoreProductsSearchService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, kalgudiAnimations, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { FileMimeTypes, IdValueMap, KalgudiUserBasicDetails, ProgramShareRequest, ProgramShareUpdate } from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import {
  KalgudiPagesMembersPickerService,
} from '../../../program-members-picker-dialog/services/kalgudi-pages-members-picker.service';
import { PageShareFiltersService } from '../../../program-target-members/services/page-share-filters.service';
import { KalgudiPromotionalShareForm } from '../../classes/kalgudi-promotional-share-form';

@Component({
  selector: 'kl-promotional-share-form',
  templateUrl: './promotional-share-form.component.html',
  styleUrls: ['./promotional-share-form.component.scss'],
  animations: kalgudiAnimations,
  viewProviders: [ PageShareFiltersService ]
})
export class PromotionalShareFormComponent extends KalgudiPromotionalShareForm implements OnInit, OnChanges {

  @Input()
  aiPredictionsShare: boolean;

  searchTypeList: IdValueMap[] = [
    {id: 'https://kuber.farm', value: 'Kuber'},
    {id: 'https://inputs.kalgudi.com', value: 'Input store'},
    {id: 'https://outputs.kalgudi.com', value: 'Output store'},
    {id: 'https://andhragreens.com', value: 'Andhra greens'},
    {id: 'https://tgreens.in', value: 'Tgreens'},
    {id: 'https://emahila.org', value: 'Emahila'},
    {id: 'https://shaktimanfarmingsolutions.com', value: 'Shaktiman'},
    {id: 'https://prorisestore.com', value: 'Prorise store'},
    {id: 'https://marketplace.kalgudi.com', value: 'Unified store'},
    {id: 'https://kalgudi.com', value: 'Unified store'},
    {id: 'https://sikkimgreens.com', value: 'Swayam Sikkim greens store'},
  ]

  information: any;
  rowsCount: number;
  showAdvanced: boolean;
  isMobileDevice: boolean;
  createdPost: boolean;
  textLimit: number;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected programDialogs: KalgudiPagesMembersPickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected sharePostService: KalgudiShareUpdateService,
    protected targetedMetaService: PageShareFiltersService,
    protected mobileMenuService: KalgudiBottomSheetService,
    private storeProductsSearchService: StoreProductsSearchService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    private bottomSheetService: KalgudiBottomSheetService
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      programDialogs,
      util,
      socialDataNormalizer,
      kalgudiApp,
      kalgudiPageService,
      sharePostService,
      targetedMetaService,
      mobileMenuService
    );

    this.lstOfAttachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.lstOfAttachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));

    this.skuSelection.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.selectedSku = res;
      })
  }

  ngOnInit() {
    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 2 : 2;

    this.textLimit = checkMobileDevice() ? 300 : 410;

    this.updateDateLocale();

    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }

    this.level2Id = this.shareForm.get('productsMetaData').value.productId;

    this.shareForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.level2Id = res && res.productsMetaData && res.productsMetaData.productId ? res.productsMetaData.productId : '';
          if(this.level2Id && res.productsMetaData && res.productsMetaData.productId && res.productsMetaData.productId !== this.level2Initialized) {
            this.getProductLevel2(this.level2Id, this.selectedStoreType);

          }
        }
      );

    this.shareFormMsgTypes.patchValue('PRODUCT');
  }

  ngOnChanges() {

    if(this.pageId) {
      this.getPageDetails(this.pageId);
    }

  }

  /**
   * Opens bottom sheet for description
   */
  openBottomSheet(description): void {

    this.information = {
      title: description,
      styleType: 'textContent'
    }

    this.bottomSheetService.openBottomSheet(this.information);
  }

  /**
   * Opens product description dialog
   */
  openProductDescriptionDialog() {
    this.isMobileDevice ? this.openBottomSheet(this.level2Details.description) : this.openPromotionalProductDescriptionDialog()
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  protected onDestroyed(): void { }

  /**
   * Calls after posting a share
   * @param payload
   * @param response
   */
  protected onPostCreated(payload: ProgramShareRequest, response: ProgramShareUpdate): void {

    this.postCreated.emit(response);
    this.createdPost = true;
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
