
<div id="kalgudi-share-update-form" class="p-0">
  <form autocomplete="off" [formGroup]="shareForm" name="shareForm">
    <div class=" px-3 pt-3 share-update-form" [ngClass]="{'pb-3': !showAdvanced}">

      <!-- Form field -->
      <div fxLayout="row" class="text-area-wrapper">
        <mat-form-field appearance="outline" class="update-form w-100">
          <textarea id="share-text" name="shareText" matInput [placeholder]="sharePlaceHolder | translate" [rows]="rowsCount" formControlName="shareText"></textarea>
        </mat-form-field>
      </div>

      <div *ngIf="isAppToDisplay && !reShareDetails">
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="6px" fxLayoutGap.xs="0px">
          <mat-form-field appearance="outline" class="update-form w-100" fxFlex="60%" fxFlex.xs="100%">
            <mat-select [placeholder]="'Select group' | translate" [disabled]="!shareFormText.value" (selectionChange)="selectPage($event)" formControlName="pageSelection">
              <mat-option *ngFor="let page of pageList"
              [title]="page?.pageTitle + ' - ' + (page?.createdBy?.firstName)" [value]="page">
                <div class="m-0 text-secondary">{{ page?.pageTitle | titlecase }}</div>
                <div class="m-0">
                  <small>Owner: <span class="text-success">{{ page?.createdBy?.firstName }}</span></small>
                </div>
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- Tags selection -->
          <mat-form-field appearance="outline" class="update-form w-100" fxFlex="40%" fxFlex.xs="100%">
            <mat-label translate>Select tag</mat-label>
            <mat-select panelClass="tag-selection" [disabled]="!enableTagSelection" placeholder="Select tag" formControlName="msgTypes" [required]="isAppToDisplay">
              <mat-option *ngFor="let tag of tagsList" [value]="tag?.id">
                {{ tag?.value | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- / Tags selection -->
        </div>

      </div>
      <!-- / Form field -->

      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url">

        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">

        </kl-post-tile-link-attachment>
      </div>
      <!-- / url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->

      <!-- Selected products -->
      <div class="my-2" *ngIf="selectedProducts?.value">
        <p class="text-secondary mt-2" *ngIf="isMobileDevice && selectedProducts?.value?.length">Suggested products</p>
        <kl-selected-store-products [selectedProducts]="selectedProducts.value" (remove)="removeProduct($event)"></kl-selected-store-products>
      </div>
      <!-- /Selected products -->

      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()" fxLayoutAlign="center center">
            <div class="audio-icon">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>
            <div class="ml-1">Voice message attached</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->

      <!-- Attachments and share buttons -->
      <div fxLayoutGap.xs="0px" fxLayout.xs="column">

          <!-- Attach files button -->
          <div>
            <button
              mat-button class="attach-files pl-0"
              id="attach-files-button"
              color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>attachment</mat-icon>
              <span class="ml-1" translate>Attach</span>
            </button>

            <mat-menu #menu="matMenu" class="attach-file">
              <ng-container>

                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Image</p>
                    </div>

                    <div fxLayout="row">
                      <div>
                        <kl-camera-form-control
                          fxFlex="95%"
                          [formControl]="cameraAttachment"
                          [showChipButton]="true"
                          [showIconButton]="true"
                          [showBorder]="false"
                          buttonIcon="camera_alt"
                          buttonLabel="Camera"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-camera-form-control>
                      </div>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="lstOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes"
                          [options]="options">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>

                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Voice</p>
                    </div>

                    <div fxLayout="row">
                      <button
                        fxFlex="95%"
                        id="attach-audio-button"
                        fxLayoutAlign="center center"
                        class="mt-1"
                        [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                        [matTooltip]="'Attach audio' | translate"
                        (click)="showAudioDialog()"
                        mat-icon-button>

                        <div class="label-icon" fxLayout fxLayoutAlign="center center">
                          <mat-icon class="m-0 camera-icon">mic</mat-icon>
                        </div>

                      </button>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control formControlName="lstOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedAudioFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>

                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Video</p>
                    </div>

                    <div fxLayout="row">
                      <div class="mt-1">
                        <kl-attach-url-form-control
                          fxFlex="100%"
                          [showBorder]="false"
                          [showChipButton]="false"
                          svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                          [formControl]="urlFormControl"
                          buttonType="mat-icon-button">
                        </kl-attach-url-form-control>
                      </div>
                      <div>
                        <span class="mx-2">|</span>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          formControlName="lstOfAttachments"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedVideoFileTypes">
                        </kl-image-picker-form-control>
                      </div>

                    </div>
                  </div>
                </div>


                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>File</p>
                    </div>

                    <div fxLayout="row">

                      <kl-image-picker-form-control
                        formControlName="lstOfAttachments"
                        [showBorder]="false"
                        [buttonType]="'mat-icon-button'"
                        svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                        [allowMultiple]="true"
                        [showAttachments]="false"
                        [s3Category]="s3Category"
                        [acceptedFileTypes]="acceptedFileTypes">
                      </kl-image-picker-form-control>
                    </div>
                  </div>
                </div>


                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                    <div>
                      <p class="m-0 title" translate>Product</p>
                    </div>

                    <div fxLayout="row">

                      <kl-store-products-form-control
                        buttonType="mat-icon-button"
                        buttonLabel="Product"
                        formControlName="productsMetaData">
                      </kl-store-products-form-control>
                    </div>
                  </div>
                </div>

              </ng-container>
            </mat-menu>
          </div>
          <!--/ Attach files button -->

        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex.lt-sm="flex-column" fxLayout.xs="column" fxLayoutAlign.lt-sm="flex-start flex-start" class="post-footer-section">
          <!-- Schedule date & time -->
          <div *ngIf="!reShareDetails" fxLayout="column" fxLayoutAlign="start end" class="mt-2">
            <div>
              <span fxLayout fxLayoutAlign="space-between" class="schedule" translate *ngIf="showScheduleTime">
                <a (click)="toggleSchedule()" translate>Close schedule</a>
              </span>
              <span class="schedule mt-2" translate *ngIf="!showScheduleTime">
                Do you want to schedule the post?
                <a (click)="toggleSchedule()" translate>Click here</a>
              </span>
            </div>
            <div *ngIf="showScheduleTime" id="schedule-update-wrapper" fxLayout fxLayout.xs="column" fxLayoutGap="15px"
              fxLayoutGap.xs="0" fxLayoutAlign="end center" fxLayoutAlign.xs="end">
              <div class="schedule-post-wrapper" fxLayout fxLayoutGap="12px" fxLayoutGap.xs="6px" fxLayoutAlign="center center"
                fxLayoutAlign.xs="start center">
                <span class="schedule" translate>Schedule</span>
                <div fxFlex="110px" fxFlex.xs="95px">
                  <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                    <mat-label translate>Choose date</mat-label>
                    <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" [min]="minDate"
                      formControlName="scheduledDate">
                    <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>
                    <mat-datepicker #scheduledDate></mat-datepicker>
                  </mat-form-field>
                </div>

                <div fxFlex="76px" fxFlex.xs="55px">
                  <kl-hour-selectable-form-control
                    [selectedDate]="scheduledDateField?.value"
                    [disabled]="!scheduledDateField?.value"
                    [placeholder]="'Time' | translate"
                    formControlName="scheduledTime">
                  </kl-hour-selectable-form-control>
                </div>
              </div>
              <span class="clear-btn cursor-pointer text-danger pl-2 pr-2 mb-1" *ngIf="scheduledDateField?.value"
                (click)="clearDate()" translate>Clear</span>

            </div>

          </div>
          <!--/ Schedule date & time -->

          <!-- Submit button -->
          <div [ngClass]="{'send-btn-container': !progress}" class="share-button" ngClass.lt-sm="w-100" fxLayout fxLayoutAlign="end end">
            <div [ngClass]="{'w-100 mt-2': isMobileDevice}">
              <kl-flat-button
                class="submit-btn"
                [label]="postDetails ? 'Update' : 'Submit'"
                [disabled]="(isAppToDisplay) ? ((!shareFormText.value && !audioAttachment?.value?.url) || (shareForm?.invalid) || (pageList && pageList.length ? !page : '')) : !shareFormText.value && !audioAttachment?.value?.url"
                [progress]="progress"
                (clicked)="createPost()">
              </kl-flat-button>
            </div>
          </div>
          <!--/ Submit button -->
        </div>

      </div>

      <!-- Attachments and share buttons -->

    </div>
  </form>
</div>

