import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiShareFilter } from '../../classes/share-filter';

@Component({
  selector: 'kl-share-target-members-filter',
  templateUrl: './share-target-members-filter.component.html',
  styleUrls: ['./share-target-members-filter.component.scss'],
  animations: kalgudiAnimations,

})
export class ShareTargetMembersFilterComponent extends KalgudiShareFilter implements OnInit {

  @Input()
  label: string;

  @Input()
  searchPlaceholder: string;

  constructor( ) {
    super();
  }

  ngOnInit() {
  }

}
