import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { AttachmentList, KalgudiImageDialogConfig } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KalgudiImagePickerFormControl } from '../../classes/kalgudi-image-picker-form-control';
import { KalgudiImagePickerService } from '../../services/kalgudi-image-picker.service';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImagePickerFormControlComponent),
  multi: true,
};


@Component({
  selector: 'kl-image-picker-form-control',
  templateUrl: './image-picker-form-control.component.html',
  styleUrls: ['./image-picker-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class ImagePickerFormControlComponent extends KalgudiImagePickerFormControl implements OnInit {

  @Input()
  buttonType: string;

  @Input()
  svgButtonIcon: string;

  @Input()
  showChipButton = false;

  @Input()
  showBorder: boolean;

  @Input() options:any;

  constructor(
    protected injector: Injector,
    private imagePickerService: KalgudiImagePickerService,
  ) {

    super(injector);
  }

  ngOnInit() { }

  onDestroyed() { }

  /**
   * Shows the picker dialog where we can pick the attachments
   */
  protected showPickerDialog(dialogDetails: KalgudiImageDialogConfig, dialogConfig: MatDialogConfig): Observable<AttachmentList> {

    return this.imagePickerService.showImageUpload(dialogDetails, dialogConfig, this.options)
      .pipe(
        // If dialog has been closed successfully
        filter(res => res && res.accepted),

        // Map the attachments list from the dialog
        map(res => res.data ? res.data.attachments as AttachmentList : []),
      );
  }
}
