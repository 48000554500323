import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { NotificationNetwork } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiNetworksApiService } from './kalgudi-networks-api.service';

@Injectable()
export class KalgudiNetworksService {

  constructor(
    private api: KalgudiNetworksApiService,
  ) { }


  /**
   * Posts a message to Api when a user visits another user profile
   *
   * @param profileKey Profile key of the user whom logged in user viewed
   */
  profileViewed(profileKey: string): Observable<NotificationNetwork> {

    return this.api.profileViewed(profileKey);
  }

  /**
   * Calls api to display .members who viewed profile
   */
  fetchNetworkList(offset: string, limit: string): Observable<KalgudiStreamData> {

    return this.api.fetchNetworkList(offset, limit);
  }

}
