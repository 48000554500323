import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProfileRouteConfig } from '../../../config';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { AddCropDialogComponent } from '../components/add-crop-dialog/add-crop-dialog.component';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiFarmerCrops extends KalgudiDestroyable {

  @Input()
  openCropDialog: string;

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  @Input()
  showViewCatalogue: boolean;

  @Input()
  showTasksLink: boolean;

  @Output()
  closeCropDialog = new EventEmitter();

  profile: KalgudiUser;

  products: any;
  editable: boolean;
  productsLength: number;

  progress: boolean;

  params: PartialData = {
    pageId: '',
    assistedTo: ''
  };

  private dialogsService: KalgudiDialogsService;
  private environment: KalgudiEnvironmentConfig;
  private profileState: KalgudiProfileStateService;
  private appRouting: KalgudiProfileRouteConfig;
  private profileService: KalgudiProfileService;
  private farmerProfileService: KalgudiFarmerProfileService;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {

    super();

    this.dialogsService       = this.injector.get(KalgudiDialogsService);
    this.environment          = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.profileState         = this.injector.get(KalgudiProfileStateService);
    this.appRouting           = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
    this.profileService       = this.injector.get(KalgudiProfileService);
    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.profile = res;

        this.params.assistedTo = this.profile.profileKey;

        this.getFarmerProducts();
      })

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
    );

    // Getting crop data from profile service to show crops for SAM FPO microsite
    setTimeout(() => {
      if(this.environment.appId === 'SAM_FPO' || this.environment.appId === 'FPO_APP' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'SHG_NET_APP') {
        this.getProductsList(this.profile?.dealsWith);
      }
    }, 500);
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Call api method to fetch crops
   */
  getFarmerProducts() {
    this.progress = true;

    const params = this.params.assistedTo ? this.params : {}

    this.farmerProfileService.getFarmerProducts(params)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onCropFetchingSuccess(res),
        err => this.onCropError(err)
      )
  }

  /**
   * Grouping the products
   */
  getProductsList(res: any) {

    if (res && res.length) {
      this.products = res.reduce((bp, p) =>
        {
          bp[p.baseProductId] = (bp[p.baseProductId] || []);

          if (p.baseProductId !== p.productId) {
            let variantCount = bp[p.baseProductId][0] && bp[p.baseProductId][0].variantCount ? bp[p.baseProductId][0].variantCount : 0;
            p.variantCount = variantCount + 1;
            bp[p.baseProductId].splice(0,0,p);
          } else {
            bp[p.baseProductId].push(p);
          }
          // console.log('p', p)
          // console.log('bp', bp)
          return bp;
        },
        {},
      );
    }
  }

  /**
   * Calls method to delete crop
   * @param product
   */
  deleteCrop(product: any) {

    this.notification.showSpinner();

    this.farmerProfileService.deleteCrop(this.profile.profileKey, product[0].productId)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.successHandler(res),
        err => this.errorHandler(err)
      )
  }

  /**
   * Shows add products dialog
   */
  showAddProductsDialog(): void {

    const pageId =this.pageId;
    const isAssisted = this.isAssisted;

    this.appRouting.toBaseProductListPage({profileKey: this.profile.profileKey}, {pageId, isAssisted});

    return;

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add crop',
      acceptButtonTitle: 'Add crop',
      rejectButtonTitle: 'Cancel',
      data: {
        showVariety: false,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-add-crop-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openAddProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        tap(_ => {
          this.closeCropDialog.emit();
        } ),

        filter(r => r.accepted),
      )
      .subscribe();
  }


  /**
   * Shows edit product dialog
   */
  editProductsDialog(product: any): Observable<KalgudiDialogResult> {


    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add crop',
      acceptButtonTitle: 'Add crop',
      rejectButtonTitle: 'Cancel',
      data: {
        showVariety: false,
        product
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-add-crop-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        product
      }
    };

    return this.openAddProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      );
  }

  /**
   * Success handler on deleting product from profile
   * @param res
   */
  private successHandler(res) {
    this.notification.showMessage('Deleted successfully');
    this.getFarmerProducts();
  }

  /**
   * Error handler on deleting product from profile
   * @param error
   */
  private errorHandler(error) {
    this.notification.showMessage(error.message || error.err.message || 'Unable to delete, Please try again later!');
  }

  /**
   * Navigate to catalogue page
   */
  navigateToCataloguePage() {

    if (this.isAssisted) {

      this.appRouting.toSellCatalogue({}, {pageId: this.pageId, assistedProfileKey: this.assistedProfileKey, firstName: this.profile.firstName})

    } else if(!this.editable) {
      this.appRouting.toSellCatalogue({}, {pageId: this.pageId, assistedProfileKey: this.profile.profileKey, firstName: this.profile.firstName, hideActions: true})

    } else {
      this.appRouting.toSellCatalogue();
    }


  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Shows the add products web or mobile dialog
   */
  private openAddProductsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(AddCropDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Event handler on fetching crop success.
   */
  protected onCropFetchingSuccess(res: any): void {}

  /**
   * Event handler on fetching crop errors.
   */
  protected onCropError(err: Error): void {
    this.notification.showMessage(err.message || 'Unable to fetch crops, Please try again later!');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
