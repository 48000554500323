export const KALGUDI_PROFILE_TYPES = {
  FARMER: 'PT000001',
  STUDENT: 'PT000002',
  EMPLOYEE: 'PT000003',
  I_RUN_A_BUSINESS: 'PT000004',
  ORGANIZATION: 'PT000005',
  I_WILL_TELL_LATER: 'PT000006',
};

export const KALGUDI_PROFILE_BUSINESS_TYPE_NAMES = {
  FARMER: 'Farmer',
  FPO: 'Producer Organizations (FPO)'
}

export const KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES = {
  PRODUCER_ORG: 'Producer Organizations'
}
