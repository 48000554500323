import { ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, Directive } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiLocation } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { GooglePlacesPrediction, GooglePlacesPredictionList, KalgudiGooglePlaceMap } from '../models/index';
import { ApiLoaderService } from '../services/api-loader.service';
import { KalgudiGooglePlacesApiService } from '../services/kalgudi-google-places-api.service';

/**
 *
 *
 * @usageNotes
 * #### To ensure the GooglePlacesAutocomplete can be used as a formControlName
 *
 * ```ts
 * Component({
 *  providers: [{
 *   provide: NG_VALUE_ACCESSOR,
 *   useExisting: forwardRef(() => YourComponent),
 *   multi: true,
 *  }]
 * })
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class GooglePlacesAutocomplete implements ControlValueAccessor, OnDestroy  {

  @ViewChild('autoCompleteInput', {static: true}) autoCompleteInput: ElementRef;

  @Input()
  placeholder: string;

  @Input()
  label: string;

  @Input()
  appearance: 'legacy' | 'standard' | 'fill' | 'outline' = 'outline';

  @Input()
  useKalgudiGoogleLocation = false;

  @Input()
  required = false;

  place: KalgudiLocation;

  @Output()
  googlePlaceChange = new EventEmitter<KalgudiGooglePlaceMap>();

  @Output()
  onLocationChange = new EventEmitter<any>();

  autoCompleteForm: FormGroup;
  predictions$: Observable<KalgudiLocation[]>;

  protected destroyed$: Observable<any>;

  private readonly destroyedSubject = new Subject();

  constructor(
    protected fb: FormBuilder,
    protected apiLoader: ApiLoaderService,
    protected googleApiService: KalgudiGooglePlacesApiService,
  ) {

    this.autoCompleteForm = this.newAutoCompleteFormGroup;
    this.destroyed$ = this.destroyedSubject.asObservable();
  }

  /**
   * Called once, before the instance is destroyed.
   * Internally it calls the onDestroyed() method.
   */
  ngOnDestroy(): void {
    this.destroyedSubject.next();
    this.destroyedSubject.complete();

    this.onDestroyed();
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      stateId: [''],
      stateName: [''],
      countryId: [''],
      countryName: [''],
      countryShortName: [''],
      latitude: [''],
      longitude: [''],
      locationLong: [''],
      locationShort: [''],
    });
  }

  /**
   * Gets, the auto complete googlePlace form field
   */
  get locationLongField(): AbstractControl {
    return this.autoCompleteForm.get('locationLong');
  }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.place = obj;

    this.updatePlaceDetailsForm();
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.locationLongField.disable() : this.locationLongField.enable();
  }

  /**
   * Initialize the google location picker api
   */
  init() {

    // Load all the necessary apis
    this.apiLoader
      .loadPlacesApi()
      .pipe(takeUntil(this.destroyed$))
      .subscribe();

    this.predictions$ = this.initQueryPrediction();
  }

  /**
   * Value mapper for the autocomplete, maps locationLong field from the
   * KalgudiLocation object.
   *
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="valueMapper">
   * ```
   */
  displayLocationLong(value: KalgudiLocation) {
    return value && value.locationLong ? value.locationLong : value;
  }

  /**
   * Fetches google place details based on the place id.
   */
  getPlaceDetails(placeId: string, placeDescription: string): void {

    this.googleApiService.getPlaceDescription(placeId, placeDescription)
      .subscribe(placeDetails => this.onPlaceDetailsUpdated(placeDetails));
  }

  /**
   * Update the place details form value with the latest place details
   */
  updatePlaceDetailsForm(): void {

    if (this.place) {
      this.autoCompleteForm.patchValue(this.place);
    }
  }

  /**
   * Event emit back to parent with selected location
   * @param location
   */
  locationChanged(location) {
    this.autoCompleteInput.nativeElement.blur();
    this.onLocationChange.emit(location.option.value)
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Implement this method to clean up resources
   */
  protected abstract onDestroyed(): void;

  /**
   * Event handler for google locations update
   */
  private onPlaceDetailsUpdated(placeDetails: KalgudiGooglePlaceMap) {

    this.place = placeDetails.kalgudiLocation;

    this.googlePlaceChange.emit(placeDetails);

    const place = this.useKalgudiGoogleLocation ? placeDetails.kalgudiGoogleLocationTo : placeDetails.kalgudiLocation;

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(place);
    this.onTouched();
  }

  /**
   * Initializes google places api query prediction Observable stream.
   */
  private initQueryPrediction(): Observable<KalgudiLocation[]> {

    return this.locationLongField
      .valueChanges
      .pipe(
        // Instantiate the stream with empty string
        startWith(''),

        // Filter empty strings
        filter(searchTerm => typeof searchTerm === 'string' && searchTerm.length > 0),

        // Trim extra spaces
        map(searchTerm => searchTerm.trim()),

        // Add debounce time for repeated key strokes. This will make less API.
        debounceTime(500),

        // Don't fall down if the search keyword is not changed
        distinctUntilChanged(),

        // Make an Api call and return the response to `prediction$`
        switchMap(searchTerm => this.googleApiService.getQueryPredictions(searchTerm)),

        // Map the GooglePlacesPredictionList to KalgudiLocation array
        map(predictions => this.mapGooglePlacesPredictionListToKalgudiLocation(predictions))
      );
  }

  /**
   * Maps a list of google places prediction result to KalgudiLocation type list.
   */
  private mapGooglePlacesPredictionListToKalgudiLocation(predictions: GooglePlacesPredictionList): KalgudiLocation[] {

    return predictions.map(prediction => this.mapGooglePlacesPredictionToKalgudiLocation(prediction));
  }

  /**
   * Maps google places prediction result to Kalgudi location type.
   *
   * @param prediction Google places prediction
   */
  private mapGooglePlacesPredictionToKalgudiLocation(prediction: GooglePlacesPrediction): KalgudiLocation {

    return {
      locationLong: prediction.description,
      placeId: prediction.placeId,
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
