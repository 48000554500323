<div class="kl-mobile-dialog-host"
  [@slideContent]="animationState"
  (@slideContent.start)="onAnimationStart($event)"
  (@slideContent.done)="onAnimationDone($event)">

  <mat-toolbar class="px-1" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">

    <button mat-icon-button (click)="closeDialog()" id="close-dialog-btn">
      <mat-icon class="back-button">keyboard_arrow_left</mat-icon>
      <span></span>
    </button>

    <!-- Title and description -->
    <div class="w-100">
      <div class="dialog-heading text-center" fxLayout="column" fxLayoutAlign="center center">
        <p class="m-0 dialog-title text-truncate" *ngIf="title"><strong>{{ title | translate }}</strong></p>
        <p class="mb-0 mt-1 dialog-desc text-truncate" *ngIf="dialogDescription">{{ dialogDescription | translate}}</p>
      </div>
    </div>
  </mat-toolbar>

  <section class="dialog-content overflow-auto">

    <!-- Dynamic dialog contents -->
    <ng-template klDynamicHost></ng-template>
  </section>
</div>
