<!-- Section for content -->
<div id="upload-image" class="text-center mt-2" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0"
  fxLayoutAlign.xs="start start">

  <!-- Add photo -->
  <div class="wrapper-img d-inline-block"*ngIf="maxImages > attachments?.length" [fxFlexOrder]="showAddOnLast ? '2' : '1'">
    <div class="wrapper-add w-100" fxLayout="row"
      fxLayoutAlign="center center">
      <div class="add-btn cursor-pointer position-relative w-100" fxLayout="column" fxLayoutAlign="center center"
        [ngClass]="{'w-100': !attachments?.length && !showCamera}">

        <ng-container *ngIf="!inProgress">
          <input class="overflow-hidden position-absolute attach-img cursor-pointer w-100"
            [ngClass]="{'show-camera': showCamera}"
            type="file" [accept]="accept" [multiple]="multiple" (change)="onFileSelection($event)">

          <ng-container *ngIf="!showCamera">
            <button mat-mini-fab class="btn btn-primary" color="primary" class="outline-none">
              <mat-icon class="add-icon text-white">attach_file</mat-icon>
            </button>

            <p class="mt-2" fxHide.xs translate>Click here to attach</p>
            <p class="mt-2" fxHide.gt-xs translate>Attach files</p>
          </ng-container>

          <div class="h-100" *ngIf="showCamera" fxLayout="column" fxLayoutAlign="center center">
            <div fxFlex="calc(50% - 1px)" class="h-100 attach_file" fxLayout fxLayoutAlign="center center">
              <mat-icon class="add-icon img-icon" color="primary">attach_file</mat-icon>

              <p class="mt-2" fxHide.xs translate>Click here to attach</p>
              <p class="m-0" fxHide.gt-xs translate>Gallery</p>
            </div>

            <div class="border-bottom my-border" fxFlex="1px"></div>

            <div fxFlex="calc(50% - 1px)" class="h-100 camera" fxLayout fxLayoutAlign="center center" (click)="showCameraDialog()">
              <mat-icon class="add-icon img-icon" color="primary">local_see</mat-icon>

              <p class="mt-2" fxHide.xs translate>Click here to capture</p>
              <p class="m-0" fxHide.gt-xs translate>Camera</p>
            </div>
          </div>
        </ng-container>


        <div fxLayout class="w-100" fxLayoutAlign="center center" *ngIf="inProgress">
          <kl-no-content [progress]="inProgress" progressText="Uploading..." iconSize="sm">
          </kl-no-content>
        </div>
      </div>
    </div>
  </div>
  <!-- / Add photo -->

  <!-- Wrapper for image section -->

  <div class="wrapper-img position-relative d-inline-block uploaded-image" *ngFor="let item of attachments; let i = index;" [fxFlexOrder]="showAddOnLast ? '1' : '2'">

    <button color="warn" class="clr-btn positioning position-absolute text-center" mat-mini-fab (click)="remove(i)">
      <mat-icon class="remove-icon text-white">close</mat-icon>
    </button>

    <div class="border image-textarea position-relative">

      <!-- Image attachment -->
      <div *ngIf="item?.msgType === attachmentTypes.IMAGE"
        class="w-100 h-100 image-textarea"
        [style.background-image]="'url('+ (item?.url | prefixDomain) +')'"></div>

      <!-- Document attachment -->
      <div class="doc-attach" *ngIf="item?.msgType === attachmentTypes.DOCUMENT" fxLayout="row" fxLayoutAlign="center center">
        <a [href]="item?.url | prefixDomain" target="_blank" [title]="'View file' | translate"><i class="fas fa-file-alt"></i></a>
      </div>

      <!-- Audio attachment -->
      <div class="audio-attach" *ngIf="item?.msgType === attachmentTypes.AUDIO" fxLayout="row" fxLayoutAlign="center center">
        <audio class="w-100 outline-none audio-attachment" controls="false" controlsList="nodownload">
          <source [src]="item?.url | prefixDomain" type="audio/mpeg">
        </audio>

      </div>

      <!-- Video attachment -->
      <div class="audio-attach" *ngIf="item?.msgType === attachmentTypes.VIDEO" fxLayout="row" fxLayoutAlign="center center">
        <video class="outline-none" width="100%" height="115" controls>
          <source [src]="item?.url | prefixDomain" type="video/mp4"/>
        </video>

      </div>

      <div class="text position-absolute w-100">
        <textarea class="text-center p-0 w-100 align-middle" [placeholder]="'Description' | translate" row="2"
          [(ngModel)]="item.context" [attr.maxLength]="env?.appId === 'SAM_FPO' && isTransactionPage ? 30 : null" ></textarea>
      </div>

    </div>

  </div>

  <!-- <div
    class="wrapper-add"
    fxLayout fxLayoutAlign="center center" *ngIf="inProgress" fxFlexOrder="3">
    <div class="add-btn px-3">
      <kl-no-content [progress]="inProgress" progressText="Uploading...">
      </kl-no-content>
    </div>
  </div> -->
  <!-- Wrapper for image section -->

</div>
<!-- /Section for content -->

