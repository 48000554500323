<main class="px-2 pt-1 share-update-header home-stream-header">
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="border-bottom pb-1"
  >
    <div
      class="header-name"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="mr-1" fxLayoutAlign="center center">
        <mat-icon class="updates">phone_callback</mat-icon>
      </div>
      <div class="header-title">
        <h3 class="mb-0" translate>Robocall</h3>
      </div>
    </div>
    <!-- <div
      [fxHide.xs]="!showMatMenu"
      *ngIf="isLoggedIn"
      (click)="$event.stopPropagation(); $event.preventDefault()"
    >
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="2px">

        <button
          mat-icon-button
          class="header-menu"
          *ngIf="
            msgType === 'SATELLITE_ADVISORIES' &&
            authorId === userDetails?.profileKey
          "
          (click)="
            refreshLatestAdvisory();
            $event.stopPropagation();
            $event.preventDefault()
          "
        >
          <mat-icon class="refresh-icon" title="Refresh Advisories"
            >refresh</mat-icon
          >
        </button>


        <button
          mat-icon-button
          fxLayoutAlign="end end"
          class="header-menu"
          [matMenuTriggerFor]="Menu"
        >
          <mat-icon class="menu"> more_horiz </mat-icon>
        </button>
      </div>
      <mat-menu #Menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
        <button
          mat-menu-item
          id="report-abuse"
          class="mb-0"
          *ngIf="
            entityId && (env.appId === 'KALGUDI_CORE' || env.appId === 'UBM')
          "
          (click)="reShare()"
          translate
        >
          Share from here
        </button>
        <button
          mat-menu-item
          id="report-abuse"
          class="mb-0"
          *ngIf="!scheduledUpdates"
          (click)="reportAbuse()"
          translate
        >
          Report abuse
        </button>
        <button
          mat-menu-item
          id="report-abuse"
          class="mb-0"
          *ngIf="
            msgType === 'AI_PREDICTIONS' && authorId === userDetails?.profileKey
          "
          (click)="sendSms()"
          translate
        >
          Send as sms
        </button>

        <button
          mat-menu-item
          id="delete"
          class="mb-0"
          *ngIf="
            (authorId === userDetails?.profileKey && !scheduledUpdates) ||
            memberRole === 'ADMIN'
          "
          (click)="deleteUpdate()"
          translate
        >
          Delete
        </button>
        <button
          mat-menu-item
          class="mb-0"
          *ngIf="scheduledUpdates"
          (click)="updatePost()"
          translate
        >
          Update
        </button>
      </mat-menu>
    </div>

    <div
      fxHide.gt-xs
      *ngIf="isLoggedIn && !showMatMenu"
      fxLayout="row"
      fxLayoutAlign="end end"
      fxLayoutGap="2px"
    >

      <button
        mat-icon-button
        class="header-menu"
        *ngIf="
          msgType === 'SATELLITE_ADVISORIES' &&
          authorId === userDetails?.profileKey
        "
        (click)="
          refreshLatestAdvisory();
          $event.stopPropagation();
          $event.preventDefault()
        "
      >
        <mat-icon class="refresh-icon" title="Refresh Advisories"
          >refresh</mat-icon
        >
      </button>


      <button
        mat-icon-button
        class="header-menu"
        (click)="
          openBottomSheet(); $event.stopPropagation(); $event.preventDefault()
        "
      >
        <mat-icon class="menu"> more_horiz </mat-icon>
      </button>

    </div> -->
  </div>
</main>
