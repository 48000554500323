import { Injector, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { SurveyPageActions, SurveyStateService } from '@kalgudi/surveys';
import { takeUntil } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';

@Directive()
export abstract class KalgudiPageSurveyManageAdmin extends KalgudiDestroyable {

  // Dependencies
  protected programState: ProgramStateService;
  protected surveyState: SurveyStateService;

  constructor(
    protected injector: Injector,
  ) {
    super();

    // Manually inject dependencies
    this.programState = this.injector.get(ProgramStateService);
    this.surveyState  = this.injector.get(SurveyStateService);

    // Subscribe to page state manager state changes
    this.subscribeToPageActions();
  }



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Subscribes to page state changes
   */
  private subscribeToPageActions(): void {

    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(action => {

        if (action.type === PageActions.PAGE_SURVEY_ADMINS_ADD) {
          this.surveyState.dispatchAction(SurveyPageActions.ADD_ADMIN);
        }
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
