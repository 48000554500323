import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-farmer-farming-activity-seasons',
  templateUrl: './farmer-farming-activity-seasons.component.html',
  styleUrls: ['./farmer-farming-activity-seasons.component.scss']
})
export class FarmerFarmingActivitySeasonsComponent implements OnInit {

  @Input()
  editable: boolean;

  @Input()
  seasonsList: any;

  @Output()
  onSeasonEditClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to edit season
   * @param season
   */
  editSeason(season: any) {
    this.onSeasonEditClick.emit(season);
  }

}
