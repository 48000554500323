import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class KalgudiUserPickerStateService {

  updateLocationNames$: Observable<any>
  private updateLocationNamesSubject$ = new BehaviorSubject<any>(null);

  updateCropNames$: Observable<any>
  private updateCropNamesSubject$ = new BehaviorSubject<any>(null);

  sendCropAndLocations$: Observable<any>
  private sendCropAndLocationsSubject$ = new BehaviorSubject<any>(null);

  constructor() {
    this.updateLocationNames$ = this.updateLocationNamesSubject$.pipe(
      shareReplay(1)
    )

    this.updateCropNames$ = this.updateCropNamesSubject$.pipe(
      shareReplay(1)
    )

    this.sendCropAndLocations$ = this.sendCropAndLocationsSubject$.pipe(
      shareReplay(1)
    )
  }
  /**
   * Updating locations
   */
  updateIsLocationNames(locationName?: any): void {
    this.updateLocationNamesSubject$.next(locationName);
  }

  /**
   * Updating crops
   */
  updateIsCropNames(cropName?: any): void {
    this.updateCropNamesSubject$.next(cropName);
  }


  updateCropAndLocationIds(cropAndLocationId: any): void {
    this.sendCropAndLocationsSubject$.next(cropAndLocationId);
  }
}
