import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { KalgudiCommonModule, KalgudiDialogsModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiChangePasswordModule } from '@kalgudi/user-settings';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppFooterComponent } from './components/app-footer/app-footer.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { HeaderMenuItemComponent } from './components/header-menu-item/header-menu-item.component';
import { HeaderMenuListComponent } from './components/header-menu-list/header-menu-list.component';
import { HeaderMenuTileComponent } from './components/header-menu-tile/header-menu-tile.component';
import { HeaderMenuComponent } from './components/header-menu/header-menu.component';
import { RouteLoadingProgressComponent } from './components/route-loading-progress/route-loading-progress.component';
import { SidebarImageComponent } from './components/sidebar-image/sidebar-image.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';



@NgModule({
  declarations: [
    AppHeaderComponent,
    AppFooterComponent,
    RouteLoadingProgressComponent,
    SidebarImageComponent,
    HeaderMenuListComponent,
    HeaderMenuItemComponent,
    HeaderMenuComponent,
    HeaderMenuTileComponent
  ],
  imports: [
    CommonModule,


    RouterModule,

    // Material components
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatProgressBarModule,
    MatCardModule,
    MatRadioModule,

    // Flex
    FlexLayoutModule,
    AngularSvgIconModule,

    TranslateModule,

    // Kalgudi Modules
    KalgudiDialogsModule,
    KalgudiChangePasswordModule,
    KalgudiPipesModule,
    KalgudiCommonModule
  ],
  exports: [
    AppHeaderComponent,
    AppFooterComponent,
    HeaderMenuComponent,
    HeaderMenuListComponent,
    HeaderMenuItemComponent,
    RouteLoadingProgressComponent,
    SidebarImageComponent,
    HeaderMenuTileComponent,
  ]
})
export class SharedModule { }
