import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableSearchStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject, SelectableUIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

import { PROJECT_TYPE_TITLE_MAP } from '../../../constants';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiProjectCreationService } from '../../project-creation/services/kalgudi-project-creation.service';
import { KalgudiProjectListService } from '../services/kalgudi-project-list.service';
import { KalgudiProjectsListActionsService } from '../services/kalgudi-projects-list-actions.service';

@Directive()
export abstract class ProjectsListTable extends KalgudiMatTableSearchStream<KalgudiProject>  {

  @Input()
  projectCategories: SelectableUIMenu[];

  readonly projectTypesMap = PROJECT_TYPE_TITLE_MAP;

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  projectsList: KalgudiProject[];

  private projectListActionService: KalgudiProjectsListActionsService;
  private projectListService: KalgudiProjectListService;
  private projectStateService: KalgudiProjectStateService;
  private notifications: KalgudiNotification;
  private projectCreationService: KalgudiProjectCreationService;
  private projectService: KalgudiProjectService;

  constructor(
    protected injector: Injector

    ) {

    super();

    this.projectListActionService =  this.injector.get<KalgudiProjectsListActionsService>(KalgudiProjectsListActionsService);
    this.projectListService       =  this.injector.get<KalgudiProjectListService>(KalgudiProjectListService);
    this.projectCreationService   = this.injector.get<KalgudiProjectCreationService>(KalgudiProjectCreationService);
    this.projectStateService      =  this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.notifications            =  this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.projectService           = this.injector.get<KalgudiProjectService>(KalgudiProjectService);

    // Override the page size options if you don't want default page size
    // this.pageSizeOptions = [1, 5, 10];

    // Override the list of columns to show
    this.displayedColumns = [
      'projectName',
      'category',
      'startDate',
      'members',
      'tasks',
      'status',
      'settings',
    ];

    this.projectStateService.reloadProjects$
      .pipe(
        takeUntil(this.destroyed$),

      )
      .subscribe(
        res => this.resetTable()
      );
  }

  deleteProject(projectId: string) {

    this.projectListActionService.deleteProject(projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res =>  this.projectDeleteHandler(res),
        err =>  this.projectDeleteErrHandler(err)
      );
  }

  /**
   * Opens the add members dialog
   */
  showAddMembersDialog(projectId: string) {

    this.projectListActionService.showAddMembersDialog(projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.projectStateService.reloadProjects();
        }
      );
  }

  /**
   * Open task creation dialog
   * @param projectId
   */
  openTaskCreationDialog(projectId: string ) {

    this.notifications.showSpinner(true);

    this.projectService.getProject(projectId)
      .pipe(

        // Hide the spinner
        finalize(() => this.notifications.hideSpinner()),

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        switchMap(_ => this.projectListActionService.openTaskCreationDialog(projectId))
      )
      .subscribe(
        (res) => {
          this.notifications.showMessage('Task created successfully');
          this.projectStateService.reloadTasks();
          this.updateProjectDetails(projectId);
          this.resetTable();
        },
        err => {
          this.notifications.showMessage('Unable to create task, please try again later!');
        }
      );
  }

  /**
   * Updates the project
   */
  showUpdateProject(projectId: string) {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories, projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.notifications.showMessage('Project updated successfully');
          this.projectStateService.reloadProjects();
          this.resetTable();
        }
      );
  }

  /**
   * Event handler for successful project deletion
   */
  protected projectDeleteHandler(res) {
    this.projectStateService.reloadProjects();
  }

  /**
   * Event handler for failed to delete project
   */
  protected projectDeleteErrHandler(err: Error) {
    this.notifications.showMessage('Failed to delete a project');
  }

  /**
   * Define the streamApi method to specify the data source of the table
   */
  protected streamApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    searchKeyword?: string): Observable<KalgudiStreamData> {

    if (searchKeyword) {
      return this.projectListService.searchProjects(searchKeyword, offset, limit);
    } else {

      return this.projectListService.getProjects(offset, limit);
    }
  }


  /**
   * Updates the project details with latest data
   */
  private updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }

}
