<!-- <div class="gps-geo-fence-bg" [ngClass]="{ 'animated': marking }"></div> -->

<div class="gps-geo-marker-wrapper" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px">

  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-raised-button
      (click)="marking ? stopMarking() : startMarking()"
      [color]="marking ? 'warn' : 'primary'">
      <mat-icon class="marking-icon" [ngClass]="{ 'marking': marking}">fiber_manual_record</mat-icon>
      <span>{{ marking ? 'Stop' : 'Start'}} Walking</span>
    </button>

    <span class="text-danger" *ngIf="!coordinates">
      Unable to access your location. Ensure GPS is turned on!
    </span>

    <button mat-button (click)="resetExistingLatLong()" [disabled]="marking || !fencedLatLongs?.length">Reset</button>
  </div>

  <!-- <div>
    <button class="fencing-btn" [ngClass]="{ 'start-btn': !marking, 'stop-btn pulse': marking }"
      (click)="marking ? stopMarking() : startMarking()">
      <span class="h4">{{ marking ? 'Stop' : 'Start' }}</span>
    </button>

    <p class="h6 text-center mt-5">
      <span *ngIf="!marking">Tap <strong>Start</strong> button to start fencing.</span>
      <span *ngIf="marking">
        <span>
          Walk around the perimeter of the field to record the fence coordinates.
          Tap
        </span><strong>Stop</strong> button to stop fencing.
      </span>
    </p>
  </div> -->
  <div class="geo-fencing position-relative">
    <kl-geo-location-marker [formControl]="geoFence" [location]="currentLocation" [showDot]="showDot"></kl-geo-location-marker>

    <div *ngIf="locationLabel" fxLayout fxLayoutAlign="end center" fxLayoutAlign.xs="start center">
      <p>{{locationLabel}}</p>
    </div>

    <div class="position-absolute h-100 w-100 loading-text" fxLayout fxLayoutAlign="center center" *ngIf="progress">

      <p class="text-white mb-0">Loading...</p>
    </div>
  </div>

  <!-- <div class="mt-3 text-center" *ngIf="marking">
    <span *ngIf="fencedLatLongs?.length > 0">
      Found {{ fencedLatLongs?.length }} coordinates
    </span>
  </div> -->

  <div class="area-fenced" *ngIf="!marking && fencedLatLongs?.length > 0 && false">
    <!-- <h2>
      <span>{{ areaFenced }} acres fenced</span>
    </h2> -->

    <div fxLayout="row" fxLayoutAlign="stretch center" fxLayoutGap="12px">
      <button mat-flat-button color="accent" fxFlex="100%" (click)="saveFenceDetails()">Save</button>
    </div>
  </div>
</div>
