<div class="crop-farming-activity">

  <mat-card fxLayout="row" class="p-2 mb-2 header-text position-relative" *ngIf="showInfoCard && editable">
    <div fxFlex="90%">
      <p class="m-0" translate>
        Create farm boundary & Add season details to get
        <span><b translate>accurate weather info & crop advisories.</b></span>
      </p>
    </div>

    <div class="position-absolute pointing-icon" fxLayoutAlign="end center">
      <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/pointing-arrow.svg"></svg-icon>
    </div>
  </mat-card>

  <div class='container'>
    <div class="on-scroll sticky-top">
      <mat-card class="add-activity mt-2" id="farm-activity-scroll"
        fxLayout="row" fxLayoutAlign="space-between center"
        fxLayout.xs="column" fxLayoutAlign.xs="center center">
        <div fxLayout="row" fxFlex="70%" [ngClass]="{'w-100': !editable || !isMobileDevice}">
          <div [ngClass]="{'w-100': !editable || !isMobileDevice}">
            <mat-form-field appearance="outline" class="w-100" [ngClass]="{'no-right-border': editable}">
              <mat-select [formControl]="activityFilter">
                <mat-option value="ALL"><span translate>All activities</span></mat-option>
                <mat-option [value]="item" *ngFor="let item of seasonsList">{{item?.title | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="editable">
            <button mat-button class="add-activity-button" (click)="openFarmActivityDialog()">
              <div>
                <p class="m-0" translate>Add activity</p>
              </div>
            </button>
          </div>
        </div>

        <div fxLayoutAlign="center center" [ngClass]="{'mt-2' : isMobileDevice}" (click)="openCropSettings()" *ngIf="editable">
          <p class="m-0"><a translate>Create season & Farm boundary</a></p>
        </div>

      </mat-card>
    </div>


    <div class="mt-2" fxLayout="column">

      <ng-container *ngIf="((stream$ | async) || true) as stream">
        <div class="w-100" *ngFor="let item of stream; let index=index;">

          <!-- Task submission form info tile -->
          <div *ngIf="item?.msgTypes && item?.msgTypes[0] === 'TASK' && item?.latestTaskSubmission">
            <kl-task-view-tile
              [taskSubmissionDetails]="item?.latestTaskSubmission"
              [animationDelay]="index * 1">
            </kl-task-view-tile>
          </div>
          <!--/ Task submission form info tile -->

          <kl-share-update-tile
            [postDetails]="item"
            [animationDelay]="index * 1">
          </kl-share-update-tile>
        </div>

        <div *ngIf="paginator$ | async as paginator">

          <ng-container *ngIf="!stream || stream?.length <= 0">
            <ng-container  *ngIf="!paginator?.loading">
              <kl-farmer-no-content
                [noContentText]="editable ? '' : 'No farming activities added yet!'"
                buttonTitle="Add Farming Activity"
                svgIcon="assets/svgs/farming-activity.svg"
                backgroundImage="assets/images/add-farm.png"
                noContentHeight="calc(100vh - 200px)"
                (action)="openFarmActivityDialog()">
              </kl-farmer-no-content>
            </ng-container>

            <ng-container *ngIf="paginator?.loading">
              <kl-no-content
                svgIcon="assets/svgs/updates.svg"
                iconSize="md"
                progressText="Loading farm activities..."
                [progress]="paginator?.loading">
              </kl-no-content>
            </ng-container>
          </ng-container>

          <!-- Load more -->
          <div class="mt-3" *ngIf="paginator?.loading || stream?.length">
            <kl-load-more
              noMoreText="No more projects"
              [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </div>
          <!-- /Load more -->
        </div>
      </ng-container>

      <!-- <kl-farmer-crop-farming-activities [pageId]="pageId"></kl-farmer-crop-farming-activities> -->

    </div>
  </div>


</div>
