<div id="profile-logs-list-table">

  <mat-card [@floatTop]="{ value: '', params: { delay: 0 }}" [ngClass]="{'d-none': !dataSource?.data?.length}" class="p-0 overflow-hidden">

    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--page column -->
      <ng-container matColumnDef="page">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Page </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout="row" class="w-100" fxLayoutGap="10px" fxLayoutAlign="start center">
            <!-- User profile pic -->
            <!-- <div class="rounded-circle">

              <img class="rounded-circle profile-pic" [src]="element?.pageDetails?.pageProfilePic | prefixDomain"/>
            </div> -->
            <!-- / User Profile pic -->

            <!-- Page Details -->
            <div class="page-details w-100">
              <span class="text-capitalize w-100 mb-0 text-truncate d-inline-block">
                {{ element?.pageDetails?.pageTitle }}
              </span>

              <!-- <span class="page-type mb-0 text-truncate mt-n4px page-type">
                {{ element?.pageDetails?.pageType }}
              </span> -->
            </div>
          </div>
        </td>
      </ng-container>
      <!--/ page column -->

      <!-- Log message column -->
      <ng-container matColumnDef="logMessage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Log </th>
        <td mat-cell class="text-truncate" *matCellDef="let element"> {{ element?.logDetails?.message }} </td>
      </ng-container>
      <!--/ Log message column -->

      <!-- Location column -->
      <!-- <ng-container matColumnDef="location">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Location </th>
        <td mat-cell *matCellDef="let element">
          {{ element?.member.location }}
        </td>
      </ng-container> -->
      <!--/ Location column -->

      <!-- Attachments column -->
      <ng-container matColumnDef="attachments">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Attachments </th>
        <td mat-cell *matCellDef="let element" class="py-2">
          <div fxFlex="35px" [ngClass]="{'left-margin': first}" fxLayout="row wrap" *ngFor="let attachments of element?.logDetails?.attachments | slice:0:2; let first = first" class="rounded-circle attachments cursor-pointer" (click)="openFullview(element?.logDetails?.attachments)">
            <img class="rounded-circle image-attachments" [src]="attachments?.url | prefixDomain"/>
          </div>
          <div fxFlex="35px" class="rounded-circle attachments cursor-pointer" *ngIf="element?.logDetails?.attachments?.length > 2" (click)="openFullview(element?.logDetails?.attachments)">
            <div class="rounded-circle image-attachments count-chip" fxLayoutAlign="center center" >
              <span>+</span>
              <span>{{element?.logDetails?.attachments?.length - 2}}</span>
            </div>
          </div>
        </td>
      </ng-container>
      <!--/ Attachments column -->

      <!-- Assistant details column -->
      <ng-container matColumnDef="assistant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Assistant </th>
        <td mat-cell class="text-truncate" *matCellDef="let element"> {{ element?.assistantDetails?.firstName }} </td>
      </ng-container>
      <!--/ Assistant details column -->

      <!-- Time stamp column -->
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Created on </th>
        <td mat-cell *matCellDef="let element"> {{ element?.CT | date: 'dd MMM yyyy, hh:mm a' }} </td>
      </ng-container>
      <!--/ Time stamp column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

    <!-- No content if there is no logs -->
    <ng-container *ngIf="!dataSource.data.length">
      <div class="no-content-center w-100" fxLayout="row" fxLayoutAlign="center center">
        <kl-no-content svgIcon="assets/svgs/profile-logs.svg"
          text="No profile logs list to show"
          iconSize="md"
          progressText="Loading your profile logs list..."
          [progress]="progress">
        </kl-no-content>
      </div>
    </ng-container>
    <!--/ No content if there is no logs -->

</div>
