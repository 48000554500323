<!-- User Task tile -->
<div class="current-tasks rounded my-2 p-3">

  <!-- Header section -->
  <div class="border-bottom pb-2 mb-2" fxLayoutAlign="space-between center">
    <span class="task-title" translate>Current Tasks</span>
    <span class="task-title schedule-btn rounded p-1" *ngIf="userCurrentTasks?.length" translate>Schedule Tasks</span>
  </div>
  <!--/ Header section -->

  <ng-container *ngFor="let tasks of filteredCurrentTasks | keyvalue">

    <!-- Task details section -->
    <div class="product">
      <div class="product-color font-weight-bold">{{tasks?.value[0].projectTitle}}</div>

      <ng-container *ngFor="let task of tasks?.value">
        <div class="product-tasks position-relative my-2 mr-2 cursor-pointer" (click)="openTaskFullview(task)">
          <span class="icon rounded-circle position-absolute mt-1" [ngClass]="{'not-started': task?.state === 'NOT_STARTED', 'progress': task?.state === 'IN_PROGRESS', 'completed': task?.state === 'COMPLETED'}"></span>
          <span class="product-tasks-type ml-4" fxLayout="column">
            <span fxLayoutAlign="space-between">
              <span class="main-task">{{task?.title}}</span>
              <span class="task-dates sub-task">{{ task?.timeFrame?.start | date: 'dd MMM, yy' }} - {{ task?.timeFrame?.end | date: 'dd MMM, yy'}}</span>
            </span>
            <span class="sub-task" translate>Task type : <a href="#" class="text-decoration-none"> {{ task?.templateRefTitle }}</a></span>
          </span>
        </div>

      </ng-container>
    </div>
    <!--/ Task details section -->

  </ng-container>

  <!-- Hint section -->
  <div fxLayoutAlign="end" fxLayoutGap="5px" class="task-hint" *ngIf="userCurrentTasks?.length">
    <span class="icon-hints progress rounded-circle"> </span>
    <span style="margin-right: 25px;" translate>Inprogress &nbsp;&nbsp;</span>
    <span class="icon-hints not-started rounded-circle"> </span>
    <span translate>Not started &nbsp;&nbsp;</span>
    <span class="icon-hints completed rounded-circle"> </span>
    <span translate>Completed</span>
  </div>
  <!-- /Hint section -->

  <ng-container *ngIf="!userCurrentTasks?.length">
    <p class="m-0 p-0 text-center text-muted" translate>
      There are no tasks to show
    </p>
  </ng-container>

</div>
<!--/ User Task tile -->
