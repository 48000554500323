<div id="kl-create-survey" class="h-100" fxLayout fxLayout.xs="column" fxLayoutGap="12px">

  <div class="position-relative mt-1" fxFlex="30%">
    <div class="survey-img-container" fxLayout fxLayoutAlign="center center">
      <!-- <img class="w-100 h-100" *ngIf="!surveyLogoUrlControl?.value" src="assets/images/survey-user.png"> -->
      <img class="w-100 h-100" *ngIf="surveyLogoUrlControl?.value" [src]="surveyLogoUrlControl?.value | prefixDomain">

      <svg-icon [applyCss]="true" *ngIf="!surveyLogoUrlControl?.value"
        src="assets/svgs/survey-user.svg"
        [svgStyle]="{ 'width': 150, 'height': 150 }" class="survey-logo"></svg-icon>

    </div>
    <span class="edit position-absolute" fxLayout="row" fxLayoutAlign="center center">
      <button class="mat-elevation-z1" mat-mini-fab>
        <mat-icon>edit</mat-icon>
      </button>
    </span>

    <input class="input-file position-absolute"
      type="file" matTooltip="Click to change profile picture"
      (change)="updateSurveyLogo($event)">
  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="space-between start">
    <form autocomplete="off" class="w-100" [formGroup]="surveyForm">
      <div>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Survey title</mat-label>
          <input  matInput required formControlName="title" [placeholder]="'Survey title' | translate" />
          <mat-error translate>Please enter survey title</mat-error>
        </mat-form-field>
      </div>


      <div >
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Description</mat-label>
          <textarea rows="1" required matInput formControlName="description" [placeholder]="'A brief description of survey' | translate"  ></textarea>
          <mat-error translate>Please write some description</mat-error>
        </mat-form-field>
      </div>

      <div class="w-100" fxLayout fxLayout.xs="column" fxLayoutGap="10px"  fxLayoutGap.xs="0">
        <div fxFlex [@floatTop]="{ value: '100', params: {delay: 250}}">
          <kl-google-places-autocomplete [label]="'Location/Place' | translate" placeholder="Location/Place" formControlName="location" required></kl-google-places-autocomplete>
        </div>

        <div fxFlex>
          <mat-form-field appearance="outline" class="w-100" >
            <mat-label translate>Expires on</mat-label>
            <input matInput
              [placeholder]="'Expires on' | translate"
              formControlName="expiryDate"
              [matDatepicker]="startDate"
              (click)="startDate.open()"
              readonly
              required
              [min]="surveyExpirationMinDate">
            <!-- <mat-error class="mt-3">Please mention start duration</mat-error> -->
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
            <mat-error translate>Please enter expiry date</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout fxLayoutAlign="space-between">
        <section>
          <!-- <mat-slide-toggle
              color="accent"
              formControlName="isResubmittable">
              Allow resubmission
          </mat-slide-toggle> -->
          <mat-checkbox formControlName="isResubmittable"><span translate>Allow resubmission</span></mat-checkbox>

        </section>
        <div class="submit-btn">
          <kl-flat-button
          [type]="'submit'"
          [label]="'Create'"
          [progress]="progress"
          [disabled]="!surveyForm.valid"
          (clicked)="create()">
        </kl-flat-button>
        </div>
      </div>


    </form>

  </div>
</div>
