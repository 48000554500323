<div class="farmer-crop-list h-100">
  <diV fxLayout.xs="column" fxLayout="row wrap" class="h-100">

    <mat-card fxFlex="100%" fxLayout="column" class="product-tile h-100">

      <!-- Crops header -->
      <kl-common-profile-header [title]="'Crops' | translate" svgIcon="assets/svgs/farm-crop.svg"
        [editable]="editable && products" actionIcon="edit" (action)="addProduct()">
      </kl-common-profile-header>
      <!-- /Crops header -->

      <!-- Crops -->
      <ng-container *ngIf="products">
        <div class="overflow-y py-2 max-height">
          <div class="mt-2" *ngFor="let product of products | keyvalue; let last = last">
            <section fxFlex="98%" [ngClass]="{'cursor-pointer': product?.value[0]?.pageId}">
              <div class="product-header mb-1" [ngClass]="{'mt-n2': editable}" fxLayout="row"
                fxLayoutAlign="space-between center">
                <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center"
                  [ngClass]="{'mt-n1': editable, 'mt-2 pt-1': !editable}">
                  <div *ngIf="product?.value[0]?.baseProductAttachments && product?.value[0]?.baseProductAttachments?.length && product?.value[0]?.baseProductAttachments[0]?.attachments && product?.value[0]?.baseProductAttachments[0]?.attachments?.length; else noImage">
                    <img [src]="product?.value[0]?.baseProductAttachments[0]?.attachments[0]?.url | prefixDomain"
                      class="product-image" [klErrorImages]="[(product?.value[0]?.baseProductAttachments[0]?.attachments[0]?.url) | prefixDomain, 'assets/images/plant.png']">
                  </div>

                  <ng-template #noImage>
                    <img [src]="'assets/images/plant.png'" class="default-product-image">
                  </ng-template>

                  <div fxLayout="column" fxLayoutAlign="center start">
                    <p class="m-0 product-name" fxLayoutAlign="center center">{{product?.value[0].baseProductName |
                      titlecase}}</p>

                    <div class="crop-border">
                      <div *ngIf="product?.value?.length" [ngClass]="{'no-base-product': !product?.value.length}"
                        fxLayout="row wrap" fxLayoutAlign="start center">
                        <ng-container *ngFor="let item of product?.value; let last = last; let i = index">
                          <p class="m-0 base-products" *ngIf="item?.productId !== product?.value[0].baseProductId">
                            <span>{{item?.productName}}</span>
                            <span
                              *ngIf="!last && product?.value[i + 1]?.baseProductId !== product?.value[i + 1].productId"
                              class="mx-1">|</span>
                          </p>
                        </ng-container>

                        <!-- <p class="m-0 base-products" *ngIf="product?.value?.length > 2">
                      <span class="ml-1">| + {{product?.value?.length - 2}}</span>
                    </p> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div fxLayout fxLayoutAlign="end end" *ngIf="editable && !isPublicPage">
                  <button mat-button class="add-season-button"
                    *ngIf="(!product?.value[0]?.latestSeasonInfo || product?.value[0]?.latestSeasonInfo?.length < 0) && product?.value[0]?.pageId"
                    (click)="opensFpoCropSeasonsFullview(product?.value[0]?.baseProductId, product?.value[0]?.baseProductName, product?.value[0]?.pageId, true)"
                    translate>
                    Add season</button>
                  <button mat-icon-button [matMenuTriggerFor]="menu" class="options mb-2"
                    (click)="$event.stopPropagation()">
                    <mat-icon>more_horiz</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md crop-menu " fxLayout="column">
                    <button class="edit-button p-0" mat-menu-item [matTooltip]="'Edit crop details' | translate"
                      (click)="editProduct(product?.value); closeMenu($event)">
                      <div fxLayout fxLayoutAlign="start center" class="ml-2">
                        <mat-icon class="edit rounded-circle text-secondary mr-2"
                          fxLayoutAlign="center center">edit</mat-icon>
                        <span class="text-secondary font-weight-normal text" translate>Edit</span>
                      </div>
                    </button>
                    <button class="edit-button p-0" mat-menu-item [matTooltip]="'Remove crop details' | translate"
                      (click)="deleteProduct(product?.value); closeMenu($event)">
                      <div fxLayout fxLayoutAlign="start center" class="ml-2">
                        <mat-icon class="edit rounded-circle text-secondary mr-2"
                          fxLayoutAlign="center center">delete</mat-icon>
                        <span class="text-secondary font-weight-normal text" translate>Delete</span>
                      </div>
                    </button>
                  </mat-menu>
                </div>

                <!-- <div fxFlex="10%" fxLayoutAlign="end end" *ngIf="product?.value[0]?.pageId">
              <button mat-icon-button class="mt-1 farm-arrow-button">
                <mat-icon class="arrow-icon">arrow_forward</mat-icon>

              </button>
            </div> -->
              </div>
              <div *ngIf="product?.value[0]?.latestSeasonInfo?.length > 0">
                <div *ngFor="let season of product?.value[0]?.latestSeasonInfo | slice: 0:2; let last = last"
                  class="p-1 season" [ngClass]="{'border-bottom': !last}">
                  <div fxFlex="33.3%" class="season-details">{{season?.crop?.productName}}</div>
                  <div fxFlex="33.3%" class="season-details">{{season?.title}}</div>
                  <div fxFlex="33.3%" class="season-details">{{season?.actualYield?.value}}
                    <span> {{season?.actualYield?.unit}}</span>
                  </div>
                </div>

                <div *ngIf="product?.value[0]?.latestSeasonInfo?.length > 0" class="show-all-button"
                  fxLayoutAlign="end end">
                  <button mat-button class="show-label"
                    (click)="opensFpoCropSeasonsFullview(product?.value[0]?.baseProductId, product?.value[0]?.baseProductName, product?.value[0]?.pageId)"
                    translate>Show all</button>
                </div>
              </div>

              <div *ngIf="!last" class="border-bottom pt-1 mb-2">
              </div>
            </section>

          </div>
        </div>
      </ng-container>
      <!--/ Crops -->

      <ng-container *ngIf="!products">
        <div class="w-100 no-content" fxLayoutAlign="center center">
          <kl-no-content iconSize="md"
            [text]="isPublicPage ? 'No crops added yet!' : editable ? '' : 'No crops added yet!' | translate"
            [actionLink]="isPublicPage ? '' : editable ? 'Add crops' : ''" (action)="addProduct()">
          </kl-no-content>
        </div>
      </ng-container>

      <div *ngIf="products && showViewCatalogue || env.appId !== 'SAM_FPO'" class="w-100" fxLayout
        fxLayoutAlign="end end">
        <ng-container *ngIf="!isPublicPage">
          <a class="view-stock" *ngIf="env.appId !== 'FPO_NET_APP'" (click)="navigateToCataloguePage()" translate>View
            stock catalogue</a>
        </ng-container>
      </div>
    </mat-card>
  </diV>
</div>