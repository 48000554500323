import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProfileMembershipsService } from './kalgudi-profile-memberships.service';
import { ProfileSectionMembership } from './profile-section-memberships';

@Component({
  selector: 'kl-memberships',
  templateUrl: './memberships.component.html',
  styleUrls: ['./memberships.component.scss']
})
export class MembershipsComponent extends ProfileSectionMembership implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileMembershipsService,
  ) {
    super(notification, app, util, api);
   }


  ngOnInit() {
  }

  protected onDestroyed(): void { }

}
