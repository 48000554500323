import { MapsAPILoader } from '@agm/core';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { checkMobileDevice, GeoLocationService } from '@kalgudi/core';
import { GooglePlacesKalgudiLocationMapService } from '@kalgudi/third-party/google-places';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DEFAULT_DIALOG_CONFIG } from '../kalgudi-dialogs/constants';
import { KalgudiDialogsService } from '../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../mobile-dialog';
import {
  GeoLocationMarkerDialogComponent,
} from './components/geo-location-marker-dialog/geo-location-marker-dialog.component';
import {
  GeoLocationMarkerMobileDialogComponent,
} from './components/geo-location-marker-mobile-dialog/geo-location-marker-mobile-dialog.component';
import { GpsGeoMarkerDialogComponent } from './components/gps-geo-marker-dialog/gps-geo-marker-dialog.component';
import {
  GpsGeoMarkerMobileDialogComponent,
} from './components/gps-geo-marker-mobile-dialog/gps-geo-marker-mobile-dialog.component';

@Injectable()
export class KalgudiGeoLocationMarkerService {

  constructor(
    private dialogsService: KalgudiDialogsService,
    private mapApiLoader: MapsAPILoader,
    private googlePlacesLocationMapService: GooglePlacesKalgudiLocationMapService,
    private geoLocationService: GeoLocationService
  ) { }

  openGpsGeoLocationMarkerDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openGpsGeoLocationMarkerMobileDialog(details)
      : this.openGpsGeoLocationMarkerWebDialog(details, config)
  }

  openGpsGeoLocationMarkerWebDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {
    return this.dialogsService.openDialog(GpsGeoMarkerDialogComponent, details, config);
  }

  openGpsGeoLocationMarkerMobileDialog(
    details: KalgudiDialogConfig,
    config?: MobileDialogConfig,
  ): Observable<KalgudiDialogResult> {
    return this.dialogsService.openMobileDialog(GpsGeoMarkerMobileDialogComponent, details);
  }


  /**
   * Shows Kalgudi users picker dialog box
   *
   * @param config Dialog
   */
  showKalgudiUsersPicker(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(GeoLocationMarkerDialogComponent, details, config);
  }

  /**
   * Opens program share update form for mobil view
   */
  showMobileUsersPicker(
    details: KalgudiDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(GeoLocationMarkerMobileDialogComponent, details);
  }

  getGeoCoords(): Observable<any> {

    return this.geoLocationService.geoCoords$;

  }


  /**
   * Search location on google
   */
   searchOnGoogle(searchFields) {
    return new Observable(observer => {
      setTimeout(() => {
        this.mapApiLoader && this.mapApiLoader.load().then(
          () => {
            if (typeof google !== 'object' || typeof google.maps !== 'object') {
              observer.error(`google map is blocked by the client browser`);
              return;
            }
            const geoCoder = new google.maps.Geocoder();
            let params: any = { region: 'IN' };
            params.location = {
              lat: searchFields.latitude || '',
              lng: searchFields.longitude || ''
            }

            geoCoder.geocode(params, (responseList, status) => {
              if (status === 'OK') {
                const response = responseList[0];

                const placeDetails = {
                  googlePlaceDetails: response,
                  ...this.googlePlacesLocationMapService.mapToKalgudiLocation(response)
                };

                observer.next(placeDetails);
              } else {
                observer.error(`Unable to find the location, please try alternate option`);
              }
              observer.complete();
            });
          });
      });

    })
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
