<div id="my-surveys-list" *ngIf="(mySurveys$ | async) as mySurveys">

  <!-- Surveys you are admin -->
  <div class="py-3"  *ngIf="mySurveys?.createdSurveys as createdSurveys">
    <ng-container *ngIf="createdSurveys?.length > 0">
      <div fxLayout="row" class="mb-2 px-3" fxLayoutAlign="space-between center">
        <div fxFlex="75%">
          <p class="mb-0 survey-list-name" translate>Surveys you are admin</p>
        </div>
        <div>
          <a (click)="openSurveyListPage()" translate>View all</a>
        </div>
      </div>

      <!-- Surveys list  -->
      <div fxLayout="row"  *ngFor="let survey of createdSurveys" class="py-2 list"
        (click)="openSurveyFullview(survey?.surveyId)">
        <div fxLayout="column" class="pl-3" fxFlex="95%">
          <span class="text-capitalize survey-title text-truncate mw-100">
            <a>{{ survey?.surveyBasicDetails?.title }}</a>
          </span>
        </div>
      </div>
      <!-- Surveys list  -->

    </ng-container>
  </div>
  <!-- /Surveys you are admin -->

  <!-- Separator -->
  <div class="separator"></div>
  <!-- /Separator -->

  <!-- Survey you follow -->
  <div>
    <div class="py-3" *ngIf="mySurveys?.followingSurveys as followingSurveys">

      <ng-container *ngIf="followingSurveys.length > 0">

        <div fxLayout="row" class="mb-2 px-3" fxLayoutAlign="space-between center">
          <div fxFlex="75%">
            <p class="mb-0 survey-list-name" translate>Surveys you are following</p>
          </div>
          <div>
            <a (click)="openSurveyListPage()" translate>View all</a>
          </div>
        </div>

        <!-- Surveys list  -->
        <div fxLayout="row" *ngFor="let survey of followingSurveys" class="py-2 list"
          (click)="openSurveyFullview(survey?.surveyId)">
          <div fxLayout="column" class="pl-3" fxFlex="95%">
            <span class="text-capitalize survey-title text-truncate mw-100">
              <a>{{ survey?.surveyBasicDetails?.title }}</a>
            </span>
          </div>
        </div>
        <!--/ Surveys list  -->

      </ng-container>

    </div>
  </div>
  <!--/ survey you follow-->

</div>

