import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

import { CommonSearchBarModule } from '../search/common-search-bar.module';
import { KalgudiMyProductsComponent } from './kalgudi-my-products/kalgudi-my-products.component';
import { ProductTileComponent } from './product-tile/product-tile.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { SearchKalgudiProductsComponent } from './search-kalgudi-products/search-kalgudi-products.component';



@NgModule({
  declarations: [
    KalgudiMyProductsComponent,
    SearchKalgudiProductsComponent,
    ProductsListComponent,
    ProductTileComponent,
  ],
  imports: [
    CommonModule,

    // Material module
    MatIconModule,

    FlexLayoutModule,

    CommonSearchBarModule
  ],
  exports: [
    KalgudiMyProductsComponent,
    SearchKalgudiProductsComponent
  ]
})
export class ProductPickerModule { }
