<!-- Image attachments -->
<kl-image-chip-list *ngIf="showAttachments"
  [attachments]="attachmentsList"
  (remove)="removeAttachment($event)">
</kl-image-chip-list>

<!-- / Image attachments -->

<button mat-button *ngIf="!showChipButton"
  id="attach-files-button"
  [ngClass]="{'mat-button': !buttonType, 'mat-icon-button': buttonType}"
  class="attach-files"
  color="primary"
  [matTooltip]=" buttonLabel ? buttonLabel : 'Attach Files' | translate"
  (click)="onAttachClicked($event);">
  <mat-icon *ngIf="buttonIcon && !svgButtonIcon">{{buttonIcon}}</mat-icon>
  <svg-icon *ngIf="svgButtonIcon" [applyCss]="true" [src]="svgButtonIcon" class="menu-icon"
    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
  <span *ngIf="!buttonType" class="ml-1" translate>{{buttonLabel}}</span>
</button>

<div *ngIf="showChipButton" id="image-picker-btn">
  <div fxLayout fxLayoutAlign="center center" class="" [ngClass]="{'button-chip mb-2 rounded-pill border': showBorder}">
    <button id="attach-files-button" class="" [ngClass]="{'rounded-pill': showBorder}" [matTooltip]="'Attach Files' | translate" type="button" mat-button  (click)="onAttachClicked($event)">
      <div class="icon-label" fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
        <mat-icon class="image-icon">{{buttonIcon}}</mat-icon>
        <span class="btn-label" translate>{{buttonLabel}}</span>
      </div>
    </button>
  </div>
</div>
