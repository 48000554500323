import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonSearchBarModule, KalgudiDialogsModule, KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { KalgudiChipsModule } from '@kalgudi/common/ui/chips';
import { KalgudiProgressStatsModule } from '@kalgudi/common/ui/progress-stats';
import { UsersTileModule } from '@kalgudi/common/ui/users-tile';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AddProjectMembersModule } from '../add-project-members/add-project-members.module';
import { KalgudiProjectCreationModule } from '../project-creation/project-creation.module';
import { KalgudiProjectSharedModule } from '../project-shared/project-shared.module';
import { TaskCreationModule } from '../task-creation/task-creation.module';
import { ProjectTileComponent } from './components/project-tile/project-tile.component';
import { ProjectsFiltersComponent } from './components/projects-filters/projects-filters.component';
import { ProjectsListGridComponent } from './components/projects-list-grid/projects-list-grid.component';
import { ProjectsListHeaderComponent } from './components/projects-list-header/projects-list-header.component';
import { ProjectsListTableComponent } from './components/projects-list-table/projects-list-table.component';
import { KalgudiProjectListApiService } from './services/kalgudi-project-list-api.service';
import { KalgudiProjectListService } from './services/kalgudi-project-list.service';
import { KalgudiProjectsListActionsService } from './services/kalgudi-projects-list-actions.service';



@NgModule({
  declarations: [
    ProjectsListHeaderComponent,
    ProjectsListGridComponent,
    ProjectsListTableComponent,
    ProjectTileComponent,
    ProjectsFiltersComponent,
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatMenuModule,
    MatDialogModule,
    MatTooltipModule,
    MomentDateModule,

    TranslateModule,

    // Kalgudi modules
    CommonSearchBarModule,
    KalgudiBreadcrumbsModule,
    KalgudiProjectSharedModule,
    KalgudiProgressStatsModule,
    KalgudiNoContentModule,
    KalgudiPipesModule,
    KalgudiProjectCreationModule,
    KalgudiChipsModule,
    KalgudiLoadMoreModule,
    UsersTileModule,
    KalgudiDialogsModule,
    TaskCreationModule,
    // KalgudiCommonModule,

    AddProjectMembersModule,

    FlexLayoutModule,
    AngularSvgIconModule,

  ],
  exports: [
    ProjectsListHeaderComponent,
    ProjectsListGridComponent,
    ProjectsListTableComponent,
    ProjectTileComponent,
    ProjectsFiltersComponent,
  ],
  providers: [
    KalgudiProjectListService,
    KalgudiProjectListApiService,
    KalgudiProjectsListActionsService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]
})
export class KalgudiProjectListModule { }
