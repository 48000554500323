<div id="kalgudi-share-update-form" class="p-0" *ngIf="shareForm">
  <form autocomplete="off" [formGroup]="shareForm">
    <div class="pb-1 px-3 pt-3 program-share-form">

      <!-- Form field -->
      <div class="text-area-wrapper">
        <mat-form-field appearance="outline" class="w-100 mb-2">
          <textarea matInput class="input-field" [placeholder]="sharePlaceHolder | translate" [rows]="rowsCount" formControlName="shareText"></textarea>
        </mat-form-field>
      </div>
      <!-- / Form field -->

      <!-- Share filters -->
      <div class="mb-2">
        <kl-page-activity-filters
          [pageId]="pageId"
          [shareForm]="shareForm"
          (shareWith)="shareWithChanged($event)">
        </kl-page-activity-filters>
      </div>
      <!-- / Share filters -->

      <!-- Gender -->
      <div class="mt-4" fxLayout fxLayoutAlign="start center"  [formGroup]="shareFormFilter" *ngIf="(env?.appId === 'SAM_FARMER' || env.appId === 'SAM_FPO') && (shareWithValue !== 'SPECIFIC_KALGUDI_USER')">
        <div class="mt-n1">
          <span class="mr-1" translate>Gender: </span>
        </div>

        <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
          <mat-radio-button value="" class="mr-4">{{ "All" | translate}}</mat-radio-button>
          <mat-radio-button value="MALE" class="mr-4">{{ "Male" | translate}}</mat-radio-button>
          <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
      <!--/ Gender -->

      <!-- Ai predictions filters -->
      <!-- <div *ngIf="aiPredictionsShare">
        <kl-page-ai-predictions-filters [pageId]="pageId" [shareForm]="shareForm"></kl-page-ai-predictions-filters>
      </div> -->
      <!--/ Ai predictions filters -->

      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url" class="mb-3">
        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">

        </kl-post-tile-link-attachment>
      </div>

      <!-- / url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list
        [attachments]="shareFormAttachments?.value"
        (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->



      <!-- Selected products -->
      <div class="my-2" *ngIf="selectedProducts?.value">
        <p class="text-secondary mt-2" *ngIf="isMobileDevice && selectedProducts?.value?.length">Suggested products</p>
        <kl-selected-store-products [selectedProducts]="selectedProducts.value" (remove)="removeProduct($event)"></kl-selected-store-products>
      </div>
      <!-- /Selected products -->

      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()">
            <div class="audio-icon">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>
            <div class="ml-1">Voice message attached</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->


      <!-- Action buttons -->
      <div fxLayout="row wrap" *ngIf="false" fxLayout.sm="row wrap" fxLayout.xs="row wrap" fxLayoutAlign="space-between center">

        <!-- Attachments buttons -->
        <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center" fxFlex>
          <div class="icons w-100" fxLayout="row wrap" fxLayoutAlign="start center">

            <!-- <button class="" title="Attach image" type="button" mat-icon-button fxLayoutAlign="center center" [disabled]="shareForm?.disabled || doctype === attachmentType?.DOCUMENT"
              (click)="attachImage(shareFormAttachments?.value)">
              <mat-icon class="image" color="success">photo</mat-icon>
            </button>

            <button title="Attach image" type="button" mat-icon-button fxLayoutAlign="center center"
              [disabled]="shareForm?.disabled || doctype === attachmentType?.IMAGE" (click)="onSendDocClick(shareFormAttachments?.value)">
              <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/document.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            </button> -->

            <!-- <p class="text-secondary mt-2 w-100" *ngIf="isMobileDevice">Attach</p> -->

            <section class="w-100 web-attachments bg-white" fxLayout="row wrap" fxFlex="100%" fxLayoutGap.xs="0px" [fxLayoutAlign]="!isMobileDevice ? 'center center' : 'start center'" >

              <div fxFlex fxLayout class="attach-btn-flex">
                <kl-camera-form-control
                  fxFlex="95%"
                  [formControl]="cameraAttachment"
                  [showChipButton]="true"
                  [showBorder]="false"
                  buttonIcon="camera_alt"
                  buttonLabel="Camera"
                  [allowMultiple]="true"
                  [showAttachments]="false"
                  [s3Category]="s3Category"
                  [acceptedFileTypes]="acceptedFileTypes">
                </kl-camera-form-control>

                <div fxFlex="5%" class="separator-pipe" fxLayout fxLayoutAlign="center center">
                  <span class="text-success">|</span>
                </div>
              </div>


              <div fxFlex fxLayout class="attach-btn-flex">
                <kl-image-picker-form-control
                  fxFlex="95%"
                  formControlName="lstOfAttachments"
                  [showChipButton]="true"
                  [showBorder]="false"
                  buttonIcon="photo"
                  buttonLabel="Gallery"
                  [allowMultiple]="true"
                  [showAttachments]="false"
                  [s3Category]="s3Category"
                  [acceptedFileTypes]="acceptedFileTypes">
                </kl-image-picker-form-control>

                <div fxFlex="5%" class="separator-pipe" fxLayout fxLayoutAlign="center center">
                  <span class="text-success">|</span>
                </div>
              </div>

              <!-- <div>
                <button mat-button
                  id="attach-audio-button"
                  mat-icon-button
                  class="attach-audio"
                  color="primary"
                  [matTooltip]="'Attach audio' | translate"
                  (click)="showAudioDialog()">
                  <mat-icon>settings_voice</mat-icon>
                </button>
              </div> -->

              <div fxFlex fxLayout class="attach-btn-flex" fxLayoutAlign="center center" [ngClass]="{'button-chip mb-2 rounded-pill border': false}">
                <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                  class="" [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                  [matTooltip]="'Attach audio' | translate"
                  (click)="showAudioDialog()"
                  mat-button>
                  <div class="label-icon" fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
                    <mat-icon class="mic-icon">volume_up</mat-icon>
                    <span class="audio-label">Audio</span>
                  </div>
                </button>

                <div fxFlex="5%" class="separator-pipe" fxLayout fxLayoutAlign="center center">
                  <span class="text-success">|</span>
                </div>
              </div>

              <div fxFlex fxLayout class="attach-btn-flex">
                <kl-attach-url-form-control
                  fxFlex="95%"
                  [showChipButton]="true"
                  [showBorder]="false"
                  svgIcon="assets/svgs/web-link.svg"
                  buttonLabel="Url"
                  [formControl]="urlFormControl"
                  buttonType="mat-icon-button">
                </kl-attach-url-form-control>

                <div fxFlex="5%" class="separator-pipe" fxLayout fxLayoutAlign="center center">
                  <span class="text-success">|</span>
                </div>
              </div>

              <div fxFlex fxLayout class="attach-btn-flex">
                <kl-attach-url-form-control
                  fxFlex="100%"
                  [showBorder]="false"
                  [showChipButton]="true"
                  svgIcon="assets/svgs/youtube.svg"
                  buttonLabel="Youtube"
                  [formControl]="urlFormControl"
                  buttonType="mat-icon-button">
                </kl-attach-url-form-control>

              </div>


            </section>
            <div class="my-2 w-100" [ngClass]="{'mb-2': isMobileDevice}">

              <a class="float-right" (click)="toggleAdvanced()" >Advanced</a>
              <!-- <button title="Attach image" type="button" mat-button fxLayoutAlign="center center">
                <span></span>
              </button> -->
            </div>


            <!-- <button title="Attach link" type="button" mat-icon-button fxLayoutAlign="center center"
              [disabled]="shareForm?.disabled" (click)="attachUrl()">
              <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/attach-link.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            </button> -->

          </div>
        </div>
        <!-- / Attachments buttons -->

        <!-- Attachments button for mobile view -->
        <!-- <div fxHide.gt-xs (click)="openMobileAttachmentsPickerMenu()">
          <button mat-mini-fab  class="add-btn-mobile" color="accent" >
            <mat-icon class="text-white">add</mat-icon>
          </button>
        </div> -->
        <!-- /Attachments button for mobile view -->

        <div  class="w-100 advanced-wrapper" fxLayout="row wrap" fxLayoutAlign="center center"  *ngIf="showAdvanced">
          <div fxLayout>
            <kl-store-products-form-control
              buttonLabel="Suggest product"
              [showChipButton]="true"
              [showBorder]="false"
              formControlName="productsMetaData"
              [storeType]="pageId === '0066' ? 'FARMSTORE' : null">
            </kl-store-products-form-control>

            <div class="separator-pipe ml-1 pr-1" fxLayout fxLayoutAlign="center center">
              <span class="text-success">|</span>
            </div>
          </div>

          <div id="schedule-update-wrapper" class="" fxLayout fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="0" fxLayoutAlign="end center" fxLayoutAlign.xs="end">
            <div class="schedule-post-wrapper" fxLayout fxLayoutGap="12px" fxLayoutGap.xs="6px" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" *ngIf="showAdvanced">
              <span class="schedule" translate>Schedule</span>
              <div fxFlex="110px" fxFlex.xs="95px">

                <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                  <mat-label translate>Choose date</mat-label>
                  <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" formControlName="scheduledDate">
                  <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>
                  <mat-datepicker #scheduledDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div [ngClass]="{'mt-n1': isMobileDevice}" fxFlex="80px" fxFlex.xs="55px">
                <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value" [placeholder]="'Time' | translate" formControlName="scheduledTime"></kl-hour-selectable-form-control>
              </div>
            </div>


          </div>
        </div>

        <div fxLayout fxLayoutAlign="end center" [ngClass]="{'send-btn-container': !progress}" class="share-button mt-2 w-100">
          <div [ngClass]="{'w-100': isMobileDevice}">
            <kl-flat-button
              class="submit-btn"
              [label]="postDetails ? 'Update' : 'Share'"
              [disabled]="(!shareFormText.value && !audioAttachment?.value?.url) || shareForm?.invalid"
              [progress]="progress"
              (clicked)="createPost()">
            </kl-flat-button>

          </div>
          <!-- <div class="send-type" [ngClass]="{'disable-selection': !shareFormText.value}" *ngIf="!progress">
            <mat-select [formControl]="scheduleBtnClicked" (selectionChange)="onSendTypeSelectionChange($event)" [disabled]="!shareFormText.value">
              <mat-option value="schedule send">
                <span><mat-icon class="mr-1" color="primary">schedule</mat-icon> <span translate>Schedule send</span></span></mat-option>
            </mat-select>
          </div> -->
        </div>

      </div>
      <!-- / Action buttons -->

      <div class="mt-n1 schedule-h" fxLayoutGap="20px" fxLayoutGap.xs="0px"
        fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutAlign="space-between flex-end">

        <div fxLayout="row">
          <div fxFlex fxLayoutAlign="flex-start flex-end">
            <button mat-button
              class="attach-files"
              id="attach-files-button"
              color="primary"
              [matMenuTriggerFor]="menu">
              <mat-icon>attachment</mat-icon>
              <span class="ml-1" translate>Attach</span>
            </button>

            <mat-menu #menu="matMenu" class="attach-file">
              <ng-container>

                <div mat-menu-item class="attach-file-mat-menu" [matTooltip]="'Capture image' | translate">
                  <div fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title" translate>Image</p>
                    </div>

                    <div fxLayout="row">
                      <div>
                        <kl-camera-form-control
                          fxFlex="95%"
                          [formControl]="cameraAttachment"
                          [showChipButton]="true"
                          [showIconButton]="true"
                          [showBorder]="false"
                          buttonIcon="camera_alt"
                          buttonLabel="Camera"
                          [allowMultiple]="true"
                          [showAttachments]="false"
                          [s3Category]="s3Category"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-camera-form-control>
                      </div>
                    <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedImageFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                </div>
              </div>
            </div>

            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>Voice</p>
                </div>

                <div fxLayout="row">
                  <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                    class="mt-1" [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                    [matTooltip]="'Attach audio' | translate"
                    (click)="showAudioDialog()"
                    mat-icon-button>
                    <div class="label-icon" fxLayout fxLayoutAlign="center center">
                      <mat-icon class="m-0 camera-icon">mic</mat-icon>
                    </div>
                  </button>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedAudioFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                </div>
              </div>
            </div>


            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                <div>
                  <p class="m-0 title" translate>Video</p>
                </div>

                <div fxLayout="row">
                  <div class="mt-1">
                    <kl-attach-url-form-control
                      fxFlex="100%"
                      [showBorder]="false"
                      [showChipButton]="false"
                      svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                      [formControl]="urlFormControl"
                      buttonType="mat-icon-button">
                    </kl-attach-url-form-control>
                  </div>
                  <div>
                    <span class="mx-2">|</span>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="lstOfAttachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                      [allowMultiple]="true"
                      [showAttachments]="false"
                      [s3Category]="s3Category"
                      [acceptedFileTypes]="acceptedVideoFileTypes">
                    </kl-image-picker-form-control>
                  </div>

                </div>
              </div>
            </div>


            <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu-file">
                <div>
                  <p class="m-0 title" translate>File</p>
                </div>

                <div fxLayout="row">

                  <kl-image-picker-form-control
                    formControlName="lstOfAttachments"
                    [showBorder]="false"
                    [buttonType]="'mat-icon-button'"
                    svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                    [allowMultiple]="true"
                    [showAttachments]="false"
                    [s3Category]="s3Category"
                    [acceptedFileTypes]="acceptedFileTypes">
                  </kl-image-picker-form-control>
                </div>
              </div>
            </div>


            <!-- <div mat-menu-item class="attach-file-mat-menu">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                <div>
                  <p class="m-0 title">Product</p>
                </div>

                <div fxLayout="row">

                  <kl-store-products-form-control
                    buttonType="mat-icon-button"
                    buttonLabel="Product"
                    formControlName="productsMetaData">
                  </kl-store-products-form-control>
                </div>
              </div>
            </div> -->

          </ng-container>
        </mat-menu></div>

        <div class="d-flex flex-column justify-content-center">
          <div>
            <span fxLayout fxLayoutAlign="flex-end" class="schedule" translate *ngIf="showScheduleTime">
              <a (click)="toggleSchedule()" translate>Close schedule</a>
            </span>
            <span class="schedule mt-2" translate *ngIf="!showScheduleTime">
              Do you want to schedule the post?
              <a (click)="toggleSchedule()" translate>Click here</a>
            </span>
          </div>
          <div *ngIf="showScheduleTime" id="schedule-update-wrapper" class="" fxLayout fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="0" fxLayoutAlign="end center" fxLayoutAlign.xs="end">
            <div class="schedule-post-wrapper" fxLayout fxLayoutGap="12px" fxLayoutGap.xs="6px" fxLayoutAlign="center center" fxLayoutAlign.xs="start center">
              <span class="schedule" translate>Schedule</span>
              <div fxFlex="110px" fxFlex.xs="95px">
                <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                  <mat-label translate>Choose date</mat-label>
                  <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" [min]="minDate" formControlName="scheduledDate">
                  <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>
                  <mat-datepicker #scheduledDate></mat-datepicker>
                </mat-form-field>
              </div>

              <div fxFlex="76px" fxFlex.xs="55px">
                <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value" [disabled]="!scheduledDateField?.value" [placeholder]="'Time' | translate" formControlName="scheduledTime"></kl-hour-selectable-form-control>
              </div>
            </div>

            <span class="clear-btn cursor-pointer text-danger pl-2 mb-1" *ngIf="scheduledDateField?.value" (click)="clearDate()">Clear</span>

          </div>

        </div>

        </div>

        <div [ngClass]="{'send-btn-container': !progress}" class="share-button" fxLayout fxLayoutAlign="end end">
          <div [ngClass]="{'w-100 mt-2': isMobileDevice}">
            <kl-flat-button
              class="submit-btn"
              [label]="postDetails ? 'Update' : 'Submit'"
              [disabled]="(!shareFormText.value && !audioAttachment?.value?.url) || shareForm?.invalid"
              [progress]="progress"
              (clicked)="createPost()">
            </kl-flat-button>

          </div>
        </div>

      </div>



    </div>
  </form>
</div>
