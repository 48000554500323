import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageSurveysQuestionsStream } from '../../classes/page-surveys-questions-stream';

@Component({
  selector: 'kl-page-surveys-questions-stream',
  template: `<kl-survey-questions-stream></kl-survey-questions-stream>`,
  styles: [``]
})
export class PageSurveysQuestionsStreamComponent extends KalgudiPageSurveysQuestionsStream implements OnInit {

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() { }

  onDestroyed(): void {}
}
