import { EventEmitter, Inject, Injector, Input, Output, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiNgxFormlyMapperService } from '@kalgudi/third-party/ngx-formly';
import {
  Attachment,
  KalgudiCropCalendarProject,
  KalgudiImageDialogConfig,
  KalgudiProjectTask,
  ProjectType,
  TaskTemplateDetails,
} from '@kalgudi/types';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiTaskCreationService } from '../services/kalgudi-task-creation.service';



@Directive()
export abstract class KalgudiCommonTaskCreationForm extends KalgudiInboxStream<KalgudiProjectTask> {

  readonly projectTypes = ProjectType;

  @Input()
  projectId: string;

  @Input()
  projectDetails: KalgudiCropCalendarProject;

  @Input()
  taskForm: FormGroup;

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  templateSchema: TaskTemplateDetails;

  @Output()
  taskFormChange = new EventEmitter<FormGroup>();


  taskCommonForm: FormGroup;

  frequencyList = ['HOURLY', 'DAILY', 'WEEKLY', 'MONTHLY', 'QUATERLY', 'HALF_YEARLY', 'ANNUALY'];

  showDateForm = true;

  selectedTagsList = {
    INFO: false,
    INPUT: false,
    OUTPUT: false,
    // SERVICES: false
  }

  showInput = true;
  showOutput = true;
  showService = true;
  showInfo = true;

  // Formly common form fields
  isFormlyInitialized = false;
  creationTemplateFormlyFields: FormlyFieldConfig[];
  creationTemplateModel = {};
  creationTemplateForm = new FormGroup({});
  showProductNameField: boolean;



  // Dependencies
  protected formlyJsonschema: FormlyJsonschema;
  protected projectStateService: KalgudiProjectStateService;
  protected jsonToFormlyMapper: KalgudiNgxFormlyMapperService;
  private fb: FormBuilder;
  private imagePickerService: KalgudiImagePickerService;
  private taskCreationService: KalgudiTaskCreationService;
  protected env: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    this.fb                  = this.injector.get(FormBuilder);
    this.imagePickerService  = this.injector.get(KalgudiImagePickerService);
    this.formlyJsonschema    = this.injector.get(FormlyJsonschema);
    this.jsonToFormlyMapper  = this.injector.get(KalgudiNgxFormlyMapperService);
    this.projectStateService = this.injector.get(KalgudiProjectStateService);
    this.taskCreationService = this.injector.get(KalgudiTaskCreationService);
    this.env                 = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    this.taskCommonForm = this.commonForm;

    this.projectStateService.projectDetails$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(project => this.projectDetails = project as any);
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Attachments form Uri domain field.
   */
  get attachmentsField(): FormArray {
    return this.taskForm.get('attachments') as FormArray;
  }

  get taskTypeFormGroup(): FormGroup {
    return this.taskForm.get('type') as FormGroup;
  }

  get taskDependentOnCropStageValue(): boolean {
    return this.taskForm.get('isTaskDependentOnCropStage').value;
  }

  get repeatField(): boolean {
    return this.taskTypeFormGroup.get('repeat').value;
  }

  get startDateField(): AbstractControl {
    return this.taskForm.get('timeFrame.start');
  }

  get endDateField(): AbstractControl {
    return this.taskForm.get('timeFrame.end');
  }

  get cropStageDependencyField(): AbstractControl {
    return this.taskForm.get('cropStageDependency');
  }

  /**
   * project form attachments form array controls
   */
  get attachmentsControls(): FormArray {
    return this.taskForm.controls.attachments as FormArray;
  }

  private get creationTemplateDataField(): AbstractControl {
    return this.taskForm.get('creationTemplateData');
  }

  /**
   * Getter for tag names field
   */
  get tagsNamesField(): AbstractControl {

    return this.taskCommonForm.get('tagNames');
  }

  /**
   * Value changes for date
   */
  valueChangesForFromDate() {
    this.startDateField.valueChanges
      .pipe(
        finalize(() => this.destroyed$)
      ).subscribe(res => {
        let fromDate: Date = new Date(res);
        let toDate: Date = new Date(this.endDateField.value);
          if (toDate.getTime() < fromDate.getTime()) {
            this.endDateField.reset()
          }
      });
  }

  /**
   * common form group
   */
  private get commonForm(): FormGroup {

    return this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      attachments: this.fb.array([]),
      timeFrame: this.fb.group({
        start: ['', Validators.required],
        end: ['', Validators.required],
        duration: ''
      }),
      type: this.fb.group({
        repeat: [false],
        frequency: ['']
      }),
      // cropStage: [''],
      isTaskDependentOnCropStage: [false],
      cropStageDependency: [{}],
      tagNames: [],
      tags: this.fb.group({
        input: this.fb.array([this.newInput()]),
        output: this.fb.array([this.newOutput()]),
        info: this.fb.array([new FormControl('')]),
        // services: this.fb.array([this.newService()])
      })
      // NOTE: `creationTemplateData` field gets added dynamically by the method initFormlyCommonTaskForm()
    });
  }

  // Formly creation template form fields
  private get idealStartDate(): AbstractControl {
    return this.creationTemplateForm.get('time.idealStartDate');
  }

  private get idealEndDate(): AbstractControl {
    return this.creationTemplateForm.get('time.idealEndDate');
  }

  /**
   * Getter for tags field
   */
  get tagsField(): FormGroup {
    return this.taskCommonForm.get('tags') as FormGroup;
  }

  /**
   * Getter for input field as form array
   */
  get inputArray(): FormArray {
    return this.tagsField.get('input') as FormArray;
  }

  /**
   * Getter for output field as form array
   */
  get outputArray(): FormArray {
    return this.tagsField.get('output') as FormArray;
  }

  /**
   * Getter for services field as form array
   */
  // get serviceArray(): FormArray {
  //   return this.tagsField.get('services') as FormArray;
  // }

  /**
   * Getter for info field as form array
   */
  get infoArray(): FormArray {
    return this.tagsField.get('info') as FormArray;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------

  /**
   * Add an item to the input form array
   */
  addInput() {

    this.inputArray.value.forEach((input, i) => {
      if(!input.product && !input.cost && !input.needType && !input.quantityPerAcre.value && !input.quantityPerAcre.unit && !input.noOfHourPerAcre ) {
        this.inputArray.removeAt(i);
      }

      if(input.productName) {
        const product = {
          productName: input.productName,
          productId: ''
        }

        input.product = product;
      }
    });

    this.inputArray.push(this.newInput());
  }

  /**
   * Add an item to the output form array
   */
  addOutput() {

    this.outputArray.value.forEach((output, i) => {
      if(!output.product && !output.cost && !output.expectedYieldPerAcre.value && !output.expectedYieldPerAcre.unit) {
        this.outputArray.removeAt(i);
      }
    });

    this.outputArray.push(this.newOutput());
  }

  /**
   * Add an item to the services form array
   */
  addServices() {

    // this.serviceArray.value.forEach((service, i) => {
    //   if(!service.product && !service.cost && !service.noOfHourPerAcre) {
    //     this.serviceArray.removeAt(i);
    //   }
    // });

    // this.serviceArray.push(this.newService());
  }

  /**
   * Add an item to the info form array
   */
  addInfo(){
    this.infoArray.push(new FormControl(''));
  }

  /**
   * To remove the item from the selected index from input array
   * @param i
   */
  removeInput(i:number) {
    this.inputArray.removeAt(i);
  }

  /**
   * To remove the item from the selected index from output array
   * @param i
   */
  removeOutput(i:number) {
    this.outputArray.removeAt(i);
  }

  /**
   * To remove the item from the selected index from services array
   * @param i
   */
  removeService(i:number) {
    // this.serviceArray.removeAt(i);
  }

  /**
   * To remove the item from the selected index from info array
   * @param i
   */
  removeInfo(i:number) {
    this.infoArray.removeAt(i);
  }

  /**
   * Displays attach image dialog. It uploads the image and injects the latest
   * attached images to the task form.
   *
   * @param attachments List of existing attachments
   */
  attachImage(): void {

    this.showAttachImageDialog(this.attachmentsField.value)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Inject attached images to task form
        map(attachments => this.injectImagesToForm(attachments)),
      )
      .subscribe();
  }


  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    this.attachmentsControls.removeAt(index);
  }

  /**
   * Show product name field
   */
  showProductName() {
    this.showProductNameField = true;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------



  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.taskCreationService.getTasks(offset, limit, this.projectId)
      .pipe(
        tap(res => {
          res.items = res.items.map(item => ({ id: item.id, title: item.title}))
        })
      );

  }

  /**
   * Injects the attached images to the task creation form. It also invalidates
   * the task form for any errors.
   *
   * @param attachments Images list to attach
   *
   * @returns Updated share form.
   */
  protected injectImagesToForm(attachments: Attachment[]): FormGroup {
    // Reset previous value in attachments
    this.attachmentsControls.controls = [];

    // Inject attached images
    this.updateAttachments(attachments);

    // Check task form for any errors
    this.taskForm.updateValueAndValidity();

    // Return project form
    return this.taskForm;
  }

  /**
   * Initializes the page form
   */
  protected initTaskForm() {

    const form = this.taskCommonForm;

    form.patchValue(this.taskForm.value);

    this.taskForm = form;

    this.taskFormChange.emit(this.taskForm);

    if (this.projectId) {
      this.initStream();
    }
  }

  /**
   * Initializes formly common task form
   */
  protected initFormlyCommonTaskForm(): void {

    // Do nothing if already initialized
    if (this.isFormlyInitialized) {
      return;
    }

    this.creationTemplateFormlyFields = [
      this.formlyJsonschema.toFieldConfig(this.templateSchema.creationSchema, {
        map: (mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig => {

          mappedField = this.jsonToFormlyMapper.mapConfiguration(mappedField, mapSource);

          return mappedField;
        }
      })
    ];

    this.isFormlyInitialized = true;

    // Inject the creation template schema to the task form
    setTimeout(() => this.handleDynamicTemplateFormInjection(), 100);
  }

  /**
   * Injects dynamic form group to the `taskForm` and handles its subscription
   */
  private handleDynamicTemplateFormInjection(): void {

    // Patch the existing form values to the form
    if (this.taskDetails && this.taskDetails.taskId) {

      if (this.taskDetails.creationTemplateData) {
        this.creationTemplateForm.patchValue(this.taskDetails.creationTemplateData);
      }

      this.tagsNamesField.value.forEach(element => {
        this.selectedTagsList[element] = true;
      });

      if(this.taskDetails.tags && this.taskDetails.tags.input && this.taskDetails.tags.input.length) {
        this.inputArray.reset();

        this.taskDetails.tags.input.forEach((inputTag: any) => {

          this.inputArray.push(this.newInput(inputTag));
        })

        this.inputArray.value.forEach((input, i) => {
          if(!input.product && !input.cost && !input.needType && !input.quantityPerAcre.value && !input.quantityPerAcre.unit && !input.noOfHourPerAcre) {
            this.inputArray.removeAt(i);
          }
        });
      }

      // if(this.taskDetails.tags && this.taskDetails.tags.services && this.taskDetails.tags.services.length) {
      //   this.serviceArray.reset();

      //   this.taskDetails.tags.services.forEach((servicesTag: any) => {

      //     this.serviceArray.push(this.newService(servicesTag));
      //   })

      //   this.serviceArray.value.forEach((service, i) => {
      //     if(!service.product && !service.cost && !service.noOfHourPerAcre) {
      //       this.serviceArray.removeAt(i);
      //     }
      //   });
      // }

      if(this.taskDetails.tags && this.taskDetails.tags.info && this.taskDetails.tags.info.length) {
        this.infoArray.reset();

        this.taskDetails.tags.info.forEach((infoTag: any) => {

          this.infoArray.push(new FormControl(infoTag));
        })

        this.infoArray.value.forEach((info, i) => {
          if(!info) {
            this.infoArray.removeAt(i);
          }
        });
      }

      if(this.taskDetails.tags && this.taskDetails.tags.output && this.taskDetails.tags.output.length) {
        this.outputArray.reset();

        this.taskDetails.tags.output.forEach((outputTag: any) => {

          this.outputArray.push(this.newOutput(outputTag));
        })

        this.outputArray.value.forEach((output, i) => {
          if(!output.product && !output.cost && !output.expectedYieldPerAcre.value && !output.expectedYieldPerAcre.unit) {
            this.outputArray.removeAt(i);
          }
        });
      }

    }

    // Add formly field to task form
    this.taskForm.addControl('creationTemplateData', this.creationTemplateForm);

    // Handle changes in the creationTemplateForm
    this.handleCreationTemplateFormValueChanges();
  }

  /**
   * Handler for formly creation form value changes to patch the values to the
   * task form.
   */
  private handleCreationTemplateFormValueChanges(): void {

    // this.idealStartDate
    //   .valueChanges
    //   .pipe(
    //     takeUntil(this.destroyed$),

    //     // On start date change int he formly creation template form
    //     // Update the start date of task
    //     tap(value => this.startDateField.patchValue(value)),

    //     // Catch errors if any
    //     catchError(e => of(null)),
    //   )
    //   .subscribe();

    // this.idealEndDate
    //   .valueChanges
    //   .pipe(
    //     takeUntil(this.destroyed$),

    //     // On end date change int he formly creation template form
    //     // Update the end date of task
    //     tap(value => this.endDateField.patchValue(value)),

    //     // Catch errors if any
    //     catchError(e => of(null)),
    //   )
    //   .subscribe();

    if (this.idealStartDate) {
      this.startDateField.patchValue(this.idealStartDate.value);

      this.startDateField
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),

          // On end date change int he formly creation template form
          // Update the end date of task
          tap(value => this.idealStartDate.patchValue(value)),

          // Catch errors if any
          catchError(e => of(null)),
        )
        .subscribe();
    }

    if (this.idealEndDate) {
      this.endDateField.patchValue(this.idealEndDate.value);

      this.endDateField
        .valueChanges
        .pipe(
          takeUntil(this.destroyed$),

          // On end date change int he formly creation template form
          // Update the end date of task
          tap(value => this.idealEndDate.patchValue(value)),

          // Catch errors if any
          catchError(e => of(null)),
        )
        .subscribe();
    }
  }

  /**
   * Creates form group for every attachment and push it into the form array
   * @param attachments
   */
  private updateAttachments(attachments: Attachment[]) {

    attachments.forEach(attachment => {
      this.attachmentsField.push(this.fb.group({
        url: [attachment.url],
        context:  [attachment.context],
        msgType: [attachment.msgType]
      }));
    });

  }

  /**
   * New input formGroup
   * @returns
   */
  newInput(input?: any): FormGroup {
    return this.fb.group({
      product: [input && input.product && input.product.productId ? input.product : ''],
      productName: [input && input.product && !input.product.productId ? input.product.productName : ''],
      // dosesPerAcre: this.fb.group({
      //   value: [input && input.dosesPerAcre.value ? input.dosesPerAcre.value : 0],
      //   unit: [input && input.dosesPerAcre.unit ? input.dosesPerAcre.unit : '']
      // }),
      quantityPerAcre: this.fb.group({
        value: [input && input.quantityPerAcre.value ? input.quantityPerAcre.value : 0],
        unit: [input && input.quantityPerAcre.unit ? input.quantityPerAcre.unit : '']
      }),
      noOfAcres: [input && input.noOfAcres ? input.noOfAcres : 0],
      noOfHourPerAcre: [input && input.noOfHourPerAcre ? input.noOfHourPerAcre : 0],
      cost: [input && input.cost ? input.cost : 0],
      needType: [input && input.needType ? input.needType : ''],
      showProductNameField: [input && input.product && input.product.productName && input.productName && !input.product.productId ? true : false]
    })
  }

  /**
   * New output formGroup
   * @returns
   */
  newOutput(output?: any): FormGroup {
    return this.fb.group({
      product: [output && output.product ? output.product : ''],
      expectedYieldPerAcre: this.fb.group({
        value: [output && output.expectedYieldPerAcre.value ? output.expectedYieldPerAcre.value : 0],
        unit: [output && output.expectedYieldPerAcre.unit ? output.expectedYieldPerAcre.unit : '']
      }),
      cost: [output && output.cost ? output.cost : 0]
    })
  }

  /**
   * New service formGroup
   * @returns
   */
  newService(service?: any): FormGroup {
    return this.fb.group({
      product: [service && service.product ? service.product : ''],
      noOfHourPerAcre: [service && service.noOfHourPerAcre ? service.noOfHourPerAcre : 0],
      cost: [service && service.cost ? service.cost : 0]
    })
  }

  /**
   * Shows attach image dialog and returns the list of attachments.
   */
  private showAttachImageDialog(attachedImages: Attachment[]): Observable<Attachment[]> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiImageDialogConfig = {
      title: 'Upload images to the project',
      acceptButtonTitle: 'Attach',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: KALGUDI_S3_POLICY_MAP.DEFAULT,
        multiple: true,
        maxImages: 50,
        attachments: this.util.clone<Attachment[]>(attachedImages),
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.imagePickerService.showImageUpload(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(dialogRes => dialogRes.accepted),

        // Transform the partial data to Attachment type
        map(dialogRes => dialogRes.data.attachments as Attachment[])
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
