import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-add-program-members',
  templateUrl: './add-program-members.component.html',
  styleUrls: ['./add-program-members.component.scss']
})
export class AddProgramMembersComponent implements OnInit {

  @Output()
  selectedUsers = new EventEmitter<KalgudiUserBasicDetails[]>();

  @Output()
  closeMembersDialog = new EventEmitter();

  @Input()
  showExtraFields: boolean = false;

  @Output()
  onResetStream = new EventEmitter();

  @Input()
  pageId: string;

  activeTabIndex: number;

  readonly TABS = {
    SEARCH: { index: 0, title: 'Search & Add user' },
    BULK: { index: 1, title: 'Add bulk users' },
    NEW_USER: { index: 2, title: 'Create & Add new user' },
  };

  constructor() { }

  ngOnInit() {
    this.activeTabIndex = 0;
  }

  /**
   * Event emit back to parent with selected members
   */
  selectedUsersList(res: any) {
    this.selectedUsers.emit(res);
  }

  /**
   * Event emit back to parent to close dialog
   */
  closeDialog() {
    this.closeMembersDialog.emit();
  }

  resetStream() {
    this.onResetStream.emit()
  }
}
