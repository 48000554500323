import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class ProjectStateService {

  toggleFilterSideBarSubject = new Subject<string>();

  constructor() { }

  get filterSideBar$(): Observable<any> {

    return this.toggleFilterSideBarSubject;
  }

  updateFilterSideBarStatus(): void {

    this.toggleFilterSideBarSubject.next();
  }
}
