<main class="edit-profile-basic">
  <form autocomplete="off" [formGroup]="profileForm">
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Basic details -->
        <div formGroupName="basicDetails">
          <legend><b translate>Basic details</b></legend>

          <!-- Name -->
          <div fxLayout id="basic-profile-name" fxLayoutGap="10px">
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-label translate>Name</mat-label>
              <input matInput required formControlName="firstName">
            </mat-form-field>

            <!-- Business type -->
            <div class="business-type" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields w-100">
                <mat-label translate>Business type</mat-label>
                <input matInput required formControlName="businessTypeName" [readonly]="!isDigitalAssist">
              </mat-form-field>
            </div>
            <!--/ Business type -->
          </div>
          <!--/ Name -->

          <div fxLayout fxLayoutGap="10px">

            <!-- Date of birth -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="calc(33.3% - 10px)">
              <mat-label translate>Date of birth</mat-label>

              <input matInput [placeholder]="'Date of birth' | translate" formControlName="dateOfBirth"
                (click)="dateOfBirth.open()" [matDatepicker]="dateOfBirth" [max]="todaysDate" readonly />

              <mat-datepicker-toggle matSuffix [for]="dateOfBirth"></mat-datepicker-toggle>
              <mat-datepicker #dateOfBirth></mat-datepicker>
            </mat-form-field>
            <!--/ Date of birth -->

            <!-- Gender -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="calc(33.3% - 10px)">
              <mat-label translate>Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option *ngFor="let item of genderList" [value]="item?.id">{{item?.value | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
            <!--/ Gender -->

            <!-- Village name -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="calc(33.3% - 10px)" *ngIf="appId === 'SAM_FARMER'">
              <mat-label translate>Village name</mat-label>
              <input matInput formControlName="village">
            </mat-form-field>
            <!--/ Village name -->

          </div>

          <!-- Education -->
          <div fxLayout fxLayoutGap="10px">

            <!-- Father name -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="calc(50% - 10px)" *ngIf="appId === 'SAM_FARMER'">
              <mat-label translate>Father name</mat-label>
              <input matInput formControlName="fatherName">
            </mat-form-field>
            <!--/ Father name -->

            <!-- Highest education -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields w-100" fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-label translate>Select your highest education</mat-label>
              <mat-select formControlName="education">
                <mat-option *ngFor="let item of educationList" [value]="item">{{ item | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!--/ Highest education -->

          </div>
          <!--/ Education -->

          <div *ngIf="showAgeError && appId === 'KUBER'" class="mt-n3">
            <p class="m-0 err-msg">You need to be 18+ years to use our app</p>
          </div>

          <div fxLayout="row" fxLayoutGap="8px">
            <!-- About yourself -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" fxLayout="column" fxFlex="100%">
              <mat-label translate>About yourself</mat-label>
              <textarea matInput [placeholder]="'Tell us a brief about yourself' | translate" rows="2" formControlName="aboutMe">
              </textarea>
            </mat-form-field>
            <!--/ About yourself -->
          </div>

        </div>
        <!-- / Basic details -->


        <!-- Employee experience -->
        <ng-container
          *ngIf="profileDetails?.additionalBizDetails?.businessTypeId === businessTypes?.EMPLOYEE || appId === 'SAM_FARMER'">

          <mat-divider></mat-divider>

          <legend translate class="mt-2" *ngIf="appId !== 'SAM_FARMER'">Experience</legend>
          <legend translate class="mt-2" *ngIf="appId === 'SAM_FARMER'">Agri and allied experience</legend>
          <div class="mt-1" fxLayout="row wrap" fxLayoutGap="10px">

            <!-- Experience in years -->
            <div class="suffix-fields" fxLayout="column" fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)" formGroupName="employeeDetails">
              <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" formGroupName="experience">
                <mat-label translate>Experience</mat-label>
                <input matInput formControlName="years" min="0" max="999" type="number" (keypress)="onKeyPress($event, 'number')" [placeholder]="'Please enter your experience in years' | translate">
                <span matSuffix translate>years</span>
              </mat-form-field>
              <div class="mt-n3 mb-2">
                <mat-error translate *ngIf="employeeDetailsYear?.getError('max')">Years cannot go past 999</mat-error>
                <mat-error translate *ngIf="employeeDetailsYear?.getError('min')">Enter positive values</mat-error>
              </div>
            </div>
            <!--/ Experience in years -->

            <!-- Experience in months -->
            <div class="suffix-fields" fxLayout="column" fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)" formGroupName="employeeDetails">
              <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields" formGroupName="experience">
                <mat-label translate>Experience</mat-label>
                <input matInput formControlName="months" min="0" max="12" type="number" (keypress)="onKeyPress($event, 'number')" [placeholder]="'Please enter your experience in months' | translate">
                <span matSuffix translate>months</span>
              </mat-form-field>
              <div class="mt-n3 mb-2">
                <mat-error translate *ngIf="employeeDetailsMonth?.getError('max')">Months cannot go past 12</mat-error>
                <mat-error translate *ngIf="employeeDetailsMonth?.getError('min')">Enter positive values</mat-error>
              </div>
            </div>
            <!--/ Experience in months -->
          </div>
        </ng-container>
        <!-- / Employee experience -->

        <mat-divider></mat-divider>

        <!-- Contact -->
        <ng-container>
          <legend translate class="mt-2"><b translate>Alternate Contacts</b></legend>
          <div class="mb-1" fxLayout="row" fxLayout.xs="column" fxLayoutGap.xs="4px" fxLayoutGap="10px"
            formGroupName="basicDetails">
            <!-- Email id -->
            <div fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields">
                <mat-label translate>Email id</mat-label>
                <input matInput [placeholder]="'Enter email id' | translate" formControlName="altemailId"
                  (keydown.space)="$event.preventDefault()">
                </mat-form-field>
                <mat-error translate class="mt-n3 error-messages" *ngIf="basicEmailId?.hasError('pattern')">Please enter valid email id</mat-error>
            </div>
            <!--/ Email id -->

            <!-- Mobile number -->
            <div class="suffix-fields" fxLayout="column" fxFlex="calc(50% - 10px)">
              <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields">
                <mat-label translate>Mobile number</mat-label>
                <span matPrefix>{{profileDetails?.mobileCode}}</span>
                <input matInput [placeholder]="'Enter mobile no' | translate" maxlength="10"
                  formControlName="alternateMobileNo" (keydown.space)="$event.preventDefault()" (keypress)="onKeyPress($event)">
                </mat-form-field>
                <mat-error translate class="mt-n3 error-messages" *ngIf="basicAlternateMobileNo?.hasError('pattern')">Please enter valid mobile number</mat-error>
            </div>

            <!-- Mobile number -->
          </div>

          <!-- Use smartphone / whatsapp -->
          <div formGroupName="basicDetails" class="mt-n2" *ngIf="appId !== 'SAM_FARMER'">
            <mat-checkbox [disabled]="basicAlternateMobileNo?.invalid || !basicAlternateMobileNo?.value"
              formControlName="useWhatsapp"><span translate>Do you have whatsapp for this number?</span>
            </mat-checkbox>
          </div>
          <!--/ Use smartphone / whatsapp -->

          <div fxLayout fxLayoutGap="10px" *ngIf="appId === 'SAM_FARMER'" formGroupName="basicDetails">

            <!-- Have smartphone field -->
            <mat-form-field appearance="outline" fxLayout="column" fxFlex="calc(50% - 10px)" class="mat-form-field-sm basic-profile-fields">
              <mat-label translate>Do you have smartphone?</mat-label>
              <mat-select formControlName="hasSmartPhone">
                <mat-option *ngFor="let option of isSmartphoneAvailable" [value]="option?.value">
                  {{ option?.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--/ Have smartphone field -->

            <!-- Have whatsapp for this number field -->
            <mat-form-field appearance="outline" fxLayout="column" fxFlex="calc(50% - 10px)" class="mat-form-field-sm basic-profile-fields">
              <mat-label translate>Do you have whatsapp for this number?</mat-label>
              <mat-select formControlName="farmerWhatsApp">
                <mat-option *ngFor="let option of isWhatsappAvailable" [value]="option?.id">
                  {{ option?.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <!--/ Have whatsapp for this number field -->

          </div>

          <!-- Location -->
          <div>

            <mat-tab-group class="geo-fence-tabs" [(selectedIndex)]="activeTabIndex"
              (selectedTabChange)="displayGeotagDialog($event)">

              <!-- Enter location tab -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.ENTER_LOC.svgIcon"
                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                  </svg-icon>
                  <span class="title" translate>Enter Location</span>
                </ng-template>
                <div class="geo-fencing geo-tag" *ngIf="activeTabIndex === TABS.ENTER_LOC.index">
                  <div class="pt-3 pb-2 basic-location-field">
                    <div *ngIf="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set' && appId !== 'KUBER'">
                      <p class="location">
                        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName?.replace(',','') }}</span>
                        <span *ngIf="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName && profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong">,</span>
                        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong?.replace(',',' ') }}</span>
                        <span *ngIf="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.postalCode"> -
                          {{profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.postalCode}}
                        </span>
                      </p>
                    </div>

                    <div *ngIf="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set' && appId === 'KUBER'">
                      <p class="location" *ngIf="profileDetails?.prfCreatedDate !== profileDetails?.prfModifiedDate">
                        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName }}</span>
                        <span
                          *ngIf="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName && profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong">,
                        </span>
                        <span>{{ profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
                      </p>
                    </div>

                    <kl-google-places-autocomplete [useKalgudiGoogleLocation]="true" formControlName="location"
                      [placeholder]="profileDetails?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName === 'Location yet to be set' ? 'Location yet to be set' : 'Village / Town / City'">
                    </kl-google-places-autocomplete>
                  </div>
                </div>
              </mat-tab>
              <!-- / Enter location tab -->

              <!-- Geo tag your land tab -->
              <mat-tab>
                <ng-template mat-tab-label>
                  <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.CURRENT_LOC.svgIcon"
                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                  </svg-icon>
                  <span class="title" translate>Use current location</span>
                </ng-template>
                <div class="geo-fencing geo-tag pb-3" *ngIf="activeTabIndex === TABS.CURRENT_LOC.index">

                  <div class="mt-3 px-2" fxLayout="column" fxLayoutAlign="center center" *ngIf="!showLocation">
                    <p class="err-msg m-0" translate>Please enable location to use this feature.</p>
                    <p class="err-msg m-0" translate>Loading...</p>
                  </div>

                  <div fxLayout="row" fxLayoutAlign="start center" class="show-location mt-3" *ngIf="showLocation">
                    <div>
                      <mat-icon>location_on</mat-icon>
                    </div>
                    <p class="m-0">{{showLocation?.locality}}
                      <span>, {{showLocation?.adminLevel2}}</span>
                      <span>, {{showLocation?.adminLevel1}}</span>
                    </p>
                  </div>

                  <!-- <kl-geo-tag
                    [showError]="!currentPosition && !currentLocationProgress"
                    [locationLabel]="locationLabel"
                    [location]="currentLocation"
                    (geoTagChanged)="geoTagChanged($event)"
                    [LabelPositionTop]="true"
                    [markerDraggable]="false">
                  </kl-geo-tag> -->
                </div>
              </mat-tab>
              <!-- / Geo tag your land tab -->

              <!-- Select location tab -->
              <!-- <mat-tab>
                <ng-template mat-tab-label>
                  <svg-icon class="text-center mr-1" [applyCss]="true" [src]="TABS.SELECT_LOC.svgIcon"
                    [svgStyle]="{ 'width.px': 20, 'height.px': 20 }">
                  </svg-icon>
                  <span class="title">Select on <br fxHide.lg> map</span>
                </ng-template>
                <div class="geo-fencing geo-tag pb-3" *ngIf="activeTabIndex === TABS.SELECT_LOC.index">
                  <kl-geo-tag
                    [showError]="!currentPosition && !currentLocationProgress"
                    [locationLabel]="locationLabel"
                    [location]="currentLocation"
                    (geoTagChanged)="geoTagChanged($event)"
                    [LabelPositionTop]="true"
                    [markerDraggable]="true">
                  </kl-geo-tag>
                </div>
              </mat-tab> -->
              <!-- / Select location tab -->

            </mat-tab-group>

          </div>
          <!-- /Location -->
        </ng-container>
        <!-- / Contact -->

        <div fxLayout fxLayoutGap="10px" *ngIf="appId === 'SAM_FARMER'" formGroupName="personalDetails">

          <div fxLayout="column" fxFlex="calc(33.3% - 10px)">
            <!-- Aadhar card no. field -->
            <mat-form-field appearance="outline"  class="mat-form-field-sm basic-profile-fields">
              <mat-label translate>Aadhar card number</mat-label>
              <input matInput maxlength="12" minlength="12" pattern='^(?!0+$)[0-9]{12,12}$' formControlName="aadhar_kyc_number" (keydown.space)="$event.preventDefault()" (keypress)="onKeyPress($event, 'number')">
            </mat-form-field>
            <mat-error class="mt-n3 error-messages" *ngIf="aadharNumberField?.hasError('pattern')" translate>Please enter valid aadhar number</mat-error>
            <!--/ Aadhar card no. field -->
          </div>

          <!-- <mat-error class="mt-n2 mb-2" *ngIf="getPanKycNumber?.hasError('pattern')">Enter a valid PAN number</mat-error> -->

          <div fxLayout="column" fxFlex="calc(33.3% - 10px)">
            <!-- PAN card no. field -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields">
              <mat-label translate>PAN card number</mat-label>
              <input oninput="this.value = this.value.toUpperCase()" matInput maxlength="10" minlength="10" formControlName="pan_kyc_number" pattern="^([A-Z]){5}([0-9]){4}([A-Z]){1}$">
            </mat-form-field>
            <mat-error class="mt-n3 error-messages" *ngIf="panNumberField?.hasError('pattern')" translate>Please enter valid PAN number</mat-error>
            <!--/ PAN card no. field -->
          </div>

          <div fxLayout="column" fxFlex="calc(33.3% - 10px)">
            <!-- Govt ID field -->
            <mat-form-field appearance="outline" class="mat-form-field-sm basic-profile-fields">
              <mat-label translate>Govt unique Id</mat-label>
              <input matInput formControlName="UniqueID">
            </mat-form-field>
            <!--/ Govt ID field -->
          </div>

        </div>
      </div>

      <div *ngIf="appId === 'KUBER'">
        <div class="mb-2" fxLayout>
          <div class="terms-conditions">
            <mat-checkbox [formControl]="termsAndConditions">
            </mat-checkbox>
          </div>
          <div>
            <span>
              <span translate>&nbsp;I have read & agree to the</span>
              <a (click)="termsAndConditionDialog()" translate> terms of use</a>
            </span>
          </div>
        </div>
      </div>

      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">

        <kl-flat-button label="Cancel" (clicked)="cancelProfile()">
        </kl-flat-button>

        <kl-flat-button label="Update profile" [progress]="progress" (clicked)="updateProfile()"
          [disabled]="(appId === 'KUBER') ? (termsAndConditions.value !== true || !profileForm?.valid || showAgeError) : !profileForm?.valid">
        </kl-flat-button>
      </div>

    </div>

  </form>
</main>
