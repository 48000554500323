import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { KalgudiUserPickerStateService } from '@kalgudi/common';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUserBasicDetails, KalgudiUsersMap } from '@kalgudi/types';
import { tap } from 'rxjs/operators';
import { KalgudiPageMembersDialogService } from '../../services/kalgudi-page-members-dialog.service';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';

@Component({
  selector: 'kl-program-search-members',
  templateUrl: './program-search-members.component.html',
  styleUrls: ['./program-search-members.component.scss']
})
export class ProgramSearchMembersComponent implements OnInit {

  @Output()
  selectedUsersList = new EventEmitter<KalgudiUserBasicDetails[]>();

  @Output()
  closeDialog = new EventEmitter();

  @Output()
  onFollowersAdded = new EventEmitter();

  @Input()
  showExtraFields: boolean = false;

  @Input()
  pageId: string;

  showLocation: any[] = [];
  showCrop : any[] = [];
  cropAndLocation: any;

  selectedUsersMap: KalgudiUsersMap = {};
  profileKey: any;
  locationAndCropForm: any;

  get selectedUsers(): KalgudiUserBasicDetails[] {

    return Object.values(this.selectedUsersMap);
  }

  constructor(
    protected kalgudiUserPickerStateService:KalgudiUserPickerStateService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageMembersDialogService: KalgudiPageMembersDialogService,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification
  ) {
    // Getting locations
    this.kalgudiUserPickerStateService.updateLocationNames$
    .pipe(
      tap(res => {
        if (res) {
          this.showLocation = res;
        }
      })
    ).subscribe()

    // Getting crops
    this.kalgudiUserPickerStateService.updateCropNames$
    .pipe(
      tap(res =>
      {
        if (res) {
          this.showCrop = res;
        }
      })
    ).subscribe()

    // Getting crops and locations
    this.kalgudiUserPickerStateService.sendCropAndLocations$
    .pipe(
      tap(res => {
        if (res) {
          this.cropAndLocation = res;
        }
      })
    ).subscribe()
  }

  ngOnInit() {
  }

  /**
   * On selecting add members event emit back to parent with selected users
   */
  addMembers() {
    if (this.selectedUsers?.length) {
      this.selectedUsersList.emit(this.selectedUsers);
      // this.showExtraFields ? this.onSubmit() : '';
      // this.close();
    } if (this.showExtraFields && !this.selectedUsers.length) {
      this.onSubmit();
    }
  }

  /**
   * On selecting cancel event emit back to parent to close the dialog
   */
  close() {
    this.closeDialog.emit();
  }

  /**
   * On submit
   */
  onSubmit() {
    const role = 'FOLLOWER';
    let payload = this.preparePayload(this.cropAndLocation || []);

    this.kalgudiPageMembersDialogService.createLocationAndCrop(payload, this.pageId, role)
      .pipe(
        // finalize(() => (this.progress = !this.progress))
      )
      .subscribe(
        res => {
          this.notification.showMessage(res.info || 'Added location or crop successfully!');
          this.onFollowersAdded.emit();
          this.showLocation.length = 0;
          this.showCrop.length = 0;
        },
        err => {
          this.errorHandler(err);
        });
  }

  /**
   * Preparing payload
   * @param formValue
   * @returns
   */
  preparePayload(formValue) {
    this.profileKey = this.kalgudiApp.profileLocal.profileKey;
    let crops = [];
    if (formValue[0].crop.length) {
    formValue[0].crop.forEach(crop => {
      crops.push(crop);
    });
  }

    let locations = [];
    if (formValue[0].location.length) {
      formValue[0].location.forEach(location => {
        locations.push(location);
      });
    }

    let payload = [
      {
        profileKey: this.profileKey,
        locationIds: locations,
        cropIds: crops
      }
    ]

    if (!crops.length) {
      delete payload[0].cropIds
    }

    if (!locations.length) {
      delete payload[0].locationIds
    }
    return payload;
  }

  /**
   * Error notification for location or crop count
   */
  private errorHandler(err) {
    this.notification.showMessage(err.error.message || err.info || err.error || 'Unable to add crop and location, Please try again later!');
  }
}
