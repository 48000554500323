import { EventEmitter, Inject, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaService, KalgudiSocialQA } from '@kalgudi/qa';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { KalgudiPageDetails, ProgramQaFullDetails, ProgramQaRequest } from '@kalgudi/types';
import { timer } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';


@Directive()
export abstract class KalgudiProgramQa extends KalgudiSocialQA<ProgramQaRequest, ProgramQaFullDetails> {

  @Output()
  postCreated = new EventEmitter<ProgramQaFullDetails>();

  shareForm: FormGroup;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected uploadImageTos3: KalgudiUploadService,
    protected qaService: KalgudiQaService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3,
    );

    // Initialize QA form
    this.shareForm = this.programQaForm;

    // Patch program details to program form
    this.patchProgramDetailsToForm();

  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * QA common form
   */
  private get programQaForm(): FormGroup {

    return new FormGroup({

      // Common fields inherited from QA
      text: new FormControl('', Validators.required),
      groupName: new FormControl(this.DEFAULT_SHARE_VISIBILITY, Validators.required),
      imageURL: new FormControl(''),
      type: new FormControl('question'),
      questionType: new FormControl(this.DEFAULT_QUESTION_TYPE, Validators.required),

      lstOfAttachments: new FormControl([]),

      // Program specific fields
      entityId: new FormControl('', Validators.required),
      isAdmin: new FormControl(false, Validators.required),
      isAuthor: new FormControl(false, Validators.required),
      filter: new FormGroup({
        pageId: new FormControl(''),
        parentPageId: new FormControl(''),
        parentPageTitle: new FormControl(''),
        parentPageUrl: new FormControl(''),
      }),

    });
  }

  /**
   * Share form program entity id field
   */
  get shareFormEntityId(): AbstractControl {
    return this.shareForm.get('entityId');
  }


  /**
   * Share form program is admin field
   */
  get shareFormIsAdmin(): AbstractControl {
    return this.shareForm.get('isAdmin');
  }

  /**
   * Share form program is author field
   */
  get shareFormIsAuthor(): AbstractControl {
    return this.shareForm.get('isAuthor');
  }


  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * share form pageId field
   */
  get shareFormPageId(): AbstractControl {
    return this.shareFormFilter.get('pageId');
  }

  /**
   * Share form parent pageId field
   */
  get shareFormParentEntityId(): AbstractControl {
    return this.shareFormFilter.get('parentPageId');
  }

  /**
   * Share form parent page title field
   */
  get shareFormParentEntityName(): AbstractControl {
    return this.shareFormFilter.get('parentPageTitle');
  }

  /**
   * Share form parent page url
   */
  get shareFormParentPageUrl(): AbstractControl {
    return this.shareFormFilter.get('parentPageUrl');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------


  /**
   * Resets the program QA form
   *
   * @override
   */
  resetForm() {
    // Honour all parent actions
    // super.resetForm();

    this.shareForm = this.programQaForm;


    // Patch page details after resetting page form
    this.patchProgramDetailsToForm();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the program details and binding the details to he share form
   */
  private patchProgramDetailsToForm(): void {

    timer(1000)
      .pipe(
        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              first(),

              tap(r => this.patchProgramDetails(r))
            )
        )
      ).subscribe();
  }

  /**
   * Initializing the share form with the program details
   * @param pageDetails
   */
  private patchProgramDetails(pageDetails: KalgudiPageDetails): void {

    this.shareFormEntityId.patchValue(pageDetails.pageId);
    this.shareFormIsAdmin.patchValue(this.kalgudiPageService.isPageAdmin(pageDetails));
    this.shareFormIsAuthor.patchValue(this.kalgudiPageService.isPageAuthor(pageDetails));
    this.shareFormPageId.patchValue(pageDetails.pageId);
    this.shareFormParentEntityId.patchValue(pageDetails.pageId);
    this.shareFormParentEntityName.patchValue(pageDetails.pageTitle);
    this.shareFormParentPageUrl.patchValue(pageDetails.pageProfilePic);
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
