import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';
import { KalgudiSurveyDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss'],
  animations: [floatTopAnimation]
})
export class SurveyListComponent implements OnInit {

  @Input()
  surveyList: KalgudiSurveyDetails[];

  @Input()
  tileFxFlex: string = '50%';

  @Output()
  surveySelected = new EventEmitter<KalgudiSurveyDetails>();

  @Output()
  surveyDetailsAccepted = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Emits an event to the parent to open selected survey full view
   * @param selectedSurvey
   */
  onSurveySelection(selectedSurvey: KalgudiSurveyDetails): void {
    this.surveySelected.emit(selectedSurvey);
  }

  /**
   * Emits an event to the parent to accept the clone survey created
   */
  cloneSurveyDetailsAccepted(cloneSurveyAccepted: boolean) {
    this.surveyDetailsAccepted.emit(cloneSurveyAccepted);
  }
}
