<div class="kalgudi-core-theme live-stock-location overflow-hidden">

  <!-- Heading and close buttons -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>View location details</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Heading and close buttons -->

  <!-- kl-geo-location -->
  <div class="p-3">
    <kl-geo-location [LabelPositionTop]="true" [showLatLong]="true" [location]="data?.data?.locationDetails">
    </kl-geo-location>
    <!--/ kl-geo-location -->
  </div>
</div>