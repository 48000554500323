import { Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { KalgudiTranslationService } from '@kalgudi/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { WeatherFullview } from '../../classes/weather-fullview';

@Component({
  selector: 'kl-weather-fullview',
  templateUrl: './weather-fullview.component.html',
  styleUrls: ['./weather-fullview.component.scss']
})
export class WeatherFullviewComponent extends WeatherFullview implements OnInit, OnChanges {

  selectedLanguage: string;

  weatherForecast = [

    {
      icon: 'assets/icons/weather/snowy-5.svg',
      day: 'MON',
      degrees: '25°C'
    },
    {
      icon: 'assets/icons/weather/cloudy.svg',
      day: 'TUES',
      degrees: '20°C'
    },
    {
      icon: 'assets/icons/weather/day.svg',
      day: 'WED',
      degrees: '30°C'
    },
    {
      icon: 'assets/icons/weather/cloudy-day-1.svg',
      day: 'THU',
      degrees: '40°C'
    },
    {
      icon: 'assets/icons/weather/cloudy-day-2.svg',
      day: 'FRI',
      degrees: '50°C'
    },
    {
      icon: 'assets/icons/weather/cloudy-day-3.svg',
      day: 'SAT',
      degrees: '60°C'
    },
    {
      icon: 'assets/icons/weather/cloudy-night-1.svg',
      day: 'SUN',
      degrees: '70°C'
    },
  ];

  constructor(
    protected injector: Injector,
    private translate: TranslateService,
    protected translationService: KalgudiTranslationService
    ) {
    super(injector, translationService);

  }

  ngOnInit() {
    this.getFavoriteLocations();

    this.translate.onLangChange
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.getWeatherDetails(this.location.latitude, this.location.longitude, res.lang.toUpperCase());
        this.getWeatherDetailsByLocation(
          this.searchLocation.latitude,
          this.searchLocation.longitude,
          res.lang.toUpperCase()
        );
      } );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.location && this.location) {
      this.getWeatherDetails(this.location.latitude, this.location.longitude, this.translationService.activeLanguage.toUpperCase());
    }

  }

  onDestroyed(): void {}
}
