import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-cooperative-society-edit-dialog',
  templateUrl: './cooperative-society-edit-dialog.component.html',
  styleUrls: ['./cooperative-society-edit-dialog.component.scss']
})
export class CooperativeSocietyEditDialogComponent extends KalgudiDialog<CooperativeSocietyEditDialogComponent> implements OnInit {

  user: KalgudiUser;

  constructor(
    protected dialogRef: MatDialogRef<CooperativeSocietyEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);
  }

  ngOnInit() {}


  /**
   * Called when the product is added successfully
   */
  onDealAdded(user: KalgudiUser): void {
    this.user = user;

    this.ok();
  }
  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }
}

