<div class="h-100 projects"
  [ngClass]="{ 'stats-sm': memberStatsDirection === 'horizontal','stats': memberStatsDirection === 'vertical' }">
  <div class="h-100" fxLayout fxLayoutAlign="space-between">

    <!-- Members and stats -->
    <div fxLayout="{{ memberStatsDirection === 'horizontal'? 'column':'row' }}"
      fxLayoutGap="{{ memberStatsDirection === 'horizontal'? '0px':'25px' }}" class="w-100">
      <div fxFlex="38%" fxLayout="{{ memberStatsDirection === 'horizontal'? 'row':'column' }}"
        fxLayoutAlign="{{ memberStatsDirection === 'horizontal'? 'start':'center' }} center">
        <div [@scaleOut]>
          <svg-icon [applyCss]="true" class="task-icon" src="assets/svgs/team-building.svg"
            *ngIf="memberStatsDirection === 'vertical'" [svgStyle]="{'width.px': '40', 'height.px': '50'}"></svg-icon>
          <svg-icon [applyCss]="true" class="task-icon mr-2" src="assets/svgs/team-building.svg"
            *ngIf="memberStatsDirection === 'horizontal'" [svgStyle]="{'width.px': '30', 'height.px': '30'}"></svg-icon>
        </div>

      <!-- All members value  starts here-->
        <div>
            <h4 class="title mb-0 text-center" translate>All Members</h4>
            <p class="count mb-0 text-secondary">{{ memberStatsDetails?.total | number}}</p>
        </div>
      <!--All members value  ends here  -->
      </div>
      <div
        [ngClass]="{ 'projects-border-bottom mb-2 mt-1': memberStatsDirection === 'horizontal', 'projects-border-right': memberStatsDirection === 'vertical' }">
      </div>

      <div *ngIf="memberStatsDetails?.total === 0" fxLayout="column" fxLayoutAlign="center center">
        <span class="text-muted mt-1" translate>
          No members added yet
        </span>
        <a class="mb-1" (click)="showAddMembersDialog();$event.stopPropagation()"><span translate>Add Members</span></a>
      </div>

      <div fxLayout="{{ memberStatsDirection === 'horizontal'? 'row':'column' }}"
        fxLayoutAlign="{{ memberStatsDirection === 'horizontal'? 'space-between':'center' }} center"
        [ngClass]="{ 'member-stats-sm': memberStatsDirection === 'horizontal', 'member-stats': memberStatsDirection === 'vertical' }">

      <!-- in order use an array us this starts here  -->

      <!--<div *ngFor="let stat of memberStatsDetails?.statsList" class="mb-2 w-100">
              <p class="mb-0 value">{{ stat.value }}</p>
              <p class="mb-0 label">{{ stat.title }}</p> -->

      <!-- in order use an array us this ends here-->

      <!-- admin and contributors and members values starts here -->
          <ng-container *ngIf="memberStatsDetails?.total !== 0">
            <div class="mb-2 w-100">
              <p class="mb-0 value">{{ memberStatsDetails?.admins }}</p>
              <p class="mb-0 label" translate>Admins</p>
            </div>
            <div class="mb-2 w-100">
              <p class="mb-0 value">{{ memberStatsDetails?.member }}</p>
              <p class="mb-0 label" translate>Members</p>
            </div>
            <div class="mb-2 w-100">
              <p class="mb-0 value">{{ memberStatsDetails?.contributors }}</p>
              <p class="mb-0 label" translate>Contributors</p>
            </div>
          </ng-container>

      <!-- admin and contributors and members values ends here -->

      </div>
    </div>
      <!-- /Members and stats -->

    <!-- <div class="add-button ml-n5 mr-2" *ngIf="showMenu">
      <button mat-mini-fab color="primary">
        <mat-icon class="add text-white">add</mat-icon>
      </button>
    </div> -->

  </div>
</div>
