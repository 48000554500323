import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiUsersPickerDialogConfig } from '@kalgudi/types';

import { KalgudiUsersPickerDialog } from '../../../kalgudi-users/classes/kalgudi-user-picker-dialog';

@Component({
  selector: 'kl-connects-picker-dialog',
  templateUrl: './connects-picker-dialog.component.html',
  styleUrls: ['./connects-picker-dialog.component.scss']
})
export class ConnectsPickerDialogComponent extends KalgudiUsersPickerDialog<ConnectsPickerDialogComponent> implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: KalgudiUsersPickerDialogConfig,
    protected dialogRef: MatDialogRef<ConnectsPickerDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {
    super(dialogData, dialogRef, util);
  }


  ngOnInit() { }

}
