import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDealsWith } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfileRouteConfig } from '../../../../config';
import { KalgudiProfileService } from '../../../../services/kalgudi-profile.service';

@Component({
  selector: 'kl-farmer-crops-list',
  templateUrl: './farmer-crops-list.component.html',
  styleUrls: ['./farmer-crops-list.component.scss']
})
export class FarmerCropsListComponent extends KalgudiDestroyable implements OnInit {

  isMobileDevice: boolean;

  isCrops: boolean;

  @Input()
  products: KalgudiDealsWith[];

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  productsLength: number;

  @Input()
  assistedProfileKey: string;

  @Output()
  editCrop = new EventEmitter<any>();

  @Output()
  deleteCrop = new EventEmitter<any>();

  domain: string;
  editable: boolean;
  isCropTiles: boolean;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private profileService: KalgudiProfileService,
  ) {

    super();

    this.isMobileDevice = checkMobileDevice();

    this.domain = this.env.coreDomain;

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Opens crop fullview
   */
  opensCropFullview(cropId: string, crop: string, cropPageId: string, farmingFarmType?: string) {

    if(!cropPageId) {

      return;
    }

    const cropTitle = crop.replace(/ /g, '_');

    const pageId = this.pageId;
    const isAssisted = this.isAssisted;
    const profileKey = this.assistedProfileKey;

    this.appRouting.toCropFullview({profileKey, cropId, cropTitle}, {cropPageId, pageId, isAssisted, farmingFarmType});

  }

  /**
   * Event emit back to parent for editing crop
   * @param product
   */
  editProduct(product: any) {
    this.editCrop.emit(product);
  }

  /**
   * Event emit back to parent to delete crop
   * @param product
   */
  deleteProduct(product: any) {
    this.deleteCrop.emit(product);
  }

  showAllCrops(product: any) {
    product.isCrops = true;
  }

  /**
   * View more button
   */
  viewMoreTiles() {
    this.isCropTiles = !this.isCropTiles;
  }
}
