import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaService } from '@kalgudi/qa';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ProgramQaFullDetails, ProgramQaRequest } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { KalgudiProgramQa } from '../../classes/kalgudi-program-qa';

@Component({
  selector: 'kl-program-qa-form',
  templateUrl: './program-qa-form.component.html',
  styleUrls: ['./program-qa-form.component.scss']
})
export class ProgramQaFormComponent extends KalgudiProgramQa implements OnInit {


  rowsCount: number;

  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiPageService: KalgudiPageService,
    protected uploadImageTos3: KalgudiUploadService,
    protected qaService: KalgudiQaService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp,
      kalgudiPageService,
      uploadImageTos3,
      qaService
    );

    this.isMobileDevice = checkMobileDevice();

    this.attachmentsFormControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.attachmentsFormControl.setValue(v, { onlySelf: true, emitEvent: false }));

  }

  ngOnInit() {
    this.rowsCount = checkMobileDevice() ? 10 : 3;
  }

  protected onDestroyed(): void { }

  /**
   * Calls after posting a share
   * @param payload
   * @param response
   */
  protected onPostCreated(payload: ProgramQaRequest, response: ProgramQaFullDetails): void {

    this.postCreated.emit(response);
  }


}
