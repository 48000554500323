import { Injectable } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { Observable } from 'rxjs';

import { LiveStockApiService } from './live-stock-api.service';


@Injectable()
export class LiveStockService {

  constructor(
    private api: LiveStockApiService,
    private dialogsService: KalgudiDialogsService
  ) { }

  /**
   * Calls api to add live stock
   */
  postLiveStock(profileKey: string, payload: any): Observable<any> {
    return this.api.postLiveStock(profileKey, payload);
  }

  /**
   * Calls api to update live stock
   */
  updateLiveStock(profileKey: string, payload: any): Observable<any> {
    return this.api.updateLiveStock(profileKey, payload);
  }

  /**
   * Calls api to get live stock list
   */
  getLiveStockList(profileKey: string): Observable<any> {
    return this.api.getLiveStockList(profileKey);
  }

}
