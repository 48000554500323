import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  KalgudiAttachUrlModule,
  KalgudiCommonModule,
  KalgudiDialogsModule,
  KalgudiGeoLocationMarkerModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiProductsPickerModule,
  KalgudiStoreProductsSearchModule,
} from '@kalgudi/common';
import { KalgudiLazyHttpClientModule } from '@kalgudi/common/lazy-http';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSelectableChipModule } from '@kalgudi/common/ui/selectable-chip';
import { KalgudiCoreModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiSocialModule, SatelliteAdvisoriesModule } from '@kalgudi/social';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { KalgudiWeatherModule } from '@kalgudi/weather';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { AddLiveStockModule } from '../add-live-stock/add-live-stock.module';
import { AwardsModule } from '../awards/awards.module';
import { CertificatesModule } from '../certificates/certificates.module';
import { ProfileBasicEditModule } from '../profile-basic-edit/profile-basic-edit.module';
import { AddCattleDialogComponent } from './components/add-cattle-dialog/add-cattle-dialog.component';
import { AddCattleMobileDialogComponent } from './components/add-cattle-mobile-dialog/add-cattle-mobile-dialog.component';
import { AddCattleComponent } from './components/add-cattle/add-cattle.component';
import { AddCropDialogComponent } from './components/add-crop-dialog/add-crop-dialog.component';
import { AddCropProductComponent } from './components/add-crop-product/add-crop-product.component';
import { AddCropComponent } from './components/add-crop/add-crop.component';
import {
  AddFarmerEquipmentDialogComponent,
} from './components/add-farmer-equipment-dialog/add-farmer-equipment-dialog.component';
import {
  AddFarmerEquipmentMobileDialogComponent,
} from './components/add-farmer-equipment-mobile-dialog/add-farmer-equipment-mobile-dialog.component';
import {
  AddFarmerMembershipDialogComponent,
} from './components/add-farmer-membership-dialog/add-farmer-membership-dialog.component';
import {
  AddFarmerMembershipMobileDialogComponent,
} from './components/add-farmer-membership-mobile-dialog/add-farmer-membership-mobile-dialog.component';
import {
  AddFarmingEquipmentDialogComponent,
} from './components/add-farming-equipment-dialog/add-farming-equipment-dialog.component';
import {
  AddFarmingEquipmentMobileDialogComponent,
} from './components/add-farming-equipment-mobile-dialog/add-farming-equipment-mobile-dialog.component';
import { AddFarmingEquipmentComponent } from './components/add-farming-equipment/add-farming-equipment.component';
import { AddLandDetailsDialogComponent } from './components/add-land-details-dialog/add-land-details-dialog.component';
import {
  AddLandDetailsMobileDialogComponent,
} from './components/add-land-details-mobile-dialog/add-land-details-mobile-dialog.component';
import { AddLandDetailsComponent } from './components/add-land-details/add-land-details.component';
import { AddLiveStockPageComponent } from './components/add-live-stock-page/add-live-stock-page.component';
import { AddProductsDialogComponent } from './components/add-products-dialog/add-products-dialog.component';
import {
  AddProductsMobileDialogComponent,
} from './components/add-products-mobile-dialog/add-products-mobile-dialog.component';
import { AddProductsComponent } from './components/add-products/add-products.component';
import { AdvisoriesDialogComponent } from './components/advisories-dialog/advisories-dialog.component';
import { AdvisoriesLandDetailsComponent } from './components/advisories-land-details/advisories-land-details.component';
import { AdvisoriesMobileDialogComponent } from './components/advisories-mobile-dialog/advisories-mobile-dialog.component';
import { CropFarmingActivityComponent } from './components/crop-farming-activity/crop-farming-activity.component';
import {
  FarmerAddFarmingHistoryDialogComponent,
} from './components/farmer-add-farming-history-dialog/farmer-add-farming-history-dialog.component';
import {
  FarmerAddFarmingHistoryFormComponent,
} from './components/farmer-add-farming-history-form/farmer-add-farming-history-form.component';
import {
  FarmerAddFarmingHistoryMobileDialogComponent,
} from './components/farmer-add-farming-history-mobile-dialog/farmer-add-farming-history-mobile-dialog.component';
import { FarmerAwardsComponent } from './components/farmer-awards/farmer-awards.component';
import { FarmerCattleComponent } from './components/farmer-cattle/farmer-cattle.component';
import { FarmerCertificatesComponent } from './components/farmer-certificates/farmer-certificates.component';
import {
  FarmerCropFarmingActivitiesComponent,
} from './components/farmer-crop-farming-activities/farmer-crop-farming-activities.component';
import { FarmerCropsListComponent } from './components/farmer-crops-list/farmer-crops-list.component';
import { FarmerCropsComponent } from './components/farmer-crops/farmer-crops.component';
import { FarmerEquipmentFormComponent } from './components/farmer-equipment-form/farmer-equipment-form.component';
import {
  FarmerFarmActivityDialogComponent,
} from './components/farmer-farm-activity-dialog/farmer-farm-activity-dialog.component';
import {
  FarmerFarmActivityMobileDialogComponent,
} from './components/farmer-farm-activity-mobile-dialog/farmer-farm-activity-mobile-dialog.component';
import { FarmerFarmActivityComponent } from './components/farmer-farm-activity/farmer-farm-activity.component';
import {
  FarmerFarmingActivityAddSeasonComponent,
} from './components/farmer-farming-activity-add-season/farmer-farming-activity-add-season.component';
import {
  FarmerFarmingActivitySeasonsComponent,
} from './components/farmer-farming-activity-seasons/farmer-farming-activity-seasons.component';
import {
  FarmerFarmingActivitySettingsComponent,
} from './components/farmer-farming-activity-settings/farmer-farming-activity-settings.component';
import {
  FarmerFarmingEquipmentsOwnedComponent,
} from './components/farmer-farming-equipments-owned/farmer-farming-equipments-owned.component';
import { FarmerFarmingHistoryComponent } from './components/farmer-farming-history/farmer-farming-history.component';
import { FarmerLandDetailsListComponent } from './components/farmer-land-details-list/farmer-land-details-list.component';
import { FarmerLandDetailsMapComponent } from './components/farmer-land-details-map/farmer-land-details-map.component';
import { FarmerLandDetailsComponent } from './components/farmer-land-details/farmer-land-details.component';
import { FarmerLandsListComponent } from './components/farmer-lands-list/farmer-lands-list.component';
import { FarmerLandsComponent } from './components/farmer-lands/farmer-lands.component';
import { FarmerMembershipFormComponent } from './components/farmer-membership-form/farmer-membership-form.component';
import { FarmerMembershipsComponent } from './components/farmer-memberships/farmer-memberships.component';
import { FarmerNoContentComponent } from './components/farmer-no-content/farmer-no-content.component';
import {
  FarmerProductAvailableStockComponent,
} from './components/farmer-product-available-stock/farmer-product-available-stock.component';
import { FarmerProductListComponent } from './components/farmer-product-list/farmer-product-list.component';
import { FarmerProductStockListComponent } from './components/farmer-product-stock-list/farmer-product-stock-list.component';
import { FarmerProductStockComponent } from './components/farmer-product-stock/farmer-product-stock.component';
import { FarmerProductComponent } from './components/farmer-product/farmer-product.component';
import { FarmerProductsComponent } from './components/farmer-products/farmer-products.component';
import {
  FarmerProfileBasicDetailsComponent,
} from './components/farmer-profile-basic-details/farmer-profile-basic-details.component';
import { FarmerProfileHeaderComponent } from './components/farmer-profile-header/farmer-profile-header.component';
import { FarmerWidget1Component } from './components/farmer-widget1/farmer-widget1.component';
import { FarmerWidget2Component } from './components/farmer-widget2/farmer-widget2.component';
import { FarmersEquipmentsComponent } from './components/farmers-equipments/farmers-equipments.component';
import { FarmersLiveStockComponent } from './components/farmers-live-stock/farmers-live-stock.component';
import { GeotagConfirmDialogComponent } from './components/geotag-confirm-dialog/geotag-confirm-dialog.component';
import { LandDataDownloadDialogComponent } from './components/land-data-download-dialog/land-data-download-dialog.component';
import { LandDataDownloadFormComponent } from './components/land-data-download-form/land-data-download-form.component';
import { LandDetailsAgroTaggingComponent } from './components/land-details-agro-tagging/land-details-agro-tagging.component';
import { LandDetailsDialogComponent } from './components/land-details-dialog/land-details-dialog.component';
import {
  LandDetailsMobileDialogComponent,
} from './components/land-details-mobile-dialog/land-details-mobile-dialog.component';
import { LandDetailsComponent } from './components/land-details/land-details.component';
import { NewFarmerCertificatesComponent } from './components/new-farmer-certificates/new-farmer-certificates.component';
import { NewFarmerProfileHeaderComponent } from './components/new-farmer-profile-header/new-farmer-profile-header.component';
import { SoilHealthCardDialogComponent } from './components/soil-health-card-dialog/soil-health-card-dialog.component';
import {
  SoilHealthCardMobileDialogComponent,
} from './components/soil-health-card-mobile-dialog/soil-health-card-mobile-dialog.component';
import { KalgudiFarmerProfileApiService } from './services/kalgudi-farmer-profile-api.service';
import { KalgudiFarmerProfileService } from './services/kalgudi-farmer-profile.service';
import { KalgudiFarmingActivityStreamStateService } from './services/kalgudi-farming-activity-stream-state.service';
import { KalgudiProductApiService } from './services/kalgudi-product-api.service';
import { KalgudiProductService } from './services/kalgudi-product.service';
import { KalgudiProfilePageApiService } from './services/kalgudi-profile-page-api.service';
import { KalgudiProfilePageService } from './services/kalgudi-profile-page.service';
import { KalgudiProgramStateService } from './services/kalgudi-program-state.service';

@NgModule({
  declarations: [
    FarmerProfileHeaderComponent,
    FarmerProfileBasicDetailsComponent,
    FarmerProductsComponent,
    FarmerProductListComponent,
    FarmerProductComponent,
    FarmerProductAvailableStockComponent,
    FarmerProductStockListComponent,
    FarmerProductStockComponent,
    FarmerLandDetailsListComponent,
    FarmerLandDetailsComponent,
    FarmerLandDetailsMapComponent,
    FarmerFarmingHistoryComponent,
    FarmerCattleComponent,
    FarmerFarmingEquipmentsOwnedComponent,
    FarmerWidget1Component,
    FarmerWidget2Component,
    FarmerCertificatesComponent,
    FarmerAwardsComponent,
    AddCattleDialogComponent,
    AddCattleMobileDialogComponent,
    AddFarmingEquipmentDialogComponent,
    AddFarmingEquipmentMobileDialogComponent,
    AddCattleComponent,
    AddFarmingEquipmentComponent,
    AddLandDetailsComponent,
    AddLandDetailsDialogComponent,
    AddLandDetailsMobileDialogComponent,
    AddProductsComponent,
    AddProductsDialogComponent,
    AddProductsMobileDialogComponent,
    FarmerAddFarmingHistoryDialogComponent,
    FarmerAddFarmingHistoryMobileDialogComponent,
    FarmerAddFarmingHistoryFormComponent,
    AdvisoriesDialogComponent,
    AdvisoriesMobileDialogComponent,
    AdvisoriesLandDetailsComponent,
    NewFarmerProfileHeaderComponent,
    FarmerFarmingActivitySettingsComponent,
    FarmerFarmingActivitySeasonsComponent,
    FarmerFarmingActivityAddSeasonComponent,
    FarmerFarmActivityComponent,
    FarmerFarmActivityDialogComponent,
    FarmerFarmActivityMobileDialogComponent,
    FarmerNoContentComponent,
    FarmerCropsListComponent,
    FarmerCropsComponent,
    CropFarmingActivityComponent,
    GeotagConfirmDialogComponent,
    FarmerLandsListComponent,
    FarmerLandsComponent,
    AddCropDialogComponent,
    AddCropComponent,
    AddCropProductComponent,
    FarmerCropFarmingActivitiesComponent,
    LandDetailsMobileDialogComponent,
    LandDetailsDialogComponent,
    LandDetailsAgroTaggingComponent,
    LandDetailsComponent,
    LandDataDownloadDialogComponent,
    LandDataDownloadFormComponent,
    FarmerMembershipFormComponent,
    AddFarmerMembershipDialogComponent,
    AddFarmerMembershipMobileDialogComponent,
    FarmerMembershipsComponent,
    FarmersEquipmentsComponent,
    FarmerEquipmentFormComponent,
    NewFarmerCertificatesComponent,
    SoilHealthCardDialogComponent,
    SoilHealthCardMobileDialogComponent,
    AddFarmerEquipmentDialogComponent,
    AddFarmerEquipmentMobileDialogComponent,
    FarmersLiveStockComponent,
    AddLiveStockPageComponent
  ],
  imports: [
    CommonModule,

    // Svg Icon Module
    AngularSvgIconModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // FlexLayout Module
    FlexLayoutModule,
    AngularSvgIconModule,

    // Material module
    MatCardModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatMenuModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatNativeDateModule,

    NgxMaterialTimepickerModule,

    //Kalgudi modules
    KalgudiLazyHttpClientModule,
    KalgudiCoreModule,
    KalgudiProductsPickerModule,
    KalgudiButtonModule,
    KalgudiDialogsModule,
    KalgudiNoContentModule,
    KalgudiSelectableChipModule,
    KalgudiGooglePlacesModule,
    KalgudiGoogleGeoLocationModule,
    KalgudiGeoLocationMarkerModule,
    ProfileBasicEditModule,
    KalgudiPipesModule,
    KalgudiWeatherModule,
    CertificatesModule,
    AwardsModule,
    KalgudiCommonModule,
    SatelliteAdvisoriesModule,
    KalgudiAttachUrlModule,
    KalgudiImagePickerModule,
    KalgudiStoreProductsSearchModule,
    AddLiveStockModule,

    KalgudiLoadMoreModule,
    KalgudiShareUpdateModule,
    KalgudiSocialModule,
    KalgudiImageChipListModule,

    TranslateModule
  ],
  exports: [
    FarmerProfileHeaderComponent,
    FarmerProfileBasicDetailsComponent,
    FarmerProductsComponent,
    FarmerProductListComponent,
    FarmerProductComponent,
    FarmerProductAvailableStockComponent,
    FarmerProductStockListComponent,
    FarmerProductStockComponent,
    FarmerLandDetailsListComponent,
    FarmerLandDetailsComponent,
    FarmerLandDetailsMapComponent,
    FarmerFarmingHistoryComponent,
    FarmerCattleComponent,
    FarmerFarmingEquipmentsOwnedComponent,
    FarmerWidget1Component,
    FarmerWidget2Component,
    FarmerCertificatesComponent,
    FarmerAwardsComponent,
    NewFarmerProfileHeaderComponent,
    FarmerFarmingActivitySettingsComponent,
    FarmerCropsComponent,
    CropFarmingActivityComponent,
    AdvisoriesDialogComponent,
    AdvisoriesMobileDialogComponent,
    AdvisoriesLandDetailsComponent,
    FarmerLandsComponent,
    AddCropProductComponent,
    AddLiveStockPageComponent,
    LandDetailsAgroTaggingComponent,
    AddLandDetailsComponent,
    AddCropComponent,
    LandDetailsMobileDialogComponent,
    LandDetailsDialogComponent,
    AddCropDialogComponent,
    FarmerMembershipsComponent,
    FarmersEquipmentsComponent,
    NewFarmerCertificatesComponent,
    FarmersLiveStockComponent
  ],
  providers: [
    KalgudiFarmerProfileApiService,
    KalgudiFarmerProfileService,
    KalgudiProductService,
    KalgudiProductApiService,
    KalgudiProfilePageService,
    KalgudiProfilePageApiService,
    KalgudiProgramStateService,
    KalgudiFarmingActivityStreamStateService,
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}
  ],
  entryComponents: [
    AddCattleDialogComponent,
    AddCattleMobileDialogComponent,
    AddFarmingEquipmentDialogComponent,
    AddFarmingEquipmentMobileDialogComponent,
    AddLandDetailsDialogComponent,
    AddLandDetailsMobileDialogComponent,
    AddProductsDialogComponent,
    AddProductsMobileDialogComponent,
    FarmerAddFarmingHistoryDialogComponent,
    FarmerAddFarmingHistoryMobileDialogComponent,
    AdvisoriesDialogComponent,
    AdvisoriesMobileDialogComponent,
    FarmerFarmActivityDialogComponent,
    FarmerFarmActivityMobileDialogComponent,
    GeotagConfirmDialogComponent,
    AddCropDialogComponent,
    LandDetailsDialogComponent,
    LandDetailsMobileDialogComponent,
    LandDataDownloadDialogComponent,
    AddFarmerMembershipDialogComponent,
    AddFarmerMembershipMobileDialogComponent,
    SoilHealthCardDialogComponent,
    SoilHealthCardMobileDialogComponent,
    AddFarmerEquipmentDialogComponent,
    AddFarmerEquipmentMobileDialogComponent,
  ]
})
export class KalgudiFarmerProfileModule {}
