import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { ChartDirection, TaskStatistics } from '@kalgudi/types';

@Component({
  selector: 'kl-project-task-stats',
  templateUrl: './project-task-stats.component.html',
  styleUrls: ['./project-task-stats.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectTaskStatsComponent implements OnInit {

  @Input()
  taskStatsDetails: TaskStatistics;

  @Input()
  taskStatsDirection: ChartDirection;

  @Input()
  showMenu: boolean;

  @Output()
  createTaskDialog = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  openCreateTaskDialog() {
    this.createTaskDialog.emit();
  }

}
