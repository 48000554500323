<main class="social-attachments">
  <div fxLayout="row wrap" fxLayoutGap="6px">

    <!-- Documents list -->
    <mat-chip-list>
      <ng-container *ngFor="let item of attachments; let i = index">
        <ng-container *ngIf="item?.msgType === attachmentType.DOCUMENT">

          <mat-chip class="pl-0 chips" [ngClass]="{'mb-3': imagesList?.length}">

            <div class="document-icon rounded-circle text-center" *ngIf="item?.msgType === attachmentType?.DOCUMENT" fxLayout="row" fxLayoutAlign="center center">
              <i class="fas fa-file-alt"></i>
            </div>

            <a class="text-truncate ml-1 mr-1"
              [href]="item?.url | prefixDomain"
              target="_blank"
              title="View document" translate>
              {{item?.context || 'Document'}}
            </a>

          </mat-chip>

        </ng-container>
      </ng-container>
    </mat-chip-list>
    <!--/ Documents list -->
  </div>

  <div *ngIf="imagesList?.length">
    <kl-post-tile-image-attachment [attachments]="imagesList">
    </kl-post-tile-image-attachment>
  </div>

  <div *ngIf="videoList?.length">
    <ng-container *ngFor="let attachment of videoList">
      <div class="audio-attach mr-3" fxLayout="row" fxLayoutAlign="center center">
        <video width="100%" height="260" controls>
          <source [src]="attachment?.url | prefixDomain" type="video/mp4"/>
        </video>
      </div>

    </ng-container>
  </div>
</main>
