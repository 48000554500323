<!-- <main class="fpo-list pt-3">
  <section class="container" *ngFor="let data of fpoList;let last = last">
    <div fxLayout="row" fxLayoutAlign="start center" class="fpo-list-info" [ngClass]="{'border-bottom-0': last}" fxLayoutGap="10px">
      <div>
        <img [src]="data?.profilePicUrl" class="image mt-1" (error)="img.src = defaultProfilePic" #img>
      </div>

      <div fxLayout="column" fxFlex="80%" fxLayoutAlign="center start">
        <span class="name">{{ data?.firstName }}</span>

        <div class="location">
          <span class="text-muted">{{ data?.locationLong }}</span>
        </div>

        <div class="contact">
          <span class="mobile-no">{{ data?.mobileNo }}</span>
          <span class="mx-1">|</span>
          <span class="email">{{ data?.email }}</span>
        </div>
      </div>
    </div>
  </section>
</main> -->

<!-- Members list -->
<div  class="user-list-main-wrapper"  fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center">

  <div class="user-list-wrapper" fxFlex="50%" fxFlex.xs="100%" *ngFor="let user of fpoList">
    <div class="p-1 m-1 user-list">
      <kl-selectable-user-tile
        [author]="user" avatarSize="md"
        [showRemove]="false"
        [navigationAllowed]="true"
        (viewProfilePage)="closeFpoListDialog()">
      </kl-selectable-user-tile>
    </div>
  </div>
</div>
<!-- /Members list -->
