import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import {
  AgGridAnchorRendererComponent,
  AgGridDateRendererComponent,
  AgGridLoadingCellRendererComponent,
  AgGridQuantityUnitRendererComponent,
  AgGridSlNoRendererComponent,
  AgGridYesNoRendererComponent,
} from '@kalgudi/ag-grid-lib/ag-grid';
import { AgGridColumnDef, AgGridFilters, AgGridFormatter } from '@kalgudi/types';


@Injectable()
export class AgGridMapperService {

  components = [
    AgGridDateRendererComponent,
    AgGridSlNoRendererComponent,
    AgGridLoadingCellRendererComponent,
    AgGridYesNoRendererComponent,
    AgGridQuantityUnitRendererComponent,
    AgGridAnchorRendererComponent
  ];

  constructor(
    private injector: Injector,
    private cfr: ComponentFactoryResolver,
  ) { }

  /**
   * Maps value formatters, cell renderers, filters params to the ag grid.
   */
  mapAgGridColumnDefs(columnDefs: AgGridColumnDef[], dynamicComponents?: any[]): AgGridColumnDef[] {
    if(dynamicComponents && dynamicComponents.length) {
      dynamicComponents.forEach(component => {
        this.components.push(component);
      });
    }

    return columnDefs.map(c => {
      c = this._mapValueFormatter(c);
      c = this._mapCellFilterParams(c);
      c = this._mapCellRendererFramework(c);

      return c;
    });
  }


  /**
   * Maps all the filter with their param values
   * @param columnDefs Ag grid column definitions
   */
  mapCellFilterParams(columnDefs: AgGridColumnDef[]): AgGridColumnDef[] {
    return columnDefs.map(c => this._mapCellFilterParams(c));
  }

  /**
   * Maps all the string value formatters to its function definitions
   * @param columnDefs Ag grid column definitions
   */
  mapValueFormatter(columnDefs: AgGridColumnDef[]): AgGridColumnDef[] {
    return columnDefs.map(c => this._mapValueFormatter(c));
  }

  /**
   * Maps all cell renderer framework names to their component type
   *
   * @param columnDefs Ag grid column definitions
   */
  mapCellRendererFramework(columnDefs: AgGridColumnDef[]): AgGridColumnDef[] {

    return columnDefs.map(c => this._mapCellRendererFramework(c));
  }



  private _mapCellFilterParams(columnDef: AgGridColumnDef): AgGridColumnDef {

    if (columnDef.filter === AgGridFilters.DateFilter) {
      columnDef.filterParams = this._dateFilterParams();
    }

    // Return the column definition
    return columnDef;
  }

  private _mapValueFormatter(columnDef: AgGridColumnDef): AgGridColumnDef {

    if (columnDef.formatter) {

      try {
        // Get injector from service
        const formatter: AgGridFormatter = this.injector.get(columnDef.formatter);

        // Add value formatter to the current column def
        columnDef.valueFormatter = (val) => formatter.format(val.value, columnDef.formatterValue);
      } catch (e) {
        console.error('No value formatter found for ', columnDef);
        console.error(e);
      }
    }

    // Return the column definition
    return columnDef;
  }

  private _mapCellRendererFramework(columnDef: AgGridColumnDef): AgGridColumnDef {

    if (columnDef.cellRendererFrameworkName) {

      try {

        // Get all registered components
        const factories = this.getAllRegisteredComponents();

        // Find the matching component with specified name
        const comp = this.getComponents(factories, columnDef.cellRendererFrameworkName);

        // Assign cell renderer framework
        columnDef.cellRendererFramework = comp as any;

      } catch (e) {
        console.error('No cell renderer framework component found for ', columnDef);
        console.error(e);
      }
    }

    // Return the column definition
    return columnDef;
  }

  private getAllRegisteredComponents(): any[] {
    return Array.from(this.components);
  }

  private getComponents(factories: any[], componentName: string) {

    return factories.find((f: any) => f.name === componentName || f.componentId === componentName)
  }


  /**
   * @see https://www.ag-grid.com/javascript-grid-date-component/
   */
  private _dateFilterParams() {

    return {

      inRangeInclusive: true,
      clearButton: true,
      resetButton: true,

      comparator: (filterLocalDateAtMidnight, cellValue) => {

        const originalDate = new Date(cellValue);
        var cellDate = new Date(originalDate.getFullYear(), originalDate.getMonth(), originalDate.getDate());

        return (filterLocalDateAtMidnight.getTime() === cellDate.getTime())
          ? 0
          : (cellDate < filterLocalDateAtMidnight)
            ? -1
            : 1;
      }
    };
  }
}
