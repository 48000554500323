import { Inject, Directive } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogResult, KalgudiUsersMap, KalgudiUsersPickerDialogConfig, PartialData } from '@kalgudi/types';

import { KalgudiDialog } from '../../kalgudi-dialogs/classes/kalgudi-dialog';

/**
 * Users picker dialog logic class.
 *
 */
@Directive()
export abstract class KalgudiUsersPickerDialog<T> extends KalgudiDialog<T> {

  /**
   * Dialog configuration if the user is allowed to select multiple users or not
   */
  readonly multiSelect: boolean;

  /**
   * Title of the dialog
   */
  readonly title: string;

  /**
   * Placeholder for searchbar
   */
  readonly placeHolder: string;

  /**
   * Binding nearest location to buyer location
   */
  readonly location: string;

  /**
   * Title of the dialog accept button
   */
  readonly acceptButtonTitle: string;

  /**
   * Title of the dialog reject button
   */
  readonly rejectButtonTitle: string;

  /**
   * Initial set of selected users when initializing the dialog.
   */
  initialSelectedUsersMap: KalgudiUsersMap = {};

  /**
   * Modified set of selected users after performing actions on dialog.
   */
  selectedUsersMap: KalgudiUsersMap = {};

  /**
   * Search extra params received from its parent
   */
  extraSearchParams: PartialData = {};


  /**
   * Search happens with the initial keyword given
   */
  initialSearchKeyword: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) protected dialogData: KalgudiUsersPickerDialogConfig,
    protected dialogRef: MatDialogRef<T>,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up (initialize) my parent
    super(dialogRef);

    // Initialize list of selected users
    this.initialSelectedUsersMap = this.util.clone(this.dialogData.data) || {};
    this.selectedUsersMap = this.util.clone(this.dialogData.data) || {};

    this.multiSelect          = !!(this.dialogData.multiSelect);
    this.title                = this.dialogData.title || 'Select users';
    this.acceptButtonTitle    = this.dialogData.acceptButtonTitle || 'Select users';
    this.rejectButtonTitle    = this.dialogData.rejectButtonTitle || 'Cancel';
    this.extraSearchParams    = this.dialogData.extraParams;
    this.placeHolder          = this.dialogData.placeHolder || 'Type to search users';
    this.location             = this.dialogData.location || '';
    this.initialSearchKeyword = this.dialogData.initialSearchKeyword || '';
  }

  ok(): void {
    const dialogRes: KalgudiDialogResult = {
      data: this.selectedUsersMap,
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: this.initialSelectedUsersMap,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
