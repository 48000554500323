import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { kalgudiAnimations, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiTasksGrid } from '../../classes/kalgudi-tasks-grid';


@Component({
  selector: 'kl-task-list-grid',
  templateUrl: './task-list-grid.component.html',
  styleUrls: ['./task-list-grid.component.scss'],
  animations: kalgudiAnimations
})
export class TaskListGridComponent extends KalgudiTasksGrid implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialog: MatDialog,
    protected injector: Injector
  ) {

    super(notification, util, dialog, injector);
  }

  ngOnInit() {
    this.initStream();
  }
}
