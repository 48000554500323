import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiDialog } from '../../classes/kalgudi-dialog';

@Component({
  selector: 'kl-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent extends KalgudiDialog<ConfirmDialogComponent> implements OnInit {

  constructor(
    protected dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }


  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {

      }
    };

    this.closeDialog(result);
  }

}


