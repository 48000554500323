import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

import { KalgudiTaskMembersListDa } from '../../classes/kalgudi-task-members-list-da';

@Component({
  selector: 'kl-task-member-list-da',
  templateUrl: './task-member-list-da.component.html',
  styleUrls: ['./task-member-list-da.component.scss']
})
export class TaskMemberListDaComponent extends KalgudiTaskMembersListDa implements OnInit {

  @Output()
  selectedProfile = new EventEmitter<any[]>();

  @Output()
  errorMsg = new EventEmitter<string>();

  selectedMembers: any[] = [];


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {
    super(notification,util,injector);
  }

  ngOnInit() {

    this.initStream();
  }

  onDestroyed(): void {}

  /**
   * Toggles the task member selection
   */
  toggleTaskMemberSelection(member: KalgudiUser) {

    if((member.selected === undefined || !member.selected) && this.selectedMembers.length === 10) {
      member.selected = false;

      this.errorMsg.emit('Select only 10 members');
    } else {

      member.selected = !member.selected;

      if (!member.selected) {
        let index = this.selectedMembers.findIndex(x => x === member.profileKey);
        this.selectedMembers.splice(index, 1);
      } else {
        this.selectedMembers.push(member);
      }
      this.errorMsg.emit('');
      this.selectedProfile.emit(this.selectedMembers);
    }

  }
}
