import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateStreamStateService } from '@kalgudi/social';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, ProgramShareUpdate, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { KalgudiPageInboxStream } from '../../../classes/kalgudi-page-inbox-stream';
import { KalgudiProgramShareUpdateService } from '../services/kalgudi-program-share-update.service';

@Directive()
export abstract class KalgudiProgramShareUpdateStream extends KalgudiPageInboxStream<ProgramShareUpdate> {

  @Input()
  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  private kalgudiDialogs: KalgudiDialogsService;
  private environment: KalgudiEnvironmentConfig;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected updatesStream: KalgudiProgramShareUpdateService,
    protected shareUpdateStreamState: KalgudiShareUpdateStreamStateService,
    protected injector: Injector
  ) {

    super(notification, util);

    this.kalgudiDialogs = this.injector.get(KalgudiDialogsService);
    this.environment    = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
  }



  /**
   * Shows confirmation dialog and returns boolean.
   */
  showSchedulePostConfirmationDialog = (postDetails: ProgramShareUpdate): Observable<ShareUpdate>  => {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Alert',
      acceptButtonTitle: 'Ok',
      rejectButtonTitle: 'Cancel',
      message: 'Updating schedule post will reset previous filters and share tags. Are you sure to update?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '400px',
      maxWidth: '400px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),

        switchMap(res => this.showUpdateScheduledPostDialog(postDetails))
      );
  }

  /**
   * Opens the share update form in update mode
   */
  showUpdateScheduledPostDialog(postDetails: ProgramShareUpdate): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update post',
      acceptButtonTitle: 'Send',
      rejectButtonTitle: 'Cancel',
      data: {
        postDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.updatesStream.showShareUpdateDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res && res.accepted)
      );
  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.updatesStream.getPageShareStream(this.pageId, offset, limit);
    //   return this.updatesStream.getShareStream(this.pageId, offset, limit);
  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<ProgramShareUpdate> {
    return this.shareUpdateStreamState.newStreamItem$;
  }
}
