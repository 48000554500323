import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';

import { AiPredictionsFiltersList } from '../../classes/ai-predictions-filters-list';


@Component({
  selector: 'kl-ai-predictions-filters-list',
  templateUrl: './ai-predictions-filters-list.component.html',
  styleUrls: ['./ai-predictions-filters-list.component.scss']
})
export class AiPredictionsFiltersListComponent extends AiPredictionsFiltersList implements OnInit {

  @Output()
  isShowFpoList = new EventEmitter<any>();

  constructor(
    protected injector: Injector
  ) {
    super(injector);

  }

  ngOnInit(): void {
    this.getAiPredictions();

    this.getAiPredictionsFilters();
  }

  onDestroyed(): void {}

  /**
   * Event emit back to parent to show fpo list
   */
  showFpoList(fpoCount) {

    if(fpoCount) {
      this.isShowFpoList.emit(this.fpoList);

    } else {
      return;
    }

  }

}
