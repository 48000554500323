import { Injectable } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { Action, ActivityType, KalgudiProjectTask, PartialData, TaskStateUpdateRequest } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiTasksApiService } from './kalgudi-tasks-api.service';

@Injectable()
export class KalgudiTasksService {

  constructor(
    private api: KalgudiTasksApiService,
    private kalgudiDialogService: KalgudiDialogsService,
    private util: KalgudiUtilityService,
    private projectStateService: KalgudiProjectStateService,
  ) { }

  /**
   * Make api call to get task details
   */
  getTaskDetails(taskId: string): Observable<KalgudiProjectTask> {
    return this.api.getTaskFullDetails(taskId)
      .pipe(
        tap(res => this.projectStateService.updateTaskDetails(res))
      );
  }



  /**
   * Make api call to update the task
   */
  updateTask(taskId: string, req: KalgudiProjectTask |  TaskStateUpdateRequest): Observable<KalgudiProjectTask> {

    const payload = this.util.clone<KalgudiProjectTask | TaskStateUpdateRequest>(req);

    return this.api.updateTaskDetails(taskId, payload);
  }

  /**
   * Make api call to delete the task
   */
  deleteTask(taskId: string, filters: PartialData = {}): Observable<KalgudiProjectTask> {

    return this.api.deleteTask(taskId, filters);
  }

  /**
   * Make api call to get task member stats
   */
  getTaskMemberStats(taskId: string): Observable<any> {

    return this.api.getTaskMemberStats(taskId);
  }

  /**
   * Calls api method to get task updates
   * @param offset
   * @param limit
   * @param activityType COMMENT | TASK_SUBMISSION
   */
  getActivities(
    activityType: string,
    taskId: string,
    offset?: number,
    limit?: number,
    profileKey?: string,
    submissionId?: string,
    searchKeyword?: string,
    extraParams?: PartialData
  ): Observable<KalgudiStreamData> {

    return this.api.getActivities(activityType, taskId, offset, limit, profileKey, submissionId, searchKeyword, extraParams);
  }


  /**
   * Calls api to fetch the activity details
   * @param projectId
   * @param taskId
   * @param activityType COMMENT | TASK_SUBMISSION
   * @param activityId
   */
  getActivity(projectId: string, taskId: string, activityType: ActivityType, activityId: string): Observable<any> {
    return this.api.getActivityDetails(projectId, taskId, activityType, activityId);
  }

   /**
    * Calls api to create the activity
    * @param projectId
    * @param taskId
    * @param activityType COMMENT | TASK_SUBMISSION
    * @param req
    */
  createActivity(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    req: any,
    submissionId?: string,
    profileKey?: string,
    action?: Action,
    isBulk?: boolean
  ): Observable<any> {

    const payload = this.util.clone<any>(req);

    return this.api.createActivity(projectId, taskId, activityType, payload, submissionId, profileKey, action, isBulk);
  }


   /**
    * Calls api to upadte the activity
    * @param projectId
    * @param taskId
    * @param activityType COMMENT | TASK_SUBMISSION
    * @param req
    */
  updateActivity(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    activityId: string,
    req: any,
    profileKey?: string,
    ): Observable<any> {

    const payload = this.util.clone<any>(req);

    return this.api.updateActivity(projectId, taskId, activityType, activityId, payload, profileKey);
  }


  /**
    * Calls api to delete the activity
    * @param projectId
    * @param taskId
    * @param activityType COMMENT | TASK_SUBMISSION
    */
  deleteActivity(projectId: string, taskId: string, activityType: ActivityType, activityId: string): Observable<any> {

    return this.api.deleteActivity(projectId, taskId, activityType, activityId);
  }

}
