import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';


@Component({
  selector: 'kl-flat-button',
  templateUrl: './flat-button.component.html',
  styleUrls: ['../sass/kalgudi-buttons.scss'],
  animations: kalgudiAnimations
})
export class FlatButtonComponent implements OnInit {

  @Input()
  label: string;

  @Input()
  type: 'button' | 'submit' | 'reset';

  @Input()
  classList: string;

  @Input()
  progress: boolean;

  @Input()
  disabled: boolean;

  @Input()
  buttonColor: string;

  @Output()
  clicked = new EventEmitter<MouseEvent>();

  constructor() {
    this.label = 'Button';
    this.type = 'button';
    this.progress = false;
    this.disabled = false;
  }

  ngOnInit() { }

  onClick(event: MouseEvent): void {

    this.clicked.emit(event);

    event.stopPropagation();
  }

}
