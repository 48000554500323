<main id="traceability-map" class="container">
  <p class="traceability-map-title">See the journey of your product</p>

  <div class="map-content" fxLayout.xs="column" fxLayout.sm="column" fxLayout="row">

    <div class="w-100 google-traceability-map" fxLayout.xs="row" fxLayout.sm="row" fxLayout="column" fxFlex="60%" fxLayoutAlign="center center">

      <div *ngIf="!buyerDetails || !sellerDetails" >
        <kl-no-content progressText="Hold on loading map..." progress="true"></kl-no-content>
      </div>
      <div class="order-map" *ngIf=" sellerDetails && buyerDetails">
        <kl-order-location-marker
          [fulfillmentBasedOnStore]="fulfillmentBasedOnStore"
          [buyerDetails]="buyerDetails"
          [sellerDetails]="sellerDetails"
          [orderDetails]="orderDetails"
          [progress]="progress">
        </kl-order-location-marker>
      </div>
    </div>

    <div class="w-100 product-details-tile" fxLayout.xs="row" fxLayout.sm="row" fxLayout="column" fxFlex="40%" fxLayoutAlign="center center">
      <div fxLayout="column" fxLayoutGap="18px" class="bg-white product-journey-details" fxLayoutAlign="space-around start">
        <ng-container *ngIf="progress || !buyerDetails" fxLayoutAlign="center start">
          <kl-no-content class="mx-auto mb-46" fxHide.xs
            [progressText]="'Loading please wait...' | translate"
            [progress]="progress">
          </kl-no-content>
        </ng-container>

        <ng-container *ngIf="!progress && buyerDetails">

          <!-- Sellers -->
          <div fxLayout="row" fxLayoutGap="8px">

            <div class="seller-details rounded-circle text-white mt-n1" fxFlex="45px" fxLayoutAlign="center center">
              S
            </div>
            <div fxLayout="column">
              <span class="seller-title" translate>Supplier</span>
              <span class="seller-address text-secondary">
                <span class="seller-address-title">{{sellerDetails?.firstName}}</span>
              </span>
              <span  class="seller-address text-secondary">
                <span *ngIf="sellerDetails?.locationDetails?.placeName">{{sellerDetails?.locationDetails?.placeName}}</span>
                <span *ngIf="sellerDetails?.locationDetails?.districtName">, {{sellerDetails?.locationDetails?.districtName}}</span>
                <span *ngIf="sellerDetails?.locationDetails?.stateName">, {{sellerDetails?.locationDetails?.stateName}}</span>
              </span>
            </div>
          </div>


          <!-- FulFillment center-->
          <div fxLayout="row" fxLayoutGap="8px">
            <div class="ful-fillment-details rounded-circle text-white mt-n1 w-100" fxFlex="45px" fxLayoutAlign="center center">
              F
            </div>
            <div fxLayout="column">
              <span class="ful-fillment-title" translate>Fulfillment center</span>
              <span class="ful-fillment-address text-secondary">
                <span class="ful-fillment-address-title">{{fulfillmentBasedOnStore?.firstName}}</span>
              </span>
              <span class="ful-fillment-address text-secondary">
                <span>{{fulfillmentBasedOnStore?.location}}</span>
              </span>
            </div>
          </div>

          <!-- Buyer -->
          <div fxLayout="row" fxLayoutGap="8px">
            <div class="buyer-details rounded-circle text-white mt-n1" fxFlex="45px" fxLayoutAlign="center center">
              B
            </div>
            <div fxLayout="column">
              <span class="buyer-title" translate>Buyer</span>
              <span class="buyer-address text-secondary">
                <span class="buyer-address-title">{{orderDetails?.buyerFirstName || buyerDetails?.name | titlecase}}</span>
              </span>
              <span class="buyer-address text-secondary">
                <span *ngIf="buyerDetails?.addressLine1">{{buyerDetails?.addressLine1 | titlecase}}</span>
                <span *ngIf="buyerDetails?.addressLine2">, {{buyerDetails?.addressLine2 | titlecase}}</span>
                <span *ngIf="buyerDetails?.city">, {{buyerDetails?.city}}</span>
                <span *ngIf="buyerDetails?.state">, {{buyerDetails?.state}}</span>
                <span *ngIf="buyerDetails?.postalCode">, {{buyerDetails?.postalCode}}</span>
              </span>
            </div>
          </div>
        </ng-container>

      </div>
    </div>

  </div>
</main>

