import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'kl-ai-predictions-information-list',
  templateUrl: './ai-predictions-information-list.component.html',
  styleUrls: ['./ai-predictions-information-list.component.scss']
})
export class AiPredictionsInformationListComponent implements OnInit {
  @Input()
  informationList: any[];

  @Input()
  showCopy: boolean;

  @Input()
  copyId: string;

  @Output()
  onCopyClicked = new EventEmitter();

  @Input()
  progress: boolean;

  showDetails: boolean;
  selectedItem: number;

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Copying the html as text to clipboard
   * @param elementId
   */
   copyToClipBoard(elementId): void {
    this.onCopyClicked.emit(elementId)
  }

  /**
   * To show the list
   */
  displayDetails(item) {
    item['hide'] = false
  }

  /**
   * To hide the list
   */
  hideExtraInfo(item) {
    item['hide'] = true
  }

}
