import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';

/**
 * Defines a list of Kalgudi reactive form validators.
 *
 * @author Pankaj Prakash
 */
// @dynamic
export class KalgudiFormValidators {

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Array of mobile validators
   */
  public static mobileValidators: ValidatorFn[] = [
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern(/^\d+$/)
  ];

  /**
   * Indian mobile number format validator
   */
  public static indianMobileNumberValidators: ValidatorFn[] = [
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern(/^[6789]\d{9}$/)
  ]

  /**
   * Array of email validators
   *
   * @see https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
   */
  public static emailValidators: ValidatorFn[] = [
    Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
  ];

  /**
   * It will accept max 4 charactor after dot (e.g .in/.com).
   */
  public static strictEmailValidators: ValidatorFn[] = [
    Validators.pattern(/^\w+([\s+-=]?\w+)*@\w+([\.-]?\w+)*(\.[a-zA-Z]{2,4})+$/)
  ];

  /**
   * Array of email validators restricted to kalgudi users.
   * It only allows a valid kalgudi email i.e.
   * <any-thing>@kalgudi.com or <any-thing>@vasudhaika.net
   *
   * @see emailValidators
   */
  public static kalgudiEmailValidators: ValidatorFn[] = [
    ...KalgudiFormValidators.emailValidators,
    Validators.pattern(/(@vasudhaika\.net|@kalgudi\.com)$/),    // Email must end with @vasudhaika.net or @kalgudi.com
  ];

  /**
   * Array of password validators
   */
  public static passwordValidators: ValidatorFn[] = [
    Validators.minLength(8)
  ];

  /**
   * Array of otp validators
   */
  public static otpValidators: ValidatorFn[] = [
    Validators.minLength(6)
  ];

  /**
   * Defines a set of alphabet validator
   */
  public static alphabetValidator: ValidatorFn[] = [
    Validators.pattern(/^([a-zA-Z\s]+)$/)
  ];

  /**
   * Defines a set of alphanumeric validators
   */
  public static alphanumericValidator: ValidatorFn[] = [
    Validators.pattern(/^([a-zA-Z0-9\s]+)$/)
  ];

  /**
   * Defines a set of number validators
   */
  public static numberValidator: ValidatorFn[] = [
    Validators.pattern(/^([0-9]*)$/)
  ];

  /**
   * Reactive form validator for must match fields. It matches two fields for equality
   * and ensures both the fields are equal.
   *
   * @param controlName Control name to match
   * @param matchingControlName Matching control name
   *
   * @example
   * ```
   * new FormGroup(
   *   {
   *     password: new FormControl('', Validators.required),
   *     confirmPassword: new FormControl('', Validators.required),
   *   },
   *   {
   *     validators: KalgudiFormValidators.mustMatchValidator('password', 'confirmPassword')
   *   }
   * );
   * ```
   */
  public static mustMatchValidator(controlName: string, matchingControlName: string): (fb: FormGroup) => void {

    return (formGroup: FormGroup) => {

      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if(matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      const error = KalgudiFormValidators.getMustMatchValidationError(control, matchingControl);

      // set error on matchingControl if validation fails
      matchingControl.setErrors(error);
    };
  }

  private static getMustMatchValidationError(control: AbstractControl, matchingControl: AbstractControl) {

    return (control.value !== matchingControl.value)
      ? { mustMatch: true }
      : null;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
