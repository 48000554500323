import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class SmsLogsListStateService {

  /**
   * Gets, the current active status type
   */
  readonly statusTypeChanged$: Observable<string>;

  /**
   * Defines current active status type
   */
  private activeStatusTypeSubject = new BehaviorSubject<string>(null);

  constructor() {

    this.statusTypeChanged$ = this.activeStatusTypeSubject.asObservable()
      .pipe(
        filter(val => val !== null)
      );
  }

  updateStatusType(filter: string): void {

    this.activeStatusTypeSubject.next(filter);
  }

}

