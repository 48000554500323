<mat-card id="kl-survey-info-page">

  <div>
    <kl-survey-info-form [surveyForm]="surveyForm"></kl-survey-info-form>

    <div class="buttons w-100 mt-2" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0" fxLayoutAlign.xs="space-between center">

      <div fxFlex.xs="100%" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10px" fxLayoutGap.xs="0px">
        <!-- Section for reminder -->
        <section class="send-reminder" fxLayout fxLayoutAlign="center center">
          <button class="reminder-btn button-icon text-white" mat-flat-button fxLayout fxLayoutAlign="center center" (click)="sendReminder()">
            <mat-icon class="mr-1">notifications</mat-icon>
            <span translate>Send reminder</span>
          </button>
        </section>
        <!-- /Section for reminder -->

        <!-- Section for clone -->
        <section class="send-reminder" fxLayout fxLayoutAlign="center center">
          <button class="reminder-btn button-icon text-white" mat-flat-button fxLayout fxLayoutAlign="center center" (click)="addCloneSurveyDialog()">
            <mat-icon class="mr-1">filter_none</mat-icon>
            <span translate>Clone</span>
          </button>
        </section>
        <!-- /Section for clone -->

        <!-- Section for reminder -->
        <section fxLayout fxLayoutAlign="center center">
          <button class="delete-survey button-icon" mat-flat-button color="warn" fxLayout fxLayoutAlign="center center" (click)="deleteSurvey()">
            <mat-icon class="mr-1">delete</mat-icon>
            <span translate>Delete survey</span>
          </button>
        </section>
        <!-- /Section for reminder -->

      </div>

      <div class="update-survey" fxFlex fxLayout fxLayoutAlign="end center" fxHide.xs>

        <kl-link-button label="Update" buttonColor="primary" [progress]="progress" (clicked)="updateSurvey()"></kl-link-button>
      </div>

      <div class="submit-btn mt-3 w-100" fxHide.gt-xs>
        <kl-flat-button
          buttonColor="primary"
          [type]="'submit'"
          [label]="'Update'"
          [progress]="progress"
          (clicked)="updateSurvey()">
        </kl-flat-button>
      </div>
    </div>
  </div>

</mat-card>
