import { Injector, Input, Directive, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import { PageFiltersService } from '@kalgudi/pages-shared';
import { IdValueMap, LabelValueMap, PageType } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { PageShareFiltersService } from '../../../program-target-members/services/page-share-filters.service';

@Directive()
export abstract class PageActivityFilters extends KalgudiDestroyable {

  @Output() shareWith = new EventEmitter<string>();

  @Input()
  pageId = '';

  @Input()
  shareForm: FormGroup;

  @Input()
  showTags = true;

  @Input()
  hideShareVisibility = false;

  @Input()
  hideProductsFilter: boolean = false;

  @Input()
  hideBusinessFilter: boolean = false;

  @Input()
  tagType: PageType;

  @Input()
  streamType: 'SMS' | 'ROBOCALL' | undefined;

  @Input()
  showBulkUpload: boolean = false;

  @Output()
  verifyBulkUpload = new EventEmitter<any>();

  @Output()
  isExcelUploaded = new EventEmitter<boolean>();

  readonly sharedVisibilities = SHARE_TYPES;

  readonly shareVisibilityList: LabelValueMap[] = [
    SHARE_TYPES.ALL_SUBSCRIBERS,
    SHARE_TYPES.SPECIFIC_KALGUDI_USER,
    SHARE_TYPES.SPECIFIC_SUBSCRIBERS,
  ];

  memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }>;

  private targetedMetaService: PageShareFiltersService;
  private pageFilterService: PageFiltersService;

  constructor(
    protected injector: Injector,
  ) {
    super();

    // Inject all dependencies
    this.targetedMetaService = this.injector.get(PageShareFiltersService);
    this.pageFilterService   = this.injector.get(PageFiltersService);

    this.memberTargetingFilters$ = this.pageFilterService.memberTargetingFilters$;
  }

  onDestroyed(): void { }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('recipient');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.shareFormVisibility.value;
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get('filter') as FormGroup;
  }

  /**
   * Share form program messages users filters field
   */
  get shareFormFilterUsers(): FormArray {
    return this.shareFormFilter.get('users') as FormArray;
  }

  /**
   * Share form program messages products filters field
   */
  get shareFormFilterProducts(): FormArray {
    return this.shareFormFilter.get('products') as FormArray;
  }

  /**
   * Share form program messages business types filter field
   */
  get shareFormFilterBusinessTypes(): FormArray {
    return this.shareFormFilter.get('businessTypes') as FormArray;
  }

  /**
   * Share form program messages locations filter field
   */
  get shareFormFilterLocations(): FormArray {
    return this.shareFormFilter.get('locations') as FormArray;
  }

  /**
   * Share form program messages countries filter field
   */
  get shareFormFilterCountries(): FormArray {
    return this.shareFormFilter.get('countries') as FormArray;
  }

  /**
   * Share form program messages states filter field
   */
  get shareFormFilterStates(): FormArray {
    return this.shareFormFilter.get('states') as FormArray;
  }

  /**
   * Share form program messages districts filter field
   */
  get shareFormFilterDistricts(): FormArray {
    return this.shareFormFilter.get('districts') as FormArray;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Event handler for share visibility change. It resets targeted users list,
   * targeted countries, states, districts and locations list.
   */
  clearShareFormTargetingFilters(event?: any): void {

    this.shareWith.emit(event);

    // Clear users list
    this.shareFormFilterUsers.reset();

    // Clear all other filters
    this.shareFormFilterProducts.clear();
    this.shareFormFilterBusinessTypes.clear();

    // Clear location filters
    this.shareFormFilterLocations.clear();
    this.shareFormFilterCountries.clear();
    this.shareFormFilterStates.clear();
    this.shareFormFilterDistricts.clear();
  }

  /**
   * Verify
   * @param attachments
   */
  verify(attachments) {
    this.verifyBulkUpload.emit(attachments);
  }

  /**
   * On uploading excel sheet
   */
  onUploadedExcel(isUploaded: boolean) {
    this.isExcelUploaded.emit(isUploaded);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
