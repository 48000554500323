import { Inject, Injector, Directive } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUploadService, KalgudiUsersService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaService, KalgudiSocialQA } from '@kalgudi/qa';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { AssistedShareQaRequest, KalgudiPageDetails, KalgudiUserBasicDetails, ShareQaFullDetails } from '@kalgudi/types';
import { timer } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';

@Directive()
export abstract class AssistedQa extends KalgudiSocialQA<AssistedShareQaRequest, ShareQaFullDetails> {

  pageId: string;
  pageTitle: string;

  assistedUser = new FormControl();

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService,
    protected kalgudiPageService: KalgudiPageService,
    protected kalgudiUsersService: KalgudiUsersService,
  ) {

    // Initialize parent
    super(
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3,
    );

    // Add a `assistantDetails` control to the share form
    this.shareForm.addControl('assistantDetails', new FormControl());
    this.shareForm.addControl('entityId', new FormControl(''));
    // this.shareForm.addControl('entityName', new FormControl(''));

    // Initialize page details
    this.initPageDetails();

    // Subscribe to assisted user details change
    this.subscribeToAssistedUserValueChanges();
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  private get shareFormAuthorId(): AbstractControl {
    return this.shareForm.get('authorId');
  }

  private get shareFormPageId(): AbstractControl {
    return this.shareForm.get('entityId');
  }

  // private get shareFormPageTitle(): AbstractControl {
  //   return this.shareForm.get('entityName');
  // }

  private get shareFormAssistantDetails(): AbstractControl {
    return this.shareForm.get('assistantDetails');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Resets kalgudi social sharing form.
   */
  resetForm(): void {

    // Perform actions from parent class
    super.resetForm();

    // Reset assistant details
    this.assistedUser.reset();
  }

  /**
   * Gets the program details and binding the details to he share form
   */
  private initPageDetails(): void {

    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),

              first(),

              tap(pageDetails => this.patchPageDetails(pageDetails))
            )
        )
      ).subscribe();
  }

  /**
   * Patch page details to the share form
   */
  private patchPageDetails(pageDetails: KalgudiPageDetails): void {

    this.pageId = pageDetails.pageId;
    this.pageTitle = pageDetails.pageTitle;

    // Patch page details to form
    this.shareFormPageId.patchValue(this.pageId);
    // this.shareFormPageTitle.patchValue(this.pageTitle);

    // Patch logged in user details to the assistant details
    this.shareFormAssistantDetails.patchValue(this.kalgudiUsersService.getBasicProfile(this.kalgudiApp.profileLocal));
  }

  /**
   * Subscribes to assisted user details value changes. On every change it updates the
   * author details of the share form.
   */
  private subscribeToAssistedUserValueChanges(): void {
    this.assistedUser.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
      ).subscribe(user => this.onAssistedUserChange(user));
  }

  /**
   * Action to perform on `assistedUser` form control value changes. It updates the
   * author details of the share form.
   */
  private onAssistedUserChange(userDetails: KalgudiUserBasicDetails): void {

    // Null check for userDetails fields
    const profileKey = userDetails && userDetails.profileKey ? userDetails.profileKey : '';

    this.shareFormAuthorId.patchValue(profileKey);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
