import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiFarmerCrops } from '../../../farmer-profile/classes/kalgudi-farmer-crops';

@Component({
  selector: 'kl-cooperative-society-crops',
  templateUrl: './cooperative-society-crops.component.html',
  styleUrls: ['./cooperative-society-crops.component.scss']
})
export class CooperativeSocietyCropsComponent extends KalgudiFarmerCrops implements OnInit {


  constructor(
    protected injector: Injector
  ) {

    super(injector);
  }

  ngOnInit() {
    if (this.openCropDialog) {
      this.showAddProductsDialog();
    }
  }

  onDestroyed(): void {}

  /**
   * Event handler on fetching crop success.
   */
  protected onCropFetchingSuccess(res: any): void {
    this.getProductsList(res);
  }

  /**
   * Event handler on fetching crop errors.
   */
  protected onCropError(err: Error): void {}

}
