<main id="survey-footer">
  <div fxLayout fxLayoutAlign="space-between center">

    <!-- Survey response -->
    <div>
      <p class="mb-0 text-center responded-count">
        <!-- <span>{{ surveyResponse?.memberCount ? surveyResponse?.responseCount : 'No' }} </span>
        <span *ngIf="surveyResponse?.memberCount"> / </span> -->
        <span>{{ surveyResponse?.memberCount | number }}</span>
        <span class="ml-1 mb-0 text-center" translate>Members</span>
      </p>
    </div>
    <!--/ Survey response -->

    <!-- Survey action button -->
    <div class="my-1">
      <kl-link-button class="fill-survey-btn" label="Fill survey" (clicked)="navigateToFullView()">
      </kl-link-button>
    </div>
    <!--/ Survey action button -->

  </div>
</main>
