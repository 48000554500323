import { Component, OnInit } from '@angular/core';
import { IAgGridCellRenderer } from '@kalgudi/ag-grid-lib/ag-grid';
import { KalgudiDestroyable } from '@kalgudi/core';

@Component({
  selector: 'kl-ag-grid-advisory-status',
  templateUrl: './ag-grid-advisory-status.component.html',
  styleUrls: ['./ag-grid-advisory-status.component.scss']
})
export class AgGridAdvisoryStatusComponent extends KalgudiDestroyable implements OnInit, IAgGridCellRenderer {


  public static componentId = 'AgGridAdvisoryStatusComponent';

  params: any;
  cell: any;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
  }

  agInit(params: any): void {
    this.params = params;
    this.cell = {
      row: params.value,
      col: params.colDef.headerName,
      rowIndex: params.rowIndex,
    };
    // console.log('params', this.params);
    // console.log('cell', this.cell);

  }

  refresh(): boolean {
    return false;
  }

  formatString(val: any): string {

    try {
      return val.type;
    } catch (e) {
      return 'ERROR';
    }
  }

  protected onDestroyed(): void { }

}
