<div class="kl-slide-toggle">

  <div fxLayout fxLayoutGap="10px" class="mb-2">
    <div (click)="toggleState(false)">
      <p class="mb-0 label cursor-pointer" [ngClass]="{'font-weight-bold': !checked}">{{ falseLabel | translate }}</p>
    </div>

    <div (click)="toggleState()" class="main-wrapper cursor-pointer" [title]="'Click to toggle the selection' | translate">
      <div class="rounded-pill toggle position-relative" [ngClass]="{'checked': checked}">
        <div class="rounded-circle toggle-circle position-absolute"  [@slideToggle]="{ value: checked }">
        </div>
      </div>
    </div>

    <div fxLayout fxLayoutGap="10px" (click)="toggleState(true)">
      <p class="mb-0 label cursor-pointer" [ngClass]="{'font-weight-bold': checked}">{{ trueLabel | translate }}</p>
    </div>
  </div>

</div>
