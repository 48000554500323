import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiProjectTask, ProjectStatus } from '@kalgudi/types';

@Component({
  selector: 'kl-task-actions',
  templateUrl: './task-actions.component.html',
  styleUrls: ['./task-actions.component.scss']
})
export class TaskActionsComponent implements OnInit {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Output()
  addTaskMembers = new EventEmitter();

  @Output()
  taskUpdate = new EventEmitter();

  @Output()
  taskDelete = new EventEmitter();

  @Output()
  stateUpdate = new EventEmitter();

  projectRoles = KALGUDI_PAGE_RELATION_MAP;

  taskStatus = [
    {
      id: ProjectStatus.COMPLETED,
      name: 'Completed',
      matIcon: 'check_circle_outline'
    },
    {
      id: ProjectStatus.IN_PROGRESS,
      name: 'In process',
      matIcon: 'slow_motion_video'
    },
    {
      id: ProjectStatus.NOT_STARTED,
      name: 'Not started',
      matIcon: 'trip_origin'
    },
  ];

  constructor() { }

  ngOnInit() {
  }

  /**
   * Add members to the task
   */
  showAddTaskMembersDialog() {
    this.addTaskMembers.emit();
  }

  /**
   * Updates task
   */
  updateTask() {
    this.taskUpdate.emit();
  }

  /**
   * Deletes task
   */
  deleteTask() {
    this.taskDelete.emit();
  }

  /**
   * On update state emit back to parent
   */
  updateState() {
    this.stateUpdate.emit();
  }

}
