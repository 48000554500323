import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiShareSearchMembersService } from '../../../../services/kalgudi-share-search-members.service';
import { KalgudiShareMembersPicker } from '../../classes/kalgudi-share-members-picker';

@Component({
  selector: 'kl-kalgudi-share-member-picker',
  templateUrl: './kalgudi-share-member-picker.component.html',
  styleUrls: ['./kalgudi-share-member-picker.component.scss']
})
export class KalgudiShareMemberPickerComponent extends KalgudiShareMembersPicker implements OnInit {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected api: KalgudiShareSearchMembersService,
  ) {

    // Wake up my parent
    super(notification, util, api);
  }

  ngOnInit() {

    // this.initStream();
  }

  protected onDestroyed(): void { }

}
