import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { KL_ROUTE_CONFIG, KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { GoogleSupportedLanguagesList, LanguageIdList } from '@kalgudi/third-party/google-language-translator';
import { ProgramShareUpdate, ShareUpdate } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';


import { KalgudiSocialRouteConfig } from '../../config';
import { KalgudiTranslationListService } from '../../services/kalgudi-translation-list.service';

@Component({
  selector: 'kl-post-tile-attachments',
  templateUrl: './post-tile-attachments.component.html',
  styleUrls: ['./post-tile-attachments.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostTileAttachmentsComponent extends KalgudiDestroyable implements OnInit, OnChanges {

  @Input()
  shareUpdate: ProgramShareUpdate;

  @Input()
  isFullviewPage: boolean;

  @Output()
  fullView = new EventEmitter();

  // Google language translations options
  translationsList: LanguageIdList = [];
  source: GoogleSupportedLanguagesList;
  showMore = false;
  limit: number;

  hours: number;
  minutes: number;


  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    private translationListService: KalgudiTranslationListService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
  ) {
    super();

    setTimeout(() => {
      if (this.shareUpdate?.noOfHours) {
        this.hours = Math.floor(this.shareUpdate?.noOfHours/60);
        this.minutes = Math.floor(this.shareUpdate?.noOfHours%60);
      }
    }, 500)
  }

  ngOnInit() {
    checkMobileDevice() ? this.limit = 110 : this.limit = 200;
  }

  /**
   * Called before any other lifecycle hook. Use it to inject dependencies,
   * but avoid any serious work here.
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.shareUpdate && this.shareUpdate) {
      this.initGoogleLangTranslator(this.shareUpdate);
    }
  }

  onDestroyed() { }

  /**
   * Event emit back to parent for share update full view
   */
  toFullview(): void {
    this.fullView.emit();
    // this.appRouting.toSocialShareUpdateFullview({ shareId });
  }

  /**
   * Initializes google language translator options
   */
  private initGoogleLangTranslator(shareUpdate: ShareUpdate): void {

    this.translationListService.initGoogleLangTranslator(shareUpdate.googleLocationShortCodes)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(list => {
        this.translationsList = list;
        this.source = shareUpdate.googleLocationShortCodes as GoogleSupportedLanguagesList;
      });
  }

  show() {
    this.showMore = true;
  }

}
