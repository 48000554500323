import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { floatTopAnimation, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiPageTaskList } from '../../classes/kalgudi-page-task-list';

@Component({
  selector: 'kl-page-tasks-list',
  templateUrl: './page-tasks-list.component.html',
  styleUrls: ['./page-tasks-list.component.scss'],
  animations: [floatTopAnimation]
})
export class PageTasksListComponent extends KalgudiPageTaskList implements OnInit {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialog: MatDialog,
    protected injector: Injector
  ) {

    super(notification, util, dialog, injector);
  }

  ngOnInit() {

    this.initStream();
  }

}
