<!-- <div class="home-task-tile-item-wrapper" *ngIf="(stream$ | async) as stream">
  <mat-card class="home-task-tile px-3 pt-2 pb-0" *ngIf="stream?.length">
    <div fxLayout class="pb-2" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="start center">
        <div>
          <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '20', 'height.px': '20'}"
            [applyCss]="true" class="mr-3 projects-icon"></svg-icon>
        </div>
        <div fxLayoutAlign="start center">
          <p class="m-0" translate>Tasks</p>
        </div>
      </div>

      <div>
        <div></div>
      </div>
    </div>
    <div class="border-bottom"></div>
    <div fxLayout="row wrap" fxLayout.xs="column">
      <div fxFlex="50%" fxFlex.xs="100%" class="home-task mb-2" *ngFor="let task of stream | slice: 0:4">
        <kl-task-tile
          enableActions= false
          (openFullview)="openTaskFullview(task?.title, task?.taskId, task)"
          [taskDetails]="task">
        </kl-task-tile>
      </div>
    </div>
    <div class="border-bottom"></div>
    <div fxLayout fxLayoutAlign="end end" class="px-3 py-2">
      <button mat-button color="primary" class="view-all mr-n3"
        (click)="openTaskListPage()" fxLayout fxLayoutAlign="center center">
        <div fxLayout fxLayoutAlign="center center">
          <div><span translate>View all</span>
            <span *ngIf="stream?.length > 4">View {{ stream?.length - 4}} more tasks</span>
          </div>
          <div class="view-icon"> <mat-icon>trending_flat</mat-icon></div>
        </div>
      </button>
    </div>
  </mat-card>
</div>

<ng-container *ngIf="paginator$ | async as paginator">
  <div class="home-task-tile-item-loading" *ngIf="paginator?.loading"></div>
</ng-container> -->

<div class="home-task-tile-item-wrapper">
  <!-- Farmers task tile -->
  <div *ngIf="farmersTasks?.length">
    <kl-farmers-task-tile
      [farmersTasks]="farmersTasks"
      [profileKey]="profileKey">
    </kl-farmers-task-tile>
  </div>
  <!--/ Farmers task tile -->

  <!-- Users current task tile -->
  <div *ngIf="userCurrentTasks?.length">
    <kl-users-task-tile
      [userCurrentTasks]="userCurrentTasks"
      [profileKey]="profileKey">
    </kl-users-task-tile>
  </div>
  <!--/ Users current task tile -->
</div>
