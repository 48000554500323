import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Inject, Injector, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { KalgudiDialogsService, KalgudiImagePickerService, StoreProductsSearchService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { checkMobileDevice, kalgudiAnimations, KalgudiAppService, KalgudiUtilityService, SHARE_TYPES } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { KalgudiShareWithService, SocialDataNormalizerService } from '@kalgudi/social';
import {
  FileMimeTypes,
  IdValueMap,
  KalgudiUserBasicDetails,
  LabelValueMap,
  TaskShareUpdate,
  TaskShareUpdateRequest,
} from '@kalgudi/types';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

import { kalgudiTaskPromotionalShareForm } from '../../classes/kalgudi-task-promotional-share-form';
import { KalgudiTaskActivityStateService } from '../../services/task-activity-state.service';

@Component({
  selector: 'kl-task-promotional-share-update-form',
  templateUrl: './task-promotional-share-update-form.component.html',
  styleUrls: ['./task-promotional-share-update-form.component.scss'],
  animations: kalgudiAnimations,
})
export class TaskPromotionalShareUpdateFormComponent extends kalgudiTaskPromotionalShareForm implements OnInit, OnChanges {

  @Input()
  aiPredictionsShare: boolean;

  @Output()
  postCreated = new EventEmitter<TaskShareUpdate>();

  searchTypeList: IdValueMap[] = [
    {id: 'https://kuber.farm', value: 'Kuber'},
    {id: 'https://inputs.kalgudi.com', value: 'Input store'},
    {id: 'https://outputs.kalgudi.com', value: 'Output store'},
    {id: 'https://andhragreens.com', value: 'Andhra greens'},
    {id: 'https://tgreens.in', value: 'Tgreens'},
    {id: 'https://emahila.org', value: 'Emahila'},
    {id: 'https://shaktimanfarmingsolutions.com', value: 'Shaktiman'},
    {id: 'https://prorisestore.com', value: 'Prorise store'},
    {id: 'https://marketplace.kalgudi.com', value: 'Unified store'},
    {id: 'https://kalgudi.com', value: 'Unified store'},
    {id: 'https://sikkimgreens.com', value: 'Swayam Sikkim greens store'},
  ]

  information: any;
  rowsCount: number;
  showAdvanced: boolean;
  isMobileDevice: boolean;
  createdPost: boolean;
  textLimit: number;

  readonly acceptedImageFileTypes: FileMimeTypes[] = [ FileMimeTypes.IMAGE ];
  readonly acceptedFileTypes: FileMimeTypes[] = [ FileMimeTypes.DOCUMENT ];
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [ FileMimeTypes.AUDIO ];
  readonly acceptedVideoFileTypes: FileMimeTypes[] = [ FileMimeTypes.VIDEO ];


  readonly shareVisibilityList: LabelValueMap[] = [
    SHARE_TYPES.ALL_SUBSCRIBERS,
    SHARE_TYPES.SPECIFIC_KALGUDI_USER,
    SHARE_TYPES.SPECIFIC_SUBSCRIBERS,
  ];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected kalgudiShareWithService: KalgudiShareWithService,
    protected sharePostService: KalgudiShareUpdateService,
    protected mobileMenuService: KalgudiBottomSheetService,
    private storeProductsSearchService: StoreProductsSearchService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    private bottomSheetService: KalgudiBottomSheetService,
    private stateService: KalgudiTaskActivityStateService

  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      kalgudiDialogService,
      util,
      socialDataNormalizer,
      kalgudiApp,
      kalgudiShareWithService,
      sharePostService,
      mobileMenuService
    );


    this.lstOfAttachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.lstOfAttachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));

    this.skuSelection.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.selectedSku = res;
      })


  }

  ngOnInit() {

    this.isMobileDevice = checkMobileDevice() ? true : false;

    this.rowsCount = checkMobileDevice() ? 2 : 2;

    this.textLimit = checkMobileDevice() ? 300 : 410;

    this.updateDateLocale();

    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }

    this.level2Id = this.shareForm.get('productsMetaData').value.productId;

    this.shareForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.level2Id = res && res.productsMetaData && res.productsMetaData.productId ? res.productsMetaData.productId : '';
          if(this.level2Id && res.productsMetaData && res.productsMetaData.productId && res.productsMetaData.productId !== this.level2Initialized) {
            this.getProductLevel2(this.level2Id, this.selectedStoreType);

          }
        }
      );

    this.shareFormMsgTypes.patchValue('PRODUCT');
  }

  ngOnChanges() {
    if(this.pageId) {
      this.getPageDetails(this.pageId);
    }
  }

  /**
   * Opens bottom sheet for description
   */
  openBottomSheet(description): void {

    this.information = {
      title: description,
      styleType: 'textContent'
    }

    this.bottomSheetService.openBottomSheet(this.information);
  }

  /**
   * Opens product description dialog
   */
  openProductDescriptionDialog() {
    this.isMobileDevice ? this.openBottomSheet(this.level2Details.description) : this.openPromotionalProductDescriptionDialog()
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  protected onDestroyed(): void { }

  /**
   * Translate and update date in locale
   */
  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  /**
   * Event handler called after the post is shared successfully.
   */
  onPostCreated(req: TaskShareUpdateRequest, res: TaskShareUpdate): void {
    this.postCreated.emit(res);
    this.stateService.taskActivityUpdated();
  }

}
