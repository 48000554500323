import { Location } from '@angular/common';
import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiMessageDetails, KalgudiQAChatListApi, ShareQaFullDetails } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { KalgudiQaService } from './services/kalgudi-qa.service';

@Directive()
export abstract class KalgudiSocialQaChatList extends KalgudiInboxStream<any> {

  @Input()
  questionId: string;

  questionData: ShareQaFullDetails;
  isAllowedToReply: boolean;

  latestMessage: KalgudiMessageDetails;
  replyMessage: KalgudiMessageDetails;

  messageProgress: boolean;

  private newMessageSubject = new Subject<KalgudiMessageDetails>();

  private qaService: KalgudiQaService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    protected location: Location
  ) {

    super(notification, util);

    this.qaService = this.injector.get(KalgudiQaService);

  }

  /**
   * Activated when the event is triggered by the child to reply to the message
   */
  onReplyMessage(msg: KalgudiMessageDetails): void {

    this.replyMessage = msg;
  }

  /**
   * Calls an api to send a reply
   */
  sendReply(formValue: any): void {

    this.messageProgress = true;

    this.qaService.postReply(this.questionId, formValue)
      .pipe(
        finalize(() => this.messageProgress = false)
      )
      .subscribe(
        res => this.replyHandler(res),
        err => this.util.errorHandler(err)
      );
  }

  /**
   * Calls an api to delete the reply
   */
  deleteReply(msg: KalgudiMessageDetails): void {

    this.notification.showSpinner();

    this.qaService.deleteReply(this.questionId, msg.messageId)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => {
          this.deleteReplyHandler(res);
          msg.hide = true;
        },
        err => this.util.errorHandler(err)
      );
  }

  /**
   * Calls an api to hide a message
   */
  hideMessage(msg: KalgudiMessageDetails): void {

    this.notification.showSpinner();

    this.qaService.hideMessage(this.questionId)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => {
          this.hideQuestionHandler();

        },
        err => this.util.errorHandler(err)
      );
  }

  /**
   * Calls after hiding the question successfully
   */
  protected hideQuestionHandler(): void {
    this.location.back();
  }

  protected deleteReplyHandler(res: boolean): void {}

  protected streamApi(): Observable<KalgudiStreamData> {

    return this.qaService.getQAChats(this.questionId)
      .pipe(
        tap((res: KalgudiQAChatListApi) => this.isAllowedToReply = res.isAllowedToReply)
      );
  }

  /**
   * Calls after successful reply
   */
  protected replyHandler(reply: KalgudiMessageDetails): void {
    this.latestMessage = reply;
    // this.unshiftToStream(reply);
    this.pushItem(reply);
  }

  /**
   * Gets the new item to be added in the stream
   */
  protected get newStreamItem$(): Observable<KalgudiMessageDetails> {
    return this.newMessageSubject;
  }

  /**
   * Inserts an item to the starting of the stream
   */
  private unshiftToStream(item: KalgudiMessageDetails): void {
    this.newMessageSubject.next(item);
  }

}
