<mat-card class="home-weather-tile cursor-pointer mt-2" [ngClass]="{'weather-tile-height': !todaysWeather}"
  @fadeIn>
  <div fxLayout="column" id="weather-tile-wrapper" (click)="opensWeatherPage()" *ngIf="weatherDetails && weatherDetails[0]?.hourly && todaysWeather">

    <div fxLayout.xs="row" fxLayoutAlign.xs="space-between center">
      <!-- Location -->
      <div fxLayoutAlign="center center" fxLayoutAlign.xs="start center"  fxLayout="column" fxLayout.xs="row" class="mt-2 todays-info">
        <h5 class="text-capitalize text-truncate text-white m-0">{{ location?.placeName }}</h5>
        <p class="text-capitalize week text-truncate text-white m-0">
          <span fxHide.gt-xs>,</span>
          <span class="mr-2">{{ todaysWeather?.date | date: 'dd'}}</span>
          <span translate>{{ todaysWeather?.date | date: 'MMMM'}}</span>
        </p>
      </div>
      <!-- /Location -->

      <!-- For mobile -->
      <div fxHide.gt-xs >
        <img fxLayoutAlign="end end" class="text-center current-weather"
          [src]="weatherCodes[todaysWeather?.hourly[0]?.weatherCode] || weatherCodes.default"
          width="60px" height="60px">
        <!-- <svg-icon [applyCss]="true" class="text-center current-weather"
          [src]="weatherCodes[todaysWeather?.hourly[0]?.weatherCode] || weatherCodes.default"
          [svgStyle]="{ 'width.px': '120' , 'height.px': '115' }"></svg-icon> -->
      </div>

    </div>


    <!-- Todays weather -->
    <div class="px-2 todays-weather" fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start start">
      <div fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutAlign.xs="start center">

        <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.xs="start start">
          <!-- <p class="mb-n1 text-white weather-type">
            <span translate>Today</span>
            <span> {{ weatherTime[todaysWeather?.hourly[0]?.time] }}</span>
          </p> -->

          <!-- For desktop -->
          <!-- <svg-icon fxHide.xs [applyCss]="true" class="text-center current-weather"
            [src]="weatherCodes[todaysWeather?.hourly[0]?.weatherCode] || weatherCodes.default"
            [svgStyle]="{ 'width.px': '120' , 'height.px': '115' }"></svg-icon> -->
          <img fxHide.xs class="text-center current-weather"
            [src]="weatherCodes[todaysWeather?.hourly[0]?.weatherCode] || weatherCodes.default"
            width="120px" height="110px">

          <div class="weather-info" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center center" fxLayoutAlign.xs="start start" fxLayoutGap.xs="8px">
            <p class="ml-0 mr-3 my-0 text-white degree">{{ todaysWeather?.hourly[0]?.tempC }}°C</p>
            <p class="m-0 weather-type text-center">{{ todaysWeather?.hourly[0]?.weatherDesc[0]?.value }}</p>
          </div>
        </div>
      </div>


      <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100 weather-changes">
        <div fxLayout="column" fxLayout.xs="row" class="mt-3" fxLayoutGap.xs="12px" fxLayoutAlign.xs="center center">
          <svg-icon [applyCss]="true" class="text-center current-weather mb-2"
            src="assets/icons/weather/wind.svg"
            [svgStyle]="{ 'width.px': 26, 'height.px': 26 }"></svg-icon>
          <p class="stats">
            <span class="mr-1" translate>Wind flow</span>
            <span>{{ todaysWeather?.hourly[0]?.windspeedKmph }} <span translate>kmph</span></span>
          </p>
        </div>
        <div fxLayout="column" fxLayout.xs="row" class="mt-3" fxLayoutGap.xs="12px" fxLayoutAlign.xs="center center">
          <svg-icon [applyCss]="true" class="text-center current-weather mb-2"
            src="assets/icons/weather/humidity.svg"
            [svgStyle]="{ 'width.px': 24, 'height.px': 26 }"></svg-icon>
          <p class="stats">
            <span class="mr-1" translate>Humidity</span>
            <span>{{ todaysWeather?.hourly[0]?.humidity }}%</span>
          </p>
        </div>
      </div>
    </div>
    <!-- /Todays weather -->

  </div>

  <kl-fake-it-block *ngIf="!todaysWeather"></kl-fake-it-block>
</mat-card>
