import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

import { MobileMenuBottomSheetComponent } from './mobile-menu-bottom-sheet.component';


@Injectable()
export class KalgudiBottomSheetService {
 constructor(
    private bottomSheet: MatBottomSheet,
    private location: LocationStrategy,
    private route: Router
  ) {}

  /**
   * Opens a BottomSheet with set of configurations. Generic method to open any
   * dialog.
   */
  openBottomSheet( data: any ) {

    return this.bottomSheet.open(MobileMenuBottomSheetComponent, {data});

  }

  /*
   * Disable back button on bottom sheet open
   */
  disableBackButton() {
     history.pushState(null, null, this.route.url);
     this.location.onPopState(() => {
        this.closeBottomSheet();
     });
  }

  /**
   * Close the bottom sheet
   * @returns
   */
  closeBottomSheet() {
    return this.bottomSheet.dismiss()
  }

}
