import { Injector, Input, Directive } from '@angular/core';
import { trimSpecialCharacters } from '@kalgudi/core';
import { ChartData, PageDaStats, UserWiseStats } from '@kalgudi/types';

import { KalgudiPageAnalyticsService } from '../services/page-analytics-activities.service';

@Directive()
export abstract class KalgudiPageTeamAnalytics {

  @Input()
  pageId: string;

  pageDaStats: PageDaStats;

  userWiseStatistics: UserWiseStats[];
  userStats: ChartData[];

  private pageAnalyticsService: KalgudiPageAnalyticsService;

  constructor(
    protected injector: Injector,
  ) {
    this.pageAnalyticsService = this.injector.get(KalgudiPageAnalyticsService);
  }

  /**
   * Calls api method to get Digital assist stats
   */
  fetchPageDaAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchDigitalAssistAnalytics(pageId)
      .subscribe(res => {
        this.pageDaStats = res.statistics;
        this.userWiseStatistics = res.userWiseStatistics;
        this.userStats = this.userWiseStats(this.userWiseStatistics);
      } );
  }

  /**
   * Initializes the page analytics activities
   */
  protected initStream() {
    this.fetchPageDaAnalytics(this.pageId);
  }

  /**
   * Maps the response into chart data model
   * @param userStats
   */
  private userWiseStats(userStats: UserWiseStats[]) {
    return userStats.map(user => {
      return {
        name: trimSpecialCharacters(user.firstName),
        value: user.count
      };
    });

  }
}
