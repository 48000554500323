import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Observable, timer } from 'rxjs';
import { mergeMapTo, share, switchMap, switchMapTo, tap } from 'rxjs/operators';

import { KalgudiFcmConfig, KL_FCM_CONFIG } from './config';

@Injectable({
  providedIn: 'root'
})
export class KalgudiFcmService {

  public notifications$: Observable<any>;

  private readonly localTokenKey = 'fcmToken';

  /**
   * `v2/auth/fcmtokens`
   */
  private readonly API_FCM = `${this.fcm.kalgudiApi}/auth/fcmtokens`;

  constructor(
    private afMessaging: AngularFireMessaging,
    @Inject(KL_FCM_CONFIG) private fcm: KalgudiFcmConfig,
    private httpClient: HttpClient,
    ) {

      this.notifications$ = this.afMessaging.messages.pipe(share());
    }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * FCM token stored in local storage
   */
  get token(): string {
    return localStorage.getItem(this.localTokenKey);
  }

  /**
   * `true` if the app is outputs store otherwise `false`.
   */
  get isOutputsStore(): boolean {
    return this.fcm.appId && this.fcm.appId === 'OUTPUTS';
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Initializes the FCM plugin
   */
  initFCM(profileKey: string): Observable<string> {


    if (this.token) {

      // Sync firebase token
      return timer(5000)
        .pipe(
          switchMapTo(this.syncTokenToApi(this.token, profileKey))
        );

    } else {


      // Generate new token and try to sync with API
      return timer(5000).pipe(

        // Try generating new token
        switchMapTo(this.requestToken()),

        // Sync the token

        switchMap(token => this.syncTokenToApi(token, profileKey))
      );


    }

  }

  /**
   * Makes a request to fetch FCM token.
   */
  requestToken(): Observable<string> {

    console.log('Generating FCM token');

    // Request permission from FCM plugin
    return this.afMessaging.requestPermission
      .pipe(

        // Return the latest token
        mergeMapTo(this.afMessaging.tokenChanges),

        // Set the token to localStorage
        tap(token => this.setToken(token))
      );
  }

  /**
   * Sync token with the API.
   */
  syncTokenToApi(token: string, profileKey: string): Observable<any> {

    const payload = {
      token,
      profileKey,
      appId : this.fcm.appId
    };

    // console.log('Syncing token', this.fcm.kalgudiApi);

    const url = `${this.fcm.kalgudiApi}/auth/fcmtokens`;

    console.log('Loaded fcm service');

    if(this.isOutputsStore) return;

    // Call the API to sync the token
    return this.httpClient.post(url, payload);
  }

  /**
   * Delete token from the API.
   */
  deleteTokenFromApi(token: string, profileKey: string): Observable<any> {

    const payload = {
      token,
      profileKey,
      appId: this.fcm.appId,
      isForDelete: true
    };

    const url = `${this.fcm.kalgudiApi}/auth/fcmtokens`;

    // Call the API to delete the token
    return this.httpClient.post(url, payload);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * Sets token to localStorage.
   */
  private setToken(val: string): void {
    localStorage.setItem(this.localTokenKey, val);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
