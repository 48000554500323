<div class="task-tile pb-2 pt-0 cursor-pointer" fxLayout="column">

  <!-- Task details -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxFlex="80%">
      <!-- Task tile title -->
      <div [matTooltip]="taskDetails?.title">
        <p class="mb-0 title text-capitalize text-truncate"><b>{{ taskDetails?.title | translate }}</b></p>
      </div>
      <!--/ Task tile title -->

      <!-- Task tile time frame -->
      <div>
        <p *ngIf=" taskDetails?.timeFrame?.start !== taskDetails?.timeFrame?.end"
          class="mb-0 text-truncate text-muted date">
            {{ taskDetails?.timeFrame?.start | date:'dd' }}
            {{ taskDetails?.timeFrame?.start | date:'MMMM' | translate }},
            {{ taskDetails?.timeFrame?.start | date:'yy' }} -
            {{ taskDetails?.timeFrame?.end | date:'dd' }}
            {{ taskDetails?.timeFrame?.end | date:'MMMM' | translate }},
            {{ taskDetails?.timeFrame?.start | date:'yy' }}
        </p>
        <p *ngIf=" taskDetails?.timeFrame?.start === taskDetails?.timeFrame?.end"
          class="mb-0 text-truncate text-muted date">
            {{ taskDetails?.timeFrame?.start | date:'dd' }}
            {{ taskDetails?.timeFrame?.start | date:'MMMM' | translate }}
            {{ taskDetails?.timeFrame?.start | date:'yyyy' }}
        </p>
      </div>
      <!--/ Task tile time frame -->
    </div>

    <!-- Task tile full view button -->
    <div class="mt-2 mr-n2" fxLayoutAlign="end end" (click)="openTaskMembersPage('COMPLETED')" fxFlex="20%">
      <button mat-icon-button>
        <mat-icon class="arrow-icon">arrow_forward</mat-icon>
      </button>
    </div>
    <!--/ Task tile full view button -->
  </div>

  <!-- Project title -->
  <div>
    <p class="mb-0 text-muted proj-tile text-capitalize text-truncate" *ngIf="taskDetails?.projectTitle" [ngClass]="{'no-data': !taskDetails?.projectTitle }"
    >{{ taskDetails?.projectTitle | translate}}</p>
  </div>
  <!--/ Project title -->

  <!-- Task tile stats bar -->
  <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="showStats && taskDetails?.userRole === 'MEMBER'" [ngClass]="{'no-members': !taskDetails?.statistics?.members?.total}">
    <div fxLayout fxLayoutAlign="center center">
      <span class="mr-2">
        <mat-icon class="text-muted group-icon">group</mat-icon>
      </span>
      <span [matTooltip]="'Users count' | translate" class="text-muted" *ngIf="taskDetails?.statistics?.members?.total">
        {{ taskDetails?.statistics?.members?.total }}
      </span>
      <span class="text-muted" *ngIf="!taskDetails?.statistics?.members?.total" translate>
        0</span>
    </div>

    <div fxLayout class="position-relative stats" [ngClass]="{'no-members': !taskDetails?.statistics?.members?.total}">
      <div *ngFor="let submission of submissions" class="ml-2">
        <div (click)="openTaskStatsDialog(submission?.id); $event.stopPropagation()">
          <span class="dot d-inline-block align-middle mx-1" [ngStyle]="{'background-color': submission?.color}">
          </span>
          <span class="state">{{ submission?.value }}</span>
        </div>
      </div>

      <div fxLayout="column" class="stats-hover d-none position-absolute mt-4 px-2">
        <div class="triangle ml-5"></div>
        <div *ngFor="let submission of submissions" fxLayoutAlign="center center" class="separator py-1">
          <div (click)="openTaskStatsDialog(submission?.id); $event.stopPropagation()" [ngClass]="{'stat-type': !enableActions}">
            <span class="text-white title"> {{ submission?.title | translate }}</span>
            <span class="text-white value">- {{ submission?.value }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/ Task tile stats bar -->

  <!-- Stats bar progress menu -->
  <div *ngIf="taskDetails?.userRole === 'MEMBER' && showStats" fxLayout class="progress">
    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.completed/ taskDetails?.statistics?.submissions?.total * 100 )+'%'}"
      class="completed" [matTooltip]="taskDetails?.statistics?.submissions?.completed + ' - ' + ('Completed' | translate)">
    </div>

    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.in_progress/ taskDetails?.statistics?.submissions?.total * 100)+'%'}"
      class="in-progress" [matTooltip]="taskDetails?.statistics?.submissions?.in_progress + ' - ' + ('In progress' | translate)">
    </div>

    <div [ngStyle]="{'width': (taskDetails?.statistics?.submissions?.not_started/ taskDetails?.statistics?.submissions?.total * 100)+'%'}"
      class="not-started" [matTooltip]="taskDetails?.statistics?.submissions?.not_started + ' - ' + ('Not started' | translate)">
    </div>
  </div>
  <!--/ Stats bar progress menu -->

  <!-- /Task details -->

  <!-- Task status -->
  <div *ngIf="taskDetails?.userRole !== 'MEMBER'" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="mb-1">
    <div fxFlex="33.3%" (click)="openTaskMembersPage('COMPLETED'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-completed" fxLayoutAlign="center center">
      <span translate>Completed</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.completed : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>

    <div fxFlex="33.3%" (click)="openTaskMembersPage('IN_PROGRESS'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-inProgress" fxLayoutAlign="center center">
      <span translate>Inprogress</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.in_progress : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>

    <div fxFlex="33.3%" (click)="openTaskMembersPage('NOT_STARTED'); $event.stopPropagation($event);" class="text-primary task-type text-center-align task-notStarted" fxLayoutAlign="center center">
      <span translate>Not started</span>
      <span> - {{ this.taskDetails.statistics ? this.taskDetails.statistics.submissions.not_started : 0 }}</span>
      <mat-icon class="arrow-image">call_made</mat-icon>
    </div>
  </div>
  <!-- /Task status -->

</div>
