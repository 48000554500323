import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FPOServices } from '@kalgudi/types';

@Component({
  selector: 'kl-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit {

  @Input()
  serviceLicense: FPOServices[];

  @Input()
  editable: boolean;

  @Output()
  removeServicesLicense = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to remove services
   */
  removeServices(index: number) {
    this.removeServicesLicense.emit(index);
  }
}
