import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ProductPickerModule } from '../product-picker/product-picker.module';
import {
  BaseProductAutocompleteComponent,
} from './components/base-product-autocomplete/base-product-autocomplete.component';
import {
  ProductVarietyAutocompleteComponent,
} from './components/product-variety-autocomplete/product-variety-autocomplete.component';
import { KalgudiProductsPickerComponent } from './kalgudi-products-picker.component';
import { BaseProductAutocompleteApiService } from './services/base-product-autocomplete-api.service';
import { BaseProductAutocompleteService } from './services/base-product-autocomplete.service';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    KalgudiProductsPickerComponent,
    BaseProductAutocompleteComponent,
    ProductVarietyAutocompleteComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    // Material module
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,

    FlexLayoutModule,

    ProductPickerModule,
    TranslateModule
  ],
  exports: [
    KalgudiProductsPickerComponent,
    BaseProductAutocompleteComponent,
    ProductVarietyAutocompleteComponent
  ],
  providers: [
    BaseProductAutocompleteService,
    BaseProductAutocompleteApiService,
  ]
})
export class KalgudiProductsPickerModule { }
