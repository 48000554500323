<main id="task-status-update">

  <div>
    <h3 class="status-heading" translate>{{ taskDetails?.state | titlecase | trimSpecialCharacters }}</h3>
  </div>

  <form autocomplete="off" [formGroup]="statusForm">

    <div fxLayout fxLayoutGap="10px" fxLayout.xs="column">

      <mat-form-field fxFlex="calc(50% - 10px)" appearance="outline">
        <mat-label translate>Select status</mat-label>
        <mat-select placeholder="Select status" formControlName="state">
          <mat-option *ngFor="let taskState of  taskStatus" [value]="taskState?.id">
            {{ taskState?.name }}
          </mat-option>
        </mat-select>
        <mat-error translate>Please select status</mat-error>
      </mat-form-field>

      <!-- Date -->
      <mat-form-field fxFlex="calc(50% - 10px)" appearance="outline">
        <mat-label translate>Date</mat-label>

          <input matInput placeholder="Date"
            formControlName="stateChangedOn"
            (click)="date.open()"
            [matDatepicker]="date"
            [max]="todaysDate"
            readonly/>

          <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
          <mat-datepicker #date></mat-datepicker>
      </mat-form-field>
      <!--/ Date -->
    </div>

    <div fxLayout fxLayoutAlign="end center">
      <kl-flat-button
        label="Submit"
        (clicked)="updateStatus()"
        [progress]="progress"
        [disabled]="!statusForm.valid">
      </kl-flat-button>
    </div>
  </form>
</main>
