import { Inject, Injector, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiProjectListService } from '../../project-list/services/kalgudi-project-list.service';

@Directive()
export abstract class RecentProjects extends KalgudiInboxStream<KalgudiProject> {

  projectsList: KalgudiProject[];

  private projectsListService: KalgudiProjectListService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
    ) {

    super(notification, util);

    this.projectsListService  = this.injector.get<KalgudiProjectListService>(KalgudiProjectListService);

    this.pageLimit = 5;


  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.projectsListService.getProjects(offset, limit);
  }

}
