import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { floatTopAnimation } from '@kalgudi/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'kl-survey-info-form',
  templateUrl: './survey-info-form.component.html',
  styleUrls: ['./survey-info-form.component.scss'],
  animations: [floatTopAnimation]
})
export class SurveyInfoFormComponent implements OnInit {

  @Input()
  surveyForm: FormGroup;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    ) { }

  ngOnInit() {
    this.updateDateLocale();
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
