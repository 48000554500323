import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiSurveyDetails } from '@kalgudi/types';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { KalgudiCreateSurvey } from '../../classes/create-survey';

@Component({
  selector: 'kl-create-survey',
  templateUrl: './create-survey.component.html',
  styleUrls: ['./create-survey.component.scss'],
  animations: kalgudiAnimations
})
export class CreateSurveyComponent extends KalgudiCreateSurvey implements OnInit {

  @Output()
  surveyCreated = new EventEmitter<KalgudiSurveyDetails>();

  constructor(
    protected injector: Injector,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(injector);
  }

  ngOnInit() {
    this.updateDateLocale();
  }

  onDestroyed(): void {}

  /**
   * Called after the successful survey creation
   */
  protected onSurveyCreation(res: KalgudiSurveyDetails): void {
    this.surveyCreated.emit(res);
  }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }
}
