import { Inject, Injector, Directive } from '@angular/core';
import { KalgudiUsersPickerBase } from '@kalgudi/common';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { StringAnyMap } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiSurveyMembersService } from '../../../services/kalgudi-survey-members.service';
import { SurveyStateService } from '../../../services/survey-state.service';

@Directive()
export abstract class KalgudiSurveysMembersSearch extends KalgudiUsersPickerBase {

  // Dependencies
  protected membersService: KalgudiSurveyMembersService;
  protected surveyState: SurveyStateService;

  readonly maxPageLimit = 10;

  constructor (
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    // Manually inject dependencies
    this.membersService = this.injector.get(KalgudiSurveyMembersService);
    this.surveyState    = this.injector.get(SurveyStateService);

    this.pageLimit = this.maxPageLimit;
    this.defaultSearchKeyword = '';
  }

  /**
   * Implement this method to define your search Api.
   */
  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
  ): Observable<KalgudiUserBasicSearchResults> {

    return this.membersService.searchMembers(this.surveyState.id, searchKeyword, offset, limit, extraParams);
  }
}
