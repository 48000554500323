<!-- Section for content -->
<div id="upload-image" class="text-center mt-2" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutGap.xs="0"
  fxLayoutAlign.xs="center center">

  <!-- Add photo -->
  <div [ngClass]="{'w-100': !attachments.length, 'mw-100': !attachments?.length}" class="wrapper-img d-inline-block">
    <div class="wrapper-add w-100" *ngIf="maxImages > attachments?.length "
      [ngClass]="{'w-100': !attachments?.length, 'center-align': !attachments.length}" fxLayout="row"
      fxLayoutAlign="center center">

      <div class="add-btn cursor-pointer position-relative w-100" fxLayout="column" fxLayoutAlign="center center"
        [ngClass]="{'w-100': !attachments?.length}">

        <input class="overflow-hidden position-absolute attach-img cursor-pointer w-100" [multiple]="multiple"
          type="file" [accept]="acceptFileTypes" (change)="onFileSelection($event)">

        <button mat-mini-fab class="btn btn-primary" color="primary" class="outline-none">
          <mat-icon class="add-icon text-white">add</mat-icon>
        </button>

        <p class="mt-2" fxHide.xs>Click here to attach documents</p>
        <p class="mt-2" fxHide.gt-xs>Attach documents</p>
      </div>
    </div>
  </div>
  <!-- / Add photo -->

  <!-- Wrapper for image section -->
  <div class="wrapper-img position-relative d-inline-block" *ngFor="let item of attachments; let i = index;">

    <button color="warn" class="clr-btn positioning position-absolute text-center" mat-mini-fab (click)="remove(i)">
      <mat-icon class="remove-icon text-white">remove</mat-icon>
    </button>

    <div class="border image-textarea position-relative"
      [style.background-image]="'url(assets/images/document.png)'">
    </div>

  </div>
  <!-- Wrapper for image section -->

</div>
<!-- /Section for content -->
