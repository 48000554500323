import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KL_NOTIFICATION, KalgudiNotification } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiRobocall, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { ScheduledRobocallStreamService } from '../../services/scheduled-robocall-stream.service';
import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'kl-scheduled-robocall-stream',
  templateUrl: './scheduled-robocall-stream.component.html',
  styleUrls: ['./scheduled-robocall-stream.component.scss']
})
export class ScheduledRobocallStreamComponent extends KalgudiInboxStream<KalgudiRobocall> implements OnInit {

  @Input()
  filters: PartialData = {};

  @Input()
  overRideUpdatePost: (postDetails: KalgudiRobocall) => Observable<KalgudiRobocall>;

  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;



  readonly maxPageLimit = 20;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    private robocallStreamService: ScheduledRobocallStreamService,
    private kalgudiProgram: KalgudiPageService
  ) {
    super(notification, util);

    this.kalgudiProgram.pageDetails$
    .pipe(
      first(),
    ).subscribe(pageDetails => {
      this.filters.pageId = pageDetails.pageId;
      this.memberRole = pageDetails.memberRole;
    });
   }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

  streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.robocallStreamService.fetchScheduledRobocallStream(offset, limit, this.filters);

  }

}
