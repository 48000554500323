import { Component, forwardRef, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ActivityVisibility } from './activity-visibility';


// Page tags NG_VALUE_ACCESSOR provider for custom form control
const ACTIVITY_VISIBILITY_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ActivityVisibilityComponent),
  multi: true,
};

@Component({
  selector: 'kl-activity-visibility',
  templateUrl: './activity-visibility.component.html',
  styleUrls: ['./activity-visibility.component.scss'],
  providers: [ ACTIVITY_VISIBILITY_FORM_CONTROL_PROVIDER ]
})
export class ActivityVisibilityComponent extends ActivityVisibility implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
