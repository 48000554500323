import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PageFilter } from '../../../constants';

/**
 * State definition for page list
 */
@Injectable()
export class PageListStateService {

  /**
   * Defines current active page type
   */
  private activePageTypeSubject = new BehaviorSubject<PageFilter>(null);

  /**
   * Gets, the current active page type
   */
  readonly pageTypeChange$: Observable<PageFilter>;

  constructor() {

    this.pageTypeChange$ = this.activePageTypeSubject.asObservable()
      .pipe(
        filter(val => val !== null)
      )
  }

  updatePageType(filter: PageFilter): void {

    this.activePageTypeSubject.next(filter);
  }

}
