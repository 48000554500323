import { Component, Input, OnInit } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';
import { ChartData } from '@kalgudi/types';

@Component({
  selector: 'kl-user-assistants-stats',
  templateUrl: './user-assistants-stats.component.html',
  styleUrls: ['./user-assistants-stats.component.scss']
})
export class UserAssistantsStatsComponent implements OnInit {

  @Input()
  userStats: ChartData[];

  view: any[];

  // options
  gradient: boolean = false;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = 'right';

  colorScheme = {
    domain: ['#fb8c00', '#00796b', '#ff5722', '#795548', '#ff9800', '#43a047', '#cddc39', '#0097a7', '#0288d1', '#283593']
  };

  constructor() { }

  ngOnInit() {

    this.view = checkMobileDevice() ? [350, 350] : [450, 450];
  }

}
