import { Inject, Pipe, PipeTransform } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AvailableDomains, DevAvailableDomains } from '@kalgudi/types';
import { KalgudiUtilityService } from '../services/kalgudi-util.service';



@Pipe({
  name: 'storeLink'
})
export class StoreLinkPipe implements PipeTransform {

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    protected util: KalgudiUtilityService,
  ) {

  }

  transform(value: any, ...args: any[]): any {

    const productDetails = value.productsMetaData;
    let storeName = '';
    let storePage = '';

    if(productDetails && productDetails.length > 0) {

      if (productDetails[0].stores && productDetails[0].stores[0]) {

        if (productDetails[0].stores[0] === 'KUBER' || productDetails[0].stores[0] === 'https://kuber.farm') {

          storeName = this.env.production ? AvailableDomains['INPUTS'] : DevAvailableDomains['INPUTS']
        } else {
          storeName =   this.env.production ? AvailableDomains[productDetails[0].stores[0]]: DevAvailableDomains[productDetails[0].stores[0]]
        }

        } else {

          if(productDetails[0].storeType && productDetails[0].storeType !== 'KUBER') {

            storeName = this.env.production ? AvailableDomains[productDetails[0].storeType] : DevAvailableDomains[productDetails[0].storeType]
          }
      }
    } else {
      storeName = this.env.domain === 'https://core.kalgudi.com' || !this.env.production ? this.env.farmerStoreDomain : this.env.storeDomain
    }

    const productKey = this.util.encodeUri(productDetails[0].productName_level2).toLowerCase();

    storePage = `${storeName}/p/c/${productKey}/${productDetails[0].productId_level2}_${productDetails[0].productId_level3}`
    return storePage;

  }

}
