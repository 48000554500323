import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { StatsTablePage, StatsTablePageService } from '@kalgudi/ag-grid-lib/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { AgGridOptions, ApiResponseCommon, PageStatsData, PartialData, StatsCardType, StoreType } from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SatelliteAdvisoriesAllUsersService implements StatsTablePage {

  /**
   * /v2/pages/:pageId/satellite-advisories/sync/:syncId
   */
  private readonly API_BASE = `${this.env.restBaseUrlV2}/pages/:pageId/satellite-advisories/sync/:syncId`;

  /**
   * /v2/pages/:pageId/satellite-advisories/sync/:syncId/stats
   */
  private readonly API_BASE_STATS = `${this.API_BASE}/stats`;

  /**
   * /v2/pages/:pageId/satellite-advisories/sync/:syncId/:profileKey
   */
  private readonly API_SATELLITE_ADVISORY = `${this.env.restBaseUrlV2}/pages/:pageId/satellite-advisories/sync/:syncId/:profileKey`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private statsPageService: StatsTablePageService,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  fetchPageStats(
    storeType: StoreType,
    params: PartialData = {}
  ): Observable<PageStatsData> {
    params = {
      ...params,
      mode: 'ag_grid',
      offset: '0',
      limit: '10'
    };

    return of({
      results: [
        {
          clickAction: true,
          filter: { filter: 'ALL' },
          key: 'ALL',
          title: 'All',
          total: 1,
        },
      ],
      selectedIndex: 0,
      viewType: StatsCardType.CHIP
    });
  }

  fetchPageList(
    storeType: StoreType,
    params: PartialData = {}
  ): Observable<AgGridOptions> {
    params = {
      ...params,
      mode: 'ag_grid',
    };

    return this.statsPageService.fetchList(
      this.API_BASE.replace(':pageId', params.pageId).replace(':syncId', params.syncId), storeType, params);
  }


  /**
   * Api to get satellite advisories
   */
  getSatellite(pageId: string, syncId: string, profileKey: string, landId: string): Observable<any> {

    const params = {
      landId,
    };

    return this.httpClient
      .get<ApiResponseCommon>(
        this.API_SATELLITE_ADVISORY.replace(':pageId', pageId).replace(':syncId', syncId).replace(':profileKey', profileKey), {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data.data)
      );
  }
}
