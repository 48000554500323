import { Component, Input, OnInit } from '@angular/core';
import { Attachment, KalgudiUserBasicDetails, StoreProductLevel2, StoreProductLevel3 } from '@kalgudi/types';

@Component({
  selector: 'kl-procurement-details',
  templateUrl: './procurement-details.component.html',
  styleUrls: ['./procurement-details.component.scss']
})
export class ProcurementDetailsComponent implements OnInit {

  @Input()
  procurementDetails: any;

  @Input()
  level2ProductDetails: StoreProductLevel2;

  @Input()
  procurementAttachments: Attachment[];

  @Input()
  level3ProductDetails: StoreProductLevel3;

  @Input()
  supplierDetails;

  @Input()
  sellerProfileInfo: KalgudiUserBasicDetails;

  traceabilityDetails = {
    "name": 'Procurement',
    "image": 'assets/images/procurement.png',
    "navTitle": 'Journey of your procurements',

    commonDetails : {
      breadCrumb: 'Reseller',
      location: 'Guntur, Andhra pradesh'
    }
  };

  constructor() { }

  ngOnInit() {
  }

}
