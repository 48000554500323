import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { fadeInAnimation, floatTopAnimation } from '@kalgudi/core';

import { KalgudiProfileLogsListTable } from '../../classes/kalgudi-profile-logs-list-table';

@Component({
  selector: 'kl-profile-logs-list-table',
  templateUrl: './profile-logs-list-table.component.html',
  styleUrls: ['./profile-logs-list-table.component.scss'],
  animations: [floatTopAnimation, fadeInAnimation]
})
export class ProfileLogsListTableComponent extends KalgudiProfileLogsListTable implements OnInit {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initTableStream(this.matPaginator, this.matSort);
  }

  onDestroyed(): void {}
}
