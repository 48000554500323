import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiProgramDetails, PageType, ProgramPageDetails, SelectableUIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiCreatePageApiService } from './kalgudi-create-page-api.service';

@Injectable()
export class KalgudiCreatePageService {

  constructor(
    private api: KalgudiCreatePageApiService,
    private util: KalgudiUtilityService,
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig
  ) { }


  /**
   * Calls API to create page.
   */
  createPage(req: ProgramPageDetails, pageType: PageType): Observable<KalgudiProgramDetails> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<ProgramPageDetails>(req);

    return this.api.createPage(payload, pageType);
  }

  /**
   * Gets, page types from local assets
   */
  getPageTypes(): Observable<SelectableUIMenu[]> {

    return  this.util.fetchJsonFromUrl<SelectableUIMenu[]>('assets/json/page-creation-types.json')
      .pipe(
        map(res => {
          res.forEach((item, index) => {
            if (item.id === 'STOCK' || item.id === 'PROCESSING') {
              res[index].hide = this.environment.appId === 'SHAKTIMAN' ? true : false;
            }
          });

          return res;
        }),

      );
  }
}
