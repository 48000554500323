import { EventEmitter, Input, OnDestroy, Output, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiFormValidators } from '@kalgudi/core';
import { Observable, Subject } from 'rxjs';

@Directive()
export abstract class KalgudiCommonPageCreation implements OnDestroy {


  @Input()
  pageForm: FormGroup;

  @Output()
  pageFormChange = new EventEmitter<FormGroup>();


  pageCommonForm: FormGroup;

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  private destroyed = new Subject<any>();

  constructor() {
    this.pageCommonForm = this.commonForm;
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the Page title from Page form
   */
  get pageTitleField(): AbstractControl {
    return this.pageForm.get('pageTitle');
  }

  /**
   * Gets, the Page description from page form
   */
  get pageDescriptionField(): AbstractControl {
    return this.pageForm.get('pageDescription');
  }

  /**
   * Gets, the Mobile number from Page form
   */
  get mobileNoField(): AbstractControl {
    return this.pageForm.get('mobileNo');
  }

  /**
   * Gets, the Email Address from Page form
   */
  get emailIdField(): AbstractControl {
    return this.pageForm.get('emailId');
  }

  /**
   * common form group
   */
  private get commonForm(): FormGroup {

    return new FormGroup({
      pageTitle: new FormControl('', Validators.required),
      // pageProfilePic: new FormControl(''),
      pageDescription: new FormControl(''),
      address: new FormControl(''),
      // website: new FormControl(''),
      // wikipediaLink: new FormControl(''),
      // facebookLink: new FormControl(''),
      // linkedInLink: new FormControl(''),
      // twitterLink: new FormControl(''),
      // foundedYear: new FormControl(''),
      // founders: new FormControl(''),
      mobileNo: new FormControl('', KalgudiFormValidators.mobileValidators),
      emailId: new FormControl('', KalgudiFormValidators.emailValidators),
      locationDetails: new FormControl({ }),

      // locationDetails: new FormGroup({
      //   districtId: new FormControl(''),
      //   districtName: new FormControl('Ranga Reddy'),
      //   stateId: new FormControl(''),
      //   stateName: new FormControl('Telangana'),
      //   countryId: new FormControl(''),
      //   countryName: new FormControl('India'),
      //   countryShortName: new FormControl('IN'),
      //   postalCode: new FormControl('500019'),
      //   latitude: new FormControl('17.4756941'),
      //   longitude: new FormControl('78.3117733'),
      //   locationLong: new FormControl('Vasudhaika Kutumbam, Komatikunta, Nallagandla, Serilingampally, Hyderabad, Telangana, India'),
      //   locationShort: new FormControl('Telangana IN'),
      // })
    });

  }

  /**
   * Observable must be emitted after the component gets destroyed.
   * It acts as a flag for all other observable to unsubscribe.
   */
  get destroyed$(): Observable<any> {
    return this.destroyed;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {

    this.destroyed.next();
    this.destroyed.complete();

    this.onDestroyed();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


   // --------------------------------------------------------
  // #region Abstract classes to be inherited by children
  // --------------------------------------------------------

  /**
   * Called once, before the instance is destroyed. Make sure you
   * release resources that you don't require. This method is called
   * by angular implementation of `ngOnDestroy()`.
   */
  protected abstract onDestroyed(): void;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Initializes the page form
   */
  protected initPageForm() {

    const form = this.pageCommonForm;

    form.patchValue(this.pageForm.value);

    this.pageForm = form;

    this.pageFormChange.emit(this.pageForm);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
