import { Injector, Input, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAutocomplete } from '@kalgudi/core';
import { BasePageDetails, PageType } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';

@Directive()
export abstract class KalgudiPageAutocomplete extends KalgudiAutocomplete<any> {

  @Input()
  pageType: PageType;

  private autoCompleteApi: KalgudiPageService;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
  ) {

    super(fb);

    this.autoCompleteApi = this.injector.get(KalgudiPageService);

    this.placeholder = 'Search and select page';
    this.label       = 'Search and select page';

    // Initialize the autoCompleteForm
    this.autoCompleteForm = this.newAutoCompleteFormGroup;

    // Construct the prediction list fetching stream
    this.initAutocomplete();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------


  /**
   * Gets, the auto complete input form field
   */
  get inputField(): AbstractControl {
    return this.autoCompleteForm.get('pageTitle');
  }

  /**
   * Creates a new auto complete form group.
   */
  private get newAutoCompleteFormGroup(): FormGroup {

    return this.fb.group({
      pageId: [''],
      pageTitle: [''],
      product: this.fb.group({
        productId: [''],
        productName: [''],
        baseCategory: this.fb.group({
          id: [''],
          value: ['']
        })
      })
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * You must add this method reference to tell the auto-complete how to handle
   * the object display.
   *
   * @usage
   * ```html
   * <mat-autocomplete [displayWith]="displayPageName">
   * ```
   */
  displayWithFn(value: BasePageDetails): any {
    return value && value.pageTitle ? value.pageTitle : value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Defines api to call on autocomplete input change.
   */
  protected streamApi(searchKeyword: string): Observable<BasePageDetails[]> {

    return this.autoCompleteApi.searchPages(searchKeyword, 'autocomplete', this.pageType)
      .pipe(
        map(res => res.results)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}

