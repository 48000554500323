import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { KalgudiFcmConfig, KL_FCM_CONFIG } from './config';
import { NotificationComponent } from './notification/notification.component';

/**
 * The fcm module must be included in the root module i.e. `AppModule`.
 */
@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    AngularFireModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,

    // material
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    NotificationComponent
  ]
})
export class KalgudiFcmModule {
  static forRoot(config: KalgudiFcmConfig): ModuleWithProviders<KalgudiFcmModule> {
    return {
      ngModule: KalgudiFcmModule,
      providers: [
        {
          provide: KL_FCM_CONFIG,
          useValue: config,
        }
      ]
    };
  }
}
