import { Directive, Injector } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileActionService } from '../../../services/kalgudi-profile-action.service';
import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiLivestock extends KalgudiDestroyable {

  progress: boolean;

  livestockList: any[] = [];

  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;
  private profileActionService: KalgudiProfileActionService;

  constructor(
    protected injector: Injector
  ) {
    super();

    this.kalgudiFarmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.profileActionService = this.injector.get(KalgudiProfileActionService)
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Get the live stock
   */
  getLiveStock(): void {
    this.progress = true;

    this.kalgudiFarmerProfileService.getLiveStock()
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(res => {

        Object.keys(res).forEach(p => {

          this.livestockList.push({title: p, items: res[p]})
        });
      })
  }

  /**
   * Open add live stock form
   */
  openAddLiveStockForm(stockType: any) {
    this.profileActionService.showLiveStockDialogForm(stockType);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
