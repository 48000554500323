import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiUsersPickerDialogConfig } from '@kalgudi/types';

import { KalgudiUsersPickerDialog } from '../../../kalgudi-users/classes/kalgudi-user-picker-dialog';

@Component({
  selector: 'kl-users-picker-dialog',
  templateUrl: './users-picker-dialog.component.html',
  styleUrls: ['./users-picker-dialog.component.scss']
})
export class UsersPickerDialogComponent extends KalgudiUsersPickerDialog<UsersPickerDialogComponent> implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiUsersPickerDialogConfig,
    protected dialogRef: MatDialogRef<UsersPickerDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);
  }

  ngOnInit() { }
}
