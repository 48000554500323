<main id="calendar-summery-form" class="position-relative w-100 form">
  <div class="container">

    <mat-card class="p-0">

      <div class="p-3">

        <!-- Header -->
        <div fxLayoutAlign="space-between">
          <h6 class="m-0 font-12">
            AI predictions
          </h6>

          <div fxLayout="row" fxLayoutGap="8px">
            <!-- Refresh  -->
            <!-- <button mat-button class="px-1 refresh-button" (click)="refresh()">
              <span class="refresh-text">Refresh</span>
              <svg-icon src="assets/svgs/refresh.svg" [svgStyle]="{'width.px': '18', 'height.px': '18'}"
              [applyCss]="true" class="ml-2"></svg-icon>
            </button> -->

            <button mat-button class="px-1 filter-button" (click)="showCalendarForm()">
              <span>Filter</span>
              <svg-icon src="assets/svgs/filter-icon.svg" [svgStyle]="{'width.px': '20', 'height.px': '20'}"
              [applyCss]="true" class="ml-2"></svg-icon>
            </button>

            <button mat-icon-button class="px-1 close-button" *ngIf="isShowCalendarForm" (click)="closeCalendarForm()">
              <mat-icon>close</mat-icon>
            </button>

          </div>
        </div>


        <form autocomplete="off" [formGroup]="filterFormGroup" *ngIf="isShowCalendarForm" class="mt-2">
          <ng-container *ngIf="(memberTargetingFilters$ | async) as filters">

            <div class="mb-2">
              <kl-ai-filters
                label="Crops"
                searchPlaceholder="Search and select crop"
                [formArray]="productsFilters"
                [resetField]="resetFields"
                [prefilledData]="prefilledFilterFormData?.products"
                [items]="filters?.products">
              </kl-ai-filters>
            </div>

            <!-- Business types filter -->
            <div class="mb-2">
              <kl-ai-filters
                label="User role"
                searchPlaceholder="Search and select user role"
                [formArray]="businessTypesFilters"
                [resetField]="resetFields"
                [prefilledData]="prefilledFilterFormData?.businessTypes"
                [items]="filters?.businessTypes">
              </kl-ai-filters>
            </div>
            <!-- / Business types filter -->

            <div fxLayout fxLayoutGap="10px" class="mb-2">
              <kl-slide-toggle
                falseLabel="Location"
                trueLabel="Select location range"
                [checked]="filterFormGroup?.get('states')?.value ? true : false"
                (toggleChanged)="onLocationRangeFilterChange($event);">
              </kl-slide-toggle>
            </div>

            <!-- Locations filter -->
            <div *ngIf="!locationRangeFilter" class="mb-2">
              <kl-ai-filters
                label="Locations"
                searchPlaceholder="Search and select location"
                [resetField]="resetFields"
                [prefilledData]="prefilledFilterFormData?.locations"
                [formArray]="locationsFilters"
                [items]="filters?.locations">
              </kl-ai-filters>
            </div>
            <!-- / Locations filter -->

            <!-- Location range filter -->
            <div *ngIf="locationRangeFilter" fxLayout="row" fxLayoutGap="10px" class="mb-3">
              <div fxFlex="calc(50% - 10px)">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                  <mat-label translate>State</mat-label>

                  <mat-select appearance="outline" formControlName="states">
                    <mat-option *ngFor="let item of stateList" [value]="item?.id">
                      {{ item?.value | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxFlex="calc(50% - 10px)">
                <mat-form-field appearance="outline" color="accent" class="w-100">
                  <mat-label translate>District</mat-label>

                  <mat-select appearance="outline" formControlName="districts" [disabled]="!filterFormGroup?.get('states')?.value"
                    >
                    <mat-option *ngFor="let item of districtList" [value]="item?.id">
                      {{ item?.value | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!-- /Location range filter -->

          </ng-container>

          <div fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button color="warn"
            (click)="resetFilterFields()">Clear</button>
            <button mat-raised-button color="primary"
              (click)="submitAiPredictionFilter()"
              [disabled]="!filterFormGroup.valid">Apply</button>
          </div>
        </form>
      </div>
    </mat-card>

  </div>

</main>
