import { Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { KalgudiDetailedTaskCreationForm } from '../../classes/detailed-task-creation';


@Component({
  selector: 'kl-detailed-task-form',
  templateUrl: './detailed-task-form.component.html',
  styleUrls: ['./detailed-task-form.component.scss']
})
export class DetailedTaskFormComponent extends KalgudiDetailedTaskCreationForm implements OnInit, OnChanges {

  constructor(
    protected injector: Injector,
  ) {

    super(injector);

    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.initTaskForm();

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.templateSchema && !this.isFormlyFormInitialized) {
      this.initFormlyForm();
    }

    if (changes.detailedFormData && this.detailedFormData) {
      this.patchUpdatedValueToForm(this.detailedFormData);
    }
  }

  onDestroyed(): void {}
}
