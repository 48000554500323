<section id="survey-list" class="p-0">

  <div fxLayout="row wrap" fxLayoutGap="16px grid" fxLayout.xs="column">
    <div *ngFor="let survey of surveyList; let i = index" [fxFlex]="tileFxFlex" fxFlex.xs="100%">
      <div class="cursor-pointer" [@floatTop]="{ value: '', params: {delay: i * 20}}">
        <kl-survey-list-item
          [surveyDetails]="survey"
          (click)="onSurveySelection(survey)"
          (cloneSurveyDetailsAccepted)="cloneSurveyDetailsAccepted($event)">
        </kl-survey-list-item>
      </div>
    </div>
  </div>

</section>
