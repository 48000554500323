import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';

import { GeoLocationMarkerComponent } from './components/geo-location-marker/geo-location-marker.component';
import { GeoLocationComponent } from './components/geo-location/geo-location.component';
import { GeoMultiLocationsComponent } from './components/geo-multi-locations/geo-multi-locations.component';
import { GeoTagComponent } from './components/geo-tag/geo-tag.component';
import { GOOGLE_API_KEY } from './config';
import { GoogleGeoLocationService } from './services/google-geo-location.service';


// Marks as google geo location
window['google_geo'] = true;

/**
 * The lazy loader module must be included in the root module i.e. `AppModule`.
 */
@NgModule({
  declarations: [
    GeoLocationMarkerComponent,
    GeoTagComponent,
    GeoLocationComponent,
    GeoMultiLocationsComponent,
  ],
  imports: [
    // Common module
    CommonModule,

    FormsModule,
    FlexLayoutModule,

    AgmCoreModule,

    MatIconModule,
    MatButtonModule,

    KalgudiGooglePlacesModule,
  ],
  exports: [
    GeoLocationMarkerComponent,
    GeoTagComponent,
    GeoLocationComponent,
    GeoMultiLocationsComponent
  ],
  providers: [
    GoogleGeoLocationService,
  ],
})
export class KalgudiGoogleGeoLocationModule {

  static forRoot(apiKey: string): ModuleWithProviders<KalgudiGoogleGeoLocationModule> {

    return {
      ngModule: KalgudiGoogleGeoLocationModule,
      providers: [
        {
          provide: GOOGLE_API_KEY,
          useValue: apiKey,
        },
        ...AgmCoreModule.forRoot({
          apiKey: apiKey,
          libraries: ['places', 'drawing', 'geometry']
        })
        .providers,
      ]
    };
  }

  static forChild(): ModuleWithProviders<KalgudiGoogleGeoLocationModule> {

    return {
      ngModule: KalgudiGoogleGeoLocationModule,
      providers: [
        // {
        //   provide: GOOGLE_API_KEY,
        //   useValue: apiKey,
        // },
        // ...AgmCoreModule.forRoot({
        //   apiKey: apiKey,
        //   libraries: ['places', 'drawing', 'geometry']
        // })
        // .providers,
      ]
    };
  }
}
