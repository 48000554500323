import { Pipe, PipeTransform } from '@angular/core';
import { Attachment, AttachmentType } from '@kalgudi/types';

@Pipe({
  name: 'attachmentTypeCount'
})
export class AttachmentTypeCountPipe implements PipeTransform {

  transform(value: Attachment[], type: AttachmentType) {
    return value.filter(v => v.msgType === type).length;
  }

}
