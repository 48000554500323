<section>
  <div>
    <div class="value-update-tiles">

      <table class="table mt-3">
        <thead>
          <tr>
            <th translate>{{titleText}}</th>
            <th translate>{{descriptionText}}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of items">
            <td>{{item[itemTitleFieldName]}}</td>
            <td class="description-field">{{item[itemDescriptionFieldName]}}</td>

            <!-- Action buttons -->
            <td class="buttons">
              <div class="action-buttons">
                <ng-container *ngIf="editable">
                  <button mat-icon-button class="edit-icon" [title]="'Update details' | translate" (click)="editDetails(item)">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button mat-icon-button class="delete-icon" [title]="'Delete' | translate" (click)="deleteItem(item)">
                    <mat-icon class="far fa-trash-alt"></mat-icon>
                  </button>
                </ng-container>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </div>
</section>
