import { Component, Injector, OnInit } from '@angular/core';
import { fadeInAnimation, scaleOutAnimation, slideRightAnimation } from '@kalgudi/core';
import { KALGUDI_PAGE_RELATION_MAP, TaskShareUpdate } from '@kalgudi/types';

import { KalgudiPageTaskFullView } from '../../classes/kalgudi-page-task-fullview';

@Component({
  selector: 'kl-page-task-fullview-page',
  templateUrl: './page-task-fullview-page.component.html',
  styleUrls: ['./page-task-fullview-page.component.scss'],
  animations: [scaleOutAnimation, slideRightAnimation, fadeInAnimation]
})
export class PageTaskFullviewPageComponent extends KalgudiPageTaskFullView implements OnInit {

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;


  activeTabIndex: number;

  readonly TABS = {
    INFO: { index: 0, title: 'Task info' },
    UPDATES: { index: 1, title: 'Activity logs' },
    DETAILS: { index: 2, title: 'Task submission' },
    SUBMISSIONS: { index: 2, title: 'Submissions'}
  };


  constructor(
    protected injector: Injector
  ) {

    super(injector);

    this.activeTabIndex = this.TABS.INFO.index;
  }

  ngOnInit() {
    this.init();
  }


  onPostCreated(res: TaskShareUpdate) {}
}
