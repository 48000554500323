import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { StorePageDialogComponent } from './components/store-page/store-page-dialog/store-page-dialog.component';
import { StorePageComponent } from './components/store-page/store-page.component';
import { StoreRoutingModule } from './store-routing.module';



@NgModule({
  declarations: [StorePageComponent, StorePageDialogComponent],
  imports: [
    CommonModule,

    MatButtonModule,
    MatIconModule,

    // Flex Module
    FlexLayoutModule,

    TranslateModule,

    //Routing module
    StoreRoutingModule,
  ],
  exports: [
    StorePageDialogComponent
  ],
  entryComponents: [StorePageDialogComponent]
})
export class StoreModule { }
