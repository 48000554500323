import { Component, Injector, OnInit } from '@angular/core';

import { FarmerCertificates } from '../../classes/farmer-certificates';

@Component({
  selector: 'kl-new-farmer-certificates',
  templateUrl: './new-farmer-certificates.component.html',
  styleUrls: ['./new-farmer-certificates.component.scss']
})
export class NewFarmerCertificatesComponent extends FarmerCertificates implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
