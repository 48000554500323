import { Component, forwardRef, Inject, Injector, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { AgGridStatsTablePage, StatsTablePage } from '@kalgudi/ag-grid-lib/common';
import { KalgudiDialogsService } from '@kalgudi/common';
import { fadeInAnimation, KalgudiUtilityService, slideRightAnimation } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../../../../config';
import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { SatelliteAdvisoriesPreviousSyncsService } from '../../services/satellite-advisories-previous-syncs.service';
import { SatelliteAdvisoriesSyncService } from '../../services/satellite-advisories-sync.service';

@Component({
  selector: 'kl-satellite-advisories-ag-grid',
  templateUrl: './satellite-advisories-ag-grid.component.html',
  styleUrls: ['./satellite-advisories-ag-grid.component.scss'],
  providers: [
    {
      provide: StatsTablePage,
      useExisting: forwardRef(() => SatelliteAdvisoriesPreviousSyncsService)
    }
  ],
  animations: [ fadeInAnimation, slideRightAnimation ]
})
export class SatelliteAdvisoriesAgGridComponent extends AgGridStatsTablePage implements OnInit {

  @Input()
  pageId: string;

  get statsParams(): PartialData {

    return {
      pageId: this.pageId
    };
  }

  get listParams(): PartialData {

    return {
      pageId: this.pageId,
    };
  }

  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private klUtil: KalgudiUtilityService,
    private satelliteAdvisoriesService: SatelliteAdvisoriesSyncService,
    private kalgudiPageService: KalgudiPageService,
    private dialogsService: KalgudiDialogsService
  ) {

    super(injector);

    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.memberRole = pageDetails.memberRole;
      });

  }

  ngOnInit() {
    this.initPage();
  }

  /**
   * Calls api method to sync records
   */
  syncRecords() {
    this.showDeleteConfirmationDialog()
      .pipe(
        tap(res => this.notification.showSpinner()),

        switchMap(res => this.satelliteAdvisoriesService.syncRecords(this.pageId)),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.notification.showMessage('Your request being processed, please check back later!'),
        err => this.klUtil.showApiErrorMessage(err),
      );
  }


  /**
   * Click event handler for row
   */
  onRowClicked(row: any): void {

    this.appRouting.toPageSatelliteAdvisoriesAllUsers( { pageId: this.pageId, syncId: row.data.syncId });
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm',
      acceptButtonTitle: 'Sync',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure to sync satellite advisories for all farmers. It may take little time to process',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }


}

