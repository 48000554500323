import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiShareTargetMembers } from '../../classes/kalgudi-share-target-members';

@Component({
  selector: 'kl-share-target-members',
  templateUrl: './share-target-members.component.html',
  styleUrls: ['./share-target-members.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareTargetMembersComponent extends KalgudiShareTargetMembers implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit() {
  }

}
