import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogResult, KalgudiInputDialogConfig } from '@kalgudi/types';

import { KalgudiDialog } from '../../classes/kalgudi-dialog';

@Component({
  selector: 'kl-input-dialog',
  templateUrl: './input-dialog.component.html',
  styleUrls: ['./input-dialog.component.scss']
})
export class InputDialogComponent extends KalgudiDialog<InputDialogComponent> implements OnInit {


  inputFormGroup: FormGroup;
  limit: number;
  showMore: boolean;

  constructor(
    protected dialogRef: MatDialogRef<InputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiInputDialogConfig
  ) {

    super(dialogRef);
  }

  ngOnInit() {

    this.inputFormGroup = new FormGroup({
      inputText: new FormControl(this.data.inputText || '')
    });
    this.limit = checkMobileDevice() ? 110 : 200;
  }

  /**
   * Share text field form control
   */
  get formInputText(): AbstractControl {
    return this.inputFormGroup.get('inputText');
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };

    this.closeDialog(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        text: this.inputFormGroup.value.inputText,
      }
    };

    this.closeDialog(result);
  }

  /**
   * show-more function for show-more button
   */
  show() {
    this.showMore = true;
  }

}
