import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { CHART_GRADIENT_COLORS } from '@kalgudi/common';
import { KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { ChartData, KalgudiChartItem, SelectableUIMenu } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { KalgudiProjectCreationService } from '../../../project-creation/services/kalgudi-project-creation.service';


@Component({
  selector: 'kl-all-projects-stats',
  templateUrl: './all-projects-stats.component.html',
  styleUrls: ['./all-projects-stats.component.scss']
})
export class AllProjectsStatsComponent extends KalgudiDestroyable implements OnInit, OnChanges {


  @Input()
  projectCategories: SelectableUIMenu[];

  chartStats: ChartData[];

  statsList: KalgudiChartItem[];

  view: any[] = [250, 250];

  // options
  gradient = true;
  showLegend = false;
  showLabels = false;
  isDoughnut = true;
  legendPosition = 'below';

  colorScheme = {
    domain: CHART_GRADIENT_COLORS,
  };

  chipsList: KalgudiChartItem[];

  @Input()
  stats;

  @Input()
  chartData;

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private projectCreationService: KalgudiProjectCreationService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
  ) {

      super();
    }

  ngOnInit() {
  }

  ngOnChanges(): void {

    if (this.chartData) {

      this.chartStats = [
        {
          name: 'Completed',
          value: this.chartData.completed
        },
        {
          name: 'In progress',
          value: this.chartData.pending
        },
        {
          name: 'Not started',
          value: this.chartData.not_started
        }
      ];

      this.chipsList = [
        {
          title: 'Completed',
          value: this.chartData.completed,
          textAlign: 'center',
          bgColor: '#35E298',
          color: '#fff'
        },
        {
          title: 'In progress',
          value: this.chartData.pending,
          textAlign: 'center',
          bgColor: '#F9C200',
          color: '#fff'
        },
        {
          title: 'Not started',
          value: this.chartData.not_started,
          textAlign: 'center',
          bgColor: '#4BA9FF',
          color: '#fff'
        },
      ];
    }

    if (this.stats) {

      this.statsList = [
        {
          title: 'Crop calenders',
          value: this.stats.crop_calender,
          textAlign: 'left',
          color: '#5D7482'
        },
        // {
        //   title: 'Generic',
        //   value: this.stats.generic,
        //   textAlign: 'left',
        //   color: '#5D7482'
        // },
        // {
        //   title: 'Roadmap creation',
        //   value: this.stats.roadmap_creation,
        //   textAlign: 'left',
        //   color: '#5D7482'
        // },
        // {
        //   title: 'Software development',
        //   value: this.stats.software_development,
        //   textAlign: 'left',
        //   color: '#5D7482'
        // },
      ];
    }
  }


  /**
   * Opens the project creation dialog
   */
  openCreateProjectDialog() {

    this.projectCreationService.openCreateProjectDialog(this.projectCategories)
      .subscribe(
        res => {
          this.notification.showMessage('Project created');

          this.appRouting.toProject( { projectId: res.id }  , {type: 'GRID'});
          // this.appRouting.toProjectList();
        }
      );
    }

  protected onDestroyed(): void { }
}
