import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { ManageProjectMembers } from '../../classes/manage-project-members';

@Component({
  selector: 'kl-manage-project-members',
  templateUrl: './manage-project-members.component.html',
  styleUrls: ['./manage-project-members.component.scss']
})
export class ManageProjectMembersComponent extends ManageProjectMembers implements OnInit {

  constructor(
   protected injector: Injector,
   @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
   protected util: KalgudiUtilityService
  ) {
   super(injector, notification, util);

  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}


}
