import { Component, Inject, OnInit } from '@angular/core';
import {
  checkMobileDevice,
  KalgudiInboxStream,
  KalgudiStreamData,
  KalgudiUtilityService,
  scaleOutAnimation,
  slideRightAnimation,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiNetworksService } from '../../services/kalgudi-networks.service';

@Component({
  selector: 'kl-profile-views',
  templateUrl: './profile-views.component.html',
  styleUrls: ['./profile-views.component.scss'],
  animations: [scaleOutAnimation, slideRightAnimation]
})
export class ProfileViewsComponent extends KalgudiInboxStream<KalgudiUser> implements OnInit {

  isMobileDevice = false;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    private networkService: KalgudiNetworksService
  ) {
    super(notification, util);
  }

  ngOnInit() {
    this.initStream();

    this.isMobileDevice = checkMobileDevice();
  }

  onDestroyed(): void {}

  /**
   * Implement this method to define your stream Api.
   *
   * @override
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.networkService.fetchNetworkList('0', '10');
  }

}
