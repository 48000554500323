import { Component, forwardRef, Injector, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { KalgudiSurveyMembersPickerFormControl } from '../../classes/kalgudi-survey-members-picker-form-control';

const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SurveysMembersPickerComponent),
  multi: true,
};

@Component({
  selector: 'kl-surveys-members-picker',
  templateUrl: './surveys-members-picker.component.html',
  styleUrls: ['./surveys-members-picker.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class SurveysMembersPickerComponent extends KalgudiSurveyMembersPickerFormControl implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
