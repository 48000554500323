import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { SelectTemplate } from '../../classes/select-template';

@Component({
  selector: 'kl-select-template',
  templateUrl: './select-template.component.html',
  styleUrls: ['./select-template.component.scss']
})
export class SelectTemplateComponent extends SelectTemplate implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService

  ) {

    super(injector, notification, util);

  }

  ngOnInit() {

  }

  onDestroyed(): void {}


}
