<div id="phosphorus-tile" class="nitrogen-details">

  <div>

    <section class="px-3 pt-3">
      <div fxLayout fxLayoutGap="10px">
        <svg-icon [applyCss]="true" class="text-center"
          src="assets/svgs/ph-ni.svg" [svgStyle]="{ 'width.px': 25, 'height.px': 25 }"></svg-icon>
        <p class="details mb-1">
          Nitrogen level in kilograms per feddan, estimated on
          <span>{{ estimatedTime | parseDate | date: 'dd MMM yyyy' }}</span>
        </p>
      </div>

    </section>
  </div>

  <section class="px-3" *ngIf="nitrogenDetails?.dynamicPOP">
    <kl-reccomendations-common [recommendations]="nitrogenDetails?.dynamicPOP"></kl-reccomendations-common>
  </section>

  <section class="px-3 pt-2">

    <div #satImg class="position-relative">
      <img [src]="nitrogenImage" width="100%" height="281">

      <div class="position-absolute land-details px-2" fxLayout="column" fxLayoutAlign="center center"
        *ngIf="status" title="Kilograms per Feddan">
        <div *ngIf="status === 'good'" title="Good">
          <mat-icon class="thumb-up">thumb_up</mat-icon>
        </div>
        <div *ngIf="status === 'low'" title="Low">
          <mat-icon class="thumb-down">arrow_downward</mat-icon>
        </div>
        <div *ngIf="status === 'more'" title="More">
          <mat-icon class="thumb-down">arrow_upward</mat-icon>
        </div>
        <div class="land-size" fxLayout="column" fxLayoutAlign="center center">
          <p class="m-0">{{ nitrogenDetails?.averageLevel  | massAreaUnitConversion: 'feddan' | number }}</p>
          <!-- <p class="m-0">{{ nitrogenDetails?.concentrationQuality || 'NA' }}</p> -->
        </div>
      </div>

      <svg class="boundary position-absolute" fill-opacity="0" [attr.width]="satImgRef?.nativeElement?.offsetWidth" [attr.height]="satImgRef?.nativeElement?.offsetHeight">
        <polygon stroke="black" stroke-width="2" [attr.points]="boundaryPoints"></polygon>
      </svg>
    </div>
  </section>
</div>
