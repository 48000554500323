import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { kalgudiAnimations, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProject, PartialData } from '@kalgudi/types';

import { KalgudiProjectsListGrid } from '../../classes/projects-list-grid';

@Component({
  selector: 'kl-projects-list-grid',
  templateUrl: './projects-list-grid.component.html',
  styleUrls: ['./projects-list-grid.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectsListGridComponent extends KalgudiProjectsListGrid implements OnInit {

  @Input()
  overrideDefaultRouteNavigation: <T extends KalgudiProject>(project: T) => void;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector
  ) {

    super(notification, util, injector);
  }

  ngOnInit() {

    this.initStream();

  }

  onDestroyed(): void {}


  /**
   * Extra filters to get the projects
   */
  get filters(): PartialData {
    return {};
  }
}
