import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ProfileBasicEditModule } from '../profile-basic-edit/profile-basic-edit.module';
import { EmployeeAchievementsComponent } from './components/employee-achievements/employee-achievements.component';
import { EmployeeCertificatesComponent } from './components/employee-certificates/employee-certificates.component';
import { EmployeeInfoComponent } from './components/employee-info/employee-info.component';
import { EmployeeProfileComponent } from './components/employee-profile/employee-profile.component';
import { EmployeeProfileApiService } from './services/employee-profile-api.service';
import { EmployeeProfileService } from './services/employee-profile.service';
import { TranslateModule } from '@ngx-translate/core';
import { KalgudiCommonModule, KalgudiNoContentModule } from '@kalgudi/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    EmployeeProfileComponent,
    EmployeeInfoComponent,
    EmployeeCertificatesComponent,
    EmployeeAchievementsComponent,
  ],
  imports: [
    CommonModule,

    // Forms modules
    FormsModule,
    ReactiveFormsModule,

    // Flex layout module
    FlexLayoutModule,

    AngularSvgIconModule,

    // Material module
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDividerModule,

    // Kalgudi modules
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiGooglePlacesModule,
    KalgudiNoContentModule,
    KalgudiCommonModule,

    // Other profile modules
    ProfileBasicEditModule,

    TranslateModule
  ],
  exports: [
    EmployeeInfoComponent,
    EmployeeProfileComponent
  ],
  providers: [
    EmployeeProfileService,
    EmployeeProfileApiService,
  ]
})
export class KalgudiEmployeeProfileModule { }
