import { Component, forwardRef, Input, OnInit, Provider, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { KalgudiUtilityService } from '@kalgudi/core';
import { SelectableChip, SelectableChipList } from '@kalgudi/types';



// NG_VALUE_ACCESSOR provider for custom form control
const FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectableChipComponent),
  multi: true,
};


@Component({
  selector: 'kl-selectable-chip',
  templateUrl: './selectable-chip.component.html',
  styleUrls: ['./selectable-chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ FORM_CONTROL_PROVIDER ]
})
export class SelectableChipComponent implements OnInit, ControlValueAccessor {

  @Input()
  items: SelectableChipList = [];

  @Input()
  label: string;

  @Input()
  color: 'primary' | 'accent' | 'warn' | 'default' = 'primary';

  @Input()
  disabled = false;

  @Input()
  mapEntireObject = false;

  selectedItemsIds: string[] = [];

  constructor(
    private util: KalgudiUtilityService,
  ) { }

  ngOnInit() { }



  // --------------------------------------------------------
  // #region Control Value accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.selectedItemsIds = this.util.clone(obj);
    this.mapFormControlValueToSelectedChip(this.selectedItemsIds);
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Toggle mat-chip selection state. It also updates the form control
   */
  toggleSelection(item: SelectableChip): void {
    item.selected = !item.selected;

    // Update form control
    const mappedVal = this.mapSelectedItemsIds();
    this.updateFormControlValue(mappedVal);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Maps the selected property of chip items if it contains in the list of selected
   * item ids.
   */
  private mapFormControlValueToSelectedChip(selectedIds: string[] = []): void {

    selectedIds = Array.isArray(selectedIds) ? selectedIds : [];

    this.items.forEach(item => item.selected = selectedIds.includes(item.id));
  }

  /**
   * Maps ids of the selected items to selectedItemIds.
   */
  private mapSelectedItemsIds(): string[] {
    this.selectedItemsIds = this.items
      .filter(item => item.selected)        // Filter all selected items from the list
      .map(item => item.id);                // Map their ids

    return this.selectedItemsIds;
  }

  /**
   * Updates form control value
   */
  private updateFormControlValue(val): void {
    this.onChange(val);
    this.onTouched();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
