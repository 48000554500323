import { KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiMobileDialogRef } from './mobile-dialog-overlay-ref';


/**
 * Base class for all Kalgudi mobile dialog. A dialog will either emit data back
 * its owner or simply close the dialog without emitting any data back to
 * the parent.
 *
 * All dialogs created must extend to this class.
 *
 * @author Pankaj Prakash
 */
export abstract class KalgudiMobileDialog {

  constructor(
    protected dialogRef: KalgudiMobileDialogRef
  ) { }

  /**
   * It must call the `closeDialog()` method passing the dialog
   * result.
   */
  abstract ok(): void;

  /**
   * It must call the `closeDialog()` method passing the dialog
   * result.
   */
  abstract cancel(): void;

  /**
   * Closes the dialog emitting out the
   */
  closeDialog(dialogResult: KalgudiDialogResult): void {
    this.dialogRef.close(dialogResult);
  }
}
