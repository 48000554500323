import { Component, Inject, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { collapsibleMenuAnimation, KALGUDI_S3_POLICY_MAP, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CkEditorUploadAdapter } from '../../third-party/ck-editor-upload-adapter';

import { KalgudiCommonTaskCreationForm } from '../../classes/common-task-creation';

@Component({
  selector: 'kl-common-task-form',
  templateUrl: './common-task-form.component.html',
  styleUrls: ['./common-task-form.component.scss'],
  animations: [collapsibleMenuAnimation]
})
export class CommonTaskFormComponent extends KalgudiCommonTaskCreationForm implements OnInit, OnChanges {

  public Editor = DecoupledEditor;
  isKalgudiOrFponetApp: boolean = false;

  tagsList = [
    {
      id: 'INFO',
      value: 'Information'
    },
    {
      id: 'INPUT',
      value: 'Input'
    },
    {
      id: 'OUTPUT',
      value: 'Output'
    },
    // {
    //   id: 'SERVICES',
    //   value: 'Services'
    // },
  ]

  needTypeList = [
    {id: 'Seeds' , value: 'Seeds'},
	  {id: 'Fertilizers', value: 'Fertilizers'},
	  {id: 'Pesticides', value: 'Pesticides'},
	  {id: 'Machinery', value: 'Machinery'},
	  {id: 'Post harvest management', value: 'Post harvest management'},
	  {id: 'Bio fertilizers', value: 'Bio fertilizers'},
    {id: 'Services', value: 'Services'},
  ]

  unitList = ["grams", "milliliters", "liters", "kgs", "tons", "quintals", "pieces"];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(injector, notification, util);

    this.isKalgudiOrFponetApp = this.env?.appId === 'FPO_NET_APP' || this.env?.appId === 'FPO_APP';
  }

  ngOnInit() {
    this.initTaskForm();

    this.updateDateLocale();
    this.valueChangesForFromDate();
  }

  /**
   *
   */
  ngOnChanges(changes: SimpleChanges): void {

    if (this.templateSchema && !this.isFormlyInitialized) {
      this.initFormlyCommonTaskForm();
    }
  }

  onDestroyed(): void {}


  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

  onTagSelected(event: any){

    this.selectedTagsList = {
      INFO: false,
      INPUT: false,
      OUTPUT: false,
      // SERVICES: false
    }

    event.value.forEach(element => {
      this.selectedTagsList[element] = true
    });
  }

  /**
   * Toggles info fields
   */
  toggleInfo() {
    this.showInfo = !this.showInfo;
  }

  /**
   * Toggle input fields
   */
  toggleInput() {
    this.showInput = !this.showInput;
  }

  /**
   * Toggle output fields
   */
  toggleOutput() {
    this.showOutput = !this.showOutput;
  }

  /**
   * Toggle services fields
   */
  toggleService() {
    this.showService = !this.showService;
  }

  /**
   * OnReady for ckEditor
   * @param editor
   */
  onReady(editor) {

    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CkEditorUploadAdapter(loader, KALGUDI_S3_POLICY_MAP.DEFAULT, this.injector);
    };

    editor.ui.view.editable.element.parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.view.editable.element
    );
  }

}
