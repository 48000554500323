import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class ShareUpdateResolverService {

  constructor(
    private shareUpdateService: KalgudiShareUpdateService,
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): ShareUpdate | Observable<ShareUpdate> | Promise<ShareUpdate> {

    const shareId = route.params.shareId;


    return this.shareUpdateService.getShareUpdate(shareId)
      .pipe(

        // Take only first response
        take(1),
      );
  }
}
