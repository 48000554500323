import { BasePageDetails } from './pages.model';
import { ApiResponseCommon } from './rest-api.model';
import { KalgudiUserBasicDetails, KalgudiUserShortProfile } from './user.model';

/**
 * Credit for either User or Page
 */
export interface Credit {

  /**
   * Either profileKey or entityId
   */
  authorId: string;
  availableCredits: number;
  creditType: CreditType;

  domesticSms: boolean;
  internationalSms: boolean;
  domesticVoiceCall: boolean;
  internationalVoiceCall: boolean;

  //Old settings unused flags
  smsConnectRequest?: boolean;
  smsPosts?: boolean;
  smsMyPool?: boolean;
  smsMyTransactions?: boolean;

  UTS?: string;
  CTS?: string;
  updatedTS?: string;

}

export interface CreditActivity {
  activityId?: string;
  activityType?: CreditActivityType;
  activityPayload?: any;
  creditHistory?: CreditHistory;
  CTS?: string;
}

export interface CreditHistory {
  user: KalgudiUserShortProfile;
  /**
   * Basic details of page
   * replacement of entity object in old one
   */
  page: BasePageDetails;
  /**
   * Either profileKey or entityId
   */
  creditorId: string;
  effectiveCredit: number;
  closingCredit: number;
  remark: string;

  creditType: CreditType;
  creditAction: CreditAction;
}

export interface CreditActivityResponse extends ApiResponseCommon {
  data?: KalgudiCredit;
}

export interface KalgudiCredit {
  activities: any[];
  activitiesCount: number;
  pages: CreditPageDetails[];
  totalCredit: number;
}

export interface CreditPageDetails {
  createdBy: KalgudiUserBasicDetails;
  credit: number;
  pageId: string;
  pageTitle: string;
  pageType: string;
}

export interface SmsSettingsPayload {
  domesticSms: boolean;
  domesticVoiceCall: boolean;
  internationalSms: boolean;
  internationalVoiceCall: boolean;
  authorId: string;
  creditType: CreditType;
}

export interface AvailableCredits {
  credits: number;
  amount: number;
}

export enum CreditAction {
  CREDIT, DEBIT
}

export enum CreditType {
  USER_CREDIT, PROGRAM_CREDIT
}

/**
 * Type of activity on credits
 */
export enum CreditActivityType {

  /**
   * To send SMS
   */
  SEND_SMS,

  /**
   * Credit update after getting SNS report
   */
  SMS_CREDIT_UPDATE,

  /**
   * To send voice call
   */
  VOICE_CALL,

  /**
   * To transfer credit to program
   */
  TRANSFER_CREDIT_TO_PROGRAM,

  /**
   * To receive credit from user
   */
  RECEIVE_PROGRAM_CREDIT_FROM_USER,

  /**
   * Credit after successful payment
   */
  CREDIT_AFTER_SUCCESSFUL_PAYMENT,

  /**
   * Credit forwarded from previous credit
   */
  CARRY_FORWARDED_PREVIOUS_CREDIT,

  /**
   * Refund credit after SMS report check
   */
  CREDIT_REFUND,
}
