import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { FakeItBlockComponent } from './fake-it-block/fake-it-block.component';
import { KalgudiNoContentComponent } from './kalgudi-no-content.component';



@NgModule({
  declarations: [
    KalgudiNoContentComponent,
    FakeItBlockComponent
  ],
  imports: [
    // Svg Icon Module
    AngularSvgIconModule,

    CommonModule,

    // Material Module
    MatIconModule,

    FlexLayoutModule,

    TranslateModule,
  ],
  exports: [
    KalgudiNoContentComponent,
    FakeItBlockComponent
  ]
})
export class KalgudiNoContentModule { }
