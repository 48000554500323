import { Component, Injector, OnInit } from '@angular/core';

import { FarmerCertificates } from '../../../farmer-profile/classes/farmer-certificates';
import { Router } from '@angular/router';

@Component({
  selector: 'kl-cooperative-society-certificates',
  templateUrl: './cooperative-society-certificates.component.html',
  styleUrls: ['./cooperative-society-certificates.component.scss']
})
export class CooperativeSocietyCertificatesComponent extends FarmerCertificates implements OnInit {

  isPublicPage: boolean = false;

  constructor(protected injector: Injector,
    private router: Router
    ) {

    super(injector);
  }

  ngOnInit() {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');
  }

  onDestroyed(): void {}

}

