<main>
  <div class="add-members" fxLayout="column">
    <kl-bulk-members-addition (loginIdsList)="addMembers($event)"></kl-bulk-members-addition>

    <div class="separator my-2"></div>

    <div fxLayout fxLayoutAlign="space-between start">
      <div fxLayout="column">
        <span class="upload-documents text-secondary mt-2" translate>Click here to add bulk users document</span>

        <kl-image-picker-form-control [allowMultiple]="false" [formControl]="attachments" [showAttachments]="false"
          [acceptedFileTypes]="acceptedFileTypes" [s3Category]="s3Category">
        </kl-image-picker-form-control>

        <kl-image-chip-list [attachments]="attachments?.value" (remove)="removeAttachment()">
        </kl-image-chip-list>

        <span class="text-danger" translate>* Please enter the role in capital</span>

      </div>

      <div class="mt-2">
        <a [href]="sampleCsvLink" target="_blank">
          <span translate>Click here for Sample file</span>
        </a>
      </div>

    </div>

    <div class="pt-0" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <kl-flat-button
        label="Submit"
        (clicked)="addMembersDocument()"
        [disabled]="!attachments?.value?.length">
      </kl-flat-button>
    </div>
  </div>
</main>
