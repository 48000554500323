import { Directive, Inject } from '@angular/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { Paginator, PartialData } from '@kalgudi/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, take, takeUntil, tap } from 'rxjs/operators';

import { NotImplementedError } from '../errors/not-implemented.error';
import { KalgudiUtilityService } from '../services/kalgudi-util.service';
import { KalgudiStreamData, KalgudiStreamLoadAction } from '../typings';
import { KalgudiBaseStream } from './kalgudi-base-stream';


/**
 * Kalgudi Stream base class. Defines the base methods that every stream
 * must have.
 *
 * Child class must subscribe to its `pageChange$` event and call the
 * `fetchStreamItems()` to fetch the latest stream items on page change event.
 * Or else can have a different implementation of fetching the stream items.
 *
 * The default item append method is `concat`. If you want your stream items to
 * replace on page change event then override the property `this.streamLoadAction = 'replace';`.
 *
 * All the children must subscribe to any Observable until the `destroyed$` is emitted.
 *
 * A class implementing KalgudiStream must implement following methods
 * - `streamApi(offset: number, limit: number)`: Defines Api to load stream data
 * - `onDestroyed()`: Gets called when the stream instance is destroyed. Do memory cleaning stuffs
 * here.
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiStream<T> extends KalgudiBaseStream {

  /**
   * Stream of items
   */
  readonly stream$: Observable<T[]>;

  /**
   * Gets, latest item in the paginator stream. Subscribe to this to
   * get latest paginator object contained in the stream.
   *
   * @see pageChange$
   */
  readonly paginator$: Observable<Paginator>;

  /**
   * Gets, the page change events in the current stream. Subscribing to this
   * emits an item to the stream if there is any page change event.
   *
   * A page change event is said to be when previous offset vary from the current
   * stream item offset.
   */
  readonly pageChange$: Observable<Paginator>;

  /**
   * Default stream load action. Default set to `concat`.
   * Override to `replace` if you want stream items to replace
   * previous stream items on page change.
   */
  protected streamLoadAction: KalgudiStreamLoadAction = 'concat';

  /**
   * Flag raised to ensure unintentional initialization of stream twice
   */
  protected isStreamInitialized = false;

  // Stream paginator defaults
  private readonly DEFAULT_PAGINATOR: Paginator = {
    offset: 0,
    limit: 20,
    loading: false,
    loading$: null,
    hasItems: true,
    count: 0,
  };

  /**
   * Observable wrapping stream of items
   */
  private streamItemsSubject = new BehaviorSubject<T[]>([]);

  /**
   * Observable containing stream of paginator objects.
   */
  private pageChangeSubject = new BehaviorSubject<Paginator>(null);

  /**
   * Item emitted when stream is reset
   */
  private resetStreamSubject = new BehaviorSubject<boolean>(null);

  protected resetStream$: Observable<boolean>;


  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super();

    this.resetStream$ = this.resetStreamSubject.asObservable()
      .pipe(filter(val => val !== null));

    this.stream$ = this.streamItemsSubject.asObservable()
      .pipe(filter(r => r !== null));


    this.paginator$ = this.pageChangeSubject.asObservable()
      .pipe(filter(r => r !== null));
      this.pageChange$ = this.paginator$
      .pipe(
        distinctUntilChanged(this.isSamePaginator));
  }

  /**
   * If the current stream item does not belong to the page change event then
   * neglect the current item in the event stream.
   * It also allows to pass through the filter if the previous paginator was `null`.
   */
  isSamePaginator(previousPaginator: Paginator, currentPaginator: Paginator): boolean {
    const result =  previousPaginator && (previousPaginator.offset === currentPaginator.offset)
    return result && previousPaginator.keyword === currentPaginator.keyword && previousPaginator.includePageTypes === currentPaginator.includePageTypes
    && previousPaginator?.searchProperties?.groupDescription === currentPaginator?.searchProperties?.groupDescription && previousPaginator?.searchProperties?.all === currentPaginator?.searchProperties?.all
    && previousPaginator?.searchProperties?.groupTitle === currentPaginator?.searchProperties?.groupTitle &&  previousPaginator?.fromDate === currentPaginator?.fromDate &&  previousPaginator?.toDate === currentPaginator?.toDate
}


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the latest page change value
   */
  get paginatorValue(): Paginator {
    return this.util.clone(this.pageChangeSubject.getValue());
  }

  /**
   * Gets, the latest value emitted in the stream
   */
  protected get streamValue(): T[] {
    return this.streamItemsSubject.getValue();
  }

  /**
   * Gets, a new paginator object constructed from the default paginator object.
   */
  private get newPaginator(): Paginator {
    return this.util.clone(this.DEFAULT_PAGINATOR);
  }

  /**
   * Sets, page limit to load on each page change event.
   */
  protected set pageLimit(val: number) {
    this.DEFAULT_PAGINATOR.limit = val;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Increments paginator offset and loads more stream items. The function also asserts if there
   * are more items to load or not. If the API returned the items requested by the client, then
   * there is may be more items to load. Or if the API sent the item count accordingly.
   */
  nextPage(): Paginator {

    // Update paginator
    const updatedPaginator = this.incrementPaginator(this.paginatorValue);

    updatedPaginator.loading = true;

    // Update latest paginator in the stream
    this.setPaginator(updatedPaginator);

    return updatedPaginator;
  }

  // /**
  //  * Loads previous set of items to the paginator
  //  */
  // prevPage(): void {

  // }

  /**
   * Resets the current stream. Clears the stream items and resets stream
   * offset, limit to its defaults.
   */
  resetStream(emit: boolean = true): void {

    // Clear stream items
    this.setStreamItems([]);

    const newPaginator = this.newPaginator;
    newPaginator.loading = true;

    // Clear paginator
    this.setPaginator(newPaginator);

    // Emit new item in the reset stream
    if (emit) {
      this.resetStreamSubject.next(true);
    }
  }

  /**
   * Appends a new item to the list. Adds item to the top of the list.
   */
  unshiftItem(item: T): T[] {

    // Get current items in stream
    const updatedStreamItems = this.streamValue;

    // Unshift the updated item
    updatedStreamItems.unshift(item);

    // Update items in stream
    this.setStreamItems(updatedStreamItems);

    return updatedStreamItems;
  }

  /**
   * Pushes a new item into the stream
   */
  pushItem(item: T): T[] {

    // Get current items in stream
    const updatedStreamItems = this.streamValue;

    // Pushes a new item into the stream
    updatedStreamItems.push(item);

    // Update items in stream
    this.setStreamItems(updatedStreamItems);

    return updatedStreamItems;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Loads the initial set of data in the stream.
   */
  protected initStream(subscribeToEvents: boolean = true): void {
    this.setPaginator(this.newPaginator);
  }

  /**
   * Loads the stream items. Internally it calls the `streamApi` function to fetch the
   * stream from API.
   */
  protected fetchStreamItems(paginator: Paginator, extraParams: PartialData = {}): Observable<KalgudiStreamData> {
    // Turn on the spinner
    this.setLoadingProgress(true);
    extraParams = {
      ...extraParams,
      offset: paginator.offset,
      keyword: paginator.keyword,
      includePageTypes: paginator.includePageTypes,
      searchProperties: paginator.searchProperties,
      fromDate: paginator.fromDate,
      toDate: paginator.toDate
    }
    // Load the stream items from API
    return this.streamApi(paginator.offset, paginator.limit, extraParams)
      .pipe(
        // Ensure to close stream after fetching first item
        take(1),

        // Subscribe to the stream Api responses only till the instance is alive
        takeUntil(this.destroyed$),

        // Handler for successful stream items fetch
        tap(r => {
          this.onStreamFetch(this.streamValue, r, this.paginatorValue);
          this.setLoadingProgress(false);
        }),

        finalize(() => this.setLoadingProgress(false)),

        // Turn off the loading progress spinner
        tap(_ => this.setLoadingProgress(false)),

        // Listen for any errors while loading of stream
        catchError(() => {
          this.setLoadingProgress(false);

          return of({ items: [], count: 0});
        })
      );
  }

  /**
   * Sets the loading progress of the stream. It also shows common spinner based
   * on the loading progress.
   */
  protected setLoadingProgress(val: boolean): void {

    // (val === true)
    //   ? this.notification.showSpinner(true)
    //   : this.notification.hideSpinner();

    // Get latest paginator object
    const paginator = this.paginatorValue;

    // Update the paginator progress status
    paginator.loading = val;
    // Set the updated paginator to the stream
    this.setPaginator(paginator);
  }

  /**
   * Stream API success response handler method. It updates the paginator
   * and stream items.
   */
  protected onStreamFetch(oldStreamItems: T[], newStream: KalgudiStreamData, paginator: Paginator): void {

    // Get the updated stream
    const updatedStream = this.streamLoadAction === 'concat'
      ? this.concatStreamItems(oldStreamItems, newStream.items)
      : this.replaceStreamItems(oldStreamItems, newStream.items);

    // Update stream items
    this.setStreamItems(updatedStream);


    // Update the paginator for more items
    const updatedPaginator = this.util.clone(paginator);

    // Update the count in the paginator if service send response
    updatedPaginator.count = newStream.count || updatedPaginator.count;

    // Update the hasMoreItem flag
    updatedPaginator.hasItems = this.hasMoreItems(
      paginator.limit,
      newStream.items.length,
      this.streamValue.length,
      newStream.count
    );

    // Set the updated paginator
    this.setPaginator(updatedPaginator);
  }

  /**
   * Stream API error response handler method. Shows the error message
   */
  private onStreamError(err: Error): void {
    this.notification.showMessage(err.message);
  }

  /**
   * Increments the paginator offset value based on the configuration.
   * It also asserts whether paginator can increment to next batch of stream
   * items or not based on the stream limit.
   */
  private incrementPaginator(paginator: Paginator): Paginator {

    paginator.offset += paginator.limit;

    return paginator;
  }

  /**
   * Decrements the paginator offset value based on the configuration.
   * Before decrementing it asserts if decrementing paginator value is possible
   * or not.
   *
   * It also updates the paginator flag `hasItems` if there are more items to load.
   */
  private decrementPaginator(): Paginator {
    throw new NotImplementedError(new Error('Method not implemented'));
  }

  /**
   * Checks after fetching the fresh batch of stream, is there are more items to load or not.
   * If the items requested is same as the items received then there are more items to load,
   * otherwise not.
   *
   * @returns `true` if there are more items to load in the stream, otherwise `false`.
   */
  private hasMoreItems(
    itemsRequested: number,
    itemsReceived: number,
    streamItemsCount: number,
    count?: number
  ): boolean {

    let hasItemsToLoad = false;

    if (count) {
      // Total count of stream items are known

      // There are more items to load if,
      // The stream is in initial state, there are no items. Then the Api sent count is greater than items requested
      // Or else Api sent count is more than the current stream items count
      hasItemsToLoad = (streamItemsCount === 0) ? (count > itemsRequested) : (count > streamItemsCount);
    } else {
      // Total count of stream items are unknown hence check based


      // There are more items to load if, server sent same number of items that are requested
      hasItemsToLoad = (itemsRequested === itemsReceived);
    }

    return hasItemsToLoad;
  }

  /**
   * Emits the latest paginator object to the paginator stream.
   */
  protected setPaginator(val: Paginator): void {
    this.pageChangeSubject.next(val);
  }

  /**
   * Emits latest items to the stream.
   */
  protected setStreamItems(items: T[]): void {
    this.streamItemsSubject.next(items);
  }

  /**
   * Concatenates the existing stream items with the new batch of stream
   * items.
   *
   * @returns Updated stream items
   */
  private concatStreamItems(streamItems: T[], newStreamItems: T[]): T[] {

    return streamItems.concat(newStreamItems);
  }

  /**
   * Replaces the existing stream items with the new items.
   *
   * @returns Updated stream items
   */
  private replaceStreamItems(streamItems: T[], newStreamItems: T[]): T[] {
    return newStreamItems;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
