import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { KalgudiEnvironmentConfig, KalgudiSsoConfig } from '@kalgudi/core/config';

@Injectable({
  providedIn: 'root'
})
export class CoreEnvironmentConfigService implements KalgudiEnvironmentConfig {

  production: boolean;
  awsAccessKey: string;
  s3BucketUrl: string;
  domain: string;
  kalgudiDomain?: string;
  consumerStoreDomain: string;
  bizStoreDomain: string;
  farmerStoreDomain: string;
  dirUrl: string;
  restBaseUrl: string;
  restBaseUrlV2: string;
  kalgudiRestV2: string;
  googlePlacesApi: string;
  development?: boolean;
  appId?: string;
  coreDomain?: string;
  storeDomain?: string;
  conserWaterKey?: string;
  useHash?: boolean;
  ssoConfig?: KalgudiSsoConfig;

  constructor() {

    this.development         = environment.development;
    this.production          = environment.production;
    this.awsAccessKey        = environment.awsAccessKey;
    this.s3BucketUrl         = environment.s3BucketUrl;
    this.domain              = environment.domain;
    this.kalgudiDomain       = environment.kalgudiDomain;
    this.consumerStoreDomain = environment.consumerStoreDomain;
    this.bizStoreDomain      = environment.bizStoreDomain;
    this.farmerStoreDomain   = environment.farmerStoreDomain;
    this.dirUrl              = environment.dirUrl;
    this.restBaseUrl         = environment.restBaseUrl;
    this.restBaseUrlV2       = environment.restBaseUrlV2;
    this.kalgudiRestV2       = environment.kalgudiRestV2;
    this.coreDomain          = environment.coreDomain;
    this.storeDomain         = environment.storeDomain;
    this.googlePlacesApi     = environment.googlePlacesApi;
    this.appId               = environment.appId;
    this.conserWaterKey      = environment.conserWaterKey;
    this.useHash             = environment.useHash ? environment.useHash : false;
    this.ssoConfig           = environment.ssoConfig
  }
}
