import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { finalize } from 'rxjs/operators';

import { KalgudiGoogleLanguageTranslationService } from '../kalgudi-google-language-translation.service';
import { GoogleSupportedLanguagesList, LanguageIdList, LanguageIdMap } from '../types';

@Component({
  selector: 'kl-google-translate',
  templateUrl: './google-translate.component.html',
  styleUrls: ['./google-translate.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GoogleTranslateComponent implements OnInit, OnChanges {

  @Input()
  text: string;

  @Input()
  source: GoogleSupportedLanguagesList;

  @Input()
  targetLanguageList: LanguageIdList = [];

  @Input()
  format: string;

  selectedLanguage: LanguageIdMap;

  translatedText: string;

  progress: boolean = false;

  constructor(
    private translationService: KalgudiGoogleLanguageTranslationService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reset();
  }

  /**
   * Resets the current translation.
   */
  reset(): void {
    this.progress         = false;
    this.translatedText   = null;
    this.selectedLanguage = null;
  }


  /**
   * Translates the text to a given language
   */
  translate(
    text: string,
    source: GoogleSupportedLanguagesList,
    target: GoogleSupportedLanguagesList
  ): void {


    // Do nothing if already selected
    if (this.selectedLanguage && this.selectedLanguage.id === target) {
      return;
    }

    this.setSelectedLanguage(target);

    this.translatedText = '';

    this.progress = true;

    this.translationService.translate(text, source, target, this.format)
      .pipe(
        finalize(() => this.progress = false),
      )
      .subscribe(
        res => {
          this.translatedText = res;
        }
      );
  }

  /**
   * Sets the current selected language
   */
  private setSelectedLanguage(lang: GoogleSupportedLanguagesList) {
    this.selectedLanguage = this.targetLanguageList.find(l => l.id === lang);
  }
}
