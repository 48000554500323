import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUserCertificateDetails } from '@kalgudi/types';


@Component({
  selector: 'kl-add-certificate-dialog',
  templateUrl: './add-certificate-dialog.component.html',
  styleUrls: ['./add-certificate-dialog.component.scss']
})
export class AddCertificateDialogComponent extends KalgudiDialog<AddCertificateDialogComponent> implements OnInit {

  certificateId: string;

  certificateDetails: KalgudiUserCertificateDetails;

  constructor(
    protected dialogRef: MatDialogRef<AddCertificateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {

    super(dialogRef);
  }

  ngOnInit() {
    this.certificateId = this.data.data.id;
    this.certificateDetails = this.data.data.details;
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: { }
    };
    this.dialogRef.close(result);
  }
}

