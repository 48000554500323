import { Injectable } from '@angular/core';
import { IPageStateManager } from '@kalgudi/core';
import { KalgudiPageDetails, StateManagerAction } from '@kalgudi/types';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { PageActions } from '../constants';


/**
 * Kalgudi Program fullview state management service. It defines observable that
 * emits program state change name on every state change. A publisher must post
 * state change message using `update()` method.
 */
@Injectable()
export class KalgudiProgramStateService implements IPageStateManager<KalgudiPageDetails, PageActions> {

  readonly data$: Observable<KalgudiPageDetails>;
  readonly id$: Observable<string>;
  readonly action$: Observable<StateManagerAction<PageActions>>;

  private pageDataSubject = new BehaviorSubject<KalgudiPageDetails>(null);
  private actionSubject   = new Subject<StateManagerAction<PageActions>>();

  constructor() {
    this.action$ = this.actionSubject.asObservable();

    this.data$ = this.pageDataSubject.pipe(filter(val => val !== null));
    this.id$   = this.data$.pipe(map(val => val.pageId));

  }

  get data(): KalgudiPageDetails {
    return this.pageDataSubject.getValue();
  }

  get id(): string {
    return this.data ? this.data.pageId : null;
  }

  dispatchAction(action: PageActions, payload?: any): void {
    this.actionSubject.next({ type: action, payload });

    if (action === PageActions.PAGE_UPDATED && payload) {
      this.pageDataSubject.next(payload);
    }
  }

  // // --------------------------------------------------------
  // // #endregion
  // // --------------------------------------------------------

}
