<div fxLayout="column" class="kalgudi-core-theme h-100 overflow-hidden mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Section for mat dialog content -->
    <div mat-dialog-content>
      <div class="dialog-container">
        <kl-farmer-farm-activity
          [pageId]="data?.data?.pageId"
          [selectedSeason]="data?.data?.selectedSeason"
          (postCreated)="onPostCreated($event)">
        </kl-farmer-farm-activity>
      </div>
    </div>
    <!-- /Section for mat dialog content -->

  </div>
</div>
