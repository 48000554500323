<div class="kalgudi-core-theme overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div>
    <kl-user-picker
      [initialSearchKeyword]="initialSearchKeyword"
      [extraSearchParams]="extraSearchParams"
      [multiSelect]="multiSelect"
      [searchPlaceHolder]="placeHolder"
      [pageId]="dialogData?.extraParams?.pageId"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-user-picker>
  </div>

  <div class="pt-0 mx-2 mb-2" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-link-button [label]="rejectButtonTitle" buttonColor="warn" (clicked)="cancel()"></kl-link-button>
    <kl-link-button [label]="acceptButtonTitle" buttonColor="primary" (clicked)="ok()" [disabled]="!(selectedUsersMap | keyvalue)?.length"></kl-link-button>
  </div>

</div>
