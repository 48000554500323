import { Injectable } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiStreamData } from '@kalgudi/core';
import {
  KalgudiDialogResult,
  KalgudiSms,
  KalgudiSpecificSubscribers,
  LogsDownloadResponse,
  LogsResponseData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';

import { MobilePublishSmsComponent } from '../components/mobile-publish-sms/mobile-publish-sms.component';
import { LogsListApiService } from './logs-list-api.service';

@Injectable()
export class LogsListService {

  constructor(private api: LogsListApiService, private dialogService: KalgudiDialogsService) { }

  /**
   * Fetches logs list from the API
   */
  fetchLogsList(smsId: string, pageId: string, offset: number, limit: number, filterType: string): Observable<LogsResponseData> {

    return this.api.fetchLogsList(smsId, pageId, offset, limit, filterType);
  }

  /**
   * Calls api method to download logs list from the API
   */
  downloadLogsList(smsId: string): Observable<LogsDownloadResponse> {

    return this.api.downloadLogsList(smsId);
  }


  /**
   * publish SMS
   */
  publishNewSms(pageId: string, payload): Observable<KalgudiSms> {
    return this.api.publishNewSms(pageId, payload);
  }

  /**
   * UPDATE SCHEDULED SMS
   */
  updateScheduledSms(scheduleId: string, payload: KalgudiSpecificSubscribers): Observable<KalgudiSms> {
    return this.api.updateScheduledSms(scheduleId, payload);
  }

  /**
   * Get SMS stream
   */
  getSmsStream(pageId: string, offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.api.getSmsStream(pageId, offset, limit);
  }

  /**
   * Api to get sms templates list
   */
  getSmsTemplateList(): Observable<any> {
    return this.api.getSmsTemplateList();
  }

  /**
   * Api to get short url
   */
  convertToShortUrl(payload: any): Observable<any> {
    return this.api.convertToShortUrl(payload);
  }

  /**
   * Opens publish SMS form for mobile view
   */
  openMobileSmsForm(): Observable<KalgudiDialogResult> {
    return this.dialogService.openMobileDialog(MobilePublishSmsComponent, {
      data: { },
      title: 'Publish SMS',
    });
  }

}
