import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiProjectTask } from '@kalgudi/types';

@Component({
  selector: 'kl-tasks-tile',
  templateUrl: './tasks-tile.component.html',
  styleUrls: ['./tasks-tile.component.scss']
})
export class TasksTileComponent implements OnInit, OnChanges {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  enableActions: boolean;

  @Input()
  showStats: boolean;

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  addMembers = new EventEmitter();

  @Output()
  openFullview = new EventEmitter();

  @Output()
  updateTask = new EventEmitter();

  @Output()
  showTaskStats = new EventEmitter<string>();

  taskStatus = ['Not stared', 'In process', 'Completed'];

  projectRoles = KALGUDI_PAGE_RELATION_MAP;

  submissions: any[];

  constructor(
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    private router: Router,
    ) {
  }

  ngOnInit() {
  }

  /**
   * Emit open task full view event to parent component
   */
  openTaskFullview() {
    this.openFullview.emit();
  }

  /**
   * Opens task stats Dialog
   */
  openTaskStatsDialog(statType: string) {
    this.showTaskStats.emit(statType);
  }

  ngOnChanges() {
    this.submissions = [
      {
        color: '#58EB8B',
        title: 'Completed members',
        id: 'COMPLETED',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.completed : 0,
      },
      {
        color: '#FFB74F',
        title: 'In progress members',
        id: 'IN_PROGRESS',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.in_progress : 0,
      },
      {
        color: '#FC5D5D',
        title: 'Not started members',
        id: 'NOT_STARTED',
        value: this.taskDetails.statistics ? this.taskDetails.statistics.submissions.not_started : 0,
      }
    ];
  }

  /**
   * Opens task members page
   */
  openTaskMembersPage(membersStatus: string) {

    if(this.taskDetails.pageId) {

      const url = this.environment.appId === 'FPO_APP' ? `/app/home/pages/${this.taskDetails.pageId}/tasks/${this.taskDetails.id}/members`
                                                       : `/app/pages/${this.taskDetails.pageId}/tasks/${this.taskDetails.id}/members`;

      this.router.navigate([url], {queryParams : { membersStatus: membersStatus}});
    } else {
      const url = `/app/projects/${this.taskDetails.projectId}/${this.taskDetails.id}/members`;

      this.router.navigate([url], {queryParams : { membersStatus: membersStatus}});
    }

  }


}
