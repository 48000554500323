import { Directive, Inject, Injector } from '@angular/core';
import { ApiError, KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KL_NOTIFICATION, KL_ROUTE_CONFIG, KalgudiNotification } from '@kalgudi/core/config';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileRouteConfig } from '../../../config';
import { KalgudiProfileActionService } from '../../../services/kalgudi-profile-action.service';
import { LiveStockService } from '../services/live-stock.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';


@Directive()
export abstract class KalgudiLiveStockList extends KalgudiDestroyable {

  progress: boolean;
  profileKey: string;
  isPublicPage: boolean;
  editable: boolean;

  livestockList: any[] = [];

  private kalgudiAppService: KalgudiAppService;
  private liveStockService: LiveStockService;
  private appRouting: KalgudiProfileRouteConfig;
  private profileActionService: KalgudiProfileActionService;
  private notification: KalgudiNotification;
  public activatedRoute: ActivatedRoute;
  private profileService: KalgudiProfileService;
  public router: Router
  locationDetails: any;
  updatedLiveStockList: any[] = [];

  constructor(
    protected injector: Injector


  ) {
    super();

    this.kalgudiAppService      = this.injector.get(KalgudiAppService);
    this.liveStockService       = this.injector.get(LiveStockService);
    this.appRouting             = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
    this.profileActionService   = this.injector.get(KalgudiProfileActionService);
    this.notification           = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileKey             = this.kalgudiAppService?.profileLocal?.profileKey;
    this.profileService         = this.injector.get(KalgudiProfileService);
    this.activatedRoute         = this.injector.get(ActivatedRoute);
    this.router                 = this.injector.get(Router);

    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');

    if(this.isPublicPage) {
      this.profileKey = this.activatedRoute.snapshot.params.profileKey;
    }

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Get the live stock list
   */
  getLiveStockList(): void {
    this.progress = true;

    this.liveStockService.getLiveStockList(this.profileKey)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(res => {
        this.livestockList = res;
        this.cloneLiveStockList(this.livestockList);
      },
        (err: ApiError) => {
          this.notification.showMessage(err.error.message);
        });
  }

  /**
   * Cloning the live-stock list
   */
  cloneLiveStockList(livestockList) {

    this.updatedLiveStockList = [...livestockList];

    let k = 1;
    for (let i = 0; i < livestockList.length; i++) {
      let value = livestockList[i];
      this.updatedLiveStockList.splice(k, 0, value);
      k += 2;
    }

    const updatedStockList = this.updatedLiveStockList.map(({ uniqueId, ...product }) => ({
      uniqueId: uniqueId ?? Math.floor(Math.random() * 100) + 1,
      ...product,
    }));

    this.updatedLiveStockList = updatedStockList;
    this.getProductsList();
  }

  /**
   * Separating product objects from updated live stock list
   */
  getProductsList() {
    let product = [];
    for (let i = 0; i < this.livestockList.length; i++) {
      for (let j = 0; j < this.livestockList[i].product.length; j++) {
        const products = this.livestockList[i].product[j];
        const obj = {
          availableQuantity: products.availableQuantity,
          productId: products.productId,
          productName: products.productName
        };
        product.push(obj);
      }
    }
    for (let i of this.updatedLiveStockList) {
      delete i.product;
    }
    this.getAllStockList(product);
  }

  /**
   * Assigning each product to updated live stock array
   * @param product
   */
  getAllStockList(product) {
    for (let i = 0; i < product.length; i++) {
      for (let j = 0; j < this.updatedLiveStockList.length; j++) {
        this.updatedLiveStockList[j]['product'] = product[i];
        i++;
      }
      break;
    }
  }

  /**
   * Open edit live stock dialog
   * @param stock
   */
  openEditLiveStockDialog(stock: any) {
    delete stock.uniqueId;
    const product = [];
    this.livestockList.forEach(stockDetails => {
      stockDetails.product.forEach(products => {
        if (stockDetails.batchNo === stock.batchNo) {
          product.push(products);
        }
      });
    });
    const stockType = {
      title: stock.type,
      items: {
        attachments: stock.attachments,
        products: product
      }
    }
    this.profileActionService.showLiveStockDialogForm(stockType, stock, true)
      .subscribe(res => {
        if (res) {
          this.getLiveStockList()
        }
      });
  }

  /**
   * View product location dialog
   */
  showLocationDialog(locationDetails?: any) {
    this.getLiveStockList();
    const locationObj = {
      latitude: locationDetails ? locationDetails.latitude : '',
      longitude: locationDetails ? locationDetails.longitude : '',
    }

    this.profileActionService.viewLocationDialog(locationObj).subscribe();
  }

  /**
   * Show live stock list page
   */
  showLiveStockListPage(): void {

    this.appRouting.toLiveStockListPage({ profileKey: this.profileKey });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
