import { Component, Inject } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiUsersSearchService } from '../../../kalgudi-users/services/kalgudi-users-search.service';
import { KalgudiConnectsPicker } from '../../classes/kalgudi-connects-picker';

@Component({
  selector: 'kl-connects-picker',
  templateUrl: './connects-picker.component.html',
  styleUrls: ['../../../picker-common.scss', './connects-picker.component.scss']
})
export class ConnectsPickerComponent extends KalgudiConnectsPicker {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected usersSearch: KalgudiUsersSearchService,
  ) {

    // Wake up my parent
    super(notification, util, usersSearch);
  }

  protected onDestroyed(): void { }

}
