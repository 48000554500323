import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { PartialData } from '@kalgudi/types';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';

import { DataStore } from '../classes/data-stores/data-store';
import { KalgudiUtilityService } from './kalgudi-util.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiLanguageServiceService {

  private readonly localLanguageIdMapKey = 'languageIdMap';

  private readonly LANGUAGE_ID_MAP_PATH = `${this.env.domain}/data/website/googleTranslationLanguageCodes.txt`;



  private languageIdMapSubject = new BehaviorSubject<PartialData>(null);

  private languageIdMap$: Observable<PartialData>;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private util: KalgudiUtilityService,
    private dataStore: DataStore,
  ) {

    this.init();

    this.languageIdMap$ = this.languageIdMapSubject.pipe(filter(val => val !== null));
  }


  // --------------------------------------------------------
  // #region Getters & Setters
  // --------------------------------------------------------

  /**
   * Language id title map
   */
  get languageIdMap(): PartialData {
    return this.languageIdMapSubject.getValue();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Gets the language id mapped to the language title
   */
  getLanguageId(languageTitle: string): Observable<string> {

    return this.languageIdMap$
      .pipe(
        map(val => val[languageTitle] || '')
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  private init(): void {

    this.initLanguageIdMap();
  }

  /**
   * Fetches google location language title and id mapping stored in S3.
   */
  private initLanguageIdMap(): void {

    this.fetchLanguageIdMap()
      .subscribe(
        res => {
          this.languageIdMapSubject.next(res);
        },
        err => {
          console.error('Unable to fetch language id map. ', err);
        }
      );
  }

  /**
   * Fetches language id map from local storage id available
   * otherwise fetches it from S3.
   */
  private fetchLanguageIdMap(): Observable<PartialData> {

    return this.dataStore.hasItem(this.localLanguageIdMapKey)
      ? this.fetchLanguageIdMapFromLocalStorage()
      : this.fetchLanguageIdMapFromS3();
  }

  /**
   * Fetches language id map from S3
   */
  private fetchLanguageIdMapFromS3(): Observable<PartialData> {

    return this.util.fetchJsonFromUrl(this.LANGUAGE_ID_MAP_PATH)
      .pipe(

        // Update the language id map to local storage
        switchMap(val => val
          ? this.updateLanguageIdMapToLocalStorage(val)
          : of(val)
        )
      );
  }

  /**
   * Fetches language id map from local storage
   */
  private fetchLanguageIdMapFromLocalStorage(): Observable<PartialData> {

    return this.dataStore.getItem(this.localLanguageIdMapKey, true);
  }

  /**
   * Updates the language id map to the localStorage
   */
  private updateLanguageIdMapToLocalStorage(val: PartialData): Observable<PartialData> {

    return this.dataStore.setItem(this.localLanguageIdMapKey, val, true)
      .pipe(
        mapTo(val)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
