<section class="university-page mb-3 page-creation-form">
  <div class="w-100 university col-7 mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="18px">

        <!-- Product -->
        <div class="mb-2">
          <kl-base-product-autocomplete
            formControlName="product"
            [required]="true">
          </kl-base-product-autocomplete>
        </div>
        <!-- / Product -->

        <!-- Variety -->
        <div class="mb-2">
          <kl-base-product-autocomplete formControlName="variety"
            placeholder="Search and select product variety"
            label="Search and select product variety"
            [productType]="productTypes.VARIETY"
            [baseProduct]="baseProductFieldValue"
            [required]="true">
          </kl-base-product-autocomplete>
        </div>
        <!-- / Variety -->

        <!-- Product -->
        <div class="mb-2">
          <kl-product-variety-autocomplete
            formControlName="variant">
          </kl-product-variety-autocomplete>
        </div>
        <!-- / Product -->

      </div>
    </form>
    <!-- / Wrapper Form -->
  </div>
</section>
