import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[klDynamicHost]'
})
export class DynamicHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) {}

}
