import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FarmerPageDetails, PageType } from '@kalgudi/types';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateFarmer extends KalgudiCreatePageType<FarmerPageDetails> {
  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the Farmer name from Page form
   */
  get farmerNameField(): AbstractControl {
    return this.pageForm.get('farmerName');
  }

  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      farmerName: ['', Validators.required],
      cropsCount: [''],
      selectCrops: [''],
      area: [''],
      areaUnits: [''],
      villageCovered: [''],
      package: [''],
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'FARMER';
  }

}

