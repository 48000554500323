import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiAddBoardMembers } from '../../classes/kalgudi-add-board-members';

@Component({
  selector: 'kl-add-board-members',
  templateUrl: './add-board-members.component.html',
  styleUrls: ['./add-board-members.component.scss']
})
export class AddBoardMembersComponent extends KalgudiAddBoardMembers implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.boardOfMembersList = this.util.clone(this.profileDetails.boardOfMembers) ? this.util.clone(this.profileDetails.boardOfMembers) : [];
  }

  onDestroyed() : void {}
}
