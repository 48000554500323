import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiEmployeeCertificates } from '../../classes/kalgudi-employee-certificates';

@Component({
  selector: 'kl-employee-certificates',
  templateUrl: './employee-certificates.component.html',
  styleUrls: ['./employee-certificates.component.scss']
})
export class EmployeeCertificatesComponent extends KalgudiEmployeeCertificates implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
