import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SatelliteAdvisoriesSyncApiService } from './satellite-advisories-sync-api.service';

@Injectable()
export class SatelliteAdvisoriesSyncService {

  constructor(
    private api: SatelliteAdvisoriesSyncApiService,
  ) { }

  /**
   * Creates an advisory
   */
  shareAdvisories(pageId: string, syncId: string, payload: any): Observable<boolean> {
    return this.api.shareAdvisories(pageId, syncId, payload);
  }

  /**
   * Sync records
   */
  syncRecords(pageId: string): Observable<boolean> {
    return this.api.syncRecords(pageId);
  }
}
