import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import {
  KalgudiMobileDialogContainerComponent,
} from '../components/kalgudi-mobile-dialog-container/kalgudi-mobile-dialog-container.component';


export class KalgudiMobileDialogRef {

  componentInstance: KalgudiMobileDialogContainerComponent;

  private beforeCloseSubject = new Subject<any>();
  private afterClosedSubject = new Subject<any>();

  constructor(
    private overlayRef: OverlayRef,
  ) { }

  get beforeClose$(): Observable<any> {
    return this.beforeCloseSubject.asObservable();
  }

  get afterClosed$(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }

  /**
   * Closes the mobile dialog.
   *
   * @param data Data to be passed to the initializer of the dialog.
   */
  close(data?: any): void {

    // Listen for animation 'start' events
    this.componentInstance.animationStateChanged
      .pipe(
        filter(event => this.isDialogAnimationStarted(event)),
        take(1)
      )
      .subscribe(() => this.onDialogClosing(data));

    // Listen for animation 'done' events
    this.componentInstance.animationStateChanged
      .pipe(
        filter(event => this.isDialogAnimationCompleted(event)),
        take(1)
      )
      .subscribe(() => this.closeDialog(data));

    // Start exit animation
    this.componentInstance.startExitAnimation();
  }

  /**
   * Event handler for dialog closing event.
   */
  private onDialogClosing(data?: any): void {
    this.beforeCloseSubject.next(data);
    this.beforeCloseSubject.complete();

    this.overlayRef.detachBackdrop();
  }

  /**
   * Closes the mobile dialog container
   */
  private closeDialog(data?: any): void {

    // Clear memory dispose the mobile dialog container
    this.overlayRef.dispose();

    // Make sure to also clear the reference to the
    // component instance to avoid memory leaks
    this.componentInstance = null!;

    // Emit item in the after close subject
    this.afterClosedSubject.next(data);
    this.afterClosedSubject.complete();
  }

  /**
   * Returns `true` if the dialog animation started, otherwise `false`.
   */
  private isDialogAnimationStarted(event: any): boolean {
    return (event.phaseName === 'start');
  }

  /**
   * Returns `true` if the dialog container animation completed otherwise `false`.
   */
  private isDialogAnimationCompleted(event: any): boolean {
    return (event.phaseName === 'done' && event.toState === 'leave');
  }
}
