import { Inject, Injectable } from '@angular/core';
import { LazyHttpClient } from '@kalgudi/common/lazy-http';
import {
  ApiError,
  HttpStatusCode,
  KalgudiError,
  KalgudiUtilityService,
  LikeShareRequest,
  REST_API_ERROR_MESSAGES,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseCommonList, IdValueMap, PageType, ShareActionType, ShareRequest, ShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';


@Injectable()
export class KalgudiShareUpdateApiService {


  /**
   * `v2/social/shares`
   */
  private readonly API_SHARE = `${this.environment.restBaseUrlV2}/social/shares`;

  /**
   * `v2/social/shares/:shareId`
   */
  private readonly API_SHARE_ID = `${this.API_SHARE}/:shareId`;

  /**
   * `v2/social/shares/scheduled/:scheduleId`
   */
  private readonly API_UPDATE_SCHEDULED_SHARE = `${this.API_SHARE}/scheduled/:scheduleId`;

  /**
   * `v2/social/analytics/likes`
   */
  private readonly API_LIKES_LIST = `${this.environment.restBaseUrlV2}/social/analytics/likes`;

  /**
   * `v2/social/shares/:shareId/:actionType`
   */
  private readonly API_UPDATE_ACTION = `${this.API_SHARE_ID}/:actionType`;

  /**
   * `v2/pages`
   */
  private readonly API_PAGES_LIST = `${this.environment.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_TAGS = `${this.API_PAGES_LIST}/tags`;

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: LazyHttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   *  Shares an update
   * @param req
   */
  createShare(req: ShareRequest): Observable<ShareUpdate> {

    return this.httpClient
      .post<ApiResponseCommon>(this.API_SHARE, req)
      .pipe(

        // Check for share API response errors
        map(res => this.createShareHandler(req, res)),

        // Map API response to share type
        map(r => this.util.toJson<ShareUpdate>(r.data))
      );
  }

  /**
   *  Gets share update object from the Api.
   */
  getShareUpdate<R extends ShareUpdate>(shareId: string): Observable<R> {

    const url = this.API_SHARE_ID.replace(':shareId', shareId);

    return this.httpClient
      .get<any>(url)
      .pipe(

        // Check for share update API response errors
        map(res =>  this.util.apiErrorHandler(res)),

        // Map API response to share update type
        map(r => this.util.toJson<R>(r.data))
      );
  }

  /**
   * Posts a comment
   *
   * @param shareId Unique share id
   * @param payload Comment request payload
   */
  postComment(shareId: string, payload: LikeShareRequest): Observable<ShareUpdate> {

    const actionType: ShareActionType = 'comments';

    const url = this.API_UPDATE_ACTION
      .replace(':shareId', shareId)
      .replace(':actionType', actionType);

    return this.httpClient
      .put<ApiResponseCommon>(url, payload)
      .pipe(
        // Check for Api errors
        map(res => this.shareLikeCommentErrorHandler(res)),

        // Transform the Api response to object type
        map(r => this.util.toJson<ShareUpdate>(r.data))
      );
  }

  /**
   * Posts a like
   * @param req
   */
  updateAction(req: LikeShareRequest, actionType: ShareActionType): Observable<ShareUpdate> {
    return this.httpClient
      .put<ApiResponseCommon>(this.API_UPDATE_ACTION.replace(':shareId', req.shareId).replace(':actionType', actionType), req)
      .pipe(
        map(res => this.shareLikeCommentErrorHandler(res)),

        map(r => this.util.toJson<ShareUpdate>(r.data))
      );
  }


  /**
   * Calls the delete share update post API.
   *
   * @param shareId Share update id
   */
  deletePost(shareId: string): Observable<boolean> {
    return this.httpClient
      .delete<ApiResponseCommon>(this.API_SHARE_ID.replace(':shareId', shareId))
      .pipe(
        // Handle any API errors
        map(res => this.deletePostResponseHandler(res)),

        // All good, emit true to the stream
        mapTo(true)
      );
  }

  updateScheduledPost(scheduledId: string, shareDetails: ShareUpdate): Observable<ShareUpdate> {

    const url = this.API_UPDATE_SCHEDULED_SHARE.replace(':scheduleId', scheduledId);

    return this.httpClient
      .put<ApiResponseCommon>(url, shareDetails)
      .pipe(
        // Handle any API errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // All good, emit true to the stream
        map(r => this.util.toJson<ShareUpdate>(r.data))

      );
  }


  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * It verifies for successful response
   */
  private createShareHandler(req: ShareRequest, res: ApiResponseCommon): ApiResponseCommon {

    // Share update error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.CREATED) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successful update
    return res;
  }

  /**
   * It verifies for successful response
   */
  private shareLikeCommentErrorHandler(res: ApiResponseCommon): ApiResponseCommon {

    // Like error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.CREATED) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successfully liked
    return res;
  }

  /**
   * It verifies for successful response
   */
  private deletePostResponseHandler(res: ApiResponseCommon): ApiResponseCommon {

    // Delete error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    let error: KalgudiError;

    if (res.code !== HttpStatusCode.OK) {
      error = new ApiError(new Error(errorMessages[res.code]));
    }

    // Throw error on any error
    if (error) {
      throw error;
    }

    // All good, successfully deleted
    return res;
  }

  /**
   *  Gets likes list data.
   */
  getLikesList(shareId: string): Observable<any> {

    const params = { shareId }

    return this.httpClient
      .get<ApiResponseCommon>(this.API_LIKES_LIST, {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetches pages list from the API
   */
  fetchPagesList(
    offset: number,
    limit: number,
    isQa?: boolean
  ): Observable<any> {

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
    };

    if(!isQa) {
      params['memberRole'] ='ADMIN,CONTRIBUTOR'
    }

    return this.httpClient.get<ApiResponseCommon>(this.API_PAGES_LIST, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response to Kalgudi page details object type
        map(res => ({ items: res.data.results,  count: res.data.count}))
      );
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPageTags(pageType: PageType, baseProductId: string = ''): Observable<IdValueMap[]> {

    const params = {
      pageType,
      baseProductId,
      appType: this.environment.appId === 'FPO_APP' ? 'fpo' : 'farmer'
    };

    return this.httpClient.get<ApiResponseCommonList>(this.API_PAGE_TAGS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data ? res.data.results : []),
      );
  }

  // --------------------------------------------------------
  // #end region
  // --------------------------------------------------------

}
