import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUsersService, KalgudiUtilityService, LikeShareRequest } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiReportAbuseService } from '@kalgudi/social';

import { KalgudiAnswerAction } from '../../kalgudi-qa-answer-action';
import { KalgudiQaService } from '../../services/kalgudi-qa.service';

@Component({
  selector: 'kl-qa-answer-tile',
  templateUrl: './qa-answer-tile.component.html',
  styleUrls: ['./qa-answer-tile.component.scss']
})
export class QaAnswerTileComponent extends KalgudiAnswerAction<LikeShareRequest> implements OnInit {

  @Input()
  url: string;

  liked = false;

  isLoggedIn: boolean;

  appId: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected kalgudiApp: KalgudiAppService,
    protected usersService: KalgudiUsersService,
    protected qaService: KalgudiQaService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
  ) {

    super(
      notifications,
      kalgudiDialogs,
      kalgudiApp,
      usersService,
      qaService,
      reportAbuse,
      util,
      injector
    );

    this.appId = this.environment.appId;

    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
  }

  /**
   * Emits an event back to the parent to post a like
   */
  likeAnswer() {

    // this.like.emit();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Calls on liked
   * @param res
   */
  protected onPostLiked(res) {

  }
}
