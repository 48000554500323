import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizationPageDetails, PageType } from '@kalgudi/types';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';
import { Directive } from "@angular/core";

@Directive()
export abstract class KalgudiCreateOrganization  extends KalgudiCreatePageType<OrganizationPageDetails> {

  constructor(
    protected fb: FormBuilder
  ) {
    super(fb);
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the Organization name from Page type form
   */
  get organizationName(): AbstractControl {
    return this.pageTypeForm.get('organizationName');
  }


  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      organizationName: ['', Validators.required],
      activity: [''],
      foundedYear: [''],
      founders: [''],
      noOfEmployees: [''],
      vision: ['']
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'ORGANIZATION';
  }

}
