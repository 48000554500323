import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  ApiResponseCommonList,
  IdValueMap,
  KalgudiPageDetails,
  PageType,
  PartialData,
  StreamUrls,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';


/**
 * Page management Api services definition
 */
@Injectable()
export class KalgudiProfilePageApiService {

  /**
   * `v2/pages`
   */
  private readonly API_PAGE_BASE = `${this.env.restBaseUrlV2}/pages`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_TAGS = `${this.API_PAGE_BASE}/tags`;


  /**
   * `v2/pages`
   */
  private readonly API_PAGE_SEARCH = `${this.API_PAGE_BASE}/search`;

  /**
   * `v2/pages/:pageId`
   */
  private readonly API_PAGE_VIEW = `${this.API_PAGE_BASE}/:pageId`;

  /**
   * `v2/pages/:pageId/farm-activities`
   */
  private readonly API_PAGE_SHARES_STREAM = `${this.env.restBaseUrlV2}/pages/:pageId/farm-activities`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private profileState: KalgudiProfileStateService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------



  /**
   * Calls Kalgudi api to get pages
   *
   * @param pageId Unique id of entity
   */
  searchPages(keyword: string, mode: string = '',  pageType = ''): Observable<any> {

    const url = this.API_PAGE_SEARCH;

    const params = {
      mode,
      pageType,
      keyword
    };

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPage(pageId: string): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Api definition for page details updation.
   */
  updatePageDetails(pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageDetails.pageId);

    return this.httpClient.put<ApiResponseCommon>(url, pageDetails)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response to KalgudiPageDetails type
        map(res => res.data)
      );
  }

  /**
   * Api definition for delete dialog box.
   */
  deletePage(pageId: string): Observable<KalgudiPageDetails> {

    const url = this.API_PAGE_VIEW.replace(':pageId', pageId);

    return this.httpClient.delete<ApiResponseCommon>(url)
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map response
        map(res => res.data),
      );
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPageTags(pageType: PageType, baseProductId: string = ''): Observable<IdValueMap[]> {

    const params = {
      pageType,
      baseProductId,
      appType:'farmer'
    };

    return this.httpClient.get<ApiResponseCommonList>(this.API_PAGE_TAGS, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data ? res.data.results : []),
      );
  }

  /**
   * Fetches program share update stream from API.
   *
   * @param entityId Program/Page id for which the stream must be fetched
   * @param offset Starting position of the records
   * @param limit Total count of records to get
   */
  fetchPageShareStream(entityId: string, filter: string, offset: number, limit: number, extraParams: PartialData = {}): Observable<StreamUrls[]> {

    const params = {
      filter,
      offset: offset.toString(),
      limit: limit.toString(),
      ...extraParams,
      ...this.profileState.assistedProfileParams
    };

    const url = this.API_PAGE_SHARES_STREAM.replace(':pageId', entityId);

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r, 202)),
        map(r => {
          // Handler API errors
          // this.streamResponseHandler(r);

          // Map api response to object type
          // const res = this.mapStreamResponse(r);

          // Map the response to the s3 urls array
          return r.data.results;
        }),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
