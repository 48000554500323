import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  OnInit,
  Output,
  Provider,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { kalgudiAnimations } from '@kalgudi/core';

import { KalgudiTaskTagsProductAutocomplete } from '../../classes/task-tags-product-autocomplete';


// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TaskTagsProductAutocompleteComponent),
  multi: true,
};

@Component({
  selector: 'kl-task-tags-product-autocomplete',
  templateUrl: './task-tags-product-autocomplete.component.html',
  styleUrls: ['./task-tags-product-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})

export class TaskTagsProductAutocompleteComponent extends KalgudiTaskTagsProductAutocomplete implements OnInit {

  @Output()
  noProductFound = new EventEmitter();

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder
  ) {
    super(injector, fb);
  }

  ngOnInit() { }

  protected onDestroyed(): void { }

  /**
   * Event emit back to parent to show product name input field
   */
  showProductName() {
    this.noProductFound.emit();
  }

}
