<div id="project-list-table" class="submission-table">

  <mat-card [@floatTop]="{ value: '', params: { delay: 0 }}" class="p-0 overflow-hidden table-card">

    <!-- Search bar -->
    <div class="my-3" fxLayoutAlign="center center">
      <kl-search class="search w-100"
        placeHolder="Type and hit enter to search members"
        (search)="search();"
        [searchForm]="searchForm">
      </kl-search>

      <!-- Bulk members submission -->
      <button class="bulk-members-submission rounded ml-2 border-0" (click)="navigateToSubmissions()">
        Bulk Submissions
      </button>
      <!-- / Bulk members submission -->

    </div>
    <!-- /Search bar -->


    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--Created by column -->
      <ng-container matColumnDef="createdBy" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Members </th>
        <td mat-cell *matCellDef="let element"> {{ element?.createdBy?.firstName }} </td>
      </ng-container>
      <!--/ Created by column -->


      <!-- Submission date column -->
      <ng-container matColumnDef="submissionDate" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Submission date </th>
        <td mat-cell *matCellDef="let element" class="text-secondary">
          <span *ngIf="element?.LUT">
            <span>
              {{ element?.LUT | date:'dd' }}
              {{ element?.LUT | date:'MMM' | translate }}
              {{ element?.LUT | date:'yyyy' }}
            </span>
          </span>
          <span *ngIf="!element?.LUT"> - </span>
        </td>
      </ng-container>
      <!-- / Submission date column -->

      <!-- Status column -->
      <ng-container matColumnDef="status" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center" translate> Status </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <div fxLayout fxLayoutAlign="center center">
            <div class="chip {{ element?.state | lowercase }}">
              <p class="text-white">{{ element?.state | titlecase | trimSpecialCharacters | translate }}</p>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- / Status column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="cursor-pointer" (click)="getSubmissionDetails(row?.taskId, row)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

</div>

