import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiFarmerLandList } from '../../classes/kalgudi-farmer-land-list';

@Component({
  selector: 'kl-farmer-lands',
  templateUrl: './farmer-lands.component.html',
  styleUrls: ['./farmer-lands.component.scss']
})
export class FarmerLandsComponent extends KalgudiFarmerLandList implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialogsService: KalgudiDialogsService
  ) {

    super(injector, notification, util, dialogsService);

  }

  ngOnInit() {
    if (this.openLandDialog) {
      this.showAddLandDetailsDialog();
    }
  }

  onDestroyed(): void {}
}

