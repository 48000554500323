import { NgModule } from '@angular/core';

import { KalgudiMatchMediaService } from './services/match-media.service';
import { KalgudiSidebarService } from './services/sidebar.service';
import { KalgudiSidebarComponent } from './sidebar.component';

/**
 * Shows sidebar similar to MatSideNav. Codebase is taken reference from fuse angular codebase.
 *
 * @see Fuse
 *
 * @usage
 * ```html
 * <kl-sidebar name="recentProjects" class="recent-projects-sidebar" position="right">
 *   <kl-recent-projects></kl-recent-projects>
 * </kl-sidebar>
 * ```
 *
 * Component
 * ```ts
 * constructor(private sidebar: KalgudiSidebarService) {}
 *
 * open() {
 *   this.sidebar.getSidebar('recentProjects').toggleOpen();
 * }
 * ```
 */
@NgModule({
  declarations: [
    KalgudiSidebarComponent
  ],
  exports: [
    KalgudiSidebarComponent
  ],
  providers: [
    KalgudiSidebarService,
    KalgudiMatchMediaService,
  ]
})
export class KalgudiSidebarModule { }
