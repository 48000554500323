import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { kalgudiAnimations, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ChartDirection, MemberStatistics } from '@kalgudi/types';

import { KalgudiProjectStateService } from '../../../../services/kalgudi-project-state.service';
import { KalgudiProjectsListActionsService } from '../../../project-list/services/kalgudi-projects-list-actions.service';

@Component({
  selector: 'kl-project-members-stats',
  templateUrl: './project-members-stats.component.html',
  styleUrls: ['./project-members-stats.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectMembersStatsComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  memberStatsDetails: MemberStatistics;

  @Input()
  memberStatsDirection: ChartDirection;

  @Input()
  showMenu: boolean;

  @Input()
  projectId: string;

  @Output()
  addProjectMembers = new EventEmitter();

  private projectListActionService: KalgudiProjectsListActionsService;
  private projectStateService: KalgudiProjectStateService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {
    super();

    this.projectStateService      = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.projectListActionService = this.injector.get<KalgudiProjectsListActionsService>(KalgudiProjectsListActionsService);

  }

  ngOnInit() { }

  /**
   * Add members to project
   */
  showAddMembersDialog() {
    this.addProjectMembers.emit();
  }
  onDestroyed(): void {}
}
