<section class="task-fullview">

  <!-- Breadcrumbs -->
  <!-- <kl-breadcrumbs></kl-breadcrumbs> -->
  <!-- / Breadcrumbs -->
  <div class="mt-n3 mb-3 task-details">
    <p class="mb-0 text-muted title">{{ taskDetails?.description }}</p>
  </div>
  <div fxLayout fxLayoutAlign="start center" fxLayoutGap="4px" fxHide.gt-xs>
    <svg-icon src="assets/svgs/calendar.svg" class="text-center menu-icons" [applyCss]="true" [svgStyle]="{ 'width.px': 20, 'height.px': 20}"></svg-icon>
    <span class="text-muted date">
      {{ taskDetails?.timeFrame?.start | date:'dd' }}
      {{ taskDetails?.timeFrame?.start | date:'MMM' | translate}}
      {{ taskDetails?.timeFrame?.start | date:'yyyy' }}  to
      {{ taskDetails?.timeFrame?.end | date:'dd' }}
      {{ taskDetails?.timeFrame?.end | date:'MMM' | translate }}
      {{ taskDetails?.timeFrame?.end | date:'yyyy' }}</span>
  </div>

  <!-- Task details section -->
  <kl-task-actions
    [taskDetails]="taskDetails"
    (addTaskMembers)="showAddTaskMembersDialog(taskDetails?.taskId, taskDetails?.projectId)"
    (taskUpdate)="updateTask(taskDetails?.taskId)"
    (taskDelete)="deleteTask(taskDetails?.taskId)"
    (stateUpdate)="updateState()"
  ></kl-task-actions>

  <div class="border-bottom mt-2 mb-1"></div>
  <!-- / Task details section -->

  <section>
    <mat-tab-group [(selectedIndex)]="activeTabIndex" mat-stretch-tabs>

      <!-- Task info tab -->
      <mat-tab [label]="TABS?.INFO?.title | translate">
        <div class="task-fill overflow-hidden" *ngIf="activeTabIndex === TABS?.INFO?.index">
          <kl-task-info [taskDetails]="taskDetails"></kl-task-info>
        </div>
      </mat-tab>
      <!--/ Task info tab -->

      <!-- Updates tab -->
      <mat-tab [label]="TABS?.UPDATES?.title | translate">
        <ng-container *ngIf="activeTabIndex === TABS?.UPDATES?.index">
          <div class="overflow-hidden" fxLayout="column" fxLayoutAlign="space-between">

            <!-- Updates stream -->
            <div class="updates-stream overflow-auto">
              <kl-task-updates-stream [taskId]="taskDetails?.taskId" ></kl-task-updates-stream>
            </div>
            <!--/ Updates stream -->

            <!-- Comment section -->
            <!-- <div fxFlex>
              <kl-task-share-update-form [projectId]="projectId" [taskId]="taskDetails?.taskId" (postCreated)="onPostCreated($event)"></kl-task-share-update-form>
            </div> -->
            <!-- / Comment section -->

          </div>
        </ng-container>
      </mat-tab>
      <!--/ Updates tab -->

      <!-- Details tab -->
      <mat-tab [label]="TABS?.DETAILS?.title"
        *ngIf="taskDetails?.userRole === projectRoles?.MEMBER">
        <div class="task-fill overflow-auto" *ngIf="activeTabIndex === TABS?.DETAILS?.index">
          <kl-task-fill
            [projectId]="projectId"
            [taskId]="taskDetails?.taskId"
            [templateId]="taskDetails?.templateId"
            [taskDetails]="taskDetails">
          </kl-task-fill>
        </div>
      </mat-tab>
      <!--/ Details tab -->

      <!-- Submissions tab -->
      <mat-tab [label]="TABS?.SUBMISSIONS?.title | translate" *ngIf="taskDetails?.userRole === projectRoles?.ADMIN || taskDetails?.userRole === projectRoles?.CONTRIBUTOR">
        <div *ngIf="activeTabIndex === TABS?.SUBMISSIONS?.index">
          <kl-task-submissions [taskId]="taskDetails?.taskId" [projectId]="projectId" [taskDetails]="taskDetails"></kl-task-submissions>
        </div>
      </mat-tab>
      <!--/ Submissions tab -->


    </mat-tab-group>
  </section>

</section>
