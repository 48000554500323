import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-stock-creation-dialog',
  templateUrl: './stock-creation-dialog.component.html',
  styleUrls: ['./stock-creation-dialog.component.scss']
})
export class StockCreationDialogComponent extends KalgudiDialog<StockCreationDialogComponent> implements OnInit {

  constructor(
    protected dialogRef: MatDialogRef<StockCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }

  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {},
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
