import { Component, Injector, Input, OnInit } from '@angular/core';
import { scaleOutAnimation, slideRightAnimation } from '@kalgudi/core';
import { UIMenu } from '@kalgudi/types';

import { KalgudiSurveyListHeader } from '../../classes/kalgudi-survey-list-header';

@Component({
  selector: 'kl-survey-list-header',
  templateUrl: './survey-list-header.component.html',
  styleUrls: ['./survey-list-header.component.scss'],
  animations: [scaleOutAnimation, slideRightAnimation]
})
export class SurveyListHeaderComponent extends KalgudiSurveyListHeader implements OnInit {

  @Input()
  filterTypes: UIMenu[];

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.selectedFilter = this.filterTypes[0];
  }

  onDestroyed(): void {}

}
