import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CORE_LIB_CONFIG } from '@app/config';
import { KalgudiLoadMoreModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';

import { ScheduledUpdatesStreamComponent } from './scheduled-updates-stream.component';
import { SchedulesUpdatesStreamService } from './scheduled-updates-stream.service';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ScheduledUpdatesStreamComponent
  ],
  imports: [
    CommonModule,

    KalgudiShareUpdateModule.forChild(CORE_LIB_CONFIG),
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    TranslateModule

  ],
  exports: [
    ScheduledUpdatesStreamComponent
  ],
  providers: [
    SchedulesUpdatesStreamService
  ]
})
export class ScheduledUpdateStreamModule { }
