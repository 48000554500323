import { Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActivityType, KALGUDI_PAGE_RELATION_MAP } from '@kalgudi/types';

import { TaskInfo } from '../../classes/task-info';

@Component({
  selector: 'kl-task-info',
  templateUrl: './task-info.component.html',
  styleUrls: ['./task-info.component.scss']
})
export class TaskInfoComponent extends TaskInfo implements OnInit, OnChanges {

  expandInfo: boolean;
  expandInput: boolean;
  expandOutput: boolean;

  activityType = ActivityType;
  pageId: string;

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  activeTabIndex: number;

  readonly TABS = {
    UPDATES: { index: 0, title: 'Updates' },
    PRODUCTS: { index: 1, title: 'Products' }
  };


  constructor(protected injector: Injector,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);

    this.activeTabIndex = this.TABS.UPDATES.index;
    this.pageId = this.activatedRoute.snapshot.params.pageId;
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.taskDetails) {
      this.initTemplateSchema(this.taskDetails.templateId);
    }

    if(this.taskDetails.tags) {
      this.expandInfo = this.taskDetails.tags.info && this.taskDetails.tags.info.length && this.taskDetails.tags.info[0] ? true : false;
      this.expandInput = (this.taskDetails.tags.input && this.taskDetails.tags.input.length) && (!this.taskDetails.tags.info && !this.taskDetails.tags.info.length && !this.taskDetails.tags.info[0]) ? true : false;
      this.expandOutput = (this.taskDetails.tags.output && this.taskDetails.tags.output.length) && (!this.taskDetails.tags.input && !this.taskDetails.tags.input.length) && (!this.taskDetails.tags.info && !this.taskDetails.tags.info.length && !this.taskDetails.tags.info[0]) ? true : false;
    }
  }
}
