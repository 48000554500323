<!-- Project creation page -->
<section class="mt-3">
  <kl-project-creation
    [projectId]="dialogData?.data?.projectId"
    [filters]="dialogData?.data?.filters"
    [categoryTiles]="dialogData?.data?.projectCategories"
    (projectCreated)="onProjectCreated($event)">
  </kl-project-creation>
</section>
<!-- / Project creation page -->
