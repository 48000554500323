<section id="program-list" class="p-0 mb-2">

  <div fxLayout="row wrap" fxLayoutGap="20px grid" fxLayout.xs="column" fxLayoutGap.xs="8px">
    <div *ngFor="let page of pagesList; let i = index" fxFlex="50%" fxFlex.xs="100%">
      <div class="cursor-pointer" [@floatTop]="{ value: '', params: {delay: i * 20}}">
        <kl-program-tile
          [pageDetails]="page"
          (click)="onPageSelection(page?.pageId)"
          (showClonePageDialog)="openClonePageDialog(page?.pageId)">
        </kl-program-tile>
      </div>
    </div>
  </div>

</section>

