<div id="traceability">
  <mat-sidenav-container class="nutrition-sidenav-container">
    <mat-sidenav (scroll)="onSidenavScroll($event)" position="end" #sidenav id="sidenav" role="navigation" [opened]="false" class="nutrition-values-sidenav" fixedInViewport="true">

      <div class="nutrition-value-close-button-wrapper">

        <div class="nutrition-value-close-button cursor-pointer" (click)="closeSideNav()">
          <!-- <button mat-icon-button (click)="sidenav.close()">
            <mat-icon>clear</mat-icon>
          </button> -->
          <img src="assets/images/cancel.png" height="48">

        </div>
      </div>

      <mat-nav-list class="sidenav-content-list-wrapper">

        <div id="traceability-about-us" #aboutUsElement>
          <kl-about
            [level3ProductDetails]="level3ProductDetails"
            [description]="productLevel2Details?.description"
            [productLevel2Details]="productLevel2Details">
          </kl-about>
        </div>

        <div id="traceability-nutrients" #nutrientsElement *ngIf="productLevel2Details?.nutrients?.length > 0">
          <kl-nutrition-values
            [nutrients]="productLevel2Details?.nutrients"
            [level3ProductDetails]="level3ProductDetails">
          </kl-nutrition-values>
        </div>

        <div id="traceability-reviews-comments" #ratingElement>
          <kl-review-comments [level2Id]="productLevel2Details?.productLevel2Id"></kl-review-comments>
        </div>

      </mat-nav-list>

    </mat-sidenav>

    <mat-sidenav-content>
      <div class="bg-white">
        <div fxLayout="column" class="container"  fxLayoutAlign="space-between start">

          <div fxLayout="row" class="mb-2 product-header">
            <button mat-button class="arrow" (click)="navigateToPreviousPage()">
              <img src="assets/icons/back-arrow.png">
            </button>
            <span fxFlexAlign="start" class="back-arrow">TRACEABILITY JOURNEY OF {{ level3ProductDetails?.productLevel3Title | uppercase }}
              <span *ngIf="level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value || level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit">
                  <span>(</span>
                  <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value }}</span>
                  <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit | uppercase }}</span>
                  <span> )</span>
              </span>
            </span>
          </div>

          <!--Product details tile section-->
          <section fxLayout="row" fxLayout.xs="column" class="w-100" fxLayout.lt-md="column" fxLayoutGap="5px" fxLayoutAlign="space-between center" fxLayoutAlign.lt-md="center">
            <!-- Product details -->
            <section  fxFlex="60%">
              <kl-product-details
                [productLevel2Details]="productLevel2Details"
                [level3ProductDetails]="level3ProductDetails"
                [sellerDetails]="sellerDetails"
                (toggleSideNav)="openSideNav($event)"
                [sellerProfileInfo]="sellerProfileInfo">
              </kl-product-details>
            </section>
            <!-- Product details -->

            <!-- Catalogue -->
            <section fxFlex="40%">
              <kl-catalogue class="h-100"
                [productLevel3Title]="level3ProductDetails?.productLevel3Title"
                [productLevel2Details]="productLevel2Details"
                [supplierDetails]="supplierDetails"
                [sellerProfileInfo]="sellerProfileInfo"
                [progress]="progress"
                [sellerDetailsProgress]="sellerDetailsProgress">
              </kl-catalogue>
            </section>
            <!-- Catalogue -->
          </section>
        </div>
      </div>

      <!-- Traceability map -->
      <section class="map-section">
        <kl-traceability-map
          [productLevel3Title]="level3ProductDetails?.productLevel3Title"
          [buyerDetails]="buyerDetails"
          [sellerDetails]="supplierDetails"
          [fulfillmentBasedOnStore]="orderDomain ? fulfillmentCenterList[orderDomain] : fulfillmentCenterList['https://andhragreens.com']"
          [orderDetails]="orderDetails"
          [progress]="progress"
        ></kl-traceability-map>
      </section>
      <!-- Traceability map -->

      <!-- Production details -->
      <section class="production-details">
        <kl-production-details
          [productLevel3Title]="level3ProductDetails?.productLevel3Title"
          [productionDetails]="productionDetails"
          [level2ProductDetails]="productLevel2Details"
          [productionAttachments]="productionAttachments"
          [supplierDetails]="supplierDetails">
        </kl-production-details>
      </section>
      <!-- Production details -->

      <!-- Output store production details -->
      <section class="output-production-details" *ngIf="appId === outputStoreId && supplierList && supplierList?.length && orderDetails">
        <kl-output-store-production-details
          [supplierList]="supplierList"
          [orderId]="orderDetails.orderId">
        </kl-output-store-production-details>
      </section>
      <!-- Output store production details -->

      <!-- Procurement details -->
      <section class="procurement-details">
        <kl-procurement-details
          [procurementDetails]="procurementDetails"
          [level2ProductDetails]="productLevel2Details"
          [procurementAttachments]="procurementAttachments"
          [level3ProductDetails]="level3ProductDetails"
          [supplierDetails]="supplierDetails"
          [sellerProfileInfo]="sellerProfileInfo">
        </kl-procurement-details>
      </section>

      <!-- Fulfillment section-->
      <section class="fulfillment-details" *ngIf="trackingDetails">
        <kl-traceability-fulfillment
        [fullFillmentCenterBasedOnStore]="orderDomain && fulfillmentCenterList[orderDomain] ? fulfillmentCenterList[orderDomain] : fulfillmentCenterList['https://andhragreens.com']"
        [trackingDetails]="trackingDetails">
        </kl-traceability-fulfillment>
      </section>
      <!-- Fulfillment section-->
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

