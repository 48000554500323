<div fxLayout="row" class="images">
  <div class="stack-tile" *ngFor="let user of users | slice:0:maxValue; let i = index" [ngClass]="{'images':true, 'ml-n2':i>0}">
    <div>
      <img class="profile-pic-url rounded-circle" [src]="user?.profilePicURL" [title]="user.firstName">
    </div>
  </div>
  <div class="mask rounded-circle ml-n2" *ngIf="users?.length > maxValue">
    <p class="mb-0 count text-white text-center"><b>{{ users?.length - maxValue }}+</b></p>
  </div>
</div>
