<mat-card [@floatTop]="{ value: '', params: {delay: 20}}" class="p-0 mb-1 survey-tile">
  <div fxLayout="row" fxLayoutGap="14px" fxLayoutAlign="start" class="px-2 py-2 w-100">

    <!-- Survey image -->
    <div class="survey-profile-image" fxFlex="22%" fxFlex.xs="calc(20% - 14px)">

      <div class="p-3 default-image-wrapper h-100"  *ngIf="!surveyDetails?.surveyBasicDetails?.logo">

        <img class="w-100 h-100" src="assets/images/survey-user.png">
      </div>

      <img class="w-100 survey-profile h-100" *ngIf="surveyDetails?.surveyBasicDetails?.logo"  width="100" [src]="surveyDetails?.surveyBasicDetails?.logo | prefixDomain">
    </div>
    <!-- /Survey image -->

    <!-- Survey content -->

    <div fxLayout="column" fxFlex="calc(78% - 14px)" fxFlex.xs="65%">
      <div class="w-100" fxLayout fxLayoutAlign="space-between" fxLayoutGap="8px">

        <!-- Survey details -->
        <div fxFlex="calc(85% - 8px)">

          <a class="text-capitalize line-truncate name" [title]="surveyDetails?.surveyBasicDetails?.title">
            {{ surveyDetails?.surveyBasicDetails?.title }}
          </a>

          <p class="mb-0 expiry-date text-danger" fxLayout fxLayoutGap="3px" fxLayoutAlign="start center">
            <svg-icon [applyCss]="true" class="expiry-icon" src="assets/svgs/calendar.svg"
            [svgStyle]="{ 'width.px': 15, 'height.px': 15 }"></svg-icon>
            <span class="expiry-text"><span translate>Expires on</span>
            <span translate>{{ surveyDetails?.surveyBasicDetails?.expiryDate | date:' MMM ' }}</span>
            <span>{{ surveyDetails?.surveyBasicDetails?.expiryDate | date:'dd, ' }}</span>
            <span>{{ surveyDetails?.surveyBasicDetails?.expiryDate | date:'yyyy' }}</span>
          </span></p>

          <span class="">{{ surveyDetails?.surveyBasicDetails?.location?.locationLong }}</span>

          <!-- Status -->
          <p fxHide.gt-xs *ngIf="surveyDetails?.surveyBasicDetails?.isPublished">
            <span class="mb-0 text-success published-text"><strong translate>Published</strong></span>
          </p>
          <!-- /Status -->

        </div>
        <!--/ Survey details -->

        <!-- Status -->
        <div fxHide.xs fxFlex *ngIf="surveyDetails?.surveyBasicDetails?.isPublished">
          <span class="mb-0 text-success published-text" ><strong translate>Published</strong></span>
        </div>
        <!-- /Status -->
      </div>

      <!-- Roles and description -->
      <div fxHide.xs>

        <div class="mt-1">
          <p class="mb-0 text-secondary about-us">{{ surveyDetails?.surveyBasicDetails?.description }}</p>
        </div>

        <!-- Roles -->
        <div class="w-100 mb-n1" fxLayout fxLayoutAlign="space-between center">

          <div fxLayout="row" fxLayoutGap="10px">
            <div *ngIf="surveyDetails?.role === memberRoles.ADMIN">
              <kl-chips [chip]="memberRole?.ADMIN" ></kl-chips>
            </div>

            <div *ngIf="surveyDetails?.role === memberRoles.MEMBER">
              <kl-chips [chip]="memberRole?.MEMBER"></kl-chips>
            </div>

            <!-- Clone chip -->
            <!-- <div class="clone-chip" *ngIf="surveyDetails?.role === memberRoles.ADMIN">
              <mat-chip-list>
                <mat-chip class="w-100 cursor-pointer" (click)="addCloneSurveyDialog(surveyDetails)">
                  Clone
                </mat-chip>
              </mat-chip-list>
            </div> -->
            <!-- Clone chip -->
          </div>

          <!-- Members and response -->
          <div fxLayout="column" fxLayoutAlign="space-between end" class="h-85">
            <div>
              <p class="mb-0 text-center responded-count">
                <span>{{ surveyDetails?.metaData?.memberCount ? surveyDetails?.metaData?.responseCount : 'No' | translate}} </span>
                <span *ngIf="surveyDetails?.metaData?.memberCount"> / </span>
                <span>{{ surveyDetails?.metaData?.memberCount || 'Members' | translate}}</span>
              </p>

              <p class="mb-0 text-center" translate>Responded</p>
            </div>
          </div>
          <!-- / Members and respons -->
        </div>
      </div>
      <!--/ Roles and description -->
    </div>

    <!-- /Survey content -->


  </div>

  <!-- Roles and description for mobile-->
  <div class="px-2 pb-1" fxHide.gt-xs>

    <div>
      <p class="mb-0 text-secondary about-us">{{ surveyDetails?.surveyBasicDetails?.description }}</p>
    </div>
    <div class="w-100 mt-1" fxLayout fxLayoutAlign="space-between center">

      <div fxLayout="row" fxLayoutGap="10px">
        <div *ngIf="surveyDetails?.role === memberRoles.ADMIN">
          <kl-chips [chip]="memberRole?.ADMIN" ></kl-chips>
        </div>

        <div *ngIf="surveyDetails?.role === memberRoles.MEMBER">
          <kl-chips [chip]="memberRole?.MEMBER"></kl-chips>
        </div>

        <!-- Clone chip -->
        <!-- <div class="clone-chip" *ngIf="surveyDetails?.role === memberRoles.ADMIN">
          <mat-chip-list>
            <mat-chip class="w-100 cursor-pointer" (click)="addCloneSurveyDialog(surveyDetails)">
              Clone
            </mat-chip>
          </mat-chip-list>
        </div> -->
        <!-- Clone chip -->
      </div>

      <!-- Members count and response -->
      <div fxLayout="column" fxLayoutAlign="space-between end" class="h-85">

        <div>
          <p class="mb-0 text-center responded-count">
            <span>{{ surveyDetails?.metaData?.memberCount ? surveyDetails?.metaData?.responseCount : 'No' | translate }} </span>
            <span *ngIf="surveyDetails?.metaData?.memberCount"> / </span>
            <span>{{ surveyDetails?.metaData?.memberCount || 'Members' | translate }}</span>
          </p>

          <p class="mb-0 text-center" translate>Responded</p>
        </div>
      </div>
      <!--/ Members count and response -->
    </div>
  </div>
  <!--/ Roles and description for mobile-->

</mat-card>

