import { Injectable } from '@angular/core';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { ProductAutoCompleteApiService } from './product-autocomplete-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductAutoCompleteService {

  constructor(private productAutoCompleteApiService: ProductAutoCompleteApiService) { }

  /**
   * Gets the base product prediction for the specified search term.
   */
  getProductsPredictions(
    keyword: string,
    storeType: string,
    filters: PartialData = {},
    offset: number = 0,
    limit: number = 30,
  ): Observable<any[]> {
    return this.productAutoCompleteApiService.getProductsPredictions(keyword, storeType, offset, limit, filters);
  }
}
