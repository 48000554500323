import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { KalgudiCreateOrganization } from '../../classes/kalgudi-create-organization-page';
import { KalgudiCreatePageService } from '../../services/kalgudi-create-page.service';

@Component({
  selector: 'kl-organization-page-creation',
  templateUrl: './organization-page-creation.component.html',
  styleUrls: ['./organization-page-creation.component.scss']
})
export class OrganizationPageCreationComponent extends KalgudiCreateOrganization implements OnInit {

  employeesList: string[] = ['up-to 100', 'more than 100', 'up-to 200', 'more than 200', 'up-to 300'];

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected fb: FormBuilder,
    protected createPageService: KalgudiCreatePageService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(fb);
  }

  ngOnInit() {
    this.updateDateLocale();

    this.initPageForm();
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
