import { Injector, Input, Directive } from '@angular/core';
import { PageAnalytics } from '@kalgudi/types';

import { KalgudiPageAnalyticsService } from '../services/page-analytics-activities.service';

@Directive()
export abstract class KalgudiPageCoverageAnalytics {

  @Input()
  pageId: string;

  pageAnalytics: PageAnalytics;
  pageProductsAnalytics: any;
  pageMembersAnalytics: any;
  pageLocationAnalytics: any;


  private pageAnalyticsService: KalgudiPageAnalyticsService;

  constructor(
    protected injector: Injector,
  ) {
    this.pageAnalyticsService = this.injector.get(KalgudiPageAnalyticsService);
  }

  /**
   * Calls api method to get shares stats
   */
  fetchPageAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageAnalytics(pageId)
      .subscribe(res => {
        this.pageAnalytics = res;
        this.pageMembersAnalytics = this.convertObjectToArray(res.memberTypeStats);
      });
  }

  /**
   * Calls api method to get page product stats
   */
  fetchPageProductsAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageCoverageAnalytics(pageId, 'PRODUCTS')
      .subscribe(res => {
        this.pageProductsAnalytics = this.covertToProductsChartData(res.results);
      });
  }

  /**
   * Calls api method to get page member stats
   */
  // fetchPageMembersAnalytics(pageId: string) {
  //   this.pageAnalyticsService.fetchPageCoverageAnalytics(pageId, 'MEMBERS')
  //     .subscribe(res => {
  //       this.pageMembersAnalytics = this.covertToProductsChartData(res.results);
  //       console.log('members', this.pageMembersAnalytics);
  //     });
  // }

  /**
   * Calls api method to get page location stats
   */
  fetchPageLocationAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageCoverageAnalytics(pageId, 'LOCATIONS')
      .subscribe(res => {
        this.pageLocationAnalytics = this.covertToLocationChartData(res.results);
      });
  }

  /**
   * Initializes the page analytics activities
   */
  protected initStream() {
    this.fetchPageAnalytics(this.pageId);
    this.fetchPageProductsAnalytics(this.pageId);
    this.fetchPageLocationAnalytics(this.pageId);
    // this.fetchPageMembersAnalytics(this.pageId);
  }


  /**
   * Maps the response into location chart data model
   * @param locationStats
   */
  private covertToLocationChartData(locationStats: any[]) {
    return locationStats.map(stat => {
      return {
        name:  stat.locationName,
        value: stat.count
      };
    });

  }

  /**
   * Maps the response into products chart data model
   * @param productStats
   */
  private covertToProductsChartData(productStats: any[]) {
    return productStats.map(stat => {
      return {
        name:  stat.productName,
        value: stat.count
      };
    });

  }

  /**
   * Convert an object to the array
   */
  private convertObjectToArray(obj: any): any[] {

    const arr = [];

    Object.entries(obj).forEach(i => {
      arr.push({
        name: i[0],
        value: i[1]
      });
    });

    return arr;
  }
}
