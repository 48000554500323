import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults, KalgudiUserSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiProfileSearchResponse, ApiResponsePageMemberSearch, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiShareSearchMembersApiService {

  /**
   * `v2/pages/search/members`
   */
  private readonly API_SEARCH_MEMBERS = `${this.env.restBaseUrlV2}/pages/search/members`;

  /**
   * `v2/profile/search`
   */
  private readonly API_PROFILE_SEARCH = `${this.env.restBaseUrlV2}/profiles/search`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Makes an API call to search list of all entity members with the specified
   * search keyword.
   */
  searchMembers(
    pageId: string,
    text: string,
    offset: number,
    limit: number
  ): Observable<KalgudiUserBasicSearchResults> {

    const params = {
      text,
      pageId,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient.get<ApiResponsePageMemberSearch>(this.API_SEARCH_MEMBERS, { params })
      .pipe(

        // Check for API errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to Kalgudi user search result type
        map(res => ({ items: res.data.results, count: res.data.count }))
      );
  }

  /**
   * Makes an API call to search list of all members with the specified
   * search keyword.
   */
  searchEntityMembers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams: PartialData = {},
  ): Observable<KalgudiUserSearchResults> {

    const params = {
      q: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      // source: this.env.appId,
      ...extraParams,
    };

    return this.httpClient.get<ApiProfileSearchResponse>(this.API_PROFILE_SEARCH, { params })
      .pipe(

        // Check for API errors
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi user search result type
        map(res => ({ count: res.data.count, items: res.data.results }))
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
