/**
 * Page creation constants
 */

export const PAGE_CREATION_STATES_MAP = {
  CHOOSE_PAGE_TYPE: 'CHOOSE_PAGE_TYPE',
  FILL_COMMON_PAGE_DETAILS: 'FILL_COMMON_PAGE_DETAILS',
  FILL_SPECIFIC_PAGE_DETAILS: 'FILL_SPECIFIC_PAGE_DETAILS',
  CREATE_PAGE: 'CREATE_PAGE',
  PAGE_CREATED: 'PAGE_CREATED',
};

export const PAGE_TYPES = {
  PROGRAM: 'PROGRAM',
  UNIVERSITY: 'UNIVERSITY',
  ORGANIZATION: 'ORGANIZATION',
  COMPANY: 'COMPANY',
  FARMER: 'FARMER',
  STOCK: 'STOCK',
  PROCESSING: 'PROCESSING',
  SALES_TEAMS: 'SALES_TEAMS',
  TRAINING: 'TRAINING',
  FIG:'FIG'
};

export declare type PageCreationStates = keyof typeof PAGE_CREATION_STATES_MAP;

export interface PageCreationStateData {
  data: any;
}

export interface PageCreationStateDataMap {
  CHOOSE_PAGE_TYPE: PageCreationStateData;
  FILL_COMMON_PAGE_DETAILS: PageCreationStateData;
  FILL_SPECIFIC_PAGE_DETAILS: PageCreationStateData;
  CREATE_PAGE: PageCreationStateData;
  PAGE_CREATED: PageCreationStateData;
}


export interface PageTypeList {
  desc?: string;
  svgIcon?: string;
  title?: string;
  selected?: boolean;
  value?: string;
}
