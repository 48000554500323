import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { AddProjectMembersService } from '../services/add-project-members.service';

@Directive()
export abstract class ManageProjectMembers extends KalgudiInboxStream<KalgudiUserBasicDetails> {

  @Input()
  projectId: string;

  private addProjectMemberService: AddProjectMembersService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService
  ) {

      super(notification, util);

      this.addProjectMemberService = this.injector.get<AddProjectMembersService>(AddProjectMembersService);

      this.pageLimit = 10;

    }

  /**
   * Deletes the user from the list
   * @param profileKey
   */
  removeSelectedUser(profileKey: string) {
    this.addProjectMemberService.deleteProjectMembers(this.projectId, profileKey)
      .subscribe(
        res => {
          this.projectMembersDeleteHandler(res);
          this.resetStream();
        },
        err =>  this.projectMembersDeleteErrHandler(err)
      );
  }

  /**
   * Event handler for successful project members deletion
   */
  protected projectMembersDeleteHandler(res) {
    this.notification.showMessage('Deleted member successfully');
  }

  /**
   * Event handler for failed to delete project members
   */
  protected projectMembersDeleteErrHandler(err: Error) {
    this.notification.showMessage('Failed to delete member');
  }


  /**
   * Calls stream api method to get members list
   * @param offset
   * @param limit
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.addProjectMemberService.getProjectMembers(this.projectId, offset, limit);
  }

}
