<div fxLayout="column" class="px-3 satellite-advisories-land-details">

  <div class="pb-2" *ngIf="showMap"
    fxLayout fxLayoutAlign="space-between center">
    <div class="location-map position-relative">
      <kl-geo-location-marker
        [zoom]="19"
        [disabled]="true"
        [location]="landDetails?.location"
        [polygonPath]="landDetails?.geoFences">
      </kl-geo-location-marker>

      <div class="position-absolute land-details px-3" fxLayout fxLayoutAlign="center center">
        <p class="m-0 land-size">{{ landDetails?.landSize?.value}}
          <span>{{landDetails?.landSize?.unit}}</span>
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="landDetails?.landSize || landDetails?.location?.locationLong" (click)="showLandMap()"
    fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="start center" class="place cursor-pointer"
    [ngClass]="{'no-action': disableLinkAction}">
    <div>
      <mat-icon class="location-icon">location_on</mat-icon>
    </div>
    <div>
      <p class="m-0 location">
        <span>{{ landDetails?.landSize?.value}}
        <span>{{landDetails?.landSize?.unit}}</span></span>
        <span *ngIf="landDetails?.landSize && landDetails?.location?.locationLong"> in </span>
        {{landDetails?.location?.locationLong}}</p>
    </div>
  </div>


</div>

