import { ExtraOptions } from '@angular/router';
import { KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import { UIMenu } from '@kalgudi/types';

// import { PreloadSameRouteModulesService } from '../services/preload-same-route-modules.service';

/**
 * Single place export for all Kalgudi Core constants
 */

/**
 * `true` if the device running the app is a mobile device,
 * otherwise `false`.
 *
 * @see https://redstapler.co/detect-mobile-device-with-javascript/
 */
export function checkMobileDevice(): boolean {
  return (!!(
    (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) ||
    window.innerWidth < 600
  ));
}

export const isMobileDevice = checkMobileDevice();


/**
 * Default router configuration for all common Kalgudi angular 8+ apps.
 *
 * @example
 * RouterModule.forRoot(routes, {
 *  ...CORE_ROUTE_EXTRA_OPTIONS,
 *
 *  // Your custom options
 * }),
 *
 * @default
 * {
 *   // Use hash prefix in the base of the URL
 *   useHash: true,
 *
 *   // Ensure to pass parent route path params to child params
 *   // NOTE: Removal of this causes all dependent child on its
 *   // parent route params to break.
 *   paramsInheritanceStrategy: 'always',
 *
 *   // Ensure to restore the scroll position
 *   // This only works with static data, it does not works
 *   // if route data is cleared.
 *   scrollPositionRestoration: 'enabled',
 *
 *   // Preload specific lazy loaded routes.
 *   // This ensure better performance of the lazy loaded modules.
 *   // Set `data: { preload: true } ` flag to lazy loaded modules
 *   // whom you want to preload.
 *   // NOTE: Don't preload all modules as it will negatively impact performance.
 *   preloadingStrategy: PreloadSameRouteModulesService,
 * }
 */
export const CORE_ROUTE_EXTRA_OPTIONS: ExtraOptions = {

    // Use hash prefix in the base of the URL
    useHash: true,

    // Ensure to pass parent route path params to child params
    // NOTE: Removal of this causes all dependent child on its
    // parent route params to break.
    paramsInheritanceStrategy: 'always',

    // Ensure to restore the scroll position
    // This only works with static data, it does not works
    // if route data is cleared.
    scrollPositionRestoration: 'enabled',

    // Preload specific lazy loaded routes.
    // This ensure better performance of the lazy loaded modules.
    // Set `data: { preload: true } ` flag to lazy loaded modules
    // whom you want to preload.
    // NOTE: Don't preload all modules as it will negatively impact performance.
    // preloadingStrategy: PreloadSameRouteModulesService,
    relativeLinkResolution: 'legacy'
};


/**
 * Kalgudi environment configuration for production. Common production environment
 * configuration for all apps.
 *
 * @example
 * export const environment: KalgudiEnvironmentConfig = {
 *   ...KalgudiEnvironmentConfig,
 *   domain: 'https://kalgudi.com',
 * };
 *
 * @default
 * {
 *   production: true,
 *   awsAccessKey: ``,
 *   s3BucketUrl : `https://kalgudidata.s3.amazonaws.com`,
 *   consumerStoreDomain: 'https://store.kalgudi.com/',
 *   bizStoreDomain: 'https://outputs.kalgudi.com/',
 *   farmerStoreDomain: 'https://inputs.kalgudi.com/',
 *   domain: 'https://kalgudi.com',
 *   kalgudiDomain: 'https://kalgudi.com',
 *   restBaseUrl: 'https://kalgudi.com/rest/v1',
 *   googlePlacesApi: 'KEY',
 * }
 *
 */
export const DEFAULT_ENVIRONMENT_PROD: KalgudiEnvironmentConfig = {
  production: true,
  awsAccessKey: ``,
  s3BucketUrl : `https://kalgudidata.s3.amazonaws.com`,
  consumerStoreDomain: 'https://store.kalgudi.com/',
  bizStoreDomain: 'https://outputs.kalgudi.com/',
  farmerStoreDomain: 'https://inputs.kalgudi.com/',
  dirUrl: `https://dir.kalgudi.com`,
  kalgudiDomain: 'https://kalgudi.com',
  coreDomain: `https://core.kalgudi.com`,
  domain: 'https://core.kalgudi.com',
  restBaseUrl: 'https://core.kalgudi.com/rest/v1',
  restBaseUrlV2: 'https://core.kalgudi.com/v2',
  appId: 'KALGUDI_CORE',
  useHash: false,

  googlePlacesApi: 'AIzaSyAU7egCYit5sYtkB15QkQCMoA4L4-fM9Xk',
};

const DEV_HOST = 'devkalgudi.vasudhaika.net';

/**
 * Kalgudi environment configuration for production. Common production environment
 * configuration for all apps.
 *
 * @example
 * export const environment: KalgudiEnvironmentConfig = {
 *   ...KalgudiEnvironmentConfig,
 *   domain: 'https://kalgudi.com',
 * };
 *
 * @default
 * {
 *   production: false,
 *   awsAccessKey: ``,
 *   s3BucketUrl: `https://www.devkalgudi.vasudhaika.net/s3/devkalgudidata`,
 *   consumerStoreDomain: `https://www.devkalgudi.vasudhaika.net/storefront`,
 *   bizStoreDomain: `https://www.devkalgudi.vasudhaika.net/trade`,
 *   farmerStoreDomain: `https://www.devkalgudi.vasudhaika.net/farmer`,
 *   kalgudiDomain: `https://www.devkalgudi.vasudhaika.net`,
 *   domain: `https://www.devkalgudi.vasudhaika.net`,
 *   restBaseUrl: `https://www.devkalgudi.vasudhaika.net/rest/v1`,
 *   restBaseUrlV2: `https://www.devkalgudi.vasudhaika.net/v2`,
 *   googlePlacesApi: 'KEY',
 * }
 *
 */
export const DEFAULT_ENVIRONMENT_DEV: KalgudiEnvironmentConfig = {
  production: false,
  awsAccessKey: ``,
  s3BucketUrl: `https://${DEV_HOST}/s3/devkalgudidata`,
  consumerStoreDomain: `https://${DEV_HOST}/storefront`,
  bizStoreDomain: `https://${DEV_HOST}/trade`,
  farmerStoreDomain: `https://${DEV_HOST}/farmer`,
  dirUrl: `https://dir.kalgudi.com`,
  kalgudiDomain: `https://${DEV_HOST}`,
  coreDomain: `https://${DEV_HOST}/core`,
  domain: `https://${DEV_HOST}`,
  restBaseUrl: `https://${DEV_HOST}/rest/v1`,
  restBaseUrlV2: `https://${DEV_HOST}/v2`,
  appId: 'KALGUDI_CORE_DEV',

  useHash: false,

  googlePlacesApi: 'AIzaSyAM1rm3Chd60HQyQ6SwsJ0L_NIA5W7OBCY',
};

const NEW_DEVKALGUDI_HOST = window.location.host.includes('localhost') ? 'devkalgudi.com' : window.location.host;


/**
 * Kalgudi environment configuration for new dev environment. Common new dev environment
 * configuration for all apps.
 *
 * @example
 * export const environment: KalgudiEnvironmentConfig = {
 *   ...DEFAULT_ENVIRONMENT_NEW_DEV,
 *
 * };
 *
 * @default
 * development: true,
  production: false,
  useHash: false,
  awsAccessKey: ``,
  adminDomain: `https://${NEW_DEVKALGUDI_HOST}/admin`,
  s3BucketUrl: `https://devawskalgudidata.s3.amazonaws.com`,
  consumerStoreDomain: `https://store.devkalgudi.com`,
  bizStoreDomain: `https://outputs.devkalgudi.com`,
  farmerStoreDomain: `https://inputs.devkalgudi.com`,
  dirUrl: `https://dir.devkalgudi.com`,
  kalgudiDomain: `https://${NEW_DEVKALGUDI_HOST}`,
  coreDomain: `https://devkalgudi.com`,
  domain: `https://${NEW_DEVKALGUDI_HOST}`,
  restBaseUrl: `https://${NEW_DEVKALGUDI_HOST}/rest/v1`,
  restBaseUrlV2: `https://${NEW_DEVKALGUDI_HOST}/v2`,
  appId: 'KALGUDI_CORE',
  googlePlacesApi: 'AIzaSyAM1rm3Chd60HQyQ6SwsJ0L_NIA5W7OBCY',

  firebaseConfig: {
    apiKey: 'AIzaSyDDxFLzN2sEuScGBTEAqS6z5uw-tKc1DmI',
    authDomain: 'kalgudi-core-dev.firebaseapp.com',
    databaseURL: 'https://kalgudi-core-dev.firebaseio.com',
    projectId: 'kalgudi-core-dev',
    storageBucket: 'kalgudi-core-dev.appspot.com',
    messagingSenderId: '319540342584',
    appId: '1:319540342584:web:3330d4a9593edab3755bcd'
  }
 *
 */

export const DEFAULT_ENVIRONMENT_NEW_DEV: KalgudiEnvironmentConfig = {
  development: true,
  production: false,
  useHash: false,
  awsAccessKey: ``,
  adminDomain: `https://${NEW_DEVKALGUDI_HOST}/admin`,
  s3BucketUrl: `https://devawskalgudidata.s3.amazonaws.com`,
  consumerStoreDomain: `https://store.devkalgudi.com`,
  bizStoreDomain: `https://outputs.devkalgudi.com`,
  farmerStoreDomain: `https://inputs.devkalgudi.com`,
  dirUrl: `https://dir.devkalgudi.com`,
  kalgudiDomain: `https://${NEW_DEVKALGUDI_HOST}`,
  coreDomain: `https://devkalgudi.com`,
  domain: `https://${NEW_DEVKALGUDI_HOST}`,
  restBaseUrl: `https://${NEW_DEVKALGUDI_HOST}/rest/v1`,
  restBaseUrlV2: `https://${NEW_DEVKALGUDI_HOST}/v2`,
  appId: 'KALGUDI_CORE',
  googlePlacesApi: 'AIzaSyAM1rm3Chd60HQyQ6SwsJ0L_NIA5W7OBCY',

  firebaseConfig: {
    apiKey: 'AIzaSyDDxFLzN2sEuScGBTEAqS6z5uw-tKc1DmI',
    authDomain: 'kalgudi-core-dev.firebaseapp.com',
    databaseURL: 'https://kalgudi-core-dev.firebaseio.com',
    projectId: 'kalgudi-core-dev',
    storageBucket: 'kalgudi-core-dev.appspot.com',
    messagingSenderId: '319540342584',
    appId: '1:319540342584:web:3330d4a9593edab3755bcd'
  }
};


export const DEFAULT_PROFILE_PIC = '/assets/images/default-profile.jpeg';

/*
**View type list
*/
export const VIEW_TYPE_LIST: UIMenu[] = [
  {
    id: 'GRID',
    svgIcon: 'assets/svgs/grid-view.svg',
    title: 'grid view'
  },
  {
    id: 'LIST',
    svgIcon: 'assets/svgs/list-view.svg',
    title: 'list view'
  },
];

export enum LoginSource {
  MICROSOFT_365 = 'MICROSOFT_365'
}



/**
 * Export other constants
 */
export * from './http-status-codes';
export * from './rest-api-error-messages';
export * from './kalgudi-form-validators';
export * from './kalgudi-business-types';
export * from './kalgudi-profile-types';
export * from './kalgudi-s3-policy-map';
export * from './social';
export * from './material-date-format';
