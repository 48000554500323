import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kl-fake-it-block',
  templateUrl: './fake-it-block.component.html',
  styleUrls: ['./fake-it-block.component.scss']
})
export class FakeItBlockComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
