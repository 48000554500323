<div class="common-profile-header py-2 mb-2" fxLayout fxLayoutAlign="space-between center">
  <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

    <div>
      <svg-icon [applyCss]="true" class="text-center" [src]="svgIcon"
        [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
    </div>

    <div>
      <p class="m-0 title font-weight-bold" translate>{{title}}</p>
    </div>
  </div>

  <div *ngIf="editable && !isPublicPage">
    <button mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end"
      (click)="updateTile()">
      <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">{{actionIcon || 'edit'}}</mat-icon>
    </button>
  </div>

</div>
