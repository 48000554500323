import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TasksTileComponent } from './components/tasks-tile/tasks-tile.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    TasksTileComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,

    FlexLayoutModule,
    AngularSvgIconModule,

    KalgudiPipesModule,
    TranslateModule
  ],
  exports: [
    TasksTileComponent,
  ]
})
export class KalgudiTaskTileModule { }
