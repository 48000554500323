import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DEFAULT_DIALOG_CONFIG } from '../../kalgudi-dialogs/constants';
import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../../mobile-dialog';
import { ConnectsPickerDialogComponent } from '../components/connects-picker-dialog/connects-picker-dialog.component';
import {
  ConnectsPickerMobileDialogComponent,
} from '../components/connects-picker-mobile-dialog/connects-picker-mobile-dialog.component';

@Injectable()
export class KalgudiConnectsPickerService {

  constructor(
    private dialogsService: KalgudiDialogsService
  ) { }

  /**
   * Shows an kalgudi connects picker dialog box. Returns an observable of `KalgudiDialogResult`.
   *
   * @param details Dialog details
   * @param config Dialog configuration
   */
  showConnectsPicker(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(ConnectsPickerDialogComponent, details, config);
  }

  /**
   * Opens program share update form for mobil view
   */
  showMobileConnectsPicker(
    details: KalgudiDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(ConnectsPickerMobileDialogComponent, details);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
