<!-- separator -->
<div class="separator mx-1 my-2"></div>
<!-- /separator -->

<footer id="footer">
  <div class="container">
    <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
      <!-- Footer menu -->
      <div fxLayout="row" class="footer-links" fxLayoutGap="16px" fxLayoutAlign="start center"
          fxLayout.md="row wrap" fxLayoutAlign.md="center center"
          fxLayout.sm="row wrap" fxLayoutAlign.sm="center center" *ngIf="env?.appId !== 'GEOAGROPRO'">
        <div class="footer-menu">
          <a href="{{ env?.appId === 'PRORISE_CORE' ? 'https://prorisestore.com/about/about-us' : 'https://kalgudi.com/about.html' }}" target="_blank" class="mb-0" translate>About</a>
        </div>

        <!-- Help Menu -->
        <div class="footer-menu" fxLayout="row" fxLayoutAlign="start center" [matMenuTriggerFor]="helpMenu"
          #helpMenuTrigger="matMenuTrigger" (mouseenter)="helpMouseEnter(helpMenuTrigger)" (mouseleave)="helpMouseLeave(helpMenuTrigger)">
          <a class="mb-0 hover-menu" translate>Help</a>
          <mat-icon class="cursor-pointer">arrow_drop_down</mat-icon>
          <mat-menu #helpMenu="matMenu" class="mat-menu-sm" [hasBackdrop]="false">
            <span (mouseenter)="helpMouseEnter(helpMenuTrigger)" (mouseleave)="helpMouseLeave(helpMenuTrigger)">
              <a href="{{ env?.appId === 'PRORISE_CORE' ? 'http://www.fertis.com/news' : 'https://kalgudi.blogspot.com/' }}" target="_blank" mat-menu-item translate>Blogs</a>
              <a href="{{ env?.appId === 'PRORISE_CORE' ? 'https://www.youtube.com/channel/UCo80fcfy_RL0qqN0ZKB7pog/playlists' : 'https://www.youtube.com/channel/UCF8fuQzuR5TMcNVjAuusFEQ' }}" target="_blank" mat-menu-item translate>Videos</a>
              <a *ngIf="env?.appId !== 'PRORISE_CORE'" href="https://kalgudi.com/app/web_index.html" target="_blank" mat-menu-item translate>FAQ's</a>
              <a *ngIf="env?.appId !== 'PRORISE_CORE'" href="https://kalgudi.com/trustTrank.html " target="_blank" mat-menu-item translate>Insights</a>
            </span>
          </mat-menu>
        </div>
        <!-- /Help Menu -->

        <!-- Contact Menu -->
        <div class="footer-menu" fxLayout="row" *ngIf="env?.appId !== 'PRORISE_CORE'" fxLayoutAlign="start center" [matMenuTriggerFor]="contactMenu"
          #contactMenuTrigger="matMenuTrigger" (mouseenter)="contactMouseEnter(contactMenuTrigger)" (mouseleave)="contactMouseLeave(contactMenuTrigger)">
          <a class="mb-0 hover-menu" translate>Contact</a>
          <mat-icon class="cursor-pointer">arrow_drop_down</mat-icon>
          <mat-menu #contactMenu="matMenu" class="mat-menu-sm" [hasBackdrop]="false">
            <span (mouseenter)="contactMouseEnter(contactMenuTrigger)" (mouseleave)="contactMouseLeave(contactMenuTrigger)">
              <a href="https://kalgudi.com/writeToUs.html" target="_blank" mat-menu-item translate>Write to us</a>
              <a href=" " target="_blank" mat-menu-item translate>Email</a>
            </span>
          </mat-menu>
        </div>
        <!--/ Contact Menu -->

        <!-- Legal Menu -->
        <div class="footer-menu" fxLayout="row" *ngIf="env?.appId !== 'PRORISE_CORE'" fxLayoutAlign="start center" [matMenuTriggerFor]="legalMenu"
          #legalMenuTrigger="matMenuTrigger" (mouseenter)="legalMouseEnter(legalMenuTrigger)" (mouseleave)="legalMouseLeave(legalMenuTrigger)">
          <a class="mb-0 hover-menu" translate>Legal</a>
          <mat-icon class="cursor-pointer">arrow_drop_down</mat-icon>
          <mat-menu #legalMenu="matMenu" class="mat-menu-sm" [hasBackdrop]="false">
            <span (mouseenter)="legalMouseEnter(legalMenuTrigger)" (mouseleave)="legalMouseLeave(legalMenuTrigger)">
              <a href="https://kalgudi.com/privacypolicy.html" target="_blank" mat-menu-item translate>Privacy</a>
              <a href="https://kalgudi.com/termsandconditions.html" target="_blank" mat-menu-item translate>Terms</a>
              <a href="https://kalgudi.com/copyright.html" target="_blank" mat-menu-item translate>Copyright</a>
              <a href="https://kalgudi.com/postingrules.html" target="_blank" mat-menu-item translate>Posting rules</a>
            </span>
          </mat-menu>
        </div>
        <!-- /Legal Menu -->
      </div>
      <!--/ Footer menu -->

      <!-- Kalgudi -->
      <div>
        <div fxLayout="row" fxLayoutGap="6px">
          <div fxLayout="row" fxLayoutGap="6px">
            <div class="footer-logo">
              <img width="100%" height="100%"
                [src]="env?.appId === 'KALGUDI_CORE' ? 'assets/kalgudi-core/icons/favicon.png' : appConfig?.appLogoUrl"
                [alt]="appConfig?.title || 'Kalgudi'"/>
            </div>
            <span translate>{{ appConfig?.title ||  'Kalgudi'}}</span>
          </div>
          <div>
            <p class="float">
              <span><i class="fa fa-copyright"></i></span>
              <span> {{ year }} </span>
            </p>
          </div>
        </div>
      </div>
      <!-- /Kalgudi -->
    </div>
  </div>
</footer>
