<main id="kl-page-survey-member-add" *ngIf="(pageId$ | async) as pageId">

  <section class="members-section">


    <mat-tab-group [(selectedIndex)]="activeTabIndex" mat-stretch-tabs>


      <mat-tab [label]="TABS?.PAGE_MEMBERS?.title | translate">
        <div class="survey-members-add h-100" fxLayout="column" *ngIf="activeTabIndex === TABS?.PAGE_MEMBERS?.index">
          <div fxFlex="92%">
            <kl-programs-member-picker [pageId]="pageId"
              [multiSelect]="true" [(selectedUsersMap)]="selectedUsersMap">
            </kl-programs-member-picker>
            <!-- <kl-user-picker [multiSelect]="true" [(selectedUsersMap)]="selectedUsersMap"></kl-user-picker> -->
          </div>

          <div class="pt-0 mt-4" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
            <kl-flat-button
              [label]="'Add members' | translate"
              [progress]="false"
              [disabled]="!selectedUsers?.length"
              (clicked)="addPageMembers()"
              >
            </kl-flat-button>
          </div>
        </div>
      </mat-tab>

      <mat-tab [label]="TABS?.TARGET_MEMBERS?.title | translate">
        <div class="task-fill mt-3" *ngIf="activeTabIndex === TABS?.TARGET_MEMBERS?.index">

          <ng-container *ngIf="(memberTargetingFilters$ | async) as filters">

            <!-- Gender -->
            <div class="mb-1" fxLayout fxLayoutAlign="start center" [formGroup]="filterFormGroup" *ngIf="(env?.appId === 'SAM_FARMER' || env?.appId === 'SAM_FPO')">
              <div class="mt-n1">
                <span class="mr-1" translate>Gender: </span>
              </div>
              <mat-radio-group aria-label="Select an option" class="mt-1" formControlName="gender">
                <mat-radio-button value="" class="mr-4">{{ "All" | translate }}</mat-radio-button>
                <mat-radio-button value="MALE" class="mr-4">{{ "Male" | translate }}</mat-radio-button>
                <mat-radio-button value="FEMALE" class="mr-4">{{ "Female" | translate }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <!--/ Gender -->

            <kl-page-target-members
              [pageId]="pageId"
              [hideTargetAudienceCount]="false"
              [productsList]="filters?.products"
              [productsFormArray]="productsFilters"
              [organizationsList]="filters?.products"
              [organizationsFormArray]="productsFilters"
              [businessTypesList]="filters?.businessTypes"
              [businessTypesFormArray]="businessTypesFilters"
              [locationsList]="filters?.locations"
              [locationsFormArray]="locationsFilters"
              [countriesList]="filters?.countries"
              [countriesFormArray]="countriesFilters"
              [statesList]="filters?.states"
              [statesFormArray]="statesFilters"
              [districtsList]="filters?.districts"
              [districtsFormArray]="districtsFilters">

            </kl-page-target-members>
          </ng-container>
          <div fxLayout="row" fxLayoutAlign="end end">
            <div fxLayoutAlign="end end" fxFlex="170px">
              <div fxFlex="100%">
                <kl-flat-button label="Target members" (clicked)="addTargetedMembers()"></kl-flat-button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab [label]="TABS?.BULK_MEMBERS?.title | translate">
        <div class="mt-3" *ngIf="activeTabIndex === TABS?.BULK_MEMBERS?.index">
          <kl-bulk-members-addition (loginIdsList)="addBulkMembers($event)"></kl-bulk-members-addition>
        </div>
      </mat-tab>

    </mat-tab-group>
  </section>


</main>
