  <button id="attach-url-button" mat-button color="primary" fxLayoutAlign="center center" *ngIf="!showChipButton"
    [ngClass]="{'mat-button': !buttonType, 'mat-icon-button': buttonType}"
    [matTooltip]="'Attach URL' | translate"
    [disabled]="urlForm?.disabled" (click)="onAttachClicked()">
    <span class="url">
      <svg-icon [applyCss]="true" class="text-center attach-link" [src]="svgIcon || 'assets/svgs/share-url.svg'" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
    </span>
    <span *ngIf="!buttonType" class="ml-1 button-label" translate>{{buttonLabel}}</span>
  </button>
<!-- <button mat-button color="primary" (click)="onAttachClicked()"> -->
  <!-- <mat-icon class="mr-2">{{buttonIcon}}</mat-icon> -->
<!-- </button> -->

<div *ngIf="showChipButton" id="attach-url-btn">
  <div fxLayoutAlign="center center" class="show-border" [ngClass]="{'button-chip mb-2 rounded-pill border': showBorder}">
    <button id="attach-url-button" fxLayoutAlign="center center" [ngClass]="{'rounded-pill': showBorder}" [matTooltip]="'Attach URL' | translate" type="button" mat-button (click)="onAttachClicked()">
      <div class="text-icon" fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
        <svg-icon [applyCss]="true" class="text-center" [ngClass]="{'web-link': buttonLabel === 'Url'}" [src]="svgIcon" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"></svg-icon>
        <span class="button-label" translate>{{buttonLabel}}</span>
      </div>
    </button>
  </div>
</div>

