import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiDealsWith, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiProductApiService } from './kalgudi-product-api.service';

@Injectable()
export class KalgudiProductService {

  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private profileService: KalgudiProfileService,
    private api: KalgudiProductApiService,
    private profileState: KalgudiProfileStateService,
  ) {
    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }

  }

  /**
   * Calls api to get the store types
   */
  fetchProductVarieties(productId: string): Observable<any> {

    return this.api.fetchProductVarieties(productId);
  }


  /**
   * Calls api to post the product
   */
  postDealsWith(payload: KalgudiDealsWith[], profileKey?: string): Observable<KalgudiUser> {

    const userProfileKey = profileKey ? profileKey : this.profileState.id;

    return this.api.postDealsWith(userProfileKey, payload)
      .pipe(

        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );

  }


  /**
   * Gets top products
   */
  getTopProducts(): Observable<any> {

    return this.api.getTopProducts();
  }
}
