import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiPageStream } from '../classes/kalgudi-page-stream';

@Component({
  selector: 'kl-page-stream',
  templateUrl: './page-stream.component.html',
  styleUrls: ['./page-stream.component.scss']
})
export class PageStreamComponent extends KalgudiPageStream implements OnInit {

  @Input()
  url: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {

    super(notification, util, injector);

  }

  ngOnInit() {
    this.initStream();
  }

  protected onDestroyed(): void { }
}
