import { Component, Input, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, KalgudiDialogResult, KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import {
  AiPredictionsShareUpdateDialogComponent,
} from '../ai-predictions-share-update-dialog/ai-predictions-share-update-dialog.component';
import {
  AiPredictionsShareUpdateMobileDialogComponent,
} from '../ai-predictions-share-update-mobile-dialog/ai-predictions-share-update-mobile-dialog.component';

@Component({
  selector: 'kl-ai-predictions-tile',
  templateUrl: './ai-predictions-tile.component.html',
  styleUrls: ['./ai-predictions-tile.component.scss']
})
export class AiPredictionsTileComponent implements OnInit {

  @Input()
  progress: boolean;

  @Input()
  informationList: any[];

  @Input()
  inputsList: any[];

  @Input()
  outputsList: any[];

  @Input()
  pageId: string;

  readonly TABS = {
    INFO: { index: 0, title: 'Information', id: 'ai-predictions-info' },
    INPUT: { index: 1, title: 'Input', id: 'ai-predictions-input' },
    OUTPUT: { index: 2, title: 'Output', id: 'ai-predictions-output' },
  };
  activeTabIndex: any;

  pageDetails: KalgudiPageDetails;

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
    private util: KalgudiUtilityService,
    private kalgudiPageService: KalgudiPageService
  ) {

    this.activeTabIndex = this.TABS.INPUT.index;

    this.kalgudiPageService.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.pageDetails = pageDetails;
      });

  }

  ngOnInit(): void {
  }

  /**
   * Provides the active tab details
   */
  get activeTabDetails(): {index: number, title: string, id: string} {

    const tabsArrays = Object.values(this.TABS);

    return tabsArrays[this.activeTabIndex];
  }

  /**
   * Copying the html as text to clipboard
   * @param elementId
   */
  copyToClipBoard(elementId): void {

    this.util.copyHTMLToClipboard(elementId);
  }

  /**
   * Opens ai predictions share update form on new tab
   */
  openAiPredictionsShareUpdate() {

    const url = `app/pages/${this.pageId}/d/updates`

    window.open(url, '_blank');
  }

  /**
   * Opens ai predictions share update dialog
   */
  openAiPredictionsShareUpdateDialog() {
    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Share',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '550px',
      maxWidth: '550px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    // Show status dialog
    return this.showAiPredictionsSendSmsDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Check for web and mobile and opens ai predictions share update dialog
   *
   * @param config Dialog
   */
  private showAiPredictionsSendSmsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.kalgudiDialogService.openMobileDialog(AiPredictionsShareUpdateMobileDialogComponent, dialogConfig);
    } else {

      return this.kalgudiDialogService.openDialog(AiPredictionsShareUpdateDialogComponent, dialogConfig, matDialogConfig);
    }
  }

}
