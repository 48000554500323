import { Component, Inject, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { ProjectStatus, ProjectRole } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { ProjectsListTable } from '../../classes/projects-list-table';

@Component({
  selector: 'kl-projects-list-table',
  templateUrl: './projects-list-table.component.html',
  styleUrls: ['./projects-list-table.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectsListTableComponent extends ProjectsListTable implements OnInit {

  projectRole = ProjectRole;

  projectStatus = ProjectStatus;

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig

  ) {
    super(injector);

  }

  ngOnInit() {

    // Initializes the KalgudiMatTableStream
    this.initTableStream(this.matPaginator, this.matSort);
  }


  /**
   * Open projects full view
   */
  openProjectFullview(projectId: string) {

    this.appRouting.toProject({ projectId }, {type: 'GRID'});

  }


  protected onDestroyed(): void { }

}
