<div class="mt-3">

  <div class="mb-3">
    <h5 translate>Social</h5>

    <kl-social-stats
      [totalStatsCount]="sharesStats?.totalShares"
      [shareStats]="shareTypeStats"
      [qaStats]="qaTypeStats"
      (pageUpdates)="openPageUpdates()"
      (pageQa)="openPageQa()">
    </kl-social-stats>

  </div>

  <!-- <kl-analytics-row title="Social">

    <kl-page-analytics-stats fxFlex
      title="Shares"
      [statsCount]="sharesStats?.totalShares"
      isClickable=true
      matIcon="format_quote"
      matIconColor="orange"
      (pageActivity)="openPageUpdates()">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex
      title="Questions"
      [statsCount]="qaStats?.totalQuestions"
      isClickable=true
      matIcon="help_outline"
      matIconColor="green"
      (pageActivity)="openPageQa()">
    </kl-page-analytics-stats>
  </kl-analytics-row> -->

  <div class="mb-3">
    <h5 translate>Projects</h5>

    <kl-project-stats
      [projectsTotalCount]="projectStats?.totalProjects"
      [tasksTotalCount]="taskStats?.totalTasks"
      [projectStats]="projectTypeStats"
      [taskStats]="taskTypeStats"
      (pageProjects)="openPageProjects()"
      (pageTasks)="openPageTasks()">
    </kl-project-stats>

  </div>

  <!-- <kl-analytics-row title="Projects">
    <kl-page-analytics-stats fxFlex="50%"
      title="Projects"
      svgIcon="assets/svgs/projects.svg"
      isClickable=true
      matIconColor="#3f51b5"
      className="projects-icon"
      [statsCount]="projectStats?.totalProjects"
      (pageActivity)="openPageProjects()">
    </kl-page-analytics-stats>

    <kl-page-analytics-stats fxFlex="50%"
      title="Tasks"
      svgIcon="assets/svgs/tasks.svg"
      isClickable=true
      matIconColor="#e91e63"
      className="tasks-icon"
      [statsCount]="taskStats?.totalTasks"
      (pageActivity)="openPageTasks()">
    </kl-page-analytics-stats>
  </kl-analytics-row> -->

</div>
