import { InjectionToken, Type } from '@angular/core';

import { KalgudiCoreRouteConfig } from './models/kalgudi-core-route-config';
import { KalgudiEnvironmentConfig } from './models/kalgudi-environment';
import { KalgudiNotification } from './models/kalgudi-notification';

/**
 * Specifies Kalgudi core configuration
 *
 * @author Pankaj Prakash
 */
export interface KalgudiCoreConfig {
  environment: Type<KalgudiEnvironmentConfig>;
  notification: Type<KalgudiNotification>;
  routeConfig?: Type<KalgudiCoreRouteConfig>;
}

// Kalgudi libraries injection tokens
export const KL_ENV          = new InjectionToken<KalgudiEnvironmentConfig>('KL_ENV');
export const KL_NOTIFICATION = new InjectionToken<KalgudiNotification>('KL_NOTIFICATION');
export const KL_ROUTE_CONFIG = new InjectionToken<KalgudiCoreRouteConfig>('KL_ROUTE_CONFIG');
