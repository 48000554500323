import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiEmployeeAchievements } from '../../classes/kalgudi-employee-achievements';

@Component({
  selector: 'kl-employee-achievements',
  templateUrl: './employee-achievements.component.html',
  styleUrls: ['./employee-achievements.component.scss']
})
export class EmployeeAchievementsComponent extends KalgudiEmployeeAchievements implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
