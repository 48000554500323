import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, Credit, CreditActivityResponse, KalgudiCredit } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CreditApiService {

  /**
   * `Base path`
   */
  private readonly API_CREDITS = `${this.env.restBaseUrlV2}/pages/sms/credit`;

  /**
   * `v2/pages/sms/credit/:creditorId`
   */
  private readonly  API_CREDIT_DETAILS = `${this.API_CREDITS}/:creditorId`;

  /**
   * `v2/pages/sms/credit/activity`
   */
  private readonly API_CREDIT_ACTIVITY_LIST = `${this.API_CREDITS}/activity`;

  /**
   * `v2/pages/sms/credit/`
   */
  private readonly API_UPDATE_CREDIT = `${this.API_CREDITS}/`;

  /**
   * `v2/pages/sms/credit/transfer`
   */
  private readonly API_CREDIT_TRANSFER = `${this.API_CREDITS}/transfer`;


  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Service call to fetch credits activity
   */
  fetchCreditActivity(offset: number, limit: number): Observable<KalgudiCredit> {

    // API request query params
    const params = {
      limit: limit.toString(),
      offset: offset.toString(),
    };

    return this.httpClient.get<CreditActivityResponse>(this.API_CREDIT_ACTIVITY_LIST, { params })
      .pipe(

        // Check and handle API response for errors
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Service call to fetch credit details
   */
  fetchCreditDetails(creditorId: string): Observable<Credit> {
    const url = this.API_CREDIT_DETAILS.replace(':creditorId', creditorId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(r => this.util.apiErrorHandler(r)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Service call to create and update credit settings
   */
  createAndUpdateCredit(payload): Observable<Credit> {

    return this.httpClient.put<ApiResponseCommon>(this.API_UPDATE_CREDIT, payload)
      .pipe(
        // Check for API response errors
        map(res => this.util.apiErrorHandler(res)),

        // Map API response to credit settings
        map(res => res.data)
      );
  }

  /**
   * `Calls api method to transfer credits`
   */
  // transferCredits(payload): Observable<any> {

  //   return this.httpClient.post<any>(this.API_CREDIT_TRANSFER, payload)
  //     .pipe(

  //       // Api response error handler
  //       map(r => this.util.apiErrorHandler(r)),

  //       // Map API response to credit transfer
  //       map(r => r.data)

  //     );
  // }

}
