import { Component, Injector, Input, OnInit } from '@angular/core';

import { KalgudiLiveStockForm } from '../../classes/kalgudi-add-livestock-form';

@Component({
  selector: 'kl-add-live-stock-form',
  templateUrl: './add-live-stock-form.component.html',
  styleUrls: ['./add-live-stock-form.component.scss']
})
export class AddLiveStockFormComponent extends KalgudiLiveStockForm implements OnInit {

  @Input()
  stockType: any;

  constructor(protected injector: Injector) {
    super(injector)
  }

  ngOnInit(): void {

    this.setLabelBasedOnCondition();
    this.type.patchValue(this.stockType.title);
    this.attachments.patchValue(this.stockType.items.attachments);
    if (this.showEditFields) {
      this.patchLiveStockDetails();
    }

    // this.stock ? this.addLiveStockForm.patchValue(this.stock) : '';
    if (this.stockType.items.products.length) {
      this.stockType.items.products.forEach(element => {
        this.product.push(this.patchValues(element.productId, element.productName, element.availableQuantity))
      });
    }
  }

  /**
   * Patching live stock details from the service
   */
  patchLiveStockDetails() {
    this.batchNo.patchValue(this.stock.batchNo);
    this.alternativeName.patchValue(this.stock.coopName);
    this.totalChicks.patchValue(this.stock.totalQuantity);
    this.length.patchValue(this.stock.dimensions.length);
    this.breadth.patchValue(this.stock.dimensions.breadth);
    this.unit.patchValue(this.stock.dimensions.unit);
    this.breedName.patchValue(this.stock.breedName);
    this.maleCount.patchValue(this.stock.maleCount);
    this.femaleCount.patchValue(this.stock.femaleCount);
    this.age.patchValue(this.stock.age);
    this.mortality.patchValue(this.stock.mortality);
    this.averageWeight.patchValue(this.stock.avgWeight);
  }

  onDestroyed(): void { }

  /**
   * Removing 0 if user click on the input field
   * @param event
   */
  onFocusValue(event: any, label: any) {
    if (+label.value === 0) {
      this.onFocusEvent(event);
      label.reset();
    }
  }

  /**
   * Updating 0 if user click on the outside input field
   * @param event
   */
  onOutFocusValue(event: any, label: any) {
    if (label.value == '' || label.value === null) {
      label.patchValue(0);
    }
  }

  /**
   * Remove 0 if user click on the input field
   * @param event
   */
  onFocusEvent(event: any) {
    event.target.value = '';
  }

}
