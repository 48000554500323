import { Component, Inject, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';

@Component({
  selector: 'kl-task-members-tile-da',
  templateUrl: './task-members-tile-da.component.html',
  styleUrls: ['./task-members-tile-da.component.scss']
})
export class TaskMembersTileDaComponent implements OnInit {

  @Input()
  member: KalgudiUser;

  @Input()
  isSubmission: boolean;

  domain: string;
  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) {

    this.domain = this.env.coreDomain;

  }

  ngOnInit() {
  }

}
