import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiFormValidators,
  KalgudiUsersService,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserLoginIdUpdatePayload } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Directive()
export abstract class KalgudiUserLoginIdUpdate extends KalgudiDestroyable {

  @Input()
  loginId: string;

  @Output()
  closeDialog = new EventEmitter();

  profileForm: FormGroup;
  progress: boolean;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private profileState: KalgudiProfileStateService;
  private profileService: KalgudiProfileService;
  private notification: KalgudiNotification;
  private kalgudiUsersService: KalgudiUsersService;
  private kalgudiApp: KalgudiAppService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                  = this.injector.get(FormBuilder);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.profileState        = this.injector.get(KalgudiProfileStateService);
    this.profileService      = this.injector.get(KalgudiProfileService);
    this.notification        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiUsersService = this.injector.get(KalgudiUsersService);
    this.kalgudiApp          = this.injector.get(KalgudiAppService);

    this.profileForm = this.newProfileBasicDetailsForm;

  }

  // --------------------------------------------------------
  // #region for Getter and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Updates the login id
   */
  updateLoginId() {
    this.progress = true;

    const payload = this.preparedPayload(this.profileForm.value);

    this.profileService.updateLoginId(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onUpdatingLoginId(res),
        err => this.onLoginIdUpdateError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Prepares payload for login id update
   */
  protected preparedPayload(formValue: any): KalgudiUserLoginIdUpdatePayload {

    const payload: KalgudiUserLoginIdUpdatePayload = this.util.clone({
      ...formValue
    });

    payload.newMobileNo = payload.newMobileNo.includes('+91') ? payload.newMobileNo : '+91' + payload.newMobileNo;
    payload.oldMobileNo = this.loginId.includes('+91') ? this.loginId : '+91' + this.loginId;
    payload.mobileTelecomCode = '+91';
    payload.assistantDetails = this.kalgudiUsersService.getBasicProfile(this.kalgudiApp.profileLocal);

    return payload;
  }


  /**
   * Event handler for updating login id API errors.
   */
  protected onLoginIdUpdateError(err: Error): void {
    this.util.showApiErrorMessage(err);
  }


  /**
   * Form group to update login id
   */
  private get newProfileBasicDetailsForm(): FormGroup {

    return this.fb.group({
      newMobileNo: ['',  [Validators.required, ...KalgudiFormValidators.mobileValidators]]
    });
  }

  /**
   * On login id changed
   */
  private onUpdatingLoginId(res): void {
    this.closeDialog.emit();
    this.notification.showMessage('Updated login id');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
