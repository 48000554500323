import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import {
  checkMobileDevice,
  kalgudiAnimations,
  KalgudiAppService,
  KalgudiUploadService,
  KalgudiUsersService,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiQaService } from '@kalgudi/qa';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { AssistedShareQaRequest, ShareQaFullDetails } from '@kalgudi/types';

import { KalgudiPageService } from '../../../../services/kalgudi-page.service';
import { AssistedQa } from '../../classes/assisted-qa';

@Component({
  selector: 'kl-digital-assist-qa',
  templateUrl: './digital-assist-qa.component.html',
  styleUrls: ['./digital-assist-qa.component.scss'],
  animations: kalgudiAnimations,
})
export class DigitalAssistQaComponent extends AssistedQa implements OnInit {

  @Output()
  qaCreated = new EventEmitter<any>();

  rowsCount: number;
  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService,
    protected kalgudiPageService: KalgudiPageService,
    protected kalgudiUsersService: KalgudiUsersService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3,
      kalgudiPageService,
      kalgudiUsersService,
    );

    this.isMobileDevice = checkMobileDevice();
  }

  ngOnInit() {
    this.rowsCount = checkMobileDevice() ? 10 : 3;
  }


  onQaCreated(event: any): void {
    this.qaCreated.emit(event);
  }

  protected onDestroyed(): void { }

  protected onPostCreated(payload: AssistedShareQaRequest, response: ShareQaFullDetails): void {
    this.qaCreated.emit(response);
  }
}
