import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SatelliteAdvisoriesSyncApiService {

  /**
   * `/pages/:pageId/satellite-advisories/sync/:syncId`
   */
  private readonly API_SHARE_ADVISORIES = `${this.env.restBaseUrlV2}/pages/:pageId/satellite-advisories/sync/:syncId`;

  /**
   * `/v2/pages/:pageId/satellite-advisories/trigger-sync`
   */
  private readonly API_SYNC_RECORDS = `${this.env.restBaseUrlV2}/pages/:pageId/satellite-advisories/trigger-sync?nitrogen=true&phosphorous=true&soilMoisture=true`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Creates an advisory
   */
  shareAdvisories(pageId: string, syncId: string, payload: any): Observable<boolean> {

    const url = this.API_SHARE_ADVISORIES
      .replace(':pageId', pageId)
      .replace(':syncId', syncId);

    return this.httpClient.post<ApiResponseCommon>(url, payload)
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data ? res.data : throwError('Unable to send advisories to farmer, please try again later.'))
      );
  }

  /**
   * Api to sync records
   */
  syncRecords(pageId: string): Observable<boolean> {

    const url = this.API_SYNC_RECORDS.replace(':pageId', pageId);

    return this.httpClient.put<ApiResponseCommon>(url, {})
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data ? res.data : throwError('Unable to sync records, please try again later.'))
      );
  }
}
