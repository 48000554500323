import { CoreAppConfiguration } from '@app/models';
import {  DEFAULT_ENVIRONMENT_NEW_DEV } from '@kalgudi/core';

export const environment: CoreAppConfiguration = {
  ...DEFAULT_ENVIRONMENT_NEW_DEV,

  appId: 'KALGUDI_CORE',
  conserWaterKey: '1003a0d3-7946-4d8e-bf81-50b3d8d6f25f',

  appConfig: {
    title: 'Kalgudi Core',
    appLogoUrl: 'assets/kalgudi-core/icons/logo.png',
    appLogoUrlMd: '/assets/kalgudi-core/icons/kalgudi.png',
    termsAndConditionsLink: 'https://kalgudi.com/termsandconditions.html'
  }
};
