import { Component, Injector } from '@angular/core';

import { KalgudiSurveyInfoManagement } from '../../classes/kalgudi-survey-info-management';

@Component({
  selector: 'kl-survey-info-page',
  templateUrl: './survey-info-page.component.html',
  styleUrls: ['./survey-info-page.component.scss']
})
export class SurveyInfoPageComponent extends KalgudiSurveyInfoManagement {

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }
}
