<div id="kl-survey-question-attachments" fxLayout="column" fxLayoutGap="10px" (click)="openFullview()">
  <div class="image image-gallery cursor-pointer position-relative w-100"
    *ngIf="attachments && attachments?.length">
      <img width="800" class="mw-100" [src]="attachments[0]?.url | prefixDomain" [alt]="attachments[0]?.context">

      <!-- Number of images -->
      <div *ngIf="attachments?.length > 1"
        class="position-absolute img-list"
        fxLayout="column"
        fxLayoutAlign="center center">
        <p class="mb-0 img-count">{{ attachments?.length - 1 }}</p>
        <p class="mb-0 more" translate>More</p>
      </div>
      <!-- Number of images -->
  </div>

  <div class="image image-gallery cursor-pointer w-100" *ngIf="url">
    <img class="w-100" [src]="url | prefixDomain | resizeImage:'md'">
  </div>
</div>
