import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiInboxStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ProgramShareUpdate } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { PageStreamService } from '../services/page-stream.service';

@Directive()
export abstract class KalgudiPageStream extends KalgudiInboxStream<ProgramShareUpdate> {

  @Input()
  pageId: string;

  private updatesStream: PageStreamService;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector

  ) {

    super(notification, util);

    this.updatesStream = this.injector.get(PageStreamService);

  }

  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.updatesStream.getShareStream(this.pageId, offset, limit);
  }

}
