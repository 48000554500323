<!-- <div>
  <kl-user-picker [multiSelect]="true" [(selectedUsersMap)]="selectedUsersMap"></kl-user-picker>

  <div class="pt-0 pr-3" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button mat-button color="primary" class="p-0" (click)="addMember()">Add members</button>
  </div>
</div> -->

<div id="task-search-members" fxLayout="column">
  <!-- Search bar -->
  <div class="my-3 search-bar" fxFlex="10%" fxLayoutAlign="center center">
    <kl-search class="search w-100"
      placeHolder="Search project members"
      (search)="search();"
      [searchForm]="searchForm">
    </kl-search>
  </div>
  <!-- /Search bar -->
  <div class="overflow-auto" fxFlex>
    <ng-container *ngIf="(paginator$ | async) as paginator">

      <div  *ngIf="(stream$ | async) as users">

        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-2 list-wrapper">
          <div *ngFor="let user of users;" fxFlex="48%" fxFlex.xs="100%" class="mb-3"
            (click)="toggleUserSelection(user)"
            [ngClass]="{'selected': !!(selectedUsersMap[user?.profileKey])}">

            <div>
              <kl-selectable-user-tile [author]="user" avatarSize="md"
                [isSelected]="!!(selectedUsersMap[user?.profileKey])">
              </kl-selectable-user-tile>
            </div>
          </div>
        </div>

        <div *ngIf="!paginator?.count">
          <kl-no-content
            [progress]="paginator?.loading"
            progressText="Loading..."
            text="Members not yet add to this project"
            matIcon="person_add">
          </kl-no-content>
        </div>
        <div class="mt-3" *ngIf="paginator?.count">
          <kl-load-more
            noMoreText="No more users"
            [progress]="paginator.loading"
            [hasMoreItems]="paginator.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
      </div>
    </ng-container>

  </div>
  <div class="pt-0 pr-3 add-member" fxFlex="10%" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <kl-flat-button
      label="Add members"
      [progress]="progress"
      [disabled]="!selectedUsers?.length"
      (clicked)="addMember()">
    </kl-flat-button>
  </div>
</div>
