import { Directive, Inject, Injector, Input } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { AudioAttachmentService, KalgudiDialogsService, KalgudiImagePickerService } from '@kalgudi/common';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KALGUDI_S3_POLICY_MAP, KalgudiAppService, KalgudiUtilityService, SHARE_TYPES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSocial, KalgudiSocialService, SocialDataNormalizerService } from '@kalgudi/social';
import {
  Attachment,
  ATTACHMENT_TYPE_MAP,
  KalgudiDialogConfig,
  LabelValueMap,
  S3PolicyPathCategoryMap,
  ScheduleDates,
  ShareRequest,
  ShareUpdate,
  UrlMetadata,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiShareUpdateService } from './services/kalgudi-share-update.service';

/**
 * Base class definition for all Kalgudi social share modules.
 */
@Directive()
export abstract class KalgudiSocialShare<T extends ShareRequest, R extends ShareUpdate> extends KalgudiSocial<T, R> {

  @Input()
  postDetails: ShareUpdate;

  @Input()
  reShareDetails: boolean = false;

  shareForm: FormGroup;
  tagsList: any[] = [];

  urlFormControl = new FormControl({});

  attachmentType = ATTACHMENT_TYPE_MAP;

  scheduleBtnClicked = new FormControl('');

  shareVisibilityList: LabelValueMap[] = [
    SHARE_TYPES.CONNECTS,
    SHARE_TYPES.ALL_SUBSCRIBERS,
    SHARE_TYPES.SPECIFIC_KALGUDI_USER,
  ];

  scheduledDates: ScheduleDates;

  pageList: any;
  page: any;

  cameraAttachment = new FormControl('');

  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.SHARE;

  private socialService: KalgudiSocialService;
  private audioAttachmentService: AudioAttachmentService;
  public environment: KalgudiEnvironmentConfig;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected kalgudiDialogService: KalgudiDialogsService,
    protected util: KalgudiUtilityService,
    protected sharePostService: KalgudiShareUpdateService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected mobileMenuService: KalgudiBottomSheetService,
  ) {

    super(
      injector,
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp
    );

    this.socialService = this.injector.get(KalgudiSocialService);
    this.audioAttachmentService = this.injector.get(AudioAttachmentService);
    this.environment = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);

    // Initialize share update form
    this.shareForm = this.shareUpdateForm;


    this.subscribeToImageCaptures();
    if(this.environment.appId === 'KALGUDI_CORE' || this.environment.appId === 'FPO_APP' || this.environment.appId === 'FARMERS_APP' || this.environment.appId === 'UBM'|| this.environment.appId === 'SAM_FPO' || this.environment.appId === 'SAM_FARMER' || this.environment.appId === 'KHETIGHAR' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'ORMAS_SHG' || this.environment.appId === 'SHG_NET_APP') {
      this.getPageShareTags();
    }

    // Fix: Syncing of multiple form elements with the same form control name
    // @see https://stackoverflow.com/a/53629638/2401088
    this.lstOfAttachmentsControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.lstOfAttachmentsControl.setValue(v, { onlySelf: true, emitEvent: false }));
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share update common form
   */
  private get shareUpdateForm(): FormGroup {

    return new FormGroup({
      authorId: new FormControl(this.kalgudiApp.profileLocal.profileKey, Validators.required),
      pageSelection: new FormControl(''),
      shareText: new FormControl(''),
      richShareText: new FormControl(''),

      sharedTo: new FormControl(this.shareVisibilityList[0].value, Validators.required),

      imageUri: new FormControl(''),
      uri: new FormControl(''),
      uriTitle: new FormControl(''),
      uriImage: new FormControl(''),
      domain: new FormControl(''),
      msgTypes: new FormControl([]),
      lstOfAttachments: new FormControl([]),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(''),
      scheduledTS: new FormControl(''),
      productsMetaData: new FormControl([]),
      filter: new FormGroup({
        gender: new FormControl(''),
      }),
    });
  }

  /**
   * Gets, the kalgudi share update form placeholder
   */
  get sharePlaceHolder(): string {
    return this.kalgudiApp.profileLocal ? `Hey! Share what is in your mind..` : '';
  }

  /**
   * Share form text field.
   */
  get shareFormText(): AbstractControl {
    return this.shareForm.get('shareText');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get('sharedTo');
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.shareFormVisibility.value;
  }

  /**
   * Share form Uri url field.
   */
  get shareFormUri(): AbstractControl {
    return this.shareForm.get('uri');
  }

  /**
   * Share form Uri image field.
   */
  get shareFormUriImage(): AbstractControl {
    return this.shareForm.get('uriImage');
  }

  /**
   * Share form Uri title field.
   */
  get shareFormUriTitle(): AbstractControl {
    return this.shareForm.get('uriTitle');
  }

  /**
   * Share form msg types field.
   */
  get msgTypes(): AbstractControl {
    return this.shareForm.get('msgTypes');
  }

  /**
   * Share form Uri domain field.
   */
  get shareFormUriDomain(): AbstractControl {
    return this.shareForm.get('domain');
  }

  /**
   * Share form Uri products meta data
   */
  get selectedProducts(): AbstractControl {
    return this.shareForm.get('productsMetaData');
  }

  /**
   * Share form Uri domain field.
   */
  get shareFormAttachments(): FormArray {
    return this.shareForm.get('lstOfAttachments') as FormArray;
  }

  get lstOfAttachmentsControl(): AbstractControl {
    return this.shareForm.get('lstOfAttachments');

  }

  get scheduledDateField(): AbstractControl {
    return this.shareForm.get('scheduledDate');
  }

  get pageSelection(): AbstractControl {
    return this.shareForm.get('pageSelection');
  }

  get doctype(): string {
    if (this.shareFormAttachmentsControls.value.length) {

      return this.shareFormAttachmentsControls.value[0].msgType;
    } else {
      return '';
    }

  }


  /**
   * Checks whether the url is attached or not
   */
  get isUrlAttachment(): boolean {
    return this.shareForm.value.uri;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  /**
   * Initializing the post details to the share form
   */
  initPostDetails(postDetails: ShareUpdate): void {

    this.shareForm.patchValue(postDetails);

    if(this.reShareDetails) {
      this.shareFormText.patchValue('');
      this.lstOfAttachmentsControl.patchValue([]);
      this.shareForm.get('imageUri').patchValue('');
      this.msgTypes.patchValue([]);
      this.shareForm.get('authorId').patchValue(this.kalgudiApp.profileLocal.profileKey)
    }

    if (postDetails && postDetails.uri) {

      const urlDetails = {
        url: postDetails.uri,
        domain: postDetails.domain,
        image: postDetails.uriImage,
        title: postDetails.uriTitle
      };

      this.urlFormControl.patchValue(urlDetails);
    }

  }

  /**
   * Clears the url meta data from the share form
   */
  resetUrlMetadataFromShareForm(): void {
    // this.injectUrlMetadataToShareForm ({
    //   domain: '',
    //   image: '',
    //   title: '',
    //   url: '',
    // });
    this.urlFormControl.reset();
  }

  /**
   * To fetch tags
   */
  getPageShareTags() {
    this.sharePostService.getPageShareTags('PROGRAM')
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => this.fetchTagsSccessHandler(res),
      err => this.onApiError(err)
    )
  }

  /**
   * Calls API to share the current post.
   */
  createPostApi(payload: ShareRequest): Observable<R> {
    console.log(payload);
    if (this.postDetails && this.postDetails.scheduleId) {

      const reqObj = {
        ...this.postDetails,
        ...payload
      };

      return this.sharePostService.updateScheduledPost(this.postDetails.scheduleId, reqObj)
        .pipe(

          // Map response to generic type
          map(r => r as R)
        );
    } else {

      // Call api to share post
      return this.sharePostService.sharePost(payload)
        .pipe(

          // Map response to generic type
          map(r => r as R)
        );
    }

  }

  /**
   * Removes product from the product list
   */
  removeProduct(index: number): void {

    const products = this.selectedProducts.value;

    if (Array.isArray(products) && products.length) {
      products.splice(index, 1);

      this.selectedProducts.patchValue(products);
    }
  }

  /**
   * Resets share form. Overrides default implementation of reset form
   * by Kalgudi social.
   */
  resetForm(): void {
    this.shareFormAttachments.controls = [];
    this.shareForm.reset();
    this.shareFormVisibility.patchValue(this.shareVisibilityList[1].value);
    this.urlFormControl.reset();

  }


  /**
   * Called on send type selection changes
   */
  onSendTypeSelectionChange(event): void {

    this.scheduleBtnClicked.patchValue('');

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Schedule send',
      acceptButtonTitle: 'Send',
      rejectButtonTitle: 'Cancel',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.socialService.showScheduleDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res && res.accepted)
      )
      .subscribe(
        res => {
          this.scheduledDates = res.data.scheduledDates;
          this.createPost();
        }
      );
  }

  showAudioDialog(): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Record',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: this.s3Category,
        url: this.audioAttachment.value && this.audioAttachment.value.url
              ? this.audioAttachment.value.url
              : ''
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.audioAttachmentService.showAudioDialog(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.data),
      )
      .subscribe(
        res => this.audioAttachment.patchValue(res.attachment)
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets, the share update API request payload.
   */
  protected getCreationPayload(shareFormValue: any): T {
    delete this.shareForm.value.pageSelection;
    if (this.urlFormControl.value && this.urlFormControl.value.url) {

      shareFormValue.uri = this.urlFormControl.value.url;
      shareFormValue.domain = this.urlFormControl.value.domain;
      shareFormValue.uriImage = this.urlFormControl.value.image;
      shareFormValue.uriTitle = this.urlFormControl.value.title;
    }

    if (shareFormValue.scheduledDate && shareFormValue.scheduledTime ) {

      const date = new Date(shareFormValue.scheduledDate);

      const dateAndTime = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()} ${shareFormValue.scheduledTime}`;

      shareFormValue.scheduledTS = new Date(dateAndTime).toISOString();
    }

    if(((this.environment?.appId === 'KALGUDI_CORE' || this.environment.appId === 'FPO_APP' || this.environment.appId === 'FARMERS_APP' || this.environment.appId === 'UBM' || this.environment.appId === 'SAM_FPO' || this.environment.appId === 'KHETIGHAR' || this.environment.appId === 'FPO_NET_APP' || this.environment.appId === 'ORMAS_SHG' || this.environment.appId === 'SHG_NET_APP') && (this.page)) || (this.postDetails && this.postDetails.entityId)) {
      shareFormValue.entityId = this.postDetails ? this.postDetails.entityId : this.page.pageId;
      shareFormValue.entityName = this.postDetails ? this.postDetails.entityName : this.page.pageTitle;
      shareFormValue.filter = {
        users:  null,
        products: [],
        businessTypes: [],
        locations: [],
        countries: [],
        states: [],
        districts: [],
        pageId: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.pageId : this.page.pageId,
        pageTitle: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.pageTitle : this.page.pageTitle,
        pageType: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.pageType : this.page.pageType,
        pageUrl: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.pageUrl : this.page.pageProfilePic,
        parentPageId: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.pagePageId : this.page.pageId,
        parentPageTitle: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.parentPageTitle : this.page.pageTitle ,
        parentPageUrl: (this.postDetails && this.postDetails.filter) ? this.postDetails.filter.parentPageUrl : this.page.pageProfilePic,
        isSms: false
      };
      shareFormValue.isAdmin = true;
      shareFormValue.isAuthor = true;
      shareFormValue.isPublishedThroughTool = true;

    shareFormValue.msgTypes = (this.postDetails && this.postDetails.msgTypes[0] && this.postDetails.msgTypes[0].length) ? [this.postDetails.msgTypes[0]] : (this.msgTypes.value && this.msgTypes.value.length)  ? [this.msgTypes.value] : [];

      shareFormValue.productsMetaData = [];
      shareFormValue.recipient = "ALL_SUBSCRIBERS"
    }

    // if (this.scheduledDates && this.scheduledDates.scheduledTS) {

    //   shareFormValue.scheduledTS = this.scheduledDates.scheduledTS;
    //   shareFormValue.scheduledDate = this.scheduledDates.scheduledDate;
    //   shareFormValue.scheduledTime = this.scheduledDates.scheduledTime;
    // }
    const audio: Attachment = this.audioAttachment.value;


    if (audio && audio.url) {
      shareFormValue.lstOfAttachments.push(audio);

      shareFormValue.shareText = shareFormValue.shareText ? shareFormValue.shareText : 'Audio Share';
    }

    return shareFormValue;
  }

  /**
   * Error handler for tags list
   * @param err
   */
  private fetchTagsSccessHandler(res: any) {
    this.tagsList = res;
  }

  /**
   * Event handler for  API errors.
   */
  protected onApiError(err: any): void {
    this.notifications.showMessage(err.message || err.error.message || 'Something went wrong, please try again later!');
  }


  /**
   * Creates form group for every attachment and push it into the form array
   * @param attachments
   */
  private updateAttachments(attachments: Attachment[]) {

    attachments.forEach(i => {
      this.shareFormAttachments.push(new FormGroup({
        url: new FormControl(i.url),
        context:  new FormControl(i.context),
        msgType: new FormControl(i.msgType),
      }));
    });

  }

  /**
   * Injects the shared url metadata to the share form. It also invalidates
   * the share form for any errors.
   *
   * @param urlMetaData Url metadata to inject to the share form
   *
   * @returns Updated share form.
   */
  private injectUrlMetadataToShareForm(urlMetaData: UrlMetadata): FormGroup {

    // Patch url metadata values to the share form
    this.shareFormUri.patchValue(urlMetaData.url);
    this.shareFormUriDomain.patchValue(urlMetaData.domain);
    this.shareFormUriImage.patchValue(urlMetaData.image);
    this.shareFormUriTitle.patchValue(urlMetaData.title);

    // Check for share form invalid errors
    this.shareForm.updateValueAndValidity();

    return this.shareForm;
  }

  private subscribeToImageCaptures(): void {

    this.cameraAttachment.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {

          const images = Array.isArray(this.lstOfAttachmentsControl.value) ? this.lstOfAttachmentsControl.value : [] ;
          images.push(res);

          this.lstOfAttachmentsControl.patchValue(images);

        });
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
