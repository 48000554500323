<div id="kalgudi-input-dialog task-stats-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{ data?.title }}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()"><mat-icon>clear</mat-icon></button>
  </div>

  <!-- <div fxLayoutAlign="end end">
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()"><mat-icon>clear</mat-icon></button>
  </div> -->
  <div class="mt-3">
    <kl-task-stats [statType]="data?.data?.statType" [taskId]="data?.data?.taskId"></kl-task-stats>
  </div>
</div>
