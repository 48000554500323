import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinArrayFields'
})
export class JoinArrayFieldsPipe implements PipeTransform {

  transform(value: any[], fieldName: string, size: number = -1, args: string  = '\n'): any {

    size = Array.isArray(value) && size <= -1 ? value.length - 1 : size;

    const normalizedFields = value.slice(0, size+1).map(v => v[fieldName]);

    return normalizedFields.join(args);
  }

}
