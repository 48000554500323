import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { StoreAdminModule } from '@kalgudi/ag-grid-lib';
import { StoreAdminAgGridModule } from '@kalgudi/ag-grid-lib/ag-grid';
import { StoreAdminCommonModule } from '@kalgudi/ag-grid-lib/common';
import { KalgudiDialogsModule, KalgudiNoContentModule, KalgudiTimePickerModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { SatelliteAdvisoriesModule } from '@kalgudi/social';
import { StoreType } from '@kalgudi/types';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AgGridAdvisoryStatusComponent } from './components/ag-grid-advisory-status/ag-grid-advisory-status.component';
import {
  SatelliteAdvisoriesAgGridComponent,
} from './components/satellite-advisories-ag-grid/satellite-advisories-ag-grid.component';
import {
  SatelliteAdvisoriesAllUsersAgGridComponent,
} from './components/satellite-advisories-all-users-ag-grid/satellite-advisories-all-users-ag-grid.component';
import {
  SatelliteAdvisoriesLandDetailsComponent,
} from './components/satellite-advisories-land-details/satellite-advisories-land-details.component';
import {
  SatelliteAdvisoriesLandDialogComponent,
} from './components/satellite-advisories-land-dialog/satellite-advisories-land-dialog.component';
import {
  SatelliteAdvisoriesLandMobileDialogComponent,
} from './components/satellite-advisories-land-mobile-dialog/satellite-advisories-land-mobile-dialog.component';
import {
  SatelliteAdvisoriesScheduleUpdatesDialogComponent,
} from './components/satellite-advisories-schedule-updates-dialog/satellite-advisories-schedule-updates-dialog.component';
import { SatelliteAdvisoriesAllUsersService } from './services/satellite-advisories-all-users.service';
import { SatelliteAdvisoriesPreviousSyncsService } from './services/satellite-advisories-previous-syncs.service';
import { SatelliteAdvisoriesSyncApiService } from './services/satellite-advisories-sync-api.service';
import { SatelliteAdvisoriesSyncService } from './services/satellite-advisories-sync.service';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    SatelliteAdvisoriesAgGridComponent,
    SatelliteAdvisoriesAllUsersAgGridComponent,
    SatelliteAdvisoriesLandDialogComponent,
    SatelliteAdvisoriesLandMobileDialogComponent,
    SatelliteAdvisoriesLandDetailsComponent,
    SatelliteAdvisoriesScheduleUpdatesDialogComponent,
    AgGridAdvisoryStatusComponent
  ],
  imports: [
    CommonModule,

    //Material module
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatCardModule,

    FormsModule,
    ReactiveFormsModule,

    TranslateModule,

    FlexLayoutModule,

    //Kalgudi libraries
    KalgudiNoContentModule,
    KalgudiDialogsModule,
    KalgudiButtonModule,
    KalgudiPipesModule,

    AngularSvgIconModule,

    SatelliteAdvisoriesModule,
    KalgudiTimePickerModule,

    AgGridModule.withComponents([
      AgGridAdvisoryStatusComponent
    ]),

    // Store admin library
    StoreAdminModule.forRoot({
      storeType: StoreType.FARM_STORE
    }),
    StoreAdminAgGridModule,
    StoreAdminCommonModule,
  ],
  exports: [
    SatelliteAdvisoriesAgGridComponent,
    SatelliteAdvisoriesAllUsersAgGridComponent
  ],
  entryComponents: [
    SatelliteAdvisoriesLandDialogComponent,
    SatelliteAdvisoriesLandMobileDialogComponent,
    SatelliteAdvisoriesScheduleUpdatesDialogComponent,
    AgGridAdvisoryStatusComponent
  ],
  providers: [
    SatelliteAdvisoriesAllUsersService,
    SatelliteAdvisoriesPreviousSyncsService,
    SatelliteAdvisoriesSyncService,
    SatelliteAdvisoriesSyncApiService,
  ]
})
export class PageSatelliteAdvisoriesModule { }
