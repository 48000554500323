import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { KalgudiStepperComponent } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { APiResponseTaskCreation } from '@kalgudi/types';

import { KalgudiTaskCreation } from '../../classes/task-creation';
import { CreateTaskStateService } from '../../services/create-task-state.service';

@Component({
  selector: 'kl-task-creation',
  templateUrl: './task-creation.component.html',
  styleUrls: ['./task-creation.component.scss'],
  providers: [
    {
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {displayDefaultIndicatorType: false},
    }
  ],
  viewProviders: [ CreateTaskStateService ]
})
export class TaskCreationComponent extends KalgudiTaskCreation implements OnInit, AfterViewInit {

  @ViewChild(KalgudiStepperComponent) kalgudiStepper: KalgudiStepperComponent;

  @Output()
  taskCreated = new EventEmitter<APiResponseTaskCreation>();

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService
    ) {

    super(injector, notification, util);
  }

  ngOnInit() {
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false; // stay on same page
  }

  ngAfterViewInit() {

    if (this.taskId) {
      this.initTaskDetails(this.taskId);
    }
  }


  nextStep(): void {

    if (this.currentState === 'FILL_COMMON_TASK_DETAILS') {

      if (!this.validateCommonTaskForm(this.commonTaskForm.value)) {
        this.notification.showMessage('Task dependent on another task must have days and task selected');
        return;
      }

      // if (!this.validateCommonTaskCropStage(this.commonTaskForm.value)) {
      //   this.notification.showMessage('For the chosen task template you must select the crop stage.');
      //   return;
      // }
    }

    if (this.currentState !== 'FILL_SPECIFIC_TASK_DETAILS') {
      this.kalgudiStepper.next();
    }

    super.nextStep();
  }

  previousState(moveStepper = true): void {

    if (moveStepper) {
      this.kalgudiStepper.previous();
    }

    super.previousState();
  }

  onDestroyed(): void {}

  /**
   * Called after successfully creating the task
   */
  protected onTaskCreation(res) {
    // this.nextStep();

    this.taskCreated.emit(res);

  }


  /**
   * Called when the task creation is failed
   */
  protected onTaskError(err: Error): void {

    this.previousState(false);
  }

}
