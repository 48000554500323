<div class="page-creation w-100" *ngIf="(creationState$ | async) as creationState">
  <div class="page-data" *ngIf="(creationStateData$ | async) as creationStateData" fxLayout
    fxLayoutAlign="center center">

    <mat-card class="w-100" fxLayout="column">

      <div class="h-100" fxLayout="column" fxLayoutAlign="space-between" fxFlex>
        <!-- Page Header -->
        <div class="w-100 mb-3 page-title" fxLayoutAlign="center center" fxHide.xs>
          <div fxLayout="column">
            <span class="title text-center" translate>Create your page</span>
            <span class="desc" translate>Make landing pages key for conversions</span>
          </div>
          <!-- View Page button -->
        </div>
        <!-- Page Header -->

        <div>
          <!-- stepper -->
          <div class="mx-auto stepper-data">
            <!-- <kl-kalgudi-stepper [stepsDetails]="pageCreationSteps" [selectedIndex]="0"></kl-kalgudi-stepper> -->

            <kl-stepper>
              <mat-horizontal-stepper
                labelPosition="bottom"
                [linear]="true"
                ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" #stepper>

                <mat-step>

                  <ng-template matStepLabel>
                    <span translate>Page type</span>
                  </ng-template>
                  <ng-template matStepperIcon="check_circle">
                    <mat-icon>check_circle</mat-icon>
                  </ng-template>

                  <div class="p-3 select-card">
                    <kl-select-card
                      layout="row"
                      [(selectedValue)]="pageType"
                      [options]="pageTypes">
                    </kl-select-card>

                    <ng-container *ngIf="progress">
                      <kl-no-content
                        iconSize="md"
                        progressText="Loading page types..."
                        [progress]="progress">
                      </kl-no-content>
                    </ng-container>
                  </div>

                  <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">
                    <!-- Back Button -->
                    <div>
                      <button mat-stroked-button disabled (click)="previousState()"><span translate>Back</span></button>
                    </div>
                    <!-- Back Button -->

                    <!-- Next page button -->
                    <div>
                      <button mat-raised-button
                        class="kl-icon-link-btn"
                        color="primary"
                        [disabled]="!pageType"
                        (click)="nextStep()">
                        <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                          <span translate>Next</span>
                          <div fxLayoutAlign="center end">
                            <mat-icon class="mr-n2">chevron_right</mat-icon>
                          </div>
                        </div>
                      </button>
                    </div>
                    <!-- / Next page button -->
                  </div>

                </mat-step>

                <mat-step [stepControl]="commonPageForm">

                  <ng-template matStepLabel><span translate>Basic details</span></ng-template>
                  <ng-template matStepperIcon="check_circle">
                    <mat-icon>check_circle</mat-icon>
                  </ng-template>

                  <div class="page-creation-info">
                    <kl-common-page-creation-form
                      [(pageForm)]="commonPageForm">
                    </kl-common-page-creation-form>
                  </div>


                  <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">
                    <!-- Back Button -->
                    <div>
                      <button mat-stroked-button (click)="previousState()"><span translate>Back</span></button>
                    </div>
                    <!-- Back Button -->

                    <!-- Next page button -->
                    <div>
                      <button mat-raised-button
                        class="kl-icon-link-btn"
                        color="primary"
                        [disabled]="commonPageForm?.invalid"
                        (click)="nextStep()">
                        <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                          <span translate>Next</span>
                          <div fxLayoutAlign="center end">
                            <mat-icon class="mr-n2">chevron_right</mat-icon>
                          </div>
                        </div>
                      </button>
                    </div>
                    <!-- / Next page button -->
                  </div>

                </mat-step>

                <mat-step [stepControl]="detailedPageForm">

                  <ng-template matStepLabel><span translate>Page details</span></ng-template>
                  <ng-template matStepperIcon="check_circle">
                    <mat-icon>check_circle</mat-icon>
                  </ng-template>

                  <div class="page-creation-info">
                    <kl-program-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.PROGRAM"
                      [(pageForm)]="detailedPageForm">
                    </kl-program-page-creation>

                    <kl-organization-page-creation
                      *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.ORGANIZATION"
                      [(pageForm)]="detailedPageForm">
                    </kl-organization-page-creation>

                    <kl-university-page-creation
                      *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.UNIVERSITY"
                      [(pageForm)]="detailedPageForm">
                    </kl-university-page-creation>

                    <kl-company-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.COMPANY"
                      [(pageForm)]="detailedPageForm">
                    </kl-company-page-creation>

                    <kl-farmer-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.FARMER"
                      [(pageForm)]="detailedPageForm">
                    </kl-farmer-page-creation>

                    <kl-stock-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.STOCK"
                      [(pageForm)]="detailedPageForm">
                    </kl-stock-page-creation>

                    <kl-processing-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.PROCESSING"
                      [(pageForm)]="detailedPageForm">
                    </kl-processing-page-creation>

                    <kl-sales-teams-page-creation *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.SALES_TEAMS"
                      [(pageForm)]="detailedPageForm">
                    </kl-sales-teams-page-creation>

                    <kl-farmers-interest-group-page-creation
                      *ngIf="creationStateData.CHOOSE_PAGE_TYPE.data === availablePageTypes.FIG"
                      [(pageForm)]="detailedPageForm">
                    </kl-farmers-interest-group-page-creation>
                  </div>

                  <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">
                    <!-- Back Button -->
                    <div>
                      <button mat-stroked-button  (click)="previousState()"><span translate>Back</span></button>
                    </div>
                    <!-- Back Button -->

                    <!-- Next page button -->
                    <div>
                      <button mat-raised-button
                        class="kl-icon-link-btn"
                        color="primary"
                        [disabled]="detailedPageForm?.invalid"
                        (click)="nextStep()">
                        <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                          <span translate>Create</span>
                          <!-- <div fxLayoutAlign="center end">
                            <mat-icon class="mr-n2">chevron_right</mat-icon>
                          </div> -->
                        </div>
                      </button>
                    </div>
                    <!-- / Next page button -->
                  </div>
                </mat-step>

                <mat-step>
                  <ng-template matStepLabel><span translate>Complete</span></ng-template>
                  <ng-template matStepperIcon="check_circle">
                    <mat-icon>check_circle</mat-icon>
                  </ng-template>

                  <div>
                    <div class="success" fxLayout="column" fxLayoutAlign="center center"
                      [@floatTop]="{ value: '', params: {delay: 50}}">
                      <div class=mb-2>
                        <svg-icon class="text-center" [applyCss]="true" src="assets/svgs/success-icon.svg"
                          [svgStyle]="{ 'width.px': 180 }">
                        </svg-icon>
                      </div>
                      <span class="mb-3" translate>Your page is ready</span>
                      <div class="w-100 mt-5 view-page" fxFlex fxLayoutAlign="center">
                        <kl-flat-button label="View page" fxFlex="13%" fxFlex.xs="30%" (clicked)="viewPage()"></kl-flat-button>
                      </div>
                    </div>
                  </div>
                </mat-step>

              </mat-horizontal-stepper>
            </kl-stepper>

          </div>
        </div>

      </div>

    </mat-card>

  </div>

</div>
