import { Injectable, OnDestroy } from '@angular/core';
import { IdValueMap, PageFilters, PageShareTargetAudienceRequest, StringStringMap } from '@kalgudi/types';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { PageShareFiltersApiService } from './page-filters-api.service';

@Injectable()
export class PageFiltersService implements OnDestroy {


  private rangeSubject = new BehaviorSubject<PageFilters>(null);
  private locationsSubject = new BehaviorSubject<StringStringMap>(null);

  private targetAudienceSubject = new BehaviorSubject<number>(0);

  private selectedCountrySubject  = new BehaviorSubject<string>('');
  private selectedStateSubject    = new BehaviorSubject<string>('');
  private selectedDistrictSubject = new BehaviorSubject<string>('');

  protected destroyed$ = new Subject();


  /**
   * Program subscribers range metadata.
   */
  protected range$: Observable<PageFilters>;

  /**
   * Program subscribers locations metadata.
   */
  protected locationsRange$: Observable<StringStringMap>;

  /**
   * Gets, stream of products that the program members deals.
   */
  readonly products$: Observable<IdValueMap[]>;

  /**
   * Gets, stream of countries that the program member belongs.
   */
  readonly countries$: Observable<IdValueMap[]>;

  /**
   * Gets, stream of states that the program member belongs.
   */
  readonly statesOrRegions$: Observable<IdValueMap[]>;

  /**
   * Gets, stream of countries that the program member belongs.
   */
  readonly districts$: Observable<IdValueMap[]>;

  /**
   * Gets, stream of business types that the program member belongs.
   */
  readonly businessTypes$: Observable<IdValueMap[]>;

  /**
   * Gets, stream of business types that the program member belongs.
   */
  readonly locations$: Observable<IdValueMap[]>;

  /**
   * Gets program targeting filter.
   */
  readonly memberTargetingFilters$: Observable<{
    products: IdValueMap[],
    businessTypes: IdValueMap[],
    countries: IdValueMap[],
    states: IdValueMap[],
    districts: IdValueMap[],
    locations: IdValueMap[]
  }>;

  /**
   * Gets targeted audience count
   */
  readonly targetAudience$: Observable<number>;

  constructor(
    protected api: PageShareFiltersApiService,
  ) {

    this.targetAudience$ = this.targetAudienceSubject.asObservable();

    this.range$          = this.rangeSubject.pipe(filter(range => range !== null));
    this.locationsRange$ = this.locationsSubject.pipe(filter(range => range !== null));

    this.products$               = this.initProducts$();
    this.countries$              = this.initCountries$();
    this.statesOrRegions$        = this.initStateOrRegions$();
    this.districts$              = this.initDistricts$();
    this.businessTypes$          = this.initBusinessTypes$();
    this.locations$              = this.initLocations$();
    this.memberTargetingFilters$ = this.initMemberTargetingFilters$();
  }


  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the current selected country id.
   */
  private get selectedCountryId(): string {
    return this.selectedCountrySubject.getValue();
  }

  /**
   * Gets, the current selected state id.
   */
  private get selectedStateId(): string {
    return this.selectedStateSubject.getValue();
  }

  /**
   * Gets, the current selected district id
   */
  private get selectedDistrictId(): string {
    return this.selectedDistrictSubject.getValue();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Loads subscribers range data. Program range data contains set of all
   * unique products, locations, and business types that the program
   * members belongs to.
   *
   * Program subscribers metadata is must to do any social activity targeted
   * to any specific members.
   */
  initPageFilters(pageFilters: PageFilters, locationFilter: StringStringMap): void {
    this.rangeSubject.next(pageFilters);
    this.locationsSubject.next(locationFilter);
  }

  /**
   * Updates the selected country details. Based on the selected country
   * a state gets selected.
   */
  updateSelectedCountry(countryId: string): void {

    // Clean states list
    this.resetSelectedState();

    this.selectedCountrySubject.next(countryId);
  }

  /**
   * Updates the selected state details. Based on the selected country
   * a state gets selected.
   */
  updateSelectedState(stateId: string): void {
    // Clear district list
    this.resetSelectedDistrict();

    this.selectedStateSubject.next(stateId);

  }

  /**
   * Updates the selected district details.
   */
  updateSelectedDistrict(districtId: string): void {
    this.selectedDistrictSubject.next(districtId);
  }

  /**
   * Gets, the program targeted audience count for a share post with the
   * selected filters.
   *
   * @param payload Selected filters for the program targeted share
   */
  fetchSocialPostTargetAudienceCount(payload: PageShareTargetAudienceRequest): Observable<number> {

    return this.api.fetchSocialPostTargetAudienceCount(payload);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Sorts the program filter list
   * @param list List to sort
   */
  protected sortFilterList(list: IdValueMap[]): IdValueMap[] {
    return list.sort((a, b) => (a.value > b.value) ? 1 : -1);
  }

  /**
   * Transforms hash map of id and value structure to id value type array.
   *
   * @param hashMap Hash map
   */
  protected transformHashMapToArray(hashMap: StringStringMap): IdValueMap[] {

    // Final mapped items
    const mappedItems: IdValueMap[] = [];

    // Null checks, return empty array immediately on null values
    if (!hashMap) {
      return mappedItems;
    }

    // Extract all keys from the hash map
    const ids = Object.keys(hashMap);

    // For each id map its value to a new type
    ids.forEach(id => mappedItems.push({
        id,
        value: hashMap[id]
      })
    );

    // Finally return the mapped list
    return mappedItems;
  }

  /**
   * Resets the selected filters
   */
  private resetSelectedFilters(): void {
    this.resetSelectedCountry();
    this.resetSelectedState();
    this.resetSelectedDistrict();
  }

  /**
   * Resets selected country
   */
  private resetSelectedCountry(): void {
    this.selectedCountrySubject.next('');
  }

  /**
   * Resets selected state
   */
  private resetSelectedState(): void {
    this.selectedStateSubject.next('');
  }

  /**
   * Resets selected district
   */
  private resetSelectedDistrict(): void {
    this.selectedDistrictSubject.next('');
  }

  /**
   * Initializes products
   */
  private initProducts$(): Observable<IdValueMap[]>  {

    return this.range$
      .pipe(

        // Convert hash map product structure to id value array
        map(range => this.transformHashMapToArray(range.products)),

        // Sorts the products list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Initializes countries observable
   */
  private initCountries$(): Observable<IdValueMap[]> {

    return this.range$
      .pipe(

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.countries)),

        // Sorts the countries list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Initializes state or region observable
   */
  private initStateOrRegions$(): Observable<IdValueMap[]> {

    return this.selectedCountrySubject
      .pipe(
        // tap(r => console.log('changed state', this.selectedCountryId)),

        switchMap(countryId => this.range$),

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.statesOrRegions[this.selectedCountryId])),

        // Sorts the states list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Initializes districts observable
   */
  private initDistricts$(): Observable<IdValueMap[]> {

    return this.selectedStateSubject
      .pipe(

        switchMap(range => this.range$),

        // Convert hash map countries structure to id value array
        map(range => this.transformHashMapToArray(range.districts[this.selectedStateId])),

        // Sorts the districts list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Initializes business types observable
   */
  private initBusinessTypes$(): Observable<IdValueMap[]> {

    return this.range$
      .pipe(

        // Convert hash map business types structure to id value array
        map(range => this.transformHashMapToArray(range.businessTypes)),

        // Sorts the business types list
        map(range => this.sortFilterList(range)),
      );
  }

  /**
   * Initializes locations observable
   */
  private initLocations$(): Observable<IdValueMap[]> {

    return this.locationsRange$
      .pipe(

        // Convert hash map business types structure to id value array
        map(range => this.transformHashMapToArray(range)),

        // Sorts the location list
        map(range => this.sortFilterList(range)),
      );
  }

  protected initMemberTargetingFilters$()  {
    return combineLatest(
      this.products$,
      this.businessTypes$,
      this.countries$,
      this.statesOrRegions$,
      this.districts$,
      this.locations$,
    )
    .pipe(
      map(([products, businessTypes, countries, states, districts, locations]) => {
        return {
          products,
          businessTypes,
          countries,
          states,
          districts,
          locations
        };
      })
    );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
