import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Provider,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { KalgudiBaseProductAutocomplete } from '@kalgudi/common';
import { kalgudiAnimations } from '@kalgudi/core';



// Base product NG_VALUE_ACCESSOR provider for custom form control
const AUTOCOMPLETE_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PromotionalCropAutocompleteComponent),
  multi: true,
};


@Component({
  selector: 'kl-promotional-crop-autocomplete',
  templateUrl: './promotional-crop-autocomplete.component.html',
  styleUrls: ['./promotional-crop-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ AUTOCOMPLETE_FORM_CONTROL_PROVIDER ]
})
export class PromotionalCropAutocompleteComponent extends KalgudiBaseProductAutocomplete implements OnInit, OnChanges {

  @Input()
  itemsList : any[] = [];

  @Input()
  items: any;

  @Input()
  postCreated: boolean;

  constructor(protected injector: Injector,
    protected fb: FormBuilder,
  ) {
    super(injector, fb);
  }

  ngOnInit() {

  }

  ngOnChanges() {

    if(this.postCreated && !this.items.crop) {
      this.itemsList = [];
    }

    if(this.itemsList) {
      this.onChange(this.itemsList);
      this.writeValue(this.itemsList);
      this.onTouched();
    }
  }

  protected onDestroyed(): void { }

  /**
   * Event handler for autocomplete selection. It updates the formControl with the selected
   * item.
   */
  selectItem(item: MatOptionSelectionChange) {

    if ((item && item instanceof MatOptionSelectionChange) && !item.source.selected) {
      return;
    }
    const newItem = item instanceof MatOptionSelectionChange ? item.source.value : item;

    if(newItem) {

      this.itemsList.push(newItem);
      this.autoCompleteForm.reset();

      // Invoke ControlValueAccessor `onChange` to update formControl values
      this.onChange(this.itemsList);
      this.writeValue(this.itemsList);
      this.onTouched();
    }
  }

  /**
   * Removes already selected item from the array
   */
  removeItem(index: number): void {

    // Remove item from the list
    this.itemsList.splice(index, 1);
    this.onChange(this.itemsList);
    this.writeValue(this.itemsList);
    this.onTouched();
  }

}
