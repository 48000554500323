import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiPageDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-program-tile',
  templateUrl: './program-tile.component.html',
  styleUrls: ['./program-tile.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramTileComponent implements OnInit, OnChanges {

  @Input()
  pageDetails: KalgudiPageDetails;

  @Output()
  showClonePageDialog = new EventEmitter();

  /**
   * List of available roles
   */
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  defaultProfilePic = 'https://kalgudi.com/data/profilepics/M01mlud0PRFREG2019071020061802UNH001.png';

  pageProfilePic: string;

  readonly memberRole: { [key: string]: any } = {

    ADMIN: {
      title: 'Admin',
      bgColor: '#B2EBF2',
      textAlign: 'center'
    },

    CONTRIBUTOR: {
      title: 'Contributor',
      bgColor: '#D1C4E9',
      textAlign: 'center'
    },

    FOLLOWER: {
      title: 'Follower',
      bgColor: '#8ba4de',
      textAlign: 'center'
    }

  } as const;

  constructor(@Inject(KL_ENV) public environment: KalgudiEnvironmentConfig) { }

  ngOnInit() {

  }

  ngOnChanges() {

    let url = '';
    if (this.pageDetails && this.pageDetails.pageProfilePic) {
      url = `url(${this.environment.domain + this.pageDetails.pageProfilePic})`;
    }

    this.pageProfilePic = `${url ? url + ', ' : ''}url(${this.defaultProfilePic})`;
  }

  /**
   * Event emit back to parent to open clone page dialog
   */
  openClonePage() {
    this.showClonePageDialog.emit();
  }

}
