import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secureMobile'
})
export class SecureMobilePipe implements PipeTransform {

  transform(value: any ) {

    if(/^\d+$/.test(value) || (value && value.includes(+91))) {
      return "XXXXXX" + value.substring(6, value.length);
    }
    else {
      return value;
    }
  }

}
