import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { IdValueMap } from '@kalgudi/types';

import { KalgudiPageRangeFilter } from '../../page-range-filter';
import { PageFiltersService } from '../../services/page-filters.service';



@Component({
  selector: 'kl-page-location-range-filter',
  templateUrl: './page-location-range-filter.component.html',
  styleUrls: ['./page-location-range-filter.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLocationRangeFilterComponent extends KalgudiPageRangeFilter implements OnInit {

  constructor(
    protected subscriberMetaService: PageFiltersService,
  ) {

    super(subscriberMetaService);
  }

  ngOnInit() {
  }

  /**
   * **Note:** Don't remove this. Required for the selection range performance.
   */
  trackById(index: number, item: IdValueMap): any {
    return item ? item.id : null;
  }

  setCountry(countryId: string): void {
    this.subscriberMetaService.updateSelectedCountry(countryId);
  }

  setState(stateId: string): void {
    this.subscriberMetaService.updateSelectedState(stateId);
  }

  setDistrict(districtId: string): void {
    this.subscriberMetaService.updateSelectedDistrict(districtId);
  }
}
