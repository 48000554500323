import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-farmers-task-tile',
  templateUrl: './farmers-task-tile.component.html',
  styleUrls: ['./farmers-task-tile.component.scss']
})
export class FarmersTaskTileComponent implements OnInit {

  @Input()
  farmersTasks: any[];

  @Input()
  profileKey: string;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Navigates to tasks list
   */
  openTaskListPage() {
    this.appRouting.toProfile({profileKey: this.profileKey}, {showTasks: true, isAdminTasks: true});
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullview(taskDetails: KalgudiProjectTask) {

    if (taskDetails.pageId) {

      this.appRouting.toProgramTaskFullview( { pageId: taskDetails.pageId, taskId: taskDetails.taskId });
    } else {

      this.appRouting.toTask( { projectId: taskDetails.projectId, taskId: taskDetails.taskId });
    }

  }
}
