<button mat-button class="kl-icon-link-btn"
  color="primary"
  (click)="onClick($event)"
  [disabled]="disabled || progress">
  <div class="icon-label mt-1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <div fxLayoutAlign="center center" *ngIf="matIcon">
      <mat-icon>{{ matIcon }}</mat-icon>
    </div>
    <div *ngIf="svgIcon" class="mt-n1">
      <svg-icon [src]="svgIcon" [svgStyle]="{ 'width.px': 20, 'height.px': 20}">
      </svg-icon>
    </div>
    <span>{{ label | translate }}</span>
    <mat-spinner *ngIf="progress" class="dim-spinner" [@slideRight] [diameter]="20" color="warn">
    </mat-spinner>
  </div>
</button>
