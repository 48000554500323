<ol class="w-100 questions-list">
  <li class="w-100 question" *ngFor="let question of questions; let i = index">
    <mat-card
      class="my-1 p-1 question"
      [@floatTop]="{ value: '', params: { delay: i * 40 } }">
      <div class="p-1">
        <div fxLayout fxLayoutAlign="space-between start">
          <p class="mb-0 question-type">
            <strong>{{ questionTitleMap[question?.questionDetails?.questionType] | translate }}</strong>
          </p>
          <button mat-icon-button
            [matTooltip]="'Delete question' | translate"
            class="remove-question"
            color="warn"
            (click)="onQuestionRemoveClicked(question?.questionId)">
            <mat-icon>cancel</mat-icon>
          </button>
        </div>

        <p class="mb-1">
          <strong>{{ question?.questionDetails?.question }}</strong>
        </p>

        <ul class="question-options" *ngIf="question?.questionDetails?.questionType === questionTypes?.MULTIPLE_CHOICE">
          <li *ngFor="let option of question?.questionDetails?.options">
            {{ option?.value || option?.title }}
          </li>
        </ul>

        <ul class="question-options" *ngIf="question?.questionDetails?.questionType === questionTypes?.MULTIPLE_SELECT_CHECKBOX">
          <li *ngFor="let option of question?.questionDetails?.options">
            {{ option?.value || option?.title }}
          </li>
        </ul>

        <div class="my-2">
          <kl-survey-question-attachments
            [attachments]="question?.questionDetails?.attachments">
          </kl-survey-question-attachments>
        </div>
      </div>
    </mat-card>
  </li>
</ol>
