import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { KalgudiAttachUrlFormControl } from '../kalgudi-attach-url-form-control';


const FORM_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AttachUrlFormControlComponent),
  multi: true,
};

@Component({
  selector: 'kl-attach-url-form-control',
  templateUrl: './attach-url-form-control.component.html',
  styleUrls: ['./attach-url-form-control.component.scss'],
  providers: [ FORM_CONTROL_ACCESSOR ]
})
export class AttachUrlFormControlComponent extends KalgudiAttachUrlFormControl implements OnInit {

  @Input()
  buttonType: string;

  @Input()
  buttonLabel = 'URL';

  @Input()
  svgIcon: string;

  @Input()
  showChipButton = false;

  @Input()
  showBorder: boolean;

  constructor(
    protected injector: Injector
  ) {

    super(injector);
   }

  ngOnInit() {
  }

  onDestroyed(): void {}


}
