<!-- Search bar -->
<div class="mb-3" fxLayoutAlign="center center">
  <kl-search class="search w-100"
    placeHolder="Type and hit enter to search tasks"
    (search)="search();"
    [searchForm]="searchForm">
  </kl-search>
</div>
<!-- /Search bar -->

<div id="project-list-table">

  <mat-card [@fadeIn] class="p-0">


    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>


    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--Task name column -->
      <ng-container matColumnDef="taskName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Task name </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.title }}</span>
        </td>
      </ng-container>
      <!--/ Task name column -->

      <!-- Start date column -->
      <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Start date </th>
        <td mat-cell *matCellDef="let element">
          <span>
            {{ element?.timeFrame?.start | date: 'dd' }}
            {{ element?.timeFrame?.start | date: 'MMM' | translate}}
            {{ element?.timeFrame?.start | date: 'yyy' }}
          </span>
        </td>
      </ng-container>
      <!-- / Start date column -->

      <!-- Start date column -->
      <ng-container matColumnDef="actualStartDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Actual date </th>
        <td mat-cell *matCellDef="let element">
          <span>
            {{ element?.updatedTimeFrame?.start | date: 'dd' }}
            {{ element?.updatedTimeFrame?.start | date: 'MMM' | translate}}
            {{ element?.updatedTimeFrame?.start | date: 'yyy' }}
          </span>
        </td>
      </ng-container>
      <!-- / Start date column -->

      <!--Variety column -->
      <ng-container matColumnDef="variety">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Variety </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.variety }} </span>
        </td>
      </ng-container>
      <!--/ Variety column -->

      <!-- Template type column -->
      <ng-container matColumnDef="templateType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Task type </th>
        <td mat-cell *matCellDef="let element" class="text-secondary"> {{ element?.templateRefTitle | titlecase | translate }} </td>
      </ng-container>
      <!-- / Template type column -->

      <!-- Members column -->
      <ng-container matColumnDef="members">
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Members </th>
        <td mat-cell *matCellDef="let element">
          <div fxLayout fxLayoutGap="4px" fxLayoutAlign="start center">

          {{ element?.statistics?.members?.total}}
          <p *ngIf="!element?.statistics?.members?.total">-</p>
          </div>

        </td>
      </ng-container>
      <!--/ Members column -->

      <!-- Status column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center" translate> Status </th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <!-- <div *ngFor="let item of element?.chips">
            <kl-chips [chip]="item"></kl-chips>
          </div> -->
          <div fxLayout fxLayoutAlign="center center" fxLayoutGap="4px">
            <div class="chip {{ element?.state | lowercase }}">
              <p class="text-white">{{ element?.state | titlecase | trimSpecialCharacters | translate }}</p>
            </div>
          </div>
        </td>
      </ng-container>
      <!-- / Status column -->

      <!-- Settings column -->
      <ng-container matColumnDef="settings">
        <th mat-header-cell *matHeaderCellDef class="text-center" translate>Actions </th>
        <td mat-cell *matCellDef="let element">
          <!-- <button mat-icon-button [matMenuTriggerFor]="menu" class="menu-button">
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
            <button mat-menu-item class="mb-0" (click)="deleteTask(element?.id)">Delete</button>
            <button mat-menu-item class="mb-0" (click)="showAddTaskMembersDialog(element?.id)">Members</button>
          </mat-menu> -->
          <div fxLayout="row" class="actions" fxLayoutAlign="center center" *ngIf="element?.userRole === projectRoles?.ADMIN">
            <button mat-icon-button class="mb-0" (click)="showAddTaskMembersDialog(element?.id);$event.stopPropagation()" [matTooltip]="'Add members' | translate">
              <mat-icon>group_add</mat-icon>
            </button>
            <button mat-icon-button class="mb-0" (click)="deleteTask(element?.id);$event.stopPropagation()" [matTooltip]="'Delete task' | translate">
              <mat-icon>delete_outline</mat-icon>
            </button>
            <button mat-icon-button class="mb-0" (click)="openEditTaskDialog(element?.taskId, element?.projectId);$event.stopPropagation()" [matTooltip]="'Edit task' | translate">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <!-- / Settings column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="cursor-pointer" mat-row *matRowDef="let task; columns: displayedColumns;"
        (click)="openTaskFullviewDialog(task?.title, task?.taskId, task)"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

</div>
