<div  fxLayout="row wrap" fxLayoutAlign="space-between center" [ngClass]="{'h-100': !stream || stream?.length <= 0}" class="px-2 list-wrapper"  *ngIf="(stream$ | async) as stream">

  <!-- Users -->
  <div *ngFor="let user of stream" fxFlex="48%" fxFlex.xs="100%" class="mb-3">
    <kl-selectable-user-tile [author]="user" avatarSize="md" [showRemove]="true" (removed)="removeSelectedUser(user?.profileKey)">
    </kl-selectable-user-tile>
  </div>
  <!--/ Users -->

  <div fxFlex="100%" fxLayoutAlign="center center" *ngIf="paginator$ | async as paginator">
    <ng-container *ngIf="!stream || stream?.length <= 0">
      <kl-no-content
        matIcon="person_add"
        text="Members not yet addded to this project!"
        progressText="Loading Members..."
        [progress]="paginator?.loading">
      </kl-no-content>
    </ng-container>

    <!-- Load more -->
    <div fxLayoutAlign="end center" class="mt-3" *ngIf="paginator && stream?.length">
      <kl-load-more
        noMoreText="No more members"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </div>
    <!-- /Load more -->

  </div>

</div>

