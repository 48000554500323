import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  ApiResponseCommon,
  KalgudiProject,
  KalgudiTaskTemplateSearchResults,
  PartialData,
  TaskTemplateDetails,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiProjectApiService {


  /**
   * `v2/projects`
   */
  private readonly API_PROJECTS = `${this.env.restBaseUrlV2}/projects`;

  /**
   * /v2/projects/:projectId
   */
  private readonly API_PROJECT = `${this.API_PROJECTS}/:projectId`;


  /**
   * `v2/projects/templates`
   */
  private readonly API_TEMPLATES = `${this.API_PROJECTS}/templates`;

  /**
   * `v2/projects/templates/:templateId`
   */
  private readonly API_TEMPLATE = `${this.API_TEMPLATES}/:templateId`;

  /**
   * `v2/projects/templates/search`
   */
  private readonly API_TEMPLATE_SEARCH = `${this.API_TEMPLATES}/search`;



  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }

  /**
   * Calls Kalgudi api to get project details
   *
   * @param projectId Unique id of entity
   */
  getProject(projectId: string, filters: PartialData = {}): Observable<KalgudiProject> {

    const url = this.API_PROJECT.replace(':projectId', projectId);

    const params = {
      ...filters
    };

    return this.httpClient.get<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  deleteProject(projectId: string, filters: PartialData = {}) {
    const url = this.API_PROJECT.replace(':projectId', projectId);

    const params = {
      ...filters
    };

    return this.httpClient.delete<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }


  /**
   * Api to fetch the task templates
   */
  getTaskTemplates(
    keyword: string,
    projectType: string,
    offset: number,
    limit: number,
    startFrom: string = ''): Observable<KalgudiTaskTemplateSearchResults> {

    const params = {
      keyword,
      projectType,
      offset: offset.toString(),
      limit: limit.toString(),
      startFrom
    };

    return this.httpClient
    .get<ApiResponseCommon>(this.API_TEMPLATE_SEARCH, { params })
    .pipe(
      map(res => this.util.apiErrorHandler(res)),

      map(res => res.data)
    );
  }

  getTemplateSchema(templateId: string): Observable<TaskTemplateDetails> {

    return this.httpClient
      .get<ApiResponseCommon>(this.API_TEMPLATE.replace(':templateId', templateId))
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }
}
