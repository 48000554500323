import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { KalgudiStreamS3FetchService } from '@kalgudi/social';
import { ProgramShareUpdate } from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { PageStreamApiService } from './page-stream-api.service';

@Injectable()
export class PageStreamService {

  constructor(
    private s3Fetch: KalgudiStreamS3FetchService,
    private api: PageStreamApiService) { }

  /**
   * Gets, stream of share update activities performed under specified program.
   *
   * @param entityId Page/program id
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getShareStream(entityId: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchStream(entityId, offset, limit)
      .pipe(

        // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
        // method.
        mergeMap(r =>

          // Combine response from all s3 items
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem<ProgramShareUpdate>(s as any, s.url, s.event))
          )
        ),

        // Map the complete stream response
        map(items => ({ items }))
      );
  }
}
