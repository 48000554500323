import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { StoreProductLevel2 } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TraceabilityApiService {

  /**
   * `/data/storeProducts/biz/:productId`
   */
  private readonly PRODUCT_LEVEL2 = `${this.env.domain}/data/storeProducts/:storeType/:productId`;

  /**
   * `v1/:storeType/orders/:orderId`
   */
  private readonly API_ORDER = `${this.env.restBaseUrl}/:storeType/orders/:orderId`;

  /**
   * `v2/estore/FMTPlans/traceability`
   */
  private readonly API_FULFILLMENT = `${this.env.domain}/v2/estore/FMTPlans/traceability`;

  /*
   * `v2/pages/trace/new`
   */
  private readonly API_NEW_TRACE = `${this.env.restBaseUrlV2}/pages/trace/new`;

  /**
   * `v2/listing/products/stocks-management/producer`
   */
  private readonly API_SUPPLIER_DETAILS = `${this.env.restBaseUrlV2}/listing/products/stocks-management/producer`

  /**
   * `/data/reviewAndRating/biz/:level2Id_METADATA`
   */
  private readonly REVIEW_RATING = `${this.env.domain}/data/reviewAndRating/:storeType/:level2Id_METADATA`;

  /**
   * v2/estore/orders/:orderId/trace
   */
  private readonly API_SUPPLIER_LIST = `${this.env.restBaseUrlV2}/estore/orders/:orderId/trace`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) {

  }

  /**
   * Fetches product level2 details from api
   * @param productId
   * @returns
   */
  getProductLevel2Details(productId: string): Observable<StoreProductLevel2> {

    const storeType = this.env.appId === 'SIKKIM' ? 'sikkim' : 'biz';

    const url = this.PRODUCT_LEVEL2.replace(':storeType', storeType).replace(':productId', productId);

    return this.httpClient.get<any>(url)
      .pipe(
        // map(res => this.util.apiErrorHandler(res)),
        map(res => res)
      )
  }


  /**
   * To get the complete details about an order
   * @param orderId of placed order for which full detail is required
   */
  getOrderFullView(orderId: string): Observable<any> {
    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    let headers = {
    };

    headers = this.getHeaders(store, orderId);

    const requestUrl = this.API_ORDER.replace(':storeType', store).replace(':orderId', orderId);

    return this.httpClient.get<any>(requestUrl, headers)
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => JSON.parse(res.data))
      );
  }

  /**
   * Api to get fulfillments details
   */
  getFulfillmentDetails(orderId: string, productLevel3Id: string): Observable<any> {

    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    let headers = {
    };

    headers = this.getHeaders(store, orderId);

    const url = `${this.API_FULFILLMENT}?orderId=${orderId}&productLevel3Id=${productLevel3Id}`;

    return this.httpClient.get<any>(url, headers)
      .pipe(

        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }

  /**
   * Mapping headers based on store type
   */
  getHeaders(store, orderId) {
    let headers: any;
    if (store === 'bizstore') {
      headers = {
        headers: {project: 'OUTPUTS_STORE'}
      }
    } else {
      headers = {
        headers: {project: this.getStore(orderId)}
      }
    }

    return headers;
  }

  /**
   * Mapping store type based on order id
   * @param orderId
   * @returns
   */
  getStore(orderId) {
    if (orderId.includes('AGORD')) return 'APGREEN_STORE';
    if (orderId.includes('EMORD')) return 'EMAHILA_STORE';
    if (orderId.includes('TGORD')) return 'TGREENS_STORE'
  }


  /**
   * Api to get traceability details
   */
  getTraceability(orderId: string, productLevel2Id: string, productLevel3Id: string): Observable<any> {

    const params = {
      orderId,
      productLevel2Id,
      productLevel3Id
    }
    return this.httpClient.get<any>(this.API_NEW_TRACE, {params})
      .pipe(
        // map(res => this.util.apiErrorHandler(res, 200)),

        map(res => res.data)
      );
  }


  /**
   * Api to get supplier list
   */
   getSupplierList(orderId: string): Observable<any> {

    const store = orderId.includes('BORD') ? 'bizstore' : 'apgreen';
    let headers = {
    };

    headers = this.getHeaders(store, orderId);


    return this.httpClient.get<any>(this.API_SUPPLIER_LIST.replace(':orderId', orderId), headers )
      .pipe(
        map(res => this.util.apiErrorHandler(res)),

        map(res => res.data)
      );
  }

  /**
   * Api method to fetch supplier details
   */
  getSupplierDetails(pageId: string): Observable<any> {

    const params = {
      pageId
    }

    return this.httpClient.get<any>(this.API_SUPPLIER_DETAILS, {params})
      .pipe(
        map(res => this.util.apiErrorHandler(res, 200)),

        map(res => res.data)
      );
  }

  /**
   * Api to get reviews and comments
   */

  getReviewsComments(level2Id: string): Observable<any> {

    const storeType = this.env.appId === 'SIKKIM' ? 'farmer' : 'biz';

    const requestUrl = this.REVIEW_RATING.replace(':storeType', storeType).replace(':level2Id', level2Id);

    return this.httpClient.get<any>(requestUrl)
      .pipe(
        //map(res => this.util.apiErrorHandler(res, 200)),
        map(res =>  {
          return res;
        })
      );
  }
}
