<div class="credits-header">
  <div class="container">
    <h1 class="text-center mb-5">{{userDetails?.firstName}}, your account</h1>

    <div fxLayout="row" fxLayoutAlign="space-between start">
      <!--user Details-->
      <div fxLayout="row" class="border-right" fxLayoutGap="10px" class="profile-details" fxFlex="60%">
        <!-- Profile pic -->
        <div fxFlex="80px">
          <img class="profile-pic" [src]="userDetails?.profilePicUrl | resizeImage: 'sm'">
        </div>

        <!-- user Profile details  -->
        <div fxLayout="column" fxLayoutGap="5px" fxFlex="calc(100% - 90px)">
          <p class="mb-0 name">{{userDetails?.firstName}}</p>
          <p class="mb-0 business-type">{{userDetails?.additionalBizDetails?.businessTypeName}}</p>
          <p class="mb-0 location">{{userDetails?.additionalBizDetails?.profileTypeDetails?.placeTo?.locationLong}}</p>
          <p class="mb-0 business-name">{{userDetails?.additionalBizDetails?.profileTypeDetails?.adminDetails?.businessName}}</p>
        </div>

      </div>
      <!-- /user Details -->
      <p class="mb-0 name text-line" fxFlex="40%">{{userDetails?.connectsCount}} Connections</p>
      <!-- Connections count -->
    </div>
  </div>
</div>
