<div class="autocomplete product-autocomplete">
  <form autocomplete="off" [formGroup]="autoCompleteForm">

    <div fxLayout fxLayout.xs="row wrap">
      <div fxFlex="110px" fxFlex.xs="100%">
        <mat-form-field class="mat-form-field-sm w-100 selection-field no-right-border" matPrefix appearance="outline">
          <mat-label translate>Store type</mat-label>
          <mat-select [formControl]="searchType">
            <mat-option *ngFor="let type of searchTypeList" [value]="type?.id">
              {{type?.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxFlex="calc(100% - 110px)" fxFlex.xs="100%">
        <mat-form-field [appearance]="appearance" class="w-100 mat-form-field-sm no-left-border">
          <mat-label translate>{{label}}</mat-label>

          <input type="text" matInput formControlName="productName"
            [placeholder]="placeholder | translate"
            [matAutocomplete]="autoComplete" [required]="required">


          <mat-autocomplete autoActiveFirstOption class="product-list" #autoComplete="matAutocomplete" [displayWith]="displayWithFn">
            <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" (onSelectionChange)="selectItem($event)">
              <div fxLayout="row" fxLayoutAlign="start center" class="mw-100">
                <!-- <mat-icon class="text-success">eco</mat-icon> -->

                <div class="products">
                  <span class="d-block autocomplete-field text-truncate product-name">{{prediction?.productName}}</span>
                  <div fxLayout="row">
                    <span class="dot d-inline-block mr-1"></span>
                    <span class="d-block autocomplete-field text-truncate value">{{prediction?.baseCategory?.value}}</span>
                    <span class="product-pipe mx-1"></span>
                    <span class="d-block autocomplete-field mr-1 subtitle-title">Mfr.</span>
                    <span class="d-block autocomplete-field text-truncate value">{{prediction?.manufacturerDetails?.firstName}}</span>
                  </div>
                  <div fxLayout="row" *ngIf="prediction?.resellerDetails?.firstName">
                    <span class="dot d-inline-block mr-1"></span>
                    <span class="d-block autocomplete-field text-truncate subtitle-title">Reseller :&nbsp;</span>
                    <span class="d-block autocomplete-field text-truncate value">{{prediction?.resellerDetails?.firstName}}</span>
                  </div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <button id="search-icon-btn" mat-button matSuffix mat-icon-button type="submit">
            <mat-icon class="search">search</mat-icon>
          </button>
          <mat-error *ngIf="required && inputField?.touched" translate class="product-mat-error" translate>Product is required</mat-error>
        </mat-form-field>
      </div>
    </div>

    <p class="mt-n1 mb-n2 text-danger no-product-error " *ngIf="noPrediction && inputFieldValue?.length > 2" @fadeIn>
      <span translate>No products found with keyword </span>
      <strong>"{{ inputFieldValue }}"</strong>
    </p>
  </form>
</div>
