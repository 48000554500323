<main class="w-100 existing-projects" fxLayout="column">
  <ng-container *ngIf="((stream$ | async) || true) as stream">
    <div class="mb-1 search-field" fxLayoutAlign="space-between center">
      <kl-search class="search"
        fxLayoutAlign="start center"
        fxFlexOrder.xs="2"
        [placeHolder]="'Search project' | translate"
        (search)="search();"
        [searchForm]="searchForm">
      </kl-search>

      <div class="total-projects" fxFlexOrder.xs="1" *ngIf="paginator$ | async as paginator">
        <span translate>ALL({{paginator.count}})</span>
      </div>
    </div>
    <!-- /Search bar -->

    <section class="mb-2 pt-1 project-tile-list" fxLayout="row wrap" fxLayoutGap.gt-xs="13px">
        <div class="project-tile cursor-pointer" fxFlex="20%" fxFlex.sm="30.5%" fxFlex.md="20%"  fxFlex.xs="100%" *ngFor="let project of stream; let i = index" (click)="selectExistingProject(project)">
          <div fxLayout.gt-xs="column" fxLayout.xs="row" [matTooltip]="project?.title" fxLayoutGap.xs="10px" fxLayoutAlign.xs="start center">
            <div class="project-selection" fxLayoutAlign.gt-xs="start start" fxLayoutAlign.xs="end center" fxFlexOrder.xs="3">
              <div class="not-selected rounded-circle" [ngClass]="{'selected-project': selectedExistingProjectDetails && (selectedExistingProjectDetails.id === project.id)}">
                <mat-icon class="text-white">done</mat-icon>
              </div>
            </div>

            <div fxLayoutAlign.gt-xs="center center" class="icon" fxFlexOrder.xs="1">
              <svg-icon class="projects-icon"
                src="assets/svgs/projects.svg" [svgStyle]="{'width.px': '27', 'height.px': '27'}" [applyCss]="true"></svg-icon>
            </div>

            <div fxLayout="column" fxLayoutAlign.gt-xs="center center" fxFlexOrder.xs="2">
              <span class="text-truncate project-creation-title">{{project.title | titlecase}}</span>
              <span class="crop-calendar">{{project?.type | titlecase | trimSpecialCharacters | translate}}</span>
              <span class="date text-muted">{{project.CT | date:'dd MMM yyyy'}}</span>
            </div>
          </div>
        </div>

        <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

          <!-- No content and loading progress -->
          <ng-container *ngIf="!stream || stream?.length <= 0">
            <kl-no-content
              svgIcon="assets/svgs/projects.svg"
              text="You are not part of any project"
              progressText="Loading your projects..."
              [progress]="paginator?.loading">
            </kl-no-content>
          </ng-container>
          <!--/ No content and loading progress -->

          <!-- Load more -->
          <div class="mt-3" *ngIf="paginator && stream?.length">
            <kl-load-more
              noMoreText="No more projects"
              [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </div>
          <!-- /Load more -->
        </div>
    </section>
  </ng-container>
</main>
