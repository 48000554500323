import { Component,Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { KalgudiOrgProfileEditDetails } from '../../classes/kalgudi-org-profile-edit-details';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';
import * as moment from 'moment';

@Component({
  selector: 'kl-cooperative-society-edit',
  templateUrl: './cooperative-society-edit.component.html',
  styleUrls: ['./cooperative-society-edit.component.scss']
})
export class CooperativeSocietyEditComponent extends KalgudiOrgProfileEditDetails implements OnInit {

  maxDate = new Date();
  isShgApp: boolean = false;
  currentDate: any;
  enteredDate: any;
  yearsDiff: number = 0;
  monthsDiff: number = 0;
  daysDiff: number = 0;
  totalMonths: number = 0;


  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,
    private translate: TranslateService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
  ) {
    super(injector, fb);

    this.isShgApp = this.env?.appId === 'SHG_NET_APP';

  }

  ngOnInit() {
    this.init();
    this.enteredDate = this.establishedSince.value;
    this.calculateDateDifference(this.establishedSince.value);
    this.establishedSince.valueChanges.subscribe(value => {
      if(value){
        this.calculateDateDifference(value);
      }
    });
  }

  onDestroyed(): void {}

  /**
   * Not Allowing characters and symbols
   * @param event
   * @returns
   */
  onKeyPress(event: any , activity?: any) {
    var x = event.which || event.keycode;

    if(activity) {
      if (activity === 'title') {
        if ((x >= 65 && x <= 90) || (x >= 97 && x <= 122) || x === 32) {
          return true;
          } else {
          return false;
          }
      }
      else if((x>=48 && x<=57) || x == 46) {
        return true;
      }
      else {
        return false;
      }
    }

    else {
      if((x>=48 && x<=57)) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  /**
   * Validate input field for max 4 or 6-digit numbers.
   * @param event
   * @param fieldValue
   * @param fieldName
   * @returns
   */
  validateNumericInputLength(event, fieldValue?: any, fieldName?: any) {
    const x = event.which || event.keyCode;
    const isNumeric = (x) => x >= 48 && x <= 57;

    switch(fieldName) {
      case 'employees':
      case 'villages':
        return isNumeric(x) && fieldValue.length < 4;

      case 'land':
        return (isNumeric(x) || x === 46) && fieldValue.length < 6;

      default:
        return false;
    }

  }

  /**
   * Not Allowing spaces at the starting
   * @param event
   * @returns
   */
  onInputChange(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;

    if(inputValue.startsWith(' ')) {
      inputElement.value = inputValue.replace(/^\s+/, '');
    }
  }

  /**
   * Removing value 0 if focus is on
   * Adding value 0 of focus is out
   * @param event
   */
  farmerFocus(event?: any) {
    if(!this.farmersFiled.value) {
      this.farmersFiled.patchValue(event !== undefined ? 0 : '');
    }
  }

  /**
   * Removing value 0 if focus is on
   * Adding value 0 of focus is out
   * @param event
   */
  villagesFocus(event?: any) {
    if(!this.villagesFiled.value) {
      this.villagesFiled.patchValue(event !== undefined ? 0 : '');
    }
  }

  /**
   * Removing value 0 if focus is on
   * Adding value 0 of focus is out
   * @param event
   */
  employeeFocus(event?: any) {
    if(!this.employessFiled.value) {
      this.employessFiled.patchValue(event !== undefined ? 0 : '');
    }
  }

  /**
   * Removing value 0 if focus is on
   * Adding value 0 of focus is out
   * @param event
   */
  landFocus(event?: any) {
    if(!this.landFiled.value) {
      this.landFiled.patchValue(event !== undefined ? 0 : '');
    }
  }

  /**
   * To calculate the age
   */
  calculateDateDifference(userEnteredDate: string) {

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    // Check if the dateString matches the regex format or 'YYYY-MM-DD'
    if (regex?.test(userEnteredDate)) {
      const [year, month, day] = userEnteredDate.split('-');
      // Format the date as DD-MM-YYYY
      var formattedDate = `${day}-${month}-${year}`;
    }
    else {
      formattedDate = userEnteredDate;
    }

    this.currentDate = moment();
    this.enteredDate = moment(formattedDate, 'DD-MM-YYYY'); // Assuming userEnteredDate is in 'DD-MM-YYYY' format
    this.totalMonths = this.currentDate.diff(this.enteredDate, 'months');
    this.yearsDiff = Math.floor(this.totalMonths / 12);
    this.monthsDiff = this.totalMonths % 12;
    this.daysDiff = this.currentDate.subtract(this.yearsDiff, 'years').subtract(this.monthsDiff, 'months').diff(this.enteredDate, 'days');

  }
}
