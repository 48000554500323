import { Injector, Input, Directive } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiTranslationService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { IdValueMap, KalgudiLocation, WeatherDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { weatherIconCodes, weatherTime } from '../constants';
import { KalgudiWeatherService } from '../services/kalgudi-weather.service';

@Directive()
export abstract class WeatherFullview  extends KalgudiDestroyable {

  @Input()
  location: KalgudiLocation;

  @Input()
  profileKey: string;

  searchLocation: KalgudiLocation;

  weatherDetails: WeatherDetails[];

  searchWeatherDetails: WeatherDetails[];

  weatherCodes = weatherIconCodes;

  weatherTime = weatherTime;

  weatherUpdates = [];

  weatherUpdatesByLocation = [];

  todaysWeather: WeatherDetails;

  searchTodaysWeather: WeatherDetails;

  locationForm = new FormGroup ({
    locationDetails: new FormControl('')
  });

  favoriteLocations: KalgudiLocation[];
  isFavoriteLocation: boolean;
  langSelected: any;


  availableLangs: IdValueMap[] = [
    { id: 'EN', value: 'English' },
    { id: 'HI', value: 'Hindi' },
  ];

  langForm = new FormControl('');


  private weatherService: KalgudiWeatherService;
  private previousLocation: any;
  private notification: KalgudiNotification;


  constructor(
    protected injector: Injector,
    protected translationService: KalgudiTranslationService
    ) {

    super();

    this.weatherService = this.injector.get(KalgudiWeatherService);
    this.notification   = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.subscribeToValueChanges();
  }

  // --------------------------------------------------------
  // #region Public methodss
  // --------------------------------------------------------


  /**
   * Gets weather details for other locations
   */
  getWeatherDetailsByLocation(latitude: string | number, longitude: string | number, language: string) {

    if (this.isPreviousLocationSame(latitude, longitude, language)) {

      return;
    }

    this.previousLocation = {
      latitude: latitude,
      longitude: longitude,
      language: language
    };

    this.notification.showSpinner();

    this.weatherService.getWeatherDetails(this.profileKey, latitude, longitude, language)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => {
          this.searchWeatherDetails = res.results[0];
          this.searchTodaysWeather = res.results[0][0];
          this.isFavoriteLocation = res.flag;
          this.searchWeather(this.searchTodaysWeather);
        }
      );
  }

  /**
   * Gets weather details
   */
  getWeatherDetails(latitude: string | number, longitude: string | number, language: string) {

    if (this.isPreviousLocationSame(latitude, longitude, language)) {
      return;
    }

    this.previousLocation = {
      latitude: latitude,
      longitude: longitude,
      language: language
    };

    this.weatherService.getWeatherDetails(this.profileKey, latitude, longitude, language)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.weatherDetails = res.results[0];
          this.todaysWeather = res.results[0][0];
          this.initWeather(this.todaysWeather);
        }
      );
  }

  /**
   * Calls api method to add it in favorite locations
   */
  addToFavoriteLocation(locationDetails: KalgudiLocation) {
    this.weatherService.addToFavoriteLocations(this.profileKey, locationDetails)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.onFavoriteLocationSuccess(res),
        err => this.onFavoriteLocationFailure(err)
      );
  }

  /**
   * Calls api to fetch favorite locations
   */
  getFavoriteLocations() {
    this.weatherService.getFavoriteLocations(this.profileKey)
    .pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe(
      res => {
        this.favoriteLocations = res.results;
      }
    );
  }

  /**
   * Calls weather details by location method of favorite location
   */
  getFavoriteLocationDetails(location: KalgudiLocation) {
    this.searchLocation = location;
    this.getWeatherDetailsByLocation(
      this.searchLocation.latitude,
      this.searchLocation.longitude,
      this.translationService.activeLanguage.toUpperCase());
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region protected and private methods
  // --------------------------------------------------------

  /**
   * Event handler for successful favorite location
   */
  protected onFavoriteLocationSuccess(res: KalgudiLocation): void {
    this.notification.showMessage('Added to favorite locations');
    this.getFavoriteLocations();
  }

  /**
   * Event handler for adding favorite location API errors.
   */
  protected onFavoriteLocationFailure(err: Error): void {
    this.notification.showMessage('Unable to add it in favorite location, please try again later!');
  }

  /**
   * Calls this method whenever the location value changes
   */
  private subscribeToValueChanges() {
    this.locationForm.valueChanges.subscribe(
      res => {
        this.searchLocation = res.locationDetails;
        this.getWeatherDetailsByLocation(
          this.searchLocation.latitude,
          this.searchLocation.longitude,
          this.translationService.activeLanguage.toUpperCase());
      }
    );
  }

  private initWeather(weather: WeatherDetails) {
    this.weatherUpdates = [
      {
        title: 'Humidity',
        value: weather.hourly[0].humidity + '%',
        staticIcon: this.weatherCodes.HUMIDITY,
        iconSize: '26'
      },
      {
        title: 'rain',
        value: weather.hourly[0].chanceofrain + '%',
        icon: this.weatherCodes[182],
        iconSize: '51',
        marginLeft: '-17px'
      },
      {
        title: 'cloud',
        value: weather.hourly[0].cloudcover + '%',
        weatherIcon: this.weatherCodes.CLOUD,
        iconSize: '32',
        marginLeft: '-8px',

      },
      {
        title: 'visibility',
        value: weather.hourly[0].visibility + ' km',
        weatherIcon: this.weatherCodes.VISIBILITY,
        iconSize: '28'
      },
      {
        title: 'sunrise',
        value: weather.astronomy[0].sunrise,
        icon: this.weatherCodes[113],
        iconSize: '56',
        marginLeft: '-22px'
      },
      {
        title: 'sunset',
        value: weather.astronomy[0].sunset,
        icon: this.weatherCodes.SUNSET,
        iconSize: '55',
        marginLeft: '-16px'
      },
      {
        title: 'Moonrise',
        value: weather.astronomy[0].moonrise,
        icon: this.weatherCodes.MOON_RISE,
        iconSize: '60'
      },
      {
        title: 'Moonset',
        value: weather.astronomy[0].moonrise,
        icon: this.weatherCodes.MOON_SET,
        iconSize: '44',
        marginRight: '8px',
        marginLeft: '-10px'
      },
      {
        title: 'Wind flow',
        value: weather.hourly[0].windspeedKmph + ' kmph',
        weatherIcon: this.weatherCodes.WIND_FLOW,
        iconSize: '32',
        marginLeft: '-6px'
      },
    ];
  }

  private searchWeather(weather: WeatherDetails) {
    this.weatherUpdatesByLocation = [
      {
        title: 'Humidity',
        value: weather.hourly[0].humidity + '%',
        staticIcon: this.weatherCodes.HUMIDITY,
        iconSize: '26'
      },
      {
        title: 'rain',
        value: weather.hourly[0].chanceofrain + '%',
        icon: this.weatherCodes[182],
        iconSize: '51',
        marginLeft: '-17px'
      },
      {
        title: 'cloud',
        value: weather.hourly[0].cloudcover + '%',
        weatherIcon: this.weatherCodes.CLOUD,
        iconSize: '32',
        marginLeft: '-8px',

      },
      {
        title: 'visibility',
        value: weather.hourly[0].visibility + ' km',
        weatherIcon: this.weatherCodes.VISIBILITY,
        iconSize: '28'
      },
      {
        title: 'sunrise',
        value: weather.astronomy[0].sunrise,
        icon: this.weatherCodes[113],
        iconSize: '56',
        marginLeft: '-22px'
      },
      {
        title: 'sunset',
        value: weather.astronomy[0].sunset,
        icon: this.weatherCodes.SUNSET,
        iconSize: '55',
        marginLeft: '-16px'
      },
      {
        title: 'Moonrise',
        value: weather.astronomy[0].moonrise,
        icon: this.weatherCodes.MOON_RISE,
        iconSize: '60'
      },
      {
        title: 'Moonset',
        value: weather.astronomy[0].moonrise,
        icon: this.weatherCodes.MOON_SET,
        iconSize: '44',
        marginRight: '8px',
        marginLeft: '-10px'
      },
      {
        title: 'Wind flow',
        value: weather.hourly[0].windspeedKmph + ' kmph',
        weatherIcon: this.weatherCodes.WIND_FLOW,
        iconSize: '32',
        marginLeft: '-6px'
      },
    ];
  }

  /**
   * Returns true if the specified location is equal to previous location.
   */
  private isPreviousLocationSame(latitude: string | number, longitude: string | number, language: string) {

    return this.previousLocation
      && this.previousLocation.latitude === latitude
      && this.previousLocation.longitude === longitude
      && this.previousLocation.language === language;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
