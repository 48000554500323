
<section class="common-page-form page-creation-form">
  <form autocomplete="off" class="p-0 page-form mt-2" [formGroup]="pageForm" fxLayout="column" fxLayoutGap="20px">

    <div class="page-info">
      <div fxLayout fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.xs="column">
        <div class="mb-3 page-label" fxLayout="column" fxFlex="40%" [@floatTop]="{ value: '20', params: {delay: 50}}">
          <mat-form-field appearance="outline">
            <mat-label translate>Page title</mat-label>
            <input matInput formControlName="pageTitle" required>
            <mat-error class="mt-3" *ngIf="pageTitleField?.errors?.required" translate>Please mention page title</mat-error>
          </mat-form-field>
        </div>
        <div class="mb-3 page-label" fxLayout="column" fxFlex="40%" [@floatTop]="{ value: '20', params: {delay: 50}}">
          <mat-form-field appearance="outline">
            <mat-label translate>Page description</mat-label>
            <textarea rows="1" matInput formControlName="pageDescription"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.xs="column">
        <div class="mb-3 pt-1 page-label" fxLayout="column" fxFlex="40%">
          <mat-form-field appearance="outline"
            [@floatTop]="{ value: '40', params: {delay: 100}}">
            <mat-label translate>E-mail ID</mat-label>
            <input matInput formControlName="emailId">
            <mat-error class="mt-3" *ngIf="emailIdField?.errors" translate>
              Please mention valid email id
            </mat-error>
          </mat-form-field>
        </div>
        <div class="mb-3 pt-1 page-label" fxLayout="column" fxFlex="40%">
          <mat-form-field appearance="outline"
            [@floatTop]="{ value: '60', params: {delay: 150}}">
            <mat-label translate>Contact number</mat-label>
            <input matInput formControlName="mobileNo">
            <mat-error class="mt-3" *ngIf="mobileNoField.errors" translate>
              Please mention valid mobile number
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <!-- Address -->
      <div fxLayout fxLayoutAlign="center center" fxLayoutGap="20px" fxLayout.xs="column">
        <div class="mb-3 pt-1 page-label" fxLayout="column" fxFlex="40%">
            <mat-form-field appearance="outline"
              [@floatTop]="{ value: '80', params: {delay: 200}}">
              <mat-label translate>Address</mat-label>
              <input matInput formControlName="address">
            </mat-form-field>
        </div>
        <div class="mb-3 pt-1 page-label" fxLayout="column" fxFlex="40%">
          <!-- <mat-form-field appearance="outline"
            [@floatTop]="{ value: '100', params: {delay: 250}}">
            <mat-label>Location</mat-label>
            <input matInput>
          </mat-form-field> -->
        <div [@floatTop]="{ value: '100', params: {delay: 250}}">
          <kl-google-places-autocomplete formControlName="locationDetails" [placeholder]="'Village / Town / City' | translate"></kl-google-places-autocomplete>
        </div>
      </div>
    </div>
      <!-- / Address -->
    </div>

  </form>
</section>
