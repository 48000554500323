import { Component, Injector, OnInit } from '@angular/core';

import { AddAwards } from '../../classes/add-awards';

@Component({
  selector: 'kl-add-awards',
  templateUrl: './add-awards.component.html',
  styleUrls: ['./add-awards.component.scss']
})
export class AddAwardsComponent extends AddAwards implements OnInit {

  s3Category: any;
  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {

    if (this.awardDetails) {
      this.addAwardForm.patchValue(this.awardDetails);
    }

    this.attachmentsField.patchValue([]);
  }

  onDestroyed(): void {}


  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    const attachments = this.attachmentsField.value || [];
    attachments.splice(index, 1);
    this.attachmentsField.patchValue(attachments);
  }
}

