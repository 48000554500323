import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable, checkMobileDevice } from '@kalgudi/core';
import { KalgudiProfileService } from '../../../../../services/kalgudi-profile.service';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { CropMobileDialogComponent } from '../crop-mobile-dialog/crop-mobile-dialog.component';
import { CropDialogComponent } from '../crop-dialog/crop-dialog.component';

@Component({
  selector: 'kl-crop-tile',
  templateUrl: './crop-tile.component.html',
  styleUrls: ['./crop-tile.component.scss']
})
export class CropTileComponent extends KalgudiDestroyable implements OnInit {

  @Input()
  profileKey: string;

  @Input()
  cropFormDetails: any;

  @Input()
  actionIcon: string;

  @Input()
  isPublicPage: boolean;

  @Output()
  getFpoProfileData = new EventEmitter();

  editable: boolean;
  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;
  constructor(protected injector: Injector) {
    super();
    this.dialogService = this.injector.get(KalgudiDialogsService);
    this.profileService = this.injector.get(KalgudiProfileService);
    this.profileService.editable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.editable = res));
  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows social dialog
   */
  showCropDialog() {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Crop Info',
      data: {
        profileKey: this.profileKey,
        cropFormDetails: this.cropFormDetails,
        pageId: 'CROP'
      },
    };
    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'crop',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {},
    };
    return this.openCropDialog(dialogDetails, dialogConfig)
    .pipe(
      filter((r) => r.accepted),
      // map(r => r.accepted),
    )
    .subscribe(
      res => {
        if(res.accepted) {
          this.getFpoProfileData.emit();
        }
      }
    )
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------
  /**
   * Open social dialog
   */
  private openCropDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {
    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(CropMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(CropDialogComponent, dialogConfig, matDialogConfig);
    }
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
  onDestroyed(): void {}
  ngOnInit(): void {}

}
