import { EventEmitter, Inject, Injector, Output, Directive } from '@angular/core';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiAppService, KalgudiUsersService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiReportAbuseService, KalgudiSocialActions, KalgudiTranslationListService } from '@kalgudi/social';
import { GoogleSupportedLanguagesList, LanguageIdList } from '@kalgudi/third-party/google-language-translator';
import { ShareQaAnswerComment, ShareQaAnswerLike, ShareQaFullDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { KalgudiQaService } from './services/kalgudi-qa.service';

@Directive()
export abstract class KalgudiQaActions<T extends ShareQaFullDetails> extends KalgudiSocialActions<T> {

  @Output()
  answered = new EventEmitter<T>();

  @Output()
  answerLiked = new EventEmitter<ShareQaAnswerLike>();

  @Output()
  answerCommented = new EventEmitter<ShareQaAnswerComment>();

  // Google language translations options
  translationsList: LanguageIdList = [];
  source: GoogleSupportedLanguagesList;

  private translationListService: KalgudiTranslationListService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected kalgudiDialogs: KalgudiDialogsService,
    protected kalgudiApp: KalgudiAppService,
    protected usersService: KalgudiUsersService,
    protected qaService: KalgudiQaService,
    protected reportAbuse: KalgudiReportAbuseService,
    protected util: KalgudiUtilityService,
  ) {

    super(
      notifications,
      kalgudiDialogs,
      kalgudiApp,
      usersService,
      reportAbuse,
      util,
      injector
    );

    this.translationListService = this.injector.get(KalgudiTranslationListService);
  }


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   *
   * @param shareDetails
   */
  protected updateLikeDetails(res: T): void {

  }

  /**
   * Calls API to delete post.
   */
  protected deletePostApi(): Observable<boolean> {

    // Call api to delete post
    return this.qaService.deletePost(this.postDetails.questionId);
  }

  /**
   * Initializes google language translator options
   */
  protected initGoogleLangTranslator(): void {

    this.translationListService.initGoogleLangTranslator()
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(list => {
        this.translationsList = list;
        this.source = 'en';
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
