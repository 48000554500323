import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SocialSharesComponent } from './components/social-shares/social-shares.component';
import { SocialSharesService } from './services/social-shares.service';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [SocialSharesComponent],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    FlexLayoutModule,

    // Material module
    MatMenuModule,
    MatButtonModule,
    TranslateModule
  ],
  exports: [
    SocialSharesComponent
  ],
  providers: [
    SocialSharesService
  ]
})
export class KalgudiSocialSharesModule { }
