import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { LatLong } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {

  readonly positionOptions: PositionOptions = {
    // Maximum age to return the cached position
    maximumAge: 60000,
  };

  /**
   * Gets the current geo location coordinates details
   */
  readonly geoCoords$: Observable<any>;

  /**
   * Gets the current geo latitude and longitude details
   */
  readonly latLong$: Observable<LatLong>;


  private readonly geoCoordsSubject = new BehaviorSubject<any>(null);
  private readonly latLongSubject     = new BehaviorSubject<LatLong>(null);


  /**
   * Flag to ensure the service does not gets initialized more than once
   */
  private initialized = false;

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private http: HttpClient,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig
  ) {
    this.geoCoords$ = this.geoCoordsSubject.pipe( filter(val => val !== null) );
    this.latLong$   = this.latLongSubject.pipe( filter(val => val !== null) );

    this.init();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Current lat long returned by the geo location API
   */
  get currentLatLong(): LatLong {
    return this.latLongSubject.getValue();
  }

  /**
   * Current geo coordinates returned by the geo location API
   */
  get currentGeoCoords(): any {
    return this.geoCoordsSubject.getValue();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Fetches the geo location details and watches for the location changes
   * afterwards.
   */
  init(): void {

    if (this.initialized) {
      // Service already initialized, no need to initialize it again
      console.warn('GeoLocation already initialized');
      return;
    }

    // Verify geo location API availability
    this.assertGeoLocationApi();

    // Geo location available fetch latest geo coordinates
    navigator.geolocation.getCurrentPosition(
      position => {
        this.onGeoLocationWatchPositionSuccess(position);

        // Watch for subsequent location changes from now
        this.watchForLocationChanges();
      },
      error => {
        console.error('GeoLocation error: ', error.message);
      },
      this.positionOptions,
    );


    // Set initialized to true
    this.initialized = true;
  }

  /**
   * Calculates area enclosed by a series of closed lat long boundary.
   *
   * Returns area in square metres
   *
   * @see https://stackoverflow.com/a/32912727/2401088
   */
  calculateArea(coords: LatLong[]): number {

    let area = 0;

    if (coords.length > 2) {

      for (let i = 0; i < coords.length - 1; i++)
      {
        let p1 = coords[i];
        let p2 = coords[i + 1];
        area += this.toRadian(+p2.longitude - +p1.longitude)
          * (
              2 + Math.sin(this.toRadian(+p1.latitude))
                + Math.sin(this.toRadian(+p2.latitude))
            );
      }

      area = area * 6378137 * 6378137 / 2;
    }

    return Math.abs(area);
  }

  /**
   * Convert square meter to acres
   *
   * @see https://www.checkyourmath.com/convert/area/m_acres.php
   */
  convertSqMetresToAcre(val: number): number {
    return val / 4046.85642;
  }

  /**
   * Fetches formatted location object
   * @param geoLocation { lat&long }
   * @returns
   */
  getLocationPlace(geoLocation) {
    // Using dev googlePlacesAPI
    if(geoLocation) {
      return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${geoLocation.latitude},${geoLocation.longitude}&key=AIzaSyAM1rm3Chd60HQyQ6SwsJ0L_NIA5W7OBCY`).toPromise();
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Subscribes to the navigation geoLocation object and continuously
   * watches for location changes.
   */
  private watchForLocationChanges(): void {

    // Verify geo location API availability
    this.assertGeoLocationApi();


    // Watch for position changes
    navigator.geolocation.watchPosition(
      position => this.onGeoLocationWatchPositionSuccess(position),
      err => this.onGeoLocationWatchPositionError(err),
      this.positionOptions,
    );
  }

  /**
   * Event handler gets called on success geo location position
   * change.
   */
  private onGeoLocationWatchPositionSuccess(position: any): void {

    const { coords } = position;
    const { latitude, longitude } = coords;

    // Update the latest coordinates and lat longs
    this.geoCoordsSubject.next(coords);
    this.latLongSubject.next({ latitude, longitude });
  }

  /**
   * Event handler gets called on failure geo location position fetch.
   */
  private onGeoLocationWatchPositionError(error: any): void {

    console.error('Error while fetching GeoLocation: ', error.message);

    if (error.code === error.PERMISSION_DENIED) {
      this.notification.showMessage('Please provide location permissions for better usage.');
    }
  }

  /**
   * Checks if geo location api is available or not.
   * It throws an error if geo location Api is not available.
   */
  private assertGeoLocationApi(): void {

    // If geo location is available
    if (!navigator.geolocation) {
      throw new Error('Location provider not available');
    }
  }

  /**
   * Converts coordinates to Radians
   *
   * @see https://stackoverflow.com/a/32912727/2401088
   */
  private toRadian(input: number) {
    return input * Math.PI / 180;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
