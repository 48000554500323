export class AppRoutes {
  /** /auth */
  static readonly AUTH = `/auth`;

  /** /auth/signin */
  static readonly AUTH_LOGIN = `${AppRoutes.AUTH}/signin`;

  /** /auth/signup */
  static readonly AUTH_SIGNUP = `${AppRoutes.AUTH}/signup`;

  /** /auth/reset */
  static readonly AUTH_RESET_PASSWORD = `${AppRoutes.AUTH}/reset`;

  /** /auth/otp */
  static readonly AUTH_OTP = `${AppRoutes.AUTH}/otp`;

  /** /app */
  static readonly APP = `/app`;

  /** /app/home */
  static readonly APP_HOME = `${AppRoutes.APP}/home`;

  /** /app/social */
  static readonly APP_SOCIAL = `/app/social`;

  /** /app/social/updates */
  static readonly APP_SOCIAL_UPDATES = `${AppRoutes.APP_SOCIAL}/updates`;

  /** /app/social/updates */
  static readonly APP_SOCIAL_UPDATES_LIST = `${AppRoutes.APP_SOCIAL_UPDATES}/list`;

  /** /app/social/updates/:shareId */
  static readonly APP_SOCIAL_UPDATES_VIEW = `${AppRoutes.APP_SOCIAL_UPDATES}/:shareId`;

  /** /app/social/qa */
  static readonly APP_SOCIAL_QA = `${AppRoutes.APP_SOCIAL}/qa`;

  /** /app/social/qa/stream */
  static readonly APP_SOCIAL_QA_LIST = `${AppRoutes.APP_SOCIAL_QA}/list`;

  /** /app/social/qa/:questionId */
  static readonly APP_SOCIAL_QA_VIEW = `${AppRoutes.APP_SOCIAL_QA}/:questionId`;

  /** /public/social */
  static readonly PUBLIC_PROFILES = `/public/profiles`;

  /** /public/profiles/:profileKey */
  static readonly PUBLIC_PROFILES_PROFILE_VIEW = `${AppRoutes.PUBLIC_PROFILES}/:profileKey`;

  /** /app/profiles */
  static readonly APP_PROFILES = `${AppRoutes.APP}/profiles`;

  /** /app/profiles/:profileKey */
  static readonly APP_PROFILE_VIEW = `${AppRoutes.APP_PROFILES}/:profileKey`;

  /** /app/profiles/:profileKey/ai-prediction */
  static readonly APP_AI_PREDICTION = `${AppRoutes.APP_PROFILES}/:profileKey/ai-prediction`;

  /** /app/profiles/crop/:cropId/:cropTitle */
  static readonly APP_CROP_FULLVIEW = `${AppRoutes.APP_PROFILE_VIEW}/crop/:cropId/:cropTitle`;

  /** /app/profiles/crop/:cropId/:cropTitle */
  static readonly APP_FPO_CROP_SEASONS = `${AppRoutes.APP_PROFILE_VIEW}/fpo-crop/:cropId/:cropTitle`;

  /** /app/profiles/crop/:cropId/:cropTitle/settings */
  static readonly APP_CROP_SETTINGS = `${AppRoutes.APP_PROFILE_VIEW}/crop/:cropId/:cropTitle/settings`;

  /** /app/profiles/:profileKey/base-product-list */
  static readonly APP_BASE_PRODUCT_LIST = `${AppRoutes.APP_PROFILE_VIEW}/base-product-list`;

  /** /app/profiles/:profileKey/live-stock-list */
  static readonly APP_LIVE_STOCK_LIST = `${AppRoutes.APP_PROFILE_VIEW}/live-stock-list`;

  /** /app/pages */
  static readonly APP_PROGRAMS = `${AppRoutes.APP}/pages`;

  /** /app/pages/programs/advisories */
  static readonly APP_PROGRAM_ADVISORIES = `${AppRoutes.APP_PROGRAMS}/programs/advisories`;

  /** /app/pages/programs/groups */
  static readonly APP_PROGRAM_GROUPS = `${AppRoutes.APP_PROGRAMS}/programs/groups`;

  /** /app/pages/new */
  static readonly APP_PROGRAM_NEW = `${AppRoutes.APP_PROGRAMS}/new`;

  /** /app/pages/:pageId */
  static readonly APP_PROGRAM_VIEW = `${AppRoutes.APP_PROGRAMS}/:pageId`;

  /** /app/pages/:pageId/stream */
  static readonly APP_PROGRAM_VIEW_STREAM = `${AppRoutes.APP_PROGRAM_VIEW}/stream`;

  /** /app/pages/:pageId/updates */
  static readonly APP_PROGRAM_VIEW_UPDATES = `${AppRoutes.APP_PROGRAM_VIEW}/updates`;

  /** /app/pages/:pageId/reports */
  static readonly APP_PROGRAM_VIEW_REPORT = `${AppRoutes.APP_PROGRAM_VIEW}/reports`;

  /** /app/pages/:pageId/questions */
  static readonly APP_PROGRAM_VIEW_QUESTIONS = `${AppRoutes.APP_PROGRAM_VIEW}/questions`;

  /** /app/pages/:pageId/surveys */
  static readonly APP_PROGRAM_VIEW_SURVEYS = `${AppRoutes.APP_PROGRAM_VIEW}/surveys`;

  /** /app/pages/:pageId/sms */
  static readonly APP_PROGRAM_VIEW_SMS = `${AppRoutes.APP_PROGRAM_VIEW}/sms`;

  /** /app/pages/:pageId/robocall */
  static readonly APP_PROGRAM_VIEW_ROBOCALL = `${AppRoutes.APP_PROGRAM_VIEW}/robocall`;

  /** /app/pages/:pageId/report-activity */
  static readonly APP_PROGRAM_VIEW_REPORT_ACTIVITY = `${AppRoutes.APP_PROGRAM_VIEW}/report-activity`;

  /** /app/programs/:pageId/assistance */
  static readonly APP_PROGRAM_VIEW_DIGITAL_ASSISTANCE = `${AppRoutes.APP_PROGRAM_VIEW}/assistance`;

  /** /app/programs/:pageId/ai-predictions */
  static readonly APP_PROGRAM_VIEW_AI_PREDICTIONS = `${AppRoutes.APP_PROGRAM_VIEW}/ai-predictions`;

  /** /app/ai predictions/fpo list */
  static readonly APP_PROGRAM_FPO_LIST = `${AppRoutes.APP_PROGRAM_VIEW_AI_PREDICTIONS}/fpo-list`;

  /** /app/programs/:pageId/projects */
  static readonly APP_PROGRAM_VIEW_PROJECTS = `${AppRoutes.APP_PROGRAM_VIEW}/projects`;

  /** /app/programs/:pageId/tasks */
  static readonly APP_PROGRAM_VIEW_TASKS = `${AppRoutes.APP_PROGRAM_VIEW}/tasks`;

  /** /app/programs/:pageId/training */
  static readonly APP_PROGRAM_VIEW_TRAINING = `${AppRoutes.APP_PROGRAM_VIEW}/training`;

  /** /app/programs/:pageId/tasks/:taskId */
  static readonly APP_PROGRAM_TASKS_VIEW = `${AppRoutes.APP_PROGRAM_VIEW_TASKS}/:taskId`;

  /** /app/programs/:pageId/tasks/:taskId/members */
  static readonly APP_PROGRAM_TASKS_VIEW_MEMBERS = `${AppRoutes.APP_PROGRAM_TASKS_VIEW}/members`;

  /** /app/programs/:pageId/tasks/:taskId/members/submissions */
  static readonly APP_PROGRAM_TASKS_VIEW_SUBMISSIONS = `${AppRoutes.APP_PROGRAM_TASKS_VIEW_MEMBERS}/submission`;

  /** /app/programs/:pageId/stocks */
  static readonly APP_PROGRAM_VIEW_STOCKS = `${AppRoutes.APP_PROGRAM_VIEW}/stocks`;

  /** /app/programs/:pageId/stocks */
  static readonly APP_PROGRAM_VIEW_PROCESSING = `${AppRoutes.APP_PROGRAM_VIEW}/processing`;

  /** /app/programs/:pageId/info */
  static readonly APP_PROGRAM_VIEW_INFO = `${AppRoutes.APP_PROGRAM_VIEW}/info`;

  /** /app/pages/:pageId/admins */
  static readonly APP_PROGRAM_VIEW_ADMINS = `${AppRoutes.APP_PROGRAM_VIEW}/admins`;

  /** /app/pages/:pageId/contributors */
  static readonly APP_PROGRAM_VIEW_CONTRIBUTORS = `${AppRoutes.APP_PROGRAM_VIEW}/contributors`;

  /** /app/pages/:pageId/members */
  static readonly APP_PROGRAM_VIEW_MEMBERS = `${AppRoutes.APP_PROGRAM_VIEW}/members`;

  /** /app/pages/:pageId/analytics */
  static readonly APP_PROGRAM_VIEW_ANALYTICS = `${AppRoutes.APP_PROGRAM_VIEW}/analytics`;

  /** /app/pages/:pageId/satellite-advisories */
  static readonly APP_PROGRAM_VIEW_SATELLITE_ADVISORIES = `${AppRoutes.APP_PROGRAM_VIEW}/satellite-advisories`;

  /** /app/pages/:pageId/satellite-advisories/all-users */
  static readonly APP_PROGRAM_VIEW_SATELLITE_ADVISORIES_ALL_USERS = `${AppRoutes.APP_PROGRAM_VIEW_SATELLITE_ADVISORIES}/:syncId`;

  /** /app/pages/:pageId/surveys */
  static readonly APP_PROGRAM_SURVEYS = `${AppRoutes.APP_PROGRAM_VIEW}/surveys`;

  /** /app/pages/:pageId/surveys/:surveyId */
  static readonly APP_PROGRAM_SURVEY_VIEW = `${AppRoutes.APP_PROGRAM_SURVEYS}/:surveyId`;

  /** /app/pages/:pageId/surveys/:surveyId/logs */
  static readonly APP_PROGRAM_SURVEY_LOGS = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/logs`;

  /** /app/pages/:pageId/surveys/:surveyId/analytics */
  static readonly APP_PROGRAM_SURVEY_ANALYTICS = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/analytics`;

  /** /app/pages/:pageId/surveys/:surveyId/questions */
  static readonly APP_PROGRAM_SURVEY_QUESTIONS = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/questions`;

  /** /app/pages/:pageId/surveys/:surveyId/info */
  static readonly APP_PROGRAM_SURVEY_INFO = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/info`;

  /** /app/pages/:pageId/surveys/:surveyId/fill */
  static readonly APP_PROGRAM_SURVEY_FILL = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/fill`;

  /** /app/pages/:pageId/surveys/:surveyId/admins */
  static readonly APP_PROGRAM_SURVEY_ADMINS = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/admins`;

  /** /app/pages/:pageId/surveys/:surveyId/members */
  static readonly APP_PROGRAM_SURVEY_MEMBERS = `${AppRoutes.APP_PROGRAM_SURVEY_VIEW}/members`;

  /** /app/projects */
  static readonly APP_PROJECTS = `${AppRoutes.APP}/projects`;

  /** /app/projects/dashboard */
  static readonly APP_PROJECTS_DASHBOARD = `${AppRoutes.APP_PROJECTS}/dashboard`;

  /** /app/projects/:projectId */
  static readonly APP_PROJECT_VIEW = `${AppRoutes.APP_PROJECTS}/:projectId`;

  /** /app/projects/:projectId/members */
  static readonly APP_PROJECTS_MEMBERS = `${AppRoutes.APP_PROJECT_VIEW}/members`;

  /** /app/search */
  static readonly APP_SEARCH = `${AppRoutes.APP}/search`;

  /** /app/search/users */
  static readonly APP_SEARCH_USERS = `${AppRoutes.APP_SEARCH}/users`;

  /** /app/search/products */
  static readonly APP_SEARCH_PRODUCTS = `${AppRoutes.APP_SEARCH}/products`;

  /** /app/tasks */
  static readonly APP_TASKS = `${AppRoutes.APP}/tasks`;

  /** /app/tasks/:taskId */
  static readonly APP_TASK_VIEW = `${AppRoutes.APP_PROJECT_VIEW}/:taskId`;

  /** /app/projects/:projectId/:taskId/members */
  static readonly APP_TASK_MEMBERS_DA = `${AppRoutes.APP_PROJECT_VIEW}/:taskId/members`;

  /** /app/projects/:projectId/:taskId/members/submission */
  static readonly APP_TASK_MEMBERS_SUBMISSION_DA = `APP_PROGRAM_VIEW_SMS${AppRoutes.APP_TASK_MEMBERS_DA}/submission`;

  /** /app/stocks */
  static readonly APP_STOCKS = `${AppRoutes.APP}/stocks`;

  /** /app/stocks/lots */
  static readonly APP_STOCKS_LOTS = `${AppRoutes.APP}/stocks/lots`;

  /** /app/stocks/processors */
  static readonly APP_STOCKS_PROCESSORS = `${AppRoutes.APP}/stocks/processors`;

  /** /app/store */
  static readonly APP_STORE = `${AppRoutes.APP}/store`;

  // --------------------------------------------------------
  // #region SURVEYS
  // --------------------------------------------------------

  /** /app/surveys */
  static readonly APP_SURVEYS = `${AppRoutes.APP}/surveys`;

  /** /app/surveys/:surveyId */
  static readonly APP_SURVEYS_VIEW = `${AppRoutes.APP_SURVEYS}/:surveyId`;

  /** /app/surveys/:surveyId/logs */
  static readonly APP_SURVEYS_VIEW_LOGS = `${AppRoutes.APP_SURVEYS_VIEW}/logs`;

  /** /app/surveys/:surveyId/questions */
  static readonly APP_SURVEYS_VIEW_QUESTIONS = `${AppRoutes.APP_SURVEYS_VIEW}/questions`;

  /** /app/surveys/:surveyId/analytics */
  static readonly APP_SURVEYS_VIEW_ANALYTICS = `${AppRoutes.APP_SURVEYS_VIEW}/analytics`;

  /** /app/surveys/:surveyId/fill */
  static readonly APP_SURVEYS_VIEW_FILL_SURVEY = `${AppRoutes.APP_SURVEYS_VIEW}/fill`;

  /** /app/surveys/:surveyId/info */
  static readonly APP_SURVEYS_VIEW_INFO = `${AppRoutes.APP_SURVEYS_VIEW}/info`;

  /** /app/surveys/:surveyId/admins */
  static readonly APP_SURVEYS_VIEW_ADMINS = `${AppRoutes.APP_SURVEYS_VIEW}/admins`;

  /** /app/surveys/:surveyId/members */
  static readonly APP_SURVEYS_VIEW_MEMBERS = `${AppRoutes.APP_SURVEYS_VIEW}/members`;

  /** /app/credits */
  static readonly APP_CREDITS = `${AppRoutes.APP}/credits`;

  /** /app/credits/details */
  static readonly APP_CREDITS_DETAILS = `${AppRoutes.APP_CREDITS}/details`;

  /** /app/credits/settings */
  static readonly APP_CREDITS_SETTINGS = `${AppRoutes.APP_CREDITS}/settings`;

  /** /app/credits/activity */
  static readonly APP_CREDITS_ACTIVITY = `${AppRoutes.APP_CREDITS}/activity`;

  /** /app/search */
  static readonly APP_WEATHER = `${AppRoutes.APP}/weather`;

  /** /app/network/views */
  static readonly APP_PROFILE_VIEWS = `${AppRoutes.APP}/network/views`;

  /** /public/social */
  static readonly PUBLIC = `/public/social`;

  /** /public/social/updates/:shareId */
  static readonly PUBLIC_SOCIAL_UPDATE = `${AppRoutes.PUBLIC}/updates/:shareId`;

  /** /public/social/qa/:questionId */
  static readonly PUBLIC_SOCIAL_QA = `${AppRoutes.PUBLIC}/qa/:questionId`;

  /** p/c/terror/3e4m_3e4s */
  static readonly STORE_PRODUCT_FULL_VIEW = `/p/c/:productTitle/:productIds`;

  /** /app/user-stocks/lots/:lotId */
  static readonly APP_STOCKS_LOT_FULLVIEW = `${AppRoutes.APP}/user-stocks/lots/:lotId`;

  /** /app/user-stocks/stock-board/:productId */
  static readonly APP_STOCK_BOARD_FULLVIEW = `${AppRoutes.APP}/user-stocks/stock-board/:productId`;

  /** /app/user-stocks/lots/create-lot */
  static readonly APP_STOCKS_CREATE_LOT = `${AppRoutes.APP}/user-stocks/lots/create-lot`;

  /** /app/user-stocks/lots */
  static readonly APP_STOCKS_LOT_PAGE = `${AppRoutes.APP}/user-stocks/lots`;

  /** /app/user-stocks/transactions/create-transaction */
  static readonly APP_STOCKS_CREATE_TRANSACTION = `${AppRoutes.APP}/user-stocks/transactions/create-transaction`;

  /** /app/user-stocks/transactions/:transactionId */
  static readonly APP_STOCKS_TRANSACTION_FULLVIEW = `${AppRoutes.APP}/user-stocks/transactions/:transactionId`;

  /** /app/accounts/transactions */
  static readonly APP_ACCOUNTS_TRANSACTIONS = `${AppRoutes.APP}/accounts/transactions`;

  /** /app/accounts/transactions/:transactionId */
  static readonly APP_ACCOUNTS_SALE_FULLVIEW = `${AppRoutes.APP}/accounts/transactions/:transactionId`;

  /** /app/accounts/transactions/:transactionId */
  static readonly APP_ACCOUNTS_PURCHASE_FULLVIEW = `${AppRoutes.APP}/accounts/transactions/:transactionId`;

  /** /app/user-stocks/transactions */
  static readonly APP_STOCKS_TRANSACTION_PAGE = `${AppRoutes.APP}/user-stocks/transactions`;

  /** /app/user-stocks/transactions/:transactionId */
  static readonly APP_STOCKS_TRANSACTION_PAYMENT_DETAILS = `${AppRoutes.APP_STOCKS_TRANSACTION_FULLVIEW}/payment-details`;

  /** /app/user-stocks/skus/skus-fullview */
  static readonly APP_STOCKS_SKUS_FULL_VIEW = `${AppRoutes.APP}/user-stocks/skus/:l2Id/:l3Id`;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
