<div class="post-tile-link-attachments px-2" fxLayout="column">

  <div class="main-wrapper position-relative">

    <div class="background-image" *ngIf="post?.uriImage && !embedLink"
      [style.background-image]="'url('+ (post?.uriImage) +')'">
    </div>

    <div class="background-image" *ngIf="image"
      [style.background-image]="'url('+ (image) +')'">
    </div>

    <!-- <img class="image mw-100 mh-100" *ngIf="post?.uriImage && !embedLink" [src]="post?.uriImage">
    <img class="image mw-100 mh-100 position-relative w-100" *ngIf="image" [src]="image"> -->

    <!-- Cancel link button-->
    <div class="position-absolute cancel-btn" *ngIf="image">
      <button type="button" class="text-white" title="Clear" color="warn" mat-icon-button fxLayoutAlign="center center" (click)="clearUrlDetails()">
        <mat-icon class="clear-share-url rounded-circle">clear</mat-icon>
      </button>
    </div>
    <!-- Cancel link button-->

    <div [innerHtml]="embedLink" class="w-100 video-player"></div>

    <!-- Details  -->
    <div *ngIf="!embedLink" class="details p-3 position-absolute w-100" [ngClass]="{'bottom': image}" fxLayout="column" fxLayoutAlign="space-around" (click)="$event.stopPropagation();">

      <div fxLayout="row" fxLayoutAlign="space-between center">
        <a [href]="post?.uri" target="_blank" *ngIf="post?.uri" class="header text-truncate pb-2">{{ post?.uriTitle }}</a>
        <a [href]="url" target="_blank" class="header text-truncate pb-2" *ngIf="title || domain">{{ title || domain }}</a>
      </div>

      <div class="uri-link" fxLayout="row" fxLayoutAlign="space-between center">
        <a [href]="post?.uri" *ngIf="post?.uri" target="_blank" class="mb-0 text-truncate link">{{ post?.uri }}</a>
        <h3 class="mb-0 text-truncate link text-white" *ngIf="domain">{{ domain }}</h3>
      </div>

    </div>
    <!-- Details  -->

  </div>

</div>
