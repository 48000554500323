import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, LatLong, ShareUpdate, SocialPostLike } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LikesListDialogComponent } from '../likes-list-dialog/likes-list-dialog.component';
import { LikesListMobileDialogComponent } from '../likes-list-mobile-dialog/likes-list-mobile-dialog.component';

@Component({
  selector: 'kl-share-update-action-bar',
  templateUrl: './share-update-action-bar.component.html',
  styleUrls: ['./share-update-action-bar.component.scss'],
})
export class ShareUpdateActionBarComponent extends KalgudiDestroyable implements OnInit, OnChanges {

  @Input()
  likes: SocialPostLike[];

  @Input()
  likesCount: number;

  @Input()
  commentsCount: number;

  @Input()
  shareCount: number;

  @Input()
  liked: boolean;

  @Input()
  shareId: string;

  @Input()
  url: string;

  @Input()
  geoLocation: LatLong;

  @Output()
  like = new EventEmitter<boolean>();

  @Output()
  commented = new EventEmitter<ShareUpdate>();

  @Output()
  fullView = new EventEmitter();

  login$: Observable<boolean>;

  isLoggedIn: boolean;
  likedPost: boolean;

  appId: string;
  loggedProfileKey: string;
  isShareOption: any;

  postLiked: boolean;
  likesCounts: number;

  isOverlayOpened = false;

  constructor(
    private kalgudiApp: KalgudiAppService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    protected kalgudiDialogs: KalgudiDialogsService,
  ) {
    super();
    this.appId = this.env.appId;

    this.isLoggedIn = this.kalgudiApp.loggedIn;

    this.kalgudiApp.profile$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.loggedProfileKey = res.profileKey;
        }
      )
  }

  ngOnInit() {
    this.likedPost = this.liked;
    this.likesCounts = this.postLiked ? this.likesCount - 1 : this.likesCount;
    this.isShareOption = this.env.appId === 'FPO_APP' || this.env.appId === 'SHAKTIMAN' || this.env.appId === 'KALGUDI_CORE' || this.env.appId === 'KUBER';
  }

  ngOnChanges() {
    if(this.likes && this.likes.length > 0) {
      this.likes.forEach(like => {
        if(like.id === this.loggedProfileKey) {
          this.postLiked = true;
        }
      })
    }
  }

  onDestroyed() : void {}

  /**
   * Emits an event to the parent
   */
  onLike(likePost: boolean) {
    this.like.emit();
    this.likedPost = likePost;
    this.postLiked = likePost;
  }

  /**
   * Event handler after comment is successful posted
   */
  onComment(): void {
    this.commented.emit();
  }

  /**
   * Event emit back to parent for share update full view
   */
  toFullview(): void {
    this.fullView.emit();
  }

  /**
   * To open the likes list dialog
   */
  openLikesListDialog() {
    // Users dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Likes list',
      data: {
        id: this.shareId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-input-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    // Show likes list dialog
    return this.showLikesListDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }

  /**
   * Show likes list dialog
   */
  private showLikesListDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.kalgudiDialogs.openMobileDialog(LikesListMobileDialogComponent, dialogConfig);
    } else {

      return this.kalgudiDialogs.openDialog(LikesListDialogComponent, dialogConfig, matDialogConfig);
    }
  }

  /**
   * Open posted location popup
   * @param event
   */
  openOverlay(event) {
    event.stopPropagation();
    this.isOverlayOpened = !this.isOverlayOpened;
  }

}
