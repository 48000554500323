<div id="kalgudi-input-dialog" class="kalgudi-core-theme kl-input-dialog overflow-hidden">
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button  (click)="cancel()"><mat-icon>clear</mat-icon></button>
  </div>
  <form autocomplete="off" class="dialog-content" [formGroup]="inputFormGroup" name="inputFormGroup">

    <div class="mb-1 post-info" *ngIf="data?.message">
      <p class="mb-0 input-msg" *ngIf="data?.message" translate>{{data?.message}}</p>
      <span class="mb-0 input-msg-data share-text overflow-hidden" [innerHTML]="(showMore) ? (data?.data?.text | linkify) : data?.data?.text | limitText : true: limit | linkify" translate>{{data?.data?.text}}</span>
      <span class="see-more" *ngIf="data?.data?.text.length > limit && !showMore"  (click)="show();$event.stopPropagation();">...See more</span>
    </div>
    <mat-form-field appearance="outline" *ngIf="data?.multiLineInput" class="w-100">
      <textarea  autofocus matInput [placeholder]="data?.inputPlaceHolder | translate"   formControlName="inputText"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="!data?.multiLineInput" class="w-100">
      <input  matInput cdkFocusInitial [placeholder]="data?.inputPlaceHolder | translate" formControlName="inputText">
    </mat-form-field>

    <div class="pt-0" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      <!-- <button mat-button color="primary" (click)="ok()">{{data?.acceptButtonTitle}}</button> -->
      <kl-link-button [label]="data?.acceptButtonTitle" [disabled]="!formInputText.value" (clicked)="ok()"></kl-link-button>
    </div>
  </form>

</div>
