import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SurveyStateService } from '@kalgudi/surveys';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiPageDetails, KalgudiSurveyDetails } from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProgramStateService } from '../../../../services/program-state.service';

@Component({
  selector: 'kl-page-survey-fill',
  templateUrl: './page-survey-fill.component.html',
  styleUrls: ['./page-survey-fill.component.scss']
})
export class PageSurveyFillComponent implements OnInit {

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  readonly pageSurvey$: Observable<{
    survey: KalgudiSurveyDetails,
    page: KalgudiPageDetails,
  }>;

  assistedToFormGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private surveyState: SurveyStateService,
    private pageState: ProgramStateService,
  ) {

    this.assistedToFormGroup = this.newAssistedToFormGroup;

    this.pageSurvey$ = combineLatest(
      this.surveyState.data$,
      this.pageState.data$
    )
    .pipe(
      map(([survey, page]) => ({survey, page}))
    );
  }

  ngOnInit() {
    this.assistedToFormGroup.valueChanges.subscribe(console.log);
  }


  get assistedToValue(): any {
    return this.assistedToFormGroup.value;
  }


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Creates a new assisted by form group instance
   */
  private get newAssistedToFormGroup(): FormGroup {

    return this.fb.group({
      assistedTo: [null]
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


}
