import { Component, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';

@Component({
  selector: 'core-route-loading-progress',
  template: `
  <section id="route-progress">
    <mat-progress-bar
        color="warn"
        mode="query">
    </mat-progress-bar>
  </section>
  `,
  styles: [
    `
    #route-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 99;
      height: 4px !important;
      overflow: hidden;
    }
    `
  ],
  animations: kalgudiAnimations,
})
export class RouteLoadingProgressComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
