import { KeyValue } from '@angular/common';
import { Injector, Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_PROFILE_PIC, KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import {
  KalgudiDialogResult,
  KalgudiPageMembersPickerDialogConfig,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import {
  KalgudiShareMembersPickerService,
} from '../../modules/share-member-picker/services/kalgudi-share-members-picker.service';


@Directive()
export abstract class ShareSpecificMembersVisibility extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  pageId = '';

  @Input()
  projectId = '';

  @Input()
  taskId = '';

  @Input()
  shareFormFilterUsers: FormControl;

  disabled = false;
  selectedProgramMembers = {};

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  readonly sharedVisibilities = SHARE_TYPES;

  private programDialogs: KalgudiShareMembersPickerService;

  constructor(
    protected injector: Injector,
  ) {
    super();

    this.programDialogs     = this.injector.get(KalgudiShareMembersPickerService);

  }

  onDestroyed(): void { }

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {

  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Opens dialog box to search and select program members
   */
  pickPageMembers(): void {

    this.openProgramMembersPicker()
      .pipe(
        takeUntil(this.destroyed$),

        // Take some actions if the dialog Okay button is clicked,
        // Neglecting cancel button click action
        filter(r => r.accepted),

        // tap(r => this.updateSelectedMembers(r.data))
      )
      .subscribe(r => {
        this.updateSelectedMembers(r.data);
        this.selectedProgramMembers = r.data;
      });
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
    return item.value.profileKey;
  }

  /**
   * Removes a selected user from the list of selected users. Removed
   * user won't get the shared update.
   */
  removeSelectedProgramMember(profileKey: string): void {

    // Get the current users map
    const selectedUsers = this.selectedProgramMembers;

    // Remove user from the list
    delete selectedUsers[profileKey];

    // Update the list
    this.updateSelectedMembers(selectedUsers);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Updates the current selected program members whom to send the update.
   */
  private updateSelectedMembers(users: KalgudiUsersMap): void {
    // this.selectedMembers.next(users);
    // this.selectedMembers = users;

    // Patch member profile keys to the form
    this.patchSelectedMembers(this.getSelectedUsersProfileKeys(users));
  }

  /**
   * Patch selected members value to the form
   */
  private patchSelectedMembers(members: string[]): void {

    // Remove all values
    this.shareFormFilterUsers.reset();

    // Form array accepts form group or form control
    // Patching values directly to form array wont work.
    this.shareFormFilterUsers.patchValue(members);
  }


  /**
   * Transforms users map to array of profile keys
   */
  private getSelectedUsersProfileKeys(users: KalgudiUsersMap): string[] {

    return Object.values(users)
      .map(r => r.profileKey);
  }

  /**
   * Opens program members picker dialog box.
   */
  private openProgramMembersPicker(): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiPageMembersPickerDialogConfig = {
      title: 'Search and select share members',
      acceptButtonTitle: 'Select',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
      pageId: this.pageId,
      projectId: this.projectId,
      taskId: this.taskId,
      data: this.selectedProgramMembers
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kalgudi-dialog',
      hasBackdrop: true,
      autoFocus: true,
      restoreFocus: true,
      disableClose: true
    };

    return this.programDialogs.showMembersPicker(dialogDetails, dialogConfig);

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
