import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'kl-task-tags-product',
  templateUrl: './task-tags-product.component.html',
  styleUrls: ['./task-tags-product.component.scss']
})
export class TaskTagsProductComponent extends FieldType implements OnInit {

  constructor() {

    super();
  }

  ngOnInit() {
  }

}
