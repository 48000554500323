<div id="upload-image-mobile" class="h-100">

  <div class="content-images">
    <kl-upload-image
      [accept]="acceptedFileTypes"
      [(attachments)]="attachments"
      [maxImages]="maxImages"
      [multiple]="multiple"
      [s3Category]="s3Category"
      [showCamera]="dialogData?.data?.showCamera"
      [showAddOnLast]="dialogData?.data?.showAddOnLast">
    </kl-upload-image>
  </div>

  <!-- Action buttons -->
  <div class="fixed-bottom p-3">
    <div class="pt-0 action-buttons w-100" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="20px">

      <div class="w-100">
        <kl-stroked-button id="upload-cancel-button" [label]="dialogData?.rejectButtonTitle" buttonColor="warn" (clicked)="cancel()">
        </kl-stroked-button>
      </div>

      <div  class="w-100">
        <kl-flat-button id="upload-submit-button" [label]="dialogData?.acceptButtonTitle" (clicked)="ok()"></kl-flat-button>
      </div>

    </div>
  </div>
  <!--/ Action buttons -->

</div>
