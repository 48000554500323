import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { StoreAdminModule } from '@kalgudi/ag-grid-lib';
import { StoreAdminAgGridModule } from '@kalgudi/ag-grid-lib/ag-grid';
import { StoreAdminCommonModule } from '@kalgudi/ag-grid-lib/common';
import {
  KalgudiBulkMembersAdditionModule,
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUserPickerModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { StoreType } from '@kalgudi/types';
import { TranslateModule } from '@ngx-translate/core';

import {
  AddProgramMembersDialogComponent,
} from './components/add-program-members-dialog/add-program-members-dialog.component';
import {
  AddProgramMembersMobileDialogComponent,
} from './components/add-program-members-mobile-dialog/add-program-members-mobile-dialog.component';
import { AddProgramMembersComponent } from './components/add-program-members/add-program-members.component';
import { PageMembersAgGridComponent } from './components/page-members-ag-grid/page-members-ag-grid.component';
import { PageMembersService } from './components/page-members-ag-grid/page-members.service';
import { ProgramAddBulkMembersComponent } from './components/program-add-bulk-members/program-add-bulk-members.component';
import { ProgramAddNewUserComponent } from './components/program-add-new-user/program-add-new-user.component';
import { ProgramSearchMembersComponent } from './components/program-search-members/program-search-members.component';
import { KalgudiPageMembersDialogService } from './services/kalgudi-page-members-dialog.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';



@NgModule({
  declarations: [
    AddProgramMembersDialogComponent,
    AddProgramMembersMobileDialogComponent,
    AddProgramMembersComponent,
    ProgramSearchMembersComponent,
    ProgramAddBulkMembersComponent,
    ProgramAddNewUserComponent,
    PageMembersAgGridComponent
  ],
  imports: [
    CommonModule,

    // MAterial modules
    MatTabsModule,
    MatRadioModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule,

    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    // kalgudi modules
    KalgudiDialogsModule,
    KalgudiButtonModule,
    KalgudiUserPickerModule,
    KalgudiStatusDialogModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiGooglePlacesModule,
    KalgudiNoContentModule,
    KalgudiImagePickerModule,
    KalgudiImageChipListModule,

    TranslateModule,

    // Store admin library
    StoreAdminModule.forRoot({
      storeType: StoreType.FARM_STORE
    }),
    StoreAdminAgGridModule,
    StoreAdminCommonModule,
  ],
  exports: [
    PageMembersAgGridComponent,
  ],
  entryComponents: [
    AddProgramMembersDialogComponent,
    AddProgramMembersMobileDialogComponent,
  ],
  providers: [
    KalgudiPageMembersDialogService,
    PageMembersService
  ]
})
export class KalgudiProgramMembersModule { }
