import { ElementRef, Inject, Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import {
  Attachment,
  AVAILABLE_STORES,
  KalgudiUserBasicDetails,
  StoreProductLevel2,
  StoreProductLevel3,
} from '@kalgudi/types';
import { finalize } from 'rxjs/operators';

import { TraceabilityService } from '../services/traceability.service';

@Directive()
export class Traceability {
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('aboutUsElement') aboutUsElement: ElementRef;
  @ViewChild('nutrientsElement') nutrientsElement: ElementRef;
  @ViewChild('ratingElement') ratingElement: ElementRef;

  @Input()
  productLevel2Details: StoreProductLevel2;

  @Input()
  level3ProductDetails: StoreProductLevel3;

  @Input()
  sellerDetails: KalgudiUserBasicDetails;

  @Input()
  orderId: string;

  @Input()
  userProfileKey: string;

  outputStoreId = AVAILABLE_STORES.OUTPUTS;

  supplierList: any[] = [];

  fulfillmentCenterList = {
    'https://tgreens.in': {

      firstName: 'Fulfillment center 1 - Hyderabad',
      location: 'Hyderabad, Telangana',
      lat: 17.718165,
      lng: 78.476217
    },
    'https://andhragreens.com': {

      firstName: 'Fulfillment center 1 - Vijayawada',
      location: 'Vijayawada, Krishna, Andhra Pradesh',
      lat: 16.5061740875244141,
      lng: 80.6480178833007812
    },
    'https://emahila.org': {

      firstName: 'Fulfillment center 1 - Vijayawada',
      location: 'Vijayawada, Krishna, Andhra Pradesh',
      lat: 16.5061740875244141,
      lng: 80.6480178833007812
    },

    'https://outputs.kalgudi.com': {

      firstName: 'Fulfillment center 1 - Vijayawada',
      location: 'Vijayawada, Krishna, Andhra Pradesh',
      lat: 16.5061740875244141,
      lng: 80.6480178833007812
    },
  }

  orderDetails: any;
  progress: boolean;
  sellerDetailsProgress: boolean;
  buyerDetails: any;
  sellerProfileInfo: KalgudiUserBasicDetails;

  orderDomain: string;

  trackingDetails: any;
  productionDetails:any;
  procurementDetails:any;
  supplierDetails:any;
  showSideNav: string;
  opened: any = false;

  productionAttachments: Attachment[] = [];
  procurementAttachments: Attachment[] = [];

  scrollReached: boolean;

  private traceabilityService: TraceabilityService;
  private util: KalgudiUtilityService;
  private appApi: KalgudiAppService;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) protected environment: KalgudiEnvironmentConfig,
  ) {

    this.traceabilityService = this.injector.get(TraceabilityService);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.appApi              = this.injector.get(KalgudiAppService);

    this.scrollReached = false;
  }

  /**
   * Calling api method to get order details
   * @param orderId
   */
  getOrderDetails(orderId: string) {
    this.progress = true;
    this.traceabilityService.getOrderFullView(orderId)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        orderDetails => {
          this.orderDetails = orderDetails;
          this.buyerDetails = this.orderDetails.address;
          this.orderDomain = this.orderDetails.domainUrl;

          if(this.environment.appId === this.outputStoreId) {
            this.trackingDetails = {
              tracking: orderDetails.tracking
            }
          }

          if(this.orderDetails.stockPageMaping) {

            let matchedLevel3Id;
            let pageId;
            let level2Id

            Object.keys(this.orderDetails.stockPageMaping).forEach(id => {
              if(id === this.level3ProductDetails.productLevel3Id) {
                matchedLevel3Id = id;
              } else {
                level2Id = id;
              }
            });

            pageId = matchedLevel3Id && this.orderDetails.stockPageMaping[matchedLevel3Id] ? this.orderDetails.stockPageMaping[matchedLevel3Id] : this.orderDetails.stockPageMaping[level2Id];

            this.getSupplierDetails(pageId);
          }
        },
        err => this.util.errorHandler('Unable to fetch order details, please try again later!')
      )
  }

  /**
   * Get traceability fulfillments details
   */
  getFulfillmentDetails() {
    this.traceabilityService.getFulfillmentDetails(this.orderId, this.level3ProductDetails.productLevel3Id)
      .subscribe (
        res => {
          this.trackingDetails = res;
        },
        err => this.util.showMessage('Unable to fetch fulfillment details')
      )
  }

  getSupplierList() {
    this.traceabilityService.getSupplierList(this.orderId)
      .subscribe(
        res => {
          this.supplierList = res;

        }
      )
  }

  /*
   * Get traceability production and procurement details
   */
  getTraceabilityDetails() {

    this.traceabilityService.getTraceability(this.orderId, this.level3ProductDetails.productLevel2Id, this.level3ProductDetails.productLevel3Id)

      .subscribe (
        res => {
          this.productionDetails = res.productions,
          this.procurementDetails = res.procurements

          this.productionDetails.forEach(async activity => {

            if (activity.shareTo.lstOfAttachments && activity.shareTo.lstOfAttachments.length) {
              activity.shareTo.lstOfAttachments.forEach(attachment => {
                if (attachment.msgType === 'IMAGE') {
                  this.productionAttachments.push(attachment);
                }
              });
            }
          });

          this.procurementDetails.forEach(async activity => {

            if (activity.shareTo.lstOfAttachments && activity.shareTo.lstOfAttachments.length) {
              activity.shareTo.lstOfAttachments.forEach(attachment => {
                if (attachment.msgType === 'IMAGE') {
                  this.procurementAttachments.push(attachment);
                }
              });
            }
          })

        }
      )
  }


  /**
   * Calls api to get supplier details
   */
  getSupplierDetails(pageId: string) {

    // this.progress = true;

    this.traceabilityService.getSupplierDetails(pageId)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        supplierDetails => {
          this.supplierDetails = supplierDetails;
        }
      )
  }


  /**
   * Calling api method to get the seller profile details
   */
  getSellerProfileInfo(profileKey: string) {
    this.sellerDetailsProgress  = true;
    this.appApi.fetchUserProfile(profileKey)
      .pipe(
        finalize(() => this.sellerDetailsProgress = false)
      )
      .subscribe(
        sellerProfileInfo => {

          this.sellerProfileInfo = sellerProfileInfo;

        }
      )
  }

  /**
   * On sidenav scroll
   * Changing ngb-rating visibility based on sidenav scroll position and ratings section position
   */
  onSidenavScroll = (event): void => {
    const elementPosition = this.ratingElement.nativeElement.offsetTop;

    const scrollPosition = document.getElementById('sidenav').scrollTop;

    // set `true` when scrolling has reached current element
    this.scrollReached = scrollPosition >= (elementPosition - 450) ? true : false;

    let i: number;
    const ratingStarElements = document.querySelectorAll("#ngb-rating-star");

    for(i = 0; i < ratingStarElements.length; i++ ) {
      (ratingStarElements[i] as HTMLElement).style.visibility = this.scrollReached ? 'visible' : 'hidden';
    }

  }

  /**
   * Open side nav
   */
  openSideNav(type: string) {
    this.showSideNav = type;

    this.sidenav.toggle();

    const appBody = document.getElementById('app-body')
    if (this.sidenav.opened && appBody) {

      appBody.style.overflowY = 'hidden';
    }

    const appHeaderMenu = document.getElementById('app-header-menu');
    const appHeaderMenuLocation = document.getElementById('app-header-location-wrapper');
    if (this.sidenav.opened && appHeaderMenu && appHeaderMenuLocation) {

      appHeaderMenu.style.zIndex = '1';
      appHeaderMenuLocation.style.zIndex = '1';
    }

    if (type === 'NUTRIENTS') {
      this.nutrientsElement.nativeElement.scrollIntoView();
    }

    if (type === 'ABOUT') {
      this.aboutUsElement.nativeElement.scrollIntoView();
    }

    if (type === 'REVIEWS_COMMENTS') {
      this.ratingElement.nativeElement.scrollIntoView();
    }
  }

  /**
   * Close sidenav
   */
  closeSideNav() {
    this.sidenav.close();

    const appBody = document.getElementById('app-body')
    if (!this.sidenav.opened && appBody) {

      appBody.style.overflowY = 'auto';
    }

    const appHeaderMenu = document.getElementById('app-header-menu');
    const appHeaderMenuLocation = document.getElementById('app-header-location-wrapper');
    if (!this.sidenav.opened && appHeaderMenu && appHeaderMenuLocation) {

      appHeaderMenu.style.zIndex = '999';
      appHeaderMenuLocation.style.zIndex = '100';
    }

  }

}
