import { Injectable } from '@angular/core';
import { KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiShareWithApiService } from './kalgudi-share-with-api.service';


@Injectable()
export class KalgudiShareWithService {

  constructor(
    private api: KalgudiShareWithApiService,
  ) {
  }

  /**
   * Calls Kalgudi api to get entity details
   *
   * @param pageId Unique id of entity
   */
  getPage(pageId: string): Observable<KalgudiPageDetails> {
    return this.api.getPageId(pageId)
  }

}
