import { Inject, Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserTaxDetails } from '@kalgudi/types';
import { Observable, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { KalgudiProfileSectionList } from '../../../classes/kalgudi-profile-section-list';
import { KalgudiProfileTaxesService } from './kalgudi-profile-taxes.service';

@Directive()
export abstract class ProfileSectionTax extends KalgudiProfileSectionList<KalgudiUserTaxDetails> {

  protected itemForm: FormGroup;
  protected existingItemFormGroup: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileTaxesService,
  ) {

    super(notification, app, util);

    // Initialize item form
    this.itemForm = this.formGroup;
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Profile memberships form group structure.
   *
   * **NOTE** Must define all fields of the profile list section type,
   * whether its shown in the view or not.
   */
  private get formGroup(): FormGroup {

    return new FormGroup({
      Flag: new FormControl(true),
      businessTaxId: new FormControl(''),
      fieldName: new FormControl(''),
      fieldValue: new FormControl('', Validators.required),
      field: new FormControl('')
    });
  }

  // Get the field details
  get taxField(): AbstractControl {
    return this.itemForm.get('field');
  }

  // Get the fieldValue
  get taxFieldValue(): AbstractControl {
    return this.itemForm.get('fieldValue');
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Implement the logic to check if the specified item in the profile section
   * list is new or existing item. An item in the list is new if it does not
   * contain any id otherwise its existing item.
   */
  protected isNewItem(item: KalgudiUserTaxDetails): boolean {

    return true;
    // return this.util.isNullOrEmpty(item.fieldName);
  }

  /**
   * Defines save new item Api.
   */
  protected saveNewItemApi(payload: KalgudiUserTaxDetails): Observable<KalgudiUserTaxDetails> {

    return this.api.saveNewTax(payload)
      .pipe(
        mapTo(payload),
      );
  }

  /**
   * Defines update membership Api.
   */
  protected updateItemApi(payload: KalgudiUserTaxDetails): Observable<KalgudiUserTaxDetails> {
    return this.api.updateTax(payload)
      .pipe(
        mapTo(payload),
      );

  }

  /**
   * Deletes an item from the profile section items list.
   *
   * @param item Item to delete
   */
  protected deleteItemApi(item: KalgudiUserTaxDetails): Observable<boolean> {

    return of();
    // return this.api.deleteTax(item.fieldId);
  }

  protected preparePayload(formValue: FormGroup): any {

    return {
      Flag: false,
      field: this.taxField.value,
      fieldId: this.taxField.value.fieldId,
      fieldName: this.taxField.value.fieldName,
      fieldValue: this.taxFieldValue,
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
