import { Injectable } from '@angular/core';
import { KalgudiUserBasicSearchResults } from '@kalgudi/core';
import { Observable } from 'rxjs';

import { KalgudiShareSearchMembersApiService } from './kalgudi-share-search-members-api.service';

@Injectable()
export class KalgudiShareSearchMembersService {

  constructor(
    private api: KalgudiShareSearchMembersApiService,
  ) { }

  /**
   * Searches for a member in a Share.
   */
  searchMembers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams
  ): Observable<KalgudiUserBasicSearchResults> {

    return this.api.searchEntityMembers(searchKeyword, offset, limit, extraParams);
  }
}
