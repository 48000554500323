import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { KalgudiLazyLoaderModule } from '@kalgudi/third-party/lazy-loader';

import { KalgudiPhotoswipeAssetsConfig, KL_PS_ASSETS } from './config';
import { ImagePreviewComponent } from './image-preview/image-preview.component';
import { KalgudiPhotoswipeComponent } from './kalgudi-photoswipe.component';
import { KalgudiPhotoswipeService } from './kalgudi-photoswipe.service';
import { PhotoswipeAngularWrapperService } from './photoswipe-angular-wrapper.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';


@NgModule({
  declarations: [
    KalgudiPhotoswipeComponent,
    ImagePreviewComponent
  ],
  imports: [
    CommonModule,

    // Lazy load photoswipe local assets
    KalgudiLazyLoaderModule,
  ],
  exports: [
    KalgudiPhotoswipeComponent
  ]
})
export class KalgudiPhotoswipeModule {

  static forRoot(config: KalgudiPhotoswipeAssetsConfig): ModuleWithProviders<KalgudiPhotoswipeModule> {

    return {
      ngModule: KalgudiPhotoswipeModule,
      providers: [
        KalgudiPhotoswipeService,
        PhotoswipeAngularWrapperService,
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true }},
        {
          provide: KL_PS_ASSETS,
          useValue: config,
        }
      ]
    };
  }
}
