<section id="profile-header" fxLayout="row" fxLayoutAlign="center center" class="mt-2">
  <!-- Profile -->
  <mat-card class="tile mb-2 w-100" fxLayout="column">
    <div class="profile-card" fxLayout="row" fxLayoutAlign.xs="center">
      <!-- Wrapper for Image -->
      <div class="w-100" fxLayout.xs="column" fxLayoutAlign="start center" fxLayoutGap="20px">
        <div fxLayoutAlign.xs="center center">
          <div class="picture position-relative px-2">
            <div class="image">
              <img [src]="(user?.profilePicUrl)"
              [klErrorImages]="[
                  (user?.profilePicUrl),
                  domain + defaultProfilePic
                ]"
                [alt]="user?.firstName">
            </div>
            <div *ngIf="editable">
              <span class="edit" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon>camera_alt</mat-icon>
              </span>
              <input class="input-file" type="file" [title]="'Change profile picture' | translate" accept=".png, .jpg, .jpeg" (change)="uploadProfilePic($event)">
            </div>
          </div>
        </div>
        <!-- / Wrapper for Image -->


        <!-- Wrapper for User Details -->
        <div class="profile-info mw-100" >
          <div>

            <h1 class="name mb-2 mt-0" *ngIf="!showNumber">{{ user?.firstName | titlecase}}</h1>
            <h1 class="name mb-2 mt-0" *ngIf="showNumber">{{ editable ? "Add name" : "No name" }}</h1>

            <p class="profile-biz text-truncate">{{user?.additionalBizDetails?.businessTypeName}}</p>
            <p class="location-info mb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName !== 'Location yet to be set'">
              <span>{{user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}}, </span>
              <span>{{user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong}}</span>
            </p>

            <div class="biz-name pt-1" fxLayout="column">
              <span>{{ editable ? user?.additionalBizDetails?.profileTypeDetails?.entityName : (user?.additionalBizDetails?.profileTypeDetails?.entityName | secureMobile) }}
              </span>
            </div>
            <span class="connects" translate>{{ user?.connectsCount | number }} Connections</span>
          </div>

          <div class="edit-section" fxLayout *ngIf="isDigitalAssist">
            <p class="mb-1">
              <span translate>Login Id:</span>  {{ loginId }}
            </p>
            <button mat-icon-button class="edit-btn ml-2 text-white" [matTooltip]="'Update' | translate" (click)="showEditLoginIdDialog()">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- / Wrapper for User Details -->

    <div fxLayout fxLayoutAlign.xs="center center" fxLayoutAlign="space-around center" id="about-profile-tab" class="mt-2">
      <div *ngFor="let tab of tabsList; let first = first;let last = last" fxFlex="33.33%">
        <button class="font-weight-bold w-100 border-0 bg-white actions-btn p-0" fxLayout="row" [ngClass]="{'tab-selected text-secondary': selectedTabId === tab?.id}" (click)="toggleTab(tab?.id)" translate>
          <span *ngIf="!first" fxLayoutAlign="center center" class=" text-muted separator">|</span>
          <span class="w-100" fxLayoutAlign="center center"> {{ tab?.title | translate }}</span>
        </button>
      </div>
    </div>
  </mat-card>
  <!-- / Profile -->

</section>
