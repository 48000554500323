import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { transformMassArea } from '../mass-area-unit-conversion.pipe';
import { SatelliteAdvisoriesService } from '../satellite-advisories.service';

@Component({
  selector: 'kl-phosphorus-details',
  templateUrl: './phosphorus-details.component.html',
  styleUrls: ['./phosphorus-details.component.scss']
})
export class PhosphorusDetailsComponent implements OnInit, OnChanges {

  @ViewChild('satImg', { static: true })
  satImgRef: ElementRef;

  @Input()
  estimatedTime: string;

  @Input()
  phosphorusImage: string;

  @Input()
  phosphorusDetails: any;

  @Input()
  landDetails: any;

  @Input()
  bbox: number[] = [];

  boundaryPoints: string = '';

  showMap: boolean = false;

  phosphorusPerHectare: number;
  phosphorusPerAcre: number;
  phosphorusPerFeddan: number;

  status: 'low' | 'good' | 'more';

  constructor(private satelliteAdvisoriesService: SatelliteAdvisoriesService) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.landDetails && this.bbox && !this.boundaryPoints) {
      this.boundaryPoints = this.satelliteAdvisoriesService.convertLatLongToPoints(
        this.landDetails.geoFences,
        this.satImgRef.nativeElement.offsetWidth,
        this.satImgRef.nativeElement.offsetHeight,
        this.bbox
      );
    }

    if (changes.phosphorusDetails && this.phosphorusDetails) {
      this.calculateStatus();
    }
  }

  showLandMap() {
    this.showMap = !this.showMap;
  }

  private calculateStatus() {
    this.phosphorusPerHectare = this.phosphorusDetails.averageLevel;
    this.phosphorusPerAcre = this.phosphorusPerHectare * 2.47105;
    this.phosphorusPerFeddan = transformMassArea(this.phosphorusPerHectare, 'feddan'); // this.phosphorusPerAcre * 1.03;

    // console.log(this.phosphorusDetails, this.phosphorusPerHectare, this.phosphorusPerAcre, this.phosphorusPerFeddan);

    if (this.phosphorusPerFeddan < 10) {
      this.status = 'low';
    } else if (this.phosphorusPerFeddan > 20) {
      this.status = 'more';
    } else {
      this.status = 'good';
    }
  }
}
