import { EventEmitter, Injector, Input, OnChanges, Output, ViewChild, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiGeoLocationMarkerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';
import {
  GeoFenceDetails,
  IdValueMap,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiFarmerLand,
  KalgudiLocation,
  KalgudiProject,
  KalgudiUser,
  LatLong,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileRouteConfig } from '../../../config';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { AddCropDialogComponent } from '../../farmer-profile/components/add-crop-dialog/add-crop-dialog.component';
import {
  AddLandDetailsDialogComponent,
} from '../../farmer-profile/components/add-land-details-dialog/add-land-details-dialog.component';
import {
  AddLandDetailsMobileDialogComponent,
} from '../../farmer-profile/components/add-land-details-mobile-dialog/add-land-details-mobile-dialog.component';
import {
  GeotagConfirmDialogComponent,
} from '../../farmer-profile/components/geotag-confirm-dialog/geotag-confirm-dialog.component';
import { SIZE_IN_LIST, SOIL_TYPES, WATER_LEVEL_UNITS } from '../../farmer-profile/constants';
import { KalgudiFarmerProfileService } from '../../farmer-profile/services/kalgudi-farmer-profile.service';


@Directive()
export abstract class FpoAddCropSeasons extends KalgudiDestroyable implements OnChanges {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  @Input()
  cropId: string;

  @Input()
  pageId: string;

  @Input()
  profileKey: string;

  @Input()
  isAssisted: boolean;

  @Input()
  selectedSeason: any;

  @Input()
  isEditSeason: boolean;

  @Input()
  required = false;

  @Output()
  seasonAdded = new EventEmitter();

  activeTabIndex: number;

  readonly TABS = {
    FARM_BOUNDARY: { index: 0, title: 'Farm boundary' },
    FARM_LOCATION: { index: 1, title: 'Farm location' },
  };

  landDetails = new FormControl('');

  readonly soilTypes: IdValueMap[] = SOIL_TYPES;

  readonly sizeInList: IdValueMap[] = SIZE_IN_LIST;

  readonly waterLevelUnitList: IdValueMap[] = WATER_LEVEL_UNITS;

  farmingActivityAddSeasonForm: FormGroup;
  profile: KalgudiUser;
  editable: boolean;

  varietyList: any[];
  varietyProgress: boolean;
  selectedVariety: any;
  cropDetails: any;
  progress: boolean;
  selectedLandDetails: any;
  previousLandSizeValue: any;
  previousLandSizeUnit: any;

  params: PartialData = {
    pageId: '',
    assistedTo: ''
  };

  private fb: FormBuilder;
  private appService: KalgudiAppService;
  private profileState: KalgudiProfileStateService;
  private geoLocationMarkerService: KalgudiGeoLocationMarkerService;
  private profileService: KalgudiProfileService;
  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;
  private notification: KalgudiNotification;
  private util: KalgudiUtilityService;
  private appRouting: KalgudiProfileRouteConfig

  projectsList: KalgudiProject[];

  constructor(
    protected injector: Injector,
    protected dialogsService: KalgudiDialogsService
  ) {

    super();

    this.fb                          = this.injector.get(FormBuilder);
    this.appService                  = this.injector.get(KalgudiAppService);
    this.profileState                = this.injector.get(KalgudiProfileStateService);
    this.geoLocationMarkerService    = this.injector.get(KalgudiGeoLocationMarkerService);
    this.profileService              = this.injector.get(KalgudiProfileService);
    this.notification                = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.util                        = this.injector.get(KalgudiUtilityService);
    this.kalgudiFarmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.appRouting                  = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);

    this.farmingActivityAddSeasonForm = this.newFarmingActivityAddSeasonForm;


    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        profile => {
          this.profile = profile;
        }
      );

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    this.subscribeToValueChanges();

  }

  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------

  /**
   * get crop
   */
  get crop(): AbstractControl {
    return this.farmingActivityAddSeasonForm.get('crop');
  }

  /**
   * get variety
   */
  get variety(): AbstractControl {
    return this.farmingActivityAddSeasonForm.get('variety');
  }

  /**
   * get land
   */
  get land(): AbstractControl {
    return this.farmingActivityAddSeasonForm.get('land');
  }

  /**
   * Getter for location field
   */
  get locationField(): AbstractControl {

    return this.land.get('location');
  }

  /**
   * Getter for geo fences field
   */
  get geoFences(): AbstractControl {
    return this.land.get('geoFences');
  }

  /**
   * Getter for geo fences field
   */
  get soilTypeField(): AbstractControl {
    return this.land.get('soilType');
  }

  /**
   * Getter for land name field
   */
  get landName(): AbstractControl {
    return this.land.get('landName');
  }

  /**
   * Getter for water level group
   */
  get waterLevel(): AbstractControl {
    return this.land.get('waterLevel');
  }

  /**
   * Getter for expected yield group
   */
  get expectedYield(): AbstractControl {
    return this.farmingActivityAddSeasonForm.get('expectedYield');
  }

  /**
   * Getter for actual yield group
   */
  get actualYield(): AbstractControl {
    return this.farmingActivityAddSeasonForm.get('actualYield');
  }

  /**
   * Getter for water level unit field
   */
  get waterLevelUnit(): AbstractControl {
    return this.waterLevel.get('unit');
  }

  /**
   * Getter for water level value field
   */
  get waterLevelValue(): AbstractControl {
    return this.waterLevel.get('value');
  }

  /**
   * Getter for land size group
   */
  get landSize(): AbstractControl {
    return this.land.get('landSize');
  }

  /**
   * Getter for land size unit field
   */
  get landSizeUnit(): AbstractControl {
    return this.landSize.get('unit');
  }

  /**
   * Getter for land size value field
   */
  get landSizeValue(): AbstractControl {
    return this.landSize.get('value');
  }

  /**
   * Form group for farm activity form
   */
  private get newFarmingActivityAddSeasonForm(): FormGroup {
    const loggedInUserLocation = this.appService.loggedIn ? this.appService.profileLocal.lstOfUserBusinessDetailsInfo[0].locationTo : {};

    return this.fb.group({
      title: [''],
      crop: [''],
      variety: [''],
      pageId: [''],
      seasonTimeFrame: this.fb.group({
        from: [''],
        to: [''],
      }),
      land: this.fb.group({
        landName: [''],
        landId: [''],
        location: [loggedInUserLocation, Validators.required],
        soilType: [''],
        landSize: this.fb.group({
          unit: ['acre', Validators.required],
          value: [0, Validators.required]
        }),
        waterLevel: this.fb.group({
          value: [0],
          unit: ['']
        }),
        irrigationType: [''],
        salinity: [''],
        salinityLevel: [''],
        geoFences: [],
        attachments: [''],
        soilHealthCard: [[]]
      }),
      acres: ['', Validators.pattern("^[0-9]*$")],
      farmers: ['', Validators.pattern("^[0-9]*$")],
      expectedYield: this.fb.group({
        value: ['', Validators.pattern("^[0-9]*$")],
        unit: ['']
      }),
      actualYield: this.fb.group({
        value: [0, Validators.pattern("^[0-9]*$")],
        unit: ['']
      }),
      harvestingMonth: [''],
      cropCalendarId: [''],
      cropCalendarTitle: ['']
    });
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  ngOnChanges() {

    if (this.cropId) {
      this.getProductVarieties(this.cropId);
    }

    this.varietyList.forEach(v => v.selected = false);

    if (this.selectedSeason && this.isEditSeason) {
      this.farmingActivityAddSeasonForm.patchValue(this.selectedSeason);

      if(this.selectedSeason.variety && this.selectedSeason.variety.productId) {

        this.varietyList.forEach(v => {
          v.selected = (v.productId === this.selectedSeason.variety.productId);

          this.selectedVariety = v;
        });
      }


    }

    if (this.isAssisted) {

      this.params.pageId = this.pageId;
      this.params.assistedTo = this.profileKey;
    }
  }

  /**
   * Calculates area automatically by using geofencing
   */
  calculateArea() {

    const geoFences = this.geoFences.value;

    if (!geoFences.length) {
      return;
    }

    this.convertAndUpdateArea(this.geoMarker.calculateAreaOfSphericalPolygonInAcres(geoFences));
  }

  /**
   * Grouping varieties of cropId from profileDealsWith list
   */
  getProductVarieties(cropId: string): void {

    this.varietyList = [];

    if (this.profile.dealsWith && this.profile.dealsWith.length && cropId) {

      this.profile.dealsWith.forEach(p => {
        if(cropId === p.baseProductId) {

          this.cropDetails = {
            productId: p.baseProductId,
            productName: p.baseProductName,
            baseCategory: p.baseCategory,
            attachments: p.baseProductAttachments,
            storeType: p.storeType
          }

          this.varietyList.push(p);
        }
      })
    }

  }

  /**
   * On crop selection
   */
  onCropSelection(variety: any, index: number): void {

    this.varietyList.forEach((v, i) => {
      if (v.selected && i !== index) {
        v.selected = false;
      }
    })

    variety.selected = !variety.selected;

    this.selectedVariety = variety.selected ? variety : {};

  }

  /**
   * Navigates to projects page
   */
  toProjectList() {
    this.appRouting.toProjectList( null  , {type: 'GRID'});
  }

  /**
   * Calls api method to add season
   */
  addSeason() {
    this.progress = true;

    const payload = this.createPayload(this.farmingActivityAddSeasonForm.value);

    if(payload.cropCalendarId !== '') {

      const projectDetails = payload.cropCalendarId;
      payload.cropCalendarId = projectDetails.id;
      payload.cropCalendarTitle = projectDetails.title;
    }

    // const params = this.params.pageId && this.params.assistedTo ? this.params : {}

    this.kalgudiFarmerProfileService.addFarmingSeason(payload)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.seasonAdded.emit();
        },
        err => this.notification.showMessage('Unable to add season, please try again later!')
      )
  }

  /**
   * On changing geo tag
   */
  geoTagChanged(latLong: LatLong): void {

    const location: KalgudiLocation = {
      countryId: '',
      countryName: '',
      countryShortName: '',
      districtId: '',
      districtName: '',
      locality: '',
      locationLong: '',
      locationShort: '',
      location_category_id: '',
      openerpCountryId: '',
      placeId: '',
      placeName: '',
      postalCode: '',
      regionId: '',
      regionName: '',
      stateId: '',
      stateName: '',

      ...latLong,
    };

    this.locationField.patchValue(location);
    this.geoFences.patchValue([]);

    // console.log(this.locationField.value);
  }

  /**
   * Shows confirm dialog
   */
  displayGeotagDialog(tab) {
    if (tab.index === 1) {
      this.showConfirmDialog()
        .subscribe(res => {
          this.activeTabIndex = res.accepted ? 1 : 0;
        },
        err => this.activeTabIndex = 0);
    }
  }

  /**
   * Opens gps geo marker dialog
   */
  openGpsGeoMarker(): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Fence your field',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {},
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      panelClass: 'kl-gps-geo-fence-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.geoLocationMarkerService.openGpsGeoLocationMarkerDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(data => data.accepted),

        map(data => data.data as GeoFenceDetails)
      )
      .subscribe(data => {
        this.geoFences.patchValue(data.coords);

        // this.landSize.patchValue({
        //   unit: 'acre',
        //   value: data.areaInAcres,
        // });

        // console.log(data);
      });
  }

  /**
   * Shows add land details dialog
   */
  showAddLandDetailsDialog(landDetails: KalgudiFarmerLand = null) {

    if (!this.editable) {
      return;
    }

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: landDetails && landDetails.landId ? 'Update land details' : 'Add land details',
      acceptButtonTitle: 'Add land details',
      rejectButtonTitle: 'Cancel',
      data: {
        landDetails,
        isAssisted: this.isAssisted
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAddLandDetailsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      );
  }

  /**
   * Displays confirmation dialog
   */
  showConfirmDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirmation',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openConfirmDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        // filter(r => r && r.accepted),

        // map(r => r.accepted)
      );
  }

  /**
   * Displays confirmation dialog
   */
  showConfirmationDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm',
      acceptButtonTitle: 'Ok',
      matIcon: 'warning',
      iconColor: 'warn',
      message: 'It seems you are using more/less land than ' + this.selectedLandDetails.landSize.value + this.selectedLandDetails.landSize.unit + '. Please mark your fence again for better advisory.',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe();
  }

  editCrop() {

    this.showAddProductsDialog(this.varietyList);
  }

  /**
   * Shows add products dialog
   */
  showAddProductsDialog(product: any = null): void {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add crop',
      acceptButtonTitle: 'Add crop',
      rejectButtonTitle: 'Cancel',
      data: {
        showVariety: false,
        product: product ? product : null,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-add-crop-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openAddProductsDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        filter(r => r.accepted),
      )

  }

  /**
   * Fetches projects list
   */
  getProjectsList() {
    this.profileService.getProjectsList()
      .subscribe(res => {
        this.projectsList = res;
      })
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Converts an area to current selected unit and updates the area to the area field.
   */
  protected convertAndUpdateArea(areaInAcres: number) {

    const landSizeUnit: 'acre' |'hectare' |'feddan' = this.landSizeUnit.value;

    let area = areaInAcres;

    if (landSizeUnit === 'hectare') {
      area = areaInAcres * 0.404686;
    } else  if(landSizeUnit === 'feddan') {
      area = areaInAcres * 1.03;
    }

    this.landSizeValue.patchValue(area.toFixed(2));
  }


  /**
   * Prepare payload for add season
   */
  private createPayload(value): any {

    let payload = this.util.clone(value);

    if (this.selectedSeason && this.selectedSeason.id && this.isEditSeason) {
      payload.id = this.selectedSeason.id;
    }

    payload.pageId = this.pageId;

    payload.crop = {
      productId: this.selectedVariety && this.selectedVariety.baseProductId ? this.selectedVariety.baseProductId : this.cropDetails.productId,
      productName: this.selectedVariety && this.selectedVariety.baseProductName ? this.selectedVariety.baseProductName : this.cropDetails.productName,
      baseCategory: this.selectedVariety && this.selectedVariety.baseCategory ? this.selectedVariety.baseCategory : this.cropDetails.baseCategory,
      attachments: this.selectedVariety && this.selectedVariety.baseProductAttachments ? this.selectedVariety.baseProductAttachments : this.cropDetails.attachments,
      storeType: this.selectedVariety && this.selectedVariety.storeType ? this.selectedVariety.storeType : this.cropDetails.storeType,
    }

    payload.variety = {
      productId: this.selectedVariety && this.selectedVariety.productId ? this.selectedVariety.productId : '',
      productName: this.selectedVariety && this.selectedVariety.productName ? this.selectedVariety.productName : '',
      baseCategory: this.selectedVariety && this.selectedVariety.baseCategory ? this.selectedVariety.baseCategory : {},
      attachments: this.selectedVariety && this.selectedVariety.attachments ? this.selectedVariety.attachments : [{type: '', attachments: []}],
      storeType: this.selectedVariety && this.selectedVariety.storeType ? this.selectedVariety.storeType : '',
    }

    payload.land.landId = this.landDetails.value.landId;

    return payload;
  }

  /**
   * Shows the add products web or mobile dialog
   */
  private openAddProductsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(AddCropDialogComponent, dialogConfig, matDialogConfig);
  }

  /**
   * Shows the confirm web or mobile dialog
   */
  private openConfirmDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(GeotagConfirmDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Shows the add land details web or mobile dialog
   */
  private openAddLandDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(AddLandDetailsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(AddLandDetailsDialogComponent, dialogConfig, matDialogConfig);
    }

  }


  /**
   * Subscribing to the land details form control
   */
  private subscribeToValueChanges() {

    this.landDetails.valueChanges
      .pipe (
        takeUntil(this.destroyed$),
      )
      .subscribe(land => {
        this.selectedLandDetails = land;
        this.land.patchValue(land);
        // this.soilTypeField.disable();
      });


    this.landSizeValue.valueChanges
      .pipe (
        takeUntil(this.destroyed$),
      )
      .subscribe(value => {
        if (this.selectedLandDetails && this.selectedLandDetails.landSize.value !== value && value && (value !== this.previousLandSizeValue)) {

          this.showConfirmationDialog()
            .pipe(
              takeUntil(this.destroyed$)
            )
            .subscribe(
            );
        }

        if (value) {
          this.previousLandSizeValue = value;
        }
      });

    this.landSizeUnit.valueChanges
      .pipe (
        takeUntil(this.destroyed$),
      )
      .subscribe(unit => {
        if (this.selectedLandDetails && this.selectedLandDetails.landSize.unit !== unit && unit && (unit !== this.previousLandSizeUnit)) {

          this.showConfirmationDialog()
            .pipe(
              takeUntil(this.destroyed$)
            )
            .subscribe(
            );
        }

        if (unit) {
          this.previousLandSizeUnit = unit;
        }
      });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
