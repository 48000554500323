<section class="promotional-recommended-products">
  <div class="my-2">
    <span class="products">Recommended Product</span>
  </div>
  <div *ngFor="let product of productList" class="mb-2">

    <div fxLayout=row fxLayoutGap="15px">
      <div class="product-img" fxLayoutAlign="center center">
        <img *ngIf="product?.productPicURL_level3" [klErrorImages]="[
          (product?.productPicURL_level3) | prefixDomain,
          domain + defaultProfilePic]"
          [src]="product?.productPicURL_level3" class="w-100 h-100">
      </div>

      <div fxLayout="column" fxLayoutGap="8px" fxFlex.gt-xs="calc(80% - 10px)" fxFlex.xs="calc(65% - 10px)">

        <div fxLayout="column" *ngIf="product?.productName_level3">
          <span class="product-name overflow-hidden">{{product?.productName_level3}}</span>
          <span class="store-name" *ngIf="product?.sellerName">
            Sold by: {{product?.sellerName}}
          </span>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="5px">
          <div fxLayout="column" class="quantity-cost">
            <span class="mb-0" *ngIf="product?.mrp" fxLayout="column">
              <span class="price-tag">Price</span>
              <span class="cost mr-2" *ngIf="product?.mrp">₹{{product?.mrp}}</span>
              <!-- <span class="text-muted actual-cost" *ngIf="product?.mrp"><del>₹{{product?.mrp}}</del></span> -->
            </span>

            <span class="quantity text-muted" *ngIf="product?.smartElements?.weight?.value && product?.smartElements?.weight?.unit">
              <span>{{product?.smartElements?.weight?.value}}</span>
              <span> {{product?.smartElements?.weight?.unit}}</span>
            </span>

            <span class="quantity text-muted" *ngIf="product?.smartElements?.dimensions?.width?.value || product?.smartElements?.dimensions?.length?.value">
              <span *ngIf="product?.smartElements?.dimensions?.width?.value">{{product?.smartElements?.dimensions?.width?.value}}</span>(W) x
              <span *ngIf="product?.smartElements?.dimensions?.length?.value">{{product?.smartElements?.dimensions?.length?.value}}</span>(L) in
              <span>{{ product?.smartElements?.dimensions?.unit}}</span>
            </span>

            <div *ngIf="!product?.mrp" fxLayout="column" class="enquiry-btn">
              <span class="price-tag">Price</span>
              <button mat-button disabled>
                <span class="text-white">Send Enquiry</span>
              </button>
            </div>
          </div>

          <div class="view-product-btn" fxLayoutAlign="end end">
            <button mat-button (click)="productFullView(product?.stores && product?.stores.length ? product?.stores[0] : ' ', product);$event.stopPropagation()">
                <span class="text-white">View Product</span>
            </button>
          </div>

        </div>

      </div>
    </div>

  </div>
</section>

