import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CommonSearchBarModule,
  KalgudiDialogsModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiMobileDialogModule,
  KalgudiNoContentModule,
  KalgudiProductsPickerModule,
  KalgudiStepperModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiSelectCardModule } from '@kalgudi/common/ui/select-card';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonCreationFormComponent } from './components/common-creation-form/common-creation-form.component';
import { CreationDialogComponent } from './components/creation-dialog/creation-dialog.component';
import { CropCalenderComponent } from './components/crop-calender/crop-calender.component';
import { ExistingProjectsComponent } from './components/existing-projects/existing-projects.component';
import { MobileCreationDialogComponent } from './components/mobile-creation-dialog/mobile-creation-dialog-component';
import { ProjectCreationComponent } from './components/project-creation/project-creation.component';
import { CreateProjectStateService } from './services/create-project-state.service';
import { KalgudiProjectCreationApiService } from './services/kalgudi-project-creation-api.service';
import { KalgudiProjectCreationDialogService } from './services/kalgudi-project-creation-dialog.service';
import { KalgudiProjectCreationStateService } from './services/kalgudi-project-creation-state.service';
import { KalgudiProjectCreationService } from './services/kalgudi-project-creation.service';


@NgModule({
  declarations: [
    CreationDialogComponent,
    CommonCreationFormComponent,
    CropCalenderComponent,
    MobileCreationDialogComponent,
    ProjectCreationComponent,
    ExistingProjectsComponent,
  ],
  imports: [
    CommonModule,

    // Material modules
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MomentDateModule,

    AngularSvgIconModule,

    // Flex
    FlexLayoutModule,

    TranslateModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // Kalgudi libraries
    KalgudiButtonModule,
    KalgudiStepperModule,
    KalgudiPipesModule,
    KalgudiDialogsModule,
    KalgudiMobileDialogModule,
    KalgudiImagePickerModule,
    KalgudiImageChipListModule,
    KalgudiSelectCardModule,
    KalgudiProductsPickerModule,
    CommonSearchBarModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule
  ],
  providers: [
    KalgudiProjectCreationService,
    KalgudiProjectCreationApiService,
    KalgudiProjectCreationDialogService,
    KalgudiProjectCreationStateService,
    CreateProjectStateService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    CreationDialogComponent,
    MobileCreationDialogComponent
  ]
})
export class KalgudiProjectCreationModule { }
