import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInAnimation, floatRightAnimation, scaleOutAnimation } from '@kalgudi/core';

@Component({
  selector: 'kl-page-analytics-stats',
  templateUrl: './page-analytics-stats.component.html',
  styleUrls: ['./page-analytics-stats.component.scss'],
  animations: [ floatRightAnimation, scaleOutAnimation, fadeInAnimation ]
})
export class PageAnalyticsStatsComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  matIcon: string;

  @Input()
  matIconColor: string;

  @Input()
  statsCount: string;

  @Input()
  svgIcon: string;

  @Input()
  className: string;

  @Input()
  isClickable: boolean;

  @Output()
  pageActivity = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Open page activities
   */
  openPageActivity() {
    this.pageActivity.emit();
  }

}
