import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  KalgudiAttachUrlModule,
  KalgudiAudioAttachmentModule,
  KalgudiCommonModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStoreProductsSearchModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiSocialModule } from '@kalgudi/social';
import { KalgudiGoogleLanguageTranslatorModule } from '@kalgudi/third-party/google-language-translator';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  ProfileLogTileAttachmentComponent,
} from './components/profile-log-tile-attachment/profile-log-tile-attachment.component';
import { ProfileLogTileComponent } from './components/profile-log-tile/profile-log-tile.component';
import { ProfileLogsFormComponent } from './components/profile-logs-form/profile-logs-form.component';
import { ProfileLogsListTableComponent } from './components/profile-logs-list-table/profile-logs-list-table.component';
import { ProfileLogsListComponent } from './components/profile-logs-list/profile-logs-list.component';
import { ProfileLogsViewComponent } from './components/profile-logs-view/profile-logs-view.component';
import { ProfileLogsApiService } from './services/profile-logs-api.service';
import { ProfileLogsStateService } from './services/profile-logs-state.service';
import { ProfileLogsService } from './services/profile-logs.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';




@NgModule({
  declarations: [
    ProfileLogTileComponent,
    ProfileLogsListComponent,
    ProfileLogsFormComponent,
    ProfileLogsViewComponent,
    ProfileLogTileAttachmentComponent,
    ProfileLogsListTableComponent,
  ],
  imports: [
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    AngularSvgIconModule,

    // Material modules
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatChipsModule,
    MatTableModule,
    MatProgressBarModule,
    MatPaginatorModule,


    TranslateModule,

    // Flex
    FlexLayoutModule,

     // Kalgudi libraries
    KalgudiPipesModule,
    KalgudiCommonModule,
    KalgudiButtonModule,
    KalgudiImageChipListModule,
    KalgudiImagePickerModule,
    KalgudiSocialModule,
    KalgudiStoreProductsSearchModule,
    KalgudiAudioAttachmentModule,
    KalgudiAttachUrlModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiGoogleLanguageTranslatorModule,
    KalgudiDirectivesModule,
  ],
  exports: [
    ProfileLogsViewComponent,
    ProfileLogsListComponent,
    ProfileLogsFormComponent,
    ProfileLogsListTableComponent,
  ],
  providers: [
    ProfileLogsService,
    ProfileLogsApiService,
    ProfileLogsStateService
  ]
})
export class KalgudiProfileLogsModule { }
