<main id="task-updates-form">

  <div fxLayout fxLayoutGap="3px" fxLayoutAlign="start center">

    <!-- Logged in user profile picture -->
    <!-- <div fxFlex="35px" class="Attach-file">
      <img class="profile-pic rounded-circle" [src]="profilePicUrl">
    </div> -->
    <!--/ Logged in user profile picture -->

    <!-- Share update form -->
    <div class="updates-form" fxFlex="100%" >
      <form autocomplete="off" class="w-100" [formGroup]="shareForm">

        <div fxLayout="column" class="w-100">
          <div *ngIf="digitalAssistance && adminProfile?.profileKey !== memberProfile?.profileKey">
            <mat-form-field appearance="outline" class="w-100 mat-form-field-sm input-field">
              <mat-label translate>Share on behalf of</mat-label>
              <mat-select  formControlName="assistedTo" panelClass="mat-select-sm">
                <mat-option [value]="adminProfile">
                  {{adminProfile?.firstName}}
                </mat-option>
                <mat-option [value]="memberProfile">
                  {{memberProfile?.firstName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="100%">
            <mat-form-field class="w-100" appearance="outline" fxLayoutGap.xs="5px" fxLayout.xs="column">
              <textarea matInput [rows]="rowsCount" [placeholder]="'Post your updates' | translate" formControlName="text"></textarea>
            </mat-form-field>
          </div>
        </div>

        <div>

        <!-- url attachment -->
        <div class="my-2" *ngIf="urlFormControl?.value?.url">
          <kl-post-tile-link-attachment
            [image]="urlFormControl?.value?.image"
            [domain]="urlFormControl?.value?.domain"
            [url]="urlFormControl?.value?.url"
            [title]="urlFormControl?.value?.title"
            (remove)="resetUrlMetadataFromShareForm()">

          </kl-post-tile-link-attachment>
        </div>

        <!-- / url attachment -->
        </div>

        <div class="attachment-buttons attach-buttons" fxLayoutAlign="space-between center" fxLayout.xs="row" fxLayoutAlign.xs="space-between center">

          <!-- Attachment buttons -->
          <div fxLayout fxLayoutGap="10px">
            <!-- <button class="text-white mr-1" color="primary" mat-mini-fab [matMenuTriggerFor]="attachmentsMenu">
              <mat-icon>add</mat-icon>
            </button>
            <mat-menu xPosition="before" yPosition="above" #attachmentsMenu="matMenu" class="mat-menu-md">
              <button mat-menu-item (click)="attachImage(shareFormAttachments?.value)"  [disabled]="doctype === attachmentType?.DOCUMENT">Image</button>
              <button mat-menu-item (click)="onSendDocClick(shareFormAttachments?.value)" [disabled]="doctype === attachmentType?.IMAGE">Document</button>
            </mat-menu> -->
            <!-- <button title="Attach image" type="button" mat-icon-button fxLayoutAlign="center center"
              (click)="attachImage(shareFormAttachments?.value)"
              [disabled]="doctype === attachmentType?.DOCUMENT">
              <mat-icon class="image">photo</mat-icon>
            </button>

            <button title="Attach document" type="button" mat-icon-button fxLayoutAlign="center center"
              (click)="onSendDocClick(shareFormAttachments?.value)"
              [disabled]="doctype === attachmentType?.IMAGE">
              <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/document.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            </button> -->

            <kl-image-picker-form-control formControlName="lstOfAttachments"
              [allowMultiple]="true"
              [showAttachments]="false"
              [acceptedFileTypes]="acceptedFileTypes">
            </kl-image-picker-form-control>

            <div class="attach-btn">
              <kl-attach-url-form-control
                [formControl]="urlFormControl"
                buttonLabel="URL">
              </kl-attach-url-form-control>
            </div>
          </div>


          <!--/ Attachment buttons -->

          <div class="button-menu">
            <kl-flat-button
              [label]="'Post' | translate"
              [disabled]="!shareFormText.value || shareForm?.invalid"
              (clicked)="createPost()">
            </kl-flat-button>
          </div>
        </div>
      </form>
    </div>
    <!--/ Share update form -->

  </div>



  <!-- Attached images and documents -->
  <div class="image-chip-list mt-3" *ngIf="shareFormAttachments?.value?.length && shareFormAttachments?.value">

    <kl-image-chip-list
      [attachments]="shareFormAttachments?.value"
      (remove)="removeImage($event)">
    </kl-image-chip-list>

  </div>
  <!--/ Attached images and documents -->

</main>

