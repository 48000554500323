import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogResult, KalgudiUserBasicDetails, KalgudiUsersPickerDialogConfig } from '@kalgudi/types';

@Component({
  selector: 'kl-surveys-members-search-dialog',
  templateUrl: './surveys-members-search-dialog.component.html',
  styleUrls: ['./surveys-members-search-dialog.component.scss']
})
export class SurveysMembersSearchDialogComponent extends KalgudiDialog<SurveysMembersSearchDialogComponent> implements OnInit {

  @Input()
  selectedUsersMap: { [key: string]: KalgudiUserBasicDetails } = {};

  constructor(
    protected dialogRef: MatDialogRef<SurveysMembersSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiUsersPickerDialogConfig,
    private util: KalgudiUtilityService,
  ) {

    super(dialogRef);

    this.selectedUsersMap = this.dialogData.data || {};
  }

  ngOnInit() {
  }


  ok(): void {

    const dialogRes: KalgudiDialogResult = {
      data: this.util.clone(this.selectedUsersMap),
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }


  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: null,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }
}
