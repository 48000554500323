<section class="kl-selectable-chip">
  <mat-label>{{label | translate}}</mat-label>

  <mat-chip-list multiple>
    <mat-chip *ngFor="let item of items"
      [color]="color"
      [selectable]="true"
      [disabled]="disabled"
      disableRipple
      (click)="toggleSelection(item)"
      [selected]="item?.selected">

      <mat-checkbox class="mat-checkbox-rounded" disableRipple
        [disabled]="disabled"
        [checked]="item?.selected">
        {{item?.value | translate}}
      </mat-checkbox>

      <!-- This ensure checkbox and mat-chip both does not toggle the current selection -->
      <div class="check-box-action-blocker"></div>

    </mat-chip>
  </mat-chip-list>

</section>
