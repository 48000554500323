import { Component, Inject, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProject } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.scss']
})
export class ProjectListItemComponent implements OnInit {

  @Input()
  project: KalgudiProject;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor(@Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig) { }

  ngOnInit() {

  }

  openProjectFullview(projectId: string) {
    this.appRouting.toProject({projectId});
  }

}
