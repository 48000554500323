<section id="sms-object">

  <mat-card class="p-0">

    <main  fxLayout="column">
      <!-- Header -->
      <div fxLayoutAlign="space-between center" class="border-bottom px-3" [class.py-2]="!scheduledUpdates">
          <!-- -->
        <div class="sms-header" fxLayout="row" fxLayoutGap="10px">
          <div class="chat-icon">
            <mat-icon>chat</mat-icon>
          </div>
          <div class="sms-members">
            <b> {{'SMS' | translate}} </b>
            <span class="sent-to text-muted"> {{'sent to' | translate}} </span>
            <b >{{sms?.smsCount}} <span translate>members</span></b>
          </div>
        </div>

        <ng-container *ngIf="scheduledUpdates">

          <div fxHide.xs>
            <button mat-button [matMenuTriggerFor]="belowMenu">
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu #belowMenu="matMenu" yPosition="below">
              <button mat-menu-item (click)="updateSms()" translate>Update</button>
            </mat-menu>
          </div>

          <!-- Mobile menu -->
          <div fxHide.gt-xs>
            <button mat-icon-button class="header-menu" (click)="openBottomSheet(); $event.stopPropagation(); $event.preventDefault()">
              <mat-icon class="menu">
                more_horiz
              </mat-icon>
            </button>
          </div>
          <!--/ Mobile menu -->

        </ng-container>


      </div>
      <!-- / Header -->
      <!-- Author details -->
      <div class="p-3">
        <kl-post-author
          [TS]="sms?.CTS"
          [scheduledTime]="sms?.scheduledTS ? sms?.scheduledTS : ''"
          [author]="sms?.author" avatarSize="md">
        </kl-post-author>
      </div>
      <!-- / Author details -->

      <!-- Send message -->
      <div class="px-3 pb-3">
        <p class="mb-0 text-content"
        klHideContent
        [lineHeight]="18"
        [visibleLine]="3"
        >
          {{ sms?.smsText }}
        </p>
      </div>
      <!-- / Send message -->

      <!-- Log link -->
      <!-- {{memberRole}} -->
      <div class="border-top px-2 py-1" fxLayoutAlign="space-between center" *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">
        <ng-container *ngIf="sms?.totalCredit">
         <span><span translate> Total credits:</span> {{sms?.totalCredit}}</span>
        </ng-container>
        <kl-link-button [type]="'button'" [label]="'Log'" (clicked)="showLogs(sms?.smsId)">
        </kl-link-button>
      </div>
      <!-- Log link -->

    </main>
  </mat-card>
</section>
