import { Component,Inject, Injector, OnInit } from '@angular/core';

import { KalgudiUpdateFpoInfrastructure } from '../../../classes/kalgudi-update-fpo-infrastructure';
import { KL_ENV, KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-fpo-infrastructure-form',
  templateUrl: './fpo-infrastructure-form.component.html',
  styleUrls: ['./fpo-infrastructure-form.component.scss']
})
export class FpoInfrastructureFormComponent extends KalgudiUpdateFpoInfrastructure implements OnInit {

  isShgApp: boolean = true;

  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig,
    ) {

    super(injector);

    this.isShgApp = !(this.environment?.appId === 'SHG_NET_APP');

    this.subscribeToIsAccountingValueChanges();
    this.subscribeToIsTrialsByFieldValueChanges();
    this.subscribeToIsParticipateFieldValueChanges();
  }

  ngOnInit() {

    if(this.profileDetails && this.profileDetails.fpoAdditionalDetails &&
      this.profileDetails.fpoAdditionalDetails.fpoInfrastructure) {

      this.fpoInfrastructureForm.patchValue(this.profileDetails.fpoAdditionalDetails.fpoInfrastructure);
    }
    else {
      this.isInterestedToParticipateField.patchValue('NO');
      this.isTrialDoneField.patchValue('NO');
      this.isAccountingField.patchValue('NO');
      this.isInternetConnectedField.patchValue('NO');
      this.isDeviceAvailableField.patchValue('NO');
    }
  }

  onDestroyed(): void {}
}
