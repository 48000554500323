import { Input, Directive } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  KalgudiUsersPickerDialogConfig,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiDestroyable } from './kalgudi-destroyable';


/**
 * Use the class KalgudiUserPickerFormControl as the base class for user picker form control elements.
 *
 * Must provide the `ASSISTANT_PICKER_VALUE_ACCESSOR` to the component.
 *
 * @usageNotes
 * #### To ensure the KalgudiUserPickerFormControl can be used as a formControlName
 *
 * ```ts
 * Component({
 *  providers: [{
 *   provide: NG_VALUE_ACCESSOR,
 *   useExisting: forwardRef(() => YourComponent),
 *   multi: true,
 *  }]
 * })
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiUserPickerFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  placeholder: string = 'Click here to select or change member';

  selectedUser: KalgudiUserBasicDetails;

  /**
   * `true` if the assistant picker is disabled otherwise `false`.
   */
  disabled: boolean;

  constructor() {

    super();
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.selectedUser = obj;
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }



  /**
   * Shows the user picker dialog configured and updates the form control on success.
   */
  pickMember(): void {

    // Users dialog UI configuration
    const { dialogDetails, dialogConfig } = this.getUsersPickerDialogConfig();

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(dialogRes => dialogRes.accepted),

        // Extract data out of the dialog result
        map(dialogData => dialogData.data),

        // Transform the users map to the users list
        map((usersMap: KalgudiUsersMap) => Object.values(usersMap))
      )
      .subscribe(assistants => this.updateAssistantDetails(assistants[0]));
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Write logic to show user picker dialog
   */
  protected abstract showUsersPicker(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult>;

  /**
   * Prepares members dialog configuration object.
   */
  protected getUsersPickerDialogConfig(): { dialogDetails: KalgudiUsersPickerDialogConfig, dialogConfig: MatDialogConfig } {

    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Select users',
      acceptButtonTitle: 'Select member',
      rejectButtonTitle: 'Cancel',
      multiSelect: false,
      data: this.mapSelectedUserArrayToMap(this.selectedUser)
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return { dialogDetails, dialogConfig };
  }

  /**
   * Transform users details to Kalgudi users map type
   */
  private mapSelectedUserArrayToMap(user: KalgudiUserBasicDetails): KalgudiUsersMap {

    const usersMap: KalgudiUsersMap = {};

    if (user && user.profileKey) {
      usersMap[user.profileKey] = user as any;
    }

    return usersMap;
  }

  /**
   * Updates the latest user details. It also invokes the `ControlValueAccessor`
   * `onChange` and `onTouched` functions to make sure the value is copied to the
   * formControl.
   *
   */
  private updateAssistantDetails(user: KalgudiUserBasicDetails): void {

    this.selectedUser = user;

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(this.selectedUser);
    this.onTouched();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
