<mat-card class="farmer-no-content position-relative" [ngStyle]="{height: noContentHeight? noContentHeight : '400px'}">

<div class="add-farmer-details">
    <div>
      <svg-icon [applyCss]="true" class="text-center flip-cam" [src]="svgIcon" [svgStyle]="{ 'width.px': 65, 'height.px': 65 }"></svg-icon>
    </div>

    <div *ngIf="!noContentText">
      <button mat-button class="farmer-button" (click)="onActionButtonClicked()">
        {{buttonTitle | uppercase | translate}}
      </button>
    </div>

    <div *ngIf="noContentText">
      <p translate>{{ noContentText }}</p>
    </div>
  </div>


  <div class="position-absolute image w-100">
    <img [src]="backgroundImage" [ngStyle]="{height: backgroundImageHeight? backgroundImageHeight : '230px'}">
  </div>
</mat-card>
