import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import {
  ChangePasswordMobileDialogComponent,
} from './components/change-password-mobile-dialog/change-password-mobile-dialog.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordDialogService } from './services/change-password-dialog.service';
import { KalgudiChangePasswordApiService } from './services/kalgudi-change-password-api.service';
import { KalgudiChangePasswordService } from './services/kalgudi-change-password.service';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ChangePasswordDialogComponent,
    ChangePasswordMobileDialogComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,

    // Forms
    ReactiveFormsModule,

    // Flex module
    FlexLayoutModule,

    // Material modules
    MatIconModule,
    MatButtonModule,
    MatInputModule,

    // Kalgudi modules
    KalgudiButtonModule,
    TranslateModule
  ],
  exports: [
    ChangePasswordComponent
  ],
  entryComponents: [
    ChangePasswordDialogComponent,
    ChangePasswordMobileDialogComponent,
  ],
  providers: [
    ChangePasswordDialogService,
    KalgudiChangePasswordApiService,
    KalgudiChangePasswordService
  ]
})
export class KalgudiChangePasswordModule { }
