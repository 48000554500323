<div class="task-actions" fxLayout="row" fxFlex.xs="100vw" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxFlex.xs="80%" fxLayoutGap="30px" fxLayoutGap.xs="15px" fxLayoutAlign="start center" fxLayoutAlign.xs="space-between center">
    <div fxLayoutAlign="start center">
      <!-- Task Status -->
      <button class="state-update-btn p-0 mr-n4" fxLayout fxLayoutAlign="center center" fxLayoutGap="5px" mat-button (click)="updateState()"
        [disabled]="taskDetails?.userRole === projectRoles.ADMIN || taskDetails?.userRole === projectRoles?.CONTRIBUTOR"
        color="primary"
        [ngClass]="{'state-update-btn': taskDetails?.stateChangedOn}">
        <mat-icon class="mb-0 ml-3">history</mat-icon>
        <span class="ml-1 text-muted mb-0 task-state" translate>{{ taskDetails?.state | titlecase | trimSpecialCharacters }}</span>
        <p class="ml-3 pl-4 text-secondary m-0 submitted-date">
          {{ taskDetails?.stateChangedOn | date:'dd' }}
          {{ taskDetails?.stateChangedOn | date:'MMM' | translate }}
          {{ taskDetails?.stateChangedOn | date:'yyyy' }}
        </p>
      </button>

      <!-- <mat-menu #statusMenu="matMenu" class="status-menu" >
        <div *ngFor="let task of taskStatus">
          <button mat-menu-item
            [ngClass]="{'status' : taskDetails?.state === task?.id }"
            (click)="updateState(task?.id)">
            <div fxLayout>
              <mat-icon class="mt-1">{{ task?.matIcon }}</mat-icon>
              <span class="ml-n2"> {{ task?.name }}</span>
            </div>
          </button>
        </div>
      </mat-menu> -->
    </div>
    <!-- /Task Status -->

    <!-- Date -->
    <div fxLayout fxLayoutAlign="center center" fxLayoutGap="4px" fxHide.xs>
      <svg-icon src="assets/svgs/calendar.svg" class="text-center menu-icons mr-1" [applyCss]="true" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>

       <div fxLayout="column" fxLayoutAlign="center center">
        <span [ngClass]="{'strike-date': taskDetails?.timeFrame?.start !== taskDetails?.updatedTimeFrame?.start || taskDetails?.timeFrame?.end !== taskDetails?.updatedTimeFrame?.end}">
          <span *ngIf="taskDetails?.timeFrame?.start !== taskDetails?.updatedTimeFrame?.start || taskDetails?.timeFrame?.end !== taskDetails?.updatedTimeFrame?.end"><b translate>Ideal date: </b></span>
          <span>
            {{ taskDetails?.timeFrame?.start | date:'dd' }}
            {{ taskDetails?.timeFrame?.start | date:'MMM' | translate }}
            {{ taskDetails?.timeFrame?.start | date:'yyyy' }}
          </span>
          <span *ngIf="taskDetails?.timeFrame?.start !== taskDetails?.timeFrame?.end"> <span translate>to</span>
           {{ taskDetails?.timeFrame?.end | date:'dd' }}
           {{ taskDetails?.timeFrame?.end | date:'MMM' | translate }}
           {{ taskDetails?.timeFrame?.end | date:'yyyy' }}</span>
        </span>

        <div *ngIf="taskDetails?.timeFrame?.start !== taskDetails?.updatedTimeFrame?.start || taskDetails?.timeFrame?.end !== taskDetails?.updatedTimeFrame?.end">
          <span><b translate>Actual date: </b></span>
          <span>
            {{ taskDetails?.updatedTimeFrame?.start | date:'dd' }}
            {{ taskDetails?.updatedTimeFrame?.start | date:'MMM' | translate}}
            {{ taskDetails?.updatedTimeFrame?.start | date:'yyyy' }}
          </span>
          <span *ngIf="taskDetails?.updatedTimeFrame?.start !== taskDetails?.updatedTimeFrame?.end"> <span translate>to</span>
           {{ taskDetails?.updatedTimeFrame?.end | date:'dd' }}
           {{ taskDetails?.updatedTimeFrame?.end | date:'MMM' | translate}}
           {{ taskDetails?.updatedTimeFrame?.end | date:'yyyy' }}</span>
        </div>
      </div>
    </div>


    <!-- /Date -->

    <!-- <a fxLayout fxLayoutAlign="center center" (click)="showAddTaskMembersDialog(taskDetails?.taskId)">
      <span class="mr-1 mt-1">
        <mat-icon>group</mat-icon>
      </span>
      <span *ngIf="taskDetails?.targetedAudience?.users">
        {{ taskDetails?.targetedAudience?.users?.length }} Members
      </span>
      <span *ngIf="!taskDetails?.targetedAudience?.users">
        No members
      </span>
    </a> -->

    <!-- Add members -->
    <button mat-stroked-button color="primary"
      class="add-members-button"
      [disabled]="taskDetails?.userRole === projectRoles?.MEMBER"
      (click)="showAddTaskMembersDialog()">
      <div fxLayout fxLayoutAlign="center center">
          <p class="mb-0 text-muted members mr-1" *ngIf="taskDetails?.statistics?.members?.total"> {{taskDetails?.statistics?.members?.total | number}} </p>
          <p class="mb-0 text-muted members mr-1" *ngIf="!taskDetails?.statistics?.members?.total">0</p>
          <p class="mb-0 text-muted label mr-2" translate>Members</p>
          <mat-icon>add_circle</mat-icon>
      </div>
    </button>
    <!-- /Add members -->

  </div>

  <div class="mt-n2" fxFlex.xs="20%" fxLayoutAlign.xs="end center">
    <button mat-icon-button
      *ngIf="taskDetails?.userRole === projectRoles?.ADMIN || taskDetails?.userRole === projectRoles?.CONTRIBUTOR"
      [matMenuTriggerFor]="menu">
      <mat-icon class="text-muted">more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

      <button mat-menu-item (click)="updateTask()">
        <mat-icon class="action-icons">edit</mat-icon>
        <span translate>Edit</span>
      </button>

      <button mat-menu-item (click)="deleteTask()">
        <mat-icon class="action-icons">delete_outline</mat-icon>
        <span translate>Delete</span>
      </button>

    </mat-menu>
  </div>
  <!-- /Delete and edit buttons -->

</div>
