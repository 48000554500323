import { Type } from '@angular/core';
import { ICellEditorParams } from 'ag-grid-community';
import { IdValueMap } from './core.model';
import { KalgudiUserBasicDetails } from './user.model';

export interface AgGridColumnDef {
  headerName?: string;
  resizable?: boolean;
  field?: string;
  sortable?: boolean;
  filter?: boolean | string;
  filterParams?: any;
  width?: number;

  headerCheckboxSelection?: boolean;
  headerCheckboxSelectionFilteredOnly?: boolean;
  checkboxSelection?: boolean;


  formatter?: string;
  formatterValue?: string;              // Formats value in the cell
  titleFormatterValue?: string;         // Formats value in the title

  valueFormatter?: (val: any) => string;

  cellRendererFramework?: Type<any>;    // Actual key used by ag-grid
  cellRendererFrameworkName?: string;   // API sends the component name
}

export interface AgGridRowClassRules {
  [key: string]: ((params: ICellEditorParams) => boolean) | string;
}

interface IAgGridAngular {
  rowSelection?: 'multiple' | 'single' | '';
  floatingFilter?: boolean;
  rowHeight?: number;
  rowClassRules?: AgGridRowClassRules;

  [key: string]: any;
}

export interface AgGridOptions extends IAgGridAngular {
  results: any[];
  columnDefs: AgGridColumnDef[];
  count?: number;
}

export interface AgGridFormatter {

  format(value: any, format?: string): any;
}

export enum AgGridFilters {
  DateFilter = 'agDateColumnFilter',
}

export interface RfqActivityLog extends RfqActivityPayload {
  id: string;
  createdTS: string;
  docType: string;
  [key: string]: any;
}

export interface RfqActivityPayload {
  requirementId: string;
  tag: string;
  comments: string;
  author: KalgudiUserBasicDetails;
  data?: any;
}

export interface IdValueMessageMap extends IdValueMap {
  message: string;
}

export enum AvailableTags {
  COMMENT         = 'COMMENT',
  EMAIL           = 'EMAIL',
  SMS             = 'SMS',
  CALLBACK        = 'CALLBACK',
  CALL_LOG        = 'CALL_LOG',
  QUALITY_TERMS   = 'QUALITY_TERMS',
  PAYMENT_TERMS   = 'PAYMENT_TERMS',
  TRANSPORT_TERMS = 'TRANSPORT_TERMS',
  STATUS          = 'STATUS',
  RFQ_TERM        = 'RFQ_TERM',
  RFQ_CLOSE       = 'RFQ_CLOSE',
  QUOTATION_CLOSE = 'QUOTATION_CLOSE',
  RFQ_MSG         = 'RFQ_MSG',
  RFQ_QUOTE       = 'RFQ_QUOTE',
  TERM_UPDATE     = 'TERM_UPDATE',
  RFQ_QUOTED      = 'RFQ_QUOTED',
  RFQ_PHASES      = 'RFQ_PHASES'
};

export interface Rfq {
  slNo: number;
  requirementId: string;
  createdTS: string;
  latestActivity: RfqLatestActivity;
  activityStatus: string;
  owner: string;
  bosLead: string;
  buyer: string;
  baseproduct: string;
  quantity: number;
  unit: string;
  rfqType: string;
  seller?: string;
  quotedPrice?: number;
  rfqValue?: number;

  [key: string]: any;
}

export function getLastActivityState(state: string) {
  return RFQ_LAST_ACTIVITY_STATE[state] ? RFQ_LAST_ACTIVITY_STATE[state] : state;
}

export interface RfqLatestActivity {
  createdTS: string;
  formattedComment: string;
  type: string;
}

export enum RfqStatus {
  IN_PROGRESS                     = 'IN_PROGRESS',
  TRIAGE                          = 'TRIAGE',
  REST                            = 'REST',
  WAITING_FOR_BUYER_CONFIRMATION  = 'WAITING_FOR_BUYER_CONFIRMATION',
  WAITING_FOR_SELLER_CONFIRMATION = 'WAITING_FOR_SELLER_CONFIRMATION',
};


export const RFQ_ACTIVITY_STATUS: IdValueMap[] = [
  { id: RfqStatus.IN_PROGRESS, value: 'In progress' },
  { id: RfqStatus.TRIAGE, value: 'Triage' },
  { id: RfqStatus.WAITING_FOR_BUYER_CONFIRMATION, value: 'Waiting for buyer confirmation' },
  { id: RfqStatus.WAITING_FOR_SELLER_CONFIRMATION, value: 'Waiting for seller confirmation' },
  { id: RfqStatus.REST, value: 'Rest' },
];

const RFQ_LAST_ACTIVITY_STATE = {

  COMMENT: 'COMMENT Added',
  RFQ_PHASES: 'PHASE Changed',
  STATUS: 'STATUS',
  REFERENCE_SKU_UPDATED: 'REF-SKU Added',
  // RFQ_MSG: '',
  LEAD_TYPE: 'LEAD',
  // CALLBACK: '',
  // CALL_LOG:'',
  RFQ_QUOTED: 'QUOTED',
  // RFQ_PHASES | SNOOZE:
  RFQ_SKU_ADDED_IN_QUOTATION: 'SKU ADDED',
  QUOTATION_SKU_APPROVED: 'SKU APPROVED',
  SKU_ORDERED: 'ORDERED',
  RFQ_VALUE: 'RFQ VALUE',
  QUOTATION_CLOSE: 'CLOSE QUOTE',
  RFQ_CLOSE: 'CLOSE RFQ'
};
