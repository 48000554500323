<div class="members-page">
  <div>
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center"
      *ngIf="(stream$ | async) as followers">

      <ng-container *ngIf="followers?.length">

        <!-- Members list -->
        <div class="user-list-wrapper" fxFlex="50%" fxFlex.xs="100%"
          *ngFor="let user of followers">
          <div class="p-1 m-1 user-list">
            <kl-selectable-user-tile
              [author]="user" avatarSize="md"
              [navigationAllowed]="true">
            </kl-selectable-user-tile>
          </div>
        </div>
        <!-- /Members list -->

        <!-- Load more members if members exist -->
        <ng-container *ngIf="(paginator$ | async) as paginator">
          <div class="m-3 w-100" fxLayout="row" fxLayoutAlign="center center">
            <kl-load-more
              noMoreText="No more processors"
              [progress]="paginator?.loading"
              [hasMoreItems]="paginator?.hasItems"
              (clicked)="nextPage()">
            </kl-load-more>
          </div>
        </ng-container>
        <!-- Load more members if members exist -->

      </ng-container>

      <!-- No content if there is no members -->
      <div class="w-100" fxLayout="row" *ngIf="(paginator$ | async) as paginator">
        <ng-container *ngIf="!followers?.length">
          <div class="no-content-center w-100" fxLayoutAlign="center center">
            <kl-no-content svgIcon="assets/svgs/contributors.svg"
              text="No processors added yet!"
              iconSize="md"
              actionLink="Add Processor"
              progressText="Loading processors..."
              [progress]="paginator?.loading"
              (action)="add()">
            </kl-no-content>
          </div>
        </ng-container>
      </div>
      <!--/ No content if there is no members -->
    </div>
  </div>
</div>

<div>
  <kl-float-button (clicked)="add()"></kl-float-button>
</div>
