import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { NoContentComponent } from './no-content.component';



@NgModule({
  declarations: [
    NoContentComponent
  ],
  imports: [
    // Svg Icon Module
    AngularSvgIconModule,

    CommonModule,

    // Material Module
    MatIconModule,

    FlexLayoutModule
  ],
  exports: [
    NoContentComponent,
  ]
})
export class NoContentModule { }
