import { Injectable } from '@angular/core';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiUserTaxDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo, switchMap, take } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { KalgudiProfileTaxesApiService } from './kalgudi-profile-taxes-api.service';

@Injectable()
export class KalgudiProfileTaxesService {


  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private app: KalgudiAppService,
    private api: KalgudiProfileTaxesApiService,
    private profileState: KalgudiProfileStateService,
    private profileService: KalgudiProfileService,
  ) {

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }
   }

  /**
   * Saves a new membership to the current logged in user profile.
   */
  saveNewTax(payload: KalgudiUserTaxDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new membership Api
        switchMap(r => this.api.saveNewTax(r.profileKey, r.defaultBusinessKey, payload)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
          .pipe(mapTo(res))
        )
      );
  }

  /**
   * Updates an existing membership of the current logged in user profile.
   */
  updateTax(payload: KalgudiUserTaxDetails): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Update new membership Api
        switchMap(r => this.api.updateNewTax(r.profileKey, r.defaultBusinessKey, payload.fieldName, payload)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
          .pipe(mapTo(res))
        )
      );
  }

  /**
   * Deletes an membership from the logged in user profile.
   */
  deleteTax(taxId: string): Observable<boolean> {

    return this.profileState.data$
      .pipe(
        // Subscribe to first result only
        take(1),

        // Transform the profile stream response to the response returned by
        // Save new membership Api
        switchMap(r => this.api.deleteTax(r.profileKey, r.defaultBusinessKey, taxId)),

        switchMap(res =>
          this.profileService.updateProfileAndState(this.profileState.id)
            .pipe(
              mapTo(res)
            )
        )
      );
  }
}
