import { Injectable } from '@angular/core';
import { KalgudiAppService, KalgudiStreamData, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiStreamS3FetchService } from '@kalgudi/social';
import { IdValueMap, KalgudiPageDetails, PageType, ProgramShareUpdate, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { combineLatest, Observable } from 'rxjs';
import { map, mapTo, mergeMap, switchMap, tap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { PageActions } from '../constants';
import { KalgudiProfilePageApiService } from './kalgudi-profile-page-api.service';
import { KalgudiProgramStateService } from './kalgudi-program-state.service';



@Injectable()
export class KalgudiProfilePageService {

  readonly pageDetails$: Observable<KalgudiPageDetails>;

  private pageShareTagsCache: IdValueMap[];

  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private app: KalgudiAppService,
    private api: KalgudiProfilePageApiService,
    private pageState: KalgudiProgramStateService,
    private uploadService: KalgudiUploadService,
    private util: KalgudiUtilityService,
    private s3Fetch: KalgudiStreamS3FetchService,
    private profileState: KalgudiProfileStateService,
  ) {

    // this.userDetails = this.app.profileLocal;

    this.pageDetails$ = this.pageState.data$;

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }

  }


  // --------------------------------------------------------
  // #region  Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches latest page details from service and updates the page details in the
   * page state.
   */
  fetchAndUpdatePageState(pageId: string): Observable<KalgudiPageDetails> {

    return this.getPage(pageId)
      .pipe(
        tap(page => this.updatePageState(page))
      );
  }

  /**
   * Updates the page details in the state service.
   */
  updatePageState(page: KalgudiPageDetails): void {
    this.pageState.dispatchAction(PageActions.PAGE_UPDATED, page);
  }


  /**
   * Gets, searched pages from Api
   */
  searchPages(keyword: string, mode: string = '',  pageType = ''): Observable<any> {
    return this.api.searchPages(keyword, mode, pageType);
  }

  /**
   * Gets, page details from Api
   */
  getPage(pageId: string): Observable<KalgudiPageDetails> {
    return this.api.getPage(pageId);
  }

  /**
   * Updates the page details. Calls the Api to update page details.
   */
  updatePage(pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {
    return this.api.updatePageDetails(pageDetails)
      .pipe(
        switchMap(_ => this.fetchAndUpdatePageState(pageDetails.pageId))
      );
  }

  /**
   * Calls the delete dialog Api.
   */
  deletePage(pageId: string): Observable<KalgudiPageDetails> {

    //returns the api service.
    return this.api.deletePage(pageId);
  }

  /**
   * Updates profile picture
   * @param file
   * @param path
   */
  updateProfilePic(file: File, path: S3PolicyPathCategoryMap, pageDetails: KalgudiPageDetails): Observable<KalgudiPageDetails> {

    return this.uploadProfilePicToS3(file, path, pageDetails)
      .pipe(

        // Update latest profile pic url to the Api
        switchMap(res => this.updatePage(res)),

        // Pull latest program data on successful profile pic update
        switchMap(res => this.fetchAndUpdatePageState(pageDetails.pageId)),
      );
  }

  /**
   * Returns `true` if the logged in user is admin of the page, otherwise `false`
   */
  isPageAdmin(pageDetails: KalgudiPageDetails): boolean {
    return pageDetails.memberRole === 'ADMIN';
  }

  /**
   * Returns `true` if the logged in user is author of the page, otherwise `false`.
   *
   * A person is author of the page if he has created the page.
   */
  isPageAuthor(pageDetails: KalgudiPageDetails): boolean {

    return (this.app.profileLocal && this.app.profileLocal.profileKey === pageDetails.createdBy.profileKey);
  }

  /**
   * Gets, program tags from local assets
   */
  getPageShareTags(pageType: PageType, baseProductId?: string): Observable<IdValueMap[]> {

    return this.api.getPageTags(pageType, baseProductId);

    // Check for local cache
    // return this.pageShareTagsCache
    //   ? of(this.pageShareTagsCache)   // Serve page share tags from cache
    //   : this.util.fetchJsonFromUrl<IdValueMap[]>('assets/json/program-share-tags.json')    // Fetch page tags from the api
    //       .pipe(

    //         map(tags => this.sortPageShareTags(tags)),

    //         // Cache the share tags locally
    //         tap(tags => this.pageShareTagsCache = tags)
    //       );
  }

  /**
   * Gets, stream of share update activities performed under specified program.
   *
   * @param entityId Page/program id
   * @param offset Offset to fetch records
   * @param limit Number of records to fetch
   */
  getPageShareStream(entityId: string, filter: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    return this.api.fetchPageShareStream(entityId, filter, offset, limit)
      .pipe(

        // Merge map will wait for response from all observables mapped by the `fetchStreamItem()`
        // method.
        mergeMap(r =>

          // Combine response from all s3 items
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem<ProgramShareUpdate>(s as any, s.url, s.event))
          )
        ),

        // Map the complete stream response
        map(items => ({ items }))
      );
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region  Private methods
  // --------------------------------------------------------

  /**
   * Uploads the file to s3 and gets the s3url
   * @param file
   * @param profileBasicDetails
   * @param path
   */
  private uploadProfilePicToS3(
      file: File,
      path: S3PolicyPathCategoryMap,
      pageDetails: KalgudiPageDetails
    ): Observable<KalgudiPageDetails> {

    // Call upload service
    return this.uploadService.uploadFile(file, path, false, `${pageDetails.pageId}.png`)
      .pipe(

        // Modifying the entity profile pic with the latest updated pic url
        tap(r => pageDetails.pageProfilePic = this.getProfilePicUrl(r.filePath)),

        mapTo(pageDetails),
      );
  }

  /**
   * Binds the time to the s3url
   * @param filePath
   */
  private getProfilePicUrl(filePath: string): string {

    return `${filePath}` + ('?' + new Date().getTime().toString());
  }

  /**
   * Sorts page share tags
   */
  private sortPageShareTags(tags: IdValueMap[]): IdValueMap[] {
    return tags.sort((x, y) => x.id > y.id ? 1 : -1);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
