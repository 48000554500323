<div fxLayout="column" class="promotional-product-dialog">
  <div fxLayout fxLayoutAlign="end start" class="clear-btn">
    <button mat-icon-button class="remove-button remove-circle" fxLayout="row" fxLayoutAlign="center center" (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>


  <div class="dialog-content">
    <div class="description-sheet">
      <span class="description">Product description</span>
      <p class="text-justify">{{description}}</p>
    </div>
  </div>
</div>
