import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-add-land-details-mobile-dialog',
  templateUrl: './add-land-details-mobile-dialog.component.html',
  styleUrls: ['./add-land-details-mobile-dialog.component.scss']
})
export class AddLandDetailsMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  geoTagLandDetails: any

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public data: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  /**
   * Triggers when the child emitted an event
   */
  geoTagDetails(res: any) {
    this.geoTagLandDetails = res;

    this.ok()
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        geoTagLandDetails: this.geoTagLandDetails
      }
    };
    this.dialogRef.close(result);
  }
}

