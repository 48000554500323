import { Injector, Input, Directive } from '@angular/core';
import { trimSpecialCharacters } from '@kalgudi/core';
import {
  ChartData,
  PageAnalytics,
  PageDaStats,
  PageProjectAnalytics,
  PageQaAnalytics,
  PageShareAnalytics,
  PageTaskAnalytics,
  UserWiseStats,
} from '@kalgudi/types';

import { KalgudiPageAnalyticsService } from '../services/page-analytics-activities.service';

@Directive()
export abstract class KalgudiPageAnalytics {

  @Input()
  pageId: string;

  projectStats: PageProjectAnalytics;
  sharesStats: PageShareAnalytics;
  qaStats: PageQaAnalytics;
  taskStats: PageTaskAnalytics;
  shareTypeStats: ChartData[];
  qaTypeStats: ChartData[];
  projectTypeStats: ChartData[];
  taskTypeStats: ChartData[];

  pageDaStats: PageDaStats;
  pageAnalytics: PageAnalytics;

  userWiseStatistics: UserWiseStats[];
  userStats: ChartData[];
  pageLocationAnalytics: ChartData[];
  pageProductsAnalytics: ChartData[];
  pageMembersAnalytics: ChartData[];


  private pageAnalyticsService: KalgudiPageAnalyticsService;


  constructor(
    protected injector: Injector,
  ) {
    this.pageAnalyticsService = this.injector.get(KalgudiPageAnalyticsService);
  }

  /**
   * Calls api method to get shares stats
   */
  getSharesAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchSharesAnalytics(pageId)
      .subscribe(res => {
        this.sharesStats = res;
        this.shareTypeStats = this.convertObjectToArray(res.updateTypeStats);
      } );
  }

  /**
   * Calls api method to get qa stats
   */
  getQaAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchQaAnalytics(pageId)
      .subscribe(res => {
        this.qaStats = res;
        this.qaTypeStats = this.convertObjectToArray(res);
      });
  }

  /**
   * Calls api method to get survey stats
   */
  getSurveysAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchSurveyAnalytics(pageId)
      .subscribe(
        // res => console.log('survey', res)
      );
  }

  /**
   * Calls api method to get project stats
   */
  getProjectsAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchProjectsAnalytics(pageId)
      .subscribe(res => {
        this.projectStats = res;
        this.projectTypeStats = this.convertObjectToArray(res.stateWiseCounts);
      });
  }

  /**
   * Calls api method to get tasks stats
   */
  getTasksAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchTasksAnalytics(pageId)
      .subscribe(res => {
        this.taskStats = res;
        this.taskTypeStats = this.convertObjectToArray(res.stateWiseCounts);
      });
  }

  /**
   * Calls api method to get Digital assist stats
   */
  fetchPageDaAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchDigitalAssistAnalytics(pageId)
      .subscribe(res => {
        this.pageDaStats = res.statistics;
        this.userWiseStatistics = res.userWiseStatistics;
        this.userStats = this.userWiseStats(this.userWiseStatistics);
      } );
  }
  /**
   * Calls api method to get page product stats
   */
  fetchPageProductsAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageCoverageAnalytics(pageId, 'PRODUCTS')
      .subscribe(res => {
        this.pageProductsAnalytics = this.covertToProductsChartData(res.results);
      });
  }

  /**
   * Calls api method to get page location stats
   */
  fetchPageLocationAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageCoverageAnalytics(pageId, 'LOCATIONS')
      .subscribe(res => {
        this.pageLocationAnalytics = this.covertToLocationChartData(res.results);
      });
  }

  /**
   * Calls api method to get shares stats
   */
  fetchPageAnalytics(pageId: string) {
    this.pageAnalyticsService.fetchPageAnalytics(pageId)
      .subscribe(res => {
        this.pageAnalytics = res;
        this.pageMembersAnalytics = this.convertObjectToArray(res.memberTypeStats);
      });
  }

  /**
   * Initializes the page analytics activities
   */
  protected initStream() {
    this.getSharesAnalytics(this.pageId);
    this.getQaAnalytics(this.pageId);
    this.getSurveysAnalytics(this.pageId);
    this.getProjectsAnalytics(this.pageId);
    this.getTasksAnalytics(this.pageId);
    this.fetchPageDaAnalytics(this.pageId);
    this.fetchPageLocationAnalytics(this.pageId);
    this.fetchPageProductsAnalytics(this.pageId);
    this.fetchPageAnalytics(this.pageId);
  }

  /**
   * Maps the response into location chart data model
   * @param locationStats
   */
  private covertToLocationChartData(locationStats: any[]) {
    return locationStats.map(stat => {
      return {
        name:  stat.locationName,
        value: stat.count
      };
    });

  }

  /**
   * Maps the response into products chart data model
   * @param productStats
   */
  private covertToProductsChartData(productStats: any[]) {
    return productStats.map(stat => {
      return {
        name:  stat.productName,
        value: stat.count
      };
    });

  }



  /**
   * Convert an object to the array
   */
  private convertObjectToArray(obj: any): any[] {

    const arr = [];

    Object.entries(obj).forEach(i => {
      arr.push({
        name: trimSpecialCharacters(i[0]),
        value: i[1]
      });
    });

    return arr;
  }

  /**
   * Maps the response into chart data model
   * @param userStats
   */
  private userWiseStats(userStats: UserWiseStats[]) {
    return userStats.map(user => {
      return {
        name: trimSpecialCharacters(user.firstName),
        value: user.count
      };
    });
  }
}
