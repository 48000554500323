import { Injectable, Inject } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { ApiResponseCommon, KalgudiDialogResult, KalgudiUsersPickerDialogConfig } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DEFAULT_DIALOG_CONFIG } from '../../kalgudi-dialogs/constants';
import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../../mobile-dialog/models';
import { UsersPickerDialogComponent } from '../components/users-picker-dialog/users-picker-dialog.component';
import {
  UsersPickerMobileDialogComponent,
} from '../components/users-picker-mobile-dialog/users-picker-mobile-dialog.component';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable()
export class KalgudiUsersPickerService {

  /**
   * `v2/pages/members/location-crop`
   */
  private readonly API_GET_LOCATIONS_OR_CROPS = `${this.env.restBaseUrlV2}/pages/members/location-crop`;

  /**
   * `v2/pages/members/targets`
   */
  private readonly API_GET_TARGET_COUNT = `${this.env.restBaseUrlV2}/pages/members/targets`;

  constructor(
    private dialogsService: KalgudiDialogsService,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private util: KalgudiUtilityService,
    private httpClient: HttpClient,
  ) { }

  /**
   * Shows Kalgudi users picker dialog box
   *
   * @param config Dialog
   */
  showKalgudiUsersPicker(
    details: KalgudiUsersPickerDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(UsersPickerDialogComponent, details, config);
  }

  /**
   * Opens program share update form for mobil view
   */
  showMobileUsersPicker(
    details: KalgudiUsersPickerDialogConfig,
    config?: MobileDialogConfig,
  ) {
    return this.dialogsService.openMobileDialog(UsersPickerMobileDialogComponent, details);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  /**
   * get all locations or crops
   * @returns
   */
  getLocation(profileKey: string, keyword: string, type: string) {
    let params: any = {
      profileKey: profileKey,
      keyword: keyword,
      type: type
    }
    // const url = this.API_GET_LOCATIONS_OR_CROPS;

    return this.httpClient.get<ApiResponseCommon>(this.API_GET_LOCATIONS_OR_CROPS , {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res)
      );
  }

  /**
   * get locations or crops count
   * @returns
   */
  refreshTargetCount(profileKey: string, locations: string, crops: string) {
    let params: any = {
      location: locations,
      crop: crops,
      profileKey: profileKey
    };

    if (!crops) {
      delete params.crop
    }

    if (!locations) {
      delete params.location
    }

    return this.httpClient.get<ApiResponseCommon>(this.API_GET_TARGET_COUNT , {params})
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res)
      );
  }
}
