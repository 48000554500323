<main id="farm-activity">
  <section class="mt-2">
    <form autocomplete="off" [formGroup]="shareForm">

      <div>
        <mat-form-field class="h-100 selection w-100" matSuffix appearance="outline">
          <mat-label translate>Select season</mat-label>
          <mat-select label="Select season" [placeholder]="'Select season' | translate" [formControl]="season">
            <mat-option *ngFor="let type of seasonsList" [value]="type">
              {{type?.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <p class="mb-2 mt-n2 text-danger" *ngIf="!inProgress && (!seasonsList || !seasonsList?.length)" translate>No seasons found</p>
      </div>

      <div formGroupName="farmingActivityDetails">

        <div *ngIf="(shareTags$ | async) as tags">
          <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">

            <mat-label translate>Season type</mat-label>
            <mat-select label="Activity type" [placeholder]="'Activity type' | translate" formControlName="seasonType" panelClass="mat-select-sm mat-checkbox-rounded">
              <mat-option *ngFor="let tag of seasonTags" [value]="tag.id">
                {{ tag.value | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div formGroupName="seasonDetails">
          <div *ngIf="selectedSeasonType === 'PRE_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Pre season type</mat-label>
              <mat-select label="Pre season type" [placeholder]="'Pre season type' | translate" (selectionChange)="resetFields($event)"
                formControlName="activityType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of preSeasonTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>In season type</mat-label>
              <mat-select label="In season type" [placeholder]="'In season type' | translate" (selectionChange)="resetFields($event)"
                formControlName="activityType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of inSeasonTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedSeasonType === 'POST_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Post season type</mat-label>
              <mat-select label="Post season type" [placeholder]="'In season type' | translate" (selectionChange)="resetFields($event)"
                formControlName="activityType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of postSeasonTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'IRRIGATION' && selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Irrigation type</mat-label>
              <mat-select label="Irrigation type" [placeholder]="'Irrigation type' | translate"
                formControlName="irrigationType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of irrigationTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'FERTILIZER' && selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Fertilizer type</mat-label>
              <mat-select label="Fertilizer type" [placeholder]="'Fertilizer type' | translate"
                formControlName="fertilizerType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of fertilizerTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'WEEDING' && selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Weed type</mat-label>
              <mat-select label="Weed type" [placeholder]="'Weed type' | translate"
                formControlName="weedType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of weedTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'PESTS' && selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Pests type</mat-label>
              <mat-select label="Pests type" [placeholder]="'Pests type' | translate"
                formControlName="pestsType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of pestsTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'DISEASE' && selectedSeasonType === 'IN_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Disease type</mat-label>
              <mat-select label="Disease type" [placeholder]="'Disease type' | translate"
                formControlName="diseaseType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of diseaseTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="selectedActivityType === 'HARVEST' && selectedSeasonType === 'POST_SEASON'">
            <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">
              <mat-label translate>Harvest type</mat-label>
              <mat-select label="Harvest type" [placeholder]="'Harvest type' | translate"
                formControlName="harvestType" panelClass="mat-select-sm mat-checkbox-rounded">
                <mat-option *ngFor="let tag of harvestTags" [value]="tag.id">
                  {{ tag.value | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="row" fxLayoutGap="10px">

            <!-- Date field -->
            <div *ngIf="showDateField" [fxFlex]="(selectedActivityType === 'FERTILIZER' && selectedSeasonType === 'IN_SEASON') || (selectedActivityType === 'HARVEST' && selectedSeasonType === 'POST_SEASON') || ((selectedActivityType === 'IRRIGATION' || selectedActivityType === 'WEEDING') && selectedSeasonType === 'IN_SEASON') || ((selectedActivityType === 'PESTS' || selectedActivityType === 'DISEASE') && selectedSeasonType === 'IN_SEASON') ? 'calc(33.3% - 10px)' : 'calc(50% - 10px)'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Date</mat-label>
                <input matInput formControlName="date" (click)="date.open()" [matDatepicker]="date">
                <mat-datepicker-toggle  matSuffix [for]="date"></mat-datepicker-toggle>
                <mat-datepicker #date></mat-datepicker>
              </mat-form-field>
            </div>
            <!-- / Date field -->

            <div *ngIf="showDateField" class="date-field" [fxFlex]="(selectedActivityType === 'FERTILIZER' && selectedSeasonType === 'IN_SEASON') || (selectedActivityType === 'HARVEST' && selectedSeasonType === 'POST_SEASON') || ((selectedActivityType === 'IRRIGATION' || selectedActivityType === 'WEEDING') && selectedSeasonType === 'IN_SEASON') || ((selectedActivityType === 'PESTS' || selectedActivityType === 'DISEASE') && selectedSeasonType === 'IN_SEASON') ? '33.3%' : '50%'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Time</mat-label>
                <input matInput [formControl]="shareFormTime" [format]=24 (click)="time.open()" [ngxTimepicker]="time">

                <mat-icon matSuffix
                  [ngClass]="{'disable-icon': !shareFormDate?.value, 'cursor-pointer': shareFormDate?.value}">
                  schedule</mat-icon><ngx-material-timepicker #time></ngx-material-timepicker>
              </mat-form-field>
            </div>

            <!-- No of hours -->
            <div fxFlex="calc(33.3% - 10px)"  *ngIf="(selectedActivityType === 'IRRIGATION' || selectedActivityType === 'WEEDING') && selectedSeasonType === 'IN_SEASON'">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>No.of hours</mat-label>
                <input matInput type="text" [placeholder]="'No of hours' | translate" formControlName="hours">
              </mat-form-field>
            </div>
            <!-- / No of hours -->

            <!-- Amount and Unit -->
            <div fxFlex="calc(33.3% - 10px)" *ngIf="(selectedActivityType === 'FERTILIZER' && selectedSeasonType === 'IN_SEASON') || (selectedActivityType === 'HARVEST' && selectedSeasonType === 'POST_SEASON')">
              <div fxLayout="row" formGroupName="quantityApplied">
                <div fxLayout="column" fxFlex="45%">
                  <mat-form-field appearance="outline" class="no-right-border">
                    <mat-label translate>Amount</mat-label>
                    <input matInput type="number" formControlName="value">
                  </mat-form-field>
                </div>

                <div fxLayout="column" fxFlex="55%">
                  <mat-form-field appearance="outline" class="no-left-border">
                    <mat-label translate>Unit</mat-label>
                    <mat-select formControlName="unit">
                      <mat-option value="option" *ngFor="let units of yieldUnits" [value]="units?.id">{{ units?.value | translate}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- /Amount and unit -->

            <!-- Rate of Infestation -->
            <div fxFlex="calc(33.3% - 10px)" *ngIf="(selectedActivityType === 'PESTS' || selectedActivityType === 'DISEASE') && selectedSeasonType === 'IN_SEASON'">
              <mat-form-field appearance="outline" color="accent" class="selection w-100 input-field">

                <mat-label translate>Rate of Infestation</mat-label>
                <mat-select label="Rate of Infestation" [placeholder]="'Rate of Infestation' | translate" formControlName="rateOfInfestation" panelClass="mat-select-sm mat-checkbox-rounded">
                  <mat-option *ngFor="let tag of rateOfInfestation" [value]="tag.id">
                    {{ tag.value | translate}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- /Rate of Infestation -->

          </div>
        </div>

      </div>

      <div>
        <mat-form-field class="h-100 w-100 farm-activity-field" appearance="outline">
          <mat-label translate>Describe your farm activity...</mat-label>
          <textarea matInput [placeholder]="'Describe your farm activity...' | translate" rows="5" formControlName="shareText"></textarea>
        </mat-form-field>
      </div>


      <!-- url attachment -->
      <div *ngIf="urlFormControl?.value?.url" class="mb-3">
        <kl-post-tile-link-attachment
          [image]="urlFormControl?.value?.image"
          [domain]="urlFormControl?.value?.domain"
          [url]="urlFormControl?.value?.url"
          [title]="urlFormControl?.value?.title"
          (remove)="resetUrlMetadataFromShareForm()">

        </kl-post-tile-link-attachment>
      </div>

      <!-- / url attachment -->

      <!-- Image attachments -->
      <kl-image-chip-list [attachments]="shareFormAttachments?.value" (remove)="removeImage($event)">
      </kl-image-chip-list>
      <!-- / Image attachments -->



      <!-- Selected products -->
      <div class="my-2" *ngIf="selectedProducts?.value">
        <p class="text-secondary mt-2" *ngIf="isMobileDevice && selectedProducts?.value?.length" translate>Suggested products</p>
        <kl-selected-store-products [selectedProducts]="selectedProducts.value" (remove)="removeProduct($event)"></kl-selected-store-products>
      </div>
      <!-- /Selected products -->

      <!-- Audio attachment -->
      <div class="my-2" *ngIf="audioAttachment?.value?.url">
        <mat-chip-list>
          <mat-chip (click)="showAudioDialog()">
            <div class="audio-icon">
              <mat-icon color="primary">volume_up</mat-icon>
            </div>
            <div class="ml-1" translate>Voice message attached</div>
            <mat-icon matChipRemove (click)="removeAudioAttachment()">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
      <!--/ Audio attachment -->

      <div class="w-100" fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div class="mb-3">
          <div class="" fxLayoutGap="20px" fxLayoutGap.xs="0px"
          fxLayoutAlign.xs="start start" fxLayout.xs="column" fxLayoutAlign="space-between center">

            <div fxLayout="row">
              <div>
                <button mat-button
                  class="attach-files"
                  id="attach-files-button"
                  [matMenuTriggerFor]="menu">
                  <!-- <mat-icon>attachment</mat-icon> -->
                  <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/attachment-url.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>

                  <span class="ml-1 attach-title" translate>Attach</span>
                </button>

                <mat-menu #menu="matMenu" class="attach-file">
                  <ng-container>

                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title" translate>Image</p>
                        </div>

                        <div fxLayout="row">
                          <div>
                            <kl-camera-form-control
                              fxFlex="95%"
                              [formControl]="cameraAttachment"
                              [showChipButton]="true"
                              [showIconButton]="true"
                              [showBorder]="false"
                              buttonIcon="camera_alt"
                              buttonLabel="Camera"
                              [allowMultiple]="true"
                              [showAttachments]="false"
                              [s3Category]="s3Category"
                              [acceptedFileTypes]="acceptedImageFileTypes">
                            </kl-camera-form-control>
                          </div>
                          <div>
                            <span class="mx-2">|</span>
                          </div>
                          <div>
                            <kl-image-picker-form-control
                              formControlName="lstOfAttachments"
                              [showBorder]="false"
                              [buttonType]="'mat-icon-button'"
                              svgButtonIcon="assets/svgs/attach-file/images.svg"
                              [allowMultiple]="true"
                              [showAttachments]="false"
                              [s3Category]="s3Category"
                              [acceptedFileTypes]="acceptedImageFileTypes">
                            </kl-image-picker-form-control>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title" translate>Voice</p>
                        </div>

                        <div fxLayout="row">
                          <button fxFlex="95%" id="attach-audio-button" fxLayoutAlign="center center"
                            class="mt-1" [ngClass]="{'rounded-pill': isMobileDevice}" title="Attach image" type="button"
                            [matTooltip]="'Attach audio' | translate"
                            (click)="showAudioDialog()"
                            mat-icon-button>
                            <div class="label-icon" fxLayout fxLayoutAlign="center center">
                              <mat-icon class="m-0 camera-icon">mic</mat-icon>
                            </div>
                          </button>
                          <div>
                            <span class="mx-2">|</span>
                          </div>
                          <div>
                            <kl-image-picker-form-control
                              formControlName="lstOfAttachments"
                              [showBorder]="false"
                              [buttonType]="'mat-icon-button'"
                              svgButtonIcon="assets/svgs/attach-file/folder-1.svg"
                              [allowMultiple]="true"
                              [showAttachments]="false"
                              [s3Category]="s3Category"
                              [acceptedFileTypes]="acceptedAudioFileTypes">
                            </kl-image-picker-form-control>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title" translate>File</p>
                        </div>

                        <div fxLayout="row">

                          <kl-image-picker-form-control
                            formControlName="lstOfAttachments"
                            [showBorder]="false"
                            [buttonType]="'mat-icon-button'"
                            svgButtonIcon="assets/svgs/attach-file/file-1.svg"
                            [allowMultiple]="true"
                            [showAttachments]="false"
                            [s3Category]="s3Category"
                            [acceptedFileTypes]="acceptedFileTypes">
                          </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>

                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title" translate>Video</p>
                        </div>

                        <div fxLayout="row">
                          <div class="mt-1">
                            <kl-attach-url-form-control
                              fxFlex="100%"
                              [showBorder]="false"
                              [showChipButton]="false"
                              svgIcon="assets/svgs/attach-file/video-camera-with-play-button.svg"
                              [formControl]="urlFormControl"
                              buttonType="mat-icon-button">
                            </kl-attach-url-form-control>
                          </div>
                          <!-- <div>
                            <span class="mx-2">|</span>
                          </div>
                          <div>
                            <kl-image-picker-form-control
                              formControlName="lstOfAttachments"
                              [showBorder]="false"
                              [buttonType]="'mat-icon-button'"
                              svgButtonIcon="assets/svgs/attach-file/folder-2.svg"
                              [allowMultiple]="true"
                              [showAttachments]="false"
                              [s3Category]="s3Category"
                              [acceptedFileTypes]="acceptedVideoFileTypes">
                            </kl-image-picker-form-control>
                          </div> -->

                        </div>
                      </div>
                    </div>

                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px">
                        <div>
                          <p class="m-0 title" translate>Product</p>
                        </div>

                        <div fxLayout="row">

                          <kl-store-products-form-control
                            buttonType="mat-icon-button"
                            buttonLabel="Product"
                            formControlName="productsMetaData">
                          </kl-store-products-form-control>
                        </div>
                      </div>
                    </div>

                  </ng-container>
                </mat-menu>
              </div>


            </div>

          </div>
        </div>

        <div class="submit-btn">
          <kl-flat-button
            class="submit-btn"
            label="Submit"
            [disabled]="!shareForm.valid"
            [progress]="progress"
            (clicked)="sharePost()">
          </kl-flat-button>
        </div>
      </div>
    </form>
  </section>
</main>
