import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';

@Component({
  selector: 'kl-task-updates-tile',
  templateUrl: './task-updates-tile.component.html',
  styleUrls: ['./task-updates-tile.component.scss']
})
export class TaskUpdatesTileComponent implements OnInit {

  @Input()
  update;

  @Input()
  userProfileKey: string;

  @Output()
  deletePost = new EventEmitter<string>();


  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) {

    this.domain = this.env.coreDomain;
  }

  ngOnInit() {
  }

  // Open image full view
  openFullview(attachments): void {
    this.kalgudiPhotoswipe.open(attachments, this.env.domain, 0).subscribe();
  }

  /**
   * Emits an event to delete the update
   */
  deleteUpdate(activityId: string): void {
    this.deletePost.emit(activityId);
  }

}
