import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AttachmentTypeCountPipe } from './attachment-type-count.pipe';
import { CapitalizeUnit } from './capitalize-unit.pipe';
import { DirLinkPipe } from './dir-link.pipe';
import { HighlightSearch } from './highlight-text.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { JoinArrayFieldsPipe } from './join-array-fields.pipe';
import { JoinArrayPipe } from './join-array.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { ModernNumberPipe } from './modern-number.pipe';
import { ObjectValuesPipe } from './object-values.pipe';
import { ParseDatePipe } from './parse-date.pipe';
import { PrefixDomainPipe } from './prefix-domain.pipe';
import { ResizeImagePipe } from './resize-image.pipe';
import { SafePipe } from './safe-html.pipe';
import { SecureMobilePipe } from './secure-mobile.pipe';
import { SentenceCasePipe } from './sentence-case.pipe';
import { StoreLinkPipe } from './store-link.pipe';
import { SubstringPipe } from './substring.pipe';
import { LimitTextPipe } from './text-limit.pipe';
import { TimeAgoPipe } from './time-ago.pipe';
import { TrimSpecialCharactersPipe } from './trim-special-characters.pipe';
import { UnitsConverterPipe } from './units-converter.pipe';
import { SmartElementsPipe } from './smart-elements.pipe';

@NgModule({
  declarations: [
    ResizeImagePipe,
    SafePipe,
    TimeAgoPipe,
    PrefixDomainPipe,
    ParseDatePipe,
    JoinArrayPipe,
    JoinArrayFieldsPipe,
    TrimSpecialCharactersPipe,
    IsEmptyPipe,
    ModernNumberPipe,
    ObjectValuesPipe,
    DirLinkPipe,
    LinkifyPipe,
    AttachmentTypeCountPipe,
    SecureMobilePipe,
    StoreLinkPipe,
    LimitTextPipe,
    SentenceCasePipe,
    SubstringPipe,
    HighlightSearch,
    UnitsConverterPipe,
    CapitalizeUnit,
    SmartElementsPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ResizeImagePipe,
    SafePipe,
    TimeAgoPipe,
    PrefixDomainPipe,
    ParseDatePipe,
    JoinArrayPipe,
    JoinArrayFieldsPipe,
    TrimSpecialCharactersPipe,
    IsEmptyPipe,
    ModernNumberPipe,
    ObjectValuesPipe,
    DirLinkPipe,
    LinkifyPipe,
    AttachmentTypeCountPipe,
    SecureMobilePipe,
    StoreLinkPipe,
    LimitTextPipe,
    SentenceCasePipe,
    SubstringPipe,
    HighlightSearch,
    UnitsConverterPipe,
    CapitalizeUnit,
    SmartElementsPipe
  ],
  providers: [
    JoinArrayPipe,
    SmartElementsPipe
  ]
})
export class KalgudiPipesModule { }
