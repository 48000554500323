<main id="task-update-header">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom">
    <!-- Header title -->
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="task-icon">format_quote</mat-icon>

      <div class="header-title">
        <h3 class="mb-0" translate>Task updates</h3>
      </div>
    </div>
    <!--/ Header title -->

    <!-- Header three dot menu -->
    <!-- <div fxHide.xs (click)="$event.stopPropagation(); $event.preventDefault()">
      <div>
        Three dot menu
        <button mat-icon-button fxLayoutAlign="end end" class="header-menu" [matMenuTriggerFor]="Menu">
          <mat-icon class="menu">
            more_horiz
          </mat-icon>
        </button>
      </div>

      <mat-menu #Menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
        <button mat-menu-item id="report-abuse" class="mb-0" (click)="reportAbuse()" translate>Report abuse</button>
        <button mat-menu-item id="delete" class="mb-0" (click)="deleteUpdate()" translate>Delete</button>
      </mat-menu>
    </div> -->

    <!-- <div fxHide.gt-xs>
      Mobile menu
      <button mat-icon-button class="header-menu" (click)="openBottomSheet(); $event.stopPropagation(); $event.preventDefault()">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>
      / Mobile menu
    </div>
    / Header three dot menu -->
  </div>
</main>
