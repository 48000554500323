import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { FPOWarehouse, KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { WarehouseGodownDialogComponent } from '../components/warehouse-godown/warehouse-godown-dialog/warehouse-godown-dialog.component';
import { WarehouseGodownMobileDialogComponent } from '../components/warehouse-godown/warehouse-godown-mobile-dialog/warehouse-godown-mobile-dialog.component';

@Directive()
export abstract class KalgudiWarehouseGodown extends KalgudiDestroyable {

  editable: boolean;
  profileDetails: KalgudiUser;
  profileWarehouseList: FPOWarehouse;

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;
  private profileState: KalgudiProfileStateService;

  constructor(
    protected injector: Injector
  ) {
    super();

    this.dialogService     = this.injector.get(KalgudiDialogsService);
    this.profileService    = this.injector.get(KalgudiProfileService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);


    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    // Initialize profile details
    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
        this.profileWarehouseList = (this.profileDetails.fpoAdditionalDetails && this.profileDetails.fpoAdditionalDetails.fpoWarehouse) ? this.profileDetails.fpoAdditionalDetails.fpoWarehouse : {};
      });

  }

  //---------------------------------------------------------
  // #region getter and setter methods
  //---------------------------------------------------------

  //---------------------------------------------------------
  // #end region
  //---------------------------------------------------------

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Shows warehouse dialog
   */
  showWarehouseDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Warehouse/godown',
      data: {

      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'warehouse-godown',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {

      }
    };

    return this.openWarehouseDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Open warehouse dialog
   */
  private openWarehouseDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(WarehouseGodownMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(WarehouseGodownDialogComponent, dialogConfig, matDialogConfig);

    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
