import { Component, Injector, OnChanges, OnInit } from '@angular/core';

import { KalgudiFarmerMembershipForm } from '../../classes/kalgudi-farmer-membership-form';

@Component({
  selector: 'kl-farmer-membership-form',
  templateUrl: './farmer-membership-form.component.html',
  styleUrls: ['./farmer-membership-form.component.scss']
})
export class FarmerMembershipFormComponent extends KalgudiFarmerMembershipForm implements OnInit, OnChanges {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.farmerMembershipDetails) {
      this.farmerMembershipForm.patchValue(this.farmerMembershipDetails)
    }
  }

  onDestroyed(): void {}
}
