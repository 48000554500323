import { Directive, EventEmitter, Injector, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService, KalgudiGeoLocationMarkerService } from '@kalgudi/common';
import {
  KALGUDI_BUSINESS_TYPES,
  KalgudiAppService,
  KalgudiDestroyable,
  KalgudiFormValidators,
  KalgudiUtilityService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  CommonBasicProfile,
  IdValueMap,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiLocation,
  KalgudiUser,
  LatLong,
  SamunnatiFarmerDetails
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { GeotagConfirmDialogComponent } from '../components/geotag-confirm-dialog/geotag-confirm-dialog.component';
import { DatePipe } from '@angular/common';


@Directive()
export abstract class KalgudiProfileBasicDetailsEdit extends KalgudiDestroyable {

  readonly businessTypes = KALGUDI_BUSINESS_TYPES;

  readonly TABS = {
    ENTER_LOC: { index: 0, title: 'Enter location', svgIcon: 'assets/svgs/farmer-profile/pencil-boundary.svg' },
    CURRENT_LOC: { index: 1, title: 'Use current location', svgIcon: 'assets/svgs/farmer-profile/user-location.svg' },
    SELECT_LOC: { index: 2, title: 'Select on map', svgIcon: 'assets/svgs/farmer-profile/man-walking.svg' },
  };

  @Output()
  closeDialog = new EventEmitter();

  @Output()
  openTermsConditionDialog = new EventEmitter();

  progress: boolean;
  profileDetails: KalgudiUser;

  profileForm: FormGroup;

  pageId: string;
  isDigitalAssist: boolean;
  activeTabIndex: number;
  locationLabel: string;

  currentPosition: { latitude: number; longitude: number; accuracy?: number };
  currentLocationProgress: boolean = true;
  currentLocation: any;

  readonly genderList: IdValueMap[] = [
    { id: 'MALE', value: 'Male' },
    { id: 'FEMALE', value: 'Female' }
  ];

  isSmartphoneAvailable = [
    { id: 0, value: 'Yes' },
    { id: 1, value: 'No' },
  ];

  isWhatsappAvailable = [
    { id: true, value: 'Yes' },
    { id: false, value: 'No' },
  ]

  readonly educationList = [
    'Uneducated',
    'Below 10th',
    '10th',
    'Intermediate',
    'Under graduate',
    'Post graduate'
  ];

  // Todays date for max date of birth picker
  readonly todaysDate = new Date();

  public util: KalgudiUtilityService;
  private profileState: KalgudiProfileStateService;
  private profileService: KalgudiProfileService;
  private notification: KalgudiNotification;
  private dialogsService: KalgudiDialogsService;
  private appService: KalgudiAppService;
  private kalgudiGeoLocationMarkerService: KalgudiGeoLocationMarkerService;
  public environment: KalgudiEnvironmentConfig;
  public datePipe: DatePipe;
  showLocation: any;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder,

  ) {

    super();

    this.util                            = this.injector.get(KalgudiUtilityService);
    this.profileState                    = this.injector.get(KalgudiProfileStateService);
    this.profileService                  = this.injector.get(KalgudiProfileService);
    this.notification                    = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.dialogsService                  = this.injector.get(KalgudiDialogsService);
    this.appService                      = this.injector.get(KalgudiAppService);
    this.kalgudiGeoLocationMarkerService = this.injector.get(KalgudiGeoLocationMarkerService);
    this.environment                     = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.datePipe                        = this.injector.get(DatePipe);

    this.profileForm = this.newProfileBasicDetailsForm;

    this.profileState.data$
      .pipe(
        takeUntil(this.destroyed$),

        take(1),
      )
      .subscribe(profile => this.onProfileStateUpdate(profile));

    this.pageId = this.profileState.pageId;

    this.isDigitalAssist = this.pageId ? true : false;
  }


  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  private get basicDetailsField(): AbstractControl {
    return this.profileForm.get('basicDetails');
  }

  private get personalDetailsField(): AbstractControl {
    return this.profileForm.get('personalDetails');
  }

  /**
   * Gets the alternate mobile number
   */
  public get basicAlternateMobileNo(): AbstractControl {
    return this.basicDetailsField.get('alternateMobileNo');
  }

  /**
   * Getter for email id
   */
  public get basicEmailId(): AbstractControl {
    return this.basicDetailsField.get('altemailId');
  }

  private get basicDetailsLocationField(): AbstractControl {
    return this.basicDetailsField.get('location');
  }

  protected get basicDetailsDoBField(): AbstractControl {
    return this.basicDetailsField.get('dateOfBirth');
  }

  private get basicBusinessTypeNameField(): AbstractControl {
    return this.basicDetailsField.get('businessTypeName');
  }

  private get employeeDetailsField(): AbstractControl {
    return this.profileForm.get('employeeDetails');
  }

  public get employeeDetailsYear(): AbstractControl {
    return this.employeeDetailsField.get('experience.years');
  }

  public get employeeDetailsMonth(): AbstractControl {
    return this.employeeDetailsField.get('experience.months');
  }

  private get locationField(): AbstractControl {
    return this.profileForm.get('location');
  }

  // Getter start for farmer update profile input fields
  protected get villageNameField(): AbstractControl {
    return this.basicDetailsField.get('village');
  }

  protected get smartphoneField(): AbstractControl {
    return this.basicDetailsField.get('hasSmartPhone');
  }

  private get whatsappField(): AbstractControl {
    return this.basicDetailsField.get('useWhatsapp');
  }

  protected get farmerWhatsApp(): AbstractControl {
    return this.basicDetailsField.get('farmerWhatsApp');
  }

  protected get fatherNameField(): AbstractControl {
    return this.basicDetailsField.get('fatherName');
  }

  public get aadharNumberField(): AbstractControl {
    return this.personalDetailsField.get('aadhar_kyc_number');
  }

  public get panNumberField(): AbstractControl {
    return this.personalDetailsField.get('pan_kyc_number');
  }

  public get govtIdField(): AbstractControl {
    return this.personalDetailsField.get('UniqueID');
  }

  // Getter end for farmer update profile input fields

  /**
   * Employee business type field
   */
  private get businessTypeNameField(): AbstractControl {
    return this.profileForm.get('basicDetails.businessTypeName');
  }

  /**
   * Form group for employee basic details
   */
  private get newProfileBasicDetailsForm(): FormGroup {

    return this.fb.group({

      location: [{}],

      basicDetails: this.fb.group({
        dateOfBirth: [''],
        firstName: [''],
        gender: [''],
        village: [''],
        fatherName: [''],
        education: [''],
        address2: [''],
        alternateMobileNo: ['', [...KalgudiFormValidators.indianMobileNumberValidators]],
        altemailId: ['', [...KalgudiFormValidators.strictEmailValidators]],
        useWhatsapp: [''],
        farmerWhatsApp: [''],
        hasSmartPhone: [''],
        businessTypeName: [''],
        aboutMe: [''],
        location: [{}]
      }),

      employeeDetails: this.fb.group({
        specialization: [''],
        rolesAndResponsibilities: [''],
        experience: this.fb.group({
          years: [0],
          months: [0]
        })
      }),

      personalDetails: this.fb.group({
        aadhar_kyc_number: [''],
        pan_kyc_number: [''],
        UniqueID: [''],
      })
    });
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Update employee basic details
   */
  updateProfile() {
    if(this.environment?.appId === "SAM_FPO") {
      // Remove time-stamp from date picker
      let establishedDate: any = this.profileForm.value.basicDetails.establishedSince
      if(establishedDate) {
        let formattedDate = this.datePipe.transform(establishedDate, 'yyyy-MM-dd');
        this.profileForm.value.basicDetails.establishedSince = formattedDate;
      }
    }

    if(this.environment?.appId === "SHG_NET_APP") {
      // Remove time-stamp from date picker
      let establishedDate: any = this.profileForm.value.basicDetails.establishedSince;

      if(establishedDate) {
        let formattedDate = this.datePipe.transform(establishedDate, 'yyyy-MM-dd');
        this.profileForm.value.basicDetails.establishedSince = formattedDate;
      } else {
        this.profileForm.value.basicDetails.establishedSince = '';
      }
    }

    if(this.environment?.appId === "SAM_FARMER") {
      // Remove time-stamp from date picker
      let dateOfBirth: any = this.profileForm.value.basicDetails.dateOfBirth
      if(dateOfBirth) {
        let formattedDate = this.datePipe.transform(dateOfBirth, 'yyyy-MM-dd');
        this.profileForm.value.basicDetails.dateOfBirth = formattedDate;
      }
    }

    const payload = this.preparedPayload(this.profileForm.value);

    let farmerPayload = {};
    if(this.environment?.appId === "SAM_FARMER") {
      let useWhatsApp: boolean = !!this.farmerWhatsApp.value;
      payload.basicDetails.useWhatsapp = useWhatsApp;

      const samFarmerPayload = this.samFarmerPayload();
      farmerPayload = {
        ...samFarmerPayload
      }

      delete payload.basicDetails.village;
      delete payload.basicDetails.fatherName;
      delete payload.basicDetails.hasSmartPhone;
      delete payload.basicDetails.farmerWhatsApp;
      delete payload.personalDetails;
      farmerPayload = {
        ...payload,
        sammunatiFarmerDetails: samFarmerPayload
      }
    }

    // Check for valid location
    if(!this.isValidLocation(payload) || !this.isValidLocation(farmerPayload)) {

      const errorMessage = 'Please select proper village/town/street';

      this.notification.showMessage(errorMessage);
      throw new Error(errorMessage);
    }

    this.progress = true;
    const finalPayload = this.environment?.appId === "SAM_FARMER" ? farmerPayload : payload

    this.profileService.updateCommonBasicDetails(finalPayload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onAddingEmployeeDetails(res),
        err => this.notification.showMessage('Unable to update profile info, please try again later')
      );
  }

  /**
   * Closes basic details form
   */
  cancelProfile() {
    this.closeDialog.emit();
  }

  /**
   * Opens terms and conditions dialog
   */
  termsAndConditionDialog() {
    this.openTermsConditionDialog.emit();
  }

  /**
   * Shows confirm dialog
   */
  displayGeotagDialog(tab) {
    if(tab.index === 2) {
      this.showConfirmDialog()
        .subscribe(res => {

          this.activeTabIndex = res.accepted ? tab.index : 0;

          if(!res.accepted && this.activeTabIndex === 0) {

            if(this.profileDetails.lstOfUserBusinessDetailsInfo[0].locationTo) {

              // this.geoFences.patchValue(this.landDetails.geoFences);
              this.locationField.patchValue(this.profileDetails.lstOfUserBusinessDetailsInfo[0].locationTo);
            } else {
              this.locationField.patchValue(this.currentLocation);
            }
          }

        },
          err => {
            this.activeTabIndex = 0;

            if(this.profileDetails.lstOfUserBusinessDetailsInfo[0].locationTo) {

              // this.geoFences.patchValue(this.landDetails.geoFences);
              this.locationField.patchValue(this.profileDetails.lstOfUserBusinessDetailsInfo[0].locationTo);
            } else {
              this.locationField.patchValue(this.currentLocation);
            }
          });
    }
  }

  /**
   * Get current location
   * @param shouldSearchInGoogle
   */
  getCurrentLocation(edit?: boolean): void {

    this.fetchCurrentLocation();

    this.currentLocationProgress = true;

    setTimeout(() => {

      if(!this.currentPosition) {
        this.currentLocationProgress = false;

        if(!this.currentLocationProgress && !this.profileDetails.lstOfUserBusinessDetailsInfo[0].locationTo) {

          const loggedInUserLocation = this.appService.loggedIn ? this.appService.profileLocal.lstOfUserBusinessDetailsInfo[0].locationTo : {};

          this.locationField.patchValue(loggedInUserLocation);
        }
      }
    }, 500);

  }

  /**showConfirmDialog
   * Displays confirmation dialog
   */
  showConfirmDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirmation',
      acceptButtonTitle: 'Save',
      rejectButtonTitle: 'Cancel',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openConfirmDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        // filter(r => r && r.accepted),

        // map(r => r.accepted)
      );
  }

  geoTagChanged(latLong: LatLong): void {

    const location: KalgudiLocation = {
      countryId: '',
      countryName: '',
      countryShortName: '',
      districtId: '',
      districtName: '',
      locality: '',
      locationLong: '',
      locationShort: '',
      location_category_id: '',
      openerpCountryId: '',
      placeId: '',
      placeName: '',
      postalCode: '',
      regionId: '',
      regionName: '',
      stateId: '',
      stateName: '',

      ...latLong,
    };


    // this.geoFences.patchValue([]);

    const latLng = { latitude: latLong.latitude, longitude: latLong.longitude }

    if(latLng.longitude && latLng.latitude) {

      this.kalgudiGeoLocationMarkerService.searchOnGoogle(latLng)
        .pipe(take(1))
        .subscribe(
          (res: any) => {

            const currentLocation = res.kalgudiGoogleLocationTo;

            this.showLocation = res.kalgudiGoogleLocationTo;

            this.locationField.patchValue(currentLocation);

            if((this.currentLocation.districtName !== currentLocation.districtName) || (this.currentLocation.stateName !== currentLocation.stateName)) {

              this.locationLabel = (currentLocation.districtName ? currentLocation.districtName + ', ' : '') +
                (currentLocation.stateName ? currentLocation.stateName + ', ' : '') +
                (currentLocation.countryName ? currentLocation.countryName : '') +
                (currentLocation.countryName && currentLocation.postalCode ? ' - ' : '') +
                (currentLocation.postalCode ? currentLocation.postalCode : '');

            }
          }
        )
    }

  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Prepares payload for basic profile details update
   */
  protected preparedPayload(formValue: any): CommonBasicProfile {

    const payload: CommonBasicProfile = this.util.clone({
      ...formValue
    });


    // Location not updated hence don't send the location payload
    // On location update using google location picker countryId will not
    // be present.
    if(payload.location && !payload.location.adminLevel1) {
      delete payload.location;
    }

    return payload;
  }

  /**
   * Assigning values to particular sam farmer fields.
   */
  protected samFarmerPayload(): SamunnatiFarmerDetails {

    let FarmerFPODetails: any = {
      fpoName: '',
      fpoNumber: '',
      fpoLocation: '',
      figName: '',
      figLocation: '',
      leadName: '',
      leadNumber: '',
      leadEmailId: ''
    }

    const payload: any = {
      village: this.villageNameField.value,
      hasSmartPhone: this.smartphoneField.value,
      identityProofDocumentUrl: '',
      addressProofDocumentUrl: '',
      Name_of_the_Nominee: '',
      Relationship_with_the_Nominee: '',
      marital: '',
      pan_kyc_number: this.panNumberField.value,
      aadhar_kyc_number: this.aadharNumberField.value,
      UniqueID: this.govtIdField.value,
      fatherName: this.fatherNameField.value,
      spousename: '',
      spouse_aadhar_kyc_number: '',
      spouse_pan_kyc_number: '',
      isShareholderWithFPO: '',
      isTransactingWithFPO: '',
      house: '',
      housetype: '',
      maleMembers: '',
      femaleMembers: '',
      householdct: '',
      primeMemName: '',
      primeMemContact: '',
      isAnymemberPartOfSameFpo: '',
      shareHolderAnotherFpo: '',
      ownirrigatedland: '',
      snownirrigatedland: '',
      ownrainfedland: '',
      snownrainfedland: '',
      leaseirrigatedland: '',
      snleaseirrigatedland: '',
      leaserainfedland: '',
      snleaserainfedland: '',
      fpoDetails: FarmerFPODetails,
      annualGrossIncome: [],
      insuranceDetails: [],
      cropDetails: [],
      liveStockDetails: [],
      bankDetails: [],
      assets: [],
    }

    return payload;
  }
  /**
   * Checks if the location field is valid or not while updating the location.
   */
  protected isValidLocation(payload: CommonBasicProfile): boolean {

    return (!payload.location) || (payload.location
      && !!payload.location.adminLevel1
      && !!payload.location.adminLevel2
      && !!payload.location.countryName
      && !!payload.location.latitude
      && !!payload.location.longitude
      && !!payload.location.locality
    );
  }

  /**
   * On employee profile basic details changed
   */
  protected onAddingEmployeeDetails(res): void {
    this.closeDialog.emit();
    this.notification.showMessage('Updated profile info');
  }

  /**
   * Event handler called on profile state update
   */
  onProfileStateUpdate(profile: KalgudiUser): void {

    this.profileDetails = profile;

    // Update the profile details to form
    this.patchProfileDetailsToForm(profile);
  }

  /**
   * Patch employee basic details to employee form
   */
  private patchProfileDetailsToForm(profile: KalgudiUser) {

    // Patch profile basic details
    this.basicDetailsField.patchValue(profile);
    if(this.environment?.appId === "SAM_FARMER") {
      this.basicDetailsField.patchValue(profile.sammunatiFarmerDetails);
      this.personalDetailsField.patchValue(profile.sammunatiFarmerDetails);
      this.farmerWhatsApp.patchValue(profile.useWhatsapp);
      this.aadharNumberField.patchValue(profile.sammunatiFarmerDetails.aadhar_kyc_number === '0' ? '' : profile.sammunatiFarmerDetails.aadhar_kyc_number)
    }

    if (profile.alternateMobileNo) {
      this.basicAlternateMobileNo.patchValue(profile.alternateMobileNo.includes('+91') ? profile.alternateMobileNo.slice(3) : profile.alternateMobileNo)
    }

    // this.basicDetailsLocationField.patchValue(profile.lstOfUserBusinessDetailsInfo[0].locationTo);
    this.basicBusinessTypeNameField.patchValue(profile.lstOfUserBusinessDetailsInfo[0].businessTypeName);

    // Patch employee details
    if(this.employeeDetailsField) {
      this.employeeDetailsField.patchValue(profile.employeeProfileTo || {});
    }

    // Patch location details
    // this.locationField.patchValue(profile.lstOfUserBusinessDetailsInfo[0].locationTo);
  }

  /**
   * Shows the confirm web or mobile dialog
   */
  private openConfirmDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogsService.openDialog(GeotagConfirmDialogComponent, dialogConfig, matDialogConfig);

  }

  /**
   * Fetch current current location using current location coordinates
   */
  private fetchCurrentLocation() {

    this.kalgudiGeoLocationMarkerService.getGeoCoords()
      .pipe(
        take(1),
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.currentPosition = res;
        this.geoTagChanged({
          latitude: res.latitude,
          lat: res.latitude,
          longitude: res.longitude,
          lng: res.lng
        })

        const observer = this.kalgudiGeoLocationMarkerService.searchOnGoogle(this.currentPosition);
        // this.handleSearchObserver(observer);
      });
  }

  /**
   * Handles keypress events for input validation.
   * @param event The keypress event.
   * @param label The type of label (optional).
   * @returns true if the keypress is allowed, false otherwise.
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,
      alphaNumeric: /^[0-9a-zA-Z]$/,
      alphabetSpace: /^[a-zA-Z\s]$/,
      alphaNumericSpace: /^[0-9a-zA-Z\s]$/,
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,
      number: /^[0-9]+$/,
      numberDot: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : false;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
