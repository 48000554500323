import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialog, KalgudiDialogsService } from '@kalgudi/common';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiProfileRouteConfig } from '../../../../config';
import { LandDataDownloadDialogComponent } from '../land-data-download-dialog/land-data-download-dialog.component';

@Component({
  selector: 'kl-land-details-dialog',
  templateUrl: './land-details-dialog.component.html',
  styleUrls: ['./land-details-dialog.component.scss']
})
export class LandDetailsDialogComponent extends KalgudiDialog<LandDetailsDialogComponent> implements OnInit {

  isEditLand: boolean;
  landId: string;

  constructor(
    protected dialogRef: MatDialogRef<LandDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig,
    private dialogService: KalgudiDialogsService,
  ) {
    super(dialogRef);

    this.landId = data.data.landDetails.landId;
  }

  ngOnInit() {
  }

  /**
   * Open the land details edit dialog and close land details dialog
   */
  openLandDetailsEditDialog(){
    this.isEditLand = true;
    this.ok();
  }

  /**
   * Opens season edit dialog
   */
  openSeasonEditDialog(season: any) {
    this.ok();

    const cropPageId = season.pageId;
    const pageId = this.data.data.pageId;
    const isAssisted = this.data.data.isAssisted;
    const seasonId = season.id;

    this.appRouting.toCropSettings({'profileKey': this.data.data.assistedProfileKey, 'cropId': season.crop.productId, 'cropTitle': season.crop.productName}, {cropPageId, pageId, isAssisted, seasonId})

  }

  /**
   * Open land data download dialog to download zip file
   */
  downloadZipFile() {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Download land data',
      data: {
        landId: this.landId,
        profileKey: this.data.data.assistedProfileKey
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'land-details',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openLandDataDownloadDialog(dialogDetails, dialogConfig)
      // .pipe(

      //   filter(r => r.accepted),

      //   map(r => r.accepted),

      // );
  }

  /**
   * Open land data download dialog
   */
  private openLandDataDownloadDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    return this.dialogService.openDialog(LandDataDownloadDialogComponent, dialogConfig, matDialogConfig);
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        isEditLand: this.isEditLand
      }
    };
    this.dialogRef.close(result);
  }
}
