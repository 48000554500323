<div id="profile-log-tile">
  <mat-card class="p-0">
    <!-- Profile Log tile header -->
    <main class="px-3 pt-2">
      <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom pb-2">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <div class="mr-1" fxLayoutAlign="center center">
            <svg-icon src="assets/svgs/profile-logs.svg" [svgStyle]="{ 'width.px': 24, 'height.px': 24}">
            </svg-icon>
          </div>
          <div>
            <span class="mb-0 text-muted header-title" translate>Profile logs</span>
          </div>
        </div>
      </div>
    </main>
    <!-- / Profile Log tile header -->

  <div fxLayout="row" fxLayoutGap="15px" class="px-3 py-n1" fxLayoutAlign="space-between">
    <div class="author-info" fxFlex="80%" fxFlex.xs="100%">

      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <!-- User profile pic -->
        <div fxFlex="50px" class="rounded-circle mt-2">
          <span class="profile-pic d-block rounded-circle overflow-hidden w-100 m-auto"
            [style.background-image]="pageProfilePic | safe: 'style'">

            <img class="w-100 h-100 rounded-circle" src="assets/icons/transparent.png"/>
          </span>
        </div>
        <!-- / User Profile pic -->

        <!-- user Details -->
        <div class="author-details" fxFlex="90%" fxFlex.xs="100%">

          <!-- User name -->
          <div fxLayout="row wrap" class="mw-100 mt-2 mb-n2">
            <div class="mw-70 author-first-name mr-1">
              <a class="text-capitalize author-name mb-0 text-truncate d-inline-block">
                {{ profileLog?.pageDetails?.pageTitle }}
              </a>
            </div>
            <div class="mx-1 time-ago-details">
              <span class="mb-0 text-muted mr-1">posted</span>
              <span class="mb-0 text-muted"
                [title]="profileLog?.LUT | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">{{ profileLog?.LUT | timeAgo }}
                <span translate>ago</span>
              </span>
            </div>
          </div>
          <!-- /User name -->

          <!-- Business type and location -->
          <div fxLayout="row" class="mw-100">
            <div fxFlex="100%" fxFlex.xs="95%">
              <p class="text-truncate mb-0">
                <span class="page-type mb-0 text-truncate mt-n4px business-type">
                  {{ profileLog?.pageDetails?.pageType | titlecase | translate }}
                </span>
                <!--<span *ngIf="profileLog?.pageDetails?.pageType && profileLog?.location" class="dot d-inline-block align-middle mx-1 mobile-view">
                </span>
                <span class="mb-0 text-truncate text-muted mt-n4px business-type">
                  {{ profileLog?.location }}
                </span>-->
              </p>
            </div>
          </div>
          <!-- / Business type and location-->
        </div>

        <!-- / User details -->
      </div>
    </div>
  </div>

  <!-- Profile Log tile attachments -->
  <div class="py-2">
    <kl-profile-log-tile-attachment [profileLogDetails]="profileLog?.logDetails"></kl-profile-log-tile-attachment>
  </div>
  <!-- / Profile Log tile attachments -->

  </mat-card>
</div>
