<div id="stocks-list">
  <mat-card class="mb-3" *ngIf="pageDetails?.product?.productName && pageDetails?.variety?.productName">
    <h5 class="base-product">
      <strong translate>Base product: </strong>
      <span>{{pageDetails?.product?.productName | titlecase}}</span>
    </h5>
    <p class="mb-0 text-secondary"><strong translate>Variety: </strong>{{pageDetails?.variety?.productName | titlecase}}</p>
  </mat-card>

  <mat-card [@fadeIn] class="p-0" [ngClass]="{'d-none': !dataSource?.data?.length}">

    <div class="h-4">
      <mat-progress-bar *ngIf="loading$ | async" mode="query" @fadeIn></mat-progress-bar>
    </div>


    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--Farmer name column -->
      <ng-container matColumnDef="farmer" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Farmer </th>
        <td mat-cell *matCellDef="let element">
          <span class="text-truncate product-name d-block">{{ element?.farmerDetails?.firstName }}</span>
        </td>
      </ng-container>
      <!--/ Farmer name column -->

      <!--Stock added on column -->
      <ng-container matColumnDef="stockAddedOn" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Added on </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.CT | date:'dd MMM yyyy'}}</span>
        </td>
      </ng-container>
      <!--/ Stock added on column -->

      <!-- Product column -->
      <ng-container matColumnDef="product" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Product </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.product?.productName }}</span>
        </td>
      </ng-container>
      <!-- / Product column -->

      <!--Price column -->
      <ng-container matColumnDef="price" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Price </th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element?.price | currency: '₹' }} </span>
        </td>
      </ng-container>
      <!--/ Price column -->

      <!-- Quantity column -->
      <ng-container matColumnDef="quantity" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Quantity </th>
        <td mat-cell *matCellDef="let element"> {{ element?.quantityValue | modernNumber }} {{ element?.quantityUnits }} </td>
      </ng-container>
      <!-- / Quantity column -->

      <!-- Settings column -->
      <ng-container matColumnDef="settings" sticky>
        <th mat-header-cell *matHeaderCellDef class="text-center" translate>Actions </th>
        <td mat-cell *matCellDef="let element">

          <div fxLayout="row" class="actions" fxLayoutAlign="center center">

            <button [matTooltip]="'Edit stock' | translate" mat-icon-button class="mb-0" (click)="editStock(element?.stockId)">
              <mat-icon>edit</mat-icon>
            </button>

            <button [matTooltip]="'Delete stock' | translate" mat-icon-button class="mb-0" (click)="deleteStock(element?.stockId)">
              <mat-icon>delete_outline</mat-icon>
            </button>

          </div>
        </td>
      </ng-container>
      <!-- / Settings column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr class="cursor-pointer" mat-row *matRowDef="let task; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions" ></mat-paginator>
  </mat-card>


  <!-- No content if there is no stocks -->
  <ng-container *ngIf="!dataSource.data.length">
    <div class="no-content-center w-100" fxLayout="row" fxLayoutAlign="center center">
      <kl-no-content svgIcon="assets/svgs/stocks.svg"
        text="No stocks added yet!"
        iconSize="md"
        actionLink="Create stock"
        progressText="Loading..."
        [progress]="progress"
        (action)="openAddStockDialog(pageId)">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there is no stocks -->

</div>

<!-- Show add button for mobile view -->
<div *ngIf="memberRole === memberRoles?.ADMIN || memberRole === memberRoles?.CONTRIBUTOR">

  <kl-float-button (clicked)="openAddStockDialog(pageId)"></kl-float-button>
</div>
<!--/ Show add button for mobile view -->
