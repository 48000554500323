<div class="banking-details">
  <form autocomplete="off" [formGroup]="fpoBankingDetailsForm">
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Bank name -->
        <div fxLayout="column" class="w-100 mb-2">
          <mat-form-field appearance="outline">
            <mat-label translate>Bank name</mat-label>
            <input matInput formControlName="bankName" [placeholder]="'Enter bank name' | translate" (keypress)="onKeyPress($event , 'alphanumericSpace')" maxlength="100">
          </mat-form-field>
        </div>

        <!-- Account number -->
        <div fxLayout="column" class="w-100 mb-2">
          <mat-form-field appearance="outline">
            <mat-label translate>Account number</mat-label>
            <input matInput formControlName="accountNumber" [placeholder]="'Enter account number' | translate" (keydown.space)="$event.preventDefault()" maxlength="18" (keypress)="onKeyPress($event)">
          </mat-form-field>
        </div>

        <!-- Account holder name -->
        <div fxLayout="column" class="w-100 mb-2">
          <mat-form-field appearance="outline">
            <mat-label translate>Account holder name</mat-label>
            <input matInput formControlName="accountHolderName" [placeholder]="'Enter account holder name' | translate">
          </mat-form-field>
        </div>

        <!-- Branch address -->
        <div fxLayout="column" class="w-100 mb-2">
          <mat-form-field appearance="outline">
            <mat-label translate>Branch address</mat-label>
            <input matInput formControlName="branchAddress" [placeholder]="'Enter branch address' | translate" maxlength="60" (keypress)="onKeyPress($event, 'alphanumericSpace')">
          </mat-form-field>
        </div>

        <!-- IFSC code -->
        <div fxLayout="column" class="w-100 mb-2">
          <mat-form-field appearance="outline">
            <mat-label translate>IFSC code</mat-label>
            <input class="text-uppercase" matInput formControlName="ifscCode" [placeholder]="'Enter IFSC code' | translate" (keydown.space)="$event.preventDefault()" maxlength="11" minlength="11" (keypress)="onKeyPress($event, 'alphaNumeric')" pattern="^[A-Za-z][A-Za-z0-9]*$">
          </mat-form-field>
        </div>

      </div>

      <div class="pt-2" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">
        <!-- Attach bank image -->
        <div fxLayout="column" fxLayoutAlign="center start">
          <!-- Image attachments -->
          <div *ngIf="bankAttachmentFormControl.value">
            <kl-image-chip-list [attachments]="bankAttachmentFormControl?.value" (remove)="removeImage()">
            </kl-image-chip-list>
          </div>
          <!--/ Image attachments -->

          <!-- Attach image -->
          <div>
            <button
            fxFlex="calc(35% - 8px)"
            mat-button
            class="p-0"
            id="attach-files-button"
            color="primary"
            [matMenuTriggerFor]="bank">
            <mat-icon>attachment</mat-icon>
            <span class="" translate>Upload bank passbook</span>
            </button>
            <mat-menu #bank="matMenu">
              <div mat-menu-item class="attach-file-mat-menu">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                  <div>
                    <p class="m-0 title">Image</p>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      [formControl]="bankAttachmentFormControl"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="false"
                      [showAttachments]="false"
                      [s3Category]="s3Category">
                    </kl-image-picker-form-control>
                  </div>
                  </div>
                </div>
              </mat-menu>
          </div>
          <!--/ Attach image -->
        </div>
        <!--/ Attach bank image -->

        <kl-flat-button
          type="submit"
          label= "Update"
          [progress]="progress"
          (clicked)="updateBankingDetails()"
          (disabled)="!fpoBankingDetailsForm?.valid">
        </kl-flat-button>
      </div>
    </div>
  </form>
</div>
