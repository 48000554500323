import { ElementRef, EventEmitter, Injector, Input, Output, ViewChild, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDealsWith, KalgudiUser, StoreBaseProductBasicDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';

import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';
import { KalgudiProductService } from '../services/kalgudi-product.service';


@Directive()
export abstract class KalgudiAddCrop extends KalgudiDestroyable {

  @ViewChild('autocompleteInput')
  private autocompleteInput: ElementRef;

  @Input()
  isNewProduct: boolean;

  @Input()
  profileKey: string;

  @Input()
  aka: string;

  @Input()
  public product: KalgudiDealsWith[];

  @Output()
  public dealWithAdded = new EventEmitter<KalgudiUser>();

  public addCropForm: FormGroup;

  public cropVarieties = [
    {id: 'MATAR_DAL', title: 'Matar Dal'},
    {id: 'URAD_DAL', title: 'Udar Dal'},
    {id: 'GREEN_MOONG', title: 'Green Moong'},
  ];

  public farmingFarmTypes = [
    {id: 'ORGANIC', value: 'Organic'},
    {id: 'CHEMICAL', value: 'Chemical'},
    {id: 'NATURAL', value: 'Natural'}
  ];

  public baseProductData: StoreBaseProductBasicDetails;

  public varietyList = [];

  public selectedVarieties = [];
  public searchedSelectedVarieties = [];

  public varietyProgress: boolean;
  public cropProgress: boolean;
  public varietiesSelected: number;

  searchControl = new FormControl({});

  filteredOptions: Observable<any[]>;

  isSearched: boolean;

  private fb: FormBuilder;
  private productService: KalgudiProductService;
  private util: KalgudiUtilityService;
  private farmerProfileService: KalgudiFarmerProfileService;
  private notification: KalgudiNotification;

  constructor(protected injector: Injector) {
    super();

    this.fb                   = this.injector.get(FormBuilder);
    this.productService       = this.injector.get(KalgudiProductService);
    this.util                 = this.injector.get(KalgudiUtilityService);
    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.addCropForm = this.newCropForm;
  }


  /**
   * Gets, the auto complete input form field
   */
  get inputFieldValue(): string {
    return this.searchControl.value;
  }

  get cropField(): AbstractControl {

    return this.addCropForm.get('crop');
  }

  /**
   * Gets the farming farm type from form
   */
  get farmingFarmTypeField(): AbstractControl {

    return this.addCropForm.get('farmingFarmType');
  }

  /**
   * Gets the since year from form
   */
  get sinceYearField(): AbstractControl {

    return this.addCropForm.get('sinceYear');
  }

  get finalizedVarities(): any[] {

    const varieties = [];
    this.selectedVarieties.forEach((v,index) => {
      if (this.selectedVarieties.indexOf(v) === index && v.selected) {
        varieties.push(v)
      }
    })

    return varieties;
  }

  toggleVarietySelection(index: number): void {

    this.selectedVarieties[index].selected = !this.selectedVarieties[index].selected;
  }

  removeVariety(index: number): void {

    this.selectedVarieties.splice(index, 1);

  }


  displayFn(cropDetails: any): string {
    return cropDetails && cropDetails.VProductName
      ? cropDetails.VProductName
      : '';
  }

  onCropSelection(cropDetails: any, isSearched: boolean): void {

    let isVarietyAdded: boolean;
    let isVarietySaved: boolean;

    if (this.product && this.product.length) {
      const selectedProducts = this.product.filter(p => p.productId === cropDetails.VProductId);

      isVarietySaved = selectedProducts.length ? true : false;

      if (isVarietySaved) {
        this.notification.showMessage(`${cropDetails.VProductName} is already added to your profile`);

        return;
      }

    }
    if(!isVarietySaved && isSearched) {

      const selectedProducts = this.selectedVarieties.filter(p => p.VProductId === cropDetails.VProductId);

      isVarietyAdded = selectedProducts.length ? true : false;

      if (isVarietyAdded) {
        this.notification.showMessage(`${cropDetails.VProductName} is already selected`);

        return;
      }
    }

    cropDetails.selected = !cropDetails.selected;

    if(!isSearched) {
      this.selectedVarieties.push(cropDetails)
    }else {
      this.selectedVarieties.push(cropDetails);
      this.searchedSelectedVarieties.push(cropDetails);
    }

    const varieties = [];
    this.selectedVarieties.forEach((v,index) => {
      if (this.selectedVarieties.indexOf(v) === index && v.selected) {
        varieties.push(v)
      }
    })

    this.varietiesSelected = varieties.length;

    if(isSearched) {

      this.searchControl.reset();
      this.autocompleteInput.nativeElement.blur();
    }


    this.isSearched = this.isSearched ? this.isSearched : isSearched;
  }


  public getProductVarieties(productId: string): void {

    this.selectedVarieties = [];
    this.varietyList = [];

    this.varietyProgress = true;
    this.productService.fetchProductVarieties(productId)
      .pipe(
        finalize(() => this.varietyProgress = false),
      )
      .subscribe(
        (res) => {
          this.varietyList = res;

          if (this.product && this.product.length) {

            this.product.forEach(p => {
              if(p.productId !== p.baseProductId) {

                this.varietyList.forEach((v, i) => {

                  if(p.productId === v.VProductId) {
                    // this.varietyList.splice(i, 1)
                    v.selected = true;
                  }
                })
              }
            })
          }

          // if (this.varietyList.length) {

          //   this.varietyList.forEach((v) => {
          //     v.selected = false;
          //   });
          // }


          this.filteredOptions = this.searchControl.valueChanges
          .pipe(
            startWith(''),
            map(value => value ? (typeof value === 'string' ? value : value.VProductName) : ''),
            map(name => name ? this._filter(name) : res.slice(4))
          );
        },
        (err) => this.util.errorHandler(err),
      );
  }

  public addProduct(): void {

    if (!this.isNewProduct && this.product && this.product[0].baseProductId && !this.finalizedVarities.length) {

      this.onProductAdded('Product already saved');
      return;
    }

    const reqObj = this.preparePayload();

    if(this.farmingFarmTypeField.value !== '') {
      reqObj[0].farmingFarmType = this.farmingFarmTypeField.value;
      reqObj[0].sinceYear = this.sinceYearField.value;
    }


    this.cropProgress = true;

    this.productService.postDealsWith(reqObj,this.profileKey)
    .pipe(
      finalize(() => this.cropProgress = false),
    )
    .subscribe(
      (res) => {
        this.onProductAdded('Product saved', res);
      },
      (err) => {
        // this.util.errorHandler(err);
        this.notification.showMessage('Something went wrong, Unable to add products')
        this.selectedVarieties = [];

      },
    );

}

  /**
   * Called after successful product addition
   */
  public onProductAdded(msg: string,res?: KalgudiUser): void {
    this.selectedVarieties = [];
    this.dealWithAdded.emit(res);
    this.notification.showMessage(msg);
  }

  /**
   * Form group for the specific page type
   */
  protected get newCropForm(): FormGroup {

    return this.fb.group({
      farmingFarmType: [''],
      sinceYear: [''],
      crop: [''],
    });
  }

  /**
   * Prepares payload
   */
  protected preparePayload(): KalgudiDealsWith[] {

    const payload: KalgudiDealsWith[] = [];

    // this.varietyList.forEach((s) => {

    //   if (s.selected) {
    //     this.selectedVarieties.push(s);
    //   }
    // });

    // this.selectedVarieties.push(this.varietyList);

    if (!this.finalizedVarities.length) {

      payload.push(this.prepareCrop(this.baseProductData, false));

    } else {

      this.finalizedVarities.forEach((v) => {
        payload.push(this.prepareCrop(v, true));
      });

    }

    return payload;
  }

  private prepareCrop(crop: any, isVarietyAvailable = false): any {

    // attachments: isVarietyAvailable
    //               ? crop.attachments || []
    //               : this.baseProductData.attachments && this.baseProductData.attachments.attachments || [],
    return {
      availableStock: {value: 0, unit: ''},
      productId: isVarietyAvailable ? crop.VProductId : this.baseProductData.productId,
      productName: isVarietyAvailable ? crop.VProductName : this.baseProductData.productName,
      attachments: isVarietyAvailable
        ? Array.isArray(crop.attachments) && crop.attachments.length ? crop.attachments : [{type: '', attachments: []}]
        : Array.isArray(this.baseProductData.attachments) && this.baseProductData.attachments.length ? this.baseProductData.attachments : [{type: '', attachments: []}],

      storeType: this.baseProductData.storeType,
      baseCategory: this.baseProductData.baseCategory,
      baseProductId: this.baseProductData.productId,
      baseProductName: this.baseProductData.productName,
      baseProductAttachments: Array.isArray(this.baseProductData.attachments) && this.baseProductData.attachments.length ? this.baseProductData.attachments : [{type: '', attachments: []}],
    };
  }


  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.varietyList.filter(option => option.VProductName.toLowerCase().indexOf(filterValue) === 0);
  }
}
