<form autocomplete="off" [formGroup]="creditTransferForm" class="p-3">
  <div fxLayout="row" fxLayoutGap="15px">
    <!-- transfer credits from -->
    <mat-form-field class="w-100 h-100" appearance="outline" fxFlex="50%">
      <mat-label>Transfer credits from</mat-label>
      <mat-select formControlName="creditFromField">
        <mat-option [value]="profileKey">
          {{loggedInUserName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- transfer credits to -->
    <mat-form-field class="w-100 h-100" appearance="outline" fxFlex="calc(50% - 15px)">
      <mat-label>Transfer credits to</mat-label>
      <mat-select formControlName="creditToField">
        <!-- <mat-option *ngFor="let type of searchTypeList" [value]="type?.id">
          {{type?.title}}
        </mat-option> -->
        <mat-option>test</mat-option>
      </mat-select>
      <mat-error *ngIf="creditTransferForm.get('creditToField')?.invalid">Please select program</mat-error>
    </mat-form-field>
  </div>

  <div class="w-100">
    <mat-form-field appearance="outline" fxFlex="50%">
      <mat-label>Enter credits to transfer</mat-label>
      <input matInput required formControlName="credit" type="number" placeholder="Enter credit to be transfer">
      <mat-error *ngIf="creditTransferForm.get('credit')?.invalid">Please enter valid amount</mat-error>
    </mat-form-field>
  </div>

  <!-- Add button -->
  <div fxLayoutAlign="end center">
    <div class="send-button pt-2 w-25">
      <kl-flat-button
      [type]="'button'"
      [label]="'Transfer'"
      [progress]="progress"
      [disabled]="creditTransferForm?.invalid"
      (clicked)="transferCredits()">
      </kl-flat-button>
    </div>
  </div>
</form>
