import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiUserTasksListApiService } from './kalgudi-user-tasks-list-api.service';

@Injectable()
export class KalgudiUserTasksListService {

  constructor(private api: KalgudiUserTasksListApiService) { }

  /**
   * Calls api method to get task list
   * @param projectId
   */
  getTasks(projectId: string, profileKey: string, filters: PartialData = {}): Observable<KalgudiStreamData> {
    return this.api.fetchStream(projectId, profileKey, filters);
  }

}
