import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiDealsWith, KalgudiUser } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiProfileRouteConfig } from '../../../config';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Directive()
export abstract class KalgudiCooperativeCropsList extends KalgudiDestroyable {

  isMobileDevice: boolean;

  @Input()
  profile: KalgudiUser;

  @Input()
  products: KalgudiDealsWith[];

  @Input()
  pageId: string;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  @Input()
  showViewCatalogue: boolean;

  @Output()
  editCrop = new EventEmitter<any>();

  @Output()
  deleteCrop = new EventEmitter<any>();

  @Output()
  addProductDialog = new EventEmitter();

  domain: string;
  editable: boolean;

  private appRouting: KalgudiProfileRouteConfig;
  public env: KalgudiEnvironmentConfig;
  private profileService: KalgudiProfileService;

  constructor(
    protected injector: Injector
  ) {
    super();

    this.appRouting     = this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
    this.env            = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.profileService = this.injector.get(KalgudiProfileService)

    this.isMobileDevice = checkMobileDevice();

    this.domain = this.env.coreDomain;

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );
  }


  /**
   * Opens crop fullview
   */
  opensCropFullview(cropId: string, crop: string, cropPageId: string) {

    if(!cropPageId) {

      return;
    }

    const cropTitle = crop.replace(/ /g, '_');

    const pageId = this.pageId;
    const isAssisted = this.isAssisted;
    const profileKey = this.assistedProfileKey;

    this.appRouting.toCropFullview({profileKey, cropId, cropTitle}, {cropPageId, pageId, isAssisted});


  }


  /**
   * Opens fpo crop seasons fullview
   */
  opensFpoCropSeasonsFullview(cropId: string, crop: string, cropPageId: string, addSeason?: boolean) {

    if(!cropPageId) {

      return;
    }

    const cropTitle = crop.replace(/ /g, '_');

    const pageId = this.pageId;
    const isAssisted = this.isAssisted;
    const profileKey = this.assistedProfileKey;

    this.appRouting.toFpoCropSeasons({profileKey, cropId, cropTitle}, {cropPageId, pageId, isAssisted, addSeason});

  }

  /**
   * Event emit back to parent for editing crop
   * @param product
   */
  editProduct(product: any) {
    this.editCrop.emit(product);
  }

  /**
   * Event emit back to parent to delete crop
   */
  deleteProduct(product: any) {
    this.deleteCrop.emit(product);
  }

  /**
   * Event emit back to parent to open add product dialog
   */
  addProduct() {
    this.addProductDialog.emit();
  }

  /**
   * Navigate to catalogue page
   */
  navigateToCataloguePage() {

    if (this.isAssisted) {

      this.appRouting.toSellCatalogue({}, {pageId: this.pageId, assistedProfileKey: this.assistedProfileKey, firstName: this.profile.firstName})

    } else if(!this.editable) {
      this.appRouting.toSellCatalogue({}, {pageId: this.pageId, assistedProfileKey: this.profile.profileKey, firstName: this.profile.firstName, hideActions: true})

    } else {
      this.appRouting.toSellCatalogue();
    }
  }

}
