import { Component, Inject, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { floatTopAnimation, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSurveyQuestionTypes } from '@kalgudi/types';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { surveyQuestionTypeLabelMap } from '../../../../constants';
import { KalgudiSurveySubmissionForm } from '../../classes/kalgudi-survey-submission-form';

@Component({
  selector: 'kl-survey-submission-form',
  templateUrl: './survey-submission-form.component.html',
  styleUrls: ['./survey-submission-form.component.scss'],
  animations: [floatTopAnimation]
})
export class SurveySubmissionFormComponent extends KalgudiSurveySubmissionForm implements OnInit, OnChanges {

  readonly questionTitleMap = surveyQuestionTypeLabelMap;
  readonly questionTypes = KalgudiSurveyQuestionTypes;

  constructor(
    private kalgudiApp: KalgudiAppService,
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
  ) {

    super(injector, notification, util);
  }

  ngOnInit() {

    this.updateDateLocale();

    if (!this.userProfileKey) {
      this.userProfileKey = this.kalgudiApp.profileLocal.profileKey;
    }

    this.initStream();
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.userProfileKey && this.userProfileKey) {
      this.resetStream();
    }
  }

  onDestroyed(): void {}

  private updateDateLocale(): void {
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((lang: LangChangeEvent) => this.dateAdapter.setLocale(lang.lang));
  }

}
