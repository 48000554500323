<div id="traceability-map" *ngIf="lat && lng" >
  <agm-map [latitude]="lat" [longitude]="lng">
    <agm-direction
      [waypoints]="wayPoints"
      [origin]="origin"
      [destination]="destination"
      [travelMode]="'DRIVING'"
      [renderOptions]="renderOptions"
      [markerOptions]="markerOptions"
      [infoWindow]>
    </agm-direction>
  </agm-map>
</div>