<section class="crop-calender mt-3">

  <form autocomplete="off"
    [ngClass]="{'col-10': !isMobileDevice}"
    class="col-10 mx-auto crop-info crop-form" [formGroup]="projectForm" fxLayout="column">

    <!-- Crop name -->
    <div>
      <kl-base-product-autocomplete formControlName="crop" [required]="true"></kl-base-product-autocomplete>
    </div>
    <!-- / Crop name -->

    <!-- Variety -->
    <div>

      <kl-base-product-autocomplete formControlName="variety"
        placeholder="Search and select product variety"
        label="Search and select product variety"
        [productType]="productTypes.VARIETY"
        [baseProduct]="cropFieldValue">
      </kl-base-product-autocomplete>
    </div>
    <!-- / Variety -->

    <!-- Date picker -->
    <div class="date-picker w-100" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="8px"
      fxLayoutAlign="space-between">
      <div class="text-area-wrapper" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
        <mat-form-field appearance="outline" class="w-100 project-form">
          <mat-label translate>Start duration</mat-label>
          <input class="picker-form" matInput [placeholder]="'Start duration' | translate"
            [matDatepicker]="startDate"
            (click)="startDate.open()"
            readonly required
            formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="text-area-wrapper" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
        <mat-form-field appearance="outline" class="w-100 project-form">
          <mat-label translate>End duration</mat-label>
          <input class="picker-form" matInput [placeholder]="'End duration' | translate"
            [matDatepicker]="endDate"
            (click)="endDate.open()"
            readonly required
            [min]="startDateField?.value"
            formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <!-- / Date picker -->

    <!-- Project duration -->
    <p class="project-duration pt-3 pb-1" *ngIf="projectDuration" fxLayout="row" fxLayoutAlign="end start">
      <strong><span translate>Project duration</span> {{projectDuration}} <span translate>days</span></strong>
    </p>

    <div formGroupName="seasonDetails">
      <div class="w-100">
        <mat-form-field class="h-100 selection w-100" matSuffix appearance="outline">
          <mat-label translate>Select season</mat-label>
          <mat-select label="Select season" [placeholder]="'Select season' | translate" formControlName="seasonTitle">
            <mat-option *ngFor="let season of seasonsList" [value]="season.id">
              {{season?.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Date picker -->
      <div class="date-picker w-100" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutGap.xs="8px"
        fxLayoutAlign="space-between">
        <div class="text-area-wrapper" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
          <mat-form-field appearance="outline" class="w-100 project-form">
            <mat-label translate>Season start date</mat-label>
            <input class="picker-form" matInput [placeholder]="'Season start date' | translate"
              [matDatepicker]="seasonStartDate"
              [required]="seasonTitle.value !== ''"
              (click)="seasonStartDate.open()"
              readonly
              formControlName="seasonStartDate">
            <mat-datepicker-toggle matSuffix [for]="seasonStartDate"></mat-datepicker-toggle>
            <mat-datepicker #seasonStartDate></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="text-area-wrapper" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
          <mat-form-field appearance="outline" class="w-100 project-form">
            <mat-label translate>Season end date</mat-label>
            <input class="picker-form" matInput [placeholder]="'Season end date' | translate"
              [matDatepicker]="seasonEndDate"
              [required]="seasonTitle.value !== ''"
              (click)="seasonEndDate.open()"
              readonly
              [min]="seasonStartDateField?.value"
              formControlName="seasonEndDate">
            <mat-datepicker-toggle matSuffix [for]="seasonEndDate"></mat-datepicker-toggle>
            <mat-datepicker #seasonEndDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <!-- / Date picker -->


      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxFlex="50%">
          <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
            <mat-label translate>Farmers</mat-label>
            <input matInput [placeholder]="'Enter farmers count' | translate" formControlName="farmers" type="number" [required]="seasonTitle.value !== ''">
            <mat-error translate>Enter only positive values</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="calc(50% - 10px)">
          <mat-form-field class="w-100 info-field mat-form-field-md" appearance="outline">
            <mat-label translate>Acres</mat-label>
            <input matInput [placeholder]="'Enter acres' | translate" formControlName="acres" type="number" [required]="seasonTitle.value !== ''">
            <mat-error translate>Enter only positive values</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxFlex="50%" fxLayout="row" formGroupName="expectedYield">
          <div fxFlex="60%" fxFlex.xs="60%">
            <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
              <mat-label translate>Expected yield</mat-label>
              <input matInput [placeholder]="'Enter expected yield' | translate" formControlName="value" type="number" [required]="seasonTitle.value !== ''">
              <mat-error translate>Enter only positive values</mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="40%" fxFlex.xs="40%">
            <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
              <mat-label translate>Select unit</mat-label>
              <mat-select appearance="outline" formControlName="unit" [required]="seasonTitle.value !== ''">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.value}}</mat-option>
              </mat-select>
              <mat-error translate>Please select unit</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxFlex="calc(50% - 10px)" fxLayout="row" formGroupName="actualYield">
          <div fxFlex="55%" fxFlex.xs="60%">
            <mat-form-field class="w-100 info-field mat-form-field-md no-right-border" appearance="outline">
              <mat-label translate>Actual yield</mat-label>
              <input matInput [placeholder]="'Enter actual yield' | translate" formControlName="value" type="number">
              <mat-error *ngIf="actualYield.get('value').hasError('pattern')">
                Enter only positive values
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="45%" fxFlex.xs="40%">
            <mat-form-field appearance="outline" class="w-100 mat-form-field-md no-left-border">
              <mat-label translate>Select unit</mat-label>
              <mat-select appearance="outline" formControlName="unit">
                <mat-option *ngFor="let unit of units" [value]="unit.id">{{unit.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>

  </form>
</section>
