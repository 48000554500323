<section class="org-page mb-3 page-creation-form">
  <div class="w-100 org col-7 mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="18px">

        <!-- Organization name -->
        <mat-form-field appearance="outline">
          <mat-label translate>Organization name</mat-label>
          <input matInput formControlName="organizationName" required/>
          <mat-error class="mt-3" *ngIf="organizationName?.errors?.required" translate>Please mention organization name</mat-error>
        </mat-form-field>
        <!-- /Organization name -->

        <!--Activities -->
        <mat-form-field appearance="outline">
          <mat-label translate>Activity</mat-label>
          <textarea rows="1" matInput formControlName="activity"></textarea>
        </mat-form-field>
        <!--/Activities -->

        <!-- Founded Year & Founders -->
        <div class="founder-date w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">

          <!-- Founded Year -->
          <mat-form-field class="date-picker" appearance="outline" fxFlex="calc(50% - 20px)">
            <input matInput
              [placeholder]="'Founded year' | translate"
              (click)="foundedYear.open()"
              [matDatepicker]="foundedYear"
              formControlName="foundedYear" readonly/>
            <mat-datepicker-toggle matSuffix [for]="foundedYear"></mat-datepicker-toggle>
            <mat-datepicker #foundedYear startView="year"></mat-datepicker>
          </mat-form-field>
          <!-- /Founded Year -->

          <!-- Founder -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Founders</mat-label>
            <input matInput [placeholder]="'Founders' | translate" formControlName="founders"/>
          </mat-form-field>
          <!-- /Founder -->

        </div>
        <!-- /Founded Year & Founders -->

        <!-- No of employees -->
        <mat-form-field appearance="outline">
          <mat-label translate>No of employees</mat-label>
          <mat-select formControlName="noOfEmployees">
            <mat-option *ngFor="let employees of employeesList" [value]="employees">{{ employees | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- /No of employees -->

        <!-- Vision -->
        <mat-form-field appearance="outline">
          <mat-label translate>Vision</mat-label>
          <textarea rows="1" matInput formControlName="vision"></textarea>
        </mat-form-field>
        <!-- /Vision -->

      </div>
    </form>
    <!-- / Wrapper Form -->
  </div>
</section>
