import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@app/env';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapHttpToHttpsService implements HttpInterceptor {

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let request = req.clone({
      url: !environment.production
      ? req.url.replace(/^(http|https)\:\/\/((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)/g, `${this.env.domain}`)
      : req.url
    });

    // Replace the http with https if its dev environment
    request = request.clone({
      url: !environment.production
        ? request.url.replace(/^http\:\/\//g, 'https://')   // Map http:// => https://
          .replace(/^https\:\/\/www\./g, 'https://')    // Remove www prefix
        : request.url
    });

    return next.handle(request);
  }
}
