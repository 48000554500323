<div class="task-info mt-2 overflow-hidden">
  <main class="mx-3 mt-1 main-tile" fxLayout="column">

    <!-- Description -->
    <div>
      <p class="m-0 headings" translate>Description</p>
      <span class="share-text" [innerHTML]="(taskDetails?.description | linkify)">
      </span>
    </div>
    <!-- / Description -->

    <div class="mt-2 mb-1" *ngIf="taskDetails?.isTaskDependentOnCropStage">
      <!-- <p class="m-0 headings" >
        This is task dependent on crop a stage
      </p> -->
      <p class="mb-0" translate>Starts
        <strong>
          <span> {{ taskDetails?.cropStageDependency?.from }} </span>
          <span *ngIf="taskDetails?.cropStageDependency?.to"> <span translate>to</span> {{ taskDetails?.cropStageDependency?.to }} </span>
          <span translate> days </span>
          <span *ngIf="taskDetails?.cropStageDependency?.taskTitle">
            <span translate> after </span> {{ taskDetails?.cropStageDependency?.taskTitle  | lowercase | trimSpecialCharacters }}
          </span>
          <span> {{ taskDetails?.cropStageDependency?.when | lowercase }} </span>
          <span> {{ taskDetails?.cropStageDependency?.cropStage | lowercase | trimSpecialCharacters }} </span>
        </strong>
      </p>
    </div>

    <!-- Tags -->
    <section *ngIf="false">
      <mat-accordion [multi]="true">
        <mat-expansion-panel [expanded]="expandInfo" class="mt-2" *ngIf="taskDetails?.tags?.info && taskDetails?.tags?.info?.length && taskDetails?.tags?.info[0]">
          <!-- Info tile -->
          <mat-expansion-panel-header>
            <div  fxLayout fxLayoutAlign="space-between center">
              <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
                <div>
                  <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
                    [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
                </div>

                <div>
                  <p class="m-0 title font-weight-bold" translate>Information</p>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="tags-tile-header mb-2"></div>

          <div>
            <div class="task-info-tile">
              <div *ngFor="let info of taskDetails?.tags?.info; let first = first; let last = last" class="px-2 pb-2" [ngClass]="{'pt-2': first, 'border-bottom mb-2': !last}">
                {{ info }}
              </div>
            </div>
          </div>
          <!--/ Info tile -->
        </mat-expansion-panel>

        <mat-expansion-panel class="mt-2 input-tile" [expanded]="expandInput" *ngIf="taskDetails?.tags?.input && taskDetails?.tags?.input?.length">
          <!-- Input tile -->
          <mat-expansion-panel-header>
            <div fxLayout fxLayoutAlign="space-between center">
              <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
                <div>
                  <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
                    [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
                </div>

                <div>
                  <p class="m-0 title font-weight-bold" translate>Input</p>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="tags-tile-header mb-2"></div>

          <div class="task-info-tile">
            <div class="p-2 font-weight-500 sub-header" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="4px">
              <p fxFlex="calc(25% - 4px)" fxFlex.xs class="my-0">Product</p>
              <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0">Doses per acreage</p>
              <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0">Quantity per acreage</p>
              <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0">No of hours per acre</p>
              <p fxFlex="calc(11% - 4px)" fxFlex.xs class="my-0">Cost</p>
              <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0">Need type</p>
            </div>
            <ng-container *ngFor="let input of taskDetails?.tags?.input; let first = first; let last = last">
              <div  *ngIf="input?.product?.productName || input?.dosesPerAcre?.value || input?.dosesPerAcre?.unit || input?.quantityPerAcre?.value || input?.quantityPerAcre?.unit || input?.noOfHourPerAcre || input?.cost || input?.needType" [ngClass]="{'pt-2': first, 'border-bottom mb-2': !last}"  class="px-2 pb-2" fxLayout="row"  fxLayoutGap="4px">
                <p fxFlex="calc(25% - 4px)" fxFlex.xs class="my-0">{{ input?.product?.productName ? input?.product?.productName : '-' }}</p>
                <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0"> {{ input?.dosesPerAcre?.value ? input?.dosesPerAcre?.value : '-' }} {{ input?.dosesPerAcre?.unit }}</p>
                <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0"> {{ input?.quantityPerAcre?.value ? input?.quantityPerAcre?.value : '-' }} {{ input?.quantityPerAcre?.unit }}</p>
                <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0"> {{ input?.noOfHourPerAcre ? input?.noOfHourPerAcre : '-' }}</p>
                <p fxFlex="calc(11% - 4px)" fxFlex.xs class="my-0"> {{ input?.cost ? input?.cost : '-' }}</p>
                <p fxFlex="calc(16% - 4px)" fxFlex.xs class="my-0"> {{ input?.needType ? input?.needType : '-' }}</p>
              </div>
            </ng-container>
          </div>
          <!--/ Input tile -->
        </mat-expansion-panel>

        <mat-expansion-panel class="mt-2" [expanded]="expandOutput" *ngIf="taskDetails?.tags?.output && taskDetails?.tags?.output?.length">
          <!-- Output tile -->
          <mat-expansion-panel-header>
            <div fxLayout fxLayoutAlign="space-between center">
              <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
                <div>
                  <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
                    [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
                </div>

                <div>
                  <p class="m-0 title font-weight-bold" translate>Output</p>
                </div>
              </div>
            </div>
          </mat-expansion-panel-header>

          <div class="tags-tile-header mb-2"></div>

          <div class="task-info-tile">
            <div class="p-2 font-weight-500 sub-header" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="4px">
              <p fxFlex="calc(60% - 4px)" fxFlex.xs class="my-0">Product</p>
              <p fxFlex="calc(20% - 4px)" fxFlex.xs class="my-0">Yield per acreage</p>
              <p fxFlex="calc(20% - 4px)" fxFlex.xs="50px" class="my-0">Cost</p>
            </div>

            <ng-container *ngFor="let output of taskDetails?.tags?.output; let first = first; let last = last">

              <div *ngIf="output?.product?.productName || output?.expectedYieldPerAcre?.value || output?.expectedYieldPerAcre?.unit || output?.cost"
                [ngClass]="{'pt-2': first, 'border-bottom mb-2': !last}" class="px-2 pb-2" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <p fxFlex="calc(60% - 4px)" fxFlex.xs class="my-0">{{output?.product?.productName ? output?.product?.productName : '-'}}</p>
                <p fxFlex="calc(20% - 4px)" fxFlex.xs class="my-0"> {{output?.expectedYieldPerAcre?.value ? output?.expectedYieldPerAcre?.value : '-'}} {{output?.expectedYieldPerAcre?.unit}}</p>
                <p fxFlex="calc(20% - 4px)" fxFlex.xs="50px" class="my-0"> {{output?.cost ? output?.cost : '-'}}</p>
              </div>
            </ng-container>
          </div>
          <!--/ Output tile -->
        </mat-expansion-panel>

        <!-- Services tile -->
        <!--<div class="tags-tile-design bg-white rounded mt-2" *ngIf="taskDetails?.tags?.services && taskDetails?.tags?.services?.length">
          <div class="tags-tile-header py-2 mb-2" fxLayout fxLayoutAlign="space-between center">
            <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
              <div>
                <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/services.svg"
                  [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
              </div>

              <div>
                <p class="m-0 title font-weight-bold" translate>Services</p>
              </div>
            </div>
          </div>
          <div class="task-info-tile">

            <div class="p-2 font-weight-500 sub-header" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="4px">
              <p fxFlex="calc(60% - 4px)" fxFlex.xs class="my-0">Product</p>
              <p fxFlex="calc(20% - 4px)" fxFlex.xs class="my-0">No of hours per acre</p>
              <p fxFlex="calc(20% - 4px)" fxFlex.xs="50px" class="my-0">Cost</p>
            </div>
            <ng-container *ngFor="let service of taskDetails?.tags?.services; let first = first; let last = last">
              <div *ngIf="service?.product?.productName || service?.noOfHourPerAcre || service?.cost"
                [ngClass]="{'pt-2': first, 'border-bottom mb-2': !last}" class="px-2 pb-2" fxLayout fxLayoutAlign="space-between center" fxLayoutGap="4px">
                <p fxFlex="calc(60% - 4px)" fxFlex.xs class="my-0">
                  <span *ngIf="service?.product?.productName">{{service?.product?.productName}}</span>
                  <span *ngIf="!service?.product?.productName">-</span>
                </p>
                <p fxFlex="calc(20% - 4px)" fxFlex.xs class="my-0">
                  <span>{{service?.noOfHourPerAcre ? service?.noOfHourPerAcre : '-'}}</span>
                </p>
                <p fxFlex="calc(20% - 4px)" fxFlex.xs="50px" class="my-0">
                  <span *ngIf="service?.cost">₹{{service?.cost}}</span>
                  <span *ngIf="!service?.cost">-</span>
                </p>
              </div>
            </ng-container>
          </div>
        </div>-->
        <!--/ Services tile -->
      </mat-accordion>
    </section>
    <!--/ Tags -->

    <!-- Task Details -->
    <kl-task-details [taskDetails]="taskDetails"></kl-task-details>
    <!--/ Task Details -->

    <!-- Ideal time -->
    <!-- <div>
      <p class="m-0 headings">Ideal time</p>
      <p class="m-0">Starts from
        <span>{{ taskDetails?.timeFrame?.start | date: 'dd MMM yyy' }}</span> ends on
        <span>{{ taskDetails?.timeFrame?.end | date: 'dd MMM yyy' }}</span>
      </p>
    </div> -->
    <!-- /Ideal time -->

  </main>

  <!-- Comment section -->
  <div class="w-75 mx-auto mt-3 mb-1 updates-form task-updates-form bg-white rounded" *ngIf="taskDetails?.userRole === memberRoles.ADMIN || taskDetails?.userRole === memberRoles?.CONTRIBUTOR">

    <!-- <kl-task-share-update-form
      [projectId]="taskDetails?.projectId"
      [taskId]="taskDetails?.taskId"
      [activityTypes]="activityType.ADMIN_UPDATES" >
    </kl-task-share-update-form> -->

    <mat-tab-group class="updates-products-tab" [(selectedIndex)]="activeTabIndex">
      <mat-tab [label]="TABS?.UPDATES?.title | translate">
        <div class="pt-2 pb-3" *ngIf="activeTabIndex === TABS?.UPDATES?.index">
          <kl-task-share-update-form
            [projectId]="taskDetails?.projectId"
            [taskId]="taskDetails?.taskId"
            [activityTypes]="activityType.ADMIN_UPDATES" >
          </kl-task-share-update-form>
        </div>
      </mat-tab>

      <mat-tab [label]="TABS?.PRODUCTS?.title | translate">
        <div class="pt-2 pb-3" *ngIf="activeTabIndex === TABS?.PRODUCTS?.index">
          <kl-task-promotional-share-update-form
            [pageId]="pageId"
            [projectId]="taskDetails?.projectId"
            [taskId]="taskDetails?.taskId"
            [activityTypes]="activityType.ADMIN_UPDATES">
          </kl-task-promotional-share-update-form>
        </div>
      </mat-tab>
    </mat-tab-group>

    <!-- Updates stream -->
    <div class="updates-stream">
      <kl-task-updates-stream
        [taskId]="taskDetails?.taskId"
        [activityTypes]="activityType.ADMIN_UPDATES"
        [projectId]="taskDetails?.projectId">
      </kl-task-updates-stream>
    </div>
    <!--/ Updates stream -->

  </div>
  <!-- / Comment section -->

</div>
<!-- <div>
  <p class="m-0 headings">More details</p>

  <form autocomplete="off" class="mb-4 pr-3 mt-3 common-info overflow-auto task-fill"
    [formGroup]="templateForm" *ngIf="fields" fxLayout="column" fxLayoutGap="10px">
    <formly-form
      [fields]="fields"
      [options]="options"
      [form]="templateForm"
      [model]="taskDetails?.creationTemplateData"
      ></formly-form>
  </form>
</div> -->
