import { Injector, Directive } from '@angular/core';
import { CreditType } from '@kalgudi/types';
import { finalize } from 'rxjs/operators';

import { KalgudiCreditDetails } from './kalgudi-credit-details';


@Directive()
export abstract class KalgudiCreditSettings extends KalgudiCreditDetails {

  progress: boolean;

  // Disable flag for saving setting changes
  isSettingsChange = false;

  constructor(
    protected injector: Injector
  ) {
    super(injector);
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * create and update the credit setting details from service
   */
  createAndUpdateCredit() {

    const payload = this.preparePayload();
    this.progress = true;

    this.creditService.createAndUpdateCredit(payload).
      pipe(finalize(() => this.progress = true))

      .subscribe(
        res => this.onCreditUpdate(res),
        err => {
          this.notifications.showMessage('Unable to update SMS settings, please try again later.');
        }
      );
  }



  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------


  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Preparing payload for create and update credit settings
   */
  private preparePayload() {

    this.creditDetails.availableCredits = 0;

    const request = {
      domesticSms: this.smsSettings.domesticSms,
      internationalSms: this.smsSettings.internationalSms,
      domesticVoiceCall: this.smsSettings.domesticVoiceCall || false,
      internationalVoiceCall: this.smsSettings.internationalVoiceCall || false,
      authorId: this.creditorId,
      creditType: CreditType.USER_CREDIT // PROGRAM_CREDIT
    };

    return request;
  }

  /**
   * credit settings updated successfully
   */
  private onCreditUpdate(res) {
    this.isSettingsChange = false;
    this.creditDetails = res;
    this.initiateCredit(this.creditDetails);
    this.notifications.showMessage('SMS settings updated successfully');
  }
  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------
}
