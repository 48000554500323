import { Component, Inject, OnInit } from '@angular/core';
import { KL_MOBILE_DIALOG_DATA, KalgudiMobileDialog, KalgudiMobileDialogRef, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-business-activities-mobile-dialog',
  templateUrl: './business-activities-mobile-dialog.component.html',
  styleUrls: ['./business-activities-mobile-dialog.component.scss']
})
export class BusinessActivitiesMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  basicActivityFormDetails: any;
  profileKey: string;
  pageId: any;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
  ) {
    super(dialogRef);

    this.profileKey = this.dialogData.data.profileKey;
    this.basicActivityFormDetails = this.dialogData.data.basicActivityFormDetails;
    this.pageId = this.dialogData.data.pageId;
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }


  ngOnInit(): void { }

}
