<div class="add-cattle">
  <form autocomplete="off" [formGroup]="addAwardForm" >
    <div fxLayout="column">

      <div fxLayout="column">

        <!-- Title -->
        <div fxLayout="column" fxFlex="50%">
          <mat-form-field appearance="outline">
            <mat-label translate>Title</mat-label>
            <input matInput required formControlName="title" [placeholder]="'Enter award title' | translate" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')">
            <mat-error translate>Please enter the title</mat-error>
          </mat-form-field>
        </div>

        <!-- University -->
        <div fxLayout="column" class="w-100">
          <mat-form-field appearance="outline">
            <mat-label translate>Institute/University</mat-label>
            <input matInput required formControlName="description" [placeholder]="'Enter institute/university name' | translate" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')">
            <mat-error translate>Please enter the institute/university name</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="pt-2" fxLayout="row" fxLayoutAlign="space-between end" fxLayoutGap="10px">

        <!-- Attach bank image -->
        <div fxLayout="column" fxLayoutAlign="center start">
          <!-- Image attachments -->
          <div *ngIf="attachmentsField.value">
            <kl-image-chip-list [attachments]="attachmentsField?.value" (remove)="removeImage($event)">
            </kl-image-chip-list>
          </div>
          <!--/ Image attachments -->

          <!-- Attach image -->
          <div>
            <button
            fxFlex="calc(35% - 8px)"
            mat-button
            class="p-0"
            id="attach-files-button"
            color="primary"
            [matMenuTriggerFor]="awards">
            <mat-icon>attachment</mat-icon>
            <span class="" translate>Upload award</span>
            </button>
            <mat-menu #awards="matMenu">
              <div mat-menu-item class="attach-file-mat-menu">
                <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                  <div>
                    <p class="m-0 title">Image</p>
                  </div>
                  <div>
                    <kl-image-picker-form-control
                      formControlName="attachments"
                      [showBorder]="false"
                      [buttonType]="'mat-icon-button'"
                      svgButtonIcon="assets/svgs/attach-file/images.svg"
                      [allowMultiple]="false"
                      [showAttachments]="false"
                      [s3Category]="s3Category">
                    </kl-image-picker-form-control>
                  </div>
                  </div>
                </div>
              </mat-menu>
          </div>
          <!--/ Attach image -->
        </div>
        <!--/ Attach bank image -->

        <kl-flat-button
          type="submit"
          label= "{{ awardId ? 'Update Award' : 'Add Award' }}"
          [progress]="progress"
          (clicked)="saveOrUpdateAward()"
          [disabled]="addAwardForm?.invalid">
        </kl-flat-button>
      </div>

    </div>

  </form>

</div>
