<div class="autocomplete product-autocomplete w-100">

  <form autocomplete="off" class="w-100" autocomplete="off" [formGroup]="autoCompleteForm">

    <div class="w-100" fxLayout fxLayoutAlign="start center">

      <div class="w-100">
        <mat-form-field [appearance]="appearance" class="w-100 no-left-border">
          <mat-label translate>{{label}}</mat-label>

          <input type="text" matInput formControlName="productName"
            [placeholder]="placeholder | translate"
            [matAutocomplete]="autoComplete" [required]="required">

          <span matSuffix *ngIf="loading$ | async">
            <span class="spinner-border spinner-border-lg text-muted" role="status"></span>
          </span>


          <mat-autocomplete autoActiveFirstOption class="product-list" #autoComplete="matAutocomplete" [displayWith]="displayWithFn">
            <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" (onSelectionChange)="selectItem($event)">
              <div fxLayout="row" fxLayoutAlign="start center" class="mw-100">
                <!-- <mat-icon class="text-success">eco</mat-icon> -->

                <div class="products">
                  <span class="d-block autocomplete-field text-truncate product-name">{{prediction?.productName}}</span>
                  <span class="d-block autocomplete-field text-truncate product-name also-known" *ngIf="prediction?.nameInOtherLanguage">Also known as {{prediction?.nameInOtherLanguage}}</span>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
          <button id="search-icon-btn" mat-button matSuffix mat-icon-button type="submit" *ngIf="!(loading$ | async)">
            <mat-icon class="search">search</mat-icon>
          </button>
          <mat-error *ngIf="required && inputField?.touched" translate class="product-mat-error">Product is required</mat-error>
        </mat-form-field>

        <p class="no-crop-error text-danger" *ngIf="noPrediction && inputFieldValue?.length > 2" @fadeIn>
          <span translate>No crop found with keyword </span>
          <strong>"{{ inputFieldValue }}"</strong>
        </p>
      </div>

    </div>

  </form>

</div>

