<div id="stock-location-mobile">

  <!-- kl-geo-location -->
  <div class="p-3">
    <kl-geo-location [LabelPositionTop]="true" [showLatLong]="true" [location]="data?.data?.locationDetails">
    </kl-geo-location>
  </div>
  <!--/ kl-geo-location -->

</div>
