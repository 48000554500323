import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ActivityType, ApiResponseCommon, KalgudiProjectTask, PartialData, TaskStateUpdateRequest } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiTasksApiService {


  /**
   * `v2/projects`
   */
  private readonly API_PROJECTS = `${this.env.restBaseUrlV2}/projects`;

  /**
   * `v2/projects/tasks
   */
  private readonly API_TASKS = `${this.API_PROJECTS}/tasks`;
  /**
   * /v2/projects/tasks/:taskId
   */
  private readonly API_TASK = `${this.API_TASKS}/:taskId`;

  /**
   * /v2/projects/activities
   */
  private readonly API_TASK_ACTIVITIES = `${this.API_PROJECTS}/activities`;

  /**
   * `v2/projects/activities/:activityId
   */
  private readonly API_TASK_ACTIVITY = `${this.API_TASK_ACTIVITIES}/:activityId`;

  /**
   * `v2/projects/members/:taskId
   */
  private readonly API_TASK_MEMBER_STATS = `${this.API_PROJECTS}/members/:taskId`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService
  ) { }


  /**
   * Call api to get task full details
   * @taskId
   */
  getTaskFullDetails(taskId: string) {
    const url = this.API_TASK.replace(':taskId', taskId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Update the task
   * @param taskId
   * @param payload
   */
  updateTaskDetails(taskId: string, payload: KalgudiProjectTask | TaskStateUpdateRequest): Observable<KalgudiProjectTask> {

    const url = this.API_TASK.replace(':taskId', taskId);

    const params = {
      action: 'STATE_UPDATE'
    };

    return this.httpClient.put<ApiResponseCommon>(url, payload, {params})
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response
        map(r => r.data)
      );
  }

  /**
   * Deletes the task
   */
  deleteTask(taskId: string, filters: PartialData = {}) {
    const url = this.API_TASK.replace(':taskId', taskId);

    const params = {
      ...filters
    };

    return this.httpClient.delete<ApiResponseCommon>(url, { params })
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

  /**
   * Fetch task updates
   * @param offset
   * @param limit
   * @param activityType COMMENT | TASK_SUBMISSION
   */
  getActivities(
    activityType: string,
    taskId: string,
    offset?: number,
    limit?: number,
    profileKey = '',
    submissionId = '',
    keyword = '',
    extraParams: PartialData = {}
  ): Observable<KalgudiStreamData> {

    const params = {
      activityType,
      taskId,
      offset: offset ? offset.toString() : '',
      limit: limit ? limit.toString() : '',
      profileKey,
      submissionId,
      keyword,
      ...extraParams
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_TASK_ACTIVITIES, { params })
      .pipe(
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi task updates object type
        map(r => {
          return {
            items: r.data.results,
            count: r.data.count
          };
        })

      );
  }

   /**
    * Calls api to get the activity details
    * @param projectId
    * @param taskId
    * @param activityType COMMENT | TASK_SUBMISSION
    * @param activityId
    */
  getActivityDetails(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    activityId: string
    ) {

    const params = {
      taskId,
      projectId,
      activityType
    };

    return this.httpClient.get<ApiResponseCommon>(this.API_TASK_ACTIVITY.replace(':activityId', activityId), { params })
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),

        // Map API response
        map(r => this.util.toJson<any>(r.data))
      );
  }

  /**
   * Creates the activity
   * @param projectId
   * @param taskId
   * @param activityType COMMENT | TASK_SUBMISSION
   * @param payload
   */
  createActivity(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    payload: any,
    submissionId= '',
    profileKey= '',
    action= '',
    isBulk?: boolean,
  ): Observable<any> {

    const params = {
      taskId,
      projectId,
      activityType,
      submissionId,
      profileKey,
      action,
      isBulk: isBulk ? isBulk.toString() : ''
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_TASK_ACTIVITIES, payload, { params })
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response
        map(r => this.util.toJson<any>(r.data))
      );
  }


  /**
   * Updates the activity
   * @param projectId
   * @param taskId
   * @param activityType COMMENT | TASK_SUBMISSION
   * @param activityId
   * @param payload
   */
  updateActivity(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    activityId: string,
    payload: any,
    profileKey= ''): Observable<any> {

    const params = {
      taskId,
      projectId,
      activityType,
      profileKey
    };

    return this.httpClient.put<ApiResponseCommon>(this.API_TASK_ACTIVITY.replace(':activityId', activityId), payload, { params })
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response
        map(r => this.util.toJson<any>(r.data))
      );
  }


  deleteActivity(
    projectId: string,
    taskId: string,
    activityType: ActivityType,
    activityId: string
    ): Observable<any> {

    const params = {
      taskId,
      projectId,
      activityType
    };

    return this.httpClient.delete<ApiResponseCommon>(this.API_TASK_ACTIVITY.replace(':activityId', activityId), { params })
      .pipe(

        // Check for API response errors
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.ACCEPTED)),

        // Map API response
        map(r => this.util.toJson<any>(r.data))
      );
  }

  /**
   * Call api to get task member stats
   * @taskId
   */
  getTaskMemberStats(taskId: string) {
    const url = this.API_TASK_MEMBER_STATS.replace(':taskId', taskId);

    return this.httpClient.get<ApiResponseCommon>(url)
      .pipe(

        // Api response error handler
        map(res => this.util.apiErrorHandler(res)),

        // No errors, all good return true
        map(res => res.data),
      );
  }

}
