import { Injectable } from '@angular/core';
import { EmployeeProfileTo, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { EmployeeProfileApiService } from './employee-profile-api.service';

@Injectable()
export class EmployeeProfileService {


  // params: PartialData = {
  //   pageId: '',
  //   assistedTo: ''
  // };

  constructor(
    private api: EmployeeProfileApiService,
    private profileService: KalgudiProfileService,
    private profileState: KalgudiProfileStateService,
  ) {

    // if (this.profileState.pageId) {
    //   this.params.pageId = this.profileState.pageId;
    //   this.params.assistedTo = this.profileState.id;
    // } else {
    //   this.params = {};
    // }
   }

  /**
   * Call api method for employee details
   */
  saveEmployeeDetails(payload: EmployeeProfileTo): Observable<KalgudiUser> {

    return this.api.addEmployeeDetails(payload)
      .pipe(
        switchMap(res => this.profileService.updateProfileAndState(res.profileKey))
      );
  }
}
