<!-- Search bar -->
<div class="mb-3" fxLayoutAlign="center center">
  <kl-search class="search w-100"
    placeHolder="Type and hit enter to search members"
    (search)="search();"
    [searchForm]="searchForm">
  </kl-search>
</div>
<!-- /Search bar -->

<div id="project-list-table">

  <mat-card [@fadeIn] class="p-0">

    <table mat-table [dataSource]="dataSource" matSort class="w-100 list-table">

      <!--Member name column -->
      <ng-container matColumnDef="memberName" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Member name </th>
        <td mat-cell *matCellDef="let element"> {{ element?.memberName }} </td>
      </ng-container>
      <!--/ Member name column -->

      <!-- Projects count column -->
      <ng-container matColumnDef="projects" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Projects </th>
        <td mat-cell *matCellDef="let element"> {{ element?.projects }} </td>
      </ng-container>
      <!-- / Projects count column -->

      <!--Projects in progress count column -->
      <ng-container matColumnDef="inProgress" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> In Progress </th>
        <td mat-cell *matCellDef="let element"> {{ element?.inProgress }} </td>
      </ng-container>
      <!--/ Projects in progress count column -->

      <!-- projects completed count column -->
      <ng-container matColumnDef="completed" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Completed </th>
        <td mat-cell *matCellDef="let element"> {{ element?.completed }} </td>
      </ng-container>
      <!-- / projects completed count column -->

      <!-- Performance column -->
      <ng-container matColumnDef="performance" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header translate> Performance </th>
        <td mat-cell *matCellDef="let element"> {{ element?.performance }} </td>
      </ng-container>
      <!-- / Performance column -->

      <!-- Settings column -->
      <ng-container matColumnDef="settings" sticky>
        <th mat-header-cell *matHeaderCellDef translate> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button class="setting-icon">
            <mat-icon>{{element?.icon}}</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- / Settings column -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </mat-card>

</div>
