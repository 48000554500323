import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { KalgudiAiFilter } from '../../classes/ai-filters';

@Component({
  selector: 'kl-ai-filters',
  templateUrl: './ai-filters.component.html',
  styleUrls: ['./ai-filters.component.scss']
})
export class AiFiltersComponent extends KalgudiAiFilter implements OnInit, OnChanges {

  @Input()
  label: string;

  @Input()
  searchPlaceholder: string;

  @Input()
  prefilledData: any;

  @Input()
  resetField: boolean;

  constructor() {
    super();
  }

  ngOnInit() { }

  ngOnChanges() {
    if(this.prefilledData) {

      this.formGroup.get('searchText').patchValue(this.prefilledData)
    }

    if(this.resetField) {
      this.formGroup.get('searchText').patchValue('');
    }
  }
}
