import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { DynamicHostDirective } from '../../../../directives/dynamic-host.directive';
import { KalgudiMobileDialogRef } from '../../classes/mobile-dialog-overlay-ref';
import { KL_MOBILE_DIALOG_DATA, KL_MOBILE_DIALOG_DESC, KL_MOBILE_DIALOG_TITLE } from '../../config';
import { MobileDialogContainer } from '../../models';


// Reusable animation timings
const ANIMATION_TIMINGS = '400ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'kl-mobile-dialog-container',
  templateUrl: './kalgudi-mobile-dialog-container.component.html',
  styleUrls: ['./kalgudi-mobile-dialog-container.component.scss'],
  animations: [
    trigger('slideContent', [
      state('void', style({ transform: 'translateY(100%)', opacity: 0.3 })),
      state('enter', style({ transform: 'none', opacity: 1 })),
      state('leave', style({ transform: 'translateY(100%)', opacity: 0.3 })),
      transition('* => *', animate(ANIMATION_TIMINGS)),
    ])
  ]
})
export class KalgudiMobileDialogContainerComponent implements OnInit, MobileDialogContainer {

  animationState: 'void' | 'enter' | 'leave' = 'enter';

  animationStateChanged = new EventEmitter<AnimationEvent>();

  @ViewChild(DynamicHostDirective, { static: true })
  private klDynamicHost: DynamicHostDirective;


  constructor(
    private dialogRef: KalgudiMobileDialogRef,
    @Inject(KL_MOBILE_DIALOG_TITLE) public title: string,
    @Inject(KL_MOBILE_DIALOG_DESC) public dialogDescription: string,
    @Inject(KL_MOBILE_DIALOG_DATA) public data: any,
    public viewContainerRef: ViewContainerRef,
  ) { }

  ngOnInit() { }

  get contentViewContainerRef(): ViewContainerRef {
    return this.klDynamicHost.viewContainerRef;
  }

  /**
   * Functionality defined by the `KalgudiMobileDialogService`
   *
   * @see KalgudiMobileDialogService.instantiateMobileDialogMethods()
   */
  closeDialog = (data?: any) => {};

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = 'leave';
  }
}
