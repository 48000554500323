import { Injector, Directive } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUsersPickerService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import {
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiUsersPickerDialogConfig,
  PageType,
  ProcessingPageDetails,
  StockPageDetails,
  StockTotalQuantity,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiCreatePageType } from './kalgudi-create-page-type';

@Directive()
export abstract class KalgudiCreateProcessing extends KalgudiCreatePageType<ProcessingPageDetails> {

  stockPage = new FormControl('');

  totalQuantity: StockTotalQuantity;

  units = [
    'Kgs',
    'Quintals',
    'Tons'
  ];

  private usersPickerService: KalgudiUsersPickerService;

  constructor(
    protected injector: Injector,
    protected fb: FormBuilder
  ) {
    super(fb);
    this.usersPickerService = this.injector.get<KalgudiUsersPickerService>(KalgudiUsersPickerService);

    this.stockPage.valueChanges.subscribe(
      (res: StockPageDetails) => {

        this.totalQuantity = res ? res.totalQuantity : null;

        this.stockPageDetailsField.patchValue(res ? {pageTitle: res.pageTitle, pageId: res.pageId} : '');
        this.stockProductDetailsField.patchValue(res ? res.product : null);

        if (res && res.totalQuantity) {
          this.quantityField.enable();
        } else {
          this.quantityField.disable();
        }
      }
    );


    /**
     * Waits till the form gets initialized
     */
    setTimeout(() => {
      this.initProcessingForm();
    }, 1000);

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  get processorsField(): FormArray {

    return this.pageForm.get('processors') as FormArray;
  }

  get stockPageDetailsField(): AbstractControl {

    return this.pageForm.get('stockPageDetails');
  }

  get stockProductDetailsField(): AbstractControl {
    return this.pageForm.get('stockProductDetails');
  }


  get quantityField(): AbstractControl {

    return this.pageForm.get('quantity');
  }

  get unitsField(): AbstractControl {

    return this.pageForm.get('units');
  }

  get pageFormControls(): any {

    return this.pageForm.controls;
  }

  /**
   * Form group for the specific page type
   */
  protected get pageTypeForm(): FormGroup {

    return this.fb.group({
      processors: this.fb.array([]),
      stockPageDetails: ['', Validators.required],
      stockProductDetails: [''],
      quantity: [{ value: '', disabled: true }, Validators.required],
      units: ['', Validators.required],
    });
  }

  /**
   * Returns the page type
   */
  protected get pageType(): PageType {
    return 'PROCESSING';
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  removeProcessor(i) {
    this.processorsField.removeAt(i);
  }

  initProcessingForm() {

    this.quantityField.valueChanges
    .pipe(
      takeUntil(this.destroyed$),

      // filter(_ => !this.editing)
    )
    .subscribe(res => {
      if (this.totalQuantity && this.totalQuantity.kgs && res > this.totalQuantity.kgs) {

        this.quantityField.setErrors({incorrect: true});
      } else {
      }

    });
  }

  /**
   * Adds a user to the stock. Displays a Kalgudi user picker dialog.
   * Finally calls the add api to add the user.
   *
   */
  pickFarmer(): void {

    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Select users to add',
      acceptButtonTitle: 'Select users',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(r => r.accepted),

        map(res => Object.values(res.data)),

        filter(r => r.length > 0)

      )
      .subscribe(

        users => {

          users.forEach(u => {

            this.processorsField.push(this.initUserFormGroup(u));
          });

        }

      );
  }

  initUserFormGroup(value): FormGroup {

    return this.fb.group({
      businessKey: value.businessKey,
      businessTypeName: value.businessTypeName,
      firstName: value.firstName,
      latLong: value.latLong,
      location: value.location,
      profileKey: value.profileKey,
      profilePicURL: value.profilePicURL,
      profilePicUrl: value.profilePicUrl
    });
  }


  protected openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return this.usersPickerService.showKalgudiUsersPicker(details, config)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }

  protected openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult> {
    return this.usersPickerService.showMobileUsersPicker(details)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }


  /**
   * Shows users picker for mobile and web based on the device or screen size.
   */
  private showUsersPicker(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileUserPickerDialog(dialogConfig)
      : this.openUsersPickerDialog(dialogConfig, matDialogConfig);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
