import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

import { SatelliteAdvisoriesService } from '../satellite-advisories.service';

@Component({
  selector: 'kl-soil-moisture-details',
  templateUrl: './soil-moisture-details.component.html',
  styleUrls: ['./soil-moisture-details.component.scss']
})
export class SoilMoistureDetailsComponent implements OnInit, OnChanges {

  @ViewChild('satImgSoilMoisture', { static: true })
  satImgRef: ElementRef;

  @Input()
  soilMoistureDetails: any;

  @Input()
  hideGauge: boolean;

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  bbox: number[];

  @Input()
  soilMoistureImage: string;

  @Input()
  estimatedTime: string;

  boundaryPoints: string = '';

  showMap: boolean = false;

  status: 'low' | 'good' | 'more';

  soilMoisturePerHectare: number;
  soilMoisturePerAcre: number;
  soilMoisturePerFeddan: number;

  constructor(private satelliteAdvisoriesService: SatelliteAdvisoriesService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {

    if (this.landDetails && this.bbox && !this.boundaryPoints) {

      setTimeout(() => {

        this.boundaryPoints = this.satelliteAdvisoriesService.convertLatLongToPoints(
          this.landDetails.geoFences,
          this.satImgRef.nativeElement.offsetWidth,
          this.satImgRef.nativeElement.offsetHeight,
          this.bbox
        );
      }, 500);
    }

    // if (changes.soilMoistureDetails && this.soilMoistureDetails) {
    //   this.calculateStatus();
    // }
  }

  showLandMap() {
    console.log('inn')
    this.showMap = !this.showMap;
  }

  private calculateStatus() {
    this.soilMoisturePerHectare = this.soilMoistureDetails.averageLevel;
    this.soilMoisturePerAcre = this.soilMoisturePerHectare * 2.47105;
    this.soilMoisturePerFeddan = this.soilMoisturePerAcre * 1.03;

    // console.log(this.soilMoistureDetails, this.soilMoisturePerHectare, this.soilMoisturePerAcre, this.soilMoisturePerFeddan);

    if (this.soilMoisturePerFeddan < 70) {
      this.status = 'low';
    } else if (this.soilMoisturePerFeddan > 80) {
      this.status = 'more';
    } else {
      this.status = 'good';
    }
  }
}
