import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { KalgudiDialogsModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MAT_INDIAN_DATE_FORMAT } from '@kalgudi/core';
import { KalgudiGoogleGeoLocationModule } from '@kalgudi/third-party/google-geo-location';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { GeotagConfirmDialogComponent } from './components/geotag-confirm-dialog/geotag-confirm-dialog.component';
import {
  ProfileBasicDetailsEditDialogComponent,
} from './components/profile-basic-details-edit-dialog/profile-basic-details-edit-dialog.component';
import {
  ProfileBasicDetailsEditMobileDialogComponent,
} from './components/profile-basic-details-edit-mobile-dialog/profile-basic-details-edit-mobile-dialog.component';
import {
  ProfileBasicDetailsEditComponent,
} from './components/profile-basic-details-edit/profile-basic-details-edit.component';
import {
  UserMobileNumberUpdateDialogComponent,
} from './components/user-mobile-number-update-dialog/user-mobile-number-update-dialog.component';
import { UserMobileNumberUpdateComponent } from './components/user-mobile-number-update/user-mobile-number-update.component';
import { EditCommonProfileDetailsDialogService } from './services/edit-common-profile-details-dialog.service';



@NgModule({
  declarations: [
    ProfileBasicDetailsEditComponent,
    ProfileBasicDetailsEditDialogComponent,
    ProfileBasicDetailsEditMobileDialogComponent,
    UserMobileNumberUpdateDialogComponent,
    UserMobileNumberUpdateComponent,
    GeotagConfirmDialogComponent
  ],
  imports: [
    CommonModule,

    AngularSvgIconModule,

    // Material
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDividerModule,
    MatTabsModule,
    MatCheckboxModule,
    MomentDateModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiGooglePlacesModule,
    KalgudiButtonModule,
    KalgudiDialogsModule,
    KalgudiGoogleGeoLocationModule,
    TranslateModule
  ],
  exports: [
    ProfileBasicDetailsEditComponent,
  ],
  entryComponents: [
    ProfileBasicDetailsEditDialogComponent,
    ProfileBasicDetailsEditMobileDialogComponent,
    UserMobileNumberUpdateDialogComponent,
    GeotagConfirmDialogComponent
  ],
  providers: [
    EditCommonProfileDetailsDialogService,
    DatePipe,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ]
})
export class ProfileBasicEditModule { }
