import { Inject, Directive } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserMembershipDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { KalgudiProfileSectionList } from '../../../classes/kalgudi-profile-section-list';
import { KalgudiProfileMembershipsService } from './kalgudi-profile-memberships.service';

@Directive()
export abstract class ProfileSectionMembership extends KalgudiProfileSectionList<KalgudiUserMembershipDetails> {

  protected itemForm: FormGroup;
  protected existingItemFormGroup: FormGroup;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected app: KalgudiAppService,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProfileMembershipsService,
  ) {

    super(notification, app, util);

    // Initialize item form
    this.itemForm = this.formGroup;
  }

  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Profile memberships form group structure.
   *
   * **NOTE** Must define all fields of the profile list section type,
   * whether its shown in the view or not.
   */
  private get formGroup(): FormGroup {

    return new FormGroup({
      Flag: new FormControl(true),
      businessMembershipId: new FormControl(''),
      membershipType: new FormControl('', Validators.required),
      membershipName: new FormControl('', Validators.required),
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Implement the logic to check if the specified item in the profile section
   * list is new or existing item. An item in the list is new if it does not
   * contain any id otherwise its existing item.
   */
  protected isNewItem(item: KalgudiUserMembershipDetails): boolean {

    return this.util.isNullOrEmpty(item.businessMembershipId);
  }

  /**
   * Defines save new item Api.
   */
  protected saveNewItemApi(payload: KalgudiUserMembershipDetails): Observable<KalgudiUserMembershipDetails> {

    return this.api.saveNewMembership(payload)
      .pipe(
        mapTo(payload),
      );
  }

  /**
   * Defines update membership Api.
   */
  protected updateItemApi(payload: KalgudiUserMembershipDetails): Observable<KalgudiUserMembershipDetails> {
    return this.api.updateMembership(payload)
      .pipe(
        mapTo(payload),
      );

  }

  /**
   * Deletes an item from the profile section items list.
   *
   * @param item Item to delete
   */
  protected deleteItemApi(item: KalgudiUserMembershipDetails): Observable<boolean> {

    return this.api.deleteMembership(item.businessMembershipId);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
