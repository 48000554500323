import { Directive } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PartialData } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';

import { KalgudiStreamData } from '../typings';
import { KalgudiMatTableStream } from './kalgudi-mat-table-stream';


/**
 * Extension class for material table. Use this class for a searchable material table.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiMatTableStream
 */
@Directive()
export abstract class KalgudiMatTableSearchStream<T> extends KalgudiMatTableStream<T> {

  /**
   * Search form group
   */
  readonly searchForm: FormGroup;

  /**
   * Stream of search keywords
   */
  protected searchKeywordSubject = new BehaviorSubject<string>(null);

  /**
   * Extra search params that must be passed to the Api
   */
  protected extraSearchParams: PartialData;

  minSearchLength = 3;

  constructor() {
    super();

    // Initialize search form
    this.searchForm = new FormGroup({
      searchKeyword: new FormControl(''),
      searchType: new FormControl(''),
    });
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets, the search keyword
   */
  get searchKeyword(): string {
    return this.searchForm.get('searchKeyword').value;
  }

  /**
   * Gets, the search type field in the search form.
   */
  get searchTypeField(): AbstractControl {
    return this.searchForm.get('searchType');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------




  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Performs a search operation on to the table
   */
  search(): void {

    if (this.searchKeyword.length <= this.minSearchLength) {
      return;
    }

    // Reset the existing table
    this.resetTable();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Fetches stream items from the Kalgudi API.
   */
  protected abstract streamApi(offset: number, limit: number): Observable<KalgudiStreamData>;
  protected abstract streamApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    searchKeyword?: string,
    params?: PartialData
  ): Observable<KalgudiStreamData>;

  /**
   * Initializes the table stream. It initializes the material table with MatPaginator and
   * MatSort.
   *
   * Feed the references of `MatPaginator` and `MatSort` to the method in order to provide
   * pagination and sorting capability to the material table.
   *
   * @override
   */
  protected initTableStream(paginator?: MatPaginator, sort?: MatSort, allowMultiSelect = true, params: PartialData = {}): void {

    super.initTableStream(paginator, sort, allowMultiSelect, params);

    // Link the data source streamApi$ function reference.
    // streamApi$ function gets called on every page, sort value changes.
    // It overrides the base implementation
    this.dataSource.streamApi$ = (offset, limit, sortBy, sortDirection, params) => this.searchApi(offset, limit, sortBy, sortDirection, params);
  }

  /**
   * Calls the streamApi with the specified search keyword.
   */
  private searchApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    params?: PartialData
  ): Observable<KalgudiStreamData> {

    return this.streamApi(offset, limit, sortBy, sortDirection, this.searchKeyword, params);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
