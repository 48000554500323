import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialRouteConfig } from '@kalgudi/social';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiFarmerLand } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiFarmerLandList } from '../../classes/kalgudi-farmer-land-list';
import { LandDetailsDialogComponent } from '../land-details-dialog/land-details-dialog.component';
import { LandDetailsMobileDialogComponent } from '../land-details-mobile-dialog/land-details-mobile-dialog.component';
import { SoilHealthCardDialogComponent } from '../soil-health-card-dialog/soil-health-card-dialog.component';
import {
  SoilHealthCardMobileDialogComponent,
} from '../soil-health-card-mobile-dialog/soil-health-card-mobile-dialog.component';

@Component({
  selector: 'kl-farmer-lands-list',
  templateUrl: './farmer-lands-list.component.html',
  styleUrls: ['./farmer-lands-list.component.scss']
})
export class FarmerLandsListComponent extends KalgudiFarmerLandList implements OnInit {

  isMobileDevice: boolean;

  isLandTiles: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialogsService: KalgudiDialogsService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig,
    private dialogService: KalgudiDialogsService,
  ) {

    super(injector, notification, util, dialogsService);

    this.isMobileDevice = checkMobileDevice();
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Navigates to share update fullview
   */
  toShareUpdateFullview(shareId: string) {
    if (shareId) {
      this.appRouting.toSocialShareUpdateFullview({ shareId });
    }
  }

  viewMoreTiles() {
    this.isLandTiles = !this.isLandTiles;
  }

  /**
   * Open edit land details dialog when the response will have data
   *
   * @param landDetails
   */
  landDetailsDialog(landDetails: KalgudiFarmerLand) {
    this.showLandDetailsDialog(landDetails)
    .subscribe(
      res => {
        if(res && res.data.isEditLand){
          this.editLandDetailsDialog(landDetails);
        }

        if(res && res.data.isSeasonEdit) {
        }
      }
    )
  }

  /**
   * Shows land details dialog
   */
  showLandDetailsDialog(landDetails: KalgudiFarmerLand) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: landDetails.landName ?   landDetails.landName : 'Land details',
      dialogDescription: landDetails.location ? landDetails.location.locationLong : '',
      data: {
        landDetails,
        assistedProfileKey: this.assistedProfileKey,
        isAssisted: this.isAssisted,
        pageId: this.pageId,
        hideActions: false,
        showDownloadButton: false
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'land-details',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        landDetails,
        assistedProfileKey: this.assistedProfileKey,
        isAssisted: this.isAssisted,
        pageId: this.pageId
      }
    };

    return this.openLandDetailsDialog(dialogDetails, dialogConfig)
      // .pipe(

      //   filter(r => r.accepted),

      //   map(r => r.accepted),

      // );
  }

  /**
   * Open board of members dialog
   */
  private openLandDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(LandDetailsMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(LandDetailsDialogComponent, dialogConfig, matDialogConfig);

    }

  }

  /**
   * Shows soil health card dialog
   */
  showSoilHealthCardDialog(landDetails: KalgudiFarmerLand) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Soil health card',
      data: {
        soilHealthCard: landDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '750px',
      height: 'calc(100vh - 50px)',
      panelClass: 'soil-health-card',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        soilHealthCard: landDetails
      }
    };

    return this.openSoilHealthCardDialog(dialogDetails, dialogConfig)
      // .pipe(

      //   filter(r => r.accepted),

      //   map(r => r.accepted),

      // );
  }

  /**
   * Open soil health card dialog
   */
  private openSoilHealthCardDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(SoilHealthCardMobileDialogComponent, dialogConfig);

    } else {
      return this.dialogService.openDialog(SoilHealthCardDialogComponent, dialogConfig, matDialogConfig);
    }
  }

}
