import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { KalgudiDestroyable, KalgudiFormValidators, KalgudiUtilityService } from '@kalgudi/core';
import {
  KalgudiEnvironmentConfig,
  KalgudiNotification,
  KL_ENV,
  KL_NOTIFICATION,
  KL_ROUTE_CONFIG,
} from '@kalgudi/core/config';
import { KalgudiPageAddNewUserPayload } from '@kalgudi/types';
import { finalize, first, takeUntil } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { KalgudiPageMembersService } from '../../../services/kalgudi-page-member.service';
import { ProgramStateService } from '../../../services/program-state.service';

@Directive()
export abstract class KalgudiPageAddNewUsers extends KalgudiDestroyable {

  @Output()
  addedMembers = new EventEmitter();

  @Output()
  selectedUsersList = new EventEmitter<any[]>();

  addUsersForm: FormGroup;
  userType = new FormControl('');

  professionsList = [
    {
      profileTypeId: 'PT000001',
      profileTypeName: 'Farmer',
      businessTypeId: 'BT000002',
      businessTypeName: 'Farmer',
      nativeBusinessTypeId: 'BT000002',
      nativeBusinessTypeName: 'Farmer'
    },
    {
      profileTypeId: 'PT000003',
      profileTypeName: 'Employee',
      businessTypeId: 'BT000017',
      businessTypeName: 'Employee',
      nativeBusinessTypeId: 'BT000017',
      nativeBusinessTypeName: 'Employee'
    },
    {
      profileTypeId: 'PT000004',
      profileTypeName: 'I run a Business',
      businessTypeId: 'BT000019',
      businessTypeName: 'A business in retail trade',
      nativeBusinessTypeId: 'BT000037',
      nativeBusinessTypeName: 'Retail Trade'
    },
    {
      profileTypeId: 'PT000004',
      profileTypeName: 'I run a Business',
      businessTypeId: 'BT000019',
      businessTypeName: 'A business in bulk trade',
      nativeBusinessTypeId: 'BT000054',
      nativeBusinessTypeName: 'Dealer'
    }
  ];

  pageId: string;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private programStateService: ProgramStateService;
  private pageMembersService: KalgudiPageMembersService;
  private notification: KalgudiNotification;
  public env: KalgudiEnvironmentConfig;
  private appRouting: KalgudiCommonRouteConfig;
  pageTitle: string;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.fb                   = this.injector.get(FormBuilder);
    this.util                 = this.injector.get(KalgudiUtilityService);
    this.programStateService  = this.injector.get(ProgramStateService);
    this.pageMembersService   = this.injector.get(KalgudiPageMembersService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.env                  = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.appRouting           = this.injector.get<KalgudiCommonRouteConfig>(KL_ROUTE_CONFIG);

    this.addUsersForm = this.addNewUsersForm;

    //this.type === 'mobile' ? this.mobileNoField.setValidators([Validators.required, ...KalgudiFormValidators.mobileValidators]) : this.emailIdField.setValidators([Validators.required, ...KalgudiFormValidators.emailValidators]);
    this.env.appId !== 'GEOAGROPRO' ? this.authGoogleLocationTo.setValidators([Validators.required]) : this.authGoogleLocationTo.setValidators([]);

    // Get entity data for pageId and pageTitle
    this.programStateService.data$
      .pipe(
        first(),
      ).subscribe(data => {
        this.pageId = data.pageId,
        this.pageTitle = data.pageTitle

      });

    this.subscribeToUserTypeChanges();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Get, the Mobile number from add new user form
   */
  get mobileNoField(): AbstractControl {
    return this.addUsersForm.get('mobileNumber');
  }

  /**
   * Get, the Email id from add new user form
   */
  get emailIdField(): AbstractControl {
    return this.addUsersForm.get('email');
  }

  /**
   * Get google location to from add new user form
   */
  get authGoogleLocationTo(): AbstractControl {
    return this.addUsersForm.get('googleLocationTo');
  }

  /**
   * Get gender from add new user form
   */
  get genderField(): AbstractControl {
    return this.addUsersForm.get('gender');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Calls method to add new user
   */
  addMembers() {

    const payload = this.preparedPayload(this.addUsersForm.value);


    // Check for valid location
    if (!this.isValidLocation(payload)) {

      const errorMessage = 'Please select proper village/town/street';

      this.notification.showMessage(errorMessage);
      throw new Error(errorMessage);
    }

    this.notification.showSpinner();

    if(this.env.appId === 'GEOAGROPRO') {
      payload.sourceFrom = 'M01q40j0PRFREG2020113047851505UNH001'; // ICARDA
    }

    this.pageMembersService.addNewUser(payload)
      .pipe(
        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onAddUseSuccess(res),
        err => this.onAddUserError(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Form group for adding new users
   */
  private get addNewUsersForm(): FormGroup {

    return this.fb.group({

      googleLocationTo: [{}],
      mobileNumber: [''],
      alternateEmail: ['', [...KalgudiFormValidators.emailValidators]],
      email: [''],
      name: [''],
      gender: [''],
    });
  }

  /**
   * Event handler for adding users successful response.
   */
  protected onAddUseSuccess(res: any): void {
    const newUserDetails = {
      businessKey: res.lstOfUserBusinessDetailsInfo[0].businessKey,
      businessTypeName: res.lstOfUserBusinessDetailsInfo[0].businessTypeName,
      distance: res.distance,
      firstName: res.firstName,
      latLong: res.location,
      location: res.lstOfUserBusinessDetailsInfo[0].locationTo.locationLong,
      profileKey: res.profileKey,
      profilePicUrl: res.profilePicUrl,
      locationDetails: res.lstOfUserBusinessDetailsInfo[0].locationTo
    }

    if(res.isExists === true) {
      this.notification.showMessage("Added existing user to the page");
    }else {
      this.notification.showMessage('Added new user to the page');
    }

    // Update program state
    this.programStateService.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATE);

    let userList = [];
    userList.push(newUserDetails)

    this.selectedUsersList.emit(userList);

    this.addedMembers.emit();

    this.appRouting.toProfile({profileKey: res.profileKey}, {pageId: this.pageId, isAssisted: true });
  }

  /**
   * Event handler for adding users API errors.
   */
  protected onAddUserError(err: Error): void {
    this.util.showApiErrorMessage(err);
  }

  /**
   * Prepares payload to add new users to page
   */
  protected preparedPayload(formValue: any): KalgudiPageAddNewUserPayload {

    if (this.env.appId === 'GEOAGROPRO') {
      formValue.googleLocationTo =  {
        locality: "Location yet to be set",
        adminLevel2:"Cairo",
        adminLevel1:"Cairo Governorate",
        countryName:"Egypt",
        latitude:"30.0444196",
        longitude:"31.2357116"
      }
    }

    const payload: KalgudiPageAddNewUserPayload = this.util.clone({
      ...formValue
    });

    payload.businessTypeId         = this.professionsList[0].businessTypeId;
    payload.businessTypeName       = this.professionsList[0].businessTypeName;
    payload.profileTypeId          = this.professionsList[0].profileTypeId;
    payload.profileTypeName        = this.professionsList[0].profileTypeName;
    payload.nativeBusinessTypeId   = this.professionsList[0].nativeBusinessTypeId;
    payload.nativeBusinessTypeName = this.professionsList[0].nativeBusinessTypeName;
    payload.pageId                 = this.pageId;
    payload.mobileTelecomCode      = '+91';

    if (this.env?.appId === 'SAM_FPO' || this.env?.appId === 'SAM_FARMER') {
      payload.pageTitle = this.pageTitle;
    }

    return payload;
  }

  /**
   * Checks if the location field is valid or not while adding user location.
   */
  protected isValidLocation(payload: KalgudiPageAddNewUserPayload): boolean {

    return (!payload.googleLocationTo) || (payload.googleLocationTo
      && !!payload.googleLocationTo.adminLevel1
      && !!payload.googleLocationTo.adminLevel2
      && !!payload.googleLocationTo.countryName
      && !!payload.googleLocationTo.latitude
      && !!payload.googleLocationTo.longitude
      && !!payload.googleLocationTo.locality
      );
  }

  /**
   * Subscribes to user type value changes
   */
  protected subscribeToUserTypeChanges(): void {

    if (!this.userType) {
      throw new Error('User type not defined');
    }

    // Subscribe to user type value changes
    this.userType.valueChanges
      .pipe(

        // Subscribe till the instance is alive
        takeUntil(this.destroyed$)
      )
      .subscribe(_ => this.onUserTypeChange());
  }

  /**
   * Update the selected user type field value
   */
  private onUserTypeChange(): void {

    // Update validators
    this.updateUserTypeValidators();

    this.userType.value === 'mobile' ? this.emailIdField.patchValue('') : this.mobileNoField.patchValue('');
  }

  /**
   * Updates add user form type validators.
   */
  private updateUserTypeValidators(): void {

    // Clear existing validators
    this.userType.value === 'mobile' ? this.emailIdField.clearValidators() : this.mobileNoField.clearValidators();

    // Update the new validator
    this.userType.value === 'mobile' ? this.mobileNoField.setValidators([Validators.required, ...KalgudiFormValidators.mobileValidators]) : this.emailIdField.setValidators([Validators.required, ...KalgudiFormValidators.emailValidators]);
    this.userType.value === 'mobile' ? this.mobileNoField.updateValueAndValidity() : this.emailIdField.updateValueAndValidity();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
