import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Attachment, KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

import { KalgudiDialog } from '../../../kalgudi-dialogs/classes/kalgudi-dialog';

@Component({
  selector: 'kl-capture-image-dialog',
  templateUrl: './capture-image-dialog.component.html',
  styleUrls: ['./capture-image-dialog.component.scss']
})
export class CaptureImageDialogComponent extends KalgudiDialog<CaptureImageDialogComponent> implements OnInit {

  attachment: Attachment;

  constructor(
    protected dialogRef: MatDialogRef<CaptureImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {

    super(dialogRef);

  }

  ngOnInit() {
  }

  onImageCaptured(attachment: Attachment): void {

    this.attachment = attachment;
    this.ok();
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        attachment: this.attachment
       }
    };
    this.dialogRef.close(result);
  }
}

