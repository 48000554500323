import { Inject, Injector, Input, Directive } from '@angular/core';
import { KalgudiSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KalgudiTaskSubmissionDaService } from '../services/kalgudi-task-submission-da.service';


@Directive()
export abstract class KalgudiTaskMembersListDa extends KalgudiSearchStream<KalgudiUser>{

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  loggedInUserTasks: boolean;

  @Input()
  membersStatus: string;

  private taskSubmissionDaService: KalgudiTaskSubmissionDaService;
  memberCount: number;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected injector: Injector,
  ) {
    super(notification,util);

    this.taskSubmissionDaService = this.injector.get(KalgudiTaskSubmissionDaService);
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods for children
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Gets the list of tasks members
   */
  protected searchApi(searchKeyword: string, offset: number, limit: number): Observable<KalgudiStreamData> {

    if (this.loggedInUserTasks) {
      this.projectId = '';
    }

    if (searchKeyword) {

      return this.taskSubmissionDaService.searchTaskMembersList(searchKeyword, offset, limit, this.projectId, this.taskId, this.membersStatus);
    } else {

      return this.taskSubmissionDaService.getTaskMembersList(offset, limit,  this.taskId, this.membersStatus)
        .pipe(
          tap(res => {
              this.memberCount = res.count;
          }
        ))
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
