import { AgmMap, ControlPosition, ZoomControlOptions, ZoomControlStyle } from '@agm/core';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { LatLong } from '@kalgudi/types';
import { Subject } from 'rxjs';

@Component({
  selector: 'kl-geo-location',
  templateUrl: './geo-location.component.html',
  styleUrls: ['./geo-location.component.scss']
})
export class GeoLocationComponent implements OnInit, OnChanges, OnDestroy  {

  @ViewChild(AgmMap) agmMap: AgmMap;

  @Input()
  location: LatLong;

  @Input()
  LabelPositionTop = false;

  @Input()
  zoomControlOptions: ZoomControlOptions = {
    style: ZoomControlStyle.SMALL,
    position: ControlPosition.RIGHT_BOTTOM
  };

  @Input()
  mapTypeId: 'roadmap' | 'hybrid' | 'satellite' | 'terrain' | string = 'roadmap';

  @Input()
  zoom: number = 17;

  @Input()showLatLong: boolean = true;

  @Output()
  geoTagChanged = new EventEmitter<LatLong>();


  /**
   * Flag sets to `true` if the map has been initialized.
   */
  // isMapInitialized = false;

  private destroyedSubject = new Subject();


  private watchNumber: number;
  error: string;

  latitude: string | number;
  longitude: string | number;

  constructor() {
  }

  ngOnInit() {
    if ((this.location && this.location.latitude) || (this.location && this.location.longitude)) {
      this.location = {
        lat: this.location.latitude,
        lng: this.location.longitude,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
      };
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if ((this.location && this.location.latitude) || (this.location && this.location.longitude)) {
      this.location = {
        lat: this.location.latitude,
        lng: this.location.longitude,
        latitude: this.location.latitude,
        longitude: this.location.longitude,
      };

      this.roundOfForLatLng(this.location);

      this.geoTagChanged.emit(this.location);

    }
  }

  /**
   * Rounding of upto 6 number after decimal for latitude and longitude
   * @param location
   */
  roundOfForLatLng(location: LatLong) {
    const latitude = +location.latitude;
    this.latitude = (latitude).toFixed(6);
    const longitude = +location.longitude;
    this.longitude = (longitude).toFixed(6);
  }

  ngOnDestroy(): void {
    // Clear polygon event listeners if any

    this.destroyedSubject.next();
    this.destroyedSubject.complete();

    if (navigator) {
      navigator.geolocation.clearWatch(this.watchNumber);
    }
  }
}
