import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { KalgudiAppService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize } from 'rxjs/operators';

import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';
import { KalgudiProgramListService } from '../services/kalgudi-program-list.service';

@Component({
  selector: 'kl-program-clone',
  templateUrl: './program-clone.component.html',
  styleUrls: ['./program-clone.component.scss']
})
export class ProgramCloneComponent implements OnInit {

  @Input()
  pageId: string;

  @Output()
  closeDialog = new EventEmitter();

  pageCloneForm: FormGroup;
  progress: boolean;

  constructor(
    private fb: FormBuilder,
    private kalgudiAppService: KalgudiAppService,
    private kalgudiProgramListService: KalgudiProgramListService,
    @Inject(KL_NOTIFICATION) private kalgudiNotification: KalgudiNotification,
    private programStateService: ProgramStateService
  ) {
    this.pageCloneForm = this.newPageCloneForm;
  }

  ngOnInit() {
  }

  /**
   * Getter for page title
   */
  get pageTitle(): AbstractControl {
    return this.pageCloneForm.get('pageTitle');
  }

  /**
   * Initializing form
   */
  private get newPageCloneForm() {
    return this.fb.group({
      pageTitle: [''],
      withMembers: [true],
      profileKey: [this.kalgudiAppService.profileLocal.profileKey]
    })
  }

  /**
   * Calls method to clone page
   */
  clonePage() {
    this.progress = true;

    const payload = this.pageCloneForm.value;

    this.kalgudiProgramListService.clonePage(payload, this.pageId)
      .pipe(
        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.kalgudiNotification.showMessage('Cloned page successfully');
          this.closeDialog.emit();
          this.programStateService.dispatchAction(PageActions.PAGE_CLONED);
        },
        err => {
          this.kalgudiNotification.showMessage('Unable to clone page, Please try again later!');
        }
      )
  }

}
