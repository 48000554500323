<div class="about-tile">
  <mat-card class="about-info">
    <form autocomplete="off" name="profileForm" [formGroup]="profileForm">
      <div formGroupName="basicDetails">

        <!-- About us header -->
        <kl-common-profile-header
          title="About Us"
          svgIcon="assets/svgs/worldwide.svg"
          [editable]="editable"
          (action)="updateAboutYourself()">
        </kl-common-profile-header>
        <!--/ About us header -->

        <!-- About us info -->
        <div class="w-100">
          <div>
            <kl-fpo-over-view></kl-fpo-over-view>
          </div>

          <!-- Textarea for update about yourself -->
          <!-- <div class="about-yourself" *ngIf="showAboutUsFiled">

            <kl-fpo-over-view-form (closedForm)="cancelAddingAboutYourself()"></kl-fpo-over-view-form>

          </div> -->
          <!--/ Textarea for update about yourself -->

        </div>

      </div>
      <!--/ About us info -->


      <!-- No content if there no about yourself -->
      <!--<ng-container *ngIf="!profile?.aboutMe && !showAboutUsFiled">
        <div class="w-100" fxLayoutAlign="center center">
          <kl-no-content
            [text]="editable ? '' : 'About yourself not added yet!'"
            [actionLink]="editable ? 'Write about yourself' : ''"
            (action)="updateAboutYourself()">
          </kl-no-content>
        </div>
      </ng-container>-->
      <!--/ No content if there no about yourself -->
    </form>

  </mat-card>

</div>
