<div class="task-creation">
  <!-- stepper -->
  <div class="mx-auto stepper-data">
    <kl-stepper>
      <mat-horizontal-stepper labelPosition="bottom" [linear]="true"
        ngClass="{{ 'last-edited-step-' + stepper.selectedIndex }}" #stepper>

        <mat-step>

          <ng-template matStepLabel><span translate>Choose template</span></ng-template>
          <div class="p-3 select-card">
            <kl-select-template [taskType]="templateType" (templateSelected)="templateType = $event" [taskId]="taskId"></kl-select-template>
          </div>

          <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="end center">

            <!-- Next page button -->
            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()" [disabled]="!templateType">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span translate>Next</span>
                  <div fxLayoutAlign="center end">
                    <mat-icon class="mr-n2">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
            </div>
            <!-- / Next page button -->
          </div>

        </mat-step>


        <mat-step>

          <ng-template matStepLabel><span translate>Task details</span></ng-template>
          <div class="p-3 select-card">

            <div class="step-container px-3" fxLayout>
              <kl-common-task-form class="w-100"
                [projectId]="projectId"
                [taskDetails]="taskDetails"
                [templateSchema]="templateSchema"
                [(taskForm)]="commonTaskForm">
              </kl-common-task-form>

            </div>
          </div>

          <div class="buttons mt-4" fxLayout="row" fxLayoutAlign="space-between center">

            <div>
              <button mat-stroked-button (click)="previousState()"><span translate>Back</span></button>
            </div>

            <!-- Next page button -->
            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" [disabled]="commonTaskForm?.invalid" (click)="nextStep()">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span translate>Next</span>
                  <div fxLayoutAlign="center end">
                    <mat-icon class="mr-n2">chevron_right</mat-icon>
                  </div>
                </div>
              </button>
            </div>
            <!-- / Next page button -->
          </div>

        </mat-step>

        <mat-step>

          <ng-template matStepLabel><span translate>Template preview</span></ng-template>

          <h3 class="text-center text-black-50 preview-title h5" translate>How users will see the task to fill!</h3>
          <!-- specific form -->
          <div class="step-container column">
            <kl-detailed-task-form
              [detailedFormData]="creationTemplateDataField?.value"
              [templateSchema]="templateSchema"
              [(taskForm)]="detailedTaskForm">
            </kl-detailed-task-form>

            <!-- Tags form -->
            <!-- <div class="mt-n3">
              <kl-task-tags-form
                (schemaChanged)="updateSchema($event)">
              </kl-task-tags-form>
            </div> -->
            <!-- / Tags form -->
          </div>
          <!-- / Specific form -->
          <div class="buttons  mt-4" fxLayout="row" fxLayoutAlign="space-between center">
            <!-- Back Button -->
            <div>
              <button mat-stroked-button (click)="previousState()"><span translate>Back</span></button>
            </div>
            <!-- / Back Button -->

            <!-- Next page button -->
            <div>

              <kl-flat-button *ngIf="!taskId" label="Create" [disabled]="!templateSchema" [progress]="progress" (clicked)="nextStep()"></kl-flat-button>
              <kl-flat-button *ngIf="taskId" label="Update" [disabled]="!templateSchema" [progress]="progress" (clicked)="nextStep()"></kl-flat-button>
              <!-- <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span>Create</span>
                  <div fxLayoutAlign="center end">
                    <mat-icon class="mr-n2">chevron_right</mat-icon>
                  </div>
                </div>
              </button> -->
            </div>
            <!-- / Next page button -->
          </div>

        </mat-step>
<!--
        <mat-step>

          <ng-template matStepLabel>Add members</ng-template>

          <div class="step-container">
            Add members
          </div>

          <div class="buttons col-6 mx-auto mt-4" fxLayout="row" fxLayoutAlign="center center">
            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span>Add</span>
                </div>
              </button>
            </div>
          </div>
        </mat-step>

        <mat-step>

          <ng-template matStepLabel>Completed</ng-template>

          <div class="step-container">
            Task has been created successfully
          </div>

          <div class="buttons col-6 mx-auto mt-4" fxLayout="row" fxLayoutAlign="center center">

            <div>
              <button class="kl-icon-link-btn" mat-raised-button color="primary" (click)="nextStep()">

                <div class="icon-label" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                  <span>OK</span>
                </div>
              </button>
            </div>
          </div>
        </mat-step> -->

      </mat-horizontal-stepper>
    </kl-stepper>

  </div>
</div>
