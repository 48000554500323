<div class="p-0 chat-footer">
  <form autocomplete="off" [formGroup]="chatForm">
    <div class="share-update-form p-2">

      <div>
        <!-- Form field -->
        <div fxLayout="column" class="text-area-wrapper" (click)="$event.stopPropagation()">

          <!-- Reply to  -->
          <div class="specific-message" *ngIf="replyMessage?.message">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="85%">
                <p class="m-0 person text-truncate">{{replyMessage?.sender.firstName}}</p>
                <p class="m-0 message text-truncate">{{replyMessage?.message}}</p>
              </div>
              <div>
                <button mat-icon-button (click)="removeReplyMsg()">
                  <mat-icon>close</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <!-- /Reply to -->

          <mat-form-field appearance="outline" class="update-form w-100" (click)="expandBox()">
            <textarea matInput [placeholder]="'Reply' | translate" [rows]="rowsCount" formControlName="message"></textarea>
          </mat-form-field>
        </div>
        <!-- / Form field -->
        <div fxHide.gt-xs *ngIf="showBtns || replyMessage?.message">

          <kl-upload-image-compact-view
            [showAddOnLast]="true"
            [accept]="acceptedFileTypes | joinArray: ','"
            [maxImages]="3"
            [multiple]="true"
            [showDisabledFrames]="true"
            [s3Category]="s3Category"
            [showCamera]="isMobileDevice"
            formControlName="attachments">
          </kl-upload-image-compact-view>
        </div>

        <div fxHide.xs>
          <!-- Image attachments -->
          <kl-image-chip-list *ngIf="attachmentsArray && attachmentsArray?.length"
          [attachments]="attachmentsArray"
          (remove)="removeAttachment($event)">
          </kl-image-chip-list>
          <!-- / Image attachments -->
        </div>

        <!-- Selected products -->
        <div class="my-2" *ngIf="selectedProducts">
          <kl-selected-store-products [selectedProducts]="selectedProducts" (remove)="removeProduct($event)"></kl-selected-store-products>
        </div>
        <!-- /Selected products -->

        <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-2" *ngIf="showBtns || replyMessage?.message">
          <div fxLayout="row" fxLayoutGap="20px">
            <!-- Image Picker -->
            <div class="mr-1 attach-btn" (click)="$event.stopPropagation()" fxHide.xs>
              <kl-image-picker-form-control formControlName="attachments"
                buttonType="mat-icon-button"
                [allowMultiple]="true"
                [showAttachments]="false"
                [s3Category]="s3Category"
                [acceptedFileTypes]="acceptedFileTypes">
              </kl-image-picker-form-control>
            </div>
            <!-- / Image picker -->

            <!-- Suggest products -->
            <div class="attach-btn" (click)="$event.stopPropagation()">
              <kl-store-products-form-control
                buttonType="mat-icon-button"
                buttonLabel="Product"
                formControlName="productsMetaData">
              </kl-store-products-form-control>
            </div>
            <!-- / Suggest products -->

          </div>

          <div (click)="$event.stopPropagation()">
            <kl-flat-button
              class="submit-btn"
              type="submit"
              label="Send"
              [progress]="progress"
              (clicked)="sendMessage()"
              [disabled]="!chatForm?.get('message')?.value">
            </kl-flat-button>
          </div>

        </div>
      </div>

    </div>
  </form>
</div>
