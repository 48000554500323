import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

import { KalgudiLandDetailsAgroTagging } from '../../classes/kalgudi-land-details-agro-tagging';

@Component({
  selector: 'kl-land-details-agro-tagging',
  templateUrl: './land-details-agro-tagging.component.html',
  styleUrls: ['./land-details-agro-tagging.component.scss']
})
export class LandDetailsAgroTaggingComponent extends KalgudiLandDetailsAgroTagging implements OnInit {

  @Input()
  details: KalgudiFarmerLand;

  @Input()
  isLandEditable: boolean;

  @Output()
  seasonEdit = new EventEmitter<any>();

  constructor(
    protected injector: Injector,
  ) {
    super( injector );
  }

  ngOnInit() {
    if(this.assistedProfileKey) {

      this.getLandDetailsAgroTagging(this.details, this.assistedProfileKey);
    }
  }

  onDestroyed() : void {}

  /**
   * Event emit back to parent to close land details dialog and opens edit season dialog
   */
  editSeason(season: any) {
    this.seasonEdit.emit(season);
  }
}
