import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KalgudiProject } from '@kalgudi/types';

@Component({
  selector: 'kl-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  animations: kalgudiAnimations
})
export class ProjectsListComponent implements OnInit {

  @Input()
  projectsList: KalgudiProject;

  constructor() { }

  ngOnInit() {
  }

}
