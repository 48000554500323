import { Injector, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableFilteredSearchStream, KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiPageDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PageFilter, PageFilterTypes } from '../../../constants';
import { KalgudiProgramListService } from '../services/kalgudi-program-list.service';
import { PageListStateService } from '../services/page-list-state.service';

@Directive()
export abstract class PagesListTable extends KalgudiMatTableFilteredSearchStream<KalgudiPageDetails> {

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  memberRole: PageFilter = {
    type: PageFilterTypes.PAGE_TYPE,
    value: ''
  };

  private notification: KalgudiNotification;
  private pagesListService: KalgudiProgramListService;
  private pageListStateService: PageListStateService;
  private util: KalgudiUtilityService;

  constructor(protected injector: Injector) {
    super();

    this.pagesListService     = this.injector.get<KalgudiProgramListService>(KalgudiProgramListService);
    this.pageListStateService = this.injector.get<PageListStateService>(PageListStateService);
    this.util                 = this.injector.get<KalgudiUtilityService>(KalgudiUtilityService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);

    this.displayedColumns = [
      'programName',
      'programType',
      // 'date',
      'members',
      'userRole',
      // 'members',
    ];


    // Invoke filter on page filter updation
    this.pageListStateService.pageTypeChange$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(res => {
        this.memberRole = res;
        this.resetTable();
      });

  }

  /**
   * Pages search api
   *
   * @param searchKeyword
   * @param offset
   * @param limit
   */
  protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {

    if (this.memberRole.type === PageFilterTypes.PAGE_TYPE) {

      return this.pagesListService.pagesList(offset, limit, this.memberRole.value);

    } else {

      return this.pagesListService.fetchPagesList(offset, limit, { memberRole: this.memberRole.value});
    }
  }
}
