import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { IAgGridCellRenderer } from '../../model/index';

@Component({
  selector: 'store-admin-ag-grid-anchor-renderer',
  template: `
    <a [title]="cell?.row?.title || cell?.row?.label"
      [href]="cell?.row?.href"
      [target]="cell?.row?.target || '_blank'">
      {{ cell?.row?.label }}
    </a>
  `,
  styles: [`
  `],
  encapsulation: ViewEncapsulation.None,
})
export class AgGridAnchorRendererComponent implements OnInit, IAgGridCellRenderer {

  public static componentId = 'AgGridAnchorRendererComponent';

  private params: any;
  public cell: any;

  ngOnInit(): void {}

  agInit(params: any): void {
    this.params = params;
    this.cell = {
      row: params.value,
      col: params.colDef.headerName,
    };
  }

  refresh(): boolean {
    return false;
  }
  formatString(val: any): string {
    return val;
  }
}
