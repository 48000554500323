import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiChangePasswordService } from '../services/kalgudi-change-password.service';

@Directive()
export abstract class ChangePassword extends KalgudiDestroyable {

  @Input()
  profileKey: string;

  @Output()
  closeDialog = new EventEmitter();

  changePasswordForm: FormGroup;

  progress: boolean;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private changePasswordService: KalgudiChangePasswordService;

  constructor(
    protected injector: Injector) {

    super();

    this.fb                    = this.injector.get(FormBuilder);
    this.util                  = this.injector.get(KalgudiUtilityService);
    this.notification          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.changePasswordService = this.injector.get(KalgudiChangePasswordService);

    this.changePasswordForm = this.resetPasswordForm;

  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Form group for change password form
   */
  private get resetPasswordForm(): FormGroup {

    return this.fb.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });
  }

  /**
   * Getter for new password
   */
  get newPasswordField(): AbstractControl {
    return this.changePasswordForm.get('newPassword');
  }

  /**
   * Getter for confirm password
   */
  get confirmPasswordField(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Changes password
   */
  changePassword() {

    const payload = this.preparePayload(this.changePasswordForm.value);

    this.progress = true;

    this.changePasswordService.changePassword(payload, this.profileKey)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)

      )
      .subscribe(
        res => this.onPasswordChanged(res),

        err => this.onPasswordFailedToChange(err)

      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  /**
   * Password changed successfully
   */
  protected onPasswordChanged(res) {
    this.notification.showMessage('Your password has been changed');

    this.closeDialog.emit();
  }

  /**
   * Password changed successfully
   */
  protected onPasswordFailedToChange(err: Error) {
    this.notification.showMessage('Failed to change the password');

    this.closeDialog.emit();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
