<div class="hour-selectable">
  <mat-form-field class="w-100 schedule-font selection" floatLabel="never" [appearance]="fieldAppearance">
    <mat-label translate>{{placeholder || 'Time'}}</mat-label>
    <mat-select [formControl]="selectedTime" [disabled]="disabled">
      <ng-container *ngFor="let item of scheduledTimeList">
        <mat-option *ngIf="!isTodayDateSelected || isTodayDateSelected && currentHour < item?.value" [value]="item?.label">{{item?.label}}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</div>
