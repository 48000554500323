<main>
  <section>
    <form autocomplete="off" [formGroup]="addUsersForm">
      <div class="w-50 m-auto add-users-form" fxLayout="column">

        <div>
          <mat-radio-group [formControl]="userType"
            class="p-25 login-type-radio-buttons"
            fxLayoutGap="20px"
            fxLayoutAlign="space-around center">
            <mat-radio-button value="email" class="login-details" >
            <span translate>Email</span>
            </mat-radio-button>
            <mat-radio-button value="mobile" class="login-details">
            <span translate>Mobile</span>
            </mat-radio-button>
          </mat-radio-group>
        </div>


        <div *ngIf="userType.value === 'mobile'">

          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Mobile number</mat-label>
            <input matInput [placeholder]="'Enter your mobile number' | translate" required formControlName="mobileNumber">
            <mat-error *ngIf="mobileNoField?.invalid">{{(addUsersForm.controls['mobileNumber'].hasError('required') ? 'Mobile number is required' : 'Please enter a valid mobile number') | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="userType.value === 'email'">

          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Email</mat-label>
            <input matInput [placeholder]="'Enter your email id' | translate" required formControlName="email">
            <mat-error *ngIf="emailIdField?.invalid">{{(addUsersForm.controls['email'].hasError('required') ? 'Email is required' : 'Please enter a valid email address') | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Your name</mat-label>
            <input matInput [placeholder]="'Enter your name' | translate" required formControlName="name" required>
            <mat-error translate>Name is required</mat-error>
          </mat-form-field>
        </div>

        <!-- Gender -->
        <mat-form-field appearance="outline" class="w-100" *ngIf="(env?.appId === 'SAM_FARMER' || env?.appId === 'SAM_FPO')">
          <mat-label translate>Gender</mat-label>
          <mat-select formControlName="gender" required>
            <mat-option *ngFor="let item of genderList" [value]="item?.id">{{item?.value | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="genderField?.invalid">{{(addUsersForm.controls['gender'].hasError('required') ? 'Gender is required' : 'Please select gender') | translate }}</mat-error>
        </mat-form-field>
        <!--/ Gender -->

        <!-- Location -->
        <div *ngIf="env.appId !== 'GEOAGROPRO'">
          <kl-google-places-autocomplete [useKalgudiGoogleLocation]="true" formControlName="googleLocationTo"></kl-google-places-autocomplete>
        </div>
        <!-- /Location -->

        <div fxLayout="row" fxLayoutAlign="end center">

          <kl-flat-button
            label="Add user"
            buttonColor="primary"
            (clicked)="addMembers()"
            [disabled]="addUsersForm.invalid">
          </kl-flat-button>
        </div>
      </div>

      <!-- <div fxLayout="row">
        <div fxFlex="calc(50% - 12px)" fxFlex.xs="100%">

        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Enter your email id" formControlName="alternateEmail">
          <mat-error *ngIf="emailIdField?.invalid">Please enter a valid email id</mat-error>
        </mat-form-field>
      </div>
        <mat-form-field appearance="outline">
          <mat-select placeHolder="Your profession">
              <mat-option *ngFor="let profession of professionsList" [value]="profession">
                {{ profession?.nativeBusinessTypeName }}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
    </form>
  </section>

</main>
