import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { KalgudiLazyLoaderModule } from '@kalgudi/third-party/lazy-loader';

import {
  GooglePlacesAutocompleteComponent,
} from './components/google-places-autocomplete/google-places-autocomplete.component';
import { GOOGLE_API_KEY } from './config';
import { ApiLoaderService } from './services/api-loader.service';
import { GooglePlacesKalgudiLocationMapService } from './services/google-places-kalgudi-location-map.service';
import { KalgudiGooglePlacesApiService } from './services/kalgudi-google-places-api.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



/**
 * The lazy loader module must be included in the root module i.e. `AppModule`.
 */
@NgModule({
  declarations: [
    GooglePlacesAutocompleteComponent,
  ],
  imports: [
    // Common module
    CommonModule,

    // Forms
    ReactiveFormsModule,

    // Material
    MatInputModule,
    MatIconModule,
    MatOptionModule,
    MatAutocompleteModule,
    MatButtonModule,

    // Kalgudi libraries
    KalgudiLazyLoaderModule,
    TranslateModule
  ],
  exports: [
    GooglePlacesAutocompleteComponent,
  ],
  providers: [ ]
})
export class KalgudiGooglePlacesModule {

  static forRoot(apiKey: string): ModuleWithProviders<KalgudiGooglePlacesModule> {

    return {
      ngModule: KalgudiGooglePlacesModule,
      providers: [

        ApiLoaderService,
        KalgudiGooglePlacesApiService,
        GooglePlacesKalgudiLocationMapService,

        {
          provide: GOOGLE_API_KEY,
          useValue: apiKey,
        }
      ]
    };
  }
}
