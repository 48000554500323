import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiSurveyQuestions } from '../../classes/kalgudi-survey-questions';
import { UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-questions-stream',
  templateUrl: './survey-questions-stream.component.html',
  styleUrls: ['./survey-questions-stream.component.scss'],
})
export class SurveyQuestionsStreamComponent extends KalgudiSurveyQuestions implements OnInit {

  readonly footerMenus: UIMenu[];

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(injector, notification, util);

    this.footerMenus = [
      { svgIcon: 'assets/svgs/qa.svg', title: 'Add question', clickAction: this.openMobileAddQuestion },
    ];
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

  /**
   * Opens Mobile qa form dialog
   */
  openMobileAddQuestion = (): void => {
    this.checkAndAddQuestion();
  }
}
