import { KalgudiGoogleLocationTo, StringStringMap } from './core.model';
import { StoreBaseProductBasicDetails } from './product-catalogue.model';
import { KalgudiSurveyDetails } from './survey.model';
import { KalgudiLocation, KalgudiPageRelation, KalgudiProduct, KalgudiUserBasicDetails } from './user.model';
import { NotificationSocialCommon } from './web-notifications';

export type PageType = 'PROGRAM' | 'UNIVERSITY' | 'ORGANIZATION' | 'COMPANY' | 'FARMER' | 'STOCK' | 'PROCESSING' | 'SALES_TEAMS' | 'TRAINING' | 'FIG';

export type TargetMembers = 'ALL' | 'CONTRIBUTORS' | 'MEMBERS';


export interface BasePageDetails {
  pageTitle?: string;
  pageId?: string;
  pageProfilePic?: string;
  pageType?: PageType; // 'PROGRAM' | 'UNIVERSITY' | 'COMPANY' | 'ORGANIZATION' | 'FARMER';
  pageDescription?: string;
  address?: string;
  locationDetails?: KalgudiLocation;
  website?: string;
  wikipediaLink?: string;
  facebookLink?: string;
  linkedInLink?: string;
  twitterLink?: string;
  foundedYear?: string;
  founders?: string;
  mobileNo?: string;
  emailId?: string;
}

export interface ProgramPageDetails extends BasePageDetails {
  vision?: string;
  activities?: string;
  startDuration?: string;
  endDuration?: string;
}

export interface StockPageDetails extends BasePageDetails {
  product?: string;
  variety?: string;
  totalQuantity?: StockTotalQuantity;
}

export interface StockTotalQuantity {
  tons: number;
  kgs: number;
  quintals: number;
}


export interface ProcessingPageDetails extends BasePageDetails {
  lot?: any;
  quality?: string;
  units?: string;
  stockProductDetails?: StoreBaseProductBasicDetails;
}

export interface UniversityPageDetails extends BasePageDetails {
  courses: string;
  controlType: string;
  faxNumber: string;
  enrollmentRange: string;
}

export interface OrganizationPageDetails extends BasePageDetails {
  organizationName: string;
  activity: string;
  noOfEmployees: string;
  vision: string;
}

export interface CompanyPageDetails extends BasePageDetails {
  vision: string;
  noOfEmployees: string;
  industry: string;
}

export interface FarmerPageDetails extends BasePageDetails {
  farmerName: string;
  cropsCount: string;
  selectCrops: string;
  area: string;
  areaUnits: string;
  villageCovered: string;
  package: string;
}

export interface SalesTeamsPageDetails extends BasePageDetails {
  selectCrops: StoreBaseProductBasicDetails;
}

export interface KalgudiPageDetails {
  CT: string;
  LUT: string;
  pageId: string;
  vision: string;
  address: string;
  emailId: string;
  website: string;
  founders: string;
  mobileNo: string;
  pageType: PageType;
  pageTitle: string;
  createdBy: KalgudiUserBasicDetails;
  updatedBy?: KalgudiUserBasicDetails;
  activities: string;
  endDuration: string;
  foundedYear: string;

  twitterLink: string;
  facebookLink: string;
  linkedInLink: string;
  startDuration: string;
  wikipediaLink: string;

  adminsCount: number;
  adminDetails: KalgudiUserBasicDetails;
  followersCount: number;
  contributorsCount: number;
  membersCount: number;

  pageProfilePic: string;
  pageVisibility: string;
  locationDetails: KalgudiLocation;
  pageDescription: string;
  activityFeatures: string[];
  memberRole: KalgudiPageRelation;

  product?: KalgudiProduct;
  variety?: KalgudiProduct;
}

export interface PageDigitalAssistanceStats {
  QA?: number;
  SHAREATHOUGHT?: number;
  PROFILE_UPDATE?: number;
  CROP_CALANDAR?: number;
  SURVEY_SUBMISSION?: number;
  [key: string]: number;
}

export declare type PageDigitalAssistanceActions = keyof PageDigitalAssistanceStats;

export const DA_STREAM_TYPE_MAP = {
  SHAREATHOUGHT: 'SHAREATHOUGHT',
  QA: 'QA'
} as const;

export declare type DA_STREAM_TYPE = keyof typeof DA_STREAM_TYPE_MAP;

export interface PageDigitalAssistanceStream {
  activityId: string;
  activityTo: NotificationSocialCommon;
  entityId: string;
  activityType: DA_STREAM_TYPE;

  contributer: PagesContributorV1;
  reportActivity?: PagesReportActivityV1;
  adminKey?: string;

  // For UI purpose only
  author?: KalgudiUserBasicDetails;
}

export interface PagesReportActivityV1 {
  quantity: number;
  cropDetails?: PagesCropDetailsV1;
  cropCalandar: PagesCropCalenderV1;
}

export interface PagesCropCalenderV1 {
  bagCount: string;
  chaffs: string;
  bagSize: string;
  harvestingDate: string;
  activities: PagesDigitalAssistanceActivityV1[];
  grade: string;
  transportationDate: string;
  receivingDate: string;
  moisture: string;
}

export interface PagesDigitalAssistanceActivityV1 {
  activityTasks: PagesActivityTask[];
  activityName: string;
  activityTime: PagesReportActivityTimeV1;
}

export interface PagesReportActivityTimeV1 {
  actual: PagesActivityTimeValuesV1;
  ideal: PagesActivityTimeValuesV1;
}

export interface PagesActivityTimeValuesV1 {
  val: string;
  dateTo: string;
  time: string;
  dateFrom: string;
}

export interface PagesActivityTask {
  pestDetails: PagesPestDetailsV1;
  tasks: PagesTaskV1[];
}

export interface PagesTaskV1 {
  mode: string;
  idealQuantity: PagesIdealQuantityV1;
  product: PagesProductV1;
  actualQuantity: PagesIdealQuantityV1;
  diffReason: string;
  remarks: string;
}

export interface PagesProductV1 {
  amountPaid: boolean;
  productId?: string;
  productName?: string;
}

export interface PagesIdealQuantityV1 {
  unitPer: string;
  unit: string;
  quantity: string;
}

export interface PagesPestDetailsV1 {
  name: string;
  description: string;
}

export interface PagesCropDetailsV1 {
  amountPaid: boolean;
  productId: string;
  varietyId: string;
  varietyName: string;
  productName: string;
}

export interface PagesContributorV1 {
  contributorId: string;
  contributorProfilePicUrl?: string;
  contributorName: string;
}


export interface PageFiltersStateRegionMap {
  [key: string]: StringStringMap;
}

export interface PageFilters {
  districts: PageFiltersStateRegionMap;
  countries: StringStringMap;
  statesOrRegions: PageFiltersStateRegionMap;
  businessTypes: StringStringMap;
  products: StringStringMap;
  [key: string]: any;
}

export interface PageShareTargetAudienceRequest {
  valid: boolean;
  isPublishedThroughTool: boolean;
  isSms: boolean;
  pageId: string;
  gender?: string;
  locations?: string[];
  states?: string[];
  districts?: string[];
  countries?: string[];
  products?: string[];
  businessTypes?: string[];
  [key: string]: any;
}

export interface KalgudiStock {
  CT: string;
  LUT: string;
  pageId: string;
  stockId: string;
  price: string;
  product: KalgudiProduct;
  quantityUnits: string;
  quantityValue: string;
  createdBy?: KalgudiUserBasicDetails;
  farmerDetails?: KalgudiUserBasicDetails;
  updatedBy?: KalgudiUserBasicDetails;
}

export interface KalgudiPageAddNewUserPayload {
  name?: string;
  mobileTelecomCode?: string;
  email?: string;
  profileTypeId?: string;
  profileTypeName?: string;
  businessTypeId?: string;
  businessTypeName?: string;
  nativeBusinessTypeId?: string;
  nativeBusinessTypeName?: string;
  mobileNumber?: string;
  googleLocationTo?: KalgudiGoogleLocationTo;
  sourceFrom?: string;
  pageId?: string;
  pageTitle?: string;
}

// --------------------------------------------------------
// #region Surveys model
// --------------------------------------------------------

export interface KalgudiPageSurveyDetails extends KalgudiSurveyDetails {
  pageId: string;
}

// --------------------------------------------------------
// #endregion
// --------------------------------------------------------



// --------------------------------------------------------
// #region Page analytics
// --------------------------------------------------------

export interface PageShareAnalytics {
  totalShares: number;
  updateTypeStats?: UpdateTypeStats;
}

interface UpdateTypeStats {
  [key: string]: number;
}

export interface PageQaAnalytics {
  totalQuestions: number;
  questionsReplied?: number;
  questionsNotReplied?: number;
}

export interface PageProjectAnalytics {
  totalProjects: number;
  stateWiseCounts?: StateWiseCounts;
}

export interface PageTaskAnalytics {
  totalTasks: number;
  stateWiseCounts?: StateWiseCounts;
}

interface StateWiseCounts {
  COMPLETED: number;
  NOT_STARTED: number;
  IN_PROGRESS: number;
}

export interface PageAnalytics {
  totalMembers: number;
  totalLocations: number;
  totalProducts: number;
  memberTypeStats?: MemberTypeStats;
}

export interface UserWiseStats {
  count?: number;
  firstName?: string;
  profileKey?: string;
}

export interface PageDaStats {
  MOBILE_NO_UPDATE?: number;
  PROFILE_CREATION?: number;
  PROFILE_UPDATE?: number;
  QA?: number;
  SURVEY_SUBMISSION?: number;
  SHARES?: number;
}

export interface PageDigitalAssistAnalytics {
  statistics?: PageDaStats;
  userWiseStatistics?: UserWiseStats[];
}

interface MemberTypeStats {
  ADMIN: number;
  CONTRIBUTOR: number;
  FOLLOWER: number;
}

// --------------------------------------------------------
// #endregion
// --------------------------------------------------------


