import { Component, EventEmitter, forwardRef, Input, OnInit, Output, Provider, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PageStats, PageStatsList, StatsCardType } from '@kalgudi/types';

import { floatTopAnimation, zoomOutAnimation } from '../../animations';



// Base product NG_VALUE_ACCESSOR provider for custom form control
const PAGE_STATS_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PageStatsComponent),
  multi: true,
};

@Component({
  selector: 'store-admin-page-stats',
  templateUrl: './page-stats.component.html',
  styleUrls: ['./page-stats.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [ floatTopAnimation, zoomOutAnimation ],
  providers: [ PAGE_STATS_FORM_CONTROL_PROVIDER ]
})
export class PageStatsComponent implements OnInit, ControlValueAccessor {

  @Input()
  cardType: StatsCardType;

  @Input()
  stats: PageStatsList;

  @Input()
  enableAction: boolean = false;

  @Input()
  selectedStat: PageStats;

  @Output()
  selectedStatChanged = new EventEmitter<PageStats>();

  readonly statsCardType = StatsCardType;

  constructor() { }

  ngOnInit() {
  }


  // --------------------------------------------------------
  // #region Control value accessor methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.selectedStat = obj;
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  /**
   * Event handler for stats clicked
   */
  onStatsClicked(value: PageStats): void {
    this.selectedStat = value;
    this.selectedStatChanged.emit(value);

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(value);
    this.onTouched();
  }
}
