import { Directive, Inject, Injector, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { AudioAttachmentService, ImagePickerFormControlComponent } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiAppService, KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiShareUpdateService } from '@kalgudi/share-update';
import { SocialDataNormalizerService } from '@kalgudi/social';
import {
  Attachment,
  ATTACHMENT_TYPE_MAP,
  FileMimeTypes,
  KalgudiDialogConfig,
  KalgudiPageDetails,
  KalgudiSms,
  LabelValueMap,
  RobocallRequestPayload,
  S3PolicyPathCategoryMap,
  ScheduleDates,
} from '@kalgudi/types';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';

// import { RobocallStreamService } from "../services/robocall-stream.service";
// import { KalgudiSmsUpdateService } from "../../program-sms";

@Directive()
export abstract class KalgudiPublishRobocall extends KalgudiDestroyable {
  @Input()
  scheduleId: string;

  /** This will store an instance of audio attachment component.
   * It is used to open the attachment dialog from parent component
   * */
  @ViewChild(ImagePickerFormControlComponent) audioUploadComponent?: ImagePickerFormControlComponent;

  disableGenerateSmsBtn: boolean = false;

  readonly sharedVisibilities = SHARE_TYPES;

  // scheduleBtnClicked = new FormControl('');

  shareVisibilityList: LabelValueMap[] = [SHARE_TYPES.ALL_SUBSCRIBERS];

  attachmentType = ATTACHMENT_TYPE_MAP;
  readonly s3Category: S3PolicyPathCategoryMap = KALGUDI_S3_POLICY_MAP.ROBOCALL;
  readonly acceptedAudioFileTypes: FileMimeTypes[] = [FileMimeTypes.AUDIO_MP3]; // To allow only mp3 files for attachment

  scheduledDates: ScheduleDates;

  shareForm: FormGroup;

  audioAttachment = new FormControl("");

  attachedAudioList: Attachment[] = [];

  pageId: string;

  pageDetails: KalgudiPageDetails;

  progress: boolean;

  isSMSToAltNumber = true;

  /** For date picker restriction */
  public today = moment().startOf('day').toDate();

  // Dependencies
  protected kalgudiPageService: KalgudiPageService;
  // private logsListService: RobocallStreamService;
  private audioAttachmentService: AudioAttachmentService;
  protected kalgudiApp: KalgudiAppService;
  // private fb: FormBuilder;
  // private util: KalgudiUtilityService;
  private sharePostService: KalgudiShareUpdateService;
  private socialDataNormalizer: SocialDataNormalizerService;
  templateId: number;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification
  ) {
    super();
    // Manually inject dependencies
    // this.logsListService = this.injector.get(RobocallStreamService);
    this.kalgudiPageService = this.injector.get(KalgudiPageService);
    this.audioAttachmentService = this.injector.get(AudioAttachmentService);
    this.kalgudiApp = this.injector.get(KalgudiAppService);
    this.sharePostService = this.injector.get(KalgudiShareUpdateService);
    this.socialDataNormalizer = this.injector.get(SocialDataNormalizerService);
    // this.smsUpdateService = this.injector.get(KalgudiSmsUpdateService);
    // this.fb = this.injector.get(FormBuilder);

    this.getPageDetails();

    this.shareForm = this.initiateRobocallForm;
    this.shareForm.controls["lstOfAttachments"].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((list: Attachment[]) => {
        list.forEach((attachment) => {
          const index = this.attachedAudioList.findIndex(
            (a) => a.url === attachment.url
          );
          if (index >= 0) {
            this.attachedAudioList[index] = attachment;
          } else {
            this.attachedAudioList.push(attachment);
          }
        });
      });
  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  showAudioDialog(index?: number): void {
    const attachment = this.attachedAudioList[index];
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: "Record",
      acceptButtonTitle: "Delete",
      rejectButtonTitle: "Cancel",
      isFpoRobocall: true,
      data: {
        s3Category: this.s3Category,
        url: attachment?.url ? attachment.url : "",
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: "600px",
      maxWidth: "600px",
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    this.audioAttachmentService
      .showAudioDialog(dialogDetails, dialogConfig)
      .pipe(
        // Filter only accepted actions, do nothing for cancel actions
        filter((r) => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map((r) => r.data)
      )
      .subscribe((res) => {
        if (index === undefined) {
          this.attachedAudioList.push(res.attachment);
        } else {
          this.attachedAudioList[index] = res.attachment;
        }
      });
  }

  public getAudioLabel(index?: number): string {
    if (index !== 0 && this.attachedAudioList.length) {
      return `Response ${index || this.attachedAudioList.length}`;
    }
    return "Main audio";
  }

  removeAudioAttachment(index: number): void {
    if (this.attachedAudioList[index].geoLocation) {
      const list: Attachment[] =
        this.shareForm.controls["lstOfAttachments"].value;
      const attachmentIndex = list.findIndex(
        (a) => a.url === this.attachedAudioList[index].url
      );
      list.splice(attachmentIndex, 1);
      this.shareForm.patchValue(
        { lstOfAttachments: list },
        { onlySelf: true, emitEvent: false }
      );
    }
    this.attachedAudioList.splice(index, 1);
  }

  /**
   * service call to publish robocall
   */
  scheduleRobocall() {
    this.progress = true;
    const payload = this.preparePayload();
    console.log(payload);

    if (this.scheduleId) {
      this.sharePostService
        .updateScheduledPost(this.scheduleId, payload as any)
        .subscribe(
          (res) => this.onRobocallSuccess(res),
          (err) => {
            this.progress = false;
            this.notifications.showMessage(
              "Unable to schedule robocall, please try again later."
            );
          }
        );
    } else {
      // Call api to share post
      return this.sharePostService.sharePost(payload).subscribe(
        (res) => this.onRobocallSuccess(res),
        (err) => {
          this.notifications.showMessage(
            "Unable to initiate robocall, please try again later."
          );
        }
      );
    }
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibility(): AbstractControl {
    return this.shareForm.get("recipient");
  }

  get shareToField(): AbstractControl {
    return this.shareForm.get("sharedTo");
  }

  get scheduledDateField(): AbstractControl {
    return this.shareForm.get("scheduledDate");
  }

  get scheduledTimeField(): AbstractControl {
    return this.shareForm.get("scheduledTime");
  }

  /**
   * Share form program messages filters or tags field
   */
  get shareFormFilter(): FormGroup {
    return this.shareForm.get("filter") as FormGroup;
  }

  /**
   * Share form text field.
   */
  get shareFormText(): AbstractControl {
    return this.shareForm.get("shareText");
  }

  /**
   * Getter for gender
   */
  get genderField(): AbstractControl {
    return this.shareFormFilter.get("gender");
  }

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.shareFormVisibility.value;
  }

  /**
   * Resets publish sms form. Overrides default implementation of reset form
   */
  resetForm(): void {
    this.shareForm.reset();
    this.attachedAudioList = [];
    // patching values instead of null value
    this.shareFormVisibility.patchValue(this.shareVisibilityList[0].value);
    this.shareToField.patchValue(this.shareVisibilityList[0].value);
  }

  /**
   * Initializing the SMS details to the share form
   */
  initPostDetails(postDetails: KalgudiSms): void {
    this.shareForm.patchValue(postDetails);
  }

  /**
   * Event handler for sending robocalls to alternate mobile number toggle
   */
  onRobocallSwitchToggle(val: boolean): void {
    this.isSMSToAltNumber = val;
  }

  /**
   * Calls api to get short url
   */

  protected abstract onRobocallCreated(response: KalgudiSms): void;

  // --------------------------------------------------------
  // #endregion
  // -------------------------------------------------------

  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * from group for filters
   */
  private get initiateRobocallForm(): FormGroup {
    return new FormGroup({
      shareText: new FormControl(""),

      // Common fields inherited from share a thought
      sharedTo: new FormControl(
        this.shareVisibilityList[0].value,
        Validators.required
      ),

      // Program specific fields
      recipient: new FormControl(
        this.shareVisibilityList[0].value,
        Validators.required
      ),
      msgTypes: new FormControl([], Validators.required),
      filter: new FormGroup({
        users: new FormControl([]),
        products: new FormArray([]),
        businessTypes: new FormArray([]),
        locations: new FormArray([]),
        countries: new FormArray([]),
        states: new FormArray([]),
        districts: new FormArray([]),
        pageId: new FormControl(""),
        isSms: new FormControl(false),
        isRobocall: new FormControl(true),
        gender: new FormControl(""),
      }),
      scheduledDate: new FormControl(),
      scheduledTime: new FormControl(""),
      scheduledTS: new FormControl(""),
      isPublishedThroughTool: new FormControl(true),
      lstOfAttachments: new FormControl([]),
    });
  }

  /**
   * Payload for publish sms
   */
  private preparePayload(): RobocallRequestPayload {
    if (this.shareForm.value.recipient === "SPECIFIC_SUBSCRIBERS") {
      this.shareForm.get("isPublishedThroughTool").patchValue(false);
    }

    if (
      this.shareForm.value.scheduledDate &&
      this.shareForm.value.scheduledTime
    ) {
      const date = new Date(this.shareForm.value.scheduledDate);

      const dateAndTime = `${
        date.getMonth() + 1
      }-${date.getDate()}-${date.getFullYear()} ${
        this.shareForm.value.scheduledTime
      }`;

      this.shareForm.value.scheduledTS = new Date(dateAndTime).toISOString();
    }
    // Converting to .MP3 file if it is not in .MP3 format
    if (this.attachedAudioList && this.attachedAudioList?.length) {
      this.attachedAudioList.forEach(element => {
        // Removing robocalltempfolder if it includes in url
        if (element.url.includes('/robocalltempfolder')) {
          element.url = element.url.replace('/share-a-thought/robocalltempfolder', '/share-a-thought');
        }
        // Converting audio in mp3 if audio is other than mp3
        if (!element.url.includes('.mp3')) {
          const lastDotIndex = element.url.lastIndexOf('.');
          if (lastDotIndex !== -1) {
            element.url = element.url.substring(0, lastDotIndex) + '.mp3';
          }
        }
        if (element.url.includes('https://kalgudi.com')) {
          element.url = element.url.replace('https://kalgudi.com', '');
        }
      });
    }


    const robocallPayload: RobocallRequestPayload = {
      ...this.shareForm.value,
      msgTypes: this.shareForm.value.msgTypes?.length
        ? ["ROBOCALL", ...this.shareForm.value.msgTypes]
        : ["ROBOCALL"],
      authorId: this.kalgudiApp.profileLocal.profileKey,
      isSMSToAltNumber: this.isSMSToAltNumber,
      lstOfAttachments: this.attachedAudioList,
      recipient: this.shareForm.value.recipient,
      sharedTo: this.shareForm.value.sharedTo,
      scheduledTS: this.shareForm.value.scheduledTS,
      scheduledDate: this.shareForm.value.scheduledDate,
      scheduledTime: this.shareForm.value.scheduledTime,
      isPublishedThroughTool: this.shareForm.value.isPublishedThroughTool,
      entityId: this.pageDetails?.pageId,
      entityName: this.pageDetails?.pageTitle,
      imageUri: "",
      isAdmin: true,
      isAuthor: true,
      filter: {
        isRobocall: true,
        isSms: false,
        gender: this.genderField.value,
        users: this.shareForm.value.filter.users,
        products: this.shareForm.value.filter.products,
        businessTypes: this.shareForm.value.filter.businessTypes,
        locations: this.shareForm.value.filter.locations,
        countries: this.shareForm.value.filter.countries,
        states: this.shareForm.value.filter.states,
        districts: this.shareForm.value.filter.districts,
        pageId: this.pageDetails?.pageId,
        pageTitle: this.pageDetails?.pageTitle,
        pageType: this.pageDetails?.pageType,
        pageUrl: this.pageDetails?.pageProfilePic,
        parentPageId: this.pageDetails?.pageId,
        parentPageTitle: this.pageDetails?.pageTitle,
        parentPageUrl: this.pageDetails?.pageProfilePic,
        isExtended: false,
        memberOf: [],
        organisations: [],
      },
    };

    if (this.shareForm.value.recipient === "SPECIFIC_KALGUDI_USER") {
      robocallPayload.filter.users = this.shareForm.value.filter.users;
    }

    return robocallPayload;
  }

  // private specificMembersList() {
  //   return this.notifications.showMessage('Please select members to send SMS');
  // }

  /**
   * Event handler for sms success
   */
  private onRobocallSuccess(res) {
    this.resetForm();
    // Selected gender all by default after submit the form
    this.genderField.patchValue("");
    this.progress = false;
    this.notifications.showMessage("Robocall initiated successfully.");
    this.socialDataNormalizer.mapShareUpdateQaAuthorDetails(res);
    this.onRobocallCreated(res);
  }

  /**
   * Gets the pageDetails for page id
   */
  private getPageDetails(): void {
    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap((_) =>
          this.kalgudiPageService.pageDetails$.pipe(
            takeUntil(this.destroyed$),

            first(),

            tap((r) => this.fetchPageId(r))
          )
        )
      )
      .subscribe();
  }

  /**
   * Gets page id and page details
   */
  private fetchPageId(pageDetails: KalgudiPageDetails): void {
    this.pageDetails = pageDetails;
    this.pageId = pageDetails.pageId;
  }

  /**
   * Reset schedule date and time
   */
  clearDate() {
    this.shareForm.get('scheduledDate').reset();
    this.shareForm.get('scheduledTime').reset();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
