/// <reference types="@types/googlemaps" />
import { Injectable } from '@angular/core';
import { GooglePlace, KalgudiGoogleLocationTo, KalgudiLocation } from '@kalgudi/types';

import { KalgudiGooglePlaceMap } from '../models/index';


// import {} from 'googlemaps';

// import { google } from '@google/maps';
declare var google: any;

/**
 * Google places autocomplete to Kalgudi location mapping service.
 * It maps google places autocomplete object to kalgudi location type.
 *
 * @author Pankaj Prakash
 * @see GooglePlacesDirective
 */
@Injectable()
export class GooglePlacesKalgudiLocationMapService {

  constructor() { }

  mapToKalgudiLocation(
    place: any, // google.maps.places.PlaceResult,
    placeDescription?: string,
  ): KalgudiGooglePlaceMap {

    let kalgudiLocation: KalgudiLocation = {};
    const googlePlace: GooglePlace = {};

    place.address_components.forEach(p => {

      if (this.hasCountry(p)) {
        const countryDetails = this.getCountry(p);
        kalgudiLocation = { ...kalgudiLocation, ...countryDetails };
        googlePlace.country = countryDetails.countryName;

      } else if (this.hasState(p)) {
        const stateDetails = this.getState(p);
        kalgudiLocation = { ...kalgudiLocation, ...stateDetails };
        googlePlace.administrative_area_level_1 = stateDetails.stateName;

      } else if (this.hasDistrict(p)) {

        const districtDetails = this.getDistrict(p);
        kalgudiLocation = { ...kalgudiLocation, ...districtDetails };
        googlePlace.administrative_area_level_2 = districtDetails.districtName;
      } else if (this.hasLocality(p)) {

        const localityDetails = this.getLocality(p);
        kalgudiLocation = { ...kalgudiLocation, ...localityDetails };
        googlePlace.locality = localityDetails.locality;
      } else if (this.hasPostalCode(p)) {

        const postalDetails = this.getPostalCode(p);
        kalgudiLocation = { ...kalgudiLocation, ...postalDetails };
        googlePlace.postal_code = postalDetails.postalCode;
      } else if(this.hasAdminAreaLevel3(p)) {

        const adminLevel3Details = this.getAdminAreaLevel3(p);
        kalgudiLocation = { ...kalgudiLocation, ...adminLevel3Details };
        googlePlace.administrative_area_level_3 = adminLevel3Details.administrative_area_level_3;
      }
    });

    // Append lat long details
    const latLong = this.getLatLong(place.geometry);
    kalgudiLocation = { ...kalgudiLocation, ...latLong };


    // Update lat long in google place
    googlePlace.latitude  = latLong.latitude;
    googlePlace.longitude = latLong.longitude;

    // Construct location long and short
    kalgudiLocation.locationLong = placeDescription || this.getKalgudiLocationLong(kalgudiLocation);
    kalgudiLocation.locationShort = this.getKalgudiLocationShort(kalgudiLocation);

    const kalgudiGoogleLocationTo: KalgudiGoogleLocationTo = {
      adminLevel1: googlePlace.administrative_area_level_1,
      adminLevel2: googlePlace.administrative_area_level_3 || googlePlace.administrative_area_level_2 || googlePlace.locality,
      countryName: googlePlace.country,
      latitude: googlePlace.latitude,
      longitude: googlePlace.longitude,
      locality: googlePlace.locality || googlePlace.administrative_area_level_3 || googlePlace.administrative_area_level_2,
    };

    return { kalgudiLocation, googlePlace, kalgudiGoogleLocationTo };
  }

  /**
   * Extracts country details from google address component.
   */
  getCountry(
    place: any, // google.maps.GeocoderAddressComponent
  ): { countryId?: string; countryName?: string; countryShortName?: string; } {

    return { countryId: '', countryName: place.long_name, countryShortName: place.short_name, };
  }

  /**
   * Check if the google address component has country fields or not.
   *
   * @returns `true` if the address field contains country details, otherwise `false`.
   */
  hasCountry(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('country') > -1);
  }

  // /**
  //  * Extracts region details from google address component.
  //  */
  // getRegion(
  //   place: any, // google.maps.GeocoderAddressComponent
  // ): { regionId?: string; regionName?: string; } {

  //   return { regionId: '', regionName: place.long_name };
  // }

  // /**
  //  * Check if the google address component has region fields or not.
  //  *
  //  * @returns `true` if the address field contains region details, otherwise `false`.
  //  */
  // hasRegion(
  //   place: any, // google.maps.GeocoderAddressComponent
  // ): boolean {

  //   return !!(place.types.indexOf('country') > -1);
  // }

  /**
   * Extracts state details from google address component.
   */
  getState(
    place: any, // google.maps.GeocoderAddressComponent
  ): { stateId?: string; stateName?: string; } {

    return { stateId: '', stateName: place.long_name };
  }

  /**
   * Check if the google address component has state fields or not.
   *
   * @returns `true` if the address field contains state details, otherwise `false`.
   */
  hasState(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('administrative_area_level_1') > -1);
  }

  /**
   * Extracts district details from google address component.
   */
  getDistrict(
    place: any, // google.maps.GeocoderAddressComponent
  ): { districtId?: string; districtName?: string; } {

    return { districtId: '', districtName: place.long_name };
  }

  /**
   * Check if the google address component has district fields or not.
   *
   * @returns `true` if the address field contains district details, otherwise `false`.
   */
  hasDistrict(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('administrative_area_level_2') > -1);
  }

  /**
   * Extracts place details from google address component.
   */
  getPlace(
    place: any, // google.maps.GeocoderAddressComponent
  ): { placeId?: string; placeName?: string; } {

    return { placeId: '', placeName: place.long_name };
  }

  /**
   * Check if the google address component has place fields or not.
   *
   * @returns `true` if the address field contains place details, otherwise `false`.
   */
  hasPlace(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('locality') > -1);
  }

  /**
   * Extracts postal code details from google address component.
   */
  getPostalCode(
    place: any, // google.maps.GeocoderAddressComponent
  ): { postalCode?: string } {

    return { postalCode: place.long_name, };
  }

  /**
   * Extracts admin level 3 details from google address component.
   */
  getAdminAreaLevel3(
    place: any, // google.maps.GeocoderAddressComponent
  ): { administrative_area_level_3?: string } {

    return { administrative_area_level_3: place.long_name, };
  }

  /**
   * Check if the google address component has postal code fields or not.
   *
   * @returns `true` if the address field contains postal code details, otherwise `false`.
   */
  hasPostalCode(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('postal_code') > -1);
  }

  /**
   * Check if the google address component has locality field or not.
   *
   * @returns `true` if the address field contains locality detail, otherwise `false`.
   */
  hasLocality(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('locality') > -1);
  }

  /**
   * Check if the google address component has admin level 3 field field or not.
   *
   * @returns `true` if the address field contains locality detail, otherwise `false`.
   */
  hasAdminAreaLevel3(
    place: any, // google.maps.GeocoderAddressComponent
  ): boolean {

    return !!(place.types.indexOf('administrative_area_level_3') > -1);
  }

  /**
   * Extracts locality details from google address component.
   */
  getLocality(
    place: any, // google.maps.GeocoderAddressComponent
  ): { locality?: string } {

    return { locality: place.long_name, };
  }

  /**
   * Extracts place details from google address component.
   */
  getLatLong(
    place: google.maps.places.PlaceGeometry
  ): { latitude?: string; longitude?: string; } {

    return {
      latitude: place.location.lat().toString(),
      longitude: place.location.lng().toString(),
    };
  }

  /**
   * Gets, kalgudi location long field
   * @param kalgudiLocation Kalgudi location details
   */
  getKalgudiLocationLong(kalgudiLocation: KalgudiLocation): string {

    return (
      (kalgudiLocation.districtName
        ? kalgudiLocation.districtName + ', '
        : '') +
      (kalgudiLocation.stateName || kalgudiLocation.regionName) + ' ' +
      kalgudiLocation.countryShortName).trim();
  }

  /**
   * Gets, kalgudi short long field
   * @param kalgudiLocation Kalgudi location details
   */
  getKalgudiLocationShort(kalgudiLocation: KalgudiLocation): string {

    return `${kalgudiLocation.stateName} ${kalgudiLocation.countryShortName}`.trim();
  }
}
