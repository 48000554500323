import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PostGoogleTranslateComponent } from './components/post-google-translate/post-google-translate.component';
import { KalgudiTranslationService } from './services/kalgudi-translation.service';

@NgModule({
  declarations: [
    PostGoogleTranslateComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PostGoogleTranslateComponent,
  ],
  providers: [
    KalgudiTranslationService,
  ]
})
export class KalgudiTranslateModule { }
