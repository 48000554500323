import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA, MobileDialogConfig } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogResult, ProgramShareUpdate } from '@kalgudi/types';

import { KalgudiFarmingActivityStreamStateService } from '../../services/kalgudi-farming-activity-stream-state.service';

@Component({
  selector: 'kl-farmer-farm-activity-mobile-dialog',
  templateUrl: './farmer-farm-activity-mobile-dialog.component.html',
  styleUrls: ['./farmer-farm-activity-mobile-dialog.component.scss']
})
export class FarmerFarmActivityMobileDialogComponent extends KalgudiMobileDialog implements OnInit {

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: MobileDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    private farmingActivityStreamStateService: KalgudiFarmingActivityStreamStateService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
  ) {
    super(dialogRef);
  }

  ngOnInit() {
  }

  onPostCreated(event: any): void {

    this.pushItemToHomeStream(event);

    this.ok();
  }


  /**
   * Pushes latest posted item to the stream
   * @param val
   */
  pushItemToHomeStream(val: ProgramShareUpdate): void {
    // this.shareUpdateStreamState.unshiftToStream(val);
    this.farmingActivityStreamStateService.unshiftToStream(val);

  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      // accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}

