<div class="users-task-tile-item-wrapper mt-2" *ngIf="userCurrentTasks?.length">
  <mat-card class="users-task-tile px-3 pt-2 pb-0">
    <!-- Task tile header -->
    <div fxLayout class="pb-2" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="start center">
        <div>
          <svg-icon src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '20', 'height.px': '20'}"
            [applyCss]="true" class="mr-3 projects-icon"></svg-icon>
        </div>
        <div fxLayoutAlign="start center">
          <p class="m-0" translate>Your Tasks</p>
        </div>
      </div>
    </div>
    <!--/ Task tile header -->

    <div class="border-bottom"></div>

    <!-- Users current task tile list -->
    <div fxLayout="row wrap">
      <div fxFlex="100%" class="users-task" *ngFor="let task of userCurrentTasks | slice: 0:2;let last = last">
        <kl-tasks-tile
          enableActions= false
          (openFullview)="openTaskFullview(task)"
          [taskDetails]="task"
          [showStats]="false">
        </kl-tasks-tile>
        <div class="border-line border-bottom mt-2 mb-1" *ngIf="!last"></div>
      </div>
    </div>
    <!--/ Users current task tile list -->

    <!-- Task full view button -->
    <div fxLayout fxLayoutAlign="end end" class="py-2">
      <button mat-button class="view-all mr-n3"
        (click)="openTaskListPage()" fxLayout fxLayoutAlign="center center">
        <div fxLayout fxLayoutAlign="center center">
          <div><span translate>View tasks</span></div>
          <div class="view-icon"><mat-icon>keyboard_arrow_right</mat-icon></div>
        </div>
      </button>
    </div>
    <!--/ Task full view button -->
  </mat-card>
</div>

