import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiPageFilters, KalgudiPageProject } from '@kalgudi/types';

@Component({
  selector: 'kl-page-projects-members-dialog',
  templateUrl: './page-projects-members-dialog.component.html',
  styleUrls: ['./page-projects-members-dialog.component.scss']
})
export class PageProjectsMembersDialogComponent extends KalgudiDialog<PageProjectsMembersDialogComponent> implements OnInit {


  filters: KalgudiPageFilters;

  activeTabIndex: number;

  projectDetails: KalgudiPageProject;

  readonly TABS = {
    MANAGE: { index: 0, title: 'Manage Members' },
    TARGET_MEMBERS: { index: 1, title: 'Target members' },
    BULK_MEMBERS: { index: 2, title: 'Bulk members' },
    // SEARCH: { index: 2, title: 'Search Members' },
  };

  constructor(
    protected dialogRef: MatDialogRef<PageProjectsMembersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    super(dialogRef);

    this.projectDetails = this.data.data.project;

    // console.log(this.data);

    this.activeTabIndex = this.TABS.TARGET_MEMBERS.index;
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
        filters: this.filters
      }
    };
    this.dialogRef.close(result);
  }

  addedMembers(filters: KalgudiPageFilters) {
    this.filters = filters;
    this.ok();
  }

  onMembersAddition(event): void {
    this.activeTabIndex = this.TABS.MANAGE.index;
  }
}

