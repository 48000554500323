<div class="header-menu-list" *ngIf="profile">

  <!-- Mobile menu -->
  <div *ngFor="let menu of menu">
    <ng-container *ngIf="!menu?.subMenu; else subMenu">
      <button class="w-100 py-2 menu"
        mat-list-item
        (click)="openAdminPage(menu?.id); close()"
        *ngIf="menu?.title !== 'Login' || (menu?.title === 'Login' && !login)"
        mat-button
        [routerLink]="menu?.routerLink"
        routerLinkActive="router-link-active"
        [ngClass]="{'d-none': menu?.id === 'ADMIN' && !isMatchedProfile}">

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">

          <div (click)="(menu?.routerLink)" fxFlexAlign="center center" >
            <mat-icon fxLayoutAlign="center center" *ngIf="menu?.matIcon">{{ menu?.matIcon }}</mat-icon>
            <svg-icon [applyCss]="true" *ngIf="menu?.svgIcon" [src]="menu?.svgIcon" class="menu-list-icons"
            [svgStyle]="{ 'width.px': 26, 'height.px': 24 }"></svg-icon>
            <!-- <i class="" *ngIf="menu?.faIcon"></i> -->
          </div>
          <div class="menu-title">
            <p class="m-0" translate>{{ menu?.title }}</p>
          </div>
        </div>
      </button>
    </ng-container>

    <ng-template #subMenu>
      <hr class="m-0" />

      <core-header-menu-list
        [menu]="menu?.subMenu"
        [login]="login"
        [profile]="profile"
        (menuClosed)="close()">
      </core-header-menu-list>

      <hr class="m-0" />
    </ng-template>
  </div>
  <!-- Mobile menu -->

</div>

