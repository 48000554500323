import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { KalgudiCommonModule } from '@kalgudi/common';
import { KalgudiPipesModule } from '@kalgudi/core';
import { TranslateModule } from '@ngx-translate/core';

import { ShareActivityFiltersComponent } from './components/share-activity-filters/share-activity-filters.component';
import {
  ShareSpecificMemberVisibilityComponent,
} from './components/share-specific-member-visibility/share-specific-member-visibility.component';
import { ShareVisibilityComponent } from './components/share-visibility/share-visibility.component';
import {
  KalgudiShareMembersPickerService,
} from './modules/share-member-picker/services/kalgudi-share-members-picker.service';
import { ShareMemberPickerModule } from './modules/share-member-picker/share-member-picker.module';
import { ShareTargetMembersModule } from './modules/share-target-members/share-target-members.module';
import { KalgudiShareSearchMembersApiService } from './services/kalgudi-share-search-members-api.service';
import { KalgudiShareSearchMembersService } from './services/kalgudi-share-search-members.service';
import { KalgudiShareWithApiService } from './services/kalgudi-share-with-api.service';
import { KalgudiShareWithService } from './services/kalgudi-share-with.service';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    ShareVisibilityComponent,
    ShareActivityFiltersComponent,
    ShareSpecificMemberVisibilityComponent
  ],
  imports: [
    CommonModule,

    ReactiveFormsModule,

    // Material Modules
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatChipsModule,
    MatIconModule,

    FlexLayoutModule,

    // Kalgudi libraries
    KalgudiPipesModule,
    KalgudiCommonModule,
    TranslateModule,

    ShareMemberPickerModule,
    ShareTargetMembersModule
  ],
  exports: [
    ShareVisibilityComponent,
    ShareActivityFiltersComponent,
    ShareSpecificMemberVisibilityComponent
  ],
  providers: [
    KalgudiShareSearchMembersApiService,
    KalgudiShareSearchMembersService,
    KalgudiShareMembersPickerService,
    KalgudiShareWithApiService,
    KalgudiShareWithService,
  ]
})
export class KalgudiShareWithModule { }
