import { EventEmitter, Injector, Output, Directive } from '@angular/core';
import { FormControl } from '@angular/forms';
import { KalgudiSmsStates } from '@kalgudi/types';

import { SmsLogsListStateService } from '../services/sms-logs-list-state.service';

@Directive()
export abstract class KalgudiSmsLogsFilter {

  @Output()
  selectedFilterId = new EventEmitter<string>();

  selectedFilter: FormControl = new FormControl('');

  statusFilters = [
    {
      label: 'All',
      value: KalgudiSmsStates.ALL
    },
    {
      label: 'Success',
      value: KalgudiSmsStates.SUCCESS
    },
    {
      label: 'Failure',
      value: KalgudiSmsStates.FAILED
    },
    {
      label: 'Pending',
      value: KalgudiSmsStates.PENDING
    }
  ];

  private logsListStateService: SmsLogsListStateService;

  constructor(protected injector: Injector) {

    this.logsListStateService = this.injector.get(SmsLogsListStateService);

    // selected filter default value is 'ALL'
    this.selectedFilter.patchValue(KalgudiSmsStates.ALL);

    this.subscribeToValueChanges();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  get selectedFilterValue() {
    return this.selectedFilter.value;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Calls this method whenever the selected filter value changes
   */
  private subscribeToValueChanges() {
    this.selectedFilter.valueChanges.subscribe(
      res => {
        // this.logsListStateService.updateStatusType(res);
        this.selectedFilterId.emit(this.selectedFilter.value);
      }
    );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
