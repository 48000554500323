<section class="position-relative production-details">
  <!-- common traceability details -->
  <kl-common-traceability-details
    [bgColor]= "'#cbf0fd'"
    [showGallery]="true"
    [showBreadCrumbs]="true"
    [showProfilePic]="true"
    [attachments]="productionAttachments"
    [level2ProductDetails]="level2ProductDetails"
    [basicDetails]="supplierDetails"
    [productLevel3Title]="productLevel3Title"
    [traceabilityDetails]="traceabilityDetails"
    [details]="!productionDetails?.length">

    <ng-container first-card *ngIf="productionDetails?.length">
      <!-- list of activities -->
      <div *ngFor="let items of productionDetails; let index = index">
        <kl-traceability-activities [items]="items" [index]="index"></kl-traceability-activities>
      </div>
      <!--/ list of activities -->
    </ng-container>

    <mat-card first-card *ngIf="!productionDetails?.length" fxLayout="column" class="no-content mt-3 position-relative">
      <div class="circle rounded-circle ml-3 mt-1" fxLayoutAlign="end end"></div>

      <div class="w-100" fxLayout="column" fxLayoutAlign="center center">
        <img class="image" src="assets/images/no-activities.gif" width=210 height=115>
        <span class="text-muted text">Activities yet to be published</span>
      </div>
    </mat-card>

  </kl-common-traceability-details>
  <!-- common traceability details -->
</section>
