<section class="farmer-page mb-3 page-creation-form">
  <div class="w-100 col-7 farmer mx-auto p-0 page-form">
    <!-- Wrapper Form -->
    <form autocomplete="off" [formGroup]="pageForm">
      <div class="page-info" fxLayout="column" fxLayoutGap="18px">
        <!-- Farmer name -->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Admin name</mat-label>
            <input matInput [placeholder]="'Farmer name' | translate" formControlName="farmerName" required>
            <mat-error class="mt-3" *ngIf="farmerNameField?.errors?.required" translate>Please mention farmer name</mat-error>
          </mat-form-field>
        </div>
        <!--  /Farmer name -->

        <!--Wrapper crops-->
        <div class="dropdown  w-100" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxLayoutAlign="space-between">
          <!-- Number of crops -->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
            <mat-label translate>Number of crops</mat-label>
            <input matInput [placeholder]="'Number of crops' | translate" formControlName="cropsCount">
          </mat-form-field>
          <!-- Number of crops -->

          <!--Choose crop-->
          <div fxLayout="column" fxFlex="calc(50% - 20px)" fxFlex.xs="100%">
            <kl-base-product-autocomplete
              formControlName="selectCrops"
              placeholder="Search and select product"
              label="Search and select product">
            </kl-base-product-autocomplete>
          </div>
          <!--/Choose crop-->

        </div>
        <!--/Wrapper crops-->

        <!--Wrapper area-->
        <div class="dropdown  w-100" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between">
          <!--Total area-->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Total area cultivated </mat-label>
            <input matInput [placeholder]="'Total area cultivated' | translate" formControlName="area">
          </mat-form-field>
          <!--/Total area-->

          <!--Select unit-->
          <mat-form-field appearance="outline" fxFlex="calc(50% - 20px)">
            <mat-label translate>Select unit</mat-label>
            <mat-select formControlName="areaUnits">
              <mat-option *ngFor="let areaUnit of areaUnitList" [value]="areaUnit">{{ areaUnit | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!--/Select unit-->

        </div>
        <!--/Wrapper area-->

        <!--Village covered-->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Village covered</mat-label>
            <input matInput [placeholder]="'Village covered' | translate" formControlName="villageCovered">
          </mat-form-field>
        </div>
        <!--/Village covered-->

        <!--Package of practice-->
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label translate>Package of practices</mat-label>
            <textarea rows="1" matInput formControlName="package"></textarea>
          </mat-form-field>
        </div>
        <!--/Package of practice-->

      </div>
    </form>
    <!-- /Wrapper Form -->

  </div>
</section>
