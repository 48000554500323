import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

@Component({
  selector: 'kl-land-details',
  templateUrl: './land-details.component.html',
  styleUrls: ['./land-details.component.scss']
})

export class LandDetailsComponent implements OnInit {

  @Input()
  details: KalgudiFarmerLand;

  @Input()
  isLandEditable: boolean;

  @Output()
  openLandDetailsDialog = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  /**
   * Event emit back to parent to open edit land details dialog
   */
  openEditLandDetailsDialog() {
    this.openLandDetailsDialog.emit();
  }

}