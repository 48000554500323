<main id="service-list">

  <div class="service-header py-2 mb-2" fxLayout fxLayoutAlign="space-between center">
    <div fxFlex="60%" fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
      <p class="m-0 title font-weight-bold" translate>Service</p>
    </div>

    <div fxFlex="30%">
      <p class="m-0 title font-weight-bold" translate>License</p>
    </div>

    <div fxFlex="10%"></div>
  </div>

  <ng-container *ngIf="!serviceLicense?.length">
    <p class="text-muted pt-2 text-center w-100 no-content">No services added yet!</p>
  </ng-container>

  <section class="list overflow-auto" *ngIf="serviceLicense?.length">

    <div  *ngFor="let services of serviceLicense; let last = last; let index = index">
      <div class="farmer-crops-header" fxLayout fxLayoutAlign="space-between center">
        <div fxFlex="60%" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start start">
          <p class="m-0 title font-weight-500 w-100 text-truncate" translate>{{ services?.service }}</p>
        </div>

        <div fxFlex="32%">
          <p class="m-0 title w-100 text-truncate" translate>{{ services?.license || '-'}}</p>
        </div>

        <div fxFlex="8%" fxLayout fxLayoutAlign="end end" fxLayoutAlign.xs="start start" *ngIf="editable">
          <button mat-icon-button class="action-buttons bg-danger" (click)="removeServices(index)">
            <mat-icon class="action-icons text-white">clear</mat-icon>
          </button>
        </div>
      </div>

      <div class="border-bottom pt-2 mb-2">
      </div>

    </div>
  </section>
</main>

