import { Component, Input, OnInit } from '@angular/core';
import { KalgudiProjectTask } from '@kalgudi/types';

@Component({
  selector: 'kl-task-header-da',
  templateUrl: './task-header-da.component.html',
  styleUrls: ['./task-header-da.component.scss']
})
export class TaskHeaderDaComponent implements OnInit {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  isSubmission: boolean;

  constructor() { }

  ngOnInit() {
  }

}
