import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { bottomFloatingCardAnimation, checkMobileDevice, KALGUDI_PROFILE_BUSINESS_TYPE_NAMES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { AssistProfileUpdate } from '../../classes/assist-profile-update';

@Component({
  selector: 'kl-digital-assist-profile-logs',
  templateUrl: './digital-assist-profile-logs.component.html',
  styleUrls: ['./digital-assist-profile-logs.component.scss'],
  animations: [bottomFloatingCardAnimation]
})
export class DigitalAssistProfileLogsComponent  extends AssistProfileUpdate implements OnInit {

  availableBusinessTypeNames = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private profileRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
  ) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

  /**
   * Open profile full view with profile logs param
   * @param profileKey
   */
  openProfileView(profileKey: string) {
    if (!checkMobileDevice()) {

      if (!this.env.production && this.env.development) {
        const url = `${this.env.domain}/core/app/profiles/${profileKey}?pageId=${this.pageId}&isAssisted=true&profileLogs=true`;

        window.open(url, '_blank');
      } else {
        this.profileRouting.toProfile({profileKey}, {pageId: this.pageId, isAssisted: true, profileLogs: true }, true);
      }
    } else {
      this.profileFullView.emit();
      this.profileRouting.toProfile({profileKey}, {pageId: this.pageId, isAssisted: true, profileLogs: true });
    }

  }
}
