import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { CommonSearchBarModule, KalgudiNoContentModule } from '@kalgudi/common';
import { KalgudiBreadcrumbsModule } from '@kalgudi/common/ui/breadcrumbs';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiChipsModule } from '@kalgudi/common/ui/chips';
import { KalgudiProgressStatsModule } from '@kalgudi/common/ui/progress-stats';
import { KalgudiPipesModule } from '@kalgudi/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { KalgudiProjectCreationModule } from '../project-creation/project-creation.module';
import { KalgudiProjectListModule } from '../project-list/project-list.module';
import { KalgudiProjectSharedModule } from '../project-shared/project-shared.module';
import { AllProjectsDashboardComponent } from './components/all-projects-dashboard/all-projects-dashboard.component';
import { AllProjectsStatsComponent } from './components/all-projects-stats/all-projects-stats.component';
import { ProjectDashboardHeaderComponent } from './components/project-dashboard-header/project-dashboard-header.component';
import { ProjectListItemComponent } from './components/project-list-item/project-list-item.component';
import { ProjectsListComponent } from './components/projects-list/projects-list.component';
import { RecentProjectsComponent } from './components/recent-projects/recent-projects.component';
import { KalgudiProjectsDashboardApiService } from './services/kalgudi-projects-dashboard-api.service';
import { KalgudiProjectsDashboardService } from './services/kalgudi-projects-dashboard.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatMenuModule } from '@angular/material/menu';



@NgModule({
  declarations: [
    RecentProjectsComponent,
    ProjectsListComponent,
    ProjectListItemComponent,
    AllProjectsDashboardComponent,
    ProjectDashboardHeaderComponent,
    AllProjectsStatsComponent,
  ],
  imports: [
    CommonModule,

    // Material module
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,

    FlexLayoutModule,

    NgxChartsModule,


    AngularSvgIconModule,

    // Kalgudi modules
    CommonSearchBarModule,
    KalgudiBreadcrumbsModule,
    KalgudiProgressStatsModule,
    KalgudiChipsModule,
    KalgudiButtonModule,
    KalgudiProjectSharedModule,
    KalgudiProjectCreationModule,
    KalgudiPipesModule,
    KalgudiProjectListModule,
    KalgudiNoContentModule,
    TranslateModule
  ],
  exports: [
    RecentProjectsComponent,
    ProjectsListComponent,
    ProjectListItemComponent,
    AllProjectsDashboardComponent,
    ProjectDashboardHeaderComponent,
    AllProjectsStatsComponent
  ],
  providers: [
    KalgudiProjectsDashboardApiService,
    KalgudiProjectsDashboardService
  ]
})
export class KalgudiProjectDashboardModule { }
