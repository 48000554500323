import { KalgudiWeatherDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { IWeatherCache } from '../model';

export class WeatherCache implements IWeatherCache {

  isWeatherCached(latitude: string | number, longitude: string | number, language: string): boolean {
    throw new Error("Method not implemented.");
  }
  constructCacheKey(latitude: string | number, longitude: string | number, language: string): string {
    throw new Error("Method not implemented.");
  }

  cacheWeather(latitude: string | number, longitude: string | number, language: string, weather: KalgudiWeatherDetails): void {
    throw new Error("Method not implemented.");
  }

  getCachedWeather(latitude: string | number, longitude: string | number, language: string): Observable<KalgudiWeatherDetails> {
    throw new Error("Method not implemented.");
  }

}
