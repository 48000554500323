import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { SmsLogsDialogComponent } from '../components/sms-logs-dialog/sms-logs-dialog.component';
import { SmsLogsMobileDialogComponent } from '../components/sms-logs-mobile-dialog/sms-logs-mobile-dialog.component';

@Injectable()
export class LogsActionService {

  constructor(
    private dialogsService: KalgudiDialogsService
  ) { }

  /**
   * Shows Logs list dialog
   */
  showLogsDialog(id: string): Observable<KalgudiDialogResult> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'SMS logs',
      rejectButtonTitle: 'Cancel',
      data: {
        smsId: id
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        smsId: id
      }
    };

    return this.openLogsDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),
      );
  }


  /**
   * Show logs list  web or mobile dialog
   */
  private openLogsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(SmsLogsMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(SmsLogsDialogComponent, dialogConfig, matDialogConfig);
    }

  }

}
