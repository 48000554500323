import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {
  CommonSearchBarModule,
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUserPickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { AddSurveyMembersDialogComponent } from './components/add-survey-members-dialog/add-survey-members-dialog.component';
import {
  AddSurveyMembersMobileDialogComponent,
} from './components/add-survey-members-mobile-dialog/add-survey-members-mobile-dialog.component';
import { AddSurveyMembersComponent } from './components/add-survey-members/add-survey-members.component';
import { SurveyMembersAddBulkComponent } from './components/survey-members-add-bulk/survey-members-add-bulk.component';
import { SurveyMembersAddComponent } from './components/survey-members-add/survey-members-add.component';
import { SurveyMembersStreamComponent } from './components/survey-members-stream/survey-members-stream.component';
import { SurveysMembersPickerComponent } from './components/surveys-members-picker/surveys-members-picker.component';
import {
  SurveysMembersSearchDialogComponent,
} from './components/surveys-members-search-dialog/surveys-members-search-dialog.component';
import {
  SurveysMembersSearchMobileDialogComponent,
} from './components/surveys-members-search-mobile-dialog/surveys-members-search-mobile-dialog.component';
import { SurveysMembersSearchComponent } from './components/surveys-members-search/surveys-members-search.component';
import { SurveyMembersActionService } from './services/survey-members-action.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    AddSurveyMembersDialogComponent,
    AddSurveyMembersMobileDialogComponent,
    AddSurveyMembersComponent,
    SurveyMembersAddBulkComponent,
    SurveyMembersAddComponent,
    SurveyMembersStreamComponent,
    SurveysMembersSearchComponent,
    SurveysMembersSearchDialogComponent,
    SurveysMembersSearchMobileDialogComponent,
    SurveysMembersPickerComponent
  ],
  imports: [
    CommonModule,

    FormsModule,

    ReactiveFormsModule,

    // Material modules
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,

    FlexLayoutModule,


    // Kalgudi modules
    KalgudiUsersModule,
    KalgudiUserPickerModule,
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    CommonSearchBarModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule,
    TranslateModule
  ],
  entryComponents: [
    AddSurveyMembersDialogComponent,
    AddSurveyMembersMobileDialogComponent,
    SurveysMembersSearchDialogComponent,
    SurveysMembersSearchMobileDialogComponent
  ],
  exports: [
    SurveyMembersStreamComponent,
    AddSurveyMembersComponent,
    SurveysMembersPickerComponent,
    SurveysMembersSearchComponent,
  ],
  providers: [
    SurveyMembersActionService
  ]
})
export class KalgudiSurveyMembersModule { }
