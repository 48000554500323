/**
 * HTTP response status codes indicate whether a specific HTTP request has been successfully completed.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */
export enum HttpStatusCode {

  // --------------------------------------------------------
  // #region Information responses
  // --------------------------------------------------------

  /**
   * This interim response indicates that everything so far is OK and that the client
   * should continue the request, or ignore the response if the request is already finished.
   */
  CONTINUE = 100,

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Successful responses
  // --------------------------------------------------------

  /**
   * The request has succeeded. The meaning of the success depends on the HTTP method:
   *  - `GET`: The resource has been fetched and is transmitted in the message body.
   *  - `HEAD`: The entity headers are in the message body.
   *  - `PUT` or `POST`: The resource describing the result of the action is transmitted in the message body.
   *  - `TRACE`: The message body contains the request message as received by the server
   */
  OK = 200,

  /**
   * The request has succeeded and a new resource has been created as a result.
   * This is typically the response sent after `POST` requests, or some `PUT` requests.
   */
  CREATED = 201,

  /**
   * The request has been received but not yet acted upon. It is noncommittal,
   * since there is no way in HTTP to later send an asynchronous response indicating
   * the outcome of the request. It is intended for cases where another process or server
   * handles the request, or for batch processing.
   */
  ACCEPTED = 202,

  /**
   * This response code means the returned meta-information is not exactly the same as
   * is available from the origin server, but is collected from a local or a third-party copy.
   * This is mostly used for mirrors or backups of another resource.
   * Except for that specific case, the "200 OK" response is preferred to this status.
   */
  NON_AUTHORITATIVE_INFORMATION = 203,

  /**
   * There is no content to send for this request, but the headers may be useful.
   * The user-agent may update its cached headers for this resource with the new ones.
   */
  NO_CONTENT = 204,

  /**
   * Tells the user-agent to reset the document which sent this request.
   */
  RESET_CONTENT = 205,

  /**
   * This response code is used when the [Range](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Range)
   * header is sent from the client to request only part of a resource.
   */
  PARTIAL_CONTENT = 206,

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Redirection messages
  // --------------------------------------------------------

  /**
   * The request has more than one possible response. The user-agent or user should choose
   * one of them. (There is no standardized way of choosing one of the responses, but HTML
   * links to the possibilities are recommended so the user can pick.)
   */
  MULTIPLE_CHOICE = 300,

  /**
   * The URL of the requested resource has been changed permanently. The new URL is given
   * in the response.
   */
  MOVED_PERMANENTLY = 301,

  /**
   * This response code means that the URI of requested resource has been changed _temporarily_.
   * Further changes in the URI might be made in the future. Therefore, this same URI
   * should be used by the client in future requests.
   */
  FOUND = 302,

  /**
   * This is used for caching purposes. It tells the client that the response has not been
   * modified, so the client can continue to use the same cached version of the response.
   */
  NOT_MODIFIED = 304,

  /**
   * The server sends this response to direct the client to get the requested resource at
   * another URI with same method that was used in the prior request. This has the same
   * semantics as the `302 Found HTTP` response code, with the exception that the user
   * agent must not change the HTTP method used: If a `POST` was used in the first request,
   * a `POST` must be used in the second request.
   */
  TEMPORARY_REDIRECT = 307,

  /**
   * This means that the resource is now permanently located at another URI, specified by
   * the `Location:` HTTP Response header. This has the same semantics as the
   * `301 Moved Permanently` HTTP response code, with the exception that the user agent
   * must not change the HTTP method used: If a `POST` was used in the first request,
   * a `POST` must be used in the second request.
   */
  PERMANENT_REDIRECT = 308,

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Client error responses
  // --------------------------------------------------------

  /**
   * The server could not understand the request due to invalid syntax.
   */
  BAD_REQUEST = 400,

  /**
   * Although the HTTP standard specifies "unauthorized", semantically this response means
   * "unauthenticated". That is, the client must authenticate itself to get the requested response.
   */
  UNAUTHORIZED = 401,

  /**
   * The client does not have access rights to the content; that is, it is unauthorized,
   * so the server is refusing to give the requested resource. Unlike 401,
   * the client's identity is known to the server.
   */
  FORBIDDEN = 403,

  /**
   * The server can not find requested resource. In the browser, this means the URL is not recognized.
   * In an API, this can also mean that the endpoint is valid but the resource itself does not exist.
   * Servers may also send this response instead of 403 to hide the existence of a resource from
   * an unauthorized client. This response code is probably the most famous one due to its
   * frequent occurrence on the web.
   */
  NOT_FOUND = 404,

  /**
   * The request method is known by the server but has been disabled and cannot be used.
   * For example, an API may forbid DELETE-ing a resource. The two mandatory methods,
   * `GET` and `HEAD`, must never be disabled and should not return this error code.
   */
  METHOD_NOT_ALLOWED = 405,

  /**
   * This response is sent when the web server, after performing
   * [server-driven content negotiation](https://developer.mozilla.org/en-US/docs/HTTP/Content_negotiation#Server-driven_negotiation),
   * doesn't find any content that conforms to the criteria given  by the user agent.
   */
  NOT_ACCEPTABLE = 406,

  /**
   * The HTTP 417 Expectation Failed client error response code indicates that the expectation
   * given in the request's Expect header could not be met.
   */
  EXCEPTION_FAILED = 417,

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Server error responses
  // --------------------------------------------------------

  /**
   * The server has encountered a situation it doesn't know how to handle.
   */
  INTERNAL_SERVER_ERROR = 500,

  /**
   * The request method is not supported by the server and cannot be handled.
   * The only methods that servers are required to support (and therefore that
   * must not return this code) are `GET` and `HEAD`.
   */
  NOT_IMPLEMENTED = 501,

  /**
   * This error response means that the server, while working as a gateway to get a
   * response needed to handle the request, got an invalid response.
   */
  BAD_GATEWAY = 502,

  /**
   * The server is not ready to handle the request. Common causes are a server that is
   * down for maintenance or that is overloaded. Note that together with this response,
   * a user-friendly page explaining the problem should be sent. This responses should be
   * used for temporary conditions and the `Retry-After`: HTTP header should, if possible,
   * contain the estimated time before the recovery of the service. The webmaster must also
   * take care about the caching-related headers that are sent along with this response, as
   * these temporary condition responses should usually not be cached.
   */
  SERVICE_UNAVAILABLE = 503,

  /**
   * This error response is given when the server is acting as a gateway and cannot get a
   * response in time.
   */
  GATEWAY_TIMEOUT = 504,

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
