import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiProgramSearchService } from '../../../../services/kalgudi-program-search.service';
import { KalgudiProgramMembersPicker } from '../../classes/kalgudi-program-members-picker';

@Component({
  selector: 'kl-programs-member-picker',
  templateUrl: './kalgudi-programs-member-picker.component.html',
  styleUrls: ['./kalgudi-programs-member-picker.component.scss']
})
export class KalgudiProgramsMemberPickerComponent extends KalgudiProgramMembersPicker implements OnInit {
  public env: KalgudiEnvironmentConfig;
  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected api: KalgudiProgramSearchService,
  ) {

    // Wake up my parent
    super(notification, util, api);
    this.env = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
  }

  ngOnInit() {

    // this.initStream();
  }

  protected onDestroyed(): void { }

}
