import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyMetaData } from '@kalgudi/types';

@Component({
  selector: 'kl-survey-action-bar',
  templateUrl: './survey-action-bar.component.html',
  styleUrls: ['./survey-action-bar.component.scss']
})
export class SurveyActionBarComponent implements OnInit {

  @Input()
  surveyResponse: SurveyMetaData;

  @Output()
  toFullview = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  /**
   * Event emit back to parent to navigate survey full view
   */
  navigateToFullView() {
    this.toFullview.emit();
  }

}
