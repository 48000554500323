<div class="task-submission-details" *ngIf="submissionDetails?.createdBy as author">
  <div fxLayout fxLayoutGap="20px" fxLayout.xs="column">
    <!-- User tile -->
    <div class="my-1" fxFlex="calc(60% - 20px)">
      <!-- <kl-selectable-user-tile [author]="submissionDetails?.createdBy" avatarSize="md">
      </kl-selectable-user-tile> -->
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" >

        <div fxLayoutAlign="center center" fxFlex="20%">
          <img class="rounded-circle avatar"
            [klErrorImages]="[
              (author?.profilePicURL || author?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
            [src]="(author?.profilePicURL || author?.profilePicUrl | resizeImage: 'sm') | prefixDomain"
          />
        </div>

        <div fxLayout="column">
          <a> <span (click)="viewProfile(author?.profileKey)"
            class="text-capitalize text-truncate user-name">{{ author?.firstName }}</span></a>
          <div fxLayout="column" class="mw-100">
            <span class="biz-name text-truncate text-capitalize text-success">{{ author?.businessTypeName }}</span>
            <span class="text-muted text-truncate text-capitalize user-location">{{ author?.location || author?.locationLong}}</span>
          </div>
        </div>
      </div>

      <!-- Date -->
      <div class="my-1" fxLayout fxLayoutAlign="start center" fxLayoutGap="4px" fxHide.xs>
        <svg-icon src="assets/svgs/calendar.svg" class="text-center menu-icons" [applyCss]="true" [svgStyle]="{ 'width.px': 20, 'height.px': 20}"></svg-icon>

        <div fxLayout="column" fxLayoutAlign="center center">
          <span [ngClass]="{'strick-date': submissionDetails?.timeFrame?.start !== submissionDetails?.updatedTimeFrame?.start || submissionDetails?.timeFrame?.end !== submissionDetails?.updatedTimeFrame?.end}">
            <span *ngIf="submissionDetails?.timeFrame?.start !== submissionDetails?.updatedTimeFrame?.start || submissionDetails?.timeFrame?.end !== submissionDetails?.updatedTimeFrame?.end"><b translate>Ideal date: </b></span>
            <span>
              {{ submissionDetails?.timeFrame?.start | date:'dd' }}
              {{ submissionDetails?.timeFrame?.start | date:'MMM' | translate}}
              {{ submissionDetails?.timeFrame?.start | date:'yyyy' }}
            </span>
            <span *ngIf="submissionDetails?.timeFrame?.start !== submissionDetails?.timeFrame?.end"> <span translate>to</span>
              {{ submissionDetails?.timeFrame?.end | date:'dd' }}
              {{ submissionDetails?.timeFrame?.end | date:'MMM' | translate }}
              {{ submissionDetails?.timeFrame?.end | date:'yyyy' }}
            </span>
          </span>

          <div *ngIf="submissionDetails?.timeFrame?.start !== submissionDetails?.updatedTimeFrame?.start || submissionDetails?.timeFrame?.end !== submissionDetails?.updatedTimeFrame?.end">
            <span><b translate>Actual date: </b></span>
            <span>
              {{ submissionDetails?.updatedTimeFrame?.start | date:'dd' }}
              {{ submissionDetails?.updatedTimeFrame?.start | date:'MMM' | translate }}
              {{ submissionDetails?.updatedTimeFrame?.start | date:'yyyy' }}
            </span>
            <span *ngIf="submissionDetails?.updatedTimeFrame?.start !== submissionDetails?.updatedTimeFrame?.end"> to {{ submissionDetails?.updatedTimeFrame?.end | date:'dd MMM yyyy' }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- /User tile -->

    <div class="mt-1" fxLayout="column" fxLayout.xs="row" fxLayoutAlign="center end" fxLayoutAlign.xs="space-between" fxFlex="calc(40% - 20px)" fxLayoutGap.xs="4px">
      <div class="text-right submitted" fxLayout fxLayoutAlign="center center">
        <mat-icon class="text-muted mr-2" translate>events</mat-icon>
        <p class="text-muted mb-0" fxLayout="row" fxLayout.xs="column">
          <span translate>Submitted on </span>
          <span fxLayoutAlign.xs="start">
            {{ submissionDetails?.stateChangedOn | date: 'dd' || submissionDetails?.LUT | date: 'dd'}}
            {{ submissionDetails?.stateChangedOn | date: 'MMM' || submissionDetails?.LUT | date: 'MMM' | translate}}
            {{ submissionDetails?.stateChangedOn | date: 'yyyy' || submissionDetails?.LUT | date: 'yyyy'}}
          </span>
        </p>
      </div>
      <!-- <div class="mr-1" fxLayout fxLayoutAlign="end center" fxLayoutGap="4px">
        <div class="chip {{ taskDetails?.state | lowercase }} mt-n3">
          <p class="text-white text-center mb-0">{{ submissionDetails?.state | titlecase | trimSpecialCharacters }}</p>
        </div>
      </div> -->

      <!-- Task Status -->
      <div fxLayoutAlign="center center" fxLayoutAlign.xs="start center" class="mb-2">
        <div fxLayoutAlign="center center" class="">
          <p class="m-0 status-label" translate>Status:</p>
        </div>
        <a fxLayout fxLayoutAlign="center center" fxLayoutGap="5px" class="ml-2 text-secondary task-status"
          (click)="updateState()" color="primary">
          {{ submissionDetails?.state | titlecase | trimSpecialCharacters | translate}}
        </a>
        <!-- <mat-menu #menu="matMenu" class="status-menu" >
          <div *ngFor="let task of taskStatus">
            <button mat-menu-item
              [ngClass]="{'status' : submissionDetails?.state === task?.id }"
              (click)="updateState(task?.id)">
              <div fxLayout>
                <mat-icon class="mt-1">{{ task?.matIcon }}</mat-icon>
                <span class="ml-n2"> {{ task?.name }}</span>
              </div>
            </button>
          </div>
        </mat-menu> -->
      </div>
      <!-- /Task Status -->

    </div>
  </div>

  <div class="border-bottom"></div>

  <div class="expansion overflow-auto">

    <!-- Submission form -->
    <div class="task-submission overflow-auto">
      <kl-task-fill
        digitalAssistance="true"
        [memberProfileKey]="author?.profileKey"
        [projectId]="projectId"
        [taskId]="taskId"
        [extraParams]="extraParams"
        [templateId]="taskDetails?.templateId"
        [taskDetails]="taskDetails"
        [submissionDetails]="submissionDetails">
      </kl-task-fill>
    </div>
    <!-- /Submission form -->

  </div>

</div>



