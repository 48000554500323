<section class="edit-profile-seller">

  <!-- Form -->
  <form autocomplete="off" name="sellerConfigForm" [formGroup]="sellerConfigForm">

    <!-- Tax details -->

    <div fxLayout="column" formGroupName="taxDetails">

      <legend translate>Tax details</legend>
      <div fxLayout="row" fxLayoutGap="15px" fxFlex fxLayout.xs="column">

        <!-- PAN number -->
        <div class="form-group" fxLayout="column" fxFlex="calc(50% - 15px)">
          <mat-form-field appearance="outline">
            <mat-label translate>PAN number</mat-label>
            <input matInput type="text" [placeholder]="'Enter PAN number' | translate" formControlName="panNumber">
          </mat-form-field>
        </div>
        <!-- / PAN number -->


        <!-- Provisional GSTIN -->
        <div class="form-group details" fxLayout="column" fxFlex="50%">
          <mat-form-field appearance="outline">
            <mat-label translate>Provisional GSTIN</mat-label>
            <input matInput [placeholder]="'Enter provisional GSTIN' | translate" formControlName="gstinNumber">
          </mat-form-field>
        </div>
        <!-- / Provisional GSTIN -->

      </div>

    </div>


    <!-- Bank account details -->

    <div fxLayout="column" formGroupName="bankAccount">
      <legend translate>Bank account details</legend>
      <div fxLayout="row" fxLayoutGap="15px" fxFlex fxLayout.xs="column">

        <!-- Account holder name -->
        <div class="form-group" fxLayout="column" fxFlex="calc(50% - 15px)">
          <mat-form-field appearance="outline">
            <mat-label translate>Account holder name</mat-label>
            <input matInput type="text" [placeholder]="'Enter account holder name' | translate" formControlName="accountHolderName">
          </mat-form-field>
        </div>
        <!-- / Account holder name -->


        <!-- Account type -->
        <div class="form-group details" fxLayout="column" fxFlex="50%">
          <mat-form-field appearance="outline">
            <mat-label translate>Account type</mat-label>
            <mat-select [placeholder]="'Enter account type' | translate" formControlName="acountType">
              <mat-option *ngFor="let type of  accountType" [value]="type?.id">
                {{type?.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- / Account type -->

      </div>

      <div class="form-group" fxLayout="column">
        <mat-form-field appearance="outline">
          <mat-label translate>Bank name</mat-label>
          <input matInput type="text" [placeholder]="'Enter bank name' | translate" formControlName="bankName">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="15px" fxFlex fxLayout.xs="column">

        <!-- Account number -->
        <div class="form-group" fxLayout="column" fxFlex="calc(50% - 15px)">
          <mat-form-field appearance="outline">
            <mat-label translate>Account number</mat-label>
            <input matInput type="text" [placeholder]="'Enter account number' | translate" formControlName="accountNumber">
          </mat-form-field>
        </div>
        <!-- / Account number -->


        <!-- IFSC code -->
        <div class="form-group details" fxLayout="column" fxFlex="50%">
          <mat-form-field appearance="outline">
            <mat-label translate>IFSC code</mat-label>
            <input matInput [placeholder]="'Enter IFSC code' | translate" formControlName="ifscCode">
          </mat-form-field>
        </div>
        <!-- / IFSC code -->

      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px">
      <kl-flat-button
        label="Update"
        [progress]="progress"
        (clicked)="updatesSellerConfig()"
        [disabled]="!sellerConfigForm?.valid">
      </kl-flat-button>
    </div>


  </form>
  <!-- / Form -->
</section>
