import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-finance-details-dialog',
  templateUrl: './finance-details-dialog.component.html',
  styleUrls: ['./finance-details-dialog.component.scss']
})
export class FinanceDetailsDialogComponent extends KalgudiDialog<FinanceDetailsDialogComponent>  implements OnInit {
  profileKey: string;
  financeFormDetails: any;
  pageId: any;
  isEditFinanceDialog: boolean;

  constructor(
    protected dialogRef: MatDialogRef<FinanceDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig
  ) {
    super(dialogRef);

    this.profileKey = this.data.data.profileKey;
    this.financeFormDetails = this.data.data.financeFormDetails;
    this.pageId = this.data.data.pageId;
    this.isEditFinanceDialog = this.data.data.isEdit;
  }

  ngOnInit(): void { }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: {

      }
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {},
    };
    this.dialogRef.close(result);
  }
}
