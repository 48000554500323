import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment } from '@kalgudi/types';

@Component({
  selector: 'kl-task-activity-updates',
  templateUrl: './task-activity-updates.component.html',
  styleUrls: ['./task-activity-updates.component.scss']
})
export class TaskActivityUpdatesComponent implements OnInit {

  @Input()
  taskActivityUpdates: any;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) { }

  ngOnInit() {}

  /**
   * To open Image in full view
   */
  openFullview(): void {
    if (this.taskActivityUpdates && this.taskActivityUpdates.activity && this.taskActivityUpdates.activity.activityDetails && this.taskActivityUpdates.activity.activityDetails.lstOfAttachments) {
      const images: Attachment[] = this.taskActivityUpdates.activity.activityDetails.lstOfAttachments;
      this.kalgudiPhotoswipe.open(images, this.env.domain, 0).subscribe();
    }
  }
}
