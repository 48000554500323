import { Injectable } from '@angular/core';
import { PageDigitalAssistanceActions, PageDigitalAssistanceStats } from '@kalgudi/types';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DigitalAssistanceStateService {

  readonly activitiesStats$: Observable<PageDigitalAssistanceStats>;
  readonly actionPerformed$: Observable<PageDigitalAssistanceActions>;
  // readonly activitiesStream$: Observable<PageDigitalAssistanceStream[]>;

  private readonly DEFAULT_STATS: PageDigitalAssistanceStats = {
    CROP_CALANDAR: 0,
    PROFILE_UPDATE: 0,
    QA: 0,
    SHAREATHOUGHT: 0
  };


  private daActionSubject = new BehaviorSubject<PageDigitalAssistanceActions>(null);
  private daStatsSubject = new BehaviorSubject<PageDigitalAssistanceStats>(this.DEFAULT_STATS);
  // private daStreamSubject = new BehaviorSubject<PageDigitalAssistanceStream[]>([]);

  constructor() {
    this.activitiesStats$ = this.daStatsSubject.asObservable();
    this.actionPerformed$ = this.daActionSubject.asObservable();
    // this.activitiesStream$ = this.daStreamSubject.asObservable();
  }

  /**
   * Updates the digital assistance stats to the stream
   */
  updateStats(stats: PageDigitalAssistanceStats): void {

    this.daStatsSubject.next(stats);
  }

  /**
   * Updates the action performed on the digital assistance page.
   * The action defines what operation the user choose to perform.
   */
  updateAction(action: PageDigitalAssistanceActions): void {
    this.daActionSubject.next(action);
  }
}
