import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiPageSurveyManageMembers } from '../../classes/page-survey-manage-members';

@Component({
  selector: 'kl-page-survey-manage-members',
  templateUrl: './page-survey-manage-members.component.html',
  styleUrls: ['./page-survey-manage-members.component.scss'],
})
export class PageSurveyManageMembersComponent extends KalgudiPageSurveyManageMembers implements OnInit {

  constructor(
    protected injector: Injector,
  ) {

    super(
      injector,
    );
  }

  ngOnInit() { }

  onDestroyed(): void { }
}
