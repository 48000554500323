import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { KALGUDI_S3_POLICY_MAP, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNgxFormlyMapperService } from '@kalgudi/third-party/ngx-formly';
import { Attachment, KalgudiImageDialogConfig, KalgudiProjectTask, PartialData, TaskTemplateDetails } from '@kalgudi/types';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class KalgudiDetailedTaskCreationForm extends KalgudiDestroyable {

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  taskForm: FormGroup;

  @Input()
  detailedFormData: PartialData;

  @Input()
  templateSchema: TaskTemplateDetails;

  @Output()
  taskFormChange = new EventEmitter<FormGroup>();

  // Formly form fields
  form: FormGroup;
  model: any;
  options: FormlyFormOptions;
  fields: FormlyFieldConfig[];
  isFormlyFormInitialized = false;


  taskDetailedForm: FormGroup;

  // Dependencies
  protected fb: FormBuilder;
  protected imagePickerService: KalgudiImagePickerService;
  protected util: KalgudiUtilityService;
  private formlyJsonschema: FormlyJsonschema;
  private jsonToFormlyMapper: KalgudiNgxFormlyMapperService;

  constructor(protected injector: Injector) {

    super();

    this.fb   = this.injector.get(FormBuilder);
    this.util = this.injector.get(KalgudiUtilityService);
    this.imagePickerService = this.injector.get(KalgudiImagePickerService);
    this.formlyJsonschema   = this.injector.get(FormlyJsonschema);
    this.jsonToFormlyMapper = this.injector.get(KalgudiNgxFormlyMapperService);

    this.taskDetailedForm = this.detailedForm;
  }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Attachments form Uri domain field.
   */
  get attachmentsField(): FormArray {
    return this.taskForm.get('attachments') as FormArray;
  }

  /**
   * project form attachments form array controls
   */
  get attachmentsControls(): FormArray {

    return this.taskForm.controls.attachments as FormArray;
  }

  /**
   * Detailed form group
   */
  private get detailedForm(): FormGroup {

    return this.fb.group({ });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing
  // --------------------------------------------------------


  /**
   * Displays attach image dialog. It uploads the image and injects the latest
   * attached images to the task form.
   *
   * @param attachments List of existing attachments
   */
  attachImage(): void {

    this.showAttachImageDialog(this.attachmentsField.value)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Inject attached images to task form
        map(attachments => this.injectImagesToForm(attachments)),
      )
      .subscribe();
  }


  /**
   * Removes an item from the array
   *
   * @param index Index of image to remove
   */
  removeImage(index: number) {

    this.attachmentsControls.removeAt(index);
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------


  /**
   * Injects the attached images to the task creation form. It also invalidates
   * the task form for any errors.
   *
   * @param attachments Images list to attach
   *
   * @returns Updated share form.
   */
  protected injectImagesToForm(attachments: Attachment[]): FormGroup {
    // Reset previous value in attachments
    this.attachmentsControls.controls = [];

    // Inject attached images
    this.updateAttachments(attachments);

    // Check task form for any errors
    this.taskForm.updateValueAndValidity();

    // Return project form
    return this.taskForm;
  }

  /**
   * Initializes the page form
   */
  protected initTaskForm() {

    const form = this.taskDetailedForm;

    form.patchValue(this.taskForm.value);

    this.taskForm = form;

    this.taskFormChange.emit(this.taskForm);
  }

  /**
   * Initialize formly json schema form fields if not initialized.
   */
  protected initFormlyForm(): void {

    // Do nothing if already initialized
    if (this.isFormlyFormInitialized) {
      return;
    }

    this.fields = [
      this.formlyJsonschema.toFieldConfig(this.templateSchema.schema, {
        map: (mappedField: FormlyFieldConfig, mapSource: any): FormlyFieldConfig => {

          mappedField = this.jsonToFormlyMapper.mapConfiguration(mappedField, mapSource);
          mappedField.templateOptions.disabled = true;

          return mappedField;
        }
      })
    ];

    // Ensure the formly fields does not gets initialized again
    this.isFormlyFormInitialized = true;
  }

  /**
   * Patches the latest detailed form value to the form
   */
  protected patchUpdatedValueToForm(value: any): void {

    if (this.detailedFormData) {
      this.taskForm.patchValue(this.detailedFormData);
    }
  }


  /**
   * Creates form group for every attachment and push it into the form array
   * @param attachments
   */
  private updateAttachments(attachments: Attachment[]) {

    attachments.forEach(attachment => {
      this.attachmentsField.push(this.fb.group({
        url: [attachment.url],
        context:  [attachment.context],
        msgType: [attachment.msgType]
      }));
    });

  }


  /**
   * Shows attach image dialog and returns the list of attachments.
   */
  private showAttachImageDialog(attachedImages: Attachment[]): Observable<Attachment[]> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiImageDialogConfig = {
      title: 'Upload images to the project',
      acceptButtonTitle: 'Attach',
      rejectButtonTitle: 'Cancel',
      data: {
        s3Category: KALGUDI_S3_POLICY_MAP.DEFAULT,
        multiple: true,
        maxImages: 50,
        attachments: this.util.clone<Attachment[]>(attachedImages),
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-attach-image-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.imagePickerService.showImageUpload(dialogDetails, dialogConfig)
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        // Filter only accepted actions, do nothing for cancel actions
        filter(dialogRes => dialogRes.accepted),

        // Transform the partial data to Attachment type
        map(dialogRes => dialogRes.data.attachments as Attachment[])
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
