import { Injector, Input, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiDestroyable, KalgudiError } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUserAwardDetails } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiProfileAwardsService } from '../../../components/sections/awards/kalgudi-profile-awards.service';
import { KalgudiProfileActionService } from '../../../services/kalgudi-profile-action.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';

@Directive()
export abstract class FarmerAwards extends KalgudiDestroyable  {

  @Input()
  awards: KalgudiUserAwardDetails[];

  editable: boolean;

  private awardsService: KalgudiProfileAwardsService;
  private notification: KalgudiNotification;
  private profileActionService: KalgudiProfileActionService;
  private profileService: KalgudiProfileService;
  private dialogsService: KalgudiDialogsService;

  constructor(protected injector: Injector) {

    super();

    this.awardsService        = this.injector.get(KalgudiProfileAwardsService);
    this.notification         = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileActionService = this.injector.get(KalgudiProfileActionService);
    this.profileService       = this.injector.get(KalgudiProfileService);
    this.dialogsService       = this.injector.get(KalgudiDialogsService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

  }


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------


  /**
   * Deletes awards
   */
  deleteAward(awardId: string) {

    this.showDeleteConfirmationDialog()
      .pipe(
        tap(res => this.notification.showSpinner()),

        switchMap(res => this.awardsService.deleteAward(awardId)),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        res => this.onAwardDeleted(res),
        err => this.onAwardFailed(err)
      );
  }

  /**
   * Updates award
   */
  updateAward(awardId: string, awardDetails: KalgudiUserAwardDetails) {

    return this.profileActionService.updateAward(awardId, awardDetails)
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.onAwardUpdated(res),
        err => this.onAwardUpdateError(err)
      );
  }


  /**
   * Shows add awards dialog
   */
  showAddAwardDialog() {

    return this.profileActionService.showAddAwardDialog()
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.onAwardAdded(res),
        err => this.onAwardAddingFailed(err)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // -------------------------------------------------------

  /**
   * Award deleted successfully
   */
  protected onAwardDeleted(res) {
    this.notification.showMessage('Award deleted');
  }

  /**
   * Failed to deleted award
   */
  protected onAwardFailed(err) {
    this.notification.showMessage('Failed to delete award');
  }

  /**
   * Award added successfully
   */
  protected onAwardAdded(res: KalgudiDialogResult) {
    this.notification.showMessage('Added award');
  }

  /**
   * Failed to add award
   */
  protected onAwardAddingFailed(err: KalgudiError) {
    this.notification.showMessage('Failed to add award');
  }

  /**
   * Award updated successfully
   */
  protected onAwardUpdated(res: KalgudiDialogResult) {
    this.notification.showMessage('Award updated');
  }

  /**
   * Failed to update award
   */
  protected onAwardUpdateError(err: KalgudiError) {
    this.notification.showMessage('Failed to update award');
  }


  /**
   * Shows confirmation dialog and returns boolean.
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.dialogsService.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
