import {
  Directive,
  EventEmitter,
  Injector,
  Input,
  Output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { KalgudiUtilityService } from "@kalgudi/core";
import { KL_ROUTE_CONFIG } from "@kalgudi/core/config";
import { takeUntil } from "rxjs/operators";

import { KalgudiProfile } from "../../../classes/kalgudi-profile";
import { KalgudiProfileRouteConfig } from "../../../config";

@Directive()
export abstract class KalgudiCooperativeSocietyProfile extends KalgudiProfile {
  @Input()
  pageId: string;

  @Input()
  profileShareUrl: string;

  @Input()
  showAipredictions: boolean;

  @Input()
  showProfileGrading: boolean = false;

  @Input()
  hasProfileGradingDetail: boolean = false;

  @Input()
  isAssisted: boolean;

  @Input()
  assistedProfileKey: string;

  @Input()
  profileLogs: boolean;

  @Input()
  selectedActivity: string;

  @Input()
  showViewCatalogue: boolean;

  @Input()
  isAdminTasks: boolean;

  @Output()
  closeCropDialog = new EventEmitter();

  loginId: string;

  openAddLand: string;

  private openAddLandDialog = "openAddLandDialog";
  private appRouting: KalgudiProfileRouteConfig;

  constructor(
    protected injector: Injector,
    protected util: KalgudiUtilityService,
    protected route: ActivatedRoute
  ) {
    super(injector, util);
    this.appRouting =
      this.injector.get<KalgudiProfileRouteConfig>(KL_ROUTE_CONFIG);
  }

  /**
   * Calls api to get the login id
   */
  getLoginId() {
    this.profileService
      .getLoginId()
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$)
      )
      .subscribe((res) => {
        this.loginId = res.loginId;
      });
  }

  openFarmPage() {
    this.appRouting.toProfile();
  }

  toAiPrediction() {
    this.appRouting.toAiPrediction({
      profileKey: this.profileDetails.profileKey,
    });
  }

  /**
   * Call after closing add land dialog
   */
  closedLandDialog() {
    this.removeLocalOpenAddLandDialog();
    this.appRouting.toProfile();
  }

  /**
   * Getter to get open add land dialog from local storage
   */
  get openAddLandDialogDetails() {
    return localStorage.getItem(this.openAddLandDialog);
  }

  /**
   * Remove open land dialog from local storage
   */
  private removeLocalOpenAddLandDialog() {
    if (this.openAddLandDialogDetails) {
      localStorage.removeItem(this.openAddLandDialog);
    }
  }

  /**
   * Assign the activated tab details to selected activity
   */
  activatedTabDetails(activity: string) {
    this.selectedActivity = activity;
  }
}
