import { Inject, Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiStreamData, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiDialogConfig, KalgudiPageDetails, KalgudiSms } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { KalgudiPageInboxStream } from '../../../classes/kalgudi-page-inbox-stream';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiSmsStreamStateService } from '../services/kalgudi-sms-stream-state.service';
import { KalgudiSmsUpdateService } from '../services/kalgudi-sms-update.service';
import { LogsListService } from '../services/logs-list.service';



@Directive()
export abstract class KalgudiSmsStream extends KalgudiPageInboxStream<KalgudiSms> {


  memberRole: string;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  // Dependencies
  private kalgudiProgram: KalgudiPageService;
  private smsStreamStateService: KalgudiSmsStreamStateService;
  private kalgudiDialogs: KalgudiDialogsService;
  private updateSms: KalgudiSmsUpdateService;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected smsStream: LogsListService,
    protected util: KalgudiUtilityService,
  ) {

    super(notification, util);

    // Manually inject dependencies
    this.kalgudiProgram         = this.injector.get(KalgudiPageService);
    this.smsStreamStateService  = this.injector.get(KalgudiSmsStreamStateService);
    this.kalgudiDialogs         = this.injector.get(KalgudiDialogsService);
    this.updateSms              = this.injector.get(KalgudiSmsUpdateService);

    this.pageLimit = 10;


    this.kalgudiProgram.pageDetails$
    .pipe(
      first(),
    ).subscribe(pageDetails => {

      this.memberRole = pageDetails.memberRole;
    });
  }

  // --------------------------------------------------------
  // #region for public methods
  // --------------------------------------------------------

  /**
   * Gets the program details
   */
  get program$(): Observable<KalgudiPageDetails> {
    return this.kalgudiProgram.pageDetails$;
  }

  /**
   * Shows confirmation dialog and returns boolean.
   */
  showScheduleSmsConfirmationDialog = (postDetails: KalgudiSms): Observable<KalgudiSms>  => {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Alert',
      acceptButtonTitle: 'Ok',
      rejectButtonTitle: 'Cancel',
      message: 'Updating schedule SMS will reset previous filters and share tags. Are you sure to update?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '400px',
      maxWidth: '400px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),

        switchMap(res => this.showUpdateScheduledSmsDialog(postDetails))
      );
  }


  /**
   * Opens the share update form in update mode
   */
  showUpdateScheduledSmsDialog(postDetails: KalgudiSms): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update SMS',
      acceptButtonTitle: 'Send',
      rejectButtonTitle: 'Cancel',
      data: {
        postDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.updateSms.showScheduleDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res && res.accepted)
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  /**
   * Defines api to call sms stream.
   */
  protected streamApi( offset: number, limit: number): Observable<KalgudiStreamData> {
    return this.smsStream.getSmsStream(this.pageId, offset, limit);
  }

  /**
   * Implement new stream item stream by the child. If the stream
   * supports new item addition to the stream.
   */
  protected get newStreamItem$(): Observable<KalgudiSms> {
    return this.smsStreamStateService.newStreamItem$;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
