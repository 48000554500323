<div id="page-target-filters" [@floatTop]="{ value: '', params: { delay: 0 }}">
  <!-- Heading -->
  <div fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" class="mb-3" *ngIf="!hideTargetAudienceCount">
    <h4 class="m-0 h6">{{ headingLabel | translate }}</h4>

    <div>
      <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign="end center">
        <p class="text-secondary mb-0">
          <span>{{ targetAudienceLabel | translate }}</span>
          <span>{{ targetAudienceCount$ | async | number }}</span>
        </p>

        <a class="cursor-pointer rounded-circle" [matTooltip]="'Refresh targeted members count' | translate" (click)="refreshTargetAudience()">
          <mat-icon class="rounded-circle" matRipple matRippleColor="primary">settings_backup_restore</mat-icon>
        </a>
      </div>
    </div>
  </div>
  <!-- / Heading -->

  <!-- Product and business types filters -->
  <div>

    <!-- Product filter -->
    <div class="mb-2" *ngIf="!hideProductsFilter">
      <kl-share-target-members-filter
        label="Crops"
        searchPlaceholder="Search and select crops"
        [formArray]="productsFormArray"
        [items]="productsList">
      </kl-share-target-members-filter>
    </div>
    <!-- / Product filter -->

    <!-- Business types filter -->
    <div class="mb-2" *ngIf="!hideBusinessFilter">
      <kl-share-target-members-filter
        label="User role"
        searchPlaceholder="Search and select user roles"
        [formArray]="businessTypesFormArray"
        [items]="businessTypesList">
      </kl-share-target-members-filter>
    </div>
    <!-- / Business types filter -->

  </div>
  <!-- /Product and business types filters -->

  <!-- Location -->
  <div *ngIf="!hideLocationFilter">

    <div fxLayout fxLayoutGap="10px" class="mb-2">
      <kl-slide-toggle
        falseLabel="Location"
        trueLabel="Select location range"
        (toggleChanged)="onLocationRangeFilterChange($event);">
      </kl-slide-toggle>
    </div>

    <!-- Locations filter -->
    <div *ngIf="!locationRangeFilter">
      <kl-share-target-members-filter
        label="Locations"
        searchPlaceholder="Search and select locations"
        [formArray]="locationsFormArray"
        [items]="locationsList">
      </kl-share-target-members-filter>
    </div>
    <!-- / Locations filter -->

    <!-- Location range filter -->
    <div *ngIf="locationRangeFilter">
      <kl-share-location-range-filter
        [countries]="countriesList"
        [countriesFormArray]="countriesFormArray"
        [states]="statesList"
        [statesFormArray]="statesFormArray"
        [districts]="districtsList"
        [districtsFormArray]="districtsFormArray">
      </kl-share-location-range-filter>
    </div>
    <!-- /Location range filter -->
  </div>
  <!--/ Location -->

</div>
