<mat-card class="network-author-details" (click)="viewProfile(author?.profileKey)">

  <div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">

    <!-- user profile pic -->
    <div fxLayoutAlign="center center" class="profile-pic rounded-circle"
      [ngClass]="{'medium': avatarSize == 'md', 'small': avatarSize == 'sm', 'large': avatarSize == 'lg'}">
      <img class="w-100 h-100 rounded-circle"
        [src]="(author?.profilePicURL | resizeImage:'sm') | prefixDomain"
        [klErrorImages]="[
        (author?.profilePicURL) | prefixDomain,
        domain + defaultProfilePic
        ]"
      />
    </div>

    <!-- user Details -->
    <div fxFLex="100%" class="author-details text-center">
      <a class="text-capitalize d-block text-truncate author-name mb-0">
        {{ author?.firstName }}
      </a>
      <p class="biz-type text-truncate mb-0" *ngIf="author?.businessName">
        {{ author?.businessName }}
      </p>
      <p class="mb-0 time text-truncate text-muted" *ngIf="author?.location">
        {{ author?.location }}
      </p>
    </div>
  </div>
</mat-card>
