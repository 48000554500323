import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { KalgudiProgramListService } from '../../program-list/services/kalgudi-program-list.service';
import { MyKalgudiProgramsList } from './my-programs-list';

@Component({
  selector: 'kl-my-programs-list',
  templateUrl: './my-programs-list.component.html',
  styleUrls: ['./my-programs-list.component.scss'],
  animations: kalgudiAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyProgramsListComponent extends MyKalgudiProgramsList implements OnInit {


  constructor(
    protected programsService: KalgudiPageService,
    @Inject(KL_ROUTE_CONFIG) protected appRouting: KalgudiProgramsRouteConfig,
    protected pageListService: KalgudiProgramListService,
  ) {


    super(
      programsService,
      appRouting,
      pageListService
    );
  }

  ngOnInit() { }

}
