import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { LazyHttpClientInterceptor } from '../model';

/**
 * Interceptor for lazy http
 */
@Injectable()
export class FailedHttpResponseInterceptorService implements LazyHttpClientInterceptor {

  constructor() { }

  canCacheRequest(res?: any, err?: HttpErrorResponse): boolean {

    return (!navigator.onLine && err instanceof HttpErrorResponse && err.status === 0);
  }
}
