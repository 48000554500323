import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'kl-common-page-form',
  templateUrl: './common-page-form.component.html',
  styleUrls: ['./common-page-form.component.scss']
})
export class CommonPageFormComponent implements OnInit {

  @Input()
  commonInfoForm: FormGroup;

  /**
   * Get, the Mobile number from page details form
   */
  get mobileNoField(): AbstractControl {
    return this.commonInfoForm.get('mobileNo');
  }

  /**
   * Get, the Email id from page details form
   */
  get emailIdField(): AbstractControl {
    return this.commonInfoForm.get('emailId');
  }

  constructor() { }

  ngOnInit() {
  }

}
