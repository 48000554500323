import { Component, forwardRef, Injector, OnInit, Provider } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { fadeInAnimation } from '@kalgudi/core';

import { ShareSpecificMembersVisibility } from './share-specific-members-visibility';

// Share tags NG_VALUE_ACCESSOR provider for custom form control
const SPECIFIC_MEMBER_FORM_CONTROL_PROVIDER: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ShareSpecificMemberVisibilityComponent),
  multi: true,
};

@Component({
  selector: 'kl-share-specific-member-visibility',
  templateUrl: './share-specific-member-visibility.component.html',
  styleUrls: ['./share-specific-member-visibility.component.scss'],
  providers: [ SPECIFIC_MEMBER_FORM_CONTROL_PROVIDER ],
  animations: [ fadeInAnimation ]
})
export class ShareSpecificMemberVisibilityComponent extends ShareSpecificMembersVisibility implements OnInit {

  constructor(
    protected injector: Injector,
  ) {
    super(injector)
  }

  ngOnInit() {
  }

}
