import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonSearchBarModule, KalgudiLoadMoreModule, KalgudiNoContentModule, KalgudiUsersModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { TranslateModule } from '@ngx-translate/core';

import {
  KalgudiShareMemberPickerDialogComponent,
} from './components/kalgudi-share-member-picker-dialog/kalgudi-share-member-picker-dialog.component';
import {
  KalgudiShareMemberPickerMobileDialogComponent,
} from './components/kalgudi-share-member-picker-mobile-dialog/kalgudi-share-member-picker-mobile-dialog.component';
import {
  KalgudiShareMemberPickerComponent,
} from './components/kalgudi-share-member-picker/kalgudi-share-member-picker.component';
import {
  KalgudiShareSelectedMemberComponent,
} from './components/kalgudi-share-selected-member/kalgudi-share-selected-member.component';




@NgModule({
  declarations: [
    KalgudiShareMemberPickerDialogComponent,
    KalgudiShareMemberPickerMobileDialogComponent,
    KalgudiShareMemberPickerComponent,
    KalgudiShareSelectedMemberComponent
  ],
  imports: [
    CommonModule,

    // Material Modules
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatDialogModule,

    FlexLayoutModule,
    // Kalgudi libraries
    KalgudiButtonModule,
    CommonSearchBarModule,
    KalgudiLoadMoreModule,
    KalgudiNoContentModule,
    KalgudiUsersModule,

    TranslateModule

  ],
  exports: [
    KalgudiShareMemberPickerComponent,
    KalgudiShareMemberPickerDialogComponent,
    KalgudiShareSelectedMemberComponent
  ],
  entryComponents: [
    KalgudiShareMemberPickerDialogComponent,
    KalgudiShareMemberPickerMobileDialogComponent,
  ]
})
export class ShareMemberPickerModule { }
