import { Directive, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { KalgudiFcmNotification } from '../config';
import { KalgudiFcmService } from '../kalgudi-fcm.service';

export abstract class KalgudiNotification  {

  public notification$: Observable<KalgudiFcmNotification>;

  notificationActive = false;

  private fcmService: KalgudiFcmService;
  private router: Router;

  constructor(protected injector: Injector) {

    this.fcmService = this.injector.get<KalgudiFcmService>(KalgudiFcmService);
    this.router     = this.injector.get<Router>(Router);


    this.notification$ = this.fcmService.notifications$
      .pipe(
        tap((res: KalgudiFcmNotification) => {

          if (this.router.url !== res.data.deepLinkUrl) {
            this.notificationActive = true;
          }
        })
      );
  }


  /**
   * Navigates to the page
   */
  openNotification(notification: KalgudiFcmNotification): void {
    this.notificationActive = false;
    this.router.navigateByUrl(notification.data.deepLinkUrl);
  }
}
