import { Inject, Injector, Input, Directive } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiTasksGrid } from '@kalgudi/project-manager';
import {
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiProjectTask,
  PartialData,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { KalgudiProgramsRouteConfig } from '../../../config';
import { PageActions } from '../../../constants';
import { ProgramStateService } from '../../../services/program-state.service';
import { PageTaskMembersDialogComponent } from '../components/page-task-members-dialog/page-task-members-dialog.component';
import {
  PageTaskMembersMobileDialogComponent,
} from '../components/page-task-members-mobile-dialog/page-task-members-mobile-dialog.component';

@Directive()
export abstract class KalgudiPageTaskList extends KalgudiTasksGrid {

  @Input()
  pageId: string;

  @Input()
  projectId: string;

  memberRole: string;

  readonly memberRoles = KALGUDI_PAGE_RELATION_MAP;

  private programState: ProgramStateService;
  private dialogsService: KalgudiDialogsService;
  private appProgramRouting: KalgudiProgramsRouteConfig;

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected dialog: MatDialog,
    protected injector: Injector
  ) {

    super(notification, util, dialog, injector);

    this.programState            = this.injector.get(ProgramStateService);
    this.dialogsService          = this.injector.get(KalgudiDialogsService);
    this.appProgramRouting       = this.injector.get<KalgudiProgramsRouteConfig>(KL_ROUTE_CONFIG);

    this.memberRole = this.programState.data.memberRole;

    /**
     * On `ADD_PROJECT` state action changed
     */
    this.programState.action$
      .pipe(
        takeUntil(this.destroyed$),

        filter(action => action.type === PageActions.ADD_TASK),
      )
      .subscribe(res => this.showAddTaskCreationDialog() );
  }

  /**
   * Opening task creation dialog
   */
  showAddTaskCreationDialog() {
    this.updateProjectDetails(this.projectId);
    this.openTaskCreationDialog();
  }

  /**
   * Opens task full view dialog
   *
   * @override
   */
  openTaskFullviewDialog(title: string, taskId: string, taskDetails: KalgudiProjectTask, projectId = this.projectId) {

    this.appProgramRouting.toProgramTaskFullview({pageId: this.pageId, taskId});

    // this.notification.showSpinner(true);

    // // Get the project details then load the task fullview
    // this.projectService.getProject(projectId, this.filters)
    //   .pipe(
    //     takeUntil(this.destroyed$),

    //     finalize(() => this.notification.hideSpinner()),

    //     switchMap(_ => this.showPageTaskFullviewDialog(title, taskId, taskDetails, projectId))
    //   )
    //   .subscribe(
    //     res => {
    //       // super.openTaskFullviewDialog(title, taskId, taskDetails);
    //     },
    //     err => {
    //       this.notification.showMessage('Unable to get project details.');
    //     }
    //   );
  }

  /**
   * Extra filters to get the tasks, In this case getting tasks under the page
   */
  get filters(): PartialData {
    return {
      pageId: this.pageId,
      sortBy: 'date'
    };
  }

  /**
   * Shows project page members management dialog
   */
  showMembersManagementDialog(projectId: string, taskId: string) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Task members',
      acceptButtonTitle: 'Add members',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId,
        taskId,
        pageId: this.pageId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    this.openPageTaskMembersManagementDialog(dialogDetails, dialogConfig)
      .pipe(
        takeUntil(this.destroyed$),

        // filter(r => r.accepted),
      )
      .subscribe(_ => this.resetStream());
  }

  /**
   * Shows the page task members management web or mobile dialog
   */
  private openPageTaskMembersManagementDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(PageTaskMembersMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(PageTaskMembersDialogComponent, dialogConfig, matDialogConfig);
    }

  }

}
