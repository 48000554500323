import { Component, Inject, Input, OnInit } from '@angular/core';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiPhotoswipeService } from '@kalgudi/third-party/photoswipe';
import { Attachment } from '@kalgudi/types';

@Component({
  selector: 'kl-post-tile-image-attachment',
  templateUrl: './post-tile-image-attachment.component.html',
  styleUrls: ['./post-tile-image-attachment.component.scss']
})
export class PostTileImageAttachmentComponent implements OnInit {

  @Input()
  attachments: Attachment[];

  @Input()
  url: string;

  isMobileDevice = checkMobileDevice();

  slideConfig = {
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    centerMode: false,
    infinite: false,
    variableWidth: true
  };


  constructor(
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig,
    private kalgudiPhotoswipe: KalgudiPhotoswipeService,
  ) {
  }

  ngOnInit() {
  }


  openFullview(): void {
    const images: Attachment[] = this.url ? [{url: this.url, msgType: 'IMAGE'}] : this.attachments;

    this.kalgudiPhotoswipe.open(images, this.env.domain, 0).subscribe();
  }
}
