import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommonV1, KalgudiCountryDetails } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpStatusCode } from '../constants';
import { ApiError } from '../errors';
import { KalgudiUtilityService } from './kalgudi-util.service';

@Injectable({
  providedIn: 'root'
})
export class KalgudiCountryDetailsService {

  private readonly PROFILE_BASE = `${this.environment.restBaseUrl}/profiles`;

  /**
   * `v1/profiles/simplesignup`
   */
  private readonly COUNTRY_DETAILS = `${this.PROFILE_BASE}/coudtls`;

  private countryDetailsLocalKey = 'country';

  private currentCountryDetails: KalgudiCountryDetails;

  private countryDetailsUpdated = new Subject<KalgudiCountryDetails>();

  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) {

    // Initialize country details
    this.initCountryDetails();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Gets the country details stored locally
   */
  get countryDetails(): KalgudiCountryDetails {
    return this.currentCountryDetails;
  }

  /**
   * Observable emitted on latest country details updated.
   */
  get $countryDetailsUpdated(): Subject<KalgudiCountryDetails> {
    return this.countryDetailsUpdated;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Initializes country details object. If country details are present in local storage
   * it fetches the country details from local storage. Otherwise fetches from the API.
   */
  private initCountryDetails(): void {

    // Fetch country details from local
    this.currentCountryDetails = this.getCountryDetailsFromLocal();

    // Country details does not exists in memory cache
    if (!this.currentCountryDetails) {

      // Try fetching country details from API
      this.fetchCountryDetails().subscribe();
    }
  }

  /**
   * Gets, country details stored in local storage.
   */
  private getCountryDetailsFromLocal(): KalgudiCountryDetails {

    // Returned the parsed object
    return this.util.getFromLocal<KalgudiCountryDetails>(this.countryDetailsLocalKey);
  }

  /**
   * Sets, country details to local storage
   *
   * @param countryDetails Country details to set to local storage
   */
  private setCountryDetailsToLocal(countryDetails: KalgudiCountryDetails): void {

    this.util.setToLocal(this.countryDetailsLocalKey, countryDetails);
  }

  /**
   * Fetches, latest country details from API. It fires `$countryDetailsUpdated`
   * on success.
   */
  private fetchCountryDetails(): Observable<KalgudiCountryDetails> {

    return this.httpClient.get<ApiResponseCommonV1>(this.COUNTRY_DETAILS)
      .pipe(
        map(r => this.mapCountryDetails(r)),
        tap(r => this.onCountryDetailsFetch(r))
      );
  }

  /**
   * Maps, the API V1 response to Kalgudi Country details object.
   *
   * @param res Country details API response
   */
  private mapCountryDetails(res: ApiResponseCommonV1): KalgudiCountryDetails {

    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error('Unable to fetch country details'));
    }

    return this.util.toJson<KalgudiCountryDetails>(res.data);
  }

  /**
   * Updates the existing Kalgudi Country details in memory and local storage.
   * It fires `$countryDetailsUpdated` on success.
   *
   * @param countryDetails Country details to update
   */
  private onCountryDetailsFetch(countryDetails: KalgudiCountryDetails): KalgudiCountryDetails {

    // Update country details in memory
    this.currentCountryDetails = countryDetails;

    // Update country details in local storage
    this.setCountryDetailsToLocal(countryDetails);

    // Fire country details updated event
    this.$countryDetailsUpdated.next(countryDetails);

    // Return the latest country details object
    return this.currentCountryDetails;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
