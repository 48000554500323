import { Injectable, OnDestroy } from '@angular/core';
import { KalgudiAppService, KalgudiUtilityService, KalgudiWebNotificationsService } from '@kalgudi/core';
import { NotificationsList } from '@kalgudi/types';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { KalgudiHomeStreamApiService } from './kalgudi-home-stream-api.service';
import { KalgudiStreamS3FetchService } from './kalgudi-stream-s3-fetch.service';

@Injectable()
export class KalgudiHomeStreamService implements OnDestroy {

  /**
   * Maximum notifications to fetch per API request.
   */
  private readonly WEB_NOTIFICATION_LIMIT = 20;

  /**
   * Default count to serve notifications to the view
   */
  private readonly DEFAULT_SERVING_LIMIT  = 20;

  /**
   * Stream of items fetched from feed API.
   */
  private readonly homeStream = new BehaviorSubject<NotificationsList[]>([]);

  /**
   * Stream of new items added through various social sub modules.
   */
  private readonly newStreamItem = new BehaviorSubject<NotificationsList>(null);

  private readonly destroyed$ = new Subject();

  constructor(
    private kalgudiApp: KalgudiAppService,
    private util: KalgudiUtilityService,
    private webNotifications: KalgudiWebNotificationsService,
    private homeApi: KalgudiHomeStreamApiService,
    private s3Fetch: KalgudiStreamS3FetchService,
  ) {

    // Logout, un subscribe to the web notification call
    this.kalgudiApp.logout$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(r => this.clearStream());
  }

  /**
   * Called once, before the instance is destroyed.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------


  /**
   * Gets, the current home stream. Home stream is a combination of
   * two separate streams i.e.  web notification API content notification
   * stream and feed API stream.
   *
   * Returns a combined stream of web notification and feed API.
   */
  get stream$(): Observable<NotificationsList[]> {

    // Merge notifications from two different sources
    // i.e. web notification and feed API response
    return this.webNotification$
      .pipe(
        switchMap(web =>
          this.homeStream$
            .pipe(

              // Combine streams from two sources i.e. web notification and feed API
              // to single. Map two separate stream arrays to single
              map(home => [...web, ...home])
            )
        ),
      );
  }

  /**
   * Gets, total items available in current home stream to load.
   * Returns the total rendered as well as non-rendered stream items
   * count.
   */
  get streamItemsCount$(): Observable<number> {

    return this.stream$
      .pipe(

        // Return the length of the stream items
        map(r => r.length)
      );
  }

  /**
   * Gets, new stream item created using various social sub modules.
   * It emits the latest value in the stream.
   */
  get newStreamItem$(): Observable<NotificationsList> {
    return this.newStreamItem
      .pipe(

        // Filter all null values
        filter(v => v !== null),
      );
  }

  /**
   * Clears home stream
   */
  clearStream(): void {
    this.homeStream.next([]);
  }

  private get webNotification$(): Observable<NotificationsList[]> {
    return this.webNotifications.stream$;
  }

  private get homeStream$(): Observable<NotificationsList[]> {
    return this.homeStream;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Fetches home stream direct from API. You must fetch the home stream
   * from API after first 40 calls from the
   */
  getHomeStream(offset: number, limit: number): Observable<NotificationsList[]> {

    // Fetch home stream notification stream
    return this.getStreamNotifications(offset, limit)
      .pipe(

        //
        mergeMap(r =>
          combineLatest(
            r.map(s => this.s3Fetch.fetchStreamItem(
              s,
              this.util.getAbsoluteUrl(s.url),      // Absolute url mapping is required for localhost
              s.event
            ))
          )
        ),
      );
  }

  /**
   * Adds a new item to the stream.
   */
  unshiftStreamItem(item: NotificationsList): void {

    this.newStreamItem.next(item);
  }

  /**
   * Fetches sms template list
   * @returns
   */
  getSmsTemplateList(): Observable<any> {
    return this.homeApi.getSmsTemplateList();
  }


  /**
   * Fetches latest advisory list
   * @returns
   */
  getLatestAdvisory(profileKey: string,landId: string): Observable<any> {
    return this.homeApi.getLatestAdvisory(profileKey, landId);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Gets, home stream notifications list from the web notification or
   * API call.
   *
   * The first 40 home stream notifications are fetched from web notifications.
   * Later notifications are served from feeds API.
   *
   * Subscribing to this immediately serves the requested amount of notification
   * if available in web notification stream. It also ensures that it always has
   * notifications available to serve for the next call.
   *
   * @param offset Requested offset of items to load
   * @param limit Request limit of items to load
   */
  private getStreamNotifications(offset: number, limit: number): Observable<NotificationsList[]> {

    return this.stream$
      .pipe(
        // Consume only first stream item
        take(1),

        // Fetch more stream items before it gets exhausted
        map(r => this.fetchItemsIfStreamDying(r, offset)),

        // Slice total web notifications urls into requested urls
        map(r => r.slice(offset, offset + limit)),
      );
  }

  /**
   * Fetches more stream items if the stream is about to exhaust.
   *
   * @param stream Current list of items in stream
   * @param offset Requested stream offset by view
   */
  private fetchItemsIfStreamDying(stream: NotificationsList[], offset: number): NotificationsList[] {

    // Fetch more stream items before it gets exhausted in next call
    if (this.isNotificationStreamDying(stream, offset)) {

      this.fetchHomeStream(offset + this.DEFAULT_SERVING_LIMIT)
        .pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe();
    }

    // Return the current stream items
    return stream;
  }

  /**
   * Fetches home stream s3 urls from the API. It emits the latest home stream urls
   * fetched from the API to the `homeStream` stream.
   *
   * @param offset Offset of results to fetch, must be greater or equal to 40
   * @param limit Number of s3 urls to fetch. Default set to 40.
   */
  private fetchHomeStream(offset: number, limit = this.WEB_NOTIFICATION_LIMIT): Observable<NotificationsList[]> {

    // Fetch profile from the profiles stream
    return this.kalgudiApp.profile$
      .pipe(
        // Switch from profile stream to home stream response
        switchMap(p => this.homeApi.fetchHomeStream(p.profileKey, offset, limit)),

        // Emit API response to the `homeStream` observable stream.
        tap(r => this.homeStream.next(

          // NOTE: You must concatenate previous value and emit the
          // concatenated value of previous stream items and new stream items
          this.homeStream.getValue().concat(r)
        )),
      );
  }

  /**
   * Checks if there are any items in the notification stream or not.
   *
   * @returns `true` if notification stream has got some item otherwise `false`.
   */
  private isNotificationStreamExhausted(stream: NotificationsList[]): boolean {
    return stream.length === 0;
  }

  /**
   * Returns true if notification stream will get exhausted in next call. A notification
   * stream will get exhausted soon if there are only 20 items left to serve.
   *
   * @param stream List of notification stream items
   * @param requestedOffset Current offset to serve notification stream
   *
   * @returns `true` if notification stream will die next call, otherwise `false`.
   */
  private isNotificationStreamDying(stream: NotificationsList[], requestedOffset: number): boolean {

    return stream.length - requestedOffset === this.DEFAULT_SERVING_LIMIT;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
