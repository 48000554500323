import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'core-store-page',
  templateUrl: './store-page.component.html',
  styleUrls: ['./store-page.component.scss']
})
export class StorePageComponent implements OnInit {

  ordersUrl = this.env.appId === 'UBM' ? `${this.env.storeDomain}/profile/orders` : `${this.env.storeDomain}/account/my-orders`;

  constructor(@Inject(KL_ENV) public env: KalgudiEnvironmentConfig) { }

  ngOnInit() {
  }

}
