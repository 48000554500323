<section id="update-tiles">
  <!--About user details tile-->
  <div class="update-data" fxLayout="column">

    <!-- Business details -->
    <p class="head-text mb-2 mt-2" *ngIf="appId !== 'KUBER'" translate>Business Info</p>
    <!-- Business Name -->
    <div class="title-data" *ngIf="user?.additionalBizDetails?.nativeBusinessTypeName && appId !== 'KUBER'" fxLayout="column" fxFlex="calc(50% - 15px)">
      <p class="mb-0 info" translate>Business name</p>
      <span class="info-data mb-1">{{user?.additionalBizDetails?.nativeBusinessTypeName}}</span>
    </div>
    <!-- / Business Name-->

    <!-- About us -->
    <div class="title-data" *ngIf="user?.aboutMe" fxLayout="column" fxFlex="calc(50% - 15px)">
      <p class="mb-0 info" translate>Description</p>
      <span class="info-data mb-1">{{user?.aboutMe}}</span>
    </div>
    <!--/ About us -->

    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px" fxLayoutGap.xs="0px">
      <!-- Gender -->
      <div class="title-data" *ngIf="user?.gender" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info" translate>Gender</p>
        <span class="info-data mb-1">{{ user?.gender | titlecase }}</span>
      </div>
      <!--/ Gender -->

      <!-- Date of birth -->
      <div class="title-data" *ngIf="user?.dateOfBirth" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info" translate>Date of birth</p>
        <span class="info-data mb-1">
          {{ user?.dateOfBirth | date:'dd' }}
          {{ user?.dateOfBirth | date:'MMM' | translate}}
          {{ user?.dateOfBirth | date:'yyyy' }}
        </span>
      </div>
      <!--/ Date of birth -->
    </div>

    <!-- / Business Description-->
    <!-- <div fxLayout="column" *ngIf="user?.lstOfUserBusinessDetailsInfo[0].briefDescription">
      <p class="mb-0 info">Description</p>
      <p class="text-content info-data mb-1" klHideContent lineHeight="18" visibleLine="3">
        {{user?.lstOfUserBusinessDetailsInfo[0].briefDescription}}
      </p>
    </div> -->
    <!-- / Business Description-->

    <!-- / Business details -->

    <div class="mb-1" fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.xs="column" fxLayoutGap.xs="0px">

      <!-- Founded on -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.establishmentDate" fxLayout="column"
        fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Founded on</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.establishmentDate}}</p>
      </div> -->
      <!-- / Founded on-->

      <!-- Employees -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.noOfEmployees" fxLayout="column"
        fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Employees</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.noOfEmployees}}</p>
      </div> -->
      <!--/ Employees -->

      <!-- Registration Number -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.orgRegNumber" fxLayout="column"
        fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Registration Number</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.orgRegNumber}}</p>
      </div> -->
      <!-- /  Registration Number -->

      <!-- License Number -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.licenseNumber" fxLayout="column"
        fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">License Number</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.licenseNumber}}</p>
      </div> -->
      <!-- / License Number-->

      <!-- Select Industry-->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.industryName" fxLayout="column"
        fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Industry</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.industryName}}</p>
      </div> -->
      <!-- / Select Industry-->

      <!-- Organization type -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.orgType" fxLayout="column"
        fxFlex="50%">
        <p class="mb-0 info">Organization type</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.orgType}}</p>
      </div> -->
      <!-- /  Organization type -->
    </div>

    <!-- Contact Info -->
    <p class="head-text mb-2" *ngIf="appId !== 'KUBER'" translate>Contact Info</p>


    <div class="mb-1" fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.xs="column" fxLayoutGap.xs="0px"
      *ngIf="appId === 'KUBER' ? (appId === 'KUBER' && user?.profileKey === loggedInUser) : true">
      <!-- Location  -->
      <div class="title-data pb-2" fxLayout="column" *ngIf="user?.address2" fxFlex="calc(81% - 15px)">
        <div fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start center">
          <i class="fas fa-map-marker-alt location"></i>
          <p class="mb-0 info" translate>Location</p>
        </div>
        <p class="info-data mb-0">
          <span>{{ user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName }}, </span>
          <span>{{ user?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.locationLong }}</span>
        </p>
      </div>
      <!-- / Location -->

      <!-- Email id -->
      <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.emailId" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info" translate>Email</p>
        <p class="info-data mb-0">{{user?.lstOfUserBusinessDetailsInfo[0]?.emailId}}</p>
      </div>
      <!-- /  Email id -->

      <!-- Phone Number -->
      <div class="title-data pb-2" *ngIf="user?.alternateMobileNo || user?.mobileNo" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info" translate>Phone Number</p>
        <p class="info-data mb-0">{{ editable ? (user?.alternateMobileNo || '-') : ((user?.alternateMobileNo || user?.mobileNo) | secureMobile) }}</p>
      </div>
      <!-- / Phone Number-->
    </div>
    <!-- / Contact Info -->

    <!-- Social Info -->
    <!-- <p class="head-text mb-2">Social Info</p> -->

    <div class="mb-1" fxLayout="row wrap" fxLayoutGap="15px grid" fxLayout.xs="column">
      <!-- Email -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.emailId" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Email</p>
        <a class="info-data text-truncate"
          href="mailto:user?.lstOfUserBusinessDetailsInfo[0]?.emailId" target="_blank">
          {{user?.lstOfUserBusinessDetailsInfo[0]?.emailId}}</a>
      </div> -->
      <!-- / Email-->

      <!-- Website -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.website" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Website</p>
        <a class="info-data text-truncate" href="{{user?.lstOfUserBusinessDetailsInfo[0]?.website}}"
          target="_blank">{{user?.lstOfUserBusinessDetailsInfo[0]?.website}}</a>
      </div> -->
      <!-- / Website -->

      <!-- Facebook -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.facebookLink" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Facebook</p>
        <a class="info-data text-truncate" href="{{user?.lstOfUserBusinessDetailsInfo[0]?.facebookLink}}"
          target="_blank">{{user?.lstOfUserBusinessDetailsInfo[0]?.facebookLink}}</a>
      </div> -->
      <!-- / Facebook-->

      <!-- Twitter -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.twitterLink" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Twitter</p>
        <a class="info-data text-truncate" href="{{user?.lstOfUserBusinessDetailsInfo[0]?.twitterLink}}"
          target="_blank">{{user?.lstOfUserBusinessDetailsInfo[0]?.twitterLink}}</a>
      </div> -->
      <!-- / Twitter -->

      <!-- Linkedin -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.linkedInLink" fxLayout="column" fxFlex="calc(50% - 15px)">
        <p class="mb-0 info">Linkedin</p>
        <a class="info-data text-truncate" href="{{user?.lstOfUserBusinessDetailsInfo[0]?.linkedInLink}}"
          target="_blank">{{user?.lstOfUserBusinessDetailsInfo[0]?.linkedInLink}}</a>
      </div> -->
      <!-- / Linkedin-->

      <!-- Wikipedia -->
      <!-- <div class="title-data pb-2" *ngIf="user?.lstOfUserBusinessDetailsInfo[0]?.website" fxLayout="column" fxFlex="50%">
        <p class="mb-0 info">Wikipedia</p>
        <a class="info-data text-truncate" href="{{user?.lstOfUserBusinessDetailsInfo[0]?.website}}"
          target="_blank">{{user?.lstOfUserBusinessDetailsInfo[0]?.website}}</a>
      </div> -->
      <!-- / Wikipedia -->
    </div>
    <!-- / Social Info -->
  </div>

  <!--/About user details tile-->
</section>
