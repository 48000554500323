import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { IdValueMap } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiAddCrop } from '../../classes/kalgudi-add-crop';

@Component({
  selector: 'kl-add-crop',
  templateUrl: './add-crop.component.html',
  styleUrls: ['./add-crop.component.scss']
})
export class AddCropComponent extends KalgudiAddCrop implements OnInit {

  @Input()
  aka: string;

  @Input()
  selectedBaseCategory: IdValueMap;

  domain: string;

  label: string;

  type: string;
  constructor(
    protected injector: Injector,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) {

    super(injector);

    this.addCropForm = this.newCropForm;

    this.domain = this.env.coreDomain;

    this.label = (this.env.appId === 'TRADERS_APP') ? 'Save commodity' : 'Save crop';

    this.type = (this.env.appId === 'TRADERS_APP') ? 'commodity' : 'crop';
  }

  public ngOnInit(): void {

    if (this.product) {

      this.baseProductData = {
        productId: this.product[0].baseProductId,
        productName: this.product[0].baseProductName,
        attachments: this.product[0].baseProductAttachments,
        baseCategory: this.product[0].baseCategory,
        storeType: this.product[0].storeType as any
      };

      this.getProductVarieties(this.baseProductData.productId);

    } else {


    this.cropField.valueChanges
    .pipe(
      takeUntil(this.destroyed$),
    )
    .subscribe(
      (res) => {
        this.baseProductData = res;

        if (this.baseProductData && this.baseProductData.productId) {
          this.getProductVarieties(this.baseProductData.productId);
        }
      },
    );

    }
  }

  onDestroyed(): void {}

}
