import { Component, Inject, Input, OnInit } from '@angular/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask } from '@kalgudi/types';

import { KalgudiProfileRouteConfig } from '../../../../config';

@Component({
  selector: 'kl-user-admin-tasks',
  templateUrl: './user-admin-tasks.component.html',
  styleUrls: ['./user-admin-tasks.component.scss']
})
export class UserAdminTasksComponent implements OnInit {

  @Input()
  userAdminTasks: any[];

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProfileRouteConfig,
  ) { }

  ngOnInit() {
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullview(taskDetails: KalgudiProjectTask) {

    if (taskDetails.pageId) {

      this.appRouting.toProgramTaskFullview( { pageId: taskDetails.pageId, taskId: taskDetails.taskId });
    } else {

      this.appRouting.toTask( { projectId: taskDetails.projectId, taskId: taskDetails.taskId });
    }

  }

}
