import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Injectable()
export class KalgudiMatchMediaService {

  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private observableMedia: MediaObserver
  ) {
    // Set the defaults
    this.activeMediaQuery = '';

    // Initialize
    this.init();

  }

  /**
   * Initialize
   */
  private init(): void {
    this.observableMedia.asObservable()
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe((change: MediaChange[]) => {
        if (this.activeMediaQuery !== change[0].mqAlias) {
          this.activeMediaQuery = change[0].mqAlias;
          this.onMediaChange.next(change[0].mqAlias);
        }
      });
  }

}
