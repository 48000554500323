import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import {
  checkMobileDevice,
  KALGUDI_PROFILE_BUSINESS_TYPE_NAMES,
  KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES,
  KalgudiAppService,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser, UIMenu } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KalgudiFarmerProfileBasicDetails } from '../../farmer-profile/classes/farmer-profile-basic-details';
import {
  EditCommonProfileDetailsDialogService,
} from '../../profile-basic-edit/services/edit-common-profile-details-dialog.service';
import {
  CooperativeSocietyEditDialogComponent,
} from '../components/cooperative-society-edit-dialog/cooperative-society-edit-dialog.component';
import {
  CooperativeSocietyEditMobileDialogComponent,
} from '../components/cooperative-society-edit-mobile-dialog/cooperative-society-edit-mobile-dialog.component';

@Directive()
export abstract class KalgudiOrgProfileHeader extends KalgudiFarmerProfileBasicDetails {

  @Output()
  activatedTab = new EventEmitter<string>();

  @Output()
  selectedTabDetails = new EventEmitter<string>();

  @Input()
  selectedTabId: string;

  @Input()
  showProfileLogs: boolean;

  @Input()
  profileShareUrl: string;

  defaultProfilePic = 'assets/images/add-farm.png';

  domain: string;

  tabsList: UIMenu[] = [
    {
      id: 'ABOUT',
      selected: false,
      title: 'Overview'
    },
    {
      id: 'UPDATES',
      selected: false,
      title: 'Updates'
    },
    {
      id: 'TASKS',
      selected: false,
      title: 'Tasks'
    },
    {
      id: 'PROFILE_LOGS',
      selected: false,
      title: 'Profile logs'
    }
  ];

  loggedInUserDetails: KalgudiUser;
  availableBusinessTypeNames = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;
  nativeBusinessTypeNames    = KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES;


  private dialogsService: EditCommonProfileDetailsDialogService;
  public environment: KalgudiEnvironmentConfig;
  private dialogService: KalgudiDialogsService;
  private kalgudiAppService: KalgudiAppService;

  constructor(
    protected injector: Injector,
  ) {

    super(injector);

    this.dialogsService    = this.injector.get(EditCommonProfileDetailsDialogService);
    this.environment       = this.injector.get<KalgudiEnvironmentConfig>(KL_ENV);
    this.dialogService     = this.injector.get(KalgudiDialogsService);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);

    this.domain = this.environment.coreDomain;

    if (this.kalgudiAppService.profileLocal) {
      this.loggedInUserDetails = this.kalgudiAppService.profileLocal;
    }
  }

  selectedTab(activity: string) {
    this.selectedTabDetails.emit(activity);
  }

  /**
   * Emit the value of activated tab
   */
  toggleTab(activity: string) {
    this.selectedTabId = activity;
    this.activatedTab.emit(activity);
  }

  /**
   * Opens profile basic details dialog
   */
  showEditDialog() {
    this.showDetailsEditDialog();
  }

  /**
   * Opens profile basic details edit dialog
   */
  showDetailsEditDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Update profile',
      data: {
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
      }
    };

    return this.openAppPageDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }

  /**
   * Open app page dialog
   */
  private openAppPageDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(CooperativeSocietyEditMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(CooperativeSocietyEditDialogComponent, dialogConfig, matDialogConfig);

    }
  }
}
