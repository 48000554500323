import { SelectionModel } from '@angular/cdk/collections';
import { Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { KalgudiError } from '../errors';
import { KalgudiStreamData } from '../typings';
import { KalgudiBaseStream } from './kalgudi-base-stream';
import { KalgudiDataSource } from './kalgudi-data-source';
import { PartialData } from '@kalgudi/types';

/**
 * Defines logic to handle `MatTable`. It includes the material table pagination, sorting,
 * individual/multiple row selection toggle logic.
 *
 * Initialize the class by invoking `initTableStream(paginator: MatPaginator, sort: MatSort)` method.
 * The method accepts two optional params `paginator` and `sort`. Pass the references of `MatPaginator`
 * and `MatSort` if you want paginator and sorting behavior.
 *
 *
 * You must override the `streamApi(offset: number, limit: number): Observable<KalgudiStreamData>` to
 * define the stream method.
 *
 * @method
 * `toggleTableSelection()` Toggles current table selection status. De-selects a table if entire table is selected
 * else selects the table
 *
 * @method
 * `toggleRowSelection(row, index)` Toggles a row selection status
 *
 * @method
 * `selectTable()` Selects entire table
 *
 * @method
 * `clearTableSelection()` De-selects entire table
 *
 * @method
 * `resetTable()` Resets the table stream, page and sort direction
 *
 * @member
 * `loading$` Observable specifying current page loading status
 *
 * @member
 * `tableSelectionChange$` Observable emits all selected row when there is any row selection change
 *
 * @member
 * `dataSource` Instance of `KalgudiDataSource` defines source of data for a mat table
 *
 * @member
 * `displayedColumns` Array of string representing columns field name
 *
 * @member
 * `pageSizeOptions` Array of mat paginator page size options
 *
 * @member
 * `selectedRows` List of current selected rows
 *
 * @member
 * `allRowsSelected` `true` if all rows are selected otherwise `false`.
 *
 * @member
 * `someRowsSelected` `true` if only 1+ rows are selected not all, `false` if all or no rows are selected.
 *
 * @member
 * `selectedRowsMap` Map of all selected rows where key is the id passed to the `toggleRowSelection()` method.
 *
 *
 * @usage
 * Component
 * ```ts
 * export class ProgramsStreamComponent extends KalgudiMatTableStream<KalgudiPageDetails> implements OnInit {
 *
 *   // Use `at` symbol with `ViewChild`
 *   ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
 *   ViewChild(MatSort, { static: true }) matSort: MatSort;
 *
 *   constructor(
 *     private pageService: KalgudiProgramListService,
 *   ) {
 *
 *     super();
 *
 *     // Override the list of columns to show
 *     this.displayedColumns = ['select', 'pageId', 'pageTitle'];
 *
 *     // Override the page size options if you don't want default page size
 *     this.pageSizeOptions = [1, 5, 10];
 *   }
 *
 *   ngOnInit() {
 *
 *     // Initializes the KalgudiMatTableStream
 *     this.initTableStream(this.matPaginator, this.matSort);
 *   }
 *
 *   // Define the streamApi method to specify the data source of the table
 *   protected streamApi(offset: number, limit: number): Observable<KalgudiStreamData> {
 *     return this.pageService.fetchPagesList(offset, limit, { memberRole: '' });
 *   }
 *
 *   // Resource cleanup
 *   protected onDestroyed(): void { }
 *
 * }
 *
 * ```
 *
 * HTML
 * ```html
 * <table mat-table [dataSource]="dataSource" matSort>
 *   <!-- Checkbox Column -->
 *   <ng-container matColumnDef="select">
 *     <th mat-header-cell *matHeaderCellDef>
 *       <mat-checkbox (change)="$event ? toggleTableSelection() : null"
 *         [checked]="allRowsSelected"
 *         [indeterminate]="someRowsSelected">
 *       </mat-checkbox>
 *     </th>
 *     <td mat-cell *matCellDef="let row; let index = index;">
 *       <mat-checkbox (click)="$event.stopPropagation()"
 *          (change)="$event ? toggleRowSelection(row, index) : null"
 *          [checked]="selectedRowsMap[index]">
 *       </mat-checkbox>
 *     </td>
 *   </ng-container>
 *
 *   <!-- Page Id column -->
 *   <ng-container matColumnDef="pageId" sticky>
 *     <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
 *     <td mat-cell *matCellDef="let element"> {{element.pageId}} </td>
 *   </ng-container>
 *
 *   <!-- Page title column -->
 *   <ng-container matColumnDef="pageTitle" sticky>
 *     <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
 *     <td mat-cell *matCellDef="let element"> {{element.pageTitle}} </td>
 *   </ng-container>
 *
 *   <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
 *   <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
 * </table>
 *
 * <mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class KalgudiMatTableStream<T> extends KalgudiBaseStream {

  dataSource: KalgudiDataSource<T>;
  displayedColumns = [''];


  allRowsSelected = false;
  someRowsSelected = false;
  selectedRowsMap: { [key: string]: boolean } = {};

  allowMultiSelect = true;

  /**
   * Emits when the table is fetching data from the API
   */
  loading$: Observable<boolean>;

  /**
   * Emits when table selection state changes. Emits back the list of all selected rows.
   */
  tableSelectionChange$: Observable<T[]>;

  /**
   * Default mat paginator page size options. Override this to add more page size
   * options to the list.
   */
  pageSizeOptions = [10, 25, 50, 100];

  private paginator: MatPaginator;
  private sort: MatSort;
  private matSelection: SelectionModel<T>;

  private tableSelectionChangeSubject = new Subject<T[]>();
  params: PartialData = {};


  constructor() {
    super();


    // Initialize table selection
    this.tableSelectionChange$ = this.tableSelectionChangeSubject
      .pipe(
        takeUntil(this.destroyed$),

        // Adding a debounce time ensures if we select entire table at once
        // it still emits a single event
        debounceTime(200),

        // Don't emit if the list selection has not changed
        // distinctUntilChanged((x, y) => x.length === y.length),
      );

    this.destroyed$.subscribe(_ => this.closeObservables());
  }

  /**
   * Gets, the current selected rows.
   */
  get selectedRows(): T[] {
    return this.matSelection.selected;
  }



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Marks a row as selected if the current row is not selected otherwise
   * unselects it. It will clear all previous selected row if the table
   * `allowMultiSelect` is `false`.
   *
   * @param row Row to select/de-select
   */
  toggleRowSelection(row: T, id: number): void {

    // Handle single row selection
    if (!this.allowMultiSelect) {

      // Current row selection status
      const isCurrentRowSelected = this.isRowSelected(row);

      // Clear the previous all selected row
      this.clearTableSelection();

      // Ensure current row selection if was previously selected
      // This is required to ensure the `clearTableSelection()` method
      // does not effect current row.
      if (isCurrentRowSelected) {
        this.matSelection.toggle(row);
      }
    }

    this.matSelection.toggle(row);

    this.updateRowSelectionStatus(row, id);
    this.updateTableSelectionStatus();
  }

  /**
   * Marks all rows as selected if entire table rows are not selected, otherwise
   * unselects them.
   *
   * It performs no action if `allowMultiSelect` is `false`.
   */
  toggleTableSelection(): void {

    // Do nothing if multi-selection is not enabled.
    if (!this.allowMultiSelect) {
      throw new KalgudiError(new Error('Cannot select entire table when "allowMultiSelect" is false'));
    }

    this.isAllRowsSelected()
      ? this.clearTableSelection()
      : this.selectTable();
  }

  /**
   * Pushes all rows of the table to the selected rows list.
   */
  selectTable(): void {

    // Clear the existing selection
    this.matSelection.clear();
    this.clearSelectedRowsMap();

    // Add all items from the data source to the selected item list
    this.dataSource.data.forEach((d, i) => this.toggleRowSelection(d, i));

    this.updateTableSelectionStatus();
  }

  /**
   * Clears all selected rows from the table.
   */
  clearTableSelection(): void {
    this.matSelection.clear();

    this.clearSelectedRowsMap();
    this.updateTableSelectionStatus();
  }

  /**
   * Checks if all rows of the table are selected or not.
   * Returns `true` if `allowMultiSelect` is `true` and all rows of the table are selected, otherwise `false`.
   */
  isAllRowsSelected(): boolean {
    return this.allowMultiSelect && (this.dataSource.data.length === this.matSelection.selected.length);
  }

  /**
   * Checks if some rows of the material table are selected or not.
   * Returns `true` if a number of rows are selected but not all, otherwise `false`.
   */
  isSomeRowsSelected(): boolean {
    return this.matSelection.hasValue() && !this.isAllRowsSelected();
  }

  /**
   * Checks if the specified row is selected or not.
   * Returns `true` if the specified row is selected otherwise `false`.
   */
  isRowSelected(row: T): boolean {
    return this.matSelection.isSelected(row);
  }

  /**
   * Resets the table data, paginator and sort.
   */
  resetTable(): void {
    if (!this.dataSource) {
      throw new KalgudiError(new Error('Table data source not defined'));
    }

    this.dataSource.resetStream();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Fetches stream items from the Kalgudi API.
   */
  protected abstract streamApi(offset: number, limit: number): Observable<KalgudiStreamData>;
  protected abstract streamApi(offset: number, limit: number, sortBy?: string, sortDirection?: string, params?: PartialData): Observable<KalgudiStreamData>;

  /**
   * Initializes the table stream. It initializes the material table with MatPaginator and
   * MatSort.
   *
   * Feed the references of `MatPaginator` and `MatSort` to the method in order to provide
   * pagination and sorting capability to the material table.
   */
  protected initTableStream(paginator?: MatPaginator, sort?: MatSort, allowMultiSelect = true, params?: PartialData): void {

    // Copy the references of paginator and sort to instance members
    this.sort             = sort;
    this.paginator        = paginator;
    this.allowMultiSelect = allowMultiSelect;
    this.params           = params;

    // Instantiate custom data source
    this.dataSource = new KalgudiDataSource<T>(this.paginator, this.sort, this.params);
    // Link the data source streamApi$ function reference.
    // streamApi$ function gets called on every page, sort value changes.
    this.dataSource.streamApi$ = (offset, limit, sortBy, sortDirection, params) => this.streamApi(offset, limit, sortBy, sortDirection, params);

    // Attach the reference of loading$ observable to the current instance
    this.loading$ = this.dataSource.loading$;

    // Instantiate the mat selection
    this.matSelection = new SelectionModel<T>(true, []);

    // Initialize mat selection event handlers
    this.initTableSelectionEventsHandlers();
  }

  /**
   * Initialized table selection event handlers. It adds a hook to the page events
   * and ensure the MatSelection flag setting and resetting based on the page events.
   */
  private initTableSelectionEventsHandlers(): void {

    this.dataSource.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(_ => this.resetTableSelectionStatus());

    // Update the table selection fields status on the selection change
    this.matSelection.changed
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(_ => this.updateTableSelectionStatus());
  }

  /**
   * Resets the current table selection status
   */
  private resetTableSelectionStatus() {

    this.allRowsSelected  = false;
    this.someRowsSelected = false;

    this.clearSelectedRowsMap();
  }

  /**
   * Re-instantiate the selected rows map with the size of the data in the
   * current data source
   */
  private clearSelectedRowsMap(): void {
    this.selectedRowsMap = {};
  }


  /**
   * Updates the row selection status
   */
  private updateRowSelectionStatus(row: T, id: number): void {

    // Push the selected row to the selected
    if (this.matSelection.isSelected(row)) {
      this.selectedRowsMap[id] = true;
    } else {
      delete this.selectedRowsMap[id];
    }
  }

  /**
   * Updates the current table row selection status
   */
  private updateTableSelectionStatus() {

    this.allRowsSelected  = this.isAllRowsSelected();
    this.someRowsSelected = this.isSomeRowsSelected();

    // Emit all the selected rows to the current table selection stream
    this.tableSelectionChangeSubject.next(this.selectedRows);
  }

  /**
   * Closes all open streams
   */
  private closeObservables(): void {
    this.tableSelectionChangeSubject.complete();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
