import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';

@Component({
  selector: 'kl-add-survey-members',
  templateUrl: './add-survey-members.component.html',
  styleUrls: ['./add-survey-members.component.scss']
})
export class AddSurveyMembersComponent implements OnInit {

  @Output()
  membersAdded = new EventEmitter();

  activeTabIndex: number;

  readonly TABS = {
    SEARCH: { index: 0, title: 'Search Members' },
    BULK: { index: 1, title: 'Add bulk members' },
  };


  constructor( @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig) { }

  ngOnInit() {
    this.activeTabIndex = 0;
  }

  /**
   * Event emit back to parent after adding members successfully
   */
  addedMembers() {
    this.membersAdded.emit();
  }

}
