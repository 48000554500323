import { Component, Inject, OnInit } from '@angular/core';
import {
  Attachment,
  FileMimeTypes,
  KalgudiDialogResult,
  KalgudiImageDialogConfig,
  KalgudiImageDialogResult,
  S3PolicyPathCategoryMap,
} from '@kalgudi/types';

import { KalgudiMobileDialogRef } from '../../../mobile-dialog/classes/mobile-dialog-overlay-ref';
import { KL_MOBILE_DIALOG_DATA } from '../../../mobile-dialog/config';

@Component({
  selector: 'kl-upload-attachments-mobile-dialog',
  templateUrl: './upload-attachments-mobile-dialog.component.html',
  styleUrls: ['./upload-attachments-mobile-dialog.component.scss']
})
export class UploadAttachmentsMobileDialogComponent implements OnInit {

  attachments: Attachment[];
  s3Category: S3PolicyPathCategoryMap;
  maxImages: number;
  multiple: boolean;

  acceptFileTypes: string;

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: KalgudiImageDialogConfig,
    private dialogRef: KalgudiMobileDialogRef
  ) {

    this.attachments = Array.isArray(this.dialogData.data.attachments) ? this.dialogData.data.attachments : [];
    this.multiple = this.dialogData.data.multiple;
    this.maxImages = this.dialogData.data.maxImages;
    this.s3Category = this.dialogData.data.s3Category;

    this.acceptFileTypes = this.dialogData.data.acceptFileTypes
      ? this.dialogData.data.acceptFileTypes as any
      : FileMimeTypes.IMAGE;

  }

  ngOnInit() {
  }


  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiImageDialogResult = {
      accepted: true,
      data: {
        attachments: this.attachments
      }
    };
    this.dialogRef.close(result);
  }
}
