import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AttachUrlFormControlComponent } from './attach-url-form-control/attach-url-form-control.component';
import { UrlMetaDataService } from './url-meta-data.service';


@NgModule({
  declarations: [AttachUrlFormControlComponent],
  imports: [
    CommonModule,

    // SVG
    AngularSvgIconModule,

    // Material
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,

    FlexLayoutModule,
  ],
  exports: [
    AttachUrlFormControlComponent
  ],
  providers: [
    UrlMetaDataService
  ]
})
export class KalgudiAttachUrlModule { }
