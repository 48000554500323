import { Component, Input, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { NotificationNetwork, ShareUpdateHeader } from '@kalgudi/types';

@Component({
  selector: 'kl-networks-tile',
  templateUrl: './networks-tile.component.html',
  styleUrls: ['./networks-tile.component.scss'],
  animations: kalgudiAnimations,
})
export class NetworksTileComponent implements OnInit {

  @Input()
  animationDelay = 0;

  @Input()
  notification: NotificationNetwork;

  networksHeader: ShareUpdateHeader;

  @Input()
  isConnected: boolean;

  constructor() { }

  ngOnInit() {

    if (this.isConnected) {
      this.networksHeader = {
        matIcon: 'group_add',
        iconColor: 'success',
        title: 'You are now connected with',
        showMenu: false
      };
    } else {
      this.networksHeader = {
        matIcon: 'group_add',
        iconColor: 'success',
        title: 'Viewed your profile',
        showMenu: false
      };
    }

  }

}
