import { Inject, Injectable } from '@angular/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, AuthDetails, AuthLoginResponse, KalgudiUser, KalgudiUserBasicDetails, LoginCredentials } from '@kalgudi/types';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';

import { KalgudiAppApiService } from './kalgudi-app-api.service';
import { KalgudiUtilityService } from './kalgudi-util.service';
import { Router } from '@angular/router';

/**
 * Kalgudi global app management service. Handles app state like user authentication,
 * login, logout and logged in user profile management.
 *
 * @author Pankaj Prakash
 */
@Injectable({
  providedIn: 'root'
})
export class KalgudiAppService {
  isPublicPage: boolean;

  /**
   * Local storage key to store login credentials
   */
  private readonly localStorageLoginCredentialsKey = 'c';
  private readonly localStorageAuthTokenKey = 'tk';

  /**
   * Local storage profile key
   */
  private readonly localStorageProfileKey = 'pf';

  private readonly loginSubject: BehaviorSubject<boolean>;
  private readonly profileSubject: BehaviorSubject<KalgudiUser>;

  private readonly authTokenSubject: BehaviorSubject<AuthDetails>;
  private readonly authTokenRefreshingSubject: BehaviorSubject<boolean>;

  constructor(
    private appApi: KalgudiAppApiService,
    private util: KalgudiUtilityService,
    private router: Router,
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig
  ) {
    this.loginSubject = new BehaviorSubject<boolean>(this.loggedIn);
    this.profileSubject = new BehaviorSubject<KalgudiUser>(this.profileLocal);

    this.authTokenSubject = new BehaviorSubject<AuthDetails>(this.authToken);
    this.authTokenRefreshingSubject = new BehaviorSubject<boolean>(false);

    if(this.env.appId != 'OUTPUTS' && this.env.appId != 'SAM_FPO') {

      this.validateLogin();
      this.validateProfile();
    }

    // If user is logged in then update its profile details locally lazily
    if (this.loggedIn) {
      this.lazyProfileUpdate();
    }

  }


  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * `true` if the app is shaktiman farming solutions otherwise `false`.
   */
  get isShaktimanFarmingSolutions(): boolean {
    return this.appApi.isShaktimanFarmingSolutions;
  }

  /**
   * `true` if the app is shaktiman farming solutions otherwise `false`.
   */
  get isProRiseStore(): boolean {
    return this.appApi.isProRiseStore;
  }

  /**
   * Gets, the stream of auth tokens.
   */
  get authToken$(): Observable<AuthDetails> {
    return this.authTokenSubject
      .pipe(

        // Filter null items in the stream
        filter(r => r !== null)
      );
  }

  /**
   * Stream containing `true` if currently auth is refreshing token
   */
  get authTokenRefreshing$(): Observable<boolean> {
    return this.authTokenRefreshingSubject
      .pipe(

        // Filter null items in the stream
        filter(r => r !== null)
      );
  }

  /**
   * `true` if currently auth is refreshing token, otherwise `false`
   */
  get authTokenRefreshing(): boolean {
    return this.authTokenRefreshingSubject.getValue();
  }

  /**
   * Login event stream, emitted every time a user performs
   * login or logout action.
   *
   * @see $login For only login events
   * @see $logout For only logout events
   */
  get loginState$(): Observable<boolean> {
    return this.loginSubject
      .pipe(

        // Filter null items in the stream
        filter(r => r !== null)
      );
  }

  /**
   * Login event stream, emitted every time a user logs in to the
   * app.
   *
   * @see $loginState For login and logout both events
   * @see $logout For only logout events
   */
  get login$(): Observable<boolean> {
    return this.loginState$
      .pipe(

        // Filter all login messages
        filter(r => r)
      );
  }

  /**
   * Login event stream, emitted every time a user logs out from the
   * app.
   *
   * @see $loginState For login and logout both events
   * @see $logout For only logout events
   */
  get logout$(): Observable<boolean> {
    return this.loginState$
      .pipe(

        // Filter all logout messages
        filter(r => !r),

        // Map logout(false) event stream to logout(true)
        // Login subject will emit either `true` or `false`.
        // Where `true` says as login and `false` states logout.
        map(_ => true),
      );
  }

  /**
   * Logged in user profile stream. Emits latest logged in user profile
   * object if exists otherwise emits `null`.
   *
   * If user is logged in and has profile data in memory then it emits
   * logged in user profile otherwise emits `null`.
   */
  get profile$(): Observable<KalgudiUser> {
    return this.profileSubject
      .pipe(

        // Filter null items in the stream
        filter(r => r !== null)
      );
  }
  /**
   * Gets, Kalgudi logged in user profile details
   */
  get profileBasicDetails$(): Observable<KalgudiUserBasicDetails> {
    return this.profile$;
  }

  /**
   * Checks is a user is currently logged in or not. A user
   * if logged in if his login credentials exists in the local storage.
   *
   * @returns `true` if user is logged in otherwise `false`
   */
  get loggedIn(): boolean {
    // Get credentials stored in local storage
    const credentials = this.util.getFromLocal<LoginCredentials>(this.localStorageLoginCredentialsKey);

    // Validate user credentials
    return !!(credentials && this.isValidCredentials(credentials));
  }

  /**
   * Logged in user login credentials. Login credentials are stored in
   * base 64 encoded format in local storage.
   */
  get loginCredentials(): LoginCredentials {
    return this.util.getFromLocal<LoginCredentials>(this.localStorageLoginCredentialsKey);
  }

  /**
   * Use this token to share user's current session with other application while
   * opening the link
   *
   * `This must be concatenated in queryParam of URL (ie. after ? )`
   */
  get redirectionToken(): string {
    const {token, userName } = this.authToken;
    return `primary=${token}&secondary=${userName}&profile=${this.profileLocal.profileKey}`;
  }

  /**
   * Gets, the logged in user auth token.
   */
  get authToken(): AuthDetails {
    return this.util.getFromLocal<AuthDetails>(this.localStorageAuthTokenKey, true);
  }

  /**
   * Gets, logged in kalgudi user profile stored in local storage.
   */
  get profileLocal(): KalgudiUser {
    return this.util.getFromLocal<KalgudiUser>(this.localStorageProfileKey, false);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Validates user credentials and logins user to the app.
   *
   * @param req Login request payload
   */
  login(req: LoginCredentials, otpverified?: boolean): Observable<KalgudiUser> {

    // Clone request payload, this maintains immutability
    const payload = this.util.clone<LoginCredentials>(req);

    // Add mobile telecom code for mobile login
    if (payload.type === 'mobile') {
      payload.userName = payload.mobileTelecomCode + payload.userName;
    } else {
      delete payload.mobileTelecomCode;
    }

    // Encrypt password field
    payload.password = this.util.encodeString(payload.password);

    // Delete unnecessary fields from payload
    delete payload.type;

    return this.appApi.login(payload, otpverified)
      .pipe(
        map(r => {
          // Update login credentials in local storage
          const user = this.updateCredentialsCache(payload, r);

          // Update login status in the `$login` stream
          this.loginSubject.next(true);

          return user;
        }),

        // Fetch logged in user profile
        switchMap(r => this.fetchProfile(r.profileKey))
      );
  }

  /**
   * Updates auth related information and starts a new session
   *
   * @param token Kalgudi valid token
   * @param userName Kalgudi user name
   * @param profileKey Kalgudi user profile key
   */
  updateSession(token: string, userName: string, profileKey: string): Observable<KalgudiUser> {

      // Update user token in local storage
      const user = this.updateCredentialsCache(
        {
          password: this.util.encodeString('')
        } as any,
        {
          auth: {
            token,
            userName,
          },
          userBasicDetail: {
            profileKey
          }
        }
        );

        // Update login status in the `$login` stream
        this.loginSubject.next(true);


      return this.fetchProfile(user.profileKey);

  }

  /**
   * Validates user credentials and logins user to the app using office 365.
   *
   * @param token microsoft office 365 accessToken
   */
  loginWithOffice365Token(token: string, appId: string): Observable<KalgudiUser> {

    return this.appApi.loginWithOffice365Token(token, appId)
      .pipe(
        map(r => {
          // Update login credentials in local storage
          const user = this.updateCredentialsCache({
            userName: r.auth.userName,
            password: this.util.encodeString(r.pwd)
          } as any, r as any);

          // Update login status in the `$login` stream
          this.loginSubject.next(true);

          return user;
        }),

        // Fetch logged in user profile
        switchMap((r:any) => this.fetchProfile(r.profileKey))
      );
  }

  /**
   * Fetches a fresh auth token from the Api and updates the
   * token cache.
   */
  updateAuthToken(): Observable<AuthDetails> {

    // Turn on flag to update auth token
    this.authTokenRefreshingSubject.next(true);

    return this.appApi.getAuthToken()
      .pipe(

        // Update auth token in local cache
        tap(r => this.updateAuthTokenCache(r)),

        // Token updation completed
        // tap(() => this.authTokenRefreshingSubject.next(false)),

        finalize(() => this.authTokenRefreshingSubject.next(false)),
      );
  }

  /**
   * Event handler for logout successful operation
   */
  logout(reload = false): Observable<boolean> {

    const profileKey = this.profileLocal && this.profileLocal.profileKey;

    console.log('Loaded logout');

    return this.appApi.logout(profileKey)
      .pipe(
        tap(_ => {

          // Map null values to the login stream
          this.loginSubject.next(false);

          // Map null values to the profile stream
          this.profileSubject.next(null);

          // Reload the page if specified
          if (reload) {
            this.util.reload();
          }
        })
      );
  }

  /**
   * Updates the logged in user profile with the latest profile data.
   *
   * This method must be called after every profile update.
   */
  updateProfile(): Observable<KalgudiUser> {

    // Logged in user profile key
    const profileKey = this.profileLocal && this.profileLocal.profileKey;

    return this.fetchProfile(profileKey);
  }

  /**
   * Makes an Api call to check whether app Api with auth validation is responding or not.
   */
  ping(): Observable<boolean> {
    return this.appApi.ping();
  }

  /**
   * Gets, mobile number or alternate mobile number from the profile details
   */
  getMobileNumber(profile: KalgudiUser): number | string {
    let mobile = '';

    //to check profile is email a/c or mobile a/c
    if (!profile.mobileNo.includes(profile.profileKey.substring(1, 7))) {

      // Replaces the '+91' with empty string
      mobile = profile.mobileNo.replace(profile.mobileCode, '');
    } else if (profile.alternateMobileNo) {
      mobile = profile.alternateMobileNo;
    }

    return mobile;
  }

  /**
   * Gets, email id or alternate email id from the profile details
   */
  getEmailId(profile: KalgudiUser): string {
    return  profile ? profile.emailId || profile.altemailId : '';
  }

  /**
   * Fetches user profile from the API
   *
   * @param profileKey Kalgudi user profile key to fetch
   */
  fetchUserProfile(profileKey: string): Observable<KalgudiUser> {

    return this.appApi.fetchProfile(profileKey);
  }

  /**
   * Calls profile last open API.
   */
  appUsage(isLogin?: boolean) : Observable<any> {
    this.isPublicPage = this.router?.routerState?.snapshot?.url?.includes('/public/profiles');

    if (!this.isPublicPage && isLogin) {
      return this.appApi.appUsage(isLogin);
    }
    return of(false);
  }

  /**
   * Hit the API call/endpoint of the generate-otp and generate OTP
   */
  generateOtp(payload: any): Observable<ApiResponseCommon> {
    return this.appApi.generateOtp(payload);
  };

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Fetches logged in kalgudi user profile
   */
  private fetchProfile(profileKey: string): Observable<KalgudiUser> {

    return this.appApi.fetchProfile(profileKey)
      .pipe(
        tap(r => {

          // Update profile details in local storage
          this.updateProfileCache(r);

          // Update profile in the `$profile` stream
          this.profileSubject.next(r);
        })
      );
  }

  /**
   * Handles successful login response. It stores the login credentials to
   * local storage. It parses the login response and returns back the parsed login
   * response.
   *
   * @param req Login request payload
   * @param res Login response from API
   */
  private updateCredentialsCache(req: LoginCredentials, res: AuthLoginResponse): KalgudiUserBasicDetails {
    // Update mobile number
    req.userName = res.auth.userName;

    /**
     * With new workflow we don't need to store user password locally
     * Please do not store password without consulting technical managers
     */
    // req.password = this.util.decodeString(req.password);
    delete req.password;

    // Store login credentials to local storage
    this.util.setToLocal(this.localStorageLoginCredentialsKey, req);

    // Update the auth token received from login
    this.updateAuthTokenCache(res.auth);

    // Return the short profile response sent by the login service
    return res.userBasicDetail;
  }

  /**
   * Sets, the latest auth token to the local storage.
   */
  private updateAuthTokenCache(token: AuthDetails): void {

    // Update token in local storage
    this.util.setToLocal(this.localStorageAuthTokenKey, token, true);

    // Update token in the stream
    this.authTokenSubject.next(token);
  }

  /**
   * Updates the memory cache and local storage with the specified kalgudi
   * user profile details.
   *
   * On successful update it fire back an event `$profileUpdated`
   *
   * @param profile Updated user profile of the logged in user
   */
  private updateProfileCache(profile: KalgudiUser): KalgudiUser {

    // Update profile in local storage
    this.util.setToLocal(this.localStorageProfileKey, profile, false);

    // Return back the latest profile details for further processing
    return profile;
  }

  /**
   * Checks if login credentials is valid or not. A valid login
   * credentials must have `mobileNo` and `password`.
   */
  private isValidCredentials(credentials: LoginCredentials): boolean {

    return (
      !this.util.isNullOrEmpty(credentials.userName)
      // Making password header optional for API calls
      // &&
      // !this.util.isNullOrEmpty(credentials.password)
    );
  }

  /**
   * Updates local profile details lazily after a delay.
   */
  private lazyProfileUpdate(delayInMs = 20000): void {

    // Set a timer to update the profile after delay
    timer(delayInMs)
      .pipe(
        // Subscribe to first result only
        take(1),

        // Fetch and update latest profile details
        switchMap(r => this.updateProfile())
      )
      .subscribe();

  }

  /**
   * Validates user login
   */
  private validateLogin() {
    if (this.env.appId !== 'SAVANNAH_APP') {
      // No need to do anything already logged in
      if (this.loggedIn && this.authToken) {
        return;
      }

      // User not logged in log him out
      this.logout(false).subscribe();
    }
  }

  /**
   * Verifies if a user has valid profile details in local storage or not
   */
  private validateProfile() {
    if (this.env.appId !== 'SAVANNAH_APP') {
      if (this.profileLocal && this.profileLocal.profileKey) {
        return true;
      }
      this.logout(false).subscribe();
    }
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
