import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { KALGUDI_S3_POLICY_MAP } from '@kalgudi/core';
import { Attachment, FileMimeTypes, KalgudiImageDialogConfig, S3PolicyPathCategoryMap } from '@kalgudi/types';
import { KalgudiDialog } from '../../kalgudi-dialogs';


@Directive()
export abstract class KalgudiUploadImageDialog<T> extends KalgudiDialog<T> {

  attachments: Attachment[] = [];

  path: S3PolicyPathCategoryMap;

  s3Category: S3PolicyPathCategoryMap;
  maxImages: number;
  multiple: boolean;
  acceptedFileTypes: FileMimeTypes[] = [];

  constructor(
    protected dialogRef: MatDialogRef<T>,
    protected dialogData: KalgudiImageDialogConfig,
  ) {

    super(
      dialogRef,
    );

    this.acceptedFileTypes = this.dialogData.data.acceptFileTypes as FileMimeTypes[];
    this.multiple          = this.dialogData.data.multiple;
    this.maxImages         = this.dialogData.data.maxImages;
    this.s3Category        = this.dialogData.data.s3Category;
    this.attachments       = Array.isArray(this.dialogData.data.attachments) ? this.dialogData.data.attachments : [];
    this.path              = this.dialogData.data.s3Category && this.dialogData.data.s3Category.category
      ? this.dialogData.data.s3Category
      :  KALGUDI_S3_POLICY_MAP.DEFAULT;
  }

}
