<section>
  <div id="updates-products-tab" class="mb-n2 mt-2 pt-1" fxLayout fxLayoutAlign="center center">
    <div fxLayout="row" *ngFor="let tab of tabsList; let first = first;let last = last" fxLayout >
      <a class="font-weight-bold w-100" fxLayout="row" [ngClass]="{'tab-selected text-secondary': selectedTabId === tab?.id}"
      (click)="toggleTab(tab?.id)" translate>
        <span *ngIf="!first" fxLayoutAlign="center center" class="mr-1 text-muted separator">|</span>
        <span class="pr-2 w-100" fxLayoutAlign="center center"> {{ tab?.title | translate }}</span>
      </a>
    </div>
  </div>

  <ng-container *ngIf="selectedTabId === 'UPDATES'">
    <kl-program-share-update-form (postCreated)="onPostCreated($event)"></kl-program-share-update-form>
  </ng-container>

  <ng-container *ngIf="selectedTabId === 'PRODUCTS'">
    <kl-promotional-share-form (postCreated)="onPostCreated($event)"></kl-promotional-share-form>
  </ng-container>
</section>
