import { Injector, Input, Directive } from '@angular/core';
import { KalgudiDestroyable } from '@kalgudi/core';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiLandDetailsAgroTagging  extends KalgudiDestroyable {

  @Input()
  assistedProfileKey: string;

  progress: boolean;
  agroLandDetails: any = [];
  farmerProfileService: KalgudiFarmerProfileService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.farmerProfileService = this.injector.get(KalgudiFarmerProfileService);
  }

  // --------------------------------------------------------
  // #region Getters and Setters methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Get land details agro tagging
   */
  getLandDetailsAgroTagging(landDetails, profileKey: string) {
    this.progress = true;

    this.farmerProfileService.getFarmHistory( landDetails.landId, '', {}, profileKey)
      .pipe(
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => {
          this.agroLandDetails = res.items;
        }
      )
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
