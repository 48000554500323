import { Component, Injector, OnInit } from '@angular/core';

import { KalgudiSendSurveyAnalytics } from '../../classes/kalgudi-send-survey-analytics';

@Component({
  selector: 'kl-send-survey-analytics',
  templateUrl: './send-survey-analytics.component.html',
  styleUrls: ['./send-survey-analytics.component.scss']
})
export class SendSurveyAnalyticsComponent extends KalgudiSendSurveyAnalytics implements OnInit {

  constructor(protected injector: Injector) {

    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}
}
