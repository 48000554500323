<div id="frequent-picker-dialog" class="member-picker-dialog">
  <div class="tab-groups-mobile">

    <kl-page-frequent-members-picker
      [pageId]="pageId"
      [multiSelect]="false"
      [(selectedUsersMap)]="selectedUsersMap">
    </kl-page-frequent-members-picker>

  </div>

  <!-- Buttons -->
  <div class="py-2 px-4 buttons fixed-bottom" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="30px">
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-stroked-button label="Cancel" buttonColor="warn" (clicked)="cancel()"></kl-stroked-button>
    </div>
    <div fxFlex="12%" fxFlex.xs="50%">
      <kl-flat-button label="Select" buttonColor="primary" (clicked)="ok()"></kl-flat-button>
    </div>
  </div>
  <!-- / Buttons -->
</div>
