import { Component, Inject, Input, OnInit } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { DEFAULT_PROFILE_PIC } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-network-author-details',
  templateUrl: './network-author-details.component.html',
  styleUrls: ['./network-author-details.component.scss']
})
export class NetworkAuthorDetailsComponent implements OnInit {

  @Input()
  author: KalgudiUserBasicDetails;

  @Input()
  isConnected: boolean;

  @Input()
  avatarSize: string;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  domain: string;

  constructor(
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiCommonRouteConfig,
    @Inject(KL_ENV) public environment: KalgudiEnvironmentConfig
  ) {

    this.domain = this.environment.coreDomain;
  }

  ngOnInit() {
  }

  /**
   * Navigate to user profile full view
   *
   * @param profileKey
   */
  viewProfile(profileKey: string) {
    if (!this.isConnected) {
      this.appRouting.toProfile({profileKey});
    }
  }

}
