import { Injector, Directive } from '@angular/core';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiSurveyDetails, UIMenu } from '@kalgudi/types';
import { map, takeUntil } from 'rxjs/operators';

import { KalgudiSurveysRouteConfig } from '../../../config';
import { SurveyPageActions } from '../../../constants';
import { SurveyStateService } from '../../../services/survey-state.service';
import { SurveyActionService } from '../services/survey-action.service';

@Directive()
export abstract class KalgudiSurveyListHeader extends KalgudiDestroyable {

  selectedFilter: UIMenu;
  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  private surveyActionService: SurveyActionService;
  private surveyStateService: SurveyStateService;
  private appRouting: KalgudiSurveysRouteConfig;

  constructor(
    protected injector: Injector
  ) {
    super();

    this.appRouting          = this.injector.get<KalgudiSurveysRouteConfig>(KL_ROUTE_CONFIG);
    this.surveyActionService = this.injector.get(SurveyActionService);
    this.surveyStateService  = this.injector.get(SurveyStateService);

  }

  selectTab(filterSelected: UIMenu): void {
    this.selectedFilter = filterSelected;

    this.surveyStateService.dispatchAction(SurveyPageActions.SURVEY_LIST_FILTER_UPDATE, filterSelected.id);
  }

  create() {

    this.surveyActionService.showCreateSurveyDialog()
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),

        map(res => res.data.surveyDetails)
      )
      .subscribe(
        (res: KalgudiSurveyDetails) => {

          const surveyId = res.surveyId;

          if (checkMobileDevice()) {
            this.appRouting.toSurveyFullView({ surveyId });
          } else {
            this.appRouting.toSurveyLogs({ surveyId });
          }
        }
      );
  }

}
