<section class="picker">
  <div class="px-4 pt-4 main-wrapper" *ngIf="(paginator$ | async) as paginator">

    <div *ngIf="showExtraFields && isFpoApp" class="radio-button">
      <mat-radio-group fxLayout="row wrap" [(ngModel)]="selectedOption"  fxLayout.lt-sm="column" aria-label="Select an option" (change)="onRadioChange()">
        <mat-radio-button value="searchUsers" checked>Based on name, email or mobile number</mat-radio-button>
        <mat-radio-button value="locationAndCrops" class="ml-3 location-mobile">Based on locations and crops</mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Search bar -->
    <div id="user-search" class="pb-2" *ngIf="selectedOption === 'searchUsers'">
      <kl-search class="search w-100" [placeHolder]="searchPlaceHolder" (search)="search();" [searchForm]="searchForm">
      </kl-search>
    </div>
    <!-- / Search bar -->
    <form *ngIf="showExtraFields && isFpoApp && selectedOption === 'locationAndCrops'" [formGroup]="locationAndCropForm">
      <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex="calc(43% - 10px)">
          <!-- Location -->
          <span translate>Location</span>
          <mat-form-field appearance="outline">
            <input [matAutocomplete]="locationAuto" #searchInput matInput placeholder="Enter location" [(ngModel)]="locationValue"
              [formControl]="location" [required]="!locationArray?.value?.length && !cropArray?.value?.length"/>
            <mat-error translate class="error-msg" translate>Location is mandatory</mat-error>
            <mat-autocomplete #locationAuto="matAutocomplete" [autoActiveFirstOption]="true"
              [displayWith]="displayLocationWithFn" (optionSelected)="selectLocation($event)">
              <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                {{ option?.placeName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <!-- <mat-error *ngIf="cropArray?.value?.length" translate class="small mt-n3" translate>You didn't select location</mat-error> -->
          <!--/ Location -->
        </div>

        <!-- Crop -->
        <div fxLayout="column" fxFlex="calc(43% - 10px)">
          <span translate>Crop</span>
          <mat-form-field appearance="outline">
            <input [matAutocomplete]="cropAuto" matInput placeholder="Enter crop" [(ngModel)]="cropValue" [formControl]="crop"
              [required]="!locationArray?.value?.length && !cropArray?.value?.length"/>
            <mat-error translate class="error-msg" translate>Crop is mandatory</mat-error>
            <mat-autocomplete #cropAuto="matAutocomplete" [displayWith]="displayWithFn" (optionSelected)="selectCropItem($event)">
              <mat-option *ngFor="let option of cropOptions | async" [value]="option">
                {{ option?.productName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <!-- <mat-error *ngIf="locationArray?.value?.length" translate class="small mt-n3" translate>You didn't select crops</mat-error> -->
        </div>
        <!--/ Crop -->

        <!-- Count -->
        <div fxLayout class="mt-1 margin-mobile" fxFlex="calc(14% - 10px)">
          <div fxLayout="column" class="count">
            <span translate>count: </span>
            <span class="mt-3 text-center">{{ targetCount }}</span>
          </div>

          <div class="cursor-pointer rounded-circle ml-1 icon " [matTooltip]="'Refresh target count' | translate"
            (click)="refreshTargetCount()">
            <mat-icon class="rounded-circle icon-color">settings_backup_restore</mat-icon>
          </div>
        </div>
        <!--/ Count -->
      </div>
    </form>

    <div class="fixed-height">
      <div fxLayout *ngIf="isFpoApp && selectedOption === 'locationAndCrops'">
        <!-- Mat chip for location -->
        <div fxFlex="calc(43% - 10px)">
          <div class="" *ngIf="locationArray?.value && locationArray?.value?.length">
            <mat-chip-list class="">
              <mat-chip *ngFor="let item of locationArray?.value; let index=index;" [selectable]="false"
                [removable]="true" (removed)="locationRemoveItem(index)">
                <span class="text-size">{{ item?.placeName | translate }}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <!-- Mat chip for location -->

        <!-- Mat chip for crops -->
        <div fxFlex="calc(43% - 10px)">
          <div class="" *ngIf="cropArray?.value && cropArray?.value?.length">
            <mat-chip-list class="">
              <mat-chip *ngFor="let item of cropArray.value; let index=index;" [selectable]="false" [removable]="true"
                (removed)="cropRemoveItem(index)">
                <span class="text-size">{{ item?.productName | translate }}</span>
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
        <!-- Mat chip for location -->

        <div fxFlex="calc(14% - 10px)"></div>
      </div>


        <!-- <div *ngIf="searchKeyword" class="mt-1" fxHide.xs>
      <p class="mb-0 count">Found <span><b>{{paginator?.count}}</b></span> results for
        <span><b>"{{searchKeyword}}"</b></span></p>
      </div> -->

      <!-- List of users -->
      <div class="list overflow-auto mt-2" *ngIf="paginator?.count && selectedOption === 'searchUsers'">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="px-2 list-wrapper">
          <div *ngFor="let user of (stream$ | async);" fxFlex="48%" fxFlex.xs="100%" class="mb-3"
            (click)="user?.isMember ? '' : toggleUserSelection(user)" [ngClass]="{'selected': !!(selectedUsersMap[user?.profileKey])}">

            <div>
              <kl-selectable-user-tile
                [author]="user" avatarSize="md"
                [isSelected]="!!(selectedUsersMap[user?.profileKey])">
              </kl-selectable-user-tile>
            </div>
          </div>
        </div>

        <!-- Load more -->
        <div class="mt-3">
          <kl-load-more [noMoreText]="'No more users' | translate" [progress]="paginator.loading" [hasMoreItems]="paginator.hasItems"
            (clicked)="nextPage()">
          </kl-load-more>
        </div>
      </div>

      <!-- No content -->
      <div *ngIf="!paginator?.count" fxLayoutAlign="center center" class="pb-2 h-100 no-content">
        <kl-no-content [progress]="paginator?.loading" [progressText]="'Hold tight, loading users list...' | translate" matIcon="people"
          [text]="searchKeyword ? 'No user found with ' + searchKeyword : 'Type and search users' | translate"
          iconColor="#00000075">
        </kl-no-content>
      </div>
    </div>
  </div>
</section>
