import { Component, Inject, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiTasksCalendar } from '../../classes/kalgudi-tasks-calendar';

@Component({
  selector: 'kl-task-list-calendar',
  templateUrl: './task-list-calendar.component.html',
  styleUrls: ['./task-list-calendar.component.scss']
})

export class TaskListCalendarComponent extends KalgudiTasksCalendar implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
  ) {

    super(injector, notification, util);
  }

  ngOnInit() {

    this.getTasks();

  }

  onDestroyed(): void {}

}
