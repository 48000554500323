<section class="post-author">

  <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between">
    <div class="author-info" fxFlex="80%" fxFlex.xs="100%">

      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <!-- User profile pic -->
        <div fxFlex="{{avatarSize === 'md' ? '50' : '35'}}px" class="profile-pic rounded-circle"
          [ngClass]="{'medium': avatarSize == 'md', 'small': avatarSize == 'sm', 'large': avatarSize == 'lg'}">

          <img class="w-100 h-100 rounded-circle"
            [klErrorImages]="[
              (author?.profilePicURL || author?.profilePicUrl) | prefixDomain,
              domain + defaultProfilePic
            ]"
            [src]="(author?.profilePicURL || author?.profilePicUrl | resizeImage: resizedImage) | prefixDomain"
            (click)="viewProfile(author?.profileKey); $event.stopPropagation()" />
        </div>
        <!-- / User Profile pic -->

        <!-- user Details -->
        <div class="author-details" fxFlex="90%" fxFlex.xs="100%">

          <!-- User name -->
          <div fxLayout="row wrap" class="mw-100">
            <div class="mw-70 author-first-name mr-1">
              <a class="text-capitalize author-name mb-0 text-truncate d-inline-block"
                (click)="viewProfile(author?.profileKey); $event.stopPropagation()">
                {{ author?.firstName }}
              </a>
            </div>
            <div class="mr-1 page-title-name" *ngIf="pageName">
              <span class="text-muted mx-1 post">{{"posted in" | translate}}</span>

              <a class="text-capitalize author-name posted-page-name mb-0 text-truncate"
              (click)="viewPage(pageId); $event.stopPropagation()">
                {{ pageName }}
              </a>
            </div>
            <div class="mx-1 time-ago-details" *ngIf="!scheduledTime && !pageName">
              <span class="mb-0 text-muted mr-1">{{ postType | translate }}</span>
              <span class="mb-0 text-muted"
                [title]="TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">{{ TS | timeAgo }}
                <span translate>ago</span>
              </span>
              <ng-container *ngIf="LUT && (TS !== LUT)">
                <span class="dot d-inline-block align-middle mx-1 ml-2"></span>
                <span class="mb-0 text-muted"
                  [title]="LUT | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'"> <span translate>updated</span> {{ LUT | timeAgo }}
                  <span translate>ago</span>
                </span>
              </ng-container>
            </div>
            <div class="mx-1 time-ago-details" *ngIf="scheduledTime">
              <span class="mb-0 text-muted mr-1" translate>Scheduled on</span>
              <span class="mb-0 text-muted"
                [title]="scheduledTime | date: 'dd MMMM yyyy, hh:mm a'">{{ scheduledTime | date: 'dd MMMM yyyy, hh:mm:ss a' }}
              </span>
            </div>

          </div>
          <!-- /User name -->

          <div class="time-ago-details" *ngIf="!scheduledTime && pageName">
            <span class="mb-0 text-muted"
              [title]="TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">{{ TS | timeAgo }}
              <span translate>ago</span>
            </span>
            <ng-container *ngIf="LUT && (TS !== LUT)">
              <span class="dot d-inline-block align-middle mx-1 ml-2"></span>
              <span class="mb-0 text-muted"
                [title]="LUT | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'"> <span translate>updated</span> {{ LUT | timeAgo }}
                <span translate>ago</span>
              </span>
            </ng-container>
          </div>

          <!-- Entity name -->
          <div class="page-name mb-1" fxLayout="column" *ngIf="pageTitle">
            <a (click)="viewPage(pageId); $event.stopPropagation()">{{ pageTitle }}</a>
          </div>
          <!-- Entity name -->

          <!-- Business type and location -->
          <div fxLayout="row" class="mw-100">
            <div fxFlex="100%" fxFlex.xs="95%">
              <p class="text-truncate mb-0" fxLayout.xs="column">
                <span class="biz-type mb-0 text-truncate mt-n4px business-type" *ngIf="author?.businessTypeName">
                  {{ author?.businessTypeName | titlecase | translate }}
                </span>
                <span *ngIf="author?.businessTypeName && author?.location" class="dot d-inline-block align-middle mx-1 mobile-view">
                </span>
                <span class="mb-0 text-truncate text-muted mt-n4px business-type" *ngIf="author?.location && showLocation">
                  {{ author?.location }}
                </span>
              </p>
            </div>
          </div>
          <!-- / Business type and location-->

          <p class="mb-0 time" *ngIf="shareText">
            {{ shareText }}
          </p>
        </div>

        <!-- / User details -->
      </div>
    </div>

  </div>
</section>
