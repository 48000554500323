import { Inject, Directive } from '@angular/core';
import { KalgudiUserBasicSearchResults, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { StringAnyMap } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { KalgudiUsersPickerBase } from '../../kalgudi-users/classes/kalgudi-users-picker-base';
import { KalgudiUsersSearchService } from '../../kalgudi-users/services/kalgudi-users-search.service';

/**
 * My kalgudi connects search picker. It searches for all connected users in the kalgudi database.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiUsersPickerBase
 * @see KalgudiSearchStream
 * @see KalgudiStream
 */
@Directive()
export abstract class KalgudiConnectsPicker extends KalgudiUsersPickerBase {

  constructor(
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected usersSearch: KalgudiUsersSearchService,
  ) {

    // Wake up (initialize) parent
    super(notification, util);

    // On initialization perform search
    // This will perform empty search causing the picker
    // to load list of my connects.
    this.initStream();
  }

  /**
   * Defines the Api call for my kalgudi connects. If the search keyword is provided then it calls
   * the Api to search my connects otherwise it loads the list of all my connects.
   *
   * It chooses the Api call based on the search keyword.
   */
  protected userApi(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams?: StringAnyMap
    ): Observable<KalgudiUserBasicSearchResults> {

    return (this.util.isNullOrEmpty(searchKeyword)
      ? this.usersSearch.fetchMyKalgudiConnects(offset, limit)
      : this.usersSearch.searchMyKalgudiConnects(searchKeyword, offset, limit));
  }
}
