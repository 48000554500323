import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { checkMobileDevice, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { DEFAULT_DIALOG_CONFIG } from '../../kalgudi-dialogs/constants';
import { KalgudiDialogsService } from '../../kalgudi-dialogs/services/kalgudi-dialogs.service';
import { MobileDialogConfig } from '../../mobile-dialog/models';
import {
  StoreProductsSearchDialogComponent,
} from '../components/store-products-search-dialog/store-products-search-dialog.component';
import {
  StoreProductsSearchMobileDialogComponent,
} from '../components/store-products-search-mobile-dialog/store-products-search-mobile-dialog.component';
import { StoreProductsSearchApiService } from './store-products-search-api.service';

@Injectable()
export class StoreProductsSearchService {

  constructor(
    private kalgudiDialogService: KalgudiDialogsService,
    private api: StoreProductsSearchApiService
  ) { }

  /**
   * @param config Dialog
   */
  showStoreProductsDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openStoreProductsMobileDialog(details)
      : this.openStoreProductsDialog(details, config);
  }


  /**
   * Calls api to fetch the products
   */
  getProducts(offset: number, limit: number, storeType: string): Observable<KalgudiStreamData> {

    return this.api.fetchStream(offset, limit, storeType);
  }


  /**
   * Makes an API call to search list of all products with the specified
   * search keyword.
   */
  searchProducts(searchKeyword: string, offset: number, limit: number, storeType: string): Observable<KalgudiStreamData> {
    return this.api.searchProducts(searchKeyword, offset, limit, storeType);
  }

  /**
   * Opens store products dialog
   */
  private openStoreProductsDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(StoreProductsSearchDialogComponent, details, config);
  }

  /**
   * Opens store products dialog in mobile
   */
  private openStoreProductsMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(StoreProductsSearchMobileDialogComponent, details );
  }

}
