<div class="autocomplete bp-autocomplete">
  <form autocomplete="off" [formGroup]="autoCompleteForm">

    <mat-form-field [appearance]="appearance" class="w-100">
      <mat-label translate>{{label}}</mat-label>

      <input type="text" matInput formControlName="productName"
        [placeholder]="placeholder | translate"
        [matAutocomplete]="autoComplete" [required]="required">

      <span matSuffix *ngIf="loading$ | async">
        <span class="spinner-border spinner-border-lg text-muted" role="status"></span>
      </span>

      <mat-autocomplete autoActiveFirstOption #autoComplete="matAutocomplete" [displayWith]="displayWithFn">
        <mat-option *ngFor="let prediction of predictions$ | async" [value]="prediction" (onSelectionChange)="selectItem($event)">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="text-success">eco</mat-icon>

            <div>
              <span class="d-block autocomplete-field title">{{prediction?.productName}}</span>
              <span class="d-block autocomplete-field subtitle">{{prediction?.baseCategory?.value}}</span>
              <!-- <div fxLayout="column" fxLayoutAlign="start center">
              </div> -->
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
      <mat-error class="product-field" *ngIf="required && inputField?.touched" translate>Product is required</mat-error>
    </mat-form-field>

    <p class="mt-n1 my-1 text-danger" *ngIf="noPrediction && inputFieldValue?.length > 2" @fadeIn>
      <span translate>No products found with keyword </span>
      <strong>"{{ inputFieldValue }}"</strong>
    </p>
  </form>
</div>
