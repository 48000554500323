<div class="nutrition-values">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div>
      <img src="assets/icons/pyramid.svg" width="45">
    </div>

    <div>
      <p class="m-0 title">Nutrition Values</p>
      <span class="sub-title">of {{level3ProductDetails?.productLevel3Title | titlecase}}
        <span *ngIf="level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value || level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit">
          <span> (</span>
          <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value}}</span>
          <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit}}</span>
          <span> )</span>
        </span>
      </span>
    </div>
  </div>

  <div class="nutrition-list" *ngIf="nutrients?.length > 0">
    <div fxLayout="row wrap">
      <div fxFlex="33.3%" *ngFor="let item of nutrients" class="tile">
        <p class="m-0 name text-truncate">{{item?.type | titlecase}}</p>
        <p class="m-0 value">{{item?.value}}</p>
      </div>

    </div>

  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="content" *ngIf="nutrients?.length <= 0">
    <p class="no-content mb-0">No nutrients available</p>
  </div>
</div>
