import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

/**
 *
 * @usageNotes
 * ```
 * <img class="w-100 h-100 rounded-circle"
 *   [klErrorImages]="[
 *     (author?.profilePicURL || author?.profilePicUrl) | prefixDomain,
 *     defaultProfilePic
 *   ]"
 *   [src]="(author?.profilePicURL || author?.profilePicUrl | resizeImage: '150x150') | prefixDomain"/>
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive({
  selector: '[klErrorImages]'
})
export class ErrorImagesDirective implements AfterViewInit {

  @Input()
  klErrorImages: string[] = [];

  @Input()
  klErrorBackgroundImage: string[] = [];

  // Initially no error image is set. This will be incremented to 0
  // when first error occurs
  private activeErrorImageIndex = -1;

  private readonly elem: HTMLElement;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.elem = this.elementRef.nativeElement;
  }

  ngAfterViewInit(): void {
    this.attachErrorListener();

    const allImages = [
      (this.elem as HTMLImageElement).src,
      ...this.klErrorImages
    ];

    // Log all images list to the element
    this.renderer.setAttribute(this.elem, 'data-err-images', allImages.join(','));
  }

  /**
   * Attaches error listener to the element
   */
  private attachErrorListener(): void {
    this.elem.addEventListener('error', (event) => this.onError(event));
  }

  /**
   * Removes error listener from the element
   */
  private removeErrorListener(): void {
    this.elem.removeEventListener('error', _ => _);
  }

  /**
   * Handles image or background image error logic
   */
  private onError(event: ErrorEvent): void {

    if (Array.isArray(this.klErrorImages) && this.klErrorImages.length > 0) {
      this.switchErrorImage(event);
    } else if (Array.isArray(this.klErrorBackgroundImage) && this.klErrorBackgroundImage.length > 0) {
      this.switchErrorBackground(event);
    }
  }

  /**
   * Switches to error images on any error occurred while loading the image.
   */
  private switchErrorImage(event: ErrorEvent): void {

    const maxPossibleIndex = this.klErrorImages.length;
    this.activeErrorImageIndex++;

    if (this.activeErrorImageIndex >= maxPossibleIndex) {
      // No more images to switch ensure the error
      this.removeErrorListener();
      return;
    }

    (this.elem as HTMLImageElement).src = this.klErrorImages[this.activeErrorImageIndex];
  }

  private switchErrorBackground(event: ErrorEvent): void {

  }
}
