import { Component, Input, OnInit } from '@angular/core';
import { KalgudiUserBasicDetails } from '@kalgudi/types';

@Component({
  selector: 'kl-traceability-map',
  templateUrl: './traceability-map.component.html',
  styleUrls: ['./traceability-map.component.scss']
})

export class TraceabilityMapComponent implements OnInit {

  @Input()
  fulfillmentBasedOnStore: any

  @Input()
  sellerDetails: KalgudiUserBasicDetails;

  @Input()
  buyerDetails;

  @Input()
  productLevel3Title: string;

  @Input()
  progress: boolean;

  @Input()
  orderDetails: any;

  constructor() {}

  ngOnInit() {
  }
}