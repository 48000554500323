<div *ngIf="(stream$ | async) as stream" class="store-products-list">
  <div fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center" class="mb-3">
    <div fxFlex="40%" fxFlex.xs="100%">
      <p class="m-0 total-products" translate>Total products<span>:</span>
        <span id="store-products-count">{{ stream?.length }}</span>
      </p>
    </div>

    <div class="w-100">
      <kl-search class="search w-100"
        placeHolder="Type and hit enter to search products"
        (search)="search();"
        [searchForm]="searchForm">
      </kl-search>
    </div>
  </div>

  <div class="store-products overflow-auto" fxLayout="column">

    <div fxLayout="row wrap" fxLayoutGap="15px"  class="w-100 products-container">
      <div fxFlex="0 0 calc(25% - 15px)" fxFlex.xs="0 0 calc(50% - 15px)"
        class="tile" *ngFor="let product of stream" (click)="toggleProductSelection(product)">
        <kl-store-product-tile [product]="product" [isSelected]="!!(selectedProductsMap[product?.productId_level3])"></kl-store-product-tile>
      </div>
    </div>

    <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

      <div fxLayoutAlign="center center" class="pb-2 h-100 no-content">
        <ng-container *ngIf="!stream || stream?.length <= 0">
          <kl-no-content
            faIcon="fab fa-envira"
            text="Type and hit enter to search products"
            progressText="Hold tight, searching products..."
            [progress]="paginator?.loading">
          </kl-no-content>
        </ng-container>
      </div>
      <!-- Load more -->
      <div class="mt-3" *ngIf="paginator && stream?.length">
        <kl-load-more
          id="store-products-load-more"
          noMoreText="No more products"
          [progress]="paginator?.loading"
          [hasMoreItems]="paginator?.hasItems"
          (clicked)="nextPage()">
        </kl-load-more>
      </div>
      <!-- /Load more -->
    </div>

  </div>


  <div fxLayoutAlign="end end" class="mt-3">
    <button id="add-products-button"  mat-raised-button color="primary" (click)="submit()"><span translate>Add</span></button>
  </div>

</div>


