import { Pipe, PipeTransform } from '@angular/core';

/**
 * Parses a kalgudi standard or ISO standard date format to date object type.
 *
 * @author Pankaj Prakash
 * @example
 * <span>{{ update?.date | parseDate | date: 'dd mmm yyyy'}}</span>
 */
@Pipe({
  name: 'parseDate'
})
export class ParseDatePipe implements PipeTransform {


  /**
   * Parses a string representation of Java date object to Javascript
   * date object.
   *
   * @param value String representation of Java date.
   * @returns Date object.
   */
  static parseDate(value: string): Date {
    // Null checks
    if (!value) { return undefined; }

    let parsedDate = ParseDatePipe.parseISODate(value);

    parsedDate = !isNaN(parsedDate.getTime()) ? parsedDate : ParseDatePipe.parseKalgudiDate(value);

    return parsedDate;
  }

  transform(value: string, ...args: any[]): Date {
    return ParseDatePipe.parseDate(value);
  }

  /**
   * Try parsing ISO format string to Date.
   */
  private static parseISODate(value: string): Date {

    let d: Date = null;

    try {
      d = new Date(value);
    } catch (e) {
      d = null;
    }

    return d;
  }

  /**
   * Try parsing Kalgudi format string to Date.
   */
  private static parseKalgudiDate(value: string): Date {

    const split = value.split(' ');
    const date  = split[0].split('-');
    const time  = split[1].split(':');

    // Separate date and time from input string
    const input = {
      ms:   +split[2],
      sec:  +time[2] - 1,
      min:  +time[1] - 1,
      hr:   +time[0],
      day:  +date[2],
      mon:  +date[1] - 1,
      year: +date[0]
    };

    // Construct and return date
    let d: Date = null;

    try {
      d = new Date(Date.UTC(input.year, input.mon, input.day, input.hr, input.min, input.sec, input.ms));
    } catch (e) {}

    return d;
  }


}
