import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';
import { KalgudiFarmerLand } from '@kalgudi/types';

@Component({
  selector: 'kl-satellite-advisoires-nitrogen-level-tile',
  templateUrl: './satellite-advisoires-nitrogen-level-tile.component.html',
  styleUrls: ['./satellite-advisoires-nitrogen-level-tile.component.scss'],
  animations: [ floatTopAnimation ],
})
export class SatelliteAdvisoiresNitrogenLevelTileComponent implements OnInit {

  @ViewChild('satImg', { static: true })
  satImgRef: ElementRef;

  showMap: boolean = false;

  @Input()
  animationDelay = 0;

  @Input()
  estimatedTime: string;

  @Input()
  nitrogenImage: string;

  @Input()
  nitrogenDetails: any;

  @Input()
  landDetails: KalgudiFarmerLand;

  @Input()
  bbox: number[] = [];

  boundaryPoints: string = '';

  constructor() {}

  ngOnInit() {
  }

  getCenterCoord(point_list) {
    var sumLat = -point_list[0].lat;
    var sumLong = -point_list[0].lng;

    point_list.map(function(x){sumLat+=x.lat;sumLong+=x.lng});
    var avgLat = sumLat/(point_list.length-1)
    var avgLong = sumLong/(point_list.length-1);
    return "("+Math.round(avgLat*100)/100+","+Math.round(avgLong*100)/100+")";
  }
}
