import { Injector, Input, Directive } from '@angular/core';
import { KalgudiCommonRouteConfig } from '@kalgudi/common';
import { DEFAULT_PROFILE_PIC, KalgudiAppService, KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION, KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiProjectTask, PartialData, ProjectRole, ProjectStatus } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class TaskSubmissionDetails extends KalgudiDestroyable {

  @Input()
  taskId: string;

  @Input()
  projectId: string;

  @Input()
  taskDetails: KalgudiProjectTask;

  @Input()
  submissionDetails: KalgudiProjectTask;

  @Input()
  extraParams: PartialData = {};

  projectRoles = ProjectRole;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  taskStatus = [
    {
      id: ProjectStatus.COMPLETED,
      name: 'Completed',
      matIcon: 'check_circle_outline'
    },
    {
      id: ProjectStatus.IN_PROGRESS,
      name: 'In progress',
      matIcon: 'slow_motion_video'
    },
    {
      id: ProjectStatus.NOT_STARTED,
      name: 'Not started',
      matIcon: 'trip_origin'
    },
  ];

  private kalgudiApp: KalgudiAppService;
  private util: KalgudiUtilityService;
  private taskService: KalgudiTasksService;
  private notifications: KalgudiNotification;
  private stateService: KalgudiProjectStateService;
  private appRouting: KalgudiCommonRouteConfig;
  private tasksActionService: KalgudiTaskActionsService;

  constructor(protected injector: Injector) {

    super();

    this.notifications      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.appRouting         = this.injector.get<KalgudiCommonRouteConfig>(KL_ROUTE_CONFIG);
    this.kalgudiApp         = this.injector.get(KalgudiAppService);
    this.util               = this.injector.get(KalgudiUtilityService);
    this.taskService        = this.injector.get(KalgudiTasksService);
    this.stateService       = this.injector.get(KalgudiProjectStateService);
    this.tasksActionService = this.injector.get(KalgudiTaskActionsService);

  }

  // --------------------------------------------------------
  // #region Public methods
  // --------------------------------------------------------

  /**
   * Navigates to user profile page
   */
  viewProfile(profileKey: string) {
    this.appRouting.toProfile({ profileKey });
  }


  /**
   * Method to get task full details
   * @param taskId
   */
  getTaskDetails(taskId: string) {

    // this.notifications.showSpinner();

    this.taskService.getTaskDetails(taskId)
      .pipe(
        finalize(() => this.notifications.hideSpinner())
      )
      .subscribe(
        res => this.taskDetails = res,
      );
  }

  /**
   * Update the task
   */
  updateState() {
    this.tasksActionService.openTaskStatusChangeDialog(this.submissionDetails)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => {
          this.stateUpdateHandler(res);

        },
        err => this.stateUpdateErrHandler(err)
      );

    // const payload = this.prepareUpdateStatePayload(selectedState);

    // this.taskService.createActivity(this.projectId, this.taskId, ActivityType.TASK_SUBMISSION, payload, '', '', Action.STATE_UPDATE )
    //   .subscribe(
    //     res => {
    //       this.getTaskDetails(this.taskId);
    //       this.stateService.reloadTasks();
    //       this.stateUpdateHandler(res);
    //     },
    //     err => this.stateUpdateErrHandler(err)
    //   );
  }


  onDestroyed(): void {}

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Prepares payload to
   */
  protected prepareUpdateStatePayload(selectedState: ProjectStatus): any {

    // If user himself is not filling the form then assign the assisted to field
    // to the payload.
    // It ensure that the user is filling the form on behalf of other.
    const assistedTo  = this.submissionDetails && this.isAssisting(this.submissionDetails.createdBy.profileKey)
      ? this.util.clone(this.submissionDetails.createdBy)
      : null;

    return this.util.clone({
      state: selectedState,
      assistedTo,
      ...this.extraParams,
    });
  }

  /**
   * Event handler for successful state updation
   */
  protected stateUpdateHandler(res: any) {
    this.getTaskDetails(this.taskDetails.taskId);
    this.stateService.reloadTasks();
    this.stateService.updateTaskDetails(this.taskDetails);
    if (res.data.submissionDetails) {

      this.submissionDetails.state = res.data.submissionDetails.state;
      this.submissionDetails.stateChangedOn = res.data.submissionDetails.stateChangedOn;
    }

    // this.notifications.showMessage('Task state updated');
  }

  /**
   * Event handler for failed to update state
   */
  protected stateUpdateErrHandler(err: Error) {
    this.notifications.showMessage('Failed to update task state');
  }

  /**
   * Returns `true` if the task is submitted by the admin of the task.
   */
  private isAssisting(taskAssignedToProfileKey: string): boolean {
    return taskAssignedToProfileKey !== this.kalgudiApp.profileLocal.profileKey;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
