<!-- Tags -->
<section class="task-details" fxLayout="row" fxLayout.xs="column" fxLayoutGap="15px">
  <!-- Info tile -->
  <div fxFlex="calc(33.3% - 15px)" fxFlex.xs="100%" class="tile bg-white info-color overflow-auto rounded mt-2" *ngIf="taskDetails?.tags?.info && taskDetails?.tags?.info?.length && taskDetails?.tags?.info[0]">
    <div  class="tags-tile-header" fxLayout fxLayoutAlign="space-between center">
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
        <!-- <div>
          <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
            [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
        </div> -->

        <div>
          <p class="m-0 title font-weight-bold" translate>INFORMATION</p>
        </div>
      </div>
    </div>

    <div class="task-info-tile">
      <div *ngFor="let info of taskDetails?.tags?.info; let first = first; let last = last" [ngClass]="{'pt-2': first, 'border-bottom mb-2': !last}">
        <p class="m-0">{{info}}</p>
      </div>
    </div>
  </div>
  <!--/ Info tile -->

  <!-- Input tile -->
  <div fxFlex="calc(33.3% - 15px)" fxFlex.xs="100%" class="tile bg-white rounded overflow-auto mt-2 input-color" *ngIf="taskDetails?.tags?.input && taskDetails?.tags?.input?.length">
    <div  class="tags-tile-header" fxLayout fxLayoutAlign="space-between center">
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
        <!-- <div>
          <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
            [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
        </div> -->

        <div>
          <p class="m-0 title font-weight-bold" translate>INPUT</p>
        </div>
      </div>
    </div>
    <div class="input-tile">
      <div class="task-info-tile" *ngFor="let input of taskDetails?.tags?.input;let i = index; let first = first; let last = last">
        <div fxLayout="row" class="py-2" fxLayoutAlign="space-between center">
          <div *ngIf="!input?.product?.productName" fxFlex="90%">
            <p class="m-0 input-heading">Product (-)</p>
          </div>
          <div *ngIf="input?.product?.productName" fxFlex="90%">
            <p class="m-0 input-heading" *ngIf="input?.product?.productName">Product - {{ input?.product?.productName ? input?.product?.productName : '-' }}</p>
          </div>
          <div fxLayoutAlign="end center" fxFlex="10%">
            <button mat-icon-button class="add-button add-circle" fxLayout="row" fxLayoutAlign="center center" (click)="displayDetails(i)" *ngIf="(selectedItem !== i || !show) && !singleItem">
              <mat-icon class="add-button">add</mat-icon>
            </button>
            <button mat-icon-button class="remove-button remove-circle" fxLayout="row" fxLayoutAlign="center center" (click)="hideExtraInfo(i)" *ngIf="(selectedItem === i && show) || singleItem">
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </div>

        <div *ngIf="(show && selectedItem === i) || singleItem">
          <div fxLayout="row" class="pb-1" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 input-data-heading text-muted">{{input?.needType === 'Services' ? 'Quantity per acre' : 'Requirement per acre' }}</p>
            </div>
            <div fxLayoutAlign="start center">
              <p class="m-0 value mr-1">{{ input?.quantityPerAcre?.value ? input?.quantityPerAcre?.value : '-' }}
                {{ input?.quantityPerAcre?.value ? input?.quantityPerAcre?.unit : '' }}
                <!-- <span *ngIf="input?.noOfAcres && input?.needType === 'Machinery'">/ {{input?.noOfAcres}} acres</span> -->
              </p>
            </div>
          </div>
          <div fxLayout="row" *ngIf="input?.needType === 'Services' || input?.needType === 'Machinery'" class="pb-1" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 input-data-heading text-muted">No of hours per acre</p>
            </div>
            <div>
              <p class="m-0 value mr-1">{{ input?.noOfHourPerAcre ? input?.noOfHourPerAcre : '-' }}</p>
            </div>
          </div>
          <div fxLayout="row" class="pb-1" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 input-data-heading text-muted">Cost/{{input?.needType === 'Services' || input?.needType === 'Machinery' ? 'hour' : 'acre' }}</p>
            </div>
            <div>
              <p class="m-0 value mr-1">{{ input?.cost ? '₹' : '' }}{{ input?.cost ? input?.cost : '-' }}</p>
            </div>
          </div>
          <div fxLayout="row" class="pb-2" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 input-data-heading text-muted">Need type</p>
            </div>
            <div>
              <p class="m-0 value mr-1">{{ input?.needType ? input?.needType : '-' }}</p>
            </div>
          </div>
        </div>

        <div class="separator"></div>

      </div>
    </div>
  </div>
  <!--/ Input tile -->

  <!-- Output tile -->
  <div fxFlex="calc(33.3% - 15px)" fxFlex.xs="100%" class="tile bg-white rounded overflow-auto mt-2 output-color" *ngIf="taskDetails?.tags?.output && taskDetails?.tags?.output?.length">
    <div class="tags-tile-header" fxLayout fxLayoutAlign="space-between center">
      <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">
        <!-- <div>
          <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/inputs-outputs.svg"
            [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
        </div> -->

        <div>
          <p class="m-0 title font-weight-bold" translate>OUTPUT</p>
        </div>
      </div>
    </div>

    <div class="task-info-tile">

      <ng-container *ngFor="let output of taskDetails?.tags?.output; let first = first; let last = last">
        <div *ngIf="output?.product?.productName || output?.expectedYieldPerAcre?.value || output?.expectedYieldPerAcre?.unit || output?.cost">
          <div fxLayout="row" class="separator py-2" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 heading">Product</p>
            </div>
            <div>
              <p class="m-0 value">{{output?.product?.productName ? output?.product?.productName : '-'}}</p>
            </div>
          </div>
          <div fxLayout="row" class="separator py-2" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 heading">Yield per acreage</p>
            </div>
            <div>
              <p class="m-0 value">{{output?.expectedYieldPerAcre?.value ? output?.expectedYieldPerAcre?.value : '-'}} {{output?.expectedYieldPerAcre?.unit}}</p>
            </div>
          </div>
          <div fxLayout="row" class="py-2" fxLayoutAlign="space-between center">
            <div>
              <p class="m-0 heading">Cost</p>
            </div>
            <div>
              <p class="m-0 value">{{output?.cost ? output?.cost : '-'}}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <!--/ Output tile -->

</section>
<!--/ Tags -->
