import { Component, EventEmitter, Inject, Injector, OnInit, Output } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiUploadService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ShareQaFullDetails, ShareQaRequest } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

import { KalgudiSocialQA } from '../../kalgudi-social-qa';
import { KalgudiQaService } from '../../services/kalgudi-qa.service';


@Component({
  selector: 'kl-qa-form',
  templateUrl: './qa-form.component.html',
  styleUrls: ['./qa-form.component.scss']
})
export class QaFormComponent extends KalgudiSocialQA<ShareQaRequest, ShareQaFullDetails> implements OnInit {

  @Output()
  postCreated = new EventEmitter<any>();

  rowsCount: number;
  page: any;

  isMobileDevice: boolean;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected qaService: KalgudiQaService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected uploadImageTos3: KalgudiUploadService,
  ) {

    super (
      injector,
      notifications,
      imagePickerService,
      util,
      qaService,
      socialDataNormalizer,
      kalgudiApp,
      uploadImageTos3,
    );

    this.isMobileDevice = checkMobileDevice();

    this.attachmentsFormControl.valueChanges
      .pipe( takeUntil(this.destroyed$))
      .subscribe(v => this.attachmentsFormControl.setValue(v, { onlySelf: true, emitEvent: false }));

  }

  ngOnInit() {
    this.rowsCount = checkMobileDevice() ? 10 : 3;
  }

  /**
   * Called once, before the instance is destroyed. Declared by parent class.
   */
  onDestroyed(): void { }

  /**
   * Creates new share update
   */
  sharePost(page?: any): void {
    this.page = page;
    this.createPost();
  }

  /**
   * Event handler called after the post is QA successfully.
   */
  onPostCreated(req: ShareQaRequest, res: any): void {
    // console.log(req, res);
    // this.shareForm.reset();
    this.postCreated.emit(res);
  }

}
