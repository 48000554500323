import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

import { KalgudiSlideToggleComponent } from './kalgudi-slide-toggle.component';



@NgModule({
  declarations: [KalgudiSlideToggleComponent],
  imports: [
    CommonModule,

    FlexLayoutModule,

    TranslateModule
  ],
  exports: [
    KalgudiSlideToggleComponent
  ]
})
export class KalgudiSlideToggleModule { }
