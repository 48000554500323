import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiProgressStatsModule } from '@kalgudi/common/ui/progress-stats';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { ProjectMembersStatsComponent } from './components/project-members-stats/project-members-stats.component';
import { ProjectTaskStatsComponent } from './components/project-task-stats/project-task-stats.component';
import { ProjectStateService } from './services/project-state.service';



@NgModule({
  declarations: [
    ProjectTaskStatsComponent,
    ProjectMembersStatsComponent,
  ],
  imports: [
    CommonModule,

    // Kalgudi modules
    KalgudiProgressStatsModule,
    KalgudiButtonModule,

    // Material module
    MatButtonModule,
    MatIconModule,
    MatMenuModule,

    FlexLayoutModule,

    TranslateModule,

    AngularSvgIconModule
  ],
  exports: [
    ProjectTaskStatsComponent,
    ProjectMembersStatsComponent,
  ],
  providers: [
    ProjectStateService
  ]
})
export class KalgudiProjectSharedModule { }
