import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HttpStatusCode, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseProjectMembersData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiMembersApiService {

  /**
   * `v2/projects/members`
   */
  private readonly API_MEMBERS = `${this.environment.restBaseUrlV2}/projects/members`;

  /**
   * `v2/projects/members/search`
   */
  private readonly API_SEARCH_MEMBERS = `${this.API_MEMBERS}/search`;


  constructor(
    @Inject(KL_ENV) private environment: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Api to get members
   */
  getMembers(projectId: string, offset: number = 0, limit: number = 50): Observable<ApiResponseProjectMembersData> {

    const params = {
      projectId,
      offset: offset.toString(),
      limit: limit.toString()
    };

    return this.httpClient
      .get<ApiResponseCommon>(this.API_MEMBERS, { params })
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),
        map(res => res.data)
      );
  }

  /**
   * Api to search project members
   */
  searchMembers(projectId: string, searchKeyword: string, offset: number, limit: number): Observable<ApiResponseProjectMembersData> {

    const params = {
      projectId,
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient
    .get<ApiResponseCommon>(this.API_SEARCH_MEMBERS, { params })
    .pipe(
      // Api response error handler
      map(res => this.util.apiErrorHandler(res)),

      // No errors, all good return true
      map(res => res.data)

    );
  }
}
