import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIG, KalgudiDialogsService, MobileDialogConfig } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { AddTaskMembersDialogComponent } from '../components/add-task-members-dialog/add-task-members-dialog.component';
import {
  AddTaskMembersMobileDialogComponent,
} from '../components/add-task-members-mobile-dialog/add-task-members-mobile-dialog.component';

@Injectable()
export class AddTaskMembersDialogService {

  constructor( private kalgudiDialogService: KalgudiDialogsService) { }


  /**
   * Open add members to task dialog
   *
   * @param config Dialog
   */
  showAddTaskMembersDialog(
    details: KalgudiDialogConfig,
    config: MatDialogConfig<any> = DEFAULT_DIALOG_CONFIG,
  ): Observable<KalgudiDialogResult> {
    // return this.dialogsService.openDialog(UploadImageDialogComponent, details, config);
    return checkMobileDevice()
      ? this.openAddTaskMembersMobileDialog(details)
      : this.openAddTaskMembersDialog(details, config);
  }


  /**
   * Opens add members to task dialog
   */
  openAddTaskMembersDialog(details: KalgudiDialogConfig, config: MatDialogConfig): Observable<KalgudiDialogResult> {
    return this.kalgudiDialogService.openDialog(AddTaskMembersDialogComponent, details, config);
  }

  /**
   * Opens add members to task Mobile dialog
   */
  openAddTaskMembersMobileDialog(details: KalgudiDialogConfig, config?: MobileDialogConfig) {
    return this.kalgudiDialogService.openMobileDialog(AddTaskMembersMobileDialogComponent, details );
  }
}
