<div class="social-shares">
  <button mat-button [ngClass]= "{'share-icon-btn': hideShareLabel} " [class.mat-icon-button]= "hideShareLabel" class="btn share-btn" (click)="openShareButton()" [color]="btnColor"  #triggerBtn="matMenuTrigger" [matMenuTriggerFor]="menu" [disabled]="!isLoggedIn"
    [title]="'Share event on social media' | translate">
    <svg-icon class="svg-icon" src="assets/svgs/share.svg" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }">
    </svg-icon>
    <span class="ml-1 share" *ngIf="!hideShareLabel" translate>Share</span>
    <span class="share" *ngIf="shareCount">({{ shareCount }})</span>
  </button>
  <mat-menu #menu="matMenu" class="px-2 ml-3 custom">
    <div class="social-btn" fxLayoutAlign="center center" fxLayout="{{ direction === 'vertical' ? 'column' : 'row' }}" fxLayoutGap="0px">
      <div>
        <button mat-icon-button matTooltip="Click to copy" matTooltipPosition="before" (click)="clickToCopyUrl()">
          <i class="fa fa-clone copy-icon"></i>
        </button>
      </div>
      <div>
        <button mat-icon-button
          (click)="shareToWhatsappWeb()">
          <i class="fab fa-whatsapp share-icons"></i> </button>
      </div>
      <div>
        <button mat-icon-button
          (click)="shareToFacebook()"><i class="fab fa-facebook-f share-icons"></i> </button>
      </div>
      <div>
        <button mat-icon-button
          (click)="shareToTwitter()">
          <!-- <i class="fab fa-twitter share-icons"></i> -->
          <svg xmlns="http://www.w3.org/2000/svg" height="1.2em" viewBox="0 0 512 512">
            <path
              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
          </svg>
        </button>
      </div>
      <div>
        <button mat-icon-button
          (click)="shareToLinkedin()">
          <i class="fab fa-linkedin-in share-icons"></i>
        </button>
      </div>
      <div>
        <button mat-icon-button
          (click)="shareToEmailWeb()">
          <i class="fas fa-envelope-square share-icons"></i>
        </button>
      </div>
    </div>
  </mat-menu>
</div>


