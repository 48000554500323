<main id="profile-certificates-achievements">
  <div class="mb-2 certificates" fxLayout fxLayoutAlign="space-between center">

    <!-- Certificates heading -->
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center">

      <svg-icon src="assets/svgs/trophy.svg"
        class="text-center menu-icons cursor-pointer"
        [applyCss]="true"
        [svgStyle]="{ 'width.px': 18, 'height.px': 18}">
      </svg-icon>

      <h4 class="titles mb-0" translate>Certificates</h4>

    </div>
    <!-- / Certificates heading -->

    <!-- Add certificate button -->
    <div class="edit-section" *ngIf="editable">
      <button mat-icon-button class="add-btn" [matTooltip]="'Add certificate' | translate" (click)="showAddCertificateDialog()">
        <mat-icon class="text-white">add</mat-icon>
      </button>
    </div>
    <!--/ Add certificate button -->

  </div>

  <!-- Certificates -->
  <ng-container *ngFor="let certificate of certificates; let i = index">
    <div fxLayout fxLayoutAlign="space-between center" class="border-bottom pb-2 mb-2 certificate-list">
      <div fxLayout fxLayoutGap="8px" fxLayoutAlign="start center">
        <div>
          <img src="assets/images/certificates.jpg" width="60" height="50">
        </div>
        <div>
          <h5 class="achievement-title mb-1 text-capitalize">{{ certificate?.certName }} </h5>
          <p class="achievement-description mb-0">
            <span translate>From</span> {{ certificate?.certAuthority }}</p>
        </div>
      </div>

      <!-- Certificate actions -->
      <div>
        <div class="edit-section" *ngIf="editable">
          <button mat-icon-button class="edit-btn"
            [matMenuTriggerFor]="menu">
            <mat-icon class="text-muted">more_horiz</mat-icon>
          </button>

          <mat-menu #menu="matMenu" [xPosition]="'before'" class="mat-menu-md task">

            <button mat-menu-item id="edit-certificate" (click)="updateCertificate(certificate?.businessCertificationId, certificate)">
              <mat-icon class="action-icons">edit</mat-icon>
              <span>Edit</span>
            </button>

            <button mat-menu-item id="delete-certificate" (click)="deleteCertificate(certificate?.businessCertificationId)">
              <mat-icon class="action-icons">delete_outline</mat-icon>
              <span>Delete</span>
            </button>

          </mat-menu>
        </div>
      </div>
      <!--/ Certificate actions -->

    </div>

  </ng-container>
  <!--/ Certificates -->

  <!-- No content if there no certificates -->
  <ng-container *ngIf="!certificates?.length">
    <div class="no-content-center w-100" fxLayoutAlign="center center">
      <kl-no-content
        text="No certificate added yet!">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no certificates -->
</main>
