import { Component, Inject, Injector, OnInit } from '@angular/core';
import { kalgudiAnimations } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { ProjectStatus } from '@kalgudi/types';

import { KalgudiProjectRouteConfig } from '../../../../config';
import { TaskListTable } from '../../classes/kalgudi-tasks-table';

@Component({
  selector: 'kl-task-list-table',
  templateUrl: './task-list-table.component.html',
  styleUrls: ['./task-list-table.component.scss'],
  animations: kalgudiAnimations
})
export class TaskListTableComponent extends TaskListTable implements OnInit {

  projectStatus = ProjectStatus;

  constructor(
    protected injector: Injector,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiProjectRouteConfig
  ) {
    super(injector);
  }

  ngOnInit() {

    // Initializes the KalgudiMatTableStream
    this.initTableStream(this.matPaginator, this.matSort);

    if (this.extraFilters && this.extraFilters.pageId) {
      this.displayedColumns.splice(3, 1);
    }
  }

  protected onDestroyed(): void { }

}
