import { Inject, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiDialogConfig, KalgudiProjectTask, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { AddTaskMembersDialogService } from '../../add-task-members/services/add-task-members-dialog.service';
import { KalgudiTaskCreationDialogService } from '../../task-creation/services/kalgudi-task-creation-dialog.service';
import { TaskStatusDialogComponent } from '../components/task-status-dialog/task-status-dialog.component';
import { KalgudiTaskFullViewDialogService } from './kalgudi-task-full-view-dialog.service';
import { KalgudiTasksService } from './kalgudi-tasks.service';

@Injectable()
export class KalgudiTaskActionsService {

  constructor(
    @Inject(KL_NOTIFICATION) private notification: KalgudiNotification,
    private taskCreationService: KalgudiTaskCreationDialogService,
    private kalgudiDialogs: KalgudiDialogsService,
    private tasksServices: KalgudiTasksService,
    private addTaskMembersService: AddTaskMembersDialogService,
    private taskFullViewDialogService: KalgudiTaskFullViewDialogService
  ) {
  }

  openTaskCreationDialog(projectId: string, taskId = '', extraParams: PartialData = {}): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: taskId ? 'Edit task' : 'Create Task',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        projectId,
        taskId,
        filters: extraParams,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '725px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.taskCreationService.openTaskCreationDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(res => res.accepted),

      );
  }

  /**
   * Open task status change dialog
   */
  openTaskStatusChangeDialog(submissionDetails: KalgudiProjectTask = {}, extraParams: PartialData = {}): Observable<any> {

    // Input dialog UI configuration

    const dialogDetails: KalgudiDialogConfig = {
      title: 'Change status',
      acceptButtonTitle: 'Create',
      rejectButtonTitle: 'Cancel',
      data: {
        taskSubmissionDetails: submissionDetails,
        filters: extraParams,
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.openDialog(TaskStatusDialogComponent, dialogDetails, dialogConfig);
  }

  /**
   * Deletes the task
   */
  deleteTask(taskId: string, filters: PartialData = {}): Observable<KalgudiProjectTask> {

    return this.showDeleteConfirmationDialog()
      .pipe(

        // Calls delete api service
        switchMap(r => this.tasksServices.deleteTask(taskId, filters)),
      );
  }

  /**
   * Open add members dialog
   */
  showAddTaskMembersDialog(taskId: string, projectId: string): Observable<any> {
    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Add members to task',
      acceptButtonTitle: 'Add members',
      rejectButtonTitle: 'Cancel',
      data: {
        taskId,
        projectId
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '900px',
      maxWidth: '900px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        taskId
      }
    };

    return this.addTaskMembersService.showAddTaskMembersDialog(dialogDetails, dialogConfig)
      .pipe(
        // filter(res => res.accepted)
      );

  }

  /**
   * Open task fullview
   */
  showTaskFullviewDialog(taskTitle: string, taskId: string, taskDetails: KalgudiProjectTask): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: taskTitle,
      acceptButtonTitle: '',
      rejectButtonTitle: 'Cancel',
      data: {
        taskTitle,
        taskId,
        taskDetails
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '60vw',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        taskId
      }
    };

    return this.taskFullViewDialogService.showTaskFullViewDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res.accepted),
      );

  }

  /**
   * Open add members dialog
   */
  showAddTaskSubmissionDialog(
    taskId: string,
    projectId: string,
    taskDetails: KalgudiProjectTask,
    submissionDetails: KalgudiProjectTask,
    extraParams: PartialData = {},
  ): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: taskDetails.title + ' - submission',
      rejectButtonTitle: 'Cancel',
      data: {
        taskId,
        projectId,
        taskDetails,
        submissionDetails,
        extraParams: {
          ...extraParams,
        }
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.taskFullViewDialogService.showTaskSubmissionDialog(dialogDetails, dialogConfig)
      .pipe(
        // filter(res => res.accepted)
      );
  }

  /**
   * Open task stats dialog
   */
  showAddTaskStatsDialog(
    taskId: string,
    statType: string, taskTitle: string
  ): Observable<any> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: taskTitle,
      rejectButtonTitle: 'Cancel',
      data: {
        statType: statType,
        taskId: taskId
        }
      }

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '700px',
      maxWidth: '700px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.taskFullViewDialogService.showTaskStatsDialog(dialogDetails, dialogConfig)
      .pipe(
        filter(res => res.accepted)
      );
  }


  /**
   * Opens dialog for deleting task
   */
  private showDeleteConfirmationDialog(): Observable<boolean> {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Confirm delete',
      acceptButtonTitle: 'Delete',
      rejectButtonTitle: 'Cancel',
      message: 'Action is irreversible, this will delete the task. Are you sure you want to delete?',
      matIcon: 'warning',
      iconColor: 'warn',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    return this.kalgudiDialogs.showConfirm(dialogDetails, dialogConfig)
      .pipe(

        // Filter only accepted actions, do nothing for cancel actions
        filter(r => r.accepted),

        // Transform the partial data to boolean whether confirmation accepted or rejected
        map(r => r.accepted),
      );
  }
}
