import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShareUpdate } from '@kalgudi/types';
import { EmbedVideoService } from 'ngx-embed-video';

@Component({
  selector: 'kl-post-tile-link-attachment',
  templateUrl: './post-tile-link-attachment.component.html',
  styleUrls: ['./post-tile-link-attachment.component.scss']
})
export class PostTileLinkAttachmentComponent implements OnInit, AfterViewInit {

  @Input()
  post: ShareUpdate;

  youtubeUrl: string;

  embedLink: string;

  @Input()
  image: string;

  @Input()
  url: string;

  @Input()
  title: string;

  @Input()
  domain: string;

  @Output()
  remove = new EventEmitter<any>();


  constructor(private embedService: EmbedVideoService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {

    if (this.post && this.post.uri != null) {
      if (this.post.uri.includes('https://www.youtube.com/') || this.post.uri.includes('https://youtu.be/')) {
        this.youtubeUrl = this.post.uri;
        this.embedLink = this.embedService.embed(this.youtubeUrl, {
          attr: { height: 355 }
        });
      }
    }
  }

  /**
   * Emits an event to the parent to clear the url metadata
   */
  clearUrlDetails() {
    this.remove.emit();
  }

}
