import { Component, Input, OnInit } from '@angular/core';
import { KalgudiUsersMap } from '@kalgudi/types';

@Component({
  selector: 'kl-kalgudi-share-selected-member',
  templateUrl: './kalgudi-share-selected-member.component.html',
  styleUrls: ['./kalgudi-share-selected-member.component.scss']
})
export class KalgudiShareSelectedMemberComponent implements OnInit {

  @Input()
  selectedUsers: KalgudiUsersMap[];

  constructor() { }

  ngOnInit() {
    this.selectedUsers = Object.values(this.selectedUsers);
  }

}
