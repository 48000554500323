<div *ngIf="shareForm">
  <div [formGroup]="shareForm">
    <div fxLayout.xs="column">

      <div *ngIf="!hideShareVisibility" fxFlex.xs="100%" fxLayout.xs="column">
        <div fxLayout="column">
        <!-- Share visibility selection-->
        <kl-share-visibility
          [shareVisibilityList]="shareVisibilityList"
          formControlName="recipient"
          (visibilityChanged)="clearShareFormTargetingFilters()">
        </kl-share-visibility>
        <!--/ Share visibility selection-->
      </div>
    </div>


    <!-- Share specific members picker -->
    <div *ngIf="shareFormVisibilityValue === sharedVisibilities?.SPECIFIC_KALGUDI_USER?.value">
      <div formGroupName="filter">
        <kl-share-specific-member-visibility
          [pageId]="pageId"
          [projectId]="projectId"
          [taskId]="taskId"
          [shareFormFilterUsers]="shareFormFilterUsers"
          formControlName="users">
        </kl-share-specific-member-visibility>
      </div>
    </div>
    <!-- / Share specific members picker -->

    <!-- Share target members -->
    <ng-container *ngIf="shareFormVisibilityValue === sharedVisibilities?.SPECIFIC_SUBSCRIBERS?.value">
      <div class="my-3" *ngIf="(memberTargetingFilters$ | async) as filters">
        <kl-share-target-members
          [hideBusinessFilter]="hideBusinessFilter"
          [hideProductsFilter]="hideProductsFilter"
          [pageId]="pageId"
          [projectId]="projectId"
          [taskId]="taskId"
          [productsList]="filters?.products"
          [productsFormArray]="shareFormFilterProducts"
          [organizationsList]="filters?.products"
          [organizationsFormArray]="shareFormFilterProducts"
          [businessTypesList]="filters?.businessTypes"
          [businessTypesFormArray]="shareFormFilterBusinessTypes"
          [locationsList]="filters?.locations"
          [locationsFormArray]="shareFormFilterLocations"
          [countriesList]="filters?.countries"
          [countriesFormArray]="shareFormFilterCountries"
          [statesList]="filters?.states"
          [statesFormArray]="shareFormFilterStates"
          [districtsList]="filters?.districts"
          [districtsFormArray]="shareFormFilterDistricts">
        </kl-share-target-members>
      </div>
    </ng-container>
    <!-- Share target members -->

  </div>
</div>
