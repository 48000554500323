import { HttpStatusCode } from './http-status-codes';

/**
 * Common rest api error messages
 */
export const REST_API_ERROR_MESSAGES = {
  [HttpStatusCode.BAD_REQUEST]: 'Server error, please try again later',
  [HttpStatusCode.UNAUTHORIZED]: 'Please login to continue!',
  [HttpStatusCode.INTERNAL_SERVER_ERROR]: 'Server error, please try again later',
  [HttpStatusCode.NOT_IMPLEMENTED]: 'Server error, please try again later',
  [HttpStatusCode.BAD_GATEWAY]: 'Unable to contact to server, please try again later',
  [HttpStatusCode.SERVICE_UNAVAILABLE]: 'Unable to contact server, please try again later',
  [HttpStatusCode.GATEWAY_TIMEOUT]: 'No response from server, please try again later',
  [HttpStatusCode.EXCEPTION_FAILED]: 'Requests will be synced back later when network comes',
};
