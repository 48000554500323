<div id="survey-logs-list" class="logs-list">

  <ng-container *ngIf="(stream$ | async) as activities">


    <!-- Surveys logs list -->
    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-between center"
      *ngIf="activities?.length" @fadeIn>
      <mat-card class="w-100 p-0 logs-wrapper">

        <div class="border-bottom logs-header px-3 py-2 mb-2 font-weight-bold" fxLayout fxLayoutAlign="space-between center">
          <p class="mb-0 header-title" fxFlex  translate>User</p>
          <p class="mb-0 header-title" translate>Activity</p>
        </div>

        <div fxLayout="column" *ngFor="let activity of activities; let last = last;">

          <div class="log py-2 px-3" fxLayout="row" fxLayoutAlign="space-between center"
            [ngClass]="{'border-bottom': !last}">

            <div class="profile" fxFlex fxLayout fxLayoutGap="4px" fxLayoutAlign="start center">
              <img [src]="activity?.activity?.createdBy?.profilePicUrl" class="rounded-circle">

              <p class="mb-0 author-name">{{ activity?.activity?.createdBy?.firstName }}</p>
              <p class="mb-0 date text-muted" [title]="activity?.activity?.LUT | date: 'dd MMM yyyy, hh:mm a'">
                {{ activity?.activity?.LUT | date: 'dd' }}
                {{ activity?.activity?.LUT | date: 'MMM' | translate }}
                {{ activity?.activity?.LUT | date: 'yyyy, hh:mm a' }}</p>
            </div>

            <p class="mb-0 type">
              <strong *ngIf="availableActivityTypes.SURVEY_SUBMITTED !== activity?.subDoctype">{{ activityTypeLabelMap[activity?.subDoctype] | translate}}</strong>
              <strong><a (click)="openSubmission(activity)" *ngIf="availableActivityTypes.SURVEY_SUBMITTED === activity?.subDoctype">{{activityTypeLabelMap[activity?.subDoctype] | translate}}</a></strong>
            </p>
          </div>
        </div>

      </mat-card>
    </div>
    <!-- / Surveys logs list -->

    <!-- No content if there no activity -->
    <div class="w-100 mt-4" *ngIf="(paginator$ | async) as paginator" fxLayout="row">
      <ng-container *ngIf="!activities?.length">
        <div class="no-content-center w-100" fxLayoutAlign="center center">
          <kl-no-content
            svgIcon="assets/svgs/updates.svg"
            [text]="'No activity performed yet!' | translate"
            iconSize="md"
            [progressText]="'Loading survey activities...' | translate"
            [progress]="paginator?.loading">
          </kl-no-content>
        </div>
      </ng-container>

      <!-- Load more -->
      <div class="w-100" *ngIf="activities?.length > 0">
        <kl-load-more
          [progress]="paginator?.loading"
          [hasMoreItems]="paginator?.hasItems"
          (clicked)="nextPage()">
        </kl-load-more>
      </div>
      <!--/ Load more -->
    </div>
    <!--/ No content if there no activities -->

  </ng-container>

</div>
