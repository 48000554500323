import { Component, Input, OnInit } from '@angular/core';
import { DEFAULT_PROFILE_PIC, fadeInAnimation } from '@kalgudi/core';
import { DA_STREAM_TYPE_MAP, PageDigitalAssistanceStream } from '@kalgudi/types';

import { DigitalAssistanceActivityTypeLabelMap } from '../../../../constants';

@Component({
  selector: 'kl-assistance-stream',
  templateUrl: './assistance-stream.component.html',
  styleUrls: ['./assistance-stream.component.scss'],
  animations: [ fadeInAnimation ],
})
export class AssistanceStreamComponent implements OnInit {

  @Input()
  stream: PageDigitalAssistanceStream[];

  readonly activityTypeLabelMap = DigitalAssistanceActivityTypeLabelMap;

  readonly daStreamTypes = DA_STREAM_TYPE_MAP;

  defaultProfilePic = DEFAULT_PROFILE_PIC;

  constructor( ) { }

  ngOnInit() { }
}
