import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { KalgudiImagePickerService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiAppService, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { SocialDataNormalizerService } from '@kalgudi/social';
import { ATTACHMENT_TYPE_MAP, TaskShareUpdate, TaskShareUpdateRequest } from '@kalgudi/types';

import { TaskShareUpdateForm } from '../../classes/task-share-update-form';
import { KalgudiTaskActivityStateService } from '../../services/task-activity-state.service';

@Component({
  selector: 'kl-task-share-update-form',
  templateUrl: './task-share-update-form.component.html',
  styleUrls: ['./task-share-update-form.component.scss']
})
export class TaskShareUpdateFormComponent extends TaskShareUpdateForm implements OnInit {

  @Input()
  projectId: string;

  @Input()
  taskId: string;

  @Input()
  digitalAssistance: boolean;

  @Input()
  isBulk: boolean;

  @Output()
  postCreated = new EventEmitter<TaskShareUpdate>();

  attachmentType = ATTACHMENT_TYPE_MAP;

  assistanceProfiles: any;

  rowsCount: number;

  constructor(
    @Inject(KL_NOTIFICATION) protected notifications: KalgudiNotification,
    protected imagePickerService: KalgudiImagePickerService,
    protected util: KalgudiUtilityService,
    protected socialDataNormalizer: SocialDataNormalizerService,
    protected kalgudiApp: KalgudiAppService,
    protected injector: Injector,
    private stateService: KalgudiTaskActivityStateService
  ) {

    super(
      notifications,
      imagePickerService,
      util,
      socialDataNormalizer,
      kalgudiApp,
      injector
    );
  }

  ngOnInit() {

    this.rowsCount = checkMobileDevice() ? 2 : 3;

    if (this.submissionDetails) {

      this.memberProfile = this.submissionDetails.createdBy;
    }
  }

  onDestroyed(): void {}

  /**
   * Event handler called after the post is shared successfully.
   */
  onPostCreated(req: TaskShareUpdateRequest, res: TaskShareUpdate): void {
    this.postCreated.emit(res);
    this.stateService.taskActivityUpdated();
  }

}
