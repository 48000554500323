import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { GeoLocationMarkerComponent } from '@kalgudi/third-party/google-geo-location';

@Component({
  selector: 'kl-satellite-advisoires-location',
  templateUrl: './satellite-advisoires-location.component.html',
  styleUrls: ['./satellite-advisoires-location.component.scss']
})
export class SatelliteAdvisoiresLocationComponent implements OnInit, AfterViewInit {

  @ViewChild(GeoLocationMarkerComponent) geoMarker: GeoLocationMarkerComponent;

  @Input()
  landDetails: any;

  @Input()
  showMap: boolean;

  @Input()
  disableLinkAction: boolean;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  showLandMap() {
    this.showMap = !this.showMap;
  }
}
