import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';

@Component({
  selector: 'kl-land-data-download-dialog',
  templateUrl: './land-data-download-dialog.component.html',
  styleUrls: ['./land-data-download-dialog.component.scss']
})
export class LandDataDownloadDialogComponent extends KalgudiDialog<LandDataDownloadDialogComponent> implements OnInit {

  landId: string;
  profileKey: string;

  constructor(
    protected dialogRef: MatDialogRef<LandDataDownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: KalgudiDialogConfig,
  ) {
    super(dialogRef);

    this.landId = data.data.landId;
    this.profileKey = data.data.profileKey;
  }

  ngOnInit() {
  }

  /**
   * No action performed, simply close the dialog
   */
  cancel(): void {
    const result: KalgudiDialogResult = {
      accepted: false,
      data: null
    };
    this.dialogRef.close(result);
  }

  /**
   * User accepted the dialog changes, pass the data to dialog owner
   * and close the dialog.
   */
  ok() {
    const result: KalgudiDialogResult = {
      accepted: true,
      data: {
      }
    };
    this.dialogRef.close(result);
  }

}
