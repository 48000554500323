<main class="px-2 pt-1 share-update-header home-stream-header">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="border-bottom pb-1">
    <div class="header-name" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div class="mr-1" fxLayoutAlign="center center">
        <mat-icon class="updates">format_quote</mat-icon>
      </div>
      <div class="header-title">
        <h3 class="mb-0" *ngIf="!latestActivity?.type" translate>Updates</h3>
        <h3 *ngIf="latestActivity?.type" class="mb-0">
          <span *ngIf="activityTypes[latestActivity?.type]">

            <a class="mr-1" (click)="viewProfile(latestActivity?.id)"
              *ngIf="userDetails?.profileKey !== latestActivity?.id">
              <b>{{ latestActivity?.name | secureMobile }}</b>
            </a>
            <b *ngIf="userDetails?.profileKey === latestActivity?.id" translate>You </b>
          </span>
          <span class="text-muted">{{activityTypes[latestActivity?.type] || 'Updates' | translate }}</span>
          <span *ngIf="activityTypes[latestActivity?.type] === 'liked'" class="mb-0 text-muted"
            [title]="latestActivity?.TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">&nbsp;{{ latestActivity?.TS | timeAgo }}
            <span translate>ago</span>
          </span>
          <span *ngIf="activityTypes[latestActivity?.type] === 'commented'" class="mb-0 text-muted"
            [title]="latestActivity?.TS | parseDate | date: 'dd MMMM yyyy, hh:mm:ss a'">&nbsp;{{ latestActivity?.TS | timeAgo }}
            <span translate>ago</span>
          </span>
        </h3>
      </div>
    </div>
    <div [fxHide.xs]="!showMatMenu" *ngIf="isLoggedIn" (click)="$event.stopPropagation(); $event.preventDefault()">
      <div fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="2px">
        <!-- Refresh advisory -->
        <button mat-icon-button class="header-menu" *ngIf="msgType === 'SATELLITE_ADVISORIES' && authorId === userDetails?.profileKey"
          (click)="refreshLatestAdvisory();$event.stopPropagation(); $event.preventDefault()">
          <mat-icon class="refresh-icon" title="Refresh Advisories">refresh</mat-icon>
        </button>

        <!-- three dot menu -->
        <button mat-icon-button fxLayoutAlign="end end" class="header-menu" [matMenuTriggerFor]="Menu">
          <mat-icon class="menu">
            more_horiz
          </mat-icon>
        </button>
      </div>
      <mat-menu #Menu="matMenu" class="mat-menu-sm" [xPosition]="'before'">
        <button mat-menu-item id="report-abuse" class="mb-0" *ngIf="entityId && (env.appId === 'KALGUDI_CORE' || env.appId === 'UBM')" (click)="reShare()" translate>Share from here</button>
        <button mat-menu-item id="report-abuse" class="mb-0" *ngIf="!scheduledUpdates" (click)="reportAbuse()" translate>Report abuse</button>
        <button mat-menu-item id="report-abuse" class="mb-0" *ngIf="msgType === 'AI_PREDICTIONS' && authorId === userDetails?.profileKey" (click)="sendSms()" translate>Send as sms</button>
        <!-- <button mat-menu-item id="report-abuse" class="mb-0" *ngIf="msgType === 'AI_PREDICTIONS'" (click)="shareOnWhatsapp()" translate>Share on whatsapp</button> -->
        <!-- <button mat-menu-item id="refresh" class="mb-0" *ngIf="msgType === 'SATELLITE_ADVISORIES' && authorId === userDetails?.profileKey"
          (click)="refreshLatestAdvisory()" translate>Refresh</button> -->
        <button mat-menu-item id="delete" class="mb-0" *ngIf="(authorId === userDetails?.profileKey && !scheduledUpdates) || memberRole === 'ADMIN'"
          (click)="deleteUpdate()" translate>Delete</button>
        <button mat-menu-item class="mb-0" *ngIf="scheduledUpdates" (click)="updatePost()" translate>Update</button>
      </mat-menu>
    </div>

    <div fxHide.gt-xs  *ngIf="isLoggedIn && !showMatMenu" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="2px">
      <!-- Refresh advisory -->
      <button mat-icon-button class="header-menu" *ngIf="msgType === 'SATELLITE_ADVISORIES' && authorId === userDetails?.profileKey"
        (click)="refreshLatestAdvisory();$event.stopPropagation(); $event.preventDefault()">
        <mat-icon class="refresh-icon" title="Refresh Advisories">refresh</mat-icon>
      </button>

      <!-- Mobile menu -->
      <button mat-icon-button class="header-menu" (click)="openBottomSheet(); $event.stopPropagation(); $event.preventDefault()">
        <mat-icon class="menu">
          more_horiz
        </mat-icon>
      </button>
      <!--/ Mobile menu -->
    </div>


  </div>
</main>
