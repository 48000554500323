import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input()
  placeHolder: string;

  @Input()
  searchForm: FormGroup;

  @Input()
  searchTypeList: UIMenu[];

  @Input()
  searchTypePlaceHolder = 'Search type';

  @Input()
  searchTypeWidth: string;

  @Input()
  prefixSearchType: boolean;

  @Input()
  prefixSearchButton: boolean;

  @Input()
  searchTypeLabel: string;

  @Input()
  searchTypeField: any;

  @Output()
  search = new EventEmitter<boolean>();

  @Output()
  searchTypeChanged = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  /**
   * Emits an event back to the parent
   */
  onSearch(event) {

    this.search.emit(event);
  }

  onTypeChange(event): void {

    this.searchTypeChanged.emit();
  }
}
