import { KalgudiInboxStream } from '@kalgudi/core';
import { Directive } from "@angular/core";

/**
 * Base class definition for all inbox streams in kalgudi pages.
 * Use the wrapper method `initPageStream()` to initialize the stream instead
 * of calling parent `KalgudiStream.initStream()` method.
 *
 * @author Pankaj Prakash
 *
 * @see KalgudiStream
 * @see KalgudiInboxStream
 */
@Directive()
export abstract class KalgudiPageInboxStream<T> extends KalgudiInboxStream<T> {

  /**
   * Unique page id for the current inbox stream
   */
  protected pageId: string;
  protected filtersForm: any;
  protected fromDate: string;
  protected toDate: string

  /**
   * Initializes page inbox stream. Wrapper method for `KalgudiStream.initStream()`.
   * Use this method to initialize a page stream
   *
   * @param pageId Current loaded page id
   */
  protected initPageStream(pageId: string, filtersForm?: any): void {

    // Assert valid page Id
    this.assertPageId(pageId);

    this.pageId = pageId;
    if (this.filtersForm && (this.filtersForm.value.fromDate && this.filtersForm.value.toDate)) {
      this.fromDate = filtersForm.fromDate;
      this.toDate = filtersForm.toDate;
    }

    // Initialize inbox stream
    this.initStream();
  }

  /**
   * Asserts a valid page id. Throws invalid page id error message if page id is
   * not a valid string and having length more than 1 characters.
   *
   * @returns `true` if page id is valid string having length greater than 1 character.
   */
  private assertPageId(pageId: string): boolean {

    if (typeof pageId !== 'string' || pageId.length <= 1) {
      throw new Error('Invalid page Id. Failed to initialize stream.');
    }

    return true;
  }

}
