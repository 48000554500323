import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  CommonSearchBarModule,
  KalgudiBulkMembersAdditionModule,
  KalgudiLoadMoreModule,
  KalgudiNoContentModule,
  KalgudiStatusDialogModule,
  KalgudiUserPickerModule,
  KalgudiUsersModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiProjectFullviewModule, KalgudiProjectManagerModule, KalgudiTasksModule } from '@kalgudi/project-manager';
import { KalgudiNgxFormlyModule } from '@kalgudi/third-party/ngx-formly';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AddPageTaskMembersComponent } from './components/add-page-task-members/add-page-task-members.component';
import { PageTaskFullviewPageComponent } from './components/page-task-fullview-page/page-task-fullview-page.component';
import { PageTaskListHeaderComponent } from './components/page-task-list-header/page-task-list-header.component';
import { PageTaskMembersDialogComponent } from './components/page-task-members-dialog/page-task-members-dialog.component';
import {
  PageTaskMembersMobileDialogComponent,
} from './components/page-task-members-mobile-dialog/page-task-members-mobile-dialog.component';
import { PageTaskSubmissionsComponent } from './components/page-task-submissions/page-task-submissions.component';
import { PageTasksListComponent } from './components/page-tasks-list/page-tasks-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    PageTasksListComponent,
    PageTaskMembersDialogComponent,
    PageTaskMembersMobileDialogComponent,
    AddPageTaskMembersComponent,
    PageTaskSubmissionsComponent,
    PageTaskFullviewPageComponent,
    PageTaskListHeaderComponent
  ],
  imports: [
    CommonModule,

    // Forms
    ReactiveFormsModule,
    AngularSvgIconModule,

    //Material modules
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatTooltipModule,

    FlexLayoutModule,

    // Formly
    FormlyMaterialModule,
    FormlyMatDatepickerModule,
    FormlyModule.forChild(),

    // Kalgudi modules
    KalgudiProjectManagerModule,
    KalgudiTasksModule,
    KalgudiProjectFullviewModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    CommonSearchBarModule,
    KalgudiButtonModule,
    KalgudiUserPickerModule,
    KalgudiUsersModule,
    KalgudiPipesModule,
    KalgudiNgxFormlyModule,
    KalgudiBulkMembersAdditionModule,
    KalgudiStatusDialogModule,

    TranslateModule
  ],
  exports: [
    PageTasksListComponent,
    PageTaskFullviewPageComponent,
    PageTaskListHeaderComponent
  ],
  entryComponents: [
    PageTaskMembersDialogComponent,
    PageTaskMembersMobileDialogComponent,
  ]
})
export class KalgudiPageTasksModule { }
