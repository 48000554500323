import { EventEmitter, Input, Output, Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { KalgudiDestroyable, SHARE_TYPES } from '@kalgudi/core';
import { LabelValueMap } from '@kalgudi/types';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class ActivityVisibility extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  shareVisibilityList: LabelValueMap[] = [];

  @Output()
  visibilityChanged = new EventEmitter<LabelValueMap>();

  @Output()
  selectSpecificMembers = new EventEmitter<boolean>();

  sharedVisibilities = SHARE_TYPES;

  disabled = false;
  visibilityForm = new FormControl(null);


  constructor() {
    super();

    this.visibilityForm.valueChanges
      .pipe(
        takeUntil(this.destroyed$)
      ).subscribe(val => this.updateFormControlValue(val))
  }

  onDestroyed(): void { }



  // --------------------------------------------------------
  // #region Getters and Setters
  // --------------------------------------------------------

  /**
   * Share form visibility field
   */
  get shareFormVisibilityValue(): string {
    return this.visibilityForm.value;
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * On change function binding reference for formControlName
   */
  onChange = (_: any) => {} ;

  /**
   * On touched function binding reference for formControlName
   */
  onTouched = () => {};

  /**
   * Writes a new value to the element.
   */
  writeValue(obj: any): void {
    this.visibilityForm.patchValue(obj);
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;

    this.disabled ? this.visibilityForm.disable() : this.visibilityForm.enable();
  }

  /**
   * Click event handler for select specific members click
   */
  pickPageMembers(): void {
    this.selectSpecificMembers.emit();
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------


  /**
   * Event handler for form control value update in the parent
   */
  private updateFormControlValue(item: any) {

    // Invoke ControlValueAccessor `onChange` to update formControl values
    this.onChange(item);
    this.onTouched();

    this.visibilityChanged.emit(item);
  }

}
