import { Input, Directive } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { KalgudiDestroyable } from '@kalgudi/core';

@Directive()
export abstract class KalgudiRatingFormControl extends KalgudiDestroyable implements ControlValueAccessor {

  @Input()
  disableActions: boolean;

  @Input() disabled: boolean;

  rating = new FormControl('');


  /**
   * On change function binding reference for formControlName
   */
  onChange: (value) => {};

  /**
   * On touched function binding reference for formControlName
   */
  onTouched: () => {};

  constructor() {

    super();

  }

  /**
   * Gets the latest changes and return back to the form control
   */
  notifyValueChange() {
    if (this.onChange) {
      this.onChange(this.rating.value);
    }
  }

  /**
   * Writes the value to the form control
   * @param val
   */
  writeValue(val: any): void {
    this.rating.patchValue(val);
  }

  /**
   * Register `onChange` function with our custom function.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Register `onTouched` function with our custom function.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Callback fired when the formControl toggles disabled state.
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Adding rating to the form control
   */
  addRating(ratingIndex) {

    if (this.disableActions) {
      return;
    }

    this.rating.patchValue(ratingIndex + 1);
    this.notifyValueChange();

  }

}
