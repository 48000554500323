<section class="selected-products">

  <mat-chip-list>
    <ng-container *ngFor="let products of selectedProducts; let i = index">
      <div class="product-chip position-relative d-inline-block">
        <mat-chip>
          <span class="product-title">
            {{ products?.productName_level2 }}
          </span>
        </mat-chip>

        <div class="hide d-none position-absolute">
          <button id="remove-product" mat-icon-button [matTooltip]="'Remove product' | translate" (click)="removeProduct(i)">
            <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer" [applyCss]="true"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16}">
            </svg-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-chip-list>


</section>
