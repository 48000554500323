import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KalgudiDialog } from '@kalgudi/common';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUsersMap } from '@kalgudi/types';

@Component({
  selector: 'kl-enable-offline-dialog',
  templateUrl: './enable-offline-dialog.component.html',
  styleUrls: ['./enable-offline-dialog.component.scss']
})
export class EnableOfflineDialogComponent extends KalgudiDialog<EnableOfflineDialogComponent> implements OnInit {

  selectedUsersMap: KalgudiUsersMap = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: KalgudiDialogConfig,
    protected dialogRef: MatDialogRef<EnableOfflineDialogComponent>,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogRef);
  }

  ngOnInit() {
  }

  ok(): void {
    const dialogRes: KalgudiDialogResult = {
      data: this.selectedUsersMap,
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: {},
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }
}
