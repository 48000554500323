import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { floatTopAnimation } from '@kalgudi/core';
import { KALGUDI_PAGE_RELATION_MAP, KalgudiChartItem, KalgudiSurveyDetails } from '@kalgudi/types';

import { SurveyActionService } from '../../services/survey-action.service';

@Component({
  selector: 'kl-survey-list-item',
  templateUrl: './survey-list-item.component.html',
  styleUrls: ['./survey-list-item.component.scss'],
  animations: [floatTopAnimation]
})
export class SurveyListItemComponent implements OnInit {

  @Input()
  surveyDetails: KalgudiSurveyDetails;

  @Output()
  cloneSurveyDetailsAccepted = new EventEmitter<boolean>();

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  readonly memberRole: { [key: string]: KalgudiChartItem } = {

    ADMIN: {
      title: 'Admin',
      bgColor: '#B2EBF2',
      textAlign: 'center'
    },

    MEMBER: {
      title: 'Member',
      bgColor: '#D1C4E9',
      textAlign: 'center'
    },

  } as const;

  constructor(private surveyActionService: SurveyActionService) { }

  ngOnInit() {
  }

  /**
   * Calls method to open clone survey dialog
   */
  addCloneSurveyDialog(surveyDetails: KalgudiSurveyDetails) {
    this.surveyActionService.showCloneSurveyDialog(surveyDetails)
    .subscribe(
      (res: any) => {
        this.cloneSurveyDetailsAccepted.emit(res.accepted);
      }
    );;
  }

}
