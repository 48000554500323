<!-- Selected Products -->
<section class="selected-products" *ngIf="showSelectedProducts">

  <mat-chip-list>
    <ng-container *ngFor="let products of selectedProducts; let i = index">
      <div class="product-chip position-relative d-inline-block">
        <mat-chip>
          <span class="product-title">
            {{ products?.productName_level2 }}
          </span>
        </mat-chip>

        <div class="hide d-none position-absolute">
          <button mat-icon-button [matTooltip]="'Remove product' | translate" (click)="removeProduct(i)">
            <svg-icon src="assets/svgs/close-red.svg" class="text-center menu-icons cursor-pointer" [applyCss]="true"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16}">
            </svg-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </mat-chip-list>


</section>
<!-- / Selected Products -->

<button *ngIf="!showChipButton" id="suggest-products-button" mat-button [ngClass]="{'mat-button': !buttonType, 'mat-icon-button': buttonType}"
  color="primary" (click)="onAddProductClicked()" [matTooltip]="'Suggest Products' | translate">
  <span class="product-share">
    <svg-icon [applyCss]="true" class="text-center attach-link" src="assets/svgs/share-product.svg" [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
  </span>
  <span *ngIf="!buttonType" class="ml-1" translate>{{buttonLabel}}</span>
</button>

<div id="store-products-btn" *ngIf="showChipButton">
  <div fxLayout fxLayoutAlign="center center" class="" [ngClass]="{'button-chip mb-2 rounded-pill border': showBorder}">
    <button class="" id="suggest-products-button" [ngClass]="{'rounded-pill': showBorder}" [matTooltip]="'Suggest Products' | translate" type="button" mat-button  (click)="onAddProductClicked()" [matTooltip]="'Suggest Products' | translate">
      <div class="button-wrapper" fxLayout fxLayoutGap="4px" fxLayoutAlign="center center">
        <svg-icon [applyCss]="true" class="text-center web-link" src="assets/svgs/share-product.svg" [svgStyle]="{ 'width.px': 14, 'height.px': 14 }"></svg-icon>
        <span class="button-label" translate>{{buttonLabel}}</span>
      </div>
    </button>
  </div>
</div>
