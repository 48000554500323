import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import {
  checkMobileDevice,
  KALGUDI_PROFILE_BUSINESS_TYPE_NAMES,
  KalgudiAppService,
  KalgudiDestroyable,
  KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES,
} from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import { AddBoardMembersDialogComponent } from '../components/add-board-members-dialog/add-board-members-dialog.component';
import {
  AddBoardMembersMobileDialogComponent,
} from '../components/add-board-members-mobile-dialog/add-board-members-mobile-dialog.component';

@Directive()
export abstract class KalgudiBoardMembers extends KalgudiDestroyable {

  editable: boolean;

  profileDetails: KalgudiUser;

  loggedInUserDetails: KalgudiUser;
  availableBusinessTypeNames = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;
  nativeBusinessTypeNames    = KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES;

  private profileService: KalgudiProfileService;
  private profileState: KalgudiProfileStateService;
  private dialogService: KalgudiDialogsService;
  private kalgudiAppService: KalgudiAppService;

  constructor(
    protected injector: Injector
  ) {

    super();

    this.profileService    = this.injector.get(KalgudiProfileService);
    this.profileState      = this.injector.get(KalgudiProfileStateService);
    this.dialogService     = this.injector.get(KalgudiDialogsService);
    this.kalgudiAppService = this.injector.get(KalgudiAppService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    // Initialize profile details
    this.profileState.data$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(profile => this.profileDetails = profile);

    this.loggedInUserDetails = this.kalgudiAppService.profileLocal;
  }

  /**
   * Opens view all members dialog
   */
  viewMembersDialog() {
    this.showBoardMembersDialog(true);
  }

  /**
   * Shows board of members dialog
   */
  showBoardMembersDialog(hideActions?: boolean) {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Board of members',
      data: {
        hideActions
      }
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '600px',
      maxWidth: '600px',
      panelClass: 'board-member',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: {
        hideActions
      }
    };

    return this.openBoardMembersDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }

  /**
   * Open board of members dialog
   */
  private openBoardMembersDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(AddBoardMembersMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(AddBoardMembersDialogComponent, dialogConfig, matDialogConfig);

    }


  }
}
