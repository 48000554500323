import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { IdValueMap, KalgudiSurveyQuestion } from '@kalgudi/types';
import { KalgudiSurveyAddQuestion } from '../../classes/kalgudi-add-question';
import { KalgudiEnvironmentConfig } from '@kalgudi/core/config';

@Component({
  selector: 'kl-survey-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss']
})
export class AddQuestionComponent extends KalgudiSurveyAddQuestion implements OnInit {

  @Output()
  questionAdded = new EventEmitter<KalgudiSurveyQuestion>();

  questionTypes: IdValueMap[] = [
    { value: 'Yes/No'         , id: this.availableQuestionTypes.YES_NO },
    { value: 'Text'           , id: this.availableQuestionTypes.TEXT },
    { value: 'Rating'         , id: this.availableQuestionTypes.RATING },
    { value: 'Date'           , id: this.availableQuestionTypes.DATE },
    { value: 'Multiple Choice', id: this.availableQuestionTypes.MULTIPLE_CHOICE},
    { value: 'Multiple select check-box', id: this.availableQuestionTypes.MULTIPLE_SELECT_CHECKBOX },
  ];

  constructor(
    protected injector: Injector
  ) {

    super(injector);
  }

  ngOnInit() {}

  onDestroyed(): void {}

  /**
   * Called after posting the question successfully
   */
  protected addQuestionHandler(res): void {

    this.questionAdded.emit(res);

  }

}
