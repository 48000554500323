import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult } from '@kalgudi/types';
import { Observable } from 'rxjs';

import { ChangePasswordDialogComponent } from '../components/change-password-dialog/change-password-dialog.component';
import {
  ChangePasswordMobileDialogComponent,
} from '../components/change-password-mobile-dialog/change-password-mobile-dialog.component';

@Injectable()
export class ChangePasswordDialogService {

  constructor(private dialogsService: KalgudiDialogsService) { }

  /**
   * Shows the change password web or mobile dialog
   */
  openChangePasswordDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {

      return this.dialogsService.openMobileDialog(ChangePasswordMobileDialogComponent, dialogConfig);
    } else {

      return this.dialogsService.openDialog(ChangePasswordDialogComponent, dialogConfig, matDialogConfig);
    }

  }
}
