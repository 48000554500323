import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { KalgudiMobileDialogRef } from '@kalgudi/common';
import { KalgudiHomeStreamStateService } from '@kalgudi/social';
import { NotificationsList } from '@kalgudi/types';

@Component({
  selector: 'kl-mobile-qa-form',
  templateUrl: './mobile-qa-form.component.html',
  styleUrls: ['./mobile-qa-form.component.scss']
})
export class MobileQaFormComponent implements OnInit {

  @Output()
  qaCreated = new EventEmitter<any>();

  constructor(
    private homeStreamState: KalgudiHomeStreamStateService,
    private dialogRef: KalgudiMobileDialogRef
  ) { }

  ngOnInit() {
  }

  onQaCreated(event: any): void {
    this.qaCreated.emit(event);
    this.pushItemToHomeStream(event);
    this.dialogRef.close();
  }

  private pushItemToHomeStream(val: NotificationsList): void {
    this.homeStreamState.unshiftToStream(val);
  }

}
