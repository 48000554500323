import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output } from '@angular/core';
import { KalgudiBottomSheetService } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { ACTIVITY_TYPES, KalgudiAppService } from '@kalgudi/core';
import { KL_ROUTE_CONFIG } from '@kalgudi/core/config';
import { KalgudiSocialRouteConfig } from '@kalgudi/social';
import { BottomSheetMenu, KalgudiUser, SocialPostLatestActivity, UIMenu } from '@kalgudi/types';

@Component({
  selector: 'kl-qa-header',
  templateUrl: './qa-header.component.html',
  styleUrls: ['./qa-header.component.scss']
})
export class QaHeaderComponent implements OnInit, OnChanges {

  @Input()
  latestActivity: SocialPostLatestActivity;

  @Input()
  userProfileKey: string;

  @Input()
  memberRole: string;

  @Input()
  authorId: string;

  @Input()
  showMatMenu: boolean = false;

  @Output()
  deletePost = new EventEmitter<boolean>();

  @Output()
  report = new EventEmitter<boolean>();

  listMenu: BottomSheetMenu;
  isLoggedIn: boolean;
  userDetails: KalgudiUser;

  activityTypes = ACTIVITY_TYPES;

  constructor(
    private bottomSheetService: KalgudiBottomSheetService,
    private kalgudiApp: KalgudiAppService,
    @Inject(KL_ROUTE_CONFIG) private appRouting: KalgudiSocialRouteConfig
  ) {

    this.userDetails = this.kalgudiApp.profileLocal;
    this.isLoggedIn = this.kalgudiApp.loggedIn;
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.listMenu = {
      title: '',
      styleType: 'list',
      menu: [
        {
          title: 'Report abuse',
          id: 'REPORT_ABUSE'
        },
        {
          title: 'Delete',
          id: 'DELETE',
          hide: this.userProfileKey !== this.authorId && this.memberRole !== 'ADMIN',
        },

      ]
    };
  }

  /**
   * Navigates to profile page
   * @param profileKey
   */
  viewProfile(profileKey: string): void {
    this.appRouting.toProfile({ profileKey });
  }

  /**
   * Emits an event to delete the update
   */
  deleteUpdate(): void {
    this.deletePost.emit();
  }

  /**
   * Emits an event to report on the post
   */
  reportAbuse(): void {
    this.report.emit();
  }


  openBottomSheet(): void {
    const ref = this.bottomSheetService.openBottomSheet(this.listMenu);

    ref.afterDismissed().subscribe(selectedMenu => {
      this.onMobileBottomSheetMenuSelected(selectedMenu);
    });
  }

  /**
   * Calls after selecting item from mobile bottom sheet menu
   */
  private onMobileBottomSheetMenuSelected(menu: UIMenu): void {

    if (!menu) {
      return;
    }

    switch (menu.id) {

      case 'REPORT_ABUSE':
        this.reportAbuse();
        break;

      case 'DELETE':
        this.deleteUpdate();
        break;

    }
  }

}
