<div class="reviews-comments">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <div>
      <img src="assets/icons/review.svg" width="45">
    </div>

    <div>
      <p class="m-0 title">Reviews and Commnents</p>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="center center" class="content" *ngIf="!reviewsComments">
    <p class="no-content mb-0">No reviews and comments available</p>
  </div>

  <section *ngIf="reviewsComments">
    <div class="overal-rating" fxLayout="column" >
      <span class="rating-info">Overal Rating</span>
      <div class="rating-tile" fxLayout="row" fxLayoutGap="25px" fxLayoutAlign="start center">
        <h1 class="font-weight-bold mb-0 count">{{reviewsComments?.avgRating | modernNumber}}.0</h1>

        <span fxLayout="column">
          <ngb-rating id="ngb-rating-star" class="rating-star star-font" [rate]="reviewsComments?.avgRating" [readonly]="true" [max]="5"></ngb-rating>
          <span class="text-secondary sub-title" fxLayout="row">
            <span>Based on </span>
            <span>&nbsp;{{ reviewsComments?.reviews?.length }}&nbsp;</span>
            <span>reviews</span>
          </span>
        </span>
      </div>
    </div>

    <div fxLayout="column" *ngFor="let reviews of reviewsComments?.reviews; let last = last;">
      <span class="author-name sub-title font-weight-bold mt-4">{{ reviews?.authorName }}</span>
      <ngb-rating id="ngb-rating-star" class="rating-star" [rate]="reviews?.rating" [readonly]="true" [max]="5"></ngb-rating>
      <p class="text-secondary sub-title mb-3 desc">{{ reviews?.description }}</p>
      <span class="text-secondary location-date" fxLayout="row">
        <span *ngIf="reviews?.location">{{ reviews?.location }}</span>
        <span *ngIf="reviews?.location && reviews?.CTS">&nbsp;/&nbsp;</span>
        <span>{{ reviews?.CTS | date: 'dd MMM yyyy'}}</span>
      </span>
      <span class="border-bottom mt-8" *ngIf="!last"></span>
    </div>
  </section>
</div>
