import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class ProfileLogsStateService {
  profileLogUpdated$: Observable<string>;

  private profileLogUpdateSubject$ = new BehaviorSubject<any>(null);

  constructor() {

    this.profileLogUpdated$ = this.profileLogUpdateSubject$.pipe(
      shareReplay(1),
    );
  }

  profileLogUpdated(): void {
    this.profileLogUpdateSubject$.next('');
  }
}
