<section id="product-details">
  <section>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px">
      <div fxLayoutAlign="center center" class="product-image">
        <div class="position-relative">
          <img  src="assets/images/product.jpg" >
          <div fxLayoutAlign="center center" class="product position-absolute">
            <img [src]="level3ProductDetails?.attachments?.attachments[0]?.url | prefixDomain">
          </div>
          <div *ngIf="level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value || level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit" class="circle p-2 rounded-circle text-center position-absolute" fxLayoutAlign="center center">
            <div fxLayout="column" fxLayoutGap="5px" class="product-quantity">
              <span class="text-muted mb-0">Qty</span>
              <span class="quantity">
                <span class="font-weight-bold mb-0">{{level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value}}&nbsp;</span>
                <span class="product-units">{{level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="5px" class="product-information w-100">
        <div fxFlex="70%" fxLayout="column" fxLayoutAlign="center start">
          <div class="product-name font-weight-bold">
            <span>{{level3ProductDetails?.productLevel3Title | titlecase}}
              <span *ngIf="level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value || level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit">
                <span> (</span>
                <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.value}}</span>
                <span> {{ level3ProductDetails?.smartElements[level3ProductDetails?.selectedSKU]?.unit}}</span>
                <span> )</span>
              </span>
            </span>
          </div>

          <div class="seller">
            <!-- <span class="text-muted">Seller : </span> -->
            <span class="seller-name">
              <span class="m-0">{{sellerDetails?.firstName}}</span>
              <!-- <span class="m-0" *ngIf="level2ProductDetails?.sellerType === 'RESELLER'">{{level2ProductDetails?.resellerDetails?.firstName}}</span>
              <span class="m-0" *ngIf="level2ProductDetails?.sellerType === 'MANUFACTURER'">{{level2ProductDetails?.manufacturerDetails?.firstName}}</span> -->
            </span>
          </div>

          <!--<div class="certified text-muted">
            <span>Certified by </span>
            <span>Spices Board</span>
            <span> | </span>
            <span>ML/REG/13684/2017</span>
          </div>-->

          <!-- <div class="location">
            <img src="assets/svg/location.svg" *ngIf="sellerDetails?.location">
            <span class="text-muted">
              <span class="m-0">{{sellerDetails?.location}}</span> -->
              <!-- <span class="m-0" *ngIf="level2ProductDetails?.sellerType === 'RESELLER'">{{level2ProductDetails?.resellerDetails?.location}}</span>
              <span class="m-0" *ngIf="level2ProductDetails?.sellerType === 'MANUFACTURER'">{{level2ProductDetails?.manufacturerDetails?.location}}</span> -->
            <!-- </span>
          </div> -->

          <div class="location">
            <img *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo" src="assets/svgs/location.svg">
            <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName">{{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.placeName}}</span>
            <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName">, {{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.districtName}}</span>
            <span *ngIf="sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName">, {{sellerProfileInfo?.lstOfUserBusinessDetailsInfo[0]?.locationTo?.stateName}}</span>
          </div>


          <mat-chip-list>
            <mat-chip class="chip cursor-pointer" (click)="openSideNav('ABOUT')">About</mat-chip>
            <mat-chip class="chip cursor-pointer" (click)="openSideNav('NUTRIENTS')" *ngIf="productLevel2Details?.nutrients?.length > 0">Nutrition Values</mat-chip>
            <mat-chip class="chip cursor-pointer" (click)="openSideNav('REVIEWS_COMMENTS')">Review and comments</mat-chip>
          </mat-chip-list>
        </div>
        <div class="w-100" fxFlex="30%" fxLayoutAlign="start center" fxLayoutAlign.xs="end center">
          <button mat-button class="cursor-pointer product-details-link" fxLayout="row" fxLayoutAlign="center center" (click)="openStore(productLevel2Details)">
            Complete product details
            <img class="back-arrow" src="assets/icons/back-arrow.png">
          </button>
        </div>
      </div>
    </div>
  </section>
</section>
