import { Injectable } from '@angular/core';
import { KalgudiStreamData } from '@kalgudi/core';
import { KalgudiCreateStockResponse, KalgudiStock } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { StockStateService } from './stock-state.service';
import { StocksApiService } from './stocks-api.service';

@Injectable()
export class StocksService {

  constructor(
    private api: StocksApiService,
    private stockStateService: StockStateService) { }

  /**
   * Calls api method to get stocks list
   * @param pageId
   * @param offset
   * @param limit
   */
  getStocks(offset: number, limit: number, pageId: string): Observable<KalgudiStreamData> {

    return this.api.fetchStream(offset, limit, pageId);
  }

  /**
   * Calls api method to create stock
   * @param pageId
   * @param payload
   */
  createStock(pageId: string, payload: KalgudiCreateStockResponse): Observable<KalgudiCreateStockResponse> {

    return this.api.createStock(pageId, payload);
  }

  /**
   * Update the stock
   */
  updateStock(stockId: string, payload: KalgudiCreateStockResponse): Observable<KalgudiCreateStockResponse> {

    return this.api.updateStock(stockId, payload);
  }


  /**
   * Make api call to delete the stock
   */
  deleteStock(stockId: string): Observable<KalgudiStock> {

    return this.api.deleteStock(stockId);
  }

  /**
   * Make api call to get the stock
   */
  getStock(stockId: string): Observable<KalgudiStock> {

    return this.api.getStock(stockId)
    .pipe(
      tap(stockDetails => this.stockStateService.updateStockDetails(stockDetails))
    );
  }
}
