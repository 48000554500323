<div class="update-list" fxLayout="column" *ngIf="(stream$ | async) as stream">

  <div class="w-100" *ngFor="let share of stream; let i = index">
    <div [@floatTop]="{ value: '', params: {delay: (i % maxPageLimit) * 50} }">
      <kl-share-update-tile
        [showLessData]="true"
        [overRideUpdatePost]="overRideUpdatePost"
        [scheduledUpdates]="true"
        [postDetails]="share"
        (postUpdated)="resetStream()">
      </kl-share-update-tile>
    </div>
  </div>


  <div fxFlex="100%" fxFlexAlign="center" *ngIf="paginator$ | async as paginator">

    <ng-container *ngIf="!stream || stream?.length <= 0">
      <kl-no-content
        matIcon="format_quote"
        [text]="'No scheduled updates' | translate"
        [progressText]="'Loading your scheduled updates...' | translate"
        [progress]="paginator?.loading">
      </kl-no-content>
    </ng-container>

    <!-- Load more -->
    <div class="m-3" *ngIf="paginator && stream?.length">
      <kl-load-more
        [noMoreText]="'No more scheduled updates' | translate"
        [progress]="paginator?.loading"
        [hasMoreItems]="paginator?.hasItems"
        (clicked)="nextPage()">
      </kl-load-more>
    </div>
    <!-- /Load more -->
  </div>
</div>
