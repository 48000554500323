<div class="projects h-100"
  [ngClass]="{ 'stats-sm': taskStatsDirection === 'horizontal','stats': taskStatsDirection === 'vertical' }">
  <div fxLayout fxLayoutAlign="space-between" class="h-100">

    <!-- Tasks and stats -->
    <div fxLayout="{{ taskStatsDirection === 'horizontal'? 'column':'row' }}"
      fxLayoutGap="{{ taskStatsDirection === 'horizontal'? '0px':'20px' }}" class="w-100">
      <div fxFlex="40%" fxLayout="{{ taskStatsDirection === 'horizontal'? 'r0w':'column' }}"
        fxLayoutAlign="{{ taskStatsDirection === 'horizontal'? 'start':'center' }} center">
        <div [@scaleOut]>
          <svg-icon [applyCss]="true" class="task-icon" src="assets/svgs/tasks.svg"
            *ngIf="taskStatsDirection === 'vertical'" [svgStyle]="{'width.px': '40', 'height.px': '50'}"></svg-icon>
          <svg-icon [applyCss]="true" class="task-icon mr-2" src="assets/svgs/tasks.svg"
            *ngIf="taskStatsDirection === 'horizontal'" [svgStyle]="{'width.px': '30', 'height.px': '30'}"></svg-icon>
        </div>

        <div>

      <!-- in order to use an array us this starts here -->

      <!-- <h4 class="title mb-0 text-center">{{ taskStatsDetails?.title }}</h4>
            <p class="count mb-0 text-secondary">{{ taskStatsDetails?.count | number}}</p> -->

      <!-- in order to use an array us this ends here -->

      <!-- tasks value starts here -->

          <h4 class="title mb-0 text-center" translate>Tasks</h4>
          <p class="count mb-0 text-secondary">{{ taskStatsDetails?.total | number}}</p>

      <!-- tasks value ends here -->
        </div>
      </div>

        <div
          [ngClass]="{ 'projects-border-bottom mb-2 mt-1': taskStatsDirection === 'horizontal', 'projects-border-right': taskStatsDirection === 'vertical' }">
        </div>

        <div *ngIf="taskStatsDetails?.total === 0" fxLayout="column" fxLayoutAlign="center center">
          <span class="text-muted mt-1" translate>
            No task added yet
          </span>
          <a class="mb-1" (click)="openCreateTaskDialog(); $event.stopPropagation()"><span translate>Add Tasks</span></a>
        </div>
      <div  *ngIf="taskStatsDetails?.total != 0" fxLayout="{{ taskStatsDirection === 'horizontal'? 'row':'column' }}"
      fxLayoutAlign="{{ taskStatsDirection === 'horizontal'? 'space-between':'center' }} center"
      [ngClass]="{ 'task-stats-sm': taskStatsDirection === 'horizontal', 'task-stats': taskStatsDirection === 'vertical' }">

      <!-- in order to use it in array use this starts here -->

      <!-- <kl-stats-list [statsList]="taskStatsDetails" [statsDirection]="taskStatsDirection"></kl-stats-list> -->

      <!-- in order to use it in array use this ends here -->

      <!-- completed and not started and pending values starts here  -->

        <ng-container *ngIf="taskStatsDetails?.total !== 0">
          <div class="mb-2 mr-2 w-100">
            <p class="mb-0 value">{{taskStatsDetails?.completed ? (taskStatsDetails?.completed | number) : '0'}} </p>
            <p class="mb-0 label" translate>Completed</p>
            <kl-stats showProgress="true"
              [progressValue]="taskStatsDetails?.completed"
              [maxProgressValue]="taskStatsDetails?.total">
            </kl-stats>
          </div>
          <div class="mb-2 mr-2 w-100">
            <p class="mb-0 value">{{ taskStatsDetails?.not_started ? (taskStatsDetails?.not_started | number) : '0'}} </p>
            <p class="mb-0 label" translate>Not Started</p>
            <kl-stats showProgress="true"
              [progressValue]="taskStatsDetails?.not_started"
              [maxProgressValue]="taskStatsDetails?.total">
            </kl-stats>
          </div>
          <div class="mb-2 mr-2 w-100">
            <p class="mb-0 value">{{ taskStatsDetails?.in_progress ? (taskStatsDetails?.in_progress | number) : '0'}} </p>
            <p class="mb-0 label" translate>In Progress</p>
            <kl-stats showProgress="true"
              [progressValue]="taskStatsDetails?.in_progress"
              [maxProgressValue]="taskStatsDetails?.total">
            </kl-stats>
          </div>
        </ng-container>

      <!-- completed and not started and pending values ends here  -->
      </div>
    </div>
      <!-- /Tasks and stats -->
    <!-- <div class="add-button ml-n5 mr-2" *ngIf="showMenu">
      <button mat-mini-fab color="primary">
        <mat-icon class="add text-white">add</mat-icon>
      </button>
    </div> -->
  </div>
</div>
