import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { KALGUDI_PROFILE_BUSINESS_TYPE_NAMES, KalgudiDestroyable, KalgudiError, KalgudiUtilityService, KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserCertificateDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import {
  KalgudiProfileCertificatesService,
} from '../../../components/sections/certifications/kalgudi-profile-certificates.service';
import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';

@Directive()
export abstract class AddCertificate extends KalgudiDestroyable {

  addCertificateForm: FormGroup;

  @Input()
  certificateId: string;

  @Input()
  certificateDetails: KalgudiUserCertificateDetails;

  @Output()
  closeDialog = new EventEmitter();

  progress: boolean;

  availableBusinessTypeNames = KALGUDI_PROFILE_BUSINESS_TYPE_NAMES;
  nativeBusinessTypeNames    = KALGUDI_PROFILE_NATIVE_BUSINESS_TYPE_NAMES;

  showOrganicCertificateFields: boolean;

  private fb: FormBuilder;
  private certificateService: KalgudiProfileCertificatesService;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private profileStateService: KalgudiProfileStateService;

  constructor(
    protected injector: Injector) {

    super();

    this.fb                  = this.injector.get(FormBuilder);
    this.certificateService  = this.injector.get(KalgudiProfileCertificatesService);
    this.util                = this.injector.get(KalgudiUtilityService);
    this.notification        = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.profileStateService = this.injector.get(KalgudiProfileStateService);

    this.addCertificateForm = this.newCertificateForm;

    this.profileStateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.showOrganicCertificateFields = (profile.lstOfUserBusinessDetailsInfo[0].businessTypeName === this.availableBusinessTypeNames.FPO || profile.lstOfUserBusinessDetailsInfo[0].nativeBusinessTypeName === this.nativeBusinessTypeNames.PRODUCER_ORG) ? true : false;
      });
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Form group for add farmer certificate form
   */
  private get newCertificateForm(): FormGroup {

    return this.fb.group({
      Flag: true,
      businessCertificationId: [''],
      certAuthority: ['', Validators.required],
      certName: ['', Validators.required],
      organicCertification: this.fb.group({
        farmers: [0],
        crops: [''],
        acres: [0]
      }),
      attachments: ['']
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Save or update certificate
   */
  saveOrUpdateCertificate() {
    this.certificateId ? this.updatesCertificate() : this.saveNewCertificate();
  }

  /**
   * Saves new certificates
   */
  saveNewCertificate() {

    const payload = this.preparePayload(this.addCertificateForm.value);

    this.progress = true;

    this.certificateService.saveNewCertificate(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)

      )
      .subscribe(
        res => this.onCertificateAdded(res),
        err => this.onCertificateFailed(err)
      );
  }

  /**
   * Updates certificates
   */
  updatesCertificate() {

    const payload = this.preparePayload(this.addCertificateForm.value);

    this.progress = true;

    this.certificateService.updateCertificate(payload)
      .pipe(
        // Subscribe to the stream only till the component is alive
        takeUntil(this.destroyed$),

        finalize(() => this.progress = false)
      )
      .subscribe(
        res => this.onCertificateUpdated(res),
        err => this.onCertificateUpdateError(err)
      );
  }

  /**
   * Getter for certificate imageUrl field
   */
  get attachmentsField(): any {
    return this.addCertificateForm.get('attachments');
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  /**
   * Certificate added successfully
   */
  protected onCertificateAdded(res: boolean) {
    this.closeDialog.emit();
    this.notification.showMessage('Certificate added');
  }

  /**
   * Failed to add certificate
   */
  protected onCertificateFailed(err: KalgudiError) {
    this.notification.showMessage('Failed to add certificate');
  }

  /**
   * Certificate updated successfully
   */
  protected onCertificateUpdated(res: boolean) {
    this.closeDialog.emit();
    this.notification.showMessage('Certificate updated');
  }

  /**
   * Failed to update certificate
   */
  protected onCertificateUpdateError(err: KalgudiError) {
    this.notification.showMessage('Failed to update certificate');
  }

  /**
   * Allowing specific characters based on label
   * @param event
   * @returns
   */
  onKeyPress(event: KeyboardEvent, label?: string): boolean {
    const x = event.key;
    const regexMap = {
      alphabet: /^[a-zA-Z]+$/,                        // alphabets only
      alphaNumeric: /^[0-9a-zA-Z]$/,                  // alphabets & numbers
      alphabetSpace: /^[a-zA-Z\s]$/,                  // alphabets & space
      alphanumericSpace: /^[0-9a-zA-Z\s]$/,           // alphabets, numbers & space
      variety: /^[0-9a-zA-Z\s]$/,                     // alphabets, numbers & space
      alphaNumericCharacters: /^[a-zA-Z0-9/-]+$/,     // alphabets, numbers & special character('-')
      alphaNumericLocation: /^[a-zA-Z0-9/ ,.\-]*$/,   // alphabets, numbers & special character('-', '.', ',')
      number: /^[0-9]+$/,                             // numbers only
      area: /^[0-9.]$/,
    };

    return label ? regexMap[label]?.test(x) : regexMap['number']?.test(x);
  }

  /**
   * Validate input field for max 4 or 6-digit numbers.
   * @param event
   * @param fieldValue
   * @param fieldName
   * @returns
   */
  validateNumericInputLength(event, fieldValue?: any, fieldName?: any) {
    const x = event.which || event.keyCode;
    const isNumeric = (x) => x >= 48 && x <= 57;

    switch(fieldName) {
      case 'employees':
      case 'villages':
        return isNumeric(x) && fieldValue.length < 4;

        case 'farmers':
        return isNumeric(x) && fieldValue.length < 7;

      case 'land':
        return (isNumeric(x) || x === 46) && fieldValue.length < 6;

      default:
        return false;
    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
