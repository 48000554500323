<div class="weather-details mx-2">

  <div fxLayout="column">

    <!-- Location -->
    <div class="mb-4" fxLayout="row" fxLayoutAlign="space-between center">
      <div>

        <h3 *ngIf="location?.placeName" id="searched-location-name"
          class="text-capitalize text-truncate text-white m-0">{{ location?.placeName }}, {{ location?.locationShort }}</h3>
        <h3 *ngIf="!location?.placeName" id="searched-location-name"
          class="text-capitalize text-truncate text-white m-0">{{ location?.locationLong }}</h3>
        <p>
          <span class="text-capitalize today-date text-truncate text-white m-0">{{ todaysWeather?.date | date: 'dd'}} </span>
          <span class="text-capitalize today-date text-truncate text-white m-0">{{ todaysWeather?.date | date: 'MMMM' | translate}}</span>
        </p>
      </div>

      <div *ngIf="showFavorite">
        <button id="favorite-button"
          mat-icon-button [matTooltip]="isFavoriteLocation ? 'Added in favorite locations' : 'Add to favorite locations' | translate" (click)="addToFavoriteLocations(location)">
          <mat-icon [ngClass]="{'text-danger': isFavoriteLocation, 'text-white': !isFavoriteLocation}">favorite</mat-icon>
        </button>
      </div>
    </div>
    <!-- /Location -->

    <!-- Weather updates -->
    <div fxLayout="row"  fxLayout.xs="column" fxLayoutGap="15px" class="mb-4">

      <!-- Todays weather -->
      <div fxFlex="30%" fxFlex.xs="100%" class="todays-weather p-3">
        <div>
          <p class="time">
          <!-- <span translate>Today </span>
          <span>{{ weatherTime[todaysWeather?.hourly[0]?.time] }}</span> -->
          </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start center">
          <div>
            <svg-icon [applyCss]="true" class="text-center current-weather todays-weather-icon"
              [src]="weatherCodes[todaysWeather?.hourly[0]?.weatherCode] || 'assets/icons/weather/animated/rainy-7.svg'"
              [svgStyle]="{ 'width.px': 120 , 'height.px': 115 }"></svg-icon>
          </div>
          <div>
            <h2 class="text-white">{{ todaysWeather?.hourly[0]?.tempC }}°C</h2>
            <p class="m-0 weather-type">{{ todaysWeather?.hourly[0]?.weatherDesc[0]?.value }}</p>
          </div>
        </div>
      </div>
      <!-- /Todays weather -->

      <!-- Stats -->
      <div fxFlex="calc(70% - 15px)">
        <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.xs="space-between center">
          <div fxFlex="{{ i > 4 ? '25%' : '20%'}}" fxFlex.sm="33%"
            fxFlex.xs="30%" fxLayoutAlign="center center" fxLayoutAlign.xs="start center" fxLayoutGap.xs="10px"
            [ngClass]="{'weather-mobile': i > 3}"
            *ngFor="let update of weatherUpdates; let i = index" fxLayout="row">
            <div fxHide.xs>
              <svg-icon *ngIf="update?.icon" [applyCss]="true" class="text-center weather-icons" [src]="update?.icon"
                [svgStyle]="{ 'width.px': 80 , 'height.px': 80 }"></svg-icon>
              <svg-icon *ngIf="update?.staticIcon" [applyCss]="true" class="text-center mr-3" [src]="update?.staticIcon"
                [svgStyle]="{ 'width.px': 38 , 'height.px': 38 }"></svg-icon>
              <svg-icon *ngIf="update?.weatherIcon" [applyCss]="true" class="text-center wind-icon mr-3" [src]="update?.weatherIcon"
                [svgStyle]="{ 'width.px': 48 , 'height.px': 48 }"></svg-icon>
            </div>
            <div fxHide.gt-xs>
              <svg-icon *ngIf="update?.icon" [applyCss]="true" class="text-center weather-icons" [src]="update?.icon"
                [svgStyle]="{ 'width.px': update?.iconSize , 'height.px': update?.iconSize }"
                [ngStyle]="{'margin-left': update?.marginLeft, 'margin-right': update?.marginRight}"></svg-icon>
              <svg-icon *ngIf="update?.staticIcon" [applyCss]="true" class="text-center" [src]="update?.staticIcon"
                [svgStyle]="{ 'width.px': update?.iconSize , 'height.px': update?.iconSize }"
                [ngStyle]="{'margin-left': update?.marginLeft, 'margin-right': update?.marginRight}"></svg-icon>
              <svg-icon *ngIf="update?.weatherIcon" [applyCss]="true" class="text-center wind-icon" [src]="update?.weatherIcon"
                [svgStyle]="{ 'width.px': update?.iconSize , 'height.px': update?.iconSize }"
                [ngStyle]="{'margin-left': update?.marginLeft, 'margin-right': update?.marginRight}"></svg-icon>
            </div>
            <div class="ml-n1" [ngClass]="{'mobile-text' : update?.icon}" fxFlex.xs="calc(80% - 10px)">
              <p class="title text-capitalize" translate>{{ update?.title }}</p>
              <p class="value">{{ update?.value }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- /Stats -->
    </div>
    <!-- /weather updates -->

    <!-- Day forecast -->
    <h4 class="forecast mb-3" translate>Next 7 days forecast</h4>
    <div fxLayout="row wrap" fxLayoutGap.xs="10px" id="forecast-wrapper">
      <!-- <div fxFlex="calc(50% - 20px)" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
        fxLayout fxLayout.xs="column" fxLayoutGap.xs="8px" class="w-100 mb-4 mr-3 separator-bottom forecase-details"
        ngClass.lg="separator"
        fxLayoutAlign="start center" fxLayoutAlign.xs="start start" *ngFor="let weather of weatherDetails | slice: 1">
        <div fxLayout="row" fxLayoutAlign.xs="space-between center">
          <div fxLayout="row" [matTooltip]="weather?.hourly[0]?.weatherDesc[0]?.value" fxLayoutAlign="center">
            <div class="position-relative shades rounded-circle">
              <div class="position-absolute week-weather">
                <svg-icon [applyCss]="true" class="text-center weather-icons"
                [src]="weatherCodes[weather?.hourly[0]?.weatherCode] || 'assets/icons/weather/animated/rainy-7.svg'"
                [svgStyle]="{ 'width.px': 65, 'height.px': 90 }"></svg-icon>
              </div>
            </div>
            <div fxLayoutAlign="center" fxLayout="column">
              <p class="degrees">{{ weather?.hourly[0]?.tempC }}°C</p>
              <p class="days">{{ weather?.date | date: 'dd MMMM'}}</p>
            </div>
          </div>

          <div fxLayout="row" class="ml-3">
            <svg-icon [applyCss]="true" class="text-center wind-icon mr-3 mt-1" src="assets/icons/weather/wind.svg"
              [svgStyle]="{ 'width.px': 30 , 'height.px': 30 }"></svg-icon>
            <div fxLayout="column">
              <p class="title">Wind flow</p>
              <span class="value">{{ weather?.hourly[0]?.windspeedKmph }} kmph</span>
            </div>
          </div>
        </div>

        <div class="h-100" fxLayout="row" fxLayoutAlign.xs="space-between center">
          <div fxLayout="row" class="ml-3">
            <svg-icon [applyCss]="true" class="text-center wind-icon mr-3 mt-1" src="assets/icons/weather/humidity.svg"
              [svgStyle]="{ 'width.px': 30 , 'height.px': 30 }"></svg-icon>
            <div fxLayout="column">
              <p class="title">Humidity</p>
              <span class="value">{{ weather?.hourly[0]?.humidity }}%</span>
            </div>
          </div>
          <div fxLayout="row" class="ml-3">
            <svg-icon [applyCss]="true" class="text-center wind-icon mr-3 mt-1" src="assets/icons/weather/cloud.svg"
              [svgStyle]="{ 'width.px': 30 , 'height.px': 30 }"></svg-icon>
            <div fxLayout="column">
              <p class="title">Cloud</p>
              <span class="value">{{ weather?.hourly[0]?.cloudcover }}%</span>
            </div>
          </div>
        </div>

      </div> -->

      <div fxFlex="calc(10% - 20px)" fxFlex.xs="calc(30% - 10px)" fxFlex.sm="calc(30% - 10px)" fxFlex.md="calc(30% - 10px)"
        fxLayout fxLayout.xs="column" fxLayoutGap.xs="8px" class="w-100 mb-4 mr-3 separator forecase-details"
        fxLayoutAlign="start center" fxLayoutAlign.xs="start start" *ngFor="let weather of weatherDetails">
        <div fxLayout="row" fxLayoutAlign.xs="space-between center">
          <div fxLayout="row" [matTooltip]="weather?.hourly[0]?.weatherDesc[0]?.value" fxLayoutAlign="center">
            <div class="position-relative shades rounded-circle">
              <div class="position-absolute week-weather">
                <svg-icon [applyCss]="true" class="text-center weather-icons" fxHide.xs
                [src]="weatherCodes[weather?.hourly[0]?.weatherCode] || 'assets/icons/weather/animated/rainy-7.svg'"
                [svgStyle]="{ 'width.px': 65, 'height.px': 90 }"></svg-icon>
                <svg-icon [applyCss]="true" class="text-center weather-icons" fxHide.gt-xs
                [src]="weatherCodes[weather?.hourly[0]?.weatherCode] || 'assets/icons/weather/animated/rainy-7.svg'"
                [svgStyle]="{ 'width.px': 56, 'height.px': 73 }"></svg-icon>
              </div>
            </div>
            <div fxLayoutAlign="center" fxLayout="column">
              <p class="degrees">{{ weather?.hourly[0]?.tempC }}°C</p>
              <p class="days">
                <span class="mr-1">{{ weather?.date | date: 'dd'}}</span>
                <span translate>{{ weather?.date | date: 'MMMM'}}</span>
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!-- /Day forecast -->

  </div>

</div>
