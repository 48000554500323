import { Component, Injector, OnInit } from '@angular/core';
import { bottomFloatingCardAnimation } from '@kalgudi/core';

import { AssistProfileUpdate } from '../../classes/assist-profile-update';


@Component({
  selector: 'kl-digital-assist-profile-update',
  templateUrl: './digital-assist-profile-update.component.html',
  styleUrls: ['./digital-assist-profile-update.component.scss'],
  animations: [bottomFloatingCardAnimation]
})
export class DigitalAssistProfileUpdateComponent extends AssistProfileUpdate implements OnInit {

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit() {
  }

  onDestroyed(): void {}

}
