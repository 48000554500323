<!-- Profile about org edit -->
<!-- <kl-profile-about-org-edit *ngIf="!editFlag">
</kl-profile-about-org-edit> -->
<div class="py-3" *ngIf="!editFlag">
  <kl-profile-basic-details-edit
    (closeDialog)="closeEditForm()"
    (openTermsConditionDialog)="showTermsAndConditionsDialog($event)">
  </kl-profile-basic-details-edit>

</div>
<!-- / Profile about org edit -->

<!-- Profile about org view -->
<kl-profile-about-org-view [user]="user"
  [editable]="editable" *ngIf="editFlag">
</kl-profile-about-org-view>
<!-- Profile about org view -->
