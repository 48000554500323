import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiDialogsService } from '@kalgudi/common';
import { checkMobileDevice, KalgudiDestroyable } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUser } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { KalgudiProfileStateService } from '../../../services/kalgudi-profile-state.service';
import { KalgudiProfileService } from '../../../services/kalgudi-profile.service';
import {
  UpdateFpoBankingDetailsDialogComponent,
} from '../components/fpo-banking-details/update-fpo-banking-details-dialog/update-fpo-banking-details-dialog.component';
import {
  UpdateFpoBankingDetailsMobileDialogComponent,
} from '../components/fpo-banking-details/update-fpo-banking-details-mobile-dialog/update-fpo-banking-details-mobile-dialog.component';

@Directive()
export abstract class KalgudiFpoBankingDetails extends KalgudiDestroyable {

  editable: boolean;

  profileDetails: KalgudiUser;

  private dialogService: KalgudiDialogsService;
  private profileService: KalgudiProfileService;
  private profileStateService: KalgudiProfileStateService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.profileService      = this.injector.get(KalgudiProfileService);
    this.dialogService       = this.injector.get(KalgudiDialogsService);
    this.profileStateService = this.injector.get(KalgudiProfileStateService);

    this.profileService.editable$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(
        res => this.editable = res
      );

    // Initialize profile details
    this.profileStateService.data$
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(profile => {
        this.profileDetails = profile;
      });
  }


  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Shows fpo banking details dialog
   */
  showUpdateFpoBankingDetailsDialog() {

    // Input dialog UI configuration
    const dialogDetails: KalgudiDialogConfig = {
      title: 'Fpo banking details',
      data: {}
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '500px',
      maxWidth: '500px',
      panelClass: 'fpo-banking-details',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
      data: { }
    };

    return this.openUpdateFpoBankingDetailsDialog(dialogDetails, dialogConfig)
      .pipe(

        filter(r => r.accepted),

        map(r => r.accepted),

      );
  }



  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   * Open fpo banking details dialog
   */
  private openUpdateFpoBankingDetailsDialog(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {

    if (checkMobileDevice()) {
      return this.dialogService.openMobileDialog(UpdateFpoBankingDetailsMobileDialogComponent, dialogConfig);
    } else {
      return this.dialogService.openDialog(UpdateFpoBankingDetailsDialogComponent, dialogConfig, matDialogConfig);

    }

  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
