import { Injectable } from '@angular/core';
import { AgGridFormatter } from '@kalgudi/types';

@Injectable()
export class SpecialCharacterFormatter implements AgGridFormatter {

  constructor( ) { }

  format(value: any, format: string) {
    return '';
  }
}
