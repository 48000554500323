<main id="task-activity-updates">
  <div class="task-updates bg-white p-2" fxLayout="column" fxLayoutGap="7px">

    <!-- Contributor updated and Date -->
    <div fxLayoutAlign="space-between center" fxLayoutGap="10px">
      <span *ngIf="taskActivityUpdates?.activity?.createdBy?.firstName" class="text-truncate contributor-name" fxFlex="calc(100% - 70px)">
        {{ taskActivityUpdates?.activity?.createdBy?.firstName | titlecase }} updated
      </span>

      <span class="date" fxFlex="60px" fxLayout="row">
        {{ taskActivityUpdates?.LUT | date: 'dd MMM yy' }}
      </span>
    </div>
    <!--/ Contributor updated and Date -->

    <!-- Activity update Image -->
    <div>
      <span class="activity">Activity update</span>
      <div (click)="openFullview()" class="position-relative mt-1" *ngIf="taskActivityUpdates?.activity?.activityDetails?.lstOfAttachments && taskActivityUpdates?.activity?.activityDetails?.lstOfAttachments?.length">
        <img [src]="taskActivityUpdates?.activity?.activityDetails?.lstOfAttachments[0]?.url | prefixDomain" class="w-100 updated-image" height="100px" alt="profile-banner">

        <!-- Number of images -->
        <div *ngIf="taskActivityUpdates?.activity?.activityDetails?.lstOfAttachments?.length > 1" class="position-absolute img-list text-white px-2 py-1" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
          <p class="mb-0 img-count font-weight-bold">{{ taskActivityUpdates?.activity?.activityDetails?.lstOfAttachments?.length - 1 }}</p>
          <p class="mb-0 more" translate>More</p>
        </div>
        <!-- Number of images -->
      </div>
    </div>
    <!--/ Activity update Image -->

    <!-- Description -->
    <p class="description-data overflow-hidden m-0"
      klHideContent
      [lineHeight]="18"
      [visibleLine]="3">{{ taskActivityUpdates?.activity?.activityDetails?.text }}
    </p>
    <!--/ Description -->
  </div>
</main>
