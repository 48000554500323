<div fxLayout="column" fxLayoutGap="20px" class="project-stats w-100">

  <!--Project stats -->
  <mat-card (click)="openPageProjects()" class="cursor-pointer">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <svg-icon class="projects-icon"
          src="assets/svgs/projects.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"></svg-icon>
      </div>
      <h2 class="m-0" translate>Projects</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="projectColorScheme"
        [results]="projectStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 projects-total">{{ projectsTotalCount | number}}</p>
          <p class="mb-0 label" translate>Projects</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="projectsTotalCount === null"></kl-fake-it-block>
  </mat-card>

  <!-- /Project stats -->

  <!-- Task stats -->

  <mat-card (click)="openPageTasks()" class="cursor-pointer">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">
      <div [@scaleOut]>
        <svg-icon class="tasks-icon"
          src="assets/svgs/tasks.svg" [svgStyle]="{'width.px': '35', 'height.px': '35'}" [applyCss]="true"></svg-icon>
      </div>
      <h2 class="m-0" translate>Tasks</h2>
    </div>

    <div fxLayout="row" class="w-100 position-relative" fxLayoutAlign="center center">
      <ngx-charts-pie-chart
        [view]="view"
        [scheme]="taskColorScheme"
        [results]="projectStats"
        [gradient]="gradient"
        [legend]="showLegend"
        [legendPosition]="legendPosition"
        [labels]="showLabels"
        [doughnut]="isDoughnut">
      </ngx-charts-pie-chart>

      <div class="position-absolute">
        <div fxLayout="column" fxLayoutAlign="center center" class="pie-chart">
          <p class="mb-0 tasks-total">{{ tasksTotalCount | number}}</p>
          <p class="mb-0 label" translate>Tasks</p>
        </div>
      </div>
    </div>

    <kl-fake-it-block *ngIf="tasksTotalCount === null"></kl-fake-it-block>
  </mat-card>

  <!-- /Task stats -->

</div>
