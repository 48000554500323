import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { KalgudiLoadMoreModule, KalgudiNoContentModule, KalgudiUserPickerModule, KalgudiUsersModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';

import { SurveyAdminsStreamComponent } from './components/survey-admins-stream/survey-admins-stream.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    SurveyAdminsStreamComponent
  ],
  imports: [
    CommonModule,

    FlexLayoutModule,

    // Kalgudi modules
    KalgudiButtonModule,
    KalgudiNoContentModule,
    KalgudiLoadMoreModule,
    KalgudiUsersModule,
    KalgudiUserPickerModule,
    TranslateModule
  ],
  exports: [
    SurveyAdminsStreamComponent,
  ]
})
export class KalgudiSurveyAdminsModule { }
