<div id="kl-task-list-calendar">
  <div class="mb-4" *ngIf="events.length">
    <div fxLayout fxLayoutAlign="space-between center">
      <div>
        <h3 translate>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en'}}</h3>
      </div>
      <div>
        <div class="btn-group">
          <div
            class="btn btn-primary"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" translate>
            Previous
          </div>
          <div
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate" translate>
            Today
          </div>
          <div
            class="btn btn-primary"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()" translate>
            Next
          </div>
        </div>
      </div>
    </div>
    <br />
    <div>
      <mwl-calendar-month-view
        [viewDate]="viewDate"
        [events]="events"
        [refresh]="refresh"
        [activeDayIsOpen]="activeDayIsOpen"
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="openTaskFullview($event?.event)"
        (eventTimesChanged)="eventTimesChanged($event)">
      </mwl-calendar-month-view>
    </div>
  </div>
  <div *ngIf="progress">
    <kl-no-content
      svgIcon= "assets/svgs/tasks.svg"
      text= "{{loggedInUserTasks ? 'You did not participate in any tasks yet!' : 'Did not create any tasks for this project yet!'}}"
      progressText="Loading your tasks..."
      actionLink="{{loggedInUserTasks ? '' : 'Create your first task in this project'}}"
      [progress]="progress"
      >
    </kl-no-content>
  </div>

</div>
