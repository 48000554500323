import { Injectable } from "@angular/core";
import { KalgudiRobocall } from "@kalgudi/types";
import { Observable, Subject } from "rxjs";

@Injectable()
export class KalgudiRobocallStreamStateService {
  private newStreamItemSubject = new Subject<KalgudiRobocall>();

  constructor() {}

  get newStreamItem$(): Observable<KalgudiRobocall> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: KalgudiRobocall): void {
    this.newStreamItemSubject.next(item);
  }
}
