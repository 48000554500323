import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiError,
  ApiMyConnectsResponse,
  HttpStatusCode,
  KalgudiConnectsSearchResults,
  KalgudiUserSearchResults,
  KalgudiUsersService,
  KalgudiUtilityService,
  REST_API_ERROR_MESSAGES,
} from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiProfileSearchResponse, ApiResponseCommon, ApiResponseCommonV1, KalgudiUser, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Defines Api for Kalgudi user search services and their handling.
 *
 * @author Pankaj Prakash
 */
@Injectable()
export class KalgudiUsersSearchApiService {

  /**
   * `v1/search/profile`
   */
  private readonly API_SEARCH_USERS = `${this.env.restBaseUrl}/search/profile`;

  /**
   * `v2/profile/search`
   */
  private readonly API_SEARCH_USERS_V2 = `${this.env.restBaseUrlV2}/profiles/search`;

  /**
   * `v2/profiles/search/sellers`
   */
  private readonly API_SEARCH_SELLERS = `${this.env.restBaseUrlV2}/profiles/search/sellers`;

  /**
   * `v1/network/connects/networkSearch`
   */
  private readonly API_SEARCH_CONNECTS = `${this.env.restBaseUrl}/network/connects/networkSearch`;

  /**
   * `v1/network/connects/:offset/:limit`
   */
  private readonly API_MY_CONNECTS = `${this.env.restBaseUrl}/network/connects/:offset/:limit`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
    private usersService: KalgudiUsersService
  ) { }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Makes an API call to search list of sellers with the specified
   * search keyword.
   */
  searchKalgudiSellers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams: PartialData = {},
  ): Observable<KalgudiUserSearchResults> {

    const params = {
      q: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      ...extraParams
    };

    return this.httpClient.get<ApiProfileSearchResponse>(this.API_SEARCH_SELLERS, { params })
      .pipe(

        // Check for API errors
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi user search result type
        // map(r => this.mapSearchUsersResponse(r)),

        map(res => ({ count: res.data.count, items: res.data.results }))
      );
  }


  /**
   * Makes an API call to search list of all users with the specified
   * search keyword.
   */
  searchKalgudiUsers(
    searchKeyword: string,
    offset: number,
    limit: number,
    extraParams: PartialData = {},
    groupId?: string,
  ): Observable<KalgudiUserSearchResults> {

    const params = {
      q: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
      // source: this.env.appId,
      ...extraParams,
      groupId: groupId,
      searchForPages: true,
    };

    return this.httpClient.get<ApiProfileSearchResponse>(this.API_SEARCH_USERS_V2, { params })
      .pipe(

        // Check for API errors
        map(r => this.util.apiErrorHandler(r)),

        // Map API response to Kalgudi user search result type
        // map(r => this.mapSearchUsersResponse(r)),

        map(res => ({ count: res.data.count, items: res.data.results }))
      );
  }

  /**
   * Makes an API call to search list of all users with the specified
   * search keyword.
   */
  searchMyKalgudiConnects(searchKeyword: string, offset: number, limit: number): Observable<KalgudiUserSearchResults> {

    const params = {
      keyword: searchKeyword,
      offset: offset.toString(),
      limit: limit.toString(),
    };

    return this.httpClient.get<ApiResponseCommonV1>(this.API_SEARCH_CONNECTS, { params })
      .pipe(

        // Check for API errors
        map(r => this.searchConnectsHandler(r)),

        // Map API response to Kalgudi connects search result type
        map(r => this.mapSearchConnectsResponse(r))
      );
  }

  /**
   * Fetches list of all my kalgudi connects from API based on offset and limit.
   */
  fetchMyKalgudiConnects(offset: number, limit: number): Observable<KalgudiConnectsSearchResults> {

    // My connects url
    const url = this.API_MY_CONNECTS
      .replace(':offset', offset.toString())
      .replace(':limit', limit.toString());

    return this.httpClient.get<ApiResponseCommonV1>(url)
      .pipe(

        // Check for API errors
        map(r => this.myKalgudiConnectsHandler(r)),

        // Map API response to Kalgudi connects search result type
        map(r => this.mapMyKalgudiConnectsResponse(r))
      );
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------

  /**
   * Maps API search result to  kalgudi user search result type.
   *
   * @param res Api response
   */
  private mapSearchUsersResponse(res: ApiResponseCommon): KalgudiUserSearchResults {

    // List of search results
    const users: KalgudiUser[] = [];

    // Map list of Json string array to array of kalgudi users type
    (res.data as Array<string>).forEach(u => users.push(
      this.util.toJson<KalgudiUser>(u)
    ));

    return {
      count: res.numFound,
      items: users
    };
  }

  /**
   * Search my kalgudi connects Api response handler. Checks for any API errors and throws
   * errors from service if there are any errors.
   */
  private searchConnectsHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Check for API errors, throw if API didn't responded correctly
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps kalgudi connects search API response to kalgudi user search result type.
   *
   * @param res Api response
   */
  private mapSearchConnectsResponse(res: ApiResponseCommonV1): KalgudiUserSearchResults {

    return {
      count: res.numFound,
      items: this.util.toJson<KalgudiUser[]>(res.data),
    };
  }

  /**
   * My kalgudi connects Api response handler. Checks for any API errors and throws
   * errors from service if there are any errors.
   */
  private myKalgudiConnectsHandler(res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // Common error messages
    const errorMessages = {
      ...REST_API_ERROR_MESSAGES,
    };

    // Check for API errors, throw if API didn't responded correctly
    if (res.code !== HttpStatusCode.OK) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    return res;
  }

  /**
   * Maps my kalgudi connects API response to kalgudi user search result type.
   *
   * @param res Api response
   */
  private mapMyKalgudiConnectsResponse(res: ApiResponseCommonV1): KalgudiConnectsSearchResults {

    const apiRes = this.util.toJson<ApiMyConnectsResponse>(res.data);

    return {
      count: apiRes.total,
      items: apiRes.networkTOList,
    };
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
