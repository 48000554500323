<main id="fpo-over-view-form">
  <section>
    <form autocomplete="off" [formGroup]="fpoOverViewForm">
      <div formGroupName="fpoBasicDetails">
      <div fxLayout="column" fxLayoutGap="3px">
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>About yourself</mat-label>
            <textarea matInput required [placeholder]="'Write about yourself' | translate" formControlName="aboutMe" maxlength="250"></textarea>
          </mat-form-field>
        </div>

        <!-- Main activity -->
        <div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>Main activity</mat-label>
            <textarea matInput [placeholder]="'Write about main activity' | translate" formControlName="mainActivity" maxlength="250"></textarea>
          </mat-form-field>
        </div>
        <!--/ Main activity -->

        <!-- Assets -->
        <div *ngIf="(env.appId !== 'SAM_FPO')">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label translate>Assets</mat-label>
            <textarea matInput [placeholder]="'Write about assets' | translate" formControlName="assets" maxlength="250"></textarea>
          </mat-form-field>
        </div>
        <!--/ Assets -->

        <!-- Total Turnover -->
        <div class="turnover" *ngIf="(env.appId !== 'SAM_FPO')">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Turnover</mat-label>
            <input matInput formControlName="totalTurnOver" maxlength="12" (keypress)="onKeyPress($event)">
          </mat-form-field>
        </div>
        <!--/ Total Turnover -->

        <!-- Date of Board meeting -->
        <div *ngIf="(env.appId !== 'SAM_FPO')">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>{{ env?.appId === 'SHG_NET_APP' ? 'Monthly meeting' : 'Board meeting' }}</mat-label>
            <input matInput formControlName="dateOfBoardMeeting" maxlength="50" (keypress)="onKeyPress($event, 'alphanumericSpace')">
          </mat-form-field>
        </div>
        <!--/ Date of Board meeting -->

        <!-- Registration number -->
        <div>
          <mat-form-field appearance="outline" [ngClass]="{'w-100':!isFpoOrFponetApp, 'w-50':isFpoOrFponetApp}">
            <mat-label translate>Registration number</mat-label>
            <input matInput formControlName="registrationNo" maxlength="30" (keypress)="onKeyPress($event, 'alphanumericSpace')">
          </mat-form-field>

          <ng-container *ngIf="isFpoOrFponetApp">

            <!-- Image attachments -->
            <div class="pl-2 mt-1" fxFlex="calc(20% - 8px)" fxLayoutAlign="center center"
              *ngIf="regCertAttachmentFormControl?.value || regCertAttachmentFormControl?.value[0]?.url">
              <mat-chip class="mb-4">
                <img class="chip-image cursor-pointer rounded-circle"
                  [src]="(regCertAttachmentFormControl?.value[0]?.url) | prefixDomain">
                <mat-icon matChipRemove class="cursor-pointer" [title]="'Remove image' | translate" (click)="removeRegCertImage()">cancel</mat-icon>
              </mat-chip>
            </div>
            <!--/ Image attachments -->

            <!-- Attach image -->
            <div fxFlex="calc(30% - 8px)">
              <button fxFlex="calc(35% - 8px)" mat-button
                [ngClass]="{'attach-files' : regCertAttachmentFormControl.value, 'mb-2 ml-4' : !regCertAttachmentFormControl.value}"
                id="attach-files-button" color="primary" [matMenuTriggerFor]="regCertificate">
                <mat-icon>attachment</mat-icon>
                <span class="ml-1" translate>Upload</span>
              </button>
              <mat-menu #regCertificate="matMenu">
                <div mat-menu-item class="attach-file-mat-menu">
                  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                    <div>
                      <p class="m-0 title">Image</p>
                    </div>
                    <div>
                      <kl-image-picker-form-control [formControl]="regCertAttachmentFormControl" [showBorder]="false"
                        [buttonType]="'mat-icon-button'" svgButtonIcon="assets/svgs/attach-file/images.svg" [allowMultiple]="false"
                        [s3Category]="s3Category" [showAttachments]="false" [acceptedFileTypes]="acceptedImageFileTypes">
                      </kl-image-picker-form-control>
                    </div>
                  </div>
                </div>
              </mat-menu>
            </div>
            <!--/ Attach image -->
          </ng-container>

        </div>
        <!--/ Registration number -->

        <!-- Registered under -->
        <div>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Registered under</mat-label>
            <input matInput formControlName="registeredUnder" maxlength="40" (keypress)="onKeyPress($event, 'alphanumericSpace')">
          </mat-form-field>
        </div>
        <!--/ Registered under -->

        <!-- Promoting agency -->
        <div>
          <span *ngIf="isShgApp" translate>Promoting Agency</span>
          <mat-form-field appearance="outline" class="w-100">
            <mat-label *ngIf="!isShgApp" translate>Promoting Agency</mat-label>
            <mat-select *ngIf="isShgApp" appearance="outline" multiple [placeholder]="'Select promoting agency' | translate" formControlName="promotingAgency">
              <mat-option *ngFor="let agency of promotingAgency" [value]="agency.id">{{agency.value}}</mat-option>
            </mat-select>
            <input *ngIf="!isShgApp" matInput formControlName="promotingAgency" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')">
          </mat-form-field>
        </div>
        <!--/ Promoting agency -->

        <div formGroupName="registrations">
          <!-- Established year -->
          <!-- <div class="established-since">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label translate>Established since</mat-label>
              <input matInput formControlName="establishedSince">
            </mat-form-field>
          </div> -->
          <!--/ Established year -->

          <div fxLayout="column">
            <label class="mb-0" translate>Established since</label>
            <div fxLayout="row" class="w-100" class="mt-n1">
              <mat-form-field class="w-100" appearance="outline">
                <input matInput [matDatepicker]="establishedDatePicker" formControlName="establishedSince" [placeholder]="'Enter established since' | translate"
                  (keydown.space)="$event.preventDefault()" (focus)="establishedDatePicker.open()">
                <mat-datepicker-toggle matSuffix [for]="establishedDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #establishedDatePicker startView="multi-year" panelClass="custom-date-picker"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!-- PAN Number -->
          <span>Do you have PAN number?</span>
          <div fxLayout fxLayoutGap="8px">
            <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
              <mat-select appearance="outline" formControlName="isPan">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex="calc(70% - 8px)">
              <div class="w-100" fxLayout="column" [fxFlex]="panAttachmentFormControl.value ? 'calc(40% - 8px)' : 'calc(65% - 8px)'">
                <mat-form-field appearance="outline">
                  <mat-label translate>PAN number</mat-label>
                  <input matInput
                    pattern="^([A-Z]){5}([0-9]){4}([A-Z]){1}$"
                    minlength="10"
                    maxlength="10"
                    formControlName="pan"
                    oninput="this.value = this.value.toUpperCase()"
                    (keydown.space)="$event.preventDefault()">
                </mat-form-field>
                <mat-error class="error-message mt-n2" *ngIf="panDetails?.hasError('pattern')">Please enter valid pan number</mat-error>
                <mat-error class="error-message mt-n2" *ngIf="!panDetails?.hasError('pattern') && panDetails?.hasError('minlength')">Pan number must contain 10 digits</mat-error>
              </div>

                <!-- Image attachments -->
                <div class="pl-2 mt-1" fxFlex="calc(30% - 8px)" fxLayoutAlign="center center" *ngIf="panAttachmentFormControl?.value">
                  <kl-image-chip-list [attachments]="panAttachmentFormControl?.value" (remove)="removeImage(panAttachmentFormControl)">
                  </kl-image-chip-list>
                </div>
                <!--/ Image attachments -->
                <!-- Attach image -->
                <div fxFlex="calc(30% - 8px)">
                  <button
                  fxFlex="calc(35% - 8px)"
                  mat-button
                  [ngClass]="{'attach-files' : panAttachmentFormControl.value, 'mb-2 ml-4' : !panAttachmentFormControl.value}"
                  id="attach-files-button"
                  color="primary"
                  [matMenuTriggerFor]="pan">
                  <mat-icon>attachment</mat-icon>
                  <span class="ml-1" translate>Upload</span>
                  </button>
                  <mat-menu #pan="matMenu">
                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title">Image</p>
                        </div>
                        <div>
                          <kl-image-picker-form-control
                            [formControl]="panAttachmentFormControl"
                            [showBorder]="false"
                            [buttonType]="'mat-icon-button'"
                            svgButtonIcon="assets/svgs/attach-file/images.svg"
                            [allowMultiple]="false"
                            [s3Category]="s3Category"
                            [showAttachments]="false"
                            [acceptedFileTypes]="acceptedImageFileTypes">
                          </kl-image-picker-form-control>
                         </div>
                        </div>
                      </div>
                    </mat-menu>
                </div>
                <!--/ Attach image -->
            </div>
          </div>
          <!--/ PAN Number -->

          <!-- GST Number -->
          <span>Do you have GST number?</span>
          <div fxLayout fxLayoutGap="8px">
            <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
              <mat-select appearance="outline" formControlName="isGST">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex="calc(70% - 8px)">
              <div fxLayout="column" [fxFlex]=" gstAttachmentFormControl.value ? 'calc(40% - 8px)' : 'calc(65% - 8px)'">
                <mat-form-field  appearance="outline" class="w-100">
                  <mat-label translate>GST number</mat-label>
                  <input matInput
                    oninput="this.value = this.value.toUpperCase()"
                    pattern="[0-9A-Za-z]*"
                    formControlName="GST"
                    maxlength="20" (keypress)="onKeyPress($event, 'alphaNumeric')">
                </mat-form-field>
                <mat-error class="mt-n2 error-message" *ngIf="gstNumber.hasError('pattern')">Please enter numbers and characters only</mat-error>
              </div>

              <!-- Image attachments -->
              <div class="pl-2 mt-1" fxFlex="calc(30% - 8px)" fxLayoutAlign="center center" *ngIf="gstAttachmentFormControl.value">
                <kl-image-chip-list [attachments]="gstAttachmentFormControl?.value" (remove)="removeImage(gstAttachmentFormControl)">
                </kl-image-chip-list>
              </div>
              <!--/ Image attachments -->

              <!-- Attach image -->
              <div fxFlex="calc(30% - 8px)">
                <button
                fxFlex="calc(35% - 8px)"
                mat-button
                [ngClass]="{'attach-files' : gstAttachmentFormControl.value, 'mb-2 ml-4' : !gstAttachmentFormControl.value}"
                id="attach-files-button"
                color="primary"
                [matMenuTriggerFor]="gst">
                <mat-icon>attachment</mat-icon>
                <span class="ml-1" translate>Upload</span>
                </button>
                <mat-menu #gst="matMenu">
                  <div mat-menu-item class="attach-file-mat-menu">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                      <div>
                        <p class="m-0 title">Image</p>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          [formControl]="gstAttachmentFormControl"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="false"
                          [s3Category]="s3Category"
                          [showAttachments]="false"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>
                  </mat-menu>
              </div>
              <!--/ Attach image -->
            </div>
          </div>
          <!--/ GST Number -->

          <!-- Pesticide license -->
          <span *ngIf="isShgApp">Do you have pesticide license?</span>
          <div *ngIf="isShgApp" fxLayout fxLayoutGap="8px">
            <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
              <mat-select appearance="outline" formControlName="isPesticideLicense">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex="calc(70% - 8px)">
              <mat-form-field [fxFlex]=" pesticideLicenseAttachmentFormControl.value ? 'calc(40% - 8px)' : 'calc(65% - 8px)'" appearance="outline" class="w-100">
                <mat-label translate>Pesticide license number</mat-label>
                <input matInput formControlName="pesticideLicense" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              </mat-form-field>
                <!-- Image attachments -->
                <div class="pl-2 mt-1" fxFlex="calc(30% - 8px)" fxLayoutAlign="center center" *ngIf="pesticideLicenseAttachmentFormControl.value">
                  <kl-image-chip-list [attachments]="pesticideLicenseAttachmentFormControl?.value" (remove)="removeImage(pesticideLicenseAttachmentFormControl)">
                  </kl-image-chip-list>
                </div>
                <!--/ Image attachments -->

                <!-- Attach image -->
                <div fxFlex="calc(30% - 8px)">
                  <button
                  fxFlex="calc(35% - 8px)"
                  mat-button
                  [ngClass]="{'attach-files' : pesticideLicenseAttachmentFormControl.value, 'mb-2 ml-4' : !pesticideLicenseAttachmentFormControl.value}"
                  id="attach-files-button"
                  color="primary"
                  [matMenuTriggerFor]="pesticide">
                  <mat-icon>attachment</mat-icon>
                  <span class="ml-1" translate>Upload</span>
                  </button>
                  <mat-menu #pesticide="matMenu">
                    <div mat-menu-item class="attach-file-mat-menu">
                      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                        <div>
                          <p class="m-0 title">Image</p>
                        </div>
                        <div>
                          <kl-image-picker-form-control
                            [formControl]="pesticideLicenseAttachmentFormControl"
                            [showBorder]="false"
                            [buttonType]="'mat-icon-button'"
                            svgButtonIcon="assets/svgs/attach-file/images.svg"
                            [allowMultiple]="false"
                            [s3Category]="s3Category"
                            [showAttachments]="false"
                            [acceptedFileTypes]="acceptedImageFileTypes">
                          </kl-image-picker-form-control>
                        </div>
                        </div>
                      </div>
                    </mat-menu>
                </div>
                <!--/ Attach image -->
            </div>
          </div>
          <!--/ Pesticide license -->

          <!-- Fertilizer license -->
          <span *ngIf="isShgApp">Do you have fertilizer license?</span>
          <div *ngIf="isShgApp" fxLayout fxLayoutGap="8px">
            <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
              <mat-select appearance="outline" formControlName="isFertilizerLicense">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex="calc(70% - 8px)">
              <mat-form-field [fxFlex]=" fertilizerLicenseAttachmentFormControl.value ? 'calc(40% - 8px)' : 'calc(65% - 8px)'" appearance="outline" class="w-100">
                <mat-label translate>Fertilizer license number</mat-label>
                <input matInput formControlName="fertilizerLicense" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              </mat-form-field>
              <!-- Image attachments -->
              <div class="pl-2 mt-1" fxFlex="calc(30% - 8px)" fxLayoutAlign="center center" *ngIf="fertilizerLicenseAttachmentFormControl.value">
                <kl-image-chip-list [attachments]="fertilizerLicenseAttachmentFormControl?.value" (remove)="removeImage(fertilizerLicenseAttachmentFormControl)">
                </kl-image-chip-list>
              </div>
              <!--/ Image attachments -->
              <!-- Attach image -->
              <div fxFlex="calc(30% - 8px)" fxLayout fxLayout="center space-evenly">
                <button
                fxFlex="calc(35% - 8px)"
                mat-button
                [ngClass]="{'attach-files' : fertilizerLicenseAttachmentFormControl.value, 'mb-2 ml-4' : !fertilizerLicenseAttachmentFormControl.value}"
                id="attach-files-button"
                color="primary"
                [matMenuTriggerFor]="fertilizer">
                <mat-icon>attachment</mat-icon>
                <span class="ml-1" translate>Upload</span>
                </button>
                <mat-menu #fertilizer="matMenu">
                  <div mat-menu-item class="attach-file-mat-menu">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                      <div>
                        <p class="m-0 title">Image</p>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          [formControl]="fertilizerLicenseAttachmentFormControl"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="false"
                          [s3Category]="s3Category"
                          [showAttachments]="false"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>
                  </mat-menu>
              </div>
                <!-- Attach image -->
            </div>
          </div>
          <!--/ Fertilizer license -->

          <!-- Seed license -->
          <span *ngIf="isShgApp">Do you have seed license?</span>
          <div *ngIf="isShgApp" fxLayout fxLayoutGap="8px">
            <mat-form-field appearance="outline" class="w-100" fxFlex="calc(30% - 8px)">
              <mat-select appearance="outline" formControlName="isSeedLicense">
                <mat-option value="YES">Yes</mat-option>
                <mat-option value="NO">No</mat-option>
              </mat-select>
            </mat-form-field>

            <div fxFlex="calc(70% - 8px)" fxLayout fxLayout="center space-evenly">
              <mat-form-field [fxFlex]="seedLicenseAttachmentFormControl.value ? 'calc(40% - 8px)' : 'calc(65% - 8px)'" appearance="outline" class="w-100">
                <mat-label translate>Seed license number</mat-label>
                <input matInput formControlName="seedLicense" maxlength="30" (keypress)="onKeyPress($event, 'alphaNumeric')">
              </mat-form-field>
              <!-- Image attachments -->
              <div class="pl-2 mt-1" fxFlex="calc(30% - 8px)" fxLayoutAlign="center center" *ngIf="seedLicenseAttachmentFormControl.value">
                <kl-image-chip-list [attachments]="seedLicenseAttachmentFormControl?.value" (remove)="removeImage(seedLicenseAttachmentFormControl)">
                </kl-image-chip-list>
              </div>
              <!--/ Image attachments -->

              <!-- Attach image -->
              <div fxFlex="calc(30% - 8px)" fxLayout fxLayout="center space-evenly">
                <button
                fxFlex="calc(35% - 8px)"
                mat-button
                [ngClass]="{'attach-files' : seedLicenseAttachmentFormControl.value, 'mb-2 ml-4' : !seedLicenseAttachmentFormControl.value}"
                id="attach-files-button"
                color="primary"
                [matMenuTriggerFor]="seeds">
                <mat-icon>attachment</mat-icon>
                <span class="ml-1" translate>Upload</span>
                </button>
                <mat-menu #seeds="matMenu">
                  <div mat-menu-item class="attach-file-mat-menu">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="35px" class="attach-menu">
                      <div>
                        <p class="m-0 title">Image</p>
                      </div>
                      <div>
                        <kl-image-picker-form-control
                          [formControl]="seedLicenseAttachmentFormControl"
                          [showBorder]="false"
                          [buttonType]="'mat-icon-button'"
                          svgButtonIcon="assets/svgs/attach-file/images.svg"
                          [allowMultiple]="false"
                          [s3Category]="s3Category"
                          [showAttachments]="false"
                          [acceptedFileTypes]="acceptedImageFileTypes">
                        </kl-image-picker-form-control>
                        </div>
                      </div>
                    </div>
                  </mat-menu>
              </div>
              <!--/ Attach image -->
            </div>
          </div>
          <!--/ Seed license -->
        </div>

      </div>
    </div>

    <!-- Save button -->
    <div fxLayout>
      <div fxLayout fxLayoutAlign="end center" class="mt-2 w-100 save-button">
        <kl-flat-button
          label="Save"
          [disabled]="registrationDetails?.invalid"
          [progress]="progress"
          (clicked)="updateProfile()"
          (disabled)="!fpoOverViewForm?.valid">
        </kl-flat-button>
      </div>
    </div>
    <!--/ Submit button -->
    </form>
  </section>
</main>
