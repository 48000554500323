import { Component, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { KalgudiEnvironmentConfig, KalgudiNotification, KL_ENV, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiSms } from '@kalgudi/types';
import { TranslateService } from '@ngx-translate/core';

import { KalgudiPublishSms } from '../../classes/kalgudi-publish-sms';

// import {_rollupMoment} from 'moment';
// import { MomentDateAdapter } from "@angular/material-moment-adapter";

const SMS_DATE_FORMAT = {
  parse: {
    dateInput: "DD-MMM-YYYY",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
@Component({
  selector: "kl-publish-sms-form",
  templateUrl: "./publish-sms-form.component.html",
  styleUrls: ["./publish-sms-form.component.scss"],

  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: SMS_DATE_FORMAT },
  ],
})
export class PublishSmsFormComponent
  extends KalgudiPublishSms
  implements OnInit
{
  @Output()
  smsCreated = new EventEmitter<KalgudiSms>();

  /**
   * SMS details, it must be provided by a stream.
   */
  @Input()
  postDetails: KalgudiSms;
  todayDate = new Date();
  shareWithValue: string;
  isSamunnatiFpo: boolean = false;

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    private dateAdapter: DateAdapter<Date>,
    private translate: TranslateService,
    @Inject(KL_ENV) public env: KalgudiEnvironmentConfig
  ) {
    super(injector, notification, env);
    this.isSamunnatiFpo = this.env.appId === 'SAM_FPO';
  }

  ngOnInit() {
    if (this.postDetails) {
      this.initPostDetails(this.postDetails);
    }
    // this.updateDateLocale();
  }

  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  // selectedUserTrackByFun(index: number, item: KeyValue<string, KalgudiUserBasicDetails>): any {
  //   return item.value.profileKey;
  // }

  protected onDestroyed(): void {}

  /**
   * Calls after posting a share
   * @param sms
   */
  protected onSmsCreated(sms: KalgudiSms): void {
    this.smsCreated.emit(sms);
  }

  // private updateDateLocale(): void {
  //   this.dateAdapter.setLocale(this.translate.currentLang);
  //   this.translate.onLangChange.subscribe((lang: LangChangeEvent) =>
  //     this.dateAdapter.setLocale(lang.lang)
  //   );
  // }

  /**
   * Triggered while changing share-with field
   */
  shareWithChanged(event: string) {
    this.shareWithValue = event;
  }
}
