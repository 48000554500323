<mat-card fxFlex="100%" fxLayout="column" id="business-activities-container">
  <!-- Business activities header -->
  <div class="business-activities-header py-2 mb-2 mt-n4" fxLayout fxLayoutAlign="space-between center">
    <div fxLayout fxLayoutGap="10px" fxLayoutAlign="start center" class="pt-2">
      <div>
        <svg-icon [applyCss]="true" class="text-center" src="assets/icons/business-activities.svg"
          [svgStyle]="{ 'width.px': 23, 'height.px': 23 }"></svg-icon>
      </div>

      <div>
        <p class="m-0 font-weight-bold" translate>Business Activities</p>
      </div>
    </div>
    <div *ngIf="!isPublicPage">
      <button matTooltip="Edit business activities details" *ngIf="isShowEdit" mat-icon-button class="border-0 edit-button p-0" fxLayoutAlign="end"
        (click)="showBusinessActivitiesDialog(true)">
      <mat-icon class="rounded-circle edit-icon text-white" fxLayoutAlign="center center">edit</mat-icon>
      </button>
    </div>
  </div>
  <!--/ Business activities header -->

  <!-- Business activities list -->
  <div *ngIf="isShowEdit" class="business-activities-list">

    <!-- Input-business list -->
    <div class="mb-2 mr-2" *ngIf="basicActivityFormDetails?.inputBusinessActivities?.length">
      <!-- Input business title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Input business details</h6>
      </div>
      <!--/ Input business title -->

      <!-- Input business values -->
      <div class="input-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Year</div>
            <div class="table-cell" translate>Revenue(₹)</div>
            <div class="table-cell" translate>No.of shops</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let inputBusiness of basicActivityFormDetails?.inputBusinessActivities; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ inputBusiness?.businessYear || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(inputBusiness?.businessRevenue?.toLocaleString('en-IN')) }}</div>
              <div class="table-cell text-break">{{ inputBusiness?.quantity || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Input business values -->
    </div>
    <!--/ Input-business list -->

    <!-- Output-business list -->
    <div class="mb-2 mr-2" *ngIf="basicActivityFormDetails?.outputBusinessActivities?.length">
      <!-- Output business title-->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Output business details</h6>
      </div>
      <!--/ Output business title-->

      <!-- Output business values -->
      <div class="output-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Year</div>
            <div class="table-cell" translate>Revenue(₹)</div>
            <!-- <div class="table-cell" translate>Top 3 buyers</div>
            <div class="table-cell" translate>Business</div> -->
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let outputBusiness of basicActivityFormDetails?.outputBusinessActivities; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ outputBusiness?.businessYear || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(outputBusiness?.businessRevenue?.toLocaleString('en-IN')) }}</div>
              <!-- <div class="table-cell text-break">{{ outputBusiness?.topThreeBuyers || '-' }}</div>
              <div class="table-cell text-break">{{ (outputBusiness?.percentageOfOutput/100 | percent) || '-' }}</div> -->
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Output business values -->
    </div>
    <!--/ Output-business list -->

    <!-- CHC list -->
    <div class="mb-2 mr-2" *ngIf="basicActivityFormDetails?.chcServices?.length">
      <!-- CHC business title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>CHC business details</h6>
      </div>
      <!--/ CHC business title -->

      <!-- CHC business values -->
      <div class="chc-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Year</div>
            <div class="table-cell" translate>Revenue(₹)</div>
            <div class="table-cell" translate>No.of shops</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let chc of basicActivityFormDetails?.chcServices; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ chc?.businessYear || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(chc?.businessRevenue?.toLocaleString('en-IN')) }}</div>
              <div class="table-cell text-break">{{ chc?.quantity || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ CHC business values -->
    </div>
    <!--/ CHC list -->

    <!-- Lending list -->
    <div class="mb-2 mr-2" *ngIf="basicActivityFormDetails?.lending?.length">
      <!-- Lending business title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Lending business details</h6>
      </div>
      <!--/ Lending business title -->

      <!-- Lending business values -->
      <div class="lending-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Year</div>
            <div class="table-cell" translate>How much was the lending <span>(₹)</span></div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let option of basicActivityFormDetails?.lending; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ option?.year || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(option?.lendingAmount?.toLocaleString('en-IN')) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Lending business values -->
    </div>
    <!--/ Lending list -->

    <!-- Consumer list -->
    <div class="mb-2 mr-2" *ngIf="consumerDetailsList?.length">
      <!-- Consumer title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Consumer details</h6>
      </div>
      <!--/ Consumer title -->

      <!-- Consumer values -->
      <div class="consumer-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Products commodity</div>
            <div class="table-cell" translate>Revenue(₹)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let consumer of consumerDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ consumer?.consumerProductCommodity || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(consumer?.consumerProductRevenue) }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Consumer values -->
    </div>
    <!--/ Consumer list -->

    <div *ngIf="noConsumerDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Consumer details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Certified list -->
    <div class="mb-2 mr-2" *ngIf="certifiedDetailsList?.length">
      <!-- Certified title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Certified details</h6>
      </div>
      <!--/ Certified title -->

      <!-- Certified values -->
      <div class="certified-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Seed production crop</div>
            <div class="table-cell" translate>Revenue(₹)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let certified of certifiedDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ certified?.certifiedProductionCrop || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(certified?.certifiedProductionRevenue) }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Certified values -->
    </div>
    <!--/ Certified list -->

    <div *ngIf="noCertifiedDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Certified details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Warehouse list -->
    <div class="mb-2 mr-2" *ngIf="warehouseDetailsList?.length">
      <!-- Warehouse title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Warehouse details</h6>
      </div>
      <!--/ Warehouse title -->

      <!-- Warehouse values -->
      <div class="warehouse-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Commodity stored</div>
            <div class="table-cell" translate>Capacity(in tons)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let ware of warehouseDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ ware?.commodityStoredInWarehouse || '-' }}</div>
              <div class="table-cell text-break">{{ ware?.capacityInWarehouse || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Warehouse values -->
    </div>
    <!--/ Warehouse list -->

    <div *ngIf="noWarehouseDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Warehouse details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Cold storage list -->
    <div class="mb-2 mr-2" *ngIf="coldStorageDetailsList?.length">
      <!-- Cold storage title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Cold storage details</h6>
      </div>
      <!--/ Cold storage title -->

      <!-- Cold storage values -->
      <div class="cold-storage-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Commodity stored</div>
            <div class="table-cell" translate>Capacity(in tons)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let coldStorage of coldStorageDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ coldStorage?.commodityStoredInColdStorage || '-' }}</div>
              <div class="table-cell text-break">{{ coldStorage?.capacityInColdStorage || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Cold storage values -->
    </div>
    <!--/ Cold storage list -->

    <div *ngIf="noColdStorageDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Cold storage details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Export & Import list -->
    <div class="mb-2 mr-2" *ngIf="exportOrImportDetailsList?.length">
      <!-- Exports/Imports title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Exports/Imports details</h6>
      </div>
      <!--/ Exports/Imports title -->

      <!-- Exports/Imports values -->
      <div class="exports-imports-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Commodity</div>
            <div class="table-cell" translate>Revenue(₹)</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let ei of exportOrImportDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ ei?.exportsOrImportsCommodity || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(ei?.exportsOrImportsRevenue) }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Exports/Imports values -->
    </div>
    <!--/ Export & Import list -->

    <div *ngIf="noExportOrImportDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Exports/Imports details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Allied list -->
    <div class="mb-2 mr-2" *ngIf="alliedDetailsList?.length">
      <!-- Allied title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Allied details</h6>
      </div>
      <!--/ Allied title -->

      <!-- Allied values -->
      <div class="allied-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Activities</div>
            <div class="table-cell" translate>Turnover <span>(₹)</span></div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let item of alliedDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ item?.alliedActivities === 'OTHERS' ? item?.alliedOthers : item?.alliedActivities || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(item?.alliedActivitiesRevenue?.toLocaleString('en-IN')) || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Allied values -->
    </div>
    <!--/ Allied list -->

    <div *ngIf="noAlliedDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Allied details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

    <!-- Other-business list -->
    <div class="mb-2 mr-2" *ngIf="otherBusinessDetailsList?.length">
      <!-- Other business title -->
      <div>
        <h6 class="font-weight-bold mb-1 mt-2" translate>Other business details</h6>
      </div>
      <!--/ Other business title -->

      <!-- Other business values -->
      <div class="other-table business-table rounded overflow-auto">
        <div class="table">
          <!-- Table header values -->
          <div class="table-header text-center text-black font-weight-bold p-1">
            <div class="table-cell" translate>S.no</div>
            <div class="table-cell" translate>Activity year</div>
            <div class="table-cell" translate>Revenue(₹)</div>
            <div class="table-cell" translate>Activities</div>
          </div>
          <!--/ Table header values -->

          <!-- Table body values -->
          <div class="table-body text-center">
            <div class="table-row bg-white" *ngFor="let otherBusiness of otherBusinessDetailsList; let i=index;">
              <div class="table-cell text-break">{{ i+1 }}</div>
              <div class="table-cell text-break">{{ otherBusiness?.businessYear || '-' }}</div>
              <div class="table-cell text-break">{{ convertIndianRupeeFormat(otherBusiness?.businessRevenue?.toLocaleString('en-IN')) }}</div>
              <div class="table-cell text-break">{{ otherBusiness?.activities || '-' }}</div>
            </div>
          </div>
          <!--/ Table body values -->
        </div>
      </div>
      <!--/ Other business values -->
    </div>
    <!--/ Other-business list -->

    <div *ngIf="noOtherBusinessDetails" class="border-bottom mb-1" fxLayout="row" fxLayoutGap="6px" fxLayoutAlign="start">
      <div fxFlex="calc(50% - 6px)" class="font-weight-bold h6" translate>Other business details</div>
      <span fxFlex="calc(50% - 6px)">No</span>
    </div>

  </div>
  <!--/ Business activities list -->

  <!-- Coming soon -->
  <!-- <div *ngIf="false" class="no-content-business" fxLayout fxLayoutAlign="center center">
    <img src="assets/images/coming-soon.png" alt="" width="60px" class="coming-soon">
  </div> -->
  <!--/ Coming soon -->

  <!-- No content if there no business activities -->
  <ng-container *ngIf="!isShowEdit">
    <div class="w-100 no-content-business" fxLayoutAlign="center center">
      <kl-no-content
        [text]="isPublicPage ? ('No Business Activities added yet!' | translate) : editable ? '' : ('No Business Activities added yet!' | translate)"
        [actionLink]="isPublicPage ? '' : editable ? ('Add business activities' | translate) : ''"
        (action)="showBusinessActivitiesDialog(false)">
      </kl-no-content>
    </div>
  </ng-container>
  <!--/ No content if there no business activities -->
</mat-card>
