import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableSearchStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { ActivityType, KalgudiProjectTask, PartialData } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class TaskSubmissions extends KalgudiMatTableSearchStream<KalgudiProjectTask> {

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input()
  taskId: string;

  @Input()
  projectId: string;

  @Input()
  taskDetails: KalgudiProjectTask;

  activityType = ActivityType;

  private tasksService: KalgudiTasksService;
  private notifications: KalgudiNotification;
  private taskActionService: KalgudiTaskActionsService;

  constructor(
    protected injector: Injector,
  ) {

      super();

      this.tasksService       = this.injector.get<KalgudiTasksService>(KalgudiTasksService);
      this.notifications      = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
      this.taskActionService  = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);

      // Override the list of columns to show
      this.displayedColumns = [
        'createdBy',
        'submissionDate',
        'status',
      ];

      this.pageSizeOptions = [
        25,
        50,
        100
      ];
    }

  /**
   * Calls api to get the submission details
   */
  getSubmissionDetails(
    taskId: string,
    submissionDetails: KalgudiProjectTask,
    extraParams: PartialData = {}
  ): void {

    this.notifications.showSpinner(true);

    this.getUserSubmission(submissionDetails.createdBy.profileKey, extraParams)
      .pipe(
        finalize(() => this.notifications.hideSpinner()),

        tap(_ => this.notifications.hideSpinner()),

        switchMap(res => this.openTaskSubmissionDialog(taskId, res, extraParams))
      )
      .subscribe();
  }

  /**
   * Calls stream api method to get members list
   * @param offset
   * @param limit
   */
  protected streamApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string): Observable<KalgudiStreamData> {
    return this.tasksService.getActivities(this.activityType.TASK_SUBMISSION, this.taskId, offset, limit, '', '', this.searchKeyword);

  }

  /**
   * Fetches the user submission details
   */
  private getUserSubmission(profileKey: string, extraParams: PartialData = {}): Observable<KalgudiProjectTask> {

    return this.tasksService.getActivities(this.activityType.TASK_SUBMISSION, this.taskId, 0, 10, profileKey, '', '', extraParams)

      .pipe(

        map(res => res.items && res.items.length ? res.items[0] : null)
      );
  }

  /**
   * Open task submission dialog
   */
  private openTaskSubmissionDialog(
    taskId: string,
    submissionDetails: KalgudiProjectTask,
    extraParams: PartialData = {}
  ): Observable<any> {

    return this.taskActionService.showAddTaskSubmissionDialog(
      taskId,
      this.projectId,
      this.taskDetails,
      submissionDetails,
      extraParams
    )
      .pipe(
        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      );
  }


}
