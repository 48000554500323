import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { PhotoswipeAngularWrapperService } from './photoswipe-angular-wrapper.service';
import { PhotoswipeAttachments } from './types';


/**
 * Kalgudi wrapper of photoswipe image preview plugin.
 */
@Component({
  selector: 'kl-photoswipe',
  template: `
    <ng-container *ngIf="(photoswipe$ | async) as photoswipe">
      <section *ngIf="photoswipe?.active">
        <kl-image-preview [gallery]="photoswipe">
        </kl-image-preview>
      </section>
    </ng-container>
  `,
  styles: [ ]
})
export class KalgudiPhotoswipeComponent {

  constructor(
    private libraryWrapper: PhotoswipeAngularWrapperService,
  ) {}


  /**
   * Show image fullview if photoswipe event is triggered
   */
  get photoswipe$(): Observable<PhotoswipeAttachments> {
    return this.libraryWrapper.photoswipe$;
  }
}
