import { Inject, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MobileDialogConfig } from '@kalgudi/common';
import { ApiError, checkMobileDevice, KalgudiError, KalgudiSearchStream, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import {
  KALGUDI_PAGE_RELATION_MAP,
  KalgudiDialogConfig,
  KalgudiDialogResult,
  KalgudiPageRelation,
  KalgudiUserBasicDetails,
  KalgudiUsersMap,
  KalgudiUsersPickerDialogConfig,
} from '@kalgudi/types';
import { Observable } from 'rxjs';
import { filter, finalize, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { PageActions } from '../../constants';
import { KalgudiPageService } from '../../services/kalgudi-page.service';
import { ProgramStateService } from '../../services/program-state.service';
import { ApiPageMemberAddResponseData } from '../../typings';


/**
 * A program users management stream is a searchable inbox stream. It defines base methods
 * for searching and providing program users stream.
 *
 * It exposes two public methods to manage program user.
 *
 * - `add()` Adds user to the program.
 * - `remove()` Removes user from the program.
 *
 * Defines several abstract methods must be implemented by its child.
 *
 * Must call the `initMembersManagement()` method to initialize the stream.
 */
@Directive()
export abstract class KalgudiProgramManageUsers extends KalgudiSearchStream<KalgudiUserBasicDetails> {

  pageId: string;
  authorId: string;

  memberRole: KalgudiPageRelation;

  memberRoles = KALGUDI_PAGE_RELATION_MAP;

  private memberStateChange$: Observable<string>;

  private initialized = false;

  constructor(
    protected kalgudiProgram: KalgudiPageService,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService,
    protected programState: ProgramStateService,
  ) {

    // Wake up my parent
    super(notification, util);

    // Get entity id
    this.kalgudiProgram.pageDetails$
      .pipe(
        first(),
      ).subscribe(pageDetails => {

        this.pageId = pageDetails.pageId;
        this.authorId = pageDetails.createdBy.profileKey;
        this.memberRole = pageDetails.memberRole;
      });

    // Refresh members list of program state changed
    this.memberStateChange$ = this.programState.action$
      .pipe(
        // Filter admin add actions
        filter(action => action.type === PageActions.PROGRAM_MEMBERS_UPDATE),

        map(action => action.payload)
      );
  }


  // --------------------------------------------------------
  // #region Public interfacing methods
  // --------------------------------------------------------

  /**
   * Adds a user to the program. Displays a Kalgudi user picker dialog.
   * Finally calls the add api to add the user.
   *
   * On successful update it automatically updates the program state.
   */
  add(showExtraFields?: boolean, pageId?: string): void {
    // Users dialog UI configuration
    const dialogDetails: KalgudiUsersPickerDialogConfig = {
      title: 'Select users to add',
      acceptButtonTitle: 'Select users',
      rejectButtonTitle: 'Cancel',
      multiSelect: true,
      extraParams: {
        showExtraFields: showExtraFields,
        pageId: pageId
      },
    };

    // Material dialog configuration
    const dialogConfig: MatDialogConfig = {
      width: '800px',
      panelClass: 'kl-dialog',
      hasBackdrop: true,
      disableClose: true,
      autoFocus: false,
    };

    // Show user picker dialog
    this.showUsersPicker(dialogDetails, dialogConfig)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Do operation only if dialog is not closed successfully
        // User has clicked the accept button
        filter(r => r.accepted),

        // Pass on the handle to the add api if there are some users selected
        switchMap(r =>
          this.addApi(r.data, this.mapUsersSetToArray(r.data))
            .pipe(
              takeUntil(this.destroyed$)
            )
        ),
      )
      .subscribe(
        res => {
          // Received success response from the addApi
          // Update program state, notify other about the change
          // Call next handler for the addition

          // Update program state
          this.programState.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATE);

          // Call the next handler
          this.onUserAdded(res);
        },
        (err: ApiError) => {
          console.error('Unable to add member to page.', err.error.message);
          this.notification.showMessage(err.error.message);
        });
  }

  /**
   * Removes a user to the program.
   *
   * On successful update it automatically updates the program state.
   */
  remove(user: KalgudiUserBasicDetails): void {

    this.notification.showSpinner();

    // Call the remove api to remove the existing user from program
    this.removeApi(user, this.pageId)
      .pipe(

        // Take items from the stream only till the instance is alive
        takeUntil(this.destroyed$),

        // Received success response from the api
        // Update program state, notify others about the change
        // Finally call the next handler for removal operation
        tap(res => {

          // Update program state
          this.programState.dispatchAction(PageActions.PROGRAM_MEMBERS_UPDATE);

          // Call next handler
          this.onUserRemoved(res);
        }),

        finalize(() => this.notification.hideSpinner())
      )
      .subscribe(
        (res) => this.onUserRemoved(res),
        (err) => this.userActionFailed(err)
      );
  }


  /**
   * By default angular tracks a list using index. Index tracking does not
   * gives performance when we are performing CRUD operations on the list
   * using some id.
   */
  userProfileKeyTrackByFun(index: number, item: KalgudiUserBasicDetails): any {
    return item ? item.profileKey : index;
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Abstract methods
  // --------------------------------------------------------

  /**
   * Define add api for the user add operation.
   */
  protected abstract addApi(
    selectedUsers: KalgudiUsersMap,
    selectedUsersList: KalgudiUserBasicDetails[]
  ): Observable<ApiPageMemberAddResponseData>;

  /**
   * Define remove api for the user remove operation.
   */
  protected abstract removeApi(user: KalgudiUserBasicDetails, pageId?: string): Observable<ApiPageMemberAddResponseData>;

  /**
   * Shows users picker dialog to add a new user.
   *
   * Different users with their roles shows different user picker sharing same data
   * structure. Implement the method in all the child of program users management.
   */
  protected abstract openUsersPickerDialog(details: KalgudiDialogConfig, config: MatDialogConfig<any>): Observable<KalgudiDialogResult>;

  /**
   * Shows users picker mobile dialog to add a new user.
   *
   * Different users with their roles shows different user picker sharing same data
   * structure. Implement the method in all the child of program users management.
   */
  protected abstract openMobileUserPickerDialog(details: MobileDialogConfig): Observable<KalgudiDialogResult>;

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------



  // --------------------------------------------------------
  // #region Private and protected methods
  // --------------------------------------------------------

  /**
   * Called immediately after the successful user add operation.
   *
   * Override this function in your child class to perform operations after user is added
   * successfully.
   */
  protected onUserAdded(stats: ApiPageMemberAddResponseData): void {

    let msg = '';

    if (stats.success > 0) {
      msg = `Successfully added ${stats.success} users`;
    }
    if (stats.failure > 0) {
      msg += `${msg.length > 0 ? ',' : ''} failed to add ${stats.failure} users.`;
    }

    this.notification.showMessage(msg);
  }

  /**
   * Called immediately after the successful user remove operation.
   *
   * Override this function in your child class to perform operations after user is removed
   * successfully.
   */
  protected onUserRemoved(stats: ApiPageMemberAddResponseData): void { }

  /**
   * Initializes members list, adds a hook to the page state change.
   */
  protected initMembersManagement(): void {

    // Do nothing if already initialized
    // Initialization should happen once
    if (this.initialized) {
      return;
    }

    // Initialize users list
    this.initStream();

    // Add hook to the member state change events
    // On every state change fetch the latest program members
    if (this.memberStateChange$) {
      this.memberStateChange$
        .pipe(
          takeUntil(this.destroyed$),
        )
        .subscribe(action => this.resetAndLoadStream());
    }

    // Raise the initialized flag to avoid multiple initialization
    this.initialized = true;
  }

  /**
   * User management error handler. This method is called on any error while adding or removing user.
   */
  protected userActionFailed(err: KalgudiError): void {
    this.util.errorHandler(err.error.message);
  }

  /**
   * Maps kalgudi users set to array
   */
  private mapUsersSetToArray(users: KalgudiUsersMap): KalgudiUserBasicDetails[] {

    const usersList = Object.values(users);

    // Remove extra profile pic url
    usersList.forEach((u: any) => delete u.profilePicURL);

    return usersList as any;
  }

  /**
   * Resets stream and search again
   */
  private resetAndLoadStream(): void {
    this.resetStream();
    this.resetSearch();

    this.searchForm.patchValue({ searchKeyword: Date.now() + '' });

    this.search();
  }

  /**
   * Shows users picker for mobile and web based on the device or screen size.
   */
  private showUsersPicker(dialogConfig: KalgudiDialogConfig, matDialogConfig: MatDialogConfig<any>): Observable<KalgudiDialogResult> {

    return checkMobileDevice()
      ? this.openMobileUserPickerDialog(dialogConfig)
      : this.openUsersPickerDialog(dialogConfig, matDialogConfig);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
