import { Component, Inject, Injector, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';

import { KalgudiPageProcessingList } from '../../../../classes/kalgudi-page-processing-list';

@Component({
  selector: 'kl-kalgudi-page-processing-list',
  templateUrl: './kalgudi-page-processing-list.component.html',
  styleUrls: ['./kalgudi-page-processing-list.component.scss']
})
export class KalgudiPageProcessingListComponent extends KalgudiPageProcessingList implements OnInit {

  constructor(
    protected injector: Injector,
    @Inject(KL_NOTIFICATION) protected notification: KalgudiNotification,
    protected util: KalgudiUtilityService
  ) {

    super(injector, notification, util);
  }

  ngOnInit() {
    this.initStream();
  }

  onDestroyed(): void {}

}
