<div fxLayout="column" class="kalgudi-core-theme h-100 overflow-hidden mw-100">

  <!-- Dialog title -->
  <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Section for mat dialog content -->
    <div mat-dialog-content>
      <div class="dialog-container">
        <form autocomplete="off" [formGroup]="scheduleUpdatesForm">

          <div class="schedule-post-wrapper" fxLayout fxLayoutGap="12px" fxLayoutGap.xs="6px" fxLayoutAlign="start center" fxLayoutAlign.xs="start center">
            <span fxFlex="60px" class="schedule" translate>Schedule</span>
            <div fxFlex="calc(50% - 60px)">

              <mat-form-field color="primary" class="w-100 schedule-font" floatLabel="never">
                <mat-label translate>Choose date</mat-label>
                <input matInput [matDatepicker]="scheduledDate" readonly (click)="scheduledDate.open()" formControlName="scheduledDate">
                <mat-datepicker-toggle matSuffix [for]="scheduledDate"></mat-datepicker-toggle>
                <mat-datepicker #scheduledDate></mat-datepicker>
              </mat-form-field>
            </div>

            <div fxFlex="calc(50% - 60px)">
              <kl-hour-selectable-form-control [selectedDate]="scheduledDateField?.value" [placeholder]="'Time' | translate" formControlName="scheduledTime"></kl-hour-selectable-form-control>
            </div>
          </div>

          <div fxLayout fxLayoutAlign="end end">
            <kl-flat-button
              [type]="'submit'"
              label="Send"
              [progress]="progress"
              [disabled]="scheduleUpdatesForm?.invalid"
              (clicked)="sendScheduleUpdates()">
            </kl-flat-button>
          </div>
        </form>
      </div>
    </div>
    <!-- /Section for mat dialog content -->

  </div>
</div>
