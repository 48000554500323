<div fxLayout="column" class="kalgudi-core-theme h-100 overflow-hidden mw-100 satellite-advisories-dialog">

  <!-- Dialog title -->
  <!-- <div class="dialog-header" fxLayout="row" fxLayoutAlign="space-between center">
    <h1 class="header-title text-capitalize" mat-dialog-title translate>{{data?.title}}</h1>
    <button class="close-dialog-btn outline-none" mat-icon-button (click)="cancel()">
      <mat-icon>clear</mat-icon>
    </button>
  </div> -->
  <!--/ Dialog title -->

  <div class="dialog-content " fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Section for mat dialog content -->
    <div mat-dialog-content>
      <div class="dialog-container geotag-confirmation">

        <div fxLayout="column" fxLayoutAlign="center center">
          <div>
            <mat-icon>warning</mat-icon>
          </div>

          <div>
            <p class="m-0" translate>This will update your location. Are you sure to update it?</p>
          </div>
        </div>

        <div fxLayoutAlign="end end" fxLayoutGap="10px">
          <button mat-button color="warn" (click)="cancel()" translate>No</button>
          <button mat-button color="primary" (click)="ok()" translate>Yes</button>
        </div>


      </div>
    </div>
    <!-- /Section for mat dialog content -->

  </div>
</div>
