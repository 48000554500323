import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter, MomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { KalgudiDialogsModule, KalgudiImagePickerModule, KalgudiMobileDialogModule } from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { KalgudiPipesModule, MAT_INDIAN_DATE_FORMAT, PageStateManager } from '@kalgudi/core';
import { KalgudiGooglePlacesModule } from '@kalgudi/third-party/google-places';
import { TranslateModule } from '@ngx-translate/core';
import { DateAdapter } from 'angular-calendar';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { SurveyStateService } from '../../services/survey-state.service';
import { CloneSurveyDialogComponent } from './components/clone-survey-dialog/clone-survey-dialog.component';
import {
  CloneSurveyMobileDialogComponent,
} from './components/clone-survey-mobile-dialog/clone-survey-mobile-dialog.component';
import { CloneSurveyComponent } from './components/clone-survey/clone-survey.component';
import { CreateSurveyDialogComponent } from './components/create-survey-dialog/create-survey-dialog.component';
import {
  CreateSurveyMobileDialogComponent,
} from './components/create-survey-mobile-dialog/create-survey-mobile-dialog.component';
import { CreateSurveyComponent } from './components/create-survey/create-survey.component';
import { KalgudiCreateSurveyApiService } from './services/create-survey-api.service';
import { KalgudiCreateSurveyService } from './services/create-survey.service';

@NgModule({
  declarations: [
    CreateSurveyComponent,
    CreateSurveyMobileDialogComponent,
    CreateSurveyDialogComponent,
    CloneSurveyDialogComponent,
    CloneSurveyComponent,
    CloneSurveyMobileDialogComponent
  ],
  imports: [
    CommonModule,

    // Material module
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MomentDateModule,

    // FlexModule
    FlexLayoutModule,

    // Forms module
    FormsModule,
    ReactiveFormsModule,

    AngularSvgIconModule,

    // Kalgudi modules
    KalgudiDialogsModule,
    KalgudiMobileDialogModule,
    KalgudiGooglePlacesModule,
    KalgudiButtonModule,
    KalgudiImagePickerModule,
    KalgudiPipesModule,
    TranslateModule
  ],
  exports: [
    CreateSurveyComponent
  ],
  providers: [
    KalgudiCreateSurveyService,
    KalgudiCreateSurveyApiService,
    DatePipe,
    {
      provide: PageStateManager,
      useExisting: SurveyStateService,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [ MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS ]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_INDIAN_DATE_FORMAT
    },
  ],
  entryComponents: [
    CreateSurveyMobileDialogComponent,
    CreateSurveyDialogComponent,
    CloneSurveyDialogComponent,
    CloneSurveyMobileDialogComponent
  ]
})
export class KalgudiSurveyCreationModule { }
