import { Injector, Input, ViewChild, Directive } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { KalgudiMatTableSearchStream, KalgudiStreamData } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiProjectTask, PartialData, ProjectRole } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { skip, switchMap, takeUntil } from 'rxjs/operators';

import { PROJECT_TYPE_TITLE_MAP } from '../../../constants';
import { KalgudiProjectStateService } from '../../../services/kalgudi-project-state.service';
import { KalgudiProjectService } from '../../../services/kalgudi-project.service';
import { KalgudiTasksListService } from '../../project-fullview/services/kalgudi-tasks-list.service';
import { KalgudiTaskActionsService } from '../services/kalgudi-task-actions.service';
import { KalgudiTasksService } from '../services/kalgudi-tasks.service';

@Directive()
export abstract class TaskListTable extends KalgudiMatTableSearchStream<KalgudiProjectTask>  {

  @ViewChild(MatPaginator, { static: true }) matPaginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;

  @Input()
  projectId: string;

  @Input()
  loggedInUserTasks: boolean;

  @Input()
  profileKey: string;

  @Input()
  extraFilters: PartialData = {};

  projectRoles = ProjectRole;

  readonly projectTypesMap = PROJECT_TYPE_TITLE_MAP;

  private tasksListService: KalgudiTasksListService;
  private notifications: KalgudiNotification;
  private tasksActionService: KalgudiTaskActionsService;
  private stateService: KalgudiProjectStateService;
  private projectService: KalgudiProjectService;
  private taskService: KalgudiTasksService;

  constructor(
    protected injector: Injector
    ) {

    super();

    this.tasksListService       = this.injector.get<KalgudiTasksListService>(KalgudiTasksListService);
    this.notifications          = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.stateService           = this.injector.get<KalgudiProjectStateService>(KalgudiProjectStateService);
    this.tasksActionService     = this.injector.get<KalgudiTaskActionsService>(KalgudiTaskActionsService);
    this.projectService         = this.injector.get<KalgudiProjectService>(KalgudiProjectService);
    this.taskService            = this.injector.get<KalgudiTasksService>(KalgudiTasksService);


    // Override the list of columns to show
    this.displayedColumns = [
      'taskName',
      'startDate',
      'actualStartDate',
      // 'variety',
      'templateType',
      'members',
      'status',
      'settings',
    ];


    this.stateService.reloadTasks$
      .pipe(
        takeUntil(this.destroyed$),

        skip(1)
      )
    .subscribe(
      (res) => this.resetTable()

    );

    this.minSearchLength = 3;
  }


  /**
   * Extra filters to get the tasks
   */
  get filters(): PartialData {
    return {};
  }

  /**
   * Opens task full view dialog
   */
  openTaskFullviewDialog(title: string, taskId: string, taskDetails: KalgudiProjectTask) {
    this.tasksActionService.showTaskFullviewDialog(title, taskId, taskDetails)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  openEditTaskDialog(taskId: string, projectId: string) {

    this.updateProjectDetails(projectId);

    this.taskService.getTaskDetails(taskId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
        switchMap(_ => this.tasksActionService.openTaskCreationDialog(projectId, taskId, {...this.filters, ...this.extraFilters}))
      )
      .subscribe(
        (res) => {

          this.notifications.showMessage('Task updated successfully');
          this.stateService.reloadTasks();
          this.updateProjectDetails(projectId);
        }
      );
  }


  /**
   * Deletes the task
   */
  deleteTask(taskId: string) {

    this.tasksActionService.deleteTask(taskId, {...this.filters, ...this.extraFilters})
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => this.taskDeleteHandler(res),
        err => this.taskDeleteErrHandler(err)
      );
  }

  /**
   * Open add members dialog
   */
  showAddTaskMembersDialog(taskId: string) {
    this.tasksActionService.showAddTaskMembersDialog(taskId, this.projectId)
      .pipe(

        // Subscribe till `$destroyed` is not emitted
        takeUntil(this.destroyed$),
      )
      .subscribe(
        res => {
          this.stateService.reloadTasks();
        }
        // res => this.stateService.taskCreated()
      );
  }

  /**
   * Event handler for successful task deletion
   */
  protected taskDeleteHandler(res) {
    this.stateService.reloadTasks();
  }

  /**
   * Event handler for failed to delete task
   */
  protected taskDeleteErrHandler(err: Error) {
    this.notifications.showMessage('Failed to delete a task');
  }

  /**
   * Updates the project details with latest data
   */
  protected updateProjectDetails(projectId: string) {
    this.projectService.getProject(projectId).subscribe();
  }

  /**
   * Define the streamApi method to specify the data source of the table
   */
  protected streamApi(
    offset: number,
    limit: number,
    sortBy?: string,
    sortDirection?: string,
    searchKeyword?: string): Observable<KalgudiStreamData> {

    if (this.loggedInUserTasks) {
      this.projectId = '';
    }

    if (searchKeyword) {

      return this.tasksListService.searchTasks(
        searchKeyword, offset, limit, this.projectId, this.profileKey,
        {...this.filters, ...this.extraFilters});
    } else {

      return this.tasksListService.getTasks(
        offset, limit, this.projectId, this.profileKey,
        {...this.filters, ...this.extraFilters});
    }

  }

}
