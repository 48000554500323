import { Component, Inject, OnInit } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiUsersPickerDialogConfig } from '@kalgudi/types';

import { KalgudiUsersPickerMobileDialog } from '../../../kalgudi-users/classes/kalgudi-user-picker-mobile-dialog';
import { KalgudiMobileDialogRef } from '../../../mobile-dialog/classes/mobile-dialog-overlay-ref';
import { KL_MOBILE_DIALOG_DATA } from '../../../mobile-dialog/config';

@Component({
  selector: 'kl-users-picker-mobile-dialog',
  templateUrl: './users-picker-mobile-dialog.component.html',
  styleUrls: ['./users-picker-mobile-dialog.component.scss']
})
export class UsersPickerMobileDialogComponent extends KalgudiUsersPickerMobileDialog implements OnInit {

  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) public dialogData: KalgudiUsersPickerDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    protected util: KalgudiUtilityService,
  ) {

    super(dialogData, dialogRef, util);
  }

  ngOnInit() {
  }

}
