<div class="prediction-list" [attr.id]="copyId">

  <div fxLayout="column">
    <div *ngFor="let item of informationList; let last = last; let i = index" [attr.id]="'info-ai-predictions' + i">
      <div fxLayout="column" class="item selected-item" [ngClass]="{'separator': !last, 'not-selected' : item?.hide}">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="column">
            <p class="m-0 font-weight-bold title">{{item?.title | titlecase}}</p>
            <p class="date m-0" *ngIf="item?.fromDate">
              {{item?.fromDate | date:'dd MMM'}}
              <span *ngIf="item?.fromDate && item?.toDate"> - </span>
              <span>{{item?.toDate | date:'dd MMM'}}</span>
            </p>
            <p class="date m-0" *ngIf="item?.info && item?.info?.length">{{item?.info}}</p>
          </div>
          <div>
            <!-- Copy button -->
            <button mat-icon-button class="share-button" (click)="copyToClipBoard('info-ai-predictions' + i)" fxHide.xs *ngIf="showCopy">
              <svg-icon [applyCss]="true" class="text-center" src="assets/svgs/copy.svg"
              [svgStyle]="{ 'width.px': 20, 'height.px': 20 }"></svg-icon>
            </button>

            <button mat-icon-button (click)="displayDetails(item)" *ngIf="item?.hide">
              <!-- <mat-icon class="add-button" >add</mat-icon> -->
              <svg-icon [applyCss]="true" class="text-center items-info" src="assets/svgs/add.svg"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </button>
            <button mat-icon-button (click)="hideExtraInfo(item)" *ngIf="!item?.hide">
              <!-- <mat-icon class="add-button">remove</mat-icon> -->
              <svg-icon [applyCss]="true" class="text-center items-info" src="assets/svgs/minus.svg"
              [svgStyle]="{ 'width.px': 16, 'height.px': 16 }"></svg-icon>
            </button>
          </div>
        </div>
        <div *ngIf="!item?.hide && item?.description">
          <div fxLayout="column" class="nested-list">
            <div fxLayout="column">
              <!-- <p class="m-0 font-weight-bold name">{{list?.product}}</p> -->
              <p class="date m-0">
                <!-- <span>Requirements: </span> -->
                <span>{{item?.description}} </span>
                <!-- <span>{{list?.requirement?.unit}}</span> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="informationList?.length <= 0 && !progress" class="my-5 py-3">
    <kl-no-content
      svgIcon="assets/svgs/information.svg"
      text="No information">
    </kl-no-content>
  </div>
</div>
