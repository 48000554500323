import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { StatsListComponent } from './components/stats-list/stats-list.component';
import { StatsComponent } from './components/stats/stats.component';



@NgModule({
  declarations: [
    StatsComponent,
    StatsListComponent,
  ],
  imports: [
    CommonModule,

    // Material module
    MatProgressBarModule,
  ],
  exports: [
    StatsComponent,
    StatsListComponent,
  ]
})
export class KalgudiProgressStatsModule { }
