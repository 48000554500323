import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { KalgudiPageFilters } from '@kalgudi/types';

import { KalgudiAddPageProjectMembers } from '../../classes/kalgudi-add-page-project-members';

@Component({
  selector: 'kl-add-page-project-members',
  templateUrl: './add-page-project-members.component.html',
  styleUrls: ['./add-page-project-members.component.scss'],
})
export class AddPageProjectMembersComponent extends KalgudiAddPageProjectMembers implements OnInit {

  @Input()
  pageId: string;

  @Output()
  addedMembers = new EventEmitter<KalgudiPageFilters>();

  constructor(
    protected injector: Injector,
  ) {

    super(injector);
  }

  ngOnInit() {
  }


  addMembers() {
    this.addedMembers.emit(this.filterFormGroup.value);
  }

}
