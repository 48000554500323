import { Component, Input, OnInit } from '@angular/core';
import { BulkMembersAdditionResponse } from '@kalgudi/types';

@Component({
  selector: 'kl-status-logs',
  templateUrl: './status-logs.component.html',
  styleUrls: ['./status-logs.component.scss']
})
export class StatusLogsComponent implements OnInit {

  @Input()
  usersList: BulkMembersAdditionResponse;

  constructor() { }

  ngOnInit() {
  }

}
