<div class="service-tile h-100">
  <mat-card class="service-info h-100">
    <div>

      <!-- Services Info header -->

      <kl-common-profile-header
        title="Services"
        svgIcon="assets/svgs/services.svg"
        [editable]="editable && profileServiceList?.length"
        actionIcon="edit"
        (action)="showServiceDialog()">
      </kl-common-profile-header>
      <!--/ Services Info header -->

      <!-- Service info -->
      <section fxLayout="row wrap" fxLayoutGap="start" fxLayoutGap="10px" *ngIf="profileServiceList?.length">
        <div class="services-details" fxFlex="calc(50% - 10px)" *ngFor="let services of profileServiceList;let i=index,let last= last">

          <div fxLayout="column" class="mt-2">
            <span class="service-field w-100 text-truncate" translate>{{services?.service}}</span>
            <span class="service-value w-100 text-truncate" translate>License : {{services?.license || '-'}}</span>
          </div>

          <!-- <div *ngIf="!last" class="border-bottom pt-2 mb-2">
          </div> -->

        </div>
      </section>
      <!--/ Service info -->

      <ng-container *ngIf="!profileServiceList?.length">
        <!-- <p class="text-muted pt-2 text-center w-100">No service added yet!</p> -->
        <div class="w-100" fxLayoutAlign="center center">
          <kl-no-content
            [text]="isPublicPage ? 'No services added yet!' : editable ? '' : 'No services added yet!'"
            [actionLink]="isPublicPage ? '' : editable ? 'Add services' : ''"
            (action)="showServiceDialog()">
          </kl-no-content>
        </div>
      </ng-container>

    </div>

  </mat-card>

</div>


