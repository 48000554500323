import { Inject } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiDialogResult, KalgudiUsersMap, KalgudiUsersPickerDialogConfig } from '@kalgudi/types';

import { KalgudiMobileDialog, KalgudiMobileDialogRef, KL_MOBILE_DIALOG_DATA } from '../../mobile-dialog';

/**
 * Users picker dialog logic class.
 *
 */
export abstract class KalgudiUsersPickerMobileDialog extends KalgudiMobileDialog {

  /**
   * Dialog configuration if the user is allowed to select multiple users or not
   */
  readonly multiSelect: boolean;

  /**
   * Title of the dialog
   */
  readonly title: string;

  /**
   * Title of the dialog accept button
   */
  readonly acceptButtonTitle: string;

  /**
   * Title of the dialog reject button
   */
  readonly rejectButtonTitle: string;

  /**
   * Initial set of selected users when initializing the dialog.
   */
  initialSelectedUsersMap: KalgudiUsersMap = {};

  /**
   * Modified set of selected users after performing actions on dialog.
   */
  selectedUsersMap: KalgudiUsersMap = {};



  constructor(
    @Inject(KL_MOBILE_DIALOG_DATA) protected dialogData: KalgudiUsersPickerDialogConfig,
    protected dialogRef: KalgudiMobileDialogRef,
    protected util: KalgudiUtilityService,
  ) {

    // Wake up (initialize) my parent
    super(dialogRef);

    // Initialize list of selected users
    this.initialSelectedUsersMap = this.util.clone(this.dialogData.data) || {};
    this.selectedUsersMap = this.util.clone(this.dialogData.data) || {};

    this.multiSelect = !!(this.dialogData.multiSelect);
    this.title = this.dialogData.title || 'Select users';
    this.acceptButtonTitle = this.dialogData.acceptButtonTitle || 'Select users';
    this.rejectButtonTitle = this.dialogData.rejectButtonTitle || 'Cancel';
  }

  ok(): void {
    const dialogRes: KalgudiDialogResult = {
      data: this.selectedUsersMap,
      accepted: true
    };

    this.dialogRef.close(dialogRes);
  }

  cancel(): void {

    const dialogRes: KalgudiDialogResult = {
      data: this.initialSelectedUsersMap,
      accepted: false
    };

    this.dialogRef.close(dialogRes);
  }

}
