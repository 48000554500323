import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {
  KalgudiAttachUrlModule,
  KalgudiImageChipListModule,
  KalgudiImagePickerModule,
  KalgudiTimePickerModule,
} from '@kalgudi/common';
import { KalgudiButtonModule } from '@kalgudi/common/ui/button';
import { MobileMenuBottomSheetModule } from '@kalgudi/common/ui/mobile-menu-bottom-sheet';
import { KalgudiCoreModule, KalgudiDirectivesModule, KalgudiPipesModule } from '@kalgudi/core';
import { KalgudiShareUpdateModule } from '@kalgudi/share-update';
import { KalgudiSocialModule } from '@kalgudi/social';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { PageActivityFiltersModule } from '../page-activity-filters/page-activity-filters.module';
import { KalgudiPageAiPredictionsFiltersModule } from '../page-ai-predictions-filters/page-ai-predictions-filters.module';
import {
  KalgudiProgramMembersPickerDialogModule,
} from '../program-members-picker-dialog/program-members-picker-dialog.module';
import { KalgudiProgramTargetMembersModule } from '../program-target-members/program-target-members.module';
import {
  PromotionalCropAutocompleteComponent,
} from './components/promotional-crop-autocomplete/promotional-crop-autocomplete.component';
import {
  PromotionalProductAutocompleteComponent,
} from './components/promotional-product-autocomplete/promotional-product-autocomplete.component';
import {
  PromotionalProductDescriptionDialogComponent,
} from './components/promotional-product-description-dialog/promotional-product-description-dialog.component';
import { PromotionalShareFormComponent } from './components/promotional-share-form/promotional-share-form.component';
import { PromotionalShareUpdateApiService } from './services/promotional-share-update-api.service';
import { PromotionalShareUpdateService } from './services/promotional-share-update.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';



@NgModule({
  declarations: [
    PromotionalShareFormComponent,
    PromotionalProductAutocompleteComponent,
    PromotionalCropAutocompleteComponent,
    PromotionalProductDescriptionDialogComponent
  ],
  imports: [
    CommonModule,

    // Forms module
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    // Material module
    MatFormFieldModule,
    MatBottomSheetModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule,
    MatAutocompleteModule,
    AngularSvgIconModule,

    // Kalgudi Libraries
    KalgudiCoreModule,
    KalgudiPipesModule,
    KalgudiButtonModule,
    KalgudiProgramTargetMembersModule,
    KalgudiShareUpdateModule,
    KalgudiProgramMembersPickerDialogModule,
    KalgudiImageChipListModule,
    KalgudiDirectivesModule,
    KalgudiSocialModule,
    MobileMenuBottomSheetModule,
    KalgudiImagePickerModule,
    KalgudiAttachUrlModule,
    KalgudiTimePickerModule,

    KalgudiPageAiPredictionsFiltersModule,

    PageActivityFiltersModule,

    TranslateModule
  ],
  exports: [
    PromotionalShareFormComponent,
    PromotionalProductAutocompleteComponent,
    PromotionalCropAutocompleteComponent,
    PromotionalProductDescriptionDialogComponent
  ],
  providers: [
    PromotionalShareUpdateApiService,
    PromotionalShareUpdateService
  ],
  entryComponents: [
    PromotionalShareFormComponent,
    PromotionalProductDescriptionDialogComponent
  ]
})
export class KuberPagePromotionalShareUpdateModule { }
