import { Injector, Directive } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { KalgudiUserPickerFormControl } from '@kalgudi/core';
import { KalgudiDialogConfig, KalgudiDialogResult, KalgudiUsersPickerDialogConfig } from '@kalgudi/types';
import { Observable, timer } from 'rxjs';
import { first, switchMap, takeUntil, tap } from 'rxjs/operators';

import { KalgudiPageService } from '../../../services/kalgudi-page.service';
import { DigitalAssistanceDialogService } from '../services/digital-assistance-dialog.service';


/**
 * Use the class AssistantPicker as the base class when picking user while
 * performing digital assistance.
 *
 * Must provide the `ASSISTANT_PICKER_VALUE_ACCESSOR` to the component.
 *
 * @usageNotes
 * #### To ensure the AssistantPicker can be used as a formControlName
 *
 * ```ts
 * Component({
 *  providers: [{
 *   provide: NG_VALUE_ACCESSOR,
 *   useExisting: forwardRef(() => YourComponent),
 *   multi: true,
 *  }]
 * })
 * ```
 *
 * @author Pankaj Prakash
 */
@Directive()
export abstract class AssistantPicker extends KalgudiUserPickerFormControl {

  pageId: string;

  protected digitalAssistantPicker: DigitalAssistanceDialogService;
  protected kalgudiPageService: KalgudiPageService;

  constructor(
    protected injector: Injector,
  ) {

    super();

    this.digitalAssistantPicker = this.injector.get(DigitalAssistanceDialogService);
    this.kalgudiPageService = this.injector.get(KalgudiPageService);

    // Initialize page details
    this.initPageDetails();
  }


  // --------------------------------------------------------
  // #region Protected and Private methods
  // --------------------------------------------------------

  /**
   * Shows page members picker dialog for mobile and web based on the device or screen size.
   */
  protected showUsersPicker(
    dialogConfig: KalgudiDialogConfig,
    matDialogConfig: MatDialogConfig<any>
  ): Observable<KalgudiDialogResult> {
      return this.digitalAssistantPicker.showFrequentPicker(dialogConfig, matDialogConfig);
  }

  /**
   * Prepares members dialog configuration object.
   *
   * @override
   */
  protected getUsersPickerDialogConfig(): { dialogDetails: KalgudiUsersPickerDialogConfig, dialogConfig: MatDialogConfig } {

    // Users dialog UI configuration
    const { dialogDetails, dialogConfig }: { dialogDetails: any, dialogConfig: MatDialogConfig } = super.getUsersPickerDialogConfig();

    dialogDetails.pageId = this.pageId;

    return { dialogDetails, dialogConfig };
  }

  /**
   * Gets the program details and binding the details to he share form
   */
  private initPageDetails(): void {

    timer(500)
      .pipe(
        takeUntil(this.destroyed$),

        first(),

        switchMap(_ =>
          this.kalgudiPageService.pageDetails$
            .pipe(
              takeUntil(this.destroyed$),

              first(),

              tap(pageDetails => this.pageId = pageDetails.pageId)
            )
        )
      ).subscribe();
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
