<main id="social-container">
  <!-- Form group for crop details form -->
  <form class="p-3 form-container overflow-auto" [formGroup]="cropDetailsForm">

    <!-- Header FPO crop -->
    <div>
      <h6 class="font-weight-bold mb-1" translate>FPO crop details</h6>
    </div>
    <!--/ Header FPO crop -->

    <div fxLayout="column" formGroupName="cropDetails">
      <div fxLayout="row wrap" fxLayoutGap="10px">

        <!-- Financial year id -->
        <div fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label translate>Financial year</mat-label>
            <mat-select placeholder="Output business year" formControlName="year">
              <mat-option *ngFor="let years of financialYears" [value]="years?.value">{{ years?.id }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Financial year id -->

        <!-- Current year crop season -->
        <div fxLayout="column" fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Season</mat-label>
            <mat-select formControlName="season" placeholder="Select season">
              <mat-option *ngFor="let type of seasonType" [value]="type?.id">
                {{ type?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--/ Current year crop season -->

        <!-- Current year crop details -->
        <div fxLayout="column" fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Crop</mat-label>
            <input autocomplete="off" [matAutocomplete]="cropAuto" matInput [placeholder]="'Select crop' | translate" formControlName="cropName" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')"
                [readonly]="!getSeason?.value">
              <mat-autocomplete #cropAuto="matAutocomplete" [autoActiveFirstOption]="true"
                [displayWith]="displayCurrentCrops" (optionSelected)="selectedCrop($event)">
               <mat-option *ngFor="let option of currentCropFilteredOptions$ | async" [value]="option?.cropValue">
                {{ option?.cropValue }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-error class="mt-n2" *ngIf="!getSeason?.value && getCrop?.touched" translate>Enter season first</mat-error>
          <mat-error class="mt-n2" *ngIf="!isCropNameMatched && getCrop?.value?.length" translate>Entered crop name is not found</mat-error>
        </div>
        <!--/ Current year crop details -->

        <!-- Current year crop variety -->
        <div fxLayout="column" fxFlex="calc(25% - 10px)" fxFlex.xs="calc(50% - 10px)" *ngIf="false">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Variety</mat-label>
            <input matInput [placeholder]="'Enter variety' | translate" formControlName="varietyName" (keypress)="onKeyPress($event, 'variety')" maxlength="40" [readonly]="!getSeason?.value">
          </mat-form-field>
        </div>
        <!--/ Current year crop variety -->

        <!-- Current year cultivation area -->
        <div fxLayout="column" fxFlex="calc(30% - 10px)" fxFlex.xs="calc(50% - 10px)">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Cultivation area(acre)</mat-label>
            <input matInput [placeholder]="'Enter cultivation area (acre)' | translate" formControlName="cultivationArea" (keypress)="onKeyPress($event, 'area')" maxlength="5" [readonly]="!getSeason?.value">
          </mat-form-field>
          <mat-error class="mt-n2" *ngIf="!getSeason.value && getCultivationArea.touched" translate>Enter season first</mat-error>
        </div>
        <!--/ Current year cultivation area -->

        <!-- Current year cultivation quantity -->
        <div fxLayout="column" fxFlex="calc(30% - 10px)" fxFlex.xs="calc(40% - 10px)">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Quantity</mat-label>
            <input matInput [placeholder]="'Enter output quantity' | translate" formControlName="outputQty" (keypress)="onKeyPress($event, 'area')" maxlength="5" [readonly]="!getSeason?.value">
          </mat-form-field>
          <mat-error class="mt-n2" *ngIf="!getSeason.value && getOutputQty.touched" translate>Enter season first</mat-error>
        </div>
        <!--/ Current year cultivation quantity -->

        <!-- Current year cultivation quantity unit -->
        <div fxLayout="column" fxFlex="calc(30% - 10px)" fxFlex.xs="calc(40% - 10px)">
          <mat-form-field appearance="outline" class="w-100 placeholder-text">
            <mat-label translate>Quantity unit</mat-label>
            <mat-select formControlName="outputQtyUnit" [placeholder]="'Enter output quantity unit' | translate" [disabled]="!getSeason?.value">
              <mat-option *ngFor="let unit of outputQtyUnits" [value]="unit?.id">
                {{ unit?.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--/ Current year cultivation quantity unit -->

        <div class="add-button" fxLayout="row" fxLayoutAlign="end center" fxFlex.xs="calc(20% - 10px)">
          <button mat-fab class="mb-2" [disabled]="!(getSeason?.value && getCrop?.value && getCurrentFinancialYearId?.value && getOutputQtyUnit.value && getCultivationArea.value) || !(isCropNameMatched)" (click)="addCurrentYearCropDetails()">
            <mat-icon class="text-white">add</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <!-- FPO crop details table view -->
    <div class="fpo-crop-table-sections fpo-crop-table rounded" *ngIf="currentYearCropDetails && currentYearCropDetails?.length">
      <div class="table">
        <!-- Table header values -->
        <div class="table-header text-center text-white p-1">
          <div class="table-cell" translate>S.no</div>
          <div class="table-cell" translate>F.year</div>
          <div class="table-cell" translate>Season</div>
          <div class="table-cell" translate>Crop</div>
          <div class="table-cell" translate *ngIf="false">Variety</div>
          <div class="table-cell" translate>Area(acre)</div>
          <div class="table-cell" translate>Quantity</div>
          <div class="table-cell" translate>Unit</div>
          <div class="table-cell" translate>Actions</div>
        </div>
        <!--/ Table header values -->

        <!-- Table body values -->
        <div class="table-body text-center">
          <div class="table-row bg-white" *ngFor="let crop of currentYearCropDetails let i= index">
            <div class="table-cell text-break">{{ i+1 }}</div>
            <div class="table-cell text-break">{{ crop?.year || '-' }}</div>
            <div class="table-cell text-break">{{ (crop?.season | titlecase) || '-' }}</div>
            <div class="table-cell text-break">{{ (crop?.cropName | titlecase) || '-' }}</div>
            <div class="table-cell text-break" *ngIf="false">{{ crop?.varietyName || '-' }}</div>
            <div class="table-cell text-break">{{ (+crop?.cultivationArea).toFixed(2) || '-' }}</div>
            <div class="table-cell text-break">{{ (+crop?.outputQty).toFixed(2) || '-' }}</div>
            <div class="table-cell text-break">{{ crop?.outputQtyUnit || '-' }}</div>
            <div class="actions table-cell">
              <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editCurrentYearCropDetails(i)">
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>
              <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeCurrentYearCropDetails(i)">
                <mat-icon class="delete-icon">clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!--/ Table body values -->
      </div>
    </div>
    <!--/ FPO crop details table view -->

    <div>
      <h6 class="font-weight-bold mb-1 mt-2" translate>FPO crop-wise procurement</h6>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="10px" formGroupName="cropWiseProcurement">

      <!-- Financial year id -->
      <div fxFlex="calc(23% - 10px)" fxFlex.xs="calc(33% - 10px)">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label translate>Financial year</mat-label>
          <mat-select [placeholder]="'Output business year' | translate" formControlName="year">
            <mat-option *ngFor="let years of financialYears" [value]="years?.value">{{ years?.id }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- Financial year id -->

      <!-- Crop -->
      <div fxLayout="column" fxFlex="calc(20% - 10px)" fxFlex.xs="calc(33% - 10px)">
        <mat-form-field appearance="outline" class="w-100 placeholder-text">
          <mat-label translate>Crop</mat-label>
          <input matInput autocomplete="off" [matAutocomplete]="procurementCrop" [placeholder]="'Enter crop' | translate"
            formControlName="crop" maxlength="40" (keypress)="onKeyPress($event, 'alphabetSpace')">
          <mat-autocomplete #procurementCrop="matAutocomplete" [autoActiveFirstOption]="true"
            [displayWith]="displayProcurementCrops" (optionSelected)="selectedProcurementCrop($event)">
            <mat-option *ngFor="let option of procurementCropFilteredOptions$ | async" [value]="option.cropValue">
              {{ option?.cropValue }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-error class="mt-n2" *ngIf="!isCropWiseNameMatched && getCropWise?.value?.length" translate>Entered crop name is not found</mat-error>
      </div>
      <!--/ Crop -->

      <!-- Number of farmers -->
      <div fxLayout="column" fxFlex="calc(18.5% - 10px)" fxFlex.xs="calc(33% - 10px)">
        <mat-form-field appearance="outline" class="w-100 placeholder-text">
          <mat-label translate>Number of farmers</mat-label>
          <input matInput [placeholder]="'Enter number of farmers' | translate" formControlName="noOfFarmers" (keypress)="onKeyPress($event)" maxlength="5" [readonly]="!getCropWise?.value">
        </mat-form-field>
        <mat-error class="mt-n2" *ngIf="!getCropWise.value && getNoOfFarmers.touched" translate>Enter crop first</mat-error>
      </div>
      <!--/ Number of farmers -->

      <!-- Total qty -->
      <div fxLayout="column" fxFlex="calc(18.5% - 10px)" fxFlex.xs="calc(80% - 10px)">
        <mat-form-field appearance="outline" class="w-100 placeholder-text">
          <mat-label translate>Total quantity in tons</mat-label>
          <input matInput [placeholder]="'Enter total quantity in tons' | translate" formControlName="totalQty" (keypress)="onKeyPress($event, 'area')" maxlength="5" [readonly]="!getCropWise?.value">
        </mat-form-field>
        <mat-error class="mt-n2" *ngIf="!getCropWise.value && getTotalQty.touched" translate>Enter crop first</mat-error>
      </div>
      <!--/ Total qty -->

      <div class="add-button" fxLayout="row" fxLayoutAlign="end start" fxFlex.xs="calc(20% - 10px)">
        <button mat-fab class="mb-2" [disabled]="!(getCropWiseFinancialYearId.value && getNoOfFarmers?.value && getCropWise?.value && getTotalQty?.value) || !(isCropWiseNameMatched)" (click)="cropwiseProcurement()">
          <mat-icon class="text-white">add</mat-icon>
        </button>
      </div>
    </div>

    <!-- FPO crop-wise table view -->
    <div class="fpo-crop-table-sections fpo-cropwise-table rounded" *ngIf="cropwiseProcurementDetails && cropwiseProcurementDetails?.length">
      <div class="table">
        <!-- Table header values -->
        <div class="table-header text-center text-white p-1">
          <div class="table-cell" translate>S.no</div>
          <div class="table-cell" translate>F.year</div>
          <div class="table-cell" translate>Total farmers</div>
          <div class="table-cell" translate>Crop</div>
          <div class="table-cell" translate>Quantity(tons)</div>
          <div class="table-cell" translate>Actions</div>
        </div>
        <!--/ Table header values -->

        <!-- Table body values -->
        <div class="table-body text-center">
          <div class="table-row bg-white" *ngFor="let crop of cropwiseProcurementDetails let i= index">
            <div class="table-cell text-break">{{ i+1 }}</div>
            <div class="table-cell text-break">{{ crop?.year || '-' }}</div>
            <div class="table-cell text-break">{{ crop?.noOfFarmers || '-' }}</div>
            <div class="table-cell text-break">{{ (crop?.crop | titlecase) || '-' }}</div>
            <div class="table-cell text-break">{{ (+crop?.totalQty).toFixed(2) }}</div>
            <div class="actions table-cell">
              <button matTooltip="Edit" matTooltipPosition="before" mat-icon-button class="edit-button" (click)="editCropWiseProcurementDetails(i)">
                <mat-icon class="edit-icon">edit</mat-icon>
              </button>
              <button matTooltip="Delete" matTooltipPosition="below" mat-icon-button class="delete-buttons" (click)="removeCropwiseProcurementDetails(i)">
                <mat-icon class="delete-icon">clear</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <!--/ Table body values -->
      </div>
    </div>
    <!--/ FPO crop-wise table view -->

  </form>
  <!--/ Form group for crop details form -->

  <!-- Save button -->
  <!-- <div fxLayout fxLayoutAlign="end end" class="p-3">
    <kl-flat-button
      label="Save"
      [progress]="isProgress"
      [disabled]="currentYearCropDetailsGroup.invalid || cropWiseProcurementGroup.invalid"
      (clicked)="addBasicInfoDetails()">
    </kl-flat-button>
  </div> -->
  <!-- Save button -->

</main>
