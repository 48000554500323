<main id="members-full-view">

  <!-- Task list header -->
  <div *ngIf="!hideTaskHeader">
    <kl-task-list-header></kl-task-list-header>
  </div>
  <!--/ Task list header -->

  <mat-card class="p-0 mt-2 task-members-list-da">

    <!-- Task header -->
    <kl-task-header-da [taskDetails]="taskDetails"></kl-task-header-da>
    <!--/ Task header -->

    <mat-tab-group class="task-members-tabs" [(selectedIndex)]="activeTabIndex">
      <!-- Mat tab for completed task -->
      <mat-tab [label]="TABS?.COMPLETED?.title">
        <div class="p-2" *ngIf="activeTabIndex === TABS?.COMPLETED.index">
          <kl-task-member-list-da (selectedProfile)="getSelectedProfileKeys($event)" (errorMsg)="showErrorMsg($event)" [projectId]="projectId" [taskId]="taskId" [membersStatus]="TABS?.COMPLETED?.id"></kl-task-member-list-da>
        </div>
      </mat-tab>
      <!--/ Mat tab for completed task -->

      <!-- Mat tab for in progress task -->
      <mat-tab [label]="TABS?.IN_PROGRESS?.title">
        <div class="p-2" *ngIf="activeTabIndex === TABS?.IN_PROGRESS.index">
          <kl-task-member-list-da (selectedProfile)="getSelectedProfileKeys($event)" (errorMsg)="showErrorMsg($event)" [projectId]="projectId" [taskId]="taskId" [membersStatus]="TABS?.IN_PROGRESS?.id"></kl-task-member-list-da>
        </div>
      </mat-tab>
      <!--/ Mat tab for in progress task -->

      <!-- Mat tab for not started task -->
      <mat-tab [label]="TABS?.NOT_STARTED?.title">
        <div class="p-2" *ngIf="activeTabIndex === TABS?.NOT_STARTED.index">
          <kl-task-member-list-da (selectedProfile)="getSelectedProfileKeys($event)" (errorMsg)="showErrorMsg($event)" [projectId]="projectId" [taskId]="taskId" [membersStatus]="TABS?.NOT_STARTED?.id"></kl-task-member-list-da>
        </div>
      </mat-tab>
      <!--/ Mat tab for not started task -->
    </mat-tab-group>
  </mat-card>
</main>

<!-- Selected members -->
<div class="members-selected p-2" fxLayout="column">

  <div><p class="m-0 err-msg">{{errorMsg}}</p></div>
  <div fxLayout="row" fxLayoutAlign="space-between center">

    <!-- Selected members count -->
    <div class="p-1" fxLayoutAlign="center center" fxLayoutGap="8px">
      <span class="members-count">{{ selectedProfileKeys?.length || 0 }}</span>
      <span class="selected-members text-secondary" translate>Members selected</span>
    </div>
    <!--/ Selected members count -->

    <!-- Submit task -->
    <div class="next-btn" fxLayoutAlign="center center" fxLayoutGap="5px">
      <span class="submit-task text-right text-secondary" translate>Submit task for selected members</span>
      <kl-flat-button
        [type]="'button'"
        [label]="'Next'"
        (clicked)=openTaskSubmissionPage()
        [disabled]="!selectedProfileKeys?.length">
      </kl-flat-button>
    </div>
    <!--/ Submit task -->
  </div>

</div>
<!--/ Selected members -->
