import { EventEmitter, Injector, Input, Output, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { KalgudiDestroyable, KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiNotification, KL_NOTIFICATION } from '@kalgudi/core/config';
import { KalgudiUserFarmerMembershipDetails } from '@kalgudi/types';
import { finalize, takeUntil } from 'rxjs/operators';

import { KalgudiFarmerProfileService } from '../services/kalgudi-farmer-profile.service';

@Directive()
export abstract class KalgudiFarmerMembershipForm extends KalgudiDestroyable {

  @Input()
  farmerMembershipDetails: KalgudiUserFarmerMembershipDetails;

  @Output()
  closeDialog = new EventEmitter();

  organizationList = ['FPO', 'FPG'];
  membershipTypeList = ['Member', 'Leader', 'BOD', 'CEO', 'Others'];

  farmerMembershipForm: FormGroup;

  private fb: FormBuilder;
  private util: KalgudiUtilityService;
  private notification: KalgudiNotification;
  private kalgudiFarmerProfileService: KalgudiFarmerProfileService;

  constructor(protected injector: Injector) {
    super();

    this.fb                          = this.injector.get(FormBuilder);
    this.util                        = this.injector.get(KalgudiUtilityService);
    this.notification                = this.injector.get<KalgudiNotification>(KL_NOTIFICATION);
    this.kalgudiFarmerProfileService = this.injector.get(KalgudiFarmerProfileService);


    this.farmerMembershipForm = this.newFarmerMembershipForm;
  }

  // --------------------------------------------------------
  // #region getter and setter methods
  // --------------------------------------------------------

  /**
   * Form group for add farmer membership form
   */
  private get newFarmerMembershipForm(): FormGroup {

    return this.fb.group({
      organization: ['', Validators.required],
      organizationName: ['', Validators.required],
      farmerMembershipId: ['', Validators.required],
      memberRole: ['', Validators.required],
      sinceYear: ['', Validators.required],
    });
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region public methods
  // --------------------------------------------------------

  /**
   * Saves or updates farmer membership
   */
  saveOrUpdateMembership() {

    this.farmerMembershipDetails ? this.updateMembership(true, this.farmerMembershipDetails.id) : this.addMembership(false);
  }

  /**
   * Saves membership
   */
  addMembership(isExisted: boolean) {
    const payload = this.preparePayload(this.farmerMembershipForm.value);

    this.notification.showSpinner();
    this.kalgudiFarmerProfileService.saveNewMembership(payload, isExisted)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.notification.hideSpinner();
        })
      )
      .subscribe(
        res => {
          this.notification.showMessage('Membership added');
          this.closeDialog.emit();
        },
        err => {
          this.util.apiErrorHandler(err);
        }
      )
  }

  /**
   * Updates membership
   */
  updateMembership(isExisted: boolean, membershipId: string) {
    const payload = this.preparePayload(this.farmerMembershipForm.value);

    this.notification.showSpinner();
    this.kalgudiFarmerProfileService.updateMembership(payload, isExisted, membershipId)
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => {
          this.notification.hideSpinner();
        })
      )
      .subscribe(
        res => {
          this.notification.showMessage('Membership updated');
          this.closeDialog.emit();
        },
        err => {
          this.util.apiErrorHandler(err);
        }
      )
  }


  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

  // --------------------------------------------------------
  // #region private and protected methods
  // --------------------------------------------------------

  /**
   *  Prepare payload
   */
  protected preparePayload(formValue) {
    return this.util.clone(formValue);
  }

  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------

}
