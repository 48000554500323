import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { KalgudiUtilityService } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SocialSharesService {

  domain: string;

  SocialUrls = {
    EMAIL_WEB: 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Kalgudi&body=',
    EMAIL_MOBILE: `mailto:?subject=Kalgudi&body=`,
    FACEBOOK: 'https://www.facebook.com/sharer/sharer.php?u=',
    TWITTER: 'http://www.twitter.com/share?url=',
    LINKED_IN: 'http://www.linkedin.com/shareArticle?mini=true&url=',
    WATS_APP_MOBILE: 'https://api.whatsapp.com/send?text=',
    WATS_APP_WEB: 'https://web.whatsapp.com/send?text=',
  }

  /**
   * `v2/social/shares/public-share`
   */
  private readonly SOCIAL_SHARE = `${this.env.restBaseUrlV2}/social/shares/public-share`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private util: KalgudiUtilityService,
    private http: HttpClient
  ) {

    // if(this.env.useHash) {
    //   this.domain = `${this.env.coreDomain}/#`
    // } else {
    //   this.domain = this.env.coreDomain
    // }

    if(this.env.appId === 'SHAKTIMAN') {
      this.domain = 'https://core.shaktimanfarmingsolutions.com';
    } else {
      this.domain = this.env.coreDomain
    }

  }


  /**
   * Shares an event to facebook
   */
  shareEventToFacebook(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.FACEBOOK)

    // const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.domain}${url}`;
    // window.open(fbUrl);
  }


  /**
   * Shares an event to twitter
   */
  shareEventToTwitter(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.TWITTER)

    // const twitterUrl = `http://www.twitter.com/share?url=${this.domain}${url}`;
    // window.open(twitterUrl);
  }


  /**
   * Shares an event to linkedIn
   */
  shareEventToLinkedIn(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.LINKED_IN)

    // const linkedinUrl = `http://www.linkedin.com/shareArticle?mini=true&url=${this.domain}${url}`;
    // window.open(linkedinUrl);
  }


  /**
   * Shares an event to whatsapp mobile
   */
  shareEventToWhatsappMobile(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.WATS_APP_MOBILE)


    // const whatsappUrl = `https://api.whatsapp.com/send?text=${shareUrl}`;
    // window.open(whatsappUrl);
  }

  /**
   * Shares an event to whatsapp web
   */
  shareEventToWhatsappWeb(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.WATS_APP_WEB)

    // const whatsappUrl = `https://web.whatsapp.com/send?text=${this.shareUrl}`;

    // window.open(whatsappUrl);
  }

  /**
   * Shares an event to email web
   */
  shareEventToEmailWeb(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.EMAIL_WEB)

    // const emailUrl = `mailto:?body=${this.env.domain}${url}`;
    // const emailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Kalgudi&body=${this.domain}${url}`;
    // window.open(emailUrl);
  }

  /**
   * Shares an event to email mobile
   */
  shareEventToEmailMobile(url: string) {

    this.fetchShortUrl(this.domain, url, this.SocialUrls.EMAIL_MOBILE)

    // const emailUrl = `mailto:?body=${this.env.domain}${url}`;
    // const emailUrl = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=Kalgudi&body=${this.domain}${url}`;
    // window.open(emailUrl);
  }

  /**
   * Click to copy url
   */
  eventClickToCopyUrl(url: string) {
    let modifiedURL: string = url;
    if ((this.env.appId === 'SAM_FPO' || this.env.appId === 'FPO_APP' || this.env.appId === 'FPO_NET_APP') && url.includes('/public/profiles')) {
      modifiedURL = url.replace('/public', '/#/public')
    } else {
      modifiedURL = url;
    }
    if(modifiedURL && !modifiedURL.includes('http')) {
      this.getShortUrl(`${this.domain}${modifiedURL}`)
        .subscribe(
          (res: string) => {
            navigator.clipboard.writeText(res);
          }
        )
    } else {
      navigator.clipboard.writeText(modifiedURL);
    }
  }

  /**
   * Fetches the short url
   */
  fetchShortUrl(domain: string, url: string, shareToURl: string) {
    let modifiedURL: string = url;
    if ((this.env.appId === 'SAM_FPO' || this.env.appId === 'FPO_APP' || this.env.appId === 'FPO_NET_APP') && url.includes('/public/profiles')) {
      modifiedURL = url.replace('/public', '/#/public')
    } else {
      modifiedURL = url;
    }
    if(modifiedURL && !modifiedURL.includes('http')) {
      this.getShortUrl(`${domain}${modifiedURL}`)
        .subscribe(
          (res: string) => {
            window.open(`${shareToURl}${res}`);

          }
        )
    } else {
      window.open(`${shareToURl}${modifiedURL}`);

    }
  }

  /**
   * Calls api to fetch the short url
   */
  private getShortUrl(publicUrl: string): Observable<any> {

    const params = {
      publicUrl
    }

    return this.http.get<ApiResponseCommon>(this.SOCIAL_SHARE, { params } )
    .pipe(
      // Check for API response errors
      map(res => this.util.apiErrorHandler(res)),

      // All good, emit true
      map(res => res.data.tinyUrl),

      catchError((err) => publicUrl)
    );

  }
}
