import { Injectable } from '@angular/core';
import { NotificationsList } from '@kalgudi/types';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class KalgudiHomeStreamStateService {

  private newStreamItemSubject = new Subject<NotificationsList>();

  constructor() { }

  get newStreamItem$(): Observable<NotificationsList> {
    return this.newStreamItemSubject;
  }

  unshiftToStream(item: NotificationsList): void {
    this.newStreamItemSubject.next(item);
  }
}
