import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiError, HttpStatusCode, KalgudiStreamData, KalgudiUtilityService, REST_API_ERROR_MESSAGES } from '@kalgudi/core';
import { KalgudiEnvironmentConfig, KL_ENV } from '@kalgudi/core/config';
import { ApiResponseCommon, ApiResponseCommonV1, KalgudiUserBasicDetails, NotificationNetwork } from '@kalgudi/types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class KalgudiNetworksApiService {

  private readonly API_NETWORK_BASE = `${this.env.restBaseUrl}/network`;

  /**
   * `v1/network/views`
   */
  private readonly API_PROFILE_VIEWS_LIST = `${this.API_NETWORK_BASE}/views/:offset/:limit`;

  /**
   * `v1/network/views/:profileKey`
   */
  private readonly API_PROFILE_VIEW = `${this.API_PROFILE_VIEWS_LIST}/:profileKey`;

  constructor(
    @Inject(KL_ENV) private env: KalgudiEnvironmentConfig,
    private httpClient: HttpClient,
    private util: KalgudiUtilityService,
  ) { }

  /**
   * Gets list of profile views
   */
  fetchNetworkList(offset: string, limit: string): Observable<KalgudiStreamData> {

    const url = this.API_PROFILE_VIEWS_LIST.replace(':offset', offset).replace(':limit', limit);

    return this.httpClient.get<ApiResponseCommonV1>(url)
      .pipe(
        map(res => this.util.apiErrorHandler(res, HttpStatusCode.OK)),
        map(res => this.mapNetworkListResponse(res))
      );
  }

  /**
   * Posts a message to Api when a user visits another user profile
   *
   * @param profileKey Profile key of the user whom logged in user viewed
   */
  profileViewed(profileKey: string): Observable<NotificationNetwork> {

    const url = this.API_PROFILE_VIEW.replace(':profileKey', profileKey);

    return this.httpClient.get<ApiResponseCommonV1>(url)
      .pipe(
        // Api error handler
        map(o => this.profileViewedResponseHandler(profileKey, o)),

        // Map Api response to type
        map(o => this.mapProfileViewedResponse(o))
      );
  }


  // --------------------------------------------------------
  // #region Private methods
  // --------------------------------------------------------


  /**
   * API response handler for the Kalgudi other user profile view.
   *
   * On success it returns back the response returned from the API. Otherwise
   * throws back specific API errors.
   *
   * @param profileKey Profile key
   * @param res API response
   */
  private profileViewedResponseHandler(profileKey: string, res: ApiResponseCommonV1): ApiResponseCommonV1 {

    // List of all profile error messages
    const errorMessages = {
      // Common error messages
      ...REST_API_ERROR_MESSAGES,

      // Profile specific error messages
      [HttpStatusCode.NOT_FOUND]: 'Unable to find profile'
    };

    // API response code is a non success response
    if (res.code !== HttpStatusCode.CREATED) {
      throw new ApiError(new Error(errorMessages[res.code]));
    }

    // All good, return the response back to process
    return res;
  }

  /**
   * Maps, the Kalgudi user profile view API response to the content notification type.
   *
   * @param res Profile view API response
   */
  private mapProfileViewedResponse(res: ApiResponseCommonV1): NotificationNetwork {

    return this.util.toJson<NotificationNetwork>(res.data);
  }

  /**
   * Maps network users API response to kalgudi users type.
   */
  private mapProfileViewResponseToUserBasicDetails(profileViews: any[]): KalgudiUserBasicDetails[] {

    return profileViews.map(profile => ({
      firstName: profile.firstName,
      profileKey: profile.userId,
      profilePicUrl: profile.profilePicURL,
      profilePicURL: profile.profilePicURL,
      businessName: profile.businessTypeName,
      location: profile.placeTo.locationShort,
    }));
  }

  /**
   * Maps network list API response to kalgudi network list type.
   */
  private mapNetworkListResponse(res: ApiResponseCommon): KalgudiStreamData {

    const resData = JSON.parse(res.data);

    return {
      items: this.mapProfileViewResponseToUserBasicDetails(resData.networkTOList),
      count: resData.total
    };
  }
  // --------------------------------------------------------
  // #endregion
  // --------------------------------------------------------
}
